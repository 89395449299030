import React, { useContext } from "react";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import { ElementsRefContext } from "../../AutomationDetails";
import TargetHandles from "../../Handles/TargetHandles";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import SourceHandles from "../../Handles/SourceHandles";
import {
  defaultEmailForm,
  formatAutomationEmailForm
} from "../../../../Reception/ReceptionNewConvForm/EmailFormContentHelper";
import EmailFormContent from "../../../../Reception/ReceptionNewConvForm/EmailFormContent";
import AutomationCreation, {
  EntityAutomationCreationSidebar
} from "./CreationNode";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import { useIntl } from "react-intl";
import { isValueExpression } from "../../../../../Helpers/MiscHelper";

const convertAutomationToEmailForm = (form, userId) => {
  const {
    client,
    to_ids,
    cc_ids,
    bcc_ids,
    to,
    cc,
    bcc,
    enableManualBCC,
    enableManualCC,
    enableManualTo,
    subject,
    text,
    tickets,
    creator,
    organization_space_id,
    tasks,
    deals,
    signature
  } = form;

  return {
    Company: organization_space_id,
    Creator: creator,
    Client: client,
    enableManualBCC,
    enableManualCC,
    enableManualTo,
    To: isValueExpression(to_ids)
      ? to_ids
      : to_ids?.length > 0
      ? to_ids?.split(",")
      : undefined,
    Cc: isValueExpression(cc_ids)
      ? cc_ids
      : cc_ids?.length > 0
      ? cc_ids?.split(",")
      : undefined,
    Bcc: isValueExpression(bcc_ids)
      ? bcc_ids
      : bcc_ids?.length > 0
      ? bcc_ids?.split(",")
      : undefined,
    ToManual: to,
    CcManual: cc,
    BccManual: bcc,
    Subject: subject,
    Text: text,
    Signature: signature
      ? {
          type: signature.Type,
          data: signature.UniqId,
          Company: signature.OrganizationId
        }
      : undefined,
    Tickets: tickets,
    Tasks: tasks,
    Deals: deals
  };
};

const settings = {
  convertAutomationToForm: convertAutomationToEmailForm,
  buildBaseForm: ({ user, id, convertedForm }) => {
    const EmailForm = defaultEmailForm();
    return {
      ...EmailForm,
      ...convertedForm
    };
  },
  getDefaultForm: () => {
    return defaultEmailForm();
  },
  formatToSnake: formatAutomationEmailForm,
  FormContent: (props) => {
    return (
      <EmailFormContent
        disableAttachments
        Automation
        disableConversations
        {...props}
      ></EmailFormContent>
    );
  }
};

const EmailCreationNode = React.memo(({ selected, data, id, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();
  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleClick = () => {
    openSidebar(
      <EntityAutomationCreationSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        data={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };
  const { enabled, config } = data;
  const intl = useIntl();
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <AutomationCreation
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleClick}
          selected={selected}
          icon={faEnvelope}
          text={intl.formatMessage({ id: "CREATE_EMAIL" })}
        />
        {/* <NodeContainer
          enabled={enabled}
					type={type}
          selected={selected}
          onDoubleClick={handleClick}
        >
          <div className={classnames(styles.nodeIcon, "p-3")}>
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
          <div className="px-3 d-flex justify-content-center bg-white flex-column text-black fs-14">
            <div className="fw-medium">Email</div>
          </div>
        </NodeContainer> */}

        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default EmailCreationNode;
