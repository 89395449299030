import { useCallback, useContext, useEffect, useMemo } from "react";
import {
	ServerAwareUpdateTempViewsContext,
  ServerAwareViewsContext
} from "../CGrid/ServerAwareViews";
import querystring from "query-string";
import { useLocation } from "react-router-dom";
import { useSpace } from "../../Contexts/SpaceContext";
import { ServerAwareFavoritesContext } from "../../Containers/AppBar/ServerAwareFavorite";
import { AppBarFavoriteTreeSetter } from "../../Containers/AppBar/AppBarFavoriteQuery";

export const DownsizedPipelineStatusChooserListnerDict = {};

const calculateColumns = (columns, orderList, resetLayout) => {
  if (columns === undefined) return undefined;
  const fieldDict = getfieldTitleDict(columns);
  const result = [];
  for (const c of orderList) {
    if (fieldDict[c]) result.push(fieldDict[c]);
  }
  return result;
};

const getfieldTitleDict = (Columns) => {
  const result = {};

  for (const c of Columns) {
    result[c.Id] = c;
  }

  return result;
};

export const useResolvedDownsizedPipelineStatus = (columns, id, pipeline) => {
  const favoriteResult = useResolvedDownsizedPipelineStatusFavorites(
    columns,
    id,
    pipeline
  );
  const viewsResult = useResolvedDownsizedPipelineStatusViews(
    columns,
    id,
    pipeline
  );
  if (favoriteResult) return favoriteResult;
  else return viewsResult;
};

const useResolvedDownsizedPipelineStatusFavorites = (columns, id, pipeline) => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);

  if (!FavoriteItem) return null;
  const { downsizedPipelineStatus } = FavoriteItem.data;
  return downsizedPipelineStatus;
};

export const useResolvedDownsizedPipelineStatusViews = (
  columns,
  id,
  pipeline
) => {
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const lsViews = useContext(ServerAwareViewsContext);
  const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);
  const space = useSpace();
  const index = lsViews?.findIndex((e) => e.id === viewPref);
	
  const preOrderList =
    lsViews && index !== -1
      ? lsViews[index].downsizedPipelineStatus
      : lsViews && lsViews[0]?.downsizedPipelineStatus
      ? lsViews[0].downsizedPipelineStatus
      : [];

  const orderList = preOrderList ? preOrderList : undefined;

  const resolvedColumn = useMemo(() => {
    return orderList && orderList.length > 0
      ? orderList
      : [];
  }, [orderList]);

  const updateLocalStorage = useCallback(
    (columns) => {
      let gridSavedViews = JSON.parse(
        window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
      );
      const viewGridViews = gridSavedViews[id];
      const index = viewGridViews.findIndex((e) => e.id === viewPref);
      let resolvedIndex = index === -1 ? 0 : index;
      let viewGridObject = viewGridViews[resolvedIndex] || {};
      let viewGridObjectPipelined =
        viewGridObject.downsizedPipelineStatus || {};
      viewGridObjectPipelined = columns;
      viewGridObject.downsizedPipelineStatus = viewGridObjectPipelined;
      viewGridViews[resolvedIndex] = viewGridObject;
      gridSavedViews[id] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
			
			updateLsViews()
    },
    [id, space.Id, updateLsViews, viewPref]
  );

  useEffect(() => {
    if (!DownsizedPipelineStatusChooserListnerDict[id])
      DownsizedPipelineStatusChooserListnerDict[id] = [];

    const listner = (orderList) => {
      if (!orderList) updateLocalStorage(columns);
      else
        updateLocalStorage(
          calculateColumns(columns, orderList).map((e, i, z) => {
            return e.Id;
          })
        );
    };

    DownsizedPipelineStatusChooserListnerDict[id].push(listner);

    return () => {
      const index =
        DownsizedPipelineStatusChooserListnerDict[id].indexOf(listner);
      DownsizedPipelineStatusChooserListnerDict[id].splice(index, 1);
    };
  }, [columns, id, updateLocalStorage]);

  return resolvedColumn;
};

export const useToggleKanbanDownsize = (schema) => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const setTree = useContext(AppBarFavoriteTreeSetter);
  const space = useSpace();
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
	const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);
  const handleColumnChange = useCallback(
    (result) => {
      setTree((oldTree) => {
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items };
        const newItem = { ...newItems[FavoriteItem.id] };
        const newData = { ...newItem.data };
        newData.downsizedPipelineStatus = [...result];
        newItem.data = newData;
        newItems[FavoriteItem.id] = newItem;
        return {
          rootId,
          items: newItems
        };
      });
    },
    [FavoriteItem, setTree]
  );

  const handleViewColumnChange = useCallback(
    (result) => {
      let gridSavedViews = JSON.parse(
        window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
      );
      const viewGridViews = gridSavedViews[schema.name];
      const index = viewGridViews.findIndex((e) => e.id === viewPref);
      let resolvedIndex = index === -1 ? 0 : index;
      let viewGridObject = viewGridViews[resolvedIndex] || {};
      viewGridObject.downsizedPipelineStatus = result;
      viewGridViews[resolvedIndex] = viewGridObject;
      gridSavedViews[schema.name] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
      updateLsViews && updateLsViews();
    },
    [schema.name, space.Id, updateLsViews, viewPref]
  );

  if (FavoriteItem) {
    return handleColumnChange;
  } else {
    return handleViewColumnChange;
  }
};
