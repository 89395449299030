import React, { useMemo } from "react";
import {
  useDetailsEntityId,
  useDetailsEntity
} from "../../../Components/DetailsView/DetailsView";
import {
  DetailsEntities,
  DetailsClientEntity,
  DetailsContactEntity
} from "../../../Components/DetailsView/DetailsChildren/DetailsChildren";

export const SubscriptionDetailsChildren = () => {
  const Id = useDetailsEntityId();
  const { Client, IndirectClient, Contact } = useDetailsEntity() || {};

  const formProps = useMemo(() => {
    return { subscriptionId: Id };
  }, [Id]);

  return (
    <DetailsEntities formProps={formProps}>
      <DetailsClientEntity id={Client || IndirectClient} />
      <DetailsContactEntity id={Contact} />
      {/* <DetailsTicketQueryableEntities
          filter={`(DirectIndirectSubscription/Id eq ${Id})`}
        />
        <DetailsTaskQueryableEntities
          filter={`(DirectIndirectSubscription/Id eq ${Id}) and (Task/Attributes eq '${TasksStatusTypes.open}')`}
        /> */}
    </DetailsEntities>
  );
};
