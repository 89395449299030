import React, { useMemo } from "react";
import classnames from "classnames";
import { useBilling } from "../../Hooks/EntityHooks";
import { FormattedMessage } from "react-intl";
import KeyedDropdown from "../Dropdown/KeyedDropdown";
import { originTypes } from "../../Helpers/MiscHelper";
import {
  ClassificationAssignedToCompanyStatusDot,
  ClassificationAssignedToContractStatusDot,
  ClassificationAssignedToDealStatusDot,
  ClassificationAssignedToProjectStatusDot,
  ClassificationAssignedToWarrantyStatusDot,
  ClassificationBilledStatusDot,
  ClassificationInefficientStatusDot,
  ClassificationNotBilledStatusDot,
  ClassificationNotClassifiedStatusDot
} from "../StatusHelper/StatusHelper";

export const BillingStatusTypes = {
  ForClassification: 1,
  ForBilling: 2,
  Billed: 3,
  UnderContract: 4,
  UnderClient: 5,
  UnderProject: 6,
  UnderWarranty: 7,
  UnderDeal: 8,
  Inefficient: 9
};

export const BillingStatusNames = {
  1: "ForClassification",
  2: "ForBilling",
  3: "Billed",
  4: "UnderContract",
  5: "UnderClient",
  6: "UnderProject",
  7: "UnderWarranty",
  8: "UnderDeal",
  9: "Inefficient"
};

// eslint-disable-next-line no-unused-vars
const buildContractStatusType = (status) => {
  switch (Number(status)) {
    case BillingStatusTypes.ForBilling:
      return "purple";

    case BillingStatusTypes.Billed:
      return "success";

    case BillingStatusTypes.UnderContract:
      return "black";

    case BillingStatusTypes.UnderClient:
      return "black";

    case BillingStatusTypes.UnderProject:
      return "black";

    case BillingStatusTypes.UnderWarranty:
      return "black";

    case BillingStatusTypes.UnderDeal:
      return "black";

    case BillingStatusTypes.Inefficient:
      return "inactive";

    case BillingStatusTypes.ForClassification:
    default:
      return "orange";
  }
};

const BillingStatusSvgIcons = (status) => {
  switch (Number(status)) {
    case BillingStatusTypes.ForBilling:
      return <ClassificationNotBilledStatusDot />;

    case BillingStatusTypes.Billed:
      return <ClassificationBilledStatusDot />;

    case BillingStatusTypes.UnderContract:
      return <ClassificationAssignedToCompanyStatusDot />;

    case BillingStatusTypes.UnderClient:
      return <ClassificationAssignedToCompanyStatusDot />;

    case BillingStatusTypes.UnderProject:
      return <ClassificationAssignedToCompanyStatusDot />;

    case BillingStatusTypes.UnderWarranty:
      return <ClassificationAssignedToCompanyStatusDot />;

    case BillingStatusTypes.UnderDeal:
      return <ClassificationAssignedToCompanyStatusDot />;

    case BillingStatusTypes.Inefficient:
      return <ClassificationInefficientStatusDot />;

    case BillingStatusTypes.ForClassification:
    default:
      return <ClassificationNotClassifiedStatusDot />;
  }
};

const statusNameByValue = (status) => {
  switch (Number(status)) {
    case BillingStatusTypes.ForBilling:
      return <FormattedMessage id={"TO_BILL"} />;

    case BillingStatusTypes.Billed:
      return <FormattedMessage id={"BILLED"} />;

    case BillingStatusTypes.UnderContract:
      return <FormattedMessage id={"CONTRACT"} />;

    case BillingStatusTypes.UnderClient:
      return <FormattedMessage id={"BILLING_STATUS_CUSTOM"} />;

    case BillingStatusTypes.UnderProject:
      return <FormattedMessage id={"PROJECT"} />;

    case BillingStatusTypes.UnderWarranty:
      return <FormattedMessage id={"WARRANTY"} />;

    case BillingStatusTypes.UnderDeal:
      return <FormattedMessage id={"DEAL"} />;

    case BillingStatusTypes.Inefficient:
      return <FormattedMessage id={"INEFFICIENT"} />;

    case BillingStatusTypes.ForClassification:
    default:
      return <FormattedMessage id={"UNCLASSIFIED"} />;
  }
};

export const BillingStatusProfile = ({
  entity,
  status,
  item,
  hideName,
  className,
  hideNull
}) => {
  const resovledStatus = entity ? entity.Status : status || item;

  const SvgComponent = useMemo(() => {
    if (hideNull && !resovledStatus) return null;
    return BillingStatusSvgIcons(resovledStatus);
  }, [hideNull, resovledStatus]);

  if (hideNull && !resovledStatus) return null;

  return (
    <div
      className={classnames(
        `d-flex align-items-center text-truncate link-color`,
        className
      )}
    >
      <div className={classnames("position-relative status-dot-svg")}>
        {SvgComponent}
      </div>
      {!hideName && (
        <div className={classnames(`text-truncate fs-12 status-color-${buildContractStatusType(resovledStatus)}`)}>
          <div className="status-label">{statusNameByValue(resovledStatus)}</div>
        </div>
      )}
    </div>
  );
};

export const ConnectBillingEntity = (Component) => {
  return ({ billing, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const b = useBilling(billing);
    return <Component billing={b} {...rest} />;
  };
};

const getBillingStatuses = (origin) => {
  const type = origin && origin.type;
  switch (type) {
    case originTypes.project:
      return [
        BillingStatusTypes.ForBilling,
        BillingStatusTypes.Billed,
        BillingStatusTypes.UnderProject,
        BillingStatusTypes.UnderWarranty,
        BillingStatusTypes.Inefficient
      ];

    case originTypes.contract:
      return [
        BillingStatusTypes.ForBilling,
        BillingStatusTypes.Billed,
        BillingStatusTypes.UnderContract,
        BillingStatusTypes.UnderWarranty,
        BillingStatusTypes.Inefficient
      ];

    case originTypes.client:
      return [
        BillingStatusTypes.ForBilling,
        BillingStatusTypes.Billed,
        BillingStatusTypes.UnderClient,
        BillingStatusTypes.UnderWarranty,
        BillingStatusTypes.Inefficient
      ];

    case originTypes.deals:
      return [
        BillingStatusTypes.ForBilling,
        BillingStatusTypes.Billed,
        BillingStatusTypes.UnderWarranty,
        BillingStatusTypes.UnderDeal,
        BillingStatusTypes.Inefficient
      ];

    default:
      return [
        BillingStatusTypes.ForBilling,
        BillingStatusTypes.Billed,
        BillingStatusTypes.UnderContract,
        BillingStatusTypes.UnderClient,
        BillingStatusTypes.UnderProject,
        BillingStatusTypes.UnderWarranty,
        BillingStatusTypes.UnderDeal,
        BillingStatusTypes.Inefficient
      ];
  }
};

const BillingDropdownStatusProfileComp = (props) => {
  return <BillingStatusProfile {...props} hideNull />;
};

export const BillingStatusDropdown = (props) => {
  const { hideForClassification, origin } = props;
  //console.log(hideForClassification);
  const options = useMemo(() => {
    const options = getBillingStatuses(origin);

    if (!hideForClassification)
      options.unshift(BillingStatusTypes.ForClassification);
    var filtered = options.filter(Boolean);

    return filtered;
  }, [hideForClassification, origin]);

  return (
    <KeyedDropdown
      options={options}
      valueComponent={BillingDropdownStatusProfileComp}
      closeOnSelect
      {...props}
    />
  );
};
