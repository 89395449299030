import React, { useMemo, useEffect, useRef } from "react";
import "./Breadcrumbs.css";
import classnames from "classnames";
import { ProjectStatusProfile } from "../Projects/ProjectProfile";
import {
  ClientStatusProfile,
  ClientStatusTypes
} from "../Clients/ClientProfile";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import { useSpaceQuery } from "../../Helpers/IOClient";
import {
  ContractStatusProfile,
  ContractStatusTypes
} from "../Contract/ContractProfile";
import { originTypes } from "../../Helpers/MiscHelper";
import DealStatusProfile from "../Deals/DealStatusProfile";
import {
  spaceClientSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceSubscriptionSchema
} from "../../config/schema";
import moment from "moment";
import { DealStatusTypes } from "../../Containers/Deals/DealsHelper";
import { ProjectStatusTypes } from "../../Containers/Projects/ProjectsStatusHelper";
import { ConnectedAccountPopup } from "../../Containers/Origin/AcountPopup";

const PipelineHistory = ({ items, className, schema }) => {
  const [ResolvedPipelineArr, ResolvedPipelineObj] = useMemo(() => {
    if (!items) return [];
    let index = 0;
    const PipelineArr = [];
    const PipelineObj = {};
    for (const item of items) {
      if (!PipelineArr[index]) {
        PipelineArr.push(item.Pipeline);
        PipelineObj[index] = [item];
      } else if (PipelineArr[index].Id !== item.Pipeline.Id) {
        index++;
        PipelineArr.push(item.Pipeline);
        PipelineObj[index] = [item];
      } else {
        const temp = PipelineObj[index];
        PipelineObj[index] = [...temp, item];
      }
    }
    return [PipelineArr, PipelineObj];
  }, [items]);

  if (ResolvedPipelineArr.length === 0) return null;

  return (
    <div className={classnames("ar-breadcrumbs w-100", className)}>
      {ResolvedPipelineArr.map((item, index) => {
        const { Id, Name } = item;
        const PipelineStatuses = ResolvedPipelineObj[index];
        return (
          <React.Fragment key={`${Name}-${Id}-${index}`}>
            <PipelineItem item={item} />
            {PipelineStatuses.map((status, ind) => {
              const { Id: StatusId, Name: StatusName } = status.PipelineStatus;
              const { Account } = status;
              return (
                <PipelineStatusItem
                  key={`${StatusName}-${StatusId}-${ind}`}
                  item={status}
                  account={Account}
                  schema={schema}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const PipelineItem = ({ item }) => {
  const {
    // Id,
    Name
  } = item;
  return (
    <>
      <div className="ar-breadcrumbs-item w-100  d-flex align-items-center text-black">
        <div className="ar-breadcrumbs-item w-100 d-flex align-items-center text-black">
          <div className="link-color status-color-pipeline ar-breadcrumbs-circle mr-2 d-flex align-items-center">
            <div className="position-relative status-dot"></div>
          </div>
          <div
            style={{ background: "transparent" }}
            className={classnames(
              "d-flex flex-column ar-breadcrumbs-detail rounded mr-2 justify-content-between"
            )}
          >
            <div className="d-inline-flex align-items-center">
              <div className="d-flex flex-1">
                <span className="fs-12 text-truncate text-black fw-medium">
                  {Name}
                </span>
              </div>
            </div>
          </div>
          <div className="flex-1 w-100">
            <hr />
          </div>
        </div>
      </div>
    </>
  );
};

const PipelineStatusItem = ({ item, schema, account }) => {
  const { Date: PipelineDate, PipelineStatus } = item;
  const { Name, Id, Status } = PipelineStatus;
  const Type = schema.name;

  return (
    <>
      <div className="ar-breadcrumbs-item w-100 d-flex align-items-center text-black">
        <div className="ar-breadcrumbs-item w-100 d-flex align-items-center text-black">
          {Type === spaceClientSchema.name ? (
            <ClientStatusProfile
              sensitive
              hideName
              value={Status}
              className="ar-breadcrumbs-circle mr-2"
            />
          ) : Type === spaceContractSchema.name ||
            Type === spaceSubscriptionSchema.name ? (
            <ContractStatusProfile
              sensitive
              hideName
              status={Status}
              className="ar-breadcrumbs-circle mr-2"
            />
          ) : Type === spaceDealsSchema.name ? (
            <DealStatusProfile
              sensitive
              hideName
              status={Status}
              className="ar-breadcrumbs-circle mr-2"
            />
          ) : (
            <ProjectStatusProfile
              sensitive
              hideName
              status={Status}
              className="ar-breadcrumbs-circle mr-2"
            />
          )}
          <div
            style={{ background: "transparent", minWidth: "30%" }}
            className={classnames(
              "d-flex flex-column ar-breadcrumbs-detail text-truncate rounded flex-1 justify-content-between"
            )}
            key={Id}
          >
            <div className="d-inline-flex text-truncate align-items-center">
              <div className="d-flex flex-1 text-truncate">
                <span className="fs-12 text-truncate">{Name}</span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end text-truncate align-items-center">
            {account && (
              <ConnectedAccountPopup
                noName
                className="mr-2 text-truncate"
                account={account.Id}
              />
            )}
            <ColorDateComponent
              PipelineDate={PipelineDate}
              Type={Type}
              Status={Status}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const ColorDateComponent = ({ Status, Type, PipelineDate }) => {
  switch (Type) {
    case spaceDealsSchema.name:
      switch (Status) {
        case DealStatusTypes.canceled:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-grey py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case DealStatusTypes.draft:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-orange py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case DealStatusTypes.lost:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-red py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case DealStatusTypes.open:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-purple py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case DealStatusTypes.won:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-green py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );

        default:
          return <div></div>;
      }
    case spaceSubscriptionSchema.name:
    case spaceContractSchema.name:
      switch (Status) {
        case ContractStatusTypes.canceled:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-grey py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case ContractStatusTypes.draft:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-orange py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case ContractStatusTypes.open:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-purple py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case ContractStatusTypes.closed:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-blue py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );

        default:
          return <div></div>;
      }

    case spaceClientSchema.name:
      switch (Status) {
        case ClientStatusTypes.disabled:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-grey py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case ClientStatusTypes.onApproval:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-orange py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case ClientStatusTypes.active:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-purple py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case ClientStatusTypes.blocked:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-red py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );

        default:
          return <div></div>;
      }

    default:
      switch (Status) {
        case ProjectStatusTypes.canceled:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-grey py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case ProjectStatusTypes.draft:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-orange py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case ProjectStatusTypes.open:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-purple py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );
        case ProjectStatusTypes.finished:
          return (
            <div className="text-truncate px-2 ar-breadcrumbs-date-text-green py-1 flex-0-0-auto rounded text-truncate">
              {moment(PipelineDate).format("DD MMM, YYYY - HH:mm")}
            </div>
          );

        default:
          return <div></div>;
      }
  }
};

export const ConnectedBreadcrumbs = ({
  value,
  highlightValue,
  className,
  onLoad
}) => {
  let endpoint = useMemo(() => {
    if (value) {
      if (value.type !== originTypes.client)
        return `breadcrumbs/${value.type}/${value.data}`;
    }
    return null;
  }, [value]);

  const { data, error, loading, refetch } = useSpaceQuery(endpoint, null, {
    autoFetch: false,
    onSuccess: () => {
      onLoad && onLoad();
    }
  });
  const isBuiltRef = useRef(false);
  useEffect(() => {
    if (!isBuiltRef.current) {
      isBuiltRef.current = true;
      refetch();
    }
  }, [refetch]);

  if (!value || error || !endpoint) return null;

  if (loading)
    return (
      <LoaderSpinner
        centerHorizontal
        size="xs"
        className={classnames("breadcrumps-loader-c text-secondary", className)}
      />
    );

  return (
    <PipelineHistory
      highlightValue={highlightValue}
      items={data}
      className={className}
    />
  );
};

export default PipelineHistory;
