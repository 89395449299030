import React from "react";

import { spaceProjectsSchema } from "../../../config/schema";

import {
  EntitySummaryTabstrip,
  EntitySummaryTab,
  EntitySummaryOverview,
} from "../../../Components/EntitySummaries/EntitySummaries";
import { History } from "../../../Components/DetailsView/History/History";
import ProjectForm from "../ProjectForm";
import { ProjectEntityActionsButton } from "../ProjectsHelper";
import ProjectSummaryContent from "./ProjectSummaryContent";
import Treeview from "../../Treeview/Treeview";
import { ProjectDetailsChildren } from "../ProjectDetails/ProjectDetailsChildren";
import { RealTimeRoomTypes } from "../../RealTime/RealTimeHelper";
import { FormattedMessage } from "react-intl";
import { ProjectFormButton } from "../../Form/FormButtons";

export const InnerProjectSummary = ({ value }) => {
  return (
    <EntitySummaryTabstrip className="mt-2 mb-3">
      <EntitySummaryTab titleId="SUMMARY">
        <ProjectSummaryContent value={value} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="HISTORY">
        <History project={value.Id} disableTabs />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ORIGINATED">
        <Treeview
          className="flex-1"
          schema={spaceProjectsSchema}
          id={value.Id}
        />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ASSOCIATIONS">
        <ProjectDetailsChildren value={value} />
      </EntitySummaryTab>
    </EntitySummaryTabstrip>
  );
};

const ProjectSummary = (props) => {
  return (
    <EntitySummaryOverview
      {...props}
      url={"projects"}
      type="project"
      schema={spaceProjectsSchema}
      FormComponent={ProjectForm}
      Summary={InnerProjectSummary}
      creationHeaderText={<FormattedMessage id="CREATE_PROJECT" />}
      ActionButton={ProjectEntityActionsButton}
      FormButton={ProjectFormButton}
      roomType={RealTimeRoomTypes.Project}
    ></EntitySummaryOverview>
  );
};

export default ProjectSummary;
