import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./EntityOverview.css";
import classnames from "classnames";
import RoundedBadge from "../RoundedBadge/RoundedBadge";
import { useSpaceCurrenciesFetcher } from "../Currency/Currency";
import { EntitySummaryAccordion } from "../EntitySummaries/EntitySummaries";
import DealCostSelector from "../../Containers/Deals/DealCostSelector";
import { createDealEstimatedCostDict } from "../../Containers/Deals/DealsHelper";
import EntityChildrenProgress from "../../Containers/EntityChildrenProgress/EntityChildrenProgress";
import { faFolderTree } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage } from "react-intl";

export const EntityOverviewItem = ({
  icon,
  title,
  style,
  titleId,
  children,
  className
}) => {
  return (
    <div className="ar-overview-item d-flex align-items-center">
      <div className="ar-overview-item-title">
        {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
        {titleId ? <FormattedMessage id={titleId} /> : title}
      </div>
      <div className={classnames("of-hidden", className)}>{children}</div>
    </div>
  );
};

export const EntityDescriptionOverviewItem = ({
  icon,
  title,
  titleId,
  children
}) => {
  return (
    <div className="ar-overview-item">
      <div className="text-truncate">
        <FontAwesomeIcon className="mr-2" icon={icon} />
        {titleId ? <FormattedMessage id={titleId} /> : title}
      </div>
      <div>{children}</div>
    </div>
  );
};

export const EntityOverviewRoundedItem = ({
  children,
  badgeClassName,
  ...rest
}) => {
  return (
    <EntityOverviewItem {...rest}>
      <RoundedBadge className={badgeClassName}>{children}</RoundedBadge>
    </EntityOverviewItem>
  );
};

export const EntityOverviewPlaceholder = ({ rounded, className }) => {
  if (rounded) return <RoundedBadge className={className}>-</RoundedBadge>;
  return "-";
};

const CurrencyValuesAccordionDetail = ({
  currencies,
  EstimatedCosts,
  titleId
}) => {
  const dict = useMemo(
    () => createDealEstimatedCostDict(EstimatedCosts, currencies),
    [EstimatedCosts, currencies]
  );

  return (
    <EntitySummaryAccordion textId={titleId || "VALUE"}>
      <DealCostSelector value={dict} currencies={currencies} preview />
    </EntitySummaryAccordion>
  );
};

export const CurrencyValuesAccordion = ({ EstimatedCosts, titleId }) => {
  const { loading, error, data: currencies } = useSpaceCurrenciesFetcher();

  if (loading || error || !EstimatedCosts) return null;

  return (
    <CurrencyValuesAccordionDetail
      titleId={titleId}
      currencies={currencies}
      EstimatedCosts={EstimatedCosts}
    />
  );
};

export const EntityOverviewChildrenProgress = ({ schema, id, forcedType }) => {
  return (
    <EntityOverviewItem
      className="w-100"
      icon={faFolderTree}
      titleId="ORIGINATED"
    >
      <EntityChildrenProgress
        schema={schema}
        forcedType={forcedType}
        placement="right"
        id={id}
        placeholder={
          <EntityOverviewPlaceholder className="d-inline-block" rounded />
        }
      />
    </EntityOverviewItem>
  );
};
