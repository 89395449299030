import React from "react";
import { usePipelineStatus, useTicket } from "../../Hooks/EntityHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDrawCircle,
  faSuitcase,
  faUserCheck,
  faUsers,
  faUsersMedical,
  faUserTie
} from "@fortawesome/pro-solid-svg-icons";
import { spaceTicketsSchema } from "../../config/schema";

import { ConnectedTeamProfile } from "../../Components/Teams/TeamProfile";
import { ConnectedDepartmentProfile } from "../../Components/Departments/DepartmentProfile";
import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";
import SplitCol from "../../Components/SplitCol/SplitCol";
import { ConnectTicketEntity } from "../../Components/Tickets/TicketProfile";
import { ProjectStatusProfileWithIcon } from "../../Components/Projects/ProjectProfile";
import OriginOverview from "./OriginOverview";
import {
  OriginContainer,
  OriginDate,
  OriginHeader,
  OriginItemGroup
} from "../../Components/Origin/Origin";
import { createOriginPopup } from "./Origin";
import { ConnectedClientOrigin } from "./ClientOrigin";
import { ConnectedAccountPopup } from "./AcountPopup";
import { ConnectedContactPopup } from "./ContactPopup";
import {
  TicketEntityGridButtons,
  TicketsStatusTypes
} from "../Tickets/TicketsHelper";
import classnames from "classnames";
import { TicketIcon } from "../../Components/Common/EntityIcons";
import { useIntl } from "react-intl";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import Button from "../../Components/Button/Button";
import { PipelineStatusProfile } from "../AdvancedMultiInputs/PipelineDropdowns";
import { faTicketSimple } from "@fortawesome/pro-solid-svg-icons";
import { useSpace } from "../../Contexts/SpaceContext";
import { useConnectedSchemaGetter } from "../../Helpers/HelperHooks";

export const TicketOriginAnchor = React.forwardRef(
  ({ ticket, children, onClick, dynamicFont, ...rest }, ref) => {
    const openForm = useEntitySummarySidebar();
    if (!ticket) return null;
    const { IsDeleted } = ticket;
    return (
      <div
        ref={ref}
        className={classnames("link-color text-truncate", {
          "item-was-deleted": IsDeleted
        })}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <>
            <div
              className={classnames(
                "link-color d-flex align-items-center text-truncate ",
                {
                  "fs-12": !dynamicFont
                }
              )}
            >
              <div className="d-inline-flex align-items-center mr-1">
                <ProjectStatusProfileWithIcon
                  Icon={<FontAwesomeIcon icon={faTicketSimple} />}
                  inline
                  hideName
                  status={ticket.Status || ticket.Attributes}
                />
              </div>
              <Button
                vType="link-primary"
                className="EntityLinkClassID fs-12 text-black"
                onClick={() => {
                  openForm({
                    type: moduleTypes.tickets,
                    props: {
                      id: ticket.Id
                    }
                  });
                }}
              >
                <span>{ticket.Name}</span>
              </Button>
            </div>
            {/* {Id && <div className="fs-10">Nº: {Id}</div>} */}
          </>
        )}
      </div>
    );
  }
);
export const ConnectedTicketOriginAnchor = ({ ticket, ...rest }) => {
  const resolvedTicket = useTicket(ticket);
  const Result = useConnectedSchemaGetter(ticket, spaceTicketsSchema);

  if (Result) return Result;
  return <TicketOriginAnchor ticket={resolvedTicket} {...rest} />;
};

export const TicektOriginPopup = ({ ticket }) => {
  const intl = useIntl();
  const t = useTicket(ticket.Id);
  const space = useSpace();
  const {
    Name,
    Id,
    Client,
    // Status,
    IndirectClient,
    Contact,
    PipelineStatus,
    ManagerAccount,
    Team,
    Department,
    ReceivedDate,
    Creator,
    CreationDate
  } = t;
  const connectedPipelineStatus = usePipelineStatus(PipelineStatus);
  const buildDepartmentTeam = () => {
    if (Team) return <ConnectedTeamProfile team={Team} />;
    else if (Department)
      return <ConnectedDepartmentProfile department={Department} />;
    else return null;
  };
  const DepartComponent = buildDepartmentTeam();
  return (
    <OriginContainer>
      <OriginHeader
        Dif
        className="mb-1"
        date={CreationDate}
        type="Ticket"
        entity="CASE"
        Icon={<TicketIcon className="mr-2" />}
        EntityButtons={<TicketEntityGridButtons noColors entity={t} minimal />}
        id={Id}
        link={`/s/${space.Id}/tickets/${Id}`}
        title={Name}
      />
      <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
        <PipelineStatusProfile pipeline={connectedPipelineStatus} />

        <div>
          <OriginDate className="fs-12" date={ReceivedDate} />
        </div>
      </div>
      {/* <OriginItemGroup className="mb-1" title="Estado">
        <ProjectStatusProfile status={Status} />
      </OriginItemGroup> */}

      {(Client || IndirectClient) && !Contact && (
        <OriginItemGroup
          icon={faSuitcase}
          title={intl.formatMessage({ id: "ACCOUNT" })}
        >
          <ConnectedClientOrigin client={Client || IndirectClient}>
            <ConnectedClientProfile showId client={Client || IndirectClient} />
          </ConnectedClientOrigin>
        </OriginItemGroup>
      )}

      <SplitCol
        show={(Client || IndirectClient) && Contact}
        className="mb-1"
        left={
          <OriginItemGroup
            icon={faSuitcase}
            title={intl.formatMessage({ id: "ACCOUNT" })}
          >
            <ConnectedClientOrigin client={Client || IndirectClient}>
              <ConnectedClientProfile
                showId
                client={Client || IndirectClient}
              />
            </ConnectedClientOrigin>
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUsersMedical}
            title={intl.formatMessage({ id: "CREATED_BY" })}
          >
            <ConnectedAccountPopup account={Creator} />
          </OriginItemGroup>
        }
      />
      <SplitCol
        className="mb-1"
        left={
          <OriginItemGroup
            icon={faUserTie}
            title={intl.formatMessage({ id: "CONTACT" })}
          >
            <ConnectedContactPopup contact={Contact} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUsers}
            title={intl.formatMessage({ id: "DEPARTMENT_TEAM" })}
          >
            {DepartComponent}
          </OriginItemGroup>
        }
      />
      {!DepartComponent && (
        <OriginItemGroup
          icon={faDrawCircle}
          title={intl.formatMessage({ id: "ORIGIN" })}
        >
          <OriginOverview entity={t} />
        </OriginItemGroup>
      )}

      <SplitCol
        show={DepartComponent}
        className="mb-1 fs-12"
        left={
          <OriginItemGroup
            icon={faUserCheck}
            title={intl.formatMessage({ id: "ASSIGNED_TO" })}
          >
            <ConnectedAccountPopup account={ManagerAccount} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faDrawCircle}
            title={intl.formatMessage({ id: "ORIGIN" })}
          >
            <OriginOverview entity={t} />
          </OriginItemGroup>
        }
      />
      <div className="pb-3"></div>
      {/* <div className="d-flex align-items-center justify-content-center shadow-top py-3 mt-3">
        <TicketEntityButtons
          //   className="mt-3 mb-3"

          entity={t}
        />
      </div> */}
    </OriginContainer>
  );
};

export const ticketOriginOptions = {
  schema: spaceTicketsSchema,
  valueProp: "ticket"
};

const TicketOrigin = createOriginPopup(
  TicketOriginAnchor,
  TicektOriginPopup,
  ticketOriginOptions
);

export default TicketOrigin;

export const ConnectedTicketOrigin = ConnectTicketEntity(TicketOrigin);

export const DifrentTicketProfile = ({ ticket }) => {
  if (!ticket) return null;
  const { Status } = ticket;

  let colorStatus;

  switch (Status) {
    case TicketsStatusTypes.open:
      colorStatus = "status-in-progress-colors";
      break;
    case TicketsStatusTypes.canceled:
      colorStatus = "status-canceled-colors";
      break;
    case TicketsStatusTypes.closed:
      colorStatus = "status-closed-colors";
      break;
    case TicketsStatusTypes.draft:
      colorStatus = "status-orange-colors";
      break;
    default:
      break;
  }

  return (
    <div className={classnames("air-new-profiles-circle", colorStatus)}>
      <FontAwesomeIcon icon={faTicketSimple} size="xl" />
    </div>
  );
};
