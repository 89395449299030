import React from "react";
import classnames from "classnames";
import classes from "./ApiKeyLogs.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";

export const ApiKeyLogResponseCode = ({ responseCode }) => {
  if (!responseCode) return null;

  const reqLetter = responseCode.toString().charAt(0);

  let error, success;
  let responseText;
  switch (reqLetter) {
    case "2":
      success = true;
      responseText = "OK";
      break;

    case "4":
    case "5":
      responseText = "ERR";
      error = true;
      break;

    default:
      break;
  }

  return (
    <div className={classnames("d-flex align-items-center")}>
      <span className={"mr-2 text-black"}>{responseCode}</span>
      <div
        className={classnames(classes.Badge, "d-flex align-items-center", {
          [classes.error]: error,
        })}
      >
        <FontAwesomeIcon
          className="mr-2"
          icon={success ? faCheckCircle : faExclamationCircle}
        />
        <div className={`${classes.text} fs-12 fw-medium`}>{responseText}</div>
      </div>
    </div>
  );
};
