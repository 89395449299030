import React from "react";
import classnames from "classnames";
const OnboardingContainer = ({ children, className }) => {
  return (
    <div className={classnames("h-100 overflow-auto", className)}>
      {children}
    </div>
  );
};

export default OnboardingContainer;
