import React from "react";

export const VirtualGridColumnChooserContext = React.createContext();

const VirtualGridColumnChooserProvider = ({ setModalOpen, children }) => {
  return (
    <VirtualGridColumnChooserContext.Provider value={setModalOpen}>
      {children}
    </VirtualGridColumnChooserContext.Provider>
  );
};

export default VirtualGridColumnChooserProvider;
