import React from "react";
import { useFormState } from "../Forms";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
} from "../Sidebar/SidebarV2Helper";
import { FormattedMessage } from "react-intl";
import {
  SideForm,
  SideFormContent,
  SideFormFooter,
} from "../Forms/SideMenuForm";
import FormInput from "../Forms/FormInput/FormInput";
import KeyedDropdown from "../Dropdown/KeyedDropdown";
import { MilSecToTime } from "../../Helpers/MiscHelper";

const ImportTypeValue = ({ item }) => <span>{MilSecToTime(item, true)}</span>;

const DashboardAutoUpdateSidebar = ({
  UpdateTimeout,
  Timeout,
  closeSidebar,
}) => {
  const formState = useFormState(() => {
    const form = {};

    // if (AvailableSettings.scope) form.Scope = "";
    form.Timeout = Timeout;
    return { ...form };
  });

  const handleSubmit = (form) => {
    localStorage.setItem("air-dash-update", form.Timeout);
    UpdateTimeout(form.Timeout);

    closeSidebar();
  };

  const IntervalOption = [
    900000,
    1800000,
    2700000,
    3600000,
    10800000,
    21600000,
    43200000,
    86400000,
  ];

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"SETTINGS"} /> -{" "}
        <FormattedMessage id={"AUTO_REFRESH"} />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideForm canEdit={true} formState={formState} onSubmit={handleSubmit}>
          <SideFormContent className="px-3 pt-3 pb-5">
            <FormInput
              className="mb-3"
              name="Timeout"
              closeOnSelect
              options={IntervalOption}
              valueComponent={ImportTypeValue}
              text={<FormattedMessage id={"INTERVAL"} />}
              inputType={KeyedDropdown}
            />
          </SideFormContent>
          <SideFormFooter></SideFormFooter>
        </SideForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
  // if (loading || error)
  //   return <LoaderSpinner className="text-primary" size="sm" />;

  // return <PipelineFormContent {...props} pipeline={pipeline} />;
};

export default DashboardAutoUpdateSidebar;
