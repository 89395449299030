import React, { Component } from "react";
import AirdeskUploaderSvg from "./AirdeskUploaderSvg";
import { FileUploaderSpinner as Spinner } from "./FileUploaderSpinner";
import "./FileUploader.css";
import { ClipboardContext } from "../ClipbordProvider/ClipboardProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTh,
  faBars,
  faTrashAlt,
  faFileImage,
  faFilePdf,
  faFileExcel,
  faFileWord,
  faFileVideo,
  faFile,
  faDownload,
  faTimes,
  faEnvelope,
  faTag
} from "@fortawesome/pro-light-svg-icons";
import { withSpace } from "../../Contexts/SpaceContext";
import moment from "moment";
import Modal from "../Modal/Modal";
import classnames from "classnames";
import EmailPreview from "./EmailPreview";
import ImagePreview from "./ImagePreview";
import PdfPreview from "./PdfPreview";
import OfficePreview from "./OfficePreview";
import { FormattedMessage } from "react-intl";
import { UpploadReact } from "./Upploader";
import VideoPreview from "./VideoPreview";
import FileUploadText from "./FileUploadText";
import DumbFilesPreview from "./DumbFilesPreview";
/**
 * @param {int} maxFiles - Max amount of files to upload
 * @param {string} className - Class to add to fileuploader container
 * @param {function} onChange - Callback when file is uploaded
 * @param {string} enableSwapper - Adds the header buttons to switch between extended mode and normal mode
 * @param {string} title - Adds the title given into the file uploader header
 * @param {string} asyncUpload - Files will be uploaded asynchrony
 * @param {string} allowDirectory - Allow the upload of file directories
 */

const AcceptedFileExtensions = {
  pdf: true,
  ppt: true,
  pptx: true,
  doc: true,
  docx: true,
  txt: true,
  msg: true,
  html: true,
  otf: true,
  css: true,
  png: true,
  jpg: true,
  jpeg: true,
  rpt: true,
  ttf: true,
  owf: true,
  mp4: true,
  wav: true,
  ods: true,
  xls: true,
  svg: true,
  xlsx: true,
  mpa: true,
  ogg: true,
  wma: true,
  mid: true,
  midi: true,
  zip: true,
  "7z": true,
  rar: true,
  csv: true,
  sql: true,
  xml: true,
  log: true,
  eml: true,
  pst: true,
  email: true,
  ps: true,
  psd: true,
  tif: true,
  tiff: true,
  webp: true,
  ico: true,
  bmp: true,
  ai: true,
  gif: true,
  odp: true,
  h264: true,
  m4v: true,
  mpg: true,
  mp3g: true,
  wmv: true,
  avi: true,
  flv: true,
  "3gp": true,
  mov: true,
  h265: true,
  rtf: true,
  odt: true
};
class FileUploader extends Component {
  constructor(props) {
    super(props);
    var extendedMode = localStorage.getItem("ar-FileUploader--ExtendedMode");

    this.state = {
      Files: [],
      GivenFiles: "",
      Value: "",
      AsyncUpload: true,
      ImageWidth: "",
      TagEditors: [],
      PreviewModalOpenStatus: false,
      PreviewModalFile: false,
      GridView: props.ForcedGrid ? true : false,
      ExtendedMode: props.extendedMode || extendedMode === "true" || false,
      DragEntered: false,
      DisableExtraButtons: props.DisableExtraButtons ? true : false,
      WarningFileTooBigMessage: false,
      FileNotSupported: false
    };
    if (this.state.GridView) {
      this.setState({ ExtendedMode: false });
    }
  }

  static contextType = ClipboardContext;

  getOnChangeValue = (value) => {
    return value;
  };

  enableTagEdit = (index) => {
    const newTagEditors = [...this.state.TagEditors];
    newTagEditors.push(index);
    this.setState({ TagEditors: newTagEditors });
  };

  disableTagEdit = (index) => {
    const newTagEditors = [...this.state.TagEditors];
    const i = newTagEditors.findIndex((e) => e === index);
    if (i !== -1) {
      newTagEditors.splice(i, 1);
      this.setState({ TagEditors: newTagEditors });
    }
  };

  onSaveTagEdition = (index) => {
    const input = document.getElementById(`airdes-file-tag-input-${index}`);
    const value = input?.value || "";

    const { onChange } = this.props;
    let arr = [...this.state.Files];
    arr[index].Label = value;
    if (onChange) {
      onChange(this.getOnChangeValue(arr));
    } else {
      this.setState({ Files: arr }, () => {
        if (typeof this.props.onChange === "function")
          this.props.onChange(this.getOnChangeValue(this.state.Files));
      });
    }

    this.disableTagEdit(index);
  };

  clearImage = (index) => {
    const { onChange } = this.props;
    let arr = [...this.state.Files];
    arr.splice(index, 1);

    if (onChange) {
      onChange(this.getOnChangeValue(arr));
    } else {
      this.setState({ Files: arr }, () => {
        if (typeof this.props.onChange === "function")
          this.props.onChange(this.getOnChangeValue(this.state.Files));
      });
    }
  };

  download_file(fileURL, fileName) {
    // ;
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_self";

      save.download = fileName;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  forceDownload = (index, File = {}) => {
    // ;
    var file_path = File.Url || this.state.Files[index].Url;
    var file_name = File.Name || this.state.Files[index].Name;
    var file_Extension = File.Extension || this.state.Files[index].Extension;
    this.download_file(file_path + "&inline=false", file_name + file_Extension);
  };

  fileUpload = (file) => {
    if (Array.isArray(file)) {
      if (this.state.AsyncUpload) {
        this.processFile(file);
      } else {
        this.updateFile(file);
      }
    } else {
      const data = [file];
      if (this.state.AsyncUpload) {
        this.processFile(data);
      } else {
        this.updateFile(data);
      }
    }
  };

  inputFile = (event) => {
    event.preventDefault();
    this.WarningMessage = null;
    const data = event.currentTarget.files;

    let FileTooBig = false;
    let FileNotSupported = false;
    for (const fi of data) {
      const fileExtension = fi.name.match(/[^.]+$/)[0];
      if (fi.size > 41943040) {
        FileTooBig = true;
      }
      if (!AcceptedFileExtensions[fileExtension?.toLowerCase()])
        FileNotSupported = true;
    }
    if (FileTooBig) {
      this.setState({ WarningFileTooBigMessage: true });
      return;
    }
    if (FileNotSupported) {
      this.setState({ FileNotSupported: true });
      return;
    }

    if (this.state.FileNotSupported) {
      this.setState({ FileNotSupported: false });
    }

    if (this.state.WarningFileTooBigMessage) {
      this.setState({ WarningFileTooBigMessage: false });
    }

    if (this.state.AsyncUpload) {
      this.processFile(data);
    } else {
      this.updateFile(data);
    }
    event.target.value = "";
  };

  drop = async (event) => {
    event.preventDefault();
    this.WarningMessage = null;
    let data = event.dataTransfer.files;
    let FileTooBig = false;
    let FileNotSupported = false;
    for (const fi of data) {
      const fileExtension = fi.name.match(/[^.]+$/)[0];
      if (fi.size > 41943040) {
        FileTooBig = true;
      }
      if (!AcceptedFileExtensions[fileExtension?.toLowerCase()])
        FileNotSupported = true;
    }
    if (FileTooBig) {
      this.setState({ WarningFileTooBigMessage: true });
      return;
    }

    if (FileNotSupported) {
      this.setState({ FileNotSupported: true });
      return;
    }

    if (this.state.FileNotSupported) {
      this.setState({ FileNotSupported: false });
    }

    if (this.state.WarningFileTooBigMessage) {
      this.setState({ WarningFileTooBigMessage: false });
    }

    // ;
    let containsDirectories = false;
    const toRemoveIndex = [];
    if (this.props.accept && Array.isArray(this.props.accept)) {
      const string = this.props.accept.join("|");
      const regex = RegExp(`(${string})`);
      for (let i = 0; i < data.length; i++) {
        const extension = data[i].name.match(/.[\w]+$/g)[0];
        if (!regex.test(extension)) {
          toRemoveIndex.push(i);
        }
        if (extension.charAt(0) !== ".") containsDirectories = true;
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        const extension = data[i].name.match(/.[\w]+$/g)[0];

        if (extension.charAt(0) !== ".") containsDirectories = true;
      }
    }

    if (toRemoveIndex.length > 0) {
      let Indexes = [];
      for (let i = 0; i < data.legnth; i++) {
        Indexes.push(i);
      }
      Indexes = Indexes.filter((item) => {
        return !toRemoveIndex.includes(item);
      });
      const resolvedData = Indexes.map((e) => {
        return data[e];
      });

      if (resolvedData.length === 0) {
        this.WarningMessage = true;
        this.handleDropZoneLeave();
      } else if (this.state.AsyncUpload) {
        this.setState({ DragEntered: false });
        this.processFile([resolvedData[0]]);
      } else {
        this.setState(
          { DragEntered: false },
          this.updateFile([resolvedData[0]])
        );
      }
    } else if (containsDirectories) {
      this.setState({ DragEntered: false });
      this.processFile(await this.getFilesAsync(event.dataTransfer));
    } else if (this.state.AsyncUpload) {
      this.setState({ DragEntered: false });
      this.processFile(data);
    } else {
      this.setState({ DragEntered: false }, this.updateFile(data));
    }
  };

  processFile = async (data) => {
    try {
      for (const file of data) {
        await this.asyncUpdateFile(file);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  async getFilesAsync(dataTransfer) {
    const files = [];
    for (let i = 0; i < dataTransfer.items.length; i++) {
      const item = dataTransfer.items[i];

      if (item.kind === "file") {
        if (typeof item.webkitGetAsEntry === "function" && item.type === "") {
          if (this.props.allowDirectory) {
            const entry = item.webkitGetAsEntry();
            const entryContent = await this.readEntryContentAsync(entry);
            files.push(...entryContent);
          }
          continue;
        }

        const file = item.getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }

    return files;
  }

  async readEntryContentAsync(entry) {
    return new Promise((resolve, reject) => {
      let reading = 0;
      const contents = [];

      var readReaderContent = (reader) => {
        reading++;

        reader.readEntries(function (entries) {
          reading--;
          for (const entry of entries) {
            readEntry(entry);
          }

          if (reading === 0) {
            resolve(contents);
          }
        });
      };

      var readEntry = (entry) => {
        if (entry.isFile) {
          reading++;
          entry.file((file) => {
            reading--;
            contents.push(file);

            if (reading === 0) {
              resolve(contents);
            }
          });
        } else if (entry.isDirectory) {
          readReaderContent(entry.createReader());
        }
      };

      readEntry(entry);
    });
  }

  updateFile = (data) => {
    if (data == null || data.length === 0 || data[0].size === 0) return;
    if (
      this.props.maxFiles !== undefined &&
      this.props.maxFiles === this.state.Files.length
    )
      return;
    let FileTooBig = false;
    for (const fi of data) {
      if (fi.size > 41943040) {
        FileTooBig = true;
      }
    }
    if (FileTooBig) {
      this.setState({ WarningFileTooBigMessage: true });
      return;
    }

    if (this.state.WarningFileTooBigMessage) {
      this.setState({ WarningFileTooBigMessage: false });
    }

    for (let i = 0; i < data.length; i++) {
      const file = data[i];
      const reader = new FileReader();

      reader.onabort = (e) => {
        this.updateState({
          Loading: false,
          Abort: true,
          dataKey:
            file.name + "" + file.lastModified + "" + file.size + "(" + i + ")"
        });
      };

      reader.onprogress = (evt) => {
        const extension = file.name.match(/.[\w]+$/g)[0];
        var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
        if (percentLoaded !== 100) {
          this.updateState({
            Data: "todo",
            TimeM: "uploading",
            Size: file.size,
            dataKey:
              file.name +
              "" +
              file.lastModified +
              "" +
              file.size +
              "(" +
              i +
              ")",
            Name: file.name
              .substring(0, file.name.length - extension.length)
              .substring(0, 100),
            Extension: extension,
            File: "todo",
            imagePreviewUrl: "todo",
            Loading: true,
            LoadingPercentage: percentLoaded,
            Abort: false,
            Reader: reader
          });
        }
      };

      reader.onloadstart = (e) => {
        const extension = file.name.match(/.[\w]+$/g)[0];
        this.updateState({
          Data: "todo",
          TimeM: "uploading",
          Size: file.size,
          dataKey:
            file.name + "" + file.lastModified + "" + file.size + "(" + i + ")",
          Name: file.name
            .substring(0, file.name.length - extension.length)
            .substring(0, 100),
          Extension: extension,
          File: "todo",
          imagePreviewUrl: "todo",
          Loading: true,
          LoadingPercentage: 0,
          Abort: false,
          Reader: reader
        });
      };

      reader.onload = (e) => {
        setTimeout(() => {
          if (
            this.props.maxFiles !== undefined &&
            this.props.maxFiles === this.state.Files.length
          )
            return;

          const extension = file.name.match(/.[\w]+$/g)[0];
          var d = new Date();

          this.updateState({
            Data: e.target.result,
            TimeM: d.getMinutes(),
            Size: file.size,
            dataKey:
              file.name +
              "" +
              file.lastModified +
              "" +
              file.size +
              "(" +
              i +
              ")",
            Name: file.name
              .substring(0, file.name.length - extension.length)
              .substring(0, 100),
            Extension: extension,
            File: file,
            imagePreviewUrl: reader.result,
            Loading: false,
            LoadingPercentage: 100,
            Abort: false
          });
        }, 1);
      };

      reader.readAsDataURL(file);
    }
  };

  asyncUpdateFile = (data) => {
    return new Promise((resolve, reject) => {
      const file = data;

      const reader = new FileReader();

      let i = this.state.Files.length;

      reader.onabort = (e) => {
        this.updateState({
          Loading: false,
          Abort: true,
          dataKey:
            file.name + "" + file.lastModified + "" + file.size + "(" + i + ")"
        });
      };

      reader.onprogress = (evt) => {
        setTimeout(() => {
          const extension = file.name.match(/.[\w]+$/g)[0];
          var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
          if (percentLoaded !== 100) {
            this.updateState({
              Data: "todo",
              TimeM: "uploading",
              Size: data.size,
              dataKey:
                file.name +
                "" +
                file.lastModified +
                "" +
                file.size +
                "(" +
                i +
                ")",
              Name: file.name
                .substring(0, file.name.length - extension.length)
                .substring(0, 100),
              Extension: extension,
              File: "todo",
              imagePreviewUrl: "todo",
              Loading: true,
              LoadingPercentage: percentLoaded,
              Abort: false,
              Reader: reader
            });
          }
        }, 1);
      };

      reader.onloadstart = (e) => {
        const extension = file.name.match(/.[\w]+$/g)[0];
        this.updateState({
          Data: "todo",
          TimeM: "uploading",
          Size: data.size,
          dataKey:
            file.name + "" + file.lastModified + "" + file.size + "(" + i + ")",
          Name: file.name
            .substring(0, file.name.length - extension.length)
            .substring(0, 100),
          Extension: extension,
          File: "todo",
          imagePreviewUrl: "todo",
          Loading: true,
          LoadingPercentage: 0,
          Abort: false,
          Reader: reader
        });
      };

      reader.onload = (e) => {
        setTimeout(() => {
          if (
            this.props.maxFiles !== undefined &&
            this.props.maxFiles === this.state.Files.length
          ) {
            return;
          }

          const extension = file.name.match(/.[\w]+$/g)[0];
          var d = new Date();

          resolve(
            this.updateState({
              Data: e.target.result,
              TimeM: d.getMinutes(),
              Size: data.size,
              dataKey:
                file.name +
                "" +
                file.lastModified +
                "" +
                file.size +
                "(" +
                i +
                ")",
              Name: file.name
                .substring(0, file.name.length - extension.length)
                .substring(0, 100),
              Extension: extension,
              File: file,
              imagePreviewUrl: reader.result,
              Loading: false,
              LoadingPercentage: 100,
              Abort: false
            })
          );
        }, 1);
      };
      reader.readAsDataURL(file.File || file);
    });
  };

  updateState = (file) => {
    const { onChange } = this.props;
    let o_files = [...this.state.Files];

    let previousUpdateIndex = o_files.findIndex((val) => {
      return val.dataKey === file.dataKey;
    });

    if (!file.Loading && previousUpdateIndex !== -1)
      o_files[previousUpdateIndex] = file;
    else if (o_files.length > 0 && file.Loading && previousUpdateIndex !== -1) {
      o_files[previousUpdateIndex] = file;
    } else {
      o_files.push(file);
    }

    if (file.Abort)
      o_files = o_files.filter((val) => {
        return val.dataKey !== file.dataKey;
      });

    o_files = o_files.filter((val) => {
      return val.File ? val.File.size !== 0 : true;
    });

    if (onChange) {
      onChange(this.getOnChangeValue(o_files));
    } else {
      this.setState({ Files: o_files }, () => {
        if (typeof this.props.onChange === "function")
          this.props.onChange(this.getOnChangeValue(this.state.Files));
      });
    }
  };

  validateRegex = (regex, value) => {
    return regex.test(value);
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value) {
      return { Files: props.value };
    }
    return null;
  }

  renderExtensionLink(file, index) {
    const { Name, Extension, Id, url } = file;

    if (Id) {
      return (
        <a
          key={"u-t-" + index}
          href={url}
          className="link-alt"
          target="_blank"
          rel="noopener noreferrer"
        >
          {Name + Extension}
        </a>
      );
    }

    return <span key={"u-t-" + index}>{Name + Extension}</span>;
  }

  parseImg(obj, index) {
    var imageName = obj.Name + obj.Extension;
    var isImg = /\.(?=gif|jpg|png|jpeg)/gi.test(imageName);
    var isVideo = /\.(?=webm|mp4)/gi.test(imageName);
    if ((isImg && obj.imagePreviewUrl) || (isImg && obj.Url)) {
      let img = React.createElement("img", {
        key: "u-i-" + index,
        alt: "",
        className: "ar-fileUploader__image",
        src: obj.imagePreviewUrl || obj.Url
      });
      return img;
    } else if (isVideo && obj.Url) {
      return (
        <video
          width="96px"
          height="96px"
          preload="metadata"
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          //   contextMenu={(e) => {
          //     e.preventDefault();
          //     e.stopPropagation();
          //   }}
        >
          <source src={obj.Url + "#t=1"} type="video/mp4" />
        </video>
      );
    }
    return (
      <ComponentElementByExtension extension={obj.Extension.toLowerCase()} />
    );
  }

  displayLoadingCirlce(file, index) {
    return (
      <div
        key={"u-i-c-s-" + index}
        className="ar-fileUploader__image__spinnerLoader"
      >
        <Spinner
          progress
          percentage={parseInt(file.LoadingPercentage)}
          size="xs"
        />
        <button
          key={"u-i-c-c-" + index}
          className="ar-fileUploader__image__cancelbutton"
          onClick={(e) => {
            e.preventDefault();
            file.Reader.abort();
          }}
        >
          Cancel
        </button>
      </div>
    );
  }

  displayLoadingbar(file, index) {
    const percentage = file.LoadingPercentage + "%";
    return (
      <React.Fragment>
        <div
          key={"u-i-c-b-" + file.index}
          className="ar-fileUploader__image__progressBarContainer"
        >
          <div
            className="ar-fileUploader__image__progressBar"
            style={{ width: percentage }}
          ></div>
        </div>
        <button
          key={"u-i-c-c-" + index}
          className="ar-fileUploader__image__cancelbuttonList"
          onClick={(e) => {
            e.preventDefault();
            file.Reader.abort();
          }}
        >
          Cancel
        </button>
      </React.Fragment>
    );
  }

  componentDidUpdate() {
    const context = this.context;
    if (context && context.Data.length !== 0) {
      this.updateFile(context.Data);
    }
  }

  renderFiles = () => {
    const { disableLinks, preview } = this.props;
    const { ExtendedMode } = this.state;
    // const spaceId = this.props.space.Id;

    // const url =
    var d = new Date();
    return this.state.Files.map((file, index) => (
      <div key={"u-w-" + index} className="ar-fileUploader__container__wrapper">
        <div
          key={"u-i-c-" + index}
          className="ar-fileUploader__imageContainer mt-2"
        >
          {ExtendedMode ? (
            file.Id ? (
              !disableLinks ? (
                <div
                  onClick={() => {
                    if (this.validadeFilePreview(file)) {
                      this.setState({
                        PreviewModalOpenStatus: true,
                        PreviewModalFile: file
                      });
                    } else if (!file.Size) {
                      this.forceDownload(index, file);
                    } else return;
                  }}
                  key={"u-i-c-i-" + index}
                  className="ar-fileUploader__image__iconContainer cursor-pointer"
                >
                  <ComponentElementByExtension
                    extension={file.Extension.toLowerCase()}
                  />
                </div>
              ) : (
                <div
                  key={"u-i-c-i-" + index}
                  className="ar-fileUploader__image__iconContainer"
                >
                  <ComponentElementByExtension
                    extension={file.Extension.toLowerCase()}
                  />
                </div>
              )
            ) : (
              <div
                key={"u-i-c-i-" + index}
                className="ar-fileUploader__image__iconContainer"
              >
                <ComponentElementByExtension
                  extension={file.Extension.toLowerCase()}
                />
              </div>
            )
          ) : (
            ""
          )}

          {ExtendedMode ? (
            file.Id ? (
              !disableLinks ? (
                <div
                  key={"u-i-c-d-" + index}
                  className="ar-fileUploader__imageContainer__Description"
                >
                  {/* <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${file.Url}`}
                  > */}
                  <div
                    onClick={() => {
                      if (this.validadeFilePreview(file)) {
                        this.setState({
                          PreviewModalOpenStatus: true,
                          PreviewModalFile: file
                        });
                      } else if (!file.Size) {
                        this.forceDownload(index, file);
                      } else return;
                    }}
                    className="ar-fileUploader__imageContainer__Title cursor-pointer"
                  >
                    <FileUploadText
                      tagEditors={this.state.TagEditors}
                      name={file.Name}
                      extension={file.Extension}
                      label={file.Label}
                      closeTagEdition={this.disableTagEdit}
                      index={index}
                      onSave={this.onSaveTagEdition}
                    />
                    {/* <TextEllipsis
                      text={file.Name}
                      length={10}
                      beforeEnd={5}
                      truncate
                      span
                      afterText={file.Extension}
                    /> */}
                  </div>
                  {/* </a> */}
                  {file.UploadDate ? (
                    <p className=" ar-fileUploader__imageContainer__details">
                      {moment(file.UploadDate).format("DD/MM/YYYY HH:mm")}
                    </p>
                  ) : null}
                  {file.Size ? (
                    <p className=" ar-fileUploader__imageContainer__details">
                      {file.TimeM !== "uploading"
                        ? this.humanFileSize(file.Size)
                        : this.humanFileSize(file.Size)}
                      {" • "}
                      {file.TimeM !== "uploading" ? (
                        <>
                          {parseInt(d.getMinutes()) - parseInt(file.TimeM)}{" "}
                          <FormattedMessage id={"MIN_AGO"} />
                        </>
                      ) : (
                        file.TimeM
                      )}
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              ) : (
                <div
                  key={"u-i-c-d-" + index}
                  className="ar-fileUploader__imageContainer__Description"
                >
                  <span className="ar-fileUploader__imageContainer__Title">
                    {/* <TextEllipsis
                      text={file.Name}
                      length={10}
                      beforeEnd={5}
                      truncate
                      span
                      afterText={file.Extension}
                    /> */}
                    <FileUploadText
                      tagEditors={this.state.TagEditors}
                      name={file.Name}
                      extension={file.Extension}
                      label={file.Label}
                      closeTagEdition={this.disableTagEdit}
                      index={index}
                      onSave={this.onSaveTagEdition}
                    />
                  </span>
                  {file.UploadDate ? (
                    <span
                      style={{ position: "absolute", right: 50, fontSize: 12 }}
                      className="ar-fileUploader__imageContainer__Title"
                    >
                      {moment(file.UploadDate).format("DD/MM/YYYY HH:mm")}
                    </span>
                  ) : null}

                  {file.Size ? (
                    <p className=" ar-fileUploader__imageContainer__details">
                      {file.TimeM !== "uploading"
                        ? this.humanFileSize(file.Size)
                        : this.humanFileSize(file.Size)}
                      {" • "}
                      {file.TimeM !== "uploading" ? (
                        <>
                          {parseInt(d.getMinutes()) - parseInt(file.TimeM)}{" "}
                          <FormattedMessage id={"MIN_AGO"} />
                        </>
                      ) : (
                        file.TimeM
                      )}
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              )
            ) : (
              <div
                key={"u-i-c-d-" + index}
                className="ar-fileUploader__imageContainer__Description"
              >
                <span className="ar-fileUploader__imageContainer__Title">
                  <FileUploadText
                    tagEditors={this.state.TagEditors}
                    name={file.Name}
                    extension={file.Extension}
                    label={file.Label}
                    closeTagEdition={this.disableTagEdit}
                    index={index}
                    onSave={this.onSaveTagEdition}
                  />
                  {/* <TextEllipsis
                    text={file.Name}
                    length={10}
                    beforeEnd={5}
                    truncate
                    span
                    afterText={file.Extension}
                  /> */}
                </span>

                {file.UploadDate ? (
                  <span
                    style={{ position: "absolute", right: 50, fontSize: 12 }}
                    className="ar-fileUploader__imageContainer__Title"
                  >
                    {moment(file.UploadDate).format("DD/MM/YYYY HH:mm")}
                  </span>
                ) : null}
                {file.Size ? (
                  <p
                    className=" ar-fileUploader__imageContainer__details"
                    style={{ textAlign: "left" }}
                  >
                    {file.TimeM !== "uploading"
                      ? this.humanFileSize(file.Size)
                      : this.humanFileSize(file.Size)}
                    {" • "}
                    {file.TimeM !== "uploading" ? (
                      <>
                        {parseInt(d.getMinutes()) - parseInt(file.TimeM)}{" "}
                        <FormattedMessage id={"MIN_AGO"} />
                      </>
                    ) : (
                      file.TimeM
                    )}
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
            )
          ) : (
            ""
          )}
          {ExtendedMode && file.Loading && this.displayLoadingbar(file, index)}
          {!ExtendedMode &&
            file.Loading &&
            this.displayLoadingCirlce(file, index)}
          {!ExtendedMode && !file.Loading && !disableLinks && (
            <div
              className="w-100 h-100 cursor-pointer"
              onClick={() => {
                if (this.validadeFilePreview(file)) {
                  this.setState({
                    PreviewModalOpenStatus: true,
                    PreviewModalFile: file
                  });
                } else if (!file.Size) {
                  this.forceDownload(index, file);
                } else return;
              }}
            >
              {this.parseImg(file, index)}
            </div>
          )}
          {!ExtendedMode &&
            !file.Loading &&
            disableLinks &&
            this.parseImg(file, index)}
          {ExtendedMode && !file.Loading && (
            <div
              key={"u-s-" + index}
              className="d-flex justify-content-end mr-3 flex-1 align-items-center"
            >
              {preview && !disableLinks && !file.Size && (
                <>
                  {/* <div
                    className="resetSVG cursor-pointer   text-color-link mr-3"
                    onClick={() => {
                      this.enableTagEdit(index, file);
                    }}
                  >
                    <FontAwesomeIcon
                      size="sm"
                      key={"u-i-i-" + index}
                      icon={faTag}
                    />
                  </div> */}
                  <div
                    className="resetSVG cursor-pointer   text-color-link mr-3"
                    onClick={() => {
                      this.forceDownload(index, file);
                    }}
                  >
                    <FontAwesomeIcon
                      size="sm"
                      key={"u-i-i-" + index}
                      icon={faDownload}
                    />
                  </div>
                </>
              )}

              {!preview && !disableLinks && (
                <>
                  <div
                    className="resetSVG cursor-pointer   text-color-link mr-3"
                    onClick={() => {
                      this.enableTagEdit(index, file);
                    }}
                  >
                    <FontAwesomeIcon size="sm" icon={faTag} />
                  </div>
                  {!file.Size && (
                    <>
                      <div
                        className="resetSVG cursor-pointer   text-color-link mr-3"
                        onClick={() => {
                          this.forceDownload(index, file);
                        }}
                      >
                        <FontAwesomeIcon size="sm" icon={faDownload} />
                      </div>
                    </>
                  )}
                  <div
                    className="resetSVG cursor-pointer   text-danger-alt "
                    onClick={() => {
                      this.clearImage(index);
                    }}
                  >
                    <FontAwesomeIcon
                      size="sm"
                      key={"u-i-i-" + index}
                      icon={faTrashAlt}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {!ExtendedMode && !file.Loading && (
            <>
              {!preview && (
                <>
                  <div
                    key={"u-s-" + index}
                    className="cursor-pointer d-flex justify-content-center align-items-center close_circular_corner"
                    onClick={() => {
                      this.clearImage(index);
                    }}
                  >
                    <FontAwesomeIcon
                      size="sm"
                      key={"u-i-i-" + index}
                      icon={faTrashAlt}
                    />
                  </div>
                  {file.Size && (
                    <div
                      className="cursor-pointer d-flex justify-content-center align-items-center text-color-link download_circular_corner"
                      onClick={() => {
                        this.enableTagEdit(index);
                      }}
                    >
                      <FontAwesomeIcon
                        size="sm"
                        key={"u-i-i-" + index}
                        icon={faTag}
                      />
                    </div>
                  )}
                </>
              )}

              {!file.Size && (
                <>
                  <div
                    style={{ top: preview ? "-10px" : "" }}
                    className="cursor-pointer d-flex justify-content-center align-items-center text-color-link download_circular_corner"
                    onClick={() => {
                      this.forceDownload(index, file);
                    }}
                  >
                    <FontAwesomeIcon
                      size="sm"
                      key={"u-i-i-" + index}
                      icon={faDownload}
                    />
                  </div>
                  {!preview && (
                    <div
                      className="cursor-pointer d-flex justify-content-center align-items-center text-color-link label_circular_corner"
                      onClick={() => {
                        this.enableTagEdit(index);
                      }}
                    >
                      <FontAwesomeIcon
                        size="sm"
                        key={"u-i-i-" + index}
                        icon={faTag}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {!ExtendedMode ? (
          file.Id ? (
            <div
              key={"u-t-" + index}
              className="ar-fileUploader__imageContainer__Title"
            >
              <div className="d-flex align-items-center">
                <ComponentElementByExtension
                  extension={file.Extension.toLowerCase()}
                />
                {!disableLinks ? (
                  <div
                    className="ml-1 cursor-pointer d-flex align-items-center"
                    onClick={() => {
                      if (this.validadeFilePreview(file)) {
                        this.setState({
                          PreviewModalOpenStatus: true,
                          PreviewModalFile: file
                        });
                      } else if (!file.Size) {
                        this.forceDownload(index, file);
                      } else return;
                    }}
                  >
                    <FileUploadText
                      tagEditors={this.state.TagEditors}
                      name={file.Name}
                      inputWidth={80}
                      length={5}
                      extension={file.Extension}
                      label={file.Label}
                      closeTagEdition={this.disableTagEdit}
                      index={index}
                      onSave={this.onSaveTagEdition}
                    />
                    {/* <TextEllipsis
                      text={file.Name}
                      length={5}
                      beforeEnd={3}
                      truncate
                      span
                      afterText={file.Extension}
                    /> */}
                  </div>
                ) : (
                  <FileUploadText
                    tagEditors={this.state.TagEditors}
                    name={file.Name}
                    inputWidth={80}
                    extension={file.Extension}
                    label={file.Label}
                    closeTagEdition={this.disableTagEdit}
                    index={index}
                    onSave={this.onSaveTagEdition}
                  />
                  // <TextEllipsis
                  //   text={file.Name}
                  //   length={5}
                  //   beforeEnd={3}
                  //   truncate
                  //   span
                  //   afterText={file.Extension}
                  // />
                )}
              </div>
              {file.UploadDate ? (
                <div
                  style={{ fontSize: 12 }}
                  className="ar-fileUploader__imageContainer__details"
                >
                  {moment(file.UploadDate).format("DD/MM/YYYY HH:mm")}
                </div>
              ) : null}
            </div>
          ) : (
            <div
              key={"u-t-" + index}
              className="ar-fileUploader__imageContainer__Title"
            >
              <div className="d-flex align-items-center">
                <ComponentElementByExtension
                  extension={file.Extension.toLowerCase()}
                />
                <div className="ml-1">
                  <FileUploadText
                    inputWidth={80}
                    tagEditors={this.state.TagEditors}
                    name={file.Name}
                    extension={file.Extension}
                    label={file.Label}
                    closeTagEdition={this.disableTagEdit}
                    index={index}
                    onSave={this.onSaveTagEdition}
                  />
                  {/* <TextEllipsis
                    text={file.Name}
                    length={5}
                    beforeEnd={3}
                    truncate
                    span
                    afterText={file.Extension}
                  /> */}
                </div>
              </div>
              {file.UploadDate ? (
                <div
                  style={{ position: "absolute", right: 50, fontSize: 12 }}
                  className="ar-fileUploader__imageContainer__Title"
                >
                  {moment(file.UploadDate).format("DD/MM/YYYY HH:mm")}
                </div>
              ) : null}
              {file.Size ? (
                <div
                  key={"u-d-" + index}
                  className="ar-fileUploader__imageContainer__details"
                >
                  <span>
                    {file.TimeM !== "uploading"
                      ? this.humanFileSize(file.Size)
                      : this.humanFileSize(file.Size)}
                    {" • "}
                    {file.TimeM !== "uploading" ? (
                      <>
                        {parseInt(d.getMinutes()) - parseInt(file.TimeM)}{" "}
                        <FormattedMessage id={"MIN_AGO"} />
                      </>
                    ) : (
                      file.TimeM
                    )}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          )
        ) : (
          ""
        )}
      </div>
    ));
  };

  humanFileSize(size) {
    if (size === 0) return "0 B";

    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "KB", "MB", "GB", "TB"][i]
    );
  }

  handleListIconClick = () => {
    if (!this.state.ExtendedMode) {
      if (!this.props.extendedMode) {
        localStorage.setItem("ar-FileUploader--ExtendedMode", true);
      }
      this.setState({ ExtendedMode: !this.state.ExtendedMode });
    }
  };

  closeModal = () => {
    this.setState({ PreviewModalOpenStatus: false, PreviewModalUrl: "" });
  };

  handleGridIconClick = () => {
    if (this.state.ExtendedMode) {
      if (!this.props.extendedMode) {
        localStorage.setItem("ar-FileUploader--ExtendedMode", false);
      }
      this.setState({ ExtendedMode: !this.state.ExtendedMode });
    }
  };

  handleDropZoneEnter = (e) => {
    e.preventDefault();
    this.setState({ DragEntered: true });
  };

  handleDropZoneLeave = () => {
    this.setState({ DragEntered: false });
  };

  validadeFilePreview = (file) => {
    if (file.Size) return false;

    switch (`${file.Extension.toLowerCase()}`) {
      case ".csv":
      case ".json":
      case ".txt":
      case ".doc":
      case ".docx":
      case ".xls":
      case ".xlr":
      case ".xlam":
      case ".dotx":
      case ".xlsx":
      case ".xlt":
      case ".pptx":
      case ".pptm":
      case ".ppt":
      case ".pdf":
      case ".xps":
      case ".pps":
      case ".ppam":
      case ".tif":
      case ".txt":
      case ".gif":
      case ".jpg":
      case ".png":
      case ".jpeg":
      case ".jfif":
      case ".bmp":
      case ".pdfa":
      case ".svg":
      case ".webp":
      case ".msg":
      case ".eml":
      case ".webm":
      case ".mp4":
        return true;
      default:
        return false;
    }
  };

  handleImageWidth = (e) => {
    this.setState({ ImageWidth: e });
  };

  createModalFill = () => {
    if (this.state.PreviewModalFile) {
      const FileExtension = this.state.PreviewModalFile.Extension;
      switch (`${FileExtension.toLowerCase()}`) {
        case ".csv":
        case ".json":
        case ".txt":
          return <DumbFilesPreview src={this.state.PreviewModalFile.Url} />;
        case ".doc":
        case ".docx":
        case ".xls":
        case ".xlr":
        case ".xlam":
        case ".xlsx":
        case ".dotx":
        case ".xlt":
        case ".pptx":
        case ".pptm":
        case ".ppt":
        case ".xps":
        case ".pps":
        case ".ppam":
        case ".tif":
          return (
            <OfficePreview
              src={this.state.PreviewModalFile.Url + "&inline=false"}
            />
          );
        case ".pdf":
        case ".pdfa":
          return (
            <PdfPreview
              src={this.state.PreviewModalFile.Url + "&inline=true"}
            />
          );

        case ".gif":
        case ".jpg":
        case ".png":
        case ".jpeg":
        case ".bmp":
        case ".jfif":
        case ".svg":
        case ".webp":
          return (
            <ImagePreview
              handleWidthChange={this.handleImageWidth}
              src={this.state.PreviewModalFile.Url + "&inline=true"}
            />
          );
        case ".msg":
        case ".eml":
          return <EmailPreview File={this.state.PreviewModalFile} />;
        case ".webm":
        case ".mp4":
          return (
            <VideoPreview
              src={this.state.PreviewModalFile.Url + "&inline=true"}
              handleWidthChange={this.handleImageWidth}
            />
          );
        default:
          return null;
      }
    } else return null;
  };

  splitter(s) {
    var middle = s.length / 2;

    return [s.substr(0, middle), s.substr(middle)];
  }

  render() {
    const { preview } = this.props;
    const { accept } = this.props;

    const { enableSwapper } = this.props || true;
    const { ExtendedMode } = this.state;
    const { DragEntered } = this.state;
    let className = "ar-fileUploader__container";

    if (
      this.props.className !== "undefined" &&
      this.props.className !== undefined
    ) {
      className += " " + this.props.className;
    }

    if (ExtendedMode) {
      className += " ar-fileUploader--extended-mode";
    }
    const ModalFil = this.createModalFill();

    const checkForImageType = (file) => {
      switch (file.Extension) {
        case ".gif":
        case ".jpg":
        case ".png":
        case ".jpeg":
        case ".bmp":
        case ".svg":
          return true;
        default:
          return false;
      }
    };
    const isImage = checkForImageType(this.state.PreviewModalFile);
    let SplitText = [];
    if (this.state.PreviewModalOpenStatus && this.state.PreviewModalFile) {
      SplitText = this.splitter(
        this.state.PreviewModalFile.Name + this.state.PreviewModalFile.Extension
      );
    }

    if (preview)
      return (
        <>
          <Modal
            // containerClassName=""
            className={classnames(
              "ar-modal-preview-file-content  d-flex flex-column p-1",
              {
                "ar-modal-preview-image-content": isImage,
                "ar-modal-preview-files-content-files": !isImage
              }
            )}
            isOpen={this.state.PreviewModalOpenStatus}
            onClose={this.closeModal}
            extraCloseButton={
              <>
                <div
                  className="ar-modal-preview-file-content-close-button"
                  onClick={() => this.closeModal()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                {!this.state.PreviewModalFile.Size && (
                  <div
                    style={{ top: 55 }}
                    className="ar-modal-preview-file-content-close-button text-color-link"
                    onClick={() =>
                      this.forceDownload(null, this.state.PreviewModalFile)
                    }
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                )}
              </>
            }
          >
            {ModalFil}
            <div
              style={{ maxWidth: this.state.ImageWidth }}
              className="align-items-center d-flex justify-content-between px-1 text-black"
            >
              <span
                title={this.state.PreviewModalFile.Name}
                className="flex-1 mr-1"
                id="Span-With-Split-Text"
                data-content-start={SplitText[0]}
                data-content-end={SplitText[1]}
              ></span>

              <div
                style={{ fontSize: 12 }}
                className="ar-fileUploader__imageContainer__Title"
              >
                {moment(this.state.PreviewModalFile.UploadDate).format(
                  "DD/MM/YYYY HH:mm"
                )}
              </div>
            </div>
          </Modal>
          {enableSwapper && (
            <div className="ar-fileUploader__header">
              <div className="ar-fileUploader__header__icons">
                <span
                  onClick={this.handleListIconClick}
                  className={`ar-fileUploader__header__icons__list${
                    this.state.ExtendedMode
                      ? " ar-fileUploader__header__icons--active"
                      : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faBars} />
                </span>
                <span
                  onClick={this.handleGridIconClick}
                  className={`ar-fileUploader__header__icons__grid${
                    !this.state.ExtendedMode
                      ? " ar-fileUploader__header__icons--active"
                      : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faTh} />
                </span>
              </div>
            </div>
          )}
          <div className={className}>{this.renderFiles()}</div>
        </>
      );

    let showDropZone = true;

    if (
      this.props.maxFiles !== undefined &&
      this.props.maxFiles === this.state.Files.length
    ) {
      showDropZone = false;
    }

    const DropperFile = this.props.RemoveInputAfterUpload ? (
      this.state.Files.length > 0 ? null : (
        <div className="ar-fileUploader__container ar-fileUploader--extended-mode">
          {showDropZone && (
            <label
              className={`ar-fileUploader__dropzone px-2${
                this.state.DragEntered
                  ? " ar-fileUploader__dropzone--hovering"
                  : ""
              }`}
              onDrop={this.drop}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDragEnter={this.handleDropZoneEnter}
              onDragLeave={this.handleDropZoneLeave}
            >
              <AirdeskUploaderSvg />
              {DragEntered ? (
                <span>
                  <FormattedMessage id="DROP_THE_FILE" />
                </span>
              ) : (
                <span>
                  <FormattedMessage id="DRAG_OR_CLICK_FILE" />
                </span>
              )}

              <input
                className="ar-fileUploader__inputFile"
                accept={accept && accept.join(",")}
                type="file"
                multiple={this.props.singleFileUpload ? false : true}
                onChange={this.inputFile}
              />
            </label>
          )}
        </div>
      )
    ) : (
      <div className="ar-fileUploader__container ar-fileUploader--extended-mode">
        {showDropZone && (
          <label
            className={`ar-fileUploader__dropzone px-2${
              this.state.DragEntered
                ? " ar-fileUploader__dropzone--hovering"
                : ""
            }`}
            onDrop={this.drop}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDragEnter={this.handleDropZoneEnter}
            onDragLeave={this.handleDropZoneLeave}
          >
            <AirdeskUploaderSvg />
            {DragEntered ? (
              <span>
                <FormattedMessage id="DROP_THE_FILE" />
              </span>
            ) : (
              <span>
                <FormattedMessage id="DRAG_OR_CLICK_FILE" />
              </span>
            )}

            <input
              className="ar-fileUploader__inputFile"
              accept={accept && accept.join(",")}
              type="file"
              multiple={this.props.singleFileUpload ? false : true}
              onChange={this.inputFile}
            />
          </label>
        )}
      </div>
    );

    return (
      <React.Fragment>
        <Modal
          // containerClassName=""
          className={classnames(
            "ar-modal-preview-file-content  d-flex flex-column p-1",
            {
              "ar-modal-preview-image-content": isImage,
              "ar-modal-preview-files-content-files": !isImage
            }
          )}
          isOpen={this.state.PreviewModalOpenStatus}
          onClose={this.closeModal}
          extraCloseButton={
            <>
              <div
                className="ar-modal-preview-file-content-close-button"
                onClick={() => this.closeModal()}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div
                style={{ top: 55 }}
                className="ar-modal-preview-file-content-close-button text-color-link"
                onClick={() =>
                  this.forceDownload(null, this.state.PreviewModalFile)
                }
              >
                <FontAwesomeIcon icon={faDownload} />
              </div>
            </>
          }
        >
          {ModalFil}

          <div
            style={{ maxWidth: this.state.ImageWidth }}
            className="align-items-center justify-content-between px-1 text-black"
          >
            {this.state.PreviewModalFile.Label && (
              <div>{this.state.PreviewModalFile.Label}</div>
            )}
            {!this.state.PreviewModalFile.Label && (
              <span
                title={this.state.PreviewModalFile.Name}
                className="d-block mr-1"
                id="Span-With-Split-Text"
                data-content-start={SplitText[0]}
                data-content-end={SplitText[1]}
              ></span>
            )}

            <div
              style={{ fontSize: 12 }}
              className="ar-fileUploader__imageContainer__Title"
            >
              {moment(this.state.PreviewModalFile.UploadDate).format(
                "DD/MM/YYYY HH:mm"
              )}
            </div>
          </div>
        </Modal>

        {!this.state.DisableExtraButtons && (
          <div
            className={classnames("float-left", {
              "mb-1": !enableSwapper || this.state.ExtendedMode
            })}
          >
            <UpploadReact
              settings={this.props.uploaderSettings}
              onFileDone={this.fileUpload}
            />
          </div>
        )}
        {enableSwapper && (
          <div className="ar-fileUploader__header">
            <div className="ar-fileUploader__header__icons">
              <span
                onClick={this.handleListIconClick}
                className={`ar-fileUploader__header__icons__list${
                  this.state.ExtendedMode
                    ? " ar-fileUploader__header__icons--active"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faBars} />
              </span>
              <span
                onClick={this.handleGridIconClick}
                className={`ar-fileUploader__header__icons__grid${
                  !this.state.ExtendedMode
                    ? " ar-fileUploader__header__icons--active"
                    : ""
                }`}
              >
                <FontAwesomeIcon icon={faTh} />
              </span>
            </div>
          </div>
        )}

        {DropperFile}
        {this.WarningMessage && (
          <div className="text-danger-alt fs-14 d-flex align-items-center">
            <FormattedMessage id={"FILES_ONLY_TYPE"} />
            {this.props.accept.join(", ")}
          </div>
        )}
        {this.state.WarningFileTooBigMessage && (
          <div className="text-danger-alt fs-14 d-flex align-items-center">
            <FormattedMessage id={"FILES_TOO_BIG"} />
          </div>
        )}
        {this.state.FileNotSupported && (
          <div className="text-danger-alt fs-14 d-flex align-items-center">
            <FormattedMessage id={"API_FAIL_UPLOAD_FILES"} />
          </div>
        )}
        <div className={className}>{this.renderFiles()}</div>
      </React.Fragment>
    );
  }
}

export const ComponentElementByExtension = ({ extension }) =>
  /.svg$|.jpg$|.png$|.gif$|.jpeg$/.test(extension) ? (
    <FontAwesomeIcon icon={faFileImage} />
  ) : /.pdf$/.test(extension) ? (
    <FontAwesomeIcon className="text-danger-alt" icon={faFilePdf} />
  ) : /.xlsx$|.xlam$|.csv$|.xls$|.xlr$/.test(extension) ? (
    <FontAwesomeIcon className="text-accept-alt" icon={faFileExcel} />
  ) : /.docx$|.doc$/.test(extension) ? (
    <FontAwesomeIcon icon={faFileWord} />
  ) : /.msg$|.eml$/.test(extension) ? (
    <FontAwesomeIcon icon={faEnvelope} />
  ) : /.webm$|.mp4$/.test(extension) ? (
    <FontAwesomeIcon icon={faFileVideo} />
  ) : (
    <FontAwesomeIcon icon={faFile} />
  );

export default withSpace(FileUploader);
