import React, { useMemo, useRef, useState, useEffect } from "react";
import Input from "./Input";
import {
  isValidPhoneNumber,
  isValidPhoneNumberStructure,
  isPhoneNumber
} from "../../Helpers/MiscHelper";
import Extension from "./Extensions.json";
import DropdownPopover from "../_Dropdown/DropdownPopover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPhone } from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import { useSpace } from "../../Contexts/SpaceContext";
import { useCountry } from "../../Hooks/EntityHooks";

export const PhoneNumber = (phonenumber, CountryCode) => {
  const lsPhoneExtension = localStorage.getItem("air-previous-phone-extension");

  let previousExtension;
  if (lsPhoneExtension) {
    previousExtension = lsPhoneExtension;
  } else if (CountryCode) {
    const item = Extension.find((e) => e.code === CountryCode) || {};
    previousExtension = item.extension || "+351";
  } else previousExtension = "+351";

  let extension = "",
    number = "",
    phoneDividers;

  if (
    typeof phonenumber === "string" &&
    isValidPhoneNumberStructure(phonenumber)
  ) {
    phoneDividers = phonenumber.split(".");
    extension = phoneDividers[0];
    number = phoneDividers[1];
  } else if (isPhoneNumber(phonenumber)) {
    phoneDividers = phonenumber.split(".");
    extension = "";
    number = phonenumber;
  } else {
    extension = previousExtension;
    number = phonenumber;
  }

  return {
    extension,
    number,
    completeNumber: extension + number,
    value: `${extension}.${number}`
  };
};

export const FormattedPhoneNumber = ({
  noHref,
  number,
  withIcon,
  className
}) => {
  const currentValue = useMemo(() => {
    if (number instanceof PhoneNumber) return number;
    return PhoneNumber(number);
  }, [number]);

  if (noHref) {
    return (
      <span className={className}>
        <FontAwesomeIcon className="mr-1" icon={faPhone} />
        {currentValue.completeNumber}
      </span>
    );
  }

  return (
    <a href={`tel:${currentValue.completeNumber}`} className={className}>
      {currentValue.completeNumber}
    </a>
  );
};

const PhoneInput = ({
  OnlyPhoneCodes = false,
  value: currentValueString,
  onChange,
  preview,
  disabled,
  name,
  className,
  noPermission,
  onboarding,
  onFocus,
  onBlur
}) => {
  const space = useSpace();
  const ConnectedCountry = useCountry(space.Country);
  const currentValue = useMemo(() => {
    return PhoneNumber(currentValueString, ConnectedCountry?.Code);
  }, [ConnectedCountry?.Code, currentValueString]);
  const {
    extension,
    number,
    completeNumber,
    value: internalValue
  } = currentValue;
  const divRef = useRef();
  const codeRef = useRef();
  const numberRef = useRef();
  const [tempInput, setTempInput] = useState(null);
  const [popupState, setPopupState] = useState(false);
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [dropdownSelected, setDropdownSelected] = useState(null);

  const handleExtensionChange = (e = "") => {
    const newValue = `${!e ? "" : e.charAt(0) === "+" ? "" : "+"}${e}${
      e && "."
    }${currentValue.number}`;

    localStorage.setItem(
      "air-previous-phone-extension",
      `${!e ? "" : e.charAt(0) === "+" ? "" : "+"}${e}`
    );
    if (!isValidPhoneNumberStructure(newValue) && !isPhoneNumber(newValue))
      return;

    onChange(newValue);
  };

  useEffect(() => {
    const timeout = closeDropdown
      ? setTimeout(() => {
          setCloseDropdown(false);
          setDropdownSelected(null);
          setPopupState(false);
        }, 100)
      : undefined;
    return () => clearTimeout(timeout);
  }, [closeDropdown]);

  const handleNumberChange = (e) => {
    // ////;
    // if (!Number.isInteger(Number(e.target.value))) return;

    const value = e.target.value.replace(/ /g, "");

    const newValue = value ? `${currentValue.extension}.${value}` : "";
    if (newValue !== "" && !isValidPhoneNumberStructure(newValue)) return;

    onChange(newValue);
  };

  const codes = useMemo(() => {
    return [
      ...new Set(
        Extension.map((e) => {
          return e.extension;
        }).sort((a, b) => {
          if (parseInt(a) > parseInt(b)) return 1;
          if (parseInt(a) < parseInt(b)) return -1;
          return 0;
        })
      )
    ];
  }, []);

  const resolvedCodes = useMemo(() => {
    return tempInput ? codes.filter((e) => e.includes(tempInput)) : codes;
  }, [codes, tempInput]);

  const itemsRef = useMemo(() => {
    const refs = {};
    resolvedCodes.forEach((e, i) => {
      refs[i] = React.createRef();
    });
    return refs;
  }, [resolvedCodes]);

  //   const itemsRef = resolvedCodes.reduce(
  //     ((acc, value, index) => {
  //       acc[index] = React.createRef();
  //       return acc;
  //     },
  //     {})
  //   );

  if (noPermission)
    return <span className="text-black fs-14">{currentValueString}</span>;

  if (preview) {
    if (isValidPhoneNumber(internalValue) || isPhoneNumber(internalValue))
      return <span className="text-black fs-14">{completeNumber}</span>;
    else return null;
  }
  // const formmatedNumber = number.replace(/(.{3})/g, "$1 ");

  const handleRefocus = () => {
    if (numberRef.current) {
      numberRef.current.focus();
    }
  };

  const handleKeyDownEvent = (e) => {
    const length = resolvedCodes.length;
    if (e.keyCode === 40 || e.keyCode === 38) {
      //   console.log(e.keyCode);

      let nextIndex = dropdownSelected;

      if (e.keyCode === 40) {
        // keyDown
        if (dropdownSelected === null) nextIndex = 0;
        else nextIndex++;
      } else if (e.keyCode === 38) {
        // keyUp
        if (dropdownSelected === null) nextIndex = length ? length - 1 : 0;
        else nextIndex--;
      }

      if (length === 0)
        nextIndex = dropdownSelected === null ? nextIndex : null;
      else if (nextIndex === -1) nextIndex = length - 1 || 0;
      else if (nextIndex === length) nextIndex = 0;

      setDropdownSelected(nextIndex);
      if (itemsRef[nextIndex]) itemsRef[nextIndex].current.scrollIntoView();
    } else if (e.keyCode === 13) {
      e.preventDefault();
      if (length === 0 && dropdownSelected === 0) {
        handleExtensionChange(e.target.value);
        setTempInput(null);
        setPopupState(false);
        setDropdownSelected(null);
      } else if (dropdownSelected !== null) {
        handleExtensionChange(resolvedCodes[dropdownSelected]);
        setTempInput(null);
        setPopupState(false);
        setDropdownSelected(null);
        handleRefocus();
      } else {
        handleExtensionChange(e.target.value);
        setTempInput(null);
        setPopupState(false);
        setDropdownSelected(null);
        handleRefocus();
      }
    }
  };

  return (
    <div
      className={`d-flex align-items-center justify-content-center phone-input ${
        className ? className : ""
      }`}
    >
      {/* <Input

		/> */}
      {!disabled && (
        <>
          <div
            ref={divRef}
            style={{
              width: 170,
              height: 40,
              borderColor:
                document.activeElement === codeRef.current ? "#007bff" : ""
            }}
            className={classnames(
              "ssi-input ssi-control mr-2 d-flex justify-content-between",
              {
                "bg-white": onboarding
              }
            )}
          >
            <input
              type="text"
              onKeyDown={handleKeyDownEvent}
              className="fs-14 border-0 w-100 h-100"
              ref={codeRef}
              style={{ border: 0, background: "transparent", outline: "none" }}
              // preview={preview}
              disabled={disabled}
              autoComplete="off"
              onFocus={() => {
                onFocus && onFocus();
                if (!popupState) setPopupState(true);
              }}
              onBlur={() => {
                onBlur && onBlur();
                setCloseDropdown(true);
                setDropdownSelected(null);
                handleExtensionChange(
                  tempInput !== null ? tempInput : extension
                );
                setTempInput(null);
              }}
              value={tempInput !== null ? tempInput : extension}
              onChange={(e) => {
                setDropdownSelected(null);
                setTempInput(e.target.value);
              }}
            />
            <div className="AddressSelector SearchButton h-100 d-flex justify-content-center align-items-center ">
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </div>
          <DropdownPopover
            className={"ar-dropdown-padding"}
            isOpen={popupState}
            anchorEl={divRef.current}
            onClose={() => {
              if (popupState) setPopupState(false);
              setTempInput(null);
              setDropdownSelected(null);
            }}
          >
            {resolvedCodes.map((e, i) => {
              return (
                <div
                  ref={itemsRef[i]}
                  style={{
                    background:
                      i === dropdownSelected ? "rgba(20, 115, 230, 0.1)" : ""
                  }}
                  onClick={() => {
                    handleExtensionChange(e);
                    setTempInput(null);
                    setPopupState(false);
                    setDropdownSelected(null);
                  }}
                  key={`${i}+${e}`}
                  className="AddressSelector Suggestion fs-14 ssi-link d-flex p-2 flex-1 breakWord"
                >
                  {e}
                </div>
              );
            })}
            {resolvedCodes.length === 0 && tempInput && (
              <div
                //   key={e.Id}
                style={{
                  background:
                    0 === dropdownSelected ? "rgba(20, 115, 230, 0.1)" : ""
                }}
                className="AddressSelector Suggestion fs-14 ssi-link d-flex flex-1 breakWord"
                onClick={() => {
                  handleExtensionChange(
                    tempInput !== null ? tempInput : extension
                  );
                  setTempInput(null);
                  setPopupState(false);
                  setDropdownSelected(null);
                }}
              >
                {tempInput !== null ? tempInput : extension}
              </div>
            )}
          </DropdownPopover>
        </>
      )}
      {/* <Dropdown
        items={codes}
        value={extension}
        onChange={handleExtensionChange}
        className="mr-2"
        closeOnSelect
      /> */}
      {!OnlyPhoneCodes && (
        <Input
          onFocus={onFocus}
          onBlur={onBlur}
          className={classnames({
            "bg-white": onboarding
          })}
          preview={preview}
          disabled={disabled}
          ref={numberRef}
          value={number}
          onChange={handleNumberChange}
          placeholder="000 000 000"
        />
      )}
      {OnlyPhoneCodes && <div className="flex-1"></div>}
    </div>
  );
};

export default PhoneInput;
