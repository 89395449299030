import { faFileContract } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

import TriggerCreation from "./TriggerCreation";
import { spaceContractSchema } from "../../../../../config/schema";
import { ConnectedContractOrigin } from "../../../../Origin/ContractOrigin";
import { useIntl } from "react-intl";

const PreviewTarget = ({ val }) => <ConnectedContractOrigin contract={val} />;

const TriggerContractCreation = React.memo(({ ...props }) => {
	const intl = useIntl()
  return (
    <TriggerCreation
      TargetPreview={PreviewTarget}
      {...props}
      schema={spaceContractSchema}
      icon={faFileContract}
      text={intl.formatMessage({ id: "CONTRACT" })}
    />
  );
});

export default TriggerContractCreation;
