import React from "react";
import {
  hasCallsAccess,
  hasClientsAccess,
  hasContactsAccess,
  hasContractsAccess,
  hasDealsAccess,
  hasInterventionsAccess,
  hasProjectsAccess,
  hasSocialMediaAcess,
  hasSubscriptionAccess,
  hasTasksAccess,
  hasTicketsAccess
} from "../../../Helpers/ModulesHelper";

export const NotificationsStateContext = React.createContext();
export const NotificationsSetStateContext = React.createContext();
export const NotificationsSwitchesContext = React.createContext();

export const NotificationsPermissionToggleContext = React.createContext();

export const hasNotificationPermission = (total, permission) => {
  return Boolean(total & permission);
};

export const getPermissionedFilteredTopicNames = (permissions) => {
  const res = [];
  // if (hasContactsAccess(permissions)) res.push("CONTACTS");
  // if (hasClientsAccess(permissions)) res.push("ACCOUNTS");
  // if (hasDealsAccess(permissions)) res.push("DEALS");
  // if (hasContractsAccess(permissions)) res.push("CONTRACTS");
  // if (hasSubscriptionAccess(permissions)) res.push("SUBSCRIPTIONS");
  // if (hasCallsAccess(permissions)) res.push("CALLS");
  // if (hasProjectsAccess(permissions)) res.push("PROJECTS");
  // if (hasTasksAccess(permissions)) res.push("TASKS");
  // if (hasTicketsAccess(permissions)) res.push("CASES");
  // if (hasInterventionsAccess(permissions)) res.push("INTERVENTIONS");
  // if (hasSocialMediaAcess(permissions)) res.push("SOCIALMEDIA");

  //Tem acesso a todos os settings para poder alterar como quiser 17/01/2023

  res.push("CONTACTS");
  res.push("ACCOUNTS");
  res.push("DEALS");
  res.push("CONTRACTS");
  res.push("SUBSCRIPTIONS");
  res.push("CALLS");
  res.push("PROJECTS");
  res.push("TASKS");
  res.push("CASES");
  res.push("INTERVENTIONS");
  res.push("SOCIALMEDIA");
  res.push("INBOX");
  return res;
};

export function formatStringToNotificationPermission(str) {
  return str
    .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
    .replace(/^[^ ]/g, (match) => match.toUpperCase());
}

export const toggleNotificationPermission = (total, permission) => {
  if (hasNotificationPermission(total, permission)) {
    return total - permission;
  } else return total + permission;
};

export const DesktopDefaultNotificationPermissions = {
  General: 31,
  Bell: {
    Accounts: 2047,
    Calls: 16383,
    Cases: 1023,
    Classifications: 15,
    Contacts: 63,
    Contracts: 4194303,
    Deals: 1048575,
    Inbox: 127,
    Interventions: 255,
    Owner: 536739839,
    Projects: 4194303,
    Socialmedia: 31,
    Subscriptions: 131071,
    Tasks: 32767,
    Users: 2047
  },
  Popup: {
    Accounts: 2047,
    Calls: 16383,
    Cases: 1023,
    Classifications: 15,
    Contacts: 63,
    Contracts: 4194303,
    Deals: 1048575,
    Inbox: 127,
    Interventions: 255,
    Owner: 536739839,
    Projects: 4194303,
    Socialmedia: 31,
    Subscriptions: 131071,
    Tasks: 32767,
    Users: 2047
  },
  Browser: {
    Accounts: 2047,
    Calls: 16383,
    Cases: 1023,
    Classifications: 15,
    Contacts: 63,
    Contracts: 4194303,
    Deals: 1048575,
    Inbox: 127,
    Interventions: 255,
    Owner: 536739839,
    Projects: 4194303,
    Socialmedia: 31,
    Subscriptions: 131071,
    Tasks: 32767,
    Users: 2047
  },
  Mobile: {
    Accounts: 2047,
    Calls: 16383,
    Cases: 1023,
    Classifications: 15,
    Contacts: 63,
    Contracts: 4194303,
    Deals: 1048575,
    Inbox: 127,
    Interventions: 255,
    Owner: 536739839,
    Projects: 4194303,
    Socialmedia: 31,
    Subscriptions: 131071,
    Tasks: 32767,
    Users: 2047
  }
};

export const EmailDefaultNotificationPermissions = {
  General: 1,
  Email: {
    Accounts: 2047,
    Calls: 16383,
    Cases: 1023,
    Classifications: 15,
    Contacts: 63,
    Contracts: 4194303,
    Deals: 1048575,
    Inbox: 127,
    Interventions: 255,
    Owner: 536739839,
    Projects: 4194303,
    Socialmedia: 31,
    Subscriptions: 131071,
    Tasks: 32767,
    Users: 2047
  }
};

export const TopicSearchFilterContext = React.createContext();
