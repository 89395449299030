import React, { useMemo } from "react";
// import SplitCol from "../../Components/SplitCol/SplitCol";
import { useIntl } from "react-intl";
import { useFormState, createFormRequestHook } from "../../Components/Forms";
import {
  spaceOrganizationSchema,
  spacePipelineSchema,
  spaceClientSchema,
  spaceSubscriptionSchema,
  spacePipelineStatusSchema
} from "../../config/schema";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../Components/Forms/SideMenuForm";
import {
  validateSubscriptionForm,
  convertSubscriptionEntityToForm,
  defaultSubscriptionForm,
  formatSubscriptionForm
} from "./SubscriptionsHelper";
import { entityActionType } from "../../Helpers/MiscHelper";
import { useToast } from "../../Components/Toast/ToastProvider";
import { useSubscription } from "../../Hooks/EntityHooks";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";
import {
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import { SubscriptionStatusTypes } from "../../Components/Subscription/SubscriptionProfile";
import {
  isOrganzationRequestValid,
  OrganizationRequestSideError
} from "../../Components/Organizations/OrganizationHelper";
import { useHandleError } from "../../Components/CGrid/ServerAwareHelper";
import { subscriptionCommunicator } from "./subscriptionCommunicatorHelper";
import SubscriptionsFormContent from "./SubscriptionsFormContent";
import {
  FormTemplateProvider,
  useFormTemplateGetter
} from "../../Components/Forms/FormTemplatesHelper";

const useSubcriptionRequest = createFormRequestHook({
  schema: spaceSubscriptionSchema,

  dependencies: [
    {
      schema: spaceClientSchema,
      getEntityId: ({ clientId }) => clientId,
      convertEntityToForm: (entity, props) => {
        const { Id, Manager } = entity;
        return {
          Client: {
            type: "Client",
            data: Id
          },
          Manager: Manager
        };
      }
    }

    // {
    //   schema: spaceClientSchema,
    //   getEntityId: ({ clientId }) => clientId,
    //   convertEntityToForm: (entity, props) => {
    //     const { Id } = entity;
    //     return {
    //       Client: Id
    //     };
    //   }
    // },
    // {
    //   schema: spaceDealsSchema,
    //   getEntityId: ({ dealId }) => dealId,
    //   convertEntityToForm: (entity, props) => {
    //     const { Id, Contact, Company } = entity;
    //     return {
    //       Client: {
    //         type: originTypes.deals,
    //         data: Id
    //       },
    //       Contact,
    //       Company
    //     };
    //   }
    // }
  ],
  convertEntityToForm: convertSubscriptionEntityToForm,
  formatDuplicationEntity: (entity) => {
    const { PipelineStatus } = entity;
    if (PipelineStatus) {
      const pipelineStatusEntity = getEntity(
        spacePipelineStatusSchema,
        PipelineStatus
      );
      if (pipelineStatusEntity.Status !== SubscriptionStatusTypes.open) {
        entity.PipelineStatus = null;
      }
    }
    return entity;
  }
});

const SubscriptionForm = (props) => {
  const { key, loading, error, convertedForm, isChild, childProps } =
    useSubcriptionRequest(props);

  const { id, defaultForm, onSuccess, className } = props;
  const contract = useSubscription(id);

  const orgState = useSpaceQuery(`query/organizations`, [
    spaceOrganizationSchema
  ]);

  const { loading: loadingOrganizations, data: organizations } = orgState;

  const resolvedForm = useMemo(() => {
    let form = {
      ...defaultForm,
      ...convertedForm
    };
    if (childProps) {
      form = { ...form, ...childProps };
    }

    if (
      !id &&
      organizations &&
      organizations.findIndex((e) => e === form.Company) === -1
    ) {
      form.Company = organizations[0];
    }

    return form;
  }, [defaultForm, convertedForm, childProps, id, organizations]);

  const {
    loading: loadingPipelines,
    data: pipelines,
    error: errorPipelines
  } = useSpaceQuery(`query/pipelines?type=${PipelineTypeEnum.Subscription}`, [
    spacePipelineSchema
  ]);

  const { data: formTemplates, refetch: formTemplateRefetch } =
    useFormTemplateGetter(spaceSubscriptionSchema);

  if (
    loadingPipelines ||
    errorPipelines ||
    loading ||
    error ||
    loadingOrganizations
  )
    return <LoaderSpinner center size="sm" className="text-secondary" />;

  if (!isOrganzationRequestValid(orgState))
    return <OrganizationRequestSideError requestState={orgState} />;

  const canEdit = contract ? contract.CanEdit : true;

  return (
    <FormTemplateProvider forms={formTemplates} refetch={formTemplateRefetch}>
      <SubscriptionFormBase
        className={className}
        onSuccess={onSuccess}
        id={id}
        pipelines={pipelines}
        canDelete={contract?.CanDelete}
        canEdit={canEdit}
        key={key}
        isChild={isChild}
        convertedForm={resolvedForm}
        organizations={organizations}
      />
    </FormTemplateProvider>
  );
};

const SubscriptionFormBase = ({
  convertedForm,
  id,
  canEdit,
  onSuccess,
  isChild,
  pipelines,
  canDelete,
  organizations,
  className
}) => {
  const formState = useFormState(() => {
    return {
      ...defaultSubscriptionForm,
      ...convertedForm
    };
  });
  const { form, setForm } = formState;
  // const {
  //   // SubscriptionTarget,
  //   // Description,
  //   // Contact,
  //   // EndDate,
  //   // Client,
  //   // SalesOpportunity,
  //   // Manager,
  //   // // SubscriptionType,
  //   // InternalDescription,
  //   // Covenant,
  //   // Files,
  //   // Creator,
  //   // Pipeline,
  //   // PipelineStatus,
  //   // Company,
  //   // BeginDate,
  //   // StatusDetailId,
  //   // // Comments,
  //   // CreationDate,
  //   // Followers,
  //   // TeamCollab,
  //   // IndirectClient
  // } = form;
  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceSubscriptionSchema);
  // const PipelineId = Pipeline;
  // const PipelineStatusId = PipelineStatus;
  // const PipelieStatusObj = usePipelineStatus(PipelineStatusId);
  const createToast = useToast();
  // useMarkRead(spaceSubscriptionSchema, id, Manager);
  const [post, { loading }] = useSidebarSpacePost(
    id ? `subscriptions/${id}` : "subscriptions",
    spaceSubscriptionSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        if (!id) {
          defineChildProps({ Client: newId });
          subscriptionCommunicator.dispatch({
            type: entityActionType.creation,
            id: newId
          });
        } else {
          subscriptionCommunicator.dispatch({
            type: entityActionType.update,
            id
          });
        }
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "SUBSCRIPTION" })} nº ${
            newId || id
          }`,
					description: !id ? `${intl.formatMessage({ id: "CREATED_SUCCEFULLY" })}` :`${intl.formatMessage({ id: "SAVE_SUCCESS" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `subscriptions/${id}`,
    spaceSubscriptionSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "SUBSCRIPTION" })} nº ${id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const handleSubmit = (form) => {
    post(formatSubscriptionForm(form));
  };

  return (
    <SideForm
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateSubscriptionForm}
    >
      <SideFormContent className={className}>
        <SubscriptionsFormContent
          formState={formState}
          canEdit={canEdit}
          organizations={organizations}
          pipelines={pipelines}
          id={id}
        />
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        deleteLoading={deleteLoading}
        form={form}
        handleChange={setForm}
        schema={spaceSubscriptionSchema}
        handleDelete={remove}
        isLoading={loading}
      />
    </SideForm>
  );
};

export default SubscriptionForm;

// class ContractForm extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       AssignedTo: null
//     };
//   }

//   handleChange = e => {
//     const { setFormState } = this.props;
//     const { name, value } = e.target;

//     setFormState({
//       [name]: value
//     });
//   };

//   handleClientChange = e => {
//     const { setFormState } = this.props;
//     const { name, value } = e.target;

//     setFormState({
//       [name]: value,
//       Contact: null
//     });
//   };

//   render() {
//     const { formStatus, form } = this.props;
//     const {
//       Client,
//       Description,
//       Contact,
//       EndDate,
//       EstimatedCost,
//       SalesOpportunity,
//       Manager,
//       SubscriptionType,
//       InternalDescription,
//       Covenant,
//       Files,
// 	  Deslocations,
// 	  Creator
//     } = form;
//     const { id } = formStatus;
//     const intl = this.props.intl;

//     return (
//       <SideMenuForm formStatus={formStatus}>
//         <div className="d-flex align-items-center my-2 flex-0-0-auto">
//           <FontAwesomeIcon className="mr-2" icon={faSuitcase} />
//           <span className="fs-14 fw-medium text-black">
//             {intl.formatMessage({ id: "customer" })}
//           </span>
//           <span className="title-id">{this.state.ClientId}</span>
//         </div>
//         <FormInput
//           className="mb-3"
//           name={"Client"}
//           inputType={ClientDropdown}
//           value={Client}
//           onChange={this.handleClientChange}
//         />
//         <FormInput
//           text={intl.formatMessage({ id: "description" })}
//           inputType={HTMLInput}
//           name="Description"
//           className="mb-3"
//           value={Description}
//           onChange={this.handleChange}
//         />

//         <SplitCol
//           className="my-3"
//           left={
//             <FormInput
//               name="Contact"
//               text={intl.formatMessage({ id: "contact" })}
//               inputType={ContactDropdown}
//               value={Contact}
//               Client={Client}
//               onChange={this.handleChange}
//             />
//           }
//           right={
//             <FormInput
//               name="Manager"
//               text={intl.formatMessage({ id: "responsible" })}
//               inputType={UserDropdown}
//               value={Manager}
//               onChange={this.handleChange}
//             />
//           }
//         />

//         <SplitCol
//           className="my-3"
//           left={
//             <FormInput
//               name="BeginDate"
//               text={intl.formatMessage({ id: "start" })}
//               inputType={ArDatePicker}
//               componentClassName="w-100"
//               openHourAfterDate
//             />
//           }
//           right={
//             <FormInput
//               name="EndDate"
//               text={intl.formatMessage({ id: "end" })}
//               inputType={ArDatePicker}
//               value={EndDate}
//               onChange={this.handleChange}
//               componentClassName="w-100"
//               openHourAfterDate
//             />
//           }
//         />
//         <SplitCol
//           className="mb-3"
//           left={
//             <FormInput
//               text={intl.formatMessage({ id: "estimated_time_hours" })}
//               name="EstimatedCost"
//               inputType={HourInput}
//               value={EstimatedCost}
//               onChange={this.handleChange}
//             />
//           }
//           right={
//             <FormInput
//               name="SalesOpportunity"
//               text={intl.formatMessage({ id: "sales_opportunity" })}
//               value={SalesOpportunity}
//               onChange={this.handleChange}
//             />
//           }
//         />

//         <FormInput
//           name="Deslocations"
//           className="mb-3"
//           text={intl.formatMessage({ id: "deslocations" })}
//           value={Deslocations}
//           onChange={this.handleChange}
//           inputType={NumberInput}
//         />

//         <FormInput
//           name="Covenant"
//           className="mb-3"
//           text={intl.formatMessage({ id: "renovation" })}
//           inputType={CovenantPeriodicity}
//           value={Covenant}
//         />

//         <FormInput
//           name="Departments"
//           className="mb-3"
//           text={intl.formatMessage({ id: "departments" })}
//           inputType={DepartmentDropdown}
//           multiple
//         />

//         <FormInput
//           name="TeamCollab"
//           className="mb-3"
//           text={intl.formatMessage({ id: "teams_contributors" })}
//           inputType={TeamCollabDropdown}
//           multiple
//         />

//         <FormInput
//           name="SubscriptionType"
//           className="mb-3"
//           text={intl.formatMessage({ id: "contract" })}
//           inputType={SubscriptionTypeDropdown}
//           value={SubscriptionType}
//           onChange={this.handleChange}
//         />
//         <ClipboardProvider>
//         <FormInput
//           className="mb-3"
//           text={intl.formatMessage({ id: "internal_note" })}
//           inputType={HTMLEditor}
//           name="InternalDescription"
//           value={InternalDescription}
//           onChange={this.handleChange}
//         />

//         {id && (
//           <FormInput
//             text={intl.formatMessage({ id: "status" })}
//             name="Status"
//             inputType={ContractStatusDropdown}
//             hideCancellation
//             className="mb-3"
//           />
//         )}

//         <FormInput
//           text={intl.formatMessage({ id: "files" })}
// 		  name="Files"
// 					className="mb-3 form-Anexos-floater"
//           inputType={FileUploader}
//           value={Files}
//           enableSwapper
//           onChange={this.handleChange}
//         />
//         </ClipboardProvider>
// 			{Creator &&
// 				<>
// 					<div className="mb-1 fs-14 fw-medium text-black">
// 						<span>
// 							Criado por
// 					</span>
// 					</div>
// 					<div className="mb-3 fs-14 fw-medium text-black">
// 						<ConnectedAccountProfile account={Creator} />
// 					</div>
// 				</>
// 			}
//       </SideMenuForm>
//     );
//   }
// }

// export default withSpaceEntityForm(withSpace(injectIntl(ContractForm)), {
//   defaultForm: defaultContractForm,
//   validateForm: validateContractForm,
//   schema: spaceContractSchema,
//   convertEntityToForm: convertContractEntityToForm,
//   dependencies: [
//     {
//       schema: spaceClientSchema,
//       getid: ({ clientId }) => clientId,
//       convertEntityToForm: (entity, props) => {
//         const { Id } = entity;
//         return {
//           Client: Id
//         };
//       }
//     }
//   ],
//   // getid: (state, ownProps) => ownProps.taskId,
//   formatForm: formatContractForm,
//   onSuccess: ({ openSidebar, form, id, createToast}) => {
//     createToast({pos: "br",type: "success",description: `Contrato gravado com Sucesso`})
//   },
// 	onError: ({ error, createToast }) => {
// 		handleError(createToast, error)
// 	}
// });
