import React, { useMemo } from "react";
import {
  useDetailsEntityId,
  useDetailsEntity
} from "../../../Components/DetailsView/DetailsView";
import {
  DetailsEntities,
  DetailsClientEntity,
  DetailsContactEntity,
  DetailsTicketQueryableEntities,
  DetailsTaskQueryableEntities
} from "../../../Components/DetailsView/DetailsChildren/DetailsChildren";
import { TasksStatusTypes } from "../../Tasks/TasksHelper";

export const ContractDetailsChildren = () => {
  const Id = useDetailsEntityId();
  const { Client, IndirectClient, Contact } = useDetailsEntity() || {};

  const formProps = useMemo(() => {
    return { contractId: Id };
  }, [Id]);

  return (
    <DetailsEntities formProps={formProps}>
      <DetailsClientEntity id={Client || IndirectClient} />
      <DetailsContactEntity id={Contact} />
      <DetailsTicketQueryableEntities
        filter={`(DirectIndirectContract/Id eq ${Id})`}
      />
      <DetailsTaskQueryableEntities
        filter={`(DirectIndirectContract/Id eq ${Id}) and (PipelineStatus/Status eq '${TasksStatusTypes.open}')`}
      />
    </DetailsEntities>
  );
};
