import React, { useMemo } from "react";
import { buildEntityOptions } from "./EntityPopupHelper";
import { useEntityValue } from "../../Hooks/EntityHooks";
import Treeview from "../Treeview/Treeview";

const SelectedEntityOverview = ({ onSelect, schema, id }) => {
  const { IconComponent, StatusComponent } = useMemo(
    () => buildEntityOptions(schema),
    [schema]
  );

  const item = useEntityValue(schema, id);

  const { Name, Id, NoTagDescription, Status } = item || {};

  const handleClick = () => {
    onSelect({
      Id: id,
      schema
    });
  };

  return (
    <div
      onClick={handleClick}
      className="fw-medium text-black d-flex justify-content-between mb-2 cursor-pointer"
    >
      <div className="text-truncate d-flex align-items-center">
        <StatusComponent className="mr-1" status={Status} hideName />
        <IconComponent />
        <span className="ml-2 text-truncate">{Name || NoTagDescription}</span>
      </div>
      <div className="ml-2 flex-0-0-auto">Nº {Id}</div>
    </div>
  );
};

const EntityPopupTreeview = ({ schema, id, onItemChange, forcedType }) => {
  return (
    <div className="ar-entity-popup-tree d-flex flex-column h-100 p-3 border-right">
      <SelectedEntityOverview onSelect={onItemChange} id={id} schema={schema} />
      <Treeview
        disablePopup
        forcedType={forcedType}
        onSelect={onItemChange}
        disableActionButtons
        className="flex-1"
        schema={schema}
        id={id}
      />
    </div>
  );
};

export default EntityPopupTreeview;
