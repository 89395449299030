import {
  ptConfig,
  Intl18nContext,
} from "../Containers/Intl18n/Intl18nProvider";
import { useContext, useMemo } from "react";

export const getPrivacyPolicyUrl = (lang) => {
  switch (lang) {
    case ptConfig:
      return "https://www.airdesk.ai/politica-de-privacidade";

    default:
      return "https://www.airdesk.ai/en/privacy-policy/";
  }
};

export const getTermsAndConditionsUrl = (lang) => {
  switch (lang) {
    case ptConfig:
      return "https://www.airdesk.ai/termos-e-condicoes/";

    default:
      return "https://www.airdesk.ai/en/terms-of-use/";
  }
};

export const usePrivacyPolicyUrl = () => {
  const { value } = useContext(Intl18nContext);
  const url = useMemo(() => getPrivacyPolicyUrl(value), [value]);

  return url;
};

export const useTermsAndConditionsUrl = () => {
  const { value } = useContext(Intl18nContext);
  const url = useMemo(() => getTermsAndConditionsUrl(value), [value]);

  return url;
};
