import React from "react";
import "../Loader.css";
import classnames from "classnames";

const LoaderSpinner = props => {
  const { className, size, center, svgOnly, centerHorizontal } = props;

  const svgComp = (
    <svg className={classnames("loader-spinner", size)} viewBox="25 25 50 50">
      <circle
        className="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );

  if (svgOnly) return svgComp;

  if (centerHorizontal)
    return (
      <div
        className={classnames(
          "d-flex align-items-center justify-content-center",
          className
        )}
      >
        {svgComp}
      </div>
    );

  if (center)
    return (
      <div
        className={classnames(
          "h-100 d-flex align-items-center justify-content-center",
          className
        )}
      >
        {svgComp}
      </div>
    );
  else return <div className={classnames(className)}>{svgComp}</div>;
};

export default LoaderSpinner;
