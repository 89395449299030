import React from "react";

import { Switch, Route, withRouter } from "react-router-dom";
import {
  useEnterViewSignalR,
  PageTypeProvider
} from "../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimePageEnum } from "../RealTime/RealTimePages";
// import {
//   RealTimePageProvider,
//   RealTimePageEnum
// } from "../RealTime/RealTimePages";
import CallDetails from "./CallDetails/CallDetails";
import CallOverview from "./CallOverview/CallOverview";

const Calls = withRouter(({ match }) => {
  // useEnterViewSignalR(RealTimePageEnum.Call)
  return (
    <PageTypeProvider pageType={RealTimePageEnum.Call}>
      <Switch>
        <Route path={`${match.url}/:callId`}>
          <CallDetails />
        </Route>
        <Route>
          <CallOverview />
        </Route>
      </Switch>
    </PageTypeProvider>
  );
});

export default Calls;
