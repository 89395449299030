import React from "react";
import {
  spaceTasksSchema,
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceInterventionsSchema,
  spaceCallsSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceContactSchema,
  spaceClientSchema,
  spaceSubscriptionSchema
} from "../../config/schema";
import {
  TaskIcon,
  ProjectIcon,
  TicketIcon,
  InterventionIcon,
  CallIcon,
  ClientIcon,
  ContractIcon,
  DealIcon,
  ContactIcon,
  SubscriptionIcon
} from "../../Components/Common/EntityIcons";
import { ProjectStatusProfile } from "../../Components/Projects/ProjectProfile";
import { ClientStatusProfile } from "../../Components/Clients/ClientProfile";
import { ContractStatusProfile } from "../../Components/Contract/ContractProfile";
import DealStatusProfile from "../../Components/Deals/DealStatusProfile";
import { ContactStatusProfile } from "../../Components/Contacts/ContactProfile";
import { SubscriptionStatusProfile } from "../../Components/Subscription/SubscriptionProfile";
import SubscriptionSummary from "../Subscriptions/SubscriptionSummary";

const ClientSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "client-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Clients/ClientSummary"
  )
);

const ProjectSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "project-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Projects/ProjectSummary/ProjectSummary"
  )
);

const ContactSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "contact-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Contacts/ContactSummary"
  )
);

const DealSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "deal-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Deals/DealSummary"
  )
);

const ContractSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "contract-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Contracts/ContractSummary"
  )
);

const CallSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "call-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Calls/CallSummary"
  )
);

const TicketSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "ticket-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Tickets/TicketSummary"
  )
);

const TaskSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "task-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Tasks/TaskSummary"
  )
);

const InterventionSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "intervention-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Interventions/InterventionSummary"
  )
);

export const buildEntityOptions = (schema) => {
  let IconComponent, DetailsComponent, StatusComponent;

  switch (schema) {
    case spaceTasksSchema:
      StatusComponent = ProjectStatusProfile;
      IconComponent = TaskIcon;
      DetailsComponent = TaskSummary;
      break;
    case spaceProjectsSchema:
      StatusComponent = ProjectStatusProfile;
      IconComponent = ProjectIcon;
      DetailsComponent = ProjectSummary;
      break;
    case spaceTicketsSchema:
      StatusComponent = ProjectStatusProfile;
      IconComponent = TicketIcon;
      DetailsComponent = TicketSummary;
      break;
    case spaceInterventionsSchema:
      StatusComponent = ProjectStatusProfile;
      IconComponent = InterventionIcon;
      DetailsComponent = InterventionSummary;
      break;
    case spaceCallsSchema:
      StatusComponent = ProjectStatusProfile;
      IconComponent = CallIcon;
      DetailsComponent = CallSummary;
      break;
    case spaceClientSchema:
      StatusComponent = ClientStatusProfile;
      IconComponent = ClientIcon;
      DetailsComponent = ClientSummary;
      // DetailsComponent = ClientSummary;
      break;
    case spaceContractSchema:
      StatusComponent = ContractStatusProfile;
      IconComponent = ContractIcon;
      DetailsComponent = ContractSummary;
      // DetailsComponent = ContractReadOverview;
      break;
    case spaceDealsSchema:
      StatusComponent = DealStatusProfile;
      IconComponent = DealIcon;
      DetailsComponent = DealSummary;
      // DetailsComponent = DealSummary;
      break;

    case spaceContactSchema:
      StatusComponent = ContactStatusProfile;
      IconComponent = ContactIcon;
      DetailsComponent = ContactSummary;
      // DetailsComponent = ContactReadOverview;
      break;

    case spaceSubscriptionSchema:
      StatusComponent = SubscriptionStatusProfile;
      IconComponent = SubscriptionIcon;
      DetailsComponent = SubscriptionSummary;
      // DetailsComponent = ContactReadOverview;
      break;

    default:
      break;
  }

  return { IconComponent, DetailsComponent, StatusComponent };
};
