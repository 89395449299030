import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  spaceCallsSchema,
  spaceClientSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceEmailConversationSchema,
  spaceInterventionsSchema,
  spaceProjectsSchema,
  spaceSubscriptionSchema,
  spaceTasksSchema,
  spaceTicketsSchema
} from "../../config/schema";
import { useToast } from "../Toast/ToastProvider";
import { ServerAwareContext } from "./ServerGrid";

export const SchemaTranslationFunct = (schema = {}) => {
  switch (schema.name) {
    case spaceEmailConversationSchema.name:
      return "EMAIL";
    case spaceCallsSchema.name:
      return "CALLS";
    case spaceProjectsSchema.name:
      return "PROJECTS";
    case spaceTicketsSchema.name:
      return "CASES";
    case spaceTasksSchema.name:
      return "TASKS";
    case spaceInterventionsSchema.name:
      return "TIMES";
    case spaceDealsSchema.name:
      return "DEALS";
    case spaceContractSchema.name:
      return "CONTRACTS";
    case spaceClientSchema.name:
      return "ACCOUNTS";
    case spaceSubscriptionSchema.name:
      return "SUBSCRIPTIONS";
    default:
      return null;
  }
};

function findVal(object, key) {
  var value;
  // eslint-disable-next-line array-callback-return
  Object.keys(object).some(function (k) {
    if (k === key) {
      value = object[k];
      return true;
    }
    if (object[k] && typeof object[k] === "object") {
      value = findVal(object[k], key);
      return value !== undefined;
    }
  });
  return value;
}

export const useHandleError = (subSchema) => {
  const createToast = useToast();
  const { schema } = useContext(ServerAwareContext) || subSchema;
  const intl = useIntl();
  const resolvedModuleName = SchemaTranslationFunct(schema);
  return (error) => {
    handleErrorWithTranslations(createToast, error, intl, resolvedModuleName);
  };
};

const DependenciesDictionary = {
  1: "DEALS",
  2: "CONTRACTS",
  3: "SUBSCRIPTIONS",
  4: "BILLING",
  5: "CALLS",
  6: "PROJECTS",
  7: "CASES",
  8: "TASK",
  9: "TIMECHARGE",
  10: "EMAIL_SIGNATURE",
  11: "DEPARTMENT_OR_TEAM",
  13: "WIDGET",
  14: "DOMAIN",
  15: "EMAIL",
  17: "CHECKLIST",
  18: "CONVERSATION"
};

const handleErrorWithTranslations = (createToast, error, intl, schemaName) => {
  let msgKey = findVal(error, "MessageKey");
  if (msgKey) {
    let ids = findVal(error, "Ids");
    let data = findVal(error, "Data");
    let dependsString = "";
    if (data) {
      let dependencies = JSON.parse(findVal(data, "Dependencies") || "{}");
      if (dependencies && dependencies.length > 0) {
        const translated_dependencies = dependencies.map((e) => {
          return intl.formatMessage({ id: DependenciesDictionary[e] });
        });
        dependsString = translated_dependencies.join(", ");
      }
    }
    createToast({
      pos: "tm",
      type: "danger",
      description: (
        <FormattedMessage
          id={msgKey}
          values={{ ModulesEnum: schemaName, Ids: ids, Depends: dependsString }}
        />
      ),
      title: intl.formatMessage({ id: "ERROR" })
    });
  } else {
    let resolvedMsg = findVal(error, "Message");
    if (typeof resolvedMsg === "object") {
      resolvedMsg = findVal(resolvedMsg, "Message");
    }

    if (!resolvedMsg) {
      resolvedMsg = <FormattedMessage id="ERROR_OCCURRED" />;
    }

    try {
      const trasnlatedMessage = intl.formatMessage({ id: resolvedMsg });
      createToast({
        pos: "tm",
        type: "danger",
        description: trasnlatedMessage,
        title: intl.formatMessage({ id: "ERROR" })
      });
    } catch (error) {
      resolvedMsg = <FormattedMessage id="ERROR_OCCURRED" />;
      createToast({
        pos: "tm",
        type: "danger",
        description: resolvedMsg,
        title: intl.formatMessage({ id: "ERROR" })
      });
    }
  }
};
