import React, { useEffect } from "react";
import "../GlobalSearch.css";
import {
  useContact,
  useClient,
  useContract,
  useProject,
  useAccount,
  useTicket,
  useTask,
  useCall,
  useIntervention,
  useSubscription,
  useDeal
} from "../../../Hooks/EntityHooks";
import RelativeTime from "../../RelativeTime/RelativeTime";
import { AccountProfileImg } from "../../Img/Img";
import { ProjectStatusProfile } from "../../Projects/ProjectProfile";
import TextEllipsis from "../../TextEllipsis/TextEllipsis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListCheck,
  faPhone,
  faTicketSimple,
  faSuitcase,
	faSquareParking
} from "@fortawesome/pro-light-svg-icons";
import { GridDateComponent } from "../../CGrid/CGrid";
import FormattedTimePreview from "../../FormattedTimePreview/FormattedTimePreview";
import { MilSecToHour } from "../../../Helpers/MiscHelper";
import { useSchemaQuery, useSpaceQuery } from "../../../Helpers/IOClient";
import {
  spaceClientSchema,
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceCallsSchema,
  spaceTasksSchema,
  spaceContractSchema,
  AccountDetailsResponse,
  spaceContactSchema,
  spaceDealsSchema,
  spaceSubscriptionSchema
} from "../../../config/schema";
import LoaderSpinner from "../../Loader/LoaderSpinner/LoaderSpinner";
import { ConnectedContractOriginAnchor } from "../../../Containers/Origin/ContractOrigin";
import { ClientEntityGridButtons } from "../../../Containers/Clients/ClientsHelper";
import { useGlobalSearchCloser } from "../GlobalSearch";
import { ProjectEntityGridButtons } from "../../../Containers/Projects/ProjectsHelper";
import { TicketEntityGridButtons } from "../../../Containers/Tickets/TicketsHelper";
import { CallEntityGridButtons } from "../../../Containers/Calls/CallsHelper";
import { TaskEntityGridButtons } from "../../../Containers/Tasks/TasksHelper";
import { ContractEntityGridButtons } from "../../../Containers/Contracts/ContractsHelper";
import {
  //   ConnectedOrganizationProfileStack,
  ConnectedTeamProfileStack,
  ConnectedDepartmentProfileStack
} from "../../Accounts/AccountProfileStack";
// import { ConnectedOrganizationProfile } from "../../Organizations/OrganizationProfile";
import { ConnectedTeamProfile } from "../../Teams/TeamProfile";
import { ConnectedDepartmentProfile } from "../../Departments/DepartmentProfile";
import { FormattedMessage } from "react-intl";
import { AccountEntityActionsButton } from "../../../Containers/Accounts/AccountHelper";
import { ContactEntityGridButtons } from "../../../Containers/Contacts/ContactsHelper";
import { SubscriptionEntityButtons } from "../../../Containers/Subscriptions/SubscriptionsHelper";
import { DealEntityGridButtons } from "../../../Containers/Deals/DealsHelper";
import { EntityPopupContext } from "../../EntityPopup/EntityPopupHelper";
import classnames from "classnames";
import { useCurrentAccount } from "../../../Contexts/UserContext";
import { isDeveloper } from "../../../Helpers/ModulesHelper";
import { Link } from "react-router-dom";
import { useSpace } from "../../../Contexts/SpaceContext";

// const SearchPreviewContact = ({ onClose }) => {
//   const contact = useContact(1124);

//   const { Name, Email, Clients, CreationDate, PhoneNumber } = contact;

//   const ClientLine = ({ Client, Index, Length }) => {
//     const client = useClient(Client);
//     if (Index < Length - 1) {
//       return `${client.Name}, `;
//     } else {
//       return `${client.Name}`;
//     }
//   };

//   const clientLine = Clients.map((e, index, array) => {
//     return (
//       <ClientLine key={e} Client={e} Index={index} Length={array.length} />
//     );
//   });

//   return (
//     <div className="ar-searchPreviewSquare">
//       <div className="ar-previewAvatar d-flex align-items-center justify-content-center">
//         <AccountProfileImg
//           size={"lg"}
//           account={contact}
//           circular
//           className="mr-1"
//         />
//       </div>
//       <div className="ar-previewName d-flex align-items-center justify-content-center">
//         <b>{Name}</b>
//       </div>

//       <div className="ar-previewEmail d-flex align-items-center justify-content-center">
//         <a href={`mailto:${Email}`} target="_blank" rel="noopener noreferrer">
//           {Email}
//         </a>
//       </div>
//       <br />
//       <div>
//         <div>
//           <FormattedMessage id={"PHONE_NUMBER"} />:{" "}
//           <b>{PhoneNumber.split(".").join(" ")}</b>
//         </div>
//         <div>
//           <FormattedMessage id={"COMPANIES"} />: <b>{clientLine}</b>
//         </div>
//         <div>
//           <FormattedMessage id={"CREATION_DATE"} />:{" "}
//           <b>
//             <RelativeTime date={CreationDate} />
//           </b>
//         </div>
//       </div>
//     </div>
//   );
// };

const useSearchSchemaQuery = (schema, id) => {
  const queryState = useSchemaQuery(schema, id, {
    autoFetch: false
  });

  const { refetch, data, loading } = queryState;

  useEffect(() => {
    if (data || loading) return;
    const timeout = setTimeout(() => {
      refetch();
    }, 300);

    return () => clearTimeout(timeout);
  }, [refetch, data, loading]);

  const hasLoaded = Boolean(data);

  return hasLoaded;
};

const SearchPreviewContainer = ({ children }) => {
  return (
    <div className="ar-searchPreviewSquare d-flex flex-column">{children}</div>
  );
};

const SearchPreviewContent = ({ children, hasLoaded }) => {
  if (!hasLoaded)
    return <LoaderSpinner size="xs" center className="text-primary mt-2" />;

  return (
    <div className="of-y-auto">
      <div className="p-4">{children}</div>
    </div>
  );
};

const SearchPreviewFooter = ({ show = true, children }) => {
  if (!show) return null;

  return (
    <div className="py-3 px-3 shadow-top ar-global-search-buttons flex-wrap d-flex align-items-center justify-content-center">
      {children}
    </div>
  );
};

const SearchPreviewTitle = ({ children, noImage, query, entity }) => {
  const space = useSpace();
  const close = useGlobalSearchCloser();
  if (!query) {
    return (
      <div
        className={classnames("fs-20 text-center", {
          "mt-5": noImage,
          "mt-3": !noImage
        })}
      >
        <b>{children}</b>
      </div>
    );
  }

  const { getLink } = query;
  const url = getLink({ id: entity.Id });

  return (
    <Link
      onClick={close}
      to={`/s/${space.Id}/${url}`}
      className={classnames("fs-20 text-center d-block", {
        "mt-5": noImage,
        "mt-3": !noImage
      })}
    >
      <b>{children}</b>
    </Link>
  );
};

const SearchPreviewLink = ({ href, children }) => {
  return (
    <a
      href={href}
      className="text-center d-block"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

const SearchPreviewBody = ({ show = true, children }) => {
  // if (!show)
  //   return (
  //     <LoaderSpinner size="xs" centerHorizontal className="text-primary mt-2" />
  //   );

  return children;
};

const SearchPreviewEntityFooter = ({ Type, show, entity }) => {
  const close = useGlobalSearchCloser();
  if (Type) {
    return (
      <SearchPreviewFooter show={show}>
        <Type entity={entity} onSuccess={close} />
      </SearchPreviewFooter>
    );
  } else {
    return <SearchPreviewFooter show={show}></SearchPreviewFooter>;
  }
};

const SearchPreviewButtons = ({ Buttons, entity, hasLoaded, developer }) => {
  const close = useGlobalSearchCloser();
  if (!hasLoaded) return null;
  return (
    <EntityPopupContext.Provider value={close}>
      <div className="ar-global-search-preview-buttons p-1 rounded">
        <Buttons
          hideBreadCrumbs
          minimal
          entity={entity}
          developer={developer}
        />
      </div>
    </EntityPopupContext.Provider>
  );
};

const SearchPreviewContacts = ({ value, query }) => {
  const contact = useContact(value);

  const { Name, Email, MobileNumber, CreationDate, PhoneNumber } = contact;

  const hasLoaded = useSearchSchemaQuery(spaceContactSchema, value);

  return (
    <SearchPreviewContainer>
      <SearchPreviewContent hasLoaded={hasLoaded}>
        <AccountProfileImg
          size={"lg"}
          account={contact}
          circular
          className="mx-auto"
        />
        <SearchPreviewTitle query={query} entity={contact}>
          {Name}
        </SearchPreviewTitle>

        <SearchPreviewLink href={`mailto:${Email}`}>{Email}</SearchPreviewLink>
        <SearchPreviewBody>
          <div className="py-3">
            {PhoneNumber && (
              <div>
                <FormattedMessage id={"PHONE_NUMBER"} />:{" "}
                <b>{PhoneNumber.split(".").join(" ")}</b>
              </div>
            )}
            {MobileNumber && (
              <div>
                <FormattedMessage id={"MOBILE_NUMBER"} />:{" "}
                <b>{MobileNumber.split(".").join(" ")}</b>
              </div>
            )}
            <div>
              <FormattedMessage id={"CREATION_DATE"} />:{" "}
              <b>
                <RelativeTime date={CreationDate} />
              </b>
            </div>
          </div>
        </SearchPreviewBody>
      </SearchPreviewContent>
      <SearchPreviewButtons
        entity={contact}
        hasLoaded={hasLoaded}
        Buttons={ContactEntityGridButtons}
      />
    </SearchPreviewContainer>
  );
};

const SearchPreviewClient = ({ value, query }) => {
  const client = useClient(value);
  const { Name, Email, Locale, CreationDate, PhoneNumber, Contracts } = client;

  const hasLoaded = useSearchSchemaQuery(spaceClientSchema, value);

  return (
    <SearchPreviewContainer>
      <SearchPreviewContent hasLoaded={hasLoaded}>
        <AccountProfileImg
          size={"lg"}
          account={client}
          circular
          className="mx-auto"
        />
        <SearchPreviewTitle query={query} entity={client}>
          {Name}
        </SearchPreviewTitle>

        <SearchPreviewLink href={`mailto:${Email}`}>{Email}</SearchPreviewLink>
        <SearchPreviewBody>
          <div className="py-3">
            {Locale && (
              <div>
                <FormattedMessage id={"LOCATION"} />: <b>{Locale}</b>
              </div>
            )}
            {PhoneNumber && (
              <div>
                <FormattedMessage id={"PHONE_NUMBER"} />:{" "}
                <b>{PhoneNumber.split(".").join(" ")}</b>
              </div>
            )}

            <div>
              <FormattedMessage id={"CREATION_DATE"} />:{" "}
              <b>
                <RelativeTime date={CreationDate} />
              </b>
            </div>
          </div>
          {Contracts && Contracts.length > 0 && (
            <div>
              <div>
                <FormattedMessage id={"ASSOCIATED_CONTRACTS"} />:
              </div>
              {Contracts.map((t) => (
                <ConnectedContractOriginAnchor key={t} contract={t} />
              ))}
            </div>
          )}
        </SearchPreviewBody>
      </SearchPreviewContent>
      <SearchPreviewButtons
        entity={client}
        hasLoaded={hasLoaded}
        Buttons={ClientEntityGridButtons}
      />
    </SearchPreviewContainer>
  );
};

const SearchPreviewSubscription = ({ value, query }) => {
  const subscription = useSubscription(value);

  const {
    Client,
    Contact,
    BeginDate,
    // ContractType,
    EndDate,
    EstimatedCost,
    SpentHours,
    Description
  } = subscription;

  const client = useClient(Client);
  const contact = useContact(Contact);
  // const contractType = useContractType(ContractType);
  const hasLoaded = useSearchSchemaQuery(spaceSubscriptionSchema, value);

  return (
    <SearchPreviewContainer>
      <SearchPreviewContent>
        {client && (
          <AccountProfileImg
            size={"lg"}
            account={client}
            circular
            className="mb-1 mx-auto"
          />
        )}
        <SearchPreviewTitle
          query={query}
          entity={subscription}
          noImage={!Boolean(client)}
        >
          {Description}
        </SearchPreviewTitle>
        <SearchPreviewBody show={hasLoaded}>
          <div className="py-3">
            {contact && (
              <div>
                <FormattedMessage id={"CONTACT"} />: <b>{contact.Name}</b>
              </div>
            )}
            <div>
              <FormattedMessage id={"START_DATE"} />:{" "}
              <b>
                <RelativeTime date={BeginDate} />
              </b>
            </div>
            <div>
              <FormattedMessage id={"END_DATE"} />:{" "}
              <b>
                <RelativeTime date={EndDate} />
              </b>
            </div>
            <div>
              <FormattedMessage id={"ESTIMATED_TIME"} />:{" "}
              <b>{MilSecToHour(EstimatedCost, true)}</b>
            </div>
            <div>
              <FormattedMessage id={"SPENT_HOURS"} />:{" "}
              <b>{MilSecToHour(SpentHours ? SpentHours : 0, true)}</b>
            </div>
          </div>
        </SearchPreviewBody>
      </SearchPreviewContent>
      <SearchPreviewEntityFooter
        Type={SubscriptionEntityButtons}
        show={hasLoaded}
        entity={subscription}
      />
    </SearchPreviewContainer>
  );
};

const SearchPreviewDeals = ({ value, query }) => {
  const deal = useDeal(value);

  const {
    Client,
    Contact,
    BeginDate,
    // ContractType,
    EndDate,
    Name
    // Description,
  } = deal;

  const client = useClient(Client);
  const contact = useContact(Contact);
  // const contractType = useContractType(ContractType);
  const hasLoaded = useSearchSchemaQuery(spaceDealsSchema, value);

  return (
    <SearchPreviewContainer>
      <SearchPreviewContent hasLoaded={hasLoaded}>
        {client && (
          <AccountProfileImg
            size={"lg"}
            account={client}
            circular
            className="mx-auto"
          />
        )}
        <SearchPreviewTitle
          query={query}
          entity={deal}
          noImage={!Boolean(client)}
        >
          {Name}
        </SearchPreviewTitle>
        <SearchPreviewBody>
          <div className="py-3">
            {contact && (
              <div>
                <FormattedMessage id={"CONTACT"} />: <b>{contact.Name}</b>
              </div>
            )}
            <div>
              <FormattedMessage id={"START_DATE"} />:{" "}
              <b>
                <RelativeTime date={BeginDate} />
              </b>
            </div>
            <div>
              <FormattedMessage id={"END_DATE"} />:{" "}
              <b>
                <RelativeTime date={EndDate} />
              </b>
            </div>
          </div>
        </SearchPreviewBody>
      </SearchPreviewContent>
      <SearchPreviewButtons
        entity={deal}
        hasLoaded={hasLoaded}
        Buttons={DealEntityGridButtons}
      />
    </SearchPreviewContainer>
  );
};

const SearchPreviewContract = ({ value, query }) => {
  const contract = useContract(value);

  const {
    Client,
    Contact,
    BeginDate,
    // ContractType,
    EndDate,
    EstimatedCost,
    SpentHours,
    Description
  } = contract;

  const client = useClient(Client);
  const contact = useContact(Contact);
  // const contractType = useContractType(ContractType);
  const hasLoaded = useSearchSchemaQuery(spaceContractSchema, value);

  return (
    <SearchPreviewContainer>
      <SearchPreviewContent hasLoaded={hasLoaded}>
        {client && (
          <AccountProfileImg
            size={"lg"}
            account={client}
            circular
            className="mx-auto"
          />
        )}
        <SearchPreviewTitle
          query={query}
          entity={contract}
          noImage={!Boolean(client)}
        >
          {Description}
        </SearchPreviewTitle>
        <SearchPreviewBody>
          <div className="py-3">
            {contact && (
              <div>
                <FormattedMessage id={"CONTACT"} />: <b>{contact.Name}</b>
              </div>
            )}
            <div>
              <FormattedMessage id={"START_DATE"} />:{" "}
              <b>
                <RelativeTime date={BeginDate} />
              </b>
            </div>
            <div>
              <FormattedMessage id={"END_DATE"} />:{" "}
              <b>
                <RelativeTime date={EndDate} />
              </b>
            </div>
            <div>
              <FormattedMessage id={"ESTIMATED_TIME"} />:{" "}
              <b>{MilSecToHour(EstimatedCost, true)}</b>
            </div>
            <div>
              <FormattedMessage id={"SPENT_HOURS"} />:{" "}
              <b>{MilSecToHour(SpentHours ? SpentHours : 0, true)}</b>
            </div>
          </div>
        </SearchPreviewBody>
      </SearchPreviewContent>
      <SearchPreviewButtons
        entity={contract}
        hasLoaded={hasLoaded}
        Buttons={ContractEntityGridButtons}
      />
    </SearchPreviewContainer>
  );
};

export const SearchPreviewAccount = ({ value, query }) => {
  const account = useAccount(value);

  const { Id, Name, Email } = account;

  //   const client = useClient(Client);
  //   const contact = useContact(Contact);
  // const contractType = useContractType(ContractType);

  const { data } = useSpaceQuery(`User/${Id}`, AccountDetailsResponse);
  const { AccountSpace, Teams, Departments } = data || {};
  const hasLoaded = Boolean(data);

  const thisAccount = useCurrentAccount();

  const developer = isDeveloper(thisAccount);

  return (
    <SearchPreviewContainer>
      <SearchPreviewContent hasLoaded={hasLoaded}>
        {Id && (
          <AccountProfileImg
            size={"lg"}
            account={account}
            circular
            className="mx-auto"
          />
        )}
        <SearchPreviewTitle>{Name}</SearchPreviewTitle>
        <SearchPreviewBody>
          {Email && (
            <div className="pt-2">
              <FormattedMessage id={"EMAIL"} />:{" "}
              <a href={`mailto:${Email}`}>
                <b>{Email}</b>
              </a>
            </div>
          )}
          {AccountSpace && Boolean(AccountSpace.PhoneExtension) && (
            <div className="pt-2">
              <FormattedMessage id={"EXTENSION"} />:{" "}
              <b>{AccountSpace.PhoneExtension}</b>
            </div>
          )}
          {/* {Organizations ? (
            Organizations.length > 1 ? (
              <div className="d-flex align-items-center pt-2">
                <span className="mr-1">Empresas: </span>
                <ConnectedOrganizationProfileStack
                  organizations={Organizations}
                />
              </div>
            ) : Organizations.length > 0 ? (
              <div className="d-flex align-items-center pt-2">
                <span className="mr-1">Empresa: </span>
                <ConnectedOrganizationProfile organization={Organizations[0]} />
              </div>
            ) : (
              null
            )
          ) : (
            null
          )} */}
          {Teams ? (
            Teams.length > 1 ? (
              <div className="d-flex align-items-center pt-2">
                <span className="mr-1">
                  <FormattedMessage id={"TEAMS"} />:{" "}
                </span>
                <ConnectedTeamProfileStack teams={Teams} />
              </div>
            ) : Teams.length > 0 ? (
              <div className="d-flex align-items-center pt-2">
                <span className="mr-1">
                  <FormattedMessage id={"TEAM"} />:{" "}
                </span>
                <ConnectedTeamProfile team={Teams[0]} />
              </div>
            ) : null
          ) : null}
          {Departments ? (
            Departments.length > 1 ? (
              <div className="d-flex align-items-center pt-2">
                <span className="mr-1">
                  <FormattedMessage id={"DEPARTMENTS"} />:{" "}
                </span>
                <ConnectedDepartmentProfileStack departments={Departments} />
              </div>
            ) : Departments.length > 0 ? (
              <div className="d-flex align-items-center pt-2">
                <span className="mr-1">
                  <FormattedMessage id={"DEPARTMENT"} />:{" "}
                </span>
                <ConnectedDepartmentProfile department={Departments[0]} />
              </div>
            ) : null
          ) : null}
        </SearchPreviewBody>
      </SearchPreviewContent>
      <SearchPreviewButtons
        entity={account}
        hasLoaded={hasLoaded}
        developer={developer}
        Buttons={AccountEntityActionsButton}
      />
    </SearchPreviewContainer>
  );
};

const SearchPreviewProject = ({ value, query }) => {
  const Project = useProject(value);

  const {
    BeginDate,
    EndDate,
    Name,

    Manager,

    EstimatedCost,
    SpentHours
  } = Project;

  const manager = useAccount(Manager);

  const hasLoaded = useSearchSchemaQuery(spaceProjectsSchema, value);

  return (
    <SearchPreviewContainer>
      <SearchPreviewContent hasLoaded={hasLoaded}>
        <AccountProfileImg
          size={"lg"}
          account={Project}
          circular
          className="mx-auto"
        />
        <SearchPreviewTitle query={query} entity={Project}>
          {Name}
        </SearchPreviewTitle>
        <SearchPreviewBody>
          <div className="py-3">
            {manager && (
              <div>
                <FormattedMessage id={"MANAGER"} />: <b>{manager.Name}</b>
              </div>
            )}
            {Boolean(EstimatedCost) && (
              <div>
                <FormattedMessage id={"ESTIMATED_TIME"} />:{" "}
                <b>{MilSecToHour(EstimatedCost, true)}</b>
              </div>
            )}
            {Boolean(SpentHours) && (
              <div>
                <FormattedMessage id={"SPENT_HOURS"} />:{" "}
                <b>{MilSecToHour(SpentHours, true)}</b>
              </div>
            )}
            <div>
              <FormattedMessage id={"START_DATE"} />:{" "}
              <b>
                <RelativeTime date={BeginDate} />
              </b>
            </div>
            <div>
              <FormattedMessage id={"END_DATE"} />:{" "}
              <b>
                <RelativeTime date={EndDate} />
              </b>
            </div>
          </div>
        </SearchPreviewBody>
      </SearchPreviewContent>
      <SearchPreviewButtons
        entity={Project}
        hasLoaded={hasLoaded}
        Buttons={ProjectEntityGridButtons}
      />
    </SearchPreviewContainer>
  );
};

const SearchPreviewTicket = ({ value, query }) => {
  const Ticket = useTicket(value);

  const { Contact, Name, Status, ManagerAccount, ReceivedDate } = Ticket;

  const contact = useContact(Contact);
  const manager = useAccount(ManagerAccount);

  const hasLoaded = useSearchSchemaQuery(spaceTicketsSchema, value);

  return (
    <SearchPreviewContainer>
      <SearchPreviewContent hasLoaded={hasLoaded}>
        <SearchPreviewTitle query={query} entity={Ticket} noImage>
          {Name}
        </SearchPreviewTitle>

        <SearchPreviewBody>
          <div className="py-3">
            {manager && (
              <div>
                <FormattedMessage id={"MANAGER"} />: <b>{manager.Name}</b>
              </div>
            )}
            {contact && (
              <div>
                <FormattedMessage id={"CONTACT"} />: <b>{contact.Name}</b>
              </div>
            )}
            {contact && contact.PhoneNumber && (
              <div>
                <FormattedMessage id={"PHONE_NUMBER"} />:{" "}
                <b>{contact.PhoneNumber.split(".").join(" ")}</b>
              </div>
            )}
            <div style={{ display: "inline-flex" }}>
              <span style={{ marginRight: 7 }}>
                <FormattedMessage id={"STATUS"} />:
              </span>{" "}
              <ProjectStatusProfile className="blueStatus" status={Status} />
            </div>
            <div>
              <FormattedMessage id={"CREATION_DATE"} />:{" "}
              <b>
                <RelativeTime date={ReceivedDate} />
              </b>
            </div>
          </div>
        </SearchPreviewBody>
      </SearchPreviewContent>
      <SearchPreviewButtons
        entity={Ticket}
        hasLoaded={hasLoaded}
        Buttons={TicketEntityGridButtons}
      />
    </SearchPreviewContainer>
  );
};

const SearchPreviewTask = ({ value, query }) => {
  const TaskI = useTask(value);

  const {
    IndirectClient,
    Client,
    Name,
    EstimatedCost,
    Status,
    Manager,
    BeginDate
  } = TaskI;

  const client = useClient(Client || IndirectClient);
  const user = useAccount(Manager);

  const hasLoaded = useSearchSchemaQuery(spaceTasksSchema, value);

  return (
    <SearchPreviewContainer>
      <SearchPreviewContent hasLoaded={hasLoaded}>
        <SearchPreviewTitle query={query} entity={TaskI} noImage>
          {Name}
        </SearchPreviewTitle>
        <SearchPreviewBody>
          <div className="py-3">
            {/* <div>
          Origem:{" "}
          <b>
            {contract ? (
              <FontAwesomeIcon icon={faFileContract} />
            ) : task ? (
              <FontAwesomeIcon icon={faListCheck} />
            ) : call ? (
              <FontAwesomeIcon icon={faPhone} />
            ) : project ? (
              <FontAwesomeIcon icon={faParkingCircle} />
            ) : ticket ? (
              <FontAwesomeIcon icon={faTicketSimple} />
            ) : indirectClient ? (
              <FontAwesomeIcon icon={faSuitcase} />
            ) : (
              <FontAwesomeIcon icon={faSuitcase} />
            )}{" "}
            {contract ? (
              contract.Name
            ) : task ? (
              task.Name
            ) : call ? (
              <TextEllipsis length={20} text={call.NoTagDescription} />
            ) : project ? (
              project.Name
            ) : ticket ? (
              ticket.Name
            ) : indirectClient ? (
              indirectClient.Name
            ) : (
              client.Name
            )}
          </b>
        </div> */}
            {client && (
              <div>
                <FormattedMessage id={"ACCOUNT"} />: <b>{client.Name}</b>
              </div>
            )}
            {user && (
              <div>
                <FormattedMessage id={"ASSIGNED_TO"} />: <b>{user.Name}</b>
              </div>
            )}
            {Status && (
              <div style={{ display: "inline-flex" }}>
                <span>
                  <FormattedMessage id={"STATUS"} />: &nbsp;
                </span>
                <ProjectStatusProfile className="blueStatus" status={Status} />
              </div>
            )}
            <div>
              <FormattedMessage id={"DATE"} />:{" "}
              <b>
                <GridDateComponent date={new Date(BeginDate)} />
              </b>
            </div>
            <div>
              <FormattedMessage id={"ESTIMATED_TIME"} />:{" "}
              <b>
                <FormattedTimePreview
                  value={EstimatedCost}
                  asHourMinute={true}
                />
              </b>
            </div>
          </div>
        </SearchPreviewBody>
      </SearchPreviewContent>
      <SearchPreviewButtons
        entity={TaskI}
        hasLoaded={hasLoaded}
        Buttons={TaskEntityGridButtons}
      />
    </SearchPreviewContainer>
  );
};

const SearchPreviewCall = ({ value, query }) => {
  const Call = useCall(value);

  const { Contact, Status, ManagerAccount, NoTagDescription, ReceivedDate } =
    Call;

  const contact = useContact(Contact);
  const user = useAccount(ManagerAccount);

  const hasLoaded = useSearchSchemaQuery(spaceCallsSchema, value);
  return (
    <SearchPreviewContainer>
      <SearchPreviewContent hasLoaded={hasLoaded}>
        <SearchPreviewTitle query={query} entity={Call} noImage>
          <TextEllipsis length={120} text={NoTagDescription} />
        </SearchPreviewTitle>
        <SearchPreviewBody>
          <div className="py-3">
            {/* <div>
              Origem:{" "}
              <b>
                {task ? (
                  <FontAwesomeIcon icon={faListCheck} />
                ) : project ? (
                  <FontAwesomeIcon icon={faParkingCircle} />
                ) : ticket ? (
                  <FontAwesomeIcon icon={faTicketSimple} />
                ) : indirectClient ? (
                  <FontAwesomeIcon icon={faSuitcase} />
                ) : (
                  <FontAwesomeIcon icon={faSuitcase} />
                )}{" "}
                {task
                  ? task.Name
                  : project
                  ? project.Name
                  : ticket
                  ? ticket.Name
                  : indirectClient
                  ? indirectClient.Name
                  : client.Name}
              </b>
            </div> */}
            {contact && (
              <div>
                <FormattedMessage id={"CONTACT"} />: <b>{contact.Name}</b>
              </div>
            )}
            {user && (
              <div>
                <FormattedMessage id={"ASSIGNED_TO"} />: <b>{user.Name}</b>
              </div>
            )}
            {Status && (
              <div style={{ display: "inline-flex" }}>
                <span>
                  <FormattedMessage id={"STATUS"} />: &nbsp;
                </span>
                <ProjectStatusProfile className="blueStatus" status={Status} />
              </div>
            )}
            <div>
              <FormattedMessage id={"ENTRY"} />:{" "}
              <b>
                <RelativeTime date={ReceivedDate} />
              </b>
            </div>
          </div>
        </SearchPreviewBody>
      </SearchPreviewContent>
      <SearchPreviewButtons
        entity={Call}
        hasLoaded={hasLoaded}
        Buttons={CallEntityGridButtons}
      />
    </SearchPreviewContainer>
  );
};

const SearchPreviewIntervention = ({ onClose, query }) => {
  const Intervention = useIntervention(566);

  const {
    NoTagDescription,
    Account,
    IndirectClient,
    BeginDate,
    EndDate,
    Ticket,
    Project,
    Client,
    Call,
    Task
  } = Intervention;

  const ticket = useTicket(Ticket);
  const project = useProject(Project);
  const user = useAccount(Account);
  const client = useClient(Client);
  const call = useCall(Call);
  const task = useTask(Task);
  const indirectClient = useClient(IndirectClient);

  return (
    <div className="ar-searchPreviewSquare">
      <SearchPreviewTitle query={query} entity={Intervention} noImage>
        <TextEllipsis length={20} text={NoTagDescription} />
      </SearchPreviewTitle>
      {/* <div className="ar-previewName d-flex align-items-center justify-content-center">
        <b>
        
        </b>
      </div> */}

      <div style={{ paddingTop: 3 }}>
        <div>
          <FormattedMessage id={"ORIGIN"} />:{" "}
          <b>
            {task ? (
              <FontAwesomeIcon icon={faListCheck} />
            ) : call ? (
              <FontAwesomeIcon icon={faPhone} />
            ) : project ? (
              <FontAwesomeIcon icon={faSquareParking} />
            ) : ticket ? (
              <FontAwesomeIcon icon={faTicketSimple} />
            ) : indirectClient ? (
              <FontAwesomeIcon icon={faSuitcase} />
            ) : (
              <FontAwesomeIcon icon={faSuitcase} />
            )}{" "}
            {task ? (
              task.Name
            ) : call ? (
              <TextEllipsis length={20} text={call.NoTagDescription} />
            ) : project ? (
              project.Name
            ) : ticket ? (
              ticket.Name
            ) : indirectClient ? (
              indirectClient.Name
            ) : (
              client.Name
            )}
          </b>
        </div>
        <div>
          <FormattedMessage id={"ACCOUNT"} />: <b>{indirectClient.Name}</b>
        </div>
        <div>
          <FormattedMessage id={"USER"} />: <b>{user.Name}</b>
        </div>
        <div>
          <FormattedMessage id={"START_DATE"} />:{" "}
          <b>
            <GridDateComponent date={new Date(BeginDate)} />
          </b>
        </div>
        <div>
          <FormattedMessage id={"END_DATE"} />:{" "}
          <b>
            <GridDateComponent date={new Date(EndDate)} />
          </b>
        </div>
      </div>
    </div>
  );
};

export {
  SearchPreviewClient,
  SearchPreviewContacts,
  SearchPreviewContract,
  SearchPreviewProject,
  SearchPreviewTicket,
  SearchPreviewTask,
  SearchPreviewCall,
  SearchPreviewIntervention,
  SearchPreviewSubscription,
  SearchPreviewDeals
};
