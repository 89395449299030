import React from "react";
import TriggerCreation from "./TriggerCreation";
import { spaceCallsSchema } from "../../../../../config/schema";
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";
import { ConnectedCallOrigin } from "../../../../Origin/CallOrigin";
import { useIntl } from "react-intl";

const PreviewTarget = ({ val }) => <ConnectedCallOrigin call={val} />;

const TriggerCallCreation = React.memo(({ ...props }) => {
  const intl = useIntl();
  return (
    <TriggerCreation
      {...props}
      TargetPreview={PreviewTarget}
      schema={spaceCallsSchema}
      icon={faPhoneAlt}
      text={intl.formatMessage({ id: "CALL" })}
    />
  );
});

export default TriggerCallCreation;
