import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useCallback,
  FC
} from "react";
import classes from "./AppbarAccordion.module.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

function saveNewLocalStorageState(label: string, val: boolean) {
  const localStorageState = localStorage.getItem(
    `airdesk-vertical-bar-accordion-states`
  );
  if (localStorageState) {
    const parsedLocalStorageState = JSON.parse(localStorageState);
    parsedLocalStorageState[label] = val;
    localStorage.setItem(
      "airdesk-vertical-bar-accordion-states",
      JSON.stringify(parsedLocalStorageState)
    );
  } else {
    const newLocalStorageState = { [label]: val };
    localStorage.setItem(
      "airdesk-vertical-bar-accordion-states",
      JSON.stringify(newLocalStorageState)
    );
  }
}

const useAccordionState = (
  isOpenByDefault: boolean,
  localStorageLabel?: string
) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(isOpenByDefault);
  const [isAnimating, setIsAnimating] = useState(isOpenByDefault);

  const hasMountedRef = useRef<boolean>(false);

  const toggleOpen = useCallback(() => {
    if (!isOpen) {
      if (localStorageLabel) saveNewLocalStorageState(localStorageLabel, true);
      setIsOpen(() => true);
      setIsAnimating(true);
    } else if (isOpen) {
      if (localStorageLabel) saveNewLocalStorageState(localStorageLabel, false);
      setIsOpen(() => false);
      setIsAnimating(true);
    }
  }, [isOpen, localStorageLabel]);

  useEffect(() => {
    if (localStorageLabel) {
      const localStorageState = localStorage.getItem(
        `airdesk-vertical-bar-accordion-states`
      );
      if (localStorageState) {
        const parsedLocalStorageState = JSON.parse(localStorageState);
        const previousState = parsedLocalStorageState[localStorageLabel];
        if (previousState) {
          setIsOpen(() => true);
          setIsAnimating(true);
        }
      }
    }
  }, [localStorageLabel]);

  useLayoutEffect(() => {
    const contentElem = contentRef.current;
    const hasMounted = hasMountedRef.current;
    if (isOpen) {
      if (!hasMounted) {
        if (contentElem) contentElem.style.opacity = "1";
        const timeout = setTimeout(() => {
          if (contentElem) contentElem.style.height = `auto`;
        }, 500);
        return () => clearTimeout(timeout);
      }
      let timeout: any;
      let frame = requestAnimationFrame(() => {
        if (contentElem) contentElem.style.height = `0px`;
        frame = requestAnimationFrame(() => {
          if (contentElem) {
            const { scrollHeight } = contentElem;
            contentElem.style.height = `${scrollHeight}px`;
            contentElem.style.opacity = "1";
            timeout = setTimeout(() => {
              contentElem.style.height = `auto`;
            }, 500);
          }
        });
      });

      return () => {
        cancelAnimationFrame(frame);
        clearTimeout(timeout);
      };
    } else if (contentElem) {
      const { scrollHeight } = contentElem;

      let timeout: any;
      let frame = requestAnimationFrame(() => {
        contentElem.style.height = `${scrollHeight}px`;
        frame = requestAnimationFrame(() => {
          if (contentElem) {
            contentElem.style.height = `0px`;
            contentElem.style.opacity = "0";
            timeout = setTimeout(() => {
              setIsAnimating(false);
            }, 500);
          }
        });
      });

      return () => {
        cancelAnimationFrame(frame);
        clearTimeout(timeout);
      };
    }
  }, [isOpen, isAnimating]);

  useEffect(() => {
    hasMountedRef.current = true;
  }, []);

  return { toggleOpen, isOpen, isAnimating, contentRef };
};

interface IAppbarAccordion {
  label: string;
  toggledLabel?: string;
  extraAnchorClasses?: string;
  icon: any;
  extraClassName: string;
  extraContentClassName?: string;
  active: boolean;
  localStorageLabel?: string;
  isOpenByDefault: boolean;
  singleExceptionForFavorites?: boolean;
  children: JSX.Element[] | JSX.Element;
  extraButton?: JSX.Element;
  hasUnreads?: boolean;
}

const AppbarAccordion: FC<IAppbarAccordion> = ({
  active,
  extraClassName,
  icon,
  singleExceptionForFavorites,
  extraButton,
  isOpenByDefault = false,
  extraAnchorClasses,
  localStorageLabel,
  label,
  toggledLabel,
  hasUnreads,
  extraContentClassName = "",
  children
}) => {
  const { toggleOpen, isOpen, isAnimating, contentRef } = useAccordionState(
    isOpenByDefault,
    localStorageLabel
  );

  const previourExceptionState = useRef<any>(null);

  const shouldShow = isOpen || isAnimating;

  useEffect(() => {
    if (
      previourExceptionState.current === null &&
      singleExceptionForFavorites !== undefined
    ) {
      previourExceptionState.current = singleExceptionForFavorites;
    }
    if (
      previourExceptionState.current === false &&
      singleExceptionForFavorites === true &&
      isOpen
    ) {
      previourExceptionState.current = true;
      toggleOpen();
    }

    if (
      previourExceptionState.current === true &&
      singleExceptionForFavorites === false &&
      !isOpen
    ) {
      previourExceptionState.current = false;
      toggleOpen();
    }
  }, [isOpen, singleExceptionForFavorites, toggleOpen]);



  return (
    <div
      className={classnames(
        classes["ar-animated-accordion"],
        {
          [classes.active]: isOpen
        },
        extraClassName
      )}
    >
      <div
        onClick={() => {
          if (!singleExceptionForFavorites) toggleOpen();
        }}
        className={classnames(
          classes["ar-animated-accordion-anchor"],
          "cursor-pointer",
          extraAnchorClasses,
          {
            [classes.AccordionActive]: isOpen,
            [classes.AccordionExtraActive]: !isOpen && active
          }
        )}
      >
        {icon && (
          <FontAwesomeIcon icon={icon} size="lg" className="flex-0-0-auto" />
        )}
        <span className="text-truncate ml-12 flex-1 mr-2">
          {toggledLabel ? (isOpen ? toggledLabel : label) : label}
        </span>
        {hasUnreads && (
          <div
            // style={{ left: 29 }}
            className="unread-count newDesign position-absolute"
          >
            {/* <span>{resolvedUnreadCount}</span> */}
          </div>
        )}
        {extraButton && extraButton}
        {!singleExceptionForFavorites && (
          <div
            className={classnames(
              classes["ar-animated-accordion-icon"],
              "flex-0-0-auto"
            )}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        )}
      </div>

      {singleExceptionForFavorites ? (
        <></>
      ) : shouldShow ? (
        <div
          className={classnames(
            classes["ar-animated-accordion-content"],
            {
              [classes.AccordionActive]: isOpen
            },
            extraContentClassName
          )}
          ref={contentRef}
        >
          {children}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AppbarAccordion;
