import React, { useContext } from "react";

const DeleteDisablerContext = React.createContext();

export const useDeleteDisable = () => useContext(DeleteDisablerContext);

const DeleteHelperProvider = ({ children }) => {
  return (
    <DeleteDisablerContext.Provider value={true}>
      {children}
    </DeleteDisablerContext.Provider>
  );
};

export default DeleteHelperProvider;
