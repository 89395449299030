// import React, { useRef, useEffect } from "react";
// import classnames from "classnames";
import {
  darkred,
  //   red,
  lightred,
  darkgrey,
  //   grey,
  darkgreen,
  green,
  //   monthNames,
  createChartComponent,
  // lightgreen,
  // lightgrey,
  red,
  grey,
  darkblue,
  // opaqueDarkBlue,
  blue,
  purple,
  lightPurple,
  darkPurple,
  darkyellow,
  darkorange,
  // lightblue,
  // lightgrey,
  // lightyellow,
  lightorange,
  // lightgreen,
  yellow,
  orange
  //   getMonth
} from "./echartDefaults";
// import { TicketsStatusTypes } from "../../Tickets/TicketsHelper";
// import { useAccount } from "../../../Hooks/EntityHooks";
// include ECharts main module

// include bar chart
require("echarts/lib/echarts.js");
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
// include  title component

// include ECharts main module

// include bar chart
// include  title component
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
export const ComboLoadChart = createChartComponent(
  (Data, intl, difrentCloseColor) => {
    const mappedArr = [];

    for (const obj of Data) {
      const v = parseInt(obj.Data["1"]);
      // const v2 = parseInt(obj.Data["4"]);
      mappedArr.push(v);
    }

    //   var maxNumGraph = Math.max(...mappedArr);

    //   var restGraph = Data.map(e => {
    //     return (
    //       parseInt(maxNumGraph) + 3 - parseInt(e.Data["1"])
    //       //   (parseInt(e.Data["1"]) + parseInt(e.Data["4"]))
    //     );
    //   }).reverse();

    return {
      tooltip: {
        show: false,
        trigger: "item",
        axisPointer: {
          type: "shadow"
        }
      },
      legend: {
        left: "left",
        show: false,
        selectedMode: false,
        borderRadius: 3,
        icon: "roundRect",
        textStyle: {
          color: darkgrey,
          backgroundColor: lightred
        },

        data: [
          {
            name: intl.formatMessage({ id: "CLOSED" }),
            textStyle: {
              color: difrentCloseColor ? darkred : darkgreen,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          },
          {
            name: intl.formatMessage({ id: "OPEN" }),
            textStyle: {
              color: purple,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          }
        ]
      },
      grid: {
        //   show: true,
        containLabel: true
      },
      xAxis: {
        type: "value",
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      yAxis: [
        {
          type: "category",
          axisLine: {
            show: false
          },

          axisTick: {
            show: false
          },
          data: Data.map((e) => {
            return {
              value: e.ManagerAccount?.Name,
              textStyle: {
                color: "#002B63",
                fontWeight: "bold"
              }
            };
          }).reverse()
        }
      ],

      series: [
        //   {
        //     name: "Fechados",
        //     type: "bar",
        //     stack: "stack",
        //     itemStyle: {
        //       color: red,
        //       borderRadius: [0, 0, 0, 0]
        //     },
        //     data: Data.map(e => {
        //       return {
        //         value: e.Data["4"],
        //         label: {
        //           normal: {
        //             show: e.Data["4"] > 0,
        //             position: "insideRight",
        //             fontWeight: "bold",

        //             color: darkred
        //           }
        //         }
        //       };
        //     }).reverse()
        //   },
        {
          name: intl.formatMessage({ id: "OPEN" }),
          type: "bar",
          stack: "stack",
          itemStyle: {
            color: lightPurple
          },
          barGap: "40%",
          label: {
            padding: [0, 0, 0, 5]
          },
          data: Data.map((e) => {
            return {
              value: e.Data["1"],
              label: {
                show: e.Data["1"] > 0,
                // offset: [20, 0],
                position: "right",
                fontWeight: "bold",
                color: purple
              }
            };
          }).reverse()
        }
        //   {
        //     name: "",
        //     type: "bar",
        //     stack: "stack",
        //     itemStyle: {
        //       color: "#f2f2f2",
        //       borderRadius: [0, 0, 0, 0],
        //       emphasis: {
        //         color: "#f2f2f2"
        //       }
        //     },
        //     data: restGraph,
        //     barGap: "40%"
        //   }
      ]
    };
  }
);

export const PipelineWorkloadComboLoadChart = createChartComponent(
  (Data, intl, difrentCloseColor) => {
    return {
      tooltip: {
        show: false,
        trigger: "item",
        axisPointer: {
          type: "shadow"
        }
      },
      legend: {
        left: "left",
        show: false,
        selectedMode: false,
        borderRadius: 3,
        icon: "roundRect",
        textStyle: {
          color: darkgrey,
          backgroundColor: lightred
        },

        data: [
          {
            name: intl.formatMessage({ id: "CLOSED" }),
            textStyle: {
              color: difrentCloseColor ? darkred : darkblue,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          },
          {
            name: intl.formatMessage({ id: "OPEN" }),
            textStyle: {
              color: darkgreen,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          }
        ]
      },
      grid: {
        //   show: true,
        containLabel: true
      },
      xAxis: {
        type: "value",
        name: intl.formatMessage({ id: "DAYS" }),
        nameLocation: "end",

        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      yAxis: [
        {
          type: "category",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: Data.map((e) => {
            return {
              value: e.PipelineStatus?.Name,
              textStyle: {
                color:
                  e.PipelineStatus.Status === 5
                    ? darkorange
                    : e.PipelineStatus.Status === 1
                    ? darkPurple
                    : e.PipelineStatus.Status === 4
                    ? difrentCloseColor
                      ? darkred
                      : darkgreen
                    : difrentCloseColor
                    ? darkred
                    : "#657b94"
              }
            };
          }).reverse()
        }
      ],

      series: [
        {
          name: intl.formatMessage({ id: "OPEN" }),
          type: "bar",
          stack: "stack",
          barGap: "40%",
          label: {
            padding: [0, 0, 0, 5]
          },
          data: Data.map((e) => {
            return {
              value: e.DayAverage,
              label: {
                show: e.DayAverage > 0,
                // offset: [20, 0],
                position: "right",
                fontWeight: "bold",
                color:
                  e.PipelineStatus.Status === 5
                    ? darkorange
                    : e.PipelineStatus.Status === 1
                    ? darkPurple
                    : e.PipelineStatus.Status === 4
                    ? difrentCloseColor
                      ? darkred
                      : darkgreen
                    : darkgrey
              },
              itemStyle: {
                color:
                  e.PipelineStatus.Status === 5
                    ? orange
                    : e.PipelineStatus.Status === 1
                    ? lightPurple
                    : e.PipelineStatus.Status === 4
                    ? difrentCloseColor
                      ? red
                      : green
                    : grey
              }
            };
          }).reverse()
        }
        //   {
        //     name: "",
        //     type: "bar",
        //     stack: "stack",
        //     itemStyle: {
        //       color: "#f2f2f2",
        //       borderRadius: [0, 0, 0, 0],
        //       emphasis: {
        //         color: "#f2f2f2"
        //       }
        //     },
        //     data: restGraph,
        //     barGap: "40%"
        //   }
      ]
    };
  }
);
export const ContractPipelineWorkloadComboLoadChart = createChartComponent(
  (Data, intl, difrentCloseColor) => {
    return {
      tooltip: {
        show: false,
        trigger: "item",
        axisPointer: {
          type: "shadow"
        }
      },
      legend: {
        left: "left",
        show: false,
        selectedMode: false,
        borderRadius: 3,
        icon: "roundRect",
        textStyle: {
          color: darkgrey,
          backgroundColor: lightred
        },

        data: [
          {
            name: intl.formatMessage({ id: "CLOSED" }),
            textStyle: {
              color: difrentCloseColor ? darkred : darkblue,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          },
          {
            name: intl.formatMessage({ id: "OPEN" }),
            textStyle: {
              color: darkPurple,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          }
        ]
      },
      grid: {
        //   show: true,
        containLabel: true
      },
      xAxis: {
        type: "value",
        name: intl.formatMessage({ id: "DAYS" }),
        nameLocation: "end",

        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      yAxis: [
        {
          type: "category",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: Data.map((e) => {
            return {
              value: e.PipelineStatus?.Name,
              textStyle: {
                color:
                  e.PipelineStatus.Status === 1
                    ? darkPurple
                    : e.PipelineStatus.Status === 2
                    ? darkblue
                    : e.PipelineStatus.Status === 3
                    ? darkgrey
                    : e.PipelineStatus.Status === 5
                    ? darkyellow
                    : e.PipelineStatus.Status === 6
                    ? darkorange
                    : darkgreen
              }
            };
          }).reverse()
        }
      ],

      series: [
        {
          name: intl.formatMessage({ id: "OPEN" }),
          type: "bar",
          stack: "stack",
          barGap: "40%",
          label: {
            padding: [0, 0, 0, 5]
          },
          data: Data.map((e) => {
            return {
              value: e.DayAverage,
              label: {
                show: e.DayAverage > 0,
                // offset: [20, 0],
                position: "right",
                fontWeight: "bold",
                color:
                  e.PipelineStatus.Status === 1
                    ? darkPurple
                    : e.PipelineStatus.Status === 2
                    ? darkblue
                    : e.PipelineStatus.Status === 3
                    ? darkgrey
                    : e.PipelineStatus.Status === 5
                    ? darkyellow
                    : e.PipelineStatus.Status === 6
                    ? darkorange
                    : darkgreen
              },
              itemStyle: {
                color:
                  e.PipelineStatus.Status === 1
                    ? lightPurple
                    : e.PipelineStatus.Status === 2
                    ? blue
                    : e.PipelineStatus.Status === 3
                    ? grey
                    : e.PipelineStatus.Status === 5
                    ? yellow
                    : e.PipelineStatus.Status === 6
                    ? orange
                    : green
              }
            };
          }).reverse()
        }
        //   {
        //     name: "",
        //     type: "bar",
        //     stack: "stack",
        //     itemStyle: {
        //       color: "#f2f2f2",
        //       borderRadius: [0, 0, 0, 0],
        //       emphasis: {
        //         color: "#f2f2f2"
        //       }
        //     },
        //     data: restGraph,
        //     barGap: "40%"
        //   }
      ]
    };
  }
);

export const DealPipelineWorkloadComboLoadChart = createChartComponent(
  (Data, intl, difrentCloseColor) => {
    const resolvedData = Data.filter(
      (e) => e.PipelineStatus.Status === 1 && e.DayAverage > 0
    );

    return {
      // tooltip: {
      //   show: false,
      //   trigger: "item",
      //   axisPointer: {
      //     type: "shadow"
      //   }
      // },
      // legend: {
      //   left: "left",
      //   show: false,
      //   selectedMode: false,
      //   borderRadius: 3,
      //   icon: "roundRect",
      //   textStyle: {
      //     color: darkgrey,
      //     backgroundColor: lightred
      //   },

      //   data: [
      //     {
      //       name: intl.formatMessage({ id: "CLOSED" }),
      //       textStyle: {
      //         color: difrentCloseColor ? darkred : darkblue,
      //         backgroundColor: "#fff",
      //         borderRadius: 3
      //       }
      //     },
      //     {
      //       name: intl.formatMessage({ id: "OPEN" }),
      //       textStyle: {
      //         color: darkgreen,
      //         backgroundColor: "#fff",
      //         borderRadius: 3
      //       }
      //     }
      //   ]
      // },
      grid: {
        left: "3%",
        right: "8%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: {
        type: "value",
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitLine: {
          show: false
        },
        name: intl.formatMessage({ id: "DAYS" }),
        nameLocation: "end"
      },
      yAxis: [
        {
          type: "category",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: resolvedData
            .filter((e) => e.DayAverage > 0)
            .map((e) => {
              return {
                value: e.PipelineStatus.Name,
                textStyle: {
                  color:
                    e.PipelineStatus.Status === 1
                      ? darkPurple
                      : e.PipelineStatus.Status === 4
                      ? darkgreen
                      : e.PipelineStatus.Status === 3
                      ? difrentCloseColor
                        ? darkred
                        : darkorange
                      : darkgrey
                }
              };
            })
            .reverse()
        }
      ],

      series: [
        {
          // name: intl.formatMessage({ id: "OPEN" }),
          type: "bar",
          stack: "stack",
          // barWidth: "60%",
          // label: {
          //   padding: [0, 0, 0, 5]
          // },
          data: resolvedData
            .map((e) => {
              return {
                value: e.DayAverage,
                // name: e.PipelineStatus.Name,
                label: {
                  show: true,
                  // offset: [20, 0],
                  // name: e.PipelineStatus.Name,
                  position: "right",
                  fontWeight: "bold",
                  color:
                    e.PipelineStatus.Status === 1
                      ? darkPurple
                      : e.PipelineStatus.Status === 4
                      ? darkgreen
                      : e.PipelineStatus.Status === 3
                      ? difrentCloseColor
                        ? darkred
                        : darkorange
                      : darkgrey
                },
                itemStyle: {
                  color:
                    e.PipelineStatus.Status === 1
                      ? lightPurple
                      : e.PipelineStatus.Status === 4
                      ? green
                      : e.PipelineStatus.Status === 3
                      ? difrentCloseColor
                        ? red
                        : lightorange
                      : grey
                }
              };
            })
            .reverse()
          // .reverse()
        }
        //   {
        //     name: "",
        //     type: "bar",
        //     stack: "stack",
        //     itemStyle: {
        //       color: "#f2f2f2",
        //       borderRadius: [0, 0, 0, 0],
        //       emphasis: {
        //         color: "#f2f2f2"
        //       }
        //     },
        //     data: restGraph,
        //     barGap: "40%"
        //   }
      ]
    };
  }
);
