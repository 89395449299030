import React from "react";
import {
  useDeal,
  useCurrency,
  usePipelineStatus
} from "../../Hooks/EntityHooks";
import { spaceDealsSchema } from "../../config/schema";
import { createOriginPopup } from "./Origin";
import DealStatusProfile, {
  DealStatusProfileWithIcon
} from "../../Components/Deals/DealStatusProfile";
import {
  OriginContainer,
  OriginDate,
  OriginHeader,
  OriginItemGroup
} from "../../Components/Origin/Origin";
import { ConnectedClientOrigin } from "./ClientOrigin";
import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";
import { useSpace } from "../../Contexts/SpaceContext";
import FormattedDecimal from "../../Components/FormattedDecimal/FormattedDecimal";
import SplitCol from "../../Components/SplitCol/SplitCol";
import { ConnectedContactPopup } from "./ContactPopup";
import { ConnectedAccountPopup } from "./AcountPopup";
import { DealEntityGridButtons } from "../Deals/DealsHelper";
import classnames from "classnames";
import { DealIcon } from "../../Components/Common/EntityIcons";
import { useIntl } from "react-intl";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import Button from "../../Components/Button/Button";
import { PipelineStatusProfile } from "../AdvancedMultiInputs/PipelineDropdowns";
import {
  faMoneyBill,
  faSuitcase,
  faUser,
  faUsersMedical,
  faUserTie
} from "@fortawesome/pro-solid-svg-icons";
import { useConnectedSchemaGetter } from "../../Helpers/HelperHooks";

export const DealOriginAnchor = React.forwardRef(
  ({ deal, children, onClick, dynamicFont, ...rest }, ref) => {
    const openForm = useEntitySummarySidebar();
    if (!deal) return null;
    const { Id, Status, Attributes, IsDeleted } = deal;
    return (
      <div
        ref={ref}
        className={classnames("link-color text-truncate", {
          "item-was-deleted": IsDeleted
        })}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <>
            <div
              className={classnames(
                "link-color d-flex align-items-center text-truncate",
                {
                  "fs-12": !dynamicFont
                }
              )}
            >
              <div className="d-inline-flex align-items-center mr-1">
                <DealStatusProfileWithIcon
                  Icon={<DealIcon />}
                  value={Status || Attributes}
                  hideName
                  {...rest}
                />
              </div>
              <Button
                vType="link-primary"
                className="EntityLinkClassID fs-12 text-black"
                onClick={() => {
                  openForm({
                    type: moduleTypes.deals,
                    props: {
                      id: Id
                    }
                  });
                }}
              >
                <span>{deal.Name}</span>
              </Button>

              {/* <ClientStatusBadge
                  className="d-inline-flex"
                  status={client.Status}
                /> */}
            </div>
            {/* <div>{task.Id}</div> */}
            {/* {Id && <div className="fs-10">Nº: {Id}</div>} */}
          </>
        )}
      </div>
    );
  }
);

export const ConnectedDealOriginAnchor = ({ deal, ...rest }) => {
  const resolvedDeal = useDeal(deal);

  const Result = useConnectedSchemaGetter(deal, spaceDealsSchema);

  if (Result) return Result;

  return <DealOriginAnchor deal={resolvedDeal} {...rest} />;
};

export const DealOriginPopup = ({ deal }) => {
  const intl = useIntl();
  const d = useDeal(deal.Id);

  const {
    Id,
    // ReceivedDate,
    Name,
    // Status,
    Client,
    EstimatedCost,
    Contact,
    Assigned,
    CreationDate,
    PipelineStatus,
    DealManager
  } = d;
  const connectedPipelineStatus = usePipelineStatus(PipelineStatus);
  const space = useSpace();

  const { Symbol } = useCurrency(space.Currency);

  return (
    <OriginContainer>
      <OriginHeader
        Dif
        className="mb-1"
        type="Negocio"
        date={CreationDate}
        entity="DEAL"
        Icon={<DealIcon className="mr-2" />}
        EntityButtons={<DealEntityGridButtons noColors entity={d} minimal />}
        id={Id}
        link={`/s/${space.Id}/deals/${Id}`}
        title={Name}
      />

      <div className="mx-3 mb-1">
        {/* <div className="text-black fs-14 mb-2">{Name}</div> */}
        <div className="mb-1 d-flex align-items-center justify-content-between">
          <PipelineStatusProfile
            StatusProfileComponent={DealStatusProfile}
            pipeline={connectedPipelineStatus}
          />

          <div>
            <OriginDate className="fs-12" date={CreationDate} />
          </div>
        </div>
      </div>

      <SplitCol
        className="mb-1"
        left={
          <OriginItemGroup
            icon={faSuitcase}
            title={intl.formatMessage({ id: "ACCOUNT" })}
            className="mb-2"
          >
            {/* <ConnectedClientPopup client={Client} /> */}
            <ConnectedClientOrigin client={Client}>
              <ConnectedClientProfile showId client={Client} />
            </ConnectedClientOrigin>
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUser}
            title={intl.formatMessage({ id: "CONTACT" })}
          >
            <ConnectedContactPopup contact={Contact} />
          </OriginItemGroup>
        }
      />

      <OriginItemGroup
        icon={faMoneyBill}
        title={intl.formatMessage({ id: "ESTIMATED" })}
        className="mb-2"
      >
        {/* <ConnectedClientPopup client={Client} /> */}
        <span className="fs-12 text-black">
          <FormattedDecimal value={EstimatedCost?.Cost} /> {Symbol}
        </span>
      </OriginItemGroup>

      <SplitCol
        className="mb-1"
        left={
          <OriginItemGroup
            icon={faUserTie}
            title={intl.formatMessage({ id: "MANAGER" })}
          >
            <ConnectedAccountPopup account={Assigned} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUsersMedical}
            title={intl.formatMessage({ id: "ASSIGNED_TO" })}
          >
            <ConnectedAccountPopup account={DealManager} />
          </OriginItemGroup>
        }
      />
      <div className="pb-3"></div>
      {/* <div className="d-flex align-items-center justify-content-center shadow-top py-3 mt-3">
        <DealEntityButtons entity={d} />
      </div> */}
      {/* <ClientProfile hideid={"true"}client={c} className="px-3 mb-2" />
  
        {Domain && (
          <SplitCol
            className="mb-1"
            left={
              <OriginItemGroup title=<FormattedMessage id={"NAME"} />>
                <span className="text-black fs-14">{Name}</span>
              </OriginItemGroup>
            }
            right={
              <OriginItemGroup show={Boolean(Domain)} title="Dominio do cliente">
                <span className="text-black fs-14">{Domain}</span>
              </OriginItemGroup>
            }
          />
        )}
        {!Domain && (
          <OriginItemGroup title=<FormattedMessage id={"NAME"} /> className="mb-1">
            <span className="text-black fs-14">{Name}</span>
          </OriginItemGroup>
        )}
        {hasEmail && !hasPhoneNumber && (
          <OriginItemGroup show={hasEmail} className="mb-1" title="Email">
            <div className="text-black d-flex align-items-center">
              <EmailIcon className="mr-1" />
              <a href={`mailto:${Email}`} className="fs-14">
                {Email}
              </a>
            </div>
          </OriginItemGroup>
        )}
        <SplitCol
          show={hasPhoneNumber && hasEmail}
          className="mb-1"
          left={
            <OriginItemGroup show={hasPhoneNumber} title="Nº Telefone">
              <div className="text-black d-flex align-items-center">
                <CallIcon className="mr-1" />
                <FormattedPhoneNumber className="fs-14" number={PhoneNumber} />
              </div>
            </OriginItemGroup>
          }
          right={
            <OriginItemGroup show={hasEmail} title="Email">
              <div className="text-black d-flex align-items-center">
                <EmailIcon className="mr-1" />
                <a href={`mailto:${Email}`} className="fs-14">
                  {Email}
                </a>
              </div>
            </OriginItemGroup>
          }
        />
        {!Email && hasPhoneNumber && (
          <OriginItemGroup
            show={hasPhoneNumber}
            className="mb-1"
            title="Nº Telefone"
          >
            <div className="text-black d-flex align-items-center">
              <CallIcon className="mr-1" />
              <FormattedPhoneNumber className="fs-14" number={PhoneNumber} />
            </div>
          </OriginItemGroup>
        )}
        {Manager && (
          <OriginItemGroup title="Responsável" className="mb-1">
            <ConnectedAccountPopup account={Manager} />
          </OriginItemGroup>
        )}
        <OriginItemGroup
          className="mb-1"
          show={hasContracts}
          title="Contratos associados"
        >
          {contractsArr}
        </OriginItemGroup>
        <OriginItemGroup className="mb-1" show={hasProjects} title="Projetos">
          {projectsArr}
        </OriginItemGroup>
        <div className="d-flex align-items-center justify-content-center shadow-top py-3 mt-3">
          <ClientFormButton id={Id} canEdit={CanEdit} className="mr-2" />
          <NoteFormButton
            alt
            formProps={{ clientId: Id }}
            className="ssi-button-primary-ghost mr-2"
          />
          <CallFormButton
            alt
            formProps={{ clientId: Id }}
            className="ssi-button-primary-ghost mr-2"
          />
          <TicketFormButton
            alt
            formProps={{ clientId: Id }}
            className="ssi-button-primary-ghost mr-2"
          >
            Converter Ticket
          </TicketFormButton>
          <TaskFormButton
            className="ssi-button-primary-ghost mr-2"
            alt
            formProps={{ clientId: Id }}
          >
            Agendar Tarefa
          </TaskFormButton>
          <InterventionFormButton alt formProps={{ clientId: Id }} />
        </div> */}
    </OriginContainer>
  );
};

export const dealOriginOptions = {
  valueProp: "deal",
  schema: spaceDealsSchema
};

export const DealOrigin = createOriginPopup(
  DealOriginAnchor,
  DealOriginPopup,
  dealOriginOptions
);

export default DealOrigin;

export const ConnectedDealOrigin = ({ deal, ...rest }) => {
  const d = useDeal(deal);

  const Result = useConnectedSchemaGetter(deal, spaceDealsSchema);

  if (Result) return Result;

  return <DealOrigin deal={d} {...rest} />;
};
