import React, { useRef, useContext } from "react";
import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import classnames from "classnames";
import "./ActionsButton.css";
import Popup, { usePopupOpenState } from "../Popup/Popup";
import { FormattedMessage } from "react-intl";

const ActionContext = React.createContext();

const ActionsButton = ({ className, children, disabled, style }) => {
  const popupRef = useRef();
  const anchorRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);

  return (
    <>
      <ActionContext.Provider value={toggleIsOpen}>
        <Popup
          domRef={popupRef}
          placement="bottom-end"
          anchorEl={anchorRef.current}
          isOpen={Boolean(isOpen)}
        >
          <div style={style} className="py-2 ar-actions-popup bg-white">
            {children}
          </div>
        </Popup>
      </ActionContext.Provider>
      <Button
        disabled={disabled}
        ref={anchorRef}
        vType="outline-primary"
        onClick={toggleIsOpen}
        className={classnames("px-2 ar-actions-button", className)}
      >
        <div className="d-flex justify-content-between align-items-center">
          <FormattedMessage id={"ACTIONS"} />
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      </Button>
    </>
  );
};

export const ActionButtonItem = ({ onClick, children, component, ...rest }) => {
  const toggleIsOpen = useContext(ActionContext);

  const handleClick = () => {
    onClick && onClick();
    toggleIsOpen(false);
  };
  if (component) return component;
  return (
    <Button onClick={handleClick} vType="link" className="text-left" {...rest}>
      {children}
    </Button>
  );
};

export default ActionsButton;
