import React from "react";
import { useCurrentSidebarItem } from "./SidebarViewsHelper";
import SuspenseErrorBoundary from "../Suspense/SuspenseErrorBoundary";
import Modal from "../Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const SidebarContentItem = React.memo(function SidebarContentItem({ item }) {
  const { Component, props } = item;

  return (
    <SuspenseErrorBoundary sidebar>
      {React.cloneElement(Component, props)}
    </SuspenseErrorBoundary>
  );
});

const SidebarContent = React.memo(({ tabs, onCloseSidebar }) => {
  const item = useCurrentSidebarItem(tabs);

  if (!item) return null;

  return (
    <div
      className="position-relative ar-modal-content"
      style={{ overflow: "visible", height: "90%" }}
    >
      <div className="ar-sidebar-close-button" onClick={() => onCloseSidebar()}>
        <FontAwesomeIcon icon={faTimes} />
      </div>

      <div className="of-hidden rounded h-100">
        <SidebarContentItem item={item} />
      </div>
    </div>
  );
});

const SidebarModalView = ({
  open,
  onEntered,
  onExited,
  tabs,
  onCloseSidebar,
}) => {
  return (
    <Modal
      customContent
      className={
        "ar-sidebar-modal d-flex align-items-center justify-content-center"
      }
			// disableClosure
      // extraCloseButton={
      //   <div
      //     className="ar-sidebar-close-button"
      //     onClick={() => onCloseSidebar()}
      //   >
      //     <FontAwesomeIcon icon={faTimes} />
      //   </div>
      // }
      isOpen={open}
      onClose={onCloseSidebar}
      onEntered={onEntered}
      onExited={onExited}
    >
      <SidebarContent onCloseSidebar={onCloseSidebar} tabs={tabs} />
    </Modal>
  );
};

export default SidebarModalView;
