import React from "react";
import { AccountProfileImg } from "../Img/Img";
import DropdownInput from "../Dropdown/DropdownInput";
import { useProject, useClient } from "../../Hooks/EntityHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareParking, faSuitcase } from "@fortawesome/pro-light-svg-icons";
import { buildProjectStatusType } from "./ProjectProfile";

const ProjectListItem = ({
  project,
  projectClient = {},
  enableInput,
  isTyping,
  dispatch,
  text,
  ...inputProps
}) => {
  let resolvedProject = project || {};
  const { Client, IndirectClient, Status } = resolvedProject;
  const resolvedClient = useClient(Client || IndirectClient);
  const resolvedStatus = buildProjectStatusType(Status);
  if (!project) {
    return (
      <DropdownInput
        className="flat-input"
        fallbackValue={""}
        text={text}
        enableInput={enableInput}
        isTyping={isTyping}
        {...inputProps}
      />
    );
  }
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100 of-hidden text-truncate">
        {!isTyping && resolvedProject && (
          <AccountProfileImg
            size="mds"
            account={resolvedClient}
            circular
            className="mr-2 flex-0-0-auto"
          />
        )}
        <div className="d-flex flex-column align-items-baseline w-100 of-hidden mr-2">
          <div className="dropdown-item-maintext ">
            {/* {!isTyping && resolvedProject && `${resolvedProject.Id || ""} - `} */}
            {!enableInput ? (
              resolvedProject.Name
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={resolvedProject && resolvedProject.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
          {!isTyping && (
            <div className="d-flex dropdown-item-subtext-dif of-hidden overflow-hidden text-truncate w-100">
              {projectClient && projectClient.Name && (
                <span className="text-truncate">
                  <FontAwesomeIcon icon={faSuitcase} className="mr-1" />{" "}
                  {projectClient.Name}
                </span>
              )}
              {resolvedProject && resolvedProject.SalesOpportunity && (
                <span className="ml-2 text-truncate">
                  OPV: {resolvedProject.SalesOpportunity}
                </span>
              )}
            </div>
          )}
        </div>
        {!isTyping && (
          <div
            className={`d-flex align-items-center justify-content-end status-color-${resolvedStatus}`}
          >
            <div className="d-flex py-1 px-2 fs-12 align-items-center status-list-template">
              <FontAwesomeIcon className="mr-2" icon={faSquareParking} />
              <span>{resolvedProject.Id}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectListItem;

export const ConnectedProjectListItem = ({ project, ...rest }) => {
  const p = useProject(project);
  const pClient = useClient(p && (p.Client || p.IndirectClient));

  return (
    <ProjectListItem
      project={p}
      projectClient={pClient || undefined}
      {...rest}
    />
  );
};
