import React from "react";
import "react-table/react-table.css";
import { Switch, Route, withRouter } from "react-router-dom";
// import {
//   RealTimePageProvider,
//   RealTimePageEnum
// } from "../RealTime/RealTimePages";
import TaskDetails from "./TaskDetails/TaskDetails";
import TaskOverview from "./TaskOverview/TaskOverview";
import {
  // useEnterViewSignalR,
  PageTypeProvider
} from "../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimePageEnum } from "../RealTime/RealTimePages";

const Tasks = withRouter(({ match }) => {
  // useEnterViewSignalR(RealTimePageEnum.ProjectTask)

  return (
    <PageTypeProvider pageType={RealTimePageEnum.ProjectTask}>
      <Switch>
        <Route path={`${match.url}/:taskId`}>
          <TaskDetails />
        </Route>
        <Route>
          {/* <RealTimePageProvider pageType={RealTimePageEnum.ProjectTask}> */}
          <TaskOverview />
          {/* </RealTimePageProvider> */}
        </Route>
      </Switch>
    </PageTypeProvider>
  );
});

export default Tasks;
