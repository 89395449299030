import React, { useContext } from "react";
import { faFile } from "@fortawesome/pro-solid-svg-icons";
import FormInput from "../../../../../Components/Forms/FormInput/FormInput";
import { useIntl } from "react-intl";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import {
  useNodeUpdater,
  AutomationTestRequestContext,
  AutomationTestClickContext
} from "../../AutomationDetailsHelper";
import { ElementsRefContext } from "../../AutomationDetails";
import NodeBaseSidebar from "../NodeBaseSidebar";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import TargetHandles from "../../Handles/TargetHandles";
import NodeIconNameContainer from "../NodeIconNameContainer";
import SourceHandles from "../../Handles/SourceHandles";
// import { TicketDropdown } from "../../../../AdvancedMultiInputs";
// import { TargetEmailsTypeEnum } from "../../NodeTypes";

const FormContainer = ({ formState, canEdit }) => {
  const { form, handleChange } = formState;
  const { id: conversationId } = form;

  const onChange = (e) => {
    const { value } = e.target;
    handleChange({
      TargetId: value
    });
  };

  return (
    <div className="w-100 mb-3 align-items-center justify-content-between">
      <FormInput
        name="TargetId"
        className="w-100"
        text={"Id"}
        value={conversationId}
        onChange={onChange}
      />
    </div>
  );
};

const settings = {
  defaultForm: {
    TargetId: ""
  },
  FormContent: (props) => {
    return <FormContainer {...props}></FormContainer>;
  }
};

const GetGeneralExportNode = React.memo(({ selected, data, id, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();
  const intl = useIntl();
  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };

  const { enabled, config } = data;
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <NodeIconNameContainer
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleClick}
          selected={selected}
          icon={faFile}
          text={intl.formatMessage({ id: "GET_EXPORT_FILE_BY_ID" })}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default GetGeneralExportNode;
