import React from "react";
import {
  buildProjectStatusType,
  ConnectProjectEntity
} from "../Projects/ProjectProfile";
import { AccountProfileImg } from "../Img/Img";
import { ConnectTicketEntity } from "../Tickets/TicketProfile";
import { ConnectCallEntity } from "../Calls/CallProfile";
import { ConnectTaskEntity } from "../Tasks/TaskProfile";
import { ConnectContractEntity } from "../Contract/ContractProfile";
import DropdownInput from "../Dropdown/DropdownInput";
import { useAccount, useClient } from "../../Hooks/EntityHooks";
import { ConnectedProjectOriginAnchor } from "../../Containers/Origin/ProjectOrigin";
import { ConnectedClientOriginAnchor } from "../../Containers/Origin/ClientOrigin";
import { ConnectedTicketOriginAnchor } from "../../Containers/Origin/TicketOrigin";
import { ConnectedCallOriginAnchor } from "../../Containers/Origin/CallOrigin";
import { ConnectedTaskOriginAnchor } from "../../Containers/Origin/TaskOrigin";
import { ConnectedContractOriginAnchor } from "../../Containers/Origin/ContractOrigin";
import { ConnectedDealOriginAnchor } from "../../Containers/Origin/DealOrigin";
import {
  ProjectIcon,
  ClientIcon,
  TicketIcon,
  CallIcon,
  TaskIcon,
  ContractIcon
} from "../Common/EntityIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListCheck,
  faTicketSimple,
  faUserClock
} from "@fortawesome/pro-light-svg-icons";

const GeneralTinyProfile = ({ icon: Icon, text }) => {
  return (
    <div className="d-flex align-items-center">
      {Icon && <Icon className="mr-1" />}
      <span className="text-truncate">{text}</span>
    </div>
  );
};

export const TinyProjectProfile = ({ project }) => (
  <GeneralTinyProfile icon={ProjectIcon} text={project ? project.Name : ""} />
);

export const TinyClientProfile = ({ client }) => {
  return (
    <GeneralTinyProfile
      icon={client ? ClientIcon : null}
      text={client ? client.Name : ""}
    />
  );
};

export const TinyTicketProfile = ({ ticket }) => (
  <GeneralTinyProfile icon={TicketIcon} text={ticket ? ticket.Name : ""} />
);

export const TinyCallProfile = ({ call }) => (
  <GeneralTinyProfile
    icon={CallIcon}
    text={call ? call.NoTagDescription : ""}
  />
);

export const TinyTaskProfile = ({ task }) => (
  <GeneralTinyProfile icon={TaskIcon} text={task ? task.Name : ""} />
);
export const TinyContractProfile = ({ contract }) => {
  return (
    <GeneralTinyProfile
      icon={ContractIcon}
      text={contract ? contract.Description : ""}
    />
  );
};

export const ConnectedTinyProjectProfile =
  ConnectProjectEntity(TinyProjectProfile);
export const ConnectedTinyClientProfile = ({ client, ...rest }) => {
  const c = useClient(client);
  return <TinyClientProfile client={c} {...rest} />;
};
export const ConnectedTinyTicketProfile =
  ConnectTicketEntity(TinyTicketProfile);
export const ConnectedTinyCallProfile = ConnectCallEntity(TinyCallProfile);
export const ConnectedTinyTaskProfile = ConnectTaskEntity(TinyTaskProfile);
export const ConnectedTinyContractProfile =
  ConnectContractEntity(TinyContractProfile);

export const TinyInterventionProfile = ({ intervention, ...rest }) => {
  const { Project, Client, Ticket, Call, Task, Contract } = intervention;

  if (Project)
    return <ConnectedTinyProjectProfile project={Project} {...rest} />;
  else if (Client)
    return <ConnectedTinyClientProfile client={Client} {...rest} />;
  else if (Ticket)
    return <ConnectedTinyTicketProfile ticket={Ticket} {...rest} />;
  else if (Call) return <ConnectedTinyCallProfile call={Call} {...rest} />;
  else if (Task) return <ConnectedTinyTaskProfile task={Task} {...rest} />;
  else if (Contract)
    return <ConnectedTinyContractProfile contract={Contract} {...rest} />;
  else return null;
};

export const TinyInterventionTargetProfile = ({ value, ...rest }) => {
  if (!value) return null;
  const { type, data } = value;

  if (type === "Project")
    return <ConnectedProjectOriginAnchor project={data} {...rest} />;
  else if (type === "Client" || type === "ClientIdBySpace")
    return <ConnectedClientOriginAnchor client={data} {...rest} />;
  else if (type === "Ticket")
    return <ConnectedTicketOriginAnchor ticket={data} {...rest} />;
  else if (type === "Call")
    return <ConnectedCallOriginAnchor call={data} {...rest} />;
  else if (type === "Task")
    return <ConnectedTaskOriginAnchor task={data} {...rest} />;
  else if (type === "Contract")
    return <ConnectedContractOriginAnchor contract={data} {...rest} />;
  else if (type === "Deal")
    return <ConnectedDealOriginAnchor deal={data} {...rest} />;
  else return null;
};

export const GeneralListItem = ({
  item,
  enableInput,
  isTyping,
  dispatch,
  disableImage,
  text,
  ...inputProps
}) => {
  const { Status, Manager, ManagerAccount, Creator } = item || {};
  const resolvedManager = useAccount(Manager || ManagerAccount);
  const resolvedCreator = useAccount(Creator);
  const { ticket, task, intervention } = inputProps;
  const resolvedStatus = buildProjectStatusType(Status);
  let resolvedIcon;
  let resolvedItem = item?.Id;
  if (ticket) {
    resolvedIcon = faTicketSimple;
  } else if (task) resolvedIcon = faListCheck;
  else if (intervention) resolvedIcon = faUserClock;

  const entityText = intervention ? item?.NoTagDescription : item?.Name;

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100">
        {!disableImage && !isTyping && item && (
          <AccountProfileImg
            size="mds"
            account={resolvedManager || resolvedCreator || Manager}
            circular
            className="mr-2 flex-0-0-auto"
          />
        )}
        <div className="d-flex flex-column align-items-baseline w-100 of-hidden mr-2">
          <div className="dropdown-item-maintext ">
            {!enableInput ? (
              entityText
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={entityText}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
          {!isTyping && item && (
            <div className="dropdown-item-subtext-dif overflow-hidden w-100">
              <TinyInterventionProfile intervention={item} />
            </div>
          )}
        </div>
        {!isTyping && item && (
          <div
            className={`d-flex align-items-center justify-content-end status-color-${resolvedStatus}`}
          >
            <div className="d-flex py-1 px-2 fs-12 align-items-center status-list-template">
              <FontAwesomeIcon className="mr-2" icon={resolvedIcon} />
              <span>{resolvedItem}</span>
            </div>
          </div>
        )}
      </div>
      {/* {!isTyping && project.ReceivedDate && (
          <Moment
            fromNow
            className="dropdown-item-time mr-5"
            date={project.ReceivedDate}
          />
        )} */}
    </div>
  );
};
