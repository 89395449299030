import React, { useContext } from "react";
import { EntityOrganizationForm } from "../Settings/Organizations/OrganizationForm";
import { FormattedMessage } from "react-intl";
import {
  OnboardingStateContext,
  onboardingStateEnum,
  OnboardingAutoRedirect,
  useSpaceOnboardingStateUpdater,
  OnboardingUpdateContext,
  SkipOnboardingLink
} from "./SpaceOnboardingHelper";
import OnboardingHeader from "../Headers/OnboardingHeader";
import OnboardingContainer from "../Headers/OnboardingContainer";
import { useUserRefetch } from "../../Contexts/UserContext";

const SpaceOnboardingCompany = ({ path }) => {
  const { obState } = useContext(OnboardingStateContext);
  const refetchUserData = useUserRefetch();
  const onboardingUpdate = useSpaceOnboardingStateUpdater(refetchUserData);
  const { update } = onboardingUpdate;
  const updateState = (props) => {
		update(onboardingStateEnum.departments);
  };

  if (obState !== onboardingStateEnum.org)
    return <OnboardingAutoRedirect url={path} />;

  return (
    <OnboardingContainer>
      <OnboardingHeader step={2} />
      <div className="mx-4">
        <OnboardingUpdateContext.Provider value={onboardingUpdate}>
          <div className="of-y-auto w-100 mx-auto py-4">
            <div className="mx-auto" style={{ width: 520 }}>
              <div className="fs-28 mb-3 text-black">
                <FormattedMessage id="YOUR_COMPANY_DATA" />
              </div>
              <EntityOrganizationForm onSuccess={updateState} />
              <div className="fs-12">
                <SkipOnboardingLink textId={"IGNORE_ORGANIZATION_DESC"} />
              </div>
            </div>
          </div>
        </OnboardingUpdateContext.Provider>
      </div>
    </OnboardingContainer>
  );
};

export default SpaceOnboardingCompany;
