import React from "react";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";

const dragType = {
  sticky: "sticky",
  normal: "normal",
};

const PortalAwareItem = React.memo(
  ({ provided, snapshot, column, removeColumn, isDragDisabled, required }) => {
    const providedd = provided;
    const snapshott = snapshot;
    const droppedOutside =
      snapshott.isDropAnimating &&
      snapshott.draggingOver !== "droppable" &&
      (snapshot.dropAnimation.moveTo.y < -100 ||
        snapshot.dropAnimation.moveTo.y > 100);

    const icon = column && (column.columnChooserIcon || column.headerIcon);

    const usePortal = snapshott.isDragging;

    const style = {
      ...providedd.draggableProps.style,
      zIndex: 99999999,
      outline: "none",
      visibility: droppedOutside ? "hidden" : "initial",
    };

    const child = (
      <div
        ref={providedd.innerRef}
        {...providedd.draggableProps}
        {...providedd.dragHandleProps}
        style={style}
        className={`py-1 text-black ${usePortal && "dragging"}`}
      >
        <div className="ColumnChooserDragableContainer1 text-uppercase fs-14 text-grey">
          <span className="GripVerticalIconCC">
            {!isDragDisabled && (
              <FontAwesomeIcon
                className="PointerEventsRemove"
                icon={faGripVertical}
              />
            )}
          </span>
          {icon && <FontAwesomeIcon size="sm" className="mr-2" icon={icon} />}
          <span className="flex-1">
            {column &&
              (typeof column.title === "function"
                ? column.title()
                : column.title)}
          </span>
          {!required && (
            <span
              onClick={() => removeColumn(column.field, false)}
              style={{ float: "right", cursor: "pointer", color: "red" }}
              className="mx-3"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          )}
        </div>
      </div>
    );

    if (!usePortal) {
      return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, document.body);
  }
);

const DragItems = React.memo(({ fields, dict, removeColumn }) => {
  return fields.map((field, index) => {
    const column = dict[field];

    if (!column) return null;
    let resolvedTitle = "botoes";

    if (typeof column.title === "object") {
      resolvedTitle = column.title.props.id;
    } else if (typeof column.title === "string") {
      resolvedTitle = column.title;
    }
    return (
      <Draggable
        //   isDragDisabled={column.unOrderable}
        draggableId={resolvedTitle}
        index={index}
        key={resolvedTitle}
      >
        {(draggableProvided, draggableSnapshot) => (
          <PortalAwareItem
            //   isDragDisabled={column.unOrderable}
            required={column.required}
            removeColumn={removeColumn}
            column={column}
            provided={draggableProvided}
            snapshot={draggableSnapshot}
          />
        )}
      </Draggable>
    );
  });
});
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ColumnChooserDrag = ({ value, dict, onChange, removeColumn }) => {
  const { orderedFields, stickyFields } = value;

  const selectedCount = orderedFields.length + stickyFields.length;

  const onDragEnd = (result) => {
    // dropped outside the list


    if (result.destination.droppableId === result.source.droppableId) {
      // no movement
      if (result.destination.index === result.source.index) {
        return;
      }

      onChange((v) => {
        const propName =
          result.destination.droppableId === dragType.normal
            ? "orderedFields"
            : "stickyFields";

        const newColumns = reorder(
          v[propName],
          result.source.index,
          result.destination.index
        );
        return { ...v, [propName]: newColumns };
      });
    } else {
      const { droppableId } = result.destination;
      const willStick = droppableId === dragType.sticky;

      onChange((v) => {
        const newVal = { ...v };

        const { stickyDict, orderedFields, stickyFields } = newVal;

        const newStickyDict = { ...stickyDict };
        const newOrderedFields = [...orderedFields];
        const newStickyFields = [...stickyFields];

        let field;

        if (willStick) {
          field = newOrderedFields[result.source.index];
          newOrderedFields.splice(result.source.index, 1);
          newStickyFields.splice(result.destination.index, 0, field);
        } else {
          field = newStickyFields[result.source.index];
          newStickyFields.splice(result.source.index, 1);
          newOrderedFields.splice(result.destination.index, 0, field);
        }

        newStickyDict[field] = willStick;

        return {
          stickyDict: newStickyDict,
          orderedFields: newOrderedFields,
          stickyFields: newStickyFields,
        };
      });
    }

    // const newOrderedColumns = reorder(
    //   filteredSelectedColumns,
    //   result.source.index,
    //   result.destination.index
    // );

    // if (onChange) {
    //   onChange([...filteredSelectedStcikyColumns, ...newOrderedColumns]);
    // }
  };

  return (
    <div className="mt-2">
      <div
        style={{ textTransform: "uppercase" }}
        className="fs-14 mb-3 fw-medium text-black"
      >
        <FormattedMessage id={"SELECTED_COLUMNS"} /> ({selectedCount})
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{ overflow: "auto" }} className="ColumnChooserOrderList">
          <Droppable droppableId={dragType.sticky}>
            {(droppableProvided) => (
              <div
                className="column-chooser-sticky-container rounded p-2 mb-2"
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                <div className="column-chooser-sticky-title fs-14 text-primary">
                  <FormattedMessage id="FREEZED_COLUMNS" />
                </div>
                <DragItems
                  fields={stickyFields}
                  dict={dict}
                  removeColumn={removeColumn}
                />
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId={dragType.normal}>
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                <DragItems
                  fields={orderedFields}
                  dict={dict}
                  removeColumn={removeColumn}
                />
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
};

export default ColumnChooserDrag;
