import React from "react";
import { ColorFilterBars } from "../FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";

const TimelineColors = ({ children, entity }) => {
  return (
    <div className="h-100">
      <ColorFilterBars timeline entity={entity} />
      <div className="flex-1">{children}</div>
    </div>
  );
};

export default TimelineColors;
