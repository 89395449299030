import React, { useMemo } from "react";

import { useIntl } from "react-intl";
import { createFormRequestHook, useFormState } from "../../Components/Forms";
import { spaceClientSchema } from "../../config/schema";
import {
  SideFormFooter,
  SideFormContent,
  SideForm
} from "../../Components/Forms/SideMenuForm";
import {
  formatCustomerForm,
  defaultCustomerForm,
  validateCustomerForm
} from "./ClientsHelper";
import {
  convertCallEntityToForm,
  convertClientEntityToForm
} from "../Calls/CallsHelper";
import { useClient } from "../../Hooks/EntityHooks";
import { useToast } from "../../Components/Toast/ToastProvider";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import {
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import { useHandleError } from "../../Components/CGrid/ServerAwareHelper";
import ClientFormContent from "./ClientFormContent";

const useCustomerRequest = createFormRequestHook({
  schema: spaceClientSchema,
  dependencies: [],
  convertEntityToForm: convertClientEntityToForm
});

const CustomerForm = (props) => {
  const { key, loading, error, convertedForm, childProps } =
    useCustomerRequest(props);

  const { id, defaultForm, onSuccess, className } = props;

  const client = useClient(id);

  const resolvedForm = useMemo(() => {
    const form = {
      ...defaultForm,
      ...convertedForm
    };

    if (childProps && childProps.ClientType) {
      const { ClientTypes } = form;
      if (ClientTypes && !ClientTypes?.includes(childProps.ClientType)) {
        form.ClientTypes = [...ClientTypes, childProps.ClientType];
      } else {
        form.ClientTypes = [childProps.ClientType];
      }
    }

    return form;
  }, [childProps, convertedForm, defaultForm]);

  if (loading || error)
    return <LoaderSpinner center size="sm" className="text-secondary" />;

  const canEdit = client ? client.CanEdit : true;

  return (
    <CustomerFormBase
      className={className}
      onSuccess={onSuccess}
      canDelete={client?.CanDelete}
      id={id}
      canEdit={canEdit}
      key={key}
      convertedForm={resolvedForm}
    />
  );
};

const CustomerFormBase = ({
  convertedForm,
  id,
  canDelete,
  canEdit,
  onSuccess,
  className
}) => {
  const defaultForm = defaultCustomerForm();
  const handleTranslatedError = useHandleError(spaceClientSchema);

  const formState = useFormState(() => {
    return {
      ...defaultForm,
      ...convertedForm
    };
  });

  // const { form, handleChange } = formState;
  // const {
  //   // Name,
  //   // // Image,
  //   // Creator,
  //   // // FiscalNumber,
  //   // Manager,
  //   // AddressSelector, // Comments,
  //   // // CreationDate,
  //   // // ClientTypes,
  //   // // Comments,
  //   // Followers
  // } = form;
  // const { Country } = AddressSelector;
  // useMarkRead(spaceClientSchema, id, Manager);
  const createToast = useToast();
  const Intl = useIntl();
  const [post, { loading }] = useSidebarSpacePost(
    id ? `clients/${id}` : "clients",
    spaceClientSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        if (!id) defineChildProps({ Client: newId });
        createToast({
          pos: "tm",
          type: "success",
          title: `${Intl.formatMessage({ id: "ACCOUNT" })} nº ${newId || id}`,
          description: !id
            ? `${intl.formatMessage({ id: "CREATED_SUCCEFULLY" })}`
            : `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );
  const intl = useIntl();
  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `clients/${id}`,
    spaceClientSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "ACCOUNT" })} nº ${id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  // const handleChanges = e => {
  //   const { name, value } = e.target;
  //   handleChange({
  //     [name]: value
  //   });
  // };

  const handleSubmit = (form) => {
    post(formatCustomerForm(form));
  };

  // const handleChanges = (e) => {
  //   const { name, value } = e.target;
  //   handleChange({
  //     [name]: value
  //   });
  // };

  // const toTitleCase = (phrase = "") => {
  //   return phrase
  //     .toLowerCase()
  //     .split(" ")
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(" ");
  // };

  // const handleVatVerification = (values) => {
  //   const ELC = values.CompanyAddress.split("\n");

  //   const CompanyName = values.CompanyName;
  //   const CompanyAddress = ELC[0];
  //   const CompanyLocale = ELC[1];
  //   const CompanyPostalCode = ELC[2];
  //   handleChange({
  //     Name: toTitleCase(CompanyName),
  //     AddressSelector: {
  //       ...AddressSelector,
  //       Address1: toTitleCase(CompanyAddress),
  //       Locality: toTitleCase(CompanyLocale),
  //       PostalCode: CompanyPostalCode?.split(" ")[0]
  //     }
  //   });
  // };

  // const handleAddressSelectChanges = (e) => {
  //   const { name, value } = e.target;
  //   handleChange({
  //     [name]: value,
  //     Country: value.Country
  //   });
  // };

  // const noContactReadPermission = !useCurrentUserContactPermission();

  // const followers = useJoinedFollowerAccounts(Manager);
  // const possibleFollowers = useAvailableFollowers(Creator, followers);

  // const entity = useClient(id);
  // const currentManager = entity ? entity.Manager : Creator;
  // const canEditFollowers = useCanEditFollowers(Creator, currentManager);

  // const handleManagerChange = useCallback(
  //   ({ target: { value } }) => {
  //     handleChange({
  //       Manager: value,
  //       Followers: updateManagerFollowers(Followers, Manager, value, [])
  //     });
  //   },
  //   [Followers, Manager, handleChange]
  // );

  return (
    <SideForm
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateCustomerForm}
    >
      <SideFormContent className={className}>
        <ClientFormContent formState={formState} canEdit={canEdit} id={id} />
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        deleteLoading={deleteLoading}
        handleDelete={remove}
        isLoading={loading}
      />
    </SideForm>
  );
};

export default CustomerForm;
