import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import { formatAutomationCallForm } from "../../../../../Helpers/FormFormmaterHelper";
import { isValueExpression } from "../../../../../Helpers/MiscHelper";
import CallFormContent from "../../../../Calls/CallFormContent";
import { defaultCallForm } from "../../../../Calls/CallsHelper";
import { PipelineTypeEnum } from "../../../../Settings/Pipelines/NewPipelineHelper";
import { defaultTicketForm } from "../../../../Tickets/TicketsHelper";
import { ElementsRefContext } from "../../AutomationDetails";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import SourceHandles from "../../Handles/SourceHandles";
import TargetHandles from "../../Handles/TargetHandles";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import AutomationCreation, {
  EntityAutomationCreationSidebar,
  snakeToFormCase
} from "./CreationNode";

const convertAutomationToCallForm = (form, currencies) => {
  const {
    creator,
    received_date,
    target,
    manager_id,
    pipeline_id,
    contact_id,
    pipeline_status_id,
    status_detail_id,
    status_detail_description,
    organization_id,
    description,
    followers,
    addresses = [],
    department_id,
    team_id
  } = form;

  // const target = getSnakeCaseTarget(form);

  const resolvedBeginDate = isValueExpression(received_date)
    ? received_date
    : new Date(received_date);

  let resolvedAddresses = [];

  for (const e of addresses) {
    let newObj = {};
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const element = e[key];
        const newKey = snakeToFormCase(key);
        newObj[newKey] = element;
      }
    }
    resolvedAddresses.push(newObj);
  }

  let AddressSelector = {
    Id: resolvedAddresses[0]?.Id || undefined,
    Country: resolvedAddresses[0]?.CountryId || undefined,
    Address1: resolvedAddresses[0]?.Address1 || "",
    Address2: resolvedAddresses[0]?.Address2 || "",
    District: resolvedAddresses[0]?.District || "",
    Locality: resolvedAddresses[0]?.Locality || "",
    PostalCode: resolvedAddresses[0]?.PostalCode || "",
    Latitude: resolvedAddresses[0]?.Latitude || "",
    Longitude: resolvedAddresses[0]?.Longitude || ""
  };

  let DepartmentTeam;
  if (team_id) {
    DepartmentTeam = {
      type: "Team",
      data: team_id
    };
  } else if (department_id) {
    DepartmentTeam = {
      type: "Department",
      data: department_id
    };
  }

  return {
    CallTarget: target,
    ReceivedDate: resolvedBeginDate,
    DepartmentTeam,
    ManagerAccount: manager_id,
    Contact: contact_id,
    Pipeline: pipeline_id,
    PipelineStatus: pipeline_status_id,
    StatusDetailId: status_detail_id,
    StatusDetailDescription: status_detail_description,
    Company: organization_id,
    Creator: creator,
    Description: description,
    Followers: followers,
    AddressSelector
  };
};

const settings = {
  convertAutomationToForm: convertAutomationToCallForm,
  buildBaseForm: ({ user, id, convertedForm, currencies }) => {
    const defaultForm = defaultCallForm({ user });
    return { ...defaultForm, ...convertedForm };
  },
  getDefaultForm: ({ user }) => {
    return defaultTicketForm({ user });
  },
  pipelineType: PipelineTypeEnum.Call,
  FormContent: (props) => {
    return <CallFormContent {...props}></CallFormContent>;
  },
  formatToSnake: formatAutomationCallForm
};

const CallsCreationNode = React.memo(({ selected, id, data, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const AllElementsRef = useContext(ElementsRefContext);
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleDoubleClick = () => {
    openSidebar(
      <EntityAutomationCreationSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        CurrentNodeId={id}
        settings={settings}
        data={data}
        handleSubmit={handleSubmit}
        AllElements={AllElementsRef.current}
      />
    );
  };
  const { enabled, config } = data;
  const intl = useIntl();

  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <AutomationCreation
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleDoubleClick}
          selected={selected}
          icon={faPhoneAlt}
          text={intl.formatMessage({ id: "CREATE_CALL" })}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default CallsCreationNode;
