import { useEffect, useState } from "react";

export const MessageChooserListnerDict = {};

const calculateColumns = (columns, orderList, resetLayout) => {
  const fieldDict = getfieldTitleDict(columns);
  const result = [];
  for (const c of orderList) {
    if (fieldDict[c]) result.push(fieldDict[c]);
  }
  return result;
};

const getfieldTitleDict = (Columns) => {
  const result = {};
  for (const c of Columns) {
    result[c.field] = c;
  }
  return result;
};

export const useResolvedMessage = (columns, id) => {
  const [resolvedColumn, setResolvedColumn] = useState(() => {
    //ir a localstorage
    let gridColumnLayout = JSON.parse(
      window.localStorage.getItem("ar-grid-inbox-msg-layout")
    );

    const orderList = gridColumnLayout ? gridColumnLayout[id] : undefined;

    if (orderList) {
      return calculateColumns(columns, orderList);
    } else {
      let result = [];
      for (const e of columns) {
        if (e.default) {
          result.push(e);
        }
      }
      return result;
    }
  });
  useEffect(() => {
    if (!MessageChooserListnerDict[id]) MessageChooserListnerDict[id] = [];

    const listner = (orderList) => {
      if (!orderList) setResolvedColumn(columns);
      else setResolvedColumn(calculateColumns(columns, orderList));
    };

    MessageChooserListnerDict[id].push(listner);

    return () => {
      const index = MessageChooserListnerDict[id].indexOf(listner);
      MessageChooserListnerDict[id].splice(index, 1);
    };
  }, [columns, id]);
  return resolvedColumn;
};
