import React, { useState, useMemo, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowFromTop,
  faLocation,
  faPaperclip,
  faArrowFromBottom,
  faCrosshairs,
  faDownload,
  faTimes,
  faSignature,
  faFlag,
  faBuilding,
  faGlobe,
  faLock
} from "@fortawesome/pro-light-svg-icons";
import AnimatedAccordion from "../AnimatedAccordion/AnimatedAccordion";
import {
  EntityOverviewItem,
  EntityOverviewPlaceholder,
  EntityOverviewRoundedItem
} from "../EntityOverview/EntityOverview";
import CountryProfile from "../Country/CountryProfile";
import { useCountry, useEntityValue } from "../../Hooks/EntityHooks";
import FileUploader from "../FileUploader/FileUploader";
import "./EntitySummaries.css";
import classnames from "classnames";
import {
  ClientMemoryProvider,
  useSpaceQuery,
  getEntity
} from "../../Helpers/IOClient";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import { AccountProfileImg } from "../Img/Img";
import Button from "../Button/Button";
import EntitySummaryBreadcrumbs from "./EntitySummaryBreadcrumbs";
import { EntitySummaryMessages } from "./EntitySummaryMessages/Messages";
import {
  SideFormCancelContext,
  DeleteModalContent
} from "../Forms/SideMenuForm";
import { LinkClosureContext } from "../../Helpers/HooksHelper";
import { useSidebar, useSidebarAnimationState } from "../Sidebar/SidebarV2";
import {
  DetailsEntityContext,
  DetailsEntityIdContext
} from "../DetailsView/DetailsView";
import EntityFollowIcon from "../EntityFollow/EntityFollowIcon";
import EntityIconOverview from "../EntityIconOverview/EntityIconOverview";
import { StaticAccountsInRoomStack } from "../AccountsInRoom/AccountsInRoomStack";
import {
  SidebarContainer,
  SidebarDeleteRefetchContext,
  useSidebarSpaceDelete
} from "../Sidebar/SidebarV2Helper";
import {
  EntitySummaryConfigContext,
  ItemEliminatedComponent,
  useSummaryConfig
} from "./EntitySummariesHelper";
import { EntitySummaryHeaderTitle } from "./EntitySummariesComponents";
import { FormattedMessage, useIntl } from "react-intl";
import { SidebarRefetchContext } from "../Sidebar/SidebarV2Helper";
import {
  useSidebarView,
  SidebarVisualTypeEnum
} from "../Sidebar/SidebarViewsHelper";
import SidebarBasicCloseButton from "../Sidebar/SidebarBasicCloseButton";
import SidebarViewSelector, {
  SidebarSelectorWidth
} from "../Sidebar/SidebarViewSelector";
import { MessagesConnection } from "./EntitySummaryMessages/MessagesConnection";
import { useConnectionId } from "../../Containers/RealTime/RealTime";
import { useEntityReader } from "../../Helpers/EntityHelper";
import PipelineHistory from "../Breadcrumbs/BreadcrumbsPipelineHistory";
import ImagePreview from "../FileUploader/ImagePreview";
import Modal from "../Modal/Modal";
import { ChildrenCountsProvider } from "../../Containers/EntityChildrenProgress/EntityChildrenProgressHelper";
import { useToast } from "../Toast/ToastProvider";
import {
  spaceCallsSchema,
  spaceClientSchema,
  spaceContactSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceProjectsSchema,
  spaceSubscriptionSchema,
  spaceTasksSchema,
  spaceTicketsSchema,
  spaceDocumentsSchema,
  spaceDocTemplatesSchema
} from "../../config/schema";
import { DealProfile, DifrentDealProfile } from "../Deals/DealProfile";
import { DifrentClientProfile } from "../Clients/ClientProfile";
import { DifrentContactProfile } from "../Contacts/ContactProfile";
import { DifrentContractProfile } from "../Contract/ContractProfile";
import { DifrentSubscriptionProfile } from "../Subscription/SubscriptionProfile";
import { DifrentCallProfile } from "../../Containers/Origin/CallOrigin";
import { DifrentProjectProfile } from "../Projects/ProjectProfile";
import { DifrentTicketProfile } from "../../Containers/Origin/TicketOrigin";
import { DifrentTaskProfile } from "../../Containers/Origin/TaskOrigin";
import { handleError } from "../../Helpers/MiscHelper";
import { DifrentDocumentProfile } from "../../Containers/Documents/DocumentHelper";
import { DifrentDocTemplateProfile } from "../../Containers/Documents/DocTemplates/DocTemplatesHelper";
import { useHandleError } from "../CGrid/ServerAwareHelper";
import { DeleteSingleButtonContextMenu } from "../CGrid/DeleteMultipleModal";
import { faWindowMaximize } from "@fortawesome/pro-regular-svg-icons";
import { ConnectedTicketTagItem } from "../../Containers/AdvancedMultiInputs";
import TextEllipsis from "../TextEllipsis/TextEllipsis";
import {
  EntitySummaryParentSchemaProviderForMessagesContext,
  EntitySummaryParentIdProviderForMessagesContext
} from "./EntitySummaryProviderForMessages";

export const DescriptionMaximizer = ({ isMaximized, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="text-primary cursor-pointer btn-link d-flex align-items-center justify-content-end mt-1"
    >
      <FontAwesomeIcon
        className="mr-2"
        icon={isMaximized ? faArrowFromBottom : faArrowFromTop}
      />
      <FormattedMessage
        id={isMaximized ? "MINIMIZE_DESCRIPTION" : "MAXIMIZE_DESCRIPTION"}
      />
    </div>
  );
};

export const TagsOverViewSummary = ({ tags = [], schema }) => {
  if (tags.length === 0) return <div>―</div>;

  const resolvedTags = [];

  for (const e of tags) {
    const tag = getEntity(schema, e);
    resolvedTags.push(tag.Name);
  }

  const tagsLine = resolvedTags.join(", ");
  return (
    <div className="text-truncate">
      <TextEllipsis
        truncate
        Component={<div className="text-truncate">{tagsLine}</div>}
        text={tagsLine}
      />
    </div>
  );
};

export const TagsOverview = ({ tags = [], schema }) => {
  if (tags.length === 0) return <div>―</div>;

  return (
    <div className="d-flex align-items-center">
      {tags.map((e) => {
        return <ConnectedTicketTagItem className="mr-3" category={e} key={e} />;
      })}
    </div>
  );
};

export const DescriptionOverview = ({ Description, NoTagDescription }) => {
  const [isMaximized, setIsMaximized] = useState(false);
  const [showMaximize, setShowMaximize] = useState(true);
  const inputRef = useRef();
  const iframeRef = useRef();
  const iframeRef2 = useRef();
  useEffect(() => {
    if (
      inputRef.current &&
      showMaximize &&
      inputRef.current.offsetHeight < 180
    ) {
      setShowMaximize(false);
    }
  }, [showMaximize]);

  const [src, setSrc] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const imgs = inputRef.current.getElementsByTagName("img");

    for (const img of imgs) {
      const link = img.closest("a");

      if (link) continue;

      img.style.cursor = "pointer";

      img.addEventListener("click", (e) => {
        setIsOpen(true);
        setSrc(e.target.src);
      });
    }
  }, []);

  const [maxModelStatus, setMaxModalStatus] = useState(false);

  const handleMaxModelStatusToggle = () => {
    setMaxModalStatus((oldS) => {
      return !oldS;
    });
  };

  return (
    <>
      <ImageModal isOpen={isOpen} setIsOpen={setIsOpen} src={src} />
      <Modal
        isOpen={maxModelStatus}
        enableCloseButton
        onClose={handleMaxModelStatusToggle}
      >
        <div
          style={{ width: "85vw", height: "85vh", borderRadius: "7px" }}
          className="p-2 borcer bg-white of-hidden"
        >
          <iframe
            onLoad={() => {
              if (iframeRef2.current) {
                const { contentDocument } = iframeRef2.current;
                let doc = contentDocument;
                if (doc?.body) {
                  doc.body.innerHTML =
                    doc.body.innerHTML +
                    `<style>
													::-webkit-scrollbar {
														padding: 4px 2px;
														width: 12px;
														height: 12px;
														border-radius: 12px;
												}
												::-webkit-scrollbar-thumb {
													background: #c1c9d2;
													border-radius: 12px;
													border: 2px solid transparent;
													background-clip: content-box;
											}
											::-webkit-scrollbar-corner {
												background: #e7f1fd;
											}
											body{
												font-size: 14px;
												font-family: "Roboto", sans-serif;
											}
												</style>`;
                }
              }
            }}
            ref={iframeRef2}
            style={{ width: "100%", height: "100%", border: 0 }}
            title="descriptionModal"
            srcDoc={Description}
          />
          {/* <div
            className="breakWord text-black"
            dangerouslySetInnerHTML={{
              __html: Description || "-"
            }}
          ></div> */}
        </div>
      </Modal>
      <div className="d-flex w-100 justify-content-end align-items-center">
        <div
          className="cursor-pointer text-primary"
          onClick={handleMaxModelStatusToggle}
        >
          <FontAwesomeIcon icon={faWindowMaximize} />
        </div>
      </div>
      {/* <div
       ref={inputRef} 
        className="breakWord px-2 mt-2 py-1 ar-rounded-badge"
        style={isMaximized ? undefined : { maxHeight: 180, overflow: "hidden" }}
        dangerouslySetInnerHTML={{
          __html: Description || "-"
        }}
      ></div> */}
      <div
        ref={inputRef}
        style={isMaximized ? undefined : { maxHeight: 180, overflow: "hidden" }}
        className="w-100 h-100 px-2 mt-2 py-1 ar-rounded-badge"
      >
        <iframe
          onLoad={() => {
            if (iframeRef.current) {
              const { contentDocument } = iframeRef.current;
              let doc = contentDocument;
              if (doc?.body) {
                doc.body.innerHTML =
                  doc.body.innerHTML +
                  `<style>
								::-webkit-scrollbar {
									padding: 4px 2px;
									width: 12px;
									height: 12px;
									border-radius: 12px;
							}
							::-webkit-scrollbar-thumb {
								background: #c1c9d2;
								border-radius: 12px;
								border: 2px solid transparent;
								background-clip: content-box;
						}
						::-webkit-scrollbar-corner {
							background: #e7f1fd;
						}
						body{
							font-size: 14px;
							font-family: "Roboto", sans-serif;
						}
							</style>`;
              }
            }
          }}
          ref={iframeRef}
          style={{ width: "100%", height: "100%", border: 0 }}
          title="descriptionModal"
          srcDoc={Description}
        />
      </div>
      {showMaximize && Description && (
        <DescriptionMaximizer
          isMaximized={isMaximized}
          onClick={() => setIsMaximized((maxmized) => !maxmized)}
        />
      )}
    </>
  );

  // return (
  //   <div className="text-black">
  //     {isMaximized ? (
  //       <div
  //         className="breakWord"
  //         dangerouslySetInnerHTML={{
  //           __html: Description || "-",
  //         }}
  //       ></div>
  //     ) : (
  //       <div className="breakWord">
  //         <TextEllipsis text={NoTagDescription || "-"} length={200} />
  //       </div>
  //     )}

  //     {Description && NoTagDescription && NoTagDescription.length > 200 && (
  //       <DescriptionMaximizer
  //         isMaximized={isMaximized}
  //         onClick={() => setIsMaximized((maxmized) => !maxmized)}
  //       />
  //     )}
  //   </div>
  // );
};

const ImageModal = ({ src, isOpen, setIsOpen, extraCloseButton }) => {
  return (
    <Modal
      className="of-v"
      extraCloseButton={
        <>
          <div
            className="ar-modal-preview-file-content-close-button"
            onClick={() => setIsOpen(false)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div
            style={{ top: 55 }}
            className="ar-modal-preview-file-content-close-button text-color-link"
            onClick={() => {
              var a = document.createElement("a");
              a.href = src;
              a.download = "Image.png";
              a.click();
              a.remove();
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </div>
        </>
      }
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <ImagePreview src={src} />
    </Modal>
  );
};

export const EntitySummaryAccordion = ({
  icon,
  text,
  textId,
  children,
  onExtraClick,
  className,
  ...rest
}) => {
  return (
    <AnimatedAccordion
      onExtraClick={onExtraClick}
      storageName={textId}
      className={classnames("py-2", { "px-3": !/px-0/i.test(className) })}
      title={
        <div className="fs-14 fw-medium">
          {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
          {textId ? <FormattedMessage id={textId} /> : text}
        </div>
      }
      isOpenByDefault
      {...rest}
    >
      <div className="pl-4">{children}</div>
    </AnimatedAccordion>
  );
};

export const EntitySummaryPhoneNumber = ({ number }) => {
  if (!number) return "-";

  const text = number.replace(".", "");

  return text;
};

const EntitySideAddressOverviewDetail = ({ address }) => {
  const intl = useIntl();
  const {
    Address1,
    Address2,
    Locality,
    PostalCode,
    Latitude,
    Longitude,
    Name,
    // District,
    Country: countryId
  } = address;

  const country = useCountry(countryId);

  return (
    <EntitySummaryAccordion
      icon={faLocation}
      text={Name || intl.formatMessage({ id: "ADDRESS" })}
    >
      <EntityOverviewItem icon={faFlag} titleId="COUNTRY">
        {country ? (
          <CountryProfile className="text-black" country={country} />
        ) : (
          <EntityOverviewPlaceholder rounded />
        )}
      </EntityOverviewItem>
      <EntityOverviewRoundedItem icon={faBuilding} titleId="ADDRESS">
        {!Address1 && !Address2 && !PostalCode && !Locality && "-"}
        {Address1 ? Address1 : ""} {Address2 ? Address2 : ""}{" "}
        {PostalCode ? PostalCode : ""} {Locality ? Locality : ""}
      </EntityOverviewRoundedItem>
      {Latitude && Longitude && (
        <EntityOverviewRoundedItem icon={faGlobe} titleId="PLAN_GEOLOCATION_SM">
          {Latitude ? Latitude : ""}
          {" | "}
          {Longitude ? Longitude : ""}
        </EntityOverviewRoundedItem>
      )}
    </EntitySummaryAccordion>
  );
};

export const EntitySummaryAddress = ({ addresses }) => {
  if (!addresses || addresses.length === 0) return null;

  if (Array.isArray(addresses)) {
    return (
      <EntitySummaryAccordion icon={faLocation} textId={"LOCATION_ADDRESS"}>
        {addresses.map((e, i) => (
          <EntitySideAddressOverviewDetail key={i} address={e} />
        ))}
      </EntitySummaryAccordion>
    );
  }

  return (
    <EntitySummaryAccordion icon={faLocation} textId={"LOCATION_ADDRESS"}>
      <EntitySideAddressOverviewDetail address={addresses} />
    </EntitySummaryAccordion>
  );
};

export const EntitySummarySignatureAccordion = ({
  Signature,
  UserSignature
}) => {
  if (!Signature && !UserSignature) return null;

  if (UserSignature) {
    return (
      <EntitySummaryAccordion icon={faSignature} textId={"USER_SIGNATURE"}>
        <div
          style={{ border: "1px solid #ced4da", borderRadius: "5px" }}
          className="mb-3 w-100"
        >
          <img
            className="p-2"
            src={UserSignature}
            alt="UserSignature"
            height="75"
          />
        </div>
      </EntitySummaryAccordion>
    );
  }

  return (
    <EntitySummaryAccordion icon={faSignature} textId={"CLIENT_SIGNATURE"}>
      <div
        style={{ border: "1px solid #ced4da", borderRadius: "5px" }}
        className="mb-3 w-100"
      >
        <img className="p-2" src={Signature} alt="Signature" height="75" />
      </div>
    </EntitySummaryAccordion>
  );
};

export const EntitySummaryFilesAccordion = ({
  Files,
  CanEdit,
  IsFollowing
}) => {
  if (!Files || Files.length === 0) return null;
  // return null;

  // eslint-disable-next-line no-unreachable
  if (CanEdit || IsFollowing)
    return (
      <EntitySummaryAccordion icon={faPaperclip} textId={"ATTACHMENTS"}>
        <FileUploader value={Files} enableSwapper preview />
      </EntitySummaryAccordion>
    );
  else return null;
};

export const EntityPipelineHistoryAccordion = ({ endpoint, schema, id }) => {
  const { data, loading, error } = useSpaceQuery(endpoint);

  if (!endpoint || loading || error) return null;

  return (
    <EntitySummaryAccordion
      icon={faCrosshairs}
      textId={"HISTORY_PIPELINE_STAGES"}
    >
      <EntityPipelineHistoryAccordionContent data={data} schema={schema} />
    </EntitySummaryAccordion>
  );
};

const EntityPipelineHistoryAccordionContent = ({ data, schema }) => {
  const ResolvedPipelineObjects = useMemo(() => {
    return data?.sort(function (a, b) {
      return new Date(a.Date) - new Date(b.Date);
    });
  }, [data]);

  return <PipelineHistory schema={schema} items={ResolvedPipelineObjects} />;
};

export const EntitySummaryTabstrip = ({ children, className, noFlex }) => {
  const [childIndex, setChildIndex] = useState(0);

  const childArr = useMemo(() => {
    return React.Children.toArray(children);
  }, [children]);

  return (
    <>
      <div
        className={classnames(className, "ar-entity-summary-tabstrip", {
          "mx-3": !/mx-0/i.test(className)
        })}
      >
        {childArr.map((tab, index) => {
          return React.cloneElement(tab, {
            onClick: setChildIndex,
            index,
            active: childIndex === index
          });
        })}
      </div>
      <EntitySummaryContent noFlex={noFlex}>
        {childArr[childIndex].props.children}
      </EntitySummaryContent>
    </>
  );
};

export const EntitySummaryTab = ({
  title,
  titleId,
  onClick,
  index,
  active
}) => {
  return (
    <div
      className={active ? "active" : undefined}
      onClick={() => onClick(index)}
    >
      {titleId ? <FormattedMessage id={titleId} /> : title}
    </div>
  );
};

export const EntitySummary = ({ children, className }) => {
  return (
    <div
      className={classnames("d-flex h-100 flex-column", className)}
      style={{ zIndex: 1 }}
    >
      {children}
    </div>
  );
};

export const EntitySummaryContent = ({ children, noFlex }) => {
  return (
    <div
      className={classnames("fs-14 pb-5", {
        "flex-1 of-y-auto": !noFlex
      })}
    >
      {children}
    </div>
  );
};

export const SummaryHeaderProfile = ({ schema, value, size }) => {
  switch (schema.name) {
    case spaceDealsSchema.name:
      return <DifrentDealProfile deal={value} size={size} />;
    case spaceClientSchema.name:
      return <DifrentClientProfile client={value} size={size} />;
    case spaceContactSchema.name:
      return <DifrentContactProfile contact={value} size={size} />;
    case spaceContractSchema.name:
      return <DifrentContractProfile contract={value} size={size} />;
    case spaceSubscriptionSchema.name:
      return <DifrentSubscriptionProfile subscription={value} size={size} />;
    case spaceCallsSchema.name:
      return <DifrentCallProfile call={value} size={size} />;
    case spaceProjectsSchema.name:
      return <DifrentProjectProfile project={value} size={size} />;
    case spaceTicketsSchema.name:
      return <DifrentTicketProfile ticket={value} size={size} />;
    case spaceTasksSchema.name:
      return <DifrentTaskProfile task={value} size={size} />;
    case spaceDocumentsSchema.name:
      return <DifrentDocumentProfile doc={value} size={size} />;
    case spaceDocTemplatesSchema.name:
      return <DifrentDocTemplateProfile doc={value} size={size} />;
    default:
      return <div></div>;
  }
};

export const EntitySummaryHeader = ({
  ActionButton,
  value,
  onEdit,
  noImage,
  translatedTitleId,
  TextComponent,
  schema,
  noChildren,
  noHistory,
  noFollow,
  forceOverview,
  FormButton
}) => {
  const { Id, IsFollowing } = value;

  const handleTranslatedError = useHandleError(schema);
  const deleteUrl = useMemo(() => {
    const n = schema?.options?.getSchemaName();
    return `${n}/${value.Id}`;
  }, [schema, value.Id]);
  const createToast = useToast();
  const intl = useIntl();
  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    deleteUrl,
    schema,
    {
      onSuccess: ({ closeSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: translatedTitleId })} nº ${newId}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
        closeSidebar();
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const [modalState, setModalState] = useState(false);

  const closeModal = () => {
    setModalState(false);
  };
  const handleDeleteOpenModal = () => {
    setModalState(true);
  };

  return (
    <div className="px-3 pt-3">
      <div className="d-flex align-items-center of-hidden text-black">
        {!noImage && (
          <div className="flex-0-0-auto mr-2">
            <SummaryHeaderProfile schema={schema} value={value} />
            {/* <AccountProfileImg
              className="flex-0-0-auto"
              circular
              account={value}
            /> */}
          </div>
        )}
        <EntitySummaryHeaderTitle TextComponent={TextComponent} value={value} />
        {!noFollow && (
          <EntityFollowIcon
            className="ml-3"
            isFollowing={IsFollowing}
            schema={schema}
            canEdit={value.CanEdit}
            id={Id}
          />
        )}
      </div>

      <div className="mt-1 d-flex align-items-center justify-content-between">
        <div className="fs-14 d-flex align-items-center">
          {/* <RoundedBadge className="fs-12 px-2 mr-2">Nº {Id}</RoundedBadge> */}

          <EntityIconOverview
            noHistory={noHistory}
            noChildren={noChildren}
            schema={schema}
            value={value}
          />
        </div>

        <div className="d-flex">
          {forceOverview ? (
            FormButton ? (
              <FormButton
                className="mr-2"
                canEdit={value.CanEdit && value.IsLock === false}
                id={value.Id}
              />
            ) : undefined
          ) : (
            onEdit && (
              <Button
                // propStyle={{ height: 25 }}
                onClick={onEdit}
                size="sm"
                vType="outline-primary"
                className="mr-2"
              >
                <FormattedMessage id="EDIT" />
              </Button>
            )
          )}
          <Modal isOpen={modalState} onClose={closeModal}>
            <DeleteModalContent
              loadingDelete={deleteLoading}
              closeModal={closeModal}
              handleDelete={remove}
            />
          </Modal>
          {ActionButton && (
            <ActionButton sidebar entity={value}>
              <DeleteSingleButtonContextMenu
                handleDeleteOpenModal={handleDeleteOpenModal}
                entity={value}
              />
            </ActionButton>
          )}
        </div>
      </div>
    </div>
  );
};

const EntitySummaryOverviewContent = ({
  value,
  entity,
  edit = false,
  FormComponent,
  form,
  childProps,
  Summary,
  OverviewHeader,
  ActionButton,
  forcedWidth,
  disableMessages,
  TextComponent,
  schema,
  noImage,
  deleteRefetchRef,
  ...rest
}) => {
  const [isEditing, setIsEditing] = useState(edit);
  const sidebarView = useSidebarView();
  const { CanEdit, IsLock, IsFollowing, CanViewChat } = value || entity;

  const handleEdit = useMemo(() => {
    if (!CanEdit) return undefined;

    return () => setIsEditing(true);
  }, [CanEdit]);

  const toggleEdit = () => setIsEditing((editing) => !editing);

  return (
    <EntitySummary
      className={classnames("shadow", {
        "w-100":
          (!(CanEdit || IsFollowing || (!CanEdit && CanViewChat)) &&
            !disableMessages) ||
          forcedWidth
      })}
    >
      <ChildrenCountsProvider
        forcedWidth={forcedWidth}
        sidebarView={sidebarView}
        id={value.Id}
        schema={schema}
      >
        <SidebarDeleteRefetchContext.Provider value={deleteRefetchRef}>
          <div
            style={{
              width: forcedWidth
                ? "100%"
                : sidebarView === SidebarVisualTypeEnum.fullscreen
                ? "100%"
                : 520
            }}
            className="flex-1 of-y-auto d-flex ar-entity-summary-container flex-column"
          >
            {isEditing ? (
              <div className="flex-1 of-y-auto">
                <SideFormCancelContext.Provider value={toggleEdit}>
                  <FormComponent
                    childProps={childProps}
                    form={form}
                    className="px-3 py-3"
                    id={value.Id}
                    schema={schema}
                    {...rest}
                  />
                </SideFormCancelContext.Provider>
              </div>
            ) : (
              <>
                <EntitySummaryHeader
                  ActionButton={ActionButton}
                  OverviewHeader={OverviewHeader}
                  isEditing={isEditing}
                  onEdit={handleEdit}
                  value={value}
                  noImage={noImage}
                  schema={schema}
                  TextComponent={TextComponent}
                  {...rest}
                />
                <Summary value={value} />
              </>
            )}
          </div>
        </SidebarDeleteRefetchContext.Provider>
      </ChildrenCountsProvider>
    </EntitySummary>
  );
};

const CreationEntitySummaryOverview = ({
  FormComponent,
  form,
  creationHeaderText,
  childProps,
  refetchRef,
  ...rest
}) => {
  const sidebarView = useSidebarView();
  const showCloseButton = sidebarView === SidebarVisualTypeEnum.fullscreen;
  return (
    <SidebarRefetchContext.Provider value={refetchRef}>
      <div className="d-flex h-100 flex-column bg-white">
        <div
          className={classnames(
            "ar-entity-summary-header d-flex align-items-center justify-content-between text-black fw-medium fs-18",
            !showCloseButton && "pl-3"
          )}
        >
          <div className="d-flex align-items-center">
            {showCloseButton && (
              <SidebarBasicCloseButton className="pl-3 mr-3 h-100 d-flex align-items-center" />
            )}
            {creationHeaderText}
          </div>
          <SidebarViewSelector />
        </div>
        <div
          className="flex-1 of-y-auto shadow"
          style={{ width: !showCloseButton && 520 }}
        >
          <FormComponent
            childProps={childProps}
            form={form}
            className="px-3 pt-3 pb-5"
            {...rest}
          />
        </div>
      </div>
    </SidebarRefetchContext.Provider>
  );
};

const FormWidth = 520;
const MessagesWidth = 520;
const ClosedMessagesWidth = 60;
const AccountsInRoomWidth = 200;

const getOriginObject = (entity) => {
  const { Call, Project, Task, Client, Ticket, Team, Contract, Deal } = entity;

  return (
    Call || Project || Task || Client || Ticket || Team || Contract || Deal
  );
};

const mutateBeforeNormalization = (entity) => {
  //;
  const { Client, IndirectClient } = entity;

  const resolvedClient = Client || IndirectClient;

  if (!resolvedClient) return entity;

  const origin = getOriginObject(entity);

  if (!origin) return entity;

  if (!origin.Client && !origin.IndirectClient)
    origin.IndirectClient = resolvedClient;

  return entity;
};

const DefaultEntityNoSidebarSummaryOverview = ({
  schema,
  id,
  url,
  type,
  disableMessages,
  roomType,
  ...rest
}) => {
  const spaceQueryUrl = useMemo(() => {
    return `${url}/${id}`;
  }, [id, url]);

  const { loading, error, data } = useSpaceQuery(spaceQueryUrl, schema, {
    mutateBeforeNormalization
    // headers: {
    //   ConnectionID: conId
    // }
  });

  const value = useEntityValue(schema, id);

  const resolvedValue = value || {};

  const [isShowingMessages, setIsShowingMessages] = useState(true);

  useEntityReader(data ? resolvedValue : undefined, schema);

  const sidebarView = useSidebarView();
  const { CanEdit, IsFollowing, CanViewChat } = resolvedValue;
  const breadcrumbsWidth = useMemo(() => {
    return 400;
  }, []);

  const config = useSummaryConfig(schema);

  // const messagesKey = `${roomType}-${id}`;

  const createToast = useToast();

  useEffect(() => {
    if (data === null && loading === false) {
      if (error && error.status === 403) {
        createToast({
          pos: "tm",
          type: "danger",
          description: (
            <FormattedMessage id="API_NOT_FOUND_OR_NO_PERMISSIONS_VIEW" />
          ),
          title: <FormattedMessage id="ERROR" />
        });
      } else if (
        error &&
        (error.status === 400 || error.status === 500 || error.status === 404)
      ) {
        handleError(createToast, error);
      } else
        createToast({
          pos: "tm",
          type: "warning",
          description: <FormattedMessage id="ITEM_DELETED_WARNING" />,
          title: <FormattedMessage id="WARNING" />
        });
    }
  }, [createToast, data, error, loading]);

  if (resolvedValue.IsDeleted) {
    return (
      <DetailsEntityIdContext.Provider value={id}>
        <DetailsEntityContext.Provider value={resolvedValue}>
          <EntitySummaryConfigContext.Provider value={config}>
            <div className="ar-entity-summary-header d-flex align-items-center pl-3 justify-content-start">
              <div className="d-flex align-items-center">
                <EntitySummaryBreadcrumbs
                  width={breadcrumbsWidth}
                  schema={schema}
                  id={id}
                />
              </div>
            </div>
            <div className="d-flex flex-1 of-y-auto of-x-hidden w-100">
              <EntitySummary className="shadow w-100">
                <div className="flex-1 of-y-auto d-flex ar-entity-summary-container flex-column">
                  <div className="flex-1 of-y-auto">
                    <ItemEliminatedComponent schema={schema} />
                  </div>
                </div>
              </EntitySummary>
            </div>
          </EntitySummaryConfigContext.Provider>
        </DetailsEntityContext.Provider>
      </DetailsEntityIdContext.Provider>
    );
  }

  return (
    <DetailsEntityIdContext.Provider value={id}>
      <DetailsEntityContext.Provider value={resolvedValue}>
        <EntitySummaryConfigContext.Provider value={config}>
          <div className="ar-entity-summary-header d-flex align-items-center pl-3 justify-content-start">
            <div className="d-flex align-items-center">
              <EntitySummaryBreadcrumbs
                width={breadcrumbsWidth}
                schema={schema}
                id={id}
              />
            </div>
          </div>
          <div className="d-flex flex-1 of-y-auto of-x-hidden">
            {loading || error ? (
              <div
                className="h-100 bg-white d-flex align-items-center justify-content-center ar-entity-summary-container"
                style={{
                  width: "100%"
                }}
              >
                <LoaderSpinner size="sm" className="text-primary" />
              </div>
            ) : (
              <EntitySummaryOverviewContent
                schema={schema}
                value={resolvedValue}
                {...rest}
              />
            )}
          </div>
        </EntitySummaryConfigContext.Provider>
      </DetailsEntityContext.Provider>
    </DetailsEntityIdContext.Provider>
  );
};

const DefaultEntitySummaryOverview = ({
  schema,
  id,
  url,
  type,
  disableMessages,
  disableOriginHeader,
  roomType,
  ...rest
}) => {
  const conId = useConnectionId();

  const spaceQueryUrl = useMemo(() => {
    return `${url}/${id}`;
  }, [id, url]);

  const { loading, error, data, refetch } = useSpaceQuery(
    spaceQueryUrl,
    schema,
    {
      mutateBeforeNormalization,
      headers: {
        ConnectionID: conId
      }
    }
  );

  const value = useEntityValue(schema, id);

  const resolvedValue = value || {};

  const [isShowingMessages, setIsShowingMessages] = useState(true);

  const { NewCommentsCount, AccountsInRoom } = resolvedValue;

  useEntityReader(data ? resolvedValue : undefined, schema);

  const sidebarView = useSidebarView();
  const { CanEdit, IsFollowing, CanViewChat } = resolvedValue;
  const breadcrumbsWidth = useMemo(() => {
    return sidebarView === SidebarVisualTypeEnum.fullscreen
      ? window.innerWidth - 16 - 200 - 50 - 50
      : FormWidth +
          (disableMessages
            ? -16 - 40 - 10
            : isShowingMessages
            ? CanEdit || IsFollowing
              ? MessagesWidth - AccountsInRoomWidth - SidebarSelectorWidth
              : -16 - 50
            : ClosedMessagesWidth - AccountsInRoomWidth - SidebarSelectorWidth);
  }, [CanEdit, IsFollowing, disableMessages, isShowingMessages, sidebarView]);

  const key = `${url}${id}`;

  const config = useSummaryConfig(schema);

  const [, closeSidebar] = useSidebar();
  const animating = useSidebarAnimationState();
  // const messagesKey = `${roomType}-${id}`;

  const createToast = useToast();

  useEffect(() => {
    if (data === null && loading === false) {
      if (error && error.status === 403) {
        createToast({
          pos: "tm",
          type: "danger",
          description: (
            <FormattedMessage id="API_NOT_FOUND_OR_NO_PERMISSIONS_VIEW" />
          ),
          title: <FormattedMessage id="ERROR" />
        });
      } else if (
        error &&
        (error.status === 400 || error.status === 500 || error.status === 404)
      ) {
        handleError(createToast, error);
      } else
        createToast({
          pos: "tm",
          type: "warning",
          description: <FormattedMessage id="ITEM_DELETED_WARNING" />,
          title: <FormattedMessage id="WARNING" />
        });
      closeSidebar();
    }
  }, [closeSidebar, createToast, data, error, loading]);

  if (resolvedValue.IsDeleted) {
    return (
      <DetailsEntityIdContext.Provider value={id}>
        <EntitySummaryParentSchemaProviderForMessagesContext.Provider
          value={schema}
        >
          <EntitySummaryParentIdProviderForMessagesContext.Provider value={id}>
            <DetailsEntityContext.Provider value={schema}>
              <EntitySummaryConfigContext.Provider value={config}>
                <LinkClosureContext.Provider value={() => closeSidebar(true)}>
                  {/* <MessagesConnection
                roomType={roomType}
                entityId={id}
                schema={schema}
                // key={messagesKey}
                disableMessages={disableMessages}
              > */}
                  <SidebarContainer fullWidth={false} key={key}>
                    <div className="ar-entity-summary-header d-flex align-items-center pl-3 justify-content-between">
                      <div className="d-flex align-items-center">
                        {sidebarView === SidebarVisualTypeEnum.fullscreen && (
                          <SidebarBasicCloseButton className="pl-3 h-100 d-flex align-items-center" />
                        )}
                        <EntitySummaryBreadcrumbs
                          width={breadcrumbsWidth}
                          schema={schema}
                          id={id}
                        />
                      </div>
                      <div className="d-flex pl-3 align-items-center">
                        {(CanEdit || IsFollowing) && (
                          <StaticAccountsInRoomStack
                            disableMessages={disableMessages}
                            width={AccountsInRoomWidth}
                            accountsInRoom={AccountsInRoom}
                          />
                        )}

                        <SidebarViewSelector />
                      </div>
                    </div>
                    <div className="d-flex flex-1 of-y-auto of-x-hidden w-100">
                      <EntitySummary className="shadow w-100">
                        <div className="flex-1 of-y-auto d-flex ar-entity-summary-container flex-column">
                          <div className="flex-1 of-y-auto">
                            <ItemEliminatedComponent schema={schema} />
                          </div>
                        </div>
                      </EntitySummary>
                    </div>
                  </SidebarContainer>
                  {/* </MessagesConnection> */}
                </LinkClosureContext.Provider>
              </EntitySummaryConfigContext.Provider>
            </DetailsEntityContext.Provider>
          </EntitySummaryParentIdProviderForMessagesContext.Provider>
        </EntitySummaryParentSchemaProviderForMessagesContext.Provider>
      </DetailsEntityIdContext.Provider>
    );
  }

  return (
    <DetailsEntityIdContext.Provider value={id}>
      <EntitySummaryParentSchemaProviderForMessagesContext.Provider
        value={schema}
      >
        <EntitySummaryParentIdProviderForMessagesContext.Provider value={id}>
          <DetailsEntityContext.Provider value={schema}>
            <EntitySummaryConfigContext.Provider value={config}>
              <LinkClosureContext.Provider value={() => closeSidebar(true)}>
                <SidebarContainer
                  fullWidth={
                    (CanEdit || IsFollowing || (!CanEdit && CanViewChat)) &&
                    !disableMessages
                  }
                  key={key}
                >
                  <div className="ar-entity-summary-header d-flex align-items-center pl-3 justify-content-between">
                    <div className="d-flex align-items-center">
                      {sidebarView === SidebarVisualTypeEnum.fullscreen && (
                        <SidebarBasicCloseButton className="pl-3 h-100 d-flex align-items-center" />
                      )}
                      {!disableOriginHeader && (
                        <EntitySummaryBreadcrumbs
                          width={breadcrumbsWidth}
                          schema={schema}
                          id={id}
                        />
                      )}
                    </div>
                    <div className="d-flex pl-3 align-items-center">
                      {(CanEdit || IsFollowing) && (
                        <StaticAccountsInRoomStack
                          disableMessages={disableMessages}
                          width={AccountsInRoomWidth}
                          accountsInRoom={AccountsInRoom}
                        />
                      )}

                      <SidebarViewSelector />
                    </div>
                  </div>
                  <div className="d-flex flex-1 of-y-auto of-x-hidden">
                    {loading || error || animating ? (
                      <div
                        className="h-100 bg-white d-flex align-items-center justify-content-center ar-entity-summary-container"
                        style={{
                          width:
                            sidebarView === SidebarVisualTypeEnum.fullscreen
                              ? "100%"
                              : 520,
                          zIndex: 1
                        }}
                      >
                        {!animating && (
                          <LoaderSpinner size="sm" className="text-primary" />
                        )}
                      </div>
                    ) : (
                      <EntitySummaryOverviewContent
                        schema={schema}
                        value={resolvedValue}
                        {...rest}
                      />
                    )}
                    {(CanEdit || IsFollowing || (!CanEdit && CanViewChat)) &&
                      !disableMessages && (
                        <MessagesConnection
                          roomType={roomType}
                          entityId={id}
                          schema={schema}
                          // key={messagesKey}
                          disableMessages={disableMessages}
                        >
                          <EntitySummaryMessages
                            width={
                              sidebarView === SidebarVisualTypeEnum.fullscreen
                                ? undefined
                                : MessagesWidth
                            }
                            roomType={roomType}
                            // roomType={roomType}
                            // loading={loading || Boolean(error)}
                            entityId={id}
                            type={type}
                            disableMessages={disableMessages}
                            schema={schema}
                            newMessages={NewCommentsCount}
                            // messages={Comments}
                            show={isShowingMessages}
                            onToggle={() =>
                              setIsShowingMessages((showing) => !showing)
                            }
                          />
                        </MessagesConnection>
                      )}
                    {!CanEdit &&
                      !IsFollowing &&
                      disableMessages === undefined && (
                        // <div
                        //   className="px-2 pt-4 d-flex justify-content-center flex-1 ar-entity-summary-messages"
                        //   style={{ width: 60 }}
                        // >
                        //   <div
                        //     // key={1}
                        //     className="ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button position-relative"
                        //     // onClick={onToggle}
                        //   >
                        //     <FontAwesomeIcon icon={faLock} />
                        //     {/* <AbsoluteUnreadMessages /> */}
                        //   </div>
                        // </div>
                        <></>
                      )}
                  </div>
                </SidebarContainer>
                {/* </MessagesConnection> */}
              </LinkClosureContext.Provider>
            </EntitySummaryConfigContext.Provider>
          </DetailsEntityContext.Provider>
        </EntitySummaryParentIdProviderForMessagesContext.Provider>
      </EntitySummaryParentSchemaProviderForMessagesContext.Provider>
    </DetailsEntityIdContext.Provider>
  );
};

export const EntitySummaryOverview = (props) => {
  const { id, forceOverview, noSidebar } = props;

  return (
    <ClientMemoryProvider>
      {noSidebar ? (
        <DefaultEntityNoSidebarSummaryOverview {...props} />
      ) : forceOverview ? (
        <EntitySummaryOverviewContent {...props} />
      ) : id ? (
        <DefaultEntitySummaryOverview {...props} />
      ) : (
        <CreationEntitySummaryOverview {...props} />
      )}
    </ClientMemoryProvider>
  );

  // if (forceOverview) return <EntitySummaryOverviewContent {...props} />;

  // if (id) return <DefaultEntitySummaryOverview {...props} />;

  // return <CreationEntitySummaryOverview {...props} />;
};
