/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useMemo,
  useLayoutEffect,
  useCallback,
  Component,
  useContext
} from "react";
import querystring from "query-string";
import ReactDOM from "react-dom";
import { subscribeUniqueEvent } from "../../Helpers/ComponentsHelper";
import "./KanbanChooser.css";
import Modal from "../Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { ActionButtonItem } from "../ActionsButton/ActionsButton";
import Button from "../Button/Button";
import classnames from "classnames";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

// import { NONAME } from "dns";
import { CardChooserListnerDict } from "./CardGridChooserHelper";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import {
	ServerAwareUpdateTempViewsContext,
  ServerAwareUpdateViewsContext,
  ServerAwareViewsContext
} from "../CGrid/ServerAwareViews";
import { useSpace } from "../../Contexts/SpaceContext";
import { ServerAwareFavoritesContext } from "../../Containers/AppBar/ServerAwareFavorite";
import { AppBarFavoriteTreeSetter } from "../../Containers/AppBar/AppBarFavoriteQuery";
import { AppFavoriteContext } from "../../Containers/AppBar/AppFavoriteProvider";
import { useFavoriteOrViewChecker } from "../../Containers/AppBar/AppBarHelper";

const getState = (columns, schemaName, orderList) => {
  //local storage
	
  const orderListDict = {};

  let orderListExists = Array.isArray(orderList) ? true : false;
  if (orderListExists)
    for (const ol of orderList) {
      orderListDict[ol] = true;
    }

  const state = {};

  for (const c of columns) {
    // if (!orderListExists && c.notDefault) state[c.title] = false;
    // else
    if (!orderListExists) state[c.title] = false;
    else state[c.title] = orderListDict[c.title] || undefined;
  }

  return state;
};

const getOrderedColumns = (Columns, schemaName, orderList) => {
  const titleArray = Columns.map((e) => e.title);
	
  if (orderList) {
    return orderList.reduce((arr, e) => {
      if (titleArray.includes(e)) arr.push(e);
      return arr;
    }, []);
  } else {
    return [];
  }
};

const getfieldTitleDict = (Columns) => {
	
  const result = {};
  for (const c of Columns) {
    result[c.title] = c;
  }
  return result;
};

export const CardGridChooser = ({ ...rest }) => {
	const result = useFavoriteOrViewChecker()

  if (result) return <CardChooserFavorites {...rest} />;
  return <CardChooserViews {...rest} />;
};

const CardChooserViews = ({ ...rest }) => {
  const { onClose, Columns, schema, schemaView } = rest;
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const schemaViews = useContext(ServerAwareViewsContext);
  const lsIndex = schemaViews.findIndex((e) => e.id === viewPref);
  const lsViews = lsIndex === -1 ? schemaViews[0] : schemaViews[lsIndex];
  const space = useSpace();
  const schemaName = schemaView
    ? schemaView.name
    : schema
    ? schema.name
    : rest.Name;

    const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);
  const handleChange = (result) => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );
    const viewGridViews = gridSavedViews[schemaName];
    const index = viewGridViews.findIndex((e) => e.id === viewPref);
    let resolvedIndex = index === -1 ? 0 : index;
    let viewGridObject = viewGridViews[resolvedIndex] || {};
    viewGridObject.cardLayout = result;
    viewGridViews[resolvedIndex] = viewGridObject;
    gridSavedViews[schemaName] = viewGridViews;
    localStorage.setItem(
      `ar-grid-saved-views-space-${space.Id}`,
      JSON.stringify(gridSavedViews)
    );
    updateLsViews && updateLsViews();

    onClose();
  };

  const handleReset = () => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );
    const viewGridViews = gridSavedViews[schemaName];
    const index = viewGridViews.findIndex((e) => e.id === viewPref);
    let resolvedIndex = index === -1 ? 0 : index;
    let viewGridObject = viewGridViews[resolvedIndex] || {};
    viewGridObject.cardLayout = undefined;
    viewGridViews[resolvedIndex] = viewGridObject;
    gridSavedViews[schemaName] = viewGridViews;
    localStorage.setItem(
      `ar-grid-saved-views-space-${space.Id}`,
      JSON.stringify(gridSavedViews)
    );
    updateLsViews && updateLsViews();

    onClose();
  };

  return (
    <CardGridChooserComp
      {...rest}
      handleChange={handleChange}
      cardLayout={lsViews.cardLayout}
      handleReset={handleReset}
    />
  );
};

const useFavoriteSelectedColumn = (onClose) => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const { cardLayout } = FavoriteItem.data;
  const setTree = useContext(AppBarFavoriteTreeSetter);
  const handleColumnChange = useCallback(
    (result) => {
      setTree((oldTree) => {
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items };
        const newItem = { ...newItems[FavoriteItem.id] };
        const newData = { ...newItem.data };
        newData.cardLayout = [...result];
        newItem.data = newData;
        newItems[FavoriteItem.id] = newItem;
        return {
          rootId,
          items: newItems
        };
      });
      onClose();
    },
    [FavoriteItem.id, onClose, setTree]
  );

  const handleColumnReset = useCallback(
    (result) => {
      setTree((oldTree) => {
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items };
        const newItem = { ...newItems[FavoriteItem.id] };
        const newData = { ...newItem.data };
        newData.cardLayout = undefined;
        newItem.data = newData;
        newItems[FavoriteItem.id] = newItem;
        return {
          rootId,
          items: newItems
        };
      });
      onClose();
    },
    [FavoriteItem, onClose, setTree]
  );

  return {
    handleColumnChange,
    handleColumnReset,
    cardLayout
  };
};

const CardChooserFavorites = ({ ...rest }) => {
  const { onClose } = rest;
  const { handleColumnChange, handleColumnReset, cardLayout } =
    useFavoriteSelectedColumn(onClose);

  return (
    <CardGridChooserComp
      {...rest}
      handleChange={handleColumnChange}
      cardLayout={cardLayout}
      handleReset={handleColumnReset}
    />
  );
};

const CardGridChooserComp = ({
  schema,
  onClose,
  setIsOpen,
  isOpen,
  schemaView,
  Columns,
  cardLayout,
  handleChange,
  handleReset,
  ...rest
}) => {
  const fieldDict = getfieldTitleDict(Columns);

  useEffect(() => {
    return subscribeUniqueEvent((e) => {
      if (e.keyCode === 27) {
        e.preventDefault();
        setIsOpen(false);
      }
    });
  }, [isOpen, setIsOpen]);

  const schemaName = schemaView
    ? schemaView.name
    : schema
    ? schema.name
    : rest.Name;

  const [searchBarValue, setSearchBarValue] = useState("");
  const [selectionState, setSelectionState] = useState(() => {
    return getState(Columns, schemaName, cardLayout);
  });
  const [orderedColumns, setOrderedColumns] = useState(() => {
    return getOrderedColumns(Columns, schemaName, cardLayout);
  });

  const resolvedColumns = useMemo(() => {
    if (!searchBarValue) return Columns;
    //;
    const newColumns = Columns.filter(
      (v) =>
        v.title && v.title.toLowerCase().includes(searchBarValue.toLowerCase())
    );

    return newColumns;
  }, [Columns, searchBarValue]);

  const handleSelectionChange = useCallback((field, isChecked) => {
    if (!isChecked) {
      setOrderedColumns((c) => {
        const newColumns = [...c];
        const index = newColumns.indexOf(field);
        newColumns.splice(index, 1);
        return newColumns;
      });
    } else {
      setOrderedColumns((c) => {
        const newColumns = [...c];
        newColumns.unshift(field);
        return newColumns;
      });
    }
    setSelectionState((s) => {
      const newState = { ...s };
      newState[field] = isChecked;
      return newState;
    });
  }, []);

  const submit = () => {
    handleChange(orderedColumns);
  };

  const reset = () => {
    handleReset();
  };

  return (
    <div className="ColumnChooser-ModalWrap">
      <div className="p-30">
        <div className="fs-16 mb-10 fw-bold text-black d-flex justify-content-between">
          <span className="pt-1">
            <FormattedMessage id={"CHOOSE_COLUMNS"} />
          </span>
        </div>
        <div className="d-flex mb-4">
          <div className="ColumnChooserContent ColumnChooserContent-borderRight d-flex flex-column justify-content-between of-hidden pr-4">
            <ColumnChooserSearchBar
              value={searchBarValue}
              handleChange={setSearchBarValue}
              className="w-a mb-3"
            />
            <div className="ColumnChooserCheckList d-flex flex-1 flex-column of-hidden">
              <ColumnChooserCheckList
                filterBy={searchBarValue}
                onChange={handleSelectionChange}
                Columns={resolvedColumns}
                selectionState={selectionState}
              />
            </div>
          </div>
          <div className="ColumnChooserContent justify-content-between pl-4">
            <ColumnChooserDragOrder
              selectedColumns={orderedColumns}
              dict={fieldDict}
              onChange={setOrderedColumns}
              removeColumn={handleSelectionChange}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <Button onClick={reset} vType="link-primary">
              <FormattedMessage id={"RESTORE_COLUMNS"} />
            </Button>
          </div>
          <div>
            <Button
              className="mr-3"
              onClick={() => {
                setIsOpen(false);
              }}
              vType="link-danger"
            >
              <FormattedMessage id={"CANCEL"} />
            </Button>
            <Button onClick={submit} vType="primary">
              <FormattedMessage id={"SAVE"} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColumnChooserSearchBar = ({
  handleChange,
  className,
  value,
  focused,
  ...rest
}) => {
  const intl = useIntl();
  const { inputRef, handleFocus, handleBlur } = rest;
  const placeholder = intl.formatMessage({ id: "SEARCH_MODULES" });

  const onChange = (e) => {
    handleChange(e.target.value);
  };

  return (
    <div
      className={classnames(
        "ssi-control rounded column-chooser-search-bar text-black ar-server-aware-input d-flex align-items-center p-2 mb-2",
        className,
        {
          selected: focused
        }
      )}
    >
      <div className="ar-server-aware-input-icon mr-2">
        {/* {isLoading ? (
          <LoaderSpinner className="text-primary" />
        ) : (
          <FontAwesomeIcon icon={faSearch} className="text-primary" />
        )} */}
        <FontAwesomeIcon icon={faSearch} className="text-primary" />
      </div>
      <Input
        ref={inputRef}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={() => {
          if (handleBlur) handleBlur();
        }}
        className="flat-input p-0"
        {...rest}
      />
      {value.length > 0 && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleChange("");
          }}
        >
          {" "}
          <FontAwesomeIcon icon={faTimes} className="text-warning" />
        </div>
      )}
    </div>
  );
};

const ColumnChooserCheckList = ({ Columns, selectionState, onChange }) => {
  let columnsList = "";
  let filteredList = null;
  const intl = useIntl();
  if (Columns) {
    columnsList = Columns.map((e, index) => {
      const isChecked = selectionState[e.title] || false;
      return (
        <div
          key={e.title + "" + index + "" + e.title}
          className="d-flex justify-content-start text-black mb-2"
        >
          <Checkbox
            disabled={e.required && isChecked}
            change={() => {
              onChange(e.title, !isChecked);
            }}
            checked={isChecked}
            textValue={
              typeof e.title === "function"
                ? e.title()
                : intl.formatMessage({ id: e.title })
            }
          />
          {/* <span
            style={{ paddingTop: 2 }}
            onClick={() => {
              !e.required && onChange(e.title, !isChecked);
            }}
            className={`disableLabelSelect text-black fs-14 ml-2 ${
              e.required ? "Checkbox-box-disabled" : ""
            }`}
          >
            {e.title instanceof Function
              ? e.title().toUpperCase()
              : e.title.toUpperCase()}
          </span> */}
        </div>
      );
    });
  }

  return (
    <>
      <div className="fs-14 mb-3 fw-medium text-black">
        <FormattedMessage id={"NAME"} />
      </div>
      <div className="flex-1 h-100 ml-2 of-y-auto">{columnsList}</div>
    </>
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ColumnChooserDragOrder = ({
  selectedColumns,
  dict,
  onChange,
  removeColumn
}) => {
  //   const onDragEnd = useCallback(r => {
  //     const { destination, source } = r;
  //     if (!destination) return;
  //     const { droppableId: destId, index: destIndex } = destination;
  //     const { droppableId: sourceId, index: sourceIndex } = source;
  //   }, []);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrderedColumns = reorder(
      selectedColumns,
      result.source.index,
      result.destination.index
    );

    if (onChange) {
      onChange(newOrderedColumns);
    }
  };

  const DragableContent = selectedColumns.map((column, index) => {
    let resolvedTitle = "botoes";
    if (typeof dict[column].title === "object") {
      resolvedTitle = dict[column].title.props.id;
    } else if (typeof dict[column].title === "string") {
      resolvedTitle = dict[column].title;
    }
    return (
      <Draggable
        isDragDisabled={dict[column].unOrderable}
        draggableId={resolvedTitle}
        index={index}
        key={resolvedTitle}
      >
        {(draggableProvided, draggableSnapshot) => (
          <PortalAwareItem
            isDragDisabled={dict[column].unOrderable}
            required={dict[column].required}
            removeColumn={removeColumn}
            column={dict[column]}
            provided={draggableProvided}
            snapshot={draggableSnapshot}
          />
        )}
      </Draggable>
    );
  });

  return (
    <div className="mt-2">
      <div
        style={{ textTransform: "uppercase" }}
        className="fs-14 mb-3 fw-medium text-black"
      >
        <FormattedMessage id={"SELECTED_MODULES"} /> ({selectedColumns.length})
      </div>
      <div className="ColumnChooserOrderList">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {DragableContent}

                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

const PortalAwareItem = ({
  provided,
  snapshot,
  column,
  removeColumn,
  isDragDisabled,
  required
}) => {
  const intl = useIntl();
  const providedd = provided;
  const snapshott = snapshot;
  const droppedOutside =
    snapshott.isDropAnimating &&
    snapshott.draggingOver !== "droppable" &&
    (snapshot.dropAnimation.moveTo.y < -100 ||
      snapshot.dropAnimation.moveTo.y > 100);

  const usePortal = snapshott.isDragging;

  const style = {
    ...providedd.draggableProps.style,
    zIndex: 99999999,
    outline: "none",
    visibility: droppedOutside ? "hidden" : "initial"
  };

  const child = (
    <div
      ref={providedd.innerRef}
      {...providedd.draggableProps}
      {...providedd.dragHandleProps}
      style={style}
      className={`py-1 text-black ${usePortal && "dragging"}`}
    >
      <div className="ColumnChooserDragableContainer">
        <div className="d-flex align-items-center">
          <div className="GripVerticalIcon">
            {!isDragDisabled && (
              <FontAwesomeIcon
                className="PointerEventsRemove"
                icon={faGripVertical}
              />
            )}
          </div>
          <div>
            {typeof column.title === "function"
              ? column.title()
              : intl.formatMessage({ id: column.title })}
          </div>
        </div>
        {!required && (
          <span
            onClick={() => removeColumn(column.title, false)}
            style={{ float: "right", cursor: "pointer", color: "red" }}
            className="mr-2"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </span>
        )}
      </div>
    </div>
  );

  if (!usePortal) {
    return child;
  }

  // if dragging - put the item in a portal
  return ReactDOM.createPortal(child, document.body);
};
