import React, { Component } from "react";
import "./FormErrorAlert.css";
import { DangerAlert } from "../../Toast/ToastComponents";

class FormErrorAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderError() {
    const { errors, children } = this.props;

    if (errors)
      return errors.map(
        (error, index) => error.isShowing && <div key={index}>{error.text}</div>
      );
    else return children;
  }

  render() {
    const { className } = this.props;
    return (
      <DangerAlert className={className}>{this.renderError()}</DangerAlert>
    );
  }
}

export default FormErrorAlert;
