import React from "react";
import {
  EntitySummaryTabstrip,
  EntitySummaryTab,
  EntitySummaryOverview,
  EntitySummaryAccordion,
  DescriptionOverview,
  EntitySummaryFilesAccordion,
  EntityPipelineHistoryAccordion
} from "../../Components/EntitySummaries/EntitySummaries";
import { spaceSubscriptionSchema } from "../../config/schema";
import SubscriptionForm from "./SubscriptionForm";
import { SubscriptionActionsButton } from "./SubscriptionsHelper";
import {
  useSubscriptionType,
  usePipelineStatus,
  useCancelationReason
} from "../../Hooks/EntityHooks";
import {
  faCalendarAlt,
  faClipboard,
  faRepeatAlt,
  faHandshake,
  faFileAlt,
  faBuilding,
  faUserTie,
  faUsersClass,
  faUsers as faUsersLight,
  faSuitcase,
  faUserCog,
  faBell,
  faDrawCircle
} from "@fortawesome/pro-light-svg-icons";
import {
  EntityOverviewItem,
  EntityDescriptionOverviewItem,
  EntityOverviewRoundedItem,
  EntityOverviewPlaceholder
} from "../../Components/EntityOverview/EntityOverview";
import { GridColloredEndDateComponent } from "../../Helpers/GridHelper";
import RelativeTime from "../../Components/RelativeTime/RelativeTime";
import { CovenantPeriodicity } from "../../Components/CovenantSelector/CovenantSelector";
import { ConnectedOrganizationProfile } from "../../Components/Organizations/OrganizationProfile";
import {
  ConnectedDepartmentProfileStack,
  ConnectedAccountsTeamsDepartmentsProfileStack,
  ConnectedAccountProfileStack
} from "../../Components/Accounts/AccountProfileStack";
import {
  faUsers,
  faAnalytics,
  faInfoSquare,
  faCity
} from "@fortawesome/pro-solid-svg-icons";
import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
import { EntityOverviewPipeline } from "../../Components/EntityOverview/EntityOverviewPipeline";
import { SubscriptionDetailsChildren } from "./SubscriptionDetails/SubscriptionDetailsChildren";
import { ConnectedContactPopup } from "../Origin/ContactPopup";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import { RealTimeRoomTypes } from "../RealTime/RealTimeHelper";
import { FormattedMessage } from "react-intl";
import {
  buildContractStatusType,
  ContractStatusProfile
} from "../../Components/Contract/ContractProfile";
import { SubscriptionStatusTypes } from "../../Components/Subscription/SubscriptionProfile";
import { History } from "../../Components/DetailsView/History/History";

export const SubscriptionSummaryHeader = ({ value }) => {
  const { NoTagDescription } = value;

  return NoTagDescription;
};

export const SubscriptionSummaryContent = ({ value }) => {
  const {
    Pipeline,
    PipelineStatus,
    StatusDetail,
    Manager,
    SalesOpportunity,
    BeginDate,
    Company,
    Contact,
    Id,
    // ReceivedDate,
    NoTagDescription,
    Description,
    InternalDescription,
    NoTagInternalDescription,
    Files,
    CanEdit,
    IsFollowing,
    Departments,
    SubscriptionType: subscriptionTypeId,
    Collaborators,
    Covenant,
    Client,
    StatusDetailDescription,
    IndirectClient,
    Followers
  } = value;

  const { Accounts, Teams } = Collaborators;

  const subscriptionType = useSubscriptionType(subscriptionTypeId);

  // const origin = useMemo(() => getInterventionTarget(value), [value]);

  const pipelineStatusObj = usePipelineStatus(PipelineStatus);
  const detail = useCancelationReason(StatusDetail);

  return (
    <>
      <EntitySummaryAccordion icon={faAnalytics} textId="STATUS">
        <EntityOverviewPipeline
          // StatusProfileComponent={DealStatusProfile}
          buildFunction={buildContractStatusType}
          pipeline={Pipeline}
          pipelineStatus={PipelineStatus}
          StatusProfileComponent={ContractStatusProfile}
        />

        {StatusDetail &&
          pipelineStatusObj.Status === SubscriptionStatusTypes.canceled && (
            <EntityOverviewRoundedItem
              icon={faDrawCircle}
              titleId="CANCELATION_REASON"
            >
              {detail.Name}
            </EntityOverviewRoundedItem>
          )}
        {StatusDetailDescription &&
          pipelineStatusObj.Status === SubscriptionStatusTypes.canceled && (
            <EntityDescriptionOverviewItem
              icon={faDrawCircle}
              titleId="CANCELATION_DESCRIPTION"
            >
              <DescriptionOverview
                Description={StatusDetailDescription}
                NoTagDescription={StatusDetailDescription}
              />
            </EntityDescriptionOverviewItem>
          )}

        <EntityOverviewItem icon={faCalendarAlt} titleId="CONCLUSION">
          <GridColloredEndDateComponent Data={value} />
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faInfoSquare} textId={"DETAILS"}>
        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="START">
          <RelativeTime date={BeginDate} />
        </EntityOverviewRoundedItem>

        <EntityOverviewRoundedItem icon={faClipboard} titleId="TYPE">
          {subscriptionType
            ? `${subscriptionType.Type} - ${subscriptionType.Name}`
            : "-"}
        </EntityOverviewRoundedItem>

        <EntityOverviewRoundedItem icon={faRepeatAlt} titleId="RENEWAL">
          <CovenantPeriodicity value={Covenant} preview />
        </EntityOverviewRoundedItem>

        {/* <EntityOverviewItem icon={faLink} titleId="ASSIGNED_TO">
          <OriginProfile dynamicFont origin={origin} />
        </EntityOverviewItem> */}

        <EntityOverviewRoundedItem
          icon={faHandshake}
          titleId="NR_SALE_OPPORTUNITY"
        >
          {SalesOpportunity ? SalesOpportunity : "-"}
        </EntityOverviewRoundedItem>

        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="DESCRIPTION">
          <DescriptionOverview
            Description={Description}
            NoTagDescription={NoTagDescription}
          />
        </EntityDescriptionOverviewItem>

        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="INTERNAL_NOTE">
          <DescriptionOverview
            Description={InternalDescription}
            NoTagDescription={NoTagInternalDescription}
          />
        </EntityDescriptionOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faCity} textId="ASSOCIATED">
        <EntityOverviewItem icon={faBuilding} titleId="COMPANY">
          <ConnectedOrganizationProfile dynamicFont organization={Company} />
        </EntityOverviewItem>
        {/* <EntityOverviewItem icon={faSuitcase} titleId="ACCOUNT">
          <ConnectedClientProfile client={Client} />
        </EntityOverviewItem> */}
        <EntityOverviewItem icon={faSuitcase} titleId="ACCOUNT">
          <ConnectedClientOrigin
            placement="right"
            dynamicFont
            client={Client || IndirectClient}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUserTie} titleId="CONTACT">
          <ConnectedContactPopup dynamicFont contact={Contact} />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUsersClass} titleId="DEPARTMENTS">
          <ConnectedDepartmentProfileStack departments={Departments} />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUsersLight} titleId="TEAMS_CONTRIBUTORS">
          <ConnectedAccountsTeamsDepartmentsProfileStack
            accounts={Accounts}
            teams={Teams}
          />
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faUsers} textId="ASSIGNED_TO">
        {/* <EntityOverviewItem icon={faUser} titleId="CREATED_BY">
          <ConnectedAccountProfile account={Creator} />
        </EntityOverviewItem> */}
        <EntityOverviewItem icon={faUserCog} titleId="MANAGER">
          <ConnectedAccountPopup
            placement="right"
            dynamicFont
            account={Manager}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faBell} titleId="FOLLOWERS">
          {Followers && Followers.length > 0 ? (
            <ConnectedAccountProfileStack dynamicFont accounts={Followers} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryFilesAccordion
        CanEdit={CanEdit}
        IsFollowing={IsFollowing}
        Files={Files}
      />
      {/* <EntityPipelineHistoryAccordion
        endpoint={`Subscriptions/${Id}/PipelineHistory`}
        id={Id}
        schema={spaceSubscriptionSchema}
      />
      <div className="mb-3"></div> */}
      <EntityPipelineHistoryAccordion
        endpoint={`history/subscription/${Id}/pipeline`}
        id={Id}
        schema={spaceSubscriptionSchema}
      />
    </>
  );
};

export const InnerSubscriptionSummary = ({ value }) => {
  return (
    <EntitySummaryTabstrip className="mt-2 mb-3">
      <EntitySummaryTab titleId="SUMMARY">
        <SubscriptionSummaryContent value={value} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="HISTORY">
        <History subscription={value.Id} disableTabs />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ASSOCIATIONS">
        <SubscriptionDetailsChildren />
      </EntitySummaryTab>
    </EntitySummaryTabstrip>
  );
};

const SubscriptionSummary = (props) => {
  return (
    <EntitySummaryOverview
      {...props}
      url={"subscriptions"}
      type={"subscription"}
      schema={spaceSubscriptionSchema}
      FormComponent={SubscriptionForm}
      Summary={InnerSubscriptionSummary}
      creationHeaderText={<FormattedMessage id="CREATE_SUBSCRIPTION" />}
      ActionButton={SubscriptionActionsButton}
      // noImage
      // TextComponent={SubscriptionSummaryHeader}
      roomType={RealTimeRoomTypes.Subscription}
      // disableMessages
    ></EntitySummaryOverview>
  );
};

export default SubscriptionSummary;
