import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { BasicPortal } from "../Portal/Portal";
import classes from "./ContextMenu.module.css";
import classnames from "classnames";

import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import LoadableButton from "../Button/LoadableButton";
import { EntityPopupContext } from "../EntityPopup/EntityPopupHelper";

export const MenuCloseContext = React.createContext<any>(undefined);

export const BasicContextItem = ({
  onChange,
  text,
  icon,
  disabled,
  ...passTroughProps
}:any) => {
  const closeMenu = useContext(MenuCloseContext) as any;

  const handleClick = () => {
    if (onChange) onChange();
    if (closeMenu) closeMenu();
  };

  return (
    <Button
      vType={"link"}
      onClick={handleClick}
      className={"AlignLeftLoadableButton order-radios-0 p-1"}
      disabled={disabled}
      {...passTroughProps}
    >
      <div className="d-flex align-items-center justify-content-start">
        <FontAwesomeIcon icon={icon} />
        <span>
          <FormattedMessage id={text} />
        </span>
      </div>
    </Button>
  );
};

export const ContextLoadableItem = ({
  onClick,
  children,
  isLoading,
  onChange,
  disabled,
  icon,
  text,
  ...passTroughProps
}:any) => {
  const closeMenu = useContext(MenuCloseContext) as any;

  const handleClick = () => {
    if (onChange) onChange();
    if (closeMenu) closeMenu();
  };

  return (
    <LoadableButton
      isLoading={isLoading}
      vType={"link"}
      onClick={handleClick}
      className={"AlignLeftLoadableButton order-radios-0 p-1"}
      disabled={disabled}
      {...passTroughProps}
    >
      <div className="d-flex align-items-center justify-content-start">
        <FontAwesomeIcon icon={icon} />
        <span>
          <FormattedMessage id={text} />
        </span>
      </div>
    </LoadableButton>
  );
};

export const GridActionContext = React.createContext<any>(null);

const ContextMenu = ({ targetRef, children, enableOnClick }:any) => {
  const [xPos, setXPos] = useState("0px");
  const [yPos, setYPos] = useState("0px");
  const [showMenu, setShowMenu] = useState(false);
  const popupRef = useRef<any>();

  const handleCloseMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  const handleClick = useCallback(
    (e: Event) => {
      if (!popupRef.current) return;
      if (
        popupRef.current === e.target ||
        popupRef.current.contains(e.target) ||
        (popupRef.current && popupRef.current.contains(e.target))
      )
        return;

      showMenu && setShowMenu(false);
    },
    [showMenu]
  );

  useEffect(() => {
    if (!showMenu) return;

    const handleContextMenu = (e:any) => {
      if (
        targetRef.current === e.target ||
        targetRef.current.contains(e.target) ||
        (targetRef.current && targetRef.current.contains(e.target))
      )
        return;
      setShowMenu(false);
    };

    document.addEventListener(
      "click",
      enableOnClick ? handleContextMenu : handleClick
    );
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener(
        "click",
        enableOnClick ? handleContextMenu : handleClick
      );
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [enableOnClick, handleClick, showMenu, targetRef]);

  useEffect(() => {
    if (!showMenu) return;

    const docHeight = document.body.offsetHeight;
    const popupRect = popupRef.current.getBoundingClientRect();
    let targetY = popupRect.top;
    const docWidth = document.body.offsetWidth;
    let targetX = popupRect.left;
    if (targetX + popupRect.width + 10 > docWidth) {
      setXPos(`${targetX - popupRect.width - 10}px`);
    }
		
    if (targetY + popupRect.height + 10 > docHeight) {
			const newY = targetY - popupRect.height - 10
			if(newY < 10)setYPos(`${10}px`);
      else setYPos(`${newY}px`);
    } else if (targetY < 0) {
      setYPos(`${docHeight - 10 - popupRect.height}`);
    }
  }, [showMenu, xPos, yPos]);

  useEffect(() => {
    const handleContextMenu = (e:any) => {
      // e.stopPropagation();
      e.preventDefault();

      let targetX = e.pageX;
      let targetY = e.pageY;

      setXPos(`${targetX}px`);
      setYPos(`${targetY}px`);
      setShowMenu(true);
    };
    const element = targetRef.current;
    element.addEventListener("contextmenu", handleContextMenu);

    enableOnClick && element.addEventListener("click", handleContextMenu);
    return () => {
      element.removeEventListener("contextmenu", handleContextMenu);
      enableOnClick && element.removeEventListener("click", handleContextMenu);
    };
  }, [enableOnClick, targetRef]);

  return showMenu ? (
    <MenuContext.Provider value={true}>
      <BasicPortal>
        <MenuCloseContext.Provider value={handleCloseMenu}>
          <EntityPopupContext.Provider value={handleCloseMenu}>
            <div
              ref={popupRef}
              className={classnames(
                "py-2 shadow bg-white position-absolute rounded",
                classes.contextMenu
              )}
              style={{
                top: yPos,
                left: xPos,
                zIndex: 999999
              }}
            >
              {children}
            </div>
          </EntityPopupContext.Provider>
        </MenuCloseContext.Provider>
      </BasicPortal>
    </MenuContext.Provider>
  ) : null;
};

export const MenuContext = React.createContext(false);

export const useMenuContext = () => useContext(MenuContext);

export default ContextMenu;
