import React, { useMemo } from "react";
import {
  useContact,
  useCall,
  usePipelineStatus,
  useCountry
} from "../../Hooks/EntityHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMap,
  faSuitcase,
  faUserCheck,
  faUsers,
  faUsersMedical,
  faUserTie
} from "@fortawesome/pro-solid-svg-icons";
import { spaceCallsSchema } from "../../config/schema";
import { ConnectedTeamProfile } from "../../Components/Teams/TeamProfile";
import { ConnectedDepartmentProfile } from "../../Components/Departments/DepartmentProfile";
import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";
import SplitCol from "../../Components/SplitCol/SplitCol";
import { ConnectCallEntity } from "../../Components/Calls/CallProfile";

import {
  buildProjectStatusType,
  ProjectStatusProfileWithIcon
} from "../../Components/Projects/ProjectProfile";

import {
  OriginItemGroup,
  OriginHeader,
  OriginDate,
  OriginContainer
} from "../../Components/Origin/Origin";
import { createOriginPopup } from "./Origin";
import { ConnectedClientOrigin } from "./ClientOrigin";
import { ConnectedAccountPopup } from "./AcountPopup";
import { ConnectedContactPopup } from "./ContactPopup";
import { CallEntityGridButtons, CallStatusTypes } from "../Calls/CallsHelper";
import classnames from "classnames";
import { CallIcon } from "../../Components/Common/EntityIcons";
import { useIntl } from "react-intl";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import Button from "../../Components/Button/Button";
import { PipelineStatusProfile } from "../AdvancedMultiInputs/PipelineDropdowns";
import { faPhone } from "@fortawesome/pro-solid-svg-icons";
import { useSpace } from "../../Contexts/SpaceContext";
import { useConnectedSchemaGetter } from "../../Helpers/HelperHooks";

export const CallOriginAnchor = React.forwardRef(
  (
    { call, callContact, dispatch, children, onClick, dynamicFont, ...rest },
    ref
  ) => {
    const contact = useContact(call && call.Contact) || callContact;
    const openForm = useEntitySummarySidebar();
    if (!call) return null;
    const { IsDeleted } = call;
    return (
      <div
        ref={ref}
        className={classnames("link-color text-primary text-truncate", {
          "item-was-deleted": IsDeleted
        })}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <>
            <div
              className={classnames(
                "d-flex align-items-center link-color text-truncate",
                {
                  "fs-12": !dynamicFont
                }
              )}
            >
              <div className="d-inline-flex align-items-center mr-1">
                <ProjectStatusProfileWithIcon
                  Icon={<FontAwesomeIcon icon={faPhone} />}
                  inline
                  hideName
                  status={call.Status || call.Attributes}
                />
              </div>
              <Button
                vType="link-primary"
                className="EntityLinkClassID fs-12 of-hidden text-black"
                onClick={() => {
                  openForm({
                    type: moduleTypes.calls,
                    props: {
                      id: call.Id
                    }
                  });
                }}
              >
                <div className="text-truncate w-100">
                  {contact
                    ? contact.Name
                    : call?.Name || call?.NoTagDescription}
                </div>
              </Button>
            </div>
            {/* {Id && <div className="fs-10">Nº: {Id}</div>} */}
          </>
        )}
      </div>
    );
  }
);
export const ConnectedCallOriginAnchor = ({ call, ...rest }) => {
  const resolvedCall = useCall(call);
  const Result = useConnectedSchemaGetter(call, spaceCallsSchema);

  if (Result) return Result;
  return <CallOriginAnchor call={resolvedCall} {...rest} />;
};

export const CallOriginPopup = ({ call }) => {
  const intl = useIntl();
  const c = useCall(call.Id);

  const {
    NoTagDescription,
    Id,
    Client,
    IndirectClient,
    Contact,
    ManagerAccount,
    Team,
    Department,
    CreationDate,
    PipelineStatus,
    ReceivedDate,
    Addresses,
    Creator
  } = c;

  const buildDepartmentTeam = () => {
    if (Team) return <ConnectedTeamProfile team={Team} />;
    else if (Department)
      return <ConnectedDepartmentProfile department={Department} />;
    else return null;
  };

  const DepartComeponent = buildDepartmentTeam();
  const connectedPipeline = usePipelineStatus(PipelineStatus);

  const finalAddress = useMemo(() => {
    if (Addresses && Addresses[0]) return Addresses[0];
    else return {};
  }, [Addresses]);

  const ConnectedCountry = useCountry(finalAddress?.Country);

  const addressString = `${finalAddress.Address1}${
    finalAddress?.Address2 ? `, ${finalAddress.Address2}` : ""
  }${finalAddress?.PostalCode ? `, ${finalAddress.PostalCode}` : ""}${
    finalAddress?.Locality ? `, ${finalAddress.Locality}` : ""
  }${ConnectedCountry ? `, ${ConnectedCountry.Name}` : ""}`;
  const space = useSpace();
  const linkString = `${finalAddress.Address1}${
    finalAddress?.Address2 ? `, ${finalAddress.Address2}` : ""
  }${finalAddress?.Locality ? `, ${finalAddress.Locality}` : ""}`
    .replace(/  +/g, " ")
    .replace(/,/g, "");

  return (
    <OriginContainer>
      <OriginHeader
        Dif
        className="mb-1"
        type="Chamada"
        entity="CALL"
        date={CreationDate}
        Icon={<CallIcon rotation={90} className="mr-2" />}
        EntityButtons={<CallEntityGridButtons noColors entity={c} minimal />}
        id={Id}
        link={`/s/${space.Id}/calls/${Id}`}
        title={NoTagDescription}
      />
      <div className="px-3 mb-2 d-flex align-items-center justify-content-between">
        <div
          className={`fs-12 text-${buildProjectStatusType(
            connectedPipeline.Status
          )}`}
        >
          <PipelineStatusProfile pipeline={connectedPipeline} />
        </div>
        <div>
          <OriginDate className="fs-12" date={ReceivedDate} />
        </div>
      </div>
      {/* <span className="origin_header"></span> */}
      {/* <FormattedMessage id={"CALL_NR"} /> {Id} */}
      <OriginItemGroup
        icon={faSuitcase}
        title={intl.formatMessage({ id: "ACCOUNT" })}
        className="mb-1"
      >
        <ConnectedClientOrigin client={Client || IndirectClient}>
          <ConnectedClientProfile showId client={Client || IndirectClient} />
        </ConnectedClientOrigin>
      </OriginItemGroup>

      {Addresses && finalAddress.Address1 && (
        <OriginItemGroup
          icon={faMap}
          title={intl.formatMessage({ id: "ADDRESS" })}
        >
          <a
            href={`https://www.google.com/maps/place/${linkString}`}
            rel="noopener noreferrer"
            target="_blank"
            className="fs-12"
          >
            {addressString}
          </a>
        </OriginItemGroup>
      )}
      {/* <SplitCol
        className="mb-2"
        left={ */}
      <SplitCol
        className="mb-1"
        left={
          <OriginItemGroup
            icon={faUserTie}
            title={intl.formatMessage({ id: "CONTACT" })}
            className="mb-1"
          >
            <ConnectedContactPopup contact={Contact} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUsersMedical}
            title={intl.formatMessage({ id: "CREATED_BY" })}
          >
            <ConnectedAccountPopup account={Creator} />
          </OriginItemGroup>
        }
      />

      {/* }
        // right={ */}
      {/* //   <OriginItemGroup title="Contato">
        //     <ConnectedContactProfile contact={Contact} />
        //   </OriginItemGroup>
        // }
	  /> */}
      {ManagerAccount && !DepartComeponent && (
        <OriginItemGroup
          icon={faUserCheck}
          className="mb-1"
          title={intl.formatMessage({ id: "ASSIGNED_TO" })}
        >
          <ConnectedAccountPopup account={ManagerAccount} />
        </OriginItemGroup>
      )}
      {!ManagerAccount && DepartComeponent && (
        <OriginItemGroup
          icon={faUsers}
          className="mb-1"
          title={intl.formatMessage({ id: "DEPARTMENT_TEAM" })}
        >
          {DepartComeponent}
        </OriginItemGroup>
      )}
      <SplitCol
        className="mb-1"
        show={ManagerAccount && DepartComeponent}
        left={
          <OriginItemGroup
            icon={faUserCheck}
            title={intl.formatMessage({ id: "ASSIGNED_TO" })}
          >
            <ConnectedAccountPopup account={ManagerAccount} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUsers}
            title={intl.formatMessage({ id: "DEPARTMENT_TEAM" })}
          >
            {DepartComeponent}
          </OriginItemGroup>
        }
      />
      <div className="pb-3"></div>
      {/* <div className="d-flex align-items-center justify-content-center shadow-top py-3 mt-3">
        <CallFormButton
          id={Id}
          canEdit={CanEdit}
          className="ssi-button-primary-ghost mx-1"
        />
        <TicketFormButton
          alt
          formProps={{ callId: Id }}
          className="ssi-button-primary-ghost mx-1"
        >
          Converter Ticket
        </TicketFormButton>
        <TaskFormButton
          className="ssi-button-primary-ghost mx-1"
          alt
          formProps={{ callId: Id }}
        >
          Agendar Tarefa
        </TaskFormButton>
        <InterventionFormButton
          className="mx-1"
          alt
          formProps={{ callId: Id }}
        />
        <CallEntityButtons
            className="mt-3 mb-3"

          entity={c}
        />
      </div> */}
    </OriginContainer>
  );
};

export const callOriginOptions = {
  valueProp: "call",
  schema: spaceCallsSchema
};

const CallOrigin = createOriginPopup(
  CallOriginAnchor,
  CallOriginPopup,
  callOriginOptions
);

export default CallOrigin;

export const ConnectedCallOrigin = ConnectCallEntity(CallOrigin);

export const DifrentCallProfile = ({ call }) => {
  if (!call) return null;
  const { Status } = call;

  let colorStatus;

  switch (Status) {
    case CallStatusTypes.open:
      colorStatus = "status-in-progress-colors";
      break;
    case CallStatusTypes.draft:
      colorStatus = "status-orange-colors";
      break;
    case CallStatusTypes.canceled:
      colorStatus = "status-canceled-colors";
      break;
    case CallStatusTypes.closed:
      colorStatus = "status-closed-colors";
      break;
    default:
      break;
  }

  return (
    <div className={classnames("air-new-profiles-circle", colorStatus)}>
      <FontAwesomeIcon icon={faPhone} size="xl" />
    </div>
  );
};
