import React from "react";
import { faFileContract } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSubscription } from "../../Hooks/EntityHooks";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { faCalendarExclamation } from "@fortawesome/pro-solid-svg-icons";

const buildSubscriptionStatusType = (status) => {
  switch (status.toString()) {
    case "2":
      return "black";
    case "3":
      return "gray";
    case "5":
      return "orange";
    default:
      return "purple";
  }
};

const statusNameByValue = (status) => {
  switch (status.toString()) {
    case "1":
      return <FormattedMessage id="OPEN" />;
    case "2":
      return <FormattedMessage id="CONCLUDED" />;
    case "3":
      return <FormattedMessage id="CANCELLED" />;
    case "5":
      return <FormattedMessage id="REVIEW" />;
    default:
      return null;
  }
};

export const SubscriptionStatusTypes = {
  open: 1,
  closed: 2,
  canceled: 3,
  review: 5
};

export const SubscriptionStatusProfileWithIcon = ({
  entity,
  status,
  item,
  Icon,
  value,
  hideName,
  inline,
  className,
  size = 24
}) => {
  const resovledStatus = entity ? entity.Status : status || item || value;

  if (!resovledStatus) return null;

  return (
    <div
      className={classnames(
        `link-color status-color-${buildSubscriptionStatusType(
          resovledStatus
        )}`,
        className,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div
        style={{ width: size, height: size }}
        className={`d-flex align-items-center justify-content-around position-relative text-truncate status-dot m-0 status-dot-background-template`}
      >
        {Icon}
      </div>
      {/* <div className="position-relative status-dot" /> */}
      {!hideName && (
        <div className="status-label fw-medium fs-12">
          {statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

export const SubscriptionStatusProfile = ({
  entity,
  status,
  item,
  value,
  hideName,
  inline,
  className
}) => {
  const resovledStatus = entity ? entity.Status : status || item || value;

  if (!resovledStatus) return null;

  return (
    <div
      className={classnames(
        `link-color status-color-${buildSubscriptionStatusType(
          resovledStatus
        )}`,
        className,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div className="position-relative status-dot" />
      {!hideName && (
        <div className="status-label fw-medium fs-12">
          {statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

const SubscriptionProfile = ({ Subscription }) => {
  if (!Subscription) return null;
  return (
    <div className="d-flex align-items-center link-color text-truncate">
      <div className="position-relative">
        <FontAwesomeIcon className="mr-1" icon={faFileContract} />
      </div>
      <div>{Subscription.Description}</div>
    </div>
  );
};

export default SubscriptionProfile;

export const ConnectSubscriptionEntity = (Component) => {
  return ({ Subscription, subscription, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const c = useSubscription(Subscription || subscription);
    // console.log(c)
    return <Component Subscription={c} subscription={subscription} {...rest} />;
  };
};

export const DifrentSubscriptionProfile = ({ subscription }) => {
  if (!subscription) return null;
  const { Status } = subscription;

  let colorStatus;

  switch (Status) {
    case SubscriptionStatusTypes.open:
      colorStatus = "status-in-progress-colors";
      break;
    case SubscriptionStatusTypes.closed:
      colorStatus = "status-closed-colors";
      break;
    case SubscriptionStatusTypes.canceled:
      colorStatus = "status-canceled-colors";
      break;
    case SubscriptionStatusTypes.review:
      colorStatus = "status-orange-colors";
      break;
    default:
      break;
  }

  return (
    <div className={classnames("air-new-profiles-circle", colorStatus)}>
      <FontAwesomeIcon icon={faCalendarExclamation} size="xl" />
    </div>
  );
};

export const ConnectedSubscriptionProfile =
  ConnectSubscriptionEntity(SubscriptionProfile);
