import React, { useContext, useState, useEffect } from "react";
import { useHistoryItem } from "../HistoryItem";
import Moment from "react-moment";
import { FormattedMessage, useIntl } from "react-intl";
import FormattedTimePreview from "../../../../FormattedTimePreview/FormattedTimePreview";
import { OriginValue } from "../../../../Origin/Origin";
import { HistoryOriginOverview } from "../../../../../Containers/Origin/OriginOverview";
import moment from "moment";
import { InterventionPlaceTypesProfile } from "../../../../Interventions/Interventions";
import InterventionTypeProfile from "../../../../InterventionTypes/InterventionTypeProfile";
import CountryProfile from "../../../../Country/CountryProfile";
import ContractTypeProfile from "../../../../ContractType/ContractTypeProfile";
import HistoryItemClientContractDetails from "./HistoryItemClientContractDetails";
import AccountProfile from "../../../../Accounts/AccountProfile";
import { DetailsAccordion } from "../../../DetailsView";
import ContactProfile from "../../../../Contacts/ContactProfile";
import ClientProfile from "../../../../Clients/ClientProfile";
import {
  Organizations,
  Accounts,
  Files,
  ContactMedium,
  Addresses,
  Contacts,
  Followers,
  Tags
} from "./HistoryItemProperties";
import { originTypes } from "../../../../../Helpers/MiscHelper";
import { PipelineStatusProfile } from "../../../../../Containers/AdvancedMultiInputs/PipelineDropdowns";
import Img from "../../../../Img/Img";
import CancellationReasonProfile from "../../../../CancellationReason/CancellationReason";
import { IneficientTypeComponent } from "../../../../../Containers/AdvancedMultiInputs";
import Checklist from "../../../../Checklist/Checklist";
import { getCovenantUnitsArray } from "../../../../CovenantSelector/CovenantSelector";
import { BaseHistoryUrlContext } from "../../HistoryHelper";
import { useSpaceQuery } from "../../../../../Helpers/IOClient";
import LoaderSpinner from "../../../../Loader/LoaderSpinner/LoaderSpinner";
import { TaskOriginAnchor } from "../../../../../Containers/Origin/TaskOrigin";

const EditItemHeader = ({ children, subTitle }) => {
  return (
    <div className="d-flex justify-content-between ar-history-edit-item-title py-1 fs-12 px-2 w-100">
      <div className="fw-medium text-black">{children}</div>
      {subTitle && <div>{subTitle}</div>}
    </div>
  );
};

const EditItemValue = ({ value, ValueComponent }) => {
  let comp;

  if (ValueComponent) comp = <ValueComponent value={value} entity={value} />;
  else if (typeof value === "object")
    comp = <pre>{JSON.stringify(value, null, 2)}</pre>;
  else comp = <span className="white-space-pre-line">{value}</span>;

  return <div className="d-flex align-items-center h-100">{comp}</div>;
};

function isUpperCase(str) {
  return typeof str === "string" && str === str?.toUpperCase();
}

export const EditItem = ({
  property,
  ValueComponent,
  NameValue,
  ValueExists,
  NameValueFinal,
  NameValueFinalSecond
}) => {
  const intl = useIntl();
  const { NewValue, OldValue, Name } = property;

  const hasNewValue = ValueExists
    ? ValueExists(NewValue)
    : NewValue !== undefined;
  const hasOldValue = ValueExists
    ? ValueExists(OldValue)
    : OldValue !== undefined;

  if (hasOldValue && hasNewValue) {
    return (
      <div className="ar-history-edit-item">
        <EditItemHeader subTitle={<FormattedMessage id={"CHANGES"} />}>
          {NameValueFinal && NameValueFinalSecond ? (
            <>
              <FormattedMessage id={NameValueFinalSecond} />
              {NameValueFinal}{" "}
            </>
          ) : NameValue ? (
            typeof NameValue === "string" && isUpperCase(NameValue) ? (
              <FormattedMessage id={NameValue} />
            ) : (
              NameValue
            )
          ) : typeof Name === "string" && isUpperCase(Name) ? (
            <FormattedMessage id={Name} />
          ) : (
            Name
          )}
        </EditItemHeader>
        <div className="d-flex fs-14 text-black ar-history-edit-item-content position-relative">
          <div className="negative-change flex-1">
            <EditItemValue value={OldValue} ValueComponent={ValueComponent} />
          </div>
          <div className="ar-history-item-seperator" />
          <div className="positive-change flex-1">
            <EditItemValue value={NewValue} ValueComponent={ValueComponent} />
          </div>
        </div>
      </div>
    );
  }

  if (hasNewValue) {
    return (
      <div className="ar-history-edit-item">
        <EditItemHeader subTitle={<FormattedMessage id={"ADITION"} />}>
          {NameValue ? (
            typeof NameValue === "string" && isUpperCase(NameValue) ? (
              <FormattedMessage id={NameValue} />
            ) : (
              NameValue
            )
          ) : typeof Name === "string" && isUpperCase(Name) ? (
            <FormattedMessage id={Name} />
          ) : (
            Name
          )}
        </EditItemHeader>
        <div className="d-flex fs-14 text-black ar-history-edit-item-content">
          <div className="positive-change flex-1">
            <EditItemValue value={NewValue} ValueComponent={ValueComponent} />
          </div>
        </div>
      </div>
    );
  }

  if (hasOldValue) {
    return (
      <div className="ar-history-edit-item">
        <EditItemHeader subTitle={<FormattedMessage id={"REMOVAL"} />}>
          {NameValue ? (
            typeof NameValue === "string" && isUpperCase(NameValue) ? (
              <FormattedMessage id={NameValue} />
            ) : (
              NameValue
            )
          ) : typeof Name === "string" && isUpperCase(Name) ? (
            <FormattedMessage id={Name} />
          ) : (
            Name
          )}
        </EditItemHeader>
        <div className="d-flex fs-14 text-black ar-history-edit-item-content">
          <div className="negative-change flex-1">
            <EditItemValue value={OldValue} ValueComponent={ValueComponent} />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const HistoryDate = ({ value }) => {
  return <Moment date={value} format="DD/MM/YYYY HH:mm" />;
};

const defaultProperyConfig = {
  Name: {
    NameId: "NAME"
  },
  Tags: Tags,
  Image: {
    NameId: "IMAGE",
    ValueComponent: ({ value }) => {
      return <Img circular size="md" src={value} />;
    },
    ValueExists: (value) => Boolean(value)
  },
  StatusDetail: {
    NameId: "CANCELATION_REASON",
    ValueComponent: ({ value }) => (
      <CancellationReasonProfile cancellationReason={value} />
    )
  },
  StatusDetailDescription: {
    NameId: "CANCELATION_DESCRIPTION",
    ValueExists: (value) => Boolean(value)
  },
  ReceivedDate: {
    NameId: "ENTRY",
    ValueComponent: HistoryDate
  },
  BeginDate: {
    NameId: "START_DATE",
    ValueComponent: HistoryDate
  },
  EndDate: {
    NameId: "END_DATE",
    ValueComponent: HistoryDate
  },
  Checklist: {
    ValueComponent: ({ value }) => (
      <Checklist
        value={value}
        canCheck={false}
        canEdit={false}
        preview={true}
        nodeWidth={350}
      />
    ),
    NameId: "CHECKLIST"
  },
  Locale: {
    ValueExists: (value) => Boolean(value),
    NameId: "LOCATION"
  },
  Place: {
    ValueExists: (value) => Boolean(value),
    NameId: "PLACE"
  },
  Description: {
    ValueExists: (value) => Boolean(value),
    NameId: "DESCRIPTION",
    ValueComponent: ({ value }) => (
      // <HTMLEditor value={value} preview />
      <div
        dangerouslySetInnerHTML={{
          __html: value
        }}
      />
    )
  },
  InternalDescription: {
    ValueExists: (value) => Boolean(value),
    NameId: "INTERNAL_NOTE",
    ValueComponent: ({ value }) => (
      // <HTMLEditor value={value} preview />
      <div
        dangerouslySetInnerHTML={{
          __html: value
        }}
      />
    )
  },
  NoTagDescription: {
    ValueExists: (value) => Boolean(value),
    NameId: "DESCRIPTION"
  },
  EstimatedCost: {
    NameId: "ESTIMATED_TIME",
    ValueComponent: ({ value }) => <FormattedTimePreview value={value} />
  },
  Duration: {
    NameId: "DURATION",
    ValueComponent: ({ value }) => (
      <FormattedTimePreview value={value} asHourMinute />
    )
  },
  PauseTime: {
    NameId: "TOTAL_PAUSES",
    ValueComponent: ({ value }) => {
      const minutes = moment.duration(value).asMinutes();
      return <span>{minutes}</span>;
    }
  },
  PlaceType: {
    NameId: "WHERE",
    ValueExists: (value) => Boolean(value),
    ValueComponent: InterventionPlaceTypesProfile
  },
  InterventionType: {
    NameId: "INTERVENTION_TYPE",
    ValueComponent: ({ value }) => (
      <InterventionTypeProfile interventionType={value} />
    )
  },
  ComplementaryTime: {
    NameId: "COMPLEMENTED_TIME",
    ValueComponent: ({ value }) => <FormattedTimePreview value={value} />
  },
  FiscalNumber: {
    NameId: "NIF"
  },
  PostalCode: {
    NameId: "POSTAL_CODE"
  },
  Address: {
    NameId: "ADDRESS"
  },
  Domain: {
    NameId: "DOMAIN"
  },
  Region: {
    NameId: "REGION_STATE_DISCTRICT"
  },
  Country: {
    NameId: "COUNTRY",
    ValueComponent: ({ value }) => <CountryProfile country={value} />
  },
  Client: {
    NameId: "ACCOUNT",
    ValueComponent: ({ value }) => <ClientProfile client={value} />
  },
  Contact: {
    NameId: "CONTACT",
    ValueComponent: ({ value }) => <ContactProfile contact={value} />
  },
  TimeValue: {
    NameId: "TimeValueMeasure",
    ValueComponent: ({ value }) => value
  },
  TimeUnitId: {
    NameId: "CURRENCY",
    ValueComponent: ({ value }) => {
      const dict = getCovenantUnitsArray();
      return <FormattedMessage id={dict[value].translationId} />;
    }
  },

  // Billing: {
  //   NameId: "billing",
  //   ValueComponent: ({ value }) => <BillingStatusProfile status={value} />
  // },
  Deslocations: {
    NameId: "TRAVEL"
    // ValueExists: value => Boolean(value)
  },
  SalesOpportunity: {
    NameId: "NR_SALE_OPPORTUNITY",
    ValueExists: (value) => Boolean(value)
  },
  ContractType: {
    NameId: "TYPE_CONTRACT",
    ValueComponent: ({ value }) => <ContractTypeProfile contracttype={value} />
  },
  Manager: {
    NameId: "MANAGER",
    ValueComponent: ({ value }) => <AccountProfile account={value} />
  },
  ProjectTasks: {
    NameId: "TASK",
    ValueComponent: ({ value }) => {
      if (Array.isArray(value) && value.length > 0) {
        return (
          <div>
            {value.map((e, i) => {
              e.Id = e.IdBySpace;
              return <TaskOriginAnchor key={i} task={e} />;
            })}
          </div>
        );
      }

      return null;
    }
  },
  InputtedDuration: {
    Name: "INPUTED_HOURS",
    ValueComponent: ({ value }) => (
      <FormattedTimePreview asHourMinute value={value} />
    )
  },
  AccountSpaceClientContractVersions: HistoryItemClientContractDetails,
  LastVersion: {
    ValueComponent: () => null
  },
  AccountSpaceProjectVersions: {
    ValueComponent: () => null
  },
  AccountSpaceProjectTaskVersions: {
    ValueComponent: () => null
  },
  NextVersions: {
    ValueComponent: () => null
  },
  Accounts: Accounts,
  Followers: Followers,
  Contacts: Contacts,
  Organizations: Organizations,
  Files: Files,
  ContactMedium: ContactMedium,
  PipelineStatus: {
    NameId: "PIPELINE_STATUS",
    ValueComponent: ({ value }) => <PipelineStatusProfile pipeline={value} />
    // ValueComponent: () => <div>hi</div>
  },
  Pipeline: {
    ValueComponent: ({ value }) => <span>{value.Name}</span>
  },
  Addresses,
  TimeDetailDescription: {
    NameId: "INEFFICIENT_TIME_REASONS"
  },
  InefficientDuration: {
    NameId: "INEFFICIENT_TIME"
  },
  TimeDetail: {
    NameId: "INEFFICIENT_TIME_TYPE",
    ValueComponent: ({ value }) => <IneficientTypeComponent type={value} />
  },
  ToDoVersions: {
    NameId: "CHECKLIST",
    ValueExists: (value) => value.length > 0,
    ValueComponent: ({ value }) => {
      const resolvedTodos = (ToDos) => {
        const data = ToDos;

        const returnData = (e) => {
          return {
            title: e.Name || e.title,
            Name: e.Name || e.title,
            children: [],
            ParentId: e.ParentId,
            Id: e.Id,
            Checked: e.Checked,
            expanded: true,
            ...e
          };
        };

        let initialData = data
          .filter((e) => !e.ParentId)
          .map((e) => returnData(e));

        const buildObject = (arr) => {
          if (!data.some((e) => e.ParentId)) return [];

          arr.forEach((item) => {
            item.children = data
              .filter((e) => e.ParentId === item.Id)
              .map((e) => returnData(e));

            buildObject(item.children);
          });
          return arr;
        };

        //   const getFlatList = (arr, current) => {
        // 	current.forEach(item => {
        // 	  arr.push({ name: item.title, Id: item.Id, parentId: item.parentId });
        // 	  if (item.children) {
        // 		getFlatList(arr, item.children);
        // 	  }
        // 	});
        // 	return arr;
        //   };

        buildObject(initialData);
        return initialData;
      };

      const resolvedValue = resolvedTodos(value);

      return (
        <Checklist
          className="flex-0-0-auto w-100"
          defaultMaxHeight={true}
          nodeWidth={290}
          disableTop={true}
          value={resolvedValue}
          canCheck={false}
          preview={true}
        />
      );
    }
  }
};

const getOriginPropertyName = (property) => {
  const { Name } = property;

  switch (Name) {
    case "Intervention":
      return originTypes.timecharge;

    case "Project":
      return originTypes.project;

    case "Client":
      return originTypes.client;

    case "Ticket":
      return originTypes.ticket;

    case "Call":
      return originTypes.call;

    case "Task":
      return originTypes.task;

    case "Contract":
      return originTypes.contract;

    case "Deal":
      return originTypes.deals;

    case "Subscription":
      return originTypes.subscription;

    default:
      return null;
  }
};

const MemorizedProperties = React.memo(({ resolvedProperties }) => {
  return <div className="ar-history-edit-items">{resolvedProperties}</div>;
});

export const createItemEditDetailsComponent = (propertyOverrides = {}) => {
  const resolvedPropertiesOverrides = {
    ...defaultProperyConfig,
    ...propertyOverrides
  };
  const useResolvedProperties = (properties) => {
    if (!properties) return null;
    const arr = [];

    let newOriginValue;
    let oldOriginValue;

    const addProperty = (property) => {
      const originType = getOriginPropertyName(property);

      if (originType) {
        const { NewValue, OldValue } = property;

        if (NewValue) newOriginValue = OriginValue(originType, NewValue);

        if (OldValue) oldOriginValue = OriginValue(originType, OldValue);

        return;
      }

      const { Name } = property;
      const override = resolvedPropertiesOverrides[Name];

      let Component;
      let NameValue;
      let ValueExists;
      if (override) {
        const {
          procedure,
          Name,
          NameId,
          ValueComponent,
          ValueExists: vE
        } = override;

        if (procedure) {
          procedure(arr, property);
          return;
        }

        if (NameId)
          NameValue =
            typeof NameId === "string" ? (
              <FormattedMessage id={NameId} />
            ) : (
              NameId
            );
        else if (Name) NameValue = Name;

        if (vE) ValueExists = vE;
        Component = ValueComponent;
      }

      arr.push(
        <EditItem
          property={property}
          NameValue={NameValue}
          key={property.Name}
          ValueComponent={Component}
          ValueExists={ValueExists}
        />
      );
    };

    for (const property of properties) {
      addProperty(property);
    }

    if (newOriginValue || oldOriginValue) {
      const originProperty = {
        NewValue: newOriginValue,
        OldValue: oldOriginValue
      };
      arr.push(
        <EditItem
          property={originProperty}
          NameValue={"ORIGIN"}
          key={"origin"}
          ValueComponent={HistoryOriginOverview}
        />
      );
    }

    return arr;
  };

  const DetailsProperties = ({ historyId }) => {
    const baseUrl = useContext(BaseHistoryUrlContext);

    const { data } = useSpaceQuery(`${baseUrl}/${historyId}`);

    const [show, setShow] = useState(() => Boolean(data));

    useEffect(() => {
      if (!show) {
        const timeout = setTimeout(() => {
          setShow(true);
        }, 450);

        return () => clearTimeout(timeout);
      }
    }, [show]);

    const resolvedProperties = useResolvedProperties(data && data.Properties);

    if (!data || !show)
      return (
        <LoaderSpinner size="xxs" className="text-primary" centerHorizontal />
      );

    return <MemorizedProperties resolvedProperties={resolvedProperties} />;
  };

  const HistoryItemEditDetails = () => {
    const { Id } = useHistoryItem();

    // const index = useContext(HistoryItemIndexContext);

    // const isOpenByDefault = index <= 10;

    return (
      <DetailsAccordion
        title={<FormattedMessage id={"SEE_CHANGES"} />}
        // isOpenByDefault={isOpenByDefault}
        // changesCount={resolvedProperties.length}
      >
        <DetailsProperties historyId={Id} />
      </DetailsAccordion>
    );
  };

  return HistoryItemEditDetails;
};
