import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const ContractOverviewComp = React.lazy(() =>
  import(/* webpackChunkName: "contracts-overview" */ "./ContractOverviewComp")
);

const ContractOverview = () => {
  return (
    <SuspenseErrorBoundary>
      <ContractOverviewComp />
    </SuspenseErrorBoundary>
  );
};

export default ContractOverview;
