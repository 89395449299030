import React, { useMemo } from "react";
import {
  defaultDealForm,
  validateDealForm,
  createDealEstimatedCostDict,
  dealCommunicator
} from "./DealsHelper";
import {
  spaceDealsSchema,
  spaceOrganizationSchema,
  spacePipelineSchema,
  spaceClientSchema,
  spaceEmailConversationSchema,
  spaceEmailSchema,
  spaceSubscriptionSchema,
  spaceCallsSchema
} from "../../config/schema";
import { createFormRequestHook, useFormState } from "../../Components/Forms";
import { useDeal } from "../../Hooks/EntityHooks";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useToast } from "../../Components/Toast/ToastProvider";
import { entityActionType, getTeamCollabValue } from "../../Helpers/MiscHelper";
import { useSpaceCurrenciesFetcher } from "../../Components/Currency/Currency";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../Components/Forms/SideMenuForm";
import { useIntl } from "react-intl";

// import SplitCol from "../../Components/SplitCol/SplitCol";

import { formatDealForm } from "../../Helpers/FormFormmaterHelper";
import { getEntity, useSpaceQuery } from "../../Helpers/IOClient";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";
// import { useMarkRead } from "../../Helpers/FormHelper";
import {
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import {
  isOrganzationRequestValid,
  OrganizationRequestSideError
} from "../../Components/Organizations/OrganizationHelper";
import { useHandleError } from "../../Components/CGrid/ServerAwareHelper";
import { convertDealEntityToForm } from "./DealsFormHelper";
import DealFormContent from "./DealFormContent";
import {
  FormTemplateProvider,
  useFormTemplateGetter
} from "../../Components/Forms/FormTemplatesHelper";

// const getBeginDate = () => {
//   const ReceivedDate = new Date();
//   ReceivedDate.setSeconds(0);
//   ReceivedDate.setMilliseconds(0);
//   return ReceivedDate;
// };

// const getEndDate = () => {
//   const ReceivedDate = new Date();
//   ReceivedDate.setSeconds(0);
//   ReceivedDate.setMilliseconds(0);
//   ReceivedDate.setHours(ReceivedDate.getHours() + 1);
//   return ReceivedDate;
// };

const useDealRequest = createFormRequestHook({
  schema: spaceDealsSchema,
  dependencies: [
    {
      schema: spaceClientSchema,
      getEntityId: ({ clientId }) => clientId,
      convertEntityToForm: (entity, props) => {
        const { Id, Manager } = entity;
        return {
          Client: Id,
          DealManager: Manager
        };
      }
    },
    {
      schema: spaceCallsSchema,
      getEntityId: ({ callId }) => callId,
      convertEntityToForm: (call) => {
        const {
          Company,
          Contact,
          Client,
          IndirectClient,
          Followers,
          Description,
          NoTagDescription
        } = call;

        return {
          Company,
          Contact,
          Client: Client || IndirectClient,
          Followers,
          Description,
          NoTagDescription
        };
      }
    },
    {
      schema: spaceEmailConversationSchema,
      getEntityId: ({ emailConversationId }) => emailConversationId,
      convertEntityToForm: (entity, props) => {
        const { Organization, Client, FirstContacts, LastEmail } = entity;
        const email = getEntity(spaceEmailSchema, LastEmail);
        return {
          Company: Organization,
          Client,
          Name: email.Subject,
          Contact: FirstContacts[0]
        };
      }
    },
    {
      schema: spaceSubscriptionSchema,
      getEntityId: ({ subscriptionId }) => subscriptionId,
      convertEntityToForm: (entity, props) => {
        const { Manager, Collaborators, PipelineStatus, Pipeline, ...rest } =
          entity;
        const TeamCollab = getTeamCollabValue(Collaborators);
        return {
          Assigned: Manager,
          TeamCollab,
          ...rest
        };
      }
    }
  ],
  // dependencies: [
  //   {
  //     schema: spaceCallsSchema,
  //     getEntityId: ({ callId }) => callId,
  //     convertEntityToForm: convertCallToTask
  //   },
  //   {
  //     schema: spaceTicketsSchema,
  //     getEntityId: ({ ticketId }) => ticketId,
  //     convertEntityToForm: convertTicketToTask
  //   },
  //   {
  //     schema: spaceInterventionsSchema,
  //     getEntityId: ({ interventionId }) => interventionId,
  //     convertEntityToForm: convertInterventionToTask
  //   },
  //   {
  //     schema: spaceContractSchema,
  //     getEntityId: ({ contractId }) => contractId,
  //     convertEntityToForm: (entity, props) => {
  //       const { Id } = entity;
  //       return {
  //         TaskTarget: {
  //           type: "Contract",
  //           data: Id
  //         }
  //       };
  //     }
  //   },
  //   {
  //     schema: spaceProjectsSchema,
  //     getEntityId: ({ projectId }) => projectId,
  //     convertEntityToForm: (entity, props) => {
  //       const { Id } = entity;
  //       return {
  //         TaskTarget: {
  //           type: "Project",
  //           data: Id
  //         }
  //       };
  //     }
  //   },
  //   {
  //     schema: spaceClientSchema,
  //     getEntityId: ({ clientId }) => clientId,
  //     convertEntityToForm: (entity, props) => {
  //       const { Id } = entity;
  //       return {
  //         TaskTarget: {
  //           type: "Client",
  //           data: Id
  //         }
  //       };
  //     }
  //   },
  //   {
  //     schema: spaceTasksSchema,
  //     getEntityId: ({ taskId }) => taskId,
  //     convertEntityToForm: (entity, props) => {
  //       const { Id } = entity;
  //       return {
  //         TaskTarget: {
  //           type: "Task",
  //           data: Id
  //         }
  //       };
  //     }
  //   }
  // ],
  formatDuplicationEntity: (entity) => {
    const { Id, TempId, ...rest } = entity;
    return rest;
  },
  convertEntityToForm: convertDealEntityToForm
});

const DealForm = (props) => {
  const { key, loading, error, convertedForm, isChild, baseForm, childProps } =
    useDealRequest(props);

  const { id, defaultForm, onSuccess, className } = props;

  const deal = useDeal(id);

  const {
    loading: loadingCurrencies,
    error: errorCurrencies,
    data: currencies
  } = useSpaceCurrenciesFetcher();

  const orgState = useSpaceQuery(`query/organizations`, [
    spaceOrganizationSchema
  ]);

  const { data: formTemplates, refetch: formTemplateRefetch } =
    useFormTemplateGetter(spaceDealsSchema);

  const { loading: loadingOrganizations, data: organizations } = orgState;

  const pipelinesUrl = useMemo(() => {
    return `query/pipelines?type=${PipelineTypeEnum.Deal}`;
  }, []);

  const {
    loading: loadingPipelines,
    data: pipelines,
    error: errorPipelines
  } = useSpaceQuery(pipelinesUrl, [spacePipelineSchema]);

  const resolvedForm = useMemo(() => {
    if (!currencies || !organizations) return;

    let form = {
      ...defaultForm,
      ...convertedForm,
      ...baseForm,
      ...childProps
    };
    const { EstimatedCosts, ...rest } = form;
    if (EstimatedCosts) {
      const EstimatedCostsDict = createDealEstimatedCostDict(
        EstimatedCosts,
        currencies
      );

      form = {
        ...rest,
        EstimatedCostsDict
      };
    }

    if (
      !id &&
      organizations &&
      organizations.findIndex((e) => e === form.Company) === -1
    ) {
      form.Company = organizations[0];
    }

    return form;
  }, [
    baseForm,
    childProps,
    convertedForm,
    currencies,
    defaultForm,
    id,
    organizations
  ]);

  if (
    loadingPipelines ||
    errorPipelines ||
    loading ||
    error ||
    loadingOrganizations
  )
    return <LoaderSpinner center size="sm" className="text-secondary" />;

  if (!isOrganzationRequestValid(orgState))
    return <OrganizationRequestSideError requestState={orgState} />;

  if (loadingCurrencies || loading || error || errorCurrencies)
    return <LoaderSpinner center size="sm" className="text-secondary" />;

  const canEdit = deal ? deal.CanEdit || false : true;
  //;
  // return null;

  return (
    <FormTemplateProvider forms={formTemplates} refetch={formTemplateRefetch}>
      <DealFormBase
        canDelete={deal?.CanDelete}
        className={className}
        isChild={isChild}
        onSuccess={onSuccess}
        id={id}
        pipelines={pipelines}
        canEdit={canEdit}
        key={key}
        organizations={organizations}
        currencies={currencies}
        convertedForm={resolvedForm}
      />
    </FormTemplateProvider>
  );
};

const DealFormBase = ({
  convertedForm,
  id,
  organizations,
  pipelines,
  canEdit,
  canDelete,
  onSuccess,
  currencies,
  className
}) => {
  // const user = useCurrentAccount();
  const formState = useFormState(() => {
    return {
      ...defaultDealForm({ currencies }),
      ...convertedForm
    };
  });
  const { form, setForm } = formState;
  // const { Pipeline, Company } = form;

  const intl = useIntl();

  // useMarkRead(spaceDealsSchema, id, Assigned);
  const createToast = useToast();
  const handleTranslatedError = useHandleError(spaceDealsSchema);
  const [post, { loading }] = useSidebarSpacePost(
    id ? `deals/${id}` : "deals",
    spaceDealsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess(newId);
        if (!id) {
          // defineChildProps({ Task: newId });
          dealCommunicator.dispatch({
            type: entityActionType.creation,
            id: newId
          });
        } else {
          dealCommunicator.dispatch({ type: entityActionType.update, id });
        }

        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "DEAL" })} nº ${newId || id}`,
					description: !id ? `${intl.formatMessage({ id: "SAVE_SUCCESS" })}` :`${intl.formatMessage({ id: "CREATED_SUCCEFULLY" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `deals/${id}`,
    spaceDealsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "DEAL" })} nº ${id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const handleSubmit = (form) => {
    post(formatDealForm(form, currencies));
  };

  return (
    <SideForm
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateDealForm}
    >
      <SideFormContent className={className}>
        <DealFormContent
          currencies={currencies}
          formState={formState}
          canEdit={canEdit}
          organizations={organizations}
          pipelines={pipelines}
          id={id}
        />
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        deleteLoading={deleteLoading}
        handleDelete={remove}
        form={form}
        handleChange={setForm}
        schema={spaceDealsSchema}
        isLoading={loading}
      />
    </SideForm>
  );
};

export default DealForm;
