import React from "react";
import { ConnectedTinyClientProfile } from "../Interventions/InterventionTargetItem";
import DropdownInput from "../Dropdown/DropdownInput";
import { useClient, useContract } from "../../Hooks/EntityHooks";
import { AccountProfileImg } from "../Img/Img";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract } from "@fortawesome/pro-light-svg-icons";
import { buildContractStatusType } from "./ContractProfile";

const ContractListItem = ({
  contract,
  enableInput,
  isTyping,
  dispatch,
  text,
  ...inputProps
}) => {
  const { Client, IndirectClient, Status } = contract || {};

  const resolvedClient = useClient(Client || IndirectClient);
  const resolvedStatus = buildContractStatusType(Status)

  if(!contract){
	return(
		<DropdownInput
			className="flat-input"
			fallbackValue={""}
			text={text}
			enableInput={enableInput}
			isTyping={isTyping}
			{...inputProps}
		  />
	)
}
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100 of-hidden text-truncate">
        {!isTyping && resolvedClient && (
          <AccountProfileImg
            size="mds"
            account={resolvedClient}
            circular
            className="mr-2 flex-0-0-auto"
          />
        )}
        <div className="d-flex flex-column w-100 of-hidden mr-2 align-items-baseline">
          <div className="dropdown-item-maintext ">
            {!enableInput && contract ? (
              contract.Description
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={contract && contract.Description}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
          {!isTyping && (
            <div className="dropdown-item-subtext-dif text-truncate">
              {contract && (
                <ConnectedTinyClientProfile client={Client || IndirectClient} />
              )}
            </div>
          )}
        </div>
		{!isTyping && <div className={` d-flex align-items-center justify-content-end status-color-${resolvedStatus}`}>
          <div className="d-flex py-1 px-2 fs-12 align-items-center status-list-template">
            <FontAwesomeIcon className="mr-2" icon={faFileContract} />
            <span>{contract.Id}</span>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default ContractListItem;

export const ConnectedContractListItem = ({ contract, ...rest }) => {
  const c = useContract(contract);
  return <ContractListItem contract={c} {...rest} />;
};
