import React from "react";
import { FormattedMessage } from "react-intl";
import KeyedDropdown from "../../Dropdown/KeyedDropdown";

export enum FilterOperatorsEnum {
  Eq = 1,
  Ne = 2,
  StartsWith = 3,
  EndsWith = 4,
  Contains = 5,
  DoesNotContain = 6,
  IsEmpty = 7,
  IsNotEmpty = 8,
  LessThanOrEqual = 9,
  LessThan = 10,
  GreaterThanOrEqual = 11,
  GreaterThan = 12,
  Between = 13,
  Next = 19,
  Last = 20,
  NotLast = 21,
  NotNext = 22
  // NextCustom= 21,
  // LastCustom= 22,
}

interface OperatorsEnumToStringIdMap {
  [val: string]: string;
}

const OperatorsText: OperatorsEnumToStringIdMap = {
  [FilterOperatorsEnum.Eq]: "FILTER_EQ",
  [FilterOperatorsEnum.Ne]: "FILTER_NEQ",
  [FilterOperatorsEnum.LessThan]: "FILTER_LT",
  [FilterOperatorsEnum.LessThanOrEqual]: "FILTER_LTE",
  [FilterOperatorsEnum.GreaterThan]: "FILTER_GT",
  [FilterOperatorsEnum.GreaterThanOrEqual]: "FILTER_GTE",
  [FilterOperatorsEnum.StartsWith]: "FILTER_STARTSWITH",
  [FilterOperatorsEnum.EndsWith]: "FILTER_ENDSWITH",
  [FilterOperatorsEnum.Contains]: "FILTER_CONTAINS",
  [FilterOperatorsEnum.DoesNotContain]: "FILTER_DOESNOTCONTAIN",
  [FilterOperatorsEnum.IsEmpty]: "FILTER_ISEMPTY",
  [FilterOperatorsEnum.IsNotEmpty]: "FILTER_ISNOEMPTY",
  [FilterOperatorsEnum.LessThan]: "FILTER_LT",
  [FilterOperatorsEnum.GreaterThan]: "FILTER_GT",
  [FilterOperatorsEnum.Between]: "FILTER_DATE_ISBETWEEN",
  [FilterOperatorsEnum.Next]: "FILTER_DATE_NEXT",
  [FilterOperatorsEnum.Last]: "FILTER_DATE_LAST",
  [FilterOperatorsEnum.NotNext]: "FILTER_NOT_NEXT_DAYS",
  [FilterOperatorsEnum.NotLast]: "FILTER_NOT_LAST_DAYS"
  // [FilterOperatorsEnum.NextCustom]: "FILTER_DATE_NEXT_CUSTOM",
  // [FilterOperatorsEnum.LastCustom]: "FILTER_DATE_LAST_CUSTOM",
};

const OperatorProfile = ({ item, className }: any) => {
  if (!item) {
    return null;
  }

  const textId = OperatorsText[item];

  return <FormattedMessage id={textId} />;
};

export const FilterOperatorDropdown = (props: any) => {
  const { options } = props;
  return (
    <KeyedDropdown
      options={options}
      valueComponent={OperatorProfile}
      closeOnSelect={true}
      {...props}
    />
  );
};

export const numberOperatorsOptions = [
  FilterOperatorsEnum.Eq,
  FilterOperatorsEnum.Ne,
  FilterOperatorsEnum.LessThan,
  FilterOperatorsEnum.LessThanOrEqual,
  FilterOperatorsEnum.GreaterThan,
  FilterOperatorsEnum.GreaterThanOrEqual,
  FilterOperatorsEnum.StartsWith,
  FilterOperatorsEnum.EndsWith,
  FilterOperatorsEnum.Contains,
  FilterOperatorsEnum.DoesNotContain
];

export const stringOperatorsOptions = [
  FilterOperatorsEnum.Eq,
  FilterOperatorsEnum.Ne,
  FilterOperatorsEnum.StartsWith,
  FilterOperatorsEnum.EndsWith,
  FilterOperatorsEnum.Contains,
  FilterOperatorsEnum.DoesNotContain,
  FilterOperatorsEnum.IsEmpty,
  FilterOperatorsEnum.IsNotEmpty
];

export const dateOperatorsOptions = [
  FilterOperatorsEnum.Eq,
  FilterOperatorsEnum.Ne,
  FilterOperatorsEnum.LessThan,
  FilterOperatorsEnum.LessThanOrEqual,
  FilterOperatorsEnum.GreaterThan,
  FilterOperatorsEnum.GreaterThanOrEqual,
  FilterOperatorsEnum.Next,
  FilterOperatorsEnum.Last,
  FilterOperatorsEnum.NotLast,
  FilterOperatorsEnum.NotNext
  // FilterOperatorsEnum.NextCustom,
  // FilterOperatorsEnum.LastCustom,
  // FilterOperatorsEnum.Between,
  // FilterOperatorsEnum.IsEmpty,
  // FilterOperatorsEnum.IsNotEmpty
];

export const generalOperatorsOptions = [
  FilterOperatorsEnum.Eq,
  FilterOperatorsEnum.Ne,
  FilterOperatorsEnum.IsEmpty,
  FilterOperatorsEnum.IsNotEmpty
];
