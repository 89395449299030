import React, { useRef, useMemo, useState } from "react";
import {
  getDefaultOrganizationAndOrigin,
  getClassificationTargetDefaultStatus,
} from "./ClassificationHelper";
import { WarningAlert } from "../../Components/Toast/ToastComponents";
import OriginOverview from "../Origin/OriginOverview";
import Button from "../../Components/Button/Button";
import { BillingStatusTypes } from "../../Components/Billing/Billing";
import "./Classification.css";
import { FormattedMessage } from "react-intl";

const WarningButtons = ({ setForm, newOrigin, setClicked, currentStatus }) => {
  const update = (updateStatus = false) => {
    setClicked(true);

    setForm((prevForm) => {
      const newForm = { ...prevForm, Target: newOrigin };

      if (updateStatus)
        newForm.Status = getClassificationTargetDefaultStatus(newOrigin);

      return newForm;
    });
  };

  const showOriginOnly = useMemo(() => {
    switch (currentStatus) {
      case BillingStatusTypes.ForBilling:
      case BillingStatusTypes.Billed:
      case BillingStatusTypes.UnderWarranty:
        return true;

      default:
        return false;
    }
  }, [currentStatus]);

  return (
    <div className="d-flex align-items-center">
      {showOriginOnly && (
        <Button className="mr-2 sm" vType="warning" onClick={() => update()}>
          <FormattedMessage id="ORIGIN" />
        </Button>
      )}
      <Button vType="warning" className="sm" onClick={() => update(true)}>
        <FormattedMessage id="ORIGIN_AND_STATUS" />
      </Button>
    </div>
  );
};

const Origin = ({ origin }) => {
  const value = useMemo(() => {
    return {
      [origin.type]: origin.data,
    };
  }, [origin.data, origin.type]);

  return <OriginOverview entity={value} />;
};

const ClassificationOriginWarning = ({
  form,
  interventions,
  currentTarget,
  currentStatus,
  setForm,
}) => {
  const formRef = useRef(form);

  const [clicked, setClicked] = useState(false);

  const newOrigin = useMemo(() => {
    const { Target } = formRef.current;
    if (Target) return null;

    const { Target: newTarget } = getDefaultOrganizationAndOrigin(
      interventions
    );

    return newTarget;
  }, [interventions]);

  const { Target } = formRef.current;

  if (Target) return null;

  if (!newOrigin) {
    if (currentTarget) return null;
    return (
      <WarningAlert className="mt-3 mb-2 rounded ar-classification-origin-warning">
        <FormattedMessage id="CLASSIFICATION_SELECT_ORIGIN" />
      </WarningAlert>
    );
  }

  if (clicked) return null;

  return (
    <WarningAlert
      isDiv
      className="mt-3 mb-2 rounded ar-classification-origin-warning"
    >
      <div>
        <FormattedMessage id="CLASSIFICATION_ORIGIN_RECOMMENDATION" />
      </div>
      <Origin origin={newOrigin} />
      <div className="mt-2 mb-1">
        <FormattedMessage id="CLASSIFICATION_SELECT_ORIGIN_QUESTION" />
      </div>
      <WarningButtons
        currentStatus={currentStatus}
        setForm={setForm}
        newOrigin={newOrigin}
        setClicked={setClicked}
      />
    </WarningAlert>
  );
};

export default ClassificationOriginWarning;
