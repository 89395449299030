import React from "react";
import { useDocumentType, usePipelineStatus } from "../../Hooks/EntityHooks";
import { DocumentsStatusTypes } from "../Settings/Documents/DocumentsHelper";
import classnames from "classnames";
import { faFileLines } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ResolvedDocumentType = ({ docType }) => {
  const type = useDocumentType(docType);

  return <div>{type.Name}</div>;
};

export const DifrentDocumentProfile = ({ doc, size }) => {
  const { PipelineStatus } = doc || {};
  const { Status } = usePipelineStatus(PipelineStatus);

  if (!doc) return null;
  let colorStatus;
  switch (Status) {
    case DocumentsStatusTypes.active:
      colorStatus = "status-in-progress-colors";
      break;
    case DocumentsStatusTypes.cancelledn:
      colorStatus = "status-canceled-colors";
      break;
    case DocumentsStatusTypes.closed:
      colorStatus = "status-closed-colors";
      break;
    case DocumentsStatusTypes.draft:
      colorStatus = "status-yellow-colors";
      break;
    case DocumentsStatusTypes.review:
      colorStatus = "status-orange-colors";
      break;
    default:
      break;
  }

  return (
    <div className={classnames("air-new-profiles-circle", colorStatus, size)}>
      <FontAwesomeIcon icon={faFileLines} size={size||"xl"} />
    </div>
  );
};
