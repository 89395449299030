import React, { useContext } from "react";
import { createEntityHook } from "../Hooks/EntityHooks";
import { spaceSchema } from "../config/schema";

export const SpaceContext = React.createContext();

// class SpaceProviderComp extends Component {
//   shouldComponentUpdate(nextProps) {
//     if (
//       this.props.space !== nextProps.space ||
//       this.props.location !== nextProps.location
//     )
//       return true;
//     return false;
//   }

//   createValue = () => {
//     const { space, error } = this.props;

//     return {
//       error,
//       space
//     };
//   };

//   render() {
//     const value = this.createValue();

//     return (
//       <SpaceContext.Provider value={value}>
//         {this.props.children}
//       </SpaceContext.Provider>
//     );
//   }
// }

// const mapStateToProps = (state, ownProps) => {
//   const currentSpaceId = Number(ownProps.match.params.spaceId);

//   if (isNaN(currentSpaceId)) {
//     return {
//       error: generalErrors.UNKNOWN_ERROR
//     };
//   }

//   return {
//     space: state.Entities.Spaces[currentSpaceId]
//   };
// };

export const useSpaceEntity = createEntityHook(spaceSchema);

const SpaceProvider = ({ spaceId, children }) => {
  const space = useSpaceEntity(spaceId);

  return (
    <SpaceContext.Provider value={space}>{children}</SpaceContext.Provider>
  );
};

// const SpaceProvider = withRouter(connect(mapStateToProps)(SpaceProviderComp));

export default SpaceProvider;

export const withSpace = (WrappedComponent) => {
  return (passThroughProps) => (
    <SpaceContext.Consumer>
      {(space) => <WrappedComponent space={space} {...passThroughProps} />}
    </SpaceContext.Consumer>
  );
};

export const useSpace = () => {
  const space = useContext(SpaceContext);
  return space;
};
