import React, { FC } from "react";
import "./DocTyper.scss";
import { DockTypeProps } from "./DocTyperTypes";

// @ts-nocheck

const DocTyperSimple = React.lazy(
  () => import(/* webpackChunkName: "docTyperSimple" */ "./DocTyperSimple")
);

const DocTyperComplex = React.lazy(
  () => import(/* webpackChunkName: "docTyperComplex" */ "./DocTyperComplex")
);

const DocTyper: FC<DockTypeProps> = ({ docType, ...rest }) => {
  switch (docType) {
    case "complex":
      return <DocTyperComplex {...rest} />;

    case "simple":
    default:
      return <DocTyperSimple {...rest} />;
  }
};


export default DocTyper;
