import React from "react";
import KeyedDropdown from "../Dropdown/KeyedDropdown";
import { ReactComponent as Angry } from "../../Assets/Svg/TicketRatingFaces/angry.svg";
import { ReactComponent as Frown } from "../../Assets/Svg/TicketRatingFaces/frown.svg";
import { ReactComponent as Meh } from "../../Assets/Svg/TicketRatingFaces/meh.svg";
import { ReactComponent as Smile } from "../../Assets/Svg/TicketRatingFaces/smile.svg";
import { ReactComponent as Laugh } from "../../Assets/Svg/TicketRatingFaces/laugh.svg";
import "../TicketRatingAccordion/TicketRating.css";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";

const RatingDictionary = {
  1: "VERY_BAD",
  2: "BAD",
  3: "AVERAGE",
  4: "GOOD",
  5: "VERY_GOOD"
};

const RatingFace = ({ value, ...props }) => {
  switch (parseInt(value)) {
    case 1:
      return <Angry {...props} />;

    case 2:
      return <Frown {...props} />;

    case 3:
      return <Meh {...props} />;

    case 4:
      return <Smile {...props} />;

    case 5:
      return <Laugh {...props} />;

    default:
      return null;
  }
};

export const NewTicketRatingComponent = ({ filter, value }) => {
  const intl = useIntl();

  if (!value) return null;

  const translation = intl.formatMessage({
    id: RatingDictionary[parseInt(value)]
  });
  return (
    <div
      className={classNames("d-flex align-items-center", { "mt-1": filter })}
    >
      <RatingFace
        value={value}
        className={classNames(" face-sizes", {
          "mr-3": !filter,
          "mr-2": filter
        })}
      />
      <div
        className={`d-flex px-2 py-1 rounded fs-14 ticket-rating-pill-${value}`}
      >
        {`${value} - ${translation}`}
      </div>
    </div>
  );
};

const options = [1, 2, 3, 4, 5];

const Component = ({ item }) => {
  return <NewTicketRatingComponent value={item} />;
};

export const RatingDropdown = (props) => {
  const { ...rest } = props;

  return (
    <KeyedDropdown
      arrowClassnames={"text-primary ml-2 fs-14"}
      valueComponent={Component}
      className="fs-12 text-truncate"
      options={options}
      dropdownClassname="text-truncate"
      closeOnSelect
      {...rest}
    />
  );
};

export const createTicketRatingFilter = (overrides) => {
  return {
    titleId: "RATING",
    field: "Ticket/Rating",
    icon: <FontAwesomeIcon icon={faStar} />,
    defaultValue: null,
    applyOnChange: true,
    component: (props) => (
      <RatingDropdown className="fs-12 text-truncate" {...props} />
    ),
    //   <ClientDropdown allClients={true} isPopup {...props} />,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div className="text-capitalize">
          <span>
            <FormattedMessage id={"RATING"} />{" "}
          </span>
          <span style={{ textTransform: "lowercase" }}>
            <FormattedMessage id={"EQUALS"} />{" "}
          </span>
          <NewTicketRatingComponent filter value={value} />
        </div>
      );
    },
    // getSchemaFromCompleteValue: value => spaceClientSchema,
    ...overrides
  };
};

export default RatingDropdown;
