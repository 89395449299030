import React, { FC } from "react";
import "./Input.css";
import classNames from "classnames";

const Input: FC<any> = React.forwardRef(
  (
    {
      className,
      size,
      hasErrors,
      preview,
      disableSelect,
      error,
      onChange,
      insideRightLabel,
      ...rest
    },
    ref
  ) => {
    if (preview) {
      return (
        <div
          style={{ padding: "4px 8px" }}
          className={classNames(
            "fs-14 text-black input-preview-mode-container",
            className
          )}
        >
          {rest.value}
        </div>
      );
    }

    const handleChange = (e: any) => {
      let newVal = `${e.target.value}`;
      if (e.target.type !== "password") {
        newVal = newVal.replace(/</g, "");
        newVal = newVal.replace(/>/g, "");
        newVal = newVal.replace(/\{/g, "");
        newVal = newVal.replace(/\}/g, "");
      }
      const t = { ...e };
      t.target.value = newVal;
      e.target.value = newVal;
      onChange(t);
    };

    let classes = classNames(
      "ssi-input ssi-control",
      className,
      size,
      disableSelect
    );

    if (insideRightLabel) {
      return (
        <div className="d-flex align-items-center">
          <input
            style={{
              borderRight: "none",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }}
            name="sample-text"
            autoComplete="off"
            ref={ref}
            className={classes}
            onChange={handleChange}
            {...rest}
          />
          {insideRightLabel && (
            <label
              style={{
                width: "fit-content",
                paddingTop: 9,
                borderLeft: "none",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }}
              className="m-0 ssi-control ssi-input"
            >
              {insideRightLabel}
            </label>
          )}
        </div>
      );
    }

    return (
      <input ref={ref} onChange={handleChange} className={classes} {...rest} />
    );
  }
);

export default Input;
