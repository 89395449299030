import React, { Component } from 'react';
import "./GridLoader.css";

class GridLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div className="a-gridloader-container" style={{width: this.props.width, height: this.props.height}}>
            </div>
        );
    }
}

export default GridLoader;