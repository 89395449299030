import React from "react";
import { useClient } from "../../../../Hooks/EntityHooks";
import {
  HistoryHeader,
  HistoryContent,
  HistoryAccountProfile,
  HistoryOverview,
  useHistoryItem,
} from "./HistoryItem";
import { ConnectedClientOrigin } from "../../../../Containers/Origin/ClientOrigin";
import { createItemEditDetailsComponent } from "./HistoryItemDetails/HistoryItemDetails";
import { ClientStatusProfile } from "../../../Clients/ClientProfile";
import { ClientEntityTypes } from "./HistoryItemDetails/HistoryItemProperties";
import { FormattedMessage } from "react-intl";

const ClientHistoryOverview = () => {
  const { Client, IsCreation, Account } = useHistoryItem();
  const item = useClient(Client);
  const { Name } = item;
  if (IsCreation)
    return (
      <HistoryOverview>
        <HistoryAccountProfile account={Account} />
        <span>
          {" "}
          <FormattedMessage id={"CREATED_ACCOUNT"} />{" "}
        </span>{" "}
        <b>{Name}</b>
      </HistoryOverview>
    );

  return (
    <HistoryOverview>
      <HistoryAccountProfile account={Account} />
      <span>
        {" "}
        <FormattedMessage id={"EDITED_ACCOUNT"} />{" "}
      </span>{" "}
      <b>{Name}</b>
    </HistoryOverview>
  );
};

const ClientEditItemDetails = createItemEditDetailsComponent({
  Status: {
    NameId: "status",
    ValueComponent: ClientStatusProfile,
  },
  ClientEntityTypes,
});

const ClientHistoryDetails = () => {
  const { IsCreation } = useHistoryItem();
  if (IsCreation) return null;

  return <ClientEditItemDetails />;
};

const ClientHistoryItem = () => {
  const { Client } = useHistoryItem();

  return (
    <>
      <HistoryHeader>
        <ConnectedClientOrigin client={Client}>
          {/* <HistoryTitle Icon={ClientIcon}>
            <FormattedMessage id={"ACCOUNT"} /> {Client}
          </HistoryTitle> */}
        </ConnectedClientOrigin>
      </HistoryHeader>
      <HistoryContent>
        <ClientHistoryOverview />
        <ClientHistoryDetails />
      </HistoryContent>
    </>
  );
};
export default ClientHistoryItem;
