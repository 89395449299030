/* eslint-disable import/no-duplicates */
// @flow
import * as React from 'react'
import { useRef, useEffect, Children } from 'react'
import Bricks from 'bricks.js'



const MasonryLayout = ({ children, sizes, fetchMore, loading, error, lastPage }) => {
	const container = useRef({})



  useEffect(() => {
    const bricks = Bricks({
      container: container.current,
      packed: 'data-packed',
      sizes,
      position: true,
    })

    bricks.resize(true)

    if (Children.count(children) > 0) {
      bricks.pack()
    }
  }, [children, sizes])

  return (
    <div ref={container} data-testid="MasonryLayoutContainer">
      {children}
    </div>
  )
}

export default MasonryLayout
