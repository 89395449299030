import React, { useContext, useLayoutEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { Intl18nContext, Languages } from "./Intl18nProvider";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";
import classnames from "classnames";
import { CountryProfileImg } from "../../Components/Img/Img";
import { useQuery, client } from "../../Helpers/IOClient";
import { useSpace } from "../../Contexts/SpaceContext";

const LanguageItem = ({ item, className }) => {
	const {Lang} = item

	const resolvedItem = Languages[Lang];


  return (
    <div className={classnames("d-flex align-items-center", className)}>
      <CountryProfileImg size="sm" country={resolvedItem.country} />
      <div>{resolvedItem.Name}</div>
    </div>
  );
};

const GiveKey = (item) => {
  return item.Lang;
};

export const Intl18nDropdown = React.memo(({ className, onChange }) => {
  const { value, changeLanguage } = useContext(Intl18nContext);
  const intl = useIntl();
  const space = useSpace();
  const { data, loading } = useQuery(`Query/Languages`);

  const handleChange = async (lang) => {
		const { Lang, Id } = lang;
		const item = Languages[Lang]
      if (space) {
        await client.post(
          `Spaces/${space.Id}/User/${Id}/Language`
        );
      }
		changeLanguage(item);
		onChange && onChange(item, lang)
  };

	const mountedRef = useRef(false)
	useLayoutEffect(() => {
		if(mountedRef.current) return;
		if(onChange && data){
			const x = data.find(e => e.Lang === value.Value)
			onChange(value, x)
			mountedRef.current = true
		}
	}, [data, onChange, value])

	const resolvedValue = useMemo(() => {
		const {Value} = value
		return {
			Lang: Value
		}
	}, [value])

  return (
    <KeyedDropdown
      className={className}
      valueComponent={LanguageItem}
      searchPlaceHolder={intl.formatMessage({ id: "SEARCH" })}
      showImage={false}
      value={resolvedValue}
      getKey={GiveKey}
			closeOnSelect={true}
			disabled={loading}
      options={data || []}
      onChange={handleChange}
    />
  );
});
