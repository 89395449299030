import React, { useMemo } from "react";
import {
  useIntervention,
  useIneficientTypes,
  useCountry
} from "../../Hooks/EntityHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserClock,
  faAlignJustify,
  faSuitcase,
  faUser,
  faCalendarAlt,
  faClock,
  faMap,
  faDrawCircle
} from "@fortawesome/pro-solid-svg-icons";

import { spaceInterventionsSchema } from "../../config/schema";
import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";
import SplitCol from "../../Components/SplitCol/SplitCol";
import {
  ConnectInterventionEntity,
  InterventionPlaceTypesProfile
} from "../../Components/Interventions/Interventions";
import Moment from "react-moment";
import { ConnectedInterventionTypeProfile } from "../../Components/InterventionTypes/InterventionTypeProfile";
import OriginOverview from "./OriginOverview";
import {
  OriginContainer,
  OriginDate,
  OriginHeader,
  OriginItemGroup
} from "../../Components/Origin/Origin";
import { createOriginPopup } from "./Origin";
import TextEllipsis from "../../Components/TextEllipsis/TextEllipsis";
import FormattedTimePreview from "../../Components/FormattedTimePreview/FormattedTimePreview";
import { ConnectedClientOrigin } from "./ClientOrigin";
import { ConnectedAccountPopup } from "./AcountPopup";
import { InterventionEntityGridButtons } from "../Interventions/InterventionsHelper";
import { InterventionIcon } from "../../Components/Common/EntityIcons";
import { useIntl } from "react-intl";
import moment from "moment";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import Button from "../../Components/Button/Button";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import classnames from "classnames";
import { useSpace } from "../../Contexts/SpaceContext";
import { useConnectedSchemaGetter } from "../../Helpers/HelperHooks";
export const InterventionOriginAnchor = React.forwardRef(
  ({ intervention, children, className, ...rest }, ref) => {
    const openForm = useEntitySummarySidebar();
    if (!intervention) return null;
    const { IsDeleted, NameWithTime, IsTime, BeginDate, EndDate } =
      intervention;

    const resolvedName =
      NameWithTime || IsTime
        ? `${intervention.Name} - ${moment(BeginDate).format(
            "DD/MM/YYYY"
          )} (${moment(BeginDate).format("HH:mm")} -> ${moment(EndDate).format(
            "HH:mm"
          )})`
        : intervention.Name;

    return (
      <div
        ref={ref}
        className={classnames(
          `${className ? className : "link-color "} text-truncate`,
          {
            "item-was-deleted": IsDeleted
          }
        )}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <div className="link-color d-flex align-items-center fs-12 text-truncate">
            {/* <ProjectStatusProfile inline hideName status={intervention.Status} /> */}
            <div className="d-inline-flex align-items-center">
              <div className="position-relative d-inline-block mr-1">
                <FontAwesomeIcon icon={faUserClock} />
              </div>
            </div>
            <Button
              vType="link-primary"
              className="EntityLinkClassID fs-12 text-black"
              onClick={() => {
                openForm({
                  type: moduleTypes.interventions,
                  props: {
                    id: intervention.Id
                  }
                });
              }}
            >
              <span>
                <TextEllipsis
                  length={200}
                  text={
                    resolvedName ||
                    intervention.NoTagDescription ||
                    intervention.Description
                  }
                />
              </span>
            </Button>

            {/* {Id && <div className="fs-10">Nº: {Id}</div>} */}
          </div>
        )}
      </div>
    );
  }
);
export const ConnectedInterventionOriginAnchor = ({
  intervention,
  ...rest
}) => {
  const resolvedIntervention = useIntervention(intervention);
  const Result = useConnectedSchemaGetter(
    intervention,
    spaceInterventionsSchema
  );

  if (Result) return Result;
  return (
    <InterventionOriginAnchor intervention={resolvedIntervention} {...rest} />
  );
};

export const InterventionOriginPopup = ({ intervention }) => {
  const intl = useIntl();
  const i = useIntervention(intervention.Id);
  const {
    NoTagDescription,
    Id,
    IndirectClient,
    Client,
    Account,
    // ReceivedDate,
    InefficientDuration,
    TotalPauses,
    EndDate,
    BeginDate,
    PlaceType,
    Addresses,
    InterventionType,
    EstimatedCost,
    TimeDetail,
    TimeDetailDescription,
    NoTagInternalDescription,
    CreationDate
  } = i;

  // const duration = moment
  //   .duration(new Date(EndDate).getTime() - new Date(BeginDate).getTime())
  //   .humanize();

  const finalAddress = useMemo(() => {
    if (Addresses && Addresses[0]) return Addresses[0];
    else return {};
  }, [Addresses]);
  const space = useSpace();
  const ConnectedCountry = useCountry(finalAddress?.Country);

  const addressString = `${finalAddress.Address1}${
    finalAddress?.Address2 ? `, ${finalAddress.Address2}` : ""
  }${finalAddress?.PostalCode ? `, ${finalAddress.PostalCode}` : ""}${
    finalAddress?.Locality ? `, ${finalAddress.Locality}` : ""
  }${ConnectedCountry ? `, ${ConnectedCountry.Name}` : ""}`;

  const linkString = `${finalAddress.Address1}${
    finalAddress?.Address2 ? `, ${finalAddress.Address2}` : ""
  }${finalAddress?.Locality ? `, ${finalAddress.Locality}` : ""}`
    .replace(/  +/g, " ")
    .replace(/,/g, "");

  return (
    <OriginContainer>
      <OriginHeader
        Dif
        className="mb-1"
        date={CreationDate}
        type="Tempo"
        entity="TIME"
        Icon={<InterventionIcon className="mr-2" />}
        EntityButtons={<InterventionEntityGridButtons entity={i} minimal />}
        id={Id}
        link={`/s/${space.Id}/interventions/${Id}`}
        title={NoTagDescription}
      />
      <div className="mx-3 mb-1">
        {/* <div className="text-black fs-14 mb-2">{Name}</div> */}
        <div className="mb-1 d-flex align-items-center justify-content-between">
          <div> </div>
          <div>
            <OriginDate className="fs-12" date={CreationDate} />
          </div>
        </div>
      </div>
      <SplitCol
        className="mb-1"
        left={
          <OriginItemGroup
            icon={faSuitcase}
            title={intl.formatMessage({ id: "ACCOUNT" })}
          >
            <ConnectedClientOrigin client={Client || IndirectClient}>
              <ConnectedClientProfile
                showId
                client={Client || IndirectClient}
              />
            </ConnectedClientOrigin>
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUser}
            title={intl.formatMessage({ id: "COLLABORATOR" })}
          >
            <ConnectedAccountPopup
              className="fs-12 text-black"
              account={Account}
            />
          </OriginItemGroup>
        }
      />
      {Addresses && finalAddress.Address1 && (
        <OriginItemGroup
          icon={faMap}
          title={intl.formatMessage({ id: "ADDRESS" })}
        >
          <a
            href={`https://www.google.com/maps/place/${linkString}`}
            rel="noopener noreferrer"
            target="_blank"
            className="fs-12"
          >
            {addressString}
          </a>
        </OriginItemGroup>
      )}
      <SplitCol
        className="mb-1"
        left={
          <OriginItemGroup
            icon={faCalendarAlt}
            title={intl.formatMessage({ id: "START_DATE" })}
          >
            <Moment
              className="fs-12 text-black"
              date={BeginDate}
              format="DD/MM/YYYY HH:mm"
            />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faCalendarAlt}
            title={intl.formatMessage({ id: "END_DATE" })}
          >
            <Moment
              className="fs-12 text-black"
              date={EndDate}
              format="DD/MM/YYYY HH:mm"
            />
          </OriginItemGroup>
        }
      />
      <SplitCol
        className="mb-1"
        left={
          <OriginItemGroup
            icon={faClock}
            title={intl.formatMessage({ id: "DURATION" })}
          >
            <FormattedTimePreview
              className="fs-12 text-black"
              value={EstimatedCost}
              asHourMinute
            />
            {/* <span className="fs-14 text-black">{duration}</span> */}
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faMap}
            title={intl.formatMessage({ id: "WHERE" })}
          >
            <InterventionPlaceTypesProfile
              className="fs-12 text-black"
              item={PlaceType}
            />
          </OriginItemGroup>
        }
      />
      <SplitCol
        className="fs-12 mb-1"
        left={
          <OriginItemGroup
            icon={faUserClock}
            title={intl.formatMessage({ id: "TIME_TYPE" })}
          >
            <ConnectedInterventionTypeProfile
              className="fs-12 text-black"
              interventionType={InterventionType}
            />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faDrawCircle}
            title={intl.formatMessage({ id: "ORIGIN" })}
          >
            <OriginOverview entity={i} />
          </OriginItemGroup>
        }
      />
      <SplitCol
        className="mb-1 fs-12 text-black"
        left={
          <OriginItemGroup
            icon={faClock}
            title={intl.formatMessage({ id: "INEFFICIENT_TIME" })}
          >
            <FormattedTimePreview
              className="fs-12 text-black"
              value={InefficientDuration}
              asHourMinute
            />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faClock}
            title={intl.formatMessage({ id: "TOTAL_PAUSES" })}
          >
            <FormattedTimePreview
              className="fs-12 text-black"
              value={TotalPauses}
              asHourMinute
            />
          </OriginItemGroup>
        }
      />
      {TimeDetail && (
        <SplitCol
          className="mb-1 fs-12 text-black"
          left={
            <OriginItemGroup
              icon={faUserClock}
              title={intl.formatMessage({ id: "INEFFICIENT_TIME_TYPE" })}
            >
              <IneficientTypeName type={TimeDetail} />
            </OriginItemGroup>
          }
          right={
            <OriginItemGroup
              icon={faUserClock}
              title={intl.formatMessage({ id: "REASON_INEFFICIENCY" })}
            >
              <TextEllipsis
                truncate
                length={150}
                text={TimeDetailDescription}
              />
            </OriginItemGroup>
          }
        />
      )}
      <OriginItemGroup
        className="fs-12 text-black"
        icon={faAlignJustify}
        title={intl.formatMessage({ id: "INTERNAL_NOTE" })}
      >
        <div className="text-black breakWord">
          <TextEllipsis
            length={250}
            truncate
            text={NoTagInternalDescription ? NoTagInternalDescription : "―"}
          />
        </div>
      </OriginItemGroup>
      <div className=" pb-3">
        {/* <InterventionEntityButtons
          //   className="mt-3 mb-3"

          entity={i}
        /> */}
      </div>
    </OriginContainer>
  );
};

const IneficientTypeName = ({ type, ...rest }) => {
  const c = useIneficientTypes(type);
  if (c !== null)
    return <TextEllipsis {...rest} truncate text={c.Name} length={10} />;
  else return "―";
};

export const InterventionOriginOptions = {
  valueProp: "intervention",
  schema: spaceInterventionsSchema
};

export const InterventionOrigin = createOriginPopup(
  InterventionOriginAnchor,
  InterventionOriginPopup,
  InterventionOriginOptions
);

export default InterventionOrigin;

export const ConnectedInterventionOrigin =
  ConnectInterventionEntity(InterventionOrigin);
