import { useLayoutEffect } from "react";
import { useEntitiesHub } from "../../Containers/RealTime/RealTime";
import { RealTimeRoomTypes } from "../../Containers/RealTime/RealTimeHelper";
import {
  spaceCallsSchema,
  spaceClientSchema,
  spaceDealsSchema,
  spaceContactSchema,
  spaceContractSchema,
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceTasksSchema,
  spaceInterventionsSchema,
  spaceSubscriptionSchema,
  accountSchema,
  spaceBillingSchema,
  spaceSocialPostsSchema,
  spaceEmailConversationSchema
} from "../../config/schema";
import { updateNormalizedSchemaCache } from "../../Helpers/IOClient";
import { normalize } from "../../Helpers/SchemaHelper";

const schemaMapping = {
  [RealTimeRoomTypes.Client]: spaceClientSchema,
  [RealTimeRoomTypes.Contact]: spaceContactSchema,
  [RealTimeRoomTypes.Deal]: spaceDealsSchema,
  [RealTimeRoomTypes.Contract]: spaceContractSchema,
  [RealTimeRoomTypes.Call]: spaceCallsSchema,
  [RealTimeRoomTypes.Project]: spaceProjectsSchema,
  [RealTimeRoomTypes.Ticket]: spaceTicketsSchema,
  [RealTimeRoomTypes.Task]: spaceTasksSchema,
  [RealTimeRoomTypes.TimeCharge]: spaceInterventionsSchema,
  [RealTimeRoomTypes.Subscription]: spaceSubscriptionSchema,
  [RealTimeRoomTypes.Billing]: spaceBillingSchema,
  [RealTimeRoomTypes.SocialPost]: spaceSocialPostsSchema,
  [RealTimeRoomTypes.Email]: spaceEmailConversationSchema
};

const regexExp =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

const AccountsInRoomSynchronizer = ({ children }) => {
  const entitiesHub = useEntitiesHub();

  useLayoutEffect(() => {
    if (!entitiesHub) return;
    // let timer;
    const listner = (roomType, entityId, accounts) => {
      const schema = schemaMapping[roomType];
      // clearTimeout(timer);
      const parsedEntityId = regexExp.test(entityId)
        ? entityId
        : parseInt(entityId) || entityId;

      const normalized = normalize(JSON.parse(accounts), [accountSchema]);

      if (!schema) return;

      const entities = {
        [parsedEntityId]: {
          Id: parsedEntityId,
          FromSignalR: true,
          AccountsInRoom: normalized.result
        }
      };

      const update = {
        ...normalized.entities,
        [schema.name]: entities
      };

      // timer = setTimeout(() => {
      updateNormalizedSchemaCache(update);
      // }, 300);
    };

    entitiesHub.on("AccountsInRoom", listner);

    return () => entitiesHub.off("AccountsInRoom", listner);
  }, [entitiesHub]);

  useLayoutEffect(() => {
    if (!entitiesHub) return;
    let timer;
    const listner = (roomType, entityId, accounts) => {
      const schema = schemaMapping[roomType];
      // clearTimeout(timer);
      const parsedEntityId = parseInt(entityId) || entityId;

      const normalized = normalize(JSON.parse(accounts), [accountSchema]);

      if (!schema) return;

      const entities = {
        [parsedEntityId]: {
          Id: parsedEntityId,
          FromSignalR: true,
          AccountsInRoomView: normalized.result
        }
      };

      const update = {
        ...normalized.entities,
        [schema.name]: entities
      };

      // timer = setTimeout(() => {
      updateNormalizedSchemaCache(update);
      // }, 300);
    };

    entitiesHub.on("AccountsInRoomView", listner);

    return () => entitiesHub.off("AccountsInRoomView", listner);
  }, [entitiesHub]);

  return children;
};

export default AccountsInRoomSynchronizer;
