import React, { useState, useMemo } from "react";
import { useToast } from "../Toast/ToastProvider";
import { useImageFetcher } from "../../Helpers/FileHelper";
import { handleError } from "../../Helpers/MiscHelper";
import Input from "../Input/Input";
import Button from "../Button/Button";
import FileUploader from "../FileUploader/FileUploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

export const SocialPostsFileInput = ({
  onChange,
  preview,
  value = [],
  account,
}) => {
  // const SocialAccount = useSocialAccount(account);
  const [url, setUrl] = useState("");
  // const [data, setData] = useState(value);
  const createToast = useToast();
  const getData = useImageFetcher(null, createToast);

  const handleClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { BlobFile, DataFile } = await getData(
      window.encodeURIComponent(url),
      createToast
    );

    if (value.length > 0 && BlobFile.type === "image/gif") {
      handleError(
        createToast,
        "Só é possivel inserir GIF's como primeira imagem"
      );
    } else {
      onChange([
        ...value,
        { imagePreviewUrl: DataFile, Url: url, contentType: BlobFile.type },
      ]);
    }
    setUrl("");
  };

  const [FinishedFiles, UploadingFiles] = useMemo(() => {
    const finished = [];
    const uploading = [];

    value.forEach((e) => {
      if (e.TimeM !== "uploading") {
        finished.push(e);
      } else {
        uploading.push(e);
      }
    });
    return [finished, uploading];
  }, [value]);

  const handleFileUploaderChanges = (e) => {
    onChange([...FinishedFiles, ...e]);
  };

  const handleRemovePicture = (id) => {
    const tempFinishedFiles = [...FinishedFiles];
    tempFinishedFiles.splice(id, 1);
    onChange([...tempFinishedFiles, ...UploadingFiles]);
  };
  const FirstFinishedItem = FinishedFiles[0] || {};
  return (
    <div>
      {FinishedFiles.length < 4 &&
        FirstFinishedItem.Extension !== ".gif" &&
        FirstFinishedItem.contentType !== "image/gif" && (
          <>
            {!preview && (
              <div className="d-flex align-items-center mb-3">
                <Input
                  className="flex-1 mr-3"
                  type="text"
                  value={url}
                  placeholder="Insira o link da imagem"
                  onChange={(e) => setUrl(`${e.target.value}`)}
                />
                <Button onClick={handleClick}>Inserir</Button>
              </div>
            )}
            <div className="mb-3">
              <FileUploader
								uploaderSettings={{blockScreenRecord: true}}
                preview={preview}
                singleFileUpload
                accept={
                  value.length > 0
                    ? [".jpg", ".png", ".jpeg"]
                    : [".jpg", ".png", ".jpeg", ".gif"]
                }
                extendedMode
                value={UploadingFiles}
                onChange={handleFileUploaderChanges}
              />
            </div>
          </>
        )}
      <div className="ImageBlock">
        {FinishedFiles.map((e, i) => {
          return (
            <ImageComponent
              preview={preview}
              key={i}
              src={e.imagePreviewUrl || e.Url}
              id={i}
              removeFunction={handleRemovePicture}
            />
          );
        })}
      </div>
    </div>
  );
};

const ImageComponent = ({ preview, src, id, removeFunction }) => {
  const handleRemove = () => {
    removeFunction(id);
  };

  return (
    <>
      <div className="ImageContainer position-relative">
        {!preview && (
          <div
            className="RemoveContainer cursor-pointer"
            onClick={handleRemove}
          >
            <FontAwesomeIcon size="sm" icon={faTrashAlt} />
          </div>
        )}

        <img alt="" className="d-block w-100 h-100" src={src} />
      </div>
    </>
  );
};
