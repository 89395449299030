import React, { useEffect } from "react";
import { useCurrentSidebarItem } from "./SidebarViewsHelper";
import SuspenseErrorBoundary from "../Suspense/SuspenseErrorBoundary";

const SidebarContentItem = React.memo(function SidebarContentItem({ item }) {
  const { Component, props } = item;

  return (
    <SuspenseErrorBoundary sidebar>
      {React.cloneElement(Component, props)}
    </SuspenseErrorBoundary>
  );
});

const SidebarContent = React.memo(({ tabs }) => {
  const item = useCurrentSidebarItem(tabs);

  if (!item) return null;

  return <SidebarContentItem item={item} />;
});

const SidebarFullscsreenView = ({
  open,
  onEntered,
  onExited,
  tabs,
  onCloseSidebar,
}) => {
  useEffect(() => {
    if (open) onEntered();
    else onExited();
  }, [onEntered, onExited, open]);

  if (!open) return null;

  return (
    <div className="ar-sidebar-fullscreen">
      <SidebarContent tabs={tabs} />
    </div>
  );
};

export default SidebarFullscsreenView;
