import React, { useRef, useContext } from "react";
import Button from "../Button/Button";
import Popup, { usePopupOpenState } from "../Popup/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faWindowMaximize,
  faExpandArrows,
  faWindowRestore,
} from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import {
  SidebarViewSetterContext,
  SidebarVisualTypeEnum,
} from "./SidebarViewsHelper";

const SidebarViewButton = ({ icon, textId, onClick, rotation }) => {
  return (
    <Button vType="link" className="my-1" onClick={onClick}>
      <div className="text-left">
        <FontAwesomeIcon rotation={rotation} icon={icon} className="mr-2" />
        <FormattedMessage id={textId} />
      </div>
    </Button>
  );
};

const SidebarViewSelectorPopupContent = ({ toggleIsOpen }) => {
  const setView = useContext(SidebarViewSetterContext);

  const handleChange = (newType) => {
    setView(newType);
    // toggleIsOpen();
  };

  return (
    <div className="py-2 ar-grid-buttons-popup bg-white rounded">
      <div className="label-title">
        <FormattedMessage id="SEE_AS" />
      </div>
      <SidebarViewButton
        rotation={90}
        textId="SIDEBAR"
        icon={faWindowMaximize}
        onClick={() => handleChange(SidebarVisualTypeEnum.side)}
      />
      <SidebarViewButton
        textId="FULLSCREEN"
        icon={faExpandArrows}
        onClick={() => handleChange(SidebarVisualTypeEnum.fullscreen)}
      />
      <SidebarViewButton
        textId="MODAL"
        icon={faWindowRestore}
        onClick={() => handleChange(SidebarVisualTypeEnum.modal)}
      />
    </div>
  );
};

export const SidebarSelectorWidth = 34;

const SidebarViewSelector = (props) => {
	const { className } = props
  const popupRef = useRef();
  const anchorRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);

  return (
    <>
      <Popup
        domRef={popupRef}
        placement="bottom-end"
        anchorEl={anchorRef.current}
        isOpen={Boolean(isOpen)}
      >
        <SidebarViewSelectorPopupContent toggleIsOpen={toggleIsOpen} />
      </Popup>

      <div
        ref={anchorRef}
        onClick={(e) => toggleIsOpen(true)}
        className={classnames(
          "d-flex align-items-center justify-content-center cursor-pointer",
          className
        )}
        style={{ width: SidebarSelectorWidth, height: SidebarSelectorWidth }}
      >
        <FontAwesomeIcon size="lg" icon={faEllipsisV} />
      </div>
    </>
  );
};

export default SidebarViewSelector;
