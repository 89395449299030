import { faBan, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useConnectionId } from "../../Containers/RealTime/RealTime";
import { getEntity, useSpaceDelete } from "../../Helpers/IOClient";
import Button from "../Button/Button";
import LoadableButton from "../Button/LoadableButton";
import FormInput from "../Forms/FormInput/FormInput";
import Modal from "../Modal/Modal";
import { useHandleError } from "./ServerAwareHelper";
import { ServerAwareContext, useServerAwareState } from "./ServerGrid";
import classnames from "classnames";
import { ConnectedClientOrigin } from "../../Containers/Origin/ClientOrigin";
import { ConnectedDealOrigin } from "../../Containers/Origin/DealOrigin";
import { ConnectedContractOrigin } from "../../Containers/Origin/ContractOrigin";
import { ConnectedSubscriptionOrigin } from "../../Containers/Origin/SubscriptionOrigin";
import { ConnectedCallOrigin } from "../../Containers/Origin/CallOrigin";
import { ConnectedProjectOrigin } from "../../Containers/Origin/ProjectOrigin";
import { ConnectedTicketOrigin } from "../../Containers/Origin/TicketOrigin";
import { ConnectedTaskOrigin } from "../../Containers/Origin/TaskOrigin";
import { ConnectedInterventionOrigin } from "../../Containers/Origin/InterventionOrigin";
import { ConnectedContactOrigin } from "../../Containers/Origin/ContactOrigin";
import { ConnectedBillingOrigin } from "../../Containers/Origin/BillingOrigin";
import { ContractStatusProfileWithIcon } from "../Contract/ContractProfile";
import { EmailSignatureIcon } from "../Common/EntityIcons";
import { useEmailSignature } from "../../Hooks/EntityHooks";
import { SelectedArrMsgContext } from "../UnOrReadOptions/UnreadOptions";
import { EntityPopupContext } from "../EntityPopup/EntityPopupHelper";
import { useDeleteDisable } from "./DeleteHelper";
import { DeleteModalContent } from "../Forms/SideMenuForm";
import { ConnectedContactProfile } from "../Contacts/ContactProfile";
import { ConnectedContactPopup } from "../../Containers/Origin/ContactPopup";

const ConnectedEmailSignatureProfile = ({ emailSignature, dynamicFont }) => {
  const {
    // Id,
    Status,
    IsDeleted,
    Name
  } = useEmailSignature(emailSignature);
  return (
    <div
      className={classnames("link-color text-truncate", {
        "item-was-deleted": IsDeleted
      })}
    >
      <>
        <div
          className={classnames(
            "link-color d-flex align-items-center text-truncate",
            {
              "fs-12": !dynamicFont
            }
          )}
        >
          <div className="d-inline-flex align-items-center mr-1">
            <ContractStatusProfileWithIcon
              Icon={<EmailSignatureIcon />}
              value={Status}
              hideName
            />
          </div>
          <Button
            vType="link-primary"
            className="EntityLinkClassID fs-12 text-black"
          >
            <span>{Name}</span>
          </Button>

          {/* <ClientStatusBadge
                  className="d-inline-flex"
                  status={client.Status}
                /> */}
        </div>
        {/* <div>{task.Id}</div> */}
        {/* {Id && <div className="fs-10">Nº: {Id}</div>} */}
      </>
    </div>
  );
};

export const DeleteAllComponentDictionary = {
  // 1: "EmailConversationIds",
  4: (e) => <ConnectedClientOrigin client={e} />,
  5: (e) => <ConnectedDealOrigin deal={e} />,
  6: (e) => <ConnectedContractOrigin contract={e} />,
  7: (e) => <ConnectedSubscriptionOrigin Subscription={e} />,
  8: (e) => <ConnectedCallOrigin call={e} />,
  9: (e) => <ConnectedProjectOrigin project={e} />,
  10: (e) => <ConnectedTicketOrigin ticket={e} />,
  11: (e) => <ConnectedTaskOrigin task={e} />,
  12: (e) => <ConnectedInterventionOrigin intervention={e} />,
  13: (e) => <ConnectedContactPopup contact={e} />,
  14: (e) => <ConnectedBillingOrigin billing={e} />,
  15: (e) => <ConnectedEmailSignatureProfile emailSignature={e} />
};

export const TranslationDictionary = {
  // 1: "EmailConversationIds",
  4: {
    title: "DELETE_ALL_MODAL_TITLE_CLIENTS",
    subValid: "DELETE_ALL_MODAL_SUB_TITLE_CLIENTS_ALL_VALID",
    sub: "DELETE_ALL_MODAL_SUB_TITLE_CLIENTS_SOME_VALID"
  },
  5: {
    title: "DELETE_ALL_MODAL_TITLE_DEALS",
    subValid: "DELETE_ALL_MODAL_SUB_TITLE_DEALS_ALL_VALID",
    sub: "DELETE_ALL_MODAL_SUB_TITLE_DEALS_SOME_VALID"
  },
  6: {
    title: "DELETE_ALL_MODAL_TITLE_CONTRACTS",
    subValid: "DELETE_ALL_MODAL_SUB_TITLE_CONTRACTS_ALL_VALID",
    sub: "DELETE_ALL_MODAL_SUB_TITLE_CONTRACTS_SOME_VALID"
  },
  7: {
    title: "DELETE_ALL_MODAL_TITLE_SUBSCRIPTIONS",
    subValid: "DELETE_ALL_MODAL_SUB_TITLE_SUBSCRIPTIONS_ALL_VALID",
    sub: "DELETE_ALL_MODAL_SUB_TITLE_SUBSCRIPTIONS_SOME_VALID"
  },
  8: {
    title: "DELETE_ALL_MODAL_TITLE_CALLS",
    subValid: "DELETE_ALL_MODAL_SUB_TITLE_CALLS_ALL_VALID",
    sub: "DELETE_ALL_MODAL_SUB_TITLE_CALLS_SOME_VALID"
  },
  9: {
    title: "DELETE_ALL_MODAL_TITLE_PROJECTS",
    subValid: "DELETE_ALL_MODAL_SUB_TITLE_PROJECTS_ALL_VALID",
    sub: "DELETE_ALL_MODAL_SUB_TITLE_PROJECTS_SOME_VALID"
  },
  10: {
    title: "DELETE_ALL_MODAL_TITLE_TICKETS",
    subValid: "DELETE_ALL_MODAL_SUB_TITLE_TICKETS_ALL_VALID",
    sub: "DELETE_ALL_MODAL_SUB_TITLE_TICKETS_SOME_VALID"
  },
  11: {
    title: "DELETE_ALL_MODAL_TITLE_TASKS",
    subValid: "DELETE_ALL_MODAL_SUB_TITLE_TASKS_ALL_VALID",
    sub: "DELETE_ALL_MODAL_SUB_TITLE_TASKS_SOME_VALID"
  },
  12: {
    title: "DELETE_ALL_MODAL_TITLE_INTERVENTIONS",
    subValid: "DELETE_ALL_MODAL_SUB_TITLE_INTERVENTIONS_ALL_VALID",
    sub: "DELETE_ALL_MODAL_SUB_TITLE_INTERVENTIONS_SOME_VALID"
  },
  13: {
    title: "DELETE_ALL_MODAL_TITLE_CONTACTS",
    subValid: "DELETE_ALL_MODAL_SUB_TITLE_CONTACTS_ALL_VALID",
    sub: "DELETE_ALL_MODAL_SUB_TITLE_CONTACTS_SOME_VALID"
  },
  14: {
    title: "DELETE_ALL_MODAL_TITLE_BILLINGS",
    subValid: "DELETE_ALL_MODAL_SUB_TITLE_BILLINGS_ALL_VALID",
    sub: "DELETE_ALL_MODAL_SUB_TITLE_BILLINGS_SOME_VALID"
  },
  15: {
    title: "DELETE_ALL_MODAL_TITLE_EMAIL_SIGNATURES",
    subValid: "DELETE_ALL_MODAL_SUB_TITLE_EMAIL_SIGNATURE_ALL_VALID",
    sub: "DELETE_ALL_MODAL_SUB_TITLE_EMAIL_SIGNATURE_SOME_VALID"
  }
};

const ComponentOrigin = ({ id, type }) => {
  const Component = useMemo(() => {
    const funct = DeleteAllComponentDictionary[type];
    return funct(id);
  }, [id, type]);

  return Component;
};

const ComponentArr = ({ arr }) => {
  const { schema } = useContext(ServerAwareContext);
  return (
    <div
      style={{ maxHeight: "50vh" }}
      className="mb-3 d-flex flex-column flex-1 of-y-auto "
    >
      {arr.map((e, i) => {
        const item = getEntity(schema, e.id);
        return (
          <div
            key={e.id}
            className={classnames(
              "py-2 d-flex align-items-center justify-content-between",
              {
                "bg-airdesk": i % 2 !== 0
              }
            )}
          >
            <ComponentOrigin {...e} />
            {item.CanDelete ? (
              <div></div>
            ) : (
              <FontAwesomeIcon icon={faBan} className="text-danger-alt" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export const DeleteSingleButtonContextMenu = ({
  entity,
  handleDeleteOpenModal,
  disable,
  deleteLoading
}) => {
  if (entity.CanDelete && !disable) {
    return (
      <>
        <div className="h-100 Logout-button ">
          <div
            className="ar-logout-button d-flex align-items-center w-100 h-100 pb-2 pt-2 cursor-pointer"
            onClick={handleDeleteOpenModal}
          >
            <div className="ar-form-button-link-icon mr-1">
              <FontAwesomeIcon icon={faTrashAlt} />
            </div>
            <span>
              <FormattedMessage id={"DELETE"} />
            </span>
          </div>
        </div>
      </>
    );
  }

  return null;
};

export const DeleteButtonContextMenu = ({ entity }) => {
  const openDeleteModal = useOpenDeleteModal();
  const disableDelete = useDeleteDisable() || false;
  const providedOnSuccess = useContext(EntityPopupContext);

  const handleDeleteOpenModal = () => {
    providedOnSuccess && providedOnSuccess();
    openDeleteModal(entity.Id);
  };

  return entity.CanDelete && openDeleteModal && !disableDelete ? (
    <div className="h-100 Logout-button ">
      <div
        className="ar-logout-button d-flex align-items-center w-100 h-100 pb-2 pt-2 cursor-pointer"
        onClick={handleDeleteOpenModal}
      >
        <div className="ar-form-button-link-icon mr-1">
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
        <span>
          <FormattedMessage id={"DELETE"} />
        </span>
      </div>
    </div>
  ) : null;
};

const MultipleDeleteModalContent = ({ closeModal = () => {} }) => {
  const { schema } = useContext(ServerAwareContext);
  const endpoint = schema.getEndpoint().split("/");
  const SelectedArr = useContext(SelectedArrMsgContext);
  const { refetch } = useServerAwareState();
  const [deleteText, setDeleteText] = useState("");
  const conId = useConnectionId();
  const filteredArr = useMemo(() => {
    return SelectedArr.filter((e) => {
      const item = getEntity(schema, e.id);
      if (item.CanDelete) {
        return true;
      } else return false;
    });
  }, [SelectedArr, schema]);

  const handleTranslatedError = useHandleError();
  const [deletePost, { loading }] = useSpaceDelete(
    endpoint[endpoint.length - 1],
    null,
    {
      headers: {
        ConnectionID: conId
      },
      onSuccess: ({ data }) => {
        refetch();
        closeModal();
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );
  const intl = useIntl();
  const deleteTranslated = intl.formatMessage({ id: "DELETE" }).toUpperCase();

  const handleDelete = () => {
    deletePost(filteredArr.map((e) => e.id));
  };

  if (SelectedArr.length === 0) return null;

  const translationIds =
    SelectedArr.length > 0 ? TranslationDictionary[SelectedArr[0].type] : {};
  return (
    <div className="ar-delete-multiple  p-3">
      <div>
        <div className="text-black fw-bold fs-18 mb-2">
          <FormattedMessage id={translationIds.title} />
        </div>

        <div className="text-black fs-16 mb-3">
          {filteredArr.length === 0 ? (
            <FormattedMessage
              values={{ total: SelectedArr.length }}
              id={"DELETE_ALL_MODAL_SUB_TITLE_NONE_VALID"}
            />
          ) : filteredArr.length === SelectedArr.length ? (
            <FormattedMessage
              values={{ total: SelectedArr.length }}
              id={translationIds.subValid}
            />
          ) : (
            <FormattedMessage
              values={{ total: SelectedArr.length, valid: filteredArr.length }}
              id={translationIds.sub}
            />
          )}
        </div>
      </div>
      <ComponentArr arr={SelectedArr} />

      {filteredArr.length !== 0 ? (
        <div>
          <div className="fw-medium text-black fs-18 mb-3">
            <FormattedMessage id="DELETE_ALL_MODAL_CONFIRMATION" />
          </div>
          <FormInput
            className="mb-3 delete-multiple-modal-input"
            value={deleteText}
            placeholder={deleteTranslated}
            onChange={(e) => {
              const { value } = e.target;
              if (
                value.charAt(value.length - 1) ===
                deleteTranslated.charAt(value.length - 1)
              )
                setDeleteText(value);
            }}
          />
          <div className="d-flex justify-content-end align-items-center">
            <Button
              type="button"
              vType="link-danger"
              className="mr-3"
              disabled={loading}
              onClick={closeModal}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
            <LoadableButton
              isLoading={loading}
              type="button"
              disabled={deleteTranslated !== deleteText}
              vType="danger"
              onClick={() => {
                handleDelete();
              }}
            >
              <FormattedMessage id="DELETE" />
            </LoadableButton>
          </div>
        </div>
      ) : (
        <div>
          <Button
            type="button"
            vType="link-danger"
            className="mr-3"
            disabled={loading}
            onClick={closeModal}
          >
            <FormattedMessage id="CANCEL" />
          </Button>
        </div>
      )}
    </div>
  );
};

export const DeleteMultipleModalProvider = React.createContext();

const DeleteModalContainer = React.memo(
  ({ modalState, closeModal, handleItemSelection }) => {
    return (
      <Modal isOpen={modalState} onClose={closeModal}>
        <MultipleDeleteModalContent
          closeModal={closeModal}
          handleItemSelection={handleItemSelection}
        />
      </Modal>
    );
  }
);

export const useOpenDeleteModal = () => useContext(DeleteMultipleModalProvider);

const DeleteMultipleChildren = React.memo(({ children }) => {
  return children;
});

const DeleteMultipleModalHandler = ({ handleItemSelection, children }) => {
  const [modalState, setModalState] = useState(false);

  const openDeleteModal = useCallback(
    (id) => {
      if (typeof id === "undefined") {
        setModalState(true);
      } else {
        handleItemSelection && handleItemSelection([id]);

        setModalState(true);
      }
    },
    [handleItemSelection]
  );
  const closeDeleteModal = useCallback(() => {
    setModalState(false);
    handleItemSelection && handleItemSelection([]);
  }, [handleItemSelection]);

  return (
    <>
      <DeleteModalContainer
        handleItemSelection={handleItemSelection}
        modalState={modalState}
        closeModal={closeDeleteModal}
      />
      <DeleteMultipleModalProvider.Provider value={openDeleteModal}>
        <DeleteMultipleChildren>{children}</DeleteMultipleChildren>
      </DeleteMultipleModalProvider.Provider>
    </>
  );
};

export default DeleteMultipleModalHandler;
