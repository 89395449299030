import React from "react";
import Modal from "../Modal/Modal";
import classnames from "classnames";
import classes from "./SendContractRenovation.module.css";
import { FormattedMessage, useIntl } from "react-intl";
import { useTicket, useContact, useContract } from "../../Hooks/EntityHooks";
import Button from "../Button/Button";
import { useSpacePost } from "../../Helpers/IOClient";
import { spaceTicketsSchema } from "../../config/schema";
import { useToast } from "../Toast/ToastProvider";
import { handleError } from "../../Helpers/MiscHelper";
import LoadableButton from "../Button/LoadableButton";

const SendContractRenovationModal = ({ modalState, toggle, entity }) => {
  const contract = useContract(entity.Id);
  const contractContact = useContact(contract.Contact);
  const createToast = useToast();
  const intl = useIntl();

  const [post, { loading }] = useSpacePost(
    `Contracts/${entity.Id}/RenovationAlertEmail`,
    null,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "info",
          description: `${intl.formatMessage({
            id: "API_TICKET_EMAIL_REPORT_STARTED"
          })}`
        });
        toggle();
      },
      onError: ({ error }) => {
        let msgKey = error?.data;
        if (msgKey === "API_CONTRACT_NEED_EMAIL_CONTACT")
          createToast({
            pos: "tm",
            type: "danger",
            description: intl.formatMessage({
              id: "API_CONTRACT_NEED_EMAIL_CONTACT"
            }),
            title: `${intl.formatMessage({ id: "CONTRACT" })} nº ${entity.Id}`
          });
        else handleError(createToast, error);
        toggle();
      }
    }
  );

  const click = () => {
    post();
  };

  return (
    <Modal isOpen={modalState} onClose={toggle}>
      <div
        className={classnames(classes.SendNotificationModalContainer, "p-4")}
      >
        <div className="text-black fw-medium justify-content-center flex-1 fs-16 mb-3">
          <FormattedMessage id="SEND_CONTRACT_RENOVATION_WARNING" />
        </div>
        <div className="d-flex justify-content-between">
          <Button vType={"outline-danger"} onClick={toggle}>
            <FormattedMessage id="NO" />
          </Button>
          <LoadableButton
            isLoading={loading}
            vType={"primary-ghost"}
            onClick={() => {
              click();
            }}
          >
            <FormattedMessage id="YES" />
          </LoadableButton>
        </div>
      </div>
    </Modal>
  );
};

export default SendContractRenovationModal;
