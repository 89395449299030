import React, { Component } from "react";
import "./SpaceSelector.css";
import { withUser } from "../../Contexts/UserContext";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OnboardingHeader from "../Headers/OnboardingHeader";
import LoadableButton from "../../Components/Button/LoadableButton";
import { createSpace } from "../../Redux/Actions/Space";
import LabeledInput from "../../Components/Input/LabeledInput";
import {
  CountryDropdown,
  CurrencyDropdown,
  TimezoneDropdown
} from "../AdvancedMultiInputs";
import { ToastContext } from "../../Components/Toast/ToastProvider";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";
import OnboardingContainer from "../Headers/OnboardingContainer";
import { SpaceLanguagenDropdown } from "../Settings/Space/Space";
import { findWindows } from "windows-iana";
import { LogoutButton } from "../LogOutButton";

class SpaceCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: "",
        DomainName: "",
        AvailableCurrencies: []
      },
      errors: false
    };
  }
  static contextType = ToastContext;
  handleInputChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      },
      errors: false
    });
  };

  handleDomainChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        DomainName: e
      },
      errors: false
    });
  };

  handleLanguageChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        Language: e
      },
      errors: false
    });
  };
  handleCountryChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        Country: e
      },
      errors: false
    });
  };
  handleTimezoneChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        TimeZone: e
      },
      errors: false
    });
  };

  handleCurrencyChange = (v) => {
    this.setState({
      form: {
        ...this.state.form,
        AvailableCurrencies: v
      },
      errors: false
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { name, AvailableCurrencies, Language, Country, TimeZone } =
      this.state.form;
    const TimezoneWid = findWindows(TimeZone?.Id) || [];
    if (name.length === 0 || AvailableCurrencies.length === 0)
      return this.setState({
        errors: true
      });
    const DomainName = name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z1-9]/g, "")
      .toLowerCase()
      .substring(0, 28);
    this.props.createSpace(
      this.props.user,
      {
        name,
        DomainName,
        AvailableCurrencies,
        LanguageId: Language.Id,
        CountryId: Country,
        TimeZone: JSON.stringify({ Id: TimeZone?.Id, Wid: TimezoneWid[0] })
      },
      (accountSpace) => {
        // history.replace(`/s/${accountSpace.Space.Id}`);
        window.location.href = `/s/${accountSpace.Space.Id}/onboarding`;
        // window.location.reload(true);
      },
      this.context
    );
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (!prevProps.SpaceSetupPage.error && this.props.SpaceSetupPage.error) {
  //     const { status } = this.props.SpaceSetupPage.error;

  //     if (status === 409) {
  //       this.context();
  //     }
  //   }
  // }

  render() {
    const { errors, form } = this.state;
    const {
      name,
      AvailableCurrencies,
      // DomainName,
      TimeZone,
      Country,
      Language
    } = form;
    const { isProcessing } = this.props.SpaceSetupPage;

    return (
      <OnboardingContainer className="position-relative">
        <OnboardingHeader step={1} />
        <div
          className="w-auto disable-selection position-fixed"
          style={{ top: "10px", right: "15px", borderRadius: "7px" }}
        >
          <LogoutButton
            className="align-items-center ar-logout-button cursor-pointer d-flex w-auto"
            style={{ borderRadius: "7px" }}
          />
        </div>
        <div className="mx-4">
          <form
            onSubmit={this.handleSubmit}
            className="py-4 mx-auto ar-onboarding-basic-form"
          >
            <div className="fs-28 mb-2 text-black">
              <FormattedMessage id="SPACE_SETUP_TITLE" />
            </div>

            <div className="mb-4">
              <FormattedMessage id="SPACE_SETUP_DESCRIPTION" />
            </div>

            <div className="mb-3 fw-medium text-black">
              <FormattedMessage id={"SPACE_SETUP_DATA"} />
            </div>

            <LabeledInput
              text={
                <div className="d-flex align-items-center">
                  <span className="mr-2">
                    <FormattedMessage id="SPACE_SETUP_NAME_FIELD" />
                  </span>
                  <InformationComponent
                    text={
                      <FormattedMessage id="SPACE_SETUP_NAME_FIELD_INFO_DESC" />
                    }
                  />
                </div>
              }
              error={errors && name.length === 0}
              name="name"
              value={name}
              onChange={this.handleInputChange}
              compclassname="bg-white"
              className="mb-4"
            />

            {/* <LabeledInput
							text={
                <div className="d-flex align-items-center">
                  <span className="mr-2">
                    <FormattedMessage id="WORKSPACE_SUBDOMAIN" />
                  </span>
                  <InformationComponent
                    text={
                      <FormattedMessage id="WORKSPACE_SUBDOMAIN_DESC" />
                    }
                  />
                </div>
              }
              error={errors && DomainName.length === 0}
              name="DomainName"
              value={DomainName}
              InputComponent={SpaceDomainInput}
              onChange={this.handleDomainChange}
              compclassname="bg-white"
              className="mb-4"
            /> */}

            <LabeledInput
              text={<FormattedMessage id="CURRENCY" />}
              error={errors && AvailableCurrencies.length === 0}
              name="AvailableCurrencies"
              value={AvailableCurrencies}
              InputComponent={CurrencyDropdown}
              multiple
              onChange={this.handleCurrencyChange}
              compclassname="bg-white"
              className="mb-4"
            />

            <LabeledInput
              text={<FormattedMessage id={"LANGUAGES"} />}
              InputComponent={SpaceLanguagenDropdown}
              className="mb-3"
              name="Language"
              componentClassName="bg-white"
              compclassname="bg-white"
              value={Language}
              textInputClassname="bg-white"
              onChange={this.handleLanguageChange}
            />

            <LabeledInput
              text={<FormattedMessage id={"COUNTRY"} />}
              className="mb-3"
              compclassname="bg-white"
              componentClassName="bg-white"
              InputComponent={CountryDropdown}
              value={Country}
              onChange={this.handleCountryChange}
            />

            <LabeledInput
              disabled={!Country}
              text={<FormattedMessage id={"TIMEZONE"} />}
              className="mb-3"
              name="TimeZone"
              Country={Country}
              Language={Language}
              compclassname="bg-white"
              componentClassName="bg-white"
              InputComponent={TimezoneDropdown}
              value={TimeZone}
              onChange={this.handleTimezoneChange}
            />

            <LoadableButton
              isLoading={isProcessing}
              vType="secondary"
              className="w-100"
              disabled={
                errors ||
                !name ||
                AvailableCurrencies?.length === 0 ||
                !Language
              }
            >
              <FormattedMessage id="CONTINUE" />
            </LoadableButton>
          </form>
        </div>
      </OnboardingContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Spaces: state.Entities.Spaces,
    SpaceSetupPage: state.Pages.SpaceSetup
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSpace: (user, form, callback, createToast) =>
      dispatch(createSpace(user, form, callback, createToast))
  };
};

export default withRouter(
  withUser(connect(mapStateToProps, mapDispatchToProps)(SpaceCreator))
);
