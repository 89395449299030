import React, { useMemo } from "react";
import {
  TaskFormButton,
  InterventionFormButton,
  CallFormButton,
  TicketFormButton,
  DealFormButton
} from "../Form/FormButtons";

import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import { originTypes } from "../../Helpers/MiscHelper";
import BreadcrumbsPopup from "../../Components/Breadcrumbs/BreadcrumbsPopup";
// import { usePipelineStatus } from "../../Hooks/EntityHooks";
// import { GetStatusType } from "../../Components/Pipelines/PipelineProvider";
import { getEntity } from "../../Helpers/IOClient";
import {
  spacePipelineStatusSchema,
  spaceCallsSchema
} from "../../config/schema";
import { LabelTitle } from "../../Helpers/GridHelper";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { moduleTypes, canUserEditModule } from "../../Helpers/ModulesHelper";
import { CallClipboardButton } from "../../Helpers/HooksHelper";
import EntityReadButton from "../../Components/EntityRead/EntityReadButton";
import { FormattedMessage } from "react-intl";
import { DeleteButtonContextMenu } from "../../Components/CGrid/DeleteMultipleModal";
import { ColorFilterBars } from "../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { TimeTrackingGridFormButton } from "../TimeTracking/TimeTracking";
import { getInterventionTarget } from "../../Helpers/FormFormmaterHelper";
import {
  createContactFilter,
  createDepartmentFilter,
  createFollowingFilter,
  createIndirectClientFilter,
  createIndirectContractFilter,
  createIndirectDealFilter,
  createIndirectProjectFilter,
  createNotFollowingFilter,
  createOrganizationFilter,
  createOriginFilter,
  createPipelineStatusFilter,
  createProjectStatusFilter,
  createReadFilter,
  createTeamFilter,
  createUnreadFilter,
  createUserFilter,
  createUpgradedPipelineStatusFilter,
  createCallTagFilter
} from "../../Helpers/FilterHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignJustify,
  faCalendarAlt,
  faPhone,
  faUserTie
} from "@fortawesome/pro-light-svg-icons";
import { ConnectedAccountProfile } from "../../Components/Accounts/AccountProfile";
import { CallTargetDropdown } from "../AdvancedMultiInputs";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";

const callPipelineSettings = {
  field: "Call/PipelineId",
  pipelineStatusField: "Call/PipelineStatusId",
  type: PipelineTypeEnum.Call
};

export const defaultCallForm = ({ user, id }) => {
  return {
    CallTarget: null,
    ReceivedDate: new Date(),
    Contact: null,
    DepartmentTeam: null,
    ManagerAccount: user.Id,
    PipelineStatus: null,
    Description: "",
    Place: "",
    Locale: "",
    Region: "",
    Address: "",
    Domain: "",

    PostalCode: "",

    Manager: null,
    Country: undefined,
    Contacts: [],
    Industries: [],
    Followers: id ? [] : [user.Id],
    Image: null,
    Latitude: 0,
    Longitude: 0,
    // Duration: 300000, //5 mins
    Status: null
  };
};

export const useCallFilters = () => {
  return useMemo(() => {
    return [
      createUserFilter({
        propField: "ManagerAccount",
        titleId: "ASSIGNED_TO",
        field: "Assigned/Id",
        DescriptionComponent: ({ item, value }) => {
          return (
            <div>
              <FormattedMessage id={"ASSIGNED_TO"} />{" "}
              <ConnectedAccountProfile account={value} />
            </div>
          );
        }
      }),
      createIndirectClientFilter({ field: "DirectIndirectClient/Id" }),
      createContactFilter({
        icon: <FontAwesomeIcon icon={faUserTie} />,
        field: "Contact/Id"
      }),
      createIndirectContractFilter({
        field: "DirectIndirectContract/Id"
      }),
      createCallTagFilter({ requiresParams: true }),
      createUserFilter({
        propField: "Creator",
        titleId: "CREATED_BY",
        field: "Creator/Id",
        DescriptionComponent: ({ item, value }) => {
          return (
            <div>
              <FormattedMessage id={"CREATED_BY"} />{" "}
              <ConnectedAccountProfile account={value} />
            </div>
          );
        }
      }),
      createDepartmentFilter(),
      {
        propField: "NoTagDescription",
        icon: <FontAwesomeIcon icon={faAlignJustify} />,
        titleId: "DESCRIPTION",
        field: "Call/NoTagDescription",
        type: "string"
      },
      createOrganizationFilter({ field: "Company/Id" }),
      {
        propField: "ReceivedDate",
        titleId: "ENTRY",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        field: "Call/ReceivedDateNoHours",
        type: "dateTime"
      },
      {
        propField: "PipelineDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_PIPELINE_STATUS_DATE",
        field: "Call/PipelineDate",
        type: "dateTime"
      },
      {
        propField: "StatusDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_STATUS_DATE",
        field: "Call/StatusDate",
        type: "dateTime"
      },
      createTeamFilter(),
      createProjectStatusFilter({
        field: "PipelineStatus/Status",
        defaultFilters: [
          {
            field: "PipelineStatus/Status",
            operator: "eq",
            value: 1
          }
        ]
      }),
      // createPipelineStatusFilter({
      //   pipelineSettings: callPipelineSettings
      // }),
      createUpgradedPipelineStatusFilter({
        pipelineSettings: callPipelineSettings
      }),
      createIndirectDealFilter({
        field: "DirectIndirectDeal/Id"
      }),
      createReadFilter({
        baseField: "Call"
      }),
      {
        propField: "Id",
        icon: <FontAwesomeIcon icon={faPhone} />,
        titleId: "CALL_NR",
        field: "Call/Id",
        type: "string"
      },
      createUnreadFilter({
        baseField: "Call"
      }),
      createFollowingFilter({
        baseField: "Call"
      }),
      createNotFollowingFilter({
        baseField: "Call"
      }),
      createOriginFilter({
        baseFieldName: "Call",
        dropdownType: CallTargetDropdown
      }),

      createIndirectProjectFilter({
        field: "DirectIndirectProject/Id"
      })

      // createUnreadFilter({
      //   field: "Call/IsRead"
      // })
    ];
  }, []);
};

export const defaultCancelForm = () => {
  return {
    DepartmentTeams: null,
    Name: "",
    Organizations: null
  };
};

export const ValidateCallForm = (
  {
    CallTarget,
    Contact,
    ReceivedDate,
    DepartmentTeam,
    ManagerAccount,
    Description,
    Company,
    Pipeline,
    PipelineStatus,
    StatusDetailId,
    StatusDetail
  },
  addError
) => {
  const Status = getEntity(spacePipelineStatusSchema, PipelineStatus);

  if (!Company) addError("Company");
  if (!CallTarget) addError("CallTarget");
  // if (!Contact) addError("Contact");
  if (!ReceivedDate) addError("ReceivedDate");
  if (!Pipeline) addError("Pipeline");
  if (!PipelineStatus) addError("PipelineStatus");

  if (
    Status &&
    Status.Status === CallStatusTypes.canceled &&
    !StatusDetailId &&
    !StatusDetail
  )
    addError("StatusDetailId");
  if (!ManagerAccount)
    // if (!DepartmentTeam) addError("DepartmentTeam");
    addError("ManagerAccount");
  if (!Description) addError("Description");
};

export const convertCallEntityToForm = (entity) => {
  //;
  const {
    Department,
    Team,
    Project,
    Tags,
    Contract,
    Ticket,
    Task,
    ...entityRest
  } = entity;

  let DepartmentTeam;

  if (Department) {
    DepartmentTeam = {
      type: "Department",
      data: Department
    };
  } else if (Team) {
    DepartmentTeam = {
      type: "Team",
      data: Team
    };
  }

  const resolvedForm = {
    ...entityRest,
    DepartmentTeam,
    CallTarget: getInterventionTarget(entity)
  };

  if (Array.isArray(Tags) && Tags.length > 0) {
    const resolvedTags = [];
    for (const t of Tags) {
      resolvedTags.push({
        data: t,
        type: "CallTag"
      });
    }
    resolvedForm.Tags = resolvedTags;
  }

  return resolvedForm;
};
export const convertClientEntityToForm = (entity) => {
  //;
  const {
    Department,
    Team,
    Project,
    Tags,
    Contract,
    Ticket,
    Task,
    ...entityRest
  } = entity;

  let DepartmentTeam;

  if (Department) {
    DepartmentTeam = {
      type: "Department",
      data: Department
    };
  } else if (Team) {
    DepartmentTeam = {
      type: "Team",
      data: Team
    };
  }

  const resolvedForm = {
    ...entityRest,
    DepartmentTeam,
    CallTarget: getInterventionTarget(entity)
  };

  if (Array.isArray(Tags) && Tags.length > 0) {
    const resolvedTags = [];
    for (const t of Tags) {
      resolvedTags.push({
        data: t,
        type: "ClientTag"
      });
    }
    resolvedForm.Tags = resolvedTags;
  }

  return resolvedForm;
};

export const CallStatusTypes = {
  open: 1,
  canceled: 3,
  closed: 4,
  draft: 5
};

export const CallEntityButtons = ({ entity, skeleton, onSuccess }) => {
  const isPositiveStatus =
    entity.Status !== CallStatusTypes.canceled &&
    entity.Status !== CallStatusTypes.finished;

  return (
    <React.Fragment>
      <CallFormButton
        id={entity.Id}
        canEdit={entity.CanEdit}
        skeleton={skeleton}
        className="mr-2"
        onSuccess={onSuccess}
      />
      {isPositiveStatus && (
        <TicketFormButton
          alt
          skeleton={skeleton}
          formProps={{ callId: entity.Id }}
          className="mr-2"
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <TaskFormButton
          className="mr-2"
          alt
          skeleton={skeleton}
          formProps={{ callId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <InterventionFormButton
          className="mr-2"
          alt
          skeleton={skeleton}
          formProps={{ callId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <CallFormButton
          alt
          skeleton={skeleton}
          id={entity.Id}
          onSuccess={onSuccess}
          duplicate
        />
      )}
    </React.Fragment>
  );
};

export const CallEntityActionsChildren = ({
  entity,
  minimal,
  sidebar,
  detailsView
}) => {
  const isPositiveStatus =
    entity.Status !== CallStatusTypes.canceled &&
    entity.Status !== CallStatusTypes.finished;
  const accountSpace = useCurrentAccountSpace();
  return (
    <>
      {!sidebar && !detailsView && (
        <CallFormButton contextIcons id={entity.Id} />
      )}
      {!detailsView && <CallFormButton globalEntityView id={entity.Id} />}

      {(canUserEditModule(accountSpace, moduleTypes.tickets) ||
        canUserEditModule(accountSpace, moduleTypes.tasks) ||
        canUserEditModule(accountSpace, moduleTypes.interventions)) &&
        isPositiveStatus && (
          <LabelTitle title={<FormattedMessage id={"SERVICES"} />} />
        )}
      {canUserEditModule(accountSpace, moduleTypes.tickets) && (
        <TicketFormButton
          hasAccess={isPositiveStatus}
          formProps={{ callId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.tasks) && (
        <TaskFormButton
          hasAccess={isPositiveStatus}
          formProps={{ callId: entity.Id }}
        />
      )}

      {canUserEditModule(accountSpace, moduleTypes.interventions) && (
        <InterventionFormButton
          hasAccess={isPositiveStatus}
          formProps={{ callId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.interventions) && (
        <TimeTrackingGridFormButton
          origin={{
            type: "Call",
            data: entity.Id
          }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.deals) &&
        isPositiveStatus && (
          <LabelTitle title={<FormattedMessage id={"COMERCIAL"} />} />
        )}
      {canUserEditModule(accountSpace, moduleTypes.deals) && (
        <DealFormButton
          hasAccess={isPositiveStatus}
          formProps={{ callId: entity.Id }}
        />
      )}
      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      {!sidebar && entity.CanEdit && (
        <CallFormButton id={entity.Id} canEdit={entity.CanEdit} contextIcons />
      )}
      <CallFormButton id={entity.Id} duplicate />
      <CallClipboardButton id={entity.Id} />
      <EntityReadButton value={entity} schema={spaceCallsSchema} />
      <DeleteButtonContextMenu entity={entity} />
    </>
  );
};

export const CallEntityActionsButton = ({ minimal, ...props }) => {
  return (
    <EntityPopup minimal={minimal}>
      <CallEntityActionsChildren minimal={minimal} {...props} />
    </EntityPopup>
  );
};

export const CallEntityGridButtons = ({
  entity,
  hideBreadCrumbs,
  minimal,
  noColors
}) => {
  return (
    <div className="d-flex align-items-center">
      {!hideBreadCrumbs && (
        <BreadcrumbsPopup
          className="mr-2 fs-14 d-inline-flex text-primary"
          originType={originTypes.call}
          minimal
          id={entity.Id}
        />
      )}
      <CallFormButton
        minimal={minimal}
        id={entity.Id}
        canEdit={entity.CanEdit}
        className="mr-2"
      />
      <CallEntityActionsButton entity={entity} minimal={minimal} />
      {!noColors && <ColorFilterBars entity={entity} />}
    </div>
  );
};
