import "./style.css";

import React, {  useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage } from "react-intl";

const calculateStrengh = (password) => {
  const isValid =
    password.length >= 8 &&
    /[a-z]/.test(password) &&
    /[A-Z]/.test(password);
  if (!isValid) return 1;
  const nums = (password.match(/[\d]/g)?.length || 0) >= 2;

  const lowerLetters = (password.match(/[a-z]/g)?.length || 0) >= 2;

  const upperLetters = (password.match(/[A-Z]/g)?.length || 0) >= 2;
  const hasSymbols = (password.match(/(?![\w])[\S]/g)?.length || 0) >= 1;

  const has16chars = password.length >= 16;
	let score = isValid + nums + hasSymbols + lowerLetters + upperLetters + has16chars;
  return score > 5 ? 5 : score
};

const ReactPasswordStrength = ({ Password, ...props }) => {
  const score = useMemo(() => {
    return calculateStrengh(Password);
  }, [Password]);

  let strengthDesc = null;
  switch (score) {
    case 1:
      strengthDesc = (
        <div className="d-flex justify-content-start">
          <div>
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          </div>
          <div>
            <FormattedMessage id="PASSWORD_STRENGH_WEAK" />
          </div>
        </div>
      );
      break;
    case 2:
      strengthDesc = <FormattedMessage id="PASSWORD_STRENGH_MODERATE" />;
      break;
    case 3:
      strengthDesc = <FormattedMessage id="PASSWORD_STRENGH_STRONG" />;
      break;
    case 4:
      strengthDesc = <FormattedMessage id="PASSWORD_STRENGH_STRONG" />;
      break;
    case 5:
      strengthDesc = <FormattedMessage id="PASSWORD_STRENGH_VERY_STRONG" />;
      break;
    default:
      break;
  }

  const wrapperClasses = [
    "ReactPasswordStrength",
    Password.length > 0 ? `is-strength-${score}` : ""
  ];

	if(Password?.length === 0) return null

  return (
    <div className={wrapperClasses.join(" ")} {...props}>
      <div className={`ReactPasswordStrength-strength-bar`} />
      <div className={`ReactPasswordStrength-strength-desc`}>
        {strengthDesc}
      </div>
    </div>
  );
};

export default ReactPasswordStrength;

// export default class ReactPasswordStrength extends Component {
//   static defaultProps = {
//     changeCallback: null,
//     className: "",
//     defaultValue: "",
//     minLength: 5,
//     minScore: 2,
//     namespaceClassName: "ReactPasswordStrength",
//     scoreWords: ["weak", "okay", "good", "strong"],
//     tooShortWord: "not valid",
//     userInputs: []
//   };

//   state = {
//     score: 0,
//     isValid: false,
//     password: this.props.Password || ""
//   };

//   componentDidMount() {
//     const { defaultValue } = this.props;

//     if (defaultValue.length > 0) {
//       this.setState({ password: defaultValue }, this.handleChange);
//     }
//   }

//   componentDidUpdate(prevProps, prevState) {
//     const { Password: oldPassword } = prevProps;
//     const { Password: newPassword } = this.props;
//     if (oldPassword !== newPassword) this.handleChange();
//   }

//   handleChange = () => {
//     const { changeCallback, minScore, userInputs, minLength } = this.props;
//     const { Password: password } = this.props;

//     let score = 0;
//     let result = null;

//     // always sets a zero score when min length requirement is not met
//     // avoids unnecessary zxcvbn computations (CPU intensive)

//     result = calculateStrengh(password, userInputs);
//     score = result;

//     this.setState(
//       {
//         isValid: score >= minScore,
//         password,
//         score
//       },
//       () => {
//         if (changeCallback !== null) {
//           changeCallback(this.state, result);
//         }
//       }
//     );
//   };

//   render() {
//     const { score, password, isValid } = this.state;
//     const {
//       className,
//       inputProps,
//       minLength,
//       namespaceClassName,
//       scoreWords,
//       style,
//       tooShortWord
//     } = this.props;

//     const inputClasses = [`${namespaceClassName}-input`];
//     const wrapperClasses = [
//       namespaceClassName,
//       className ? className : "",
//       password.length > 0 ? `is-strength-${score}` : ""
//     ];

//     if (isValid === true) {
//       inputClasses.push("is-password-valid");
//     } else if (password.length > 0) {
//       inputClasses.push("is-password-invalid");
//     }

//     if (inputProps && inputProps.className) {
//       inputClasses.push(inputProps.className);
//     }

//     switch (score) {
//       case 1:
//         strengthDesc = (
//           <div className="d-flex justify-content-start">
//             <div>
//               <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
//             </div>
//             <div>
//               <FormattedMessage id="PASSWORD_STRENGH_WEAK" />
//             </div>
//           </div>
//         );
//         break;
//       case 2:
//         strengthDesc = <FormattedMessage id="PASSWORD_STRENGH_MODERATE" />;
//         break;
//       case 3:
//         strengthDesc = <FormattedMessage id="PASSWORD_STRENGH_STRONG" />;
//         break;
//       case 4:
//         strengthDesc = <FormattedMessage id="PASSWORD_STRENGH_STRONG" />;
//         break;
//       case 5:
//         strengthDesc = <FormattedMessage id="PASSWORD_STRENGH_VERY_STRONG" />;
//         break;

//       default:
//         break;
//     }

//     return (
//       <div className={wrapperClasses.join(" ")} style={style}>
//         <div className={`${namespaceClassName}-strength-bar`} />
//         <div className={`${namespaceClassName}-strength-desc`}>
//           {strengthDesc}
//         </div>
//       </div>
//     );
//   }
// }
