import api from "../../../api";
import React from 'react'
import {
  accountSummarySpaceSchema
} from "../../../config/schema";
import { getAccountSpaceIdFromMapper } from "../../Reducer/AccountSpaceMapperReducer";
import { normalize } from "../../../Helpers/SchemaHelper";
import { FormattedMessage } from "react-intl";

// export const FETCH_SPACE_USERS = "FETCH_SPACE_USERS";
// export const FETCH_SPACE_USERS_REQUEST = "FETCH_SPACE_USERS_REQUEST";
// export const FETCH_SPACE_USERS_SUCCESS = "FETCH_SPACE_USERS_SUCCESS";
// export const FETCH_SPACE_USERS_ERROR = "FETCH_SPACE_USERS_ERROR";

// export const getSpaceUsers = (spaceId, params) => (dispatch, getState) => {
//   dispatch({ type: FETCH_SPACE_USERS_REQUEST, spaceId });
//   getOData(`spaces/${spaceId}/users`, params)
//     .then(response => {
//       for (const user of response.results) {
//         user.AccountSpace.Modules = getFormmatedModules(
//           user.AccountSpace.Modules
//         );
//       }
//       const space = getState().Entities.Spaces[spaceId];
//       let normalizedData = normalize(response.results, [
//         getspaceUserSchema(space)
//       ]);

//       dispatch({
//         type: FETCH_SPACE_USERS_SUCCESS,
//         spaceId,
//         response: normalizedData
//       });
//     })
//     .catch(error => {
//       dispatch({ type: FETCH_SPACE_USERS_ERROR, spaceId, error });
//     });
// };

export const UPDATE_SPACE_USERS_MODULES_REQUEST =
  "UPDATE_SPACE_USERS_MODULES_REQUEST";
export const UPDATE_SPACE_USERS_MODULES_SUCCESS =
  "UPDATE_SPACE_USERS_MODULES_SUCCESS";
export const UPDATE_SPACE_USERS_MODULES_ERROR =
  "UPDATE_SPACE_USERS_MODULES_ERROR";

export const updateSpaceUserModules = (spaceId, userId, newModules) => (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_SPACE_USERS_MODULES_REQUEST, spaceId });

  var mapper = getState().AccountSpaceMapper;

  let accountSpaceId = getAccountSpaceIdFromMapper(mapper, spaceId, userId);

  api
    .post(`spaces/${spaceId}/users/${userId}/modules`, newModules)
    .then(response => {
      dispatch({
        type: UPDATE_SPACE_USERS_MODULES_SUCCESS,
        spaceId,
        userId,
        accountSpaceId,
        response: newModules
      });
    })
    .catch(error => {
      dispatch({
        type: UPDATE_SPACE_USERS_MODULES_ERROR,
        userId,
        spaceId,
        accountSpaceId,
        error
      });
    });
};

export const REMOVE_SETTINGS_PERMISSIONS_ERROR =
  "REMOVE_SETTINGS_PERMISSIONS_ERROR";
export const removeSettingsPermissionsError = () => {
  return { type: REMOVE_SETTINGS_PERMISSIONS_ERROR };
};

export const CREATE_SPACE_REQUEST = "CREATE_SPACE_REQUEST";
export const CREATE_SPACE_SUCCESS = "CREATE_SPACE_SUCCESS";
export const CREATE_SPACE_ERROR = "CREATE_SPACE_ERROR";

export const ASSIGN_CURRENT_SPACE = "ASSIGN_CURRENT_SPACE";

export const createSpace = (user, form, callback, createToast) => (dispatch, getState) => {
  dispatch({ type: CREATE_SPACE_REQUEST });
  api
    .post(`spaces`, form)
    .then(response => {
      
      var normalizedData = normalize({...response}, accountSummarySpaceSchema);

      dispatch({
        type: CREATE_SPACE_SUCCESS,
        response: normalizedData,
        spaceId: response.Space.Id,
        user
      });
      callback(response);
    })
    .catch(error => {
			createToast({
				pos: "tm",
				type: "danger",
				description: <FormattedMessage id="SPACE_UPDATE_DOMAIN_CONFLICT" />
				// title: `Erro`,
			})
      dispatch({ type: CREATE_SPACE_ERROR, error });
    });
};

export const REMOVE_SPACE_SETUP_ERROR = "REMOVE_SETTINGS_PERMISSIONS_ERROR";
export const removeSpaceSetupError = () => {
  return { type: REMOVE_SPACE_SETUP_ERROR };
};


