import React from "react";
import {
  useAccount,
  useDeal,
  useTask,
  useTicket
} from "../../../../Hooks/EntityHooks";
import {
  NotificationClientProfile,
  GeneralNotificationItem,
  buildNotificationEndpoint
} from "./NotificationHelper";
import { FormattedMessage } from "react-intl";
import TaskOrigin from "../../../Origin/TaskOrigin";
import TicketOrigin from "../../../Origin/TicketOrigin";
import DealOrigin from "../../../Origin/DealOrigin";

const NotificationUserAssignedTask = ({ FromAccount, ToAccount, task }) => {
  const resolvedTask = task;

  if (!resolvedTask) return null;

  const { Client, IndirectClient } = resolvedTask;

  const isSameUser = FromAccount.Id === ToAccount.Id;

  let text;

  if (isSameUser)
    text = (
      <div className="fs-14">
        <FormattedMessage id={"THE_USER"} /> <b>{FromAccount.Name}</b>{" "}
        <FormattedMessage id={"TASK_ASSIGNED_TO"} /> <b>{resolvedTask.Name}</b>{" "}
        <FormattedMessage id={"FOR_HIMSELF"} />
        <br />
        <NotificationClientProfile client={Client || IndirectClient} />
      </div>
    );
  else {
    text = (
      <div className="fs-14">
        <FormattedMessage id={"THE_USER"} /> <b>{FromAccount.Name}</b>{" "}
        <FormattedMessage id={"TASK_ASSIGNED_TO"} /> <b>{resolvedTask.Name}</b>{" "}
        <FormattedMessage id={"FOR_USER"} /> <b>{ToAccount.Name}</b>
        <br />
        <NotificationClientProfile client={Client || IndirectClient} />
      </div>
    );
  }

  return (
    <GeneralNotificationItem
      redirectUrl={buildNotificationEndpoint("tasks", "Task/Id", task.Id)}
      OriginComponent={<TaskOrigin task={task} />}
      body={<div className="text-black">{text}</div>}
    />
  );
};

const NotificationUserAssignedTicket = ({ FromAccount, ToAccount, ticket }) => {
  const resolvedTicket = ticket;

  if (!resolvedTicket) return null;

  const { Client, IndirectClient } = resolvedTicket;

  const isSameUser = FromAccount.Id === ToAccount.Id;

  let text;

  if (isSameUser)
    text = (
      <div className="fs-14">
        <FormattedMessage id={"THE_USER"} /> <b>{FromAccount.Name}</b>{" "}
        <FormattedMessage id={"CASE_ASSIGNED_TO"} />{" "}
        <b>{resolvedTicket.Name}</b> <FormattedMessage id={"FOR_HIMSELF"} />
        <br />
        <NotificationClientProfile client={Client || IndirectClient} />
      </div>
    );
  else {
    text = (
      <div className="fs-14">
        <FormattedMessage id={"THE_USER"} /> <b>{FromAccount.Name}</b>{" "}
        <FormattedMessage id={"CASE_ASSIGNED_TO"} />{" "}
        <b>{resolvedTicket.Name}</b> <FormattedMessage id={"FOR_USER"} />{" "}
        <b>{ToAccount.Name}</b>
        <br />
        <NotificationClientProfile client={Client || IndirectClient} />
      </div>
    );
  }

  return (
    <GeneralNotificationItem
      redirectUrl={buildNotificationEndpoint("tickets", "Project/Id", ticket.Id)}
      OriginComponent={<TicketOrigin ticket={ticket} />}
      body={<div className="text-black">{text}</div>}
    />
  );
};

const NotificationUserAssignedDeal = ({ FromAccount, ToAccount, deal }) => {
  const resolvedDeal = deal;

  if (!resolvedDeal) return null;

  const { Client, IndirectClient } = resolvedDeal;

  const isSameUser = FromAccount.Id === ToAccount.Id;

  let text;

  if (isSameUser)
    text = (
      <div className="fs-14">
        <FormattedMessage id={"THE_USER"} /> <b>{FromAccount.Name}</b>{" "}
        <FormattedMessage id={"ASSIGNED_THE_DEAL"} /> <b>{resolvedDeal.Name}</b>{" "}
        <FormattedMessage id={"FOR_HIMSELF"} />
        <br />
        <NotificationClientProfile client={Client || IndirectClient} />
      </div>
    );
  else {
    text = (
      <div className="fs-14">
        <FormattedMessage id={"THE_USER"} /> <b>{FromAccount.Name}</b>{" "}
        <FormattedMessage id={"ASSIGNED_THE_DEAL"} /> <b>{resolvedDeal.Name}</b>{" "}
        <FormattedMessage id={"FOR_USER"} /> <b>{ToAccount.Name}</b>
        <br />
        <NotificationClientProfile client={Client || IndirectClient} />
      </div>
    );
  }

  return (
    <GeneralNotificationItem
      redirectUrl={buildNotificationEndpoint("deals", "Deal/Id", deal.Id)}
      OriginComponent={<DealOrigin deal={deal} />}
      body={<div className="text-black">{text}</div>}
    />
  );
};

const NotificationUserAssignedItem = ({ value }) => {
  const { FromAccount, ToAccount, Task, Ticket, Deal } = value;

  const resolvedToAccount = ToAccount;
  const resolvedFromAccount = FromAccount;

  if (!resolvedToAccount || !resolvedFromAccount) return null;

  const props = {
    FromAccount: resolvedFromAccount,
    ToAccount: resolvedToAccount
  };

  if (Task) return <NotificationUserAssignedTask task={Task} {...props} />;
  else if (Ticket)
    return <NotificationUserAssignedTicket ticket={Ticket} {...props} />;
  else if (Deal) return <NotificationUserAssignedDeal deal={Deal} {...props} />;
  else return null;
};

export default NotificationUserAssignedItem;
