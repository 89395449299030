import React, { useState, useContext } from "react";
import { getFilesAsync } from "../../../Components/Files/Files";
import {
  useMessagePost,
  useMessageTextSync
} from "../../../Components/EntitySummaries/EntitySummaryMessages/MessageSender/MessageSender";
import { createFormData, handleError } from "../../../Helpers/MiscHelper";
import ClipboardProvider from "../../../Components/ClipbordProvider/ClipboardProvider";
import HTMLInput from "../../../Components/HTMLInput/HTMLInput";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UpploadReact } from "../../../Components/FileUploader/Upploader";
import { faPaperclip, faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import MessageSenderFiles from "../../../Components/EntitySummaries/EntitySummaryMessages/MessageSender/MessageSenderFiles";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { useIntl, FormattedMessage } from "react-intl";
import LoadableButton from "../../../Components/Button/LoadableButton";
import { connect } from "react-redux";
import { NoteCountEmailConversation } from "../../../Redux/Reducer/Entities";
import { SetAddedNewMessageManualyContext } from "../../../Components/EntitySummaries/EntitySummaryMessages/MessagesHelper";

const EmailNoteInputInput = ({
  inputRef,
  roomType,
  updateNoteCounter,
  entityId,
  EmailNote,
  disabled,
  onPasteEvent,
  placeholder,
  addMessage,
  onChangeReplyMessageId
}) => {
  // const dragging = useDragDropEffect();
  const [text, setText] = useState("");

  const setNewAddedItems = useContext(SetAddedNewMessageManualyContext);

  // useMessageTextSync(text, roomType, entityId);

  const [dragging, setDragging] = useState(false);

  const handleDrop = async (e) => {
    setDragging(false);
    e.preventDefault();
    const files = e.dataTransfer
      ? await getFilesAsync(e.dataTransfer)
      : e.currentTarget.files;
    setFiles((f) => [...f, ...files]);
  };

  const [files, setFiles] = useState([]);
  const HandleExtraUploads = (f) => {
    setFiles((e) => [...e, f]);
  };

  const [post, { loading }] = useMessagePost(null, {
    onSuccess: ({ data }) => {
      onChangeReplyMessageId();
      addMessage({ Note: data });
      setText("");
      setFiles([]);
      updateNoteCounter(entityId);
      setNewAddedItems(true);
      createToast({
        pos: "tm",
        type: "success",
        // description: intl.formatMessage({ id: "CREATED_SUCCEFULLY" }),
        title: intl.formatMessage({ id: "CREATED_SUCCEFULLY" })
      });
    },
    onError: ({ error }) => {
      handleError(createToast, error);
    }
  });
  const createToast = useToast();
  const intl = useIntl();
  const handleSubmit = (e) => {
    e && e.preventDefault();
    if (text === "") {
      createToast({
        pos: "tm",
        type: "danger",
        description: intl.formatMessage({ id: "TEXT_FIELD_IS_REQUIRED" }),
        title: `Erro`
      });
      return;
    }
    if (text.length > 4000) {
      createToast({
        pos: "tm",
        type: "danger",
        description: intl.formatMessage({ id: "API_DESCRIPTION_TOLARGE" }),
        title: `Erro`
      });
      return;
    }
    const data = createFormData(
      {
        // noteId: replyMessageId,
        Description: text,
        [`EmailConversationId`]: entityId,
        type: 2
      },
      files
    );
    post(data);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <div className="d-flex flex-column h-100">
      <ClipboardProvider>
        <HTMLInput
          // style={{ minHeight: 233, background: "#f5f8fa" }}
          onDragEnter={() => setDragging(true)}
          onDragLeave={() => setDragging(false)}
          onDrop={handleDrop}
          // onPasteEvent={onPasteEvent}
          ref={inputRef}
          // onKeyDown={handleKeyDown}
          disabled={disabled}
          placeholder={placeholder}
          className={classnames(
            "ar-entity-summary-message-textarea mt-3 flex-1 h-100",
            {
              dragging
            }
          )}
          // disabled={loading}
          value={text}
          onChange={handleChange}
        />
        {files && files.length > 0 && (
          <div
            style={{ maxHeight: 96 }}
            className="mb-1 mt-2 flex-0-0-auto of-y-auto of-x-hidden"
          >
            <MessageSenderFiles files={files} setFiles={setFiles} />
          </div>
        )}
        <div className="d-flex align-items-center justify-content-between mt-2">
          <div className="d-flex align-items-center">
            <label className="ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button">
              <FontAwesomeIcon icon={faPaperclip} />

              <input
                className="ar-fileUploader__inputFile"
                type="file"
                multiple
                onChange={handleDrop}
              />
            </label>
            <div className="">
              <UpploadReact onFileDone={HandleExtraUploads} Chat={true} />
            </div>
          </div>
          <LoadableButton
            isLoading={loading}
            disabled={text === ""}
            className="float-right"
            onClick={handleSubmit}
          >
            <FormattedMessage id="SEND" />
            <FontAwesomeIcon className="ml-2" icon={faPaperPlane} />
          </LoadableButton>
        </div>
      </ClipboardProvider>
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateNoteCounter: (emailCovnersationId) => {
      dispatch(NoteCountEmailConversation(emailCovnersationId));
    }
  };
};

export default connect(null, mapDispatchToProps)(EmailNoteInputInput);
