import React, { useContext, useState, useMemo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import { useSpace } from "../../Contexts/SpaceContext";
import { client, getEntity, useSpaceQuery } from "../../Helpers/IOClient";
import {
  UserDropdown,
  ContactDropdown,
  ContractTypeDropdown,
  DepartmentDropdown,
  TeamCollabDropdown,
  OrganizationDropdown,
  ContractTargetDropdown,
  contractsCancellationReasonsDropdown,
  InboundEmailDropdown,
  ContractTagDropdown
} from "../AdvancedMultiInputs";
import { useContract, usePipelineStatus } from "../../Hooks/EntityHooks";
import {
  ContractPipelineDropdown,
  ContractStatusPipelineDropdown
} from "../AdvancedMultiInputs/PipelineDropdowns";
import { ContractStatusTypes } from "../../Components/Contract/ContractProfile";
import {
  updateCollabFollowers,
  updateManagerFollowers,
  useCanEditFollowers,
  useDropdownFollowers
} from "../../Components/EntityFollow/EntityFollowHelper";
import { SidebarAutomationContext } from "../../Components/Sidebar/SidebarV2Automation";
import HTMLInput from "../../Components/HTMLInput/HTMLInput";
import ArDatePicker from "../../Components/DatePicker/ArDatePicker";
import DealCostSelector from "../Deals/DealCostSelector";
import MinuteInput from "../../Components/Input/MinuteInput";
import HourInput from "../../Components/Input/HourInput";
import NumberInput from "../../Components/Input/NumberInput";
import { CovenantPeriodicity } from "../../Components/CovenantSelector/CovenantSelector";
import ClipboardProvider from "../../Components/ClipbordProvider/ClipboardProvider";
import TimeTablePicker from "../../Components/TimetablePicker/TimeTablePicker";
import FileUploader from "../../Components/FileUploader/FileUploader";
import CreatedBy from "../../Components/CreatedByComponent/CreatedBy";
import FileAssociations from "../../Components/FileUploader/FileAssociations";
import DocTyper from "../../Components/DocTyper/DocTyper";
import {
  hasContractFinancialPermission
  // isAdmin
} from "../../Helpers/ModulesHelper";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { originTypes } from "../../Helpers/MiscHelper";
import {
  spaceDealsSchema,
  spaceEmailReceptionSchema
} from "../../config/schema";
import Checkbox from "../../Components/Checkbox/Checkbox";
import Switch from "../../Components/Switch/Switch";

function dateCompare(date1, date2) {
  return new Date(date2) > new Date(date1);
}

const ContractFormContent = ({
  formState,
  organizations,
  pipelines,
  id,
  currencies,
  canEdit
}) => {
  const { form, handleChange } = formState;
  const {
    ContractTarget,
    Description,
    Contact,
    EndDate,
    Id,
    EstimatedCost,
    SalesOpportunity,
    Manager,
    TicketLimit,
    // ContractType,
    InternalDescription,
    Covenant,
    Files,
    Deslocations,
    AssociatedFiles,
    AssignedAssociations,
    TransferInboundEmail,
    Creator,
    Pipeline,
    PipelineStatus,
    SupportEmail,
    InboundEmail,
    CurrentInboundEmails,
    InboundEmails,
    Company,
    StatusDetailDescription,
    BeginDate,
    StatusDetail,
    // Comments,
    CreationDate,
    TeamCollab,
    Followers,
    Client,
    IndirectClient
  } = form;
  const PipelineId = Pipeline;
  const PipelineStatusId = PipelineStatus;
  const PipelieStatusObj = usePipelineStatus(PipelineStatusId);

  let resolvedEmailList = null;

  if (Array.isArray(InboundEmails) && InboundEmails?.length > 0) {
    resolvedEmailList = InboundEmails.map((e) => {
      const resolEmailObject = getEntity(spaceEmailReceptionSchema, e);
      return { Id: resolEmailObject.Id, Email: resolEmailObject.Email };
    });
  }

  const currentAccountSpace = useCurrentAccountSpace();
  const PrivateFinancialPermission =
    hasContractFinancialPermission(currentAccountSpace);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value
    });
  };

  const handleCaseLimitChanges = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value ? Math.round(parseFloat(value)) : ""
    });
  };

  const handleTargetChange = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value
      // Contact: null
    });
  };

  const contactValidationURL = useMemo(() => {
    if (!Contact) return null;

    let projectId, ticketId, taskId, contractId, clientId, callId, dealId;

    if (ContractTarget) {
      switch (ContractTarget.type) {
        case originTypes.client:
          clientId = ContractTarget.data;
          break;

        case originTypes.deals:
          dealId = ContractTarget.data;
          break;

        default:
          break;
      }
    }

    const deal = getEntity(spaceDealsSchema, dealId);

    const Client =
      clientId ||
      (deal && (deal.Client || deal.IndirectClient)) ||
      IndirectClient;

    return `Query/Contacts/${Client}/${Contact}`;
  }, [Contact, ContractTarget, IndirectClient]);

  const { loading: contactaValidation } = useSpaceQuery(
    contactValidationURL,
    null,
    {
      onSuccess: ({ data }) => {
        if (!Boolean(data)) {
          handleChange({
            Contact: null
          });
        }
      },
      onError: (err) => {
        handleChange({
          Contact: null
        });
      }
    }
  );

  useEffect(() => {
    if (
      !Pipeline &&
      pipelines &&
      pipelines.length > 0 &&
      pipelines.length === 1
    ) {
      handleChange({
        Pipeline: pipelines[0]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const space = useSpace();
  const [loadingValidation, setLoadingValidation] = useState(false);

  const HandleContractChanges = async (e) => {
    const { value } = e.target;

    handleChange({
      ContractType: value
    });
    setLoadingValidation(true);
    var { data } = await client.get(
      `spaces/${space.Id}/ContractTypes/${value}/Schedule`
    );
    setLoadingValidation(false);
    handleChange({
      IntervalMinimum: data?.IntervalMinimum,
      RoundingMargin: data?.RoundingMargin,
      Schedules: data?.Schedules || []
    });
  };

  const handleCompanyChange = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value,
      ContractType: undefined
    });
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;

    if (name === "BeginDate") {
      if (dateCompare(EndDate, value)) {
        let endValue = new Date(value);
        endValue.setTime(endValue.getTime() + 60 * 60 * 1000);
        handleChange({
          BeginDate: value,
          EndDate: endValue
        });
        return;
      }
    }

    handleChange({
      [name]: value
    });
  };

  const handleAssociationsChanges = (value) => {
    handleChange((oldF) => {
      const newF = { ...oldF };
      const newAssignedAssociations = Array.isArray(newF.AssignedAssociations)
        ? [...newF.AssignedAssociations]
        : [];
      const index = newAssignedAssociations.findIndex((e) => e === value);
      if (index !== -1) {
        newAssignedAssociations.splice(index, 1);
      } else newAssignedAssociations.push(value);
      newF.AssignedAssociations = newAssignedAssociations;
      return newF;
    });
  };

  const handlePipelineChanges = (e) => {
    const { value } = e.target;
    if (PipelineId === value) return;
    handleChange({
      Pipeline: value,
      PipelineStatus: ""
    });
  };

  useEffect(() => {
    if (StatusDetail && PipelieStatusObj?.Status !== 3) {
      handleChange({
        StatusDetail: null
      });
    }
  }, [PipelieStatusObj, StatusDetail, handleChange]);

  const remainingAccounts = useDropdownFollowers(TeamCollab);
  // const followers = useJoinedFollowerAccounts(Manager, remainingAccounts);
  // const possibleFollowers = useAvailableFollowers(Creator, followers);
  const PipelineStatusObj = usePipelineStatus(PipelineStatus);
  const entity = useContract(id);
  const currentManager = entity ? entity.Manager : Creator;
  const canEditFollowers = useCanEditFollowers(Creator, currentManager);

  const handleTeamCollabChange = ({ target: { value } }) => {
    handleChange({
      TeamCollab: value,
      Followers: updateCollabFollowers(Followers, TeamCollab, value, [Manager])
    });
  };

  const handleManagerChange = ({ target: { value } }) => {
    handleChange({
      Manager: value,
      Followers: updateManagerFollowers(Followers, Manager, value, [
        remainingAccounts
      ])
    });
  };
  const Automation = useContext(SidebarAutomationContext);

  const handleCheckChanges = ({ target: { name, checked } }) => {
    handleChange((oldF) => {
      return {
        ...oldF,
        [name]: checked
      };
    });
  };

  return (
    <>
      {Automation && (
        <FormInput
          textId="CREATED_BY"
          name="Creator"
          inputType={UserDropdown}
          className="mb-3"
        />
      )}

      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"ASSOCIATE_CONTRACT_TO"} />
      </div>

      {(Automation || (organizations && organizations.length > 1)) && (
        <FormInput
          name="Company"
          textId="COMPANY"
          inputType={OrganizationDropdown}
          onChange={handleCompanyChange}
          className="mb-3"
        />
      )}

      <FormInput
        textId="ASSIGN_TO"
        className="mb-3"
        name={"ContractTarget"}
        inputType={ContractTargetDropdown}
        onChange={handleTargetChange}
        allClients={false}
      />

      <FormInput
        className="mb-3"
        name="Contact"
        textId="CONTACT"
        inputType={ContactDropdown}
        disabled={contactaValidation}
        IndirectClient={IndirectClient || Client}
        value={Contact}
        originTarget={ContractTarget}
        onChange={handleChanges}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="CLASSIFICATION" />
      </div>

      <FormInput
        name="Pipeline"
        className="mb-3"
        value={PipelineId}
        disabled={pipelines && pipelines.length > 0 && pipelines.length === 1}
        textId="PIPELINE"
        inputType={ContractPipelineDropdown}
        onChange={handlePipelineChanges}
      />

      <FormInput
        className="mb-3"
        disabled={!PipelineId}
        textId="STATUS"
        name="PipelineStatus"
        Status={!id && ContractStatusTypes.open}
        value={PipelineStatusId}
        onLoad={({ data }) => {
          handleChange(
            {
              PipelineStatus: data[0],
              StatusDetail: null,
              StatusDetailDescription: null
            },
            true
          );
        }}
        filterBlock={true}
        inputType={ContractStatusPipelineDropdown}
        pipelineId={PipelineId}
        onChange={handleChanges}
      />

      {id &&
        PipelineStatus &&
        PipelineStatusObj.Status === ContractStatusTypes.canceled && (
          <FormInput
            name="StatusDetail"
            value={StatusDetail}
            textId="CANCELATION_REASON"
            inputType={contractsCancellationReasonsDropdown}
            company={Company}
            //   value={
            //     StatusDetailId
            //       ? StatusDetailId
            //       : StatusDetail
            //       ? StatusDetail
            //       : null
            //   }
            onChange={handleChanges}
            className="mb-3"
          />
        )}
      {id &&
        PipelineStatus &&
        PipelineStatusObj.Status === ContractStatusTypes.canceled && (
          <FormInput
            name="StatusDetailDescription"
            textId="CANCELATION_DESCRIPTION"
            inputType={HTMLInput}
            value={StatusDetailDescription ? StatusDetailDescription : ""}
            onChange={handleChanges}
            className="mb-3"
          />
        )}

      <FormInput
        className="mb-3"
        name="Tags"
        textId="CONTRACT_TAGS"
        pipelineId={Pipeline}
        // getKey={(e) => {
        //   const resolvedTag = getEntity(spaceTicketTagsSchema, e);
        //   return `${e}-${resolvedTag?.Pipeline}`;
        // }}
        multiple
        inputType={ContractTagDropdown}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="ASSIGN_TO" />
      </div>
      <FormInput
        name="Manager"
        className="mb-3"
        textId="SALESPERSON"
        inputType={UserDropdown}
        value={Manager}
        onChange={handleManagerChange}
      />

      <FormInput
        name="Departments"
        className="mb-3"
        textId="DEPARTMENTS"
        inputType={DepartmentDropdown}
        multiple
      />

      <FormInput
        name="TeamCollab"
        className="mb-3"
        textId="TEAMS_CONTRIBUTORS"
        inputType={TeamCollabDropdown}
        multiple
        onChange={handleTeamCollabChange}
      />

      <FormInput
        name="Followers"
        textId="FOLLOWERS"
        inputType={UserDropdown}
        multiple
        // items={possibleFollowers}
        className="mb-3"
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"DETAILS"} />
      </div>

      <FormInput
        textId="NAME"
        // inputType={HTMLInput}
        name="Description"
        className="mb-3"
        value={Description}
        onChange={handleChanges}
      />

      <FormInput
        name="ContractType"
        className="mb-3"
        textId="TYPE_CONTRACT"
        inputType={ContractTypeDropdown}
        OrganizationSpaceId={Company}
        onChange={HandleContractChanges}
      />

      <FormInput
        className="mb-3"
        name="BeginDate"
        textId="START"
        inputType={ArDatePicker}
        onChange={handleTimeChange}
        componentClassName="w-100"
        openHourAfterDate
      />

      <FormInput
        className="mb-3"
        textId="END"
        inputType={ArDatePicker}
        name="EndDate"
        onChange={handleTimeChange}
        disablePastDate={BeginDate}
        disableDates={true}
        componentClassName="w-100"
        openHourAfterDate
      />

      <FormInput
        maxLength={50}
        className="mb-3"
        name="SalesOpportunity"
        textId="NR_SALE_OPPORTUNITY"
        value={SalesOpportunity}
        onChange={handleChanges}
      />

      <FormInput
        type="number"
        min={0}
        className="mb-3 removeNumberButtonsFromInput"
        name="TicketLimit"
        textId="TICKET_LIMIT"
        value={TicketLimit}
        onChange={handleCaseLimitChanges}
      />

      {!Id && TransferInboundEmail && InboundEmails?.length > 0 && (
        <InboundEmailsPreview
          emailList={resolvedEmailList}
          handleChange={handleChange}
          currentEmails={CurrentInboundEmails}
          renovation={TransferInboundEmail}
        />
      )}

      {!Id && !TransferInboundEmail && (
        <FormInput
          textId="SUPPORT_EMAIL"
          // dontShowAddButton
          inputType={!Id ? InboundEmailDropdown : undefined}
          closeOnSelect={true}
          // inputType={HTMLInput}
          OrganizationSpaceId={Company}
          name="InboundEmail"
          className="mb-3"
          disabled={TransferInboundEmail || (Id ? true : false)}
          value={InboundEmail}
          onChange={!Id ? handleChanges : () => {}}
        />
      )}

      {Id && InboundEmails?.length > 0 && (
        <InboundEmailsPreview emailList={resolvedEmailList} />
      )}

      <FormInput
        name="IntervalMinimum"
        textId="MINIMUM_BILLING_INTERVAL"
        className="mb-3"
        inputType={MinuteInput}
      />
      <FormInput
        name="RoundingMargin"
        textId="ROUNDING_MARGIN"
        className="mb-3"
        inputType={MinuteInput}
      />

      <FormInput
        className="mb-3"
        textId="ESTIMATED_TIME"
        name="EstimatedCost"
        inputType={HourInput}
        value={EstimatedCost}
        onChange={handleChanges}
      />

      <FormInput
        name="Deslocations"
        className="mb-3"
        textId="TRAVELS"
        value={Deslocations}
        onChange={handleChanges}
        inputType={NumberInput}
      />

      <FormInput
        disableAutomation
        name="Covenant"
        className="mb-3"
        textId="RENEWAL"
        inputType={CovenantPeriodicity}
        value={Covenant}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="CONTROL_HOURS" />
      </div>

      <FormInput
        disableAutomation
        name="BillingPeriod"
        className="mb-3"
        textId="TIME_CONTROL_PERIODICITY"
        inputType={CovenantPeriodicity}
      />

      <FormInput
        className="mb-3"
        textId="MAXIMUM_PERIOD_HOURS"
        name="PeriodTime"
        inputType={HourInput}
        onChange={handleChanges}
      />
      {PrivateFinancialPermission && (
        <>
          <hr className="mb-3"></hr>
          <div className="mb-3 text-black fs-16 fw-medium">
            <FormattedMessage id={"FINANCIAL_DATA"} />
          </div>

          {/* Assinado */}

          <FormInput
            name="IsSigned"
            onChange={handleCheckChanges}
            textId={"CONTRACT_SIGNED"}
            inputType={Switch}
            className="mb-3"
          />

          <FormInput
            disableAutomation
            textId="VALUE_HOUR"
            name="EstimatedCostsHourDict"
            currencies={currencies}
            inputType={DealCostSelector}
            className="mb-3"
          />

          <FormInput
            disableAutomation
            textId="TOTAL_VALUE"
            name="EstimatedCostsDict"
            currencies={currencies}
            inputType={DealCostSelector}
            className="mb-3"
          />
        </>
      )}

      <ClipboardProvider>
        <hr className="mb-3"></hr>
        <div className="mb-3 text-black fs-16 fw-medium">
          <FormattedMessage id="OPENING_HOURS" />
        </div>

        {!loadingValidation && (
          <FormInput
            disableAutomation
            className="mb-3"
            name="Schedules"
            inputType={TimeTablePicker}
            multiple
            onChange={handleChanges}
          />
        )}

        <FormInput
          className="mb-3"
          textId="INTERNAL_NOTE"
          inputType={DocTyper}
          name="InternalDescription"
          value={InternalDescription}
          onChange={handleChanges}
        />

        {!Automation && (
          <>
            {Array.isArray(AssociatedFiles) && AssociatedFiles.length > 0 && (
              <>
                <hr className="mb-3"></hr>
                <div className="text-black fs-16 mb-3 fw-medium">
                  <FormattedMessage id="RELATED_ATTACHMENTS" />
                </div>
                <FormInput
                  disableAutomation
                  inputType={FileAssociations}
                  name="Files"
                  className="mb-3 form-Anexos-floater"
                  extendedMode
                  handleAssociationsChanges={handleAssociationsChanges}
                  AssignedAssociations={AssignedAssociations}
                  onChange={() => {}}
                  value={AssociatedFiles}
                />
              </>
            )}

            <hr className="mb-3"></hr>
            <div className="mb-3 text-black fs-16 fw-medium">
              <FormattedMessage id="ATTACHMENTS" />
            </div>
            {/* {id && (
						<FormInput
							textId="status"
							name="Status"
							inputType={ContractStatusDropdown}
							hideCancellation
							className="mb-3"
						/>
					)} */}
            <FormInput
              disableAutomation
              name="Files"
              className="mb-3 form-Anexos-floater"
              inputType={FileUploader}
              value={Files}
              enableSwapper
              onChange={handleChanges}
            />
          </>
        )}
      </ClipboardProvider>

      {/* {id && (
          <>
            <hr className="mb-3"></hr>
            <div className="mb-3">
              <span className="text-black fs-16 fw-medium">Comentários</span>
            </div>
            <div className="mb-3">
              <CommentModule
                Type="contract"
                Id={id}
                Comments={Comments || []}
              />
            </div>
          </>
        )} */}

      <CreatedBy Creator={Creator} CreationDate={CreationDate} />
    </>
  );
};

const InboundEmailsPreview = ({
  emailList,
  currentEmails,
  renovation,
  handleChange
}) => {
  const handleCheckboxChanges = (emailId) => {
    handleChange((oldS) => {
      const newS = { ...oldS };
      const newCurrentInboundEmails = [...newS.CurrentInboundEmails];
      if (newCurrentInboundEmails.includes(emailId)) {
        newCurrentInboundEmails.splice(
          newCurrentInboundEmails.indexOf(emailId),
          1
        );
      } else newCurrentInboundEmails.push(emailId);
      newS.CurrentInboundEmails = newCurrentInboundEmails;
      return newS;
    });
  };

  if (renovation) {
    return emailList.map((e, i, arr) => {
      if (i === 0) {
        return (
          <div key={i} className="d-flex mb-2  align-items-center">
            <FormInput
              textId="SUPPORT_EMAIL"
              name="InboundEmail"
              className="mr-2 w-100"
              disabled
              value={e.Email}
              onChange={() => {}}
            />
            <div
              className="d-flex flex-column justify-content-end"
              style={{ width: 24, height: 24 }}
            >
              <Checkbox
                onChange={() => {
                  handleCheckboxChanges(e.Id);
                }}
                checked={currentEmails.includes(e.Id)}
              ></Checkbox>
            </div>
          </div>
        );
      } else if (i === arr.length - 1) {
        return (
          <div key={i} className="d-flex mb-3  align-items-center">
            <FormInput
              name="InboundEmail"
              className="mr-2 w-100"
              disabled
              value={e.Email}
              onChange={() => {}}
            />
            <div
              className="d-flex flex-column justify-content-end"
              style={{ width: 24 }}
            >
              <Checkbox
                onChange={() => {
                  handleCheckboxChanges(e.Id);
                }}
                checked={currentEmails.includes(e.Id)}
              ></Checkbox>
            </div>
          </div>
        );
      } else
        return (
          <div key={i} className="mb-2 d-flex align-items-center">
            <FormInput
              name="InboundEmail"
              className="mr-2 w-100"
              disabled
              value={e.Email}
              onChange={() => {}}
            />
            <div
              className="d-flex flex-column justify-content-end"
              style={{ width: 24 }}
            >
              <Checkbox
                onChange={() => {
                  handleCheckboxChanges(e.Id);
                }}
                checked={currentEmails.includes(e.Id)}
              ></Checkbox>
            </div>
          </div>
        );
    });
  }

  return emailList.map((e, i, arr) => {
    if (i === 0) {
      return (
        <FormInput
          textId="SUPPORT_EMAIL"
          key={i}
          name="InboundEmail"
          className="mb-2"
          disabled
          value={e.Email}
          onChange={() => {}}
        />
      );
    } else if (i === arr.length - 1) {
      return (
        <FormInput
          key={i}
          name="InboundEmail"
          className="mb-3"
          disabled
          value={e.Email}
          onChange={() => {}}
        />
      );
    } else
      return (
        <FormInput
          key={i}
          name="InboundEmail"
          className="mb-2"
          disabled
          value={e.Email}
          onChange={() => {}}
        />
      );
  });
};

export default ContractFormContent;
