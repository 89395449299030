import React from "react";
// import {
//     ContractIcon,
//     ProjectIcon,
//     TicketIcon,
//     CallIcon,
//     ClientIcon,
//     TaskIcon,
//     InterventionIcon
//   } from "../../../Components/Interventions/InterventionTargetItem";
//   import { ProjectStatusProfile } from "../../../Components/Projects/ProjectProfile";
//   import { ClientStatusBadge } from "../../../Components/Clients/ClientProfile";
import { DirectOriginOverview } from "../OriginOverview";

export const OriginChild = ({ item, disablePopup }) => {
  // const { Id, Type, Status, Name, SalesOpportunity } = item;
  // const IconClass = "mr-2";

  const mainClass = "d-flex align-items-center my-2 text-truncate";
  return (
    <div className={mainClass}>
      <DirectOriginOverview origin={item} disablePopup={disablePopup} />
    </div>
  );
  // switch (Type) {
  //   case "timecharge":
  //     return (
  //       <div className={mainClass}>
  //         <ProjectStatusProfile hideName project={{ Status }} />
  //         <InterventionIcon className={IconClass} />
  //         <div className="d-flex flex-column lh-normal">
  //           <div className="d-flex">
  //             <span className="fs-12 mw-120px text-truncate">{Name}</span>
  //           </div>
  //           <div className="fs-10 text-left">
  //             <span className=" mr-2">Nº: {Id}</span>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   case "project":
  //     return (
  //       <div className={mainClass}>
  //         <ProjectStatusProfile hideName project={{ Status }} />
  //         <ProjectIcon className={IconClass} />
  //         <div className="d-flex flex-column lh-normal">
  //           <div className="d-flex">
  //             <span className="fs-12 mw-120px text-truncate">{Name}</span>
  //           </div>
  //           <div className="fs-10 text-left">
  //             <span className=" mr-2">Nº: {Id}</span>
  //             {SalesOpportunity && <span>OPV: {SalesOpportunity}</span>}
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   case "ticket":
  //     return (
  //       <div className={mainClass}>
  //         <ProjectStatusProfile hideName project={{ Status }} />
  //         <TicketIcon className={IconClass} />
  //         <div className="d-flex flex-column lh-normal text-truncate">
  //           <span className="fs-12 mw-120px text-truncate">{Name}</span>
  //           <span className="fs-10 text-left">Nº: {Id}</span>
  //         </div>
  //       </div>
  //     );
  //   case "call":
  //     return (
  //       <div className={mainClass}>
  //         <ProjectStatusProfile hideName project={{ Status }} />
  //         <CallIcon className={IconClass} />
  //         <div className="d-flex flex-column lh-normal">
  //           <div className="d-flex">
  //             <span className="fs-12 mw-120px text-truncate">{Name}</span>
  //           </div>
  //           <span className="fs-10 text-left">Nº: {Id}</span>
  //         </div>
  //       </div>
  //     );
  //   case "client":
  //     return (
  //       <div className={mainClass}>
  //         <ClientIcon className={IconClass} />
  //         <div className="d-flex flex-column lh-normal">
  //           <div className="d-flex">
  //             <span className="fs-12 mw-120px text-truncate">{Name}</span>
  //             <ClientStatusBadge className="ml-2 fs-10 sm" status={Status} />
  //           </div>
  //           <span className="fs-10 text-left">Nº: {Id}</span>
  //         </div>
  //       </div>
  //     );
  //   case "task":
  //     return (
  //       <div className={mainClass}>
  //         <ProjectStatusProfile hideName project={{ Status }} />
  //         <TaskIcon className={IconClass} />
  //         <div className="d-flex flex-column lh-normal">
  //           <div className="d-flex">
  //             <span className="fs-12 mw-120px text-truncate">{Name}</span>
  //           </div>
  //           <span className="fs-10 text-left">Nº: {Id}</span>
  //         </div>
  //       </div>
  //     );
  //   case "contract":
  //     return (
  //       <div className={mainClass}>
  //         <ContractIcon className={IconClass} />
  //         <div className="d-flex flex-column lh-normal">
  //           <div className="d-flex">
  //             <span className="fs-12 mw-120px text-truncate">{Name}</span>
  //           </div>
  //           <span className="fs-10 text-left">Nº: {Id}</span>
  //         </div>
  //       </div>
  //     );
  //   default:
  //     return null;
  // }
};

export const OriginChildren = ({ items }) => {
  //   console.log(items);
  if (!items) return null;

  return (
    <div>
      {items.map((item, index) => (
        <OriginChild key={index} item={item} />
      ))}
    </div>
  );
};
