import React, { useContext } from "react";
import { faRoute } from "@fortawesome/pro-solid-svg-icons";
import { useSidebar } from "../../../../Components/Sidebar/SidebarV2";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../AutomationDetailsHelper";
import { ElementsRefContext } from "../AutomationDetails";
import SourceHandles from "../Handles/SourceHandles";
import TargetHandles from "../Handles/TargetHandles";
import NodeBaseSidebar from "./NodeBaseSidebar";
import CounterInput from "../../../../Components/Input/CounterInput";
import AutomationTestResults from "./AutomationTestResults";
import NodeBaseContainer from "./NodeExtraOptions/NodeExtraOptions";
import NodeIconNameContainer from "./NodeIconNameContainer";

const FormContainer = ({ formState, id, canEdit }) => {
  const { form, handleChange } = formState;
  const { delay } = form;

  const onChange = (newVal) => {
    handleChange({
      delay: newVal
    });
  };

  return (
    <div>
      <div className="d-flex w-100 mb-3 align-items-center justify-content-between">
        <div className="text-black fs-16">Delay (ms)</div>
        <CounterInput step={50} enableInput value={delay} onChange={onChange} />
      </div>
    </div>

    // <BaseSidebarContainer>
    //   <BaseSidebarHeader>Delay</BaseSidebarHeader>
    //   <BaseSidebarContent>
    //     <div className="p-3">
    //       <HourPicker value={value} onChange={handleChange} />

    //       <Button onClick={handleSubmit}>Guardar</Button>
    //     </div>
    //   </BaseSidebarContent>
    //   <NodeBaseSidebar AllElements={elements} CurrentNodeId={id} />
    // </BaseSidebarContainer>
  );
};

const settings = {
  defaultForm: {
    delay: 1000
  },
  FormContent: (props) => {
    return <FormContainer {...props}></FormContainer>;
  }
};

const DelayNode = React.memo(({ selected, data, id, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };

  const { enabled, config } = data;
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <NodeIconNameContainer
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleClick}
          selected={selected}
          icon={faRoute}
          text={"Delay"}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default DelayNode;
