import React, { Component, useMemo, useRef } from "react";
import "./GroupButton.css";
import { withRouter } from "react-router";
import { useServerAwareState } from "../CGrid/ServerGrid";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import querystring from "query-string";
import { usePipelineQuery } from "../Pipelines/PipelineProvider";
import InformationComponent from "../InformationComponent/InformationComponent";
import { FormattedMessage } from "react-intl";
import { useHoverEvents } from "../../Containers/Origin/Origin";
import Popup from "../Popup/Popup";
class GroupButton extends Component {
  constructor(props) {
    super(props);

    const items = this.props.items || [];

    this.state = { items: items, selectedItem: this.props.value };
  }

  onChange = (id) => {
    if (this.state.selectedItem === id) return;

    const { value, onChange } = this.props;

    if (typeof onChange === "function") onChange(id);

    if (!value) this.setState({ selectedItem: id });
  };

  static getDerivedStateFromProps(nextProps, state) {
    const { value } = nextProps;

    if (!value) return null;

    return { selectedItem: value };
  }

  render() {
    const { items } = this.state;

    return (
      <div className="ar-groupbutton-container">
        {items.map((item, i) => {
          const isSelected = item.id === this.state.selectedItem;

          return (
            <SingleButton
              key={"sb-" + i}
              click={this.onChange}
              item={item}
              selected={isSelected}
            />
          );
        })}
      </div>
    );
  }
}

const SingleButton = ({ item, selected, click }) => {
  const { text, image, id } = item;

  return (
    <button
      onClick={() => {
        click(id);
      }}
      className={"ar-groupbutton" + (selected ? " ar-selected" : "")}
    >
      {typeof image === "string" ? (
        <img alt="" src={image} />
      ) : image ? (
        image
      ) : (
        text
      )}
    </button>
  );
};

export default GroupButton;

export const ServerAwareButtons = ({ children, className }) => {
  return (
    <div
      className={classnames("ar-groupbutton-container ssi-control", className)}
    >
      {children}
    </div>
  );
};

const getTranslatedLabel = (t) => {
  switch (t) {
    case "calendar":
      return "VIEW_CALENDAR";
    case "timeline":
      return "VIEW_TIMELINE";
    case "board":
      return "VIEW_BOARD";
    case "cards":
      return "VIEW_CARDS";
    default:
      return "VIEW_GRID";
  }
};

export const ServerAwareButton = withRouter(({ type, icon, match }) => {
  const { params, paramsUrl } = useServerAwareState();

  const { view } = params;
  const label = getTranslatedLabel(type);
  const { url } = match;
  const isActive = type === view;
  const { data } = usePipelineQuery() || {};
  const to = useMemo(() => {
    const { view, ...rest } = querystring.parse(paramsUrl);
    const newParams = { ...rest };
    if (type) newParams.view = type;

    if (type === "board" && !newParams.pipeline && data && data.length > 0)
      newParams.pipeline = data[0];

    const search = querystring.stringify(newParams);
    return search ? `${url}?${search}` : url;
  }, [data, paramsUrl, type, url]);

  const anchorRef = useRef();
  const {
    // onPopupMouseEnter,
    // onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents(0, 0);

  return (
    <>
      <Popup
        placement={"bottom"}
        anchorEl={anchorRef.current}
        modifiers={{
					offset: { offset: "0px, 2px" },
          preventOverflow: { boundariesElement: "viewport" }
        }}
        style={{ display: !isOpen ? "none" : "" }}
        isOpen={isOpen}
        // onMouseEnter={onPopupMouseEnter}
        // onMouseLeave={onPopupMouseLeave}
      >
        <div className="InformationTooltip bg-black disable-selection text-white px-2 py-1 fs-14 breakWord rounded">
          <FormattedMessage id={label} />
        </div>
      </Popup>
      <NavLink
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
        ref={anchorRef}
        to={to}
        className="ar-groupbutton"
        isActive={() => isActive}
        activeClassName="ar-selected"
      >
        <FontAwesomeIcon icon={icon} />
      </NavLink>
    </>
  );
});

export const ServerAwareView = ({ type, children }) => {
  const { params } = useServerAwareState();

  const { view } = params;

  const isActive = type === view;

  if (!isActive) return null;

  return children;
};
