import React, { useContext } from "react";
import TargetHandles from "../Handles/TargetHandles";
import SourceHandles from "../Handles/SourceHandles";
import AutomationTestResults from "./AutomationTestResults";
import NodeBaseContainer from "./NodeExtraOptions/NodeExtraOptions";
import { faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons";
import { useIntl } from "react-intl";
import NodeIconNameContainer from "./NodeIconNameContainer";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../AutomationDetailsHelper";
import { useSidebar } from "../../../../Components/Sidebar/SidebarV2";
import { ElementsRefContext } from "../AutomationDetails";
import NodeBaseSidebar from "./NodeBaseSidebar";

const settings = {
  defaultForm: {
    delay: 1000
  },
  FormContent: null
};

const DoNothingNode = React.memo(({ selected, id, data, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };

  const { enabled, config } = data;
  const intl = useIntl();

  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <NodeIconNameContainer
          config={config}
          type={type}
          enabled={enabled}
          selected={selected}
          onDoubleClick={handleClick}
          icon={faArrowCircleRight}
          text={intl.formatMessage({ id: "DO_NOTHING" })}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default DoNothingNode;
