import React, { useRef } from "react";
import { useHoverEvents } from "../../Containers/Origin/Origin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoPopup from "../Popup/InfoPopup";
import { useChildrenCountQuery } from "../../Containers/EntityChildrenProgress/EntityChildrenProgressHelper";
import {
  faFolderTree,
  faHistory,
  faPaperclip,
  faComments,
  faEnvelope,
  faBellPlus
} from "@fortawesome/pro-light-svg-icons";
import { useLastHistoryItem } from "../DetailsView/History/HistoryHelper";
import moment from "moment";
import "./EntityIconOverview.css";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

export const IconOverviewItem = ({
  icon,
  count,
  title,
  titleId,
  className,
  ...rest
}) => {
  const hoverEvents = useHoverEvents();

  const {
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen,
    onPopupMouseEnter,
    onPopupMouseLeave
  } = hoverEvents;

  const ref = useRef();

  return (
    <>
      <div
        ref={ref}
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
        className={className}
        {...rest}
      >
        <FontAwesomeIcon icon={icon} className="ar-entity-icon-overview-img" />
        {count && count}
      </div>
      <InfoPopup
        anchorEl={ref.current}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        {titleId ? <FormattedMessage id={titleId} /> : title}
      </InfoPopup>
    </>
  );
};

const ChildrenIconOverviewItem = ({ schema, id }) => {
  const { data } = useChildrenCountQuery(schema, id);

  if (!data) return null;

  const { Total } = data;

  if (Total === 0) return null;

  return (
    <IconOverviewItem titleId="ORIGINATED" icon={faFolderTree} count={Total} />
  );
};

const HistoryIconOverviewItem = ({ schema, id }) => {
  const { data } = useLastHistoryItem(schema, id);

  if (!data || data.length === 0) return null;

  const { Date } = data[0];

  const fromNow = moment(Date).fromNow();

  return (
    <IconOverviewItem titleId="LAST_UPDATE" icon={faHistory} count={fromNow} />
  );
};

const EntityIconOverview = ({
  value,
  schema,
  noChildren,
  noHistory,
  className
}) => {
  const { FilesCount, NotificationsCount, CommentsCount, EmailsCount } = value;

  return (
    <div className={classnames("ar-entity-icon-overview", className)}>
      {Boolean(FilesCount && FilesCount) > 0 && (
        <IconOverviewItem
          titleId="ATTACHMENTS"
          icon={faPaperclip}
          count={FilesCount}
        />
      )}
      {Boolean(CommentsCount && CommentsCount > 0) && (
        <IconOverviewItem
          titleId="MESSAGES"
          icon={faComments}
          count={CommentsCount}
        />
      )}

      {Boolean(NotificationsCount && NotificationsCount) > 0 && (
        <IconOverviewItem
          titleId="CONTACT_NOTIFICATIONS"
          icon={faBellPlus}
          count={NotificationsCount}
        />
      )}
      {Boolean(EmailsCount && EmailsCount) > 0 && (
        <IconOverviewItem
          titleId="EMAILS"
          icon={faEnvelope}
          count={EmailsCount}
        />
      )}

      {schema && !noChildren && (
        <ChildrenIconOverviewItem schema={schema} id={value.Id} />
      )}

      {schema && !noHistory && (
        <HistoryIconOverviewItem schema={schema} id={value.Id} />
      )}
    </div>
  );
};

export default EntityIconOverview;
