import {
  faLayerGroup,
  faShieldKeyhole,
  faSitemap,
  faSquareInfo,
  faUsers
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";
import {
  useCanEditFollowers,
  updateManagerFollowers
} from "../../../Components/EntityFollow/EntityFollowHelper";
import { createFormRequestHook, useFormState } from "../../../Components/Forms";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import {
  FormTemplateProvider,
  useFormTemplateGetter
} from "../../../Components/Forms/FormTemplatesHelper";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../../Components/Forms/SideMenuForm";
import HTMLInput from "../../../Components/HTMLInput/HTMLInput";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import {
  isOrganzationRequestValid,
  OrganizationRequestSideError
} from "../../../Components/Organizations/OrganizationHelper";
import PublicAvailabilitySwitch from "../../../Components/PermissionsFormComponent/PublicAvailabilitySwitch";
import ShareWithComponent from "../../../Components/PermissionsFormComponent/ShareWithComponent";
import {
  BaseSidebarHeader,
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../../Components/Sidebar/SidebarV2Helper";
import { useToast } from "../../../Components/Toast/ToastProvider";
import {
  spaceCallsSchema,
  spaceClientSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceDocumentsSchema,
  spaceInterventionsSchema,
  spaceOrganizationSchema,
  spacePipelineSchema,
  spaceProjectsSchema,
  spaceSubscriptionSchema,
  spaceTasksSchema,
  spaceTicketsSchema,
  spacePipelineStatusSchema
} from "../../../config/schema";
import { useCurrentAccount } from "../../../Contexts/UserContext";
import { getInterventionTarget } from "../../../Helpers/FormFormmaterHelper";
import {
  useSpacePost,
  useSpaceQuery,
  getEntity
} from "../../../Helpers/IOClient";
import { useDocument } from "../../../Hooks/EntityHooks";
import {
  DocumentTargetDropdown,
  DocumentTypeDropdown,
  OrganizationDropdown,
  UserDropdown
} from "../../AdvancedMultiInputs";
import {
  DocumentPipelineDropdown,
  DocumentPipelineStatusDropdown
} from "../../AdvancedMultiInputs/PipelineDropdowns";
import { DocumentsStatusTypes } from "../../Settings/Documents/DocumentsHelper";
import { PipelineTypeEnum } from "../../Settings/Pipelines/NewPipelineHelper";
import {
  convertDocumentEntityToForm,
  formatDocumentForm,
  validateDocForm
} from "./DocumentsHelper";
import { useHistory } from "react-router-dom";
import { useSpace } from "../../../Contexts/SpaceContext";
import { isValueExpression } from "../../../Helpers/MiscHelper";

const useDocumentRequest = createFormRequestHook({
  schema: spaceDocumentsSchema,
  convertEntityToForm: convertDocumentEntityToForm,
  dependencies: [
    {
      schema: spaceCallsSchema,
      getEntityId: ({ callId }) => callId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          DocTarget: {
            type: "Call",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceClientSchema,
      getEntityId: ({ clientId }) => clientId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          DocTarget: {
            type: "Client",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceDealsSchema,
      getEntityId: ({ dealId }) => dealId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          DocTarget: {
            type: "Deal",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceContractSchema,
      getEntityId: ({ contractId }) => contractId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          DocTarget: {
            type: "Contract",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceSubscriptionSchema,
      getEntityId: ({ subscriptionId }) => subscriptionId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          DocTarget: {
            type: "Subscription",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceProjectsSchema,
      getEntityId: ({ projectId }) => projectId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          DocTarget: {
            type: "Project",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceTasksSchema,
      getEntityId: ({ taskId }) => taskId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          DocTarget: {
            type: "Task",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceTicketsSchema,
      getEntityId: ({ ticketId }) => ticketId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          DocTarget: {
            type: "Ticket",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceInterventionsSchema,
      getEntityId: ({ interventionId }) => interventionId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          DocTarget: {
            type: "Intervention",
            data: Id
          },
          Company
        };
      }
    }
  ]
});

const formatDuplicationEntity = (entity) => {
  const { PipelineStatus } = entity;
  if (PipelineStatus) {
    const pipelineStatusEntity = getEntity(
      spacePipelineStatusSchema,
      PipelineStatus
    );
    if (
      pipelineStatusEntity.Status === DocumentsStatusTypes.cancelled ||
      pipelineStatusEntity.Status === DocumentsStatusTypes.closed
    ) {
      entity.PipelineStatus = null;
    }
  }
  delete entity.Client;
  delete entity.Ticket;
  delete entity.Task;
  delete entity.Project;
  delete entity.Intervention;
  delete entity.Deal;
  delete entity.Contract;
  delete entity.Subscription;
  delete entity.Call;

  return entity;
};

const DocumentsForm = (props) => {
  const { key, loading, error, convertedForm, isChild, baseForm, childProps } =
    useDocumentRequest(props);

  const { id, defaultForm, onSuccess, className, duplicationForm } = props;
  const resolvedDuplicationForm = useMemo(
    () => (duplicationForm ? formatDuplicationEntity(duplicationForm) : {}),
    [duplicationForm]
  );
  const doc = useDocument(id);
  const user = useCurrentAccount();

  const {
    loading: loadingPipelines,
    data: pipelines,
    error: errorPipelines
  } = useSpaceQuery(`query/pipelines?type=${PipelineTypeEnum.Documents}`, [
    spacePipelineSchema
  ]);

  const orgState = useSpaceQuery(
    `query/organizations?$filter=((Account/Id eq '${user.Id}') or (Manager/Id eq '${user.Id}'))`,
    [spaceOrganizationSchema]
  );

  const { loading: loadingOrganizations, data: organizations } = orgState;

  const resolvedForm = useMemo(() => {
    const form = {
      ...defaultForm,
      ...convertedForm,
      ...resolvedDuplicationForm
    };

    if (childProps) {
      form.TaskTarget = getInterventionTarget(childProps) || form.TaskTarget;
    }
    //;

    if (
      !id &&
      organizations &&
      organizations.findIndex((e) => e === form.Company) === -1
    ) {
      form.Company = organizations[0];
    }

    return form;
  }, [
    childProps,
    convertedForm,
    defaultForm,
    id,
    organizations,
    resolvedDuplicationForm
  ]);

  const { data: formTemplates, refetch: formTemplateRefetch } =
    useFormTemplateGetter(spaceDocumentsSchema);

  if (
    loadingPipelines ||
    errorPipelines ||
    loading ||
    error ||
    loadingOrganizations
  )
    return (
      // <div className="d-flex h-100 flex-column bg-white shadow">
      //   <BaseSidebarHeader>
      //     <FormattedMessage id="CREATE_DOCUMENT" />
      //   </BaseSidebarHeader>
      //   <div
      //     className="flex-1 of-y-auto justify-content-center align-items-center"
      //     style={{ width: 520 }}
      //   >
      <LoaderSpinner center size="sm" className="text-secondary" />
      //   </div>
      // </div>
    );

  if (!isOrganzationRequestValid(orgState))
    return <OrganizationRequestSideError requestState={orgState} />;

  const canEdit = doc ? doc.CanEdit || false : true;
  const CanCancel = doc ? doc.CanCancel || false : true;
  const canDelete = doc?.CanDelete || false;

  return (
    <FormTemplateProvider forms={formTemplates} refetch={formTemplateRefetch}>
      <DocumentsFormBase
        className={className}
        isChild={isChild}
        onSuccess={onSuccess}
        organizations={organizations}
        pipelines={pipelines}
        resolvedForm={resolvedForm}
        id={id}
        user={user}
        canEdit={canEdit}
        canDelete={canDelete}
        canCancel={CanCancel}
        convertedForm={resolvedForm}
      />
    </FormTemplateProvider>
  );
};

const DocumentsFormBase = ({
  convertedForm,
  resolvedForm,
  user,
  id,
  canEdit,
  pipelines,
  organizations,
  canCancel,
  canDelete,
  onSuccess,
  className,
  ...rest
}) => {
  const currentUser = useCurrentAccount();
  const history = useHistory();
  const space = useSpace();
  const formState = useFormState({
    ...DefaultDocumentForm(currentUser.Id),
    ...resolvedForm
  });
  const { form, handleChange, setForm } = formState;
  const saved = useRef(false);
  const { AssignedTo, Creator } = form;

  const intl = useIntl();

  const canEditFollowers = useCanEditFollowers(Creator, AssignedTo);

  const createToast = useToast();
  // useMarkRead(spaceCallsSchema, id, ManagerAccount);
  const handleTranslatedError = useHandleError(spaceDocumentsSchema);

  const [EditOnPost] = useSpacePost(
    `documents/${id}/EditOn`,
    spaceDocumentsSchema
  );
  const [EditOffPost] = useSpacePost(
    `documents/${id}/EditOff?isRequiredSave=false`,
    spaceDocumentsSchema
  );

  useEffect(() => {
    if (id) EditOnPost();
    return () => {
      if (id && !saved.current) EditOffPost(formatDocumentForm(form));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [post, { loading }] = useSidebarSpacePost(
    id ? `documents/${id}/EditOff?isRequiredSave=true` : "documents",
    spaceDocumentsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        saved.current = true;
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "DOCUMENT" })} nº ${newId || id}`,
          description: id
            ? `${intl.formatMessage({ id: "CREATED_SUCCEFULLY" })}`
            : `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`
        });
        if (!id) {
          history.push(`/s/${space.Id}/documents/${newId}`);
        }
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `documents/${id}`,
    spaceDocumentsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "DOCUMENT" })} nº ${id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const handleSubmit = (form) => {
    post(formatDocumentForm(form));
  };

  return (
    // <div className="d-flex h-100 flex-column bg-white shadow">
    //   <BaseSidebarHeader>
    //     <FormattedMessage id="CREATE_DOCUMENT" />
    //   </BaseSidebarHeader>
    //   <div className="flex-1 of-y-auto " style={{ width: 520 }}>
    <SideForm
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateDocForm}
    >
      <SideFormContent className={"px-3 pt-3"}>
        <DocFormContent handleChange={handleChange} form={form} />
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        deleteLoading={deleteLoading}
        handleDelete={remove}
        form={form}
        handleChange={setForm}
        schema={spaceDocumentsSchema}
        isLoading={loading}
      />
    </SideForm>
    // </div>
    // </div>
  );
};

export const DocFormContent = ({ form, handleChange }) => {
  const {
    Description,
    Company,
    DocTarget,
    Followers,
    Name,
    Pipeline,
    Id,
    Creator,
    AssignedTo
  } = form;

  const canEditFollowers = useCanEditFollowers(Creator, AssignedTo);

  const handleManagerChange = ({ target: { value } }) => {
    if (isValueExpression(value)) {
      handleChange({
        AssignedTo: value
      });
    } else
      handleChange({
        AssignedTo: value,
        Followers: updateManagerFollowers(Followers, AssignedTo, value, [])
      });
  };

  return (
    <>
      <div className="mb-3 mt-1 d-flex align-items-center text-black ">
        <FontAwesomeIcon icon={faSquareInfo} />
        <div className="ml-2 fs-16 fw-medium">
          <FormattedMessage id={"DETAILS"} />
        </div>
      </div>

      <FormInput
        name="Name"
        textId="NAME"
        value={Name}
        // onChange={handleTargetChange}
        className="mb-3"
      />

      <FormInput
        className="mb-3"
        name="DocumentType"
        textId="DOCUMENT_TYPE"
        removable={false}
        inputType={DocumentTypeDropdown}
      />

      <FormInput
        textId="DESCRIPTION"
        inputType={HTMLInput}
        name="Description"
        style={{ maxHeight: 250 }}
        className="mb-3"
        value={Description}
      />

      <div className="mb-3 d-flex align-items-center text-black ">
        <FontAwesomeIcon icon={faSitemap} />
        <div className="ml-2 fs-16 fw-medium">
          <FormattedMessage id={"ASSOCIATIONS"} />
        </div>
      </div>
      {/* {organizations && organizations.length > 1 && ( */}
      <FormInput
        name="Company"
        textId="COMPANY"
        value={Company}
        inputType={OrganizationDropdown}
        unremovable
        removable={false}
        // multiple
        // onChange={handleOrganizationChanges}
        className="mb-3"
      />
      {/* )} */}

      <FormInput
        name="DocTarget"
        textId="ASSOCIATE_TO"
        value={DocTarget}
        unremovable
        removable={false}
        inputType={DocumentTargetDropdown}
        allClients={false}
        // onChange={handleTargetChange}
        className="mb-3"
      />

      {/* <div className="mb-3 mt-1 d-flex align-items-center text-black ">
          <FontAwesomeIcon icon={faLayerGroup} />
          <div className="ml-2 fs-16 fw-medium">
            <FormattedMessage id={"STATUS"} />
          </div>
        </div> */}
      <FormInput
        className="mb-3"
        textId="ASSIGNED_TO"
        inputType={UserDropdown}
        removable={false}
        name="AssignedTo"
      />

      <FormInput
        name="Pipeline"
        className="mb-3"
        // disabled={
        //   pipelines && pipelines.length > 0
        //   // && pipelines.length === 1
        // }
        textId="PIPELINE"
        inputType={DocumentPipelineDropdown}
      />

      <FormInput
        className="mb-3"
        disabled={!Pipeline}
        textId="STATUS"
        removable={false}
        name="PipelineStatus"
        Status={
          !Id && [
            DocumentsStatusTypes.draft,
            DocumentsStatusTypes.review,
            DocumentsStatusTypes.active
          ]
        }
        inputType={DocumentPipelineStatusDropdown}
        pipelineId={Pipeline}
      />
      {/* 
        <div className="mb-3 mt-1 d-flex align-items-center text-black ">
          <FontAwesomeIcon icon={faUsers} />
          <div className="ml-2 fs-16 fw-medium">
            <FormattedMessage id={"ASSIGN_TO"} />
          </div>
        </div> */}

      {/* <div className="mb-3 mt-1 d-flex align-items-center text-black ">
          <FontAwesomeIcon icon={faUsers} />
          <div className="ml-2 fs-16 fw-medium">
            <FormattedMessage id={"SETTINGS"} />
          </div>
        </div>
        <PublicAvailabilitySwitch
          name="PublicStatus"
          handleChange={handleChange}
          value={PublicStatus}
          className="mb-3"
        />
        {!PublicStatus && (
          <ShareWithComponent
            subTextId={"SHARE_WITH_DOCUMENT_SUBTEXT"}
            name="Collaborators"
            value={Collaborators}
            className={"mb-3"}
          />
        )} */}
      <FormInput
        name="Followers"
        textId="FOLLOWERS"
        inputType={UserDropdown}
        multiple
        disabled={!canEditFollowers}
        // items={possibleFollowers}
        className="mb-3"
      />
      <FormInput
        name="Companies"
        textId="COMPANIES"
        multiple
        inputType={OrganizationDropdown}
        // multiple
        // onChange={handleOrganizationChanges}
        className="mb-3"
      />
    </>
  );
};

// const DocumentsFormContent = ({

// }) => {

//   return (

//   );
// };

const DefaultDocumentForm = (currentUser) => {
  return {
    AssignedTo: currentUser,
    Name: "",
    Description: ""
  };
};

export default DocumentsForm;
