import React, { useMemo, useRef, useContext, useState } from "react";
import { useAccount, useNote } from "../../../../Hooks/EntityHooks";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReply,
  faTimesCircle,
  faEllipsisV,
  faPencil,
  faTimes,
  faNotEqual
} from "@fortawesome/pro-light-svg-icons";
import { faThumbtack } from "@fortawesome/pro-regular-svg-icons";
import classnames from "classnames";
import { useCurrentAccount } from "../../../../Contexts/UserContext";
import TextEllipsis from "../../../TextEllipsis/TextEllipsis";
import Popup, { usePopupOpenState } from "../../../Popup/Popup";
import Button from "../../../Button/Button";
import LineHeader from "../../../LineHeader/LineHeader";
import "./Message.css";
import { ConnectedAccountProfileImg } from "../MessagesHelper";
import { MessageReadUsersPreview } from "../MessageReadUsersPreview/MessageReadUsersPreview";
import MessageFiles from "./MessageFiles";
import {
  useSpaceDelete,
  useSpacePost,
  getEntity,
  updateNormalizedSchemaCache
} from "../../../../Helpers/IOClient";
import Modal from "../../../Modal/Modal";
import { FormattedMessage } from "react-intl";
import { spaceNoteSchema } from "../../../../config/schema";
import { useConnectionId } from "../../../../Containers/RealTime/RealTime";
import {
  useEntitySummarySchemaProvider,
  useEntitySummaryParentIdProvider
} from "../../EntitySummaryProviderForMessages";
import { normalize } from "../../../../Helpers/SchemaHelper";

const useMessageInfo = (message, prevMessage, currentAccountId) => {
  const { CreationDate, Account } = message;

  const momentDate = useMemo(() => moment(CreationDate), [CreationDate]);

  const formmatedDate = useMemo(
    () => momentDate.format("DD/MM/YYYY HH:mm"),
    [momentDate]
  );

  const isCurrentUser = currentAccountId === Account;

  const isPartOfGroup = useMemo(() => {
    if (!isCurrentUser || !prevMessage) return false;

    const minutesDiff = momentDate.diff(
      moment(prevMessage.CreationDate),
      "minutes"
    );

    if (minutesDiff <= 2) return true;
    else return false;
  }, [isCurrentUser, momentDate, prevMessage]);

  return { formmatedDate, isPartOfGroup, isCurrentUser };
};

const MessageHeader = ({
  account,
  formmatedDate,
  isCurrentUser,
  className,
  inline,
  isPartOfGroup = true,
  WasEdited
}) => {
  const { Name } = useAccount(account) || {};

  return (
    <div
      className={classnames("align-items-center", className, {
        "d-inline-flex w-100": inline,
        "d-flex": !inline
      })}
    >
      {!isCurrentUser && (
        <div className="text-black mr-2 fs-14 text-truncate flex-1">{Name}</div>
      )}
      <div className="fs-12">{formmatedDate}</div>
      {WasEdited && (
        <div
          className={classnames("fs-14", {
            "ml-2": !isPartOfGroup
          })}
        >
          <FormattedMessage id="EDITED" />
        </div>
      )}
    </div>
  );
};

const MessageContainer = ({
  className,
  messageInfo,
  children,
  noteCreator
}) => {
  const { isPartOfGroup, isCurrentUser } = messageInfo;

  return (
    <div
      className={classnames(
        "w-100 d-flex ar-entity-summary-message-container",
        { grouped: isPartOfGroup, self: isCurrentUser, note: noteCreator },
        className
      )}
    >
      {children}
    </div>
  );
};

const MessageContent = ({ className, messageInfo, children, IsPinned }) => {
  const { isCurrentUser } = messageInfo;

  return (
    <div
      className={classnames(
        "ar-entity-summary-message pl-3 d-flex justify-content-start",
        { self: isCurrentUser, pinned: IsPinned },
        className
      )}
    >
      {IsPinned && (
        <div className="ar-entity-summary-message-pin d-flex align-items-center justify-content-center">
          <FontAwesomeIcon size="sm" icon={faThumbtack} />
        </div>
      )}
      {children}
    </div>
  );
};

const MessageOptionsButton = ({ onClick, icon, children }) => {
  const toggleIsOpen = useContext(MessageOptionsContext);
  const handleClick = (e) => {
    toggleIsOpen();
    onClick && onClick(e);
  };

  return (
    <Button className="rounded-0 text-left" onClick={handleClick} vType="link">
      <FontAwesomeIcon icon={icon} className="mr-2" />
      {children}
    </Button>
  );
};

const MessageOptionsPopup = ({
  onReply,
  onEdit,
  onDelete,
  onPin,
  IsPinned,
  noteCreator
}) => {
  return (
    <div className="bg-white py-2 rounded" style={{ width: 210 }}>
      <MessageOptionsButton
        icon={IsPinned ? faNotEqual : faThumbtack}
        onClick={onPin}
      >
        <FormattedMessage id={IsPinned ? "UNPIN" : "PIN"} />
      </MessageOptionsButton>
      {!noteCreator && (
        <MessageOptionsButton icon={faReply} onClick={onReply}>
          <FormattedMessage id="REPLY" />
        </MessageOptionsButton>
      )}
      {onEdit && (
        <MessageOptionsButton icon={faPencil} onClick={onEdit}>
          <FormattedMessage id="EDIT" />
        </MessageOptionsButton>
      )}
      {onDelete && (
        <MessageOptionsButton icon={faTimes} onClick={onDelete}>
          <FormattedMessage id="DELETE" />
        </MessageOptionsButton>
      )}
    </div>
  );
};

const MessageOptionsContext = React.createContext();

const MessageOptions = ({
  onReply,
  onEdit,
  onDelete,
  onPin,
  IsPinned,
  noteCreator
}) => {
  const anchorRef = useRef();
  const popupRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  return (
    <>
      <div className="h-100 px-2 ar-entity-summary-message-options">
        <div
          ref={anchorRef}
          onClick={toggleIsOpen}
          className="cursor-pointer px-1"
        >
          <FontAwesomeIcon size="lg" icon={faEllipsisV} />
        </div>
      </div>
      <Popup
        placement="bottom-end"
        domRef={popupRef}
        anchorEl={anchorRef.current}
        isOpen={Boolean(isOpen)}
      >
        <MessageOptionsContext.Provider value={toggleIsOpen}>
          <MessageOptionsPopup
            noteCreator={noteCreator}
            IsPinned={IsPinned}
            onPin={onPin}
            onReply={onReply}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </MessageOptionsContext.Provider>
      </Popup>
    </>
  );
};

const LastUnreadMessage = () => {
  return (
    <LineHeader className="text-primary fs-12 fw-medium mt-3">
      Últimas lidas
    </LineHeader>
  );
};

const useMessageDelete = (message, onSuccess) => {
  const { Id } = message;
  const conId = useConnectionId();
  return useSpaceDelete(`notes/${Id}`, spaceNoteSchema, {
    onSuccess,
    headers: {
      ConnectionID: conId
    }
  });
};

const MessageDeleteModal = ({ open, closeModal, onDelete }) => {
  return (
    <Modal isOpen={open} onClose={closeModal}>
      <div className="p-4">
        <div className="text-black mb-3">
          <FormattedMessage id="DELETE_MESSAGE" />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <Button className="mr-2" vType="link-danger" onClick={closeModal}>
            <FormattedMessage id="CANCEL" />
          </Button>
          <Button vType="danger" onClick={onDelete}>
            <FormattedMessage id="DELETE" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const Message = React.memo(
  ({
    message,
    noteCreator,
    prevMessage,
    currentAccountId,
    onChangeReplyMessageId,
    onChangeEditMessageId
  }) => {
    const {
      Description,
      Account,
      Id,
      ParentNote,
      LastMessageRead,
      ReadUsers,
      IsDeleted,
      Files,
      IsPinned,
      WasEdited
    } = message;

    const parentEntitySchema = useEntitySummarySchemaProvider();
    const parentEntityId = useEntitySummaryParentIdProvider();
    const [PinPost] = useSpacePost(`Notes/${Id}/TogglePin`, spaceNoteSchema);

    const handlePinSubmit = () => {
      const resolvedBody = JSON.stringify(!IsPinned);
      PinPost(resolvedBody);
    };

    const messageInfo = useMessageInfo(message, prevMessage, currentAccountId);

    const { isPartOfGroup, isCurrentUser, formmatedDate } = messageInfo;

    const handleReply = () => {
      onChangeReplyMessageId(Id);
    };

    const handleRemoveFiles = () => {
      const currentItem = getEntity(parentEntitySchema, parentEntityId);

      if (Array.isArray(Files) && Files?.length > 0) {
        const currentItemFiles = currentItem.Files
          ? [...currentItem.Files]
          : [];

        const filteredFiles = [];

        for (const e of currentItemFiles) {
          if (Files.findIndex((t) => t.Id === e.Id) < 0) {
            filteredFiles.push(e);
          }
        }

        const normalizedEntities = normalize(
          { Id: parentEntityId, Files: filteredFiles },
          parentEntitySchema
        );
        updateNormalizedSchemaCache(normalizedEntities.entities);
      }
    };

    const handleEdit = isCurrentUser
      ? () => {
          onChangeEditMessageId(Id);
        }
      : undefined;

    const [deleteMessage, { loading: loadingDelete }] = useMessageDelete(
      message,
      handleRemoveFiles
    );

    const [confirmDelete, setConfirmDelete] = useState(false);

    const handleDelete = () => {
      deleteMessage();
      setConfirmDelete(false);
    };

    const handlePopupDelete = isCurrentUser
      ? () => {
          setConfirmDelete(true);
        }
      : undefined;

    if (IsDeleted) return null;

    return (
      <>
        <MessageDeleteModal
          open={confirmDelete}
          onDelete={handleDelete}
          closeModal={() => {
            setConfirmDelete(false);
          }}
        />
        {LastMessageRead && <LastUnreadMessage />}
        <MessageContainer noteCreator={noteCreator} messageInfo={messageInfo}>
          {!isPartOfGroup && !isCurrentUser && (
            <ConnectedAccountProfileImg account={Account} size="sm" circular />
          )}
          <MessageContent
            IsPinned={IsPinned}
            className={loadingDelete ? "skeleton-inverted-pulse" : undefined}
            messageInfo={messageInfo}
          >
            <div className="d-inline-block message-container-for-chat of-hidden">
              {(!isPartOfGroup || WasEdited) && (
                <MessageHeader
                  className="mb-2"
                  account={Account}
                  WasEdited={WasEdited}
                  isPartOfGroup={isPartOfGroup}
                  isCurrentUser={isCurrentUser}
                  formmatedDate={formmatedDate}
                />
              )}
              {ParentNote && (
                <ReplyMessage className="mb-2" replyMessageId={ParentNote} />
              )}
              <div className="text-black ar-message-text">{Description}</div>
              {Boolean(Files) && Files.length > 0 && (
                <MessageFiles files={Files} />
              )}
            </div>
            <MessageOptions
              noteCreator={noteCreator}
              IsPinned={IsPinned}
              onPin={handlePinSubmit}
              onReply={handleReply}
              onEdit={handleEdit}
              onDelete={handlePopupDelete}
            />
          </MessageContent>
          {ReadUsers && !noteCreator && ReadUsers.length > 0 && (
            <MessageReadUsersPreview
              className="ar-chat-read-users-preview"
              accounts={ReadUsers}
            />
          )}
        </MessageContainer>
      </>
    );
  }
);

export const ReplyMessage = ({
  replyMessageId,
  onChangeReplyMessageId,
  className
}) => {
  const message = useNote(replyMessageId);

  const { Description, Account: accountId } = message;

  const { Id: currentAccountId } = useCurrentAccount();

  const messageInfo = useMessageInfo(message, null, currentAccountId);

  const { formmatedDate } = messageInfo;

  return (
    <MessageContent
      className={classnames("reply", className, {
        "pr-3": !Boolean(onChangeReplyMessageId)
      })}
      messageInfo={messageInfo}
    >
      <div className="d-inline-block flex-1 of-hidden">
        <MessageHeader
          inline
          className="mb-2"
          account={accountId}
          formmatedDate={formmatedDate}
        />
        <div className="text-black" style={{ maxHeight: 65 }}>
          <TextEllipsis text={Description} length={200} />
        </div>
      </div>
      {onChangeReplyMessageId && (
        <div className="px-2">
          <div
            className="cursor-pointer text-danger-alt"
            onClick={() => onChangeReplyMessageId()}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>
        </div>
      )}
    </MessageContent>
  );
};
