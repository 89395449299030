import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { ColorInput } from "../../../../Components/Input/ColorInput/ColorInput";
import CounterInput from "../../../../Components/Input/CounterInput";
import Switch from "../../../../Components/Switch/Switch";


export const InnerNodeConfigForm = ({ formState, ...rest }) => {
  const { form, handleChange } = formState;

  const formHandleChange = useCallback(
    (newFormValue) => {
      handleChange((oldV) => {
        const update =
          typeof newFormValue === "function"
            ? newFormValue(oldV.config)
            : newFormValue;
        ;
        return {
          ...oldV,
          config: {
            ...oldV.config,
            ...update
          }
        };
      });
    },
    [handleChange]
  );

  const innerFormState = useMemo(() => {
    return {
      form: form.config,
      handleChange: formHandleChange,
      setForm: formHandleChange,
      setValidation: () => {}
    };
  }, [form.config, formHandleChange]);

  return <NodeConfigForm formState={innerFormState}></NodeConfigForm>;
};

const NodeConfigForm = ({ formState }) => {
  const { form, handleChange } = formState;

  const {
    hasCustomColor,
    customColor,
    repeatOnFail,
    maxFailRepeat,
    failTimeout,
    continueOnFail,
    note
  } = form;

  const onChange = (e) => {
    const { name, value } = e;
    handleChange({
      [name]: value
    });
  };

  return (
    <>
      <NodeConfigSwitchComponent
        name={"hasCustomColor"}
        value={hasCustomColor}
        onChange={onChange}
        text={<FormattedMessage id="ENABLE_CUSTOM_NODE_COLOR" />}
      />
      {hasCustomColor && (
        <NodeConfigColorComponent
          name={"customColor"}
          value={customColor}
          onChange={onChange}
          text={<FormattedMessage id="CUSTOM_NODE_COLOR" />}
        />
      )}
      <NodeConfigSwitchComponent
        name={"repeatOnFail"}
        value={repeatOnFail}
        onChange={onChange}
        text={<FormattedMessage id="REPEAT_ON_FAIL" />}
      />
      <NodeConfigNumberCounterComponent
        name={"maxFailRepeat"}
        value={maxFailRepeat}
        onChange={onChange}
        text={<FormattedMessage id="REPEAT_ON_FAIL_COUNT" />}
      />
      <NodeConfigNumberCounterComponent
        name={"failTimeout"}
        value={failTimeout}
        onChange={onChange}
        text={<FormattedMessage id="TIMEOUT_BETWEEN_FAIL" />}
      />
      <NodeConfigSwitchComponent
        name={"continueOnFail"}
        value={continueOnFail}
        onChange={onChange}
        text={<FormattedMessage id="CONTINUE_ON_FAIL" />}
      />
      <NodeConfigNoteComponent
        name={"note"}
        value={note}
        onChange={onChange}
        text={<FormattedMessage id="NOTE" />}
      />
    </>
  );
};

const NodeConfigSwitchComponent = ({ text, onChange, value, name }) => {
  const handleChange = (e) => {
    const { checked } = e.target;
    onChange({ name, value: checked });
  };

  return (
    <div className="d-flex w-100 mb-3 align-items-center justify-content-between">
      <div className="text-black fs-14">{text}</div>
      <Switch value={value} onChange={handleChange} />
    </div>
  );
};
const NodeConfigColorComponent = ({ text, onChange, value, name }) => {
  const handleChange = (e) => {
    onChange({ name, value: e });
  };

  return (
    <div className="d-flex w-100 mb-3 align-items-center justify-content-between">
      <div className="text-black fs-14">{text}</div>
      <ColorInput value={value} onChange={handleChange} />
    </div>
  );
};
const NodeConfigNumberCounterComponent = ({ text, onChange, value, name }) => {
  const handleChange = (e) => {

    onChange({ name, value: e });
  };

  return (
    <div className="d-flex w-100 mb-3 align-items-center justify-content-between">
      <div className="text-black fs-14">{text}</div>
      <CounterInput value={value} onChange={handleChange} />
    </div>
  );
};
const NodeConfigNoteComponent = ({ text, onChange, value, name }) => {
  const handleChange = (e) => {
    const { value } = e.target;
    onChange({ name, value });
  };

  return (
    <div className="d-flex flex-column w-100 mb-3 align-items-center">
      <div className="text-black fs-14 d-flex w-100 mb-2">{text}</div>
      <textarea
        className="ssi-control ar-html-input rounded w-100"
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};
