import React, { useMemo, useContext, useLayoutEffect, useEffect } from "react";
import "./HTMLInput.css";
// import "draft-js/dist/Draft.css";
import { removeTags } from "../../Helpers/MiscHelper";
import classnames from "classnames";
import { ClipboardContext } from "../ClipbordProvider/ClipboardProvider";
// EditorState.createWithContent()

const HTMLInput = React.forwardRef(
  (
    {
      value,
      preview,
      name,
      onPasteEvent = () => {},
      onChange,
      className,
      placeholder,
      ...rest
    },
    textareaRef
  ) => {
    if (!textareaRef) textareaRef = React.createRef();
    const resolvedValue = useMemo(() => {
      return removeTags(value);
    }, [value]);

    useLayoutEffect(() => {
      const textarea = textareaRef.current;
      // textarea.addEventListener('paste',context ? context.onPaste: ()=>{})
      if (textarea && textarea.style) {
        textarea.style.height = textarea.scrollHeight + 2 + "px";
      }
    });

    const context = useContext(ClipboardContext);
    const rOnChange = (e) => {
      const textarea = textareaRef.current;

      //handle textarea resize
      if (textarea && textarea.style) {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + 2 + "px";
      }

      onChange(e);
    };

    useEffect(() => {
      if (context && Array.isArray(context.Data) && context.Data.length > 0) {
        onPasteEvent(context.Data);
      }
    }, [context, onPasteEvent]);

    if (preview)
      return (
        <div
          className={classnames(
            "fs-14 white-space-pre-line text-black",
            className
          )}
        >
          {resolvedValue}
        </div>
      );

    return (
      <textarea
        name={name}
        placeholder={placeholder}
        ref={textareaRef}
        className={classnames(
          "ssi-control ar-html-input p-2 rounded w-100",
          className
        )}
        value={resolvedValue}
        onChange={rOnChange}
        onPaste={context ? context.onPaste : () => {}}
        {...rest}
      />

      // <div className={`ssi-control ar-html-input ${className}`}>
      //   <Editor
      //     editorState={resolvedValue}
      //     onChange={rOnChange}
      //     className={className}
      //   />
      // </div>
    );
  }
);

export default HTMLInput;

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBold, faItalic, faLink } from "@fortawesome/pro-regular-svg-icons";

// import "./HTMLInput.css";
// import { GenerateGuid, GenerateSmallerGuid } from "../../Assets/Utils";
// import { removeTags } from "../../Helpers/MiscHelper";

// /**
//  * @param {string} className - classes for the container
//  */

// const textPropertyRegex = ".*?";
// const boldRegex = "<strong>" + textPropertyRegex + "</strong[ ]{0,1}>";
// const boldRegexWithParams =
//   '<strong [\\w= "./:;-]+>' + textPropertyRegex + "</strong[ ]{0,1}>";
// const linkRegex =
//   "<a>" +
//   textPropertyRegex +
//   '</a[ ]{0,1}>|<a href="[\\w\\s:.;/_d]+">' +
//   textPropertyRegex +
//   '</a[ ]{0,1}>|<a target="[\\w_]+" href="[\\w\\s:.;/_d]+">' +
//   textPropertyRegex +
//   '</a[ ]{0,1}>|<a style="[\\w\\s\\d-:;]+" target="[\\w_]+" href="[\\w\\s:.;/_d]+">' +
//   textPropertyRegex +
//   "</a[ ]{0,1}>";
// const firstTagAndParametersRegex = '<[\\w]+ [\\w= "./:;-]+>';
// const spanRegex = "<span>" + textPropertyRegex + "<\\/span[ ]{0,1}>";
// const textRegex = textPropertyRegex;
// const styledTagRegex =
//   '<div[\\w\\s="/:;-]{0,}>' +
//   textPropertyRegex +
//   '<\\/div[ ]{0,1}>|<span[\\w\\s="/:;-]{0,}>' +
//   textPropertyRegex +
//   '</span[ ]{0,1}>|<a[\\w\\s="/:;.-]{0,}>' +
//   textPropertyRegex +
//   '</a[ ]{0,1}>|<strong[\\w\\s="/:;-]{0,}>' +
//   textPropertyRegex +
//   "</strong[ ]{0,1}>";
// // '<[\\w= "./:;-]+>' + textPropertyRegex + "</[\\w]+[ ]{0,1}>";

// const isBold = text => {
//   const hasParamsRegex = new RegExp(firstTagAndParametersRegex).test(text);
//   if (
//     new RegExp(boldRegex).test(text) ||
//     new RegExp(boldRegexWithParams).test(text) ||
//     (hasParamsRegex && text.includes("font-weight")) ||
//     (hasParamsRegex && text.includes("font-bold"))
//   )
//     return true;
//   return false;
// };

// const isItalic = text => {
//   const hasParamsRegex = new RegExp(firstTagAndParametersRegex).test(text);

//   if (
//     (hasParamsRegex && text.includes("font-style")) ||
//     (hasParamsRegex && text.includes("italic"))
//   )
//     return true;
//   return false;
// };

// const isLink = text => {
//   return new RegExp(linkRegex).test(text);
// };

// const getSelectedOffSet = () => {
//   const { extentOffset: a, baseOffset: b } = window.getSelection();
//   return a < b ? { smaller: a, bigger: b } : { smaller: b, bigger: a };
// };

// // const getTypeByTag = tag => {
// //   switch (tag) {
// //     case "span":
// //       return 0;
// //     case "strong":
// //       return 1;
// //     default:
// //       return 0;
// //   }
// // };

// // const getTagByType = type => {
// //   switch (type) {
// //     case typeContains(type, TagEnum.Link):
// //       return "a";
// //     case typeContains(type, TagEnum.Bold):
// //       return "strong";
// //     default:
// //       return "span";
// //   }
// // };

// const typeContains = (type, contain) => {
//   return (type & contain) === contain;
// };

// const TagEnum = {
//   Span: 0,
//   Bold: 1,
//   Italic: 2,
//   Link: 4
// };

// const getFormattedText = formattedObject => {
//   let text = "";
//   let parentObjCounter = {};
//   let parentObj = {};
//   for (let i = 0; i < formattedObject.length; i++) {
//     const type = formattedObject[i].type;
//     const parentKey = formattedObject[i].parentKey;

//     const validParentKey = parentKey ? true : false;

//     if (validParentKey && !parentObj[parentKey]) {
//       parentObjCounter[parentKey] = formattedObject.filter(
//         e => e.parentKey === parentKey
//       ).length;
//       parentObj[parentKey] = 0;
//       text += "<div>";
//     }

//     const containsLink = typeContains(type, TagEnum.Link);
//     const containsBold = typeContains(type, TagEnum.Bold);
//     const containsItalic = typeContains(type, TagEnum.Italic);

//     if (type === TagEnum.Span)
//       text += "<span>" + formattedObject[i].text + "</span>";
//     else if (type === TagEnum.Bold)
//       text += "<strong>" + formattedObject[i].text + "</strong>";
//     else if (type === TagEnum.Italic)
//       text +=
//         '<span style="font-style: italic;">' +
//         formattedObject[i].text +
//         "</span>";
//     else if (type === TagEnum.Link)
//       text +=
//         '<a target="_blank" href="' +
//         formattedObject[i].text +
//         '">' +
//         formattedObject[i].text +
//         "</a>";
//     else if (containsBold && containsItalic && containsLink)
//       text +=
//         '<a style="font-style: italic; font-weight: bold;" target="_blank" href="' +
//         formattedObject[i].text +
//         '">' +
//         formattedObject[i].text +
//         "</a>";
//     else if (containsBold && containsItalic)
//       text +=
//         '<strong style="font-style: italic;">' +
//         formattedObject[i].text +
//         "</strong>";
//     else if (containsBold && containsLink)
//       text +=
//         '<a style="font-weight: 600;" target="_blank" href="' +
//         formattedObject[i].text +
//         '">' +
//         formattedObject[i].text +
//         "</a>";
//     else if (containsItalic && containsLink)
//       text +=
//         '<a style="font-style: italic;" target="_blank" href="' +
//         formattedObject[i].text +
//         '">' +
//         formattedObject[i].text +
//         "</a>";

//     if (validParentKey) {
//       parentObj[parentKey]++;
//       if (parentObj[parentKey] === parentObjCounter[parentKey])
//         text += "</div>";
//     }
//   }
//   return text;
// };

// const getItemObjectByItem = (item, parentKey) => {
//   const isbold = isBold(item);
//   const isitalic = isItalic(item);
//   const islink = isLink(item);
//   if (isbold && isitalic && islink) {
//     return {
//       text: removeTags(item),
//       type: TagEnum.Bold | TagEnum.Link | TagEnum.Italic,
//       parentKey
//     };
//   } else if (isbold && isitalic) {
//     return {
//       text: removeTags(item),
//       type: TagEnum.Bold | TagEnum.Italic,
//       parentKey
//     };
//   } else if (isbold && islink) {
//     return {
//       text: removeTags(item),
//       type: TagEnum.Bold | TagEnum.Link,
//       parentKey
//     };
//   } else if (isitalic && islink) {
//     return {
//       text: removeTags(item),
//       type: TagEnum.Link | TagEnum.Italic,
//       parentKey
//     };
//   } else if (isbold) {
//     return { text: removeTags(item), type: TagEnum.Bold, parentKey };
//   } else if (isitalic) {
//     return { text: removeTags(item), type: TagEnum.Italic, parentKey };
//   } else if (islink) {
//     return { text: removeTags(item), type: TagEnum.Link, parentKey };
//   } else {
//     return { text: removeTags(item), type: TagEnum.Span, parentKey };
//   }
// };

// const getFormattedObject = formattedText => {
//   const regex = new RegExp(styledTagRegex, "gm");

//   const matches = formattedText.match(regex);
//   let results = [];
//   if (matches) {
//     for (let i = 0; i < matches.length; i++) {
//       const item = matches[i];
//       if (item.substring(0, 5) === "<div>") {
//         const tempItem = item.substring(5);
//         const matches2 = tempItem
//           .substring(0, tempItem.length - 6)
//           .match(regex);
//         if (matches2) {
//           const parentKey = GenerateSmallerGuid();
//           for (let j = 0; j < matches2.length; j++) {
//             const item2 = matches2[j];
//             results.push(getItemObjectByItem(item2, parentKey));
//           }
//         }
//       } else results.push(getItemObjectByItem(item));
//     }
//   }

//   return results;

//   // return matches ? matches.map(item => getItemObjectByItem(item)) : [];

//   //   return matches
//   //     ? matches.reduce((array, item) => {
//   //         array.push(getItemObjectByItem(item))
//   //         return array;
//   //       }, [])
//   //     : [];
// };

// const getHTML = el => {
//   const tag = el.tagName.toLowerCase();
//   const fontWeight = el.style.fontWeight;
//   const fontStyle = el.style.fontStyle;
//   const hasStyles = fontWeight !== "" || fontStyle !== "";
//   let style = hasStyles ? ' style="' : "";
//   let target = el.target ? ' target="' + el.target + '"' : "";
//   let link = el.href !== "" && tag === "a" ? ' href="' + el.href + '"' : "";

//   if (fontWeight !== "") style += "font-weight: bold;";
//   if (fontStyle !== "") style += "font-style: italic;";

//   if (hasStyles) style += '"';

//   return `<${tag}${style}${target}${link}>${el.textContent}</${tag}>`;
// };

// /**
//  * @param {function} onChange - callback for text change
//  */
// class HTMLInput extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { text: "", formattedObject: [] };
//     this.selectedDomToHighlight = {};
//     this.textArea = React.createRef();
//     this.boldButton = React.createRef();
//     this.italicButton = React.createRef();
//     this.linkButton = React.createRef();
//   }

//   getTargetType = (currentType, type) => {
//     if (currentType === type || typeContains(currentType, type))
//       return (currentType &= ~type);
//     else return currentType | type;
//   };

//   getSelectedStartAndEndPosition = () => {
//     this.lastStartEndPosition = {
//       start: this.lastSelection.getRangeAt(0).startOffset,
//       end: this.lastSelection.getRangeAt(0).endOffset
//     };
//     return this.lastStartEndPosition;
//   };

//   getSelectedText = () => {
//     this.lastSelection = window.getSelection();
//     return this.lastSelection.toString();
//   };

//   getEndSelectedNode = () => {
//     this.lastSelection = window.getSelection();
//     if (this.lastSelection.rangeCount > 0)
//       return this.lastSelection.getRangeAt(0).endContainer.parentNode;
//   };
//   getStartSelectedNode = () => {
//     this.lastSelection = window.getSelection();
//     let item;
//     if (this.lastSelection.rangeCount > 0) {
//       item = this.lastSelection.getRangeAt(0).startContainer.parentNode;
//       if (item.tagName === "DIV")
//         item = item.querySelectorAll("span, strong, a")[0];
//     }
//     return item;
//   };

//   addTagToNextElement = (objj, index, text, type) => {
//     const itemText = objj[index].text;

//     const currentTagType = objj[index].type;
//     const currentParentKey = objj[index].parentKey;
//     const targetText =
//       text.length > itemText.length ? text.substring(0, itemText.length) : text;

//     const textAfter = itemText.substring(text.length);

//     const targetType = this.getTargetType(currentTagType, type);
//     let nextIndex = index + 1;

//     objj[index] = {
//       text: targetText,
//       type: targetType,
//       parentKey: currentParentKey
//     };
//     if (textAfter !== "") {
//       objj.splice(index + 1, 0, {
//         text: textAfter,
//         type: currentTagType,
//         parentKey: currentParentKey
//       });
//       nextIndex++;
//     }

//     if (itemText.length < text.length) {
//       const remainingText = text.substring(itemText.length);
//       return this.addTagToNextElement(objj, nextIndex, remainingText, type);
//     } else {
//       this.selectedDomToHighlight.end = targetText.length;
//       this.selectedDomToHighlight.endElIndex = index;
//       return objj;
//     }
//   };

//   addOrRemoveTagResultFunction = (
//     objj,
//     index,
//     textIndex,
//     itemText,
//     text,
//     type
//   ) => {
//     const currentTagType = objj[index].type;
//     const currentParentKey = objj[index].parentKey;

//     const targetType = this.getTargetType(currentTagType, type);

//     let targetText = text;
//     let remainingText = "";

//     const { start, end } = this.getSelectedStartAndEndPosition();
//     if (
//       this.element.getAttribute("ar-htmlinput-key") !==
//       this.endElement.getAttribute("ar-htmlinput-key")
//     ) {
//       targetText = itemText.substring(start);
//       remainingText = text.substring(targetText.length);
//     }

//     // if (objj[index].text.substring(smallerIndex, itemText.length) !== targetText) {
//     //   if (objj[index].text.substring(smallerIndex, itemText.length) !== targetText) {

//     //   }
//     // }

//     const textBefore = itemText.substring(0, start);
//     const textAfter = itemText.substring(
//       targetText.length + start,
//       itemText.length
//     );
//     let nextIndex = index + 1;
//     this.selectedDomToHighlight.startElIndex = index;
//     this.selectedDomToHighlight.start = 0;

//     objj[index] = {
//       text: targetText,
//       type: targetType,
//       parentKey: currentParentKey
//     };
//     if (textBefore !== "") {
//       objj.splice(index, 0, {
//         text: textBefore,
//         type: currentTagType,
//         parentKey: currentParentKey
//       });
//       this.selectedDomToHighlight.startElIndex++;
//       nextIndex++;
//     }
//     if (textAfter !== "") {
//       objj.splice(index + 2, 0, {
//         text: textAfter,
//         type: currentTagType,
//         parentKey: currentParentKey
//       });
//       nextIndex++;
//     }

//     if (remainingText !== "") {
//       return this.addTagToNextElement(objj, nextIndex, remainingText, type);
//     } else {
//       this.selectedDomToHighlight.end = targetText.length;
//       this.selectedDomToHighlight.endElIndex = this.selectedDomToHighlight.startElIndex;
//       return objj;
//     }
//   };

//   getChildElementByIndex = (elements, index) => {
//     let indexCounter = 0;
//     for (let i = 0; i < elements.length; i++) {
//       if (elements[i].tagName === "DIV") {
//         for (let j = 0; j < elements[i].children.length; j++) {
//           if (indexCounter === index) return elements[i].children[j];
//           indexCounter++;
//         }
//         continue;
//       }
//       if (indexCounter === index) return elements[i];
//       indexCounter++;
//     }
//   };

//   getHTMLElement = obj => {
//     let tempObj = [...obj];
//     const children = this.textArea.current.children;

//     for (let i = 0; i < tempObj.length; i++) {
//       const itemText = tempObj[i].text;
//       const childElement = this.getChildElementByIndex(children, i);

//       if (
//         childElement.getAttribute("ar-htmlinput-key") ===
//         this.element.getAttribute("ar-htmlinput-key")
//       )
//         return { element: childElement, index: i, text: itemText };
//     }
//   };

//   addOrRemoveTag = (obj, type, text, index) => {
//     const { index: i, text: itemText } = this.getHTMLElement(obj);

//     return this.addOrRemoveTagResultFunction(
//       obj,
//       i,
//       index,
//       itemText,
//       text,
//       type
//     );
//   };

//   getInputText = () => {
//     if (this.textArea.current.childElementCount === 0) return this.state.text;
//     let childrenArr = [];
//     for (let i = 0; i < this.textArea.current.children.length; i++) {
//       const item = this.textArea.current.children[i];
//       if (item.tagName === "DIV") {
//         const parentKey = GenerateSmallerGuid();
//         if (
//           item.children &&
//           item.children.length === 1 &&
//           item.children[0].tagName === "BR"
//         ) {
//           childrenArr.push({ text: "", type: 0, parentKey });
//           continue;
//         }
//         for (let j = 0; j < item.children.length; j++) {
//           childrenArr.push(
//             getItemObjectByItem(getHTML(item.children[j]), parentKey)
//           );
//         }
//       } else childrenArr.push(getItemObjectByItem(getHTML(item)));
//     }

//     return childrenArr;
//   };

//   addOrRemoveEmptyTag = (type, text) => {
//     this.addNewTagOnType = undefined;

//     // this.element = this.getStartSelectedNode();
//     // this.endElement = this.getEndSelectedNode();

//     const formattedObj = this.getInputText();

//     const htmlEl = this.getHTMLElement(formattedObj);
//     let i = htmlEl !== undefined ? htmlEl.index : 0;
//     let index = 0;

//     const prevItem = { ...formattedObj[i] };
//     if (text) {
//       if (this.isFullyEmpty) {
//         formattedObj[0] = {
//           text,
//           type,
//           parentKey: prevItem.parentKey
//         };
//       } else {
//         const { start } = this.lastNodeOffset;
//         const lastText = this.lastNodeText;

//         const {
//           type: currentTagType,
//           parentKey: currentParentKey
//         } = formattedObj[i];

//         const textBefore = lastText.substring(0, start);
//         const textAfter = lastText.substring(start);

//         index = i;

//         formattedObj[i] = {
//           text,
//           type,
//           parentKey: prevItem.parentKey
//         };

//         if (textBefore !== "") {
//           formattedObj.splice(i, 0, {
//             text: textBefore,
//             type: currentTagType,
//             parentKey: currentParentKey
//           });
//           index++;
//         }
//         if (textAfter !== "") {
//           formattedObj.splice(index + 1, 0, {
//             text: textAfter,
//             type: currentTagType,
//             parentKey: currentParentKey
//           });
//         }
//       }
//     } else {
//       if (this.isFullyEmpty) {
//         formattedObj[0] = { ...formattedObj[0], type };
//         text = formattedObj[0].text;
//       } else {
//         // index = i;
//         // formattedObj[i].type = type;
//         index = i + 1;
//         text = formattedObj[i].text.substring(this.lastNodeText.length);
//         formattedObj[i].text = prevItem.text.substring(
//           0,
//           this.lastNodeText.length
//         );
//         formattedObj[index] = { text, type, parentKey: prevItem.parentKey };
//       }
//     }

//     this.selectedDomToHighlight.startElIndex = index;
//     this.selectedDomToHighlight.endElIndex = index;
//     this.selectedDomToHighlight.start = text.length;
//     this.selectedDomToHighlight.end = text.length;

//     const formattedTxt = getFormattedText(formattedObj);
//     this.setState({ text: formattedTxt }, () => {
//       const {
//         startElIndex,
//         endElIndex,
//         start,
//         end
//       } = this.selectedDomToHighlight;

//       const elementss = this.textArea.current.querySelectorAll(
//         "a, strong, span"
//       );
//       this.selectText(
//         elementss[startElIndex],
//         elementss[endElIndex],
//         start,
//         end
//       );
//     });
//   };

//   triggerChanged = value => {
//     const { onChange, name } = this.props;

//     if(!onChange) return;

//     if (name) {
//       onChange({
//         target: {
//           name,
//           value
//         }
//       });
//     } else {
//       onChange(value);
//     }
//   };

//   toggleOption = option => {
//     // const selectedText = this.textArea.current.matches(":focus") ? window.getSelection().toString() : null;
//     const selectedText = this.getSelectedText().replace(/\r?\n|\r/g, "");

//     const elementss = this.textArea.current.querySelectorAll("a, strong, span");

//     if (
//       elementss &&
//       elementss.length > 0 &&
//       !elementss[0].getAttribute("ar-htmlinput-key")
//     ) {
//       const hash = GenerateSmallerGuid();
//       for (let i = 0; i < elementss.length; i++) {
//         elementss[i].setAttribute("ar-htmlinput-key", hash + "-" + i);
//       }
//     }

//     if (selectedText !== "") {
//       // const txt =
//       //   this.textArea.current.childElementCount === 0 &&
//       //   this.textArea.current.textContent !== ""
//       //     ? this.textArea.current.textContent
//       //     : this.getInputText();

//       let index;
//       index = getSelectedOffSet();
//       this.element = this.getStartSelectedNode();
//       this.endElement = this.getEndSelectedNode();

//       if (
//         !this.textArea.current.contains(this.element) ||
//         !this.textArea.current.contains(this.endElement)
//       )
//         return;

//       const formattedObj = this.getInputText();

//       const formattedObjWithNewTag = this.addOrRemoveTag(
//         formattedObj,
//         option,
//         selectedText,
//         index
//       );

//       const formattedTxt = getFormattedText(formattedObjWithNewTag);
//       this.setState({ text: formattedTxt }, () => {
//         const {
//           startElIndex,
//           endElIndex,
//           start,
//           end
//         } = this.selectedDomToHighlight;

//         const elementss = this.textArea.current.querySelectorAll(
//           "a, strong, span"
//         );

//         this.selectText(
//           elementss[startElIndex],
//           elementss[endElIndex],
//           start,
//           end
//         );

//         const { onChange } = this.props;
//         if (onChange) setTimeout(() => this.triggerChanged(formattedTxt));
//       });
//     } else {
//       this.element = this.getStartSelectedNode();

//       const formattedObj = this.getInputText();

//       const obj = this.getHTMLElement(formattedObj);
//       if (obj) {
//         let { type } = formattedObj[obj.index];

//         if (this.addNewTagOnType !== undefined) type = this.addNewTagOnType;

//         option = this.getTargetType(type, option);
//       } else {
//         if (this.addNewTagOnType !== undefined)
//           option = this.getTargetType(this.addNewTagOnType, option);
//       }

//       this.lastNodeOffset = this.getSelectedStartAndEndPosition();
//       this.lastNodeText = this.getStartSelectedNode().textContent;
//       this.addNewTagOnType = option;

//       if (typeContains(option, TagEnum.Bold)) {
//         this.boldButton.current.classList.add("ar-active");
//       } else {
//         this.boldButton.current.classList.remove("ar-active");
//       }
//       if (typeContains(option, TagEnum.Italic)) {
//         this.italicButton.current.classList.add("ar-active");
//       } else {
//         this.italicButton.current.classList.remove("ar-active");
//       }
//       if (typeContains(option, TagEnum.Link)) {
//         this.linkButton.current.classList.add("ar-active");
//       } else {
//         this.linkButton.current.classList.remove("ar-active");
//       }
//     }
//   };

//   static getDerivedStateFromProps(props, state) {
//     const text = props.value || state.text;
//     const results = getFormattedObject(text);

//     return {
//       formattedObject: results
//     };
//   }

//   linkClick = ({ target, ctrlKey }) => {
//     if (ctrlKey) window.open(target.href, "_blank");
//   };

//   getElementItemByType = (type, item, hash, id) => {
//     const containsLink = typeContains(type, TagEnum.Link);
//     const containsBold = typeContains(type, TagEnum.Bold);
//     const containsItalic = typeContains(type, TagEnum.Italic);

//     if (type === TagEnum.Span) {
//       return (
//         <span ar-htmlinput-key={hash + "-" + id} key={id}>
//           {item.text !== "" ? item.text : <br />}
//         </span>
//       );
//     } else if (type === TagEnum.Bold) {
//       return (
//         <strong ar-htmlinput-key={hash + "-" + id} key={id}>
//           {item.text !== "" ? item.text : <br />}
//         </strong>
//       );
//     } else if (type === TagEnum.Italic) {
//       return (
//         <span
//           ar-htmlinput-key={hash + "-" + id}
//           style={{ fontStyle: "italic" }}
//           key={id}
//         >
//           {item.text !== "" ? item.text : <br />}
//         </span>
//       );
//     } else if (type === TagEnum.Link) {
//       return (
//         <a
//           ar-htmlinput-key={hash + "-" + id}
//           target="_blank"
//           rel="noopener noreferrer"
//           onClick={this.linkClick}
//           href={item.text}
//           key={id}
//         >
//           {item.text !== "" ? item.text : <br />}
//         </a>
//       );
//     } else if (containsBold && containsItalic && containsLink) {
//       return (
//         <a
//           ar-htmlinput-key={hash + "-" + id}
//           target="_blank"
//           rel="noopener noreferrer"
//           onClick={this.linkClick}
//           href={item.text}
//           style={{ fontStyle: "italic", fontWeight: "bold" }}
//           key={id}
//         >
//           {item.text !== "" ? item.text : <br />}
//         </a>
//       );
//     } else if (containsBold && containsItalic) {
//       return (
//         <strong
//           ar-htmlinput-key={hash + "-" + id}
//           style={{ fontStyle: "italic" }}
//           key={id}
//         >
//           {item.text !== "" ? item.text : <br />}
//         </strong>
//       );
//     } else if (containsBold && containsLink) {
//       return (
//         <a
//           ar-htmlinput-key={hash + "-" + id}
//           target="_blank"
//           rel="noopener noreferrer"
//           onClick={this.linkClick}
//           href={item.text}
//           style={{ fontWeight: "bold" }}
//           key={id}
//         >
//           {item.text !== "" ? item.text : <br />}
//         </a>
//       );
//     } else if (containsItalic && containsLink) {
//       return (
//         <a
//           ar-htmlinput-key={hash + "-" + id}
//           target="_blank"
//           rel="noopener noreferrer"
//           onClick={this.linkClick}
//           href={item.text}
//           style={{ fontStyle: "italic" }}
//           key={id}
//         >
//           {item.text !== "" ? item.text : <br />}
//         </a>
//       );
//     }
//   };

//   getElement = () => {
//     const { formattedObject } = this.state;
//     if (!formattedObject || formattedObject.length === 0) {
//       return (
//         <div>
//           <span>
//             <br />
//           </span>
//         </div>
//       );
//     }
//     const hash = GenerateSmallerGuid();
//     let parentKeyRendered = {};
//     return formattedObject.reduce((array, item) => {
//       const type = item.type;
//       const parentKey = item.parentKey;

//       let elRendered = false;
//       if (parentKey && parentKeyRendered[parentKey]) elRendered = true;

//       if (parentKey && !elRendered) {
//         const filteredItems = formattedObject.filter(
//           e => e.parentKey === parentKey
//         );
//         array.push(
//           <div key={"d-" + array.length}>
//             {filteredItems.map((itm, i) =>
//               this.getElementItemByType(
//                 itm.type,
//                 itm,
//                 hash,
//                 array.length + "-" + i
//               )
//             )}
//           </div>
//         );
//         parentKeyRendered[parentKey] = true;
//       } else if (!elRendered) {
//         array.push(this.getElementItemByType(type, item, hash, array.length));
//       }

//       return array;
//     }, []);
//   };

//   inputHasNothing = () => {
//     return (
//       (this.textArea.current.childElementCount === 0 ||
//         (this.textArea.current.childElementCount === 1 &&
//           this.textArea.current.children[0].tagName === "BR")) &&
//       this.textArea.current.textContent === ""
//     );
//   };

//   selectText = (firstDom, lastDom, start, end) => {
//     if (!firstDom || !lastDom) return;
//     var sel, range;
//     range = document.createRange();
//     range.setStart(firstDom.firstChild, start);
//     range.setEnd(lastDom.lastChild, end);
//     sel = window.getSelection();
//     sel.removeAllRanges();
//     sel.addRange(range);
//   };

//   keyDown = e => {
//     if (this.addNewTagOnType !== undefined) {
//       const divs = this.textArea.current.querySelectorAll(":scope > div");
//       if (divs.length === 1 && divs[0].querySelector(":scope > span > br"))
//         this.isFullyEmpty = true;
//       else this.isFullyEmpty = false;
//     }
//     const { keyCode, ctrlKey } = e;
//     switch (keyCode) {
//       case 8: //backspace
//       case 46: //delete
//         this.addNewTagOnType = undefined;
//         break;
//       case 76: //l
//         if (ctrlKey) {
//           this.toggleOption(TagEnum.Link);
//           e.preventDefault();
//         }
//         break;
//       case 73: //i
//         if (ctrlKey) {
//           this.toggleOption(TagEnum.Italic);
//           e.preventDefault();
//         }
//         break;
//       case 66: //b
//         if (ctrlKey) {
//           this.toggleOption(TagEnum.Bold);
//           e.preventDefault();
//         }
//         break;
//       default:
//         break;
//     }
//   };
//   differentiateKeys = () => {
//     const elementss = this.textArea.current.querySelectorAll("a, strong, span");
//     if (elementss.length === 0) return;
//     const key = elementss[0].getAttribute("ar-htmlinput-key");
//     if (!key) return;
//     const hash = elementss[0]
//       .getAttribute("ar-htmlinput-key")
//       .match(new RegExp("^[\\w\\d]+"));

//     let keys = {};
//     let index = 0;
//     for (let i = 0; i < elementss.length; i++) {
//       const currKey = elementss[i].getAttribute("ar-htmlinput-key");
//       if (keys[currKey]) {
//         const newKey = hash + "-r-" + index;
//         elementss[i].setAttribute("ar-htmlinput-key", newKey);
//         keys[newKey] = true;
//       } else {
//         keys[currKey] = true;
//       }
//       index++;
//     }
//   };
//   keyUp = ({ keyCode }) => {
//     this.elementOffsetSelected();

//     switch (keyCode) {
//       case 37: //left arrow
//       case 38: //top arrow
//       case 39: //right arrow
//       case 40: //bottom arrow
//         if (this.addNewTagOnType !== undefined) {
//           this.lastNodeOffset = this.getSelectedStartAndEndPosition();
//           this.lastNodeText = this.getStartSelectedNode().textContent;
//           this.element = this.getStartSelectedNode();
//         }
//         break;
//       case 13: //enter
//         this.differentiateKeys();
//         this.elementOffsetSelected();
//         break;
//       case 46: //del
//       case 8: //backspace
//         if (this.inputHasNothing())
//           this.setState({ text: "" }, () => this.textArea.current.focus());
//         break;
//       default:
//         break;
//     }
//   };
//   mouseUp = () => {
//     if (this.addNewTagOnType !== undefined) {
//       this.lastNodeOffset = this.getSelectedStartAndEndPosition();
//       this.lastNodeText = this.getStartSelectedNode().textContent;
//       this.element = this.getStartSelectedNode();
//     }
//   };
//   mouseDown = () => {
//     if (this.addNewTagOnType !== undefined) return;
//     setTimeout(() => {
//       this.elementOffsetSelected();
//     });
//   };

//   inputText = e => {
//     const { onChange } = this.props;
//     const { target, nativeEvent } = e;
//     if (this.addNewTagOnType !== undefined) {
//       this.addOrRemoveEmptyTag(this.addNewTagOnType, nativeEvent.data);
//     }
//     if (onChange)
//       setTimeout(() => this.triggerChanged(getFormattedText(this.getInputText())));
//   };
//   paste = e => {
//     // cancel paste
//     e.preventDefault();

//     // get text representation of clipboard
//     var text = (e.originalEvent || e).clipboardData.getData("text/plain");

//     // insert text manually
//     document.execCommand("insertHTML", false, text);
//   };
//   elementOffsetSelected = () => {
//     if (this.addNewTagOnType !== undefined) return;

//     this.element = this.getStartSelectedNode();

//     if (this.element) {
//       const elementHtml = getHTML(this.element);
//       if (isBold(elementHtml)) {
//         this.boldButton.current.classList.add("ar-active");
//       } else {
//         this.boldButton.current.classList.remove("ar-active");
//       }

//       if (isItalic(elementHtml)) {
//         this.italicButton.current.classList.add("ar-active");
//       } else {
//         this.italicButton.current.classList.remove("ar-active");
//       }

//       if (isLink(elementHtml)) {
//         this.linkButton.current.classList.add("ar-active");
//       } else {
//         this.linkButton.current.classList.remove("ar-active");
//       }
//     } else {
//       this.boldButton.current.classList.remove("ar-active");
//       this.italicButton.current.classList.remove("ar-active");
//       this.linkButton.current.classList.remove("ar-active");
//     }

//     // boldButton
//     // italicButton
//     // linkButton
//   };

//   render() {
//     const { className } = this.props;

//     const boldAreaActive = false;

//     return (
//       <div className={`ar-htmlinput ${className}`}>
//         <label
//           onKeyDown={this.keyDown}
//           key={GenerateGuid()}
//           ref={this.textArea}
//           contentEditable
//           suppressContentEditableWarning
//           onInput={this.inputText}
//           onMouseUp={this.mouseUp}
//           // onMouseDown={this.elementOffsetSelected}
//           onMouseDown={this.mouseDown}
//           onKeyUp={this.keyUp}
//           onPaste={this.paste}
//         >
//           {this.getElement()}
//         </label>
//         <div className="ar-htmlinput-button-area">
//           <button
//             ref={this.boldButton}
//             onClick={() => this.toggleOption(TagEnum.Bold)}
//           >
//             <FontAwesomeIcon icon={faBold} />
//           </button>
//           <button
//             ref={this.italicButton}
//             onClick={() => this.toggleOption(TagEnum.Italic)}
//           >
//             <FontAwesomeIcon icon={faItalic} />
//           </button>
//           <button
//             ref={this.linkButton}
//             onClick={() => this.toggleOption(TagEnum.Link)}
//           >
//             <FontAwesomeIcon icon={faLink} />
//           </button>
//         </div>
//       </div>
//     );
//   }
// }

// export default HTMLInput;
