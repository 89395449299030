import React, { useRef, useCallback, useContext } from "react";
import Popup, { usePopupOpenState } from "../Popup/Popup";
import Button from "../Button/Button";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { ObfuscateProvider } from "../../Containers/Space/Space2";
import { MenuContext } from "../ContextMenu/ContextMenu";
import { EntityPopupContext } from "./EntityPopupHelper";
import InformationComponent from "../InformationComponent/InformationComponent";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";

export const EntityPopup = ({ className, children, minimal, icon }: any) => {
  const anchorRef = useRef(null);
  const popupRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  const toggleOffParent = useContext(EntityPopupContext);
  const toggleOff = useCallback(() => {
    if (typeof toggleIsOpen === "function") toggleIsOpen(false);
    if (typeof toggleOffParent === "function") toggleOffParent();
  }, [toggleIsOpen, toggleOffParent]);
  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        vType={minimal ? undefined : "primary-ghost"}
        className={classnames(
          minimal ? "ar-minimal-button" : "ssi-button-grid sm",
          className
        )}
        onClick={(e: any) => {
          e.stopPropagation();
          if (typeof toggleIsOpen === "function") toggleIsOpen(true);
        }}
      >
        {icon ? icon : <FontAwesomeIcon size="lg" icon={faEllipsisV} />}
      </Button>
      <Popup
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        domRef={popupRef}
        anchorEl={anchorRef.current}
        isOpen={isOpen}
        placement="bottom-start"
      >
        {() => {
          return (
            <MenuContext.Provider value={true}>
              <EntityPopupContext.Provider value={toggleOff}>
                <div
                  className="py-2 ar-grid-buttons-popup bg-white rounded"
                  onDoubleClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {children}
                  {/* {React.Children.map(children, (child1) => {
                    if (!child1) return child1;
                    return React.Children.map(child1, (child2) => {
                      if (!child2) return child2;
                       return React.Children.map(child2, (child3) => {
												if (!child3) return child3;
												return React.cloneElement(child3, {
													link: true,
													show: true,
													onSuccess: () => toggleIsOpen(false)
												});
											}); 
                    });
                  })} */}
                </div>
              </EntityPopupContext.Provider>
            </MenuContext.Provider>
          );
        }}
      </Popup>
    </React.Fragment>
  );
};

export const NewEntityPopup = ({ className, children, minimal, icon }: any) => {
  const anchorRef = useRef(null);
  const popupRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  const toggleOffParent = useContext(EntityPopupContext);
  const toggleOff = useCallback(() => {
    if (typeof toggleIsOpen === "function") toggleIsOpen(false);
    if (typeof toggleOffParent === "function") toggleOffParent();
  }, [toggleIsOpen, toggleOffParent]);
  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        size="sm-40"
        vType="tertiary"
        onClick={(e: any) => {
          e.stopPropagation();
          if (typeof toggleIsOpen === "function") toggleIsOpen(true);
        }}
      >
        {icon ? icon : <FontAwesomeIcon size="lg" icon={faEllipsisV} />}
      </Button>
      <Popup
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        domRef={popupRef}
        anchorEl={anchorRef.current}
        isOpen={isOpen}
        placement="bottom-start"
      >
        {() => {
          return (
            <MenuContext.Provider value={true}>
              <EntityPopupContext.Provider value={toggleOff}>
                <div
                  className="py-2 ar-grid-buttons-popup bg-white rounded"
                  onDoubleClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {children}
                  {/* {React.Children.map(children, (child1) => {
                    if (!child1) return child1;
                    return React.Children.map(child1, (child2) => {
                      if (!child2) return child2;
                       return React.Children.map(child2, (child3) => {
												if (!child3) return child3;
												return React.cloneElement(child3, {
													link: true,
													show: true,
													onSuccess: () => toggleIsOpen(false)
												});
											}); 
                    });
                  })} */}
                </div>
              </EntityPopupContext.Provider>
            </MenuContext.Provider>
          );
        }}
      </Popup>
    </React.Fragment>
  );
};

export const EntityPopupHeaderTogglerContext =
  React.createContext<any>(undefined);

export const EntityPopupHeader = ({
  className,
  children,
  icon,
  placement,
  style,
  tooltipText,
  tooltipStyle,
  tooltipPlacement
}: any) => {
  const anchorRef = useRef(null);
  const popupRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);

  const ObfuscateState = useContext(ObfuscateProvider);
  const handleClick = (e: any) => {
    if (ObfuscateState === true) e.preventDefault();
    else {
      if (typeof toggleIsOpen === "function") toggleIsOpen(true);
    }
  };

  return (
    <React.Fragment>
      <InformationComponent
        placement={tooltipPlacement}
        style={tooltipStyle}
        text={tooltipText}
      >
        <div
          style={{
            color: "#fff",
            opacity: ObfuscateState === true ? 0.3 : undefined,
            pointerEvents: ObfuscateState === true ? "none" : undefined
          }}
          ref={anchorRef}
          className={classnames("ar-header-rounded-icon", className)}
          onClick={handleClick}
        >
          {icon ? icon : <FontAwesomeIcon size="lg" icon={faPlus} />}
        </div>
      </InformationComponent>
      <Popup
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        exit={100}
        style={{ ...style, display: !isOpen }}
        domRef={popupRef}
        anchorEl={anchorRef.current}
        isOpen={isOpen}
        placement={placement ? placement : "bottom-start"}
      >
        {() => {
          return (
            <EntityPopupHeaderTogglerContext.Provider value={toggleIsOpen}>
              <div className="py-2 ar-grid-buttons-popup bg-white rounded">
                {/* {React.Children.map(children, child => {
                  if (!child) return child;
                  return React.cloneElement(child, {
                    link: true,
                    show: true,
                    onSuccess: () => toggleIsOpen(false)
                  });
                })} */}
                {children}
              </div>
            </EntityPopupHeaderTogglerContext.Provider>
          );
        }}
      </Popup>
    </React.Fragment>
  );
};

export const EntityHr = () => {
  return <hr className="my-1" />;
};
