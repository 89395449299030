import { faFileAlt, faStarExclamation } from "@fortawesome/pro-light-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import {
  EntityDescriptionOverviewItem,
  EntityOverviewItem
} from "../EntityOverview/EntityOverview";
import {
  DescriptionOverview,
  EntitySummaryAccordion
} from "../EntitySummaries/EntitySummaries";
import { ReactComponent as Angry } from "../../Assets/Svg/TicketRatingFaces/angry.svg";
import { ReactComponent as Frown } from "../../Assets/Svg/TicketRatingFaces/frown.svg";
import { ReactComponent as Meh } from "../../Assets/Svg/TicketRatingFaces/meh.svg";
import { ReactComponent as Smile } from "../../Assets/Svg/TicketRatingFaces/smile.svg";
import { ReactComponent as Laugh } from "../../Assets/Svg/TicketRatingFaces/laugh.svg";
import "./TicketRating.css";
import { useIntl } from "react-intl";

const RatingDictionary = {
  1: "VERY_BAD",
  2: "BAD",
  3: "AVERAGE",
  4: "GOOD",
  5: "VERY_GOOD"
};

const RatingFace = ({ value, ...props }) => {
  switch (parseInt(value)) {
    case 1:
      return <Angry {...props} />;

    case 2:
      return <Frown {...props} />;

    case 3:
      return <Meh {...props} />;

    case 4:
      return <Smile {...props} />;

    case 5:
      return <Laugh {...props} />;

    default:
      return null;
  }
};

export const TicketRatingComponent = ({ value }) => {
  const intl = useIntl();
  const translation = intl.formatMessage({
    id: RatingDictionary[parseInt(value)]
  });
  return (
    <div className="d-flex align-items-center">
      <RatingFace value={value} className="mr-3 face-sizes" />
      <div
        className={`d-flex px-2 py-1 rounded fs-14 ticket-rating-pill-${value}`}
      >
        {`${value} - ${translation}`}
      </div>
    </div>
  );
};

const TicketBrowserJsonContent = ({ json }) => {
  function syntaxHighlight(json) {
    json = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      function (match) {
        var cls = "number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "key";
          } else {
            cls = "string";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
        } else if (/null/.test(match)) {
          cls = "null";
        }
        return '<span class="' + cls + '">' + match + "</span>";
      }
    );
  }

  const parsed = JSON.parse(json);
  const stringif = JSON.stringify(parsed, undefined, 4);
  const content = syntaxHighlight(stringif);
  return (
    <pre
      dangerouslySetInnerHTML={{ __html: content }}
      className="bg-airdesk border p-3 mt-2 tirac"
    ></pre>
  );
};

const TicketRatingAccordion = ({ value, feedback }) => {
  const { Feedback, BrowserJson } = feedback || {};
  return (
    <EntitySummaryAccordion icon={faStar} textId={"RATING"}>
      <EntityOverviewItem icon={faStarExclamation} titleId="SCORE">
        <TicketRatingComponent value={value} />
      </EntityOverviewItem>
      {Feedback && (
        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="DESCRIPTION">
          <DescriptionOverview
            Description={Feedback}
            NoTagDescription={Feedback}
          />
        </EntityDescriptionOverviewItem>
      )}
      {BrowserJson && (
        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="DETAILS">
          <TicketBrowserJsonContent json={BrowserJson} />
        </EntityDescriptionOverviewItem>
      )}
    </EntitySummaryAccordion>
  );
};

export default TicketRatingAccordion;
