import React, { useRef, useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faList,
  faMap,
  faLocation
} from "@fortawesome/pro-light-svg-icons";
import "./AddressSelector.css";
import { useQuery } from "../../Helpers/IOClient";
import FormInput from "../Forms/FormInput/FormInput";
import { CountryDropdown } from "../../Containers/AdvancedMultiInputs";
import { FormattedMessage, useIntl } from "react-intl";
import DropdownPopover from "../_Dropdown/DropdownPopover";
import { BarLoader } from "../GlobalLoader/GlobalLoader";
import { faMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons";
import { useGoogleMaps } from "../../Containers/GoogleMaps/GoogleMaps";
import Button from "../Button/Button";
import { useToast } from "../Toast/ToastProvider";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import classnames from "classnames";
import Popup from "../Popup/Popup";
import { useHoverEvents } from "../../Containers/Origin/Origin";

const AddressSelector = ({ value = {}, onChange, preview, onboarding }) => {
  const intl = useIntl();
  const inputValue = useRef();
  const inputRef = useRef();
  const PlaceHolderText = useRef(intl.formatMessage({ id: "SEARCH" }));
  const [input, setInput] = useState("");
  const [resolvedInput, setResolvedInput] = useState("");
  const [detailsState, setDetailsState] = useState(
    value.Id || value.Country || value.Address1 ? "details" : null
  );
  const [suggestionId, setSuggestionId] = useState(null);
  const [countryDescription, setCountryDescription] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [closeDropdown, setCloseDropdown] = useState(false);

  const [dropdownSelected, setDropdownSelected] = useState(null);

  useEffect(() => {
    const timeout = closeDropdown
      ? setTimeout(() => {
          setResolvedInput("");
          setInput("");
          setCoordinates(null);
          setCloseDropdown(false);
        }, 100)
      : undefined;
    return () => clearTimeout(timeout);
  }, [closeDropdown]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setResolvedInput(input);
    }, 600);

    return () => clearTimeout(timeout);
  }, [input]);

  const { data, error, loading } = useQuery(
    resolvedInput.length > 3
      ? `Map/Places/Autocomplete?input=${resolvedInput}`
      : null
  );

  const {
    // data: placeData,
    // error: placeError,
    loading: placeLoading
  } = useQuery(
    suggestionId ? `Map/Places/Detail?id=${suggestionId}` : null,
    null,
    {
      cache: false,
      onSuccess: ({ data }) => {
        onChange({
          ...value,
          Address1: data.Address || "",
          District: data.AdministrativeAreaLevel1 || "",
          Locality: data.Locality || "",
          PostalCode: data.PostalCode || "",
          Latitude: data.Latitude || 0,
          Longitude: data.Longitude || 0
        });
        setCountryDescription(data.Country);
      }
    }
  );

  const {
    // data: countryData,
    // error: countryError,
    loading: countryLoading
  } = useQuery(
    countryDescription
      ? `query/countries?$top=100&query=${countryDescription}`
      : null,
    null,
    {
      onSuccess: ({ data }) => {
        setCountryDescription(null);
        onChange({ ...value, Country: data[0].Id });
      }
    }
  );

  const {
    data: geoPoData,
    error: geoPoError,
    loading: geoPoLoading
  } = useQuery(
    coordinates
      ? `Map/Geocode/Reverse?lat=${coordinates.Latitude}&lng=${coordinates.Longitude}`
      : null,
    null
  );

  const handleChanges = (e) => {
    const { name, value: targetValue } = e.target;

    onChange({ ...value, [name]: targetValue });
  };

  const handleApplyCoordinates = (e) => {
    onChange({
      ...value,
      Address1: "",
      Address2: "",
      Country: "",
      District: "",
      Locality: "",
      PostalCode: "",
      Latitude: e.Latitude,
      Longitude: e.Longitude
    });
    setDetailsState("details");
    setCoordinates(null);
  };

  const handleCoordinateChanges = useCallback((value) => {
    if (!value) setCoordinates(null);
    else
      setCoordinates({ Latitude: value.Latitude, Longitude: value.Longitude });
  }, []);
  const Intl = useIntl();
  const createToast = useToast();
  const [locating, setLocating] = useState(false);
  const getLocation = () => {
    if (navigator.geolocation) {
      setLocating(true);
      navigator.geolocation.getCurrentPosition(
        () => {
          navigator.geolocation.getCurrentPosition((position) => {
            onChange({
              ...value,
              Latitude:
                value.Latitude === position.coords.latitude
                  ? position.coords.latitude + 0.0000001
                  : position.coords.latitude,
              Longitude: position.coords.longitude
            });
            // setCoordinates({
            //   Latitude: position.coords.latitude,
            //   Longitude: position.coords.longitude
            // });
            setDetailsState("map");
            createToast({
              pos: "tm",
              type: "success",
              description: `${Intl.formatMessage({
                id: "GEOLOCATION_SUCCESS"
              })}`
            });
            setLocating(false);
          });
        },
        (error) => {
          //onError
          switch (error.code) {
            case error.PERMISSION_DENIED:
              createToast({
                pos: "tm",
                type: "danger",
                description: `${Intl.formatMessage({
                  id: "GEOLOCATION_REFUSED"
                })}`,
                title: `Erro`
              });
              break;
            case error.POSITION_UNAVAILABLE:
              createToast({
                pos: "tm",
                type: "danger",
                description: `${Intl.formatMessage({
                  id: "GEOLOCATION_UNAVAILABLE"
                })}`,
                title: `Erro`
              });
              break;
            case error.TIMEOUT:
              createToast({
                pos: "tm",
                type: "danger",
                description: `${Intl.formatMessage({
                  id: "GEOLOCATION_TIMEOUT"
                })}`,
                title: `Erro`
              });
              break;
            case error.UNKNOWN_ERROR:
              createToast({
                pos: "tm",
                type: "danger",
                description: `${Intl.formatMessage({ id: "ERROR_HAPPEND" })}`,
                title: `Erro`
              });
              break;
            default:
              break;
          }
          setLocating(false);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 10000,
          timeout: 5000
        }
      );
    } else {
      //Not suported
      createToast({
        pos: "tm",
        type: "danger",
        description: `${Intl.formatMessage({
          id: "GEOLOCATION_BROWSER_ERROR"
        })}`,
        title: `Erro`
      });
    }
  };

  const handleKeyDownEvent = (e) => {
    if (!data && !geoPoData) return;
    const activeArray = detailsState === "map" ? geoPoData : data || geoPoData;
    const length = activeArray.length;
    if (e.keyCode === 40 || e.keyCode === 38) {
      //   console.log(e.keyCode);

      let nextIndex = dropdownSelected;

      if (e.keyCode === 40) {
        // keyDown
        if (dropdownSelected === null) nextIndex = 0;
        else nextIndex++;
      } else if (e.keyCode === 38) {
        // keyUp
        if (dropdownSelected === null) nextIndex = length ? length : 0;
        else nextIndex--;
      }

      if (length === 0)
        nextIndex = dropdownSelected === null ? nextIndex : null;
      else if (nextIndex === -1) nextIndex = length || 0;
      else if (nextIndex === length + 1) nextIndex = 0;
      setDropdownSelected(nextIndex);
    } else if (e.keyCode === 13) {
      e.preventDefault();
      if (length === 0 && dropdownSelected === 0) {
        PlaceHolderText.current = inputRef.current.value;
        setResolvedInput("");
        setInput("");
        onChange({ ...value, Address1: inputRef.current.value });
        setDetailsState("details");
      } else if (length > 0 && dropdownSelected === length) {
        PlaceHolderText.current = inputRef.current.value;
        setResolvedInput("");
        setInput("");
        onChange({ ...value, Address1: inputRef.current.value });
        setDetailsState("details");
      } else if (dropdownSelected !== null) {
        PlaceHolderText.current = data
          ? activeArray[dropdownSelected].Description
          : activeArray[dropdownSelected].Address;
        setResolvedInput("");
        setInput("");
        setSuggestionId(activeArray[dropdownSelected].Id);
        setDetailsState("details");
        setCoordinates(null);
        setDropdownSelected(null);
      }
    }

    // if (e.keyCode === 40) {

    //   if (dropdownSelected === null) setDropdownSelected(0);
    //   else if (length === 0) setDropdownSelected(null);
    //   else setDropdownSelected(dropdownSelected + 1);
    // } else if (e.keyCode === 38) {
    //   if (dropdownSelected === null && length === 0) setDropdownSelected(0);
    //   else if (dropdownSelected === null || dropdownSelected === 0)
    //     setDropdownSelected(length - 1);
    //   else if (length === 0) setDropdownSelected(null);
    //   else setDropdownSelected(dropdownSelected - 1);
    // }
  };

  const handleSuggestionClick = useCallback((e) => {
    PlaceHolderText.current = e.Description;
    setResolvedInput("");
    setInput("");
    setSuggestionId(e.Id);
    setDetailsState("details");
  }, []);

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave
    // isOpen
  } = useHoverEvents();

  const popoverSearchBar = !preview ? (
    <>
      <div
        ref={inputValue}
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        className={classnames(
          "AddressContainerDiv dropdown-container ssi-control ar-dropdown-container d-flex justify-content-lg-end align-items-center p-0 disable-selection",
          { "bg-white": onboarding }
        )}
      >
        <div className="AddressSelector w-100 flex-1">
          <input
            onKeyDown={handleKeyDownEvent}
            disabled={preview}
            ref={inputRef}
            autoComplete="off"
            value={input}
            style={{
              marginBottom: 1,
              borderColor:
                document.activeElement === inputRef.current ? "#007bff" : ""
            }}
            onChange={(e) => {
              setSuggestionId(null);
              setInput(e.target.value);
            }}
            onBlur={() => {
              if (setCloseDropdown) setCloseDropdown(true);
            }}
            placeholder={intl.formatMessage({ id: "SEARCH" })}
            className={classnames("ssi-input ssi-control disable-selection", {
              "bg-white": onboarding
            })}
            type="text"
            name="SearchBar"
          ></input>
        </div>
        <div className="AddressSelector SearchButton d-flex justify-content-center align-items-center px-1">
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <div
          className={`AddressSelector ${
            detailsState === "details" && "AtiveSelector"
          } Buttons d-flex justify-content-center align-items-center px-1`}
          onClick={() => {
            if (detailsState !== "details") setDetailsState("details");
            else setDetailsState(null);
          }}
        >
          <FontAwesomeIcon icon={faList} />
        </div>
        <div
          className={`AddressSelector ${
            detailsState === "map" && "AtiveSelector"
          } Buttons d-flex justify-content-center align-items-center px-1`}
          onClick={() => {
            if (detailsState !== "map") setDetailsState("map");
            else setDetailsState(null);
          }}
        >
          <FontAwesomeIcon icon={faMap} />
        </div>
        <div
          onClick={() => {
            getLocation();
          }}
          className={classnames(
            "AddressSelector Buttons d-flex justify-content-center align-items-center px-1",
            { DisabledClickEvent: locating }
          )}
        >
          {locating ? (
            <LoaderSpinner size="xs" />
          ) : (
            <FontAwesomeIcon icon={faLocation} />
          )}
        </div>
      </div>
      {input.length > 3 ? (
        <Popup
          isOpen={(loading || error || data) && input.length > 3}
          anchorEl={inputValue.current}
          // loadingComponent={BarLoader}
          // isLoading={loading}
          modifiers={{
            preventOverflow: { boundariesElement: "viewport" }
          }}
          onMouseEnter={onPopupMouseEnter}
          onMouseLeave={onPopupMouseLeave}

          // onClose={() => {
          //   setInput("");
          //   setDropdownSelected(null);
          // }}
        >
          <div style={{ width: 476 }} className="bg-white">
            {data &&
              data.map((e, i) => {
                return (
                  <div
                    key={e.Id}
                    style={{
                      background:
                        i === dropdownSelected ? "rgba(20, 115, 230, 0.1)" : ""
                    }}
                    className="AddressSelector fs-14 Suggestion ssi-link d-flex flex-1 w-100 breakWord"
                    onClick={() => {
                      handleSuggestionClick(e);
                    }}
                  >
                    <div style={{ color: "#7C98B6", marginRight: 16 }}>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </div>
                    <div className="AddressSelector-suggestion">
                      {e.Description}
                    </div>
                  </div>
                );
              })}
            {data && data.length === 0 && (
              <>
                <div
                  //   key={e.Id}
                  style={{
                    background:
                      0 === dropdownSelected ? "rgba(20, 115, 230, 0.1)" : ""
                  }}
                  className="AddressSelector Suggestion ssi-link d-flex flex-1 breakWord"
                  onClick={() => {
                    PlaceHolderText.current = inputRef.current.value;
                    setResolvedInput("");
                    setInput("");
                    onChange({ ...value, Address1: inputRef.current.value });
                    setDetailsState("details");
                  }}
                >
                  <div style={{ color: "#1e73f0", marginRight: 16 }}>
                    <FontAwesomeIcon icon={faList} />
                  </div>
                  <div>
                    <FormattedMessage id={"ADD_ADDRESS"} />
                  </div>
                </div>
                <div className="AddressSelector Suggestion">
                  <FormattedMessage id={"NO_RECORDS"} />
                </div>
              </>
            )}
            {data && data.length > 0 && (
              <>
                <div
                  //   key={e.Id}
                  style={{
                    background:
                      data.length === dropdownSelected
                        ? "rgba(20, 115, 230, 0.1)"
                        : ""
                  }}
                  className="AddressSelector Suggestion ssi-link d-flex flex-1 breakWord"
                  onClick={() => {
                    PlaceHolderText.current = inputRef.current.value;
                    setResolvedInput("");
                    setInput("");
                    onChange({ ...value, Address1: inputRef.current.value });
                    setDetailsState("details");
                  }}
                >
                  <div style={{ color: "#1e73f0", marginRight: 16 }}>
                    <FontAwesomeIcon icon={faList} />
                  </div>
                  <div>
                    <FormattedMessage id={"ADD_ADDRESS"} />
                  </div>
                </div>
              </>
            )}
          </div>
        </Popup>
      ) : coordinates ? (
        <DropdownPopover
          isOpen={(geoPoLoading || geoPoError || geoPoData) && coordinates}
          anchorEl={inputValue.current}
          loadingComponent={BarLoader}
          isLoading={geoPoLoading}
          onClose={() => {
            setCoordinates(null);
            setDropdownSelected(null);
          }}
        >
          {geoPoData &&
            geoPoData.map((e, i) => {
              return (
                <div
                  key={e.Id}
                  style={{
                    background:
                      i === dropdownSelected ? "rgba(20, 115, 230, 0.1)" : ""
                  }}
                  className="AddressSelector fs-14 Suggestion text-black ssi-link d-flex flex-1 breakWord"
                  onClick={() => {
                    PlaceHolderText.current = e.Address;
                    setResolvedInput("");
                    setInput("");
                    setSuggestionId(e.Id);
                    setDetailsState("details");
                    setCoordinates(null);
                  }}
                >
                  <div style={{ color: "#7C98B6", marginRight: 16 }}>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </div>
                  <div>{e.Description || e.Address}</div>
                </div>
              );
            })}
          {geoPoData && geoPoData.length === 0 && (
            <>
              <div
                //   key={e.Id}
                style={{
                  background:
                    0 === dropdownSelected ? "rgba(20, 115, 230, 0.1)" : ""
                }}
                className="AddressSelector Suggestion ssi-link d-flex flex-1 breakWord"
                onClick={() => {
                  onChange({
                    ...value,
                    Address1: "",
                    Address2: "",
                    Country: "",
                    District: "",
                    Locality: "",
                    PostalCode: "",
                    Latitude: coordinates.Latitude,
                    Longitude: coordinates.Longitude
                  });
                  setDetailsState("details");
                  setCoordinates(null);
                }}
              >
                <div style={{ color: "#1e73f0", marginRight: 16 }}>
                  <FontAwesomeIcon icon={faList} />
                </div>
                <div>
                  {" "}
                  <FormattedMessage id={"ADD_ADDRESS"} />
                </div>
              </div>
              <div className="AddressSelector Suggestion">
                <FormattedMessage id={"NO_RECORDS"} />
              </div>
            </>
          )}
          {geoPoData && geoPoData.length > 0 && (
            <>
              <div
                //   key={e.Id}
                style={{
                  background:
                    geoPoData.length === dropdownSelected
                      ? "rgba(20, 115, 230, 0.1)"
                      : ""
                }}
                className="AddressSelector Suggestion ssi-link d-flex flex-1 breakWord"
                onClick={() => {
                  onChange({
                    ...value,
                    Address1: "",
                    Address2: "",
                    Country: "",
                    District: "",
                    Locality: "",
                    PostalCode: "",
                    Latitude: coordinates.Latitude,
                    Longitude: coordinates.Longitude
                  });
                  setDetailsState("details");
                  setCoordinates(null);
                }}
              >
                <div style={{ color: "#1e73f0", marginRight: 16 }}>
                  <FontAwesomeIcon icon={faList} />
                </div>
                <div>
                  <FormattedMessage id={"ADD_ADDRESS"} />
                </div>
              </div>
            </>
          )}
        </DropdownPopover>
      ) : null}
    </>
  ) : null;

  return (
    <>
      {popoverSearchBar}
      {detailsState && (
        <div className="AddressSelector Dropdown disable-selection">
          {detailsState === "details" && (
            <div>
              <BarLoader
                style={{ top: 0 }}
                isLoading={countryLoading || placeLoading}
              />
              <div className="px-4 py-3">
                <FormInput
                  disableSelect="disable-selection"
                  disabled={preview}
                  className="mb-3"
                  name={"Country"}
                  value={value.Country}
                  text={<FormattedMessage id="COUNTRY" />}
                  inputType={CountryDropdown}
                  onChange={handleChanges}
                />
                <FormInput
                  disableSelect="disable-selection"
                  disabled={preview}
                  className="mb-2"
                  name="Address1"
                  value={value.Address1}
                  text={<FormattedMessage id="ADDRESS" />}
                  onChange={handleChanges}
                />
                <FormInput
                  className="mb-3"
                  disableSelect="disable-selection"
                  disabled={preview}
                  name="Address2"
                  value={value.Address2}
                  //   text={<FormattedMessage id="address" />}
                  onChange={handleChanges}
                />
                <FormInput
                  className="mb-3"
                  disableSelect="disable-selection"
                  disabled={preview}
                  name="District"
                  value={value.District}
                  text={<FormattedMessage id="REGION_STATE_DISCTRICT" />}
                  onChange={handleChanges}
                />
                <FormInput
                  disableSelect="disable-selection"
                  disabled={preview}
                  className="mb-3"
                  name="Locality"
                  value={value.Locality}
                  text={<FormattedMessage id="CITY" />}
                  onChange={handleChanges}
                />
                <FormInput
                  disableSelect="disable-selection"
                  disabled={preview}
                  className="mb-3"
                  name="PostalCode"
                  value={value.PostalCode}
                  text={<FormattedMessage id="POSTAL_CODE" />}
                  onChange={handleChanges}
                />
                <FormInput
                  className="mb-3"
                  disableSelect="disable-selection"
                  disabled={preview}
                  name="Latitude"
                  value={value.Latitude}
                  text={<FormattedMessage id={"LATITUDE"} />}
                  onChange={handleChanges}
                />
                <FormInput
                  disableSelect="disable-selection"
                  disabled={preview}
                  className="mb-3"
                  name="Longitude"
                  value={value.Longitude}
                  text={<FormattedMessage id={"LONGITUDE"} />}
                  onChange={handleChanges}
                />
              </div>
            </div>
          )}
          {detailsState === "map" && (
            <div className="disable-selection">
              {/* <iframe
              //   key={"maperino"}
              title="map"
              className="w-100"
              height="500"
              frameBorder="0"
              style={{ border: 0 }}
              src={
                "https://www.google.com/maps/embed/v1/search?key=AIzaSyAh9Q5FPTFQrsak30rcdhnj6hh0wpXrWss&q=Portugal"
              }
            /> */}
              <GoogleMap
                inputRef={inputRef}
                key={`${value.Latitude}-${value.Longitude}`}
                lat={value.Latitude}
                lng={value.Longitude}
                onSearch={handleCoordinateChanges}
                onApply={handleApplyCoordinates}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const MapComponent = ({
  lat,
  lng,
  onApply,
  onSearch,
  inputRef,
  google,
  ...props
}) => {
  const domMapRef = useRef();
  // const [closeDropdown, setCloseDropdown] = useState(false);

  const [coordinates, setCoordinates] = useState(() => {
    if (lat && lng)
      return {
        Latitude: lat,
        Longitude: lng
      };
    else return { Latitude: 37.1299603, Longitude: -8.0408227 };
  });

  //   useEffect(() => {
  //     const timeout = closeDropdown
  //       ? setTimeout(() => {
  //           onSearch(null);
  //           setCloseDropdown(false);
  //         }, 100)
  //       : undefined;
  //     return () => clearTimeout(timeout);
  //   }, [closeDropdown, onSearch]);

  const mapRef = useRef();
  const markerRef = useRef();
  useEffect(() => {
    if (!google) return;
    var center =
      lat && lng
        ? { lat: parseFloat(lat), lng: parseFloat(lng) }
        : { lat: 37.1299603, lng: -8.0408227 };
    var map = new google.maps.Map(domMapRef.current, {
      zoom: 16,
      center: center,
      streetViewControl: false,
      mapTypeControlOptions: {
        mapTypeIds: [
          google.maps.MapTypeId.ROADMAP,
          google.maps.MapTypeId.SATELLITE
        ]
      }
    });

    mapRef.current = map;

    // The marker, positioned at Uluru
    markerRef.current = new google.maps.Marker({
      position: lat && lng ? new google.maps.LatLng(lat, lng) : center,
      map: map,
      draggable: true
    });

    // Create a div to hold the control.
    var controlDiv = document.createElement("div");
    const eventOne = controlDiv.addEventListener("contextmenu", (event) =>
      event.preventDefault()
    );
    // Set CSS for the control border
    var controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginBottom = "0px";
    controlUI.style.textAlign = "center";
    controlUI.title = "Click to recenter the map";
    controlUI.style.height = "40px";
    controlUI.style.width = "40px";
    controlUI.style.boxShadow = "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px";
    controlUI.style.marginRight = "10px";
    controlUI.style.userSelect = "none";
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior
    var controlText = document.createElement("div");
    controlText.style.color = "#666666";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.lineHeight = "38px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.insertAdjacentHTML(
      "afterbegin",
      `<div><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="home" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-home fa-w-18 fa-lg"><path fill="currentColor" d="M570.24 247.41L512 199.52V104a8 8 0 0 0-8-8h-32a8 8 0 0 0-7.95 7.88v56.22L323.87 45a56.06 56.06 0 0 0-71.74 0L5.76 247.41a16 16 0 0 0-2 22.54L14 282.25a16 16 0 0 0 22.53 2L64 261.69V448a32.09 32.09 0 0 0 32 32h128a32.09 32.09 0 0 0 32-32V344h64v104a32.09 32.09 0 0 0 32 32h128a32.07 32.07 0 0 0 32-31.76V261.67l27.53 22.62a16 16 0 0 0 22.53-2L572.29 270a16 16 0 0 0-2.05-22.59zM463.85 432H368V328a32.09 32.09 0 0 0-32-32h-96a32.09 32.09 0 0 0-32 32v104h-96V222.27L288 77.65l176 144.56z" class=""></path></svg></div>`
    );
    controlUI.appendChild(controlText);
    controlDiv.index = 1;

    const eventTwo = controlDiv.addEventListener("click", () => {
      const center = new google.maps.LatLng(37.1299603, -8.0408227);
      map.panTo(center);
      map.setZoom(16);
    });

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);

    // var infowindow = new google.maps.InfoWindow({});
    // google.maps.event.addListener(marker, "dragend", function() {
    //   infowindow.setContent(
    //     "<div style='height:60px;width:auto;'>" +
    //       "<br>" +
    //       marker.getPosition().toUrlValue(6) +
    //       "</div>"
    //   );
    //   infowindow.open(map, marker);
    // });
    return () => {
      controlDiv.removeEventListener("contextmenu", eventOne);
      controlDiv.removeEventListener("click", eventTwo);
    };
  }, [google, lat, lng]);

  useEffect(() => {
    if (markerRef.current) {
      const { Latitude, Longitude } = coordinates;
      markerRef.current.setPosition({
        lat: Number(Latitude),
        lng: Number(Longitude)
      });
    }
  }, [coordinates]);

  useEffect(() => {
    const e = google.maps.event.addListener(
      mapRef.current,
      "click",
      function (event) {
        if (markerRef.current) {
          markerRef.current.setPosition({
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
          });
          setCoordinates({
            Latitude: event.latLng.lat(),
            Longitude: event.latLng.lng()
          });
        }
      }
    );

    const i = google.maps.event.addListener(
      markerRef.current,
      "dragend",
      function (event) {
        if (markerRef.current) {
          markerRef.current.setPosition({
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
          });
          setCoordinates({
            Latitude: event.latLng.lat(),
            Longitude: event.latLng.lng()
          });
        }
      }
    );

    return () => {
      google.maps.event.removeListener(e);
      google.maps.event.removeListener(i);
    };
  }, [coordinates, google.maps.event]);

  const UpdateCoordinates = (update) => {
    setCoordinates((c) => {
      return {
        ...c,
        ...update
      };
    });
  };

  const { Latitude, Longitude } = coordinates;

  return (
    <div>
      <div style={{ height: 350 }} ref={domMapRef}></div>
      <div className="px-4 my-3">
        <FormInput
          className="mb-3"
          name="Latitude"
          value={Latitude}
          text={<FormattedMessage id={"LATITUDE"} />}
          onChange={(e) => {
            UpdateCoordinates({
              Latitude: e.target.value
            });
          }}
        />
        <FormInput
          className="mb-3"
          name="Longitude"
          value={Longitude}
          text={<FormattedMessage id={"LONGITUDE"} />}
          onChange={(e) => {
            UpdateCoordinates({
              Longitude: e.target.value
            });
          }}
        />
        <div
          className="w-100 d-flex justify-content-between"
          style={{ color: "#F1F5FA" }}
        >
          <Button
            type="button"
            vType="primary-ghost"
            onClick={() => {
              onSearch(coordinates);
              inputRef.current.focus();
            }}
          >
            <div>
              <FormattedMessage id={"SEARCH"} />{" "}
              <FontAwesomeIcon className="ml-2" icon={faSearch} />
            </div>
          </Button>
          <Button type="button" onClick={() => onApply(coordinates)}>
            <FormattedMessage id={"APPLY"} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export const GoogleMap = ({ ...props }) => {
  const google = useGoogleMaps();
  if (!google)
    return (
      <div
        style={{ height: 350 }}
        className="w-100 d-flex justify-content-center align-items-center text-color-link"
      >
        <LoaderSpinner size="sm" />
      </div>
    );
  else return <MapComponent google={google} {...props} />;
};

export default AddressSelector;
