import React, { useRef, useMemo, useContext } from "react";
import { useHoverEvents } from "../../Containers/Origin/Origin";
import classnames from "classnames";
import Button from "../Button/Button";
import Popup from "../Popup/Popup";
import {
  createDay,
  useTimelineZoom,
  TimelineSettingsContext
} from "./TimelineHelper";

const HeaderItem = React.memo(function HeaderItem({
  value,
  nowMilisec,
  holiday
}) {
  const { key } = value;
  const anchorRef = useRef();
  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  const { width } = useTimelineZoom();
  const { HeaderButton } = useContext(TimelineSettingsContext);

  const { hoverTitle, onClick } = HeaderButton;
  return (
    <div
      className={classnames(
        "ar-timeline-grid-item ar-timeline-grid-header-item",
        {
          today: nowMilisec === key
        }
      )}
      style={{ width }}
    >
      <Button
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        ref={anchorRef}
        vType="link"
        onClick={() => onClick(value.key)}
      >
        <span className="text-black text-uppercase">
          {value.date.format("MMM")}
        </span>
      </Button>
      <Popup
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        placement={"bottom-center"}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <div className="bg-black text-white px-2 py-1 fs-14 rounded">
          {hoverTitle}
        </div>
      </Popup>
    </div>
  );
});

const buildYearTimes = (times, startIndex = 0) => {
  const time = times[startIndex];
  const maxIndex = times.length - 1;

  const { date } = time;

  const currentMonth = date.get("month");
  const monthsAhead = 12 - currentMonth;

  let endIndex = startIndex + monthsAhead;

  let totalMonths = 12 - currentMonth;
  if (endIndex > maxIndex) {
    ;

    totalMonths = times[maxIndex].date.get("month") + 1;
    endIndex = maxIndex;
  }

  // const totalDays = endIndex - startIndex + 1;

  let nextIndex;

  if (endIndex !== maxIndex) {
    nextIndex = endIndex;
  }

  const value = {
    date,
    totalMonths
    //   totalDays
  };

  return { nextIndex, value };
};

const useYearTimes = times => {
  const x = useMemo(() => {
    const arr = [];
    let currentIndex = 0;

    while (currentIndex !== undefined) {
      //;
      const { nextIndex, value } = buildYearTimes(times, currentIndex);

      arr.push(value);
      currentIndex = nextIndex;
    }
    return arr;
  }, [times]);

  return x;
};

const YearHeaderItem = ({ value }) => {
  const { date, totalMonths } = value;

  const month = date.format("YYYY");

  const { width: dayWidth } = useTimelineZoom();

  const width = `${totalMonths * dayWidth}px`;

  return (
    <div className="ar-timeline-month-header-item" style={{ width }}>
      <div className="text-capitalize text-black fw-medium mx-3">{month}</div>
    </div>
  );
};

const YearHeader = ({ times }) => {
  const years = useYearTimes(times);

  return (
    <div className="ar-timeline-header-months">
      {/* <div className="ar-timeline-grid-header-item ar-timeline-month-header-item justify-content-end px-3">
              Mês
            </div> */}
      {years.map((v, i) => {
        return <YearHeaderItem key={i} value={v} />;
      })}
    </div>
  );
};

export const YearlyTimelineHeader = React.memo(function YearlyTimelineHeader({
  virtualInfo,
  nowMilisec,
  holidays
}) {
  const { resolvedTimes, style } = virtualInfo;
  if (!resolvedTimes) return null;
  return (
    <div className="ar-timeline-header" style={style}>
      <YearHeader times={resolvedTimes} />
      <div className="ar-timeline-header-days d-flex">
        {/* <div className="ar-timeline-grid-item ar-timeline-grid-header-item justify-content-between justify-content-between px-3">
              <div>At.</div>
              <div>Dia</div>
            </div> */}
        {resolvedTimes.map(v => (
          <HeaderItem
            holiday={holidays[v.key]}
            key={v.key}
            nowMilisec={nowMilisec}
            value={v}
          />
        ))}
      </div>
    </div>
  );
});

export const TimelineYearlyConfig = {
  key: "yearly",
  title: "Anual",
  disableWeekends: true,
  disableHolidays: true,
  HeaderComponent: YearlyTimelineHeader,
  dateUnit: "months",
  itemsPerCycle: 14,
  getStartDate: () => {
    const now = createDay();
    now.setDate(1);
    return now;
  }
};
