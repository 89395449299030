import React from "react";
import {
  useIntervention,
  useInterventionType,
} from "../../../../Hooks/EntityHooks";
import {
  HistoryHeader,
  HistoryContent,
  HistoryAccountProfile,
  HistoryOverview,
  useHistoryItem,
} from "./HistoryItem";
import { ConnectedInterventionOrigin } from "../../../../Containers/Origin/InterventionOrigin";
import { createItemEditDetailsComponent } from "./HistoryItemDetails/HistoryItemDetails";
import { FormattedMessage } from "react-intl";

const InterventionHistoryOverview = () => {
  const { TimeCharge, IsCreation, Account } = useHistoryItem();
  const Data = useIntervention(TimeCharge);
  const { Name: Type } = useInterventionType(Data.InterventionType);

  if (IsCreation)
    return (
      <HistoryOverview>
        <HistoryAccountProfile account={Account} />
        <span>
          {" "}
          <FormattedMessage id={"CREATED_TIME"} />{" "}
        </span>{" "}
        <b>({Type})</b>
      </HistoryOverview>
    );

  return (
    <HistoryOverview>
      <HistoryAccountProfile account={Account} />
      <span>
        {" "}
        <FormattedMessage id={"EDITED_TIME"} />{" "}
      </span>{" "}
      <b>({Type})</b>
    </HistoryOverview>
  );
};

const InterventionEditItemDetails = createItemEditDetailsComponent({
  // Attributes: {
  //   NameId: "status",
  //   ValueComponent: ProjectStatusProfile
  // }
});

const InterventionHistoryDetails = () => {
  const { IsCreation } = useHistoryItem();
  if (IsCreation) return null;

  return <InterventionEditItemDetails />;
};

const InterventionHistoryItem = () => {
  const { TimeCharge } = useHistoryItem();

  return (
    <>
      <HistoryHeader>
        <ConnectedInterventionOrigin intervention={TimeCharge}>
          {/* <HistoryTitle Icon={InterventionIcon}>
            <FormattedMessage id={"TIME_RECORD"} /> {TimeCharge}
          </HistoryTitle> */}
        </ConnectedInterventionOrigin>
      </HistoryHeader>
      <HistoryContent>
        <InterventionHistoryOverview />
        <InterventionHistoryDetails />
      </HistoryContent>
    </>
  );
};
export default InterventionHistoryItem;
