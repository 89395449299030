import {
  getColumnDropdownTarget,
  getInterventionTarget
} from "../../Helpers/FormFormmaterHelper";
import { getDeptTeamCollabValue } from "../../Helpers/MiscHelper";

export const convertDealEntityToForm = (entity, props) => {
  const { Collaborators, Tags, ...rest } = entity;
  const TeamCollab = getDeptTeamCollabValue(Collaborators);

  const resolvedForm = {
    ...rest,
    DealTarget: getColumnDropdownTarget(props) || getInterventionTarget(entity),
    TeamCollab
  };

  if (Array.isArray(Tags) && Tags.length > 0) {
    const resolvedTags = [];
    for (const t of Tags) {
      resolvedTags.push({
        data: t,
        type: "DealTag"
      });
    }
    resolvedForm.Tags = resolvedTags;
  }

  return resolvedForm;
};
