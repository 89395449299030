import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import ClipboardProvider from "../../Components/ClipbordProvider/ClipboardProvider";
import { CovenantPeriodicity } from "../../Components/CovenantSelector/CovenantSelector";
import CreatedBy from "../../Components/CreatedByComponent/CreatedBy";
import ArDatePicker from "../../Components/DatePicker/ArDatePicker";
import DocTyper from "../../Components/DocTyper/DocTyper";
import {
  updateCollabFollowers,
  updateManagerFollowers,
  useDropdownFollowers
} from "../../Components/EntityFollow/EntityFollowHelper";
import FileUploader from "../../Components/FileUploader/FileUploader";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import { SidebarAutomationContext } from "../../Components/Sidebar/SidebarV2Automation";
import { SubscriptionStatusTypes } from "../../Components/Subscription/SubscriptionProfile";
import { usePipelineStatus } from "../../Hooks/EntityHooks";
import {
  ContactDropdown,
  DepartmentDropdown,
  OrganizationDropdown,
  SubscriptionTargetDropdown,
  SubscriptionTypeDropdown,
  TeamCollabDropdown,
  UserDropdown,
  SubscriptionTagDropdown,
  SubscriptionsCancellationReasonsDropdown
} from "../AdvancedMultiInputs";
import {
  SubscriptionPipelineDropdown,
  SubscriptionStatusPipelineDropdown
} from "../AdvancedMultiInputs/PipelineDropdowns";
import HTMLInput from "../../Components/HTMLInput/HTMLInput";

const SubscriptionsFormContent = ({
  formState,
  organizations,
  pipelines,
  id,
  canEdit,
  currencies
}) => {
  const { form, handleChange } = formState;
  const {
    SubscriptionTarget,
    Description,
    Contact,
    EndDate,
    Client,
    SalesOpportunity,
    Manager,
    // SubscriptionType,
    InternalDescription,
    Covenant,
    Files,
    StatusDetailDescription,
    StatusDetail,
    Creator,
    Pipeline,
    PipelineStatus,
    Company,
    // BeginDate,
    StatusDetailId,
    // Comments,
    CreationDate,
    Followers,
    TeamCollab,
    IndirectClient
  } = form;
  const PipelineId = Pipeline;
  const PipelineStatusId = PipelineStatus;
  const PipelieStatusObj = usePipelineStatus(PipelineStatusId);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value
    });
  };

  const handleTargetChange = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value,
      Contact: null
    });
  };

  if (
    !Pipeline &&
    pipelines &&
    pipelines.length > 0 &&
    pipelines.length === 1
  ) {
    handleChange({
      Pipeline: pipelines[0]
    });
  }

  // const space = useSpace();
  // const [loadingValidation, setLoadingValidation] = useState(false);

  const HandleContractChanges = async (e) => {
    const { value } = e.target;

    handleChange({
      SubscriptionType: value
    });
  };

  if (organizations.length === 1 && !Company) {
    handleChange({
      Company: organizations[0]
    });
  }

  function dateCompare(date1, date2) {
    return new Date(date2) > new Date(date1);
  }

  const handleCompanyChange = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value,
      SubscriptionType: undefined
    });
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;

    if (name === "BeginDate") {
      if (dateCompare(EndDate, value)) {
        let endValue = new Date(value);
        endValue.setTime(endValue.getTime() + 60 * 60 * 1000);
        handleChange({
          BeginDate: value,
          EndDate: endValue
        });
        return;
      }
    }

    handleChange({
      [name]: value
    });
  };

  const handlePipelineChanges = (e) => {
    const { value } = e.target;
    if (PipelineId === value) return;
    handleChange({
      Pipeline: value,
      PipelineStatus: ""
    });
  };

  if (StatusDetailId && PipelieStatusObj.Status !== 3) {
    handleChange({
      CancelationReasonId: null
    });
  }
  const PipelineStatusObj = usePipelineStatus(PipelineStatus);
  const remainingAccounts = useDropdownFollowers(TeamCollab);
  // const followers = useJoinedFollowerAccounts(Manager, remainingAccounts);
  // const possibleFollowers = useAvailableFollowers(Creator, followers);

  const handleTeamCollabChange = ({ target: { value } }) => {
    handleChange({
      TeamCollab: value,
      Followers: updateCollabFollowers(Followers, TeamCollab, value, [Manager])
    });
  };

  const handleManagerChange = ({ target: { value } }) => {
    handleChange({
      Manager: value,
      Followers: updateManagerFollowers(Followers, Manager, value, [
        remainingAccounts
      ])
    });
  };

  const Automation = useContext(SidebarAutomationContext);

  return (
    <>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"ASSOCIATE_SUBSCRIPTION"} />
      </div>

      {Automation && (
        <FormInput
          textId="CREATED_BY"
          name="Creator"
          inputType={UserDropdown}
          className="mb-3"
        />
      )}

      {(Automation || (organizations && organizations.length > 1)) && (
        <FormInput
          name="Company"
          textId="COMPANY"
          inputType={OrganizationDropdown}
          onChange={handleCompanyChange}
          className="mb-3"
        />
      )}

      <FormInput
        textId="ASSIGN_TO"
        className="mb-3"
        name="Client"
        value={Client}
        inputType={SubscriptionTargetDropdown}
        onChange={handleTargetChange}
        allClients={false}
      />

      <FormInput
        className="mb-3"
        name="Contact"
        textId="CONTACT"
        inputType={ContactDropdown}
        IndirectClient={IndirectClient || Client}
        value={Contact}
        originTarget={SubscriptionTarget || Client}
        onChange={handleChanges}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="CLASSIFICATION" />
      </div>

      <FormInput
        name="Pipeline"
        className="mb-3"
        value={PipelineId}
        disabled={pipelines && pipelines.length > 0 && pipelines.length === 1}
        textId="PIPELINE"
        inputType={SubscriptionPipelineDropdown}
        onChange={handlePipelineChanges}
      />

      <FormInput
        className="mb-3"
        disabled={!PipelineId}
        textId="STATUS"
        name="PipelineStatus"
        Status={!id && SubscriptionStatusTypes.open}
        value={PipelineStatusId}
        filterBlock={true}
        onLoad={({ data }) => {
          handleChange(
            {
              PipelineStatus: data[0],
              StatusDetail: null,
              StatusDetailDescription: null
            },
            true
          );
        }}
        inputType={SubscriptionStatusPipelineDropdown}
        pipelineId={PipelineId}
        onChange={handleChanges}
      />

      {id &&
        PipelineStatus &&
        PipelineStatusObj.Status === SubscriptionStatusTypes.canceled && (
          <FormInput
            name="StatusDetail"
            value={StatusDetail}
            textId="CANCELATION_REASON"
            inputType={SubscriptionsCancellationReasonsDropdown}
            company={Company}
            //   value={
            //     StatusDetailId
            //       ? StatusDetailId
            //       : StatusDetail
            //       ? StatusDetail
            //       : null
            //   }
            onChange={handleChanges}
            className="mb-3"
          />
        )}
      {id &&
        PipelineStatus &&
        PipelineStatusObj.Status === SubscriptionStatusTypes.canceled && (
          <FormInput
            name="StatusDetailDescription"
            textId="CANCELATION_DESCRIPTION"
            inputType={HTMLInput}
            value={StatusDetailDescription ? StatusDetailDescription : ""}
            onChange={handleChanges}
            className="mb-3"
          />
        )}

      <FormInput
        className="mb-3"
        name="Tags"
        textId="SUBSCRIPTION_TAGS"
        pipelineId={Pipeline}
        // getKey={(e) => {
        //   const resolvedTag = getEntity(spaceTicketTagsSchema, e);
        //   return `${e}-${resolvedTag?.Pipeline}`;
        // }}
        multiple
        inputType={SubscriptionTagDropdown}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="ASSIGN_TO" />
      </div>
      <FormInput
        name="Manager"
        className="mb-3"
        textId="SALESPERSON"
        inputType={UserDropdown}
        value={Manager}
        onChange={handleManagerChange}
      />

      <FormInput
        name="Departments"
        className="mb-3"
        textId="DEPARTMENTS"
        inputType={DepartmentDropdown}
        multiple
      />

      <FormInput
        name="TeamCollab"
        className="mb-3"
        textId="TEAMS_CONTRIBUTORS"
        inputType={TeamCollabDropdown}
        multiple
        onChange={handleTeamCollabChange}
      />

      <FormInput
        name="Followers"
        textId="FOLLOWERS"
        inputType={UserDropdown}
        multiple
        // items={possibleFollowers}
        className="mb-3"
      />
      {/* 
      <FormInput
        name="TeamCollab"
        className="mb-3"
        textId="SHARE_WITH"
        inputType={DeptTeamCollabDropdown}
        multiple
        onChange={handleTeamCollabChange}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"NOTIFICATIONS"} />
      </div>

      <FormInput
        name="Followers"
        textId="SEND_NOTIFICATION_TO"
        inputType={UserDropdown}
        multiple
        disabled={!canEditFollowers}
        // items={possibleFollowers}
        className="mb-3"
      /> */}

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"DETAILS"} />
      </div>

      <FormInput
        name="SubscriptionType"
        className="mb-3"
        textId="TYPE_SUBSCRIPTION"
        inputType={SubscriptionTypeDropdown}
        OrganizationSpaceId={Company}
        onChange={HandleContractChanges}
      />

      <FormInput
        className="mb-3"
        name="BeginDate"
        textId="START"
        inputType={ArDatePicker}
        onChange={handleTimeChange}
        componentClassName="w-100"
        openHourAfterDate
      />

      <FormInput
        className="mb-3"
        textId="END"
        inputType={ArDatePicker}
        name="EndDate"
        onChange={handleTimeChange}
        // disablePastDate={BeginDate}
        // disableDates={true}
        componentClassName="w-100"
        openHourAfterDate
      />

      <FormInput
        maxLength={50}
        className="mb-3"
        name="SalesOpportunity"
        textId="NR_SALE_OPPORTUNITY"
        value={SalesOpportunity}
        onChange={handleChanges}
      />

      <FormInput
        name="Covenant"
        className="mb-3"
        textId="RENEWAL"
        inputType={CovenantPeriodicity}
        value={Covenant}
      />

      <ClipboardProvider>
        <FormInput
          textId="DESCRIPTION"
          inputType={DocTyper}
          name="Description"
          className="mb-3"
          value={Description}
          onChange={handleChanges}
        />

        <FormInput
          className="mb-3"
          textId="INTERNAL_NOTE"
          inputType={DocTyper}
          name="InternalDescription"
          value={InternalDescription}
          onChange={handleChanges}
        />
        {!Automation && (
          <>
            <hr className="mb-3"></hr>
            <div className="mb-3 text-black fs-16 fw-medium">
              <FormattedMessage id="ATTACHMENTS" />
            </div>

            {/* {id && (
						<FormInput
							textId="status"
							name="Status"
							inputType={ContractStatusDropdown}
							hideCancellation
							className="mb-3"
						/>
					)} */}

            <FormInput
              name="Files"
              className="mb-3 form-Anexos-floater"
              inputType={FileUploader}
              value={Files}
              enableSwapper
              onChange={handleChanges}
            />
          </>
        )}
      </ClipboardProvider>

      {/* {id && (
          <>
            <hr className="mb-3"></hr>
            <div className="mb-3">
              <span className="text-black fs-16 fw-medium">Comentários</span>
            </div>
            <div className="mb-3">
              <CommentModule
                Type="subscription"
                Id={id}
                Comments={Comments || []}
              />
            </div>
          </>
        )} */}

      <CreatedBy Creator={Creator} CreationDate={CreationDate} />
    </>
  );
};

export default SubscriptionsFormContent;
