import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import {
  PageContainer,
  PageContent
} from "../../Components/PageUtilities/PageUtilities";
import {
  ServerAwareProvider,
  GridRequestProvider,
  ServerAwareQueryInput
} from "../../Components/CGrid/ServerGrid";
import PageHeader from "../../Components/Headers/PageHeader";
import classnames from "classnames";
import { spacesListSchema } from "../../config/schema";
import { ConnectedAccountProfile } from "../../Components/Accounts/AccountProfile";
import { ServerAwareView } from "../../Components/GroupButton/GroupButton";
import { GridDateComponent } from "../../Components/CGrid/CGrid";
import { ConnectedAccountsTeamsDepartmentsProfileStack } from "../../Components/Accounts/AccountProfileStack";
import {
  BillingPlansComponentDict,
  BillingPlanStatusProfile,
  BillingPlanCheck,
  spaceBlockTypeEnum
} from "../BillingPlans/BillingPlanPayment";
import { SpaceFormButton } from "../Form/FormButtons";
import { useAccount, useEntityValue } from "../../Hooks/EntityHooks";
import UserAlterEgoButton from "../../Components/UserAlterEgoButton/UserAlterEgoButton";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { DefaultColumnsWidth } from "../../Components/CGrid/GridDefinitions";
import VirtualServerAwareGrid from "../../Components/VirtualGrid/VirtualServerAwareGrid";
import { VirtualGridRow } from "../../Components/VirtualGrid/VirtualGrid";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import SaveFavoriteButton from "../AppBar/SaveFavoriteButton";
import ServerAwareFilterList from "../../Components/FilterList/ServerAwareFilterList";
import { FilterViewTypeEnum } from "../../Components/FilterList/FilterList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlarmClock,
  faAlignJustify,
  faCalendarAlt,
  faDollarSign,
  faDrawCircle,
  faHdd,
  faPaperPlane,
  faStarShooting,
  faTally,
  faTrashAlt
} from "@fortawesome/pro-light-svg-icons";
import { createUserFilter } from "../../Helpers/FilterHelper";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";
import { ActionButtonOptions } from "../../Components/ExtraActionsButton/ExtraActionButton";

// const Row = React.memo(function Row({ value, ...rest }) {
//   // const canSelectRow = useCallback(v => !v.Call, []);
//   // console.log(value);
//   return (
//     <GridRow
//       // className={call && call.IsRead === false && "unread"}
//       value={value}
//       {...rest}
//       // canSelectRow={canSelectRow}
//     />
//   );
// });

const StatusComponent = ({ item, value }) => {
  const resovledStatus = item || value;

  return resovledStatus === "true" ? (
    <FormattedMessage id="YES" />
  ) : (
    <FormattedMessage id="NO" />
  );
};

const IsDeletedDropdown = (props) => {
  const options = useMemo(() => {
    return ["true", "false"];
  }, []);

  return (
    <KeyedDropdown
      options={options}
      valueComponent={StatusComponent}
      closeOnSelect
      {...props}
    />
  );
};

const SpaceSubscriptionComponent = ({ item, value }) => {
  const resovledStatus = parseInt(item || value);

  return BillingPlansComponentDict[resovledStatus];
};

const SpaceSubscriptionDropdown = (props) => {
  const options = useMemo(() => {
    return [1, 2, 3, 4];
  }, []);

  return (
    <KeyedDropdown
      options={options}
      valueComponent={SpaceSubscriptionComponent}
      closeOnSelect
      {...props}
    />
  );
};
const SpaceBlockTypeComponent = ({ item, value }) => {
  const resovledStatus = parseInt(item || value);

  return <BillingPlanStatusProfile status={resovledStatus} />;
};

const SpaceBlockTypeDropdown = (props) => {
  const options = useMemo(() => {
    return [0, 1, 2, 3];
  }, []);

  return (
    <KeyedDropdown
      options={options}
      valueComponent={SpaceBlockTypeComponent}
      closeOnSelect
      {...props}
    />
  );
};

const createSpaceStatusFilter = (overrides) => {
  return {
    title: "Eliminado",
    field: "IsDeleted",
    icon: <FontAwesomeIcon icon={faTrashAlt} />,
    applyOnChange: true,
    component: IsDeletedDropdown,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>Está eliminado? {(item || value) === "true" ? "Sim" : "Não"}</div>
      );
    },
    ...overrides
  };
};

const createSpaceSubscriptionFilter = (overrides) => {
  return {
    titleId: "SUBSCRIPTION",
    field: "Plan/Id",
    icon: <FontAwesomeIcon icon={faPaperPlane} />,
    applyOnChange: true,
    component: SpaceSubscriptionDropdown,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id="SUBSCRIPTION" /> é{" "}
          {BillingPlansComponentDict[parseInt(item || value)]}
        </div>
      );
    },
    ...overrides
  };
};

const createSpaceBlocktypeFilter = (overrides) => {
  return {
    titleId: "STATUS",
    field: "BlockType",
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    applyOnChange: true,
    component: SpaceBlockTypeDropdown,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id="STATUS" /> é{" "}
          <BillingPlanStatusProfile status={parseInt(item || value)} />
        </div>
      );
    },
    ...overrides
  };
};

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const HandleStorageSizeComponent = (Data) => {
  const resolvedValue = formatBytes(Data);

  return <span className="text-black">{`${resolvedValue} `}</span>;
};

const LogTr = ({ value, ...rest }) => {
  const log = useEntityValue(spacesListSchema, value);

  return (
    <VirtualGridRow
      // className={call && call.IsRead === false && "unread"}
      value={log}
      {...rest}
      // canSelectRow={canSelectRow}
    />
  );
};

// const getInt = (n) => {
//   return Math.trunc(parseFloat(n));
// };

// const handleDataSize = (objectStorage) => {
//   let resolvedValue = 0;
//   let resolvedKey = "";
//   for (const key in objectStorage) {
//     if (objectStorage.hasOwnProperty(key)) {
//       const element = objectStorage[key];
//       const value = getInt(element);
//       if (!resolvedValue && value > 0) {
//         resolvedValue = element;
//         resolvedKey = key;
//       } else {
//         const tempElementInt = getInt(resolvedValue);
//         if (value > 0 && value < tempElementInt) {
//           resolvedValue = element;
//           resolvedKey = key;
//         }
//       }
//     }
//   }
//   return { resolvedValue, resolvedKey };
// };

const SpaceListEmail = ({ owner }) => {
  const account = useAccount(owner);
  if (!account) return <span>-</span>;

  return <span>{account.Email}</span>;
};

const columns = [
  {
    width: DefaultColumnsWidth.buttons,
    field: "buttons",
    required: true,
    title: "Actions",
    orderBy: false,
    minSize: 110,
    noTitle: true,
    sortable: false,
    className: "p-2",
    Cell: ({ Data: value }) => {
      const account = useCurrentAccount();

      // const support = account.IsSupport;
      const dev = account.IsDeveloper;

      if (!dev) return null;

      return <SpaceFormButton canEdit id={value.Id} />;
    }
  },
  {
    field: "Id",
    width: DefaultColumnsWidth.id,
    sortable: true,
    sticky: true,
    orderBy: false,
    title: "Id",
    Cell: ({ Data }) => <span className="text-black">{Data.Id}</span>
  },
  {
    field: "Name",
    width: DefaultColumnsWidth.Description,
    sortable: false,
    sticky: true,
    orderBy: false,
    title: "Nome",
    Cell: ({ Data }) => (
      <div style={{ maxHeight: 70 }} className="text-black">
        {Data.Name}
      </div>
    )
  },
  {
    field: "Owner/Id",
    width: DefaultColumnsWidth.Origem,
    sortable: false,
    orderBy: false,
    title: "Owner",
    Cell: ({ Data }) => (
      <div className="d-flex">
        <ConnectedAccountProfile account={Data.Owner} />
        <UserAlterEgoButton className="ml-1" canEdit userId={Data.Owner} />
      </div>
    )
  },
  {
    field: "Email",
    width: DefaultColumnsWidth.Origem,
    sortable: false,
    orderBy: false,
    title: "Email",
    Cell: ({ Data }) => <SpaceListEmail owner={Data.Owner} />
  },
  {
    field: "CountUsers",
    width: DefaultColumnsWidth.Origem,
    sortable: true,
    orderBy: false,
    title: "Numero de Utilizadores",
    Cell: ({ Data }) => {
      const { MaxAmountOfUsers, CountUsers } = Data;

      const isRed = MaxAmountOfUsers && CountUsers >= MaxAmountOfUsers;

      return (
        <span
          className={classnames({
            "text-black": !isRed,
            "text-danger-alt": isRed
          })}
        >
          {`${CountUsers}${MaxAmountOfUsers ? `/${MaxAmountOfUsers}` : ""}`}
          {/* {Data.CountUsers}/{Data.MaxAmountOfUsers} */}
        </span>
      );
    }
  },
  {
    field: "IsDeleted",
    width: DefaultColumnsWidth.Origem,
    sortable: true,
    orderBy: false,
    title: "Eliminado",
    Cell: ({ Data }) => {
      if (Data?.IsDeleted === "true") {
        return "Sim";
      } else return "Não";
    }
  },
  {
    field: "StorageSize",
    width: DefaultColumnsWidth.Origem,
    sortable: true,
    orderBy: false,
    title: "Storage used",
    Cell: ({ Data }) => {
      const resolvedValue = HandleStorageSizeComponent(Data.StorageSize);
      return resolvedValue;
    }
  },
  {
    width: DefaultColumnsWidth.AssignedGroup,
    orderBy: false,
    sortable: false,
    minSize: 145,
    field: "Users",
    title: <FormattedMessage id="USERS" />,
    Cell: ({ Data }) => {
      //   console.log(Data);
      return (
        <ConnectedAccountsTeamsDepartmentsProfileStack
          accounts={Data.Accounts}
        />
      );
    }
  },
  {
    width: DefaultColumnsWidth.AssignedGroup,
    orderBy: false,
    sortable: true,
    minSize: 145,
    field: "Plan/Id",
    title: <FormattedMessage id="SUBSCRIPTION" />,
    Cell: ({ Data }) => {
      //   console.log(Data);
      return BillingPlansComponentDict[parseInt(Data.Plan.Id)] || "―";
    }
  },
  {
    width: DefaultColumnsWidth.AssignedGroup,
    orderBy: false,
    sortable: false,
    minSize: 120,
    field: "ActiveStripeCoupon",
    title: "Cupão Ativo",
    Cell: ({ Data }) => {
      return Data?.ActiveStripeCoupon?.Name || "―";
    }
  },
  {
    width: DefaultColumnsWidth.AssignedGroup,
    orderBy: false,
    sortable: false,
    minSize: 145,
    field: "SpaceUsedCoupons",
    title: "Cupões usados",
    Cell: ({ Data }) => {
      return Data?.SpaceUsedCoupons?.map((e) => e.Name)?.join(", ") || "―";
    }
  },
  {
    width: DefaultColumnsWidth.status,
    orderBy: false,
    sortable: true,
    minSize: 145,
    field: "BlockType",
    title: <FormattedMessage id="STATUS" />,
    Cell: ({ Data }) => {
      //   console.log(Data);
      return (
        <BillingPlanStatusProfile spaceItem={Data} status={Data.BlockType} />
      );
    }
  },
  {
    width: DefaultColumnsWidth.AssignedGroup,
    orderBy: false,
    sortable: true,
    minSize: 145,
    field: "NextInvoiceDate",
    title: <FormattedMessage id="NEXT_RENEWAL" />,
    Cell: ({ Data }) => {
      //   console.log(Data);
      if (!Data.NextInvoiceDate) return "―";
      return (
        <GridDateComponent removeSlice date={new Date(Data.NextInvoiceDate)} />
      );
    }
  },
  {
    width: DefaultColumnsWidth.AssignedGroup,
    orderBy: false,
    sortable: false,
    minSize: 145,
    field: "IsManualPayment",
    title: <FormattedMessage id="POST_PAYMENT" />,
    Cell: ({ Data }) => {
      //   console.log(Data);
      if (Data.IsManualPayment === "false") return "―";
      return <BillingPlanCheck />;
    }
  },
  {
    width: DefaultColumnsWidth.AssignedGroup,
    orderBy: false,
    sortable: true,
    minSize: 145,
    field: "CreationDate",
    title: "Data de Criação",
    Cell: ({ Data }) => {
      //   console.log(Data);
      if (Data.CreationDate)
        return (
          <GridDateComponent removeSlice date={new Date(Data.CreationDate)} />
        );
      else return "―";
    }
  },
  {
    width: DefaultColumnsWidth.AssignedGroup,
    orderBy: false,
    sortable: true,
    minSize: 145,
    field: "TrialEndDate",
    title: <FormattedMessage id="EVALUATION" />,
    Cell: ({ Data }) => {
      //   console.log(Data);
      const {
        BlockType,
        NextInvoiceDate,
        IsManualPayment,
        TrialEndDate,
        IsSubscribed
      } = Data;

      if (
        BlockType !== spaceBlockTypeEnum.Active ||
        NextInvoiceDate ||
        IsManualPayment ||
        IsSubscribed ||
        !TrialEndDate
      )
        return null;

      return <GridDateComponent removeSlice date={new Date(TrialEndDate)} />;
    }
  }
];

const filterColumns = [
  {
    propField: "Id",
    icon: <FontAwesomeIcon icon={faStarShooting} />,
    title: "Id",
    field: "Id",
    type: "number"
  },
  {
    propField: "Name",
    icon: <FontAwesomeIcon icon={faAlignJustify} />,
    titleId: "NAME",
    field: "Name",
    type: "string"
  },
  createUserFilter({
    propField: "Owner/Id",
    titleId: "OWNER",
    field: "Owner/Id"
  }),
  createSpaceStatusFilter(),
  createSpaceSubscriptionFilter(),
  createSpaceBlocktypeFilter(),
  {
    propField: "Email",
    icon: <FontAwesomeIcon icon={faAlignJustify} />,
    title: "Email",
    field: "Email",
    type: "string"
  },
  {
    propField: "CountUsers",
    icon: <FontAwesomeIcon icon={faTally} />,
    title: "Numero de Utilizadores",
    field: "CountUsers",
    type: "number"
  },
  {
    propField: "StorageSize",
    icon: <FontAwesomeIcon icon={faHdd} />,
    title: "Storage used in bytes",
    field: "StorageSize",
    type: "number"
  },
  {
    propField: "NextInvoiceDate",
    icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    titleId: "NEXT_RENEWAL",
    field: "NextInvoiceDate",
    type: "dateTime"
  },
  {
    propField: "CreationDate",
    icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    titleId: "CREATION_DATE",
    field: "CreationDate",
    type: "dateTime"
  },
  createSpaceStatusFilter({
    field: "IsManualPayment",
    title: undefined,
    titleId: "POST_PAYMENT",
    icon: <FontAwesomeIcon icon={faDollarSign} />,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>Pós-Pagamento? {(item || value) === "true" ? "Sim" : "Não"}</div>
      );
    }
  }),
  {
    propField: "TrialEndDate",
    icon: <FontAwesomeIcon icon={faAlarmClock} />,
    titleId: "EVALUATION",
    field: "TrialEndDate",
    type: "dateTime"
  }
];

const initialParams = () => {
  return {
    orderBy: { column: "Id", type: "desc" }
  };
};

const SpaceList = () => {
  //   const endpoint = "Logs?$inlinecount=allpages&$orderby=Id%20desc&$top=50";
  //   const { data, error, loading } = useQuery(endpoint, null);

  const filterColumnsDict = {};
  columns.forEach((element) => {
    filterColumnsDict[element.field] = element.title;
  });

  const intl = useIntl();
  return (
    <PageContainer>
      <ServerAwareProvider
        columns={columns}
        initialParams={initialParams}
        filterColumns={filterColumns}
        moduleType={moduleTypes.spaceList}
        // defaultOrderby={[]}
        schema={spacesListSchema}
      >
        <PageHeader
          schema={spacesListSchema}
          toggleFilterList
          title={"Espaços"}
        >
          <div className="d-flex ml-2 justify-content-between flex-1 w-100 align-items-center">
            <ServerAwareQueryInput
              placeholder={intl.formatMessage({ id: "SEARCH" })}
              className="mr-2"
            />
            <div className="d-flex align-items-center">
              <SaveFavoriteButton />
              <ActionButtonOptions
                moduleType={moduleTypes.spaceList}
                spaceType={"spaceList"}
                excelUrl="Excel/SpacesToFiles"
              />
            </div>
          </div>
        </PageHeader>

        <PageContent>
          <ServerAwareFilterList
            disableAdvancedFilters
            groupableColumns={[]}
            ordenableColums={columns}
            filterDict={filterColumnsDict}
            columns={filterColumns}
            filterType={FilterViewTypeEnum.SpaceList}
          />
          <ServerAwareView>
            <GridRequestProvider>
              <VirtualServerAwareGrid
                className="flex-1"
                sortable={true}
                rowComponent={LogTr}
                columns={columns}
              />
            </GridRequestProvider>
          </ServerAwareView>
        </PageContent>
      </ServerAwareProvider>
    </PageContainer>
  );
};

export default injectIntl(withRouter(SpaceList));
