import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect
} from "react";
import FormInput from "../../Forms/FormInput/FormInput";
import { FilterConditionsEnum } from "./AdvancedFilterConditions";
import { FilterOperatorsEnum } from "./AdvancedFilterOperators";
import Button from "../../Button/Button";
import {
  AdvancedFilterItemList,
  AdvancedFilterButtons,
  AdvancedFilterhasErrorSetterContext
} from "./AdvancedFilterGroup";
import { UserDropdown } from "../../../Containers/AdvancedMultiInputs";
import {
  useSpacePost,
  useSpaceQuery,
  getEntity,
  client,
  cacheType,
  useSpaceDelete
} from "../../../Helpers/IOClient";
import LoadableButton from "../../Button/LoadableButton";
import { FilterViewTypeEnum } from "../FilterList";
import LoaderSpinner from "../../Loader/LoaderSpinner/LoaderSpinner";
import { spaceAdvancedFilterSchema } from "../../../config/schema";
import Axios, { CancelTokenSource } from "axios";
import { useSpace } from "../../../Contexts/SpaceContext";
import Switch from "../../Switch/Switch";
import querystring from "query-string";
import { ErrorAlert } from "../../Alerts/Alerts";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent
} from "../../Sidebar/SidebarV2Helper";
import { FormattedMessage, useIntl } from "react-intl";
import {
  AvailableFiltersContext,
  getDefaultParameterValues,
  IFilter,
  IFilterOption,
  IFilterProps,
  useAvailableFilters
} from "./AdvancedFilterHelper";
import { handleError } from "../../../Helpers/MiscHelper";
import { useToast } from "../../Toast/ToastProvider";
const replaceFieldSlash = (param: string) => {
  return param.replace("/", ".");
};
const replaceFieldDot = (param: string) => {
  return param.replace(".", "/");
};
export const createNewCondition = (
  resolvedAvailableFilters: IFilterOption[]
) => {
  const firstFilter = resolvedAvailableFilters[0];
  const { Operator, Value } = getDefaultParameterValues(firstFilter);
  const newCondition: IFilter = {
    Condition: FilterConditionsEnum.And,
    Parameter: firstFilter,
    Operator: Operator,
    Value: Value,
    Groups: []
  };
  return newCondition;
};

const checkForRelatives = (filters: IFilter[]) => {
  let result = false;

  for (const filter of filters) {
    const { Operator, Groups } = filter;
    if (
      Operator === FilterOperatorsEnum.Next ||
      Operator === FilterOperatorsEnum.Last
    ) {
      result = true;
      break;
    }
    if (Groups.length > 0) result = checkForRelatives(Groups) as boolean;
  }

  return result;
};

const useResolvedAvailableFilters = (filters: IFilterOption[]) => {
  return useMemo(() => {
    // const arr = [...filters];
    const arr = filters.reduce((val: any, originalElem) => {
      if (originalElem.disableAdvancedFilter) return val;
      const element = { ...originalElem };

      if (originalElem.resolvedFilter) {
        element.field = element.field?.replace("/", ".");
        val.push(element);
        return val;
      }
      if (element.field) element.field = replaceFieldSlash(element.field);
      if (element.mappedFields) {
        element.mappedFields = [...element.mappedFields];
        for (let i = 0; i < element.mappedFields.length; i++) {
          const newMappedField = { ...element.mappedFields[i] };
          newMappedField.resolvedField = replaceFieldSlash(
            newMappedField.resolvedField
          );
          element.mappedFields[i] = newMappedField;
        }
      }
      val.push(element);
      return val;
    }, []);
    // for (let i = 0; i < arr.length; i++) {
    //   const element = { ...arr[i] };
    //   if (element.field) element.field = replaceFieldSlash(element.field);
    //   if (element.mappedFields) {
    //     element.mappedFields = [...element.mappedFields];
    //     for (let i = 0; i < element.mappedFields.length; i++) {
    //       const newMappedField = { ...element.mappedFields[i] };
    //       newMappedField.resolvedField = replaceFieldSlash(
    //         newMappedField.resolvedField
    //       );
    //       element.mappedFields[i] = newMappedField;
    //     }
    //   }
    //   arr[i] = element;
    // }
    return arr;
  }, [filters]);
};
const useFiltersDict = (filters: IFilterOption[]) => {
  return useMemo(() => {
    const fieldDict: { [field: string]: IFilterOption } = {};
    const mappedFieldDict: { [field: string]: IFilterOption } = {};
    for (const filter of filters) {
      const { field, mappedFields } = filter;
      if (!field) continue;
      fieldDict[field] = filter;
      if (mappedFields) {
        for (const mappedField of mappedFields) {
          const { resolvedField } = mappedField;
          mappedFieldDict[resolvedField] = filter;
        }
      }
    }
    return { fieldDict, mappedFieldDict };
  }, [filters]);
};
interface IAvailableFiltersValue {
  availableFilters: IFilterOption[];
  fieldDict: { [field: string]: IFilterOption };
  mappedFieldDict: { [field: string]: IFilterOption };
}
const buildResolvedFilters = (
  filter: any,
  fieldMappings: IAvailableFiltersValue
) => {
  const { fieldDict, mappedFieldDict } = fieldMappings;
  let arr = [];
  const newFilter = { ...filter };
  const { Parameter, Groups } = newFilter;
  let parameterObj;

  if (Parameter) {
    const getEntityEndpoint = fieldDict[Parameter]
      ? fieldDict[Parameter].getEntityEndpoint
      : undefined;
    if (fieldDict[Parameter]) {
      parameterObj = fieldDict[Parameter];
      newFilter.Parameter = fieldDict[Parameter];
    } else if (mappedFieldDict[Parameter]) {
      parameterObj = mappedFieldDict[Parameter];
      newFilter.Parameter = mappedFieldDict[Parameter];
    }
    if (parameterObj) {
      const {
        getCompleteValue,
        getSchemaFromCompleteValue,
        getNormalizationSchema
      } = parameterObj;
      const oldValue = newFilter.Value;
      if (getCompleteValue) {
        newFilter.Value = getCompleteValue(
          replaceFieldDot(Parameter),
          newFilter.Value
        );
      }
      if (getSchemaFromCompleteValue && getEntityEndpoint) {
        const schema = getSchemaFromCompleteValue(newFilter.Value);
        arr.push({
          schema,
          getEntityEndpoint,
          normalizationSchema: getNormalizationSchema
            ? getNormalizationSchema()
            : schema,
          value: oldValue,
          parameter: newFilter
        });
      }
    }
  }
  if (Groups && Groups.length > 0) {
    newFilter.Groups = [...newFilter.Groups];
    for (let i = 0; i < newFilter.Groups.length; i++) {
      const element = newFilter.Groups[i];
      const { filter, dependencies }: any = buildResolvedFilters(
        element,
        fieldMappings
      );
      newFilter.Groups[i] = filter;
      arr = [...arr, ...dependencies];
    }
  }
  return { filter: newFilter, dependencies: arr };
};
const useResolvedValue = (
  availableFiltersValue: IAvailableFiltersValue,
  data?: number
) => {
  const prevDataRef = useRef(data);
  const [loading, setLoading] = useState(true);
  if (data !== prevDataRef.current) {
    prevDataRef.current = data;
    setLoading(true);
  }
  const valueRef = useRef();
  const space: any = useSpace();
  useEffect(() => {
    if (!data) return;
    const f = { ...getEntity(spaceAdvancedFilterSchema, data) };
    //;

    const { filter, dependencies } = buildResolvedFilters(
      f.Filter,
      availableFiltersValue
    );
    f.Filter = filter;
    valueRef.current = f;
    if (dependencies.length === 0) setLoading(false);
    else {
      const requestArr: CancelTokenSource[] = [];
      const requestsRef = {
        current: dependencies.length
      };
      for (const dep of dependencies) {
        const {
          schema,
          normalizationSchema,
          value,
          parameter,
          getEntityEndpoint,
          getQueryEndpoint
        } = dep;

        const { defaultValue } = parameter.Parameter;
        const url = getQueryEndpoint
          ? getQueryEndpoint(space.Id, value)
          : getEntityEndpoint
          ? getEntityEndpoint(space.Id, value)
          : `${schema.getEndpoint(space.Id)}/${value}`;
        const source = Axios.CancelToken.source();
        requestArr.push(source);

        client.get(url, {
          schema: normalizationSchema,
          cancelToken: source.token,
          onSuccess: (s: any) => {
            requestsRef.current--;
            if (requestsRef.current === 0) {
              setLoading(false);
            }
          },
          onError: () => {
            requestsRef.current--;
            parameter.Value = defaultValue;
            if (requestsRef.current === 0) {
              setLoading(false);
            }
          }
        });
      }
      return () => {
        if (requestsRef.current === 0) return;
        for (const r of requestArr) {
          r.cancel();
        }
      };
    }
  }, [data, availableFiltersValue, space]);
  return { loading, value: valueRef.current };
};
export const AdvancedFilterSidebar = (props: IFilterProps) => {
  const { id, availableFilters } = props;

  const editUrl = id ? `CustomFilters/${id}` : null;
  const { data } = useSpaceQuery(editUrl, spaceAdvancedFilterSchema);
  const resolvedAvailableFilters =
    useResolvedAvailableFilters(availableFilters);
  const { fieldDict, mappedFieldDict } = useFiltersDict(
    resolvedAvailableFilters
  );
  const availableFiltersValue = useMemo(() => {
    return {
      availableFilters: resolvedAvailableFilters,
      mappedFieldDict,
      fieldDict
    };
  }, [fieldDict, mappedFieldDict, resolvedAvailableFilters]);

  const { loading: loadingDep, value } = useResolvedValue(
    availableFiltersValue,
    data
  );
  if (id && (!data || loadingDep))
    return (
      <BaseSidebarContainer>
        <LoaderSpinner size="sm" className="text-primary" center />
      </BaseSidebarContainer>
    );
  return (
    <AvailableFiltersContext.Provider value={availableFiltersValue}>
      <AdvancedFilter
        key={id}
        {...props}
        advancedfilter={id ? value : undefined}
      />
    </AvailableFiltersContext.Provider>
  );
};
const useNameValidator = (
  name: string,
  filterType: FilterViewTypeEnum,
  id?: number
) => {
  const [resolvedName, setResolvedName] = useState(name);
  const [isValid, setIsValid] = useState(true);
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setResolvedName(name);
  //   }, 300);
  //   return () => clearTimeout(timeout);
  // }, [name]);
  const search = useMemo(() => {
    //;
    if (!resolvedName) return null;
    const x = {
      Type: filterType,
      Id: id,
      Name: name
    };
    return querystring.stringify(x);
  }, [filterType, id, resolvedName, name]);
  const responseState = useSpaceQuery(
    search ? `CustomFilters/CheckName?${search}` : null,
    null,
    {
      onSuccess: ({ data }: any) => {
        setIsValid(data);
      },
      cache: cacheType.component
    }
  );
  const handleBlur = useCallback((e: any) => {
    setResolvedName(e.target.value);
  }, []);
  // if (!search) return { isNameValid: isValid, handleBlur };
  const { loading, error } = responseState;
  return { isNameValid: isValid, loadingName: loading || error, handleBlur };
};
export const AdvancedFilter = ({
  filterType,
  advancedfilter,
  onSuccess: handleSuccess,
  onRemoval
}: IFilterProps) => {
  const { availableFilters } = useAvailableFilters();
  const canDelete = advancedfilter && advancedfilter.CanDelete;
  const [filter, setFilter] = useState<IFilter>(() => {
    //;

    if (advancedfilter) return advancedfilter.Filter;
    const firstFilter = availableFilters[0];
    const { Operator, Value } = getDefaultParameterValues(firstFilter);
    const newGroup: IFilter = {
      Condition: FilterConditionsEnum.And,
      Parameter: firstFilter,
      Operator: Operator,
      Value: Value,
      Groups: []
    };
    const newGroups = [newGroup];
    return {
      Groups: newGroups
    };
  });
  const [isPublic, setIsPublic] = useState(
    advancedfilter ? advancedfilter.IsPublic : false
  );
  const [accounts, setAccounts] = useState(
    advancedfilter && advancedfilter.Accounts ? advancedfilter.Accounts : []
  );
  // const availableFiltersDict = useMemo(() => {
  //   const dict: [filter: string] = {}
  //   for (const f of availableFilters) {
  //     dict[f.field] = f;
  //   }
  // }, [])
  const [filterName, setFilterName] = useState(
    advancedfilter ? advancedfilter.Name : ""
  );
  const editId = advancedfilter ? advancedfilter.Id : undefined;
  const addCondition = useCallback(() => {
    const newCondition = createNewCondition(availableFilters);
    setFilter((f) => {
      const Condition =
        f.Groups.length > 0 ? f.Groups[0].Condition : FilterConditionsEnum.And;
      newCondition.Condition = Condition;
      const newGroups = [...f.Groups, newCondition];
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, [availableFilters]);
  const addGroup = useCallback(() => {
    setFilter((f) => {
      const newCondition = createNewCondition(availableFilters);
      const Condition =
        f.Groups.length > 0 ? f.Groups[0].Condition : FilterConditionsEnum.And;
      const newGroup = {
        Condition,
        Groups: [newCondition]
      };
      const newGroups = [...f.Groups, newGroup];
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, [availableFilters]);
  const updateGroup = useCallback((index: number, newGroupsFunc: any) => {
    setFilter((f) => {
      const newGroups = [...f.Groups];
      newGroups[index] = newGroupsFunc(newGroups[index]);
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, []);
  const removeGroup = useCallback((index: number) => {
    setFilter((f) => {
      const newGroups = [...f.Groups];
      newGroups.splice(index, 1);
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, []);
  const updateCondition = useCallback((newCondition: FilterConditionsEnum) => {
    setFilter((f) => {
      const newGroups = [...f.Groups];
      for (const g of newGroups) {
        g.Condition = newCondition;
      }
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, []);
  // const [, closeSidebar] = useSidebar();
  const createToast = useToast();
  let endpoint = editId ? `CustomFilters/${editId}` : "CustomFilters";
  const [post, { loading }]: any = useSpacePost(
    endpoint,
    spaceAdvancedFilterSchema,
    {
      onSuccess: ({ data }: any) => {
        handleSuccess(data);
      },
      onError: (e: any) => {
        handleError(createToast, e.error);
      }
    }
  );
  const resetParameters = (filter: any) => {
    //;
    if (filter.Parameter) {
      const { mappedFields, getResolvedValue, getField } = filter.Parameter;
      if (mappedFields) {
        const uris = getField(filter.Value);
        const { resolvedField } = uris;
        filter.Parameter = replaceFieldSlash(resolvedField);
        filter.Value = getResolvedValue(filter.Value);
      } else {
        filter.Parameter = filter.Parameter.field;
      }
    }
    if (filter.Groups && filter.Groups.length > 0) {
      filter.Groups = [...filter.Groups];
      for (let i = 0; i < filter.Groups.length; i++) {
        const newGroup = { ...filter.Groups[i] };
        resetParameters(newGroup);
        filter.Groups[i] = newGroup;
      }
    }
    // for (let i = 0; i < filter.Groups.length; i++) {
    //   const g = { ...filter.Groups[i] };
    //   if (g.Parameter) g.Parameter = g.Parameter.field;
    //   if (g.Groups && g.Groups.length > 0){
    //     for (const cGroup of g.Groups) {
    //       resetParameters(cGroup);
    //     }
    //   }
    //   filter.Groups[i] = g;
    // }
  };
  const handleSubmit = () => {
    const resolvedFilter: any = { ...filter };
    let isRelative = checkForRelatives(resolvedFilter.Groups);

    resetParameters(resolvedFilter);

    debugger;
    post({
      Name: filterName,
      Type: filterType,
      IsPublic: isPublic,
      Accounts: isPublic ? undefined : accounts,
      HasRelative: isRelative,
      Data: resolvedFilter
    });
  };
  const errorRef = useRef(0);
  const [hasErrors, setHasErrors] = useState(false);
  // useLayoutEffect(() => {
  //   if (errorRef.current === 0) setHasErrors(false);
  //   else setHasErrors(true);
  // });
  const { loadingName, isNameValid, handleBlur } = useNameValidator(
    filterName,
    filterType,
    advancedfilter ? advancedfilter.Id : undefined
  );
  const { Groups } = filter;
  const canSubmit =
    isNameValid &&
    filterName.length > 0 &&
    filter.Groups.length > 0 &&
    !hasErrors;
  const [isCancelling, setIsCancelling] = useState(false);
  const disabled = isCancelling;
  const intl = useIntl();
  return (
    <BaseSidebarContainer width={540}>
      <BaseSidebarHeader>
        <FormattedMessage id="PLAN_ADVANCED" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <div className="d-flex flex-column h-100">
          <div className="px-3 py-3 flex-1 of-y-auto">
            {isCancelling && (
              <div className="a-advanced-filter-canceling"></div>
            )}
            <FormInput
              className="mb-3"
              text={intl.formatMessage({ id: "FILTER_NAME" })}
              value={filterName}
              onBlur={handleBlur}
              disabled={disabled}
              onChange={(e: any) => setFilterName(e.target.value)}
            />
            {!isNameValid && !loadingName && (
              <div>
                <ErrorAlert className="mb-3">
                  <FormattedMessage id="FILTER_NAME_NOT_VALID" />
                </ErrorAlert>
              </div>
            )}
            <div className="mt-2 mb-3 d-flex align-items-center">
              <Switch
                disabled={disabled}
                checked={isPublic}
                onChange={(e: any) => setIsPublic(e.target.checked)}
              ></Switch>
              <span className="ml-2 text-black fs-14 fw-medium">
                <FormattedMessage id="PUBLIC" />
              </span>
            </div>
            {!isPublic && (
              <FormInput
                disabled={disabled}
                className="mb-3"
                text={intl.formatMessage({ id: "USERS" })}
                value={accounts}
                multiple
                inputType={UserDropdown}
                excludeMe
                onChange={(e: any) => setAccounts(e.target.value)}
              />
            )}
            <AdvancedFilterhasErrorSetterContext.Provider value={setHasErrors}>
              <AdvancedFilterItemList
                disabled={disabled}
                errorRef={errorRef}
                Groups={Groups}
                removeGroup={removeGroup}
                updateGroup={updateGroup}
                updateCondition={updateCondition}
              />
            </AdvancedFilterhasErrorSetterContext.Provider>
            <div className="mt-3">
              <AdvancedFilterButtons
                disabled={disabled}
                addCondition={addCondition}
                addGroup={addGroup}
              />
            </div>
          </div>
          {isCancelling ? (
            <RemovalFooter
              endpoint={endpoint}
              onSuccess={() => onRemoval(editId)}
              onCancel={() => setIsCancelling(false)}
            />
          ) : (
            <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
              {canDelete ? (
                <Button
                  vType="link-danger"
                  className="mr-3"
                  onClick={() => setIsCancelling(true)}
                >
                  <FormattedMessage id="DELETE_FILTER" />
                </Button>
              ) : (
                <div></div>
              )}
              <LoadableButton
                disabled={!canSubmit}
                isLoading={loading || loadingName}
                onClick={handleSubmit}
              >
                <FormattedMessage id="APPLY" />
              </LoadableButton>
            </div>
          )}
        </div>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};
const RemovalFooter = ({ endpoint, onSuccess, onCancel }: any) => {
  const [deleteFilter, { loading }]: any = useSpaceDelete(endpoint, null, {
    onSuccess: onSuccess
  });
  return (
    <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
      <div className="text-black">
        <FormattedMessage id="DELETE_FILTER_WARNING" />
      </div>
      <div className="d-flex align-items-center">
        <Button
          vType="outline-danger"
          type="button"
          className="mr-3"
          onClick={onCancel}
        >
          <FormattedMessage id="BACK" />
        </Button>
        <LoadableButton
          vType="danger"
          type="button"
          isLoading={loading}
          onClick={() => {
            deleteFilter();
          }}
        >
          <FormattedMessage id="DELETE" />
        </LoadableButton>
      </div>
    </div>
  );
};
