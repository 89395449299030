import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const ClientDetailsComp = React.lazy(() =>
  import(/* webpackChunkName: "clients-details" */ "./ClientDetails2Comp")
);

const ClientDetails = () => {
  return (
    <SuspenseErrorBoundary>
      <ClientDetailsComp />
    </SuspenseErrorBoundary>
  );
};

export default ClientDetails;
