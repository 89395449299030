import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useMemo, useRef, useState } from "react";
import {
  CalendarViewEnum,
  CalenderRefetchRefContext,
  isValidCalendarView,
  monthTranslation
} from "./CalendarHelper";
import classes from "./Calendar.module.css";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import CalendarMonthView from "./CalendarViews/CalendarMonthView/CalendarMonthView";
import CalendarWeekView from "./CalendarViews/CalendarWeekView/CalendarWeekView";
import Button from "../Button/Button";
import CalendarDailyView from "./CalendarViews/CalendarDailyView/CalendarDailyView";
import { GridRequestContext, ServerAwareContext } from "../CGrid/ServerGrid";
import {
  faArrowsRotate,
  faCalendarAlt,
  faCalendarDay,
  faCalendarWeek
} from "@fortawesome/pro-light-svg-icons";
import { FilterColumnColorProvider } from "../FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";

const CalendarContent = ({ type, selectedDate, setSelectedDate, settings }) => {
  switch (type) {
    case CalendarViewEnum.Month:
      return (
        <CalendarMonthView
          settings={settings}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      );

    case CalendarViewEnum.Week:
      return (
        <CalendarWeekView
          settings={settings}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      );

    case CalendarViewEnum.Day:
      return (
        <CalendarDailyView
          settings={settings}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      );

    default:
      return null;
  }
};

const ViewTypeSelectorItem = ({
  viewType,
  isSelected,
  textId,
  setViewType,
  icon
}) => {
  const handleClick = () => {
    setViewType(viewType);
  };
  return (
    <div
      onClick={handleClick}
      className={classnames(
        "p-2 fs-14 d-flex align-items-center",
        classes.viewSelectorButton,
        {
          [classes.selected]: isSelected
        }
      )}
    >
      <FontAwesomeIcon icon={icon} className="mr-2" />
      <FormattedMessage id={textId} />
    </div>
  );
};
const ViewTypeSelector = ({ selectedView, setViewType }) => {
  return (
    <div className={classnames("d-flex ml-2", classes.viewSelector)}>
      <ViewTypeSelectorItem
        icon={faCalendarDay}
        isSelected={selectedView === CalendarViewEnum.Day}
        setViewType={setViewType}
        viewType={CalendarViewEnum.Day}
        textId={"DAILY"}
      />
      <ViewTypeSelectorItem
        icon={faCalendarWeek}
        isSelected={selectedView === CalendarViewEnum.Week}
        setViewType={setViewType}
        viewType={CalendarViewEnum.Week}
        textId={"WEEKLY"}
      />
      <ViewTypeSelectorItem
        icon={faCalendarAlt}
        isSelected={selectedView === CalendarViewEnum.Month}
        setViewType={setViewType}
        viewType={CalendarViewEnum.Month}
        textId={"MONTHLY"}
      />
    </div>
  );
};

const CalendarHeader = ({
  selectedDate,
  setSelectedDate,
  viewType,
  setViewType,
  disableViewSwitcher = false,
  resetDate,
  refetch
}) => {
  const { year, month } = useMemo(() => {
    const month = selectedDate.getMonth();
    const year = selectedDate.getFullYear();
    return { month, year };
  }, [selectedDate]);

  const f = useIntl().formatMessage;

  const handleBack = () => {
    let newD;
    const date = selectedDate.getDate();

    const month = selectedDate.getMonth();
    switch (viewType) {
      case CalendarViewEnum.Month:
        newD = new Date(year, month - 1, 1);
        break;

      case CalendarViewEnum.Week:
        newD = new Date(year, month, date - 7);
        break;

      case CalendarViewEnum.Day:
        newD = new Date(year, month, date - 1);
        break;
      default:
        break;
    }
    setSelectedDate(newD);
  };

  const handleForward = () => {
    let newD;

    const month = selectedDate.getMonth();
    const date = selectedDate.getDate();

    switch (viewType) {
      case CalendarViewEnum.Month:
        newD = new Date(year, month + 1, 1);
        break;

      case CalendarViewEnum.Week:
        newD = new Date(year, month, date + 7);
        break;

      case CalendarViewEnum.Day:
        newD = new Date(year, month, date + 1);
        break;
      default:
        break;
    }
    setSelectedDate(newD);
  };

  return (
    <div
      // style={{ height: 50 }}
      className="mb-2 d-flex align-items-center justify-content-between text-black"
    >
      <div className="d-flex align-items-center">
        <div
          onClick={handleBack}
          className={classnames("mr-1", classes.dateArrow)}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div onClick={handleForward} className={classes.dateArrow}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className="ml-2 mr-2 fs-22">
          {`${f({ id: monthTranslation[month] })}, ${year}`}
        </div>
      </div>
      <div className="d-flex">
        <Button vType="outline-primary" onClick={resetDate}>
          <FormattedMessage id="TODAY" />
        </Button>

        {!disableViewSwitcher && (
          <ViewTypeSelector selectedView={viewType} setViewType={setViewType} />
        )}
        <Button
          vType="outline-primary"
          onClick={() => {
            if (refetch.current) refetch.current();
          }}
          className="ml-2"
          style={{ height: 40 }}
          size="sm"
        >
          <FontAwesomeIcon icon={faArrowsRotate} />
        </Button>
      </div>
    </div>
  );
};

const Calendar = ({ settings, disableViewSwitcher, defaultView }) => {
  const { params, onParamsChange } = useContext(ServerAwareContext);
  const refetchRef = useRef();
  const [viewType, setViewType] = useState(() => {
    const urlViewType = Number(params.calendarView);
    return isValidCalendarView(urlViewType)
      ? urlViewType
      : defaultView || CalendarViewEnum.Month;
  });

  const [selectedDate, setSelectedDate] = useState(() => {
    // const date = new Date(2020, 10, 16);

    const urlDate = Number(params.calendarDate);

    const date = isNaN(urlDate) ? new Date() : new Date(urlDate);

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  });
  const handleUpdateDate = (newD) => {
    onParamsChange({ additionalParams: { calendarDate: newD.getTime() } });
    setSelectedDate((oldD) =>
      newD.getTime() !== oldD.getTime() ? newD : oldD
    );
  };

  const handleResetDate = () => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    onParamsChange({ additionalParams: { calendarDate: undefined } });
    setSelectedDate(date);
  };

  const handleViewTypeUpdate = (viewType) => {
    onParamsChange({
      additionalParams: {
        calendarView: defaultView === viewType ? undefined : viewType
      }
    });
    setViewType(viewType);
  };

  return (
    <div className="h-100 flex-1 pb-4 d-flex flex-column">
      <CalenderRefetchRefContext.Provider value={refetchRef}>
        <CalendarHeader
          viewType={viewType}
          setViewType={handleViewTypeUpdate}
          selectedDate={selectedDate}
          disableViewSwitcher={disableViewSwitcher}
          setSelectedDate={handleUpdateDate}
          resetDate={handleResetDate}
          refetch={refetchRef}
        />
        <FilterColumnColorProvider>
          <CalendarContent
            settings={settings}
            type={viewType}
            selectedDate={selectedDate}
            setSelectedDate={handleUpdateDate}
          />
        </FilterColumnColorProvider>
      </CalenderRefetchRefContext.Provider>
    </div>
  );
};

export default Calendar;
