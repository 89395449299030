import { useCallback } from "react";
import axios from "axios";
import { useSpace } from "../Contexts/SpaceContext";
import { handleError } from "./MiscHelper";

export const useImageFetcher = (ExtraTypes, createToast) => {
  const space = useSpace();

  // const getHeaders = useCallback(
  //   (url) =>
  //     new Promise(function (resolve, reject) {
  //       axios
  //         .head(`${url}`)
  //         .then(function (response) {
  //           resolve(response);
  //         })
  //         .catch((error) => {
  //           reject(error);
  //         });
  //     }),
  //   []
  // );

  const getData = useCallback(
    (url) =>
      new Promise(function (resolve, reject) {
        let BlobFile, DataFile;
        axios
          .get(`spaces/${space.Id}/Social/Post/Image?url=${url}`, {
            responseType: "blob", // important
          })
          //data:image/gif;base64,
          .then(function (response) {
            BlobFile = response;
            var reader = new FileReader();
            reader.readAsDataURL(response);
            reader.onloadend = function () {
              DataFile = reader.result;
              resolve({ BlobFile, DataFile });
            };
          })
          .catch((error) => {
            // console.log(error);
            handleError(
              createToast,
              "Ocorreu um erro a importar a imagem do link"
            );
            reject(error);
          });
      }),
    [createToast, space.Id]
  );
  return getData;
};
