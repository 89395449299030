import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const ClientOverviewComp = React.lazy(() =>
  import(/* webpackChunkName: "clients-overview" */ "./ClientOverviewComp")
);

const ClientOverview = () => {
  return (
    <SuspenseErrorBoundary>
      <ClientOverviewComp />
    </SuspenseErrorBoundary>
  );
};

export default ClientOverview;
