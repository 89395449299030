// import { v1 as uuidv1 } from "uuid";
const lsKey = "ar-color-filter";

let lsObj;

const getColorFilterDict = (spaceId) => {
  const lsValue = localStorage.getItem(`${lsKey}-space-${spaceId}`);
  if (!lsValue) return {};
  const resolvedVal = JSON.parse(lsValue);
  lsObj = resolvedVal;
  return lsObj;
};

const getSchemaDict = (schema, spaceId) => {
  const dict = getColorFilterDict(spaceId);
  return dict[schema.name] || {};
};

export const getAllFiltersBySchema = (schema, spaceId) => {
  const schemaValue = getSchemaDict(schema, spaceId);

  const { filters } = schemaValue;

  return filters || [];
};

export const getSelectedFiltersBySchema = (schema, spaceId) => {
  const schemaValue = getSchemaDict(schema, spaceId);

  const { selectedFilters } = schemaValue;

  return selectedFilters || [];
};

export const getFilterBySchema = (schema, Id, spaceId) => {
  const filters = getAllFiltersBySchema(schema, spaceId);

  const filter = filters.find((v) => v.Id === Id);
  return filter;
};

const updateSchemaDict = (schema, update, spaceId) => {
  const dict = getColorFilterDict(spaceId);

  const rDict = { ...dict };

  if (!rDict[schema.name]) rDict[schema.name] = update;
  else rDict[schema.name] = { ...rDict[schema.name], ...update };

  lsObj = rDict;
  localStorage.setItem(`${lsKey}-space-${spaceId}`, JSON.stringify(lsObj));
};

export const updateColorFitlersBySchema = (schema, filters, spaceId) => {
  const dict = getColorFilterDict(spaceId);

  const rDict = { ...dict };

  rDict[schema.name] = { ...rDict[schema.name], filters };

  lsObj = rDict;
  localStorage.setItem(`${lsKey}-space-${spaceId}`, JSON.stringify(lsObj));
};

export const updateFilterBySchema = (schema, filter, spaceId) => {
  const schemaDict = getSchemaDict(schema, spaceId);
  const filters = schemaDict.filters ? [...schemaDict.filters] : [];

  const index = filters.findIndex((v) => v.Id === filter.Id);
  if (index === -1) return addFilterBySchema(schema, filter, spaceId);

  filters[index] = filter;
  updateSchemaDict(schema, { filters }, spaceId);

  return filter;
};

export const addFilterBySchema = (schema, filter, spaceId) => {
  const schemaDict = getSchemaDict(schema, spaceId);
  const filters = schemaDict.filters ? [...schemaDict.filters] : [];
  const selectedFilters = schemaDict.selectedFilters
    ? [...schemaDict.selectedFilters]
    : [];



  filters.push(filter);
  selectedFilters.push(filter.Id);

  updateSchemaDict(schema, { filters, selectedFilters }, spaceId);
  return filter;
};

export const removeFilterBySchema = (schema, filterId, spaceId) => {
  const schemaDict = getSchemaDict(schema, spaceId);
  const filters = schemaDict.filters ? [...schemaDict.filters] : [];
  const selectedFilters = schemaDict.selectedFilters
    ? [...schemaDict.selectedFilters]
    : [];

  const index = filters.findIndex((f) => f.Id === filterId);
  if (index !== -1) filters.splice(index, 1);

  const sIndex = selectedFilters.indexOf(filterId);
  if (sIndex !== -1) selectedFilters.splice(sIndex, 1);

  updateSchemaDict(schema, { filters, selectedFilters }, spaceId);
  return selectedFilters;
};

export const removeSelectedFilterBySchema = (schema, filterId, spaceId) => {
  const schemaDict = getSchemaDict(schema, spaceId);
  const selectedFilters = schemaDict.selectedFilters
    ? [...schemaDict.selectedFilters]
    : [];

  const index = selectedFilters.indexOf(filterId);

  if (index !== -1) selectedFilters.splice(index, 1);
  updateSchemaDict(schema, { selectedFilters }, spaceId);
  return selectedFilters;
};

export const removeAllSelectedFilterBySchema = (schema, spaceId) => {
  // const schemaDict = getSchemaDict(schema);

  updateSchemaDict(schema, { selectedFilters: [] }, spaceId);
};

export const addSelectedFilterBySchema = (schema, filterId, spaceId) => {
  const schemaDict = getSchemaDict(schema, spaceId);
  const selectedFilters = schemaDict.selectedFilters
    ? [...schemaDict.selectedFilters]
    : [];

  const index = selectedFilters.indexOf(filterId);

  if (index === -1) selectedFilters.push(filterId);
  updateSchemaDict(schema, { selectedFilters }, spaceId);
  return selectedFilters;
};
