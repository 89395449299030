import React from "react";
import SuspenseErrorBoundary from "../../../../Components/Suspense/SuspenseErrorBoundary";

const ActivityOverviewComp = React.lazy(() =>
  import(/* webpackChunkName: "activity-overview" */ "./ActivityOverviewComp")
);

const ActivityOverview = ({...props}) => {
  return (
    <SuspenseErrorBoundary>
      <ActivityOverviewComp {...props} />
    </SuspenseErrorBoundary>
  );
};

export default ActivityOverview;
