import React, { useMemo } from "react";
import {
  TaskFormButton,
  InterventionFormButton,
  CallFormButton,
  EmailConversationButton
} from "../Form/FormButtons";
import {
  createTaskStatusFilter,
  createUserFilter,
  createOriginFilter,
  createDepartmentFilter,
  createTeamFilter,
  createIndirectClientFilter,
  createIndirectProjectFilter,
  createIndirectContractFilter,
  createIndirectDealFilter,
  createOrganizationFilter,
  createReadFilter,
  createUnreadFilter,
  createFollowingFilter,
  createNotFollowingFilter,
  createTaskTypeFilter,
  createUpgradedPipelineStatusFilter,
  createTaskTagFilter
  // createPipelineStatusFilter
} from "../../Helpers/FilterHelper";
import { FormattedMessage, useIntl } from "react-intl";
import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import {
  Communicator,
  createCommunicatorHook
} from "../../Helpers/Communicator";
import BreadcrumbsPopup from "../../Components/Breadcrumbs/BreadcrumbsPopup";
import { originTypes } from "../../Helpers/MiscHelper";
import {
  spaceCallsSchema,
  spacePipelineStatusSchema,
  spaceTasksSchema,
  spaceTicketsSchema
} from "../../config/schema";
import { getEntity } from "../../Helpers/IOClient";
import { LabelTitle } from "../../Helpers/GridHelper";
import {
  moduleTypes,
  canUserEditModule,
  canUserReadModule
} from "../../Helpers/ModulesHelper";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { TasksClipboardButton } from "../../Helpers/HooksHelper";
import EntityReadButton from "../../Components/EntityRead/EntityReadButton";
import { ConnectedAccountsTeamsDepartmentsProfileStack } from "../../Components/Accounts/AccountProfileStack";
import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";
import { useToast } from "../../Components/Toast/ToastProvider";
import {
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import { canUpdateEntity, ConclusionForm } from "../ChildForms/ChildForms";
import { useHandleError } from "../../Components/CGrid/ServerAwareHelper";
import { ProjectStatusTypes } from "../Projects/ProjectsStatusHelper";
import { DeleteButtonContextMenu } from "../../Components/CGrid/DeleteMultipleModal";
import { ColorFilterBars } from "../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { TimeTrackingGridFormButton } from "../TimeTracking/TimeTracking";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignJustify,
  faBell,
  faBellSlash,
  faBuilding,
  faCalendarAlt,
  faDrawCircle,
  faEye,
  faEyeSlash,
  faFileContract,
  faHandshake,
  faSquareParking,
  faSitemap,
  faListCheck,
  faSuitcase,
  faUser,
  faUserFriends,
  faUserPlus,
  faUsers,
  faStickyNote
} from "@fortawesome/pro-light-svg-icons";
import { ConnectedAccountProfile } from "../../Components/Accounts/AccountProfile";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";
import { TaskTargetDropdown } from "../AdvancedMultiInputs";
import { walk } from "../../Components/Checklist/tree_list_utils";

export const useResolvedToDos = (ToDos) => {
  return useMemo(() => {
    if (!ToDos) return null;
    const data = ToDos;
    const returnData = (e) => {
      return {
        title: e.Name || e.title,
        Name: e.Name || e.title,
        children: [],
        ParentId: e.ParentId,
        Id: e.Id,
        Checked: e.Checked,
        expanded: true,
        ...e
      };
    };

    let initialData = data.filter((e) => !e.ParentId).map((e) => returnData(e));

    const buildObject = (arr) => {
      if (!data.some((e) => e.ParentId)) return [];

      arr.forEach((item) => {
        item.children = data
          .filter((e) => e.ParentId === item.Id)
          .map((e) => returnData(e));

        buildObject(item.children);
      });
      return arr;
    };

    //   const getFlatList = (arr, current) => {
    // 	current.forEach(item => {
    // 	  arr.push({ name: item.title, Id: item.Id, parentId: item.parentId });
    // 	  if (item.children) {
    // 		getFlatList(arr, item.children);
    // 	  }
    // 	});
    // 	return arr;
    //   };

    buildObject(initialData);
    return initialData;
  }, [ToDos]);
};

export const validateTaskForm = (
  {
    TaskTarget,
    Company,
    BeginDate,
    Pipeline,
    PipelineStatus,
    StatusDetailId,
    StatusDetail,
    EndDate,
    InternalDescription,
    EstimatedCost,
    Manager,
    Name
  },
  addError
) => {
  const Status = getEntity(spacePipelineStatusSchema, PipelineStatus);
  if (!Company) addError("Company");
  if (!TaskTarget) addError("TaskTarget");
  if (!Name) addError("Name");
  if (!Pipeline) addError("Pipeline");
  if (!PipelineStatus) addError("PipelineStatus");
  if (
    Status &&
    Status.Status === TasksStatusTypes.canceled &&
    !StatusDetailId &&
    !StatusDetail
  )
    addError("StatusDetailId");

  if (!Manager) addError("Manager");
  if (!EstimatedCost) addError("EstimatedCost");

  if (!BeginDate) addError("BeginDate");
  if (!EndDate) addError("EndDate");

  // if (InternalDescription && InternalDescription?.length > 65535)
  //   addError(
  //     "InternalDescription",
  //     <FormattedMessage id="INTERNAL_DESCRIPTION_TOO_BIG" />
  //   );

  if (
    BeginDate &&
    EndDate &&
    new Date(EndDate).getTime() < new Date(BeginDate).getTime()
  )
    addError(
      "EndDate",
      <FormattedMessage id="FORM_END_DATA_BIGGER_THAN_START_DATA" />
    );
};

export const TasksStatusTypes = {
  open: 1,
  canceled: 3,
  draft: 5,
  closed: 4
};

export const TaskEntityButtons = ({ entity, skeleton, onSuccess }) => {
  const isPositiveStatus =
    entity.Status !== TasksStatusTypes.canceled &&
    entity.Status !== TasksStatusTypes.finished;

  return (
    <React.Fragment>
      <TaskFormButton
        id={entity.Id}
        canEdit={entity.CanEdit}
        skeleton={skeleton}
        className="mr-2"
        onSuccess={onSuccess}
      />
      {isPositiveStatus && (
        <CallFormButton
          className="mr-2"
          alt
          skeleton={skeleton}
          formProps={{ taskId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <TaskFormButton
          className="mr-2"
          alt
          formProps={{ taskId: entity.Id }}
          skeleton={skeleton}
          onSuccess={onSuccess}
        />
      )}

      {isPositiveStatus && (
        <InterventionFormButton
          className="mr-2"
          alt
          skeleton={skeleton}
          formProps={{ taskOrigin: entity, taskId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <TaskFormButton
          id={entity.Id}
          alt
          skeleton={skeleton}
          onSuccess={onSuccess}
          duplicate
        />
      )}
    </React.Fragment>
  );
};

export const TaskEntityActionsChildren = ({
  entity,
  minimal,
  sidebar,
  detailsView
}) => {
  const isPositiveStatus =
    entity.Status !== ProjectStatusTypes.canceled &&
    entity.Status !== ProjectStatusTypes.finished;
  const accountSpace = useCurrentAccountSpace();
  return (
    <>
      {!sidebar && !detailsView && (
        <TaskFormButton contextIcons id={entity.Id} />
      )}
      {!detailsView && <TaskFormButton globalEntityView id={entity.Id} />}

      {(canUserEditModule(accountSpace, moduleTypes.interventions) ||
        canUserEditModule(accountSpace, moduleTypes.tasks) ||
        canUserEditModule(accountSpace, moduleTypes.calls)) &&
        isPositiveStatus && (
          <LabelTitle title={<FormattedMessage id={"SERVICES"} />} />
        )}
      {canUserEditModule(accountSpace, moduleTypes.interventions) && (
        <TimeTrackingGridFormButton
          origin={{
            type: "Task",
            data: entity.Id
          }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.calls) && (
        <CallFormButton
          hasAccess={isPositiveStatus}
          formProps={{ taskId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.tasks) && (
        <TaskFormButton
          hasAccess={isPositiveStatus}
          formProps={{ taskId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.interventions) && (
        <InterventionFormButton
          hasAccess={isPositiveStatus}
          formProps={{ taskOrigin: entity, taskId: entity.Id }}
        />
      )}

      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      {!sidebar && entity.CanEdit && (
        <TaskFormButton id={entity.Id} canEdit={entity.CanEdit} contextIcons />
      )}
      <TaskFormButton id={entity.Id} duplicate />
      <TasksClipboardButton id={entity.Id} />
      {canUserReadModule(accountSpace, moduleTypes.emailConversations) && (
        <EmailConversationButton formProps={{ taskId: entity.Id }} />
      )}
      <EntityReadButton value={entity} schema={spaceTasksSchema} />
      <DeleteButtonContextMenu entity={entity} />
    </>
  );
};

export const TaskEntityActionsButton = ({ minimal, ...props }) => {
  return (
    <EntityPopup minimal={minimal}>
      <TaskEntityActionsChildren minimal={minimal} {...props} />
    </EntityPopup>
  );
};

export const TaskEntityGridButtons = ({
  entity,
  skeleton,
  minimal,
  hideBreadCrumbs,
  noColors
}) => {
  return (
    <React.Fragment>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="d-flex align-items-center"
      >
        {!hideBreadCrumbs && (
          <BreadcrumbsPopup
            className="mr-2 fs-14 d-inline-flex text-primary"
            originType={originTypes.task}
            minimal
            id={entity.Id}
          />
        )}
        <TaskFormButton
          minimal={minimal}
          id={entity.Id}
          canEdit={entity.CanEdit}
          skeleton={skeleton}
          className="mr-2 FormButtonClassID"
        />
        <TaskEntityActionsButton entity={entity} minimal={minimal} />
        {!noColors && <ColorFilterBars entity={entity} />}
      </div>
    </React.Fragment>
  );
};

export const taskActionType = {
  creation: "creation",
  update: "update"
};

export const taskCommunicator = new Communicator();

export const useTaskCommunicator = createCommunicatorHook(taskCommunicator);

export const TaskAssociations = ({ value }) => {
  const { Manager, Company, Client, IndirectClient } = value;
  const resolvedClient = Client || IndirectClient || undefined;
  return (
    <>
      <ConnectedAccountsTeamsDepartmentsProfileStack
        size="xsm"
        organizations={[Company]}
        accounts={[Manager]}
      />
      <div className="vertical-splitter h-100 mx-2" />
      <div className="ar-board-pics align-items-center d-flex">
        <ConnectedClientOrigin client={resolvedClient}>
          <ConnectedClientProfile
            size="xsm"
            client={resolvedClient}
            justImage
          />
        </ConnectedClientOrigin>
      </div>
    </>
  );
};
export const useTaskPost = ({ onSuccess, id, TaskTarget }) => {
  const createToast = useToast();
  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceTasksSchema);
  return useSidebarSpacePost(id ? `tasks/${id}` : "tasks", spaceTasksSchema, {
    onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
      onSuccess && onSuccess(newId);
      if (!id) {
        defineChildProps({ Task: newId });
        taskCommunicator.dispatch({
          type: taskActionType.creation,
          id: newId
        });
      } else {
        taskCommunicator.dispatch({ type: taskActionType.update, id });
      }

      const { data, type } = TaskTarget;

      if (type === "Ticket" && canUpdateEntity(spaceTicketsSchema, data)) {
        return openSidebar(
          <ConclusionForm ticket={data} />,
          intl.formatMessage({ id: "CONCLUDE_TICKET" })
        );
      }
      // else if (type === "Task" && canUpdateEntity(spaceTasksSchema, data)) {
      //   return openSidebar(<ConclusionForm task={data} />, "Concluir Tarefa");
      // }
      else if (type === "Call" && canUpdateEntity(spaceCallsSchema, data)) {
        return openSidebar(
          <ConclusionForm call={data} />,
          intl.formatMessage({ id: "CONCLUDE_CALL" })
        );
      }

      createToast({
        pos: "tm",
        type: "success",
        title: `${intl.formatMessage({ id: "TASK" })} nº ${newId || id}`,
        description: !id
          ? `${intl.formatMessage({ id: "CREATED_SUCCEFULLY" })}`
          : `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`
      });
    },
    onError: ({ error }) => {
      handleTranslatedError(error);
    }
  });
};

export const useTaskDelete = ({ onSuccess, id, TaskTarget }) => {
  const createToast = useToast();
  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceTasksSchema);
  return useSidebarSpaceDelete(`tasks/${id}`, null, {
    onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
      onSuccess && onSuccess();

      taskCommunicator.dispatch({ type: taskActionType.update, id });

      createToast({
        pos: "tm",
        type: "success",
        title: `${intl.formatMessage({ id: "TASK" })} nº ${id}`,
        description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
      });
    },
    onError: ({ error }) => {
      handleTranslatedError(error);
    }
  });
};

export const taskPipelineSettings = {
  field: "Task/PipelineId",
  pipelineStatusField: "Task/PipelineStatusId",
  type: PipelineTypeEnum.Task
};

export const useTasksFilterColumns = (userId) => {
  return useMemo(() => {
    return [
      {
        propField: "Name",
        icon: <FontAwesomeIcon icon={faAlignJustify} />,
        titleId: "NAME",
        field: "Task/Name",
        type: "string"
      },
      createUserFilter({
        propField: "Manager",
        titleId: "ASSIGNED_TO",
        field: "Assigned/Id",
        icon: <FontAwesomeIcon icon={faUser} />,
        defaultFilters: [
          {
            field: "Assigned/Id",
            operator: "eq",
            value: userId
          }
        ]
      }),
      // {
      //   propField: "CreationDate",
      //   icon: <FontAwesomeIcon icon={faCalendarAlt} />,
      //   titleId: "CREATION_DATE",
      //   field: "Task/CreationDateNoHours",
      //   type: "dateTime"
      // },
      createIndirectClientFilter({
        icon: <FontAwesomeIcon icon={faSuitcase} />,
        field: "DirectIndirectClient/Id"
      }),
      createIndirectContractFilter({
        icon: <FontAwesomeIcon icon={faFileContract} />,
        field: "DirectIndirectContract/Id"
      }),
      createUserFilter({
        propField: "Creator",
        icon: <FontAwesomeIcon icon={faUserPlus} />,
        titleId: "CREATED_BY",
        field: "Creator/Id",
        DescriptionComponent: ({ item, value }) => {
          return (
            <div className="d-flex align-items-center">
              <FormattedMessage id="CREATED_BY" className="mr-2" />
              <ConnectedAccountProfile account={value} />
            </div>
          );
        }
      }),

      {
        propField: "BeginDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "START_DATE",
        field: "Task/BeginDateNoHours",
        type: "dateTime"
      },
      {
        propField: "PipelineDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_PIPELINE_STATUS_DATE",
        field: "Task/PipelineDate",
        type: "dateTime"
      },
      {
        propField: "StatusDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_STATUS_DATE",
        field: "Task/StatusDate",
        type: "dateTime"
      },
      {
        propField: "CloseDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "CLOSE_DATE",
        field: "Task/CloseDateNoHours",
        type: "dateTime"
      },
      createTaskTagFilter({ requiresParams: true }),
      createDepartmentFilter({ icon: <FontAwesomeIcon icon={faUsers} /> }),
      createOrganizationFilter({
        icon: <FontAwesomeIcon icon={faBuilding} />,
        field: "Company/Id"
      }),
      createTeamFilter({ icon: <FontAwesomeIcon icon={faUserFriends} /> }),
      createTaskStatusFilter({
        icon: <FontAwesomeIcon icon={faDrawCircle} />,
        defaultFilters: [
          {
            field: "PipelineStatus/Status",
            operator: "eq",
            value: 1
          }
        ]
      }),
      createTaskTypeFilter({
        icon: <FontAwesomeIcon icon={faStickyNote} />,
        propField: "TaskType"
      }),
      // createPipelineStatusFilter({
      //   icon: <FontAwesomeIcon icon={faDrawCircle} />,
      //   pipelineSettings: taskPipelineSettings
      // }),
      createUpgradedPipelineStatusFilter({
        icon: <FontAwesomeIcon icon={faDrawCircle} />,
        pipelineSettings: taskPipelineSettings
      }),
      createIndirectDealFilter({
        icon: <FontAwesomeIcon icon={faHandshake} />,
        field: "DirectIndirectDeal/Id"
      }),
      createReadFilter({
        icon: <FontAwesomeIcon icon={faEye} />,
        baseField: "Task"
      }),
      {
        propField: "Id",
        icon: <FontAwesomeIcon icon={faListCheck} />,
        title: "Nº",
        field: "Task/Id",
        type: "string"
      },
      createUnreadFilter({
        icon: <FontAwesomeIcon icon={faEyeSlash} />,
        baseField: "Task"
      }),
      createFollowingFilter({
        icon: <FontAwesomeIcon icon={faBell} />,
        baseField: "Task"
      }),
      createNotFollowingFilter({
        icon: <FontAwesomeIcon icon={faBellSlash} />,
        baseField: "Task"
      }),
      createOriginFilter({
        icon: <FontAwesomeIcon icon={faSitemap} />,
        taskName: "ParentId",
        baseFieldName: "Task",
        dropdownType: TaskTargetDropdown
      }),
      {
        propField: "EndDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "DEADLINE",
        field: "Task/EndDateNoHours",
        type: "dateTime"
      },
      createIndirectProjectFilter({
        icon: <FontAwesomeIcon icon={faSquareParking} />,
        field: "DirectIndirectProject/Id"
      })
    ];
  }, [userId]);
};

export const formatTodosGood = (data) => {
  const returnData = (e) => {
    return {
      title: e.Name || e.title,
      Name: e.Name || e.title,
      children: [],
      ParentId: e.ParentId,
      Id: e.Id,
      Checked: e.Checked,
      expanded: true,
      ...e
    };
  };

  let initialData = data.filter((e) => !e.ParentId).map((e) => returnData(e));

  const buildObject = (arr) => {
    if (!data.some((e) => e.ParentId)) return [];

    arr.forEach((item) => {
      item.children = data
        .filter((e) => e.ParentId === item.Id)
        .map((e) => returnData(e));

      buildObject(item.children);
    });
    return arr;
  };

  buildObject(initialData);

  let checkedNodes = 0;
  let checklistNodes = 0;

  walk({
    treeData: initialData,
    getNodeKey: ({ node, treeIndex }) => {
      return treeIndex;
    },
    callback: (e) => {
      if (e.node.Checked) {
        checkedNodes++;
        checklistNodes++;
      } else {
        checklistNodes++;
      }
    }
  });

  return {
    ToDosChecked: checkedNodes,
    ToDosCount: checklistNodes,
    ToDos: initialData
  };
};
