import React from "react";
import { useCurrentUserContactPermission } from "../../Helpers/ContactPermissionsHelper";
import { FormattedPhoneNumber } from "../Input/PhoneInput";

export const EmailColumn = ({ email }) => {
  const noContactReadPermission = !useCurrentUserContactPermission();

  if (!email) return "―";

  if (noContactReadPermission) return email;

  return (
    <a className="text-black text-truncate" href={`mailto:${email}`}>
      {email}
    </a>
  );
};

export const PhoneColumn = ({ phoneNumber }) => {
  const noContactReadPermission = !useCurrentUserContactPermission();

  if (!phoneNumber) return "―";

  if (noContactReadPermission) return phoneNumber;

  return (
    <div className="text-truncate">
      <FormattedPhoneNumber number={phoneNumber} />
    </div>
  );
};
