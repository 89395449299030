import React, { useState, useRef, useMemo, useContext } from "react";
import SplitCol from "../../../Components/SplitCol/SplitCol";
import FormInput from "../../../Components/Forms/FormInput/FormInput";

import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import PhoneInput from "../../../Components/Input/PhoneInput";
import {
  isEmail,
  createFormData,
  handleError,
  isValidDomainName,
  hasPhoneNumberError,
  canSubmitPhoneNumber,
  appendFormDataImages
} from "../../../Helpers/MiscHelper";
import { withSpace } from "../../../Contexts/SpaceContext";
import withSpaceEntityForm from "../../../Components/Forms";
import SideMenuForm from "../../../Components/Forms/SideMenuForm";
import {
  ContactMediumTypes,
  spaceOrganizationSchema
} from "../../../config/schema";
import {
  UserDropdown,
  PrimaryDomainDropdown,
  IndustryDropdown
} from "../../AdvancedMultiInputs";
import {
  LogoPictures,
  OrganizationPicture
} from "../../../Components/ProfilePicture/ProfilePicture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTrashAlt, faPlus } from "@fortawesome/pro-light-svg-icons";
import { AccountProfileImg, AccountLogoImg } from "../../../Components/Img/Img";
import TimeTablePicker from "../../../Components/TimetablePicker/TimeTablePicker";
import Button from "../../../Components/Button/Button";
import LoadableButton from "../../../Components/Button/LoadableButton";
import { ReactComponent as Facebook } from "../../FirstWelcome/Assets/facebook-brands.svg";
import { ReactComponent as Twitter } from "../../FirstWelcome/Assets/twitter-brands.svg";
import { ReactComponent as Linkedin } from "../../FirstWelcome/Assets/linkedin-brands.svg";
import { ReactComponent as Instagram } from "../../FirstWelcome/Assets/instagram-brands.svg";
import { ReactComponent as Youtube } from "../../FirstWelcome/Assets/youtube-brands.svg";
import AddressSelector from "../../../Components/AddressSelector/AddressSelector";
import { getResultAddresses } from "../../../Helpers/FormFormmaterHelper";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import InformationComponent from "../../../Components/InformationComponent/InformationComponent";
import classnames from "classnames";
import { useOnboarding } from "../../SpaceOnboarding/SpaceOnboardingHelper";
import { withUser } from "../../../Contexts/UserContext";
import Radio from "../../../Components/Radio/Radio";
import { inboxEmailDomain } from "../../../config";
import Input from "../../../Components/Input/Input";
import TabStrip from "../../../Components/TabStrip/TabStrip";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";
import { useConnectionId } from "../../RealTime/RealTime";
import { useToast } from "../../../Components/Toast/ToastProvider";
import KeyedDropdown from "../../../Components/Dropdown/KeyedDropdown";
import { useOrganization } from "../../../Hooks/EntityHooks";

const OrganizationFormFooter = ({ children }) => {
  const onboarding = useOnboarding();
  return (
    <div
      className={classnames(
        "py-3 of-y-auto d-flex align-items-center justify-content-between",
        { "shadow-top px-3": !onboarding }
      )}
    >
      {children}
    </div>
  );
};

const OrganizationDomainSelector = ({
  value,
  onChange,
  DomainName,
  DomainType,
  handleDomainTypeChange,
  Company,
  error
}) => {
  const handleRadioChange = (bol) => {
    if (value && !bol) onChange(null);
    handleDomainTypeChange({ name: "DomainType", value: bol });
  };

  const f = useIntl().formatMessage;

  return (
    <div className="d-flex flex-column">
      <Radio
        name="customDomain"
        value={false}
        textValue={f({ id: "USE_DEFAULT" })}
        checked={!DomainType && !value}
        change={() => handleRadioChange(false)}
      ></Radio>
      <Radio
        name="customDomain"
        value={true}
        textValue={f({ id: "CUSTOM_DOMAIN" })}
        checked={DomainType || value}
        change={() => handleRadioChange(true)}
      ></Radio>

      {DomainType || value ? (
        <PrimaryDomainDropdown
          Company={Company}
          className={classnames("mt-2", { error: error })}
          value={value}
          onChange={onChange}
        />
      ) : (
        <Input
          disabled
          className="mt-2"
          value={`*.${DomainName}@${inboxEmailDomain}`}
        />
      )}
    </div>
  );
};

const DetailsForm = () => {
  const intl = useIntl();
  const onboarding = useOnboarding();
  const [setFormState, props] = useContext(FormStateContext);
  const handleChanges = (v) => {
    const {
      target: { name, value }
    } = v;
    if (typeof value === "function") {
      setFormState((oldV) => {
        const newVal = value(oldV);

        return { [name]: newVal };
      });
    } else {
      setFormState({
        [name]: value
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      [name]: value
    });
  };

  const handleWebsiteChange = (e) => {
    const { name, value } = e.target;
    const newValue = value.replace(/(^\w+:|^)\/\//, "");

    if (value.indexOf("http://") === 0) {
      setFormState({
        Protocol: "http",
        [name]: newValue
      });
    } else if (value.indexOf("https://") === 0) {
      setFormState({
        Protocol: "https",
        [name]: newValue
      });
    } else {
      setFormState({
        [name]: newValue
      });
    }
  };

  const handleTimeTableChange = (e) => {
    setFormState({
      Schedules: e.target.value
    });
  };

  const { form } = props;

  const {
    FiscalNumber,
    Addresses,
    Email,
    PhoneNumber,
    Name,
    Image,
    Industry,
    ReportImage,
    Schedules,
    SocialNetworks
  } = form;

  const DefaultAddress = {
    Id: undefined,
    Country: "",
    Address1: "",
    Address2: "",
    District: "",
    Locality: "",
    PostalCode: "",
    Latitude: "",
    Longitude: ""
  };

  const handleAddressesChanges = (e) => {
    const resolvedData = Addresses
      ? { ...Addresses[0], ...e.target.value }
      : { ...e.target.value };

    setFormState({
      Addresses: [resolvedData]
    });
  };

  if (onboarding) {
    return (
      <>
        <div className="fw-medium fs-16 text-black mb-3">
          <FormattedMessage id="COMPANY_INFORMATION" />
        </div>
        <FormInput
          textInputClassname={onboarding ? "bg-white" : undefined}
          text={
            <div className="d-flex align-items-center">
              <span className="mr-2">
                <FormattedMessage id="ORGANIZATION_NAME" />
              </span>
              <InformationComponent
                text={<FormattedMessage id="ORGANIZATION_NAME_INFO_DESC" />}
              />
            </div>
          }
          className="mb-3"
          name="Name"
        />
        <FormInput
          textInputClassname={onboarding ? "bg-white" : undefined}
          textId="ORGANIZATIONS_SHORT_NAME"
          text={
            <div className="d-flex align-items-center">
              <span className="mr-2">
                {intl.formatMessage({ id: "SHORT_NAME" })}
              </span>
              <InformationComponent
                text={
                  <span>
                    <FormattedMessage id="SHORT_NAME_INFORMATION" /> support.
                    <b>{intl.formatMessage({ id: "SHORT_NAME_DES" })}</b>
                    @inbox.aidesk.ai
                  </span>
                }
              />
            </div>
          }
          className="mb-3"
          name="DomainName"
        />
      </>
    );
  }

  return (
    <>
      <div className="fw-medium fs-16 text-black my-3">
        <FormattedMessage id="BRANDING" />
      </div>

      <div className="mb-3" style={{ display: "flex" }}>
        <FormInput
          textId="THUMBNAIL"
          text={intl.formatMessage({ id: "THUMBNAIL" }) + " 100x100"}
          name="Image"
          inputType={OrganizationPicture}
          className="mr-3"
          uploadText={
            <div className="link-color fs-12 mt-1">
              <FontAwesomeIcon icon={faCamera} />{" "}
              <FormattedMessage id="UPLOAD_IMAGE" />
            </div>
          }
          placeHolderComponent={
            <AccountProfileImg
              className="rounded-50 mt-1"
              account={{ Name, Image }}
            />
          }
        />
        <FormInput
          textId="LOGO_FOR_REPORTS"
          text={
            intl.formatMessage({ id: "LOGO_FOR_REPORTS" }) + " 400x100 (4:1)"
          }
          name="ReportImage"
          inputType={LogoPictures}
          uploadText={
            <div className="link-color fs-12 mt-1">
              <FontAwesomeIcon icon={faCamera} />{" "}
              <FormattedMessage id={"UPLOAD_LOGO"} />
            </div>
          }
          placeHolderPhoto={ReportImage}
          placeHolderComponent={
            <AccountLogoImg
              showRectagle
              className="border w-100 h-100 mt-1"
              account={{ Name: "", Image }}
              url={ReportImage}
            />
          }
        />
      </div>
      <div className="border-top w-100 mb-3"></div>
      <div className="fw-medium fs-16 text-black mb-3">
        <FormattedMessage id="COMPANY_INFORMATION" />
      </div>
      <FormInput
        textInputClassname={onboarding ? "bg-white" : undefined}
        text={
          <div className="d-flex align-items-center">
            <span className="mr-2">
              <FormattedMessage id="NAME" />
            </span>
            <InformationComponent
              text={<FormattedMessage id="ORGANIZATION_NAME_INFO_DESC" />}
            />
          </div>
        }
        className="mb-3"
        name="Name"
      />

      <FormInput
        inputType={IndustryDropdown}
        textId="INDUSTRY"
        value={Industry}
        multiple
        className="mb-3"
        name="Industry"
      />

      <SplitCol
        leftpercent={18}
        rightpercente={82}
        left={
          <FormInput
            textInputClassname={onboarding ? "bg-white" : undefined}
            textId="PROTOCOL"
            inputType={KeyedDropdown}
            closeOnSelect
            options={["https", "http"]}
            className="mb-3"
            name="Protocol"
          />
        }
        right={
          <FormInput
            textInputClassname={onboarding ? "bg-white" : undefined}
            onChange={handleWebsiteChange}
            textId="WEBSITE"
            className="mb-3"
            placeholder="mydomain.com"
            name="Website"
          />
        }
      />

      <FormInput
        textInputClassname={onboarding ? "bg-white" : undefined}
        textId="SHORT_NAME"
        text={
          <div className="d-flex align-items-center">
            <span className="mr-2">
              {intl.formatMessage({ id: "SHORT_NAME" })}
            </span>
            <InformationComponent
              text={
                <span>
                  <FormattedMessage id="SHORT_NAME_INFORMATION" /> support.
                  <b>{intl.formatMessage({ id: "SHORT_NAME_DES" })}</b>
                  @inbox.aidesk.ai
                </span>
              }
            />
          </div>
        }
        className="mb-3"
        name="DomainName"
      />

      <FormInput
        textInputClassname={onboarding ? "bg-white" : undefined}
        textId="NIF"
        className="mb-3"
        name="FiscalNumber"
        value={FiscalNumber}
        onChange={handleChange}
      />

      <SplitCol
        left={
          <FormInput
            textInputClassname={onboarding ? "bg-white" : undefined}
            textId="EMAIL"
            className="mb-3"
            name="Email"
            value={Email}
            onChange={handleChange}
          />
        }
        right={
          <FormInput
            onboarding={onboarding}
            textId="PHONE_NUMBER"
            inputType={PhoneInput}
            className="mb-3"
            name="PhoneNumber"
            value={PhoneNumber}
            onChange={handleChange}
          />
        }
      />

      <FormInput
        onboarding={onboarding}
        value={Addresses ? Addresses[0] : DefaultAddress}
        name="AddressSelector"
        textId="ADDRESS"
        className="mb-3"
        inputType={AddressSelector}
        onChange={handleAddressesChanges}
      />

      {/* <FormInput
		text={intl.formatMessage({ id: "address" })}
		className="mb-3"
		name="Address"
		value={Address}
		onChange={handleChange}
	/>

	<FormInput
		text={intl.formatMessage({ id: "postal_code" })}
		className="mb-3"
		name="PostalCode"
		value={PostalCode}
		onChange={handleChange}
	/> */}

      <div className="border-top w-100 mb-3"></div>
      <div className="fw-medium fs-16 text-black mb-3">
        <FormattedMessage id="SCHEDULE" />
      </div>

      <FormInput
        onboarding={onboarding}
        className="mb-3"
        name="Schedules"
        textId="WORK_HOURS"
        inputType={TimeTablePicker}
        value={Schedules}
        multiple
        onChange={handleTimeTableChange}
      />

      <div className="border-top w-100 mb-3"></div>

      <FormInput
        onboarding={onboarding}
        className="mb-3"
        onChange={handleChanges}
        inputType={CompanySocialNetworks}
        value={SocialNetworks}
        name="SocialNetworks"
      />
    </>
  );
};

const AdvancedForm = () => {
  const onboarding = useOnboarding();
  const [setFormState, props] = useContext(FormStateContext);

  const { form } = props;

  const { Id, DomainName, DomainType } = form;

  const handleDomainTypeChange = (e) => {
    const { name, value } = e;
    setFormState({
      [name]: value
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      [name]: value
    });
  };

  return (
    <>
      {Id && (
        <FormInput
          textInputClassname={onboarding ? "bg-white" : undefined}
          name="Domain"
          className="mb-3"
          textId="PRIMARY_DOMAIN"
          // inputType={PrimaryDomainDropdown}
          inputType={OrganizationDomainSelector}
          DomainName={DomainName}
          DomainType={DomainType}
          handleDomainTypeChange={handleDomainTypeChange}
          onChange={handleChange}
          // placeholder={`*.${DomainName}@${inboxEmailDomain}`}
          Company={Id}
        />
      )}
    </>
  );
};

const TeamsForm = () => {
  const onboarding = useOnboarding();
  const [setFormState, props] = useContext(FormStateContext);

  const { form } = props;

  const { Managers, Collaborators } = form;

  const handleManagerChange = (e) => {
    const { value, name } = e.target;

    const resolvedValue = Collaborators.filter((f) => !value.includes(f));

    setFormState({
      [name]: value,
      Collaborators: resolvedValue
    });
  };

  const handleColaboratorChange = (e) => {
    const { value, name } = e.target;
    const resolvedValue = Managers.filter((f) => !value.includes(f));
    setFormState({
      [name]: value,
      Managers: resolvedValue
    });
  };

  return (
    <>
      <FormInput
        textInputClassname={onboarding ? "bg-white" : undefined}
        name="Managers"
        className="mb-3"
        textId="MANAGER"
        onChange={handleManagerChange}
        inputType={UserDropdown}
        multiple
      />

      <FormInput
        textInputClassname={onboarding ? "bg-white" : undefined}
        name="Collaborators"
        className="mb-3"
        onChange={handleColaboratorChange}
        textId="COLLABORATORS"
        inputType={UserDropdown}
        multiple
      />
    </>
  );
};

const FormStateContext = React.createContext();

const OrganizationFormContent = ({ setFormState, refetchRef, ...props }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const { formStatus, form, entity } = props;
  const { CanDelete } = entity || {};

  const { Id } = form;

  const previousOrganizationState = useOrganization(Id) || {};

  const onboarding = useOnboarding();

  const tabItems = useMemo(() => {
    return [
      {
        default: true,
        name: <FormattedMessage id={"DETAILS"} />,
        component: <DetailsForm />
      },
      {
        name: <FormattedMessage id={"TEAM"} />,
        component: <TeamsForm />
      },
      {
        name: <FormattedMessage id={"ADVANCED_SETTINGS"} />,
        component: <AdvancedForm />
      }
    ];
  }, []);

  const Content = (
    <FormStateContext.Provider value={[setFormState, { ...props }]}>
      <SideMenuForm
        formStatus={formStatus}
        footerComponent={
          !Id ? (
            <OrganizationFormFooter>
              <div></div>
              <LoadableButton
                className={onboarding ? "w-100" : undefined}
                isLoading={formStatus.isProcessing}
                onClick={formStatus.submit}
                vType={onboarding ? "secondary" : "primary"}
              >
                <FormattedMessage id={"CONFIRM"} />
              </LoadableButton>
            </OrganizationFormFooter>
          ) : !isDeleting ? (
            <OrganizationFormFooter>
              {CanDelete ? (
                <Button
                  vType="link-danger fw-bold"
                  className="mr-3"
                  onClick={() => setIsDeleting(true)}
                >
                  <span style={{ fontWeight: 500 }}>
                    <FormattedMessage id="DELETE" />
                  </span>
                </Button>
              ) : (
                <div></div>
              )}
              <LoadableButton
                isLoading={formStatus.isProcessing}
                onClick={(e) => {
                  formStatus.submit(e, previousOrganizationState);
                }}
              >
                <FormattedMessage id={"CONFIRM"} />
              </LoadableButton>
            </OrganizationFormFooter>
          ) : (
            <RemovalFooter
              endpoint={`organizations/${Id}`}
              refetchRef={refetchRef}
              onCancel={() => setIsDeleting(false)}
            />
          )
        }
      >
        {onboarding ? (
          <DetailsForm />
        ) : (
          <TabStrip
            contentClassName={"px-3"}
            alignLeft
            route={false}
            items={tabItems}
            className="h-100"
          />
        )}
      </SideMenuForm>
    </FormStateContext.Provider>
  );

  if (onboarding) return Content;

  return (
    <BaseSidebarContainer width={800}>
      <BaseSidebarHeader>
        <FormattedMessage id={"COMPANY"} />
      </BaseSidebarHeader>
      <BaseSidebarContent>{Content}</BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

// const ErrorMessageComponent = ({ DomainName, Website }) => {
//   if (Website) {
//     return <FormattedMessage id={"WEBSITE_NAME_ERROR"} />;
//   }
//   if (DomainName?.length > 30) {
//     return <FormattedMessage id={"DOMAIN_NAME_ERROR_LENGTH"} />;
//   } else if (DomainName?.length > 0 && !isValidDomainName(DomainName)) {
//     return <FormattedMessage id={"DOMAIN_NAME_ERROR_FORMAT"} />;
//   } else return "";
// };

export const EntityOrganizationForm = withUser(
  withSpaceEntityForm(withSpace(injectIntl(OrganizationFormContent)), {
    defaultForm: ({ user }) => {
      return {
        Name: "",
        DomainType: false,
        Domain: "",
        DomainName: "",
        FiscalNumber: "",
        Address: "",
        PostalCode: "",
        Email: "",
        PhoneNumber: "",
        Place: "",
        Region: "",
        Protocol: "https",
        Files: [],
        Locale: "",
        Country: undefined,
        Contacts: [],
        Industries: [],
        Image: null,
        Latitude: "",
        Longitude: "",
        Managers: [user.Id],
        Collaborators: [],
        ReportImage: null,
        Schedules: [],
        SocialNetworks: [],
        Website: ""
      };
    },
    onSuccess: ({ createToast }) => {
      createToast({
        pos: "tm",
        type: "success",
        description: <FormattedMessage id="ORGANIZATION_SAVE_SUCCESS" />
      });
    },
    onError: ({ error, createToast }) => {
      // const { status } = error;
      // if (status === 409) {
      //   createToast({
      //     pos: "tm",
      //     type: "danger",
      //     description: <FormattedMessage id="SPACE_UPDATE_DOMAIN_CONFLICT" />
      //     // title: `Erro`,
      //   });
      // } else
      try {
        const parsedError = JSON.parse(error.data);
        handleError(createToast, parsedError);
      } catch (e) {
        handleError(createToast, error);
      }
    },
    convertEntityToForm: ({ Website, ...rest }, props) => {
      const resolvedWebsite = Website?.replace(/(^\w+:|^)\/\//, "") || "";

      if (Website?.indexOf("http://") === 0) {
        return { ...rest, Website: resolvedWebsite, Protocol: "http" };
      } else if (Website?.indexOf("https://") === 0) {
        return { ...rest, Website: resolvedWebsite, Protocol: "https" };
      } else {
        return { ...rest, Website: resolvedWebsite };
      }
    },
    validateForm: (form, addError) => {
      const {
        Name,
        Managers,
        // Website,
        Schedules,
        Email,
        PhoneNumber,
        DomainType,
        Domain,
        DomainName
      } = form;

      let isNameValid = Name.length > 0;
      if (!isNameValid) addError("Name");

      if (DomainType && (!Domain || Domain.length === 0)) {
        addError("Domain");
      }
      // if (Website && (Website.length > 30 || !isValidDomain(Website)))
      //   addError("Website");

      if (!DomainName || !isValidDomainName(DomainName))
        addError(
          "DomainName",
          <FormattedMessage id={"DOMAIN_NAME_ERROR_FORMAT"} />
        );

      if (Email && !isEmail(Email))
        addError("Email", <FormattedMessage id="EMAIL_CORRECT" />);

      if (hasPhoneNumberError(PhoneNumber))
        addError(
          "PhoneNumber",
          <FormattedMessage id="INVALID_NUMBER_WARNING" />
        );
      // if (Domain.length > 0 && !isValidDomain(Domain))
      //   addError("Domain", <FormattedMessage id={"DOMAIN_ERROR_FORMAT"} />);

      if (Array.isArray(Schedules)) {
        for (const e of Schedules) {
          if (e?.Days && e?.Days.length === 0) {
            addError(
              "Schedules",
              <FormattedMessage id="INVALID_SCHEDULE_DAYS" />
            );
            break;
          }
        }
      }

      if (Managers.length < 1) addError("Managers");
      // if (Collaborators.length < 1) addError("Collaborators");
    },
    formatForm: (form, previousForm) => {
      const { Image: OldImage, ReportImage: oldReportImage } = previousForm || {};


      let {
        Email,
        PhoneNumber,
        Image,
        ReportFiles,
        Schedules,
        Industry,
        Protocol,
        Addresses,
        Collaborators,
        SocialNetworks,
        Website,
        Managers,
        Domain,
        ReportImage,
        ...newForm
      } = form;

      //   const Files = []
      const Files =
        !Image || typeof Image === "string"
          ? []
          : [
              {
                File: Image.file,
                Name: Image.file.name,
                Extension: ""
              }
            ];

      const ContactMediums = [];

      if (canSubmitPhoneNumber(PhoneNumber))
        ContactMediums.push({
          ContactMediumTypeId: ContactMediumTypes.PhoneNumber,
          Contact: PhoneNumber
        });

      if (isEmail(Email)) {
        ContactMediums.push({
          ContactMediumTypeId: ContactMediumTypes.Email,
          Contact: Email
        });
      }

      //   if (!(!Image || typeof Image === "string")) {
      // 	  Files.push({
      //           File: Image.file,
      //           Name: Image.file.name,
      //           Extension: ""
      //         })
      //   }

      newForm.ContactMediums = JSON.stringify(ContactMediums);

      const resolvedWebsite =
        Website && Website.length > 0 ? `${Protocol}://${Website}` : undefined;

      const formData = createFormData(
        {
          ...newForm,
          DomainId: Domain,
          Industry,
          Schedules: JSON.stringify(Schedules),
          SocialNetworks: JSON.stringify(SocialNetworks),
          Collaborators: JSON.stringify(Collaborators),
          Managers: JSON.stringify(Managers),
          Website: resolvedWebsite,
          Addresses: Addresses
            ? Addresses[0]
              ? JSON.stringify(getResultAddresses(Addresses[0]))
              : JSON.stringify([])
            : JSON.stringify([])
        },
        Files
      );
      if (Files.length === 0 && !Image && OldImage) {
        formData.append(
          "Files",
          JSON.stringify({ Id: -1, Name: "REMOVE_PLEASE" })
        );
      }

      if (ReportImage && typeof ReportImage !== "string") {
        appendFormDataImages(formData, [
          {
            File: ReportImage.file,
            Name: ReportImage.file.name,
            Extension: ""
          }
        ]);
      } else if (!ReportImage && oldReportImage) {
        formData.append(
          "Images",
          JSON.stringify({ Id: -1, Name: "REMOVE_PLEASE" })
        );
      }

      // if (ReportImage && typeof ReportImage === "object") {

      // } else {
      //   formData.append("Images", "");
      // }
      return formData;
    },
    schema: spaceOrganizationSchema
    // getEntityId: (state, ownProps) => ownProps.organizationId
  })
);

const RemovalFooter = ({ endpoint, refetchRef, onCancel }) => {
  const handleTranslatedError = useHandleError(spaceOrganizationSchema);
  const conId = useConnectionId();
  const createToast = useToast();
  const intl = useIntl();
  const [deleteUser, { loading }] = useSidebarSpaceDelete(
    endpoint,
    spaceOrganizationSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "COMPANY" })}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef,
      headers: {
        ConnectionID: conId
      }
    }
  );

  return (
    <OrganizationFormFooter>
      <div className="text-black">
        <FormattedMessage id="DELETE_COMPANY_MSG" />
      </div>

      <div className="d-flex align-items-center">
        <Button
          vType="outline-danger"
          type="button"
          className="mr-3"
          onClick={onCancel}
        >
          <FormattedMessage id="BACK" />
        </Button>

        <LoadableButton
          vType="danger"
          type="button"
          isLoading={loading}
          onClick={() => {
            deleteUser();
          }}
        >
          <FormattedMessage id="DELETE" />
        </LoadableButton>
      </div>
    </OrganizationFormFooter>
  );
};

const SocialNetwork = ({
  data,
  index,
  onChange,
  removeItem,
  preview,
  length,
  onboarding
}) => {
  const removeDomain = (e) => {
    onChange((v) => {
      const arr = [...v];
      arr.splice(index, 1);
      return arr;
    });
  };

  const Icon = useMemo(() => {
    const type = SocialNetworkType(data.Url);
    switch (type) {
      case 1:
        return Facebook;
      case 2:
        return Instagram;
      case 3:
        return Youtube;
      case 4:
        return Linkedin;
      case 5:
        return Twitter;
      default:
        return <></>;
    }
  }, [data.Url]);

  return (
    <div
      className={classnames(
        "dropdown-container ar-checklist-input mb-3 p-0 ssi-control rounded",
        { "bg-white": onboarding }
      )}
    >
      <div className="h-100 d-flex justify-content-between align-items-center text-black px-2">
        <div>
          <Icon />
          <span className="ml-3">{data.Url}</span>
        </div>
        {!preview && (
          <div onClick={removeDomain} className="p-2 cursor-pointer">
            <FontAwesomeIcon className="text-danger-alt" icon={faTrashAlt} />
          </div>
        )}
      </div>
    </div>
  );
};

export const CompanySocialNetworks = ({
  onChange,
  preview,
  value,
  name,
  error,
  onboarding
}) => {
  const [inputError, setinputError] = useState("");

  const handleChanges = (getNewValue) => {
    onChange((v) => {
      return getNewValue(v.SocialNetworks);
    });
  };

  const inputRef = useRef();

  const handlePlusClick = () => {
    const inputValue = inputRef.current.value;
    if (inputValue.length > 0 && /\S/.test(inputValue)) {
      const type = SocialNetworkType(inputValue);
      if (type !== 0 && OnlyType(type, value)) {
        handleChanges((oldV) => {
          const arr = [...oldV];

          arr.push({ Url: inputValue, Type: type });
          inputRef.current.value = "";
          setinputError("");
          return arr;
        });
      } else if (type === 0) {
        setinputError(
          intl.formatMessage({ id: "INSERT_ONLY_VALID_SOCIAL_MEDIA_LINK" })
        );
      } else if (type !== 0 && !OnlyType(type, value)) {
        setinputError(
          intl.formatMessage({ id: "ONLY_1_LINK_PER_SOCIAL_NETWORK" })
        );
      }
    } else {
      setinputError("");
    }
  };

  const handleKeyPress = (e) => {
    if (
      e.key === "Enter" &&
      e.target.value.length > 0 &&
      /\S/.test(e.target.value)
    ) {
      e.preventDefault();
      const inputValue = inputRef.current.value;
      const type = SocialNetworkType(inputValue);
      if (type !== 0 && OnlyType(type, value)) {
        handleChanges((oldV) => {
          const arr = [...oldV];
          arr.push({ Url: inputValue, Type: type });
          inputRef.current.value = "";
          setinputError("");
          return arr;
        });
      } else if (type !== 0 && !OnlyType(type, value)) {
        e.preventDefault();
        setinputError(
          intl.formatMessage({ id: "ONLY_1_LINK_PER_SOCIAL_NETWORK" })
        );
      } else if (type === 0) {
        e.preventDefault();
        setinputError(
          intl.formatMessage({ id: "INSERT_ONLY_VALID_SOCIAL_MEDIA_LINK" })
        );
      } else if (e.key === "Enter") {
        e.preventDefault();
        setinputError("");
      }
    } else {
      setinputError("");
    }
  };
  const intl = useIntl();
  return (
    <div>
      <div className="mb-1 fs-14 d-flex text-black">
        <div className="fw-medium fs-16 text-black mb-3">
          <FormattedMessage id={"SOCIAL_MEDIA"} />
        </div>
        <span className="ml-2">
          <InformationComponent
            text={intl.formatMessage({ id: "ONLY_1_LINK_PER_SOCIAL_NETWORK" })}
          ></InformationComponent>
        </span>
        <span className="ml-2 text-danger-alt">{inputError}</span>
      </div>
      {!preview && (
        <div
          className={classnames(
            "dropdown-container ar-checklist-input mb-3 p-0 ssi-control rounded",
            { "bg-white": onboarding }
          )}
          tabIndex="1"
        >
          <label className="d-flex pl-0 align-items-center ar-dropdown-label-search multiple">
            <div
              style={{ height: 40 }}
              className="px-2 cursor-pointer pb-1 d-flex plus-sign-svg align-items-center justify-content-center"
              onClick={handlePlusClick}
            >
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <div className="flex-1 pb-1 of-hidden w-100">
              <input
                ref={inputRef}
                placeholder="Adicionar"
                onKeyPress={handleKeyPress}
                type="text"
                name="ar-dropdown"
                className="ar-dropdown-button w-100"
              />
            </div>
          </label>
        </div>
      )}
      {value.map((e, i, arr) => {
        return (
          <SocialNetwork
            onboarding={onboarding}
            key={i}
            length={arr.length}
            data={e}
            index={i}
            onChange={handleChanges}
            preview={preview}
          />
        );
      })}
    </div>
  );
};

export const pLinkType = {
  None: 0,
  Facebook: 1,
  Instagram: 2,
  Youtube: 3,
  Linkedin: 4,
  Twitter: 5
};

export const pLinkTypeDict = {
  0: "None",
  1: "Facebook",
  2: "Instagram",
  3: "Youtube",
  4: "Linkedin",
  5: "Twitter"
};

export const SocialNetworkType = (value) => {
  var pattern =
    /^([htps]{0,}[:/]{0,3})([w]{3}[.]){0,4}([\w]+[.])([\w]+)[.][\w]+\/[\S]+$|^([htps]{0,}[:/]{0,3})([w]{3}[.]){0,4}([\w]+)[.][\w]+\/[\S]+$/;

  const r = new RegExp(pattern);

  var match = value.match(r);
  //;

  var type = match ? match[4] || match[7] || "" : "";
  switch (type.toLowerCase()) {
    case "facebook":
      return pLinkType.Facebook;
    case "instagram":
      return pLinkType.Instagram;
    case "youtube":
      return pLinkType.Youtube;
    case "linkedin":
      return pLinkType.Linkedin;
    case "twitter":
      return pLinkType.Twitter;
    default:
      return pLinkType.None;
  }
};

export const OnlyType = (type, arr = []) => {
  //;
  const index = arr.findIndex((e) => typeof e === "object" && e.Type === type);
  if (index === -1) return true;
  else return false;
};

export default EntityOrganizationForm;
