import {
  createContractNotificationButton,
  createProjectPdfButton
} from "../Pdf/PdfHelper";
import { moduleTypes } from "../../Helpers/ModulesHelper";

export const ContractPdfButton = createProjectPdfButton({
  //   generateUrl: ({ id }) => `interventions/Export/GenerateTokenPdf/${id}/contracts`,
  generateUrl: ({ id, excel }) =>
    excel
      ? `Excel/ExportTimeCharges/contracts/${id}/ToFile`
      : `PDF/ExportTimeCharges/contracts/${id}/ToFile`,
  AddQueryStrings: true,

  download: true,
  moduleType: moduleTypes.contracts
});

export const SendContractNotificationButton = createContractNotificationButton({
  //   generateUrl: ({ id }) => `interventions/Export/GenerateTokenPdf/${id}/contracts`,
  generateUrl: ({ id }) => `Contracts/EmailClientReport/${id}`,
  AddQueryStrings: true,

  moduleType: moduleTypes.contracts
});
