import React, {
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from "react";

const ReceptionCallbacksContext = React.createContext();
const ReceptionResizeContext = React.createContext();

export const useReceptionResizeCallback = (callback) => {
  const { addCallback, removeCallback } = useContext(ReceptionCallbacksContext);
  useEffect(() => {
    addCallback(callback);

    return () => {
      removeCallback(callback);
    };
  }, [addCallback, callback, removeCallback]);
};

export const useReceptionResizer = () => useContext(ReceptionResizeContext);

export const ReceptionResizerProvider = ({ children }) => {
  const callbacksRef = useRef([]);

  const ReceptionCallbacksValue = useMemo(() => {
    const addCallback = (callback) => callbacksRef.current.push(callback);

    const removeCallback = (callback) => {
      const index = callbacksRef.current.indexOf(callback);
      callbacksRef.current.splice(index, 1);
    };

    return { addCallback, removeCallback };
  }, []);

  const resize = useCallback(() => {
    for (const callback of callbacksRef.current) {
      callback();
    }
  }, []);

  useEffect(() => {
    const listener = () => {
      resize();
    };

    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [resize]);

  return (
    <ReceptionCallbacksContext.Provider value={ReceptionCallbacksValue}>
      <ReceptionResizeContext.Provider value={resize}>
        {children}
      </ReceptionResizeContext.Provider>
    </ReceptionCallbacksContext.Provider>
  );
};
