import { useReducer } from "react";

function toggler(state) {
  return !state;
}

export const useModalStatusReducer = () => {
  const [state, toggle] = useReducer(toggler, false);
  return [state, toggle];
};
