import React, { useMemo, useState } from "react";
import FormInput from "../../../Components/Forms/FormInput/FormInput";

import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import { createFormData } from "../../../Helpers/MiscHelper";
import { withSpace } from "../../../Contexts/SpaceContext";
import withSpaceEntityForm from "../../../Components/Forms";
import SideMenuForm from "../../../Components/Forms/SideMenuForm";
import {
  spaceDocumentTypesSchema,
  spaceProjectCategorySchema
} from "../../../config/schema";
import {
  CompanyDeptTeamDropdown,
  DepartmentDropdown,
  OrganizationDropdown,
  TeamDropdown
  //   DepartmentTeamDropdown,
  //   convertToDepartmentTeams,
  //   convertDepartmentTeams
} from "../../AdvancedMultiInputs";
// import FileUploader from "../../../Components/FileUploader/FileUploader";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import Button from "../../../Components/Button/Button";
import LoadableButton from "../../../Components/Button/LoadableButton";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";

const DocumentTypesForm = ({ refetchRef, ...props }) => {
  const { formStatus, isChild, form } = props;
  const [isDeleting, setIsDeleting] = useState(false);

  const { Id, CanDelete, Combined } = form;

  const footerComp = useMemo(() => {
    if (!Id)
      return (
        <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
          <div></div>
          <LoadableButton
            type="button"
            isLoading={formStatus.isProcessing}
            onClick={formStatus.submit}
          >
            <FormattedMessage id={"CONFIRM"} />
          </LoadableButton>
        </div>
      );

    if (!isDeleting) {
      return (
        <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
          {CanDelete ? (
            <Button
              type="button"
              vType="link-danger fw-bold"
              className="mr-3"
              onClick={() => setIsDeleting(true)}
            >
              <span style={{ fontWeight: 500 }}>
                <FormattedMessage id="DELETE" />
              </span>
            </Button>
          ) : (
            <div></div>
          )}
          <LoadableButton
            type="button"
            isLoading={formStatus.isProcessing}
            onClick={formStatus.submit}
          >
            <FormattedMessage id={"CONFIRM"} />
          </LoadableButton>
        </div>
      );
    } else
      return (
        CanDelete && (
          <RemovalFooter
            endpoint={`Documents/Types/${Id}`}
            refetchRef={refetchRef}
            onCancel={() => setIsDeleting(false)}
          />
        )
      );
  }, [
    CanDelete,
    Id,
    formStatus.isProcessing,
    formStatus.submit,
    isDeleting,
    refetchRef
  ]);

  const intl = useIntl();

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id="DOCUMENT_TYPE" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideMenuForm footerComponent={footerComp} formStatus={formStatus}>
          <FormInput name="Name" textId="DESCRIPTION" className="mb-3" />
          {/* <FormInput
            name="Organizations"
            className="mb-3"
            textId="COMPANIES_SUBSIDIARIES"
            inputType={OrganizationDropdown}
            multiple
          />
          <FormInput
            name="Departments"
            className="mb-3"
            textId="Departments"
            inputType={DepartmentDropdown}
            multiple
          />
          <FormInput
            name="Teams"
            className="mb-3"
            textId="TEAMS"
            inputType={TeamDropdown}
            multiple
          /> */}

          <FormInput
            name="Combined"
            className="mb-3"
            textId={
              "SHARE_WITH"
            }
            inputType={CompanyDeptTeamDropdown}
            multiple
          />

          {/* <FormInput name="Domain" textId="DOMAIN" className="mb-3" />

          <FormInput
            name="Files"
            textId="FILES"
            inputType={FileUploader}
            extendedMode
            className="mb-3"
          /> */}
        </SideMenuForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

const RemovalFooter = ({ endpoint, refetchRef, onCancel }) => {
  const createToast = useToast();
  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceDocumentTypesSchema);
  const [deleteUser, { loading }] = useSidebarSpaceDelete(
    endpoint,
    spaceDocumentTypesSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "DOCUMENT_TYPE" })}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  return (
    <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
      <div className="text-black">
        <FormattedMessage id="ELIMINATE_DOCUMENT_TYPE" />
      </div>

      <div className="d-flex align-items-center">
        <Button
          type="button"
          vType="outline-danger"
          className="mr-3"
          onClick={onCancel}
        >
          <FormattedMessage id="BACK" />
        </Button>

        <LoadableButton
          vType="danger"
          isLoading={loading}
          onClick={() => {
            deleteUser();
          }}
        >
          <FormattedMessage id="DELETE" />
        </LoadableButton>
      </div>
    </div>
  );
};

export default withSpaceEntityForm(withSpace(injectIntl(DocumentTypesForm)), {
  defaultForm: {
    Organizations: [],
    Teams: [],
    Departments: [],
    Name: ""
  },
  validateForm: ({ Name }, addError) => {
    if (!Name) addError("Name");
  },
  convertEntityToForm: (entity) => {
    const {
      Teams = [],
      Departments = [],
      Organizations = [],
      ...rest
    } = entity;
    let Combined = [];

    for (const e of Teams) {
      Combined.push({ type: "Team", data: e });
    }
    for (const e of Departments) {
      Combined.push({ type: "Department", data: e });
    }
    for (const e of Organizations) {
      Combined.push({ type: "organization", data: e });
    }

    return {
      Combined,
      ...rest
    };
  },
  onSuccess: (props) => {
    const { createToast, intl, form } = props;
    const { Name } = form;
    createToast({
      pos: "tm",
      type: "success",
      title: `${intl.formatMessage({ id: "DOCUMENT_TYPE" })} ${Name}`,
      description: `${intl.formatMessage({
        id: "SUCCESS_SAVED"
      })}`
    });
    // handleToast(props)
  },
  formatForm: (form) => {
    let { Combined, ...rest } = form;

    let Teams = [];
    let Departments = [];
    let Organizations = [];

    for (const obj of Combined) {
      const { type, data } = obj;
      switch (type) {
        case "organization":
          Organizations.push(data);
          break;
        case "Team":
          Teams.push(data);
          break;
        case "Department":
          Departments.push(data);
          break;

        default:
          break;
      }
    }

    return {
      ...rest,
      organizations: Organizations.length === 0 ? undefined : Organizations,
      teams: Teams.length === 0 ? undefined : Teams,
      departments: Departments.length === 0 ? undefined : Departments
    };
  },
  schema: spaceDocumentTypesSchema
  // getEntityId: (state, ownProps) => ownProps.projectCategoryId
});
