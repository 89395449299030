import React from "react";
import classnames from "classnames";
import { useTaskType } from "../../Hooks/EntityHooks";

const TaskTypeProfile = ({ TaskType, className }) => {
  //   console.log(Projecttype);
  if (!TaskType) return null;
  return (
    <div className={classnames("d-flex align-items-center", className)}>
      <div className="flex-1">
        <div>{TaskType.Name}</div>
      </div>
    </div>
  );
};

export default TaskTypeProfile;

export const ConnectTaskTypeEntity = (Component) => {
  return ({ TaskType, ...rest }) => {
    //console.log(Projecttype);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const a = useTaskType(TaskType);
    return <Component TaskType={a} {...rest} />;
  };
};

export const ConnectedTaskTypeProfile = ConnectTaskTypeEntity(TaskTypeProfile);

// const mapStateToProps = (state, ownProps) => {
//   return {
//     Projecttype: state.Entities.SpaceProjectTypes[ownProps.Projecttype]
//   };
// };

// export const ConnectedProjectTypeProfile = connect(mapStateToProps)(
//   ProjectTypeProfile
// );
