import React from "react";
import TriggerCreation from "./TriggerCreation";
import { spaceClientSchema } from "../../../../../config/schema";
import { faSuitcase } from "@fortawesome/pro-solid-svg-icons";
import { ConnectedClientOrigin } from "../../../../Origin/ClientOrigin";
import { useIntl } from "react-intl";

const PreviewTarget = ({ val }) => <ConnectedClientOrigin client={val} />;

const TriggerClientCreation = React.memo(({ ...props }) => {
	const intl = useIntl();
  return (
    <TriggerCreation
      {...props}
      TargetPreview={PreviewTarget}
      schema={spaceClientSchema}
      icon={faSuitcase}
      text={intl.formatMessage({ id: "ACCOUNT" })}
    />
  );
});

export default TriggerClientCreation;
