import React from 'react'
import './FileUploaderSpinner.css'


export class FileUploaderSpinner extends React.Component{
    constructor(props){
      super(props)
      this.state = {}
    }

    render(){
      if(this.props.progress){
        let circ = 20*20*Math.PI;
        var circlePathStyle = {
            stroke: "#E7F1FD",
            fill: "transparent",
            border: "1px solid #D5DEE8",
            strokeLinecap: "round",
            strokeDasharray: `${circ} ${circ}`,
            strokeDashoffset: 0,
        }
        var circlePathBarStyle = {
          stroke: "#36AB80",
          fill: "transparent",
          strokeLinecap: "round",
          strokeDasharray: `${circ}`,
          strokeDashoffset: `${this.props.percentage?circ-this.props.percentage/1000 * circ:circ}`,
          transition: "strokeDashoffset 0.01s linear",
          transformOrigin: 'center center',
          transform:` rotate(270deg) translateX(-50px)`,
      }
      
        return(<svg className={`ar-loader__progress${this.props.size?' ar-loader__spinner-'+this.props.size:''}`} viewBox="25 25 50 50">
        <circle style={circlePathStyle} cx="50" cy="50" r="20" fill="none" strokeWidth={5} strokeMiterlimit="10">
        </circle>
        <circle style={circlePathBarStyle} cx="50" cy="50" r="20" fill="none" strokeWidth={5} strokeMiterlimit="10">
        </circle>
        </svg>)
      }
      else{
      return (
        <svg className={`${this.props.className?this.props.className+' ':''}ar-loader__spinner${this.props.size?' ar-loader__spinner-'+this.props.size:''}`} viewBox="25 25 50 50">
        <circle className="ar-loader__spinner__path" cx="50" cy="50" r="20" fill="none" strokeWidth={this.props.strokeWidth?this.props.strokeWidth:5} strokeMiterlimit="10">
        </circle>
        </svg>)
      }
    }
}


