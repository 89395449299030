import React, { useMemo } from "react";
import { spaceCallsSchema } from "../../config/schema";
import {
  EntitySummaryTabstrip,
  EntitySummaryTab,
  EntitySummaryOverview,
  EntitySummaryAccordion,
  DescriptionOverview,
  EntitySummaryAddress,
  EntityPipelineHistoryAccordion
} from "../../Components/EntitySummaries/EntitySummaries";
import { History } from "../../Components/DetailsView/History/History";
import CallForm from "./CallForm";
import { CallEntityActionsButton, CallStatusTypes } from "./CallsHelper";
import {
  faInfoSquare,
  faCity,
  faUsers
} from "@fortawesome/pro-solid-svg-icons";
import {
  faFileAlt,
  faUserTie,
  faLink,
  faCalendarAlt,
  faUsersClass,
  faSuitcase,
  faUserFriends,
  faUserPlus,
  faUserCog,
  faBell,
  faBuilding,
  faDrawCircle
} from "@fortawesome/pro-light-svg-icons";
import {
  EntityOverviewItem,
  EntityDescriptionOverviewItem,
  EntityOverviewRoundedItem,
  EntityOverviewPlaceholder,
  EntityOverviewChildrenProgress
} from "../../Components/EntityOverview/EntityOverview";

import OriginProfile from "../Origin/OriginProfile";
import RelativeTime from "../../Components/RelativeTime/RelativeTime";
import { ConnectedDepartmentProfile } from "../../Components/Departments/DepartmentProfile";
import { ConnectedTeamProfile } from "../../Components/Teams/TeamProfile";
import { EntityOverviewPipeline } from "../../Components/EntityOverview/EntityOverviewPipeline";
import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
import Treeview from "../Treeview/Treeview";
import { CallDetailsChildren } from "./CallDetails/CallDetailsChildren";
import { ConnectedAccountProfileStack } from "../../Components/Accounts/AccountProfileStack";
import { ConnectedContactPopup } from "../Origin/ContactPopup";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import { RealTimeRoomTypes } from "../RealTime/RealTimeHelper";
import { FormattedMessage } from "react-intl";
import { CallFormButton } from "../Form/FormButtons";
import { getInterventionTarget } from "../../Helpers/FormFormmaterHelper";
import { ConnectedOrganizationProfile } from "../../Components/Organizations/OrganizationProfile";
import {
  usePipelineStatus,
  useCancelationReason
} from "../../Hooks/EntityHooks";

export const CallSummaryHeader = ({ value }) => {
  const { NoTagDescription } = value;

  return NoTagDescription;
};

export const CallSummaryContent = ({ value }) => {
  const {
    Contact,
    CreationDate,
    Addresses,
    Pipeline,
    PipelineStatus,
    StatusDetail,
    Department,
    Team,
    Company,
    ManagerAccount,
    NoTagDescription,
    Description,
    Creator,
    ReceivedDate,
    Client,
    IndirectClient,
    Id,
    StatusDetailDescription,
    Followers
  } = value;

  const origin = useMemo(() => getInterventionTarget(value), [value]);
  const pipelineStatusObj = usePipelineStatus(PipelineStatus);
  const detail = useCancelationReason(StatusDetail);
  return (
    <>
      <EntitySummaryAccordion icon={faInfoSquare} textId={"DETAILS"}>
        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="ENTRY">
          <RelativeTime date={ReceivedDate} />
        </EntityOverviewRoundedItem>
        <EntityOverviewPipeline
          // StatusProfileComponent={ContractStatusProfile}
          // buildFunction={buildContractStatusType}
          pipeline={Pipeline}
          pipelineStatus={PipelineStatus}
        />

        {StatusDetail &&
          pipelineStatusObj.Status === CallStatusTypes.canceled && (
            <EntityOverviewRoundedItem
              icon={faDrawCircle}
              titleId="CANCELATION_REASON"
            >
              {detail.Name}
            </EntityOverviewRoundedItem>
          )}
        {StatusDetailDescription &&
          pipelineStatusObj.Status === CallStatusTypes.canceled && (
            <EntityDescriptionOverviewItem
              icon={faDrawCircle}
              titleId="CANCELATION_DESCRIPTION"
            >
              <DescriptionOverview
                Description={StatusDetailDescription}
                NoTagDescription={StatusDetailDescription}
              />
            </EntityDescriptionOverviewItem>
          )}

        <EntityOverviewItem icon={faLink} titleId="ASSOCIATED_TO">
          <OriginProfile dynamicFont origin={origin} />
        </EntityOverviewItem>

        <EntityOverviewChildrenProgress schema={spaceCallsSchema} id={Id} />

        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="DESCRIPTION">
          <DescriptionOverview
            Description={Description}
            NoTagDescription={NoTagDescription}
          />
        </EntityDescriptionOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faCity} textId="ASSOCIATED">
        {Contact && (
          <EntityOverviewItem icon={faUserTie} titleId="CONTACT">
            <ConnectedContactPopup
              placement="right"
              dynamicFont
              contact={Contact}
            />
          </EntityOverviewItem>
        )}
        <EntityOverviewItem icon={faSuitcase} titleId="ACCOUNT">
          <ConnectedClientOrigin
            placement="right"
            dynamicFont
            client={Client || IndirectClient}
          />
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faUsers} textId="ASSOCIATED_TO">
        <EntityOverviewItem icon={faBuilding} titleId="COMPANY">
          <ConnectedOrganizationProfile dynamicFont organization={Company} />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUsersClass} titleId="DEPARTMENT">
          {Department ? (
            <ConnectedDepartmentProfile dynamicFont department={Department} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>

        <EntityOverviewItem icon={faUserFriends} titleId="TEAM">
          {Team ? (
            <ConnectedTeamProfile dynamicFont team={Team} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>

        <EntityOverviewItem icon={faUserPlus} titleId="CREATED_BY">
          <ConnectedAccountPopup
            placement="right"
            dynamicFont
            account={Creator}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUserCog} titleId="ASSIGNED_TO">
          <ConnectedAccountPopup
            placement="right"
            dynamicFont
            account={ManagerAccount}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faBell} titleId="FOLLOWERS">
          {Followers && Followers.length > 0 ? (
            <ConnectedAccountProfileStack dynamicFont accounts={Followers} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
      </EntitySummaryAccordion>
      <EntitySummaryAddress addresses={Addresses} />
      {/* <EntityPipelineHistoryAccordion
        endpoint={`Calls/${Id}/PipelineHistory`}
        id={Id}
        schema={spaceCallsSchema}
      />
      <div className="mb-3"></div> */}
      <EntityPipelineHistoryAccordion
        endpoint={`history/Call/${Id}/pipeline`}
        id={Id}
        schema={spaceCallsSchema}
      />
    </>
  );
};

export const InnerCallSummary = ({ value }) => {
  return (
    <EntitySummaryTabstrip className="mt-2 mb-3">
      <EntitySummaryTab titleId="SUMMARY">
        <CallSummaryContent value={value} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="HISTORY">
        <History call={value.Id} disableTabs />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ORIGINATED">
        <Treeview className="flex-1" schema={spaceCallsSchema} id={value.Id} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ASSOCIATIONS">
        <CallDetailsChildren />
      </EntitySummaryTab>
    </EntitySummaryTabstrip>
  );
};

const CallSummary = (props) => {
  return (
    <EntitySummaryOverview
      {...props}
      url={"calls"}
      type={"call"}
      schema={spaceCallsSchema}
      FormComponent={CallForm}
      Summary={InnerCallSummary}
      creationHeaderText={<FormattedMessage id="CREATE_CALL" />}
      // noImage
      // TextComponent={CallSummaryHeader}
      ActionButton={CallEntityActionsButton}
      FormButton={CallFormButton}
      roomType={RealTimeRoomTypes.Call}
    ></EntitySummaryOverview>
  );
};

export default CallSummary;
