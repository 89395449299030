import React, { useContext, useMemo } from "react";
import FilterList from "./FilterList2";
import { ServerAwareContext } from "../CGrid/ServerGrid";

import { FilterListOpenStateContext } from "./FilterListHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import { GroupedByOrderByDictionary } from "../../Helpers/GroupbyHelper";
import { useTempFilters } from "../../Helpers/TempColumnIdHelper";
import { useLocation } from "react-router-dom";
import { ServerAwareViewsContext } from "../CGrid/ServerAwareViews";
import querystring from "query-string";
import {
  useServerAwareColumns,
  useServerAwareFilterColumns
} from "../CGrid/ServerAwareContexts";
import { removeAllSelectedFilterBySchema } from "./AdvancedFilter/AdvancedFilterColors/AdvancedFilterColorsLs";
import { useServerAwareColorFilterUpdater } from "../CGrid/ServerAwareColorFilters";
import { ServerAwareFavoritesContext } from "../../Containers/AppBar/ServerAwareFavorite";
import { useSpace } from "../../Contexts/SpaceContext";

const ServerAwareFilterList = React.memo(({ filterType, ...rest }) => {
  const { params, onParamsChange, Reset, schema, gridSchema } =
    useContext(ServerAwareContext);

  const { filter = [], groupBy, advancedFilters, $orderBy } = params;

  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const ordenableColums = useServerAwareColumns();
  const schemaViews = useContext(ServerAwareViewsContext);
  const location = useLocation();
  const resolvedOrdenableColums = [];
  if (FavoriteItem) {
    const { orderedFields } = FavoriteItem.data;
    if (ordenableColums) {
      if (orderedFields) {
        ordenableColums.forEach((e) => {
          if (orderedFields.indexOf(e.field) !== -1 && e.sortable !== false) {
            resolvedOrdenableColums.push(e.field);
          }
        });
      } else {
        ordenableColums.forEach((e) => {
          if (e.sortable !== false) {
            resolvedOrdenableColums.push(e.field);
          }
        });
      }
    }
  } else {
    const { search } = location;
    const queryObj = querystring.parse(search);
    const { viewPref } = queryObj;

    const lsIndex = schemaViews?.findIndex((e) => e.id === viewPref);
    const lsViews = schemaViews
      ? lsIndex && lsIndex === -1
        ? schemaViews[0]
        : schemaViews[lsIndex] || {}
      : {};

    const localStorageColumns = lsViews?.columnLayout || undefined;

    if (ordenableColums) {
      if (localStorageColumns) {
        ordenableColums.forEach((e) => {
          if (
            localStorageColumns.indexOf(e.field) !== -1 &&
            e.sortable !== false
          ) {
            resolvedOrdenableColums.push(e.field);
          }
        });
      } else {
        ordenableColums.forEach((e) => {
          if (e.sortable !== false) {
            resolvedOrdenableColums.push(e.field);
          }
        });
      }
    }
  }

  const handleFilterAddition = (newfilter) => {
    const newFilters = [...filter];
    newFilters.push(newfilter);
    onParamsChange({
      filter: newFilters
    });
  };

  const handleFilterRemoval = (newfilter, index) => {
    const newFilters = [...filter];
    newFilters.splice(index, 1);
    onParamsChange({
      filter: newFilters
    });
  };

  const handleFilterEdit = (newfilter, index) => {
    const newFilters = [...filter];
    newFilters[index] = newfilter;
    onParamsChange({
      filter: newFilters
    });
  };

  const handleAllFiltersRemoval = () => {
    onParamsChange({
      filter: []
    });
  };
  const unresolvedColumns = useServerAwareFilterColumns();
  const columns = useMemo(() => {
    if (unresolvedColumns) return unresolvedColumns;
    else return [];
  }, [unresolvedColumns]);

  const onUnreadAdd = () => {
    const filterUnread = columns.findIndex((e) => {
      return e.titleId === "NOT_READ";
    });
    const UnReadObject = columns[filterUnread];

    onParamsChange({
      filter: [
        {
          data: {
            applyOnChange: true,
            field: UnReadObject.field,
            titleId: UnReadObject.titleId
          },
          field: UnReadObject.field,
          operator: "eq",
          value: 0
        }
      ]
    });
  };

  const handleAdvancedFilterRemoval = () => {
    onParamsChange({
      advancedFilters: []
    });
  };

  // const handleColorFilterRemoval = () => {
  //   onParamsChange({
  //     colorFilters: []
  //   });
  // };

  const onRemoveAllOrderbys = () => {
    const itemToCompareWith =
      GroupedByOrderByDictionary[groupBy[0]] || groupBy[0];
    const groupBYOrderByIndex =
      groupBy.length > 0
        ? $orderBy.findIndex((e) => e.column === itemToCompareWith)
        : -1;

    onParamsChange({
      $orderBy:
        groupBYOrderByIndex !== -1 ? [$orderBy[groupBYOrderByIndex]] : []
    });
  };

  const onRemoveAllGroupbys = () => {
    onParamsChange({
      groupBy: []
    });
  };
  const updateSelectedColorFilter = useServerAwareColorFilterUpdater();
  const space = useSpace();
  const onRemoveEveryKindOfFilter = () => {
    onParamsChange({
      groupBy: [],
      $orderBy: [],
      advancedFilters: [],
      filter: []
    });
    removeAllSelectedFilterBySchema(gridSchema || schema, space.Id);
    updateSelectedColorFilter([]);
  };

  // const isOpen = useIsFilterListOpen(s
  //   FilterViewTypeEnumToSchemaName[filterType]
  // );

  // eslint-disable-next-line no-unused-vars
  const [isOpen, toggle] = useContext(FilterListOpenStateContext);

  const resolvedColumns = useTempFilters(columns);

  const filterUnread = useMemo(() => {
    return columns.findIndex((e) => {
      return e.titleId === "NOT_READ";
    });
  }, [columns]);

  if (!isOpen) return null;
  // console.log(filter);
  return (
    <FilterList
      data={resolvedColumns}
      // onRemoveColorFilters={handleColorFilterRemoval}
      onReset={Reset}
      onUnreadAdd={filterUnread !== -1 && onUnreadAdd}
      filterType={filterType}
      onRemoveEveryKindOfFilter={onRemoveEveryKindOfFilter}
      onRemoveAllOrderbys={onRemoveAllOrderbys}
      // onFilterChange={data => this.setState({ filter: [...data] })}
      onRemoveAllFilters={handleAllFiltersRemoval}
      onRemoveFilter={handleFilterRemoval}
      onRemoveAllGroups={onRemoveAllGroupbys}
      onRemoveAvancedFilters={handleAdvancedFilterRemoval}
      onAddFilter={handleFilterAddition}
      onGroupChange={(data) => {
        onParamsChange({ groupBy: [...data] });
      }}
      onChange={onParamsChange}
      onEditFilter={handleFilterEdit}
      value={{
        groupBy: groupBy,
        orderby: $orderBy,
        groups: groupBy,
        filters: filter,
        advancedFilters
      }}
      {...rest}
      ordenableColums={resolvedOrdenableColums}
    />
  );
});

export default ServerAwareFilterList;

export const ServerAwareFilterListToggler = ({ className }) => {
  const [isOpen, toggle] = useContext(FilterListOpenStateContext);

  return (
    <div
      onClick={toggle}
      className={classnames(
        "d-flex align-items-center cursor-pointer ar-filter-list-toggler",
        {
          closed: !isOpen,
          open: isOpen
        },
        className
      )}
    >
      <div
        style={{ minHeight: 32, minWidth: 32 }}
        className={classnames(
          "align-items-center ar-filter-list-toggler cursor-pointer rounded-lg d-flex justify-content-center ssi-button-primary-ghost"
        )}
      >
        <span>
          <FontAwesomeIcon icon={faFilter} />
        </span>
      </div>
    </div>
  );
};
