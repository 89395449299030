import React from "react";
import { ProjectStatusProfile } from "../../../../Components/Projects/ProjectProfile";
import {
  GeneralNotificationItem,
  ProjectTitle,
  buildNotificationEndpoint,
  NotificationClientProfile,
  buildLegacyNotificationEndpoint,
  ContactTitle,
  ClientTitle,
  DealTitle,
  ContractTitle,
  SubscriptionTitle
} from "./NotificationHelper";
import { FormattedMessage } from "react-intl";
import TaskOrigin from "../../../Origin/TaskOrigin";
import TicketOrigin from "../../../Origin/TicketOrigin";
import InterventionOrigin from "../../../Origin/InterventionOrigin";
import ProjectOrigin from "../../../Origin/ProjectOrigin";
import ContactOrigin from "../../../Origin/ContactOrigin";
import { ContactStatusProfile } from "../../../../Components/Contacts/ContactProfile";
import ClientOrigin from "../../../Origin/ClientOrigin";
import { ClientStatusProfile } from "../../../../Components/Clients/ClientProfile";
import DealOrigin from "../../../Origin/DealOrigin";
import DealStatusProfile from "../../../../Components/Deals/DealStatusProfile";
import { ContractStatusProfile } from "../../../../Components/Contract/ContractProfile";
import SubscriptionOrigin from "../../../Origin/SubscriptionOrigin";
import ContractOrigin from "../../../Origin/ContractOrigin";
import CallOrigin from "../../../Origin/CallOrigin";
import { moduleTypes } from "../../../../Helpers/ModulesHelper";
import { MessageItemProfileStatus } from "../../../Reception/ReceptionCompMsgList";
import { EmailConversationStatusProfile } from "../../../../Components/EmailConversations/EmailConversationProfile";

const NotificationStatusChangedProject = ({ project }) => {
  const resolvedProject = project;
  const { Client, IndirectClient, StatusName } = resolvedProject;
  const { Status } = resolvedProject;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.projects,
        Id: project.Id
      }}
      TitleComponent={ProjectTitle}
      OriginComponent={<ProjectOrigin project={project} />}
      redirectUrl={buildNotificationEndpoint(
        "projects",
        "Project/Id",
        project.Id
      )}
      body={
        <div className="text-black ">
          <div className="d-flex align-items-center">
            <div className="d-inline fs-14 mr-2">
              <FormattedMessage id={"THE_PROJECT_STATUS"} />{" "}
              <b>{resolvedProject.Name}</b>{" "}
              <FormattedMessage id={"WAS_CHANGED_TO"} />{" "}
              <ProjectStatusProfile
                pipelineName={StatusName}
                className="d-inline-flex ml-1"
                status={Status}
              />
            </div>
          </div>
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationStatusChangedEmailConversation = ({ emaiLConversation }) => {
  // const { EmailConversation } = emaiLConversation;
  // const { Name: FromName } = FromAccount;

  const { Subject, Id, Client, Status } = emaiLConversation;

  return (
    <GeneralNotificationItem
      OriginComponent={
        <div className="d-flex align-items-center of-hidden ">
          <MessageItemProfileStatus
            size="xs"
            style={{ width: 24, height: 24 }}
            Status={Status}
          />
          <div className="flex-1 text-truncate ml-2 disable-selection fs-12 text-black">
            {Subject}
          </div>
        </div>
      }
      redirectUrl={buildNotificationEndpoint(
        "inbox/1",
        null,
        Id,
        `conversationTypes=1&entityNoteType=0`
      )}
      body={
        <div className="text-black ">
          <div className="d-flex align-items-center">
            <div className="d-inline fs-14 mr-2">
              <FormattedMessage id={"THE_EMAIL_CONVERSATION_STATUS"} />{" "}
              <b>{Subject}</b> <FormattedMessage id={"WAS_CHANGED_TO"} />{" "}
              <EmailConversationStatusProfile
                className="d-inline-flex ml-1"
                status={Status}
              />
            </div>
          </div>
          <NotificationClientProfile client={Client} />
        </div>
      }
    />
  );
};

const NotificationStatusChangedDeal = ({ deal }) => {
  const resolvedDeal = deal;
  const { Client, IndirectClient, Status, StatusName } = resolvedDeal;

  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.deals,
        Id: deal.Id
      }}
      TitleComponent={DealTitle}
      OriginComponent={<DealOrigin deal={resolvedDeal} />}
      redirectUrl={buildNotificationEndpoint("deals", "Deal/Id", deal.Id)}
      body={
        <div className="text-black ">
          <div className="d-flex align-items-center">
            <div className="d-inline fs-14 mr-2">
              <FormattedMessage id={"THE_DEAL_STATUS"} /> <b>{deal.Name}</b>{" "}
              <FormattedMessage id={"WAS_CHANGED_TO"} />{" "}
              <DealStatusProfile
                pipelineName={StatusName}
                className="d-inline-flex ml-1"
                status={Status}
              />
            </div>
          </div>
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};
const NotificationStatusChangedContract = ({ contract }) => {
  const resovledContract = contract;
  const { Client, IndirectClient, Status, StatusName } = resovledContract;

  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.contracts,
        Id: contract.Id
      }}
      TitleComponent={ContractTitle}
      OriginComponent={<ContractOrigin contract={resovledContract} />}
      redirectUrl={buildNotificationEndpoint(
        "contracts",
        "Contract/Id",
        resovledContract.Id
      )}
      body={
        <div className="text-black ">
          <div className="d-flex align-items-center">
            <div className="d-inline fs-14 mr-2">
              <FormattedMessage id={"THE_CONTRACT_STATUS"} />{" "}
              <b>{resovledContract.NoTagDescription}</b>{" "}
              <FormattedMessage id={"WAS_CHANGED_TO"} />{" "}
              <ContractStatusProfile
                className="d-inline-flex ml-1"
                pipelineName={StatusName}
                status={Status}
              />
            </div>
          </div>
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};
const NotificationStatusChangedSubscription = ({ subscription }) => {
  const resovledSubscription = subscription;
  const { Client, IndirectClient, Status, StatusName } = resovledSubscription;

  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.subscriptions,
        Id: subscription.Id
      }}
      TitleComponent={SubscriptionTitle}
      OriginComponent={
        <SubscriptionOrigin Subscription={resovledSubscription} />
      }
      redirectUrl={buildNotificationEndpoint(
        "subscriptions",
        "Subscription/Id",
        resovledSubscription.Id
      )}
      body={
        <div className="text-black ">
          <div className="d-flex align-items-center">
            <div className="d-inline fs-14 mr-2">
              <FormattedMessage id={"THE_SUBSCRIPTION_STATUS"} />{" "}
              <b>{resovledSubscription.NoTagDescription}</b>{" "}
              <FormattedMessage id={"WAS_CHANGED_TO"} />{" "}
              <ContractStatusProfile
                className="d-inline-flex ml-1"
                pipelineName={StatusName}
                status={Status}
              />
            </div>
          </div>
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationStatusChangedContact = ({ contact }) => {
  const resolvedContact = contact;
  const { StatusName, Name } = resolvedContact;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.contacts,
        Id: contact.Id
      }}
      TitleComponent={ContactTitle}
      OriginComponent={<ContactOrigin contact={contact} />}
      redirectUrl={buildNotificationEndpoint(
        "contacts",
        "Contact/Id",
        contact.Id
      )}
      body={
        <div className="text-black ">
          <div className="d-flex align-items-center">
            <div className="d-inline fs-14 mr-2">
              <FormattedMessage id={"THE_CONTACT_STATUS"} /> <b>{Name}</b>{" "}
              <FormattedMessage id={"WAS_CHANGED_TO"} />{" "}
              <ContactStatusProfile
                className="d-inline-flex ml-1"
                status={StatusName}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

const NotificationStatusChangedClient = ({ client }) => {
  const resolvedClient = client;
  const { StatusName, Name } = resolvedClient;
  return (
    <GeneralNotificationItem
      TitleComponent={ClientTitle}
      formOptions={{
        formType: moduleTypes.clients,
        Id: client.Id
      }}
      OriginComponent={<ClientOrigin client={client} />}
      redirectUrl={buildNotificationEndpoint(
        "customers",
        "Client/Id",
        client.Id
      )}
      body={
        <div className="text-black ">
          <div className="d-flex align-items-center">
            <div className="d-inline fs-14 mr-2">
              <FormattedMessage id={"THE_CLIENT_STATUS"} /> <b>{Name}</b>{" "}
              <FormattedMessage id={"WAS_CHANGED_TO"} />{" "}
              <ClientStatusProfile
                className="d-inline-flex ml-1"
                status={StatusName}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

const NotificationStatusChangedTask = ({ task }) => {
  const resolvedTask = task;
  const { Client, IndirectClient } = resolvedTask;
  const { Status, StatusName } = resolvedTask;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.tasks,
        Id: task.Id
      }}
      redirectUrl={buildNotificationEndpoint("tasks", "Task/Id", task.Id)}
      OriginComponent={<TaskOrigin task={task} />}
      body={
        <div className="text-black ">
          <div className="d-flex align-items-center">
            <div className="d-inline fs-14 mr-2">
              <FormattedMessage id={"THE_TASK_STATUS"} />{" "}
              <b>{resolvedTask.Name}</b>{" "}
              <FormattedMessage id={"WAS_CHANGED_TO"} />{" "}
              <ProjectStatusProfile
                className="d-inline-flex ml-1"
                pipelineName={StatusName}
                status={Status}
              />
            </div>
          </div>
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};
const NotificationStatusChangedCall = ({ call }) => {
  const resolvedTask = call;
  const { Client, IndirectClient } = resolvedTask;
  const { Status, StatusName } = resolvedTask;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.calls,
        Id: call.Id
      }}
      redirectUrl={buildNotificationEndpoint("calls", "Call/Id", call.Id)}
      OriginComponent={<CallOrigin call={call} />}
      body={
        <div className="text-black ">
          <div className="d-flex align-items-center">
            <div className="d-inline fs-14 mr-2">
              <FormattedMessage id={"THE_CALL_STATUS"} />{" "}
              <b>{resolvedTask.Name}</b>{" "}
              <FormattedMessage id={"WAS_CHANGED_TO"} />{" "}
              <ProjectStatusProfile
                className="d-inline-flex ml-1"
                pipelineName={StatusName}
                status={Status}
              />
            </div>
          </div>
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationStatusChangedTicket = ({ ticket }) => {
  const resolvedTicket = ticket;
  const { Client, IndirectClient } = resolvedTicket;
  const { Status, StatusName } = resolvedTicket;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.tickets,
        Id: ticket.Id
      }}
      redirectUrl={buildNotificationEndpoint(
        "tickets",
        "Project/Id",
        ticket.Id
      )}
      OriginComponent={<TicketOrigin ticket={ticket} />}
      body={
        <div className="text-black">
          <div className="d-flex align-items-center">
            <div className="d-inline fs-14 mr-2">
              <FormattedMessage id={"THE_CASE_STATUS"} />{" "}
              <b>{resolvedTicket.Name}</b>{" "}
              <FormattedMessage id={"WAS_CHANGED_TO"} />{" "}
              <ProjectStatusProfile
                className="d-inline-flex ml-1"
                pipelineName={StatusName}
                status={Status}
              />
            </div>
          </div>
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationStatusChangedIntervention = ({ intervention }) => {
  const resolvedIntervention = intervention;
  const { Client, IndirectClient } = resolvedIntervention;
  const { Status, StatusName } = resolvedIntervention;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.interventions,
        Id: intervention.Id
      }}
      redirectUrl={buildNotificationEndpoint(
        "interventions",
        "TimeCharge/Id",
        intervention.Id
      )}
      OriginComponent={<InterventionOrigin intervention={intervention} />}
      body={
        <div className="text-black ">
          <div className="d-flex align-items-center">
            <div className="d-inline fs-14 mr-2">
              <FormattedMessage id={"THE_TIME_RECORD_STATUS"} />{" "}
              <b>{resolvedIntervention.Description}</b>{" "}
              <FormattedMessage id={"WAS_CHANGED_TO"} />{" "}
              <ProjectStatusProfile
                className="d-inline-flex ml-1"
                pipelineName={StatusName}
                status={Status}
              />
            </div>
          </div>
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationStatusChangedItem = ({ value }) => {
  const {
    Project,
    Task,
    Ticket,
    Intervention,
    TimeCharge,
    Contact,
    Client,
    Deal,
    Contract,
    Subscription,
    EmailConversation,
    Call
  } = value;

  if (Project) return <NotificationStatusChangedProject project={Project} />;
  else if (Contact)
    return <NotificationStatusChangedContact contact={Contact} />;
  else if (Client) return <NotificationStatusChangedClient client={Client} />;
  else if (Deal) return <NotificationStatusChangedDeal deal={Deal} />;
  else if (Call) return <NotificationStatusChangedCall call={Call} />;
  else if (Subscription)
    return (
      <NotificationStatusChangedSubscription subscription={Subscription} />
    );
  else if (Contract)
    return <NotificationStatusChangedContract contract={Contract} />;
  else if (Task) return <NotificationStatusChangedTask task={Task} />;
  else if (Ticket) return <NotificationStatusChangedTicket ticket={Ticket} />;
  else if (EmailConversation)
    return (
      <NotificationStatusChangedEmailConversation
        emaiLConversation={EmailConversation}
      />
    );
  else if (Intervention || TimeCharge)
    return (
      <NotificationStatusChangedIntervention
        intervention={Intervention || TimeCharge}
      />
    );
  else return null;
};

export default NotificationStatusChangedItem;
