import React from "react";

import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { Tr } from "../../Components/Table";
import { useProject } from "../../Hooks/EntityHooks";
import { spaceProjectsSchema } from "../../config/schema";
import RelativeTime from "../../Components/RelativeTime/RelativeTime";
import {
  MilSecToTime,
  MilSecToHour,
  MilSecToFormatedHourMinute
} from "../../Helpers/MiscHelper";
import SpentHoursPreview from "../../Components/SpentHoursPreview/SpentHoursPreview";
import {
  DashboardTable,
  DashboardTitle,
  DashboardEmptyResponse
} from "./Dashboard";
import { faSquareParking } from "@fortawesome/pro-solid-svg-icons";
import { ProjectFormButton } from "../Form/FormButtons";
import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
import { ProjectEntityGridButtons } from "../Projects/ProjectsHelper";
import { ProjectsLink } from "../../Helpers/HooksHelper";
import { FormattedMessage } from "react-intl";
import { useDashboardQuery } from "./DashboardQueryHelper";

const columns = [
  {
    minWidth: 90,
    width: 90,
    Cell: (value) => <ProjectEntityGridButtons entity={value} />
  },
  {
    title: "Nº",
    Cell: ({ Id }) => <ProjectsLink id={Id}>{Id}</ProjectsLink>
  },
  {
    title: <FormattedMessage id={"NAME"} />,
    Cell: ({ Name }) => <span className="text-black">{Name}</span>
  },
  // {
  //   title: "Atribuídoa",
  //   Cell: ({ Departments, Collaborators }) => (
  //     <ConnectedAccountsTeamsDepartmentsProfileStack
  //       departments={Departments}
  //       accounts={Collaborators.Accounts}
  //       teams={Collaborators.Teams}
  //     />
  //   )
  // },
  {
    maxWidth: 200,
    title: <FormattedMessage id={"ACCOUNT"} />,
    Cell: ({ Client, IndirectClient }) => (
      <ConnectedClientOrigin client={Client || IndirectClient} />
    )
  },
  {
    title: <FormattedMessage id={"SPENT_HOURS"} />,
    Cell: ({ EstimatedCost, SpentHours }) => (
      <SpentHoursPreview
        SpentHours={SpentHours}
        EstimatedHours={EstimatedCost}
      />
    )
  },
  {
    title: <FormattedMessage id={"ESTIMATED_HOUR"} />,
    Cell: ({ EstimatedCost }) => MilSecToFormatedHourMinute(EstimatedCost)
  },
  {
    title: <FormattedMessage id={"DEADLINE"} />,
    Cell: ({ EndDate }) => <RelativeTime date={EndDate} />
  }
];

const ProjectTr = ({ value, ...rest }) => {
  const project = useProject(value);

  return <Tr value={project} {...rest} />;
};

const DashboardProjectOverview = (props) => {
  const { data, loading, error } = useDashboardQuery(
    "dashboard/projects",
    [spaceProjectsSchema],
    { cache: false }
  );

  const buildBody = () => {
    if (loading || error)
      return <LoaderSpinner size="sm text-secondary" center />;

    if (data?.length === 0)
      return (
        <DashboardEmptyResponse className="h-100" type="projects">
          <FormattedMessage id={"EMPTY_PROJECTS"} />
        </DashboardEmptyResponse>
      );

    return (
      <DashboardTable TrComponent={ProjectTr} columns={columns} data={data} />
    );
  };

  return (
    <React.Fragment>
      <DashboardTitle
        text={<FormattedMessage id={"OPEN_PROJECTS"} />}
        icon={faSquareParking}
        actionButton={<ProjectFormButton vType="primary-ghost" />}
      />
      {buildBody()}
    </React.Fragment>
  );
};

export default DashboardProjectOverview;
