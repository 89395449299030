import { faSquareParking } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

import TriggerCreation from "./TriggerCreation";
import { spaceProjectsSchema } from "../../../../../config/schema";
import { ConnectedProjectOrigin } from "../../../../Origin/ProjectOrigin";
import { useIntl } from "react-intl";

const PreviewTarget = ({ val }) => <ConnectedProjectOrigin project={val} />;

const TriggerProjectCreation = React.memo(({ ...props }) => {
	const intl = useIntl()
  return (
    <TriggerCreation
      {...props}
      TargetPreview={PreviewTarget}
      schema={spaceProjectsSchema}
      icon={faSquareParking}
			text={intl.formatMessage({ id: "PROJECT" })}
    />
  );
});

export default TriggerProjectCreation;
