import React, { useRef, useContext } from "react";

export const SidebarVisualTypeEnum = {
  side: 1,
  modal: 2,
  fullscreen: 3,
};

export const useCurrentSidebarItem = (tabs) => {
  const itemRef = useRef();
  let currentItem;
  let item = itemRef.current;

  if (!tabs || (tabs.length === 0 && !item)) return;

  if (tabs && tabs.length > 0) {
    currentItem = tabs[tabs.length - 1];
  }

  if (currentItem) {
    itemRef.current = currentItem;
    item = itemRef.current;
  }

  return item;
};

export const SidebarViewContext = React.createContext();
export const SidebarViewSetterContext = React.createContext();
export const SidebarViewLsKey = "ar-sidebar-view-type";

export const useSidebarView = () => useContext(SidebarViewContext);
