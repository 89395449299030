export const CustomizedLayout = [
  { i: "missingTimeCharges", x: 0, y: 0, w: 12, h: 1, minW: 6 },
  { i: "interventions", x: 0, y: 1, w: 6, h: 2, minH: 2 },
  { i: "tasks", x: 6, y: 1, w: 6, h: 2, minH: 2 },
  { i: "projects", x: 0, y: 2, w: 6, h: 2, minH: 2 },
  { i: "tickets", x: 6, y: 2, w: 6, h: 2, minH: 2 },
  { i: "calls", x: 0, y: 3, w: 6, h: 2, minH: 2 },
  { i: "departmentTimeCharges", x: 6, y: 3, w: 6, h: 2, minH: 2 },
  { i: "aniversary", x: 6, y: 4, w: 6, h: 2, minH: 2 },
  { w: 12, h: 2, x: 0, y: 3, i: "excededHours" },
  { w: 6, h: 2, x: 0, y: 5, i: "almostExcededHours" },
  { w: 6, h: 2, x: 6, y: 5, i: "almostExpiredHours" },
  { w: 2, h: 1, x: 0, y: 0, i: "openContracts" },
  { w: 2, h: 1, x: 2, y: 0, i: "closedContracts" },
  { w: 6, h: 2, x: 6, y: 9, i: "expiredContracts" },
  { w: 6, h: 2, x: 0, y: 9, i: "inactiveContracts" },
  { w: 6, h: 2, x: 0, y: 1, i: "evolutionContracts" },
  { w: 6, h: 2, x: 6, y: 1, i: "openEvolutionContracts" },
  { w: 6, h: 2, x: 6, y: 7, i: "DeadlineRisk" },
  { w: 6, h: 2, x: 0, y: 7, i: "BudgetRisk" },
  { w: 8, h: 1, x: 4, y: 0, i: "ContractStates" },
  { w: 2, h: 1, x: 0, y: 0, i: "openProjects" },
  { w: 2, h: 1, x: 2, y: 0, i: "closedProjects" },
  { w: 2, h: 1, x: 4, y: 0, i: "canceledProjects" },
  { w: 6, h: 2, x: 0, y: 1, i: "openEvolutionProjects" },
  { w: 12, h: 2, x: 0, y: 5, i: "ExcededTimeProject" },
  { w: 6, h: 2, x: 6, y: 7, i: "AlmostExcededTimeProject" },
  { w: 6, h: 2, x: 0, y: 7, i: "AlmostOutOfTimeProjects" },
  { w: 6, h: 2, x: 6, y: 11, i: "InactiveProjects" },
  { w: 12, h: 2, x: 0, y: 3, i: "WorkloadProjects" },
  { w: 6, h: 2, x: 6, y: 1, i: "ProjectsActivity" },
  { w: 6, h: 2, x: 0, y: 11, i: "ClosedProjects" },
  { w: 6, h: 2, x: 6, y: 9, i: "BudgetRisk" },
  { w: 6, h: 2, x: 0, y: 9, i: "DeadlineRisk" },
  { w: 6, h: 1, x: 6, y: 0, i: "ProjectStates" },
  { i: "openTickets", x: 0, y: 0, w: 2, h: 1 },
  { i: "closedTickets", x: 2, y: 0, w: 2, h: 1 },
  { i: "averageOpenTime", x: 4, y: 0, w: 3, h: 1 },
  { i: "ticketsStatus", x: 7, y: 0, w: 5, h: 1, minW: 5 },
  { i: "evolutionClosedTickets", x: 0, y: 1, w: 6, h: 2, minW: 4, minH: 2 },
  { i: "ticketsHistory", x: 6, y: 1, w: 6, h: 4 },
  { i: "performanceTickets", x: 0, y: 2, w: 6, h: 2, minW: 4, minH: 2 },
  { i: "evolutionTickets", x: 8, y: 2, w: 6, h: 2, minW: 4, minH: 2 },
  { i: "globalProductivityTickets", x: 0, y: 2, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "resolutionTimeOpenTickets", x: 6, y: 2, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "departmentWorkloadTickets", x: 0, y: 3, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "averageTimeClosingTickets", x: 6, y: 3, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "teamWorkloadTickets", x: 0, y: 4, w: 6, h: 2, minW: 6, minH: 2 },
  {
    i: "teamAverageTimeClosingTickets",
    x: 6,
    y: 4,
    w: 6,
    h: 2,
    minW: 6,
    minH: 2
  },
  { i: "percentageStatusTickets", x: 0, y: 5, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "openTasks", x: 0, y: 0, w: 2, h: 1 },
  { i: "closedTasks", x: 2, y: 0, w: 2, h: 1 },
  { i: "averageOpenTimeTasks", x: 4, y: 0, w: 3, h: 1 },
  { i: "StatusTasks", x: 7, y: 0, w: 5, h: 1, minW: 5 },
  { i: "evolutionClosedTasks", x: 0, y: 1, w: 6, h: 2, minW: 4, minH: 2 },
  { i: "tasksHistory", x: 6, y: 1, w: 6, h: 4 },
  { i: "performanceTasks", x: 0, y: 2, w: 6, h: 2, minW: 4, minH: 2 },
  { i: "evolutionTasks", x: 8, y: 2, w: 6, h: 2, minW: 4, minH: 2 },
  { i: "globalProductivityTasks", x: 0, y: 2, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "resolutionTimeOpenTasks", x: 6, y: 2, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "departmentWorkloadTasks", x: 0, y: 3, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "averageTimeClosingTasks", x: 6, y: 3, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "teamWorkloadTasks", x: 0, y: 4, w: 6, h: 2, minW: 6, minH: 2 },
  {
    i: "teamAverageTimeClosingTasks",
    x: 6,
    y: 4,
    w: 6,
    h: 2,
    minW: 6,
    minH: 2
  },
  { i: "percentageStatusTasks", x: 0, y: 5, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "PipelineWorkloadTicket", x: 0, y: 6, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "PipelineWorkloadTask", x: 0, y: 6, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "PipelineWorkloadDeal", x: 0, y: 6, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "PipelineWorkloadProject", x: 0, y: 6, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "PipelineWorkloadContract", x: 0, y: 6, w: 6, h: 2, minW: 6, minH: 2 },
  {
    w: 4,
    h: 4,
    x: 8,
    y: 1,
    i: "dealHistory"
  },
  {
    w: 2,
    h: 1,
    x: 0,
    y: 0,
    i: "openDeals"
  },
  {
    w: 2,
    h: 1,
    x: 2,
    y: 0,
    i: "lostDeals"
  },
  {
    w: 2,
    h: 1,
    x: 6,
    y: 0,
    i: "wonDeals"
  },
  {
    w: 2,
    h: 1,
    x: 8,
    y: 0,
    i: "openDealsSum"
  },
  {
    w: 2,
    h: 1,
    x: 4,
    y: 0,
    i: "lostDealsSum"
  },
  {
    w: 2,
    h: 1,
    x: 10,
    y: 0,
    i: "wonDealsSum"
  },
  {
    w: 8,
    h: 2,
    x: 0,
    y: 1,
    i: "dealsAverageEvolution"
  },
  {
    w: 4,
    h: 2,
    x: 8,
    y: 5,
    i: "dealsPerformance"
  },
  {
    w: 4,
    h: 2,
    x: 0,
    y: 3,
    i: "dealsEvolution"
  },
  {
    w: 4,
    h: 2,
    x: 4,
    y: 5,
    i: "dealsTeamActivity"
  },
  {
    w: 4,
    h: 2,
    x: 4,
    y: 3,
    i: "dealsGlobalStates"
  },
  {
    w: 6,
    h: 2,
    x: 0,
    y: 7,
    i: "dealsTopClients"
  },
  {
    w: 6,
    h: 2,
    x: 0,
    y: 9,
    i: "dealsOpenDealsOverview"
  },
  {
    w: 6,
    h: 2,
    x: 6,
    y: 7,
    i: "dealsSalesOverview"
  },
  {
    w: 6,
    h: 2,
    x: 6,
    y: 9,
    i: "dealsLostOverview"
  },
  {
    w: 4,
    h: 2,
    x: 0,
    y: 5,
    i: "dealsGlobalPerformance"
  },
  {
    w: 12,
    h: 2,
    x: 0,
    y: 10,
    i: "dealsLostReasons"
  }
];

export const ServicesLayout = [
  { i: "missingTimeCharges", x: 0, y: 0, w: 2, h: 1, minW: 2 },
  { i: "interventions", x: 0, y: 1, w: 1, h: 2, minH: 2 },
  { i: "tasks", x: 1, y: 1, w: 1, h: 2, minH: 2 },
  { i: "projects", x: 0, y: 2, w: 1, h: 2, minH: 2 },
  { i: "tickets", x: 1, y: 2, w: 1, h: 2, minH: 2 },
  { i: "calls", x: 0, y: 3, w: 1, h: 2, minH: 2 },
  { i: "departmentTimeCharges", x: 1, y: 3, w: 1, h: 2, minH: 2 },
  { i: "aniversary", x: 1, y: 4, w: 1, h: 2, minH: 2 }
];

export const ComercialLayout = [
  { i: "missingTimeCharges", x: 0, y: 0, w: 2, h: 1 },
  { i: "interventions", x: 0, y: 1, w: 1, h: 2, minH: 2 },
  { i: "tasks", x: 1, y: 1, w: 1, h: 2, minH: 2 },
  { i: "projects", x: 0, y: 2, w: 1, h: 2, minH: 2 },
  { i: "tickets", x: 1, y: 2, w: 1, h: 2, minH: 2 },
  { i: "calls", x: 0, y: 3, w: 1, h: 2, minH: 2 },
  { i: "departmentTimeCharges", x: 1, y: 3, w: 1, h: 2, minH: 2 }
];

export const ServicesContractLayout = [
  { w: 12, h: 2, x: 0, y: 3, i: "excededHours" },
  { w: 6, h: 2, x: 0, y: 5, i: "almostExcededHours" },
  { w: 6, h: 2, x: 6, y: 5, i: "almostExpiredHours" },
  { w: 2, h: 1, x: 0, y: 0, i: "openContracts" },
  { w: 2, h: 1, x: 2, y: 0, i: "closedContracts" },
  { w: 6, h: 2, x: 6, y: 9, i: "expiredContracts" },
  { w: 6, h: 2, x: 0, y: 9, i: "inactiveContracts" },
  { w: 6, h: 2, x: 0, y: 1, i: "evolutionContracts" },
  { w: 6, h: 2, x: 6, y: 1, i: "openEvolutionContracts" },
  { w: 6, h: 2, x: 6, y: 7, i: "DeadlineRisk" },
  { w: 6, h: 2, x: 0, y: 7, i: "BudgetRisk" },
  { w: 8, h: 1, x: 4, y: 0, i: "ContractStates" },
  { i: "PipelineWorkloadContract", x: 0, y: 6, w: 6, h: 2, minW: 6, minH: 2 }
];

export const ServicesProjectLayout = [
  { w: 2, h: 1, x: 0, y: 0, i: "openProjects" },
  { w: 2, h: 1, x: 2, y: 0, i: "closedProjects" },
  { w: 2, h: 1, x: 4, y: 0, i: "canceledProjects" },
  { w: 6, h: 2, x: 0, y: 1, i: "openEvolutionProjects" },
  { w: 12, h: 2, x: 0, y: 5, i: "ExcededTimeProject" },
  { w: 6, h: 2, x: 6, y: 7, i: "AlmostExcededTimeProject" },
  { w: 6, h: 2, x: 0, y: 7, i: "AlmostOutOfTimeProjects" },
  { w: 6, h: 2, x: 6, y: 11, i: "InactiveProjects" },
  { w: 12, h: 2, x: 0, y: 3, i: "WorkloadProjects" },
  { w: 6, h: 2, x: 6, y: 1, i: "ProjectsActivity" },
  { w: 6, h: 2, x: 0, y: 11, i: "ClosedProjects" },
  { w: 6, h: 2, x: 6, y: 9, i: "BudgetRisk" },
  { w: 6, h: 2, x: 0, y: 9, i: "DeadlineRisk" },
  { w: 6, h: 1, x: 6, y: 0, i: "ProjectStates" },
  { i: "PipelineWorkloadProject", x: 0, y: 6, w: 6, h: 2, minW: 6, minH: 2 }
];

export const ServicesTicketLayout = [
  { i: "openTickets", x: 0, y: 0, w: 2, h: 1 },
  { i: "closedTickets", x: 2, y: 0, w: 2, h: 1 },
  { i: "canceledTickets", x: 0, y: 1, w: 2, h: 1 },
  { i: "averageOpenTime", x: 4, y: 0, w: 3, h: 1 },
  { i: "ticketsStatus", x: 7, y: 0, w: 5, h: 1, minW: 5 },
  { i: "evolutionClosedTickets", x: 0, y: 1, w: 6, h: 2, minW: 4, minH: 2 },
  { i: "ticketsHistory", x: 6, y: 1, w: 6, h: 4 },
  { i: "performanceTickets", x: 0, y: 2, w: 6, h: 2, minW: 4, minH: 2 },
  { i: "evolutionTickets", x: 8, y: 2, w: 6, h: 2, minW: 4, minH: 2 },
  { i: "globalProductivityTickets", x: 0, y: 2, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "resolutionTimeOpenTickets", x: 6, y: 2, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "departmentWorkloadTickets", x: 0, y: 3, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "averageTimeClosingTickets", x: 6, y: 3, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "teamWorkloadTickets", x: 0, y: 4, w: 6, h: 2, minW: 6, minH: 2 },
  {
    i: "teamAverageTimeClosingTickets",
    x: 6,
    y: 4,
    w: 6,
    h: 2,
    minW: 6,
    minH: 2
  },
  { i: "percentageStatusTickets", x: 0, y: 5, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "tickets", x: 0, y: 5, w: 6, h: 2 },
  { i: "PipelineWorkloadTicket", x: 0, y: 6, w: 6, h: 2, minW: 6, minH: 2 }
];

export const ServicesTaskLayout = [
  { i: "openTasks", x: 0, y: 0, w: 2, h: 1 },
  { i: "closedTasks", x: 2, y: 0, w: 2, h: 1 },
  { i: "averageOpenTimeTasks", x: 4, y: 0, w: 3, h: 1 },
  { i: "StatusTasks", x: 7, y: 0, w: 5, h: 1, minW: 5 },
  { i: "evolutionClosedTasks", x: 0, y: 1, w: 6, h: 2, minW: 4, minH: 2 },
  { i: "tasksHistory", x: 6, y: 1, w: 6, h: 4 },
  { i: "performanceTasks", x: 0, y: 2, w: 6, h: 2, minW: 4, minH: 2 },
  { i: "evolutionTasks", x: 8, y: 2, w: 6, h: 2, minW: 4, minH: 2 },
  { i: "globalProductivityTasks", x: 0, y: 2, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "resolutionTimeOpenTasks", x: 6, y: 2, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "departmentWorkloadTasks", x: 0, y: 3, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "averageTimeClosingTasks", x: 6, y: 3, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "teamWorkloadTasks", x: 0, y: 4, w: 6, h: 2, minW: 6, minH: 2 },
  {
    i: "teamAverageTimeClosingTasks",
    x: 6,
    y: 4,
    w: 6,
    h: 2,
    minW: 6,
    minH: 2
  },
  { i: "percentageStatusTasks", x: 0, y: 5, w: 6, h: 2, minW: 6, minH: 2 },
  { i: "tasks", x: 0, y: 5, w: 6, h: 2 },
  { i: "PipelineWorkloadTask", x: 0, y: 6, w: 6, h: 2, minW: 6, minH: 2 }
];

export const ServicesDealLayout = [
  {
    w: 4,
    h: 4,
    x: 8,
    y: 1,
    i: "dealHistory"
  },
  {
    w: 2,
    h: 1,
    x: 0,
    y: 0,
    i: "openDeals",
    Settings: {}
  },
  {
    w: 2,
    h: 1,
    x: 2,
    y: 0,
    i: "lostDeals"
  },
  {
    w: 2,
    h: 1,
    x: 6,
    y: 0,
    i: "wonDeals"
  },
  {
    w: 2,
    h: 1,
    x: 8,
    y: 0,
    i: "openDealsSum"
  },
  {
    w: 2,
    h: 1,
    x: 4,
    y: 0,
    i: "lostDealsSum"
  },
  {
    w: 2,
    h: 1,
    x: 10,
    y: 0,
    i: "wonDealsSum"
  },
  {
    w: 8,
    h: 2,
    x: 0,
    y: 1,
    i: "dealsAverageEvolution"
  },

  {
    w: 4,
    h: 2,
    x: 8,
    y: 5,
    i: "dealsPerformance"
  },
  {
    w: 4,
    h: 2,
    x: 0,
    y: 3,
    i: "dealsEvolution"
  },
  {
    w: 4,
    h: 2,
    x: 4,
    y: 5,
    i: "dealsTeamActivity"
  },
  {
    w: 4,
    h: 2,
    x: 4,
    y: 3,
    i: "dealsGlobalStates"
  },
  {
    w: 6,
    h: 2,
    x: 0,
    y: 7,
    i: "dealsTopClients"
  },
  {
    w: 6,
    h: 2,
    x: 0,
    y: 9,
    i: "dealsOpenDealsOverview"
  },
  {
    w: 6,
    h: 2,
    x: 6,
    y: 7,
    i: "dealsSalesOverview"
  },
  {
    w: 6,
    h: 2,
    x: 6,
    y: 9,
    i: "dealsLostOverview"
  },
  {
    w: 4,
    h: 2,
    x: 0,
    y: 5,
    i: "dealsGlobalPerformance"
  },
  {
    w: 12,
    h: 2,
    x: 0,
    y: 10,
    i: "dealsLostReasons"
  },
  { i: "PipelineWorkloadDeal", x: 0, y: 6, w: 6, h: 2, minW: 6, minH: 2 }
];
