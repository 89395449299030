import React from "react";

import { Switch, Route, withRouter } from "react-router-dom";
import {
  useEnterViewSignalR,
  PageTypeProvider
} from "../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimePageEnum } from "../RealTime/RealTimePages";
// import {
//   RealTimePageEnum,
//   RealTimePageProvider
// } from "../RealTime/RealTimePages";
import ContactDetails from "./ContactDetails/ContactDetails";

import ContactOverview from "./ContactOverview/ContactOverview";

const Contacts = withRouter(({ match }) => {
  // useEnterViewSignalR(RealTimePageEnum.Contact)

  return (
    <PageTypeProvider pageType={RealTimePageEnum.Contact}>
      <Switch>
        {/* <Route path={`${match.url}/:contactId/detailsTest`}>
        <div
          className="of-y-auto p-3 bg-white"
          style={{ height: 450, width: 900 }}
        >
          <TestContactReadOverview />
        </div>
      </Route> */}
        <Route path={`${match.url}/:contactId`}>
          <ContactDetails />
        </Route>
        <Route>
          {/* <RealTimePageProvider pageType={RealTimePageEnum.Contact}> */}
          <ContactOverview />
          {/* </RealTimePageProvider> */}
        </Route>
      </Switch>
    </PageTypeProvider>
  );
});

export default Contacts;
