import React, { Component, useContext } from "react";
import DropdownPopover from "./DropdownPopover";
class BasicDropdownBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isUpwards: false
    };

    this.baseRef = React.createRef();
    this.buttonRef = props.domRef ? props.domRef : React.createRef();
    this.popupRef = React.createRef();
  }

  // handleEntered = element => {
  //   if (
  //     !this.popupRef.current ||
  //     !this.buttonRef.current ||
  //     !this.baseRef.current
  //   )
  //     return;

  //     const {onTop} = this.props;

  //   let clickedTarget = this.buttonRef.current;
  //   let popupTarget = element;

  //   let rect = this.buttonRef.current.getBoundingClientRect();
  //   let viewHeight = document.documentElement.clientHeight;
  //   let scrollTop = document.documentElement.scrollTop;

  //   let currentTargetScroll = clickedTarget.offsetHeight;
  //   let popupScroll = popupTarget.offsetHeight;
  //   let top = (onTop ? 0 : rect.top) + window.scrollY + currentTargetScroll;

  //   //needs to go upwards
  //   if (viewHeight + scrollTop < top + popupScroll) {
  //     this.isUpwards = true;
  //     this.baseRef.current.classList.add("up");
  //   } else {
  //     this.isUpwards = false;
  //     this.baseRef.current.classList.remove("up");
  //   }
  // };

  render() {
    const {
      className,
      popup: Popup,
      children,
      isOpen,
      onClick,
      domRef,
      loadingComponent,
      isLoading,
      onTop,
      fixedWidth,
			extraClassnames,
      ...rest
    } = this.props;
    // const { isOpen } = this.state;


    const baseClass = extraClassnames
      ? `dropdown-container ssi-control ${extraClassnames}
		${isOpen ? "selected" : ""}    
		${this.isUpwards ? "up" : ""}
		${className ? ` ${className}` : ""}
		`
      : `dropdown-container p-0 ssi-control 
		${isOpen ? "selected" : ""}    
		${this.isUpwards ? "up" : ""}
		${className ? ` ${className}` : ""}
		`;

    // let baseClass = `dropdown-container p-0 ssi-control
    //     ${isOpen ? "selected" : ""}
    //     ${this.isUpwards ? "up" : ""}
    //     ${className ? ` ${className}` : ""}
    //     `;

    // const children1 = React.cloneElement(children[0], {
    //   ref: this.buttonRef
    // } )

    return (
      <div className={baseClass} ref={this.baseRef} {...rest}>
        {/* <DropdownButton domRef={this.buttonRef} onClick={onClick}>
        </DropdownButton> */}
        {/* {componentButton && componentButton(this.baseRef)} */}

        {React.Children.map(children, (child) => {
          return child
            ? React.cloneElement(child, {
                ref: this.buttonRef
              })
            : null;
        })}

        {/* {children} */}
        {/* {[children1]} */}
        <DropdownPopover
          disablePortal
          onTop={onTop}
          fixedWidth={fixedWidth}
          // anchorEl={this.buttonRef.current}
          anchorEl={this.baseRef.current}
          isOpen={isOpen}
          onClose={this.props.onClose}
          onEnter={this.handleEntered}
          domRef={this.popupRef}
          animationClass={"dropdown-popup"}
          isLoading={isLoading}
          loadingComponent={loadingComponent}
        >
          {Popup}
        </DropdownPopover>
      </div>
    );
  }
}

// const BasicDropdownBase = (props) => {
//   const {
//     className,
//     popup: Popup,
//     children,
//     isOpen,
//     onClick,
//     domRef,
//     loadingComponent,
//     isLoading,
//     onTop,
//     ...rest
//   } = this.props;
// }

// const PopupDropdownBase = ({
//   className,
//   isOpen,
//   children,
//   domRef,
//   popup: popupChildren,
//   large
// }) => {
//   const popupRef = useRef();

//   let baseClass = classnames(
//     "p-0 ssi-control ssi-input",
//     {
//       selected: isOpen,
//       "large-dropdown": large
//     },
//     className
//   );

//   const placement = window.innerHeight <= 680 ? "auto-start" : "bottom-start";

//   return (
//     <React.Fragment>
//       <div ref={domRef} className={baseClass}>
//         {children}
//       </div>
//       <Popup
//         placement={placement}
//         className={"ar-alt-dropdown-popup"}
//         isOpen={isOpen}
//         domRef={popupRef}
//         anchorEl={domRef.current}
//       >
//         {popupChildren}
//       </Popup>
//     </React.Fragment>
//   );
// };


export const DropdownBaseClassnameContext = React.createContext()

const DropdownBase = ({ isPopup, fixedWidth, ...rest }) => {
	const extraClassnames = useContext(DropdownBaseClassnameContext);
  return (
    <BasicDropdownBase
      fixedWidth={fixedWidth ? fixedWidth : isPopup ? 400 : undefined}
			extraClassnames={extraClassnames}
      {...rest}
    />
  );
  //   if (!isPopup) return <BasicDropdownBase {...rest} />;

  //   return <PopupDropdownBase {...rest} />;
};

export default DropdownBase;
