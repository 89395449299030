// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './index.module.css'
import SearchForm from './components/SearchForm/SearchForm'
import ImageItem from './components/ImageItem/ImageItem'
import PoweredByGiphy from './components/PoweredByGiphy/PoweredByGiphy'
import MasonryLayout from './components/MasonryLayout/MasonryLayout'
import Alert from './components/Alert/Alert'
import Spinner from './components/Spinner/Spinner'
import useSearchForm from './hooks/useSearchForm'
import useDebounce from './hooks/useDebounce'
import useMedia from './hooks/useMedia'
import useApi from './hooks/useApi'
import assetsSpinner from './assets/spinner.svg'
import assetsPoweredByGiphy from './assets/poweredByGiphy.png'
import {
	getComponentWrapperWidth,
	getDefaultMasonryConfig,
	getMasonryConfigExceptLast,
	getMediaBreakpoints,
} from './utils/masonry'
import { useEndlessScroll } from '../EndlessScrollPage/EndlessScrollPage'
import { useIntl } from 'react-intl'

const ReactGiphySearchBox = ({
	apiKey,
	gifListHeight,
	gifPerPage,
	imageBackgroundColor,
	library,
	listItemClassName,
	listWrapperClassName,
	loadingImage,
	masonryConfig,
	messageError,
	messageLoading,
	messageNoMatches,
	onSearch,
	onSelect,
	poweredByGiphy,
	poweredByGiphyImage,
	rating,
	searchFormClassName,
	searchPlaceholder,
	wrapperClassName,
}) => {
	const intl = useIntl()
	const { query, handleInputChange, handleSubmit } = useSearchForm()
	const debouncedQuery = useDebounce(query, 500)

	const apiEndpoint = query ? 'search' : 'trending'
	const apiUrl = useCallback(
		offset =>
			`https://api.giphy.com/v1/${library}/${apiEndpoint}?api_key=${apiKey}&limit=${gifPerPage}&rating=${rating}&offset=${offset}&q=${query}`,
		[apiEndpoint, apiKey, gifPerPage, library, query, rating],
	)

	const [{ data, loading, error, lastPage }, fetchImages] = useApi()

	const masonryConfigMatchMedia = useMedia(
		getMediaBreakpoints(masonryConfig),
		getMasonryConfigExceptLast(masonryConfig),
		getDefaultMasonryConfig(masonryConfig),
	)

	// Fetch Giphy Api on component mount and on search query change
	const [firstRun, setFirstRun] = useState(true)
	const isFirstRun = useRef(true)
	useEffect(() => {
		fetchImages(apiUrl(0))
		onSearch(query)

		if (isFirstRun.current) {
			isFirstRun.current = false
			setFirstRun(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedQuery])

	const pageCount = useRef(0)

	const fetchMore = useCallback(
		() => {

			pageCount.current++;
			fetchImages(apiUrl(pageCount.current * gifPerPage), true);
		},
		[apiUrl, fetchImages, gifPerPage],
	)
	const pageRef = useRef()
	useEndlessScroll({
		listen: !loading && !lastPage && !error,
		onFetchMore: fetchMore,
		offsettMultiplier: 1.5,
		pageRef,
	});

	return (
		<div
			className={`${styles.componentWrapper}${wrapperClassName ? ` ${wrapperClassName}` : ''
				}`}
			style={{ width: getComponentWrapperWidth(masonryConfigMatchMedia) }}
		>
			<SearchForm
				value={query}
				setValue={handleInputChange}
				onSubmit={handleSubmit}
				loadingData={loading}
				searchFormClassName={searchFormClassName}
				placeholder={intl.formatMessage({ id: "SEARCH_GIFS" })}
			/>

			<div
				ref={pageRef}
				className={`${styles.listWrapper}${listWrapperClassName ? ` ${listWrapperClassName}` : ''
					}`}
				style={{ height: gifListHeight }}
			>
				<Alert
					show={data.length === 0 && !loading && !error && !firstRun}
					message={intl.formatMessage({ id: "NO_MATCHES_FOUND" })}
				/>

				<Alert show={error} message={messageError} />

				<Spinner show={loading} message={messageLoading} image={loadingImage} />

				<div
				>
					{data.length > 0 && (
						<MasonryLayout loading={loading} lastPage={lastPage} error={error} fetchMore={fetchMore} sizes={masonryConfig}>
							{data.map(item => (
								<ImageItem
									item={item}
									size={masonryConfigMatchMedia.imageWidth}
									key={item.id}
									listItemClassName={listItemClassName}
									onSelect={onSelect}
									backgroundColor={imageBackgroundColor}
								/>
							))}
						</MasonryLayout>
					)}
					{/* {loading && !firstRun && (
						<div key="loading">
							<Spinner
								show={loading}
								message={messageLoading}
								image={loadingImage}
							/>
						</div>
					)} */}
				</div>
			</div>
			{poweredByGiphy && <PoweredByGiphy image={poweredByGiphyImage} />}
		</div>
	)
}

ReactGiphySearchBox.defaultProps = {
	gifListHeight: '350px',
	gifPerPage: 20,
	imageBackgroundColor: '#eee',
	library: 'gifs',
	listItemClassName: '',
	listWrapperClassName: '',
	loadingImage: assetsSpinner,
	masonryConfig: [{ columns: 5, imageWidth: 138, gutter: 5 }],
	messageError: 'Oops! Something went wrong. Please, try again.',
	messageLoading: 'Loading...',
	onSearch: () => { },
	poweredByGiphy: true,
	poweredByGiphyImage: assetsPoweredByGiphy,
	rating: 'g',
	searchFormClassName: '',
	// apiKey: 'LlnWova1HjRAhsbK6dqyIHBZNzOUZ8n2',
	wrapperClassName: '',
}

export default ReactGiphySearchBox
