import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
  useContext
} from "react";
import { useMultipleEntityValueSelector } from "../../Hooks/AdvancedEntityHooks";
import { useEntityValue } from "../../Hooks/EntityHooks";
import {
  GroupedByDictionary,
  groupByColumnDict
} from "../../Helpers/GroupbyHelper";
import VirtualGrid from "./VirtualGrid";
// import { useParams } from "react-router-dom";
import { GridRequestContext } from "../CGrid/ServerGrid";

const GroupedComponent = ({
  value,
  className,
  groupBy,
  schema,
  showTotals,
  selectedItems,
  columnsSize,
  RowComponent,
  columns,
  sort,
  onColumnsSizeChange,
  onSelectedItemsChange,
  results,
  onSort
}) => {
  const Obj = groupByColumnDict[groupBy[0]];

  let data = useEntityValue(Obj.schema, value) || value;

  if (groupBy[0] === "Origem") {
    return (
      <OriginGroupedHandler
        value={value}
        className={className}
        groupBy={groupBy}
        schema={schema}
        results={results}
        showTotals={false}
        selectedItems={selectedItems}
        columnsSize={columnsSize}
        RowComponent={RowComponent}
        columns={columns}
        sort={sort}
        onColumnsSizeChange={onColumnsSizeChange}
        onSelectedItemsChange={onSelectedItemsChange}
        onSort={onSort}
      />
    );
  }

  const Component = Obj.Component;

  return (
    <div
      style={{ maxHeight: "100%", height: "auto" }}
      className={className + " d-flex flex-column mb-2 of-hidden"}
    >
      <div className="pb-2">
        <Component Data={data} />
      </div>

      <VirtualGrid
        showTotals={false}
        noBody={true}
        //sortable
        // schemaName={schema.name}
        // getRowKey={getRowKey}
        selectedItems={selectedItems}
        onSelectedItemsChange={onSelectedItemsChange}
        onSort={onSort}
        sort={sort}
        height={50}
        className="m-h-100 flex-1 of-auto w-100 ar-main-grid"
        // serverFiltering={serverFiltering}
        columnsSize={columnsSize}
        onColumnsSizeChange={onColumnsSizeChange}
        // messages={{
        //   today: intl.formatMessage({ id: "today" }),
        //   yesterday: intl.formatMessage({ id: "yesterday" })
        // }}

        // filter={filter}
        groupBy={groupBy}
        RowComponent={RowComponent}
        // page={currentPage}
        // pageSize={pageSize}
        // totalItems={count}
        // headerScroll={false}
        //   change={data => console.log(data)}
        columns={columns}
        // onPageChange={handlePageChange}
        data={results}
        // getValue={getValue}
      />
    </div>
  );
};

const OriginGroupedHandler = ({
  value,
  className,
  groupBy,
  schema,
  showTotals,
  selectedItems,
  columnsSize,
  RowComponent,
  columns,
  sort,
  onColumnsSizeChange,
  onSelectedItemsChange,
  results,
  onSort
}) => {
  const Obj = groupByColumnDict[groupBy[0]];

  let data = useEntityValue(schema, results[0]) || results[0];

  const Component = Obj.Component;

  return (
    <div
      style={{ height: 400 }}
      className={className + " d-flex flex-column mb-2 of-hidden pb-4"}
    >
      <div className="pb-2">
        <Component Data={data} />
      </div>

      <VirtualGrid
        showTotals={false}
        //sortable
        // schemaName={schema.name}
        // getRowKey={getRowKey}
        selectedItems={selectedItems}
        onSelectedItemsChange={onSelectedItemsChange}
        onSort={onSort}
        sort={sort}
        height={50}
        className="m-h-100 flex-1 w-100 ar-main-grid"
        // serverFiltering={serverFiltering}
        columnsSize={columnsSize}
        onColumnsSizeChange={onColumnsSizeChange}
        // messages={{
        //   today: intl.formatMessage({ id: "today" }),
        //   yesterday: intl.formatMessage({ id: "yesterday" })
        // }}

        // filter={filter}
        groupBy={groupBy}
        RowComponent={RowComponent}
        // page={currentPage}
        // pageSize={pageSize}
        // totalItems={count}
        // headerScroll={false}
        //   change={data => console.log(data)}
        columns={columns}
        // onPageChange={handlePageChange}
        data={results}
        // getValue={getValue}
      />
    </div>
  );
};

const MoreGroupedOriginGroupedHandler = ({
  value,
  className,
  groupBy,
  schema,
  showTotals,
  selectedItems,
  columnsSize,
  RowComponent,
  columns,
  sort,
  onColumnsSizeChange,
  onSelectedItemsChange,
  results,
  onSort
}) => {
  const Obj = groupByColumnDict[groupBy[0]];

  let data = useEntityValue(schema, results[0]) || results[0];

  const Component = Obj.Component;
  const NewGroupBy = [...groupBy];
  NewGroupBy.splice(0, 1);
  return (
    <div className={className + " d-flex flex-column mb-2 of-hidden pb-4"}>
      <div className="pb-2">
        <Component Data={data} />
      </div>
      <ServerAwareGroupedGrid
        groupByColumnDict={groupByColumnDict}
        schema={schema}
        showTotals={false}
        selectedItems={selectedItems}
        onSelectedItemsChange={onSelectedItemsChange}
        onSort={onSort}
        sort={sort}
        height={50}
        className="bg-white flex-1 mb-3 w-100 p-2 border ar-main-grid"
        columnsSize={columnsSize}
        onColumnsSizeChange={onColumnsSizeChange}
        groupBy={NewGroupBy}
        RowComponent={RowComponent}
        columns={columns}
        data={results}
      />
    </div>
  );
};

const MoreGroupedComponent = ({
  value,
  className,
  groupBy,
  schema,
  showTotals,
  selectedItems,
  columnsSize,
  RowComponent,
  columns,
  sort,
  onColumnsSizeChange,
  onSelectedItemsChange,
  results,
  onSort
}) => {
  const Obj = groupByColumnDict[groupBy[0]];

  let data = useEntityValue(Obj.schema, value) || value;

  if (groupBy[0] === "Origem") {
    return (
      <MoreGroupedOriginGroupedHandler
        value={value}
        className={className}
        groupBy={groupBy}
        schema={schema}
        results={results}
        showTotals={false}
        selectedItems={selectedItems}
        columnsSize={columnsSize}
        RowComponent={RowComponent}
        columns={columns}
        sort={sort}
        onColumnsSizeChange={onColumnsSizeChange}
        onSelectedItemsChange={onSelectedItemsChange}
        onSort={onSort}
      />
    );
  }

  const Component = Obj.Component;
  const NewGroupBy = [...groupBy];
  NewGroupBy.splice(0, 1);
  return (
    <div className={className + " d-flex flex-column mb-2 of-y-auto pb-4"}>
      <div className="pb-2">
        <Component Data={data} />
      </div>

      <ServerAwareGroupedGrid
        groupByColumnDict={groupByColumnDict}
        schema={schema}
        groupByOfSecondOrder={true}
        showTotals={false}
        selectedItems={selectedItems}
        onSelectedItemsChange={onSelectedItemsChange}
        onSort={onSort}
        sort={sort}
        height={50}
        className="bg-white flex-1 mb-3 w-100 p-2 border ar-main-grid"
        columnsSize={columnsSize}
        onColumnsSizeChange={onColumnsSizeChange}
        groupBy={NewGroupBy}
        RowComponent={RowComponent}
        columns={columns}
        data={results}
      />
    </div>
  );
};

const itemsOffset = 1;
const useVirtualGridData = (height, data, containerRef) => {
  const [resolvedIndexes, setResolvedIndexes] = useState();
  const [resolvedData, setResolvedData] = useState();

  const [style, setStyle] = useState();

  const helperRef = useRef({});

  const verifyCicle = useCallback(
    (forceUpdate = false) => {
      if (helperRef.current.frame) {
        cancelAnimationFrame(helperRef.current.frame);
        helperRef.current.frame = undefined;
      }

      if (!data) return;

      //   ;

      const { offsetHeight, scrollTop } = containerRef.current;

      //
      const availableItemsInViewport =
        Math.round(offsetHeight / height) + itemsOffset * 2;

      let scrolledItems = Math.floor(scrollTop / height) - itemsOffset;

      if (scrolledItems < 0) scrolledItems = 0;

      const startIndex = scrolledItems;

      let endIndex = scrolledItems + availableItemsInViewport;
      if (endIndex > data.length - 1) endIndex = data.length - 1;

      if (
        !forceUpdate &&
        helperRef.current.startIndex === startIndex &&
        helperRef.current.endIndex === endIndex
      )
        return;

      // timeoutRef.current.timeout = setTimeout(() => {
      //   timeoutRef.current.timeout = undefined;

      // });
      helperRef.current.frame = requestAnimationFrame(() => {
        helperRef.current.frame = undefined;

        helperRef.current.startIndex = startIndex;
        helperRef.current.endIndex = endIndex;

        setResolvedData(data);
        setResolvedIndexes({ startIndex, endIndex });
        const paddingTop = scrolledItems * height;
        const paddingBottom = (data.length - 1 - endIndex) * height;

        setStyle({
          paddingTop,
          paddingBottom
        });
      });
    },
    [height, data, containerRef]
  );

  useLayoutEffect(() => {
    const container = containerRef.current;
    let frame = requestAnimationFrame(() => {
      if (container) verifyCicle(true);
      frame = undefined;
    });
    const listner = () => {
      verifyCicle();
    };

    if (container) container.addEventListener("scroll", listner);

    return () => {
      if (container) container.removeEventListener("scroll", listner);
      if (frame) cancelAnimationFrame(frame);
    };
  }, [verifyCicle, containerRef]);

  return {
    resolvedIndexes,
    containerRef,
    timeoutRef: helperRef,
    style,
    verifyCicle,
    resolvedData
  };
};

// const useVirtualGridDataWithVariableHeights = (
//   heightArr,
//   data,
//   containerRef
// ) => {
//   console.log(heightArr);
//   const [resolvedIndexes, setResolvedIndexes] = useState();
//   const [resolvedData, setResolvedData] = useState();

//   const [style, setStyle] = useState();

//   const helperRef = useRef({});

//   const verifyCicle = useCallback(
//     (forceUpdate = false) => {
//       if (helperRef.current.frame) {
//         cancelAnimationFrame(helperRef.current.frame);
//         helperRef.current.frame = undefined;
//       }

//       if (!data) return;

//       const { offsetHeight, scrollTop } = containerRef.current;

//       let GroupsInViewPort = { AddToTop: 0, AddToBottom: 0 };
//       let TempStartIndex = undefined;
//       let TempEndIndex = undefined;
//       let TempHeight = 0;

//       for (let i = 0; i < heightArr.length; i++) {
//         const CurrentHeight = heightArr[i] * 300;
//         TempHeight = CurrentHeight + TempHeight;
//         if (TempHeight < scrollTop) {
//           GroupsInViewPort.AddToTop++;
//           continue;
//         }
//         // if(TempHeight > scrollTop + offsetHeight) continue;
//         if (TempHeight >= scrollTop) {
//           if (typeof TempStartIndex === "undefined") TempStartIndex = i;
//           if (
//             TempHeight >= scrollTop + offsetHeight &&
//             typeof TempEndIndex === "undefined"
//           )
//             TempEndIndex = i;
//           if (
//             typeof TempEndIndex !== "undefined" &&
//             typeof TempStartIndex !== "undefined"
//           )
//             GroupsInViewPort.AddToBottom++;
//         }
//         console.log(TempStartIndex, TempEndIndex);
//       }

//       //   console.log(offsetHeight, scrollTop);
//       //   const availableItemsInViewport =
//       //     Math.round(offsetHeight / 300) + itemsOffset * 2;

//       //   let scrolledItems = Math.floor(scrollTop / 300) - itemsOffset;

//       //   if (scrolledItems < 0) scrolledItems = 0;

//       const startIndex = TempStartIndex;

//       let endIndex = TempEndIndex + 1;
//       console.log(startIndex, endIndex);
//       if (endIndex > data.length - 1) endIndex = data.length - 1;

//       if (
//         !forceUpdate &&
//         helperRef.current.startIndex === startIndex &&
//         helperRef.current.endIndex === endIndex
//       )
//         return;

//       helperRef.current.frame = requestAnimationFrame(() => {
//         helperRef.current.frame = undefined;

//         helperRef.current.startIndex = startIndex;
//         helperRef.current.endIndex = endIndex + 2;

//         setResolvedData(data);
//         setResolvedIndexes({ startIndex, endIndex });
//         const paddingTop = GroupsInViewPort.AddToTop * 300;
//         const paddingBottom = GroupsInViewPort.AddToBottom * 300;

//         setStyle({
//           paddingTop,
//           paddingBottom
//         });
//       });
//     },
//     [data, containerRef, heightArr]
//   );

//   useLayoutEffect(() => {
//     const container = containerRef.current;
//     let frame = requestAnimationFrame(() => {
//       if (container) verifyCicle(true);
//       frame = undefined;
//     });
//     const listner = () => {
//       verifyCicle();
//     };

//     if (container) container.addEventListener("scroll", listner);

//     return () => {
//       if (container) container.removeEventListener("scroll", listner);
//       if (frame) cancelAnimationFrame(frame);
//     };
//   }, [verifyCicle, containerRef]);

//   return {
//     resolvedIndexes,
//     containerRef,
//     timeoutRef: helperRef,
//     style,
//     verifyCicle,
//     resolvedData
//   };
// };

const GroupedByContainer = ({
  Groups,
  height,
  groupBy,
  groupByOfSecondOrder
}) => {
  const containerRef = useRef();
  const { params } = useContext(GridRequestContext);
  const { skip } = params;
  // useLayoutEffect(()=>{
  // 	const container = containerRef.current;
  // 	if(container){
  // 		const children =[...container.children]
  // 		const childrenHeights = children.map(e=>{
  // 			return e.offsetHeight;
  // 		})

  // 	}

  // },[])
  // let resolvedHeight = height;
  // if(groupBy[1]){
  // 	resolvedHeight =
  // }

  // const { style, resolvedIndexes, resolvedData } = useVirtualGridData(
  //   height,
  //   Groups,
  //   containerRef
  // );
  const previousSkip = useRef();
  useEffect(() => {
    if (typeof previousSkip.current === "number" && containerRef.current) {
      containerRef.current?.scrollTo({ top: 0, behavior: "instant" });
    } else previousSkip.current = skip;
  }, [skip]);
  // if (resolvedIndexes) {
  // const { startIndex, endIndex } = resolvedIndexes;
  // const containers = [];

  // for (let i = startIndex; i <= endIndex; i++) {
  //   const v = resolvedData[i];
  //   if (v === undefined) continue;

  //   containers.push(v);
  // }

  return (
    <div ref={containerRef} className="of-y-auto pt-2 h-100">
      {/* <div style={style}>{containers}</div> */}
      {Groups}
    </div>
  );
  // } else {
  //   return (
  //     <div style={style} ref={containerRef} className="of-y-auto h-100"></div>
  //   );
  // }
};

// const GroupedBySecondOrderContainer = ({ Groups, heightArr, groupBy }) => {
//   const containerRef = useRef();
//   const maxHeight = Math.max(...heightArr) * 350;
//   const { style, resolvedIndexes, resolvedData } = useVirtualGridData(
//     maxHeight,
//     Groups,
//     containerRef
//   );
//   if (resolvedIndexes) {
//     const { startIndex, endIndex } = resolvedIndexes;
//     const containers = [];

//     for (let i = startIndex; i <= endIndex; i++) {
//       const v = resolvedData[i];
//       if (v === undefined) continue;

//       containers.push(v);
//     }

//     return (
//       <div ref={containerRef} className="of-y-auto h-100">
//         <div style={style}>{containers}</div>
//       </div>
//     );
//   } else {
//     return (
//       <div style={style} ref={containerRef} className="of-y-auto h-100"></div>
//     );
//   }
// };

// const ServerAwareGroupedSecondLevelGrid = ({
//   ConsolidatedResults,
//   data,
//   groupBy,
//   RowComponent,
//   height,
//   columns,
//   columnsSize,
//   className,
//   onColumnsSizeChange,
//   sort,
//   onSort,
//   schema,
//   selectedItems,
//   onSelectedItemsChange,
//   showTotals,
//   datakey
// }) => {
//   const heightArray = [];

//   const selector = useMemo(() => {
//     return v => {
//       const { Id } = v;

//       const obj = { Id };
//       const item = GroupedByDictionary[groupBy[0]];

//       if (item === "Origem") {
//         obj.Deal = v.Deal;
//         obj.Client = v.Client;
//         obj.Task = v.Task;
//         obj.Call = v.Call;
//         obj.Deal = v.Deal;
//         obj.Project = v.Project;
//         obj.Ticket = v.Ticket;
//         obj.Contract = v.Contract;
//       } else if (item === "Client") {
//         obj[item] = v["IndirectClient"] || v[item];
//       } else {
//         obj[item] = v[item];
//       }

//       return obj;
//     };
//   }, [groupBy]);

//   const connectedResults = useMultipleMultipleEntityValueSelector(
//     schema,
//     data || [],
//     datakey,
//     selector
//   );

//   connectedResults.forEach(singleConnectedResult => {
//     let groupedByFilterIds = [];
//     let groupedIds = {};

//     for (const obj of singleConnectedResult) {
//       if (groupBy[0] === "Origem") {
//         const activeType =
//           obj.Deal ||
//           obj.Client ||
//           obj.Task ||
//           obj.Call ||
//           obj.Deal ||
//           obj.Project ||
//           obj.Ticket ||
//           obj.Contract;

//         if (groupedByFilterIds.indexOf(activeType) === -1) {
//           groupedByFilterIds.push(activeType);
//           groupedIds[activeType] = [];
//           groupedIds[activeType].push(obj.Id);
//         } else {
//           groupedIds[activeType].push(obj.Id);
//         }
//       } else {
//         const activeType = obj[GroupedByDictionary[groupBy[0]]];
//         if (groupedByFilterIds.indexOf(activeType) === -1) {
//           groupedByFilterIds.push(activeType);
//           groupedIds[activeType] = [];
//           groupedIds[activeType].push(obj.Id);
//         } else {
//           groupedIds[activeType].push(obj.Id);
//         }
//       }
//     }

//     heightArray.push(Object.keys(groupedIds).length);
//   });

//   return (
//     <GroupedBySecondOrderContainer
//       Groups={ConsolidatedResults}
//       groupBy={groupBy}
//       heightArr={heightArray}
//     />
//   );
// };

export const ServerAwareGroupedGrid = ({
  groupByOfSecondOrder = false,
  data,
  groupBy,
  RowComponent,
  height,
  columns,
  columnsSize,
  className,
  onColumnsSizeChange,
  sort,
  onSort,
  schema,
  selectedItems,
  onSelectedItemsChange,
  showTotals
}) => {
  const selector = useMemo(() => {
    return (v) => {
      const { Id } = v;

      const obj = { Id };
      const item = GroupedByDictionary[groupBy[0]];

      if (item === "Origem") {
        obj.Deal = v.Deal;
        obj.Client = v.Client;
        obj.Task = v.Task;
        obj.Call = v.Call;
        obj.Deal = v.Deal;
        obj.Project = v.Project;
        obj.Ticket = v.Ticket;
        obj.Contract = v.Contract;
      } else if (item === "Client") {
        obj[item] = v["IndirectClient"] || v[item];
      } else {
        obj[item] = v[item];
      }

      return obj;
    };
  }, [groupBy]);

  const connectedResults = useMultipleEntityValueSelector(
    schema,
    data || [],
    selector
  );

  let groupedByFilterIds = [];
  let groupedIds = {};

  for (const obj of connectedResults) {
    if (groupBy[0] === "Origem") {
      const activeType =
        obj.Deal ||
        obj.Client ||
        obj.Task ||
        obj.Call ||
        obj.Deal ||
        obj.Project ||
        obj.Ticket ||
        obj.Contract;

      if (groupedByFilterIds && groupedByFilterIds.indexOf(activeType) === -1) {
        groupedByFilterIds.push(activeType);
        groupedIds[activeType] = [];
        groupedIds[activeType].push(obj.Id);
      } else {
        groupedIds[activeType].push(obj.Id);
      }
    } else {
      const activeType = obj[GroupedByDictionary[groupBy[0]]];
      if (groupedByFilterIds && groupedByFilterIds.indexOf(activeType) === -1) {
        groupedByFilterIds.push(activeType);
        groupedIds[activeType] = [];
        groupedIds[activeType].push(obj.Id);
      } else {
        groupedIds[activeType].push(obj.Id);
      }
    }
  }

  let resultGroupedUpData = [];

  if (!groupBy[1]) {
    resultGroupedUpData = groupedByFilterIds.map((e, i) => {
      return (
        <GroupedComponent
          key={i}
          schema={schema}
          groupBy={groupBy}
          className={className}
          value={e}
          results={groupedIds[e]}
          showTotals={false}
          selectedItems={selectedItems}
          columnsSize={columnsSize}
          RowComponent={RowComponent}
          columns={columns}
          sort={sort}
          onColumnsSizeChange={onColumnsSizeChange}
          onSelectedItemsChange={onSelectedItemsChange}
          onSort={onSort}
        />
      );
    });
  } else {
    resultGroupedUpData = groupedByFilterIds.map((e, i) => {
      return (
        <MoreGroupedComponent
          key={i}
          schema={schema}
          groupBy={groupBy}
          className={className}
          value={e}
          results={groupedIds[e]}
          showTotals={false}
          selectedItems={selectedItems}
          columnsSize={columnsSize}
          RowComponent={RowComponent}
          columns={columns}
          sort={sort}
          onColumnsSizeChange={onColumnsSizeChange}
          onSelectedItemsChange={onSelectedItemsChange}
          onSort={onSort}
        />
      );
    });

    // const NewGroupBy = [...groupBy];
    // NewGroupBy.splice(0, 1);
    // return (
    //   <ServerAwareGroupedSecondLevelGrid
    //     ConsolidatedResults={resultGroupedUpData}
    //     groupByColumnDict={groupByColumnDict}
    //     schema={schema}
    //     showTotals={false}
    //     selectedItems={selectedItems}
    //     onSelectedItemsChange={onSelectedItemsChange}
    //     onSort={onSort}
    //     sort={sort}
    //     height={50}
    //     className="bg-white flex-1 mb-3 w-100 p-2 border ar-main-grid"
    //     columnsSize={columnsSize}
    //     onColumnsSizeChange={onColumnsSizeChange}
    //     groupBy={NewGroupBy}
    //     RowComponent={RowComponent}
    //     columns={columns}
    //     data={groupedIds}
    //     datakey={groupedByFilterIds}
    //   />
    // );
  }

  return (
    <GroupedByContainer
      groupByOfSecondOrder
      Groups={resultGroupedUpData}
      groupBy={groupBy}
      height={300}
    />
  );
};
