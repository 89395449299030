import React, { useContext, useEffect } from "react";
import { ProjectEntityGridButtons } from "../../Projects/ProjectsHelper";
import { ProjectsLink } from "../../../Helpers/HooksHelper";
import { ConnectedClientOrigin } from "../../Origin/ClientOrigin";
import { ConnectedAccountPopup } from "../../Origin/AcountPopup";
import {
  MilSecToFormatedHourMinute,
  MilSecToHour
} from "../../../Helpers/MiscHelper";
import SpentHoursPreview from "../../../Components/SpentHoursPreview/SpentHoursPreview";
import { useProject } from "../../../Hooks/EntityHooks";
import {
  ReportsDateProvider,
  DashboardEmptyResponse,
  DashboardTable,
  DashboardTitle
} from "../Dashboard";
import { Tr } from "../../../Components/Table";
import { spaceProjectsSchema } from "../../../config/schema";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { faFileArchive, faCog } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage } from "react-intl";
import { useDashboardQuery } from "../DashboardQueryHelper";
import { useSidebar } from "../../../Components/Sidebar/SidebarV2";
import { DashboardSettingsContext } from "../DashboardLayout";
import DashboardCardSettingsForm, {
  handleIntervalCalculation
} from "../DashboardCardSettingsForm";
import Button from "../../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const columns = [
  {
    minWidth: 120,
    width: 120,
    Cell: (value) => <ProjectEntityGridButtons entity={value} />
  },
  {
    title: "Id",
    Cell: ({ Id }) => <ProjectsLink id={Id}>{Id}</ProjectsLink>
  },
  {
    maxWidth: 300,
    title: <FormattedMessage id={"NAME"} />,
    Cell: ({ Name }) => <span className="text-black">{Name}</span>
  },
  {
    maxWidth: 200,
    title: <FormattedMessage id={"ACCOUNT"} />,
    Cell: ({ Client, IndirectClient }) => (
      <ConnectedClientOrigin client={Client || IndirectClient} />
    )
  },
  {
    title: <FormattedMessage id={"MANAGER"} />,
    Cell: ({ Manager }) => <ConnectedAccountPopup account={Manager} />
  },
  {
    title: <FormattedMessage id={"SPENT_HOURS"} />,
    Cell: ({ SpentHours, EstimatedCost }) =>
      typeof SpentHours === "number" && typeof EstimatedCost === "number"
        ? MilSecToFormatedHourMinute(SpentHours)
        : null
  },
  {
    title: <FormattedMessage id={"ESTIMATED_TIME"} />,
    Cell: ({ EstimatedCost }) => <div>{MilSecToHour(EstimatedCost, true)}</div>
  },
  {
    title: <FormattedMessage id={"PERFORMANCE"} />,
    Cell: ({ SpentHours, EstimatedCost }) => (
      <SpentHoursPreview
        onlyPercentage
        SpentHours={SpentHours}
        EstimatedHours={EstimatedCost}
      />
    )
  }
];

const ContractTr = ({ value, ...rest }) => {
  const contract = useProject(value);

  return <Tr value={contract} {...rest} />;
};
const lS_Group = "dashboard-layout-projetos";
const AlmostExcededTimeProject = (props) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "AlmostExcededTimeProject";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, loading, error } = useDashboardQuery(
    `Dashboard/Services/Projects/AlmostTimeExceeded?top=15&tax=80&todate=${
      DataReports[0] === "Projects"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Projects"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Projects"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`,
    [spaceProjectsSchema],
    { cache: false }
  );
  const user =
    DataReports[0] === "Contracts" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  const buildBody = () => {
    if (loading || error)
      return <LoaderSpinner size="sm text-secondary" center />;

    if (data?.length === 0)
      return (
        <DashboardEmptyResponse className="h-100" type="projects">
          <FormattedMessage id={"EMPTY_PROJECTS"} />
        </DashboardEmptyResponse>
      );

    return (
      <DashboardTable TrComponent={ContractTr} columns={columns} data={data} />
    );
  };

  return (
    <React.Fragment>
      <DashboardTitle
        text={<FormattedMessage id={"PROJECTS_ALMOST_EXCEEDED"} />}
        icon={faFileArchive}
      />
      {DataReports[0] !== "Projects" && (
        <div className="d-flex  px-4 justify-content-between align-items-center">
          <div className="mb-2">
            <span className="ar-dashboard-description">
              {Settings.Interval ? (
                <FormattedMessage id={Settings.Interval.title} />
              ) : (
                <FormattedMessage id={"ALL"} />
              )}
            </span>
            <span className="ar-dashboard-description ml-1">
              | <FormattedMessage id={"SCOPE"} />:
            </span>
            <span className="text-primary pl-1 fs-14">{user}</span>
          </div>
          <Button
            className="ar-dashboard-card-settings-link"
            vType="link-primary"
            onClick={() =>
              openSidebar(
                <DashboardCardSettingsForm
                  lS_Field={lS_Field}
                  lS_Group={lS_Group}
                  Settings={Settings}
                  closeSidebar={closeSidebar}
                  Title={"PROJECTS_ALMOST_EXCEEDED"}
                  handleChanges={handleLayoutChange}
                  AvailableSettings={{
                    interval: true,
                    scope: true,
                    pipeline: false
                  }}
                />
              )
            }
          >
            <FontAwesomeIcon icon={faCog} />
          </Button>
        </div>
      )}
      {buildBody()}
    </React.Fragment>
  );
};

export default AlmostExcededTimeProject;
