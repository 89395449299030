import React, { useMemo } from "react";
import Modal from "../Modal/Modal";
import "./FilePreviewer.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDownload } from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import OfficePreview from "../FileUploader/OfficePreview";
import PdfPreview from "../FileUploader/PdfPreview";
import ImagePreview from "../FileUploader/ImagePreview";
import EmailPreview from "../FileUploader/EmailPreview";
import DumbFilesPreview from "../FileUploader/DumbFilesPreview";

const checkForImageType = (file) => {
  if (!file) return false;
  switch (file.Extension) {
    case ".gif":
    case ".jpg":
    case ".png":
    case ".jpeg":
    case ".bmp":
    case ".svg":
      return true;
    default:
      return false;
  }
};

const download_file = (fileURL, fileName) => {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_self";

    save.download = fileName;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, "_blank");
    _window.document.close();
    _window.document.execCommand("SaveAs", true, fileName || fileURL);
    _window.close();
  }
};

const forceDownload = (index, File = {}) => {
  var file_path = File.Url;
  var file_name = File.Name;
  var file_Extension = File.Extension;
  download_file(file_path + "&inline=false", file_name + file_Extension);
};

export const ExtraCloseButton = ({ file, onClose }) => {
  return (
    <>
      <div
        className="ar-modal-preview-file-content-close-button"
        onClick={() => onClose()}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
      {file && !file.Size && (
        <div
          style={{ top: 55 }}
          className="ar-modal-preview-file-content-close-button text-color-link"
          onClick={() => forceDownload(null, file)}
        >
          <FontAwesomeIcon icon={faDownload} />
        </div>
      )}
    </>
  );
};

const splitter = (s) => {
  var middle = s.length / 2;

  return [s.substr(0, middle), s.substr(middle)];
};

const Footer = ({ file, onClose }) => {
  let SplitText = splitter(file.Name + file.Extension);

  return (
    <div
      // style={{ maxWidth: this.state.ImageWidth }}
      className="align-items-center d-flex justify-content-between px-1 text-black"
    >
      <span
        title={file.Name}
        className="flex-1 mr-1"
        id="Span-With-Split-Text"
        data-content-start={SplitText[0]}
        data-content-end={SplitText[1]}
      ></span>

      <div
        style={{ fontSize: 12 }}
        className="ar-fileUploader__imageContainer__Title"
      >
        {moment(file.UploadDate).format("DD/MM/YYYY HH:mm")}
      </div>
    </div>
  );
};

const Content = ({ file }) => {
  return useMemo(() => {
    const FileExtension = file.Extension;
    switch (`${FileExtension.toLowerCase()}`) {
      case ".doc":
      case ".docx":
      case ".xls":
      case ".xlr":
      case ".xlam":
      case ".dotx":
      case ".xlsx":
      case ".xlt":
      case ".pptx":
      case ".pptm":
      case ".ppt":
      case ".xps":
      case ".pps":
      case ".ppam":
      case ".tif":
        return <OfficePreview src={file.Url + "&inline=false"} />;
      case ".pdf":
      case ".pdfa":
        return <PdfPreview src={file.Url + "&inline=true"} />;

      case ".gif":
      case ".jpg":
      case ".png":
      case ".jpeg":
      case ".bmp":
      case ".jfif":
      case ".svg":
      case ".webp":
        return (
          <ImagePreview
            handleWidthChange={this.handleImageWidth}
            src={file.Url + "&inline=true"}
          />
        );
      case ".msg":
      case ".eml":
        return <EmailPreview File={file} />;
      case ".csv":
      case ".json":
      case ".txt":
        return <DumbFilesPreview src={file.Url} />;
      default:
        return null;
    }
  }, [file]);
};

export const FilePreviewerModal = ({ file, isOpen, onClose }) => {
  const isImage = checkForImageType(file);

  return (
    <Modal
      className={classnames(
        "ar-modal-preview-file-content  d-flex flex-column p-1",
        {
          "ar-modal-preview-image-content": isImage,
          "ar-modal-preview-files-content-files": !isImage
        }
      )}
      isOpen={isOpen}
      onClose={onClose}
      extraCloseButton={<ExtraCloseButton file={file} onClose={onClose} />}
    >
      <Content file={file} />
      <Footer file={file} />
    </Modal>
  );
};
