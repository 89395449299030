import { createStore, applyMiddleware, compose } from "redux";
import RootReducer from "./Redux/Reducer/Index";
import thunk from "redux-thunk";
import { configureApi } from "./api";

// const logger = store => next => action => {
//   console.group(action.type);
//   console.info("dispatching", action);
//   let result = next(action);
//   console.log("next state", store.getState());
//   console.groupEnd();
//   return result;
// };
// const composeEnhancers = (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  RootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

configureApi(store);

export default store;
