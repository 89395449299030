import { createSpaceAwareReducer } from "../../Helpers/ReduxHelper";

const createMainGridViewReducer = reducer => (state = {}, action) => {
  const { internalType } = action;

  if (internalType !== "gridView" && internalType !== "form") return state;

  const { name } = action;

  if (!name) return state;

  const currentGridViewState = state[name];

  const newGridViewState = reducer(currentGridViewState, action);

  if (
    newGridViewState !== undefined &&
    newGridViewState !== currentGridViewState
  )
    return {
      ...state,
      [name]: newGridViewState
    };
  else return state;
};

const gridView = (
  state = {
    isFetching: false,
    params: null,
    error: null,
    value: null,
    requestSource: null,
    shouldUpdate: false
  },
  action
) => {
  const { actionName } = action;

  switch (action.type) {
    case `FETCH_${actionName}_REQUEST`:
      return {
        ...state,
        requestSource: action.requestSource,
        params: action.params,
        isFetching: true,
        shouldUpdate: false,
        error: null
      };

    case `FETCH_${actionName}_SUCCESS`:
      return {
        ...state,
        isFetching: false,
        requestSource: null,
        value: action.response.result
      };

    case `FETCH_${actionName}_ERROR`:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case `CREATE_OR_UPDATE_${actionName}_SUCCESS`:
      return !action.isUpdate
        ? {
            ...state,
            shouldUpdate: true
          }
        : state;

    default:
      return state;
  }
};

export default createSpaceAwareReducer(createMainGridViewReducer(gridView));
