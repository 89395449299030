import React, { FC, useMemo, useRef } from "react";
import Popup, { usePopupOpenState } from "../Popup/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconName,
  IconPrefix,
} from "@fortawesome/fontawesome-svg-core";
import classes from "./FavoriteIconPicker.module.css";
import classnames from "classnames";
import IconPickerPopup, { IFavoriteIconPickerProps } from "./IconPickerPopup";

const FavoriteIconPicker: FC<IFavoriteIconPickerProps> = ({ ...rest }) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const { color, icon } = rest;
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);

  const resolvedIcon = useMemo(() => {
    return icon.split(" ") as [IconPrefix, IconName];
  }, [icon]);

  return (
    <div>
      <Popup
        style={{ boxShadow: "none" }}
        placement={"bottom-start"}
        domRef={popupRef}
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
      >
        <IconPickerPopup {...rest} />
      </Popup>
      <div
        onClick={() => {
          typeof toggleIsOpen === "function" && toggleIsOpen();
        }}
        ref={anchorRef}
        className={classnames(classes.IconContainer)}
        style={{ background: color }}
      >
        <FontAwesomeIcon size="lg" icon={resolvedIcon} />
      </div>
    </div>
  );
};

export default FavoriteIconPicker;
