import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const CallOverviewComp = React.lazy(() =>
import(/* webpackChunkName: "calls-overview" */ "./CallOverviewComp")
);

const CallOverview = () => {
  return (
    <SuspenseErrorBoundary>
      <CallOverviewComp />
    </SuspenseErrorBoundary>
  );
};

export default CallOverview;
