// import React from "react";
// import classnames from "classnames";
import {
  darkred,
  red,
  //   lightred,
  darkgrey,
  //   grey,
  darkgreen,
  green,
  //   monthNames,
  createChartComponent,
  getMonth,
  // darkblue,
  // blue,
  darkPurple,
  // purple,
  lightPurple
} from "./echartDefaults";
import { DealStatusTypes } from "../../Deals/DealsHelper";
import { largeNumberFormatter } from "../../../Helpers/MiscHelper";

// include ECharts main module

// include bar chart
require("echarts/lib/echarts.js");
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
// include  title component

// include ECharts main module

// include bar chart

require("echarts/lib/chart/line");
// include  title component
require("echarts/lib/component/tooltip");
// const formatter = new Intl.NumberFormat("de-DE", {
//   style: "decimal",
//   minimumFractionDigits: 2
// });
export const DealsComboEvolution = createChartComponent((Data, intl) => {
  const Open = [];
  const Lost = [];
  const Won = [];

  Data.forEach((e) => {
    Object.keys(e.Data).forEach((k) => {
      if (parseInt(k) === DealStatusTypes.open)
        Open.push(e.Data[k].Sum ? e.Data[k].Sum : 0);
      else if (parseInt(k) === DealStatusTypes.won)
        Won.push(e.Data[k].Sum ? e.Data[k].Sum : 0);
      else if (parseInt(k) === DealStatusTypes.lost)
        Lost.push(e.Data[k].Sum ? e.Data[k].Sum : 0);
    });
  });

  const AllValues = [...Open, ...Lost, ...Won];
  const interval =
    AllValues.reduce((a, b) => {
      return Math.max(a, b);
    }) / 5;

  return {
    tooltip: {
      renderMode: "richText",
      backgroundColor: "#002B63",
      show: true,
      textStyle: {
        color: "#FFFFFF",
        fontWight: "bolder"
      },
      trigger: "item",
      axisPointer: {
        type: "line"
      }
    },
    legend: {
      left: "left",
      selectedMode: true,
      borderRadius: 3,
      icon: "roundRect",
      textStyle: {
        color: darkgrey
        // backgroundColor: lightred,
      },
      data: [
        {
          name: intl.formatMessage({ id: "LOSES" }),
          textStyle: {
            color: darkred,

            backgroundColor: "#fff",
            borderRadius: 3
          }
        },
        {
          name: intl.formatMessage({ id: "OPEN" }),
          textStyle: {
            color: darkPurple,

            backgroundColor: "#fff",
            borderRadius: 3
          }
        },
        {
          name: intl.formatMessage({ id: "SALES" }),
          textStyle: {
            color: darkgreen,

            backgroundColor: "#fff",
            borderRadius: 3
          }
        }
      ]
    },
    grid: {
      left: "4%",
      right: "0%",
      bottom: "4%",
      containLabel: true
    },
    xAxis: {
      name: "",
      nameLocation: "end",
      nameTextStyle: {
        // padding: [47, 15, 0, 0],
        color: "#7c98b6"
      },
      boundaryGap: true,
      type: "category",
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      data: Data.map((e) => {
        return {
          value: `${getMonth(e.Month - 1)}, ${e.Year.toString().substring(2)}`,
          textStyle: {
            color: "#7c98b6"
          }
        };
      })
    },
    yAxis: {
      boundaryGap: false,
      interval: interval,
      axisLabel: {
        color: "#7c98b6",
        formatter: (value, index) => {
          return `€ ${largeNumberFormatter(value)}`;
        }
      },
      axisLine: {
        show: false
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#eff4f8"]
        }
      },
      name: "",
      nameLocation: "end",
      nameTextStyle: {
        padding: [0, 40, 0, 0],
        color: "#7c98b6"
      },
      type: "value",
      axisTick: {
        show: false
      }
    },
    series: [
      {
        name: intl.formatMessage({ id: "LOSES" }),
        data: Lost,
        type: "bar",
        itemStyle: {
          color: red,
          borderRadius: [5, 5, 5, 5]
        },
        tooltip: {
          show: true,
          axisPointer: {
            animation: false
          },
          formatter: (item, index) => {
            return `${item.seriesName}: € ${largeNumberFormatter(item.value)}`;
          },
          renderMode: "richText",
          trigger: "item",
          textStyle: {
            color: "#FFFFFF",
            fontWeight: "500"
          },
          backgroundColor: "#002B63"
        }
      },
      {
        name: intl.formatMessage({ id: "OPEN" }),
        data: Open,
        type: "bar",
        itemStyle: {
          color: lightPurple,
          borderRadius: [5, 5, 5, 5]
        },
        tooltip: {
          show: true,
          axisPointer: {
            animation: false
          },
          formatter: (item, index) => {
            return `${item.seriesName}: € ${largeNumberFormatter(item.value)}`;
          },
          renderMode: "richText",
          trigger: "item",
          textStyle: {
            color: "#FFFFFF",
            fontWeight: "500"
          },
          backgroundColor: "#002B63"
        }
      },
      {
        name: intl.formatMessage({ id: "SALES" }),
        data: Won,
        type: "bar",
        itemStyle: {
          color: green,
          borderRadius: [5, 5, 5, 5]
        },
        tooltip: {
          show: true,
          axisPointer: {
            animation: false
          },
          formatter: (item, index) => {
            // console.log(item);
            return `${item.seriesName}: € ${largeNumberFormatter(item.value)}`;
          },
          renderMode: "richText",
          trigger: "item",
          textStyle: {
            color: "#FFFFFF",
            fontWeight: "500"
          },
          backgroundColor: "#002B63"
        }
      }
    ]
  };
});
