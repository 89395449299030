import React, {
  FC,
  useState,
  useMemo,
  useContext,
  useCallback,
  useRef,
  useEffect
} from "react";
import ReactDOM from "react-dom";
import Draggable from "react-draggable";
import classes from "./NotifBrowserBox.module.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowRestore } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage } from "react-intl";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { getEntity, useQuery, useSpaceQuery } from "../../Helpers/IOClient";
import { spacePopupNotificationSchema } from "../../config/schema";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import { useEndlessScroll } from "../EndlessScrollPage/EndlessScrollPage";
import store from "../../store";
import moment from "moment";
import FilteredNotifications from "../../Containers/Notifications/FilteredNotifications/FilteredNotifications";
import { NotificationSchemaContext } from "../../Containers/Notifications/FilteredNotifications/NotificationItem/NotificationHelper";
import { usePopupNotification } from "../../Hooks/EntityHooks";
import { useEntitiesHub } from "../../Containers/RealTime/RealTime";
import { useSpace } from "../../Contexts/SpaceContext";
import { useSoundPlayer } from "../NotificationSoundProvider/NotificationSound";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { BarLoader } from "../GlobalLoader/GlobalLoader";

function getDiffInDays(d1, d2) {
  const d11 = new moment(d1);
  const d22 = new moment(d2);
  return d11.isSame(d22, "day");
}

const NotificationBrowserBox = ({ isOpen, setIsOpen }) => {
  const BoxRef = React.createRef();
  const tempState = useRef();
  const [lastCoordinates, setLastCoordinates] = useState();
  const timeoutRef = useRef();

  const setUpdateTimer = useCallback((props, result) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      let newX = props.x;
      let newY = props.y;
      tempState.current = [newX, newY];
      // console.log(props);
    }, 100);
  }, []);

  const saveBrowserPopupCoordinates = (e: any, t: any) => {
    setUpdateTimer(t);
    // tempState.current = [x, y] as [number, number];
  };

  useEffect(() => {
    if (!isOpen && tempState.current) {
      if (
        tempState.current[0] !== lastCoordinates?.x ||
        tempState.current[1] !== lastCoordinates?.y
      )
        setLastCoordinates({
          x: tempState.current[0],
          y: tempState.current[1]
        });
      tempState.current = undefined;
    }
  }, [isOpen, lastCoordinates]);

  // const isMountedRef = useRef<boolean>(false)
  // useEffect(() => {
  // 	if(lastCoordinates){

  // 	}
  // }, [])

  const Box = (
    <Draggable
      defaultPosition={
        lastCoordinates && {
          x: lastCoordinates.x,
          y: lastCoordinates.y
        }
      }
      bounds={{
        left: 0,
        top: 0,
        right: window.innerWidth - 560,
        bottom: window.innerHeight - 380
      }}
      onStop={saveBrowserPopupCoordinates}
      nodeRef={BoxRef}
      handle="#handle"
    >
      <RemWrapper style={{ zIndex: 10000001, display: !isOpen && "none" }}>
        <div
          ref={BoxRef}
          className={classnames(
            classes.NotifBox,
            classes.remPositionFix,
            "popup"
          )}
          style={{ position: "absolute", top: 0, left: 0 }}
        >
          <div className="d-flex align-items-center bg-airdesk">
            <div
              id="handle"
              className={classnames(
                "flex-1 d-flex p-2 align-items-center text-black fs-14",
                classes.cursor
              )}
            >
              <FontAwesomeIcon icon={faWindowRestore} />
              <span className="ml-2">
                <FormattedMessage id="NOTIFICATIONS" />
              </span>
            </div>
            <div
              onClick={() => {
                setIsOpen(false);
              }}
              className="text-danger-alt cursor-pointer p-2"
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
          <NotificationSchemaContext.Provider
            value={{
              schema: spacePopupNotificationSchema,
              useSchema: usePopupNotification
            }}
          >
            <NotificationBrowserContent openModal={setIsOpen} isOpen={isOpen} />
          </NotificationSchemaContext.Provider>
        </div>
      </RemWrapper>
    </Draggable>
  );
  return ReactDOM.createPortal(Box, document.body);
};

const buildFilteredNotifications = (notifications) => {
  let currentDate: string;
  const Store = store.getState().Entities;
  const notificationsStore = Store.SpacePopupNotifications;

  return notifications.reduce(
    (filters: any, notfId: any, currentIndex: any) => {
      const notf = notificationsStore[notfId];

      if (!currentDate || !getDiffInDays(currentDate, notf.Date)) {
        currentDate = notf.Date;

        filters.push({
          Date: notf.Date,
          notifications: [notfId]
        });
        return filters;
      }

      const filterNotifications = filters[filters.length - 1];
      filterNotifications.notifications.push(notfId);

      return filters;
    },
    []
  );
};

const NotificationBrowserContent = ({ openModal, isOpen }) => {
  const entitiesHub = useEntitiesHub();
  const pageRef = useRef(null);

  const [skip, setSkip] = useState(0);

  const resolvedUrl = useMemo(() => {
    if (!isOpen) return null;
    const baseUrl = `notifications/all/2?$top=20&$skip=${skip}&IsRead=0`;

    return `${baseUrl}`;
  }, [isOpen, skip]);

  const [response, setResponse] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const { loading, error } = useSpaceQuery(
    resolvedUrl,
    [spacePopupNotificationSchema],
    {
      cache: false,
      onSuccess: ({ data }: { data: [] }) => {
        if (data.length === 0) setHasMore(false);
        setResponse((r) => [...r, ...data]);
      }
    }
  );

  const fetchMore = useCallback(() => {
    setSkip((s) => s + 20);
  }, []);

  useEndlessScroll({
    listen: hasMore && !loading && !error,
    onFetchMore: fetchMore,
    offsettMultiplier: 0.5,
    offset: false,
    pageRef
  });

  const isCreationRef = useRef(false);
  const space = useSpace();

  const newMessageUrl = useCallback(
    (noteId) => {
      return `spaces/${space.Id}/Notifications/${noteId}`;
    },
    [space.Id]
  );

  const addNotification = useCallback(
    (message) => {
      if (response.findIndex((e) => e === message) === -1)
        setResponse((messages) => [message, ...messages]);
    },
    [response]
  );

  const [newMessageUrlFixed, setNewMessageUrlFixed] = useState(null);
  const { refetch } = useQuery(
    newMessageUrlFixed,
    spacePopupNotificationSchema,
    {
      cache: false,
      onSuccess: ({ data }) => {
        // const normalNote = getEntity(spacePopupNotificationSchema, data);

        addNotification(data);

        // if(!isCreationRef.current && normalNote.IsPinned){
        // 	const normalizedEntities = normalize({Id: normalNote.Id, IsPinned: normalNote.IsPinned}, spaceNoteSchema);
        // 	updateNormalizedSchemaCache(normalizedEntities.entities);
        // }
        setNewMessageUrlFixed(null);
      }
    }
  );

  const { Notifications } = useCurrentAccount();
  const playAudio = useSoundPlayer();

  useEffect(() => {
    if (!entitiesHub) return;
    let timeout;
    const listner = (id) => {
      const newUrlNewMessage = newMessageUrl(id);

      setNewMessageUrlFixed(newUrlNewMessage);

      if (!isOpen) {
        timeout = setTimeout(() => {
          if ((Notifications.Desktop.General & 2) === 2) openModal(true);
          if ((Notifications.Desktop.General & 8) === 8) playAudio();
        }, 100);
      } else {
        if ((Notifications.Desktop.General & 8) === 8) playAudio();
      }
    };

    entitiesHub.on("APIModalNotification", listner);

    return () => {
      timeout && clearTimeout(timeout);
      entitiesHub.off("APIModalNotification", listner);
    };
  }, [
    entitiesHub,
    isOpen,
    newMessageUrl,
    playAudio,
    openModal,
    Notifications.Desktop.General
  ]);

  const firstRenderAfterLoad = useRef(false);

  useEffect(() => {
    if (!loading && response.length > 0 && !firstRenderAfterLoad.current) {
      if (pageRef.current) pageRef.current.scrollTo(0, 0);
      firstRenderAfterLoad.current = true;
    }
  }, [loading, response.length]);

  const filteredNotifications = useMemo(() => {
    if (!response) return null;
    return buildFilteredNotifications(response);
  }, [response]);

  const buildBody = () => {
    if (response.length === 0 && loading) return <div></div>;
    else if (response.length === 0 && !error)
      return (
        <div style={{ marginTop: 20, textAlign: "center" }}>
          <FormattedMessage id={"EMPTY_NOTIFICATIONS"} />
        </div>
      );
    // return null

    return <FilteredNotifications values={filteredNotifications} />;
  };

  return (
    <div
      ref={pageRef}
      className="d-flex flex-column of-x-hidden position-relative of-y-auto flex-1"
    >
      {buildBody()}

      {loading && (
        <div className="d-flex justify-content-center text-primary mt-2 py-2 align-items-center">
          <LoaderSpinner size="xs" center />
        </div>
      )}
    </div>
  );
};

const RemWrapper = ({ ...rest }) => {
  // const translateTransformToRem = useCallback(
  //   (transform: string, remBaseline = 16) => {
  //     console.log(transform);
  //     const convertedValues = transform
  //       .replace("translate(", "")
  //       .replace(")", "")
  //       .split(",")
  //       .map((px) => px.replace("px", ""))
  //       .map((px) => parseInt(px, 10) / remBaseline)
  //       .map((x) => `${x}rem`);
  //     const [x, y] = convertedValues;
  //     // setUpdateTimer(`translate(${x}, ${y})`);
  //     return `translate(${x}, ${y})`;
  //   },
  //   []
  // );

  const { children, remBaseline = 16, style } = rest;
  const child = React.Children.only(children);

  const editedStyle = {
    ...child.props.style,
    ...style
  };

  return React.cloneElement(child, {
    ...child.props,
    ...rest,
    style: editedStyle
  });
};

// class RemWrapper extends React.Component<any> {
//   translateTransformToRem(transform: string, remBaseline = 16) {}

//   render() {
//     const { children, remBaseline = 16, style } = this.props as any;
//     const child = React.Children.only(children);

//     const editedStyle = {
//       ...child.props.style,
//       ...style,
//       transform: this.translateTransformToRem(style.transform, remBaseline)
//     };

//     return React.cloneElement(child, {
//       ...child.props,
//       ...this.props,
//       style: editedStyle
//     });
//   }
// }

const NotificationBrowserBoxContext = React.createContext(null);

export const useNotificationBrowserFunctions = () => {
  return useContext(NotificationBrowserBoxContext);
};

export const NotificationBrowserBoxProvider = ({ children }) => {
  const [boxState, setBoxState] = useState(false);

  const ContextVal = useMemo(() => {
    return { boxState, setBoxState };
  }, [boxState]);

  return (
    <NotificationBrowserBoxContext.Provider value={ContextVal}>
      <NotificationBrowserBox isOpen={boxState} setIsOpen={setBoxState} />
      {children}
    </NotificationBrowserBoxContext.Provider>
  );
};
