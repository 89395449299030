import React, {
  Component,
  useCallback,
  useContext,
  useRef,
  useState
} from "react";
import { Link, withRouter } from "react-router-dom";
import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import {
  withSpatialUser,
  withUser,
  //   useCurrentAccountSpace,
  useCurrentAccount,
  withHistory,
  useCurrentAccountSpace
} from "../Contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faBadgePercent,
  faBallot,
  faBook,
  faBracketsCurly,
  faDatabase,
  faEllipsisV,
  faEnvelope,
  faExchange,
  faFileInvoice,
  faLifeRing,
  faMobile,
  faServer,
  faSignOut,
  faTools,
  faUser,
  faUsers,
  faWaveSquare,
  faWindowAlt,
  faWindowRestore
} from "@fortawesome/pro-light-svg-icons";
import Popup, { usePopupOpenState } from "../Components/Popup/Popup";
import { AccountProfileImg } from "../Components/Img/Img";
import SubPopup from "../Components/Popup/SubPopup";
import { connect } from "react-redux";
import { withSpace } from "../Contexts/SpaceContext";
import { logout } from "../Helpers/AuthHelper";
// import { useAccountSpace } from "../Hooks/EntityHooks";
import { isDeveloper, isOwner, isSupport } from "../Helpers/ModulesHelper";
import Button from "../Components/Button/Button";
import {
  spaceBlockTypeEnum,
  BillingPlansEnum
} from "./BillingPlans/BillingPlanPayment";
import { Intl18nContext } from "./Intl18n/Intl18nProvider";
import Switch from "../Components/Switch/Switch";
import { useSpaceEntitiesHub } from "./RealTime/RealTimeSpace";
import { useDelete } from "../Helpers/IOClient";
import LoaderSpinner from "../Components/Loader/LoaderSpinner/LoaderSpinner";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { AppViewContext } from "./AppBar/AppBarHelper";
import { ViewsEnum } from "../Interfaces/EntityInterfaces";
import { useSidebar } from "../Components/Sidebar/SidebarV2";
import MobileVersionForm from "./MobileVersionControl/MobileVersionForm";
import { LogoutButton } from "./LogOutButton";

// import { getCurrentUser } from "../Redux/Actions/UserActions";

const AccountPopup = (props) => {
  const popupRef = useRef();
  const buttonRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, buttonRef);

  const showMenu = () => {
    toggleIsOpen(!isOpen);
  };
  const closeMenu = () => {
    toggleIsOpen(false);
  };

  const {
    user,
    space,
    intl,
    match,
    History,
    disableSpaceName,
    disableExtraActionButton,
    disableName,
    imageSize,
    placement,
    modifiers,
    style
  } = props;
  const [viewState, toggleViewState] = useContext(AppViewContext);
  return (
    <div className="AccountPopup-FullContainer w-100">
      <AccountPopupButton
        disableSpaceName={disableSpaceName}
        disableExtraActionButton={disableExtraActionButton}
        click={showMenu}
        domRef={buttonRef}
        account={user}
        disableName={disableName}
        space={space}
        imageSize={imageSize}
      />

      <AccountPopupContent
        placement={placement}
        space={space}
        style={style}
        match={match}
        modifiers={modifiers}
        isOpen={isOpen}
        viewState={viewState}
        toggleViewState={toggleViewState}
        oncloseMenu={closeMenu}
        intl={intl}
        History={History}
        domRef={popupRef}
        anchorEl={buttonRef.current}
        close={closeMenu}
        account={user}
      />
    </div>
  );
};

// class AccountPopup extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isOpened: false,
//       Account: {
//         Image:
//           "https://images.pexels.com/photos/1138903/pexels-photo-1138903.jpeg?auto=compress&cs=tinysrgb&h=650&w=940",
//         Name: "David Serôdio",
//         Email: "david.serodio@algardata.pt",
//       },
//     };
//     this.Popupcontainer = React.createRef();
//     this.buttonRef = React.createRef();
//     this.closeMenu = this.closeMenu.bind(this);
//   }

//   showMenu = (event) => {
//     ;
//     // event.preventDefault();
//     this.setState({ isOpened: true });
//     document.addEventListener("click", this.closeMenu);
//   };
//   closeMenu = (event) => {
//     if (this.Popupcontainer.current == null) return;

//     if (!this.Popupcontainer.current.contains(event.target)) {
//       this.setState({ isOpened: false }, () => {
//         document.removeEventListener("click", this.closeMenu);
//       });
//     }
//   };
//   forceClose = (event) => {
//     this.setState({ isOpened: false }, () => {
//       document.removeEventListener("click", this.closeMenu);
//     });
//   };

//   render() {
//     const { user, space, intl, match } = this.props;
//     const { isOpened } = this.state;

//     return (
//       <div className="AccountPopup-FullContainer">
//         <AccountPopupButton
//           click={this.showMenu}
//           domRef={this.buttonRef}
//           account={user}
//           space={space}
//         />

//         <AccountPopupContent
//           space={space}
//           match={match}
//           isOpen={isOpened}
//           oncloseMenu={this.closeMenu}
//           intl={intl}
//           History={this.props.History}
//           domRef={this.Popupcontainer}
//           anchorEl={this.buttonRef.current}
//           close={this.forceClose}
//           account={user}
//         />
//       </div>
//     );
//   }
// }

const AccountPopupLink = ({ children, ...rest }) => (
  <Link className="ssi-link py-2 px-4" {...rest}>
    {children}
  </Link>
);

// const AccountPopupLinkButton = ({ children, ...rest }) => (
//   <button className="ssi-link py-2 px-4" {...rest}>
//     {children}
//   </button>
// );

class AccountSubPopupButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.buttonRef = React.createRef();
  }

  handleMouseEnter = (e) => {
    this.setState({
      isOpen: true
    });
  };
  handleMouseLeave = (e) => {
    this.setState({
      isOpen: false
    });
  };

  render() {
    const { isOpen } = this.state;
    const { disablePopup, text, className, children } = this.props;
    let classes =
      "ssi-link d-flex align-items-center py-2 air-rounded-selection";

    if (isOpen) classes += ` selected`;
    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.props.onClick && this.props.onClick}
      >
        <div ref={this.buttonRef} className={classes}>
          <span className="px-4 flex-1">{text}</span>
          <FontAwesomeIcon icon={faAngleRight} className="text-primary mr-4" />
        </div>
        <SubPopup
          // domRef={rref}
          className={`account-popup ${className ? className : ""}`}
          isOpen={!disablePopup && isOpen}
          anchorEl={this.buttonRef.current}
          placement={"left"}
        >
          {children}
        </SubPopup>
      </div>
    );
  }
}

function compare(a, b) {
  if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
    return -1;
  }
  if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
    return 1;
  }
  return 0;
}

class AccountSpaceSelectorComp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { AccountSpaces, Spaces, userAccountSpace, user } = this.props;
    const { AccountSpaces: userSpaces } = user;

    const resolvedSpaceObjs = userSpaces.map((accountSpaceId) => {
      let accountSpace = AccountSpaces[accountSpaceId];
      return Spaces[accountSpace.Space];
    });

    resolvedSpaceObjs.sort(compare);

    return (
      <React.Fragment>
        {resolvedSpaceObjs.map((space) => {
          // let accountSpace = AccountSpaces[accountSpaceId];
          // let space = Spaces[accountSpace.Space];
          // ////
          if (userAccountSpace.Space.Id === space.Id)
            return (
              <div key={space.Id} className="ssi-link py-2 px-4 selected">
                {space.Name}
              </div>
            );
          else
            return (
              <a
                className="ssi-link py-2 air-rounded-selection px-4"
                onClick={(e) => {
                  e.stopPropagation();
                  localStorage.setItem("Airdesk-Previous-Space", space.Id);
                  this.oncloseMenu && this.oncloseMenu();
                }}
                href={`/s/${space.Id}/dashboard`}
                key={space.Id}
              >
                {space.Name}
              </a>
            );
          // return (
          //   <Link
          //     className="ssi-link py-2 px-4"
          //     onClick={this.oncloseMenu}
          //     to={`/s/${space.Id}`}
          //     key={space.Id}
          //   >
          //     {space.Name}
          //   </Link>
          // );
        })}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AccountSpaces: state.Entities.AccountSpaces,
    Spaces: state.Entities.Spaces
  };
};

export const AccountSpaceSelector = withSpatialUser(
  connect(mapStateToProps)(AccountSpaceSelectorComp)
);

const DevLinks = ({ match, close }) => {
  var account = useCurrentAccount();
  const [openSidebar] = useSidebar();
  const dev = isDeveloper(account);
  const support = isSupport(account);

  const handleServerStatusClick = () => {
    openSidebar(<MobileVersionForm />);
  };

  if (dev) {
    return (
      <>
        <span className="my-2 fs-12 mx-2">Developer</span>
        <AccountPopupLink to={`${match.url}/usersControl`} onClick={close}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              style={{ width: 15 }}
              icon={faUsers}
              className="mr-2"
            />
            Utilizadores
          </div>
        </AccountPopupLink>
        <AccountPopupLink to={`${match.url}/cache`} onClick={close}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              style={{ width: 15 }}
              icon={faDatabase}
              className="mr-2"
            />
            Cache
          </div>
        </AccountPopupLink>
        <AccountPopupLink to={`${match.url}/logs`} onClick={close}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              style={{ width: 15 }}
              icon={faServer}
              className="mr-2"
            />
            Logs
          </div>
        </AccountPopupLink>
        <AccountPopupLink to={`${match.url}/spaceList`} onClick={close}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              style={{ width: 15 }}
              icon={faWindowRestore}
              className="mr-2"
            />
            Espaços
          </div>
        </AccountPopupLink>
        <AccountPopupLink to={`${match.url}/queues`} onClick={close}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              style={{ width: 15 }}
              icon={faWaveSquare}
              className="mr-2"
            />
            Queues
          </div>
        </AccountPopupLink>
        <AccountPopupLink to={`${match.url}/cupons`} onClick={close}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              style={{ width: 15 }}
              icon={faBadgePercent}
              className="mr-2"
            />
            Cupões
          </div>
        </AccountPopupLink>
        <AccountPopupLink to={`${match.url}/maintenance`} onClick={close}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              style={{ width: 15 }}
              icon={faTools}
              className="mr-2"
            />
            Manutenção
          </div>
        </AccountPopupLink>
        <div className="ssi-link py-2 px-4" onClick={handleServerStatusClick}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              style={{ width: 15 }}
              icon={faMobile}
              className="mr-2"
            />
            Versões Mobile
          </div>
        </div>
      </>
    );
  } else if (support) {
    return (
      <>
        <span className="my-2 fs-12 mx-2">Support</span>
        <AccountPopupLink to={`${match.url}/spaceList`} onClick={close}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              style={{ width: 15 }}
              icon={faWindowRestore}
              className="mr-2"
            />
            Espaços
          </div>
        </AccountPopupLink>
      </>
    );
  } else return null;
};

const OwnerLinks = ({ match, close, History, space, intl }) => {
  const accountSpace = useCurrentAccountSpace();
  if (space.IsManualPayment) return null;

  if (!isOwner(accountSpace)) return null;

  return (
    <>
      <span className="fs-12 mx-2">
        <FormattedMessage id={"ACTIVE_WORKSPACE"} />
      </span>
      <AccountPopupLink to={`/s/${space.Id}/workspace`} onClick={close}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
            {intl.formatMessage({ id: "SUBSCRIPTIONS" })}
          </div>
          <Button
            propStyle={{ height: 24 }}
            size="sm"
            className="ml-4 fs-12"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              History.push(`${match.url}/plans`);
              close();
            }}
            vType="primary-ghost"
          >
            {space.TrialEnd
              ? intl.formatMessage({ id: "UPGRADE_PLAN" })
              : !space.TrialEnd && !space.CurrentPlan
              ? intl.formatMessage({ id: "UPGRADE_PLAN" })
              : space.BlockType === spaceBlockTypeEnum.Canceled
              ? intl.formatMessage({ id: "UPGRADE_PLAN" })
              : space.CurrentPlan === BillingPlansEnum.company
              ? intl.formatMessage({ id: "PLANS" })
              : intl.formatMessage({ id: "UPGRADE_PLAN" })}
          </Button>
        </div>
      </AccountPopupLink>
    </>
  );
};

const AccountHelpToggler = ({ oncloseMenu }) => {
  const [status, setStatus] = useState(window.$airdesk.status || false);
  const intl = useIntl();

  const handleChange = (e) => {
    const { checked } = e.target;
    setStatus(checked);
    var { $airdesk } = window;
    if ($airdesk) {
      if (checked) {
        $airdesk.visible();
        $airdesk.status = true;
        $airdesk.open();
      } else {
        $airdesk.hidden();
        $airdesk.status = false;
      }
    }
    oncloseMenu();
  };

  const handleNameClick = () => {
    var { $airdesk } = window;
    if ($airdesk) {
      if (!status) {
        $airdesk.visible();
        $airdesk.status = true;
        $airdesk.open();
        setStatus(true);
      } else {
        $airdesk.hidden();
        $airdesk.status = false;
        setStatus(false);
      }
    }
    oncloseMenu();
  };

  return (
    <div className="ssi-link d-flex align-items-center py-2 px-4">
      <div
        className="d-flex align-items-center mr-3 flex-1"
        onClick={handleNameClick}
      >
        <FontAwesomeIcon
          style={{ width: 15 }}
          icon={faEnvelope}
          className="mr-2"
        />
        {intl.formatMessage({ id: "REQUEST_HELP" })}
      </div>
      <Switch className="sm" checked={status} onChange={handleChange} />
    </div>
  );
};
class AccountPopupContent extends Component {
  static contextType = Intl18nContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      intl,
      account,
      domRef,
      anchorEl,
      isOpen,
      space,
      History,
      oncloseMenu,
      match,
      modifiers,
      style,
      viewState,
      toggleViewState,
      offsets,
      placement
      // close
    } = this.props;
    const { Name, Email } = account;

    const handleClickForSpaceList = () => {
      History.push("/s");
    };

    return (
      <Popup
        className="account-popup disable-selection"
        domRef={domRef}
        isOpen={isOpen}
        modifiers={modifiers}
        anchorEl={anchorEl}
        style={style}
        offsets={offsets}
        placement={placement || "bottom-end"}
      >
        <div style={{ padding: 8 }}>
          <div className="d-flex px-4 py-3">
            <AccountProfileImg account={account} circular className="mr-2 " />
            <div className="d-flex flex-column justify-content-center">
              <span className="fs-14 fw-medium text-black">{Name}</span>
              <span className="fs-12">{Email}</span>
            </div>
          </div>
          <hr className="mt-0 mb-2 horizontal-splitter" />
          <span className="fs-12 mx-2">
            <FormattedMessage id={"USER"} />
          </span>
          <AccountPopupLink
            className="ssi-link py-2 px-4 air-rounded-selection"
            to={`${match.url}/account`}
            onClick={this.props.close}
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                style={{ width: 15 }}
                icon={faUser}
                className="mr-2"
              />
              {intl.formatMessage({ id: "ACCOUNT_PROFILE" })}
            </div>
          </AccountPopupLink>
          <AccountSubPopupButton
            disablePopup={!isOpen}
            className="py-3 "
            onClick={handleClickForSpaceList}
            text={
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  style={{ width: 15 }}
                  icon={faExchange}
                  className="mr-2"
                />
                <FormattedMessage id={"CHANGE_WORKSPACE"} />
              </div>
            }
          >
            <AccountSpaceSelector oncloseMenu={oncloseMenu} user={account} />
          </AccountSubPopupButton>

          <AccountSubPopupButton
            disablePopup={!isOpen}
            className="py-3"
            text={
              <div className="d-flex flex-1 align-items-center">
                <FontAwesomeIcon
                  style={{ width: 15 }}
                  icon={faBallot}
                  className="mr-2"
                />
                <FormattedMessage id={"MENU_LAYOUT"} />
              </div>
            }
          >
            <div className="d-flex flex-column">
              <Button
                onClick={() => {
                  viewState !== ViewsEnum.basic && toggleViewState();
                }}
                className="px-4 mb-2"
                vType="link"
              >
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    style={{ width: 15 }}
                    icon={faWindowAlt}
                    className="mr-2"
                  />
                  {intl.formatMessage({ id: "HORIZONTAL" })}
                  {viewState === ViewsEnum.basic && (
                    <div className="d-flex text-green justify-content-end flex-1 align-items-center">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="mr-2"
                        size="lg"
                      />
                    </div>
                  )}
                </div>
              </Button>
              <Button
                className="px-4"
                onClick={() => {
                  viewState !== ViewsEnum.advanced && toggleViewState();
                }}
                vType="link"
              >
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    style={{ width: 15 }}
                    icon={faWindowAlt}
                    className="mr-2"
                    rotation={270}
                  />
                  {intl.formatMessage({ id: "VERTICAL" })}
                  {viewState === ViewsEnum.advanced && (
                    <div className="d-flex text-green justify-content-end flex-1 align-items-center">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="mr-2"
                        size="lg"
                      />
                    </div>
                  )}
                </div>
              </Button>
            </div>
          </AccountSubPopupButton>

          <AccountSubPopupButton
            disablePopup={!isOpen}
            className="py-3"
            // onClick={handleClickForSpaceList}
            text={
              <div className="d-flex flex-1 align-items-center">
                <FontAwesomeIcon
                  style={{ width: 15 }}
                  icon={faLifeRing}
                  className="mr-2"
                />
                <FormattedMessage id={"HELP"} />
              </div>
            }
          >
            <AccountPopupLink
              className="ssi-link py-2 px-4 air-rounded-selection"
              to={`${match.url}/welcome`}
              onClick={this.props.close}
            >
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  style={{ width: 15 }}
                  icon={faBallot}
                  className="mr-2"
                />
                {intl.formatMessage({ id: "WELCOME_VIEW_LINK" })}
              </div>
            </AccountPopupLink>
            <a
              href={
                this.context.value.Value === "pt"
                  ? `https://docs.airdesk.ai/`
                  : "https://en.docs.airdesk.ai/"
              }
              target="_blank"
              rel="noopener noreferrer"
              className="ssi-link d-flex align-items-center py-2 px-4"
              // onClick={this.props.close}
            >
              <FontAwesomeIcon
                style={{ width: 15 }}
                icon={faBook}
                className="mr-2"
              />
              {intl.formatMessage({ id: "DOCUMENTATION" })}
            </a>
            <a
              href="https://airdesk.stoplight.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="ssi-link d-flex align-items-center py-2 px-4"
              // onClick={this.props.close}
            >
              <FontAwesomeIcon
                style={{ width: 15 }}
                icon={faBracketsCurly}
                className="mr-2"
              />
              {intl.formatMessage({ id: "API_REFERENCE" })}
            </a>
            <AccountHelpToggler oncloseMenu={oncloseMenu} />
          </AccountSubPopupButton>

          {/* <a
          href={`https://www.airdesk.ai/videos/`}
          target="_blank"
          rel="noopener noreferrer"
          className="ssi-link py-2 px-4"
          onClick={this.props.close}
        >
          {intl.formatMessage({ id: "VIDEOS" })}
        </a> */}

          {/* <AccountPopupLinkButton
          onClick={() => {
            close();
            window.hj && window.hj("trigger", "provide_feedback");
          }}
        >
          <FormattedMessage id={"FEEDBACK"} />
        </AccountPopupLinkButton> */}
          <OwnerLinks
            intl={intl}
            match={match}
            close={this.props.close}
            space={space}
            History={History}
          />
          <DevLinks intl={intl} match={match} close={this.props.close} />
          <hr className="my-2 horizontal-splitter" />
          <div className="h-100 mb-2 Logout-button">
            <LogoutButton />
          </div>
        </div>
      </Popup>
    );
  }
}

class AccountPopupButton extends Component {
  render() {
    const {
      account,
      space,
      domRef,
      disableSpaceName,
      imageSize,
      disableName,
      disableExtraActionButton
    } = this.props;

    const spaceName = space.Name;

    return (
      <button
        ref={domRef}
        // style={{ padding: 8 }}
        className="AccountPopup-Button w-100 position-relative of-hidden d-flex align-items-center text-white"
        onClick={this.props.click}
      >
        <div
          style={{
            width: 40,
            height: 40
          }}
          className="flex-0-0-auto d-flex align-items-center justify-content-center"
        >
          <AccountProfileImg
            account={account}
            circular
            size={imageSize ? imageSize : "sm"}
            // className="mr-2"
          />
        </div>
        {!disableName && (
          <div
            style={{ color: "#ddddf7" }}
            className="fw-medium flex-1 text-truncate fs-14 d-flex justify-content-start ml-12"
          >
            {account.Name}
          </div>
        )}
        {!disableExtraActionButton && (
          <div
            style={{ width: 32 }}
            className="ml-3 d-flex justify-content-center ActionButtonNewColorDefault flex-0-0-auto"
          >
            <FontAwesomeIcon size="lg" icon={faEllipsisV} />
          </div>
        )}
        {/* <img
          alt=""
          className="AccountPopup-Image mr-3"
          src={this.props.account.Image}
        /> */}
        {!disableSpaceName && (
          <div className="d-flex flex-column align-items-start account-button-container ml-2">
            <span className="account-button-space">{spaceName}</span>
          </div>
        )}
        {/* <FontAwesomeIcon icon={faAngleDown} className="ml-4" /> */}
      </button>
    );
  }
}

// class LogoutButtonComp extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
// 	}

//   render() {
//     const { Logout, intl } = this.props;
//     return (
//       <div
//         className="ar-logout-button d-flex align-items-center w-100 h-100 pb-2 pt-2 cursor-pointer"
//         onClick={Logout}
//       >
//         <FontAwesomeIcon
//           style={{ width: 15 }}
//           icon={faSignOut}
//           className="mr-2"
//         />
//         {intl.formatMessage({ id: "LOG_OUT" })}
//       </div>
//     );
//   }
// }

export default injectIntl(
  withUser(withRouter(withHistory(withSpace(AccountPopup))))
);
