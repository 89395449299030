import React, { useMemo } from "react";
import { faFileContract } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContract } from "../../Hooks/EntityHooks";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import {
  CompanyActiveStatusDot,
  ClosedStatusDot,
  CanceledStatusDot,
  DealsCanceledStatusDot,
  ContractsClosedStatusDot,
  DealsDraftStatusDot,
  SocialPostsDraftStatusDot,
  DealsWonStatusDot
} from "../StatusHelper/StatusHelper";

export const buildContractStatusType = (status = "") => {
  switch (status.toString()) {
    case "2":
      return "black";
    case "3":
      return "grey";
    case "5":
      return "orange";
    case "6":
      return "yellow";
    case "7":
      return "success";
    default:
      return "purple";
  }
};

const statusNameByValue = (status) => {
  switch (status.toString()) {
    case "1":
      return <FormattedMessage id={"ACTIVE"} />;
    case "2":
      return <FormattedMessage id={"INACTIVE"} />;
    case "3":
      return <FormattedMessage id={"CANCELLED"} />;
    case "5":
      return <FormattedMessage id={"REVIEW"} />;
    case "6":
      return <FormattedMessage id={"DRAFT"} />;
    case "7":
      return <FormattedMessage id={"CONCLUDED"} />;
    default:
      return null;
  }
};

export const ContractStatusTypes = {
  open: 1,
  closed: 2,
  canceled: 3,
  draft: 5,
  staging: 6,
  renovated: 7
};

export const ContractStatusProfileWithIcon = ({
  entity,
  status,
  Icon,
  item,
  value,
  hideName,
  inline,
  className,
  size = 24
}) => {
  const resovledStatus = entity ? entity.Status : status || item || value;

  // if (!resovledStatus) return null;

  return (
    <div
      className={classnames(
        `link-color status-color-${buildContractStatusType(resovledStatus)}`,
        className,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div
        style={{ width: size, height: size }}
        className={`d-flex align-items-center justify-content-around position-relative text-truncate status-dot m-0 status-dot-background-template`}
      >
        {Icon || <FontAwesomeIcon icon={faFileContract} />}
      </div>
      {!hideName && (
        <div className="status-label fw-medium fs-12">
          {statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

export const ContractStatusSvgIcons = (status = "") => {
  switch (status.toString()) {
    case "2":
      return <ContractsClosedStatusDot />;
    case "3":
      return <DealsCanceledStatusDot />;
    case "5":
      return <DealsDraftStatusDot />;
    case "6":
      return <SocialPostsDraftStatusDot />;
    case "7":
      return <DealsWonStatusDot />;
    default:
      return <CompanyActiveStatusDot />;
  }
};

export const ContractStatusProfile = ({
  entity,
  status,
  item,
  value,
  hideName,
  inline,
  className,
  pipelineName,
  dotClassName
}) => {
  const resovledStatus = entity ? entity.Status : status || item || value;
  const SvgComponent = useMemo(() => {
    if (!resovledStatus) return null;
    return ContractStatusSvgIcons(resovledStatus);
  }, [resovledStatus]);

  if (!resovledStatus) return null;

  return (
    <div
      className={classnames(
        `link-color flex-0-0-auto text-truncate status-color-${buildContractStatusType(
          resovledStatus
        )}`,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div
        className={classnames("position-relative status-dot-svg", dotClassName)}
      >
        {SvgComponent}
      </div>
      {!hideName && (
        <div className="status-label text-truncate fs-12">
          {pipelineName || statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

const ContractProfile = ({ contract }) => {
  if (!contract) return null;
  return (
    <div className="d-flex align-items-center link-color text-truncate">
      <div className="position-relative">
        <FontAwesomeIcon className="mr-1" icon={faFileContract} />
      </div>
      <div>{contract.Description}</div>
    </div>
  );
};

export default ContractProfile;

export const ConnectContractEntity = (Component) => {
  return ({ contract, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const c = useContract(contract);
    return <Component contract={c} {...rest} />;
  };
};

export const DifrentContractProfile = ({ contract }) => {
  if (!contract) return null;
  const { Status } = contract;

  let colorStatus;

  switch (Status) {
    case ContractStatusTypes.open:
      colorStatus = "status-in-progress-colors";
      break;
    case ContractStatusTypes.canceled:
      colorStatus = "status-canceled-colors";
      break;
    case ContractStatusTypes.draft:
      colorStatus = "status-orange-colors";
      break;
    case ContractStatusTypes.renovated:
      colorStatus = "status-closed-colors";
      break;
    case ContractStatusTypes.closed:
      colorStatus = "status-ended-colors";
      break;
    case ContractStatusTypes.staging:
      colorStatus = "status-yellow-colors";
      break;
    default:
      break;
  }

  return (
    <div className={classnames("air-new-profiles-circle", colorStatus)}>
      <FontAwesomeIcon icon={faFileContract} size="xl" />
    </div>
  );
};

export const ConnectedContractProfile = ConnectContractEntity(ContractProfile);
