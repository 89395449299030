import { useEffect, useState } from "react";
import { Communicator } from "../../Helpers/Communicator";

const TimeTrackingStorageKey = "ar-time-tracking";

let inMemoryTimeTrackingState = {
  timeTrackers: [],
  multipleTracking: false,
  playingCount: 0
};
let hasCheckedLocalStorage = false;

const TimeTrackingCommunicator = new Communicator();

export const useTimeTrackingState = () => {
  const [state, setState] = useState(getTimeTrackingState);

  useEffect(() => {
    return TimeTrackingCommunicator.subscribe(() =>
      setState(getTimeTrackingState())
    );
  }, []);

  return state;
};

export const getTimeTrackingState = () => {
  if (!hasCheckedLocalStorage) {
    hasCheckedLocalStorage = true;
    const json = localStorage.getItem(TimeTrackingStorageKey);
    if (json) inMemoryTimeTrackingState = JSON.parse(json);
  }

  return inMemoryTimeTrackingState;
};

const toggleTimeTracker = timeTracker => {
  const newDate = new Date();
  const { isPaused, date } = timeTracker;
  let { pauses, elapsedTime } = timeTracker;

  //will pause
  if (!isPaused) {
    elapsedTime += newDate.getTime() - new Date(date).getTime();
  } else {
    pauses += newDate.getTime() - new Date(date).getTime();
  }
  timeTracker = {
    ...timeTracker,
    isPaused: !timeTracker.isPaused,
    date: newDate,
    pauses,
    elapsedTime
  };

  return timeTracker;
};

export const toggleTimeTrackerState = key => {
  const timeTrackerState = { ...getTimeTrackingState() };
  const { timeTrackers, multipleTracking } = timeTrackerState;
  let { playingCount } = timeTrackerState;

  const timeTrackerIndex = timeTrackers.findIndex(v => v.key === key);

  let timeTracker = timeTrackers[timeTrackerIndex];

  //will unpause and does not have multiple tracking enabled
  //needs to stop other timeTrackings
  if (timeTracker.isPaused && !multipleTracking) {
    for (const index in timeTrackers) {
      if (timeTrackers.hasOwnProperty(index)) {
        const tracker = timeTrackers[index];
        if (!tracker.isPaused) {
          playingCount--;
          timeTrackers[index] = toggleTimeTracker(tracker);
        }
      }
    }
  }

  if (timeTracker.isPaused) playingCount++;
  else playingCount--;

  timeTracker = toggleTimeTracker(timeTracker);
  timeTrackers[timeTrackerIndex] = timeTracker;

  timeTrackerState.timeTrackers = [...timeTrackers];
  timeTrackerState.playingCount = playingCount;

  saveStorage(timeTrackerState);

  return { state: timeTrackerState, timeTracker };
};

export const updateTimeTrackingValues = (key, update) => {
  const timeTrackerState = { ...getTimeTrackingState() };
  const { timeTrackers } = timeTrackerState;

  const index = timeTrackers.findIndex(v => v.key === key);
  let timeTracker = timeTrackers[index];

  timeTracker = {
    ...timeTracker,
    values: {
      ...timeTracker.values,
      ...update
    }
  };

  timeTrackers[index] = timeTracker;

  timeTrackerState.timeTrackers = [...timeTrackers];

  saveStorage(timeTrackerState);

  return timeTrackerState;
};

export const removeTimeTracker = key => {
  const timeTrackerState = { ...getTimeTrackingState() };
  const { timeTrackers } = timeTrackerState;
  let { playingCount } = timeTrackerState;
  const index = timeTrackers.findIndex(v => v.key === key);
  const timeTracker = timeTrackers[index];

  if (!timeTracker.isPaused) playingCount--;

  timeTrackers.splice(index, 1);

  timeTrackerState.timeTrackers = [...timeTrackers];
  timeTrackerState.playingCount = playingCount;
  saveStorage(timeTrackerState);

  return timeTrackerState;
};

export const addTimeTracker = (Origin) => {
  const creationDate = new Date();

  const timeTrack = {
    key: creationDate.getTime(),
    creationDate,
    date: creationDate,
    isPaused: false,
    pauses: 0,
    elapsedTime: 0,
    values: {
      internalDescription: "",
			InterventionTarget: Origin
    }
  };

  let timeTrackingState = {...getTimeTrackingState()};

  const { timeTrackers, multipleTracking } = timeTrackingState;
  let { playingCount } = timeTrackingState;
  if (!multipleTracking) {
    for (const index in timeTrackers) {
      if (timeTrackers.hasOwnProperty(index)) {
        const tracker = timeTrackers[index];
        if (!tracker.isPaused) {
          playingCount--;
          timeTrackers[index] = toggleTimeTracker(tracker);
        }
      }
    }
  }

  playingCount++;
  timeTrackingState.timeTrackers = [timeTrack, ...timeTrackers];
  timeTrackingState.playingCount = playingCount;
  saveStorage(timeTrackingState);
  return timeTrackingState;
};

const saveStorage = state => {
  ////;
  const json = JSON.stringify(state);
  inMemoryTimeTrackingState = state;
  localStorage.setItem(TimeTrackingStorageKey, json);
  TimeTrackingCommunicator.dispatch();
};

export const toggleMultipleTracking = () => {
  let timeTrackingState = { ...getTimeTrackingState() };

  const { multipleTracking, timeTrackers } = timeTrackingState;
  let { playingCount } = timeTrackingState;
  if (multipleTracking) {
    let hasOneActiveTracker = false;
    for (const index in timeTrackers) {
      if (timeTrackers.hasOwnProperty(index)) {
        const tracker = timeTrackers[index];
        if (!tracker.isPaused) {
          if (!hasOneActiveTracker) {
            hasOneActiveTracker = true;
            continue;
          }
          playingCount--;
          timeTrackers[index] = toggleTimeTracker(tracker);
        }
      }
    }
    timeTrackingState.timeTrackers = [...timeTrackers];
    timeTrackingState.playingCount = playingCount;
    timeTrackingState.multipleTracking = false;
  } else {
    timeTrackingState.multipleTracking = true;
  }

  saveStorage(timeTrackingState);

  return timeTrackingState;
};
