

export const HandleQueryStringObjectMaker = (oldQueryString) => {
	return {
		$filter: oldQueryString["$filter"],
		$orderBy: oldQueryString["$orderBy"],
		$inlineCount: oldQueryString["$inlineCount"],
		$skip: oldQueryString["$skip"],
		$top: oldQueryString["$top"],
		viewPref: oldQueryString["viewPref"],
		favoriteId: oldQueryString["favoriteId"],
		query: oldQueryString["query"],
		entityNoteType: oldQueryString["entityNoteType"],
		conversationTypes: oldQueryString["conversationTypes"],
		basicFilter: oldQueryString["basicFilter"],
		advancedFilters: oldQueryString["advancedFilters"],
		pipeline: oldQueryString["pipeline"],
		view: oldQueryString["view"],
		groupBy: oldQueryString["groupBy"]
	};
}