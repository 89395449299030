import { html } from "@codemirror/lang-html";
import { EditorView } from "@codemirror/view";
import { html_beautify } from "js-beautify";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { CodeMirrorComponent } from "../../Containers/CodeMirrorReact/CodeMirrorReact";

// @ts-nocheck
// require("codemirror/lib/codemirror.css");
// require("codemirror/theme/neat.css");
// require("codemirror/mode/xml/xml.js");
// require("codemirror/mode/javascript/javascript.js");

interface CodeMirrorProps {
  text: any;
  onChange: (e: any) => void;
}

const DocTyperCodeMirror: FunctionComponent<CodeMirrorProps> = ({
  text,
  onChange
}) => {
  const containerRef = useRef() as any;
  const [height, setHeight] = useState(undefined);

  useEffect(() => {
    if (containerRef.current) {
      const size = containerRef.current?.getBoundingClientRect();
      setHeight(size.Height);
    }
  }, []);

  return (
    <div ref={containerRef} className="flex-1 position-relative overflow-auto">
      <CodeMirrorComponent chosenExtensions={[html({matchClosingTags: true, autoCloseTags: true }), EditorView.lineWrapping]} height={height} onChange={onChange} value={text} />
    </div>
  );
};

// const DocTyperCodeMirrorOld: FunctionComponent<CodeMirrorProps> = ({
//   text,
//   onChange
// }) => {
//   const containerRef = useRef() as any;
//   const editorRef = useRef() as any;

//   useEffect(() => {
//     if (editorRef.current) {
//       const size = containerRef.current?.getBoundingClientRect();

//       editorRef.current.setSize(size?.width, size?.height);
//       editorRef.current.refresh();
//     }

//   }, []);

//   return (
//     <div ref={containerRef} className="flex-1 position-relative">
//       <CodeMirror
//         // className={"h-auto"}
//         value={text}
//         options={{
//           lineNumbers: true,
//           readOnly: false,
//           mode: "xml",
//           htmlMode: true
//           // viewportMargin: "Infinity",
//         }}
//         editorDidMount={(editor) => {
//           editorRef.current = editor;

//           let editorContent = editor.getValue();
//           editorContent = html_beautify(editorContent, {
//             indent_with_tabs: true
//           });
//           editor.setValue(editorContent);
//           editor.refresh();
//         }}
//         onBeforeChange={(editor, data, value) => {
//           onChange(value);
//         }}
//         onChange={(editor, data, value) => {}}
//       />
//     </div>
//   );
// };

export default DocTyperCodeMirror;
