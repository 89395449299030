import React, { useCallback, useMemo, useState } from "react";
import {
  EntitySummaryTabstrip,
  EntitySummaryTab,
  EntitySummaryOverview,
  EntitySummaryAccordion,
  DescriptionOverview,
  EntitySummaryFilesAccordion,
  EntityPipelineHistoryAccordion
} from "../../Components/EntitySummaries/EntitySummaries";
import { History } from "../../Components/DetailsView/History/History";
import { spaceTasksSchema } from "../../config/schema";
import TaskForm from "./TaskForm";
import {
  TaskEntityActionsButton,
  useResolvedToDos,
  TasksStatusTypes
} from "./TasksHelper";
import { faAnalytics, faCity, faUsers } from "@fortawesome/pro-solid-svg-icons";
import {
  EntityOverviewItem,
  EntityDescriptionOverviewItem,
  EntityOverviewRoundedItem,
  EntityOverviewChildrenProgress,
  EntityOverviewPlaceholder
} from "../../Components/EntityOverview/EntityOverview";
import {
  faInfoSquare,
  faLink,
  faFileAlt,
  faCalendarAlt,
  faSuitcase,
  faUserPlus,
  faUserCog,
  faBell,
  faUserTie,
  faDrawCircle
} from "@fortawesome/pro-light-svg-icons";
import OriginProfile from "../Origin/OriginProfile";
import { GridColloredEndDateComponent } from "../../Helpers/GridHelper";
import RelativeTime from "../../Components/RelativeTime/RelativeTime";
import FormattedTimePreview from "../../Components/FormattedTimePreview/FormattedTimePreview";
import { EntityOverviewPipeline } from "../../Components/EntityOverview/EntityOverviewPipeline";
import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
import Checklist from "../../Components/Checklist/Checklist";
import Treeview from "../Treeview/Treeview";
import { ConnectedAccountProfileStack } from "../../Components/Accounts/AccountProfileStack";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import { RealTimeRoomTypes } from "../RealTime/RealTimeHelper";
import { FormattedMessage } from "react-intl";
import { TaskFormButton } from "../Form/FormButtons";
import {
  TaskDetailsEmailConversations,
  TaskDetailsChildren
} from "./TaskDetails/TaskDetailsChildren";
import { getInterventionTarget } from "../../Helpers/FormFormmaterHelper";
import { ConnectedContactPopup } from "../Origin/ContactPopup";
import TaskConflicts from "./TaskConflicts";
import {
  usePipelineStatus,
  useCancelationReason
} from "../../Hooks/EntityHooks";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { canUserReadModule, moduleTypes } from "../../Helpers/ModulesHelper";

export const TaskSummaryContent = ({ value }) => {
  const {
    Pipeline,
    PipelineStatus,
    StatusDetail,
    Manager,
    EstimatedCost,
    BeginDate,
    Contact,
    EndDate,
    ToDos,
    NoTagInternalDescription,
    InternalDescription,
    Creator,
    Files,
    Client,
    StatusDetailDescription,
    IndirectClient,
    Id,
    CanEdit,
    IsFollowing,
    Followers
  } = value;

  const origin = useMemo(() => getInterventionTarget(value), [value]);

  const resolvedToDos = useResolvedToDos(ToDos);
  const [todos, setTodos] = useState(resolvedToDos);
  const handleToDoChange = useCallback((newTodos) => {
    setTodos(newTodos);
  }, []);

  const pipelineStatusObj = usePipelineStatus(PipelineStatus);
  const detail = useCancelationReason(StatusDetail);

  return (
    <>
      <EntitySummaryAccordion icon={faAnalytics} textId="STATUS">
        <EntityOverviewPipeline
          pipeline={Pipeline}
          pipelineStatus={PipelineStatus}
          statusDetail={StatusDetail}
        />

        {StatusDetail &&
          pipelineStatusObj.Status === TasksStatusTypes.canceled && (
            <EntityOverviewRoundedItem
              icon={faDrawCircle}
              titleId="CANCELATION_REASON"
            >
              {detail.Name}
            </EntityOverviewRoundedItem>
          )}
        {StatusDetailDescription &&
          pipelineStatusObj.Status === TasksStatusTypes.canceled && (
            <EntityDescriptionOverviewItem
              icon={faDrawCircle}
              titleId="CANCELATION_DESCRIPTION"
            >
              <DescriptionOverview
                Description={StatusDetailDescription}
                NoTagDescription={StatusDetailDescription}
              />
            </EntityDescriptionOverviewItem>
          )}

        <EntityOverviewItem icon={faCalendarAlt} titleId="CONCLUSION">
          <GridColloredEndDateComponent Data={value} />
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faInfoSquare} textId={"DETAILS"}>
        <EntityOverviewItem icon={faLink} titleId="ASSIGNED_TO">
          <OriginProfile dynamicFont origin={origin} />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faCalendarAlt} titleId="ESTIMATED_TIME">
          <FormattedTimePreview
            value={EstimatedCost}
            className="px-2 py-1 ar-rounded-badge"
            asHourMinute
          />
        </EntityOverviewItem>
        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="START">
          <RelativeTime date={BeginDate} />
        </EntityOverviewRoundedItem>
        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="END">
          <RelativeTime date={EndDate} />
        </EntityOverviewRoundedItem>

        {Manager && (
          <TaskConflicts
            taskId={Id}
            user={Manager}
            beginDate={BeginDate}
            endDate={EndDate}
          />
        )}

        <EntityOverviewChildrenProgress schema={spaceTasksSchema} id={Id} />
        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="INTERNAL_NOTE">
          <DescriptionOverview
            Description={InternalDescription}
            NoTagDescription={NoTagInternalDescription}
          />
        </EntityDescriptionOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faCity} textId="ASSOCIATED">
        <EntityOverviewItem icon={faSuitcase} titleId="ACCOUNT">
          <ConnectedClientOrigin
            dynamicFont
            client={Client || IndirectClient}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUserTie} titleId="CONTACT">
          {Contact ? (
            <ConnectedContactPopup
              placement="right"
              dynamicFont
              contact={Contact}
            />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faUsers} textId="ASSIGNED_TO">
        <EntityOverviewItem icon={faUserPlus} titleId="CREATED_BY">
          <ConnectedAccountPopup
            placement="right"
            dynamicFont
            account={Creator}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUserCog} titleId="ASSIGNED_TO">
          <ConnectedAccountPopup
            placement="right"
            dynamicFont
            account={Manager}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faBell} titleId="FOLLOWERS">
          {Followers && Followers.length > 0 ? (
            <ConnectedAccountProfileStack dynamicFont accounts={Followers} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      {ToDos && ToDos.length > 0 && (
        <EntitySummaryAccordion icon={faUsers} text={"CheckList"}>
          <div className="py-3">
            <Checklist
              onChange={handleToDoChange}
              value={todos}
              nodeWidth={390}
              preview
              canCheck={false}
            />
          </div>
        </EntitySummaryAccordion>
      )}

      <EntitySummaryFilesAccordion
        CanEdit={CanEdit}
        IsFollowing={IsFollowing}
        Files={Files}
      />

      {/* <EntityPipelineHistoryAccordion
        endpoint={`Tasks/${Id}/PipelineHistory`}
        id={Id}
        schema={spaceTasksSchema}
      />
      <div className="mb-3"></div> */}
      <EntityPipelineHistoryAccordion
        endpoint={`history/projecttask/${Id}/pipeline`}
        id={Id}
        schema={spaceTasksSchema}
      />
    </>
  );
};

export const InnerTaskSummary = ({ value }) => {
  const currentAccountSpace = useCurrentAccountSpace();

  const hasEmailPermission = canUserReadModule(
    currentAccountSpace,
    moduleTypes.emailConversations
  );

  return (
    <EntitySummaryTabstrip className="mt-2 mb-3">
      <EntitySummaryTab titleId="SUMMARY">
        <TaskSummaryContent value={value} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="HISTORY">
        <History task={value.Id} disableTabs />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ORIGINATED">
        <Treeview className="flex-1" schema={spaceTasksSchema} id={value.Id} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ASSOCIATIONS">
        <TaskDetailsChildren />
      </EntitySummaryTab>
      {hasEmailPermission && (
        <EntitySummaryTab titleId="EMAILS">
          <TaskDetailsEmailConversations id={value.Id} />
        </EntitySummaryTab>
      )}
    </EntitySummaryTabstrip>
  );
};

export const TaskSummary = (props) => {
  return (
    <EntitySummaryOverview
      {...props}
      url={"tasks"}
      type={"task"}
      schema={spaceTasksSchema}
      FormComponent={TaskForm}
      Summary={InnerTaskSummary}
      creationHeaderText={<FormattedMessage id="CREATE_TASK" />}
      ActionButton={TaskEntityActionsButton}
      FormButton={TaskFormButton}
      roomType={RealTimeRoomTypes.Task}
    ></EntitySummaryOverview>
  );
};

export default TaskSummary;
