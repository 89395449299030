import React, { useState, useMemo, useRef, useCallback } from "react";
import { useSpacePost, useSpaceQuery } from "../../Helpers/IOClient";
import {
  spaceBellNotificationSchema,
  spaceNotificationSchema
} from "../../config/schema";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import Popup, { usePopupOpenState } from "../../Components/Popup/Popup";
import store from "../../store";
import { useEndlessScroll } from "../../Components/EndlessScrollPage/EndlessScrollPage";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent
} from "../../Components/Sidebar/SidebarV2Helper";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import FilteredNotifications from "./FilteredNotifications/FilteredNotifications";
import { FormattedMessage } from "react-intl";
import LoadableButton from "../../Components/Button/LoadableButton";
import { BarLoader } from "../../Components/GlobalLoader/GlobalLoader";
import {
  useUnreadEntities,
  useUnreadEntitiesFetcher
} from "../../Components/UnreadEntities/UnreadEntities";
// import { useNotificationBrowserFunctions } from "../../Components/NotificationBrowserBox/NotificationBrowserBox";
import TabStrip from "../../Components/TabStrip/TabStrip";
import { NotificationSchemaContext } from "./FilteredNotifications/NotificationItem/NotificationHelper";
import { useBellNotification, useNotification } from "../../Hooks/EntityHooks";
import {
  SidebarVisualTypeEnum,
  useSidebarView
} from "../../Components/Sidebar/SidebarViewsHelper";
import moment from "moment";
import { useNotificationBrowserFunctions } from "../../Components/NotificationBrowserBox/NotificationBrowserBox";
import { faCog, faExternalLinkAlt } from "@fortawesome/pro-light-svg-icons";
import { Link, useRouteMatch } from "react-router-dom";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";

function getDiffInDays(d1, d2) {
  // ////
  const d11 = new moment(d1);

  const d22 = new moment(d2);

  return d11.isSame(d22, "day");
}

const buildFilteredNotifications = (notifications, bell) => {
  let currentDate;
  const notificationsStore = bell
    ? store.getState().Entities.SpaceBellNotifications
    : store.getState().Entities.SpaceNotifications;

  return notifications.reduce((filters, notfId, currentIndex) => {
    const notf = notificationsStore[notfId];

    // if (notf?.Type === 10) return filters;

    if (!currentDate || !getDiffInDays(currentDate, notf.Date)) {
      currentDate = notf.Date;

      filters.push({
        Date: notf.Date,
        notifications: [notfId]
      });
      return filters;
    }

    const filterNotifications = filters[filters.length - 1];
    filterNotifications.notifications.push(notfId);

    return filters;
  }, []);
};

export const BasicFilterLink = ({ value, type, onClick, filterName }) => {
  const isSelected = value === filterName;

  return (
    <div
      className={classnames("ssi-link py-2 px-3 disable-selection my-1", {
        selected: isSelected
      })}
      onClick={() => onClick(filterName)}
    >
      <FormattedMessage id={filterName} />
    </div>
  );
};

const FilterStatusOptions = {
  ALL: {
    value: null
  },
  NOTIFICATIONS_READ: {
    value: 1
  },
  NOTIFICATIONS_UNREAD: {
    value: 0
  }
};

const FilterOptions = {
  ALL: {
    value: 0
  },
  CALLS: {
    value: 1
  },
  CASES: {
    value: 2
  },
  PROJECTS: {
    value: 3
  },
  TASKS: {
    value: 4
  },
  // "Registo de Tempo": {
  //   filter: "Intervention ne null"
  // },
  ACCOUNTS: {
    value: 5
  },
  // Contactos: {
  //   filter: 6
  // },
  CONTRACTS: {
    value: 6
  },
  DEALS: {
    value: 7
  },
  CONTACTS: {
    value: 8
  },
  EMAIL_CONVERSATION: {
    value: 13
  }
  // SOCIAL_POST: {
  //   value: 14
  // }
};

const NotificationFilter = React.memo(({ value, onClick }) => {
  const anchorRef = useRef();
  const popupRef = useRef();

  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);

  const handleClick = (v) => {
    toggleIsOpen(false);
    onClick(v);
  };

  const options = [];

  for (const filterName in FilterOptions) {
    if (FilterOptions.hasOwnProperty(filterName)) {
      const option = FilterOptions[filterName];
      options.push(
        <BasicFilterLink
          key={filterName}
          type={option ? option.filter : option}
          onClick={handleClick}
          value={value}
          filterName={filterName}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <div
        ref={anchorRef}
        className="d-inline-flex align-items-center cursor-pointer disable-selection"
        onClick={(e) => toggleIsOpen(true)}
      >
        <div className="mr-2 text-truncate">
          <span className="mr-2">
            <FormattedMessage id={"FILTER"} />:
          </span>
          <span className="text-primary">
            <FormattedMessage id={value} />
          </span>
        </div>
        <span className="text-primary">
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </div>
      <Popup
        isOpen={isOpen}
        domRef={popupRef}
        placement="bottom-start"
        anchorEl={anchorRef.current}
      >
        <div className="bg-white p-2">{options}</div>
      </Popup>
    </React.Fragment>
  );
});

const NotificationFilterStatus = React.memo(({ value, onClick }) => {
  const anchorRef = useRef();
  const popupRef = useRef();

  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);

  const handleClick = (v) => {
    toggleIsOpen(false);
    onClick(v);
  };

  const options = [];

  for (const filterName in FilterStatusOptions) {
    if (FilterStatusOptions.hasOwnProperty(filterName)) {
      const option = FilterStatusOptions[filterName];
      options.push(
        <BasicFilterLink
          key={filterName}
          type={option ? option.filter : option}
          onClick={handleClick}
          value={value}
          filterName={filterName}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <div
        ref={anchorRef}
        className="d-inline-flex align-items-center cursor-pointer"
        onClick={(e) => toggleIsOpen(true)}
      >
        <div className="mr-2 text-truncate">
          <span className="mr-2">
            <FormattedMessage id={"STATUS"} />:
          </span>
          <span className="text-primary">
            <FormattedMessage id={value} />
          </span>
        </div>
        <span className="text-primary">
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </div>
      <Popup
        isOpen={isOpen}
        domRef={popupRef}
        placement="bottom-start"
        anchorEl={anchorRef.current}
      >
        <div className="bg-white p-2">{options}</div>
      </Popup>
    </React.Fragment>
  );
});

const Notifications = () => {
  const [, closeSidebar] = useSidebar();
  const stableIndex = useMemo(() => {
    const localStorageFilterStatus = window.localStorage.getItem(
      "air-notification-last-tab"
    );
    let res = 0;
    if (localStorageFilterStatus !== null) {
      res = parseInt(localStorageFilterStatus);
      if (isNaN(res) || res > 1 || res < 0) return 0;
      else return res;
    } else return res;
  }, []);

  const tabItems = [
    {
      name: <FormattedMessage id="NOTIFICATION_BELL_TITLE" />,
      component: (
        <NotificationSchemaContext.Provider
          value={{
            schema: spaceBellNotificationSchema,
            useSchema: useBellNotification
          }}
        >
          <NotificationBellContent bell />
        </NotificationSchemaContext.Provider>
      )
    },
    {
      name: <FormattedMessage id="ALL" />,
      component: (
        <NotificationSchemaContext.Provider
          value={{
            schema: spaceNotificationSchema,
            useSchema: useNotification
          }}
        >
          <NotificationAllContent />
        </NotificationSchemaContext.Provider>
      )
    }
  ];

  const saveLastTab = (i) => {
    window.localStorage.setItem("air-notification-last-tab", i);
  };
  const match = useRouteMatch();
  const { setBoxState, boxState } = useNotificationBrowserFunctions() || {};
  const sidebarView = useSidebarView();
  return (
    <BaseSidebarContainer
      width={sidebarView === SidebarVisualTypeEnum.fullscreen ? "" : 800}
    >
      <BaseSidebarHeader>
        <div className="d-flex align-items-center justify-content-between flex-1 mr-3">
          <FormattedMessage id={"NOTIFICATIONS"} />
          <div className="d-flex align-items-center justify-content-end">
            <div
              onClick={() => {
                if (setBoxState) setBoxState(!boxState);
              }}
              className={classnames(
                "p-2 air-rounded-selection text-primary fs-14 cursor-pointer",
                { "bg-airdesk": boxState }
              )}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </div>
            <Link
              onClick={() => {
                closeSidebar();
              }}
              to={`${match.url}/account/notifications`}
              className={classnames(
                "p-2 ml-3 air-rounded-selection text-primary fs-14 cursor-pointer"
              )}
            >
              <FontAwesomeIcon icon={faCog} />
            </Link>
          </div>
        </div>
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <TabStrip
          route={false}
          index={stableIndex}
          alignLeft
          switchTab={saveLastTab}
          items={tabItems}
          contentClassName={"p-0 position-relative"}
          className="ar-details-tabstrip h-100"
        />
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

// eslint-disable-next-line no-unused-vars
const NotificationBellContent = ({ bell = false }) => {
  const currentSchema = useMemo(
    () => (bell ? spaceBellNotificationSchema : spaceNotificationSchema),
    [bell]
  );
  const [filter, setFilter] = useState(() => {
    const localStorageFilter = window.localStorage.getItem(
      "air-notification-filter"
    );
    if (
      localStorageFilter &&
      FilterOptions.hasOwnProperty(localStorageFilter)
    ) {
      return localStorageFilter;
    } else return "ALL";
  });
  const [filterStatus, setFilterStatus] = useState(() => {
    const localStorageFilterStatus = window.localStorage.getItem(
      "air-notification-filter-status"
    );
    if (
      localStorageFilterStatus &&
      FilterStatusOptions.hasOwnProperty(localStorageFilterStatus)
    ) {
      return localStorageFilterStatus;
    } else return "ALL";
  });
  const pageRef = useRef();

  const [skip, setSkip] = useState(0);

  const resolvedUrl = useMemo(() => {
    const baseUrl = `notifications/all/1?$top=20&$skip=${skip}`;
    if (!filter) return baseUrl;
    ////;
    const resolvedOption = FilterOptions[filter];
    const unresolvedStatus = FilterStatusOptions[filterStatus];
    const resolvedStatus =
      unresolvedStatus.value === 0 || unresolvedStatus.value === 1
        ? true
        : false;
    return `${baseUrl}&Type=${resolvedOption.value}${
      resolvedStatus ? `&IsRead=${unresolvedStatus.value}` : ``
    }`;
  }, [filter, filterStatus, skip]);

  const [response, setResponse] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const { loading, error } = useSpaceQuery(resolvedUrl, [currentSchema], {
    cache: false,
    onSuccess: ({ data }) => {
      if (data.length === 0) setHasMore(false);
      setResponse((r) => [...r, ...data]);
    }
  });
  const countRefetch = useUnreadEntitiesFetcher();

  const [markAllAsReadPost, { loading: allReadLoading }] = useSpacePost(
    `notifications/read/all?IsBell=1`,
    [currentSchema],
    {
      cache: false,
      onSuccess: () => {
        countRefetch();
      }
    }
  );

  const handleMarkAllReadClick = () => {
    markAllAsReadPost();
  };

  const Unreads = useUnreadEntities();
  const notificationsCount = Unreads?.UnreadNotifications || 0;

  const fetchMore = useCallback(() => {
    setSkip((s) => s + 20);
  }, []);

  const filteredNotifications = useMemo(() => {
    if (!response.length === 0) return null;
    return buildFilteredNotifications(response, bell);
  }, [bell, response]);

  const buildBody = () => {
    if (response.length === 0 && (loading || error))
      return (
        <div className="mx-4 my-3">
          {/* <HistorySkeleton /> */}
          <LoaderSpinner center size="sm" className="text-primary" />
        </div>
      );

    if (response.length === 0)
      return (
        <div style={{ marginTop: 20, textAlign: "center" }}>
          <FormattedMessage id={"EMPTY_NOTIFICATIONS"} />
        </div>
      );
    // return null
    return <FilteredNotifications values={filteredNotifications} />;
  };
  useEndlessScroll({
    listen: hasMore && !loading && !error,
    onFetchMore: fetchMore,
    offsettMultiplier: 0.5,
    pageRef
  });
  return (
    <div className="d-flex flex-column h-100">
      <div className="px-4 py-3 shadow-sm border-bottom d-flex align-items-center">
        <NotificationFilter
          value={filter}
          onClick={(f) => {
            setFilter(f);
            setSkip(0);
            setResponse([]);
            window.localStorage.setItem("air-notification-filter", f);
          }}
        />
        <div className="ml-4"></div>
        <NotificationFilterStatus
          value={filterStatus}
          onClick={(f) => {
            setFilterStatus(f);
            window.localStorage.setItem("air-notification-filter-status", f);
            setSkip(0);
            setResponse([]);
          }}
        />
        <div className="flex-1 w-100"></div>
        {
          <LoadableButton
            isLoading={allReadLoading}
            disabled={notificationsCount === 0}
            onClick={handleMarkAllReadClick}
            vType="primary-ghost"
          >
            <FormattedMessage id={"MARK_ALL_AS_READ"} />
          </LoadableButton>
        }
      </div>
      <BarLoader style={{ top: 2 }} isLoading={loading} className="w-100" />
      <div ref={pageRef} className="of-y-auto h-100 flex-1">
        {buildBody()}
      </div>
    </div>
  );
};
const NotificationAllContent = () => {
  const [filter, setFilter] = useState(() => {
    const localStorageFilter = window.localStorage.getItem(
      "air-notification-filter"
    );
    if (
      localStorageFilter &&
      FilterOptions.hasOwnProperty(localStorageFilter)
    ) {
      return localStorageFilter;
    } else return "ALL";
  });
  const [filterStatus, setFilterStatus] = useState(() => {
    const localStorageFilterStatus = window.localStorage.getItem(
      "air-notification-filter-status"
    );
    if (
      localStorageFilterStatus &&
      FilterStatusOptions.hasOwnProperty(localStorageFilterStatus)
    ) {
      return localStorageFilterStatus;
    } else return "ALL";
  });
  const pageRef = useRef();

  const [skip, setSkip] = useState(0);

  const resolvedUrl = useMemo(() => {
    const baseUrl = `notifications/all/0?$top=20&$skip=${skip}`;
    if (!filter) return baseUrl;
    ////;
    const resolvedOption = FilterOptions[filter];
    const unresolvedStatus = FilterStatusOptions[filterStatus];
    const resolvedStatus =
      unresolvedStatus.value === 0 || unresolvedStatus.value === 1
        ? true
        : false;
    return `${baseUrl}&Type=${resolvedOption.value}${
      resolvedStatus ? `&IsRead=${unresolvedStatus.value}` : ``
    }`;
  }, [filter, filterStatus, skip]);

  const [response, setResponse] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const { loading, error } = useSpaceQuery(
    resolvedUrl,
    [spaceNotificationSchema],
    {
      cache: false,
      onSuccess: ({ data }) => {
        if (data.length === 0) setHasMore(false);
        setResponse((r) => [...r, ...data]);
      }
    }
  );
  const countRefetch = useUnreadEntitiesFetcher();
  const hasSuccessfulyMarkedRef = useRef(false);
  const [markAllAsReadPost, { loading: allReadLoading }] = useSpacePost(
    `notifications/read/all`,
    [spaceNotificationSchema],
    {
      cache: false,
      onSuccess: () => {
        hasSuccessfulyMarkedRef.current = true;
        countRefetch();
      }
    }
  );

  const handleMarkAllReadClick = () => {
    markAllAsReadPost();
  };

  const Unreads = useUnreadEntities();
  const notificationsCount = Unreads?.UnreadNotifications || 0;

  const fetchMore = useCallback(() => {
    setSkip((s) => s + 20);
  }, []);

  const filteredNotifications = useMemo(() => {
    if (!response.length === 0) return null;
    return buildFilteredNotifications(response);
  }, [response]);

  const buildBody = () => {
    if (response.length === 0 && (loading || error))
      return (
        <div className="mx-4 my-3">
          {/* <HistorySkeleton /> */}
          <LoaderSpinner center size="sm" className="text-primary" />
        </div>
      );

    if (response.length === 0)
      return (
        <div style={{ marginTop: 20, textAlign: "center" }}>
          <FormattedMessage id={"EMPTY_NOTIFICATIONS"} />
        </div>
      );
    // return null
    return <FilteredNotifications values={filteredNotifications} />;
  };
  useEndlessScroll({
    listen: hasMore && !loading && !error,
    onFetchMore: fetchMore,
    offsettMultiplier: 0.5,
    pageRef
  });
  return (
    <div className="d-flex flex-column h-100">
      <div className="px-4 py-3 shadow-sm border-bottom d-flex align-items-center">
        <NotificationFilter
          value={filter}
          onClick={(f) => {
            setFilter(f);
            setSkip(0);
            setResponse([]);
            window.localStorage.setItem("air-notification-filter", f);
          }}
        />
        <div className="ml-4"></div>
        <NotificationFilterStatus
          value={filterStatus}
          onClick={(f) => {
            setFilterStatus(f);
            window.localStorage.setItem("air-notification-filter-status", f);
            setSkip(0);
            setResponse([]);
          }}
        />
        <div className="flex-1 w-100"></div>
        {
          <LoadableButton
            isLoading={allReadLoading}
            type="button"
            disabled={
              notificationsCount === 0 || hasSuccessfulyMarkedRef.current
            }
            onClick={handleMarkAllReadClick}
            vType="primary-ghost"
          >
            <FormattedMessage id={"MARK_ALL_AS_READ"} />
          </LoadableButton>
        }
      </div>
      <BarLoader style={{ top: 2 }} isLoading={loading} className="w-100" />
      <div ref={pageRef} className="of-y-auto h-100 flex-1">
        {buildBody()}
      </div>
    </div>
  );
};

export default Notifications;
