import React from "react";
import { connect } from "react-redux";
import DropdownInput, {
  DropdownInputContainer
} from "../Dropdown/DropdownInput";

const IndustryDropdownInput = props => {
  const { industry } = props;

  return (
    <DropdownInputContainer>
      <DropdownInput {...props} fallbackValue={industry && industry.Name} />
    </DropdownInputContainer>
  );
};

export default IndustryDropdownInput;

const mapStateToProps = (state, ownProps) => {
  if (!ownProps.industry) return {};
  return {
    industry: state.Entities.Industries[ownProps.industry]
  };
};

export const ConnectedIndustryDropdownInput = connect(mapStateToProps)(
  IndustryDropdownInput
);
