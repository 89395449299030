export const DefaultColumnsWidth = {
  buttons: 120,
  smallButton: 90,
  singleButton: 75,
  id: 80,
  client: 200,
  fiscalNumber: 120,
  EstimatedCost: 100,
  EstimatedTime: 220,
  performance: 160,
  Email: 200,
  classification: 145,
  selectable: 40,
  SpentTime: 150,
  pipeline: 150,
  opv: 100,
  status: 180,
  relativeTime: 170,
  duration: 100,
  assigned: 80,
  ReceivedDate: 200,
  ClientName: 250,
  travels: 150,
  hourControl: 218,
  Description: 310,
  Origem: 250,
  ContactName: 200,
  CreatorName: 180,
  Phone: 185,
  periodomaxhours: 220,
  Title: 165,
  Company: 170,
  billingNumber: 160,
  estimatedTime: 220,
  nextPeriod: 180,
  AssignedGroup: 145,
  performanceProjects: 130,
  icons: 140,
  followIcon: 40,
  breaks: 120,
  minStatusSize: 67,
  timeRegister: 200,
  InterventionType: 210,
  ineficientHours: 180
};

export const clientPropFields = ["Client", "IndirectClient"];

export const originPropFields = [
  "Project",
  "Call",
  "Ticket",
  "Client",
  "Task",
  "Contract",
  "Deal"
];
