import { faUserClock } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import TriggerCreation from "./TriggerCreation";
import { spaceInterventionsSchema } from "../../../../../config/schema";
import { ConnectedInterventionOrigin } from "../../../../Origin/InterventionOrigin";
import { useIntl } from "react-intl";

const PreviewTarget = ({ val }) => (
  <ConnectedInterventionOrigin intervention={val} />
);

const TriggerTimeCreation = React.memo(({ ...props }) => {
	const intl = useIntl();
  return (
    <TriggerCreation
      {...props}
      TargetPreview={PreviewTarget}
      schema={spaceInterventionsSchema}
      icon={faUserClock}
			text={intl.formatMessage({ id: "TIME" })}
    />
  );
});

export default TriggerTimeCreation;
