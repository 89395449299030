import { useMemo } from "react";
import { useCurrentAccountSpace } from "../Contexts/UserContext";
import { canReadContacts, hasCallEditPermission, hasContractsEditPermission, hasDealsEditPermission, hasClientsEditPermission, hasSubscriptionsEditPermission, hasProjectEditPermission, hasTicketEditPermission, hasTaskEditPermission } from "./ModulesHelper";

export const useCurrentUserContactPermission = () => {
  const accountSpace = useCurrentAccountSpace();

  return useMemo(() => canReadContacts(accountSpace), [accountSpace]);
};
export const useCurrentUserEditClientPermission = () => {
  const accountSpace = useCurrentAccountSpace();

  return useMemo(() => hasClientsEditPermission(accountSpace), [accountSpace]);
};
export const useCurrentUserEditDealPermission = () => {
  const accountSpace = useCurrentAccountSpace();

  return useMemo(() => hasDealsEditPermission(accountSpace), [accountSpace]);
};
export const useCurrentUserEditContractPermission = () => {
  const accountSpace = useCurrentAccountSpace();

  return useMemo(() => hasContractsEditPermission(accountSpace), [accountSpace]);
};
export const useCurrentUserEditSubscriptionPermission = () => {
  const accountSpace = useCurrentAccountSpace();

  return useMemo(() => hasSubscriptionsEditPermission(accountSpace), [accountSpace]);
};
export const useCurrentUserEditCallPermission = () => {
  const accountSpace = useCurrentAccountSpace();

  return useMemo(() => hasCallEditPermission(accountSpace), [accountSpace]);
};
export const useCurrentUserEditProjectPermission = () => {
  const accountSpace = useCurrentAccountSpace();

  return useMemo(() => hasProjectEditPermission(accountSpace), [accountSpace]);
};
export const useCurrentUserEditTicketPermission = () => {
  const accountSpace = useCurrentAccountSpace();

  return useMemo(() => hasTicketEditPermission(accountSpace), [accountSpace]);
};
export const useCurrentUserEditTaskPermission = () => {
  const accountSpace = useCurrentAccountSpace();

  return useMemo(() => hasTaskEditPermission(accountSpace), [accountSpace]);
};
