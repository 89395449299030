import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from "react";
import { useServerAwareState } from "../../../../Components/CGrid/ServerGrid";
import { useSpaceQuery, getEntity } from "../../../../Helpers/IOClient";
import {
  spacePipelineStatusSchema,
  spaceContractSchema
} from "../../../../config/schema";
import { useSpace } from "../../../../Contexts/SpaceContext";
import { FormattedMessage } from "react-intl";
import { useCurrency, useContract } from "../../../../Hooks/EntityHooks";
import {
  BoardSection,
  BoardTimePeriod,
  BoardContainer,
  BoardHeaderTypes,
  BoardHeaderContext
} from "../../../../Components/BoardList/BoardList";
import { ConnectedAccountsTeamsDepartmentsProfileStack } from "../../../../Components/Accounts/AccountProfileStack";
import { ConnectedClientOrigin } from "../../../Origin/ClientOrigin";
import { ConnectedClientProfile } from "../../../../Components/Clients/ClientProfile";
import { ConnectedContactPopup } from "../../../Origin/ContactPopup";
// import { largeNumberFormatter } from "../../../../Helpers/MiscHelper";
import { HoverEntityChildrenProgress } from "../../../EntityChildrenProgress/EntityChildrenProgress";
import { ConnectedAccountPopup } from "../../../Origin/AcountPopup";
import { ConnectedOrganizationProfile } from "../../../../Components/Organizations/OrganizationProfile";
import { useResolvedKanbans } from "../../../../Components/KanbanChooser/KanbanChooserHelper";
import { ContractEntityGridButtons } from "../../ContractsHelper";
import { ContractStatusTypes } from "../../../../Components/Contract/ContractProfile";
import { ContractSidebarLink } from "../../../../Components/EntitySidebarLinks/EntitySidebarLinks";
import { usePipelineSettings } from "../../../../Components/Pipelines/PipelineProvider";
import {
  buildBoardParamOverride,
  EntityBoardItem,
  CancellableBoard,
  DownsizedColumnsContext,
  useFavoriteToggleDownsize
} from "../../../../Components/EntityBoards/EntityBoards";
import { buildODataQuery } from "../../../../Helpers/ODataHelper";
import { ContractUpdateCommunicator } from "../../../EntitySynchronizer/EntitySynchronizer";
import { PipelineTypeEnum } from "../../../Settings/Pipelines/NewPipelineHelper";
import LoaderSpinner from "../../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useDetailsServerAwareParams } from "../../../../Helpers/GridHelper";
import { EntityBoardsPipelineSelector } from "../../../../Components/EntityBoards/EntityBoardsPipelineSelector";
import {
  DownsizedPipelineStatusChooserListnerDict,
  useResolvedDownsizedPipelineStatus
} from "../../../../Components/KanbanChooser/KanbanResizerHelper";
import { ConnectedContractOrigin } from "../../../Origin/ContractOrigin";
import classnames from "classnames";
import { ServerAwareFavoritesContext } from "../../../AppBar/ServerAwareFavorite";
import { toCurrencyFromDecimal } from "../../../../Helpers/CurrencyHelper";
export const useContractPipelinesStatusItems = () => {
  const { params } = useServerAwareState();

  const pipelineId = params.pipeline;
  const { data } = useSpaceQuery(
    pipelineId
      ? `query/PipelineStatus?pipelineId=${pipelineId}&isFilterEnabled=true`
      : null,
    [spacePipelineStatusSchema]
  );

  return useMemo(() => {
    if (!data) return undefined;

    return data.map((e) => {
      const resolved = getEntity(spacePipelineStatusSchema, e);
      return {
        key: resolved.Id,
        title: resolved.Name,
        Id: resolved.Id
      };
    });
  }, [data]);
};

export const useContractItemBoardComponents = (deal) => {
  const space = useSpace();
  const { Symbol } = useCurrency(space.Currency);

  const {
    Id,
    Assigned,
    EndDate,
    BeginDate,
    // EstimatedCost,
    NoTagDescription,
    Manager,
    Creator,
    Contact,
    Client,
    IndirectClient,
    Company,
    DefaultEstimatedCost,
    SalesOpportunity
  } = deal;

  const unresolvedValue = DefaultEstimatedCost ? DefaultEstimatedCost.Cost : 0;
  const value = toCurrencyFromDecimal(unresolvedValue);
  return [
    {
      title: "ASSOCIATIONS",
      comp: (
        <BoardSection
          key="Associations"
          text={<FormattedMessage id={"ASSOCIATIONS"} />}
          className="mb-1 mt-2"
        >
          <div className="d-flex justify-content-between mb-2">
            <div>
              <div>
                <ConnectedAccountsTeamsDepartmentsProfileStack
                  organizations={[Company]}
                  accounts={[Assigned, Manager]}
                />
              </div>
            </div>
            <div>
              <div className="ar-board-pics d-flex">
                <ConnectedClientOrigin client={Client || IndirectClient}>
                  <ConnectedClientProfile
                    client={Client || IndirectClient}
                    justImage
                  />
                </ConnectedClientOrigin>
                <ConnectedContactPopup justImage contact={Contact} />
              </div>
            </div>
          </div>
        </BoardSection>
      )
    },
    {
      title: "VALUES",
      comp: (
        <BoardSection
          key="Valores"
          text={<FormattedMessage id={"VALUES"} />}
          className="mb-1 mt-2"
        >
          {!value && !SalesOpportunity ? null : (
            <div className="d-flex justify-content-between mb-2">
              {!value ? null : (
                <div className="fs-12 fw-medium moneyContainer rounded p-1">
                  {Symbol} {value}
                  {/* <FormattedDecimal value={EstimatedCost.Cost} /> */}
                </div>
              )}
              {SalesOpportunity ? (
                <div className="fs-12 p-1">{`OPV: ${SalesOpportunity}`}</div>
              ) : null}
            </div>
          )}
        </BoardSection>
      )
    },
    {
      title: "DEADLINE",
      comp: (
        <BoardSection
          key="Prazos"
          text={<FormattedMessage id={"DEADLINE"} />}
          className="mb-1 mt-2"
          contentClassName="fs-14 text-black"
        >
          <BoardTimePeriod startDate={BeginDate} endDate={EndDate} />
        </BoardSection>
      )
    },
    {
      title: "ORIGINATED",
      comp: (
        <BoardSection
          key="Originou"
          text={<FormattedMessage id={"ORIGINATED"} />}
          className="mb-1 mt-2"
          contentClassName="fs-14 text-black"
        >
          <HoverEntityChildrenProgress schema={spaceContractSchema} id={Id} />
        </BoardSection>
      )
    },
    {
      title: "DESCRIPTION",
      comp: (
        <BoardSection
          key="Descrição"
          text={<FormattedMessage id={"DESCRIPTION"} />}
          className="mb-1 mt-2"
          contentClassName="fs-12 text-black"
        >
          {NoTagDescription}
        </BoardSection>
      )
    },
    {
      title: "CREATED_BY",
      comp: (
        <BoardSection
          key="Criado por"
          text={<FormattedMessage id={"CREATED_BY"} />}
          className="mb-1 mt-2"
          contentClassName="fs-14 text-black"
        >
          <div className="ar-board-pics d-flex">
            <ConnectedAccountPopup justImage account={Creator} />
          </div>
        </BoardSection>
      )
    },
    {
      notDefault: true,
      title: "COMPANY",
      comp: (
        <BoardSection
          key="Empresa"
          text={<FormattedMessage id={"COMPANY"} />}
          className="mb-1 mt-2"
        >
          <div className="d-flex justify-content-between mb-2">
            <div className="w-100">
              <div className="w-100">
                <ConnectedOrganizationProfile organization={Company} />
              </div>
            </div>
            <div></div>
          </div>
        </BoardSection>
      )
    },
    // {
    //   notDefault: true,
    //   title: intl.formatMessage({ id: "ASSIGNED_TO" }),
    //   comp: (
    //     <BoardSection
    //       key="Atribuídoa"
    //       text={<FormattedMessage id={"ASSIGNED_TO"} />}
    //       className="mb-1 mt-2"
    //     >
    //       <div className="d-flex justify-content-between mb-2">
    //         <div className="w-100">
    //           <div className="w-100">
    //             <ConnectedAccountPopup account={Assigned} />
    //           </div>
    //         </div>
    //         <div></div>
    //       </div>
    //     </BoardSection>
    //   ),
    // },
    {
      notDefault: true,
      title: "MANAGER",
      comp: (
        <BoardSection
          key="Responsável"
          text={<FormattedMessage id={"MANAGER"} />}
          className="mb-1 mt-2"
        >
          <div className="d-flex justify-content-between mb-2">
            <div className="w-100">
              <div className="w-100">
                <ConnectedAccountPopup account={Manager} />
              </div>
            </div>
            <div></div>
          </div>
        </BoardSection>
      )
    },
    {
      notDefault: true,
      title: "ACCOUNT",
      comp: (
        <BoardSection
          key="Cliente"
          text={<FormattedMessage id={"ACCOUNT"} />}
          className="mb-1 mt-2"
        >
          <div className="d-flex justify-content-between mb-2">
            <div className="w-100">
              <div className="w-100">
                <ConnectedClientOrigin client={Client || IndirectClient}>
                  <ConnectedClientProfile
                    client={Client || IndirectClient}
                    hideStatus={"true"}
                  />
                </ConnectedClientOrigin>
              </div>
            </div>
            <div></div>
          </div>
        </BoardSection>
      )
    },
    {
      notDefault: true,
      title: "CONTACT",
      comp: (
        <BoardSection
          key="Contato"
          text={<FormattedMessage id={"CONTACT"} />}
          className="mb-1 mt-2"
        >
          <div className="d-flex justify-content-between mb-2">
            <div>
              <div>
                <ConnectedContactPopup contact={Contact} />
              </div>
            </div>
            <div></div>
          </div>
        </BoardSection>
      )
    }
  ];
};

export const ContractAssociations = ({ value }) => {
  const { Assigned, Manager, Company, Client, IndirectClient, Contact } = value;
  return (
    <>
      <ConnectedAccountsTeamsDepartmentsProfileStack
        size="xsm"
        organizations={[Company]}
        accounts={[Assigned, Manager]}
      />
      <div className="vertical-splitter h-100 mx-2" />
      <div className="ar-board-pics align-items-center d-flex">
        <ConnectedClientOrigin client={Client || IndirectClient}>
          <ConnectedClientProfile
            size="xsm"
            client={Client || IndirectClient}
            justImage
          />
        </ConnectedClientOrigin>
        <ConnectedContactPopup size="xsm" justImage contact={Contact} />
      </div>
    </>
  );
};

const ContractItem = ({ id, isDragging }) => {
  const contract = useContract(id) || {};

  const { NoTagDescription, IsRead, IsFollowing, CreationDate, Status } =
    contract;

  const BoardComponents = useContractItemBoardComponents(contract);

  const resolvedColumns = useResolvedKanbans(
    BoardComponents,
    spaceContractSchema.name
  );

  const isDownsized = useContext(BoardItemsDownsizeContext);

  if (isDownsized) {
    return (
      <ConnectedContractOrigin contract={id}>
        <div
          className={classnames(
            "board-item-dot",
            `board-item-color-${getContractBorderType(Status)}`,
            {
              "board-item-dot-unread": !IsRead && IsFollowing
            }
          )}
        ></div>
      </ConnectedContractOrigin>
    );
  } else
    return (
      <BoardContainer
        isRead={IsRead}
        isFollowing={IsFollowing}
        id={id}
        schema={spaceContractSchema}
        value={contract}
        name={NoTagDescription}
        LinkComponent={ContractSidebarLink}
        ButtonsComponent={ContractEntityGridButtons}
        Associations={<ContractAssociations value={contract} />}
        startDate={CreationDate}
      >
        {/* <BoardSection className="mb-1 mt-2">
        <div className="d-flex justify-content-between">
          <div className="fs-14 fw-medium"> {Name}</div>
          <div className="fs-12 d-flex align-items-center justify-content-center">
            <DealEntityGridButtons minimal entity={deal} />
          </div>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <div className="fs-12">
            <DealLink className="text-black" id={id}>
              Nº {id}
            </DealLink>
          </div>
          <div style={{ color: "#7C98B6" }} className="fs-12">
            {dateFormat(new Date(CreationDate), "yyyy-MM-dd hh:mm a")}
          </div>
        </div>
      </BoardSection> */}

        {resolvedColumns.map((e) => e.comp)}
      </BoardContainer>
    );
};

const getContractBorderType = (baseStatus) => {
  switch (baseStatus) {
    case ContractStatusTypes.open:
      return BoardHeaderTypes.purple;

    case ContractStatusTypes.closed:
      return BoardHeaderTypes.inactive;

    case ContractStatusTypes.canceled:
      return BoardHeaderTypes.grey;

    case ContractStatusTypes.draft:
      return BoardHeaderTypes.orange;
    case ContractStatusTypes.staging:
      return BoardHeaderTypes.yellow;

    default:
      return BoardHeaderTypes.purple;
  }
};

const ContractBoardItem = (props) => {
  const params = useDetailsServerAwareParams();

  const { pipelineStatus, data, fullItemsCount } = props;
  const resolvedCount = fullItemsCount.current[pipelineStatus] || 0;
  const pipelineSettings = usePipelineSettings();
  const { pipelineStatusField: field } = pipelineSettings;

  const { sumEndpoint, countEndpoint } = useMemo(() => {
    const pOverride = buildBoardParamOverride(
      params.filter,
      pipelineStatus,
      field
    );
    pOverride.query = params.query;
    const resolvedPOverider = {
      ...params,
      ...pOverride
    };
    const query = buildODataQuery(resolvedPOverider, true, {
      pipelineSettings
    });

    const countEndpoint = `contracts/Count${query}`;
    const sumEndpoint = `contracts/totalSum${query}&SumParams=Currency.EstimatedCost`;

    return { sumEndpoint, countEndpoint };
  }, [field, params, pipelineSettings, pipelineStatus]);

  const {
    loading: loadingSum,
    data: sumDict,
    error: sumError,
    refetch: refetchSum
  } = useSpaceQuery(sumEndpoint);

  const PrevDataRef = useRef();
  useEffect(() => {
    if (!PrevDataRef.current) {
      PrevDataRef.current = Array.isArray(data) ? [...data] : [];
      return;
    }

    const orderedOldVal = PrevDataRef.current.sort(function (a, b) {
      return a - b;
    });
    const orderedNewVal = [...data].sort(function (a, b) {
      return a - b;
    });

    if (orderedOldVal.join(", ") !== orderedNewVal.join(", ")) {
      PrevDataRef.current = [...data];
      refetchSum();
    }
  }, [data, refetchSum]);

  useEffect(() => {
    return ContractUpdateCommunicator.subscribe(
      (entityId, oldEntity, newEntity) => {
        if (!oldEntity) return;
        const { PipelineStatus: prevPipelineStatus } = oldEntity;
        const { PipelineStatus: newPipelineStatus } = newEntity;

        if (prevPipelineStatus === pipelineStatus) {
          refetchSum();
        } else if (newPipelineStatus === pipelineStatus) {
          refetchSum();
        }

        // if (
        //   oldEntity.PipelineStatus === pipelineStatus &&
        //   newEntity.PipelineStatus !== pipelineStatus
        // ) {
        //   refetch()
        // }
      }
    );
  }, [pipelineStatus, refetchSum]);

  const resolvedValue = useMemo(() => {
    return {
      loadingSum: loadingSum || sumError,
      sumValue: sumDict ? sumDict["Currency.EstimatedCost"] : 0,
      loadingCount: false,
      countValue: resolvedCount,
      shownAmount: data.length
    };
  }, [data.length, loadingSum, resolvedCount, sumDict, sumError]);

  const resolvedColumns = useContext(DownsizedColumnsContext);
  const downsizedItems = useResolvedDownsizedPipelineStatus(
    resolvedColumns,
    spaceContractSchema.name,
    pipelineStatus
  );
  const isDownsized = useMemo(() => {
    return (
      Array.isArray(downsizedItems) &&
      downsizedItems.some((e) => e === pipelineStatus)
    );
  }, [downsizedItems, pipelineStatus]);
  const toggleDownsize = useCallback(() => {
    const resolvedDownsizedItems = Array.isArray(downsizedItems)
      ? [...downsizedItems]
      : [];
    if (isDownsized) {
      resolvedDownsizedItems.splice(
        resolvedDownsizedItems.findIndex((e) => e === pipelineStatus),
        1
      );
    } else {
      resolvedDownsizedItems.push(pipelineStatus);
    }
    // window.localStorage.setItem(FilterListLocalStorageKey, localStorageValue);
    const listenerFunction = DownsizedPipelineStatusChooserListnerDict[
      spaceContractSchema.name
    ].find((e) => Boolean(e));
    if (typeof listenerFunction === "function")
      listenerFunction(resolvedDownsizedItems);
  }, [downsizedItems, isDownsized, pipelineStatus]);

  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const toogleFavoriteDownsize = useFavoriteToggleDownsize(pipelineStatus);

  return (
    <BoardHeaderContext.Provider value={resolvedValue}>
      <BoardItemsDownsizeContext.Provider value={isDownsized}>
        <BoardHeaderContext.Provider value={resolvedValue}>
          <EntityBoardItem
            toggleDownsize={
              FavoriteItem ? toogleFavoriteDownsize : toggleDownsize
            }
            isDownsized={isDownsized}
            {...props}
          />
        </BoardHeaderContext.Provider>
      </BoardItemsDownsizeContext.Provider>
    </BoardHeaderContext.Provider>
  );
};

const BoardItemsDownsizeContext = React.createContext();

const ContractSettings = {
  // updateCommunicator: ContractUpdateCommunicator,
  // creationCommunicator: ContractCreationCommunicator,
  updateMessage: (entityId, entity, intl) => {
    return `${intl.formatMessage({ id: "CONTRACT" })} ${
      entity.Id
    } ${intl.formatMessage({
      id: "WAS_UPDATED"
    })}`;
  },
  getBorderType: getContractBorderType,
  ItemComponent: ContractItem,
  canceledStatus: ContractStatusTypes.canceled,

  Type: PipelineTypeEnum.Contract,
  BoardItemComponent: ContractBoardItem,

  schema: spaceContractSchema,
  selector: (e) => {
    const { Id, Assigned } = e;
    return {
      Id,
      UserId: Assigned
    };
  }
};

const ContractResults = ({ ResolvedColumns, pipelineId }) => {
  return (
    <CancellableBoard
      allPipelineStatus={ResolvedColumns}
      ResolvedColumns={ResolvedColumns}
      settings={ContractSettings}
      data={ResolvedColumns}
    />
  );
};

const ContractBoard = () => {
  const { params } = useServerAwareState();
  const pipelineId = params.pipeline;
  const columns = useContractPipelinesStatusItems();
  if (!columns)
    return (
      <LoaderSpinner
        size="sm"
        className="text-primary w-100 d-flex align-items-center justify-content-around"
      />
    );

  if (columns.length === 0) return <EntityBoardsPipelineSelector />;

  return (
    <ContractResults
      key={pipelineId}
      ResolvedColumns={columns}
      pipelineId={pipelineId}
    />
  );
};

export default ContractBoard;
