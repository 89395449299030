import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import classes from "./CalendarMonthView.module.css";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import {
  CalendarItemContext,
  calendarToEpochDays,
  useReftchRefProvider,
  weekDayTranslation
} from "../../CalendarHelper";
import { useQuery } from "../../../../Helpers/IOClient";
import moment from "moment";
import { useMultipleEntityValueSelector } from "../../../../Hooks/AdvancedEntityHooks";
import Popup, { usePopupOpenState } from "../../../Popup/Popup";
import { many } from "../../../../Helpers/SchemaHelper";
import { useEntitySummarySidebar } from "../../../EntitySummaries/EntitySummariesHelper";
import { EntityPopupContext } from "../../../EntityPopup/EntityPopupHelper";
import { useSpace } from "../../../../Contexts/SpaceContext";
import {
  useDetailAwareParams,
  useServerAwareState
} from "../../../CGrid/ServerGrid";
import { buildODataFilter } from "../../../../Helpers/ODataHelper";
import { BarLoader } from "../../../GlobalLoader/GlobalLoader";
import { useToast } from "../../../Toast/ToastProvider";
import { useHoverEvents } from "../../../../Containers/Origin/Origin";

//January = 0
const getDaysOfMonth = (year, month) => {
  const endDate = new Date(year, month + 1, 0);
  const end = 6 - endDate.getDay();

  const startDate = new Date(year, month, 1);
  const start = startDate.getDay();

  startDate.setDate(startDate.getDate() - start);
  endDate.setDate(endDate.getDate() + end);

  return { startDate, endDate, month };
};

window.getDaysOfMonth = getDaysOfMonth;

const useDateDayInfo = (startDate, i, month) => {
  return useMemo(() => {
    const d = new Date(startDate);
    d.setDate(startDate.getDate() + i);
    const dayOfMonth = d.getDate();
    const dayOfWeek = d.getDay();

    return {
      dayOfMonth,
      dayOfWeek,
      date: d,
      isSameMonth: month === d.getMonth()
    };
  }, [i, month, startDate]);
};

const WeekDay = ({ i, startDate, settings, month, currentDate }) => {
  const { dayOfMonth, date, isSameMonth } = useDateDayInfo(startDate, i, month);

  const isToday = date.getTime() === currentDate.getTime();

  const anchorRef = useRef();
  const openSidebar = useEntitySummarySidebar();

  const { moduleType } = settings;

  const handleDoubleClick = () => {
    const BeginDate = new Date(date);
    BeginDate.setHours(BeginDate.getHours() + 12);
    const EndDate = new Date(date);
    EndDate.setHours(EndDate.getHours() + 13);
    openSidebar({
      type: moduleType,
      props: {
        defaultForm: {
          BeginDate,
          EndDate
        }
      }
    });
  };

  return (
    <>
      <div
        ref={anchorRef}
        onDoubleClick={handleDoubleClick}
        className={classnames("fs-14", classes.weekDay, {
          [classes.outOfMonth]: !isSameMonth,
          [classes.today]: isToday
        })}
      >
        <div className={classes.weekDayHeader}>
          {dayOfMonth}
          {/* | {i + 1} */}
        </div>
      </div>
    </>
  );
};

const getMaxElemsPerDay = (dayElemRef) => {
  const elem = dayElemRef.current;

  const height = elem.offsetHeight;

  const maxElems = Math.floor(height / 28);
  return maxElems;
};

const ItemPopup = ({
  isOpen,
  id,
  popupRef,
  anchorEl,
  placement,
  modifiers,
  settings,
  onMouseEnter,
  onMouseLeave
}) => {
  const { EntityPopup } = settings;
  return (
    <Popup
      placement={placement || "auto"}
      modifiers={modifiers}
      anchorEl={anchorEl}
      domRef={popupRef}
      isOpen={isOpen}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <EntityPopup entityId={id} />
    </Popup>
  );
};

// const ItemPreview = ({ item, width }) => {
//   const { Name, Id, BeginDate, Status, Manager } = item;

//   return (
//     <div
//       ref={anchorRef}
//       onClick={onClick}
//       className={classnames("px-2 d-flex align", classes.rowContent, className)}
//     >
//       <span className="text-truncate">{Name}</span>
//     </div>
//   );
// };

// const useItemSize = (anchorRef) => {
//   const [isSmall, setIsSmall] = useState()

//   useEffect(() => {
//     const listener = () => {
//       if(anchorRef.current.offsetWidth > )
//     }
//   }, [])
// };

const InnerItem = ({
  anchorRef,
  onClick,
  onMouseOver,
  onMouseOut,
  className,
  item,
  settings
}) => {
  const { CalendarItem } = settings;
  const { HasUpdates } = item.metadata;

  const value = useMemo(
    () => ({
      anchorRef,
      onClick,
      onMouseOver,
      onMouseOut,
      className: classnames(classes.rowContent, className, {
        [classes.CalenderItemUpdated]: HasUpdates
      })
    }),
    [anchorRef, onClick, onMouseOver, onMouseOut, className, HasUpdates]
  );

  return (
    <CalendarItemContext.Provider value={value}>
      <CalendarItem item={item} />
    </CalendarItemContext.Provider>
  );
};

const ItemContent = ({ item, className, placement, modifiers, settings }) => {
  const { Id } = item.metadata;

  const anchorRef = useRef();
  const popupRef = useRef();
  // const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  const { moduleType } = settings;
  const openForm = useEntitySummarySidebar();
  const handleClick = (e) => {
    onAnchorMouseLeave();
    openForm({
      type: moduleType,
      props: {
        id: Id
      }
    });
  };

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  const toggleOffParent = useContext(EntityPopupContext);

  const toggleOff = useCallback(() => {
    // toggleIsOpen(false);
    toggleOffParent && toggleOffParent();
  }, [toggleOffParent]);

  return (
    <>
      <EntityPopupContext.Provider value={toggleOff}>
        <ItemPopup
          modifiers={modifiers}
          settings={settings}
          anchorEl={anchorRef.current}
          isOpen={isOpen}
          onMouseEnter={onPopupMouseEnter}
          onMouseLeave={onPopupMouseLeave}
          id={Id}
          popupRef={popupRef}
          placement={placement}
        />
      </EntityPopupContext.Provider>
      <InnerItem
        settings={settings}
        item={item}
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        anchorRef={anchorRef}
        onClick={handleClick}
        className={className}
      />
    </>
  );
};

const MoreItemsButton = ({
  itemsInDay,
  maxElems,
  startDate,
  dayDiff,
  settings
}) => {
  const anchorRef = useRef();
  const popupRef = useRef();

  const [blocked] = useState(false);

  const [open, toggleOpen] = usePopupOpenState(popupRef, anchorRef, {
    blocked
  });

  const { dayOfMonth, dayOfWeek } = useDateDayInfo(startDate, dayDiff);
  const intl = useIntl();

  const toggleOff = useCallback(() => {
    toggleOpen(false);
  }, [toggleOpen]);

  // const onItemClick = () => {
  //   setBlocked(true);
  // };

  return (
    <>
      <div
        ref={anchorRef}
        onClick={() => toggleOpen()}
        className={classnames("px-1", classes.rowContent)}
      >
        <span className="text-truncate">
          {intl.formatMessage(
            { id: "CALENDAR_MORE_ITEMS" },
            { num: itemsInDay.length - maxElems + 1 }
          )}
          {/* mais {itemsInDay.length - maxElems + 1} */}
        </span>
      </div>
      <EntityPopupContext.Provider value={toggleOff}>
        <Popup
          placement="auto"
          className={classes.moreItemPopup}
          popperClassName={classes.moreItemsPopper}
          isOpen={open}
          domRef={popupRef}
          anchorEl={anchorRef.current}
        >
          <div
            className="p-3 bg-white rounded shadow overflow-auto"
            onWheel={(e) => e.stopPropagation()}
            style={{ width: 300, maxHeight: "90%" }}
          >
            <div className="text-black mb-3">
              <FormattedMessage id={weekDayTranslation[dayOfWeek]} />
              <div>{dayOfMonth}</div>
            </div>
            {itemsInDay.map((item) => {
              return (
                <ItemContent
                  settings={settings}
                  placement="left"
                  modifiers={{
                    preventOverflow: { boundariesElement: "viewport" }
                  }}
                  className="mb-1"
                  item={item}
                  key={item.metadata.Id}
                />
              );
            })}
          </div>
        </Popup>
      </EntityPopupContext.Provider>
    </>
  );
};

const calculateItemsPerDay = (items) => {
  const itemsPerDayDict = {};
  const totalItemsPerDayDict = {};
  for (const item of items) {
    const { rowStartDay, rowEndDay } = item;

    for (let i = rowStartDay; i <= rowEndDay; i++) {
      if (!totalItemsPerDayDict[i]) totalItemsPerDayDict[i] = 1;
      else totalItemsPerDayDict[i]++;

      if (!itemsPerDayDict[i]) itemsPerDayDict[i] = [item];
      else itemsPerDayDict[i].push(item);
    }
  }

  return { itemsPerDayDict, totalItemsPerDayDict };
};

// const getCurrentElapsedDays = (item, itemsPerDayProgressDict) => {
//   const { rowStartDay, rowEndDay } = item;

//   let maxElapsedDays = 0;

//   for (let i = rowStartDay; i <= rowEndDay; i++) {
//     const dayElapsedDays = itemsPerDayProgressDict[i]?.length || 0;
//     if (dayElapsedDays > maxElapsedDays) maxElapsedDays = dayElapsedDays;
//   }
//   return maxElapsedDays;
// };

export const buildItems = (
  items,
  gridRowStartDay,
  maxElems,
  startDate,
  settings,
  { isDaily } = { isDaily: false }
) => {
  const children = [];
  //        number           array
  const { itemsPerDayDict, totalItemsPerDayDict } = calculateItemsPerDay(items);
  let resolvedElapsedDays = 0;
  const AdditionalItemPerDay = {};

  const itemsPerDayProgressDict = {};
  for (const item of items) {
    const { rowStartDay, rowEndDay, totalRowDays, metadata } = item;

    const elapsedDays =
      (itemsPerDayProgressDict[rowStartDay] &&
        itemsPerDayProgressDict[rowStartDay].length) ||
      0;

    // const elapsedDays = getCurrentElapsedDays(item, itemsPerDayProgressDict);

    if (
      (totalItemsPerDayDict[rowStartDay] || 0) > maxElems &&
      elapsedDays >= maxElems - 1 // needs space for the show all button
    )
      continue;

    for (let i = rowStartDay; i <= rowEndDay; i++) {
      if (!itemsPerDayProgressDict[i]) itemsPerDayProgressDict[i] = [item];
      else itemsPerDayProgressDict[i].push(item);
    }

    //verify if there are conflicts in the last line with the "plus" button
    if (
      elapsedDays + 1 === maxElems &&
      itemsPerDayDict[rowStartDay].length === maxElems
    ) {
      if (rowEndDay > rowStartDay) {
        let shouldStop = false;
        for (let i = rowStartDay + 1; i <= rowEndDay; i++) {
          if (itemsPerDayDict[i].length > maxElems) {
            shouldStop = true;
            break;
          }
        }
        if (shouldStop) {
          AdditionalItemPerDay[rowStartDay] = true;
          continue;
        }
      }
    }

    const { Id } = metadata;

    const child = (
      <div
        key={Id}
        className={classnames("px-1 text-truncate", classes.rowItem)}
        style={{
          left: isDaily
            ? undefined
            : `${(100 / 7) * (rowStartDay - gridRowStartDay - 1)}%`,
          top: 24 * elapsedDays + 4 * elapsedDays,
          width: isDaily ? "100%" : `${(totalRowDays * 100) / 7}%`
        }}
      >
        <ItemContent
          modifiers={{
            preventOverflow: { boundariesElement: "viewport" }
          }}
          settings={settings}
          item={item}
        />
      </div>
    );

    children.push(child);

    if (elapsedDays > resolvedElapsedDays) resolvedElapsedDays = elapsedDays;
  }

  for (let i = gridRowStartDay; i <= gridRowStartDay + 6; i++) {
    let totalItemsInDay = totalItemsPerDayDict[i] || 0;
    const hasAdditionalItem = AdditionalItemPerDay[i];
    if (totalItemsInDay > maxElems || hasAdditionalItem) {
      if (hasAdditionalItem) totalItemsInDay = 1;
      resolvedElapsedDays = maxElems - 1;
      const plusChild = (
        <div
          key={`plus-${i}`}
          className={classnames("px-1 fs-12 text-truncate", classes.rowItem)}
          style={{
            left: isDaily
              ? undefined
              : `${(100 / 7) * (6 - (gridRowStartDay + 7 - i))}%`,
            top: 24 * (maxElems - 1) + 4 * (maxElems - 1),
            width: isDaily ? "100%" : `${(1 * 100) / 7}%`
          }}
        >
          <MoreItemsButton
            settings={settings}
            startDate={startDate}
            dayDiff={i - 1}
            maxElems={maxElems}
            itemsInDay={itemsPerDayDict[i]}
          />
        </div>
      );
      children.push(plusChild);
    }
  }

  return { children, elapsedDays: resolvedElapsedDays + 1 };
};

const WeekDayHeader = React.memo(function WeekDayHeader() {
  return (
    <div className={classes.rowWeekDays}>
      <HeaderDay i={0} />
      <HeaderDay i={1} />
      <HeaderDay i={2} />
      <HeaderDay i={3} />
      <HeaderDay i={4} />
      <HeaderDay i={5} />
      <HeaderDay i={6} />
    </div>
  );
});

const HeaderDay = ({ i }) => {
  return (
    <div className="d-flex justify-content-center text-black text-uppercase fs-14">
      <FormattedMessage id={weekDayTranslation[i]} />
    </div>
  );
};

const WeekRow = ({
  i,
  startDate,
  itemsElemRef,
  items,
  maxElems,
  settings,
  month,
  currentDate
}) => {
  const baseNum = 7 * i;
  return (
    <div className={classnames(classes.weekRow)}>
      <div ref={itemsElemRef} className={classes.weekItems}>
        {items.length > 0 &&
          maxElems !== undefined &&
          buildItems(items, baseNum, maxElems, startDate, settings).children}
      </div>

      <div className={classes.weekRowDays}>
        <WeekDay
          currentDate={currentDate}
          month={month}
          settings={settings}
          startDate={startDate}
          i={baseNum}
        />
        <WeekDay
          currentDate={currentDate}
          month={month}
          settings={settings}
          startDate={startDate}
          i={baseNum + 1}
        />
        <WeekDay
          currentDate={currentDate}
          month={month}
          settings={settings}
          startDate={startDate}
          i={baseNum + 2}
        />
        <WeekDay
          currentDate={currentDate}
          month={month}
          settings={settings}
          startDate={startDate}
          i={baseNum + 3}
        />
        <WeekDay
          currentDate={currentDate}
          month={month}
          settings={settings}
          startDate={startDate}
          i={baseNum + 4}
        />
        <WeekDay
          currentDate={currentDate}
          month={month}
          settings={settings}
          startDate={startDate}
          i={baseNum + 5}
        />
        <WeekDay
          currentDate={currentDate}
          month={month}
          settings={settings}
          startDate={startDate}
          i={baseNum + 6}
        />
      </div>
    </div>
  );
};

const formatDate = (date) => {
  return `datetime'${moment(date).format("YYYY-MM-DD")}'`;
};

const selector = (v) => {
  return v;
};

export const sortRowItems = (items, gridNum = 0) => {
  const minRowDay = gridNum * 7 + 1;
  const maxRowDay = minRowDay + 6;

  const newArr = [];
  for (const item of items) {
    const { gridStartDay, gridEndDay } = item;
    const rowStartDay = gridStartDay < minRowDay ? minRowDay : gridStartDay;
    const rowEndDay = gridEndDay > maxRowDay ? maxRowDay : gridEndDay;
    const totalRowDays = rowEndDay - rowStartDay + 1;
    newArr.push({ ...item, rowStartDay, rowEndDay, totalRowDays });
  }

  const sortedArr = newArr.sort((a, b) => {
    return (
      a.epochStartDay - b.epochStartDay ||
      b.totalRowDays - a.totalRowDays ||
      a.epochStart - b.epochStart ||
      a.epochEnd - b.epochEnd
    );
  });

  return sortedArr;
};

export const buildResolvedValueItem = (v, settings, startDate) => {
  const { BeginDateFieldObj, EndDateFieldObj } = settings;

  const valueBeginDate = BeginDateFieldObj ? v[BeginDateFieldObj] : v.BeginDate;
  const BeginDate = new Date(valueBeginDate);
  const valueEndDate = EndDateFieldObj
    ? v[EndDateFieldObj]
    : v.EndDate || valueBeginDate;
  BeginDate.setHours(18);
  BeginDate.setMinutes(0);
  BeginDate.setSeconds(0);
  BeginDate.setMilliseconds(0);
  const EndDate = new Date(valueEndDate);
  EndDate.setHours(18);
  EndDate.setMinutes(0);
  EndDate.setSeconds(0);
  EndDate.setMilliseconds(0);

  const epochStart = BeginDate.getTime();
  const epochEnd = EndDate.getTime();

  const epochStartDay = calendarToEpochDays(BeginDate);
  const epochEndDay = calendarToEpochDays(EndDate);

  const epochGridStartDate = calendarToEpochDays(startDate);

  let gridStartDay = epochStartDay - epochGridStartDate + 1;
  let gridEndDay = epochEndDay - epochGridStartDate + 1;

  if (gridEndDay > 35) gridEndDay = 35;

  const newV = {
    gridStartDay,
    gridEndDay,
    epochStart,
    epochEnd,
    epochStartDay,
    epochEndDay,
    totalDays: epochEndDay - epochStartDay,
    BeginDate,
    EndDate,
    metadata: v
  };

  return newV;
};

const useMonthGridItems = (data, startDate, settings) => {
  return useMemo(() => {
    const gridItems = { 0: [], 1: [], 2: [], 3: [], 4: [] };
    if (!data) return gridItems;
    const results = data;

    const resolvedResults = results.map((v) =>
      buildResolvedValueItem(v, settings, startDate)
    );

    for (const item of resolvedResults) {
      const { gridStartDay, gridEndDay } = item;
      const firstGrid = Math.ceil(gridStartDay / 7) - 1;
      const lastGrid = Math.ceil(gridEndDay / 7) - 1;
      for (let i = firstGrid; i <= lastGrid; i++) {
        if (gridItems[i]) gridItems[i].push(item);
      }
    }

    gridItems[0] = sortRowItems(gridItems[0], 0);
    gridItems[1] = sortRowItems(gridItems[1], 1);
    gridItems[2] = sortRowItems(gridItems[2], 2);
    gridItems[3] = sortRowItems(gridItems[3], 3);
    gridItems[4] = sortRowItems(gridItems[4], 4);

    return gridItems;
  }, [data, settings, startDate]);
};

const useMaxItemsPerDay = (firstRowItemsRef) => {
  const [maxElems, setMaxElems] = useState();
  useLayoutEffect(() => {
    const listener = () => {
      const max = getMaxElemsPerDay(firstRowItemsRef);
      setMaxElems(max);
    };
    listener();

    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [firstRowItemsRef]);

  return maxElems;
};

const InnerCalendarMonthView = React.memo(function InnerCalendarMonthView({
  resolvedDate,
  setSelectedDate,
  settings,
  data,
  dateInfo,
  loading
}) {
  const { schema } = settings;
  const connectedResults = useMultipleEntityValueSelector(
    schema,
    data,
    selector
  );

  const { startDate, month } = dateInfo;

  const wheelTimeoutRef = useRef();

  const handleWheel = (e) => {
    if (e.ctrlKey) return;
    const isUp = e.deltaY < 0;
    const currentTimer = wheelTimeoutRef.current;
    if (currentTimer) {
      if (currentTimer.isUp === isUp) return;

      clearTimeout(currentTimer.timer);
    }

    wheelTimeoutRef.current = {
      isUp,
      timer: setTimeout(() => {
        const month = resolvedDate.getMonth();
        const year = resolvedDate.getFullYear();

        if (isUp) setSelectedDate(new Date(year, month - 1, 1));
        else setSelectedDate(new Date(year, month + 1, 1));
        wheelTimeoutRef.current = undefined;
      }, 200)
    };
  };

  const gridItems = useMonthGridItems(connectedResults, startDate, settings);

  const firstRowItemsRef = useRef();

  const maxElems = useMaxItemsPerDay(firstRowItemsRef);

  const currentDate = useMemo(() => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }, []);

  return (
    <>
      <div className="flex-1 w-100" onWheel={handleWheel}>
        <div
          className={classnames(
            "w-100 h-100 d-flex flex-column rounded",
            classes.month
          )}
        >
          <BarLoader isLoading={loading} />
          <WeekDayHeader />
          <WeekRow
            currentDate={currentDate}
            month={month}
            settings={settings}
            maxElems={maxElems}
            itemsElemRef={firstRowItemsRef}
            i={0}
            items={gridItems[0]}
            startDate={startDate}
          />
          <WeekRow
            currentDate={currentDate}
            month={month}
            settings={settings}
            maxElems={maxElems}
            i={1}
            items={gridItems[1]}
            startDate={startDate}
          />
          <WeekRow
            currentDate={currentDate}
            month={month}
            settings={settings}
            maxElems={maxElems}
            i={2}
            items={gridItems[2]}
            startDate={startDate}
          />
          <WeekRow
            currentDate={currentDate}
            month={month}
            settings={settings}
            maxElems={maxElems}
            i={3}
            items={gridItems[3]}
            startDate={startDate}
          />
          <WeekRow
            currentDate={currentDate}
            month={month}
            settings={settings}
            maxElems={maxElems}
            i={4}
            items={gridItems[4]}
            startDate={startDate}
          />
        </div>
      </div>
    </>
  );
});

const top = 250;

const CalendarMonthView = ({ selectedDate, setSelectedDate, settings }) => {
  const [resolvedDate, setResolvedDate] = useState(selectedDate);
  const refetchRef = useReftchRefProvider();
  const dateInfo = useMemo(() => {
    const month = resolvedDate.getMonth();
    const year = resolvedDate.getFullYear();
    return getDaysOfMonth(year, month);
  }, [resolvedDate]);

  const space = useSpace();

  const { params, moduleType } = useServerAwareState();
  const { filter, query } = useDetailAwareParams(params, moduleType);

  const url = useMemo(() => {
    const { BeginDateField, EndDateField, schema } = settings;
    let baseUrl = schema.getEndpoint(space.Id);

    let resFilter = buildODataFilter(filter, false, true);
    resFilter = resFilter.substring(9, resFilter.length - 1);

    const { startDate, endDate } = dateInfo;
    const calendarFilter = `(((${BeginDateField} gt ${formatDate(
      startDate
    )}) and (${BeginDateField} lt ${formatDate(
      endDate
    )})) or ((${BeginDateField} lt ${formatDate(
      startDate
    )}) and (${EndDateField} gt ${formatDate(startDate)})))`;

    resFilter = resFilter
      ? `${resFilter} and ${calendarFilter}`
      : calendarFilter;

    baseUrl += `?$inlinecount=allpages&$filter=(${resFilter})&$top=${top}${
      query ? `&query=${query}` : ""
    }`;

    return baseUrl;
  }, [dateInfo, filter, query, settings, space.Id]);

  const [data, setData] = useState([]);

  useEffect(() => {
    const { communicator } = settings;
    if (!communicator) return;

    const unsub = communicator.subscribe((e) => {
      const { type, id } = e;
      if (type !== "creation") return;
      setData((d) => [...d, id]);
      // console.log(v);
    });
    return unsub;
  }, [settings]);
  const { schema } = settings;
  const RSchema = useMemo(
    () => ({
      d: {
        results: many(schema)
      }
    }),
    [schema]
  );

  const createToast = useToast();

  const { loading, refetch } = useQuery(url, RSchema, {
    onSuccess: ({ data }) => {
      setData(data.d.results);
      // if (data.d.__count > top)
      //   createToast({
      //     pos: "tm",
      //     type: "danger",
      //     description: <FormattedMessage id="CALENDAR_GENERAL_ERROR" />,
      //     title: <FormattedMessage id="ERROR" />,
      //   });
    },
    onError: () => {
      createToast({
        pos: "tm",
        type: "danger",
        description: <FormattedMessage id="CALENDAR_GENERAL_ERROR" />,
        title: <FormattedMessage id="ERROR" />
      });
    },
    cache: false
  });

  useEffect(() => {
    refetchRef.current = refetch;
  }, [refetch, refetchRef]);

  const hasMountedRef = useRef(false);

  useEffect(() => {
    if (!hasMountedRef.current) {
      hasMountedRef.current = true;
      return;
    }

    setData([]);
    setResolvedDate(selectedDate);
  }, [selectedDate]);

  return (
    <InnerCalendarMonthView
      loading={loading}
      resolvedDate={resolvedDate}
      setSelectedDate={setSelectedDate}
      settings={settings}
      data={data}
      dateInfo={dateInfo}
    />
  );
};

export default CalendarMonthView;
