import React, { useContext } from "react";
import {
  faCodeBranch,
} from "@fortawesome/pro-solid-svg-icons";
import { useSidebar } from "../../../../Components/Sidebar/SidebarV2";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../AutomationDetailsHelper";
import { ElementsRefContext } from "../AutomationDetails";
import TargetHandles from "../Handles/TargetHandles";
import NodeBaseSidebar from "./NodeBaseSidebar";
import FormInput from "../../../../Components/Forms/FormInput/FormInput";
import { useIntl } from "react-intl";
import { ManualWorkflowDropdown } from "../../../AdvancedMultiInputs";
import AutomationTestResults from "./AutomationTestResults";
import NodeBaseContainer from "./NodeExtraOptions/NodeExtraOptions";
import NodeIconNameContainer from "./NodeIconNameContainer";

const FormContainer = ({ formState, id, canEdit }) => {
  const { form, handleChange } = formState;
  const { automationId, value } = form;

  const onChange = (e) => {
    const { value, name } = e.target;
    handleChange({
      [name]: value
    });
  };
  const intl = useIntl();
  return (
    <div>
      <FormInput
        // disabled={Id}
        name="automationId"
        className="mb-3"
        onChange={onChange}
        disableAutomation
        value={automationId}
        text={intl.formatMessage({ id: "WORKFLOW" })}
        inputType={ManualWorkflowDropdown}
      />
      <FormInput
        // disabled={Id}
        name="value"
        className="mb-3 "
        onChange={onChange}
        value={value}
        text={intl.formatMessage({ id: "VALUE" })}
        // inputType={ManualWorkflowDropdown}
      />
    </div>
  );
};

const settings = {
  defaultForm: {
    connectedWorkflow: null
  },
  FormContent: (props) => {
    return <FormContainer {...props}></FormContainer>;
  }
};

const ConnectWorkflow = React.memo(({ selected, data, id, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };

  const { enabled, config } = data;

  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <NodeIconNameContainer
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleClick}
          selected={selected}
          icon={faCodeBranch}
          text={"Connect Workflow"}
        />
      </NodeBaseContainer>
    </div>
  );
});

export default ConnectWorkflow;
