import React, { useMemo, useContext, useEffect } from "react";
import { getEntity } from "../../Helpers/IOClient";

// import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import //   faEllipsisV,
//   faTicketSimple,
"@fortawesome/pro-solid-svg-icons";
import {
  DashboardTitle,
  ReportsDateProvider,
  DashboardEmptyResponse
  //   DashboardEmptyResponse
} from "./Dashboard";
import {
  spacePipelineSchema
  //   spacePipelineStatusSchema
} from "../../config/schema";
import classnames from "classnames";
import moment from "moment";
// import { msToTime } from "../../Assets/Utils";
import { BarLoader } from "../../Components/GlobalLoader/GlobalLoader";
// import FormattedDecimal from "../../Components/FormattedDecimal/FormattedDecimal";
import { useSpace } from "../../Contexts/SpaceContext";
import { useCurrency } from "../../Hooks/EntityHooks";
import { DealsAreaChart } from "./Echarts/EvolutionDeal";
import {
  DealsPieChart,
  BigBarDealChartPerformance
} from "./Echarts/PerformanceChart";
import { DealStatusTypes } from "../Deals/DealsHelper";
import { DealsComboEvolution } from "./Echarts/DealEvolutionBarChart";
import { MultiBarChartDeals } from "./Echarts/MultiBarChartDeals";
// import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
// import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";
import {
  DealStatusGlobalCounter,
  LostReasonCounter
} from "./Echarts/DealGlobalStatusCounterChart";
import { largeNumberFormatter } from "../../Helpers/MiscHelper";
import { DealPipelineWorkloadComboLoadChart } from "./Echarts/TicketLoadBarChart";
import { FormattedMessage } from "react-intl";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import Button from "../../Components/Button/Button";
import { DashboardSettingsContext } from "./DashboardLayout";
import DashboardCardSettingsForm, {
  handleIntervalCalculation
} from "./DashboardCardSettingsForm";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import { useDashboardQuery } from "./DashboardQueryHelper";
import CreateDashboardFilterLinksHelper from "./DashboardFilterLinksHelper";
import { DealMainViewLink } from "../../Helpers/HooksHelper";
import { useCurrentAccount } from "../../Contexts/UserContext";
// import { useCurrentAccount } from "../../Contexts/UserContext";

const defaultTimeFrame = moment().subtract(180, "d").format("YYYY-MM-DD");

// const default30TimeFrame = moment()
//   .subtract(30, "d")
//   .format("YYYY-MM-DD");
const lS_Group = "dashboard-layout-deals";

export const OpenDeals = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "openDeals";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);

  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Deals/1/Count?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  // var account = useCurrentAccount().Id;
  const account = useCurrentAccount().Id;
  const globalComponent = useMemo(() => {
    const { Global } = data || {};

    if (Number(Global) > 0)
      return (
        <div
          className={classnames(
            "fs-14 ar-dashboard-active-description d-inline-block"
          )}
        >
          <FormattedMessage id={"PERIOD_TOTAL"} />
          {": "}
          {largeNumberFormatter(Global)}
        </div>
      );
    else return <></>;
  }, [data]);
  if (!loading && !error) {
    const resolvedData = data.CurrentMonth;
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden ar-dashboard-container-card">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"OPEN"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <>
            <div className="mb-2 d-flex justify-content-between align-items-center">
              <div>
                <span className="ar-dashboard-description">
                  {Settings.Interval ? (
                    <FormattedMessage id={Settings.Interval.title} />
                  ) : (
                    <FormattedMessage id={"ALL"} />
                  )}
                </span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14 mr-2">{user}</span>
              </div>
              <Button
                className="ar-dashboard-card-settings-link"
                vType="link-primary"
                onClick={() =>
                  openSidebar(
                    <DashboardCardSettingsForm
                      lS_Field={lS_Field}
                      lS_Group={lS_Group}
                      Settings={Settings}
                      closeSidebar={closeSidebar}
                      Title={"OPEN"}
                      handleChanges={handleLayoutChange}
                      AvailableSettings={{
                        interval: true,
                        scope: true,
                        pipeline: false
                      }}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faCog} />
              </Button>
            </div>
          </>
        )}
        <DealMainViewLink
          className="ar-dashboard-active-values mb-2"
          params={`$filter=PipelineStatus/Status eq '1'${CreateDashboardFilterLinksHelper(
            DataReports,
            account
          )}`}
        >
          {resolvedData}
        </DealMainViewLink>
        <div></div>
        {globalComponent}
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden ar-dashboard-container-card">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"OPEN"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <>
            <div className="mb-2 d-flex justify-content-between align-items-center">
              <div>
                <span className="ar-dashboard-description">
                  {Settings.Interval ? (
                    <FormattedMessage id={Settings.Interval.title} />
                  ) : (
                    <FormattedMessage id={"ALL"} />
                  )}
                </span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14 mr-2">{user}</span>
              </div>
              <Button
                className="ar-dashboard-card-settings-link"
                vType="link-primary"
                onClick={() =>
                  openSidebar(
                    <DashboardCardSettingsForm
                      lS_Field={lS_Field}
                      lS_Group={lS_Group}
                      Settings={Settings}
                      closeSidebar={closeSidebar}
                      Title={"OPEN"}
                      handleChanges={handleLayoutChange}
                      AvailableSettings={{
                        interval: true,
                        scope: true,
                        pipeline: false
                      }}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faCog} />
              </Button>
            </div>
          </>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const LostDeals = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "lostDeals";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Deals/3/Count?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  // var account = useCurrentAccount().Id;
  var account = useCurrentAccount().Id;
  const globalComponent = useMemo(() => {
    const { Global } = data || {};

    if (Number(Global) > 0)
      return (
        <div
          className={classnames(
            "fs-14 ar-dashboard-closed-red-description d-inline-block"
          )}
        >
          <FormattedMessage id={"PERIOD_TOTAL"} />
          {": "}
          {largeNumberFormatter(Global)}
        </div>
      );
    else return <></>;
  }, [data]);

  if (!loading && !error) {
    const resolvedData = data.CurrentMonth;
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"LOST_DEALS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"LOST_DEALS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DealMainViewLink
          className="ar-dashboard-closed-red-values mb-2"
          params={`$filter=PipelineStatus/Status eq '3'${CreateDashboardFilterLinksHelper(
            DataReports,
            account
          )}`}
        >
          {resolvedData}
        </DealMainViewLink>
        <div></div>
        {globalComponent}
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"LOST_DEALS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"LOST_DEALS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const WonDeals = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "wonDeals";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Deals/4/Count?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  var account = useCurrentAccount().Id;
  const globalComponent = useMemo(() => {
    const { Global } = data || {};

    if (Number(Global) > 0)
      return (
        <div
          className={classnames(
            "fs-14 ar-dashboard-closed-description d-inline-block"
          )}
        >
          <FormattedMessage id={"PERIOD_TOTAL"} />
          {": "}
          {largeNumberFormatter(Global)}
        </div>
      );
    else return <></>;
  }, [data]);

  if (!loading && !error) {
    const resolvedData = data.CurrentMonth;
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"SALES"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"SALES"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DealMainViewLink
          className="ar-dashboard-closed-values mb-2"
          params={`$filter=PipelineStatus/Status eq '4'${CreateDashboardFilterLinksHelper(
            DataReports,
            account
          )}`}
        >
          {resolvedData}
        </DealMainViewLink>
        <div></div>
        {globalComponent}
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"SALES"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"SALES"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const OpenDealsSum = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "openDealsSum";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Deals/1/Sum?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const { Currency: CurrencyId } = useSpace();
  const Currency = useCurrency(CurrencyId);
  //   const formatter = new Intl.NumberFormat("de-DE", {
  //     style: "decimal",
  //     minimumFractionDigits: 2
  //   });

  const globalComponent = useMemo(() => {
    const { Global } = data || {};

    if (Number(Global) > 0)
      return (
        <div
          className={classnames(
            "fs-14 ar-dashboard-active-description d-inline-block"
          )}
        >
          <FormattedMessage id={"PERIOD_TOTAL"} />
          {": "}
          {Currency && Currency.Symbol} {largeNumberFormatter(Global)}
        </div>
      );
    else return <></>;
  }, [Currency, data]);

  if (!loading && !error) {
    const resolvedData = data.CurrentMonth;
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"OPEN_DEALS_AMOUNTS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"OPEN_DEALS_AMOUNTS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <div className="ar-dashboard-active-values mb-2">
          {Currency && Currency.Symbol} {largeNumberFormatter(resolvedData)}
        </div>
        {globalComponent}
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"OPEN_DEALS_AMOUNTS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"OPEN_DEALS_AMOUNTS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const LostDealsSum = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "lostDealsSum";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Deals/3/Sum?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const { Currency: CurrencyId } = useSpace();
  const Currency = useCurrency(CurrencyId);
  //   const formatter = new Intl.NumberFormat("de-DE", {
  //     style: "decimal",
  //     minimumFractionDigits: 2
  //   });

  const globalComponent = useMemo(() => {
    const { Global } = data || {};

    if (Number(Global) > 0)
      return (
        <div
          className={classnames(
            "fs-14 ar-dashboard-closed-red-description d-inline-block"
          )}
        >
          <FormattedMessage id={"PERIOD_TOTAL"} />
          {": "}
          {Currency && Currency.Symbol} {largeNumberFormatter(Global)}
        </div>
      );
    else return <></>;
  }, [Currency, data]);

  if (!loading && !error) {
    const resolvedData = data.CurrentMonth;
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"LOST_DEALS_AMOUNTS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"LOST_DEALS_AMOUNTS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <div className="ar-dashboard-closed-red-values mb-2">
          {Currency && Currency.Symbol} {largeNumberFormatter(resolvedData)}
        </div>
        {globalComponent}
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const WonDealsSum = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "wonDealsSum";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Deals/4/Sum?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const { Currency: CurrencyId } = useSpace();
  const Currency = useCurrency(CurrencyId);
  //   const formatter = new Intl.NumberFormat("de-DE", {
  //     style: "decimal",
  //     minimumFractionDigits: 2
  //   });
  const globalComponent = useMemo(() => {
    const { Global } = data || {};

    if (Number(Global) > 0)
      return (
        <div
          className={classnames(
            "fs-14 ar-dashboard-closed-description d-inline-block"
          )}
        >
          <FormattedMessage id={"PERIOD_TOTAL"} />
          {": "}
          {Currency && Currency.Symbol} {largeNumberFormatter(Global)}
        </div>
      );
    else return <></>;
  }, [Currency, data]);

  if (!loading && !error) {
    const resolvedData = data.CurrentMonth;
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );

    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"DEALS_SALES_AMOUNTS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"DEALS_SALES_AMOUNTS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <div className="ar-dashboard-closed-values mb-2">
          {Currency && Currency.Symbol} {largeNumberFormatter(resolvedData)}
        </div>
        {globalComponent}
        {/* {(data.Global && data.Global > 0) && (
            <div
              className={classnames(
                "fs-14 ar-dashboard-won-description"
              )}
            >
              <FormattedMessage id={"PERIOD_TOTAL"} />{": "}{Currency && Currency.Symbol}{" "}{data.Global}
            </div>
          )} */}
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const AverageGlobalEvolutionClosedDeals = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "dealsAverageEvolution";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Deals/DaysEvolution?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Deals" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const { Results, HasData } = data;

    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"EVOLUTION_DEALS_GLOBAL"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"180DAYS"} />
                )}
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">{user}</span>
              </span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    disableAll={{
                      title: "180DAYS",
                      fromDate: moment()
                        .subtract(180, "days")
                        .format("YYYY-MM-DD"),
                      toDate: moment().add(1, "day").format("YYYY-MM-DD")
                    }}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"EVOLUTION_DEALS_GLOBAL"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <DealsAreaChart Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="deals">
            <FormattedMessage id={"EMPTY_DEALS"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"EVOLUTION_DEALS_GLOBAL"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"180DAYS"} />
                )}
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">{user}</span>
              </span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    disableAll={{
                      title: "180DAYS",
                      fromDate: moment()
                        .subtract(180, "days")
                        .format("YYYY-MM-DD"),
                      toDate: moment().add(1, "day").format("YYYY-MM-DD")
                    }}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"EVOLUTION_DEALS_GLOBAL"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};
export const AverageEvolutionClosedDeals = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "dealsAverageEvolution";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Deals/DaysEvolution?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }&setInterval=1`
  );
  const user =
    DataReports[0] === "Deals" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const { Results, HasData } = data;

    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"EVOLUTION_DEALS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"180DAYS"} />
                )}
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">{user}</span>
              </span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    disableAll={{
                      title: "180DAYS",
                      fromDate: moment()
                        .subtract(180, "days")
                        .format("YYYY-MM-DD"),
                      toDate: moment().add(1, "day").format("YYYY-MM-DD")
                    }}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"EVOLUTION_DEALS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <DealsAreaChart Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="deals">
            <FormattedMessage id={"EMPTY_DEALS"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"EVOLUTION_DEALS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"180DAYS"} />
                )}
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">{user}</span>
              </span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    disableAll={{
                      title: "180DAYS",
                      fromDate: moment()
                        .subtract(180, "days")
                        .format("YYYY-MM-DD"),
                      toDate: moment().add(1, "day").format("YYYY-MM-DD")
                    }}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"EVOLUTION_DEALS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const PerformanceDeals = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "dealsPerformance";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/deals/Performance?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );

  // eslint-disable-next-line no-unused-vars
  const [resolvedData, totalCount] = useMemo(() => {
    const { Results, HasData } = data || {};
    if (!HasData) return [null, 0];
    const resolvedData = {};
    let totalCount = 0;
    for (const e of Results) {
      if (e.Status === DealStatusTypes.open) {
        resolvedData.Open = [e.Count, e.Total];
        totalCount += e.Count;
      } else if (e.Status === DealStatusTypes.lost) {
        resolvedData.Lost = [e.Count, e.Total];
        totalCount += e.Count;
      } else if (e.Status === DealStatusTypes.won) {
        resolvedData.Won = [e.Count, e.Total];
        totalCount += e.Count;
      }
    }
    return [resolvedData, totalCount];
  }, [data]);

  if (!loading && !error) {
    const { HasData } = data;
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"DEALS_PERFORMANCE"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"DEALS_PERFORMANCE"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <DealsPieChart Data={resolvedData} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="deals">
            <FormattedMessage id={"EMPTY_DEALS"} />
          </DashboardEmptyResponse>
        )}
        {/* {totalCount > 0 ? (
          <div className="h-100">
            <DealsPieChart Data={resolvedData} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="deals">
            <FormattedMessage id={"EMPTY_DEALS"} />
          </DashboardEmptyResponse>
        )} */}
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"DEALS_PERFORMANCE"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"DEALS_PERFORMANCE"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const EvolutionDeals = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "dealsEvolution";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/deals/Evolution?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Deals" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const { Results, HasData } = data;

    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"DEALS_ANALYSIS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"180DAYS"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    disableAll={{
                      title: "180DAYS",
                      fromDate: moment()
                        .subtract(180, "days")
                        .format("YYYY-MM-DD"),
                      toDate: moment().add(1, "day").format("YYYY-MM-DD")
                    }}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"DEALS_ANALYSIS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <DealsComboEvolution Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="deals">
            <FormattedMessage id={"EMPTY_DEALS"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"DEALS_ANALYSIS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"180DAYS"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    disableAll={{
                      title: "180DAYS",
                      fromDate: moment()
                        .subtract(180, "days")
                        .format("YYYY-MM-DD"),
                      toDate: moment().add(1, "day").format("YYYY-MM-DD")
                    }}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"DEALS_ANALYSIS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const DealsTeamsActivity = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "dealsTeamActivity";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/deals/TeamRank?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  //   const user = DataReports[0] === "Deals" ? DataReports[2]?.Name : <FormattedMessage id={"ME"} />;
  if (!loading && !error) {
    const Results = data;

    let HasData = false;
    for (const e of Results) {
      const { Data } = e;
      for (const key in Data) {
        if (Object.hasOwnProperty.call(Data, key)) {
          const element = Data[key];
          if (parseInt(element) > 0) {
            HasData = true;
            break;
          }
        }
      }
      if (HasData) {
        break;
      }
    }
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"TEAM_ACTIVITY"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"180DAYS"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    disableAll={{
                      title: "180DAYS",
                      fromDate: moment()
                        .subtract(180, "days")
                        .format("YYYY-MM-DD"),
                      toDate: moment().add(1, "day").format("YYYY-MM-DD")
                    }}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"TEAM_ACTIVITY"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100 d-flex">
            <MultiBarChartDeals Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="deals">
            <FormattedMessage id={"EMPTY_DEALS"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"TEAM_ACTIVITY"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"180DAYS"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    disableAll={{
                      title: "180DAYS",
                      fromDate: moment()
                        .subtract(180, "days")
                        .format("YYYY-MM-DD"),
                      toDate: moment().add(1, "day").format("YYYY-MM-DD")
                    }}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"TEAM_ACTIVITY"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const DealGlobalStatus = () => {
  const { data, error, loading } = useDashboardQuery(`query/Pipelines?Type=6`, [
    spacePipelineSchema
  ]);

  if (!loading && !error) {
    return <DealsGlobalStatusChart results={data} />;
  } else {
    return <BarLoader style={{ marginTop: -16 }} isLoading={loading} />;
  }
};

export const DealsGlobalStatusChart = ({ results }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "dealsGlobalStates";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings = (DashboardSettings &&
    DashboardSettings[lS_Group] &&
    DashboardSettings[lS_Group][lS_Field]) || { Pipeline: results[0] };

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);

  const pipeline =
    DataReports[0] === "Deals" ? DataReports[3] : Settings.Pipeline;

  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Deals/Status?pipelineId=${
      pipeline ? pipeline : Settings.Pipeline
    }&todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Deals" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const { Results, HasData } = data;
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"DEALS_STATUS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
              <span className="ar-dashboard-description ml-1">
                | {<FormattedMessage id={"PIPELINE"} />}:
              </span>
              <span className="text-primary pl-1 fs-14">
                {
                  getEntity(
                    spacePipelineSchema,
                    pipeline ? pipeline : Settings.Pipeline
                  )?.Name
                }
              </span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    pipelineType={6}
                    closeSidebar={closeSidebar}
                    Title={"DEALS_STATUS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: true
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <DealStatusGlobalCounter Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="deals">
            <FormattedMessage id={"EMPTY_DEALS"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"DEALS_STATUS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
              <span className="ar-dashboard-description ml-1">
                | {<FormattedMessage id={"PIPELINE"} />}:
              </span>
              <span className="text-primary pl-1 fs-14">
                {
                  getEntity(
                    spacePipelineSchema,
                    Settings.Pipeline ? Settings.Pipeline : pipeline
                  )?.Name
                }
              </span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    pipelineType={6}
                    closeSidebar={closeSidebar}
                    Title={"DEALS_STATUS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: true
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const DealsTopClients = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "dealsTopClients";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/deals/ClientRank?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Deals" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const resolvedData = data;
    if (resolvedData.length === 0) {
      return (
        <div className="px-3 of-hidden h-100 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <DashboardTitle
              className="mb-0 fs-20"
              text={<FormattedMessage id={"TOP_ACCOUNTS"} />}
            />
            <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
          </div>
          {DataReports[0] !== "Deals" && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-2">
                <span className="ar-dashboard-description">
                  <FormattedMessage id={"INTERVAL"} />:
                </span>
                <span className="text-primary pl-1 fs-14">
                  {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                    <FormattedMessage id={Settings.Interval.title} />
                  ) : (
                    <FormattedMessage id={"ALL"} />
                  )}
                </span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">{user}</span>
              </div>
              <Button
                className="ar-dashboard-card-settings-link"
                vType="link-primary"
                onClick={() =>
                  openSidebar(
                    <DashboardCardSettingsForm
                      lS_Field={lS_Field}
                      lS_Group={lS_Group}
                      Settings={Settings}
                      closeSidebar={closeSidebar}
                      Title={"TOP_ACCOUNTS"}
                      handleChanges={handleLayoutChange}
                      AvailableSettings={{
                        interval: true,
                        scope: true,
                        pipeline: false
                      }}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faCog} />
              </Button>
            </div>
          )}
          <div className="h-100">
            <DashboardEmptyResponse className="h-50" type="deals">
              <FormattedMessage id={"NO_ACCOUNT"} />
            </DashboardEmptyResponse>
          </div>
        </div>
      );
    }
    // const profiles = resolvedData.map((e, i) => {
    //
    //   return (
    //     <ConnectedClientOrigin key={i} client={e.Client}>
    //       <ConnectedClientProfile key={i + 1} client={e.Client} justImage />
    //     </ConnectedClientOrigin>
    //   );
    // });

    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"TOP_ACCOUNTS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"180DAYS"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    disableAll={{
                      title: "180DAYS",
                      fromDate: moment()
                        .subtract(180, "days")
                        .format("YYYY-MM-DD"),
                      toDate: moment().add(1, "day").format("YYYY-MM-DD")
                    }}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"TOP_ACCOUNTS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <div className="h-100">
          {resolvedData.length !== 0 && (
            <MultiBarChartDeals Data={resolvedData} />
          )}
        </div>
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"TOP_ACCOUNTS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"180DAYS"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    disableAll={{
                      title: "180DAYS",
                      fromDate: moment()
                        .subtract(180, "days")
                        .format("YYYY-MM-DD"),
                      toDate: moment().add(1, "day").format("YYYY-MM-DD")
                    }}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"TOP_ACCOUNTS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const GlobalPerformanceDeals = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "dealsGlobalPerformance";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { Currency: CurrencyId } = useSpace();
  const Currency = useCurrency(CurrencyId);
  //   const formatter = new Intl.NumberFormat("de-DE", {
  //     style: "decimal",
  //     minimumFractionDigits: 2
  //   });
  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/deals/Performance?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );

  const resolvedData = useMemo(() => {
    if (!data) return null;
    const resolvedData = {};
    const { Results = [] } = data;
    for (const e of Results) {
      if (e.Status === DealStatusTypes.open) {
        resolvedData.Open = [e.Count, e.Total];
      } else if (e.Status === DealStatusTypes.lost) {
        resolvedData.Lost = [e.Count, e.Total];
      } else if (e.Status === DealStatusTypes.won) {
        resolvedData.Won = [e.Count, e.Total];
      }
    }
    return resolvedData;
  }, [data]);

  if (!loading && !error && data) {
    const { HasData } = data;
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"DEALS_PERFORMANCE"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"DEALS_PERFORMANCE"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <div className="fs-40 text-black pt-1">
              {Currency && Currency.Symbol}{" "}
              {largeNumberFormatter(
                resolvedData.Open[1] +
                  resolvedData.Won[1] -
                  resolvedData.Lost[1]
              )}
            </div>
            <BigBarDealChartPerformance Data={resolvedData} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="deals">
            <FormattedMessage id={"EMPTY_DEALS"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"DEALS_PERFORMANCE"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"DEALS_PERFORMANCE"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const DealLostReasonsChart = ({ results }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "dealsLostReasons";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);

  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Deals/Lost?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );

  if (!loading && !error) {
    const resolvedData = data.filter((t) => t.Total > 0);
    const body =
      resolvedData.length === 0 ? (
        <DashboardEmptyResponse className="h-100" type="deals">
          <span>
            <FormattedMessage id={"EMPTY_LOST_DEALS"} />
          </span>
        </DashboardEmptyResponse>
      ) : (
        <div className="h-100">
          <LostReasonCounter Data={resolvedData} />
        </div>
      );
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"REASONS_LOSS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"REASONS_LOSS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {body}
      </div>
    );
  } else if (!loading && error?.status === 403) {
    const user =
      DataReports[0] === "Deals" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"REASONS_LOSS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"REASONS_LOSS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <DashboardEmptyResponse type="deals">
          {DataReports ? (
            DataReports[4] === "Account" ? (
              <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
            ) : (
              <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
            )
          ) : (
            <FormattedMessage id={"EMPLOYEE"} />
          )}
        </DashboardEmptyResponse>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const PipelineWorkloadDeal = () => {
  const { data, error, loading } = useDashboardQuery(`query/Pipelines?Type=6`, [
    spacePipelineSchema
  ]);

  if (!loading && !error) {
    return <DealsStatusTimesTotals results={data} />;
  } else {
    return <BarLoader style={{ marginTop: -16 }} isLoading={loading} />;
  }
};

const DealsStatusTimesTotals = ({ results }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "PipelineWorkloadDeal";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings = (DashboardSettings &&
    DashboardSettings[lS_Group] &&
    DashboardSettings[lS_Group][lS_Field]) || { Pipeline: results[0] };

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const pipeline = DataReports[0] === "Deals" ? DataReports[3] : Settings.Pipeline;
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Deals/AverageStatus?pipelineId=${
      pipeline ? pipeline : Settings.Pipeline
    }&todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Deals" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );

  if (!loading && !error) {
    // data.splice(20, data.length - 21);
    const { Results, HasData } = data;

    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"AVERAGE_STATUS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Deals" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"DEPARTMENT"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"PIPELINE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {
                  getEntity(
                    spacePipelineSchema,
                    pipeline ? pipeline : Settings.Pipeline
                  )?.Name
                }
              </span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    pipelineType={6}
                    closeSidebar={closeSidebar}
                    Title={"AVERAGE_STATUS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: true
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            {Results.length > 0 && (
              <DealPipelineWorkloadComboLoadChart
                DifrentCloseColor={true}
                Data={Results}
              />
            )}
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="deals">
            <FormattedMessage id={"EMPTY_DEALS"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else {
    if (!loading && error?.status === 403) {
      return (
        <div className="px-3 of-hidden h-100 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <DashboardTitle
              className="mb-0 fs-20"
              text={<FormattedMessage id={"AVERAGE_STATUS"} />}
            />
            <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
          </div>
          {DataReports[0] !== "Deals" && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-2">
                <span className="ar-dashboard-description">
                  <FormattedMessage id={"INTERVAL"} />:
                </span>
                <span className="text-primary pl-1 fs-14">
                  {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                    <FormattedMessage id={Settings.Interval.title} />
                  ) : (
                    <FormattedMessage id={"ALL"} />
                  )}
                </span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"DEPARTMENT"} />:
                </span>
                <span className="text-primary pl-1 fs-14">{user}</span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"PIPELINE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">
                  {
                    getEntity(
                      spacePipelineSchema,
                      pipeline ? pipeline : Settings.Pipeline
                    )?.Name
                  }
                </span>
              </div>
              <Button
                className="ar-dashboard-card-settings-link"
                vType="link-primary"
                onClick={() =>
                  openSidebar(
                    <DashboardCardSettingsForm
                      lS_Field={lS_Field}
                      lS_Group={lS_Group}
                      Settings={Settings}
                      pipelineType={6}
                      closeSidebar={closeSidebar}
                      Title={"AVERAGE_STATUS"}
                      handleChanges={handleLayoutChange}
                      AvailableSettings={{
                        interval: true,
                        scope: true,
                        pipeline: true
                      }}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faCog} />
              </Button>
            </div>
          )}

          <DashboardEmptyResponse type="deals">
            {DataReports ? (
              DataReports[4] === "Account" ? (
                <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
              ) : (
                <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
              )
            ) : (
              <FormattedMessage id={"EMPLOYEE"} />
            )}
          </DashboardEmptyResponse>
        </div>
      );
    }

    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};
