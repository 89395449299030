import React, { Component } from "react";


const ClipboardContext = React.createContext();

class ClipboardProvider extends Component {
    constructor(props){
        super(props);
        this.state={Data: ''}
    }

    handlePasteEvent = (e) => {
		
        let file = e.clipboardData.files[0];
        if(file){
            e.stopPropagation();
            e.preventDefault();
            this.setState({Data: [file]})
        }
    }

    handlePasteEventFromEditor = (e) => {
        let file = e.data.dataTransfer._.files[0];
        if(file){
            this.setState({Data: [file]})
        }
    }

    componentDidUpdate(){
        if(this.state.Data !== ''){
            this.setState({Data: ''})
        }
        
    }

    render(){
        return (
            <ClipboardContext.Provider value={{...this.state, onPaste: this.handlePasteEvent, onPasteFromEditor: this.handlePasteEventFromEditor}}>
                {this.props.children}
            </ClipboardContext.Provider>   
        )
    }

}    

export {ClipboardContext};

export default ClipboardProvider;