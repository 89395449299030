import api from "../../api";

export const GET_SPACE_INVITE_REQUEST = "GET_SPACE_INVITE_REQUEST";
export const GET_SPACE_INVITE_SUCCESS = "GET_SPACE_INVITE_SUCCESS";
export const GET_SPACE_INVITE_ERROR = "GET_SPACE_INVITE_ERROR";
export const getInvite = inviteId => dispatch => {
  dispatch({ type: GET_SPACE_INVITE_REQUEST, inviteId });

  api
    .get(`invites/${inviteId}`)
    .then(response => {
      dispatch({
        type: GET_SPACE_INVITE_SUCCESS,
        inviteId,
        response
      });
    })
    .catch(error => {
      dispatch({ type: GET_SPACE_INVITE_ERROR, inviteId, error });
    });
};


export const CAPTURE_SPACE_INVITE_REQUEST = "CAPTURE_SPACE_INVITE_REQUEST";
export const CAPTURE_SPACE_INVITE_SUCCESS = "CAPTURE_SPACE_INVITE_SUCCESS";
export const CAPTURE_SPACE_INVITE_ERROR = "CAPTURE_SPACE_INVITE_ERROR";
export const captureInvite = inviteId => dispatch => {
  dispatch({ type: CAPTURE_SPACE_INVITE_REQUEST, inviteId });

  api
    .post(`invites/${inviteId}/capture`)
    .then(response => {
      dispatch({
        type: CAPTURE_SPACE_INVITE_SUCCESS,
        inviteId,
        response
      });
    })
    .catch(error => {
      dispatch({ type: CAPTURE_SPACE_INVITE_ERROR, inviteId, error });
    });
};
