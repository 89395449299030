import React from "react";
import { AccountProfileImg } from "../Img/Img";
import { connect } from "react-redux";
import DropdownInput, {
  DropdownInputContainer
} from "../Dropdown/DropdownInput";

const AccountDropdownInput = (props) => {
  const { account, isTyping, ListItem } = props;

  return (
    <DropdownInputContainer className="w-100">
      {account && !isTyping && (
        <AccountProfileImg
          size={ListItem ? "mds" : "sm"}
          account={account}
          ListItem
          circular
          className="mr-1 flex-0-0-auto"
        />
      )}
      <DropdownInput
        {...props}
        className="w-100"
        fallbackValue={account && account.Name}
      />
    </DropdownInputContainer>
  );
};

export default AccountDropdownInput;

const mapStateToProps = (state, ownProps) => {
  if (!ownProps.account) return {};
  return {
    account: state.Entities.Accounts[ownProps.account]
  };
};

export const ConnectedAccountDropdownInput =
  connect(mapStateToProps)(AccountDropdownInput);
