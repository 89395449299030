import React, { useMemo, useRef, useState } from "react";
import { useAirdeskTitle, createKinectHook } from "../../Hooks/MiscHooks";
import { withRouter } from "react-router";
import { useReportState, ReportDatePicker } from "./Reports";
import {
  useCurrentAccount,
  useCurrentAccountSpace
} from "../../Contexts/UserContext";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { MilSecToTime, MilSecToHour } from "../../Helpers/MiscHelper";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import "./ReportClassification.css";

import { PageContainer } from "../../Components/PageUtilities/PageUtilities";
import Button from "../../Components/Button/Button";
import Checkbox from "../../Components/Checkbox/Checkbox";
import { TimesReportsTargetDropdown } from "../AdvancedMultiInputs";
import AccountProfile from "../../Components/Accounts/AccountProfile";
import LoadableButton from "../../Components/Button/LoadableButton";
import { InterventionMainViewLink } from "../../Helpers/HooksHelper";
import moment from "moment";
import {
  hasModulePermission,
  modulePermissions,
  isAdmin
} from "../../Helpers/ModulesHelper";
// import {
//   RealTimePageProvider,
//   RealTimePageEnum
// } from "../RealTime/RealTimePages";
import { useIntl, FormattedMessage } from "react-intl";
import { v1 as uuidv1 } from "uuid";
import { useConnectionId } from "../RealTime/RealTime";
import { useToast } from "../../Components/Toast/ToastProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/pro-solid-svg-icons";
import EmptyListContainer from "../../Components/EmptyListContainer/EmptyListContainer";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";

// eslint-disable-next-line no-unused-vars
const numDaysBetween = (d1, d2) => {
  var diff = Math.abs(d1.getTime() - d2.getTime());
  return diff / (1000 * 60 * 60 * 24);
};

const FilterBegin = "$filter=";

const TimeRangeHeader = ({ time }) => {
  const header = [];

  for (const key in time) {
    if (time.hasOwnProperty(key)) {
      const days = [];
      const element = time[key];
      const dates = element.dates;
      for (const key in element.dateValues) {
        if (element.dateValues.hasOwnProperty(key)) {
          const dateV = element.dateValues[key];

          days.push(
            <div key={uuidv1()}>
              <InterventionMainViewLink
                target="_blank"
                className={`d-flex  TimeRangeDays align-items-center border-right justify-content-center ${
                  dateV.isHoliday
                    ? "holiday fw-medium"
                    : dateV.isWeekend
                    ? "weekend fw-medium"
                    : ""
                }`}
                params={
                  FilterBegin +
                  `TimeCharge/BeginDateNoHours eq datetime'${moment(
                    dates[key]
                  ).format("YYYY-MM-DD")}'`
                }
              >
                {dateV.value}
              </InterventionMainViewLink>
            </div>
          );
        }
      }
      header.push(
        <div key={uuidv1()} className="d-flex flex-column TimeRange">
          <div className=" bg-white TimeRangeMonths  border-right border-top">
            <span
              style={{ left: 250 }}
              className="position-sticky text-capitalize text-black fw-medium mx-3"
            >
              <InterventionMainViewLink
                target="_blank"
                params={
                  FilterBegin +
                  `TimeCharge/BeginDateNoHours ge datetime'${moment(
                    dates[0]
                  ).format(
                    "YYYY-MM-DD"
                  )}' and TimeCharge/EndDateNoHours le datetime'${moment(
                    dates[dates.length - 1]
                  ).format("YYYY-MM-DD")}'`
                }
              >
                {element.monthValue}
              </InterventionMainViewLink>
            </span>
          </div>
          <div className="d-flex bg-white border-top DaysContainer">{days}</div>
        </div>
      );
    }
  }
  header.push(
    <div
      key={uuidv1()}
      style={{ right: 0, marginLeft: -1, zIndex: 9 }}
      className="d-flex flex-column position-sticky"
    >
      <div
        style={{ marginLeft: -1 }}
        className="TimeRangeMonths border-right border-top bg-white"
      >
        {" "}
      </div>
      <div className="d-flex  border-left border-right">
        <div className="d-flex border-top  bg-darkerWhite align-items-center justify-content-center fw-medium TimeRangeTotal">
          <FormattedMessage id={"TOTAL"} />
        </div>
      </div>
    </div>
  );
  return (
    <div
      key={uuidv1()}
      style={{ paddingLeft: 170 }}
      className="d-flex timeContainer bg-white flex-1"
    >
      {header}
    </div>
  );
};

const DepartmentGroup = ({ report, allReports }) => {
  const result = (
    <>
      <div
        style={{ top: 83, left: 0, zIndex: 5 }}
        className="d-flex flex-1 bg-airdesk  DepartmentNameContainer position-sticky "
      >
        <div className="bg-airdesk breakWord text-truncate position-sticky border-left text-black fw-medium border-top border-bottom border-right DepartmentName">
          <InterventionMainViewLink
            target="_blank"
            params={
              FilterBegin +
              `Department/Id eq '${
                report.Department.Id
              }' and TimeCharge/BeginDateNoHours ge datetime'${moment(
                allReports.Dates[0].Date
              ).format(
                "YYYY-MM-DD"
              )}' and TimeCharge/EndDateNoHours le datetime'${moment(
                allReports.Dates[allReports.Dates.length - 1].Date
              ).format("YYYY-MM-DD")}'`
            }
          >
            {report.Department.Name}
          </InterventionMainViewLink>
        </div>
        <div className="d-flex">
          {allReports.Dates.map((e) => {
            return (
              <div
                key={uuidv1()}
                className="d-flex BasicCellWidth border-color-airdesk border-bottom CellValue align-items-center justify-content-center border-right bg-airdesk"
              >
                {" "}
              </div>
            );
          })}
          <div
            style={{ right: 0, marginLeft: -1, zIndex: 2 }}
            className="d-flex flex-column h-100 position-sticky border-right border-left border-bottom"
          >
            <div className="d-flex h-100 BasicCellWidth CellValue  align-items-center justify-content-center  bg-darkerWhite">
              {" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return result;
};

const AccountGroup = ({ report, allReports }) => {
  let totalPerCollumn = allReports.Dates.map(() => 0);

  report.Report.forEach((key, index) => {
    const indexOfIntervention = allReports.Dates.findIndex((f) => {
      return f.Date === key.Date;
    });

    totalPerCollumn[indexOfIntervention] = key.Duration;
  });

  //     return (
  //       <div key={uuidv1()} className="d-flex flex-1">
  //         {/* Valores celula */}
  //         <div className="d-flex">
  //           {allReports.Dates.map((e, i) => {
  //             let resolvedValue = "";

  //             const indexOfIntervention = allReports.Dates.findIndex(f => {
  //               console.log(key);
  //               console.log(f);
  //               return f.Date === key.Date;
  //             });

  //             if (totalPerCollumn[indexOfIntervention]) {
  //               totalPerCollumn[i] = totalPerCollumn[i] + key.Duration;
  //             } else {
  //               totalPerCollumn[i] = key.Duration;
  //             }

  //             // if (interventions[indexOfIntervention]) {
  //             //   resolvedValue = MilSecToTime(
  //             //     interventions[indexOfIntervention].Duration
  //             //   );
  //             //   totalPerStatus += interventions[indexOfIntervention].Duration;
  //             //   if (totalPerCollumn[i]) {
  //             //     totalPerCollumn[i] =
  //             //       totalPerCollumn[i] +
  //             //       interventions[indexOfIntervention].Duration;
  //             //   } else
  //             //     totalPerCollumn[i] =
  //             //       interventions[indexOfIntervention].Duration;
  //             // } else {
  //             //   resolvedValue = "-";
  //             //   if (totalPerCollumn[i]) {
  //             //     totalPerCollumn[i] = totalPerCollumn[i] + 0;
  //             //   } else totalPerCollumn[i] = 0;
  //             // }

  //             return (
  //               <div
  //                 key={uuidv1()}
  //                 className={`d-flex border-right border-bottom BasicCellWidth CellValue align-items-center justify-content-center  ${
  //                   e.IsHoliday
  //                     ? "bg-holidayStripes"
  //                     : e.IsWeekend
  //                     ? "bg-weekendStripes"
  //                     : "bg-white"
  //                 }`}
  //               >
  //                 {resolvedValue}
  //               </div>
  //             );
  //           })}
  //           {/* Total Vertical */}
  //           <div
  //             style={{ right: 0, marginLeft: -1, zIndex: 2 }}
  //             className="d-flex flex-column  h-100 position-sticky border-right border-bottom border-left"
  //           >
  //             <div className="d-flex h-100 BasicCellWidth align-items-center CellValue justify-content-center bg-darkerWhite">
  //               {totalPerStatus === 0 ? "-" : MilSecToTime(totalPerStatus)}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   });

  const result = (
    <>
      <div
        style={{ top: 128, zIndex: 4 }}
        className="d-flex flex-1 AccountNameContainer bg-airdesk position-sticky"
      >
        <div className=" fs-14  fw-medium border-left border-right border-bottom text-black bg-white AccountName position-sticky d-flex justify-content-start">
          <InterventionMainViewLink
            target="_blank"
            className="text-truncate"
            params={
              FilterBegin +
              `Account/Id eq '${
                report.Account.Id
              }' and TimeCharge/BeginDateNoHours ge datetime'${moment(
                allReports.Dates[0].Date
              ).format(
                "YYYY-MM-DD"
              )}' and TimeCharge/EndDateNoHours le datetime'${moment(
                allReports.Dates[allReports.Dates.length - 1].Date
              ).format("YYYY-MM-DD")}'`
            }
          >
            <AccountProfile
              className="text-truncate"
              account={report.Account}
            />
          </InterventionMainViewLink>
        </div>
        <div className="d-flex">
          {totalPerCollumn.map((e, index) => {
            const day = allReports.Dates[index];
            let NotBilledTimes = [];
            let NotBilledTimesDuration = 0;

            if (report.NotBillableTimes) {
              NotBilledTimes = report.NotBillableTimes.filter((t) => {
                return t.Date === day.Date;
              }).map((f) => {
                NotBilledTimesDuration += f.Duration;
                return {
                  Name: f.EntityType.Name,
                  Duration: f.Duration
                };
              });
            }

            const durationHours = MilSecToHour(e);
            const ResolvedNotBilledDuration = MilSecToTime(
              NotBilledTimesDuration
            );
            // const PositiveDuration = 8;
            let style;
            const isDayInFuture = new Date(day.Date) > new Date();
            const resolvedTime = e ? MilSecToTime(e) : "-";
            if (!isDayInFuture) {
              // if (durationHours >= PositiveDuration) {
              //   style = {
              //     background: `#E6FAF4`,
              //     color: "#0E6400"
              //   };
              // } else {
              //   const opac = 1 - (durationHours * 1) / PositiveDuration;
              //   style = {
              //     background: `rgba(255, 89, 98, ${opac})`,
              //     color: "#831F12",
              //     fontWeight: 500
              //   };
              // }
              if (durationHours > 0) {
                // style = {
                //   background: `rgba(215, 238, 230)`,
                //   color: "#0E6400 !important"
                // };
              } else {
                style = {
                  background: `#fcdbdd`,
                  color: "#FF5962 !important",
                  fontWeight: 500
                };
              }
            }

            return (
              <div
                key={index}
                style={style}
                className={`d-flex border-right border-bottom BasicCellWidth CellValue align-items-center justify-content-center  ${
                  day.IsHoliday
                    ? `font-color-Gray ${"bg-holidayStripes"}`
                    : day.IsWeekend
                    ? `font-color-Gray ${"bg-weekendStripes"}`
                    : isDayInFuture
                    ? "bg-white"
                    : ""
                }`}
              >
                {NotBilledTimesDuration > 0 ? (
                  <InformationComponent
                    className="w-100"
                    style={{ marginTop: 4 }}
                    text={
                      <div className="p-2">
                        {NotBilledTimes.map((y) => (
                          <div key={y.Name} className="d-flex ">
                            {y.Name} : {MilSecToTime(y.Duration)}
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <InterventionMainViewLink
                      target="_blank"
                      params={
                        FilterBegin +
                        `Account/Id eq '${
                          report.Account.Id
                        }' and TimeCharge/BeginDateNoHours eq datetime'${moment(
                          day.Date
                        ).format("YYYY-MM-DD")}'`
                      }
                    >
                      <div>{isDayInFuture ? "" : resolvedTime}</div>

                      <div className="NonBillableContainer">
                        {ResolvedNotBilledDuration}
                      </div>
                    </InterventionMainViewLink>
                  </InformationComponent>
                ) : (
                  <InterventionMainViewLink
                    target="_blank"
                    params={
                      FilterBegin +
                      `Account/Id eq '${
                        report.Account.Id
                      }' and TimeCharge/BeginDateNoHours eq datetime'${moment(
                        day.Date
                      ).format("YYYY-MM-DD")}'`
                    }
                  >
                    <div>{isDayInFuture ? "" : resolvedTime}</div>
                  </InterventionMainViewLink>
                )}
              </div>
            );
          })}
          <div
            style={{ right: 0, marginLeft: -1, zIndex: 2 }}
            className="d-flex flex-column h-100 position-sticky border-right border-left border-bottom"
          >
            <div className="d-flex h-100 BasicCellWidth CellValue  align-items-center justify-content-center  bg-darkerWhite">
              <InterventionMainViewLink
                target="_blank"
                params={
                  FilterBegin +
                  `Account/Id eq '${
                    report.Account.Id
                  }' and TimeCharge/BeginDateNoHours ge datetime'${moment(
                    allReports.Dates[0].Date
                  ).format(
                    "YYYY-MM-DD"
                  )}' and TimeCharge/EndDateNoHours le datetime'${moment(
                    allReports.Dates[allReports.Dates.length - 1].Date
                  ).format("YYYY-MM-DD")}'`
                }
              >
                {report.TotalHours === 0
                  ? "-"
                  : MilSecToTime(report.TotalHours)}
              </InterventionMainViewLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return result;
};

const TimeRows = ({ allReports }) => {
  const Groups = [];

  for (const report of allReports.Reports) {
    Groups.push(
      <div key={uuidv1()}>
        <DepartmentGroup report={report} allReports={allReports} />
        <div className="ReportsContainerSelector">
          {report.Reports.map((e) => {
            return (
              <div key={uuidv1()} className="AccountNameContainer">
                <AccountGroup report={e} allReports={allReports} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return Groups;
};

const useKinect = createKinectHook({});

export const TableHeader = ({ allReports }) => {
  const time = {};
  const months = moment.monthsShort();
  const abreviatedWeekDays = moment.weekdaysShort();
  const containerRef = useRef();
  useKinect(containerRef);

  for (const e of allReports.Dates) {
    const dateElement = new Date(e.Date);
    const year = moment(dateElement).format("YY");
    const month = dateElement.getMonth();

    var day = dateElement.getDay();
    const dateObj = {
      value: `${abreviatedWeekDays[day]} ${dateElement.getDate()}`,
      isWeekend: e.IsWeekend,
      isHoliday: e.IsHoliday
    };

    if (!time.hasOwnProperty(`${month}-${year}`)) {
      time[`${month}-${year}`] = {
        monthValue: `${months[month]} ${year}`,
        dateValues: [dateObj],
        dates: [dateElement]
      };
    } else {
      const tempDatevalues = time[`${month}-${year}`].dateValues;
      const tempDates = time[`${month}-${year}`].dates;
      time[`${month}-${year}`] = {
        monthValue: `${months[month]} ${year}`,
        dateValues: [...tempDatevalues, dateObj],
        dates: [...tempDates, dateElement]
      };
    }
  }
  return (
    <div className="flex-1 mt-3 of-y-auto pb-3 px-3">
      <div
        ref={containerRef}
        className="h-100 w-100 Cursor-Grab"
        style={{ overflow: "auto" }}
      >
        <div className="d-inline-flex flex-column position-relative">
          <div
            style={{ top: 0, zIndex: 20 }}
            className="d-flex position-sticky"
          >
            <TimeRangeHeader time={time} />
          </div>
          <div key={uuidv1()}>
            <TimeRows allReports={allReports} />
          </div>
        </div>
      </div>
    </div>
  );
};

const useClassificationReportState = () => {
  const currentAcount = useCurrentAccount();
  const reportState = useReportState();
  const [account, setAccount] = useState();
  const [missingRecords, setMissingRecords] = useState(false);

  const handleAccountChange = (e) => {
    setAccount(e);
  };

  const handleMissingRecordsChange = (e) => {
    const { checked } = e.target;
    setMissingRecords(checked);
  };

  return {
    currentAcount,
    reportState,
    account,
    handleAccountChange,
    handleMissingRecordsChange,
    missingRecords
  };
};

const TimeReports = withRouter((props) => {
  const intl = useIntl();
  useAirdeskTitle(intl.formatMessage({ id: "REPORTS_TIMES" }));
  const currentAcount = useCurrentAccount();
  const {
    reportState,
    account,
    handleAccountChange,
    missingRecords,
    handleMissingRecordsChange
  } = useClassificationReportState();

  const {
    reportState: reportStateTemp,
    account: accountTemp,
    handleAccountChange: handleAccountChangeTemp,
    missingRecords: missingRecordsTemp,
    handleMissingRecordsChange: handleMissingRecordsChangeTemp
  } = useClassificationReportState();

  //   const reportState = useReportState();
  //   const [account, setAccount] = useState(null)
  //   const [ accountTemp, setAccountTemp] =useState(null)
  //   const reportStateTemp = useReportState();

  const apply = () => {
    reportState[1](() => reportStateTemp[0]);
    handleAccountChange(accountTemp);
    const e = {};
    e.target = {};
    e.target.checked = missingRecordsTemp;
    handleMissingRecordsChange(e);
  };

  const [
    {
      toDateString,
      fromDateString
      // toDate,
      // fromDate
    }
  ] = reportState;

  // const [{ toDate: toDateTemp, fromDate: fromDateTemp }] = reportStateTemp;

  const isDisabled = useMemo(
    () =>
      reportState[0].toDateString === reportStateTemp[0].toDateString &&
      reportState[0].fromDateString === reportStateTemp[0].fromDateString &&
      accountTemp &&
      account &&
      accountTemp.type === account.type &&
      accountTemp.data === account.data &&
      missingRecords === missingRecordsTemp,
    [
      account,
      accountTemp,
      missingRecords,
      missingRecordsTemp,
      reportState,
      reportStateTemp
    ]
  );

  // const areTempDatesValid = useMemo(() => {
  //   const from = new Date(
  //     fromDateTemp.getFullYear(),
  //     fromDateTemp.getMonth(),
  //     fromDateTemp.getDate()
  //   );
  //   const to = new Date(
  //     toDateTemp.getFullYear(),
  //     toDateTemp.getMonth(),
  //     toDateTemp.getDate()
  //   );
  //   return from <= to && numDaysBetween(from, to) <= 600;
  // }, [fromDateTemp, toDateTemp]);

  const resolvedURL = useMemo(() => {
    return `Interventions/ReportByDepartment?fromdate=${fromDateString}${
      toDateString ? `&todate=${toDateString}` : ""
    }${
      account
        ? account.type === "Department"
          ? `&departmentid=${account.data}`
          : account.type === "Account"
          ? `&userid=${account.data}`
          : ""
        : ""
    }${missingRecords ? "&missingRecords=true" : ""}`;
  }, [account, fromDateString, missingRecords, toDateString]);

  const {
    data: allReports,
    loading,
    error,
    refetch
  } = useSpaceQuery(resolvedURL, null, { cache: false });

  const classificationGrid = useMemo(() => {
    if (loading || error)
      return (
        <div className="flex-1 d-flex align-items-center justify-content-center">
          <LoaderSpinner size="sm" className="text-primary" />
        </div>
      );
    else if (allReports) return <TableHeader allReports={allReports} />;
    else
      return (
        <EmptyListContainer type="interventions">
          <div className="fw-bold fs-24 text-black ">
            <FormattedMessage id="EMPTY_INTERVENTIONS_LIST" />
          </div>
          <div className="fs-18 text-black text-center mb-4">
            <FormattedMessage id="NO_INTERVENTION_LIST" />
          </div>
        </EmptyListContainer>
      );
  }, [allReports, error, loading]);

  //   const { data: allReports, loading, error } = useSpaceQuery(
  //     `Classification/ReportByDepartment?fromdate=2019-12-01&todate=2019-12-12`,
  //     null
  //   );

  const resolvedExcelUrl = useMemo(() => {
    return `Excel/Interventions/ReportByDepartment/ToFile?todate=${toDateString}&fromdate=${fromDateString}${
      account
        ? account.type === "Department"
          ? `&departmentid=${account.data}`
          : account.type === "Account"
          ? `&userid=${account.data}`
          : ""
        : ""
    }${missingRecords ? "&missingRecords=true" : ""}`;
  }, [account, fromDateString, missingRecords, toDateString]);

  const conId = useConnectionId();
  const createToast = useToast();
  const { refetch: fetchExcel, loading: loadingExcel } = useSpaceQuery(
    resolvedExcelUrl,
    null,
    {
      headers: {
        ConnectionID: conId
      },
      cache: false,
      autoFetch: false,
      onSuccess: ({ data }) => {
        // var blob = new Blob([data], {
        //   type:
        //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        // });
        // const link = document.createElement("a");
        // // Browsers that support HTML5 download attribute

        // const url = URL.createObjectURL(blob);
        // link.setAttribute("href", url);
        // link.setAttribute("download", "relatórios.xlsx");
        // link.style.visibility = "hidden";
        // document.body.appendChild(link);
        // link.click();
        // if (document.body.contains(link)) document.body.removeChild(link);
        createToast({
          pos: "tm",
          type: "info",
          description: intl.formatMessage({
            id: "API_EXPORT_STARTED"
          })
        });
      }
    }
  );

  const AccountSpace = useCurrentAccountSpace();

  const canExport =
    hasModulePermission(
      AccountSpace.Modules.reportsInterventions,
      modulePermissions.Export
    ) || isAdmin(AccountSpace);

  return (
    // <RealTimePageProvider pageType={RealTimePageEnum.ReportingTimeCharge}>
    <PageContainer className="ClassificationReport">
      <div className="d-flex pl-2 mb-2 align-items-center dashboardHeader bg-white">
        <div className="mr-3 flex-0-0-auto mt-3">
          <span className="fs-24 text-black">
            <FormattedMessage id={"REPORTS_TIMES"} />
          </span>
        </div>
        <div className="d-flex w-100">
          <div className="d-flex flex-column">
            <span className="fs-14 text-black mb-1">
              <FormattedMessage id={"INTERVAL"} />
            </span>
            <ReportDatePicker className="mr-3" state={reportStateTemp} />
          </div>
          <div className="mr-3">
            <div className="d-flex flex-column">
              <span className="fs-14 text-black mb-1">
                <FormattedMessage id={"SCOPE"} />
              </span>

              <TimesReportsTargetDropdown
                userId={currentAcount.Id}
                value={accountTemp}
                fixedWidth="350"
                className="mb-0"
                onChange={handleAccountChangeTemp}
              />
            </div>
          </div>
          <div className="mr-3">
            <div className="d-flex flex-column">
              <div className="mb-1" style={{ height: 21 }}></div>
              <div style={{ height: 40 }} className="d-flex align-items-center">
                <span className="fs-14 text-black mr-2">
                  <FormattedMessage id={"MISSING_RECORDS"} />
                </span>
                <div
                  style={{ width: 24, height: 24 }}
                  className="align-content-center d-flex"
                >
                  <Checkbox onChange={handleMissingRecordsChangeTemp} />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-1 justify-content-between">
            <div className="d-flex">
              <div className="d-flex  flex-column ">
                <span className="mb-1" style={{ paddingTop: 21 }}></span>

                <Button
                  disabled={isDisabled}
                  className="mr-1"
                  vtype="primary"
                  onClick={apply}
                >
                  <FormattedMessage id={"APPLY"} />
                </Button>
              </div>
              <div className="d-flex flex-column mx-2">
                <span className="mb-1" style={{ paddingTop: 21 }}></span>
                <Button
                  style={{ width: 42, minWidth: "fit-content" }}
                  className="mr-1 px-2"
                  vtype="primary"
                  onClick={refetch}
                >
                  <FontAwesomeIcon icon={faSyncAlt} />
                </Button>
              </div>
            </div>
            <div className="d-flex pr-3 flex-column justify-content-end">
              <span className="mb-1" style={{ paddingTop: 21 }}></span>
              <div style={{ width: 180 }}>
                {canExport && (
                  <LoadableButton
                    vType="primary-ghost"
                    isLoading={loadingExcel}
                    onClick={fetchExcel}
                    className="w-100"
                  >
                    <FormattedMessage id={"EXPORT_TO_EXCEL"} />
                  </LoadableButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {error || loading ? (
        <div className="flex-1 d-flex align-items-center justify-content-center">
          <LoaderSpinner size="sm" className="text-primary" />
        </div>
      ) : (
        classificationGrid
      )}
    </PageContainer>
    // </RealTimePageProvider>
  );
});

export default TimeReports;
