import React, { useRef, useEffect, useContext, useMemo } from "react";
import classnames from "classnames";
import Button from "../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { faChevronDoubleRight } from "@fortawesome/pro-regular-svg-icons";
import ReceptionServerAwareFilterList from "./ReceptionServerAwareFilterList";
import { useReceptionResizer } from "./ReceptionResizer";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import ReceptionNewConvForm from "./ReceptionNewConvForm/ReceptionNewConvForm";
import { MinimizeContext } from "./ReceptionComp";
import { useMinimizeEvents } from "./ReceptionDetails";
import {
  canUserEditModule,
  canUserReadModule,
  moduleTypes
} from "../../Helpers/ModulesHelper";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { ServerAwareFavoritesContext } from "../AppBar/ServerAwareFavorite";
import { useSpace } from "../../Contexts/SpaceContext";

// const ReceptionFilters = () => {
//   const { onParamsChange, params } = useServerAwareState();
//   const { setSkip, setResponse } = useContext(
//     ConversationMessagesFunctionContext
//   );
//   const { conversationFilter } = params;

//   const onCurrentSelectedChange = (e) => {
//     if (parseInt(conversationFilter) !== e) {
//       setResponse([]);
//       setSkip(0);
//       onParamsChange((params) => {
//         const { additionalParams } = params;
//         return {
//           ...params,
//           additionalParams: {
//             ...additionalParams,
//             conversationFilter: e,
//           },
//         };
//       });
//     }
//   };

//   const intl = useIntl();

//   return (
//     <>
//       <hr className="w-100" />
//       <div className="mb-3 fs-14 fw-medium text-black align-items-center d-flex">
//         <FontAwesomeIcon icon={faFilter} size="sm" className="mr-2" />
//         <span>
//           <FormattedMessage id="FILTER" />
//         </span>
//       </div>

//       <div className="mb-3"></div>
//     </>
//   );
// };

// const ReceptionNav = ({ onCurrentSelectedChange, status, CurrentSelected }) => {
//   return (
//     <>
//       <hr className="w-100" />
//       <div className="mb-3 fs-14 fw-medium text-black align-items-center d-flex">
//         <FontAwesomeIcon icon={faCommentsAlt} size="sm" className="mr-2" />
//         <span>
//           <FormattedMessage id="MESSAGES" />
//         </span>
//       </div>
//       <ReceptionNavFilters
//         onChange={onCurrentSelectedChange}
//         status={status}
//         CurrentSelected={CurrentSelected}
//       />
//     </>
//   );
// };

// const filterColumns = [
//   createReadFilter({
//     icon: <FontAwesomeIcon icon={faEye} />,
//     baseField: "Conversation",
//   }),
//   createUnreadFilter({
//     icon: <FontAwesomeIcon icon={faEyeSlash} />,
//     baseField: "Conversation",
//   }),
// ];

export const NewConvButton = ({ setResponse }) => {
  const [openSidebar] = useSidebar();

  const handleClick = () => {
    openSidebar(<ReceptionNewConvForm setResponse={setResponse} />);
  };

  return (
    <Button onClick={handleClick} className="w-100">
      <FormattedMessage id="NEW_CONVERSATION" />
    </Button>
  );
};

export const ReceptionSidebarNav = React.memo(({ setResponse }) => {
  const { minimized, hasSpace, toggleMinimized } = useContext(MinimizeContext);
  const { handleMouseEnter, handleMouseLeave } = useMinimizeEvents();
  const mountedRef = useRef();
  const resize = useReceptionResizer();

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      return;
    }
    const timeout = setTimeout(() => {
      resize();
    }, 400);

    return () => clearTimeout(timeout);
  }, [resize, minimized]);

  const currentUserSpace = useCurrentAccountSpace();
	const space = useSpace()
  const hasEmailPermission = useMemo(() => {
		if(!space.TrialEnd){
			return canUserReadModule(
				currentUserSpace,
				moduleTypes.emailConversations
			);
		}
	}, [currentUserSpace, space.TrialEnd]) 

  // const intl = useIntl();
  // const ordenableColums = ["Date"];

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classnames(
        "d-flex flex-column air-reception-sidenav of-hidden bg-airdesk",
        { closed: minimized, minimized: !hasSpace }
      )}
    >
      <div
        style={{ padding: "0 20px" }}
        className="justify-content-between align-items-center d-flex NavItemHeader py-4"
      >
        <span className="text-black navDescription fs-22">
          {!minimized && (
            <HeaderTitle title={<FormattedMessage id="RECEPTION" />} />
          )}
        </span>
        <div
          className={classnames(
            "align-items-center d-flex flex-1 justify-content-end",
            {
              "cursor-pointer": hasSpace,
              "cursor-default": !hasSpace
            }
          )}
          onClick={toggleMinimized}
        >
          <FontAwesomeIcon
            className="text-primary"
            size="lg"
            rotation={!minimized ? 180 : undefined}
            icon={faChevronDoubleRight}
          />
        </div>
      </div>

      {!minimized && (
        <div
          style={{ width: 280 }}
          className="d-flex flex-column overflow-auto flex-1"
        >
          {hasEmailPermission && (
            <>
              <div style={{ padding: "0 20px" }} className="w-100">
                <NewConvButton setResponse={setResponse} />
              </div>
              <hr style={{ marginLeft: 20, marginRight: 20 }} />
            </>
          )}
          <ReceptionServerAwareFilterList />
        </div>
      )}
    </div>
  );
});

const HeaderTitle = ({ title }) => {
  const favoriteItem = useContext(ServerAwareFavoritesContext);

  if (favoriteItem) {
    return (
      <div className="overflow-hidden flex-1">
        <div className="fs-18 mt-1 text-black" style={{ lineHeight: "20px" }}>
          {favoriteItem.data.title}
        </div>
        <div className="text-black fs-12">{title}</div>
      </div>
    );
  } else return <span className="fs-22 mt-1 text-black w-100">{title}</span>;
};
