import { createProjectPdfButton } from "../Pdf/PdfHelper";
import { moduleTypes } from "../../Helpers/ModulesHelper";

export const ProjectPdfButton = createProjectPdfButton({
  //   generateUrl: ({ id }) => `interventions/Export/GenerateTokenPdf/${id}/projects`,
  generateUrl: ({ id, excel }) => excel ? `Excel/ExportTimeCharges/projects/${id}/ToFile` : `PDF/ExportTimeCharges/projects/${id}/ToFile`,
  AddQueryStrings: true,

  download: true,
  moduleType: moduleTypes.projects
});
