import { useContext, useMemo } from "react";
import { MinimizeContext } from "./ReceptionComp";

export const useMinimizeEvents = () => {
  const { minimized, hasSpace, toggleMinimized } = useContext(MinimizeContext);

  return useMemo(() => {
    if (hasSpace) return {};
    let timeout;

    const removeTimer = () => {
      if (!timeout) return;
      clearTimeout(timeout);
      timeout = undefined;
    };

    if (minimized) {
      const handleMouseEnter = () => {
        timeout = setTimeout(() => {
          toggleMinimized();
        }, 200);
      };

      const handleMouseLeave = () => {
        removeTimer();
      };

      return { handleMouseEnter, handleMouseLeave };
    }

    const handleMouseLeave = () => {
      timeout = setTimeout(() => {
        toggleMinimized();
      }, 50);
    };

    const handleMouseEnter = () => {
      removeTimer();
    };

    return { handleMouseEnter, handleMouseLeave };
  }, [hasSpace, minimized, toggleMinimized]);
};
