import React, { useCallback, useContext, useEffect, useRef } from "react";
import { NotificationSoundBase64 } from "../../Assets/Sounds/NotificationSound";
import { Howl } from "howler";

const NotificationSoundContext = React.createContext();

export const useSoundPlayer = () => {
  return useContext(NotificationSoundContext);
};

const NotificationSoundProvider = ({ children }) => {
  const audioRef = useRef();

  useEffect(() => {
    const startHowl = () => {
      audioRef.current = new Howl({
        src: NotificationSoundBase64,
        volume: 1
      });
      window.removeEventListener("click", startHowl);
    };

    window.addEventListener("click", startHowl);

    return () => {
      window.removeEventListener("click", startHowl);
    };
  }, []);

  const playTheAudio = useCallback(() => {
    audioRef.current?.play();
  }, []);

  return (
    <NotificationSoundContext.Provider value={playTheAudio}>
      {children}
    </NotificationSoundContext.Provider>
  );
};

export default NotificationSoundProvider;
