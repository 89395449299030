import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import {
  useSpaceQuery,
  getEntity,
  updateNormalizedSchemaCache,
} from "../../Helpers/IOClient";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import {
  spaceInterventionsSchema,
  spaceBillingSchema,
} from "../../config/schema";
import store from "../../store";
import { useBilling } from "../../Hooks/EntityHooks";
import {
  ClassificationTargetDropdown,
  OrganizationDropdown,
  BillingDropdown,
} from "../AdvancedMultiInputs";
import createFormButton from "../../Hoc/createFormButton";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/pro-light-svg-icons";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import {
  BillingStatusTypes,
  BillingStatusDropdown,
} from "../../Components/Billing/Billing";
import { SideForm, SideFormFooter } from "../../Components/Forms/SideMenuForm";
import ClassificationPreview from "./ClassificationPreview/ClassificationPreview";
import { convertInterventionTarget, getInterventionTarget } from "../../Helpers/FormFormmaterHelper";
import { getEntityFromState } from "../../Helpers/ReduxHelper";
import { useFormState } from "../../Components/Forms";
import {
  useClassificationCallback,
  CLASSIFICATION_SUCCESS,
  getDefaultOrganizationAndOrigin,
  getClassificationTargetDefaultStatus,
} from "./ClassificationHelper";
import { useToast } from "../../Components/Toast/ToastProvider";
import { buildInterventionsState } from "./Classification";
import { originTypes } from "../../Helpers/MiscHelper";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  useSidebarSpacePost,
  useSidebarSpaceDelete,
} from "../../Components/Sidebar/SidebarV2Helper";
import CreatedBy from "../../Components/CreatedByComponent/CreatedBy";
import { useEntityRoom } from "../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimeRoomTypes } from "../RealTime/RealTimeHelper";
import { StaticAccountsInRoomStack } from "../../Components/AccountsInRoom/AccountsInRoomStack";
import { useIntl, FormattedMessage } from "react-intl";
import ClassificationOriginWarning from "./ClassificationOriginWarning";
import { useHandleError } from "../../Components/CGrid/ServerAwareHelper";
import DocTyper from "../../Components/DocTyper/DocTyper";
const InnerClassificationForm = ({ id, interventions, canDelete }) => {
  const { loading, error } = useSpaceQuery(
    id && `billing/${id}`,
    spaceBillingSchema,
    { cache: false }
  );

  if (error)
    return (
      <div className="d-flex align-items-center justify-content-center p-3 h-100">
        <div className="text-center text-black">
          <FormattedMessage id="CLASSIFICATION_ERROR" />
          {/* Houve um erro ao processar o seu pedido. Se o erro persistir contacte
          a administração */}
        </div>
      </div>
    );

  if (loading)
    return <LoaderSpinner size="sm" className="text-secondary" center />;

  return (
    <ClassificationFormBase
      key={id}
      id={id}
      interventions={interventions}
      canDelete={canDelete}
    />
  );
};

const ClassificationForm = ({ formStatus, id, interventions }) => {
  useEntityRoom(RealTimeRoomTypes.Billing, id);

  const { AccountsInRoom, CanDelete } = useBilling(id) || {};

  return (
    <BaseSidebarContainer width={900}>
      <BaseSidebarHeader>
        <div className="d-flex justify-content-between w-100">
          <div>
            <FormattedMessage id="CLASSIFICATIONS" />
          </div>
          {AccountsInRoom && AccountsInRoom.length > 0 && (
            <StaticAccountsInRoomStack
              width={200}
              accountsInRoom={AccountsInRoom}
            />
          )}
        </div>
      </BaseSidebarHeader>
      {/* <BaseSidebarContent className="of-y-auto flex-1"> */}
      <InnerClassificationForm
        canDelete={CanDelete}
        id={id}
        interventions={interventions}
      />
      {/* </BaseSidebarContent> */}
    </BaseSidebarContainer>
  );
};

const formatInterventionsState = (
  interventionsState,
  ignoreBilling = false
) => {
  const arr = [];

  for (const i of interventionsState.new) {
    arr.push({
      InputtedDuration: i.InputtedDuration,
      Id: i.intervention,
    });
  }

  if (!ignoreBilling)
    for (const i of interventionsState.billing) {
      arr.push({
        InputtedDuration: i.InputtedDuration,
        Id: i.intervention,
      });
    }

  return arr;
};

const validateForm = ({ Organization, Target, Status, Billing }, addError) => {
  if (!Organization) addError("Organization");
  if (!Target)
    addError("Target", <FormattedMessage id="CLASSIFICATION_ORIGIN_ERROR" />);
  if (Status === BillingStatusTypes.ForClassification)
    addError("Status", <FormattedMessage id="CLASSIFICATION_STATUS_ERROR" />);
  // if (Status === BillingStatusTypes.Billed && !Billing)
  //   addError(
  //     "Status",
  //     "Nº de fatura é obrigatório para classificações faturadas."
  //   );
};

//valor de Billing é usado tanto para um BillingId como um identifier
//Identifier e sempre string e Billing sempre int
const ClassificationFormBase = ({
  id,
  interventions: selectedInterventions,
  canDelete,
}) => {
  const entity = useBilling(id);

  const interventions = useMemo(() => {
    if (selectedInterventions) return selectedInterventions;
    else if (entity) return entity.TimeCharges;
    else return [];
  }, [entity, selectedInterventions]);

  const handleTranslatedError = useHandleError(spaceBillingSchema);

  const buildForm = () => {
    if (entity) {
      const {
        Organization,
        Status,
        Id,
        Identifier,
        Creator,
        InternalDescription,
      } = entity;

      const InterventionsState = buildInterventionsState([], interventions);

      return {
        InterventionsState,
        Organization,
        Status,
        Billing: Id,
        InternalDescription,
        Identifier: Identifier || "",
        Creator,
        Target: getInterventionTarget(entity),
      };
    }
    const { Organization, Target } = getDefaultOrganizationAndOrigin(
      interventions
    );

    return {
      Status: BillingStatusTypes.ForClassification,
      Organization,
      Target,
      Billing: null,
      Identifier: "",
      InterventionsState: buildInterventionsState(interventions),
    };
  };

  const [billingId, setBillingId] = useState();
  const formState = useFormState(buildForm);

  const { form, setError } = formState;

  const isUnmountedRef = useRef();
  useEffect(() => {
    return () => {
      isUnmountedRef.current = true;
    };
  }, []);

  const dispatchClassification = useClassificationCallback();

  const createToast = useToast();
  const f = useIntl().formatMessage;
  const handleSuccess = ({ data: billingId }) => {
    const interventions = {};

    const cacheBilling = getEntity(spaceBillingSchema, billingId);
    const { InterventionsState } = form;
    // console.log(form)
    createToast({
      pos: "tm",
      type: "success",
      title: `${f({ id: "CLASSIFICATION_NR" })} ${billingId}`,
      description: id
        ? f({ id: "CLASSIFICATION_UPDATE_SUCCESS" })
        : f({ id: "CLASSIFICATION_TIMECHARGE_ADDITION_SUCCESS" }),
    });

    const newBilling = { ...cacheBilling };
    if (!newBilling.TimeCharges) newBilling.TimeCharges = [];
    for (const { intervention } of InterventionsState.new) {
      interventions[intervention] = {
        ...getEntity(spaceInterventionsSchema, intervention),
        Billing: billingId,
      };

      newBilling.TimeCharges.push(intervention);
    }

    for (const i of InterventionsState.removedBilling) {
      const index = newBilling.TimeCharges.findIndex((v) => v === i);
      newBilling.TimeCharges.splice(index, 1);

      interventions[i] = {
        ...getEntity(spaceInterventionsSchema, i),
        Billing: null,
      };
    }

    updateNormalizedSchemaCache({
      [spaceInterventionsSchema.name]: interventions,
      [spaceBillingSchema.name]: { [billingId]: newBilling },
    });

    dispatchClassification({ type: CLASSIFICATION_SUCCESS });
  };

  const [post, { loading: loadingChange }] = useSidebarSpacePost(
    billingId || id ? `billing/${billingId || id}` : "billing",
    spaceBillingSchema,
    {
      onError: ({ error }) => {
        handleTranslatedError(error);
        if (
          error.data &&
          error.data.Error &&
          error.data.Error.Message === "Identifier already in use."
        ) {
          setError("Identifier", f({ id: "CLASSIFICATION_IDENTIFIER_ERROR" }));
        }
      },
      onSuccess: handleSuccess,
    }
  );

  const { Status } = form;
  const isForBilling = Status === BillingStatusTypes.ForBilling;
  // const [checkingIntegrity, setCheckingIntegrity] = useState(false);
  // const [integrity, setIntegrity] = useState(false);

  const handleSubmit = () => {
    const {
      InterventionsState,
      Organization,
      Target,
      Status,
      Identifier,
      InternalDescription,
    } = form;

    const obj = {
      InternalDescription,
      timeCharges: formatInterventionsState(InterventionsState),
      ...convertInterventionTarget(Target),
      OrganizationId: Organization,
      Identifier: isForBilling ? null : Identifier || null,
      Status,
    };

    post(obj);
  };

  // const [verifyIntegrity, { loading: loadingIntegrety }] = useSpacePost(
  //   billingId || id ? `billing/${billingId || id}/verify` : "billing/verify",
  //   null,
  //   {
  //     onSuccess: ({ data }) => {
  //       if (data && data.results && data.results.length === 0) {
  //         handleSubmit();
  //       } else {
  //         setIntegrity(data);
  //         setCheckingIntegrity(true);
  //       }
  //     },
  //   }
  // );

  const handleUpdateSubmit = (form) => {
    // const { InterventionsState, Target } = form;

    // const obj = {
    //   timeCharges: formatInterventionsState(InterventionsState, true),
    //   ...convertInterventionTarget(Target),
    // };

    // verifyIntegrity(obj);

    handleSubmit();
  };

  // if (checkingIntegrity)
  //   return (
  //     <IntegrityClassificationForm
  //       cancel={() => setCheckingIntegrity(false)}
  //       submit={handleSubmit}
  //       loading={loadingChange}
  //       formState={formState}
  //       result={integrity}
  //     />
  //   );

  return (
    <ClassificationUpdateForm
      formState={formState}
      handleSubmit={handleUpdateSubmit}
      id={id}
      setBillingId={setBillingId}
      billingId={billingId}
      interventions={interventions}
      selectedInterventions={selectedInterventions}
      loading={loadingChange}
      canDelete={canDelete}
    />
  );
};

const ClassificationUpdateForm = ({
  formState,
  handleSubmit,
  id,
  setBillingId,
  billingId,
  selectedInterventions,
  loading,
  interventions,
  canDelete,
}) => {
  const { form, setForm } = formState;

  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceBillingSchema);
  const createToast = useToast();
  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `billing/${id}`,
    spaceBillingSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        // onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "BILLING" })} nº ${id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`,
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
    }
  );

  const handleOriginChange = useCallback(
    (e) => {
      const value = e.target.value;

      setForm((f) => {
        let Status = f.Status;

        if (value) {
          const newStatus = getClassificationTargetDefaultStatus(value);
          if (newStatus) Status = newStatus;
          // switch (value.type) {
          //   case originTypes.project:
          //     Status = BillingStatusTypes.UnderProject;
          //     break;

          //   case originTypes.contract:
          //     Status = BillingStatusTypes.UnderContract;
          //     break;

          //   case originTypes.client:
          //     Status = BillingStatusTypes.UnderClient;
          //     break;

          //   case originTypes.deals:
          //     Status = BillingStatusTypes.UnderDeal;
          //     break;

          //   default:
          //     break;
          // }
        }

        return {
          ...f,
          Target: value,
          Status,
        };
      });
    },
    [setForm]
  );

  const handleOrganizationChange = useCallback(
    (e) => {
      const value = e.target.value;

      if (id) {
        setForm((f) => {
          return {
            ...f,
            Organization: value,
          };
        });
      } else {
        setBillingId(null);
        setForm((f) => {
          const newForm = {
            ...f,
            Organization: value,
            Billing: null,
            Identifier: "",
          };
          newForm.InterventionsState = {
            ...f.InterventionsState,
            billing: [],
          };
          return newForm;
        });
      }
    },
    [id, setBillingId, setForm]
  );

  const handleBillingChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (typeof value === "number") {
        setBillingId(value);
      } else setBillingId(undefined);

      setForm((f) => {
        const newForm = {
          ...f,
          // Billing: value
        };
        //billing id foi seleciondo
        if (value !== newForm.Billing) {
          if (typeof value === "number") {
            newForm.Billing = value;
            newForm.Identifier = getEntityFromState(
              store.getState(),
              spaceBillingSchema,
              value
            ).Identifier;
            newForm.InterventionsState = {
              ...f.InterventionsState,
              billing: [],
            };
          } else {
            newForm.Billing = null;
            newForm.Identifier = value;
            newForm.InterventionsState = {
              ...f.InterventionsState,
              billing: [],
            };
          }
        }

        return newForm;
      });
    },
    [setBillingId, setForm]
  );

  const { loading: loadingInterventions } = useSpaceQuery(
    billingId ? `billing/${billingId}` : null,
    spaceBillingSchema,
    {
      cache: false,
      onSuccess: ({ data: response }) => {
        const state = store.getState();
        const billing = getEntityFromState(state, spaceBillingSchema, response);

        const {
          TimeCharges,
          Status,
          Organization,
          InternalDescription,
        } = billing;

        setForm((f) => {
          return {
            ...f,
            InterventionsState: buildInterventionsState(
              selectedInterventions,
              TimeCharges
            ),
            InternalDescription,
            Status,
            Organization,
            Target: getInterventionTarget(billing),
          };
        });
      },
    }
  );

  // const GetIntervention = (e) => {
  // 	return useIntervention(e);
  // }
  // console.log(form)
  const {
    Organization,
    Billing,
    Identifier,
    Status,
    Creator,
    CreationDate,
    Target,
  } = form;

  const { data, loading: classification_loading } = useSpaceQuery(
    Target && Target.type === originTypes.contract
      ? `Classification/${Target.data}/ContractTimes`
      : null,
    null
  );

  const resolvedData = useMemo(() => {
    return Target && Target.type === originTypes.contract ? data : {};
  }, [Target, data]);

  const isForBilling = Status === BillingStatusTypes.ForBilling;
  // let Target = form.Target
  // // console.log(Target)
  // // form.InterventionsState.new.forEach((e)=>{
  // // 	const test = GetIntervention(e.intervention);
  // // 	console.log(test)
  // // })

  //   if (classification_loading) {
  //     return (
  //       <div className="w-100 h-100 text-color-link d-flex justify-content-center align-items-center">
  //         <LoaderSpinner size="sm" />
  //       </div>
  //     );
  //   }

  return (
    <SideForm
      className="of-hidden"
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      <div className="d-flex flex-column flex-1 py-3 of-y-auto">
        <div className="px-3">
          <FormInput
            name="Organization"
            text={intl.formatMessage({ id: "COMPANY" })}
            className="mb-2"
            onChange={handleOrganizationChange}
            inputType={OrganizationDropdown}
          />
          {Boolean(id) && (
            <ClassificationOriginWarning
              key={id}
              interventions={interventions}
              currentTarget={Target}
              currentStatus={Status}
              form={form}
              setForm={setForm}
            />
          )}
          <FormInput
            name="Target"
            className="mb-2"
            text={intl.formatMessage({ id: "CLASSIFICATION_ORIGIN" })}
            allClients={false}
            onChange={handleOriginChange}
            inputType={ClassificationTargetDropdown}
          />
          {/* <ContractParamsWarning value={resolvedData} /> */}
          <FormInput
            className="mb-2"
            name="Status"
            text={intl.formatMessage({ id: "STATUS" })}
            hideForClassification
            origin={Target}
            inputType={BillingStatusDropdown}
          />

          <FormInput
            name="InternalDescription"
            text={<FormattedMessage id="INTERNAL_NOTE" />}
            inputType={DocTyper}
            height={100}
            className="mb-2"
          />

          {!isForBilling &&
            (id ? (
              <FormInput
                className="mb-2"
                name="Identifier"
                text="Nº Faturação"
                // onChange={handleBillingChange}
              />
            ) : (
              <FormInput
                className="mb-2"
                name="Billing"
                text={intl.formatMessage({ id: "BILLING_NR" })}
                Organization={Organization}
                value={Billing || Identifier}
                onChange={handleBillingChange}
                inputType={BillingDropdown}
              />
            ))}

          <CreatedBy Creator={Creator} CreationDate={CreationDate} />
        </div>

        <FormInput
          className="mb-2"
          name="InterventionsState"
          originTarget={
            Target && Target.type === originTypes.contract ? "Contract" : null
          }
          InterventionsTimeLimitation={data}
          loading={loadingInterventions || classification_loading}
          contractData={resolvedData || data}
          inputType={ClassificationPreview}
        />

        {/* <div className="mb-2">
			<span className="text-black px-3">Caso confirme esta classificação, estes serão os novos totais de horas</span>
				  <SplitCol
					  left={
						  MilSecToHour(Data.EstimatedCost, true)
					  }
					  right={
						  <SpentHoursPreview
							  SpentHours={MilSecToHour(SpentHours, true)}
							  EstimatedHours={MilSecToHour(EstimatedCost)}
						  />
					  }
				  />

		</div>  */}
      </div>
      <SideFormFooter
        canDelete={canDelete}
        deleteLoading={deleteLoading}
        handleDelete={remove}
        disabled={loadingInterventions}
        isLoading={loading}
      />
    </SideForm>
  );
};

// const IntegrityClassificationForm = ({
//   formState,
//   loading,
//   submit,
//   cancel,
//   result,
// }) => {
//   const [checked, setChecked] = useState(false);
//   const { targetOrigin, results } = result;
//   const integrityInfo = useIntegrityInfo2(results);
//   const { interventions } = integrityInfo;
//   const {
//     totalChangedMiliseconds,
//     totalChanges: childrenChanges,
//   } = interventions;

//   const selectedInterventionMilisecs = useMemo(() => {
//     let milsecs = 0;
//     for (const result of results) {
//       const { SelectedTimeCharge } = result;
//       const { InputtedDuration, Duration } = SelectedTimeCharge;

//       milsecs += InputtedDuration || Duration || 0;
//     }
//     return milsecs;
//   }, [results]);

//   const f = useIntl().formatMessage;

//   if (integrityInfo.interventions.invalidItems.length > 0) {
//     return (
//       <InvalidInterventions
//         targetOrigin={targetOrigin}
//         cancel={cancel}
//         invalidInterventions={integrityInfo.interventions.invalidItems}
//       />
//     );
//   }

//   const totalMilisecs = totalChangedMiliseconds + selectedInterventionMilisecs;
//   const totalHours = MilSecToHour(totalMilisecs, true);
//   const totalChanges = childrenChanges + results.length;

//   const { Duration: targetOriginDuration } = targetOrigin;

//   const totalTargetHours = MilSecToHour(
//     (targetOriginDuration || 0) + totalMilisecs,
//     true
//   );

//   const isClient = targetOrigin.Type === "client";

//   return (
//     <div className="d-flex flex-column h-100">
//       <div className="px-3 py-3 flex-1 of-y-auto">
//         <IntegrityErrorAlert integrityInfo={integrityInfo} />

//         <div className="text-black fs-14 fw-medium">
//           <FormattedMessage id="CLASSIFICATION_WILL_CHANGE" />
//         </div>
//         <IntegrityUpdateList results={results} />
//         {/* <TimechargeList timeCharges={computedTimecharges} /> */}

//         <div className="mb-2 mt-2 text-black fs-14 fw-medium">
//           <FormattedMessage id="CLASSIFICATION_TO_NEW_ORIGIN" />
//         </div>
//         <InfoAlert className="d-inline-flex flex-wrap align-items-center w-100">
//           <b>
//             {totalChanges} {f({ id: "TIME_CHARGES_MULTIPLE" })} ({totalHours}{" "}
//             {f({ id: "HOURS_MULTIPLE" })}) &nbsp;
//           </b>
//           <span>{f({ id: "CLASSIFICATION_WILL_BE_ADDED_TO" })} &nbsp;</span>
//           <div className="d-inline-block lh-normal">
//             <DirectOriginOverview origin={targetOrigin} />
//           </div>
//           &nbsp;
//           {!isClient && (
//             <>
//               <span>{f({ id: "CLASSIFICATION_TOTAL_OF" })} &nbsp;</span>
//               <b>
//                 {totalTargetHours} {f({ id: "HOURS_MULTIPLE" })}
//               </b>
//             </>
//           )}
//         </InfoAlert>
//       </div>
//       <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
//         <Checkbox
//           disabled={loading}
//           checked={checked}
//           onChange={(e) => setChecked(e.target.checked)}
//           className="mb-0 d-flex align-items-center text-black"
//           textValue={f({ id: "CLASSIFICATION_CONFIRMATION_TEXT" })}
//         >
//           {/* <span className="ml-2 text-black">Deseja efetuar esta acção?</span> */}
//         </Checkbox>
//         <div>
//           <Button onClick={cancel} vType="link-danger" className="mr-3">
//             <FormattedMessage id={"CANCEL"} />
//           </Button>

//           <LoadableButton
//             disabled={!checked}
//             isLoading={loading}
//             onClick={submit}
//           >
//             <FormattedMessage id={"CONTINUE"} />
//           </LoadableButton>
//         </div>
//       </div>
//     </div>
//   );
// };

// const ContractParamsWarning = ({ value }) => {
//   if (value && value.IntervalMinimum && value.RoundingMargin) {
//     return (
//       <div className="mb-2 fs-12 text-warning">
//         Este Contrato apresenta Intervalo Minimo De Tempo e Margem de
//         Arredondamento
//       </div>
//     );
//   } else if (value && value.IntervalMinimum) {
//     return (
//       <div className="mb-2 fs-12 text-warning">
//         Este Contrato apresenta Intervalo Minimo De Tempo
//       </div>
//     );
//   }
//   return null;
// };

export default ClassificationForm;

export const ClassificationFormButton = createFormButton({
  Form: ClassificationForm,
  textId: "CLASSIFY",
  popupTextId: "CLASSIFICATION",
  popupEditTextId: "CLASSIFICATION",
  moduleType: moduleTypes.billing,
  altImage: <FontAwesomeIcon icon={faClipboardCheck} />,
});
