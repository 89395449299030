import React from "react";
import { useTask } from "../../../../Hooks/EntityHooks";
import {
  HistoryHeader,
  HistoryContent,
  HistoryAccountProfile,
  HistoryOverview,
  useHistoryItem,
} from "./HistoryItem";
import { ConnectedTaskOrigin } from "../../../../Containers/Origin/TaskOrigin";
import { createItemEditDetailsComponent } from "./HistoryItemDetails/HistoryItemDetails";
import { ProjectStatusProfile } from "../../../Projects/ProjectProfile";
import { FormattedMessage } from "react-intl";

const TaskHistoryOverview = () => {
  const { Task, IsCreation, Account } = useHistoryItem();
  const item = useTask(Task);
  const { Name } = item;
  if (IsCreation)
    return (
      <HistoryOverview>
        <HistoryAccountProfile account={Account} />
        <span>
          {" "}
          <FormattedMessage id={"CREATED_TASK"} />{" "}
        </span>{" "}
        <b>{Name}</b>
      </HistoryOverview>
    );

  return (
    <HistoryOverview>
      <HistoryAccountProfile account={Account} />
      <span>
        {" "}
        <FormattedMessage id={"EDITED_TASK"} />{" "}
      </span>{" "}
      <b>{Name}</b>
    </HistoryOverview>
  );
};

const TaskEditItemDetails = createItemEditDetailsComponent({
  Status: {
    NameId: "STATUS",
    ValueComponent: ProjectStatusProfile,
  },
});

const TaskHistoryDetails = () => {
  const { IsCreation } = useHistoryItem();
  if (IsCreation) return null;

  return <TaskEditItemDetails />;
};

const TaskHistoryItem = () => {
  const { Task } = useHistoryItem();

  return (
    <>
      <HistoryHeader>
        <ConnectedTaskOrigin task={Task}>
          {/* <HistoryTitle Icon={TaskIcon}>
            <FormattedMessage id={"TASK"} /> {Task}
          </HistoryTitle> */}
        </ConnectedTaskOrigin>
      </HistoryHeader>
      <HistoryContent>
        <TaskHistoryOverview />
        <TaskHistoryDetails />
      </HistoryContent>
    </>
  );
};
export default TaskHistoryItem;
