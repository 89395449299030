import React from "react";
import { BillingPlansEnum } from "./BillingPlanPayment";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";
import { FormattedMessage } from "react-intl";

const valueDict = {
  [BillingPlansEnum.starter]: {
    textId: "PLAN_STARTER",
    color: "#00c7e5",
  },
  [BillingPlansEnum.basic]: {
    textId: "PLAN_BASIC",
    color: "#5757d9",
  },
  [BillingPlansEnum.pro]: {
    textId: "PLAN_PRO",
    color: "#4b8ff3",
  },
  [BillingPlansEnum.company]: {
    textId: "PLAN_COMPANY",
    color: "#0dcf93",
  },
};

const options = [
  BillingPlansEnum.starter,
  BillingPlansEnum.basic,
  BillingPlansEnum.pro,
  BillingPlansEnum.company,
];

const ValComp = ({ item }) => {
  const { textId, color } = valueDict[item];

  return (
    <div style={{ color }}>
      <FormattedMessage id={textId} />
    </div>
  );
};

export const BillingPlanDropdown = (props) => {
  return (
    <KeyedDropdown
      options={options}
      valueComponent={ValComp}
      closeOnSelect
      {...props}
    />
  );
};
