import React, { useContext, useRef, useLayoutEffect, useCallback } from "react";
import PopperJs from "popper.js";
import Portal from "../Portal/Portal";

const PopperProvider = React.createContext();

export const usePopperResize = () => {
  const popperRef = useContext(PopperProvider);
  useLayoutEffect(() => {
    if (popperRef.current) popperRef.current.scheduleUpdate();
  });
};

export const usePopperRef = () => {
  const popperRef = useContext(PopperProvider);
  return popperRef;
};

const Popper = (props) => {
  const popperRef = useRef();
  const popupRef = useRef();
  const {
    isOpen,
    transition,
    disablePortal,
    className,
    anchorEl,
    fixed,
    placement,
    modifiers,
    children,
    ...rest
  } = props;

  const startPopper = useCallback(() => {
    if (popperRef.current) popperRef.current.destroy();
    const popper = new PopperJs(anchorEl, popupRef.current, {
      placement: placement ? placement : "bottom",
      modifiers,
      positionFixed: fixed,
    });
    window.popper = popper;
    popperRef.current = popper;
  }, [anchorEl, fixed, modifiers, placement]);

  useLayoutEffect(() => {
    return () => {
      if (popperRef.current) popperRef.current.destroy();
    };
  }, []);

  let canShow = isOpen;
  let classes = "popper";
  if (className) classes += ` ${className}`;
  if (!canShow) return null;

  return (
    <PopperProvider.Provider value={popperRef}>
      <Portal disablePortal={disablePortal} onRendered={startPopper}>
        <div className={classes} ref={popupRef} {...rest}>
          {children}
        </div>
      </Portal>
    </PopperProvider.Provider>
  );
};

export default Popper;
