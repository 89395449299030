import React, { useMemo, useRef } from "react";
import { DropdownButton } from "./Dropdown";
import { usePopupOpenState } from "../Popup/Popup";
import classnames from "classnames";
import DropdownBase from "../_Dropdown/DropdownBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";

const KeyedDropdown = (props) => {
  const {
    options,
    valueComponent: Component,
    ListComponent,
    preview,
    disabled,
    title,
    className,
    dropdownClassname,
    value,
    valueDict,
    onChange,
    arrowClassnames,
    placeholder,
    hideName,
    disabledOptions = [],
    fixedWidth,
    onTop,
    skeleton,
    closeOnSelect,
    preventChange,
    getKey,
    auto
  } = props;

  const labelRef = useRef();
  const popupRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, labelRef);

  const resolvedOptions = useMemo(() => {
    const handleChange = (v) => {
      if (closeOnSelect) toggleIsOpen(false);
      // if (name) {
      //   onChange({
      //     target: {
      //       name,
      //       value: v
      //     }
      //   });
      // } else onChange(v);

      onChange(v);
    };

    let resolvedOptions = options.map((e, i) => {
      return (
        <div
          className={classnames("ar-dropdown-list-button", {
            "disabled-dropdown-option": disabledOptions.includes(i)
          })}
          onClick={() => handleChange(e)}
          key={getKey ? getKey(e) : i}
        >
          {ListComponent ? (
            <ListComponent item={e} />
          ) : Component ? (
            <Component item={e} />
          ) : (
            e
          )}
        </div>
      );
    });

    return resolvedOptions;
  }, [
    options,
    closeOnSelect,
    toggleIsOpen,
    onChange,
    disabledOptions,
    getKey,
    ListComponent,
    Component
  ]);

  if (preview)
    return (
      <DropdownButton
        fixedWidth={fixedWidth}
        value={valueDict ? valueDict[value] : value}
        component={Component}
        preview={preview}
      />
    );

  let resolvedClasses = classnames("ar-dropdown-container", className, {
    "ar-disabled": disabled,
    "skeleton border-0": skeleton,
    "cursor-auto": preventChange,
    auto: auto
  });

  return (
    <DropdownBase
      onTop={onTop}
      className={resolvedClasses}
      // onClick={this.showMenu}
      onClose={toggleIsOpen}
      domRef={labelRef}
      tabIndex="1"
      // onKeyDown={keyDownHandler}
      isOpen={isOpen}
      fixedWidth={fixedWidth}
      popup={
        <div ref={popupRef} className="ar-dropdown-menu">
          {resolvedOptions}
        </div>
      }
    >
      {!skeleton && (
        <label
          title={title}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          onClick={preventChange || disabled ? undefined : toggleIsOpen}
          className={classnames("ar-dropdown-button", {
            "cursor-auto": preventChange
          })}
        >
          <div className={classnames("flex-1", dropdownClassname)}>
            {value !== undefined && Component ? (
              <Component hideName={hideName} item={value} />
            ) : !value && placeholder ? (
              <span style={{ color: "#7C98B6" }}>{placeholder}</span>
            ) : (
              value
            )}
          </div>
          {!preventChange && (
            <FontAwesomeIcon className={arrowClassnames} icon={faCaretDown} />
          )}
        </label>
      )}
    </DropdownBase>
  );
};

export default KeyedDropdown;
