import * as SpaceActions from "../../Actions/Space";

//set initial auth state by verifying if token exists
const initialAuthState = {
  isProcessing: false,
  error: null
};

function settingsPermissionsPage(state = initialAuthState, action) {
  switch (action.type) {
    case SpaceActions.UPDATE_SPACE_USERS_MODULES_REQUEST:
      return {
        ...state,
        isProcessing: true
      };

    case SpaceActions.UPDATE_SPACE_USERS_MODULES_SUCCESS:
      return {
        ...state,
        isProcessing: false
      };

    case SpaceActions.UPDATE_SPACE_USERS_MODULES_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: action.error
      };

    case SpaceActions.REMOVE_SETTINGS_PERMISSIONS_ERROR:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
}

export default settingsPermissionsPage;
