import React, { useContext } from "react";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import SourceHandles from "../../Handles/SourceHandles";
import {
  IfElseConditionEnum,
  IfElseTriggerTypeEnum,
  TriggerTypeEnum
} from "../../NodeTypes";
import AutomationTrigger from "./AutomationTrigger";
import { ElementsRefContext } from "../../AutomationDetails";
import NodeBaseSidebar from "../NodeBaseSidebar";
import { IfElseFormContainer } from "../IFNode";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";

const TriggerTypeOptions = [
  IfElseTriggerTypeEnum.any,
  IfElseTriggerTypeEnum.creation,
  IfElseTriggerTypeEnum.statusChange,
  IfElseTriggerTypeEnum.updates,
  IfElseTriggerTypeEnum.renew
];

const convertAutomationToForm = (form) => {
  const { trigger_type, ...rest } = form;

  const resolvedTriggerType = TriggerTypeOptions.includes(trigger_type)
    ? trigger_type
    : IfElseTriggerTypeEnum.any;

  return {
    ...rest,
    trigger_type: resolvedTriggerType
  };
};

const settings = {
  defaultForm: {
    items: [],
    condition: IfElseConditionEnum.all,
    trigger_type: IfElseTriggerTypeEnum.any
  },
  convertAutomationToForm: convertAutomationToForm,
  includesSelf: true,
  FormContent: (props) => {
    return <IfElseFormContainer triggerType {...props}></IfElseFormContainer>;
  }
};

const TriggerCreation = ({
  data,
  id,
  schema,
  icon,
  text,
  type,
  selected,
  TargetPreview
}) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        type={type}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };
  const { enabled, config } = data;

  return (
    <div className="position-relative">
      <AutomationTestResults id={id} type={type} />
      <NodeBaseContainer type={type} data={data} id={id}>
        <SourceHandles />
        <AutomationTrigger
          enabled={enabled}
          condition={data?.form?.trigger_type}
          TargetPreview={TargetPreview}
          selected={selected}
          text={text}
          config={config}
          type={type}
          onDoubleClick={handleClick}
          icon={icon}
        />
      </NodeBaseContainer>
    </div>
  );
};

export default TriggerCreation;
