import React, { useMemo } from "react";
// import SplitCol from "../../Components/SplitCol/SplitCol";

import { useFormState, createFormRequestHook } from "../../Components/Forms";
import {
  spaceProjectsSchema,
  spaceOrganizationSchema,
  spacePipelineSchema,
  spaceDealsSchema,
  spacePipelineStatusSchema,
  spaceClientSchema
} from "../../config/schema";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../Components/Forms/SideMenuForm";
import {
  entityActionType,
  originTypes,
  handleError
} from "../../Helpers/MiscHelper";
import {
  formatProjectForm,
  getInterventionTarget
} from "../../Helpers/FormFormmaterHelper";
import {
  defaultProjectForm,
  validateProjectForm,
  convertProjectEntityToForm,
  projectCommunicator
} from "./ProjectsHelper";
import { useProject, usePipelineStatus } from "../../Hooks/EntityHooks";
import { useToast } from "../../Components/Toast/ToastProvider";
import { useManagerVerifier } from "../Form/FormHelper";

import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";
// import { useCurrentAccount } from "../../Contexts/UserContext";
// import ProjectCategoryForm from "../Settings/ProjectCategories/ProjectCategoryForm";
// import ProjectCategories from "../Settings/ProjectCategories/ProjectCategories";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";

import {
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import {
  isOrganzationRequestValid,
  OrganizationRequestSideError
} from "../../Components/Organizations/OrganizationHelper";
import { useHandleError } from "../../Components/CGrid/ServerAwareHelper";
import { ProjectStatusTypes } from "./ProjectsStatusHelper";
import ProjectFormContent from "./ProjectFormContent";
import { useIntl } from "react-intl";
import {
  FormTemplateProvider,
  useFormTemplateGetter
} from "../../Components/Forms/FormTemplatesHelper";
import { useSpaceCurrenciesFetcher } from "../../Components/Currency/Currency";

const useProjectRequest = createFormRequestHook({
  schema: spaceProjectsSchema,
  dependencies: [
    {
      schema: spaceClientSchema,
      getEntityId: ({ clientId }) => clientId,
      convertEntityToForm: (entity, props) => {
        const { Id, Files } = entity;
        return {
          ProjectTarget: {
            type: originTypes.client,
            data: Id
          },
          AssociatedFiles: Files
        };
      }
    },
    {
      schema: spaceDealsSchema,
      getEntityId: ({ dealId }) => dealId,
      convertEntityToForm: (entity, props) => {
        const {
          Id,
          Contact,
          Company,
          SalesOpportunity,
          Name,
          Files,
          Description,
          Departments,
          Collaborators,
          Followers,
          Assigned
        } = entity;
        const { Accounts, Teams } = Collaborators || {};
        let data = [];
        if (Accounts || Teams) {
          if (Accounts && Array.isArray(Accounts)) {
            for (let i = 0; i < Accounts.length; i++) {
              if (Accounts[i].type === "Account" && Accounts[i].data)
                data.push(Accounts[i]);
              else data.push({ type: "Account", data: Accounts[i] });
            }
          }
          if (Teams && Array.isArray(Teams)) {
            for (let i = 0; i < Teams.length; i++) {
              if (Teams[i].type === "Team" && Teams[i].data)
                data.push(Teams[i]);
              else data.push({ type: "Team", data: Teams[i] });
            }
          }
        }
        return {
          ProjectTarget: {
            type: originTypes.deals,
            data: Id
          },
          Contact,
          Company,
          Name,
          Description,
          Departments,
          Collaborators,
          TeamCollab: data,
          Manager: Assigned,
          Followers,
          AssociatedFiles: Files,
          SalesOpportunity
        };
      }
    }
  ],
  convertEntityToForm: convertProjectEntityToForm,
  formatDuplicationEntity: (entity) => {
    const { PipelineStatus } = entity;
    if (PipelineStatus) {
      const pipelineStatusEntity = getEntity(
        spacePipelineStatusSchema,
        PipelineStatus
      );
      if (pipelineStatusEntity.Status !== ProjectStatusTypes.open) {
        entity.PipelineStatus = null;
      }
    }
    return entity;
  }
});

const ProjectForm = (props) => {
  const { key, loading, error, convertedForm, isChild, childProps, baseForm } =
    useProjectRequest(props);
  ////;
  const { id, defaultForm, onSuccess, className } = props;

  const project = useProject(id);
  //   const user = useCurrentAccount();

  const orgState = useSpaceQuery(
    `query/organizations`,
    [spaceOrganizationSchema],
    {
      cache: false
    }
  );

  const { loading: loadingOrganizations, data: organizations } = orgState;

  const {
    loading: loadingCurrencies,
    // error: errorCurrencies,
    data: currencies
  } = useSpaceCurrenciesFetcher();

  const resolvedForm = useMemo(() => {
    if (!currencies) return;
    let form = {
      ...defaultForm,
      ...convertedForm,
      ...baseForm
    };
    if (childProps) {
      form.ProjectTarget =
        getInterventionTarget(childProps) || form.ProjectTarget;
    }

    const { EstimatedCosts } = form;

    if (EstimatedCosts) {
      const EstimatedCostsDict = {};

      for (const val of EstimatedCosts) {
        EstimatedCostsDict[val.CurrencyId] = val;
      }

      for (const curr of currencies) {
        if (!EstimatedCostsDict[curr]) {
          EstimatedCostsDict[curr] = {
            Cost: 0,
            CurrencyId: curr
          };
        }
      }

      form.EstimatedCostsDict = EstimatedCostsDict;
    }

    if (
      !id &&
      organizations &&
      organizations.findIndex((e) => e === form.Company) === -1
    ) {
      form.Company = organizations[0];
    }

    return form;
  }, [
    baseForm,
    childProps,
    convertedForm,
    currencies,
    defaultForm,
    id,
    organizations
  ]);

  const { data: formTemplates, refetch: formTemplateRefetch } =
    useFormTemplateGetter(spaceProjectsSchema);

  const {
    loading: loadingPipelines,
    data: pipelines,
    error: errorPipelines
  } = useSpaceQuery(`query/pipelines?type=${PipelineTypeEnum.Project}`, [
    spacePipelineSchema
  ]);

  if (
    loadingPipelines ||
    errorPipelines ||
    loadingCurrencies ||
    loading ||
    error ||
    loadingOrganizations
  )
    return <LoaderSpinner center size="sm" className="text-secondary" />;

  if (!isOrganzationRequestValid(orgState))
    return <OrganizationRequestSideError requestState={orgState} />;

  const canEdit = project ? project.CanEdit : true;

  return (
    <FormTemplateProvider forms={formTemplates} refetch={formTemplateRefetch}>
      <ProjectFormBase
        className={className}
        onSuccess={onSuccess}
        id={id}
        currencies={currencies}
        canEdit={canEdit}
        canDelete={project?.CanDelete}
        pipelines={pipelines}
        key={key}
        convertedForm={resolvedForm}
        isChild={isChild}
        organizations={organizations}
      />
    </FormTemplateProvider>
  );
};

const ProjectFormBase = ({
  convertedForm,
  id,
  canEdit,
  onSuccess,
  isChild,
  currencies,
  canDelete,
  pipelines,
  organizations,
  className
}) => {
  const formState = useFormState(() => {
    const EstimatedCostsDict = {};

    for (const currency of currencies) {
      EstimatedCostsDict[currency] = {
        Cost: 0,
        CurrencyId: currency
      };
    }

    return {
      EstimatedCostsDict,
      ...defaultProjectForm,
      ...convertedForm
    };
  });
  const { form, handleChange, setForm } = formState;
  const {
    // Name,
    ManagerAccount,
    DepartmentTeam,
    // Creator,
    // Image,
    Company,
    // BeginDate,
    // EndDate,
    StatusDetailDescription,
    Pipeline,
    // Manager,
    // // ProjectType,
    // CreationDate,
    PipelineStatus,
    StatusDetailId,
    StatusDetail
    // // Comments,
    // ProjectTarget,
    // Followers,
    // TeamCollab,
    // IndirectClient,
    // Client
  } = form;

  const handleTranslatedError = useHandleError(spaceProjectsSchema);

  const intl = useIntl();
  const createToast = useToast();

  const PipelineStatusId = PipelineStatus;
  const PipelieStatusObj = usePipelineStatus(PipelineStatusId);
  // useMarkRead(spaceProjectsSchema, id, Manager);
  const [post, { loading }] = useSidebarSpacePost(
    id ? `projects/${id}` : "projects",
    spaceProjectsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        if (!id) {
          projectCommunicator.dispatch({
            type: entityActionType.creation,
            id: newId
          });
          defineChildProps({ Project: newId });
        } else {
          projectCommunicator.dispatch({
            type: entityActionType.update,
            id
          });
        }
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "PROJECT" })} nº ${newId || id}`,
          description: !id
            ? `${intl.formatMessage({ id: "CREATED_SUCCEFULLY" })}`
            : `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`
        });
      },
      onError: ({ error }) => {
        handleError(createToast, error);
        // handleTranslatedError(error);
      }
    }
  );

  if (
    !Pipeline &&
    pipelines &&
    pipelines.length > 0 &&
    pipelines.length === 1
  ) {
    handleChange({
      Pipeline: pipelines[0]
    });
  }

  const handleSubmit = (form) => {
    post(formatProjectForm(form, currencies));
  };

  const validatingManager = useManagerVerifier(ManagerAccount, DepartmentTeam, {
    onUpdateManager: (manager) => {
      handleChange({
        ManagerAccount: manager
      });
    }
  });

  if (organizations.length === 1 && !Company) {
    handleChange({
      Company: organizations[0]
    });
  }

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `projects/${id}`,
    spaceProjectsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "PROJECT" })} nº ${id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  if (
    PipelieStatusObj &&
    PipelieStatusObj.Status !== ProjectStatusTypes.canceled &&
    (Boolean(StatusDetailId) ||
      Boolean(StatusDetail) ||
      Boolean(StatusDetailDescription))
  ) {
    handleChange({
      StatusDetailId: "",
      StatusDetail: "",
      StatusDetailDescription: ""
    });
  }

  // const followers = useJoinedFollowerAccounts(Manager, remainingAccounts);
  // const possibleFollowers = useAvailableFollowers(Creator, followers);

  return (
    <SideForm
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateProjectForm}
    >
      <SideFormContent className={className}>
        <ProjectFormContent
          formState={formState}
          canEdit={canEdit}
          organizations={organizations}
          pipelines={pipelines}
          currencies={currencies}
          id={id}
        />
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        deleteLoading={deleteLoading}
        handleChange={setForm}
        form={form}
        schema={spaceProjectsSchema}
        handleDelete={remove}
        disabled={validatingManager}
        isLoading={loading}
      />
    </SideForm>
  );
};

export default ProjectForm;

// const ProjectFormComp = props => {
// 	const { setFormState, formStatus, form, intl } = props;

// 	const handleClientChange = e => {
// 		setFormState({
// 			Client: e.target.value,
// 			Contact: null
// 		});
// 	};

// 	const { Client, Name, Image, Creator } = form;

// 	const {
// 		entityId,
// 		entity,
// 		validateForm,
// 		isProcessing,
// 		validation
// 	} = formStatus;

// 	var openIncompleteProjectForm = useIncompleteProjectForm(form, entityId);

// 	const footerSubComponent = entity && entity.CanEdit && entity.CanCancel && (
// 		<Button
// 			className="mr-2"
// 			vType="outline-danger"
// 			disabled={isProcessing || validation.hasErrors}
// 			onClick={() => validateForm() && openIncompleteProjectForm()}
// 		>
// 			Cancelar Projeto
//     </Button>
// 	);

// 	return (
// 		<SideMenuForm
// 			formStatus={formStatus}
// 			footerSubComponent={footerSubComponent}
// 		>
// 			<FormInput
// 				name="Image"
// 				inputType={ProfilePicture}
// 				uploadText={
// 					<div className="link-color fs-12 mt-1">
// 						<FontAwesomeIcon icon={faCamera} />{" "}
// 						<FormattedMessage id="upload_photo" />
// 					</div>
// 				}
// 				placeHolderComponent={
// 					<AccountProfileImg className="rounded-50" account={{ Name, Image }} />
// 				}
// 			/>
// 			<div className="d-flex align-items-center my-2 flex-0-0-auto">
// 				<FontAwesomeIcon className="mr-2" icon={faSuitcase} />
// 				<span className="fs-14 fw-medium text-black">
// 					{intl.formatMessage({ id: "customer" })}
// 				</span>
// 				<span className="title-id">{Client}</span>
// 			</div>
// 			<FormInput
// 				name="Client"
// 				inputType={ClientDropdown}
// 				value={Client}
// 				onChange={handleClientChange}
// 			/>
// 			<SplitCol
// 				className="my-3"
// 				left={
// 					<FormInput
// 						name="ReceivedDate"
// 						text={intl.formatMessage({ id: "entry" })}
// 						inputType={ArDatePicker}
// 						componentClassName="w-100"
// 						openHourAfterDate
// 					/>
// 				}
// 				right={
// 					<FormInput
// 						name="Contact"
// 						text={intl.formatMessage({ id: "contact" })}
// 						inputType={ContactDropdown}
// 						Client={Client}
// 					/>
// 				}
// 			/>

// 			<SplitCol
// 				className="my-3"
// 				left={
// 					<FormInput text={intl.formatMessage({ id: "name" })} name="Name" />
// 				}
// 				right={
// 					<FormInput
// 						name="SalesOpportunity"
// 						text={intl.formatMessage({ id: "sales_opportunity" })}
// 					/>
// 				}
// 			/>

// 			<SplitCol
// 				className="my-3"
// 				left={
// 					<FormInput
// 						name="BeginDate"
// 						text={intl.formatMessage({ id: "start" })}
// 						inputType={ArDatePicker}
// 						componentClassName="w-100"
// 						enableHours={false}
// 					/>
// 				}
// 				right={
// 					<FormInput
// 						name="EstimatedCost"
// 						text={intl.formatMessage({ id: "estimated_time_hours" })}
// 						inputType={HourInput}
// 					/>
// 				}
// 			/>
// 			<SplitCol
// 				className="my-3"
// 				left={
// 					<FormInput
// 						name="EndDate"
// 						text={intl.formatMessage({ id: "end" })}
// 						inputType={ArDatePicker}
// 						componentClassName="w-100"
// 						enableHours={false}
// 					/>
// 				}
// 				right={
// 					<FormInput
// 						name="Manager"
// 						text={intl.formatMessage({ id: "responsible" })}
// 						inputType={UserDropdown}
// 					/>
// 				}
// 			/>

// 			<FormInput
// 				name="Departments"
// 				className="mb-3"
// 				text={intl.formatMessage({ id: "departments" })}
// 				inputType={DepartmentDropdown}
// 				multiple
// 			/>

// 			<FormInput
// 				name="TeamCollab"
// 				className="mb-3"
// 				text={intl.formatMessage({ id: "teams_contributors" })}
// 				inputType={TeamCollabDropdown}
// 				multiple
// 			/>
// 			<ClipboardProvider>
// 				<FormInput
// 					className="mb-3"
// 					text={intl.formatMessage({ id: "description" })}
// 					inputType={HTMLEditor}
// 					name="Description"
// 				/>

// 				<FormInput
// 					className="mb-3"
// 					text={intl.formatMessage({ id: "responsible_description" })}
// 					inputType={HTMLEditor}
// 					name="InternalDescription"
// 				/>

// 				{entityId && (
// 					<FormInput
// 						text={intl.formatMessage({ id: "status" })}
// 						name="Status"
// 						inputType={ProjectStatusDropdown}
// 						hideCancellation={!isEntityStatusCancelled(entity)}
// 						className="mb-3"
// 					/>
// 				)}

// 				<FormInput
// 					text={intl.formatMessage({ id: "files" })}
// 					name="Files"
// 					enableSwapper
// 					className="mb-3 form-Anexos-floater"
// 					inputType={FileUploader}
// 				/>
// 				{Creator &&
// 					<>
// 						<div className="mb-1 fs-14 fw-medium text-black">
// 							<span>
// 								Criado por
// 			</span>
// 						</div>
// 						<div className="mb-3 fs-14 fw-medium text-black">
// 							<ConnectedAccountProfile account={Creator} />
// 						</div>
// 					</>
// 				}
// 			</ClipboardProvider>
// 		</SideMenuForm>
// 	);
// };

// const ProjectForm = withSpaceEntityForm(
//   withSpace(injectIntl(ProjectFormComp)),
//   {
//     defaultForm: defaultProjectForm,
//     validateForm: validateProjectForm,
//     schema: spaceProjectsSchema,
//     dependencies: [
//       {
//         schema: spaceClientSchema,
//         getEntityId: ({ clientId }) => clientId,
//         convertEntityToForm: (entity, props) => {
//           const { Id } = entity;
//           return {
//             Client: Id
//           };
//         }
//       }
//     ],
//     // getEntityId: (state, ownProps) => ownProps.projectId,
//     convertEntityToForm: convertProjectEntityToForm,
//     formatForm: formatProjectForm,
//     onSuccess: ({ openSidebar, form, entityId, createToast}) => {
//       createToast({pos: "br",type: "success",description: `Projeto gravado com Sucesso`})
// 	},
// 	onError: ({error, createToast}) => {
// 		handleError(createToast, error)
// 	}
//   }
// );

// const { setFormState, formStatus, form, intl } = props;

// const handleClientChange = e => {
// 	setFormState({
// 		Client: e.target.value,
// 		Contact: null
// 	});
// };

// const { Client, Name, Image, Creator } = form;

// const {
// 	entityId,
// 	entity,
// 	validateForm,
// 	isProcessing,
// 	validation
// } = formStatus;

// var openIncompleteProjectForm = useIncompleteProjectForm(form, entityId);

// const footerSubComponent = entity && entity.CanEdit && entity.CanCancel && (
// 	<Button
// 		className="mr-2"
// 		vType="outline-danger"
// 		disabled={isProcessing || validation.hasErrors}
// 		onClick={() => validateForm() && openIncompleteProjectForm()}
// 	>
// 		Cancelar Projeto
// </Button>
// );

// var openIncompleteProjectForm = useIncompleteProjectForm(form, entityId);
// let CallItem = useCall(id ? id : undefined);
// if (!CallItem) {
// 	CallItem = {};
// 	CallItem.Comments = [];
// }
// const { Comments } = CallItem;

// const [c, setC] = useState(Comments);

// const addComment = c => {
// 	return
// };

// const formState = useFormState(() => {
// 	return {
// 		...defaultProjectForm({ user }),
// 		...convertedForm
// 	};
// });
// const { form, handleChange } = formState;
// const { CallTarget, ManagerAccount, DepartmentTeam, Creator } = form;

// const createToast = useToast();

// const [post, { loading }] = useSidebarPost(
// 	id ? `calls/${id}` : "calls",
// 	spaceCallsSchema,
// 	{
// 		onSuccess: ({ openSidebar, data: newId }) => {
// 			onSuccess && onSuccess();

// 			createToast({
// 				pos: "br",
// 				type: "success",
// 				title: `Chamada nº ${newId || id}`,
// 				description: `Gravada com Sucesso`
// 			});
// 		},
// 		onError: ({ error }) => {
// 			handleError(createToast, error)
// 		}
// 	}
// );

// const validatingManager = useManagerVerifier(ManagerAccount, DepartmentTeam, {
// 	onUpdateManager: manager => {
// 		handleChange({
// 			ManagerAccount: manager
// 		});
// 	}
// });

// const handleCallTargetChange = e => {
// 	handleChange({
// 		CallTarget: e.target.value,
// 		Contact: null
// 	});
// };

// const handleSubmit = form => {
// 	post(formatCallForm(form));
// };
