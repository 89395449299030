import React, { useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import AddressSelector from "../../Components/AddressSelector/AddressSelector";
import CreatedBy from "../../Components/CreatedByComponent/CreatedBy";
import ArDatePicker from "../../Components/DatePicker/ArDatePicker";
import DocTyper from "../../Components/DocTyper/DocTyper";
import {
  updateManagerFollowers,
  useCanEditFollowers
} from "../../Components/EntityFollow/EntityFollowHelper";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import HTMLInput from "../../Components/HTMLInput/HTMLInput";
import { SidebarAutomationContext } from "../../Components/Sidebar/SidebarV2Automation";
import { useCall, usePipelineStatus } from "../../Hooks/EntityHooks";
import {
  callCancellationReasonsDropdown,
  CallTargetDropdown,
  ContactDropdown,
  DepartmentTeamDropdown,
  OrganizationDropdown,
  UserDropdown,
  CallTagDropdown
} from "../AdvancedMultiInputs";
import {
  CallPipelineDropdown,
  CallStatusPipelineDropdown
} from "../AdvancedMultiInputs/PipelineDropdowns";
import { useManagerVerifier } from "../Form/FormHelper";
import { CallStatusTypes } from "./CallsHelper";
import { originTypes } from "../../Helpers/MiscHelper";
import { getEntity, useSpaceQuery } from "../../Helpers/IOClient";
import {
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceTasksSchema,
  spaceContractSchema,
  spaceCallsSchema,
  spaceDealsSchema
} from "../../config/schema";

const CallFormContent = ({
  formState,
  organizations,
  pipelines,
  id,
  canEdit
}) => {
  const { form, handleChange } = formState;

  const {
    CallTarget,
    ManagerAccount,
    DepartmentTeam,
    Creator,
    CreationDate,
    Pipeline,
    PipelineStatus,
    Contact,
    StatusDetail,
    IndirectClient,
    StatusDetailDescription,
    Company,
    // Comments,
    StatusDetailId,
    Followers,
    Client
  } = form;
  const PipelineId = Pipeline;
  const PipelineStatusId = PipelineStatus;
  const PipelieStatusObj = usePipelineStatus(PipelineStatusId);

  const validatingManager = useManagerVerifier(ManagerAccount, DepartmentTeam, {
    onUpdateManager: (manager) => {
      handleChange({
        ManagerAccount: manager,
        Followers: updateManagerFollowers(
          Followers,
          ManagerAccount,
          manager,
          []
        )
      });
    }
  });

  // if (
  //   !Pipeline &&
  //   pipelines &&
  //   pipelines.length > 0 &&
  //   pipelines.length === 1
  // ) {
  //   handleChange({
  //     Pipeline: pipelines[0]
  //   });
  // }

  // if (organizations.length === 1 && !Company) {
  //   handleChange({
  //     Company: organizations[0]
  //   });
  // }

  const handleCallTargetChange = (e) => {
    handleChange({
      CallTarget: e.target.value
      // Contact: null
    });
  };

  const contactValidationURL = useMemo(() => {
    if (!Contact) return null;

    let projectId, ticketId, taskId, contractId, clientId, callId, dealId;

    if (CallTarget) {
      switch (CallTarget.type) {
        case originTypes.project:
          projectId = CallTarget.data;
          break;

        case originTypes.ticket:
          ticketId = CallTarget.data;
          break;

        case originTypes.task:
          taskId = CallTarget.data;
          break;

        case originTypes.contract:
          contractId = CallTarget.data;
          break;

        case originTypes.client:
          clientId = CallTarget.data;
          break;

        case originTypes.call:
          callId = CallTarget.data;
          break;

        case originTypes.deals:
          dealId = CallTarget.data;
          break;

        default:
          break;
      }
    }

    const project = getEntity(spaceProjectsSchema, projectId);
    const ticket = getEntity(spaceTicketsSchema, ticketId);
    const task = getEntity(spaceTasksSchema, taskId);
    const contract = getEntity(spaceContractSchema, contractId);
    const call = getEntity(spaceCallsSchema, callId);
    const deal = getEntity(spaceDealsSchema, dealId);

    const Client =
      clientId ||
      (project && (project.Client || project.IndirectClient)) ||
      (deal && (deal.Client || deal.IndirectClient)) ||
      (ticket && (ticket.Client || ticket.IndirectClient)) ||
      (task && (task.Client || task.IndirectClient)) ||
      (call && (call.Client || call.IndirectClient)) ||
      (contract && (contract.Client || contract.IndirectClient)) ||
      IndirectClient;

    return `Query/Contacts/${Client}/${Contact}`;
  }, [CallTarget, Contact, IndirectClient]);

  const { loading: contactaValidation } = useSpaceQuery(
    contactValidationURL,
    null,
    {
      onSuccess: ({ data }) => {
        if (!Boolean(data)) {
          handleChange({
            Contact: null
          });
        }
      },
      onError: (err) => {
        handleChange({
          Contact: null
        });
      }
    }
  );

  const handlePipelineChanges = (e) => {
    const { value } = e.target;
    if (PipelineId === value) return;
    handleChange({
      Pipeline: value,
      PipelineStatus: ""
    });
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;

    handleChange({
      [name]: value
    });
  };

  if (
    PipelieStatusObj &&
    PipelieStatusObj.Status !== CallStatusTypes.canceled &&
    (Boolean(StatusDetail) ||
      Boolean(StatusDetailId) ||
      Boolean(StatusDetailDescription))
  ) {
    handleChange({
      StatusDetail: null,
      StatusDetailId: null,
      StatusDetailDescription: null
    });
  }

  const handleOrganizationChanges = (e) => {
    const { value } = e.target;
    handleChange({
      Company: value,
      StatusDetailId: null,
      StatusDetail: null
    });
  };
  const entity = useCall(id);
  const currentManager = entity ? entity.ManagerAccount : Creator;
  const canEditFollowers = useCanEditFollowers(Creator, currentManager);
  const handleManagerChange = ({ target: { value } }) => {
    handleChange({
      ManagerAccount: value,
      Followers: updateManagerFollowers(Followers, ManagerAccount, value, [])
    });
  };

  const Automation = useContext(SidebarAutomationContext);

  return (
    <>
      {Automation && (
        <FormInput
          textId="CREATED_BY"
          name="Creator"
          inputType={UserDropdown}
          className="mb-3"
        />
      )}
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"ASSIGN_TO"} />
      </div>
      {(Automation || (organizations && organizations.length > 1)) && (
        <FormInput
          name="Company"
          textId="COMPANY"
          value={Company}
          inputType={OrganizationDropdown}
          onChange={handleOrganizationChanges}
          className="mb-3"
        />
      )}
      <FormInput
        name="CallTarget"
        textId="ASSIGN_TO"
        inputType={CallTargetDropdown}
        allClients={false}
        call={id}
        className="mb-3"
        onChange={handleCallTargetChange}
      />

      <FormInput
        name="Contact"
        className="mb-3"
        textId="CONTACT"
        inputType={ContactDropdown}
        disabled={contactaValidation}
        originTarget={CallTarget}
        IndirectClient={IndirectClient || Client}
      />
      <FormInput
        name="ReceivedDate"
        className="mb-3"
        textId="ENTRY"
        inputType={ArDatePicker}
        componentClassName="w-100"
        openHourAfterDate
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"CLASSIFICATION"} />
      </div>
      <FormInput
        className="mb-3"
        name="Pipeline"
        // disabled={
        //   validatingManager ||
        //   (pipelines && pipelines.length > 0 && pipelines.length === 1)
        // }
        value={PipelineId}
        textId="PIPELINE"
        inputType={CallPipelineDropdown}
        onChange={handlePipelineChanges}
      />

      <FormInput
        className="mb-3"
        disabled={!PipelineId}
        textId="STATUS"
        name="PipelineStatus"
        value={PipelineStatusId ? PipelineStatusId : null}
        inputType={CallStatusPipelineDropdown}
        Status={!id && CallStatusTypes.open}
        focusonmount={"hideCancelation"}
        filterBlock={true}
        onLoad={({ data }) => {
          handleChange(
            {
              PipelineStatus: data[0],
              StatusDetail: null,
              StatusDetailDescription: null
            },
            true
          );
        }}
        pipelineId={PipelineId ? PipelineId : null}
        onChange={handleChanges}
      />

      {id &&
        PipelieStatusObj &&
        PipelieStatusObj.Status === CallStatusTypes.canceled && (
          <FormInput
            name="StatusDetailId"
            textId="CANCELATION_REASON"
            inputType={callCancellationReasonsDropdown}
            company={Company}
            value={
              StatusDetailId
                ? StatusDetailId
                : StatusDetail
                ? StatusDetail
                : null
            }
            onChange={handleChanges}
            className="mb-3"
          />
        )}
      {id &&
        PipelieStatusObj &&
        PipelieStatusObj.Status === CallStatusTypes.canceled && (
          <FormInput
            name="StatusDetailDescription"
            textId="CANCELATION_DESCRIPTION"
            inputType={HTMLInput}
            value={StatusDetailDescription ? StatusDetailDescription : ""}
            onChange={handleChanges}
            className="mb-3"
          />
        )}

      <FormInput
        className="mb-3"
        name="Tags"
        textId="CALL_TAGS"
        pipelineId={Pipeline}
        // getKey={(e) => {
        //   const resolvedTag = getEntity(spaceTicketTagsSchema, e);
        //   return `${e}-${resolvedTag?.Pipeline}`;
        // }}
        multiple
        inputType={CallTagDropdown}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fw-medium fs-16">
        <FormattedMessage id={"ASSIGN_TO"} />
      </div>

      <FormInput
        className="mb-3"
        name="DepartmentTeam"
        disabled={validatingManager}
        textId="DEPARTMENT_TEAM"
        inputType={DepartmentTeamDropdown}
      />

      <FormInput
        className="mb-3"
        name="ManagerAccount"
        disabled={validatingManager}
        textId="ASSIGNED_TO"
        inputType={UserDropdown}
        DepartmentTeam={DepartmentTeam}
        onChange={handleManagerChange}
      />

      <FormInput
        name="Followers"
        textId="FOLLOWERS"
        inputType={UserDropdown}
        multiple
        // items={possibleFollowers}
        className="mb-3"
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fw-medium fs-16">
        <FormattedMessage id={"DETAILS"} />
      </div>

      <FormInput
        textId="DESCRIPTION"
        name="Description"
        className="mb-3"
        inputType={DocTyper}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"LOCATION_ADDRESS"} />
      </div>

      <FormInput
        disabledAutomation
        name="AddressSelector"
        className="mb-3"
        inputType={AddressSelector}
      />
      <CreatedBy Creator={Creator} CreationDate={CreationDate} />
    </>
  );
};

export default CallFormContent;
