import React, { useMemo, useRef } from "react";
import {
  faUsers,
  faInfoSquare,
  faCity
} from "@fortawesome/pro-solid-svg-icons";
import { spaceClientSchema, spaceClientTypeSchema } from "../../config/schema";
import {
  faDrawCircle,
  faFileAlt,
  faUserTie,
  faEnvelope,
  faPhone,
  faGlobe,
  faIdCardAlt,
  faUserPlus,
  faUserCog,
  faBell,
  faIdCardClip
} from "@fortawesome/pro-light-svg-icons";
import {
  EntityOverviewItem,
  EntityDescriptionOverviewItem,
  EntityOverviewRoundedItem,
  EntityOverviewPlaceholder,
  EntityOverviewChildrenProgress
} from "../../Components/EntityOverview/EntityOverview";
import { ClientStatusProfile } from "../../Components/Clients/ClientProfile";
import {
  EntitySummaryAccordion,
  DescriptionOverview,
  EntitySummaryPhoneNumber,
  EntitySummaryAddress,
  EntitySummaryTabstrip,
  EntitySummaryTab,
  EntitySummaryOverview
} from "../../Components/EntitySummaries/EntitySummaries";
import {
  ConnectedContactProfileStack,
  ConnectedAccountProfileStack
} from "../../Components/Accounts/AccountProfileStack";
import CustomerForm from "./ClientForm";
import { History } from "../../Components/DetailsView/History/History";
import { ClientEntityActionsButton } from "./ClientsHelper";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import Treeview from "../Treeview/Treeview";
import { useMultipleEntityValueSelector } from "../../Hooks/AdvancedEntityHooks";
import InfoPopup from "../../Components/Popup/InfoPopup";
import { useHoverEvents } from "../Origin/Origin";
import { ClientDetailsChildren } from "./ClientDetails/ClientDetailsChildren";
import { RealTimeRoomTypes } from "../RealTime/RealTimeHelper";
import { FormattedMessage } from "react-intl";
import { ClientFormButton } from "../Form/FormButtons";

const InnerClientTypeItem = ({ ClientTypes }) => {
  const connectedTypes = useMultipleEntityValueSelector(
    spaceClientTypeSchema,
    ClientTypes
  );

  const anchorRef = useRef();

  const resolvedText = useMemo(() => {
    let text = "";

    for (const type of connectedTypes) {
      if (text.length !== 0) text += ", ";

      text += type.Name;
    }

    return text;
  }, [connectedTypes]);

  const hoverEvents = useHoverEvents();
  const {
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen,
    onPopupMouseEnter,
    onPopupMouseLeave
  } = hoverEvents;

  return (
    <>
      <EntityOverviewRoundedItem
        icon={faIdCardAlt}
        badgeClassName="text-truncate"
        titleId="TYPE"
      >
        <span
          ref={anchorRef}
          onMouseEnter={onAnchorMouseEnter}
          onMouseLeave={onAnchorMouseLeave}
        >
          {resolvedText}
        </span>
      </EntityOverviewRoundedItem>
      <InfoPopup
        anchorEl={anchorRef.current}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        {resolvedText}
      </InfoPopup>
    </>
  );
};

const ClientTypeItem = ({ ClientTypes }) => {
  if (!ClientTypes || ClientTypes.length === 0)
    return (
      <EntityOverviewRoundedItem icon={faIdCardAlt} titleId="TYPE">
        -
      </EntityOverviewRoundedItem>
    );

  return <InnerClientTypeItem ClientTypes={ClientTypes} />;
};

export const ClientSummaryContent = ({ value }) => {
  const {
    NoTagDescription,
    Description,
    Status,
    FiscalNumber,
    Domain,
    Contacts,
    Creator,
    Manager,
    CodeERP,
    Email,
    PhoneNumber,
    Addresses,
    ClientTypes,
    Id,
    Followers
  } = value;

  return (
    <>
      <EntitySummaryAccordion icon={faInfoSquare} textId={"DETAILS"}>
        <EntityOverviewItem icon={faDrawCircle} titleId="STATUS">
          <ClientStatusProfile value={Status} />
        </EntityOverviewItem>

        <EntityOverviewRoundedItem icon={faIdCardAlt} titleId="NIF">
          {FiscalNumber ? FiscalNumber : "-"}
        </EntityOverviewRoundedItem>
        <EntityOverviewRoundedItem icon={faIdCardClip} titleId="CODEERP">
          {CodeERP ? CodeERP : "-"}
        </EntityOverviewRoundedItem>

        <ClientTypeItem ClientTypes={ClientTypes} />

        <EntityOverviewRoundedItem icon={faGlobe} titleId="DOMAIN">
          {Domain ? Domain : "-"}
        </EntityOverviewRoundedItem>

        <EntityOverviewChildrenProgress schema={spaceClientSchema} id={Id} />
        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="DESCRIPTION">
          <DescriptionOverview
            Description={Description}
            NoTagDescription={NoTagDescription}
          />
        </EntityDescriptionOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faCity} textId="ASSOCIATED">
        <EntityOverviewRoundedItem icon={faEnvelope} titleId="EMAIL">
          {Email ? Email : "-"}
        </EntityOverviewRoundedItem>
        <EntityOverviewRoundedItem icon={faPhone} titleId="PHONE_NUMBER">
          <EntitySummaryPhoneNumber number={PhoneNumber} />
        </EntityOverviewRoundedItem>
        <EntityOverviewItem icon={faUserTie} titleId="CONTACTS">
          {Contacts && Contacts.length > 0 ? (
            <ConnectedContactProfileStack contacts={Contacts} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faUsers} textId="ASSIGNED_TO">
        <EntityOverviewItem icon={faUserPlus} titleId="CREATED_BY">
          {Creator ? (
            <ConnectedAccountPopup dynamicFont account={Creator} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUserCog} titleId="ATTRIBUTED_TO">
          {Manager ? (
            <ConnectedAccountPopup
              placement="right"
              dynamicFont
              account={Manager}
            />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>

        <EntityOverviewItem icon={faBell} titleId="FOLLOWERS">
          {Followers && Followers.length > 0 ? (
            <ConnectedAccountProfileStack dynamicFont accounts={Followers} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAddress addresses={Addresses} />
    </>
  );
};

export const InnerClientSummary = ({ value }) => {
  return (
    <EntitySummaryTabstrip className="mt-2 mb-3">
      <EntitySummaryTab titleId="SUMMARY">
        <ClientSummaryContent value={value} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="HISTORY">
        <History client={value.Id} disableTabs />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ORIGINATED">
        <Treeview className="flex-1" schema={spaceClientSchema} id={value.Id} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ASSOCIATIONS">
        <ClientDetailsChildren id={value.Id} />
      </EntitySummaryTab>
    </EntitySummaryTabstrip>
  );
};

const ClientSummary = (props) => {
  return (
    <EntitySummaryOverview
      {...props}
      url={"clients"}
      type={"client"}
      schema={spaceClientSchema}
      FormComponent={CustomerForm}
      Summary={InnerClientSummary}
      creationHeaderText={<FormattedMessage id="CREATE_ACCOUNT" />}
      ActionButton={ClientEntityActionsButton}
      FormButton={ClientFormButton}
      roomType={RealTimeRoomTypes.Client}
    ></EntitySummaryOverview>
  );
};

export default ClientSummary;
