import React, { useState, useRef } from "react";
import { msToTime } from "../../Assets/Utils";
import classnames from "classnames";
import "./HourPicker.css";
import { HourToMilSec, MinuteToMilSec } from "../../Helpers/MiscHelper";
import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";

const HourPicker = ({ value = 0, onChange, className, preview }) => {
  const [hours, minutes] = msToTime(value, "hh:mm").split(":");
  const [isFocused, setIsFocused] = useState(false);
  const prevSelectionRef = useRef();
  const hoursRef = useRef();
  const minutesRef = useRef();

  const handleChange = (milsecs) => {
    const newValue = Number(value) + milsecs;

    onChange(newValue);
  };

  const handleFocus = (e) => {
    prevSelectionRef.current = e.target.selectionStart;
    setIsFocused(true);
  };
  const handleBlur = (e) => {
    setIsFocused(false);
  };

  const handleHoursKeyUp = (e) => {
    const { selectionStart, value } = e.target;
    //arrow right
    if (e.keyCode !== 39) {
      prevSelectionRef.current = selectionStart;
      return;
    }

    const valueLength = value.length;

    if (
      selectionStart !== valueLength ||
      prevSelectionRef.current !== selectionStart
    ) {
      prevSelectionRef.current = selectionStart;
      return;
    }

    minutesRef.current.focus();
    minutesRef.current.setSelectionRange(0, 0);
    prevSelectionRef.current = 0;
  };

  const handleMinutesKeyUp = (e) => {
    const { selectionStart } = e.target;
    //arrow left
    if (e.keyCode !== 37) {
      prevSelectionRef.current = selectionStart;
      return;
    }

    if (selectionStart !== 0 || prevSelectionRef.current !== selectionStart) {
      prevSelectionRef.current = selectionStart;
      return;
    }

    hoursRef.current.focus();
    // minutesRef.current.setSelectionRange(0, 0);
  };
  if (preview)
    return (
      <span>
        {hours}:{minutes}
      </span>
    );

  return (
    <div
      className={classnames(
        "ssi-control rounded d-flex align-items-center justify-content-center ar-hourpicker",
        className,
        { selected: isFocused }
      )}
    >
      <div className="ar-hourpicker-input-container d-flex align-items-center">
        <HoursInput
          key={`h-${hours}`}
          ref={hoursRef}
          value={hours}
          onFocus={handleFocus}
          onKeyUp={handleHoursKeyUp}
          onChange={handleChange}
          onBlur={() => {
            if (handleBlur) handleBlur();
          }}
        />
        <div>:</div>
        <MinutesInput
          key={`m-${minutes}`}
          ref={minutesRef}
          value={minutes}
          onFocus={handleFocus}
          onKeyUp={handleMinutesKeyUp}
          onChange={handleChange}
          onBlur={() => {
            if (handleBlur) handleBlur();
          }}
        />
      </div>
      <Button
        onClick={() => {
          if (Number(hours) === 0) return;
          const newValue = Number(value) - HourToMilSec(1);
          onChange(newValue);
        }}
        type="button"
      >
        <FontAwesomeIcon icon={faCaretDown} />
      </Button>
      <Button
        onClick={() => {
          const newValue = Number(value) + HourToMilSec(1);
          onChange(newValue);
        }}
        type="button"
      >
        <FontAwesomeIcon icon={faCaretUp} />
      </Button>
    </div>
  );
};

const createInput = ({ Converter, isValueValid }) => {
  const Component = React.forwardRef(
    ({ value, onChange, onBlur, ...rest }, ref) => {
      const [time, setTime] = useState(value);

      const handleHoursChange = (e) => {
        const { value: textValue } = e.target;

        if (textValue === "") return setTime("");

        const resolvedValue = Number(textValue);
        if (
          !Number.isInteger(resolvedValue) ||
          (isValueValid && !isValueValid(resolvedValue))
        )
          return;

        setTime(textValue);
      };

      const handleBlur = (e) => {
        if (onBlur) {
          onBlur(e);
        }
        if (Number(time) === Number(value)) return setTime(value);

        const newTime = Number(time) - Number(value);

        const milsecs = Converter(newTime);

        onChange(milsecs);
        // const newValue = Number(value) + milsecs;
      };

      return (
        <input
          ref={ref}
		  onChange={handleHoursChange}
		  onBlur={() => {
			if (handleBlur) handleBlur();
		  }}
          value={time}
          {...rest}
        />
      );
    }
  );

  return Component;
};

const HoursInput = createInput({ Converter: HourToMilSec });

const MinutesInput = createInput({
  Converter: MinuteToMilSec,
  isValueValid: (v) => v < 60,
});

export default HourPicker;
