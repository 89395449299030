import React, { useMemo } from "react";
import KeyedDropdown from "../../../Components/Dropdown/KeyedDropdown";
import { usePipelineFormOpener } from "../Pipelines/PipelineHelper";
import { DocumentStatusProfile } from "./DocumentsStatusProfile";

export const DocumentsStatusTypesForFilter = {
  active: "1",
  cancelled: "3",
  closed: "4",
  review: "5",
  draft: "6"
};

export const DocumentsStatusTypes = {
  active: 1,
  cancelled: 3,
  closed: 4,
  review: 5,
  draft: 6
};

export const DocumentStatusDropdown = (props) => {
  const options = useMemo(() => {
    return [
      DocumentsStatusTypes.draft,
			DocumentsStatusTypes.review,
      DocumentsStatusTypes.active,
			DocumentsStatusTypes.closed,
      DocumentsStatusTypes.cancelled,
    ];
  }, []);

  return (
    <KeyedDropdown
      options={options}
      valueComponent={DocumentStatusProfile}
      closeOnSelect
      {...props}
    />
  );
};

export const documentPipelineConfig = {
  StatusDropdown: DocumentStatusDropdown,
  defaultStatus: DocumentsStatusTypes.active
};

export const useDocumentPipelineFormOpener = (type, id, className) => {
  return usePipelineFormOpener(type, id, className, documentPipelineConfig);
};
