import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const AccountDetailsComp = React.lazy(() =>
  import(/* webpackChunkName: "account-details" */ "./AccountDetailsComp")
);

const AccountDetails = () => {
  return (
    <SuspenseErrorBoundary>
      <AccountDetailsComp />
    </SuspenseErrorBoundary>
  );
};

export default AccountDetails;
