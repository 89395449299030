import { useMemo, useEffect, useLayoutEffect, useContext } from "react";
import { Kinect } from "../Helpers/Kinect";
import { DetailsSettingsContext } from "../Components/DetailsView2/DetailsView2Helper";

export const createDateGroupingHook = (isDiffrentDate, buildKey, dateProp) => {
  const useDateGrouping = items => {
    return useMemo(() => {
      let currentDate;
      return items.reduce((filters, item, currentIndex) => {
        const itemDate = item[dateProp];
        if (!currentDate || !isDiffrentDate(currentDate, itemDate)) {
          currentDate = itemDate;

          filters.push({
            date: itemDate,
            key: buildKey(itemDate),
            items: [item]
          });
          return filters;
        }

        const filterNotifications = filters[filters.length - 1];
        filterNotifications.items.push(item);

        return filters;
      }, []);
    }, [items]);
  };

  return useDateGrouping;
};

export const useTitle = title => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

export const useAirdeskTitle = title => {
  const detailsSettings = useContext(DetailsSettingsContext);
  useEffect(() => {
    if (detailsSettings) return;
    document.title = `${title} - Airdesk`;
  }, [detailsSettings, title]);
};

export const createKinectHook = ({ filterTarget } = {}) => {
  const useKinect = (elemRef, kinectRef) => {
    useLayoutEffect(() => {
      const ref = kinectRef || {};

      ref.current = new Kinect(elemRef.current, {
        cursor: null,
        filterTarget: filterTarget
      });
    }, [elemRef, kinectRef]);
  };

  return useKinect;
};
