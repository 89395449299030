import React from "react";
import { useTicket } from "../../../../Hooks/EntityHooks";
import {
  HistoryHeader,
  HistoryContent,
  HistoryAccountProfile,
  HistoryOverview,
  useHistoryItem
} from "./HistoryItem";
import { ConnectedTicketOrigin } from "../../../../Containers/Origin/TicketOrigin";
import { createItemEditDetailsComponent } from "./HistoryItemDetails/HistoryItemDetails";
import { ProjectStatusProfile } from "../../../Projects/ProjectProfile";
import { FormattedMessage } from "react-intl";
import { ConnectedTicketTagItem } from "../../../../Containers/AdvancedMultiInputs";

const TicketHistoryOverview = () => {
  const { Ticket, IsCreation, Account } = useHistoryItem();
  const item = useTicket(Ticket);
  const { Name } = item;
  if (IsCreation)
    return (
      <HistoryOverview>
        <HistoryAccountProfile account={Account} />
        <span>
          {" "}
          <FormattedMessage id={"CREATED_CASE"} />{" "}
        </span>{" "}
        <b>{Name}</b>
      </HistoryOverview>
    );

  return (
    <HistoryOverview>
      <HistoryAccountProfile account={Account} />
      <span>
        {" "}
        <FormattedMessage id={"EDITED_CASE"} />{" "}
      </span>{" "}
      <b>{Name}</b>
    </HistoryOverview>
  );
};

const TicketEditItemDetails = createItemEditDetailsComponent({
  Status: {
    NameId: "status",
    ValueComponent: ProjectStatusProfile
  }
});

const TicketHistoryDetails = () => {
  const { IsCreation } = useHistoryItem();
  if (IsCreation) return null;

  return <TicketEditItemDetails />;
};

const TicketHistoryItem = () => {
  const { Ticket } = useHistoryItem();

  return (
    <>
      <HistoryHeader>
        <ConnectedTicketOrigin ticket={Ticket}>
          {/* <HistoryTitle Icon={TicketIcon}>
            {" "}
            <FormattedMessage id={"CASE"} /> {Ticket}
          </HistoryTitle> */}
        </ConnectedTicketOrigin>
      </HistoryHeader>
      <HistoryContent>
        <TicketHistoryOverview />
        <TicketHistoryDetails />
      </HistoryContent>
    </>
  );
};
export default TicketHistoryItem;
