import React, { useMemo } from "react";
import { AccountProfileImg } from "../Img/Img";
import classnames from "classnames";
import Badge from "../Badge/Badge";
import { useClient } from "../../Hooks/EntityHooks";
import { Link } from "react-router-dom";
import { useSpace } from "../../Contexts/SpaceContext";
import { useIntl, FormattedMessage } from "react-intl";
import { ClientIcon } from "../Common/EntityIcons";
import DropdownInput from "../Dropdown/DropdownInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/pro-light-svg-icons";
import {
  CompanyActiveStatusDot,
  CompanyBlockedStatusDot,
  CompanyInactiveStatusDot,
  CompanyInAprovalStatusDot
} from "../StatusHelper/StatusHelper";
import { faSuitcase } from "@fortawesome/pro-solid-svg-icons";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { spaceClientSchema } from "../../config/schema";

export const ClientStatusTypes = {
  active: 1,
  disabled: 2,
  onApproval: 3,
  blocked: 4
};

export const ClientStausTypeDict = {
  [ClientStatusTypes.active]: "ACTIVE",
  [ClientStatusTypes.disabled]: "INACTIVE",
  [ClientStatusTypes.onApproval]: "IN_APPROVAL",
  [ClientStatusTypes.blocked]: "BLOCKED"
};

export const ClientStatusProfileWithIcon = ({
  item,
  value,
  className,
  hideName,
  size = 24
}) => {
  let text, type;
  const intl = useIntl();
  if (!item && !value) return null;

  switch (item || parseInt(value)) {
    case ClientStatusTypes.disabled:
      type = "inactive";
      text = intl.formatMessage({ id: "INACTIVE" });
      break;

    case ClientStatusTypes.onApproval:
      type = "orange";
      text = intl.formatMessage({ id: "IN_APPROVAL" });
      break;

    case ClientStatusTypes.blocked:
      type = "danger";
      text = intl.formatMessage({ id: "BLOCKED" });
      break;

    default:
      type = "purple";
      text = intl.formatMessage({ id: "ACTIVE" });
      break;
  }

  return (
    <div
      className={`d-flex align-items-center link-color text-truncate status-color-${type} ${className} `}
    >
      <div
        style={{ width: size, height: size }}
        className={`d-flex align-items-center justify-content-around position-relative text-truncate status-dot m-0 status-dot-background-template`}
      >
        <ClientIcon />
      </div>

      {!hideName && (
        <div
          style={{ fontWeight: 400 }}
          className="status-label flex-1 text-truncate"
        >
          {text}
        </div>
      )}
    </div>
  );
};

export const buildClientStatusProfile = (item, value) => {
  let type;
  if (!item && !value) return null;

  switch (item || parseInt(value)) {
    case ClientStatusTypes.disabled:
      type = "inactive";
      break;

    case ClientStatusTypes.onApproval:
      type = "purple";
      break;

    case ClientStatusTypes.blocked:
      type = "danger";
      break;

    default:
      type = "success";
      break;
  }

  return type;
};

export const ClientStatusSvgIcons = (resolvedV) => {
  switch (resolvedV) {
    case ClientStatusTypes.disabled:
      return <CompanyInactiveStatusDot />;
    case ClientStatusTypes.onApproval:
      return <CompanyInAprovalStatusDot />;
    case ClientStatusTypes.blocked:
      return <CompanyBlockedStatusDot />;
    default:
      return <CompanyActiveStatusDot />;
  }
};

export const ClientStatusProfile = ({
  item,
  value,
  className,
  hideName,
  dotClassName,
  pipelineName,
  status
}) => {
  let text, type;
  const intl = useIntl();

  const resolvedV = item || status || parseInt(value);

  const SvgComponent = useMemo(() => {
    if (!resolvedV) return null;
    return ClientStatusSvgIcons(resolvedV);
  }, [resolvedV]);

  if (!resolvedV) return null;

  switch (resolvedV) {
    case ClientStatusTypes.disabled:
      type = "inactive";
      text = intl.formatMessage({ id: "INACTIVE" });
      break;

    case ClientStatusTypes.onApproval:
      type = "orange";
      text = intl.formatMessage({ id: "IN_APPROVAL" });
      break;

    case ClientStatusTypes.blocked:
      type = "danger";
      text = intl.formatMessage({ id: "BLOCKED" });
      break;

    default:
      type = "purple";
      text = intl.formatMessage({ id: "ACTIVE" });
      break;
  }

  return (
    <div
      className={`d-flex align-items-center link-color of-visible text-truncate status-color-${type} ${className} `}
    >
      {/* <div className={`position-relative text-truncate status-dot`} /> */}
      <div
        className={classnames("position-relative status-dot-svg", dotClassName)}
      >
        {SvgComponent}
      </div>
      {!hideName && (
        <div
          style={{ fontWeight: 400 }}
          className="status-label flex-1 fs-12 text-truncate"
        >
          {pipelineName || text}
        </div>
      )}
    </div>
  );
};

export const ClientStatusBadge = ({ className, status }) => {
  switch (status) {
    case ClientStatusTypes.disabled:
      return (
        <Badge noTruncate type="inactive" className={classnames(className)}>
          <FormattedMessage id={"INACTIVE"} />
        </Badge>
      );

    case ClientStatusTypes.onApproval:
      return (
        <Badge noTruncate type="orange" className={classnames(className)}>
          <FormattedMessage id={"IN_APPROVAL"} />
        </Badge>
      );

    case ClientStatusTypes.blocked:
      return (
        <Badge noTruncate type="blocked" className={classnames(className)}>
          <FormattedMessage id={"BLOCKED"} />
        </Badge>
      );

    default:
      return (
        <Badge noTruncate type="purple" className={classnames(className)}>
          <FormattedMessage id={"ACTIVE"} />
        </Badge>
      );
  }
};

export const ClientListProfile = ({
  client,
  showId,
  size,
  justImage,
  justStatus,
  hidestatus,
  noImage,
  text,
  hideIndex,
  isTyping,
  className,
  enableInput,
  showWithLink,
  dynamicFont,
  ...inputProps
}) => {
  const intl = useIntl();

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100 of-hidden text-truncate">
        {!isTyping && client && (
          <AccountProfileImg
            size="mds"
            account={client}
            circular
            className="mr-2"
          />
        )}
        <div className="d-flex flex-1 of-hidden align-items-center">
          <div className="d-flex flex-column of-hidden mr-2 w-100">
            <div className="dropdown-item-maintext ">
              {!enableInput ? (
                client?.Name
              ) : (
                <DropdownInput
                  title={
                    client?.IsDeleted && intl.formatMessage({ id: "DELETED" })
                  }
                  className={classnames("flat-input text-truncate", {
                    "text-decoration-line-through": client?.IsDeleted
                  })}
                  fallbackValue={client && client.Name}
                  text={text}
                  enableInput={enableInput}
                  isTyping={isTyping}
                  {...inputProps}
                />
              )}
            </div>
            {!isTyping && client && (
              <div className="dropdown-item-subtext-dif text-truncate">
                {client && client?.Domain && (
                  <span>
                    <FontAwesomeIcon icon={faGlobe} className="mr-1" />
                    {client?.Domain}
                  </span>
                )}
              </div>
            )}
          </div>
          {!isTyping && !hidestatus && !client?.IsDeleted && client && (
            <ClientStatusBadge status={client?.Status} />
          )}
        </div>
        {!isTyping && !hideIndex && client && (
          <div className={` d-flex align-items-center justify-content-end `}>
            <div className="d-flex py-1 px-2 fs-12 align-items-center ">
              <FontAwesomeIcon className="mr-2" icon={faSuitcase} />
              <span>{client ? client?.Id : ""}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  // return (
  //   <div
  // 	className={classnames(
  // 	  "d-flex align-items-center text-truncate",
  // 	  className
  // 	)}
  //   >
  // 	<AccountProfileImg
  // 	  size={size || "sm"}
  // 	  account={client}
  // 	  circular
  // 	  className={classnames("flex-shrink-0", { "mr-1": !justImage })}
  // 	/>
  // 	{!justImage && (
  // 	  <div
  // 		className={classnames(
  // 		  "d-flex flex-column text-truncate flex-shrink-1",
  // 		  {
  // 			"fs-12": !dynamicFont,
  // 		  }
  // 		)}
  // 	  >
  // 		<div className="d-flex text-truncate align-items-center">
  // 		  {/* <Link to={`/s/${space.Id}/customers/${client.Id}`}> */}
  // 		  <div
  // 			title={client.Name}
  // 			className="flex-1 lh-normal text-truncate text-black"
  // 		  >
  // 			{client.Name}
  // 		  </div>
  // 		  {/* </Link> */}
  // 		  {!hidestatus && (
  // 			<ClientStatusBadge className="ml-2" status={client.Status} />
  // 		  )}
  // 		</div>
  // 		{showId && (
  // 		  <Link to={`/s/${space.Id}/customers/${client.Id}`}>
  // 			<div className="link-color lh-normal fw-medium">
  // 			  Nº{client.Id}
  // 			</div>
  // 		  </Link>
  // 		)}
  // 	  </div>
  // 	)}
  //   </div>
  // );
};

const ClientProfile = ({
  client,
  showId,
  size,
  justImage,
  justStatus,
  hideStatus,
  noImage,
  className,
  noAvatar,
  justName,
  showWithLink,
  dynamicFont
}) => {
  const space = useSpace();
  const intl = useIntl();
  if (!client) return null;
  const { IsDeleted } = client;
  if (justStatus)
    return (
      <div style={{ width: "fit-content" }}>
        <ClientStatusBadge status={client.Status} />
      </div>
    );
  return (
    <div
      className={classnames(
        "d-flex align-items-center text-truncate",
        className
      )}
    >
      {!noAvatar && (
        <AccountProfileImg
          size={size || "sm"}
          account={client}
          circular
          className={classnames("flex-shrink-0", { "mr-1": !justImage })}
        />
      )}
      {!justImage && (
        <div
          className={classnames(
            "d-flex flex-column text-truncate flex-shrink-1",
            {
              "fs-12": !dynamicFont
            }
          )}
        >
          <div className="d-flex text-truncate align-items-center">
            {/* <Link to={`/s/${space.Id}/customers/${client.Id}`}> */}
            <div
              title={
                client.IsDeleted
                  ? intl.formatMessage({ id: "DELETED" })
                  : client.Name
              }
              className={classnames("flex-1 lh-normal text-truncate", {
                "text-decoration-line-through": IsDeleted
              })}
            >
              {client.Name}
            </div>
            {/* </Link> */}
            {!hideStatus && !IsDeleted && (
              <ClientStatusBadge className="ml-2" status={client.Status} />
            )}
          </div>
          {showId && (
            <Link to={`/s/${space.Id}/customers/${client.Id}`}>
              <div className="link-color lh-normal fw-medium">
                Nº{client.Id}
              </div>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

// const mapEntityStateToProps = (state, { client }) => {
//   if (!client) return null;

//   const clientEntity = state.Entities.SpaceClients[client];

//   const { Id, Name, Description, Status } = clientEntity;
//   return { Id, Name, Description, Status };
// };

export const DifrentClientProfile = ({ client }) => {
  if (!client) return null;
  const { Status, Image } = client;

  if (Image)
    return (
      <AccountProfileImg
        size={"mdx"}
        account={client}
        circular
        className={classnames("flex-shrink-0")}
      />
    );

  let colorStatus;

  switch (Status) {
    case ClientStatusTypes.active:
      colorStatus = "status-in-progress-colors";
      break;
    case ClientStatusTypes.onApproval:
      colorStatus = "status-draft-colors";
      break;
    case ClientStatusTypes.blocked:
      colorStatus = "status-lost-colors";
      break;
    case ClientStatusTypes.disabled:
      colorStatus = "status-canceled-colors";
      break;
    default:
      break;
  }

  return (
    <div className={classnames("air-new-profiles-circle", colorStatus)}>
      <FontAwesomeIcon icon={faSuitcase} size="xl" />
    </div>
  );
};

export default ClientProfile;

export const ConnectedClientProfile = ({ client, ...rest }) => {
  const c = useClient(client);

  const url = useMemo(() => {
    if (!client) return null;
    return !c ? `query/clients?query=${client}` : null;
  }, [c, client]);
  useSpaceQuery(url, [spaceClientSchema]);

  return <ClientProfile client={c} {...rest} />;
};

export const ConnectedClientListProfile = ({ client, ...rest }) => {
  const c = useClient(client);

  const url = useMemo(() => {
    if (!client) return null;
    return !c ? `query/clients?query=${client}` : null;
  }, [c, client]);
  useSpaceQuery(url, [spaceClientSchema]);

  return <ClientListProfile client={c} {...rest} />;
};

export const ConnectClientEntity = (Component) => {
  const Comp = ({ client, ...rest }) => {
    const c = useClient(client);

    const url = useMemo(() => {
      if (!client) return null;
      return !c ? `query/clients?query=${client}` : null;
    }, [c, client]);
    useSpaceQuery(url, [spaceClientSchema]);

    return <Component client={c} {...rest} />;
  };
  return Comp;
};
