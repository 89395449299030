import React, { useContext, useState, useRef } from "react";
import {
  faFileLines,
  faAlignJustify,
  faDrawCircle,
  faCalendarAlt,
  faUserPlus,
  faDownload,
  faTimes
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Button from "../../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColorFilterBars } from "../../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { DocumentsFormButton } from "../../Form/FormButtons";
import { createFormData } from "../../../Helpers/MiscHelper";
import { getInterventionTarget } from "../../../Helpers/FormFormmaterHelper";
import {
  createPipelineStatusFilter,
  createUserFilter,
  createProjectFilter,
  createDealFilter,
  createClientFilter,
  createContractFilter,
  createSubscriptionFilter,
  createCallFilter,
  createTaskFilter,
  createTicketFilter,
  createInterventionFilter,
  createDocumentTypeFilter,
	createUpgradedPipelineStatusFilter
} from "../../../Helpers/FilterHelper";
import { PipelineTypeEnum } from "../../Settings/Pipelines/NewPipelineHelper";
import { DocumentStatusProfile } from "../../Settings/Documents/DocumentsStatusProfile";
import { FormattedMessage } from "react-intl";
import { ConnectedAccountProfile } from "../../../Components/Accounts/AccountProfile";
import {
  EntityPopup,
  NewEntityPopup
} from "../../../Components/EntityPopup/EntityPopup";
import { useCurrentAccountSpace } from "../../../Contexts/UserContext";
import { LabelTitle } from "../../../Helpers/GridHelper";
import { DocumentsClipboardButton } from "../../../Helpers/HooksHelper";
import EntityReadButton from "../../../Components/EntityRead/EntityReadButton";
import { spaceDocumentsSchema } from "../../../config/schema";
import { DeleteButtonContextMenu } from "../../../Components/CGrid/DeleteMultipleModal";
import {
  DocumentGeneratePdfFromDocument,
  PreviewPdfDocumentContext
} from "../DocumentDetails/DocDetailsHelper";
import { EntityPopupContext } from "../../../Components/EntityPopup/EntityPopupHelper";
import Modal from "../../../Components/Modal/Modal";
import PdfPreview from "../../../Components/FileUploader/PdfPreview";

const DocumentsEditButton = ({ id, contextMenu }) => {
  const url = `documents/${id}`;

  if (contextMenu) {
    return (
      <Link to={url}>
        <Button vType="link" type="button" className="text-left">
          <div className="mr-1 ar-form-button-link-icon">
            <FontAwesomeIcon icon={faFileLines} />
          </div>
          <span className="fw-regular fs-14">
            {" "}
            <FormattedMessage id="SPACE_OPEN" />
          </span>
        </Button>
      </Link>
    );
  }

  return (
    <Link to={url}>
      <Button
        vType="primary-ghost"
        size="sm"
        type="button"
        className="d-flex align-items-center justify-content-center"
      >
        <FontAwesomeIcon icon={faFileLines} />
      </Button>
    </Link>
  );
};

export const DocumentsButtonGroup = ({
  entity,
  hideBreadCrumbs,
  minimal,
  skeleton,
  noColors
}) => {
  return (
    <div className="d-flex align-items-center">
      {!entity.IsLock && <DocumentsEditButton id={entity.Id} />}
      <DocumentsFormButton
        minimal={minimal}
        id={entity.Id}
        canEdit={entity.CanEdit && !entity.IsLock}
        skeleton={skeleton}
        className="mx-2 FormButtonClassID"
      />
      <DocumentEntityActionsButton entity={entity} minimal={minimal} />
      {/* <DocTemplatesEntityActionsButton minimal={minimal} entity={entity} /> */}
      {!noColors && <ColorFilterBars entity={entity} />}
    </div>
  );
};

export const documentPipelineSettings = {
  field: "Pipeline/Id",
  pipelineStatusField: "PipelineStatus/Id",
  type: PipelineTypeEnum.Documents,
  StatusProfileComponent: DocumentStatusProfile
};

export const getDocumentsFilters = () => [
  {
    propField: "Id",
    icon: <FontAwesomeIcon icon={faFileLines} />,
    title: "Nº",
    field: "Document/Id",
    type: "string"
  },
  createDocumentTypeFilter({
    icon: <FontAwesomeIcon icon={faFileLines} />,
    propField: "DocumentType",
    field: "DocumentType/Id"
  }),
  {
    propField: "Name",
    icon: <FontAwesomeIcon icon={faAlignJustify} />,
    titleId: "NAME",
    field: "Document/Name",
    type: "string"
  },
  // createPipelineStatusFilter({
  //   icon: <FontAwesomeIcon icon={faDrawCircle} />,
  //   pipelineSettings: documentPipelineSettings
  // }),
  createUpgradedPipelineStatusFilter({
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    pipelineSettings: documentPipelineSettings
  }),
  createProjectFilter({
    field: "Project/Id"
  }),
  createDealFilter({
    field: "Deal/Id"
  }),
  createContractFilter({
    field: "Contract/Id"
  }),
  createSubscriptionFilter({
    field: "Subscription/Id"
  }),
  createCallFilter({
    field: "Call/Id"
  }),
  createTaskFilter({
    field: "Task/Id"
  }),
  createTicketFilter({
    field: "Ticket/Id"
  }),
  createInterventionFilter({
    field: "TimeCharge/Id"
  }),
  createClientFilter({
    field: "Client/Id"
  }),
  createUserFilter({
    propField: "Assigned",
    icon: <FontAwesomeIcon icon={faUserPlus} />,
    titleId: "ASSIGNED_TO",
    field: "Assigned/Id",
    DescriptionComponent: ({ item, value }) => {
      return (
        <div className="d-flex flex-column align-items-center">
          <FormattedMessage id="ASSIGNED_TO" className="mr-2" />
          <ConnectedAccountProfile account={value} />
        </div>
      );
    }
  }),
  {
    propField: "CreationDate",
    icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    titleId: "CREATION_DATE",
    field: "Document/CreationDateNoHours",
    type: "dateTime"
  },
  {
    propField: "LastChange",
    icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    titleId: "LAST_CHANGE_DATE",
    field: "Document/LastChangeNoHours",
    type: "dateTime"
  }
];

const AssociationTypeEnum = {
  Client: 1,
  Deal: 2,
  Contract: 4,
  Subscription: 8,
  Call: 16,
  Ticket: 32,
  Project: 64,
  Task: 128,
  Time: 256
};

export const formatDocumentForm = (form) => {
  const {
    DocTarget,
    Company,
    Pipeline,
    PipelineStatus,
    Manager,
		AssignedTo,
    Companies,
    DocumentType,
    Collaborators = [],
    PublicStatus,
    Files,
    ...rest
  } = form;

  // const orgs = [],
  //   deps = [],
  //   teams = [],
  //   people = [];

  // for (const item of Collaborators) {
  //   if (item.type === "Account") people.push(item.data);
  //   else if (item.type === "Team") teams.push(item.data);
  //   else if (item.type === "organization") orgs.push(item.data);
  //   else if (item.type === "Department") deps.push(item.data);
  // }

  let NewFiles = [];
  if (!Array.isArray(Files)) {
    for (const key in Files) {
      if (Object.hasOwnProperty.call(Files, key)) {
        const element = Files[key] || {};
        const { Label, Name, Id, Extension } = element;
        NewFiles.push({ Label, Name, Id, Extension, IsRecovery: true });
      }
    }
  } else NewFiles = [...Files];

  const obj = {
    AssociateTo: DocTarget && AssociationTypeEnum[DocTarget.type],
    AssociateId: DocTarget && DocTarget.data,
    OrganizationId: Company,
    Organizations: Companies,
    PipelineId: Pipeline,
    DocumentTypeId: DocumentType,
    PipelineStatusId: PipelineStatus,
    AssignedToId: Manager || AssignedTo?.Id || AssignedTo,
    // Organizations: orgs,
    // Departments: deps,
    // Teams: teams,
    // Collaborator: people,
    IsPublic: PublicStatus,
    ...rest
  };

  return createFormData(obj, NewFiles);
};

export const validateDocForm = (
  { DocTarget, Company, Pipeline, AssignedTo, PipelineStatus, Name },
  addError
) => {
  if (!AssignedTo) addError("AssignedTo");
  if (!Company) addError("Company");
  if (!DocTarget) addError("DocTarget");
  if (!Name) addError("Name");
  if (!Pipeline) addError("Pipeline");
  if (!PipelineStatus) addError("PipelineStatus");
};

export const convertDocumentEntityToForm = (entity, props) => {
  const {
    Call,
    Project,
    Task,
    Client,
    Ticket,
    Contract,
    Deal,
    Company,
    Subscription,
    Collaborators,
    ...entityRest
  } = entity;

  const { Accounts, Teams, Companies, Departments } = Collaborators || {};
  const data = [];

  if (Accounts || Teams || Companies || Departments) {
    if (Accounts && Array.isArray(Accounts)) {
      for (let i = 0; i < Accounts.length; i++) {
        if (Accounts[i].type === "Account" && Accounts[i].data)
          data.push(Accounts[i]);
        else data.push({ type: "Account", data: Accounts[i] });
      }
    }
    if (Teams && Array.isArray(Teams)) {
      for (let i = 0; i < Teams.length; i++) {
        if (Teams[i].type === "Team" && Teams[i].data) data.push(Teams[i]);
        else data.push({ type: "Team", data: Teams[i] });
      }
    }
    if (Companies && Array.isArray(Companies)) {
      for (let i = 0; i < Companies.length; i++) {
        if (Companies[i].type === "organization" && Companies[i].data)
          data.push(Companies[i]);
        else data.push({ type: "organization", data: Companies[i] });
      }
    }

    if (Departments && Array.isArray(Departments)) {
      for (let i = 0; i < Departments.length; i++) {
        if (Departments[i].type === "Department" && Departments[i].data)
          data.push(Departments[i]);
        else data.push({ type: "Department", data: Departments[i] });
      }
    }
  }

  return {
    ...entityRest,
    Company,
    Companies,
    Collaborators: data,
    DocTarget: getInterventionTarget(entity)
  };
};

export const DocumentEntityActionsChildren = ({
  entity,
  minimal,
  sidebar,
  extraActionButton,
  detailsView
}) => {
  const accountSpace = useCurrentAccountSpace();

  const toggleOff = useContext(EntityPopupContext);

  return (
    <>
      {!sidebar && !detailsView && <DocumentsFormButton id={entity.Id} />}
      {!sidebar && !detailsView && (
        <DocumentsEditButton contextMenu id={entity.Id} />
      )}
      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      <DocumentsFormButton id={entity.Id} duplicate />
      <DocumentsClipboardButton id={entity.Id} />
      <DocumentGeneratePdfFromDocument id={entity.Id} preview />
      <DocumentGeneratePdfFromDocument id={entity.Id} />
      {extraActionButton && extraActionButton(toggleOff)}
      <EntityReadButton value={entity} schema={spaceDocumentsSchema} />
      <DeleteButtonContextMenu entity={entity} />
    </>
  );
};

function download_file(fileURL, fileName) {
  // ;
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_self";

    save.download = fileName;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, "_blank");
    _window.document.close();
    _window.document.execCommand("SaveAs", true, fileName || fileURL);
    _window.close();
  }
}

export const DocumentEntityActionsButton = ({
  minimal,
  newEntity,
  entity,
  ...props
}) => {
  const [previewPdfModal, setPreviewPdfModal] = useState(false);
  const FileRef = useRef(null);
  const toggleModal = () => {
    FileRef.current = null;
    setPreviewPdfModal((oldS) => !oldS);
  };

  const handleModalOpen = (file) => {
    FileRef.current = file;
    setPreviewPdfModal(true);
  };

  if (newEntity) {
    return (
      <>
        <Modal
          // containerClassName=""
          className={classnames(
            "ar-modal-preview-file-content  d-flex flex-column p-1 ar-modal-preview-files-content-files"
          )}
          isOpen={previewPdfModal}
          onClose={toggleModal}
          extraCloseButton={
            <>
              <div
                className="ar-modal-preview-file-content-close-button"
                onClick={toggleModal}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>

              <div
                style={{ top: 55 }}
                className="ar-modal-preview-file-content-close-button text-color-link"
                onClick={() => download_file(FileRef.current, entity.Name)}
              >
                <FontAwesomeIcon icon={faDownload} />
              </div>
            </>
          }
        >
          <PdfPreview src={FileRef.current} />
        </Modal>
        <NewEntityPopup minimal={minimal}>
          <PreviewPdfDocumentContext.Provider value={handleModalOpen}>
            <DocumentEntityActionsChildren
              minimal={minimal}
              entity={entity}
              {...props}
            />
          </PreviewPdfDocumentContext.Provider>
        </NewEntityPopup>
      </>
    );
  }

  return (
    <>
      <Modal
        // containerClassName=""
        className={classnames(
          "ar-modal-preview-file-content  d-flex flex-column p-1 ar-modal-preview-files-content-files"
        )}
        isOpen={previewPdfModal}
        onClose={toggleModal}
        extraCloseButton={
          <>
            <div
              className="ar-modal-preview-file-content-close-button"
              onClick={toggleModal}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>

            <div
              style={{ top: 55 }}
              className="ar-modal-preview-file-content-close-button text-color-link"
              onClick={() => download_file(FileRef.current, entity.Name)}
            >
              <FontAwesomeIcon icon={faDownload} />
            </div>
          </>
        }
      >
        <PdfPreview src={FileRef.current} />
      </Modal>

      <EntityPopup minimal={minimal}>
        <PreviewPdfDocumentContext.Provider value={handleModalOpen}>
          <DocumentEntityActionsChildren
            minimal={minimal}
            entity={entity}
            {...props}
          />
        </PreviewPdfDocumentContext.Provider>
      </EntityPopup>
    </>
  );
};
