import React from "react";
import {
  createFormData,
  isEmail,
  hasPhoneNumberError,
  canSubmitPhoneNumber
} from "../../Helpers/MiscHelper";
import { ClientStatusTypes } from "../../Components/Clients/ClientProfile";
import {
  ClientFormButton,
  CallFormButton,
  TicketFormButton,
  TaskFormButton,
  InterventionFormButton,
  NoteFormButton,
  DealFormButton,
  ContractFormButton,
  SubscriptionFormButton,
  ContactFormButton,
  ProjectFormButton
} from "../Form/FormButtons";
import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import { createPdfButton } from "../../Components/Pdf/PdfHelper";
import { moduleTypes, canUserEditModule } from "../../Helpers/ModulesHelper";
// import { getResultAddresses } from "../../Helpers/FormFormmaterHelper";
import { LabelTitle } from "../../Helpers/GridHelper";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { spaceClientSchema, spaceClientTagsSchema } from "../../config/schema";
import { ClientClipboardButton } from "../../Helpers/HooksHelper";
import EntityReadButton from "../../Components/EntityRead/EntityReadButton";

import { FormattedMessage, useIntl } from "react-intl";
import { DeleteButtonContextMenu } from "../../Components/CGrid/DeleteMultipleModal";
import {
  InnerColorFilterBars,
  TriggeredColoredFilterProvider,
  useTriggeredColorFilters
} from "../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import classnames from "classnames";
import { TimeTrackingGridFormButton } from "../TimeTracking/TimeTracking";
import { getEntity } from "../../Helpers/IOClient";

export const formatCustomerForm = ({
  Manager,
  Image,
  PhoneNumber,
  ClientTypes,
  Latitude,
  Longitude,
  Addresses,
  Tags,
  Locale,
  PostalCode,
  Region,
  AddressSelector,
  ...rest
}) => {
  const Files =
    !Image || typeof Image === "string"
      ? null
      : [
          {
            File: Image.file,
            Name: Image.file.name,
            Extension: ""
          }
        ];

  const resolvedAddresses = [...Addresses];

  for (const addr of resolvedAddresses) {
    addr.CountryId = addr.Country;
  }

  let tagsIds = [];
  if (Tags && Array.isArray(Tags))
    for (const e of Tags) {
      const { TempId } = getEntity(spaceClientTagsSchema, e.data);
      if (TempId) tagsIds.push(TempId);
    }

  return createFormData(
    {
      ...rest,
      tagsIds,
      Image: Boolean(Image),
      PhoneNumber: canSubmitPhoneNumber(PhoneNumber) ? PhoneNumber : "",
      ManagerId: Manager,
      ClientTypes: JSON.stringify(ClientTypes),
      Addresses: JSON.stringify(resolvedAddresses)
    },
    Files
  );
};

export const defaultCustomerForm = () => {
  return {
    Name: "",
    Email: "",
    PhoneNumber: "",
    FiscalNumber: "",
    Domain: "",
    CodeERP: "",
    Region: "",
    PostalCode: "",
    Locale: "",
    Addresses: [
      {
        Id: undefined,
        Country: undefined,
        Address1: "",
        Address2: "",
        Name: "",
        District: "",
        Locality: "",
        PostalCode: "",
        Latitude: "",
        Longitude: ""
      }
    ],
    Description: "",
    ClientTypes: null,
    Manager: null,
    Country: undefined,
    Contacts: [],
    Industries: [],
    Followers: [],
    Image: null,
    Latitude: 0,
    Longitude: 0,
    Status: ClientStatusTypes.active
  };
};

// export const defaultCustomerForm = {
//   Name: "",
//   Email: "",
//   PhoneNumber: "",
//   FiscalNumber: "",
//   Domain: "",
//   Region: "",
//   PostalCode: "",
//   Locale: "",
//   Address: "",
//   Description: "",
//   Manager: null,
//   Country: null,
//   Contacts: [],
//   Industries: [],
//   Image: null,
//   Status: ClientStatusTypes.active
// };

function validaNIF(value) {
  value = value + "";
  // has 9 digits?
  // if (!/^[0-9]{9}$/.test(value)) return false;
  // is from a person?
  // if (!/^[123]|45|5||9/.test(value)) return false;
  return true;
}

export const validateCustomerForm = (
  {
    Name,
    Domain,
    PhoneNumber: pn,
    Description,
    FiscalNumber,
    Email,
    FiscalCountry
  },
  addError
) => {
  if (!Name) addError("Name");
  if (Name && Name.match(/<(.*?)>|(\$){0,1}{(.*?)}|<|>/)) addError("Name");
  if (hasPhoneNumberError(pn))
    addError("PhoneNumber", <FormattedMessage id="INVALID_NUMBER_WARNING" />);

  if (Email && !isEmail(Email))
    addError("Email", <FormattedMessage id="EMAIL_CORRECT" />);
  if (Email && Email.match(/<(.*?)>|(\$){0,1}{(.*?)}|<|>/))
    addError("Email", <FormattedMessage id="EMAIL_CORRECT" />);
  if (Domain && !/^([.]*[\w-]+)+[.][\w]+$/.test(Domain))
    addError("Domain", <FormattedMessage id="DOMAIN_ERROR_FORMAT" />);

  if (
    FiscalCountry?.toString() === "7" &&
    /\S/.test(FiscalNumber?.toString()) &&
    !validaNIF(FiscalNumber)
  )
    addError("FiscalNumber", <FormattedMessage id="INVALID_NIF" />);
  //   if (
  //     PhoneNumber &&
  //     !(isValidPhoneNumber(PhoneNumber) || isPhoneNumber(PhoneNumber))
  //   )
  //     addError("PhoneNumber");
};

export const ClientEntityButtons = ({ entity, skeleton, onSuccess }) => {
  const isPositiveStatus =
    entity.Status !== ClientStatusTypes.disabled &&
    entity.Status !== ClientStatusTypes.blocked;

  return (
    <React.Fragment>
      <ClientFormButton
        id={entity.Id}
        canEdit={entity.CanEdit}
        skeleton={skeleton}
        className="mr-2 FormButtonClassID"
        onSuccess={onSuccess}
      />
      <NoteFormButton
        alt
        formProps={{ clientId: entity.Id, Type: 1 }}
        skeleton={skeleton}
        className="mr-2"
      />
      {isPositiveStatus && (
        <CallFormButton
          alt
          formProps={{ clientId: entity.Id }}
          skeleton={skeleton}
          className="mr-2"
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <TicketFormButton
          alt
          formProps={{ clientId: entity.Id }}
          skeleton={skeleton}
          className="mr-2"
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <TaskFormButton
          className="mr-2"
          alt
          skeleton={skeleton}
          formProps={{ clientId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <InterventionFormButton
          alt
          className="mr-2"
          skeleton={skeleton}
          formProps={{ clientId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <DealFormButton
          alt
          className="mr-2"
          skeleton={skeleton}
          hasAccess={isPositiveStatus}
          formProps={{ clientId: entity.Id }}
        />
      )}

      {isPositiveStatus && (
        <ContractFormButton
          alt
          className="mr-2"
          skeleton={skeleton}
          hasAccess={isPositiveStatus}
          formProps={{ clientId: entity.Id }}
        />
      )}
      {isPositiveStatus && (
        <SubscriptionFormButton
          alt
          formProps={{ clientId: entity.Id }}
          skeleton={skeleton}
          className="mr-2"
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <ClientFormButton
          alt
          className="mr-2"
          skeleton={skeleton}
          id={entity.Id}
          duplicate
        />
      )}
      {isPositiveStatus && (
        <ClientPdfButton
          alt
          className="mr-2"
          skeleton={skeleton}
          id={entity.Id}
        />
      )}
      {isPositiveStatus && (
        <ClientPdfButton alt skeleton={skeleton} id={entity.Id} excel={true} />
      )}
    </React.Fragment>
  );
};

export const ClientEntityActionsChildren = ({
  entity,
  minimal,
  sidebar,
  skeleton,
  detailsView
}) => {
  const intl = useIntl();
  const isPositiveStatus =
    entity.Status !== ClientStatusTypes.disabled &&
    entity.Status !== ClientStatusTypes.blocked;
  const accountSpace = useCurrentAccountSpace();
  return (
    <>
      {!sidebar && !detailsView && (
        <ClientFormButton contextIcons id={entity.Id} />
      )}

      {!detailsView && (
        <ClientFormButton globalEntityView contextIcons id={entity.Id} />
      )}

      {(canUserEditModule(accountSpace, moduleTypes.calls) ||
        canUserEditModule(accountSpace, moduleTypes.tickets) ||
        canUserEditModule(accountSpace, moduleTypes.tasks) ||
        canUserEditModule(accountSpace, moduleTypes.interventions) ||
        canUserEditModule(accountSpace, moduleTypes.projects)) &&
        isPositiveStatus && (
          <LabelTitle title={intl.formatMessage({ id: "SERVICES" })} />
        )}
      {canUserEditModule(accountSpace, moduleTypes.calls) && (
        <CallFormButton
          hasAccess={isPositiveStatus}
          formProps={{ clientId: entity.Id }}
        />
      )}

      {canUserEditModule(accountSpace, moduleTypes.projects) && (
        <ProjectFormButton
          hasAccess={isPositiveStatus}
          formProps={{ clientId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.tickets) && (
        <TicketFormButton
          hasAccess={isPositiveStatus}
          formProps={{ clientId: entity.Id }}
        />
      )}

      {canUserEditModule(accountSpace, moduleTypes.tasks) && (
        <TaskFormButton
          hasAccess={isPositiveStatus}
          formProps={{ clientId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.interventions) && (
        <InterventionFormButton
          hasAccess={isPositiveStatus}
          formProps={{ clientId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.interventions) && (
        <TimeTrackingGridFormButton
          origin={{
            type: "Client",
            data: entity.Id
          }}
        />
      )}
      {(canUserEditModule(accountSpace, moduleTypes.deals) ||
        canUserEditModule(accountSpace, moduleTypes.contracts) ||
        canUserEditModule(accountSpace, moduleTypes.subscriptions)) &&
        isPositiveStatus && (
          <LabelTitle title={intl.formatMessage({ id: "COMERCIAL" })} />
        )}
      {canUserEditModule(accountSpace, moduleTypes.deals) && (
        <DealFormButton
          hasAccess={isPositiveStatus}
          formProps={{ clientId: entity.Id, DealManager: entity.Manager }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.contracts) && (
        <ContractFormButton
          hasAccess={isPositiveStatus}
          formProps={{ clientId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.subscriptions) && (
        <SubscriptionFormButton
          hasAccess={isPositiveStatus}
          formProps={{ clientId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.contacts) &&
        isPositiveStatus && (
          <LabelTitle title={intl.formatMessage({ id: "CONTACTS" })} />
        )}
      {canUserEditModule(accountSpace, moduleTypes.contacts) && (
        <ContactFormButton
          hasAccess={isPositiveStatus}
          formProps={{ clientId: entity.Id, Manager: entity.Manager }}
        />
      )}
      <LabelTitle title={intl.formatMessage({ id: "ACTIONS" })} />
      {!sidebar && entity.CanEdit && (
        <ClientFormButton
          id={entity.Id}
          canEdit={entity.CanEdit}
          contextIcons
        />
      )}
      <ClientFormButton id={entity.Id} duplicate />
      <ClientPdfButton id={entity.Id} />
      <ClientPdfButton id={entity.Id} excel={true} />
      <ClientClipboardButton id={entity.Id} />
      <EntityReadButton value={entity} schema={spaceClientSchema} />
      <DeleteButtonContextMenu entity={entity} schema={spaceClientSchema} />
    </>
  );
};

export const ClientEntityActionsButton = ({ minimal, ...props }) => {
  return (
    <EntityPopup minimal={minimal}>
      <ClientEntityActionsChildren minimal={minimal} {...props} />
    </EntityPopup>
  );
};

export const ClientEntityGridButtons = ({ entity, minimal }) => {
  const conds = useTriggeredColorFilters(entity);

  return (
    <TriggeredColoredFilterProvider conds={conds}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="d-flex align-items-center"
      >
        <ClientFormButton
          minimal={minimal}
          id={entity.Id}
          canEdit={entity.CanEdit}
          className={classnames("mr-2 FormButtonClassID", {
            "ml-1": conds?.length > 0
          })}
        />
        <ClientEntityActionsButton entity={entity} minimal={minimal} />
        <InnerColorFilterBars />
      </div>
    </TriggeredColoredFilterProvider>
  );
};

export const ClientPdfButton = createPdfButton({
  //   generateUrl: ({ id }) =>
  //     `interventions/Export/GenerateTokenPdf/${id}/clients`,
  generateUrl: ({ id, excel }) =>
    excel
      ? `Excel/ExportTimeCharges/clients/${id}/ToFile`
      : `PDF/ExportTimeCharges/clients/${id}/ToFile`,
  AddQueryStrings: true,
  download: false,
  moduleType: moduleTypes.clients
});

// const createNoEntityComponent = ({ Button, shouldHide, getNewForm }) => {
//   const NoEntityComp = (props) => {
//     const { name, type, component: Component, className } = props;
//     const form = useContext(FormStateContext);

//     if (shouldHide && shouldHide(props)) return null;

//     const getParentForm = name
//       ? (v) => {
//           let resolvedValue;
//           if (type) {
//             resolvedValue = {
//               type,
//               data: v
//             };
//           } else resolvedValue = v;
//           return {
//             [name]: resolvedValue
//           };
//         }
//       : undefined;
//     // if(!name) return null;
//
//     return (
//       <Button
//         className={className}
//         Component={Component}
//         oldForm={form}
//         newForm={getNewForm ? getNewForm(props) : {}}
//         push={Boolean(form)}
//         getParentForm={getParentForm}
//         link
//         isChild
//       />
//     );
//   };
//   return NoEntityComp;
// };
