import React from "react";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import {
  useEnterViewSignalR,
  PageTypeProvider
} from "../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimePageEnum } from "../RealTime/RealTimePages";
// import {
//   RealTimePageProvider,
//   RealTimePageEnum
// } from "../RealTime/RealTimePages";
import ProjectDetails from "./ProjectDetails/ProjectDetails";
import ProjectOverview from "./ProjectOverview/ProjectOverview";

const Projects = React.memo(function Projects() {
  const match = useRouteMatch();
  // useEnterViewSignalR(RealTimePageEnum.Project)
  return (
    <PageTypeProvider pageType={RealTimePageEnum.Project}>
      <Switch>
        <Route path={`${match.url}/:projectId`}>
          <ProjectDetails />
        </Route>
        <Route>
          <ProjectOverview />
        </Route>
      </Switch>
    </PageTypeProvider>
  );
});

export default Projects;
