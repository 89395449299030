import React from "react";
import { useBilling } from "../../Hooks/EntityHooks";
import { spaceBillingSchema } from "../../config/schema";
import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";
import SplitCol from "../../Components/SplitCol/SplitCol";
import {
  OriginItemGroup,
  OriginHeader,
  OriginDate,
} from "../../Components/Origin/Origin";
import { createOriginPopup } from "./Origin";
import {
  ConnectBillingEntity,
  BillingStatusProfile,
} from "../../Components/Billing/Billing";
import { ConnectedOrganizationProfile } from "../../Components/Organizations/OrganizationProfile";
import OriginOverview from "./OriginOverview";
import FormattedTimePreview from "../../Components/FormattedTimePreview/FormattedTimePreview";
import { ClassificationFormButton } from "../Classification/ClassificationForm";
import { ConnectedClientOrigin } from "./ClientOrigin";
import Moment from "react-moment";
import { BillingIcon } from "../../Components/Common/EntityIcons";
import {  useIntl } from "react-intl";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUser } from "@fortawesome/pro-light-svg-icons";
import Button from "../../Components/Button/Button";
import TextEllipsis from "../../Components/TextEllipsis/TextEllipsis";
import { BillingEntityGridButtons } from "../Billing/Billing";
export const BillingOriginAnchor = React.forwardRef(
  ({ billing, dispatch, children, ...rest }, ref) => {
    const intl = useIntl();
    if (!billing) return null;
    // const openForm = useEntitySummarySidebar();
    const { Id, IsDeleted } = billing;
    return (
      <div
        ref={ref}
        className={classnames("link-color text-primary", {
          "item-was-deleted": IsDeleted,
        })}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <div className="link-color d-flex align-items-center fs-12 text-truncate">
            {/* <ProjectStatusProfile inline hideName status={intervention.Status} /> */}
            <div className="d-inline-flex align-items-center">
              <div className="position-relative d-inline-block mr-1">
                <FontAwesomeIcon icon={faFileUser} />
              </div>
            </div>
            <Button
              vType="link-primary"
              className="EntityLinkClassID fs-12 text-black"
              // onClick={() => {
              //   openForm({
              //     type: moduleTypes.billing,
              //     props: {
              //       id: Id
              //     }
              //   });
              // }}
            >
              <span>
                <TextEllipsis
                  text={`${intl.formatMessage({ id: "CLASSIFICATION" })} ${Id}`}
                />
              </span>
            </Button>

            {/* {Id && <div className="fs-10">Nº: {Id}</div>} */}
          </div>
        )}
      </div>
    );
  }
);
export const ConnectedBillingOriginAnchor = ({ billing, ...rest }) => {
  const resolvedCall = useBilling(billing);

  return <BillingOriginAnchor call={resolvedCall} {...rest} />;
};

export const BillingOriginPopup = ({ billing }) => {
  const intl = useIntl();
  const b = useBilling(billing.Id);

  const {
    Id,
    Client,
    IndirectClient,
    CreationDate,
    CanEdit,
    Identifier,
    Status,
    Organization,
    InputtedHours,
    Duration,
  } = b;

  return (
    <div className="ar-origin-popup bg-white">
      <OriginHeader
        Dif
        className="mb-1"
        type="Tempo"
				date={CreationDate}
        entity="TIME"
        Icon={<BillingIcon className="mr-2" />}
        id={Id}
        EntityButtons={<BillingEntityGridButtons entity={b} minimal />}
        title={`${intl.formatMessage({ id: "CLASSIFICATION" })} ${Id}`}
      />
      <div className="mx-3 mb-1">
        {/* <div className="text-black fs-14 mb-2">{Name}</div> */}
        <div className="mb-1 d-flex align-items-center justify-content-between">
          <BillingStatusProfile status={Status} />
          <div>
            <OriginDate className="fs-12" date={CreationDate} />
          </div>
        </div>
      </div>
      {Identifier && (
        <span className="text-black fs-14 px-3 fw-medium mb-1">
          {Identifier}
        </span>
      )}

      <SplitCol
        className="mb-2"
        left={
          <OriginItemGroup title={intl.formatMessage({ id: "ACCOUNT" })}>
            <ConnectedClientOrigin client={Client || IndirectClient}>
              <ConnectedClientProfile
                showId
                client={Client || IndirectClient}
              />
            </ConnectedClientOrigin>
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup title={intl.formatMessage({ id: "COMPANY" })}>
            <ConnectedOrganizationProfile organization={Organization} />
          </OriginItemGroup>
        }
      />

      <SplitCol
        className="mb-2"
        left={
          <OriginItemGroup title={intl.formatMessage({ id: "DATE" })}>
            <Moment
              className="fs-12 text-black"
              date={CreationDate}
              format="DD/MM/YYYY HH:mm"
            />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup title={intl.formatMessage({ id: "ORIGIN" })}>
            <OriginOverview entity={b} />
          </OriginItemGroup>
        }
      />

      <SplitCol
        left={
          <OriginItemGroup title={intl.formatMessage({ id: "TOTAL_HOURS" })}>
            <FormattedTimePreview
              className="fs-12 text-black"
              asHourMinute
              value={Duration}
            />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup title={intl.formatMessage({ id: "INPUTED_HOURS" })}>
            <FormattedTimePreview
              className="fs-12 text-black"
              asHourMinute
              value={InputtedHours}
            />
          </OriginItemGroup>
        }
      />

      {/* <SplitCol
        className="mb-2"
        left={
          <OriginItemGroup title="Contato">
            <ConnectedContactProfile contact={Contact} />
          </OriginItemGroup>
        }
      />
      <SplitCol
        left={
          <OriginItemGroup title="Atribuídoa">
            <ConnectedAccountProfile account={ManagerAccount} />
          </OriginItemGroup>
        }
      />

      */}
      <div className="d-flex align-items-center justify-content-center shadow-top py-3 mt-3">
        <ClassificationFormButton
          id={Id}
          canEdit={CanEdit}
          className="ssi-button-primary-ghost mx-1"
        />
      </div>
    </div>
  );
};

const BillingOrigin = createOriginPopup(
  BillingOriginAnchor,
  BillingOriginPopup,
  { valueProp: "billing", schema: spaceBillingSchema }
);

export default BillingOrigin;

export const ConnectedBillingOrigin = ConnectBillingEntity(BillingOrigin);
