import ClassificationOverview from "./ClassificationOverview/ClassificationOverview";
import { getItemFromEntityState } from "../../Hooks/EntityHooks";
import { spaceInterventionsSchema } from "../../config/schema";
import store from "../../store";

const buildInterventionState = (state, intervention) => {
  const {
    InputtedDuration,
    EstimatedCost,
    DeslocationTime,
    InefficientDuration,
    TotalPauses
  } = getItemFromEntityState(spaceInterventionsSchema, intervention, state);
  const iState = {
    EstimatedCost,
    DeslocationTime,
    InefficientDuration,
    TotalPauses,
    InputtedDuration:
      InputtedDuration !== undefined ? InputtedDuration : EstimatedCost,
    intervention
  };
  return iState;
};

export const buildInterventionsState = (
  newInterventions,
  billingInterventions
) => {
  const newInterventionsState = [];
  const billingInterventionsState = [];
  const state = store.getState();

  for (const i of newInterventions) {
    newInterventionsState.push(buildInterventionState(state, i));
  }
  if (billingInterventions) {
    for (const i of billingInterventions) {
      billingInterventionsState.push(buildInterventionState(state, i));
    }
  }
  return {
    new: newInterventionsState,
    billing: billingInterventionsState,
    removedBilling: []
  };
};

export default ClassificationOverview;
