import React, { useState, useContext, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentsAlt,
  faChevronDoubleRight,
  faPaperclip,
  faNote
} from "@fortawesome/pro-light-svg-icons";
import "./Messages.css";
import Badge from "../../Badge/Badge";
import {
  ConnectedMessenger,
  ConnectedNoteLog,
  UnreadMessagesContext
} from "./MessagesConnection";
import { useConnectionId } from "../../../Containers/RealTime/RealTime";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import FilesOverview from "./FilesOverview/FilesOverview";
import {
  SidebarVisualTypeEnum,
  useSidebarView
} from "../../Sidebar/SidebarViewsHelper";
import { getEntity, useSpaceQuery } from "../../../Helpers/IOClient";
import {
  spaceClientSchema,
  spaceDealsSchema,
  spaceContractSchema,
  spaceSubscriptionSchema,
  spaceClassificationSchema,
  spaceCallsSchema,
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceTasksSchema,
  spaceInterventionsSchema
} from "../../../config/schema";

const NewMessagesCount = ({ count = 0 }) => {
  const newCount = useContext(UnreadMessagesContext);

  const resolvedCount = newCount;

  if (!resolvedCount || resolvedCount === "0") return null;

  // const resolvedCount = count > 9 ? 9 : count;

  return (
    <Badge type="primary" className="ml-2">
      {resolvedCount}
    </Badge>
    // <div className="ar-entity-summary-messages-count">{resolvedCount}</div>
  );
};

const viewEnum = {
  messages: 1,
  files: 2,
  notes: 3
};

const HeaderItem = ({ children, view, selectedView, setView, className }) => {
  const handleClick = () => {
    setView(view);
  };

  const selected = view === selectedView;

  return (
    <div
      onClick={handleClick}
      className={classnames(
        "ar-messages-header-item d-flex px-2 pb-3 cursor-pointer",
        { selected },
        className
      )}
    >
      {children}
    </div>
  );
};

const Header = ({
  newMessages,
  onToggle,
  view,
  countDate = {},
  setView,
  receptionInbox
}) => {
  const SidebarState = useSidebarView();
  const { TotalNotes } = countDate || {};
  if (receptionInbox) {
    return (
      <div>
        <div className="text-black d-flex align-items-center disable-selection">
          <div className="flex-1 align-items-center d-flex px-3 ">
            <HeaderItem
              view={viewEnum.messages}
              selectedView={view}
              setView={setView}
              className="mr-4 EmailChatHeader align-items-center"
            >
              <div className="mr-2">
                <FontAwesomeIcon icon={faCommentsAlt} />
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <FormattedMessage id="MESSAGES" />
                </div>
                <NewMessagesCount count={newMessages} />
              </div>
            </HeaderItem>
            <HeaderItem
              view={viewEnum.files}
              selectedView={view}
              setView={setView}
              className="EmailChatHeader align-items-center"
            >
              <div className="mr-2">
                <FontAwesomeIcon icon={faPaperclip} />
              </div>
              <FormattedMessage id="FILES" />
            </HeaderItem>
          </div>
          {SidebarVisualTypeEnum.fullscreen !== SidebarState && onToggle && (
            <div
              className="px-3 h-100 cursor-pointer text-primary"
              onClick={onToggle}
            >
              <FontAwesomeIcon icon={faChevronDoubleRight} />
            </div>
          )}
        </div>
        <div className="border-bottom mx-3"></div>
      </div>
    );
  } else
    return (
      <div className="text-black d-flex align-items-center border-bottom mx-3 pt-1">
        <div className="flex-1 d-flex">
          <HeaderItem
            view={viewEnum.messages}
            selectedView={view}
            setView={setView}
            className="mr-4"
          >
            <div className="mr-2">
              <FontAwesomeIcon icon={faCommentsAlt} />
            </div>
            <div className="d-flex align-items-center">
              <div>
                <FormattedMessage id="MESSAGES" />
              </div>
              <NewMessagesCount count={newMessages} />
            </div>
          </HeaderItem>
          <HeaderItem
            view={viewEnum.notes}
            selectedView={view}
            setView={setView}
            className="mr-4"
          >
            <div className="mr-2">
              <FontAwesomeIcon icon={faNote} />
            </div>
            <FormattedMessage id="NOTES" />
            {TotalNotes && TotalNotes > 0 ? (
              <div className="ml-2">({TotalNotes})</div>
            ) : (
              ""
            )}
          </HeaderItem>
          <HeaderItem
            view={viewEnum.files}
            selectedView={view}
            setView={setView}
          >
            <div className="mr-2">
              <FontAwesomeIcon icon={faPaperclip} />
            </div>
            <FormattedMessage id="FILES" />
          </HeaderItem>
        </div>
        {SidebarVisualTypeEnum.fullscreen !== SidebarState && onToggle && (
          <div
            className="px-3 h-100 cursor-pointer text-primary"
            onClick={onToggle}
          >
            <FontAwesomeIcon icon={faChevronDoubleRight} />
          </div>
        )}
      </div>
    );
};

const AbsoluteUnreadMessages = () => {
  const newCount = useContext(UnreadMessagesContext);
  const resolvedCount = newCount > 9 ? 9 : newCount;

  if (resolvedCount === 0) return null;

  return (
    <div className="ar-entity-summary-message-unread-badge">
      {resolvedCount}
    </div>
  );
};

const NoteCounterEnum = {
  [spaceClientSchema.name]: 0,
  [spaceDealsSchema.name]: 1,
  [spaceContractSchema.name]: 2,
  [spaceSubscriptionSchema.name]: 3,
  [spaceCallsSchema.name]: 4,
  [spaceProjectsSchema.name]: 5,
  [spaceTicketsSchema.name]: 6,
  [spaceTasksSchema.name]: 7,
  [spaceInterventionsSchema.name]: 8
};

export const EntitySummaryMessages = ({
  show,
  onToggle,
  type,
  entityId,
  newMessages,
  schema,
  roomType,
  width,
  className,
  receptionInbox,
  disableMessages,
  style
}) => {
  // const animating = useSidebarAnimationState();
  const conId = useConnectionId();

  const entityNotesUrl = useMemo(() => {
    const entityType = NoteCounterEnum[schema.name];

    if (entityType >= 0) {
      return `Notes/${entityType}/${entityId}/Counter`;
    }

    return null;
  }, [entityId, schema.name]);

  const { data } = useSpaceQuery(entityNotesUrl, null);

  const entityItem = getEntity(schema, entityId);

  const [view, setView] = useState(viewEnum.messages);

  if (show === false)
    return (
      <div
        className="px-2 pt-4 d-flex justify-content-center flex-1 ar-entity-summary-messages"
        style={{ width: 60 }}
      >
        <div
          key={1}
          className="ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button position-relative"
          onClick={onToggle}
        >
          <FontAwesomeIcon icon={faCommentsAlt} />
          <AbsoluteUnreadMessages />
        </div>
      </div>
    );

  const key = `${conId}${entityId}${type}`;

  return (
    <div
      className={classnames("ar-entity-summary-messages flex-1", className)}
      style={{ width: width || "100%" }}
    >
      <div
        style={{ minWidth: width, ...style }}
        className={
          receptionInbox
            ? "d-flex flex-column flex-1 h-100"
            : "pt-4 d-flex flex-1 flex-column h-100"
        }
      >
        <Header
          receptionInbox={receptionInbox}
          view={view}
          setView={setView}
          entityItem={entityItem}
          newMessages={newMessages}
          countDate={data}
          onToggle={onToggle}
        />
        <div
          className="mx-auto flex-1 of-hidden d-flex position-relative flex-column w-100"
          style={{ maxWidth: 1200 }}
        >
          {view === viewEnum.messages ? (
            <ConnectedMessenger
              roomType={roomType}
              type={type}
              schema={schema}
              key={key}
              entityId={entityId}
            />
          ) : view === viewEnum.notes ? (
            <ConnectedNoteLog
              noteCreator
              roomType={roomType}
              type={type}
              schema={schema}
              key={key}
              entityId={entityId}
              disableMessages={disableMessages}
            />
          ) : (
            <FilesOverview schema={schema} entityId={entityId} />
          )}
        </div>
      </div>
    </div>
  );
};
