import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const InterventionOverviewComp = React.lazy(() =>
  import(
    /* webpackChunkName: "interventions-overview" */ "./InterventionOverviewComp"
  )
);

const InterventionOverview = () => {
  return (
    <SuspenseErrorBoundary>
      <InterventionOverviewComp />
    </SuspenseErrorBoundary>
  );
};

export default InterventionOverview;
