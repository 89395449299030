import React from "react";
import { useSubscription } from "../../../../Hooks/EntityHooks";
import {
  HistoryHeader,
  HistoryContent,
  HistoryAccountProfile,
  HistoryOverview,
  useHistoryItem,
} from "./HistoryItem";
import { createItemEditDetailsComponent } from "./HistoryItemDetails/HistoryItemDetails";
import classnames from "classnames";
import { ConnectedSubscriptionOrigin } from "../../../../Containers/Origin/SubscriptionOrigin";
import { FormattedMessage } from "react-intl";

const buildSubscriptionStatusType = (status) => {
  switch (status.toString()) {
    case "2":
      return "danger";
    case "3":
      return "gray";
    case "5":
      return "orange";
    default:
      return "purple";
  }
};

const statusNameByValue = (status) => {
  switch (status.toString()) {
    case "1":
      return <FormattedMessage id="OPEN"/>;
    case "2":
      return <FormattedMessage id="CONCLUDED"/>;
    case "3":
      return <FormattedMessage id="CANCELLED"/>;
    case "5":
      return <FormattedMessage id="REVIEW"/>;
    default:
      return null;
  }
};

export const SubscriptionStatusProfile = ({
  entity,
  status,
  item,
  value,
  hideName,
  inline,
  className,
}) => {
  const resovledStatus = entity ? entity.Status : status || item || value;

  if (!resovledStatus) return null;

  return (
    <div
      className={classnames(
        `link-color status-color-${buildSubscriptionStatusType(
          resovledStatus
        )}`,
        className,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div className="position-relative status-dot" />
      {!hideName && (
        <div className="status-label fw-medium fs-12">
          {statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

const SubscriptionHistoryOverview = () => {
  const { Subscription, IsCreation, Account } = useHistoryItem();
  const item = useSubscription(Subscription);
  const { NoTagDescription } = item;
  if (IsCreation)
    return (
      <HistoryOverview>
        <HistoryAccountProfile account={Account} />
        <span>
          {" "}
          <FormattedMessage id={"CREATED_SUBSCRIPTION"} />{" "}
        </span>{" "}
        <b>{NoTagDescription}</b>
      </HistoryOverview>
    );

  return (
    <HistoryOverview>
      <HistoryAccountProfile account={Account} />
      <span>
        {" "}
        <FormattedMessage id={"EDITED_SUBSCRIPTION"} />{" "}
      </span>{" "}
      <b>{NoTagDescription}</b>
    </HistoryOverview>
  );
};

const SubscriptionEditItemDetails = createItemEditDetailsComponent({
  Status: {
    NameId: "status",
    ValueComponent: SubscriptionStatusProfile,
  },
});

const SubscriptionHistoryDetails = () => {
  const { IsCreation } = useHistoryItem();
  if (IsCreation) return null;

  return <SubscriptionEditItemDetails />;
};

const SubscriptionHistoryItem = () => {
  const { Subscription } = useHistoryItem();

  return (
    <>
      <HistoryHeader>
        <ConnectedSubscriptionOrigin Subscription={Subscription}>
          {/* <HistoryTitle Icon={ContractIcon}>
            <FormattedMessage id={"SUBSCRIPTION"} /> {Subscription}
          </HistoryTitle> */}
        </ConnectedSubscriptionOrigin>
      </HistoryHeader>
      <HistoryContent>
        <SubscriptionHistoryOverview />
        <SubscriptionHistoryDetails />
      </HistoryContent>
    </>
  );
};
export default SubscriptionHistoryItem;
