import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Button from "../Button/Button";
import LoadableButton from "../Button/LoadableButton";
import FormInput from "../Forms/FormInput/FormInput";
import { v1 as uuidv1 } from "uuid";
import {
  ServerAwareUpdateViewsContext,
  ServerAwareViewsContext
} from "../CGrid/ServerAwareViews";
import querystring from "query-string";
import { useSpace } from "../../Contexts/SpaceContext";
import { HandleQueryStringObjectMaker } from "../../Helpers/QueryStringerHelper";

const handleViewSelection = (id) => {
  const option = document.getElementById(id);
  if (option) option?.click();
};

const ViewSaver = ({ schema, layoutName, onClose }) => {
  // const UpdateViews = useContext(ServerAwareUpdateViewsContext);
  const lsViews = useContext(ServerAwareViewsContext);
  const space = useSpace();
  const intl = useIntl();
  const [name, setName] = useState("");
  const { search } = useLocation();

  const handleChange = (e) => {
    const {
      target: { value }
    } = e;
    setName(value);
  };
  const updateLsViews = useContext(ServerAwareUpdateViewsContext);

  // const history = useHistory();
  const handleSubmit = () => {
    if (name === "Default View") {
      return;
    }

    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );

    const queryObj = querystring.parse(search);
    const resolvedQueryObj = HandleQueryStringObjectMaker(queryObj);
    const oldViewPref = resolvedQueryObj.viewPref;
    const id = uuidv1();

    const index = lsViews.findIndex((e) => e.id === oldViewPref);
    resolvedQueryObj.viewPref = id;
    const newSearch = querystring.stringify(resolvedQueryObj);
    if (gridSavedViews && gridSavedViews[schema.name]) {
      const viewGridViews = gridSavedViews[schema.name];
      const currentItem = lsViews[index !== -1 ? index : 0];

      viewGridViews.push({ ...currentItem, name, id, search: newSearch });
      gridSavedViews[schema.name] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
    } else {
      const columnLayout = lsViews[index !== -1 ? index : 0].columnLayout;
      const columnSize = lsViews[index !== -1 ? index : 0].columnSize;

      const gridViews = {
        ...gridSavedViews,
        [schema.name]: [
          {
            search: newSearch,
            name,
            id,
            columnLayout,
            columnSize
          }
        ]
      };
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridViews)
      );
    }

    // history.push({
    // 	search: newSearch
    // });
    updateLsViews();
    window.setTimeout(() => {
      handleViewSelection(id);
    }, 50);
    onClose && onClose();
  };

  return (
    <div className="w-450px">
      <div
        style={{ height: 51 }}
        className="text-black px-4 fw-medium d-flex align-items-center ar-entity-summary-header"
      >
        <FormattedMessage id="SAVE_VIEW" />
      </div>
      <div className="p-4">
        <FormInput
          text={intl.formatMessage({ id: "NAME" })}
          className="mb-4"
          value={name}
          error={name === "Default View"}
          errorMessage={intl.formatMessage({ id: "REGISTER_INVALID_NAME" })}
          onChange={handleChange}
        />
        <div className="d-flex justify-content-end">
          <Button onClick={onClose} vType="link-danger mr-3">
            <FormattedMessage id="CANCEL" />
          </Button>
          <LoadableButton
            disabled={!name || name.match(/^\s*$/) || name === "Default View"}
            onClick={handleSubmit}
          >
            <FormattedMessage id="SAVE" />
          </LoadableButton>
        </div>
      </div>
    </div>
  );
};

export default ViewSaver;
