import React, { useContext, useMemo } from "react";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import { ElementsRefContext } from "../../AutomationDetails";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import SourceHandles from "../../Handles/SourceHandles";
import TargetHandles from "../../Handles/TargetHandles";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseSidebar from "../NodeBaseSidebar";
import AdvancedAutomationFilters, {
  buildResolvedFilters,
  resetParameters
} from "./AdvancedAutomationFilters";
import { FormattedMessage, useIntl } from "react-intl";
import Switch from "../../../../../Components/Switch/Switch";
import FormInput from "../../../../../Components/Forms/FormInput/FormInput";
import { ContactDropdown } from "../../../../AdvancedMultiInputs";
import {
  AvailableFiltersContext,
  useFiltersDict,
  useResolvedAvailableFilters
} from "../../../../../Components/FilterList/AdvancedFilter/AdvancedFilterHelper";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import NodeIconNameContainer from "../NodeIconNameContainer";
import { faUserTie } from "@fortawesome/pro-solid-svg-icons";
import { ContactFilterColumns } from "../../../../Contacts/ContactOverview/ContactOverviewComp";

const SwitchComponent = ({ ...props }) => {
  const { value, onChange } = props;

  const handleChange = (e) => {
    const { checked } = e.target;
    onChange(checked);
  };

  return (
    <div className="d-flex align-items-center mb-3 justify-content-between">
      <div className="text-black fs-14">
        <FormattedMessage id="ENABLE_MULTIPLE" />
      </div>
      <Switch {...props} onChange={handleChange} checked={value} />
    </div>
  );
};

const TicketAdvancedFilters = ({ onChange, value }) => {
  const callFilters = [...ContactFilterColumns];

  const resolvedAvailableFilters = useResolvedAvailableFilters(callFilters);

  const { fieldDict, mappedFieldDict } = useFiltersDict(
    resolvedAvailableFilters
  );
  const availableFiltersValue = useMemo(() => {
    return {
      availableFilters: resolvedAvailableFilters,
      mappedFieldDict,
      fieldDict
    };
  }, [fieldDict, mappedFieldDict, resolvedAvailableFilters]);

  const resolvedFiltersValue = useMemo(() => {
    return value
      .map((e) => {
        return buildResolvedFilters(e, availableFiltersValue);
      })
      .map((e) => e.filter);
  }, [availableFiltersValue, value]);

  return (
    <AvailableFiltersContext.Provider value={availableFiltersValue}>
      <AdvancedAutomationFilters
        filters={resolvedFiltersValue}
        availableFilters={resolvedAvailableFilters}
        handleChange={onChange}
      />
    </AvailableFiltersContext.Provider>
  );
  // filterType={FilterViewTypeEnum.Task}
};

const FormContainer = ({ formState, id, canEdit }) => {
  const { form, handleChange } = formState;
  const { TargetId, EnableMultiple, Filters } = form;
  const intl = useIntl();
  const onChange = (e) => {
    const { value } = e.target;
    handleChange({
      TargetId: value
    });
  };
  const onCheckChange = (e) => {
    handleChange({
      EnableMultiple: e
    });
  };

  return (
    <div>
      <SwitchComponent onChange={onCheckChange} value={EnableMultiple} />
      {!EnableMultiple && (
        <FormInput
          // disabled={Id}
          name="targetId"
          className="mb-3"
          onChange={onChange}
          value={TargetId}
          text={intl.formatMessage({ id: "CONTACT" })}
          inputType={ContactDropdown}
        />
      )}
      {EnableMultiple && (
        <TicketAdvancedFilters onChange={handleChange} value={Filters} />
      )}
    </div>
  );
};

const settings = {
  defaultForm: {
    TargetId: null,
    EnableMultiple: false,
    Filters: []
  },
  FormContent: (props) => {
    return <FormContainer {...props}></FormContainer>;
  }
};

const GetContactNode = React.memo(({ selected, data, id, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    let resolvedNewData = { ...newData };
    if (resolvedNewData.form.Filters.length > 0) {
      resetParameters(resolvedNewData.form.Filters);
    }
    updater(id, { ...resolvedNewData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };
  const { enabled, config } = data;
	const intl = useIntl();
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
				<NodeIconNameContainer
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleClick}
          selected={selected}
          icon={faUserTie}
          text={intl.formatMessage({ id: "GET_CONTACT" })}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default GetContactNode;
