import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const CallDetailsComp = React.lazy(() =>
  import(/* webpackChunkName: "calls-details" */ "./CallDetails2Comp")
);

const CallDetails = () => {
  return (
    <SuspenseErrorBoundary>
      <CallDetailsComp />
    </SuspenseErrorBoundary>
  );
};

export default CallDetails;
