import React, { useMemo } from "react";
import classnames from "classnames";
import {
  DealStatusTypes,
  DealStatusTypesForFilter
} from "../../Containers/Deals/DealsHelper";
import { FormattedMessage } from "react-intl";
import { DealIcon } from "../Common/EntityIcons";
import {
  DealsCanceledStatusDot,
  DealsDraftStatusDot,
  DealsLostStatusDot,
  DealsNewStatusDot,
  DealsWonStatusDot,
  StatusDealLostLabel,
  StatusDealWonLabel,
  StatusGreenLabel,
  StatusGreyLabel,
  StatusOrangeLabel,
  StatusPurpleLabel,
  StatusRedLabel
} from "../StatusHelper/StatusHelper";

export const buildDealStatusType = (status, sensitive = false) => {
  switch (parseInt(status)) {
    case DealStatusTypes.canceled:
      return "grey";

    case DealStatusTypes.draft:
      return "orange";

    case DealStatusTypes.lost:
      return "danger";

    case DealStatusTypes.open:
      return "purple";

    case DealStatusTypes.won:
      return "success";

    default:
      return sensitive ? "outline-grey" : "success";
  }
};

const statusNameByValue = (status) => {
  switch (status.toString()) {
    case DealStatusTypesForFilter.canceled:
      return <FormattedMessage id={StatusGreyLabel} />;

    case DealStatusTypesForFilter.lost:
      return <FormattedMessage id={StatusDealLostLabel} />;

    case DealStatusTypesForFilter.draft:
      return <FormattedMessage id={StatusOrangeLabel} />;

    case DealStatusTypesForFilter.open:
      return <FormattedMessage id={StatusPurpleLabel} />;

    case DealStatusTypesForFilter.won:
      return <FormattedMessage id={StatusDealWonLabel} />;
    default:
      return null;
  }
};

export const DealStatusProfileWithIcon = ({
  project,
  status,
  item,
  hideName,
  className,
  Icon,
  dotClassName,
  inline,
  sensitive,
  value,
  size = 24
}) => {
  const resovledStatus = project ? project.Status : status || item || value;

  if (!resovledStatus && !sensitive) return null;

  return (
    <div
      className={classnames(
        `link-color status-color-${buildDealStatusType(
          resovledStatus,
          sensitive
        )}`,
        className,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div
        style={{ width: size, height: size }}
        className={`d-flex align-items-center justify-content-around position-relative text-truncate status-dot m-0 status-dot-background-template`}
      >
        <DealIcon />
      </div>
      {!hideName && (
        <div className="status-label fs-12">
          {statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

const DealStatusSvgIcons = (status, sensitive = false) => {
  switch (parseInt(status)) {
    case DealStatusTypes.canceled:
      return <DealsCanceledStatusDot />;

    case DealStatusTypes.lost:
      return <DealsLostStatusDot />;

    case DealStatusTypes.open:
      return <DealsNewStatusDot />;

    case DealStatusTypes.won:
      return <DealsWonStatusDot />;

    case DealStatusTypes.draft:
      return <DealsDraftStatusDot />;

    default:
      return sensitive ? <DealsCanceledStatusDot /> : <DealsNewStatusDot />;
  }
};

const DealStatusProfile = ({
  project,
  status,
  item,
  hideName,
  className,
  dotClassName,
  inline,
  sensitive,
  pipelineName,
  value,
  children
}) => {
  const resovledStatus = project ? project.Status : status || item || value;

  const SvgComponent = useMemo(() => {
    if (!resovledStatus && !sensitive) return null;
    return DealStatusSvgIcons(resovledStatus, sensitive);
  }, [resovledStatus, sensitive]);

  if (!resovledStatus && !sensitive) return null;

  return (
    <div
      className={classnames(
        `link-color flex-0-0-auto text-truncate status-color-${buildDealStatusType(
          resovledStatus,
          sensitive
        )}`,
        className,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div
        className={classnames("position-relative status-dot-svg", dotClassName)}
      >
        {SvgComponent}
      </div>
      {!hideName && (
        <div className="status-label text-truncate fs-12">
          {pipelineName || statusNameByValue(resovledStatus)}
        </div>
      )}
      {children}
    </div>
  );
};

export default DealStatusProfile;
