/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useMemo,
  useLayoutEffect,
  useCallback,
  Component
} from "react";
import ReactDOM from "react-dom";

import "./CardChooser.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/pro-regular-svg-icons";
import classnames from "classnames";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

import { ReverseCardChooserListnerDict } from "./ReverseCardChooserHelper";
import { subscribeUniqueEvent } from "../../../Helpers/ComponentsHelper";
import Button from "../../../Components/Button/Button";
import Input from "../../../Components/Input/Input";
import Checkbox from "../../../Components/Checkbox/Checkbox";
import { FormattedMessage, useIntl } from "react-intl";

const getState = (columns, schemaName) => {
  //local storage
  let gridColumnLayout = JSON.parse(
    window.localStorage.getItem("ar-grid-card-layout")
  );

  const orderList = gridColumnLayout ? gridColumnLayout[schemaName] : null;
  const orderListDict = {};

  let orderListExists = Array.isArray(orderList) ? true : false;
  if (orderListExists)
    for (const ol of orderList) {
      orderListDict[ol] = true;
    }

  const state = {};

  for (const c of columns) {
    if (!orderListExists) state[c.field] = false;
    else state[c.field] = orderListDict[c.field] || undefined;
  }

  return state;
};

const getOrderedColumns = (Columns, schemaName) => {
  let gridColumnLayout = JSON.parse(
    window.localStorage.getItem("ar-grid-card-layout")
  );

  const orderList = gridColumnLayout ? gridColumnLayout[schemaName] : null;

  if (orderList) {
    return orderList.map(e => {
      return e;
    });
  } else {
    return [];
  }
};

const getfieldTitleDict = Columns => {
  const result = {};
  for (const c of Columns) {
    result[c.field] = c;
  }
  return result;
};

export const ReverseCardChooser = ({
  schema,
  onClose,
  setIsOpen,
  isOpen,
  Columns,
  ...rest
}) => {
  const fieldDict = getfieldTitleDict(Columns);
  const intl = useIntl();
  useEffect(() => {
    return subscribeUniqueEvent(e => {
      if (e.keyCode === 27) {
        e.preventDefault();
        setIsOpen(false);
      }
    });
  }, [isOpen, setIsOpen]);

  const schemaName = schema ? schema.name : rest?.Name;
  const [searchBarValue, setSearchBarValue] = useState("");
  const [selectionState, setSelectionState] = useState(() => {
    return getState(Columns, schemaName);
  });
  const [orderedColumns, setOrderedColumns] = useState(() => {
    return getOrderedColumns(Columns, schemaName);
  });

  const resolvedColumns = useMemo(() => {
    if (!searchBarValue) return Columns;

    const newColumns = Columns.filter(
      v =>
        v.titleId &&
        intl
          .formatMessage({ id: v.titleId })
          .toLowerCase()
          .includes(searchBarValue.toLowerCase())
    );

    return newColumns;
  }, [Columns, intl, searchBarValue]);

  const handleSelectionChange = useCallback((field, isChecked) => {
    if (!isChecked) {
      setOrderedColumns(c => {
        const newColumns = [...c];
        const index = newColumns.indexOf(field);
        newColumns.splice(index, 1);
        return newColumns;
      });
    } else {
      setOrderedColumns(c => {
        const newColumns = [...c];
        newColumns.unshift(field);
        return newColumns;
      });
    }
    setSelectionState(s => {
      const newState = { ...s };
      newState[field] = isChecked;
      return newState;
    });
  }, []);

  const submit = () => {
    let gridColumnLayout = JSON.parse(
      window.localStorage.getItem("ar-grid-card-layout")
    );
    if (gridColumnLayout) {
      gridColumnLayout[schemaName] = orderedColumns;
      localStorage.setItem(
        "ar-grid-card-layout",
        JSON.stringify(gridColumnLayout)
      );
    } else {
      const gridColumnLayout = { [schemaName]: orderedColumns };
      localStorage.setItem(
        "ar-grid-card-layout",
        JSON.stringify(gridColumnLayout)
      );
    }

    if (ReverseCardChooserListnerDict[schemaName]) {
      for (const listner of ReverseCardChooserListnerDict[schemaName]) {
        listner(orderedColumns);
      }
    }
    onClose();
  };

  const reset = () => {
    let gridColumnLayout = JSON.parse(
      window.localStorage.getItem("ar-grid-card-layout")
    );
    if (gridColumnLayout) {
      delete gridColumnLayout[schemaName];
      localStorage.setItem(
        "ar-grid-card-layout",
        JSON.stringify(gridColumnLayout)
      );
      if (ReverseCardChooserListnerDict[schemaName]) {
        for (const listner of ReverseCardChooserListnerDict[schemaName]) {
          listner();
        }
      }
    }
    onClose();
  };

  return (
    <div className="CardChooser-ModalWrap">
      <div className="p-30">
        <div className="fs-16 mb-10 fw-bold text-black d-flex justify-content-between">
          <span className="pt-1">
            <FormattedMessage id={"CHOOSE_MODULES"} />
          </span>
        </div>
        <div className="d-flex mb-4">
          <div className="CardChooserContent justify-content-between pr-4 CardChooserContent-borderRight">
            <ColumnChooserSearchBar
              value={searchBarValue}
              handleChange={setSearchBarValue}
              className="w-a mb-3"
            />

            <ColumnChooserCheckList
              filterBy={searchBarValue}
              onChange={handleSelectionChange}
              Columns={resolvedColumns}
              selectionState={selectionState}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <Button onClick={reset} vType="link-primary">
              <FormattedMessage id={"RESTORE_MODULES"} />
            </Button>
          </div>
          <div>
            <Button
              className="mr-3"
              onClick={() => {
                setIsOpen(false);
              }}
              vType="link-danger"
            >
              <FormattedMessage id={"CANCEL"} />
            </Button>
            <Button onClick={submit} vType="primary">
              <FormattedMessage id={"SAVE"} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColumnChooserSearchBar = ({
  handleChange,
  className,
  value,
  focused,
  ...rest
}) => {
  const { inputRef, handleFocus, handleBlur } = rest;
  const intl = useIntl();

  const placeholder = intl.formatMessage({ id: "SEARCH_MODULES" });

  const onChange = e => {
    handleChange(e.target.value);
  };

  return (
    <div
      className={classnames(
        "ssi-control rounded Card-chooser-search-bar text-black ar-server-aware-input d-flex align-items-center p-2 mb-2",
        className,
        {
          selected: focused
        }
      )}
    >
      <div className="ar-server-aware-input-icon mr-2">
        {/* {isLoading ? (
          <LoaderSpinner className="text-primary" />
        ) : (
          <FontAwesomeIcon icon={faSearch} className="text-primary" />
        )} */}
        <FontAwesomeIcon icon={faSearch} className="text-primary" />
      </div>
      <Input
        ref={inputRef}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
		onFocus={handleFocus}
		onBlur={() => {
			if (handleBlur) handleBlur();
		  }}

        className="flat-input p-0"
        {...rest}
      />
      {value.length > 0 && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleChange("");
          }}
        >
          {" "}
          <FontAwesomeIcon icon={faTimes} className="text-warning" />
        </div>
      )}
    </div>
  );
};

const ColumnChooserCheckList = ({ Columns, selectionState, onChange }) => {
  let columnsList = "";
  let filteredList = null;
  const intl = useIntl();
  if (Columns) {
    columnsList = Columns.map((e, index) => {
      const isChecked = selectionState[e.field] || false;
      return (
        <div
          key={
            e.field + "" + index + "" + intl.formatMessage({ id: e.titleId })
          }
          className="d-flex justify-content-start mb-2 text-black"
        >
          <Checkbox
            disabled={e.required && isChecked}
            change={() => {
              onChange(e.field, !isChecked);
            }}
            checked={isChecked}
            textValue={intl.formatMessage({ id: e.titleId })}
          />
          {/* <span
            style={{ paddingTop: 2 }}
            onClick={() => {
              !e.required && onChange(e.field, !isChecked);
            }}
            className={`disableLabelSelect text-black fs-14 ml-2 ${
              e.required ? "Checkbox-box-disabled" : ""
            }`}
          >
            {e.title.toUpperCase()}
          </span> */}
        </div>
      );
    });
  }

  return (
    <div className="CardChooserCheckList">
      <div
        style={{ background: "#ffffff" }}
        className="fs-14 mb-3 sticky-top fw-medium text-black"
      >
        <FormattedMessage id={"MODULES_NAME"} />
      </div>
      <div className="ml-2">{columnsList}</div>
    </div>
  );
};
