import React, { useContext } from "react";
import moment from "moment";
import { useAccount } from "../../../../Hooks/EntityHooks";
import { AccountProfileImg } from "../../../Img/Img";
import ClientHistoryItem from "./ClientHistoryItem";
import CallHistoryItem from "./CallHistoryItem";
import ProjectHistoryItem from "./ProjectHistoryItem";
import TicketHistoryItem from "./TIcketHistoryItem";
import TaskHistoryItem from "./TaskHistoryItem";
import InterventionHistoryItem from "./InterventionHistoryItem";
import ContractHistoryItem from "./ContractHistoryItem";
import BillingHistoryItem from "./BillingHistoryItem";
import NoteHistoryItem from "./NoteHistoryItem";
import classnames from "classnames";
import DealHistoryItem from "./DealHistoryItem";
import EmailHistoryItem from "./EmailHistoryItem";
import SubscriptionHistoryItem from "./SubscriptionHistoryItem";
import EmailConversationHistoryItem from "./EmailConversationHistoryItem";

export const HistoryTitle = ({ Icon, children, className }) => {
  return (
    <div className={classnames("d-flex align-items-center", className)}>
      <div className="ar-history-item-header-icon">
        <Icon />
      </div>
      <div className="lh-normal fs-14">{children}</div>
    </div>
  );
};

export const HistoryHeader = ({ children }) => {
  const { Date } = useContext(HistoryItemContext);

  const d = moment(Date).format("DD/MM/YYYY HH:mm");
  return (
    <div className="d-flex align-items-center justify-content-between">
      {children}
      <div className="fs-12 ml-2 flex-0-0-auto">{d}</div>
    </div>
  );
};

export const HistoryOverview = ({ children }) => {
  return <div className="fs-14 text-black">{children}</div>;
};

export const HistoryContent = ({ children }) => {
  return <div className="ar-history-item-content">{children}</div>;
};

export const HistoryAccountProfile = ({ account }) => {
  //;
  const a = useAccount(account);
  return (
    <div className={"d-inline-block"}>
      <AccountProfileImg
        size="sm"
        account={a}
        circular
        className="d-inline-flex mr-0"
      />
      <b className="d-inline-block">&nbsp; {a.Name}</b>
    </div>
  );
};

const HistoryItemContext = React.createContext();

const HistoryItemProvider = ({ children, value }) => {
  return (
    <HistoryItemContext.Provider value={value}>
      {children}
    </HistoryItemContext.Provider>
  );
};

export const HistoryItemIndexContext = React.createContext();

export const useHistoryItem = () => {
  return useContext(HistoryItemContext);
};

export const HistoryItem = ({ item, index, className }) => {
  const {
    Call,
    Ticket,
    Project,
    Task,
    TimeCharge,
    Client,
    Contract,
    Billing,
    Note,
    Deal,
		Subscription,
		EmailConversation,
    Email
  } = item;

  let comp;
  if (Subscription) comp = <SubscriptionHistoryItem />;
  else if (Call) comp = <CallHistoryItem />;
  else if (Ticket) comp = <TicketHistoryItem />;
  else if (Project) comp = <ProjectHistoryItem />;
  else if (Task) comp = <TaskHistoryItem />;
  else if (TimeCharge) comp = <InterventionHistoryItem />;
  else if (Client) comp = <ClientHistoryItem />;
  else if (Contract) comp = <ContractHistoryItem />;
  else if (Billing) comp = <BillingHistoryItem />;
  else if (Deal) comp = <DealHistoryItem />;
  else if (Note) comp = <NoteHistoryItem />;
	else if (Email) comp = <EmailHistoryItem />;
	else if(EmailConversation) comp = <EmailConversationHistoryItem />;

  return (
    <div
      className={classnames("ar-history-item", className, {
        "ar-history-item-note": Boolean(Note)
      })}
    >
      <HistoryItemIndexContext.Provider value={index}>
        <HistoryItemProvider value={item}>{comp}</HistoryItemProvider>
      </HistoryItemIndexContext.Provider>
    </div>
  );
};
