import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import {
  IAppBarFavoriteContet,
  TreeDataChangerType,
  AppBarFavoriteTreeSetter,
  AppBarFavoriteQueryContext
} from "../../Containers/AppBar/AppBarFavoriteQuery";
import { ServerAwareFavoritesContext } from "../../Containers/AppBar/ServerAwareFavorite";
import {
  TreeData,
  ResetViewContext
} from "../../Containers/AppBar/AppBarHelper";
import { AppFavoriteContext } from "../../Containers/AppBar/AppFavoriteProvider";

interface IServerAwareFavoriteTreeProps {
  favoriteId: string;
  children?: React.ReactNode;
}

export const ServerAwareFavoriteTree: FC<IServerAwareFavoriteTreeProps> = ({
  children,
  favoriteId
}) => {
  const { data, handleChange, loading, error } = useContext(
    AppBarFavoriteQueryContext
  ) as IAppBarFavoriteContet;

  const [newData, setnewData] = useState(data);

  const newHandleChange = useCallback(
    (e: TreeDataChangerType, showToast?: boolean) => {
      let value: TreeData;

      // HandleChangesPropsRef.current = { space: space.Id, showToast };
      if (typeof e === "function") {
        setnewData((oldData) => {
          value = e(oldData as TreeData);
          return value;
        });
      } else {
        value = e;
        setnewData(value);
      }
    },
    []
  );

  const newValue = useMemo(() => {
    return {
      data: newData,
      handleChange: newHandleChange,
      loading,
      error,
      commitChanges: (
        e: (value: TreeData) => TreeData,
        showToast?: boolean
      ) => {
        const ultimateValue = e(newData);
        handleChange(ultimateValue, showToast);
      }
    };
  }, [error, handleChange, loading, newData, newHandleChange]);

  const Favoriteitem = useMemo(
    () => newData.items[favoriteId],
    [favoriteId, newData.items]
  );

  const resetView = useCallback(
    (itemFavoriteId: string) => {
      if (itemFavoriteId === favoriteId) setnewData(data);
    },
    [data, favoriteId]
  );

  return (
    <AppFavoriteContext.Provider value={favoriteId}>
      <AppBarFavoriteQueryContext.Provider
        value={newValue as IAppBarFavoriteContet}
      >
        <ResetViewContext.Provider value={resetView}>
          <AppBarFavoriteTreeSetter.Provider value={newHandleChange}>
            <ServerAwareFavoritesContext.Provider value={Favoriteitem}>
              {children}
            </ServerAwareFavoritesContext.Provider>
          </AppBarFavoriteTreeSetter.Provider>
        </ResetViewContext.Provider>
      </AppBarFavoriteQueryContext.Provider>
    </AppFavoriteContext.Provider>
  );
};
