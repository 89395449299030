import React from "react";
import { useProject } from "../../../../Hooks/EntityHooks";
import {
  HistoryHeader,
  HistoryContent,
  HistoryAccountProfile,
  HistoryOverview,
  useHistoryItem,
} from "./HistoryItem";
import { ConnectedProjectOrigin } from "../../../../Containers/Origin/ProjectOrigin";
import { createItemEditDetailsComponent } from "./HistoryItemDetails/HistoryItemDetails";
import { ProjectStatusProfile } from "../../../Projects/ProjectProfile";
import { FormattedMessage } from "react-intl";

const ProjectHistoryOverview = () => {
  const { Project, IsCreation, Account } = useHistoryItem();
  const item = useProject(Project);
  const { Name } = item;
  if (IsCreation)
    return (
      <HistoryOverview>
        <HistoryAccountProfile account={Account} />
        <span>
          {" "}
          <FormattedMessage id={"CREATED_PROJECT"} />{" "}
        </span>{" "}
        <b>{Name}</b>
      </HistoryOverview>
    );

  return (
    <HistoryOverview>
      <HistoryAccountProfile account={Account} />
      <span>
        {" "}
        <FormattedMessage id={"EDITED_PROJECT"} />{" "}
      </span>{" "}
      <b>{Name}</b>
    </HistoryOverview>
  );
};

const ProjectEditItemDetails = createItemEditDetailsComponent({
  Status: {
    NameId: "status",
    ValueComponent: ProjectStatusProfile,
  },
});

const ProjectHistoryDetails = () => {
  const { IsCreation } = useHistoryItem();
  if (IsCreation) return null;

  return <ProjectEditItemDetails />;
};

const ProjectHistoryItem = () => {
  const { Project } = useHistoryItem();

  return (
    <>
      <HistoryHeader>
        <ConnectedProjectOrigin project={Project}>
          {/* <HistoryTitle Icon={ProjectIcon}>
            <FormattedMessage id={"PROJECT"} /> {Project}
          </HistoryTitle> */}
        </ConnectedProjectOrigin>
      </HistoryHeader>
      <HistoryContent>
        <ProjectHistoryOverview />
        <ProjectHistoryDetails />
      </HistoryContent>
    </>
  );
};
export default ProjectHistoryItem;
