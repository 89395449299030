// import React, { useRef, useEffect, useMemo, useCallback } from "react";
// import classnames from "classnames";
import {
  darkred,
  red,
  //   lightred,
  darkgrey,
  //   grey,
  darkgreen,
  // darkblue,
  green,
  //   getMonth,
  createChartComponent,
  // blue,
  monthNames,
  darkPurple,
  purple
} from "./echartDefaults";
import { DealStatusTypes } from "../../Deals/DealsHelper";
import { largeNumberFormatter } from "../../../Helpers/MiscHelper";
// import { useSpace } from "../../../Contexts/SpaceContext";
// import { useCurrency } from "../../../Hooks/EntityHooks";

// include bar chart
require("echarts/lib/echarts.js");
require("echarts/lib/chart/line");
// include  title component
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");

// const formatter = new Intl.NumberFormat("de-DE", {
//   style: "decimal",
//   minimumFractionDigits: 2
// });

// const HandleCurrencyCall = () => {
//   const { Currency: CurrencyId } = useSpace();
//   const Currency = useCurrency(CurrencyId);

//   return Currency && Currency.Symbol;
// };

export const DealsAreaChart = createChartComponent((Data, intl) => {
  const Open = [];
  const Lost = [];
  const Won = [];
  Data.forEach((e) => {
    Object.keys(e.Data).forEach((k) => {
      if (parseInt(k) === DealStatusTypes.open) Open.push(e.Data[k].Total);
      else if (parseInt(k) === DealStatusTypes.lost) Lost.push(e.Data[k].Total);
      else if (parseInt(k) === DealStatusTypes.won) Won.push(e.Data[k].Total);
    });
  });
  const AllValues = [...Open, ...Lost, ...Won];
  const interval =
    AllValues.length > 0
      ? AllValues.reduce((a, b) => {
          return Math.max(a, b);
        }) / 5
      : 0;

  return {
    tooltip: {
      renderMode: "richText",
      backgroundColor: "#002B63",
      axisPointer: {
        animation: false
      },
      textStyle: {
        color: "#FFFFFF",
        fontWight: "bolder"
      },
      formatter: (params) => {
        let rez = `Data: ${params[0].axisValue}\n`;
        params.forEach((item, i, arr) => {
          var chooser =
            item.seriesName === intl.formatMessage({ id: "OPEN" })
              ? DealStatusTypes.open
              : item.seriesName === intl.formatMessage({ id: "LOSES" })
              ? DealStatusTypes.lost
              : DealStatusTypes.won;
          var xx =
            item.seriesName +
            ` (${Data[item.dataIndex].Data[chooser].Count})` +
            ": €" +
            largeNumberFormatter(item.data);

          rez += xx + (i !== arr.length - 1 ? "\n" : "");
        });

        return rez;
      },
      show: true,
      trigger: "axis"
    },
    grid: {
      left: "4%",
      right: "2%",
      bottom: "1%",
      containLabel: true
    },
    legend: {
      left: "left",
      selectedMode: true,
      borderRadius: 3,
      icon: "roundRect",
      textStyle: {
        color: darkgrey
      },
      data: [
        {
          name: intl.formatMessage({ id: "LOSES" }),
          textStyle: {
            color: darkred,
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 3
          }
        },
        {
          name: intl.formatMessage({ id: "OPEN" }),
          textStyle: {
            color: darkPurple,
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 3
          }
        },
        {
          name: intl.formatMessage({ id: "SALES" }),
          textStyle: {
            color: darkgreen,
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 3
          }
        }
      ]
    },
    xAxis: {
      minInterval: 3,
      maxInterval: 10,
      name: "",
      nameLocation: "start",
      nameTextStyle: {
        padding: [47, 15, 0, 0],
        color: "#7c98b6"
      },
      boundaryGap: true,
      type: "category",
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      data: Data.map((e) => {
        const day = new Date(e.Day);
        // console.log(day);
        return {
          value: `${day.getDate()} ${
            monthNames[day.getMonth()]
          }, ${day.getFullYear()}`,
          textStyle: {
            color: "#7c98b6"
          }
        };
      })
    },
    yAxis: {
      boundaryGap: false,
      interval: interval,
      axisLabel: {
        color: "#7c98b6",
        formatter: (value, index) => {
          return `€ ${largeNumberFormatter(value)}`;
        }
      },
      axisLine: {
        show: false
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#eff4f8"]
        }
      },
      name: "",
      nameLocation: "end",
      nameTextStyle: {
        padding: [0, 40, 0, 0],
        color: "#7c98b6"
      },
      type: "value",
      axisTick: {
        show: false
      }
    },
    series: [
      {
        name: intl.formatMessage({ id: "SALES" }),
        data: Won,
        type: "line",
        symbol: "circle",
        symbolSize: 7,

        itemStyle: {
          color: green
          // color: darkblue
        },
        lineStyle: {
          color: darkgreen
        }
        // areaStyle: {
        //   // color: '#F7F9FB',
        //   color: blue,
        //   opacity: 1
        // }
      },
      {
        name: intl.formatMessage({ id: "OPEN" }),
        data: Open,
        type: "line",
        symbol: "circle",
        symbolSize: 7,
        itemStyle: {
          // color: darkgreen,
          color: purple
        },
        lineStyle: {
          color: darkPurple
        }
        // areaStyle: {
        //   // color: '#E5FAF4',
        //   color: green,
        //   opacity: 1
        // }
      },
      {
        name: intl.formatMessage({ id: "LOSES" }),
        data: Lost,
        type: "line",
        symbol: "circle",
        symbolSize: 7,
        itemStyle: {
          // color: darkred,
          color: red
        },
        lineStyle: {
          color: darkred
        }
        // areaStyle: {
        //   // color: lightred,
        //   color: red,
        //   opacity: 1
        // }
      }
    ]
  };
});
