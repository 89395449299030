import React, { useContext } from "react";

import { useRef, useReducer, useEffect, useMemo, useCallback } from "react";
import ReactDOM from "react-dom";
import api from "../api";
import Axios from "axios";
import { normalize } from "./SchemaHelper";
import store from "../store";
import { useSpace } from "../Contexts/SpaceContext";
import { getEntityFromState } from "./ReduxHelper";
import { useConnectionId } from "../Containers/RealTime/RealTime";
import { UseOneSignalPlayerId } from "./OneSignalHelper";

export class MemoryCache {
  constructor() {
    this.data = {};
  }

  write = (id, value) => {
    this.data[id] = value;
  };

  read = (id) => {
    return this.data[id];
  };
}

// const defaultRequestState = {
//   loading: false,
//   data: null,
//   error: null
// };

const defaultRequestConfig = {
  cancelToken: undefined,
  schema: null,
  cache: true,
  onStart: null
};

class Client {
  constructor() {
    this.cache = new MemoryCache();
    this.postCache = new MemoryCache();
  }

  get = async (endpoint, options) => {
    if (!options) options = defaultRequestConfig;
    if (endpoint.includes("EmailConversation")) {
    }
    const {
      cancelToken,
      schema,
      customCache,
      cache,
      onSuccess,
      onError,
      responseType,
      requestConfig,
      createNormalizationAction,
      headers,
      mutateBeforeNormalization
    } = options;
    let resolvedCache;

    if (customCache !== undefined) resolvedCache = customCache;
    else if (cache) resolvedCache = this.cache;
    const controller = new AbortController();
    let action;
    let state;
    try {
      let data = await api.get(endpoint, {
        responseType,
        cancelToken,
        signal: controller.signal,
        headers: {
          ...headers,
          OriginUrl: window.location.href
        },

        ...requestConfig
      });

      if (mutateBeforeNormalization) data = mutateBeforeNormalization(data);

      if (schema) {
        const normalizedData = normalize(data, schema);
        action = {
          type: `UPDATE_ENTITIES - GET - ${endpoint}`,
          response: { entities: normalizedData.entities }
        };

        if (createNormalizationAction)
          action = createNormalizationAction(action);

        if (resolvedCache) resolvedCache.write(endpoint, normalizedData.result);
        // if (onSuccess) onSuccess(normalizedData.result);
        state = { loading: false, data: normalizedData.result, error: null };
      } else {
        if (resolvedCache) resolvedCache.write(endpoint, data);
        // if (onSuccess) onSuccess(data);
        state = { loading: false, data, error: null };
      }
    } catch (e) {
      if (Axios.isCancel(e)) {
        state = { loading: false, data: null, error: null, canceled: true };
        //   return { loading: false, data: null, error: e };
      } else {
        state = { loading: false, data: null, error: e };
      }
    }
    const { error, canceled } = state;
    if (!canceled)
      ReactDOM.unstable_batchedUpdates(() => {
        action && store.dispatch(action);

        !error && onSuccess && onSuccess(state);
        error && onError && onError(state);
      });
    return state;
  };

  dumbGet = async (endpoint, options) => {
    if (!options) options = defaultRequestConfig;
    if (endpoint.includes("EmailConversation")) {
    }
    const {
      cancelToken,
      schema,
      customCache,
      cache,
      onSuccess,
      onError,
      responseType,
      requestConfig,
      createNormalizationAction,
      headers,
      mutateBeforeNormalization
    } = options;
    let resolvedCache;

    if (customCache !== undefined) resolvedCache = customCache;
    else if (cache) resolvedCache = this.cache;
    const controller = new AbortController();
    let action;
    let state;
    try {
      let data = await api.get(endpoint, {
        responseType,
        cancelToken,
        signal: controller.signal,
        headers: {
          ...headers,
          OriginUrl: window.location.href
        },

        ...requestConfig
      });

      if (mutateBeforeNormalization) data = mutateBeforeNormalization(data);

      if (schema) {
        const normalizedData = normalize(data, schema);
        action = {
          type: `UPDATE_ENTITIES - GET - ${endpoint}`,
          response: { entities: normalizedData.entities }
        };

        if (createNormalizationAction)
          // eslint-disable-next-line no-unused-vars
          action = createNormalizationAction(action);

        if (resolvedCache) resolvedCache.write(endpoint, normalizedData.result);
        // if (onSuccess) onSuccess(normalizedData.result);
        state = { loading: false, data: normalizedData.result, error: null };
      } else {
        if (resolvedCache) resolvedCache.write(endpoint, data);
        // if (onSuccess) onSuccess(data);
        state = { loading: false, data, error: null };
      }
    } catch (e) {
      if (Axios.isCancel(e)) {
        state = { loading: false, data: null, error: null, canceled: true };
        //   return { loading: false, data: null, error: e };
      } else {
        state = { loading: false, data: null, error: e };
      }
    }
    const { error, canceled } = state;
    if (!canceled) !error && onSuccess && onSuccess(state);
    error && onError && onError(state);

    return state;
  };

  post = async (endpoint, body, newOptions = {}) => {
    const options = { ...defaultRequestConfig, ...newOptions };

    const { cancelToken, schema, onSuccess, onError, cache, headers } = options;

    let resolvedCache;

    if (cache) resolvedCache = this.postCache;

    let state;
    let action;

    if (
      headers &&
      Object.hasOwnProperty.call(headers, "ConnectionID") &&
      !headers.ConnectionID
    ) {
      delete headers.ConnectionID;
    }

    try {
      const data = await api.post(endpoint, body, {
        cancelToken,
        headers: { "Content-Type": "application/json", ...headers }
      });

      if (schema && data !== undefined && data !== null) {
        const normalizedData = normalize(data, schema);
        // if (cache) this.cache.write(endpoint, normalizedData.result);
        action = {
          type: "UPDATE_ENTITIES",
          response: { entities: normalizedData.entities }
        };

        if (resolvedCache) resolvedCache.write(endpoint, normalizedData.result);

        state = {
          loading: false,
          data: normalizedData.result,
          error: null
        };
      } else {
        if (resolvedCache) resolvedCache.write(endpoint, data);
        state = { loading: false, data, error: null };
      }
    } catch (e) {
      if (Axios.isCancel(e)) {
        state = { loading: false, data: null, error: null, canceled: true };
      } else {
        state = { loading: false, data: null, error: e };
      }
    }
    const { error, canceled } = state;
    if (!canceled)
      ReactDOM.unstable_batchedUpdates(() => {
        action && store.dispatch(action);

        !error && onSuccess && onSuccess(state);
        error && onError && onError(state);
      });
    return state;
  };

  delete = async (endpoint, newOptions = defaultRequestConfig) => {
    const options = { ...defaultRequestConfig, ...newOptions };

    const { cancelToken, schema, onSuccess, onError, headers, body } = options;

    let state;
    let action;

    try {
      const data = await api.delete(endpoint, {
        cancelToken,
        headers: { "Content-Type": "application/json", ...headers },
        data: body
      });

      if (schema) {
        const normalizedData = normalize(data, schema);
        // if (cache) this.cache.write(endpoint, normalizedData.result);
        action = {
          type: "UPDATE_ENTITIES",
          response: { entities: normalizedData.entities }
        };

        state = {
          loading: false,
          data: normalizedData.result,
          error: null
        };
      } else {
        state = { loading: false, data, error: null };
      }
    } catch (e) {
      if (Axios.isCancel(e)) {
        state = { loading: false, data: null, error: null, canceled: true };
      } else {
        state = { loading: false, data: null, error: e };
      }
    }
    const { error, canceled } = state;
    if (!canceled)
      ReactDOM.unstable_batchedUpdates(() => {
        action && store.dispatch(action);

        !error && onSuccess && onSuccess(state);
        error && onError && onError(state);
      });
    return state;
  };
}

export const cacheType = {
  enabled: "enabled",
  component: "component",
  disabled: "disabled"
};

const defaultQueryOptions = {
  autoFetch: true,
  cache: cacheType.enabled
};

export const client = new Client();

export const ClientMemoryContext = React.createContext();

export const ClientMemoryProvider = ({ children }) => {
  const clientMemory = useMemo(() => new MemoryCache(), []);

  return (
    <ClientMemoryContext.Provider value={clientMemory}>
      {children}
    </ClientMemoryContext.Provider>
  );
};

const useQueryOptions = (options) => {
  const conId = useConnectionId();
  return useMemo(() => {
    if (conId)
      return {
        ...defaultQueryOptions,
        ...options,
        headers: {
          SchemaRequest: true,
          ConnectionID: conId,
          ...options?.headers
        }
      };
    else
      return {
        ...defaultQueryOptions,
        ...options,
        headers: {
          SchemaRequest: true,
          ...options?.headers
        }
      };
  }, [conId, options]);
};

const useQueryBase = (options) => {
  const resolvedOptions = useQueryOptions(options);
  const { cache: currentCacheType } = resolvedOptions;
  const configRef = useRef();
  let config = configRef.current;

  const memoryCtxt = useContext(ClientMemoryContext);

  if (!config) {
    let cache;
    switch (currentCacheType) {
      case cacheType.enabled:
        cache = memoryCtxt || client.cache;
        break;

      case cacheType.component:
        cache = new MemoryCache();
        break;

      default:
        cache = null;
        break;
    }

    const obj = {
      hasUnmounted: false,
      cache
    };

    configRef.current = obj;
    config = obj;
  }

  //clear query and stop outgoing requests on unmount
  useEffect(() => {
    return () => {
      config.hasUnmounted = true;
      if (config.source) config.source.cancel();
    };
  }, [config]);

  return [config, resolvedOptions];
};

export const useQuery = (endpoint, schema, options) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const previousEndpointRef = useRef();
  const playerIdRef = UseOneSignalPlayerId();
  const [
    config,
    {
      autoFetch,
      onSuccess,
      onError,
      responseType,
      requestConfig,
      createNormalizationAction,
      mutateBeforeNormalization,
      headers
    }
  ] = useQueryBase(options);
  const stateRef = useRef();

  const getData = useCallback(
    (isRefetching = false) => {
      const cacheValue =
        !isRefetching && config.cache ? config.cache.read(endpoint) : undefined;
      if (config.source) config.source.cancel();

      if (!endpoint) {
        if (previousEndpointRef.current !== endpoint) {
          stateRef.current.loading = false;
          stateRef.current.error = null;
          stateRef.current.data = null;
          // forceUpdate();
        }
        return;
      }

      if (cacheValue !== undefined) {
        const newState = { loading: false, data: cacheValue, error: null };
        stateRef.current = newState;

        if (onSuccess) onSuccess(newState);
        return;
      }

      config.source = Axios.CancelToken.source();

      stateRef.current.loading = true;
      stateRef.current.error = null;
      stateRef.current.data = null;

      if (isRefetching) {
        forceUpdate();
      }
      client.get(endpoint, {
        responseType: responseType,
        cancelToken: config.source.token,
        schema,

        customCache: config.cache,
        onSuccess: (state) => {
          stateRef.current = state;
          forceUpdate();
          onSuccess && onSuccess(state);
        },
        onError: (state) => {
          stateRef.current = state;
          forceUpdate();
          onError && onError(state);
        },
        requestConfig,
        createNormalizationAction,
        headers: {
          ...headers,
          // PlayerId: playerIdRef.current
          PlayerId: playerIdRef
        },
        mutateBeforeNormalization
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      config.cache,
      config.source,
      endpoint,
      onError,
      onSuccess,
      requestConfig,
      responseType,
      schema
    ]
  );

  //startup
  if (!stateRef.current) {
    stateRef.current = {
      data: null,
      loading: autoFetch && endpoint ? true : false,
      error: null
    };
  }

  if (endpoint !== previousEndpointRef.current) {
    if (autoFetch) {
      getData();
      previousEndpointRef.current = endpoint;
    } else {
      previousEndpointRef.current = endpoint;
      let data;
      if (config.cache) {
        const cacheData = config.cache.read(endpoint);
        if (cacheData !== undefined) data = cacheData;
      }
      stateRef.current = {
        data,
        loading: false,
        error: null
      };
    }
  }

  const refetch = useCallback(() => getData(true), [getData]);

  return { ...stateRef.current, refetch };
};

export const usePostQuery = (endpoint, body, schema, options) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const previousEndpointRef = useRef();
  const previousBodyRef = useRef();

  const [config, { autoFetch, onSuccess, onError }] = useQueryBase(options);

  const getData = (isRefetching = false) => {
    if (config.source) config.source.cancel();

    previousEndpointRef.current = endpoint;
    previousBodyRef.current = body;

    if (config.source) config.source.cancel();

    if (!endpoint) return;

    config.source = Axios.CancelToken.source();
    if (isRefetching) {
      forceUpdate();
    }
    config.state.loading = true;
    config.state.error = null;
    config.state.data = null;

    client.post(endpoint, body, {
      cancelToken: config.source.token,
      schema,
      onSuccess: (state) => {
        config.state = state;
        onSuccess && onSuccess(state);
        forceUpdate();
      },
      onError: (state) => {
        config.state = state;
        onError && onError(state);
        forceUpdate();
      }
    });
  };

  //startup
  if (!config.state) {
    config.state = {
      data: null,
      loading: autoFetch && endpoint ? true : false,
      error: null
    };
  }

  if (
    autoFetch &&
    (endpoint !== previousEndpointRef.current ||
      body !== previousBodyRef.current)
  ) {
    getData();
  }

  return { ...config.state, refetch: () => getData(true) };
};

export const useSpaceQuery = (endpoint, ...rest) => {
  const space = useSpace();
  const resolvedEndpoint = !endpoint
    ? undefined
    : `spaces/${space.Id}/${endpoint}`;

  return useQuery(resolvedEndpoint, ...rest);
};

export const useSpacePostQuery = (endpoint, ...rest) => {
  const space = useSpace();
  const resolvedEndpoint = !endpoint
    ? undefined
    : `spaces/${space.Id}/${endpoint}`;

  return usePostQuery(resolvedEndpoint, ...rest);
};

export const useSchemaQuery = (schema, id, ...rest) => {
  const space = useSpace();
  const resolvedEndpoint = useMemo(() => {
    return schema.getEndpoint(space.Id) + `/${id}`;
  }, [schema, space.Id, id]);

  return useQuery(resolvedEndpoint, schema, ...rest);
};

export const usePost = (
  endpoint,
  schema,
  {
    onSuccess,
    onError,
    handleEntityUpdate,
    updates,
    normalizedSchema,
    headers
  } = {}
) => {
  const stateRef = useRef({
    data: null,
    loading: false,
    error: null
  });
  const sourceRef = useRef();
  const hasUnmountedRef = useRef(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const conId = useConnectionId();
  const resolvedHeaders = useMemo(() => {
    let head = { ConnectionID: conId, ...headers };
    if (
      Object.hasOwnProperty.call(head, "ConnectionID") &&
      !head.ConnectionID
    ) {
      delete head.ConnectionID;
    }
    return head;
  }, [conId, headers]);
  const postFunc = useCallback(
    (body) => {
      sourceRef.current = Axios.CancelToken.source();
      stateRef.current.loading = true;
      forceUpdate();
      client.post(endpoint, body, {
        cancelToken: sourceRef.current.token,
        schema: normalizedSchema || schema,
        onSuccess: (state) => {
          stateRef.current = state;
          forceUpdate();
          onSuccess && onSuccess({ ...state });
        },
        onError: (state) => {
          stateRef.current = state;
          forceUpdate();
          onError && onError({ ...state });
        },
        handleEntityUpdate,
        updates,
        headers: resolvedHeaders
      });
    },
    [
      endpoint,
      handleEntityUpdate,
      normalizedSchema,
      onError,
      onSuccess,
      resolvedHeaders,
      schema,
      updates
    ]
  );

  //clear query and stop outgoing requests on unmount
  useEffect(() => {
    return () => {
      hasUnmountedRef.current = true;
      if (sourceRef.current) sourceRef.current.cancel();
    };
  }, []);

  return [postFunc, stateRef.current];
};

export const useShortPost = (endpoint, schema) => {
  const updateRefs = useRef({});

  const handleSuccess = useCallback((...args) => {
    updateRefs.current.onSuccess && updateRefs.current.onSuccess(...args);
  }, []);

  const handleError = useCallback((...args) => {
    updateRefs.current.onError && updateRefs.current.onError(...args);
  }, []);

  const [post, state] = usePost(endpoint, schema, {
    onSuccess: handleSuccess,
    onError: handleError
  });

  const resolvedPost = useCallback(
    (body, onSuccess, onError) => {
      updateRefs.current.onSuccess = onSuccess;
      updateRefs.current.onError = onError;
      post(body);
    },
    [post]
  );

  return [resolvedPost, state];
};

export const useShortSpacePost = (endpoint, schema) => {
  const updateRefs = useRef({});

  const handleSuccess = useCallback((...args) => {
    updateRefs.current.onSuccess && updateRefs.current.onSuccess(...args);
  }, []);

  const handleError = useCallback((...args) => {
    updateRefs.current.onError && updateRefs.current.onError(...args);
  }, []);

  const [post, state] = useSpacePost(endpoint, schema, {
    onSuccess: handleSuccess,
    onError: handleError
  });

  const resolvedPost = useCallback(
    (body, onSuccess, onError) => {
      updateRefs.current.onSuccess = onSuccess;
      updateRefs.current.onError = onError;
      post(body);
    },
    [post]
  );

  return [resolvedPost, state];
};

export const useMultiplePost = () => {
  const sourcesRef = useRef([]);
  const conId = useConnectionId();

  const post = useCallback(
    async (endpoint, body, options) => {
      const source = Axios.CancelToken.source();
      const { headers } = options || {};
      const resolvedHeaders = headers || {};
      sourcesRef.current.push(source);

      const r = await client.post(endpoint, body, {
        ...options,
        headers: { ...resolvedHeaders, ConnectionID: conId },

        cancelToken: source.token
      });

      if (!r.canceled) {
        const index = sourcesRef.current.indexOf(source);
        sourcesRef.current.splice(index, 1);
      }

      return r;
    },
    [conId]
  );

  useEffect(() => {
    const sources = sourcesRef.current;
    return () => {
      for (const source of sources) {
        source.cancel();
      }
    };
  }, []);

  return post;
};

export const useSpaceMultiplePost = () => {
  const post = useMultiplePost();

  const { Id } = useSpace();
  const rPost = useCallback(
    (endpoint, ...args) => {
      return post(`spaces/${Id}/${endpoint}`, ...args);
    },
    [Id, post]
  );

  return rPost;
};

export const useSpacePost = (endpoint, ...rest) => {
  const space = useSpace();
  const resolvedEndpoint = !endpoint
    ? undefined
    : `spaces/${space.Id}/${endpoint}`;

  return usePost(resolvedEndpoint, ...rest);
};

export const useSchemaPost = (schema, id, ...rest) => {
  const space = useSpace();

  const resolvedEndpoint = useMemo(() => {
    let url = schema.getEndpoint(space.Id);
    if (id) url += `/${id}`;

    return url;
  }, [schema, space.Id, id]);

  return usePost(resolvedEndpoint, schema, ...rest);
};

export const useDelete = (
  endpoint,
  schema,
  {
    onSuccess,
    onError,
    handleEntityUpdate,
    updates,
    normalizedSchema,
    headers
  } = {}
) => {
  const stateRef = useRef({
    data: null,
    loading: false,
    error: null
  });
  const sourceRef = useRef();
  const hasUnmountedRef = useRef(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const conId = useConnectionId();
  const resolvedHeaders = useMemo(() => {
    let head = { ConnectionID: conId, ...headers };
    if (
      Object.hasOwnProperty.call(head, "ConnectionID") &&
      !head.ConnectionID
    ) {
      delete head.ConnectionID;
    }
    return head;
  }, [conId, headers]);
  const postFunc = useCallback(
    (body) => {
      sourceRef.current = Axios.CancelToken.source();
      stateRef.current.loading = true;
      forceUpdate();
      client.delete(endpoint, {
        body,
        cancelToken: sourceRef.current.token,
        schema: normalizedSchema || schema,
        onSuccess: (state) => {
          stateRef.current = state;
          forceUpdate();
          onSuccess && onSuccess({ ...state });
        },
        onError: (state) => {
          stateRef.current = state;
          forceUpdate();
          onError && onError({ ...state });
        },
        handleEntityUpdate,
        updates,
        headers: resolvedHeaders
      });
    },
    [
      endpoint,
      handleEntityUpdate,
      normalizedSchema,
      onError,
      onSuccess,
      resolvedHeaders,
      schema,
      updates
    ]
  );

  //clear query and stop outgoing requests on unmount
  useEffect(() => {
    return () => {
      hasUnmountedRef.current = true;
      if (sourceRef.current) sourceRef.current.cancel();
    };
  }, []);

  return [postFunc, stateRef.current];
};

export const useSpaceDelete = (endpoint, ...rest) => {
  const space = useSpace();
  const resolvedEndpoint = !endpoint
    ? undefined
    : `spaces/${space.Id}/${endpoint}`;

  return useDelete(resolvedEndpoint, ...rest);
};

export const updateNormalizedSchemaCache = (entities) => {
  const action = {
    type: "UPDATE_ENTITIES",
    response: { entities: entities }
  };

  store.dispatch(action);
};

export const getEntity = (schema, id) => {
  if (!id) return null;
  const state = store.getState();

  const entity = getEntityFromState(state, schema, id);

  return entity;
};

// useAdvancedQuery(endpoint, schema)
// export const useAdvancedQuery = (endpoint, schema) => {
//   const x = useQuery(endpoint, null, {
//     onSuccess: ({data}) => {

//     }
//   })
// }
