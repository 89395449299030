import React, { useContext, useMemo, useRef } from "react";
import classnames from "classnames";
import "./BoardList.css";
import FormattedTimePreview from "../FormattedTimePreview/FormattedTimePreview";
import RelativeTime from "../RelativeTime/RelativeTime";
import { useSpace } from "../../Contexts/SpaceContext";
import { useCurrency } from "../../Hooks/EntityHooks";
import { getBoardStyle } from "../../Helpers/GridHelper";
import { largeNumberFormatter } from "../../Helpers/MiscHelper";
import EntityFollowIcon from "../EntityFollow/EntityFollowIcon";
import EntityIconOverview from "../EntityIconOverview/EntityIconOverview";
import moment from "moment";
import ContextMenu, { GridActionContext } from "../ContextMenu/ContextMenu";
import {
  InnerColorFilterBars,
  TriggeredColoredFilterProvider,
  useTriggeredColorFilters
} from "../FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faCompressAlt,
  faExpandAlt
} from "@fortawesome/pro-light-svg-icons";

export const BoardListContainer = ({ isDownsized, children }) => {
  return (
    <div
      style={{ width: isDownsized ? 40 : "" }}
      className={"ar-board-list-container"}
    >
      {children}
    </div>
  );
};

export const BoardList = React.forwardRef(
  ({ children, className, isDragging, isDraggingOver, ...rest }, ref) => {
    return (
      <div
        className={classnames(
          "ar-board-list",
          {
            dragging: isDraggingOver
          },
          className
        )}
        {...rest}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export const BoardHeaderTypes = {
  orange: "orange",
  grey: "grey",
  inactive: "inactive",
  danger: "danger",
  blue: "blue",
  success: "success",
  purple: "purple",
  yellow: "yellow"
};

const BoardHeaderSum = ({ loading, value, isDownsized }) => {
  const space = useSpace();

  const { Symbol } = useCurrency(space.Currency);

  if (loading)
    return (
      <span
        className={classnames("text-nowrap", {
          "mt-2": isDownsized,
          "ml-2": !isDownsized
        })}
      >
        {Symbol} {`―`}
      </span>
    );

  return (
    <span
      className={classnames("text-nowrap", {
        "mt-2": isDownsized,
        "ml-2": !isDownsized
      })}
    >
      {Symbol} {largeNumberFormatter(value || 0)}
      {/* <FormattedDecimal value={value} /> */}
    </span>
  );
};

export const BoardHeaderContext = React.createContext();

const BoardHeaderC = ({ isDownsized }) => {
  const context = useContext(BoardHeaderContext);

  if (!context) return null;

  const { loadingSum, sumValue, countValue, shownAmount } = context;

  return (
    <div
      className={classnames(
        "d-flex justify-content-end flex-1",
        isDownsized && "mb-2"
      )}
    >
      <span className={isDownsized && "mb-2"}>
        {typeof countValue === "number" && `(${shownAmount}/${countValue})  `}
      </span>

      {loadingSum !== undefined && (
        <BoardHeaderSum
          isDownsized={isDownsized}
          loading={loadingSum}
          value={sumValue}
        />
      )}
    </div>
  );
};

export const BoardHeader = ({
  type,
  text,
  toggleDownsize,
  isDownsized,
  refetch,
  pipelineStatus
}) => {
  if (isDownsized) {
    return (
      <>
        <div className={classnames("ar-board-list-header", type)}>
          <div className="d-flex justify-content-between fs-14 fw-medium">
            <Button onClick={toggleDownsize} size="xs" vType="minimal-primary">
              <FontAwesomeIcon className="fs-14" icon={faExpandAlt} />
            </Button>
            {/* {Boolean(loadingSum || sumValue !== undefined) && (
						<BoardHeaderSum loading={loadingSum} value={sumValue} />
					)} */}
          </div>
        </div>
        <div
          style={{ writingMode: "vertical-lr", paddingTop: 20 }}
          className="text-truncate of-hidden m-auto d-flex align-items-baseline fw-medium"
        >
          <span
            style={{ padding: "0 3px" }}
            className={classnames(
              { "mx-2": !isDownsized, "my-2": isDownsized },
              "fs-14 ar-board-list-header-title text-uppercase text-truncate"
            )}
          >
            {text}
          </span>
          <BoardHeaderC isDownsized={isDownsized} />
          <Button
            onClick={() => {
              refetch(pipelineStatus);
            }}
            size="xs"
            className=""
            vType="minimal-primary"
          >
            <FontAwesomeIcon icon={faArrowsRotate} />
          </Button>
          {/* <BoardHeaderC isDownsized={isDownsized} /> */}
        </div>
        <hr className="mb-0" />
      </>
    );
  } else
    return (
      <div className={classnames("ar-board-list-header", type)}>
        <div className="d-flex justify-content-between fs-14 fw-medium">
          <div className="flex-1 of-hidden d-flex align-items-center mr-2">
            <div className="text-truncate mr-2">
              <span
                className={classnames(
                  { "mx-2": !isDownsized, "my-2": isDownsized },
                  "fs-14 ar-board-list-header-title text-uppercase text-truncate"
                )}
              >
                {text}
              </span>
            </div>
            <BoardHeaderC />
          </div>
          <Button
            onClick={() => {
              refetch(pipelineStatus);
            }}
            size="xs"
            className="mr-2"
            vType="minimal-primary"
          >
            <FontAwesomeIcon icon={faArrowsRotate} />
          </Button>
          <Button onClick={toggleDownsize} size="xs" vType="minimal-primary">
            <FontAwesomeIcon icon={faCompressAlt} />
          </Button>
          {/* {Boolean(loadingSum || sumValue !== undefined) && (
          <BoardHeaderSum loading={loadingSum} value={sumValue} />
        )} */}
        </div>
      </div>
    );
};

export const BoardTime = ({ date, duration, className }) => {
  return (
    <div
      className={classnames(
        "fs-12 fw-medium d-flex justify-content-end",
        className
      )}
    >
      <div className="ar-board-time rounded p-1 mr-1">
        <RelativeTime date={date} />
      </div>
      {duration && (
        <div className="ar-board-time rounded p-1">
          <FormattedTimePreview value={duration} asHourMinute />
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
const monthNames = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez"
];

export const BoardTimePeriod = ({
  startDate,
  endDate,
  className,
  simple,
  children
}) => {
  const { start, end } = useMemo(() => {
    return { start: moment(startDate), end: moment(endDate) };
  }, [endDate, startDate]);

  const style = useMemo(() => {
    return getBoardStyle(start, end);
  }, [end, start]);

  if (simple) {
    return (
      <div
        className={classnames(
          "d-flex justify-content-between fs-12 fw-medium",
          className
        )}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div style={style} className="ar-board-time rounded p-1 mr-1">
            {`${start.date()} ${start.format(
              "MMM"
            )} - ${end.date()} ${end.format("MMM")}`}
          </div>
        </div>
        {children}
      </div>
    );
  }

  return (
    <div
      className={classnames(
        "d-flex justify-content-between fs-12 fw-medium",
        className
      )}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div style={style} className="ar-board-time rounded p-1 mr-1">
          {`${start.date()} ${start.format(
            "MMM"
          )}, ${start.year()} - ${end.date()} ${end.format(
            "MMM"
          )}, ${end.year()}`}
        </div>
      </div>
      {children}
    </div>
  );
};

export const BoardContainer = ({
  children,
  className,
  isRead,
  isFollowing,
  value,
  ...rest
}) => {
  const ContextChildren = useContext(GridActionContext);
  const headerRef = useRef();
  const { HasUpdates } = value;
  const conds = useTriggeredColorFilters(value);

  let paddingText = "";
  if (conds?.length > 0) {
    paddingText = "0px 8px";
  }

  return (
    <TriggeredColoredFilterProvider conds={conds}>
      <BoardItemTools value={value} {...rest} />
      <div
        ref={headerRef}
        style={{
          padding: paddingText,
          boxShadow: HasUpdates && "0 0 0 2px orange"
        }}
        className={classnames(
          "text-black ar-board-item py-2",
          {
            unread: isFollowing && isRead === false,
            "pl-3 pr-2": Boolean(conds?.length > 0),
            "px-2": !Boolean(conds?.length > 0)
          },
          className
        )}
      >
        <BoardItemHeader value={value} {...rest} />

        {children}
      </div>
      {ContextChildren && (
        <ContextMenu value={value} targetRef={headerRef}>
          <ContextChildren entity={value} />
        </ContextMenu>
      )}
    </TriggeredColoredFilterProvider>
  );
};

export const BoardSection = ({
  text,
  children,
  className,
  contentClassName
}) => {
  if (!children) return null;

  return (
    <div className={className}>
      {text && (
        <div className="fs-12 text-capitalize px-2 fw-medium text-black mb-1 py-1 bg-primary-light">
          {text}
        </div>
      )}
      <div className={classnames(contentClassName, "px-2 text-truncate")}>
        {children}
      </div>
    </div>
  );
};

export const BoardItemTools = ({ ButtonsComponent, value, id, schema }) => {
  const { IsFollowing } = value;
  return (
    <div className="ar-board-item-header-tools px-2">
      <EntityFollowIcon
        className="mr-3"
        id={id}
        isFollowing={IsFollowing}
        canEdit={value.CanEdit}
        schema={schema}
      />
      <ButtonsComponent minimal noColors entity={value} />
    </div>
  );
};

export const BoardItemHeader = ({
  id,
  schema,
  value,
  name,
  LinkComponent,
  ButtonsComponent,
  Associations,
  startDate,
  betterDate
}) => {
  const formmatedDate = moment(startDate).format("DD MMM YYYY");

  return (
    <>
      <InnerColorFilterBars kanban entity={value} />
      <div>
        {/* <div className="d-flex justify-content-between mb-1">
        <div className="d-flex align-items-center flex-1">
          <EntityFollowIcon id={id} isFollowing={IsFollowing} schema={schema} />
          <RoundedBadge className="fs-12 fw-regular ml-2 mr-2">
            Nº {id}
          </RoundedBadge>
          <div className="flex-1">
            {Associations}
          </div>
        </div>
        <div className="fs-12 align-items-center justify-content-center ">
          <ButtonsComponent minimal entity={value} />
        </div>
      </div> */}

        <div>
          <LinkComponent className="h-100" id={id}>
            <div
              className="text-left fs-13"
              style={{ wordBreak: "break-word" }}
            >
              {name}
            </div>
          </LinkComponent>
          <div className="mt-2 d-flex justify-content-between">
            <div className="fs-12 d-inline-flex align-items-center">
              <div className="px-2 py-1 border rounded">Nº {id}</div>
              {betterDate ? (
                <div className="ml-2">{betterDate}</div>
              ) : (
                <div className="ml-2">{formmatedDate}</div>
              )}
            </div>
            <div className="d-flex">{Associations}</div>
          </div>
          <EntityIconOverview
            noChildren
            className="fs-12 mt-2"
            schema={schema}
            value={value}
            noHistory
          />
        </div>
      </div>
    </>
  );
};
