import React, { useMemo } from "react";
import {
  useCardRequestProvider,
  useGridRequest,
  useServerAwareState
} from "../../../../Components/CGrid/ServerGrid";
import { useSpaceQuery, getEntity } from "../../../../Helpers/IOClient";
import {
  spacePipelineStatusSchema,
  spaceContractSchema
} from "../../../../config/schema";
import { useSpace } from "../../../../Contexts/SpaceContext";
import { FormattedMessage } from "react-intl";
import { useCurrency, useContract } from "../../../../Hooks/EntityHooks";
import {
  BoardSection,
  BoardTimePeriod
} from "../../../../Components/BoardList/BoardList";
import { ConnectedAccountsTeamsDepartmentsProfileStack } from "../../../../Components/Accounts/AccountProfileStack";
import { ConnectedClientOrigin } from "../../../Origin/ClientOrigin";
import { ConnectedClientProfile } from "../../../../Components/Clients/ClientProfile";
import { ConnectedContactPopup } from "../../../Origin/ContactPopup";
import { largeNumberFormatter } from "../../../../Helpers/MiscHelper";
import { HoverEntityChildrenProgress } from "../../../EntityChildrenProgress/EntityChildrenProgress";
import { ConnectedAccountPopup } from "../../../Origin/AcountPopup";
import { ConnectedOrganizationProfile } from "../../../../Components/Organizations/OrganizationProfile";
import { ContractEntityGridButtons } from "../../ContractsHelper";
import { ContractStatusProfile } from "../../../../Components/Contract/ContractProfile";
import { ContractSidebarLink } from "../../../../Components/EntitySidebarLinks/EntitySidebarLinks";
import LoaderSpinner from "../../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { CardContainer } from "../../../../Components/CardList/CardHelper";
import { useResolvedCards } from "../../../../Components/KanbanChooser/CardGridChooserHelper";
import { ContractIcon } from "../../../../Components/Common/EntityIcons";
import EmptyListError from "../../../../Components/EmptyListContainer/EmptyListError";
import EmptyListContainer from "../../../../Components/EmptyListContainer/EmptyListContainer";
import { ContractFormButton } from "../../../Form/FormButtons";
import CardList from "../../../../Components/CardList/CardList";
import { StatusCompContext } from "../../../AdvancedMultiInputs/PipelineDropdowns";

export const useContractPipelinesStatusItems = () => {
  const { params } = useServerAwareState();

  const pipelineId = params.pipeline;
  const { data } = useSpaceQuery(
    pipelineId ? `query/PipelineStatus?pipelineId=${pipelineId}` : null,
    [spacePipelineStatusSchema]
  );

  return useMemo(() => {
    if (!data) return undefined;

    return data.map((e) => {
      const resolved = getEntity(spacePipelineStatusSchema, e);
      return {
        key: resolved.Id,
        title: resolved.Name,
        Id: resolved.Id
      };
    });
  }, [data]);
};

export const useContractItemBoardComponents = (deal) => {
  const space = useSpace();
  const { Symbol } = useCurrency(space.Currency);

  const {
    Id,
    Assigned,
    EndDate,
    BeginDate,
    EstimatedCost,
    NoTagDescription,
    IndirectClient,
    Manager,
    Creator,
    Contact,
    Client,
    Company,
    SalesOpportunity
  } = deal;

  return [
    {
      title: "ASSOCIATIONS",
      comp: (
        <BoardSection
          key="Associations"
          text={<FormattedMessage id={"ASSOCIATIONS"} />}
          className="mb-1 mt-2"
        >
          <div className="d-flex justify-content-between mb-2">
            <div>
              <div>
                <ConnectedAccountsTeamsDepartmentsProfileStack
                  organizations={[Company]}
                  accounts={[Assigned, Manager]}
                />
              </div>
            </div>
            <div>
              <div className="ar-board-pics d-flex">
                <ConnectedClientOrigin client={Client || IndirectClient}>
                  <ConnectedClientProfile
                    client={Client || IndirectClient}
                    justImage
                  />
                </ConnectedClientOrigin>
                <ConnectedContactPopup justImage contact={Contact} />
              </div>
            </div>
          </div>
        </BoardSection>
      )
    },
    {
      title: "VALUES",
      comp: (
        <BoardSection
          key="Valores"
          text={<FormattedMessage id={"VALUES"} />}
          className="mb-1 mt-2"
        >
          {(!EstimatedCost || (EstimatedCost && EstimatedCost.Cost === 0.0)) &&
          !SalesOpportunity ? null : (
            <div className="d-flex justify-content-between mb-2">
              {!EstimatedCost ||
              (EstimatedCost && EstimatedCost.Cost === 0.0) ? null : (
                <div className="fs-12 fw-medium moneyContainer rounded p-1">
                  {Symbol} {largeNumberFormatter(EstimatedCost.Cost)}
                  {/* <FormattedDecimal value={EstimatedCost.Cost} /> */}
                </div>
              )}
              {SalesOpportunity ? (
                <div className="fs-12 p-1">{`OPV: ${SalesOpportunity}`}</div>
              ) : null}
            </div>
          )}
        </BoardSection>
      )
    },
    {
      title: "DEADLINE",
      comp: (
        <BoardSection
          key="Prazos"
          text={<FormattedMessage id={"DEADLINE"} />}
          className="mb-1 mt-2"
          contentClassName="fs-14 text-black"
        >
          <BoardTimePeriod startDate={BeginDate} endDate={EndDate} />
        </BoardSection>
      )
    },
    {
      title: "ORIGINATED",
      comp: (
        <BoardSection
          key="Originou"
          text={<FormattedMessage id={"ORIGINATED"} />}
          className="mb-1 mt-2"
          contentClassName="fs-14 text-black"
        >
          <HoverEntityChildrenProgress schema={spaceContractSchema} id={Id} />
        </BoardSection>
      )
    },
    {
      title: "DESCRIPTION",
      comp: (
        <BoardSection
          key="Descrição"
          text={<FormattedMessage id={"DESCRIPTION"} />}
          className="mb-1 mt-2"
          contentClassName="fs-12 text-black"
        >
          {NoTagDescription}
        </BoardSection>
      )
    },
    {
      title: "CREATED_BY",
      comp: (
        <BoardSection
          key="Criado por"
          text={<FormattedMessage id={"CREATED_BY"} />}
          className="mb-1 mt-2"
          contentClassName="fs-14 text-black"
        >
          <div className="ar-board-pics d-flex">
            <ConnectedAccountPopup justImage account={Creator} />
          </div>
        </BoardSection>
      )
    },
    {
      notDefault: true,
      title: "COMPANY",
      comp: (
        <BoardSection
          key="Empresa"
          text={<FormattedMessage id={"COMPANY"} />}
          className="mb-1 mt-2"
        >
          <div className="d-flex justify-content-between mb-2">
            <div className="w-100">
              <div className="w-100">
                <ConnectedOrganizationProfile organization={Company} />
              </div>
            </div>
            <div></div>
          </div>
        </BoardSection>
      )
    },
    // {
    //   notDefault: true,
    //   title: intl.formatMessage({ id: "ASSIGNED_TO" }),
    //   comp: (
    //     <BoardSection
    //       key="Atribuídoa"
    //       text={<FormattedMessage id={"ASSIGNED_TO"} />}
    //       className="mb-1 mt-2"
    //     >
    //       <div className="d-flex justify-content-between mb-2">
    //         <div className="w-100">
    //           <div className="w-100">
    //             <ConnectedAccountPopup account={Assigned} />
    //           </div>
    //         </div>
    //         <div></div>
    //       </div>
    //     </BoardSection>
    //   ),
    // },
    {
      notDefault: true,
      title: "MANAGER",
      comp: (
        <BoardSection
          key="Responsável"
          text={<FormattedMessage id={"MANAGER"} />}
          className="mb-1 mt-2"
        >
          <div className="d-flex justify-content-between mb-2">
            <div className="w-100">
              <div className="w-100">
                <ConnectedAccountPopup account={Manager} />
              </div>
            </div>
            <div></div>
          </div>
        </BoardSection>
      )
    },
    {
      notDefault: true,
      title: "ACCOUNT",
      comp: (
        <BoardSection
          key="Cliente"
          text={<FormattedMessage id={"ACCOUNT"} />}
          className="mb-1 mt-2"
        >
          <div className="d-flex justify-content-between mb-2">
            <div className="w-100">
              <div className="w-100">
                <ConnectedClientOrigin client={Client || IndirectClient}>
                  <ConnectedClientProfile
                    client={Client || IndirectClient}
                    hideStatus={"true"}
                  />
                </ConnectedClientOrigin>
              </div>
            </div>
            <div></div>
          </div>
        </BoardSection>
      )
    },
    {
      notDefault: true,
      title: "CONTACT",
      comp: (
        <BoardSection
          key="Contato"
          text={<FormattedMessage id={"CONTACT"} />}
          className="mb-1 mt-2"
        >
          <div className="d-flex justify-content-between mb-2">
            <div>
              <div>
                <ConnectedContactPopup contact={Contact} />
              </div>
            </div>
            <div></div>
          </div>
        </BoardSection>
      )
    }
  ];
};

export const ContractAssociations = ({ value }) => {
  const { Assigned, Manager, Company, Client, Contact, IndirectClient } = value;
  return (
    <>
      <ConnectedAccountsTeamsDepartmentsProfileStack
        size="xsm"
        organizations={[Company]}
        accounts={[Assigned, Manager]}
      />
      <div className="vertical-splitter h-100 mx-2" />
      <div className="ar-board-pics align-items-center d-flex">
        <ConnectedClientOrigin client={Client || IndirectClient}>
          <ConnectedClientProfile
            size="xsm"
            client={Client || IndirectClient}
            justImage
          />
        </ConnectedClientOrigin>
        <ConnectedContactPopup size="xsm" justImage contact={Contact} />
      </div>
    </>
  );
};

const ContractItem = React.memo(({ id, onItemSelect, isSelected }) => {
  const contract = useContract(id) || {};

  const { NoTagDescription, IsRead, IsFollowing, CreationDate } = contract;

  const BoardComponents = useContractItemBoardComponents(contract);

  const resolvedColumns = useResolvedCards(
    BoardComponents,
    spaceContractSchema.name
  );

  return (
    <CardContainer
      isRead={IsRead}
      isFollowing={IsFollowing}
      id={id}
      schema={spaceContractSchema}
      value={contract}
      Icon={<ContractIcon />}
      name={NoTagDescription}
      onItemSelect={onItemSelect}
      isSelected={isSelected}
      LinkComponent={ContractSidebarLink}
      ButtonsComponent={ContractEntityGridButtons}
      Associations={<ContractAssociations value={contract} />}
      startDate={CreationDate}
    >
      {resolvedColumns.map((e) => e.comp)}
    </CardContainer>
  );
});

// const getContractBorderType = (baseStatus) => {
//   switch (baseStatus) {
//     case ContractStatusTypes.open:
//       return BoardHeaderTypes.success;

//     case ContractStatusTypes.closed:
//       return BoardHeaderTypes.danger;

//     default:
//       return BoardHeaderTypes.success;
//   }
// };

// const ContractBoardItem = (props) => {
//   const params = useDetailsServerAwareParams();

//   const { pipelineStatus } = props;

//   const pipelineSettings = usePipelineSettings();
//   const { pipelineStatusField: field } = pipelineSettings;

//   const { sumEndpoint, countEndpoint } = useMemo(() => {
//     const pOverride = buildBoardParamOverride(
//       params.filter,
//       pipelineStatus,
//       field
//     );
//     pOverride.query = params.query;
//     const query = buildODataQuery(pOverride, true, {
//       pipelineSettings
//     });

//     const countEndpoint = `contracts/Count${query}`;
//     const sumEndpoint = `contracts/totalSum${query}&SumParams=Currency.EstimatedCost`;

//     return { sumEndpoint, countEndpoint };
//   }, [field, params, pipelineSettings, pipelineStatus]);

//   const {
//     loading: loadingSum,
//     data: sumDict,
//     error: sumError,
//     refetch: refetchSum
//   } = useSpaceQuery(sumEndpoint);

//   const {
//     loading: loadingCount,
//     data: count,
//     error: countError,
//     refetch: refetchCount
//   } = useSpaceQuery(countEndpoint);

//   useEffect(() => {
//     return ContractUpdateCommunicator.subscribe(
//       (entityId, oldEntity, newEntity) => {
//         if (!oldEntity) return;
//         const { PipelineStatus: prevPipelineStatus } = oldEntity;
//         const { PipelineStatus: newPipelineStatus } = newEntity;

//         if (prevPipelineStatus === pipelineStatus) {
//           refetchSum();
//           refetchCount();
//         } else if (newPipelineStatus === pipelineStatus) {
//           refetchSum();
//           refetchCount();
//         }

//         // if (
//         //   oldEntity.PipelineStatus === pipelineStatus &&
//         //   newEntity.PipelineStatus !== pipelineStatus
//         // ) {
//         //   refetch()
//         // }
//       }
//     );
//   }, [pipelineStatus, refetchCount, refetchSum]);

//   const resolvedValue = useMemo(() => {
//     return {
//       loadingSum: loadingSum || sumError,
//       sumValue: sumDict ? sumDict["Currency.EstimatedCost"] : 0,
//       loadingCount: loadingCount || countError,
//       countValue: count
//     };
//   }, [count, countError, loadingCount, loadingSum, sumDict, sumError]);

//   return (
//     <BoardHeaderContext.Provider value={resolvedValue}>
//       <EntityBoardItem {...props} />
//     </BoardHeaderContext.Provider>
//   );
// };

const ContractCards = ({ onItemSelect }) => {
  const { error, results, isLoading } = useGridRequest();

  const ContractSettings = useMemo(() => {
    return {
      onItemSelect: onItemSelect,
      ItemComponent: ContractItem,
      schema: spaceContractSchema,
      selector: (e) => {
        const { Id, Assigned } = e;
        return {
          Id,
          UserId: Assigned
        };
      }
    };
  }, [onItemSelect]);

  if (isLoading)
    return (
      <LoaderSpinner
        size="sm"
        className="text-primary flex-1 d-flex align-items-center justify-content-around"
      />
    );
  if (error) return <EmptyListError />;
  if (results && results.length === 0)
    return (
      <EmptyListContainer type="contracts">
        <div className="fw-bold fs-24 text-black ">
          <FormattedMessage id={"EMPTY_CONTRACTS"} />
        </div>
        <div className="fs-18 text-black text-center mb-4">
          <FormattedMessage id={"NO_CONTRACTS"} />
        </div>
        <ContractFormButton></ContractFormButton>
      </EmptyListContainer>
    );

  return (
    <StatusCompContext.Provider value={ContractStatusProfile}>
      <CardList settings={ContractSettings} />;
    </StatusCompContext.Provider>
  );
};
export default ContractCards;
