import React, { Component } from "react";
import "./SplitCol.css";

class SplitCol extends Component {
  render() {
    const { className } = this.props;
    let left_w;
    let right_w;
    if (this.props.leftpercent !== null) {
      left_w = (this.props.leftpercent || 50) + "%";
      right_w = 100 - (this.props.leftpercent || 50) + "%";
    }
    const { show = true } = this.props;

    if (!show) return null;

    return (
      <div className={`split ${className ? className : ""} `}>
        <div style={{ width: left_w }} className="left">
          {this.props.left}
        </div>
        <div style={{ width: right_w }} className="right">
          {this.props.right}
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default SplitCol;
