import { useReducer, useRef, useEffect, useContext, useState } from "react";
import api from "../api";
import Axios from "axios";
import { useSpace } from "../Contexts/SpaceContext";
import store from "../store";
import { normalize } from "./SchemaHelper";
import querystring from "query-string";
import { Link } from "react-router-dom";
import React from "react";
import Button from "../Components/Button/Button";
import { useToast } from "../Components/Toast/ToastProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import { useMenuContext } from "../Components/ContextMenu/ContextMenu";
import { EntityPopupContext } from "../Components/EntityPopup/EntityPopupHelper";


export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
const cache = {
  schema: {},
  url: {}
};

const createMainViewLink = (view) => {
  const EntityLink = ({ children, params, ...rest }) => {
    const space = useSpace();
    return (
      <Link
        to={`/s/${space.Id}/${view}${params ? `?${params}` : ""}`}
        {...rest}
      >
        {children}
      </Link>
    );
  };
  return EntityLink;
};
export const TicketMainViewLink = createMainViewLink("tickets");
export const TaskMainViewLink = createMainViewLink("tasks");
export const ProjectMainViewLink = createMainViewLink("projects");
export const ContractMainViewLink = createMainViewLink("contracts");
export const DealMainViewLink = createMainViewLink("deals");
export const ClientMainViewLink = createMainViewLink("customers");
export const ContactsMainViewLink = createMainViewLink("contacts");
export const ClassificationMainViewLink = createMainViewLink("classification");
export const InterventionMainViewLink = createMainViewLink("interventions");

export const LinkClosureContext = React.createContext();

const createEntityLink = (view) => {
  const EntityLink = ({ children, id, innerRef, onClick, ...rest }) => {
    const space = useSpace();
    const handleContextClosure = useContext(LinkClosureContext);

    return (
      <Link
        innerRef={innerRef}
        className="EntityLinkClassID"
        onClick={(e) => {
          if (handleContextClosure) handleContextClosure(e);
          if (onClick) onClick(e);
          e.stopPropagation();
        }}
        to={id ? `/s/${space.Id}/${view}/${id}` : `/s/${space.Id}/${view}`}
        {...rest}
      >
        {children}
      </Link>
    );
  };
  return EntityLink;
};

const createEntityClipboardButton = (view) => {
  const EntityClipboardButton = ({
    children,
    id,
    innerRef,
    onClick,
    onSuccess,
    link,
    show,
    ...rest
  }) => {
    const providedOnSuccess = useContext(EntityPopupContext);
    const resolvedLink = useMenuContext() || link;
    const space = useSpace();
    // const handleContextClosure = useContext(LinkClosureContext);
    const intl = useIntl();
    const createToast = useToast();

    const handleClick = () => {
      const link = `${window.location.origin}/s/${space.Id}/${view}/${id}`;
      // var textArea = document.createElement("textarea");
      // // Place in top-left corner of screen regardless of scroll position.
      // textArea.style.position = "fixed";
      // textArea.style.top = -500;
      // textArea.style.left = -500;

      // // Ensure it has a small width and height. Setting to 1px / 1em
      // // doesn't work as this gives a negative w/h on some browsers.
      // textArea.style.width = "2em";
      // textArea.style.height = "2em";

      // // We don't need padding, reducing the size if it does flash render.
      // textArea.style.padding = 0;

      // // Clean up any borders.
      // textArea.style.border = "none";
      // textArea.style.outline = "none";
      // textArea.style.boxShadow = "none";

      // // Avoid flash of white box if rendered for any reason.
      // textArea.style.background = "transparent";

      // textArea.value = link;

      // document.body.appendChild(textArea);
      // textArea.focus();
      // textArea.select();
      // document.execCommand("copy");

      // document.removeChild(textArea)
      const elem = document.createElement("textarea");
      elem.value = link;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand("copy");
      document.body.removeChild(elem);
      createToast({
        pos: "tm",
        type: "success",
        // title: `link copiado`
        description: intl.formatMessage({ id: "COPIED" })
      });
    };

    return (
      <Button
        // innerRef={innerRef}
        vType={resolvedLink ? "link" : undefined}
        className={resolvedLink ? "text-left" : undefined}
        onClick={(e) => {
          // if (handleContextClosure) handleContextClosure(e);
          handleClick();
          if (onClick) onClick(e);
          onSuccess && onSuccess();
          providedOnSuccess && providedOnSuccess();
          e.stopPropagation();
        }}
        {...rest}
      >
        <div className="ar-form-button-link-icon mr-1">
          <FontAwesomeIcon icon={faLink} />
        </div>
        <span>
          <FormattedMessage id="COPY_URL" />
        </span>
      </Button>
    );
  };
  return EntityClipboardButton;
};

export const AccountLink = createEntityLink("accounts");
export const ClientLink = createEntityLink("customers");
export const ContactsLink = createEntityLink("contacts");
export const CallLink = createEntityLink("calls");
export const TicketsLink = createEntityLink("tickets");
export const AutomationLink = createEntityLink("automation");
export const TasksLink = createEntityLink("tasks");
export const ContractsLink = createEntityLink("contracts");
export const SubscriptionsLink = createEntityLink("subscriptions");
export const ProjectsLink = createEntityLink("projects");
export const InterventionsLink = createEntityLink("interventions");
export const DealLink = createEntityLink("deals");

export const ClientClipboardButton = createEntityClipboardButton("customers");
export const ContactsClipboardButton = createEntityClipboardButton("contacts");
export const CallClipboardButton = createEntityClipboardButton("calls");
export const AutomationClipboardButton =
  createEntityClipboardButton("automation");
export const TicketsClipboardButton = createEntityClipboardButton("tickets");
export const TasksClipboardButton = createEntityClipboardButton("tasks");
export const DocumentsClipboardButton = createEntityClipboardButton("documents");
// export const DocTemplatesClipboardButton = createEntityClipboardButton("templates");
export const ContractsClipboardButton =
  createEntityClipboardButton("contracts");
export const SubscriptionsClipboardButton =
  createEntityClipboardButton("subscriptions");
export const ProjectsClipboardButton = createEntityClipboardButton("projects");
export const InterventionsClipboardButton =
  createEntityClipboardButton("interventions");
export const DealClipboardButton = createEntityClipboardButton("deals");

// export const CLink = ({ view, id, children }) => {
//   return <Link to={`${view}/${id}`}>{children}</Link>;
// };

const getUrlCacheValue = (endpoint) => {
  return cache.url[endpoint];
};
const setUrlCache = (endpoint, response) => {
  cache.url[endpoint] = response;
};

const createSchemaIdPath = (schema, id) => {
  const resolvedSchema = Array.isArray(schema) ? schema[0] : schema;
  if (id) return `${resolvedSchema.name}${id}`;
  return resolvedSchema.name;
};

const getSchemaCacheValue = (schema, id) => {
  const path = createSchemaIdPath(schema, id);
  return cache.schema[path];
};

const setSchemaCache = (schema, id, response) => {
  const path = createSchemaIdPath(schema, id);
  cache.url[path] = response;
};

const defaultSettings = {
  autoFetch: true,
  cache: true,
  variables: null
};

export const useLegacyQuery = (endpoint, options = defaultSettings) => {
  const { autoFetch, cache } = { ...defaultSettings, ...options };

  const stateRef = useRef();
  const sourceRef = useRef();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const previousEndpoint = usePrevious(endpoint);

  const fetch = () => {
    if (!endpoint) return;
    const cacheValue = cache && getUrlCacheValue(endpoint);

    if (cacheValue) {
      stateRef.current.loading = false;
      stateRef.current.data = cacheValue;
      stateRef.current.error = null;
      return;
    }

    stateRef.current.loading = true;
    stateRef.current.error = null;
    sourceRef.current = Axios.CancelToken.source();

    api
      .get(endpoint, {
        cancelToken: sourceRef.current.token
      })
      .then((r) => {
        if (cache) setUrlCache(endpoint, r);
        stateRef.current.data = r;
        stateRef.current.loading = false;
        sourceRef.current = null;
        forceUpdate();
      })
      .catch((e) => {
        if (Axios.isCancel(e)) {
          return;
        }
        stateRef.current.error = e;
        stateRef.current.loading = false;
        forceUpdate();
      });
  };

  const updateQuery = (value) => {
    if (cache && getUrlCacheValue(endpoint) === value) return;

    if (sourceRef.current) sourceRef.current.cancel();
    stateRef.current.loading = false;
    stateRef.current.error = null;
    stateRef.current.data = value;
    if (cache) setUrlCache(endpoint, value);
    forceUpdate();
  };

  //clear query on unmount
  useEffect(() => {
    return () => {
      if (sourceRef.current) sourceRef.current.cancel();
    };
  }, []);

  //startup
  if (!stateRef.current || endpoint !== previousEndpoint) {
    stateRef.current = {
      data: null,
      loading: autoFetch ? true : false,
      error: null
    };

    if (autoFetch && endpoint) fetch();
  }

  return { ...stateRef.current, fetch, updateQuery };
};

const defaultPostSettings = {
  notify: true
};
export const usePost = (endpoint, { notify } = defaultPostSettings) => {
  const stateRef = useRef();
  const sourceRef = useRef();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const previousEndpoint = usePrevious(endpoint);

  const post = (body) => {
    stateRef.current.loading = true;
    stateRef.current.error = null;
    sourceRef.current = Axios.CancelToken.source();

    api
      .post(endpoint, body, {
        cancelToken: sourceRef.current.token
      })
      .then((r) => {
        stateRef.current.data = r;
        stateRef.current.loading = false;
        sourceRef.current = null;
        if (notify) forceUpdate();
      })
      .catch((e) => {
        if (Axios.isCancel(e)) {
          return;
        }
        stateRef.current.error = e;
        stateRef.current.loading = false;
        if (notify) forceUpdate();
      });
  };

  //clear query on unmount
  useEffect(() => {
    return () => {
      if (sourceRef.current) sourceRef.current.cancel();
    };
  }, []);

  //startup
  if (!stateRef.current || endpoint !== previousEndpoint) {
    stateRef.current = {
      data: null,
      loading: false,
      error: null
    };
  }

  return { ...stateRef.current, post };
};

export const useSchemaMutation = (schema, id) => {
  const mutate = (newValue) => {
    const schemaValue = { [id]: newValue };

    const response = {
      entities: {
        [schema.name]: schemaValue
      }
    };

    store.dispatch({
      type: "UPDATE_ENTITIES",
      response: response
    });
  };

  return mutate;
};

export const useSchemaQuery = (schema, id, options) => {
  const { autoFetch, cache, params } = { ...defaultSettings, ...options };
  const space = useSpace();
  const stateRef = useRef();
  const sourceRef = useRef();
  const hasUnmountedRef = useRef(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const resolvedSchema = Array.isArray(schema) ? schema[0] : schema;
  const endpoint = resolvedSchema.getEndpoint(space.Id);

  const previousEndpoint = usePrevious(endpoint);

  const fetch = (update) => {
    if (hasUnmountedRef.current) return;
    const cacheValue = cache && getSchemaCacheValue(schema, id);

    if (cacheValue) {
      stateRef.current.loading = false;
      stateRef.current.data = cacheValue;
      stateRef.current.error = null;
      return;
    }

    stateRef.current.loading = true;
    stateRef.current.error = null;
    sourceRef.current = Axios.CancelToken.source();

    if (update) forceUpdate();
    const resolvedParams = params ? querystring.stringify(params) : null;

    const resolvedEndpoint = resolvedParams
      ? `${endpoint}?${resolvedParams}`
      : endpoint;

    api
      .get(resolvedEndpoint, {
        cancelToken: sourceRef.current.token
      })
      .then((r) => {
        const normalizedData = normalize(r, schema);
        if (cache) setSchemaCache(schema, id, normalizedData.result);
        stateRef.current.data = normalizedData.result;
        stateRef.current.loading = false;
        sourceRef.current = null;
        store.dispatch({
          type: "UPDATE_ENTITIES",
          response: { entities: normalizedData.entities }
        });
        forceUpdate();
      })
      .catch((e) => {
        if (Axios.isCancel(e)) {
          return;
        }
        stateRef.current.error = e;
        stateRef.current.loading = false;
        // forceUpdate();
      });
  };

  const updateQuery = (value) => {
    if (cache && getSchemaCacheValue(schema, id) === value) return;

    if (sourceRef.current) sourceRef.current.cancel();
    stateRef.current.loading = false;
    stateRef.current.error = null;
    stateRef.current.data = value;
    if (cache) setSchemaCache(schema, id, value);
    forceUpdate();
  };

  //clear query on unmount
  useEffect(() => {
    return () => {
      hasUnmountedRef.current = true;
      if (sourceRef.current) sourceRef.current.cancel();
    };
  }, []);

  //startup
  if (!stateRef.current || endpoint !== previousEndpoint) {
    stateRef.current = {
      data: null,
      loading: autoFetch ? true : false,
      error: null
    };

    if (autoFetch) fetch();
  }

  return { ...stateRef.current, fetch: () => fetch(true), updateQuery };
};

export const useRequestSchemaQuery = (requestObj, options) => {
  const { autoFetch, cache, variables } = { ...defaultSettings, ...options };
  const stateRef = useRef();
  const sourceRef = useRef();
  const hasUnmountedRef = useRef(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const endpoint =
    typeof requestObj.endpoint === "function"
      ? requestObj.endpoint(variables)
      : requestObj.endpoint;

  const previousEndpoint = usePrevious(endpoint);

  const fetch = (update) => {
    if (hasUnmountedRef.current) return;
    const cacheValue = cache && getUrlCacheValue(endpoint);

    if (cacheValue) {
      stateRef.current.loading = false;
      stateRef.current.data = cacheValue;
      stateRef.current.error = null;
      return;
    }

    stateRef.current.loading = true;
    stateRef.current.error = null;
    sourceRef.current = Axios.CancelToken.source();

    if (update) forceUpdate();

    api
      .get(endpoint, {
        cancelToken: sourceRef.current.token
      })
      .then((r) => {
        const normalizedData = normalize(r, requestObj);
        if (cache) setUrlCache(endpoint, normalizedData.result);
        stateRef.current.data = normalizedData.result;
        stateRef.current.loading = false;
        sourceRef.current = null;
        store.dispatch({
          type: "UPDATE_ENTITIES",
          response: { entities: normalizedData.entities }
        });
        forceUpdate();
      })
      .catch((e) => {
        if (Axios.isCancel(e)) {
          return;
        }
        stateRef.current.error = e;
        stateRef.current.loading = false;
        // forceUpdate();
      });
  };

  const updateQuery = (value) => {
    if (cache && getUrlCacheValue(endpoint) === value) return;

    if (sourceRef.current) sourceRef.current.cancel();
    stateRef.current.loading = false;
    stateRef.current.error = null;
    stateRef.current.data = value;
    if (cache) setUrlCache(endpoint, value);
    forceUpdate();
  };

  //clear query on unmount
  useEffect(() => {
    return () => {
      hasUnmountedRef.current = true;
      if (sourceRef.current) sourceRef.current.cancel();
    };
  }, []);

  //startup
  if (!stateRef.current || endpoint !== previousEndpoint) {
    stateRef.current = {
      data: null,
      loading: autoFetch ? true : false,
      error: null
    };

    if (autoFetch) fetch();
  }

  return { ...stateRef.current, fetch: () => fetch(true), updateQuery };
};





export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Return a cleanup function that will be called every time
      // useEffect is re-called. useEffect will only be re-called
      // if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is
      // changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's
      // search box, we don't want the debouncedValue to update until
      // they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler)
      }
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you
    // need to be able to change that dynamically.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  )

  return debouncedValue
}