import { useEffect } from "react";

export const useEndlessScroll = ({
  listen,
  pageRef,
  onFetchMore,
  offset,
  offsettMultiplier
}) => {
  useEffect(() => {
    if (!listen) return;
    let hasUnsubscribed = false;
    const elem = pageRef.current;

    const listener = () => {
      if (hasUnsubscribed || !elem) return;
      const { scrollHeight, scrollTop, offsetHeight: height } = elem;

      const totalScrolledHeight = scrollTop + height;

      let resolvedOffset = offset || height;
      //   
      if (offsettMultiplier)
        resolvedOffset = resolvedOffset * offsettMultiplier;

      if (scrollHeight - totalScrolledHeight <= resolvedOffset) {
        // hasUnsubscribed = true;
        hasUnsubscribed = true;
        onFetchMore();
      }
    };

    listener();

    if (hasUnsubscribed) return;
    elem.addEventListener("scroll", listener);
    window.addEventListener("resize", listener);

    return () => {
      elem.removeEventListener("scroll", listener);
      window.addEventListener("resize", listener);
    };
  }, [listen, offset, offsettMultiplier, onFetchMore, pageRef]);
};
