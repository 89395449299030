import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const ContactOverviewComp = React.lazy(() =>
  import(/* webpackChunkName: "contacts-overview" */ "./ContactOverviewComp")
);

const ContactOverview = () => {
  return (
    <SuspenseErrorBoundary>
      <ContactOverviewComp />
    </SuspenseErrorBoundary>
  );
};

export default ContactOverview;
