import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getNumberOfDays } from "../../Helpers/MiscHelper";
import { faClock, faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import { HourToMilSec, MinuteToMilSec } from "../../Helpers/MiscHelper";

import TimePicker from "../TimePicker/TimePicker";
import moment from "moment";
import { DoubleDigitFormat } from "../../Assets/Utils";

export class DatePickerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Mins: this.props.Minutes,
      Hrs: this.props.Hours,
      Day: null,
      Month: null,
      Year: null,
      PreviousPropHour: this.props.Hours,
      PreviousPropMinute: this.props.Minutes,
      HideYearInput: this.props.HideYearInput || false
    };
    this.dayInput = React.createRef();
    this.monthInput = React.createRef();
    this.yearInput = React.createRef();
    this.hourpickerRef = React.createRef();
    this.innerRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    const { Hours, Minutes } = props;

    // if (
    //   state.PreviousPropHour !== Hours ||
    //   state.PreviousPropMinute !== Minutes
    // ) {
    if (Hours !== state.Hrs || Minutes !== state.Mins)
      return {
        Hrs: Hours,
        Mins: Minutes,
        PreviousPropHour: Hours,
        PreviousPropMinute: Minutes
      };
    // }
    return null;
  }

  onChange = (e, type = "") => {
    const { name, value } = type === "altered" ? e : e.target;

    if (value !== "") {
      const valueParsed = parseInt(value);

      if (isNaN(valueParsed)) return;

      if (
        name === "Hrs" &&
        (valueParsed < 0 || valueParsed > 23 || value.length > 2)
      )
        return;
      if (
        name === "Mins" &&
        (valueParsed < 0 || valueParsed > 59 || value.length > 2)
      )
        return;

      if (
        name === "Day" &&
        (valueParsed < 0 || valueParsed > 31 || value.length > 2)
      )
        return;
      if (
        name === "Month" &&
        (valueParsed < 0 || valueParsed > 12 || value.length > 2)
      )
        return;
      if (name === "Year" && (valueParsed < 0 || value.length > 4)) return;
    }

    this.setState(
      {
        [name]: value
      },
      () => {
        this.props.onChange({
          Day: this.dayInput.current.value,
          Month: this.monthInput.current.value,
          Year: this.yearInput.current.value
        });
      }
    );
  };

  onChangeHour = (value, instruction) => {
    if (value !== "") {
      const valueParsed = parseInt(value);
      if (isNaN(valueParsed)) return;

      //   if (valueParsed < 0 || valueParsed > 23 || value.length > 2) return;
      switch (instruction) {
        case "decreaseDay":
          this.setState({ Hrs: value }, () => {
            const Hours = valueParsed;
            const Minutes = this.state.Mins;
            if (this.dayInput.current) {
              if (parseInt(this.dayInput.current.value) - 1 === 0) {
                this.monthInput.current.value =
                  this.monthInput.current.value - 1;
                this.dayInput.current.value = getNumberOfDays(
                  parseInt(this.yearInput.current.input),
                  parseInt(this.monthInput.current.value)
                );
              } else {
                this.dayInput.current.value =
                  parseInt(this.dayInput.current.value) - 1;
              }

              this.inputDateChange();
            }
            this.props.onChangeTime({ Hours, Minutes });
          });
          break;
        case "increaseDay":
          this.setState({ Hrs: value }, () => {
            const Hours = valueParsed;
            const Minutes = this.state.Mins;
            if (this.dayInput.current) {
              this.dayInput.current.value =
                parseInt(this.dayInput.current.value) + 1;
              this.inputDateChange();
              this.props.onChangeTime({ Hours, Minutes });
            }
          });
          break;
        default:
          if (this.props.disablePastDate) {
            const disablePastDate = new Date(this.props.disablePastDate);
            if (parseInt(value) >= parseInt(disablePastDate.getHours())) {
              let newVal = value;
              let newMins =
                parseInt(value) === parseInt(disablePastDate.getHours()) &&
                parseInt(this.state.Mins) <=
                  parseInt(disablePastDate.getMinutes())
                  ? parseInt(disablePastDate.getMinutes()) + 1
                  : parseInt(this.state.Mins);
              if (newMins === 60) {
                newVal += 1;
                newMins = 0;
              }
              this.setState({ Hrs: newVal, Mins: newMins }, () => {
                const Hours = newVal;
                const Minutes = newMins;

                this.props.onChangeTime({ Hours, Minutes });
              });
            }
          } else {
            this.setState({ Hrs: value }, () => {
              const Hours = value;
              const Minutes = this.state.Mins;

              this.props.onChangeTime({ Hours, Minutes });
            });
          }

          break;
      }
    }
  };

  onChangeMinute = (value, hours) => {
    if (value !== "") {
      const valueParsed = parseInt(value);
      const valueHoursParsed = parseInt(hours);

      if (this.props.disablePastDate) {
        const disablePastDate = new Date(this.props.disablePastDate);
        let newHours = this.state.Hrs;
        let newMins =
          parseInt(newHours) === parseInt(disablePastDate.getHours()) &&
          parseInt(value) <= parseInt(disablePastDate.getMinutes())
            ? parseInt(disablePastDate.getMinutes()) + 1
            : parseInt(value);
        if (newMins === 60) {
          newHours += 1;
          newMins = 0;
        }
        this.setState({ Hrs: newHours, Mins: newMins }, () => {
          const Hours = newHours;
          const Minutes = value;
          this.props.onChangeTime({ Hours, Minutes });
        });
      } else {
        if (isNaN(valueParsed)) return;

        if (valueParsed < 0 || valueParsed > 59 || value.length > 2) return;

        this.setState({ Mins: value }, () => {
          const Hours =
            typeof valueHoursParsed === "number" && !isNaN(valueHoursParsed)
              ? valueHoursParsed
              : this.state.Hrs;
          const Minutes = value;
          this.props.onChangeTime({ Hours, Minutes });
        });
      }
    }
  };

  inputHourChange = (value) => {
    const hour = value;
    this.props.closeHourPicker();
    this.setState({ Hrs: null, Mins: null }, () => {
      this.props.itemClick({ Hours: hour || 0 }, false);
    });
  };

  inputMinChange = (value) => {
    const min = value;
    this.props.closeHourPicker();
    this.setState({ Hrs: null, Mins: null }, () => {
      this.props.itemClick({ Minutes: min || 0 }, false);
    });
  };

  inputDateChange = (option = "") => {
    const _Day = this.dayInput.current.value;
    const _Month = this.monthInput.current.value;
    const _Year = this.yearInput.current.value;

    if (_Day === "" && _Month === "" && _Year === "") return;

    let Day = _Day === "" ? null : parseInt(_Day);
    let Month = _Month === "" ? null : parseInt(_Month);
    let Year = _Year === "" ? null : parseInt(_Year);

    this.setState({ Day: null, Month: null, Year: null }, () => {
      this.props.onChange({ Day, Month, Year }, false);
    });
  };

  handleArrowChange = (type, e) => {
    if (e.keyCode === 37 || e.keyCode === 39) {
      if (
        e.keyCode === 39 &&
        e.target &&
        e.target.selectionStart === e.target.value.length &&
        e.target.nextElementSibling
      ) {
        e.preventDefault();
        e.target.nextElementSibling.focus();
        e.target.nextElementSibling.setSelectionRange(0, 0);
      } else if (
        e.keyCode === 37 &&
        e.target &&
        e.target.selectionStart === 0 &&
        e.target.previousElementSibling
      ) {
        e.preventDefault();
        e.target.previousElementSibling.focus();
        e.target.previousElementSibling.setSelectionRange(
          e.target.previousElementSibling.value.length,
          e.target.previousElementSibling.value.length
        );
      } else if (
        e.keyCode === 39 &&
        e.target &&
        e.target.selectionStart === e.target.value.length &&
        !e.target.nextElementSibling
      ) {
        e.preventDefault();
        e.target.parentNode.nextElementSibling.firstElementChild.focus();
        e.target.parentNode.nextElementSibling.firstElementChild.setSelectionRange(
          0,
          0
        );
      }
    }

    switch (type) {
      case "Day":
        if (
          e.keyCode === 38 &&
          !this.props.DataPopupStatus &&
          parseInt(e.target.value) <
            getNumberOfDays(
              parseInt(this.yearInput.current.input),
              parseInt(this.monthInput.current.value)
            )
        ) {
          e.target.value = parseInt(e.target.value) + 1;
          this.onChange(e);
        } else if (
          e.keyCode === 40 &&
          !this.props.DataPopupStatus &&
          parseInt(e.target.value) > 1
        ) {
          if (!this.props.disableDates) {
            e.target.value = parseInt(e.target.value) - 1;
            this.onChange(e);
          } else if (
            new Date(this.props.disablePastDate).getDate() <=
            parseInt(e.target.value) - 1
          ) {
            e.target.value = parseInt(e.target.value) - 1;
            this.onChange(e);
          }
        } else if (
          new Date(this.props.disableFutureDate).getDate() >=
          parseInt(e.target.value) + 1
        ) {
          e.target.value = parseInt(e.target.value) + 1;
          this.onChange(e);
        } else if (
          e.keyCode === 40 &&
          !this.props.DataPopupStatus &&
          parseInt(e.target.value) === 1
        ) {
          if (!this.props.disableDates) {
            e.target.value = getNumberOfDays(
              parseInt(this.yearInput.current.input),
              parseInt(this.monthInput.current.value)
            );
            this.monthInput.current.value =
              parseInt(this.monthInput.current.value) - 1;
            if (parseInt(this.monthInput.current.value) === 0) {
              this.monthInput.current.value = 12;
              this.yearInput.current.value =
                parseInt(this.yearInput.current.value) - 1;
              this.onChange(this.yearInput.current, "altered");
            }
            this.onChange(this.monthInput.current, "altered");
            this.onChange(e);
          } else if (
            new Date(this.props.disablePastDate).getDate() <=
            parseInt(e.target.value) - 1
          ) {
            e.target.value = getNumberOfDays(
              parseInt(this.yearInput.current.input),
              parseInt(this.monthInput.current.value)
            );
            this.monthInput.current.value =
              parseInt(this.monthInput.current.value) - 1;
            if (parseInt(this.monthInput.current.value) === 0) {
              this.monthInput.current.value = 12;
              this.yearInput.current.value =
                parseInt(this.yearInput.current.value) - 1;
              this.onChange(this.yearInput.current, "altered");
            }
            this.onChange(this.monthInput.current, "altered");
            this.onChange(e);
          } else if (
            new Date(this.props.disableFutureDate).getDate() >=
            parseInt(e.target.value) + 1
          ) {
            e.target.value = getNumberOfDays(
              parseInt(this.yearInput.current.input),
              parseInt(this.monthInput.current.value)
            );
            this.monthInput.current.value =
              parseInt(this.monthInput.current.value) - 1;
            if (parseInt(this.monthInput.current.value) === 0) {
              this.monthInput.current.value = 12;
              this.yearInput.current.value =
                parseInt(this.yearInput.current.value) - 1;
              this.onChange(this.yearInput.current, "altered");
            }
            this.onChange(this.monthInput.current, "altered");
            this.onChange(e);
          }
        } else if (
          e.keyCode === 38 &&
          !this.props.DataPopupStatus &&
          parseInt(e.target.value) ===
            getNumberOfDays(
              parseInt(this.yearInput.current.input),
              parseInt(this.monthInput.current.value)
            )
        ) {
          e.target.value = 1;
          this.monthInput.current.value =
            parseInt(this.monthInput.current.value) + 1;
          if (parseInt(this.monthInput.current.value) === 13) {
            this.monthInput.current.value = 1;
            this.yearInput.current.value =
              parseInt(this.yearInput.current.value) + 1;
            this.onChange(this.yearInput.current, "altered");
          }
          this.onChange(this.monthInput.current, "altered");
          this.onChange(e);
        }

        break;
      case "Month":
        if (
          e.keyCode === 38 &&
          !this.props.DataPopupStatus &&
          parseInt(e.target.value) < 12
        ) {
          e.target.value = parseInt(e.target.value) + 1;
          this.onChange(e);
        } else if (
          e.keyCode === 40 &&
          !this.props.DataPopupStatus &&
          parseInt(e.target.value) > 1
        ) {
          if (!this.props.disableDates) {
            e.target.value = parseInt(e.target.value) - 1;
            this.onChange(e);
          } else if (
            new Date(this.props.disablePastDate).getMonth() + 1 <=
            parseInt(e.target.value) - 1
          ) {
            e.target.value = parseInt(e.target.value) - 1;
            this.onChange(e);
          } else if (
            new Date(this.props.disableFutureDate).getMonth() + 1 >=
            parseInt(e.target.value) + 1
          ) {
            e.target.value = parseInt(e.target.value) + 1;
            this.onChange(e);
          }
        } else if (
          e.keyCode === 38 &&
          !this.props.DataPopupStatus &&
          parseInt(e.target.value) === 12
        ) {
          e.target.value = 1;
          this.yearInput.current.value =
            parseInt(this.yearInput.current.value) + 1;
          this.onChange(e);
          this.onChange(this.yearInput.current, "altered");
        } else if (
          e.keyCode === 40 &&
          !this.props.DataPopupStatus &&
          parseInt(e.target.value) === 1
        ) {
          if (!this.props.disableDates) {
            e.target.value = 12;
            this.yearInput.current.value =
              parseInt(this.yearInput.current.value) - 1;
            this.onChange(e);
            this.onChange(this.yearInput.current, "altered");
          } else if (
            new Date(this.props.disablePastDate).getMonth() + 1 <=
            parseInt(e.target.value) - 1
          ) {
            e.target.value = 12;
            this.yearInput.current.value =
              parseInt(this.yearInput.current.value) - 1;
            this.onChange(e);
            this.onChange(this.yearInput.current, "altered");
          } else if (
            new Date(this.props.disableFutureDate).getMonth() + 1 >=
            parseInt(e.target.value) + 1
          ) {
            e.target.value = 12;
            this.yearInput.current.value =
              parseInt(this.yearInput.current.value) + 1;
            this.onChange(e);
            this.onChange(this.yearInput.current, "altered");
          }
        }
        break;
      case "Year":
        if (e.keyCode === 38 && !this.props.DataPopupStatus) {
          e.target.value = parseInt(e.target.value) + 1;
          this.onChange(e);
        } else if (e.keyCode === 40 && !this.props.DataPopupStatus) {
          if (!this.props.disableDates) {
            e.target.value = parseInt(e.target.value) - 1;
            this.onChange(e);
          } else if (
            new Date(this.props.disablePastDate).getFullYear() <=
            parseInt(e.target.value) - 1
          ) {
            e.target.value = parseInt(e.target.value) - 1;
            this.onChange(e);
          } else if (
            new Date(this.props.disableFutureDate).getFullYear() >=
            parseInt(e.target.value) + 1
          ) {
            e.target.value = parseInt(e.target.value) + 1;
            this.onChange(e);
          }
        }
        break;
      default:
        break;
    }
  };

  render() {
    const date = this.props.date;

    const {
      hourPickerButtonRef,
      datePickerButtonRef,
      Hours,
      Minutes,
      enableDate = true,
      enableHours = true,
      preview
    } = this.props;

    const { Mins, Hrs, Day, Month, Year } = this.state;

    const hrs =
      Hrs !== null ? Hrs : Hours !== null ? DoubleDigitFormat(Hours) : "";
    const mns =
      Mins !== null ? Mins : Minutes !== null ? DoubleDigitFormat(Minutes) : "";

    const day =
      Day !== null ? Day : date.Day ? DoubleDigitFormat(date.Day) : "";
    const month =
      Month !== null ? Month : date.Month ? DoubleDigitFormat(date.Month) : "";
    const year = Year !== null ? Year : date.Year ? date.Year : "";

    const showDateBar =
      (day !== "" && month !== "") ||
      (day !== "" && year !== "") ||
      (month !== "" && year !== "");
    const showHourDots = hrs !== "" && mns !== "";
    // `${hrs}:${mns}`;

    if (preview) {
      let previewDate = new Date(year, month, day, hrs, mns);
      return (
        <React.Fragment>
          {enableHours && enableDate && showDateBar && showHourDots ? (
            <div>{`${day}/${month}/${year} ${moment(previewDate).format(
              "HH:mm"
            )}`}</div>
          ) : enableHours && showHourDots ? (
            <div>{moment(previewDate).format("HH:mm")}</div>
          ) : enableDate && showDateBar ? (
            <div>{`${day}/${month}/${year}`}</div>
          ) : (
            <div />
          )}
        </React.Fragment>
      );
    }

    return (
      <div className="DatePickerComponent">
        <div className="DatePickerComponent-L">
          {enableDate !== false && (
            <span className="ar-timepicker-date">
              <input
                // onFocus={this.props.openDatePicker}
                autoComplete="off"
                onKeyDown={this.handleArrowChange.bind(this, "Day")}
                ref={this.dayInput}
                name="Day"
                value={day}
                onChange={this.onChange}
                onBlur={() => {
                  if (this.inputDateChange) this.inputDateChange();
                }}
              />
              {showDateBar && "/"}
              <input
                // onFocus={this.props.openDatePicker}
                onKeyDown={this.handleArrowChange.bind(this, "Month")}
                autoComplete="off"
                ref={this.monthInput}
                name="Month"
                value={month}
                onChange={this.onChange}
                onBlur={() => {
                  if (this.inputDateChange) this.inputDateChange();
                }}
              />
              {!this.state.HideYearInput && showDateBar && "/"}
              <input
                autoComplete="off"
                style={{ visibility: this.state.HideYearInput && "hidden" }}
                // onFocus={this.props.openDatePicker}
                onKeyDown={this.handleArrowChange.bind(this, "Year")}
                ref={this.yearInput}
                name="Year"
                value={year}
                onChange={this.onChange}
                onBlur={() => {
                  if (this.inputDateChange) this.inputDateChange();
                }}
              />
            </span>
          )}
          {enableHours !== false && (
            <span className="ar-timepicker-time">
              <TimePicker
                innerRef={this.innerRef}
                onChangeHours={this.onChangeHour}
                onChangeMins={this.onChangeMinute}
                value={HourToMilSec(hrs) + MinuteToMilSec(mns)}
              />
            </span>
          )}
        </div>
        <div ref={datePickerButtonRef} className="DatePickerComponent-R">
          {/* <div
           
            className="d-flex flex-column align-items-center justify-content-center"
          > */}
          {enableDate !== false && (
            <button
              data-testid="calendar"
              style={{
                width: 40,
                // borderRight: "1px solid #ced4da",
                height: "100%"
              }}
              type="button"
              className={`${enableDate && !enableHours ? "" : "border-right"}`}
              tabIndex={-1}
              onClick={this.props.openDatePicker}
            >
              {this.props.NewCalenderIconForSchedules ? (
                this.props.NewCalenderIconForSchedules
              ) : (
                <FontAwesomeIcon
                  style={{ color: "#1E73F0" }}
                  icon={faCalendarAlt}
                />
              )}
            </button>
          )}
          {/* </div> */}
          {/* <div
           
            className="d-flex flex-column align-items-center justify-content-center"
          > */}
          {enableHours !== false && (
            <button
              data-testid="clocker"
              tabIndex={-1}
              style={{
                width: 40,

                height: "100%"
              }}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (this.innerRef.current) {
                  this.innerRef.current.activateTimePicker();
                }
                this.props.forceCloseDatePicker();
              }}
              ref={hourPickerButtonRef}
            >
              <FontAwesomeIcon style={{ color: "#1E73F0" }} icon={faClock} />
            </button>
          )}
          {/* </div> */}
        </div>
      </div>
    );
  }
}
