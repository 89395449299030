import React, { useContext } from "react";

export const ServerAwareFilterColumnsContext = React.createContext();
export const ServerAwareColumnsContext = React.createContext();

export const useServerAwareColumns = () =>
  useContext(ServerAwareColumnsContext);

export const useServerAwareFilterColumns = () =>
  useContext(ServerAwareFilterColumnsContext);
