import React from "react";
import { AccountProfileImg } from "../Img/Img";
import classnames from "classnames";
import { FormattedPhoneNumber } from "../Input/PhoneInput";
import { useContact } from "../../Hooks/EntityHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { isPhoneNumber } from "../../Helpers/MiscHelper";
import { FormattedMessage } from "react-intl";
import DropdownInput from "../Dropdown/DropdownInput";
import { faUserTie } from "@fortawesome/pro-solid-svg-icons";

const buildContactStatusType = (status) => {
  switch (`${status}`) {
    case "2":
      return "inactive";
    default:
      return "purple";
  }
};

const statusNameByValue = (status) => {
  switch (status.toString()) {
    case "1":
      return <FormattedMessage id={"ACTIVE"} />;
    case "2":
      return <FormattedMessage id={"INACTIVE"} />;
    default:
      return null;
  }
};

export const ContactStatusProfile = ({
  entity,
  status,
  item,
  hideName,
  className
}) => {
  const resovledStatus = entity ? entity.Status : status || item;

  if (!resovledStatus) return null;

  return (
    <div
      className={classnames(
        `d-flex align-items-center link-color fs-12  status-color-${buildContactStatusType(
          resovledStatus
        )}`,
        className
      )}
    >
      <div className="position-relative status-dot" />
      {!hideName && (
        <div
          style={{ fontWeight: 400 }}
          className="status-label text-black fs-12 text-truncate flex-1 fw-medium"
        >
          {statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

const ContactListItem = ({
  contact,
  className,
  shownumber,
  dropdowninputComp,
  text,
  inline,
  dynamicFont,
  enableInput,
  isTyping,
  ...rest
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100">
        {!isTyping && contact && (
          <AccountProfileImg
            size="sm"
            account={contact}
            circular
            className="mr-2"
          />
        )}
        <div className="d-flex flex-column w-100 text-truncate flex-1">
          <div className="dropdown-item-maintext d-flex">
            {!enableInput ? (
              contact && contact.Name
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={contact && contact.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...rest}
              />
              // <input
              //   {...inputProps}
              //   value={isTyping || !project ? inputProps.value : project.Name}
              // />
            )}
          </div>
          {!isTyping && (
            <div className="dropdown-item-subtext">
              {contact && isPhoneNumber(contact.MobileNumber) ? (
                <span>
                  <FormattedPhoneNumber
                    withIcon
                    className="lh-normal text-truncate"
                    number={contact.MobileNumber}
                  />
                </span>
              ) : contact && isPhoneNumber(contact.PhoneNumber) ? (
                <span className="text-primary">
                  <FormattedPhoneNumber
                    withIcon
                    className="lh-normal text-truncate"
                    number={contact.PhoneNumber}
                  />
                </span>
              ) : null}
              {contact && contact.Email && (
                <span>
                  <a
                    className="text-color-link"
                    href={`mailto:${contact.Email}`}
                    target="_top"
                  >
                    <FontAwesomeIcon className="mr-1" icon={faEnvelope} />
                    {contact.Email}
                  </a>
                </span>
              )}
            </div>
          )}
        </div>

        {/* {contact && (
          <div
            className={`pr-2 d-flex align-items-center justify-content-end status-color-${buildContactStatusType(
              contact?.Status
            )}`}
          >
            <div className="d-flex py-1 px-2 fs-12 align-items-center status-list-template">
              <FontAwesomeIcon className="mr-2" icon={faUserTie} />
              <span>{contact?.Id}</span>
            </div>
          </div>
        )} */}

        {/* {contact && (
          <div
            className={` d-flex align-items-center justify-content-end status-color-${buildContactStatusType(
              contact?.Status
            )}`}
          >
            <div className="d-flex py-1 px-2 fs-12 align-items-center status-list-template">
              <FontAwesomeIcon className="mr-2" icon={faUserTie} />
              <span>{contact?.Id}</span>
            </div>
          </div>
        )} */}
      </div>
      {/* {!isTyping && project.ReceivedDate && (
		  <Moment
			fromNow
			className="dropdown-item-time mr-5"
			date={project.ReceivedDate}
		  />
		)} */}
    </div>
  );

  //   return (
  //     <div
  //       className={classnames(
  //         "align-items-center",
  //         {
  //           "d-flex": !inline,
  //           "d-inline-flex": inline,
  //         },
  //         className
  //       )}
  //     >
  //       <AccountProfileImg
  //         size={size ? size : "sm"}
  //         account={contact}
  //         circular
  //         className="mr-1"
  //       />
  //       <div
  //         className={classnames("flex-1 d-flex flex-column text-truncate", {
  //           "fs-12": !dynamicFont,
  //         })}
  //       >
  //         {!disableName && (
  //           <div className={"text-black fs-14-enforced text-truncate"}>
  //             {contact.Name}
  //           </div>
  //         )}
  //         {dropdownInfo &&
  //           (isPhoneNumber(contact.PhoneNumber) ||
  //             isPhoneNumber(contact.MobileNumber) ||
  //             contact.Email) && (
  //             <div
  //               style={{ bottom: 0 }}
  //               className="position-absolute fs-10 text-black"
  //             >
  //               {isPhoneNumber(contact.MobileNumber) ? (
  //                 <FormattedPhoneNumber
  //                   withIcon
  //                   className="link-color lh-normal text-truncate mr-2"
  //                   number={contact.MobileNumber}
  //                 />
  //               ) : isPhoneNumber(contact.PhoneNumber) ? (
  //                 <FormattedPhoneNumber
  //                   withIcon
  //                   className="link-color lh-normal text-truncate mr-2"
  //                   number={contact.PhoneNumber}
  //                 />
  //               ) : null}
  //               {contact.Email && (
  //                 <a href={`mailto:${contact.Email}`} target="_top">
  //                   <FontAwesomeIcon className="mr-1" icon={faEnvelope} />
  //                   {contact.Email}
  //                 </a>
  //               )}
  //             </div>
  //           )}
  //         {contact.MobileNumber && shownumber ? (
  //           <FormattedPhoneNumber
  //             className="link-color lh-normal text-truncate"
  //             number={contact.MobileNumber}
  //           />
  //         ) : contact.PhoneNumber && shownumber ? (
  //           contact.PhoneNumber &&
  //           shownumber && (
  //             <FormattedPhoneNumber
  //               className="link-color lh-normal text-truncate"
  //               number={contact.PhoneNumber}
  //             />
  //           )
  //         ) : null}
  //       </div>
  //     </div>
  //   );
};

export const ConnectContactEntity = (Component) => {
  return ({ contact, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const c = useContact(contact);
    return <Component contact={c} {...rest} />;
  };
};

export default ContactListItem;

// const mapStateToProps = (state, { contact }) => {
//   if (!contact) return null;

//   const contactEntity = state.Entities.SpaceContacts[contact];

//   const { Id, Name, Description, PhoneNumber } = contactEntity;

//   return { Id, Name, Description, PhoneNumber };
// };

export const ConnectedContactListItem = ConnectContactEntity(ContactListItem);

// export const ConnectedContactProfile = connectShallowEntity(
//   "contact",
//   mapStateToProps
// )(ContactProfile);
