import React, { useRef, useState } from "react";
import { useMemo } from "react";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";

const OfficePreviewContent = ({ src }) => {
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef();
  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div className="h-100 w-100">
      {loading && (
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
          <LoaderSpinner size="lg" className="m-4 text-primary" />
        </div>
      )}
      <iframe
        onLoad={() => {
          handleLoad();
          if (iframeRef.current) {
            const { contentDocument } = iframeRef.current;
            let doc = contentDocument;
            if (doc?.body) {
              doc.body.innerHTML =
                doc.body.innerHTML +
                `<style>
				::-webkit-scrollbar {
					padding: 4px 2px;
					width: 12px;
					height: 12px;
					border-radius: 12px;
			}
			::-webkit-scrollbar-thumb {
				background: #c1c9d2;
				border-radius: 12px;
				border: 2px solid transparent;
				background-clip: content-box;
		}
			</style>`;
            }
          }
        }}
        style={{ display: loading ? "none" : "" }}
        className="w-100 h-100"
        title="preview"
        ref={iframeRef}
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${src}`}
      ></iframe>
    </div>
  );
};

const OfficePreview = ({ src }) => {
  const resolvedSRC = useMemo(() => {
    return encodeURIComponent(src);
  }, [src]);
  return <OfficePreviewContent key={resolvedSRC} src={resolvedSRC} />;
};

export default OfficePreview;
