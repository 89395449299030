import React from "react";
import { ConnectedPipelineListItem } from "../Containers/AdvancedMultiInputs/PipelineDropdowns";
import { ConnectedAccountPopup } from "../Containers/Origin/AcountPopup";
import { ConnectedClientOrigin } from "../Containers/Origin/ClientOrigin";
import { ConnectedContactPopup } from "../Containers/Origin/ContactPopup";
import {
  spacePipelineSchema,
  spaceClientSchema,
  accountSchema,
  spaceContactSchema,
  spaceInterventionTypeSchema,
  spaceOrganizationSchema,
  spaceBillingSchema,
  spaceTasksSchema
} from "../config/schema";
import OriginOverview from "../Containers/Origin/OriginOverview";
import { ConnectedOrganizationProfile } from "../Components/Organizations/OrganizationProfile";
import { BillingStatusProfile } from "../Components/Billing/Billing";
import { faSuitcase } from "@fortawesome/pro-light-svg-icons";

export const GroupedByDictionary = {
  PipelineId: "Pipeline",
  "Task/PipelineId": "PipelineId",
  PipelineStatusId: "PipelineStatus",
  Origem: "Origem",
  "InterventionType/Name": "InterventionType",
  "TimeCharge/PlaceType": "PlaceType",
  "Client-IndirectClient/Name": "Client",
  "Account/Name": "Account",
  "Assigned/Name": "Manager",
  "ManagerAccount/Name": "ManagerAccount",
  Manager: "Manager",
  "DealClient/Name": "Client",
  "Manager/Name": "Manager/Name",
  "Client/Name": "Client",
  "Contact/Name": "Contact",
  "Creator/Name": "Creator",
  "Billing/Attributes": "Billing",
  "Company/Id": "Company",
  "Organization/Name": "Organization",
  "DealManager/Name": "DealManager",
  "Task/Name": "Name",
  "DealAssigned/Name": "Assigned",
  "Contact/Clients": "Clients",
  "Currency/EstimatedCost": "Value"
};

export const GroupedByDictionaryForFilterBar = {
  PipelineId: "PIPELINE",
  "Task/PipelineId": "PIPELINE",
  PipelineStatusId: "PIPELINE_STATUS",
  "Assigned/Name": "ASSIGNED_TO",
  "ManagerAccount/Name": "ASSIGNED_TO",
  Manager: "MANAGER",
  "Client/Name": "ACCOUNT",
  "Task/Name": "NAME",
  "Client-IndirectClient/Name": "ACCOUNT",
  "DealClient/Name": "ACCOUNT",
  Origem: "ORIGIN",
  "Contact/Name": "CONTACT",
  "Creator/Name": "CREATED_BY",
  "Account/Name": "USER",
  "TimeCharge/PlaceType": "LOCATION",
  "InterventionType/Name": "INTERVENTION_TYPE",
  "Billing/Attributes": "BILLING_STATUS",
  "Company/Id": "ORGANIZATION",
  "Manager/Name": "MANAGER",
  "DealManager/Name": "MANAGER",
  "DealAssigned/Name": "ASSIGNED_TO",
  "Organization/Name": "ORGANIZATION",
  "Contact/Clients": "ACCOUNTS",
  "Currency/EstimatedCost": "VALUE",
  "Conversation/LastMessageDate": "DATE"
};

export const GroupedByDictionaryForIcons = {
  "Client-IndirectClient/Name": faSuitcase
};

export const DifrentDict = {
  "Task/PipelineId": "PipelineId"
};

export const GroupedByOrderByDictionary = {
  Manager: "Assigned/Name",
  "Assigned/Name": "Assigned/Name",
  "Client/Name": "Client/Name",
  "DealAssigned/Name": "Assigned/Name",
  "ManagerAccount/Name": "Assigned/Name",
  "Task/Name": "Task/Name",
  "Manager/Name": "Assigned/Name",
  "Organization/Name": "Company/Name",
  "DealClient/Name": "Client/Name"
};

export const groupByColumnDict = {
  PipelineId: {
    Component: ({ Data }) => (
      <ConnectedPipelineListItem grid resolvedPipeline={Data} />
    ),
    schema: spacePipelineSchema
  },
  "Manager/Name": {
    Component: ({ Data }) => (
      <ConnectedAccountPopup account={Data ? Data.Id : Data} />
    ),
    schema: accountSchema
  },
  Manager: {
    Component: ({ Data }) => (
      <ConnectedAccountPopup account={Data ? Data.Id : Data} />
    ),
    schema: accountSchema
  },
  "Assigned/Name": {
    Component: ({ Data }) => (
      <ConnectedAccountPopup account={Data ? Data.Id : Data} />
    ),
    schema: accountSchema
  },
  "ManagerAccount/Name": {
    Component: ({ Data }) => (
      <ConnectedAccountPopup account={Data ? Data.Id : Data} />
    ),
    schema: accountSchema
  },
  "Client-IndirectClient/Name": {
    Component: ({ Data }) => (
      <ConnectedClientOrigin client={Data ? Data.Id : Data} />
    ),
    schema: spaceClientSchema
  },
  "Client/Name": {
    Component: ({ Data }) => (
      <ConnectedClientOrigin client={Data ? Data.Id : Data} />
    ),
    schema: spaceClientSchema
  },
  "DealClient/Name": {
    Component: ({ Data }) =>
      Data ? <ConnectedClientOrigin client={Data.Id} /> : null,
    schema: spaceClientSchema
  },
  "Billing/Attributes": {
    Component: ({ Data }) => {
      return <BillingStatusProfile status={Data && Data.Status} />;
    },
    schema: spaceBillingSchema
  },
  Origem: { Component: ({ Data }) => <OriginOverview entity={Data} /> },
  "Contact/Name": {
    Component: ({ Data }) => (
      <ConnectedContactPopup shownumber="true" contact={Data.Id} />
    ),
    schema: spaceContactSchema
  },
  "Creator/Name": {
    Component: ({ Data }) =>
      Data ? <ConnectedAccountPopup account={Data.Id} /> : null,
    schema: accountSchema
  },
  "Account/Name": {
    Component: ({ Data }) => (
      <ConnectedAccountPopup account={Data ? Data.Id : Data} />
    ),
    schema: accountSchema
  },
  "TimeCharge/PlaceType": {
    Component: ({ Data }) => {
      return <div>{Data === 1 ? "Local" : "Remoto"}</div>;
    },
    schema: null
  },
  "InterventionType/Name": {
    Component: ({ Data }) => {
      if (Data) return <div>{Data.Name}</div>;
      else return null;
    },
    schema: spaceInterventionTypeSchema
  },
  "Task/Name": {
    Component: ({ Data }) => {
      if (Data) return <div className="text-black fw-medium fs-14">{Data}</div>;
      else return null;
    },
    schema: spaceTasksSchema
  },
  "Company/Id": {
    Component: ({ Data }) => {
      if (Data) return <ConnectedOrganizationProfile organization={Data.Id} />;
      else return null;
    },
    schema: spaceOrganizationSchema
  },
  "DealManager/Name": {
    Component: ({ Data }) => (
      <ConnectedAccountPopup account={Data ? Data.Id : Data} />
    ),
    schema: accountSchema
  },
  "DealAssigned/Name": {
    Component: ({ Data }) => (
      <ConnectedAccountPopup account={Data ? Data.Id : Data} />
    ),
    schema: accountSchema
  },
  "Organization/Name": {
    Component: ({ Data }) => {
      return <ConnectedOrganizationProfile organization={Data.Id} />;
    },
    schema: spaceOrganizationSchema
  }
};
