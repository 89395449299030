import {
  useSpaceRoutesAccess,
  useSpaceMainView
} from "../../Containers/Space/SpaceHelper";
import React, { useMemo, useCallback, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import { HeaderLink, useHeaderActiveGroup } from "./HeaderV2";
import { useUnreadEntities } from "../UnreadEntities/UnreadEntities";
import Badge from "../Badge/Badge";
import { ObfuscateProvider } from "../../Containers/Space/Space2";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import Popup from "../Popup/Popup";
import { useIntl } from "react-intl";

const HeaderLinkGroupPopup = ({ children, active, anchorRef }) => {
  return (
    <Popup
      anchorEl={anchorRef.current}
      modifiers={{
        preventOverflow: { boundariesElement: "viewport" },
        flip: { enabled: false },
        offset: { offset: "0px, -2px" }
      }}
      placement="bottom-start"
      isOpen={active}
    >
      <div className={classnames("ar-header-group-popup", { active })}>
        {children}
      </div>
    </Popup>
  );

  // return (
  //   <div className={classnames("ar-header-group-popup", { active })}>
  //     {children}
  //   </div>
  // );
};

const HeaderLinkGroupSection = ({
  sectionRoutes,
  showSeperator,
  toggleActiveGroup
}) => {
  return (
    <>
      {showSeperator && <hr />}
      <ul>
        {sectionRoutes.map((route, i) => {
          return (
            <HeaderLink
              onClick={toggleActiveGroup}
              key={route.path}
              routeModule={route}
            />
          );
        })}
      </ul>
    </>
  );
};

const createResolvedSectionRoutes = (
  section,
  accessDict,
  viewDict,
  accountSpace
) => {
  const resolvedSectionRoutes = [];

  for (const route of section) {
    const { hasAccess } = route;

    if (typeof hasAccess === "function" && !hasAccess(accountSpace)) continue;
    else if (accessDict[route.path]) {
      resolvedSectionRoutes.push(route);
      viewDict[route.path] = true;
    }
  }
  return resolvedSectionRoutes;
};

const createResolvedGroup = (group, accessDict, accountSpace) => {
  const resolvedGroup = [];
  const viewDict = {};
  for (const section of group) {
    const sectionRoutes = createResolvedSectionRoutes(
      section,
      accessDict,
      viewDict,
      accountSpace
    );
    if (sectionRoutes.length !== 0) resolvedGroup.push(sectionRoutes);
  }
  return { resolvedGroup, viewDict };
};

export const useGroupUnreadCount = (groups) => {
  const data = useUnreadEntities();
  const totalCount = useMemo(() => {
    if (!data) return 0;

    let count = 0;

    for (const group of groups) {
      for (const route of group) {
        const { unreadEntityName } = route;
        if (!unreadEntityName) continue;

        const routeCount = data[`Unread${unreadEntityName}`];
        if (routeCount) count += routeCount;
      }
    }
    return count;
  }, [data, groups]);
  return totalCount;
};

const HeaderLinkButton = ({ text, group, originalGroup, viewDict }) => {
  const { activeGroup, toggleActiveGroup } = useHeaderActiveGroup();

  const isActive = activeGroup === originalGroup;

  const handleToggle = useCallback(() => {
    toggleActiveGroup(null);
  }, [toggleActiveGroup]);

  const toggle = useCallback(() => {
    toggleActiveGroup(originalGroup);
  }, [originalGroup, toggleActiveGroup]);

  const currentView = useSpaceMainView();

  const isSelected = viewDict[currentView];

  const hasActiveGroup = Boolean(activeGroup);
  const handleMouseEnter = useMemo(() => {
    if (!hasActiveGroup || isActive) return;

    return toggle;
  }, [toggle, hasActiveGroup, isActive]);

  const unreadCount = useGroupUnreadCount(group);

  const ObfuscateState = useContext(ObfuscateProvider);
  const handleClick = (e) => {
    if (ObfuscateState === true) e.preventDefault();
    else {
      toggle();
    }
  };
  const anchorRef = useRef();
  const intl = useIntl();
  return (
    // <HeaderActiveGroupContext.Provider value={}>
    <li className={isActive || isSelected ? "selected" : undefined}>
      <div
        ref={anchorRef}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        className="ar-header-link cursor-pointer"
      >
        <div>{text}</div>
        {Boolean(unreadCount) && (
          <div
            title={intl.formatMessage({ id: "NOT_READ" })}
            className="ssi-unread-count-pill ml-2"
          >
            {unreadCount}
          </div>
        )}
        <FontAwesomeIcon
          icon={faChevronDown}
          className="ar-header-group-arrow"
        />
      </div>
      {isActive && (
        <HeaderLinkGroupPopup active={true} anchorRef={anchorRef}>
          {group.map((sectionRoutes, i) => {
            return (
              <HeaderLinkGroupSection
                key={i}
                toggleActiveGroup={handleToggle}
                showSeperator={i !== 0}
                sectionRoutes={sectionRoutes}
              />
            );
          })}
        </HeaderLinkGroupPopup>
      )}
    </li>
  );
};

const HeaderLinkGroup = ({ text, group }) => {
  // const [] = useState(null);

  const accessDict = useSpaceRoutesAccess();
  const currentAccountSpace = useCurrentAccountSpace();

  // verifies permissions
  const { resolvedGroup, viewDict } = useMemo(() => {
    return createResolvedGroup(group, accessDict, currentAccountSpace);
  }, [accessDict, currentAccountSpace, group]);

  if (resolvedGroup.length === 0) return null;

  return (
    <HeaderLinkButton
      viewDict={viewDict}
      text={text}
      group={resolvedGroup}
      originalGroup={group}
    />
  );
};

export default HeaderLinkGroup;
