import React from "react";
import {
  useHistoryItem,
  HistoryHeader,
  HistoryTitle,
  HistoryContent,
  HistoryOverview,
  HistoryAccountProfile
} from "./HistoryItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { DetailsAccordion } from "../../DetailsView";
import ContactPopup from "../../../../Containers/Origin/ContactPopup";
import { FormattedMessage } from "react-intl";

const DealHistoryOverview = () => {
  const { Email, Account } = useHistoryItem();

  const { Subject, Cc, Contact } = Email;
  return (
    <>
      <HistoryOverview>
        <HistoryAccountProfile account={Account} />
        <span>
          {" "}
          <FormattedMessage id={"SENT_EMAIL"} />{" "}
        </span>
        {/* <b>{Subject}</b> <span>a </span> */}
      </HistoryOverview>

      <div className="d-flex mt-2 fs-14">
        <span className="text-black mr-2">
          <FormattedMessage id={"SUBJECT"} />:{" "}
        </span>
        <span className="breakWord text-black white-space-pre-line">
          {Subject}
        </span>
      </div>
      <div className="d-flex align-items-center mt-2 fs-14">
        <span className="text-black mr-2">
          <FormattedMessage id={"EMAIL_TO"} />:{" "}
        </span>
        <div>
          <ContactPopup contact={Contact} />
        </div>
      </div>

      {Cc && Cc.length > 0 && (
        <div className="d-flex align-items-center flex-wrap my-1 fs-14">
          <span className="text-black mr-2">Cc: </span>
          {/* <span>{Cc.join(", ")}</span> */}
          <div className="d-flex">
            {Cc.map((v, index) => {
              return <ContactPopup className="mr-2" key={index} contact={v} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};

export const EmailIcon = (props) => {
  return <FontAwesomeIcon icon={faEnvelope} {...props} />;
};

const EmailHistoryItem = () => {
  const { Email } = useHistoryItem();

  const { Id, Body } = Email;

  return (
    <>
      <HistoryHeader>
        <HistoryTitle className="link-color" Icon={EmailIcon}>
          <FormattedMessage id="EMAIL_SENT" />
        </HistoryTitle>
      </HistoryHeader>
      <HistoryContent>
        <DealHistoryOverview />
        <DetailsAccordion
          title="Mensagem"
          // isOpenByDefault={isOpenByDefault}
          // changesCount={resolvedProperties.length}
        >
          <iframe
            style={{ border: 0, width: "100%", minHeight: "350px" }}
            title="email"
            srcDoc={Body}
            // dangerouslySetInnerHTML={{
            //   __html: Body
            // }}
          ></iframe>
        </DetailsAccordion>
        {/* <div>Email</div>
        <div>{Receiver}</div>

        <div>hi</div> */}
      </HistoryContent>
    </>
  );
};

export default EmailHistoryItem;
