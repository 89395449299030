import React, { useState, useEffect } from "react";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent
} from "../../Components/Sidebar/SidebarV2Helper";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../Components/Forms/SideMenuForm";
import { useFormState } from "../../Components/Forms";
import { FormattedMessage } from "react-intl";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import moment from "moment";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";
import { ReportDatePicker, useReportState } from "../Reports/Reports";
import {
  DealPipelineDropdown,
  ContractPipelineDropdown,
  ProjectPipelineDropdown,
  TaskPipelineDropdown,
  TicketPipelineDropdown
} from "../AdvancedMultiInputs/PipelineDropdowns";

const SelectComponent = ({ item }) => {
  return (
    <div>
      <FormattedMessage id={item.title} />
    </div>
  );
};

export const handleIntervalCalculation = (interval, value, obj) => {
  switch (interval) {
		case "180DAYS":
			if (value === "toDate")
			return moment().format("YYYY-MM-DD");
		else return moment().subtract(180,"day").format("YYYY-MM-DD");

    case "INTERVAL_TODAY":
      if (value === "toDate")
        return moment().format("YYYY-MM-DD");
			else return moment().format("YYYY-MM-DD");
			
    case "INTERVAL_YESTERDAY":
      if (value === "fromDate")
        return moment().subtract(1, "day").format("YYYY-MM-DD");
			else return moment().format("YYYY-MM-DD");
			
    case "INTERVAL_THIS_WEEK":
      if (value === "fromDate")
        return moment().startOf("week").format("YYYY-MM-DD");
			else return moment().endOf("week").add(1, "day").format("YYYY-MM-DD");
			
    case "INTERVAL_LAST_WEEK":
      if (value === "fromDate")
        return moment()
				.subtract(1, "week")
				.startOf("week")
				.format("YYYY-MM-DD");
			else return moment()
			.subtract(1, "week")
			.add(1, "day")
			.endOf("week")
			.format("YYYY-MM-DD");
			
    case "INTERVAL_LAST_FIFTEEN_DAYS":
      if (value === "fromDate")
        return moment().subtract(15, "day").format("YYYY-MM-DD");
			else return moment().add(1, "day").format("YYYY-MM-DD");
			
    case "INTERVAL_THIS_MONTH":
      if (value === "fromDate")
        return moment().startOf("month").format("YYYY-MM-DD");
			else return moment().endOf("month").add(1, "day").format("YYYY-MM-DD");
			
    case "INTERVAL_LAST_MONTH":
      if (value === "fromDate")
        return moment()
				.subtract(1, "month")
				.startOf("month")
				.format("YYYY-MM-DD");
			else return moment()
			.subtract(1, "month")
			.endOf("month")
			.add(1, "day")
			.format("YYYY-MM-DD");
			
    case "INTERVAL_THIS_TRIMESTER":
      if (value === "fromDate")
        return moment().subtract(90, "days").format("YYYY-MM-DD");
			else return moment().add(1, "day").format("YYYY-MM-DD");
			
    case "INTERVAL_LAST_TRIMESTER":
      if (value === "fromDate")
        return moment().subtract(180, "days").format("YYYY-MM-DD");
			else return moment().subtract(91, "days").format("YYYY-MM-DD");
			
    case "INTERVAL_THIS_SEMESTER":
      if (value === "fromDate")
        return moment().subtract(180, "days").format("YYYY-MM-DD");
			else return moment().add(1, "day").format("YYYY-MM-DD");
			
    case "INTERVAL_LAST_SEMESTER":
      if (value === "fromDate")
        return moment().subtract(360, "days").format("YYYY-MM-DD");
			else return moment().subtract(181, "days").format("YYYY-MM-DD");
			
    case "INTERVAL_CUSTOM":
			if (value === "fromDate")
			return obj.value.fromDate;
			else return obj.value.toDate;
    default:
			return "";
  }
};

const CardIntervalInput = ({
  preview,
  name,
  onChange,
  className,
  placeholder,
  disableAll,
  value = {
    title: disableAll ? disableAll.title : "ALL",
    value: disableAll
      ? {
          fromDate: disableAll.fromDate,
          toDate: disableAll.toDate
        }
      : undefined
  },
  ...rest
}) => {
  const reportState = useReportState(
    null,
    null,
    value.title === "INTERVAL_CUSTOM" && value.value
  );

  const Options = [
    {
      title: disableAll ? disableAll.title : "ALL",
      value: disableAll
        ? {
            fromDate: disableAll.fromDate,
            toDate: disableAll.toDate
          }
        : undefined
    },
    {
      title: "INTERVAL_TODAY",
      value: {
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().add(1, "day").format("YYYY-MM-DD")
      }
    },
    {
      title: "INTERVAL_YESTERDAY",
      value: {
        fromDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD")
      }
    },
    {
      title: "INTERVAL_THIS_WEEK",
      value: {
        fromDate: moment().startOf("week").format("YYYY-MM-DD"),
        toDate: moment().endOf("week").add(1, "day").format("YYYY-MM-DD")
      }
    },
    {
      title: "INTERVAL_LAST_WEEK",
      value: {
        fromDate: moment()
          .subtract(1, "week")
          .startOf("week")
          .format("YYYY-MM-DD"),
        toDate: moment()
          .subtract(1, "week")
          .add(1, "day")
          .endOf("week")
          .format("YYYY-MM-DD")
      }
    },
    {
      title: "INTERVAL_LAST_FIFTEEN_DAYS",
      value: {
        fromDate: moment().subtract(15, "day").format("YYYY-MM-DD"),
        toDate: moment().add(1, "day").format("YYYY-MM-DD")
      }
    },
    {
      title: "INTERVAL_THIS_MONTH",
      value: {
        fromDate: moment().startOf("month").format("YYYY-MM-DD"),
        toDate: moment().endOf("month").add(1, "day").format("YYYY-MM-DD")
      }
    },
    {
      title: "INTERVAL_LAST_MONTH",
      value: {
        fromDate: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        toDate: moment()
          .subtract(1, "month")
          .endOf("month")
          .add(1, "day")
          .format("YYYY-MM-DD")
      }
    },
    {
      title: "INTERVAL_THIS_TRIMESTER",
      value: {
        fromDate: moment().subtract(90, "days").format("YYYY-MM-DD"),
        toDate: moment().add(1, "day").format("YYYY-MM-DD")
      }
    },
    {
      title: "INTERVAL_LAST_TRIMESTER",
      value: {
        fromDate: moment().subtract(180, "days").format("YYYY-MM-DD"),
        toDate: moment().subtract(91, "days").format("YYYY-MM-DD")
      }
    },
    {
      title: "INTERVAL_THIS_SEMESTER",
      value: {
        fromDate: moment().subtract(180, "days").format("YYYY-MM-DD"),
        toDate: moment().add(1, "day").format("YYYY-MM-DD")
      }
    },
    {
      title: "INTERVAL_LAST_SEMESTER",
      value: {
        fromDate: moment().subtract(360, "days").format("YYYY-MM-DD"),
        toDate: moment().subtract(181, "days").format("YYYY-MM-DD")
      }
    },
    {
      title: "INTERVAL_CUSTOM",
      value: {
        fromDate: reportState[0].fromDateString,
        toDate: reportState[0].toDateString
      }
    }
  ];

  const [enableCustomInterval, setEnableCustomInterval] = useState(
    value.title === "INTERVAL_CUSTOM"
  );

  const handleDropdownChanges = (item) => {
    if (item.title === "INTERVAL_CUSTOM") {
      setEnableCustomInterval(true);
      onChange(item);
    } else if (enableCustomInterval && item.title !== "INTERVAL_CUSTOM") {
      setEnableCustomInterval(false);
      onChange(item);
    } else {
      onChange(item);
    }
  };

  useEffect(() => {
    if (disableAll && value.title === "ALL") {
      onChange({
        title: disableAll ? disableAll.title : "ALL",
        value: disableAll
          ? {
              fromDate: disableAll.fromDate,
              toDate: disableAll.toDate
            }
          : undefined
      });
    } else if (enableCustomInterval) {
      onChange({
        title: "INTERVAL_CUSTOM",
        value: {
          fromDate: reportState[0].fromDateString,
          toDate: reportState[0].toDateString
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableCustomInterval, disableAll, onChange, reportState[0]]);

  return (
    <div>
      <div className="mb-2">
        <KeyedDropdown
          getKey={(e) => (typeof e === "object" ? e.title : e)}
          closeOnSelect
          valueComponent={SelectComponent}
          onChange={handleDropdownChanges}
          value={value}
          options={Options}
        />
      </div>
      {enableCustomInterval && (
        <ReportDatePicker
          showLabels={true}
          className="mr-3"
          state={reportState}
        />
      )}
    </div>
  );
};

const getDropdownType = (type) => {
  switch (type) {
    case 1:
      return ProjectPipelineDropdown;
    case 2:
      return TicketPipelineDropdown;

    case 3:
      break;
    case 4:
      return TaskPipelineDropdown;
    case 5:
      return ContractPipelineDropdown;

    case 6:
      return DealPipelineDropdown;
    // case 1:

    // break;
    default:
      break;
  }
};

const DashboardCardSettingsForm = ({
  lS_Group,
  lS_Field,
  Title,
  Settings,
  pipelineType,
  disableAll,
  closeSidebar,
  handleChanges,
  AvailableSettings
}) => {
  const formState = useFormState(() => {
    const form = {};
    if (AvailableSettings.interval)
      form.Interval = {
        title: "ALL"
      };
    // if (AvailableSettings.scope) form.Scope = "";
    if (AvailableSettings.pipeline) form.Pipeline = "";
    return { ...form, ...Settings };
  });

  const handleSubmit = (form) => {
    const lS_Data = JSON.parse(localStorage.getItem("rgl-7"));
    lS_Data[lS_Group].find((e) => e.i === lS_Field).Settings = form;

    const DashboardSettings =
      JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
    if (DashboardSettings[lS_Group]) {
      DashboardSettings[lS_Group][lS_Field] = { ...form };
    } else {
      DashboardSettings[lS_Group] = {};
      DashboardSettings[lS_Group][lS_Field] = { ...form };
    }
    localStorage.setItem(
      "ar-dashboard-settings",
      JSON.stringify(DashboardSettings)
    );
    handleChanges(lS_Data[lS_Group]);
    closeSidebar();
  };

  const TypePipelineDropdown = getDropdownType(pipelineType);

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"SETTINGS"} /> - <FormattedMessage id={Title} />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideForm canEdit={true} formState={formState} onSubmit={handleSubmit}>
          <SideFormContent className="px-3 pt-3 pb-5">
            {AvailableSettings.interval && (
              <FormInput
                className="mb-3"
                disableAll={disableAll}
                name="Interval"
                text={<FormattedMessage id={"INTERVAL"} />}
                inputType={CardIntervalInput}
              />
            )}
            {AvailableSettings.pipeline && (
              <FormInput
                className="mb-3"
                disableAll={disableAll}
                name="Pipeline"
                text={<FormattedMessage id={"PIPELINE"} />}
                inputType={TypePipelineDropdown}
              />
            )}
          </SideFormContent>
          <SideFormFooter></SideFormFooter>
        </SideForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
  // if (loading || error)
  //   return <LoaderSpinner className="text-primary" size="sm" />;

  // return <PipelineFormContent {...props} pipeline={pipeline} />;
};

export default DashboardCardSettingsForm;
