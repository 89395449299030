import React, { useEffect, useState, useContext, useMemo } from "react";
// import { ConnectionStatusEnum } from "./RealTimeHelper";
// import signalR from '@microsoft/signalr';
import { signalUrl } from "../../config";
// import signalR from "../EntitySynchronizer/SignalR";
// const signalR = require("@microsoft/signalr");
// const signalRMsgPack = require("@microsoft/signalr-protocol-msgpack");

import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
  HttpTransportType
} from "@microsoft/signalr";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import { connect } from "react-redux";
import api from "../../api";

const handleTestToken = (assignedSpace) => {
  return api.get(`/Spaces/${assignedSpace}/AirToken`);
  // const dataPromise = promise.then((response) => response.data);

  // return promise;

  // api.get(`/Spaces/${assignedSpace}/AirToken`).catch((e) => {
  // 		api.post(`/Spaces/1/Logs`, {
  // 			Type: 1,
  // 			Category: "SignalR",
  // 			Message:
  // 				JSON.stringify(e) +
  // 				`\n URL: "/Spaces/${assignedSpace}/AirToken"`
  // 		});
  // 	});
};

const useHubConnectionSignalR = (
  assignedSpace,
  connectionHub,
  setConnectionHub,
  setConnectionStatus,
  isAuthenticated
) => {
  useEffect(() => {
    if (!connectionHub && assignedSpace && isAuthenticated) {
      setConnectionHub(() => {
        const ConnectionHub = new HubConnectionBuilder()
          // .withUrl(`${signalUrl}/signalr`, {
          .withUrl(`${signalUrl}/signalr?spaceId=${assignedSpace}`, {
            // skipNegotiation: true,
            accessTokenFactory: () =>
              handleTestToken(assignedSpace).catch(() => ""),
            transport: HttpTransportType.WebSockets
          })
          .withAutomaticReconnect()
          .configureLogging(LogLevel.None)
          .withHubProtocol(new MessagePackHubProtocol())
          .build();

        ConnectionHub.start()
          .then(() => {
            // console.log("Connection established");
            setConnectionStatus(HubConnectionState.Connected);
          })
          .catch((error) => {
            // console.log("Error establishing the connection: ", error);
            setConnectionStatus(HubConnectionState.Disconnected);

            sleep(5000).then(() => setConnectionHub(null));
          });

        ConnectionHub.onreconnecting((error) => {
          // console.log("Reconnecting");
          setConnectionStatus(HubConnectionState.Reconnecting);
        });

        ConnectionHub.onreconnected((connectionId) => {
          // console.log("Reconnected: ", connectionId);
          setConnectionStatus(HubConnectionState.Connected);
        });

				ConnectionHub.on("")

        ConnectionHub.onclose((connectionId) => {
          // console.log("Connection closed: ", connectionId);
          setConnectionStatus(HubConnectionState.Disconnected);
          setConnectionHub(null);
        });
        return ConnectionHub;
      });
    }
  }, [
    assignedSpace,
    connectionHub,
    isAuthenticated,
    setConnectionHub,
    setConnectionStatus
  ]);
};

// const useSignalR = (isAuthenticated, loginState, assignedSpace, signalRToken) => {
//   useEffect(() => {
//     if (loginState && assignedSpace) {
//       // import(/* webpackChunkName: "signalr" */ "./SignalR").then(
//       //   ({ default: signalr }) => {
//       //     setValue(signalr);
//       //   }
//       // );

//       CreateHubConnectionSignalR(assignedSpace);
//     }
//   }, [assignedSpace, loginState, signalRToken]);
// };

const startConnection = async (
  connection,
  entitiesHub,
  setReconnecting,
  onEnd
) => {
  // entitiesHub.on("dealEntity", function (dealId, user, isCreation) {});

  try {
    // console.log(connection);

    await connection.start();

    onEnd(connection.state);
  } catch (error) {
    // console.log(error);

    onEnd(connection.state);
  }
};

export const sendSignalRMessage = async (
  entitiesHub,
  msg,
  userId,
  // tkn,
  space,
  onDone,
  onError
) => {
  try {
    if (!entitiesHub) return;
    await entitiesHub.invoke(msg, userId, space); //, tkn);

    onDone && onDone();
  } catch (error) {
    onError && onError(error);
  }
};

export const sendSignalRSimple = async (
  entitiesHub,
  msg,
  value,
  onDone,
  onError
) => {
  try {
    if (!entitiesHub) return;
    await entitiesHub.invoke(msg, value);
    onDone && onDone();
  } catch (error) {
    onError && onError(error);
  }
};

export const sendSignalRAdvance = async (
  entitiesHub,
  msg,
  value1,
  value2,
  value3,
  onDone,
  onError
) => {
  try {
    if (!entitiesHub) return;
    await entitiesHub.invoke(msg, value1, value2, value3);
    onDone && onDone();
  } catch (error) {
    onError && onError(error);
  }
};

const ConnectionContext = React.createContext();

const ConnectionIdContext = React.createContext();

const EntitiesHubContext = React.createContext();

const ConnectionStatusContext = React.createContext();

const ReconnectionStatusContext = React.createContext();

const ReconnectContext = React.createContext();

const ConnectionReadyContext = React.createContext();

export const useConnectionReadyState = () => useContext(ConnectionReadyContext);

export const useReconnectionStatus = () =>
  useContext(ReconnectionStatusContext);

export const useConnectionStatus = () => useContext(ConnectionStatusContext);

export const useConnectionId = () => useContext(ConnectionIdContext);

export const withConnectionId = (WrappedComponent) => {
  function WithConnectionId(rest) {
    const conId = useConnectionId();
    return <WrappedComponent connectionId={conId} {...rest} />;
  }

  return WithConnectionId;
};

export const useEntitiesHub = () => useContext(EntitiesHubContext);

export const useReconnect = () => useContext(ReconnectContext);

const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

const RealTimeProviderComp = ({
  children,
  isAuthenticated,
  assignedSpace,
  signalRToken
}) => {
  const [connectionHub, setConnectionHub] = useState(null);
  const [connectionStatus, setconnectionStatus] = useState(
    HubConnectionState.Disconnected
  );

  useHubConnectionSignalR(
    assignedSpace,
    connectionHub,
    setConnectionHub,
    setconnectionStatus,
    isAuthenticated
  );

  // const [reconnecting, setReconnecting] = useState(false);

  // const [ready, setReady] = useState(true);

  // const connection = useMemo(() => {
  //   if (!ConnectionHub) return;
  //   if (HubStatus === HubConnectionState.Connected) return signalR;
  // }, []);

  // const connectionId = useMemo(() => {
  //   if (!signalR) return;

  //   if (connectionStatus === HubConnectionState.Connected)
  //     return signalR.connection.connectionId;
  // }, [connectionStatus, signalR]);

  // const reconnect = () => {
  //   // if (!signalR) return;
  //   // if (connectionStatus === HubConnectionState.Disconnected) {
  //   //   return () => {
  //   //     setConnectionStatus(HubConnectionState.Reconnecting);
  //   //     startConnection(
  //   //       signalR,
  //   //       undefined,
  //   //       setReconnecting,
  //   //       (connectionState) => {
  //   //         setConnectionStatus(connectionState);
  //   //       }
  //   //     );
  //   //   };
  // };

  // // if (signalR.status === HubConnectionState.) {
  // //   return () => {
  // //     setConnectionStatus(HubConnectionState.Connecting);
  // //     startConnection(
  // //       signalR,
  // //       undefined,
  // //       setReconnecting,
  // //       (connectionState) => {
  // //         setConnectionStatus(connectionState);
  // //       }
  // //     );
  // //   };
  // // }

  // useEffect(() => {
  //   if (!reconnect) return;

  //   // const listener = () => {
  //   //   // console.log(document.visibilityState);
  //   //   if (document.visibilityState === "visible") reconnect();
  //   // };

  //   // document.addEventListener("visibilitychange", listener);
  //   // return () => document.removeEventListener("visibilitychange", listener);
  // }, [reconnect]);

  // const hasConnection = Boolean(connectionHub);

  const reconect = () => {};

  return (
    <ConnectionReadyContext.Provider
      value={connectionStatus === HubConnectionState.Connected}
    >
      <ConnectionContext.Provider
        value={connectionStatus === HubConnectionState.Connected}
      >
        <ConnectionIdContext.Provider
          value={connectionHub?.connection?.connectionId}
        >
          <ReconnectionStatusContext.Provider
            value={
              connectionStatus === HubConnectionState.Reconnecting ||
              connectionStatus === HubConnectionState.Disconnected
            }
          >
            <ConnectionStatusContext.Provider value={connectionStatus}>
              <ReconnectContext.Provider value={reconect}>
                <EntitiesHubContext.Provider
                  value={
                    connectionStatus === HubConnectionState.Connected
                      ? connectionHub
                      : undefined
                  }
                  // value={hasConnection ? signalR : undefined}
                >
                  {children}
                </EntitiesHubContext.Provider>
              </ReconnectContext.Provider>
            </ConnectionStatusContext.Provider>
          </ReconnectionStatusContext.Provider>
        </ConnectionIdContext.Provider>
      </ConnectionContext.Provider>
    </ConnectionReadyContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    assignedSpace: state.Pages.SpaceSetup.AssignedSpace,
    signalRToken: state.Pages.Login.SignalRToken
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     onTodoClick: id => {
//       dispatch(toggleTodo(id))
//     }
//   }
// }

export const RealTimeProvider = connect(
  mapStateToProps
  // mapDispatchToProps
)(RealTimeProviderComp);
