import React from "react";
import { useTask, useAccount } from "../../../../Hooks/EntityHooks";
import {
  GeneralNotificationItem,
  NotificationClientProfile
} from "./NotificationHelper";
import { FormattedMessage } from "react-intl";
import TaskOrigin from "../../../Origin/TaskOrigin";

const NotificationManagerDiscardTask = ({ task, notf }) => {
  const resolvedTask = task;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  const { Client, IndirectClient } = resolvedTask;
  return (
    <GeneralNotificationItem
      OriginComponent={<TaskOrigin task={task} />}
      // redirectUrl={buildNotificationEndpoint("tasks", "Task/Id", task)}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"TASK_REMOVED"} /> <b>{resolvedTask.Name}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationManagerDiscardItem = ({ value }) => {
  const { Task } = value;

  if (Task) return <NotificationManagerDiscardTask notf={value} task={Task} />;
  else return null;
};

export default NotificationManagerDiscardItem;
