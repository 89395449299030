import {
  faEnvelope,
  faFileContract,
  faFileImport,
  faHandshake,
  faSquareParking,
  faPhone,
  faPhoneAlt,
  faSuitcase,
  faTicketSimple,
  faUserClock
} from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ConnectedClientProfileStack } from "../../../Components/Accounts/AccountProfileStack";
import Button from "../../../Components/Button/Button";
import LoadableButton from "../../../Components/Button/LoadableButton";

import ContactProfile, {
  ContactStatusProfile
} from "../../../Components/Contacts/ContactProfile";

import {
  cacheType,
  useSpacePost,
  useSpacePostQuery
} from "../../../Helpers/IOClient";

import classnames from "classnames";
import { OriginItemGroup } from "../../../Components/Origin/Origin";
import { FormattedPhoneNumber } from "../../../Components/Input/PhoneInput";

import { buildProjectStatusType } from "../../../Components/Projects/ProjectProfile";

import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";

import { useToast } from "../../../Components/Toast/ToastProvider";
import { handleError } from "../../../Helpers/MiscHelper";
import { useConnectionId } from "../../RealTime/RealTime";
import { useGridRequest } from "../../../Components/CGrid/ServerGrid";

const ContactsMergeAlteredItemsComponent = ({
  icon,
  title,
  number,
  description
}) => {
  if (number === 0) {
    return null;
  }

  return (
    <div className="p-2 bg-airdesk border merge-altered-element-box">
      <div className="d-flex align-items-center mb-2">
        <FontAwesomeIcon icon={icon} className="mr-2 text-black" />
        <div className="text-black fw-medium fs-20">{title}</div>
      </div>
      <div>
        <span className="text-black fs-12 fw-medium">{number} </span>
        <span className="text-grey-light fs-12">{description}</span>
      </div>
    </div>
  );
};

const ContactsMergeThirdPage = ({
  finalContact,
  handleClose,
  handlePrevious,
  selectedGridContacts,
  ClearSelection
}) => {
  const intl = useIntl();
  const { refetch } = useGridRequest();
  const resolvedOtherContacts = useMemo(() => {
    return {
      OtherContactIds: selectedGridContacts.filter((e) => e !== finalContact.Id)
    };
  }, [finalContact.Id, selectedGridContacts]);
  const conId = useConnectionId();
  const createToast = useToast();

  const {
    data,
    loading: loadingFusionData,
    error: fusionDataError
  } = useSpacePostQuery(
    `Contacts/${finalContact.Id}/MergeCounts`,
    resolvedOtherContacts,
    null,
    {
      cache: cacheType.disabled
    }
  );

  const [postMerge, { loading }] = useSpacePost(
    `Contacts/${finalContact.Id}/Merge`,
    null,
    {
      headers: {
        ConnectionID: conId
      },
      onSuccess: ({ data }) => {
        ClearSelection();
        refetch();
        handleClose();
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  const handleSubmit = () => {
    postMerge({
      Name: finalContact.Name,
      Email: finalContact.Email,
      MobileNumber: finalContact.MobileNumber,
      PhoneNumber: finalContact.PhoneNumber,
      Description: finalContact.Description,
      OtherContactIds: resolvedOtherContacts.OtherContactIds
    });
  };

  const { Name, Email, MobileNumber, PhoneNumber, Clients, Id, Status } =
    finalContact;

  const {
    Calls,
    Contracts,
    Deals,
    Projects,
    Subscriptions,
    Tickets,
    TimeCharges
  } = data || {};

  return (
    <div className={classnames("d-flex flex-column h-100 of-hidden w-100")}>
      <div className="d-flex justify-content-center text-black fs-20 mb-2">
        <FormattedMessage id="CONFIRM_CONTACT_INFORMATION" />
      </div>
      <div className="d-flex justify-content-center text-black fs-14 mb-2">
        <FormattedMessage id="CONFIRM_CONTACT_INFORMATION_DESCRIPTION" />
      </div>
      {(loadingFusionData || fusionDataError) && (
        <div className="d-flex justify-content-center text-color-link mt-4">
          <LoaderSpinner size="sm" center />
        </div>
      )}
      {data && (
        <div className="d-flex mt-2">
          <div
            style={{ width: 590, borderRadius: 5 }}
            className="border of-hidden"
          >
            <div className="bg-white">
              <div className="mx-3 mt-2 mb-2 d-flex align-items-center">
                <ContactProfile
                  size="md"
                  justIcon
                  disableName
                  contact={finalContact}
                />
                <div className="ml-2">
                  <div className="text-black fw-medium fs-16">{Name}</div>
                  <div style={{ color: "#7c98b6" }} className="fs-12 fw-medium">
                    <FormattedMessage id="CONTACT" /> {Id}
                  </div>
                </div>
              </div>

              <div className="mx-3 mb-1 fs-14">
                <div className="mb-1 d-flex align-items-center justify-content-between">
                  <div
                    className={`fw-medium fs-12 text-${buildProjectStatusType(
                      Status
                    )}`}
                  >
                    <ContactStatusProfile
                      status={Status}
                    ></ContactStatusProfile>
                  </div>
                </div>
              </div>

              {Clients && (
                <OriginItemGroup
                  icon={faSuitcase}
                  className="mb-1"
                  title={<FormattedMessage id={"ASSOCIATED_ACCOUNTS"} />}
                >
                  <ConnectedClientProfileStack clients={Clients} />
                </OriginItemGroup>
              )}

              {Boolean(Email) && (
                <OriginItemGroup
                  icon={faEnvelope}
                  className="mb-1"
                  title="Email"
                >
                  <div className="text-black fs-12">
                    <a href={`mailto:${Email}`}>{Email}</a>
                  </div>
                </OriginItemGroup>
              )}

              {Boolean(PhoneNumber) && (
                <OriginItemGroup
                  icon={faPhone}
                  className="mb-1"
                  title={<FormattedMessage id={"PHONE_NUMBER"} />}
                >
                  <div className="text-black fs-12">
                    <FormattedPhoneNumber number={PhoneNumber} />
                  </div>
                </OriginItemGroup>
              )}

              <OriginItemGroup
                icon={faPhone}
                show={Boolean(MobileNumber)}
                title={<FormattedMessage id={"MOBILE_NUMBER"} />}
              >
                <span className="text-black fs-12">
                  <FormattedPhoneNumber number={MobileNumber} />
                </span>
              </OriginItemGroup>
            </div>
          </div>
          <div className="d-inline-flex flex-wrap merge-altered-element-container">
            <ContactsMergeAlteredItemsComponent
              icon={faHandshake}
              description={intl.formatMessage({
                id: "MERGE_CONTACTS_ALTERED_DEALS_DESCRIPTION"
              })}
              title={intl.formatMessage({ id: "DEALS" })}
              number={Deals}
            />
            <ContactsMergeAlteredItemsComponent
              icon={faFileContract}
              description={intl.formatMessage({
                id: "MERGE_CONTACTS_ALTERED_CONTRACTS_DESCRIPTION"
              })}
              title={intl.formatMessage({ id: "CONTRACTS" })}
              number={Contracts}
            />
            <ContactsMergeAlteredItemsComponent
              icon={faFileImport}
              description={intl.formatMessage({
                id: "MERGE_CONTACTS_ALTERED_SUBSCRIPTIONS_DESCRIPTION"
              })}
              title={intl.formatMessage({ id: "SUBSCRIPTIONS" })}
              number={Subscriptions}
            />
            <ContactsMergeAlteredItemsComponent
              icon={faPhoneAlt}
              description={intl.formatMessage({
                id: "MERGE_CONTACTS_ALTERED_CALLS_DESCRIPTION"
              })}
              title={intl.formatMessage({ id: "CALLS" })}
              number={Calls}
            />
            <ContactsMergeAlteredItemsComponent
              icon={faSquareParking}
              description={intl.formatMessage({
                id: "MERGE_CONTACTS_ALTERED_PROJECTS_DESCRIPTION"
              })}
              title={intl.formatMessage({ id: "PROJECTS" })}
              number={Projects}
            />
            <ContactsMergeAlteredItemsComponent
              icon={faTicketSimple}
              description={intl.formatMessage({
                id: "MERGE_CONTACTS_ALTERED_CASES_DESCRIPTION"
              })}
              title={intl.formatMessage({ id: "CASES" })}
              number={Tickets}
            />
            <ContactsMergeAlteredItemsComponent
              icon={faUserClock}
              description={intl.formatMessage({
                id: "MERGE_CONTACTS_ALTERED_TIMES_DESCRIPTION"
              })}
              title={intl.formatMessage({ id: "TIMES" })}
              number={TimeCharges}
            />
          </div>
        </div>
      )}

      <div className="align-items-end d-flex flex-1 justify-content-between mt-3">
        <Button
          className="p-1 mr-3"
          vType="primary-ghost"
          onClick={() => {
            handlePrevious();
          }}
        >
          <span>
            <FormattedMessage id="PREVIOUS" />
          </span>
        </Button>
        <div className="d-flex">
          <Button
            className="p-1 mr-3"
            vType="link-danger"
            onClick={() => {
              handleClose();
            }}
          >
            <span>
              <FormattedMessage id="CANCEL" />
            </span>
          </Button>

          <LoadableButton
            isLoading={loading}
            disabled={loadingFusionData || fusionDataError}
            vType="primary"
            className="p-1"
            onClick={handleSubmit}
          >
            <span className="mr-2">
              <FormattedMessage id="CONFIRM" />
            </span>
          </LoadableButton>
        </div>
      </div>
    </div>
  );
};

export default ContactsMergeThirdPage;
