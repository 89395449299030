import React from "react";
import { VirtualGridColumnProps } from "./VirtualGridHelper";

interface VirtualGridFooterProps {
  columns: VirtualGridColumnProps[];
}

const FooterCell = ({ column }: { column: VirtualGridColumnProps }) => {
  const { TotalCell } = column;

  return <td>{TotalCell ? <TotalCell /> : null}</td>;
};

export const VirtualGridFooter = (props: VirtualGridFooterProps) => {
  const { columns } = props;

  return (
    <tfoot>
      <tr>
        {columns.map((c, i) => {
          return <FooterCell key={i} column={c} />;
        }, [])}
        <td />
      </tr>
    </tfoot>
  );
};
