import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useFormState, createFormRequestHook } from "../../Components/Forms";
import {
  spaceContractSchema,
  spaceOrganizationSchema,
  spacePipelineSchema,
  spaceDealsSchema,
  spaceClientSchema,
  spacePipelineStatusSchema
} from "../../config/schema";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../Components/Forms/SideMenuForm";
import {
  defaultContractForm,
  validateContractForm,
  convertContractEntityToForm,
  formatContractForm
} from "./ContractsHelper";
import { entityActionType, originTypes } from "../../Helpers/MiscHelper";
import { useToast } from "../../Components/Toast/ToastProvider";
import { useContract } from "../../Hooks/EntityHooks";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";
import { ContractStatusTypes } from "../../Components/Contract/ContractProfile";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";
import {
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import { useSpaceCurrenciesFetcher } from "../../Components/Currency/Currency";
import {
  isOrganzationRequestValid,
  OrganizationRequestSideError
} from "../../Components/Organizations/OrganizationHelper";
import { useHandleError } from "../../Components/CGrid/ServerAwareHelper";
import { contractCommunicator } from "./ContractCommunicatorHelper";
import ContractFormContent from "./ContractFormContent";
import {
  FormTemplateProvider,
  useFormTemplateGetter
} from "../../Components/Forms/FormTemplatesHelper";

const useContractRequest = createFormRequestHook({
  schema: spaceContractSchema,

  dependencies: [
    {
      schema: spaceClientSchema,
      getEntityId: ({ clientId }) => clientId,
      convertEntityToForm: (entity, props) => {
        const { Id, Files, Manager } = entity;
        return {
          ContractTarget: {
            type: "Client",
            data: Id
          },
          Manager: Manager,
          AssociatedFiles: Files
        };
      }
    },

    // {
    //   schema: spaceClientSchema,
    //   getEntityId: ({ clientId }) => clientId,
    //   convertEntityToForm: (entity, props) => {
    //     const { Id } = entity;
    //     return {
    //       Client: Id
    //     };
    //   }
    // },
    {
      schema: spaceDealsSchema,
      getEntityId: ({ dealId }) => dealId,
      convertEntityToForm: (entity, props) => {
        const { Id, Contact, Company, Files } = entity;
        return {
          ContractTarget: {
            type: originTypes.deals,
            data: Id
          },
          Contact,
          AssociatedFiles: Files,
          Company
        };
      }
    }
  ],
  convertEntityToForm: convertContractEntityToForm,
  formatDuplicationEntity: (entity) => {
    const { PipelineStatus } = entity;
    if (PipelineStatus) {
      const pipelineStatusEntity = getEntity(
        spacePipelineStatusSchema,
        PipelineStatus
      );
      if (pipelineStatusEntity.Status !== ContractStatusTypes.open) {
        entity.PipelineStatus = null;
      }
    }

    entity.IsSigned = false;

    return entity;
  }
});

const ContractForm = (props) => {
  const { key, loading, error, convertedForm, isChild, childProps } =
    useContractRequest(props);

  const { id, defaultForm, onSuccess, className } = props;
  const contract = useContract(id);

  const {
    loading: loadingCurrencies,
    // error: errorCurrencies,
    data: currencies
  } = useSpaceCurrenciesFetcher();

  const orgState = useSpaceQuery(`query/organizations`, [
    spaceOrganizationSchema
  ]);

  const {
    loading: loadingPipelines,
    data: pipelines,
    error: errorPipelines
  } = useSpaceQuery(`query/pipelines?type=${PipelineTypeEnum.Contract}`, [
    spacePipelineSchema
  ]);

  const { loading: loadingOrganizations, data: organizations } = orgState;

  const resolvedForm = useMemo(() => {
    if (!currencies) return;
    let form = {
      ...defaultForm,
      ...convertedForm
    };

    const { EstimatedCosts, EstimatedCostsHour } = form;
    if (EstimatedCosts) {
      const EstimatedCostsDict = {};

      for (const val of EstimatedCosts) {
        EstimatedCostsDict[val.CurrencyId] = val;
      }

      for (const curr of currencies) {
        if (!EstimatedCostsDict[curr]) {
          EstimatedCostsDict[curr] = {
            Cost: 0,
            CurrencyId: curr
          };
        }
      }

      form = {
        ...form,
        EstimatedCostsDict
      };
    }
    if (EstimatedCostsHour) {
      const EstimatedCostsHourDict = {};

      for (const val of EstimatedCostsHour) {
        EstimatedCostsHourDict[val.CurrencyId] = val;
      }

      for (const curr of currencies) {
        if (!EstimatedCostsHourDict[curr]) {
          EstimatedCostsHourDict[curr] = {
            Cost: 0,
            CurrencyId: curr
          };
        }
      }

      form = {
        ...form,
        EstimatedCostsHourDict
      };
    }
    if (childProps) {
      form = { ...form, ...childProps };
    }

    if (
      !id &&
      organizations &&
      organizations.findIndex((e) => e === form.Company) === -1
    ) {
      form.Company = organizations[0];
    }

    if (form.TransferInboundEmail && Array.isArray(form.InboundEmails)) {
      form.CurrentInboundEmails = [...form.InboundEmails];

      form.IsSigned = false;
    }

    return form;
  }, [currencies, defaultForm, convertedForm, childProps, id, organizations]);

  const { data: formTemplates, refetch: formTemplateRefetch } =
    useFormTemplateGetter(spaceContractSchema);

  if (
    loadingPipelines ||
    errorPipelines ||
    loading ||
    error ||
    loadingOrganizations ||
    loadingCurrencies
  )
    return <LoaderSpinner center size="sm" className="text-secondary" />;

  if (!isOrganzationRequestValid(orgState))
    return <OrganizationRequestSideError requestState={orgState} />;

  const canEdit = contract ? contract.CanEdit : true;

  return (
    <FormTemplateProvider forms={formTemplates} refetch={formTemplateRefetch}>
      <ContractFormBase
        className={className}
        onSuccess={onSuccess}
        canDelete={contract?.CanDelete}
        id={id}
        pipelines={pipelines}
        canEdit={canEdit}
        key={key}
        isChild={isChild}
        convertedForm={resolvedForm}
        organizations={organizations}
        currencies={currencies}
      />
    </FormTemplateProvider>
  );
};

const ContractFormBase = ({
  convertedForm,
  id,
  canEdit,
  onSuccess,
  canDelete,
  isChild,
  pipelines,
  organizations,
  className,
  currencies
}) => {
  const formState = useFormState(() => {
    return {
      ...defaultContractForm({ currencies }),
      ...convertedForm
    };
  });
  const { form, handleChange, setForm } = formState;
  const {
    // ContractTarget,
    // Description,
    // Contact,
    // EndDate,
    // EstimatedCost,
    // SalesOpportunity,
    // Manager,
    // // ContractType,
    // InternalDescription,
    // Covenant,
    // Files,
    // Deslocations,
    // Creator,
    // Pipeline,
    // PipelineStatus,
    Company
    // StatusDetailDescription,
    // BeginDate,
    // StatusDetail,
    // // Comments,
    // CreationDate,
    // TeamCollab,
    // Followers,
    // Client,
    // IndirectClient
  } = form;
  const intl = useIntl();
  // const PipelineId = Pipeline;
  // const PipelineStatusId = PipelineStatus;
  // const PipelieStatusObj = usePipelineStatus(PipelineStatusId);
  const createToast = useToast();
  const handleTranslatedError = useHandleError(spaceContractSchema);
  // useMarkRead(spaceContractSchema, id, Manager);
  const [post, { loading }] = useSidebarSpacePost(
    id ? `contracts/${id}` : "contracts",
    spaceContractSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        if (!id) {
          defineChildProps({ Client: newId });
          contractCommunicator.dispatch({
            type: entityActionType.creation,
            id: newId
          });
        } else {
          contractCommunicator.dispatch({ type: entityActionType.update, id });
        }

        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "CONTRACT" })} nº ${newId || id}`,
          description: !id
            ? `${intl.formatMessage({ id: "CREATED_SUCCEFULLY" })}`
            : `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `contracts/${id}`,
    spaceContractSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "CONTRACT" })} nº ${id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const handleSubmit = (form) => {
    post(formatContractForm(form, currencies));
  };

  if (organizations.length === 1 && !Company) {
    handleChange({
      Company: organizations[0]
    });
  }

  return (
    <SideForm
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateContractForm}
    >
      <SideFormContent className={className}>
        <ContractFormContent
          canEdit={canEdit}
          formState={formState}
          id={id}
          organizations={organizations}
          pipelines={pipelines}
          currencies={currencies}
        />
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        deleteLoading={deleteLoading}
        form={form}
        handleChange={setForm}
        schema={spaceContractSchema}
        handleDelete={remove}
        isLoading={loading}
      />
    </SideForm>
  );
};

export default ContractForm;

// class ContractForm extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       AssignedTo: null
//     };
//   }

//   handleChange = e => {
//     const { setFormState } = this.props;
//     const { name, value } = e.target;

//     setFormState({
//       [name]: value
//     });
//   };

//   handleClientChange = e => {
//     const { setFormState } = this.props;
//     const { name, value } = e.target;

//     setFormState({
//       [name]: value,
//       Contact: null
//     });
//   };

//   render() {
//     const { formStatus, form } = this.props;
//     const {
//       Client,
//       Description,
//       Contact,
//       EndDate,
//       EstimatedCost,
//       SalesOpportunity,
//       Manager,
//       ContractType,
//       InternalDescription,
//       Covenant,
//       Files,
// 	  Deslocations,
// 	  Creator
//     } = form;
//     const { id } = formStatus;
//     const intl = this.props.intl;

//     return (
//       <SideMenuForm formStatus={formStatus}>
//         <div className="d-flex align-items-center my-2 flex-0-0-auto">
//           <FontAwesomeIcon className="mr-2" icon={faSuitcase} />
//           <span className="fs-14 fw-medium text-black">
//             {intl.formatMessage({ id: "customer" })}
//           </span>
//           <span className="title-id">{this.state.ClientId}</span>
//         </div>
//         <FormInput
//           className="mb-3"
//           name={"Client"}
//           inputType={ClientDropdown}
//           value={Client}
//           onChange={this.handleClientChange}
//         />
//         <FormInput
//           text={intl.formatMessage({ id: "description" })}
//           inputType={HTMLInput}
//           name="Description"
//           className="mb-3"
//           value={Description}
//           onChange={this.handleChange}
//         />

//         <SplitCol
//           className="my-3"
//           left={
//             <FormInput
//               name="Contact"
//               text={intl.formatMessage({ id: "contact" })}
//               inputType={ContactDropdown}
//               value={Contact}
//               Client={Client}
//               onChange={this.handleChange}
//             />
//           }
//           right={
//             <FormInput
//               name="Manager"
//               text={intl.formatMessage({ id: "responsible" })}
//               inputType={UserDropdown}
//               value={Manager}
//               onChange={this.handleChange}
//             />
//           }
//         />

//         <SplitCol
//           className="my-3"
//           left={
//             <FormInput
//               name="BeginDate"
//               text={intl.formatMessage({ id: "start" })}
//               inputType={ArDatePicker}
//               componentClassName="w-100"
//               openHourAfterDate
//             />
//           }
//           right={
//             <FormInput
//               name="EndDate"
//               text={intl.formatMessage({ id: "end" })}
//               inputType={ArDatePicker}
//               value={EndDate}
//               onChange={this.handleChange}
//               componentClassName="w-100"
//               openHourAfterDate
//             />
//           }
//         />
//         <SplitCol
//           className="mb-3"
//           left={
//             <FormInput
//               text={intl.formatMessage({ id: "estimated_time_hours" })}
//               name="EstimatedCost"
//               inputType={HourInput}
//               value={EstimatedCost}
//               onChange={this.handleChange}
//             />
//           }
//           right={
//             <FormInput
//               name="SalesOpportunity"
//               text={intl.formatMessage({ id: "sales_opportunity" })}
//               value={SalesOpportunity}
//               onChange={this.handleChange}
//             />
//           }
//         />

//         <FormInput
//           name="Deslocations"
//           className="mb-3"
//           text={intl.formatMessage({ id: "deslocations" })}
//           value={Deslocations}
//           onChange={this.handleChange}
//           inputType={NumberInput}
//         />

//         <FormInput
//           name="Covenant"
//           className="mb-3"
//           text={intl.formatMessage({ id: "renovation" })}
//           inputType={CovenantPeriodicity}
//           value={Covenant}
//         />

//         <FormInput
//           name="Departments"
//           className="mb-3"
//           text={intl.formatMessage({ id: "departments" })}
//           inputType={DepartmentDropdown}
//           multiple
//         />

//         <FormInput
//           name="TeamCollab"
//           className="mb-3"
//           text={intl.formatMessage({ id: "teams_contributors" })}
//           inputType={TeamCollabDropdown}
//           multiple
//         />

//         <FormInput
//           name="ContractType"
//           className="mb-3"
//           text={intl.formatMessage({ id: "contract" })}
//           inputType={ContractTypeDropdown}
//           value={ContractType}
//           onChange={this.handleChange}
//         />
//         <ClipboardProvider>
//         <FormInput
//           className="mb-3"
//           text={intl.formatMessage({ id: "internal_note" })}
//           inputType={HTMLEditor}
//           name="InternalDescription"
//           value={InternalDescription}
//           onChange={this.handleChange}
//         />

//         {id && (
//           <FormInput
//             text={intl.formatMessage({ id: "status" })}
//             name="Status"
//             inputType={ContractStatusDropdown}
//             hideCancellation
//             className="mb-3"
//           />
//         )}

//         <FormInput
//           text={intl.formatMessage({ id: "files" })}
// 		  name="Files"
// 					className="mb-3 form-Anexos-floater"
//           inputType={FileUploader}
//           value={Files}
//           enableSwapper
//           onChange={this.handleChange}
//         />
//         </ClipboardProvider>
// 			{Creator &&
// 				<>
// 					<div className="mb-1 fs-14 fw-medium text-black">
// 						<span>
// 							Criado por
// 					</span>
// 					</div>
// 					<div className="mb-3 fs-14 fw-medium text-black">
// 						<ConnectedAccountProfile account={Creator} />
// 					</div>
// 				</>
// 			}
//       </SideMenuForm>
//     );
//   }
// }

// export default withSpaceEntityForm(withSpace(injectIntl(ContractForm)), {
//   defaultForm: defaultContractForm,
//   validateForm: validateContractForm,
//   schema: spaceContractSchema,
//   convertEntityToForm: convertContractEntityToForm,
//   dependencies: [
//     {
//       schema: spaceClientSchema,
//       getid: ({ clientId }) => clientId,
//       convertEntityToForm: (entity, props) => {
//         const { Id } = entity;
//         return {
//           Client: Id
//         };
//       }
//     }
//   ],
//   // getid: (state, ownProps) => ownProps.taskId,
//   formatForm: formatContractForm,
//   onSuccess: ({ openSidebar, form, id, createToast}) => {
//     createToast({pos: "br",type: "success",description: `Contrato gravado com Sucesso`})
//   },
// 	onError: ({ error, createToast }) => {
// 		handleError(createToast, error)
// 	}
// });
