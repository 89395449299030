import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faUser } from "@fortawesome/pro-light-svg-icons";
import { removeTags } from "../../Helpers/MiscHelper";
import DropdownInput from "../Dropdown/DropdownInput";
import { useCall, useContact, useClient } from "../../Hooks/EntityHooks";
import { AccountProfileImg } from "../Img/Img";
import { buildProjectStatusType } from "../Projects/ProjectProfile";

const ContactConectMethoed = ({ ContactData }) => {
  const { PhoneNumber, MobileNumber, Email } = ContactData;

  if (MobileNumber) {
    return (
      <>
        <FontAwesomeIcon className="mr-1 ml-2" icon={faPhone} />
        {MobileNumber.replace(".", " ")}
      </>
    );
  } else if (PhoneNumber) {
    return (
      <>
        <FontAwesomeIcon className="mr-1 ml-2" icon={faPhone} />
        {PhoneNumber.replace(".", " ")}
      </>
    );
  } else if (Email) {
    return (
      <>
        <FontAwesomeIcon className="mr-1 ml-2" icon={faEnvelope} />
        {Email}
      </>
    );
  } else return null;
};

const CallListItem = ({
  call,
  callClient = {},
  callContact = {},
  enableInput,
  isTyping,
  dispatch,
  text,
  ...inputProps
}) => {
  const { Description, NoTagDescription, Contact, Status, Manager } =
    call || {};

  const resolvedDescription =
    NoTagDescription || (Description && removeTags(Description));
  const ContactData = useContact(Contact);
  const resolvedStatus = buildProjectStatusType(Status);
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100 of-hidden text-truncate">
        {!isTyping && call && (
          <AccountProfileImg
            size="mds"
            account={Manager}
            circular
            className="mr-2 flex-0-0-auto"
          />
        )}
        <div className="d-flex flex-column align-items-baseline w-100 of-hidden mr-2">
          <div className="dropdown-item-maintext ">
            {!enableInput && resolvedDescription ? (
              resolvedDescription.replace(/\s\s+/g, " ")
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={resolvedDescription}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
          {!isTyping && (
            <div className="dropdown-item-subtext-dif text-truncate">
              {ContactData && ContactData.Name && (
                <span>
                  <FontAwesomeIcon className="mr-1" icon={faUser} />
                  {ContactData.Name}
                </span>
              )}
              {ContactData && (
                <span>
                  <ContactConectMethoed ContactData={ContactData} />
                </span>
              )}
            </div>
          )}
        </div>
        {!isTyping && call && (
          <div
            className={`d-flex align-items-center justify-content-end status-color-${resolvedStatus}`}
          >
            <div className="d-flex py-1 px-2 fs-12 align-items-center status-list-template">
              <FontAwesomeIcon className="mr-2" icon={faPhone} />
              <span>{call.Id}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CallListItem;

export const ConnectedCallListItem = ({ call, ...rest }) => {
  const c = useCall(call);
  const cContact = useContact(call && call.Contact);
  const cClient = useClient(call && call.Client);

  return (
    <CallListItem
      call={c}
      callContact={cContact || {}}
      callClient={cClient || {}}
      {...rest}
    />
  );
};
