import React, { useMemo, useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCardAlt } from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import { getEntity, useSpaceQuery } from "../../Helpers/IOClient";
import { countrySchema } from "../../config/schema";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import { useToast } from "../Toast/ToastProvider";
import InformationComponent from "../InformationComponent/InformationComponent";
import { useIntl } from "react-intl";

const AvaliableCountries = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "EL",
  "ES",
  "FI",
  "FR",
  "GB",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK"
];

export const NifValidator = ({
  value,
  onChange,
  className,
  preview,
  country,
  name,
  text,
  error,
  onUpdate,
  disabled,
  ...props
}) => {
  const [errorMsg, setErrorMsg] = useState(null);

  const intl = useIntl();
  const onError = useCallback(
    (option) => {
      switch (option) {
        case "1":
          setErrorMsg(intl.formatMessage({ id: "NIF_VALIDATION_NOT_VALID" }));
          break;
        case "2":
          setErrorMsg(intl.formatMessage({ id: "NIF_VALIDATION_NOT_FOUND" }));
          break;
        case "3":
          setErrorMsg(intl.formatMessage({ id: "NIF_VALIDATION_ERROR" }));
          break;
        case "4":
          setErrorMsg(intl.formatMessage({ id: "INVALID_NIF" }));
          break;
        default:
          setErrorMsg(null);
      }
    },
    [intl]
  );

  const { countryCode, showGlobe } = useMemo(() => {
    const entity = getEntity(countrySchema, country);
    const code = entity ? entity.Code : null;
    const verification = code ? AvaliableCountries.includes(code) : false;
    return { countryCode: code, showGlobe: verification };
  }, [country]);
  return (
    <>
      {text && (
        <div
          className={classnames("mb-1 fs-14 fw-medium text-black", {
            "text-danger": error
          })}
        >
          {text}
        </div>
      )}
      {preview && <span className="text-black fs-14">{value}</span>}
      {!preview && (
        <div
          className={classnames(
            `dropdown-container p-0 ssi-control 
         ar-dropdown-container 
        `,
            className,
            { error: error }
          )}
          tabIndex="1"
        >
          <label
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            className="d-flex ar-dropdown-label-search p-0"
          >
            <div className="flex-1">
              <div className="custom-dropdown-input d-flex align-items-center">
                <input
                  disabled={disabled}
                  name={name}
                  className="ssi-input ssi-control "
                  value={value}
                  onChange={onChange}
                />
              </div>
            </div>
            {!disabled && (
              <div
                style={{ paddingRight: 12 }}
                className="text-primary d-flex align-items-center justify-content-center cursor-pointer"
              >
                <InformationComponent
                  text={intl.formatMessage({ id: "VALIDATE_NIF" })}
                  Component={
                    <VatInfoGetter
                      onUpdate={onUpdate}
                      countryCode={countryCode}
                      showGlobe={showGlobe}
                      value={value}
                      onError={onError}
                    />
                  }
                />
              </div>
            )}
          </label>
        </div>
      )}
      {errorMsg && <div className="text-danger fs-14 mt-1">{errorMsg}</div>}
    </>
  );
};

let config = {
  headers: {
    "vat-app":
      "9mtyYfkmpKWiPfAAN2IGeo0N7MEYQXuj3h8noxmqaGb6WXBeW8Hhop5YAbDyJa3CnU3AVUmCF80bYHgUIxt6oZ9kujjYPOhlhE0hZkBXCWXFWw4RZovsOl0zB_TY2wUtkSSPfPehFLQQA79N23UnOhXqpTffckO14351"
  }
};
const VatInfoGetter = ({
  countryCode,
  showGlobe,
  onUpdate,
  onError,
  value
}) => {
  const endpoint = useMemo(
    () => `nifvalidator/${countryCode}/${value}`,
    [countryCode, value]
  );

  const createToast = useToast();

  const { loading, refetch } = useSpaceQuery(endpoint, null, {
    requestConfig: config,
    autoFetch: false,
    onSuccess: ({ data }) => {
      if (data.Result !== 1) {
        createToast({
          width: 520,
          pos: "tm",
          type: "info",
          description: `Apesar dos dados terem sido importados com sucesso verificamos que a empresa poderá estar encerrada ou com dados desatualizados. Por favor valide-os.`
        });
      } else {
        createToast({
          width: 520,
          pos: "tm",
          type: "success",
          description: `Dados importados com sucesso.`
        });
      }
      onError();
      onUpdate(data);
    },
    onError: ({ error }) => {
      if (error && error.data && error.data.Message === "Invalid Vat number")
        onError("4");
      else if (
        error &&
        error.data &&
        error.data.Message === "Vat number not found"
      )
        onError("2");
      else onError("3");
    }
  });

  const HandleDataGather = () => {
    if (value && (value.match(/[\d]/g) || []).length >= 2) refetch();
    else onError("4");
    return;
  };

  return (
    <>
      {showGlobe && !loading && (
        <FontAwesomeIcon icon={faIdCardAlt} onClick={HandleDataGather} />
      )}
      {loading && <LoaderSpinner size="xxs" />}
    </>
  );
};
