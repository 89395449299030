import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useContext
} from "react";
import Button from "../../Components/Button/Button";
import Modal from "../../Components/Modal/Modal";
import {
  getEntity,
  useSpacePostQuery,
  useSpacePost,
  useSpaceQuery,
  useQuery
} from "../../Helpers/IOClient";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeOpenText,
  faFilePdf,
  faEye
} from "@fortawesome/pro-light-svg-icons";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";
import { FormattedMessage } from "react-intl";
import { EmailPreviewFormContext } from "../../Components/EntitySummaries/EntitySummaryMessages/MessageSender/MessageSender";
import { spaceSignatureAggregationSchema } from "../../config/schema";
import { createPortal } from "react-dom";
import { useSpace } from "../../Contexts/SpaceContext";
import Axios from "axios";
// // Create style with font-family
// const styles = StyleSheet.create({
//   page: {
//     fontFamily: "Roboto"
//   }
// });

const CustomIframe = ({ children, ...props }) => {
  const [contentRef, setContentRef] = useState(null);

  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe
      id="iframe"
      width={595}
      height="842"
      className="h-100 bg-white w-100 border-0"
      title="emailPreview"
      {...props}
      ref={setContentRef}
    >
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

const styles = `
    /* Use the DejaVu Sans font for displaying and embedding in the PDF file. The standard PDF fonts do not support Unicode characters. */
    div {
        font-family: "DejaVu Sans", "Arial", sans-serif;
        font-size: 12px;
    }

    /* The examples load the DejaVu Sans from the KendoReact CDN. Other fonts have to be hosted from your application.
    The official site of the Deja Vu Fonts project is https://dejavu-fonts.github.io/. */
    @font-face {
        font-family: "DejaVu Sans";
        src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
    }

    @font-face {
        font-family: "DejaVu Sans";
        font-weight: bold;
        src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
    }

    @font-face {
        font-family: "DejaVu Sans";
        font-style: italic;
        src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
    }

    @font-face {
        font-family: "DejaVu Sans";
        font-weight: bold;
        font-style: italic;
        src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
    }
`;

const OriginalEmailModal = ({ form, emailConversationId, noPreview }) => {
  // const [post, { loading, error }] = useSpacePost(
  //   `EmailConversation/SendEmailPreview${
  //     emailConversationId ? `?EmailConversationId=${emailConversationId}` : ""
  //   }`,
  //   null,
  //   {
  //     onSuccess: ({ data }) => {
  //       setHtmlPreview(data);
  //     }
  //   }
  // );
  const space = useSpace();
  const containerRef = useRef();
  const data = useMemo(() => {
    const { text, subject, to, cc, bcc, signature, OrganizationSpaceId } =
      form || {};

    let resolvedSignature = undefined;
    if (signature) {
      const { data } = signature;
      const connectedSignature = getEntity(
        spaceSignatureAggregationSchema,
        data
      );
      const { Company, Department, Team, SignatureId } =
        connectedSignature || {};

      const Id = Company || Department || Team;
      resolvedSignature = {
        OrganizationId: Id,
        Id: SignatureId
      };
    }
    return {
      OrganizationSpaceId,
      Text: text,
      Subject: subject,
      To: to,
      Cc: cc,
      Bcc: bcc,
      Signature: resolvedSignature ? resolvedSignature : undefined
    };
  }, [form]);

  const resolvedUrl = useMemo(() => {
    if (noPreview && emailConversationId) {
      return `EmailConversation/PreviewConversation?EmailConversationId=${emailConversationId}`;
    } else {
      return `EmailConversation/SendEmailPreview/v2?EmailConversationId=${emailConversationId}&IsPreview=true`;
    }
  }, [emailConversationId, noPreview]);

  const downloadPdfUrl = useMemo(
    () =>
      `Spaces/${space.Id}/EmailConversation/SendEmailPreview/v2?EmailConversationId=${emailConversationId}&IsPreview=false`,
    [emailConversationId, space.Id]
  );
  const [downloadStatus, setDownloadStatus] = useState(false);

  const {
    data: htmlPreview,
    loading,
    error
  } = useSpacePostQuery(resolvedUrl, data, null, { cache: false });

  // useEffect(() => {
  //   post(data);
  // }, [data, post]);

  // const [post, { loading: loadingPdf, error: errorPDF }] = useSpacePost(
  //   downloadPdfUrl,
  //   null,
  //   {
  //     cache: false,
  //     onSuccess: ({ data }) => {
  //       let link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(data);
  //       link.download = "SingleEmailExport";
  //       link.click();
  //       link.remove();
  //     },
  //     onError: ({ error }) => {
  //       console.log(error);
  //     }
  //   }
  // );

  const handleCreatePdf = useCallback(() => {
    setDownloadStatus(true);
    Axios.post(downloadPdfUrl, data, {
      responseType: "blob"
    }).then(
      (response) => {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(response);
        link.download = "SingleEmailExport";
        link.click();
        link.remove();
        setDownloadStatus(false);
      },
      (error) => {
        console.log(error);
        setDownloadStatus(false);
      }
    );
  }, [data, downloadPdfUrl]);

  const iframeRef = useRef();
  if (loading || error)
    return (
      <div
        style={{ width: "85vw" }}
        className="h-100 m-auto d-flex align-items-center justify-content-center"
      >
        <LoaderSpinner size="sm" center className="text-primary" />
      </div>
    );

  return (
    <div
      ref={containerRef}
      style={{ width: "85vw" }}
      className="h-100 position-relative"
    >
      <iframe
        onLoad={() => {
          if (iframeRef.current) {
            const { contentDocument } = iframeRef.current;
            let doc = contentDocument;
            if (doc?.body) {
              doc.body.innerHTML =
                doc.body.innerHTML +
                `<style>
								body{
									overflow-y: auto;
									overflow-x: hidden;
									box-sizing: border-box;
									font-family: "Roboto", sans-serif;
								
									/* padding-left: 1rem; */
				
							}
							img {
								max-width: 100% !important;
								object-fit: contain;
								border: 0px;
							}
							::-webkit-scrollbar {
									padding: 4px 2px;
									width: 12px;
									height: 12px;
									border-radius: 12px;
							}
							::-webkit-scrollbar-thumb {
									background: #c1c9d2;
									border-radius: 12px;
									border: 2px solid transparent;
									background-clip: content-box;
							}
							blockquote {
									margin: 0 !important;
							}
							/*table.x_x_MsoNormalTable, table.x_MsoNormalTable, */
							
							table {
									overflow: hidden;
									max-width: 100%;
									width: 100% !important;
									box-sizing: border-box;
							}
							.x_bodyContainer > table {
									max-width: 100%;
									overflow: hidden;
									box-sizing: border-box;
									border-radius: 10px;
									table-layout: fixed;
							}
							.x_bodyContainer > table .x_mcnTextBlockInner:first-of-type {
									overflow: hidden;
							}
							.x_templateContainer {
									max-width: 100% !important;
									overflow: hidden;
									table-layout: fixed;
							}
							.x_bodyContainer > table .x_mcnTextBlockInner:first-of-type > table{
									max-width: 100%;
									min-width: 100%;
									overflow: hidden;
									table-layout: fixed;
							}
							#x_templateBodyAirdesk > table{
									width: 100%;
									max-width: 100% !important;
									overflow: hidden;
									box-sizing: border-box;
									table-layout: fixed;
							}
							#x_bodyCellAirdesk > table {
									table-layout: fixed !important;
							}
			</style>`;
            }
          }
        }}
        ref={iframeRef}
        className="h-100 w-100 border-0"
        title="email"
        srcDoc={htmlPreview}
      />
      {/* <div
        style={{ top: 50 }}
        className="ar-modal-preview-file-content-close-button text-color-link"
        onClick={() => {
          if (downloadStatus === true) return;
          else handleCreatePdf();
        }}
      >
        {downloadStatus ? (
          <LoaderSpinner size="xxs" className="text-primary" />
        ) : (
          <FontAwesomeIcon icon={faFilePdf} />
        )}
      </div> */}
      {/* <div dangerouslySetInnerHTML={{ __html: data }}></div> */}
    </div>
  );
};

const OriginalEmailWebhookModal = ({ form, id, noPreview }) => {
  // const [post, { loading, error }] = useSpacePost(
  //   `EmailConversation/SendEmailPreview${
  //     emailConversationId ? `?EmailConversationId=${emailConversationId}` : ""
  //   }`,
  //   null,
  //   {
  //     onSuccess: ({ data }) => {
  //       setHtmlPreview(data);
  //     }
  //   }
  // );
  const containerRef = useRef();
  const data = useMemo(() => {
    const { text, subject, to, cc, bcc, signature, OrganizationSpaceId } =
      form || {};

    let resolvedSignature = undefined;
    if (signature) {
      const { data } = signature;
      const connectedSignature = getEntity(
        spaceSignatureAggregationSchema,
        data
      );
      const { Company, Department, Team, SignatureId } =
        connectedSignature || {};

      const Id = Company || Department || Team;
      resolvedSignature = {
        OrganizationId: Id,
        Id: SignatureId
      };
    }
    return {
      OrganizationSpaceId,
      Text: text,
      Subject: subject,
      To: to,
      Cc: cc,
      Bcc: bcc,
      Signature: resolvedSignature ? resolvedSignature : undefined
    };
  }, [form]);

  const resolvedUrl = useMemo(() => {
    return `CommonTools/PostalWebhook/${id}/Email`;
  }, [id]);

  const {
    data: htmlPreview,
    loading,
    error
  } = useQuery(resolvedUrl, data, null, { cache: false });

  // useEffect(() => {
  //   post(data);
  // }, [data, post]);

  // const [post, { loading: loadingPdf, error: errorPDF }] = useSpacePost(
  //   downloadPdfUrl,
  //   null,
  //   {
  //     cache: false,
  //     onSuccess: ({ data }) => {
  //       let link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(data);
  //       link.download = "SingleEmailExport";
  //       link.click();
  //       link.remove();
  //     },
  //     onError: ({ error }) => {
  //       console.log(error);
  //     }
  //   }
  // );

  const iframeRef = useRef();
  if (loading || error)
    return (
      <div
        style={{ width: "85vw" }}
        className="h-100 m-auto d-flex align-items-center justify-content-center"
      >
        <LoaderSpinner size="sm" center className="text-primary" />
      </div>
    );

  return (
    <div
      ref={containerRef}
      style={{ width: "85vw" }}
      className="h-100 position-relative"
    >
      <div className="text-black d-flex p-2 fw-medium fs-14">
        Postal Id: {htmlPreview.PostalId}
      </div>
      <iframe
        onLoad={() => {
          if (iframeRef.current) {
            const { contentDocument } = iframeRef.current;
            let doc = contentDocument;
            if (doc?.body) {
              doc.body.innerHTML =
                doc.body.innerHTML +
                `<style>
								body{
									overflow-y: auto;
									overflow-x: hidden;
									box-sizing: border-box;
									font-family: "Roboto", sans-serif;
								
									/* padding-left: 1rem; */
				
							}
							img {
								max-width: 100% !important;
								object-fit: contain;
								border: 0px;
							}
							::-webkit-scrollbar {
									padding: 4px 2px;
									width: 12px;
									height: 12px;
									border-radius: 12px;
							}
							::-webkit-scrollbar-thumb {
									background: #c1c9d2;
									border-radius: 12px;
									border: 2px solid transparent;
									background-clip: content-box;
							}
							blockquote {
									margin: 0 !important;
							}
							/*table.x_x_MsoNormalTable, table.x_MsoNormalTable, */
							
							table {
									overflow: hidden;
									max-width: 100%;
									width: 100% !important;
									box-sizing: border-box;
							}
							.x_bodyContainer > table {
									max-width: 100%;
									overflow: hidden;
									box-sizing: border-box;
									border-radius: 10px;
									table-layout: fixed;
							}
							.x_bodyContainer > table .x_mcnTextBlockInner:first-of-type {
									overflow: hidden;
							}
							.x_templateContainer {
									max-width: 100% !important;
									overflow: hidden;
									table-layout: fixed;
							}
							.x_bodyContainer > table .x_mcnTextBlockInner:first-of-type > table{
									max-width: 100%;
									min-width: 100%;
									overflow: hidden;
									table-layout: fixed;
							}
							#x_templateBodyAirdesk > table{
									width: 100%;
									max-width: 100% !important;
									overflow: hidden;
									box-sizing: border-box;
									table-layout: fixed;
							}
							#x_bodyCellAirdesk > table {
									table-layout: fixed !important;
							}
			</style>`;
            }
          }
        }}
        ref={iframeRef}
        className="h-100 w-100 border-0"
        title="email"
        srcDoc={htmlPreview.Email}
      />
      {/* <div
        style={{ top: 50 }}
        className="ar-modal-preview-file-content-close-button text-color-link"
        onClick={() => {
          if (downloadStatus === true) return;
          else handleCreatePdf();
        }}
      >
        {downloadStatus ? (
          <LoaderSpinner size="xxs" className="text-primary" />
        ) : (
          <FontAwesomeIcon icon={faFilePdf} />
        )}
      </div> */}
      {/* <div dangerouslySetInnerHTML={{ __html: data }}></div> */}
    </div>
  );
};

export const EmailWebhookPreviewButton = ({ id }) => {
  const FormRef = useContext(EmailPreviewFormContext) || {};
  const [open, setOpen] = useState(false);

  const handleClosure = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Modal
        className="h-100 d-flex align-items-center of-visible justify-content-center"
        isOpen={open}
        enableCloseButton
        onClose={handleClosure}
      >
        <OriginalEmailWebhookModal form={FormRef.current} id={id} />
      </Modal>
      <Button
        vType="primary-ghost"
        className="ssi-button-grid ml-3"
        size="sm"
        onClick={() => {
          setOpen(true);
        }}
      >
        <FontAwesomeIcon icon={faEnvelopeOpenText} />
      </Button>
    </>
  );
};

const PreviewEmailButton = ({
  emailConversationId,
  className,
  textStyle,
  disabled,
  noPreview
}) => {
  const FormRef = useContext(EmailPreviewFormContext) || {};
  const [open, setOpen] = useState(false);

  const handleClosure = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Modal
        className="h-100 d-flex align-items-center of-visible justify-content-center"
        isOpen={open}
        enableCloseButton
        onClose={handleClosure}
      >
        <OriginalEmailModal
          noPreview={noPreview}
          form={FormRef.current}
          emailConversationId={emailConversationId}
        />
      </Modal>
      {textStyle ? (
        <Button
          disabled={disabled}
          vType="link-primary"
          type="button"
          className="mr-3"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(true);
          }}
        >
          <FormattedMessage id="PREVIEW" />
        </Button>
      ) : (
        <InformationComponent
          text={<FormattedMessage id="PREVIEW" />}
          Component={
            <Button
              disabled={disabled}
              style={{ height: "auto" }}
              type="button"
              vType="link-primary"
              className={className}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          }
        />
      )}
    </>
  );
};

export default PreviewEmailButton;
