import moment from "moment";
import {
  getColumnDropdownTarget,
  getInterventionTarget
} from "../../Helpers/FormFormmaterHelper";
import { formatTodosGood } from "./TasksHelper";
export const convertTaskEntityToForm = (entity, props) => {
  const {
    Call,
    Project,
    Task,
    Tags,
    Client,
    Ticket,
    Contract,
    Team,
    ...entityRest
  } = entity;

  const resolvedTags = [];
  if (Array.isArray(Tags) && Tags.length > 0) {
    for (const t of Tags) {
      resolvedTags.push({
        data: t,
        type: "TaskTag"
      });
    }
  }

  return {
    ...entityRest,
    Tags: resolvedTags,
    TaskTarget: getColumnDropdownTarget(props) || getInterventionTarget(entity)
  };
};

export const defaultTaskForm = ({ user, taskId }) => {
  return {
    TaskTarget: null,
    Name: "",
    Creator: "",
    EstimatedCost: 3600000, //1 h
    // SalesOpportunity: "",
    BeginDate: new Date(),
    ToDos: [],
    Followers: taskId ? [] : [user.Id],
    EndDate: moment().add(1, "h").toDate(),
    Manager: user.Id,
    InternalDescription: "",
    Files: null,
    Status: null
  };
};

export const buildTaskBaseForm = (user, taskId, convertedForm) => {
  const defaultTaskFor = defaultTaskForm({ user, taskId });
  let AddressSelector;
  const { ToDos } = convertedForm;
  if (
    convertedForm &&
    convertedForm.Addresses &&
    convertedForm.Addresses.length > 0
  ) {
    AddressSelector = {
      Id: convertedForm.Addresses[0].Id || undefined,
      Country:
        convertedForm.Addresses[0].Country ||
        convertedForm.Addresses[0].CountryId ||
        undefined,
      Address1: convertedForm.Addresses[0].Address1 || "",
      Address2: convertedForm.Addresses[0].Address2 || "",
      District: convertedForm.Addresses[0].District || "",
      Locality: convertedForm.Addresses[0].Locality || "",
      PostalCode: convertedForm.Addresses[0].PostalCode || "",
      Latitude: convertedForm.Addresses[0].Latitude || "",
      Longitude: convertedForm.Addresses[0].Longitude || ""
    };
  } else {
    AddressSelector = {
      Id: undefined,
      Country: defaultTaskFor.Country,
      Address1: defaultTaskFor.Address,
      Address2: defaultTaskFor.Address,
      District: defaultTaskFor.Region,
      Locality: defaultTaskFor.Locale,
      PostalCode: defaultTaskFor.PostalCode,
      Latitude: defaultTaskFor.Latitude,
      Longitude: defaultTaskFor.Longitude
    };
  }

  const obj = ToDos?.length > 0 ? formatTodosGood(ToDos) : [];

  return {
    ...defaultTaskFor,
    ...convertedForm,
    AddressSelector,
    ...obj
  };
};
