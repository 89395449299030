import React, { useCallback, useState, useMemo, useContext } from "react";
import { useSpacePost, getEntity } from "../../../Helpers/IOClient";
import { useToast } from "../../../Components/Toast/ToastProvider";
import {
  spaceClientSchema,
  spaceClientTagsSchema
} from "../../../config/schema";
import { ClientStatusDropdown } from "../../../Components/Clients/Clients";
import {
  ServerAwareProvider,
  ServerAwareQueryInput,
  GridRequestProvider
} from "../../../Components/CGrid/ServerGrid";
import {
  ClientEntityActionsChildren,
  ClientEntityGridButtons
} from "../ClientsHelper";
import EntityFollowIcon from "../../../Components/EntityFollow/EntityFollowIcon";
import EntityIconOverview from "../../../Components/EntityIconOverview/EntityIconOverview";
import { ClientLink } from "../../../Helpers/HooksHelper";
import { ClientSidebarLink } from "../../../Components/EntitySidebarLinks/EntitySidebarLinks";
import AccountsInRoomStack from "../../../Components/AccountsInRoom/AccountsInRoomStack";
import {
  EmailColumn,
  PhoneColumn
} from "../../../Components/VirtualGrid/VirtualGridColumns";
import {
  EntityGridChildrenProgress,
  EntityGridChildrenProgressProvider
} from "../../EntityChildrenProgress/EntityChildrenProgress";
import RelativeTime from "../../../Components/RelativeTime/RelativeTime";
import { ConnectedAccountPopup } from "../../Origin/AcountPopup";
import {
  createClientAtributesFilter,
  createReadFilter,
  createUnreadFilter,
  createFollowingFilter,
  createNotFollowingFilter,
  createUserFilter,
  createClientTypeFilter,
  createClientTagFilter
} from "../../../Helpers/FilterHelper";
import { useIntl, FormattedMessage } from "react-intl";
import {
  PageContainer,
  PageContent
} from "../../../Components/PageUtilities/PageUtilities";
import Modal from "../../../Components/Modal/Modal";
import { ColumnChooser } from "../../../Components/ColumnChooser/ColumnChooser";
import PageHeader from "../../../Components/Headers/PageHeader";
import { ClientFormButton } from "../../Form/FormButtons";
import { ActionButtonOptions } from "../../../Components/ExtraActionsButton/ExtraActionButton";
import { moduleTypes } from "../../../Helpers/ModulesHelper";
import ServerAwareFilterList from "../../../Components/FilterList/ServerAwareFilterList";
import { FilterViewTypeEnum } from "../../../Components/FilterList/FilterList";
import ClientGrid from "./ClientGrid";
import { useAirdeskTitle } from "../../../Hooks/MiscHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faAlignJustify,
  faSuitcase,
  faPhone,
  faFileImport,
  faInfoSquare,
  faUser,
  faDrawCircle,
  faIdCardAlt,
  faLocation,
  faUserCog,
  faUserTie,
  faCalendarAlt,
  faUserPlus,
  faGlobe,
  faCity,
  faRoad,
  faIdCardClip,
  faTag
} from "@fortawesome/pro-light-svg-icons";
import {
  faBell as faBellSolid,
  faGlobe as faGlobeSolid,
  faUserCog as faUserCogSolid,
  faUserPlus as faUserPlusSolid,
  faCalendarAlt as faCalendarAltSolid,
  faSuitcase as faSuitcaseSolid,
  faUserTie as faUserTieSolid,
  faEnvelope as faEnvelopeSolid,
  faPhone as faPhoneSolid,
  faAlignJustify as faAlignJustifySolid,
  faInfoSquare as faInfoSquareSolid,
  faIdCardClip as faIdCardClipSolid,
  faTag as faTagSolid,
  faIdCardAlt as faIdCardAltSolid,
  faFolderTree,
  faLocation as faLocationSolid,
  faDrawCircle as faDrawCircleSolid,
  faBullseyePointer
} from "@fortawesome/pro-solid-svg-icons";
import { RealTimeRoomTypes } from "../../RealTime/RealTimeHelper";
import { ConnectedAccountProfile } from "../../../Components/Accounts/AccountProfile";
import { InboxTypeEnum } from "../../Reception/ReceptionHelpers";
import { SelectedArrMsgContext } from "../../../Components/UnOrReadOptions/UnreadOptions";
import { GridActionContext } from "../../../Components/ContextMenu/ContextMenu";
import { ConnectedContactProfileStack } from "../../../Components/Accounts/AccountProfileStack";
import DeleteMultipleModalHandler from "../../../Components/CGrid/DeleteMultipleModal";
import { DefaultColumnsWidth } from "../../../Components/CGrid/GridDefinitions";
import TextEllipsis from "../../../Components/TextEllipsis/TextEllipsis";
import { useCurrentUserEditClientPermission } from "../../../Helpers/ContactPermissionsHelper";
import { ActionButtonItem } from "../../../Components/ActionsButton/ActionsButton";
import { CLientManagerMultipleButton } from "../ClientsMultiActionForms";
import SaveFavoriteButton from "../../AppBar/SaveFavoriteButton";
import { ServerAwareFavoritesContext } from "../../AppBar/ServerAwareFavorite";
import { GridDateComponent } from "../../../Components/CGrid/CGrid";
import MultiMarkerChanger from "../../../Components/MultiMarkerChanger/MultiMarkerChanger";
const ClientStatusChanger = ({ Data }) => {
  const intl = useIntl();
  const createToast = useToast();
  const [post, { loading }] = useSpacePost(
    `Clients/${Data.Id}/Status`,
    spaceClientSchema,
    {
      onSuccess: () => {
        createToast({
          pos: "tm",
          type: "success",
          title: intl.formatMessage({ id: "ACCOUNT_NR" }) + ` ${Data.Id}`,
          description: intl.formatMessage({ id: "STATUS_CHANGED_SUCCESS" })
        });
      }
    }
  );

  const handleStatusChange = (v) => {
    post({ Status: v });
  };

  return (
    <ClientStatusDropdown
      disabled={loading}
      preview={!Data.CanEdit}
      onChange={handleStatusChange}
      className="fs-12 text-truncate"
      value={Data.Status}
    />
  );
};

export const customersColumns = [
  {
    columnChooserIcon: faBullseyePointer,
    sticky: true,
    sortable: false,
    required: true,
    orderBy: false,
    noTitle: true,
    field: "buttons",
    title: "Actions",
    minSize: 90,
    width: DefaultColumnsWidth.smallButton,
    className: "p-2",
    Cell: ({ Data: value }) => <ClientEntityGridButtons entity={value} />
  },
  {
    headerIcon: faAlignJustifySolid,
    filterIcon: faAlignJustify,
    sticky: true,
    width: DefaultColumnsWidth.ClientName,
    // orderBy: false,
    // sortable: false,
    required: true,
    field: "Client/Name",
    title: <FormattedMessage id={"NAME"} />,
    Cell: ({ Data }) => {
      return (
        <div className="d-flex align-items-center">
          {Data.AccountsInRoomView && Data.AccountsInRoomView.length > 0 && (
            <span className="mr-2">
              <AccountsInRoomStack
                dontShowLine
                accountsInRoom={Data.AccountsInRoomView}
              />
            </span>
          )}
          <ClientSidebarLink className="fs-12" id={Data.Id}>
            <TextEllipsis length={85} truncate text={Data.Name} />
            {/* <ConnectedClientOrigin
            hideStatus={"true"}
            hideId={"true"}
            client={Data.Id}
          >
            <ClientProfile
              className="text-primary"
              hideStatus
              hideId={"true"}
              client={Data}
            />
          </ConnectedClientOrigin> */}
          </ClientSidebarLink>
        </div>
      );
    }
  },
  {
    headerIcon: faBellSolid,
    width: DefaultColumnsWidth.followIcon,
    title: <FormattedMessage id={"FOLLOW_UP"} />,
    field: "IsFollowing",
    sortable: false,
    orderBy: false,
    noTitle: true,
    Cell: ({ Data: value }) => (
      <EntityFollowIcon
        className="mx-auto"
        canEdit={value.CanEdit}
        size="lg"
        schema={spaceClientSchema}
        isFollowing={value.IsFollowing}
        id={value.Id}
      />
    )
  },
  {
    headerIcon: faInfoSquareSolid,
    filterIcon: faInfoSquare,
    width: DefaultColumnsWidth.icons,
    field: "Attributes",
    title: <FormattedMessage id={"ATTRIBUTES"} />,
    sortable: false,
    orderBy: false,
    // noTitle: true,
    Cell: ({ Data: value }) => <EntityIconOverview value={value} />
  },
  {
    headerIcon: faSuitcaseSolid,
    filterIcon: faSuitcase,
    width: DefaultColumnsWidth.id,
    field: "Client/Id",
    title: "Nº",
    Cell: ({ Data: value }) => <ClientLink id={value.Id}>{value.Id}</ClientLink>
  },
  {
    headerIcon: faTagSolid,
    filterIcon: faTag,
    orderBy: false,
    sortable: false,
    width: DefaultColumnsWidth.Company,
    field: "Client/Tags",
    title: <FormattedMessage id={"TAGS"} />,
    Cell: ({ Data }) => {
      if (!Data.Tags || Data.Tags?.length === 0) return <div>―</div>;

      const resolvedTags = [];

      for (const e of Data.Tags) {
        const tag = getEntity(spaceClientTagsSchema, e);
        resolvedTags.push(tag.Name);
      }

      const tagsLine = resolvedTags.join(", ");
      return (
        <div className="text-truncate">
          <TextEllipsis
            truncate
            Component={<div className="text-truncate">{tagsLine}</div>}
            text={tagsLine}
          />
        </div>
      );
    }
  },
  // {
  //   headerIcon: faPollPeople,
  //   width: DefaultColumnsWidth.AssignedGroup,
  //   orderBy: false,
  //   sortable: false,
  //   field: "AccountsInRoom",
  //   title: <FormattedMessage id={"WORKING_IN"} />,
  //   Cell: ({ Data }) => {
  //     return <AccountsInRoomStack accountsInRoom={Data.AccountsInRoom} />;
  //   }
  // },
  {
    headerIcon: faDrawCircleSolid,
    filterIcon: faDrawCircle,
    width: DefaultColumnsWidth.status,
    minSize: DefaultColumnsWidth.minStatusSize,
    required: true,
    field: "Client/Attributes",
    title: <FormattedMessage id={"STATUS"} />,
    Cell: ({ Data }) => {
      return <ClientStatusChanger Data={Data} />;
      //   return <ClientProfile justStatus hideId client={Data} />;
    }
  },
  {
    headerIcon: faIdCardAltSolid,
    width: DefaultColumnsWidth.fiscalNumber,
    filterIcon: faIdCardAlt,
    field: "Client/FiscalNumber",
    title: <FormattedMessage id={"NIF"} />,
    Cell: ({ Data: value }) => (
      <div className="text-truncate">{value.FiscalNumber}</div>
    )
  },
  {
    headerIcon: faIdCardClipSolid,
    width: DefaultColumnsWidth.fiscalNumber,
    filterIcon: faIdCardClip,
    field: "Client/CodeERP",
    title: <FormattedMessage id={"CODEERP"} />,
    Cell: ({ Data: value }) => (
      <div className="text-truncate">{value.CodeERP}</div>
    )
  },
  {
    headerIcon: faEnvelopeSolid,
    filterIcon: faEnvelope,
    width: DefaultColumnsWidth.Email,
    field: "Client/Email",
    title: <FormattedMessage id={"EMAIL"} />,
    Cell: ({ Data: value }) => <EmailColumn email={value.Email} />
  },
  {
    headerIcon: faPhoneSolid,
    filterIcon: faPhone,
    width: DefaultColumnsWidth.Phone,
    field: "Client/PhoneNumber",
    title: <FormattedMessage id={"PHONE_NUMBER"} />,
    Cell: ({ Data: value }) => <PhoneColumn phoneNumber={value.PhoneNumber} />
  },
  {
    headerIcon: faUserCogSolid,
    filterIcon: faUserCog,
    width: DefaultColumnsWidth.Company,
    field: "Manager/Name",
    title: <FormattedMessage id={"MANAGER"} />,
    Cell: ({ Data }) =>
      Data.Manager ? <ConnectedAccountPopup account={Data.Manager} /> : "―"
  },
  {
    headerIcon: faFolderTree,
    width: DefaultColumnsWidth.Origem,
    title: <FormattedMessage id={"ORIGINATED"} />,
    orderBy: false,
    sortable: false,
    field: "originated",
    Cell: ({ Data }) => (
      <EntityGridChildrenProgress id={Data.Id} />
      // <GetOriginChildren schema={spaceProjectsSchema} id={Data.Id} />
    )
  },
  {
    headerIcon: faGlobeSolid,
    filterIcon: faGlobe,
    width: DefaultColumnsWidth.Origem,
    title: <FormattedMessage id={"DOMAIN"} />,
    orderBy: false,
    sortable: false,
    field: "Client/Domain",
    Cell: ({ Data }) => (
      <div className="text-truncate">{Data.Domain || "―"}</div>
    )
    // <GetOriginChildren schema={spaceProjectsSchema} id={Data.Id} />
  },
  {
    headerIcon: faLocationSolid,
    filterIcon: faLocation,
    // orderBy: false,
    // sortable: false,
    width: DefaultColumnsWidth.ReceivedDate,
    field: "Location/Locality",
    title: <FormattedMessage id={"LOCATION"} />,
    Cell: ({ Data: value }) => {
      let Local = undefined;
      if (value?.Addresses && value.Addresses[0]?.Locality) {
        Local = value.Addresses[0]?.Locality;
      }
      return <div className="text-truncate">{Local || "―"}</div>;
    }
  },
  // {
  //   headerIcon: faFileContract,
  //   width: DefaultColumnsWidth.ReceivedDate,
  //   field: "Client/ContractCount",
  //   title: <FormattedMessage id={"CONTRACTS"} />,
  //   Cell: ({ Data: value }) => (
  //     <div className="text-truncate w-100 d-flex justify-content-end">
  //       {value.ContractCount || "―"}
  //     </div>
  //   ),
  // },
  {
    headerIcon: faUserTieSolid,
    filterIcon: faUserTie,
    width: DefaultColumnsWidth.buttons,
    field: "Client/ContactsCount",
    orderBy: false,
    sortable: false,
    title: <FormattedMessage id={"CONTACTS"} />,
    Cell: ({ Data: value }) => {
      //   console.log(Data);
      if (!value.Contacts) return <div>―</div>;
      return <ConnectedContactProfileStack contacts={value.Contacts} />;
    }
  },
  {
    headerIcon: faCalendarAltSolid,
    filterIcon: faCalendarAlt,
    width: DefaultColumnsWidth.relativeTime,
    field: "Client/CreationDate",
    title: <FormattedMessage id={"CREATION_DATE"} />,
    Cell: ({ Data }) => (
      <div className="text-truncate">
        <RelativeTime date={Data.CreationDate} />
      </div>
    )
  },
  {
    headerIcon: faUserPlusSolid,
    filterIcon: faUserPlus,
    width: DefaultColumnsWidth.Company,
    field: "Creator/Name",
    title: <FormattedMessage id={"CREATED_BY"} />,
    Cell: ({ Data }) => <ConnectedAccountPopup account={Data.Creator} />
  },
  {
    headerIcon: faCalendarAltSolid,
    filterIcon: faCalendarAlt,
    orderBy: false,
    sortable: false,
    width: DefaultColumnsWidth.ReceivedDate,
    field: "Client/CreationDate",
    title: <FormattedMessage id={"CREATION_DATE"} />,
    Cell: ({ Data }) => <GridDateComponent date={new Date(Data.CreationDate)} />
  }
  // {
  //   width: 49,
  //   className: "p-1",
  //   Cell: ({ Data: value }) => (<ClientFormButton id={value.Id} canEdit={value.CanEdit} />)
  // }
];

export const ClientFilterColumns = [
  {
    propField: "Email",
    title: "Email",
    field: "Client/Email",
    type: "string",
    icon: <FontAwesomeIcon icon={faEnvelope} />
  },
  {
    propField: "Locality",
    icon: <FontAwesomeIcon icon={faCity} />,
    titleId: "CITY",
    field: "Location/Locality",
    type: "string"
  },
  {
    propField: "PostalCode",
    icon: <FontAwesomeIcon icon={faRoad} />,
    titleId: "POSTAL_CODE",
    field: "Location/PostalCode",
    type: "string"
  },
  createUserFilter({
    propField: "Creator",
    titleId: "CREATED_BY",
    field: "Creator/Id",
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"CREATED_BY"} />{" "}
          <ConnectedAccountProfile account={value} />
        </div>
      );
    }
  }),
  createClientAtributesFilter(),
  createReadFilter({
    baseField: "Client"
  }),
  {
    propField: "Id",
    icon: <FontAwesomeIcon icon={faSuitcase} />,
    titleId: "ACCOUNT_NR",
    field: "Client/Id",
    type: "number"
  },
  // {
  //   icon: <FontAwesomeIcon icon={faUserTie} />,
  //   titleId: "CONTACTS_NR",
  //   field: "Client/ContactsCount",
  //   type: "number",
  // },
  {
    icon: <FontAwesomeIcon icon={faFileImport} />,
    titleId: "IMPORT_NR",
    field: "Import/Id",
    type: "number"
  },
  {
    icon: <FontAwesomeIcon icon={faIdCardClip} />,
    titleId: "CODEERP",
    field: "Client/CodeERP",
    type: "string"
  },
  {
    icon: <FontAwesomeIcon icon={faIdCardAlt} />,
    titleId: "NIF",
    field: "Client/FiscalNumber",
    type: "string"
  },
  {
    icon: <FontAwesomeIcon icon={faGlobe} />,
    titleId: "DOMAIN",
    field: "Client/Domain",
    type: "string"
  },

  // { title: "Qtd. de Contratos", field: "Client/ContractCount", type: "number" },
  createUnreadFilter({
    baseField: "Client"
  }),
  {
    icon: <FontAwesomeIcon icon={faAlignJustify} />,
    titleId: "NAME",
    field: "Client/Name",
    type: "string"
  },
  createFollowingFilter({
    baseField: "Client"
  }),
  createNotFollowingFilter({
    baseField: "Client"
  }),
  createClientTagFilter({ requiresParams: true }),
  {
    icon: <FontAwesomeIcon icon={faPhone} />,
    titleId: "PHONE_NUMBER",
    field: "Client/PhoneNumber",
    type: "string"
  },
  createClientTypeFilter(),
  createUserFilter({
    titleId: "MANAGER",
    field: "Manager/Id",
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"MANAGER_IS"} />{" "}
          <ConnectedAccountProfile maxWidth account={value} />
        </div>
      );
    },
    icon: <FontAwesomeIcon icon={faUser} />
  })
];

const defaultOrderBy = { column: "Client/Id", type: "desc" };

const ClientOverviewComp = function CustomerBase() {
  const intl = useIntl();

  const favoriteItem = useContext(ServerAwareFavoritesContext);

  const pageTitle = useMemo(() => {
    if (favoriteItem) {
      return `${favoriteItem.data.title} - ${intl.formatMessage({
        id: "ACCOUNTS"
      })}`;
    } else return intl.formatMessage({ id: "ACCOUNTS" });
  }, [favoriteItem, intl]);

  useAirdeskTitle(pageTitle);

  const [selectedItems, setSelectedItems] = useState([]);
  const handleItemSelection = useCallback((fn) => {
    setSelectedItems((t) => {
      const resolvedNewItems = fn(t);
      return resolvedNewItems;
    });
  }, []);

  const SelectedResolvedArr = useMemo(() => {
    return selectedItems.map((t) => {
      return { type: InboxTypeEnum.Client, id: t };
    });
  }, [selectedItems]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);
  const ClearSelection = useCallback(() => {
    setSelectedItems([]);
  }, []);
  const columnResize = true;
  const hasClientEditPermission = useCurrentUserEditClientPermission();
  return (
    <PageContainer>
      <GridActionContext.Provider value={ClientEntityActionsChildren}>
        <SelectedArrMsgContext.Provider value={SelectedResolvedArr}>
          <ServerAwareProvider
            columns={customersColumns}
            filterColumns={ClientFilterColumns}
            moduleType={moduleTypes.clients}
            defaultOrderBy={defaultOrderBy}
            schema={spaceClientSchema}
          >
            <EntityGridChildrenProgressProvider
              roomType={RealTimeRoomTypes.Client}
              schema={spaceClientSchema}
            >
              <Modal
                containerClassName=""
                isOpen={modalIsOpen}
                onClose={closeModal}
              >
                <ColumnChooser
                  schemaView={spaceClientSchema}
                  grid={"customers"}
                  Name="customers"
                  Columns={customersColumns}
                  onClose={closeModal}
                  setIsOpen={setModalIsOpen}
                  isOpen={modalIsOpen}
                />
              </Modal>
              <DeleteMultipleModalHandler
                handleItemSelection={setSelectedItems}
              >
                <GridRequestProvider toggleColumnChooser={setModalIsOpen}>
                  <PageHeader
                    toggleFilterList
                    schema={spaceClientSchema}
                    title={intl.formatMessage({ id: "ACCOUNTS" })}
                    unreadOptions={{
                      Text: true,
                      IsService: true,
                      EnableDeleteAll: true,
                      Children: hasClientEditPermission && (
                        <>
                          <ActionButtonItem
                            component={
                              <CLientManagerMultipleButton
                                selectedItems={{
                                  selectedItems,
                                  ClearSelection
                                }}
                              />
                            }
                          ></ActionButtonItem>
                          <ActionButtonItem
                            component={
                              <MultiMarkerChanger
                                selectedItems={{
                                  selectedItems,
                                  ClearSelection
                                }}
                              />
                            }
                          ></ActionButtonItem>
                        </>
                      )
                    }}
                  >
                    <div className="d-flex ml-2 justify-content-start flex-1 align-items-center">
                      <ClientFormButton />
                      <ServerAwareQueryInput
                        placeholder={intl.formatMessage({ id: "SEARCH" })}
                        className="mx-2"
                      />
                    </div>
                    <div className="d-flex">
                      <SaveFavoriteButton />
                      <ActionButtonOptions
                        moduleType={moduleTypes.clients}
                        enableColumnEdit={() => {
                          setModalIsOpen(true);
                        }}
                        columnResize={columnResize}
                        spaceType={spaceClientSchema.name}
                        type="customers"
                      />
                    </div>
                  </PageHeader>
                  <PageContent>
                    <ServerAwareFilterList
                      filterType={FilterViewTypeEnum.Client}
                    />
                    <ClientGrid
                      columnResize={columnResize}
                      className="flex-1"
                      onItemSelect={handleItemSelection}
                      selectedItems={selectedItems}
                    />
                  </PageContent>
                </GridRequestProvider>
              </DeleteMultipleModalHandler>
            </EntityGridChildrenProgressProvider>
          </ServerAwareProvider>
        </SelectedArrMsgContext.Provider>
      </GridActionContext.Provider>
    </PageContainer>
  );
};

export default ClientOverviewComp;
