import React from "react";
import classnames from "classnames";
import { useSubscriptionType } from "../../Hooks/EntityHooks";

const SubscriptionTypeProfile = ({ subscriptionType, className }) => {
  if (!subscriptionType) return null;
  return (
    <div
      className={classnames(
        "d-flex align-items-center text-truncate",
        className
      )}
    >
      <div className="flex-1 text-truncate">
        <div className="text-truncate">
          {subscriptionType.Type} - {subscriptionType.Name}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionTypeProfile;

export const ConnectSubscriptionTypeEntity = Component => {
  return ({ subscriptionType, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const a = useSubscriptionType(subscriptionType);
    return <Component subscriptionType={a} {...rest} />;
  };
};

export const ConnectedSubscriptionTypeProfile = ConnectSubscriptionTypeEntity(
  SubscriptionTypeProfile
);

// const mapStateToProps = (state, ownProps) => {
//   return {
//     contracttype: state.Entities.SpaceContractTypes[ownProps.contracttype]
//   };
// };

// export const ConnectedContractTypeProfile = connect(mapStateToProps)(
//   ContractTypeProfile
// );
