import {
  faInboxIn,
  faBell as faBellLight
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useRouteMatch } from "react-router-dom";
import {
  spaceContactSchema,
  spaceEmailConversationSchema
} from "../../config/schema";
import { ConnectedAccountPopup } from "../../Containers/Origin/AcountPopup";
import { EmailSendingStatus } from "../../Containers/Reception/EmailChatComponent";
import { useResolvedMessage } from "../../Containers/Reception/MessageListComponentChooser/MessageListComponentChooserHelper";
import {
  InboxMessageListComponents,
  MessageitemProfileDot,
  MessageItemProfileStatus,
  ReceptionItemTypeEnum
} from "../../Containers/Reception/ReceptionCompMsgList";
import { useMultipleEntityValueSelector } from "../../Hooks/AdvancedEntityHooks";
import {
  useContact,
  useEmail,
  useEmailConversation
} from "../../Hooks/EntityHooks";
import { ConnectedAccountProfile } from "../Accounts/AccountProfile";
import TextEllipsis from "../TextEllipsis/TextEllipsis";
import moment from "moment";
import "./DetailsEmailList.css";
import { ConversationTypeEnum } from "../../Containers/Reception/ReceptionHelpers";
import classnames from "classnames";
import PreviewEmailButton from "../../Containers/Reception/PreviewEmailButton";
import { useSpacePost } from "../../Helpers/IOClient";
import { faBell } from "@fortawesome/pro-solid-svg-icons";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
const selector = (v) => v.Name;

const EntityType = (Data) => {
  let EntityKey;

  const {
    EmailConversation,
    Client,
    Deal,
    Contract,
    Subscription,
    Call,
    Project,
    Ticket,
    Task,
    TimeCharge
  } = Data;

  if (EmailConversation)
    EntityKey = `${ConversationTypeEnum.Email}-${EmailConversation}`;
  else if (Client) EntityKey = `${ConversationTypeEnum.Client}-${Client}`;
  else if (Deal) EntityKey = `${ConversationTypeEnum.Deal}-${Deal}`;
  else if (Contract) EntityKey = `${ConversationTypeEnum.Contract}-${Contract}`;
  else if (Subscription)
    EntityKey = `${ConversationTypeEnum.Subscription}-${Subscription}`;
  else if (Call) EntityKey = `${ConversationTypeEnum.Call}-${Call}`;
  else if (Project) EntityKey = `${ConversationTypeEnum.Project}-${Project}`;
  else if (Ticket) EntityKey = `${ConversationTypeEnum.Ticket}-${Ticket}`;
  else if (Task) EntityKey = `${ConversationTypeEnum.Task}-${Task}`;
  else if (TimeCharge) EntityKey = `${ConversationTypeEnum.Time}-${TimeCharge}`;

  return EntityKey;
};

const MessageItemEmail = React.memo(
  ({ Data, onMessageClick, OpenedId, index, len }) => {
    const Conversation = useEmailConversation(Data);
    const {
      LastEmail,
      Status,
      // IsMessageRead,
      // IsFollowing,
      Manager,
      IsFollowing
    } = Conversation;

    const EmailContent = useEmail(LastEmail);
    const {
      FromContact,
      ToContacts,
      Account,
      // Id,
      SentDate,
      Status: EmailStatus
    } = EmailContent;

    const [followPost] = useSpacePost(
      `EmailConversation/${Data}/Follow`,
      spaceEmailConversationSchema,
      {
        onSuccess: ({ Data }) => {
          // console.log(Data);
        }
      }
    );

    const [unfollowPost] = useSpacePost(
      `EmailConversation/${Data}/Unfollow`,
      spaceEmailConversationSchema,
      {
        onSuccess: ({ Data }) => {
          // console.log(Data);
        }
      }
    );

    const Contact = useContact(FromContact);

    // const { paramsUrl } = useServerAwareState();

    const toContacts = useMultipleEntityValueSelector(
      spaceContactSchema,
      ToContacts || [],
      selector
    );

    const match = useRouteMatch();
    const LocalStorageModules = useResolvedMessage(
      InboxMessageListComponents,
      "air-inbox"
    );

    let ResolvedModules = {};
    for (const e of LocalStorageModules) {
      const { field } = e;
      ResolvedModules[field] = true;
    }

    const handleBellClick = () => {
      if (IsFollowing) {
        unfollowPost();
      } else {
        followPost();
      }
    };

    return (
      <div
        className={classnames(
          "d-flex of-hidden disableTextDecoration py-2 mx-3",
          { "border-bottom": Boolean(index < len - 1) }
        )}
      >
        <Link
          to={`${match.url}/inbox/${ReceptionItemTypeEnum.Email}/${Data}?conversationTypes=1&entityNoteType=0`}
        >
          <div className="mr-2">
            {ResolvedModules.status && (
              <MessageItemProfileStatus Status={Status} />
            )}
            {!ResolvedModules.status &&
              ResolvedModules.status !== undefined && (
                <MessageitemProfileDot Status={Status} />
              )}
          </div>
        </Link>
        <div className="d-flex flex-1 flex-column justify-content-between of-hidden">
          <Link
            style={{ textDecoration: "none" }}
            className="overflow-hidden"
            to={`${match.url}/inbox/${ReceptionItemTypeEnum.Email}/${Data}?conversationTypes=1&entityNoteType=0`}
          >
            <div className="d-flex justify-content-between text-black">
              <div className="fs-14 fw-medium flex-1 text-truncate">
                <div style={{ textDecoration: "none" }}>
                  {Contact ? Contact.Name : toContacts.join(", ")}
                </div>
              </div>
              <div className="flex-0-0-auto fs-12 ml-3">
                {ResolvedModules.date &&
                  moment(SentDate).format("DD/MM/YYYY, HH:mm")}
              </div>
            </div>
          </Link>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center w-100">
              <Link
                style={{ textDecoration: "none" }}
                className="overflow-hidden w-100"
                to={`${match.url}/inbox/${ReceptionItemTypeEnum.Email}/${Data}?conversationTypes=1&entityNoteType=0`}
              >
                <div className="d-flex align-items-center w-100">
                  {Account ? (
                    <>
                      {ResolvedModules.sentStatus && (
                        <EmailSendingStatus
                          className="mr-2"
                          Status={EmailStatus}
                        />
                      )}
                      {ResolvedModules.user && (
                        <ConnectedAccountProfile
                          size="xsm"
                          justImage
                          account={Account}
                        />
                      )}
                    </>
                  ) : (
                    ResolvedModules.sentStatus && (
                      <FontAwesomeIcon size="sm" icon={faInboxIn} />
                    )
                  )}
                  <div className="fs-12 ml-2 flex-1 text-grey-light text-truncate">
                    {ResolvedModules.description && (
                      <div
                        style={{ textDecoration: "non" }}
                        className="text-truncate"
                      >
                        <TextEllipsis
                          length={85}
                          truncate
                          text={EmailContent.Subject}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Link>
              {Manager && (
                <div className="ml-2">
                  <ConnectedAccountPopup
                    size="xsm"
                    justImage
                    account={Manager}
                  />
                </div>
              )}
              <PreviewEmailButton
                noPreview
                className="ml-2"
                emailConversationId={Data}
              />
              <div
                onClick={handleBellClick}
                className={classnames("cursor-pointer ml-3", {
                  "text-primary": IsFollowing
                })}
              >
                {IsFollowing && <FontAwesomeIcon icon={faBell} />}
                {!IsFollowing && <FontAwesomeIcon icon={faBellLight} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const DetailsEmailList = React.memo(({ Messages }) => {
  return (
    <>
      {Messages.map((e, i, arr) => {
        const len = arr.length;
        const key = EntityType(e);
        const { EmailConversation } = e;
        if (!key) return null;
        return (
          <MessageItemEmail
            index={i}
            len={len}
            key={i + key}
            Data={EmailConversation}
          />
        );
      })}
    </>
  );
});

export default DetailsEmailList;
