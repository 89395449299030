import React from "react";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { Tr } from "../../Components/Table";
import { useCall } from "../../Hooks/EntityHooks";
import { spaceCallsSchema } from "../../config/schema";
import RelativeTime from "../../Components/RelativeTime/RelativeTime";
import { ConnectedContactProfile } from "../../Components/Contacts/ContactProfile";
import {
  DashboardTable,
  DashboardTitle,
  DashboardEmptyResponse,
} from "./Dashboard";
import { faPhone } from "@fortawesome/pro-solid-svg-icons";
import { CallFormButton } from "../Form/FormButtons";
import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
import { CallEntityGridButtons } from "../Calls/CallsHelper";
import { CallLink } from "../../Helpers/HooksHelper";
import { FormattedMessage } from "react-intl";
import { useDashboardQuery } from "./DashboardQueryHelper";

const columns = [
  {
    minWidth: 90,
    width: 90,
    Cell: (value) => <CallEntityGridButtons entity={value} />,
  },
  {
    title: "Nº",
    Cell: ({ Id }) => <CallLink id={Id}>{Id}</CallLink>,
  },
  {
    maxWidth: 180,
    title: <FormattedMessage id={"ACCOUNT"} />,
    Cell: ({ Client, IndirectClient }) => (
      <ConnectedClientOrigin client={Client || IndirectClient} />
    ),
  },
  {
    title: <FormattedMessage id={"DESCRIPTION"} />,
    Cell: ({ NoTagDescription }) => (
      <span className="text-black">{NoTagDescription}</span>
    ),
  },
  {
    title: <FormattedMessage id={"CONTACT"} />,
    Cell: ({ Contact }) => <ConnectedContactProfile contact={Contact} />,
  },
  {
    title: <FormattedMessage id={"DATE"} />,
    Cell: ({ ReceivedDate }) => <RelativeTime date={ReceivedDate} />,
  },
];

const CallTr = ({ value, ...rest }) => {
  const call = useCall(value);
  return <Tr value={call} {...rest} />;
};

const DashboardCallOverview = (props) => {
  const { data, loading, error } = useDashboardQuery(
    "dashboard/calls",
    [spaceCallsSchema],
    { cache: false }
  );

  const buildBody = () => {
    if (loading || error)
      return <LoaderSpinner size="sm text-secondary" center />;

    if (data?.length === 0)
      return (
        <DashboardEmptyResponse type="calls">
          <FormattedMessage id={"EMPTY_CALLS"} />
        </DashboardEmptyResponse>
      );

    return (
      <DashboardTable TrComponent={CallTr} columns={columns} data={data} />
    );
  };

  return (
    <React.Fragment>
      <DashboardTitle
        text={<FormattedMessage id={"RECEIVE_CALLS"} />}
        icon={faPhone}
        actionButton={<CallFormButton vType="primary-ghost" />}
      />
      {buildBody()}
    </React.Fragment>
  );
};

export default DashboardCallOverview;
