import React from "react";
import Calendar from "../../../../Components/Calendar/Calendar";
import {
  CalendarItem,
  CalendarViewEnum
} from "../../../../Components/Calendar/CalendarHelper";
import { spaceSocialPostsSchema } from "../../../../config/schema";
import { moduleTypes } from "../../../../Helpers/ModulesHelper";
import { useSocialPost } from "../../../../Hooks/EntityHooks";
import {
  ConnectedSocialAccountComponentForSocialPostGrid,
  PostStatusProfile
} from "../../SocialPostsHelper";
import monthClasses from "../../../../Components/Calendar/CalendarViews/CalendarMonthView/CalendarMonthView.module.css";
import moment from "moment";
import classnames from "classnames";
import { useEntitySummarySidebar } from "../../../../Components/EntitySummaries/EntitySummariesHelper";
import { socialPostCommunicator } from "../../socialPostCommunicator";
import { ColorFilterBars } from "../../../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
// import { taskCommunicator } from "../../TasksHelper";

const SocialPopup = ({ entityId }) => {
  return null;
  // const { loading, error, data } = useSpaceQuery(
  //   `socialPosts/${entityId}/`,
  //   spaceTasksSchema
  // );

  // const post = useSocialPost(data);

  // if (loading || error) return null;

  // return <div>OI</div>;
};

const WeeklyItem = ({ item }) => {
  const {
    Social,
    Status,
    Text
    // Schedule,
    // Id
  } = item.metadata;
  // const formattedDate = moment(new Date(Schedule)).format("HH:mm");

  return (
    <div className="flex-1 d-flex align-items-center text-truncate pl-2">
      <ConnectedSocialAccountComponentForSocialPostGrid
        className="mr-1"
        containerStyle={{ width: 82 }}
        style={{ height: 16 }}
        SocialAccount={Social}
      />
      <PostStatusProfile className="ml-2" hideName status={Status} />
      <span className="text-truncate">{Text}</span>
    </div>
  );
};

const Item = ({ item }) => {
  const { Social, Status, Text, Schedule, Id } = item.metadata;
  const formattedDate = moment(new Date(Schedule)).format("HH:mm");

  const openForm = useEntitySummarySidebar();

  return (
    <CalendarItem
      onClick={() => {
        openForm({
          type: moduleTypes.socialPost,
          props: {
            id: Id
          }
        });
      }}
    >
      <ColorFilterBars entity={item.metadata} />
      <div className="flex-1 d-flex align-items-center text-truncate pl-2">
        <ConnectedSocialAccountComponentForSocialPostGrid
          className="mr-1"
          containerStyle={{ width: 82 }}
          style={{ height: 16 }}
          SocialAccount={Social}
        />
        <PostStatusProfile
          className="ml-2 flex-0-0-auto"
          hideName
          status={Status}
        />
        <span className="text-truncate">{Text}</span>
      </div>
      <div className={classnames("mr-1", monthClasses.dateBadge)}>
        {formattedDate}
      </div>
    </CalendarItem>
  );
};

const settings = {
  schema: spaceSocialPostsSchema,
  communicator: socialPostCommunicator,
  getKey: (v) => v,
  TextComp: ({ value }) => {
    const { Name } = useSocialPost(value);
    return <span>{Name}</span>;
  },
  CalendarItem: Item,
  WeeklyItem,
  BeginDateField: "SocialPost/PostDate",
  EndDateField: "SocialPost/PostDate",
  BeginDateFieldObj: "Schedule",
  moduleType: moduleTypes.socialPost,
  EntityPopup: SocialPopup
};

const SocialPostsCalendar = () => {
  return <Calendar settings={settings} defaultView={CalendarViewEnum.Week} />;
};

export default SocialPostsCalendar;
