import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback
} from "react";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import PercentageBar from "../../Components/PercentageBar/PercentageBar";
import Popup from "../../Components/Popup/Popup";
import { useHoverEvents } from "../Origin/Origin";
import {
  useChildrenCountQuery,
  ChildrenCountBody
} from "./EntityChildrenProgressHelper";
import classnames from "classnames";
import { useHoverState } from "../../Helpers/MiscHelper";
import { useSpace } from "../../Contexts/SpaceContext";
import { client } from "../../Helpers/IOClient";
import { useSpaceEntitiesHub } from "../RealTime/RealTimeSpace";
import { FormattedMessage } from "react-intl";
import EntityPopup from "../EntityPopup/EntityPopup";
import { EntityPopupContext } from "../../Components/EntityPopup/EntityPopupHelper";

export const HoverEntityChildrenProgress = (props) => {
  const isHovering = useHoverState();

  const [show, setShow] = useState();

  useEffect(() => {
    if (show) return;

    if (isHovering) {
      const timeout = setTimeout(() => {
        setShow(true);
      }, 200);

      return () => clearTimeout(timeout);
    }
  }, [isHovering, show]);

  if (show) return <EntityChildrenProgress {...props} />;

  return (
    <div className="text-secondary">
      <FormattedMessage id="HOVER_ME_TO_SHOW" />
    </div>
  );
};

const ChildrenIncludeType = {
  Calls: 1,
  Tickets: 2,
  Tasks: 4,
  TimeCharges: 8,
  Projects: 16,
  Contracts: 32,
  Deals: 64,
  Subscription: 128
};

const EntityChildrenProgressComponent = ({
  data,
  placeholder,
  className,
  schema,
  placement,
  forcedType,
  id
}) => {
  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  const anchorRef = useRef();
  const popupRef = useRef();

  const [complete, Total, percentage] = useMemo(() => {
    const {
      TotalFilteredCalls,
      TotalFilteredTickets,
      TotalFilteredTasks,
      TotalFilteredTimeCharges,
      TotalFilteredProjects,
      TotalFilteredContracts,
      TotalFilteredDeals,
      TotalTickets,
      Total
    } = data;

    let complete, percentage;

    if (forcedType === ChildrenIncludeType.Tickets) {
      complete = TotalFilteredTickets;

      percentage = ((100 * complete) / TotalTickets).toFixed(0);

      return [complete, TotalTickets, percentage];
    } else {
      complete =
        (TotalFilteredCalls || 0) +
        (TotalFilteredTickets || 0) +
        (TotalFilteredTasks || 0) +
        (TotalFilteredTimeCharges || 0) +
        (TotalFilteredProjects || 0) +
        (TotalFilteredContracts || 0) +
        (TotalFilteredDeals || 0);

      percentage = ((100 * complete) / Total).toFixed(0);
      return [complete, Total, percentage];
    }
  }, [data, forcedType]);

  // ;

  if (Total === 0)
    return (
      placeholder || <div className="w-100 d-flex justify-content-end">―</div>
    );

  return (
    <>
      <div
        ref={anchorRef}
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        className={classnames("w-100 px-2", className)}
      >
        <PercentageBar
          disableAnimation
          className="alt"
          percentage={percentage}
        />
        <div className="text-right mt-1">
          <span className="text-black-50 mr-1">
            <FormattedMessage id="COMPLETED" />:
          </span>
          <span className="text-black">
            {complete}/{Total}
          </span>
        </div>
      </div>
      {Total !== 0 && (
        <EntityPopupContext.Provider value={onPopupMouseLeave}>
          <Popup
            anchorEl={anchorRef.current}
            domRef={popupRef}
            modifiers={{
              preventOverflow: { boundariesElement: "viewport" }
            }}
            isOpen={isOpen}
            placement={placement || "bottom-center"}
            onMouseEnter={onPopupMouseEnter}
            onMouseLeave={onPopupMouseLeave}
          >
            <EntityPopup id={id} schema={schema} forcedType={forcedType} />
          </Popup>
        </EntityPopupContext.Provider>
      )}
    </>
  );
};

const EntityChildrenProgress = ({ schema, id, ...rest }) => {
  const { data } = useChildrenCountQuery(schema, id);

  if (!data) return <LoaderSpinner size="xs" className="text-primary" />;

  return (
    <EntityChildrenProgressComponent
      data={data}
      id={id}
      schema={schema}
      {...rest}
    />
  );
};

export default EntityChildrenProgress;

const EntityChildrenGridContext = React.createContext();

const useChildrenUpdates = (
  roomType,
  stateRef,
  listnersRef,
  canceledRequestsRef
) => {
  const entitiesHub = useSpaceEntitiesHub();

  useEffect(() => {
    if (!entitiesHub) return;

    const listener = (type, id, userId) => {
      if (type !== roomType) return;
      //
      stateRef.current[id] = { loading: false, error: null, data: null };

      canceledRequestsRef.current[id] = true;

      if (listnersRef.current[id]) {
        for (const listner of listnersRef.current[id]) {
          listner(stateRef.current[id]);
        }
      }

      // console.log(type, id, userId);
    };

    entitiesHub.on("ChildrenUpdate", listener);

    return () => entitiesHub.off("ChildrenUpdate", listener);
  }, [canceledRequestsRef, entitiesHub, listnersRef, roomType, stateRef]);
};

export const EntityGridChildrenProgressProvider = ({
  schema,
  roomType,
  children
}) => {
  const stateRef = useRef({});

  const listnersRef = useRef({});

  const space = useSpace();

  const canceledRequestsRef = useRef({});

  useChildrenUpdates(roomType, stateRef, listnersRef, canceledRequestsRef);

  const fetch = useCallback(
    async (id) => {
      delete canceledRequestsRef.current[id];
      const url = `${schema.getEndpoint(space.Id)}/${id}/ChildrenCount`;
      let state = { loading: true, error: null, data: null };

      stateRef.current[id] = state;

      if (listnersRef.current[id]) {
        for (const listner of listnersRef.current[id]) {
          listner(state);
        }
      }

      state = await client.post(url, ChildrenCountBody);

      if (canceledRequestsRef.current[id]) {
        delete canceledRequestsRef.current[id];
        return;
      }

      stateRef.current[id] = state;

      if (listnersRef.current[id]) {
        for (const listner of listnersRef.current[id]) {
          listner(state);
        }
      }
    },
    [schema, space.Id]
  );

  const contextValue = useMemo(() => {
    return {
      stateRef,
      listnersRef,
      fetch,
      schema
    };
  }, [fetch, schema]);

  return (
    <EntityChildrenGridContext.Provider value={contextValue}>
      {children}
    </EntityChildrenGridContext.Provider>
  );
};

const useEntityGridChildrenProgress = (id) => {
  const { listnersRef, stateRef, fetch, schema } = useContext(
    EntityChildrenGridContext
  );

  const [state, setState] = useState(() => {
    return stateRef.current[id] || { loading: false, error: null, data: null };
  });

  useEffect(() => {
    const listner = (newState) => {
      setState(newState);
    };

    const lisntersArr = listnersRef.current;

    if (!lisntersArr[id]) lisntersArr[id] = [];

    lisntersArr[id].push(listner);

    return () => {
      const index = lisntersArr[id].indexOf(listner);

      lisntersArr[id].splice(index, 1);
    };
  }, [id, listnersRef]);

  return [state, schema, () => fetch(id)];
};

export const EntityGridChildrenProgress = ({ id, ...rest }) => {
  const [{ loading, data, error }, schema, fetch] =
    useEntityGridChildrenProgress(id);

  const isHovering = useHoverState() || true;

  useEffect(() => {
    if (loading || data || error || !isHovering) return;

    if (isHovering) {
      const timeout = setTimeout(() => {
        fetch();
      }, 200);

      return () => clearTimeout(timeout);
    }
  }, [data, error, fetch, isHovering, loading]);

  if (loading) return <div>...</div>;

  if (!data) return null;

  return (
    <EntityChildrenProgressComponent
      data={data}
      id={id}
      schema={schema}
      {...rest}
    />
  );
};
