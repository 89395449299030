export const PrintHtml = `<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <title>User Sheet</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-iYQeCzEYFbKjA/T2uDLTpkwGzCiq6soy8tYaI1GyVh/UjpbCx/TYkiZhlZB6+fzT"
      crossorigin="anonymous"
    />
		<style type="text/css">
		span{
			font-size: 12px;
			white-space: nowrap;
		}
		div.border-bottom.border-dark{
			font-size: 12px;
			overflow: hidden;
			text-overflow: ellipsis;
    	white-space: nowrap;
		}

		div:has(> img[style*="display:block"]) {
			--bs-border-opacity: 1;
			width: 100px;
			height: 100px;
			border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
    	border-color: rgba(var(--bs-dark-rgb),var(--bs-border-opacity))!important;
		}

		</style>
  </head>
  <body style="height: 100vh; width: 100vw">
    <div class="p-3 bg-white w-100 h-100">
      <h4 class="text-center">{EMPLOYEE_PERSONAL_INFORMATION}</h4>
      <hr />
      <div class="d-flex justify-content-between">
        <div class="flex-1 w-100 d-flex flex-column">
          <div class="d-flex">
            <span class="fw-semibold">{FULL_NAME}:</span>
            <div class="ms-2 w-100 border-bottom border-dark">{[NAME]}</div>
          </div>
          <div class="d-flex mt-3">
            <span class="fw-semibold">Email:</span>
            <div class="ms-2 w-100 border-bottom border-dark">{[Email]}</div>
          </div>
          <div class="d-flex mt-3">
            <span class="fw-semibold">{DEFAULT_HOUR_COST}:</span>
            <div class="ms-2 w-100 border-bottom border-dark">
              {[StandardCost]}
            </div>
          </div>
        </div>
        <div class="flex-0-0-auto ms-3">
          <div
            class="ms-auton overflow-hidden"
          >
            <img
              onerror="this.style.display='none'"
              src="{[IMAGE_URL]}"
              alt=" "
            />
          </div>
        </div>
      </div>
      <div class="d-flex mt-3">
        <span class="fw-semibold">{CODEERP}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">{[Code]}</div>
        <span class="fw-semibold ms-2">{COLLABORATOR_NR}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">{[CollaboratorNumber]}</div>
      </div>
      <div class="d-flex mt-3">
        <span class="fw-semibold">{ROLE}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">{[RoleName]}</div>
        <span class="fw-semibold ms-2">{CATEGORY}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">
          {[Category]}
        </div>
      </div>

      <!-- <div class="d-flex mt-3">
        <span class="fw-semibold">{COMPANIES}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">
          {[USER_COMPANIES]}
        </div>
      </div> -->
      <!-- <div class="d-flex mt-3">
        <span class="fw-semibold">{DEPARTMENTS}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">
          {[USER_DEPARTMENTS]}
        </div>
      </div>
      <div class="d-flex mt-3">
        <span class="fw-semibold">{TEAMS}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">{[USER_TEAMS]}</div>
      </div>
      <div class="d-flex mt-3">
        <span class="fw-semibold">{COUNTRY}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">{[USER_COUNTRY]}</div>
      </div> -->

			<div class="d-flex mt-3">
        <span class="fw-semibold">{DOCUMENT_TYPE}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">
          {[AccountDocumentType]}
        </div>
      </div>
			
      <div class="d-flex mt-3">
        <span class="fw-semibold">{NUMBER}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">
          {[BINumber]}
        </div>
        <span class="fw-semibold ms-2">{LOCAL}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">
          {[BILocale]}
        </div>
      </div>
      <div class="d-flex mt-3">
        <span class="fw-semibold">{BI_DATE}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">{[BIIssueDate]}</div>
        <span class="fw-semibold ms-2">{BI_EXPIRATION}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">
          {[BIExpirationDate]}
        </div>
      </div>
			<div class="d-flex mt-3">
        <span class="fw-semibold">{NIF}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">{[NIF]}</div>
        <span class="fw-semibold ms-2">{SOCIAL_SECURITY}:</span>
        <div class="ms-2 w-100 border-bottom border-dark">{[SocialSecurity]}</div>
      </div>
    </div>
  </body>
</html>
`;
