import React from "react";

const AirdeskBackgroundSvg = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="710.7296"
      height="650.64"
      viewBox="0 0 888.412 813.3"
      className={className}
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          y1="0.5"
          x2="1"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#a1a1e6" />
          <stop offset="1" stopColor="#ebebf9" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="-0.325"
          y1="-0.511"
          x2="-0.02"
          y2="-0.138"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#3f3f73" />
          <stop offset="1" stopColor="#1f1f4d" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="0.561"
          y1="0.295"
          x2="0.41"
          y2="0.803"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#545499" />
          <stop offset="1" stopColor="#3d3d99" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="0.565"
          y1="0.306"
          x2="0.425"
          y2="0.824"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="3.198"
          y1="3.058"
          x2="-0.576"
          y2="-0.52"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="0.971"
          y1="0.524"
          x2="0.762"
          y2="0.514"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="0.5"
          y1="1.028"
          x2="0.5"
          y2="-0.16"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="0.505"
          y1="0.794"
          x2="0.434"
          y2="0.192"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fa9" />
          <stop offset="1" stopColor="#ff8066" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-9"
          x1="0.697"
          y1="0.638"
          x2="0.451"
          y2="0.526"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="0.287"
          y1="0.5"
          x2="0.531"
          y2="0.5"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="-0.643"
          y1="0.455"
          x2="-0.082"
          y2="0.477"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="0.531"
          y1="0.579"
          x2="0.488"
          y2="0.306"
          xlinkHref="#linear-gradient-8"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="0.451"
          y1="0.867"
          x2="0.571"
          y2="-0.063"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-14"
          x1="3.183"
          y1="0.412"
          x2="-0.309"
          y2="0.531"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-15"
          x1="-0.221"
          y1="0.486"
          x2="0.652"
          y2="0.503"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-16"
          x1="0.524"
          y1="0.776"
          x2="0.257"
          y2="-0.537"
          xlinkHref="#linear-gradient-8"
        />
        <linearGradient
          id="linear-gradient-17"
          x1="0.5"
          y1="1.089"
          x2="0.5"
          y2="0.305"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#5151cc" />
          <stop offset="1" stopColor="#a1a1e6" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-18"
          x1="0.067"
          y1="0.415"
          x2="0.875"
          y2="0.573"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-19"
          x1="-0.046"
          y1="0.443"
          x2="1.025"
          y2="0.507"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-20"
          x1="0.189"
          y1="0.444"
          x2="0.771"
          y2="0.581"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#7070cc" />
          <stop offset="1" stopColor="#5c5ce6" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-21"
          x1="0.5"
          y1="1.965"
          x2="0.5"
          y2="0.075"
          xlinkHref="#linear-gradient-17"
        />
        <linearGradient
          id="linear-gradient-22"
          x1="0.193"
          y1="0.423"
          x2="0.761"
          y2="0.565"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-23"
          x1="0.562"
          y1="0.3"
          x2="0.404"
          y2="0.81"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-24"
          x1="0.094"
          y1="0.5"
          x2="1.084"
          y2="0.5"
          xlinkHref="#linear-gradient-20"
        />
        <linearGradient
          id="linear-gradient-25"
          x1="1"
          y1="0.5"
          x2="0"
          y2="0.5"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-26"
          x1="0.633"
          y1="0.419"
          x2="0.796"
          y2="0.098"
          xlinkHref="#linear-gradient-8"
        />
        <linearGradient
          id="linear-gradient-27"
          x1="0.5"
          y1="1.126"
          x2="0.5"
          y2="-0.299"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-28"
          x1="-0.386"
          y1="-7.074"
          x2="1.333"
          y2="7.618"
          xlinkHref="#linear-gradient-17"
        />
        <linearGradient
          id="linear-gradient-29"
          x1="0.593"
          y1="0.633"
          x2="1.331"
          y2="1.695"
          xlinkHref="#linear-gradient-17"
        />
        <linearGradient
          id="linear-gradient-30"
          x1="2.491"
          y1="0.5"
          x2="1.734"
          y2="0.5"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-31"
          x1="0.002"
          y1="0.5"
          x2="1.005"
          y2="0.5"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-32"
          x1="0.5"
          y1="-2.92"
          x2="0.5"
          y2="4.329"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-33"
          x1="0.499"
          y1="0.035"
          x2="0.499"
          y2="1.2"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-34"
          x1="0.501"
          y1="0.035"
          x2="0.501"
          y2="1.2"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-36"
          x1="0.563"
          y1="0.284"
          x2="-0.352"
          y2="1.272"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#c6c6ef" />
          <stop offset="1" stopColor="#7070cc" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-37"
          x1="1.271"
          y1="0.101"
          x2="0.498"
          y2="0.662"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-38"
          x1="0.761"
          y1="0.151"
          x2="-0.006"
          y2="0.712"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-39"
          x1="1.404"
          y1="0.092"
          x2="-0.939"
          y2="1.134"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-40"
          x1="2.131"
          y1="-0.249"
          x2="-0.305"
          y2="0.718"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-41"
          x1="0.922"
          y1="0.064"
          x2="0.189"
          y2="0.731"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-42"
          x1="1.988"
          y1="-0.054"
          x2="0.199"
          y2="0.612"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-43"
          x1="-0.01"
          y1="0.5"
          x2="0.971"
          y2="0.5"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-44"
          x1="0.13"
          y1="0.5"
          x2="1.063"
          y2="0.5"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-45"
          x1="0"
          y1="0.498"
          x2="1"
          y2="0.498"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-46"
          x1="0.072"
          y1="0.5"
          x2="0.984"
          y2="0.5"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-47"
          x1="0.036"
          y1="0.618"
          x2="0.406"
          y2="0.447"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fa9" />
          <stop offset="0.95" stopColor="#ff8066" />
          <stop offset="1" stopColor="#ff8066" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-48"
          x1="0.615"
          y1="-0.238"
          x2="0.263"
          y2="1.643"
          xlinkHref="#linear-gradient-47"
        />
        <linearGradient
          id="linear-gradient-49"
          x1="2.605"
          y1="-3.594"
          x2="2.154"
          y2="-2.735"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-50"
          x1="0.029"
          y1="0.475"
          x2="0.959"
          y2="0.53"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-51"
          x1="1.147"
          y1="0.018"
          x2="-0.157"
          y2="0.888"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-52"
          x1="0"
          y1="0.5"
          x2="1"
          y2="0.5"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-53"
          x1="1.378"
          y1="0.083"
          x2="0.033"
          y2="0.683"
          xlinkHref="#linear-gradient-20"
        />
        <linearGradient
          id="linear-gradient-54"
          x1="3.126"
          y1="0.5"
          x2="2.595"
          y2="0.5"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-55"
          x1="0.064"
          y1="-0.052"
          x2="0.628"
          y2="0.87"
          xlinkHref="#linear-gradient-36"
        />
        <linearGradient
          id="linear-gradient-56"
          x1="0.244"
          y1="-0.08"
          x2="0.808"
          y2="0.842"
          xlinkHref="#linear-gradient-20"
        />
        <linearGradient
          id="linear-gradient-57"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-58"
          x1="0.539"
          y1="1.169"
          x2="0.474"
          y2="0.282"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-59"
          x1="0.192"
          y1="0.5"
          x2="2.971"
          y2="0.5"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g
        id="Group_4390"
        data-name="Group 4390"
        transform="translate(-786.324 -147.7)"
      >
        <path
          id="Path"
          d="M243.921,128.3C437.321,155.9,576.056,19.642,672.178,2.9c144.317-25.186,311.213,113.691,150.58,412.762S590.3,786.573,374.646,784.559C202.138,782.914,69.637,639.223,23.559,532.7-48.562,365.989,50.536,100.71,243.921,128.3Z"
          transform="translate(786.324 147.7)"
          fill="#f3f8fe"
        />
        <g
          id="_10_work"
          data-name="10_work"
          transform="translate(988.523 414.002)"
        >
          <path
            id="Path-2"
            data-name="Path"
            d="M102.557,492.659a3.892,3.892,0,0,1-3.342-1.876c-.323-.528-32.233-53.621-59.539-127.358C-6.086,239.873-4.059,154.095,5.7,103.9,15.819,51.866,37.164,18.954,69.16,6.093c55.723-22.4,122.561,24.029,125.374,26.013a3.9,3.9,0,1,1-4.485,6.371c-.658-.464-66.234-46.021-118.011-25.14C42.63,25.153,22.924,56.124,13.351,105.39S1.848,238.785,46.942,360.6C73.99,433.681,105.587,486.256,105.9,486.73a3.892,3.892,0,0,1-3.342,5.929Z"
            transform="translate(0.491 0.998)"
            fill="url(#linear-gradient)"
          />
          <rect
            id="Rectangle"
            width="15.955"
            height="11.007"
            transform="translate(188.255 391.331)"
            fill="url(#linear-gradient-2)"
          />
          <rect
            id="Rectangle-2"
            data-name="Rectangle"
            width="147.42"
            height="166.716"
            rx="8.74"
            transform="translate(347.684 263.434)"
            fill="url(#linear-gradient-3)"
          />
          <path
            id="Path-3"
            data-name="Path"
            d="M146.7,0H17.766C7.956,0,0,8.312,0,18.564V170.058H166.016V20.213C166.026,9.034,157.381,0,146.7,0Z"
            transform="translate(193.958 360.392)"
            fill="url(#linear-gradient-4)"
          />
          <rect
            id="Rectangle-3"
            data-name="Rectangle"
            width="152.681"
            height="166.716"
            rx="8.74"
            transform="translate(326.534 263.434)"
            fill="url(#linear-gradient-5)"
          />
          <path
            id="Path-4"
            data-name="Path"
            d="M45.061,0l3.169,65.08L0,123.046l15.33,12.71L66.374,79.289,45.061,0Z"
            transform="translate(282.539 245.474)"
            fill="url(#linear-gradient-6)"
          />
          <path
            id="Path-5"
            data-name="Path"
            d="M64.908,4.3S77.391,10.877,87.2,17.335s17.108,29.452,17.949,53.9S97.044,164.3,97.044,164.3,44.792,175.147,11.5,160.378c-11.19-4.97-11.5-4.2-11.5-4.2S10.381,98.111,10.1,91.384,3.633,75.4,3.633,63.054s2.8-38.421,8.139-47.961S28.32,0,28.32,0Z"
            transform="translate(315.84 230.393)"
            fill="url(#linear-gradient-7)"
          />
          <path
            id="Path-6"
            data-name="Path"
            d="M35.075,3l4.5.248,3.59,1.078s-9.573,14.435-20.137,15S9.924,8.247,9.924,8.247,7.4,13.993,5.16,14.273-1.309,10.629.395,6.727,6.561,0,6.561,0Z"
            transform="translate(337.587 230.328)"
            fill="#fff"
          />
          <path
            id="Path-7"
            data-name="Path"
            d="M1.078,0,0,16.968s3.234,5.746,12.494,5.886,17.421-5.282,17.421-5.282L24.633,1.121Z"
            transform="translate(342.736 215.753)"
            fill="url(#linear-gradient-8)"
          />
          <path
            id="Path-8"
            data-name="Path"
            d="M3.279,13.324s-6.813,10.112-.8,32.341c3.439,12.71,8.8,22.24,10.586,32.858S19.6,90.069,24.775,90.748s23.372-6.889,30.4-6.9S70.957,85.541,77.3,81.1c3.568-2.5,5.39-30.788,5.39-30.788L60.738,7.19,47.661,0Z"
            transform="translate(308.453 141.93)"
            fill="url(#linear-gradient-9)"
          />
          <rect
            id="Rectangle-4"
            data-name="Rectangle"
            width="104.202"
            height="128.112"
            transform="translate(62.827 402.338)"
            fill="url(#linear-gradient-10)"
          />
          <rect
            id="Rectangle-5"
            data-name="Rectangle"
            width="35.489"
            height="128.112"
            transform="translate(131.54 402.338)"
            fill="url(#linear-gradient-11)"
          />
          <path
            id="Path-9"
            data-name="Path"
            d="M1.254,33.956C3.787,46.127,6.946,60.81,14.083,72.97s33.085,11.05,40.038-.55,7.4-28.535,7.4-28.535,8.894-.744,10.144-7.665S70.894,19.554,66.5,18.249s-7.622,3.805-7.622,3.805S58.411,9.119,48.148,2.543,3.841,4,2.192,5.938-1.279,21.8,1.254,33.956Z"
            transform="translate(315.254 149.004)"
            fill="url(#linear-gradient-12)"
          />
          <path
            id="Path-10"
            data-name="Path"
            d="M2.724,14.387s-3.051,5.3-2.7,15.491,6.953,13.54,18.035,14.478,18.758-3.126,25.1-2.835,10.672,15.685,10.7,31.672,7.19,17.248,13.443,18.326S97.773,76.427,83.166,47.191c-11.988-24.008-8.915-35.575-29.311-43.8S16.124,4.027,10.41,7.7,2.724,14.387,2.724,14.387Z"
            transform="translate(311.078 133.958)"
            fill="url(#linear-gradient-13)"
          />
          <path
            id="Path-11"
            data-name="Path"
            d="M8.538,0H34.227a8.538,8.538,0,0,1,8.538,8.538v161.52H0V8.538A8.538,8.538,0,0,1,8.538,0Z"
            transform="translate(193.958 360.392)"
            fill="url(#linear-gradient-14)"
          />
          <rect
            id="Rectangle-6"
            data-name="Rectangle"
            width="17.572"
            height="128.112"
            transform="translate(176.376 402.338)"
            fill="url(#linear-gradient-15)"
          />
          <path
            id="Path-12"
            data-name="Path"
            d="M0,4.792S.151,2.528,4.2.825s6.889-1.078,7.967,4.83S10.317,16.23,7.244,17.868.884,16.445.884,16.445Z"
            transform="translate(360.179 181.844)"
            fill="url(#linear-gradient-16)"
          />
          <path
            id="Path-13"
            data-name="Path"
            d="M11.5,133c33.289,14.769,85.541,3.924,85.541,3.924s8.98-68.713,8.139-93.12c-.55-16.063-3.913-31.478-8.937-42.043L67.862,0s4.873,41.7,3.752,50.484-53.244,44.921-59.83,52.8a135.508,135.508,0,0,0-9.5,12.505h0C.959,123.434,0,128.8,0,128.8S.3,128.026,11.5,133Z"
            transform="translate(315.818 257.775)"
            fill="url(#linear-gradient-17)"
          />
          <path
            id="Path-14"
            data-name="Path"
            d="M4.549,0H111.036V112.783H0V4.582A4.582,4.582,0,0,1,4.549,0Z"
            transform="translate(245.552 417.365)"
            fill="url(#linear-gradient-18)"
          />
          <path
            id="Path-15"
            data-name="Path"
            d="M22.6,0,24.27,6.716S6.731,10.371,3.788,11.761-.18,14.1.025,15.879,4.2,18.219,4.2,18.219l42.043-.291L42.176,1.488Z"
            transform="translate(236.191 512.232)"
            fill="#fff"
          />
          <path
            id="Path-16"
            data-name="Path"
            d="M30.4,0s-4.862,6.921-7.1,8.883-17.486,2.8-21.129,4.959S.58,21.388.58,21.388L44.7,21.1l2.426-16.72Z"
            transform="translate(189.401 509.03)"
            fill="#fff"
          />
          <path
            id="Path-17"
            data-name="Path"
            d="M38.547,0S12.556,0,6.757,2.3-.908,8.657.4,19.685,16.47,125.708,16.47,125.708s16.979,5.563,19.4,3.342l2.393-2.221Z"
            transform="translate(241.178 387.353)"
            fill="url(#linear-gradient-19)"
          />
          <path
            id="Path-18"
            data-name="Path"
            d="M106.627,0S68.9,1.013,59.313,2.684,0,122.474,0,122.474s7.1,5.983,13.087,6.36,7.481,0,7.481,0L89.12,33.548,131.993,34.7a20.353,20.353,0,0,0,20.774-22.531L152.2,6.932Z"
            transform="translate(217.653 387.353)"
            fill="url(#linear-gradient-20)"
          />
          <path
            id="Path-19"
            data-name="Path"
            d="M12.645,12.02C1.714,6.695.129.593,0,0,.83,10.931,6.069,19.275,6.328,25.484c.129,3.234-2.156,18.208-4.614,32.955h0S15.308,50.02,22.876,37.267s30.292-21.56,27.069-24.547S24.137,17.636,12.645,12.02Z"
            transform="translate(319.602 296.282)"
            fill="url(#linear-gradient-21)"
          />
          <path
            id="Path-20"
            data-name="Path"
            d="M136.586,0c-6.662,3.892-15.092,8.182-20.871,8.786-10.845,1.078-41.7-6.166-50.861-6.36s1.078,6.921,0,11.589-20.385,10.1-29.915,26.951C25.5,57.631.442,108.2,0,109.182a23.716,23.716,0,0,0,5.024-.119L73.575,13.777l42.873,1.153A20.342,20.342,0,0,0,136.586,0Z"
            transform="translate(233.047 407.189)"
            fill="url(#linear-gradient-22)"
          />
          <path
            id="Path-21"
            data-name="Path"
            d="M0,0H135.367a9.271,9.271,0,0,1,9.271,9.271V170.058H0Z"
            transform="translate(377.955 360.392)"
            fill="url(#linear-gradient-23)"
          />
          <path
            id="Path-22"
            data-name="Path"
            d="M8.538,0H34.227a8.538,8.538,0,0,1,8.538,8.538v161.52H0V8.538A8.538,8.538,0,0,1,8.538,0Z"
            transform="translate(356.578 360.392)"
            fill="url(#linear-gradient-24)"
          />
          <path
            id="Path-23"
            data-name="Path"
            d="M0,16.548H618.171L575.751,0H39.132L0,16.548Z"
            transform="translate(0 530.45)"
            fill="url(#linear-gradient-25)"
          />
          <path
            id="Path-24"
            data-name="Path"
            d="M27.183,0A110.271,110.271,0,0,0,13.46,2.9C7.628,4.679,2.087,5.983.642,8.6s0,3.439,0,3.439h32.47c1.833,0,7.449-4.377,7.449-4.377Z"
            transform="translate(303.77 372.627)"
            fill="url(#linear-gradient-26)"
          />
          <path
            id="Path-25"
            data-name="Path"
            d="M54.667,4.226c-8.074,6.08,13.8,70.729,13.8,70.729L0,126.938s5.207,10.1,10.78,11.589l5.606,1.5s65.759-43.811,73.7-53.136S88.732-21.409,54.667,4.226Z"
            transform="translate(330.759 245.485)"
            fill="url(#linear-gradient-27)"
          />
          <rect
            id="Rectangle-7"
            data-name="Rectangle"
            width="45.891"
            height="5.369"
            rx="0.56"
            transform="translate(301.34 384.669)"
            fill="url(#linear-gradient-28)"
          />
          <path
            id="Path-26"
            data-name="Path"
            d="M18.5,74.287h85.972c1.671,0,2.674-1.639,2.232-3.676L92.135,3.676C91.693,1.649,89.979,0,88.308,0H2.336C.665,0-.338,1.639.1,3.676L14.636,70.611C15.11,72.637,16.792,74.287,18.5,74.287Z"
            transform="translate(204.203 315.751)"
            fill="url(#linear-gradient-29)"
          />
          <path
            id="Path-27"
            data-name="Path"
            d="M18.5,74.287h85.972c1.671,0,2.674-1.639,2.232-3.676L92.168,3.676C91.726,1.649,90.012,0,88.341,0h-86C.665,0-.338,1.639.1,3.676L14.668,70.611C15.11,72.637,16.825,74.287,18.5,74.287Z"
            transform="translate(199.977 315.751)"
            fill="url(#linear-gradient-30)"
          />
          <path
            id="Path-28"
            data-name="Path"
            d="M6.546,3.44A16.645,16.645,0,0,1,5.468,0a4.215,4.215,0,0,0-2.7.884C-.289,3.236-.914,9.4,1.393,14.652s6.651,7.622,9.7,5.282c2.415-1.843,3.234-6.058,2.426-10.338C11.009,9.542,8.228,7.278,6.546,3.44Z"
            transform="translate(242.618 342.441)"
            fill="url(#linear-gradient-31)"
          />
          <rect
            id="Rectangle-8"
            data-name="Rectangle"
            width="147.862"
            height="11.007"
            transform="translate(40.394 391.331)"
            fill="url(#linear-gradient-32)"
          />
          <rect
            id="Rectangle-9"
            data-name="Rectangle"
            width="9.346"
            height="128.112"
            transform="translate(53.481 402.338)"
            fill="url(#linear-gradient-33)"
          />
          <rect
            id="Rectangle-10"
            data-name="Rectangle"
            width="9.346"
            height="128.112"
            transform="translate(167.029 402.338)"
            fill="url(#linear-gradient-34)"
          />
          <rect
            id="Rectangle-11"
            data-name="Rectangle"
            width="104.202"
            height="11.007"
            transform="translate(62.827 460.885)"
            fill="url(#linear-gradient-32)"
          />
          <path
            id="Path-29"
            data-name="Path"
            d="M0,0A37.235,37.235,0,0,1,14.446,7.374L7.374,11.136l9.7-1.358a37.009,37.009,0,0,1,5.39,6.781L9.53,20.224l14.251-1.348c.259.507.507,1.078.744,1.552l-14.51,6.986,15.556-4.409c.356.959.69,1.93.97,2.921A37.278,37.278,0,0,1,27.91,37.914L14.165,39.542l13.583.485a6.357,6.357,0,0,1-.119,1L15.836,42.841l11.546-.2a37.192,37.192,0,0,1-2.275,7.751l-8.969.183L23.684,53.5a37.149,37.149,0,0,1-6.069,8.419C10.565,31.187,0,0,0,0Z"
            transform="translate(83.45 205.027)"
            fill="url(#linear-gradient-36)"
          />
          <path
            id="Path-30"
            data-name="Path"
            d="M21.971,50.624l-7.449,4.722A37.171,37.171,0,0,1,9.67,50.894l9.131-8.2L6.6,47A37.278,37.278,0,0,1,1.423,36c-.086-.323-.151-.636-.237-.959l12.581-5.121L.335,30.659c-.129-1-.216-1.994-.27-2.986l12.807-5.929L0,26.056a37,37,0,0,1,.862-8.128l11.988-4.851L1.5,15.416a37.257,37.257,0,0,1,1.779-4.83l8.074-2.792L3.806,9.422A37.17,37.17,0,0,1,10.4,0S20.968,31.187,27.964,61.922A37.084,37.084,0,0,1,21.5,59.582Z"
            transform="translate(73.036 205.016)"
            fill="#b3b3ea"
          />
          <path
            id="Path-31"
            data-name="Path"
            d="M33.069,0A63.355,63.355,0,0,0,12.253,18.046l13.26,3.234-16.6,1.854A62.525,62.525,0,0,0,2.874,36.491l22.919.679L1.667,40.846c-.226.949-.4,1.908-.582,2.868L27.992,49.19.416,48.382C.222,50.1.082,51.831.028,53.589A62.816,62.816,0,0,0,2.755,73.866L26.17,70.848,3.92,77.262c.194.539.4,1.078.6,1.606l20.235-1.843L5.58,81.456a62.978,62.978,0,0,0,6.975,11.761l14.92-3.407L16.3,97.723a63.3,63.3,0,0,0,13.551,11.319C28.606,55.626,33.069,0,33.069,0Z"
            transform="translate(85.201 223.353)"
            fill="url(#linear-gradient-37)"
          />
          <path
            id="Path-32"
            data-name="Path"
            d="M5.475,87.967l14.284,4.689a62.925,62.925,0,0,0,6.156-9.325l-18.52-9.7L29.375,75.7a62.817,62.817,0,0,0,3.978-20.235V53.793L10.434,50.559l22.52-4.312c-.194-1.692-.474-3.363-.809-5.024L8.493,36.771,31.574,38.5a62.525,62.525,0,0,0-4.819-13L4.86,22.5,24.6,21.647a63.161,63.161,0,0,0-4.948-7.2L5.141,13.2l13.141-.442A63.323,63.323,0,0,0,3.448,0S-1.015,55.626.214,109.042a63.248,63.248,0,0,0,9.767-6.522Z"
            transform="translate(114.8 223.353)"
            fill="url(#linear-gradient-38)"
          />
          <path
            id="Path-33"
            data-name="Path"
            d="M33.969,0A42.5,42.5,0,0,0,18.122,9.293l6.759,4.582-9.788-1.606A53.9,53.9,0,0,0,8.624,20.72l12.8,4.4L6.964,23.609q-.5.954-.97,1.94l13.939,8.484L4.538,28.772c-.5,1.186-.97,2.393-1.4,3.633A58.084,58.084,0,0,0,.108,47.271l13.907,1.865L0,49.88v1.251l11.858,2.156L0,53.157A49.966,49.966,0,0,0,1.294,62.7l9.2.129-8.1,3.708a40.21,40.21,0,0,0,5.1,10.349C18.9,38.744,33.969,0,33.969,0Z"
            transform="translate(101.108 226.976)"
            fill="#b3b3ea"
          />
          <path
            id="Path-34"
            data-name="Path"
            d="M7.622,62.795l7.018,5.767a50.257,50.257,0,0,0,5.584-5.573L11.945,52.931l11.912,5.228A58.073,58.073,0,0,0,30.67,44.468c.14-.4.248-.8.377-1.186l-12.2-6.231,13.7.776c.27-1.24.5-2.469.679-3.708l-12.354-7.2,12.688,5.218a54.171,54.171,0,0,0,.216-10.058L22.11,16.213l11.341,2.771a48.2,48.2,0,0,0-1.175-5.961L24.363,9.659l7.546,1.93A40.642,40.642,0,0,0,26.412,0S11.319,38.733,0,76.874a38.808,38.808,0,0,0,6.986-2.965Z"
            transform="translate(108.74 226.986)"
            fill="url(#linear-gradient-39)"
          />
          <path
            id="Path-35"
            data-name="Path"
            d="M16.947,0A48.975,48.975,0,0,0,4.226,17.119l10.554.259L2.533,21.485A48.317,48.317,0,0,0,.194,32.567L17.6,29.279,0,36.06v2.264l21.194-.313L.291,41.957c.14,1.326.313,2.663.571,4a48.511,48.511,0,0,0,5.39,14.855l17.173-6.156L7.719,63.151c.237.377.474.744.722,1.078l15.006-4.765-13.734,6.6a48.684,48.684,0,0,0,7.223,7.719L27.63,68.735l-7.072,7.826a48.931,48.931,0,0,0,12.106,6.3C22.811,42.744,16.947,0,16.947,0Z"
            transform="translate(68.918 253.711)"
            fill="#b3b3ea"
          />
          <path
            id="Path-36"
            data-name="Path"
            d="M16.106,66.072l11.567,1.164a48.64,48.64,0,0,0,3.1-8.064l-15.6-4.247,16.936-2.081A48.511,48.511,0,0,0,31.759,36.9c-.075-.431-.194-.841-.28-1.261l-17.841,1.4,16.278-7.007c-.431-1.251-.916-2.469-1.445-3.655l-18.6.571,17.712-2.523a48.3,48.3,0,0,0-5.8-9.023L4.808,16.8l14.758-3.924a48.824,48.824,0,0,0-4.937-4.7L3.471,9.681l9.842-2.512A48.953,48.953,0,0,0,0,0S5.864,42.744,15.674,82.857A48.889,48.889,0,0,0,21.938,76.3Z"
            transform="translate(85.865 253.711)"
            fill="url(#linear-gradient-40)"
          />
          <path
            id="Path-37"
            data-name="Path"
            d="M56.65,63.927H7.331L0,0H63.981L56.65,63.927Z"
            transform="translate(73.036 327.404)"
            fill="url(#linear-gradient-41)"
          />
          <path
            id="Path-38"
            data-name="Path"
            d="M0,0,7.331,63.927H26.2L20.73,0Z"
            transform="translate(73.036 327.404)"
            fill="url(#linear-gradient-42)"
          />
          <rect
            id="Rectangle-12"
            data-name="Rectangle"
            width="68.972"
            height="15.75"
            transform="translate(70.546 325.572)"
            fill="#b3b3ea"
          />
          <rect
            id="Rectangle-13"
            data-name="Rectangle"
            width="11.901"
            height="53.88"
            transform="translate(71.43 407.006)"
            fill="#b3b3ea"
          />
          <rect
            id="Rectangle-14"
            data-name="Rectangle"
            width="11.901"
            height="53.88"
            transform="translate(83.331 407.006)"
            fill="url(#linear-gradient-43)"
          />
          <rect
            id="Rectangle-15"
            data-name="Rectangle"
            width="9.303"
            height="53.88"
            transform="translate(95.222 407.006)"
            fill="#fff"
          />
          <rect
            id="Rectangle-16"
            data-name="Rectangle"
            width="37.149"
            height="53.88"
            transform="translate(104.514 407.006)"
            fill="#b3b3ea"
          />
          <rect
            id="Rectangle-17"
            data-name="Rectangle"
            width="8.096"
            height="53.88"
            transform="translate(71.43 476.571)"
            fill="#b3b3ea"
          />
          <rect
            id="Rectangle-18"
            data-name="Rectangle"
            width="8.096"
            height="53.88"
            transform="translate(79.526 476.571)"
            fill="url(#linear-gradient-44)"
          />
          <rect
            id="Rectangle-19"
            data-name="Rectangle"
            width="6.328"
            height="53.88"
            transform="translate(87.622 476.571)"
            fill="#fff"
          />
          <rect
            id="Rectangle-20"
            data-name="Rectangle"
            width="25.28"
            height="53.88"
            transform="translate(93.95 476.571)"
            fill="#b3b3ea"
          />
          <rect
            id="Rectangle-21"
            data-name="Rectangle"
            width="11.901"
            height="53.88"
            transform="translate(104.543 408.211) rotate(-12.21)"
            fill="#fff"
          />
          <rect
            id="Rectangle-22"
            data-name="Rectangle"
            width="11.901"
            height="53.88"
            transform="translate(133.849 408.211) rotate(-12.21)"
            fill="#fff"
          />
          <path
            id="Path-39"
            data-name="Path"
            d="M0,0H29.311L40.706,52.661H11.395L0,0Z"
            transform="translate(116.146 405.712)"
            fill="#b3b3ea"
          />
          <path
            id="Path-40"
            data-name="Path"
            d="M0,2.512,11.632,0H40.943L29.311,2.512Z"
            transform="translate(115.909 458.373)"
            fill="url(#linear-gradient-45)"
          />
          <path
            id="Shape"
            d="M24.665,36.9H3.32A3.324,3.324,0,0,1,0,33.58V0H27.974V6.241h4.14a6.425,6.425,0,0,1,4.581,1.9,6.509,6.509,0,0,1,1.887,4.6V24.191a6.476,6.476,0,0,1-6.468,6.468H27.985V33.58A3.324,3.324,0,0,1,24.665,36.9ZM28.018,9.778V27.134h1.93a5.191,5.191,0,0,0,5.185-5.186V14.963a5.191,5.191,0,0,0-5.185-5.185Z"
            transform="translate(125.493 354.43)"
            fill="url(#linear-gradient-46)"
          />
          <path
            id="Path-41"
            data-name="Path"
            d="M36.5,0H4.4A4.4,4.4,0,0,0,0,4.4V24.17a4.4,4.4,0,0,0,4.4,4.4h7.007l6.662,9.088h2.426l9.045-9.045h7.007a4.4,4.4,0,0,0,4.4-4.4V4.4A4.4,4.4,0,0,0,36.5,0Z"
            transform="translate(223.41 254.411)"
            fill="url(#linear-gradient-47)"
          />
          <path
            id="Path-42"
            data-name="Path"
            d="M36.5,0H4.4A4.4,4.4,0,0,0,0,4.4V24.17a4.4,4.4,0,0,0,4.4,4.4h7.007l9.088,9.088,9.045-9.045h7.007a4.4,4.4,0,0,0,4.4-4.4V4.4A4.4,4.4,0,0,0,36.5,0Z"
            transform="translate(220.984 254.411)"
            fill="url(#linear-gradient-48)"
          />
          <path
            id="Shape-2"
            data-name="Shape"
            d="M76.314,170.08H57.243v-.1H20.849a3.838,3.838,0,0,1-3.816-3.4L7.654,84.658a3.837,3.837,0,0,0-3.81-3.4H3.838A3.843,3.843,0,0,1,0,77.424V57.157A3.842,3.842,0,0,1,3.838,53.32h17.14a3.837,3.837,0,0,0,3.708-4.83L13.669,7.4a3.756,3.756,0,0,0-.96-1.671C10.888,3.9,9.074,1.977,7.319,0H88.409A174.631,174.631,0,0,1,68.8,40.351a3.7,3.7,0,0,0-.571,1.445l-1.2,7.061a3.838,3.838,0,0,0,3.786,4.474h47.69a3.816,3.816,0,0,1,2.7,1.108,3.869,3.869,0,0,1,1.139,2.719V77.424a3.843,3.843,0,0,1-3.838,3.838H118.5a3.837,3.837,0,0,0-3.81,3.4l-9.379,81.929a3.836,3.836,0,0,1-3.812,3.4H76.314v.1ZM61.48,50.484c-.516.652-1.06,1.285-1.585,1.9-.266.309-.54.629-.808.948h2.253C61.39,52.245,61.435,51.34,61.48,50.484Z"
            transform="translate(450.42 360.392)"
            opacity="0.45"
            fill="url(#linear-gradient-49)"
          />
          <path
            id="Path-43"
            data-name="Path"
            d="M5.946,94.273C-5.234,136.661-.124,178.359,16.877,208.792,46.663,190.7,71.705,156.95,82.841,114.519S88.91,30.433,71.91,0C42.113,18.132,17.1,51.885,5.946,94.273Z"
            transform="translate(505.038 214.201)"
            fill="url(#linear-gradient-50)"
          />
          <path
            id="Path-44"
            data-name="Path"
            d="M23.622,0A512.612,512.612,0,0,0,6.277,60.208C-1.809,95.782.229,138,.229,138"
            transform="translate(531.83 306.782)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path-45"
            data-name="Path"
            d="M0,0S4.851,40.76,10.834,72.228s10.036,72.928,10.036,72.928"
            transform="translate(508.342 294.568)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path-46"
            data-name="Path"
            d="M0,0S9.7,26.552,16.828,43.757,30.605,78.663,30.605,78.663"
            transform="translate(498.619 363.011)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path-47"
            data-name="Path"
            d="M2.1,88.344c-5.077,37.149-.668,71.408,10.284,94.564,16.774-19.4,30.185-51.2,35.284-88.344S48.335,23.156,37.382,0C20.619,19.351,7.176,51.2,2.1,88.344Z"
            transform="translate(537.366 57.111)"
            fill="url(#linear-gradient-51)"
          />
          <path
            id="Path-48"
            data-name="Path"
            d="M33.645,131.174C35.176,97.162,37.44,35.78,38.733,0,15.135,29.214,0,73.963,0,124.21c0,49.665,14.8,93.95,37.925,123.175C35.305,213.082,32.254,162.156,33.645,131.174Z"
            transform="translate(471.366 73.853)"
            fill="url(#linear-gradient-52)"
          />
          <path
            id="Path-49"
            data-name="Path"
            d="M46.171,125.493C46.171,74.524,30.615,29.171,6.435,0c-.345.41-.668.862-1,1.283C4.139,37.062,1.864,98.445.344,132.457c-1.391,30.961,1.714,81.93,4.312,116.222.6.765,1.2,1.574,1.811,2.318C30.583,221.8,46.171,176.462,46.171,125.493Z"
            transform="translate(504.699 72.581)"
            fill="#b3b3ea"
          />
          <path
            id="Path-50"
            data-name="Path"
            d="M6.3,68.1c-9.163,30.562-7.977,60.542,1.272,82.383,19.739-13.152,37.213-37.537,46.355-68.1S61.9,21.841,52.654,0C32.937,13.141,15.463,37.537,6.3,68.1Z"
            transform="translate(544.797 164.784)"
            fill="#b3b3ea"
          />
          <path
            id="Path-51"
            data-name="Path"
            d="M100.483,113.376H12.99L0,0H113.462L100.483,113.376Z"
            transform="translate(472.714 416.967)"
            fill="url(#linear-gradient-53)"
          />
          <path
            id="Path-52"
            data-name="Path"
            d="M0,0,12.99,113.376H46.463L36.771,0Z"
            transform="translate(472.714 416.967)"
            fill="url(#linear-gradient-54)"
          />
          <rect
            id="Rectangle-23"
            data-name="Rectangle"
            width="122.334"
            height="27.942"
            transform="translate(468.283 413.722)"
            fill="#b3b3ea"
          />
          <path
            id="Path-53"
            data-name="Path"
            d="M12.243,97.594c13.96,35.219,37.127,62.288,61.825,75.828C39.862,134.785,5.333,0,5.333,0-3.356,26.778-1.685,62.374,12.243,97.594Z"
            transform="translate(432.981 212.735)"
            fill="url(#linear-gradient-55)"
          />
          <path
            id="Path-54"
            data-name="Path"
            d="M61.857,75.828C47.843,40.609,24.7,13.572,0,0,0,0,34.5,134.753,68.735,173.422,77.456,146.644,75.785,111.047,61.857,75.828Z"
            transform="translate(438.346 212.735)"
            fill="url(#linear-gradient-56)"
          />
          <path
            id="Path-55"
            data-name="Path"
            d="M61.857,75.828C47.843,40.609,24.7,13.572,0,0,0,0,34.5,134.753,68.735,173.422,77.456,146.644,75.785,111.047,61.857,75.828Z"
            transform="translate(438.346 212.735)"
            fill="#b3b3ea"
          />
          <path
            id="Path-56"
            data-name="Path"
            d="M0,0S1.294,15.038,1.294,18.326s4.5.259,5.875,0"
            transform="translate(326.641 193.427)"
            fill="none"
            stroke="#ff8066"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <circle
            id="Oval"
            cx="27.339"
            cy="27.339"
            r="27.339"
            transform="translate(175.911 77.66) rotate(-11.71)"
            fill="#b3b3ea"
          />
          <circle
            id="Oval-2"
            data-name="Oval"
            cx="19.469"
            cy="19.469"
            r="19.469"
            transform="translate(185.214 83.769) rotate(-11.71)"
            fill="url(#linear-gradient-57)"
          />
          <path
            id="Path-57"
            data-name="Path"
            d="M43.391.941C12.129,7.41-6.381,45.572,2.028,86.105L115.22,62.669C106.8,22.114,74.654-5.538,43.391.941Z"
            transform="translate(151.418 33.197)"
            fill="url(#linear-gradient-58)"
          />
          <path
            id="Path-58"
            data-name="Path"
            d="M2.028,83.924l30.411-6.306C26.294,47.951,29.2,19.782,38.724,0,10.3,9.109-5.95,45.417,2.028,83.924Z"
            transform="translate(151.407 35.421)"
            fill="url(#linear-gradient-59)"
          />
          <g
            id="Artwork_1"
            data-name="Artwork 1"
            transform="translate(241.248 268.348)"
          >
            <g
              id="Artwork_1-2"
              data-name="Artwork 1"
              transform="translate(-7.856 -8.624)"
            >
              <path
                id="Path_64"
                data-name="Path 64"
                d="M19.987.294a.483.483,0,0,0-.468-.3H16.685a.421.421,0,0,0-.419.3L13.259,8.633a.369.369,0,0,0,.287.491q1.412.224,2.864.31a.371.371,0,0,0,.373-.255l1.356-4.154,1.356,4.152a.371.371,0,0,0,.373.255q1.439-.085,2.839-.309a.369.369,0,0,0,.287-.491Z"
                transform="translate(-10.271 0.002)"
                fill="#fff"
              />
              <path
                id="Path_65"
                data-name="Path 65"
                d="M15.712,45.445a.37.37,0,0,0-.466-.356,29.229,29.229,0,0,1-14.78,0A.37.37,0,0,0,0,45.445v2.5a.37.37,0,0,0,.283.36q.467.112.937.21L.034,51.8c-.1.246.025.419.3.419h3.2a.394.394,0,0,0,.419-.3l.937-2.867q1.471.133,2.968.136t2.972-.136l.933,2.867a.394.394,0,0,0,.419.3h3.2c.271,0,.394-.172.3-.419l-1.186-3.288q.471-.1.937-.21a.37.37,0,0,0,.283-.36Z"
                transform="translate(0 -34.975)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AirdeskBackgroundSvg;
