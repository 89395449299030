import React, { useMemo } from "react";
import KeyedDropdown from "../Dropdown/KeyedDropdown";
import { DealStatusTypes } from "../../Containers/Deals/DealsHelper";
import DealStatusProfile from "./DealStatusProfile";

const DealStatusDropdown = (props) => {
  const { hideCancellation } = props;

  const options = useMemo(() => {
    if (hideCancellation)
      return [
        DealStatusTypes.draft,
        DealStatusTypes.open,
        DealStatusTypes.lost,
        DealStatusTypes.won
      ];
    else
      return [
        DealStatusTypes.draft,
        DealStatusTypes.open,
        DealStatusTypes.canceled,
        DealStatusTypes.lost,
        DealStatusTypes.won
      ];
  }, [hideCancellation]);

  return (
    <KeyedDropdown
      options={options}
      valueComponent={DealStatusProfile}
      closeOnSelect
      {...props}
    />
  );
};

export default DealStatusDropdown;
