import { faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../Components/Button/Button";
import LoadableButton from "../../Components/Button/LoadableButton";
import { useGridRequest } from "../../Components/CGrid/ServerGrid";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import {
  SideFormContent,
  SideMenuFormFooterBase
} from "../../Components/Forms/SideMenuForm";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import {
  BaseSidebarContainer,
  BaseSidebarContent,
  BaseSidebarHeader,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import { useToast } from "../../Components/Toast/ToastProvider";
import { UserDropdown } from "../AdvancedMultiInputs";

export const CLientManagerMultipleButton = ({ selectedItems }) => {
  const [openSidebar] = useSidebar();
  const { refetch } = useGridRequest();
  const handleStatusMultiAction = () => {
    openSidebar(
      <ClientManagerMultiActionForm
        selectedItems={selectedItems}
        refetch={refetch}
      />
    );
  };

  return (
    <Button
      className="p-1 AlignLeftLoadableButton justify-content-start d-flex align-items-center"
      vType={"link"}
      onClick={handleStatusMultiAction}
    >
      <div className="ar-form-button-link-icon mr-2">
        <FontAwesomeIcon icon={faUser} />
      </div>
      <FormattedMessage id="CHANGE_MANAGER" />
    </Button>
  );
};

export const ClientManagerMultiActionForm = ({ selectedItems, refetch }) => {
  const [Manager, setManager] = useState(null);

  const handleManagerChange = (e) => {
    const { value } = e.target;
    setManager(value);
  };
  const createToast = useToast();
  const [, closeSidebar] = useSidebar();
  const intl = useIntl();
  const [post, { loading }] = useSidebarSpacePost(`Clients/Multi`, null, {
    onSuccess: ({ openSidebar, disableClosure, data }) => {
      createToast({
        pos: "tm",
        type: "success",
        description: `${data.Success.length} ${intl.formatMessage({
          id: "CLIENTS_CHANGED"
        })}, ${data.NotChange.length} ${intl.formatMessage({
          id: "CLIENTS_NOT_CHANGED"
        })}, ${data.Fail.length} ${intl.formatMessage({
          id: "CLIENTS_FAILED_CHANGED"
        })}${
          data.NoPermission?.length > 0
            ? `, ${data.NoPermission.length} ${intl.formatMessage({
                id: "CALL_NO_PERMISSION"
              })}`
            : ""
        }`
      });
      closeSidebar();
      selectedItems.ClearSelection();
      refetch();
    }
  });

  const submit = () => {
    post({
      ClientIds: selectedItems.selectedItems,
      ManagerId: Manager
    });
  };

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"EDIT_CLIENTS"} />
      </BaseSidebarHeader>
      <BaseSidebarContent className="d-flex flex-column">
        <SideFormContent className="px-4 py-3">
          <div className="d-flex w-100 flex-column h-100">
            <FormInput
              className="mb-3"
              value={Manager}
              name="Manager"
              textId="MANAGER"
              inputType={UserDropdown}
              onChange={handleManagerChange}
            />

            <div />
          </div>
        </SideFormContent>
        <SideMenuFormFooterBase className="d-flex align-items-center justify-content-between">
          <div></div>
          <LoadableButton
            disabled={!Manager}
            isLoading={loading}
            onClick={submit}
            vType="primary-ghost"
          >
            <FormattedMessage id={"SUBMIT"} />
          </LoadableButton>
        </SideMenuFormFooterBase>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};
