import React, { useState, useRef, useEffect, useMemo } from "react";
import Dropdown, {
  DropdownListRemovalItem,
  DropdownList
} from "../../Components/Dropdown/Dropdown";
import querystring from "query-string";
import {
  useSpaceQuery,
  useQuery,
  cacheType,
  getEntity,
  client
} from "../../Helpers/IOClient";
import classnames from "classnames";
import { BarLoader } from "../../Components/GlobalLoader/GlobalLoader";
import { FormattedMessage } from "react-intl";
import { canUserEditModule } from "../../Helpers/ModulesHelper";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { useSpace } from "../../Contexts/SpaceContext";
import { spacePipelineSchema } from "../../config/schema";
import store from "../../store";

export const Searching = () => (
  <div className="px-3 py-2">
    <FormattedMessage id="SEARCHING" />
  </div>
);
export const TypeToSearch = () => (
  <div className="px-3 py-2">
    <FormattedMessage id="TYPE_TO_SEARCH" />
  </div>
);
export const NoResults = () => (
  <div className="px-3 py-2">
    <FormattedMessage id="NO_SEARCH_RESULTS" />
  </div>
);

const getkey = (v) => v;

const SingleListComponent = React.memo((props) => {
  const {
    items,
    isLoading,
    value,
    onClick,
    moduleType,
    removable,
    optionalGetKey,
    ...rest
  } = props;

  const {
    query: text,
    dontShowAddButton,
    NoEntityComponent,
    values,
    name,
    props: dropdownProps
  } = items;

  const currentAccount = useCurrentAccountSpace();
  const hasEditPermission = useMemo(() => {
    if (!NoEntityComponent) return false;
    return canUserEditModule(currentAccount, moduleType);
  }, [NoEntityComponent, currentAccount, moduleType]);

  if (isLoading && !values) return <Searching />;

  if (!values) return <TypeToSearch />;

  if (values.length === 0) {
    return (
      <React.Fragment>
        {removable && value && (
          <DropdownListRemovalItem
            onClick={onClick}
            // field="Name"
          />
        )}
        <NoResults />
        {/* {canAdd && text && <div onClick={() => onClick(text)}>Adicionar</div>} */}
        {hasEditPermission && NoEntityComponent && (
          <NoEntityComponent name={name} text={text} {...dropdownProps} />
        )}
      </React.Fragment>
    );
  }

  return (
    <>
      <DropdownList
        {...rest}
        removable={removable}
        value={value}
        onClick={onClick}
        items={values}
        getkey={optionalGetKey || getkey}
        NoEntityComponent={
          hasEditPermission &&
          NoEntityComponent && (
            <NoEntityComponent
              name={name}
              text={text}
              dontShowAddButton={dontShowAddButton}
              {...dropdownProps}
              className="no-entity-button"
            />
          )
        }
      />
      {/* <div className="mb-2"></div> */}
      {/* {NoEntityComponent && (
          <NoEntityComponent name={name} text={text} {...dropdownProps} />
        )} */}
    </>
  );
});

const validateEntityValue = (value, schema, space) => {
  if (!value || !schema || !space) return;
  const connectedValue = getEntity(schema, value);
  if (connectedValue) return;
  if (!schema.getEndpoint) return;
  const url = schema.getEndpoint(space) + `/${value}`;
  client.get(url, {
    schema
  });
};

const useBaseEntityFetch = (value, schema) => {
  const space = useSpace();
  const runned = useRef();

  useEffect(() => {
    if (!schema) return;
    if (runned.current) return;
    runned.current = true;
    if (Array.isArray(value)) {
      for (const v of value) {
        validateEntityValue(v, schema, space?.Id);
      }
    } else {
      validateEntityValue(value, schema, space?.Id);
    }

    // return () => {};
  }, [schema, space, value]);
};

export const PipelineStatusMultiListItems = React.memo(
  ({
    loading,
    onClick,
    filter,
    className,
    isLoading,
    name,
    disableEntityCreation,
    listItemComponent,
    items,
    ...rest
  }) => {
    // const currentAccountSpace = useCurrentAccountSpace();
    const { values } = items;

    const handleClick = (item) => {
      onClick(item);
    };

    return (
      <div
        className={classnames("ar-dropdow-container", {
          loading: loading || isLoading
        })}
      >
        {values?.length > 0 ? (
          values.map((e) => {
            const pipe = getEntity(spacePipelineSchema, e);
            return (
              <React.Fragment key={e}>
                <div className="ar-dropdown-list-column text-gray-darker">
                  <span>
                    {pipe.Name}
                    {/* {icon && <FontAwesomeIcon icon={icon} className="mr-2" />} */}
                    {/* <FormattedMessage id={queryName} /> */}
                  </span>
                </div>
                {pipe?.PipelineStatus.length > 0 && (
                  <DropdownList
                    {...rest}
                    items={pipe?.PipelineStatus}
                    onClick={handleClick}
                    getkey={getkey}
                    component={listItemComponent}
                  />
                )}
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}
        {/* {AddMoreButton} */}
        {/* {hasEditPermission && NoEntityComponent && !disableEntityCreation && (
          <NoEntityComponent
            name={name}
            type={key}
            text={filter}
            {...rest}
            component={({ Button }) => {
              return (
                // <div className={classnames("ar-dropdow-container")}>
                  Button
                // </div>
              );
            }}
          />
        )} */}
      </div>
    );
  }
);

export const PipelineTagsMultiListItems = React.memo(
  ({
    loading,
    onClick,
    filter,
    className,
    schema,
    isLoading,
    name,
    disableEntityCreation,
    listItemComponent,
    items,
    ...rest
  }) => {
    // const currentAccountSpace = useCurrentAccountSpace();
    const { values } = items;

    const handleClick = (item) => {
      onClick(item);
    };

    const resolvedList = {};
    const resolvedSchema = schema[0];

    const defaultTags = [];
    // const entities =

    if (Array.isArray(values) && values.length > 0) {
      for (const tag of values) {
        const resolvedTag = getEntity(resolvedSchema, tag);
        const { Pipeline } = resolvedTag || {};
        if (Pipeline) {
          if (resolvedList[Pipeline])
            resolvedList[Pipeline] = [...resolvedList[Pipeline], tag];
          else resolvedList[Pipeline] = [tag];
        } else defaultTags.push(tag);
      }
    }

    const resultBody = [];

    if (Array.isArray(values) && values.length > 0) {
      for (const key in resolvedList) {
        if (Object.hasOwnProperty.call(resolvedList, key)) {
          const tags = resolvedList[key];
          const pipe = getEntity(spacePipelineSchema, key);
          resultBody.push(
            <React.Fragment key={pipe.Id}>
              <div className="ar-dropdown-list-column mb-1 text-gray-darker">
                <span>
                  {pipe.Name}
                  {/* {icon && <FontAwesomeIcon icon={icon} className="mr-2" />} */}
                  {/* <FormattedMessage id={queryName} /> */}
                </span>
              </div>
              {tags.length > 0 && (
                <DropdownList
                  {...rest}
                  items={tags}
                  onClick={handleClick}
                  getkey={getkey}
                  component={listItemComponent}
                />
              )}
            </React.Fragment>
          );
        }
      }

      resultBody.push(
        <React.Fragment key={"GENERAL"}>
          <div className="ar-dropdown-list-column text-gray-darker">
            <span>
              {/* {icon && <FontAwesomeIcon icon={icon} className="mr-2" />} */}
              <FormattedMessage id={"GERAL"} />
            </span>
          </div>
          {defaultTags.length > 0 && (
            <DropdownList
              {...rest}
              items={defaultTags}
              onClick={handleClick}
              getkey={getkey}
              component={listItemComponent}
            />
          )}
        </React.Fragment>
      );
    }

    return (
      <div
        className={classnames("ar-dropdow-container", {
          loading: loading || isLoading
        })}
      >
        {resultBody?.length > 0 ? resultBody : <></>}
        {/* {AddMoreButton} */}
        {/* {hasEditPermission && NoEntityComponent && !disableEntityCreation && (
          <NoEntityComponent
            name={name}
            type={key}
            text={filter}
            {...rest}
            component={({ Button }) => {
              return (
                // <div className={classnames("ar-dropdow-container")}>
                  Button
                // </div>
              );
            }}
          />
        )} */}
      </div>
    );
  }
);

export const createDropdown = ({
  query,
  enableSearch,
  enableFetchOnStart,
  searchOnType,
  searchOnlyOnOpen = false,
  requiresSpace = true,
  canAdd,
  isTextValid,
  ListComponent,

  clearTextOnChanges,
  grid,
  hideCancelation,
  closeOnClickWhileOpen,
  unremovable = false,
  onChange,
  optionalGetKey,
  ...rest
}) => {
  const {
    endpoint,
    schema,
    Component,
    ListItemComponent,
    getUpdateParams,
    DefaultActiveButton,
    NoEntityComponent,
    moduleType
  } = query;

  const buildEndpoint = (query, { props }) => {
    if (props.items) return undefined;

    const optionalParams = getUpdateParams ? getUpdateParams(props) : undefined;

    const search = querystring.stringify({
      ...optionalParams,
      query: query
    });

    return `${endpoint}?${search}`;
  };

  const listComponent = ListComponent
    ? ListComponent
    : optionalGetKey
    ? (props) => (
        <SingleListComponent
          moduleType={moduleType}
          optionalGetKey={optionalGetKey}
          {...props}
        />
      )
    : (props) => <SingleListComponent moduleType={moduleType} {...props} />;

  const AdvancedMultiSelect = React.memo((props) => {
    const { onLoad, closeOnClick, disabledDefault, excludeMe, ...rest } = props;
    const [query, setQuery] = useState();

    const resolvedEndpoint = buildEndpoint(query, { props });

    // const resolvedDataRef = useRef();
    const useQueryHook = requiresSpace ? useSpaceQuery : useQuery;
    const queryOptions = {
      cache: cacheType.component,
      autoFetch: !searchOnlyOnOpen,
      onSuccess: !props.pipelineId ? () => {} : props.value ? () => {} : onLoad,
      onError: (error) => {
        console.log(error);
      }
    };

    const { data, error, loading, refetch } = useQueryHook(
      resolvedEndpoint,
      schema,
      queryOptions
    );

    const handleOnOpen = useMemo(() => {
      if (!searchOnlyOnOpen) return;

      return () => {
        if (!loading && !data && !error) refetch();
      };
    }, [data, error, loading, refetch]);

    // const handleOnOpen = searchOnlyOnOpen
    //   ? useCallback(() => {
    //       if (!loading && !data && !error) refetch();
    //     }, [data, error, loading, refetch])
    //   : undefined;

    // if (!loading && !error) resolvedDataRef.current = data;

    const resolvedData = data;

    const timeoutRef = useRef();

    const handleOnTextChange = (text) => {
      clearTimeout(timeoutRef.current);
      if (!text) return setQuery(undefined);
      timeoutRef.current = setTimeout(async () => {
        setQuery(text);
      }, 400);
    };

    const handlePopupClosure = () => setQuery(undefined);
    useEffect(() => {
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);

    const { items, value } = props;

    // const resolvedQuery = useMemo(() => {
    //   return query;
    // }, [query, resolvedData]);

    const resolvedListComponent = !items ? listComponent : undefined;
    const resolvedItems = items
      ? items
      : {
          NoEntityComponent,
          query,
          name: rest.name,
          values: resolvedData,
          props: rest
        };

    //   const handleClearText = e => {
    //     ////;
    //     if (onChange) {
    //       onChange(e);
    //     }
    //     if (clearTextOnChanges) {
    //       setQuery(undefined);
    //     }
    //   };

    if (clearTextOnChanges) {
      ////;
    }
    useBaseEntityFetch(value, schema?.[0]);

    return (
      <Dropdown
        className={`w-100 ${grid ? "" : "mb-3"}`}
        serverFiltering
        isLoading={loading}
        hideCancelation={hideCancelation}
        valueComponent={Component}
        listComponent={resolvedListComponent}
        listItemComponent={ListItemComponent}
        excludeMe={excludeMe}
        disabledDefault={disabledDefault}
        defaultActiveButton={excludeMe ? undefined : DefaultActiveButton}
        loadingComponent={BarLoader}
        onTextChange={handleOnTextChange}
        items={resolvedItems}
        schema={schema}
        removable={!unremovable}
        closeOnSelect={!rest.multiple}
        enableSearch={enableSearch && !items}
        onClose={handlePopupClosure}
        closeOnClickWhileOpen={closeOnClickWhileOpen}
        onOpen={handleOnOpen}
        {...rest}
      />
    );
  });
  return AdvancedMultiSelect;
};
