import { faGripVertical, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import {
  ServerAwareUpdateViewsContext,
  ServerAwareViewsContext
} from "../CGrid/ServerAwareViews";
import "./PageHeaderViews.css";
import Button from "../Button/Button";
import { ReorderChangesContext } from "./PageHeader";
import { useSpace } from "../../Contexts/SpaceContext";

const PageViewSorter = ({
  schema,
  onClose,
  setIsOpen,
  schemaView,
  IsOpen,
  ...rest
}) => {
  const space = useSpace();
  const views = useContext(ServerAwareViewsContext);
  // const updateView = useContext(ServerAwareUpdateViewsContext);
  const schemaName = schemaView
    ? schemaView.name
    : schema
    ? schema.name
    : rest.Name;
  const [orderedColumns, setOrderedColumns] = useState(() => {
    const slicedArray = views.slice(1);
    return slicedArray;
  });
  const ReorderChanges = useContext(ReorderChangesContext);
  const handleSelectionChange = useCallback((id) => {
    setOrderedColumns((c) => {
      const newColumns = [...c];
      const index = newColumns.findIndex((e) => e.id === id);
      if (index !== -1) newColumns.splice(index, 1);
      return newColumns;
    });
  }, []);
  const updateLsViews = useContext(ServerAwareUpdateViewsContext);
  const submit = () => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );

    gridSavedViews[schemaName] = [views[0], ...orderedColumns];
    localStorage.setItem(
      `ar-grid-saved-views-space-${space.Id}`,
      JSON.stringify(gridSavedViews)
    );

    updateLsViews();
    // updateView();
    if (ReorderChanges.current) ReorderChanges.current();
    onClose();
  };

  return (
    <div className="ViewSorterModalWrap p-30">
      <div className="fs-16 mb-10 fw-bold text-black d-flex justify-content-between">
        <span className="pt-1">
          <FormattedMessage id="SORT_YOUR_VIEWS" />
        </span>
      </div>
      <div className="d-flex mb-4">
        <ViewsDragOrder
          selectedColumns={orderedColumns}
          onChange={setOrderedColumns}
          removeColumn={handleSelectionChange}
        />
      </div>
      <div className="d-flex justify-content-end">
        <Button
          className="mr-3"
          onClick={() => {
            setIsOpen(false);
          }}
          vType="link-danger"
        >
          <FormattedMessage id={"CANCEL"} />
        </Button>
        <Button onClick={submit} vType="primary">
          <FormattedMessage id={"SAVE"} />
        </Button>
      </div>
    </div>
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ViewsDragOrder = ({ selectedColumns, onChange, removeColumn }) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrderedColumns = reorder(
      selectedColumns,
      result.source.index,
      result.destination.index
    );

    if (onChange) {
      onChange(newOrderedColumns);
    }
  };

  const DragableContent = selectedColumns.map((column, index) => {
    return (
      <Draggable draggableId={column.id} index={index} key={column.id}>
        {(draggableProvided, draggableSnapshot) => (
          <PortalAwareItem
            removeColumn={removeColumn}
            column={column}
            provided={draggableProvided}
            snapshot={draggableSnapshot}
          />
        )}
      </Draggable>
    );
  });

  return (
    <div className="mt-2">
      <div
        style={{ textTransform: "uppercase" }}
        className="fs-14 mb-3 fw-medium text-black"
      >
        <FormattedMessage id={"CREATED_VIEWS"} /> ({selectedColumns.length})
      </div>
      <div className="ViewSorterChooserOrderList">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {DragableContent}

                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

const PortalAwareItem = ({
  provided,
  snapshot,
  column,
  removeColumn,
  isDragDisabled,
  required
}) => {
  const providedd = provided;
  const snapshott = snapshot;
  const droppedOutside =
    snapshott.isDropAnimating &&
    snapshott.draggingOver !== "droppable" &&
    (snapshot.dropAnimation.moveTo.y < -100 ||
      snapshot.dropAnimation.moveTo.y > 100);

  const usePortal = snapshott.isDragging;

  const style = {
    ...providedd.draggableProps.style,
    zIndex: 99999999,
    outline: "none",
    visibility: droppedOutside ? "hidden" : "initial"
  };

  const child = (
    <div
      ref={providedd.innerRef}
      {...providedd.draggableProps}
      {...providedd.dragHandleProps}
      style={style}
      className={`py-1 text-black ${usePortal && "dragging"}`}
    >
      <div className="ViewSorterDragableContainer">
        <span className="GripVerticalIcon">
          {!isDragDisabled && (
            <FontAwesomeIcon
              className="PointerEventsRemove"
              icon={faGripVertical}
            />
          )}
        </span>
        <span>{column.name}</span>
        {!required && (
          <span
            onClick={() => removeColumn(column.id)}
            style={{ float: "right", cursor: "pointer", color: "red" }}
            className="mx-3 mb-2"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </span>
        )}
      </div>
    </div>
  );

  if (!usePortal) {
    return child;
  }

  // if dragging - put the item in a portal
  return ReactDOM.createPortal(child, document.body);
};

export default PageViewSorter;
