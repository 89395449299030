import React, { useState } from "react";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import moment from "moment";
import { DashboardTitle } from "./Dashboard";
import { faUserClock } from "@fortawesome/pro-light-svg-icons";
import { useReportQuery, ReportDatePicker } from "../Reports/Reports";
import { TableHeader } from "../Reports/TimesReport";
import { FormattedMessage } from "react-intl";

// const ReportTrComponent = props => {
//   const { value } = props;
//   if (typeof value === "number") {
//     return <Tr className="department" {...props} />;
//   }

//   return <Tr className="line" {...props} />;
// };

// const Report = ({ columns, items }) => {
//   return (
//     <DashboardTable
//       basic
//       className="ar-dashboard-report"
//       TrComponent={ReportTrComponent}
//       columns={columns}
//       data={items}
//     />
//   );
// };

const reportDateTypes = {
  last7Days: "last7Days",
  custom: "custom"
};

const useReportState = () => {
  const state = useState(() => {
    const toDate = moment();
    const fromDate = moment(toDate).subtract(14, "days");

    return {
      toDateString: toDate.format("YYYY-MM-DD"),
      fromDateString: fromDate.format("YYYY-MM-DD"),
      toDate: toDate.toDate(),
      fromDate: fromDate.toDate(),
      type: reportDateTypes.last7Days
    };
  });
  return state;
};

const DashboardDepartmentOverview = () => {
  const reportState = useReportState();

  const [{ toDateString, fromDateString, toDate, fromDate }] = reportState;

  // const dates = useMemo(() => {
  //   const dateArr = getDates(moment(fromDateString), moment(toDateString));

  //   return dateArr;
  // }, [fromDateString, toDateString]);

  // const { Id } = useCurrentAccount();
  const { data: allReports, loading, error } = useReportQuery({
    toDate: toDateString,
    fromDate: fromDateString
  });

  const areDatesValid = !(fromDate > toDate);

  const buildBody = () => {
    if (loading || error)
      return <LoaderSpinner size="sm" center className="text-secondary" />;

    if (!areDatesValid)
      return (
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="text-black">
            <FormattedMessage id={"START_DATE_NO_BIGGER_THEN_END"} />
          </div>
        </div>
      );

    return <TableHeader allReports={allReports} />;
  };

  return (
    <>
      <div className="of-hidden">
        <DashboardTitle
          actionButton={<ReportDatePicker state={reportState} defaultVal={5}/>}
          text={<FormattedMessage id={"TIME_REGISTRATION"} />}
          className="mb-2 px-4"
          icon={faUserClock}
        ></DashboardTitle>
        <div
          style={{ height: "90%" }}
          className="w-100 d-flex flex-column ClassificationReport"
        >
          {buildBody()}
        </div>
      </div>
    </>
  );
};

export default DashboardDepartmentOverview;
