/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  FC,
  FunctionComponent,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useSpace } from "../../Contexts/SpaceContext";

import { ObfuscateProvider } from "../Space/Space2";
import { PersonalHistoryButton } from "../PersonalHistory/PersonalHistory";
import ReceptionInboxButton from "../Reception/ReceptionInboxButton";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";
import Popup, { usePopupOpenState } from "../../Components/Popup/Popup";
import Button from "../../Components/Button/Button";
import {
  commercialGroup,
  contactsGroup,
  IMainRoute,
  MarketingGroup,
  reportDashboardRoute,
  reportingOverview,
  ReportsGroupMaster,
  RouteAccessDict,
  servicesGroup,
  settingsRoute,
  useSpaceMainView,
  useSpaceRoutesAccess
} from "../Space/SpaceHelper";
import MaintenanceIcon from "../Maintenance/MaintenanceIcon";
import {
  TrialPlanHeaderButton,
  CancelationPlanHeaderButton
} from "../BillingPlans/BillingPlanPayment";
import PersonalExportButton from "../Accounts/ExportsGrid/PersonalExportsButton";
import { TimeTrackingFormButton } from "../TimeTracking/TimeTracking";
import NotificationsBell from "../Notifications/NotificationsBell";
import AccountPopup from "../AccountPopup";
import classnames from "classnames";
import GlobalSearch from "../../Components/GlobalSearch/GlobalSearch";
import classes from "./AppBar.module.css";
import {
  HeaderNavLink,
  MainViewSearchContext,
  useMainViewSearchDict
} from "../../Components/Header/HeaderV2";
import { lsDashboardKey } from "../Dashboard/DashboardHelper";
import { NavLink } from "react-router-dom";
import { ReactComponent as AirdeskLogo } from "../../Assets/Svg/airdesk_icon_small.svg";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderPlus,
  faStar as faStarLight,
  faSidebar as faWindowAlt,
	faTimes
} from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import { useHoverEvents } from "../Origin/Origin";
import OmniButton from "../OmniButton/OmniButton";
import {
  faAnalytics,
  faBuilding,
  faCog,
  faHandshake,
  faHouse,
  faShareAlt,
  faStar,
  faUserCog,
  faPlusLarge as faPlus
} from "@fortawesome/pro-duotone-svg-icons";
import AppBarFavorites from "./AppBarFavoritesWithLibrary";
import Modal from "../../Components/Modal/Modal";

import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import {
  AppTreeValueContext,
  AppTreeSetValueContext,
  AppTreeChildFavoriteCreatorContext,
  AppTreeChildFolderCreatorContext
} from "./AppBarHelper";
import {
  AppBarFavoriteQueryContext,
  AppBarFavoriteTreeSetterResponse,
  IAppBarFavoriteContet,
  IAppBarFavoriteTreeSetterResponseProps
} from "./AppBarFavoriteQuery";
import { AppFavoriteContext } from "./AppFavoriteProvider";
import { TreeItem } from "@atlaskit/tree";
import FavoritesCreator, {
  FavoriteCreatorTypes,
  FontAwesomeIconsProvider
} from "./AppBarFavoriteCreator";
import { MainViewsSearchLSKey } from "../../Helpers/MiscHelper";
import { getAlterEgo } from "../../Helpers/AuthHelper";
import { useUnreadEntities } from "../../Components/UnreadEntities/UnreadEntities";
import Badge from "../../Components/Badge/Badge";
import { useGroupUnreadCount } from "../../Components/Header/HeaderLinkGroup";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { AccountSpace } from "../../Interfaces/EntityInterfaces";
import { faChevronLeft, faThumbTack } from "@fortawesome/pro-solid-svg-icons";

interface AppBarProps {
  disableHeader: boolean;
  objuscateButtons: boolean;
}

// const AirdeskIcon = ({ className }: { className?: string }) => {
//   const dict = useMainViewSearchDict() || {};
//   const ObfuscateState = useContext(ObfuscateProvider);
//   const { Id } = useSpace();
//   let search = dict[lsDashboardKey];
//   if (search) search = `?${search}`;
//   const handleClick = (e: any) => {
//     if (ObfuscateState === true) e.preventDefault();
//   };

//   return (
//     <NavLink
//       style={{ pointerEvents: ObfuscateState && "none" }}
//       onClick={handleClick}
//       // to={`/s/${Id}${search}`}
//       to={`/s/${Id}/dashboard`}
//       className={classnames(className)}
//     >
//       <span style={{ opacity: ObfuscateState && 0.3 }}>
//         <AirdeskIconSvg />
//       </span>
//     </NavLink>
//   );
// };

const ContactSingleGroup: IAppBarSingleGroup = {
  id: "contacts",
  titleId: "CONTACTS",
  group: contactsGroup
};
const ComercialSingleGroup: IAppBarSingleGroup = {
  id: "comercial",
  titleId: "COMERCIAL",
  group: commercialGroup
};
const ServicesSingleGroup: IAppBarSingleGroup = {
  id: "services",
  titleId: "SERVICES",
  group: servicesGroup
};
const MarketingSingleGroup: IAppBarSingleGroup = {
  id: "marketing",
  titleId: "MARKETING",
  group: MarketingGroup
};
const ReportsSingleGroup: IAppBarSingleGroup = {
  id: "reports",
  titleId: "REPORTING",
  group: ReportsGroupMaster
};

const isInAlterEgo = Boolean(getAlterEgo());
interface IAppBarSingleGroup {
  id: string;
  titleId: string;
  group: IMainRoute[][];
}

const lsItem = localStorage.getItem(MainViewsSearchLSKey);

const dict = lsItem ? JSON.parse(lsItem) : {};

const AppBar: FunctionComponent<AppBarProps> = ({
  disableHeader,
  objuscateButtons
}) => {
  const intl = useIntl();
  const [activeGroup, setActiveGroup] = useState<
    IAppBarSingleGroup | undefined
  >();

  const [hoverGroupo, setHoverGroup] = useState<
    IAppBarSingleGroup | undefined
  >();

  const resolvedGroup = hoverGroupo || activeGroup;

  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const handleToggle = useCallback(
    (group: IAppBarSingleGroup | undefined) => {
      if (!group) setActiveGroup(undefined);
      if (isFavorite) {
        setIsFavorite(false);
        // setActiveGroup(undefined);
      }
      if (group && group.id === activeGroup?.id) setActiveGroup(undefined);
      else setActiveGroup(group);
    },
    [activeGroup, isFavorite]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleHover = useCallback(
    (group: IAppBarSingleGroup) => {
      if (isFavorite) setIsFavorite(false);
      setHoverGroup(group);
    },
    [isFavorite]
  );

  const ObfuscateState = useContext(ObfuscateProvider);

  const handleClick = (e: any) => {
    if (ObfuscateState === true) e.preventDefault();
  };

  const [isFixed, setIsFixed] = useState(() => {
    const lsFixed = localStorage.getItem("air-vertical-sidebar-fixed-status");
    if (lsFixed) {
      return Boolean(parseInt(lsFixed));
    } else return true;
  });

  const toggleFavoriteView = () => {
    if (activeGroup) setActiveGroup(undefined);
    setIsFavorite(!isFavorite);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleHoverFavoriteView = () => {
    setHoverGroup(undefined);
    setIsFavorite(true);
  };

  // const toggleFixed = () => {
  //   setIsFixed(!isFixed);
  // };

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen,
    setIsOpen
  } = useHoverEvents(0);

  const previousActiveGroup = useRef<boolean>();
  const previousFavoriteState = useRef<boolean>();
  useEffect(() => {
    // if (isFixed && Boolean(activeGroup) !== previousActiveGroup.current) {
    //   window.dispatchEvent(new Event("resize"));
    //   previousActiveGroup.current = Boolean(activeGroup);
    // } else if (
    //   isFixed &&
    //   Boolean(isFavorite) !== previousFavoriteState.current
    // ) {
    //   window.dispatchEvent(new Event("resize"));
    //   previousFavoriteState.current = Boolean(isFavorite);
    // }else{

    // }
    window.dispatchEvent(new Event("resize"));
  }, [activeGroup, isFavorite, isFixed]);

  const MouseEnterTimeout = useRef<any>();
  const handleMouseEnter = useCallback(() => {
    if (MouseEnterTimeout.current) clearTimeout(MouseEnterTimeout.current);
    if (!isFixed) {
      MouseEnterTimeout.current = setTimeout(() => {
        onAnchorMouseEnter();
      }, 100);
    }
  }, [isFixed, onAnchorMouseEnter]);

  const handleMouseLeave = useCallback(
    (e:any) => {
      const nextElement = e.toElement || e.relatedTarget;
      if (
        typeof nextElement?.id === "string" &&
        (nextElement?.id === "app-bar-side-right" ||
          nextElement?.id === "app-bar-side-left")
      )
        return;
      if (MouseEnterTimeout.current) clearTimeout(MouseEnterTimeout.current);
      if (!isFixed) {
        MouseEnterTimeout.current = setTimeout(() => {
          onAnchorMouseLeave();
        }, 100);
      }
    },
    [isFixed, onAnchorMouseLeave]
  );

  const space = useSpace();

  let search = dict[lsDashboardKey];
  if (search) search = `?${search}`;
  return (
    <ObfuscateProvider.Provider value={objuscateButtons}>
      <MainViewSearchContext.Provider value={dict}>
        <div
          className={classnames(classes.appBarContainer, "py-3", {
            [classes.alterEgo]: isInAlterEgo
          })}
        >
          <div
            id="app-bar-side-left"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="align-items-center d-flex flex-column h-100 w-100"
          >
            <NavLink
              to={`/s/${space.Id}/dashboard${search ? search: ''}`}
              className={classnames("d-flex justify-content-center")}
            >
              <AirdeskLogo />
            </NavLink>
            <div
              className={classnames(classes.appBarAirdeskLogo, "mt-3 mb-2")}
            ></div>
            {/* <div className="app-sidebar-icons">
            <HeaderLink
              onClick={() => {
                handleToggle(undefined);
              }}
              routeModule={reportDashboardRoute}
              forcedChildren={<FontAwesomeIcon icon={faHouse} size="lg" />}
            ></HeaderLink>
          </div> */}
            <div
              className={classnames(
                "d-flex flex-column h-100 of-y-auto w-100 Smaller-Scroll-Bar"
              )}
            >
              <InformationComponent
                placement="right"
                style={{ margin: "10px 10px" }}
                text={intl.formatMessage({ id: "FAVORITES" })}
              >
                <AppFavoriteBarGroup
                  isSelected={isFavorite}
                  handleToggle={toggleFavoriteView}
                  // handleHover={toggleHoverFavoriteView}
                ></AppFavoriteBarGroup>
              </InformationComponent>

              <div className="app-sidebar-icons flex-column align-items-center">
                <OmniButton
                  tooltipText={intl.formatMessage({ id: "ACTIONS" })}
                  tooltipStyle={{ margin: "10px 10px" }}
                  tooltipPlacement={"right"}
                  placement={"right-end"}
                  buttonClass="m-auto"
                  style={{ margin: "10px 10px" }}
                  className="cursor-pointer"
                />

                <GlobalSearch
                  className="cursor-pointer my-2"
                  buttonClass="m-auto"
                  placement="right"
                  style={{ margin: "10px 10px" }}
                  text={intl.formatMessage({ id: "SEARCH" })}
                />
              </div>

              <AppBarGroup
                text={intl.formatMessage({ id: ContactSingleGroup.titleId })}
                handleToggle={handleToggle}
                // handleHover={handleHover}
                singleGroup={ContactSingleGroup}
                icon={faBuilding}
                isSelected={ContactSingleGroup.id === resolvedGroup?.id}
              />

              <AppBarGroup
                text={intl.formatMessage({ id: ComercialSingleGroup.titleId })}
                handleToggle={handleToggle}
                // handleHover={handleHover}
                singleGroup={ComercialSingleGroup}
                icon={faHandshake}
                isSelected={ComercialSingleGroup.id === resolvedGroup?.id}
              ></AppBarGroup>

              <AppBarGroup
                text={intl.formatMessage({ id: ServicesSingleGroup.titleId })}
                handleToggle={handleToggle}
                // handleHover={handleHover}
                singleGroup={ServicesSingleGroup}
                icon={faUserCog}
                isSelected={ServicesSingleGroup.id === resolvedGroup?.id}
              ></AppBarGroup>

              <AppBarGroup
                text={intl.formatMessage({ id: MarketingSingleGroup.titleId })}
                handleToggle={handleToggle}
                // handleHover={handleHover}
                singleGroup={MarketingSingleGroup}
                icon={faShareAlt}
                isSelected={MarketingSingleGroup.id === resolvedGroup?.id}
              ></AppBarGroup>

              <div className="app-sidebar-icons">
                <HeaderLink
                  onClick={() => {
                    handleToggle(undefined);
                  }}
                  routeModule={reportingOverview}
                  buttonClass="m-auto"
                  forcedChildren={
                    <FontAwesomeIcon
                      icon={faAnalytics}
                      className="fs-18"
                      size="lg"
                    />
                  }
                ></HeaderLink>
              </div>

              {/* <AppBarGroup
            text={intl.formatMessage({ id: ReportsSingleGroup.titleId })}
            handleToggle={handleToggle}
            // handleHover={handleHover}
            singleGroup={ReportsSingleGroup}
            icon={faAnalytics}
            isSelected={ReportsSingleGroup.id === resolvedGroup?.id}
          ></AppBarGroup> */}

              {/* <div
              className={classnames(classes.appBarAirdeskLogo, "mt-2 mb-2")}
            ></div> */}

              <div className="flex-1"></div>

              <div className="d-flex app-sidebar-icons flex-column align-items-center justify-content-end">
                <TrialPlanHeaderButton enableVertical="true" />
                <CancelationPlanHeaderButton enableVertical="true" />
                <MaintenanceIcon />

                {/* <OmniButton
              tooltipText={intl.formatMessage({ id: "ACTIONS" })}
              tooltipStyle={{ margin: "10px 10px" }}
              tooltipPlacement={"right"}
              placement={"right-end"}
              style={{ margin: "10px 10px" }}
              className="cursor-pointer my-1"
            />

            <GlobalSearch
              className="cursor-pointer mb-1"
              placement="right"
              style={{ margin: "10px 10px" }}
              text={intl.formatMessage({ id: "SEARCH" })}
            /> */}

                <InformationComponent
                  placement="right"
                  style={{ margin: "10px 10px" }}
                  text={intl.formatMessage({ id: "INBOX" })}
                >
                  {/* <ReceptionInboxButton className="cursor-pointer mb-1" labelText={undefined} buttonClass={undefined} size={undefined} /> */}
                </InformationComponent>

                <InformationComponent
                  placement="right"
                  style={{ margin: "10px 10px" }}
                  text={intl.formatMessage({ id: "NOTIFICATIONS" })}
                >
                  {/* <NotificationsBell className="cursor-pointer mb-1" /> */}
                </InformationComponent>
                <InformationComponent
                  placement="right"
                  style={{ margin: "10px 10px" }}
                  text={intl.formatMessage({ id: "HISTORY" })}
                >
                  {/* <PersonalHistoryButton className="cursor-pointer mb-1" /> */}
                </InformationComponent>

                <InformationComponent
                  placement="right"
                  style={{ margin: "10px 10px" }}
                  text={intl.formatMessage({ id: "TIME_CONTROL" })}
                >
                  {/* <TimeTrackingFormButton className="cursor-pointer mb-1" /> */}
                </InformationComponent>

                <InformationComponent
                  placement="right"
                  style={{ margin: "10px 10px" }}
                  text={intl.formatMessage({ id: "EXPORTS" })}
                >
                  {/* <PersonalExportButton className="cursor-pointer" /> */}
                </InformationComponent>
                <SettingsLink text={intl.formatMessage({ id: "SETTINGS" })} />
              </div>
            </div>
            <div
              className={classnames(classes.appBarAirdeskLogo, "mt-2 mb-3")}
            ></div>
            <AccountPopup
              placement={"right"}
              imageSize="mds"
              disableSpaceName="true"
              style={{ margin: "10px 10px" }}
              className="pl-2 text-black"
            />
          </div>
        </div>
        {(isFavorite || resolvedGroup) && (
          <AppSidebar
            isFavorite={isFavorite}
            isFixed={isFixed}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setIsFixed={setIsFixed}
            onPopupMouseEnter={handleMouseEnter}
            onPopupMouseLeave={handleMouseLeave}
            activeGroup={resolvedGroup}
            handleClick={handleClick}
          />
        )}
      </MainViewSearchContext.Provider>
    </ObfuscateProvider.Provider>
  );
};

interface IAppSidebarProps {
  activeGroup?: IAppBarSingleGroup;
  handleClick: React.MouseEventHandler<HTMLAnchorElement>;
  isFixed: boolean;
  isFavorite: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFixed?: React.Dispatch<React.SetStateAction<boolean>>;
  onPopupMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onPopupMouseLeave: React.MouseEventHandler<HTMLDivElement>;
}

const useAppBarDragEvent = (
  commitChange: (value: number) => void,
  size: number,
  minSize: number,
  maxSize: number
) => {
  const sizeRef = useRef<number | undefined>();
  const [tempSize, setTempSize] = useState<number | undefined>(size);
  const [baseX, setBaseX] = useState<number | undefined>();
  const baseSizeRef = useRef<number | undefined>();

  const onMouseDown = useCallback(
    (e: any) => {
      baseSizeRef.current = size;
      setBaseX(e.clientX);
    },
    [size]
  );

  useEffect(() => {
    if (baseX === undefined) return;

    const moveListener = (e: any) => {
      const { clientX: newClientX } = e;
      const incrementedSize = newClientX - baseX;
      const base = baseSizeRef.current as number;
      let newSize = base + incrementedSize;
      if (newSize < minSize) newSize = minSize;
      if (newSize > maxSize) newSize = maxSize;
      sizeRef.current = newSize;
      setTempSize(newSize);
      //   console.log("moved");
    };

    document.addEventListener("mousemove", moveListener);

    const upListener = () => {
      if (sizeRef.current) commitChange(sizeRef.current);
      setBaseX(undefined);
    };

    document.addEventListener("mouseup", upListener);

    return () => {
      document.removeEventListener("mousemove", moveListener);
      document.removeEventListener("mouseup", upListener);
    };
  }, [baseX, commitChange, maxSize, minSize]);

  const result = useMemo(() => {
    return { onMouseDown, tempSize };
  }, [onMouseDown, tempSize]);

  return result;
};

interface IAppSidebarContainerProps {
  resolvedIsOpen: boolean;
  onPopupMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onPopupMouseLeave: React.MouseEventHandler<HTMLDivElement>;
  isFixed: boolean;
	children?: React.ReactNode;
}

const AppSidebarContainer: FC<IAppSidebarContainerProps> = ({
  children,
  isFixed,
  resolvedIsOpen,
  onPopupMouseEnter,
  onPopupMouseLeave
}) => {
  const [width, setWidth] = useState(() => {
    const lsWidth = localStorage.getItem("app-sidebar-width");
    return lsWidth ? parseInt(lsWidth) : 240;
  });

  const comitWidth = useCallback((newWidth: any) => {
    setWidth(newWidth);
    localStorage.setItem("app-sidebar-width", newWidth);
  }, []);

  const { onMouseDown, tempSize } = useAppBarDragEvent(
    comitWidth,
    width,
    240,
    480
  );
  const resolvedSize = tempSize as number;

  return (
    <>
      <div
        id="app-bar-side-right"
        style={{ width: isFixed ? resolvedSize : undefined }}
        onMouseEnter={!isFixed ? onPopupMouseEnter : undefined}
        onMouseLeave={!isFixed ? onPopupMouseLeave : undefined}
        className={classnames(
          classes.appBarSecondaryContainer,
          "py-4 of-hidden text-white",
          {
            [classes.IsFixed]: isFixed,
            [classes.IsOpen]: resolvedIsOpen,
            [classes.alterEgo]: isInAlterEgo
          }
        )}
      >
        {children}
        {isFixed && (
          <div
            onMouseDown={onMouseDown}
            className={classes.appBarSecondaryContainerDragger}
          ></div>
        )}
      </div>
    </>
  );
};

const AppSidebar: FunctionComponent<IAppSidebarProps> = ({
  activeGroup,
  handleClick,
  isFixed,
  setIsOpen,
  isFavorite,
  setIsFixed,
  isOpen,
  onPopupMouseEnter,
  onPopupMouseLeave
}) => {
  const [resolvedIsOpen, setResolvedIsOpen] = useState(false);
  const intl = useIntl();
  useLayoutEffect(() => {
    const animationFrame = requestAnimationFrame(() => {
      setResolvedIsOpen(isOpen);
    });
    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [isOpen]);

  // const mountedRef = useRef(false);

  return (
    <AppSidebarContainer
      key={isFixed ? 0 : 1}
      onPopupMouseEnter={onPopupMouseEnter}
      onPopupMouseLeave={onPopupMouseLeave}
      isFixed={isFixed}
      resolvedIsOpen={resolvedIsOpen}
    >
      {isFavorite ? (
        <FavoritesContainer
          stickyFavorites={isFixed}
          setStickyFavorites={setIsFixed}
        />
      ) : (
        <div className={classes.SecondaryContent}>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div
              className={classnames(
                classes.appBarLinksTitle,
                "disable-selection"
              )}
            >
              <FormattedMessage id={activeGroup?.titleId} />
            </div>
            <InformationComponent
              placement="right"
              style={{ margin: "10px 13px" }}
              text={intl.formatMessage({
                id: !isFixed ? "STICKY" : "UNSTICKY"
              })}
            >
              <div
                onClick={(event) => {
                  localStorage.setItem(
                    "air-vertical-sidebar-fixed-status",
                    `${!isFixed ? 1 : 0}`
                  );
                  typeof setIsFixed === "function" && setIsFixed(!isFixed);
                  typeof setResolvedIsOpen === "function" &&
                    setResolvedIsOpen(false);
                  setIsOpen(false);
                }}
                className={classnames(
                  "d-flex ml-3 p-1 cursor-pointer rounded",
                  classes.appBarFavoriteAddItemButton
                )}
              >
                <FontAwesomeIcon
                  style={{
                    height: "16px",
                    width: "16px"
                  }}
                  icon={!isFixed ? faThumbTack : faChevronLeft}
                />
              </div>
            </InformationComponent>
          </div>
          {activeGroup
            ? activeGroup.group.map((sectionRoutes, i) => {
                return (
                  <HeaderLinkGroupSection
                    key={i}
                    toggleActiveGroup={handleClick}
                    showSeperator={i !== 0}
                    sectionRoutes={sectionRoutes}
                  />
                );
              })
            : null}
        </div>
      )}
    </AppSidebarContainer>
  );
};

interface FavoritesContaienrProps {
  stickyFavorites?: boolean;
  setStickyFavorites?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FavoritesContainer: FunctionComponent<FavoritesContaienrProps> = ({
  stickyFavorites,
  setStickyFavorites,
  setIsOpen
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const defaultParent = useRef<TreeItem | null>(null);
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  const intl = useIntl();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState<FavoriteCreatorTypes | null>();

  const onClose = () => {
    if (defaultParent.current) defaultParent.current = null;
    setModalIsOpen(false);
  };
  const onModalOpen = () => {
    setModalIsOpen(true);
  };

  const FavoriteQueryResponse = useContext(
    AppBarFavoriteQueryContext
  ) as IAppBarFavoriteContet;

  const { data, handleChange, loading, error } = FavoriteQueryResponse;

  const UpdateResponse = useContext(
    AppBarFavoriteTreeSetterResponse
  ) as IAppBarFavoriteTreeSetterResponseProps;
  const { loading: updateLoading, error: updateError } = UpdateResponse;

  const createFavoriteWithParent = useCallback((item:any) => {
    defaultParent.current = item;
    setModalType(FavoriteCreatorTypes.favorite);
    onModalOpen();
  }, []);

  const createFolderWithParent = useCallback((item:any) => {
    defaultParent.current = item;
    setModalType(FavoriteCreatorTypes.folder);
    onModalOpen();
  }, []);

  const amountOfNodes = useMemo(() => {
    if (data) {
      const { rootId, items } = data;
      const rootItem = items[rootId];
      return rootItem.children.length;
    } else return 0;
  }, [data]);

  if (!data || loading || error) {
    return (
      <div className={classes.SecondaryContent}>
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <div className={classnames(classes.appBarLinksTitle)}>
            <FormattedMessage id={"FAVORITES"} />
          </div>
        </div>
        <div className="d-flex flex-1 justify-content-center align-items-center">
          <LoaderSpinner size="sm" center />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.SecondaryContent}>
      <AppTreeChildFavoriteCreatorContext.Provider
        value={createFavoriteWithParent}
      >
        <AppTreeChildFolderCreatorContext.Provider
          value={createFolderWithParent}
        >
          <Suspense fallback={<LoaderSpinner center size="sm" />}>
            <FontAwesomeIconsProvider>
              <AppTreeValueContext.Provider value={data}>
                <AppTreeSetValueContext.Provider value={handleChange}>
                  <Modal isOpen={modalIsOpen} onClose={onClose}>
                    <FavoritesCreator
                      defaultParent={defaultParent.current as TreeItem}
                      onClose={onClose}
                      type={modalType || undefined}
                    ></FavoritesCreator>
                  </Modal>
                  <Popup
                    domRef={popupRef}
                    anchorEl={anchorRef.current}
                    placement="bottom-start"
                    modifiers={{
                      preventOverflow: { boundariesElement: "viewport" }
                    }}
                    isOpen={isOpen}
                  >
                    <div
                      className="bg-white text-black px-2 d-flex flex-column py-2 fs-14 rounded"
                      style={{ cursor: "context-menu" }}
                    >
                      <Button
                        onClick={() => {
                          setModalType(FavoriteCreatorTypes.favorite);
                          onModalOpen();
                          typeof toggleIsOpen === "function" && toggleIsOpen();
                        }}
                        vType="link"
                        className="d-flex pl-2 justify-content-start align-items-center text-left"
                      >
                        <div className="mr-1 ar-form-button-link-icon">
                          <FontAwesomeIcon size="lg" icon={faStarLight} />
                        </div>
                        <span>
                          <FormattedMessage id="ADD_FAVORITE" />
                        </span>
                      </Button>
                      <Button
                        onClick={() => {
                          setModalType(FavoriteCreatorTypes.folder);
                          onModalOpen();
                          typeof toggleIsOpen === "function" && toggleIsOpen();
                        }}
                        vType="link"
                        className="d-flex pl-2 align-items-center justify-content-start text-left"
                      >
                        <div className="mr-1 ar-form-button-link-icon">
                          <FontAwesomeIcon icon={faFolderPlus} />
                        </div>
                        <span>
                          <FormattedMessage id="ADD_FOLDER" />
                        </span>
                      </Button>
                    </div>
                  </Popup>
                  <div className="mb-3 d-flex flex-0-0-auto disable-selection w-100 align-items-center justify-content-between">
                    <div className={classnames(classes.appBarLinksTitle)}>
                      <FormattedMessage id={"FAVORITES"} />
                    </div>
                    <div className="d-flex align-items-center">
                      {(updateLoading || updateError) && (
                        <LoaderSpinner className="mr-2" size="xxs" />
                      )}
                      <div
                        ref={anchorRef}
                        onClick={() => {
                          typeof toggleIsOpen === "function" && toggleIsOpen();
                        }}
                        className={classnames(
                          "d-flex p-1 cursor-pointer rounded",
                          classes.appBarFavoriteAddItemButton
                        )}
                      >
                        <FontAwesomeIcon
                          style={{
                            height: "16px",
                            width: "16px"
                          }}
                          icon={faPlus}
                        />
                      </div>
                      {/* {setStickyFavorites && (
                        <InformationComponent
                          placement="right"
                          style={{ margin: "10px 13px" }}
                          text={intl.formatMessage({
                            id: !stickyFavorites ? "STICKY" : "UNSTICKY"
                          })}
                        >
                          <div
                            onClick={() => {
                              localStorage.setItem(
                                "air-vertical-sidebar-fixed-status",
                                `${!stickyFavorites ? 1 : 0}`
                              );
                              typeof setStickyFavorites === "function" &&
                                setStickyFavorites(!stickyFavorites);
                              typeof setIsOpen === "function" &&
                                setIsOpen(false);
                            }}
                            className={classnames(
                              "d-flex ml-3 p-1 cursor-pointer rounded",
                              classes.appBarFavoriteAddItemButton
                            )}
                          >
                            <FontAwesomeIcon
                              style={{
                                height: "16px",
                                width: "16px"
                              }}
                              icon={
                                !stickyFavorites ? faThumbTack : faTimes
                              }
                            />
                          </div>
                        </InformationComponent>
                      )} */}
                    </div>
                  </div>
                  {amountOfNodes === 0 && (
                    <div className="d-flex flex-column flex-1 justify-content-center align-items-center">
                      {/* <FontAwesomeIcon
                        icon={faStar}
                        size="3x"
                        className="text-white mb-4"
                      /> */}
                      <div className="text-white fs-16 fw-medium text-center mx-2 disable-selection">
                        <FormattedMessage id="EMPTY_FAVORITE_LIST" />
                      </div>
                    </div>
                  )}
                  {amountOfNodes > 0 && <AppBarFavorites />}
                </AppTreeSetValueContext.Provider>
              </AppTreeValueContext.Provider>
            </FontAwesomeIconsProvider>
          </Suspense>
        </AppTreeChildFolderCreatorContext.Provider>
      </AppTreeChildFavoriteCreatorContext.Provider>
    </div>
  );
};

interface IHeadLinkProps {
  routeModule: IMainRoute;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  forcedChildren?: any;
  buttonClass?: string;
}

const parseParams = (querystring: string) => {
  // parse query string
  const params = new URLSearchParams(querystring) as any;

  const obj: any = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  delete obj["favoriteId"];

  const qs = Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");

  return qs;
};

export const HeaderLink: FunctionComponent<IHeadLinkProps> = ({
  routeModule,
  onClick,
  forcedChildren,
  ...rest
}) => {
  const { Id: spaceId } = useSpace();
  const { buttonClass } = rest as any;
  // const { path, text, schema, icon, lsKey, unreadEntityName } = routeModule;
  const { path, text, icon, unreadEntityName, hasAccess, schema } = routeModule;

  // const dict = useMainViewSearchDict();

  // const searchDict = useContext(MainViewSearchContext) as any;
  let searchDictString;
  try {
    searchDictString = JSON.parse(
      localStorage.getItem(MainViewsSearchLSKey) as string
    );
  } catch (error) {
    searchDictString = undefined;
  }

  const search =
    schema && searchDictString && searchDictString[schema.name]
      ? "?" + searchDictString[schema.name]
      : "";

  const resolvedSearch = search.length > 0 ? "?" + parseParams(search) : "";

  const basePath = `/s/${spaceId}/${path}${resolvedSearch}`;
  // const basePath = `/s/${spaceId}/${path}`;

  // let search;

  // if ((lsKey || schema) && dict) search = dict[lsKey ? lsKey : schema.name];

  let resolvedPath = basePath;
  // if (search) resolvedPath += `?${search}`;

  const currentView = useSpaceMainView();

  const currentAccountSpace = useCurrentAccountSpace() as AccountSpace;

  if (typeof hasAccess === "function" && !hasAccess(currentAccountSpace))
    return null;

  const isSelected = currentView === path;

  if (forcedChildren) {
    return (
      <div
        className="position-relative mb-2"
        // className={isSelected ? "selected" : undefined}
      >
        <NavLink
          onClick={onClick}
          className={classnames(
            "ar-header-rounded-icon alternative-color disable-selection",
            buttonClass
          )}
          activeClassName="selected"
          to={resolvedPath}
        >
          {forcedChildren}
        </NavLink>
      </div>
    );
  }

  return (
    <NavLink
      onClick={onClick}
      className={classnames(classes.appBarLinkItem, "disable-selection")}
      activeClassName={classes.selected}
      to={resolvedPath}
    >
      {icon && (
        <div className="ar-header-link-icon">
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      <span>{text}</span>
      {unreadEntityName && (
        <HeaderLinkUnreadCount unreadEntityName={unreadEntityName} />
      )}
    </NavLink>
  );
};

const HeaderLinkUnreadCount = ({ ...props }) => {
  const data = useUnreadEntities();
  const { unreadEntityName } = props;
  if (!data) return null;

  const count = data[`Unread${unreadEntityName}`];
  if (!count) return null;

  return (
    <Badge
      type="primary"
      className={classnames(classes.badgeSelected, "span-badge ml-2")}
    >
      {count}
    </Badge>
  );
};

interface IHeaderLinkGroupSection {
  sectionRoutes: IMainRoute[];
  showSeperator: boolean;
  toggleActiveGroup: React.MouseEventHandler<HTMLAnchorElement>;
}

const HeaderLinkGroupSection: FunctionComponent<IHeaderLinkGroupSection> = ({
  sectionRoutes,
  showSeperator,
  toggleActiveGroup
}) => {
  return (
    <>
      {showSeperator && (
        <div className={classnames("my-3", classes.appBarLinksDivider)} />
      )}
      {sectionRoutes.map((route, i) => {
        return (
          <HeaderLink
            onClick={toggleActiveGroup}
            key={route.path}
            routeModule={route}
          />
        );
      })}
    </>
  );
};

const createResolvedSectionRoutes = (
  section: IMainRoute[],
  accessDict: RouteAccessDict,
  viewDict: RouteAccessDict
) => {
  const resolvedSectionRoutes = [];

  for (const route of section) {
    if (accessDict[route.path]) {
      resolvedSectionRoutes.push(route);
      viewDict[route.path] = true;
    }
  }
  return resolvedSectionRoutes;
};

const createResolvedGroup = (
  group: IMainRoute[][],
  accessDict: RouteAccessDict
) => {
  const resolvedGroup = [];
  const viewDict = {};
  for (const section of group) {
    const sectionRoutes = createResolvedSectionRoutes(
      section,
      accessDict,
      viewDict
    );
    if (sectionRoutes.length !== 0) resolvedGroup.push(sectionRoutes);
  }
  return { resolvedGroup, viewDict };
};

interface IBarGroup {
  singleGroup: IAppBarSingleGroup;
  icon: IconProp;
  text?: string;
  handleHover?: (group: IAppBarSingleGroup) => void;
  isSelected: boolean;
  handleToggle: (group: IAppBarSingleGroup) => void;
}

interface IFBarGroup {
  handleToggle: () => void;
  isSelected: boolean;
  handleHover?: () => void;
}

const AppBarGroup: FunctionComponent<IBarGroup> = ({
  singleGroup,
  icon,
  handleHover,
  text,
  isSelected,
  handleToggle
}) => {
  // const [] = useState(null)
  const accessDict = useSpaceRoutesAccess();
  const { group } = singleGroup;
  const unreadCount = useGroupUnreadCount(group);
  // verifies permissions
  const { resolvedGroup, viewDict } = useMemo(() => {
    return createResolvedGroup(group, accessDict);
  }, [accessDict, group]);

  if (resolvedGroup.length === 0) return null;

  const handleClick = () => {
    handleToggle({ ...singleGroup, group: resolvedGroup });
  };

  const handleMouseHover = () => {
    handleHover && handleHover({ ...singleGroup, group: resolvedGroup });
  };
  const resolvedNotificationsCount = unreadCount
    ? unreadCount > 99
      ? "99+"
      : unreadCount
    : null;
  return (
    <InformationComponent
      placement="right"
      style={{ margin: "10px 10px" }}
      text={text}
    >
      <div className="position-relative">
        {resolvedNotificationsCount && (
          <div
            style={{
              top: 0,
              right: 4,
              width: 12,
              height: 12
            }}
            className={classnames("unread-count position-absolute")}
          >
            {/* <span>{resolvedNotificationsCount}</span> */}
          </div>
        )}
        <HeaderLinkButton
          isActiveGroup={isSelected}
          handleHover={handleMouseHover}
          handleClick={handleClick}
          viewDict={viewDict}
          group={resolvedGroup}
          icon={icon}
        />
      </div>
    </InformationComponent>
  );
};

const AppFavoriteBarGroup: FunctionComponent<IFBarGroup> = ({
  handleToggle,
  isSelected,
  handleHover
}) => {
  const FavoriteId = useContext(AppFavoriteContext);
  const toggle = () => {
    handleToggle();
  };
  const hover = () => {
    handleHover && handleHover();
  };

  return (
    <HeaderFavoriteLinkButton
      isSelected={isSelected}
      isActiveGroup={Boolean(FavoriteId)}
      handleClick={toggle}
      handleHover={hover}
      icon={faStar}
    />
  );
};

interface IHeaderLinkProps {
  group: IMainRoute[][];
  handleHover: () => void;
  viewDict: RouteAccessDict;
  icon: IconProp;
  isActiveGroup: boolean;
  handleClick: () => void;
}
interface IFHeaderLinkProps {
  icon: IconProp;
  isActiveGroup: boolean;
  handleClick: () => void;
  handleHover: () => void;
  isSelected: boolean;
}

const HeaderFavoriteLinkButton: FunctionComponent<IFHeaderLinkProps> = ({
  isActiveGroup,
  isSelected,
  icon,
  handleHover,
  handleClick
}) => {
  // const unreadCount = useGroupUnreadCount(group);

  const ObfuscateState = useContext(ObfuscateProvider);

  const handleTouch = (e: any) => {
    if (ObfuscateState === true) e.preventDefault();
    else {
      handleClick();
    }
  };

  useEffect(() => {
    if (isActiveGroup) {
      handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      onClick={handleTouch}
      onMouseEnter={handleHover}
      className={classnames(
        "text-white d-flex mb-2 mt-2 align-items-center justify-content-center",
        classes.appBarGroupIcon,
        {
          [classes.selected]: isSelected,
          [classes.active]: isActiveGroup
        }
      )}
    >
      <FontAwesomeIcon size="lg" icon={icon} />
    </div>
  );
};

const HeaderLinkButton: FunctionComponent<IHeaderLinkProps> = ({
  group,
  viewDict,
  isActiveGroup,
  handleHover,
  icon,
  handleClick
}) => {
  // const unreadCount = useGroupUnreadCount(group);
  const ActiveFavoriteView = useContext(AppFavoriteContext);
  const ObfuscateState = useContext(ObfuscateProvider);

  const handleTouch = (e: any) => {
    if (ObfuscateState === true) e.preventDefault();
    else {
      handleClick();
    }
  };

  const currentView = useSpaceMainView();

  const isSelected = viewDict[currentView] && !ActiveFavoriteView;

  useEffect(() => {
    if (isSelected) {
      handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      onMouseEnter={handleHover}
      onClick={handleTouch}
      className={classnames(
        "text-white d-flex mb-2 align-items-center justify-content-center",
        classes.appBarGroupIcon,
        {
          [classes.active]: isSelected,
          [classes.selected]: isActiveGroup
        }
      )}
    >
      <FontAwesomeIcon size="lg" icon={icon} />
    </div>
  );
};

const SettingsLink = ({ ...props }: any) => {
  const ObfuscateState = useContext(ObfuscateProvider);
  const { text } = props;
  const accessDict = useSpaceRoutesAccess();
  const asAccess = useMemo(() => {
    return accessDict[settingsRoute.path];
  }, [accessDict]);

  if (!asAccess) return null;

  return (
    <>
      {/* <div className={classnames(classes.appBarAirdeskLogo, "mt-2 mb-2")}></div> */}
      <InformationComponent
        placement="right"
        style={{ margin: "10px 10px" }}
        text={text}
      >
        <HeaderNavLink
          activeClassName={""}
          routeModule={settingsRoute}
          className={"ar-header-rounded-icon"}
        >
          <span
            style={{
              opacity: ObfuscateState && 0.3,
              pointerEvents: ObfuscateState && "none"
            }}
          >
            <FontAwesomeIcon icon={faCog} size="lg" />
          </span>
        </HeaderNavLink>
      </InformationComponent>
    </>
  );
};

export default AppBar;
