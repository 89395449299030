import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const DealDetailsComp = React.lazy(() =>
  import(/* webpackChunkName: "deals-details" */ "./DealDetails2Comp")
);

const DealsDetails = () => {
  return (
    <SuspenseErrorBoundary>
      <DealDetailsComp />
    </SuspenseErrorBoundary>
  );
};

export default DealsDetails;
