import React, { useEffect, useState } from "react";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";

const SuspenseLoader = (props) => {
  const [isShowing, setIsShowing] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShowing(true);
    }, 300);
    return () => clearTimeout(timeout);
  }, []);
  if (!isShowing) return null;
  return (
    <div className="w-100 flex-1 h-100 d-flex align-items-center justify-content-center">
      <LoaderSpinner className="text-primary" center size="sm" {...props} />
    </div>
  );
};

export default SuspenseLoader;
