import React, { useRef, useEffect, useState, useMemo } from "react";
import Popup from "../../Components/Popup/Popup";
import { useSpace } from "../../Contexts/SpaceContext";
import { useQuery } from "../../Helpers/IOClient";

export const useHoverEvents = (timeout = 200, timeoutLeave = 100) => {
  const timeoutRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const clearTimeoutRef = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  };

  const onAnchorMouseEnter = (e) => {
    if (timeoutRef.current) {
      clearTimeoutRef();
      // return;
    }
    timeoutRef.current = setTimeout(() => {
      setIsOpen(true);
      timeoutRef.current = null;
    }, timeout);
  };

  const onAnchorMouseLeave = (e) => {
    if (timeoutRef.current) {
      clearTimeoutRef();
      // return;
    }
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
      timeoutRef.current = null;
    }, timeoutLeave);
  };

  const onPopupMouseEnter = (e) => {
    if (timeoutRef.current) {
      clearTimeoutRef();
      return;
    }
  };

  const onPopupMouseLeave = (e) => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
      timeoutRef.current = null;
    }, timeoutLeave);
  };

  //on unmount
  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeoutRef();
      }
    },
    []
  );

  return {
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    onPopupMouseEnter,
    onPopupMouseLeave,
    isOpen,
    setIsOpen
  };
};

export const MemoPopup = React.memo(({ Component, value, props }) => {
  return <Component value={value} {...props} />;
});

export const OriginPopup = ({
  PopupComp,
  props,
  hoverEvents,
  anchorRef,
  options,
  ...rest
}) => {
  const { onPopupMouseEnter, onPopupMouseLeave, isOpen } = hoverEvents;

  const { placement } = props || {};

  const { schema, valueProp, endpoint: buildEndpoint } = options || {};

  const space = useSpace();

  const id = props[valueProp] ? props[valueProp].Id : undefined;
  const propName = props[valueProp] ? props[valueProp].Name : undefined;

  const openedFirstRef = useRef(false);

  const endpoint = useMemo(() => {
    if (id === "00000000-0000-0000-0000-000000000001") return null;
    if (!isOpen && !openedFirstRef.current) return null;
    openedFirstRef.current = true;
    if (buildEndpoint) return `${buildEndpoint(space.Id)}/${id}`;
    else if (schema) return `${schema.getEndpoint(space.Id)}/${id}`;

    return null;
  }, [buildEndpoint, id, isOpen, schema, space.Id]);

  const { data, loading } = useQuery(endpoint, schema);

  const hasData = schema || endpoint ? Boolean(data) : true;

  if (!hasData && !loading && propName)
    return (
      <Popup
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        placement={placement || "bottom-start"}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <div
          className="bg-black text-white px-2 py-1 fs-14 rounded"
          style={{ cursor: "context-menu" }}
        >
          <span>{propName}</span>
        </div>
      </Popup>
    );

  return (
    <Popup
      modifiers={{
        preventOverflow: { boundariesElement: "viewport" }
      }}
      isOpen={isOpen && hasData}
      placement={placement || "bottom-start"}
      anchorEl={anchorRef.current}
      onMouseEnter={onPopupMouseEnter}
      onMouseLeave={onPopupMouseLeave}
      {...rest}
    >
      <MemoPopup value={data} Component={PopupComp} props={props} />
    </Popup>
  );
};

export const createOriginPopup = (Anchor, PopupComp, options) => {
  const OriginAnchorPopup = (props) => {
    const anchorRef = useRef();

    const hoverEvents = useHoverEvents();

    const { onAnchorMouseEnter, onAnchorMouseLeave } = hoverEvents;
    const { disablePopup, ...rest } = props;
    return (
      <React.Fragment>
        <Anchor
          ref={anchorRef}
          onMouseOver={onAnchorMouseEnter}
          onMouseOut={onAnchorMouseLeave}
          {...rest}
        />
        {!disablePopup && (
          <OriginPopup
            options={options}
            props={props}
            hoverEvents={hoverEvents}
            anchorRef={anchorRef}
            PopupComp={PopupComp}
          />
        )}
      </React.Fragment>
    );
  };

  return OriginAnchorPopup;
};
