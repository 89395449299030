import React, { useContext, useMemo } from "react";
import { ConnectedProjectOrigin } from "../../../Containers/Origin/ProjectOrigin";
// import { ConnectedContactProfile } from "../../Contacts/ContactProfile";
import TicketOrigin from "../../../Containers/Origin/TicketOrigin";
import {
  useTicket,
  useAccount,
  useTask,
  useClient,
} from "../../../Hooks/EntityHooks";
import TaskOrigin from "../../../Containers/Origin/TaskOrigin";
import moment from "moment";
import FormattedTimePreview from "../../FormattedTimePreview/FormattedTimePreview";
import AccountProfile from "../../Accounts/AccountProfile";
import { ConnectedContractOrigin } from "../../../Containers/Origin/ContractOrigin";
import { ConnectedClientOrigin } from "../../../Containers/Origin/ClientOrigin";
import { useQuery } from "../../../Helpers/IOClient";
import { useSpace } from "../../../Contexts/SpaceContext";
import {
  spaceProjectsSchema,
  spaceContractSchema,
  spaceContactSchema,
  spaceTicketsSchema,
  spaceTasksSchema,
  spaceInterventionsSchema,
  spaceDealsSchema,
} from "../../../config/schema";
import { many } from "../../../Helpers/SchemaHelper";
import { DetailsAccordion, useDetailsEntity } from "../DetailsView";
import {
  ProjectFormButton,
  ContractFormButton,
  ContactFormButton,
  TicketFormButton,
  TaskFormButton,
  InterventionFormButton,
} from "../../../Containers/Form/FormButtons";
import { ConnectedInterventionOrigin } from "../../../Containers/Origin/InterventionOrigin";
import { ConnectedDealOrigin } from "../../../Containers/Origin/DealOrigin";
import { ConnectedContactPopup } from "../../../Containers/Origin/ContactPopup";
import { moduleTypes, hasModuleAccess } from "../../../Helpers/ModulesHelper";
import { useCurrentAccountSpace } from "../../../Contexts/UserContext";
import { FormattedMessage } from "react-intl";

const DetailsEntityChildSkeleton = () => {
  return (
    <div className="px-4 py-4 ar-details-entity">
      <div className="ar-details-id skeleton mb-4" />
      <div className="ar-details-child-skeleton skeleton rounded mb-2" />
      <div className="ar-details-child-skeleton skeleton rounded" />
    </div>
  );
};

export const DetailsEntityChildrenSkeleton = () => {
  return (
    <>
      <div className="px-4 py-4 border-bottom">
        <div className="ar-details-id skeleton mb-4" />
        <div className="ar-details-child-skeleton skeleton rounded mb-2" />
        <div className="ar-details-child-skeleton skeleton rounded" />
      </div>
      <div className="px-4 py-4 border-bottom">
        <div className="ar-details-id skeleton mb-4" />
        <div className="ar-details-child-skeleton skeleton rounded mb-2" />
        <div className="ar-details-child-skeleton skeleton rounded" />
      </div>
      <div className="px-4 py-4">
        <div className="ar-details-id skeleton mb-4" />
        <div className="ar-details-child-skeleton skeleton rounded mb-2" />
        <div className="ar-details-child-skeleton skeleton rounded" />
      </div>
    </>
  );
};

const DetailsProjectChild = ({ item }) => {
  return (
    <div className="d-flex">
      <ConnectedProjectOrigin placement="left" project={item} />
    </div>
  );
};

const DetailsContactChild = ({ item }) => {
  return <ConnectedContactPopup contact={item} />;
};

const DetailsClientChild = ({ item }) => {
  return (
    <div className="d-flex">
      <ConnectedClientOrigin placement="left" client={item} />
    </div>
  );
};

const DetailsContractChild = ({ item }) => {
  return (
    <div className="d-flex">
      <ConnectedContractOrigin placement="left" contract={item} />
    </div>
  );
};

const DetailsInterventionChild = ({ item }) => {
  return (
    <div className="d-flex">
      <ConnectedInterventionOrigin placement="left" intervention={item} />
    </div>
  );
};

const DetailsDealChild = ({ item }) => {
  return (
    <div className="d-flex">
      <ConnectedDealOrigin placement="left" deal={item} />
    </div>
  );
};

const DetailsTicketChild = ({ item }) => {
  const ticket = useTicket(item);
  const creator = useAccount(ticket && ticket.Creator);
  const manager = useAccount(ticket && ticket.ManagerAccount);

  return (
    <>
      <TicketOrigin
        className="d-inline-block"
        placement="left"
        ticket={ticket}
      />
      {creator && (
        <div className="text-black mt-1">
          <span>
            <FormattedMessage id={"CREATED_BY"} />:{" "}
          </span>
          <span className="fw-medium">{creator.Name}</span>
        </div>
      )}
      {manager && (
        <div className="text-black mt-1">
          <span>
            <FormattedMessage id={"MANAGER"} />:{" "}
          </span>
          <span className="fw-medium">{manager.Name}</span>
        </div>
      )}
    </>
  );
};

const DetailsTaskChild = ({ item }) => {
  const task = useTask(item);
  const manager = useAccount(task && task.Manager);
  const client = useClient(task && (task.Client || task.IndirectClient));
  const { EndDate, EstimatedCost } = task || {};
  const formattedEndDate = moment(EndDate).format("DD/MM/YYYY hh:mm");
  return (
    <>
      <TaskOrigin className="d-inline-block" placement="left" task={task} />
      {client && (
        <div className="text-black mt-1">
          <span>
            <FormattedMessage id={"ACCOUNT"} />:{" "}
          </span>
          <span className="fw-medium">{client.Name}</span>
        </div>
      )}
      <div className="text-black mt-1">
        <span>
          <FormattedMessage id={"DEADLINE"} />:{" "}
        </span>
        <span className="fw-medium">{formattedEndDate}</span>
      </div>
      <div className="text-black mt-1">
        <span>
          <FormattedMessage id={"DURATION"} />:{" "}
        </span>
        <FormattedTimePreview
          className="fw-medium d-inline-block"
          value={EstimatedCost}
          asHourMinute
        />
      </div>

      {manager && (
        <div className="text-black d-flex align-items-center mt-1">
          <span>
            <FormattedMessage id={"MANAGER"} />:{" "}
          </span>
          <div className="ml-1 fw-medium d-inline-block">
            <AccountProfile account={manager} />
          </div>
        </div>
      )}
    </>
  );
};

const SingleEntityContainer = ({
  id,
  singleTitle,
  ChildComponent,
  FormButton,
  formProps,
}) => {
	const loading = !Boolean(useDetailsEntity());

  if (loading) return <DetailsEntityChildSkeleton />;

  return (
    <div className="ar-details-entity px-4 py-4">
      <DetailsAccordion title={`${singleTitle}`} isOpenByDefault>
        {id ? (
          <div className="ar-details-entity-children">
            <div className="ar-details-entity-child p-2 rounded border">
              <ChildComponent item={id} />
            </div>
          </div>
        ) : (
          <div className="text-black fs-14 text-center mt-2">
            <FormattedMessage id={"NO_RECORDS"} />
          </div>
        )}
      </DetailsAccordion>
    </div>
  );
};

const MultipleEntityContainer = ({
  ids,
  singleTitle,
  ChildComponent,
  FormButton,
  formProps,
}) => {
  const loading = !Boolean(useDetailsEntity());
  if (loading) return <DetailsEntityChildSkeleton />;

  return (
    <div className="ar-details-entity px-4 py-4">
      <DetailsAccordion title={`${singleTitle}`} isOpenByDefault>
        {ids && ids.length > 0 ? (
          <div className="ar-details-entity-children">
            {ids.map((id) => {
              return (
                <div
                  key={id}
                  className="ar-details-entity-child p-2 rounded border"
                >
                  <ChildComponent item={id} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-black fs-14 text-center mt-2">
            <FormattedMessage id={"NO_RECORDS"} />
          </div>
        )}
      </DetailsAccordion>
    </div>
  );
};

const AsyncEntityContainer = ({
  items: objectItems,
  title,
  ChildComponent,
  FormButton,
  formProps,
}) => {
  const items = [];
  for (const key in objectItems) {
    if (objectItems.hasOwnProperty(key)) items.push(objectItems[key]);
  }

  const count = items.length;
  return (
    <div className="ar-details-entity px-4 py-4">
      <DetailsAccordion title={`${title} (${count})`} isOpenByDefault>
        {count ? (
          <div className="ar-details-entity-children">
            {items.map((item) => {
              return (
                <div
                  key={item}
                  className="ar-details-entity-child p-2 rounded border"
                >
                  <ChildComponent item={item} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-black fs-14 text-center mt-2">
            <FormattedMessage id={"NO_RECORDS"} />
          </div>
        )}
        {FormButton && (
          <div className="d-flex align-items-center justify-content-center">
            <FormButton
              formProps={formProps}
              vType="outline-primary"
              className="mt-3"
            />
          </div>
        )}
      </DetailsAccordion>
    </div>
  );
};

const DetailsContext = React.createContext();

export const DetailsEntities = ({ children, formProps }) => {
  return (
    <DetailsContext.Provider value={formProps}>
      <div className="ar-details-entities">{children}</div>
    </DetailsContext.Provider>
  );
};

// const createEntitiesComponent = ({
//   ItemComponent,
//   schema,
//   singleTitle,
//   title,
//   FormButton,
//   defaultFilter
// }) => {
//   const responseObject = {
//     d: {
//       results: many(schema)
//     }
//   };

//   const DetailsEntity = ({ filter, id, ids }) => {
//     const space = useSpace();
//     const baseUrl = schema.getEndpoint(space.Id);
//     const formProps = useContext(DetailsContext);
//     let url = filter ? `${baseUrl}?$filter=(${filter}` : baseUrl;
//     if (defaultFilter) {
//       if (filter) url += ` and (${defaultFilter}))`;
//       else url += `?$filter=(${defaultFilter})`;
//     }

//     const { loading, data, error } = useQuery(url, responseObject);

//     if (ids)
//       return (
//         <MultipleEntityContainer
//           ids={ids}
//           singleTitle={title}
//           ChildComponent={ItemComponent}
//         />
//       );

//     if (id)
//       return (
//         <SingleEntityContainer
//           id={id}
//           singleTitle={singleTitle || title}
//           ChildComponent={ItemComponent}
//         />
//       );
//     if (loading || error) return <DetailsEntityChildSkeleton />;

//     return (
//       <AsyncEntityContainer
//         items={data.d.results}
//         title={title}
//         ChildComponent={ItemComponent}
//         FormButton={FormButton}
//         formProps={formProps}
//       />
//     );
//   };

//   return DetailsEntity;
// };

const createQueryableEntityComponent = ({
  ItemComponent,
  schema,
  title,
  FormButton,
  moduleType,
  defaultFilter,
}) => {
  const responseObject = many(schema);

  const DetailsEntity = ({ filter }) => {
    const space = useSpace();
    const accountSpace = useCurrentAccountSpace();
    const canAcess = hasModuleAccess(accountSpace.Modules[moduleType]);

    const formProps = useContext(DetailsContext);
    const url = useMemo(() => {
      const baseUrl = schema.getEndpoint(space.Id);

      if (!filter) return null;

      let url = filter ? `${baseUrl}?$filter=(${filter}` : baseUrl;
      if (defaultFilter) {
        if (filter) url += ` and (${defaultFilter}))`;
        else url += `&$filter=(${defaultFilter})`;
      } else if (filter) url += ")";
      return url;
    }, [filter, space.Id]);

    const { loading, data, error } = useQuery(url, responseObject);
    if (!canAcess) return null;
    if (!url || loading || error) return <DetailsEntityChildSkeleton />;

    return (
      <AsyncEntityContainer
        items={data}
        title={title}
        ChildComponent={ItemComponent}
        FormButton={FormButton}
        formProps={formProps}
      />
    );
  };

  return DetailsEntity;
};

const createSingleEntityComponent = ({ ItemComponent, title }) => {
  const DetailsEntity = ({ id }) => {
    if (!id) return <DetailsEntityChildSkeleton />;

    return (
      <SingleEntityContainer
        id={id}
        singleTitle={title}
        ChildComponent={ItemComponent}
      />
    );
  };

  return DetailsEntity;
};
const createMultipleEntityComponent = ({ ItemComponent, title }) => {
  const DetailsEntity = ({ ids }) => {
    if (!ids) return <DetailsEntityChildSkeleton />;

    return (
      <MultipleEntityContainer
        ids={ids}
        singleTitle={title}
        ChildComponent={ItemComponent}
      />
    );
  };

  return DetailsEntity;
};

export const DetailsClientEntities = createMultipleEntityComponent({
  ItemComponent: DetailsClientChild,
  title: "Cliente",
});
export const DetailsClientEntity = createSingleEntityComponent({
  ItemComponent: DetailsClientChild,
  title: "Cliente",
});

export const DetailsProjectQueryableEntities = createQueryableEntityComponent({
  title: "Projetos",
  schema: spaceProjectsSchema,
  ItemComponent: DetailsProjectChild,
  FormButton: ProjectFormButton,
  moduleType: moduleTypes.projects,
  defaultFilter: `PipelineStatus/Status eq '1'`,
});

export const DetailsContractQueryableEntities = createQueryableEntityComponent({
  title: "Contratos",
  schema: spaceContractSchema,
  ItemComponent: DetailsContractChild,
  FormButton: ContractFormButton,
  moduleType: moduleTypes.contracts,
  defaultFilter: `PipelineStatus/Status eq '1'`,
});

export const DetailsContactQueryableEntities = createQueryableEntityComponent({
  title: "Contatos",
  schema: spaceContactSchema,
  ItemComponent: DetailsContactChild,
  FormButton: ContactFormButton,
  moduleType: moduleTypes.contacts,
});
export const DetailsContactEntity = createSingleEntityComponent({
  ItemComponent: DetailsContactChild,
  title: "Contato",
});

export const DetailsTicketQueryableEntities = createQueryableEntityComponent({
  title: "Tickets",
  schema: spaceTicketsSchema,
  ItemComponent: DetailsTicketChild,
  FormButton: TicketFormButton,
  moduleType: moduleTypes.tickets,
  defaultFilter: `PipelineStatus/Status eq '1'`,
});

export const DetailsTaskQueryableEntities = createQueryableEntityComponent({
  title: "Tarefas agendadas",
  schema: spaceTasksSchema,
  ItemComponent: DetailsTaskChild,
  moduleType: moduleTypes.tasks,
  FormButton: TaskFormButton,
  defaultFilter: `PipelineStatus/Status eq '1'`,
});

export const DetailsInterventionQueryableEntities = createQueryableEntityComponent(
  {
    title: "Registos de Tempo",
    schema: spaceInterventionsSchema,
    ItemComponent: DetailsInterventionChild,
    FormButton: InterventionFormButton,
    moduleType: moduleTypes.interventions,
  }
);

export const DetailsDealQueryableEntities = createQueryableEntityComponent({
  title: "Negócios",
  schema: spaceDealsSchema,
  ItemComponent: DetailsDealChild,
  moduleType: moduleTypes.deals,
  // FormButton: TaskFormButton,
  defaultFilter: `PipelineStatus/Status eq '1'`,
});

export const DetailsEntityChildren = () => {
  return null;
};
