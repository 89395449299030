import React, { useContext, useEffect } from "react";

import { ProjectMainViewLink } from "../../../Helpers/HooksHelper";
import { getEntity } from "../../../Helpers/IOClient";
import {
  DashboardTitle,
  ReportsDateProvider,
  DashboardEmptyResponse
} from "../Dashboard";
import { BarLoader } from "../../../Components/GlobalLoader/GlobalLoader";
import { useCurrentAccount } from "../../../Contexts/UserContext";
import { spacePipelineSchema } from "../../../config/schema";
import { PipelineWorkloadComboLoadChart } from "../Echarts/TicketLoadBarChart";
// import moment from "moment";
import { FormattedMessage } from "react-intl";
import { useDashboardQuery } from "../DashboardQueryHelper";
import { useSidebar } from "../../../Components/Sidebar/SidebarV2";
import { DashboardSettingsContext } from "../DashboardLayout";
import DashboardCardSettingsForm, {
  handleIntervalCalculation
} from "../DashboardCardSettingsForm";
import Button from "../../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import CreateDashboardFilterLinksHelper from "../DashboardFilterLinksHelper";
const lS_Group = "dashboard-layout-projetos";
export const OpenCounterProjects = (props) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "openProjects";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, loading, error } = useDashboardQuery(
    `Dashboard/Services/Projects/1/Count?todate=${
      DataReports[0] === "Projects"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Projects"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Projects"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`,
    null,
    { cache: false }
  );

  var account = useCurrentAccount().Id;
  if (!loading && !error) {
    const resolvedData = data.CurrentMonth;
    const user =
      DataReports[0] === "Projects" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"OPEN_PROJECTS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Projects" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"OPEN_PROJECTS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <ProjectMainViewLink
          className="ar-dashboard-active-values mb-2"
          params={`$filter=PipelineStatus/Status eq '1'${CreateDashboardFilterLinksHelper(
            DataReports,
            account
          )}`}
        >
          {resolvedData}
        </ProjectMainViewLink>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const CanceledCounterProjects = (props) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "canceledProjects";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, loading, error } = useDashboardQuery(
    `Dashboard/Services/Projects/3/Count?todate=${
      DataReports[0] === "Projects"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Projects"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Projects"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`,
    null,
    { cache: false }
  );
  // const user =
  //   DataReports[0] === "Contracts" ? (
  //     DataReports[2]?.Name
  //   ) : (
  //     <FormattedMessage id={"ME"} />
  //   );
  var account = useCurrentAccount().Id;
  if (!loading && !error) {
    const resolvedData = data.CurrentMonth;
    const user =
      DataReports[0] === "Projects" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"CANCELLED_PROJECTS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Projects" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"CANCELLED_PROJECTS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <ProjectMainViewLink
          className="ar-dashboard-canceled-values mb-2"
          params={`$filter=PipelineStatus/Status eq '3'${CreateDashboardFilterLinksHelper(
            DataReports,
            account
          )}`}
        >
          {resolvedData}
        </ProjectMainViewLink>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const ClosedCounterProjects = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "closedProjects";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, loading, error } = useDashboardQuery(
    `Dashboard/Services/Projects/4/Count?todate=${
      DataReports[0] === "Projects"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Projects"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Projects"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`,
    null,
    { cache: false }
  );
  var account = useCurrentAccount().Id;
  const user =
    DataReports[0] === "Projects" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const resolvedData = data.CurrentMonth;
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"CLOSED_PROJECTS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Projects" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                {Settings.Interval ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"CLOSED_PROJECTS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <ProjectMainViewLink
          className="ar-dashboard-closed-values mb-2"
          params={`$filter=PipelineStatus/Status eq '4'${CreateDashboardFilterLinksHelper(
            DataReports,
            account
          )}`}
        >
          {resolvedData}
        </ProjectMainViewLink>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const PipelineWorkloadProject = () => {
  const { data, error, loading } = useDashboardQuery(`query/Pipelines?Type=1`, [
    spacePipelineSchema
  ]);

  if (!loading && !error) {
    return <ProjectStatusTimesTotals results={data} />;
  } else {
    return <BarLoader style={{ marginTop: -16 }} isLoading={loading} />;
  }
};
// const defaultTimeFrame = moment().subtract(180, "d").format("YYYY-MM-DD");

const ProjectStatusTimesTotals = ({ results }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "PipelineWorkloadProject";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings = (DashboardSettings &&
    DashboardSettings[lS_Group] &&
    DashboardSettings[lS_Group][lS_Field]) || { Pipeline: results[0] };
  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const pipeline =
    DataReports[0] === "Projects" ? DataReports[3] : Settings.Pipeline;
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Projects/AverageStatus?pipelineId=${
      pipeline ? pipeline : Settings.Pipeline
    }&todate=${
      DataReports[0] === "Projects"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Projects"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Projects"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Projects" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );

  if (!loading && !error) {
    // data.splice(20, data.length - 21);
    const { Results, HasData } = data;

    const resolvedData = HasData ? [...Results] : [];

    const user =
      DataReports[0] === "Projects" ? (
        DataReports[2]?.Name
      ) : (
        <FormattedMessage id={"ME"} />
      );
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"AVERAGE_STATUS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Projects" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"PIPELINE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {
                  getEntity(
                    spacePipelineSchema,
                    pipeline ? pipeline : Settings.Pipeline
                  )?.Name
                }
              </span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    pipelineType={1}
                    Title={"AVERAGE_STATUS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: true
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <PipelineWorkloadComboLoadChart Data={resolvedData} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="projects">
            <FormattedMessage id={"EMPTY_PROJECTS"} />
          </DashboardEmptyResponse>
        )}
        {/* {Results.length === 0 ? (
          <DashboardEmptyResponse className="header h-100" type="projects">
            <FormattedMessage id={"EMPTY_PROJECTS"} />
          </DashboardEmptyResponse>
        ) : (
          <div className="h-100">
            {Results.length > 0 && (
              <PipelineWorkloadComboLoadChart Data={Results} />
            )}
          </div>
        )} */}
      </div>
    );
  } else {
    if (!loading && error.status === 403) {
      return (
        <div className="px-3 of-hidden h-100 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <DashboardTitle
              className="mb-0 fs-20"
              text={<FormattedMessage id={"AVERAGE_STATUS"} />}
            />
            <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
          </div>
          {DataReports[0] !== "Projects" && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-2">
                <span className="ar-dashboard-description">
                  <FormattedMessage id={"INTERVAL"} />:
                </span>
                <span className="text-primary pl-1 fs-14">
                  {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                    <FormattedMessage id={Settings.Interval.title} />
                  ) : (
                    <FormattedMessage id={"ALL"} />
                  )}
                </span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">{user}</span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"PIPELINE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">
                  {
                    getEntity(
                      spacePipelineSchema,
                      pipeline ? pipeline : Settings.Pipeline
                    )?.Name
                  }
                </span>
              </div>
              <Button
                className="ar-dashboard-card-settings-link"
                vType="link-primary"
                onClick={() =>
                  openSidebar(
                    <DashboardCardSettingsForm
                      lS_Field={lS_Field}
                      lS_Group={lS_Group}
                      Settings={Settings}
                      closeSidebar={closeSidebar}
                      pipelineType={5}
                      Title={"AVERAGE_STATUS"}
                      handleChanges={handleLayoutChange}
                      AvailableSettings={{
                        interval: true,
                        scope: true,
                        pipeline: true
                      }}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faCog} />
              </Button>
            </div>
          )}
          <DashboardEmptyResponse type="projects">
            {DataReports ? (
              DataReports[4] === "Account" ? (
                <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
              ) : (
                <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
              )
            ) : (
              <FormattedMessage id={"EMPLOYEE"} />
            )}
          </DashboardEmptyResponse>
        </div>
      );
    }

    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};
