import React, { useRef, useEffect, useState } from "react";
import Popup from "../../../../Components/Popup/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import classes from "./DomainKeys.module.css";
import { FormattedMessage } from "react-intl";
import { copyToClipboard } from "../../../../Helpers/MiscHelper";

export const DomainKeyClipText = ({ children }) => {
  const anchorRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    const timeout = setTimeout(() => {
      setIsOpen(false);
      //   onAnchorMouseLeave();
    }, 600);

    return () => clearTimeout(timeout);
  }, [isOpen]);

  if (!children) return null;

  return (
    <div
      ref={anchorRef}
      className="link-primary fw-medium cursor-pointer d-inline-flex align-items-center"
      onClick={(e) => {
        copyToClipboard(children);
        setIsOpen(true);
      }}
    >
      <Popup
        placement="top"
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
      >
        <div
          className="bg-black text-white px-2 py-1 fs-14 rounded"
          style={{ cursor: "context-menu" }}
        >
          <span>
            <FormattedMessage id="COPIED" />
          </span>
        </div>
      </Popup>
      <div className="mr-1">{children}</div>

      <FontAwesomeIcon size="sm" icon={faCopy} />
    </div>
  );
};

export const DomainKeyPre = ({ value, wrap }) => {
  const anchorRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    const timeout = setTimeout(() => {
      setIsOpen(false);
      //   onAnchorMouseLeave();
    }, 600);

    return () => clearTimeout(timeout);
  }, [isOpen]);

  if (!value) return null;

  return (
    <>
      <Popup
        placement="top"
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
      >
        <div
          className="bg-black text-white px-2 py-1 fs-14 rounded"
          style={{ cursor: "context-menu" }}
        >
          <span>
            <FormattedMessage id="COPIED" />
          </span>
        </div>
      </Popup>
      <div
        ref={anchorRef}
        onClick={(e) => {
          copyToClipboard(value);
          setIsOpen(true);
        }}
        className={classnames(classes.keyPre, {
          [classes.wrap]: wrap
        })}
      >
        <pre className={"p-3"}>{value}</pre>
        <FontAwesomeIcon icon={faCopy} />
      </div>
    </>
  );
};

export const DomainKeyDescCopy = ({ value, wrap }) => {
  const anchorRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    const timeout = setTimeout(() => {
      setIsOpen(false);
      //   onAnchorMouseLeave();
    }, 600);

    return () => clearTimeout(timeout);
  }, [isOpen]);
  return (
    <>
      <Popup
        placement="top"
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
      >
        <div
          className="bg-black text-white px-2 py-1 fs-14 rounded"
          style={{ cursor: "context-menu" }}
        >
          <span>
            <FormattedMessage id="COPIED" />
          </span>
        </div>
      </Popup>
      <div
        className={classnames(
          "d-flex align-items-center w-100 h-100",
          classes.keyDesc
        )}
      >
        <div className="mr-2 flex-1 overflow-hidden text-truncate">{value}</div>
        <div
          className={classnames(
            classes.keyDescIcon,
            "d-flex align-items-center justify-content-center rounded border text-primary"
          )}
          onClick={(e) => {
            copyToClipboard(value);
            setIsOpen(true);
          }}
          ref={anchorRef}
        >
          <FontAwesomeIcon icon={faCopy} />
        </div>
      </div>
    </>
  );
};
