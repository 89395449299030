import React, { useCallback, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useServerAwareState } from "../../Components/CGrid/ServerGrid";
import { AppBarFavoriteQueryContext } from "./AppBarFavoriteQuery";
import Button from "../../Components/Button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import {
  useFavoriteOrViewChecker,
  ViewTypeEnumAppFavorites
} from "./AppBarHelper";
import { ServerAwareUpdateViewsContext } from "../../Components/CGrid/ServerAwareViews";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-light-svg-icons";
import { useSpace } from "../../Contexts/SpaceContext";
import querystring from "query-string";

const getColorFilterDict = (spaceId: number) => {
  const lsValue = localStorage.getItem(`ar-color-filter-space-${spaceId}`);
  if (!lsValue) return {};
  const resolvedVal = JSON.parse(lsValue);
  return resolvedVal;
};

export const useSaveCurrentView = () => {
  const commitChanges = useContext(AppBarFavoriteQueryContext)?.commitChanges;
  const { params, paramsUrl, schema } = useServerAwareState();
  const space = useSpace();
  const { view, favoriteId } = params;
  const handleUpdate = () => {
    typeof commitChanges === "function" &&
      commitChanges((oldTree) => {
        const newTree = { ...oldTree };
        const newItems = { ...newTree.items };
        const newItem = { ...newItems[favoriteId] };
        const newItemData = { ...newItem.data };
        newItemData.viewType = view || ViewTypeEnumAppFavorites.grid;
        newItemData.search = paramsUrl;
        const colorFitlers = getColorFilterDict(space.Id);
        const viewColorFilters = colorFitlers[schema.name];
        newItemData.colorFilters = viewColorFilters;
        newItem.data = newItemData;
        newItems[favoriteId] = newItem;
        newTree.items = newItems;
        return newTree;
      }, true);
  };
  return handleUpdate;
};

const SaveFavoriteButton = ({ ...props }) => {
  const { minimal, overideSchema, ...rest } = props;
  const handleFavoriteUpdate = useSaveCurrentView();
  const updateLsViews = useContext(ServerAwareUpdateViewsContext);
  const result = useFavoriteOrViewChecker();
  const location = useLocation();
  const space = useSpace();
  const intl = useIntl();
  const { schema: unresolvedSchema } = useServerAwareState();

  const schema = useMemo(() => {
    if (overideSchema) return overideSchema;
    else return unresolvedSchema;
  }, [overideSchema, unresolvedSchema]);

  const handleClick = useCallback(() => {
    if (result) {
      handleFavoriteUpdate();
    } else {
      const { search } = location;
      const queryObj = querystring.parse(search);
      const { viewPref } = queryObj;
      const lsItems = window.localStorage.getItem(
        `ar-grid-saved-views-space-${space?.Id}`
      );
      let gridSavedViews = JSON.parse(lsItems || "{}");
      const viewGridViews = gridSavedViews[schema.name];
      const lsValue = localStorage.getItem(`ar-color-filter-space-${space.Id}`);
      let viewLsValue = JSON.parse(lsValue || "{}");
      let parsedLsValue = viewLsValue[schema.name];
      if (!viewGridViews && !parsedLsValue) {
        if (typeof updateLsViews === "function") updateLsViews();
        return;
      }
      const index = viewGridViews.findIndex((e: any) => e.id === viewPref);
      let resolvedIndex = index === -1 ? 0 : index;
      let viewGridObject = viewGridViews[resolvedIndex] || {};
      viewGridObject.search = search;
      viewGridObject.colorFilters = { ...parsedLsValue };
      viewGridViews[resolvedIndex] = viewGridObject;
      gridSavedViews[schema.name] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
      if (typeof updateLsViews === "function") updateLsViews();
    }
  }, [
    handleFavoriteUpdate,
    location,
    result,
    schema.name,
    space.Id,
    updateLsViews
  ]);

  if (minimal) {
    return (
      <Button
        vType="link-primary"
        {...rest}
        title={intl.formatMessage({ id: "SAVE_VIEW" })}
        onClick={handleClick}
      >
        <div className="d-flex align-items-center px-2 justify-content-center">
          <FontAwesomeIcon icon={faSave} />
        </div>
      </Button>
    );
  }

  return (
    <Button vType="link-primary" {...rest} onClick={handleClick}>
      <div className="d-flex align-items-center justify-content-center">
        <FontAwesomeIcon icon={faSave} className="mr-2" />
        <FormattedMessage id="SAVE_VIEW" />
      </div>
    </Button>
  );
};

export default SaveFavoriteButton;
