import React from "react";
import DropdownInput, {
  DropdownInputContainer
} from "../Dropdown/DropdownInput";
import { useCancelationReason } from "../../Hooks/EntityHooks";

const CancellationReasonDropdownInput = props => {
  const { cancellationReason } = props;

  return (
    <DropdownInputContainer>
      <DropdownInput
        {...props}
        fallbackValue={cancellationReason && cancellationReason.Name}
      />
    </DropdownInputContainer>
  );
};

export default CancellationReasonDropdownInput;

// const mapStateToProps = (state, { cancellationReason }) => {
//   if (!cancellationReason) return {};
//   return {
//     cancellationReason:
//       state.Entities.SpaceCancellationReasons[cancellationReason]
//   };
// };

export const ConnectedCancellationReasonDropdownInput = ({ cancellationReason }) => {
  const c = useCancelationReason(cancellationReason);

  return <CancellationReasonDropdownInput cancellationReason={c} />;
};

// export const ConnectedCancellationReasonDropdownInput = connect(
//   mapStateToProps
// )(CancellationReasonDropdownInput);
