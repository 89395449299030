import React, { useMemo } from "react";

import FormInput from "../../../Components/Forms/FormInput/FormInput";

import { useIntl } from "react-intl";
import { handleError } from "../../../Helpers/MiscHelper";
// import { withSpace } from "../../../Contexts/SpaceContext";
import {
  // withSpaceEntityForm
  //   createFormRequestHook,
  useFormState
} from "../../../Components/Forms";
import {
  // SideMenuForm,
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../../Components/Forms/SideMenuForm";

import {
  OrganizationDropdown
  //   DepartmentTeamDropdown,
  //   convertDepartmentTeams,
  //   convertToDepartmentTeams
} from "../../AdvancedMultiInputs";

import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";

import { useToast } from "../../../Components/Toast/ToastProvider";
// import Switch from "../../../Components/Switch/Switch";
import { useSpaceQuery } from "../../../Helpers/IOClient";
import { spaceChecklistTemplatesSchema } from "../../../config/schema";
import { useChecklistTemplate } from "../../../Hooks/EntityHooks";
import Checklist from "../../../Components/Checklist/Checklist";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  useSidebarSpacePost,
  BaseSidebarContent,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";


// const useCancelRequest = createFormRequestHook({
//   schema: spaceCancelationReasonSchema,
//   dependencies: []
// });

const ChecklistTemplatenForm = (props) => {
  const { id, onSuccess } = props;

  const endpoint = useMemo(() => {
    if (!id) return null;
    return `Settings/Checklist/${id}`;
  }, [id]);

  const {
    loading,
    error
    // data
  } = useSpaceQuery(endpoint, spaceChecklistTemplatesSchema);

  const cancel = useChecklistTemplate(id);

  const canEdit = cancel ? cancel.CanEdit : true;
  const intl = useIntl();
  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        {id
          ? `${intl.formatMessage({ id: "EDIT_CHECKLIST_TEMPLATE" })}`
          : intl.formatMessage({ id: "ADD_CHECKLIST_TEMPLATE" })}
      </BaseSidebarHeader>
      <BaseSidebarContent>
        {loading || error ? (
          <LoaderSpinner center size="sm" className="text-secondary" />
        ) : (
          <ChecklistForm
						onSuccess={onSuccess}
						canDelete={cancel?.CanDelete}
            id={id}
            canEdit={canEdit}
            convertedForm={cancel}
            {...props}
          />
        )}
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );

  // if (loading || error)
  //   return <LoaderSpinner center size="sm" className="text-secondary" />;

  // return (
  //   <ChecklistForm
  //     onSuccess={onSuccess}
  //     id={id}
  //     canEdit={canEdit}
  //     convertedForm={cancel}
  //     {...props}
  //   />
  // );
};

const defaultTemplateForm = () => {
  return {
    Name: "",
    Organizations: null,
    ToDos: []
  };
};

const ChecklistForm = ({
  convertedForm,
  id,
  canEdit,
	onSuccess,
	canDelete,
  isChild,
  refetchRef,
  ...props
}) => {
  const formState = useFormState(() => {
    return {
      ...defaultTemplateForm(),
      ...convertedForm
    };
  });
  const { form } = formState;
  const { Name, ToDos } = form;

  const createToast = useToast();
  const intl = useIntl();
  const [post, { loading }] = useSidebarSpacePost(
    id ? `Settings/Checklist/${id}` : `Settings/Checklist`,
    spaceChecklistTemplatesSchema,
    {
      refetchRef,
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
					title: `${intl.formatMessage({ id: "CHECKLIST_TEMPLATE" })} ${Name}`,
          description: intl.formatMessage({ id: "SUCCESSFULLY_RECORDED" }),
        });
        onSuccess && onSuccess();
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  const resolvedTodos = useMemo(() => {
    const data = ToDos;
    const returnData = (e) => {
      return {
        title: e.Name || e.title,
        Name: e.Name || e.title,
        children: e.children || [],
        ParentId: e.ParentId,
        Id: e.Id,
        expanded: true,
        ...e
      };
    };

    let initialData = data.filter((e) => !e.ParentId).map((e) => returnData(e));

    const buildObject = (arr) => {
      if (!data.some((e) => e.ParentId)) return [];

      arr.forEach((item) => {
        item.children = data
          .filter((e) => e.ParentId === item.Id)
          .map((e) => returnData(e));

        buildObject(item.children);
      });
      return arr;
    };

    //   const getFlatList = (arr, current) => {
    // 	current.forEach(item => {
    // 	  arr.push({ name: item.title, Id: item.Id, parentId: item.parentId });
    // 	  if (item.children) {
    // 		getFlatList(arr, item.children);
    // 	  }
    // 	});
    // 	return arr;
    //   };

    buildObject(initialData);
    return initialData;
  }, [ToDos]);

  const handleSubmit = (form) => {
    post(formatChecklistTemplateForm(form));
  };

  const validateCancelForm = ({ Name, DepartmentTeams }, addError) => {
    if (!Name) addError("Name");
    if (resolvedTodos.length === 0) addError("ToDos");
  };

  const formatChecklistTemplateForm = (form) => {
    const { Name, Companies, ToDos, ...restForm } = form;
    return {
      ...restForm,
      Name,
      Companies,
      ToDos: resolvedTodos
    };
  };
	const handleTranslatedError = useHandleError
	(spaceChecklistTemplatesSchema);

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `Settings/Checklist/${id}`,
    spaceChecklistTemplatesSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "CHECKLIST_TEMPLATE" })} ${Name}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
			},
			refetchRef
    }
  );

  return (
    <SideForm
      className="flex-1 of-y-auto d-flex flex-column"
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateCancelForm}
    >
      <SideFormContent className="p-4">
        <FormInput name="Name" value={Name} textId="NAME" className="mb-3" />

        <FormInput
          name="Companies"
          className="mb-3"
          textId="COMPANY"
          inputType={OrganizationDropdown}
          multiple
        />

        <FormInput
          nodeWidth={350}
          name="ToDos"
          className="mb-3"
          value={resolvedTodos}
          textId="CHECKLIST"
          inputType={Checklist}
        />
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        deleteLoading={deleteLoading}
        handleDelete={remove}
        isLoading={loading}
      />
    </SideForm>
  );
};

export default ChecklistTemplatenForm;
