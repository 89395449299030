import React, { useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-duotone-svg-icons";
import { NotificationContext } from "./NotificationProvider";
import "./Notifications.css";
import { ObfuscateProvider } from "../Space/Space2";
import classnames from "classnames";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { useIntl } from "react-intl";

const NotificationsBell = ({
  className,
  buttonClass,
  size,
  vertical,
  labelText,
  disableCount
}) => {
  const intl = useIntl();
  const { openNotificationSidebar, notificationsCount } =
    useContext(NotificationContext);
  const { Notifications } = useCurrentAccount();
  const hasMany = notificationsCount > 99;
  const resolvedNotificationsCount = notificationsCount
    ? hasMany
      ? "99+"
      : notificationsCount
    : null;

  const ObfuscateState = useContext(ObfuscateProvider);
  const handleClick = (e) => {
    if (ObfuscateState === true) e.preventDefault();
    else {
      openNotificationSidebar();
    }
  };

  return (
    <div
      style={{
        opacity: ObfuscateState === true && 0.3,
        pointerEvents: ObfuscateState === true && "none"
      }}
      className={className || "cursor-pointer ml-1"}
    >
      <div
        onClick={handleClick}
        // className={`mr-0 ar-header-rounded-icon BellButton position-relative cursor-pointer d-flex align-items-center justify-content-center`}
        className={classnames(
          "ar-header-rounded-icon position-relative d-flex align-items-center flex-0-0-auto",
          className,
          buttonClass
        )}
      >
        {(Notifications.Desktop.General & 16) === 16 &&
          vertical &&
          notificationsCount > 0 && (
            <div
              // style={{ left: 34, top: 4, width: 10, height: 10, outline: "none", background: "#FF8066" }}
              className={classnames("unread-count newDesign position-absolute")}
            >
              {/* <span>{resolvedNotificationsCount}</span> */}
            </div>
          )}

        <FontAwesomeIcon icon={faBell} size="lg" />
        {labelText && (
          <span style={{ fontWeight: 500 }} className="ml-12">
            {labelText}
          </span>
        )}
        {!disableCount &&
          (Notifications.Desktop.General & 16) === 16 &&
          notificationsCount > 0 && (
            <div
              style={vertical ? { marginRight: "6px" } : undefined}
              className={classnames("d-flex flex-1", {
                "ml-2 justify-content-end": vertical
              })}
            >
              <div
                title={intl.formatMessage({ id: "NOT_READ" })}
                className="ssi-unread-count-pill"
              >
                {resolvedNotificationsCount}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default NotificationsBell;

// class Bell extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }
//   render() {
//     const intl = this.props.intl;
//     this.click = () => {
//       this.props.openSidebar(
//         <Notifications />,
//         intl.formatMessage({ id: "notifications" })
//       );
//     };
//     return (
//       <button
//         onClick={this.click}
//         className={`BellButton ${
//           this.props.className ? this.props.className : ""
//         }`}
//       >
//         <FontAwesomeIcon icon={faBell} />
//       </button>
//     );
//   }
// }

// const mapDispatchToProps = dispatch => {
//   return {
//     openSidebar: (component, title) => {
//       dispatch(OpenSidebar(component, title));
//     }
//   };
// };

// export default connect(
//   null,
//   mapDispatchToProps
// )(injectIntl(Bell));
