import React from "react";
import {
  GeneralNotificationItem,
  buildNotificationEndpoint,
  NotificationClientProfile
} from "./NotificationHelper";
import { FormattedMessage } from "react-intl";
import TaskOrigin from "../../../Origin/TaskOrigin";
import TicketOrigin from "../../../Origin/TicketOrigin";
import ContractOrigin from "../../../Origin/ContractOrigin";
import ClientOrigin from "../../../Origin/ClientOrigin";
import DealOrigin from "../../../Origin/DealOrigin";
import ProjectOrigin from "../../../Origin/ProjectOrigin";
import CallOrigin from "../../../Origin/CallOrigin";
import SubscriptionOrigin from "../../../Origin/SubscriptionOrigin";
import ContactOrigin from "../../../Origin/ContactOrigin";
import InterventionOrigin from "../../../Origin/InterventionOrigin";
import { moduleTypes } from "../../../../Helpers/ModulesHelper";
import { MessageItemProfileStatus } from "../../../Reception/ReceptionCompMsgList";
import { notificationTypes } from "../../NotificationsHelper";

const NotificationEditProject = ({ project, notf }) => {
  const resolvedProject = project;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;
  const { Client, IndirectClient } = resolvedProject;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.projects,
        Id: project.Id
      }}
      OriginComponent={<ProjectOrigin project={project} />}
      redirectUrl={buildNotificationEndpoint(
        "projects",
        "Project/Id",
        project.Id
      )}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"PROJECT_UPDATE"} />{" "}
          <b>{resolvedProject.Name}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationEditCall = ({ call, notf }) => {
  const resolvedCall = call;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;
  const { Client, IndirectClient } = resolvedCall;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.calls,
        Id: call.Id
      }}
      redirectUrl={buildNotificationEndpoint("calls", "Project/Id", call.Id)}
      OriginComponent={<CallOrigin call={call} />}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"CALL_UPDATE"} />{" "}
          <b>{resolvedCall.NoTagDescription}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationEditEmailConversation = ({ emailConversation, notf }) => {
  const { FromAccount, Type } = notf;
  const { Name: FromName } = FromAccount;
  const { Client, Subject, Status, Id } = emailConversation;

  return (
    <GeneralNotificationItem
      redirectUrl={buildNotificationEndpoint(
        "inbox/1",
        null,
        Id,
        `conversationTypes=1&entityNoteType=0`
      )}
      OriginComponent={
        <div className="d-flex align-items-center of-hidden ">
          <MessageItemProfileStatus
            size="xs"
            style={{ width: 24, height: 24 }}
            Status={Status}
          />
          <div className="flex-1 text-truncate ml-2 disable-selection fs-12 text-black">
            {Subject}
          </div>
        </div>
      }
      // OriginComponent={<CallOrigin call={call} />}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"EMAIL_CONVERSATION_UPDATE"} /> <b>{Subject}</b>
          <br />
          <NotificationClientProfile client={Client} />
        </div>
      }
    />
  );
};

const NotificationEditTask = ({ task, notf }) => {
  const resolvedTask = task;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;
  const { Client, IndirectClient } = resolvedTask;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.tasks,
        Id: task.Id
      }}
      redirectUrl={buildNotificationEndpoint("tasks", "Task/Id", task.Id)}
      OriginComponent={<TaskOrigin task={task} />}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"TASK_UPDATE"} /> <b>{resolvedTask.Name}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationEditTicket = ({ ticket, notf }) => {
  const resolvedTicket = ticket;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;
  const { Client, IndirectClient } = resolvedTicket;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.tickets,
        Id: ticket.Id
      }}
      redirectUrl={buildNotificationEndpoint(
        "tickets",
        "Project/Id",
        ticket.Id
      )}
      OriginComponent={<TicketOrigin ticket={ticket} />}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"CASE_UPDATE"} /> <b>{resolvedTicket.Name}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

// eslint-disable-next-line no-unused-vars
const NotificationEditIntervention = ({ intervention, notf }) => {
  const resolvedIntervention = intervention;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;
  const { Client, IndirectClient } = resolvedIntervention;
  return (
    <GeneralNotificationItem
      redirectUrl={buildNotificationEndpoint(
        "times",
        "Intervention/Id",
        intervention.Id
      )}
      OriginComponent={<InterventionOrigin intervention={intervention} />}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"TIME_UPDATE"} />{" "}
          <b>{resolvedIntervention.Id}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationEditContract = ({ contract, notf }) => {
  const resolvedContract = contract;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;
  const { Client, IndirectClient } = resolvedContract;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.contracts,
        Id: contract.Id
      }}
      OriginComponent={<ContractOrigin contract={contract} />}
      redirectUrl={buildNotificationEndpoint(
        "contracts",
        "Contract/Id",
        contract.Id
      )}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"CONTRACT_UPDATE"} />{" "}
          <b>{resolvedContract.NoTagDescription}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationEditClient = ({ client, notf }) => {
  const resolvedClient = client;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.clients,
        Id: client.Id
      }}
      OriginComponent={<ClientOrigin client={client} />}
      redirectUrl={buildNotificationEndpoint("customers", "Id", client.Id)}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"ACCOUNT_UPDATED"} />{" "}
          <b>{resolvedClient.Name}</b>
        </div>
      }
    />
  );
};
const NotificationEditSubscription = ({ subscription, notf }) => {
  const resolvedSub = subscription;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.subscriptions,
        Id: subscription.Id
      }}
      OriginComponent={<SubscriptionOrigin Subscription={subscription} />}
      redirectUrl={buildNotificationEndpoint(
        "subscriptions",
        "Subscription/Id",
        subscription.Id
      )}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"SUBSCRIPTION_UPDATED"} />{" "}
          <b>{resolvedSub.NoTagDescription}</b>
        </div>
      }
    />
  );
};
const NotificationEditTimecharge = ({ intervention, notf }) => {
  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.interventions,
        Id: intervention.Id
      }}
      OriginComponent={<InterventionOrigin intervention={intervention} />}
      redirectUrl={buildNotificationEndpoint(
        "interventions",
        "TimeCharge/Id",
        intervention.Id
      )}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"TIMECHARGE_UPDATED"} />{" "}
          <b>{intervention.NoTagDescription}</b>
        </div>
      }
    />
  );
};
const NotificationEditContact = ({ contact, notf }) => {
  const resolvedClient = contact;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.contacts,
        Id: contact.Id
      }}
      OriginComponent={<ContactOrigin contact={contact} />}
      redirectUrl={buildNotificationEndpoint("contacts", "Id", contact.Id)}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"CONTACT_UPDATED"} />{" "}
          <b>{resolvedClient.Name}</b>
        </div>
      }
    />
  );
};

const NotificationEditDeal = ({ deal, notf }) => {
  const resolvedDeal = deal;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;
  const { Client, IndirectClient } = resolvedDeal;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.deals,
        Id: deal.Id
      }}
      redirectUrl={buildNotificationEndpoint("deals", "Deal/Id", deal.Id)}
      OriginComponent={<DealOrigin deal={deal} />}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"DEAL_UPDATED"} /> <b>{resolvedDeal.Name}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationEditItem = ({ value }) => {
  const {
    Project,
    Call,
    Task,
    Ticket,
    Contract,
    Client,
    Deal,
    Contact,
    Intervention,
    TimeCharge,
    EmailConversation,
    Subscription
  } = value;

  if (Project)
    return <NotificationEditProject notf={value} project={Project} />;
  else if (Call) return <NotificationEditCall notf={value} call={Call} />;
  else if (EmailConversation)
    return (
      <NotificationEditEmailConversation
        notf={value}
        emailConversation={EmailConversation}
      />
    );
  else if (Intervention || TimeCharge)
    return (
      <NotificationEditTimecharge
        notf={value}
        intervention={Intervention || TimeCharge}
      />
    );
  else if (Task) return <NotificationEditTask notf={value} task={Task} />;
  else if (Ticket)
    return <NotificationEditTicket notf={value} ticket={Ticket} />;
  else if (Contract)
    return <NotificationEditContract notf={value} contract={Contract} />;
  else if (Client)
    return <NotificationEditClient notf={value} client={Client} />;
  else if (Contact)
    return <NotificationEditContact notf={value} contact={Contact} />;
  else if (Subscription)
    return (
      <NotificationEditSubscription notf={value} subscription={Subscription} />
    );
  else if (Deal) return <NotificationEditDeal notf={value} deal={Deal} />;
  else return null;
};

export default NotificationEditItem;
