import React, { useState } from "react";
import SplitCol from "../../../Components/SplitCol/SplitCol";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import PhoneInput from "../../../Components/Input/PhoneInput";
import {
  canSubmitPhoneNumber,
  handleError,
  hasPhoneNumberError,
  isEmail
} from "../../../Helpers/MiscHelper";
import { withSpace } from "../../../Contexts/SpaceContext";
import withSpaceEntityForm from "../../../Components/Forms";
import SideMenuForm from "../../../Components/Forms/SideMenuForm";
import {
  ContactMediumTypes,
  spaceDepartmentSchema
} from "../../../config/schema";

import { UserDropdown, OrganizationDropdown } from "../../AdvancedMultiInputs";
import Button from "../../../Components/Button/Button";
import LoadableButton from "../../../Components/Button/LoadableButton";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";

const DepartmentForm = ({ setFormState, refetchRef, ...props }) => {
  const { formStatus } = props;
  const { form } = formStatus;
  const { Id, Collaborators, Managers, CanDelete } = form;
  const [isDeleting, setIsDeleting] = useState(false);

  const handleManagerChange = (e) => {
    const { value, name } = e.target;

    const resolvedValue = Collaborators.filter((f) => !value.includes(f));

    setFormState({
      [name]: value,
      Collaborators: resolvedValue
    });
  };

  const handleColaboratorChange = (e) => {
    const { value, name } = e.target;
    const resolvedValue = Managers.filter((f) => !value.includes(f));
    setFormState({
      [name]: value,
      Managers: resolvedValue
    });
  };

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"DEPARTMENT"} />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideMenuForm
          formStatus={formStatus}
          footerComponent={
            !Id ? (
              <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
                <div></div>
                <LoadableButton
                  isLoading={formStatus.isProcessing}
                  onClick={formStatus.submit}
                >
                  <FormattedMessage id={"CONFIRM"} />
                </LoadableButton>
              </div>
            ) : !isDeleting ? (
              <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
                {CanDelete ? (
                  <Button
                    vType="link-danger fw-bold"
                    className="mr-3"
                    onClick={() => setIsDeleting(true)}
                  >
                    <span style={{ fontWeight: 500 }}>
                      <FormattedMessage id="DELETE" />
                    </span>
                  </Button>
                ) : (
                  <div></div>
                )}
                <LoadableButton
                  isLoading={formStatus.isProcessing}
                  onClick={formStatus.submit}
                >
                  <FormattedMessage id={"CONFIRM"} />
                </LoadableButton>
              </div>
            ) : (
              CanDelete && (
                <RemovalFooter
                  endpoint={`departments/${Id}`}
                  refetchRef={refetchRef}
                  onCancel={() => setIsDeleting(false)}
                />
              )
            )
          }
        >
          <FormInput
            name="ParentOrganization"
            textId="COMPANY"
            inputType={OrganizationDropdown}
            className="mb-3"
          />

          <FormInput name="Name" textId="NAME" className="mb-3" />

          <SplitCol
            left={<FormInput name="Email" textId="EMAIL" className="mb-3" />}
            right={
              <FormInput
                name="PhoneNumber"
                textId="PHONE_NUMBER"
                inputType={PhoneInput}
                className="mb-3"
              />
            }
          />

          <FormInput
            name="Managers"
            className="mb-3"
            textId="MANAGER"
            inputType={UserDropdown}
            multiple
            onChange={handleManagerChange}
          />

          <FormInput
            name="Collaborators"
            textId="COLLABORATORS"
            inputType={UserDropdown}
            onChange={handleColaboratorChange}
            multiple
            // onChange={this.handleCollaboratorsChange}
          />
        </SideMenuForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export default withSpaceEntityForm(withSpace(injectIntl(DepartmentForm)), {
  defaultForm: {
    Name: "",
    ParentOrganization: null,
    Managers: [],
    Collaborators: [],
    Description: "",
    Email: "",
    PhoneNumber: ""
  },
  onSuccess: ({ createToast }) => {
    createToast({
      pos: "tm",
      type: "success",
      description: <FormattedMessage id="DEPARTMENT_SAVE_SUCCESS" />
    });
  },
  onError: ({ error, createToast }) => {
    try {
      const parsedError = JSON.parse(error.data);
      handleError(createToast, parsedError);
    } catch (e) {
      handleError(createToast, error);
    }
  },
  validateForm: (form, addError) => {
    const { ParentOrganization, Managers, Email, PhoneNumber, Name } = form;

    if (!ParentOrganization) addError("ParentOrganization");
    if (!Name) addError("Name");
    if (Email && !isEmail(Email))
      addError("Email", <FormattedMessage id="EMAIL_CORRECT" />);

    if (hasPhoneNumberError(PhoneNumber))
      addError("PhoneNumber", <FormattedMessage id="INVALID_NUMBER_WARNING" />);

    if (Managers.length === 0) addError("Managers");
  },
  formatForm: (form) => {
    let { Email, PhoneNumber, ParentOrganization, ...newForm } = form;
    const ContactMediums = [];
    if (canSubmitPhoneNumber(PhoneNumber))
      ContactMediums.push({
        ContactMediumTypeId: ContactMediumTypes.PhoneNumber,
        Contact: PhoneNumber
      });

    if (isEmail(Email))
      ContactMediums.push({
        ContactMediumTypeId: ContactMediumTypes.Email,
        Contact: Email
      });

    newForm.ParentOrganizationId = ParentOrganization
      ? ParentOrganization
      : null;

    newForm.ContactMediums = ContactMediums;

    return newForm;
  },
  schema: spaceDepartmentSchema
  // getEntityId: (state, ownProps) => ownProps.departmentId,
});

const RemovalFooter = ({ endpoint, refetchRef, onCancel }) => {
  const createToast = useToast();
  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceDepartmentSchema);
  const [deleteUser, { loading }] = useSidebarSpaceDelete(
    endpoint,
    spaceDepartmentSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "DEPARTMENT" })}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  return (
    <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
      <div className="text-black">
        <FormattedMessage id="ELIMINATE_DEPARTMENT_CONFIRMATION" />
      </div>

      <div className="d-flex align-items-center">
        <Button
          type="button"
          vType="outline-danger"
          className="mr-3"
          onClick={onCancel}
        >
          <FormattedMessage id="BACK" />
        </Button>

        <LoadableButton
          type="button"
          vType="danger"
          isLoading={loading}
          onClick={() => {
            deleteUser();
          }}
        >
          <FormattedMessage id="DELETE" />
        </LoadableButton>
      </div>
    </div>
  );
};
