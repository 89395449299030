import React, { Component } from "react";
import "./DatePicker.css";
import "../TimePicker/TimePicker.css";
import { msToTime } from "../../Assets/Utils";
import Popup from "../Popup/Popup";
import { isDate, DoubleDigitFormat } from "../../Assets/Utils";
import classnames from "classnames";
import { subscribeUniqueEvent } from "../../Helpers/ComponentsHelper";
import { DatePickerComponent } from "./DatePickerComponent";
import { DatePickerW } from "./DatePickerW";
import moment from "moment";
// import React, { useState, useRef, useLayoutEffect } from "react";
// import { msToTime } from "../../Assets/Utils";
// import classnames from "classnames";
// import "./TimePicker.css";
// import { HourToMilSec, MinuteToMilSec } from "../../Helpers/MiscHelper";
// import Button from "../Button/Button";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faClock } from "@fortawesome/pro-regular-svg-icons";

/**
 * @param {string} className - Custom class name for the container
 * @param {component} [component = DatePickerComponent] - Custom input/buttons template
 * @param {string} [hourFormat = "24h"] - Hour format (24h or 12h)
 * @param {bool} [closeOnSelect = true] - Closes popup when selecting a value
 * @param {bool} [disablePastDays = false] - Disable days before today's date
 * @param {bool} [disableFutureDays = false] - Disable days after today's date
 * @param {bool} [disableWeekends = false] - Disable weekends days
 * @param {Date} disabledDates - Array of dates to disable
 * @param {string} [locale = "en"] - Locale language for the widget
 * @param {String[]} [weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]] - Names of the days of the week
 * @param {String[]} [months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]] - Names of the months
 * @param {bool} [openDateAfterHour = false] - Opens datepicker after selecting an hour
 * @param {bool} [openHourAfterDate = false] - Opens hourpicker after selecting a day
 * @param {bool} [enableHours = true] - Enables hour picker
 * @param {bool} [enableDate = true] - Enables date picker
 * @param {bool} onChange - Callback when date or hour is changed
 * @param {bool} preview - Enables read only of value
 */
// @param {string} [position = "right"] - Popup start position

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.HourItems = [];
    this.MinuteItems = [];
    // for (let i = 23; i >= 0; i--) {
    //   this.HourItems.push(DoubleDigitFormat(i));
    // }
    // for (let i = 59; i >= 0; i--) {
    //   this.MinuteItems.push(DoubleDigitFormat(i));
    // }
    for (let i = 0; i <= 23; i++) {
      this.HourItems.push(DoubleDigitFormat(i));
    }
    for (let i = 0; i <= 59; i++) {
      this.MinuteItems.push(DoubleDigitFormat(i));
    }

    this.state = {
      Hours: null,
      Minutes: null,
      Date: {},
      //Date: {
      //   Day: 1,
      //   Month: 12,
      //   Year: 2018
      // }
      timestamp: null,
      HourFormat: this.props.hourFormat === "12h" ? "12h" : "24h",
      OpenDatePicker: false,
      OpenHourPicker: false
    };
    this.hourPickerButtonRef = React.createRef();
    this.datePickerButtonRef = React.createRef();

    this.HourPicker = React.createRef();
    this.DatePickerPopupRef = React.createRef();
    this.DatePicker = React.createRef();
    this.Parent = React.createRef();
    this.propsComponentRef = React.createRef();
    this.TimeInputRef = React.createRef();
    // this.hourItems = [];
    // if (this.state.HourFormat === "12h") {
    //   let hour = 0;
    //   let minute = 0;
    //   let ftime = "AM";

    //   for (let i = 0; i < 96; i++) {
    //     this.hourItems.push({
    //       Name:
    //         DoubleDigitFormat(hour) +
    //         ":" +
    //         DoubleDigitFormat(minute) +
    //         " " +
    //         ftime
    //     });

    //     if (hour === 11 && minute === 45) {
    //       hour = -1;
    //       ftime = "PM";
    //     }

    //     minute += 15;

    //     if (minute == 60) {
    //       minute = 0;
    //       hour++;
    //     }
    //   }
    // } else if (this.state.HourFormat === "24h") {
    //   let hour = 0;
    //   let minute = 0;

    //   for (let i = 0; i < 96; i++) {
    //     this.hourItems.push({
    //       Name: DoubleDigitFormat(hour) + ":" + DoubleDigitFormat(minute)
    //     });

    //     minute += 15;

    //     if (minute == 60) {
    //       minute = 0;
    //       hour++;
    //     }
    //   }
    // }

    if (this.props.locale === "pt") {
      this.weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
      this.months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ];
    } else if (this.props.locale === "es") {
      this.weekDays = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sab"];
      this.months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ];
    } else {
      this.weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      this.months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
    }

    if (this.props.weekDays !== undefined) {
      this.weekDays = this.props.weekDays;
    }
    if (this.props.months !== undefined) {
      this.months = this.props.months;
    }
  }

  // static getDerivedStateFromProps(nextProps, state) {
  //   const { value } = nextProps;

  //   if (!value || state.timestamp === value) return null;

  //   let Day = value.getDate();
  //   let Month = value.getMonth() + 1;
  //   let Year = value.getFullYear();

  //   let Minutes = value.getMinutes();

  //   if (Minutes < 10) Minutes = `0${Minutes}`;

  //   let Hours = value.getHours();
  //   if (Hours < 10) Hours = `0${Hours}`;

  //   return {
  //     Date: { Day, Month, Year },
  //     Hour:
  //       Minutes !== 0 || Hours !== 0
  //         ? {
  //             Name: `${Hours}/${Minutes}`
  //           }
  //         : state.Hour,
  //     timestamp: value
  //   };
  // }

  static getDerivedStateFromProps(props, state) {
    const { value: dateValue } = props;

    let value;
    if (typeof dateValue === "string") {
      const matchTime =
        dateValue.match(/^[\d]{1,2}:[\d]{1,2}([:][\d]{1,2}){0,1}$/g) || [];
      if (matchTime.length === 1) {
        const todayDate = new Date();
        const numsMatch = matchTime[0].match(/[\d]{1,2}/g);
        const hrs = numsMatch[0];
        const mins = numsMatch[1];
        const secs = numsMatch.length === 3 ? numsMatch[2] : "00";
        value = new Date(
          todayDate.getMonth() +
            1 +
            "/" +
            todayDate.getDate() +
            "/" +
            todayDate.getFullYear() +
            " " +
            hrs +
            ":" +
            mins +
            ":" +
            secs +
            ".0"
        );
      } else if (moment(dateValue, true).isValid()) {
        value = new Date(dateValue);
      } else {
        value = new Date();
      }
    } else if (typeof dateValue == "number") {
      if (dateValue < 86400000) {
        value = new Date(`01/01/1970 ${msToTime(dateValue)}`);
      } else {
        value = new Date(dateValue);
      }
    } else {
      value = dateValue;
    }

    if (value === null) {
      return { Date: {}, Hours: null, Minutes: null, timestamp: null };
    } else if (value && isDate(value)) {
      if (
        isDate(state.timestamp) &&
        value.getTime() === state.timestamp.getTime()
      )
        return null;

      const _Date = {
        Day: value.getDate(),
        Month: value.getMonth() + 1,
        Year: value.getFullYear()
      };

      const hrs = value.getHours();
      const mins = value.getMinutes();

      const hours = hrs !== null && hrs !== undefined ? hrs : state.Hours;
      const minutes =
        mins !== null && mins !== undefined ? mins : state.Minutes;
      // const Hour = {
      //   Name:
      //     (hours < 10 ? `0${hours}` : hours) +
      //     ":" +
      //     (minutes < 10 ? `0${minutes}` : minutes)
      // };
      return { Date: _Date, Hours: hours, Minutes: minutes, timestamp: value };
    }

    return null;
  }

  OpenDatePicker = () => {
    if (this.props.enableDate === false) return;
    this.setState({ OpenDatePicker: true, OpenHourPicker: false }, () => {
      document.addEventListener("click", this.CloseDatePicker);
      this.unsubscribeDatePicker = subscribeUniqueEvent(
        this.UniqueCloseDatePicker
      );
    });
  };

  UniqueCloseDatePicker = (event) => {
    if (event.key === "Escape") {
      this.setState({ OpenDatePicker: false });
      this.unsubscribeDatePicker();
      document.removeEventListener("click", this.CloseDatePicker);
    }
  };

  CloseDatePicker = (event) => {
    if (this.props.fullScreenDatePicker) return;
    if (this.props.enableDate === false) return;
    if (this.Parent.current == null) return;
    if (this.propsComponentRef.current == null) return;

    // //;
    if (
      event.key === "Escape" ||
      (!this.Parent.current.contains(event.target) &&
        !this.propsComponentRef.current.contains(event.target))
    ) {
      this.setState({ OpenDatePicker: false }, () => {
        this.unsubscribeDatePicker();
        document.removeEventListener("click", this.CloseDatePicker);
      });
    }
  };

  OpenHourPicker = () => {
    if (this.props.enableHours === false) return;
    this.setState({ OpenHourPicker: true, OpenDatePicker: false }, () => {
      this.unsubscribeHourPicker = subscribeUniqueEvent(this.CloseHourPicker);
      document.addEventListener("click", this.CloseHourPicker);
    });
  };
  CloseHourPicker = (event) => {
    if (this.props.enableHours === false) return;
    if (this.HourPicker.current == null) return;
    if (this.propsComponentRef.current == null) return;

    if (
      event.key === "Escape" ||
      (!this.HourPicker.current.contains(event.target) &&
        !this.propsComponentRef.current.contains(event.target))
    ) {
      this.setState({ OpenHourPicker: false }, () => {
        this.unsubscribeHourPicker();
        document.removeEventListener("click", this.CloseHourPicker);
      });
    }
  };

  forceClose = () => {
    if (this.props.fullScreenDatePicker) return;
    this.setState({ OpenHourPicker: false, OpenDatePicker: false }, () => {
      document.removeEventListener("click", this.CloseHourPicker);
      document.removeEventListener("click", this.CloseDatePicker);
    });
  };

  closeHourPickerOpenDatePicker = () => {
    this.setState({ OpenHourPicker: false, OpenDatePicker: true }, () => {
      document.removeEventListener("click", this.CloseHourPicker);
    });
  };

  closeDatePickerOpenHourPicker = () => {
    if (this.props.fullScreenDatePicker) return;
    this.setState({ OpenHourPicker: true, OpenDatePicker: false }, () => {
      document.removeEventListener("click", this.CloseDatePicker);
    });
  };

  itemClick = (item, openOther, pickerHoursAndMinutes) => {
    this.handleChange(null, item, () => {
      if (
        !item.Hours === null ||
        !item.Minutes === null ||
        pickerHoursAndMinutes !== true
      )
        return;

      if (this.props.openDateAfterHour === true && openOther !== false) {
        this.closeHourPickerOpenDatePicker();
      } else if (this.props.closeOnSelect !== false) {
        this.forceClose();
      }
    });
  };

  handleChange = (date, hour, callback) => {
    //;
    let _Date = date;

    let hourObject = hour || {};

    let _Hours =
      hourObject.Hours !== null && hourObject.Hours !== undefined
        ? hourObject.Hours
        : this.state.Hours;
    let _Minutes =
      hourObject.Minutes !== null && hourObject.Minutes !== undefined
        ? hourObject.Minutes
        : this.state.Minutes;

    if (typeof _Hours === "string") _Hours = parseInt(_Hours);
    if (typeof _Minutes === "string") _Minutes = parseInt(_Minutes);

    const minutesValueInvalid = hour && (_Hours === null || _Minutes === null);

    if (_Hours !== null && (_Minutes === null || _Minutes === undefined))
      _Minutes = 0;
    if (_Minutes !== null && (_Hours === null || _Hours === undefined))
      _Hours = 0;

    const todayDate = new Date();

    if (_Date) {
      if (!_Date.Day) _Date.Day = todayDate.getDate();
      if (!_Date.Month) _Date.Month = todayDate.getMonth() + 1;
      if (!_Date.Year) _Date.Year = todayDate.getFullYear();
    }

    const rethour =
      _Hours !== null && _Minutes !== null ? _Hours + ":" + _Minutes : "";

    const retdate =
      _Date && _Date.Day
        ? _Date.Month + "/" + _Date.Day + "/" + _Date.Year
        : todayDate.getMonth() +
          1 +
          "/" +
          todayDate.getDate() +
          "/" +
          todayDate.getFullYear();

    const currDate = new Date(retdate + " " + rethour);

    let hour_timestamp = null;

    if (_Hours !== null && _Minutes !== null) {
      // const hrs_t = _Hours === 0 ? 1 : _Hours;
      // const min_t = _Minutes === 0 ? 1 : _Minutes;

      const hours_t = 1000 * 60 * 60 * _Hours;
      const minutes_t = 1000 * 60 * _Minutes;

      hour_timestamp = hours_t + minutes_t;
    }

    if (typeof this.props.onChange === "function") {
      const value = {
        DateObject: _Date,
        Hours: _Hours,
        Minutes: _Minutes,
        Date: currDate,
        Timestamp: currDate.getTime(),
        HourTimestamp: hour_timestamp
      };
      if (this.props.enableDate === false) {
        this.props.onChange([_Hours, _Minutes]);
      } else {
        this.props.onChange(value);
      }
    }
    if (!this.props.value || minutesValueInvalid) {
      this.setState({ Date: _Date, Hours: _Hours, Minutes: _Minutes }, () => {
        if (!minutesValueInvalid) callback && callback();
      });
    } else {
      callback && callback();
    }
  };

  onChange = (date, openOther) => {
    //;
    this.handleChange(date, null, () => {
      if (this.props.openHourAfterDate === true && openOther !== false) {
        // this.closeDatePickerOpenHourPicker();
        this.unsubscribeDatePicker && this.unsubscribeDatePicker();
        this.OpenHourPicker();
      } else if (this.props.closeOnSelect !== false) {
        this.forceClose();
      }
    });
  };
  getMonthName = (month) => {
    return this.months[month - 1];
  };

  onChangeTime = (time) => {
    this.handleChange(this.state.Date, time);
  };

  render() {
    const { preview, className } = this.props;

    let classes = classnames(
      {
        "DatePicker-FullContainer ssi-control": !preview,
        "fw-14 text-black": preview
      },
      className,
      this.props.size
    );

    // if (this.props.position === "right") {
    //   className += " right";
    // }

    return this.props.fullScreenDatePicker === true ? (
      <DatePickerW
        ref={this.TimeInputRef}
        weekDays={this.weekDays}
        getMonthName={this.getMonthName}
        selectedDate={this.state.Date}
        change={this.onChange}
        disabledDates={this.props.disabledDates}
        disableWeekends={this.props.disableWeekends}
        disableFutureDays={this.props.disableFutureDays}
        disablePastDays={this.props.disablePastDays}
      />
    ) : (
      <div ref={this.propsComponentRef} className={classes}>
        {this.props.component === undefined ? (
          <DatePickerComponent
            HideYearInput={this.props.HideYearInput}
            ref={this.TimeInputRef}
            preview={preview}
            enableHours={this.props.enableHours}
            enableDate={this.props.enableDate}
            Hours={this.state.Hours}
            Minutes={this.state.Minutes}
            date={this.state.Date}
            hourPickerButtonRef={this.hourPickerButtonRef}
            datePickerButtonRef={this.datePickerButtonRef}
            DataPopupStatus={this.state.OpenDatePicker}
            openDatePicker={this.OpenDatePicker}
            openHourPicker={this.OpenHourPicker}
            closeHourPicker={this.forceClose}
            CloseDatePicker={this.forceClose}
            disableFutureDays={this.props.disableFutureDays}
            itemClick={this.itemClick}
            forceCloseDatePicker={this.forceClose}
            onChange={this.onChange}
            onChangeTime={this.onChangeTime}
            disablePastDate={this.props.disablePastDate}
            disableFutureDate={this.props.disableFutureDate}
            disableDates={this.props.disableDates}
          />
        ) : (
          this.props.component({
            Hour: this.state.Hour,
            Date: this.state.Date,
            OpenDatePicker: this.OpenDatePicker,
            OpenHourPicker: this.OpenHourPicker,
            disablePastDate: this.props.disablePastDate,
            disableFutureDate: this.props.disableFutureDate,
            disableDates: this.props.disableDates
          })
        )}
        <Popup
          anchorEl={
            // this.TimeInputRef
            this.props.component
              ? this.propsComponentRef.current
              : this.datePickerButtonRef.current
          }
          modifiers={{
            preventOverflow: { boundariesElement: "viewport" }
          }}
          isOpen={this.state.OpenDatePicker}
          placement={this.props.placement || "auto"}
        >
          <div className="DatePicker-Container" ref={this.Parent}>
            {this.props.enableDate !== false && (
              <DatePickerW
                weekDays={this.weekDays}
                getMonthName={this.getMonthName}
                selectedDate={this.state.Date}
                change={this.onChange}
                disabledDates={this.props.disabledDates}
                disableWeekends={this.props.disableWeekends}
                disableFutureDays={this.props.disableFutureDays}
                disablePastDays={this.props.disablePastDays}
                disablePastDate={this.props.disablePastDate}
                disableFutureDate={this.props.disableFutureDate}
                disableDates={this.props.disableDates}
              />
            )}
          </div>
        </Popup>
      </div>
    );
  }
}

export default DatePicker;
