import Tree, {
  moveItemOnTree,
  mutateTree,
  TreeDestinationPosition,
  TreeSourcePosition
} from "@atlaskit/tree";
import classes from "./AppBar.module.css";
// import { TreeDraggableProvided } from "@atlaskit/tree/dist/types/components/TreeItem/TreeItem-types";
import React, {
  Dispatch,
  FC,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TreeDraggableProvided } from "@atlaskit/tree/dist/types/components/TreeItem/TreeItem-types";
import { AppFavoriteContext } from "./AppFavoriteProvider";
import Button from "../../Components/Button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import { v4 as uuidv1 } from "uuid";
import Popup from "../../Components/Popup/Popup";
import {
  AppDraggingFavoriteContext,
  AppTreeChildFavoriteCreatorContext,
  AppTreeChildFolderCreatorContext,
  AppTreeSetValueContext,
  AppTreeValueContext,
  EntiyTypeEnumAppFavorites,
  LinkTypesEnumAppFavorites,
  ResetViewContext,
  TreeData,
  ViewTypeEnumAppFavorites
} from "./AppBarHelper";
import Modal from "../../Components/Modal/Modal";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
import { useSpace } from "../../Contexts/SpaceContext";
import { usePopupOpenState } from "../../Components/Popup/Popup";
import {
  faChevronDown,
  faClone,
  faEdit,
  faEllipsisV,
  faFolderPlus,
  faPlus,
  faTrashAlt
} from "@fortawesome/pro-light-svg-icons";
import LabeledInput from "../../Components/Input/LabeledInput";
import FavoriteIconPicker from "../../Components/FavoriteIconPicker/FavoriteIconPicker";

type TreeItemData = {
  isFolder: boolean;
  title: string;
  viewType: ViewTypeEnumAppFavorites;
  iconText: string;
  search: string;
  linkType: LinkTypesEnumAppFavorites;
  itemId: number;
  entity: EntiyTypeEnumAppFavorites;
  filterSideToggleStatus: boolean;
  background: string;
  columnSizes: Record<string, number>;
};

enum FavoriteCreatorTypes {
  folder = "folder",
  favorite = "favorite"
}

type ItemId = string | number;

const ModalFavoriteEditContext = React.createContext<any>(null);
export interface TreeItem {
  id: ItemId;
  children: ItemId[];
  hasChildren?: boolean;
  isExpanded?: boolean;
  isChildrenLoading?: boolean;
  data?: TreeItemData;
}

type RenderItemParams = {
  item: TreeItem;
  depth: number;
  onExpand: (itemId: ItemId) => void;
  onCollapse: (itemId: ItemId) => void;
  provided: TreeDraggableProvided;
  snapshot: any;
  isActive?: boolean;
};

interface IFavoriteItemProps {
  item: TreeItem;
  isChild?: boolean;
  onExpand: (itemId: ItemId) => void;
  onCollapse: (itemId: ItemId) => void;
  isDragging: boolean;
  isActive?: boolean;
}
interface IViewTypeIcon {
  [key: string]: string;
}
const ViewTypeIcon: IViewTypeIcon = {
  [ViewTypeEnumAppFavorites.cards]: "fas th-large",
  [ViewTypeEnumAppFavorites.grid]: "fas list",
  [ViewTypeEnumAppFavorites.board]: "fas columns",
  [ViewTypeEnumAppFavorites.timeline]: "fas stream",
  [ViewTypeEnumAppFavorites.calendar]: "fas calendar"
};

const DetailsTypeIcon: IViewTypeIcon = {
  [EntiyTypeEnumAppFavorites.customers]: "fas suitcase",
  [EntiyTypeEnumAppFavorites.deals]: "fas handshake",
  [EntiyTypeEnumAppFavorites.contracts]: "fas file-contract",
  [EntiyTypeEnumAppFavorites.calls]: "fas phone-alt",
  [EntiyTypeEnumAppFavorites.projects]: "fas parking-circle",
  [EntiyTypeEnumAppFavorites.tickets]: "fas ticket",
  [EntiyTypeEnumAppFavorites.tasks]: "fas sticky-note",
  [EntiyTypeEnumAppFavorites.interventions]: "fas user-clock",
  [EntiyTypeEnumAppFavorites.accounts]: "fas user"
};

const RemoveFolder = (id: ItemId, items: Record<ItemId, TreeItem>) => {
  const { children } = items[id];
  delete items[id];
  for (const key in items) {
    if (Object.prototype.hasOwnProperty.call(items, key)) {
      const singleItem = items[key];
      const newItem = { ...singleItem };
      const itemIdIndex = singleItem.children.findIndex((e) => e === id);
      if (itemIdIndex !== -1) {
        const newChildren = [...singleItem.children];
        newChildren.splice(itemIdIndex, 1);
        newItem.children = newChildren;
        items[singleItem.id] = newItem;
      }
    }
  }
  if (children.length > 0)
    for (const childrenId of children) {
      RemoveFolder(childrenId, items);
    }
};

function parseQueryString(search: string) {
  // Use location.search to access query string instead
  if (search === "") {
    return {};
  }
  const qs = search.replace("?", "");

  const items = qs.split("&");

  // Consider using reduce to create the data mapping
  return items.reduce((data: Record<string, string>, item) => {
    const [rawKey, rawValue] = item.split("=");
    const key = decodeURIComponent(rawKey);
    const value = decodeURIComponent(rawValue);

    data[key] = value;

    return data;
  }, {});
}

const FavoriteItem: FunctionComponent<IFavoriteItemProps> = React.memo(
  ({ item, isChild, onCollapse, onExpand, isDragging, isActive, ...rest }) => {
    const {
      entity,
      viewType,
      iconText,
      linkType,
      itemId,
      background,
      title,
      isFolder
    } = item.data as TreeItemData;
    // const intl = useIntl();
    const setTree = useContext(AppTreeSetValueContext) as Dispatch<
      SetStateAction<TreeData>
    >;

    const text = title ? title : "";
    const isOpen = item?.isExpanded ? true : false;
    // const icon = getIcon(item, onCollapse, onExpand);
    const space = useSpace();
    const anchorRef = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);
    const [isPopupOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);

    const viewTypeIcon =
      linkType === LinkTypesEnumAppFavorites.details
        ? (DetailsTypeIcon[entity]?.split(" ") as any)
        : (ViewTypeIcon[viewType]?.split(" ") as any);

    const url = useMemo(() => {
      if (linkType === LinkTypesEnumAppFavorites.details)
        return `/s/${space.Id}/${entity}/${itemId}${
          item.data?.search ? item.data.search : `?favoriteId=${item.id}`
        }`;
      else
        return `/s/${space.Id}/${entity}${
          item.data?.search ? item.data.search : `?favoriteId=${item.id}`
        }`;
    }, [entity, item.data, item.id, itemId, linkType, space.Id]);

    // const [newName, setNewName] = useState<string | undefined>(
    //   item.data?.title
    // );

    // const handleNameChange = (e: any) => {
    //   const { value } = e.target;
    //   setNewName(value);
    // };

    const handleDuplication = () => {
      setTree((oldTree) => {
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items } as Record<ItemId, TreeItem>;
        const newRootItem = newItems[rootId] as TreeItem;
        const newRootItemChildren = newRootItem.children;
        const newId = uuidv1();
        newRootItemChildren.unshift(newId);
        newRootItem.children = newRootItemChildren;
        const newItem = { ...item };
        const newSearch = newItem.data?.search || "";
        const newParsedSearched = parseQueryString(newSearch);
        newParsedSearched.favoriteId = newId;
        if (newItem.data)
          newItem.data.search =
            "?" +
            Object.keys(newParsedSearched)
              .map((key) => {
                return encodeURI(key) + "=" + encodeURI(newParsedSearched[key]);
              })
              .join("&");
        const newChild = {
          ...newItem,
          children: [],
          hasChildren: false,
          isExpanded: false,
          isChildrenLoading: false,
          id: newId
        };
        newItems[rootId] = newRootItem;
        newItems[newId] = newChild;
        return {
          rootId,
          items: newItems
        };
      });
      typeof toggleIsOpen === "function" && toggleIsOpen(false);
    };

    const handleDelete = () => {
      setTree((oldTree) => {
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items } as Record<ItemId, TreeItem>;
        delete newItems[item.id];
        for (const key in items) {
          if (Object.prototype.hasOwnProperty.call(newItems, key)) {
            const singleItem = newItems[key];
            const newItem = { ...singleItem };
            const itemIdIndex = singleItem.children.findIndex(
              (e) => e === item.id
            );
            if (itemIdIndex !== -1) {
              const newChildren = [...singleItem.children];
              newChildren.splice(itemIdIndex, 1);
              newItem.children = newChildren;
              newItems[singleItem.id] = newItem;
            }
          }
        }

        return {
          rootId,
          items: newItems
        };
      });
      typeof toggleIsOpen === "function" && toggleIsOpen(false);
    };

    const handleDeleteFolder = () => {
      setTree((oldTree) => {
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items } as Record<ItemId, TreeItem>;
        RemoveFolder(item.id, newItems);
        return {
          rootId,
          items: newItems
        };
      });
      typeof toggleIsOpen === "function" && toggleIsOpen(false);
    };

    const handleModalOpen = useContext(ModalFavoriteEditContext);

    const handleChildrenAdd = useContext(AppTreeChildFavoriteCreatorContext);
    const handleFolderChildrenAdd = useContext(
      AppTreeChildFolderCreatorContext
    );

    const resetView = useContext(ResetViewContext);

    // useEffect(() => {
    //   if (isFolder && item.isExpanded && isDragging) {
    //     onCollapse(item.id);
    //   }
    // }, [isDragging, isFolder, item.id, item.isExpanded, onCollapse]);

    if (isFolder) {
      return (
        <>
          <Popup
            domRef={popupRef}
            anchorEl={anchorRef.current}
            placement="bottom-start"
            modifiers={{
              preventOverflow: { boundariesElement: "viewport" }
            }}
            isOpen={isPopupOpen}
          >
            <div
              className="bg-white text-black px-2 d-flex flex-column py-2 fs-14 rounded"
              style={{ cursor: "context-menu" }}
            >
              <Button
                onClick={() => {
                  handleModalOpen(
                    item.data?.isFolder
                      ? FavoriteCreatorTypes.folder
                      : FavoriteCreatorTypes.folder,
                    item
                  );
                  typeof toggleIsOpen === "function" && toggleIsOpen(false);
                }}
                vType="link"
                className="d-flex pl-2 align-items-center justify-content-start text-left"
              >
                <div className="mr-1 ar-form-button-link-icon">
                  <FontAwesomeIcon icon={faEdit} />
                </div>
                <span>
                  <FormattedMessage id="EDIT" />
                </span>
              </Button>
              <Button
                onClick={() => {
                  typeof handleFolderChildrenAdd === "function" &&
                    handleFolderChildrenAdd(item);
                  typeof toggleIsOpen === "function" && toggleIsOpen(false);
                }}
                vType="link"
                className="d-flex pl-2 align-items-center justify-content-start text-left"
              >
                <div className="mr-1 ar-form-button-link-icon">
                  <FontAwesomeIcon icon={faFolderPlus} />
                </div>
                <span>
                  <FormattedMessage id="ADD_FOLDER" />
                </span>
              </Button>
              <Button
                onClick={() => {
                  typeof handleChildrenAdd === "function" &&
                    handleChildrenAdd(item);
                  typeof toggleIsOpen === "function" && toggleIsOpen(false);
                }}
                vType="link"
                className="d-flex pl-2 align-items-center justify-content-start text-left"
              >
                <div className="mr-1 ar-form-button-link-icon">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <span>
                  <FormattedMessage id="ADD_FAVORITE" />
                </span>
              </Button>
              <Button
                onClick={handleDeleteFolder}
                vType="link-danger"
                className={classnames(
                  classes.deleteButton,
                  "d-flex pl-2 align-items-center justify-content-start text-left"
                )}
              >
                <div className="mr-1 ar-form-button-link-icon">
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
                <span>
                  <FormattedMessage id="DELETE" />
                </span>
              </Button>
            </div>
          </Popup>
          <div
            // onMouseDown={() => {
            //   if (isOpen) onCollapse(item.id);
            //   else onExpand(item.id);
            // }}
            onClick={() => {
              // if (!isDragging) {
              if (isOpen) onCollapse(item.id);
              else onExpand(item.id);
              // }
            }}
            onContextMenu={(e) => {
              e.preventDefault();
              e.stopPropagation();
              typeof toggleIsOpen === "function" && toggleIsOpen(true);
            }}
            className={classnames(
              classes.appBarFolderContainer,
              "d-flex align-items-center"
            )}
            {...rest}
          >
            <div
              className={classnames(
                "d-flex align-items-center",
                classes.appBarFolderTitleContainer
              )}
            >
              <div
                style={{ width: 40 }}
                className="flex-0-0-auto d-flex justify-content-center"
              >
                <div
                  style={{ background: background }}
                  className={classnames(classes.appBarFolderIcon, {
                    [classes.active]: isOpen
                  })}
                >
                  <FontAwesomeIcon
                    size="sm"
                    icon={iconText.split(" ") as IconProp}
                  />
                </div>
              </div>
              <div
                className={classnames(
                  "fs-14 text-truncate ml-3 flex-1 fw-medium",
                  classes.appbartFavoriteTitle
                )}
              >
                {text}
              </div>
              <div
                style={{ width: 24, padding: 4 }}
                ref={anchorRef}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  typeof toggleIsOpen === "function" && toggleIsOpen(true);
                }}
                className="d-flex cursor-pointer justify-content-center align-items-center"
              >
                <FontAwesomeIcon
                  className={classnames(classes.appBarFolderMoreOptionsIcon)}
                  icon={faEllipsisV}
                />
              </div>
              <div
                className={classnames(
                  "d-flex align-items-center justify-content-center",
                  classes.appBarFolderColapseIcon
                )}
              >
                <FontAwesomeIcon
                  rotation={isOpen ? undefined : 270}
                  icon={faChevronDown}
                />
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <Popup
          domRef={popupRef}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          modifiers={{
            preventOverflow: { boundariesElement: "viewport" }
          }}
          isOpen={isPopupOpen}
        >
          <div
            className="bg-white text-black px-2 d-flex flex-column py-2 fs-14 rounded"
            style={{ cursor: "context-menu" }}
          >
            <Button
              onClick={() => {
                handleDuplication();
              }}
              vType="link"
              className="d-flex pl-2 justify-content-start align-items-center text-left"
            >
              <div className="mr-1 ar-form-button-link-icon">
                <FontAwesomeIcon icon={faClone} />
              </div>
              <span>
                <FormattedMessage id="DUPLICATE" />
              </span>
            </Button>
            <Button
              onClick={() => {
                handleModalOpen(FavoriteCreatorTypes.favorite, item);
                typeof toggleIsOpen === "function" && toggleIsOpen(false);
              }}
              vType="link"
              className="d-flex pl-2 align-items-center justify-content-start text-left"
            >
              <div className="mr-1 ar-form-button-link-icon">
                <FontAwesomeIcon icon={faEdit} />
              </div>
              <span>
                <FormattedMessage id="RENAME" />
              </span>
            </Button>
            <Button
              onClick={handleDelete}
              vType="link-danger"
              className={classnames(
                classes.deleteButton,
                "d-flex pl-2 align-items-center justify-content-start text-left"
              )}
            >
              <div className="mr-1 ar-form-button-link-icon">
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
              <span>
                <FormattedMessage id="DELETE" />
              </span>
            </Button>
          </div>
        </Popup>
        <Link to={url}>
          <div
            {...rest}
            style={{
              // opacity: isChild ? 0.8 : undefined,
              fontWeight: 400
            }}
            onClick={() => {
              typeof resetView === "function" && resetView(item.id as string);
            }}
            onContextMenu={(e) => {
              e.preventDefault();
              typeof toggleIsOpen === "function" && toggleIsOpen(true);
            }}
            className={classnames(
              "d-flex align-items-center text-white position-relative",
              classes.AppBarFavoriteParentItemContainer,
              { [classes.isDragging]: isDragging }
            )}
          >
            <div
              className={classnames(
                "d-flex align-items-center w-100",
                classes.AppBarFavoriteItemContainer,
                { [classes.isDragging]: isDragging, [classes.active]: isActive }
              )}
            >
              <div
                style={{ width: 40 }}
                className="flex-0-0-auto d-flex justify-content-center"
              >
                {viewTypeIcon ? (
                  <FontAwesomeIcon icon={viewTypeIcon} size="sm" />
                ) : null}
              </div>
              <div
                className={classnames(
                  "fs-14 text-truncate ml-3 flex-1 fw-medium",
                  classes.appbartFavoriteTitle
                )}
              >
                {text}
              </div>
              <div
                style={{ width: 32 }}
                className={classnames(
                  "flex-0-0-auto d-flex justify-content-center"
                )}
              >
                <div
                  ref={anchorRef}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    typeof toggleIsOpen === "function" && toggleIsOpen(true);
                  }}
                  className="px-2 py-1 d-flex cursor-pointer align-items-center"
                >
                  <FontAwesomeIcon
                    className={classnames(classes.appBarFolderMoreOptionsIcon)}
                    icon={faEllipsisV}
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </>
    );

    // return (
    //   <div className="d-flex align-items-center">
    //     {icon}
    //     <span className="ml-2">{text}</span>
    //   </div>
    // );
  }
);

interface Extras extends RenderItemParams {
  className?: string;
}

const TreeGroupItem = ({
  item,
  onExpand,
  onCollapse,
  provided,
  isActive,
  className,
  snapshot
}: Extras) => {
  const { draggableProps } = provided;
  const { style } = draggableProps;
  const { paddingLeft } = style;
  // const newStyle  ={
  // 	...style,
  // 	paddingLeft: paddingLeft > "0" ? "11" : "0";
  // }

  // const dragHanProps =
  //   item.hasChildren && item.isExpanded ? {} : { ...provided.dragHandleProps };

  return (
    <div
      ref={provided.innerRef}
      className={classnames("mb-1", className)}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      // style={newStyle}
    >
      <FavoriteItem
        isActive={isActive}
        isChild={paddingLeft > 0}
        isDragging={snapshot.isDragging}
        item={item}
        onCollapse={onCollapse}
        onExpand={onExpand}
      />
    </div>
  );
};

interface IEditFavoriteFolderProps {
  item: TreeItem;
  onClose: () => void;
}

const EditFolderModelContent: FC<IEditFavoriteFolderProps> = ({
  item,
  onClose
}) => {
  const { background, iconText } = item.data as TreeItemData;
  const setTree = useContext(AppTreeSetValueContext) as Dispatch<
    SetStateAction<TreeData>
  >;
  const [newName, setNewName] = useState<string | undefined>(item.data?.title);
  const intl = useIntl();
  const handleNameChange = (e: any) => {
    const { value } = e.target;
    setNewName(value);
  };
  const [color, setColor] = useState<string>(background);
  const [icon, setIcon] = useState<string>(iconText);

  const submitFolderChange = () => {
    setTree((oldTree) => {
      const newTree = { ...oldTree };
      const { rootId, items } = newTree;
      const newItems = { ...items } as Record<ItemId, TreeItem>;
      const activeItem = { ...newItems[item.id] } as TreeItem;
      const newData = { ...activeItem.data } as TreeItemData;
      newData.title = newName as string;
      newData.iconText = icon;
      newData.background = color;
      activeItem.data = { ...newData };
      newItems[item.id] = activeItem;
      return {
        rootId,
        items: newItems
      };
    });
    onClose();
  };

  return (
    <div className="bg-white p-4 d-flex w-450px flex-column">
      <div className="d-flex align-items-end mb-3">
        <FavoriteIconPicker
          icon={icon}
          setIcon={setIcon}
          setColor={setColor}
          color={color}
        />
        <LabeledInput
          autoComplete="off"
          text={intl.formatMessage({ id: "NAME" })}
          className={classnames("flex-1 ml-3")}
          name="Name"
          onChange={handleNameChange}
          value={newName}
        />
      </div>

      <div
        className={classnames("d-flex align-items-center justify-content-end")}
      >
        <Button vType="link-danger" className="mr-3" onClick={onClose}>
          <FormattedMessage id="CANCEL" />
        </Button>
        <Button disabled={false} onClick={submitFolderChange}>
          <FormattedMessage id="SUBMIT" />
        </Button>
      </div>
    </div>
  );
};

const EditFavoriteModelContent: FC<IEditFavoriteFolderProps> = ({
  item,
  onClose
}) => {
  const setTree = useContext(AppTreeSetValueContext) as Dispatch<
    SetStateAction<TreeData>
  >;
  const [newName, setNewName] = useState<string | undefined>(item.data?.title);
  const intl = useIntl();
  const handleNameChange = (e: any) => {
    const { value } = e.target;
    setNewName(value);
  };

  const submitNameChange = () => {
    setTree((oldTree) => {
      const newTree = { ...oldTree };
      const { rootId, items } = newTree;
      const newItems = { ...items } as Record<ItemId, TreeItem>;
      const activeItem = { ...newItems[item.id] } as TreeItem;
      const newData = { ...activeItem.data } as TreeItemData;
      newData.title = newName as string;
      activeItem.data = { ...newData };
      newItems[item.id] = activeItem;
      return {
        rootId,
        items: newItems
      };
    });
    onClose();
  };

  return (
    <div className="bg-white p-4 d-flex w-450px flex-column">
      <LabeledInput
        autoComplete="off"
        text={intl.formatMessage({ id: "NAME" })}
        className={classnames("flex-1 mb-3")}
        name="Name"
        onChange={handleNameChange}
        value={newName}
      />
      <div
        className={classnames("d-flex align-items-center justify-content-end")}
      >
        <Button vType="link-danger" className="mr-3" onClick={onClose}>
          <FormattedMessage id="CANCEL" />
        </Button>
        <Button disabled={false} onClick={submitNameChange}>
          <FormattedMessage id="SUBMIT" />
        </Button>
      </div>
    </div>
  );
};

const AppBarFavorites: FC<{
  childClassName?: string;
  extraClassNames?: string;
}> = ({ childClassName, extraClassNames }) => {
  const tree = useContext(AppTreeValueContext) as TreeData;
  const handleDisableHide = useContext(AppDraggingFavoriteContext);
  const setTree = useContext(AppTreeSetValueContext) as Dispatch<
    SetStateAction<TreeData>
  >;
  const FavoritesContainerRef = useRef(null);
  const [recalculateHight, setRecalculateHight] = useState(false);
  const resolvedTree = useMemo(() => {
    return tree;
  }, [tree]);
  const onExpand = (itemId: ItemId) => {
    setTree((oldTree) => {
      return mutateTree(oldTree, itemId, { isExpanded: true });
    });
    setRecalculateHight(true);
    // calculateNewHeight();
  };

  const onCollapse = (itemId: ItemId) => {
    setTree((oldTree) => {
      return mutateTree(oldTree, itemId, { isExpanded: false });
    });
    setRecalculateHight(true);
    // calculateNewHeight();
  };

  const onDragStart = (itemId: ItemId) => {
    handleDisableHide && handleDisableHide(true);
  };

  const onDragEnd = (
    source: TreeSourcePosition,
    destination?: TreeDestinationPosition
  ) => {
    handleDisableHide && handleDisableHide(false);
    if (!destination) {
      return;
    }
    const { parentId, index } = destination;
    if (!index && parentId) {
      const destinationItem = resolvedTree.items[parentId];
      const { isFolder } = destinationItem.data;
      if (isFolder) {
        setTree((oldTree) => {
          return moveItemOnTree(oldTree, source, destination);
        });
      } else return;
    } else
      setTree((oldTree) => {
        return moveItemOnTree(oldTree, source, destination);
      });
  };
  const ActiveFavorite = useContext(AppFavoriteContext);

  const renderItem = useMemo(() => {
    return ({ ...rest }: RenderItemParams) => {
      const { id } = rest.item;

      const isSelected = id === ActiveFavorite;
      return (
        <TreeGroupItem
          key={id}
          isActive={isSelected}
          className={childClassName}
          {...rest}
        />
      );
    };
  }, [ActiveFavorite, childClassName]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState<FavoriteCreatorTypes | null>();

  const onClose = () => {
    setModalIsOpen(false);
  };
  const onModalOpen = () => {
    setModalIsOpen(true);
  };

  const currentItem = useRef<TreeItem | null>(null);
  const handleModalOpen = useCallback(
    (type: FavoriteCreatorTypes, item: TreeItem) => {
      // console.log(type);
      currentItem.current = item;
      setModalType(type);
      onModalOpen();
    },
    []
  );
  const calculateNewHeight = useCallback(() => {
    const currentHeight = FavoritesContainerRef.current
      ? //@ts-ignore
        FavoritesContainerRef.current.firstChild.getBoundingClientRect().height
      : 0;
    if (FavoritesContainerRef.current) {
      //@ts-ignore
      FavoritesContainerRef.current.style.minHeight = currentHeight + 3 + "px";
    }
    setRecalculateHight(false);
  }, []);

  useEffect(() => {
    calculateNewHeight();
  }, [calculateNewHeight]);

  useEffect(() => {
    if (recalculateHight) calculateNewHeight();
  }, [calculateNewHeight, recalculateHight]);

  return (
    <div
      ref={FavoritesContainerRef}
      className={classnames(
        "w-100 of-y-auto h-100",
        "Smaller-Scroll-Bar",
        extraClassNames,
        classes.favoriteDropableContainer
      )}
    >
      <ModalFavoriteEditContext.Provider value={handleModalOpen}>
        <Modal isOpen={modalIsOpen} onClose={onClose}>
          {modalType === FavoriteCreatorTypes.folder ? (
            <EditFolderModelContent
              item={currentItem.current as TreeItem}
              onClose={onClose}
            />
          ) : (
            <EditFavoriteModelContent
              item={currentItem.current as TreeItem}
              onClose={onClose}
            />
          )}
        </Modal>
        <Tree
          tree={resolvedTree}
          onDragStart={onDragStart}
          offsetPerLevel={16}
          renderItem={renderItem}
          onExpand={onExpand}
          onCollapse={onCollapse}
          onDragEnd={onDragEnd}
          isDragEnabled
          isNestingEnabled={true}
        />
      </ModalFavoriteEditContext.Provider>
    </div>
  );
};

export default AppBarFavorites;
