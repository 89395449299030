import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from "react";
import classes from "../AutomationNodes.module.css";
import styles from "../../AutomationDetails.module.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NodeContainer from "../NodeContainer";
import SidebarV2Automation from "../../../../../Components/Sidebar/SidebarV2Automation";
import Modal from "../../../../../Components/Modal/Modal";
import ExpressionModal from "../../../Expressions/ExpressionModal";
import { useCurrentAccount } from "../../../../../Contexts/UserContext";
import { useSpaceQuery } from "../../../../../Helpers/IOClient";
import {
  spaceOrganizationSchema,
  spacePipelineSchema
} from "../../../../../config/schema";
import LoaderSpinner from "../../../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import {
  isOrganzationRequestValid,
  OrganizationRequestSideError
} from "../../../../../Components/Organizations/OrganizationHelper";
import { Form, useFormState } from "../../../../../Components/Forms";
import {
  AutomationSidebarFooter,
  AutomationSidebarHeader
} from "../AutomationSidebar";
import TabStrip from "../../../../../Components/TabStrip/TabStrip";
import { FormattedMessage, useIntl } from "react-intl";
import { InnerNodeConfigForm } from "../../NodesSidebar/NodeConfigForm";
import { baseConfig } from "../../NodeHelper";
import { useSpaceCurrenciesFetcher } from "../../../../../Components/Currency/Currency";
import {
  NodeShowDetailsContext,
  useAutomationTestRequestSidebar
} from "../../AutomationDetailsHelper";
import { shadeColor } from "../../../../../Helpers/EntityHelper";
import { TriggerList } from "../AutomationTestResults";
import { faChevronDoubleRight, faEye } from "@fortawesome/pro-light-svg-icons";
import {
  SidebarVisualTypeEnum,
  useSidebarView
} from "../../../../../Components/Sidebar/SidebarViewsHelper";
import Dropdown from "../../../../../Components/Dropdown/Dropdown";

export const snakeToFormCase = (str) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    )
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

export const EntityAutomationCreationSidebar = ({
  settings,
  data,
  ...rest
}) => {
  const user = useCurrentAccount();
  const {
    loading: loadingPipelines,
    data: pipelines,
    error: errorPipelines
  } = useSpaceQuery(
    settings.pipelineType
      ? `query/pipelines?type=${settings.pipelineType}`
      : null,
    [spacePipelineSchema]
  );

  const orgState = useSpaceQuery(
    `query/organizations?$filter=((Account/Id eq '${user.Id}') or (Manager/Id eq '${user.Id}'))`,
    [spaceOrganizationSchema]
  );

  const { loading: loadingOrganizations, data: organizations } = orgState;
  const {
    loading: loadingCurrencies,
    // error: errorCurrencies,
    data: currencies
  } = useSpaceCurrenciesFetcher();
  if (
    (settings.pipelineType && loadingPipelines) ||
    errorPipelines ||
    loadingCurrencies ||
    loadingOrganizations
  )
    return (
      <div style={{ width: 520 }} className="d-flex h-100 flex-column bg-white">
        <LoaderSpinner center size="sm" className="text-secondary" />
      </div>
    );

  if (!isOrganzationRequestValid(orgState))
    return (
      <div style={{ width: 520 }} className="d-flex h-100 flex-column bg-white">
        <OrganizationRequestSideError requestState={orgState} />
      </div>
    );

  return (
    <EntityAutomationCreationInnerSidebar
      organizations={organizations}
      pipelines={pipelines}
      data={data}
      settings={settings}
      user={user}
      currencies={currencies}
      {...rest}
    ></EntityAutomationCreationInnerSidebar>
  );
};
const InnerFormContent = ({ formState, FormContent, ...rest }) => {
  const { form, handleChange } = formState;
  const formHandleChange = useCallback(
    (newFormValue) => {
      handleChange((oldV) => {
        const update =
          typeof newFormValue === "function"
            ? newFormValue(oldV.form)
            : newFormValue;
        return {
          ...oldV,
          form: {
            ...oldV.form,
            ...update
          }
        };
      });
    },
    [handleChange]
  );

  const innerFormState = useMemo(() => {
    return {
      form: form.form,
      handleChange: formHandleChange,
      setForm: formHandleChange,
      setValidation: () => {}
    };
  }, [form.form, formHandleChange]);

  return (
    <Form formState={innerFormState}>
      <FormContent formState={innerFormState} {...rest} />
    </Form>
  );
};

const EntityAutomationCreationInnerSidebar = ({
  data: NodeData,
  children,
  organizations,
  pipelines,
  AllElements,
  CurrentNodeId,
  user,
  type,
  handleSubmit,
  automationTestClick,
  baseRequest,
  currencies,
  settings
}) => {
  const {
    convertAutomationToForm,
    buildBaseForm,
    FormContent,
    formatToSnake,
    getDefaultForm
  } = settings;

  const [modalStatus, setModalStatus] = useState(false);
  const ModalOptionsRef = useRef({});
  const openModal = (inputString, value, onChange) => {
    ModalOptionsRef.current = {
      inputString,
      value,
      onChange
    };
    setModalStatus(true);
  };

  const closeModal = () => {
    setModalStatus(false);
  };
  const defaultForm = useMemo(() => {
    return getDefaultForm({ user, currencies });
  }, [currencies, getDefaultForm, user]);

  const sidebarConfig = useMemo(() => {
    return {
      submit: handleSubmit,
      OnExpressionModalOpen: openModal,
      defaultForm
    };
  }, [defaultForm, handleSubmit]);

  const formState = useFormState(
    () => {
      const convertedForm = NodeData?.form
        ? convertAutomationToForm(NodeData?.form, currencies)
        : {};
      const baseForm = buildBaseForm({ user, convertedForm, currencies });

      return {
        form: baseForm,
        config: { ...baseConfig, ...NodeData?.config }
      };
    }
    // validateTaskForm
  );

  const {
    data,
    // loading
    // error
  } = useAutomationTestRequestSidebar(baseRequest);
  const parsedRequestData = JSON.parse(data);

  const [OpenPreview, setOpenPreview] = useState(() => {
    if (!parsedRequestData) return false;
    const { cycles } = parsedRequestData.data.workflowResult
      ? parsedRequestData.data.workflowResult
      : {};
    if (parsedRequestData?.data?.userError && TriggerList.includes(type))
      return true;
    if (!cycles || !cycles[CurrentNodeId]) return false;
    return true;
  });

  const onSave = () => {
    handleSubmit({
      form: formatToSnake({ form: formState.form.form, currencies }),
      config: formState.form.config,
      userId: formState.form.form.Creator
    });
  };

  const tabs = [
    {
      default: true,
      name: <FormattedMessage id="PARAMETERS" />,
      component: (
        <div className="px-3">
          <InnerFormContent
            FormContent={FormContent}
            organizations={organizations}
            pipelines={pipelines}
            formState={formState}
            currencies={currencies}
            canEdit
          />
        </div>
      )
    },
    {
      name: <FormattedMessage id="SETTINGS" />,
      component: (
        <div className="px-3">
          <InnerNodeConfigForm formState={formState} />
        </div>
      )
    }
  ];

  const sidebarView = useSidebarView();

  return (
    <div className="d-flex h-100 flex-column bg-white">
      <SidebarV2Automation config={sidebarConfig}>
        <Modal enableCloseButton isOpen={modalStatus} onClose={closeModal}>
          <ExpressionModal
            isOpen={modalStatus}
            closeModal={closeModal}
            AllElements={AllElements}
            CurrentNodeId={CurrentNodeId}
            ModalOptionsRef={ModalOptionsRef}
          />
        </Modal>
        <div
          className={classnames("d-flex flex-column h-100", {
            [classes.disableSideBarPreview]: !OpenPreview
          })}
        >
          <AutomationSidebarHeader
            formState={formState}
            automationTestClick={automationTestClick}
            CurrentNodeId={CurrentNodeId}
            baseRequest={baseRequest}
            title={CurrentNodeId}
          />
          <div className="flex-1 of-hidden d-flex">
            <div
              style={{ width: 590, zIndex: 1, paddingTop: 8 }}
              className="shadow"
            >
              <TabStrip
                route={false}
                alignCenter
                headerClassName="px-2"
                contentClassName="px-0"
                items={tabs}
                className="ar-details-tabstrip of-y-auto h-100 flex-1"
              />
            </div>
            <div className="bg-airdesk">
              <div
                className={classnames(
                  "flex-1 bg-airdesk d-flex h-100 flex-column",
                  classes.nodebaseSidebarPreviewSide
                )}
              >
                {OpenPreview ? (
                  <div
                    style={{
                      padding: "14px 15px 15px 15px"
                    }}
                    className="d-flex border-bottom justify-content-between align-items-center"
                  >
                    <div className="text-black text-truncate fs-16">
                      <FontAwesomeIcon icon={faEye} className="mr-2" />
                      <span>
                        <FormattedMessage id="PREVIEW_TEST_RESULTS" />
                      </span>
                    </div>
                    {sidebarView !== SidebarVisualTypeEnum.fullscreen && (
                      <div
                        className="cursor-pointer p-2 ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button"
                        onClick={() => {
                          setOpenPreview((e) => {
                            return !e;
                          });
                        }}
                      >
                        <FontAwesomeIcon
                          rotation={OpenPreview ? 180 : undefined}
                          icon={faChevronDoubleRight}
                          className="text-color-link"
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="p-2 h-100 d-flex justify-content-center align-items-center">
                    <div
                      className="cursor-pointer bg-airdesk ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button"
                      onClick={() => {
                        setOpenPreview((e) => {
                          return !e;
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </div>
                  </div>
                )}
                {OpenPreview ? (
                  <TestWorkflowContainerResults
                    type={type}
                    CurrentNodeId={CurrentNodeId}
                    RequestData={parsedRequestData}
                  />
                ) : (
                  <div className="flex-0-0-auto h-100"></div>
                )}
              </div>
            </div>
          </div>

          <div className="of-hidden d-flex">
            <div style={{ width: 590, zIndex: 1 }} className="shadow">
              <AutomationSidebarFooter onClick={onSave} />
            </div>
            <div
              className={classnames(
                "bg-airdesk h-100",
                classes.nodebaseSidebarPreviewSide
              )}
            ></div>
          </div>
        </div>
      </SidebarV2Automation>
    </div>
  );
};

const AutomationCreation = ({
  enabled,
  icon,
  onDoubleClick,
  text,
  subtittle,
  selected,
  config,
  type
}) => {
  const { note, hasCustomColor, customColor } = config || {};
  const canShowDetails = useContext(NodeShowDetailsContext);
  const darkerCustomColor = hasCustomColor
    ? shadeColor(customColor, -40)
    : undefined;
  return (
    <NodeContainer
      enabled={enabled}
      selected={selected}
      onDoubleClick={onDoubleClick}
    >
      <div
        style={{
          background: hasCustomColor ? customColor : undefined,
          color: darkerCustomColor
        }}
        className={classnames(styles.nodeIcon, styles[type], "p-3")}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="px-3 d-flex justify-content-center flex-column bg-white text-black fs-14">
        <div className="fw-medium">{text}</div>
        {note && canShowDetails && (
          <div className={classnames(styles.nodeNoteContainer, "fs-10")}>
            {note}
          </div>
        )}
      </div>
    </NodeContainer>
  );
};
const TestWorkflowContainerResults = ({ RequestData, CurrentNodeId, type }) => {
  const intl = useIntl();
  if (!RequestData) return <div className="flex-0-0-auto h-100"></div>;

  if (RequestData.data?.userError && TriggerList.includes(type)) {
    return (
      <div className="flex-0-0-auto h-100">
        <div className="p-3">
          <div className="d-flex mb-2 align-items-center text-danger-alt">
            {RequestData.data.name}
          </div>
          <div className="text-black breakWord">{RequestData.data.message}</div>
        </div>
      </div>
    );
  }

  const { cycles } = RequestData.data.workflowResult
    ? RequestData.data.workflowResult
    : {};

  if (!cycles || !cycles[CurrentNodeId])
    return <div className="flex-0-0-auto h-100"></div>;
  const {
    cyclesCount,
    errorsCount,
    // sucessesCount,
    results
  } = cycles[CurrentNodeId];

  // console.log(cyclesCount, errorsCount, sucessesCount, results);

  if (errorsCount > 0)
    return (
      <div className="overflow-auto flex-1">
        {results.map((e, i) => {
          const { result, error } = e;
          const contents = [];
          const isError = Boolean(error);
          let count = 0;
          const resolvedType = error || result;
          let keysLength = Object.keys(resolvedType).length;
          for (const key in resolvedType) {
            if (Object.hasOwnProperty.call(resolvedType, key)) {
              const element = resolvedType[key];
              if (typeof element === "object") {
                contents.push(
                  <div
                    key={key}
                    className={classnames("pl-2", {
                      "text-danger-alt": isError,
                      "text-black": !isError
                    })}
                  >{`"${key}" : ${handleObjToString(element)}${
                    count !== keysLength - 1 ? ", " : ""
                  }`}</div>
                );
              } else {
                contents.push(
                  <div
                    key={key}
                    className={classnames("pl-2", {
                      "text-danger-alt": isError,
                      "text-black": !isError
                    })}
                  >{`"${key}" : "${element}"${
                    count !== keysLength - 1 ? ", " : ""
                  }`}</div>
                );
              }
              count++;
            }
          }
          return (
            <div key={i} className="border-bottom p-3">
              <div className="d-flex  align-items-center text-black">
                {`${intl.formatMessage({ id: "CYCLE" })} ${i + 1}`}
              </div>
              <div className="text-black pl-2 breakWord">
                <div>{`{`}</div>
                {contents}
                <div>{`}`}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  return (
    <AutomtionTestResultsPreviewWithDropdowForCycles
      cyclesCount={cyclesCount}
      results={results}
    />
  );
  // return (
  //   <div className="overflow-auto flex-1">
  //     {results.map((e, i) => {
  //       const { result } = e;
  //       const contents = [];
  //       let count = 0;
  //       let keysLength = Object.keys(result).length;
  //       for (const key in result) {
  //         if (Object.hasOwnProperty.call(result, key)) {
  //           const element = result[key];
  //           if (typeof element === "object") {
  //             contents.push(
  //               <div
  //                 key={key}
  //                 className="text-black pl-2"
  //               >{`"${key}" : ${handleObjToString(element)}${
  //                 count !== keysLength - 1 ? ", " : ""
  //               }`}</div>
  //             );
  //           } else {
  //             contents.push(
  //               <div
  //                 key={key}
  //                 className="text-black pl-2"
  //               >{`"${key}" : "${element}"${
  //                 count !== keysLength - 1 ? ", " : ""
  //               }`}</div>
  //             );
  //           }
  //           count++;
  //         }
  //       }
  //       return (
  //         <div key={i} className="border-bottom p-3">
  //           <div className="d-flex  align-items-center text-black">
  //             {`${intl.formatMessage({ id: "CYCLE" })} ${i + 1}`}
  //           </div>
  //           <div className="text-black pl-2 breakWord">
  //             <div>{`{`}</div>
  //             {contents}
  //             <div>{`}`}</div>
  //           </div>
  //         </div>
  //       );
  //     })}
  //   </div>
  // );
};

const Item = ({ item }) => {
  return parseInt(item) + 1;
};

const AutomtionTestResultsPreviewWithDropdowForCycles = ({
  cyclesCount,
  results
}) => {
  const intl = useIntl();
  const [activeCycle, setActiveCycle] = useState(0);
  const Resolvedcycles = useMemo(() => {
    const cycles = [];
    for (var i = 0; i < cyclesCount; i++) {
      cycles.push(i);
    }
    return cycles;
  }, [cyclesCount]);

  const content = useMemo(() => {
    const { result } = results[activeCycle];
    const contents = [];
    let count = 0;
    let keysLength = Object.keys(result).length;
    for (const key in result) {
      if (Object.hasOwnProperty.call(result, key)) {
        const element = result[key];
        if (typeof element === "object") {
          contents.push(
            <div
              key={key}
              className="text-black pl-2"
            >{`"${key}" : ${handleObjToString(element)}${
              count !== keysLength - 1 ? ", " : ""
            }`}</div>
          );
        } else {
          contents.push(
            <div
              key={key}
              className="text-black pl-2"
            >{`"${key}" : "${element}"${
              count !== keysLength - 1 ? ", " : ""
            }`}</div>
          );
        }
        count++;
      }
    }
    return contents;
  }, [activeCycle, results]);

  return (
    <div className="overflow-auto flex-1">
      <div className="border-bottom p-3">
        <div className="d-flex  align-items-center text-black">
          <div className="mr-3">{`${intl.formatMessage({ id: "CYCLE" })}`}</div>
          <Dropdown
            className={classnames("bg-white")}
            // preview={preview}
            closeOnSelect
            closeOnClickWhileOpen
            items={Resolvedcycles}
            onChange={(e) => setActiveCycle(e)}
            listItemComponent={Item}
            valueComponent={Item}
            value={activeCycle}
          />
        </div>
        <div className="text-black pl-2 breakWord">
          <div>{`{`}</div>
          {content}
          <div>{`}`}</div>
        </div>
      </div>
    </div>
  );
};

const handleObjToString = (obj) => {
  let result = [];
  if (Array.isArray(obj)) {
    result.push("[");
    for (let index = 0; index < obj.length; index++) {
      const o = obj[index];
      if (typeof o === "object") {
        result.push("{");
        let count = 0;
        let objKeyLength = Object.keys(o).length;
        for (const key in o) {
          if (Object.hasOwnProperty.call(o, key)) {
            const element = o[key];
            let string = "";
            if (typeof element === "object") {
              string = `"${key}" : ${handleObjToString(element)}`;
            } else {
              string = `"${key}" : "${`${element}`.replace(/"/g, '\\"')}"`;
            }
            if (count !== objKeyLength - 1) string += ",";
            count++;
            result.push(string);
          }
        }
        if (index !== obj.length - 1) {
          result.push("},");
        } else result.push("}");
      } else {
        result.push(o);
        if (index !== obj.length - 1) {
          result.push(",");
        }
      }
    }
    result.push("]");
  } else {
    let count = 0;
    let objKeyLength = Object.keys(obj).length;
    result.push("{");
    for (const key in obj) {
      let string = "";
      if (Object.hasOwnProperty.call(obj, key)) {
        const element = obj[key];
        if (typeof element === "object") {
          string = `"${key}" : ${handleObjToString(element)}`;
        } else {
          string = `"${key}" : "${`${element}`.replace(/"/g, '\\"')}"`;
        }
        if (count !== objKeyLength - 1) string += ",";
        count++;
        result.push(string);
      }
    }
    result.push("}");
  }
  return result.join("");
};

export default AutomationCreation;
