import React from "react";
import Button from "./Button";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";

export const ExtraActionLoadableButton = (props) => {
  const {
    isLoading,
    vType,
    disabled,
    children,
    className,
    innerRef,
    spinnerSize,
    noMargin,
    ...passTroughProps
  } = props;

  return (
    <Button
      ref={innerRef}
      vType={vType}
      className={className}
      disabled={isLoading || disabled}
      {...passTroughProps}
    >
      <div className="d-flex align-items-center justify-content-start">
        {isLoading ? (
          noMargin ? (
            <LoaderSpinner
              size={spinnerSize ? spinnerSize : "xxs"}
              className="text-primary"
            />
          ) : (
            <LoaderSpinner
              size={spinnerSize ? spinnerSize : "xxs"}
              className="mx-2 text-primary"
            />
          )
        ) : (
          children
        )}
      </div>
    </Button>
  );
};

const LoadableButton = (props) => {
  const {
    isLoading,
    vType,
    disabled,
    children,
    className,
    innerRef,
    spinnerSize,
    noMargin,
    ...passTroughProps
  } = props;

  return (
    <Button
      ref={innerRef}
      vType={vType}
      // type={props.type || "button"}
      className={className}
      disabled={isLoading || disabled}
      {...passTroughProps}
    >
      <div className="d-flex align-items-center justify-content-center">
        {isLoading ? (
          noMargin ? (
            <LoaderSpinner
              size={spinnerSize ? spinnerSize : "xxs"}
              className="text-primary"
            />
          ) : (
            <LoaderSpinner
              size={spinnerSize ? spinnerSize : "xxs"}
              className="mx-2 text-primary"
            />
          )
        ) : (
          children
        )}
      </div>
    </Button>
  );
};

export default LoadableButton;
