import classnames from "classnames";

export const sanitizeHtml = function (markup) {
  markup = markup.replace(/&/g, "&amp;");
  markup = markup.replace(/</g, "&lt;");
  markup = markup.replace(/>/g, "&gt;");
  return markup;
};

const alignType = ["left", "right", "center", "justify"];

export const customParsers = {
  delimiter: function (data, config) {
    return `<div class="delimiter"></div>`;
  },
  image: function (data, config) {
    var base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    var {
      Alignment: { alignment }
    } = data.tunes || {};

    const { stretched } = data;
    var r = "";
    if (base64regex.test(data.url)) {
      let base64Result;

      const getBase64Image = (url) => {
        const img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL("image/png");
          document.removeChild(canvas);
          base64Result = dataURL;
        };
        img.src = url;
      };

      getBase64Image(data.url);
      r = `<img src="${base64Result}" onerror="this.style.display='none'" alt="${data.caption}" >`;
    } else
      r = `<img src="${data.url}" onerror="this.style.display='none'" alt="${data.caption}" >`;
    return `<div class="${classnames(
      alignment === "right"
        ? "text-align-right"
        : alignment === "center"
        ? "text-align-center"
        : alignment === "justify"
        ? "text-align-justify"
        : "text-align-left",
      stretched ? "img-stretched" : ""
    )}">${r}</div>`;
  },
  paragraph: function (data, config) {
    const paragraphAlign = data.alignment || data.align;
    var r = "";
    var {
      Alignment: { alignment }
    } = data.tunes || {};
    if (
      typeof paragraphAlign !== "undefined" &&
      alignType.includes(paragraphAlign)
    ) {
      r = `<p style="text-align:${paragraphAlign};">${data.text}</p>`;
    } else {
      r = `<p>${data.text}</p>`;
    }
    return `<div class="${
      alignment === "right"
        ? "text-align-right"
        : alignment === "center"
        ? "text-align-center"
        : alignment === "justify"
        ? "text-align-justify"
        : "text-align-left"
    }">${r}</div>`;
  },

  header: function (data) {
    var {
      Alignment: { alignment }
    } = data.tunes || {};
    var r = "";
    r = `<h${data.level}>${data.text}</h${data.level}>`;
    return `<div class="${
      alignment === "right"
        ? "text-align-right"
        : alignment === "center"
        ? "text-align-center"
        : alignment === "justify"
        ? "text-align-justify"
        : "text-align-left"
    }">${r}</div>`;
  },

  checklist: function (data) {
    const { items } = data;
    var {
      Alignment: { alignment }
    } = data.tunes || {};
    var r = "";
    const content = [];

    for (const e of items) {
      const { text, checked } = e;

      content.push(
        `<div class="CheckListItem ${
          checked ? "CheckList--checked" : ""
        }"><div class="Checkbox"></div><div class="Checklist-text">${text}</div></div>`
      );
    }

    r = `<div class="cdx-block">${content.join("")}</div>`;

    return `<div class="${
      alignment === "right"
        ? "text-align-right"
        : alignment === "center"
        ? "text-align-center"
        : alignment === "justify"
        ? "text-align-justify"
        : "text-align-left"
    }">${r}</div>`;
  },

  list: function (data) {
    var {
      Alignment: { alignment }
    } = data.tunes || {};
    var r = "";
    const type = data.style === "ordered" ? "ol" : "ul";
    const classtest =
      data.style === "ordered"
        ? "cdx-block cdx-list cdx-list--ordered"
        : "cdx-block cdx-list cdx-list--unordered";
    const items = data.items.reduce(
      (acc, item) => acc + `<li class="cdx-list__item">${item}</li>`,
      ""
    );
    r = `<${type} class="${classtest}">${items}</${type}>`;
    return `<div class="${
      alignment === "right"
        ? "text-align-right"
        : alignment === "center"
        ? "text-align-center"
        : alignment === "justify"
        ? "text-align-justify"
        : "text-align-left"
    }">${r}</div>`;
  },

  quote: function (data, config) {
    var {
      Alignment: { alignment }
    } = data.tunes || {};
    var r = "";

    r = `<div class="cdx-block">
		<blockquote class="cdx-quote">
		<div class="cdx-quote__text">${data.text}</div>
		<div class="cdx-quote__caption">${data.caption}</div>
		</blockquote>
		</div>`;

    return `<div class="${
      alignment === "right"
        ? "text-align-right"
        : alignment === "center"
        ? "text-align-center"
        : alignment === "justify"
        ? "text-align-justify"
        : "text-align-left"
    }">${r}</div>`;
  },

  table: function (data) {
    var {
      Alignment: { alignment }
    } = data.tunes || {};
    var r = "";

    const rows = data.content.map((row) => {
      return `<div class="tc-row">${row.reduce((acc, cell, idx) => {
        let style = "";
        if (data.collumnWidth && data.collumnWidth[idx]) {
          style = `width: ${data.collumnWidth[idx]}px; flex: 0 0 auto;`;
        }
        return acc + `<div style="${style}" class="tc-cell">${cell}</div>`;
      }, "")}</div>`;
    });

    // r = `<div class="tc-table">${rows.join("")}</div>`;
    r = `${rows.join("")}`;

    return `<div class="${
      alignment === "right"
        ? "text-align-right"
        : alignment === "center"
        ? "text-align-center"
        : alignment === "justify"
        ? "text-align-justify"
        : "text-align-left"
    }">${r}</div>`;
  },
  code: function (data, config) {
    var {
      Alignment: { alignment }
    } = data.tunes || {};
    var r = "";

    const markup = sanitizeHtml(data.code);
    r = `<div class="cdx-block"><div class="ce-code__textarea">${markup}</div></div>`;

    return `<div class="${
      alignment === "right"
        ? "text-align-right"
        : alignment === "center"
        ? "text-align-center"
        : alignment === "justify"
        ? "text-align-justify"
        : "text-align-left"
    }">${r}</div>`;
  },

  raw: function (data) {
    var {
      Alignment: { alignment }
    } = data.tunes || {};
    var r = "";

    r = data.html;

    return `<div class="${
      alignment === "right"
        ? "text-align-right"
        : alignment === "center"
        ? "text-align-center"
        : alignment === "justify"
        ? "text-align-justify"
        : "text-align-left"
    }">${r}</div>`;
  },

  pagebreaker: function (data) {
    return `<div class="pageBreaker"></div>`;
  },

  embed: function (data, config) {
    if (config.embed.useProvidedLength) {
      data.length = `width="${data.width}" height="${data.height}"`;
    } else {
      data.length = "";
    }
    const regex = new RegExp(/<%data\.(.+?)%>/, "gm");
    if (config.embedMarkups[data.service]) {
      return config.embedMarkups[data.service].replace(
        regex,
        (match, p1) => data[p1]
      );
    } else {
      return config.embedMarkups["defaultMarkup"].replace(
        regex,
        (match, p1) => data[p1]
      );
    }
  }
};
