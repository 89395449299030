import React, { useMemo } from "react";
import {
  createFormRequestHook,
  useFormState
} from "../../../../Components/Forms";
import { spaceCostCentersSchema } from "../../../../config/schema";
import LoaderSpinner from "../../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useCostCenter } from "../../../../Hooks/EntityHooks";
import { useIntl, FormattedMessage } from "react-intl";
import { useToast } from "../../../../Components/Toast/ToastProvider";
import { useHandleError } from "../../../../Components/CGrid/ServerAwareHelper";
import {
  useSidebarSpacePost,
  useSidebarSpaceDelete,
  BaseSidebarHeader
} from "../../../../Components/Sidebar/SidebarV2Helper";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../../../Components/Forms/SideMenuForm";
import FormInput from "../../../../Components/Forms/FormInput/FormInput";
import { UserDropdown } from "../../../AdvancedMultiInputs";
import Switch from "../../../../Components/Switch/Switch";

const validateForm = ({ Name }, addError) => {
  if (!Name || Name.length === 0) addError("Name");
};

const CostCenterFormBase = ({
  convertedForm,
  canDelete,
  refetchRef,
  onSuccess,
  id,
  canEdit,
  className
}) => {
  const formState = useFormState(() => {
    return { ...convertedForm };
  });

  const { form, setForm } = formState;

  const intl = useIntl();
  const createToast = useToast();
  const handleTranslatedError = useHandleError(spaceCostCentersSchema);

  const [post, { loading }] = useSidebarSpacePost(
    id ? `Settings/Users/CostCenter/${id}` : "Settings/Users/CostCenter",
    spaceCostCentersSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess(newId);

        createToast({
          pos: "tm",
          type: "success",
          description: `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`,
          title: `${intl.formatMessage({ id: "COST_CENTER" })} nº ${
            newId || id
          }`
        });

        // if (id) return;
      },
      refetchRef,
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `Settings/Users/CostCenter/${id}`,
    spaceCostCentersSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "COST_CENTER" })} nº ${id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      refetchRef,
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const format = (form) => {
    const { Name, Accounts, Id, ViewInReport } = form;

    // return { Name, AccountIds: Accounts };
    return { Name, Id, ViewInReport };
  };

  const handleSubmit = (form) => {
    post(format(form));
  };

  const handleCheckChanges = ({ target: { name, checked } }) => {
    setForm((oldF) => {
      return {
        ...oldF,
        [name]: checked
      };
    });
  };

  return (
    <SideForm
      className="bg-white"
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      <BaseSidebarHeader>
        <FormattedMessage id={"COST_CENTER"} />
      </BaseSidebarHeader>
      <SideFormContent style={{ width: 520 }} className={"px-3 pt-3 pb-5"}>
        <div className="mb-3 text-black fs-16 fw-medium">
          <FormattedMessage id={"DETAILS"} />
        </div>
        <FormInput className="mb-3" textId="NAME" name="Name" />

        <FormInput
          name="ViewInReport"
          onChange={handleCheckChanges}
          textId={"BILLABLE"}
          inputType={Switch}
          className="mb-3"
        />
        {/* <FormInput
          name="Accounts"
          className="mb-3"
          textId="COLLABORATORS"
          inputType={UserDropdown}
          multiple
        /> */}
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        form={form}
        handleChange={setForm}
        deleteLoading={deleteLoading}
        handleDelete={remove}
        isLoading={loading}
      />
    </SideForm>
  );
};

const defaultForm = {
  Name: ""
};

const convertToForm = (entity, props) => {
  if (!entity) {
    return {};
  }

  return { ...entity };
};

const useCostCenterRequest = createFormRequestHook({
  schema: spaceCostCentersSchema,
  convertEntityToForm: convertToForm,
  formatDuplicationEntity: (entity) => {
    return { ...entity, Id: undefined };
  }
});

const CostCenterForm = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { key, loading, error, convertedForm, isChild, baseForm, childProps } =
    useCostCenterRequest({
      ...props,
      allwaysNewRequest: true,
      options: {
        cache: false
      }
    });
  const { id, onSuccess, className, ...rest } = props;
  const resolvedForm = useMemo(() => {
    const form = {
      ...defaultForm,
      ...convertedForm,
      ...baseForm
    };
    return form;
  }, [baseForm, convertedForm]);

  const category = useCostCenter(id);

  if (loading || error)
    return (
      <div
        style={{ width: 520 }}
        className={"px-3 d-flex h-100 flex-column bg-white pt-3 pb-5"}
      >
        <LoaderSpinner center size="sm" className="text-secondary" />;
      </div>
    );

  const canEdit = category ? category.CanEdit : true;

  return (
    <CostCenterFormBase
      id={id}
      canDelete={category?.CanDelete}
      className={className}
      onSuccess={onSuccess}
      canEdit={canEdit}
      convertedForm={resolvedForm}
      {...rest}
    ></CostCenterFormBase>
  );
};

export default CostCenterForm;
