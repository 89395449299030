import React from "react";
import "./Checkbox.css";
import classnames from "classnames";

/**
 * @param {bool} [checked = false] - Input check
 * @param {function} change - Callback when input changes
 */

// class Checkbox extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};

//     this.isChecked = this.props.checked === true;
//   }

//   onChange = e => {
//     this.isChecked = !this.isChecked;
//     this.props.onChange && this.props.onChange(e);
//     this.props.change && this.props.change({ isChecked: this.isChecked });
//   };

//   render() {
//     const { className } = this.props;
//     return (
//       <label className={classnames("Checkbox-container", className)}>
//         <input
//           type="checkbox"
//           checked={this.props.checked}
// 		  onChange={this.onChange}
// 		  disabled={this.props.disabled}
//         />
// 			<div className={`Checkbox-box ${this.props.disabled ? "Checkbox-box-disabled" : ''}`}>
//           <i className="Checkbox-check fas fa-check" />
//         </div>
//         {this.props.children}
//         {this.props.textValue && (
// 				<div className={`Checkbox-text`}>{this.props.textValue}</div>
//         )}
//       </label>
//     );
//   }
// }

const Checkbox = ({
  onChange = () => {},
  change,
  textValue,
  className,
  children,
  style,
  ...rest
}) => {
  const handleChange = (e) => {
    const isChecked = !e.target.checked;
    onChange && onChange(e);
    change && change({ isChecked });
  };

  return (
    <label
      className={classnames("checkbox-container", className)}
      style={style}
    >
      <input type="checkbox" onChange={handleChange} {...rest}></input>
      <span className="checkmark"></span>
      {textValue && <span className="Checkbox-text">{textValue}</span>}
      {children && <div className="Checkbox-text">{children}</div>}
    </label>
  );
};

export const CheckboxPreview = ({
  onChange = () => {},
  change,
  textValue,
  className,
  defaultChecked,
  children,
  style,
  ...rest
}) => {
  return (
    <label
      key={defaultChecked}
      className={classnames("checkbox-container pointer-none", className)}
      style={style}
    >
      <input type="checkbox" defaultChecked={defaultChecked} {...rest}></input>
      <span className="checkmark"></span>
      {textValue && <span className="Checkbox-text">{textValue}</span>}
      {children && <div className="Checkbox-text">{children}</div>}
    </label>
  );
};

export default Checkbox;
