import React from "react";
import "react-table/react-table.css";
import { injectIntl } from "react-intl";
import { Switch, Route, withRouter } from "react-router-dom";
// import {
//   RealTimePageProvider,
//   RealTimePageEnum
// } from "../RealTime/RealTimePages";
import ContractOverview from "./ContractOverview/ContractOverview";
import ContractDetails from "./ContractDetails/ContractDetails";
import {
  useEnterViewSignalR,
  PageTypeProvider
} from "../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimePageEnum } from "../RealTime/RealTimePages";

const Contracts = withRouter(({ match }) => {
  // useEnterViewSignalR(RealTimePageEnum.Contract)
  return (
    <PageTypeProvider pageType={RealTimePageEnum.Contract}>
      <Switch>
        {/* <Route path={`${match.url}/:contractId/detailsTest`}>
        <div
          className="of-y-auto p-3 bg-white"
          style={{ height: 450, width: 900 }}
        >
          <TestContractReadOverview />
        </div>
      </Route> */}
        <Route path={`${match.url}/:contractId`}>
          <ContractDetails />
        </Route>
        <Route>
          {/* <RealTimePageProvider pageType={RealTimePageEnum.Contract}> */}
          <ContractOverview />
          {/* </RealTimePageProvider> */}
        </Route>
      </Switch>
    </PageTypeProvider>
  );
});

export default injectIntl(Contracts);
