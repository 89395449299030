import {
  Communicator,
  createCommunicatorHook,
} from "../../Helpers/Communicator";

export const contractCommunicator = new Communicator();

export const useContractCommunicator = createCommunicatorHook(
  contractCommunicator
);
