import React, {  useMemo } from "react";
import FormInput from "../../../Components/Forms/FormInput/FormInput";

import { useIntl } from "react-intl";
// import { withSpace } from "../../../Contexts/SpaceContext";
import {
  //   createFormRequestHook,
  useFormState,
} from "../../../Components/Forms";
import {
  SideForm,
  SideFormContent,
  SideFormFooter,
} from "../../../Components/Forms/SideMenuForm";
import { spaceLostReasonSchema } from "../../../config/schema";
import {
  OrganizationDropdown,
  //   DepartmentTeamDropdown,
  //   convertDepartmentTeams,
  //   convertToDepartmentTeams
} from "../../AdvancedMultiInputs";
import { useLostReason } from "../../../Hooks/EntityHooks";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { defaultCancelForm } from "../../Calls/CallsHelper";
import { useToast } from "../../../Components/Toast/ToastProvider";
// import Switch from "../../../Components/Switch/Switch";
import { useSpaceQuery } from "../../../Helpers/IOClient";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpacePost,
  useSidebarSpaceDelete,
} from "../../../Components/Sidebar/SidebarV2Helper";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";

const LostReasonForm = (props) => {
  const { Type, id, onSuccess } = props;
  // console.log(props)
  const endpoint = useMemo(() => {
    if (!id) return null;
    return `statusdetail/${handleLocation(Type)}/LostReasons/${id}`;
  }, [Type, id]);

  const { loading, error } = useSpaceQuery(endpoint, spaceLostReasonSchema);

  const lost = useLostReason(id);

  const canEdit = lost ? lost.CanEdit : true;
  const intl = useIntl();
  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        {id
          ? `${intl.formatMessage({ id: "EDIT_LOSS_REASON" })} ${id}`
          : intl.formatMessage({ id: "ADD_LOSS_REASON" })}
      </BaseSidebarHeader>
      <BaseSidebarContent>
        {loading || error ? (
          <LoaderSpinner center size="sm" className="text-secondary" />
        ) : (
          <LostForm
            onSuccess={onSuccess}
            id={id}
            canEdit={canEdit}
            convertedForm={lost}
            {...props}
          />
        )}
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );

  // if (loading || error)
  //   return <LoaderSpinner center size="sm" className="text-secondary" />;

  // return (
  //   <LostForm
  //     onSuccess={onSuccess}
  //     id={id}
  //     canEdit={canEdit}
  //     convertedForm={lost}
  //     {...props}
  //   />
  // );
};

const LostForm = ({
  convertedForm,
  id,
  canEdit,
  onSuccess,
  isChild,
  refetchRef,
  ...props
}) => {
  const { Type } = props;
  const location = handleLocation(Type);
  const formState = useFormState(() => {
    return {
      ...defaultCancelForm(),
      ...convertedForm,
    };
  });
  const { form } = formState;
  const { Organizations, Name } = form;

  const lostReason = useLostReason(id);

  const canDelete = lostReason?.CanDelete;

  const handleTranslatedError = useHandleError(spaceLostReasonSchema);

  const createToast = useToast();
  const intl = useIntl();
  const [post, { loading }] = useSidebarSpacePost(
    id
      ? `statusdetail/${location}/LostReasons/${id}`
      : `statusdetail/${location}/LostReasons`,
    spaceLostReasonSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
					title: `${intl.formatMessage({ id: "LOST_REASONS_SUCCESS" })} ${Name}`,
					description: `${intl.formatMessage({ id: "SUCCESS_SAVED" })}`,
        });
      },
      refetchRef: refetchRef,
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
    }
  );
  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `statusdetail/${location}/LostReasons/${id}`,
    spaceLostReasonSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "LOST_REASON" })} ${Name}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`,
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
			},
			refetchRef
    }
  );

  const handleSubmit = (form) => {
    post(formatLostForm(form));
  };

  const validateCancelForm = (
    { Name, DepartmentTeams, Organizations },
    addError
  ) => {
    if (!Name) addError("Name");
    if ((Organizations && Organizations.length === 0) || !Organizations)
      addError("Organizations");
  };

  const formatLostForm = (form) => {
    const { Name, DepartmentTeams, Organizations, ...restForm } = form;
    return {
      ...restForm,
      Name,
      DepartmentTeams,
      Organizations,
    };
  };

  return (
    <SideForm
      className="flex-1 of-y-auto d-flex flex-column"
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateCancelForm}
    >
      <SideFormContent className="p-4">
        <FormInput name="Name" value={Name} textId="NAME" className="mb-3" />

        <FormInput
          name="Organizations"
          className="mb-3"
          value={Organizations}
          textId="COMPANY"
          inputType={OrganizationDropdown}
          multiple
        />
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        deleteLoading={deleteLoading}
        handleDelete={remove}
        isLoading={loading}
      />
    </SideForm>
  );
};

const handleLocation = (type) => {
  switch (type) {
    case 1:
      return "settingsProjects";
    case 2:
      return "settingsTickets";
    case 3:
      return "settingsCalls";
    case 4:
      return "settingsTasks";
    case 5:
      return "settingsContracts";
    case 6:
      return "settingsDeals";
    default:
      return null;
  }
};

export default LostReasonForm;
