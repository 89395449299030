import React, { useCallback } from "react";
import "./ExcelHelp.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/pro-light-svg-icons";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import LoadableButton from "../Button/LoadableButton";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { useDetailAwareParams, useServerAwareState } from "../CGrid/ServerGrid";
import { buildODataQuery } from "../../Helpers/ODataHelper";
import api from "../../api";
import { FormattedMessage, useIntl } from "react-intl";
import { useToast } from "../Toast/ToastProvider";
import { handleError } from "../../Helpers/MiscHelper";
import { useConnectionId } from "../../Containers/RealTime/RealTime";
import { useUnreadEntitiesFetcher } from "../UnreadEntities/UnreadEntities";
import { spaceAccountExportsSchema } from "../../config/schema";

const createExcelButton = ({
  getUrl,
  moduleType,
  ExportType,
  pipelineFilter
}) => {
  return ({ max = 1000000, ExportType, directUrl }) => {
    const url = directUrl || getUrl();
    const { params } = useServerAwareState();
    const queryParams = useDetailAwareParams(params, moduleType);
    const { top, skip } = queryParams;
    // const filter = params.filter;
    // const orderby = params.orderBy;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "_" + mm + "_" + yyyy;

    const searchFilter = buildODataQuery(
      {
        ...queryParams,
        top: top || max,
        skip: ExportType === "Total" ? 0 : skip,
        isFull: ExportType === "Total" ? 1 : 0
      },
      true,
      {
        pipelineSettings: {
          field: pipelineFilter
        }
      }
    );
    const createToast = useToast();
    const intl = useIntl();
    const conId = useConnectionId();
    const { refetch, loading } = useSpaceQuery(`${url}${searchFilter}`, null, {
      autoFetch: false,
      headers: {
        ConnectionID: conId
      },
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "info",
          description: intl.formatMessage({
            id: "API_EXPORT_STARTED"
          })
        });
        // var blob = new Blob([data], {
        //   type:
        //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        // });
        // const link = document.createElement("a");
        // // Browsers that support HTML5 download attribute
        // const url = URL.createObjectURL(blob);

        // link.setAttribute("href", url);
        // link.setAttribute(
        //   "download",
        //   `${space.charAt(0).toUpperCase() +
        //     space.slice(1)}-${workspace}-${today}-${ExportType}.xlsx`
        // );
        // link.style.visibility = "hidden";
        // document.body.appendChild(link);
        // link.click();
        // if (document.body.contains(link)) document.body.removeChild(link);
      },
      onError: ({ error }) => {
        handleError(createToast, "Ocorreu um erro a exportar");
      }
    });

    const HandleClick = () => {
      refetch();
    };

    return (
      <LoadableButton
        className="p-1 AlignLeftLoadableButton"
        vType="link"
        onClick={HandleClick}
      >
        {!loading ? (
          <FontAwesomeIcon icon={faFileExcel} />
        ) : (
          <LoaderSpinner center size="xxs" />
        )}
        <span className="ml-2">
          {max > 50 ? (
            <FormattedMessage id={"EXPORT_EXCEL"} />
          ) : (
            <FormattedMessage id={"EXPORT_PAGE"} />
          )}
        </span>
      </LoadableButton>
    );
  };
};

export const useExcelQuery = (url, ExportType, fileId) => {
  // const workspace = useSpace().Id;
  // let space = url.split("/")[0];
  // var today = new Date();
  const { refetch } = useSpaceQuery(
    `export/user/${fileId}`,
    spaceAccountExportsSchema,
    {
      cache: false,
      autoFetch: false
    }
  );
  const unreaddUpdater = useUnreadEntitiesFetcher();
  const post = useCallback(() => {
    api
      .get(url, {
        responseType: "arraybuffer"
      })
      .then((r) => {
        var blob = new Blob([r], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const link = document.createElement("a");
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);

        link.setAttribute("href", url);
        link.setAttribute("download", `${ExportType}.xlsx`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        if (document.body.contains(link)) document.body.removeChild(link);
        refetch && refetch();
        unreaddUpdater && unreaddUpdater();
      });
  }, [ExportType, refetch, unreaddUpdater, url]);

  return post;
};

// const createDirectExcelButton = ({ getUrl, moduleType, ExportType }) => {
//   return ({ max = 1000000, ExportType, directUrl }) => {
//     const url = directUrl || getUrl();
//     const workspace = useSpace().Id;
//     let space = url.split("/")[0];

//     const { params } = useServerAwareState();
//     // const filter = params.filter;
//     // const orderby = params.orderBy;
//     var today = new Date();
//     var dd = String(today.getDate()).padStart(2, "0");
//     var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
//     var yyyy = today.getFullYear();

//     today = dd + "_" + mm + "_" + yyyy;

//     const searchFilter = buildODataQuery({ ...params, top: max }, true);

//     const { refetch, loading } = useSpaceQuery(`${url}${searchFilter}`, null, {
//       responseType: "blob",
//       autoFetch: false,
//       onSuccess: ({ data }) => {
//         var blob = new Blob([data], {
//           type:
//             "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
//         });
//         const link = document.createElement("a");
//         // Browsers that support HTML5 download attribute
//         const url = URL.createObjectURL(blob);

//         link.setAttribute("href", url);
//         link.setAttribute(
//           "download",
//           `${space.charAt(0).toUpperCase() +
//             space.slice(1)}-${workspace}-${today}-${ExportType}.xlsx`
//         );
//         link.style.visibility = "hidden";
//         document.body.appendChild(link);
//         link.click();
//         if (document.body.contains(link)) document.body.removeChild(link);
//       }
//     });

//     const HandleClick = () => {
//       refetch();
//     };

//     return (
//       <LoadableButton
//         className="p-1 AlignLeftLoadableButton"
//         vType="link"
//         onClick={HandleClick}
//       >
//         {!loading ? (
//           <FontAwesomeIcon icon={faFileExcel} />
//         ) : (
//           <LoaderSpinner center size="xxs" />
//         )}
//         <span className="ml-2">
//           {max > 50 ? "Exportar tudo para Excel" : "Exportar pagina para Excel"}
//         </span>
//       </LoadableButton>
//     );
//   };
// };

export default createExcelButton;
