import React from "react";
import {
  UserDropdown,
  ClientDropdown,
  ContactDropdown,
  ContractStatusDropdown,
  ContractTypeDropdown,
  InterventionTypeDropdown,
  DepartmentDropdown,
  TeamDropdown,
  ContractDropdown,
  ProjectDropdown,
  ProjectStatusDropdown,
  OrganizationDropdown,
  DealDropdown,
  TicketDropdown,
  SubscriptionTypeDropdown,
  ProjectTypeDropdown,
  ContactStatusDropdown,
  TaskDropdown,
  CallDropdown,
  InterventionDropdown,
  ReadOptionsDropdown,
  ClientTypeDropdown,
  SocialAccountDropdown,
  FeedbackQueueStatusDropdown,
  SubscriptionDropdown,
  DocumentTDTypeDropdown,
  TaskTypeDropdown,
  // CostCenterDropdown,
  CostCenterBaseDropdown,
  TicketTagTDropdown,
  ConnectedTicketTagItem,
  ConnectedDealTagItem,
  DealTagTDropdown,
  ConnectedClientTagItem,
  ClientTagTDropdown,
  ContractTagTDropdown,
  ConnectedContractTagItem,
  ConnectedSubscriptionTagItem,
  SubscriptionTagTDropdown,
  ConnectedCallTagItem,
  CallTagTDropdown,
  ConnectedProjectTagItem,
  ProjectTagTDropdown,
  ConnectedTaskTagItem,
  TaskTagTDropdown
} from "../Containers/AdvancedMultiInputs";
import { ProjectStatusProfile } from "../Components/Projects/ProjectProfile";
import { ConnectedAccountProfile } from "../Components/Accounts/AccountProfile";
import { TinyInterventionTargetProfile } from "../Components/Interventions/InterventionTargetItem";
import { ContractStatusProfile } from "../Components/Contract/ContractProfile";
import { ConnectedContractTypeProfile } from "../Components/ContractType/ContractTypeProfile";
import InterventionTypeProfile from "../Components/InterventionTypes/InterventionTypeProfile";
import {
  useClientType,
  useInterventionType,
  usePipelineStatus,
  useSocialAccount,
  useDocumentType,
  useAllPipelines,
  useCostCenter,
  useTicketType,
  useSubscriptionType,
  useDealTag,
  useClientTag,
  useContractTag,
  useSubscriptionTag,
  useCallTag,
  useProjectTag,
  useTaskTag
} from "../Hooks/EntityHooks";
import { ConnectedClientOriginAnchor } from "../Containers/Origin/ClientOrigin";
import { ConnectedContactOriginAnchor } from "../Containers/Origin/ContactOrigin";
import { ConnectedDepartmentProfile } from "../Components/Departments/DepartmentProfile";
import { ConnectedTeamProfile } from "../Components/Teams/TeamProfile";
import { ConnectedProjectOriginAnchor } from "../Containers/Origin/ProjectOrigin";
import {
  spaceProjectsSchema,
  spaceCallsSchema,
  spaceTicketsSchema,
  spaceClientSchema,
  spaceTasksSchema,
  spaceContractSchema,
  spaceContactSchema,
  accountSchema,
  spaceDepartmentSchema,
  spaceTeamSchema,
  spaceInterventionTypeSchema,
  spaceContractTypeSchema,
  spaceOrganizationSchema,
  spaceDealsSchema,
  spaceSubscriptionTypeSchema,
  spaceInterventionsSchema,
  // spaceEmailConversationSchema,
  spaceClientTypeSchema,
  spaceDocumentTypesSchema,
  spacePipelineSchema,
  spaceCostCentersSchema,
  spaceTicketTagsSchema,
  spaceDealsTagsSchema,
  spaceClientTagsSchema,
  spaceContractTagsSchema,
  spaceSubscriptionTagsSchema,
  spaceCallsTagsSchema,
  spaceProjectTagsSchema,
  spaceTaskTagsSchema,
  spaceProjectCategorySchema,
  spaceTaskTypeSchema
} from "../config/schema";
import { fk } from "./SchemaHelper";
import { ConnectedContractOriginAnchor } from "../Containers/Origin/ContractOrigin";
import {
  ServerAwarePipelineStatusDropdown,
  ServerAwareUpgradedPipelineStatusDropdown
} from "../Containers/AdvancedMultiInputs/PipelineDropdowns";
// import MultiSelectInput from "../Components/MultiSelectInput/MultiSelectInput";
import { ConnectedProjectTypeProfile } from "../Components/ProjectType/ProjectTypeProfile";
import { ConnectedOrganizationProfile } from "../Components/Organizations/OrganizationProfile";
import { ConnectedDealOriginAnchor } from "../Containers/Origin/DealOrigin";
import { ConnectedTicketOriginAnchor } from "../Containers/Origin/TicketOrigin";
import DealStatusDropdown from "../Components/Deals/DealStatusDropdown";
import DealStatusProfile from "../Components/Deals/DealStatusProfile";
// import { ConnectedSubscriptionTypeProfile } from "../Components/SubscriptionType/SubscriptionTypeProfile";
import { ClientStatusDropdown } from "../Components/Clients/Clients";
import { ClientStatusProfile } from "../Components/Clients/ClientProfile";
import { FormattedMessage } from "react-intl";
import { MilSecToMinute } from "./MiscHelper";
import {
  faBell,
  faBellSlash,
  faEyeSlash,
  faEye,
  faSitemap,
  faUserFriends,
  faBuilding,
  faUsers,
  faFileContract,
  faHandshake,
  faTicketSimple,
  faSquareParking,
  faSuitcase,
  faDrawCircle,
  faUser,
  faUserTie,
  faListCheck,
  faPhone,
  faUserClock,
  faCalendarExclamation,
  faMessagesDollar,
  faPhoneAlt,
  faCoin,
  faMemoCircleCheck,
  faEnvelope,
  faSignature
  // faEnvelope,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContactStatusProfile } from "../Components/Contacts/ContactProfile";
import { ConnectedTaskOriginAnchor } from "../Containers/Origin/TaskOrigin";
import { ConnectedCallOriginAnchor } from "../Containers/Origin/CallOrigin";
import { ConnectedInterventionOriginAnchor } from "../Containers/Origin/InterventionOrigin";
import {
  PostStatusDropdown,
  PostStatusProfile,
  SocialAccountComponentForSocialPostGrid
} from "../Containers/SocialPosts/SocialPostsHelper";

import {
  clientPropFields,
  originPropFields
} from "../Components/CGrid/GridDefinitions";
// import SocialAccountComponent from "../Components/SocialAccountComponenent/SocialAccountComponent";
import AutomationStatusProfile, {
  AutomationStatusDropdown
} from "../Containers/Automation/AutomationStatusProfile";
import { ConnectedSubscriptionOriginAnchor } from "../Containers/Origin/SubscriptionOrigin";
import {
  AssociationTypeEnum,
  // DocTemplateTargetProfileComp,
  DocTemplateAssociateToGridComponent
} from "../Containers/Documents/DocTemplates/DocTemplatesHelper";
import { ConnectedTaskTypeProfile } from "../Components/TaskType/TaskTypeProfile";
import { useSpaceQuery } from "./IOClient";
import { useConnectedSchemaGetter } from "./HelperHooks";

// import { TicketRatingComponent } from "../Components/TicketRatingAccordion/TicketRatingAccordion";

export const createFeedbackQueueStatusFilter = (overrides) => {
  return {
    titleId: "STATUS",
    field: "Status",
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    defaultValue: 1,
    applyOnChange: true,
    component: FeedbackQueueStatusDropdown,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"STATUS_EQUALS"} />{" "}
          {value === "Scheduled" ? "Agendado" : "Falhado"}
        </div>
      );
    },
    ...overrides
  };
};

export const createProjectStatusFilter = (overrides) => {
  return {
    titleId: "STATUS",
    field: "PipelineStatus/Status",
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    defaultValue: 1,
    applyOnChange: true,
    component: ProjectStatusDropdown,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"STATUS_EQUALS"} />{" "}
          <ProjectStatusProfile project={{ Status: value }} />
        </div>
      );
    },
    ...overrides
  };
};

export const createEmailSignatureStatusFilter = (overrides) => {
  return {
    titleId: "STATUS",
    field: "Signature/Attributes",
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    defaultValue: 1,
    applyOnChange: true,
    component: PostStatusDropdown,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"STATUS_EQUALS"} />{" "}
          <PostStatusProfile post={{ Status: value }} />
        </div>
      );
    },
    ...overrides
  };
};

export const createPostStatusFilter = (overrides) => {
  return {
    titleId: "STATUS",
    field: "SocialPost/Status",
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    defaultValue: 1,
    applyOnChange: true,
    component: PostStatusDropdown,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"STATUS_EQUALS"} />{" "}
          <PostStatusProfile post={{ Status: value }} />
        </div>
      );
    },
    ...overrides
  };
};

export const createSociaAccountFilter = (overrides) => {
  return {
    titleId: "ACCOUNT",
    field: "Social/Id",
    icon: <FontAwesomeIcon icon={faSuitcase} />,
    defaultValue: 1,
    applyOnChange: true,
    component: SocialAccountDropdown,
    DescriptionComponent: ({ item, value }) => {
      const socialData = useSocialAccount(value);
      return (
        <div>
          <FormattedMessage id={"ACCOUNT_IS"} />{" "}
          <SocialAccountComponentForSocialPostGrid
            style={{ width: 16 }}
            data={socialData}
          />
        </div>
      );
    },
    ...overrides
  };
};

export const createTaskStatusFilter = (overrides) => {
  return {
    ...createProjectStatusFilter(),
    field: "PipelineStatus/Status",
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    ...overrides
  };
};

export const createContractStatusFilter = (overrides) => {
  return {
    titleId: "STATUS",
    field: "PipelineStatus/Status",
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    defaultValue: 1,
    applyOnChange: true,
    component: ContractStatusDropdown,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"STATUS_EQUALS"} />{" "}
          <ContractStatusProfile entity={{ Status: value }} />
        </div>
      );
    },
    ...overrides
  };
};

export const createSubscriptionStatusFilter = (overrides) => {
  return {
    titleId: "STATUS",
    field: "PipelineStatus/Status",
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    defaultValue: 1,
    applyOnChange: true,
    component: ContractStatusDropdown,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"STATUS_EQUALS"} />{" "}
          <ContractStatusProfile entity={{ Status: value }} />
        </div>
      );
    },
    ...overrides
  };
};

export const createDealStatusFilter = (overrides) => {
  return {
    titleId: "STATUS",
    field: "PipelineStatus/Status",
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    defaultValue: 1,
    applyOnChange: true,
    component: DealStatusDropdown,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"STATUS_EQUALS"} />{" "}
          <DealStatusProfile status={value} />
        </div>
      );
    },
    ...overrides
  };
};

export const createContactStatusFilter = (overrides) => {
  return {
    titleId: "STATUS",
    field: "Contact/Attributes",
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    defaultValue: 1,
    applyOnChange: true,
    component: (props) => (
      <ContactStatusDropdown className="fs-12 text-truncate" {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"STATUS_EQUALS"} />{" "}
          <ContactStatusProfile status={value} />
        </div>
      );
    },
    ...overrides
  };
};

export const createAutomationStatusFilter = (overrides) => {
  return {
    titleId: "STATUS",
    field: "Automation/IsActive",
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    defaultValue: true,
    applyOnChange: true,
    component: (props) => (
      <AutomationStatusDropdown className="fs-12 text-truncate" {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"STATUS_EQUALS"} />{" "}
          <AutomationStatusProfile status={value} />
        </div>
      );
    },
    ...overrides
  };
};

export const createDocumentTypeFilter = (overrides) => {
  return {
    titleId: "DOCUMENT_TYPE",
    field: "DocumentType/Id",
    applyOnChange: true,
    component: DocumentTDTypeDropdown,
    DescriptionComponent: ({ item, value }) => {
      const docType = useDocumentType(value);
      const Result = useConnectedSchemaGetter(value, spaceDocumentTypesSchema);

      if (Result) return Result;
      return (
        <div>
          <FormattedMessage id={"DOCUMENT_TYPE_EQUALS"} />
          <span className="ml-2">{docType?.Name}</span>
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceDocumentTypesSchema,
    ...overrides
  };
};

// export const

export const createDynamicTemplateAssociationFilter = (overrides) => {
  const {
    field,
    value = 1,
    text,
    DescriptionComponent,
    icon,
    ...rest
  } = overrides;

  return {
    type: "number",
    titleId: text,
    field,
    icon: <FontAwesomeIcon icon={icon || faSitemap} />,
    DescriptionComponent: DescriptionComponent,
    resolvedFilter: {
      field,
      value,
      operator: "eq",
      data: {
        titleId: text,
        field,
        applyOnChange: true
      }
    },
    component: (props) => <ReadOptionsDropdown {...props} />,
    ...rest
  };
};

export const createTemplateAccountAssociationFilter = (overrides) => {
  return createDynamicTemplateAssociationFilter({
    ...overrides,
    text: "ACCOUNTS",
    icon: faSuitcase,
    field: "Associated/Clients",
    DescriptionComponent: () => {
      return (
        <div className="d-flex align-items-center">
          <span className="mr-2">
            <FormattedMessage id="ASSOCIATED_TO" />
          </span>
          <DocTemplateAssociateToGridComponent
            associate={AssociationTypeEnum.Client}
          />
        </div>
      );
    }
  });
};

export const createTemplateDealAssociationFilter = (overrides) => {
  return createDynamicTemplateAssociationFilter({
    ...overrides,
    text: "DEALS",
    icon: faMessagesDollar,
    field: "Associated/Deals",
    DescriptionComponent: () => {
      return (
        <div className="d-flex align-items-center">
          <span className="mr-2">
            <FormattedMessage id="ASSOCIATED_TO" />
          </span>
          <DocTemplateAssociateToGridComponent
            associate={AssociationTypeEnum.Deal}
          />
        </div>
      );
    }
  });
};
export const createTemplateContractAssociationFilter = (overrides) => {
  return createDynamicTemplateAssociationFilter({
    ...overrides,
    icon: faFileContract,
    text: "CONTRACTS",
    field: "Associated/Contracts",
    DescriptionComponent: () => {
      return (
        <div className="d-flex align-items-center">
          <span className="mr-2">
            <FormattedMessage id="ASSOCIATED_TO" />
          </span>
          <DocTemplateAssociateToGridComponent
            associate={AssociationTypeEnum.Contract}
          />
        </div>
      );
    }
  });
};
export const createTemplateSubscriptionAssociationFilter = (overrides) => {
  return createDynamicTemplateAssociationFilter({
    ...overrides,
    text: "SUBSCRIPTIONS",
    icon: faCalendarExclamation,
    field: "Associated/Subscriptions",
    DescriptionComponent: () => {
      return (
        <div className="d-flex align-items-center">
          <span className="mr-2">
            <FormattedMessage id="ASSOCIATED_TO" />
          </span>
          <DocTemplateAssociateToGridComponent
            associate={AssociationTypeEnum.Subscription}
          />
        </div>
      );
    }
  });
};
export const createTemplateCallAssociationFilter = (overrides) => {
  return createDynamicTemplateAssociationFilter({
    ...overrides,
    text: "CALLS",
    icon: faPhoneAlt,
    field: "Associated/Calls",
    DescriptionComponent: () => {
      return (
        <div className="d-flex align-items-center">
          <span className="mr-2">
            <FormattedMessage id="ASSOCIATED_TO" />
          </span>
          <DocTemplateAssociateToGridComponent
            associate={AssociationTypeEnum.Call}
          />
        </div>
      );
    }
  });
};
export const createTemplateCaseAssociationFilter = (overrides) => {
  return createDynamicTemplateAssociationFilter({
    ...overrides,
    text: "CASES",
    icon: faTicketSimple,
    field: "Associated/Tickets",
    DescriptionComponent: () => {
      return (
        <div className="d-flex align-items-center">
          <span className="mr-2">
            <FormattedMessage id="ASSOCIATED_TO" />
          </span>
          <DocTemplateAssociateToGridComponent
            associate={AssociationTypeEnum.Ticket}
          />
        </div>
      );
    }
  });
};
export const createTemplateProjectsAssociationFilter = (overrides) => {
  return createDynamicTemplateAssociationFilter({
    ...overrides,
    text: "PROJECTS",
    icon: faSquareParking,
    field: "Associated/Projects",
    DescriptionComponent: () => {
      return (
        <div className="d-flex align-items-center">
          <span className="mr-2">
            <FormattedMessage id="ASSOCIATED_TO" />
          </span>
          <DocTemplateAssociateToGridComponent
            associate={AssociationTypeEnum.Project}
          />
        </div>
      );
    }
  });
};
export const createTemplateTaskAssociationFilter = (overrides) => {
  return createDynamicTemplateAssociationFilter({
    ...overrides,
    text: "TASKS",
    icon: faListCheck,
    field: "Associated/Tasks",
    DescriptionComponent: () => {
      return (
        <div className="d-flex align-items-center">
          <span className="mr-2">
            <FormattedMessage id="ASSOCIATED_TO" />
          </span>
          <DocTemplateAssociateToGridComponent
            associate={AssociationTypeEnum.Task}
          />
        </div>
      );
    }
  });
};
export const createTemplateTimesAssociationFilter = (overrides) => {
  return createDynamicTemplateAssociationFilter({
    ...overrides,
    text: "TIMES",
    icon: faUserClock,
    field: "Associated/Times",
    DescriptionComponent: () => {
      return (
        <div className="d-flex align-items-center">
          <span className="mr-2">
            <FormattedMessage id="ASSOCIATED_TO" />
          </span>
          <DocTemplateAssociateToGridComponent
            associate={AssociationTypeEnum.Time}
          />
        </div>
      );
    }
  });
};

export const createInterventionTypeFilter = (overrides) => {
  return {
    titleId: "TIME_TYPE",
    field: "Timecharge/EntityTypeId",
    defaultValue: 1,
    applyOnChange: true,
    component: (props) => (
      <InterventionTypeDropdown {...props} All></InterventionTypeDropdown>
    ),
    DescriptionComponent: ({ item, value }) => {
      const interventionType = useInterventionType(value);
      const Result = useConnectedSchemaGetter(
        value,
        spaceInterventionTypeSchema
      );

      if (Result) return Result;
      return (
        <div>
          <FormattedMessage id={"TIME_TYPE_EQUALS"} />
          <InterventionTypeProfile interventionType={interventionType} />"
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceInterventionTypeSchema,
    All: true,
    ...overrides
  };
};

export const createProjectTypeFilter = (overrides) => {
  return {
    titleId: "PROJECT_TYPE",
    field: "Project/EntityTypeId",
    defaultValue: null,
    applyOnChange: true,
    component: ProjectTypeDropdown,
    DescriptionComponent: ({ item, value }) => {
      const Result = useConnectedSchemaGetter(
        value,
        spaceProjectCategorySchema
      );

      if (Result) return Result;

      return (
        <div className="d-flex  flex-column">
          <FormattedMessage id={"PROJECT_TYPE_EQUALS"} />
          <ConnectedProjectTypeProfile Projecttype={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceProjectsSchema,
    ...overrides
  };
};

export const createTaskTypeFilter = (overrides) => {
  return {
    titleId: "TASK_TYPE",
    field: "Task/EntityTypeId",
    defaultValue: null,
    applyOnChange: true,
    component: TaskTypeDropdown,
    DescriptionComponent: ({ item, value }) => {
      const Result = useConnectedSchemaGetter(value, spaceTaskTypeSchema);

      if (Result) return Result;

      return (
        <div className="d-flex flex-column ">
          <FormattedMessage id={"TASK_TYPE_EQUALS"} />
          <ConnectedTaskTypeProfile TaskType={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceTasksSchema,
    ...overrides
  };
};

export const createContractTypeFilter = (overrides) => {
  return {
    titleId: "TYPE_CONTRACT",
    field: "Contract/EntityTypeId",
    defaultValue: null,
    applyOnChange: true,
    component: ContractTypeDropdown,
    DescriptionComponent: ({ item, value }) => {
      const Result = useConnectedSchemaGetter(value, spaceContractTypeSchema);

      if (Result) return Result;

      return (
        <div>
          <FormattedMessage id={"TYPE_CONTRACT_EQUALS"} />
          <ConnectedContractTypeProfile contracttype={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceContractTypeSchema,
    ...overrides
  };
};

export const createSubctriptionTypeFilter = (overrides) => {
  return {
    titleId: "TYPE_SUBSCRIPTION",
    field: "Subscription/EntityTypeId",
    defaultValue: null,
    applyOnChange: true,
    component: SubscriptionTypeDropdown,
    DescriptionComponent: ({ item, value }) => {
      const resolvedType = useSubscriptionType(value);

      const Result = useConnectedSchemaGetter(
        value,
        spaceSubscriptionTypeSchema
      );

      if (Result) return Result;

      return (
        <div>
          <FormattedMessage id={"TYPE_SUBSCRIPTION"} />{" "}
          <FormattedMessage id={"EQUALS"} /> {resolvedType?.Name}
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceSubscriptionTypeSchema,
    ...overrides
  };
};

const ConnectedClientTypeDescription = ({ type }) => {
  const Type = useClientType(type);

  const Result = useConnectedSchemaGetter(type, spaceClientTypeSchema);

  if (Result) return Result;

  return <span className="text-truncate">{Type && Type.Name}</span>;
};

export const createClientTypeFilter = (overrides) => {
  return {
    titleId: "TYPE",
    field: "ClientEntityType/EntityTypeId",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faSuitcase} />,
    component: (props) => <ClientTypeDropdown canAdd={false} {...props} />,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"CLIENT_TYPE_EQUALS"} />{" "}
          <ConnectedClientTypeDescription type={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceClientTypeSchema,
    ...overrides
  };
};

export const createTicketTagFilter = (overrides) => {
  return {
    titleId: "TAG",
    field: "Tag/Id",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faTicketSimple} />,
    component: (props) => (
      <TicketTagTDropdown dontShowAddButton allTags canAdd={false} {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      const caseTag = useTicketType(value);
      const Result = useConnectedSchemaGetter(
        value,
        spaceTicketTagsSchema,
        (v) => v.split("-")[0]
      );

      if (Result) return Result;
      return (
        <div className="d-flex flex-wrap">
          <FormattedMessage id={"CASE_TAG"} />{" "}
          <FormattedMessage id={"EQUALS"} /> {caseTag?.Name}
        </div>
      );
    },
    ListItemComponent: ({ item }) => <ConnectedTicketTagItem category={item} />,
    createResolvedApiFilter: (value) => {
      return `Tag/Id eq '${value.split("-")[0]}'`;
    },
    createAdvanvedFilterValue: (value) => value.split("-")[0],
    getSchemaFromCompleteValue: (value) => spaceTicketTagsSchema,
    ...overrides
  };
};

export const createSubscriptionTagFilter = (overrides) => {
  return {
    titleId: "TAG",
    field: "Tag/Id",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faCalendarExclamation} />,
    component: (props) => (
      <SubscriptionTagTDropdown allTags canAdd={false} {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      const caseTag = useSubscriptionTag(value);
      const Result = useConnectedSchemaGetter(
        value,
        spaceSubscriptionTagsSchema,
        (v) => v.split("-")[0]
      );

      if (Result) return Result;
      return (
        <div className="d-flex flex-wrap">
          <FormattedMessage id={"SUBSCRIPTION_TAG"} />{" "}
          <FormattedMessage id={"EQUALS"} /> {caseTag?.Name}
        </div>
      );
    },
    ListItemComponent: ({ item }) => (
      <ConnectedSubscriptionTagItem category={item} />
    ),
    createResolvedApiFilter: (value) => {
      return `Tag/Id eq '${value.split("-")[0]}'`;
    },
    createAdvanvedFilterValue: (value) => value.split("-")[0],
    getSchemaFromCompleteValue: (value) => spaceSubscriptionTagsSchema,
    ...overrides
  };
};

export const createDealTagFilter = (overrides) => {
  return {
    titleId: "TAG",
    field: "Tag/Id",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faTicketSimple} />,
    component: (props) => (
      <DealTagTDropdown dontShowAddButton allTags canAdd={false} {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      const caseTag = useDealTag(value);
      const Result = useConnectedSchemaGetter(
        value,
        spaceDealsTagsSchema,
        (v) => v.split("-")[0]
      );

      if (Result) return Result;
      return (
        <div className="d-flex flex-wrap">
          <FormattedMessage id={"DEAL_TAG"} />{" "}
          <FormattedMessage id={"EQUALS"} /> {caseTag?.Name}
        </div>
      );
    },
    ListItemComponent: ({ item }) => <ConnectedDealTagItem category={item} />,
    createResolvedApiFilter: (value) => {
      return `Tag/Id eq '${value.split("-")[0]}'`;
    },
    createAdvanvedFilterValue: (value) => value.split("-")[0],
    getSchemaFromCompleteValue: (value) => spaceDealsTagsSchema,
    ...overrides
  };
};
export const createProjectTagFilter = (overrides) => {
  return {
    titleId: "TAG",
    field: "Tag/Id",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faTicketSimple} />,
    component: (props) => (
      <ProjectTagTDropdown
        dontShowAddButton
        allTags
        canAdd={false}
        {...props}
      />
    ),
    DescriptionComponent: ({ item, value }) => {
      const caseTag = useProjectTag(value);
      const Result = useConnectedSchemaGetter(
        value,
        spaceProjectTagsSchema,
        (v) => v.split("-")[0]
      );

      if (Result) return Result;
      return (
        <div className="d-flex flex-wrap">
          <FormattedMessage id={"PROJECT_TAG"} />{" "}
          <FormattedMessage id={"EQUALS"} /> {caseTag?.Name}
        </div>
      );
    },
    ListItemComponent: ({ item }) => (
      <ConnectedProjectTagItem category={item} />
    ),
    createResolvedApiFilter: (value) => {
      return `Tag/Id eq '${value.split("-")[0]}'`;
    },
    createAdvanvedFilterValue: (value) => value.split("-")[0],
    getSchemaFromCompleteValue: (value) => spaceProjectTagsSchema,
    ...overrides
  };
};
export const createTaskTagFilter = (overrides) => {
  return {
    titleId: "TAG",
    field: "Tag/Id",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faTicketSimple} />,
    component: (props) => (
      <TaskTagTDropdown dontShowAddButton allTags canAdd={false} {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      const caseTag = useTaskTag(value);
      const Result = useConnectedSchemaGetter(
        value,
        spaceTaskTagsSchema,
        (v) => v.split("-")[0]
      );

      if (Result) return Result;
      return (
        <div className="d-flex flex-wrap">
          <FormattedMessage id={"TASK_TAG"} />{" "}
          <FormattedMessage id={"EQUALS"} /> {caseTag?.Name}
        </div>
      );
    },
    ListItemComponent: ({ item }) => <ConnectedTaskTagItem category={item} />,
    createResolvedApiFilter: (value) => {
      return `Tag/Id eq '${value.split("-")[0]}'`;
    },
    createAdvanvedFilterValue: (value) => value.split("-")[0],
    getSchemaFromCompleteValue: (value) => spaceTaskTagsSchema,
    ...overrides
  };
};

export const createCallTagFilter = (overrides) => {
  return {
    titleId: "TAG",
    field: "Tag/Id",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faTicketSimple} />,
    component: (props) => (
      <CallTagTDropdown dontShowAddButton allTags canAdd={false} {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      const caseTag = useCallTag(value);
      const Result = useConnectedSchemaGetter(
        value,
        spaceCallsTagsSchema,
        (v) => v.split("-")[0]
      );

      if (Result) return Result;
      return (
        <div className="d-flex flex-wrap">
          <FormattedMessage id={"CALL_TAG"} />{" "}
          <FormattedMessage id={"EQUALS"} /> {caseTag?.Name}
        </div>
      );
    },
    ListItemComponent: ({ item }) => <ConnectedCallTagItem category={item} />,
    createResolvedApiFilter: (value) => {
      return `Tag/Id eq '${value.split("-")[0]}'`;
    },
    createAdvanvedFilterValue: (value) => value.split("-")[0],
    getSchemaFromCompleteValue: (value) => spaceCallsTagsSchema,
    ...overrides
  };
};
export const createContractTagFilter = (overrides) => {
  return {
    titleId: "TAG",
    field: "Tag/Id",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faTicketSimple} />,
    component: (props) => (
      <ContractTagTDropdown
        dontShowAddButton
        allTags
        canAdd={false}
        {...props}
      />
    ),
    DescriptionComponent: ({ item, value }) => {
      const caseTag = useContractTag(value);
      const Result = useConnectedSchemaGetter(
        value,
        spaceContractTagsSchema,
        (v) => v.split("-")[0]
      );

      if (Result) return Result;
      return (
        <div className="d-flex flex-wrap">
          <FormattedMessage id={"CONTRACT_TAG"} />{" "}
          <FormattedMessage id={"EQUALS"} /> {caseTag?.Name}
        </div>
      );
    },
    ListItemComponent: ({ item }) => (
      <ConnectedContractTagItem category={item} />
    ),
    createResolvedApiFilter: (value) => {
      return `Tag/Id eq '${value.split("-")[0]}'`;
    },
    createAdvanvedFilterValue: (value) => value.split("-")[0],
    getSchemaFromCompleteValue: (value) => spaceContractTagsSchema,
    ...overrides
  };
};
export const createClientTagFilter = (overrides) => {
  return {
    titleId: "TAG",
    field: "Tag/Id",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faTicketSimple} />,
    component: (props) => (
      <ClientTagTDropdown dontShowAddButton allTags canAdd={false} {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      const caseTag = useClientTag(value);
      const Result = useConnectedSchemaGetter(
        value,
        spaceClientTagsSchema,
        (v) => v.split("-")[0]
      );

      if (Result) return Result;
      return (
        <div className="d-flex flex-wrap">
          <FormattedMessage id={"ACCOUNT_TAG"} />{" "}
          <FormattedMessage id={"EQUALS"} /> {caseTag?.Name}
        </div>
      );
    },
    ListItemComponent: ({ item }) => <ConnectedClientTagItem category={item} />,
    createResolvedApiFilter: (value) => {
      return `Tag/Id eq '${value.split("-")[0]}'`;
    },
    createAdvanvedFilterValue: (value) => value.split("-")[0],
    getSchemaFromCompleteValue: (value) => spaceClientTagsSchema,
    ...overrides
  };
};

export const createUserFilter = (overrides) => {
  return {
    titleId: "USER",
    field: "User",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faUser} />,
    component: (props) => (
      <UserDropdown isPopup {...props} allAccounts={true} />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"ASSIGNED_TO"} />{" "}
          <ConnectedAccountProfile maxWidth account={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => {
      return accountSchema;
    },
    getNormalizationSchema: () => {
      return { Account: fk(accountSchema) };
    },
    ...overrides
  };
};

export const createManagerFilter = (overrides) => {
  return {
    titleId: "MANAGER",
    field: "Assigned/Id",
    defaultValue: null,
    icon: <FontAwesomeIcon icon={faUser} />,
    applyOnChange: true,
    component: (props) => (
      <UserDropdown isPopup {...props} allAccounts={true} />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"MANAGER"} />{" "}
          <ConnectedAccountProfile maxWidth account={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => {
      return accountSchema;
    },
    getNormalizationSchema: () => {
      return { Account: fk(accountSchema) };
    },
    ...overrides
  };
};

export const createClientFilter = (overrides) => {
  return {
    titleId: "ACCOUNT",
    field: "Client",
    propField: "Client",
    defaultValue: null,
    icon: <FontAwesomeIcon icon={faSuitcase} />,
    applyOnChange: true,
    component: (props) => (
      <ClientDropdown allClients={true} isPopup {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"ACCOUNT_IS"} />{" "}
          <ConnectedClientOriginAnchor client={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceClientSchema,
    ...overrides
  };
};

export const createClientAtributesFilter = (overrides) => {
  return {
    titleId: "CLIENT_STATUS",
    field: "Client/Attributes",
    icon: <FontAwesomeIcon icon={faSuitcase} />,
    defaultValue: null,
    applyOnChange: true,
    component: (props) => (
      <ClientStatusDropdown className="fs-12 text-truncate" {...props} />
    ),
    //   <ClientDropdown allClients={true} isPopup {...props} />,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"CLIENT_STATUS_IS"} />{" "}
          <ClientStatusProfile item={item} value={value} />
        </div>
      );
    },
    // getSchemaFromCompleteValue: value => spaceClientSchema,
    ...overrides
  };
};

export const createProjectFilter = (overrides) => {
  return {
    titleId: "PROJECT",
    field: "Project",
    defaultValue: null,
    icon: <FontAwesomeIcon icon={faSquareParking} />,
    applyOnChange: true,
    component: (props) => (
      <ProjectDropdown allProjects={true} isPopup {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"PROJECT_IS"} />
          <ConnectedProjectOriginAnchor project={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceProjectsSchema,
    ...overrides
  };
};

// export const createEmailConversationFilter = (overrides) => {
//   return {
//     titleId: "EMAIL_CONVERSATION",
//     field: "EmailConversation",
//     defaultValue: null,
//     icon: <FontAwesomeIcon icon={faEnvelope} />,
//     applyOnChange: true,
//     component: (props) => <InterventionDropdown allTimes isPopup {...props} />,
//     DescriptionComponent: ({ item, value }) => {
//       return (
//         <div>
//           <FormattedMessage id={"EMAIL_CONVERSATION_IS"} />
//           {/* <ConnectedInterventionOriginAnchor intervention={value} /> */}
//         </div>
//       );
//     },
//     getSchemaFromCompleteValue: (value) => spaceEmailConversationSchema,
//     ...overrides,
//   };
// };

export const createInterventionFilter = (overrides) => {
  return {
    titleId: "TIME",
    field: "TimeCharge",
    defaultValue: null,
    icon: <FontAwesomeIcon icon={faUserClock} />,
    applyOnChange: true,
    component: (props) => <InterventionDropdown allTimes isPopup {...props} />,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"TIME_IS"} />
          <ConnectedInterventionOriginAnchor intervention={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceInterventionsSchema,
    ...overrides
  };
};

export const createCallFilter = (overrides) => {
  return {
    titleId: "CALL",
    field: "Call",
    defaultValue: null,
    icon: <FontAwesomeIcon icon={faPhone} />,
    applyOnChange: true,
    component: (props) => <CallDropdown allCalls isPopup {...props} />,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"CALL_IS"} />
          <ConnectedCallOriginAnchor call={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceCallsSchema,
    ...overrides
  };
};

export const createTaskFilter = (overrides) => {
  return {
    titleId: "TASK",
    field: "Task",
    defaultValue: null,
    icon: <FontAwesomeIcon icon={faListCheck} />,
    applyOnChange: true,
    component: (props) => <TaskDropdown allTasks isPopup {...props} />,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"TASK_IS"} />
          <ConnectedTaskOriginAnchor task={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceTasksSchema,
    ...overrides
  };
};

export const createTicketFilter = (overrides) => {
  return {
    titleId: "CASE",
    field: "Ticket",
    defaultValue: null,
    icon: <FontAwesomeIcon icon={faTicketSimple} />,
    applyOnChange: true,
    component: (props) => (
      <TicketDropdown allTickets={true} isPopup {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"CASE_IS"} />
          <ConnectedTicketOriginAnchor ticket={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceTicketsSchema,
    ...overrides
  };
};

export const createDealFilter = (overrides) => {
  return {
    titleId: "DEAL",
    field: "Deal",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faHandshake} />,
    component: (props) => <DealDropdown allDeals={true} isPopup {...props} />,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"DEAL_IS"} />
          <ConnectedDealOriginAnchor deal={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceDealsSchema,
    ...overrides
  };
};

export const createContractFilter = (overrides) => {
  return {
    titleId: "CONTRACT",
    field: "Contract",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faFileContract} />,
    component: (props) => (
      <ContractDropdown allContracts={true} isPopup {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"CONTRACT_IS"} />
          <ConnectedContractOriginAnchor contract={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceClientSchema,
    ...overrides
  };
};

export const createSubscriptionFilter = (overrides) => {
  return {
    titleId: "SUBSCRIPTION",
    field: "Subscription",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faCalendarExclamation} />,
    component: (props) => (
      <SubscriptionDropdown allSubscriptions={true} isPopup {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"SUBSCRIPTION_IS"} />
          <ConnectedSubscriptionOriginAnchor Subscription={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceClientSchema,
    ...overrides
  };
};

export const createPipelineStatusFilter = (overrides) => {
  const { pipelineSettings, ...rest } = overrides;

  const { type, pipelineStatusField, StatusProfileComponent } =
    pipelineSettings;

  return {
    propField: "PipelineStatus",
    titleId: "PIPELINE_STATUS",
    field: pipelineStatusField,
    defaultValue: null,
    applyOnChange: true,
    component: (props) => (
      <ServerAwarePipelineStatusDropdown
        StatusProfile={StatusProfileComponent}
        Type={type}
        isPopup
        {...props}
      />
    ),
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    DescriptionComponent: ({ item, value }) => {
      const pipelineStatus = usePipelineStatus(value);
      if (pipelineStatus)
        return (
          <div>
            <FormattedMessage id={"PIPELINE_STATUS"} /> {pipelineStatus.Name}
          </div>
        );

      return (
        <div>
          <FormattedMessage id={"PIPELINE_STATUS"} />:
        </div>
      );
    },
    ...rest
  };
};

export const createUpgradedPipelineStatusFilter = (overrides) => {
  const { pipelineSettings, ...rest } = overrides;

  const { type, pipelineStatusField, StatusProfileComponent } =
    pipelineSettings;

  return {
    propField: "PipelineStatus",
    titleId: "PIPELINE_STATUS",
    field: pipelineStatusField,
    defaultValue: null,
    applyOnChange: true,
    component: (props) => (
      <ServerAwareUpgradedPipelineStatusDropdown
        StatusProfile={StatusProfileComponent}
        Type={type}
        isPopup
        {...props}
      />
    ),
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    DescriptionComponent: ({ item, value }) => {
      useSpaceQuery(`query/PipelineStatus/filter?Type=${type}`, [
        spacePipelineSchema
      ]);
      const pStatus = usePipelineStatus(value);
      const pipelines = useAllPipelines() || {};

      let pipeName = null;
      for (const key in pipelines) {
        if (Object.hasOwnProperty.call(pipelines, key)) {
          const pipe = pipelines[key];
          const PipelineStatus = pipe?.PipelineStatus || [];

          if (PipelineStatus.includes(parseInt(value))) {
            pipeName = pipe.Name;
          }
        }
      }

      if (pStatus)
        return (
          <div>
            <FormattedMessage id={"PIPELINE_STATUS"} />{" "}
            {pipeName ? `${pipeName} - ${pStatus.Name}` : pStatus.Name}
          </div>
        );

      return (
        <div>
          <FormattedMessage id={"PIPELINE_STATUS"} />:
        </div>
      );
    },
    ...rest
  };
};

export const createIndirectClientFilter = (overrides) => {
  const base = createClientFilter({
    propField: undefined,
    propFields: clientPropFields,
    ...overrides
  });
  const { field } = base;

  return {
    createResolvedApiFilter: (value) => {
      return `${field} eq '${value}'`;
    },
    icon: <FontAwesomeIcon icon={faSuitcase} />,
    ...base
  };
};

// export const createResolvedCallTicketsApiFilter = (value) =>{
// 	return `(Parent/Id eq '${value}') or (IndirectProject/Id eq '${value}')`;
// }

export const createIndirectProjectFilter = (overrides) => {
  const base = createProjectFilter(overrides);
  const { field } = base;
  return {
    ...base,
    icon: <FontAwesomeIcon icon={faSquareParking} />,
    createResolvedApiFilter: (value) => {
      return `${field} eq '${value}'`;
    }
  };
};

export const createIndirectTicketFilter = (overrides) => {
  const base = createTicketFilter(overrides);
  const { field } = base;
  return {
    ...base,
    icon: <FontAwesomeIcon icon={faTicketSimple} />,
    createResolvedApiFilter: (value) => {
      return `${field} eq '${value}'`;
    }
  };
};

export const createIndirectDealFilter = (overrides) => {
  const base = createDealFilter(overrides);
  const { field } = base;
  return {
    ...base,
    icon: <FontAwesomeIcon icon={faHandshake} />,
    createResolvedApiFilter: (value) => {
      return `${field} eq '${value}'`;
    }
  };
};

export const createIndirectContractFilter = (overrides) => {
  const base = createContractFilter(overrides);
  const { field } = base;

  return {
    ...base,
    icon: <FontAwesomeIcon icon={faFileContract} />,
    createResolvedApiFilter: (value) => {
      return `${field} eq '${value}'`;
    }
  };
};

export const createContactFilter = (overrides) => {
  return {
    propField: "Contact",
    titleId: "CONTACT",
    field: "Contact",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faUserTie} />,
    component: (props) => (
      <ContactDropdown isPopup {...props} allContacts={true} />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"CONTACT_IS"} />
          <ConnectedContactOriginAnchor contact={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceContactSchema,
    ...overrides
  };
};

export const createDepartmentFilter = (overrides) => {
  return {
    titleId: "DEPARTMENT",
    field: "Department/Id",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faUsers} />,
    component: (props) => (
      <DepartmentDropdown allDepartments={true} isPopup {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"DEPARTMENT_IS"} />
          <ConnectedDepartmentProfile noManager department={value} />
        </div>
      );
    },
    normalizationSchema: [spaceDepartmentSchema],
    getEntityEndpoint: (spaceId, entityId) =>
      `spaces/${spaceId}/query/departments?filterBlock=true&$filter=((Organization/Id eq ${entityId}))`,
    getSchemaFromCompleteValue: (value) => spaceDepartmentSchema,
    ...overrides
  };
};

export const createCostCenterFilter = (overrides) => {
  return {
    titleId: "COST_CENTER",
    propField: "CostCenter",
    field: "Account/CostCenterId",
    defaultValue: null,
    applyOnChange: false,
    icon: <FontAwesomeIcon icon={faCoin} />,
    component: (props) => <CostCenterBaseDropdown isPopup {...props} />,
    DescriptionComponent: ({ item, value }) => {
      const costCenter = useCostCenter(value);

      const Result = useConnectedSchemaGetter(value, spaceCostCentersSchema);

      if (Result) return Result;

      return (
        <div>
          <FormattedMessage id={"COST_CENTER"} />{" "}
          <FormattedMessage id="FILTER_EQ" /> {costCenter?.Name}
        </div>
      );
    },
    normalizationSchema: [spaceCostCentersSchema],
    getEntityEndpoint: (spaceId, entityId) =>
      `spaces/${spaceId}/query/User/CostCenter?filterBlock=true&$filter=((CostCenter/Id eq ${entityId}))`,
    getSchemaFromCompleteValue: (value) => spaceCostCentersSchema,
    ...overrides
  };
};

export const createOrganizationFilter = (overrides) => {
  return {
    propField: "Company",
    titleId: "COMPANY",
    field: "Company/Id",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faBuilding} />,
    component: (props) => (
      <OrganizationDropdown allOrganizations={true} isPopup {...props} />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"COMPANY_IS"} />
          <ConnectedOrganizationProfile noManager organization={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceOrganizationSchema,
    ...overrides
  };
};

export const createTimePauseFilter = (overrides) => {
  return {
    titleId: "PAUSES",
    field: "TimeCharge/PauseTime",
    type: "number",
    defaultValue: null,
    applyOnChange: true,
    component: (props) => MilSecToMinute(props.value),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"PAUSES"} />
          <FormattedMessage id={"FILTER_EQ"} /> {MilSecToMinute(value)}
        </div>
      );
    },

    ...overrides
  };
};

export const createTeamFilter = (overrides) => {
  return {
    titleId: "TEAM",
    field: "Team/Id",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faUserFriends} />,
    component: (props) => <TeamDropdown allTeams={true} isPopup {...props} />,
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"TEAM_IS"} />
          <ConnectedTeamProfile noManager team={value} />
        </div>
      );
    },
    getSchemaFromCompleteValue: (value) => spaceTeamSchema,
    ...overrides
  };
};

export const createLogsSenderFilter = (overrides) => {
  return {
    titleId: "ORIGIN",
    field: "Log/Sender",
    defaultValue: null,
    applyOnChange: true,

    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"ORIGIN_IS"} /> {value}
        </div>
      );
    },
    ...overrides
  };
};

export const createLogsTypeFilter = (overrides) => {
  return {
    titleId: "TYPE",
    field: "Log/Type",
    defaultValue: null,
    applyOnChange: true,

    ...overrides
  };
};

export const createLogsStatusFilter = (overrides) => {
  return {
    titleId: "STATUS",
    field: "Log/Status",
    defaultValue: null,
    applyOnChange: true,

    ...overrides
  };
};

export const createLogsCategoryFilter = (overrides) => {
  return {
    titleId: "CATEGORY",
    field: "Log/Category",
    defaultValue: null,
    applyOnChange: true,

    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"CATEGORY_IS"} /> {value}
        </div>
      );
    },
    ...overrides
  };
};

export const createOriginFilter = ({
  dropdownType: Dropdown,
  baseFieldName,
  projectName = "ProjectId",
  callName = "CallId",
  ticketName = "TicketId",

  clientName = "ClientId",
  taskName = "ProjectTaskId",
  contractName = "ClientContractId",
  dealName = "DealId",
  uniqueUrlClientName,
  uniqueUrl = false,
  ...overrides
}) => {
  const projectField = `${baseFieldName}/${projectName}`;
  const callField = `${baseFieldName}/${callName}`;
  const ticketField = `${baseFieldName}/${ticketName}`;
  const clientField = `${baseFieldName}/${clientName}`;
  const taskField = `${baseFieldName}/${taskName}`;
  const contractField = `${baseFieldName}/${contractName}`;
  const dealField = `${baseFieldName}/${dealName}`;

  const baseUrlFieldName = uniqueUrl ? baseFieldName : "Origin";

  const projectUrlField = `${baseUrlFieldName}/Project`;
  const callUrlField = `${baseUrlFieldName}/Call`;
  const ticketUrlField = `${baseUrlFieldName}/Ticket`;
  const clientUrlField = `${baseUrlFieldName}/${
    uniqueUrlClientName ? uniqueUrlClientName : "Client"
  }`;
  const taskUrlField = `${baseUrlFieldName}/Task`;
  const contractUrlField = `${baseUrlFieldName}/Contract`;
  const dealUrlField = `${baseUrlFieldName}/Deal`;

  return {
    propFields: originPropFields,
    titleId: "ORIGIN",
    field: "origin",
    defaultValue: null,
    applyOnChange: true,
    icon: <FontAwesomeIcon icon={faSitemap} />,
    mappedFields: [
      {
        urlField: projectUrlField,
        resolvedField: projectField
      },
      {
        urlField: callUrlField,
        resolvedField: callField
      },
      {
        urlField: ticketUrlField,
        resolvedField: ticketField
      },
      {
        urlField: clientUrlField,
        resolvedField: clientField
      },
      {
        urlField: taskUrlField,
        resolvedField: taskField
      },
      {
        urlField: contractUrlField,
        resolvedField: contractField
      },
      {
        urlField: dealUrlField,
        resolvedField: dealField
      }
    ],
    convertUrlValue: (urlField, value) => {
      let type = urlField.split("/")[1];

      return {
        type: type === "ClientIdBySpace" ? "Client" : type,
        data: value
      };
    },
    getField: (value) => {
      if (!value) return null;

      const { type } = value;

      switch (type) {
        case "Project":
          return {
            urlField: projectUrlField,
            resolvedField: projectField
          };

        case "Call":
          return {
            urlField: callUrlField,
            resolvedField: callField
          };

        case "Ticket":
          return {
            urlField: ticketUrlField,
            resolvedField: ticketField
          };

        case "Client":
          return {
            urlField: clientUrlField,
            resolvedField: clientField
          };

        case "Task":
          return {
            urlField: taskUrlField,
            resolvedField: taskField
          };

        case "Contract":
          return {
            urlField: contractUrlField,
            resolvedField: contractField
          };

        case "Deal":
          return {
            urlField: dealUrlField,
            resolvedField: dealField
          };

        default:
          return null;
      }
    },
    getCompleteValue: (resolvedField, value) => {
      let type;

      switch (resolvedField) {
        case projectField:
          type = "Project";
          break;

        case callField:
          type = "Call";
          break;

        case ticketField:
          type = "Ticket";
          break;

        case clientField:
          type = "Client";
          break;

        case taskField:
          type = "Task";
          break;

        case contractField:
          type = "Contract";
          break;

        case dealField:
          type = "Deal";
          break;

        default:
          break;
      }

      if (!type) return null;

      return {
        type,
        data: value
      };
    },
    getSchemaFromCompleteValue: (value) => {
      const { type } = value;

      switch (type) {
        case "Project":
          return spaceProjectsSchema;

        case "Call":
          return spaceCallsSchema;

        case "Ticket":
          return spaceTicketsSchema;

        case "Client":
          return spaceClientSchema;

        case "Task":
          return spaceTasksSchema;

        case "Contract":
          return spaceContractSchema;

        case "Deal":
          return spaceDealsSchema;

        default:
          return null;
      }
    },
    // createResolvedApiFilter: (value) => {
    //   const { type, data } = value;

    //   let field;
    //   switch (type) {
    //     case "Project":
    //       field = projectName;
    //       break;

    //     case "Client":
    //       field = clientName;
    //       break;

    //     case "Contract":
    //       field = contractName;
    //       break;

    //     default:
    //       break;
    //   }

    //   if (!field) return null;

    //   return `(${baseFieldName}/${field} eq '${data}') or (${baseFieldName}/${field} eq '${data}')`;
    // },
    getResolvedValue: (value) => value.data,
    component: (props) => (
      <Dropdown
        allClients
        allowedCalls
        allProjects
        allTasks
        allTickets
        allContracts
        allCalls
        isPopup
        {...props}
      />
    ),
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"ORIGIN_IS"} />
          <TinyInterventionTargetProfile value={value} />
        </div>
      );
    },
    ...overrides
  };
};

// const createBaseReadFollowFilter = (field, text, value) => {
//   return {
//     titleId: text,
//     field,
//     DescriptionComponent: props => <div>{text}</div>,
//     resolvedFilter: {
//       field,
//       value,
//       operator: "eq",
//       data: {
//         titleId: text,
//         field,
//         applyOnChange: true
//       }
//     }
//   };
// };

export const createReadFilter = (overrides) => {
  const { baseField, value = 1, ...rest } = overrides;

  const text = value === 1 ? "READ" : "NOT_READ";
  const field = `${baseField}/IsRead`;
  return {
    titleId: text,
    field,
    icon: <FontAwesomeIcon icon={faEye} />,
    DescriptionComponent: ({ value }) => {
      return (
        <div>
          {Number(value) === 1 ? (
            <FormattedMessage id={"READ"} />
          ) : (
            <FormattedMessage id={"NOT_READ"} />
          )}
        </div>
      );
    },
    resolvedFilter: {
      field,
      value,
      operator: "eq",
      data: {
        titleId: text,
        field,
        applyOnChange: true
      }
    },
    component: (props) => <ReadOptionsDropdown {...props} />,
    ...rest
  };
};

export const createUnreadFilter = (overrides) => {
  return createReadFilter({
    ...overrides,
    icon: <FontAwesomeIcon icon={faEyeSlash} />,
    value: 0,
    disableAdvancedFilter: true
  });
};

export const createFollowingFilter = (overrides) => {
  const { baseField, value = 1, ...rest } = overrides;

  const text = value === 1 ? "ACTIVE_NOTIFICATIONS" : "INACTIVE_NOTIFICATIONS";
  const field = `${baseField}/IsFollowing`;
  return {
    type: "number",
    titleId: text,
    icon: <FontAwesomeIcon icon={faBell} />,
    field,
    disableAdvancedFilter: true,
    DescriptionComponent: ({ value }) => {
      return (
        <div>
          {Number(value) === 1 ? (
            <FormattedMessage id={"ACTIVE_NOTIFICATIONS"} />
          ) : (
            <FormattedMessage id={"INACTIVE_NOTIFICATIONS"} />
          )}
        </div>
      );
    },
    component: (props) => <ReadOptionsDropdown {...props} />,
    resolvedFilter: {
      field,
      value,
      operator: "eq",
      data: {
        titleId: text,
        field,
        applyOnChange: true
      }
    },
    ...rest
  };
};

export const createSupportEmailFilter = (overrides) => {
  const { baseField, value = 1, ...rest } = overrides;

  const text =
    value === 1 ? "HAS_SUPPORT_EMAIL" : "DOES_NOT_HAVE_SUPPORT_EMAIL";
  const field = `${baseField}/HasInbound`;
  return {
    type: "number",
    titleId: text,
    icon: <FontAwesomeIcon icon={faEnvelope} />,
    field,
    disableAdvancedFilter: true,
    DescriptionComponent: ({ value }) => {
      return (
        <div>
          {Number(value) === 1 ? (
            <FormattedMessage id={"HAS_SUPPORT_EMAIL"} />
          ) : (
            <FormattedMessage id={"DOES_NOT_HAVE_SUPPORT_EMAIL"} />
          )}
        </div>
      );
    },
    component: (props) => <ReadOptionsDropdown {...props} />,
    resolvedFilter: {
      field,
      value,
      operator: "eq",
      data: {
        titleId: text,
        field,
        applyOnChange: true
      }
    },
    ...rest
  };
};

export const createContractSignedFilter = (overrides) => {
  const { baseField, value, ...rest } = overrides;

  const text = "CONTRACT_SIGNED";
  const field = `Contract/IsSigned`;
  return {
    titleId: text,
    icon: <FontAwesomeIcon icon={faSignature} />,
    field,
    defaultValue: null,
    applyOnChange: true,
    DescriptionComponent: ({ value }) => {
      return (
        <div>
          {Number(value) === 1 ? (
            <>
              <FormattedMessage id="CONTRACT_SIGNED" />{" "}
              <b>
                <FormattedMessage id={"YES"} />
              </b>
            </>
          ) : (
            <>
              <FormattedMessage id="CONTRACT_SIGNED" />{" "}
              <b>
                <FormattedMessage id={"NO"} />
              </b>
            </>
          )}
        </div>
      );
    },
    component: (props) => <ReadOptionsDropdown {...props} />,
    // resolvedFilter: {
    //   field,
    //   value,
    //   operator: "eq",
    //   data: {
    //     titleId: text,
    //     field
    //   }
    // },
    ...rest
  };
};

export const createIsClassifiedFilter = (overrides) => {
  const { baseField, value = 1, ...rest } = overrides;

  const text = value === 1 ? "CLASSIFIED" : "UNCLASSIFIED";
  const field = `${baseField}/IsClassified`;
  return {
    type: "number",
    titleId: text,
    icon: <FontAwesomeIcon icon={faMemoCircleCheck} />,
    field,
    DescriptionComponent: ({ value }) => {
      return (
        <div>
          {Number(value) === 1 ? (
            <FormattedMessage id={"CLASSIFIED"} />
          ) : (
            <FormattedMessage id={"UNCLASSIFIED"} />
          )}
        </div>
      );
    },
    component: (props) => <ReadOptionsDropdown {...props} />,
    resolvedFilter: {
      field,
      value,
      operator: "eq",
      data: {
        titleId: text,
        field,
        applyOnChange: true
      }
    },
    ...rest
  };
};

export const createNotClassifiedFilter = (overrides) => {
  return createIsClassifiedFilter({
    ...overrides,
    value: 0,
    disableAdvancedFilter: true
  });
};

export const createNotFollowingFilter = (overrides) => {
  return createFollowingFilter({
    ...overrides,
    icon: <FontAwesomeIcon icon={faBellSlash} />,
    value: 0
  });
};
// export const createProjectOriginFilter = overrides => {
//   return {
//     titleId: "Origem Projeto",
//     field: "Projeto",
//     defaultValue: null,
//     applyOnChange: true,
//     component: props => <ProjectDropdown isPopup {...props} />,
//     DescriptionComponent: ({ item, value }) => {
//       return (
//         <div>
//           Projeto Origem é <ConnectedProjectOriginAnchor project={value} />
//         </div>
//       );
//     },
//     ...overrides
//   };
// };

// export const createContractOriginFilter = ({
//   dropdownType: Dropdown,
//   baseFieldName,
//   contractName = "ClientContractId",
//   ...overrides
// }) => {
//   const contractField = `${baseFieldName}/${contractName}`;
//   return {
//     titleId: "Contrato Origem",
//     defaultValue: null,
//     applyOnChange: true,
//     mappedFields: [
//       {
//         urlField: "Origin/Contract",
//         resolvedField: contractField
//       }
//     ],
//     convertUrlValue: (urlField, value) => {
//       let type = urlField.split("/")[1];

//       return {
//         type,
//         data: value
//       };
//     },
//     getField: value => {
//       if (!value) return null;

//       const { type } = value;

//       switch (type) {
//         case "Contract":
//           return {
//             urlField: "Origin/Contract",
//             resolvedField: contractField
//           };

//         default:
//           return null;
//       }
//     },
//     getResolvedValue: value => value.data,
//     component: props => <Dropdown allContracts isPopup {...props} />,
//     DescriptionComponent: ({ item, value }) => {
//       return (
//         <div>
//           <div>Contrato Origem é</div>
//           <TinyInterventionTargetProfile value={value} />
//         </div>
//       );
//     },
//     ...overrides
//   };
// };
