import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { SketchPicker } from "react-color";
import classes from "./FavoriteIconPicker.module.css";
import TabStrip from "../TabStrip/TabStrip";
import { FormattedMessage, useIntl } from "react-intl";
import { IconName } from "@fortawesome/fontawesome-common-types";
import classnames from "classnames";
import "./FavoriteColorPicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, IconPrefix } from "@fortawesome/pro-light-svg-icons";
import { iconList } from "./FavoriteIconList";

export interface IFavoriteIconPickerProps {
  icon: string;
  setIcon: React.Dispatch<React.SetStateAction<string>>;
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
}

interface IColorContextValue {
  color: string;
  setColor: Dispatch<SetStateAction<string>>;
}
interface IIconContextValue {
  icon: IconName;
  setIcon: Dispatch<SetStateAction<string>>;
}

const ColorContext = React.createContext<IColorContextValue | null>(null);
const IconContext = React.createContext<IIconContextValue | null>(null);

export const useIconContext = () =>
  useContext(IconContext) as IIconContextValue;
export const useColorContext = () =>
  useContext(ColorContext) as IColorContextValue;

const IconPickerPopup: FC<IFavoriteIconPickerProps> = ({
  icon,
  setIcon,
  color,
  setColor
}) => {
  var x = icon as string;
  const colorContextValue = useMemo(() => {
    return { color, setColor };
  }, [color, setColor]);
  const iconContextValue = useMemo(() => {
    return { icon: x, setIcon } as IIconContextValue;
  }, [x, setIcon]);

  return (
    <ColorContext.Provider value={colorContextValue}>
      <IconContext.Provider value={iconContextValue}>
        <div className={classnames(classes.IconPickerPopup, "border")}>
          <TabStrip
            route={false}
            alignLeft
            headerClassName="px-2"
            contentClassName="px-2 py-2"
            items={tabs}
            className="ar-details-tabstrip h-100"
          />
        </div>
      </IconContext.Provider>
    </ColorContext.Provider>
  );
};

const presetColors: string[] = [
  "#33475B",
  "#7C98B6",
  "#FF5962",
  "#36AB80",
  "#1E73F0",
  "#F7BF19",
  "#37248F",
  "#831F12",
  "#000000",
  "#657B94",
  "#FF8066",
  "#0E6400",
  "#002B63",
  "#7E6514",
  "#5757D9",
  "#AD3B23"
];

const ColorLocalInput: FC = () => {
  const { color, setColor } = useColorContext();
  const changeColor = (e: any) => {
    typeof setColor === "function" && setColor(e.hex as string);
  };
  return (
    <div
      className={classnames(
        "d-flex align-items-center justify-content-center w-100 h-100 of-hidden SketchPickerContainer"
      )}
    >
      <SketchPicker
        disableAlpha={true}
        color={color}
        onChange={changeColor}
        presetColors={presetColors}
      />
    </div>
  );
};

const IconInput: FC = () => {
  const {
    // icon,
    setIcon
  } = useIconContext();
  const intl = useIntl();
  const [searchText, setSearchText] = useState("");
  const [resolvedSearchText, setResolvedSearchText] = useState("");
  const handleSearchOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const icons = useMemo(() => {
    if (resolvedSearchText) {
      const filterIconList = iconList.filter((e) => {
        return e.includes(resolvedSearchText);
      });
      return filterIconList.slice(0, 399);
    } else return iconList.slice(0, 399);
  }, [resolvedSearchText]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setResolvedSearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  return (
    <div className="d-flex w-100 h-100 of-hidden flex-column">
      <div className="dropdown-container mb-2 ar-checklist-input p-0 ssi-control rounded">
        <label className="d-flex pl-0 align-items-center ar-dropdown-label-search multiple">
          <div
            style={{ height: 40, color: "#1E73F0" }}
            className="px-2 d-flex pb-1 align-items-center justify-content-center"
          >
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <div className="flex-1 pb-1 of-hidden w-100">
            <input
              maxLength={1024}
              value={searchText}
              onChange={handleSearchOnChange}
              placeholder={intl.formatMessage({ id: "SEARCH" })}
              type="text"
              name="ar-dropdown"
              className="ar-dropdown-button w-100"
            />
          </div>
        </label>
      </div>
      <div className="flex-1 of-y-auto flex-box">
        <IconListComponent handlePick={setIcon} icons={icons} />
      </div>
    </div>
  );
};

interface IIconListComponentProps {
  icons: string[];
  handlePick: React.Dispatch<React.SetStateAction<string>>;
}

const IconListComponent: FC<IIconListComponentProps> = React.memo(
  ({ icons, handlePick }) => {
    const handleClick = (e: string) => {
      handlePick(e);
    };

    return (
      <>
        {icons.map((e, i) => {
          const resolvedIcon = e.split(" ") as [IconPrefix, IconName];

          return (
            <FontAwesomeIcon
              key={e}
              onClick={() => {
                handleClick(e);
              }}
							size="lg"
              className={classnames(
                "mr-1 d-inline-block text-black cursor-pointer rounded",
                classes.IconHover
              )}
              icon={resolvedIcon}
            />
          );
        })}
      </>
    );
  }
);

const tabs = [
  {
    default: true,
    name: <FormattedMessage id="COLOR" />,
    component: <ColorLocalInput />
  },
  {
    name: <FormattedMessage id="ICON" />,
    component: <IconInput />
  }
];

export default IconPickerPopup;
