import React, { useContext, useMemo } from "react";
import styles from "./NodeExtraOptions.module.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faPauseCircle,
  faPlay,
  faPlayCircle,
  faTrashAlt
} from "@fortawesome/pro-solid-svg-icons";
import { useHoverEvents } from "../../../../Origin/Origin";
import { ElementRemoveContext } from "../../AutomationDetails";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  NodeCloneFunctionContext,
  NodeGetItemContext,
  NodeStatusUpdaterContext,
  useAutomationTestRequestSidebar
} from "../../AutomationDetailsHelper";
import { TriggerTypeEnum } from "../../NodeTypes";
import { FormCanEditContext } from "../../../../../Components/Forms";

const useCurrentItemStatus = (id) => {
  const getItem = useContext(NodeGetItemContext);

  const result = useMemo(() => {
    const item = getItem(id);

    const { data } = item || {};
    const { enabled } = data || {};
    if (enabled === undefined) return true;
    else return Boolean(enabled);
  }, [getItem, id]);

  return result;
};

const EnablePauseButton = ({ id }) => {
  const toggleStatus = useContext(NodeStatusUpdaterContext);

  const status = useCurrentItemStatus(id);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        toggleStatus(id, !status);
      }}
      className={classnames(
        styles.ButtonExtra,
        "cursor-pointer d-flex justify-content-center"
      )}
    >
      <FontAwesomeIcon size="sm" icon={status ? faPauseCircle : faPlayCircle} />
    </div>
  );
};

const TrashButton = ({ id, type }) => {
  const onRemove = useContext(ElementRemoveContext);

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onRemove([{ id, type }]);
  };

  return (
    <div
      onClick={handleClick}
      className={classnames(
        styles.ButtonExtra,
        "cursor-pointer d-flex justify-content-center"
      )}
    >
      <FontAwesomeIcon size="sm" icon={faTrashAlt} />
    </div>
  );
};

const CloneButton = ({ id }) => {
  const handleClone = useContext(NodeCloneFunctionContext);

  return (
    <div
      onClick={() => {
        handleClone(id);
      }}
      className={classnames(
        styles.ButtonExtra,
        "cursor-pointer d-flex justify-content-center"
      )}
    >
      <FontAwesomeIcon size="sm" icon={faClone} />
    </div>
  );
};

const RunButton = ({ id, data }) => {
  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const { loading } = useAutomationTestRequestSidebar(AutomationTestRequest);

  if (loading) return null;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        AutomationTestClick(id, {
          ...data
        });
      }}
      className={classnames(
        styles.ButtonExtra,
        "cursor-pointer d-flex justify-content-center"
      )}
    >
      <FontAwesomeIcon size="sm" icon={faPlay} />
    </div>
  );
};

const checkIfTrigger = (type) => {
  if (!type) return false;
  return Boolean(TriggerTypeEnum[type]);
};

const NodeBaseContainer = ({ children, id, data, type }) => {
  const {
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    onPopupMouseEnter,
    onPopupMouseLeave,
    isOpen
  } = useHoverEvents();
  const canEdit = useContext(FormCanEditContext);
  const isTrigger = checkIfTrigger(type);

  return (
    <>
      {isOpen && canEdit && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onMouseEnter={onPopupMouseEnter}
          onMouseLeave={onPopupMouseLeave}
          style={{
            display: "flex",
            left: "-16px",
            // top: "-40%",
            width: "calc(100% + 30px)",
            // transform: `translate(0, -50%)`
            transform: `translate(0px, calc(-50% - 1.3rem))`
          }}
          className={classnames(
            styles.Container,
            "py-3 position-absolute align-items-center justify-content-sm-around cursor-default"
          )}
        >
          <TrashButton id={id} type={type} />
          {!isTrigger && <EnablePauseButton id={id} />}
          {!isTrigger && <CloneButton id={id} />}
          <RunButton data={data} id={id} />
        </div>
      )}
      <div
        className="position-relative"
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
      >
        {children}
      </div>
    </>
  );
};

export default NodeBaseContainer;
