export function RemoveAccents(strAccentsParam) {
  var strAccents = strAccentsParam.split("");
  var strAccentsOut = [];
  var strAccentsLen = strAccents.length;
  var accents =
    "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
  var accentsOut =
    "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  for (var y = 0; y < strAccentsLen; y++) {
    if (accents && accents.indexOf(strAccents[y]) !== -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
    } else strAccentsOut[y] = strAccents[y];
  }
  strAccentsOut = strAccentsOut.join("");
  return strAccentsOut;
}

export function msToTime(duration, format, asObject) {
  var milliseconds = parseInt((duration % 1000) / 100),
    seconds = parseInt((duration / 1000) % 60),
    minutes = parseInt((duration / (1000 * 60)) % 60),
    hours = null;

  if (duration >= 86400000) {
    hours = parseInt(duration / (1000 * 60 * 60));
  } else if (duration < 1000) {
    hours = 0;
    seconds = 0;
    minutes = 0;
  } else {
    hours = parseInt((duration / (1000 * 60 * 60)) % 24);
  }

  if (asObject) {
    return {
      hours,
      minutes,
      seconds,
      milliseconds
    };
  }

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  if (format) {
    return format
      .replace("hh", hours)
      .replace("mm", minutes)
      .replace("ss", seconds)
      .replace("SS", milliseconds);
  }

  return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
}

// export function ReplaceAll(str, str1, str2, ignore) {
//   return str.replace(
//     new RegExp(
//       str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&"),
//       ignore ? "gi" : "g"
//     ),
//     typeof str2 == "string" ? str2.replace(/\$/g, "$$$$") : str2
//   );
// }
export function GenerateGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

export function GenerateSmallerGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4();
}

export function DoubleDigitFormat(num) {
  if (num > 9) return num;
  else return "0" + num;
}

export function dateFormat(date, format) {

	
	if (!format) return date.toUTCString();
	
  
  return format
    .replace("MM", DoubleDigitFormat(date.getMonth() + 1))
    .replace("dd", DoubleDigitFormat(date.getDate()))
    .replace("yyyy", date.getFullYear())
    .replace("yy", date.getFullYear().toString().slice(2))
    .replace("hh", DoubleDigitFormat(date.getHours()))
    .replace("mm", DoubleDigitFormat(date.getMinutes()))
    .replace("ss", DoubleDigitFormat(date.getSeconds()))
    .replace("a", DoubleDigitFormat(date.getHours()) > 12 ? "PM" : "AM");
}

export function isDate(d) {
  return d instanceof Date && !isNaN(d);
  // return value !== undefined && value !== null && typeof value.getMonth === "function";
}
