import { useCallback, useContext } from "react";
import {
  EntiyTypeEnumAppFavorites,
  LinkTypesEnumAppFavorites,
  ModuleTypeDict,
  ViewTypeEnumAppFavorites
} from "./AppBarHelper";
import { ServerAwareFavoritesContext } from "./ServerAwareFavorite";
import { v1 as uuidv1 } from "uuid";
import { ItemId, TreeItem } from "@atlaskit/tree";
import { ServerAwareViewsContext } from "../../Components/CGrid/ServerAwareViews";
import { useLocation } from "react-router-dom";
import querystring from "query-string";
import { useServerAwareState } from "../../Components/CGrid/ServerGrid";
import {
  AppBarFavoriteQueryContext,
  AppBarFavoriteTreeSetter,
  IAppBarFavoriteContet
} from "./AppBarFavoriteQuery";
import { useToast } from "../../Components/Toast/ToastProvider";

const useAppBarAddViewFavorite = (schemaName: string) => {
  const lsViews = useContext(ServerAwareViewsContext) as any;
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const { commitChanges } = useContext(
    AppBarFavoriteQueryContext
  ) as IAppBarFavoriteContet;
  const handleChange = useContext(AppBarFavoriteTreeSetter);
  const createToast = useToast();
  const { search } = useLocation();
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const awareState = useServerAwareState();

  const handleAddNewFavorite = useCallback(() => {
    typeof commitChanges === "function" &&
      commitChanges((oldTree) => {
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items } as Record<ItemId, TreeItem>;
        const newRootItem = { ...newItems[rootId] } as TreeItem;

        const newRootItemChildren = [...newRootItem.children];
        const newId = uuidv1();
        newRootItemChildren.unshift(newId);
        newRootItem.children = newRootItemChildren;
        const { data } = FavoriteItem;
        const resolvedSearch = querystring.parse(search || "");
        resolvedSearch.favoriteId = newId;
        const newChild = {
          ...FavoriteItem,
          data: {
            ...data,
            search: "?" + querystring.stringify(resolvedSearch)
          },
          id: newId,
          children: [],
          hasChildren: false,
          isExpanded: false,
          isChildrenLoading: false
        };
        newItems[rootId] = newRootItem;
        newItems[newId] = newChild;

        return {
          rootId,
          items: newItems
        };
      });
  }, [FavoriteItem, commitChanges, search]);

  const handleAddNewFavoriteView = useCallback(() => {
    typeof handleChange === "function" &&
      handleChange((oldTree) => {
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items } as Record<ItemId, TreeItem>;
        const newRootItem = newItems[rootId] as TreeItem;
        const newRootItemChildren = newRootItem.children;
				
        const index = lsViews.findIndex((e: any) => e.id === viewPref) as any;
        const currentItem = lsViews[index !== -1 ? index : 0];
        const {
          name,
          kanbanLayout,
          filterSideToggleStatus,
          pipelineStatus,
          colorFilters,
          columnLayout,
          stickyLayout,
          columnSize,
          cardLayout,
          downsizedPipelineStatus
        } = currentItem;

        const newSearch = querystring.parse(search);
        delete newSearch.viewPref;

        const { params, moduleType } = awareState;
        const resolvedModuleType = moduleType as EntiyTypeEnumAppFavorites;

        const newId = uuidv1();
        newSearch.favoriteId = newId;
        newRootItemChildren.unshift(newId);
        newRootItem.children = newRootItemChildren;
        const newChild = {
          id: newId,
          children: [],
          hasChildren: false,
          isExpanded: false,
          isChildrenLoading: false,
          data: {
            isFolder: false,
            title: name,
            viewType: params.view ? params.view : ViewTypeEnumAppFavorites.grid,
            linkType: LinkTypesEnumAppFavorites.complex,
            entity: ModuleTypeDict[resolvedModuleType],
            filterSideToggleStatus,
            kanbanLayout,
            pipelineStatus,
            colorFilters,
            search: "?" + querystring.stringify(newSearch),
            columnLayout,
            stickyLayout,
            columnSize,
            cardLayout,
            downsizedPipelineStatus
          }
        };
        newItems[rootId] = newRootItem;
        newItems[newId] = newChild;
        return {
          rootId,
          items: newItems
        };
      }, createToast);
  }, [awareState, createToast, handleChange, lsViews, search, viewPref]);

  if (FavoriteItem) {
    return handleAddNewFavorite;
  } else {
    return handleAddNewFavoriteView;
  }
};

export default useAppBarAddViewFavorite;
