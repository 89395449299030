import { faSave, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { subscribeUniqueEvent } from "../../Helpers/ComponentsHelper";
import TextEllipsis from "../TextEllipsis/TextEllipsis";

const InputComponent = ({
  closeTagEdition,
  onSave,
  index,
  inputWidth,
  label
}) => {
  const intl = useIntl();
  useEffect(() => {
    return subscribeUniqueEvent((e) => {
      if (e.keyCode === 27) {
        e.preventDefault();
      }
    });
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      e.stopPropagation();
      e.preventDefault();
      closeTagEdition(index);
    }
    if (e.key === "Enter") {
      e.stopPropagation();
      e.preventDefault();
      onSave(index);
    }
  };

  return (
    <input
      onKeyDown={handleKeyDown}
      onBlur={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onSave(index);
      }}
      style={{ width: inputWidth }}
      autoFocus
      maxLength={100}
      placeholder={`${intl.formatMessage({ id: "DESCRIPTION" })}...`}
      className="border-0 bg-transparent outline-0"
      id={`airdes-file-tag-input-${index}`}
      defaultValue={label}
    />
  );
};

const FileUploadText = ({
  tagEditors,
  name,
  length,
  extension,
  label = "",
  closeTagEdition,
  inputWidth,
  index,
  onSave
}) => {
  const EnableTagEdition = useMemo(() => {
    return tagEditors.includes(index);
  }, [index, tagEditors]);

  if (EnableTagEdition) {
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="d-flex cursor-default align-items-center"
      >
        <InputComponent
          closeTagEdition={closeTagEdition}
          index={index}
          inputWidth={inputWidth}
          label={label}
          onSave={onSave}
        />
        <div
          onClick={() => {
            closeTagEdition(index);
          }}
          className="text-danger-alt ml-2 px-2 cursor-pointer fw-medium"
        >
          <FontAwesomeIcon className="position-relative" icon={faTimes} />
        </div>
        <div
          onClick={() => {
            onSave(index);
          }}
          className="text-primary mx-2 px-2 cursor-pointer fw-medium"
        >
          <FontAwesomeIcon className="position-relative" icon={faSave} />
        </div>
      </div>
    );
  }

  if (label) {
    return <TextEllipsis text={label} length={15} truncate span />;
  }

  return (
    <TextEllipsis
      text={name}
      length={length || 5}
      beforeEnd={5}
      truncate
      span
      afterText={extension}
    />
  );
};

export default FileUploadText;
