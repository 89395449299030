import { faAt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useMemo, useState } from "react";
import { useSidebar } from "../../../../Components/Sidebar/SidebarV2";
import { ElementsRefContext } from "../AutomationDetails";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../AutomationDetailsHelper";
import SourceHandles from "../Handles/SourceHandles";
import NodeBaseSidebar from "./NodeBaseSidebar";
import styles from "../AutomationDetails.module.css";
import Button from "../../../../Components/Button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import KeyedDropdown from "../../../../Components/Dropdown/KeyedDropdown";
import FormInput from "../../../../Components/Forms/FormInput/FormInput";
import AutomationTestResults from "./AutomationTestResults";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import TargetHandles from "../Handles/TargetHandles";
import AnimatedAccordion from "../../../../Components/AnimatedAccordion/AnimatedAccordion";
import classnames from "classnames";
import Switch from "../../../../Components/Switch/Switch";
import NodeBaseContainer from "./NodeExtraOptions/NodeExtraOptions";
import NodeIconNameContainer from "./NodeIconNameContainer";

const responseTypeOptions = ["json", "text"];

const TextArea = ({ className, preview, error, ...props }) => {
  // const resolvedPreview = useMemo(() => {
  // 	console.log(preview)
  //   if (typeof preview === "string" && preview ===  'false') return 0;
  //   else if (typeof preview === "string" && preview === 'true') return 1;
  //   else return preview;
  // }, [preview]);

  return (
    <textarea
      {...props}
      // preview={resolvedPreview}
      className={classnames(
        className,
        "ssi-control ar-html-input rounded w-100"
      )}
    />
  );
};

const SwitchComponent = ({ text, onChange, value, name }) => {
  const handleChange = (e) => {
    const { checked } = e.target;
    onChange({ target: { name, value: checked } });
  };

  return (
    <div className="d-flex w-100 mt-1 align-items-center justify-content-between">
      <div className="text-black fs-14">{text}</div>
      <Switch value={value} onChange={handleChange} />
    </div>
  );
};

const MethodValueComponent = ({ item }) => {
  if (!item) return <div></div>;
  return <div className={styles.keyDropdownTextStyle}>{item}</div>;
};

const ResponseTypeDropdown = ({ value, onChange }) => {
  return (
    <KeyedDropdown
      value={value}
      onChange={onChange}
      options={responseTypeOptions}
      closeOnSelect
      name="responseFormat"
      valueComponent={MethodValueComponent}
    />
  );
};

const methodOptions = [
  "GET",
  "POST",
  "DELETE",
  "PUT",
  "HEAD",
  "PATCH",
  "PURGE",
  "LINK",
  "UNLINK"
];

const MethodDropdown = ({ value, onChange }) => {
  return (
    <KeyedDropdown
      value={value}
      name={"method"}
      onChange={onChange}
      options={methodOptions}
      closeOnSelect
      valueComponent={MethodValueComponent}
    />
  );
};

const OptionsContainer = ({ options, handleChange }) => {
  const { maxRedirects, maxBodyLength, timeout, maxContentLength } = options;

  const onChange = (e) => {
    const { value, name } = e.target;
    handleChange((oldV) => {
      const newV = { ...oldV };
      newV.options = {
        ...oldV.options,
        [name]: value
      };
      return newV;
    });
  };

  return (
    <AnimatedAccordion
      className={classnames("mb-3")}
      storageName={"ADVANCED_OPTIONS"}
      title={
        <div className="text-black">
          <FormattedMessage id="ADVANCED_OPTIONS" />
        </div>
      }
    >
      <div className={classnames(styles.items)}>
        <FormInput
          className="my-3"
          textId="MAX_REDIRECTS"
          onChange={onChange}
          type="number"
          value={maxRedirects}
          name="maxRedirects"
        />
        <FormInput
          className="mb-3"
          textId="TIMEOUT"
          onChange={onChange}
          type="number"
          value={timeout}
          name="timeout"
        />
        <FormInput
          className="mb-3"
          onChange={onChange}
          textId="MAX_BODY_LENGTH"
          type="number"
          value={maxBodyLength}
          name="maxBodyLength"
        />
        <FormInput
          className="mb-3"
          onChange={onChange}
          textId="MAX_CONTENT_LENGTH"
          type="number"
          value={maxContentLength}
          name="maxContentLength"
        />
      </div>
    </AnimatedAccordion>
  );
};

const SingleKeyValueItem = ({ itemValue, onChange, index, onDelete }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange({ name, value }, index);
  };

  const handleDelete = () => {
    onDelete(index);
  };

  const { name, value } = itemValue;

  return (
    <div
      className={classnames(
        styles.ifElseValueItem,
        "p-2 border mb-3 bg-airdesk"
      )}
    >
      <div className="d-flex w-100 justify-content-between mb-3">
        <span className="text-black fs-16 text-truncate flex-1">{name}</span>
        <div
          onClick={handleDelete}
          className="d-flex cursor-pointer align-items-center"
        >
          <FontAwesomeIcon icon={faTrashAlt} className="text-danger-alt" />
        </div>
      </div>
      <FormInput
        textId="NAME"
        name="name"
        value={name}
        onChange={handleChange}
        textInputClassname="bg-white"
        className="mb-3"
      />
      <FormInput
        name="value"
        textId="VALUE"
        value={value}
        textInputClassname="bg-white"
        onChange={handleChange}
      />
    </div>
  );
};

const JsonKeyValueGroup = ({ name, value, handleChange, enableRaw }) => {
  const addNewItem = () => {
    handleChange((oldV) => {
      const newV = { ...oldV };
      const newItems = [...newV[name]];
      newItems.push({
        name: "",
        value: ""
      });
      newV[name] = newItems;
      return newV;
    });
  };

  const deleteItem = (index) => {
    handleChange((oldV) => {
      const newV = { ...oldV };
      const newItems = [...newV[name]];
      newItems.splice(index, 1);
      newV[name] = newItems;
      return newV;
    });
  };

  const handleItemChange = (e, index) => {
    const { name: itemName, value: itemValue } = e;
    handleChange((oldV) => {
      const newV = { ...oldV };
      const newItems = [...newV[name]];
      newItems[index] = {
        ...newItems[index],
        [itemName]: itemValue
      };
      newV[name] = newItems;
      return newV;
    });
  };

  return (
    <div className="pt-3">
      {value.map((e, i) => {
        return (
          <SingleKeyValueItem
            itemValue={e}
            onChange={handleItemChange}
            index={i}
            onDelete={deleteItem}
          />
        );
      })}
      <Button
        type="button"
        vType="primary-ghost"
        onClick={addNewItem}
        className="d-flex w-100 mb-3 align-items-center justify-content-center"
      >
        <FormattedMessage id="ADD_ITEM" />
      </Button>
    </div>
  );
};

const FormContainer = ({ formState, id, canEdit }) => {
  const { form, handleChange } = formState;

  const {
    url,
    method,
    responseFormat,
    headers,
    body,
    queryStrings,
    options,
    enableRawHeader,
    enableRawBody,
    enableRawQueryStrings,
    rawHeader,
    rawBody,
    rawQueryStrings
  } = form;

  const [error, setError] = useState({
    rawHeader: false,
    rawBody: false,
    rawQueryStrings: false
  });

  const handleJsonTest = (name) => {
    const value = form[name];
    if (value.length === 0) return;
    try {
      JSON.parse(value);
    } catch (error) {
      setError((oldE) => {
        let newE = { ...oldE };
        newE[name] = true;

        return newE;
      });
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    handleChange({
      [name]: value
    });
  };
  const intl = useIntl();
  return (
    <div
      className={classnames(
        styles.disableInputArrows,
        "d-flex flex-column overflow-auto"
      )}
    >
      <FormInput className="mb-3" textId="URL" value={url} name="url" />
      <FormInput
        className="mb-3"
        textId="METHOD"
        value={method}
        name="method"
        onChange={onChange}
        inputType={MethodDropdown}
      />
      <FormInput
        className="mb-3"
        textId="RESPONSE_FORMAT"
        value={responseFormat}
        name="responseFormat"
        onChange={onChange}
        inputType={ResponseTypeDropdown}
      />
      <OptionsContainer handleChange={handleChange} options={options} />
      <AnimatedAccordion
        className={classnames("mb-3")}
        storageName={"HEADERS"}
        title={
          <div className="text-black">
            <FormattedMessage id="HEADERS" />
          </div>
        }
      >
        <SwitchComponent
          text={intl.formatMessage({ id: "ENABLE_RAW_HEADERS" })}
          onChange={onChange}
          value={enableRawHeader}
          name="enableRawHeader"
        />
        {!enableRawHeader ? (
          <JsonKeyValueGroup
            value={headers}
            name="headers"
            handleChange={handleChange}
          />
        ) : (
          <>
            <FormInput
              className="mt-3"
              name="rawHeader"
              text="Raw Header"
              inputType={TextArea}
              value={rawHeader}
              error={error.rawHeader}
            />
            <span
              className="text-primary fs-12 cursor-pointer"
              onClick={() => {
                handleJsonTest("rawHeader");
              }}
            >
              <FormattedMessage id="TEST_JSON" />
            </span>
          </>
        )}
      </AnimatedAccordion>
      <AnimatedAccordion
        className={classnames("mb-3")}
        storageName={"BODY"}
        title={
          <div className="text-black">
            <FormattedMessage id="BODY" />
          </div>
        }
      >
        <SwitchComponent
          text={intl.formatMessage({ id: "ENABLE_RAW_BODY" })}
          onChange={onChange}
          value={enableRawBody}
          name="enableRawBody"
        />
        {!enableRawBody ? (
          <JsonKeyValueGroup
            value={body}
            name="body"
            handleChange={handleChange}
          />
        ) : (
          <>
            <FormInput
              name="rawBody"
              text="Raw Body"
              inputType={TextArea}
              className="mt-3"
              value={rawBody}
              error={error.rawBody}
            />
            <span
              className="text-primary fs-12 cursor-pointer"
              onClick={() => {
                handleJsonTest("rawBody");
              }}
            >
              <FormattedMessage id="TEST_JSON" />
            </span>
          </>
        )}
      </AnimatedAccordion>
      <AnimatedAccordion
        storageName={"QUERY_PARAMETERS"}
        className={classnames("mb-3")}
        title={
          <div className="text-black">
            <FormattedMessage id="QUERY_PARAMETERS" />
          </div>
        }
      >
        <SwitchComponent
          text={intl.formatMessage({ id: "ENABLE_RAW_QUERY_STRINGS" })}
          onChange={onChange}
          value={enableRawQueryStrings}
          name="enableRawQueryStrings"
        />
        {!enableRawQueryStrings ? (
          <JsonKeyValueGroup
            value={queryStrings}
            name="queryStrings"
            handleChange={handleChange}
          />
        ) : (
          <>
            <FormInput
              name="rawQueryStrings"
              text="Raw Query Strings"
              className="mt-3"
              inputType={TextArea}
              value={rawQueryStrings}
              error={error.rawQueryStrings}
            />
            <span
              className="text-primary fs-12 cursor-pointer"
              onClick={() => {
                handleJsonTest("rawQueryStrings");
              }}
            >
              <FormattedMessage id="TEST_JSON" />
            </span>
          </>
        )}
      </AnimatedAccordion>
    </div>
  );
};

const settings = {
  defaultForm: {
    url: "",
    method: "",
    responseFormat: "",
    headers: [],
    body: [],
    queryStrings: [],
    options: [],
    enableRawHeader: false,
    enableRawBody: false,
    enableRawQueryStrings: false,
    rawHeader: "",
    rawBody: "",
    rawQueryStrings: ""
  },
  FormContent: (props) => {
    return <FormContainer {...props}></FormContainer>;
  }
};

const HttpRequestNode = React.memo(({ selected, data, id, type, ...rest }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };
  const intl = useIntl();
  const AllElementsRef = useContext(ElementsRefContext);

  const AutomationTestRequest = useContext(AutomationTestRequestContext);

  const AutomationTestClick = useContext(AutomationTestClickContext);

  const { enabled, config } = data;

  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <NodeIconNameContainer
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleClick}
          selected={selected}
          icon={faAt}
          text={intl.formatMessage({ id: "HTTP_REQUEST" })}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default HttpRequestNode;
