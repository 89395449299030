import React, { useMemo, useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
// import SpaceSelector from "./SpaceSelector";
import MainLoader from "../../Components/Loader/MainLoader";
import Space from "./Space2";
import SpaceCreator from "./SpaceCreator";
import { connect } from "react-redux";
import {
  useMaintenanceState,
  ServerStatusEnum
} from "../Maintenance/Maintenance";
import MaintenanceView from "../Maintenance/MaintenanceView/MaintenanceView";
// import querystring from "query-string";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { getCookie } from "../../Helpers/CookiesHelper";
import SpaceSelector from "./SpaceSelector";
import SpaceOnboarding from "../SpaceOnboarding/SpaceOnboarding";
import SmallScreen from "../SmallScreen/SmallScreen";
import { isDeveloper } from "../../Helpers/ModulesHelper";
// import { useUserEntitiesHub } from "../RealTime/RealTimeUser";

const canSkipMaintenance = () => {
  const isMaintenanceDisabled = getCookie("disableMaintenance");

  return isMaintenanceDisabled;
};

export const NotificationsSetupContext = React.createContext();

const SpaceRoutes = (props) => {
  const { match, areNotificationsSetup } = props;
  const hasAnimated = true;

  const user = useCurrentAccount();

  useEffect(() => {
    const inviteId =
      localStorage.getItem("VNI-T") !== ""
        ? localStorage.getItem("VNI-T")
        : null;

    if (inviteId) {
      localStorage.removeItem("VNI-T");
    }
  }, []);

  const {
    loading: loadingMaintenance,
    // error: errorMaintenance,
    data: maintenanceData
  } = useMaintenanceState();

  // const userHub = useUserEntitiesHub();
  // const hasConnectedRef = useRef(false);

  // const connectionReady = useMemo(() => {
  //   if (Boolean(userHub) || hasConnectedRef.current) {
  //     hasConnectedRef.current = true;
  //     return true;
  //   }
  //   return false;
  // }, [userHub]);

  let isLoading = useMemo(
    () => !hasAnimated || !user || loadingMaintenance,
    [hasAnimated, loadingMaintenance, user]
  );

  // !hasAnimated || !user || loadingMaintenance || !connectionReady;

  const isMobile = () => {
    const myCookieValue = getCookie("bypassSmallScreen");
    if (myCookieValue) {
      return false;
    }

    var userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      return true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }
    return false;
  };
  var account = useCurrentAccount();
  const [isSmallScreen, setIsSmallScreen] = useState(() => {
    return isMobile();
  });

  const buildContent = () => {
    if (isLoading) return null;

    // if (!maintenanceData) return <MaintenanceView />;

    if (
      maintenanceData &&
      maintenanceData.ServerStatus === ServerStatusEnum.Maintenance &&
      !canSkipMaintenance()
    )
      return <MaintenanceView />;

    const isDev = isDeveloper(account);
    if (isSmallScreen && !isDev) {
      return <SmallScreen />;
    }

    return (
      <NotificationsSetupContext.Provider value={areNotificationsSetup}>
        <Switch>
          <Route path={`${match.path}/create`} component={SpaceCreator} />
          <Route
            path={`${match.path}/:spaceId/onboarding`}
            component={SpaceOnboarding}
          />
          <Route path={`${match.path}/:spaceId`} component={Space} />
          <SpaceSelector />
        </Switch>
      </NotificationsSetupContext.Provider>
    );
  };

  return (
    <React.Fragment>
      {isLoading && <MainLoader />}
      {/* <MainLoader /> */}
      {buildContent()}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, accountId } = state.CurrentUser;

  return {
    isFetchingUser: error ? false : !Boolean(accountId),
    areNotificationsSetup: state.Notifications.isSetup
    // error
  };
};

export default connect(mapStateToProps)(SpaceRoutes);
