import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";
import { useFormState } from "../../../Components/Forms";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../../Components/Forms/SideMenuForm";
import HTMLInput from "../../../Components/HTMLInput/HTMLInput";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import {
  BaseSidebarHeader,
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../../Components/Sidebar/SidebarV2Helper";
import { useToast } from "../../../Components/Toast/ToastProvider";
import {
  spaceAutomationSchema,
  spaceOrganizationSchema
} from "../../../config/schema";
import { useCurrentAccount } from "../../../Contexts/UserContext";
import { useSpaceQuery } from "../../../Helpers/IOClient";
import { useAutomation } from "../../../Hooks/EntityHooks";
import {
  DepartmentDropdown,
  OrganizationDropdown,
  TeamDropdown,
  UserDropdown
} from "../../AdvancedMultiInputs";
import {
  defaultAutomationForm,
  ValidateAutomationForm
} from "./AutomationFormHelper";

export const AutomationFormContent = ({ id, ...props }) => {
  const user = useCurrentAccount();
  const orgState = useSpaceQuery(
    `query/organizations?$filter=((Account/Id eq '${user.Id}') or (Manager/Id eq '${user.Id}'))`,
    [spaceOrganizationSchema]
  );
  const { loading: loadingOrganizations, data: organizations } = orgState;
  const connectedWorkflow = useAutomation(id);
  if (loadingOrganizations)
    return (
      <div className="d-flex h-100 flex-column bg-white">
        <BaseSidebarHeader>
          <FormattedMessage id="CREATE_WORKFLOW" />
        </BaseSidebarHeader>
        <div className="flex-1 of-y-auto shadow" style={{ width: 520 }}>
          <LoaderSpinner center size="sm" className="text-secondary" />
        </div>
      </div>
    );
  return (
    <AutomationFormInnerContent
      id={id}
      key={id || "NewF"}
      connectedWorkflow={connectedWorkflow}
      organizations={organizations}
      {...props}
    />
  );
};

const AutomationFormInnerContent = ({
  onClose,
  refetchRef,
  organizations,
  id,
  connectedWorkflow,
  duplicationForm,
  ...props
}) => {
  const user = useCurrentAccount();
  const { CanEdit, CanDelete } = connectedWorkflow || {};
  const formState = useFormState(() => {
    const defaulForm = defaultAutomationForm({ user, organizations });
    if (duplicationForm) {
      const {
        AssignedTo,
        Description,
        EnableSchedule,
        Json,
        Name,
        Company,
        Departments,
        Teams
      } = duplicationForm;

      return {
        AssignedTo,
        Description,
        Company,
        Departments,
        Teams,
        EnableSchedule,
        IsActive: false,
        Json,
        Name
      };
    }

    return {
      ...defaulForm,
      ...connectedWorkflow
    };
  });
  const { form, handleChange } = formState;
  const handleTranslatedError = useHandleError(spaceAutomationSchema);
  const {
    Description,
    // Name,
    Company,
    Id,
    AssignedTo
  } = form;
  const intl = useIntl();
  const createToast = useToast();

  const [post, { loading }] = useSidebarSpacePost(
    Id ? `automation/${Id}` : "automation",
    spaceAutomationSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "WORKFLOW" })} nº ${newId || id}`,
          description: `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`
        });
        refetchRef?.current && refetchRef.current();
        onClose && onClose();
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const handleAssignToChange = (e) => {
    const { value } = e.target;
    handleChange({
      AssignedTo: value
    });
  };

  const formatAutomationForm = (form) => {
    const { Company, ...rest } = form;

    return {
      OrganizationId: Company,
      ...rest
    };
  };

  const handleSubmit = (form) => {
    post(formatAutomationForm(form));
  };

  // const followers = useJoinedFollowerAccounts(ManagerAccount);
  // const possibleFollowers = useAvailableFollowers(Creator, followers);

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `automation/${id}`,
    spaceAutomationSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "WORKFLOW" })} nº ${id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
        onClose && onClose();
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );
  const handleOrganizationChanges = (e) => {
    const { value } = e.target;
    handleChange({
      Company: value
    });
  };
  return (
    <div className="d-flex h-100 flex-column bg-white">
      <BaseSidebarHeader>
        {id ? (
          <FormattedMessage id="EDIT_WORKFLOW" />
        ) : (
          <FormattedMessage id="CREATE_WORKFLOW" />
        )}
      </BaseSidebarHeader>
      <div className="flex-1 of-y-auto shadow" style={{ width: 520 }}>
        <SideForm
          canEdit={CanEdit}
          formState={formState}
          onSubmit={handleSubmit}
          validate={ValidateAutomationForm}
        >
          <SideFormContent className="px-3 pt-3 pb-5 of-auto">
            <FormInput
              textId="COMPANY"
              name="Company"
              value={Company}
              inputType={OrganizationDropdown}
              onChange={handleOrganizationChanges}
              className="mb-3"
            />

            <FormInput name="Name" className="mb-3" textId="NAME" />
            <FormInput
              name="Description"
              className="mb-3"
              textId="DESCRIPTION"
              inputType={HTMLInput}
              value={Description}
            />
            <FormInput
              name="Departments"
              className="mb-3"
              multiple
              text={intl.formatMessage({ id: "DEPARTMENT" })}
              inputType={DepartmentDropdown}
            />
            <FormInput
              name="Teams"
              multiple
              className="mb-3"
              text={intl.formatMessage({ id: "TEAM" })}
              inputType={TeamDropdown}
            />
            <FormInput
              value={AssignedTo}
              textId="ASSIGNED_TO"
              className="mb-3"
              onChange={handleAssignToChange}
              inputType={UserDropdown}
            />
          </SideFormContent>
          <SideFormFooter
            canDelete={CanDelete}
            deleteLoading={deleteLoading}
            handleDelete={remove}
            isLoading={loading}
          />
        </SideForm>
      </div>
    </div>
  );
};

// const AutomationFormButton = ({ className }) => {
//   const [modalStatus, setModalStatus] = useState(false);

//   const toggleModalStatus = () => {
//     setModalStatus(false);
//   };

//   return (
//     <>
//       <Modal
//         className="of-v"
//         extraCloseButton={
//           <>
//             <div
//               className="ar-modal-preview-file-content-close-button"
//               onClick={() => setModalStatus(false)}
//             >
//               <FontAwesomeIcon icon={faTimes} />
//             </div>
//           </>
//         }
//         isOpen={modalStatus}
//         onClose={toggleModalStatus}
//         disableClosure
//       >
//         <AutomationFormContent onClose={toggleModalStatus} />
//       </Modal>
//       <Button
//         onClick={() => setModalStatus(true)}
//         className={classNames(className)}
//       >
//         <div className="d-flex align-items-center">
//           <FontAwesomeIcon icon={faCodeMerge} />
//           <span className="ml-2">
//             <FormattedMessage id="ADD_WORKFLOW" />
//           </span>
//         </div>
//       </Button>
//     </>
//   );
// };
