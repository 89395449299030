import React, { Component } from "react";
import "./toast.css";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faExclamationCircle,
  faTimesCircle,
  faCheckCircle,
  faInfoCircle
} from "@fortawesome/pro-solid-svg-icons";
import { NotificationAlert } from "./ToastComponents";

export class ToastGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: props.pos || "br",
      insert: props.insert || "top",
      status: ""
    };
  }

  getNotificationsArray() {
    return this.state.notifications;
  }

  handleStateChange = (pos, index) => {
    this.props.clear(pos, index);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.notifications.length > 3) {
      const filtered = this.props.notifications.filter((e) => Boolean(e));
      if (filtered.length > 3) {
        const index = this.props.notifications.findIndex((e) => Boolean(e));
				this.props.clear(this.state.position, index);
      }
    }
  }

  //   static getDerivedStateFromProps(props, state) {

  // 	let newChildren = [...state.notifications,...React.Children.toArray(props.children)];
  //     if(props.children){
  //         return {notifications: newChildren};
  //     }
  //     else return null;
  //   }

  render() {
    let children = [];
    this.props.notifications.forEach((notification, index, arr) => {
      if (notification) {
        children.push(
          React.cloneElement(notification, {
            pos: this.state.position,
            // key: this.state.position + "" + index,
            position: index,
            onMouseUp: this.handleStateChange.bind(
              this,
              this.state.position,
              index
            ),
            location:
              index === 0 ? "top" : index === arr.length - 1 ? "bot" : "between"
          })
        );
      }
    });

    const NotificationGroup = (
      <div
        className={`ar-toast ${"ar-toast__" + this.state.position} ${
          "ar-toast__insert--" + this.state.insert
        }`}
      >
        {children}
      </div>
    );

    return NotificationGroup;
  }
}

export class Toast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      timeout: "",
      notifiRect: "",
      size: ""
    };
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeout);
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    if (!this.props.noTimeout) {
      const time = this.props.timeout ? this.props.timeout : 4000;
      this.setState({
        timeout: setTimeout(() => {
          this.setState({ status: "remove" });
        }, time)
      });
    }
    if (this.state.notifiRect === "") {
      let notiNode = ReactDOM.findDOMNode(this);
      let notiRect = notiNode.getBoundingClientRect();
      this.setState({ notifiRect: notiRect });
    }
    if (this.state.status === "remove") this.setState({ status: "" });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.timeout !== prevProps.timeout ||
      this.props.noTimeout !== prevProps.noTimeout
    ) {
      if (this.props.noTimeout) {
        clearTimeout(this.state.timeout);
      } else {
        const time = this.props.timeout ? this.props.timeout : 4000;
        this.setState({
          timeout: setTimeout(() => {
            this.setState({ status: "remove" });
          }, time)
        });
      }
    }
  }

  handleClick = () => {
    clearTimeout(this.state.timeout);
    this.setState({ status: "remove" });
  };

  updateDimensions = () => {
    if (document.body.clientWidth < 769 && this.state.size !== "small") {
      this.setState({ size: "small" });
    } else if (
      document.body.clientWidth >= 769 &&
      this.state.size === "small"
    ) {
      this.setState({ size: "" });
    }
  };

  chooseEntry(entry = this.props.entry) {
    const placement =
      this.props.pos === "tl" ||
      this.props.pos === "tm" ||
      this.props.pos === "tr"
        ? "top"
        : "bot";

    switch (entry) {
      case "flipx":
        return placement === "top"
          ? "ar-toast__item--flipInUpX"
          : "ar-toast__item--flipInDownX";
      case "flipy":
        return placement === "top"
          ? "ar-toast__item--flipInUpY"
          : "ar-toast__item--flipInDownY";
      case "zoom":
        return "ar-toast__item--zoomIn";
      case "eye":
        return "ar-toast__item--eyeCatcher";
      case "flash":
        return "ar-toast__item--flash";
      case "rubber":
        return "ar-toast__item--rubberBandIn";
      case "noAnimation":
        return "noAnimation";
      default:
        return "ar-toast__item--fadeIn";
    }
  }

  chooseExit(exit = this.props.exit) {
    switch (exit) {
      case "flipx":
        return "ar-toast__item--flipOutX";
      case "flipy":
        return "ar-toast__item--flipOutY";
      case "zoom":
        return "ar-toast__item--zoomOut";
      case "rubber":
        return "ar-toast__item--rubberBandOut";
      default:
        return "ar-toast__item--fadeOut";
    }
  }

  chooseIcon(type = this.props.type) {
    switch (type) {
      case "danger":
        return (
          <FontAwesomeIcon style={{ color: "#FF5962" }} icon={faTimesCircle} />
        );
      case "warning":
        return (
          <FontAwesomeIcon
            style={{ color: "#F7D154" }}
            icon={faExclamationCircle}
          />
        );
      case "info":
        return (
          <FontAwesomeIcon style={{ color: "#1E73F0" }} icon={faInfoCircle} />
        );
      case "success":
        return (
          <FontAwesomeIcon style={{ color: "#36AB80" }} icon={faCheckCircle} />
        );
      case "Neutral":
        return <FontAwesomeIcon style={{ color: "#7C98B6" }} icon={faCircle} />;
      default:
        return "";
    }
  }

  render() {
    const type = this.props.type || "default";
    const title = this.props.title || "";
    const placement =
      this.props.pos === "tl" ||
      this.props.pos === "tm" ||
      this.props.pos === "tr" ||
      this.props.pos === "mr" ||
      this.props.pos === "ml"
        ? "top"
        : "bot";
    const entry = this.chooseEntry();
    const exit = this.chooseExit();
    const zdex =
      placement === "top"
        ? 8000 - this.props.position
        : 8000 + this.props.position;
    const location = this.props.location;
    const icon = this.props.ForcedIcon
      ? this.props.ForcedIcon
      : this.props.withIcon || true
      ? this.chooseIcon()
      : "";
    let notification = "";
    let customWidth =
      this.props.width && document.body.clientWidth >= 769
        ? this.props.width
        : document.body.clientWidth >= 769
        ? this.props.pos === "tm" || this.props.pos === "bl"
          ? 520
          : 250
        : "";
    if (this.state.status === "remove") {
      notification = (
        <NotificationAlert
          key={this.props.key}
          customWidth={customWidth}
          handleClick={this.handleClick}
          zdex={zdex}
          placementTop={
            placement === "top" && location !== "bot"
              ? -this.state.notifiRect.height - 10
              : 5
          }
          onAnimationEnd={this.props.onMouseUp.bind(
            this,
            this.props.pos,
            this.props.position
          )}
          placementBot={
            placement === "bot" && location !== "top"
              ? -this.state.notifiRect.height
              : 15
          }
          transitionStyle={"margin 0.3s ease-in-out"}
          exit={exit}
          type={type}
          icon={icon}
          title={title}
        >
          {this.props.children}
        </NotificationAlert>
        // <div
        //   key={this.props.key}
        //   style={{
        //     width: customWidth,
        //     zIndex: zdex,
        //     marginTop:
        //       placement === "top" && location !== "bot"
        //         ? -this.state.notifiRect.height - 10
        //         : 5,
        //     marginBottom:
        //       placement === "bot" && location !== "top"
        //         ? -this.state.notifiRect.height
        //         : 15,
        //     transition: "margin 0.3s ease-in-out"
        //   }}
        //   onAnimationEnd={this.props.onMouseUp.bind(
        //     this,
        //     this.props.pos,
        //     this.props.position
        //   )}
        //   className={`ar-toast__item ${"ar-toast__" + type} ${exit}`}
        // >
        //   {icon ? (
        //     <div className="ar-toast__item__content__icon">{icon}</div>
        //   ) : (
        //     ""
        //   )}
        //   <div className="ar-toast__item__content">
        //     <h4 className="ar-toast__item__content__title">
        //       {title}
        //       <i className="ar-toast__item__content__closer">
        //         <FontAwesomeIcon icon={faTimes} />
        //       </i>
        //     </h4>
        //     <p className="ar-toast__item__content__msg">
        //       {this.props.children}
        //     </p>
        //   </div>
        // </div>
      );
    } else if (this.state.notifiRect) {
      notification = (
        <NotificationAlert
          customWidth={customWidth}
          handleClick={this.handleClick}
          zdex={zdex}
          placementTop={placement === "top" ? -this.state.notifiRect.height : 5}
          placementBot={
            placement === "bot" ? -this.state.notifiRect.height : 15
          }
          transitionStyle={"margin 0.3s ease-in-out"}
          entry={entry}
          type={type}
          icon={icon}
          title={title}
        >
          {this.props.children}
        </NotificationAlert>

        // <div
        //   style={{
        //     width: customWidth,
        //     zIndex: zdex,
        //     marginTop: placement === "top" ? -this.state.notifiRect.height : 5,
        //     marginBottom:
        //       placement === "bot" ? -this.state.notifiRect.height : 15,
        //     transition: "margin 0.3s ease-in-out)"
        //   }}
        //   onClick={this.handleClick}
        //   className={`ar-toast__item ${"ar-toast__" + type} ${entry}`}
        // >
        //   {icon ? (
        //     <div className="ar-toast__item__content__icon">{icon}</div>
        //   ) : (
        //     ""
        //   )}
        //   <div className="ar-toast__item__content">
        //     <h4 className="ar-toast__item__content__title">
        //       {title}
        //       <i className="ar-toast__item__content__closer">
        //         <FontAwesomeIcon icon={faTimes} />
        //       </i>
        //     </h4>
        //     <p className="ar-toast__item__content__msg">
        //       {this.props.children}
        //     </p>
        //   </div>
        // </div>
      );
    } else {
      notification = (
        <NotificationAlert
          customWidth={customWidth}
          handleClick={this.handleClick}
          type={type}
          icon={icon}
          title={title}
        >
          {this.props.children}
        </NotificationAlert>
      );
      // <div style={{width: customWidth}} onClick={this.handleClick} className={`ar-toast__item ${'ar-toast__' + type}`}>
      //           {icon? <div className="ar-toast__item__content__icon">{icon}</div>:''}
      //           <div className="ar-toast__item__content">
      //           <h4 className="ar-toast__item__content__title">{title}<i className="ar-toast__item__content__closer"><FontAwesomeIcon icon={faTimes} /></i></h4>
      //           <p className="ar-toast__item__content__msg">{this.props.children}</p>
      //           </div>
      //       </div>;
    }
    return notification;
  }
}
