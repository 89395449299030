import React, { useMemo, useState, useCallback } from "react";
import {
  PageContainer,
  PageContent
} from "../../../Components/PageUtilities/PageUtilities";
import {
  ServerAwareProvider,
  ServerAwareQueryInput,
  GridRequestProvider,
  useServerAwareState
} from "../../../Components/CGrid/ServerGrid";
import TextEllipsis from "../../../Components/TextEllipsis/TextEllipsis";
import { useAirdeskTitle } from "../../../Hooks/MiscHooks";
import { withUser } from "../../../Contexts/UserContext";
import {
  createPostStatusFilter,
  createSociaAccountFilter,
  createUserFilter
} from "../../../Helpers/FilterHelper";
import { ConnectedAccountProfile } from "../../../Components/Accounts/AccountProfile";
import { spaceSocialPostsSchema } from "../../../config/schema";
import PageHeader from "../../../Components/Headers/PageHeader";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import ServerAwareFilterList from "../../../Components/FilterList/ServerAwareFilterList";
import { FilterViewTypeEnum } from "../../../Components/FilterList/FilterList";
import {
  ServerAwareButton,
  ServerAwareButtons,
  ServerAwareView
} from "../../../Components/GroupButton/GroupButton";
import SocialPostsGrid from "./SocialPostsGrid";
import Modal from "../../../Components/Modal/Modal";
import { ColumnChooser } from "../../../Components/ColumnChooser/ColumnChooser";
import { ActionButtonOptions } from "../../../Components/ExtraActionsButton/ExtraActionButton";
import { moduleTypes } from "../../../Helpers/ModulesHelper";
import { SocialPostButton } from "../../Form/FormButtons";
import {
  SocialPostsEntityGridButtons,
  PostStatusProfile,
  SocialPostsEntityGridChildren
} from "../SocialPostsHelper";

import { GridDateComponent } from "../../../Components/CGrid/CGrid";
import { ConnectedAccountPopup } from "../../Origin/AcountPopup";
import AccountsInRoomStack from "../../../Components/AccountsInRoom/AccountsInRoomStack";
import {
  faCalendarAlt,
  faList,
  faImage,
  faDrawCircle,
  faShareAlt,
  faCalendar,
  faUserPlus
} from "@fortawesome/pro-light-svg-icons";
import {
  faUserPlus as faUserPlusSolid,
  faCalendarAlt as faCalendarAltSolid,
  faSuitcase as faSuitcaseSolid,
  faShareAlt as faShareAltSolid,
  faImage as faImageSolid,
  faDrawCircle as faDrawCircleSolid,
  faPollPeople,
  faBullseyePointer,
  faAlignJustify
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridActionContext } from "../../../Components/ContextMenu/ContextMenu";
import SocialPostsCalendar from "./SocialPostsCalendar/SocialPostsCalendar";
import { ConnectedSocialAccountComponent } from "../../../Components/SocialAccountComponenent/SocialAccountComponent";
import { DefaultColumnsWidth } from "../../../Components/CGrid/GridDefinitions";
import SaveFavoriteButton from "../../AppBar/SaveFavoriteButton";
import { SocialPostSidebarLink } from "../../../Components/EntitySidebarLinks/EntitySidebarLinks";
const ImageComponent = ({ Url }) => {
  const [error, setError] = useState(false);

  const handleError = () => {
    setError(true);
  };

  if (error) {
    return (
      <div
        style={{
          width: 48,
          height: 48,
          borderRadius: "50%",
          border: "1px solid #ff5962"
        }}
        className="text-danger-alt d-flex justify-content-center align-items-center"
      >
        <FontAwesomeIcon size="2x" icon={faImage} />
      </div>
    );
  } else
    return (
      <div>
        <img
          alt=""
          width={48}
          height={48}
          className="d-block"
          src={Url}
          onError={handleError}
        />
      </div>
    );
};

export const socialColumns = [
  {
    columnChooserIcon: faBullseyePointer,
    sticky: true,
    required: true,
    sortable: false,
    width: DefaultColumnsWidth.buttons,
    noTitle: true,
    minimumWidth: 120,
    orderBy: false,
    title: "Actions",
    minSize: DefaultColumnsWidth.buttons,
    field: "button",
    className: "p-2",
    Cell: ({ Data }) => <SocialPostsEntityGridButtons entity={Data} />
    // Cell: ({ Data: value }) => <DealEntityGridButtons entity={value} />
  },
  {
    sticky: true,
    headerIcon: faAlignJustify,
    width: DefaultColumnsWidth.Description,
    sortable: false,
    orderBy: false,
    field: "SocialPost/Description",
    title: <FormattedMessage id={"DESCRIPTION"} />,
    Cell: ({ Data }) => (
      <div className="d-flex align-items-center">
        {Data.AccountsInRoomView && Data.AccountsInRoomView.length > 0 && (
          <span className="mr-2">
            <AccountsInRoomStack
              dontShowLine
              accountsInRoom={Data.AccountsInRoomView}
            />
          </span>
        )}
        <SocialPostSidebarLink className="fs-12" id={Data.Id}>
          <TextEllipsis length={85} truncate text={Data.Text} />
        </SocialPostSidebarLink>
      </div>
    )
  },
  {
    headerIcon: faShareAltSolid,
    filterIcon: faShareAlt,
    width: DefaultColumnsWidth.id,
    field: "SocialPost/Id",
    title: "Nº",
    Cell: ({ Data }) => Data.Id
    // Cell: ({ Data }) => (
    //   <div>
    //     <DealLink id={Data.Id}>{Data.Id}</DealLink>
    //   </div>
    // )
  },
  {
    headerIcon: faImageSolid,
    width: DefaultColumnsWidth.pipeline,
    sortable: false,
    orderBy: false,
    field: "SocialPost/Image",
    title: <FormattedMessage id={"MINIATURES"} />,
    Cell: ({ Data }) => {
      if (Data.Thumbnail) {
        if (Data.Thumbnail.NoThumb) return <div></div>;
        return <ImageComponent Url={Data.Thumbnail.Url} />;
      } else return <div></div>;
    }
  },

  {
    headerIcon: faDrawCircleSolid,
    filterIcon: faDrawCircle,
    width: DefaultColumnsWidth.status,
    field: "SocialPost/Status",
    minSize: DefaultColumnsWidth.minStatusSize,
    title: <FormattedMessage id={"STATUS"} />,
    Cell: ({ Data }) => {
      const { FailErrors, Status } = Data;
      return <PostStatusProfile value={Status} errors={FailErrors} />;
    }
  },

  {
    headerIcon: faSuitcaseSolid,
    width: DefaultColumnsWidth.Origem,
    title: <FormattedMessage id={"ACCOUNT"} />,
    sortable: false,
    orderBy: false,
    field: "SocialPost/SocialAccount",
    Cell: ({ Data }) => {
      //   return null;
      //   const SocialAccount = useSocialAccount(Data.SocialId);
      return <ConnectedSocialAccountComponent SocialAccount={Data.Social} />;
    }
  },
  {
    headerIcon: faPollPeople,
    width: DefaultColumnsWidth.AssignedGroup,
    orderBy: false,
    sortable: false,
    minSize: 145,
    field: "AccountsInRoom",
    title: <FormattedMessage id={"WORKING_IN"} />,
    Cell: ({ Data }) => {
      return <AccountsInRoomStack accountsInRoom={Data.AccountsInRoom} />;
    }
  },
  {
    headerIcon: faCalendarAltSolid,
    filterIcon: faCalendarAlt,
    width: DefaultColumnsWidth.ReceivedDate,
    field: "SocialPost/PostDate",
    title: <FormattedMessage id={"PUBLISHING_DATE"} />,
    Cell: ({ Data }) => {
      return <GridDateComponent removeSlice date={new Date(Data.Schedule)} />;
    }
  },
  {
    headerIcon: faUserPlusSolid,
    filterIcon: faUserPlus,
    width: DefaultColumnsWidth.Company,
    field: "Creator/Name",
    title: <FormattedMessage id={"CREATED_BY"} />,
    Cell: ({ Data }) => <ConnectedAccountPopup account={Data.Creator} />
  },
  {
    headerIcon: faCalendarAltSolid,
    filterIcon: faCalendarAlt,
    width: DefaultColumnsWidth.Description,
    field: "SocialPost/CreationDate",
    title: <FormattedMessage id={"CREATION_DATE"} />,
    Cell: ({ Data }) => (
      <GridDateComponent
        className="text-truncate"
        date={new Date(Data.CreationDate)}
      />
    )
    // <GridColloredEndDateComponent Data={Data} />
  }
];

// const dealPipelineSettings = {
//   field: "Deal/PipelineId",
//   pipelineStatusField: "Deal/PipelineStatusId",
//   type: PipelineTypeEnum.Deal
// };

// const defaultOrderBy = {};

const SocialPostsOverview = (props) => {
  const intl = useIntl();

  useAirdeskTitle(intl.formatMessage({ id: "PUBLICATIONS" }));

  const filterColumns = useMemo(() => {
    return [
      // {
      //   propField: "Text",
      //   icon: <FontAwesomeIcon icon={faAlignJustify} />,
      //   title: intl.formatMessage({ id: "DESCRIPTION" }),
      //   field: "SocialPost/Text",
      //   type: "string",
      // },
      createUserFilter({
        propField: "Creator",
        title: intl.formatMessage({ id: "CREATED_BY" }),
        field: "Creator/Id",
        DescriptionComponent: ({ item, value }) => {
          return (
            <div>
              {intl.formatMessage({ id: "CREATED_BY" })}{" "}
              <ConnectedAccountProfile account={value} />
            </div>
          );
        }
      }),
      createPostStatusFilter({ propField: "Status" }),
      createSociaAccountFilter(),
      {
        propField: "CreationDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "CREATION_DATE",
        field: "SocialPost/CreationDate",
        type: "dateTime"
      },
      {
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "PUBLICATION_DATE",
        field: "SocialPost/PostDate",
        type: "dateTime"
      },
      //   createDealStatusFilter(),

      {
        propField: "Id",
        icon: <FontAwesomeIcon icon={faShareAlt} />,
        title: intl.formatMessage({ id: "PUBLICATION_NR" }),
        field: "SocialPost/Id",
        type: "number"
      }
    ];
  }, [intl]);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemSelection = useCallback((items) => {
    setSelectedItems(items);
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  // let gridColumnLayout = JSON.parse(
  //   window.localStorage.getItem("ar-grid-column-layout")
  // );
  const columnResize = true;

  //   const requestRef = useRef();

  //   useEffect(() => {
  // 	console.log(requestRef)
  //   }, [])

  return (
    <PageContainer>
      <GridActionContext.Provider value={SocialPostsEntityGridChildren}>
        <ServerAwareProvider
          columns={socialColumns}
          filterColumns={filterColumns}
          moduleType={moduleTypes.social}
          // defaultOrderBy={defaultOrderBy}
          schema={spaceSocialPostsSchema}
        >
          <Modal
            containerClassName=""
            isOpen={modalIsOpen}
            onClose={closeModal}
          >
            <ColumnChooser
              grid={"socialPosts"}
              Name="socialPosts"
              schemaView={spaceSocialPostsSchema}
              Columns={socialColumns}
              onClose={closeModal}
              setIsOpen={setModalIsOpen}
              isOpen={modalIsOpen}
            />
          </Modal>

          <PageHeader
            schema={spaceSocialPostsSchema}
            toggleFilterList
            title={<FormattedMessage id="PUBLICATIONS" />}
          >
            <div className="d-flex justify-content-start flex-1 align-items-center">
              <SocialPostButton />
              <ServerAwareQueryInput
                placeholder={intl.formatMessage({ id: "SEARCH" })}
                className="ml-2"
              />
            </div>
            <div className="d-flex">
              <SaveFavoriteButton />
              <ServerAwareButtons className="ml-2">
                <ServerAwareButton icon={faList} />
                <ServerAwareButton icon={faCalendar} type="calendar" />
                {/* <ServerAwareButton icon={faStream} type="timeline" />

                  <ServerAwareButton icon={faColumns} type="board" /> */}
              </ServerAwareButtons>
              {/* <ServerAwareButtons className="ml-2">
                <ServerAwareButton icon={faList} />
                <ServerAwareButton icon={faCalendarAlt} type="timeline" />
              </ServerAwareButtons> */}

              <ActionButtonOptions
                disableAllExports
                moduleType={moduleTypes.socialPost}
                enableColumnEdit={() => {
                  setModalIsOpen(true);
                }}
                columnResize={columnResize}
                spaceType={spaceSocialPostsSchema.name}
                type="socialPosts"
              />
            </div>
          </PageHeader>
          <PageContent>
            <ServerAwareFilterList
              filterType={FilterViewTypeEnum.SocialPosts}
            />

            {/* <ServerAwareView> */}
            <ServerAwareView>
              <GridRequestProvider toggleColumnChooser={setModalIsOpen}>
                <SocialPostsGrid
                  columnResize={columnResize}
                  className="flex-1"
                  onItemSelect={handleItemSelection}
                  selectedItems={selectedItems}
                />
              </GridRequestProvider>
            </ServerAwareView>
            <ServerAwareView type="calendar">
              <SocialPostsCalendar />
            </ServerAwareView>
          </PageContent>
        </ServerAwareProvider>
      </GridActionContext.Provider>
    </PageContainer>
  );
};

export const SocialPostFormButton = ({ ...props }) => {
  const { refetch } = useServerAwareState();

  return <SocialPostButton formProps={{ refetch }} {...props} />;
};

export default withUser(injectIntl(SocialPostsOverview));
