import { createPdfButton } from "../Pdf/PdfHelper";
import { moduleTypes } from "../../Helpers/ModulesHelper";

export const TicketsPdfButton = createPdfButton({
  ///BuildPdf/{timechargeid}/
  generateUrl: ({ id }) => `PDF/ExportTicket/ToFile`,
  //   generateUrl: ({ id }) => `interventions/Export/BuildPdf/${id}/`,
  download: true,
  //   buildUrl: ({ id, token }) => `interventions/Export/BuildPdf/${id}/${token}`,
  chooseDate: false,
  moduleType: moduleTypes.tickets,
});
