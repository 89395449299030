import React, { useContext, useMemo, useState } from "react";
import LoadableButton from "../Button/LoadableButton";
import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import classnames from "classnames";
import { FormCanEditContext, Form } from ".";
import Button from "../Button/Button";
import { v1 as uuidv1 } from "uuid";
import {
  useOnboarding,
  OnboardingUpdateContext
} from "../../Containers/SpaceOnboarding/SpaceOnboardingHelper";
import Modal from "../Modal/Modal";
import FormInput from "./FormInput/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookArrowRight,
  faBookArrowUp
} from "@fortawesome/pro-solid-svg-icons";
import InformationComponent from "../InformationComponent/InformationComponent";
import { useSpace } from "../../Contexts/SpaceContext";
import {
  FormTemplatesContext,
  TemplateFormSorter
} from "./FormTemplatesHelper";
import { useSpacePost } from "../../Helpers/IOClient";
import { useToast } from "../Toast/ToastProvider";
import { handleError } from "../../Helpers/MiscHelper";
import { useSidebarChanges } from "../Sidebar/SidebarV2";

const TemplateSaver = ({ schema, form, onClose }) => {
  const unresolvedFormTemplates = useContext(FormTemplatesContext);

  const formTemplates = useMemo(() => {
    return unresolvedFormTemplates || [];
  }, [unresolvedFormTemplates]);

  const formTemplatesObj = useMemo(() => {
    const formNames = [];
    const formKeys = [];
    for (const t of formTemplates) {
      formKeys.push(t.Key);
      const resolvedVal = JSON.parse(t.Value);
      formNames.push(resolvedVal.name);
    }
    return { formNames, formKeys };
  }, [formTemplates]);

  const space = useSpace();
  const intl = useIntl();
  const [name, setName] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const {
      target: { value }
    } = e;
    setError(false);
    setName(value);
  };

  const createToast = useToast();

  const [post, { loading }] = useSpacePost("Preference/SetMultiple", null, {
    onSuccess: ({ data }) => {
      createToast({
        pos: "tm",
        type: "success",
        description: intl.formatMessage({ id: "SUCCESS_SAVED" })
      });
      onClose();
    },
    onError: ({ error }) => {
      handleError(createToast, error);
    }
  });

  const handleSubmit = async () => {
    if (name === null || name.match(/^ *$/) !== null) {
      setError(true);
      return;
    }
    if (formTemplatesObj.formNames.find((e) => e === name)) {
      setError(true);
      return;
    }
    const uui = uuidv1();
    const newKey = `air-form-templates-by-space-${space.Id}-view-${schema.name}-key-${uui}`;
    const { Files, ...restForm } = form;
    const value = JSON.stringify({
      name,
      form: restForm
    });

    const resolvedBody = [
      {
        Key: newKey,
        Value: value
      },
      {
        Key: `air-form-templates-by-space-${space.Id}-view-${schema.name}`,
        Value: JSON.stringify([...formTemplatesObj.formKeys, newKey])
      }
    ];

    post(resolvedBody);
  };

  return (
    <div className="w-450px">
      <div
        style={{ height: 51 }}
        className="text-black disable-selection px-4 d-flex fw-medium align-items-center ar-entity-summary-header"
      >
        <FormattedMessage id="SAVE_FORM" />
      </div>
      <div className="p-4">
        <FormInput
          text={intl.formatMessage({ id: "NAME" })}
          className="mb-4"
          value={name}
          error={error}
          errorMessage={intl.formatMessage({ id: "REGISTER_INVALID_NAME" })}
          onChange={handleChange}
        />
        <div className="d-flex justify-content-end">
          <Button type="button" onClick={onClose} vType="link-danger mr-3">
            <FormattedMessage id="CANCEL" />
          </Button>
          <LoadableButton
            type="button"
            isLoading={loading}
            disabled={!name || name.match(/^\s*$/)}
            onClick={handleSubmit}
          >
            <FormattedMessage id="SAVE" />
          </LoadableButton>
        </div>
      </div>
    </div>
  );
};

export const SideMenuFormFooterBase = ({ children, className }) => {
  const onboarding = useOnboarding();

  if (onboarding)
    return <div className={classnames("py-3 px-4", className)}>{children}</div>;

  return (
    <div className={classnames("py-3 px-4 shadow-top", className)}>
      {children}
    </div>
  );
};

const SideMenuForm = ({
  children,
  formStatus,
  intl,
  footerComponent,
  footerSubComponent,
  disableButton,
  classNames,
  ...props
}) => {
  const { isProcessing, isFetchingDetails, validation, submit, error } =
    formStatus;

  const canEdit = useContext(FormCanEditContext);

  const onboarding = useOnboarding();
  const onboardingUpdate = useContext(OnboardingUpdateContext);
  if (error)
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        We had an error
      </div>
    );

  if (isFetchingDetails)
    return <LoaderSpinner className="text-primary" size="lg" center />;

  if (onboarding) {
    const { loading } = onboardingUpdate;

    return (
      <>
        {children}
        {canEdit
          ? footerComponent
            ? footerComponent
            : footerComponent === undefined && (
                <>
                  {footerSubComponent}
                  <LoadableButton
                    isLoading={isProcessing || loading}
                    disabled={disableButton || validation.hasErrors}
                    vType="primary"
                    onClick={(e) => {
                      //;
                      submit(e);
                    }}
                  >
                    {intl.formatMessage({ id: "CONFIRM" })}
                  </LoadableButton>
                </>
              )
          : null}
      </>
    );
  }

  return (
    <div className="d-flex flex-column h-100">
      <div
        className={classnames("d-flex flex-column flex-1", {
          "of-y-auto": !props.disableOverflow
        })}
      >
        <div
          className={
            classNames
              ? classNames
              : classnames("px-3 pt-3", { "of-y-auto": !props.disableOverflow })
          }
        >
          {children}
        </div>
      </div>
      {canEdit
        ? footerComponent
          ? footerComponent
          : footerComponent === undefined && (
              <SideMenuFormFooterBase className="d-flex justify-content-end align-items-center shadow-top">
                {footerSubComponent}
                <LoadableButton
                  isLoading={isProcessing}
                  disabled={disableButton || validation.hasErrors}
                  vType="primary"
                  onClick={(e) => {
                    //;
                    submit(e);
                  }}
                >
                  {intl.formatMessage({ id: "CONFIRM" })}
                </LoadableButton>
              </SideMenuFormFooterBase>
            )
        : null}
    </div>
  );
};

export default injectIntl(SideMenuForm);

export const SideForm = (props) => {
  const { className, ...rest } = props;
  // const isDetails = useContext(DetailsFormContext);
  return (
    <Form
      className={classnames("d-flex h-100 flex-column", className)}
      {...rest}
    />
  );
};

export const SideFormContent = (props) => {
  const {
    className,
    children,
    style
    // noPadding
  } = props;
  return (
    <div style={style} className="d-flex flex-column of-y-auto flex-1">
      <div
        // style={{ padding: !noPadding && "48px 30px 30px 30px" }}
        className={className !== undefined ? className : "of-y-auto"}
      >
        {children}
      </div>
    </div>
  );
};

export const SideFormCancelContext = React.createContext();

export const DeleteModalContent = ({
  closeModal,
  loadingDelete,
  handleDelete
}) => {
  const [deleteText, setDeleteText] = useState("");
  const intl = useIntl();
  const deleteTranslated = intl.formatMessage({ id: "DELETE" }).toUpperCase();
  return (
    <div className="p-4 w-450px d-flex flex-column">
      <div className="fw-medium text-black fs-18 mb-3">
        <FormattedMessage id="DELETE_ALL_MODAL_CONFIRMATION" />
      </div>
      <FormInput
        className="mb-4"
        value={deleteText}
        placeholder={deleteTranslated}
        onChange={(e) => {
          const { value } = e.target;
          if (
            value.charAt(value.length - 1) ===
            deleteTranslated.charAt(value.length - 1)
          )
            setDeleteText(value);
        }}
      />
      <div className="d-flex justify-content-end align-items-center">
        <Button
          type="button"
          disabled={loadingDelete}
          vType="link-danger"
          className="mr-3"
          onClick={closeModal}
        >
          <FormattedMessage id="CANCEL" />
        </Button>
        <LoadableButton
          type="button"
          style={{
            border: deleteTranslated !== deleteText ? "" : "1px solid #ff5962"
          }}
          isLoading={loadingDelete}
          disabled={deleteTranslated !== deleteText}
          vType="danger-ghost"
          onClick={() => {
            handleDelete();
          }}
        >
          <FormattedMessage id="DELETE" />
        </LoadableButton>
      </div>
    </div>
  );
};

export const SideFormFooter = (props) => {
  const {
    children,
    isLoading,
    disabled,
    printLoading,
    canDelete,
    handleDelete,
    handleChange,
    confirmText,
    deleteLoading,
    subComponent,
    showPrint,
    handlePrint,
    handleDiffrentOnClick,
    className,
    showFooterAnyway,
    form,
    schema
  } = props;
  const handleCancelation = useContext(SideFormCancelContext);
	const [toggleSidebarChanges] = useSidebarChanges();
  const { Id } = form || {};
  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleSidebarChanges(false);
    handleDiffrentOnClick();
  };

  const [saveTemplateModalStatus, setSaveTemplateModalStatus] = useState(false);
  const [useTemplateModalStatus, setUseTemplateModalStatus] = useState(false);
 
  const toggleOpenModelStatus = () => {
    setSaveTemplateModalStatus(!saveTemplateModalStatus);
  };
  const toggleOpenUseModelStatus = () => {
    setUseTemplateModalStatus(!useTemplateModalStatus);
  };

  //   const isDetailsForm = useIsDetailsForm();
  const canEdit = useContext(FormCanEditContext);

  const [modalState, setModalState] = useState(false);

  const closeModal = () => {
    setModalState(false);
  };
  const openModal = () => {
    setModalState(true);
  };

  if (!showFooterAnyway && !canEdit) return null;

  if (children) return children;

  if (canDelete && handleDelete) {
    return (
      <SideMenuFormFooterBase
        className={classnames(
          // "details-form-footer d-flex justify-content-between align-items-center shadow-top",
          "d-flex justify-content-between align-items-center shadow-top",
          className
        )}
      >
        <Modal isOpen={modalState} onClose={closeModal}>
          <DeleteModalContent
            loadingDelete={deleteLoading}
            closeModal={closeModal}
            handleDelete={handleDelete}
          />
        </Modal>
        <LoadableButton
          isLoading={isLoading}
          disabled={disabled}
          vType="link-danger"
          onClick={openModal}
          type="button"
          className="fw-bold"
        >
          <span style={{ fontWeight: 500 }}>
            <FormattedMessage id="DELETE" />
          </span>
        </LoadableButton>
        <div className="d-flex align-items-center">
          {subComponent ? (
            subComponent
          ) : (
            <div>
              {handleCancelation && (
                <Button
                  vType="link-danger"
                  type="button"
                  className="mr-3"
                  onClick={handleCancelation}
                >
                  <FormattedMessage id="CANCEL" />
                </Button>
              )}
            </div>
          )}
          {handleDiffrentOnClick ? (
            <div className="d-flex align-items-center">
              {showPrint && (
                <Button
                  vType="link-primary"
                  type="button"
                  className="mr-4"
                  onClick={handlePrint}
                >
                  <FormattedMessage id="PRINT" />
                </Button>
              )}
              <LoadableButton
                onClick={handleOnClick}
                isLoading={isLoading}
                disabled={disabled}
                vType="primary"
                type="button"
              >
                {confirmText ? (
                  confirmText
                ) : Id ? (
                  <FormattedMessage id="SAVE" />
                ) : (
                  <FormattedMessage id="SUBMIT" />
                )}
              </LoadableButton>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              {showPrint && (
                <Button
                  vType="link-primary"
                  type="button"
                  className="mr-4"
                  onClick={handlePrint}
                >
                  <FormattedMessage id="PRINT" />
                </Button>
              )}
              <LoadableButton
                isLoading={isLoading}
                disabled={disabled}
                vType="primary"
                type="submit"
              >
                {confirmText ? (
                  confirmText
                ) : Id ? (
                  <FormattedMessage id="SAVE" />
                ) : (
                  <FormattedMessage id="SUBMIT" />
                )}
              </LoadableButton>
            </div>
          )}
        </div>
      </SideMenuFormFooterBase>
    );
  }

  return (
    <>
      <Modal isOpen={saveTemplateModalStatus} onClose={toggleOpenModelStatus}>
        <TemplateSaver
          schema={schema}
          form={form}
          onClose={toggleOpenModelStatus}
        />
      </Modal>
      <Modal isOpen={useTemplateModalStatus} onClose={toggleOpenUseModelStatus}>
        <TemplateFormSorter
          schema={schema}
          handleChange={handleChange}
          onClose={toggleOpenUseModelStatus}
        />
      </Modal>
      <SideMenuFormFooterBase
        className={classnames(
          "details-form-footer d-flex justify-content-between align-items-center shadow-top",
          className
        )}
      >
        {form && !form.Id && schema && (
          <div className="d-flex align-items-center">
            <InformationComponent
              text={<FormattedMessage id="ADD_TEMPLATE" />}
              className="mr-2"
            >
              <Button
                vType="primary-ghost"
                type="button"
                size="sm"
                onClick={toggleOpenModelStatus}
              >
                <FontAwesomeIcon icon={faBookArrowUp} />
              </Button>
            </InformationComponent>
            <InformationComponent
              text={<FormattedMessage id="USE_TEMPLATE" />}
              className="mr-2"
            >
              <Button
                vType="primary-ghost"
                type="button"
                size="sm"
                onClick={toggleOpenUseModelStatus}
              >
                <FontAwesomeIcon icon={faBookArrowRight} />
              </Button>
            </InformationComponent>
          </div>
        )}
        {subComponent ? (
          subComponent
        ) : (
          <div>
            {handleCancelation && (
              <Button
                vType="link-danger"
                type="button"
                onClick={handleCancelation}
              >
                <FormattedMessage id="CANCEL" />
              </Button>
            )}
          </div>
        )}
        {handleDiffrentOnClick ? (
          <div className="d-flex align-items-center">
            {showPrint && (
              <LoadableButton
                isLoading={printLoading}
                vType="link-primary"
                type="button"
                className="mr-3"
                onClick={handlePrint}
              >
                <FormattedMessage id="PRINT" />
              </LoadableButton>
            )}
            <LoadableButton
              onClick={handleOnClick}
              isLoading={isLoading}
              disabled={disabled}
              vType="primary"
              type="button"
            >
              {confirmText ? (
                confirmText
              ) : Id ? (
                <FormattedMessage id="SAVE" />
              ) : (
                <FormattedMessage id="SUBMIT" />
              )}
            </LoadableButton>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            {showPrint && (
              <LoadableButton
                isLoading={printLoading}
                vType="link-primary"
                type="button"
                className="mr-3"
                onClick={handlePrint}
              >
                <FormattedMessage id="PRINT" />
              </LoadableButton>
            )}
            <LoadableButton
              isLoading={isLoading}
              disabled={disabled}
              vType="primary"
              type="submit"
            >
              {confirmText ? (
                confirmText
              ) : Id ? (
                <FormattedMessage id="SAVE" />
              ) : (
                <FormattedMessage id="SUBMIT" />
              )}
            </LoadableButton>
          </div>
        )}
      </SideMenuFormFooterBase>
    </>
  );
};
