import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useParams
} from "react-router-dom";
import SpaceOnboardingCompany from "./SpaceOnboardingCompany";
import {
  SpaceContext,
  useSpaceEntity,
  useSpace
} from "../../Contexts/SpaceContext";
import {
  OnboardingContext,
  useSpaceOnboardingState,
  onboardingStateEnum,
  OnboardingStateContext,
  OnboardingAutoRedirect
} from "./SpaceOnboardingHelper";
import SpaceOnboardingDepartments from "./SpaceOnboardingDepartments/SpaceOnboardingDepartments";
import "./SpaceOnboarding.css";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import SpaceOnboardingInvites from "./SpaceOnboardingInvites/SpaceOnboardingInvitres";

const InnerSpaceOnboarding = () => {
  const match = useRouteMatch();
  const space = useSpace();
  const s = useSpaceOnboardingState(space.Id);

  const { obState, loading, error } = s;

  if (loading || (error && error.status !== 404))
    return <LoaderSpinner center size="sm" />;

  if (obState === onboardingStateEnum.done)
    return <Redirect to={`/s/${space.Id}/welcome`} />;

  return (
    <OnboardingContext.Provider value={true}>
      <SpaceContext.Provider value={space}>
        <OnboardingStateContext.Provider value={s}>
          <Switch>
            <Route path={`${match.path}/company`}>
              <SpaceOnboardingCompany path={match.url} />
            </Route>
            <Route path={`${match.path}/departments`}>
              <SpaceOnboardingDepartments path={match.url} />
            </Route>
            <Route path={`${match.path}/invites`}>
              <SpaceOnboardingInvites path={match.url} />
            </Route>
            {/* <Route path={`${match.path}/teams`}>
                <SpaceOnboardingTeams path={match.url} />
              </Route> */}
            <OnboardingAutoRedirect url={match.url} />
          </Switch>
        </OnboardingStateContext.Provider>
      </SpaceContext.Provider>
    </OnboardingContext.Provider>
  );
};

const SpaceOnboarding = () => {
  const { spaceId } = useParams();
  const space = useSpaceEntity(spaceId);

  if (!space) return <Redirect to="/s" />;

  return (
    <SpaceContext.Provider value={space}>
      <InnerSpaceOnboarding />
    </SpaceContext.Provider>
  );
};

export default SpaceOnboarding;
