import React from "react";
import { usePipelineStatus, useTask } from "../../Hooks/EntityHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faDrawCircle,
  faSuitcase,
  faUserCheck,
  faUserClock,
  faUsersMedical,
  faUserTie
} from "@fortawesome/pro-solid-svg-icons";
import { ProjectStatusProfileWithIcon } from "../../Components/Projects/ProjectProfile";
import {
  spaceTasksSchema,
  spaceInterventionsSchema
} from "../../config/schema";
import SplitCol from "../../Components/SplitCol/SplitCol";
import {
  ProjectHourPercentageBar,
  EstimatedMonthsBar
} from "../../Components/PercentageBar/PercentageBar";
import Moment from "react-moment";
import { ConnectTaskEntity } from "../../Components/Tasks/TaskProfile";
import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";
import { OriginChild } from "./OriginChildren/OriginChildren";
import OriginOverview from "./OriginOverview";
import {
  OriginContainer,
  OriginDate,
  OriginHeader,
  OriginItemGroup
} from "../../Components/Origin/Origin";
import { createOriginPopup } from "./Origin";
import { ConnectedClientOrigin } from "./ClientOrigin";
import { ConnectedAccountPopup } from "./AcountPopup";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { TaskEntityGridButtons, TasksStatusTypes } from "../Tasks/TasksHelper";
import classnames from "classnames";
import { TaskIcon } from "../../Components/Common/EntityIcons";
import { useIntl } from "react-intl";
import { usePopperResize } from "../../Components/Popper/Popper";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import Button from "../../Components/Button/Button";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import { PipelineStatusProfile } from "../AdvancedMultiInputs/PipelineDropdowns";
import { useSpace } from "../../Contexts/SpaceContext";
import { ConnectedContactPopup } from "./ContactPopup";
import { faListCheck } from "@fortawesome/pro-solid-svg-icons";
import { useConnectedSchemaGetter } from "../../Helpers/HelperHooks";

export const TaskOriginAnchor = React.forwardRef(
  ({ task, children, onClick, className, dynamicFont, ...rest }, ref) => {
    const openForm = useEntitySummarySidebar();
    if (!task) return null;
    const { IsDeleted } = task;
    return (
      <div
        ref={ref}
        className={classnames("link-color text-truncate", className, {
          "item-was-deleted": IsDeleted
        })}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <>
            <div
              className={classnames(
                "link-color d-flex align-items-center text-truncate",
                {
                  "fs-12": !dynamicFont
                }
              )}
            >
              <div className="mr-1 d-flex justify-content-center align-items-center">
                <ProjectStatusProfileWithIcon
                  Icon={<FontAwesomeIcon icon={faListCheck} />}
                  inline
                  hideName
                  status={task.Status || task.Attributes}
                />
              </div>
              <Button
                vType="link-primary"
                className="EntityLinkClassID fs-12 text-black"
                onClick={() => {
                  openForm({
                    type: moduleTypes.tasks,
                    props: {
                      id: task.Id
                    }
                  });
                }}
              >
                <span>{task.Name}</span>
              </Button>
            </div>
            {/* {Id && <div className="fs-10">Nº: {Id}</div>} */}
          </>
        )}
      </div>
    );
  }
);
export const ConnectedTaskOriginAnchor = ({ task, ...rest }) => {
  const resolvedTask = useTask(task);

  const Result = useConnectedSchemaGetter(task, spaceTasksSchema);

  if (Result) return Result;

  return <TaskOriginAnchor task={resolvedTask} {...rest} />;
};

export const TaskOriginPopup = ({ task }) => {
  const intl = useIntl();
  const space = useSpace();
  const t = useTask(task.Id);
  usePopperResize();
  const {
    Name,
    Id,
    // Status,
    Manager,
    ReceivedDate,
    CreationDate,
    EndDate,
    Contact,
    BeginDate,
    PipelineStatus,
    Creator,
    Client,
    IndirectClient
  } = t;
  const connectedPipelineStatus = usePipelineStatus(PipelineStatus);
  const {
    data: dataTasksTimes
    // loading: loadingProjects,
    // error: errorProjects
  } = useSpaceQuery(
    `interventions?$orderby=TimeCharge/Id%20desc&$filter=((TimeCharge/ProjectTaskId%20eq%20%27${Id}%27))&$top=5`,
    [spaceInterventionsSchema]
  );

  const hasChildren = Boolean(dataTasksTimes && dataTasksTimes.length > 0);

  return (
    <OriginContainer>
      <OriginHeader
        Dif
        className="mb-1"
        date={CreationDate}
        type="Tarefa"
        entity="TASK"
        Icon={<TaskIcon className="mr-2" />}
        EntityButtons={<TaskEntityGridButtons noColors entity={t} minimal />}
        id={Id}
        link={`/s/${space.Id}/tasks/${Id}`}
        title={Name}
      />

      {/* <div className="d-flex justify-content-between px-3">
        <div className="d-flex align-items-center text-black fw-medium">
          <TaskIcon className="mr-1" />
          <div className="mr-1">Tarefa</div>
          {Id}
        </div>
        <OriginDate className="fs-14" date={ReceivedDate} />
	  </div> */}
      <div className="mx-3 mb-1">
        {/* <div className="text-black fs-14 mb-2">{Name}</div> */}
        <div className="mb-1 d-flex align-items-center justify-content-between">
          <PipelineStatusProfile pipeline={connectedPipelineStatus} />

          <div>
            <OriginDate className="fs-12" date={ReceivedDate || CreationDate} />
          </div>
        </div>

        <ProjectHourPercentageBar
          title={intl.formatMessage({ id: "INPUTED_HOURS" })}
          className="mb-2"
          project={t}
        />
        <EstimatedMonthsBar
          title={intl.formatMessage({ id: "DURATION" })}
          EndDate={EndDate}
          BeginDate={BeginDate}
        />
      </div>
      {/* <ConnectedBreadcrumbs className="px-3 mb-2" value={{
        type: "Project",
        data: Id
      }} /> */}

      <SplitCol
        className="mb-1"
        left={
          <OriginItemGroup
            icon={faCalendarAlt}
            title={intl.formatMessage({ id: "START_DATE" })}
          >
            <Moment
              className="fs-12 text-black"
              date={BeginDate}
              format="DD/MM/YYYY HH:mm"
            />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faCalendarAlt}
            title={intl.formatMessage({ id: "END_DATE" })}
          >
            <Moment
              icon={faCalendarAlt}
              className="fs-12 text-black"
              date={EndDate}
              format="DD/MM/YYYY HH:mm"
            />
          </OriginItemGroup>
        }
      />

      <SplitCol
        className="mb-1 fs-12"
        left={
          <OriginItemGroup
            icon={faSuitcase}
            title={intl.formatMessage({ id: "ACCOUNT" })}
            className="mb-1"
          >
            <ConnectedClientOrigin client={Client || IndirectClient}>
              <ConnectedClientProfile
                showId
                client={Client || IndirectClient}
              />
            </ConnectedClientOrigin>
          </OriginItemGroup>
        }
        right={
          Contact ? (
            <OriginItemGroup
              icon={faUserTie}
              title={intl.formatMessage({ id: "CONTACT" })}
            >
              <ConnectedContactPopup contact={Contact} />
            </OriginItemGroup>
          ) : (
            <OriginItemGroup
              icon={faUsersMedical}
              title={intl.formatMessage({ id: "CREATED_BY" })}
            >
              <ConnectedAccountPopup account={Creator} />
            </OriginItemGroup>
          )
        }
      />

      {/* <OriginItemGroup title="Nota Interna" className="mb-2">
        <HTMLInput value={InternalDescription} preview />
      </OriginItemGroup> */}
      <SplitCol
        className="mb-1 fs-12"
        left={
          <OriginItemGroup
            icon={faUserCheck}
            title={intl.formatMessage({ id: "ASSIGNED_TO" })}
          >
            <ConnectedAccountPopup account={Manager} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faDrawCircle}
            title={intl.formatMessage({ id: "ORIGIN" })}
          >
            <OriginOverview entity={t} />
          </OriginItemGroup>
        }
      />

      {/* <SplitCol
        className="mb-2"
        left={
          <OriginItemGroup title="Tempos">
            {t.Children.map((itm, index) => (
              <OriginChild key={index} item={{ ...itm, type: "timecharge" }} />
            ))}
          </OriginItemGroup>
        }
      /> */}

      {dataTasksTimes ? (
        <OriginItemGroup
          icon={faUserClock}
          show={hasChildren}
          title={intl.formatMessage({ id: "TIMES" })}
          className="mb-1"
        >
          {dataTasksTimes.map((itm, index) => {
            const resolvedItem = getEntity(spaceInterventionsSchema, itm);

            return (
              <OriginChild
                className="mb-1"
                key={index}
                item={{ ...resolvedItem, Type: "timecharge" }}
              />
            );
          })}
        </OriginItemGroup>
      ) : (
        <OriginItemGroup
          title={intl.formatMessage({ id: "TIMES" })}
          className="mb-1"
        >
          <LoaderSpinner size={"xs"} />
        </OriginItemGroup>
      )}
      <div className="pb-3"></div>
      {/* <div className="d-flex align-items-center justify-content-center shadow-top py-3 mt-3">
        {/* <TaskFormButton id={Id} canEdit={CanEdit} className="mx-1" /> */}
      {/* <TaskEntityButtons entity={t} /> */}
      {/* <InterventionFormButton
          alt
          className="mx-1"
          formProps={{ taskOrigin: t, taskId: Id }}
        /> */}
      {/* </div>  */}
    </OriginContainer>
  );
};

export const taskOriginOptions = {
  schema: spaceTasksSchema,
  valueProp: "task"
};

export const TaskOrigin = createOriginPopup(
  TaskOriginAnchor,
  TaskOriginPopup,
  taskOriginOptions
);

export default TaskOrigin;

export const ConnectedTaskOrigin = ConnectTaskEntity(TaskOrigin);

export const DifrentTaskProfile = ({ task }) => {
  if (!task) return null;
  const { Status } = task;

  let colorStatus;

  switch (Status) {
    case TasksStatusTypes.open:
      colorStatus = "status-in-progress-colors";
      break;
    case TasksStatusTypes.canceled:
      colorStatus = "status-canceled-colors";
      break;
    case TasksStatusTypes.closed:
      colorStatus = "status-closed-colors";
      break;
    case TasksStatusTypes.draft:
      colorStatus = "status-orange-colors";
      break;
    default:
      break;
  }

  return (
    <div className={classnames("air-new-profiles-circle", colorStatus)}>
      <FontAwesomeIcon icon={faListCheck} size="xl" />
    </div>
  );
};
