import React from "react";
import { useTask } from "../../Hooks/EntityHooks";

export const ConnectTaskEntity = Component => {
  return ({ task, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const t = useTask(task);
    return <Component task={t} {...rest} />;
  };
};
