import React, { useCallback, useEffect, useContext } from "react";
import "./ClassificationPreview.css";
import Table, { Tr } from "../../../Components/Table";
import FormattedTimePreview from "../../../Components/FormattedTimePreview/FormattedTimePreview";
import { useIntervention } from "../../../Hooks/EntityHooks";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import HourPicker from "../../../Components/HourPicker/HourPicker";
import DeleteButton from "../../../Components/Button/DeleteButton";
import ClassificationTimechargePreview from "../ClassificationTimechargePreview";
import IntervalHourPicker from "../../../Components/IntervalHourPicker/IntervalHourPicker";
import { FormattedMessage } from "react-intl";

const InterventionTimeLimitation = React.createContext();

const ClassificatonHourPicker = ({
  onChange,
  preview,
  InputtedDuration,
  intervention,
  removable
}) => {
  const data = React.useContext(InterventionTimeLimitation);

  useEffect(() => {
    if (data) {
      const { IntervalMinimum, RoundingMargin } = data;
      if (IntervalMinimum > 0) {
        const remainer = InputtedDuration % IntervalMinimum;
        if (remainer !== 0) {
          if (remainer > RoundingMargin) {
            onChange(intervention.Id, {
              InputtedDuration: InputtedDuration - remainer + IntervalMinimum
            });
          } else {
            onChange(intervention.Id, {
              InputtedDuration: InputtedDuration - remainer
            });
          }
        }
      }
    }
  }, [InputtedDuration, data, intervention, onChange]);

  if (data) {
    const { IntervalMinimum, RoundingMargin } = data;

    return (
      <div className="d-flex w-100 justify-content-between align-items-center">
        <IntervalHourPicker
          preview={preview}
          interval={IntervalMinimum}
          margin={RoundingMargin}
          className="flex-1 mr-2"
          value={InputtedDuration}
          enableDate={false}
          onChange={(v) =>
            onChange(intervention.Id, {
              InputtedDuration: v
            })
          }
        />
        {(!preview || removable) && (
          <DeleteButton
            className="d-inline-block"
            onClick={() => {
              onChange(intervention.Id);
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div className="d-flex w-100 justify-content-between align-items-center">
      <HourPicker
        preview={preview}
        className="flex-1 mr-2"
        value={InputtedDuration}
        enableDate={false}
        onChange={(v) =>
          onChange(intervention.Id, {
            InputtedDuration: v
          })
        }
      />
      {(!preview || removable) && (
        <DeleteButton
          className="d-inline-block"
          onClick={() => {
            onChange(intervention.Id);
          }}
        />
      )}
    </div>
  );
};

const CustomTr = React.memo(
  ({
    value,
    onChange,
    isNew,
    removable,
    preview,
    InterventionsTimeLimitation,
    ...rest
  }) => {
    const { intervention: iId, ...valueRest } = value;
    const intervention = useIntervention(iId);

    const handleNewChange = useCallback(
      (i, state) => {
        onChange((data) => {
          const type = isNew ? "new" : "billing";

          const index = data[type].findIndex((v) => v.intervention === i);

          const newValues = [...data[type]];
          if (state) {
            newValues[index] = { ...newValues[index], ...state };
          } else {
            newValues.splice(index, 1);
          }
          const update = {
            ...data,
            [type]: newValues
          };
          if (!isNew && !state) update.removedBilling.push(i);
          return update;
        });
      },
      [isNew, onChange]
    );

    const v = {
      ...valueRest,
      onChange: handleNewChange,
      intervention,
      InputtedDuration: value.InputtedDuration,
      removable,
      preview
    };

    return (
      <InterventionTimeLimitation.Provider value={InterventionsTimeLimitation}>
        <Tr className={isNew ? "new" : undefined} value={v} {...rest} />
      </InterventionTimeLimitation.Provider>
    );
  }
);

const columns = [
  {
    title: <FormattedMessage id={"RECORD"} />,
    Cell: ({ intervention }) => (
      <ClassificationTimechargePreview id={intervention.Id} />
      // <InterventionOrigin
      //   placement="left"
      //   className="text-primary py-2"
      //   intervention={intervention}
      // >
      //   <InterventionIcon className="mr-1" />
      //   <span>{intervention.Id}</span>
      // </InterventionOrigin>
    )
  },
  {
    width: 100,
    title: <FormattedMessage id={"DURATION"} />,
    Cell: ({ intervention }) => (
      <FormattedTimePreview value={intervention.EstimatedCost} asHourMinute />
    )
  },
  {
    width: 120,
    title: <FormattedMessage id={"TRAVEL"} />,
    Cell: ({ intervention }) => (
      <FormattedTimePreview value={intervention.DeslocationTime} asHourMinute />
    )
  },
  {
    width: 100,
    title: <FormattedMessage id={"PAUSES"} />,
    Cell: ({ intervention }) => (
      <FormattedTimePreview value={intervention.TotalPauses} asHourMinute />
    )
  },
  {
    width: 120,
    title: <FormattedMessage id={"INEFFICIENT_HOURS_ABV"} />,
    Cell: ({ intervention }) => (
      <FormattedTimePreview
        value={intervention.InefficientDuration}
        asHourMinute
      />
    )
  },

  {
    title: <FormattedMessage id={"INPUTED_HOURS"} />,

    Cell: ({
      InputtedDuration,
      intervention,
      onChange,
      preview,
      removable
    }) => {
      return (
        <ClassificatonHourPicker
          onChange={onChange}
          InputtedDuration={InputtedDuration}
          intervention={intervention}
          preview={preview}
          removable={removable}
        />
      );
    }
  }
];

const RoundingMargin = () => {
  const { RoundingMargin } = useContext(ContractDataProvider);

  return <FormattedTimePreview value={RoundingMargin} asHourMinute />;
};

const MiniumIntervalPeriod = () => {
  const { IntervalMinimum } = useContext(ContractDataProvider);

  return <FormattedTimePreview value={IntervalMinimum} asHourMinute />;
};

const columnsForContractOrigin = [
  {
    title: <FormattedMessage id={"RECORD"} />,
    Cell: ({ intervention }) => (
      <ClassificationTimechargePreview id={intervention.Id} />
      // <InterventionOrigin
      //   placement="left"
      //   className="text-primary py-2"
      //   intervention={intervention}
      // >
      //   <InterventionIcon className="mr-1" />
      //   <span>{intervention.Id}</span>
      // </InterventionOrigin>
    )
  },
  {
    width: 100,
    title: <FormattedMessage id={"DURATION"} />,
    Cell: ({ intervention }) => (
      <FormattedTimePreview value={intervention.EstimatedCost} asHourMinute />
    )
  },
  {
    width: 120,
    title: <FormattedMessage id={"TRAVEL"} />,
    Cell: ({ intervention }) => (
      <FormattedTimePreview value={intervention.DeslocationTime} asHourMinute />
    )
  },
  {
    width: 100,
    title: <FormattedMessage id={"PAUSES"} />,
    Cell: ({ intervention }) => (
      <FormattedTimePreview value={intervention.TotalPauses} asHourMinute />
    )
  },
  {
    width: 140,
    title: <FormattedMessage id={"INEFFICIENT_HOURS_ABV"} />,
    Cell: ({ intervention }) => (
      <FormattedTimePreview
        value={intervention.InefficientDuration}
        asHourMinute
      />
    )
  },

  {
    width: 140,
    title: <FormattedMessage id={"MINIMUM_BILLING_INTERVAL_ABV"} />,
    Cell: ({ intervention }) => (
      <MiniumIntervalPeriod />
      // <TextEllipsis
      //   truncate
      //   Component={<FontAwesomeIcon icon={faAlignJustify} />}
      //   text={intervention.NoTagDescription}
      // />
    )
  },
  {
    width: 130,
    title: <FormattedMessage id={"ROUNDING_MARGIN_ABV"} />,
    Cell: ({ intervention }) => <RoundingMargin />
  },
  {
    title: <FormattedMessage id={"INPUTED_HOURS"} />,

    Cell: ({
      InputtedDuration,
      intervention,
      onChange,
      preview,
      removable
    }) => {
      return (
        <ClassificatonHourPicker
          onChange={onChange}
          InputtedDuration={InputtedDuration}
          intervention={intervention}
          preview={preview}
          removable={removable}
        />
      );
    }
  }
];

export const ContractDataProvider = React.createContext();

const ClassificationPreview = ({
  name,
  InterventionsTimeLimitation,
  value,
  originTarget,
  onChange,
  loading,
  contractData,
  preview,
  removable
}) => {
  //   const handleNewChange = (intervention, state) => {
  //       const
  //   };

  const buildBody = useCallback(
    (data, columns, { onChange, name }) => {
      // const handleNewClick =

      let accumulatedDuration = 0;
      let accumulatedInputtedDuration = 0;
      let accumulatedDeslocationTime = 0;
      let accumulatedInefficientDuration = 0;
      let accumulatedPausesDuration = 0;
      return (
        <React.Fragment>
          {data.new.map((v) => {
            accumulatedDuration += v.EstimatedCost;
            accumulatedInputtedDuration += v.InputtedDuration;
            accumulatedDeslocationTime += v.DeslocationTime;
            accumulatedInefficientDuration += v.InefficientDuration;
            accumulatedPausesDuration += v.TotalPauses;

            return (
              <CustomTr
                InterventionsTimeLimitation={InterventionsTimeLimitation}
                isNew
                removable={removable}
                preview={preview}
                key={v.intervention}
                onChange={onChange}
                name={name}
                columns={columns}
                value={v}
              />
            );
          })}
          {data.billing.map((v) => {
            accumulatedDuration += v.EstimatedCost;
            accumulatedInputtedDuration += v.InputtedDuration;
            accumulatedDeslocationTime += v.DeslocationTime;
            accumulatedInefficientDuration += v.InefficientDuration;
            accumulatedPausesDuration += v.TotalPauses;

            return (
              <CustomTr
                InterventionsTimeLimitation={InterventionsTimeLimitation}
                preview={preview}
                removable={removable}
                key={v.intervention}
                onChange={onChange}
                name={name}
                columns={columns}
                value={v}
              />
            );
          })}
          <tr>
            <td>
              <span>Total</span>
            </td>
            <td>
              <FormattedTimePreview
                className="fw-medium"
                value={accumulatedDuration}
                asHourMinute
              />
            </td>
            <td>
              <FormattedTimePreview
                className="fw-medium"
                value={accumulatedDeslocationTime}
                asHourMinute
              />
            </td>
            <td>
              <FormattedTimePreview
                className="fw-medium"
                value={accumulatedPausesDuration}
                asHourMinute
              />
            </td>

            <td>
              <FormattedTimePreview
                className="fw-medium"
                value={accumulatedInefficientDuration}
                asHourMinute
              />
            </td>
            {originTarget === "Contract" && (
              <>
                <td>―</td>
                <td>―</td>
              </>
            )}
            <td>
              <FormattedTimePreview
                className="fw-medium"
                value={accumulatedInputtedDuration}
                asHourMinute
              />
            </td>
          </tr>
        </React.Fragment>
      );
    },
    [InterventionsTimeLimitation, originTarget, preview, removable]
  );

  if (loading) return <LoaderSpinner centerHorizontal size="sm" />;

  return (
    <ContractDataProvider.Provider value={contractData || {}}>
      <Table
        className="ar-classification-table"
        buildBody={buildBody}
        TrComponent={CustomTr}
        columns={
          originTarget === "Contract" ? columnsForContractOrigin : columns
        }
        data={value}
        onChange={onChange}
        name={name}
      />
    </ContractDataProvider.Provider>
  );
};

export default ClassificationPreview;
