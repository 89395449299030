import React from "react";
import classnames from "classnames";
import classes from "./DomainKeys.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle
} from "@fortawesome/pro-solid-svg-icons";
import { DomainKeyClipText, DomainKeyPre } from "./DomainKeysClipboard";
import { FormattedMessage, useIntl } from "react-intl";

export const DomainKeyNameTypeEnum = {
  SPF: 1,
  DKIM: 2,
  ReturnPath: 3,
  MX: 4
};

export const DomainKeyTitle = ({ text, textId, IsApproved, className }) => {
  const intl = useIntl();
  return (
    <div className={classnames("d-flex align-items-center mb-2", className)}>
      {(text || textId) && (
        <span
          className={classnames("mr-2 fw-medium", {
            "text-danger-alt": !IsApproved
          })}
        >
          {textId ? intl.formatMessage({ id: textId }) : text}
        </span>
      )}
      <div
        className={classnames(
          classes.keyHeaderBadge,
          "d-flex align-items-center",
          {
            [classes.error]: !IsApproved
          }
        )}
      >
        <FontAwesomeIcon
          className="mr-2"
          icon={IsApproved ? faCheckCircle : faExclamationCircle}
        />
        <div className={`${classes.text} fs-12 fw-medium`}>
          <FormattedMessage id={IsApproved ? "VALIDATED" : "NOT_VALIDATED"} />
        </div>
      </div>
    </div>
  );
};

const DomainKeyDescription = ({ item, textId, subDomain }) => {
  const { Key } = item;
  return (
    <FormattedMessage
      id={textId}
      values={{
        subDomain: subDomain,
        b: (...chunks) => <b>{chunks}</b>,
        Key,
        clip: (...chunks) => <DomainKeyClipText children={chunks} />,
        brk: <br />
      }}
    />
  );
};

const DomainKeySPF = ({ item, subDomain }) => {
  const { IsApproved, Value } = item;
  return (
    <div>
      <DomainKeyTitle
        className="mb-2"
        textId="SPF_RECORD"
        IsApproved={IsApproved}
      />
      <div className="fs-14 mb-2">
        <DomainKeyDescription
          subDomain={subDomain}
          item={item}
          textId="DOMAIN_KEY_SPF_DESCRIPTION"
        />
      </div>
      <DomainKeyPre value={Value} />
    </div>
  );
};

const DomainKeyDKIM = ({ item, subDomain }) => {
  const { IsApproved, Value } = item;

  const rItem = { ...item };

  if (subDomain !== "@") rItem.Key = rItem.Key + `.${subDomain}`;

  return (
    <div>
      <DomainKeyTitle
        className="mb-2"
        textId="DKIM_RECORD"
        IsApproved={IsApproved}
      />
      <div className="fs-14 mb-2">
        <DomainKeyDescription
          subDomain={subDomain}
          item={rItem}
          textId="DOMAIN_KEY_DKIM_DESCRIPTION"
        />
      </div>
      <DomainKeyPre value={Value} />
    </div>
  );
};

const DomainKeyReturnPath = ({ item, subDomain }) => {
  const { IsApproved, Value } = item;

  const rItem = { ...item };

  rItem.Key = "adrp";

  if (subDomain !== "@") rItem.Key += `.${subDomain}`;

  return (
    <div>
      <DomainKeyTitle
        className="mb-2"
        textId="RETURN_PATH_RECORD"
        IsApproved={IsApproved}
      />
      <div className="fs-14 mb-2">
        <DomainKeyDescription
          subDomain={subDomain}
          item={rItem}
          textId="DOMAIN_RETURN_PATH_DESCRIPTION"
        />
      </div>
      <DomainKeyPre value={Value} />
    </div>
  );
};

const DomainKeyMX = ({ item, subDomain }) => {
  const { IsApproved, Value } = item;
  return (
    <div>
      <DomainKeyTitle
        className="mb-2"
        textId="MX_RECORD"
        IsApproved={IsApproved}
      />
      <div className="fs-14 mb-2">
        <DomainKeyDescription
          subDomain={subDomain}
          item={item}
          textId="DOMAIN_MX_DESCRIPTION"
        />
      </div>
      <DomainKeyPre value={Value} />
    </div>
  );
};

const DomainKeyItem = ({ item, subDomain }) => {
  switch (item.NameType) {
    case DomainKeyNameTypeEnum.SPF:
      return <DomainKeySPF subDomain={subDomain} item={item} />;
    case DomainKeyNameTypeEnum.DKIM:
      return <DomainKeyDKIM subDomain={subDomain} item={item} />;
    case DomainKeyNameTypeEnum.ReturnPath:
      return <DomainKeyReturnPath subDomain={subDomain} item={item} />;
    case DomainKeyNameTypeEnum.MX:
      return <DomainKeyMX subDomain={subDomain} item={item} />;

    default:
      return null;
  }
};

const DomainKeys = ({ form }) => {
  const { DomainKeys: keys, BaseDomain } = form;

  // const subDomainReg = /(^[\w]+([.]*[\w]+){1,})([.][\w-]+){2}/.exec(BaseDomain);
  // const subDomain = subDomainReg ? subDomainReg[1] : "@";
  const subDomain = BaseDomain;

  return (
    <div className="text-black">
      <div className="fw-medium">
        <FormattedMessage id="UPDATE_DNS_DATA" />
      </div>
      <div className="fs-14 mb-3">
        <FormattedMessage
          id="EMAIL_DOMAIN_ADD_TO_DNS"
          values={{ b: (...chunks) => <b>{chunks}</b>, Domain: BaseDomain }}
        />
      </div>
      {keys.map((k, i) => {
        return (
          <React.Fragment key={k.NameType}>
            <DomainKeyItem subDomain={subDomain} item={k} />
            {i !== keys.length - 1 && <hr />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default DomainKeys;
