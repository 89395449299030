import {
  createCallFilter,
  createClientFilter,
  createContractFilter,
  createDealFilter,
  createInterventionFilter,
  createProjectFilter,
  createTaskFilter,
  createTicketFilter,
  createUserFilter
} from "../../Helpers/FilterHelper";

export const InboxTypeEnum = {
  Email: 1,
  Chat: 2,
  Client: 4,
  Deal: 5,
  Contract: 6,
  Subscription: 7,
  Call: 8,
  Project: 9,
  Ticket: 10,
  Task: 11,
  Time: 12,
  Contact: 13,
  Billing: 14,
  EmailSignature: 15,
  TimeCharge: 12
};

export const InboxMatchTypeEnum = {
  // [InboxTypeEnum.Email]: "EmailConversation",
  // [InboxTypeEnum.Chat]: "Chat",
  [InboxTypeEnum.Client]: "Client",
  [InboxTypeEnum.Deal]: "Deal",
  [InboxTypeEnum.Contract]: "Contract",
  [InboxTypeEnum.Subscription]: "Subscription",
  [InboxTypeEnum.Call]: "Call",
  [InboxTypeEnum.Project]: "Project",
  [InboxTypeEnum.Ticket]: "Ticket",
  [InboxTypeEnum.Task]: "Task",
  [InboxTypeEnum.Time]: "TimeCharge"
  // [InboxTypeEnum.Contact]: "Contact",
  // [InboxTypeEnum.Billing]: "Billing",
  // [InboxTypeEnum.EmailSignature]: "EmailSignature",
};

export const ConversationTypeEnum = {
  Email: 1,
  Chat: 2,
  SocialMedia: 3,
  EntityNotes: 4,
  DirectChat: 5
};

export const ConversationTypeEnumFlags = {
  Email: 1,
  Chat: 2,
  SocialMedia: 4,
  EntityNotes: 8,
  DirectChat: 16,
  All: 31
};

export const EntityTypeNoteEnum = {
  Client: 1,
  Deal: 2,
  Contract: 3,
  Subscription: 4,
  Call: 5,
  Project: 6,
  Ticket: 7,
  Task: 8,
  Time: 9
};

export const EntityTypeNoteEnumFlags = {
  Client: 1,
  Deal: 2,
  Contract: 4,
  Subscription: 8,
  Call: 16,
  Project: 32,
  Ticket: 64,
  Task: 128,
  Time: 256,
  All: 511
};

//to do : intervention, emailConversation
export const ReceptionFilterColumns = [
  createUserFilter({
    titleId: "ASSIGNED_TO",
    field: "Conversation/AssignedId"
  }),
  createDealFilter({
    field: "Deal/Id",
    outboundFields: ["Deal/Id", "Deal_A/Id"]
  }),
  createTicketFilter({
    field: "Ticket/Id",
    outboundFields: ["Ticket/Id", "Ticket_A/Id"]
  }),
  createTaskFilter({
    field: "Task/Id",
    outboundFields: ["Task/Id", "Task_A/Id"]
  }),
  createProjectFilter({
    field: "Project/Id",
    operator: "eq",
    data: {
      titleId: "PROJECT",
      field: "Project/Id",
      operator: "eq"
    }
  }),
  createContractFilter({
    field: "Contract/Id",
    operator: "eq",
    data: {
      titleId: "CONTRACT",
      field: "Contract/Id",
      operator: "eq"
    }
  }),
  createCallFilter({
    field: "Call/Id",
    operator: "eq",
    data: {
      titleId: "CALL",
      field: "Call/Id",
      operator: "eq"
    }
  }),
  createInterventionFilter({
    field: "TimeCharge/Id",
    operator: "eq",
    data: {
      titleId: "TIME",
      field: "TimeCharge/Id",
      operator: "eq"
    }
  }),
  createClientFilter({ field: "Client/Id" })
];

export const EmailConversationFilterColumns = [
  createUserFilter({
    titleId: "ASSIGNED_TO",
    field: "Conversation/AssignedId"
  }),
  createDealFilter({
    field: "Deal/Id"
    // outboundFields: ["Deal/Id"]
  }),
  createTicketFilter({
    field: "Ticket/Id"
    // outboundFields: ["Ticket/Id"]
  }),
  createClientFilter({ field: "Client/Id" })
];

export const ConversationBothFilterColumns = [
  ...EmailConversationFilterColumns,
  ...ReceptionFilterColumns
];
