import React from "react";
import { Handle } from "react-flow-renderer";
import classes from "../AutomationDetails.module.css";

const TargetHandles = (props) => {
  // const ElementsRef = useContext(ElementsRefContext);

  // const validateConnection = (e) => {
  //   const { source, sourceHandle } = e;
  //   const sourceIndex = ElementsRef.current.findIndex((e) => e.id === source);
  //   if (sourceIndex !== -1) {
  //     const outgoers = getOutgoers(
  //       ElementsRef.current[sourceIndex],
  //       ElementsRef.current
  //     );
  //     if (sourceHandle && outgoers.length === 1) {
  //       const otherEdge = ElementsRef.current.find((e) => e.source === source);
  //       if (otherEdge.sourceHandle !== sourceHandle) return true;
  //       else return false;
  //     }
  //     return outgoers.length === 0;
  //   } else return false;
  // };

  return (
    <Handle
      type="target"
      position="top"
      // isValidConnection={validateConnection}
      className={classes.handle}
      // onConnect={(params) => console.log("handle onConnect", params)}
      {...props}
    />
  );
};

export default TargetHandles;
