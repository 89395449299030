import React from "react";
import { useAccount } from "../../../../Hooks/EntityHooks";
import { useSpace } from "../../../../Contexts/SpaceContext";
import { NotificationContainer } from "./NotificationHelper";
import { FormattedMessage } from "react-intl";

const NotificationInviteItem = ({ value }) => {
  const { Account } = value;

  const resolvedAccount = Account;
  const space = useSpace();

  return (
    <NotificationContainer>
      <div className="fs-14">
        <FormattedMessage id={"THE_USER"} /> <b>{resolvedAccount?.Name}</b>{" "}
        <FormattedMessage id={"SPACE_ENTERED"} /> <b>{space.Name}</b>{" "}
      </div>
    </NotificationContainer>
  );
};

export default NotificationInviteItem;
