import React from "react";
import AirdeskTextSvg from "../../Components/Svg/AirdeskTextSvg";
import { Link } from "react-router-dom";

const OnboardingHeader = ({ step, newWidth, disableProgress = false }) => {
  return (
    <>
      {!disableProgress && (
        <div className="onboarding-progress-container">
          <div
            style={{ width: `${step * 25}%` }}
            className="onboarding-progress-bar"
          ></div>
        </div>
      )}
      <div className="w-100 pt-5">
        <div
          style={{ width: newWidth }}
          className="onboarding-airdesk-icon-header"
        >
          <Link to="/">
            <AirdeskTextSvg className="h-100 py-2 airdesk-logo" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default OnboardingHeader;
