import { useMemo, useLayoutEffect, useRef } from "react";
import {
  useCurrentAccount,
  useCurrentAccountSpace
} from "../../Contexts/UserContext";
import { isAdmin } from "../../Helpers/ModulesHelper";
import { originTypes } from "../../Helpers/MiscHelper";

export const useAvailableFollowers = (Creator, RemainingAccounts) => {
  const currentAccountId = useCurrentAccount().Id;

  const ResolvedCreator = Creator || currentAccountId;

  return useMemo(() => {
    let arr = [];
    if (ResolvedCreator !== "00000000-0000-0000-0000-000000000001")
      arr.push(ResolvedCreator);

    if (RemainingAccounts) arr = [...arr, ...RemainingAccounts];
    return [...new Set(arr)];
  }, [RemainingAccounts, ResolvedCreator]);
};

export const useFollowerCreator = (Creator) => {
  const currentAccountId = useCurrentAccount().Id;

  const ResolvedCreator = Creator || currentAccountId;

  return ResolvedCreator;
};

export const useCanEditFollowers = (Creator, Manager) => {
  const accountSpace = useCurrentAccountSpace();
  const currentAccountId = useCurrentAccount().Id;

  return useMemo(() => {
    if (!Creator) return true;

    if (isAdmin(accountSpace)) return true;

    if (currentAccountId === Manager) return true;

    if (currentAccountId === Creator) return true;

    return false;
  }, [Creator, Manager, accountSpace, currentAccountId]);
};

const getDropdownFollowers = (dropdownValue) => {
  const accounts = [];

  for (const { type, data } of dropdownValue) {
    if (type === originTypes.account) {
      accounts.push(data);
    }
  }
  return accounts;
};

export const useDropdownFollowers = (dropdownValue) => {
  return useMemo(() => {
    return getDropdownFollowers(dropdownValue);
  }, [dropdownValue]);
};

export const useJoinedFollowerAccounts = (...args) => {
  return useMemo(() => {
    let accounts = [];
    ////;
    for (const arg of args) {
      if (Array.isArray(arg)) accounts = [...accounts, ...arg];
      else if (arg) accounts.push(arg);
    }
    return accounts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...args]);
};

const computeChanges = (followers, prevFollowers) => {
  const additions = [];
  const removals = [];

  for (const follower of prevFollowers) {
    if (!followers.includes(follower)) removals.push(follower);
  }

  for (const follower of followers) {
    if (!prevFollowers.includes(follower)) additions.push(follower);
  }

  return { additions, removals };
};

export const useFollowerAutoUpdater = (
  Followers,
  possibleFollowers,
  handleChange,
  Manager
) => {
  const prevPossibleFollowersRef = useRef(possibleFollowers);

  useLayoutEffect(() => {
    if (prevPossibleFollowersRef.current === possibleFollowers) return;

    const { additions, removals } = computeChanges(
      possibleFollowers,
      prevPossibleFollowersRef.current
    );

    if (additions.length > 0 || removals.length > 0) {
      let newFollowers = [...Followers];
      for (const follower of additions) {
        newFollowers.push(follower);
      }

      for (const follower of removals) {
        const index = newFollowers.indexOf(follower);
        if (index !== -1) newFollowers.splice(index, 1);
        // newFollowers.push(follower)
      }
      handleChange({
        Followers: newFollowers
      });
    }

    prevPossibleFollowersRef.current = possibleFollowers;
  }, [Followers, Manager, handleChange, possibleFollowers]);
};

export const updateManagerFollowers = (
  Followers,
  prevManager,
  Manager,
  args
) => {
  if (Followers.length === 0) {
    let shouldRemovePrevManager = true;
    let shouldAddManager = Boolean(Manager) && !Followers.includes(Manager);

    for (const arg of args) {
      if (!arg) continue;
      if (Array.isArray(arg)) {
        for (const user of arg) {
          if (user === prevManager) shouldRemovePrevManager = false;

          // if (user === Manager) shouldAddManager = false;
        }
      } else {
        if (arg === prevManager) shouldRemovePrevManager = false;

        // if (arg === Manager) shouldAddManager = false;
      }
    }

    if (shouldRemovePrevManager || shouldAddManager) {
      const newFollowers = [...Followers];

      if (shouldRemovePrevManager) {
        const index = newFollowers.indexOf(prevManager);
        if (index !== -1) newFollowers.splice(index, 1);
      }

      if (shouldAddManager) {
        newFollowers.push(Manager);
      }

      return newFollowers;
    }
  }else if(Followers.length > 0){
		let shouldAddManager = Boolean(Manager) && !Followers.includes(Manager);
		const newFollowers = [...Followers];
		if (shouldAddManager) {
			newFollowers.push(Manager);
		}
		return newFollowers;
	}

  return Followers;
};

export const updateCollabFollowers = (
  Followers,
  prevValue,
  value,
  possibleValues
) => {
  const prevAccounts = getDropdownFollowers(prevValue);
  const accounts = getDropdownFollowers(value);

  const { additions, removals } = computeChanges(accounts, prevAccounts);

  let newFollowers = Followers;
  //
  for (const addedUser of additions) {
    newFollowers = updateManagerFollowers(
      Followers,
      null,
      addedUser,
      possibleValues
    );
  }

  for (const removedUser of removals) {
    newFollowers = updateManagerFollowers(
      Followers,
      removedUser,
      null,
      possibleValues
    );
  }

  return newFollowers;
};

export const updateCollaborators = (Followers, prevValue, value, arg) => {};
