import React from "react";
import { useContact } from "../../Hooks/EntityHooks";
import {
  faEnvelope,
  faPhone,
  faSuitcase
} from "@fortawesome/pro-solid-svg-icons";
import { spaceContactSchema } from "../../config/schema";
import SplitCol from "../../Components/SplitCol/SplitCol";
import ContactProfile, {
  ConnectContactEntity,
  ContactStatusProfile
} from "../../Components/Contacts/ContactProfile";

import {
  OriginContainer,
  OriginDate,
  OriginHeader,
  OriginItemGroup
} from "../../Components/Origin/Origin";
import { createOriginPopup } from "./Origin";
import { ConnectedClientProfileStack } from "../../Components/Accounts/AccountProfileStack";
import { AccountProfileImg } from "../../Components/Img/Img";
import { FormattedPhoneNumber } from "../../Components/Input/PhoneInput";
import { ContactEntityGridButtons } from "../Contacts/ContactsHelper";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { ContactIcon } from "../../Components/Common/EntityIcons";

export const ContactPopupAnchor = React.forwardRef(
  (
    {
      contact,
      dispatch,
      children,
      justImage,
      className,
      dynamicFont,
      size,
      ...rest
    },
    ref
  ) => {
    if (!contact) {
      return null;
    }
    const { IsDeleted } = contact;
    if (justImage) {
      return (
        <div
          ref={ref}
          className={classnames(className, {
            "item-was-deleted": IsDeleted
          })}
          {...rest}
        >
          <AccountProfileImg
            size={size || "sm"}
            account={contact}
            dynamicFont={dynamicFont}
            circular
          />
        </div>
      );
    }

    return (
      <div
        ref={ref}
        className={classnames(
          "link-color text-primary text-truncate",
          className
        )}
        {...rest}
      >
        <ContactProfile
          contact={contact}
          dynamicFont={dynamicFont}
          size={size}
          {...rest}
        />
      </div>
    );
  }
);
export const ConnectedAccountPopupAnchor = ({ contact, ...rest }) => {
  return <ContactPopupAnchor contact={contact} {...rest} />;
};

export const ContactPopupComponent = ({ contact }) => {
  const a = useContact(contact.Id);

  const {
    Email,
    Id,
    Clients,
    Status,
    MobileNumber,
    Name,
    PhoneNumber,
    CreationDate
  } = a;

  return (
    <OriginContainer>
      <OriginHeader
        Dif
        className="mb-1"
        type="Contacto"
				date={CreationDate}
        entity="CONTACT"
        Icon={<ContactIcon className="mr-2" />}
        EntityButtons={<ContactEntityGridButtons entity={a} minimal />}
        id={Id}
        title={Name}
      />
      {/* <div className=" mb-1 origin_header_div text-white px-3">
        <span className="origin_header">
          <FontAwesomeIcon icon={faUserTie} className="mr-2" />{" "}
          <FormattedMessage id={"CONTACT_NR"} /> {Id}
        </span>
      </div> */}
      {/* <OriginHeader
				className="mb-2"
				type="Account"
				Icon={Account.Image}
				id={Account.Id}
				title={Account.name}

			/> */}

      <div className="mx-3 mb-1 fs-14">
        <div className="mb-1 d-flex align-items-center justify-content-between">
          <ContactStatusProfile status={Status}></ContactStatusProfile>
          {/* <EntityStatusUpdater
              fixedWidth={150}
              hideName={false}
              canEdit={false}
              StatusComponent={ContactStatusProfile}
              Component={ContactStatusDropdown}
              schema={spaceContactSchema}
              status={Status}
              type="Contact"
              id={Id}
              hideCancellation={true}
            /> */}

          <div>
            <OriginDate className="fs-12" date={CreationDate} />
          </div>
        </div>

        {/* <ConnectedContactProfile contact={Id} /> */}
      </div>
      {/* <SplitCol
				className="mb-2"
				left={
				
					
				}></SplitCol>

			// right={
			//   <OriginItemGroup title="Contato">
			//     <ConnectedContactProfile contact={Contact} />
			//   </OriginItemGroup>
			//  */}

      {Clients && (
        <OriginItemGroup
          icon={faSuitcase}
          className="mb-1"
          title={<FormattedMessage id={"ASSOCIATED_ACCOUNTS"} />}
        >
          <ConnectedClientProfileStack clients={Clients} />
        </OriginItemGroup>
      )}

      {Boolean(Email) && Boolean(!PhoneNumber) && (
        <OriginItemGroup icon={faEnvelope} className="mb-1" title="Email">
          <div className="text-black fs-12">
            <a href={`mailto:${Email}`}>{Email}</a>
          </div>
        </OriginItemGroup>
      )}

      {Boolean(!Email) && Boolean(PhoneNumber) && (
        <OriginItemGroup
          icon={faPhone}
          className="mb-1"
          title={<FormattedMessage id={"PHONE_NUMBER"} />}
        >
          <div className="text-black fs-12">
            <FormattedPhoneNumber number={PhoneNumber} />
          </div>
        </OriginItemGroup>
      )}

      <SplitCol
        show={Boolean(Email) && Boolean(PhoneNumber)}
        className="mb-1"
        left={
          <OriginItemGroup icon={faEnvelope} title="Email">
            <div className="text-black fs-12">
              <a href={`mailto:${Email}`}>{Email}</a>
            </div>
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faPhone}
            title={<FormattedMessage id={"PHONE_NUMBER"} />}
          >
            <div className="text-black fs-12">
              <FormattedPhoneNumber number={PhoneNumber} />
            </div>
          </OriginItemGroup>
        }
      />
      {/* 
      {Status && Boolean(!MobileNumber) && (
        <OriginItemGroup

          className="mb-1"
          title={<FormattedMessage id={"STATUS"} />}
        >
          <EntityStatusUpdater
            fixedWidth={150}
            hideName={false}
            canEdit={false}
            StatusComponent={ContactStatusProfile}
            Component={ContactStatusDropdown}
            schema={spaceContactSchema}
            status={Status}
            type="Contact"
            id={Id}
            hideCancellation={true}
          />
        </OriginItemGroup>
      )} */}
      <OriginItemGroup
        icon={faPhone}
        show={Boolean(MobileNumber)}
        title={<FormattedMessage id={"MOBILE_NUMBER"} />}
      >
        <span className="text-black fs-12">
          <FormattedPhoneNumber number={MobileNumber} />
        </span>
      </OriginItemGroup>
      {/* <SplitCol
        className="mb-1"
        show={Status && Boolean(MobileNumber)}
        left={
          <OriginItemGroup title={<FormattedMessage id={"STATUS"} />}>
            <EntityStatusUpdater
              fixedWidth={150}
              hideName={false}
              canEdit={false}
              StatusComponent={ContactStatusProfile}
              Component={ContactStatusDropdown}
              schema={spaceContactSchema}
              status={Status}
              type="Contact"
              id={Id}
              hideCancellation={true}
            />
          </OriginItemGroup>
        }
        right={}
      /> */}
      {/* <OriginItemGroup className="mb-2" title="Contratos Associados">
				
			</OriginItemGroup>
			<OriginItemGroup className="mb-2" title="Projetos Associados">
			
			</OriginItemGroup> */}
      <div className="pb-3"></div>
      {/* <div className="d-flex align-items-center justify-content-center shadow-top py-3 mt-3">
        <ContactEntityButtons
          //   className="mt-3 mb-3"

          entity={a}
        />
      </div> */}
    </OriginContainer>
  );
};

const ContactPopup = createOriginPopup(
  ContactPopupAnchor,
  ContactPopupComponent,
  {
    valueProp: "contact",
    schema: spaceContactSchema
  }
);

export default ContactPopup;

export const ConnectedContactPopup = ConnectContactEntity(ContactPopup);
