import {
  spaceApiLogsSchema,
  spaceApiTokensSchema,
  spaceAutomationSchema,
  spaceBillingSchema,
  spaceBreadcrumbsQueueSchema,
  spaceCallsSchema,
  spaceClassificationSchema,
  spaceClientSchema,
  spaceContactSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceDocumentsSchema,
  spaceEmailSignatureSchema,
  spaceErrorLogSchema,
  spaceFeedbackQueueSchema,
  spaceImportsSchema,
  spaceInterventionsSchema,
  spacePermissionQueueSchema,
  spaceProjectsSchema,
  spaceSocialPostsSchema,
  spaceSubscriptionSchema,
  spaceTasksSchema,
  spaceTicketsSchema
} from "../../config/schema";

export enum FilterViewTypeEnum {
  None = 0,
  Call = 1,
  Ticket = 2,
  Project = 3,
  Task = 4,
  TimeCharge = 5,
  Client = 6,
  Contact = 7,
  Contract = 8,
  Classification = 9,
  Billing = 10,
  Deals = 11,
  Imports = 12,
  SocialPosts = 13,
  Subscription = 14,
  EmailSignature = 15,
  Automations = 16,
  Documents = 17,
  Templates = 18,
  ErrorLogs = 22,
  SpaceList = 23,
  ApiKeys = 24,
  ApiLogs = 25,
  FeedbackQueue = 26,
  PermissionQueue = 27,
  BreadcrumbQueue = 28,
  AutomationTriggerQueue = 29,
  AutomationEnrollQueue = 30,
  EnrollManagerQueue = 31,
  WidgetEmailQueue = 32,
  NotificationsQueue = 33,
  WidgetImageQueue = 34,
  TravelReports = 35,
  AutomationEmailConversationGeneration = 36,
  AllUserPermissionsQueue = 37,
  EmailWebhookQueue = 38
}

export const FilterViewTypeEnumToSchemaName = {
  1: spaceCallsSchema.name,
  2: spaceTicketsSchema.name,
  3: spaceProjectsSchema.name,
  4: spaceTasksSchema.name,
  5: spaceInterventionsSchema.name,
  6: spaceClientSchema.name,
  7: spaceContactSchema.name,
  8: spaceContractSchema.name,
  9: spaceClassificationSchema.name,
  10: spaceBillingSchema.name,
  11: spaceDealsSchema.name,
  12: spaceImportsSchema.name,
  13: spaceSocialPostsSchema.name,
  14: spaceSubscriptionSchema.name,
  15: spaceEmailSignatureSchema.name,
  16: spaceAutomationSchema.name,
  17: spaceDocumentsSchema.name,
  22: spaceErrorLogSchema.name,
  24: spaceApiTokensSchema.name,
  25: spaceApiLogsSchema.name,
  26: spaceFeedbackQueueSchema.name,
  27: spacePermissionQueueSchema.name,
  28: spaceBreadcrumbsQueueSchema.name
};
