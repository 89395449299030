import React from "react";
import Calendar from "../../../../Components/Calendar/Calendar";
import {
  CalendarItem,
  CalendarViewEnum,
} from "../../../../Components/Calendar/CalendarHelper";
import { ContractStatusProfile } from "../../../../Components/Contract/ContractProfile";
import { AccountProfileImg } from "../../../../Components/Img/Img";
import { spaceContractSchema } from "../../../../config/schema";
import { useSpaceQuery } from "../../../../Helpers/IOClient";
import { moduleTypes } from "../../../../Helpers/ModulesHelper";
import {
  useAccount,
  useContract,
  usePipelineStatus,
} from "../../../../Hooks/EntityHooks";
import { ContractOriginPopup } from "../../../Origin/ContractOrigin";
import { contractCommunicator } from "../../ContractCommunicatorHelper";
import moment from "moment";
import { ConnectedAccountProfileImg } from "../../../../Components/EntitySummaries/EntitySummaryMessages/MessagesHelper";
import monthClasses from "../../../../Components/Calendar/CalendarViews/CalendarMonthView/CalendarMonthView.module.css";
import classnames from "classnames";
import { ContractAssociations } from "../ContractBoard/ContractBoard";
import { ColorFilterBars } from "../../../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";

const ContractPopup = ({ entityId }) => {
  const { loading, error, data } = useSpaceQuery(
    `contracts/${entityId}/`,
    spaceContractSchema
  );

  const contract = useContract(data);

  if (loading || error) return null;

  return <ContractOriginPopup contract={contract} />;
};

const WeeklyItem = ({ item }) => {
  const { PipelineStatus, NoTagDescription, Manager } = item.metadata;
  const { Status } = usePipelineStatus(PipelineStatus);
  const connectedManager = useAccount(Manager);
  return (
    <div className="d-flex align-items-center">
      <AccountProfileImg
        size="sm"
        circular
        account={connectedManager}
        className="mr-2"
      />
      <div className="d-flex flex-column of-hidden flex-1">
        <div className="d-flex align-items-center">
          <ContractStatusProfile hideName status={Status} />
          <span className="text-truncate text-black fs-12">
            {NoTagDescription}
          </span>
        </div>
        {/* <div>
          <CalendarHours beginDate={BeginDate} endDate={EndDate} />
        </div> */}
      </div>

      {/* <TaskOriginAnchor task={value} /> */}
    </div>
  );
};

const Item = ({ item }) => {
  const {
    NoTagDescription,
    Manager,
    Status,
    BeginDate: BeginDateString,
  } = item.metadata;

  const { BeginDate, EndDate } = item;

  const formattedDate =
    BeginDate.getTime() === EndDate.getTime()
      ? moment(BeginDateString).format("HH:mm")
      : moment(BeginDateString).format("DD MMM - HH:mm");

  return (
    <CalendarItem className="px-2 d-flex">
      <ColorFilterBars entity={item.metadata} />
      <div className="flex-1 d-flex align-items-center text-truncate">
        <ConnectedAccountProfileImg
          className="mr-1 flex-0-0-auto"
          account={Manager}
        />
        <ContractStatusProfile hideName status={Status} />
        <span className="text-truncate">{NoTagDescription}</span>
      </div>
      <div className={classnames("mr-1", monthClasses.dateBadge)}>
        {formattedDate}
      </div>
      <ContractAssociations value={item.metadata} />
    </CalendarItem>
  );
};

const settings = {
  schema: spaceContractSchema,
  communicator: contractCommunicator,
  getKey: (v) => v,
  TextComp: ({ value }) => {
    const { Name } = useContract(value);
    return <span>{Name}</span>;
  },
  CalendarItem: Item,
  WeeklyItem,
  BeginDateField: "Contract/BeginDate",
  EndDateField: "Contract/EndDate",
  moduleType: moduleTypes.contracts,
  EntityPopup: ContractPopup,
};

const ContractCalendar = () => {
  return (
    <Calendar
      // disableViewSwitcher
      defaultView={CalendarViewEnum.Month}
      settings={settings}
    />
  );
};

export default ContractCalendar;
