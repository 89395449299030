import React from "react";
import { useContract } from "../../../../Hooks/EntityHooks";
import {
  HistoryHeader,
  HistoryContent,
  HistoryAccountProfile,
  HistoryOverview,
  useHistoryItem
} from "./HistoryItem";
import { ConnectedContractOrigin } from "../../../../Containers/Origin/ContractOrigin";
import { createItemEditDetailsComponent } from "./HistoryItemDetails/HistoryItemDetails";
import { ContractStatusProfile } from "../../../Contract/ContractProfile";
import { ContractCurrencies } from "./HistoryItemDetails/HistoryItemProperties";
import { FormattedMessage } from "react-intl";
import { PipelineStatusProfile } from "../../../../Containers/AdvancedMultiInputs/PipelineDropdowns";
import { CheckboxPreview } from "../../../Checkbox/Checkbox";

const ContractHistoryOverview = () => {
  const { Contract, IsCreation, Account } = useHistoryItem();
  const item = useContract(Contract);
  const { NoTagDescription } = item;
  if (IsCreation)
    return (
      <HistoryOverview>
        <HistoryAccountProfile account={Account} />
        <span>
          {" "}
          <FormattedMessage id={"CREATED_CONTRACT"} />{" "}
        </span>{" "}
        <b>{NoTagDescription}</b>
      </HistoryOverview>
    );

  return (
    <HistoryOverview>
      <HistoryAccountProfile account={Account} />
      <span>
        {" "}
        <FormattedMessage id={"EDITED_CONTRACT"} />{" "}
      </span>{" "}
      <b>{NoTagDescription}</b>
    </HistoryOverview>
  );
};

const ContractPipelineStatus = ({ value }) => {
  return (
    <PipelineStatusProfile
      pipeline={value}
      StatusProfileComponent={ContractStatusProfile}
    />
  );
};

const ContractSignedStatus = ({ value }) => {
  let result = "―";
  if (value === 0)
    result = <CheckboxPreview style={{ height: 20 }} defaultChecked={false} />;
  else if (value === 1)
    result = <CheckboxPreview style={{ height: 20 }} defaultChecked={true} />;

  return <div className="d-flex align-items-center h-100">{result}</div>;
};

const ContractEditItemDetails = createItemEditDetailsComponent({
  PipelineStatus: {
    NameId: "PIPELINE_STATUS",
    ValueComponent: ContractPipelineStatus
  },
  IsSigned: {
    NameId: "CONTRACT_SIGNED",
    ValueComponent: ContractSignedStatus
  },
  Currencies: ContractCurrencies
});

const ContractHistoryDetails = () => {
  const { IsCreation } = useHistoryItem();
  if (IsCreation) return null;

  return <ContractEditItemDetails />;
};

const ContractHistoryItem = () => {
  const { Contract } = useHistoryItem();

  return (
    <>
      <HistoryHeader>
        <ConnectedContractOrigin contract={Contract}>
          {/* <HistoryTitle Icon={ContractIcon}>
            <FormattedMessage id={"CONTRACT"} /> {Contract}
          </HistoryTitle> */}
        </ConnectedContractOrigin>
      </HistoryHeader>
      <HistoryContent>
        <ContractHistoryOverview />
        <ContractHistoryDetails />
      </HistoryContent>
    </>
  );
};
export default ContractHistoryItem;
