import { useContext } from "react";
import { Intl18nContext } from "../../Intl18n/Intl18nProvider";
import moment from "moment";
import 'moment/locale/es'
import 'moment/locale/pt'

export const useNotificationElapsedTime = (date) => {
  // const { currentDate } = useContext(FilteredNotificationsContext);

  // const now = currentDate;

  // const diff = date.diff(currentDate);
  // return moment.duration(diff).humanize();

  const { value } = useContext(Intl18nContext);

  moment.locale(value.Value);
  const d = moment(date)
	d.locale(value.Value);

  return d.fromNow();
};
