import React, {
  Component,
  useCallback,
  useContext,
  useRef,
  useState
} from "react";
import { injectIntl, useIntl } from "react-intl";
import { useSpaceEntitiesHub } from "./RealTime/RealTimeSpace";
import { useDelete } from "../Helpers/IOClient";
import LoaderSpinner from "../Components/Loader/LoaderSpinner/LoaderSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import { logout } from "../Helpers/AuthHelper";

const mapDispatchToProps = (dispatch) => {
  // console.log(dispatch);
  return {
    Logout: () => logout()
  };
};

const LogoutButtonComp = ({ Logout, className, style }) => {
  const intl = useIntl();
  const entitiesHub = useSpaceEntitiesHub();

  const [EndSession, { loading }] = useDelete(`account/logout`, null, {
    onSuccess: () => {
      if (entitiesHub) {
        entitiesHub.invoke("Logout");
      }
      Logout();
    },
    onError: () => {
      if (entitiesHub) {
        entitiesHub.invoke("Logout");
      }
      Logout();
    }
  });

  const handleLogout = useCallback(() => {
    EndSession();
  }, [EndSession]);

  return (
    <div
      className={
        className
          ? className
          : "ar-logout-button d-flex align-items-center w-100 h-100 pb-2 pt-2 cursor-pointer"
      }
      style={style}
      onClick={!loading && handleLogout}
    >
      {loading && <LoaderSpinner size="xxxs" className="mr-2" />}
      <FontAwesomeIcon
        style={{ width: 15 }}
        icon={faSignOut}
        className="mr-2"
      />
      {intl.formatMessage({ id: "LOG_OUT" })}
    </div>
  );
};

export const LogoutButton = connect(
  null,
  mapDispatchToProps
)(injectIntl(LogoutButtonComp));
