import { faUserTie } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

import TriggerCreation from "./TriggerCreation";
import { spaceContactSchema } from "../../../../../config/schema";
import { ConnectedContactOrigin } from "../../../../Origin/ContactOrigin";
import { useIntl } from "react-intl";

const PreviewTarget = ({ val }) => <ConnectedContactOrigin contact={val} />;

const TriggerContactCreation = React.memo(({ ...props }) => {
	const intl = useIntl()
  return (
    <TriggerCreation
      TargetPreview={PreviewTarget}
      {...props}
      schema={spaceContactSchema}
      icon={faUserTie}
      text={intl.formatMessage({ id: "CONTACT" })}
    />
  );
});

export default TriggerContactCreation;
