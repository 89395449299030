import { UPDATE_NOTIFICATIONS } from "../Actions/NotificationActions";

const initialState = {
  isSetup: Boolean(localStorage.getItem("notification_setup"))
};

export default function NotificationsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        ...action.response
      };

    default:
      return state;
  }
}
