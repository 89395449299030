import {
  schema,
  denormalize as originalDenormalize,
  normalize as originalNormalize
} from "normalizr";

export let keyedSchemas = {};
export class EntitySchema {
  constructor(name, options = {}) {
    const {
      relationships,
      idAttribute = "Id",
      processStrategy,
      handleEntityUpdate,
      getEndpoint,
      fields
    } = options;
this.options = options
    this.newSchema = new schema.Entity(name, relationships, {
      idAttribute,
      processStrategy
    });
    this.newSchema.handleEntityUpdate = handleEntityUpdate;

    keyedSchemas[name] = this.newSchema;

    this.name = name;
    this.getEndpoint = getEndpoint;
    this.fields = fields;
    this.relationships = relationships;
    this._schema = this.newSchema;
  }

  updateRelations = (relations) => {
    this.relationships = {
      ...this.relationships,
      ...relations
    }
    const {
      idAttribute = "Id",
      processStrategy,
      handleEntityUpdate,
    } = this.options;

    this.newSchema = new schema.Entity(this.name, this.relationships, {
      idAttribute,
      processStrategy
    });
    this.newSchema.handleEntityUpdate = handleEntityUpdate;

    keyedSchemas[this.name] = this.newSchema;
    this._schema = this.newSchema;
  }

  // handleResolveEntityRelation = (schema, props) => {

  // }
}

// const defaultRequestParams = { params: null };
export class SchemaRequest {
  constructor(endpoint, schemaBody) {
    this.endpoint = endpoint;
    this.schemaBody = schemaBody;
  }

  // handleResolveEntityRelation = (schema, props) => {

  // }
}

export const defineEntityCircularReference = (entity, reference) => {
  let schema = entity._schema;

  schema.define(reference);
};

export const denormalize = (input, schema, entities) => {
  return originalDenormalize(input, schema._schema, entities);
};

export const normalize = (data, schema) => {
  let normalizedValue;

  if (
    schema instanceof EntitySchema ||
    (Array.isArray(schema) && schema[0] instanceof EntitySchema)
  ) {
    if (Array.isArray(schema)) {
      normalizedValue = originalNormalize(data, [schema[0]._schema]);
      normalizedValue._originSchema = schema[0];
    } else {
      normalizedValue = originalNormalize(data, schema._schema);
      normalizedValue._originSchema = schema;
    }
  } else {
    normalizedValue = originalNormalize(data, schema);
  }

  return normalizedValue;
};

export const fk = schema => {
  return schema._schema;
};

export const many = schema => {
  return [schema._schema];
};

export const getResolvedSchema = schema => {
  if (Array.isArray(schema)) return schema[0];
  else return schema;
};
