import React from "react";
import { connect } from "react-redux";
import { CountryProfileImg } from "../Img/Img";
import DropdownInput, { DropdownInputContainer } from "../Dropdown/DropdownInput";

const CountryDropdownInput = (props) => {

  const {
    country
  } = props;

  return (
    <DropdownInputContainer>
      <CountryProfileImg size="sm" country={country} />
      <DropdownInput {...props} fallbackValue={country && country.Name} />
    </DropdownInputContainer>
  );
};

export default CountryDropdownInput;

const mapStateToProps = (state, ownProps) => {

  if (!ownProps.country) return {};
  if(!state.Entities.Countries) return {};
  return {
    country: state.Entities.Countries[ownProps.country]
  };
};

export const ConnectedCountryDropdownInput = connect(mapStateToProps)(
  CountryDropdownInput
);
