import { Communicator } from "../../Helpers/Communicator";

// const getEntityDetails = (schema, id, options) => {
//   const baseUrl = schema.getEndpoint("1");
//   client.get(`${baseUrl}/${id}`, {
//     cache: false,
//     schema,
//     ...options,
//     // onSuccess: ({ data }) => {}
//   });
// };

// const registerEntityUpdate = (schema, id, Communicator) => {
//   const entityToUpdate = getEntity(schema, id);
//   if (!entityToUpdate) {
//     Communicator.dispatch(id);
//     return;
//   }
//   getEntityDetails(schema, id, {
//     onSuccess: () => {
//       Communicator.dispatch(id, entityToUpdate, getEntity(schema, id));
//     },
//   });

//   // const x = normalize(entity, schema);

//   // x.entities[schema.name][id] = {
//   //   ...entityToUpdate,
//   //   ...x.entities[schema.name][id]
//   // };
//   // //;
//   // updateNormalizedSchemaCache(x.entities);
// };

// const useSignalR = () => {
//   const [value, setValue] = useState(null);
//   useEffect(() => {
//     import(/* webpackChunkName: "signalr" */ "./SignalR").then(
//       ({ default: signalr }) => {
//         setValue(signalr);
//       }
//     );
//   }, []);

//   return value;
// };

export const DealCreationCommunicator = new Communicator();
export const DealUpdateCommunicator = new Communicator();
export const SubscriptionUpdateCommunicator = new Communicator();
export const SubscriptionCreationCommunicator = new Communicator();
export const ContractUpdateCommunicator = new Communicator();
export const ContractCreationCommunicator = new Communicator();

// const setupSignalR = async (signalR) => {
//   // console.log(signalR);
//   const connection = new window.$.hubConnection(`${apiUrl}signalr`);
//   // console.log(connection);
//   var entitiesHub = connection.createHubProxy("EntitiesHub");
//   // entitiesHub.reconnected(() => {
//   //   console.log("reconnected");
//   // });
//   entitiesHub.on("dealEntity", function (dealId, user, isCreation) {
//     // console.log("deal updated");
//     if (isCreation) {
//       getEntityDetails(spaceDealsSchema, dealId, {
//         onSuccess: () => {
//           DealCreationCommunicator.dispatch(dealId);
//         },
//       });
//     } else {
//       registerEntityUpdate(spaceDealsSchema, dealId, DealUpdateCommunicator);
//     }
//   });

//   const startSignalR = async () => {
//     try {
//       await connection.start();
//       // console.log("connected");
//       const tkn = getCurrentUserAuthToken();

//       entitiesHub.invoke("SetUserId", tkn);
//     } catch (error) {
//       //   ;
//       // setTimeout(() => {
//       //   startSignalR();
//       // }, 500);
//       // console.log(error);
//     }
//   };

//   connection.reconnected(() => {
//     // console.log("reconnected");
//     const tkn = getCurrentUserAuthToken();
//     entitiesHub.invoke("SetUserId", tkn);
//   });

//   // connection.disconnected(() => {
//   //   setTimeout(() => {
//   //     startSignalR();
//   //   }, 500);
//   // });

//   startSignalR();

//   // const socket = new WebSocket(`wss://${apiUrl}signalr`)
// };

const EntitySynchronizer = ({ children }) => {
  // const signalR = useSignalR();
  // useEffect(() => {
  //   if (!signalR) return;
  //   setupSignalR(signalR);
  // }, [signalR]);

  return children;
};

export default EntitySynchronizer;
