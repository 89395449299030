import React, { useContext, useLayoutEffect } from "react";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { spacePipelineSchema } from "../../config/schema";
import { usePipelineStatus } from "../../Hooks/EntityHooks";

const PipelineContext = React.createContext();
const PipelineQueryContext = React.createContext();

export const PipelineProvider = ({ value, children }) => {
  const { type } = value;

  const state = useSpaceQuery(
    `query/pipelines?Type=${type}`,
    [spacePipelineSchema],
    {
      autoFetch: false
    }
  );


  useLayoutEffect(() => {
    if (state.refetch) state.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PipelineContext.Provider value={value}>
      <PipelineQueryContext.Provider value={state}>
        {children}
      </PipelineQueryContext.Provider>
    </PipelineContext.Provider>
  );
};

export const usePipelineSettings = () => useContext(PipelineContext);
export const usePipelineQuery = () => useContext(PipelineQueryContext);

export const GetStatusType = (Status) => {
  const PipelineStatus = usePipelineStatus(Status);
  return PipelineStatus.Status;
};

export default PipelineProvider;
