import { useConnectionId } from "./RealTime";
import { useQuery } from "../../Helpers/IOClient";

export enum ConnectionStatusEnum {
  loading = 1,
  userIdError = 2,
  spaceError = 3,
  disconnected = 4,
  connected = 5,
}

export const ReversedRealTimeRoomTypes = {
  1: "Client",
  2: "Contact",
  3: "Deal",
  4: "Contract",
  5: "Call",
  6: "Project",
  7: "Ticket",
  8: "Task",
  9: "TimeCharge",
  10: "Subscription",
  11: "Billing",
  12: "SocialPost",
  13: "Email",
	18: "Documents",
	19: "DocTemplates",
};

export const RealTimeRoomTypes = {
  Client: 1,
  Contact: 2,
  Deal: 3,
  Contract: 4,
  Call: 5,
  Project: 6,
  Ticket: 7,
  Task: 8,
  TimeCharge: 9,
  Subscription: 10,
  Billing: 11,
  SocialPost: 12,
  Email: 13,
  EmailSignature: 14,
  Company: 15,
  Department: 16,
  Team: 17,
	PermissionQueue: 20,
	Documents: 18,
	DocTemplates: 19,
};

export const useConnectionQuery = (url: string, schema: any, options: any) => {
  const conId = useConnectionId();

  return useQuery(url, schema, {
    headers: {
      ConnectionID: conId,
    },
    ...options,
  });
};
