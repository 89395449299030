import React, { useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import {
  DepartmentDropdown,
  TSSTargetDropdown
} from "../../AdvancedMultiInputs";
import ArDatePicker from "../../../Components/DatePicker/ArDatePicker";
import { useDownloadExcelData, handleError } from "../../../Helpers/MiscHelper";
import api from "../../../api";
import LoadableButton from "../../../Components/Button/LoadableButton";
import { useConnectionId } from "../../RealTime/RealTime";
import { useToast } from "../../../Components/Toast/ToastProvider";
import KeyedDropdown from "../../../Components/Dropdown/KeyedDropdown";

const TSSExport = () => {
  const [dep, setDep] = useState(null);
  const [target, setTarget] = useState(null);
  const [beginDate, setBeginDate] = useState(
    moment().subtract(30, "days").toISOString()
  );
  const [endDate, setEndDate] = useState(moment().toISOString());

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "Dept":
        setDep(value);
        break;
      case "Target":
        setTarget(value);
        break;
      case "BeginDate":
        setBeginDate(value);
        break;
      case "EndDate":
        setEndDate(value);
        break;
      case "ProjectDate":
        setProjectYear(value);
        break;
      default:
        break;
    }
  };

  const [loading, setLoading] = useState(false);
  const [markerLoading, setMarkerLoading] = useState(false);

  const downloadExcelData = useDownloadExcelData();
  const intl = useIntl();
  const createToast = useToast();
  const conId = useConnectionId();

  const resolvedUrl = useMemo(() => {
    let resolvedType = 0;
    if (target) {
      const { type } = target;
      switch (type) {
        // case "Project":
        // 	return { projectId: target.data };
        // case "Task":
        // 	return { taskId: target.data };
        // case "Ticket":
        // 	return { ticketId: target.data };
        // case "Call":
        // 	return { callId: target.data };
        case "Contract":
          resolvedType = 1;
          break;
        // case "Subscription":
        // 	return { subscriptionId: target.data };
        // case originTypes.deals:
        // 	return { dealId: target.data };
        // case "Client":
        // 	return { clientId: target.data };
        default:
          break;
      }
    }
    return `spaces/1/Excel/TicketsAnalytics?type=${resolvedType}`;
  }, [target]);

  const handleExport = (body) => {
    setLoading(true);

    api
      .post(resolvedUrl, body, {
        responseType: "arraybuffer",
        headers: {
          ConnectionID: conId
        }
      })
      .then((r) => {
        let resolvedName = "";
        if (target) {
          const { type, data } = target;
          switch (type) {
            // case "Project":
            // 	return { projectId: target.data };
            // case "Task":
            // 	return { taskId: target.data };
            // case "Ticket":
            // 	return { ticketId: target.data };
            // case "Call":
            // 	return { callId: target.data };
            case "Contract":
              resolvedName = `${intl.formatMessage({
                id: "CONTRACT"
              })}-${data}-${intl.formatMessage({ id: "CASES" })}.xlsx`;
              break;
            // case "Subscription":
            // 	return { subscriptionId: target.data };
            // case originTypes.deals:
            // 	return { dealId: target.data };
            // case "Client":
            // 	return { clientId: target.data };
            default:
              break;
          }
        } else
          resolvedName = `${intl.formatMessage({ id: "CASES" })}_${moment(
            beginDate
          ).format("DD-MM-YYYY")}-${moment(endDate).format("DD-MM-YYYY")}.xlsx`;

        downloadExcelData(r, resolvedName);
        setLoading(false);
      })
      .catch((r) => {
        try {
          let parsedJson = JSON.parse(new TextDecoder().decode(r.data));
          handleError(createToast, parsedJson);
        } catch (e) {
          handleError(createToast, e);
        }
        setLoading(false);
      });
  };

  const handleMarkerExport = (body) => {
    setMarkerLoading(true);

    api
      .post(`spaces/1/Excel/GetTicketClosedByMarker`, body, {
        responseType: "arraybuffer",
        headers: {
          ConnectionID: conId
        }
      })
      .then((r) => {
        let resolvedName = "";
        if (target) {
          const { type, data } = target;
          switch (type) {
            // case "Project":
            // 	return { projectId: target.data };
            // case "Task":
            // 	return { taskId: target.data };
            // case "Ticket":
            // 	return { ticketId: target.data };
            // case "Call":
            // 	return { callId: target.data };
            case "Contract":
              resolvedName = `${intl.formatMessage({
                id: "CONTRACT"
              })}-${data}-${intl.formatMessage({ id: "CASES" })}.xlsx`;
              break;
            // case "Subscription":
            // 	return { subscriptionId: target.data };
            // case originTypes.deals:
            // 	return { dealId: target.data };
            // case "Client":
            // 	return { clientId: target.data };
            default:
              break;
          }
        } else
          resolvedName = `${intl.formatMessage({ id: "CASES" })}_${moment(
            beginDate
          ).format("DD-MM-YYYY")}-${moment(endDate).format("DD-MM-YYYY")}.xlsx`;

        downloadExcelData(r, resolvedName);
        setMarkerLoading(false);
      })
      .catch((r) => {
        try {
          let parsedJson = JSON.parse(new TextDecoder().decode(r.data));
          handleError(createToast, parsedJson);
        } catch (e) {
          handleError(createToast, e);
        }

        setMarkerLoading(false);
      });
  };

  const handleSubmit = () => {
    if (target) {
      handleExport({
        contractId: target?.data
      });
    } else
      handleExport({
        departmentId: dep,
        // pipelineId: pipeline,
        beginDate: beginDate,
        endDate: endDate
      });
  };

  const handleMarkerSubmit = () => {
    handleMarkerExport({
      departmentId: dep,
      // pipelineId: pipeline,
      beginDate: beginDate,
      endDate: endDate
    });
  };

  const shouldSubmit = useMemo(() => {
    if (!dep && !target) return true;
    return false;
  }, [dep, target]);

  const [ProjectYear, setProjectYear] = useState(new Date().getFullYear());
  const [projectExportLoading, setSetprojectExportLoading] = useState(false);

  const handleProjectExport = () => {
    setSetprojectExportLoading(true);

    api
      .post(
        `spaces/1/Excel/ProjectAnalytics`,
        {
          Year: ProjectYear
        },
        {
          responseType: "arraybuffer",
          headers: {
            ConnectionID: conId
          }
        }
      )
      .then((r) => {
        let resolvedName = `${intl.formatMessage({
          id: "PROJECTS"
        })}_${ProjectYear}.xlsx`;

        downloadExcelData(r, resolvedName);
        setSetprojectExportLoading(false);
      })
      .catch((r) => {
        try {
          let parsedJson = JSON.parse(new TextDecoder().decode(r.data));
          handleError(createToast, parsedJson);
        } catch (e) {
          handleError(createToast, e);
        }
        setSetprojectExportLoading(false);
      });
  };

  return (
    <div>
      <div className="mb-3 text-black fw-bold fs-16">
        <FormattedMessage id="CASES" />
      </div>
      <div className="d-flex flex-wrap mb-3" style={{ rowGap: "1rem" }}>
        <FormInput
          style={{ width: "300px" }}
          name="Target"
          className="mr-3"
          textId="CONTRACTS"
          value={target}
          allContracts={true}
          dontShowAddButton
          disabled={Boolean(dep)}
          onChange={handleChange}
          inputType={TSSTargetDropdown}
          closeOnSelect={true}
        />

        <FormInput
          style={{ width: "300px" }}
          name="Dept"
          className="mr-3"
          disabled={Boolean(target)}
          textId="DEPARTMENT"
          value={dep}
          onChange={handleChange}
          inputType={DepartmentDropdown}
          closeOnSelect={true}
        />
        {/* 
        <FormInput
          name="Pipeline"
          className="mr-3"
          value={pipeline}
          textId="PIPELINE"
          inputType={TicketPipelineDropdown}
          onChange={handleChange}
        /> */}

        {Boolean(dep) && (
          <>
            <FormInput
              style={{ minWidth: 150 }}
              name="BeginDate"
              textId="START_DATE"
              value={beginDate}
              className="mr-3"
              onChange={handleChange}
              inputType={ArDatePicker}
              // componentClassName="w-100"
              enableHours={false}
            />
            <FormInput
              style={{ minWidth: 150 }}
              name="EndDate"
              textId="END_DATE"
              value={endDate}
              inputType={ArDatePicker}
              onChange={handleChange}
              // componentClassName="w-100"
              enableHours={false}
            />
          </>
        )}
      </div>
      <div className="d-flex mt-4">
        <LoadableButton
          disabled={shouldSubmit}
          onClick={handleSubmit}
          isLoading={loading}
        >
          Exportar por estado
        </LoadableButton>

        <LoadableButton
          className="ml-4"
          disabled={!Boolean(dep)}
          onClick={handleMarkerSubmit}
          isLoading={markerLoading}
        >
          Exportar por marcador
        </LoadableButton>
      </div>
      <hr></hr>
      <div className="mb-3 text-black fw-bold fs-16">
        <FormattedMessage id="PROJECTS" />
      </div>
      <div className="d-flex flex-wrap mb-3" style={{ rowGap: "1rem" }}>
        <FormInput
          style={{ width: "300px" }}
          name="ProjectDate"
          className="mr-3"
          textId="DATE"
          value={ProjectYear}
          onChange={handleChange}
          inputType={DateDropdown}
          closeOnSelect={true}
        />
      </div>
      <div className="d-flex mt-4">
        <LoadableButton
          onClick={handleProjectExport}
          isLoading={projectExportLoading}
        >
          Exportar Tempos dos Projetos
        </LoadableButton>
      </div>
    </div>
  );
};

const DateDropdown = ({ name, value, onChange, ...rest }) => {
  const DateOptions = useMemo(() => {
    const years = [];
    for (let i = new Date().getFullYear(); i > 2018; i--) {
      years.push(i);
    }
    return years;
  }, []);

  return (
    <KeyedDropdown
      name={name}
      options={DateOptions}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

export default TSSExport;
