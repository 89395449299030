import {
  faBuilding,
  faCamera,
  faChevronDoubleRight,
  faEye,
  faSearch,
  faSignature,
  faTimes,
  faUser,
  faUsers,
  faUsersClass
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  createFormRequestHook,
  FormStateContext,
  useFormState
} from "../../Components/Forms";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import { AccountProfileImg } from "../../Components/Img/Img";
import { LogoPictures } from "../../Components/ProfilePicture/ProfilePicture";
import { SideForm, SideFormFooter } from "../../Components/Forms/SideMenuForm";
import {
  BaseSidebarHeader,
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import classnames from "classnames";
import Switch from "../../Components/Switch/Switch";
import TabStrip from "../../Components/TabStrip/TabStrip";
import {
  ContactStatusDropdown,
  DepartmentDropdown,
  OrganizationDropdown,
  TeamDropdown,
  UserDropdown
} from "../AdvancedMultiInputs";
import EmailSignatureFormTabModels from "./EmailSignatureFormTabs/EmailSignatureFormTabModels";
import "./EmailSignatureFormTabs/EmailSignatureFormModes.css";
import { getEntity } from "../../Helpers/IOClient";
import {
  convertEmailSignatureEntityToForm,
  formatSignatureForm,
  TagsDictionary,
  TagsTranslationDictionary,
  validateEmailSignatureForm
} from "./EmailSignatureFormHelper";
import {
  accountSchema,
  spaceDepartmentSchema,
  spaceEmailSignatureSchema,
  spaceOrganizationSchema,
  spaceTeamSchema
} from "../../config/schema";
import { useToast } from "../../Components/Toast/ToastProvider";
import { useHandleTagReplaces } from "./EmailSignatureTagsSwap.jsx";
import Popup, { usePopupOpenState } from "../../Components/Popup/Popup";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useEmailSignature } from "../../Hooks/EntityHooks";
import { useHandleError } from "../../Components/CGrid/ServerAwareHelper";
import Sujestion from "../../Components/DocTyper/TiptapExtensions/EmailNotificationTags/Sujestion";
import DocTyper from "../../Components/DocTyper/DocTyper";
import EmailSignatureTags from "../../Components/DocTyper/TiptapExtensions/EmailNotificationTags/EmailSignatureTags";

const ReceptionSwitch = ({ ...props }) => {
  const { value, onChange } = props;

  const handleChange = (e) => {
    const { checked } = e.target;
    onChange(checked);
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="text-black fs-14">
        <FormattedMessage id="RECEPTION_SIGNATURE" />
      </div>
      <Switch {...props} onChange={handleChange} checked={value} />
    </div>
  );
};

const AnswerSwitch = ({ ...props }) => {
  const { value, onChange } = props;

  const handleChange = (e) => {
    const { checked } = e.target;

    onChange(checked);
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="text-black fs-14">
        <FormattedMessage id="ANSWER_SIGNATURE" />
      </div>
      <Switch {...props} onChange={handleChange} checked={value} />
    </div>
  );
};

const Details = () => {
  const intl = useIntl();
  const form = useContext(FormStateContext);
  const { Logo, Banner, Users } = form;

  return (
    <div className="overflow-hidden pr-3">
      <FormInput
        name="Name"
        className="mb-3 mt-3"
        text={intl.formatMessage({ id: "NAME" })}
      />
      <FormInput
        text={intl.formatMessage({ id: "STATUS" })}
        inputType={ContactStatusDropdown}
        className="mb-3"
        name="Status"
      />
      <hr />
      <div className="text-black fs-1  mb-3 fw-bold">
        <FormattedMessage id="APPLY_TO" />
      </div>
      <FormInput
        name="Company"
        className="mb-3"
        multiple
        text={intl.formatMessage({ id: "COMPANY" })}
        inputType={OrganizationDropdown}
      />
      <FormInput
        name="Departments"
        className="mb-3"
        multiple
        text={intl.formatMessage({ id: "DEPARTMENT" })}
        inputType={DepartmentDropdown}
      />
      <FormInput
        name="Teams"
        multiple
        className="mb-3"
        text={intl.formatMessage({ id: "TEAM" })}
        inputType={TeamDropdown}
      />
      <FormInput
        multiple
        name="Users"
        className="mb-3"
        text={intl.formatMessage({ id: "USER" })}
        inputType={UserDropdown}
      />
      <FormInput
        multiple
        ExcludedUsers={Users}
        name="ExcludedUsers"
        className="mb-3"
        text={intl.formatMessage({ id: "EXCLUDED_USER" })}
        inputType={UserDropdown}
      />
      <FormInput
        inputType={ReceptionSwitch}
        className="mb-3"
        name="Reception"
      />
      <FormInput inputType={AnswerSwitch} className="mb-3" name="Answer" />
      <hr />
      <div className="text-black fs-1  mb-3 fw-bold">
        <FormattedMessage id="BRANDING" />
      </div>
      <FormInput
        text={`${intl.formatMessage({ id: "LOGO" })} 180x40 (9:2)`}
        name="Logo"
        inputType={LogoPictures}
        componentClassName="air-min-width-signature-pictures"
        className="mb-3 air-signature-logo"
        uploadText={
          <div className="link-color fs-12 mt-1">
            <FontAwesomeIcon icon={faCamera} />{" "}
            <FormattedMessage id="UPLOAD_IMAGE" />
          </div>
        }
        placeHolderComponent={
          <AccountProfileImg
            className="rounded-50 mt-1"
            account={{ Name: "", Logo }}
          />
        }
      />
      <FormInput
        name="LogoUrl"
        className="mb-3"
        text={
          intl.formatMessage({ id: "LOGO_LINK" }) +
          " (" +
          intl.formatMessage({ id: "OPTIONAL" }) +
          ")"
        }
      />
      <FormInput
        text={`${intl.formatMessage({ id: "BANNER" })} 500x100 (5:1)`}
        name="Banner"
        inputType={LogoPictures}
        componentClassName="air-min-width-signature-pictures"
        className="mb-3"
        uploadText={
          <div className="link-color fs-12 mt-1">
            <FontAwesomeIcon icon={faCamera} />{" "}
            <FormattedMessage id="UPLOAD_IMAGE" />
          </div>
        }
        placeHolderComponent={
          <AccountProfileImg
            className="rounded-50 mt-1"
            account={{ Name: "", Banner }}
          />
        }
      />
      <FormInput
        name="BannerUrl"
        className="mb-3"
        text={
          intl.formatMessage({ id: "BANNER_LINK" }) +
          " (" +
          intl.formatMessage({ id: "OPTIONAL" }) +
          ")"
        }
      />
    </div>
  );
};

const Models = () => {
  return <EmailSignatureFormTabModels />;
};

const Editor = () => {
  const intl = useIntl();
  const divRef = useRef();
  const [CurrentHeight, setCurrentHeight] = useState(170);
  useLayoutEffect(() => {
    setCurrentHeight(divRef.current.offsetHeight);
  }, []);

  const myItems = {
    items: ({ query }) => {
      const TranslatedNormalTags = [];
      const AutoCompleteOptions = [];

      for (const key in TagsDictionary) {
        if (TagsDictionary.hasOwnProperty(key)) {
          const element = TagsDictionary[key];
          TranslatedNormalTags.push([
            element,
            intl.formatMessage({ id: TagsTranslationDictionary[key] })
          ]);
        }
      }
      for (var e of TranslatedNormalTags) {
        AutoCompleteOptions.push({
          code: e[0],
          tag: e[1]
        });
      }
      return AutoCompleteOptions.filter((item) =>
        item.tag.toLowerCase().includes(query.toLowerCase())
      ).slice(0, 5);
    },
    ...Sujestion
  };

  const ExtraExtensions = [
    EmailSignatureTags.configure({
      HTMLAttributes: {
        class: "email-tag"
      },
      suggestion: myItems
    })
  ];

  return (
    <div className="d-flex flex-column flex-1 h-100">
      <div ref={divRef} className="h-100 of-y-auto pt-3 pb-2">
        <FormInput
          name="Signature"
          className="h-100"
          key={CurrentHeight}
          extraStyles={PreviewStyles}
          componentClassName="h-100"
          inputType={DocTyper}
          extraExtensions={ExtraExtensions}
          extraMenuItemsForEmailSignature
          DisabledmaximizeButton={true}
          autoGrow={true}
          height={CurrentHeight - 140}
        />
      </div>
    </div>
  );
};

const PreviewStyles = `<style type="text/css">
	p{
margin:5px 5px;
padding:5px;
}
a{
	text-decoration: none
}
</style>`;

// const handleTagReplaces = (Signature = "", user = {}, form = {}) => {
//   const { Banner = {}, Logo = {}, BannerUrl, LogoUrl } = form;

//   let NewSignature = Signature.replace(
//     /&zwnj;\[\[USER_NAME\]\]&zwnj;/g,
//     `${user.Name}`
//   );
//   NewSignature = NewSignature.replace(
//     /&zwnj;\[\[THUMBNAIL\]\]&zwnj;/g,
//     `<img style="
// 		width: 64px;
// 		height: 64px;
// 		border-radius: 32px;
// 	" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA+CAMAAACr3TNIAAAAAXNSR0IArs4c6QAAAYlQTFRFAAAA////////////////////////////////////////9P//9f//9/f/9/f/+Pj/+Pj/+Pj/+fn/8/n/8/n/9Pn/9Pr/9Pr/9vb/8/f/9Pf/9Pj/9Pj/9fj/9fj/9fn/9vn/9vn/8/b88/b89Pf89Pf89Pf89fj98/j98/j98/j99Pj99Pb99Pb99Pb99Pf99Pj99Pj99Pb98/f98/f98/f99Pf99Pf99Pf99Pf99Pf99Pf99Pf89Pf89Pf88/f88/f88/f88/f88/f88/b89Pb89Pb99Pf99Pf98/f98/f98/f98/f98/f98/f99Pf99Pf99Pf99Pb99Pb98/b98/f98/f99Pf99Pf99Pf99Pf99Pf91d7o1t7o19/p1+Dp1+Hq2ODp2OHq2eHr2uLr2+Ps2+Ts3eTt3eXu3uXv3ubv3+bv4Ofv4ejw4enw4+nx5Ory5Ovz5uz06O316e726u/26+/27PD37fL47vH57vP58PT78fT78fX88vX88vb88/b98/f99Pf9rVjHjQAAAFx0Uk5TAAECBAUJDQ4REhQYGR8gJCYnKCssLi8wOEBDRUhKTE9TVFZYWl1haWprbHBzdHV4iIyQl5mboKGjpKWotre4wMHDxMXHy8zS09bX2Nna3N/h4uXm7/Dx9fb3+fwJXXSrAAACc0lEQVQYGaXBi0NLYRwG4BeTXCOXGbm15FJkobB0IuZSkVit9lUz2lw2Num6kb1/OcnsfDu/7+wcngcGzZ2R/rux8QQT47GB/khnM3xovTqUpC451N0Cby49o2zsAhra1j5Ks9Gz2+DqZIzuHp6AWeAmG7segMHeB/RieDdER1/Sm+dBCNoT9GqqAw7n6Ec76hxL0I9EEJo9L+jPiz2w2XGfft3bgZqb9O8G/mqj2TeahFBlUVYuvHutXr8rlCmx8EeIsq/zasv8V0pC2GJRlFM1OQos/BaiaFHZLVIQwiaLktKcspsr0cnCL00zlHxSuk90mtkFIEzRgtItUBAGEKWkMqt0sxU69QGBSUrWVb11Ok0AIYo2VL0NCo6ji7K3SveWki70UpZTuhwl1xClbE3p1ijpg0WDgrIrUGRhhAaVjKrJVCgaQZwmPz6rqs8/KIsjTrPV9ymlVOr9Kk3iGKGr8kqZLkZg8X9YiFLwfbn4MZvN5lcqJCsr+Ww2+7G4/J0OUfTSaSmlqtILaVWVWmK9XvTQIa9M8qzTgzbWW1Rmi9S1IRCnrjSnzOZKtJsMAFHqispNkXZRAGHqPig3H2gXBrBrhpq0cpOmzUwTfhmkRrmjzSA2hahJKzdp2oTw2yDtispNkTWD2HKEmi+ZNyaZL6xJHsYfUf6LPlQdmKZ/0/vxVxf964LNLfp1B3Y7n9CfpzuhaX1FP+KHUOc0/TgFh44pejXVAUFwgt5MBCHaN0wvhvfBIHCbjd0OwOz0I7p7fAautp9/TrOx8HY0dPEZZWOX4c3B7qEkdcmhKy3wobkz0j8QG08wMR4b6I90NkP2E9FVstM6GBNQAAAAAElFTkSuQmCC'"
// 			src="${user.Image}"
// 			alt=""
// 		/>`
//   );

//   if (LogoUrl) {
//     NewSignature = NewSignature.replace(
//       /&zwnj;\[\[LOGO\]\]&zwnj;/g,
//       `<a
// 			href="${LogoUrl}"
// 			rel="noopener noreferrer"
// 			target="_blank"
// 		><img onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAoCAMAAABgtyA4AAAAAXNSR0IArs4c6QAAAJNQTFRFAAAA////8/n/8/f/9Pf/9fj/8/f98/f99Pf99Pj99Pf99Pf89Pb99Pf99Pb98/b99Pf99Pf91d7o2ODq2eHr2+Pt3eXu3uTu3+fw4ejw4+rx4+ry5Ory5evz5uv06O316u/26/H37PH37vL58PT68fX88/b98/f89Pb89Pf89Pf99Pf+9Pj99ff99ff+9fj99fj+6P0+AwAAABJ0Uk5TAAMqQENJgIOGiJy4zeDo7/3+9Hmm/AAAAThJREFUWMPt2NtugkAQBuDfU7WiiGNZu60Vl4OHbVHf/+l6ISLVRryYkJ3E/3Ynux+TCZAF0BtNrJhMRj0ArcHUisq030LHSkvQhce01T6vzZ7pKA8+yz759pGq7YHlMB88D79jLasLDzpnL2wA/c1e2BzaaKWNMHRERERaFNrQKStJaF2glSR0WKBJElr9i84WibjxyEKaJQ6j4wIdV1cUEb1lzr/yltWFU/fDzOGPS/ox1/GtuaJ2EH2ds/midh+9oEuU0+jP9/MoRFSNdhity6b+NRMZZ9HLsqnXZnfRUTkKhqSgy+7qZCYFfTMRAtApCUSbJ/qJvjPTX3eTCvlhEnHvIQtt16xlDaF/HivLmdA8V732uKmv2Rx5zvLZLtUbjIduIM0cdIBXaegXAGgPx3LE42Eb+AUwjXMH7REgPQAAAABJRU5ErkJggg=='"
// 				src="${Logo && Logo.image}"
// 				alt=""
// 			/></a>`
//     );
//   } else {
//     NewSignature = NewSignature.replace(
//       /&zwnj;\[\[LOGO\]\]&zwnj;/g,
//       `<img onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAoCAMAAABgtyA4AAAAAXNSR0IArs4c6QAAAJNQTFRFAAAA////8/n/8/f/9Pf/9fj/8/f98/f99Pf99Pj99Pf99Pf89Pb99Pf99Pb98/b99Pf99Pf91d7o2ODq2eHr2+Pt3eXu3uTu3+fw4ejw4+rx4+ry5Ory5evz5uv06O316u/26/H37PH37vL58PT68fX88/b98/f89Pb89Pf89Pf99Pf+9Pj99ff99ff+9fj99fj+6P0+AwAAABJ0Uk5TAAMqQENJgIOGiJy4zeDo7/3+9Hmm/AAAAThJREFUWMPt2NtugkAQBuDfU7WiiGNZu60Vl4OHbVHf/+l6ISLVRryYkJ3E/3Ynux+TCZAF0BtNrJhMRj0ArcHUisq030LHSkvQhce01T6vzZ7pKA8+yz759pGq7YHlMB88D79jLasLDzpnL2wA/c1e2BzaaKWNMHRERERaFNrQKStJaF2glSR0WKBJElr9i84WibjxyEKaJQ6j4wIdV1cUEb1lzr/yltWFU/fDzOGPS/ox1/GtuaJ2EH2ds/midh+9oEuU0+jP9/MoRFSNdhity6b+NRMZZ9HLsqnXZnfRUTkKhqSgy+7qZCYFfTMRAtApCUSbJ/qJvjPTX3eTCvlhEnHvIQtt16xlDaF/HivLmdA8V732uKmv2Rx5zvLZLtUbjIduIM0cdIBXaegXAGgPx3LE42Eb+AUwjXMH7REgPQAAAABJRU5ErkJggg=='"
// 				src="${Logo && Logo.image}"
// 				alt=""
// 			/>`
//     );
//   }

//   if (BannerUrl) {
//     NewSignature = NewSignature.replace(
//       /&zwnj;\[\[BANNER\]\]&zwnj;/g,
//       `<a
// 				href="${BannerUrl}"
// 				rel="noopener noreferrer"
// 				target="_blank"
// 			><img
// 				src="${
//           Banner && Banner.image
//         }" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAABkCAMAAABHGTJPAAAAAXNSR0IArs4c6QAAAJNQTFRFAAAA////8/n/8/f/9Pf/9fj/8/f98/f99Pf99Pj99Pf99Pf89Pb99Pf99Pb98/b99Pf99Pf91d7o2ODq2eHr2+Pt3eXu4ejw4+rx4+ry5Ory5evz5uv06O316u/26/H37PH37vL58PT68fX88/b88/b98/f88/f99Pb89Pb99Pf89Pf99Pj99Pj+9ff99fj99fj+UAvPrgAAABJ0Uk5TAAMqQENJgIOGiJy4zeDo7/3+9Hmm/AAAAfxJREFUeNrt3EtT2zAUgFHx6oNACAohrYOh1KQPQoH//+u6IBOczsSdhA3WPd/em3vGsuSFUkpHJ2cLBens5CiltPfp3Cgidf5xLx0YQ7BGh2lgCtEapKEhRGuYzCBe0KELuqALuqALuqALuqALuqALuqALuqALuqBDF3RBF3RBf6c9LubbP3QPvdfmc691NPQ/Oz/5G3pfu3vLdwF6uB6gx+sn9P53XVU30EOhV+Occx7X0OOgT/OyKfQo6FVedQ09Bnrzap4voMdAr1vo+QZ6CPSqjX4FHfrLee4WemFd/W95n+ZcQy+r7y3z8aYDXQ29rL52ru7LQ3wNvawmHT9nVj9uauhl9eVlbZ91mP+jDr3/NfVsVjed5uvq0IttzXxNHXoQ87Y69CjmLXXohVTny6bb/FUdeinmOU+abvOcoZdm3lLfYA69PPOV+iZz6AWaL9U3mkMv0TznSdNhDr1I85wnHebQyzTvDno8c+iFdAkdOnTo0KFDhw69n91+2yLocYMOHXpvesOFYs/Qe9ruF4PNn6D3tN3lir5RrPQbI3/t9p6XbV78Ru5x8WPb7h4KN7d7jxh06IIu6IIu6IIu6IIu6IIu6IIu6IIu6IIu6NAFXdBVDvrQDKI1TANDiNYgHY5MIVajg5Q+G0OsPqSU0v7xqUlE6fR4P6W/T9R5nL3bWEEAAAAASUVORK5CYII='"
// 				alt=""
// 				style="height: 111px; width: 587px"
// 			/></a>`
//     );
//   } else {
//     NewSignature = NewSignature.replace(
//       /&zwnj;\[\[BANNER\]\]&zwnj;/g,
//       `<img
// 				src="${
//           Banner && Banner.image
//         }" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAABkCAMAAABHGTJPAAAAAXNSR0IArs4c6QAAAJNQTFRFAAAA////8/n/8/f/9Pf/9fj/8/f98/f99Pf99Pj99Pf99Pf89Pb99Pf99Pb98/b99Pf99Pf91d7o2ODq2eHr2+Pt3eXu4ejw4+rx4+ry5Ory5evz5uv06O316u/26/H37PH37vL58PT68fX88/b88/b98/f88/f99Pb89Pb99Pf89Pf99Pj99Pj+9ff99fj99fj+UAvPrgAAABJ0Uk5TAAMqQENJgIOGiJy4zeDo7/3+9Hmm/AAAAfxJREFUeNrt3EtT2zAUgFHx6oNACAohrYOh1KQPQoH//+u6IBOczsSdhA3WPd/em3vGsuSFUkpHJ2cLBens5CiltPfp3Cgidf5xLx0YQ7BGh2lgCtEapKEhRGuYzCBe0KELuqALuqALuqALuqALuqALuqALuqALuqBDF3RBF3RBf6c9LubbP3QPvdfmc691NPQ/Oz/5G3pfu3vLdwF6uB6gx+sn9P53XVU30EOhV+Occx7X0OOgT/OyKfQo6FVedQ09Bnrzap4voMdAr1vo+QZ6CPSqjX4FHfrLee4WemFd/W95n+ZcQy+r7y3z8aYDXQ29rL52ru7LQ3wNvawmHT9nVj9uauhl9eVlbZ91mP+jDr3/NfVsVjed5uvq0IttzXxNHXoQ87Y69CjmLXXohVTny6bb/FUdeinmOU+abvOcoZdm3lLfYA69PPOV+iZz6AWaL9U3mkMv0TznSdNhDr1I85wnHebQyzTvDno8c+iFdAkdOnTo0KFDhw69n91+2yLocYMOHXpvesOFYs/Qe9ruF4PNn6D3tN3lir5RrPQbI3/t9p6XbV78Ru5x8WPb7h4KN7d7jxh06IIu6IIu6IIu6IIu6IIu6IIu6IIu6IIu6NAFXdBVDvrQDKI1TANDiNYgHY5MIVajg5Q+G0OsPqSU0v7xqUlE6fR4P6W/T9R5nL3bWEEAAAAASUVORK5CYII='"
// 				alt=""
// 				style="height: 111px; width: 587px"
// 			/>`
//     );
//   }

//   return NewSignature;
// };

const tabs = [
  {
    default: true,
    name: <FormattedMessage id="DETAILS" />,
    component: <Details />
  },
  {
    name: <FormattedMessage id="TEMPLATE" />,
    component: <Models />
  },
  {
    name: <FormattedMessage id="EDITOR" />,
    component: <Editor />
  }
];

const useEmailSignatureRequest = createFormRequestHook({
  schema: spaceEmailSignatureSchema,
  convertEntityToForm: convertEmailSignatureEntityToForm,
  formatDuplicationEntity: (entity) => {
    if (entity.Id) {
      entity.Id = undefined;
    }
    return entity;
  }
});

const PreviewApplyToContext = React.createContext();

const defaultEmailSignatureForm = {
  Name: "",
  Signature: "",
  Status: 1,
  BannerUrl: "",
  LogoUrl: "",
  Answer: false,
  Reception: false,
  ApplyToOption: undefined,
  AppliedToUser: undefined
};

const EmailSignatureForm = ({ ...props }) => {
  const {
    // key,
    loading,
    error,
    convertedForm,
    // isChild,
    // childProps,
    baseForm
  } = useEmailSignatureRequest(props);

  const { id } = props;

  const emailSignature = useEmailSignature(id);

  if (loading || error)
    return (
      <div className={classnames("bg-white d-flex flex-column h-100")}>
        <BaseSidebarHeader style={{ zIndex: 2 }} disableSizeSelector={true}>
          <FormattedMessage id="EDIT_EMAIL_SIGNATURE" />
        </BaseSidebarHeader>
        <div
          style={{ zIndex: 1, width: 720 }}
          className="d-flex h-100 flex-column of-hidden shadow"
        >
          <LoaderSpinner center size="sm" className="text-secondary" />;
        </div>
      </div>
    );

  return (
    <EmailSignatureBaseForm
      convertedForm={convertedForm}
      baseForm={baseForm}
      canDelete={emailSignature?.CanDelete}
      {...props}
    />
  );
};

const EmailSignatureBaseForm = ({
  className,
  convertedForm,
  baseForm,
  refetchRef,
  onSuccess,
  canDelete,
  ...props
}) => {
  const resolvedForm = useMemo(() => {
    return {
      ...defaultEmailSignatureForm,
      ...convertedForm,
      ...baseForm
    };
  }, [baseForm, convertedForm]);

  const formState = useFormState(() => {
    return {
      Signature: "",
      Answer: false,
      Reception: false,
      ...resolvedForm
    };
  });
  const previewClosedRef = useRef(true);
  const [previewState, setPreviewState] = useState(false);
  const togglePreviewState = () => {
    setPreviewState(!previewState);
  };
  const { form, handleChange } = formState;
  const { Signature } = form;

  useEffect(() => {
    if (Signature?.length > 0 && previewClosedRef.current) {
      previewClosedRef.current = false;
      setPreviewState(true);
    }
  }, [Signature]);

  // const setView = useContext(SidebarViewSetterContext);

  // useEffect(() => {
  //   setView(SidebarVisualTypeEnum.fullscreen);
  // }, [setView]);

  const { Id } = form;

  const createToast = useToast();
  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceEmailSignatureSchema);

  const [post, { loading: submitLoading }] = useSidebarSpacePost(
    Id ? `EmailSignatures/${Id}` : `EmailSignatures`,
    spaceEmailSignatureSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "SIGNATURE" })} nº ${newId || Id}`,
          description: intl.formatMessage({ id: "SUCCESSFULLY_RECORDED" })
        });
        onSuccess && onSuccess();
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `EmailSignatures/${Id}`,
    spaceEmailSignatureSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "SIGNATURE" })} nº ${Id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const handleSubmit = () => {
    const formatedForm = formatSignatureForm(form);
    post(formatedForm);
  };

  const handleApplyToOptionPick = (option) => {
    handleChange({ ApplyToOption: option });
  };
  const handleApplyToUserPick = (e) => {
    const { value } = e.target;

    handleChange({ AppliedToUser: value });
  };

  //TODO REMOVE THE NEXT LINE
  // const {data, loading, error} = useSpaceQuery(`Query/EmailSignatures?type=2&organizationType=1`)

  const defaultTabWidth = window.innerWidth > 1440 ? 720 : 690;

  return (
    <div className={classnames("bg-white d-flex flex-column h-100")}>
      <BaseSidebarHeader style={{ zIndex: 2 }} disableSizeSelector={true}>
        <FormattedMessage
          id={Id ? "EDIT_EMAIL_SIGNATURE" : "ADD_EMAIL_SIGNATURE"}
        />
      </BaseSidebarHeader>
      <div className="d-flex flex-1 of-y-auto of-x-hidden">
        <div
          style={{ zIndex: 1, width: defaultTabWidth }}
          className="d-flex h-100 flex-column of-hidden shadow"
        >
          <div className="flex-1 of-y-auto d-flex ar-entity-summary-container flex-column">
            <SideForm
              canEdit={true}
              formState={formState}
              onSubmit={handleSubmit}
              validate={validateEmailSignatureForm}
            >
              <div className="flex-1 of-y-auto air-signature-form">
                <TabStrip
                  route={false}
                  // defaultPath={path}
                  alignLeft
                  items={tabs}
                  className="ar-details-tabstrip h-100 px-3"
                />
              </div>
              <SideFormFooter
                canDelete={canDelete}
                deleteLoading={deleteLoading}
                handleDelete={remove}
                disabled={false}
                isLoading={submitLoading}
              />
            </SideForm>
          </div>
        </div>
        <PreviewApplyToContext.Provider value={handleApplyToOptionPick}>
          <div
            style={{ width: previewState ? defaultTabWidth : 60 }}
            className="ar-entity-summary-messages flex-1"
          >
            <div
              // style={{ paddingBottom: !previewState ? "6px" : "" }}
              className={classnames("Email-signature-preview-header  d-flex", {
                "justify-content-between mx-4": previewState,
                "justify-content-center align-items-center flex-column":
                  !previewState
              })}
            >
              <div
                onClick={() => {
                  if (!previewState) togglePreviewState();
                }}
                className={classnames({
                  "d-flex align-items-center text-black fs-16": previewState,
                  "cursor-pointer bg-airdesk ar-entity-summary-message-rounded-button p-2 ar-entity-summary-message-send-button":
                    !previewState
                })}
              >
                <FontAwesomeIcon icon={faEye} />
                {previewState && (
                  <span className="ml-2">
                    <FormattedMessage id="PREVIEW" />
                  </span>
                )}
              </div>
              {previewState && (
                <div
                  onClick={togglePreviewState}
                  className="d-flex cursor-pointer align-items-center fs-16 text-color-link"
                >
                  <FontAwesomeIcon icon={faChevronDoubleRight} />
                </div>
              )}
            </div>
            <div className="w-100 border-bottom mb-3"></div>
            {previewState && (
              <div>
                <SignaturePreviewApplication
                  handleAppliedToUserChange={handleApplyToUserPick}
                  form={form}
                />
              </div>
            )}
            {previewState && (
              <div className="my-4 d-flex flex-column align-items-center">
                <PreviewComponent signature={Signature} form={form} />
              </div>
            )}
          </div>
        </PreviewApplyToContext.Provider>
      </div>
    </div>
  );
};

const ApplyToPopupContent = ({
  companies = [],
  departments = [],
  teams = [],
  users = [],
  excludedusers = [],
  name = "",
  value = {},
  onSuccess
}) => {
  const handleClick = useContext(PreviewApplyToContext);
  const { optionId } = value;

  const companyGroup = useMemo(() => {
    if (companies.length === 0) return null;

    return (
      <div>
        <div
          style={{ top: 0 }}
          className="p-1 px-3 position-sticky d-flex fs-14 bg-airdesk text-black align-items-center"
        >
          <FontAwesomeIcon icon={faBuilding} />
          <div className="ml-2">
            <FormattedMessage id="COMPANY" />
          </div>
        </div>
        <div style={{ padding: "8px 8px" }}>
          {companies.map((e) => {
            const company = getEntity(spaceOrganizationSchema, e);
            return (
              <div
                key={e}
                onClick={() => {
                  handleClick({
                    optionSchema: spaceOrganizationSchema,
                    optionId: e
                  });
                  if (onSuccess) onSuccess();
                }}
                className="ar-dropdown-list-button p-0"
              >
                <div className="d-flex p-1 px-3 of-hidden align-items-center">
                  <AccountProfileImg
                    className="flex-0-0-auto"
                    size="mds"
                    circular
                    account={company}
                  />
                  <div className="d-flex ml-2 text-truncate  flex-column">
                    <div className="text-black  text-truncate fs-14">
                      {name}
                    </div>
                    <div className="text-grey-light  text-truncate fs-12">
                      {company.Name}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [companies, handleClick, name, onSuccess]);

  const departmentGroup = useMemo(() => {
    if (departments.length === 0) return null;

    return (
      <div>
        <div
          style={{ top: 0 }}
          className="p-1 px-3 d-flex position-sticky bg-airdesk fs-14 text-black align-items-center"
        >
          <FontAwesomeIcon icon={faUsers} />
          <div className="ml-2">
            <FormattedMessage id="DEPARTMENT" />
          </div>
        </div>
        <div style={{ padding: "8px 8px" }}>
          {departments.map((e) => {
            const department = getEntity(spaceDepartmentSchema, e);
            return (
              <div
                key={e}
                onClick={() => {
                  handleClick({
                    optionSchema: spaceDepartmentSchema,
                    optionId: e
                  });
                  if (onSuccess) onSuccess();
                }}
                className="ar-dropdown-list-button p-0"
              >
                <div className="d-flex px-3 p-1 of-hidden align-items-center">
                  <AccountProfileImg
                    className="flex-0-0-auto"
                    size="mds"
                    circular
                    account={department}
                  />
                  <div className="d-flex ml-2 text-truncate  flex-column">
                    <div className="text-black  text-truncate fs-14">
                      {name}
                    </div>
                    <div className="text-grey-light  text-truncate fs-12">
                      {department.Name}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [departments, handleClick, name, onSuccess]);

  const teamGroup = useMemo(() => {
    if (teams.length === 0) return null;

    return (
      <div>
        <div
          style={{ top: 0 }}
          className="p-1 position-sticky px-3 d-flex bg-airdesk fs-14 text-black align-items-center"
        >
          <FontAwesomeIcon icon={faUsersClass} />
          <div className="ml-2">
            <FormattedMessage id="TEAM" />
          </div>
        </div>
        <div style={{ padding: "8px 8px" }}>
          {teams.map((e) => {
            const team = getEntity(spaceTeamSchema, e);
            return (
              <div
                key={e}
                onClick={() => {
                  handleClick({ optionSchema: spaceTeamSchema, optionId: e });
                  if (onSuccess) onSuccess();
                }}
                className="ar-dropdown-list-button p-0"
              >
                <div className="d-flex px-3 p-1 of-hidden align-items-center">
                  <AccountProfileImg
                    className="flex-0-0-auto"
                    size="mds"
                    circular
                    account={team}
                  />
                  <div className="d-flex ml-2 text-truncate  flex-column">
                    <div className="text-black fs-14  text-truncate">
                      {name}
                    </div>
                    <div className="text-grey-light  text-truncate fs-12">
                      {team.Name}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [handleClick, name, onSuccess, teams]);

  const usersGroup = useMemo(() => {
    if (users.length > 0) {
      return (
        <div>
          <div
            style={{ top: 0 }}
            className="p-1 px-3 fs-14 d-flex position-sticky bg-airdesk text-black align-items-center"
          >
            <FontAwesomeIcon icon={faUser} />
            <div className="ml-2">
              <FormattedMessage id="PERSONAL" />
            </div>
          </div>
          <div style={{ padding: "8px 8px" }}>
            <div
              onClick={() => {
                handleClick({ optionSchema: accountSchema, optionId: null });
                if (onSuccess) onSuccess();
              }}
              className="ar-dropdown-list-button p-0"
            >
              <div className="d-flex p-1 px-3 of-hidden align-items-center">
                <div
                  className={
                    "img mds circular flex-0-0-auto mr-2 text-color-link text-truncate account"
                  }
                >
                  <FontAwesomeIcon icon={faSignature} />
                </div>
                <div className="d-flex text-truncate  flex-column">
                  <div className="text-black text-truncate fs-14">{name}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  }, [users.length, name, handleClick, onSuccess]);

  const removeOption = useMemo(() => {
    if (optionId) {
      return (
        <div
          onClick={() => {
            handleClick({ optionId: null });
            if (onSuccess) onSuccess();
          }}
          className="ar-dropdown-list-button p-0"
        >
          <div className="d-flex px-3 p-1 align-items-center">
            <div style={{ height: 30 }}></div>
            <FontAwesomeIcon icon={faTimes} className="text-danger-alt" />
            <div className="d-flex ml-2 flex-column">
              <span>
                <FormattedMessage id="REMOVE" />
              </span>
            </div>
          </div>
        </div>
      );
    } else return null;
  }, [handleClick, onSuccess, optionId]);

  return (
    <div
      style={{ width: 300, maxHeight: "77vh" }}
      className="d-flex of-y-auto flex-column bg-white"
    >
      {removeOption}
      {companyGroup}
      {departmentGroup}
      {teamGroup}
      {usersGroup}
    </div>
  );
};

const ApplyToOptionComponent = ({ option = {}, name }) => {
  const { optionSchema, optionId } = option;

  const content = useMemo(() => {
    switch (optionSchema && optionSchema.name) {
      case "SpaceOrganizations":
        const company = getEntity(spaceOrganizationSchema, optionId);
        return (
          <div className="d-flex p-1 px-1 align-items-center">
            <AccountProfileImg size="mds" circular account={company} />
            <div className="d-flex ml-2 flex-column">
              <div className="text-black  text-truncate fs-14">{name}</div>
              <div
                style={{ marginTop: "-3px" }}
                className="text-grey-light  text-truncate fs-12"
              >
                {company.Name}
              </div>
            </div>
          </div>
        );

      case "SpaceDepartments":
        const department = getEntity(spaceDepartmentSchema, optionId);
        return (
          <>
            <div className="d-flex p-1 px-1 align-items-center">
              <AccountProfileImg size="mds" circular account={department} />
              <div className="d-flex ml-2 flex-column">
                <div className="text-black  text-truncate fs-14">{name}</div>
                <div
                  style={{ marginTop: "-3px" }}
                  className="text-grey-light  text-truncate fs-12"
                >
                  {department.Name}
                </div>
              </div>
            </div>
          </>
        );

      case "SpaceTeams":
        const team = getEntity(spaceTeamSchema, optionId);
        return (
          <div className="d-flex p-1 px-1 align-items-center">
            <AccountProfileImg size="mds" circular account={team} />
            <div className="d-flex ml-2 flex-column">
              <div className="text-black  text-truncate fs-14">{name}</div>
              <div
                style={{ marginTop: "-3px" }}
                className="text-grey-light  text-truncate fs-12"
              >
                {team.Name}
              </div>
            </div>
          </div>
        );

      case "Accounts":
        return (
          <div className="d-flex p-1 px-1 align-items-center">
            <div
              className={
                "img mds circular mr-2 text-color-link text-truncate account"
              }
            >
              <FontAwesomeIcon icon={faSignature} />
            </div>
            <div className="d-flex flex-column">
              <div className="text-black  text-truncate fs-14">{name}</div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }, [name, optionId, optionSchema]);

  return <>{content}</>;
};

export const EmailSignatureContext = React.createContext();

const SignaturePreviewApplication = ({ form, handleAppliedToUserChange }) => {
  const applyToInputRef = useRef();
  const applyToPopupRef = useRef();

  const [applyToIsOpen, toggleApplyTo] = usePopupOpenState(
    applyToPopupRef,
    applyToInputRef
  );

  // const userInputRef = useRef();
  // const userPopupRef = useRef();

  // const [userIsOpen, toggleUserTo] = usePopupOpenState(
  //   userPopupRef,
  //   userInputRef
  // );

  const {
    Company,
    Departments,
    Teams,
    Users,
    Name,
    ApplyToOption,
    AppliedToUser,
    ExcludedUsers
  } = form;

  const allItemsCount = useMemo(() => {
    let allItemsCount = 0;
    allItemsCount += Company?.length || 0;
    allItemsCount += Departments?.length || 0;
    allItemsCount += Teams?.length || 0;
    allItemsCount += Users?.length || 0;
    return allItemsCount;
  }, [Company, Departments, Teams, Users]);

  const AppliedToUserProps = useMemo(() => {
    if (!ApplyToOption) return {};

    const { optionSchema, optionId } = ApplyToOption;
    switch (optionSchema && optionSchema.name) {
      case "SpaceOrganizations":
        return {
          Company: optionId,
          ExcludedUsers: ExcludedUsers
        };

      case "SpaceDepartments":
        return {
          Department: optionId,
          ExcludedUsers: ExcludedUsers
        };

      case "SpaceTeams":
        return {
          Team: optionId,
          ExcludedUsers: ExcludedUsers
        };
      case "Accounts":
        return {
          OnlyTheseUsers: Users
        };
      default:
        return undefined;
    }
  }, [ApplyToOption, ExcludedUsers, Users]);

  return (
    <>
      <Popup
        domRef={applyToPopupRef}
        anchorEl={applyToInputRef.current}
        isOpen={applyToIsOpen}
      >
        <ApplyToPopupContent
          onSuccess={() => {
            toggleApplyTo(!applyToIsOpen);
          }}
          companies={Company}
          departments={Departments}
          teams={Teams}
          users={Users}
          name={Name}
          value={ApplyToOption}
        />
      </Popup>
      <div className="d-flex align-items-center flex-1 mx-3">
        <div className="text-grey-light d-flex h-100 align-items-center fs-12 mr-2">
          <FormattedMessage id={"APPLY_TO"} />:
        </div>
        <div className="mr-2 flex-1">
          <div
            ref={applyToInputRef}
            onClick={() => {
              if (allItemsCount > 0) toggleApplyTo(!applyToIsOpen);
            }}
            className="ssi-input bg-white cursor-pointer ssi-control w-100"
          >
            <div
              className={classnames(
                "d-flex justify-content-between align-items-center h-100",
                { "ar-disabled": allItemsCount === 0 }
              )}
            >
              <div>
                <ApplyToOptionComponent option={ApplyToOption} name={Name} />
              </div>
              <div className="text-color-link">
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </div>
          </div>
        </div>
        <div className="text-grey-light d-flex h-100 align-items-center fs-12 mr-2">
          <FormattedMessage id={"COLLABORATOR"} />:
        </div>
        <EmailSignatureContext.Provider value={ExcludedUsers}>
          <FormInput
            disabled={!ApplyToOption}
            onChange={handleAppliedToUserChange}
            value={AppliedToUser}
            name="AppliedToUser"
            componentClassName="bg-white"
            className="mr-2 flex-1"
            inputType={UserDropdown}
            {...AppliedToUserProps}
          />
        </EmailSignatureContext.Provider>
        {/* <div className="mr-2 flex-1">
          <div
            ref={userInputRef}
            onClick={() => {
              toggleUserTo(!userIsOpen);
            }}
            className="ssi-input cursor-pointer ssi-control w-100"
          >
            <div className="d-flex justify-content-between align-items-center h-100">
              <div></div>
              <div className="text-color-link">
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

const PreviewComponent = ({ signature, form }) => {
  // const [debouncedForm, setDebouncedForm] = useState(form);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setDebouncedForm(form);
  //   }, 300);
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [form]);

  const iframeRef = useRef();
  const adjustHeight = () => {
    const iframeDOMNode = iframeRef.current;
    if (iframeDOMNode) {
      const { contentWindow } = iframeDOMNode || {};
      const { document } = contentWindow || {};
      const { body } = document || {};
      const { offsetHeight } = body || 400;
      const newHeight = parseInt(offsetHeight) + 50;

      iframeDOMNode.height = newHeight <= 450 ? "450" : newHeight;
    }
  };

  const TagSwappedSignature = useHandleTagReplaces(form);
  const resolvedSignature = useMemo(() => {
    return `<div id="signature-container" style='max-width:600px; overflow: hidden;padding: 0.5rem; word-break: break-all; margin: auto;font-family: sans-serif, Arial, Verdana, "Trebuchet MS", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";'>${TagSwappedSignature}</div>`;
  }, [TagSwappedSignature]);

  return (
    <>
      <iframe
        scrolling="no"
        ref={iframeRef}
        height={650}
        width={650}
        onLoad={adjustHeight}
        title="preview"
        className="breakWord bg-white border of-hidden shadow"
        srcDoc={resolvedSignature}
      />
    </>
  );
};

export default EmailSignatureForm;
