import React, { useState } from "react";
import { useContext } from "react";
import {
  Communicator,
  createCommunicatorHook
} from "../../../Helpers/Communicator";

type updater = (nodeId: string, update: any) => void;
export const NodeGetItemContext = React.createContext<any>(undefined);
export const NodeUpdaterContext = React.createContext<any>(undefined);
export const NodeStatusUpdaterContext = React.createContext<any>(undefined);
export const NodeCloneFunctionContext = React.createContext<any>(undefined);
export const NodeTriggerTargetContext = React.createContext<any>(undefined);
export const NodeShowDetailsContext = React.createContext<any>(undefined)
export const useNodeUpdater = () => useContext<updater>(NodeUpdaterContext);

export const AutomationTestClickContext = React.createContext<any>(undefined);
export const AutomationTestRequestContext = React.createContext<any>(undefined);
export const AutomationTestLoadingRequestContext = React.createContext<any>(undefined);

export const useTestEnable = () => useContext<any>(AutomationTestClickContext);

export const AutomationTestRequestCommunicator = new Communicator();

export const useAutomationTestRequestCommunicator = createCommunicatorHook(
  AutomationTestRequestCommunicator
);

export const useAutomationTestRequestSidebar = (baseRequest: any) => {
  const [request, setRequest] = useState(baseRequest);

  useAutomationTestRequestCommunicator((request: any) => {
    setRequest(request);
  });

	return request
};
