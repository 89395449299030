import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
	ServerAwareUpdateTempViewsContext,
  ServerAwareViewsContext
} from "../CGrid/ServerAwareViews";
import querystring from "query-string";
import { useLocation } from "react-router-dom";
import { useSpace } from "../../Contexts/SpaceContext";

export const PipelineStatusChooserListnerDict = {};

const calculateColumns = (columns, orderList, resetLayout) => {

  const fieldDict = getfieldTitleDict(orderList);
  const result = [];
  for (const c of columns) {
    if (fieldDict[c.Id]) result.push(c);
  }
	
	if(result.length === 0) return columns
  return result;
};

const getfieldTitleDict = (orderList) => {
  const result = {};

  for (const c of orderList) {
    result[c] = c;
  }

  return result;
};

const EmptyArray = [];

export const useResolvedPipelineStatus = (columns, id, pipeline) => {
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const lsViews = useContext(ServerAwareViewsContext);

	const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);
  const space = useSpace();
  const index = lsViews?.findIndex((e) => e.id === viewPref);
  let preOrderList =
	lsViews && index !== -1
      ? lsViews[index].pipelineStatus || EmptyArray
      : lsViews ? (lsViews[0] && lsViews[0].pipelineStatus) : EmptyArray;
  if (!Array.isArray(preOrderList)) preOrderList = EmptyArray;

  const [downsizedData, setDownsizedData] = useState(preOrderList);

  useEffect(() => {
    var is_same =
      downsizedData?.length === preOrderList?.length &&
      downsizedData.every(function (element, index) {
        return element === preOrderList[index];
      });
    if (!is_same) setDownsizedData(preOrderList);
  }, [downsizedData, preOrderList]);

  const resolvedColumn = useMemo(() => {
    return downsizedData && downsizedData.length > 0
      ? calculateColumns(columns, downsizedData).map((e, i, z) => {
          return e.Id;
        })
      : columns.map((e) => e.Id);
  }, [columns, downsizedData]);

  const updateLocalStorage = useCallback(
    (columns) => {
      let gridSavedViews = JSON.parse(
        window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
      );
      const viewGridViews = gridSavedViews[id];
      const index = viewGridViews.findIndex((e) => e.id === viewPref);
      let resolvedIndex = index === -1 ? 0 : index;
      let viewGridObject = viewGridViews[resolvedIndex] || {};
      let viewGridObjectPipelined = viewGridObject.pipelineStatus || {};
      viewGridObjectPipelined = columns;
      viewGridObject.pipelineStatus = viewGridObjectPipelined;
      viewGridViews[resolvedIndex] = viewGridObject;
      gridSavedViews[id] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
			updateLsViews && updateLsViews();
    },
    [id, space.Id, updateLsViews, viewPref]
  );

  useEffect(() => {
    if (!PipelineStatusChooserListnerDict[id])
      PipelineStatusChooserListnerDict[id] = [];

    const listner = (orderList) => {
      if (!orderList) updateLocalStorage(columns);
      else updateLocalStorage(calculateColumns(columns, orderList));
    };

    PipelineStatusChooserListnerDict[id].push(listner);

    return () => {
      const index = PipelineStatusChooserListnerDict[id].indexOf(listner);
      PipelineStatusChooserListnerDict[id].splice(index, 1);
    };
  }, [columns, id, updateLocalStorage]);

  return resolvedColumn;
};

// export const olduseResolvedPipelineStatus = (columns, id, pipeline) => {
//   const [resolvedColumn, setResolvedColumn] = useState(() => {
//     //ir a localstorage

//     let gridColumnLayout = JSON.parse(
//       window.localStorage.getItem("ar-grid-pipelineStatus-layout")
//     );

//     const preOrderList = gridColumnLayout ? gridColumnLayout[id] : undefined;

//     const orderList = preOrderList ? preOrderList[pipeline] : undefined;

//     if (orderList && orderList.length > 0)
//       return calculateColumns(columns, orderList).map((e) => {
//         return e.Id;
//       });
//     else return columns.map((e) => e.Id);
//   });
//   useEffect(() => {
//     if (!PipelineStatusChooserListnerDict[id])
//       PipelineStatusChooserListnerDict[id] = [];

//     const listner = (orderList) => {
//       if (!orderList) {
//         setResolvedColumn(
//           columns.map((e) => {
//             return e.Id;
//           })
//         );
//       } else {
//         setResolvedColumn(
//           calculateColumns(columns, orderList).map((e) => {
//             return e.Id;
//           })
//         );
//       }
//     };

//     PipelineStatusChooserListnerDict[id].push(listner);

//     return () => {
//       const index = PipelineStatusChooserListnerDict[id].indexOf(listner);
//       PipelineStatusChooserListnerDict[id].splice(index, 1);
//     };
//   }, [columns, id]);

//   return resolvedColumn;
// };
