import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  useEffect
} from "react";
import {
  PageContainer,
  PageContent
} from "../../Components/PageUtilities/PageUtilities";
import "./Reports.css";
import moment from "moment";
import { MilSecToHour, MilSecToTime, getDates } from "../../Helpers/MiscHelper";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { spaceDepartmentSchema, accountSchema } from "../../config/schema";
import { fk } from "../../Helpers/SchemaHelper";
import { useDepartment } from "../../Hooks/EntityHooks";
import { ConnectedAccountProfile } from "../../Components/Accounts/AccountProfile";
import classnames from "classnames";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { withRouter } from "react-router-dom";
import querystring from "query-string";
import ArDatePicker from "../../Components/DatePicker/ArDatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons";
import { useAirdeskTitle, createKinectHook } from "../../Hooks/MiscHooks";
import LoadableButton from "../../Components/Button/LoadableButton";
import {
  useCurrentAccount,
  useCurrentAccountSpace
} from "../../Contexts/UserContext";
import { isAdmin } from "../../Helpers/ModulesHelper";
import { useIntl, FormattedMessage } from "react-intl";
import { useToast } from "../../Components/Toast/ToastProvider";
import { useConnectionId } from "../RealTime/RealTime";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";

const TimeOptionsDict = {
  0: <FormattedMessage id="TODAY" />,
  2: <FormattedMessage id="YESTERDAY" />,
  3: <FormattedMessage id="FILTER_LAST_X_DAYS" values={{ days: 7 }} />,
  4: <FormattedMessage id="FILTER_LAST_X_DAYS" values={{ days: 15 }} />,
  5: <FormattedMessage id="FILTER_LAST_X_DAYS" values={{ days: 30 }} />,
  6: <FormattedMessage id="FILTER_LAST_X_DAYS" values={{ days: 180 }} />,
  7: <FormattedMessage id="FILTER_LAST_X_DAYS" values={{ days: 365 }} />,
  8: <FormattedMessage id="CUSTOM" />,
  9: <FormattedMessage id="FILTER_LAST_X_DAYS" values={{ days: 45 }} />,
  10: <FormattedMessage id="FILTER_LAST_X_DAYS" values={{ days: 60 }} />,
  11: <FormattedMessage id="THIS_MONTH" />
};

const TimeOptions = [11, 3, 4, 5, 9, 10, 6, 7, 8];

const ReportsHeader = ({ columns, domRef }) => {
  return (
    <div className="report-header d-flex align-items-center">
      <div ref={domRef} className="report-header-container">
        {columns.map((column, colI) => {
          return (
            <div
              key={colI}
              className={classnames("header-item text-center", {
                "total-field": column.isTotal
              })}
            >
              {column.title}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const DepartmentName = ({ department }) => {
  const resolvedDepartment = useDepartment(department);
  return <span className="text-truncate">{resolvedDepartment.Name}</span>;
};

// const months = {
//   1: "Janeiro",
//   2: "Fevereiro",
//   3: "Março",
//   4: "Abril",
//   5: "Maio",
//   6: "Junho",
//   7: "Julho",
//   8: "Agosto",
//   9: "Setembro",
//   10: "Outubro",
//   11: "Novembro",
//   12: "Dezembro"
// };

const TimeIntervalValue = ({ item }) => {
  return (
    <div style={{ textTransform: "capitalize" }}>{TimeOptionsDict[item]}</div>
  );
};

const getIntervalsFromOption = (
  option,
  handleToDate,
  handleFromDate,
  handleBothDateChanges
) => {
  let toDate, fromDate;

  switch (option) {
    case 0:
      toDate = moment();
      fromDate = moment().subtract(1, "day");
      handleToDate(toDate.toDate());
      handleFromDate(fromDate.toDate());
      break;
    case 1:
      toDate = moment().add(1, "day");
      fromDate = moment().add(1, "day");
      handleToDate(toDate.toDate());
      handleFromDate(fromDate.toDate());
      break;
    case 2:
      toDate = moment().subtract(1, "day");
      fromDate = moment().subtract(1, "day");
      handleToDate(toDate.toDate());
      handleFromDate(fromDate.toDate());
      break;
    case 3:
      toDate = moment();
      fromDate = moment().subtract(6, "days");
      handleBothDateChanges(fromDate.toDate(), toDate.toDate());
      // handleToDate(toDate);
      // handleFromDate(fromDate.toDate());
      break;
    case 4:
      toDate = moment();
      fromDate = moment().subtract(14, "days");
      handleBothDateChanges(fromDate.toDate(), toDate.toDate());
      // handleToDate(toDate.toDate());
      // handleFromDate(fromDate.toDate());
      break;
    case 5:
      toDate = moment();
      fromDate = moment().subtract(29, "days");
      // handleToDate(toDate.toDate());
      // handleFromDate(fromDate.toDate());
      handleBothDateChanges(fromDate.toDate(), toDate.toDate());
      break;
    case 6:
      toDate = moment();
      fromDate = moment().subtract(179, "days");
      // handleToDate(toDate.toDate());
      // handleFromDate(fromDate.toDate());
      handleBothDateChanges(fromDate.toDate(), toDate.toDate());
      break;
    case 7:
      toDate = moment();
      fromDate = moment().subtract(364, "days");
      // handleToDate(toDate.toDate());
      // handleFromDate(fromDate.toDate());
      handleBothDateChanges(fromDate.toDate(), toDate.toDate());
      break;
    case 9:
      toDate = moment();
      fromDate = moment().subtract(44, "days");
      // handleToDate(toDate.toDate());
      // handleFromDate(fromDate.toDate());
      handleBothDateChanges(fromDate.toDate(), toDate.toDate());
      break;
    case 10:
      toDate = moment();
      fromDate = moment().subtract(59, "days");
      // handleToDate(toDate.toDate());
      // handleFromDate(fromDate.toDate());
      handleBothDateChanges(fromDate.toDate(), toDate.toDate());
      break;
    case 11:
      toDate = moment().endOf("month");
      fromDate = moment().startOf("month");
      handleBothDateChanges(fromDate.toDate(), toDate.toDate());
      // handleToDate();
      // handleFromDate();
      break;
    // case 12:
    // 	toDate = moment();
    //   fromDate = moment().startOf("month");
    //   handleBothDateChanges(fromDate.toDate(), toDate.toDate());
    // 	break
    default:
      break;
  }
};

export const ReportDatePicker = ({
  allowFutureDates,
  showLabels,
  state,
  className,
  defaultVal,
  size
}) => {
  const [{ toDate, fromDate }, setReportState] = state;

  const [timeOption, setTimeOption] = useState(defaultVal || 11);

  const onTimeOptionChange = (val) => {
    setTimeOption(val);
  };

  const handleToDateChange = useCallback(
    (v) => {
      setReportState((s) => {
        return {
          ...s,
          toDate: v,
          toDateString: moment(v).format("YYYY-MM-DD")
        };
      });
    },
    [setReportState]
  );

  const handleFromDateChange = useCallback(
    (v) => {
      setReportState((s) => {
        const { toDate } = s;
        if (toDate < v) {
          const newToDate = moment(v).add(1, "day");
          return {
            ...s,
            fromDate: v,
            fromDateString: moment(v).format("YYYY-MM-DD"),
            toDate: newToDate.toDate(),
            toDateString: newToDate.format("YYYY-MM-DD")
          };
        }

        return {
          ...s,
          fromDate: v,
          fromDateString: moment(v).format("YYYY-MM-DD")
        };
      });
    },
    [setReportState]
  );
  const handleBothDateChanges = useCallback(
    (f, t) => {
      setReportState((s) => {
        return {
          ...s,
          toDate: t,
          toDateString: moment(t).format("YYYY-MM-DD"),
          fromDate: f,
          fromDateString: moment(f).format("YYYY-MM-DD")
        };
      });
    },
    [setReportState]
  );

  useEffect(() => {
    if (timeOption !== 8) {
      getIntervalsFromOption(
        timeOption,
        handleToDateChange,
        handleFromDateChange,
        handleBothDateChanges
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOption]);

  // if (type === reportDateTypes.last7Days) return <div>last 7 days</div>;

  return (
    <>
      {showLabels && (
        <div
          style={{ width: 320 }}
          className="d-flex mb-1 justify-content-between"
        >
          <span className="fs-14 text-black">
            <FormattedMessage id="START" />
          </span>
          <span style={{ width: 145 }} className="fs-14 text-black">
            <FormattedMessage id="END" />
          </span>
        </div>
      )}
      <div
        // style={{ width: 540 }}
        className={classnames("d-flex align-items-center", className)}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <KeyedDropdown
          className="mr-2 w-170px"
          options={TimeOptions}
          onChange={onTimeOptionChange}
          value={timeOption}
          closeOnSelect
          valueComponent={TimeIntervalValue}
        />
        {timeOption === 8 && (
          <>
            <ArDatePicker
              // size="sm"
              className="mr-2 w-170px"
              value={fromDate}
              enableHours={false}
              onChange={handleFromDateChange}
            />
            <FontAwesomeIcon
              className="text-primary mr-2"
              icon={faLongArrowRight}
            />
            <ArDatePicker
              // size="sm"
              disablePastDate={fromDate}
              disableDates={true}
              className="w-170px"
              value={toDate}
              enableHours={false}
              onChange={handleToDateChange}
            />
          </>
        )}
      </div>
    </>
  );
};

const ReportsSidebar = ({
  month,
  year,
  data,
  domRef,
  reportState,
  ...rest
}) => {
  return (
    <div className="fixed-bar">
      <div className="month-container d-flex align-items-center justify-content-center">
        <span className="text-black fw-bold fs-24">
          {/* {months[month]} - {year} */}
          <FormattedMessage id="USERS" />
          {/* Utilizadores */}
          {/* <ReportDatePicker state={reportState} /> */}
        </span>
      </div>
      <div ref={domRef} className="content" {...rest}>
        {data.map((item, colI) => {
          if (typeof item === "number")
            return (
              <div
                key={colI}
                className="department-line text-black px-3 text-truncate"
              >
                <DepartmentName department={item} />
                {/* <span className="text-truncate">{item}</span> */}
              </div>
            );

          return (
            <div key={colI} className="line px-3">
              <ConnectedAccountProfile
                inline
                account={item.Account}
                className="text-black"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ReportText = ({ item, showEmpty }) => {
  const { Duration, IsWeekend } = item;
  const durationHours = MilSecToHour(Duration);
  const resolvedDuration = MilSecToTime(Duration);
  let text;
  let style;
  const PositiveDuration = 7;
  if (IsWeekend) {
    if (durationHours === 0) {
      style = {
        background: `#E6EDF4`,
        color: "#7C98B6"
      };
      text = "-";
    }
  } else if (IsWeekend) text = resolvedDuration;
  else if (durationHours >= PositiveDuration) {
    style = {
      background: `#E6FAF4`,
      color: "#0E6400"
    };
  } else {
    const opac = 1 - (durationHours * 1) / PositiveDuration;
    style = {
      background: `rgba(255, 89, 98, ${opac})`,
      color: "#831F12"
    };
    text = durationHours === 0 ? "-" : resolvedDuration;
  }

  return (
    <div
      className="h-100 d-flex align-items-center justify-content-center"
      style={style}
    >
      <span>{text || resolvedDuration}</span>
    </div>
  );
};

const useKinect = createKinectHook();

const ReportsContent = ({ columns, data, domRef, ...rest }) => {
  useKinect(domRef);
  return (
    <div ref={domRef} className="body h-100 flex-1" {...rest}>
      <div>
        {data.map((item, itemI) => {
          if (typeof item === "number")
            return (
              <div key={itemI} className="department-line">
                {columns.map((column, colI) => {
                  return <div key={colI} className="table-item" />;
                })}
              </div>
            );
          return (
            <div key={itemI} className="line">
              {columns.map((column, colI) => {
                return (
                  <div
                    key={colI}
                    className={classnames("table-item text-center", {
                      "total-field": column.isTotal
                    })}
                  >
                    {column.Cell(item)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      {/* {columns.map((column, colI) => {
        return <div key={colI} className="table-item">{column.Cell()}</div>;
      })} */}
    </div>
  );
};

export const ReportsOverview = ({
  month,
  reportState,
  year,
  columns,
  items,
  areDatesValid
}) => {
  const tableRef = useRef();
  const sidebarRef = useRef();
  const headerRef = useRef();
  const isScrollingTableRef = useRef(false);
  const isScrollingSidebarRef = useRef(false);

  const handleTableScroll = (e) => {
    if (isScrollingSidebarRef.current) {
      isScrollingSidebarRef.current = false;
      return;
    }
    isScrollingTableRef.current = true;
    sidebarRef.current.scrollTop = e.currentTarget.scrollTop;
    headerRef.current.scrollLeft = e.currentTarget.scrollLeft;
  };

  const handleSidebarScroll = (e) => {
    if (isScrollingTableRef.current) {
      isScrollingTableRef.current = false;
      return;
    }
    isScrollingSidebarRef.current = true;
    tableRef.current.scrollTop = e.currentTarget.scrollTop;
  };

  if (!areDatesValid)
    return <div>Hora inicial não pode ser maior que hora final</div>;

  return (
    <div className="ar-reports-container shadow-lg w-100 flex-1">
      <ReportsSidebar
        reportState={reportState}
        month={month}
        year={year}
        domRef={sidebarRef}
        data={items}
        onScroll={handleSidebarScroll}
      />

      <div className="report-table flex-1 d-flex flex-column">
        <ReportsHeader domRef={headerRef} columns={columns} />
        <ReportsContent
          domRef={tableRef}
          onScroll={handleTableScroll}
          columns={columns}
          data={items}
        />
      </div>
    </div>
  );
};

const ReportsResponseSchema = {
  Data: [
    {
      Department: fk(spaceDepartmentSchema),
      Reports: [
        {
          Account: fk(accountSchema)
        }
      ]
    }
  ]
};

// const GET_REPORTS = new SchemaRequest(
//   ({ spaceId, month }) =>
//     `Spaces/${spaceId}/Interventions/ReportByDepartment?month=${month}`,
//   {
//     Data: [
//       {
//         Department: fk(spaceDepartmentSchema),
//         Reports: [
//           {
//             Account: fk(accountSchema)
//           }
//         ]
//       }
//     ]
//   }
// );

export const useReportQuery = ({
  month,
  year,
  fromDate,
  toDate,
  userId
} = {}) => {
  const query = useMemo(() => {
    if (fromDate && toDate) {
      if (new Date(fromDate) > new Date(toDate)) return;
    }

    const obj = {};
    if (month) obj.month = month;
    if (year) obj.year = year;
    if (fromDate) obj.fromdate = fromDate;
    if (toDate) obj.todate = toDate;
    if (userId) obj.userid = userId;

    return querystring.stringify(obj);
  }, [fromDate, month, toDate, userId, year]);

  return useSpaceQuery(
    query ? `Interventions/ReportByDepartment?${query}` : null,
    ReportsResponseSchema,
    {
      cache: false
    }
  );
};

export const useReportState = (location, history, inheritValue) => {
  const [state, setState] = useState(() => {
    const {
      month,
      year,
      fromDate: fromDateUrl,
      toDate: toDateUrl
    } = querystring.parse(location ? location.search : "");
    let toDate, fromDate;
    if (fromDateUrl && toDateUrl) {
      toDate = moment(toDateUrl).endOf("month");
      fromDate = moment(fromDateUrl).startOf("month");
    } else if (month && year) {
      toDate = moment(`01-${month}-${year}`).endOf("month");
      fromDate = moment(toDate).startOf("month");
    } else {
      // toDate = moment().endOf("month");
      fromDate = moment().startOf("month");
      toDate = moment();
    }

    if (location && location.newDate) {
      return {
        toDateString: toDate.format("YYYY-MM-DD"),
        fromDateString: moment(location.newDate).format("YYYY-MM-DD"),
        toDate: toDate.toDate(),
        fromDate: moment(location.newDate).toDate()
      };
    }

    if (inheritValue) {
      return {
        toDateString: inheritValue.toDate,
        fromDateString: inheritValue.fromDate,
        toDate: moment(inheritValue.toDate).toDate(),
        fromDate: moment(inheritValue.fromDate).toDate()
      };
    }

    return {
      toDateString: toDate.format("YYYY-MM-DD"),
      fromDateString: fromDate.format("YYYY-MM-DD"),
      toDate: toDate.toDate(),
      fromDate: fromDate.toDate()
    };
  });

  const setReportState = useCallback(
    (f) => {
      const newState = f(state);

      const obj = {
        fromDate: newState.fromDateString,
        toDate: newState.toDateString
      };

      const x = querystring.stringify(obj);
      if (history)
        history.push({
          search: x
        });

      setState(newState);
    },
    [history, state]
  );
  return [state, setReportState];
};

const Reports = withRouter((props) => {
  const intl = useIntl();
  useAirdeskTitle(intl.formatMessage({ id: "REPORTS" }));
  const reportState = useReportState(props.location, props.history);

  const [{ toDateString, fromDateString, toDate, fromDate }] = reportState;
  const { Id } = useCurrentAccount();
  const accountSpace = useCurrentAccountSpace();

  const { data, loading, error } = useReportQuery({
    toDate: toDateString,
    fromDate: fromDateString,
    userId: isAdmin(accountSpace) ? undefined : Id
  });

  const areDatesValid = useMemo(() => {
    return new Date(fromDate) < new Date(toDate);
  }, [fromDate, toDate]);

  const dates = useMemo(() => {
    const dateArr = getDates(moment(fromDateString), moment(toDateString));

    return dateArr;
  }, [fromDateString, toDateString]);

  const { columns, items } = useMemo(() => {
    if (!data) return {};

    const cols = [];
    const now = new Date();
    for (const date of dates) {
      const fDate = date.format("DD/MM/YY");
      const dateI = date.format("DD-MM-YYYY");
      var day = date.day();
      var isWeekend = day === 6 || day === 0;
      cols.push({
        title: (
          <span
            className={classnames("text-capitalize", {
              "text-black fw-medium": !isWeekend
            })}
          >
            {fDate}
          </span>
        ),
        Cell: (v) => {
          if (typeof v !== "object") return null;

          const date = v.dates ? v.dates[dateI] : null;

          if (!date) return null;

          if (new Date(date.Date) > now) return null;

          const duration = date.Duration;

          if (isNaN(duration)) return null;

          return <ReportText showEmpty item={date} />;
        }
      });
    }

    cols.push({
      title: <span>{intl.formatMessage({ id: "TOTAL" })}</span>,
      Cell: (v) => {
        return <span>{MilSecToTime(v.TotalHours || 0)}</span>;
      },
      isTotal: true
    });

    const items = [];
    for (const item of data.Data) {
      const { Reports, Department } = item;
      items.push(Department);
      for (const reportI of Reports) {
        const { Account, Report, ...rest } = reportI;

        const item = {
          Account,
          dates: {},
          ...rest
        };

        for (const userReport of Report) {
          item.dates[moment(userReport.Date).format("DD-MM-YYYY")] = userReport;
        }
        items.push(item);
      }
    }
    // console.log(items);
    return { columns: cols, items };
  }, [data, dates, intl]);

  const conId = useConnectionId();
  const createToast = useToast();
  const { refetch: fetchExcel, loading: loadingExcel } = useSpaceQuery(
    `Excel/Interventions/ReportByDepartment/ToFile?todate=${toDateString}&fromdate=${fromDateString}`,
    null,
    {
      headers: {
        ConnectionID: conId
      },
      autoFetch: false,
      cache: false,
      onSuccess: ({ data }) => {
        // var blob = new Blob([data], {
        //   type:
        //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        // });
        // const link = document.createElement("a");
        // // Browsers that support HTML5 download attribute

        // const url = URL.createObjectURL(blob);
        // link.setAttribute("href", url);
        // link.setAttribute("download", "relatórios.xlsx");
        // link.style.visibility = "hidden";
        // document.body.appendChild(link);
        // link.click();
        // if (document.body.contains(link)) document.body.removeChild(link);
        createToast({
          pos: "tm",
          type: "info",
          description: intl.formatMessage({
            id: "API_EXPORT_STARTED"
          })
        });
      }
    }
  );

  if (loading || error)
    return <LoaderSpinner className="text-secondary" size="sm" center />;

  return (
    <PageContainer>
      <PageContent className="bg-white">
        <div className="py-4 of-v d-flex flex-column w-100">
          <div className="d-flex align-items-center mb-3">
            <ReportDatePicker className="mr-3" state={reportState} />
            <LoadableButton isLoading={loadingExcel} onClick={fetchExcel}>
              {intl.formatMessage({ id: "EXPORT_TO_EXCEL" })}
            </LoadableButton>
          </div>
          <ReportsOverview
            areDatesValid={areDatesValid}
            reportState={reportState}
            // month={month}
            // year={year}
            items={items}
            columns={columns}
          />
        </div>
      </PageContent>
    </PageContainer>
  );
});

export default Reports;
