import React, {
  useRef,
  useMemo,
  useEffect,
  useLayoutEffect,
  useCallback,
  useContext,
  useState
} from "react";
import { useCurrentAccount } from "../../../Contexts/UserContext";
import { useMultipleEntityValueSelector } from "../../../Hooks/AdvancedEntityHooks";
import { spaceNoteSchema } from "../../../config/schema";
import LoaderSpinner from "../../Loader/LoaderSpinner/LoaderSpinner";
import LineHeader from "../../LineHeader/LineHeader";
import { Message } from "./Message/Message";
import {
  useMessageConnectionState,
  useMessengerTypingAccounts
} from "./MessagesConnection";
import {
  useSpaceReconnect,
  useSpaceEntitiesHub
} from "../../../Containers/RealTime/RealTimeSpace";
import Button from "../../Button/Button";
import { useReconnectionStatus } from "../../../Containers/RealTime/RealTime";
import TypingLoader from "../../TypingLoader/TypingLoader";
import classnames from "classnames";
import { MessageReadUsersPreview } from "./MessageReadUsersPreview/MessageReadUsersPreview";
import { FormattedMessage } from "react-intl";
import Popup, { usePopupOpenState } from "../../Popup/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import LoadableButton from "../../Button/LoadableButton";
import {
  ContainerProvider,
  AddedNewMessageManualyContext,
  SetAddedNewMessageManualyContext
} from "./MessagesHelper";
import { BarLoader } from "../../GlobalLoader/GlobalLoader";

export const MessagesExtraAction = ({ isLoading, onPin }) => {
  const popupRef = useRef();
  const anchorRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);

  return (
    <div>
      <Popup
        domRef={popupRef}
        placement="bottom-end"
        anchorEl={anchorRef.current}
        isOpen={Boolean(isOpen)}
      >
        <div className="py-2 ar-actions-popup bg-white">
          <LoadableButton isLoading={isLoading} vType="link" onClick={onPin}>
            <FormattedMessage id="PIN_MESSAGE" />
          </LoadableButton>
        </div>
      </Popup>
      <div
        ref={anchorRef}
        onClick={toggleIsOpen}
        className="d-flex justify-content-between p-2 align-items-center cursor-pointer"
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </div>
    </div>
  );
};

const ChatContent = ({
  // pinnedMessages,
  messages,
  replyMessageId,
  onChangeReplyMessageId,
  editMessageId,
  noteCreator,
  onChangeEditMessageId,
  loading,
  error,
  isEmail,
  loadMore,
  messageSchema,
  MessageComponent,
  selector
}) => {
  const { Id } = useCurrentAccount();

  const connectedMessages = useMultipleEntityValueSelector(
    messageSchema ? messageSchema : spaceNoteSchema,
    messages || [],
    selector
  );

  // console.log(connectedMessages);
  // const connectedPinnedMessages = useMultipleEntityValueSelector(
  //   Email ? spaceEmailSchema : spaceNoteSchema,
  //   pinnedMessages || []
  // );

  const containerRef = useRef();

  // useLayoutEffect(() => {
  //   containerRef.current.scrollTop = containerRef.current.scrollHeight;
  // }, []);

  const [isLoaded, setIsLoaded] = useState(false);

  const currentPage = useRef(1);
  const fromLoadMore = useRef(false);
  const handleScroll = useMemo(() => {
    if (!loadMore || loading || error || !isLoaded) return undefined;

    return () => {
      const { scrollTop } = containerRef.current;

      if (scrollTop < 30 && isLoaded) {
        fromLoadMore.current = true;
        loadMore();
      }
    };
  }, [error, isLoaded, loadMore, loading]);

  // useEffect(() => {
  //   ////
  //   if (!handleScroll) return;
  //   const el = containerRef.current;
  //   el.addEventListener("scroll", handleScroll);

  //   return () => {
  //     el.removeEventListener("scroll", handleScroll);
  //   };
  // }, [handleScroll]);

  // console.log(connectedMessages);

  const prevScrollHeightRef = useRef();
  const entitiesHub = useSpaceEntitiesHub();
  // useEffect(() => {
  //   if (handleScroll) handleScroll();
  // }, [handleScroll]);

  // const handleIframeLoad = useCallback(() => {
  // const el = containerRef.current;
  // if (!prevScrollHeightRef.current) {
  //   prevScrollHeightRef.current = el.scrollHeight;
  //   containerRef.current.scrollTop = containerRef.current.scrollHeight;
  //   return;
  // }
  // const scrollDiff = el.scrollHeight - prevScrollHeightRef.current;
  // prevScrollHeightRef.current = el.scrollHeight;
  // // if(el.scrollTop === scrollDiff) return
  // el.scrollTop = el.scrollTop + scrollDiff + 60;
  // }, []);
  const messageAddedRef = useRef(false);
  const timeoutRef = useRef();
  const handleIframeLoad = useCallback(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      if (messageAddedRef.current) {
        try {
          const el = containerRef.current;
          const lastMessage = messages[messages.length - 1];
          const topPos =
            document.getElementById(`#anchor-id-${lastMessage.Email}`)
              ?.offsetTop || 0;
          el.scrollTop = topPos - 75;
          messageAddedRef.current = false;
        } catch (error) {}
      } else if (loadMore || messages.length <= 5) {
        try {
          const el = containerRef.current;

          const topPos =
            document.getElementById(`#page${currentPage.current}`)?.offsetTop ||
            0;
          el.scrollTop = topPos - 75;
        } catch (error) {}
      } else if (!loadMore) {
        try {
          const el = containerRef.current;
          // timeoutRef.current = setTimeout(() => {
          const topPos = document.getElementById(`#start`)?.offsetTop || 0;
          el.scrollTop = topPos - 75;
        } catch (error) {}
      }
    }, 400);
    // }, 300);
  }, [loadMore, messages]);

  useLayoutEffect(() => {
    if (!isLoaded) {
      prevScrollHeightRef.current = 0;
      setIsLoaded(true);
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [isLoaded, messages]);

  const addedNewItems = useContext(AddedNewMessageManualyContext);
  const setNewAddedItems = useContext(SetAddedNewMessageManualyContext);

  const PreviousMessageLength = useRef(messages.length || 0);

  useLayoutEffect(() => {
    if (addedNewItems) {
      const timer = setTimeout(() => {
        const el = containerRef.current;
        prevScrollHeightRef.current = el.scrollHeight;
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
        setNewAddedItems(false);
      }, 50);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [addedNewItems, setNewAddedItems]);

  useEffect(() => {
    if (!isEmail) {
      const el = containerRef.current;
      if (
        !prevScrollHeightRef.current ||
        messageAddedRef.current ||
        (PreviousMessageLength.current < messages.length &&
          !fromLoadMore.current)
      ) {
        messageAddedRef.current = false;
        PreviousMessageLength.current = messages.length;
        prevScrollHeightRef.current = el.scrollHeight;
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      } else {
        try {
          PreviousMessageLength.current = messages.length;
          fromLoadMore.current = false;
          const scrollDiff = el.scrollHeight - prevScrollHeightRef.current;

          prevScrollHeightRef.current = el.scrollHeight;

          // if(el.scrollTop === scrollDiff) return
          if (!addedNewItems) el.scrollTop = scrollDiff;
        } catch (error) {}
      }
    }
  }, [isEmail, messages]);

  useEffect(() => {
    if (!entitiesHub || !isEmail) return;
    // const { convertNote } = Settings || {};

    const listner2 = (dataId, isNote) => {
      messageAddedRef.current = true;
      if (isEmail && isNote) {
        // if (!prevScrollHeightRef.current) {
        //   prevScrollHeightRef.current = el.scrollHeight;
        //   containerRef.current.scrollTop = containerRef.current.scrollHeight;
        // } else {
        setTimeout(() => {
          try {
            const el = containerRef.current;
            // const scrollDiff = el.scrollHeight - prevScrollHeightRef.current;
            prevScrollHeightRef.current = el.scrollHeight;

            // if(el.scrollTop === scrollDiff) return

            containerRef.current.scrollTop = containerRef.current.scrollHeight;
          } catch (error) {}
        }, 300);
        // }
      }
    };

    entitiesHub.on("NewConversationMessageV2", listner2);

    return () => {
      entitiesHub.off("NewConversationMessageV2", listner2);
    };
  }, [entitiesHub, isEmail, messages.length, setNewAddedItems]);

  // useEffect(() => {
  // // const timeout = setTimeout(() => {
  // const el = containerRef.current;
  // if (!prevScrollHeightRef.current) {
  //   prevScrollHeightRef.current = el.scrollHeight;
  //   containerRef.current.scrollTop = containerRef.current.scrollHeight;
  //   return;
  // }
  // const scrollDiff = el.scrollHeight - prevScrollHeightRef.current;
  // prevScrollHeightRef.current = el.scrollHeight;
  // // if(el.scrollTop === scrollDiff) return
  // el.scrollTop = el.scrollTop + scrollDiff + 60;
  // // }, 500);
  // // return () => {
  // //   clearTimeout(timeout);
  // };
  // }, [messages]);

  return (
    <ContainerProvider.Provider value={handleIframeLoad}>
      <BarLoader
        style={{ marginTop: 3 }}
        className="px-3"
        isLoading={loading}
      />
      <div
        ref={containerRef}
        onScroll={handleScroll}
        className="flex-1 of-y-auto pt-2 h-100 px-4"
      >
        {/* {loading && (
          <LoaderSpinner
            centerHorizontal
            size="xxs"
            className="mb-2 text-primary"
          />
        )} */}
        {!loading && !loadMore && (
          <LineHeader id={"#start"}>
            <FormattedMessage id="START" />
          </LineHeader>
        )}
        {connectedMessages.map((message, index, arr) => {
          const page = Math.round((index + 1) / 5) || 1;
          if (MessageComponent)
            return (
              <React.Fragment key={index}>
                {((index + 1) % 5 === 0 || index + 1 === arr.length) && (
                  <div id={`#page${page}`}></div>
                )}
                <div
                  id={`#anchor-id-${message.Email?.Id || message.Note?.Id}`}
                ></div>
                <MessageComponent
                  noteCreator={noteCreator}
                  onChangeReplyMessageId={onChangeReplyMessageId}
                  onChangeEditMessageId={onChangeEditMessageId}
                  currentAccountId={Id}
                  message={message}
                  prevMessage={connectedMessages[index - 1]}
                />
              </React.Fragment>
            );

          return (
            <React.Fragment key={index}>
              {/* {(index + 1) % 5 === 0 && (
                <div className="text-black" id={`#page${page}`}></div>
              )} */}
              {/* {((index + 1) % 5 === 0 || index + 1 === arr.length) && (
                <div id={`#page${page}`}></div>
              )}
              <div id={`#anchor-id-${message.Note?.Id}`}></div> */}
              <Message
                noteCreator={noteCreator}
                onChangeReplyMessageId={onChangeReplyMessageId}
                onChangeEditMessageId={onChangeEditMessageId}
                currentAccountId={Id}
                message={message}
                prevMessage={connectedMessages[index - 1]}
              />
            </React.Fragment>
          );
        })}

        {/* {connectedPinnedMessages && (
          <>
            <LineHeader className="my-3">
              <FormattedMessage id="PINNED" />
            </LineHeader>
            {connectedPinnedMessages.map((message, index) => {
              if (Email) {
                return (
                  <EmailChatMessagesItem key={message.Id} Data={message} />
                );
              } else {
                return (
                  <Message
                    onChangeReplyMessageId={onChangeReplyMessageId}
                    onChangeEditMessageId={onChangeEditMessageId}
                    key={message.Id}
                    currentAccountId={Id}
                    message={message}
                    prevMessage={connectedPinnedMessages[index - 1]}
                  />
                );
              }
            })}
          </>
        )} */}
      </div>
      <TypingPreview />
    </ContainerProvider.Provider>
  );
};
const NoMessagesContent = ({ noteCreator }) => {
  return (
    <div
      className={
        noteCreator
          ? "ar-entity-summary-no-notes"
          : "ar-entity-summary-no-messages"
      }
    >
      <FormattedMessage
        id={noteCreator ? "CHAT_NO_NOTES" : "CHAT_NO_MESSAGES"}
      />
    </div>
  );
};

export const TypingPreview = () => {
  const accounts = useMessengerTypingAccounts();

  const prevAccountsRef = useRef();

  useEffect(() => {
    if (accounts.length > 0) prevAccountsRef.current = accounts;
  }, [accounts]);

  const resolvedAccounts =
    accounts.length > 0
      ? accounts
      : prevAccountsRef.current
      ? prevAccountsRef.current
      : accounts;

  const hasAccounts = resolvedAccounts.length > 0;

  return (
    <div
      className={classnames("ar-entity-summary-message-typing w-100 px-4", {
        active: accounts.length > 0
      })}
    >
      <div className="d-flex align-items-center">
        {hasAccounts && (
          <>
            <MessageReadUsersPreview
              className="d-inline-flex mr-2"
              accounts={resolvedAccounts}
              limit={4}
            />
            <TypingLoader />
          </>
        )}
      </div>
    </div>
  );
};

const ReconnectWarning = () => {
  const isReconnecting = useReconnectionStatus();

  if (!isReconnecting) return null;

  return (
    <div className="bg-grey px-4 py-2 text-black">
      <FormattedMessage id="CHAT_RECONNECTING" />
    </div>
  );
};

export const Chat = (props) => {
  const { messages, noTyping, noteCreator } = props;
  const { isConnected, reconnect: reconnectRoom } = useMessageConnectionState();

  const reconnect = useSpaceReconnect();

  //console.log(`${reconnect} - ${reconnectRoom}`);

  // if (reconnect || reconnectRoom)
  //   return (
  //     <div className="d-flex align-items-center justify-content-center flex-1">
  //       <div className="text-center">
  //         <div className="mb-2 text-black">
  //           <FormattedMessage id="CHAT_CONNECTION_ERROR_DESCRIPTION" />
  //         </div>
  //         <Button onClick={reconnect || reconnectRoom}>
  //           <FormattedMessage id="RECONNECT" />
  //         </Button>
  //       </div>
  //     </div>
  //   );

  if (!messages)
    return <LoaderSpinner size="sm" className="text-primary" center />;

  if (messages.length === 0) {
    return (
      <>
        {/* <ReconnectWarning /> */}
        <div className="flex-1 px-4 d-flex align-items-center justify-content-center">
          <NoMessagesContent noteCreator={noteCreator} />
        </div>
        {!noTyping && <TypingPreview />}
      </>
    );
  }

  return (
    <>
      {/* <ReconnectWarning /> */}
      <ChatContent {...props} />
    </>
  );

  // return <div className="of-y-auto flex-1 py-3 px-4"></div>;
};
