import {
  Communicator,
  createCommunicatorHook,
} from "../../Helpers/Communicator";

export const socialPostCommunicator = new Communicator();

export const useTaskCommunicator = createCommunicatorHook(
  socialPostCommunicator
);
