import React, { useCallback } from "react";
import Input from "./Input";

const NumberInput = ({ onChange, value, ...rest }) => {
  const handleChange = useCallback(
    e => {
      const num = Number(e.target.value);
      if (!Number.isInteger(num)) return;

      onChange(num);
    },
    [onChange]
  );

  return <Input onChange={handleChange} value={value} {...rest} />;
};

export default NumberInput;
