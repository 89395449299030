import React from "react";
import { canUserEditModule } from "../Helpers/ModulesHelper";
import Button from "../Components/Button/Button";
import { injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPencil } from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import { useCurrentAccountSpace } from "../Contexts/UserContext";
import { useSidebar, useSidebarChanges } from "../Components/Sidebar/SidebarV2";

// const buildFormattedMessage = id => {
//   if (!id) return null;

//   return <FormattedMessage id={id} />;
// };

const createFormButton = ({
  Form,
  textId,
  popupTextId,
  editTextId,
  popupEditTextId,
  moduleType,
  altImage,
}) => {
  return injectIntl(
    ({
      onClick,
      children,
      className,
      id: entityId, // preview,
      canEdit = false,
      oldForm,
      newForm,
      formProps,
      push,
      intl,
      alt,
      size,
      space,
      isChild,
      getParentForm,
      link,
      Component,
      disabled,
      ...rest
    }) => {
      const userAccountSpace = useCurrentAccountSpace();
      const [openSidebar] = useSidebar();

      let resolvedCanEdit = false;
      let resolvedCanPreview = false;

      //verify if needs to preview or edit
      if (entityId) {
        resolvedCanPreview = true;

        if (canUserEditModule(userAccountSpace, moduleType) && canEdit)
          resolvedCanEdit = true;
      } else {
        if (!canUserEditModule(userAccountSpace, moduleType)) return null;
      }

      let resolvedText;

      if (children) {
        resolvedText = children;
      } else {
        if (resolvedCanEdit)
          // resolvedContent = <div><FontAwesomeIcon icon={faPencil} /></div>;
          resolvedText = intl.formatMessage({ id: editTextId || "EDIT" });
        else if (resolvedCanPreview)
          // resolvedContent = <div><FontAwesomeIcon icon={faEye} /></div>;
          resolvedText = intl.formatMessage({ id: "VIEW" });
        else resolvedText = intl.formatMessage({ id: textId });
      }

			// eslint-disable-next-line no-unused-vars
			const [toggleSidebarChanges, hasChanges] = useSidebarChanges();

      const handleClick = () => {
        const resolvedFormProps =
          typeof formProps === "function" ? formProps() : formProps;

        const props = entityId
          ? {
              id: entityId,
              preview: !resolvedCanEdit,
              ...resolvedFormProps,
            }
          : { ...resolvedFormProps };

        openSidebar(
          <Form
            isChild={isChild}
            oldForm={oldForm}
            getParentForm={getParentForm}
            form={newForm}
						toggleSidebarChanges={toggleSidebarChanges}
            {...props}
          />,
          // entityId
          //   ? buildFormattedMessage(popupEditTextId || editTextId)
          //   : buildFormattedMessage(popupTextId || textId),
          // push,
          {
            pushOnTop: push,
            oldProps: {
              form: oldForm,
              edit: true,
            },
          }
        );
      };

      if (alt) {
        return (
          <Button
            className={classnames("ssi-button-grid", className, size || "sm")}
            vType="primary-ghost"
            title={resolvedText}
            onClick={handleClick}
            disabled={disabled}
          >
            {altImage}
          </Button>
        );
      }

      if (resolvedCanPreview) {
        return (
          <Button
            className={classnames("ssi-button-grid", className, size || "sm")}
            vType="primary-ghost"
            title={resolvedText}
            onClick={handleClick}
            disabled={disabled}
          >
            {resolvedCanEdit ? (
              <FontAwesomeIcon icon={faPencil} />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </Button>
        );
      }

      if (link) {
        const button = (
          <Button
            className={classnames(className, size)}
            vType="link"
            title={resolvedText}
            onClick={handleClick}
            disabled={disabled}
          >
            <div className="mr-1 d-inline-block">{altImage}</div>
            <span className="fw-regular fs-14"> {resolvedText} </span>
          </Button>
        );

        return Component ? <Component Button={button} /> : button;
      }

      return (
        <Button
          className={classnames(className, size)}
          onClick={handleClick}
          disabled={disabled}
          {...rest}
        >
          <div className="mr-1 d-inline-block">{altImage}</div>
          <span className="fw-regular fs-14"> {resolvedText} </span>
        </Button>
      );
    }
  );
};

export default createFormButton;
