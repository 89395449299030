/* eslint-disable no-unused-vars */
import React from "react";

import { ReactComponent as DotGreen } from "../../Assets/Svg/StatusIcons/DotGreen.svg";
import { ReactComponent as DotGray } from "../../Assets/Svg/StatusIcons/DotGray.svg";
import { ReactComponent as DotRed } from "../../Assets/Svg/StatusIcons/DotRed.svg";
import { ReactComponent as DoubleDotBlack } from "../../Assets/Svg/StatusIcons/DoubleDotBlack.svg";
import { ReactComponent as DoubleDotBlue } from "../../Assets/Svg/StatusIcons/DoubleDotBlue.svg";
import { ReactComponent as DoubleDotClear } from "../../Assets/Svg/StatusIcons/DoubleDotClear.svg";
import { ReactComponent as DoubleDotGray } from "../../Assets/Svg/StatusIcons/DoubleDotGrey.svg";
import { ReactComponent as DoubleDotDarkGray } from "../../Assets/Svg/StatusIcons/DoubleDotDarkGrey.svg";
import { ReactComponent as DoubleDotPurple } from "../../Assets/Svg/StatusIcons/DoubleDotPurple.svg";
import { ReactComponent as DoubleDotYellow } from "../../Assets/Svg/StatusIcons/DoubleDotYellow.svg";
import { ReactComponent as DoubleThreeQuartsDotOrange } from "../../Assets/Svg/StatusIcons/DoubleThreeQuartsDotOrange.svg";
import { ReactComponent as DoubleHalfDotPurple } from "../../Assets/Svg/StatusIcons/DoubleHalfDotPurple.svg";
import { ReactComponent as DoubleThreeQuartsDotGreen } from "../../Assets/Svg/StatusIcons/DoubleThreeQuartsDotGreen.svg";
import { ReactComponent as UnfilledDotRed } from "../../Assets/Svg/StatusIcons/UnfilledDotRed.svg";
import { ReactComponent as DoubleDotRed } from "../../Assets/Svg/StatusIcons/DoubleDotRed.svg";
import { ReactComponent as DoubleHalfDotGreen } from "../../Assets/Svg/StatusIcons/DoubleHalfDotGreen.svg";
import { ReactComponent as DoubleDotGreen } from "../../Assets/Svg/StatusIcons/DoubleDotGreen.svg";
import { ReactComponent as DoubleThreeQuartsDotPurple } from "../../Assets/Svg/StatusIcons/DoubleThreeQuartsDotPurple.svg";
import { ReactComponent as DoubleHalfDotYellow } from "../../Assets/Svg/StatusIcons/DoubleHalfDotYellow.svg";
import { ReactComponent as DoubleHalfDotBlue } from "../../Assets/Svg/StatusIcons/DoubleHalfDotBlue.svg";
import { ReactComponent as DoubleOneQuarterDotOrange } from "../../Assets/Svg/StatusIcons/DoubleOneQuarterDotOrange.svg";
import { ReactComponent as DoubleHalfDotGray } from "../../Assets/Svg/StatusIcons/DoubleHalfDotGray.svg";

//Deals
export const DealsWonStatusDot = ({ ...props }) => (
  <DoubleDotGreen {...props} />
);

export const DealsDraftStatusDot = ({ ...props }) => (
  <DoubleThreeQuartsDotOrange {...props} />
);
export const DealsLostStatusDot = ({ ...props }) => <DoubleDotRed {...props} />;
export const DealsNewStatusDot = ({ ...props }) => (
  <DoubleThreeQuartsDotPurple {...props} />
);
export const DealsCanceledStatusDot = ({ ...props }) => (
  <DoubleDotGray {...props} />
);

export const ContractsClosedStatusDot = ({ ...props }) => (
  <DoubleDotDarkGray {...props} />
);


//Emails
export const EmailOpenStatusDot = ({ ...props }) => (
  <DoubleHalfDotGreen {...props} />
);
export const EmailClosedStatusDot = ({ ...props }) => (
  <DoubleDotBlack {...props} />
);
export const EmailDisabledClosedStatusDot = ({ ...props }) => (
  <DoubleDotGray {...props} />
);
export const EmailDisabledOpenStatusDot = ({ ...props }) => (
  <DoubleHalfDotGray {...props} />
);

//Companies
export const CompanyInAprovalStatusDot = ({ ...props }) => (
  <DoubleThreeQuartsDotOrange {...props} />
);
export const CompanyActiveStatusDot = ({ ...props }) => (
  <DoubleDotPurple {...props} />
);
export const CompanyInactiveStatusDot = ({ ...props }) => (
  <DoubleDotDarkGray {...props} />
);
export const CompanyBlockedStatusDot = ({ ...props }) => (
  <DoubleDotRed {...props} />
);

//Normal Pipelines
export const OpenStatusDot = ({ ...props }) => (
  <DoubleThreeQuartsDotPurple {...props} />
);
export const DraftStatusDot = ({ ...props }) => <DoubleThreeQuartsDotOrange {...props} />;
export const ClosedStatusDot = ({ ...props }) => <DoubleDotGreen {...props} />;
export const CanceledStatusDot = ({ ...props }) => <DoubleDotGray {...props} />;

//Classifications
export const ClassificationBilledStatusDot = ({ ...props }) => (
  <DoubleDotGreen {...props} />
);
export const ClassificationNotClassifiedStatusDot = ({ ...props }) => (
  <DoubleThreeQuartsDotOrange {...props} />
);
export const ClassificationNotBilledStatusDot = ({ ...props }) => (
  <DoubleThreeQuartsDotPurple {...props} />
);
export const ClassificationAssignedToCompanyStatusDot = ({ ...props }) => (
  <DoubleDotBlue {...props} />
);
export const ClassificationAssignedToContractStatusDot = ({ ...props }) => (
  <DoubleDotBlack {...props} />
);
export const ClassificationAssignedToWarrantyStatusDot = ({ ...props }) => (
  <DoubleDotYellow {...props} />
);
export const ClassificationAssignedToProjectStatusDot = ({ ...props }) => (
  <DoubleDotPurple {...props} />
);
export const ClassificationAssignedToDealStatusDot = ({ ...props }) => (
  <DoubleDotClear {...props} />
);
export const ClassificationInefficientStatusDot = ({ ...props }) => (
  <DoubleDotDarkGray {...props} />
);

//Social Posts

export const SocialPostsPublishedStatusDot = ({ ...props }) => (
  <DoubleDotGreen {...props} />
);
export const SocialPostsScheduledStatusDot = ({ ...props }) => (
  <DoubleThreeQuartsDotPurple {...props} />
);
export const SocialPostsPublishingStatusDot = ({ ...props }) => (
  <DoubleThreeQuartsDotPurple {...props} />
);
export const SocialPostsDraftStatusDot = ({ ...props }) => (
  <DoubleHalfDotYellow {...props} />
);
export const SocialPostsDeletedStatusDot = ({ ...props }) => (
  <DoubleDotRed {...props} />
);
export const SocialPostsFailedStatusDot = ({ ...props }) => (
  <DoubleDotRed {...props} />
);
export const SocialPostsCanceledStatusDot = ({ ...props }) => (
  <DoubleDotGray {...props} />
);

//Users and Invitations

export const UserActiveStatusDot = ({ ...props }) => (
  <DoubleDotGreen {...props} />
);
export const UserBlockedStatusDot = ({ ...props }) => (
  <UnfilledDotRed {...props} />
);
export const UserInvitationExpiredStatusDot = ({ ...props }) => (
  <DoubleDotRed {...props} />
);
export const UserInvitationDraftStatusDot = ({ ...props }) => (
  <DoubleHalfDotYellow {...props} />
);
export const UserInvitationPendingStatusDot = ({ ...props }) => (
  <DoubleThreeQuartsDotPurple {...props} />
);

export const StatusOrangeLabel = "REVIEW";
export const StatusPurpleLabel = "ACTIVE";
export const StatusGreenLabel = "SUCCESS";
export const StatusRedLabel = "FAILURE";
export const StatusGreyLabel = "CANCELLED";


export const StatusDealWonLabel = "WON"
export const StatusDealLostLabel = "LOST"
