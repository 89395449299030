import React, { useCallback, useContext } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import { moduleTypes, canUserEditModule } from "../../Helpers/ModulesHelper";
import Button from "../../Components/Button/Button";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faPhone,
  faTicketSimple,
  faSquareParking,
  faListCheck,
  faUserClock,
  faSuitcase,
  faUserTie,
  faFileContract,
  faClone,
  faEdit,
  faHandshake,
  faCalendarExclamation,
  faShareAlt,
  faRobot,
  faTag,
  faEnvelope,
  faSignature,
  faBrowser,
  faExpandAlt,
  faCodeMerge,
  faFileAlt,
  faCalendarPlus,
  faFileLines,
  faTableLayout,
  faImagePortrait
} from "@fortawesome/pro-light-svg-icons";
import { formTypes } from "./FormHelper";
import { useSchemaQuery, getEntity } from "../../Helpers/IOClient";
import {
  spaceCallsSchema,
  spaceTicketsSchema,
  spaceProjectsSchema,
  spaceTasksSchema,
  spaceInterventionsSchema,
  spaceClientSchema,
  spaceContactSchema,
  spaceContractSchema,
  spaceNoteSchema,
  spaceOrganizationSchema,
  spaceDepartmentSchema,
  spaceTeamSchema,
  spaceContractTypeSchema,
  spaceInterventionTypeSchema,
  spaceProjectCategorySchema,
  spaceDealsSchema,
  spaceSubscriptionSchema,
  spaceSubscriptionTypeSchema,
  spaceEmailDomainsSchema,
  spaceClientTypeSchema,
  spaceSocialAccountSchema,
  spaceSocialPostsSchema,
  spaceWidgetSchema,
  spaceCuponSchema,
  spacesListSchema,
  spaceEmailReceptionSchema,
  spaceEmailConversationSchema,
  spaceEmailSignatureSchema,
  spaceApiTokensSchema,
  spaceApiLogsSchema,
  spaceAutomationSchema,
  spaceDocTemplatesSchema,
  spaceDocumentsSchema,
  SpaceDocTemplateFoldersSchema,
  SpaceDocFoldersSchema,
  spaceDocumentTypesSchema,
  spaceUserCategoriesSchema,
  spaceTaskTypeSchema,
  spaceCostCentersSchema,
  spaceTicketTagsSchema,
  spaceDealsTagsSchema,
  spaceClientTagsSchema,
  spaceContractTagsSchema,
  spaceSubscriptionTagsSchema,
  spaceCallsTagsSchema,
  spaceProjectTagsSchema,
  spaceTaskTagsSchema
} from "../../config/schema";
import "./FormButtons.css";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";

import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import {
  DetailsSettingsContext,
  DetailsValueIdContext
} from "../../Components/DetailsView2/DetailsView2Helper";
import { useServerAwareState } from "../../Components/CGrid/ServerGrid";
import { useMenuContext } from "../../Components/ContextMenu/ContextMenu";
import { EntityPopupContext } from "../../Components/EntityPopup/EntityPopupHelper";
import { useHistory } from "react-router-dom";
import { useSpace } from "../../Contexts/SpaceContext";
import moment from "moment";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";

const EntityLinkDict = {
  clients: "customers",
  contacts: "contacts",
  deals: "deals",
  subscriptions: "subscriptions",
  contracts: "contracts",
  calls: "calls",
  tickets: "tickets",
  tasks: "tasks",
  interventions: "interventions",
  projects: "projects"
};

// const buildFormattedMessage = (id, entityId) => {
//   if (!id) return null;

//   return (
//     <FormattedMessage id={id}>
//       {txt => <span>{entityId ? `${txt} ${entityId}` : txt}</span>}
//     </FormattedMessage>
//   );
// };

export const FormButtonSkeleton = ({ size, className }) => {
  return (
    <div
      className={classnames(
        "ssi-button-grid skeleton rounded",
        size || "sm",
        className
      )}
    />
  );
};

// const CovenantItemsTrans = {
//   1: {
//     key: 1,
//     maxPeriodicity: 50,
//     defaultPeriodicity: 1,
//     translationId: "DAY"
//   },
//   2: {
//     key: 2,
//     maxPeriodicity: 50,
//     defaultPeriodicity: 1,
//     translationId: "MONTH"
//   },
//   3: {
//     key: 3,
//     maxPeriodicity: 50,
//     defaultPeriodicity: 1,
//     translationId: "YEAR"
//   },
//   4: {
//     key: 4,
//     maxPeriodicity: 50,
//     defaultPeriodicity: 1,
//     translationId: "WEEK"
//   }
// };

const FormButtonDuplicator = (props) => {
  const {
    entityId,
    schema,
    formType,
    renovate,
    // popupTextId,
    // textId,
    push,
    oldForm,
    newForm,
    onSuccess
  } = props;
  const providedOnSuccess = useContext(EntityPopupContext);
  const openForm = useEntitySummarySidebar();
  const { refetchRef } = useServerAwareState() || {};
  const { refetch, loading } = useSchemaQuery(schema, entityId, {
    autoFetch: false,
    onSuccess: () => {
      const entity = getEntity(schema, entityId);
      const {
        Creator,
        CreationDate,
        Account,
        Id,
        InboundEmail,
        ...entityRest
      } = entity;
      if (renovate && schema.name === "SpaceContracts") {
        const renovateForm = { ...entityRest };
        const { Covenant, EndDate } = renovateForm;
        const { TimeUnit, TimeValue } = Covenant || {};

        let newEndDate = "";

        switch (TimeUnit) {
          case 1:
            newEndDate = moment(EndDate).add(TimeValue, "day").toISOString();
            break;
          case 2:
            newEndDate = moment(EndDate).add(TimeValue, "month").toISOString();
            break;
          case 3:
            newEndDate = moment(EndDate).add(TimeValue, "year").toISOString();
            break;
          case 4:
            newEndDate = moment(EndDate).add(TimeValue, "week").toISOString();
            break;
          default:
            break;
        }
        renovateForm.BeginDate = EndDate;
        renovateForm.EndDate = newEndDate;
        renovateForm.InboundEmail = InboundEmail;
        // renovateForm.InboundEmailId = renovateForm?.InboundEmail?.Id;
        renovateForm.TransferInboundEmail = true;
        // renovateForm.oldContractId = entityId;
        // renovateForm.ChangeAirbotOrigin = true;
        openForm({
          type: formType,
          props: {
            duplicationForm: renovateForm,
            form: newForm,
            refetchRef
          },
          // title: buildFormattedMessage(popupTextId || textId),
          // push,
          options: {
            pushOnTop: push,
            oldProps: {
              form: oldForm,
              edit: true
            }
          }
        });
      } else
        openForm({
          type: formType,
          props: {
            duplicationForm: entityRest,
            form: newForm,
            refetchRef
          },
          // title: buildFormattedMessage(popupTextId || textId),
          // push,
          options: {
            pushOnTop: push,
            oldProps: {
              form: oldForm,
              edit: true
            }
          }
        });
      onSuccess && onSuccess();
      providedOnSuccess && providedOnSuccess();
    }
  });
  const handleClick = () => {
    if (entityId) refetch();
  };

  return (
    <FormButtonContent
      {...props}
      altImage={
        renovate ? (
          <FontAwesomeIcon icon={faCalendarPlus} />
        ) : (
          <FontAwesomeIcon icon={faClone} />
        )
      }
      loading={loading}
      onClick={handleClick}
    />
  );
};

const getResolvedFormProps = (
  formProps,
  detailsSettings,
  detailsSettingsId
) => {
  if (formProps)
    return typeof formProps === "function" ? formProps() : formProps;

  if (detailsSettings) {
    const { formContextProp } = detailsSettings;

    if (!formContextProp) return;

    return {
      [formContextProp]: detailsSettingsId
    };
  }
};

const StandardFormButton = (props) => {
  const {
    formProps,
    formType,
    entityId,
    // popupEditTextId,
    canEdit,
    isChild,
    refetchOnEdit,
    oldForm,
    getParentForm,
    // editTextId,
    // popupTextId,
    // textId,
    push,
    newForm,
    onSuccess
    // Icon,
  } = props;
  const providedOnSuccess = useContext(EntityPopupContext);
  const openForm = useEntitySummarySidebar();
  const detailsSettings = useContext(DetailsSettingsContext);
  const detailsEntityId = useContext(DetailsValueIdContext);
  const { refetchRef } = useServerAwareState() || {};

  const handleClick = useCallback(() => {
    const resolvedFormProps = getResolvedFormProps(
      formProps,
      detailsSettings,
      detailsEntityId
    );

    let props = entityId
      ? {
          id: entityId,
          preview: !canEdit,
          refetchRef: refetchOnEdit ? refetchRef : undefined,
          deleteRefetchRef: refetchRef,
          ...resolvedFormProps
        }
      : { ...resolvedFormProps, refetchRef };
    openForm({
      type: formType,
      props: {
        edit: canEdit,
        isChild: isChild,
        oldForm: oldForm,
        getParentForm: getParentForm,
        form: newForm,
        ...props
      },
      // title: entityId ? (
      //   <span>
      //     {Icon}
      //     {buildFormattedMessage(popupEditTextId || editTextId, entityId)}
      //   </span>
      // ) : (
      //   <span>
      //     {Icon}
      //     {buildFormattedMessage(popupTextId || textId)}
      //   </span>
      // ),

      // push,
      options: {
        pushOnTop: push,
        oldProps: {
          form: oldForm,
          edit: true
        }
        // newProps: {
        //   form: newForm
        // }
      }
    });
    onSuccess && onSuccess();
    providedOnSuccess && providedOnSuccess();
  }, [
    canEdit,
    detailsEntityId,
    detailsSettings,
    entityId,
    formProps,
    formType,
    getParentForm,
    isChild,
    newForm,
    oldForm,
    onSuccess,
    openForm,
    providedOnSuccess,
    push,
    refetchOnEdit,
    refetchRef
  ]);

  return <FormButtonContent {...props} onClick={handleClick} />;
};

const FormButtonContent = ({
  canPreview,
  canEdit,
  link,
  skeleton,
  disabled,
  entityId,
  size,
  className,
  altImage,
  alt,
  forceOnlyChildren,
  minimal,
  children,
  renovate,
  duplicate,
  intl,
  editTextId,
  textId,
  onClick,
  Component,
  globalEntityView,
  moduleType,
  loading,
  vType,
  icon,
  hideWithoutPermission,
  onMouseEnter,
  contextIcons
}) => {
  let resolvedText;
  const history = useHistory();
  const hasContextChildren = useMenuContext();
  const space = useSpace();
  if (children) {
    resolvedText = children;
  } else if (duplicate) resolvedText = intl.formatMessage({ id: "DUPLICATE" });
  else if (renovate) resolvedText = intl.formatMessage({ id: "RENOVATE" });
  else {
    if (canEdit)
      // resolvedContent = <div><FontAwesomeIcon icon={faPencil} /></div>;
      resolvedText = intl.formatMessage({ id: editTextId || "EDIT" });
    else if (canPreview && entityId)
      // resolvedContent = <div><FontAwesomeIcon icon={faEye} /></div>;
      resolvedText = intl.formatMessage({ id: "DETAILS" });
    else resolvedText = intl.formatMessage({ id: textId });
  }

  if (skeleton) {
    return <FormButtonSkeleton size={size} className={className} />;
  }

  const resolvedDisabled = disabled || !canPreview;
  if (hideWithoutPermission && resolvedDisabled) return null;

  const resolvedImage = altImage;

  const globalEntityViewClick = () => {
    history.push({
      pathname: `/s/${space.Id}/${EntityLinkDict[moduleType]}/${entityId}`
    });
  };

  if (globalEntityView) {
    return (
      <Button
        className={classnames(className, size, "text-left")}
        vType="link"
        onClick={globalEntityViewClick}
        disabled={resolvedDisabled}
      >
        <div className="mr-1 ar-form-button-link-icon">
          <FontAwesomeIcon icon={faBrowser} />
        </div>
        <span className="fw-regular fs-14">
          <FormattedMessage id="VIEW" /> 360
        </span>
      </Button>
    );
  }

  if (forceOnlyChildren) {
    return (
      <Button
        onMouseEnter={onMouseEnter}
        className={classnames(className, size)}
        onClick={onClick}
        disabled={resolvedDisabled}
        vType={"link"}
      >
        {children}
      </Button>
    );
  }

  if (minimal) {
    return (
      <Button
        className={classnames("ar-minimal-button", className)}
        title={resolvedText}
        onClick={onClick}
        disabled={resolvedDisabled}
      >
        {canEdit ? (
          <FontAwesomeIcon icon={faPencil} />
        ) : (
          <FontAwesomeIcon icon={faExpandAlt} />
        )}
      </Button>
    );
  }

  if (alt) {
    return (
      <Button
        className={classnames(
          "ssi-button ssi-button-grid rounded",
          className,
          size || "sm"
        )}
        vType="primary-ghost"
        title={resolvedText}
        onClick={onClick}
        disabled={resolvedDisabled}
      >
        {resolvedImage}
      </Button>
    );
  }

  if (link || hasContextChildren) {
    const button = (
      <Button
        className={classnames(className, size, "text-left")}
        vType="link"
        title={resolvedText}
        onClick={onClick}
        disabled={resolvedDisabled}
      >
        <div className="mr-1 ar-form-button-link-icon">
          {loading ? (
            <LoaderSpinner />
          ) : contextIcons ? (
            canEdit ? (
              <FontAwesomeIcon icon={faPencil} />
            ) : (
              <FontAwesomeIcon icon={faExpandAlt} />
            )
          ) : (
            resolvedImage
          )}
        </div>
        <span className="fw-regular fs-14"> {resolvedText} </span>
      </Button>
    );

    return Component ? <Component Button={button} /> : button;
  }

  if (entityId) {
    return (
      <Button
        className={classnames("ssi-button-grid", className, size || "sm")}
        vType="primary-ghost"
        title={resolvedText}
        onClick={onClick}
        // disabled={resolvedDisabled}
      >
        {canEdit ? (
          <FontAwesomeIcon icon={faPencil} />
        ) : duplicate ? (
          <FontAwesomeIcon icon={faClone} />
        ) : renovate ? (
          <FontAwesomeIcon icon={faCalendarPlus} />
        ) : (
          <FontAwesomeIcon icon={faExpandAlt} />
        )}
      </Button>
    );
  }

  return (
    <Button
      className={classnames(className, size)}
      onClick={onClick}
      disabled={resolvedDisabled}
      vType={vType}
    >
      <div className="mr-1 d-inline-block">{resolvedImage}</div>
      <span className="fw-regular fs-14"> {resolvedText} </span>
    </Button>
  );
};

export const createFormButton = (buttonOptions) => {
  const { Icon, skipPermission, refetchOnEdit, moduleType } = buttonOptions;

  return injectIntl((props) => {
    const {
      id: entityId, // preview,
      canEdit = false,
      disabled,
      show,
      hasAccess = true,
      duplicate,
      renovate,
      onMouseEnter
      // changeModuleType,
    } = props;

    const currentAccountSpace = useCurrentAccountSpace();

    let resolvedCanEdit = false;
    let resolvedCanPreview = false;

    resolvedCanPreview = skipPermission || hasAccess;

    //verify if needs to preview or edit
    if (entityId) {
      if (resolvedCanPreview && canEdit) {
        resolvedCanEdit = true;
      }
    } else {
      if (!resolvedCanPreview && !show) {
        return null;
      }
    }

    if (!entityId || (entityId && duplicate)) {
      const canCreate = canUserEditModule(currentAccountSpace, moduleType);
      if (!canCreate) return null;
    }

    const resolvedDisabled = disabled || !resolvedCanPreview;

    const contentProps = {
      ...buttonOptions,
      ...props,
      entityId,
      canPreview: resolvedCanPreview,
      canEdit: resolvedCanEdit,
      disabled: resolvedDisabled,
      Icon,
      onMouseEnter
    };

    if (duplicate) return <FormButtonDuplicator {...contentProps} />;
    else if (renovate)
      return <FormButtonDuplicator renovate {...contentProps} />;
    else
      return (
        <StandardFormButton refetchOnEdit={refetchOnEdit} {...contentProps} />
      );
  });
};

export const AutomationFormButton = createFormButton({
  schema: spaceAutomationSchema,
  Icon: <FontAwesomeIcon icon={faCodeMerge} className="mr-2" />,
  formType: formTypes.automation,
  textId: "ADD_WORKFLOW",
  popupTextId: "ADD_WORKFLOW",
  popupEditTextId: "WORKFLOW",
  moduleType: moduleTypes.automation,
  altImage: <FontAwesomeIcon icon={faCodeMerge} />
});

export const DocTemplatesFormButton = createFormButton({
  schema: spaceDocTemplatesSchema,
  Icon: <FontAwesomeIcon icon={faFileAlt} className="mr-2" />,
  formType: formTypes.docTemplates,
  textId: "ADD_DOC_TEMPLATE",
  popupTextId: "ADD_DOC_TEMPLATE",
  popupEditTextId: "DOC_TEMPLATE",
  moduleType: moduleTypes.docTemplates,
  altImage: <FontAwesomeIcon icon={faTableLayout} />
});
export const DocumentsFormButton = createFormButton({
  schema: spaceDocumentsSchema,
  Icon: <FontAwesomeIcon icon={faFileLines} className="mr-2" />,
  formType: formTypes.documents,
  textId: "ADD_DOCUMENT",
  popupTextId: "ADD_DOCUMENT",
  popupEditTextId: "DOCUMENT",
  moduleType: moduleTypes.documents,
  altImage: <FontAwesomeIcon icon={faFileLines} />
});

export const CallFormButton = createFormButton({
  schema: spaceCallsSchema,
  Icon: <FontAwesomeIcon icon={faPhone} className="mr-2" />,
  formType: moduleTypes.calls,
  textId: "ADD_CALL",
  popupTextId: "ADD_CALL",
  popupEditTextId: "CALLS",
  moduleType: moduleTypes.calls,
  altImage: <FontAwesomeIcon icon={faPhone} />
});

export const CuponFormButton = createFormButton({
  schema: spaceCuponSchema,
  refetchOnEdit: true,
  Icon: <FontAwesomeIcon icon={faTag} className="mr-2" />,
  formType: moduleTypes.cupons,
  textId: "ADD_COUPON",
  popupTextId: "ADD_COUPON",
  popupEditTextId: "COUPONS",
  moduleType: moduleTypes.cupons,
  altImage: <FontAwesomeIcon icon={faTag} />
});

export const SpaceFormButton = createFormButton({
  schema: spacesListSchema,
  // refetchOnEdit: true,
  Icon: <FontAwesomeIcon icon={faTag} className="mr-2" />,
  formType: formTypes.spaces,
  textId: "ADD_Space",
  popupTextId: "ADD_Space",
  popupEditTextId: "WORKSPACE_TEXT",
  // moduleType: moduleTypes.cupons,
  altImage: <FontAwesomeIcon icon={faTag} />
});

export const TicketFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faTicketSimple} className="mr-2" />,
  schema: spaceTicketsSchema,
  formType: moduleTypes.tickets,
  textId: "ADD_CASE",
  popupTextId: "ADD_CASE",
  popupEditTextId: "CASES",
  moduleType: moduleTypes.tickets,
  altImage: <FontAwesomeIcon icon={faTicketSimple} />
});

export const ProjectFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faSquareParking} className="mr-2" />,
  schema: spaceProjectsSchema,
  formType: moduleTypes.projects,
  textId: "ADD_PROJECT",
  popupTextId: "ADD_PROJECT",
  popupEditTextId: "PROJECT",
  moduleType: moduleTypes.projects,
  altImage: <FontAwesomeIcon icon={faSquareParking} />
});

export const UserCategoryFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faImagePortrait} className="mr-2" />,
  schema: spaceUserCategoriesSchema,
  formType: moduleTypes.spaceUserCategories,
  textId: "ADD_CATEGORY",
  popupTextId: "ADD_CATEGORY",
  popupEditTextId: "CATEGORY",
  moduleType: moduleTypes.spaceUserCategories,
  altImage: <FontAwesomeIcon icon={faImagePortrait} />
});

export const CostCenterFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faImagePortrait} className="mr-2" />,
  schema: spaceCostCentersSchema,
  formType: moduleTypes.spaceCostCenters,
  textId: "ADD_COST_CENTER",
  popupTextId: "ADD_COST_CENTER",
  popupEditTextId: "COST_CENTER",
  moduleType: moduleTypes.spaceCostCenters,
  altImage: <FontAwesomeIcon icon={faImagePortrait} />
});

export const TaskFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faListCheck} className="mr-2" />,
  schema: spaceTasksSchema,
  formType: moduleTypes.tasks,
  textId: "ADD_TASK",
  popupTextId: "ADD_TASK",
  popupEditTextId: "TASK",
  moduleType: moduleTypes.tasks,
  altImage: <FontAwesomeIcon icon={faListCheck} />
});

export const InterventionFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faUserClock} className="mr-2" />,
  schema: spaceInterventionsSchema,
  formType: moduleTypes.interventions,
  textId: "ADD_TIME",
  popupTextId: "ADD_TIME",
  popupEditTextId: "TIME",
  moduleType: moduleTypes.interventions,
  altImage: <FontAwesomeIcon icon={faUserClock} />
});

export const ClientFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faSuitcase} className="mr-2" />,
  schema: spaceClientSchema,
  formType: moduleTypes.clients,
  textId: "ADD_ACCOUNT",
  popupTextId: "ADD_ACCOUNT",
  popupEditTextId: "ACCOUNT",
  moduleType: moduleTypes.clients,
  altImage: <FontAwesomeIcon icon={faSuitcase} />
});

export const ContactFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faUserTie} className="mr-2" />,
  schema: spaceContactSchema,
  formType: moduleTypes.contacts,
  textId: "ADD_CONTACT",
  popupTextId: "ADD_CONTACT",
  popupEditTextId: "CONTACT",
  moduleType: moduleTypes.contacts,
  altImage: <FontAwesomeIcon icon={faUserTie} />
});

export const ContractFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faFileContract} className="mr-2" />,
  schema: spaceContractSchema,
  formType: moduleTypes.contracts,
  textId: "ADD_CONTRACT",
  popupTextId: "ADD_CONTRACT",
  popupEditTextId: "CONTRACT",
  moduleType: moduleTypes.contracts,
  altImage: <FontAwesomeIcon icon={faFileContract} />
});

export const SocialAccountFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faFileContract} className="mr-2" />,
  schema: spaceSocialAccountSchema,
  formType: moduleTypes.settingsSocial,
  textId: "ADD_SOCIAL_ACCOUNT",
  popupTextId: "ADD_SOCIAL_ACCOUNT",
  popupEditTextId: "EDIT_SOCIAL_ACCOUNT",
  moduleType: moduleTypes.settingsSocial,
  altImage: <FontAwesomeIcon icon={faFileContract} />
});

export const SubscriptionFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faCalendarExclamation} className="mr-2" />,
  schema: spaceSubscriptionSchema,
  formType: moduleTypes.subscriptions,
  textId: "ADD_SUBSCRIPTION",
  popupTextId: "ADD_SUBSCRIPTION",
  popupEditTextId: "SUBSCRIPTION",
  moduleType: moduleTypes.subscriptions,
  altImage: <FontAwesomeIcon icon={faCalendarExclamation} />
});

export const NoteFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceNoteSchema,
  formType: formTypes.notes,
  textId: "ADD_NOTE",
  popupTextId: "ADD_NOTE",
  popupEditTextId: "NOTE",
  skipPermission: true,
  // moduleType: moduleTypes.contracts,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const OrganizationFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceOrganizationSchema,
  formType: formTypes.organizations,
  textId: "ADD_COMPANY",
  popupTextId: "ADD_COMPANY",
  popupEditTextId: "EDIT_COMPANY",
  moduleType: moduleTypes.organizations,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const DepartmentFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceDepartmentSchema,
  formType: formTypes.departments,
  textId: "ADD_DEPARTMENT",
  popupTextId: "ADD_DEPARTMENT",
  popupEditTextId: "EDIT_DEPARTMENT",
  moduleType: moduleTypes.departments,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const DocTemplateFolderFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: SpaceDocTemplateFoldersSchema,
  formType: formTypes.docTemplateFolders,
  textId: "ADD_DOC_TEMPLATE_FOLDER",
  popupTextId: "ADD_DOC_TEMPLATE_FOLDER",
  popupEditTextId: "EDIT_DOC_TEMPLATE_FOLDER",
  moduleType: moduleTypes.folderSettings,
  altImage: <FontAwesomeIcon icon={faEdit} />
});
export const DocFolderFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: SpaceDocFoldersSchema,
  formType: formTypes.docFolders,
  textId: "ADD_DOC_TEMPLATE_FOLDER",
  popupTextId: "ADD_DOC_TEMPLATE_FOLDER",
  popupEditTextId: "EDIT_DOC_TEMPLATE_FOLDER",
  moduleType: moduleTypes.folderSettings,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const TeamFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceTeamSchema,
  formType: formTypes.teams,
  textId: "ADD_TEAM",
  popupTextId: "ADD_TEAM",
  popupEditTextId: "EDIT_TEAM",
  moduleType: moduleTypes.teams,
  altImage: <FontAwesomeIcon icon={faEdit} />,
  refetchOnEdit: true
});

export const ContractTypeFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceContractTypeSchema,
  formType: formTypes.contractTypes,
  textId: "ADD_TYPE_OF_CONTRACT",
  popupTextId: "ADD_TYPE_OF_CONTRACT",
  popupEditTextId: "EDIT_TYPE_OF_CONTRACT",
  moduleType: moduleTypes.contractTypes,
  altImage: <FontAwesomeIcon icon={faEdit} />,
  refetchOnEdit: true
});

export const EmailDomainsFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceEmailDomainsSchema,
  formType: formTypes.emailDomains,
  textId: "ADD_EMAIL_DOMAIN",
  popupTextId: "ADD_EMAIL_DOMAIN",
  popupEditTextId: "EDIT_EMAIL_DOMAIN",
  moduleType: moduleTypes.emailDomains,
  altImage: <FontAwesomeIcon icon={faEdit} />,
  refetchOnEdit: true
});

export const ApiTokensFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceApiTokensSchema,
  formType: formTypes.apiTokens,
  textId: "ADD_API_KEY",
  popupTextId: "ADD_API_KEY",
  popupEditTextId: "EDIT_API_KEY",
  moduleType: moduleTypes.apiKeySettings,
  altImage: <FontAwesomeIcon icon={faEdit} />,
  refetchOnEdit: true
});

export const ApiLogsFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceApiLogsSchema,
  formType: formTypes.apiLogs,
  // textId: "ADD_API_KEY",
  // popupTextId: "ADD_API_KEY",
  // popupEditTextId: "EDIT_API_KEY",
  moduleType: moduleTypes.apiKeySettings,
  altImage: <FontAwesomeIcon icon={faEdit} />
  // refetchOnEdit: true,
});

export const SubscriptionTypeFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceSubscriptionTypeSchema,
  formType: formTypes.subscriptionTypes,
  textId: "ADD_TYPE_OF_SUBSCRIPTION",
  popupTextId: "ADD_TYPE_OF_SUBSCRIPTION",
  popupEditTextId: "EDIT_TYPE_OF_SUBSCRIPTION",
  moduleType: moduleTypes.subscriptionTypes,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const InterventionTypeFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceInterventionTypeSchema,
  formType: formTypes.interventionTypes,
  textId: "ADD_TIME_TYPE",
  popupTextId: "ADD_TIME_TYPE",
  popupEditTextId: "EDIT_TIME_TYPE",
  moduleType: moduleTypes.SettingsTimeCharge,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const ClientTypeFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceClientTypeSchema,
  formType: formTypes.clientTypes,
  refetchOnEdit: true,
  textId: "ADD_CLIENT_TYPE",
  popupTextId: "ADD_CLIENT_TYPE",
  popupEditTextId: "EDIT_CLIENT_TYPE",
  moduleType: moduleTypes.ticketType,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const TicketTagsFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceTicketTagsSchema,
  formType: formTypes.ticketTags,
  refetchOnEdit: true,
  textId: "ADD_CASE_TAG",
  popupTextId: "ADD_CASE_TAG",
  popupEditTextId: "EDIT_CASE_TAG",
  moduleType: moduleTypes.settingsTickets,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const DealsTagsFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceDealsTagsSchema,
  formType: formTypes.dealTags,
  refetchOnEdit: true,
  textId: "ADD_DEAL_TAG",
  popupTextId: "ADD_DEAL_TAG",
  popupEditTextId: "EDIT_DEAL_TAG",
  moduleType: moduleTypes.settingsDeals,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const ProjectTagsFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceProjectTagsSchema,
  formType: formTypes.projectTags,
  refetchOnEdit: true,
  textId: "ADD_PROJECT_TAG",
  popupTextId: "ADD_PROJECT_TAG",
  popupEditTextId: "EDIT_PROJECT_TAG",
  moduleType: moduleTypes.settingsProjects,
  altImage: <FontAwesomeIcon icon={faEdit} />
});
export const TaskTagsFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceTaskTagsSchema,
  formType: formTypes.taskTags,
  refetchOnEdit: true,
  textId: "ADD_TASK_TAG",
  popupTextId: "ADD_TASK_TAG",
  popupEditTextId: "EDIT_TASK_TAG",
  moduleType: moduleTypes.settingsTasks,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const CallsTagsFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceCallsTagsSchema,
  formType: formTypes.callTags,
  refetchOnEdit: true,
  textId: "ADD_CALL_TAG",
  popupTextId: "ADD_CALL_TAG",
  popupEditTextId: "EDIT_CALL_TAG",
  moduleType: moduleTypes.settingsCalls,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const ContractTagsFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceContractTagsSchema,
  formType: formTypes.contractTags,
  refetchOnEdit: true,
  textId: "ADD_CONTRACT_TAG",
  popupTextId: "ADD_CONTRACT_TAG",
  popupEditTextId: "EDIT_CONTRACT_TAG",
  moduleType: moduleTypes.settingsContracts,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const SubscriptionTagsFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceSubscriptionTagsSchema,
  formType: formTypes.subscriptionTags,
  refetchOnEdit: true,
  textId: "ADD_SUBSCRIPTION_TAGS",
  popupTextId: "ADD_SUBSCRIPTION_TAGS",
  popupEditTextId: "EDIT_SUBSCRIPTION_TAGS",
  moduleType: moduleTypes.settingsSubscriptions,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const ClientTagsFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceClientTagsSchema,
  formType: formTypes.clientTags,
  refetchOnEdit: true,
  textId: "ADD_ACCOUNT_TAG",
  popupTextId: "ADD_ACCOUNT_TAG",
  popupEditTextId: "EDIT_ACCOUNT_TAG",
  moduleType: moduleTypes.settingsClient,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const TaskTypeFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceTaskTypeSchema,
  formType: formTypes.taskType,
  textId: "ADD_TASK_TYPE",
  popupTextId: "ADD_TASK_TYPE",
  popupEditTextId: "EDIT_TASK_TYPE",
  moduleType: moduleTypes.settingsTaskTypes,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const IneficientTypeFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  //   schema: spaceIneficientTypesSchema,
  formType: formTypes.ineficientTypes,
  textId: "ADD_INEFICIENT_TYPE",
  popupTextId: "ADD_INEFICIENT_TYPE",
  popupEditTextId: "EDIT_INEFICIENT_TYPE",
  moduleType: moduleTypes.ineficientType,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const TravelDurationFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  //   schema: spaceIneficientTypesSchema,
  formType: formTypes.travelTimes,
  textId: "ADD_TRAVEL_DURATION",
  popupTextId: "ADD_TRAVEL_DURATION",
  popupEditTextId: "EDIT_TRAVEL_DURATION",
  moduleType: moduleTypes.travelTimes,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const ProjectCategoryFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceProjectCategorySchema,
  formType: formTypes.projectCategory,
  textId: "ADD_TYPE_OF_PROJECTS",
  popupTextId: "ADD_TYPE_OF_PROJECTS",
  popupEditTextId: "EDIT_TYPE_OF_PROJECTS",
  moduleType: moduleTypes.SettingsTimeCharge,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const DocumentTypeFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faFileLines} className="mr-2" />,
  schema: spaceDocumentTypesSchema,
  formType: formTypes.documentType,
  textId: "ADD_DOCUMENT_TYPE",
  popupTextId: "ADD_DOCUMENT_TYPE",
  popupEditTextId: "EDIT_DOCUMENT_TYPE",
  moduleType: moduleTypes.SettingsDocumentType,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const CancelationReasonFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  //   schema: spaceCancelationReasonSchema,
  formType: formTypes.cancelationReason,
  textId: "ADD_CANCELATION_REASON",
  popupTextId: "ADD_CANCELATION_REASON",
  popupEditTextId: "EDIT_CANCELATION_REASON",
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const LostReasonFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  //   schema: spaceCancelationReasonSchema,
  formType: formTypes.lostReason,
  textId: "ADD_LOST_REASON",
  popupTextId: "ADD_LOST_REASON",
  popupEditTextId: "EDIT_LOST_REASON",
  moduleType: moduleTypes.lostReasons,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const ChecklistTemplatesFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  //   schema: spaceCancelationReasonSchema,
  formType: formTypes.checklistTemplate,
  textId: "ADD_CHECKLIST_TEMPLATE",
  popupTextId: "ADD_CHECKLIST_TEMPLATE",
  popupEditTextId: "EDIT_CHECKLIST_TEMPLATE",
  moduleType: moduleTypes.checklistTemplate,
  altImage: <FontAwesomeIcon icon={faEdit} />,
  refetchOnEdit: true
});

export const DealFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faHandshake} className="mr-2" />,
  schema: spaceDealsSchema,
  formType: moduleTypes.deals,
  textId: "ADD_DEAL",
  popupTextId: "ADD_DEAL",
  popupEditTextId: "EDIT_DEAL",
  moduleType: moduleTypes.deals,
  altImage: <FontAwesomeIcon icon={faHandshake} />
});

export const SocialPostButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faShareAlt} className="mr-2" />,
  schema: spaceSocialPostsSchema,
  formType: moduleTypes.socialPost,
  textId: "ADD_PUBLICATION",
  popupTextId: "ADD_PUBLICATION",
  popupEditTextId: "EDIT_PUBLICATION",
  moduleType: moduleTypes.socialPost,
  altImage: <FontAwesomeIcon icon={faShareAlt} />,
  refetchOnEdit: true
});

export const WidgetFormButton = createFormButton({
  schema: spaceWidgetSchema,
  Icon: <FontAwesomeIcon icon={faRobot} className="mr-2" />,
  formType: moduleTypes.widgets,
  textId: "ADD_WIDGET",
  popupTextId: "ADD_WIDGET",
  popupEditTextId: "EDIT_WIDGET",
  moduleType: moduleTypes.widgets,
  altImage: <FontAwesomeIcon icon={faRobot} />
});

export const DuplicateWidgetFormButton = createFormButton({
  schema: spaceWidgetSchema,
  Icon: <FontAwesomeIcon icon={faClone} className="mr-2" />,
  formType: moduleTypes.widgets,
  textId: "DUPLICATE",
  popupTextId: "DUPLICATE",
  popupEditTextId: "DUPLICATE",
  moduleType: moduleTypes.widgets,
  altImage: <FontAwesomeIcon icon={faRobot} />
});

export const EmailReceptionFormButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEdit} className="mr-2" />,
  schema: spaceEmailReceptionSchema,
  formType: formTypes.settingsInboundEmails,
  textId: "ADD_RECEPTION",
  popupTextId: "ADD_RECEPTION",
  popupEditTextId: "EDIT_RECEPTION",
  moduleType: moduleTypes.settingsInboundEmails,
  altImage: <FontAwesomeIcon icon={faEdit} />
});

export const EmailConversationButton = createFormButton({
  Icon: <FontAwesomeIcon icon={faEnvelope} className="mr-2" />,
  schema: spaceEmailConversationSchema,
  formType: moduleTypes.emailConversations,
  textId: "NEW_CONVERSATION",
  popupTextId: "NEW_CONVERSATION",
  popupEditTextId: "NEW_CONVERSATION",
  moduleType: moduleTypes.emailConversations,
  altImage: <FontAwesomeIcon icon={faEnvelope} />
});

export const EmailSignatureFormButton = createFormButton({
  schema: spaceEmailSignatureSchema,
  Icon: <FontAwesomeIcon icon={faSignature} className="mr-2" />,
  formType: formTypes.emailSignature,
  refetchOnEdit: true,
  textId: "ADD_EMAIL_SIGNATURE",
  popupTextId: "ADD_EMAIL_SIGNATURE",
  popupEditTextId: "EDIT_EMAIL_SIGNATURE",
  moduleType: moduleTypes.emailSignatures,
  altImage: <FontAwesomeIcon icon={faSignature} />
});
