import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import MainLoader from "./Components/Loader/MainLoader";
import { useToast } from "./Components/Toast/ToastProvider";
import { captureInvite, getInvite } from "./Redux/Actions/InviteActions";

const InviteProviderChecker = ({
  children,
  hasCaptured,
  isCapturing,
  isFetching,
  error,
  invite,
  location,
  match,
  inviteError,
  history,
  getInvite,
  captureInvite,
  isAuthenticated,
  ...rest
}) => {
  let isLoading = useMemo(() => {
    const inviteId =
      localStorage.getItem("VNI-T") !== ""
        ? localStorage.getItem("VNI-T")
        : null;

    return inviteId && !error && (!invite || (invite.Account && !hasCaptured));
  }, [error, invite, hasCaptured]);

  // const isLoading = inviteId && (!invite || (invite.Account && !hasCaptured));
  const createToast = useToast();
  const intl = useIntl();

  useEffect(() => {
    const inviteId =
      localStorage.getItem("VNI-T") !== ""
        ? localStorage.getItem("VNI-T")
        : null;

    if (!inviteId || invite || inviteError) return;

    getInvite(inviteId);
  }, [getInvite, invite, inviteError]);

  useEffect(() => {
    const inviteId =
      localStorage.getItem("VNI-T") !== ""
        ? localStorage.getItem("VNI-T")
        : null;

    if (!invite || !invite.Account || hasCaptured || isCapturing || inviteError)
      return;

    captureInvite(inviteId);
  }, [captureInvite, hasCaptured, invite, inviteError, isCapturing]);

  if (hasCaptured && invite && localStorage.getItem("VNI-T") !== "") {
    localStorage.setItem("VNI-T", "");
    createToast({
      pos: "tm",
      type: "success",
      description: `${intl.formatMessage({
        id: "JOINED_THE_SPACE"
      })} ${invite.Space.Name}`
    });
    return <Redirect to={`/s/${invite.Space.Id}/welcome`} />;
  }

  return (
    <>
      {isLoading && <MainLoader />}
      {!isLoading && children}
    </>
  );
};

const mapStateToProps = (state) => {
  const inviteManager = state.InviteManager;

  const { isFetching, error, invite, isCapturing, hasCaptured } = inviteManager;

  const resolvedIsFetching = isFetching || Boolean(!invite);

  return {
    isFetching: resolvedIsFetching,
    error,
    invite,
    isCapturing,
    hasCaptured,
    isAuthenticated: state.Auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInvite: (inviteId) => dispatch(getInvite(inviteId)),
    captureInvite: (inviteId) => dispatch(captureInvite(inviteId))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InviteProviderChecker)
);
