import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "../../AutomationDetails.module.css";
import NodeContainer from "../NodeContainer";
import classnames from "classnames";
import SourceHandles from "../../Handles/SourceHandles";
import { faBolt } from "@fortawesome/pro-solid-svg-icons";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import { FormattedMessage } from "react-intl";

const ManualTrigger = React.memo(({ selected, id, data, type }) => {
  const { enabled } = data;
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer type={type} data={data} id={id}>
        <SourceHandles />
        <NodeContainer enabled={enabled} selected={selected}>
          <div className={classnames(styles.nodeIcon, styles[type], "p-3")}>
            <FontAwesomeIcon icon={faBolt} />
          </div>
          <div className="px-3 d-flex justify-content-center flex-column bg-white text-black fs-14">
            <div className="fw-medium">
              <FormattedMessage id="TRIGGER_CONDITION" />
            </div>
            <div>
              <FormattedMessage id="MANUAL" />
            </div>
          </div>
        </NodeContainer>
      </NodeBaseContainer>
    </div>
  );
});

export default ManualTrigger;
