import React from "react";
import { ConnectedTinyClientProfile } from "../Interventions/InterventionTargetItem";
import DropdownInput from "../Dropdown/DropdownInput";
import { useSubscription } from "../../Hooks/EntityHooks";

const SubscriptionListItem = ({
  Subscription,
  enableInput,
  isTyping,
  dispatch,
  text,
  ...inputProps
}) => {
	
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100">
        <div className="d-flex flex-column text-truncate w-100">
          <div className="dropdown-item-maintext d-flex">
            {!isTyping && Subscription && `${Subscription.Id} - `}
            {!enableInput && Subscription ? (
              <span className="text-truncate">{Subscription.Description}</span>
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={Subscription && Subscription.Description}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
              // <input
              //   {...inputProps}
              //   value={
              //     isTyping || !Subscription
              //       ? inputProps.value
              //       : Subscription.Description
              //   }
              // />
            )}
          </div>
          {!isTyping && (
            <React.Fragment>
              <div className="dropdown-item-subtext">
                {Subscription && (
                  <ConnectedTinyClientProfile
                    client={Subscription.Client || Subscription.IndirectClient}
                  />
                )}
              </div>
              {/* <Moment
                fromNow
                className="dropdown-item-time mr-5"
                date={Subscription.BeginDate}
              /> */}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionListItem;

export const ConnectedSubscriptionListItem = ({ Subscription, ...rest }) => {
  const c = useSubscription(Subscription);
  return <SubscriptionListItem Subscription={c} {...rest} />;
};
