import React, {
  useEffect,
  useContext,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { toIsoString } from "../../Helpers/MiscHelper";
import { useSpace } from "../../Contexts/SpaceContext";
import moment from "moment";
import { EntitySumaryCommunicator } from "../EntitySummaries/EntitySummariesHelper";

export const itemHeight = 60;
export const itemWidthCut = 10;
export const edgeMarginWidth = 10;
export const itemMargin = 10;
export const dayMilisec = 86400000;

export const openTaskForm = (selectedDate, aditionalProps) => {
  const BeginDate = new Date(selectedDate);
  BeginDate.setHours(9);
  const EndDate = new Date(BeginDate);
  EndDate.setHours(10);

  EntitySumaryCommunicator.dispatch({
    type: moduleTypes.tasks,
    props: {
      baseForm: {
        BeginDate,
        EndDate,
        ...aditionalProps,
      },
    },
  });
};

export const openSubscriptionForm = (selectedDate, aditionalProps) => {
  const BeginDate = new Date(selectedDate);
  BeginDate.setHours(9);
  const EndDate = new Date(BeginDate);
  EndDate.setHours(10);

  EntitySumaryCommunicator.dispatch({
    type: moduleTypes.subscriptions,
    props: {
      baseForm: {
        BeginDate,
        EndDate,
        ...aditionalProps,
      },
    },
  });
};

export const openDealForm = (selectedDate, aditionalProps) => {
  const BeginDate = new Date(selectedDate);
  BeginDate.setHours(9);
  const EndDate = new Date(BeginDate);
  EndDate.setHours(10);

  EntitySumaryCommunicator.dispatch({
    type: moduleTypes.deals,
    props: {
      baseForm: {
        BeginDate,
        EndDate,
        ...aditionalProps,
      },
    },
  });
};

export const openContractForm = (selectedDate, aditionalProps) => {
  const BeginDate = new Date(selectedDate);
  BeginDate.setHours(9);
  const EndDate = new Date(BeginDate);
  EndDate.setHours(10);

  EntitySumaryCommunicator.dispatch({
    type: moduleTypes.contracts,
    props: {
      baseForm: {
        BeginDate,
        EndDate,
        ...aditionalProps,
      },
    },
  });
};

export const TimelineContext = React.createContext();
export const useTimeline = () => useContext(TimelineContext);

export const TimelineLayoutStateContext = React.createContext();
export const useTimelineIsLayoutActive = () =>
  useContext(TimelineLayoutStateContext);

const KinectTimelineContext = React.createContext();

export const useTimelineKinect = () => useContext(KinectTimelineContext);

export const TimelineKinectProvider = ({ value, children }) => {
  return (
    <KinectTimelineContext.Provider value={value}>
      {children}
    </KinectTimelineContext.Provider>
  );
};

export const createTimelineUpdateBody = (
  task,
  dayDiffrence,
  dateUnit,
  fromRight
) => {
  const { BeginDate, EndDate } = task;
  //   ;
  if (fromRight !== undefined) {
    if (!fromRight) {
      //;
      // const start = new Date(BeginDate);
      const newBeginDate = moment(BeginDate)
        .add(dayDiffrence * -1, dateUnit)
        .valueOf();
      const body = {
        BeginDate: toIsoString(newBeginDate),
        EndDate: toIsoString(EndDate),
      };

      return body;
    } else {
      const newEndDate = moment(EndDate).add(dayDiffrence, dateUnit).valueOf();
      const body = {
        BeginDate: toIsoString(BeginDate),
        EndDate: toIsoString(newEndDate),
      };

      return body;
    }
  } else {
    //is dragging
    const newBeginDate = moment(BeginDate)
      .add(dayDiffrence, dateUnit)
      .valueOf();

    const newEndDate = moment(EndDate).add(dayDiffrence, dateUnit).valueOf();
    const body = {
      BeginDate: toIsoString(newBeginDate),
      EndDate: toIsoString(newEndDate),
    };

    return body;
  }

  //left
};

export const useTimelineResize = (setters, totalDays, task, post) => {
  const [isResizing, setIsResizing] = useState(false);

  const mouseUpRef = useRef();
  const mouseMoveRef = useRef();

  const pageXRef = useRef();

  const movingLeftRef = useRef();

  const releaseListners = useCallback(() => {
    if (mouseUpRef.current) {
      document.removeEventListener("mouseup", mouseUpRef.current);
      mouseUpRef.current = null;
    }

    if (mouseMoveRef.current) {
      document.removeEventListener("mousemove", mouseMoveRef.current);
      mouseMoveRef.current = null;
    }
  }, []);

  const timeline = useTimeline();

  const { setOffset, setDayDiffrence } = setters;

  const offsetRef = useRef(0);
  const { width: zoomWidth } = useTimelineZoom();
  const { dateUnit } = useTimelineConfig();

  const handleMouseDown = useCallback(
    (e, isMovingLeft) => {
      e.preventDefault();
      releaseListners();
      setIsResizing(true);
      timeline.disableLayout();
      movingLeftRef.current = isMovingLeft;
      pageXRef.current = e.pageX;

      mouseMoveRef.current = (e) => {
        if (movingLeftRef.current) {
          let newLeft = e.pageX - pageXRef.current;

          let daysDiffrence = Math.ceil((newLeft * -1) / zoomWidth) * -1;

          if (daysDiffrence >= totalDays - 1) {
            daysDiffrence = totalDays - 1;
            newLeft = daysDiffrence * zoomWidth;
          }
          // console.log(`day diffrence: ${daysDiffrence}`);
          setDayDiffrence(daysDiffrence);
          // console.log(newLeft);
          setOffset(newLeft);
          offsetRef.current = newLeft;
        } else {
          let newLeft = e.pageX - pageXRef.current;
          // console.log(newLeft)
          let daysDiffrence = Math.ceil(newLeft / zoomWidth);
          // console.log(daysDiffrence);
          // console.log(totalDays + numOfDaysToLeft);

          if (totalDays + daysDiffrence < 2) {
            daysDiffrence = (totalDays - 1) * -1;
            newLeft = daysDiffrence * zoomWidth;
          }
          // console.log(`day diffrence: ${daysDiffrence}`);
          setDayDiffrence(daysDiffrence);
          setOffset(newLeft);
          offsetRef.current = newLeft;
        }
      };
      mouseUpRef.current = (e) => {
        // console.log("mouse up")
        const onEnd = () => {
          setOffset(0);
          offsetRef.current = 0;
          setDayDiffrence(0);
          setIsResizing(false);
          timeline.enableLayout();
        };

        if (movingLeftRef.current) {
          const numOfDaysToLeft = Math.ceil(
            (offsetRef.current * -1) / zoomWidth
          );
          // console.log(numOfDaysToLeft);
          //
          if (numOfDaysToLeft !== 0) {
            post(
              createTimelineUpdateBody(task, numOfDaysToLeft, dateUnit, false),
              onEnd,
              onEnd
            );
          } else {
            onEnd();
          }

          setOffset(zoomWidth * numOfDaysToLeft * -1);
        } else {
          const numOfDaysToLeft = Math.ceil(offsetRef.current / zoomWidth);
          // console.log(numOfDaysToLeft);

          if (numOfDaysToLeft !== 0) {
            post(
              createTimelineUpdateBody(task, numOfDaysToLeft, dateUnit, true),
              onEnd,
              onEnd
            );
          } else {
            onEnd();
          }

          setOffset(zoomWidth * numOfDaysToLeft);
        }
        releaseListners();
      };
      document.addEventListener("mousemove", mouseMoveRef.current);
      document.addEventListener("mouseup", mouseUpRef.current);
    },
    [
      dateUnit,
      post,
      releaseListners,
      setDayDiffrence,
      setOffset,
      task,
      timeline,
      totalDays,
      zoomWidth,
    ]
  );

  const handleLeftMouseDown = useCallback(
    (e) => {
      handleMouseDown(e, true);
    },
    [handleMouseDown]
  );

  const handleRightMouseDown = useCallback(
    (e) => {
      handleMouseDown(e, false);
    },
    [handleMouseDown]
  );

  //release on unmount
  useEffect(() => {
    return () => {
      releaseListners();
    };
  }, [releaseListners]);

  return {
    isResizing,
    handleLeftMouseDown,
    handleRightMouseDown,
    isMovingLeft: movingLeftRef.current,
  };
};

export const useTimelineDrag = (elemRef, setters, task, post) => {
  const kinectRef = useTimelineKinect();

  const mouseMoveRef = useRef();
  const mouseUpRef = useRef();

  const [isDragging, setIsDragging] = useState(false);

  const { setOffset, setDayDiffrence } = setters;

  const offsetRef = useRef(0);

  const timeline = useTimeline();
  const { width: zoomWidth } = useTimelineZoom();
  const { dateUnit } = useTimelineConfig();
  const halfZoomWidth = zoomWidth / 2;
  useEffect(() => {
    if (!elemRef) return;
    const elem = elemRef.current;

    const listner = (e) => {
      // //
      if (
        e.target.classList &&
        e.target.classList.contains("ar-timeline-item-handle")
      )
        return;
      // e.stopPropagation();
      const kinect = kinectRef.current;

      const baseX = e.x;

      const timeout = setTimeout((e) => {
        //;
        kinect.forceEnd();
        setIsDragging(true);
        timeline.disableLayout();
        elem.removeEventListener("mousemove", mouseMoveRef.current);
        document.removeEventListener("mouseup", mouseUpRef.current);

        const moveListner = (e) => {
          e.preventDefault();
          const offset = e.x - baseX;
          let daysDiffrence =
            Math.ceil(((offset + halfZoomWidth) * -1) / zoomWidth) * -1;
          // console.log(daysDiffrence);

          offsetRef.current = offset;
          setOffset(offset);
          setDayDiffrence(daysDiffrence);
        };

        mouseUpRef.current = () => {
          // setIsDragging(false);
          const onEnd = () => {
            setOffset(0);
            offsetRef.current = 0;
            setDayDiffrence(0);
            setIsDragging(false);
            timeline.enableLayout();
          };
          let daysDiffrence =
            Math.ceil(((offsetRef.current + halfZoomWidth) * -1) / zoomWidth) *
            -1;

          if (daysDiffrence !== 0) {
            setOffset(zoomWidth * daysDiffrence);
            post(
              createTimelineUpdateBody(task, daysDiffrence, dateUnit),
              onEnd,
              onEnd
            );
          } else {
            onEnd();
          }

          document.removeEventListener("mouseup", mouseUpRef.current);
          document.removeEventListener("mousemove", moveListner);
        };
        document.addEventListener("mousemove", moveListner);
        document.addEventListener("mouseup", mouseUpRef.current);
      }, 300);

      mouseMoveRef.current = (e) => {
        // console.log(e)
        if (e.movementX === 0) return;

        clearTimeout(timeout);
        elem.removeEventListener("mousemove", mouseMoveRef.current);
        document.removeEventListener("mouseup", mouseUpRef.current);
      };

      mouseUpRef.current = () => {
        //;
        clearTimeout(timeout);
        elem.removeEventListener("mousemove", mouseMoveRef.current);
        document.removeEventListener("mouseup", mouseUpRef.current);
      };

      elem.addEventListener("mousemove", mouseMoveRef.current);
      document.addEventListener("mouseup", mouseUpRef.current);

      // console.log("mousing down");
    };

    elem.addEventListener("mousedown", listner);

    return () => {
      elem.removeEventListener("mousedown", listner);
    };
  }, [
    dateUnit,
    elemRef,
    halfZoomWidth,
    kinectRef,
    post,
    setDayDiffrence,
    setOffset,
    task,
    timeline,
    zoomWidth,
  ]);
  return { isDragging };
};

export const TimelineEntityUrlContext = React.createContext();

export const useTimelineEntityUrl = () => useContext(TimelineEntityUrlContext);

export const TImelineEntityUrlProvider = ({ schema, children }) => {
  const space = useSpace();

  const url = useMemo(() => {
    return schema.getEndpoint(space.Id);
  }, [schema, space.Id]);

  return (
    <TimelineEntityUrlContext.Provider value={url}>
      {children}
    </TimelineEntityUrlContext.Provider>
  );
};

export const TimelineSettingsContext = React.createContext();


export const TimelineRefetchValue = React.createContext();
export const TimelineRefetchSetValue = React.createContext();





const buildTimelineDate = (date) => {
  const d = date.day();
  const dateMilsec = date.valueOf();
  const isWeekend = d === 6 || d === 0;

  return { key: dateMilsec, date, isWeekend };
};

export const createDay = (...args) => {
  const date = new Date(...args);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

export const generalComputeDates = (date, itemsPerCycle, unit) => {
  const d = moment(date);
  const arr = [];

  for (let i = itemsPerCycle; i > 0; i--) {
    const newD = moment(d);
    newD.subtract(i, unit);
    arr.push(buildTimelineDate(newD));
  }

  for (let i = 0; i < itemsPerCycle + 1; i++) {
    const newD = moment(d);
    newD.add(i, unit);
    arr.push(buildTimelineDate(newD));
  }
  return arr;
};

export const generalComputeAdittionalDate = (baseDate, itemsPerCycle, unit) => {
  const arr = [];
  if (itemsPerCycle > 0) {
    for (let i = 1; i < itemsPerCycle + 1; i++) {
      const newD = moment(baseDate);
      newD.add(i, unit);
      arr.push(buildTimelineDate(newD));
    }
  } else {
    for (let i = itemsPerCycle; i < 0; i++) {
      const newD = moment(baseDate);
      newD.subtract(i * -1, unit);
      arr.push(buildTimelineDate(newD));
    }
  }

  return arr;
};

export const TimelineConfigContext = React.createContext();

export const useTimelineConfig = () => useContext(TimelineConfigContext);

export const TimelineZoomContext = React.createContext();

export const useTimelineZoom = () => useContext(TimelineZoomContext);

export const useTimelineSettings = () => useContext(TimelineSettingsContext);
