import React from "react";
import { usePipeline } from "../../Hooks/EntityHooks";
import { useServerAwareState } from "../CGrid/ServerGrid";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import { usePipelineQuery } from "../Pipelines/PipelineProvider";
import classnames from "classnames";
import WarningExclamationSvg from "../Svg/WarningExclamationSvg";
import { FormattedMessage } from "react-intl";
import Button from "../Button/Button";

export const EntityBoardsPipelineSelector = () => {
  const { loading, error, data } = usePipelineQuery();
  const { params, onParamsChange } = useServerAwareState();
  const pipelineId = params.pipeline;

  const firstPipeline = usePipeline(
    data && data.length > 0 ? data[0] : undefined
  );

  if (loading || error || !pipelineId)
    return (
      <LoaderSpinner
        size="sm"
        className="text-primary w-100 d-flex align-items-center justify-content-around"
      />
    );

  if (data.length === 0) return null;

  const resetPipeline = () => {
    onParamsChange({ pipeline: firstPipeline.Id });
  };

  return (
    <div
      style={{ minWidth: 520 }}
      className={classnames(
        "text-center h-100 w-100 d-flex align-items-center justify-content-center flex-column bg-white"
      )}
    >
      <WarningExclamationSvg className="ar-exclamation-warning-svg mx-auto mb-2" />
      <div className="text-warning fw-bold fs-22 mb-2">
        <FormattedMessage id="PIPELINE_ERROR" />
      </div>
      <div className="text-black">
        <FormattedMessage id="PIPELINE_ERROR_DESC" />
      </div>
      <Button className="mt-3" onClick={resetPipeline}>
        <FormattedMessage id="CHANGE_PIPELINE" />
      </Button>
    </div>
  );
};
