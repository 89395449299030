import { useSpace } from "../Contexts/SpaceContext";
import { useRef, useMemo, useEffect } from "react";
import { useUnreadEntitiesFetcher } from "../Components/UnreadEntities/UnreadEntities";
import { usePost } from "./IOClient";

export const useEntityReader = (value, schema, readProp, forceRead = false) => {
  const { IsFollowing, Id } = value || {};

  const { Id: spaceId } = useSpace();

  const completeIdRef = useRef(false);

  const url = useMemo(() => {
    return `${schema.getEndpoint(spaceId)}/${Id}/Read`;
  }, [Id, schema, spaceId]);

  const fetch = useUnreadEntitiesFetcher();

  const [post, { loading }] = usePost(url, schema, {
    onSuccess: () => {
      fetch();
    }
  });

  useEffect(() => {
    if (!value || loading) return;
    if (completeIdRef.current === value.Id) return;

    const read = readProp ? value[readProp] : value.IsRead;

    completeIdRef.current = value.Id;
    if (!IsFollowing || read !== false) return;

    post();

    return () => {
      if (forceRead) completeIdRef.current = false;
    };
  }, [IsFollowing, forceRead, loading, post, readProp, value]);
};

export function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}
