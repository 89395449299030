import React from "react";
import AccountDropdownInput from "../Accounts/AccountDropdownInput";
import { useSpaceEntity } from "../../Contexts/SpaceContext";

const SpacesDropdownInput = ({ space, ...rest }) => {
  return <AccountDropdownInput account={space} {...rest} />;
};

export default SpacesDropdownInput;

export const ConnectedSpacesDropdownInput = ({ space, ...rest }) => {
  const ConnectedSpace = useSpaceEntity(space);
  return <SpacesDropdownInput space={ConnectedSpace} {...rest} />;
};
