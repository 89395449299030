import React, { useContext } from "react";
import styles from "../../AutomationDetails.module.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NodeContainer from "../NodeContainer";
import { FormattedMessage, useIntl } from "react-intl";
import {
  NodeShowDetailsContext,
  NodeTriggerTargetContext
} from "../../AutomationDetailsHelper";
import { shadeColor } from "../../../../../Helpers/EntityHelper";
import { IfElseTriggerTypeEnum } from "../../NodeTypes";

const TriggerValueComponent = ({ item }) => {
  const intl = useIntl();

  if (!item) return <div></div>;
  let resovledItem;

  if (item === IfElseTriggerTypeEnum.any) {
    return (
      <div className={"ml-1"}>
        {intl.formatMessage({ id: "ANY_SITUATION" })}
      </div>
    );
  }

  switch (item) {
    case IfElseTriggerTypeEnum.any:
      resovledItem = "ANY_SITUATION";
      break;
    case IfElseTriggerTypeEnum.creation:
      resovledItem = "CREATION";
      break;
    case IfElseTriggerTypeEnum.updates:
      resovledItem = "EDITION";
      break;
    case IfElseTriggerTypeEnum.statusChange:
      resovledItem = "STATUS";
      break;
    case IfElseTriggerTypeEnum.renew:
      resovledItem = "RENOVATE";
      break;

    default:
      resovledItem = "ANY_SITUATION";
  }

  return (
    <div
      className={classnames(
        styles.keyDropdownTextStyle,
        "ml-1 text-truncate overflow-hidden"
      )}
    >
      <FormattedMessage id={resovledItem} />
    </div>
  );
};

const AutomationTrigger = ({
  enabled,
  selected,
  icon,
  onDoubleClick,
  type,
  condition,
  config,
  TargetPreview,
  text
}) => {
  const { note, hasCustomColor, customColor } = config || {};
  const targetData = useContext(NodeTriggerTargetContext);
  const canShowDetails = useContext(NodeShowDetailsContext);
  const darkerCustomColor = hasCustomColor
    ? shadeColor(customColor, -40)
    : undefined;
  return (
    <NodeContainer
      enabled={enabled}
      selected={selected}
      onDoubleClick={onDoubleClick}
    >
      <div
        style={{
          background: hasCustomColor ? customColor : undefined,
          color: darkerCustomColor
        }}
        className={classnames(styles.nodeIcon, styles[type], "p-3")}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="px-3 py-2 d-flex justify-content-center overflow-hidden flex-column bg-white text-black fs-14">
        <div className="fw-medium">
          <FormattedMessage id="TRIGGER_CONDITION" />
        </div>
        <div className="d-flex text-truncate overflow-hidden">
          {text}
          {condition && " - "}
          {condition && (
            <>
              <TriggerValueComponent item={condition} />
            </>
          )}
        </div>
        {canShowDetails && (
          <>
            {note && (
              <div
                className={classnames(
                  styles.nodeNoteContainer,
                  "fs-10 breakWord"
                )}
              >
                {note}
              </div>
            )}
            {targetData && TargetPreview && (
              <div className="border-top mt-2 pt-2">
                <TargetPreview val={targetData} />
              </div>
            )}
          </>
        )}
      </div>
    </NodeContainer>
  );
};
// Condição de Trigger
export default AutomationTrigger;
