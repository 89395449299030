import React from "react";
import { useAutomation } from "../../Hooks/EntityHooks";
import { GeneralListItem } from "../Interventions/InterventionTargetItem";


const AutomationListItem = ({ automation, ...inputProps }) => {
  return <GeneralListItem disableImage automation item={automation} {...inputProps} />;
};

export default AutomationListItem;
export const ConnectedAutomationListItem = ({ automation, ...rest }) => {
  const t = useAutomation(automation);
  return <AutomationListItem automation={t} {...rest} />;
};
