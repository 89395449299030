import React, { useEffect, useMemo, useRef } from "react";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpacePost
} from "../../../Components/Sidebar/SidebarV2Helper";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../../Components/Forms/SideMenuForm";
import { createFormRequestHook, useFormState } from "../../../Components/Forms";
import { FormattedMessage, useIntl } from "react-intl";
import { handleError } from "../../../Helpers/MiscHelper";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { normalize } from "../../../Helpers/SchemaHelper";
import {
  spaceDealsSchema,
  spaceEmailConversationSchema,
  spaceEmailSchema,
  spaceTasksSchema,
  spaceTicketsSchema
} from "../../../config/schema";
import {
  getEntity,
  updateNormalizedSchemaCache
} from "../../../Helpers/IOClient";
import { useSpace } from "../../../Contexts/SpaceContext";
import { ReceptionItemTypeEnum } from "../ReceptionCompMsgList";
import { useLocation, useHistory } from "react-router-dom";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import PreviewEmailButton from "../PreviewEmailButton";
import { EmailPreviewFormContext } from "../../../Components/EntitySummaries/EntitySummaryMessages/MessageSender/MessageSender";
import { formateEmailForm } from "./EmailFormContentHelper";
import EmailFormContent from "./EmailFormContent";

export const ValidateNewEmailForm = (
  { Company, Client, To, Text },
  addError
) => {
  if (!Company) addError("Company");
  if (!Client) addError("Client");
  if (To.length === 0) addError("To");
  if (!Text) addError("Text");
};

const useEmailRequest = createFormRequestHook({
  // schema: spaceContactSchema,
  dependencies: [
    {
      schema: spaceTicketsSchema,
      getEntityId: ({ ticketId }) => ticketId,
      convertEntityToForm: (entity, { intl }) => {
        const { Id, Company, Client, IndirectClient, Contact, Name } = entity;
        return {
          Company: Company,
          Client: Client || IndirectClient,
          ticketId: Id,
          To: [Contact],
          Subject: `${Name} | ${intl.formatMessage({ id: "CASE" })}: ${Id}`
        };
      }
    },
    {
      schema: spaceTasksSchema,
      getEntityId: ({ taskId }) => taskId,
      convertEntityToForm: (entity, { intl }) => {
        const { Id, Company, Client, IndirectClient, Name } = entity;
        return {
          Company: Company,
          Client: Client || IndirectClient,
          taskId: Id,
          Subject: `${Name} | ${intl.formatMessage({ id: "TASK" })}: ${Id}`
        };
      }
    },
    {
      schema: spaceDealsSchema,
      getEntityId: ({ dealId }) => dealId,
      convertEntityToForm: (entity, { intl }) => {
        const { Id, Company, Client, Name } = entity;
        return {
          Company: Company,
          Client: Client,
          dealId: Id,
          Subject: `${Name} | ${intl.formatMessage({ id: "DEAL" })}: ${Id}`
        };
      }
    }
  ]
});

const ReceptionNewConvEmailForm = (props) => {
  const intl = useIntl();
  const resolvedP = useMemo(() => {
    return { ...props, intl };
  }, [intl, props]);
  const { loading, error, convertedForm } = useEmailRequest(resolvedP);
  const { onSuccess, className, setResponse, conversationId } = props;

  const resolvedConvertedForm = useMemo(() => {
    if (conversationId) {
      const conversation = getEntity(
        spaceEmailConversationSchema,
        conversationId
      );
      const { LastEmail } = conversation;
      const emailProps = getEntity(spaceEmailSchema, LastEmail);
      const { ToContacts } = emailProps;

      return { ...convertedForm, ...conversation, To: ToContacts };
    } else return convertedForm;
  }, [conversationId, convertedForm]);

  if (loading || error)
    return (
      <BaseSidebarContainer>
        <BaseSidebarContent>
          <LoaderSpinner center size="sm" className="text-secondary" />
        </BaseSidebarContent>
      </BaseSidebarContainer>
    );

  return (
    <ReceptionNewConvEmailFormBase
      setResponse={setResponse}
      className={className}
      onSuccess={onSuccess}
      convertedForm={resolvedConvertedForm}
    />
  );
};

const ReceptionNewConvEmailFormBase = ({
  setResponse,
  ticketId,
  dealId,
  taskId,
  convertedForm
}) => {
  const formState = useFormState(() => {
    const form = {
      Company: null,
      Client: null,
      To: [],
      Cc: [],
      Bcc: [],
      Subject: "",
      Text: "",
      Tickets: [],
      Tasks: [],
      Deals: [],
      ticketId,
      dealId,
      taskId,
      ...convertedForm
    };

    return form;
  });

  const createToast = useToast();

  const space = useSpace();
  const location = useLocation();
  const history = useHistory();
  const [post, { loading }] = useSidebarSpacePost(
    `EmailConversation/sendemail`,
    null,
    {
      onSuccess: ({ openSidebar, data, defineChildProps }) => {
        const { EmailConversationId } = data;

        const newConversationEmail = {
          Id: EmailConversationId,
          Status: 0,
          IsMessageRead: true,
          IsFollowing: true,
          LastEmail: data
        };

        const normalized = normalize(
          newConversationEmail,
          spaceEmailConversationSchema
        );

        updateNormalizedSchemaCache(normalized.entities);
        setResponse &&
          setResponse((r) => {
            const newR = [...r];
            newR.unshift({ EmailConversation: normalized.result });
            return newR;
          });

        const url = `/s/${space.Id}/inbox/${ReceptionItemTypeEnum.Email}/${normalized.result}${location.search}`;

        history.push(url);

        //   onSuccess && onSuccess();
        //   if (!id) defineChildProps({ Call: newId });
        //   createToast({
        //     pos: "tm",
        //     type: "success",
        //     title: `${intl.formatMessage({ id: "CALL" })} nº ${newId || id}`,
        //     description: `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`,
        //   });
        //   const { data, type } = CallTarget;
        //   if (type === "Ticket" && canUpdateEntity(spaceTicketsSchema, data)) {
        //     return openSidebar(
        //       <ConclusionForm ticket={data} />,
        //       "Concluir Ticket"
        //     );
        //   } else if (type === "Task" && canUpdateEntity(spaceTasksSchema, data)) {
        //     return openSidebar(<ConclusionForm task={data} />, "Concluir Tarefa");
        //   }
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );
  const intl = useIntl();

  const { form } = formState;
  const { To, Cc, Bcc, Subject, Text, Signature, Company } = form;
  const handleSubmit = (form) => {
    post(
      formateEmailForm({
        ...form,
        Subject:
          Subject !== ""
            ? Subject
            : intl.formatMessage({ id: "EMAIL_NO_SUBJECT" })
      })
    );
    // localStorage.setItem("air-dash-update", form.Timeout);
    // UpdateTimeout(form.Timeout);
    // closeSidebar();
  };

  // const {
  //   Tickets,
  //   Deals,
  //   Tasks,
  //   ticketId: fTicketId,
  //   dealId: fDealId,
  //   taskId: fTaskId
  // } = form;

  const FormRef = useRef({});

  useEffect(() => {
    const formdata = {
      OrganizationSpaceId: Company,
      text: Text,
      subject: Subject,
      to: To,
      cc: Cc,
      bcc: Bcc,
      signature: Signature
    };
    FormRef.current = formdata;
  }, [Bcc, Cc, Company, FormRef, Signature, Subject, Text, To]);

  const PreviewButtonStatus = useMemo(() => {
    if (Company && Text) return false;
    else return true;
  }, [Company, Text]);

  return (
    <EmailPreviewFormContext.Provider value={FormRef}>
      <BaseSidebarContainer>
        <BaseSidebarHeader>
          <FormattedMessage id="NEW_EMAIL_CONVERSATION" />
        </BaseSidebarHeader>
        <BaseSidebarContent>
          <SideForm
            validate={ValidateNewEmailForm}
            canEdit={true}
            formState={formState}
            onSubmit={handleSubmit}
          >
            <SideFormContent className="px-3 pt-3 pb-5">
              <EmailFormContent formState={formState} />
            </SideFormContent>
            <SideFormFooter
              subComponent={
                <PreviewEmailButton disabled={PreviewButtonStatus} textStyle />
              }
              isLoading={loading}
            ></SideFormFooter>
          </SideForm>
        </BaseSidebarContent>
      </BaseSidebarContainer>
    </EmailPreviewFormContext.Provider>
  );
};

export default ReceptionNewConvEmailForm;
