import {
  Communicator,
  createCommunicatorHook,
} from "../../Helpers/Communicator";

export const subscriptionCommunicator = new Communicator();

export const useSubscriptionCommunicator = createCommunicatorHook(
  subscriptionCommunicator
);
