import React from "react";
import classnames from "classnames";
import "./RoundedBadge.css";

const RoundedBadge = ({ children, type, className, innerRef, ...rest }) => {
  return (
    <div
      ref={innerRef}
      className={classnames("px-2 py-1 ar-rounded-badge", type, className)}
      {...rest}
    >
      {children}
    </div>
  );
};

export default RoundedBadge;
