import React, { useState, useCallback, useRef, useMemo } from "react";
import Button from "../../Components/Button/Button";
import Modal from "../../Components/Modal/Modal";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { ExtraCloseButton } from "../../Components/FilePreviewer/FilePreviewer";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeOpenText,
  faFilePdf,
  faEye
} from "@fortawesome/pro-light-svg-icons";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";
import { FormattedMessage, useIntl } from "react-intl";
import { createPortal } from "react-dom";
import Axios from "axios";
import { useSpace } from "../../Contexts/SpaceContext";
import { useConnectionId } from "../RealTime/RealTime";
import { handleError } from "../../Helpers/MiscHelper";
import { useToast } from "../../Components/Toast/ToastProvider";

const CustomIframe = ({ children, ...props }) => {
  const [contentRef, setContentRef] = useState(null);

  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe
      id="iframe"
      width={595}
      height="842"
      className="h-100 bg-white w-100 border-0"
      title="emailPreview"
      {...props}
      ref={setContentRef}
    >
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

const OriginalEmailModal = ({ emailConversationId, emailId }) => {
  const space = useSpace();
  const downloadUrl = useMemo(
    () =>
      `Spaces/${space.Id}/EmailConversation/${emailConversationId}/OriginalEmail/${emailId}/v2?IsPreview=false`,
    [emailConversationId, emailId, space.Id]
  );
  const previewUrl = useMemo(
    () =>
      `EmailConversation/${emailConversationId}/OriginalEmail/${emailId}/v2?IsPreview=true`,
    [emailConversationId, emailId]
  );
  const { data, loading, error } = useSpaceQuery(previewUrl, null);

  const [downloadStatus, setDownloadStatus] = useState(false);

  const connectionId = useConnectionId();
  const createToast = useToast();
  const intl = useIntl();
  const handleCreatePdf = useCallback(() => {
    setDownloadStatus(true);
    Axios.get(downloadUrl, {
      headers: {
        ConnectionId: connectionId
      }
    }).then(
      (response) => {
        // let link = document.createElement("a");
        // link.href = window.URL.createObjectURL(response);
        // link.download = "SingleEmailExport";
        // link.click();
        // link.remove();
        createToast({
          pos: "tm",
          type: "info",
          description: intl.formatMessage({
            id: "API_EXPORT_STARTED"
          })
        });
        setDownloadStatus(false);
      },
      (error) => {
        handleError(createToast, "Ocorreu um erro a exportar");
        setDownloadStatus(false);
      }
    );
  }, [connectionId, createToast, downloadUrl, intl]);

  const iframeRef = useRef();
  if (loading || error)
    return (
      <div
        style={{ width: "85vw" }}
        className="h-100 pl-3 py-3 d-flex align-items-center justify-content-center"
      >
        <LoaderSpinner size="sm" className="text-primary m-auto" />
      </div>
    );

  // eslint-disable-next-line no-unused-vars

  return (
    <div
      style={{ width: "85vw" }}
      className="h-100 pl-3 py-3 position-relative"
    >
      <iframe
        onLoad={() => {
          if (iframeRef.current) {
            const { contentDocument } = iframeRef.current;
            let doc = contentDocument;
            if (doc?.body) {
              doc.body.innerHTML =
                doc.body.innerHTML +
                `<style>
								body{
									overflow-y: auto;
									overflow-x: hidden;
									box-sizing: border-box;
									font-family: "Roboto", sans-serif;
									padding-top: 0.75rem;
									/* padding-left: 1rem; */
									padding-right: 1rem;
							}
							img {
								max-width: 100% !important;
								object-fit: contain;
								border: 0px;
							}
							::-webkit-scrollbar {
									padding: 4px 2px;
									width: 12px;
									height: 12px;
									border-radius: 12px;
							}
							::-webkit-scrollbar-thumb {
									background: #c1c9d2;
									border-radius: 12px;
									border: 2px solid transparent;
									background-clip: content-box;
							}
							blockquote {
									margin: 0 !important;
							}
							/*table.x_x_MsoNormalTable, table.x_MsoNormalTable, */
							
							table {
									overflow: hidden;
									max-width: 100%;
									width: 100% !important;
									box-sizing: border-box;
							}
							.x_bodyContainer > table {
									max-width: 100%;
									overflow: hidden;
									box-sizing: border-box;
									border-radius: 10px;
									table-layout: fixed;
							}
							.x_bodyContainer > table .x_mcnTextBlockInner:first-of-type {
									overflow: hidden;
							}
							.x_templateContainer {
									max-width: 100% !important;
									overflow: hidden;
									table-layout: fixed;
							}
							.x_bodyContainer > table .x_mcnTextBlockInner:first-of-type > table{
									max-width: 100%;
									min-width: 100%;
									overflow: hidden;
									table-layout: fixed;
							}
							#x_templateBodyAirdesk > table{
									width: 100%;
									max-width: 100% !important;
									overflow: hidden;
									box-sizing: border-box;
									table-layout: fixed;
							}
							#x_bodyCellAirdesk > table {
									table-layout: fixed !important;
							}
			</style>`;
            }
          }
        }}
        ref={iframeRef}
        className="h-100 w-100 border-0"
        title="email"
        srcDoc={data}
      />
      <div
        style={{ top: 50 }}
        className="ar-modal-preview-file-content-close-button text-color-link"
        onClick={() => {
          if (downloadStatus === true) return;
          else handleCreatePdf();
        }}
      >
        {downloadStatus ? (
          <LoaderSpinner size="xxs" className="text-primary" />
        ) : (
          <FontAwesomeIcon icon={faFilePdf} />
        )}
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: data }}></div> */}
    </div>
  );
};

const OriginalEmailButton = ({ emailConversationId, emailId, className }) => {
  const [open, setOpen] = useState(false);

  const handleClosure = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Modal
        extraCloseButton={<ExtraCloseButton onClose={handleClosure} />}
        className="h-100 d-flex align-items-center of-visible justify-content-center"
        isOpen={open}
        enableCloseButton
        onClose={handleClosure}
      >
        <OriginalEmailModal
          emailConversationId={emailConversationId}
          emailId={emailId}
        />
      </Modal>
      <InformationComponent
        text={<FormattedMessage id="SEE_ORIGINAL" />}
        Component={
          <Button
            style={{ height: "auto" }}
            vType="link-primary"
            className={className}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
        }
      />
    </>
  );
};

export default OriginalEmailButton;
