import React from 'react';

function array_move(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing purposes
}

const internal = (
  data,
  entityId,
  PipelineStatus,
  newPipelineStatus,
  targetIndex,
  entityStatusDict
) => {
  // ;
  //order changed
  if (PipelineStatus === newPipelineStatus) {
    const pipelineStatusData = data[PipelineStatus];
    if (!pipelineStatusData) return data;

    const originalIndex = pipelineStatusData.indexOf(entityId);
    if (originalIndex === targetIndex) return data;
    const originPipelineStatusData = [...pipelineStatusData];

    if (originalIndex === -1) {
      originPipelineStatusData.splice(targetIndex, 0, entityId);
    } else {
      array_move(originPipelineStatusData, originalIndex, targetIndex);
    }

    return {
      ...data,
      [PipelineStatus]: originPipelineStatusData
    };
  }
  // pipelineStatus changed
  else {
    let originPipelineStatusData = data[PipelineStatus];
    if (originPipelineStatusData) {
      const originalIndex = originPipelineStatusData.indexOf(entityId);
      if (originalIndex !== -1) {
        originPipelineStatusData = [...originPipelineStatusData];
        originPipelineStatusData.splice(originalIndex, 1);
      } else {
        originPipelineStatusData = undefined;
      }
    }

    let newPipelineStatusData = data[newPipelineStatus];

    if (newPipelineStatusData) {
      newPipelineStatusData = [...newPipelineStatusData];
      newPipelineStatusData.splice(targetIndex, 0, entityId);
      entityStatusDict[entityId] = newPipelineStatus;
    } else {
      delete entityStatusDict[entityId];
    }

    const update = {
      ...data,
      [PipelineStatus]: originPipelineStatusData || data[PipelineStatus],
      [newPipelineStatus]: newPipelineStatusData || data[newPipelineStatus]
    };

    return update;
  }
};

export const KanbanCreateNewContext = React.createContext()

export const updateEntityPipelineStatus = ({
  entityId,
  entityStatusDict,
  setter,
  newPipelineStatus,
  targetIndex
}) => {
  const PipelineStatus = entityStatusDict[entityId];

  // ;

  setter(data =>
    internal(
      data,
      entityId,
      PipelineStatus,
      newPipelineStatus,
      targetIndex,
      entityStatusDict
    )
  );
};

export const updateMultipleEntityPipelineStatus = (
  updates,
  setter,
  entityStatusDict
) => {
  const updatesSetter = setter;

  updatesSetter(data => {
    let iteratedData = data;

    for (const update of updates) {
      const { entityId, newPipelineStatus, targetIndex } = update;

      const PipelineStatus = entityStatusDict[entityId];

      iteratedData = internal(
        iteratedData,
        entityId,
        PipelineStatus,
        newPipelineStatus,
        targetIndex,
        entityStatusDict
      );
    }

    return iteratedData;
  });
};


export const SelectedBoardUserContext = React.createContext()