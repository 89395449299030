import { faCamera } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import ClipboardProvider from "../../Components/ClipbordProvider/ClipboardProvider";
import CreatedBy from "../../Components/CreatedByComponent/CreatedBy";
import ArDatePicker from "../../Components/DatePicker/ArDatePicker";
import DocTyper from "../../Components/DocTyper/DocTyper";
import {
  updateCollabFollowers,
  updateManagerFollowers,
  useCanEditFollowers,
  useDropdownFollowers
} from "../../Components/EntityFollow/EntityFollowHelper";
import FileAssociations from "../../Components/FileUploader/FileAssociations";
import FileUploader from "../../Components/FileUploader/FileUploader";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import HTMLInput from "../../Components/HTMLInput/HTMLInput";
import { AccountProfileImg } from "../../Components/Img/Img";
import HourInput from "../../Components/Input/HourInput";
import { ProfilePicture } from "../../Components/ProfilePicture/ProfilePicture";
import { SidebarAutomationContext } from "../../Components/Sidebar/SidebarV2Automation";
import {
  spaceDealsSchema,
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceTasksSchema,
  spaceContractSchema,
  spaceCallsSchema
} from "../../config/schema";
import classnames from "classnames";
import { originTypes } from "../../Helpers/MiscHelper";
import {
  useEntityValue,
  usePipelineStatus,
  useProject
} from "../../Hooks/EntityHooks";
import {
  ContactDropdown,
  DepartmentDropdown,
  OrganizationDropdown,
  projectsCancellationReasonsDropdown,
  ProjectTargetDropdown,
  ProjectTypeDropdown,
  TeamCollabDropdown,
  UserDropdown,
  ProjectTagDropdown
} from "../AdvancedMultiInputs";
import {
  ProjectPipelineDropdown,
  ProjectStatusPipelineDropdown
} from "../AdvancedMultiInputs/PipelineDropdowns";
import ProjectConflicts from "./ProjectConflicts";
import { ProjectStatusTypes } from "./ProjectsStatusHelper";
import { hasProjectFinancialPermission } from "../../Helpers/ModulesHelper";
import {
  useCurrentAccountSpace,
  useCurrentAccount
} from "../../Contexts/UserContext";
import ProjectCostSelector from "./ProjectCostSelector";
import { getEntity, useSpaceQuery } from "../../Helpers/IOClient";

const useProjectTargetClient = (projectTarget) => {
  const { schema, id } = useMemo(() => {
    const r = {
      id: projectTarget ? projectTarget.data : null
    };

    if (!projectTarget) return r;

    const { type } = projectTarget;

    if (type === originTypes.deals) {
      r.schema = spaceDealsSchema;
    }
    return r;
  }, [projectTarget]);

  const value = useEntityValue(schema, id);
  const resolvedValue = value ? value.Client : id;
  return resolvedValue;
};

const ProjectFormContent = ({
  formState,
  organizations,
  pipelines,
  currencies,
  id,
  canEdit
}) => {
  const { form, handleChange } = formState;
  const {
    Name,
    // ManagerAccount,
    // DepartmentTeam,
    Creator,
    Image,
    Company,
    Id,
    BeginDate,
    EndDate,
    StatusDetailDescription,
    Pipeline,
    AssociatedFiles,
    AssignedAssociations,
    Contact,
    Manager,
    // ProjectType,
    CreationDate,
    PipelineStatus,
    StatusDetailId,
    StatusDetail,
    // Comments,
    ProjectTarget,
    Followers,
    TeamCollab,
    IndirectClient,
    Client
  } = form;
  const PipelineId = Pipeline;
  const PipelineStatusId = PipelineStatus;
  const handlePipelineChanges = (e) => {
    const { value } = e.target;
    if (PipelineId === value) return;
    handleChange({
      Pipeline: value,
      PipelineStatus: ""
    });
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value
    });
  };

  const handleCompanyChange = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value,
      ProjectType: undefined
    });
  };

  const handleProjectTargetChange = (e) => {
    handleChange({
      ProjectTarget: e.target.value
      // Contact: null
    });
  };

  const contactValidationURL = useMemo(() => {
    if (!Contact) return null;

    let projectId, ticketId, taskId, contractId, clientId, callId, dealId;

    if (ProjectTarget) {
      switch (ProjectTarget.type) {
        case originTypes.project:
          projectId = ProjectTarget.data;
          break;

        case originTypes.ticket:
          ticketId = ProjectTarget.data;
          break;

        case originTypes.task:
          taskId = ProjectTarget.data;
          break;

        case originTypes.contract:
          contractId = ProjectTarget.data;
          break;

        case originTypes.client:
          clientId = ProjectTarget.data;
          break;

        case originTypes.call:
          callId = ProjectTarget.data;
          break;

        case originTypes.deals:
          dealId = ProjectTarget.data;
          break;

        default:
          break;
      }
    }

    const project = getEntity(spaceProjectsSchema, projectId);
    const ticket = getEntity(spaceTicketsSchema, ticketId);
    const task = getEntity(spaceTasksSchema, taskId);
    const contract = getEntity(spaceContractSchema, contractId);
    const call = getEntity(spaceCallsSchema, callId);
    const deal = getEntity(spaceDealsSchema, dealId);

    const Client =
      clientId ||
      (project && (project.Client || project.IndirectClient)) ||
      (deal && (deal.Client || deal.IndirectClient)) ||
      (ticket && (ticket.Client || ticket.IndirectClient)) ||
      (task && (task.Client || task.IndirectClient)) ||
      (call && (call.Client || call.IndirectClient)) ||
      (contract && (contract.Client || contract.IndirectClient)) ||
      IndirectClient;

    return `Query/Contacts/${Client}/${Contact}`;
  }, [Contact, IndirectClient, ProjectTarget]);

  const { loading: contactaValidation } = useSpaceQuery(
    contactValidationURL,
    null,
    {
      onSuccess: ({ data }) => {
        if (!Boolean(data)) {
          handleChange({
            Contact: null
          });
        }
      },
      onError: (err) => {
        handleChange({
          Contact: null
        });
      }
    }
  );

  const handleAssociationsChanges = (value) => {
    handleChange((oldF) => {
      const newF = { ...oldF };
      const newAssignedAssociations = Array.isArray(newF.AssignedAssociations)
        ? [...newF.AssignedAssociations]
        : [];
      const index = newAssignedAssociations.findIndex((e) => e === value);
      if (index !== -1) {
        newAssignedAssociations.splice(index, 1);
      } else newAssignedAssociations.push(value);
      newF.AssignedAssociations = newAssignedAssociations;
      return newF;
    });
  };

  const projectTargetClient = useProjectTargetClient(ProjectTarget);
  const entity = useProject(id);
  const currentManager = entity ? entity.Manager : Creator;

  const handleTeamCollabChange = ({ target: { value } }) => {
    handleChange({
      TeamCollab: value,
      Followers: updateCollabFollowers(Followers, TeamCollab, value, [Manager])
    });
  };
  const PipelieStatusObj = usePipelineStatus(PipelineStatusId);
  const remainingAccounts = useDropdownFollowers(TeamCollab);
  const handleManagerChange = ({ target: { value } }) => {
    handleChange({
      Manager: value,
      Followers: updateManagerFollowers(Followers, Manager, value, [
        remainingAccounts
      ])
    });
  };

  if (
    !Pipeline &&
    pipelines &&
    pipelines.length > 0 &&
    pipelines.length === 1
  ) {
    handleChange({
      Pipeline: pipelines[0]
    });
  }

  if (
    PipelieStatusObj &&
    PipelieStatusObj.Status !== ProjectStatusTypes.canceled &&
    (Boolean(StatusDetailId) ||
      Boolean(StatusDetail) ||
      Boolean(StatusDetailDescription))
  ) {
    handleChange({
      StatusDetailId: "",
      StatusDetail: "",
      StatusDetailDescription: ""
    });
  }
  const currentAccountSpace = useCurrentAccountSpace();
  const PrivateFinancialPermission =
    hasProjectFinancialPermission(currentAccountSpace);

  const currentUser = useCurrentAccount();

  const Automation = useContext(SidebarAutomationContext);
  return (
    <>
      {Automation && (
        <FormInput
          textId="CREATED_BY"
          name="Creator"
          inputType={UserDropdown}
          className="mb-3"
        />
      )}

      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"ASSOCIATE_PROJECT_TO"} />
      </div>

      {(Automation || (organizations && organizations.length > 1)) && (
        <FormInput
          name="Company"
          textId="COMPANY"
          inputType={OrganizationDropdown}
          onChange={handleCompanyChange}
          className="mb-3"
        />
      )}

      <FormInput
        textId="ASSIGN_TO"
        name="ProjectTarget"
        inputType={ProjectTargetDropdown}
        allClients={false}
        className="mb-3"
        onChange={handleProjectTargetChange}
      />

      <FormInput
        className="mb-3"
        name="Contact"
        textId="CONTACT"
        inputType={ContactDropdown}
        disabled={contactaValidation}
        IndirectClient={IndirectClient || Client}
        Client={projectTargetClient}
      />

      <FormInput
        className="mb-3"
        name="ReceivedDate"
        textId="ENTRY"
        inputType={ArDatePicker}
        componentClassName="w-100"
        openHourAfterDate
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"CLASSIFICATION"} />
      </div>

      <FormInput
        className="mb-3"
        name="Pipeline"
        value={PipelineId}
        disabled={pipelines && pipelines.length > 0 && pipelines.length === 1}
        textId="PIPELINE"
        inputType={ProjectPipelineDropdown}
        onChange={handlePipelineChanges}
      />

      <FormInput
        className="mb-3"
        disabled={!PipelineId}
        textId="STATUS"
        name="PipelineStatus"
        Status={!id && ProjectStatusTypes.open}
        value={PipelineStatusId}
        onLoad={({ data }) => {
          handleChange(
            {
              PipelineStatus: data[0],
              StatusDetail: null,
              StatusDetailDescription: null
            },
            true
          );
        }}
        filterBlock={true}
        inputType={ProjectStatusPipelineDropdown}
        pipelineId={PipelineId}
        onChange={handleChanges}
      />

      {id &&
        PipelieStatusObj &&
        PipelieStatusObj.Status === ProjectStatusTypes.canceled && (
          <FormInput
            name="StatusDetailId"
            company={Company}
            textId="CANCELATION_REASON"
            inputType={projectsCancellationReasonsDropdown}
            value={
              StatusDetailId
                ? StatusDetailId
                : StatusDetail
                ? StatusDetail
                : null
            }
            onChange={handleChanges}
            className="mb-3"
          />
        )}
      {id &&
        PipelieStatusObj &&
        PipelieStatusObj.Status === ProjectStatusTypes.canceled && (
          <FormInput
            name="StatusDetailDescription"
            textId="CANCELATION_DESCRIPTION"
            inputType={HTMLInput}
            value={StatusDetailDescription}
            onChange={handleChanges}
            className="mb-3"
          />
        )}

      <FormInput
        className="mb-3"
        name="Tags"
        textId="PROJECT_TAGS"
        pipelineId={Pipeline}
        // getKey={(e) => {
        //   const resolvedTag = getEntity(spaceTicketTagsSchema, e);
        //   return `${e}-${resolvedTag?.Pipeline}`;
        // }}
        multiple
        inputType={ProjectTagDropdown}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"ASSIGN_TO"} />
      </div>

      <FormInput
        className="mb-3"
        name="Manager"
        textId="MANAGER"
        inputType={UserDropdown}
        onChange={handleManagerChange}
      />

      <FormInput
        name="Departments"
        className="mb-3"
        textId="DEPARTMENTS"
        inputType={DepartmentDropdown}
        multiple
      />

      <FormInput
        name="TeamCollab"
        className="mb-3"
        textId="TEAMS_CONTRIBUTORS"
        inputType={TeamCollabDropdown}
        multiple
        onChange={handleTeamCollabChange}
      />

      <FormInput
        name="Followers"
        textId="FOLLOWERS"
        inputType={UserDropdown}
        multiple
        // items={possibleFollowers}
        className="mb-3"
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"DETAILS"} />
      </div>

      <div className="d-flex mb-3 align-items-center">
        {!Automation && (
          <FormInput
            name="Image"
            disableAutomation
            inputType={ProfilePicture}
            uploadText={
              <div className="link-color fs-12 mt-1">
                <FontAwesomeIcon icon={faCamera} />{" "}
                <FormattedMessage id="UPLOAD_IMAGE" />
              </div>
            }
            placeHolderComponent={
              <AccountProfileImg
                className="rounded-50"
                account={{ Name, Image }}
              />
            }
          />
        )}
        <FormInput
          className={classnames("flex-1", { "ml-3": !Automation })}
          textId="NAME"
          name="Name"
        />
      </div>
      {/* {Name && Name.length > 60 ? (
          <div
            //   style={{ maxWidth: 320 }}
            className="mb-3 fs-12 mt-2  text-danger"
          >
            {`O nome deve ter no máximo 60 caracteres (${Name.length} introduzidos)`}
          </div>
        ) : (
          <div className="mb-3"></div>
        )} */}
      {/* <SplitCol
          className="mb-3"
          leftpercent={20}
          left={
            <FormInput
              name="Image"
              inputType={ProfilePicture}
              uploadText={
                <div className="link-color fs-12 mt-1">
                  <FontAwesomeIcon icon={faCamera} />{" "}
                  <FormattedMessage id="upload_photo" />
                </div>
              }
              placeHolderComponent={
                <AccountProfileImg
                  className="rounded-50"
                  account={{ Name, Image }}
                />
              }
            />
          }
          right={
            <FormInput text={<FormattedMessage id={"NAME"} />} name="Name" />
          }
        /> */}

      <FormInput
        name="ProjectType"
        className="mb-3"
        OrganizationSpaceId={Company}
        textId="PROJECT_TYPE"
        inputType={ProjectTypeDropdown}
      />

      <FormInput name="CodeERP" className="mb-3" textId="CODEERP" />

      <FormInput
        name="SalesOpportunity"
        className="mb-3"
        maxLength={50}
        textId="NR_SALE_OPPORTUNITY"
      />

      {PrivateFinancialPermission && (
        <>
          <hr className="mb-3"></hr>
          <div className="mb-3 text-black fs-16 fw-medium">
            <FormattedMessage id={"FINANCIAL_DATA"} />
          </div>
          <FormInput
            disableAutomation
            textId="TOTAL_VALUE"
            name="EstimatedCostsDict"
            currencies={currencies}
            inputType={ProjectCostSelector}
            className="mb-3"
          />
        </>
      )}

      <FormInput
        className="mb-3"
        preview={
          !Id ? false : !PrivateFinancialPermission || Creator === currentUser
        }
        disabled={
          !Id ? false : !PrivateFinancialPermission || Creator === currentUser
        }
        name="EstimatedCost"
        textId="BUDGET_TIME"
        inputType={HourInput}
      />
      <FormInput
        className="mb-3"
        name="BeginDate"
        textId="START"
        inputType={ArDatePicker}
        value={BeginDate}
        onChange={handleChanges}
        componentClassName="w-100"
        enableHours={false}
      />

      <FormInput
        className="mb-3"
        textId="END"
        inputType={ArDatePicker}
        value={EndDate}
        name="EndDate"
        onChange={handleChanges}
        disablePastDate={BeginDate}
        disableDates={true}
        componentClassName="w-100"
        enableHours={false}
      />
      {PrivateFinancialPermission && (
        <FormInput
          className="mb-3"
          name="ComplementaryTime"
          textId="COMPLEMENTED_TIME"
          inputType={HourInput}
        />
      )}

      {Manager && BeginDate && EndDate && (
        <ProjectConflicts
          projectId={id}
          user={Manager}
          beginDate={BeginDate}
          endDate={EndDate}
        />
      )}

      <ClipboardProvider>
        <FormInput
          className="mb-3"
          textId="DESCRIPTION"
          inputType={DocTyper}
          name="Description"
        />

        <FormInput
          className="mb-3"
          textId="DESCRIPTION_PERSON_RESPONSIBLE"
          inputType={DocTyper}
          name="InternalDescription"
        />

        {/* {id && (
            <FormInput
              textId="status"} 
              name="Status"
              inputType={ProjectStatusDropdown}
              hideCancellation={!isEntityStatusCancelled(id)}
              className="mb-3"
            />
		  )} */}
        {!Automation && (
          <>
            {Array.isArray(AssociatedFiles) && AssociatedFiles.length > 0 && (
              <>
                <hr className="mb-3"></hr>
                <div className="text-black fs-16 mb-3 fw-medium">
                  <FormattedMessage id="RELATED_ATTACHMENTS" />
                </div>
                <FormInput
                  disableAutomation
                  inputType={FileAssociations}
                  name="Files"
                  className="mb-3 form-Anexos-floater"
                  extendedMode
                  handleAssociationsChanges={handleAssociationsChanges}
                  AssignedAssociations={AssignedAssociations}
                  onChange={() => {}}
                  value={AssociatedFiles}
                />
              </>
            )}
            <hr className="mb-3"></hr>
            <div className="text-black fs-16 fw-medium">
              <FormattedMessage id={"ATTACHMENTS"} />
            </div>

            <FormInput
              name="Files"
              enableSwapper
              inputType={FileUploader}
              className="mb-3 form-Anexos-floater"
            />
          </>
        )}
      </ClipboardProvider>

      {/* {id && (
          <>
            <hr className="mb-3"></hr>
            <div className="mb-3">
              <span className="text-black fs-16 fw-medium">Comentários</span>
            </div>
            <div className="mb-3">
              <CommentModule Type="project" Id={id} Comments={Comments || []} />
            </div>
          </>
        )} */}

      <CreatedBy Creator={Creator} CreationDate={CreationDate} />
    </>
  );
};

export default ProjectFormContent;
