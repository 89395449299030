import React, { useRef, useState } from "react";
import { useSpaceQuery } from "../../Helpers/IOClient";
import Modal from "../Modal/Modal";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTimes } from "@fortawesome/pro-light-svg-icons";
import TextEllipsis from "../TextEllipsis/TextEllipsis";
import Axios from "axios";
import { useSpace } from "../../Contexts/SpaceContext";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import { apiUrl } from "../../config";
import classnames from "classnames";
import ImagePreview from "./ImagePreview";
import PdfPreview from "./PdfPreview";
import OfficePreview from "./OfficePreview";
import { FormattedMessage } from "react-intl";
import VideoPreview from "./VideoPreview";
import { ComponentElementByExtension } from "./FileUploader";
import DumbFilesPreview from "./DumbFilesPreview";
export const validadeFilePreview = (file) => {
  switch (`${file.Extension.toLowerCase()}`) {
    case ".csv":
    case ".json":
    case ".txt":
    case ".doc":
    case ".docx":
    case ".xls":
    case ".dotx":
    case ".xlr":
    case ".xlam":
    case ".xlsx":
    case ".xlt":
    case ".pptx":
    case ".pptm":
    case ".ppt":
    case ".pdf":
    case ".xps":
    case ".pps":
    case ".ppam":
    case ".tif":
    case ".gif":
    case ".jpg":
    case ".png":
    case ".jpeg":
    case ".jfif":
    case ".bmp":
    case ".pdfa":
    case ".svg":
    case ".webp":
    case ".msg":
    case ".eml":
      return true;
    default:
      return false;
  }
};

const ModalFill = ({ Space, Token, ParentFile, Type, Attachement }) => {
  const UrlFile = `${apiUrl}/Spaces/${Space}/File/Get/${ParentFile.Id}/Email/${Attachement.Id}/Attachement?token=${Token}&type=${Type}&inline=true`;
  const FileExtension = Attachement.Extension;
  switch (`${FileExtension.toLowerCase()}`) {
    case ".csv":
    case ".json":
    case ".txt":
      return <DumbFilesPreview src={UrlFile} />;
    case ".doc":
    case ".docx":
    case ".xls":
    case ".xlr":
    case ".xlam":
    case ".dotx":
    case ".xlsx":
    case ".xlt":
    case ".pptx":
    case ".pptm":
    case ".ppt":
    case ".xps":
    case ".pps":
    case ".ppam":
    case ".tif":
      return <OfficePreview src={UrlFile} />;
    case ".pdf":
    case ".pdfa":
      return <PdfPreview src={UrlFile} />;
    case ".gif":
    case ".jpg":
    case ".png":
    case ".jpeg":
    case ".bmp":
    case ".jfif":
    case ".svg":
    case ".webp":
      return <ImagePreview src={UrlFile} />;
    case ".msg":
    case ".eml":
      return (
        <AttachmentEmailPreview
          ParentFile={ParentFile}
          Token={Token}
          File={Attachement}
        />
      );
    case ".webm":
    case ".mp4":
      return (
        <VideoPreview
          src={this.state.PreviewModalFile.Url + "&inline=true"}
          handleWidthChange={this.handleImageWidth}
        />
      );
    default:
      return null;
  }
};

const downloadEmailFile = (
  Attachment,
  token,
  ParentFile,
  space,
  inline,
  type
) => {
  Axios.get(
    `Spaces/${space.Id}/File/Get/${ParentFile.Id}/Email/${Attachment.Id}/EmailAttachement?token=${token}&type=${type}&inline=${inline}`,
    {
      responseType: "blob"
    }
  ).then(
    (response) => {
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(response);
      link.download = Attachment.FileName;
      link.click();
    },
    (error) => {
      // console.log(error);
    }
  );
};

const downloadFile = (Attachment, token, ParentFile, space, inline, type) => {
  Axios.get(
    `Spaces/${space.Id}/File/Get/${ParentFile.Id}/Email/${Attachment.Id}/Attachement?token=${token}&type=${type}&inline=${inline}`,
    {
      responseType: "blob"
    }
  ).then(
    (response) => {
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(response);
      link.download = Attachment.FileName;
      link.click();
    },
    (error) => {
      // console.log(error);
    }
  );
};

const FileMaker = ({
  Attachments,
  EmailType = "",
  ParentFile,
  SetFilePreview,
  EnablePreview,
  token
}) => {
  const space = useSpace();

  const ResolvedAttachments = Attachments.map((e, i, arr) => {
    return (
      <div
        key={i}
        className="border  rounded ar-fileUploader_Preview_Email_Attachments_Container d-inline-flex mt-2 mr-2 bg-white"
      >
        <div
          onClick={() => {
            if (validadeFilePreview(e)) {
              SetFilePreview(e);
              EnablePreview();
            } else {
              downloadFile(
                e,
                token,
                ParentFile,
                space,
                false,
                EmailType.toLowerCase() === ".msg"
                  ? EmailFileType.Msg
                  : EmailFileType.Eml
              );
            }
          }}
          className="d-flex cursor-pointer justify-content-center py-2 align-items-center border-right px-2"
        >
          <ComponentElementByExtension extension={e.Extension} />
        </div>
        <div
          onClick={() => {
            if (validadeFilePreview(e)) {
              SetFilePreview(e);
              EnablePreview();
            } else {
              downloadFile(
                e,
                token,
                ParentFile,
                space,
                false,
                EmailType.toLowerCase() === ".msg"
                  ? EmailFileType.Msg
                  : EmailFileType.Eml
              );
            }
          }}
          className="flex-1 d-flex ssi-button-standard-link cursor-pointer justify-content-start align-content-center align-self-center px-2 text-black"
        >
          <TextEllipsis
            text={e.FileName.split(".")[0]}
            length={15}
            beforeEnd={5}
            truncate
            span
            afterText={e.Extension}
          />
        </div>
        <div
          onClick={() => {
            if (
              e.Extension.toLowerCase() === ".msg" ||
              e.Extension.toLowerCase() === ".eml"
            ) {
              downloadEmailFile(
                e,
                token,
                ParentFile,
                space,
                false,
                EmailType.toLowerCase() === ".msg"
                  ? EmailFileType.Msg
                  : EmailFileType.Eml
              );
            } else {
              downloadFile(
                e,
                token,
                ParentFile,
                space,
                false,
                EmailType.toLowerCase() === ".msg"
                  ? EmailFileType.Msg
                  : EmailFileType.Eml
              );
            }
          }}
          className="d-flex cursor-pointer justify-content-center px-2 align-items-center text-color-link "
        >
          <FontAwesomeIcon icon={faDownload} />
        </div>
      </div>
    );
  });

  return <div className="breakWord">{ResolvedAttachments}</div>;
};

export const EmailFileType = {
  Msg: 1,
  Eml: 2
};

export const checkForImageType = (file = {}) => {
  switch (file.Extension) {
    case ".gif":
    case ".jpg":
    case ".png":
    case ".jpeg":
    case ".bmp":
    case ".svg":
      return true;
    default:
      return false;
  }
};

const EmailPreview = ({ File }) => {
  const Space = useSpace();
  const [previewFile, setPreviewFile] = useState(null);
  const [ModalOpenState, setModalOpenState] = useState(false);
  const OpenModal = () => {
    setModalOpenState(true);
  };

  const CloseModal = () => {
    setModalOpenState(false);
  };

  const token = /token=(.*?)&|token=([\S]+)/.exec(File.Url);

  const { loading, data, error } = useSpaceQuery(
    `File/Get/${File.Id}/Email?token=${token && (token[1] || token[2])}&type=${
      File.Extension.toLowerCase() === ".msg"
        ? EmailFileType.Msg
        : EmailFileType.Eml
    }`,
    null
  );

  //   const [getFileAttachData, { loading: loadingAttachment }] = use(
  //     `File/Get/{fileid}/Email/{index}/Attachement?type=true&inline=true`,
  //     null,
  //     {
  //       onSuccess: ({ data }) => {},
  //       onError: ({ error }) => {}
  //     }
  //   );

  // useSpacePost(``, null, {
  //   onSuccess: ({ data }) => {},
  //   onError: ({ error }) => {}
  // });

  const replaceAll = (str = "", searchStr, replaceStr, regex) => {
    // escape regexp special characters in search string
    if (regex) {
      return str.replace(/target="([\w_]+)"/g, replaceStr);
    }

    // eslint-disable-next-line no-useless-escape
    searchStr = searchStr.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

    return str.replace(new RegExp(searchStr, "g"), replaceStr);
  };

  // usage:
  let resolvedBodyHtml = null;
  if (data) {
    resolvedBodyHtml = replaceAll(data.BodyHtml, " alt", " noAlt");
    resolvedBodyHtml = replaceAll(
      resolvedBodyHtml,
      "<a",
      `<a target="_blank" rel="noopener noreferrer"`
    );

    // replaceAll(resolvedBodyHtml, "alt", "noAlt");
    // // resolvedBodyHtml = data.BodyHtml.replaceAll("\\alt\\", "noAlt");
  }

  const isImage = checkForImageType(previewFile || undefined);
  const iframeRef = useRef();
  return (
    <>
      <Modal
        className={classnames(
          "ar-modal-preview-file-content d-flex flex-column p-1",
          {
            "ar-modal-preview-image-content": isImage,
            "ar-modal-preview-files-content-files": !isImage
          }
        )}
        extraCloseButton={
          <>
            <div
              className="ar-modal-preview-file-content-close-button"
              onClick={() => CloseModal()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div
              style={{ top: 55 }}
              className="ar-modal-preview-file-content-close-button text-color-link"
              onClick={() => {
                if (
                  previewFile.Extension.toLowerCase() === ".msg" ||
                  previewFile.Extension.toLowerCase() === ".eml"
                ) {
                  downloadEmailFile(
                    previewFile,
                    token && (token[1] || token[2]),
                    File,
                    Space,
                    false,
                    File.Extension.toLowerCase() === ".msg"
                      ? EmailFileType.Msg
                      : EmailFileType.Eml
                  );
                } else {
                  downloadFile(
                    previewFile,
                    token && (token[1] || token[2]),
                    File,
                    Space,
                    false,
                    File.Extension.toLowerCase() === ".msg"
                      ? EmailFileType.Msg
                      : EmailFileType.Eml
                  );
                }
              }}
            >
              <FontAwesomeIcon icon={faDownload} />
            </div>
          </>
        }
        isOpen={ModalOpenState}
        onClose={CloseModal}
      >
        <ModalFill
          Type={
            data && data.Extension.toLowerCase() === ".msg"
              ? EmailFileType.Msg
              : EmailFileType.Eml
          }
          ParentFile={File}
          Token={token && (token[1] || token[2])}
          Space={Space.Id}
          Attachement={previewFile}
        />
        <div className="flex-1 text-black px-1 d-flex justify-content-between align-items-center">
          <span className="mr-1">{File.Name + File.Extension}</span>
          <span>{moment(File.UploadDate).format("DD/MM/YYYY HH:mm")}</span>
        </div>
      </Modal>

      <div className="p-3 d-flex flex-column h-100">
        {(loading || error || !data) && (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <LoaderSpinner size="lg" className="m-4 text-primary" />
          </div>
        )}
        {data && (
          <>
            <div className="pl-2" style={{ background: "#F2F4F8" }}>
              <div className="d-flex text-black align-items-center">
                <span className="mr-3 fs-14 fw-bold">
                  <FormattedMessage id="EMAIL_FROM" />:
                </span>
                <div className="flex-1 p-2 fs-14">
                  {data.Sender && `${data.Sender.Email}`}
                </div>
              </div>
              <div className="d-flex text-black align-items-center">
                <span className="mr-3 fs-14 fw-bold">
                  <FormattedMessage id="EMAIL_TO" />:
                </span>
                <div className="flex-1  p-2 fs-14 d-flex">
                  {data.Recipients.map((e, i, arr) => {
                    return (
                      <span key={i} className="mr-2">
                        {`${e.Email}${arr.length - 1 === i ? "" : ", "}`}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="d-flex text-black align-items-center ">
                <span className="mr-3 fs-14 fw-bold">
                  <FormattedMessage id="SENT_DATE" />:
                </span>
                <div className="flex-1 p-2 fs-14">
                  {moment(data.DateSend).format("LLL")}
                </div>
              </div>
              <div className="d-flex text-black align-items-center ">
                <span className="mr-3 fs-14 fw-bold">
                  <FormattedMessage id="SUBJECT" />:
                </span>
                <div className="flex-1 p-2 fs-14">{data.Subject}</div>
              </div>
              {data.Attachments && (
                <div className="text-black align-items-center d-flex mb-3">
                  <span className="mr-3 fs-14 fw-bold">
                    <FormattedMessage id="ATTACHMENTS" />:
                  </span>

                  <div className="flex-1 p-2 fs-14">
                    <FileMaker
                      token={token && (token[1] || token[2])}
                      SetFilePreview={setPreviewFile}
                      EnablePreview={OpenModal}
                      Attachments={data.Attachments}
                      EmailType={data.Extension}
                      ParentFile={File}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* <div className="mb-3 border rounded"> */}
            <iframe
              onLoad={() => {
                if (iframeRef.current) {
                  const { contentDocument } = iframeRef.current;
                  let doc = contentDocument;
                  if (doc?.body) {
                    doc.body.innerHTML =
                      doc.body.innerHTML +
                      `<style>
											body{
												overflow-y: auto;
												overflow-x: hidden;
												box-sizing: border-box;
												font-family: "Roboto", sans-serif;
												padding-top: 0.75rem;
												/* padding-left: 1rem; */
												padding-right: 1rem;
										}
										img {
											max-width: 100% !important;
											object-fit: contain;
											border: 0px;
										}
										::-webkit-scrollbar {
												padding: 4px 2px;
												width: 12px;
												height: 12px;
												border-radius: 12px;
										}
										::-webkit-scrollbar-thumb {
												background: #c1c9d2;
												border-radius: 12px;
												border: 2px solid transparent;
												background-clip: content-box;
										}
										blockquote {
												margin: 0 !important;
										}
										/*table.x_x_MsoNormalTable, table.x_MsoNormalTable, */
										
										table {
												overflow: hidden;
												max-width: 100%;
												width: 100% !important;
												box-sizing: border-box;
										}
										.x_bodyContainer > table {
												max-width: 100%;
												overflow: hidden;
												box-sizing: border-box;
												border-radius: 10px;
												table-layout: fixed;
										}
										.x_bodyContainer > table .x_mcnTextBlockInner:first-of-type {
												overflow: hidden;
										}
										.x_templateContainer {
												max-width: 100% !important;
												overflow: hidden;
												table-layout: fixed;
										}
										.x_bodyContainer > table .x_mcnTextBlockInner:first-of-type > table{
												max-width: 100%;
												min-width: 100%;
												overflow: hidden;
												table-layout: fixed;
										}
										#x_templateBodyAirdesk > table{
												width: 100%;
												max-width: 100% !important;
												overflow: hidden;
												box-sizing: border-box;
												table-layout: fixed;
										}
										#x_bodyCellAirdesk > table {
												table-layout: fixed !important;
										}
						</style>`;
                  }
                }
              }}
              ref={iframeRef}
              className="w-100 flex-1 border-0"
              title="Email Preview"
              srcDoc={resolvedBodyHtml}
            ></iframe>
          </>
        )}
      </div>
    </>
  );
};

const AttachmentEmailPreview = ({ File, Token, ParentFile }) => {
  const { loading, data, error } = useSpaceQuery(
    `File/Get/${ParentFile.Id}/Email/${
      File.Id
    }/EmailAttachement?token=${Token}&type=${
      ParentFile.Extension.toLowerCase() === ".msg"
        ? EmailFileType.Msg
        : EmailFileType.Eml
    }&inline=true`,
    null
  );
  const iframeRef = useRef();
  const replaceAll = (str = "", searchStr, replaceStr, regex) => {
    if (regex) {
      return str.replace(/target="([\w_]+)"/g, replaceStr);
    }

    // eslint-disable-next-line no-useless-escape
    searchStr = searchStr.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

    return str.replace(new RegExp(searchStr, "g"), replaceStr);
  };

  // usage:
  let resolvedBodyHtml = null;
  if (data) {
    resolvedBodyHtml = replaceAll(data.BodyHtml, "alt", "noAlt");
    resolvedBodyHtml = replaceAll(
      resolvedBodyHtml,
      "<a",
      `<a target="_blank" rel="noopener noreferrer"`
    );
  }
  return (
    <>
      <div className="p-3 d-flex flex-column h-100">
        {(loading || error || !data) && (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <LoaderSpinner size="lg" className="m-4 text-primary" />
          </div>
        )}
        {data && (
          <>
            <div className="pl-2" style={{ background: "#F2F4F8" }}>
              <div className="d-flex text-black align-items-center">
                <span className="mr-3 fs-14 fw-bold">
                  <FormattedMessage id="EMAIL_FROM" />:
                </span>
                <div className="flex-1 p-2 fs-14">
                  {data.Sender && `${data.Sender.Email}`}
                </div>
              </div>
              <div className="d-flex text-black align-items-center">
                <span className="mr-3 fs-14 fw-bold">
                  <FormattedMessage id="EMAIL_TO" />:
                </span>
                <div className="flex-1  p-2 fs-14 d-flex">
                  {data.Recipients.map((e, i, arr) => {
                    return (
                      <span key={i} className="mr-2">
                        {`${e.Email}${arr.length - 1 === i ? "" : ", "}`}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="d-flex text-black align-items-center ">
                <span className="mr-3 fs-14 fw-bold">
                  <FormattedMessage id="SENT_DATE" />:
                </span>
                <div className="flex-1 p-2 fs-14">
                  {moment(data.DateSend).format("LLL")}
                </div>
              </div>
              <div className="d-flex text-black align-items-center ">
                <span className="mr-3 fs-14 fw-bold">
                  <FormattedMessage id="SUBJECT" />:
                </span>
                <div className="flex-1 p-2 fs-14">{data.Subject}</div>
              </div>
              {data.Attachments && (
                <div className="text-black align-items-center d-flex mb-3">
                  <span className="mr-3 fs-14 fw-bold">
                    <FormattedMessage id="ATTACHMENTS" />:
                  </span>

                  <div className="flex-1 p-2 fs-14">
                    <span>
                      <FormattedMessage id="ERROR_LOADING_EMAIL_PREVIEW" />
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="mb-3 border rounded"> */}
            <iframe
              onLoad={() => {
                if (iframeRef.current) {
                  const { contentDocument } = iframeRef.current;
                  let doc = contentDocument;
                  if (doc?.body) {
                    doc.body.innerHTML =
                      doc.body.innerHTML +
                      `<style>
							::-webkit-scrollbar {
								padding: 4px 2px;
								width: 12px;
								height: 12px;
								border-radius: 12px;
						}
						::-webkit-scrollbar-thumb {
							background: #c1c9d2;
							border-radius: 12px;
							border: 2px solid transparent;
							background-clip: content-box;
							}
						html {
							overflow-y:auto;
						}
						</style> `;
                  }
                }
              }}
              ref={iframeRef}
              className="w-100 flex-1 border-0"
              title="Email Preview"
              srcDoc={resolvedBodyHtml}
            ></iframe>
            {/* </div> */}
          </>
        )}
      </div>
    </>
  );
};

export default EmailPreview;
