import React from "react";
import {
  faAnalytics,
  faUsers,
  faInfoSquare,
  faCity
} from "@fortawesome/pro-solid-svg-icons";
import { spaceDealsSchema } from "../../config/schema";
import {
  faCalendarAlt,
  faUsers as faUsersLight,
  faHandshake,
  faFileAlt,
  faBuilding,
  faSuitcase,
  faUserTie,
  faUsersClass,
  faUserPlus,
  faUserCog,
  faBell,
  faDrawCircle
} from "@fortawesome/pro-light-svg-icons";
import {
  EntityOverviewItem,
  EntityDescriptionOverviewItem,
  EntityOverviewRoundedItem,
  EntityOverviewPlaceholder,
  CurrencyValuesAccordion,
  EntityOverviewChildrenProgress
} from "../../Components/EntityOverview/EntityOverview";
import { GridColloredEndDateComponent } from "../../Helpers/GridHelper";
import RelativeTime from "../../Components/RelativeTime/RelativeTime";
import { ConnectedOrganizationProfile } from "../../Components/Organizations/OrganizationProfile";
import {
  EntitySummaryAccordion,
  DescriptionOverview,
  EntitySummaryFilesAccordion,
  EntitySummaryTabstrip,
  EntitySummaryTab,
  EntitySummaryOverview,
  EntityPipelineHistoryAccordion
} from "../../Components/EntitySummaries/EntitySummaries";
import { DealEntityActionsButton, DealStatusTypes } from "./DealsHelper";
import {
  ConnectedDepartmentProfileStack,
  ConnectedAccountsTeamsDepartmentsProfileStack,
  ConnectedAccountProfileStack
} from "../../Components/Accounts/AccountProfileStack";
import { History } from "../../Components/DetailsView/History/History";
import DealForm from "./DealsForm";
import { EntityOverviewPipeline } from "../../Components/EntityOverview/EntityOverviewPipeline";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import DealStatusProfile, {
  buildDealStatusType
} from "../../Components/Deals/DealStatusProfile";
import Treeview from "../Treeview/Treeview";
import {
  DealDetailsChildren,
  DealDetailsEmailConversations
} from "./DealDetails/DealDetailsChildren";
import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
import { ConnectedContactPopup } from "../Origin/ContactPopup";
import { RealTimeRoomTypes } from "../RealTime/RealTimeHelper";
import { FormattedMessage } from "react-intl";
import { DealFormButton } from "../Form/FormButtons";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { hasDealFinancialPermission } from "../../Helpers/ModulesHelper";
import {
  usePipelineStatus,
  useCancelationReason
} from "../../Hooks/EntityHooks";

export const DealSummaryContent = ({ value }) => {
  const {
    Pipeline,
    PipelineStatus,
    Creator,
    Assigned,
    SalesOpportunity,
    BeginDate,
    Company,
    Client,
    Contact,
    // ReceivedDate,
    NoTagDescription,
    Description,
    DealManager,
    Files,
    Departments,
    EstimatedCosts,
    Collaborators,
    StatusDetail,
    CanEdit,
    IsFollowing,
    Id,
    StatusDetailDescription,
    Followers
  } = value;

  const { Accounts, Teams } = Collaborators;

  const currentAccountSpace = useCurrentAccountSpace();
  const PrivateFinancialPermission =
    hasDealFinancialPermission(currentAccountSpace);

  const pipelineStatusObj = usePipelineStatus(PipelineStatus);
  const detail = useCancelationReason(StatusDetail);
  return (
    <>
      <EntitySummaryAccordion icon={faAnalytics} textId="STATUS">
        <EntityOverviewPipeline
          StatusProfileComponent={DealStatusProfile}
          buildFunction={buildDealStatusType}
          pipeline={Pipeline}
          pipelineStatus={PipelineStatus}
        />

        {StatusDetail && pipelineStatusObj.Status === DealStatusTypes.lost && (
          <EntityOverviewRoundedItem icon={faDrawCircle} titleId="REASONS_LOSS">
            {detail.Name}
          </EntityOverviewRoundedItem>
        )}
        {StatusDetailDescription &&
          pipelineStatusObj.Status === DealStatusTypes.lost && (
            <EntityDescriptionOverviewItem
              icon={faDrawCircle}
              titleId="LOSS_DESCRIPTION"
            >
              <DescriptionOverview
                Description={StatusDetailDescription}
                NoTagDescription={StatusDetailDescription}
              />
            </EntityDescriptionOverviewItem>
          )}
        {StatusDetail &&
          pipelineStatusObj.Status === DealStatusTypes.canceled && (
            <EntityOverviewRoundedItem
              icon={faDrawCircle}
              titleId="CANCELATION_REASON"
            >
              {detail.Name}
            </EntityOverviewRoundedItem>
          )}
        {StatusDetailDescription &&
          pipelineStatusObj.Status === DealStatusTypes.canceled && (
            <EntityDescriptionOverviewItem
              icon={faDrawCircle}
              titleId="CANCELATION_DESCRIPTION"
            >
              <DescriptionOverview
                Description={StatusDetailDescription}
                NoTagDescription={StatusDetailDescription}
              />
            </EntityDescriptionOverviewItem>
          )}

        <EntityOverviewItem icon={faCalendarAlt} titleId="CONCLUSION">
          <GridColloredEndDateComponent Data={value} />
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faInfoSquare} textId={"DETAILS"}>
        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="START">
          <RelativeTime date={BeginDate} />
        </EntityOverviewRoundedItem>

        <EntityOverviewRoundedItem
          icon={faHandshake}
          titleId="NR_SALE_OPPORTUNITY"
        >
          {SalesOpportunity ? SalesOpportunity : "-"}
        </EntityOverviewRoundedItem>

        <EntityOverviewChildrenProgress schema={spaceDealsSchema} id={Id} />

        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="DESCRIPTION">
          <DescriptionOverview
            Description={Description}
            NoTagDescription={NoTagDescription}
          />
        </EntityDescriptionOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faCity} textId="ASSOCIATED">
        <EntityOverviewItem icon={faBuilding} titleId="COMPANY">
          <ConnectedOrganizationProfile dynamicFont organization={Company} />
        </EntityOverviewItem>
        {/* <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="ENTRY">
          <RelativeTime date={ReceivedDate} />
        </EntityOverviewRoundedItem> */}
        <EntityOverviewItem icon={faSuitcase} titleId="ACCOUNT">
          <ConnectedClientOrigin
            placement="right"
            dynamicFont
            client={Client}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUserTie} titleId="CONTACT">
          <ConnectedContactPopup
            placement="right"
            dynamicFont
            contact={Contact}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUsersClass} titleId="DEPARTMENTS">
          {Departments ? (
            <ConnectedDepartmentProfileStack departments={Departments} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUsersLight} titleId="TEAMS_CONTRIBUTORS">
          {Accounts || Teams ? (
            <ConnectedAccountsTeamsDepartmentsProfileStack
              accounts={Accounts}
              teams={Teams}
            />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faUsers} textId="ASSIGNED_TO">
        <EntityOverviewItem icon={faUserPlus} titleId="CREATED_BY">
          {Creator ? (
            <ConnectedAccountPopup
              placement="right"
              dynamicFont
              account={Creator}
            />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUserCog} titleId="ATTRIBUTED_TO">
          {Assigned ? (
            <ConnectedAccountPopup
              placement="right"
              dynamicFont
              account={Assigned}
            />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUserCog} titleId="RESPONSIBLE_DEAL">
          {DealManager ? (
            <ConnectedAccountPopup
              placement="right"
              dynamicFont
              account={DealManager}
            />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
        <EntityOverviewItem icon={faBell} titleId="FOLLOWERS">
          {Followers && Followers.length > 0 ? (
            <ConnectedAccountProfileStack dynamicFont accounts={Followers} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
      </EntitySummaryAccordion>
      {PrivateFinancialPermission && (
        <CurrencyValuesAccordion EstimatedCosts={EstimatedCosts} />
      )}
      <EntitySummaryFilesAccordion
        CanEdit={CanEdit}
        IsFollowing={IsFollowing}
        Files={Files}
      />
      {/* <EntityPipelineHistoryAccordion
        endpoint={`Deals/${Id}/PipelineHistory`}
        id={Id}
        schema={spaceDealsSchema}
      />
      <div className="mb-3"></div> */}
      <EntityPipelineHistoryAccordion
        endpoint={`history/Deal/${Id}/pipeline`}
        id={Id}
        schema={spaceDealsSchema}
      />
    </>
  );
};

export const InnerDealSummary = ({ value }) => {
  return (
    <EntitySummaryTabstrip className="mt-2 mb-3">
      <EntitySummaryTab titleId="SUMMARY">
        <DealSummaryContent value={value} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="HISTORY">
        <History deal={value.Id} disableTabs />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ORIGINATED">
        <Treeview className="flex-1" schema={spaceDealsSchema} id={value.Id} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ASSOCIATIONS">
        <DealDetailsChildren id={value.Id} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="EMAILS">
        <DealDetailsEmailConversations id={value.Id} />
      </EntitySummaryTab>
    </EntitySummaryTabstrip>
  );
};

const DealSummary = (props) => {
  return (
    <EntitySummaryOverview
      {...props}
      url={"deals"}
      type={"deal"}
      schema={spaceDealsSchema}
      FormComponent={DealForm}
      Summary={InnerDealSummary}
      creationHeaderText={<FormattedMessage id="CREATE_DEAL" />}
      ActionButton={DealEntityActionsButton}
      FormButton={DealFormButton}
      roomType={RealTimeRoomTypes.Deal}
    ></EntitySummaryOverview>
  );
};

export default DealSummary;
