import React, { useContext, useMemo, useState } from "react";
import {
  ServerAwareContext,
  useServerAwareState
} from "../../Components/CGrid/ServerGrid";
import { GroupedByOrderByDictionary } from "../../Helpers/GroupbyHelper";
import { useSpace } from "../../Contexts/SpaceContext";
import { useIsSpecialUser } from "../../Helpers/TempColumnIdHelper";
import { ReceptionNavFilters } from "./ReceptionFilterSidebarComponents";
import { FormattedMessage, useIntl } from "react-intl";
import {
  faCommentsAlt,
  faPlus,
  faChevronUp,
  faMinusCircle,
  faSort,
  faPencil,
  faRedoAlt,
  faBolt,
  faFilter,
  faTrashAlt
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResetSelectContext } from "./ReceptionComp";
import Checkbox from "../../Components/Checkbox/Checkbox";
import Button from "../../Components/Button/Button";
import classnames from "classnames";
import {
  OrderByOptions,
  OrderByChooserDragOrder,
  FilterOptions,
  FilterSelector,
  FilterItem
} from "../../Components/FilterList/FilterList2";
import {
  ConversationTypeEnumFlags,
  EntityTypeNoteEnumFlags,
  ReceptionFilterColumns,
  ConversationTypeEnum,
  EmailConversationFilterColumns
} from "./ReceptionHelpers";
import { DetailsValueIdContext } from "../../Components/DetailsView2/DetailsView2Helper";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { canUserReadModule, moduleTypes } from "../../Helpers/ModulesHelper";
const useTempFilters = (columns, optionalModuleType) => {
  const isSpecial = useIsSpecialUser();
  // return columns;
  // const { moduleType } = useServerAwareState() || {};
  // const resolvedModuleType = optionalModuleType || moduleType;

  const space = useSpace();
  return useMemo(() => {
    if (!isSpecial) return columns;
    if (space.Id !== 1 || !columns) return columns;
    // const field = fieldDict[resolvedModuleType];
    const newColumns = [...columns];

    return newColumns;
  }, [columns, isSpecial, space.Id]);
};

const ReceptionServerAwareFilterList = ({
  columns = [],
  ordenableColumns,
  ...rest
}) => {
  const { params, onParamsChange, Reset } = useContext(ServerAwareContext);
  const ResetData = useContext(ResetSelectContext);
  const { filter = [], groupBy, advancedFilters, $orderBy } = params;

  const handleFilterAddition = (newfilter) => {
    const newFilters = [...filter];
    newFilters.push(newfilter);

    onParamsChange({
      filter: newFilters
    });
  };

  const handleFilterRemoval = (newfilter, index) => {
    const newFilters = [...filter];
    newFilters.splice(index, 1);
    onParamsChange({
      filter: newFilters
    });
  };

  const handleAllFiltersRemoval = () => {
    onParamsChange({
      filter: []
    });
  };

  const handleFilterEdit = (newfilter, index) => {
    // 	const filter = filters[filterI];
    // 	setFilterItem(filter.data);
    // 	setCurrentFilter({ filter, index: filterI });
    // 	setView(viewTypes.filterSelector);
    //   }
    const newFilters = [...filter];
    newFilters[index] = newfilter;
    onParamsChange({
      filter: newFilters
    });
  };

  const onUnreadAdd = () => {
    const filterUnread = columns.findIndex((e) => {
      return e.titleId === "NOT_READ";
    });
    const UnReadObject = columns[filterUnread];

    onParamsChange({
      filter: [
        {
          data: {
            applyOnChange: true,
            field: UnReadObject.field,
            titleId: UnReadObject.titleId
          },
          field: UnReadObject.field,
          operator: "eq",
          value: 0
        }
      ]
    });
  };

  const onRemoveEveryKindOfFilter = () => {
    ResetData();
    onParamsChange({
      $orderBy: [],
      advancedFilters: [],
      filter: [],
      additionalParams: {
        basicFilter: "",
        ReceptionAssignedFilter: "",
        conversationTypes: ConversationTypeEnumFlags.All,
        entityNoteType: EntityTypeNoteEnumFlags.All
      }
    });
  };

  const onReset = () => {
    ResetData();
    onParamsChange({
      $orderBy: [{ column: "Conversation/LastMessageDate", type: "desc" }],
      advancedFilters: [],
      filter: [],
      additionalParams: {
        basicFilter: 1,
        ReceptionAssignedFilter: "",
        conversationTypes: ConversationTypeEnumFlags.All,
        entityNoteType: EntityTypeNoteEnumFlags.All
      }
    });
  };

  const resolvedColumns = useTempFilters(columns);

  const filterUnread = useMemo(() => {
    return columns.findIndex((e) => {
      return e.titleId === "NOT_READ";
    });
  }, [columns]);

  const handleAdvancedFilterRemoval = () => {
    onParamsChange({
      advancedFilters: []
    });
  };

  const onRemoveAllOrderbys = () => {
    const itemToCompareWith =
      GroupedByOrderByDictionary[groupBy[0]] || groupBy[0];
    const groupBYOrderByIndex =
      groupBy.length > 0
        ? $orderBy.findIndex((e) => e.column === itemToCompareWith)
        : -1;

    onParamsChange({
      $orderBy:
        groupBYOrderByIndex !== -1 ? [$orderBy[groupBYOrderByIndex]] : []
    });
  };

  return (
    <InboxFilterList
      data={resolvedColumns}
      onReset={Reset}
      onUnreadAdd={filterUnread !== -1 && onUnreadAdd}
      onRemoveEveryKindOfFilter={onRemoveEveryKindOfFilter}
      onRemoveAllOrderbys={onRemoveAllOrderbys}
      // onFilterChange={data => this.setState({ filter: [...data] })}
      onRemoveAllFilters={handleAllFiltersRemoval}
      onResetAllFilters={onReset}
      onRemoveFilter={handleFilterRemoval}
      onRemoveAvancedFilters={handleAdvancedFilterRemoval}
      onAddFilter={handleFilterAddition}
      onChange={onParamsChange}
      onEditFilter={handleFilterEdit}
      value={{
        orderby: $orderBy,
        groups: groupBy,
        filters: filter,
        advancedFilters
      }}
      {...rest}
    ></InboxFilterList>
  );
};

// const InboxFilterNewAssingedInput = ({ onSubmit }) => {
//   const [value, setValue] = useState(null);

//   return (
//     <div className="my-3">
//       <UserDropdown
//         className="bg-white mb-3"
//         value={value}
//         onChange={setValue}
//       />

//       <Button
//         vType={"primary-ghost"}
//         onClick={() => {
//           onSubmit(value);
//         }}
//         className="mx-auto d-block fs-14 fw-regular"
//         size="sm"
//       >
//         <FormattedMessage id="ADD_FILTER" />
//       </Button>
//     </div>
//   );
// };

// const ReceptionAssignedItem = ({ value }) => {
//   const { onParamsChange } = useServerAwareState();

//   const removeFilter = () => {
//     onParamsChange((params) => {
//       const { additionalParams } = params;

//       return {
//         ...params,
//         additionalParams: {
//           ...additionalParams,
//           ReceptionAssignedFilter: ""
//         }
//       };
//     });
//   };

//   return (
//     <div className="a-ordenable-filters px-2 d-flex pb-2 false align-items-center justify-content-between fs-14">
//       <div className="flex-1 overflow-hidden">
//         <div className="a-filter-item d-flex pr-1">
//           <div class="flex-1 breakWord w-75">
//             <FormattedMessage id={"ASSIGNED_TO"} />{" "}
//             <ConnectedAccountProfile maxWidth account={value} />
//           </div>
//           <div className="ml-1 mr-1">
//             <div className="d-flex align-items-center justify-content-center">
//               <FontAwesomeIcon
//                 onClick={removeFilter}
//                 size="lg"
//                 icon={faMinusCircle}
//                 className="ml-2 cursor-pointer removal-cross"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>

//     // <div>
//     //
//     // </div>
//   );
// };

// const InboxFilterAssignedFilter = () => {
//   const { onParamsChange, params } = useServerAwareState();
//   const [addingNewAssigned, setAddingNewAssigned] = useState(false);
//   const ResetState = useContext(ResetSelectContext);
//   const handleSubmitNewFilter = (e) => {
//     ResetState();
//     onParamsChange((params) => {
//       const { additionalParams } = params;

//       return {
//         ...params,
//         additionalParams: {
//           ...additionalParams,
//           ReceptionAssignedFilter: e
//         }
//       };
//     });
//     toggleAddingNewAssigned();
//   };

//   const toggleAddingNewAssigned = () => {
//     setAddingNewAssigned(!addingNewAssigned);
//   };

//   const { ReceptionAssignedFilter } = params;

//   return (
//     <>
//       <div className="mb-3 fs-14 fw-medium text-black align-items-center text-truncate justify-content-between d-flex">
//         <div className="d-inline-block">
//           <FontAwesomeIcon icon={faUserPlus} size="sm" className="mr-2" />
//           <span>
//             <FormattedMessage id="ASSIGNED_TO" />
//           </span>
//         </div>
//         <div className="d-flex align-items-center">
//           <Button
//             vType={"primary"}
//             className={classnames("ar-minimal-button ")}
//             onClick={toggleAddingNewAssigned}
//           >
//             {addingNewAssigned ? (
//               <FontAwesomeIcon icon={faChevronUp} />
//             ) : (
//               <FontAwesomeIcon icon={faPlus} />
//             )}
//           </Button>

//           {/* {hasFilters && (
// 				<Button
// 					vType={"danger-ghost"}
// 					className={classnames("ar-minimal-button ")}
// 					onClick={onRemoveAllFilters}
// 				>
// 					<FontAwesomeIcon icon={faTrashAlt} />
// 				</Button>
// 				)} */}
//         </div>
//       </div>
//       {addingNewAssigned && (
//         <InboxFilterNewAssingedInput onSubmit={handleSubmitNewFilter} />
//       )}
//       {ReceptionAssignedFilter && (
//         <ReceptionAssignedItem value={ReceptionAssignedFilter} />
//       )}
//     </>
//   );
// };

const InboxOrderBy = () => {
  const [addingNewOrderBy, setAddingNewOrderBy] = useState(false);
  const { onParamsChange, params } = useServerAwareState();

  const ResetState = useContext(ResetSelectContext);
  const toggleAddingNewOrderBy = () => {
    setAddingNewOrderBy(!addingNewOrderBy);
  };
  const intl = useIntl();
  const { $orderBy } = params;

  const hasOrderBys = $orderBy.length > 0;
  return (
    <div>
      <div className="mb-3 fs-14 fw-medium text-truncate text-black align-items-center justify-content-between d-flex">
        <div className="d-inline-block">
          <FontAwesomeIcon icon={faSort} size="sm" className="mr-2" />
          <span>
            <FormattedMessage id="ORDER" />
          </span>
        </div>
        <div className="d-flex align-items-center">
          <Button
            vType={"primary"}
            className={classnames("ar-minimal-button ")}
            onClick={toggleAddingNewOrderBy}
          >
            {addingNewOrderBy ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : hasOrderBys ? (
              <FontAwesomeIcon icon={faPencil} />
            ) : (
              <FontAwesomeIcon icon={faPlus} />
            )}
          </Button>

          {/* {hasFilters && (
			<Button
			vType={"danger-ghost"}
			className={classnames("ar-minimal-button ")}
					onClick={onRemoveAllFilters}
					>
					<FontAwesomeIcon icon={faTrashAlt} />
					</Button>
				)} */}
        </div>
      </div>
      {hasOrderBys && !addingNewOrderBy && (
        <div className="mb-3 justify-content-between">
          <OrderByChooserDragOrder
            filterDict={{
              "Conversation/LastMessageDate": <FormattedMessage id={"DATE"} />
            }}
            GroupdData={[]}
            selectedColumns={$orderBy}
            onChange={(e) => {
              ResetState();

              onParamsChange((params) => {
                return { ...params, $orderBy: e };
              });
            }}
            removeColumn={(e) => {
              ResetState();
              onParamsChange((params) => {
                return { ...params, $orderBy: e };
              });
            }}
          />
        </div>
      )}
      {addingNewOrderBy && (
        <OrderByOptions
          ordenableColums={["Conversation/LastMessageDate"]}
          filterDict={{
            "Conversation/LastMessageDate": intl.formatMessage({ id: "DATE" })
          }}
          GroupdData={[]}
          data={$orderBy}
          onApply={(e) => {
            ResetState();
            onParamsChange((params) => {
              return { ...params, $orderBy: e };
            });
            toggleAddingNewOrderBy();
          }}
          RemoveReturnButton
          onGoBack={() => toggleAddingNewOrderBy}
        />
      )}
    </div>
  );
};

const InboxFilterList = React.memo(
  ({
    intl,
    onRemoveEveryKindOfFilter,
    value,
    data,
    ordenableColums,
    className,
    onResetAllFilters,
    onRemoveAvancedFilters,
    onRemoveFilter,
    onReset,
    onRemoveAllGroups,
    onEditFilter,
    onUnreadAdd,
    enableDeactivation,
    onRemoveAllOrderbys,
    ExtraFilterComponent,
    onRemoveAllFilters,
    filterType,
    filterDict,
    groupableColumns,
    disableAdvancedFilters
  }) => {
    const { params } = useServerAwareState();
    // const { user } = useCurrentAccount();
    const {
      basicFilter,
      ReceptionAssignedFilter,
      $orderBy,
      entityNoteType,
      filter: filters,
      conversationTypes
    } = params;

    const filterColumns = useMemo(() => {
      if (parseInt(conversationTypes) === ConversationTypeEnumFlags.Email) {
        return [...EmailConversationFilterColumns];
      } else return [...ReceptionFilterColumns];
    }, [conversationTypes]);

    const viewTypes = {
      overview: 0,

      filterOptions: 2,
      filterSelector: 3,
      advancedFilterSelector: 4,
      orderbyOptions: 5,
      groupByOptions: 6
    };

    const hasFilters = filters.length > 0;

    const [view, setView] = useState(viewTypes.overview); //overview | filterOptions | filterSelector

    const { onParamsChange } = useContext(ServerAwareContext);
    const [currentFilter, setCurrentFilter] = useState();

    const handleSingleFilterChange = (newfilter, index) => {
      const newFilters = [...filters];
      newFilters[index] = newfilter;
      onParamsChange({
        filter: newFilters
      });
    };

    const handleFilterAddition = (newfilter) => {
      const newFilters = [...filters];
      newFilters.push(newfilter);

      onParamsChange({
        filter: newFilters
      });
    };

    const reset = useContext(ResetSelectContext);
    const handleFilterSuperEdit = (newfilter, index) => {
      const filter = filters[index];
      setFilterItem(filter.data);
      setCurrentFilter({ filter, index });
      setView(viewTypes.filterSelector);
    };

    const onAddFilter = () => setView(viewTypes.filterOptions);
    const [filterItem, setFilterItem] = useState(viewTypes.overview);
    const ArrayFilters = filters.map((item, index) => (
      <FilterItem
        key={index}
        index={index}
        enableDeactivation={enableDeactivation}
        item={item}
        handleSingleFilterChange={handleSingleFilterChange}
        onEdit={() => handleFilterSuperEdit(item, index)}
        onRemove={(e) => {
          onRemoveFilter(item, index);
          reset();
        }}
      />
    ));

    const handleFilterOptionsClick = (filterItem) => {
      const { resolvedFilter } = filterItem;

      if (resolvedFilter) {
        setView(viewTypes.overview);
        handleSingleFilterChange(resolvedFilter);
      } else {
        setView(viewTypes.filterSelector);
        setFilterItem(filterItem);
      }
    };
    const detailsEntityId = useContext(DetailsValueIdContext);
    return (
      <div style={{ padding: "0 20px" }} className="overflow-auto flex-1">
        <div>
          {view === viewTypes.filterSelector && (
            <FilterSelector
              filterItem={filterItem}
              onAddFilter={(f) => {
                if (currentFilter) {
                  handleSingleFilterChange(f, currentFilter.index);
                } else {
                  handleFilterAddition(f);
                }
                setCurrentFilter(null);
                reset();
                setView(viewTypes.overview);
              }}
              currentFilter={currentFilter}
              onGoBack={() => {
                if (currentFilter) {
                  setCurrentFilter(null);
                  setView(viewTypes.overview);
                } else {
                  setView(viewTypes.filterOptions);
                }
              }}
            />
          )}
          {view === viewTypes.filterOptions && (
            <FilterOptions
              data={filterColumns}
              onAddFilter={handleFilterOptionsClick}
              onGoBack={() => setView(viewTypes.overview)}
            />
          )}

          {view === viewTypes.overview && (
            <>
              {!detailsEntityId && (
                <>
                  {/* <div className="mb-3 fs-14 fw-medium text-black align-items-center d-flex">
                    <FontAwesomeIcon
                      icon={faCommentsAlt}
                      size="sm"
                      className="mr-2"
                    />
                    <span>
                      <FormattedMessage id="MESSAGES" />
                    </span>
                  </div> */}
                  <ReceptionNavFilters />
                  <hr className="w-100 text-truncate" />
                </>
              )}
              <div className="mb-3 fs-14 fw-medium text-black text-truncate align-items-center d-flex">
                <FontAwesomeIcon icon={faBolt} size="sm" className="mr-2" />
                <span>
                  <FormattedMessage id="QUICK_ACTIONS" />
                </span>
              </div>
              <ReceptionCheckboxFilters />
              <>
                <hr className="w-100 text-truncate" />
                <div className="mb-3 fs-14 fw-medium text-black text-truncate align-items-center d-flex">
                  <div className="cursor-pointer flex-1" onClick={onAddFilter}>
                    <FontAwesomeIcon icon={faFilter} className="mr-2" />
                    <FormattedMessage id={"FILTER"} />
                  </div>
                  <div className="d-flex">
                    <div>
                      <Button
                        vType={"primary"}
                        className={classnames("ar-minimal-button ")}
                        onClick={onAddFilter}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </div>
                    {hasFilters && (
                      <Button
                        vType={"danger-ghost"}
                        className={classnames("ar-minimal-button ")}
                        onClick={onRemoveAllFilters}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    )}
                  </div>
                </div>
              </>
              {hasFilters && (
                <div className="mb-3 justify-content-between">
                  <OrderByChooserDragOrder
                    filterDict={filterDict}
                    selectedColumns={filters}
                    enableDeactivation={enableDeactivation}
                    itemsArray={ArrayFilters}
                    onChange={(e) => {
                      onParamsChange({ filter: e });
                    }}
                    removeColumn={(e) => {
                      onParamsChange({ filter: e });
                    }}
                  />
                </div>
              )}
              {/* <InboxFilterAssignedFilter /> */}
              <hr className="w-100 text-truncate" />
              <InboxOrderBy />

              <hr className="w-100 text-truncate" />
              <div
                className="text-primary fs-14 mb-3 text-truncate  of-hidden white-space-pre cursor-pointer"
                onClick={onResetAllFilters}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
                <span className="ml-2 text-decoration-underline text-truncate of-hidden white-space-pre">
                  <FormattedMessage id={"RESTORE_ALL"} />
                </span>
              </div>

              {(Number(basicFilter) ||
                Number(conversationTypes) !== ConversationTypeEnumFlags.All ||
                ReceptionAssignedFilter ||
                Number(entityNoteType) !== EntityTypeNoteEnumFlags.All ||
                $orderBy.length > 0) && (
                <div
                  className="text-danger-alt text-truncate of-hidden fs-14 white-space-pre cursor-pointer mb-3"
                  onClick={onRemoveEveryKindOfFilter}
                >
                  <FontAwesomeIcon icon={faMinusCircle} />
                  <span className="ml-2 text-decoration-underline text-truncate of-hidden white-space-pre">
                    <FormattedMessage id={"REMOVE_ALL"} />
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);

export const ReceptionBasicFilterEnum = {
  Unread: 1,
  Unresolved: 2,
  NotificationActive: 4,
  Read: 8,
  Resolved: 16,
  NotificationUnactive: 32
};

const ReceptionCheckboxFilters = React.memo(() => {
  const { onParamsChange, params } = useServerAwareState();
  const ResetState = useContext(ResetSelectContext);

  const { basicFilter } = params;

  const handleChange = (e) => {
    const { name, checked } = e.target;
    ResetState();
    onParamsChange((params) => {
      const { basicFilter = 0 } = params;
      let resolvedBasicFilter = Number(basicFilter);
      if (checked) {
        resolvedBasicFilter += ReceptionBasicFilterEnum[name];
      } else {
        resolvedBasicFilter -= ReceptionBasicFilterEnum[name];
      }
      return {
        ...params,
        additionalParams: {
          basicFilter: resolvedBasicFilter
        }
      };
    });
  };

  const currentUserSpace = useCurrentAccountSpace();
  const space = useSpace();
  const hasEmailPermission = useMemo(() => {
    if (!space.TrialEnd) {
      return canUserReadModule(
        currentUserSpace,
        moduleTypes.emailConversations
      );
    }
  }, [currentUserSpace, space.TrialEnd]);

  const intl = useIntl();

  return (
    <div>
      <Checkbox
        name="Read"
        onChange={handleChange}
        disabled={basicFilter & ReceptionBasicFilterEnum.Unread}
        checked={basicFilter & ReceptionBasicFilterEnum.Read}
        className="mb-2"
      >
        <div className="text-black">{intl.formatMessage({ id: "READ" })}</div>
      </Checkbox>
      <Checkbox
        onChange={handleChange}
        disabled={basicFilter & ReceptionBasicFilterEnum.Read}
        name="Unread"
        checked={basicFilter & ReceptionBasicFilterEnum.Unread}
        className="mb-2"
      >
        <div className="text-black text-truncate">
          {intl.formatMessage({ id: "NOT_READ" })}
        </div>
      </Checkbox>
      {hasEmailPermission && (
        <>
          <Checkbox
            onChange={handleChange}
            disabled={basicFilter & ReceptionBasicFilterEnum.Resolved}
            name="Unresolved"
            checked={basicFilter & ReceptionBasicFilterEnum.Unresolved}
            className="mb-2"
          >
            <div className="text-black text-truncate">
              {intl.formatMessage({ id: "OPEN" })}
            </div>
          </Checkbox>
          <Checkbox
            onChange={handleChange}
            name="Resolved"
            disabled={basicFilter & ReceptionBasicFilterEnum.Unresolved}
            checked={basicFilter & ReceptionBasicFilterEnum.Resolved}
            className="mb-2"
          >
            <div className="text-black">
              {intl.formatMessage({ id: "CLOSED_RECEPTION" })}
            </div>
          </Checkbox>
        </>
      )}
      <Checkbox
        onChange={handleChange}
        name="NotificationActive"
        disabled={basicFilter & ReceptionBasicFilterEnum.NotificationUnactive}
        checked={basicFilter & ReceptionBasicFilterEnum.NotificationActive}
        className="mb-2"
      >
        <div className="text-black text-truncate">
          {intl.formatMessage({ id: "ACTIVE_NOTIFICATIONS" })}
        </div>
      </Checkbox>
      <Checkbox
        onChange={handleChange}
        disabled={basicFilter & ReceptionBasicFilterEnum.NotificationActive}
        name="NotificationUnactive"
        checked={basicFilter & ReceptionBasicFilterEnum.NotificationUnactive}
        className="mb-2"
      >
        <div className="text-black text-truncate">
          {intl.formatMessage({ id: "INACTIVE_NOTIFICATIONS" })}
        </div>
      </Checkbox>
    </div>
  );
});

export default ReceptionServerAwareFilterList;
