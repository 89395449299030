import * as AuthActions from "../../Actions/AuthActions";

//set initial auth state by verifying if token exists
const initialAuthState = {
  isProcessing: false,
  error: null
};

function registerPageReducer(state = initialAuthState, action) {
  switch (action.type) {
    case AuthActions.REGISTER_USER_REQUEST:
      return {
        ...state,
        isProcessing: true
      };

    case AuthActions.REGISTER_USER_SUCCESS:
      return {
        ...state,
        isProcessing: false
      };

    case AuthActions.REGISTER_USER_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: action.error
      };

    case AuthActions.REMOVE_REGISTER_ERROR:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
}

export default registerPageReducer;
