import { Node, mergeAttributes } from "@tiptap/core";
import { Node as ProseMirrorNode } from "prosemirror-model";
import { PluginKey } from "prosemirror-state";
import Suggestion, { SuggestionOptions } from "@tiptap/suggestion";

export type EmailSignatureTagsOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: EmailSignatureTagsOptions;
    node: ProseMirrorNode;
  }) => string;
  suggestion: Omit<SuggestionOptions, "editor">;
};

export const EmailSignatureTagsPluginKey = new PluginKey("emailSignatureTags");

const EmailSignatureTags = Node.create<EmailSignatureTagsOptions>({
  name: "emailSignatureTags",

  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ options, node }) {
        return `${node.attrs.label ?? node.attrs.id}`;
      },
      suggestion: {
        char: "{{",
        pluginKey: EmailSignatureTagsPluginKey,
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(" ");

          if (overrideSpace) {
            range.to += 0;
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props
              },
              {
                type: "text",
                text: " "
              }
            ])
            .run();
        },
        allow: ({ editor, range }) => {
          const $from = editor.state.doc.resolve(range.from);
          const type = editor.schema.nodes[this.name];
          const allow = !!$from.parent.type.contentMatch.matchType(type);

          return allow;
        }
      }
    };
  },

  group: "inline",

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => {
          const el = element.getAttribute("data-id");
          return el !== null ? `{{${el}}}` : "";
        },
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          const newId = `${attributes.id}`;

          return {
            "data-id": `${newId.replace(/{/g, "").replace(/}/g, "")}`
          };
        }
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[data-tag]"
      }
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(
        { "data-tag": "" },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      this.options.renderLabel({
        options: this.options,
        node
      })
    ];
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node
    });
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMention = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(
            anchor - 1,
            anchor,
            (node: { type: { name: string }; nodeSize: any }, pos: any) => {
              if (node.type.name === this.name) {
                isMention = true;
                tr.insertText(
                  this.options.suggestion.char || "",
                  pos,
                  pos + node.nodeSize
                );

                return false;
              }
            }
          );

          return isMention;
        })
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion
      })
    ];
  }
});

export default EmailSignatureTags;
