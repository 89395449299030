import { faSignature } from "@fortawesome/pro-duotone-svg-icons";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../Components/Button/Button";
import { DeleteButtonContextMenu } from "../../Components/CGrid/DeleteMultipleModal";
import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import { EntityPopupContext } from "../../Components/EntityPopup/EntityPopupHelper";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import { AccountProfileImg } from "../../Components/Img/Img";
import Modal from "../../Components/Modal/Modal";
import Switch from "../../Components/Switch/Switch";
import { useToast } from "../../Components/Toast/ToastProvider";
import {
  spaceEmailSignatureSchema,
  spaceSignatureAggregationSchema
} from "../../config/schema";
import { LabelTitle } from "../../Helpers/GridHelper";
import { getEntity, useSpacePost } from "../../Helpers/IOClient";
import { createFormData, handleError } from "../../Helpers/MiscHelper";
import {
  useDepartment,
  useEmailSignatureChoice,
  useOrganization,
  useTeam
} from "../../Hooks/EntityHooks";
import {
  ContactStatusDropdown,
  EmailSignaturesTypesDropdown
} from "../AdvancedMultiInputs";
import { EmailSignatureFormButton } from "../Form/FormButtons";

export const TagsDictionary = {
  "company.avatar": "{{company.avatar}}",
  "company.logo": "{{company.logo}}",
  "company.name": "{{company.name}}",
  "company.industries": "{{company.industries}}",
  "company.website": "{{company.website}}",
  "company.vat": "{{company.vat}}",
  "company.email": "{{company.email}}",
  "company.phone_number": "{{company.phone_number}}",
  "company.address1": "{{company.address1}}",
  "company.address2": "{{company.address2}}",
  "company.linkedin": "{{company.linkedin}}",
  "company.facebook": "{{company.facebook}}",
  "company.twitter": "{{company.twitter}}",
  "company.instagram": "{{company.instagram}}",
  "company.youtube": "{{company.youtube}}",
  "department.name": "{{department.name}}",
  "department.email": "{{department.email}}",
  "department.phone_number": "{{department.phone_number}}",
  "team.name": "{{team.name}}",
  "user.phone_number": "{{user.phone_number}}",
  "user.email": "{{user.email}}",
  "user.collaborator_number": "{{user.collaborator_number}}",
  "user.extension": "{{user.extension}}",
  "user.companies": "{{user.companies}}",
  "user.role": "{{user.role}}",
  "user.departements": "{{user.departements}}",
  "user.teams": "{{user.teams}}",
  "user.avatar": "{{user.avatar}}",
  "user.name": "{{user.name}}",
  "signature.logo": "{{signature.logo}}",
  "signature.banner": "{{signature.banner}}"
};

export const TagsLinkDictionary = {
  "company.website": "{{company.website}}",
  "company.linkedin": "{{company.linkedin}}",
  "company.facebook": "{{company.facebook}}",
  "company.twitter": "{{company.twitter}}",
  "company.instagram": "{{company.instagram}}",
  "company.youtube": "{{company.youtube}}"
};

export const TagsTranslationDictionary = {
  "company.avatar": "COMPANY_AVATAR",
  "company.logo": "COMPANY_LOGO",
  "company.name": "COMPANY_SIGNATURE_NAME",
  "company.industries": "COMPANY_INDUSTRY",
  "company.website": "COMPANY_DOMAIN",
  "company.vat": "COMPANY_VAT",
  "company.email": "COMPANY_EMAIL",
  "company.phone_number": "COMPANY_PHONE",
  "company.address1": "COMPANY_ADDRESS1",
  "company.address2": "COMPANY_ADDRESS2",
  "company.linkedin": "COMPANY_LINKEDIN_URL",
  "company.facebook": "COMPANY_FACEBOOK_URL",
  "company.twitter": "COMPANY_TWITTER_URL",
  "company.instagram": "COMPANY_INSTAGRAM_URL",
  "company.youtube": "COMPANY_YOUTUBE_URL",
  "department.name": "DEPARTMENT_NAME",
  "department.email": "DEPARTMENT_EMAIL",
  "department.phone_number": "DEPARTMENT_PHONE",
  "team.name": "TEAM_NAME",
  "user.phone_number": "USER_PHONE",
  "user.email": "USER_EMAIL",
  "user.collaborator_number": "USER_EMPLOYEE_NUMBER",
  "user.extension": "USER_EXNTENSION",
  "user.companies": "USER_COMPANY",
  "user.role": "USER_ROLE",
  "user.departements": "USER_DEPARTMENTS",
  "user.teams": "USER_TEAMS",
  "user.avatar": "USER_AVATAR",
  "user.name": "USER_FULL_NAME",
  "signature.logo": "SIGNATURE_LOGO",
  "signature.banner": "SIGNATURE_BANNER"
};

export const formatSignatureForm = (form) => {
  const {
    Id,
    Name,
    Status,
    Company,
    Departments,
    Teams,
    Users,
    Reception,
    Answer,
    Logo,
    LogoUrl,
    Banner,
    BannerUrl,
    LogoImage,
    BannerImage,
    Signature,
    ExcludedUsers
  } = form;
  const CompanyIds = Company ? JSON.stringify([...Company]) : undefined;
  const DepartmentIds = Departments
    ? JSON.stringify([...Departments])
    : undefined;
  const TeamIds = Teams ? JSON.stringify([...Teams]) : undefined;
  const AccountIds = Users ? JSON.stringify([...Users]) : undefined;
  const IgnoredAccountIds = ExcludedUsers
    ? JSON.stringify([...ExcludedUsers])
    : undefined;

  const Files =
    !Logo || typeof Logo === "string"
      ? []
      : [
          {
            File: Logo.file,
            Name: Logo.file.name,
            Extension: ""
          }
        ];

  let resolvedFormData = createFormData(
    {
      Id,
      Name,
      Status,
      CompanyIds,
      DepartmentIds,
      IgnoredAccountIds,
      TeamIds,
      AccountIds,
      Signature,
      Type:
        Reception && Answer
          ? 3
          : Reception && !Answer
          ? 1
          : !Reception && Answer
          ? 2
          : 0,
      LogoLink: LogoUrl || undefined,
      BannerLink: BannerUrl || undefined,
      RemoveLogoImage: !Logo && typeof LogoImage === "string" ? true : false,
      RemoveBannerImage:
        !Banner && typeof BannerImage === "string" ? true : false
    },
    Files
  );

  if (Banner && typeof Banner === "object") {
    resolvedFormData.append("Images", Banner.file);
  }
  //  else {
  //   resolvedFormData.append("Images", {});
  // }

  return resolvedFormData;
};

export const convertEmailSignatureEntityToForm = (entity) => {
  const {
    Id,
    Companies: Company,
    Departments,
    Teams,
    Users,
    Status,
    LogoLink: LogoUrl,
    BannerLink: BannerUrl,
    Type,
    Signature,
    Name,
    LogoImage,
    BannerImage,
    IgnoredUsers: ExcludedUsers
  } = entity;

  return {
    Id,
    Company,
    Departments,
    Teams,
    Users,
    Status,
    LogoUrl,
    LogoImage,
    Logo: LogoImage,
    BannerImage,
    Banner: BannerImage,
    BannerUrl,
    Reception: Type === 3 || Type === 1 ? true : false,
    Answer: Type === 3 || Type === 2 ? true : false,
    Signature,
    Name,
    ExcludedUsers
  };
};

export const validateEmailSignatureForm = (
  { Name, Signature, Status, Logo, LogoUrl, Banner, BannerUrl },
  addError
) => {
  if (!Name || Name.length === 0) addError("Name");
  if (!Signature || Signature.length === 0) addError("Signature");
  if (typeof Status !== "number") addError("Status");
  if (LogoUrl?.length > 0 && !Logo) addError("Logo");
  if (BannerUrl?.length > 0 && !Banner) addError("Banner");
};

export const EmailSignatureGridTypeChanger = ({ entity, name }) => {
  const { Id, Type, CanEdit } = entity;

  const [checkState, setCheckState] = useState(() => {
    if (name === "Answer") {
      return Type === 3 || Type === 2 ? true : false;
    } else {
      return Type === 3 || Type === 1 ? true : false;
    }
  });
  const intl = useIntl();
  const createToast = useToast();
  const [post, { loading }] = useSpacePost(
    `EmailSignatures/${Id}/Type`,
    spaceEmailSignatureSchema,
    {
      onSuccess: () => {
        createToast({
          pos: "tm",
          type: "success",
          title: intl.formatMessage({ id: "EMAIL_SIGNATURE" }) + ` ${Id}`,
          description: intl.formatMessage({ id: "SUCCESSFULLY_RECORDED" })
        });
      }
    }
  );

  const handleCheckToggle = useCallback(() => {
    let newType;

    if (name === "Answer" && Type === 3) {
      newType = 1;
    } else if (name === "Answer" && Type === 2) {
      newType = 0;
    } else if (name === "Answer" && Type === 1) {
      newType = 3;
    } else if (name === "Answer" && Type === 0) {
      newType = 2;
    } else if (Type === 3) {
      newType = 2;
    } else if (Type === 1) {
      newType = 0;
    } else if (Type === 0) {
      newType = 1;
    } else if (Type === 2) {
      newType = 3;
    }

    post({
      Type: newType
    });
    setCheckState(!checkState);
  }, [Type, checkState, name, post]);

  return (
    <Switch
      className="fs-18"
      disabled={loading || !CanEdit}
      onChange={handleCheckToggle}
      checked={checkState}
    />
  );
};

export const EmailSignatureStatusChanger = ({ data }) => {
  const { Id, CanEdit, Status } = data;
  const intl = useIntl();
  const createToast = useToast();
  const [post, { loading }] = useSpacePost(
    `EmailSignatures/${Id}/Status`,
    spaceEmailSignatureSchema,
    {
      onSuccess: () => {
        createToast({
          pos: "tm",
          type: "success",
          title: intl.formatMessage({ id: "EMAIL_SIGNATURE" }) + ` ${Id}`,
          description: intl.formatMessage({ id: "STATUS_CHANGED_SUCCESS" })
        });
      }
    }
  );

  const handleStatusChange = (v) => {
    post({ Status: v });
  };

  return (
    <ContactStatusDropdown
      preview={!CanEdit}
      disabled={loading}
      arrowClassnames={"text-primary ml-2 fs-14"}
      onChange={handleStatusChange}
      className="fs-12 text-truncate"
      value={Status}
    />
  );
};

export const EmailSignatureEntityGridButtons = ({
  entity,
  skeleton,
  minimal,
  hideBreadCrumbs
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [signature, setSignature] = useState();

  const createToast = useToast();
  const [post, { loading }] = useSpacePost(
    `EmailSignatures/${entity.Id}/SendPreview`,
    null,
    {
      onSuccess: () => {
        createToast({
          pos: "tm",
          type: "success",
          description: intl.formatMessage({ id: "TEST_EMAIL_SEND_SUCCESS" })
        });
        toggleOpenState();
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );
  const intl = useIntl();
  const handleSignaureChange = (e) => {
    const { value } = e.target;
    setSignature(value);
  };

  const submit = () => {
    const { data } = signature;
    const connectedSignature = getEntity(spaceSignatureAggregationSchema, data);
    const { Company, Department, Team, SignatureId, User } =
      connectedSignature || {};

    post({
      Id: SignatureId,
      OrganizationId: Company || Department || Team,
      AccountId: User
    });
  };

  const toggleOpenState = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div
      className="d-flex align-items-center"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <EmailSignatureFormButton
        minimal={minimal}
        id={entity.Id}
        canEdit={entity.CanEdit}
        skeleton={skeleton}
        className="mr-2 FormButtonClassID"
      />
      <Modal onClose={toggleOpenState} isOpen={Boolean(isOpen)}>
        <div style={{ width: 400 }} className="p-30 bg-white">
          <div className="text-black fs-16 fw-bold mb-3">
            <FormattedMessage id="TEST_EMAIL_SIGNATURE_DESCRIPTION" />
          </div>
          <FormInput
            value={signature}
            textId={"APPLY_TO"}
            onChange={handleSignaureChange}
            className="mb-3"
            inputType={EmailSignaturesTypesDropdown}
            name="ReplySignatureModel"
            type={3}
            id={entity.Id}
          />
          <div className="d-flex justify-content-end">
            <Button disabled={loading} onClick={submit}>
              <FormattedMessage id="SEND" />
            </Button>
          </div>
        </div>
      </Modal>
      <EntityPopup minimal={minimal}>
        <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
        <ToggleButtonTest toggleOpenState={toggleOpenState} />
        {entity.CanEdit && (
          <EmailSignatureFormButton
            id={entity.Id}
            canEdit={entity.CanEdit}
            contextIcons
          />
        )}
        <EmailSignatureFormButton id={entity.Id} duplicate />
        <DeleteButtonContextMenu entity={entity} />
      </EntityPopup>
    </div>
  );
};

const ToggleButtonTest = ({ toggleOpenState }) => {
  const closePopup = useContext(EntityPopupContext);

  return (
    <Button
      propStyle={{ borderRadius: 5 }}
      onClick={() => {
        toggleOpenState();
        closePopup();
      }}
      className="d-flex"
      vType="link"
    >
      <div className="ar-form-button-link-icon">
        <FontAwesomeIcon icon={faEnvelope} />
      </div>
      <span className="ml-2 pl-1 fw-regular fs-14">
        <FormattedMessage id="TEST_EMAIL_SIGNATURE" />
      </span>
    </Button>
  );
};

export const EmailSignaturesEntityGridChildren = ({
  entity,
  minimal,
  sidebar,
  skeleton
}) => {
  return (
    <>
      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      {!sidebar && entity.CanEdit && (
        <EmailSignatureFormButton
          id={entity.Id}
          canEdit={entity.CanEdit}
          contextIcons
        />
      )}
      <EmailSignatureFormButton id={entity.Id} duplicate />
      <DeleteButtonContextMenu entity={entity} />
    </>
  );
};

export const ConnectedCompanyEmailSignature = ({ item }) => {
  const resolvedItem = useEmailSignatureChoice(item);

  const { Company, Name } = resolvedItem || {};
  const ResovledCompany = useOrganization(Company);
  if (!resolvedItem) return null;

  return (
    <div className="d-flex px-2 align-items-center">
      <AccountProfileImg size="mds" circular account={ResovledCompany} />
      <div className="d-flex ml-2 flex-column">
        <div className="text-black  text-truncate fs-14">{Name}</div>
        <div className="text-grey-light  text-truncate fs-12">
          {ResovledCompany.Name}
        </div>
      </div>
    </div>
  );
};

export const ConnectedDepartmentEmailSignature = ({ item }) => {
  const resolvedItem = useEmailSignatureChoice(item);
  const { Department, Name } = resolvedItem;
  const department = useDepartment(Department);
  return (
    <div className="d-flex px-2 align-items-center">
      <AccountProfileImg size="mds" circular account={department} />
      <div className="d-flex ml-2 flex-column">
        <div className="text-black  text-truncate fs-14">{Name}</div>
        <div className="text-grey-light  text-truncate fs-12">
          {department.Name}
        </div>
      </div>
    </div>
  );
};

export const ConnectedTeamEmailSignature = ({ item }) => {
  const resolvedItem = useEmailSignatureChoice(item);
  const { Team, Name } = resolvedItem || {};
  const ResolvedTeam = useTeam(Team) || {};
  return (
    <div className="d-flex px-2 align-items-center">
      <AccountProfileImg size="mds" circular account={ResolvedTeam} />
      <div className="d-flex ml-2 flex-column">
        <div className="text-black fs-14  text-truncate">{Name}</div>
        <div className="text-grey-light  text-truncate fs-12">
          {ResolvedTeam.Name}
        </div>
      </div>
    </div>
  );
};
export const ConnectedUserEmailSignature = ({ item }) => {
  const resolvedItem = useEmailSignatureChoice(item);
  const { Name } = resolvedItem;
  return (
    <div className="d-flex px-2 align-items-center">
      <div
        className={
          "img mds circular mr-2 text-color-link text-truncate account"
        }
      >
        <FontAwesomeIcon icon={faSignature} />
      </div>
      <div className="d-flex flex-column">
        <div className="text-black text-truncate fs-14">{Name}</div>
      </div>
    </div>
  );
};
