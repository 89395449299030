import React, { useMemo, useRef } from "react";
import {
  useServerAwareState,
  useGridRequest,
  useDetailAwareParams
} from "../Components/CGrid/ServerGrid";
import { buildODataQuery } from "./ODataHelper";
import { useSpaceQuery, useQuery } from "./IOClient";
import { OriginChildren } from "../Containers/Origin/OriginChildren/OriginChildren";
import LoaderSpinner from "../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useSpace } from "../Contexts/SpaceContext";
import { GridDateComponent } from "../Components/CGrid/CGrid";
import {
  HoursBetweenDates,
  checkIfBeginDateIsInFuture,
  checkIfEndDateIsInFuture
} from "./MiscHelper";
import { useHoverEvents } from "../Containers/Origin/Origin";
import Popup from "../Components/Popup/Popup";
// import { ConnectedPipelineListItem } from "../Containers/AdvancedMultiInputs/PipelineDropdowns";

export const useDetailsServerAwareParams = () => {
  const { params, moduleType } = useServerAwareState();
  return useDetailAwareParams(params, moduleType);
};

export const TotalsContext = React.createContext();

export const buildGridTotalsContext = (builder) => {
  const TotalsProvider = ({ children }) => {
    const { params, moduleType } = useServerAwareState();
    const queryParams = useDetailAwareParams(params, moduleType);
    const sumEndpoint = useMemo(() => {
      const query = buildODataQuery(queryParams, true);

      const sumEndpoint = builder(query);

      return sumEndpoint;
    }, [queryParams]);

    const resolvedEndpoint = sumEndpoint;

    const r = useSpaceQuery(resolvedEndpoint);

    return (
      <TotalsContext.Provider value={r}>{children}</TotalsContext.Provider>
    );
  };

  return [TotalsContext, TotalsProvider];
};

export const GetOriginChildren = ({ schema, id }) => {
  const space = useSpace();
  const { data, loading, error } = useQuery(
    `${schema.getEndpoint(
      space.Id
    )}/${id}/Children?$inlinecount=allpages&$top=10`
  );

  const anchorRef = useRef();
  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  const items = useMemo(() => {
    if (!data) return;
    return [...data.d.results.reverse()];
  }, [data]);

  if (loading || error) return <LoaderSpinner size="xxs" />;
  else {
    if (!data) {
      return <div>―</div>;
    }
    return data.d.__count > 0 ? (
      <>
        {/* <OriginChildren items={items} /> */}
        <span
          ref={anchorRef}
          onMouseOver={onAnchorMouseEnter}
          onMouseOut={onAnchorMouseLeave}
        >
          {data.d.results.length} de {data.d.__count}
        </span>

        <Popup
          anchorEl={anchorRef.current}
          modifiers={{
            preventOverflow: { boundariesElement: "viewport" }
          }}
          isOpen={isOpen}
          onMouseEnter={onPopupMouseEnter}
          onMouseLeave={onPopupMouseLeave}
        >
          <div
            className="bg-white text-white px-3 py-2 rounded mw-290px"
            style={{ cursor: "context-menu" }}
          >
            <OriginChildren items={items} />
          </div>
        </Popup>
      </>
    ) : (
      <div>―</div>
    );
  }
};

export const GridColloredEndDateComponent = ({ Data }) => {
  if (!checkIfBeginDateIsInFuture(Data.BeginDate)) {
    if (checkIfEndDateIsInFuture(Data.EndDate)) {
      const hoursBetween = HoursBetweenDates(Data.EndDate);

      if (hoursBetween >= 0 && hoursBetween <= 24)
        return (
          <GridDateComponent
            className="px-2 py-1 rounded text-truncate"
            style={{
              color: "#FF5962",
              background: "rgba(255, 89, 98, 0.1)",
              fontWeight: "bold"
            }}
            date={new Date(Data.EndDate)}
          />
        );
      else if (hoursBetween <= 48 && hoursBetween > 24)
        return (
          <GridDateComponent
            className="px-2 py-1 rounded text-truncate"
            style={{
              color: "#FF8066",
              background: "rgba(255, 128, 102, 0.1)",
              fontWeight: "bold"
            }}
            date={new Date(Data.EndDate)}
          />
        );
      else
        return (
          <GridDateComponent
            className="px-2 py-1 rounded text-truncate"
            style={{ background: "rgba(30, 114, 240, 0.1)", color: "#002b63" }}
            date={new Date(Data.EndDate)}
          />
        );
    } else
      return (
        <GridDateComponent
          className="px-2 py-1 rounded text-truncate"
          style={{ color: "#657B94", background: "rgba(101, 123, 148, 0.1)" }}
          date={new Date(Data.EndDate)}
        />
      );
  } else {
    return (
      <GridDateComponent
        style={{ background: "rgba(30, 114, 240, 0.1)", color: "#002b63" }}
        className="px-2 py-1 rounded text-truncate"
        date={new Date(Data.EndDate)}
      />
    );
  }
};

export const LabelTitle = ({ title, ...props }) => (
  <div className="label-title">{title}</div>
);

export const getBoardStyle = (start, end) => {
  if (!checkIfBeginDateIsInFuture(`${start}`)) {
    if (checkIfEndDateIsInFuture(`${end}`)) {
      const hoursBetween = HoursBetweenDates(`${end}`);

      if (hoursBetween >= 0 && hoursBetween <= 24)
        return {
          textTransform: "Capitalize",
          color: "#FF5962",
          background: "rgba(255, 89, 98, 0.1)",
          fontWeight: "bold"
        };
      else if (hoursBetween <= 48 && hoursBetween > 24)
        return {
          textTransform: "Capitalize",
          color: "#FF8066",
          background: "rgba(255, 128, 102, 0.1)",
          fontWeight: "bold"
        };
      else
        return {
          textTransform: "Capitalize",
          background: "rgba(30, 114, 240, 0.1)",
          color: "#002b63"
        };
    } else
      return {
        textTransform: "Capitalize",
        color: "#657B94",
        background: "rgba(101, 123, 148, 0.1)"
      };
  } else {
    return {
      textTransform: "Capitalize",
      background: "rgba(30, 114, 240, 0.1)",
      color: "#002b63"
    };
  }
};

export const CheckView = (target) => {
  const { params } = useServerAwareState();

  const { view } = params;

  return target === view;
};

export const useResolvedTotalsUrl = (url) => {
  // const { isLoading, hasFetched, error, results } = useGridRequest();

  // if (isLoading || error || !hasFetched || (results && results.length === 0))
  //   return null;
  return url;
};
