import React from "react";
import { useIntervention } from "../../Hooks/EntityHooks";
import KeyedDropdown from "../Dropdown/KeyedDropdown";
import { FormattedMessage } from "react-intl";

import { createPdfButton } from "../Pdf/PdfHelper";
import { moduleTypes } from "../../Helpers/ModulesHelper";

export const ConnectInterventionEntity = Component => {
  return ({ intervention, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const c = useIntervention(intervention);
    return <Component intervention={c} {...rest} />;
  };
};

export const InterventionPlaceTypes = {
  onSite: 1,
  remote: 2
};

export const InterventionPlaceTypesProfile = ({ item, value, className }) => {
  let text;

  switch (item || value) {
    case 1:
      text = <FormattedMessage id="ON_SITE" />;
      break;

    case 2:
      text = <FormattedMessage id="REMOTE" />;
      break;

    default:
      break;
  }
  return <span className={className}>{text}</span>;
};

export const InterventionPlaceTypeDropdown = props => {
  return (
    <KeyedDropdown
      options={[InterventionPlaceTypes.onSite, InterventionPlaceTypes.remote]}
      valueComponent={InterventionPlaceTypesProfile}
      closeOnSelect
      {...props}
    />
  );
};

export const InterventionPdfButton = createPdfButton({
  ///BuildPdf/{timechargeid}/
  generateUrl: ({ id }) => `PDF/ExportInterventions/ToFile`,
  //   generateUrl: ({ id }) => `interventions/Export/BuildPdf/${id}/`,
  download: true,
  //   buildUrl: ({ id, token }) => `interventions/Export/BuildPdf/${id}/${token}`,
  chooseDate: false,
  moduleType: moduleTypes.interventions
});
