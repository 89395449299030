import React, { useMemo } from "react";
import {
  ProjectFormButton,
  TaskFormButton,
  TicketFormButton,
  InterventionFormButton,
  CallFormButton
} from "../Form/FormButtons";

import { ProjectPdfButton } from "../../Components/Projects/Projects";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import BreadcrumbsPopup from "../../Components/Breadcrumbs/BreadcrumbsPopup";
import { originTypes } from "../../Helpers/MiscHelper";
import { getEntity } from "../../Helpers/IOClient";
// import { BarLoader } from "../../Components/GlobalLoader/GlobalLoader";
import {
  spacePipelineStatusSchema,
  spaceProjectsSchema
} from "../../config/schema";
import { moduleTypes, canUserEditModule } from "../../Helpers/ModulesHelper";
import { LabelTitle } from "../../Helpers/GridHelper";
import { DetailEntityButtons } from "../../Components/DetailEntityButtons/DetailEntityButtons";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { EntitySumaryCommunicator } from "../../Components/EntitySummaries/EntitySummariesHelper";
import { ProjectsClipboardButton } from "../../Helpers/HooksHelper";
import EntityReadButton from "../../Components/EntityRead/EntityReadButton";
import {
  Communicator,
  createCommunicatorHook
} from "../../Helpers/Communicator";
import { DeleteButtonContextMenu } from "../../Components/CGrid/DeleteMultipleModal";
import { ProjectStatusTypes } from "./ProjectsStatusHelper";
import { ColorFilterBars } from "../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { TimeTrackingGridFormButton } from "../TimeTracking/TimeTracking";
import { getInterventionTarget } from "../../Helpers/FormFormmaterHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignJustify,
  faCalendarAlt,
  faHashtag,
  faSquareParking,
  faPercentage,
  faIdCardClip
} from "@fortawesome/pro-light-svg-icons";
import {
  createDepartmentFilter,
  createFollowingFilter,
  createIndirectClientFilter,
  createNotFollowingFilter,
  createOrganizationFilter,
  createOriginFilter,
  createPipelineStatusFilter,
  createProjectStatusFilter,
  createProjectTypeFilter,
  createReadFilter,
  createTeamFilter,
  createUnreadFilter,
  createUserFilter,
  createUpgradedPipelineStatusFilter,
  createProjectTagFilter
} from "../../Helpers/FilterHelper";
import { ConnectedAccountProfile } from "../../Components/Accounts/AccountProfile";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";
import { ProjectTargetDropdown } from "../AdvancedMultiInputs";

export const openProjectForm = (selectedDate, aditionalProps) => {
  const BeginDate = new Date(selectedDate);
  BeginDate.setHours(9);
  const EndDate = new Date(BeginDate);
  EndDate.setHours(10);

  EntitySumaryCommunicator.dispatch({
    type: moduleTypes.projects,
    props: {
      baseForm: {
        BeginDate,
        EndDate,
        ...aditionalProps
      }
    }
  });
};

const projectPipelineSettings = {
  field: "Project/PipelineId",
  pipelineStatusField: "Project/PipelineStatusId",
  type: PipelineTypeEnum.Project
};

export const useProjectFilters = () => {
  const intl = useIntl();
  return useMemo(() => {
    return [
      {
        icon: <FontAwesomeIcon icon={faAlignJustify} />,
        titleId: "NAME",
        field: "Project/Name",
        type: "string",
        propField: "Name"
      },
      // { title: <FormattedMessage id={"NAME"} />, field: "Name", type: "string" },
      // { title: "Nome cliente", field: "Client/Name", type: "string" },
      // { title: "Nº cliente", field: "Client/Id", type: "string" },
      // { title: "Responsável", field: "Manager/Name", type: "string" },
      createIndirectClientFilter({ field: "DirectIndirectClient/Id" }),
      createUserFilter({
        propField: "Creator",
        titleId: "CREATED_BY",
        field: "Creator/Id",

        DescriptionComponent: ({ item, value }) => {
          return (
            <div>
              <FormattedMessage id={"CREATED_BY"} />:{" "}
              <ConnectedAccountProfile account={value} />
            </div>
          );
        }
      }),
      {
        propField: "EndDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "END_DATE",
        field: "Project/EndDate",
        type: "dateTime"
      },
      {
        propField: "CreationDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "CREATION_DATE",
        field: "History/CreationDateNoHours",
        type: "dateTime"
      },
      {
        propField: "PipelineDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_PIPELINE_STATUS_DATE",
        field: "Project/PipelineDate",
        type: "dateTime"
      },
      {
        propField: "StatusDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_STATUS_DATE",
        field: "Project/StatusDate",
        type: "dateTime"
      },
      {
        propField: "BeginDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "START_DATE",
        field: "Project/BeginDate",
        type: "dateTime"
      },
      {
        icon: <FontAwesomeIcon icon={faIdCardClip} />,
        titleId: "CODEERP",
        field: "Project/CodeERP",
        type: "string"
      },
      {
        icon: <FontAwesomeIcon icon={faPercentage} />,
        titleId: "PERCENTAGE_PERFORMANCE",
        field: "Project/Timeleft",
        type: "number"
      },

      //   createProjectTypeFilter(),
      createDepartmentFilter(),
      createProjectTagFilter({ requiresParams: true }),
      createOrganizationFilter({ field: "Company/Id", propField: "Company" }),
      createTeamFilter(),
      createProjectStatusFilter({
        propField: "Name",
        field: "PipelineStatus/Status",
        defaultFilters: [
          {
            field: "PipelineStatus/Status",
            operator: "eq",
            value: 1
          }
        ]
      }),
      // createPipelineStatusFilter({
      //   pipelineSettings: projectPipelineSettings
      // }),
      createUpgradedPipelineStatusFilter({
        pipelineSettings: projectPipelineSettings
      }),
      // {
      //   icon: <FontAwesomeIcon icon={faPercentage} />,
      //   titleId: "PERCENTAGE_PERFORMANCE",
      //   field: "Project/Timeleft",
      //   type: "number"
      // },
      // {
      //   icon: <FontAwesomeIcon icon={faClock} />,
      //   titleId: "SPENT_HOURS",
      //   field: "Project/SpentHours",
      //   type: "number",
      //   // DescriptionComponent: ({ item, allData, value }) => {
      //   //   return (
      //   //     <div>
      //   //       <FormattedMessage id={"SPENT_HOURS"} />{" "}
      //   //       <FormattedMessage
      //   //         id={messages.filters.default[allData.operator]}
      //   //       />
      //   //       {` ${MilSecToHour(value)}`}
      //   //       {/* <ConnectedOrganizationProfile noManager organization={value} /> */}
      //   //     </div>
      //   //   );
      //   // },
      //   // resolveURLOnly: true,
      //   getResolvedAPIValue: (value) => HourToMilSec(value)
      // },
      createReadFilter({
        baseField: "Project"
      }),
      {
        propField: "Id",
        icon: <FontAwesomeIcon icon={faSquareParking} />,
        title: intl.formatMessage({ id: "PROJECT_NR" }),
        field: "Project/Id",
        type: "number"
      },
      createUnreadFilter({
        baseField: "Project"
      }),
      createFollowingFilter({
        baseField: "Project"
      }),
      createNotFollowingFilter({
        baseField: "Project"
      }),
      createOriginFilter({
        baseFieldName: "Project",
        dropdownType: ProjectTargetDropdown
      }),
      {
        propField: "SalesOpportunity",
        icon: <FontAwesomeIcon icon={faHashtag} />,
        title: "OPV",
        field: "Project/SalesOpportunity",
        type: "string"
      },
      createProjectTypeFilter({
        icon: <FontAwesomeIcon icon={faSquareParking} />
      }),
      createUserFilter({
        propField: "Manager",
        titleId: "MANAGER",
        field: "Assigned/Id",
        DescriptionComponent: ({ item, value }) => {
          return (
            <div>
              <FormattedMessage id={"MANAGER"} />:{" "}
              <ConnectedAccountProfile maxWidth account={value} />
            </div>
          );
        }
      }),
      createUserFilter({
        propField: "Collaborator",
        titleId: "COLLABORATOR",
        field: "Collaborator/Id",
        DescriptionComponent: ({ item, value }) => {
          return (
            <div>
              <FormattedMessage id={"COLLABORATOR"} />:{" "}
              <ConnectedAccountProfile maxWidth account={value} />
            </div>
          );
        }
      })
    ];
  }, [intl]);
};

export const defaultProjectForm = {
  SalesOpportunity: "",
  ReceivedDate: new Date(),
  Contact: null,
  ProjectTarget: null,
  Manager: null,
  Contract: null,
  Departments: [],
  TeamCollab: [],
  Followers: [],
  Name: "",
  CodeERP: "",
  // Category: null,
  BeginDate: moment().toDate(),
  EndDate: moment().add(1, "d").toDate(),
  EstimatedCost: 0,
  ComplementaryTime: 0,
  Description: "",
  InternalDescription: "",
  Files: [],
  Status: null,
  Image: null,
  StatusDetailDescription: ""
};

export const validateProjectForm = (
  {
    ProjectTarget,
    Contact,
    ReceivedDate,
    Pipeline,
    PipelineStatus,
    ProjectType,
    Manager,
    Name,
    BeginDate,
    EndDate,
    Company,
    Description,
    InternalDescription,
    StatusDetail,
    StatusDetailId
  },
  addError
) => {
  const Status = getEntity(spacePipelineStatusSchema, PipelineStatus);
  if (!Company) addError("Company");
  if (!ProjectTarget) addError("ProjectTarget");
  // if (!Contact) addError("Contact");
  if (!ReceivedDate) addError("ReceivedDate");
  if (!Pipeline) addError("Pipeline");
  if (!PipelineStatus) addError("PipelineStatus");
  if (
    Status &&
    Status.Status === ProjectStatusTypes.canceled &&
    !StatusDetailId &&
    !StatusDetail
  )
    addError("StatusDetailId");
  if (!Name) addError("Name");
  if (!ProjectType) addError("ProjectType");
  if (!BeginDate) addError("BeginDate");
  if (!EndDate) addError("EndDate");
  if (BeginDate && EndDate && EndDate < BeginDate)
    addError(
      "EndDate",
      <FormattedMessage id="FORM_END_DATA_BIGGER_THAN_START_DATA" />
    );
  if (!Manager) addError("Manager");
};

// export const ProjectTypeDropdown = () => {
//   const { data, error, loading } = useSpaceQuery(
//     "/Query/ProjectTypes",
//     spaceProjectCategorySchema
//   );

//   if (!loading && !error) {

//   } else {
//     return <BarLoader isLoading={loading || error} />;
//   }
// };

export const convertProjectEntityToForm = (entity) => {
  const { Collaborators, Deal, Client, Tags, ...entityRest } = entity;
  const { Accounts, Teams } = Collaborators || {};

  const form = { ...entityRest, ProjectTarget: getInterventionTarget(entity) };

  if (Accounts || Teams) {
    let data = [];

    if (Accounts && Array.isArray(Accounts)) {
      for (let i = 0; i < Accounts.length; i++) {
        if (Accounts[i].type === "Account" && Accounts[i].data)
          data.push(Accounts[i]);
        else data.push({ type: "Account", data: Accounts[i] });
      }
    }
    if (Teams && Array.isArray(Teams)) {
      for (let i = 0; i < Teams.length; i++) {
        if (Teams[i].type === "Team" && Teams[i].data) data.push(Teams[i]);
        else data.push({ type: "Team", data: Teams[i] });
      }
    }
    form.TeamCollab = data;
  }

  if (Array.isArray(Tags) && Tags.length > 0) {
    const resolvedTags = [];
    for (const t of Tags) {
      resolvedTags.push({
        data: t,
        type: "ProjectTag"
      });
    }
    form.Tags = resolvedTags;
  }

  return form;
};

export const ProjectEntityButtons = ({ entity, skeleton, onSuccess }) => {
  const isPositiveStatus =
    entity.Status !== ProjectStatusTypes.canceled &&
    entity.Status !== ProjectStatusTypes.finished;
  return (
    <DetailEntityButtons>
      <ProjectFormButton
        id={entity.Id}
        canEdit={entity.CanEdit}
        skeleton={skeleton}
        onSuccess={onSuccess}
      />
      {isPositiveStatus && (
        <CallFormButton
          skeleton={skeleton}
          alt
          formProps={{ projectId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}

      {isPositiveStatus && (
        <TicketFormButton
          skeleton={skeleton}
          alt
          formProps={{ projectId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <TaskFormButton
          skeleton={skeleton}
          alt
          formProps={{ projectId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <InterventionFormButton
          skeleton={skeleton}
          alt
          formProps={{ projectId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <ProjectFormButton
          id={entity.Id}
          duplicate
          skeleton={skeleton}
          alt
          onSuccess={onSuccess}
        />
      )}
      <ProjectPdfButton
        id={entity.Id}
        skeleton={skeleton}
        onSuccess={onSuccess}
      />
      <ProjectPdfButton id={entity.Id} skeleton={skeleton} excel={true} />
    </DetailEntityButtons>
  );
};

export const ProjectEntityActionChildren = ({
  entity,
  minimal,
  sidebar,
  detailsView,
  skeleton
}) => {
  const isPositiveStatus =
    entity.Status !== ProjectStatusTypes.canceled &&
    entity.Status !== ProjectStatusTypes.finished;
  const accountSpace = useCurrentAccountSpace();

  return (
    <>
      {!sidebar && !detailsView && (
        <ProjectFormButton contextIcons id={entity.Id} />
      )}
      {!detailsView && <ProjectFormButton globalEntityView id={entity.Id} />}

      {(canUserEditModule(accountSpace, moduleTypes.calls) ||
        canUserEditModule(accountSpace, moduleTypes.tickets) ||
        canUserEditModule(accountSpace, moduleTypes.tasks) ||
        canUserEditModule(accountSpace, moduleTypes.interventions)) &&
        isPositiveStatus && (
          <LabelTitle title={<FormattedMessage id={"SERVICES"} />} />
        )}
      {canUserEditModule(accountSpace, moduleTypes.calls) && (
        <CallFormButton
          hasAccess={isPositiveStatus}
          formProps={{ projectId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.tickets) && (
        <TicketFormButton
          hasAccess={isPositiveStatus}
          formProps={{ projectId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.tasks) && (
        <TaskFormButton
          hasAccess={isPositiveStatus}
          formProps={{ projectId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.interventions) && (
        <InterventionFormButton
          hasAccess={isPositiveStatus}
          formProps={{ projectId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.interventions) && (
        <TimeTrackingGridFormButton
          origin={{
            type: "Project",
            data: entity.Id
          }}
        />
      )}

      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      {!sidebar && entity.CanEdit && (
        <ProjectFormButton
          id={entity.Id}
          canEdit={entity.CanEdit}
          contextIcons
        />
      )}
      {entity.CanEdit && <ProjectFormButton id={entity.Id} duplicate />}
      <ProjectPdfButton id={entity.Id} skeleton={skeleton} />
      <ProjectPdfButton id={entity.Id} skeleton={skeleton} excel={true} />
      <ProjectsClipboardButton id={entity.Id} />
      <EntityReadButton value={entity} schema={spaceProjectsSchema} />
      <DeleteButtonContextMenu entity={entity} />
    </>
  );
};

export const ProjectEntityActionsButton = ({ minimal, ...props }) => {
  return (
    <EntityPopup minimal={minimal}>
      <ProjectEntityActionChildren minimal={minimal} {...props} />
    </EntityPopup>
  );
};

export const ProjectEntityGridButtons = ({
  entity,
  skeleton,
  hideBreadCrumbs,
  minimal,
  noColors
}) => {
  return (
    <React.Fragment>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="d-flex align-items-center"
      >
        {!hideBreadCrumbs && (
          <BreadcrumbsPopup
            className="mr-2 fs-14 d-inline-flex text-primary"
            originType={originTypes.project}
            minimal
            id={entity.Id}
          />
        )}
        <ProjectFormButton
          minimal={minimal}
          id={entity.Id}
          canEdit={entity.CanEdit}
          skeleton={skeleton}
          className="mr-2 FormButtonClassID"
        />
        <ProjectEntityActionsButton
          entity={entity}
          minimal={minimal}
          skeleton={skeleton}
        />
        {!noColors && <ColorFilterBars entity={entity} />}
      </div>
    </React.Fragment>
  );
};

export const projectCommunicator = new Communicator();

export const useProjectCommunicator =
  createCommunicatorHook(projectCommunicator);
