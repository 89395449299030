import React, { useMemo } from "react";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { originTypes } from "../../Helpers/MiscHelper";
import {
  DealFormButton,
  CallFormButton,
  ContractFormButton,
  ProjectFormButton,
  TicketFormButton,
  TaskFormButton,
  InterventionFormButton,
  EmailConversationButton
} from "../Form/FormButtons";
import {
  spacePipelineStatusSchema,
  spaceDealsSchema
} from "../../config/schema";
import { getEntity } from "../../Helpers/IOClient";
import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import { LabelTitle } from "../../Helpers/GridHelper";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import {
  moduleTypes,
  canUserEditModule,
  canUserReadModule
} from "../../Helpers/ModulesHelper";
import { DealClipboardButton } from "../../Helpers/HooksHelper";
import EntityReadButton from "../../Components/EntityRead/EntityReadButton";
import BreadcrumbsPopup from "../../Components/Breadcrumbs/BreadcrumbsPopup";
import {
  Communicator,
  createCommunicatorHook
} from "../../Helpers/Communicator";
import { DeleteButtonContextMenu } from "../../Components/CGrid/DeleteMultipleModal";
import { ColorFilterBars } from "../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { TimeTrackingGridFormButton } from "../TimeTracking/TimeTracking";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignJustify,
  faCalendarAlt,
  faHandshake,
  faHashtag,
  faMoneyBill
} from "@fortawesome/pro-light-svg-icons";
import {
  createClientFilter,
  createDealStatusFilter,
  createDepartmentFilter,
  createFollowingFilter,
  createNotFollowingFilter,
  createOrganizationFilter,
  createPipelineStatusFilter,
  createReadFilter,
  createTeamFilter,
  createUnreadFilter,
  createUserFilter,
  createUpgradedPipelineStatusFilter,
  createTicketTagFilter,
  createDealTagFilter
} from "../../Helpers/FilterHelper";
import { ConnectedAccountProfile } from "../../Components/Accounts/AccountProfile";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";

const dealPipelineSettings = {
  field: "Deal/PipelineId",
  pipelineStatusField: "Deal/PipelineStatusId",
  type: PipelineTypeEnum.Deal
};

export const useDealFilters = () => {
  return useMemo(() => {
    return [
      {
        propField: "Name",
        icon: <FontAwesomeIcon icon={faAlignJustify} />,
        titleId: "NAME",
        field: "Deal/Name",
        type: "string"
      },
      createUserFilter({
        propField: "Assigned",
        titleId: "ASSIGNED_TO",
        field: "Assigned/Id"
      }),
      createClientFilter({ field: "Deal/ClientId" }),
      createUserFilter({
        propField: "Creator",
        titleId: "CREATED_BY",
        field: "Creator/Id",
        DescriptionComponent: ({ item, value }) => {
          return (
            <div>
              <FormattedMessage id={"CREATED_BY"} />{" "}
              <ConnectedAccountProfile account={value} />
            </div>
          );
        }
      }),

      {
        propField: "EndDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "END_DATE",
        field: "Deal/EndDate",
        type: "dateTime"
      },
      {
        propField: "BeginDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "START_DATE",
        field: "Deal/BeginDate",
        type: "dateTime"
      },
      {
        propField: "PipelineDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_PIPELINE_STATUS_DATE",
        field: "Deal/PipelineDate",
        type: "dateTime"
      },
      {
        propField: "StatusDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_STATUS_DATE",
        field: "Deal/StatusDate",
        type: "dateTime"
      },
      createDepartmentFilter(),
      createOrganizationFilter({ field: "Company/Id" }),
      createTeamFilter(),
      createDealStatusFilter(),
      // createPipelineStatusFilter({
      //   pipelineSettings: dealPipelineSettings
      // }),
      createUpgradedPipelineStatusFilter({
        pipelineSettings: dealPipelineSettings
      }),
      createReadFilter({
        baseField: "Deal"
      }),
      {
        propField: "Id",
        icon: <FontAwesomeIcon icon={faHandshake} />,
        title: "Nº",
        field: "Deal/Id",
        type: "number"
      },
      createUnreadFilter({
        baseField: "Deal"
      }),
      createDealTagFilter({ requiresParams: true }),
      createFollowingFilter({
        baseField: "Deal"
      }),
      createNotFollowingFilter({
        baseField: "Deal"
      }),
      {
        propField: "SalesOpportunity",
        icon: <FontAwesomeIcon icon={faHashtag} />,
        title: "OPV",
        field: "Deal/SalesOpportunity",
        type: "string"
      },

      createUserFilter({
        propField: "DealManager",
        titleId: "MANAGER",
        field: "DealManager/Id",
        DescriptionComponent: ({ item, value }) => {
          return (
            <div>
              <FormattedMessage id={"MANAGER_IS"} />{" "}
              <ConnectedAccountProfile account={value} />
            </div>
          );
        }
      }),
      {
        // getResolvedValue: (e) => {
        //   ;
        //   return e.Cost;
        // },
        propField: "EstimatedCost",
        getPropValue: (v) => v?.Cost,
        icon: <FontAwesomeIcon icon={faMoneyBill} />,
        titleId: "VALUE",
        field: "Currency/EstimatedCost",
        type: "number"
      }
    ];
  }, []);
};

export const defaultDealForm = ({ user, currencies } = {}) => {
  const EstimatedCostsDict = {};

  for (const currency of currencies) {
    EstimatedCostsDict[currency] = {
      Cost: 0,
      CurrencyId: currency
    };
  }

  return {
    Client: null,
    Name: "",
    Pipeline: null,
    PipelineStatus: null,
    BeginDate: new Date(),
    EndDate: moment().add(1, "h").toDate(),
    Contact: null,
    Departments: [],
    TeamCollab: [],
    Followers: [],
    Manager: null,
    DealManager: null,
    EstimatedCostsDict,
    Files: [],
    SalesOpportunity: "",
    Description: ""
  };
};

export const validateDealForm = (
  {
    Company,
    Name,
    Client,
    Pipeline,
    PipelineStatus,
    BeginDate,
    EndDate,
    Values,
    Contact,
    Assigned,
    DealTarget,
    Description,
    StatusDetailDescription,
    StatusDetailId,
    StatusDetail,
    DealManager
  },
  addError
) => {
  const Status = getEntity(spacePipelineStatusSchema, PipelineStatus);
  if (!Company) addError("Company");
  // if (!DealTarget) addError("DealTarget");
  if (!Client) addError("Client");
  // if (!Contact) addError("Contact");
  if (!Name) addError("Name");
  if (!Description) addError("Description");

  if (!BeginDate) addError("BeginDate");
  if (!EndDate) addError("EndDate");
  if (BeginDate && EndDate && EndDate < BeginDate)
    addError(
      "EndDate",
      <FormattedMessage id="FORM_END_DATA_BIGGER_THAN_START_DATA" />
    );
  if (!Pipeline) addError("Pipeline");
  if (!PipelineStatus) addError("PipelineStatus");
  if (
    Status &&
    (Status.Status === DealStatusTypes.canceled ||
      Status.Status === DealStatusTypes.lost) &&
    !StatusDetail
  )
    addError("StatusDetail");
  if (!DealManager) addError("DealManager");
  if (!Assigned) addError("Assigned");
};

export const DealEntityButtons = ({ entity, skeleton, minimal, onSuccess }) => {
  const isPositiveStatus =
    entity.Status !== DealStatusTypes.canceled &&
    entity.Status !== DealStatusTypes.lost;

  return (
    <React.Fragment>
      <DealFormButton
        minimal={minimal}
        id={entity.Id}
        onSuccess={onSuccess}
        canEdit={entity.CanEdit}
        skeleton={skeleton}
        className="mr-2"
      />

      {isPositiveStatus && (
        <ContractFormButton
          alt
          skeleton={skeleton}
          onSuccess={onSuccess}
          className="mr-2"
          formProps={{ dealId: entity.Id }}
        />
      )}
      {isPositiveStatus && (
        <CallFormButton
          alt
          skeleton={skeleton}
          onSuccess={onSuccess}
          className="mr-2"
          formProps={{ dealId: entity.Id }}
        />
      )}
      {isPositiveStatus && (
        <ProjectFormButton
          alt
          skeleton={skeleton}
          className="mr-2"
          formProps={{ dealId: entity.Id }}
        />
      )}
      {isPositiveStatus && (
        <TicketFormButton
          alt
          skeleton={skeleton}
          onSuccess={onSuccess}
          className="mr-2"
          formProps={{ dealId: entity.Id }}
        />
      )}
      {isPositiveStatus && (
        <TaskFormButton
          alt
          skeleton={skeleton}
          onSuccess={onSuccess}
          className="mr-2"
          formProps={{ dealId: entity.Id }}
        />
      )}
      {isPositiveStatus && (
        <InterventionFormButton
          alt
          skeleton={skeleton}
          className="mr-2"
          onSuccess={onSuccess}
          formProps={{ dealId: entity.Id }}
        />
      )}
    </React.Fragment>
  );
};

export const DealEntityActionChildren = ({
  entity,
  minimal,
  sidebar,
  detailsView,
  skeleton
}) => {
  const intl = useIntl();
  const accountSpace = useCurrentAccountSpace();
  const isPositiveStatus =
    entity.Status !== DealStatusTypes.canceled &&
    entity.Status !== DealStatusTypes.lost;
  return (
    <>
      {!sidebar && !detailsView && (
        <DealFormButton contextIcons id={entity.Id} />
      )}
      {!detailsView && <DealFormButton globalEntityView id={entity.Id} />}

      {(canUserEditModule(accountSpace, moduleTypes.calls) ||
        canUserEditModule(accountSpace, moduleTypes.tickets) ||
        canUserEditModule(accountSpace, moduleTypes.tasks) ||
        canUserEditModule(accountSpace, moduleTypes.interventions)) &&
        isPositiveStatus && (
          <LabelTitle title={intl.formatMessage({ id: "SERVICES" })} />
        )}

      {canUserEditModule(accountSpace, moduleTypes.calls) && (
        <CallFormButton
          hasAccess={isPositiveStatus}
          formProps={{ dealId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.projects) && (
        <ProjectFormButton
          hasAccess={isPositiveStatus}
          formProps={{ dealId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.tickets) && (
        <TicketFormButton
          hasAccess={isPositiveStatus}
          formProps={{ dealId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.tasks) && (
        <TaskFormButton
          hasAccess={isPositiveStatus}
          formProps={{ dealId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.interventions) && (
        <InterventionFormButton
          hasAccess={isPositiveStatus}
          formProps={{ dealId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.interventions) && (
        <TimeTrackingGridFormButton
          origin={{
            type: "Deal",
            data: entity.Id
          }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.contracts) &&
        isPositiveStatus && (
          <LabelTitle title={intl.formatMessage({ id: "COMMERCIAL" })} />
        )}
      {canUserEditModule(accountSpace, moduleTypes.contracts) && (
        <ContractFormButton
          hasAccess={isPositiveStatus}
          formProps={{ dealId: entity.Id }}
        />
      )}
      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      {!sidebar && entity.CanEdit && (
        <DealFormButton id={entity.Id} canEdit={entity.CanEdit} contextIcons />
      )}
      <DealFormButton className="mr-2" id={entity.Id} duplicate />
      <DealClipboardButton id={entity.Id} />
      {canUserReadModule(accountSpace, moduleTypes.emailConversations) && (
        <EmailConversationButton formProps={{ dealId: entity.Id }} />
      )}
      <EntityReadButton value={entity} schema={spaceDealsSchema} />
      <DeleteButtonContextMenu entity={entity} />
    </>
  );
};

export const DealEntityActionsButton = ({ minimal, ...props }) => {
  return (
    <EntityPopup minimal={minimal}>
      <DealEntityActionChildren minimal={minimal} {...props} />
    </EntityPopup>
  );
};

export const DealEntityGridButtons = ({
  entity,
  skeleton,
  minimal,
  hideBreadCrumbs,
  noColors
}) => {
  // const isPositiveStatus = entity.Status === DealStatusTypes.open;

  return (
    <div className="d-flex align-items-center">
      {!hideBreadCrumbs && (
        <BreadcrumbsPopup
          className="mr-2 fs-14 d-inline-flex text-primary"
          originType={originTypes.deals}
          minimal
          id={entity.Id}
        />
      )}
      <div
        className="d-flex align-items-center"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DealFormButton
          minimal={minimal}
          id={entity.Id}
          canEdit={entity.CanEdit}
          skeleton={skeleton}
          className="mr-2 FormButtonClassID"
        />
        <DealEntityActionsButton entity={entity} minimal={minimal} />
        {!noColors && <ColorFilterBars entity={entity} />}
      </div>
    </div>
  );
};

export const DealStatusTypesForFilter = {
  open: "1",
  canceled: "2",
  lost: "3",
  won: "4",
  draft: "5"
};

export const DealStatusTypes = {
  open: 1,
  canceled: 2,
  lost: 3,
  won: 4,
  draft: 5
};

export const createDealEstimatedCostDict = (EstimatedCosts, currencies) => {
  const EstimatedCostsDict = {};

  for (const val of EstimatedCosts) {
    EstimatedCostsDict[val.CurrencyId] = val;
  }

  for (const curr of currencies) {
    if (!EstimatedCostsDict[curr]) {
      EstimatedCostsDict[curr] = {
        Cost: 0,
        CurrencyId: curr
      };
    }
  }

  return EstimatedCostsDict;
};

export const dealCommunicator = new Communicator();

export const useTaskCommunicator = createCommunicatorHook(dealCommunicator);
