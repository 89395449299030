import React, { useRef, useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile, faImages } from "@fortawesome/pro-light-svg-icons";
import { useHoverEvents } from "../../Containers/Origin/Origin";
import classnames from "classnames";
import Popup from "../Popup/Popup";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import HTMLInput from "../HTMLInput/HTMLInput";
import { getEntity } from "../../Helpers/IOClient";
import { spaceSocialAccountSchema } from "../../config/schema";
import { getSocialIcon } from "../../Containers/SocialPosts/SocialPostsHelper";
import { SocialNetworkDict } from "../SocialPostSingleComponent/SocialPostSingleComponent";
const getTextLengthLimit = (Type) => {
  let TextLimits = 2200;

  switch (Type) {
    case 1:
      TextLimits = 2200;

      break;

    case 2:
      TextLimits = 2200;

      break;

    case 3:
      TextLimits = 280;

      break;

    case 4:
      TextLimits = 2200;

      break;

    case 5:
      TextLimits = 2200;

      break;

    case 6:
      TextLimits = 2200;

      break;

    case 7:
      TextLimits = 2200;

      break;

    default:
      TextLimits = 2200;

      break;
  }

  return TextLimits;
};

export const SocialPostsDescription = ({
  value,
  onChange,
  account,
  preview,
  TextError,
  toggleImages
}) => {
  const prefiltered_limits = [];
  if (account && Array.isArray(account)) {
    account.forEach((t) => {
      const { Type } = getEntity(spaceSocialAccountSchema, t);
      prefiltered_limits.push({ length: getTextLengthLimit(Type), Type: Type });
    });
  } else if (account) {
    const { Type } = getEntity(spaceSocialAccountSchema, account);
    prefiltered_limits.push({ length: getTextLengthLimit(Type), Type: Type });
  }
  const onlyUnique = (value, index, self) => {
    return (
      self.findIndex((e) => {
        return SocialNetworkDict[e.Type] === SocialNetworkDict[value.Type];
      }) === index
    );
  };
  const limits = prefiltered_limits.filter(onlyUnique);
  const Selection = useRef();

  const handleChange = (e) => {
    onChange(e.target.value);
  };
  const EmojiRef = useRef();
  const TextAreaRef = useRef();
  const { onPopupMouseLeave, onAnchorMouseEnter, onAnchorMouseLeave, isOpen } =
    useHoverEvents();

  const handleEmojiPick = useCallback(
    (event) => {
      TextAreaRef.current.focus();
      let SelectStart = null;
      let PreviousLength = TextAreaRef.current.value.length;
      if (
        TextAreaRef.current.selectionStart ||
        TextAreaRef.current.selectionStart === "0"
      ) {
        SelectStart = TextAreaRef.current.selectionStart;
        var startPos =
          TextAreaRef.current.selectionStart || Selection.current.index;
        var endPos = TextAreaRef.current.selectionEnd;
        TextAreaRef.current.value =
          TextAreaRef.current.value.substring(0, startPos) +
          event.native +
          TextAreaRef.current.value.substring(
            endPos,
            TextAreaRef.current.value.length
          );
      } else {
        TextAreaRef.current.value += event.native;
      }
      onChange(TextAreaRef.current.value);
      if (SelectStart !== null) {
        const diference = TextAreaRef.current.value.length - PreviousLength;
        TextAreaRef.current.selectionStart = SelectStart + diference;
        TextAreaRef.current.selectionEnd = SelectStart + diference;
      }
    },
    [onChange]
  );

  return (
    <div
      className={classnames("d-flex flex-column", {
        "border-danger-text-enable": TextError
      })}
    >
      <Popup
        anchorEl={EmojiRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        onMouseLeave={onPopupMouseLeave}
        placement="left"
        isOpen={isOpen}
      >
        <div className="disable-selection outline-0">
          <Picker
            showPreview={false}
            emojiSize={15}
            emoji=""
            title=""
            native={true}
            sheetSize={16}
            darkMode={false}
            onClick={handleEmojiPick}
          />
        </div>
      </Popup>
      <HTMLInput
        preview={preview}
        ref={TextAreaRef}
        onClick={onAnchorMouseLeave}
        className="m-0 br-bottom-0 fs-12 PostDescription TextInputPostsBorderDanger"
        value={value}
        onChange={handleChange}
      />
      {!preview && (
        <div
          ref={EmojiRef}
          className="border-left TextInputPostsBorderDangerBottomHalf rounded br-top-0 pl-2 border-right border-bottom d-flex justify-content-between"
        >
          <div className="d-flex align-items-center">
            <div
              onClick={onAnchorMouseEnter}
              className="bg-white mr-3 p-2 text-black cursor-pointer"
            >
              <FontAwesomeIcon icon={faSmile} />
            </div>
            <div
              onClick={toggleImages}
              className="qbg-white p-2 text-black cursor-pointer"
            >
              <FontAwesomeIcon icon={faImages} />
            </div>
          </div>
          <div className="align-items-center d-flex mr-3 fs-12 disable-selection">
            {limits.map((t, i) => {
              const { Icon } = getSocialIcon(t.Type, { width: 16 });
              return (
                <div key={i} className="align-items-center ml-2 d-flex">
                  <div className="d-flex align-items-center mr-2">{Icon}</div>
                  <span
                    className={
                      value
                        ? encodeURI(value).length > t.length
                          ? "text-danger-alt"
                          : "text-black"
                        : "text-black"
                    }
                  >{`${(value && encodeURI(value).length) || 0}`}</span>
                  <span className="text-black">{`/${t.length}`}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
