import React, { useMemo } from "react";

import AccountProfile from "../Accounts/AccountProfile";
import { useOrganization } from "../../Hooks/EntityHooks";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { spaceOrganizationSchema } from "../../config/schema";

const OrganizationProfile = ({ organization, ...rest }) => (
  <AccountProfile account={organization} {...rest} />
);

export default OrganizationProfile;

export const ConnectOrganizationEntity = (Component) => {
  const Comp = ({ organization, ...rest }) => {
    const o = useOrganization(organization);

    const url = useMemo(() => {
      if (o === null && organization) {
        return `query/organizations?query=${organization}`;
      } else return null;
    }, [o, organization]);

    const { loading, error } = useSpaceQuery(url, [spaceOrganizationSchema]);

    if (loading || error) return null;

    return <Component organization={o} {...rest} />;
  };
  return Comp;
};

export const ConnectedOrganizationProfile =
  ConnectOrganizationEntity(OrganizationProfile);
