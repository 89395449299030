import { Communicator } from "../../Helpers/Communicator";
import { many } from "../../Helpers/SchemaHelper";
import { accountSchema } from "../../config/schema";
import { useRef, useMemo } from "react";
import querystring from "query-string";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { usePrevious } from "../../Helpers/HooksHelper";
export const formTypes = {
  subscriptionTags: "subscriptionTags",
  ticketTags: "ticketType",
  clientTags: "clientTags",
  taskTags: "taskTags",
  projectTags: "projectTags",
  callTags: "callTags",
  contractTags: "contractTags",
  dealTags: "dealTag",
  documentType: "documentType",
  taskType: "taskType",
  socialPost: "socialPost",
  automation: "automation",
  timeTracking: "timeTracking",
  notes: "notes",
  organizations: "organizations",
  departments: "departments",
  ineficientTypes: "ineficientTypes",
  teams: "teams",
  contractTypes: "contractTypes",
  subscriptionTypes: "SubscriptionTypes",
  interventionType: "interventionType",
  clientTypes: "clientTypes",
  emailDomains: "emailDomains",
  apiTokens: "apiTokens",
  apiLogs: "apiLogs",
  projectCategory: "projectCategory",
  cancelationReason: "cancelationReason",
  socialAccount: "settingsSocial",
  lostReason: "LostReasons",
  dealsPipeline: "dealsPipeline",
  CallsPipeline: "callsPipeline",
  checklistTemplate: "checklistTemplate",
  widgets: "widgets",
  cupons: "cupons",
  spaces: "spaces",
  emailReception: "emailReception",
  emailSignature: "emailSignature",
  documents: "documents",
  docTemplates: "docTemplates",
  docTemplateFolders: "docTemplateFolders",
  docFolders: "docFolders",
  travelTimes: "travelTimes",
  settingsInboundEmails: "settingsInboundEmails"
};

export const FormCommunicator = new Communicator();

const managerVerifierResponseSchema = {
  Managers: many(accountSchema)
};
export const useManagerVerifier = (
  accountId,
  departmentTeam,
  { onUpdateManager }
) => {
  const urlRef = useRef();
  const firstBuild = useRef(false);
  const prevDepartmentTeam = usePrevious(departmentTeam);
  // const prevAccountId = usePrevious(accountId)

  useMemo(() => {
    if (!firstBuild.current) {
      firstBuild.current = true;
      return;
    }
    if (!departmentTeam) return;
    if (prevDepartmentTeam && prevDepartmentTeam === departmentTeam) return;
    const queryObj = {
      OrganizationId: departmentTeam.data,
      AccountId: accountId
    };

    const qString = querystring.stringify(queryObj);

    urlRef.current = `query/checkAccount?${qString}`;
  }, [accountId, departmentTeam, prevDepartmentTeam]);

  const { loading } = useSpaceQuery(
    urlRef.current,
    managerVerifierResponseSchema,
    {
      cache: false,
      onSuccess: ({ data }) => {
        const { UserInOrganization, Managers } = data;

        if (UserInOrganization) return;

        if (Managers.length > 0) onUpdateManager(null);
        else onUpdateManager(null);
      },
      onError: () => onUpdateManager(null)
    }
  );
  return loading;
};
