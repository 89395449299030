import React from "react";
import { FormattedMessage } from "react-intl";
import { BaseSidebarHeader } from "../../../Components/Sidebar/SidebarV2Helper";

const DocTemplatesForm = () => {
  return <DocTemplatesFormContent></DocTemplatesFormContent>;
};

const DocTemplatesFormContent = () => {
  return (
    <div className="d-flex h-100 flex-column bg-white">
      <BaseSidebarHeader>
        <FormattedMessage id="CREATE_DOC_TEMPLATE" />
      </BaseSidebarHeader>
      <div className="flex-1 of-y-auto shadow" style={{ width: 520 }}></div>
    </div>
  );
};

export default DocTemplatesForm;
