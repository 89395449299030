import {
  GET_SPACE_INVITE_REQUEST,
  GET_SPACE_INVITE_ERROR,
  GET_SPACE_INVITE_SUCCESS,
  CAPTURE_SPACE_INVITE_REQUEST,
  CAPTURE_SPACE_INVITE_ERROR,
  CAPTURE_SPACE_INVITE_SUCCESS
} from "../Actions/InviteActions";

const initialState = {
  isFetching: false,
  inviteId: null,
  error: null,
  invite: null,
  isCapturing: false,
  hasCaptured: false
};

export default function InviteManagerReducer(state = initialState, action) {
  if (!action.inviteId) return state;

  switch (action.type) {
    case GET_SPACE_INVITE_REQUEST:
      return {
        ...state,
        inviteId: action.inviteId,
        isFetching: true,
        error: null
      };

    case GET_SPACE_INVITE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        invite: action.response
      };

    case GET_SPACE_INVITE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case CAPTURE_SPACE_INVITE_REQUEST:
      return {
        ...state,
        isCapturing: true,
        error: null
      };

    case CAPTURE_SPACE_INVITE_SUCCESS:
      return {
        ...state,
        isCapturing: false,
        hasCaptured: true
      };

    case CAPTURE_SPACE_INVITE_ERROR:
      return {
        ...state,
        isCapturing: false,
        error: action.error
      };

    default:
      return state;
  }
}
