import React, {
  useMemo,
  useState,
  useCallback,
  useContext,
  useEffect
} from "react";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import FileUploader from "../../Components/FileUploader/FileUploader";
import {
  TaskTargetDropdown,
  UserDropdown,
  //   CancellationReasonsDropdown,
  tasksCancellationReasonsDropdown,
  OrganizationDropdown,
  ContactDropdown,
  TaskTypeDropdown,
  TaskTagDropdown
} from "../AdvancedMultiInputs";
import { isValueExpression, originTypes } from "../../Helpers/MiscHelper";
import ArDatePicker from "../../Components/DatePicker/ArDatePicker";
import { ConnectedBreadcrumbs } from "../../Components/Breadcrumbs/Breadcrumbs";
import { formatTodosGood, TasksStatusTypes } from "./TasksHelper";
import ClipboardProvider from "../../Components/ClipbordProvider/ClipboardProvider";
import { useTask, usePipelineStatus } from "../../Hooks/EntityHooks";
import { FormattedMessage } from "react-intl";
import {
  TaskStatusPipelineDropdown,
  TaskPipelineDropdown
} from "../AdvancedMultiInputs/PipelineDropdowns";
import HourPicker from "../../Components/HourPicker/HourPicker";
import HTMLInput from "../../Components/HTMLInput/HTMLInput";
import Checklist from "../../Components/Checklist/Checklist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faListCheck } from "@fortawesome/pro-light-svg-icons";
import Modal from "../../Components/Modal/Modal";
import { ChecklistTemplateChooser } from "../../Components/ChecklistTemplateChooser/ChecklistTemplateChooser";
import { updateManagerFollowers } from "../../Components/EntityFollow/EntityFollowHelper";
import CreatedBy from "../../Components/CreatedByComponent/CreatedBy";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import AddressSelector from "../../Components/AddressSelector/AddressSelector";
import TaskConflicts from "./TaskConflicts.tsx";
import { SidebarAutomationContext } from "../../Components/Sidebar/SidebarV2Automation";
import FileAssociations from "../../Components/FileUploader/FileAssociations";
import DocTyper from "../../Components/DocTyper/DocTyper";
import { getEntity, useSpaceQuery } from "../../Helpers/IOClient";
import {
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceTasksSchema,
  spaceContractSchema,
  spaceCallsSchema,
  spaceDealsSchema
} from "../../config/schema";

const useChecklistOptionalButtonsConfig = (task) => {
  const openForm = useEntitySummarySidebar();

  return useMemo(() => {
    if (!task) return;

    const onClick = (rowInfo) => {
      openForm({
        type: moduleTypes.tasks,
        props: {
          form: {
            Name: rowInfo.node.title,
            TaskTarget: {
              type: "Task",
              data: task.Id
            }
          }
        }
      });
    };

    return [
      {
        textId: "ADD_TASK",
        icon: faListCheck,
        onClick
      }
    ];
  }, [openForm, task]);
};

const TaskFormContent = ({
  formState,
  organizations,
  pipelines,
  id,
  canEdit
}) => {
  const { form, handleChange } = formState;

  const {
    Name,
    BeginDate,
    EndDate,
    EstimatedCost,
    AssociatedFiles,
    AssignedAssociations,
    // SalesOpportunity,
    Manager,
    Creator,
    ToDosChecked,
    ToDosCount,
    Company,
    Contact,
    // InternalDescription,
    TaskTarget,
    Pipeline,
    PipelineStatus,
    StatusDetailDescription,
    Files,
    ToDos,
    StatusDetailId,
    StatusDetail,
    Followers,
    // Comments,
    CreationDate,
    Client,
    IndirectClient
  } = form;
  const PipelineId = Pipeline;
  const PipelineStatusId = PipelineStatus;
  const PipelieStatusObj = usePipelineStatus(PipelineStatusId);

  const handleTaskNameChange = (e) => {
    const { value } = e.target;

    handleChange({
      Name: value
    });
  };

  if (
    !Pipeline &&
    pipelines &&
    pipelines.length > 0 &&
    pipelines.length === 1
  ) {
    handleChange({
      Pipeline: pipelines[0]
    });
  }

  if (organizations.length === 1 && !Company) {
    handleChange({
      Company: organizations[0]
    });
  }

  const handleOrganizationChanges = (e) => {
    const { value } = e.target;
    handleChange({
      Company: value,
      StatusDetailId: null
    });
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value
    });
  };

  const handleOriginChanges = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value
      // Contact: null
    });
  };

  const contactValidationURL = useMemo(() => {
    if (!Contact) return null;

    let projectId, ticketId, taskId, contractId, clientId, callId, dealId;

    if (TaskTarget) {
      switch (TaskTarget.type) {
        case originTypes.project:
          projectId = TaskTarget.data;
          break;

        case originTypes.ticket:
          ticketId = TaskTarget.data;
          break;

        case originTypes.task:
          taskId = TaskTarget.data;
          break;

        case originTypes.contract:
          contractId = TaskTarget.data;
          break;

        case originTypes.client:
          clientId = TaskTarget.data;
          break;

        case originTypes.call:
          callId = TaskTarget.data;
          break;

        case originTypes.deals:
          dealId = TaskTarget.data;
          break;

        default:
          break;
      }
    }

    const project = getEntity(spaceProjectsSchema, projectId);
    const ticket = getEntity(spaceTicketsSchema, ticketId);
    const task = getEntity(spaceTasksSchema, taskId);
    const contract = getEntity(spaceContractSchema, contractId);
    const call = getEntity(spaceCallsSchema, callId);
    const deal = getEntity(spaceDealsSchema, dealId);

    const Client =
      clientId ||
      (project && (project.Client || project.IndirectClient)) ||
      (deal && (deal.Client || deal.IndirectClient)) ||
      (ticket && (ticket.Client || ticket.IndirectClient)) ||
      (task && (task.Client || task.IndirectClient)) ||
      (call && (call.Client || call.IndirectClient)) ||
      (contract && (contract.Client || contract.IndirectClient)) ||
      IndirectClient;

    return `Query/Contacts/${Client}/${Contact}`;
  }, [Contact, IndirectClient, TaskTarget]);

  const { loading: contactaValidation } = useSpaceQuery(
    contactValidationURL,
    null,
    {
      onSuccess: ({ data }) => {
        if (!Boolean(data)) {
          handleChange({
            Contact: null
          });
        }
      },
      onError: (err) => {
        handleChange({
          Contact: null
        });
      }
    }
  );

  const handleAssociationsChanges = (value) => {
    handleChange((oldF) => {
      const newF = { ...oldF };
      const newAssignedAssociations = Array.isArray(newF.AssignedAssociations)
        ? [...newF.AssignedAssociations]
        : [];
      const index = newAssignedAssociations.findIndex((e) => e === value);
      if (index !== -1) {
        newAssignedAssociations.splice(index, 1);
      } else newAssignedAssociations.push(value);
      newF.AssignedAssociations = newAssignedAssociations;
      return newF;
    });
  };

  // function dateCompare(date1, date2) {
  //   return new Date(date2).getTime() > new Date(date1).getTime();
  // }

  const handlePipelineChanges = (e) => {
    const { value } = e.target;
    if (PipelineId === value) return;
    handleChange({
      Pipeline: value,
      PipelineStatus: ""
    });
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;

    handleChange({
      [name]: value
    });

    // if (name === "BeginDate") {
    //   if (dateCompare(EndDate, value)) {
    //     let endValue = new Date(value);
    //     endValue.setTime(endValue.getTime() + 60 * 60 * 1000);
    //     handleChange({
    //       BeginDate: value,
    //       EndDate: endValue
    //     });
    //     return;
    //   }
    // } else {
    //   if (dateCompare(value, BeginDate)) {
    //     let endValue = new Date(BeginDate);
    //     endValue.setTime(endValue.getTime() + 60 * 60 * 1000);

    //     handleChange({
    //       EndDate: endValue
    //     });
    //     return;
    //   }
    //}
  };

  useEffect(() => {
    if (
      PipelieStatusObj &&
      PipelieStatusObj.Status !== TasksStatusTypes.canceled &&
      (Boolean(StatusDetailId) ||
        Boolean(StatusDetail) ||
        Boolean(StatusDetailDescription))
    ) {
      handleChange({
        StatusDetailId: "",
        StatusDetail: "",
        StatusDetailDescription: ""
      });
    }
  }, [
    PipelieStatusObj,
    StatusDetail,
    StatusDetailDescription,
    StatusDetailId,
    handleChange
  ]);

  const Automation = useContext(SidebarAutomationContext);
  const entity = useTask(id);
  // const canEditFollowers = useCanEditFollowers(Creator, currentManager);

  const handleManagerChange = ({ target: { value } }) => {
    if (isValueExpression(value)) {
      handleChange({
        Manager: value
      });
    } else
      handleChange({
        Manager: value,
        Followers: updateManagerFollowers(Followers, Manager, value, [])
      });
  };
  const optionalButtons = useChecklistOptionalButtonsConfig(entity);

  const handleTodosChanges = (e) => {
    const { value: data } = e.target;

    const obj = formatTodosGood(data);
    handleChange({
      ...obj
    });
  };

  // const resolvedTodos = useMemo(() => {
  //   const data = [...ToDos];

  //   const returnData = (e) => {
  //     return {
  //       title: e.Name || e.title,
  //       Name: e.Name || e.title,
  //       children: [],
  //       ParentId: e.ParentId,
  //       Id: e.Id,
  //       Checked: e.Checked,
  //       expanded: true,
  //       ...e
  //     };
  //   };

  //   let initialData = data.filter((e) => !e.ParentId).map((e) => returnData(e));

  //   const buildObject = (arr) => {
  //     if (!data.some((e) => e.ParentId)) return [];

  //     arr.forEach((item) => {
  //       item.children = data
  //         .filter((e) => e.ParentId === item.Id)
  //         .map((e) => returnData(e));

  //       buildObject(item.children);
  //     });
  //     return arr;
  //   };

  //   buildObject(initialData);

  //   return initialData;
  // }, [ToDos]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const handleTodoTempleSelect = (value) => {
    handleChange({
      ToDos: value
    });
  };

  // useEffect(() => {
  //   let checkedNodes = 0;
  //   let checklistNodes = 0;

  //   walk({
  //     treeData: resolvedTodos,
  //     getNodeKey: ({ node, treeIndex }) => {
  //       return treeIndex;
  //     },
  //     callback: (e) => {
  //       if (e.node.Checked) {
  //         checkedNodes++;
  //         checklistNodes++;
  //       } else {
  //         checklistNodes++;
  //       }
  //     }
  //   });

  //   handleChange({
  //     ToDosChecked: checkedNodes,
  //     ToDosCount: checklistNodes,
  //     ToDos: resolvedTodos
  //   });
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [resolvedTodos]);

  const isPipelineIdAnExpression = useMemo(
    () => isValueExpression(PipelineId),
    [PipelineId]
  );

  const TargetIsVariable = isValueExpression(TaskTarget);

  return (
    <>
      <Modal isOpen={modalIsOpen} onClose={closeModal}>
        <ChecklistTemplateChooser
          sumbitToDo={handleTodoTempleSelect}
          setIsOpen={setModalIsOpen}
        />
      </Modal>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"ASSOCIATE_TASK_TO"} />
      </div>

      {Automation && (
        <FormInput
          textId="CREATED_BY"
          name="Creator"
          inputType={UserDropdown}
          className="mb-3"
        />
      )}
      {(Automation || (organizations && organizations.length > 1)) && (
        <FormInput
          textId="COMPANY"
          name="Company"
          inputType={OrganizationDropdown}
          onChange={handleOrganizationChanges}
          className="mb-3"
        />
      )}

      {TaskTarget && (
        <div className="my-1 fs-14 text-black">
          <FormattedMessage id="ORIGIN" />
        </div>
      )}
      {!TargetIsVariable && (
        <ConnectedBreadcrumbs value={TaskTarget} className="mb-3" />
      )}

      {/* <> */}

      {/* {organizations && organizations.length > 1 && (
          <FormInput
            name="Company"
            text={"Empresa"}
            inputType={OrganizationDropdown}
            items={organizations}
            onChange={handleOrganizationChanges}
            className="mb-3"
          />
        )} */}

      <FormInput
        name={"TaskTarget"}
        textId="ASSOCIATE_TO"
        className="mb-3"
        inputType={TaskTargetDropdown}
        value={TaskTarget}
        allClients={false}
        Task={id}
        onChange={handleOriginChanges}
      />

      <FormInput
        className="mb-3"
        name="Contact"
        originTarget={TaskTarget}
        textId="CONTACT"
        disable={contactaValidation}
        inputType={ContactDropdown}
        IndirectClient={IndirectClient || Client}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="CLASSIFICATION" />
      </div>

      <FormInput
        className="mb-3"
        name="TaskType"
        OrganizationSpaceId={Company}
        textId="TASK_TYPE"
        inputType={TaskTypeDropdown}
      />

      <FormInput
        className="mb-3"
        name="Pipeline"
        value={PipelineId}
        disabled={pipelines && pipelines.length > 0 && pipelines.length === 1}
        textId="PIPELINE"
        inputType={TaskPipelineDropdown}
        onChange={handlePipelineChanges}
      />

      <FormInput
        className="mb-3"
        disabled={!PipelineId}
        textId="STATUS"
        name="PipelineStatus"
        value={PipelineStatusId}
        Status={!id && TasksStatusTypes.open}
        filterBlock={true}
        inputType={TaskStatusPipelineDropdown}
        pipelineId={!isPipelineIdAnExpression ? PipelineId : undefined}
        onLoad={({ data }) => {
          !isPipelineIdAnExpression &&
            handleChange(
              {
                PipelineStatus: data[0],
                StatusDetail: null,
                StatusDetailDescription: null
              },
              true
            );
        }}
        onChange={handleChanges}
      />

      {id &&
        PipelieStatusObj &&
        PipelieStatusObj.Status === TasksStatusTypes.canceled && (
          <FormInput
            company={Company}
            name="StatusDetailId"
            textId="CANCELATION_REASON"
            inputType={tasksCancellationReasonsDropdown}
            value={
              StatusDetailId
                ? StatusDetailId
                : StatusDetail
                ? StatusDetail
                : null
            }
            onChange={handleChanges}
            className="mb-3"
          />
        )}
      {id &&
        PipelieStatusObj &&
        PipelieStatusObj.Status === TasksStatusTypes.canceled && (
          <FormInput
            name="StatusDetailDescription"
            textId="CANCELATION_DESCRIPTION"
            inputType={HTMLInput}
            value={StatusDetailDescription ? StatusDetailDescription : ""}
            onChange={handleChanges}
            className="mb-3"
          />
        )}

      <FormInput
        className="mb-3"
        name="Tags"
        textId="TASK_TAGS"
        pipelineId={Pipeline}
        // getKey={(e) => {
        //   const resolvedTag = getEntity(spaceTicketTagsSchema, e);
        //   return `${e}-${resolvedTag?.Pipeline}`;
        // }}
        multiple
        inputType={TaskTagDropdown}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"ASSIGN_TO"} />
      </div>

      <FormInput
        className="mb-3"
        textId="ASSIGNED_TO"
        inputType={UserDropdown}
        name="Manager"
        value={Manager}
        onChange={handleManagerChange}
      />

      <FormInput
        name="Followers"
        textId="FOLLOWERS"
        inputType={UserDropdown}
        multiple
        // items={possibleFollowers}
        className="mb-3"
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="DETAILS" />
      </div>

      <FormInput
        name={"Name"}
        textId="NAME"
        value={Name}
        onChange={handleTaskNameChange}
        className="mb-3"
      />

      <FormInput
        className="mb-3"
        name="BeginDate"
        textId="START"
        inputType={ArDatePicker}
        value={BeginDate}
        onChange={handleTimeChange}
        componentClassName="w-100"
      />

      <FormInput
        className="mb-3"
        textId="END"
        inputType={ArDatePicker}
        value={EndDate}
        name="EndDate"
        onChange={handleTimeChange}
        componentClassName="w-100"
      />

      {Manager && (
        <TaskConflicts
          taskId={id}
          user={Manager}
          beginDate={BeginDate}
          endDate={EndDate}
        />
      )}

      <FormInput
        className="mb-3"
        name="EstimatedCost"
        textId="ESTIMATED_TIME"
        inputType={HourPicker}
        componentClassName="w-100"
        enableDate={false}
        value={EstimatedCost}
        onChange={handleChanges}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="CHECK_LIST" />
      </div>
      <FormInput
        disableAutomation
        name="ToDos"
        className="mb-3"
        value={ToDos}
        onChange={handleTodosChanges}
        canCheck={canEdit}
        text={
          <div className="d-flex justify-content-between w-100">
            <div>
              <FormattedMessage
                id="TODO_NR_ITEMS"
                values={{
                  items:
                    ToDos.length > 0 ? `(${ToDosChecked}/${ToDosCount})` : "(―)"
                }}
              />
              {/* {`Numero de itens ${
                    resolvedTodos.length > 0
                      ? `(${ToDosChecked}/${ToDosCount})`
                      : "(―)"
                  }`} */}
            </div>
            <div
              onClick={() => {
                setModalIsOpen(true);
              }}
              className="d-flex cursor-pointer text-primary align-items-center"
            >
              <FontAwesomeIcon icon={faClipboardList} />
              <div className="pl-2">
                <FormattedMessage id="TEMPLATES" />
              </div>
            </div>
          </div>
        }
        optionalItemButtonsConfig={optionalButtons}
        inputType={Checklist}
        nodeWidth={350}
      />
      <hr className="mb-3"></hr>
      {/* <FormInput
          className="mb-3"
          text={intl.formatMessage({ id: "contract" })}
          inputType={ContractDropdown}
          name="Contract"
          value={Contract}
          onChange={handleChange}
        /> */}
      <ClipboardProvider>
        <FormInput
          className="mb-3"
          textId="INTERNAL_NOTE"
          inputType={DocTyper}
          name="InternalDescription"
          // value={InternalDescription}
          // onChange={handleChanges}
        />
        {/* {id && (
            <FormInput
              text={<FormattedMessage id="status" />}
              name="Status"
              inputType={ProjectStatusDropdown}
              hideCancellation={!isEntityStatusCancelled(id)}
              value={Status}
              onChange={handleChanges}
              className="mb-3"
            />
		  )} */}

        {/* {PipelieStatusObj && PipelieStatusObj.Status === 3 ? (
            <FormInput
              name="CancelationObs"
              text={<FormattedMessage id={"observations"} />}
              inputType={HTMLInput}
              value={CancelationObs}
              onChange={handleChanges}
              className="mb-3"
            />
          ) : (
            <div></div>
          )} */}

        <hr className="mb-3"></hr>
        <div className="mb-3 text-black fs-16 fw-medium">
          <FormattedMessage id={"LOCATION_ADDRESS"} />
        </div>

        <FormInput
          disableAutomation
          name="AddressSelector"
          className="mb-3"
          inputType={AddressSelector}
        />

        {!Automation && (
          <>
            {Array.isArray(AssociatedFiles) && AssociatedFiles.length > 0 && (
              <>
                <hr className="mb-3"></hr>
                <div className="text-black fs-16 mb-3 fw-medium">
                  <FormattedMessage id="RELATED_ATTACHMENTS" />
                </div>
                <FormInput
                  disableAutomation
                  inputType={FileAssociations}
                  name="Files"
                  className="mb-3 form-Anexos-floater file-associations-class"
                  extendedMode
                  handleAssociationsChanges={handleAssociationsChanges}
                  AssignedAssociations={AssignedAssociations}
                  onChange={() => {}}
                  value={AssociatedFiles}
                />
              </>
            )}
            <hr className="mb-3"></hr>
            <div className="text-black fs-16 mb-3 fw-medium">
              <FormattedMessage id="ATTACHMENTS" />
            </div>
            <FormInput
              disableAutomation
              inputType={FileUploader}
              name="Files"
              className="mb-3 form-Anexos-floater"
              enableSwapper
              value={Files}
              onChange={handleChanges}
            />
          </>
        )}
      </ClipboardProvider>

      {/* {id && (
            <>
              <hr className="mb-3"></hr>
              <div className="mb-3">
                <span className="text-black fs-16 fw-medium">Comentários</span>
              </div>
              <div className="mb-3">
                <CommentModule Type="task" Id={id} Comments={Comments || []} />
              </div>
            </>
          )} */}

      {!Automation && (
        <CreatedBy Creator={Creator} CreationDate={CreationDate} />
      )}
    </>
  );
};

export default TaskFormContent;
