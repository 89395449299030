import React from "react";
import NotificationCreationItem, {
  NotificationUserReplyConversationTask,
  NotificationUserInternalNoteReplyConversation,
  NotificationUserParticipationEmailConversation,
  NotificationNewFromOutsideEmailConversation,
  NotificationUserParticipationEmailConversationFromOutside
} from "./NotificationCreationItem";
// import NotificationEditItem from "./NotificationEditItem";
// import NotificationInviteItem from "./NotificationInviteItem";
// import { useNotification } from "../../../../Hooks/EntityHooks";
import {
  NotificationContext,
  useNotificationSchema
} from "./NotificationHelper";
import { notificationTypes } from "../../NotificationsHelper";
import NotificationEditItem from "./NotificationEditItem";
import NotificationInviteItem from "./NotificationInviteItem";
import NotificationUserAssignedItem from "./NotificationUserAssignedItem";
import NotificationStatusChangedItem from "./NotificationStatusChangedItem";
import NotificationManagerDiscardItem from "./NotificationManagerDiscardItem";
import NotificationDeleteItem from "./NotificationDeleteItem";

const NotificationItem = ({ value }) => {
  const { useSchema } = useNotificationSchema();
  const notf = useSchema(value);
  let component;

  switch (notf.Type) {
    case notificationTypes.create:
      component = <NotificationCreationItem value={notf} />; //sim
      break;

    case notificationTypes.edit:
      component = <NotificationEditItem value={notf} />; //sim
      break;

    case notificationTypes.invite:
      component = <NotificationInviteItem value={notf} />; //nao
      break;

    case notificationTypes.userAssigned:
      component = <NotificationUserAssignedItem value={notf} />; //sim;
      break;

    case notificationTypes.statusChanged:
      component = <NotificationStatusChangedItem value={notf} />; //sim
      break;

    case notificationTypes.managerDiscard:
      component = <NotificationManagerDiscardItem value={notf} />; //sim
      break;

    case notificationTypes.delete:
      component = <NotificationDeleteItem value={notf} />; //sim
      break;

    case notificationTypes.sendResponse:
      component = <NotificationUserReplyConversationTask value={notf} />; //sim
      break;

    case notificationTypes.sendEmailConversationNote:
      component = (
        <NotificationUserInternalNoteReplyConversation value={notf} />
      );
      break;
    case notificationTypes.emailConversationParticipation:
      component = (
        <NotificationUserParticipationEmailConversationFromOutside
          value={notf}
        />
      );
      break;

    case notificationTypes.emailNewmessageReceivedParticipated:
      component = (
        <NotificationUserParticipationEmailConversation value={notf} />
      );
      break;
    case notificationTypes.response:
      component = <NotificationNewFromOutsideEmailConversation value={notf} />;
      break;

    default:
      return null;
    // component = (
    //   <NotificationContainer>Notificação inválida</NotificationContainer>
    // );
    // break;
  }
  //
  return (
    <NotificationContext.Provider value={notf}>
      {component}
    </NotificationContext.Provider>
  );
};

export default NotificationItem;
