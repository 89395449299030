import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useToast } from "../../Components/Toast/ToastProvider";
import { useSpace } from "../../Contexts/SpaceContext";
import { useSpacePost, useSpacePostQuery } from "../../Helpers/IOClient";
import {
  BaseTreeData,
  LinkTypesEnumAppFavorites,
  TreeData
} from "./AppBarHelper";
import querystring from "query-string";
export interface IAppBarFavoriteContet {
  data: TreeData;
  handleChange: (
    e: TreeDataChangerType,
    showToast?: boolean | undefined
  ) => void;
  loading: boolean;
  error: any;
  commitChanges?: (
    e: (value: TreeData) => TreeData,
    showToast?: boolean | undefined
  ) => void;
}

export interface IAppBarFavoriteTreeSetterResponseProps {
  loading: boolean;
  error: any;
}

export const AppBarFavoriteTreeSetterResponse = React.createContext<
  IAppBarFavoriteTreeSetterResponseProps | undefined
>(undefined);

export const AppBarFavoriteQueryContext = React.createContext<
  IAppBarFavoriteContet | undefined
>(undefined);

export const AppBarFavoriteTreeSetter = React.createContext<
  (e: TreeDataChangerType, showToast: boolean) => void
>(undefined as any);

export type TreeDataChangerType = TreeData | ((value: TreeData) => TreeData);

const removeFavoriteFromSearch = (location: any, history: any) => {
  const queryObj = querystring.parse(location.search);
  delete queryObj.favoriteId;
  const newSearch = querystring.stringify(queryObj);
  history.replace({
    search: newSearch
  });
};

const AppBarFavoriteQuery: FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const space = useSpace();
  const location = useLocation();
  const [data, setData] = useState<TreeData | undefined>();
  const [error, setError] = useState<any>();
  const postPrefereceBody = useMemo(() => {
    return {
      key: `air-favorites-by-space-${space.Id}`
    };
  }, [space]);
  const createToast = useToast();
  const intl = useIntl();
  const ToastRef = useRef<boolean>(false);

  const onSuccess = useCallback(() => {
    ToastRef.current &&
      createToast({
        pos: "tm",
        type: "success",
        description: intl.formatMessage({ id: "SUCCESS_SAVED" })
      });
    if (ToastRef.current) ToastRef.current = false;
  }, [createToast, intl]);

  const [post, { loading: updateLoading, error: updateError }] = useSpacePost(
    `Preference/set`,
    null,
    {
      onSuccess
    }
  ) as any;

  const AppBarFavoriteTreeSetterResponseValue = useMemo(() => {
    return { loading: updateLoading, error: updateError };
  }, [updateError, updateLoading]);

  const MountedRef = useRef<Boolean>(false);
  const HandleChangesPropsRef = useRef<any>();

  const updateData = useCallback(
    (spaceId: number, value: TreeData, showToast?: boolean) => {
      const body = {
        Key: `air-favorites-by-space-${spaceId}`,
        value: JSON.stringify(value)
      };
      if (showToast) {
        ToastRef.current = true;
      }
      post(body);
    },
    [post]
  );

  useEffect(() => {
    if (!MountedRef.current || !HandleChangesPropsRef.current) {
      MountedRef.current = true;
      return;
    }
    const { space, showToast } = HandleChangesPropsRef.current;
    updateData(space, data as TreeData, showToast);
  }, [data, updateData]);

  const handleChange = useCallback(
    (e: TreeDataChangerType, showToast?: boolean) => {
      let value: TreeData;
      HandleChangesPropsRef.current = { space: space.Id, showToast };
      if (typeof e === "function") {
        setData((oldData) => {
          value = e(oldData as TreeData);
          return value;
        });
      } else {
        value = e;
        setData(value);
      }
    },
    [space.Id]
  );

  const handleChildrenRecursive = (
    items: Record<string, any>,
    resovledItems: Record<string, any>,
    thisId: string
  ) => {
    const thisItem = items[thisId];
    resovledItems[thisId] = thisItem;
    const resolvedChildren = [];
    if (resovledItems[thisId]?.children?.length > 0) {
      for (const c of thisItem.children) {
        if (items[c]) {
          handleChildrenRecursive(items, resovledItems, c);
          resolvedChildren.push(c);
        }
      }
    }
    resovledItems[thisId].children = resolvedChildren;
  };

  const { loading, refetch } = useSpacePostQuery(
    "Preference",
    postPrefereceBody,
    null,
    {
      autoFetch: false,
      onSuccess: ({ data: goodData }: any) => {
        if (goodData !== "NO_ITEMS") {
          const ParsedData = JSON.parse(goodData);
          const { items, rootId } = ParsedData;
          const ItemKeys = Object.keys(items);
          const KeysObject = {} as Record<string, Boolean>;
          for (const k of ItemKeys) {
            KeysObject[k] = false;
          }
          KeysObject[rootId] = true;

          const resolvedItems = {} as Record<string, any>;
          resolvedItems[rootId] = items[rootId];
          const resolvedRootChildren = [];
          if (resolvedItems[rootId]?.children?.length > 0) {
            for (const c of resolvedItems[rootId].children) {
              if (items[c]) {
                handleChildrenRecursive(items, resolvedItems, c);
                resolvedRootChildren.push(c);
              }
            }
          }
          resolvedItems[rootId].children = resolvedRootChildren;
          setData({ rootId, items: resolvedItems });
        } else {
          setData(BaseTreeData);
        }
      },
      onError: ({ error }: any) => {
        if (error && error.status === 404) {
          setData(BaseTreeData);
        } else if (error && error.status === 500) {
          setError(error);
        }
      },
      cache: false
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  useEffect(() => {
    const queryObj = querystring.parse(location.search);
    const selectedFavoriteId = queryObj.favoriteId as string;
    if (!data || !selectedFavoriteId) return;

    const item = data.items[selectedFavoriteId];
    if (item) {
      const { itemId, entity, linkType } = item.data;
      const urlData = location.pathname.split("/");
      const urlView = urlData[3];
      const urlId = urlData[4];

      if (entity !== urlView) removeFavoriteFromSearch(location, history);
      else if (
        linkType &&
        linkType === LinkTypesEnumAppFavorites.details &&
        String(itemId) !== urlId
      )
        removeFavoriteFromSearch(location, history);
    }
  }, [data, history, location]);

  const value = useMemo(() => {
    return { data, handleChange, loading, error };
  }, [data, error, handleChange, loading]);

  return (
    <AppBarFavoriteQueryContext.Provider value={value as IAppBarFavoriteContet}>
      <AppBarFavoriteTreeSetter.Provider value={handleChange}>
        <AppBarFavoriteTreeSetterResponse.Provider
          value={AppBarFavoriteTreeSetterResponseValue}
        >
          {children}
        </AppBarFavoriteTreeSetterResponse.Provider>
      </AppBarFavoriteTreeSetter.Provider>
    </AppBarFavoriteQueryContext.Provider>
  );
};

export default AppBarFavoriteQuery;
