import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {  useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSpace } from "../../Contexts/SpaceContext";
import {
  useSpaceDelete,
  useSpacePost,
  useSpacePostQuery
} from "../../Helpers/IOClient";
import { handleError } from "../../Helpers/MiscHelper";
import Button from "../Button/Button";
import LoadableButton from "../Button/LoadableButton";
import Checkbox from "../Checkbox/Checkbox";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import TextEllipsis from "../TextEllipsis/TextEllipsis";
import { useToast } from "../Toast/ToastProvider";
import "./FormTemplates.css";

export const FormTemplatesContext = React.createContext();
export const FormTemplatesRefetchContext = React.createContext();

export const useFormTemplateGetter = (schema) => {
  const space = useSpace();
  const body = useMemo(
    () => [
      { Key: `air-form-templates-by-space-${space.Id}-view-${schema.name}` }
    ],
    [schema.name, space.Id]
  );

  const {
    loading: loadingTemplatesKeys,
    data: templatesKeys,
    error: errorTemplatesKeys,
    refetch
  } = useSpacePostQuery(`Preference/GetMutiple`, body);

  const secondBody = useMemo(() => {
    if (!templatesKeys) return null;
    const obj = templatesKeys[0];
    if (!obj) return null;
    const parsedVal = JSON.parse(obj.Value);
    const result = [];
    for (const e of parsedVal) {
      result.push({ Key: e });
    }
    return result;
  }, [templatesKeys]);

  const {
    loading: loadingTemplatesComplete,
    data: templatesComplete,
    error: errorTemplatesComplete
  } = useSpacePostQuery(
    secondBody ? `Preference/GetMutiple` : null,
    secondBody
  );

  return {
    data: templatesComplete,
    loading: loadingTemplatesComplete || loadingTemplatesKeys,
    error: errorTemplatesKeys || errorTemplatesComplete,
    refetch
  };
};

export const FormTemplateProvider = ({ children, forms, refetch }) => {
  return (
    <FormTemplatesContext.Provider value={forms}>
      <FormTemplatesRefetchContext.Provider value={refetch}>
        {children}
      </FormTemplatesRefetchContext.Provider>
    </FormTemplatesContext.Provider>
  );
};

export const TemplateFormSorter = ({ schema, onClose, handleChange }) => {
  const [temps, setTemps] = useState([]);
  const { data: templates, loading: loadingTemplates } =
    useFormTemplateGetter(schema);

  useEffect(() => {
    if (!loadingTemplates && templates) {
      setTemps(templates);
    }
  }, [loadingTemplates, templates]);

  const createToast = useToast();
  const intl = useIntl();

  const schemaName = schema.name;

  // eslint-disable-next-line no-unused-vars
  const [deletePref, { loading }] = useSpaceDelete(`Preference/DeleteMultiple`);

  // eslint-disable-next-line no-unused-vars
  const [post, { loading: loadingChanges }] = useSpacePost(
    "Preference/SetMultiple",
    null,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          description: intl.formatMessage({ id: "SUCCESS_DELETED" })
        });

        // refetch();
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  const resolvedTemplates = useMemo(() => temps, [temps]);

  const [activeTemplate, setActiveTemplate] = useState("");
  const space = useSpace();
  const handleDelete = (key) => {
    deletePref([{ Key: key }]);
    document.getElementById(key).style.display = "none";
    const formTemplatesObj = resolvedTemplates.map((t) => {
      return t.Key;
    });
    formTemplatesObj.splice(
      formTemplatesObj.findIndex((k) => k === key),
      1
    );
    const newKeyBody = [
      {
        Key: `air-form-templates-by-space-${space.Id}-view-${schemaName}`,
        Value: JSON.stringify([...formTemplatesObj])
      }
    ];
    post(newKeyBody);
    setTemps((oldTemps) => {
      const newTemps = [...oldTemps];
      newTemps.splice(
        newTemps.findIndex((k) => k.Key === key),
        1
      );
      return newTemps;
    });
  };

  const submit = () => {
    const match = resolvedTemplates.find((t) => {
      return t.Key === activeTemplate;
    });
    const { Value } = match;
    const resolvedValue = JSON.parse(Value);
    handleChange((oldV) => {
      const newV = { ...resolvedValue.form };
      return newV;
    });
    createToast({
      pos: "tm",
      type: "success",
      description: intl.formatMessage({ id: "TEMPLATE_APPLIED" })
    });
    onClose();
  };

  return (
    <div className="TemplateModalWrap disable-selection p-30">
      <div className="fs-16 mb-10 fw-bold text-black d-flex justify-content-between">
        <span className="pt-1">
          <FormattedMessage id="CHOOSE_A_TEMPLATE" />
        </span>
      </div>
      <div className="TemplaterChooserOrderList mb-3">
        {resolvedTemplates.length > 0 ? (
          resolvedTemplates.map((t) => (
            <Item
              key={t.Key}
              checked={activeTemplate === t.Key}
              onChange={setActiveTemplate}
              data={t}
              handleDelete={handleDelete}
            />
          ))
        ) : loadingTemplates ? (
          <div className="TemplaterChooserOrderList text-primary h-50 of-hidden d-flex align-items-center flex-columns justify-content-center mb-3">
            <LoaderSpinner size="sm" center />
          </div>
        ) : (
          <div className="align-items-center d-flex flex-0-0-auto flex-column fs-16 fw-medium h-50 justify-content-center text-black">
            <FormattedMessage id="NO_TEMPLATES" />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <Button
          type="button"
          className="mr-3"
          onClick={() => {
            onClose();
          }}
          vType="link-danger"
        >
          <FormattedMessage id={"CANCEL"} />
        </Button>
        <LoadableButton type="button" onClick={submit} vType="primary">
          <FormattedMessage id={"APPLY"} />
        </LoadableButton>
      </div>
    </div>
  );
};

const Item = ({ data, handleDelete, checked, onChange }) => {
  const { Key, Value } = data;
  const resolvedValue = JSON.parse(Value);
  const handleChange = (e) => {
    if (e.target.checked) {
      onChange(Key);
    } else onChange("");
  };

  return (
    <div id={Key} className={`py-1 text-black`}>
      <div className="TemplateContainer">
        <span style={{ width: 24, height: 24 }} className="ml-2 d-flex">
          <Checkbox checked={checked} onChange={handleChange} />
        </span>

        <span
          onClick={() => {
            onChange(checked ? "" : Key);
          }}
          className="ml-2 flex-1 cursor-pointer text-truncate"
        >
          <TextEllipsis truncate length={30} text={resolvedValue.name} />
        </span>
        <span
          onClick={() => handleDelete(Key)}
          style={{ float: "right", cursor: "pointer", color: "red" }}
          className="mx-3 mb-2"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </span>
      </div>
    </div>
  );
};
