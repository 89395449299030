import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import querystring from "query-string";
import {
  AppBarFavoriteQueryContext,
  IAppBarFavoriteContet
} from "./AppBarFavoriteQuery";
import SuspenseLoader from "../../Components/SuspenseLoader/SuspenseLoader";
import { TreeItem } from "./AppBarFavoritesWithLibrary";

export const ServerAwareFavoritesContext = React.createContext<TreeItem>(
  undefined as any
);

// const verifyId = (search: string, newFavoriteId: string) => {
//   const queryObj = querystring.parse(search);
//   const { favoriteId } = queryObj;
//   if (newFavoriteId === favoriteId) return true;
//   else return false;
// };

export const useQueryALL = () => {
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { favoriteId, viewPref } = queryObj;
  return { favoriteId, viewPref };
};
interface IServerAwareFavoriteProps {
  favoriteId: string;
  children?: React.ReactNode;
}
const ServerAwareFavorite: FC<IServerAwareFavoriteProps> = ({
  children,
  favoriteId
}) => {
  // useFavoriteIdSynchronizer(favoriteId);
  const history = useHistory();
  const FavoriteData = useContext(AppBarFavoriteQueryContext);
  const { data, loading, error } = FavoriteData as IAppBarFavoriteContet;
  const [searchChanged, setSearchChanged] = useState(false);

  useEffect(() => {
    if (!data || searchChanged) return;
    const item = data.items[favoriteId];
    if (!item) {
      history.replace({
        search: ""
      });
      return;
    }
    const { search } = item.data;
    if (search) {
      const newSearch = querystring.parse(search);
      newSearch.favoriteId = favoriteId;
      history.replace({
        search: querystring.stringify(newSearch)
      });
    } else
      history.replace({
        search: `?favoriteId=${favoriteId}`
      });
    setSearchChanged(true);
  }, [data, favoriteId, history, searchChanged]);

  if (loading || error || !searchChanged) {
    return <SuspenseLoader />;
  }
  return <>{children}</>;
};

export default ServerAwareFavorite;
