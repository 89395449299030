import React, { useContext } from "react";
import Button from "../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStopwatch } from "@fortawesome/pro-duotone-svg-icons";
import {
  faStopwatch as faStopwatchLight,
  faThumbtack,
  faXmark
} from "@fortawesome/pro-light-svg-icons";
import "./TimeTracking.css";
import { addTimeTracker, useTimeTrackingState } from "./TimeTrackingHelper";
import classnames from "classnames";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { moduleTypes, canUserEditModule } from "../../Helpers/ModulesHelper";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import { TimeTrackerForm } from "./TimeTrackingForm";
import { ObfuscateProvider } from "../Space/Space2";
import { FormattedMessage, useIntl } from "react-intl";

export const TimeTrackingFormButton = ({
  className,
  pinItem,
  isPinned,
  pinClass,
  disableCount,
  labelText,
  buttonClass
}) => {
  const { playingCount } = useTimeTrackingState();
  const intl = useIntl();
  const accountSpace = useCurrentAccountSpace();

  const canTime = canUserEditModule(accountSpace, moduleTypes.interventions);

  const [openSidebar] = useSidebar();

  const ObfuscateState = useContext(ObfuscateProvider);
  const handleClick = (e) => {
    e.stopPropagation();
    if (ObfuscateState === true) e.preventDefault();
    else {
      openSidebar(<TimeTrackerForm />);
    }
  };

  if (!canTime) return null;
  return (
    <div
      style={{
        opacity: ObfuscateState && 0.3,
        pointerEvents: ObfuscateState && "none"
      }}
      className={className || "ml-1 "}
    >
      <div
        onClick={handleClick}
        className={classnames(
          "ar-header-rounded-icon w-100 flex-0-0-auto",
          buttonClass
        )}
      >
        <FontAwesomeIcon icon={faStopwatch} size="lg" />
        {labelText && (
          <span
            style={{ fontWeight: 500 }}
            className="ml-3 flex-1 text-truncate"
          >
            {labelText}
          </span>
        )}
        {!disableCount && playingCount !== undefined && playingCount > 0 && (
          <div className="ml-2 ">
            <div
              title={intl.formatMessage({ id: "ACTIVE" })}
              className="ssi-unread-count-pill"
            >
              {playingCount}
            </div>
          </div>
        )}
        {pinItem && (
          <div
            className={classnames(
              "flex-0-0-auto d-flex align-items-center",
              pinClass
            )}
            onClick={pinItem}
          >
            <FontAwesomeIcon icon={isPinned ? faXmark : faThumbtack} />
          </div>
        )}
      </div>
      {playingCount !== undefined && playingCount > 0 && (
        <div
          className={classnames("unread-count newDesign position-absolute")}
        ></div>
      )}
      {/* <span>{playingCount}</span> */}
      {/* <FontAwesomeIcon className="h-100" icon={faCaretRight} size="lg" /> */}
    </div>
  );
};

export const TimeTrackingGridFormButton = ({ origin }) => {
  const accountSpace = useCurrentAccountSpace();

  const canTime = canUserEditModule(accountSpace, moduleTypes.interventions);

  const [openSidebar] = useSidebar();

  const handleClick = (e) => {
    addTimeTracker(origin);
    openSidebar(<TimeTrackerForm />);
  };

  if (!canTime) return null;
  return (
    <Button onClick={handleClick} vType={"link"} className="text-left">
      <div className="mr-1 ar-form-button-link-icon">
        <FontAwesomeIcon icon={faStopwatchLight} />
      </div>
      <span className="fw-regular fs-14">
        <FormattedMessage id="ADD_TIMER" />
      </span>
    </Button>
  );
};
