import React from "react";
import classnames from "classnames";

export const PageContainer = ({ children, className, style }) => {
  return (
    <div
      style={style}
      className={classnames("h-100 w-100 d-flex overflow-auto flex-column", className)}
    >
      {children}
    </div>
  );
};

export const PageContent = ({ children, className }) => {
  return (
    <div className={classnames("innerContent", className)}>{children}</div>
  );
};
