import React from "react";
import classnames from "classnames";
import "./Alerts.css";

export const BaseErrorAlert = (props) => {
  const { children, className, outline } = props;
  return (
    <div
      className={classnames("ar-alert ar-alert-error", { outline }, className)}
    >
      {children}
    </div>
  );
};

export const ErrorAlert = (props) => {
  const { children, className } = props;
  return (
    <div className={classnames("py-3 px-2 ar-alert ar-alert-error", className)}>
      {children}
    </div>
  );
};

export const InfoAlert = ({ children, className, size }) => {
  return (
    <div
      className={classnames(
        "ar-alert ar-alert-info",
        {
          "py-3 px-2": !Boolean(size),
          "py-1 px-2": size === "sm",
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export const WarningAlert = ({ children, className }) => {
  return (
    <div className={classnames("ar-alert ar-alert-warning", className)}>
      {children}
    </div>
  );
};

export const InfoAlertAlt = ({ children, className, style }) => {
  return (
    <div
      style={style}
      className={classnames("ar-alert ar-alert-info", className)}
    >
      {children}
    </div>
  );
};
