import React, { useRef, useMemo } from "react";
import { EntityOverviewItem } from "./EntityOverview";
import RoundedBadge from "../RoundedBadge/RoundedBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faDrawCircle } from "@fortawesome/pro-light-svg-icons";
import {
  usePipeline,
  usePipelineStatus,
  useCancelationReason
} from "../../Hooks/EntityHooks";
import { useHoverEvents } from "../../Containers/Origin/Origin";
import { PipelineStatusProfile } from "../../Containers/AdvancedMultiInputs/PipelineDropdowns";
import { buildProjectStatusType } from "../Projects/ProjectProfile";
import InfoPopup from "../Popup/InfoPopup";
import { FormattedMessage } from "react-intl";

const PipelineItem = ({
  pipeline,
  title,
  titleId,
  children,
  buildFunction,
  StatusProfileComponent,
  showIcon
}) => {
  const ref = useRef();

  const { Name, Status } = pipeline;

  const {
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen,
    onPopupMouseEnter,
    onPopupMouseLeave
  } = useHoverEvents();

  const type = useMemo(() => {
    if (!Status) return;

    if (buildFunction) return buildFunction(Status);

    return buildProjectStatusType(String(Status));
  }, [Status, buildFunction]);

  return (
    <>
      <RoundedBadge
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
        innerRef={ref}
        className="text-truncate"
        type={type}
      >
        {showIcon ? (
          <PipelineStatusProfile
            className="mr-1"
            StatusProfileComponent={
              StatusProfileComponent ? StatusProfileComponent : undefined
            }
            pipeline={pipeline}
          />
        ) : (
          Name
        )}
        {/* {Name} */}
      </RoundedBadge>
      {/* {children} */}
      {/* <InfoPopup
        anchorEl={ref.current}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        {titleId ? <FormattedMessage id={titleId} /> : title}
        {children}
      </InfoPopup> */}
    </>
  );
};

export const EntityOverviewPipeline = ({
  pipeline: pipelineId,
  pipelineStatus: pipelineStatusId,
  buildFunction,
  statusDetail,
  StatusProfileComponent,
  ...rest
}) => {
  const pipeline = usePipeline(pipelineId);
  const pipelineStatus = usePipelineStatus(pipelineStatusId);
  return (
    <EntityOverviewItem
      className="d-flex align-items-center"
      icon={faDrawCircle}
      titleId="PIPELINE"
    >
      <PipelineItem titleId="PIPELINE" pipeline={pipeline}>
        <br />
        {pipeline.Name}
      </PipelineItem>
      <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
      <PipelineItem
        {...rest}
        StatusProfileComponent={StatusProfileComponent}
        titleId="PIPELINE_STATUS"
        pipeline={pipelineStatus}
        showIcon
        buildFunction={buildFunction || buildProjectStatusType}
      >
        <PipelineStatusProfile
          StatusProfileComponent={
            StatusProfileComponent ? StatusProfileComponent : undefined
          }
          pipeline={pipelineStatus}
        />
        {/* {detail && (
          <div>
            <FormattedMessage
              id={"REASON_TEXT"}
              values={{ reason: detail.Name }}
            />
          </div>
        )} */}
      </PipelineItem>
    </EntityOverviewItem>
  );
};
