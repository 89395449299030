import React, { useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-light-svg-icons";
import { faBell as faBellSolid } from "@fortawesome/pro-solid-svg-icons";
import classnames from "classnames";
import {
  getEntity,
  usePost
  // getEntity,
  // updateNormalizedSchemaCache
} from "../../Helpers/IOClient";
import { useSpace } from "../../Contexts/SpaceContext";
import "./EntityFollowIcon.css";
import InfoPopup from "../Popup/InfoPopup";
import { useHoverEvents } from "../../Containers/Origin/Origin";
import { useUnreadEntitiesFetcher } from "../UnreadEntities/UnreadEntities";
import Modal from "../Modal/Modal";
import { FormattedMessage } from "react-intl";
import Button from "../Button/Button";
import LoadableButton from "../Button/LoadableButton";

// const updateIsFollowing = (schema, entityId, IsFollowing) => {
//   const entities = {};

//   const entity = { ...getEntity(schema, entityId) };

//   entity.IsFollowing = IsFollowing;

//   entities[entityId] = entity;

//   updateNormalizedSchemaCache({
//     [schema.name]: entities
//   });
// };

export const useUrlPost = (schema, id, isFollowing) => {
  const { Id: spaceId } = useSpace();

  const fetch = useUnreadEntitiesFetcher();

  const url = useMemo(() => {
    const base = schema.getEndpoint(spaceId);

    if (isFollowing) return `${base}/${id}/Unfollow`;

    return `${base}/${id}/Follow`;
  }, [id, isFollowing, schema, spaceId]);
  return usePost(url, schema, {
    onSuccess: () => {
      fetch();
      // updateIsFollowing(schema, id, !isFollowing);
    }
  });
};

const EntityFollowIcon = ({
  size,
  className,
  isFollowing,
  schema,
  id,
  canEdit
}) => {
  const [post, { loading }] = useUrlPost(schema, id, isFollowing);

  const handleClick = () => {
    if (loading) return;
    if (canEdit !== false) {
      post();
    } else {
      openModal();
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const openModal = () => {
    setModalIsOpen(true);
  };
  const { CanEdit } = getEntity(schema, id) || {};

  const ref = useRef();

  const hoverEvents = useHoverEvents();

  const {
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen,
    onPopupMouseEnter,
    onPopupMouseLeave
  } = hoverEvents;

  if (!CanEdit && !isFollowing) return null;

  return (
    <>
      <Modal isOpen={modalIsOpen} onClose={closeModal}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            e.stopPropagation();
          }}
          style={{ borderRadius: 7, width: 600 }}
          className="bg-white p-3 disable-selection"
        >
          <div className="text-black fw-bold fs-18 mb-2">
            <FormattedMessage id="CONFIRM_UNSUB_TITLE" />
          </div>
          <div className="text-black fs-16 mb-3">
            <FormattedMessage id="CONFIRM_UNSUB_DESCRIPTION" />
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Button className="mr-3" onClick={closeModal} vType="link-danger">
              <FormattedMessage id="CANCEL" />
            </Button>
            <LoadableButton
              isLoading={loading}
              onClick={()=>{
								post()
							}}
              vType="primary-ghost"
            >
              <FormattedMessage id="CONFIRM" />
            </LoadableButton>
          </div>
        </div>
      </Modal>
      <div
        ref={ref}
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
        onClick={handleClick}
        className={classnames(
          "ar-entity-favorite-icon cursor-pointer",
          className,
          {
            active: isFollowing
          }
        )}
      >
        <FontAwesomeIcon
          size={size}
          icon={isFollowing ? faBellSolid : faBell}
        />
      </div>
      <InfoPopup
        anchorEl={ref.current}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <span>
          {isFollowing ? "Desativar notificações" : "Ativar notificações"}
        </span>
      </InfoPopup>
    </>
  );
};

export default EntityFollowIcon;
