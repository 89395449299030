import React, { Component } from "react";
import DatePickerWithSocialPresetSchedules from "./DatePickerWithSocialPresetSchedules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarStar } from "@fortawesome/pro-light-svg-icons";

class ArDatePickerWithSocialPresetSchedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  valueChanges = e => {
    this.setState({
      value: e
    });
  };

  handleChange = e => {
    const { onChange, enableDate } = this.props;
    if (enableDate === false) onChange(e);
    else onChange(e.Date);
  };

  render() {
    const { onChange, ...rest } = this.props;

    return (
      <DatePickerWithSocialPresetSchedules
        NewCalenderIconForSchedules={
          <FontAwesomeIcon style={{ color: "#1e73f0" }} icon={faCalendarStar} />
        }
        onChange={onChange ? this.handleChange : undefined}
        DontDisableAfterClick={true}
        {...rest}
      />
    );
  }
}

export default ArDatePickerWithSocialPresetSchedules;
