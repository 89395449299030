import React, { useContext, useEffect, useMemo } from "react";
import "./PersonalExportsButton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/pro-duotone-svg-icons";
import { useUnreadEntities } from "../../../Components/UnreadEntities/UnreadEntities";
import { ObfuscateProvider } from "../../Space/Space2";
import { useSidebar } from "../../../Components/Sidebar/SidebarV2";
import classnames from "classnames";
import PersonalExportSidebar from "./PersonalExportSidebar";
import {
  hasModulePermission,
  isAdmin,
  modulePermissions
} from "../../../Helpers/ModulesHelper";
import { useCurrentAccountSpace } from "../../../Contexts/UserContext";
import classNames from "classnames";
import { faThumbtack, faXmark } from "@fortawesome/pro-light-svg-icons";
import { useIntl } from "react-intl";

export const hasAnyExportPermission = (accountSpace) => {
  let result = false;
  if (isAdmin(accountSpace)) return true;
  const { Modules } = accountSpace;
  for (const key in Modules) {
    if (Object.hasOwnProperty.call(Modules, key)) {
      const element = Modules[key];
      if (hasModulePermission(element, modulePermissions.Export)) result = true;
    }
  }
  return result;
};

const PersonalExportButton = ({
  className,
  pinItem,
  isPinned,
  pinClass,
  disableCount,
  labelText,
  buttonClass
}) => {
  const unresolvedUnreads = useUnreadEntities();
  const unreads = useMemo(() => {
    if (!unresolvedUnreads) return {};
    else return unresolvedUnreads;
  }, [unresolvedUnreads]);
  const ResolvedUnreadsCount = useMemo(() => {
    return unreads.UnreadExports || 0;
  }, [unreads]);

  const hasMany = ResolvedUnreadsCount > 99;
  const intl = useIntl();
  const resolvedUnreadCount = hasMany ? "99+" : ResolvedUnreadsCount;

  const [openSidebar, closeSidebar] = useSidebar();

  const ObfuscateState = useContext(ObfuscateProvider);
  const handleClick = (e) => {
    e.stopPropagation();
    if (ObfuscateState === true) e.preventDefault();
    else {
      openSidebar(<PersonalExportSidebar />);
    }
  };

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const accountSpace = useCurrentAccountSpace();
  if (!hasAnyExportPermission(accountSpace)) return null;

  return (
    <div
      style={{
        opacity: ObfuscateState === true && 0.3,
        pointerEvents: ObfuscateState === true && "none"
      }}
      className={className || "cursor-pointer ml-1"}
    >
      {ResolvedUnreadsCount > 0 && (
        <div
          // style={{ left: 34, top: 4, width: 10, height: 10, outline: "none", background: "#FF8066" }}
          className={classnames("unread-count newDesign position-absolute")}
        >
          {/* <span>{resolvedNotificationsCount}</span> */}
        </div>
      )}
      <div
        onClick={handleClick}
        // className={`mr-0 ar-header-rounded-icon BellButton position-relative cursor-pointer d-flex align-items-center justify-content-center`}
        className={classnames(
          "ar-header-rounded-icon position-relative d-flex align-items-center flex-0-0-auto",
          className,
          buttonClass
        )}
      >
        <FontAwesomeIcon icon={faFileDownload} size="lg" />
        {labelText && (
          <span
            style={{ fontWeight: 500 }}
            className="ml-3 flex-1 text-truncate"
          >
            {labelText}
          </span>
        )}
        {!disableCount && ResolvedUnreadsCount > 0 && (
          <div className="ml-2 ">
            <div
              title={intl.formatMessage({ id: "NOT_READ" })}
              className="ssi-unread-count-pill"
            >
              {resolvedUnreadCount}
            </div>
          </div>
        )}
        {pinItem && (
          <div
            className={classnames("flex-0-0-auto", pinClass)}
            onClick={pinItem}
          >
            <FontAwesomeIcon icon={isPinned ? faXmark : faThumbtack} />
          </div>
        )}
      </div>
    </div>
  );
};
//   return (
//     <div
//       onClick={handleClick}
//       style={{ pointerEvents: ObfuscateState === true && "none" }}
//       className={classnames("ar-header-rounded-icon", className || "ml-1")}
//       // activeClassName="selected"
//       // to={`/s/${spaceId}/account/exports`}
//     >
//       <span
//         style={{
//           opacity: ObfuscateState && 0.3,
//           pointerEvents: ObfuscateState && "none"
//         }}
//       >
//         <div
//           className={`mr-0 ar-header-rounded-icon InboxButton position-relative cursor-pointer d-flex align-items-center justify-content-center`}
//         >
//           {ResolvedUnreadsCount > 0 && (
//             <div
//               className={classNames("unread-count position-absolute", {
//                 many: hasMany
//               })}
//             >
//               <span>{resolvedUnreadCount}</span>
//             </div>
//           )}
//           <FontAwesomeIcon
//             icon={faFileDownload}
//             className="personal-export-svg"
//             size="lg"
//           />
//         </div>
//       </span>
//     </div>
//   );
// };

export default PersonalExportButton;
