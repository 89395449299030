import React from "react";
import "react-table/react-table.css";
import { Switch, Route, withRouter } from "react-router-dom";
// import {
//   RealTimePageEnum,
//   RealTimePageProvider
// } from "../RealTime/RealTimePages";
import TicketDetails from "./TicketDetails/TicketDetails";
import TicketOverview from "./TicketOverview/TicketOverview";
import {
  // useEnterViewSignalR,
  PageTypeProvider
} from "../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimePageEnum } from "../RealTime/RealTimePages";

const Tickets = withRouter(({ match }) => {
  // useEnterViewSignalR(RealTimePageEnum.Ticket)

  return (
    <PageTypeProvider pageType={RealTimePageEnum.Ticket}>
      <Switch>
        <Route path={`${match.url}/:ticketId`}>
          <TicketDetails />
        </Route>
        <Route>
          {/* <RealTimePageProvider pageType={RealTimePageEnum.Ticket}> */}
          <TicketOverview />
          {/* </RealTimePageProvider> */}
        </Route>
      </Switch>
    </PageTypeProvider>
  );
});

export default Tickets;
