import { faHandshake} from "@fortawesome/pro-solid-svg-icons";
import React, { useContext, } from "react";
import { useIntl } from "react-intl";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import {
  formatAutomationDealForm
} from "../../../../../Helpers/FormFormmaterHelper";
import { isValueExpression } from "../../../../../Helpers/MiscHelper";
import DealFormContent from "../../../../Deals/DealFormContent";
import { defaultDealForm } from "../../../../Deals/DealsHelper";
import { PipelineTypeEnum } from "../../../../Settings/Pipelines/NewPipelineHelper";
import { ElementsRefContext } from "../../AutomationDetails";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import SourceHandles from "../../Handles/SourceHandles";
import TargetHandles from "../../Handles/TargetHandles";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import AutomationCreation, {
  EntityAutomationCreationSidebar,
  snakeToFormCase
} from "./CreationNode";

const convertAutomationToDealForm = (form, currencies) => {
  const {
    creator,
    // received_date,
    // target,
    manager_id,
    pipeline_id,
    contact_id,
    client_id,
    deal_manager_id,
    name,
    pipeline_status_id,
    estimated_cost,
    departments,
    status_detail_id,
    sales_opportunity,
    status_detail_description,
    organization_id,
    description,
    teams,
    accounts,
    followers,
    begin_date,
    end_date,
    addresses = [],
    // department_id,
    // team_id
  } = form;

  // const target = getSnakeCaseTarget(form);

  const resolvedBeginDate = isValueExpression(begin_date)
    ? begin_date
    : new Date(begin_date);

  const resolvedEndDate = isValueExpression(end_date)
    ? end_date
    : new Date(end_date);

  let resolvedAddresses = [];

  for (const e of addresses) {
    let newObj = {};
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const element = e[key];
        const newKey = snakeToFormCase(key);
        newObj[newKey] = element;
      }
    }
    resolvedAddresses.push(newObj);
  }

  let AddressSelector = {
    Id: resolvedAddresses[0]?.Id || undefined,
    Country: resolvedAddresses[0]?.CountryId || undefined,
    Address1: resolvedAddresses[0]?.Address1 || "",
    Address2: resolvedAddresses[0]?.Address2 || "",
    District: resolvedAddresses[0]?.District || "",
    Locality: resolvedAddresses[0]?.Locality || "",
    PostalCode: resolvedAddresses[0]?.PostalCode || "",
    Latitude: resolvedAddresses[0]?.Latitude || "",
    Longitude: resolvedAddresses[0]?.Longitude || ""
  };

  const TeamCollab = [];

  for (const t of teams) {
    TeamCollab.push({
      data: t,
      type: "Team"
    });
  }
  for (const t of accounts) {
    TeamCollab.push({
      data: t,
      type: "Account"
    });
  }

  let resolvedForm = {
    Client: client_id,
    Departments: departments,
    BeginDate: resolvedBeginDate,
    EndDate: resolvedEndDate,
    ManagerAccount: manager_id,
    Contact: contact_id,
    Pipeline: pipeline_id,
    PipelineStatus: pipeline_status_id,
    StatusDetailId: status_detail_id,
    Name: name,
    EstimatedCost: estimated_cost,
    SalesOpportunity: sales_opportunity,
    StatusDetailDescription: status_detail_description,
    Company: organization_id,
    TeamCollab,
    DealManager: deal_manager_id,
    Assigned: manager_id,
    Creator: creator,
    Description: description,
    Followers: followers,
    AddressSelector
  };
  const { estimated_costs } = form;
  if (estimated_costs) {
    const EstimatedCostsDict = {};

    for (const val of estimated_costs) {
      const newVal = {};
      for (const t in val) {
        newVal[snakeToFormCase(t)] = val[t];
      }
      EstimatedCostsDict[newVal.CurrencyId] = newVal;
    }

    for (const curr of currencies) {
      if (!EstimatedCostsDict[curr]) {
        EstimatedCostsDict[curr] = {
          Cost: 0,
          CurrencyId: curr
        };
      }
    }

    resolvedForm = {
      ...resolvedForm,
      EstimatedCostsDict
    };
  }

  return resolvedForm;
};

const settings = {
  convertAutomationToForm: convertAutomationToDealForm,
  buildBaseForm: ({ user, id, convertedForm, currencies }) => {
    const defaultForm = defaultDealForm({ user, currencies });
    return { ...defaultForm, ...convertedForm };
  },
  getDefaultForm: ({ user, currencies }) => {
    return defaultDealForm({ user, currencies });
  },
  pipelineType: PipelineTypeEnum.Deal,
  FormContent: (props) => {
    return <DealFormContent {...props}></DealFormContent>;
  },
  formatToSnake: formatAutomationDealForm
};

const DealsCreationNode = React.memo(({ selected, id, data, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const AllElementsRef = useContext(ElementsRefContext);
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleDoubleClick = () => {
    openSidebar(
      <EntityAutomationCreationSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        CurrentNodeId={id}
        settings={settings}
        data={data}
        handleSubmit={handleSubmit}
        AllElements={AllElementsRef.current}
      />
    );
  };
  const { enabled, config } = data;
  const intl = useIntl();
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <AutomationCreation
          config={config}
          enabled={enabled}
          type={type}
          selected={selected}
          onDoubleClick={handleDoubleClick}
          icon={faHandshake}
          text={intl.formatMessage({ id: "CREATE_DEAL" })}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default DealsCreationNode;
