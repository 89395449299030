import React, { useRef, useEffect, useState, useMemo } from "react";
import { DealFormButton, SubscriptionFormButton } from "../Form/FormButtons";
import { FormattedMessage, useIntl } from "react-intl";

import { CovenantValue } from "../../Components/CovenantSelector/CovenantSelector";
import {
  createFormData,
  toIsoString,
  formatDurationHMS,
  originTypes
} from "../../Helpers/MiscHelper";
import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import moment from "moment";
import { LabelTitle } from "../../Helpers/GridHelper";
import "./HourControll.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignJustify,
  faCalendarAlt,
  faCalendarExclamation,
  faChevronLeft,
  faChevronRight
} from "@fortawesome/pro-light-svg-icons";

import classnames from "classnames";

import {
  faArrowAltFromRight,
  faArrowAltFromLeft
} from "@fortawesome/pro-solid-svg-icons";
import Popup from "../../Components/Popup/Popup";
import { useHoverEvents } from "../Origin/Origin";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";
import {
  spaceInterventionsSchema,
  spaceSubscriptionSchema,
  spaceSubscriptionTagsSchema
} from "../../config/schema";
import { OriginPopupLoader } from "../../Components/Origin/Origin";
import { ConnectedInterventionOrigin } from "../Origin/InterventionOrigin";
import { SubscriptionsClipboardButton } from "../../Helpers/HooksHelper";
import EntityReadButton from "../../Components/EntityRead/EntityReadButton";
import { DeleteButtonContextMenu } from "../../Components/CGrid/DeleteMultipleModal";
import {
  InnerColorFilterBars,
  TriggeredColoredFilterProvider,
  useTriggeredColorFilters
} from "../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { canUserEditModule, moduleTypes } from "../../Helpers/ModulesHelper";
import {
  useCurrentAccount,
  useCurrentAccountSpace
} from "../../Contexts/UserContext";
import { SubscriptionStatusTypes } from "../../Components/Subscription/SubscriptionProfile";
import { useColumnsSize } from "../../Components/VirtualGrid/VirtualGridResizer";
import { getInterventionTarget } from "../../Helpers/FormFormmaterHelper";
import {
  createClientFilter,
  createDepartmentFilter,
  createFollowingFilter,
  createManagerFilter,
  createNotFollowingFilter,
  createOrganizationFilter,
  createReadFilter,
  createSubctriptionTypeFilter,
  createSubscriptionStatusFilter,
  createTeamFilter,
  createUnreadFilter,
  createUserFilter,
  createUpgradedPipelineStatusFilter,
  createSubscriptionTagFilter
  // createPipelineStatusFilter
} from "../../Helpers/FilterHelper";
import { ConnectedAccountProfile } from "../../Components/Accounts/AccountProfile";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";
import BreadcrumbsPopup from "../../Components/Breadcrumbs/BreadcrumbsPopup";

const subscriptionPipelineSettings = {
  field: "Subscription/PipelineId",
  pipelineStatusField: "Subscription/PipelineStatusId",
  type: PipelineTypeEnum.Subscription
};

export const defaultSubscriptionForm = {
  Deslocations: 0,
  Covenant: new CovenantValue(),
  Description: "",
  Contact: "",
  Client: "",
  BeginDate: new Date(),
  EndDate: moment().add(1, "d").toDate(),
  SalesOpportunity: "",
  Manager: null,
  ContractType: null,
  InternalDescription: "",
  Files: null,
  Departments: [],
  TeamCollab: [],
  Schedules: [],
  Followers: [],
  Status: 1, // ativo

  PeriodTime: 0,
  BillingPeriod: new CovenantValue()
};

export const useSubscriptionFilters = () => {
  const CurrentAccount = useCurrentAccount();
  return useMemo(() => {
    return [
      {
        propField: "NoTagDescription",
        icon: <FontAwesomeIcon icon={faAlignJustify} />,
        titleId: "DESCRIPTION",
        field: "Subscription/NoTagDescription",
        type: "string"
      },
      createClientFilter({ field: "Subscription/ClientId" }),
      createSubctriptionTypeFilter({
        propField: "SubscriptionType",
        icon: <FontAwesomeIcon icon={faCalendarExclamation} />
      }),
      createTeamFilter(),
      createSubscriptionStatusFilter({
        defaultFilters: [
          {
            field: "PipelineStatus/Status",
            operator: "eq",
            value: 1
          }
        ]
      }),
      // createPipelineStatusFilter({
      //   pipelineSettings: subscriptionPipelineSettings
      // }),
      createSubscriptionTagFilter({ requiresParams: true }),
      createUpgradedPipelineStatusFilter({
        pipelineSettings: subscriptionPipelineSettings
      }),
      {
        propField: "EndDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "END_DATE",
        field: "Subscription/EndDateNoHours",
        type: "dateTime"
      },
      {
        propField: "PipelineDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_PIPELINE_STATUS_DATE",
        field: "Subscription/PipelineDate",
        type: "dateTime"
      },
      {
        propField: "StatusDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_STATUS_DATE",
        field: "Subscription/StatusDate",
        type: "dateTime"
      },
      // {
      //   propField: "BeginDate",
      //   icon: <FontAwesomeIcon icon={faCalendarAlt} />,
      //   titleId: "START_DATE",
      //   field: "Subscription/BeginDate",
      //   type: "dateTime"
      // },
      createUserFilter({
        propField: "Creator",
        titleId: "CREATED_BY",
        field: "Creator/Id",
        DescriptionComponent: ({ item, value }) => {
          return (
            <div>
              <FormattedMessage id={"CREATED_BY"} />{" "}
              <ConnectedAccountProfile account={value} />
            </div>
          );
        }
      }),
      {
        propField: "BeginDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "START_DATE",
        field: "Subscription/BeginDateNoHours",
        type: "dateTime"
      },
      createDepartmentFilter(),
      createOrganizationFilter({ field: "Company/Id" }),
      createReadFilter({
        baseField: "Subscription"
      }),
      {
        propField: "Id",
        icon: <FontAwesomeIcon icon={faCalendarExclamation} />,
        titleId: "SUBSCRIPTION_NR",
        field: "Subscription/Id",
        type: "number"
      },
      createUnreadFilter({
        baseField: "Subscription"
      }),
      createFollowingFilter({
        baseField: "Subscription"
      }),
      createNotFollowingFilter({
        baseField: "Subscription"
      }),
      // { title: "Client", field: "Client/Name", type: "string" },

      createManagerFilter({
        propField: "Manager",
        defaultFilters: [
          {
            field: "Assigned/Id",
            operator: "eq",
            value: CurrentAccount.Id
          }
        ]
      })
    ];
  }, [CurrentAccount.Id]);
};

export const formatSubscriptionForm = (form) => {
  const {
    Files,
    Contact,
    Manager,
    Subscription,
    Client,
    SubscriptionType,
    Covenant,
    TeamCollab,
    BeginDate,
    EndDate,
    Company,
    Pipeline,
    Tags,
    StatusDetail,
    StatusDetailDescription,
    PipelineStatus,
    Departments,
    // StatusDetail,
    SalesOpportunity,
    Description,
    InternalDescription,
    StatusDetailId,
    Followers
    // BillingPeriod,
    // ...formRest
  } = form;

  const contactId = Contact;
  const managerId = Manager;
  const subscriptionId = Subscription;
  const organizationid = Company;
  let accounts = [],
    teams = [];

  for (const item of TeamCollab) {
    if (item.type === "Account") accounts.push(item.data);
    else if (item.type === "Team") teams.push(item.data);
  }

  let tagsIds = [];
  if (Tags && Array.isArray(Tags))
    for (const e of Tags) {
      const { TempId } = getEntity(spaceSubscriptionTagsSchema, e.data);
      if (TempId) tagsIds.push(TempId);
    }

  const { TimeValue, TimeUnit } = Covenant;

  return createFormData(
    {
      //   ...formRest,
      clientId: Client.data,
      managerId,
      contactId,
      tagsIds,
      Departments,
      EntityTypeId: SubscriptionType,
      BeginDate: toIsoString(BeginDate),
      EndDate: toIsoString(EndDate),
      TimeValue,
      TimeUnit,
      SalesOpportunity,
      Description,
      InternalDescription,
      PipelineId: Pipeline.Id ? Pipeline.Id : Pipeline,
      PipelineStatusId: PipelineStatus.Id ? PipelineStatus.Id : PipelineStatus,
      StatusDetailId: StatusDetail,
      StatusDetailDescription,
      subscriptionId,
      accounts,
      teams,
      organizationid,

      Followers
    },
    Files
  );
};

export const OutsideContractHours = ({
  LeftDurationNotInRange,
  RightDurationNotInRange,
  Entity
}) => {
  if (!LeftDurationNotInRange && !RightDurationNotInRange) {
    return <div>―</div>;
  }

  return (
    <>
      <div className="HoursOutside-Container p-1">
        {LeftDurationNotInRange && (
          <LeftAnchor
            LeftDurationNotInRange={LeftDurationNotInRange}
            Entity={Entity}
          />
        )}
        {RightDurationNotInRange && (
          <RightAnchor
            RightDurationNotInRange={RightDurationNotInRange}
            Entity={Entity}
          />
        )}
      </div>
    </>
  );
};

const LeftAnchor = ({ LeftDurationNotInRange, Entity }) => {
  const anchorLeftRef = useRef();

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  return (
    <>
      <Popup
        anchorEl={anchorLeftRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <LeftPopup Entity={Entity} />
      </Popup>
      <div
        ref={anchorLeftRef}
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        className="HoursOutside-Component text-truncate mr-2 d-flex rounded align-items-center justify-content-center"
      >
        <FontAwesomeIcon className="mr-2" icon={faArrowAltFromRight} />
        <span>{formatDurationHMS(LeftDurationNotInRange)}</span>
      </div>
    </>
  );
};

const LeftPopup = ({ Entity }) => {
  const {
    data
    // loading,
    // error
  } = useSpaceQuery(
    `interventions?$orderby=TimeCharge/Id%20asc&$filter=((DirectIndirectContract/Id eq '${Entity.Id}') and (TimeCharge/BeginDateNoHours lt '${Entity.BeginDate}'))&$top=10`,
    [spaceInterventionsSchema]
  );

  if (!data) return <OriginPopupLoader />;

  return (
    <div className="bg-white" style={{ maxWidth: 300, maxHeight: 500 }}>
      <div className="of-y-auto h-100 position-relative">
        {data.map((e, i) => (
          <div key={i} className="ar-treeview-item">
            <div className="flex-1 d-flex align-items-center ar-treeview-item-content h-100 justify-content-start">
              <ConnectedInterventionOrigin intervention={e} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const RightAnchor = ({ RightDurationNotInRange, Entity }) => {
  const anchorRightRef = useRef();

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  return (
    <>
      <Popup
        anchorEl={anchorRightRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <RightPopup Entity={Entity} />
      </Popup>
      <div
        ref={anchorRightRef}
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        className="HoursOutside-Component text-truncate d-flex rounded align-items-center justify-content-center"
      >
        <FontAwesomeIcon className="mr-2" icon={faArrowAltFromLeft} />
        <span>{formatDurationHMS(RightDurationNotInRange)}</span>
      </div>
    </>
  );
};

const RightPopup = ({ Entity }) => {
  const {
    data
    // loading,
    // error
  } = useSpaceQuery(
    `interventions?$orderby=TimeCharge/Id%20asc&$filter=((DirectIndirectContract/Id eq '${Entity.Id}') and (TimeCharge/BeginDateNoHours gt '${Entity.EndDate}'))&$top=10`,
    [spaceInterventionsSchema]
  );

  if (!data) return <OriginPopupLoader />;

  return (
    <div className="bg-white" style={{ maxWidth: 300, maxHeight: 500 }}>
      <div className="of-y-auto h-100 position-relative">
        {data.map((e, i) => (
          <div key={i} className="ar-treeview-item">
            <div className="flex-1 d-flex align-items-center ar-treeview-item-content h-100 justify-content-start">
              <ConnectedInterventionOrigin intervention={e} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const HourControll = ({
  TimeChargesDurationByRenovation = [],
  PeriodTime,
  Entity
}) => {
  const HoursContainerRef = useRef();
  const PeriodsArr = [];
  const GridWider = useColumnsSize();

  const [currenteWidth, setCurrentWidth] = useState(
    GridWider
      ? GridWider["Subscription/HourControl"]
        ? GridWider["Subscription/HourControl"]
        : 250
      : 250
  );
  const [indexOfObjInView, setIndexArr] = useState(null);
  useEffect(() => {
    const handleRerender = () => {
      const resolvedGridWider = GridWider || {};
      const GridWidth = resolvedGridWider["Subscription/HourControl"]
        ? resolvedGridWider["Subscription/HourControl"]
        : 250;
      if (GridWidth !== currenteWidth) {
        setCurrentWidth(GridWidth);
        setIndexArr(null);
      }
    };
    const timeout = setTimeout(() => {
      handleRerender();
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [currenteWidth, GridWider]);

  const numberOfSpotsAvailable = useMemo(
    () => Math.floor((currenteWidth - 35) / 60),
    [currenteWidth]
  );

  if (
    !TimeChargesDurationByRenovation ||
    TimeChargesDurationByRenovation.length === 0
  ) {
    return <div>―</div>;
  }

  TimeChargesDurationByRenovation.forEach((e) => {
    if (e && e.Begin && e.End) {
      PeriodsArr.push(e);
    }
  });

  if (PeriodsArr.length === 0) return <div>―</div>;

  const HoursComponents = PeriodsArr.map((e, i) => {
    return (
      <HourComponent
        key={i}
        Entity={Entity}
        Value={e}
        PeriodTime={PeriodTime}
      />
    );
  });

  let resolvedItems;
  if (indexOfObjInView === null) {
    if (numberOfSpotsAvailable >= HoursComponents.length) {
      resolvedItems = HoursComponents;
    } else {
      resolvedItems = HoursComponents.slice(
        HoursComponents.length - numberOfSpotsAvailable
      );
    }
  } else {
    resolvedItems = indexOfObjInView.map((e) => {
      return HoursComponents[e];
    });
  }

  const leftClick = () => {
    const tempArr = [];
    let startIndex =
      indexOfObjInView !== null
        ? indexOfObjInView[0] - 1
        : HoursComponents.length - numberOfSpotsAvailable - 1;
    for (let i = 0; i < numberOfSpotsAvailable; i++) {
      tempArr.push(startIndex);
      startIndex++;
    }
    setIndexArr(tempArr);
  };

  const rightClick = () => {
    const tempArr = [];
    let startIndex =
      indexOfObjInView !== null
        ? indexOfObjInView[0] + 1
        : HoursComponents.length - numberOfSpotsAvailable + 1;
    for (let i = 0; i < numberOfSpotsAvailable; i++) {
      tempArr.push(startIndex);
      startIndex++;
    }
    setIndexArr(tempArr);
  };

  let LeftButton, RightButton;
  if (numberOfSpotsAvailable >= HoursComponents.length) {
    LeftButton = (
      <div className="HoursControll-controller disable-selection visibility-none mr-1 p-1 d-flex align-items-center h-100">
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
    RightButton = (
      <div className="HoursControll-controller disable-selection px-1 visibility-none d-flex align-items-center h-100">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  } else if (indexOfObjInView === null) {
    LeftButton = (
      <div
        onClick={leftClick}
        className="HoursControll-controller disable-selection cursor-pointer p-1 mr-1 d-flex align-items-center h-100"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
    RightButton = (
      <div className="HoursControll-controller disable-selection px-1 visibility-none d-flex align-items-center h-100">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  } else if (indexOfObjInView[0] === 0) {
    LeftButton = (
      <div className="HoursControll-controller disable-selection visibility-none mr-1 px-1 d-flex align-items-center h-100">
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
    RightButton = (
      <div
        onClick={rightClick}
        className="HoursControll-controller disable-selection p-1 cursor-pointer d-flex align-items-center h-100"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  } else if (
    indexOfObjInView[indexOfObjInView.length - 1] ===
    HoursComponents.length - 1
  ) {
    LeftButton = (
      <div
        onClick={leftClick}
        className="HoursControll-controller disable-selection cursor-pointer p-1 mr-1 d-flex align-items-center h-100"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
    RightButton = (
      <div className="HoursControll-controller disable-selection px-1 visibility-none d-flex align-items-center h-100">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  } else {
    LeftButton = (
      <div
        onClick={leftClick}
        className="HoursControll-controller disable-selection cursor-pointer p-1 mr-1 d-flex align-items-center h-100"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
    RightButton = (
      <div
        onClick={rightClick}
        className="HoursControll-controller disable-selection p-1 cursor-pointer d-flex align-items-center h-100"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onDoubleClick={(e) => {
        e.stopPropagation();
      }}
      className="HoursControll-Container disable-selection d-flex flex-row h-100 w-100"
    >
      {LeftButton}
      <div
        ref={HoursContainerRef}
        className="HoursControll-Hours w-100 of-hidden"
      >
        {resolvedItems}
      </div>
      {RightButton}
    </div>
  );
};

const HourComponent = ({ Entity, Value, PeriodTime, ...rest }) => {
  const Begin = new Date(Value.Begin);
  const End = new Date(Value.End);
  const Today = new Date();
  const Current = Today > Begin && Today < End;
  const FormatedTime = moment(Begin).format("DD, MMM");

  const anchorRef = useRef();

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  return (
    <>
      <Popup
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <HourComponentPopup
          Entity={Entity}
          Begin={Value.Begin}
          End={Value.End}
        />
      </Popup>
      <div
        ref={anchorRef}
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        className={classnames(
          "mr-1 rounded pb-1 disable-selection",
          { "HoursControll-HourComponent-basic": Value.Duration < PeriodTime },
          {
            "HoursControll-HourComponent-OverDone": Value.Duration >= PeriodTime
          },
          { "HoursControll-Current": Current }
        )}
        {...rest}
      >
        <div className="fs-12">{FormatedTime}</div>
        <div style={{ textTransform: "none" }} className="fw-medium fs-14">
          {formatDurationHMS(Value.Duration)}
        </div>
      </div>
    </>
  );
};

const HourComponentPopup = ({ Entity, Begin, End }) => {
  const {
    data
    // loading,
    // error
  } = useSpaceQuery(
    `interventions?$orderby=TimeCharge/Id%20asc&$filter=((DirectIndirectContract/Id eq '${Entity.Id}') and (TimeCharge/BeginDateNoHours gt '${Begin}') and (TimeCharge/BeginDateNoHours lt '${End}'))&$top=10`,
    [spaceInterventionsSchema]
  );

  if (!data) return <OriginPopupLoader />;

  return (
    <div className="bg-white" style={{ maxWidth: 300, maxHeight: 500 }}>
      <div className="of-y-auto h-100 position-relative">
        {data.map((e, i) => (
          <div key={i} className="ar-treeview-item">
            <div className="flex-1 d-flex align-items-center ar-treeview-item-content h-100 justify-content-start">
              <ConnectedInterventionOrigin intervention={e} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const validateSubscriptionForm = (
  {
    Client,
    Contact,
    EstimatedCost,
    BeginDate,
    EndDate,
    Manager,
    SubscriptionType,
    Description,
    InternalDescription,
    Company,
    Pipeline,
    PipelineStatus
  },
  addError
) => {
  if (!Company) addError("Company");
  if (!Client) addError("Client");
  // if (!Contact) addError("Contact");
  if (!Pipeline) addError("Pipeline");
  if (!PipelineStatus) addError("PipelineStatus");
  if (!SubscriptionType) addError("SubscriptionType");
  if (!BeginDate) addError("BeginDate");
  if (!EndDate) addError("EndDate");
  if (BeginDate && EndDate && EndDate < BeginDate)
    addError(
      "EndDate",
      <FormattedMessage id="FORM_END_DATA_BIGGER_THAN_START_DATA" />
    );
  if (!Description) addError("Description");
  if (Description?.length > 65535)
    addError("Description", <FormattedMessage id="DESCRIPTION_TOO_BIG" />);
  if (InternalDescription && InternalDescription?.length > 65535)
    addError(
      "InternalDescription",
      <FormattedMessage id="INTERNAL_DESCRIPTION_TOO_BIG" />
    );
  if (!Manager) addError("Manager");
};

export const convertSubscriptionEntityToForm = (entity) => {
  const { Collaborators, Tags, ...entityRest } = entity;

  const form = { ...entityRest, Client: getInterventionTarget(entity) };

  const { Accounts, Teams } = Collaborators || {};

  if (Accounts || Teams) {
    let data = [];

    if (Accounts && Array.isArray(Accounts)) {
      for (let i = 0; i < Accounts.length; i++) {
        if (Accounts[i].type === "Account" && Accounts[i].data)
          data.push(Accounts[i]);
        else data.push({ type: "Account", data: Accounts[i] });
      }
    }
    if (Teams && Array.isArray(Teams)) {
      for (let i = 0; i < Teams.length; i++) {
        if (Teams[i].type === "Team" && Teams[i].data) data.push(Teams[i]);
        else data.push({ type: "Team", data: Teams[i] });
      }
    }

    form.TeamCollab = data;
  }

  if (Array.isArray(Tags) && Tags.length > 0) {
    const resolvedTags = [];
    for (const t of Tags) {
      resolvedTags.push({
        data: t,
        type: "SubscriptionTag"
      });
    }
    form.Tags = resolvedTags;
  }

  return form;
};

export const SubscriptionEntityButtons = ({ entity, skeleton, onSuccess }) => {
  // const isPositiveStatus = entity.Status !== ContractStatusTypes.closed;

  return (
    <React.Fragment>
      <SubscriptionFormButton
        canEdit={entity.CanEdit}
        id={entity.Id}
        skeleton={skeleton}
        className="mr-2"
        onSuccess={onSuccess}
      />
      {/* {isPositiveStatus && (
        <CallFormButton
          alt
          className="mr-2"
          skeleton={skeleton}
          formProps={{ contractId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <TicketFormButton
          alt
          className="mr-2"
          skeleton={skeleton}
          formProps={{ contractId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}

      {isPositiveStatus && (
        <TaskFormButton
          className="mr-2"
          skeleton={skeleton}
          alt
          formProps={{ contractId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <InterventionFormButton
          alt
          className="mr-2"
          skeleton={skeleton}
          formProps={{ taskOrigin: entity, contractId: entity.Id }}
          onSuccess={onSuccess}
        />
      )} */}
      <SubscriptionFormButton
        alt
        className="mr-2"
        onSuccess={onSuccess}
        skeleton={skeleton}
        id={entity.Id}
        duplicate
      />
      {/* <ContractPdfButton
        alt
        className="mr-2"
        skeleton={skeleton}
        id={entity.Id}
      />
      <ContractPdfButton
        alt
        className="mr-2"
        skeleton={skeleton}
        id={entity.Id}
        excel={true}
      /> */}
    </React.Fragment>
  );
};

export const SubscriptionActionsChildren = ({
  entity,
  minimal,
  sidebar,
  skeleton,
  detailsView,
  onSuccess
}) => {
  const accountSpace = useCurrentAccountSpace();
  const intl = useIntl();
  const isPositiveStatus =
    entity.Status !== SubscriptionStatusTypes.canceled &&
    entity.Status !== SubscriptionStatusTypes.lost;
  return (
    <>
      {!sidebar && !detailsView && (
        <SubscriptionFormButton contextIcons id={entity.Id} />
      )}
      {!detailsView && (
        <SubscriptionFormButton globalEntityView contextIcons id={entity.Id} />
      )}
      {canUserEditModule(accountSpace, moduleTypes.deals) &&
        isPositiveStatus && (
          <LabelTitle title={intl.formatMessage({ id: "SERVICES" })} />
        )}

      <DealFormButton
        skeleton={skeleton}
        onSuccess={onSuccess}
        className="mr-2"
        formProps={{ subscriptionId: entity.Id }}
      />

      {/* <LabelTitle title="Serviços" />
  <CallFormButton
    hasAccess={isPositiveStatus}
    formProps={{ contractId: entity.Id }}
  />
  <TicketFormButton
    hasAccess={isPositiveStatus}
    formProps={{ contractId: entity.Id }}
  />
  <TaskFormButton
    hasAccess={isPositiveStatus}
    formProps={{ contractId: entity.Id }}
  />
  <InterventionFormButton
    hasAccess={isPositiveStatus}
    formProps={{ taskOrigin: entity, contractId: entity.Id }}
  /> */}

      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      {!sidebar && entity.CanEdit && (
        <SubscriptionFormButton
          id={entity.Id}
          canEdit={entity.CanEdit}
          contextIcons
        />
      )}
      <SubscriptionFormButton id={entity.Id} duplicate className="mr-2" />

      {/* <ContractPdfButton id={entity.Id} />
  <ContractPdfButton id={entity.Id} excel={true} />
  <SendContractNotificationButton id={entity.Id} /> */}
      <SubscriptionsClipboardButton id={entity.Id} />
      <EntityReadButton value={entity} schema={spaceSubscriptionSchema} />
      <DeleteButtonContextMenu entity={entity} />
    </>
  );
};

export const SubscriptionActionsButton = ({ minimal, ...props }) => {
  return (
    <EntityPopup minimal={minimal} {...props}>
      <SubscriptionActionsChildren {...props} />
    </EntityPopup>
  );
};

export const SubscriptionEntityGridButtons = ({
  entity,
  minimal,
  hideBreadCrumbs,
  noColors
}) => {
  // const isPositiveStatus = entity.Status !== ContractStatusTypes.closed;
  const conds = useTriggeredColorFilters(entity);
  return (
    <TriggeredColoredFilterProvider conds={conds}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="d-flex align-items-center"
      >
        {!hideBreadCrumbs && (
          <BreadcrumbsPopup
            className="mr-2 fs-14 d-inline-flex text-primary"
            originType={originTypes.subscription}
            minimal
            id={entity.Id}
          />
        )}
        <SubscriptionFormButton
          minimal={minimal}
          canEdit={entity.CanEdit}
          id={entity.Id}
          className={classnames("mr-2 FormButtonClassID", {
            "ml-1": conds?.length > 0
          })}
        />
        <SubscriptionActionsButton entity={entity} minimal={minimal} />
        {!noColors && <InnerColorFilterBars />}
      </div>
    </TriggeredColoredFilterProvider>
  );
};
