import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { getInvite, captureInvite } from "../Redux/Actions/InviteActions";
import { withRouter, Redirect, Link } from "react-router-dom";
import querystring from "query-string";
import MainLoader from "../Components/Loader/MainLoader";
import Button from "../Components/Button/Button";
import MaintenanceIcon from "../Containers/Maintenance/MaintenanceIcon";
import AirdeskTextSvg from "../Components/Svg/AirdeskTextSvg";
import AirdeskInviteBackgroundSvg from "../Components/Svg/AirdeskInviteBackgroundSvg";
import { FormattedMessage } from "react-intl";
import { LogoutButton } from "../Containers/LogOutButton";
import { useSpaceEntitiesHub } from "../Containers/RealTime/RealTimeSpace";
import { removeCurrentUserAuthToken } from "../Helpers/AuthHelper";
import { useDelete } from "../Helpers/IOClient";
import LoaderSpinner from "../Components/Loader/LoaderSpinner/LoaderSpinner";

const InviteWarning = ({ header, text, buttonText, onClick, logout }) => {
  return (
    <div className="text-center d-flex align-items-center justify-content-center flex-column">
      <div className="text-warning fs-22">{header}</div>
      <div className="mt-2 fw-light">{text}</div>
      {logout && (
        <LogoutButton
          className="align-items-center ar-logout-button cursor-pointer d-flex w-auto"
          style={{ borderRadius: "7px" }}
        />
      )}
      {onClick && (
        <Button onClick={() => window.location.reload(true)}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

const InviteRemoveSession = ({ header, text, buttonText, onClick }) => {
  const entitiesHub = useSpaceEntitiesHub();
  localStorage.removeItem("VNI-T");
  const [EndSession] = useDelete(`account/logout`, null, {
    onSuccess: () => {
      if (entitiesHub) {
        entitiesHub.invoke("Logout");
      }
      removeCurrentUserAuthToken();
      window.location.reload();
    },
    onError: () => {
      if (entitiesHub) {
        entitiesHub.invoke("Logout");
      }
      removeCurrentUserAuthToken();
      window.location.reload();
    }
  });

  useEffect(() => {
    EndSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="text-center d-flex text-primary align-items-center justify-content-center flex-column">
      <LoaderSpinner size="sm" />
    </div>
  );
};

const InviteErrorEnum = {
  NotFound: "1", // Invitation not found
  InviteeAccountDoesNotCoincide: "2", // Invitee user doesn't coincide with current logged in user
  LoginRequired: "3", // You must be logged in to accept this invite.
  AlreadyAccepted: "4", // Invitation was already accepted.
  Expired: "5" // Invitation has expired
};

const useErrorComponent = (error) => {
  const { data } = error;
  return useMemo(() => {
    if (data && data.Message) {
      switch (data.Message) {
        case InviteErrorEnum.AlreadyAccepted:
          localStorage.removeItem("VNI-T");
          return (
            <InviteWarning
              header={<FormattedMessage id={"INVITE_INVALID"} />}
              text={<FormattedMessage id={"INVITE_ALREADY_ACCEPTED"} />}
            />
          );

        case InviteErrorEnum.Expired:
          localStorage.removeItem("VNI-T");
          return (
            <InviteWarning
              header={<FormattedMessage id={"INVITE_EXPIRED"} />}
              text={<FormattedMessage id={"INVITE_EXPIRED_DESCRIPTION"} />}
            />
          );

        case InviteErrorEnum.InviteeAccountDoesNotCoincide:
          return (
            <InviteRemoveSession />
            // <InviteWarning
            //   logout
            //   header={<FormattedMessage id={"INVITE_INVALID"} />}
            //   text={<FormattedMessage id={"INVITE_INVALID_ACCOUNT"} />}
            // />
          );

        case InviteErrorEnum.LoginRequired:
          return (
            <InviteWarning
              header={<FormattedMessage id={"INVITE_LOGIN_REQUIRED"} />}
              text={
                <FormattedMessage id={"INVITE_LOGIN_REQUIRED_DESCRIPTION"} />
              }
            />
          );

        case InviteErrorEnum.NotFound:
          localStorage.removeItem("VNI-T");
          return (
            <InviteWarning
              header={<FormattedMessage id={"INVITE_INVALID"} />}
              text={<FormattedMessage id={"INVITE_NOT_FOUND_DESCRIPTION"} />}
            />
          );

        default:
          break;
      }
    }

    localStorage.removeItem("VNI-T");
    return (
      <InviteWarning
        header={<FormattedMessage id={"INVITE_INVALID"} />}
        text={<FormattedMessage id={"INVITE_UNKNOWN_DESCRIPTION"} />}
      />
    );
  }, [data]);
};

const InviteWarningContainer = ({ error, isAuthenticated, inviteId }) => {
  const comp = useErrorComponent(error);
  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem("VNI-T", inviteId);
    }
  }, [inviteId, isAuthenticated]);

  return (
    <div className="ssi-onboarding-page">
      <MaintenanceIcon absolute />
      <div className="content p-5">
        <AirdeskTextSvg className="mb-5 w-75 mx-auto airdesk-logo" />
        {comp}
        <Link
          className="ssi-control mt-5 ssi-button ssi-button-secondary text-center"
          to={isAuthenticated ? "/s" : "/login"}
        >
          {isAuthenticated ? (
            <FormattedMessage id={"BACK"} />
          ) : (
            <FormattedMessage id={"SIGN_IN"} />
          )}
        </Link>
      </div>
      <div className="image">
        <AirdeskInviteBackgroundSvg className="center-el" />
      </div>
    </div>
  );
};

const withOptionalInvite = (WrappedComponent, getInviteId) => {
  const Comp = ({
    hasCaptured,
    isCapturing,
    isFetching,
    error,
    invite,
    location,
    match,
    history,
    getInvite,
    captureInvite,
    isAuthenticated,
    ...rest
  }) => {
    const inviteId = useMemo(() => {
      if (getInviteId)
        return getInviteId({
          match
        });
      const queryObj = querystring.parse(location.search);
      const { invite } = queryObj;
      if (invite) return invite;
      else return null;
    }, [location.search, match]);

    useEffect(() => {
      if (!inviteId || invite || error) return;

      getInvite(inviteId);
    }, [error, getInvite, invite, inviteId]);

    useEffect(() => {
      if (!invite || !invite.Account || hasCaptured || isCapturing || error)
        return;

      captureInvite(inviteId);
    }, [captureInvite, error, hasCaptured, invite, inviteId, isCapturing]);

    if (error) {
      return (
        <InviteWarningContainer
          isAuthenticated={isAuthenticated}
          error={error}
          inviteId={inviteId}
        />
      );
    }

    const isLoading = inviteId && (!invite || (invite.Account && !hasCaptured));

    if (isLoading) return <MainLoader isLoading disableExit />;

    if (hasCaptured) {
      // createToast({
      //   pos: "tm",
      //   type: "success",
      //   description: `${intl.formatMessage({
      //     id: "JOINED_THE_SPACE"
      //   })} ${invite.Space.Name}`
      // });

      localStorage.removeItem("VNI-T");

      return <Redirect to={`/s/${invite.Space.Id}/welcome`} />;
    }

    return <WrappedComponent {...rest} invite={invite} inviteId={inviteId} />;
  };

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(Comp));
};

const mapStateToProps = (state) => {
  const inviteManager = state.InviteManager;

  const { isFetching, error, invite, isCapturing, hasCaptured } = inviteManager;

  const resolvedIsFetching = isFetching || Boolean(!invite);

  return {
    isFetching: resolvedIsFetching,
    error,
    invite,
    isCapturing,
    hasCaptured,
    isAuthenticated: state.Auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInvite: (inviteId) => dispatch(getInvite(inviteId)),
    captureInvite: (inviteId) => dispatch(captureInvite(inviteId))
  };
};

export default withOptionalInvite;
