import React from "react";
import { ContactFormButton } from "../Form/FormButtons";
import { hasPhoneNumberError, isEmail } from "../../Helpers/MiscHelper";
import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import { LabelTitle } from "../../Helpers/GridHelper";
import { FormattedMessage } from "react-intl";
import { DeleteButtonContextMenu } from "../../Components/CGrid/DeleteMultipleModal";
import {
  InnerColorFilterBars,
  TriggeredColoredFilterProvider,
  useTriggeredColorFilters
} from "../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import classnames from "classnames";
export const defaultContactForm = {
  Clients: [],
  Name: "",
  Email: "",
  Role: "",
  PhoneNumber: "",
  MobileNumber: "",
  Description: "",
  Status: 1 //ativo
};

export const validateContactForm = (
  {
    // Clients,
    Name,
    Email,
    GDPRType,
    PhoneNumber,
    MobileNumber,
    Status,
    Description
  },
  addError
) => {
  if (!Name) addError("Name");

  if (hasPhoneNumberError(PhoneNumber))
    addError("PhoneNumber", <FormattedMessage id="INVALID_NUMBER_WARNING" />);

  if (Email && !isEmail(Email))
    addError("Email", <FormattedMessage id="EMAIL_CORRECT" />);

  if (hasPhoneNumberError(MobileNumber))
    addError("MobileNumber", <FormattedMessage id="INVALID_NUMBER_WARNING" />);

  if (!GDPRType) addError("GDPRType");
  if (!Status) addError("Status");
};

export const ContactEntityButtons = ({ entity, skeleton, onSuccess }) => {
  return (
    <React.Fragment>
      <ContactFormButton
        className="mr-2"
        onSuccess={onSuccess}
        canEdit={entity.CanEdit}
        skeleton={skeleton}
        id={entity.Id}
      />
      <ContactFormButton skeleton={skeleton} alt duplicate id={entity.Id} />
    </React.Fragment>
  );
};

export const ContactEntityActionsChildren = ({
  entity,
  minimal,
  sidebar,
  detailsView
}) => {
  return (
    <>
      {!sidebar && !detailsView && (
        <ContactFormButton contextIcons id={entity.Id} />
      )}
      {!detailsView && (
        <ContactFormButton globalEntityView contextIcons id={entity.Id} />
      )}

      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      {!sidebar && entity.CanEdit && (
        <ContactFormButton
          id={entity.Id}
          canEdit={entity.CanEdit}
          contextIcons
        />
      )}
      <ContactFormButton duplicate id={entity.Id} />
      <DeleteButtonContextMenu entity={entity} />
    </>
  );
};

export const ContactEntityActionsButton = ({ minimal, ...props }) => {
  return (
    <EntityPopup minimal={minimal}>
      <ContactEntityActionsChildren minimal={minimal} {...props} />
    </EntityPopup>
  );
};

export const ContactEntityGridButtons = ({ entity, minimal }) => {
  const conds = useTriggeredColorFilters(entity);
  return (
    <TriggeredColoredFilterProvider conds={conds}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="d-flex align-items-center"
      >
        <ContactFormButton
          minimal={minimal}
          className={classnames("mr-2 FormButtonClassID", {
            "ml-1": conds?.length > 0
          })}
          canEdit={entity.CanEdit}
          id={entity.Id}
        />
        <ContactEntityActionsButton entity={entity} minimal={minimal} />
        <InnerColorFilterBars />
      </div>
    </TriggeredColoredFilterProvider>
  );
};
