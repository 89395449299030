import React, { useMemo } from "react";
import {
  DetailsEntities,
  DetailsClientEntity,
  DetailsContactEntity,
  DetailsTaskQueryableEntities,
  DetailsInterventionQueryableEntities,
} from "../../../Components/DetailsView/DetailsChildren/DetailsChildren";

export const ProjectDetailsChildren = ({ value }) => {
  const { Contact, Client, IndirectClient, Id } = value;
  const formProps = useMemo(() => {
    return { projectId: Id };
  }, [Id]);
  ////
  return (
    <DetailsEntities formProps={formProps}>
      <DetailsClientEntity id={Client || IndirectClient} />
      <DetailsContactEntity id={Contact} />
      {/* <DetailsContractEntities filter={`Contract/ProjectId=${id}`} /> */}
      <DetailsTaskQueryableEntities
        filter={`(DirectIndirectProject/Id eq '${Id}') and (PipelineStatus/Status eq '1')`}
      />
      <DetailsInterventionQueryableEntities
        filter={`(DirectIndirectProject/Id eq ${Id})`}
      />
    </DetailsEntities>
  );
};
