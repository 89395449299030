import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./Helpers/MomentLangHelper";
// import logo from './logo.svg';

import "./App.css";
import Login from "./Containers/Login/Login";
// import PrivateRoute from "./Containers/Routes/PrivateRoute";
import PublicRoute from "./Containers/Routes/PublicRoute";
import Register from "./Containers/Register/Register";
import Invites from "./Containers/Invites/Invites";
import Recovery from "./Containers/Recovery/Recovery";
import PrivateRoute from "./Containers/Routes/PrivateRoute";
import MainApp from "./MainApp";
import Feedback from "./Containers/Feedback/Feedback";
// import MaintenanceView from "./Components/MaintenanceView/MaintenanceView";
class App extends Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route path="/feedback" component={Feedback} />
					<Route path="/login" component={Login} />
					<Route path="/register" component={Register} />
					<Route path="/invites/:inviteId" component={Invites} />
					<PublicRoute path="/recovery" component={Recovery} />
					<PrivateRoute>
						<MainApp />
					</PrivateRoute>
				</Switch>
			</Router>
		);
	}
}

export default App;
