import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext
} from "react";
import api from "../../api";
import { connect } from "react-redux";
import { finishNotificationsSetup } from "../../Redux/Actions/NotificationActions";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import Button from "../../Components/Button/Button";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { FormattedMessage } from "react-intl";
import { Intl18nContext } from "../Intl18n/Intl18nProvider";
import { UseOneSignalPlayerId } from "../../Helpers/OneSignalHelper";
import { client } from "../../Helpers/IOClient";

const NotificationsSetup = ({ finishNotificationsSetup }) => {
  const [loadingState, setLoadingState] = useState(
    window.oneSignalLoadingState
  );
  useEffect(() => {
    loadingState === false && finishNotificationsSetup();
  }, [finishNotificationsSetup, loadingState]);

  useLayoutEffect(() => {
    if (loadingState !== undefined) return;
    window.onOneSignalLoadingStateChange = (loadingState) => {
      setLoadingState(loadingState);
    };

    return () => delete window.onOneSignalLoadingStateChange;
  }, [loadingState]);

  const [isProcessing, setIsProcessing] = useState(true);
  const [error, setError] = useState(null);
  // const [OnesignalId, setOnesignalId] = useState(null);
  const { value } = useContext(Intl18nContext);
  const account = useCurrentAccount();

  const OneSignal = window.OneSignal;

  const markUserOneSignal = async () => {
    setIsProcessing(true);
    await OneSignal?.setExternalUserId(account.Id);

    const OnesignalUserId = await OneSignal?.getUserId();

    api
      .post("notifications/subscribe", { PlayerId: OnesignalUserId })
      .then(() => {
        return finishNotificationsSetup();
      })
      .catch((e) => {
        setError(e);
        return finishNotificationsSetup();
      });
  };

  useEffect(() => {
    OneSignal?.push(() => {
      try {
        const areNotificationsSupported =
          OneSignal?.isPushNotificationsSupported();
        if (!areNotificationsSupported) return finishNotificationsSetup();

        setIsProcessing(false);
      } catch (error) {
        setError(error);
        return finishNotificationsSetup();
      }
    });
  }, [OneSignal, finishNotificationsSetup]);

  const setNotification = async () => {
    setIsProcessing(true);

    timerRef.current = setTimeout(() => {
      finishNotificationsSetup();
    }, 10000);

    try {
      // const isSubscribed =
      //   OneSignal?.isPushNotificationsEnabled &&
      //   (await OneSignal?.isPushNotificationsEnabled());

      // if(timedOut) return;

      // if (isSubscribed) {
      //   return await markUserOneSignal();
      // }

      // if (userId) {
      //   await OneSignal.setSubscription(true);
      //   return markUserOneSignal(userId);
      // }

      OneSignal.once("subscriptionChange", async (isSubscribed) => {
        if (!isSubscribed) return finishNotificationsSetup();
        await markUserOneSignal();
      });

      const permission =
        OneSignal?.getNotificationPermission &&
        (await OneSignal?.getNotificationPermission());

      if (permission === "denied") {
        clearTimeout(timerRef.current);
        return finishNotificationsSetup();
      }

      if (permission === "default") {
        OneSignal.once(
          "notificationPermissionChange",
          async (permissionChange) => {
            clearTimeout(timerRef.current);
            if (permissionChange.to !== "granted") {
              window.OneSignal.push(async function () {
                const id = await window.OneSignal?.getUserId();
                if (typeof id === "string") {
                  await client.post(`account/onesignal/${id}/1`);
                }
              });

              setIsProcessing(false);
              finishNotificationsSetup();
            } else {
              finishNotificationsSetup();
            }
          }
        );
      }

      OneSignal.push(async () => {
        await OneSignal?.showNativePrompt();
      });

      OneSignal.push(async () => {
        await OneSignal?.registerForPushNotifications();
        await OneSignal?.setSubscription(true);
      });
    } catch (e) {
      return finishNotificationsSetup();
    }
  };

  const timerRef = useRef();

  const handleRejectButton = async () => {
    setIsProcessing(true);
    localStorage.setItem("notification_setup", "1");
    // document.cookie = `notification_setup=1;expires=Fri, 31 Dec 2150 23:59:59 GMT;domain=${document.domain};SameSite=Strict;`;
    timerRef.current = setTimeout(() => {
      finishNotificationsSetup();
    }, 2000);
    await OneSignal.setSubscription(false);
    clearTimeout(timerRef.current);
    finishNotificationsSetup();
  };

  const buildContent = () => {
    // if (error || loadingState) {
    //   // if (finishNotificationsSetup) finishNotificationsSetup();
    //   return <div>We had an error</div>;
    // }

    // if (!isProcessing && timerRef.current) clearTimeout(timerRef.current);

    if (isProcessing || loadingState === false)
      return <LoaderSpinner size="lg" className="text-primary" />;

    return (
      <div className="d-flex flex-column align-items-center">
        <div className="fs-28 mb-3 text-black d-flex justify-content-center">
          <span className="mb-1">
            <FormattedMessage id="NOTIFICATIONS" />
          </span>
        </div>

        <div className="mb-4">
          <FormattedMessage id="NOTIFICATIONS_CONF" />
        </div>
        <div>
          <Button className="mr-3" onClick={setNotification}>
            <FormattedMessage id="YES" />
          </Button>
          <Button onClick={handleRejectButton}>
            <FormattedMessage id="NO" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {/* <OnboardingHeader /> */}
      <div className="page d-flex justify-content-center w-100">
        {buildContent()}
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    finishNotificationsSetup: () => dispatch(finishNotificationsSetup())
  };
};

export default connect(null, mapDispatchToProps)(NotificationsSetup);
