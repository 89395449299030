// const datas = [
//   {
//     id: 1,
//     begin: new Date("2020-11-25 09:00"),
//     end: new Date("2020-11-25 09:30"),
//   },
//   {
//     id: 2,
//     begin: new Date("2020-11-25 09:00"),
//     end: new Date("2020-11-25 09:30"),
//   },
//   {
//     id: 3,
//     begin: new Date("2020-11-25 09:25"),
//     end: new Date("2020-11-25 10:00"),
//   },
//   // {
//   //     id: 6,
//   //     begin: new Date('2020-11-25 09:50'),
//   //     end: new Date('2020-11-25 10:20')
//   // },
//   // {
//   //     id: 4,
//   //     begin: new Date('2020-11-25 09:50'),
//   //     end: new Date('2020-11-25 10:20')
//   // },
//   // {
//   //     id: 7,
//   //     begin: new Date('2020-11-25 09:50'),
//   //     end: new Date('2020-11-25 10:20')
//   // },
//   {
//     id: 5,
//     begin: new Date("2020-11-25 09:55"),
//     end: new Date("2020-11-25 10:20"),
//   },
//   // {
//   //     id: 6,
//   //     begin: new Date('2020-11-25 09:55'),
//   //     end: new Date('2020-11-25 10:20')
//   // },
// ];
// window.datas = datas;

// const getLeft = (item, dict) => {
//   const {
//     value,
//     width,
//     allCollisions,
//     totalCollisions,
//     maxCollisions,
//     index,
//   } = item;

//   let totalCollisionsArr = [];

//   for (const collision of allCollisions) {
//     const x = dict[collision.metadata.Id];
//     const { width: colWidth, collisions, maxCollisions, index } = x;

//     totalCollisionsArr.push({
//       maxCollisions,
//       totalCollisions: collisions.length,
//       width: colWidth,
//       value: collision,
//       index,
//     });
//   }
//   if (value.metadata.Id === 4650) {

//   }
//   totalCollisionsArr.push({
//     totalCollisions,
//     maxCollisions,
//     width: width,
//     value,
//     index,
//   });
//   totalCollisionsArr = datesOrderBy(totalCollisionsArr);

//   let left = getMaxSizeOnSameLevel(item, totalCollisionsArr);
//   // for (const col of totalCollisionsArr) {
//   //   if (col.value === value) {
//   //     break;
//   //   }
//   //   const { width: colWidth } = dict[col.value.metadata.Id];
//   //   left += colWidth;
//   // }

//   return left;
// };

const getLeft = (arr, dict) => {
  var lines = getAllLinesOnSameLevel(arr.map((e) => e.value));

  for (let i = 0; i < lines.length; i++) {
    let orderByArray = [];
    const line = lines[i];

    const prevLine = lines[i - 1];
    const nextLine = lines[i + 1];

    for (const d of line) {
      orderByArray.push({
        value: dict[d.metadata.Id],
        hasChildrenPrevLevel: prevLine !== undefined && prevLine.includes(d),
        hasChildrenNextLevel: nextLine !== undefined && nextLine.includes(d),
      });
    }
    orderByArray.sort(
      (a, b) =>
        b.hasChildrenPrevLevel - a.hasChildrenPrevLevel ||
        a.hasChildrenNextLevel - b.hasChildrenNextLevel
    );
    // let left = 0;

    for (const d of orderByArray) {
      // const dictItem = dict[d.value.value.metadata.Id];
      // if (dictItem.left === undefined) {
      //   dict[d.value.value.metadata.Id].left = left;
      //   left += d.value.width;
      // }
      // else {
      const left = getOrderLeft(d, orderByArray);
      d.value.left = left;
      dict[d.value.value.metadata.Id].left = left;
      // }
    }
  }
};

const getOrderLeft = (item, line) => {
  if (item.value.left) return item.value.left;

  const arr = [...line.filter((e) => e.value.left !== undefined)];
  arr.sort((a, b) => (a.value.left || 1) - (b.value.left || 1));

  arr.unshift({
    value: {
      left: 0,
      width: 0,
    },
  });

  var index = arr.indexOf(item);
  if (index !== -1) arr.splice(index, 1);

  // if (item.hasChildrenNextLevel && !item.hasChildrenPrevLevel) {
  //   for (var i = line.length - 1; i >= 0; i--) {
  //     const lineDate = line[i];

  //     const { left: prevLeft, width: prevWidth } = (line[i - 1] &&
  //       line[i - 1].value) || {
  //       left: 0,
  //       width: 0,
  //     };
  //     let { left: currLeft, width: currWidth } = lineDate.value || {
  //       left: 0,
  //       width: 0,
  //     };

  //     if (!currLeft) currLeft = 100 - currWidth;

  //     if (Math.abs(prevLeft + prevWidth - currLeft) >= item.value.width)
  //       return prevLeft + prevWidth;
  //   }
  // } else {
  for (let i = /*index ? 0 : -1 */ 0; i < arr.length; i++) {
    const lineDate = arr[i];

    const { left: currLeft = 0, width: currWidth } = lineDate?.value || {
      left: 0,
      width: 0,
    };
    let { left: nextLeft = 100 } = arr[i + 1]?.value || {
      left: 100,
      width: 0,
    };

    // if (item.value.value.metadata.Id === 4573) ;
    if (currLeft === nextLeft) continue;

    if (
      Math.abs(parseInt(currLeft) + parseInt(currWidth) - parseInt(nextLeft)) >=
      parseInt(item.value.width)
    )
      return currLeft + currWidth;
  }
  // }
};

// const datesOrderBy = (dates) => {
//   return dates.sort(
//     (a, b) =>
//       //b.width - a.width ||
//       b.maxCollisions - a.maxCollisions ||
//       a.totalCollisions - b.totalCollisions ||
//       a.index - b.index
//   );
// };

// export const getSize = (date, allDates, dict) => {
//   ;
//   if (dict[date.metadata.Id].width) return dict[date.metadata.Id].width;

//   var colDates = getCollisionDates(date, allDates);

//   const result = getMaxCollisionsOnSameLevel(date, allDates);
//   const index = result.indexOf(date);
//   if (index !== -1) result.splice(index, 1);

//   const collisions = getCollisionsOnSameLevelChild(colDates, allDates);

//   const collisionsWidth = collisions.map((e) => 100 / (e.length + 1));

//   const otherMaxColliderWidth = collisionsWidth.reduce((a, b) => a + b, 0);

//   let mySize = 100 / (result.length + 1);

//   if (otherMaxColliderWidth + mySize < 100) {
//     const countHigherThanBaseSize =
//       collisionsWidth.filter((e) => parseInt(e) >= parseInt(mySize)).length + 1;

//     mySize +=
//       (100 - (mySize + otherMaxColliderWidth)) / countHigherThanBaseSize;
//   }

//   return mySize;
// };

export const getSize = (item, items, dict) => {
  if (dict[item.metadata.Id].width) return dict[item.metadata.Id].width;
  // var colDates = getCollisionDates(item, items);

  const result = getMaxCollisionsOnSameLevel(item, items, dict);
  const index = result.indexOf(item);
  if (index !== -1) result.splice(index, 1);

  // let collisions = getMaxCollisionsOnSameLevelChild(colDates, items);
  // const collisions = getCollisionsOnSameLevelChild(colDates, items);
  // if (item.metadata.Id === 4549) ;
  const collisionsWidth = getCollisionsChildrenWidth(result, items, dict);

  // const collisionsWidth = collisions.map((e) => 100 / (e.length + 1));

  const otherMaxColliderWidth = collisionsWidth.reduce((a, b) => a + b, 0);

  // colDates.push(item);
  // const otherMaxCollider = 100 / (collisions.length + 1);
  let mySize = 100 / (result.length + 1);

  // if (!loop) loop = 0;
  // loop++;
  // if (loop >= 2) {
  //   return {
  //     width: mySize,
  //     value: item,
  //     totalCollisions: result.length,
  //     collisions: result,
  //     allCollisions: colDates,
  //     maxCollisions: colDates.length,
  //   };
  // }

  if (otherMaxColliderWidth + mySize < 100) {
    const sizeOfitemsThatHaveLessSize = collisionsWidth
      .filter((e) => e < mySize)
      .reduce((a, b) => a + b, 0); //collisions.filter(e => 100/(e.length + 1) < mySize);

    const countItemsWithSameOrBiggerSize = collisionsWidth.filter(
      (e) => e >= mySize
    ).length;

    mySize =
      (100 - sizeOfitemsThatHaveLessSize) /
      (countItemsWithSameOrBiggerSize + 1);

    // mySize = 100 - otherMaxColliderWidth;
  }

  // if (mySize + otherMaxColliderWidth * result.length < 100) {
  //   const otherSize = getSize(result[0], items).width;

  //   if (otherSize + mySize !== 100)
  //     mySize = 100 - otherMaxCollider * result.length; //(mySize - otherMaxCollider) / result.length;
  // }

  return mySize;

  // return {
  //   width: mySize,
  //   value: item,
  //   totalCollisions: result.length,
  //   collisions: result,
  //   allCollisions: colDates,
  //   maxCollisions: colDates.length,
  // };
};

const getCollisionsChildrenWidth = (dates, allDates, dict) => {
  const widths = [];

  for (const date of dates) {
    if (dict[date.metadata.Id].tempWidth) {
      widths.push(dict[date.metadata.Id].tempWidth);
      continue;
    }

    var colDates = getCollisionDates(date, allDates);

    const result = getMaxCollisionsOnSameLevel(date, allDates, dict);
    const index = result.indexOf(date);
    if (index !== -1) result.splice(index, 1);

    const collisions = getCollisionsOnSameLevelChild(colDates, allDates, dict);

    const collisionsWidth = collisions.map((e) => 100 / (e.length + 1));

    const otherMaxColliderWidth = collisionsWidth.reduce((a, b) => a + b, 0);

    let mySize = 100 / (result.length + 1);

    if (otherMaxColliderWidth + mySize < 100) {
      const countHigherThanBaseSize =
        collisionsWidth.filter((e) => parseInt(e) >= parseInt(mySize)).length +
        1;

      mySize +=
        (100 - (mySize + otherMaxColliderWidth)) / countHigherThanBaseSize;

      // const sizeOfitemsThatHaveLessSize = collisionsWidth
      //   .filter((e) => e < mySize)
      //   .reduce((a, b) => a + b, 0); //collisions.filter(e => 100/(e.length + 1) < mySize);

      // const countItemsWithSameSize = collisionsWidth.filter((e) => e === mySize)
      //   .length;

      // mySize =
      //   (100 - sizeOfitemsThatHaveLessSize) / (countItemsWithSameSize + 1);
    }

    dict[date.metadata.Id].tempWidth = mySize;

    widths.push(mySize);
  }
  return widths;
};

export const getResolvedSizes = (items) => {
  const arr = [];
  const dict = {};

  let inc = -1;
  for (const item of items) {
    inc++;
    const computedValue = {
      value: item,
      index: inc,
    };

    dict[item.metadata.Id] = computedValue;
    arr.push(computedValue);
  }

  for (const item of items) {
    dict[item.metadata.Id].width = getSize(item, items, dict);
  }

  // arr.sort((a, b) => a.totalCollisions - b.totalCollisions);
  getLeft(arr, dict);
  // for (const item of arr) {
  //   item.left = getLeft(item, dict);
  // }

  window.getAllLinesOnSameLevel = () => getAllLinesOnSameLevel(items);

  return arr;
};
window.getResolvedSizes = getResolvedSizes;

// const getMaxCollisionsOnSameLevel = (datess, ignore = true) => {
//   // Id 2 e 3

//   let arr = [];
//   datess.forEach((e) => {
//     const dates = getCollisionDates(e, datess, true);

//     // collisionDates.push(data);

//     if (arr.length < dates.length) arr = dates;
//   });

//   return arr;
// };

const getMaxCollisionsOnSameLevel = (date, dates, dict) => {
  if (dict[date.metadata.Id].maxCollisionsOnSameLevel)
    return dict[date.metadata.Id].maxCollisionsOnSameLevel;

  // if (date.metadata.Id === 3960) ;
  const lines = getLinesOnSameLevel(date, dates, dict);

  let arr = [];

  for (const line of lines) {
    if (arr.length < line.length) arr = line;
  }

  dict[date.metadata.Id].maxCollisionsOnSameLevel = arr;
  return arr;
};

const getLinesOnSameLevel = (date, dates, dict) => {
  if (dict[date.metadata.Id].linesOnSameLevel)
    return dict[date.metadata.Id].linesOnSameLevel;

  let arr = [];
  let result = [];
  let triedIndex = 0;
  const arraysIn = {};

  const filteredDates = getCollisionDates(date, dates);

  while (true) {
    if (filteredDates.length <= triedIndex) break;

    const firstDate = filteredDates[triedIndex];
    arr.push(firstDate);
    const firstDateId = firstDate.metadata.Id;
    if (!arraysIn[firstDateId]) arraysIn[firstDateId] = [result.length];
    else arraysIn[firstDateId].push(result.length);

    for (const d of filteredDates) {
      if (
        firstDate !== d &&
        datesAreColliding(date, d) &&
        !arr.some((e) => !datesAreColliding(d, e))
      ) {
        const id = d.metadata.Id;
        if (!arraysIn[id]) arraysIn[id] = [result.length];
        else arraysIn[id].push(result.length);
        arr.push(d);
      }
    }

    let isEqual = false;

    for (const arrItem of arr) {
      let equalCount = 0;
      for (const arrayIndex of arraysIn[arrItem.metadata.Id]) {
        var line = result[arrayIndex];

        if (!line || line.length !== arr.length) continue;

        for (const lineItem of line) {
          if (arr.includes(lineItem)) {
            equalCount++;
          }
          if (equalCount === arr.length) {
            isEqual = true;
            break;
          }
        }
      }
    }

    if (!isEqual) result.push(arr);
    triedIndex++;
    arr = [];
  }

  dict[date.metadata.Id].linesOnSameLevel = result;

  return result;
};

const getAllLinesOnSameLevel = (dates) => {
  let arr = [];
  let result = [];
  const arraysIn = {};

  for (const date of dates) {
    const filteredDates = getCollisionDates(date, dates);
    filteredDates.push(date);
    let triedIndex = 0;

    while (true) {
      if (filteredDates.length <= triedIndex) break;

      const firstDate = filteredDates[triedIndex];
      arr.push(firstDate);
      const firstDateId = firstDate.metadata.Id;
      if (!arraysIn[firstDateId]) arraysIn[firstDateId] = [result.length];
      else arraysIn[firstDateId].push(result.length);

      for (const d of filteredDates) {
        if (
          firstDate !== d &&
          datesAreColliding(date, d) &&
          !arr.some((e) => !datesAreColliding(d, e))
        ) {
          const id = d.metadata.Id;
          if (!arraysIn[id]) arraysIn[id] = [result.length];
          else arraysIn[id].push(result.length);
          arr.push(d);
        }
      }

      let isEqual = false;

      for (const arrItem of arr) {
        let equalCount = 0;
        for (const arrayIndex of arraysIn[arrItem.metadata.Id]) {
          var line = result[arrayIndex];

          if (!line || line.length !== arr.length) continue;

          for (const lineItem of line) {
            if (arr.includes(lineItem)) {
              equalCount++;
            }
            if (equalCount === arr.length) {
              isEqual = true;
              break;
            }
          }
        }
      }

      if (!isEqual) result.push(arr);
      triedIndex++;
      arr = [];
    }
  }

  return result;
};

// const getMaxSizeOnSameLevel = (date, dates) => {
//   const lines = getLinesOnSameLevel(
//     date.value,
//     dates.map((e) => e.value)
//   );

//   let size = 0;
//   let maxSize = 0;

//   let items = [];

//   for (const line of lines) {
//     for (const d of line) {
//       const d2 = dates.find((e) => e.value === d);

//       items.push(d2);
//     }
//     items.push(date);
//     items = datesOrderBy(items);
//     for (const d of items) {
//       if (d.value === date.value) break;
//       size += d.width;
//     }
//     if (size > maxSize) maxSize = size;
//     items = [];
//     size = 0;
//   }

//   return maxSize;
// };

// const getMaxCollisionsOnSameLevel2 = (datess, items, ignore = true) => {
//   // Id 2 e 3

//   let max = 0;
//   datess.forEach((e) => {
//     const dates22 = getCollisionDates(e, items, true);

//     // Id 1 e 3
//     // Id 1, 2, 4, 5, 6

//     dates22.forEach((s) => {
//       const countt = getCollisionDates(s, dates22, false).length;

//       // collisionDates.push(data);

//       if (max < countt) max = countt;
//     });
//   });

//   return max;
// };

// const getMaxCollisionsOnSameLevelChild = (datesToCheck, dates) => {
//   let maxArr = [];

//   for (const d of datesToCheck) {
//     const arr = getMaxCollisionsOnSameLevel(d, dates);

//     if (arr.length > maxArr.length) {
//       maxArr = arr;
//     }
//   }

//   return maxArr;
// };

const getCollisionsOnSameLevelChild = (datesToCheck, dates, dict) => {
  let maxArr = [];

  for (const d of datesToCheck) {
    const arr = getMaxCollisionsOnSameLevel(d, dates, dict);

    if (arr.length > maxArr.length) {
      maxArr.push(arr);
    }
  }

  return maxArr;
};

const datesAreColliding = (date1, date2) => {
  if (
    (date1.BeginDate >= date2.BeginDate && date1.BeginDate < date2.EndDate) ||
    (date1.EndDate > date2.BeginDate && date1.EndDate < date2.EndDate) ||
    (date2.BeginDate >= date1.BeginDate && date2.BeginDate < date1.EndDate) ||
    (date2.EndDate > date1.BeginDate && date2.EndDate < date1.EndDate)
  )
    return true;

  // if (date1.BeginDate <= date2.BeginDate && date2.BeginDate <= date1.EndDate)
  //   return true; // b starts in a
  // if (date1.BeginDate <= date2.EmdDate && date2.EmdDate <= date1.EndDate)
  //   return true; // b ends in a
  // if (date2.BeginDate < date1.BeginDate && date1.EndDate < date2.EmdDate)
  //   return true; // a in b

  // if (
  //   (date1.BeginDate >= date2.BeginDate && date1.BeginDate < date2.EndDate) ||
  //   (date1.EndDate > date2.BeginDate && date1.EndDate <= date2.EndDate) //  ||
  //   // (date2.BeginDate >= date1.BeginDate && date2.BeginDate < date2.EndDate) ||
  //   // (date2.EndDate > date1.EndDate && date2.EndDate <= date1.EndDate)
  // )
  //   return true;
};

const getCollisionDates = (item, dates) => {
  const collisionDates = [];

  for (const d of dates) {
    // const { BeginDate, EndDate } = d;

    if (item === d) continue;

    if (datesAreColliding(item, d)) collisionDates.push(d);
  }

  return collisionDates;
};
