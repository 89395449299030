import React, { useState, useRef } from "react";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import classnames from "classnames";
const ImagePreviewContent = ({ src, handleWidthChange }) => {
  const [loading, setLoading] = useState(true);
  const ImageRef = useRef();
  const handleLoad = () => {
    handleWidthChange(ImageRef.current.offsetWidth);
    setLoading(false);
  };

  return (
    <div className="Preview_Image_Container position-relative bg-dark d-flex flex-1 h-100 of-hidden">
      {loading && (
        <div
          style={{ top: 0, left: 0, zIndex: 3 }}
          className="position-absolute h-100 bg-dark w-100"
        >
          <LoaderSpinner
            size="sm"
            className="d-flex justify-content-center align-items-center h-100 text-primary"
          />
        </div>
      )}

      <img
        ref={ImageRef}
        className={classnames("d-inline-block mx-auto")}
        onLoad={handleLoad}
        src={src}
        alt="Preview"
      />
    </div>
  );
};

const ImagePreview = ({ src, handleWidthChange = () => {} }) => {
  return (
    <ImagePreviewContent
      key={src}
      handleWidthChange={handleWidthChange}
      src={src}
    />
  );
};

export default ImagePreview;
