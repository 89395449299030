import { Communicator } from "../../Helpers/Communicator";
import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useMemo,
  useCallback
} from "react";
import { useLocation } from "react-router-dom";
import querystring from "query-string";
import {
  ServerAwareUpdateTempViewsContext,
  ServerAwareViewsContext
} from "../CGrid/ServerAwareViews";
import { useSpace } from "../../Contexts/SpaceContext";
import { AppBarFavoriteTreeSetter } from "../../Containers/AppBar/AppBarFavoriteQuery";
import { ServerAwareFavoritesContext } from "../../Containers/AppBar/ServerAwareFavorite";
import { useFavoriteOrViewChecker } from "../../Containers/AppBar/AppBarHelper";

export const FilterListLocalStorageKey = "ar-filter-list-open";

export const FilterListOpenStateCommunicator = new Communicator();

export const FilterListOpenStateContext = React.createContext();

export const FilterListOpenStateProvider = ({ children, schema }) => {
  const result = useFavoriteOrViewChecker();
  if (result)
    return (
      <FilterListOpenStateCompFavoritesProvider>
        {children}
      </FilterListOpenStateCompFavoritesProvider>
    );
  return (
    <ServerAwareFilterListTogglerViews schema={schema}>
      {children}
    </ServerAwareFilterListTogglerViews>
  );
};

export const FilterListOpenStateCompFavoritesProvider = ({ children }) => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const { filterSideToggleStatus } = FavoriteItem.data;
  const setTree = useContext(AppBarFavoriteTreeSetter);

  const resolvedIsOpen = useMemo(() => {
    return typeof filterSideToggleStatus === "undefined"
      ? true
      : Boolean(filterSideToggleStatus)
      ? true
      : false;
  }, [filterSideToggleStatus]);

  const toggle = useCallback(() => {
    setTree((oldTree) => {
      const newTree = { ...oldTree };
      const { rootId, items } = newTree;
      const newItems = { ...items };
      const newItem = { ...newItems[FavoriteItem.id] };
      const newData = { ...newItem.data };
      newData.filterSideToggleStatus = !resolvedIsOpen;
      newItem.data = newData;
      newItems[FavoriteItem.id] = newItem;
      return {
        rootId,
        items: newItems
      };
    });
  }, [FavoriteItem.id, resolvedIsOpen, setTree]);

  const resolveValue = useMemo(() => {
    return [resolvedIsOpen, toggle];
  }, [resolvedIsOpen, toggle]);

  return (
    <FilterListOpenStateContext.Provider value={resolveValue}>
      {children}
    </FilterListOpenStateContext.Provider>
  );
};

export const ServerAwareFilterListTogglerViews = ({ children, schema }) => {
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const schemaViews = useContext(ServerAwareViewsContext);
  const lsIndex = useMemo(
    () => schemaViews?.findIndex((e) => e.id === viewPref),
    [schemaViews, viewPref]
  );

  const unresolvedIsOpen = useMemo(() => {
    return schemaViews
      ? lsIndex !== -1
        ? schemaViews[lsIndex].filterSideToggleStatus
        : schemaViews[0].filterSideToggleStatus
      : undefined;
  }, [lsIndex, schemaViews]);

  const [isOpen, setIsOpen] = useState(
    typeof unresolvedIsOpen === "undefined"
      ? true
      : Boolean(unresolvedIsOpen)
      ? true
      : false
  );
  const space = useSpace();
  const toggle = useCallback(() => {
    const schemaName = schema.name;
    const newOpen = !isOpen;
    setIsOpen(newOpen);
    let gridSavedViews =
      JSON.parse(
        window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
      ) || {};
    const viewGridViews = (gridSavedViews && gridSavedViews[schemaName]) || [];

    const index = viewGridViews?.findIndex((e) => e.id === viewPref);
    let resolvedIndex = index && index >= 0 ? index : 0;
    let viewGridObject = viewGridViews[resolvedIndex] || {};
    viewGridObject.filterSideToggleStatus = newOpen;
    viewGridViews[resolvedIndex] = viewGridObject;
    gridSavedViews[schemaName] = viewGridViews;
    localStorage.setItem(
      `ar-grid-saved-views-space-${space.Id}`,
      JSON.stringify(gridSavedViews)
    );
  }, [isOpen, schema.name, space.Id, viewPref]);

  const resolveValue = useMemo(() => {
    return [isOpen, toggle];
  }, [isOpen, toggle]);

  return (
    <FilterListOpenStateContext.Provider value={resolveValue}>
      {children}
    </FilterListOpenStateContext.Provider>
  );
};

export const useIsFilterListOpen = (schemaName) => {
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const lsViews = useContext(ServerAwareViewsContext);
  const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);
  const space = useSpace();
  const index = lsViews?.findIndex((e) => e.id === viewPref);

  const unresolvedFilterSideToggleStatus =
    lsViews && index !== -1
      ? lsViews[index].filterSideToggleStatus
      : lsViews
      ? lsViews[0].filterSideToggleStatus
      : undefined;

  const filterSideToggleStatus =
    typeof unresolvedFilterSideToggleStatus === "undefined"
      ? "1"
      : unresolvedFilterSideToggleStatus;

  const [isOpen, setIsOpen] = useState(() => {
    return filterSideToggleStatus === "1" ? true : false;
  });

  useLayoutEffect(() => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );
    const viewGridViews = gridSavedViews[schemaName];
    const index = viewGridViews?.findIndex((e) => e.id === viewPref);
    let resolvedIndex = index === -1 ? 0 : index;
    let viewGridObject = viewGridViews && viewGridViews[resolvedIndex];
    const unresolvedFilterSideToggleStatus =
      viewGridObject?.filterSideToggleStatus;

    const filterSideToggleStatus =
      typeof unresolvedFilterSideToggleStatus === "undefined"
        ? "1"
        : unresolvedFilterSideToggleStatus;
    const translatedOpen = filterSideToggleStatus === "1" ? true : false;

    if (translatedOpen !== isOpen) {
      setIsOpen(translatedOpen);
    }
  }, [isOpen, schemaName, space.Id, viewPref]);

  useEffect(() => {
    return FilterListOpenStateCommunicator.subscribe((isOpen) => {
      let gridSavedViews = JSON.parse(
        window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
      );
      const viewGridViews = gridSavedViews[schemaName];
      const index = viewGridViews.findIndex((e) => e.id === viewPref);
      let resolvedIndex = index === -1 ? 0 : index;
      let viewGridObject = viewGridViews
        ? viewGridViews[resolvedIndex] || {}
        : {};
      viewGridObject.filterSideToggleStatus = isOpen;
      viewGridViews[resolvedIndex] = viewGridObject;
      gridSavedViews[schemaName] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
      updateLsViews && updateLsViews();
      const translatedOpen = isOpen === "1" ? true : false;
      setIsOpen(translatedOpen);
    });
  }, [schemaName, space.Id, updateLsViews, viewPref]);

  return isOpen;
};
