import React from "react";
import { useCall } from "../../../../Hooks/EntityHooks";
import {
  HistoryHeader,
  HistoryContent,
  HistoryAccountProfile,
  HistoryOverview,
  useHistoryItem,
} from "./HistoryItem";
import { ConnectedCallOrigin } from "../../../../Containers/Origin/CallOrigin";
import { createItemEditDetailsComponent } from "./HistoryItemDetails/HistoryItemDetails";
import { ProjectStatusProfile } from "../../../Projects/ProjectProfile";
import { FormattedMessage } from "react-intl";

const CallHistoryOverview = () => {
  const { Call, IsCreation, Account } = useHistoryItem();
  const item = useCall(Call);
  const { NoTagDescription } = item;
  if (IsCreation)
    return (
      <HistoryOverview>
        <HistoryAccountProfile account={Account} />
        <span>
          {" "}
          <FormattedMessage id={"CREATED_CALL"} />{" "}
        </span>{" "}
        <b>{NoTagDescription}</b>
      </HistoryOverview>
    );

  return (
    <HistoryOverview>
      <HistoryAccountProfile account={Account} />
      <span>
        {" "}
        <FormattedMessage id={"EDITED_CALL"} />{" "}
      </span>{" "}
      <b>{NoTagDescription}</b>
    </HistoryOverview>
  );
};

const CallEditItemDetails = createItemEditDetailsComponent({
  Status: {
    NameId: "STATUS",
    ValueComponent: ProjectStatusProfile,
	},
});

const CallHistoryDetails = () => {
  const { IsCreation } = useHistoryItem();
  if (IsCreation) return null;

  return <CallEditItemDetails />;
};

const CallHistoryItem = () => {
  const { Call } = useHistoryItem();

  return (
    <>
      <HistoryHeader>
        <ConnectedCallOrigin call={Call}>
          {/* <HistoryTitle Icon={CallIcon}>
            <FormattedMessage id={"CALL"} /> {Call}
          </HistoryTitle> */}
        </ConnectedCallOrigin>
      </HistoryHeader>
      <HistoryContent>
        <CallHistoryOverview />
        <CallHistoryDetails />
      </HistoryContent>
    </>
  );
};
export default CallHistoryItem;
