import React, { useMemo } from "react";
import {
  spaceTicketsSchema,
  spaceCallsSchema,
  spaceContactSchema,
  spaceClientSchema,
  spaceContractSchema,
  spaceProjectsSchema,
  spaceOrganizationSchema,
  spacePipelineSchema,
  spaceDealsSchema,
  spacePipelineStatusSchema,
  spaceEmailConversationSchema,
  spaceEmailSchema
} from "../../config/schema";
import { createFormRequestHook, useFormState } from "../../Components/Forms";
import {
  defaultTicketForm,
  validateTicketForm,
  TicketsStatusTypes
} from "./TicketsHelper";
import store from "../../store";
import { useTicket } from "../../Hooks/EntityHooks";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useToast } from "../../Components/Toast/ToastProvider";
import { canUpdateEntity, ConclusionForm } from "../ChildForms/ChildForms";
import {
  formatTicketForm,
  getColumnDropdownTarget,
  getInterventionTarget
} from "../../Helpers/FormFormmaterHelper";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../Components/Forms/SideMenuForm";
// import SplitCol from "../../Components/SplitCol/SplitCol";
import { entityActionType, originTypes } from "../../Helpers/MiscHelper";
import { useManagerVerifier } from "../Form/FormHelper";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";
import {
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import { updateManagerFollowers } from "../../Components/EntityFollow/EntityFollowHelper";
import {
  isOrganzationRequestValid,
  OrganizationRequestSideError
} from "../../Components/Organizations/OrganizationHelper";
import { useHandleError } from "../../Components/CGrid/ServerAwareHelper";
import { ticketCommunicator } from "./TicketCommunicatorHelper";
import { convertTicketEntityToForm } from "./TicketFormHelper";
import { TicketFormContent } from "./TicketFormContent";
import { useIntl } from "react-intl";
import {
  FormTemplateProvider,
  useFormTemplateGetter
} from "../../Components/Forms/FormTemplatesHelper";
// import { useSpace } from "../../Contexts/SpaceContext";

const getCallContactId = (callId) => {
  const Entities = store.getState().Entities;
  const callEntities = Entities[spaceCallsSchema.name];

  if (!callEntities) return null;

  const callEntity = callEntities[callId];

  if (!callEntity || !callEntity.Contact) return null;

  const contactEntity = Entities[spaceContactSchema.name][callEntity.Contact];

  return contactEntity.Id;
};

const getDepartmentTeam = (entity) => {
  const { Department, Team } = entity;
  if (Department) {
    return {
      type: "Department",
      data: Department
    };
  } else if (Team) {
    return {
      type: "Team",
      data: Team
    };
  } else return null;
};

const useTicketRequest = createFormRequestHook({
  schema: spaceTicketsSchema,
  dependencies: [
    {
      schema: spaceCallsSchema,
      getEntityId: ({ callId }) => callId,
      convertEntityToForm: (entity, props) => {
        const {
          Id,
          // ReceivedDate,
          ManagerAccount,
          Place,
          Files,
          Company,
          Description
        } = entity;
        return {
          TicketTarget:
            getColumnDropdownTarget(props) || getInterventionTarget(entity),
          Contact: getCallContactId(Id),
          // ReceivedDate,
          AssociatedFiles: Files,
          DepartmentTeam: getDepartmentTeam(entity),
          ManagerAccount,
          Followers: [ManagerAccount],
          Company,
          Place,
          Description
        };
      }
    },
    {
      schema: spaceClientSchema,
      getEntityId: ({ clientId }) => clientId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company, Files } = entity;
        return {
          TicketTarget: {
            type: "Client",
            data: Id
          },
          AssociatedFiles: Files,
          Company
          // Contact: getCallContactId(Id),
          // ReceivedDate,
          // DepartmentTeam: getDepartmentTeam(entity),
          // ManagerAccount,
          // Place,
        };
      }
    },
    {
      schema: spaceContractSchema,
      getEntityId: ({ contractId }) => contractId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company, Files } = entity;
        return {
          TicketTarget: {
            type: "Contract",
            data: Id
          },
          AssociatedFiles: Files,
          Company
        };
      }
    },
    {
      schema: spaceProjectsSchema,
      getEntityId: ({ projectId }) => projectId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company, Files } = entity;
        return {
          TicketTarget: {
            type: "Project",
            data: Id
          },
          AssociatedFiles: Files,
          Company
        };
      }
    },
    {
      schema: spaceTicketsSchema,
      getEntityId: ({ ticketId }) => ticketId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company, Files } = entity;
        return {
          TicketTarget: {
            type: "Ticket",
            data: Id
          },
          AssociatedFiles: Files,
          Company
        };
      }
    },
    {
      schema: spaceDealsSchema,
      getEntityId: ({ dealId }) => dealId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company, Contact, Files } = entity;
        return {
          TicketTarget: {
            type: originTypes.deals,
            data: Id
          },
          Company,
          Contact,
          AssociatedFiles: Files
        };
      }
    },
    {
      schema: spaceEmailConversationSchema,
      getEntityId: ({ emailConversationId }) => emailConversationId,
      convertEntityToForm: (entity, props) => {
        const { Organization, Client, FirstContacts, LastEmail } = entity;
        const email = getEntity(spaceEmailSchema, LastEmail);
        return {
          Company: Organization,
          TicketTarget: {
            type: originTypes.client,
            data: Client
          },
          Name: email.Subject,
          Contact: FirstContacts[0]
        };
      }
    }
  ],
  convertEntityToForm: convertTicketEntityToForm,
  formatDuplicationEntity: (entity) => {
    const { PipelineStatus } = entity;
    if (PipelineStatus) {
      const pipelineStatusEntity = getEntity(
        spacePipelineStatusSchema,
        PipelineStatus
      );
      if (pipelineStatusEntity.Status !== TicketsStatusTypes.open) {
        entity.PipelineStatus = null;
      }
    }

    return entity;
  }
});

const TicketForm = (props) => {
  const { key, loading, error, convertedForm, isChild, baseForm, childProps } =
    useTicketRequest(props);

  const { id, defaultForm, onSuccess, className } = props;

  const ticket = useTicket(id);

  const {
    loading: loadingPipelines,
    data: pipelines,
    error: errorPipelines
  } = useSpaceQuery(`query/pipelines?type=${PipelineTypeEnum.Ticket}`, [
    spacePipelineSchema
  ]);
  const user = useCurrentAccount();
  const orgState = useSpaceQuery(
    `query/organizations?$filter=((Account/Id eq '${user.Id}') or (Manager/Id eq '${user.Id}'))`,
    [spaceOrganizationSchema]
  );
  const { loading: loadingOrganizations, data: organizations } = orgState;

  const resolvedForm = useMemo(() => {
    const form = {
      ...defaultForm,
      ...convertedForm,
      ...baseForm
    };

    if (childProps) {
      const { Contact } = childProps || {};
      if (Contact) form.Contact = Contact;
      form.TicketTarget =
        getInterventionTarget(childProps) || form.TicketTarget;
    }

    // if (childProps) {
    // form.TicketTarget =
    // childProps ? getInterventionTarget(childProps) : getInterventionTarget(form) || form.TicketTarget;
    // }

    if (
      !id &&
      organizations &&
      organizations.findIndex((e) => e === form.Company) === -1
    ) {
      form.Company = organizations[0];
    }

    return form;
  }, [baseForm, childProps, convertedForm, defaultForm, id, organizations]);

  const { data: formTemplates, refetch: formTemplateRefetch } =
    useFormTemplateGetter(spaceTicketsSchema);

  if (
    loadingPipelines ||
    errorPipelines ||
    loading ||
    error ||
    loadingOrganizations
  )
    return <LoaderSpinner center size="sm" className="text-secondary" />;

  if (!isOrganzationRequestValid(orgState))
    return <OrganizationRequestSideError requestState={orgState} />;

  const canEdit = ticket ? ticket.CanEdit : true;

  return (
    <FormTemplateProvider forms={formTemplates} refetch={formTemplateRefetch}>
      <TicketFormBase
        className={className}
        isChild={isChild}
        onSuccess={onSuccess}
        id={id}
        canDelete={ticket?.CanDelete}
        canEdit={canEdit}
        key={key}
        pipelines={pipelines}
        organizations={organizations}
        convertedForm={resolvedForm}
      />
    </FormTemplateProvider>
  );
};

const TicketFormBase = ({
  convertedForm,
  id,
  canEdit,
  canDelete,
  onSuccess,
  isChild,
  pipelines,
  organizations,
  className
}) => {
  const user = useCurrentAccount();

  const formState = useFormState(() => {
    const defaultTicketFor = defaultTicketForm({ user, id });

    let AddressSelector;

    if (
      convertedForm &&
      convertedForm.Addresses &&
      convertedForm.Addresses.length > 0
    ) {
      AddressSelector = {
        Id: convertedForm.Addresses[0].Id || undefined,
        Country: convertedForm.Addresses[0].Country || undefined,
        Address1: convertedForm.Addresses[0].Address1 || "",
        Address2: convertedForm.Addresses[0].Address2 || "",
        District: convertedForm.Addresses[0].District || "",
        Locality: convertedForm.Addresses[0].Locality || "",
        PostalCode: convertedForm.Addresses[0].PostalCode || "",
        Latitude: convertedForm.Addresses[0].Latitude || "",
        Longitude: convertedForm.Addresses[0].Longitude || ""
      };
    } else {
      AddressSelector = {
        Id: undefined,
        Country: defaultTicketFor.Country,
        Address1: defaultTicketFor.Address,
        Address2: defaultTicketFor.Address,
        District: defaultTicketFor.Region,
        Locality: defaultTicketFor.Locale,
        PostalCode: defaultTicketFor.PostalCode,
        Latitude: defaultTicketFor.Latitude,
        Longitude: defaultTicketFor.Longitude
      };
    }

    return {
      ...defaultTicketFor,
      ...convertedForm,
      AddressSelector
    };
  });
  const { form, handleChange, setForm } = formState;
  const {
    TicketTarget,
    ManagerAccount,
    DepartmentTeam,
    Followers
    // Comments
  } = form;

  const intl = useIntl();
  const createToast = useToast();

  const handleTranslatedError = useHandleError(spaceTicketsSchema);
  const [post, { loading }] = useSidebarSpacePost(
    id ? `tickets/${id}` : "tickets",
    spaceTicketsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess(newId);
        if (!id) {
          defineChildProps({ Ticket: newId });
          ticketCommunicator.dispatch({
            type: entityActionType.creation,
            id: newId
          });
        } else {
          ticketCommunicator.dispatch({ type: entityActionType.update, id });
        }
        createToast({
          pos: "tm",
          type: "success",
          description: !id
            ? `${intl.formatMessage({ id: "CREATED_SUCCEFULLY" })}`
            : `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`,
          title: `${intl.formatMessage({ id: "CASE" })} nº ${newId || id}`
        });

        // if (id) return;

        const { data, type } = TicketTarget;

        if (type === "Call" && canUpdateEntity(spaceCallsSchema, data)) {
          return openSidebar(
            <ConclusionForm call={data} />,
            intl.formatMessage({ id: "CONCLUDE_CALL" })
          );
        }
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  useManagerVerifier(ManagerAccount, DepartmentTeam, {
    onUpdateManager: (manager) => {
      handleChange({
        ManagerAccount: manager,
        Followers: updateManagerFollowers(
          Followers,
          ManagerAccount,
          manager,
          []
        )
      });
    }
  });

  const handleSubmit = (form) => {
    post(formatTicketForm(form));
  };

  // const followers = useJoinedFollowerAccounts(ManagerAccount);
  // const possibleFollowers = useAvailableFollowers(Creator, followers);

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `tickets/${id}`,
    spaceTicketsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "CASE" })} nº ${id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  return (
    <SideForm
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateTicketForm}
    >
      <SideFormContent className={className}>
        <TicketFormContent
          canEdit={canEdit}
          formState={formState}
          id={id}
          form={form}
          organizations={organizations}
          pipelines={pipelines}
        ></TicketFormContent>
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        form={form}
        handleChange={setForm}
        schema={spaceTicketsSchema}
        deleteLoading={deleteLoading}
        handleDelete={remove}
        // disabled={validatingManager}
        isLoading={loading}
      />
    </SideForm>
  );
};

export default TicketForm;
