import React, { useCallback, useMemo } from "react";
import {
  toCurrency,
  toCurrencyFromDecimal
} from "../../Helpers/CurrencyHelper";
import "./Deals.css";
import { useCurrency } from "../../Hooks/EntityHooks";
import classnames from "classnames";

const DealCostSelectorItem = ({ value, onChange, preview }) => {
  const { Cost, CurrencyId } = value;

  const stringValue = useMemo(() => {
    return toCurrencyFromDecimal(Cost);
  }, [Cost]);

  const handleChange = useCallback(
    (e) => {
      const valueAsCurrency = toCurrency(e.target.value, ",");
      const decimalString = valueAsCurrency.replace(",", ".");
      const dec = Number(decimalString);

      // setStringValue(valueAsCurrency);
      onChange(CurrencyId, (v) => {
        return {
          ...v,
          Cost: dec
        };
      });
    },
    [CurrencyId, onChange]
  );
  const { Name: CurrencyName, Symbol } = useCurrency(CurrencyId);

  return (
    <div>
      <div
        className={classnames(
          "d-flex align-items-center px-2 rounded justify-content-between text-black",
          {
            "ssi-control": !preview
          }
        )}
      >
        <div className="d-flex">
          {/* <OptionalIndicator /> */}
          <div className="fs-14">
            {CurrencyName} ({Symbol})
          </div>
        </div>

        {preview ? (
          <div>{stringValue}</div>
        ) : (
          <input
            className="pl-1 flex-1 h-100 text-right"
            value={stringValue}
            onChange={handleChange}
          />
        )}
        {/* <Input value={stringValue} onChange={handleChange} /> */}
      </div>
    </div>
  );
};

const DealCostSelector = ({ value, onChange, currencies, preview }) => {
  const handleSelectorChange = useCallback(
    (currencyId, update) => {
      onChange((dict) => {
        return {
          ...dict,
          [currencyId]:
            typeof update === "function" ? update(dict[currencyId]) : update
        };
      });
    },
    [onChange]
  );

  if (!value) return null;
  return (
    <div className="ar-deal-const-selector">
      {currencies.map((c) => {
        return (
          <DealCostSelectorItem
            key={c}
            preview={preview}
            value={value[c]}
            onChange={handleSelectorChange}
          />
        );
      })}
    </div>
  );
};

export default DealCostSelector;
