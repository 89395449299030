import api, { updateApiToken } from "../../api";
import { setCurrentUserAuthToken } from "../../Helpers/AuthHelper";

export const OPEN_SIDEBAR = "OPEN_SIDEBARMENU";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBARMENU";

export const LoginStatus = {
  Success: 0,
  InvalidUserName: 1,
  InvalidPassword: 2,
  UserRejected: 8,
  ProviderError: 11,
  PendingValidation: 32,
  InvalidPasswordFormat: 34,
  UserLocked: 35,
  UserNotActive: 36
};

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const ASSIGN_SIGNAL_R = "ASSIGN_SIGNAL_R";

export const login = (form) => (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  api
    .post("account/login", form)
    .then((response) => {
      const { Status: LoginStatus, authToken } = response;

      if (LoginStatus) dispatch({ type: LOGIN_ERROR, error: LoginStatus });
      else {
        setCurrentUserAuthToken(authToken);
        updateApiToken(authToken);
        dispatch({ type: LOGIN_SUCCESS, response });
      }
    })
    .catch((error) => {
      dispatch({ type: LOGIN_ERROR });
    });
};

export const REMOVE_LOGIN_ERROR = "REMOVE_LOGIN_ERROR";
export const removeLoginError = () => {
  return { type: REMOVE_LOGIN_ERROR };
};

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const registerUser = (form) => (dispatch, getState) => {
  dispatch({ type: REGISTER_USER_REQUEST });

  api
    .post("account/register", form)
    .then(({ authToken }) => {
      setCurrentUserAuthToken(authToken);
      updateApiToken(authToken);
      if (window.dataLayer && window.dataLayer.push)
        window.dataLayer.push({ event: "airdeskSignupComplete" });
      dispatch({
        type: REGISTER_USER_SUCCESS,
        authToken: authToken
      });
    })
    .catch((error) => {
      dispatch({ type: REGISTER_USER_ERROR, error });
    });
};

export const REMOVE_LOGIN = "REMOVE_LOGIN";

export const REMOVE_REGISTER_ERROR = "REMOVE_REGISTER_ERROR";

export const removeRegisterError = () => {
  return { type: REMOVE_REGISTER_ERROR };
};
