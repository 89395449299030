import React, { useContext, useState } from "react";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import {
  SideFormContent,
  SideMenuFormFooterBase
} from "../../Components/Forms/SideMenuForm";
import LoadableButton from "../../Components/Button/LoadableButton";
import { ContactStatusDropdown } from "../AdvancedMultiInputs";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import { useToast } from "../../Components/Toast/ToastProvider";
import { useGridRequest } from "../../Components/CGrid/ServerGrid";
import Button from "../../Components/Button/Button";
import PhoneInput from "../../Components/Input/PhoneInput";
import { FormattedMessage, useIntl } from "react-intl";
import GDPR from "./GDPR";
import { SelectedArrMsgContext } from "../../Components/UnOrReadOptions/UnreadOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCodeMerge,
  faDrawCircle,
  faFileCertificate,
  faPhone,
  faListCheck,
  faTrashAlt
} from "@fortawesome/pro-light-svg-icons";
import { DeleteContactModalContext } from "./ContactOverview/ContactOverviewComp";
import DocTyper from "../../Components/DocTyper/DocTyper";

export const ContactStatusMultipleButton = ({ selectedItems }) => {
  const [openSidebar] = useSidebar();
  const { refetch } = useGridRequest();
  const handleStatusMultiAction = () => {
    openSidebar(
      <ContactStatusMultiActionForm
        selectedItems={selectedItems}
        refetch={refetch}
      />
    );
  };

  return (
    <Button className="d-flex" vType={"link"} onClick={handleStatusMultiAction}>
      <div className="ar-form-button-link-icon mr-1">
        <FontAwesomeIcon icon={faDrawCircle} />
      </div>
      <FormattedMessage id="CHANGE_STATUS" />
    </Button>
  );
};

export const ContactStatusMultiActionForm = ({ selectedItems, refetch }) => {
  const [Status, setStatus] = useState(null);

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setStatus(value);
  };
  const createToast = useToast();
  const [, closeSidebar] = useSidebar();
  const intl = useIntl();
  const [post, { loading }] = useSidebarSpacePost(`Contacts/Multi`, null, {
    onSuccess: ({ openSidebar, disableClosure, data }) => {
      createToast({
        pos: "tm",
        type: "success",
        description: `${data.Success.length} ${intl.formatMessage({
          id: "CONTACTS_CHANGED"
        })}, ${data.NotChange.length} ${intl.formatMessage({
          id: "CONTACTS_NOT_CHANGED"
        })}, ${data.Fail.length} ${intl.formatMessage({
          id: "CONTACTS_FAILED_CHANGED"
        })}${
          data.NoPermission?.length > 0
            ? `, ${data.NoPermission.length} ${intl.formatMessage({
                id: "CALL_NO_PERMISSION"
              })}`
            : ""
        }`
      });
      closeSidebar();
      selectedItems.ClearSelection();
      refetch();
    }
  });

  const submit = () => {
    post({
      ContactIds: selectedItems.selectedItems,
      Status
    });
  };

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"EDIT_CONTACTS"} />
      </BaseSidebarHeader>
      <BaseSidebarContent className="d-flex flex-column">
        <SideFormContent className="px-4 py-3">
          <div className="d-flex w-100 flex-column h-100">
            <FormInput
              text={intl.formatMessage({ id: "STATUS" })}
              onChange={handleStatusChange}
              inputType={ContactStatusDropdown}
              className="mb-3"
              name="Status"
              value={Status}
            />

            <div />
          </div>
        </SideFormContent>
        <SideMenuFormFooterBase className="d-flex align-items-center justify-content-between">
          <div></div>
          <LoadableButton
            disabled={!Status}
            isLoading={loading}
            onClick={submit}
            vType="primary-ghost"
          >
            <FormattedMessage id={"SUBMIT"} />
          </LoadableButton>
        </SideMenuFormFooterBase>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export const ContactMobileCodeMultipleButton = ({ selectedItems }) => {
  const [openSidebar] = useSidebar();
  const { refetch } = useGridRequest();
  const handleStatusMultiAction = () => {
    openSidebar(
      <ContactMobileCodeMultiActionForm
        selectedItems={selectedItems}
        refetch={refetch}
      />
    );
  };

  return (
    <Button className="d-flex" vType={"link"} onClick={handleStatusMultiAction}>
      <div className="ar-form-button-link-icon mr-1">
        <FontAwesomeIcon icon={faPhone} />
      </div>
      <FormattedMessage id="CELLPHONE_CODES" />
    </Button>
  );
};

export const ContactMobileCodeMultiActionForm = ({
  selectedItems,
  refetch
}) => {
  const [MobileCode, setMobileCode] = useState("+351.");
  const intl = useIntl();
  const handleStatusChange = (e) => {
    const { value } = e.target;
    setMobileCode(value);
  };
  const createToast = useToast();
  const [, closeSidebar] = useSidebar();

  const [post, { loading }] = useSidebarSpacePost(`Contacts/Multi`, null, {
    onSuccess: ({ openSidebar, disableClosure, data }) => {
      createToast({
        pos: "tm",
        type: "success",
        description: `${data.Success.length} ${intl.formatMessage({
          id: "CONTACTS_CHANGED"
        })}, ${data.NotChange.length} ${intl.formatMessage({
          id: "CONTACTS_NOT_CHANGED"
        })}, ${data.Fail.length} ${intl.formatMessage({
          id: "CONTACTS_FAILED_CHANGED"
        })}${
          data.NoPermission?.length > 0
            ? `, ${data.NoPermission.length} ${intl.formatMessage({
                id: "CALL_NO_PERMISSION"
              })}`
            : ""
        }`
      });
      closeSidebar();
      selectedItems.ClearSelection();
      refetch();
    }
  });

  const submit = () => {
    post({
      ContactIds: selectedItems.selectedItems,
      MobileCode
    });
  };

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"EDIT_CONTACTS"} />
      </BaseSidebarHeader>
      <BaseSidebarContent className="d-flex flex-column">
        <SideFormContent className="px-4 py-3">
          <div className="d-flex w-100 flex-column h-100">
            <FormInput
              text={intl.formatMessage({ id: "EXTENSION" })}
              onChange={handleStatusChange}
              inputType={PhoneInput}
              value={MobileCode}
              className="mb-3"
              name="MobileCode"
              OnlyPhoneCodes={true}
            />

            <div />
          </div>
        </SideFormContent>
        <SideMenuFormFooterBase className="d-flex align-items-center justify-content-between">
          <div></div>
          <LoadableButton
            disabled={!MobileCode}
            isLoading={loading}
            onClick={submit}
            vType="primary-ghost"
          >
            <FormattedMessage id={"SUBMIT"} />
          </LoadableButton>
        </SideMenuFormFooterBase>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export const ContactPhoneCodeMultipleButton = ({ selectedItems }) => {
  const [openSidebar] = useSidebar();
  const { refetch } = useGridRequest();
  const handleStatusMultiAction = () => {
    openSidebar(
      <ContactPhoneCodeMultiActionForm
        selectedItems={selectedItems}
        refetch={refetch}
      />
    );
  };

  return (
    <Button className="d-flex" vType={"link"} onClick={handleStatusMultiAction}>
      <div className="ar-form-button-link-icon mr-1">
        <FontAwesomeIcon icon={faPhone} />
      </div>
      <FormattedMessage id="PHONE_CODES" />
    </Button>
  );
};

export const ContactMultipleDeleteButton = () => {
  const openDeleteModal = useContext(DeleteContactModalContext);
  const SelectedResolvedArr = useContext(SelectedArrMsgContext);

  return (
    <div className="h-100 Logout-button ">
      <div
        disabled={SelectedResolvedArr.length === 0}
        className="ar-logout-button d-flex align-items-center w-100 h-100 pb-2 pt-2 cursor-pointer"
        onClick={openDeleteModal}
      >
        <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faTrashAlt} />
        <span style={{ marginLeft: 9 }}>
          <FormattedMessage id={"DELETE_SELECTED"} />
        </span>
      </div>
    </div>
  );
};

export const ContactPhoneCodeMultiActionForm = ({ selectedItems, refetch }) => {
  const [PhoneCode, setPhoneCode] = useState("+351.");

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setPhoneCode(value);
  };
  const createToast = useToast();
  const [, closeSidebar] = useSidebar();
  const intl = useIntl();
  const [post, { loading }] = useSidebarSpacePost(`Contacts/Multi`, null, {
    onSuccess: ({ openSidebar, disableClosure, data }) => {
      createToast({
        pos: "tm",
        type: "success",
        description: `${data.Success.length} ${intl.formatMessage({
          id: "CONTACTS_CHANGED"
        })}, ${data.NotChange.length} ${intl.formatMessage({
          id: "CONTACTS_NOT_CHANGED"
        })}, ${data.Fail.length} ${intl.formatMessage({
          id: "CONTACTS_FAILED_CHANGED"
        })}${
          data.NoPermission?.length > 0
            ? `, ${data.NoPermission.length} ${intl.formatMessage({
                id: "CALL_NO_PERMISSION"
              })}`
            : ""
        }`
      });
      closeSidebar();
      selectedItems.ClearSelection();
      refetch();
    }
  });

  const submit = () => {
    post({
      ContactIds: selectedItems.selectedItems,
      PhoneCode
    });
  };

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"EDIT_CONTACTS"} />
      </BaseSidebarHeader>
      <BaseSidebarContent className="d-flex flex-column">
        <SideFormContent className="px-4 py-3">
          <div className="d-flex w-100 flex-column h-100">
            <FormInput
              text={intl.formatMessage({ id: "EXTENSION" })}
              onChange={handleStatusChange}
              inputType={PhoneInput}
              value={PhoneCode}
              className="mb-3"
              name="PhoneCode"
              OnlyPhoneCodes={true}
            />

            <div />
          </div>
        </SideFormContent>
        <SideMenuFormFooterBase className="d-flex align-items-center justify-content-between">
          <div></div>
          <LoadableButton
            disabled={!PhoneCode}
            isLoading={loading}
            onClick={submit}
            vType="primary-ghost"
          >
            <FormattedMessage id={"SUBMIT"} />
          </LoadableButton>
        </SideMenuFormFooterBase>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export const ContactGDPRMultipleButton = ({ selectedItems }) => {
  const [openSidebar] = useSidebar();
  const { refetch } = useGridRequest();
  const handleStatusMultiAction = () => {
    openSidebar(
      <ContactGDPRMultiActionForm
        selectedItems={selectedItems}
        refetch={refetch}
      />
    );
  };

  return (
    <Button className="d-flex" vType={"link"} onClick={handleStatusMultiAction}>
      <div className="ar-form-button-link-icon mr-1">
        <FontAwesomeIcon icon={faFileCertificate} />
      </div>
      <FormattedMessage id="CHANGE_GDPR" />
    </Button>
  );
};

export const ContactGDPRMultiActionForm = ({ selectedItems, refetch }) => {
  const [GDPRValue, setGDPRValue] = useState(null);

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setGDPRValue(value);
  };
  const createToast = useToast();
  const [, closeSidebar] = useSidebar();
  const intl = useIntl();
  const [post, { loading }] = useSidebarSpacePost(`Contacts/Multi`, null, {
    onSuccess: ({ openSidebar, disableClosure, data }) => {
      createToast({
        pos: "tm",
        type: "success",
        description: `${data.Success.length} ${intl.formatMessage({
          id: "CONTACTS_CHANGED"
        })}, ${data.NotChange.length} ${intl.formatMessage({
          id: "CONTACTS_NOT_CHANGED"
        })}, ${data.Fail.length} ${intl.formatMessage({
          id: "CONTACTS_FAILED_CHANGED"
        })}${
          data.NoPermission?.length > 0
            ? `, ${data.NoPermission.length} ${intl.formatMessage({
                id: "CALL_NO_PERMISSION"
              })}`
            : ""
        }`
      });
      closeSidebar();
      selectedItems.ClearSelection();
      refetch();
    }
  });

  const submit = () => {
    post({
      ContactIds: selectedItems.selectedItems,
      GDPRType: GDPRValue
    });
  };

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"EDIT_CONTACTS"} />
      </BaseSidebarHeader>
      <BaseSidebarContent className="d-flex flex-column">
        <SideFormContent className="px-4 py-3">
          <div className="d-flex w-100 flex-column h-100">
            <FormInput
              onChange={handleStatusChange}
              text={<FormattedMessage id="GDPR_LEGALBASIS" />}
              inputType={GDPR}
              value={GDPRValue}
              className="mb-3"
              name="GDPRType"
            />

            <div />
          </div>
        </SideFormContent>
        <SideMenuFormFooterBase className="d-flex align-items-center justify-content-between">
          <div></div>
          <LoadableButton
            disabled={!GDPRValue}
            isLoading={loading}
            onClick={submit}
            vType="primary-ghost"
          >
            <FormattedMessage id={"SUBMIT"} />
          </LoadableButton>
        </SideMenuFormFooterBase>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export const ContactDescriptionMultipleButton = ({ selectedItems }) => {
  const [openSidebar] = useSidebar();
  const { refetch } = useGridRequest();
  const handleStatusMultiAction = () => {
    openSidebar(
      <ContactDescriptionMultiActionForm
        selectedItems={selectedItems}
        refetch={refetch}
      />
    );
  };

  return (
    <Button className="d-flex" vType={"link"} onClick={handleStatusMultiAction}>
      <div className="ar-form-button-link-icon mr-1">
        <FontAwesomeIcon icon={faListCheck} />
      </div>
      <FormattedMessage id="CHANGE_NOTE" />
    </Button>
  );
};

export const ContactMergeButton = ({ openContactsMerge, ...rest }) => {
  return (
    <Button
      className="d-flex"
      vType={"link"}
      onClick={openContactsMerge}
      {...rest}
    >
      <div className="ar-form-button-link-icon mr-1">
        <FontAwesomeIcon icon={faCodeMerge} />
      </div>
      <FormattedMessage id="CONTACTS_MERGE" />
    </Button>
  );
};

export const ContactDescriptionMultiActionForm = ({
  selectedItems,
  refetch
}) => {
  const intl = useIntl();
  const [Description, setDescription] = useState(null);

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setDescription(value);
  };
  const createToast = useToast();
  const [, closeSidebar] = useSidebar();

  const [post, { loading }] = useSidebarSpacePost(`Contacts/Multi`, null, {
    onSuccess: ({ openSidebar, disableClosure, data }) => {
      createToast({
        pos: "tm",
        type: "success",
        description: `${data.Success.length} ${intl.formatMessage({
          id: "CONTACTS_CHANGED"
        })}, ${data.NotChange.length} ${intl.formatMessage({
          id: "CONTACTS_NOT_CHANGED"
        })}, ${data.Fail.length} ${intl.formatMessage({
          id: "CONTACTS_FAILED_CHANGED"
        })}${
          data.NoPermission?.length > 0
            ? `, ${data.NoPermission.length} ${intl.formatMessage({
                id: "CALL_NO_PERMISSION"
              })}`
            : ""
        }`
      });
      closeSidebar();
      selectedItems.ClearSelection();
      refetch();
    }
  });

  const submit = () => {
    post({
      ContactIds: selectedItems.selectedItems,
      Description
    });
  };

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"EDIT_CONTACTS"} />
      </BaseSidebarHeader>
      <BaseSidebarContent className="d-flex flex-column">
        <SideFormContent className="px-4 py-3">
          <div className="d-flex w-100 flex-column h-100">
            <FormInput
              text={intl.formatMessage({
                id: "NOTES"
              })}
              inputType={DocTyper}
              className="mb-3"
              onChange={handleStatusChange}
              value={Description}
              name="Description"
            />

            <div />
          </div>
        </SideFormContent>
        <SideMenuFormFooterBase className="d-flex align-items-center justify-content-between">
          <div></div>
          <LoadableButton
            disabled={!Description}
            isLoading={loading}
            onClick={submit}
            vType="primary-ghost"
          >
            <FormattedMessage id={"SUBMIT"} />
          </LoadableButton>
        </SideMenuFormFooterBase>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};
