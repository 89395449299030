import React, { useRef } from "react";
import { useHoverEvents } from "../../Containers/Origin/Origin";
import Popup from "../Popup/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import "./InformationComponent.css";

const InformationComponent = (props) => {
  const {
    placement,
    text,
    Component,
    children,
    style,
		offset,
    className,
    disablePortal,
    ...rest
  } = props;
  const anchorRef = useRef();
  const {
    // onPopupMouseEnter,
    // onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents(0, 0);

  if (!text) return children;

  return (
    <>
      <Popup
        disablePortal={disablePortal}
        placement={placement}
        anchorEl={anchorRef.current}
        modifiers={{
					offset,
          preventOverflow: { boundariesElement: "viewport" }
        }}
        style={{ ...style, display: !isOpen ? "none" : "" }}
        isOpen={isOpen}
        // onMouseEnter={onPopupMouseEnter}
        // onMouseLeave={onPopupMouseLeave}
      >
        <div className="InformationTooltip disable-selection text-white px-2 py-1 fs-14 breakWord rounded">
          {text}
        </div>
      </Popup>

      {Component ? (
        <div
          className="position-relative d-block"
          onMouseEnter={onAnchorMouseEnter}
          onMouseLeave={onAnchorMouseLeave}
          //   onMouseLeave={onAnchorMouseLeave}
          ref={anchorRef}
        >
          {Component}
        </div>
      ) : children ? (
        <div
          ref={anchorRef}
          className={classnames("position-relative", className)}
          onMouseEnter={onAnchorMouseEnter}
          onMouseLeave={onAnchorMouseLeave}
          {...rest}
        >
          {children}
        </div>
      ) : (
        <div
          ref={anchorRef}
          className="InformationComponent"
          onMouseEnter={onAnchorMouseEnter}
          onMouseLeave={onAnchorMouseLeave}
        >
          <FontAwesomeIcon icon={faInfoCircle} size="sm" />
        </div>
      )}
    </>
  );
};

export default InformationComponent;
