import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { useMaintenanceState } from "./Maintenance";
import { ReactComponent as InternetFail } from "../../Assets/Svg/internet-fail.svg";
import Popup from "../../Components/Popup/Popup";
import { useHoverEvents } from "../Origin/Origin";
import "./Maintenance.css";
import { MaintenanceText } from "./MaintenanceHelper";
import { FormattedMessage } from "react-intl";

const MaintenanceContent = ({ data, absolute }) => {
  const anchorRef = useRef();

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  const { ScheduleMessage } = data;

  let colorClass;

  if (ScheduleMessage) colorClass = "text-maintence-color";
  else colorClass = "text-maintence-color";
  return (
    <>
      <div
        className={absolute ? "ar-maintenance-icon my-1" : undefined}
        ref={anchorRef}
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
      >
        <FontAwesomeIcon
          size={absolute ? "2x" : "lg"}
          className={colorClass}
          icon={faExclamationTriangle}
        />
      </div>
      <Popup
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        disablePortal
        placement="bottom"
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <div
          style={{ width: 230 }}
          className="bg-black p-2 text-white rounded fs-14 fw-medium text-center "
        >
          <MaintenanceText data={data} />
        </div>
      </Popup>
    </>
  );
};

const OfflineContent = ({ data, absolute }) => {
  const anchorRef = useRef();

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  return (
    <>
      <div
        className={absolute ? "ar-maintenance-icon my-1" : undefined}
        ref={anchorRef}
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
      >
        <InternetFail
          style={{ fill: "#f7bf19", width: 25, height: 25 }}
          className="svg-inline--fa fa-exclamation-triangle fa-w-18 fa-lg text-maintence-color"
        />
      </div>
      <Popup
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        placement="bottom-end"
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <div className="bg-black p-2 text-white rounded fs-14 fw-medium mw-290px text-center ">
          <FormattedMessage id="INTERNET_FAIL_MESSAGE" />
        </div>
      </Popup>
    </>
  );
};

const MaintenanceIcon = ({ absolute = false }) => {
  const { data, loading } = useMaintenanceState() || {};

  if (!data && loading) return null;

  if (!data && !loading) return <OfflineContent absolute={absolute} />;

  const { CurrentlyInMaintenance, ScheduleMessage } = data;

  if (!CurrentlyInMaintenance && !ScheduleMessage) return null;

  return <MaintenanceContent data={data} absolute={absolute} />;
};

export default MaintenanceIcon;
