import React, { useCallback } from "react";
import { useGridRequest } from "../../../Components/CGrid/ServerGrid";
import EmptyListError from "../../../Components/EmptyListContainer/EmptyListError";
import { useClient } from "../../../Hooks/EntityHooks";
import EmptyListContainer from "../../../Components/EmptyListContainer/EmptyListContainer";
import { ClientFormButton } from "../../Form/FormButtons";
import VirtualServerAwareGrid from "../../../Components/VirtualGrid/VirtualServerAwareGrid";
import { VirtualGridRow } from "../../../Components/VirtualGrid/VirtualGrid";
import { moduleTypes } from "../../../Helpers/ModulesHelper";
import { customersColumns } from "./ClientOverviewComp";
import { FormattedMessage } from "react-intl";

const ClientRow = React.memo(({ value, ...rest }) => {
  const v = useClient(value);
  const canSelectRow = useCallback((v) => !v.Client, []);
  return (
    <VirtualGridRow
      formType={moduleTypes.clients}
      className={v && v.IsFollowing && v.IsRead === false && "unread"}
      value={v}
      {...rest}
      canSelectRow={canSelectRow}
    />
  );
});

const canSelectRow = (value) => {
  if (!value) return false;

  return true;

  // const state = store.getState();

  // const intervention = getEntityFromState(
  //   state,
  //   spaceInterventionsSchema,
  //   value
  // );

  // return !intervention.Billing;
};

const ClientGrid = ({ onItemSelect, ...rest }) => {
  const { error, results } = useGridRequest();

  if (error) return <EmptyListError />;

  if (results && results.length === 0)
    return (
      <EmptyListContainer type="clients">
        <div className="fw-bold fs-24 text-black ">
          <FormattedMessage id="NO_ACCOUNTS" />
        </div>
        <div className="fs-18 text-black text-center mb-4">
          <FormattedMessage id="EMPTY_ACCOUNTS" />
        </div>
        <ClientFormButton>
          <FormattedMessage id="ADD_NEW_ACCOUNT" />
        </ClientFormButton>
      </EmptyListContainer>
    );

  return (
    <VirtualServerAwareGrid
      sortable
      rowComponent={ClientRow}
      onSelectedItemsChange={onItemSelect}
      canSelectRow={canSelectRow}
      columns={customersColumns}
      {...rest}
    />
  );
};

export default ClientGrid;
