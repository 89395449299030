import { ItemId, TreeItem } from "@atlaskit/tree";
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState
} from "react";
import { DetailsValueIdContext } from "../../Components/DetailsView2/DetailsView2Helper";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { useSpacePost } from "../../Helpers/IOClient";
import { ViewsEnum } from "../../Interfaces/EntityInterfaces";
import { TreeDataChangerType } from "./AppBarFavoriteQuery";
import {
  AppFavoriteContext,
  AppFavoriteSetterContext
} from "./AppFavoriteProvider";

export const AppViewContext = React.createContext<
  (ViewsEnum | React.Dispatch<React.SetStateAction<ViewsEnum>>)[]
>([]);

export const AppViewProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const account = useCurrentAccount();
  const [viewState, setViewState] = useState(
    account.AdvancedLayout ? account.AdvancedLayout : ViewsEnum.basic
  );

  const [post] = useSpacePost("headerAdvancedLayout", null, {});

  const toggleViewState = useCallback(() => {
    typeof post === "function" &&
      post({
        AdvancedLayout:
          viewState === (ViewsEnum.basic as any)
            ? ViewsEnum.advanced
            : ViewsEnum.basic
      });
    setViewState((oldV) => {
      if (oldV === ViewsEnum.basic) return ViewsEnum.advanced;
      else return ViewsEnum.basic;
    });
  }, [post, viewState]);

  const viewStateOptions = useMemo(() => {
    return [viewState, toggleViewState];
  }, [toggleViewState, viewState]);

  return (
    <AppViewContext.Provider value={viewStateOptions}>
      {children}
    </AppViewContext.Provider>
  );
};

export interface TreeData {
  rootId: ItemId;
  items: Record<ItemId, TreeItem>;
}

export const BaseTreeData: TreeData = {
  rootId: "00000000-0000-0000-0000-000000000001",
  items: {
    "00000000-0000-0000-0000-000000000001": {
      id: "00000000-0000-0000-0000-000000000001",
      children: [],
      hasChildren: true,
      isExpanded: true,
      isChildrenLoading: false,
      data: { title: "Default", isFolder: true }
    }
  }
};

export const useFavoriteIdSynchronizer = (favoriteId: string) => {
  const SetFavoriteId = useContext(AppFavoriteSetterContext);
  useLayoutEffect(() => {
    if (!favoriteId) return;
    if (typeof SetFavoriteId === "function") SetFavoriteId(favoriteId);
    return () => {
      if (typeof SetFavoriteId === "function") SetFavoriteId(null);
    };
  }, [SetFavoriteId, favoriteId]);
};

export const ResetViewContext = React.createContext<
  ((itemFavoriteId: string) => void) | null
>(null);

export const ModuleTypeDict: Record<EntiyTypeEnumAppFavorites, string> = {
  customers: "customers",
  contacts: "contacts",
  deals: "deals",
  contracts: "contracts",
  subscriptions: "subscriptions",
  classification: "classification",
  billing: "billing",
  calls: "calls",
  tickets: "tickets",
  projects: "projects",
  tasks: "tasks",
  automation: "automation",
  interventions: "interventions",
  social: "social",
  signatures: "signatures",
  dashboard: "dashboard",
  reports: "reports",
  accounts: "accounts",
  spaceList: "spaceList",
  receptionMessages: "inbox"
};

export enum EntiyTypeEnumAppFavorites {
  customers = "customers",
  contacts = "contacts",
  deals = "deals",
  contracts = "contracts",
  subscriptions = "subscriptions",
  classifications = "classification",
  billing = "billing",
  receptionMessages = "receptionMessages",
  automation = "automation",
  calls = "calls",
  tickets = "tickets",
  projects = "projects",
  tasks = "tasks",
  interventions = "interventions",
  dashboard = "dashboard",
  reports = "reports",
  accounts = "accounts",
  spaceList = "spaceList",
  social = "social",
  signatures = "signatures"
}

export enum EntityTypeQueryNameDictionary {
  "Client" = EntiyTypeEnumAppFavorites.customers,
  "Deal" = EntiyTypeEnumAppFavorites.deals,
  "Contract" = EntiyTypeEnumAppFavorites.contracts,
  "Call" = EntiyTypeEnumAppFavorites.calls,
  "Project" = EntiyTypeEnumAppFavorites.projects,
  "Ticket" = EntiyTypeEnumAppFavorites.tickets,
  "Task" = EntiyTypeEnumAppFavorites.tasks,
  "timecharge" = EntiyTypeEnumAppFavorites.interventions,
  "Account" = EntiyTypeEnumAppFavorites.accounts
}

export enum ViewTypeEnumAppFavorites {
  grid = "grid",
  board = "board",
  timeline = "timeline",
  calendar = "calendar",
  cards = "cards"
}

export enum LinkTypesEnumAppFavorites {
  complex = "complex",
  details = "details"
}

export const BaseTreeDataExample: TreeData = {
  rootId: "1",
  items: {
    "1": {
      id: "1",
      children: ["1-1", "1-0", "1-2", "1-3", "1-4", "1-5", "1-6", "1-7", "1-8"],
      hasChildren: true,
      isExpanded: true,
      isChildrenLoading: false,
      data: { title: "Title 1", isFolder: true }
    },
    "1-0": {
      id: "1-0",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 1-0" }
    },
    "1-1": {
      id: "1-1",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 1-1" }
    },
    "1-2": {
      id: "1-2",
      children: ["1-2-0", "1-2-1", "1-2-2", "1-2-3"],
      hasChildren: true,
      isExpanded: true,
      isChildrenLoading: false,
      data: { title: "Title 2", isFolder: true }
    },
    "1-2-0": {
      id: "1-2-0",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 2-0" }
    },
    "1-2-1": {
      id: "1-2-1",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 2-1" }
    },
    "1-2-2": {
      id: "1-2-2",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 2-2" }
    },
    "1-2-3": {
      id: "1-2-3",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 2-3" }
    },
    "1-3": {
      id: "1-3",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 1-3" }
    },
    "1-4": {
      id: "1-4",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 1-4" }
    },
    "1-5": {
      id: "1-5",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 1-5" }
    },
    "1-6": {
      id: "1-6",
      children: ["1-6-0", "1-6-1", "1-6-2", "1-6-3", "1-6-4"],
      hasChildren: true,
      isExpanded: true,
      isChildrenLoading: false,
      data: { title: "Title 6", isFolder: true }
    },
    "1-6-0": {
      id: "1-6-0",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 6-0" }
    },
    "1-6-1": {
      id: "1-6-1",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 6-1" }
    },
    "1-6-2": {
      id: "1-6-2",
      children: ["1-6-2-0", "1-6-2-1", "1-6-2-2"],
      hasChildren: true,
      isExpanded: true,
      isChildrenLoading: false,
      data: { title: "Title 2", isFolder: true }
    },
    "1-6-2-0": {
      id: "1-6-2-0",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 2-0" }
    },
    "1-6-2-1": {
      id: "1-6-2-1",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 2-1" }
    },
    "1-6-2-2": {
      id: "1-6-2-2",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 2-2" }
    },
    "1-6-3": {
      id: "1-6-3",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 6-3" }
    },
    "1-6-4": {
      id: "1-6-4",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 6-4" }
    },
    "1-7": {
      id: "1-7",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 1-7" }
    },
    "1-8": {
      id: "1-8",
      children: [],
      hasChildren: false,
      isExpanded: false,
      isChildrenLoading: false,
      data: { title: "Title 1-8" }
    }
  }
};

export const AppTreeChildFavoriteCreatorContext = React.createContext<
  ((item: TreeItem) => void) | null
>(null);
export const AppTreeChildFolderCreatorContext = React.createContext<
  ((item: TreeItem) => void) | null
>(null);

export const AppDraggingFavoriteContext = React.createContext<
  ((e: boolean) => void) | null
>(null);

export const AppTreeValueContext = React.createContext<TreeData | null>(null);
export const AppTreeSetValueContext = React.createContext<
  ((e: TreeDataChangerType) => void) | null
>(null);

export const useFavoriteOrViewChecker = () => {
  const FavoriteId = useContext(AppFavoriteContext);
  const detailsEntityId = useContext(DetailsValueIdContext);
  if (FavoriteId && !detailsEntityId) return true;
  else return false;
};
