import React, { useContext, useMemo } from "react";
import classnames from "classnames";
import { useDetailAwareParams, useServerAwareState } from "../CGrid/ServerGrid";
import { useSpace } from "../../Contexts/SpaceContext";
import { buildODataFilter } from "../../Helpers/ODataHelper";
import moment from "moment";

export const CalenderRefetchRefContext = React.createContext(null);
export const useReftchRefProvider = () => useContext(CalenderRefetchRefContext);
export enum CalendarViewEnum {
  Month = 1,
  Week = 2,
  Day = 3
}
export const isValidCalendarView = (view: number) => {
  switch (view) {
    case CalendarViewEnum.Month:
    case CalendarViewEnum.Week:
    case CalendarViewEnum.Day:
      return true;

    default:
      return false;
  }
};

export const weekDayTranslation = {
  0: "SUNDAY",
  1: "MONDAY",
  2: "TUESDAY",
  3: "WEDNESDAY",
  4: "THURSDAY",
  5: "FRIDAY",
  6: "SATURDAY"
};

export const monthTranslation = {
  0: "JANUARY",
  1: "FEBRUARY",
  2: "MARCH",
  3: "APRIL",
  4: "MAY",
  5: "JUNE",
  6: "JULY",
  7: "AUGUST",
  8: "SEPTEMBER",
  9: "OCTOBER",
  10: "NOVEMBER",
  11: "DECEMBER"
};

export const calendarToEpochDays = (date: Date) => {
  const total_seconds = Math.floor(date.getTime() / 1000);
  const total_minutes = Math.floor(total_seconds / 60);
  const total_hours = Math.floor(total_minutes / 60);
  const days = Math.ceil(total_hours / 24);
  return days;
};

export const CalendarItemContext = React.createContext<any>(undefined);

export const CalendarItem = ({
  children,
  onClick: overrideOnClick,
  className: optionalClassnames
}: any) => {
  const { anchorRef, onClick, className, onMouseOver, onMouseOut } =
    useContext(CalendarItemContext);

  return (
    <div
      ref={anchorRef}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={overrideOnClick || onClick}
      className={classnames(className, optionalClassnames)}
    >
      {children}
    </div>
  );
};

const formatDate = (date: Date) => {
  return `datetime'${moment(date).format("YYYY-MM-DD")}'`;
};

const top = 100;
export const useCalendarDateUrl = (dateInfo: any, settings: any) => {
  const { params, moduleType } = useServerAwareState();
  const { filter, query } = useDetailAwareParams(params, moduleType);
  const { advancedFilters } = params;
  const space = useSpace();

  const url = useMemo(() => {
    const { BeginDateField, EndDateField, schema } = settings;
    let baseUrl = schema.getEndpoint(space.Id);

    let resFilter = buildODataFilter(filter, false, true);
    resFilter = resFilter.substring(9, resFilter.length - 1);

    const { startDate, endDate } = dateInfo;
    const resolvedStartDate = new Date(startDate);
    resolvedStartDate.setDate(resolvedStartDate.getDate() - 1);

    const resolvedEndDate = new Date(endDate);
    resolvedEndDate.setDate(resolvedEndDate.getDate() + 1);

    const calendarFilter = `(((${BeginDateField} gt ${formatDate(
      resolvedStartDate
    )}) and (${BeginDateField} lt ${formatDate(
      resolvedEndDate
    )})) or ((${BeginDateField} lt ${formatDate(
      resolvedStartDate
    )}) and (${EndDateField} gt ${formatDate(resolvedStartDate)})))`;

    resFilter = resFilter
      ? `${resFilter} and ${calendarFilter}`
      : calendarFilter;

    baseUrl += `?$inlinecount=allpages&$filter=(${resFilter})&$top=${top}${
      query ? `&query=${query}` : ""
    }`;

    if (advancedFilters && advancedFilters.length > 0)
      baseUrl += `&customFilters=${advancedFilters.join(",")}`;

    return baseUrl;
  }, [advancedFilters, dateInfo, filter, query, settings, space.Id]);

  return url;
};
