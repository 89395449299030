import React from "react";
import { useTicket } from "../../Hooks/EntityHooks";

export const ConnectTicketEntity = Component => {
  return ({ ticket, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const t = useTicket(ticket);
    return <Component ticket={t} {...rest} />;
  };
};
