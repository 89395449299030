import React from "react";
import { GeneralListItem } from "../Interventions/InterventionTargetItem";
import { useTicket, useClient } from "../../Hooks/EntityHooks";

const TicketListItem = ({ ticket, ...rest }) => (
  <GeneralListItem ticket item={ticket} {...rest} />
);

export default TicketListItem;

export const ConnectedTicketListItem = ({ ticket, ...rest }) => {
  
  const t = useTicket(ticket);
  const tClient = useClient(t && t.Client);
  return <TicketListItem ticket={t} ticketClient={tClient} {...rest} />;
};
