import React, { Component } from "react";
import DatePicker from "../DatePicker/DatePicker";

class ArDatePicker extends Component {
  handleChange = (e) => {
    const { onChange, enableDate } = this.props;
    if (enableDate === false) onChange(e);
    else onChange(e.Date);
  };

  render() {
    const { onChange, ...rest } = this.props;

    return (
      <DatePicker
        onChange={onChange ? this.handleChange : undefined}
        {...rest}
      />
    );
  }
}
export class ArOnlyDatePicker extends Component {
  handleChange = (e) => {
    const { onChange, enableDate } = this.props;
    if (enableDate === false) onChange(e);
    else onChange(e.Date);
  };

  render() {
    const { onChange, ...rest } = this.props;

    return (
      <DatePicker
        enableHours={false}
        onChange={onChange ? this.handleChange : undefined}
        {...rest}
      />
    );
  }
}

export default ArDatePicker;
