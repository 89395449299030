// import React, { useRef, useEffect } from "react";
// import classnames from "classnames";
import {
  darkred,
  red,
  //   lightred,
  darkgrey,
  grey,
  darkgreen,
  // green,
  //   monthNames,
  createChartComponent,
  getMonth,
  // darkblue,
  // opaqueDarkBlue,
  purple
  // lightPurple
} from "./echartDefaults";
import { TicketsStatusTypes } from "../../Tickets/TicketsHelper";
// include ECharts main module

// include bar chart
require("echarts/lib/echarts.js");
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
// include  title component

// include ECharts main module

// include bar chart

require("echarts/lib/chart/line");
// include  title component
require("echarts/lib/component/tooltip");

export const ComboEvolution = createChartComponent(
  (Data, intl, difrentCloseColor) => {
    const Open = [];
    const Closed = [];
    const Canceled = [];

    Data.forEach((e) => {
      Object.keys(e.Data).forEach((k) => {
        if (parseInt(k) === TicketsStatusTypes.open) Open.push(e.Data[k]);
        else if (parseInt(k) === TicketsStatusTypes.canceled)
          Canceled.push(e.Data[k]);
        else if (parseInt(k) === TicketsStatusTypes.closed)
          Closed.push(e.Data[k]);
      });
    });
    return {
      grid: {
        containLabel: true
        //   left: "5%",
        //   right: "5%",
        //   bottom: "10%",
        //   top: "5%"
      },
      tooltip: {
        renderMode: "richText",
        trigger: "item",
        //   formatter: "{b}: {c}",
        formatter: "{c}",
        textStyle: {
          color: "#FFFFFF",
          fontWight: "bolder"
        },
        backgroundColor: "#002B63",
        axisPointer: {
          animation: false
        }
      },
      legend: {
        left: "left",
        show: true,
        selectedMode: true,
        borderRadius: 3,
        itemGap: 15,
        icon: "roundRect",
        textStyle: {
          color: darkgrey
        },
        data: [
          {
            name: intl.formatMessage({ id: "CLOSED" }),
            textStyle: {
              color: difrentCloseColor ? darkred : darkgreen,
              padding: 5,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          },
          {
            name: intl.formatMessage({ id: "OPEN" }),
            textStyle: {
              color: purple,
              padding: 5,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          },
          {
            name: intl.formatMessage({ id: "CANCELLED" }),
            textStyle: {
              color: darkgrey,
              padding: 5,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          }
        ]
      },
      xAxis: [
        {
          offset: 10,
          name: "",
          nameLocation: "start",
          nameTextStyle: {
            padding: [47, 15, 0, 0],
            color: "#7c98b6"
          },
          boundaryGap: true,
          type: "category",
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          data: Data.map((e) => {
            return {
              value: `${getMonth(e.Month - 1)} ${e.Year.toString().substring(
                2
              )}`,
              textStyle: {
                color: "#7c98b6"
              }
            };
          })
        }
      ],
      yAxis: {
        boundaryGap: false,

        axisLabel: {
          color: "#7c98b6"
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ["#eff4f8"]
          }
        },
        name: "",
        nameLocation: "end",
        nameTextStyle: {
          padding: [0, 80, 0, 0],
          color: "#7c98b6"
        },
        type: "value",
        axisTick: {
          show: false
        }
      },
      series: [
        //   {
        //     name: "",
        //     data: Canceled,
        //     type: "line",
        //     symbol: "circle",
        //     symbolSize: 7,
        //     itemStyle: {
        //       color: grey
        //     },
        //     lineStyle: {
        //       color: darkgrey
        //     }
        //   },
        {
          name: intl.formatMessage({ id: "CANCELLED" }),
          data: Canceled,
          type: "bar",
          itemStyle: {
            color: grey,
            borderRadius: [5, 5, 5, 5]
          }
        },
        //   {
        //     name: "Abertos",
        //     data: Open,
        //     type: "line",
        //     symbol: "circle",
        //     symbolSize: 7,
        //     itemStyle: {
        //       color: green
        //     },
        //     lineStyle: {
        //       color: darkgreen
        //     }
        //   },
        {
          name: intl.formatMessage({ id: "OPEN" }),
          data: Open,
          type: "bar",
          itemStyle: {
            color: purple,
            borderRadius: [5, 5, 5, 5]
          }
        },
        //   {
        //     name: "",
        //     data: Closed,
        //     type: "line",
        //     symbol: "circle",
        //     symbolSize: 7,

        //     itemStyle: {
        //       color: red
        //     },
        //     lineStyle: {
        //       color: darkred
        //     }
        //   },
        {
          name: intl.formatMessage({ id: "CLOSED" }),
          data: Closed,
          type: "bar",
          itemStyle: {
            color: difrentCloseColor ? red : darkgreen,
            borderRadius: [5, 5, 5, 5]
          }
        }
      ]
    };
  }
);
