import Login from "./LoginPageReducer";
import Register from "./RegisterPageReducer";
import SettingsPermissions from "./SettingsPermissionsPageReducer";
import SpaceSetup from "./SpaceSetupPageReducer";
// import SpaceSettings from "./Space/Settings";
import { combineReducers } from "redux";

const Pages = combineReducers({
  Login,
  Register,
  SettingsPermissions,
  SpaceSetup,
  // SpaceContacts,
  // SpaceContracts,
  // SpaceTickets,
  // SpaceTicketForm,
  // SpaceTicketDetails,
  // SpaceInterventions,
  // SpaceClients,
  // SpaceContactForm,
  // SpaceClientForm,
  // SpaceContactDetails,
  // SpaceClientDetails,
  // SpaceCalls,
  // SpaceCallForm,
  // SpaceCallDetails,
  // SpaceTasks,
  // SpaceTaskForm,
  // SpaceProjects,
  // SpaceProjectForm,
  // SpaceProjectDetails,
  // SpaceBilling,
  // SpaceSettings
});

export default Pages;
