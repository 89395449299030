import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  EntityTypeQueryNameDictionary,
  EntiyTypeEnumAppFavorites,
  LinkTypesEnumAppFavorites,
  ViewTypeEnumAppFavorites
} from "./AppBarHelper";
import classes from "./AppBar.module.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarExclamation,
  faClipboardCheck,
  faFileContract,
  faFileInvoice,
  faHandshake,
  faSquareParking,
  faPhoneAlt,
  faShareNodes,
  faSignature,
  faListCheck,
  faSuitcase,
  faTicketSimple,
  faUserClock,
  faUserTie
} from "@fortawesome/pro-light-svg-icons";
import LabeledInput from "../../Components/Input/LabeledInput";
import TabStrip from "../../Components/TabStrip/TabStrip";
import { FavoritesTargetDropdown } from "../AdvancedMultiInputs";
import { useSpaceRoutesAccess } from "../Space/SpaceHelper";
interface IToggleInputProps {
  icon: IconProp;
  text: string;
  value: EntiyTypeEnumAppFavorites;
  className?: string;
}

const EntityTypeSelectedContext = React.createContext<
  AppBarCurrentType | undefined
>(undefined);
const EntityTypeSetterContext = React.createContext<
  | React.Dispatch<React.SetStateAction<AppBarCurrentType | undefined>>
  | undefined
>(undefined);

const NewFavoriteName = React.createContext<string>("");
const NewFavoriteNameSetter = React.createContext<
  ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined
>(undefined);

const ToggleInput: FC<IToggleInputProps> = React.memo(
  ({ icon, text, value, className }) => {
    const chosenValue = useContext(EntityTypeSelectedContext);
    const isActive = useMemo(() => {
      return chosenValue ? chosenValue.entity === value : false;
    }, [chosenValue, value]);

    const setValue = useContext(EntityTypeSetterContext);

    const handleClick = useCallback(() => {
      if (typeof setValue === "function")
        setValue((oldValue) => {
          const newVal = { ...oldValue } as AppBarCurrentType;
          newVal.entity = value;
          newVal.viewType = ViewTypeEnumAppFavorites.grid;
          newVal.linkType = LinkTypesEnumAppFavorites.complex;
          return newVal;
        });
    }, [setValue, value]);

    return (
      <div
        onClick={handleClick}
        className={classnames(
          "p-1 d-flex align-items-center",
          className,
          classes.favoriteCreator_singleview,
          { [classes.active]: isActive }
        )}
      >
        <div
          className={classnames(
            classes.favoriteCreator_singleview_icon_container
          )}
        >
          <FontAwesomeIcon icon={icon} className="text-white" />
        </div>
        <div className="text-black">{text}</div>
      </div>
    );
  }
);

export const FavoriteTypeGroupContext = React.createContext<any>(null);

const ViewTab: FC = () => {
  const intl = useIntl();
  const name = useContext(NewFavoriteName);
  const setName = useContext(NewFavoriteNameSetter);
  const accessDict = useSpaceRoutesAccess();

  const AccountGroup = useMemo(() => {
    const result = [];
    if (accessDict[EntiyTypeEnumAppFavorites.customers])
      result.push(
        <ToggleInput
          key={"ACCOUNTS"}
          icon={faSuitcase}
          text={intl.formatMessage({ id: "ACCOUNTS" })}
          value={EntiyTypeEnumAppFavorites.customers}
        />
      );
    if (accessDict[EntiyTypeEnumAppFavorites.contacts])
      result.push(
        <ToggleInput
          key={"CONTACTS"}
          icon={faUserTie}
          text={intl.formatMessage({ id: "CONTACTS" })}
          value={EntiyTypeEnumAppFavorites.contacts}
        />
      );
    return result;
  }, [accessDict, intl]);

  const ComercialGroup = useMemo(() => {
    const result = [];
    if (accessDict[EntiyTypeEnumAppFavorites.deals])
      result.push(
        <ToggleInput
          key={"DEALS"}
          icon={faHandshake}
          text={intl.formatMessage({ id: "DEALS" })}
          value={EntiyTypeEnumAppFavorites.deals}
        />
      );
    if (accessDict[EntiyTypeEnumAppFavorites.contracts])
      result.push(
        <ToggleInput
          key={"CONTRACTS"}
          icon={faFileContract}
          text={intl.formatMessage({ id: "CONTRACTS" })}
          value={EntiyTypeEnumAppFavorites.contracts}
        />
      );
    if (accessDict[EntiyTypeEnumAppFavorites.subscriptions])
      result.push(
        <ToggleInput
          key={"SUBSCRIPTIONS"}
          icon={faCalendarExclamation}
          text={intl.formatMessage({ id: "SUBSCRIPTIONS" })}
          value={EntiyTypeEnumAppFavorites.subscriptions}
        />
      );
    if (accessDict[EntiyTypeEnumAppFavorites.classifications])
      result.push(
        <ToggleInput
          key={"CLASSIFICATIONS"}
          icon={faClipboardCheck}
          text={intl.formatMessage({ id: "CLASSIFICATIONS" })}
          value={EntiyTypeEnumAppFavorites.classifications}
        />
      );
    if (accessDict[EntiyTypeEnumAppFavorites.billing])
      result.push(
        <ToggleInput
          key={"BILLING"}
          icon={faFileInvoice}
          text={intl.formatMessage({ id: "BILLING" })}
          value={EntiyTypeEnumAppFavorites.billing}
        />
      );
    return result;
  }, [accessDict, intl]);

  const ServicesGroup = useMemo(() => {
    const result = [];
    if (accessDict[EntiyTypeEnumAppFavorites.calls])
      result.push(
        <ToggleInput
          key={"CALLS"}
          icon={faPhoneAlt}
          text={intl.formatMessage({ id: "CALLS" })}
          value={EntiyTypeEnumAppFavorites.calls}
        />
      );
    if (accessDict[EntiyTypeEnumAppFavorites.projects])
      result.push(
        <ToggleInput
          key={"PROJECTS"}
          icon={faSquareParking}
          text={intl.formatMessage({ id: "PROJECTS" })}
          value={EntiyTypeEnumAppFavorites.projects}
        />
      );
    if (accessDict[EntiyTypeEnumAppFavorites.tasks])
      result.push(
        <ToggleInput
          key={"TASKS"}
          icon={faListCheck}
          text={intl.formatMessage({ id: "TASKS" })}
          value={EntiyTypeEnumAppFavorites.tasks}
        />
      );
    if (accessDict[EntiyTypeEnumAppFavorites.tickets])
      result.push(
        <ToggleInput
          key={"CASES"}
          icon={faTicketSimple}
          text={intl.formatMessage({ id: "CASES" })}
          value={EntiyTypeEnumAppFavorites.tickets}
        />
      );
    if (accessDict[EntiyTypeEnumAppFavorites.interventions])
      result.push(
        <ToggleInput
          key={"TIMES"}
          icon={faUserClock}
          text={intl.formatMessage({ id: "TIMES" })}
          value={EntiyTypeEnumAppFavorites.interventions}
        />
      );
    return result;
  }, [accessDict, intl]);

  const MarketingGroup = useMemo(() => {
    const result = [];
    if (accessDict[EntiyTypeEnumAppFavorites.social])
      result.push(
        <ToggleInput
          key={"PUBLICATIONS"}
          icon={faShareNodes}
          text={intl.formatMessage({ id: "PUBLICATIONS" })}
          value={EntiyTypeEnumAppFavorites.social}
        />
      );
			
    if (accessDict[EntiyTypeEnumAppFavorites.signatures])
      result.push(
        <ToggleInput
          key={"EMAIL_SIGNATURE"}
          icon={faSignature}
          text={intl.formatMessage({ id: "EMAIL_SIGNATURE" })}
          value={EntiyTypeEnumAppFavorites.signatures}
        />
      );
    return result;
  }, [accessDict, intl]);

  return (
    <div>
      <div className="fs-18 fw-bold my-2 text-black">
        <FormattedMessage id="ADD_NEW_VIEW_APP_BAR_TITLE" />
      </div>
      <div className="fs-18 mb-2 text-black">
        <FormattedMessage id="ADD_NEW_VIEW_APP_BAR_DESC" />
      </div>
      <LabeledInput
        autoComplete="off"
        text={intl.formatMessage({ id: "NAME" })}
        className={classnames("flex-1 mb-2")}
        name="Name"
        onChange={setName}
        value={name}
      />
      {AccountGroup.length > 0 && (
        <>
          <div className="fs-12 mb-1 text-grey">
            <FormattedMessage id="CONTACTS" />
          </div>
          <div
            className={classnames(
              "mb-2",
              classes.favoriteCreatorSingleViewContainer
            )}
          >
            {AccountGroup}
          </div>
        </>
      )}
      {ServicesGroup.length > 0 && (
        <>
          <div className="fs-12 mb-1 text-grey">
            <FormattedMessage id="SERVICES" />
          </div>
          <div
            className={classnames(
              "mb-2",
              classes.favoriteCreatorSingleViewContainer
            )}
          >
            {ServicesGroup}
          </div>
        </>
      )}
      {ComercialGroup.length > 0 && (
        <>
          <div className="fs-12 mb-1 text-grey">
            <FormattedMessage id="COMERCIAL" />
          </div>
          <div
            className={classnames(
              "mb-2",
              classes.favoriteCreatorSingleViewContainer
            )}
          >
            {ComercialGroup}
          </div>
        </>
      )}
      {MarketingGroup.length > 0 && (
        <>
          <div className="fs-12 mb-1 text-grey">
            <FormattedMessage id="MARKETING" />
          </div>
          <div
            className={classnames(
              "mb-2",
              classes.favoriteCreatorSingleViewContainer
            )}
          >
            {MarketingGroup}
          </div>
        </>
      )}
    </div>
  );
};

const DetailsTab: FC = () => {
  const intl = useIntl();
  const name = useContext(NewFavoriteName);
  const setName = useContext(NewFavoriteNameSetter);
  const setValue = useContext(EntityTypeSetterContext);

  const [target, setTarget] = useState();

  const handleChange = useCallback(
    (e:any) => {
      if (typeof setValue === "function")
        setValue((oldValue) => {
          const newVal = { ...oldValue } as AppBarCurrentType;
          newVal.entity = EntityTypeQueryNameDictionary[
            e.type
          ] as EntiyTypeEnumAppFavorites;
          newVal.itemId = e.data;
          newVal.viewType = undefined;
          newVal.linkType = LinkTypesEnumAppFavorites.details;
          return newVal;
        });
      setTarget(e);
    },
    [setValue]
  );

  return (
    <div>
      <div className="fs-18 fw-bold my-2 text-black">
        <FormattedMessage id="ADD_NEW_DETAILS_APP_BAR_TITLE" />
      </div>
      <div className="fs-18 mb-2 text-black">
        <FormattedMessage id="ADD_NEW_DETAILS_APP_BAR_DESC" />
      </div>
      <LabeledInput
        autoComplete="off"
        text={intl.formatMessage({ id: "NAME" })}
        className={classnames("flex-1 mb-2")}
        name="Name"
        onChange={setName}
        value={name}
      />
      <LabeledInput
        name="InterventionTarget"
        autoComplete="off"
        text={intl.formatMessage({ id: "ASSOCIATE_TO" })}
        InputComponent={FavoritesTargetDropdown}
        allClients={true}
        value={target}
        disableEntityCreation
        onChange={handleChange}
        className="mb-3"
      />
    </div>
  );
};

export interface AppBarCurrentType {
  title: string;
  entity: EntiyTypeEnumAppFavorites;
  itemId?: number;
  viewType?: ViewTypeEnumAppFavorites;
  linkType: LinkTypesEnumAppFavorites;
}

export interface FavoriteTypePickerProps {
  name: string;
  handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: React.Dispatch<
    React.SetStateAction<AppBarCurrentType | undefined>
  >;
  currentEntityType: AppBarCurrentType | undefined;
}

const tabs = [
  {
    default: true,
    name: <FormattedMessage id="ADD_NEW_VIEW_APP_BAR" />,
    component: <ViewTab />
  },
  {
    name: <FormattedMessage id="ADD_NEW_DETAIL_VIEW_APP_BAR" />,
    component: <DetailsTab />
  }
];

const FavoriteTypePicker: FC<FavoriteTypePickerProps> = ({
  handleChange,
  currentEntityType,
  name,
  handleNameChange
}) => {
  return (
    <EntityTypeSelectedContext.Provider value={currentEntityType}>
      <EntityTypeSetterContext.Provider value={handleChange}>
        <NewFavoriteName.Provider value={name}>
          <NewFavoriteNameSetter.Provider value={handleNameChange}>
            <TabStrip
              route={false}
              alignLeft
              headerClassName="px-2"
              contentClassName="px-4 py-2"
              items={tabs}
              className="ar-details-tabstrip h-100"
            />
          </NewFavoriteNameSetter.Provider>
        </NewFavoriteName.Provider>
      </EntityTypeSetterContext.Provider>
    </EntityTypeSelectedContext.Provider>
  );
};

export default FavoriteTypePicker;
