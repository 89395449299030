import React, { useMemo, useState, useCallback } from "react";
import { IntlProvider } from "react-intl";
import "moment/locale/es";
import "moment/locale/pt";
import { pt } from "../../Assets/Langs/pt";
import { en } from "../../Assets/Langs/en";
import { es } from "../../Assets/Langs/es";
import moment from "moment";

const handleError = (error) => {
  if (process.env.NODE_ENV !== "production") {
    console.error(error);
  }
};

export const enConfig = {
  Value: "en",
  Name: "English",
  messages: en,
  country: { Code: "gb" }
};
export const ptConfig = {
  Value: "pt",
  Name: "Português",
  messages: pt,
  country: { Code: "pt" }
};
export const esConfig = {
  Value: "es",
  Name: "Español",
  messages: es,
  country: { Code: "es" }
};
export const Languages = {
  en: enConfig,
  pt: ptConfig,
  es: esConfig
};

moment.updateLocale("pt", {
  relativeTime: {
    future: "dentro de %s"
  }
});

export const possibleLanguages = [enConfig, ptConfig, esConfig];

const Intl18nContext = React.createContext();

const Intl18nProvider = ({ children }) => {
  const [lang, setLang] = useState(() => {
    return (
      Languages[window.localStorage.getItem("intl18-lang")] ||
      possibleLanguages[0]
    );
  });

  const changeLanguage = useCallback((newLang) => {
    window.localStorage.setItem("intl18-lang", newLang.Value);
    moment.locale(newLang.Value);
    setLang(newLang);
  }, []);

  const contextValue = useMemo(() => {
    return { changeLanguage, value: lang };
  }, [changeLanguage, lang]);

  return (
    <IntlProvider
      locale={lang.Value}
      messages={lang.messages}
      onError={handleError}
    >
      <Intl18nContext.Provider value={contextValue}>
        {children}
      </Intl18nContext.Provider>
    </IntlProvider>
  );
};

export default Intl18nProvider;
export { Intl18nContext };
