import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ConnectedAccountProfile } from "../../../Components/Accounts/AccountProfile";
import { GridDateComponent } from "../../../Components/CGrid/CGrid";
import { DefaultColumnsWidth } from "../../../Components/CGrid/GridDefinitions";
import {
  GridRequestProvider,
  ServerAwareProvider
} from "../../../Components/CGrid/ServerGrid";
import PageHeader from "../../../Components/Headers/PageHeader";
import { ConnectedOrganizationProfile } from "../../../Components/Organizations/OrganizationProfile";
import {
  PageContainer,
  PageContent
} from "../../../Components/PageUtilities/PageUtilities";
import { spaceApiTokensSchema } from "../../../config/schema";
import { ApiTokensFormButton } from "../../Form/FormButtons";
import { DomainKeyDescCopy } from "../Domains/DomainKeys/DomainKeysClipboard";
import ApiKeyGrid, { ApiKeysState } from "./ApiKeysGrid";
import { Link } from "react-router-dom";
import { useSpace } from "../../../Contexts/SpaceContext";
import {
  faAlignJustify,
  faBuilding,
  faCalendar,
  faDrawCircle,
  faKey,
  faKeySkeleton,
  faUser
} from "@fortawesome/pro-solid-svg-icons";
import SaveFavoriteButton from "../../AppBar/SaveFavoriteButton";

const LogsLink = ({ apiKeyId }) => {
  const space = useSpace();
  const url = `/s/${space.Id}/settings/apiLogs?$filter=ApiLog/ApiKeyId eq ${apiKeyId}`;

  return (
    <Link className="EntityLinkClassID text-primary" to={url}>
      <FormattedMessage id="VIEW_LOGS" />
    </Link>
  );
};

export const ApiKeysColumns = [
  {
    width: DefaultColumnsWidth.singleButton,
    field: "buttons",
    required: true,
    title: "Actions",
    minSize: 40,
    orderBy: false,
    noTitle: true,
    sortable: false,
    className: "p-2",
    Cell: ({ Data: value }) => (
      <ApiTokensFormButton vType="primary-ghost" id={value.Id} canEdit />
    )
  },
  {
    headerIcon: faUser,
    sortable: false,
    field: "Account",
    width: DefaultColumnsWidth.Origem,
    title: <FormattedMessage id="USER" />,
    Cell: ({ Data }) => {
      return <ConnectedAccountProfile account={Data.Account} />;
    }
  },
  {
    headerIcon: faBuilding,
    sortable: false,
    field: "Account",
    width: DefaultColumnsWidth.Company,
    title: <FormattedMessage id="COMPANY" />,
    Cell: ({ Data }) => {
      return <ConnectedOrganizationProfile organization={Data.Organization} />;
    }
  },
  {
    headerIcon: faKey,
    sortable: false,
    field: "Id",
    width: DefaultColumnsWidth.buttons,
    title: <FormattedMessage id="LOGS" />,
    Cell: ({ Data }) => {
      return <LogsLink apiKeyId={Data.Id} />;
    }
  },
  {
    headerIcon: faKeySkeleton,
    sortable: false,
    field: "Token",
    width: DefaultColumnsWidth.Title,
    title: <FormattedMessage id="API_KEY" />,
    Cell: ({ Data }) => {
      return <DomainKeyDescCopy value={Data.Token} />;
      // (
      //   <div className="d-flex align-items-center w-100">
      //     <div className="mr-2 flex-1 overflow-hidden text-truncate">
      //       {Data.Token}
      //     </div>
      //     <div>
      //       <FontAwesomeIcon icon={faCopy} />
      //     </div>
      //   </div>
      // );
    }
  },
  {
    headerIcon: faAlignJustify,
    sortable: false,
    field: "Description",
    width: DefaultColumnsWidth.Description,
    title: <FormattedMessage id="DESCRIPTION" />,
    Cell: ({ Data }) => {
      return <div>{Data.Description}</div>;
    }
  },

  {
    headerIcon: faDrawCircle,
    sortable: false,
    field: "IsActive",
    width: DefaultColumnsWidth.status,
    title: <FormattedMessage id="STATUS" />,
    Cell: ({ Data }) => {
      return <ApiKeysState state={Data.State} />;
    }
  },
  {
    headerIcon: faUser,
    sortable: false,
    field: "Creator",
    width: DefaultColumnsWidth.Origem,
    title: <FormattedMessage id="CREATED_BY" />,
    Cell: ({ Data }) => {
      return <ConnectedAccountProfile account={Data.Creator} />;
    }
  },
  {
    headerIcon: faCalendar,
    sortable: false,
    field: "CreationDate",
    width: DefaultColumnsWidth.AssignedGroup,
    title: <FormattedMessage id="CREATION_DATE" />,
    Cell: ({ Data: value }) => {
      return (
        <GridDateComponent removeSlice date={new Date(value.CreationDate)} />
      );
    }
  }
];

const filterColumns = [
  {
    propField: "Id",
    // icon: <FontAwesomeIcon icon={faPhone} />,
    titleId: "NR",
    field: "ApiKey/Id",
    type: "number"
  }
];

const defaultOrderBy = { column: "ApiKey/Id", type: "desc" };

const ApiKeys = (props) => {
  const columnResize = true;
  // const intl = useIntl();
  const Workspace = useSpace();
  return (
    <PageContainer>
      <ServerAwareProvider
        defaultOrderBy={defaultOrderBy}
        columns={ApiKeysColumns}
        filterColumns={filterColumns}
        schema={spaceApiTokensSchema}
      >
        <PageHeader title={<FormattedMessage id="API_KEYS" />}>
          <div className="d-flex align-items-center justify-content-end flex-1">
            {/* <ServerAwareQueryInput
              placeholder={intl.formatMessage({ id: "SEARCH" })}
            /> */}
            <div className="d-flex align-items-center">
              <SaveFavoriteButton />
              {Workspace.CurrentPId > 2 && (
                <ApiTokensFormButton className="ml-3" />
              )}
            </div>

            {/* <EmailDomainsFormButton /> */}
          </div>
        </PageHeader>
        <PageContent>
          {/* <ServerAwareFilterList
            disableAdvancedFilters
            disableColorFilters
            disableOrdering
            // groupableColumns={groupableColumns}
            filterType={FilterViewTypeEnum.ApiKeys}
          /> */}
          <GridRequestProvider>
            <ApiKeyGrid
              className="flex-1"
              columnResize={columnResize}
              //   onItemSelect={handleItemSelection}
              //   selectedItems={selectedItems}
            />
          </GridRequestProvider>
        </PageContent>
      </ServerAwareProvider>
    </PageContainer>
  );
};

export default ApiKeys;
