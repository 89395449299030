import React, { useRef, useState, useEffect } from "react";

import classnames from "classnames";

import Checkbox from "../Checkbox/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import {
  faChevronCircleDown,
  faChevronCircleRight
} from "@fortawesome/pro-light-svg-icons";
import { useHoverEvents } from "../../Containers/Origin/Origin";
import Popup from "../Popup/Popup";
import TextEllipsis from "../TextEllipsis/TextEllipsis";
import { LabelTitle } from "../../Helpers/GridHelper";
import { FormattedMessage } from "react-intl";

export const AlternateNodeRendererDefault = ({ ...props }) => {
  const {
    scaffoldBlockPxWidth,
    toggleChildrenVisibility,
    connectDragPreview,
    connectDragSource,
    isDragging,
    canDrop,
    canDrag,
    node,
    title,
    subtitle,
    draggedNode,
    path,
    treeIndex,
    isSearchMatch,
    isSearchFocus,
    buttons,
    className,
    style,
    didDrop,
    treeId,
    isOver, // Not needed, but preserved for other renderers
    parentNode, // Needed for dndManager
    rowDirection,
    checkEnable,
    onCheckChange,
    handledit,
    canceledit,
    removeFunction,
    preview,
    startSelecting,
    startEditing,
    checkStatus,
    stopEdition,
    Id,
    NodeWidth,
    CurrentSelectedIndex,
    CurrentEditingIndex,
    ...otherProps
  } = props;

  const nodeTitle = title || node.title;
  const resolvedTitle =
    typeof nodeTitle === "function"
      ? nodeTitle({
          node,
          path,
          treeIndex
        })
      : nodeTitle;
  // const nodeSubtitle = subtitle || node.subtitle;

  const rowDirectionClass = rowDirection === "rtl" ? "rst__rtl" : null;
  const [textTitle, setTitle] = useState(resolvedTitle);

  useEffect(() => {
    if (CurrentEditingIndex !== treeIndex && textTitle !== resolvedTitle) {
      setTitle(resolvedTitle);
    }
  }, [CurrentEditingIndex, treeIndex, textTitle, resolvedTitle]);

  const ActionButtonRef = useRef();
  const InputRef = useRef();
  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  useRef(() => {
    if (InputRef.current) {
      InputRef.current.Focus();
    }
  }, []);

  let handle;
  if (canDrag && !preview) {
    if (typeof node.children === "function" && node.expanded) {
      // Show a loading symbol on the handle when the children are expanded
      //  and yet still defined by a function (a callback to fetch the children)
      handle = (
        <div className="rst__loadingHandle">
          <div className="rst__loadingCircle">
            {[...new Array(12)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classnames(
                  "rst__loadingCirclePoint",
                  rowDirectionClass
                )}
              />
            ))}
          </div>
        </div>
      );
    } else {
      // Show the handle used to initiate a drag-and-drop
      handle = connectDragSource(
        <div className="d-flex node-move-handle">
          <FontAwesomeIcon icon={faGripVertical} />
        </div>,
        {
          dropEffect: "copy"
        }
      );
    }
  }
  let checkbox = null;
  if (checkEnable) {
    checkbox = (
      <div style={{ width: 24 }} className="mr-2">
        <Checkbox
          onChange={() => {
            onCheckChange(!checkStatus);
          }}
          checked={checkStatus}
        ></Checkbox>
      </div>
    );
  } else if (!checkEnable && preview) {
    checkbox = (
      <div style={{ width: 24 }} className="mr-2">
        <Checkbox disabled checked={checkStatus}></Checkbox>
      </div>
    );
  }

  // const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };
  if (rowDirection === "rtl") {
    buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
  }

  const handleDeleteKeyDown = (e) => {
    if (e.keyCode === 46) {
      e.stopPropagation();
      e.preventDefault();
      handleCancelEdit();
      removeFunction();
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.stopPropagation();
      e.preventDefault();
      if (/\S/.test(e.target.value)) handledit(e.target.value);
      else {
        handleCancelEdit();
      }
    }
  };

  const handleCancelEdit = (e) => {
    canceledit();
    setTitle(
      typeof nodeTitle === "function"
        ? nodeTitle({
            node,
            path,
            treeIndex
          })
        : nodeTitle
    );
  };

  const TextRef = useRef();

  useRef(() => {
    if (CurrentSelectedIndex === treeIndex && TextRef.current)
      TextRef.current.Focus();
  }, []);

  return (
    <>
      <Popup
        anchorEl={ActionButtonRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <div
          className="py-2 ar-checklist-actionPopover bg-white rounded"
          style={{ cursor: "context-menu" }}
        >
          <div>
            <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
            <span onClick={onPopupMouseLeave}>{buttons}</span>
          </div>
        </div>
      </Popup>
      <div style={{ height: "100%" }} className="ar-checklist" {...otherProps}>
        {toggleChildrenVisibility &&
          node.children &&
          (node.children.length > 0 || typeof node.children === "function") && (
            <div>
              <span
                type="button"
                aria-label={node.expanded ? "Collapse" : "Expand"}
                className={classnames("ExpanderButton")}
                style={buttonStyle}
                onClick={() =>
                  toggleChildrenVisibility({
                    node,
                    path,
                    treeIndex
                  })
                }
              >
                {node.expanded ? (
                  <FontAwesomeIcon
                    className="bg-white"
                    icon={faChevronCircleDown}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="bg-white"
                    icon={faChevronCircleRight}
                  />
                )}
              </span>
              {node.expanded && !isDragging && (
                <div
                  style={{ width: scaffoldBlockPxWidth, zIndex: -1 }}
                  className={classnames(
                    "ar-linechart-alternate rst__lineChildren",
                    rowDirectionClass
                  )}
                />
              )}
            </div>
          )}
        <div className="node-wrapper">
          {connectDragPreview(
            <div
              style={{ width: NodeWidth }}
              className={classnames(
                "node-component p-0 d-flex align-items-center bg-airdesk ssi-control ssi-input border",
                isLandingPadActive && "rst__rowLandingPad",
                isLandingPadActive && "bg-transparent",
                isLandingPadActive && !canDrop && "rst__rowCancelPad",
                isSearchMatch && "rst__rowSearchMatch",
                isSearchFocus && "rst__rowSearchFocus"
              )}
            >
              <div className="d-flex h-100 align-items-center px-2">
                {handle}
              </div>
              {checkbox}
              {CurrentEditingIndex === treeIndex ? (
                <div className="flex-1 d-flex align-items-center h-100">
                  <input
                    name="search-text"
                    autoComplete="off"
                    maxLength="1024"
                    onBlur={() => {
                      if (handleCancelEdit) handleCancelEdit();
                    }}
                    autoFocus
                    className="border-0 bg-airdesk
					 outline-0 w-100"
                    value={textTitle}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{ outline: "none" }}
                  ref={TextRef}
                  onKeyDown={handleDeleteKeyDown}
                  tabIndex={1}
                  onClick={() => {
                    if (!preview) startSelecting();
                  }}
                  onDoubleClick={() => {
                    if (!preview) startEditing();
                  }}
                  className="text-truncate d-flex align-items-center h-100 flex-1"
                >
                  <TextEllipsis
                    length={NodeWidth <= 290 ? 25 : 32}
                    truncate
                    text={
                      typeof nodeTitle === "function"
                        ? nodeTitle({
                            node,
                            path,
                            treeIndex
                          })
                        : nodeTitle
                    }
                  />
                </div>
              )}
              {preview ? null : CurrentEditingIndex !== treeIndex ? (
                <div
                  style={{ color: "#7C98B6" }}
                  ref={ActionButtonRef}
                  onClick={onAnchorMouseEnter}
                  onMouseLeave={onAnchorMouseLeave}
                  className="d-flex h-100 align-items-center cursor-pointer px-2"
                >
                  <FontAwesomeIcon icon={faEllipsisV} />
                </div>
              ) : (
                <>
                  <div
                    onMouseDown={() => {
                      handleCancelEdit();
                    }}
                    className="pl-2 h-100 d-flex align-items-center text-danger-alt cursor-pointer text-decoration-underline-with-hover"
                  >
                    <FormattedMessage id={"CANCEL"} />
                  </div>
                  <div
                    onMouseDown={(e) => {
                      handledit(textTitle);
                    }}
                    className="px-2 h-100 d-flex align-items-center text-primary cursor-pointer text-decoration-underline-with-hover"
                  >
                    OK
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        {/* <div className={classnames("rst__rowWrapper", rowDirectionClass)}>
          {connectDragPreview(
            <div
              className={classnames(
                "rst__row",
                isLandingPadActive && "rst__rowLandingPad",
                isLandingPadActive && !canDrop && "rst__rowCancelPad",
                isSearchMatch && "rst__rowSearchMatch",
                isSearchFocus && "rst__rowSearchFocus",
                rowDirectionClass,
                className
              )}
              style={{
                opacity: isDraggedDescendant ? 0.5 : 1,
                ...style
              }}
            >
              {handle}

              <div
                className={classnames(
                  "rst__rowContents",
                  !canDrag && "rst__rowContentsDragDisabled",
                  rowDirectionClass
                )}
              >
                {checkbox}
                <div className={classnames("rst__rowLabel", rowDirectionClass)}>
                  <span
                    className={classnames(
                      "rst__rowTitle",
                      node.subtitle && "rst__rowTitleWithSubtitle"
                    )}
                  >
                    {typeof nodeTitle === "function"
                      ? nodeTitle({
                          node,
                          path,
                          treeIndex
                        })
                      : nodeTitle}
                  </span>

                  {nodeSubtitle && (
                    <span className="rst__rowSubtitle">
                      {typeof nodeSubtitle === "function"
                        ? nodeSubtitle({
                            node,
                            path,
                            treeIndex
                          })
                        : nodeSubtitle}
                    </span>
                  )}
                </div>

                <div className="rst__rowToolbar">
                  {buttons.map((btn, index) => (
                    <div
                      key={index} // eslint-disable-line react/no-array-index-key
                      className="rst__toolbarButton"
                    >
                      {btn}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      */}
      </div>
    </>
  );
};

AlternateNodeRendererDefault.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: "",
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  subtitle: null,
  rowDirection: "ltr"
};
