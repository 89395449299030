import React from "react";
import EmailChatComponent from "../EmailChatComponent";

// const ReceptionChatLoading = ({ Loading }) => {
//   return (
//     <div className="h-100 w-100 d-flex flex-column">
//       <div className="EmailChatHeader d-flex justify-content-between align-items-center px-4">
//         <div className="d-flex justify-content-start">
//           <div className="d-flex align-items-center text-black">
//             {/* <FontAwesomeIcon icon={faCommentsAlt} className="mr-2" /> */}
//             <span>{/* <FormattedMessage id="MESSAGES" /> */}</span>
//           </div>
//           <div></div>
//         </div>
//         <div className="d-flex align-items-center">
//           <div></div>
//           <div></div>
//         </div>
//       </div>
//       {/* <hr className="w-100 mt-0 px-4" /> */}
//       <div className="d-flex flex-column flex-1 justify-content-center">
//         <LoaderSpinner center size="sm" />
//       </div>
//       {/* Resolved Email Banner  <div></div>*/}
//       {/* <div ref={pageRef} className="of-y-auto flex-1 mb-4 px-4">
//         <EmailMessagesComponent />
//       </div> */}
//       {/* {(loading || error) && <LoaderSpinner center size="sm" />} */}

//       {/* <TabStrip
//         contentClassName="py-0 of-v"
//         index={tabIndex}
//         switchTab={handleTabSwitching}
//         onTabChange
//         alignLeft
//         route={false}
//         content
//         items={tabItems}
//         className="ar-details-tabstrip bg-white px-4 air-email-chat-footer"
//       /> */}
//     </div>
//   );
// };

const ReceptionEmailChat = ({ OpenedId, Loading }) => {
  // if (!Loading) {
  return (
    <div className="air-reception-chat-container of-hidden flex-1">
      {OpenedId && <EmailChatComponent Loading={Loading} OpenedId={OpenedId} />}
    </div>
  );
  // }

  // return (
  //   <div className="air-reception-chat-container of-hidden">
  //     <ReceptionChatLoading />
  //   </div>
  // );
};

export default ReceptionEmailChat;
