import {
  faCalendarExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import TriggerCreation from "./TriggerCreation";
import {
  spaceSubscriptionSchema
} from "../../../../../config/schema";
import { ConnectedSubscriptionOrigin } from "../../../../Origin/SubscriptionOrigin";
import { useIntl } from "react-intl";

const PreviewTarget = ({ val }) => <ConnectedSubscriptionOrigin Subscription={val} />;

const TriggerSubscriptionCreation = React.memo(({ ...props }) => {
	const intl = useIntl()
  return (
    <TriggerCreation
      {...props}
			TargetPreview={PreviewTarget}
      schema={spaceSubscriptionSchema}
      icon={faCalendarExclamation}
			text={intl.formatMessage({ id: "SUBSCRIPTION" })}
    />
  );
});

export default TriggerSubscriptionCreation;
