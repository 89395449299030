import React, { useState, useMemo, useRef } from "react";
import SortableTree, {
  toggleExpandedForAll
} from "@nosferatu500/react-sortable-tree";
import classnames from "classnames";
import "@nosferatu500/react-sortable-tree/style.css";
import "./Checklist.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faExpandAlt,
  faCompressAlt
} from "@fortawesome/pro-solid-svg-icons";
import { AlternateNodeRendererDefault } from "./ChecklistComponent";
import {
  faSearch,
  faPencil,
  faClone,
  faTrashAlt,
  faMinusCircle,
  faArrowFromTop,
  faArrowFromBottom
} from "@fortawesome/pro-light-svg-icons";
import Button from "../Button/Button";
import { removeNode, changeNodeAtPath, walk } from "./tree_list_utils";
import { useIntl, FormattedMessage } from "react-intl";
import { v1 as uuidv1 } from "uuid";

const Checklist = ({
  value: data = [],
  preview = false,
  onChange,
  disableTop = false,
  defaultMaxHeight = false,
  canCheck,
  className = "",
  containerName = "",
  nodeWidth,
  disableMaximise,
  optionalItemButtonsConfig,
  ...props
}) => {
  const ref = useRef();
  const [search, setSearch] = useState("");
  const [editing, setEditing] = useState(null);
  const [select, setSelect] = useState(null);
  const [enableMax, setEnableMax] = useState(defaultMaxHeight);
  const handleSearchOnChange = (e) => {
    setSearch(e.target.value);
  };

  const toggleNodeExpansion = (expanded) => {
    const tempData = [...data];

    onChange(
      toggleExpandedForAll({
        treeData: tempData,
        expanded
      })
    );
  };

  const handleKeyPress = (e) => {
    if (
      e.key === "Enter" &&
      e.target.value.length > 0 &&
      /\S/.test(e.target.value)
    ) {
      e.preventDefault();
      const tempData = [...data];

      tempData.push({
        title: e.target.value,
        Name: e.target.value,
        TreeIndex: tempData.length,
        Path: [tempData.length],
        children: [],
        Checked: false
      });
      onChange(tempData);
      ref.current.value = "";
    } else if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleDuplicateSingle = (rowInfo) => {
    const tempData = [...data];

    tempData.push({
      title: rowInfo.node.title,
      Name: rowInfo.node.title,
      Checked: false,
      children: []
    });
    onChange(tempData);
  };

  const HandleChildrenDuplicate = (e) => {
    const tempData = e.children.map((t) => {
      if (t.children.length > 0) {
        return {
          title: e.title,
          Name: e.title,
          Checked: false,
          children: HandleChildrenDuplicate(t),
          expanded: true
        };
      } else {
        return {
          title: e.title,
          Name: e.title,
          children: [],
          Checked: false,
          expanded: true
        };
      }
    });
    return tempData;
  };

  const handleDuplicateAll = (rowInfo) => {
    const tempData = [...data];
    const tempChildren = rowInfo.node.children.map((e) => {
      if (e.children.length > 0) {
        return {
          title: e.title,
          Name: e.title,
          Checked: false,
          children: HandleChildrenDuplicate(e),
          expanded: true
        };
      } else {
        return {
          title: e.title,
          Name: e.title,
          Checked: false,
          children: [],
          expanded: true
        };
      }
    });
    tempData.push({
      title: rowInfo.node.title,
      Name: rowInfo.node.title,
      Checked: false,
      children: tempChildren,
      expanded: true
    });
    onChange(tempData);
  };

  const handlePlusClick = () => {
    const value = ref.current.value;
    if (value.length > 0 && /\S/.test(value)) {
      const tempData = [...data];

      tempData.push({
        title: value,
        Name: value,
        Checked: false,
        children: []
      });
      onChange && onChange(tempData);
      ref.current.value = "";
    }
  };

  const handleNodeAndChildrenDelete = (rowInfo) => {
    const { path } = rowInfo;

    const result = removeNode({
      treeData: data,
      path,
      getNodeKey: ({ node, treeIndex }) => {
        return treeIndex;
      }
    });
    var newResolvedData = getResolvedCheckboxs(
      [...result.treeData],
      null,
      null,
      null
    );
    onChange && onChange(newResolvedData);
  };

  const checkChange = (e) => {
    const tempData = [...e];

    var newResolvedData = getResolvedCheckboxs([...tempData], null, null, null);

    onChange && onChange(newResolvedData);
  };

  const resolvedData = useMemo(() => {
    return data;
  }, [data]);

  const handleTextEdit = (value, rowInfo) => {
    const changedNode = rowInfo.node;
    changedNode.title = value;
    changedNode.Name = value;
    const NewTree = changeNodeAtPath({
      treeData: resolvedData,
      path: rowInfo.path,
      newNode: changedNode,
      getNodeKey: ({ node, treeIndex }) => {
        return treeIndex;
      }
    });
    onChange(NewTree);
    setEditing(null);
  };

  const getResolvedCheckboxs = (data, parentChecked, oldChecked, rowIndex) => {
    return data.map((e) => {
      let currentE = {
        ...e,
        Checked: getResolvedIsChecked(
          e,
          e.Checked,
          parentChecked,
          oldChecked,
          rowIndex
        )
      };

      if (currentE.children.length > 0)
        currentE.children = getResolvedCheckboxs(
          currentE.children,
          currentE.Checked,
          oldChecked,
          rowIndex
        );

      return currentE;
    });
  };

  const getResolvedIsChecked = (
    item,
    currentCheck,
    parentCheck,
    oldChecked,
    rowIndex
  ) => {
    if (item.TreeIndex === rowIndex) return currentCheck;

    if (
      parentCheck !== null &&
      parentCheck !== currentCheck &&
      item.Path.some((a) => a === rowIndex)
    )
      return !oldChecked;
    // else if (
    //   parentCheck !== null &&
    //   parentCheck !== currentCheck &&
    //   oldChecked !== null
    // )
    //   return currentCheck;

    // if (parentCheck || (item.Checked && !parentCheck)) return parentCheck;
    if (item.children.length === 0) return item.Checked;
    var aaa = !item.children.some(
      (e) =>
        getResolvedIsChecked(
          e,
          e.Checked,
          item.Checked,
          oldChecked,
          rowIndex
        ) === false
    );

    if (parentCheck === null && aaa !== currentCheck && oldChecked !== null)
      return !oldChecked;

    return aaa;
  };

  const handleCheckboxChanges = (value, rowInfo) => {
    const changedNode = rowInfo.node;
    changedNode.Checked = value;

    const NewTree = changeNodeAtPath({
      treeData: resolvedData,
      path: rowInfo.path,
      newNode: changedNode,
      getNodeKey: ({ node, treeIndex }) => {
        return treeIndex;
      }
    });

    var newResolvedData = getResolvedCheckboxs(
      [...NewTree],
      null,
      !value,
      rowInfo.treeIndex
    );

    onChange(newResolvedData);
  };

  const removeAll = () => {
    setEditing(null);
    onChange([]);
  };

  const NodeCount = useMemo(() => {
    let count = 0;
    walk({
      treeData: resolvedData,
      getNodeKey: ({ node, treeIndex }) => {
        return treeIndex;
      },
      callback: () => {
        count++;
      }
    });
    return count;
  }, [resolvedData]);

  walk({
    treeData: resolvedData,
    getNodeKey: ({ node, treeIndex }) => {
      return treeIndex;
    },
    callback: (e) => {
      e.node.TreeIndex = e.treeIndex;
      e.node.ParentId = undefined;
      e.node.Path = e.path;
      return true;
    }
  });
  const intl = useIntl();

  return (
    <>
      {!disableTop && (
        <>
          <div className="d-flex align-items-center mb-2">
            <div
              className="dropdown-container mr-2 ar-checklist-input flex-1  p-0 ssi-control rounded"
              tabIndex="1"
            >
              <label className="d-flex pl-0 align-items-center ar-dropdown-label-search multiple">
                <div
                  style={{ height: 40, color: "#1E73F0" }}
                  className="px-2 d-flex pb-1 align-items-center justify-content-center"
                >
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <div className="flex-1 pb-1 of-hidden w-100">
                  <input
                    autoComplete="off"
                    maxLength="1024"
                    ref={ref}
                    onChange={handleSearchOnChange}
                    placeholder={intl.formatMessage({ id: "SEARCH" })}
                    type="text"
                    name="ar-dropdown"
                    className="ar-dropdown-button w-100"
                  />
                </div>
              </label>
            </div>
            <div className="mr-2" style={{ width: 40 }}>
              <Button
                className="ar-checklist-expandableButtons"
                vType="primary-ghost"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleNodeExpansion(true);
                }}
              >
                <span className="checklist-expand-rotate">
                  <FontAwesomeIcon icon={faExpandAlt} />
                </span>
              </Button>
            </div>
            <div style={{ width: 40 }}>
              <Button
                className="ar-checklist-expandableButtons"
                vType="primary-ghost"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleNodeExpansion(false);
                }}
              >
                <span className="checklist-expand-rotate">
                  <FontAwesomeIcon icon={faCompressAlt} />
                </span>
              </Button>
            </div>
          </div>

          {!preview && (
            <div
              className="dropdown-container ar-checklist-input mb-3 p-0 ssi-control rounded"
              tabIndex="1"
            >
              <label className="d-flex pl-0 align-items-center ar-dropdown-label-search multiple">
                <div
                  style={{ height: 40 }}
                  className="px-2 cursor-pointer pb-1 d-flex plus-sign-svg align-items-center justify-content-center"
                  onClick={handlePlusClick}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <div className="flex-1 pb-1 of-hidden w-100">
                  <input
                    ref={ref}
                    placeholder={intl.formatMessage({ id: "ADD" })}
                    onKeyPress={handleKeyPress}
                    type="text"
                    maxLength="1024"
                    name="ar-dropdown"
                    className="ar-dropdown-button w-100"
                  />
                </div>
              </label>
            </div>
          )}
        </>
      )}
      <div
        className={classnames(containerName, className)}
        style={{
          maxHeight: enableMax ? "100%" : containerName ? "" : 350,
          height: containerName ? "" : 50 * NodeCount + 15,
          visibility: NodeCount === 0 ? "hidden" : "visible"
        }}
      >
        <SortableTree
          getNodeKey={({ node, treeIndex }) => {
            return treeIndex;
          }}
          generateNodeProps={(rowInfo) => {
            const buttons = [
              <button
                style={{ borderRadius: 0 }}
                key={uuidv1()}
                type="button"
                className="ssi-button pl-2 pr-2 ssi-control ssi-button-link text-left"
                onClick={() => {
                  setEditing(rowInfo.treeIndex);
                }}
              >
                <div className="mr-1 ar-form-button-link-icon">
                  <FontAwesomeIcon icon={faPencil} />
                </div>
                <span className="fw-regular fs-14">
                  <FormattedMessage id={"EDIT"} />
                </span>
              </button>,
              <button
                style={{ borderRadius: 0 }}
                key={uuidv1()}
                type="button"
                className="ssi-button pl-2 pr-2 ssi-control ssi-button-link text-left"
                onClick={() => {
                  handleDuplicateSingle(rowInfo);
                }}
              >
                <div className="mr-1 ar-form-button-link-icon">
                  <FontAwesomeIcon icon={faClone} />
                </div>
                <span className="fw-regular fs-14">
                  <FormattedMessage id={"DUPLICATE"} />
                </span>
              </button>,
              <button
                style={{ borderRadius: 0 }}
                key={uuidv1()}
                type="button"
                disabled={rowInfo.node.children.length === 0}
                className="ssi-button pl-2 pr-2 ssi-control ssi-button-link text-left"
                onClick={() => {
                  handleDuplicateAll(rowInfo);
                }}
              >
                <div className="mr-1 ar-form-button-link-icon">
                  <FontAwesomeIcon icon={faClone} />
                </div>
                <span className="fw-regular fs-14">
                  <FormattedMessage id={"DUPLICATE_ALL"} />
                </span>
              </button>,
              <button
                style={{ borderRadius: 0 }}
                key={uuidv1()}
                type="button"
                className="ssi-button ar-logout-button-checklist pl-2 pr-2 ssi-control ssi-button-link text-left"
                onClick={() => {
                  handleNodeAndChildrenDelete(rowInfo);
                }}
              >
                <div className="mr-1 ar-form-button-link-icon">
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
                <span className="fw-regular fs-14">
                  {rowInfo.node.children.length > 0 ? (
                    <FormattedMessage id={"DELETE_ALL"} />
                  ) : (
                    <FormattedMessage id={"DELETE"} />
                  )}
                </span>
              </button>
            ];

            if (optionalItemButtonsConfig) {
              for (const config of optionalItemButtonsConfig) {
                const { onClick, icon, textId } = config;

                buttons.push(
                  <button
                    style={{ borderRadius: 0 }}
                    key={uuidv1()}
                    type="button"
                    className="ssi-button pl-2 pr-2 ssi-control ssi-button-link text-left"
                    onClick={() => {
                      onClick(rowInfo);
                    }}
                  >
                    <div className="mr-1 ar-form-button-link-icon">
                      <FontAwesomeIcon icon={icon} />
                    </div>
                    <span className="fw-regular fs-14">
                      <FormattedMessage id={textId} />
                    </span>
                  </button>
                );
              }
            }

            return {
              checkEnable: canCheck,
              checkStatus: rowInfo.node.Checked,
              CurrentEditingIndex: editing,
              CurrentSelectedIndex: select,
              preview: preview,
              NodeWidth: nodeWidth,
              removeFunction: () => {
                handleNodeAndChildrenDelete(rowInfo);
              },
              handledit: (e) => {
                handleTextEdit(e, rowInfo);
              },
              onCheckChange: (e) => {
                handleCheckboxChanges(e, rowInfo);
              },
              canceledit: () => {
                setEditing(null);
              },
              startSelecting: () => {
                setSelect(rowInfo.treeIndex);
              },
              startEditing: () => {
                setEditing(rowInfo.treeIndex);
              },
              stopEdition: () => {
                setEditing(null);
              },
              buttons
            };
          }}
          scaffoldBlockPxWidth={32}
          rowHeight={50}
          searchQuery={search}
          searchMethod={({ node, searchQuery }) => {
            if (searchQuery.length === 0) return false;
            const resolvedTitle = node.title.toLowerCase();
            let splitSearchQuery = searchQuery
              .toLowerCase()
              .split(" ")
              .filter((e) => e.length > 0);

            for (const iterator of splitSearchQuery) {
              if (resolvedTitle.includes(iterator)) return true;
            }

            return false;
          }}
          nodeContentRenderer={AlternateNodeRendererDefault}
          toggleChildrenVisibility
          treeData={resolvedData}
          canDrag={!preview}
          onChange={(treeData) => checkChange(treeData)}
        />
      </div>
      {(!preview && resolvedData.length > 0) ||
      50 * NodeCount > (containerName || 350) ? (
        <div className="w-100 pt-2 d-flex justify-content-between">
          {!preview && resolvedData.length > 0 ? (
            <div
              className="fs-12  text-danger-alt fw-medium cursor-pointer text-decoration-underline-with-hover"
              onClick={removeAll}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
              <span className="pl-2">
                <FormattedMessage id={"DELETE_ALL"} />
              </span>
            </div>
          ) : (
            <div></div>
          )}
          {!disableMaximise &&
          !defaultMaxHeight &&
          50 * NodeCount > (containerName || 350) ? (
            <div
              className="fs-12  text-primary cursor-pointer fw-medium text-decoration-underline-with-hover"
              onClick={() => {
                setEnableMax(!enableMax);
              }}
            >
              {enableMax ? (
                <>
                  <FontAwesomeIcon icon={faArrowFromBottom} />
                  <span className="pl-2">
                    <FormattedMessage id={"MINIMIZE"} />
                  </span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faArrowFromTop} />
                  <span className="pl-2">
                    <FormattedMessage id={"MAXIMIZE"} />
                  </span>
                </>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default Checklist;
