import React, { useContext } from "react";
import TargetHandles from "../Handles/TargetHandles";
import { faRedoAlt } from "@fortawesome/pro-light-svg-icons";
import SourceHandles from "../Handles/SourceHandles";
import { useSidebar } from "../../../../Components/Sidebar/SidebarV2";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../AutomationDetailsHelper";
import { ElementsRefContext } from "../AutomationDetails";
import NodeBaseSidebar from "./NodeBaseSidebar";
import CounterInput from "../../../../Components/Input/CounterInput";
import FormInput from "../../../../Components/Forms/FormInput/FormInput";
import AutomationTestResults from "./AutomationTestResults";
import NodeBaseContainer from "./NodeExtraOptions/NodeExtraOptions";
import { useIntl } from "react-intl";
import NodeIconNameContainer from "./NodeIconNameContainer";

const FormContainer = ({ formState, id, canEdit }) => {
  const { form, handleChange } = formState;
  const { batches, value } = form;

  const onChange = (newVal) => {
    handleChange({
      batches: newVal
    });
  };

  const handleValueChange = (e) => {
    const { value } = e.target;
    handleChange({
      value: value
    });
  };

  return (
    <div>
      <div className="d-flex w-100 mb-3 align-items-center justify-content-between">
        <div className="text-black fs-16">Batches</div>
        <CounterInput enableInput value={batches} onChange={onChange} />
      </div>
      <FormInput
        onChange={handleValueChange}
        value={value}
        textId="VALUE"
        className="mb-3"
      />
    </div>

    // <BaseSidebarContainer>
    //   <BaseSidebarHeader>Delay</BaseSidebarHeader>
    //   <BaseSidebarContent>
    //     <div className="p-3">
    //       <HourPicker value={value} onChange={handleChange} />

    //       <Button onClick={handleSubmit}>Guardar</Button>
    //     </div>
    //   </BaseSidebarContent>
    //   <NodeBaseSidebar AllElements={elements} CurrentNodeId={id} />
    // </BaseSidebarContainer>
  );
};

const settings = {
  defaultForm: {
    batches: 1,
    value: ""
  },
  FormContent: (props) => {
    return <FormContainer {...props}></FormContainer>;
  }
};

const SplitInBatchesNode = React.memo(({ selected, id, data, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);
  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);
  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };
  const { enabled, config } = data;
  const intl = useIntl();
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <NodeIconNameContainer
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleClick}
          selected={selected}
          icon={faRedoAlt}
          text={intl.formatMessage({ id: "SPLIT_IN_BATCHES" })}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default SplitInBatchesNode;
