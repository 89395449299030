import {
  NodeActionsEnum,
  NodeTypes,
  TriggerTypeEnum
} from "../AutomationDetails/NodeTypes";

export const ClientNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "number",
    propertySample: "1"
  },
  {
    propertyTitle: "Name",
    propertyName: "name",
    propertyType: "string",
    propertySample: "Client Name"
  },
  {
    propertyTitle: "Description",
    propertyName: "description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Status",
    propertyName: "status",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Fiscal Number",
    propertyName: "fiscal_number",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Phone Number",
    propertyName: "phone_number",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Email",
    propertyName: "email",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Domain",
    propertyName: "domain",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyName: "Image",
    propertyType: "string",
    propertyTitle: "Image Url"
  },
  {
    propertyTitle: "Contacts",
    propertyName: "contacts",
    propertyType: "contact id list",
    propertySample: "[contact_id]"
  },
  {
    propertyTitle: "Addresses",
    propertyName: "addresses",
    propertyType: "object",
    children: [
      {
        propertyName: "addresses.0.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "addresses.0.address1",
        propertyTitle: "Address",
        propertyType: "string",
        propertySample: ""
      },
      {
        propertyName: "addresses.0.district",
        propertyTitle: "District",
        propertyType: "string",
        propertySample: ""
      },
      {
        propertyName: "addresses.0.locality",
        propertyTitle: "City",
        propertyType: "string",
        propertySample: ""
      },
      {
        propertyName: "addresses.0.postal_code",
        propertyTitle: "Zip Code",
        propertyType: "string",
        propertySample: ""
      },
      {
        propertyName: "addresses.0.latitude",
        propertyTitle: "Latitude",
        propertyType: "string",
        propertySample: ""
      },
      {
        propertyName: "addresses.0.longitude",
        propertyTitle: "Longitude",
        propertyType: "string",
        propertySample: ""
      }
    ]
  },
  {
    propertyTitle: "Industries List",
    propertyName: "industries",
    propertyType: "industries list",
    children: [
      {
        propertyName: "industries.0.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "industries.0.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Construction"
      }
    ]
  },
  {
    propertyTitle: "Client Type List",
    propertyName: "client_types",
    propertyType: "ClientTypes list",
    children: [
      {
        propertyName: "client_types.0.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "client_types.0.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Partner"
      }
    ]
  },
  {
    propertyTitle: "Manager",
    propertyName: "manager",
    propertyType: "object",
    children: [
      {
        propertyName: "manager.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "manager.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "manager.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Followers",
    propertyName: "followers",
    propertyType: "account list",
    propertySample: "[Account]"
  },
  {
    propertyTitle: "Creation Date",
    propertyName: "creation_date",
    propertyType: "datetimeOffset",
    propertySample: ""
  },
  {
    propertyTitle: "Can Edit",
    propertyName: "can_edit",
    propertyType: "boolean",
    propertySample: ""
  },
  {
    propertyTitle: "Can Delete",
    propertyName: "can_delete",
    propertyType: "boolean",
    propertySample: ""
  },
  {
    propertyTitle: "Creator",
    propertyName: "creator",
    propertyType: "object",
    children: [
      {
        propertyName: "creator.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "creator.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "creator.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  }
];

export const ContactNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "number",
    propertySample: "1"
  },
  {
    propertyTitle: "Name",
    propertyName: "name",
    propertyType: "string",
    propertySample: "Contact Name"
  },
  {
    propertyTitle: "Description",
    propertyName: "description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Status",
    propertyName: "status",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Legal base type for data prossesing",
    propertyName: "gdpr_type",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Fiscal Number",
    propertyName: "fiscal_number",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Phone Number",
    propertyName: "phone_number",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Mobile Number",
    propertyName: "mobile_number",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Email",
    propertyName: "email",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Domain",
    propertyName: "domain",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyName: "Image",
    propertyType: "string",
    propertyTitle: "Image Url"
  },
  {
    propertyTitle: "Clients",
    propertyName: "clients",
    propertyType: "clients list",
    propertySample: "[Client]"
  },

  {
    propertyTitle: "Followers",
    propertyName: "followers",
    propertyType: "account list",
    propertySample: "[Account]"
  },
  {
    propertyTitle: "Creation Date",
    propertyName: "creation_date",
    propertyType: "datetimeOffset",
    propertySample: ""
  },
  {
    propertyTitle: "Can Edit",
    propertyName: "can_edit",
    propertyType: "boolean",
    propertySample: ""
  },
  {
    propertyTitle: "Can Delete",
    propertyName: "can_delete",
    propertyType: "boolean",
    propertySample: ""
  },
  {
    propertyTitle: "Creator",
    propertyName: "creator",
    propertyType: "object",
    children: [
      {
        propertyName: "creator.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "creator.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "creator.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  }
];

export const InterventionNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "number",
    propertySample: "1"
  },
  {
    propertyTitle: "Begin Date",
    propertyName: "begin_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "End Date",
    propertyName: "end_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Inefficient Duration",
    propertyName: "inefficient_duration",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Inefficient Details",
    propertyName: "time_detail",
    propertyType: "object",
    children: [
      {
        propertyName: "time_detail.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "time_detail.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Know How"
      }
    ]
  },
  {
    propertyTitle: "Inefficient Details Description",
    propertyName: "time_detail_description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Deslocation Time",
    propertyName: "deslocation_time",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Location type",
    propertyName: "place_type",
    propertyType: "number",
    propertySample: "1 - Local | 2 - Remote"
  },
  {
    propertyTitle: "Account",
    propertyName: "account",
    propertyType: "object",
    children: [
      {
        propertyName: "account.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "account.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "account.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Client",
    propertyName: "client",
    propertyType: "object",
    children: [
      {
        propertyName: "client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Indirect Client",
    propertyName: "indirect_client",
    propertyType: "object",
    children: [
      {
        propertyName: "indirect_client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "indirect_client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "indirect_client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "indirect_client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Contract",
    propertyName: "contract",
    propertyType: "object",
    children: [
      {
        propertyName: "contract.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "contract.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: ""
      },
      {
        propertyName: "contract.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Deal",
    propertyName: "deal",
    propertyType: "object",
    children: [
      {
        propertyName: "deal.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "deal.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: ""
      },
      {
        propertyName: "deal.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Project",
    propertyName: "project",
    propertyType: "object",
    children: [
      {
        propertyName: "project.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "32"
      },
      {
        propertyName: "project.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Website Development"
      },
      {
        propertyName: "project.status",
        propertyType: "number",
        propertyTitle: "Project Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Ticket",
    propertyName: "ticket",
    propertyType: "object",
    children: [
      {
        propertyName: "ticket.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "ticket.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Web development"
      },
      {
        propertyName: "project.status",
        propertyType: "number",
        propertyTitle: "Status"
      }
    ]
  },
  {
    propertyTitle: "Call",
    propertyName: "call",
    propertyType: "object",
    children: [
      {
        propertyName: "call.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "call.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Web development"
      },
      {
        propertyName: "call.status",
        propertyType: "number",
        propertyTitle: "Status"
      }
    ]
  },
  {
    propertyTitle: "Task",
    propertyName: "task",
    propertyType: "object",
    children: [
      {
        propertyName: "task.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "task.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Web development"
      },
      {
        propertyName: "task.status",
        propertyType: "number",
        propertyTitle: "Status"
      }
    ]
  },
  {
    propertyTitle: "Organization",
    propertyName: "organization",
    propertyType: "object",
    children: [
      {
        propertyName: "organization.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "organization.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Google"
      },
      {
        propertyName: "organization.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  }
];

export const TicketNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "number",
    propertySample: "1"
  },
  {
    propertyTitle: "Name",
    propertyName: "name",
    propertyType: "string",
    propertySample: "Ticket Name"
  },
  {
    propertyTitle: "Description",
    propertyName: "no_tag_description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Followers",
    propertyName: "followers",
    propertyType: "User List",
    propertySample: "[User]"
  },
  {
    propertyTitle: "Entry Date",
    propertyName: "received_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Pipeline Status",
    propertyName: "pipeline_status",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline_status.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "32"
      },
      {
        propertyName: "pipeline_status.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "pipeline_status.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Pipeline",
    propertyName: "pipeline",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "pipeline.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Client",
    propertyName: "client",
    propertyType: "object",
    children: [
      {
        propertyName: "client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Indirect Client",
    propertyName: "indirect_client",
    propertyType: "object",
    children: [
      {
        propertyName: "indirect_client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "indirect_client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "indirect_client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "indirect_client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Company",
    propertyName: "company",
    propertyType: "object",
    children: [
      {
        propertyName: "company.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "company.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "company.image",
        propertyType: "string",
        propertyTitle: "Image Url",
        propertySample: "*"
      }
    ]
  },
  {
    propertyTitle: "Contact",
    propertyName: "contact",
    propertyType: "object",
    children: [
      {
        propertyName: "contact.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "43"
      },
      {
        propertyName: "contact.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Contact Name"
      }
    ]
  },
  {
    propertyTitle: "Manager",
    propertyName: "manager_account",
    propertyType: "object",
    children: [
      {
        propertyName: "manager_account.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "manager_account.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "manager_account.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Project",
    propertyName: "project",
    propertyType: "object",
    children: [
      {
        propertyName: "project.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "project.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Web development"
      },
      {
        propertyName: "project.sales_opportunity",
        propertyType: "string",
        propertyTitle: ""
      },
      {
        propertyName: "project.status",
        propertyType: "number",
        propertyTitle: ""
      }
    ]
  },
  {
    propertyTitle: "Ticket",
    propertyName: "ticket",
    propertyType: "object",
    children: [
      {
        propertyName: "ticket.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "ticket.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Web development"
      },
      {
        propertyName: "project.status",
        propertyType: "number",
        propertyTitle: ""
      }
    ]
  },
  {
    propertyTitle: "Call",
    propertyName: "call",
    propertyType: "object",
    children: [
      {
        propertyName: "call.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "call.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Web development"
      },
      {
        propertyName: "call.status",
        propertyType: "number",
        propertyTitle: ""
      }
    ]
  },
  {
    propertyTitle: "Contract",
    propertyName: "contract",
    propertyType: "object",
    children: [
      {
        propertyName: "contract.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "contract.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: ""
      },
      {
        propertyName: "contract.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Deal",
    propertyName: "deal",
    propertyType: "object",
    children: [
      {
        propertyName: "deal.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "deal.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: ""
      },
      {
        propertyName: "deal.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Creator",
    propertyName: "creator",
    propertyType: "object",
    children: [
      {
        propertyName: "creator.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "creator.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "creator.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyName: "status_detail_description",
    propertyType: "string",
    propertyTitle: "Cancelation Reason",
    propertySample: ""
  }
];

export const ClassificationNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "number",
    propertySample: "1"
  },
  {
    propertyTitle: "Billing identifier",
    propertyName: "identifier",
    propertyType: "string",
    propertySample: "FT-1234"
  },
  {
    propertyTitle: "Status",
    propertyName: "status",
    propertyType: "int",
    propertySample: `ForBilling = 2, 
		Billed = 3, 
		UnderContract = 4, 
		UnderClient = 5, 
		UnderProject = 6, 
		UnderWarranty = 7, 
		UnderDeal = 8, 
		Inefficient = 9 `
  },
  {
    propertyTitle: "Internal Description",
    propertyName: "internal_description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Classified Timecharges",
    propertyName: "time_charges",
    propertyType: "list of timecharge object",
    propertySample: "[Timecharge]"
  },
  {
    propertyTitle: "Timecharge",
    propertyName: "time_charge",
    propertyType: "object",
    children: [
      {
        propertyName: "id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "estimated_cost",
        propertyType: "int",
        propertyTitle: "Estimated Cost (ms)",
        propertySample: ""
      },
      {
        propertyName: "inputted_duration",
        propertyType: "int",
        propertyTitle: "Inputted Duration (ms)",
        propertySample: ""
      },
      {
        propertyName: "deslocation_time",
        propertyType: "int",
        propertyTitle: "Deslocation Time (ms)",
        propertySample: ""
      },
      {
        propertyName: "inefficient_duration",
        propertyType: "int",
        propertyTitle: "Inefficient Time (ms)",
        propertySample: ""
      }
    ]
  },
  {
    propertyTitle: "Company",
    propertyName: "company",
    propertyType: "object",
    children: [
      {
        propertyName: "company.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "company.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "company.image",
        propertyType: "string",
        propertyTitle: "Image Url",
        propertySample: "*"
      }
    ]
  },
  {
    propertyTitle: "Project",
    propertyName: "project",
    propertyType: "object",
    children: [
      {
        propertyName: "project.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "32"
      },
      {
        propertyName: "project.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Website Development"
      },
      {
        propertyName: "project.status",
        propertyType: "number",
        propertyTitle: "Project Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Contract",
    propertyName: "contract",
    propertyType: "object",
    children: [
      {
        propertyName: "contract.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "contract.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: ""
      },
      {
        propertyName: "contract.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Deal",
    propertyName: "deal",
    propertyType: "object",
    children: [
      {
        propertyName: "deal.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "deal.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: ""
      },
      {
        propertyName: "deal.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Client",
    propertyName: "client",
    propertyType: "object",
    children: [
      {
        propertyName: "client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Indirect Client",
    propertyName: "indirect_client",
    propertyType: "object",
    children: [
      {
        propertyName: "indirect_client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "indirect_client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "indirect_client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "indirect_client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Creator",
    propertyName: "creator",
    propertyType: "object",
    children: [
      {
        propertyName: "creator.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "creator.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "creator.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Creation Date",
    propertyName: "creation_date",
    propertyType: "datetime",
    propertySample: "2021-03-15T11:37:00"
  }
];

export const TaskNodeGrouppedExpressionDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Count",
    propertyName: "count",
    propertyType: "number",
    propertySample: "1"
  },
  {
    propertyTitle: "Data",
    propertyName: "data",
    propertyType: "Tasks List",
    propertySample: "[Task]"
  }
];

export const TaskNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "number",
    propertySample: "1"
  },
  {
    propertyTitle: "Name",
    propertyName: "name",
    propertyType: "string",
    propertySample: "Task Name"
  },
  {
    propertyTitle: "Begin Date",
    propertyName: "begin_date",
    propertyType: "datetime",
    propertySample: "2021-03-19T11:37:00"
  },
  {
    propertyTitle: "End Date",
    propertyName: "end_date",
    propertyType: "datetime",
    propertySample: "2021-03-22T11:37:00"
  },
  {
    propertyTitle: "Creation Date",
    propertyName: "creation_date",
    propertyType: "datetime",
    propertySample: "2021-03-15T11:37:00"
  },
  {
    propertyTitle: "Internal Description",
    propertyName: "no_tag_internal_description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Estimated Cost",
    propertyName: "estimated_cost",
    propertyType: "number",
    propertySample: "3600000"
  },
  {
    propertyTitle: "Company",
    propertyName: "company",
    propertyType: "object",
    children: [
      {
        propertyName: "company.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "company.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "company.image",
        propertyType: "string",
        propertyTitle: "Image Url",
        propertySample: "*"
      }
    ]
  },
  {
    propertyTitle: "Contact",
    propertyName: "contact",
    propertyType: "object",
    children: [
      {
        propertyName: "contact.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "43"
      },
      {
        propertyName: "contact.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Contact Name"
      }
    ]
  },
  {
    propertyTitle: "Pipeline",
    propertyName: "pipeline",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "pipeline.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Pipeline Status",
    propertyName: "pipeline_status",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline_status.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "32"
      },
      {
        propertyName: "pipeline_status.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "pipeline_status.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Manager",
    propertyName: "manager",
    propertyType: "object",
    children: [
      {
        propertyName: "manager.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "32"
      },
      {
        propertyName: "manager.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Smith"
      },
      {
        propertyName: "manager.image",
        propertyType: "string",
        propertyTitle: "Image Url",
        propertySample: "*"
      }
    ]
  },
  {
    propertyTitle: "Creator",
    propertyName: "creator",
    propertyType: "object",
    children: [
      {
        propertyName: "creator.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "32"
      },
      {
        propertyName: "creator.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Smith"
      },
      {
        propertyName: "creator.image",
        propertyType: "string",
        propertyTitle: "Image Url",
        propertySample: "*"
      }
    ]
  },
  {
    propertyTitle: "Project",
    propertyName: "project",
    propertyType: "object",
    children: [
      {
        propertyName: "project.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "32"
      },
      {
        propertyName: "project.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Website Development"
      },
      {
        propertyName: "project.status",
        propertyType: "number",
        propertyTitle: "Project Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Indirect Client",
    propertyName: "indirect_client",
    propertyType: "object",
    children: [
      {
        propertyName: "indirect_client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "32"
      },
      {
        propertyName: "indirect_client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Microsoft"
      },
      {
        propertyName: "indirect_client.image",
        propertyType: "string",
        propertyTitle: "Indirect Client Image Url",
        propertySample: "*"
      },
      {
        propertyName: "indirect_client.status",
        propertyType: "number",
        propertyTitle: "Indirect Client Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "ToDos Count",
    propertyName: "to_dos_count",
    propertyType: "number",
    propertySample: "1"
  },
  {
    propertyTitle: "Can Edit",
    propertyName: "can_edit",
    propertyType: "boolean",
    propertySample: "true"
  },
  {
    propertyTitle: "Can Delete",
    propertyName: "can_delete",
    propertyType: "boolean",
    propertySample: "false"
  }
];

export const ContractNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "number",
    propertySample: "1"
  },
  {
    propertyTitle: "Name",
    propertyName: "name",
    propertyType: "string",
    propertySample: "Contract Name"
  },
  {
    propertyTitle: "Description",
    propertyName: "no_tag_description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Begin Date",
    propertyName: "begin_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "End Date",
    propertyName: "end_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Contract Type",
    propertyName: "contract_type",
    propertyType: "number"
  },
  {
    propertyTitle: "Creation Date",
    propertyName: "creation_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Next Renovation",
    propertyName: "next_renovation",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Sales Opportunity",
    propertyName: "sales_opportunity",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Deslocations",
    propertyName: "deslocations",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Period Time",
    propertyName: "period_time",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Estimated Cost",
    propertyName: "estimated_cost",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Minimum Interval",
    propertyName: "interval_minimum",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Rounding Margin",
    propertyName: "rounding_margin",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Spent Hours in ms",
    propertyName: "spent_hours",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Hours after the end of the contract",
    propertyName: "right_duration_not_in_range",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Hours before the start of the contract",
    propertyName: "left_duration_not_in_range",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Estimated Costs",
    propertyName: "estimated_costs",
    propertyType: "list",
    propertySample: "[{cost, currency_id, order}]",
    children: [
      {
        propertyName: "estimated_costs.cost",
        propertyTitle: "Cost",
        propertyType: "number",
        propertySample: "20"
      },
      {
        propertyName: "estimated_costs.currency_id",
        propertyType: "number",
        propertyTitle: "Currency Id",
        propertySample: ""
      },
      {
        propertyName: "estimated_costs.order",
        propertyType: "number",
        propertyTitle: "Order",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Estimated Costs per hour",
    propertyName: "estimated_costs_hour",
    propertyType: "list",
    propertySample: "[{cost, currency_id, order}]",
    children: [
      {
        propertyName: "estimated_costs.cost",
        propertyTitle: "Cost",
        propertyType: "number",
        propertySample: "20"
      },
      {
        propertyName: "estimated_costs.currency_id",
        propertyType: "number",
        propertyTitle: "Currency Id",
        propertySample: ""
      },
      {
        propertyName: "estimated_costs.order",
        propertyType: "number",
        propertyTitle: "Order",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Pipeline",
    propertyName: "pipeline",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "pipeline.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Pipeline Status",
    propertyName: "pipeline_status",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline_status.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "pipeline_status.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Client",
    propertyName: "client",
    propertyType: "object",
    children: [
      {
        propertyName: "client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Indirect Client",
    propertyName: "indirect_client",
    propertyType: "object",
    children: [
      {
        propertyName: "indirect_client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "indirect_client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "indirect_client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "indirect_client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Schedules",
    propertyName: "schedules",
    propertyType: "object list",
    children: [
      {
        propertyName: "schedules.0.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "schedules.0.start_time",
        propertyType: "number",
        propertyTitle: "Start Time"
      },
      {
        propertyName: "schedules.0.end_time",
        propertyType: "number",
        propertyTitle: "End Time"
      },
      {
        propertyName: "schedules.0.days",
        propertyType: "number list",
        propertyTitle: "Days",
        propertySample: "[1,2,3]"
      }
    ]
  },
  {
    propertyTitle: "Contact",
    propertyName: "contact",
    propertyType: "object",
    children: [
      {
        propertyName: "contact.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "43"
      },
      {
        propertyName: "contact.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Contact Name"
      }
    ]
  },
  {
    propertyTitle: "Deal",
    propertyName: "deal",
    propertyType: "object",
    children: [
      {
        propertyName: "deal.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "deal.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: ""
      },
      {
        propertyName: "deal.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Company",
    propertyName: "company",
    propertyType: "object",
    children: [
      {
        propertyName: "company.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "company.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Google"
      },
      {
        propertyName: "company.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Department",
    propertyName: "department",
    propertyType: "object",
    children: [
      {
        propertyName: "department.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "department.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "QA Dept"
      },
      {
        propertyName: "department.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Collaborators",
    propertyName: "collaborators",
    propertyType: "object",
    children: [
      {
        propertyName: "collaborators.accounts",
        propertyTitle: "Accounts",
        propertyType: "list",
        propertySample: "[Account]"
      },
      {
        propertyName: "collaborators.teams",
        propertyType: "list",
        propertyTitle: "Teams",
        propertySample: "[Team]"
      }
    ]
  },
  {
    propertyTitle: "Manager",
    propertyName: "manager_account",
    propertyType: "object",
    children: [
      {
        propertyName: "manager.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "manager.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "manager.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Creator",
    propertyName: "creator",
    propertyType: "object",
    children: [
      {
        propertyName: "creator.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "creator.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "creator.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  }
];

export const ConversationDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "guid",
    propertySample: ""
  },
  {
    propertyTitle: "Subject",
    propertyName: "subject",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Status",
    propertyName: "status",
    propertyType: "int",
    propertySample: "1 = open | 2 = closed"
  }
];

export const CallNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Name",
    propertyName: "name",
    propertyType: "string",
    propertySample: "Call Name"
  },
  {
    propertyTitle: "Description",
    propertyName: "no_tag_description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Received Date",
    propertyName: "received_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Can Edit",
    propertyName: "can_edit",
    propertyType: "boolean",
    propertySample: ""
  },
  {
    propertyTitle: "Can Delete",
    propertyName: "can_delete",
    propertyType: "boolean",
    propertySample: ""
  },
  {
    propertyTitle: "Company",
    propertyName: "company",
    propertyType: "object",
    children: [
      {
        propertyName: "company.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "company.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Google"
      },
      {
        propertyName: "company.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Contact",
    propertyName: "contact",
    propertyType: "object",
    children: [
      {
        propertyName: "contact.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "43"
      },
      {
        propertyName: "contact.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Contact Name"
      }
    ]
  },
  {
    propertyTitle: "Pipeline Status",
    propertyName: "pipeline_status",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline_status.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "pipeline_status.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Project",
    propertyName: "project",
    propertyType: "object",
    children: [
      {
        propertyName: "project.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "32"
      },
      {
        propertyName: "project.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Website Development"
      },
      {
        propertyName: "project.status",
        propertyType: "number",
        propertyTitle: "Project Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Contract",
    propertyName: "contract",
    propertyType: "object",
    children: [
      {
        propertyName: "contract.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "contract.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: ""
      },
      {
        propertyName: "contract.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Ticket",
    propertyName: "ticket",
    propertyType: "object",
    children: [
      {
        propertyName: "ticket.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "ticket.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Web development"
      },
      {
        propertyName: "project.status",
        propertyType: "number",
        propertyTitle: ""
      }
    ]
  },
  {
    propertyTitle: "Client",
    propertyName: "client",
    propertyType: "object",
    children: [
      {
        propertyName: "client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Indirect Client",
    propertyName: "indirect_client",
    propertyType: "object",
    children: [
      {
        propertyName: "indirect_client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "32"
      },
      {
        propertyName: "indirect_client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Microsoft"
      },
      {
        propertyName: "indirect_client.image",
        propertyType: "string",
        propertyTitle: "Indirect Client Image Url",
        propertySample: "*"
      },
      {
        propertyName: "indirect_client.status",
        propertyType: "number",
        propertyTitle: "Indirect Client Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Manager",
    propertyName: "manager_account",
    propertyType: "object",
    children: [
      {
        propertyName: "manager.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "manager.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "manager.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Creator",
    propertyName: "creator",
    propertyType: "object",
    children: [
      {
        propertyName: "creator.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "creator.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "creator.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  }
];

export const DealNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Name",
    propertyName: "name",
    propertyType: "string",
    propertySample: "Deal Name"
  },
  {
    propertyTitle: "Description",
    propertyName: "no_tag_description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Begin Date",
    propertyName: "begin_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "End Date",
    propertyName: "end_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Creation Date",
    propertyName: "creation_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Sales Opportunity",
    propertyName: "sales_opportunity",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Estimated Cost",
    propertyName: "estimated_cost",
    propertyType: "object",
    children: [
      {
        propertyName: "estimated_cost.cost",
        propertyTitle: "Cost",
        propertyType: "number",
        propertySample: "20"
      },
      {
        propertyName: "estimated_cost.currency_id",
        propertyType: "number",
        propertyTitle: "Currency Id",
        propertySample: ""
      },
      {
        propertyName: "estimated_cost.order",
        propertyType: "number",
        propertyTitle: "Order",
        propertySample: ""
      }
    ]
  },
  {
    propertyTitle: "Estimated Costs",
    propertyName: "estimated_costs",
    propertyType: "list",
    propertySample: "[{cost, currency_id, order}]",
    children: [
      {
        propertyName: "estimated_costs.cost",
        propertyTitle: "Cost",
        propertyType: "number",
        propertySample: "20"
      },
      {
        propertyName: "estimated_costs.currency_id",
        propertyType: "number",
        propertyTitle: "Currency Id",
        propertySample: ""
      },
      {
        propertyName: "estimated_costs.order",
        propertyType: "number",
        propertyTitle: "Order",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Pipeline",
    propertyName: "pipeline",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "pipeline.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Pipeline Status",
    propertyName: "pipeline_status",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline_status.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "pipeline_status.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Client",
    propertyName: "client",
    propertyType: "object",
    children: [
      {
        propertyName: "client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Contact",
    propertyName: "contact",
    propertyType: "object",
    children: [
      {
        propertyName: "contact.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "43"
      },
      {
        propertyName: "contact.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Contact Name"
      }
    ]
  },
  {
    propertyTitle: "Company",
    propertyName: "company",
    propertyType: "object",
    children: [
      {
        propertyName: "company.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "company.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Google"
      },
      {
        propertyName: "company.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Department",
    propertyName: "department",
    propertyType: "object",
    children: [
      {
        propertyName: "department.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "department.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "QA Dept"
      },
      {
        propertyName: "department.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Collaborators",
    propertyName: "collaborators",
    propertyType: "object",
    children: [
      {
        propertyName: "collaborators.accounts",
        propertyTitle: "Accounts",
        propertyType: "list",
        propertySample: "[Account]"
      },
      {
        propertyName: "collaborators.teams",
        propertyType: "list",
        propertyTitle: "Teams",
        propertySample: "[Team]"
      }
    ]
  },
  {
    propertyTitle: "Manager",
    propertyName: "manager_account",
    propertyType: "object",
    children: [
      {
        propertyName: "manager.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "manager.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "manager.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Assigned To",
    propertyName: "assigned",
    propertyType: "object",
    children: [
      {
        propertyName: "assigned.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "assigned.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "assigned.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Creator",
    propertyName: "creator",
    propertyType: "object",
    children: [
      {
        propertyName: "creator.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "creator.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "creator.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  }
];

export const SubscriptionNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "number",
    propertySample: ""
  },
  {
    propertyTitle: "Name",
    propertyName: "name",
    propertyType: "string",
    propertySample: "Subscription Name"
  },
  {
    propertyTitle: "Description",
    propertyName: "no_tag_description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Internal Description",
    propertyName: "no_tag_internal_description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Begin Date",
    propertyName: "begin_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "End Date",
    propertyName: "end_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Subscription Type",
    propertyName: "subscription_type",
    propertyType: "number"
  },
  {
    propertyTitle: "Creation Date",
    propertyName: "creation_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Next Renovation",
    propertyName: "next_renovation",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Sales Opportunity",
    propertyName: "sales_opportunity",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Pipeline",
    propertyName: "pipeline",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "pipeline.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Pipeline Status",
    propertyName: "pipeline_status",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline_status.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "pipeline_status.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Client",
    propertyName: "client",
    propertyType: "object",
    children: [
      {
        propertyName: "client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Contact",
    propertyName: "contact",
    propertyType: "object",
    children: [
      {
        propertyName: "contact.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "43"
      },
      {
        propertyName: "contact.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Contact Name"
      }
    ]
  },
  {
    propertyTitle: "Company",
    propertyName: "company",
    propertyType: "object",
    children: [
      {
        propertyName: "company.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "company.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Google"
      },
      {
        propertyName: "company.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Department",
    propertyName: "department",
    propertyType: "object",
    children: [
      {
        propertyName: "department.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "department.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "QA Dept"
      },
      {
        propertyName: "department.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Collaborators",
    propertyName: "collaborators",
    propertyType: "object",
    children: [
      {
        propertyName: "collaborators.accounts",
        propertyTitle: "Accounts",
        propertyType: "list",
        propertySample: "[Account]"
      },
      {
        propertyName: "collaborators.teams",
        propertyType: "list",
        propertyTitle: "Teams",
        propertySample: "[Team]"
      }
    ]
  },
  {
    propertyTitle: "Manager",
    propertyName: "manager_account",
    propertyType: "object",
    children: [
      {
        propertyName: "manager.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "manager.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "manager.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Creator",
    propertyName: "creator",
    propertyType: "object",
    children: [
      {
        propertyName: "creator.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "creator.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "creator.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  }
];

export const ProjectNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "number",
    propertySample: "1"
  },
  {
    propertyTitle: "Name",
    propertyName: "name",
    propertyType: "string",
    propertySample: "Project Name"
  },
  {
    propertyTitle: "Description",
    propertyName: "no_tag_description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Internal Description",
    propertyName: "no_tag_internal_description",
    propertyType: "string",
    propertySample: ""
  },
  {
    propertyTitle: "Name",
    propertyName: "name",
    propertyType: "string",
    propertySample: "Project Name"
  },
  {
    propertyTitle: "Id",
    propertyName: "id",
    propertyType: "number",
    propertySample: "1"
  },
  {
    propertyTitle: "Entry Date",
    propertyName: "received_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Begin Date",
    propertyName: "begin_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "End Date",
    propertyName: "end_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Creation Date",
    propertyName: "creation_date",
    propertyType: "datetime",
    propertySample: "2018-07-17T23:00:00"
  },
  {
    propertyTitle: "Estimated Hour Cost (ms)",
    propertyName: "estimated_cost",
    propertyType: "number",
    propertySample: "36000000"
  },
  {
    propertyTitle: "Spent Hours (ms)",
    propertyName: "spent_hours",
    propertyType: "number",
    propertySample: "36000000"
  },
  {
    propertyTitle: "Followers",
    propertyName: "followers",
    propertyType: "User List",
    propertySample: "[User]"
  },
  {
    propertyTitle: "Pipeline",
    propertyName: "pipeline",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "pipeline.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Pipeline Status",
    propertyName: "pipeline_status",
    propertyType: "object",
    children: [
      {
        propertyName: "pipeline_status.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "pipeline_status.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      }
    ]
  },
  {
    propertyTitle: "Client",
    propertyName: "client",
    propertyType: "object",
    children: [
      {
        propertyName: "client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Indirect Client",
    propertyName: "indirect_client",
    propertyType: "object",
    children: [
      {
        propertyName: "indirect_client.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "indirect_client.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Open"
      },
      {
        propertyName: "indirect_client.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      },
      {
        propertyName: "indirect_client.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Deal",
    propertyName: "deal",
    propertyType: "object",
    children: [
      {
        propertyName: "deal.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "deal.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: ""
      },
      {
        propertyName: "deal.status",
        propertyType: "number",
        propertyTitle: "Status",
        propertySample: "1"
      }
    ]
  },
  {
    propertyTitle: "Manager",
    propertyName: "manager_account",
    propertyType: "object",
    children: [
      {
        propertyName: "manager.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "manager.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "manager.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Creator",
    propertyName: "creator",
    propertyType: "object",
    children: [
      {
        propertyName: "creator.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "creator.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "John Silver"
      },
      {
        propertyName: "creator.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  },
  {
    propertyTitle: "Company",
    propertyName: "company",
    propertyType: "object",
    children: [
      {
        propertyName: "company.id",
        propertyTitle: "Id",
        propertyType: "number",
        propertySample: "1"
      },
      {
        propertyName: "company.name",
        propertyType: "string",
        propertyTitle: "Name",
        propertySample: "Google"
      },
      {
        propertyName: "company.image",
        propertyType: "string",
        propertyTitle: "Image Url"
      }
    ]
  }
];

export const IfElseNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyName: "result",
    propertyTitle: "Result",
    propertyType: "boolean",
    propertySample: "true"
  }
];

export const EmailNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyName: "subject",
    propertyTitle: "Subject",
    propertyType: "string",
    propertySample: "Email Test"
  }
];

export const ExportAssociatedEmailsExpressionDict: NodeExpressionItem[] = [
  {
    propertyName: "is_completed",
    propertyTitle: "Is Completed",
    propertyType: "boolean",
    propertySample: "false"
  },
  {
    propertyName: "is_cancelled",
    propertyTitle: "Is Cancelled",
    propertyType: "boolean",
    propertySample: "false"
  },
  {
    propertyName: "key",
    propertyTitle: "Key",
    propertyType: "guid",
    propertySample: "xxxxx-xxxxx-xxxxxx-xxxxxx"
  }
];

export const GetGeneralExportItemDict: NodeExpressionItem[] = [
  {
    propertyName: "url",
    propertyTitle: "URL",
    propertyType: "string",
    propertySample: "https://...."
  }
];

export const TimeReportNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyName: "subject",
    propertyTitle: "Subject",
    propertyType: "string",
    propertySample: "Report Test"
  }
];

export const SplitInBatchesNodeExpressionDict: NodeExpressionItem[] = [
  {
    propertyName: "value",
    propertyTitle: "Value",
    propertyType: "string"
  },
  {
    propertyName: "context",
    propertyTitle: "Context",
    propertyType: "object",
    children: [
      {
        propertyName: "context.has_more",
        propertyTitle: "Has more",
        propertyType: "boolean",
        propertySample: "true"
      },
      {
        propertyName: "context.current_iteration",
        propertyTitle: "Current Iteration",
        propertyType: "number",
        propertySample: "1"
      }
    ]
  }
];

export const DelayExpressionDict: NodeExpressionItem[] = [
  {
    propertyName: "delay",
    propertyTitle: "Delay",
    propertyType: "number",
    propertySample: "1000"
  }
];

export const WebhookExpression: NodeExpressionItem[] = [
  {
    propertyName: "",
    propertyTitle: "Webhook Data",
    propertyType: "any",
    propertySample: ""
  }
];

export const TriggerManualExpressionDict: NodeExpressionItem[] = [
  {
    propertyName: "",
    propertyTitle: "Trigger Data",
    propertyType: "any",
    propertySample: ""
  }
];

export const TriggerCronExpressionDict: NodeExpressionItem[] = [
  {
    propertyName: "targetDate",
    propertyTitle: "Trigger Start Date Time",
    propertyType: "date",
    propertySample: ""
  }
];
export interface NodeExpressionItem {
  propertyName: string;
  propertyTitle: string;
  propertyType: string;
  propertySample?: string;
  children?: NodeExpressionItem[];
}

interface NewNode extends Node {
  data?: any;
}

interface NodeTypeBaseConfig {
  baseId: string;
  propertiesTitle?: string;
}
interface NodeTypeConfigFunction extends NodeTypeBaseConfig {
  getOwnProperties: (node: Node) => NodeExpressionItem[];
}
interface NodeTypeConfigBasic extends NodeTypeBaseConfig {
  ownProperties: NodeExpressionItem[];
  groupedProperties?: NodeExpressionItem[];
}

type NodeTypeConfig = NodeTypeConfigFunction | NodeTypeConfigBasic;

export const NodeTypeConfigDict: Record<NodeTypes, NodeTypeConfig> = {
  [NodeActionsEnum.httpStatusRequest]: {
    baseId: "HTTP Status Request",
    ownProperties: [
      {
        propertyName: "headers",
        propertyTitle: "Headers",
        propertyType: "json",
        propertySample: ""
      },
      {
        propertyName: "status",
        propertyTitle: "Status",
        propertyType: "number",
        propertySample: ""
      }
    ]
  },
  [NodeActionsEnum.conversationCreation]: {
    baseId: "Create Email Conversation",
    ownProperties: EmailNodeExpressionDict
  },
  [NodeActionsEnum.exportEmailsConversations]: {
    baseId: "Export Email Conversation",
    ownProperties: ExportAssociatedEmailsExpressionDict
  },
  [NodeActionsEnum.getExportFileById]: {
    baseId: "Get General Export File",
    ownProperties: GetGeneralExportItemDict
  },
  [NodeActionsEnum.getClient]: {
    baseId: "Get Client",
    propertiesTitle: "Client",
    ownProperties: ClientNodeExpressionDict,
    groupedProperties: TaskNodeGrouppedExpressionDict
  },
  [NodeActionsEnum.timeReports]: {
    baseId: "Times Report",
    ownProperties: TimeReportNodeExpressionDict
  },
  [NodeActionsEnum.getContact]: {
    baseId: "Get Contact",
    propertiesTitle: "Contact",
    ownProperties: ContactNodeExpressionDict,
    groupedProperties: TaskNodeGrouppedExpressionDict
  },
  [NodeActionsEnum.getTask]: {
    baseId: "Get Task",
    propertiesTitle: "Task",
    ownProperties: TaskNodeExpressionDict,
    groupedProperties: TaskNodeGrouppedExpressionDict
  },
  [NodeActionsEnum.getContract]: {
    baseId: "Get Contract",
    propertiesTitle: "Contract",
    ownProperties: ContractNodeExpressionDict,
    groupedProperties: TaskNodeGrouppedExpressionDict
  },
  [NodeActionsEnum.getCall]: {
    baseId: "Get Call",
    propertiesTitle: "Call",
    ownProperties: CallNodeExpressionDict,
    groupedProperties: TaskNodeGrouppedExpressionDict
  },
  [NodeActionsEnum.getTicketConversations]: {
    baseId: "Get Ticket Conversations",
    propertiesTitle: "TicketConversations",
    ownProperties: ConversationDict
  },
  [NodeActionsEnum.getDeal]: {
    baseId: "Get Deal",
    propertiesTitle: "Deal",
    ownProperties: DealNodeExpressionDict,
    groupedProperties: TaskNodeGrouppedExpressionDict
  },
  [NodeActionsEnum.getSubscription]: {
    baseId: "Get Subscription",
    propertiesTitle: "Subscription",
    ownProperties: SubscriptionNodeExpressionDict,
    groupedProperties: TaskNodeGrouppedExpressionDict
  },
  [NodeActionsEnum.getProject]: {
    baseId: "Get Project",
    propertiesTitle: "Project",
    ownProperties: ProjectNodeExpressionDict,
    groupedProperties: TaskNodeGrouppedExpressionDict
  },
  [NodeActionsEnum.getTicket]: {
    baseId: "Get Ticket",
    propertiesTitle: "Ticket",
    ownProperties: TicketNodeExpressionDict,
    groupedProperties: TaskNodeGrouppedExpressionDict
  },
  [NodeActionsEnum.ticketCreation]: {
    baseId: "Ticket Creation",
    propertiesTitle: "Ticket",
    ownProperties: TicketNodeExpressionDict
  },
  [NodeActionsEnum.projectCreation]: {
    baseId: "Project Creation",
    propertiesTitle: "Project",
    ownProperties: ProjectNodeExpressionDict
  },
  [TriggerTypeEnum.trigger_ticket_creation]: {
    baseId: "Ticket Trigger",
    propertiesTitle: "Ticket",
    ownProperties: TicketNodeExpressionDict
  },
  [TriggerTypeEnum.trigger_classification_creation]: {
    baseId: "Classification Trigger",
    propertiesTitle: "Classification",
    ownProperties: ClassificationNodeExpressionDict
  },
  [TriggerTypeEnum.trigger_contract_creation]: {
    baseId: "Contract Trigger",
    propertiesTitle: "Contract",
    ownProperties: ContractNodeExpressionDict
  },
  [TriggerTypeEnum.trigger_cron]: {
    baseId: "Cron Trigger",
    propertiesTitle: "Cron",
    ownProperties: TriggerCronExpressionDict
  },
  [TriggerTypeEnum.trigger_webhook]: {
    baseId: "Trigger Webhook",
    propertiesTitle: "Trigger Webhook",
    ownProperties: [
      {
        propertyName: "",
        propertyTitle: "Trigger Webhook",
        propertyType: "any",
        propertySample: ""
      }
    ]
  },
  [TriggerTypeEnum.trigger_manual]: {
    baseId: "Trigger Manual",
    ownProperties: TriggerManualExpressionDict
  },
  [NodeActionsEnum.condition_if]: {
    baseId: "If Else",
    ownProperties: IfElseNodeExpressionDict
  },
  [NodeActionsEnum.emailCreation]: {
    baseId: "Email Creation",
    ownProperties: EmailNodeExpressionDict
  },
  [NodeActionsEnum.callCreation]: {
    baseId: "Call Creation",
    ownProperties: CallNodeExpressionDict
  },
  [NodeActionsEnum.connectWorkflow]: {
    baseId: "Connect Workflow",
    ownProperties: []
  },
  [NodeActionsEnum.splitInBatches]: {
    baseId: "Split in Batches",
    ownProperties: SplitInBatchesNodeExpressionDict
  },
  [NodeActionsEnum.propertySetter]: {
    baseId: "Property Setter",
    getOwnProperties: (node: NewNode) => {
      const rItems: NodeExpressionItem[] = [];
      for (const item of node?.data?.form?.properties || []) {
        if (item.name) {
          rItems.push({
            propertyName: item.name,
            propertyTitle: item.name,
            propertyType: item.type
          });
        }
      }
      return rItems;
    }
  },
  [NodeActionsEnum.doNothing]: {
    baseId: "Do Nothing",
    ownProperties: []
  },
  [NodeActionsEnum.delay]: {
    baseId: "Delay",
    ownProperties: DelayExpressionDict
    // getOwnProperties: (node: Node) => {
    // 	const rItems:NodeExpressionItem[]  = []
    // 	for (const item of node.data?.form?.properties) {
    // 		rItems.push({
    // 			propertyName: item.name,
    // 			propertyTitle: item.name,
    // 			propertyType: item.type,
    // 		})
    // 	}
    // 	return rItems;
    // }
  },
  [NodeActionsEnum.taskCreation]: {
    baseId: "Task Creation",
    propertiesTitle: "Task",
    ownProperties: TaskNodeExpressionDict
    // getOwnProperties: (node: Node) => {
    // 	const rItems:NodeExpressionItem[]  = []
    // 	for (const item of node.data?.form?.properties) {
    // 		rItems.push({
    // 			propertyName: item.name,
    // 			propertyTitle: item.name,
    // 			propertyType: item.type,
    // 		})
    // 	}
    // 	return rItems;
    // }
  },
  [NodeActionsEnum.dealCreation]: {
    baseId: "Deal Creation",
    propertiesTitle: "Deal",
    ownProperties: DealNodeExpressionDict
    // getOwnProperties: (node: Node) => {
    // 	const rItems:NodeExpressionItem[]  = []
    // 	for (const item of node.data?.form?.properties) {
    // 		rItems.push({
    // 			propertyName: item.name,
    // 			propertyTitle: item.name,
    // 			propertyType: item.type,
    // 		})
    // 	}
    // 	return rItems;
    // }
  },
  [NodeActionsEnum.contractCreation]: {
    baseId: "Contract Creation",
    propertiesTitle: "Contract",
    ownProperties: ContractNodeExpressionDict
    // getOwnProperties: (node: Node) => {
    // 	const rItems:NodeExpressionItem[]  = []
    // 	for (const item of node.data?.form?.properties) {
    // 		rItems.push({
    // 			propertyName: item.name,
    // 			propertyTitle: item.name,
    // 			propertyType: item.type,
    // 		})
    // 	}
    // 	return rItems;
    // }
  },
  [TriggerTypeEnum.trigger_client_creation]: {
    baseId: "Client Trigger",
    propertiesTitle: "Client",
    ownProperties: ClientNodeExpressionDict
  },
  [TriggerTypeEnum.trigger_contact_creation]: {
    baseId: "Contact Trigger",
    propertiesTitle: "Contact",
    ownProperties: ContactNodeExpressionDict
  },
  [TriggerTypeEnum.trigger_deal_creation]: {
    baseId: "Deal Trigger",
    propertiesTitle: "Deal",
    ownProperties: DealNodeExpressionDict
  },
  [TriggerTypeEnum.trigger_subscription_creation]: {
    baseId: "Subscription Trigger",
    propertiesTitle: "Subscription",
    ownProperties: SubscriptionNodeExpressionDict
  },
  [TriggerTypeEnum.trigger_task_creation]: {
    baseId: "Task Trigger",
    propertiesTitle: "Task",
    ownProperties: TaskNodeExpressionDict
  },
  [TriggerTypeEnum.trigger_project_creation]: {
    baseId: "Project Trigger",
    propertiesTitle: "Project",
    ownProperties: ProjectNodeExpressionDict
  },
  [TriggerTypeEnum.trigger_time_creation]: {
    baseId: "Time Trigger",
    propertiesTitle: "Time",
    ownProperties: InterventionNodeExpressionDict
  },
  [TriggerTypeEnum.trigger_call_creation]: {
    baseId: "Call Trigger",
    propertiesTitle: "Call",
    ownProperties: CallNodeExpressionDict
  },
  [NodeActionsEnum.subscriptionCreation]: {
    baseId: "Subscription Creation",
    propertiesTitle: "Subscription",
    ownProperties: SubscriptionNodeExpressionDict
  },
  [NodeActionsEnum.clientCreation]: {
    baseId: "Client Creation",
    propertiesTitle: "Client",
    ownProperties: ClientNodeExpressionDict
  },
  [NodeActionsEnum.contactCreation]: {
    baseId: "Contact Creation",
    propertiesTitle: "Contact",
    ownProperties: ContactNodeExpressionDict
  },
  [NodeActionsEnum.httpRequest]: {
    baseId: "HTTP Request",
    propertiesTitle: "HTTP Request",
    ownProperties: [
      {
        propertyName: "",
        propertyTitle: "HTTP Request",
        propertyType: "any",
        propertySample: ""
      }
    ]
  }
};
