/* eslint-disable no-unused-vars */
import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
  useRef
} from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { getFilesAsync } from "../../../Files/Files";
import { useEmailConversation, useEmail } from "../../../../Hooks/EntityHooks";
import { useCurrentAccount } from "../../../../Contexts/UserContext";
import {
  useSpacePostQuery,
  useSpaceMultiplePost,
  useSpaceQuery,
  useSpacePost,
  getEntity
} from "../../../../Helpers/IOClient";
import {
  spaceSignatureAggregationSchema,
  spaceEmailSchema
} from "../../../../config/schema";
import { useConnectionId } from "../../../../Containers/RealTime/RealTime";
import {
  findVal,
  handleErrorWithIntl,
  handleError,
  createFormData
} from "../../../../Helpers/MiscHelper";
import { useToast } from "../../../Toast/ToastProvider";
import { EmailPreviewFormContext } from "./MessageSender";
import ClipboardProvider from "../../../ClipbordProvider/ClipboardProvider";
import {
  ContactDropdown,
  EmailSignaturesTypesDropdown
} from "../../../../Containers/AdvancedMultiInputs";
import Button from "../../../Button/Button";
import Input from "../../../Input/Input";
import DocTyper from "../../../DocTyper/DocTyper";
import classnames from "classnames";
import MessageSenderFiles from "./MessageSenderFiles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { UpploadReact } from "../../../FileUploader/Upploader";
import FormInput from "../../../Forms/FormInput/FormInput";
import LoadableButton from "../../../Button/LoadableButton";
import { connect } from "react-redux";
import { MessageCountEmailConversation } from "../../../../Redux/Reducer/Entities";

const EmailSenderItem = ({ title, children }) => {
  return (
    <div
      className="d-flex align-items-center of-hidden mb-2"
      style={{ maxHeight: 95 }}
    >
      <div className="text-black fs-14" style={{ width: 100 }}>
        {title}
      </div>
      <div className="d-flex flex-1 h-100 of-hidden" style={{ maxHeight: 95 }}>
        {children}
      </div>
    </div>
  );
};

const calculateSize = (fd) => {
  let size = 0;

  for (const e of fd.entries()) {
    size += new Blob([e[0]]).size;
    size += new Blob([e[1]]).size;
  }
  return size;
};

const EmailMessageInput = React.forwardRef((props, ref) => {
  const {
    disabled,
    entityId,
    schema,
    messages,
    onChangeReplyMessageId,
    addMessage,
    updateMessageCounter,
    formMinimized,
    onFocus,
    setFormMinimized,
    roomType
  } = props;
  // const dragging = useDragDropEffect();
  const intl = useIntl();
  // const [dragging, setDragging] = useState(false);

  // const handleKeyDown = (e) => {
  //   //enter
  //   if (e.keyCode === 13 && !e.shiftKey) {
  //     //Stops enter from creating a new line
  //     e.preventDefault();
  //     handleSubmit()
  //   }
  // };

  const [files, setFiles] = useState([]);

  const handleDrop = async (e) => {
    // setDragging(false);
    e.preventDefault();
    const files = e.dataTransfer
      ? await getFilesAsync(e.dataTransfer)
      : e.currentTarget.files;
    setFiles((f) => [...f, ...files]);
    // setDragging(false);
  };

  // const [dragging, setDragging] = useState(false);

  // const handlePaste = (e) => {
  //   setFiles((f) => [...f, ...e]);
  // };

  const emailConversation = useEmailConversation(entityId);
  const user = useCurrentAccount();
  const {
    LastEmail,
    Status,
    Id: ConversationId,
    Client
  } = emailConversation || {};
  // ;
  const { FromContact, ToContacts, CcContacts, Subject, Account } =
    useEmail(LastEmail) || {};
  // console.log(FromContact, ToContacts, CcContacts);

  const [textKey, setTextKey] = useState(1);

  const [text, setText] = useState("");

  const [signature, setSignature] = useState(null);

  const preferenceBody = useMemo(() => {
    return { Key: `recent-signature-${user.Id}-${ConversationId}` };
  }, [ConversationId, user.Id]);

  const [preferenceSignature, setPreferenceSignature] = useState(null);

  useSpacePostQuery(`Preference`, preferenceBody, null, {
    cache: false,
    onSuccess: ({ data }) => {
      if (data !== "NO_ITEMS" && data !== "" && data !== null) {
        const resolvedData = JSON.parse(data);
        setPreferenceSignature(resolvedData);
      }
    },
    onError: ({ error }) => {
      // console.log(error);
    }
  });

  const preferencePost = useSpaceMultiplePost();

  const preferenceSignatureQueryUrl = useMemo(() => {
    const { data } = preferenceSignature || {};
    if (data) {
      return `Query/EmailSignatures?id=${data}`;
    } else return null;
  }, [preferenceSignature]);

  useSpaceQuery(preferenceSignatureQueryUrl, spaceSignatureAggregationSchema, {
    onSuccess: ({ data }) => {
      if (data !== null && data !== "NO_ITEMS" && data !== "")
        setSignature(preferenceSignature);
    }
  });

  const [resovledFirstEmail, setResovledFirstEmail] = useState(() => {
    if (Account === "00000000-0000-0000-0000-000000000001") {
      const firstEmail = messages[0]?.Email;
      if (!firstEmail) return null;
      const resolvedFirstEmail = getEntity(spaceEmailSchema, firstEmail);
      const newFirstEmail = { ...resolvedFirstEmail };
      newFirstEmail.ToContacts = [resolvedFirstEmail.FromContact];
      return newFirstEmail;
    }
    return null;
  });

  const [to, setTo] = useState(() => {
    if (
      Account === "00000000-0000-0000-0000-000000000001" &&
      resovledFirstEmail
    ) {
      return resovledFirstEmail.ToContacts;
    }
    if (Account && ToContacts && ToContacts.length !== 0) return ToContacts;
    else if (FromContact) return [FromContact];
    else return [];
  });

  const [receiveNotfi, setReceiveNotfi] = useState(false);
  const [cc, setCc] = useState(() => {
    if (
      Account === "00000000-0000-0000-0000-000000000001" &&
      resovledFirstEmail
    ) {
      return resovledFirstEmail.CcContacts || [];
    } else return CcContacts || [];
  });
  const [bcc, setBcc] = useState([]);
  const [subject, setSubject] = useState(() => {
    if (Subject && !Subject.toLowerCase().startsWith("re:"))
      return `RE: ${Subject}`;
    return Subject || "";
  });

  const alredyRan = useRef(false);
  useEffect(() => {
    if (!alredyRan.current && LastEmail !== undefined) {
      if (
        Account === "00000000-0000-0000-0000-000000000001" &&
        resovledFirstEmail
      ) {
        setTo(resovledFirstEmail.ToContacts);
      }

      if (Account && ToContacts && ToContacts.length !== 0) setTo(ToContacts);
      else if (FromContact) setTo([FromContact]);

      if (
        Account === "00000000-0000-0000-0000-000000000001" &&
        resovledFirstEmail
      ) {
        setCc(resovledFirstEmail.CcContacts || []);
      } else setCc(CcContacts || []);

      if (Subject && !Subject.toLowerCase().startsWith("re:"))
        setSubject(`RE: ${Subject}`);
      else setSubject(Subject || "");

      alredyRan.current = true;
    }
  }, [
    Account,
    CcContacts,
    FromContact,
    LastEmail,
    Subject,
    ToContacts,
    resovledFirstEmail
  ]);

  const conId = useConnectionId();
  // const signatureObj = useEmailSignatureChoice(signature)
  const [post, { loading }] = useSpacePost(
    `EmailConversation/${entityId}/Reply`,
    null,
    {
      onSuccess: () => {
        // addComment(data);
        // onClose();
        // shouldFocusRef.current = true;
        onChangeReplyMessageId();
        // addMessage({ Email: data });

        // updateCommentSchemas(schema, entityId, data);
        setText("");
        setFiles([]);
        setFormMinimized(true);

        updateMessageCounter(entityId);
        // // const normalized = normalize(
        // //   { ...EmailConversation, LastEmail: data },
        // //   spaceEmailConversationSchema
        // // );

        // const update = {
        //   [spaceEmailConversationSchema.name]: {
        //     [entityId]: { LastEmail: data }
        //   }
        // };

        createToast({
          pos: "tm",
          type: "success",
          // description: intl.formatMessage({ id: "CREATED_SUCCEFULLY" }),
          title: intl.formatMessage({ id: "TEST_EMAIL_SEND_SUCCESS" })
        });

        // updateNormalizedSchemaCache(update);

        preferencePost("Preference/Set", {
          Key: `recent-signature-${user.Id}-${ConversationId}`,
          Value: JSON.stringify(signature)
        });

        setTextKey(textKey + 1);
      },
      onError: ({ error }) => {
        let msgKey = findVal(error, "Message");
        if (msgKey === "API_EMAIL_SUBJECT_INVALID") {
          handleErrorWithIntl(createToast, "API_EMAIL_SUBJECT_INVALID");
        } else handleError(createToast, error);
      },
      headers: {
        ConnectionID: conId
      }
    }
  );
  const createToast = useToast();
  const handleSubmit = useCallback(
    (e) => {
      e && e.preventDefault();

      if (Status && Status === 1) {
        createToast({
          pos: "tm",
          type: "danger",
          description: (
            <FormattedMessage id={"CANT_REPLY_CLOSED_CONVERSATION"} />
          ),
          title: `Erro`
        });
        return;
      }

      if (text === "") return;
      let resolvedSignature = undefined;
      if (signature) {
        const { type, data } = signature;
        const connectedSignature = getEntity(
          spaceSignatureAggregationSchema,
          data
        );
        const { Company, Department, Team, SignatureId } =
          connectedSignature || {};
        let OrganizationId = undefined;
        switch (type) {
          case "Company":
            OrganizationId = Company;
            break;
          case "Department":
            OrganizationId = Department;
            break;
          case "Team":
            OrganizationId = Team;
            break;
          default:
            break;
        }

        resolvedSignature = {
          OrganizationId,
          Id: SignatureId
        };
      }

      const data = createFormData(
        {
          Text: text,
          Subject: subject,
          To: JSON.stringify(to),
          Cc: JSON.stringify(cc),
          Bcc: JSON.stringify(bcc),
          Signature: resolvedSignature
            ? JSON.stringify(resolvedSignature)
            : undefined
        },
        files
      );

      const globalEmailSize = calculateSize(data);
      if (globalEmailSize > 5000000) {
        createToast({
          pos: "tm",
          type: "danger",
          description: <FormattedMessage id={"EMAIL_SIZE_TOO_BIG"} />,
          title: `Erro`
        });
        return;
      } else post(data);
    },
    [Status, bcc, cc, createToast, files, post, signature, subject, text, to]
  );

  const HandleExtraUploads = (f) => {
    setFiles((e) => [...e, f]);
  };

  const handleToChange = (v) => {
    setTo(v);
  };

  const handleCcChange = (v) => {
    setCc(v);
  };

  const handlebccChange = (v) => {
    setBcc(v);
  };

  const handleReceiveNotif = (v) => {
    setReceiveNotfi(v);
  };

  const handleApplyToUserPick = (e) => {
    const { value } = e.target;
    setSignature(value);
  };

  const [showCC, setShowCC] = useState(() => {
    if (cc.length > 0) return true;
    else return false;
  });

  useEffect(() => {
    if (cc.length > 0 && !showCC) setShowCC(true);
  }, [cc, showCC]);

  const [showBCC, setShowBCC] = useState(false);
  const FormRef = useContext(EmailPreviewFormContext);

  useEffect(() => {
    const formdata = {
      text,
      subject,
      to,
      cc,
      bcc,
      signature
    };
    FormRef.current = formdata;
  }, [FormRef, bcc, cc, signature, subject, text, to]);

  return (
    <ClipboardProvider>
      <div className="d-flex flex-column h-100">
        {!formMinimized && (
          <div className="mt-3">
            <EmailSenderItem title={intl.formatMessage({ id: "EMAIL_TO" })}>
              <div className="align-items-center  d-flex flex-1 of-hidden w-100">
                <div className="flex-1 h-100 of-hidden w-100">
                  <ContactDropdown
                    grid
                    multiple
                    labelClassName="h-100"
                    containerClassName="h-100"
                    className="h-100"
                    value={to}
                    onChange={handleToChange}
                    hasEmail
                    IndirectClient={Client}
                    EmailConversationId={ConversationId}
                  />
                </div>
                {!showCC && (
                  <Button
                    vType="link-primary"
                    className="ml-2"
                    onClick={() => setShowCC(true)}
                  >
                    CC
                  </Button>
                )}
                {!showBCC && (
                  <Button
                    vType="link-primary"
                    className="ml-2"
                    onClick={() => setShowBCC(true)}
                  >
                    BCC
                  </Button>
                )}
              </div>
            </EmailSenderItem>
            {showCC && (
              <EmailSenderItem title={"Cc"}>
                <div className="align-items-center  d-flex flex-1 of-hidden w-100">
                  <div className="flex-1 h-100 of-hidden w-100">
                    <ContactDropdown
                      grid
                      multiple
                      labelClassName="h-100"
                      containerClassName="h-100"
                      className="h-100"
                      value={cc}
                      onChange={handleCcChange}
                      hasEmail
                      IndirectClient={Client}
                      EmailConversationId={ConversationId}
                    />
                  </div>
                </div>
              </EmailSenderItem>
            )}

            {showBCC && (
              <EmailSenderItem title={"Bcc"}>
                <div className="align-items-center  d-flex flex-1 of-hidden w-100">
                  <div className="flex-1 h-100 of-hidden w-100">
                    <ContactDropdown
                      grid
                      labelClassName="h-100"
                      containerClassName="h-100"
                      className="h-100"
                      multiple
                      value={bcc}
                      onChange={handlebccChange}
                      hasEmail
                      IndirectClient={Client}
                      EmailConversationId={ConversationId}
                    />
                  </div>
                </div>
              </EmailSenderItem>
            )}

            <EmailSenderItem title={intl.formatMessage({ id: "SUBJECT" })}>
              <Input
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </EmailSenderItem>

            {/* <EmailSenderItem title={" "}>
              <div className="d-flex align-items-center" style={{ height: 24 }}>
                <Checkbox
                  onChange={(e) => handleReceiveNotif(e.target.checked)}
                  checked={receiveNotfi}
                >
                  <span className="mr-3 text-black fs-14">
                    <FormattedMessage id="EMAIL_CONVERSATION_EMAIL_PERSONAL_RESPONSE" />
                  </span>
                </Checkbox>
              </div>
            </EmailSenderItem> */}
          </div>
        )}
        <DocTyper
          noToolbar={formMinimized}
          key={textKey}
          // config={{
          //   autoGrow_minHeight: 20,
          //   autoGrow_maxHeight: 150
          // }}
          // autoGrow
          // onDragEnter={() => setDragging(true)}
          // onDragLeave={(e) => {
          //   const nodes = Array.from(
          //     document.getElementsByClassName("remove-overflow-from-editor")
          //   );
          //   if (!nodes[0].contains(e.target)) setDragging(false);
          // }}
          // onDrop={handleDrop}
          //  onPasteEvent={handlePaste}
          DisabledmaximizeButton={true}
          ref={ref}
          onFocus={onFocus}
          // onKeyDown={handleKeyDown}
          disabled={disabled}
          //  placeholder={intl.formatMessage({ id: "WRITE_YOUR_EMAIL" })}
          // className={classnames("mt-3 flex-1 disable-heights-for-editor")}
          className={classnames("mt-3 flex-1")}
          // disabled={loading}
          value={text}
          onChange={setText}
          // onDragOver={() => setDragging(true)}
          // onDragLeave={() => setDragging(false)}
          onDrop={handleDrop}
        />
        {/* <HTMLEditor
        noToolbar={formMinimized}
        config={{
          autoGrow_minHeight: 20,
          autoGrow_maxHeight: 150
        }}
        autoGrow
        onDragEnter={() => setDragging(true)}
        onDragLeave={() => setDragging(false)}
        onDrop={handleDrop}
        onPasteEvent={handlePaste}
        DisabledmaximizeButton={true}
        ref={ref}
        onFocus={onFocus}
        // onKeyDown={handleKeyDown}
        disabled={disabled}
        placeholder={intl.formatMessage({ id: "WRITE_YOUR_EMAIL" })}
        className={"mt-3"}
        // disabled={loading}
        value={text}
        onChange={setText}
      /> */}
        {files && files.length > 0 && (
          <div
            style={{ maxHeight: 96 }}
            className="mb-1 mt-2 flex-0-0-auto of-y-auto of-x-hidden"
          >
            <MessageSenderFiles files={files} setFiles={setFiles} />
          </div>
        )}
        <div className="d-flex align-items-center justify-content-between mt-2">
          <div className="d-flex align-items-center flex-1">
            <div className="d-flex align-items-center">
              <label className="ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button">
                <FontAwesomeIcon icon={faPaperclip} />

                <input
                  className="ar-fileUploader__inputFile"
                  // accept={accept && accept.join(",")}
                  type="file"
                  multiple
                  onChange={handleDrop}
                />
              </label>
              <div className="">
                <UpploadReact onFileDone={HandleExtraUploads} Chat={true} />
              </div>
            </div>
            <span className="fs-14 mr-2">
              <FormattedMessage id="SIGNATURE" />:
            </span>
            <FormInput
              value={signature}
              onChange={handleApplyToUserPick}
              className="mr-2 flex-1"
              inputType={EmailSignaturesTypesDropdown}
              name="ReplySignatureModel"
              type={2}
            />
          </div>
          <LoadableButton
            isLoading={loading}
            disabled={text === "" || to.length === 0}
            className="float-right"
            onClick={handleSubmit}
          >
            <FormattedMessage id="REPLY" />
            <FontAwesomeIcon className="ml-2" icon={faPaperPlane} />
          </LoadableButton>
        </div>
      </div>
    </ClipboardProvider>
  );
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateMessageCounter: (emailCovnersationId) => {
      dispatch(MessageCountEmailConversation(emailCovnersationId));
    }
  };
};

export default connect(null, mapDispatchToProps)(EmailMessageInput);
