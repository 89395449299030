import React from "react";
import {
  useProject,
  useCall,
  useTask,
  useTicket,
  useContract,
  useClient,
  useAccount,
  useDeal,
  useContact,
  useSubscription,
  useEmailConversation
} from "../../../../Hooks/EntityHooks";
import {
  GeneralNotificationItem,
  buildNotificationEndpoint,
  NotificationClientProfile
} from "./NotificationHelper";
import { FormattedMessage } from "react-intl";
import TaskOrigin from "../../../Origin/TaskOrigin";
import CallOrigin from "../../../Origin/CallOrigin";
import TicketOrigin from "../../../Origin/TicketOrigin";
import ContractOrigin from "../../../Origin/ContractOrigin";
import ClientOrigin from "../../../Origin/ClientOrigin";
import DealOrigin from "../../../Origin/DealOrigin";
import ProjectOrigin from "../../../Origin/ProjectOrigin";
import ContactOrigin from "../../../Origin/ContactOrigin";
import SubscriptionOrigin from "../../../Origin/SubscriptionOrigin";
import InterventionOrigin from "../../../Origin/InterventionOrigin";
import { moduleTypes } from "../../../../Helpers/ModulesHelper";
import { MessageItemProfileStatus } from "../../../Reception/ReceptionCompMsgList";

const NotificationCreationProject = ({ project, notf }) => {
  const resolvedProject = project;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  const { Client, IndirectClient } = resolvedProject;
  return (
    <GeneralNotificationItem
      redirectUrl={buildNotificationEndpoint(
        "projects",
        "Project/Id",
        project.Id
      )}
      formOptions={{
        formType: moduleTypes.projects,
        Id: project.Id
      }}
      OriginComponent={<ProjectOrigin project={project} />}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"PROJECT_CREATED"} />{" "}
          <b>{resolvedProject.Name}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationCreationCall = ({ call, notf }) => {
  const resolvedCall = call;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  const { Client, IndirectClient } = resolvedCall;
  return (
    <GeneralNotificationItem
      redirectUrl={buildNotificationEndpoint("calls", "Call/Id", call.Id)}
      formOptions={{
        formType: moduleTypes.calls,
        Id: call.Id
      }}
      OriginComponent={<CallOrigin call={call} />}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"CALL_CREATED"} />{" "}
          <b>{resolvedCall.NoTagDescription}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

export const NotificationUserReplyConversationTask = ({ value }) => {
  const { FromAccount, EmailConversation } = value;
  const { Name: FromName } = FromAccount;

  const { Subject, Id, Client, Status } = EmailConversation;

  return (
    <GeneralNotificationItem
      OriginComponent={
        <div className="d-flex align-items-center of-hidden ">
          <MessageItemProfileStatus
            size="xs"
            style={{ width: 24, height: 24 }}
            Status={Status}
          />
          <div className="flex-1 text-truncate ml-2 disable-selection fs-12 text-black">
            {Subject}
          </div>
        </div>
      }
      redirectUrl={buildNotificationEndpoint(
        "inbox/1",
        null,
        Id,
        `conversationTypes=1&entityNoteType=0`
      )}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"CONVERSATION_REPLIED"} /> <b>{Subject}</b>
          <br />
          <NotificationClientProfile client={Client} />
        </div>

        // <div className="text-black ar-double-line-elipsis fs-14">
        // 	<FormattedMessage id={"EMAIL_CONVERSATION_CREATED"} />{" "}
        // 	<b>{Subject}</b>
        // 	<br />
        // 	<NotificationClientProfile client={Client} />
        // </div>
      }
    />

    // <GeneralNotificationItem
    //  OriginComponent={
    //     <div className="d-flex align-items-center of-hidden ">
    //       <MessageItemProfileStatus
    //         size="xs"
    //         style={{ width: 24, height: 24 }}
    //         Status={Status}
    //       />
    //       <div className="flex-1 text-truncate ml-2 disable-selection fs-12 text-black">
    //         {Subject}
    //       </div>
    //     </div>
    //   redirectUrl={buildNotificationEndpoint("tasks", "Task/Id", task.Id)}
    //   body={
    //     <div className="text-black ar-double-line-elipsis fs-14">
    //       <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
    //       <FormattedMessage id={"TASK_CREATED"} /> <b>{resolvedTask.Name}</b>
    //       <br />
    //       <NotificationClientProfile client={Client || IndirectClient} />
    //     </div>
    //   }
    // />
  );
};

export const NotificationNewFromOutsideEmailConversation = ({ value }) => {
  const { EmailConversation } = value;
  // const { Name: FromName } = FromAccount;

  const { Subject, Id, Client, Status } = EmailConversation;

  return (
    <GeneralNotificationItem
      redirectUrl={buildNotificationEndpoint(
        "inbox/1",
        null,
        Id,
        `conversationTypes=1&entityNoteType=0`
      )}
      OriginComponent={
        <div className="d-flex align-items-center of-hidden ">
          <MessageItemProfileStatus
            size="xs"
            style={{ width: 24, height: 24 }}
            Status={Status}
          />
          <div className="flex-1 text-truncate ml-2 disable-selection fs-12 text-black">
            {Subject}
          </div>
        </div>
      }
      // OriginComponent={<CallOrigin call={call} />}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage
            id={"EMAIL_CONVERSATION_NEW_EMAIL"}
            values={{ EmailSub: <b>{Subject}</b> }}
          />
          <NotificationClientProfile client={Client} />
        </div>
      }
    />
  );
};

export const NotificationUserParticipationEmailConversation = ({ value }) => {
  const { FromAccount, EmailConversation } = value;
  const { Name: FromName } = FromAccount;

  const { Subject, Id, Client, Status } = EmailConversation;

  return (
    <GeneralNotificationItem
      OriginComponent={
        <div className="d-flex align-items-center of-hidden ">
          <MessageItemProfileStatus
            size="xs"
            style={{ width: 24, height: 24 }}
            Status={Status}
          />
          <div className="flex-1 text-truncate ml-2 disable-selection fs-12 text-black">
            {Subject}
          </div>
        </div>
      }
      redirectUrl={buildNotificationEndpoint(
        "inbox/1",
        null,
        Id,
        `conversationTypes=1`
      )}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage
            id={"CONVERSATION_REPLIED_PARTICIPATION"}
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              subject: Subject
            }}
          />
          <br />
          <NotificationClientProfile client={Client} />
        </div>

        // <div className="text-black ar-double-line-elipsis fs-14">
        // 	<FormattedMessage id={"EMAIL_CONVERSATION_CREATED"} />{" "}
        // 	<b>{Subject}</b>
        // 	<br />
        // 	<NotificationClientProfile client={Client} />
        // </div>
      }
    />

    // <GeneralNotificationItem
    //  OriginComponent={
    //     <div className="d-flex align-items-center of-hidden ">
    //       <MessageItemProfileStatus
    //         size="xs"
    //         style={{ width: 24, height: 24 }}
    //         Status={Status}
    //       />
    //       <div className="flex-1 text-truncate ml-2 disable-selection fs-12 text-black">
    //         {Subject}
    //       </div>
    //     </div>
    //   redirectUrl={buildNotificationEndpoint("tasks", "Task/Id", task.Id)}
    //   body={
    //     <div className="text-black ar-double-line-elipsis fs-14">
    //       <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
    //       <FormattedMessage id={"TASK_CREATED"} /> <b>{resolvedTask.Name}</b>
    //       <br />
    //       <NotificationClientProfile client={Client || IndirectClient} />
    //     </div>
    //   }
    // />
  );
};

export const NotificationUserParticipationEmailConversationFromOutside = ({
  value
}) => {
  const { FromAccount, EmailConversation } = value;
  const { Name: FromName } = FromAccount;

  const { Subject, Id, Client, Status } = EmailConversation;

  return (
    <GeneralNotificationItem
      OriginComponent={
        <div className="d-flex align-items-center of-hidden ">
          <MessageItemProfileStatus
            size="xs"
            style={{ width: 24, height: 24 }}
            Status={Status}
          />
          <div className="flex-1 text-truncate ml-2 disable-selection fs-12 text-black">
            {Subject}
          </div>
        </div>
      }
      redirectUrl={buildNotificationEndpoint(
        "inbox/1",
        null,
        Id,
        `conversationTypes=1&entityNoteType=0`
      )}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage
            id={"NOTIFICATION_EMAIL_CONVERSATION_PARTICIPATION_FROM_OUTSIDE"}
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              subject: Subject
            }}
          />
          <br />
          <NotificationClientProfile client={Client} />
        </div>

        // <div className="text-black ar-double-line-elipsis fs-14">
        // 	<FormattedMessage id={"EMAIL_CONVERSATION_CREATED"} />{" "}
        // 	<b>{Subject}</b>
        // 	<br />
        // 	<NotificationClientProfile client={Client} />
        // </div>
      }
    />
  );
};

export const NotificationUserInternalNoteReplyConversation = ({ value }) => {
  const { FromAccount, EmailConversation } = value;
  const { Name: FromName } = FromAccount;

  const { Subject, Id, Client, Status } = EmailConversation;

  return (
    <GeneralNotificationItem
      OriginComponent={
        <div className="d-flex align-items-center of-hidden ">
          <MessageItemProfileStatus
            size="xs"
            style={{ width: 24, height: 24 }}
            Status={Status}
          />
          <div className="flex-1 text-truncate ml-2 disable-selection fs-12 text-black">
            {Subject}
          </div>
        </div>
      }
      redirectUrl={buildNotificationEndpoint(
        "inbox/1",
        null,
        Id,
        `conversationTypes=1&entityNoteType=0`
      )}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"CONVERSATION_NOTE_CREATED"} /> <b>{Subject}</b>
          <br />
          <NotificationClientProfile client={Client} />
        </div>

        // <div className="text-black ar-double-line-elipsis fs-14">
        // 	<FormattedMessage id={"EMAIL_CONVERSATION_CREATED"} />{" "}
        // 	<b>{Subject}</b>
        // 	<br />
        // 	<NotificationClientProfile client={Client} />
        // </div>
      }
    />

    // <GeneralNotificationItem
    //  OriginComponent={
    //     <div className="d-flex align-items-center of-hidden ">
    //       <MessageItemProfileStatus
    //         size="xs"
    //         style={{ width: 24, height: 24 }}
    //         Status={Status}
    //       />
    //       <div className="flex-1 text-truncate ml-2 disable-selection fs-12 text-black">
    //         {Subject}
    //       </div>
    //     </div>
    //   redirectUrl={buildNotificationEndpoint("tasks", "Task/Id", task.Id)}
    //   body={
    //     <div className="text-black ar-double-line-elipsis fs-14">
    //       <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
    //       <FormattedMessage id={"TASK_CREATED"} /> <b>{resolvedTask.Name}</b>
    //       <br />
    //       <NotificationClientProfile client={Client || IndirectClient} />
    //     </div>
    //   }
    // />
  );
};

const NotificationCreationTask = ({ task, notf }) => {
  const resolvedTask = task;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  const { Client, IndirectClient } = resolvedTask;

  return (
    <GeneralNotificationItem
      OriginComponent={<TaskOrigin task={task} />}
      formOptions={{
        formType: moduleTypes.tasks,
        Id: task.Id
      }}
      redirectUrl={buildNotificationEndpoint("tasks", "Task/Id", task.Id)}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"TASK_CREATED"} /> <b>{resolvedTask.Name}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationCreationEmailConversation = ({ emailConversation, notf }) => {
  const { Client, Subject, Status, Id } = emailConversation;

  return (
    <GeneralNotificationItem
      redirectUrl={buildNotificationEndpoint(
        "inbox/1",
        null,
        Id,
        `conversationTypes=1&entityNoteType=0`
      )}
      OriginComponent={
        <div className="d-flex align-items-center of-hidden ">
          <MessageItemProfileStatus
            size="xs"
            style={{ width: 24, height: 24 }}
            Status={Status}
          />
          <div className="flex-1 text-truncate ml-2 disable-selection fs-12 text-black">
            {Subject}
          </div>
        </div>
      }
      // redirectUrl={buildNotificationEndpoint("tasks", "Task/Id", task.Id)}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"EMAIL_CONVERSATION_CREATED"} />{" "}
          <b>{Subject}</b>
          <br />
          <NotificationClientProfile client={Client} />
        </div>
      }
    />
  );
};

const NotificationCreationTicket = ({ ticket, notf }) => {
  const resolvedTicket = ticket;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  const { Client, IndirectClient } = resolvedTicket;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.tickets,
        Id: ticket.Id
      }}
      OriginComponent={<TicketOrigin ticket={ticket} />}
      redirectUrl={buildNotificationEndpoint("tickets", "Ticket/Id", ticket.Id)}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"CASE_CREATED"} /> <b>{resolvedTicket.Name}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationCreationTimecharge = ({ timecharge, notf }) => {
  const resolvedTimecharge = timecharge;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  const { Client, IndirectClient } = resolvedTimecharge;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.interventions,
        Id: timecharge.Id
      }}
      OriginComponent={<InterventionOrigin intervention={resolvedTimecharge} />}
      redirectUrl={buildNotificationEndpoint(
        "interventions",
        "TimeCharge/Id",
        resolvedTimecharge.Id
      )}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"TIMECHARGE_CREATED"} />{" "}
          <b>{resolvedTimecharge.NoTagDescription}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationCreationContract = ({ contract, notf }) => {
  const resolvedContract = contract;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  const { Client, IndirectClient } = resolvedContract;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.contracts,
        Id: contract.Id
      }}
      OriginComponent={<ContractOrigin contract={contract} />}
      redirectUrl={buildNotificationEndpoint(
        "contracts",
        "Contract/Id",
        contract.Id
      )}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"CONTRACT_CREATED"} />{" "}
          <b>{resolvedContract.NoTagDescription}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationCreationClient = ({ client, notf }) => {
  const resolvedClient = client;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.clients,
        Id: client.Id
      }}
      OriginComponent={<ClientOrigin client={client} />}
      redirectUrl={buildNotificationEndpoint("customers", "Id", client.Id)}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"ACCOUNT_CREATED"} />{" "}
          <b>{resolvedClient.Name}</b>
        </div>
      }
    />
  );
};
const NotificationCreationSubscription = ({ subscription, notf }) => {
  const resolvedClient = subscription;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.subscriptions,
        Id: subscription.Id
      }}
      OriginComponent={<SubscriptionOrigin Subscription={subscription} />}
      redirectUrl={buildNotificationEndpoint(
        "subscriptions",
        "Id",
        subscription.Id
      )}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"SUBSCRIPTION_CREATED"} />{" "}
          <b>{resolvedClient.NoTagDescription}</b>
        </div>
      }
    />
  );
};
const NotificationCreationContact = ({ contact, notf }) => {
  const resolvedClient = contact;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.contacts,
        Id: contact.Id
      }}
      OriginComponent={<ContactOrigin contact={contact} />}
      redirectUrl={buildNotificationEndpoint("contacts", "Id", contact.Id)}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"CONTACT_CREATED"} />{" "}
          <b>{resolvedClient.Name}</b>
        </div>
      }
    />
  );
};

const NotificationCreationDeal = ({ deal, notf }) => {
  const resolvedDeal = deal;

  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;

  const { Client, IndirectClient } = resolvedDeal;
  return (
    <GeneralNotificationItem
      formOptions={{
        formType: moduleTypes.deals,
        Id: deal.Id
      }}
      OriginComponent={<DealOrigin deal={deal} />}
      redirectUrl={buildNotificationEndpoint("deals", "Deal/Id", deal.Id)}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={"DEAL_CREATED"} /> <b>{resolvedDeal.Name}</b>
          <br />
          <NotificationClientProfile client={Client || IndirectClient} />
        </div>
      }
    />
  );
};

const NotificationCreationItem = ({ value }) => {
  const {
    Project,
    Call,
    Task,
    Ticket,
    Contract,
    Client,
    Deal,
    Contact,
    TimeCharge,
    EmailConversation,
    Subscription
  } = value;

  if (Project)
    return <NotificationCreationProject notf={value} project={Project} />;
  else if (EmailConversation)
    return (
      <NotificationCreationEmailConversation
        emailConversation={EmailConversation}
        notf={value}
      />
    );
  else if (Call) return <NotificationCreationCall notf={value} call={Call} />;
  else if (Task) return <NotificationCreationTask notf={value} task={Task} />;
  else if (Contact)
    return <NotificationCreationContact notf={value} contact={Contact} />;
  else if (Subscription)
    return (
      <NotificationCreationSubscription
        notf={value}
        subscription={Subscription}
      />
    );
  else if (TimeCharge)
    return (
      <NotificationCreationTimecharge notf={value} timecharge={TimeCharge} />
    );
  else if (Ticket)
    return <NotificationCreationTicket notf={value} ticket={Ticket} />;
  else if (Contract)
    return <NotificationCreationContract notf={value} contract={Contract} />;
  else if (Client)
    return <NotificationCreationClient notf={value} client={Client} />;
  else if (Deal) return <NotificationCreationDeal notf={value} deal={Deal} />;
  else return null;
};

export default NotificationCreationItem;
