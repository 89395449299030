export enum ViewsEnum {
  basic = "basic",
  advanced = "advanced"
}
export interface Account {
  Id: string;
  Name: string;
  Image: string;
  HasOneSignal: boolean;
  Status: any;
  AccountSpaces: number[];
  AdvancedLayout: ViewsEnum;
  Email: string;
  IsDeveloper: boolean;
  IsSupport: boolean;
}

export enum AccountSpacePermissionsEnum {
  Default = 0,
  ReadContacts = 1,
  ViewDealFinancialData = 2,
  ViewContractFinancialData = 4,
  ViewSubcriptionFinancialData = 8
}

export interface AccountSpace {
  Id: number;
  IsResponsibleInAnyDepartment: boolean;
  Modules: { [moduleType: string]: number };
  Role: number;
  Space: number;
  SpacePermissions: AccountSpacePermissionsEnum;
}

export interface Call {
  Id: number;
  CanCancel: boolean;
  CanEdit: boolean;
  Description: string;
  NoTagDescription: string;
  Place: string;
  ReceivedDate: string;
  Status: any;

  Client: number;
  IndirectClient: number;
  Project: number;
  Contact: number;
  Contract: number;
  Department: number;
  Team: number;
  ManagerAccount: number;
  Creator: number;
  StatusDetail: number;
}

export interface Deal {
  Id: number;
  HasUpdates: boolean;
  Company: number;
  CanEdit: boolean;
  CanDelete: boolean;
  Client: number;
  Name: string;
  Pipeline: number;
  PipelineStatus: number;
  BeginDate: string;
  Departments: number[];
  EndDate: string;
  EstimatedCost: object;
  EstimatedCosts: object[];
  Followers: string[];
  Contact: number;
  StatusDetail: number;
  Collaborators: string[];
  Assigned: string;
  DealManager: string;
  Creator: string;
  CreationDate: string;
  SalesOpportunity: string;
  Status: number;
  IsFollowing: boolean;
  NoTagDescription: string;
  EmailsCount: number;
  StatusDetailDescription: string;
  FilesCount: number;
}

export interface Task {
  Id: number;
  // HasUpdates: boolean;
  // BeginDate: string;
  // EndDate: string;
  // CanCancel: boolean;
  // CanEdit: boolean;
  // Description: string;
  // NoTagDescription: string;
  // Place: string;
  // Status: any;
  // Client: number;
  // IndirectClient: number;
  // Project: number;
  // Contact: number;
  // Contract: number;
  // Department: number;
  // Team: number;
  // ManagerAccount: number;
  // Creator: number;
  // StatusDetail: number;
  HasUpdates: boolean;
  BeginDate: string;
  EndDate: string;
  Status: number;
  Name: string;
  FilesCount: number;
  IsFollowing: boolean;
  EstimatedCost: number;
  Pipeline: number;
  PipelineStatus: number;
  Company: number;
  Manager: string;
  Ticket: number;
  IndirectClient: number;
  CanEdit: boolean;
  CanDelete: boolean;
  Creator: string;
  CreationDate: string;
  NoTagInternalDescription: string;
  EmailsCount: number;
  Client: number;
  SpentHours: number;
  Contract: number;
  Project: number;
  Task: number;
  Deal: number;
  Addresses: number[];
  Followers: string[];
  IsRead: boolean;
  ToDos: number;
  ToDosCount: number;
}

export interface Ticket {
  Id: number;
  Name: string;
  CanCancel: boolean;
  CanEdit: boolean;
  Description: string;
  Place: string;
  ReceivedDate: string;
  Status: any;

  Client: number;
  IndirectClient: number;
  Call: number;
  Project: number;
  Contact: number;
  Team: number;
  Department: number;
  ManagerAccount: number;
  Creator: number;
  Contract: number;
  StatusDetail: number;
}

export interface Project {
  Id: number;
  Name: string;
  BeginDate: string;
  EndDate: string;
  ReceivedDate: string;
  CanCancel: boolean;
  CanEdit: boolean;
  Description: string;
  InternalDescription: string;
  EstimatedCost: number;
  SpentHours: number;
  SalesOpportunity: string;
  Status: any;
  TimeStamp: string;

  Manager: number;
  Creator: number;
  Departments: number;
  Collaborators: {
    Accounts: number[];
    Teams: number[];
  };
  Category: number;
  Contact: number;
  Contract: number;
  Client: number;
  StatusDetail: number;
}

export interface Intervention {
  Id: number;
  BeginDate: string;
  CanCancel: boolean;
  Date: string;
  Description: string;
  EndDate: string;
  EstimatedCost: number;
  InternalDescription: string;
  IsDeleted: boolean;
  Place: string;
  PlaceType: any;
  TotalPauses: number;

  Project: number;
  Contacts: number[];
  Client: number;
  IndirectClient: number;
  Contact: number;
  Account: number;
  Ticket: number;
  Call: number;
  Task: number;
  Contract: number;
  Type: number;
  Organization: number;
  Billing: number;
}
