import React, { useMemo } from "react";
import FormInput from "../../../Components/Forms/FormInput/FormInput";

import { useIntl } from "react-intl";
// import { withSpace } from "../../../Contexts/SpaceContext";
import {
  // withSpaceEntityForm
  //   createFormRequestHook,
  useFormState
} from "../../../Components/Forms";
import {
  // SideMenuForm,
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../../Components/Forms/SideMenuForm";
import { spaceCancelationReasonSchema } from "../../../config/schema";
import {
  OrganizationDropdown
  //   DepartmentTeamDropdown,
  //   convertDepartmentTeams,
  //   convertToDepartmentTeams
} from "../../AdvancedMultiInputs";
import { useCancelationReason } from "../../../Hooks/EntityHooks";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { defaultCancelForm } from "../../Calls/CallsHelper";
import { useToast } from "../../../Components/Toast/ToastProvider";
// import Switch from "../../../Components/Switch/Switch";
import { useSpaceQuery } from "../../../Helpers/IOClient";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpacePost,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";

// const useCancelRequest = createFormRequestHook({
//   schema: spaceCancelationReasonSchema,
//   dependencies: []
// });

const CancelationReasonForm = (props) => {
  const { Type, id, onSuccess } = props;

  const endpoint = useMemo(() => {
    if (!id) return null;
    return `statusdetail/${handleLocation(Type)}/CancellationReasons/${id}`;
  }, [Type, id]);

  const {
    loading,
    error
    // data
  } = useSpaceQuery(endpoint, spaceCancelationReasonSchema);

  const cancel = useCancelationReason(id);

  const canEdit = cancel ? cancel.CanEdit : true;

  const canDelete = cancel?.CanDelete;

  const intl = useIntl();
  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        {id
          ? `${intl.formatMessage({ id: "EDIT_CANCELATION_REASON" })} ${id}`
          : intl.formatMessage({ id: "ADD_CANCELATION_REASON" })}
      </BaseSidebarHeader>
      <BaseSidebarContent>
        {loading || error ? (
          <LoaderSpinner center size="sm" className="text-secondary" />
        ) : (
          <CancelationForm
            canDelete={canDelete}
            onSuccess={onSuccess}
            id={id}
            canEdit={canEdit}
            convertedForm={cancel}
            {...props}
          />
        )}
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );

  // if (loading || error)
  //   return <LoaderSpinner center size="sm" className="text-secondary" />;

  // return (
  //   <CancelationForm
  //     onSuccess={onSuccess}
  //     id={id}
  //     canEdit={canEdit}
  //     convertedForm={cancel}
  //     {...props}
  //   />
  // );
};

const CancelationForm = ({
  convertedForm,
  id,
  canEdit,
  onSuccess,
  isChild,
  refetchRef,
  canDelete,
  ...props
}) => {
  const { Type } = props;
  const location = handleLocation(Type);
  const formState = useFormState(() => {
    return {
      ...defaultCancelForm(),
      ...convertedForm
    };
  });
  const { form } = formState;
  const { Organizations, Name } = form;

  const handleTranslatedError = useHandleError(spaceCancelationReasonSchema);
  const createToast = useToast();
  const intl = useIntl();

  const [post, { loading }] = useSidebarSpacePost(
    id
      ? `statusdetail/${location}/CancellationReasons/${id}`
      : `statusdetail/${location}/CancellationReasons`,
    spaceCancelationReasonSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({
            id: "CANCELATION_REASON"
          })} ${Name}`,
          description: `${intl.formatMessage({
            id: "CANCELATION_REASONS_SUCCESS"
          })}`
        });
      },
      refetchRef,
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `statusdetail/${location}/CancellationReasons/${id}`,
    spaceCancelationReasonSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "CANCELATION_REASON" })} ${Name}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  const handleSubmit = (form) => {
    post(formatCancelForm(form));
  };

  const validateCancelForm = ({ Name, Organizations }, addError) => {
    if (!Name) addError("Name");
    if ((Organizations && Organizations.length === 0) || !Organizations)
      addError("Organizations");
  };

  const formatCancelForm = (form) => {
    const { Name, DepartmentTeams, ...restForm } = form;
    return {
      ...restForm,
      Name,
      DepartmentTeams,
      Organizations
    };
  };

  return (
    <SideForm
      className="flex-1 of-y-auto d-flex flex-column"
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateCancelForm}
    >
      <SideFormContent className="p-4">
        <FormInput name="Name" value={Name} textId="NAME" className="mb-3" />

        <FormInput
          name="Organizations"
          className="mb-3"
          value={Organizations}
          textId="COMPANY"
          inputType={OrganizationDropdown}
          multiple
        />
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        deleteLoading={deleteLoading}
        handleDelete={remove}
        isLoading={loading}
      />
    </SideForm>
  );
};

const handleLocation = (type) => {
  switch (type) {
    case 1:
      return "settingsProjects";
    case 2:
      return "settingsTickets";
    case 3:
      return "settingsCalls";
    case 4:
      return "settingsTasks";
    case 5:
      return "settingsContracts";
    case 6:
      return "settingsDeals";
    case 8:
      return "settingsSubscriptions";
    default:
      return null;
  }
};

export default CancelationReasonForm;
