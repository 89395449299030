import React, { useMemo } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import {
  DocumentsStatusTypes,
  DocumentsStatusTypesForFilter
} from "./DocumentsHelper";
import { DocIcon } from "../../../Components/Common/EntityIcons";
import {
  DealsCanceledStatusDot,
  DealsNewStatusDot,
  DealsWonStatusDot,
  SocialPostsDraftStatusDot,
  DealsDraftStatusDot,
  OpenStatusDot,
  ClosedStatusDot
} from "../../../Components/StatusHelper/StatusHelper";

export const buildDocumentStatusType = (status, sensitive = false) => {

  switch (parseInt(status)) {
    case DocumentsStatusTypes.draft:
      return "yellow";
    case DocumentsStatusTypes.review:
      return "orange";

    case DocumentsStatusTypes.active:
      return "purple";
    case DocumentsStatusTypes.closed:
      return "success";

    case DocumentsStatusTypes.cancelled:
      return "grey";

    default:
      return sensitive ? "outline-grey" : "success";
  }
};

const statusNameByValue = (status) => {
  switch (status.toString()) {
    case DocumentsStatusTypesForFilter.draft:
      return <FormattedMessage id={"DRAFT"} />;

    case DocumentsStatusTypesForFilter.active:
      return <FormattedMessage id={"ACTIVE"} />;
    case DocumentsStatusTypesForFilter.review:
      return <FormattedMessage id={"REVIEW"} />;
    case DocumentsStatusTypesForFilter.closed:
      return <FormattedMessage id={"CLOSED"} />;

    case DocumentsStatusTypesForFilter.cancelled:
      return <FormattedMessage id={"INACTIVE"} />;

    default:
      return null;
  }
};

export const DocumentsStatusProfileWithIcon = ({
  project,
  status,
  item,
  hideName,
  className,
  Icon,
  dotClassName,
  inline,
  sensitive,
  value,
  size = 24
}) => {
  const resovledStatus = project ? project.Status : status || item || value;

  if (!resovledStatus && !sensitive) return null;

  return (
    <div
      className={classnames(
        `link-color status-color-${buildDocumentStatusType(
          resovledStatus,
          sensitive
        )}`,
        className,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div
        style={{ width: size, height: size }}
        className={`d-flex align-items-center justify-content-around position-relative text-truncate status-dot m-0 status-dot-background-template`}
      >
        <DocIcon />
      </div>
      {!hideName && (
        <div className="status-label fs-12">
          {statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

const DocumentStatusSvgIcons = (status, sensitive = false) => {
  switch (parseInt(status)) {
    case DocumentsStatusTypes.draft:
      return <SocialPostsDraftStatusDot />;
    case DocumentsStatusTypes.review:
      return <DealsDraftStatusDot />;

    case DocumentsStatusTypes.active:
      return <OpenStatusDot />;
    case DocumentsStatusTypes.closed:
      return <ClosedStatusDot />;

    case DocumentsStatusTypes.cancelled:
      return <DealsCanceledStatusDot />;

    default:
      return sensitive ? <DealsCanceledStatusDot /> : <DealsNewStatusDot />;
  }
};

export const DocumentStatusProfile = ({
  project,
  status,
  item,
  hideName,
  className,
  dotClassName,
  inline,
  sensitive,
  pipelineName,
  value,
  children
}) => {

  const resovledStatus = project ? project.Status : status || item || value;

  const SvgComponent = useMemo(() => {
    if (!resovledStatus && !sensitive) return null;
    return DocumentStatusSvgIcons(resovledStatus, sensitive);
  }, [resovledStatus, sensitive]);

  if (!resovledStatus && !sensitive) return null;

  return (
    <div
      className={classnames(
        `link-color flex-0-0-auto text-truncate status-color-${buildDocumentStatusType(
          resovledStatus,
          sensitive
        )}`,
        className,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div
        className={classnames("position-relative status-dot-svg", dotClassName)}
      >
        {SvgComponent}
      </div>
      {!hideName && (
        <div className="status-label text-truncate fs-12">
          {pipelineName || statusNameByValue(resovledStatus)}
        </div>
      )}
      {children}
    </div>
  );
};
