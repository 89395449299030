import React from "react";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";
import classnames from "classnames";

const DeleteButton = (props) => {
  
  const { children, className, ...rest } = props;
  return (
    <Button
      className={classnames("ssi-button-grid", className)}
      vType="outline-danger"
      type="button"
      {...rest}
    >
      <FontAwesomeIcon icon={faTrashAlt} />
    </Button>
  );
};

export default DeleteButton;
