import React, { useState, useEffect, useCallback, useRef } from "react";
import { msToTime } from "../../Assets/Utils";
import {
  SideMenuFormFooterBase,
  SideFormContent
} from "../../Components/Forms/SideMenuForm";
import Button from "../../Components/Button/Button";
import {
  addTimeTracker,
  toggleMultipleTracking,
  toggleTimeTrackerState,
  updateTimeTrackingValues,
  removeTimeTracker,
  useTimeTrackingState
} from "./TimeTrackingHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import { InterventionFormButton } from "../Form/FormButtons";
import classnames from "classnames";
import Switch from "../../Components/Switch/Switch";
import HTMLInput from "../../Components/HTMLInput/HTMLInput";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import DeleteButton from "../../Components/Button/DeleteButton";
import { DashboardEmptyResponse } from "../Dashboard/Dashboard";
import { createDateGroupingHook } from "../../Hooks/MiscHooks";
import { MilSecToMinute, MinuteToMilSec } from "../../Helpers/MiscHelper";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent
} from "../../Components/Sidebar/SidebarV2Helper";
import { FormattedMessage, useIntl } from "react-intl";
import { InterventionTargetDropdown } from "../AdvancedMultiInputs";
import Modal from "../../Components/Modal/Modal";
import { useSidebarChanges } from "../../Components/Sidebar/SidebarV2";

const Counter = ({ value, isPaused }) => {
  const [state, setState] = useState(() => {
    let currentTime;

    currentTime = Date.now() - value;

    return msToTime(currentTime, null, true);
  });

  useEffect(() => {
    if (isPaused) return;
    const interval = setInterval(() => {
      const currentTime = Date.now() - value;
      setState(msToTime(currentTime, null, true));
    }, 1000);

    return () => clearInterval(interval);
  }, [isPaused, value]);

  const { hours, minutes, seconds } = state;
  return (
    <div className="d-flex align-items-center text-primary ml-4">
      <div className="text-primary fs-30">
        {hours < 10 ? `0${hours}` : hours}:
        {minutes < 10 ? `0${minutes}` : minutes}
      </div>
      <div className="fs-20">{seconds < 10 ? `0${seconds}` : seconds}</div>
    </div>
  );
};

const TimeTrackerItem = React.memo(
  ({
    value,
    onTogglePause,
    multipleTracking,
    hasAtLeastOneTracking,
    onTimeTrackingValueChange,
    onRemoveTimeTrackerRemoval,
    firstRender
  }) => {
    // eslint-disable-next-line no-unused-vars
    const [toggleSidebarChanges, changes] = useSidebarChanges();
    const { date, key, creationDate, isPaused, elapsedTime, values } = value;

    const { internalDescription, InterventionTarget } = values;

    let startValue;

    if (isPaused) {
      startValue = Date.now() - elapsedTime;
    } else {
      startValue = new Date(date).getTime() - elapsedTime;
    }
    const formmatedDate = moment(creationDate).format("DD/MM/YYYY HH:mm");

    const handleToggle = () => {
      onTogglePause(key);
    };

    const intl = useIntl();

    const getFormProps = () => {
      const { creationDate, date, pauses } = !isPaused
        ? onTogglePause(key)
        : value;
      const BeginDate = new Date(creationDate);
      BeginDate.setSeconds(0);
      BeginDate.setMilliseconds(0);
      const EndDate = new Date(date);
      EndDate.setSeconds(0);
      EndDate.setMilliseconds(0);
      ////;
      const TotalPauses = MinuteToMilSec(MilSecToMinute(pauses));

      const formProps = {
        onSuccess: () => {
          removeTimeTracker(key);
          setTimeout(() => {
            toggleSidebarChanges(false);
          }, 100);
        },
        defaultForm: {
          BeginDate: BeginDate,
          EndDate: EndDate,
          TotalPauses,
          InternalDescription: internalDescription,
          InterventionTarget: InterventionTarget
        }
      };
      return formProps;
    };

    const [modalState, setModalState] = useState(false);

    const closeModal = () => {
      setModalState(false);
    };

    const textareaRef = useRef();
    const hasMoundetRef = useRef(false);
    useEffect(() => {
      if (!firstRender && !hasMoundetRef.current) {
        textareaRef.current.focus();
      }
      hasMoundetRef.current = true;
    }, [firstRender]);

    return (
      <div
        className={classnames("ar-time-tracker-item p-3", {
          active: !isPaused
        })}
      >
        <Modal isOpen={modalState} onClose={closeModal}>
          <div
            style={{ borderRadius: 7 }}
            className="p-4 w-450px bg-white text-black"
          >
            <div className="mb-4 fw-medium">
              <FormattedMessage id="DELETE_STOPWATCH_CONFIRMATION" />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <Button
                onClick={closeModal}
                className="fw-medium w-auto"
                type="button"
                vType="link"
              >
                <FormattedMessage id="CANCEL" />
              </Button>
              <Button
                onClick={() => onRemoveTimeTrackerRemoval(key)}
                type="button"
                vType="danger"
              >
                <FormattedMessage id="REMOVE" />
              </Button>
            </div>
          </div>
        </Modal>
        <div className="ar-time-tracker-table d-flex align-items-center mb-3">
          <div className="d-flex align-items-center">
            <Button
              onClick={handleToggle}
              disabled={!multipleTracking && isPaused && hasAtLeastOneTracking}
              className="ar-tracker-button mr-2"
              vType={"primary-ghost"}
            >
              <FontAwesomeIcon icon={isPaused ? faPlay : faPause} />
            </Button>
            <div style={{ height: "40px", minWidth: "40px" }}>
              <InterventionFormButton
                className="w-100 h-100"
                alt
                push
                formProps={getFormProps}
              />
            </div>
          </div>
          <Counter isPaused={isPaused} value={startValue} key={startValue} />
          <div className="d-flex align-items-center w-100 justify-content-end ml-2">
            <span className="mr-3 fs-14 text-black">{formmatedDate}</span>
            <div style={{ height: "40px", minWidth: "40px" }}>
              <DeleteButton
                className="w-100 h-100"
                onClick={() => setModalState(true)}
              />
            </div>
          </div>
        </div>
        <FormInput
          name="InterventionTarget"
          textId="ASSOCIATE_TO"
          value={InterventionTarget}
          inputType={InterventionTargetDropdown}
          allClients={false}
          onChange={(e) =>
            onTimeTrackingValueChange(key, {
              InterventionTarget: e.target.value
            })
          }
          className="mb-3"
        />
        <FormInput
          text={intl.formatMessage({ id: "INTERNAL_NOTE" })}
          inpuRef={textareaRef}
          inputType={HTMLInput}
          value={internalDescription}
          onChange={(e) =>
            onTimeTrackingValueChange(key, {
              internalDescription: e.target.value
            })
          }
        />
      </div>
    );
  }
);

const isDiffrentDay = (d1, d2) => {
  const rd1 = new Date(d1);
  const rd2 = new Date(d2);

  if (
    rd1.getDate() !== rd2.getDate() ||
    rd1.getMonth() !== rd2.getMonth() ||
    rd1.getFullYear() !== rd2.getFullYear()
  )
    return false;

  return true;
};
const buildDateKey = (date) => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth();
  const year = d.getFullYear();

  return `${day}${month}${year}`;
};

const useTimeTrackingDateGroup = createDateGroupingHook(
  isDiffrentDay,
  buildDateKey,
  "creationDate"
);

const Title = ({ date, now = new Date() }) => {
  const intl = useIntl();
  let diff = moment(now).diff(moment(date), "days");
  if (diff === 0) return intl.formatMessage({ id: "TODAY" });
  else if (diff === 1) return intl.formatMessage({ id: "YESTERDAY" });
  else
    return (
      <FormattedMessage
        id="DAYS_AGO"
        values={{ days: diff }}
      ></FormattedMessage>
    );
};

const TimeTrackingList = ({ timeTrackers, ...rest }) => {
  const filtredByDate = useTimeTrackingDateGroup(timeTrackers);
  ////;
  const now = new Date();

  return (
    <div className="w-100 h-100 d-flex flex-column">
      <div className="ar-time-tracker-table tracker-header bg-transparent d-flex px-3 mb-1 py-3 fs-14 fw-medium text-black">
        <div className="w-100">
          <FormattedMessage id={"RECORD"} />
        </div>
        <div className="w-100 ml-4">
          <FormattedMessage id={"TIME"} />
        </div>
        <div className="w-100 mr-4">
          <FormattedMessage id={"DATE"} />
        </div>
      </div>
      <div className="d-flex flex-column of-y-auto ">
        {filtredByDate.map((f) => {
          return (
            <div className="ar-time-tracker-container" key={f.key}>
              <div className="ar-time-tracker-title px-3 py-2 fw-medium text-black fs-14">
                <Title date={f.date} now={now} />
              </div>
              <div className="ar-time-tracker-items">
                {f.items.map((t) => {
                  return <TimeTrackerItem key={t.key} value={t} {...rest} />;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const TimeTrackerForm = () => {
  ////;
  const state = useTimeTrackingState();
  const handleAddTimeTracking = () => {
    addTimeTracker();
  };

  const handleTogglePause = useCallback((key) => {
    const { timeTracker } = toggleTimeTrackerState(key);

    return timeTracker;
  }, []);

  const handleUpdateTimeTrackerValues = useCallback((key, update) => {
    updateTimeTrackingValues(key, update);
  }, []);

  const { timeTrackers, multipleTracking } = state;

  const handleMultipleTrackingToggle = useCallback(() => {
    toggleMultipleTracking();
  }, []);

  const handleTimeTrackerRemoval = useCallback((key) => {
    removeTimeTracker(key);
  }, []);

  const firstRenderRef = useRef(true);

  useEffect(() => {
    firstRenderRef.current = false;
  }, []);

  ////;
  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"TIME_CONTROL"} />
      </BaseSidebarHeader>
      <BaseSidebarContent className="d-flex flex-column w-100">
        <SideFormContent noPadding className={"h-100 d-flex of-hidden"}>
          {timeTrackers.length === 0 ? (
            <DashboardEmptyResponse
              style={{ height: 200 }}
              type="interventions"
            >
              <FormattedMessage id={"EMPTY_TIMER"} />
            </DashboardEmptyResponse>
          ) : (
            <TimeTrackingList
              firstRender={firstRenderRef.current}
              onTimeTrackingValueChange={handleUpdateTimeTrackerValues}
              multipleTracking={multipleTracking}
              onTogglePause={handleTogglePause}
              timeTrackers={timeTrackers}
              onRemoveTimeTrackerRemoval={handleTimeTrackerRemoval}
            />
          )}
        </SideFormContent>
        <SideMenuFormFooterBase className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <Switch
              checked={multipleTracking}
              onChange={handleMultipleTrackingToggle}
              className="mr-2"
            />
            <div className="fs-14 text-black">
              <FormattedMessage id={"MULTIPLE_TIMERS"} />
            </div>
          </div>
          <Button onClick={handleAddTimeTracking} vType="primary-ghost">
            +{" "}
            <FormattedMessage
              id={multipleTracking ? "ADD_TIMERS" : "ADD_TIMER"}
            />
          </Button>
        </SideMenuFormFooterBase>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};
