import { useRef, useReducer, useMemo, useLayoutEffect } from "react";
import store from "../store";
import {
  spaceProjectsSchema,
  spaceCallsSchema,
  spaceTasksSchema,
  spaceTicketsSchema,
  spaceContractSchema,
  spaceClientSchema,
  accountSchema,
  spaceNotificationSchema,
  spaceBreadcrumbsSchema,
  spaceInterventionsSchema,
  spaceMissingTimeChargeSchema,
  spaceDepartmentSchema,
  spaceContactSchema,
  accountSummarySpaceSchema,
  spaceInterventionTypeSchema,
  spaceOrganizationSchema,
  spaceBillingSchema,
  spaceContractTypeSchema,
  spaceNoteSchema,
  spaceTeamSchema,
  spaceProjectCategorySchema,
  spaceCancelationReasonSchema,
  spaceAdvancedFilterSchema,
  spaceDealsSchema,
  spacePipelineSchema,
  currencySchema,
  spacePipelineStatusSchema,
  spacePrioritySchema,
  spaceLostReasonSchema,
  spaceExportsSchema,
  spaceIneficientTypesSchema,
  spaceSubscriptionSchema,
  spaceSubscriptionTypeSchema,
  spaceEmailDomainsSchema,
  spaceChecklistTemplatesSchema,
  countrySchema,
  spaceSocialAccountSchema,
  spaceImportsSchema,
  spaceClientTypeSchema,
  spaceSocialPostsSchema,
  spaceWidgetSchema,
  spaceWidgetLanguagesSchema,
  inviteSchema,
  spaceInviteSchema,
  spaceCuponSchema,
  spaceEmailReceptionSchema,
  spaceEmailConversationSchema,
  spaceEmailSchema,
  spaceEmailSignatureSchema,
  spaceSignatureAggregationSchema,
  spaceAccountExportsSchema,
  AlterEgoControlOverviewSchema,
  spaceApiTokensSchema,
  spaceApiLogsSchema,
  spaceErrorLogSchema,
  spaceAutomationSchema,
  spaceAutomationActivitySchema,
  UserControlSchema,
  spaceFeedbackQueueSchema,
  spacePermissionQueueSchema,
  spaceBreadcrumbsQueueSchema,
  spaceDocumentsSchema,
  spaceAutomationTriggerQueueSchema,
  spaceAutomationEnrollQueueSchema,
  spaceEnrollManagerQueueSchema,
  spaceWidgetEmailQueueSchema,
  spaceNotificationsQueueSchema,
  spaceColorFilterSchema,
  spaceDocTemplatesSchema,
  spaceBellNotificationSchema,
  spaceClassificationSchema,
  SpaceDocTemplateFoldersSchema,
  SpaceDocFoldersSchema,
  spaceWidgetTicketImageQueueSchema,
  spacePopupNotificationSchema,
  spaceDocumentTypesSchema,
  spaceUserCategoriesSchema,
  UserDocumentType,
  emailWebhooksLogSchema,
  spaceTaskTypeSchema,
  spaceCostCentersSchema,
  spaceTravelTimeAmmountSchema,
  spaceTravelReportsSchema,
  emailOutboundWebhooksLogSchema,
  spaceTicketTagsSchema,
  spaceAutomationEmailConversationExportQueueSchema,
  spaceAllUsersPermissionsQueueSchema,
  reprocessingLogSchema,
  spaceDealsTagsSchema,
  spaceClientTagsSchema,
  spaceContractTagsSchema,
  spaceSubscriptionTagsSchema,
  spaceCallsTagsSchema,
  spaceProjectTagsSchema,
  spaceTaskTagsSchema
} from "../config/schema";
import { useSpace } from "../Contexts/SpaceContext";
import {
  Call,
  Ticket,
  Project,
  Intervention,
  Account
} from "../Interfaces/EntityInterfaces";
import { isEquivalent } from "../Helpers/MiscHelper";

export const getSchemaState = (
  schema: any,
  state = store.getState() as any
) => {
  const { name, key } = schema;
  return state.Entities[name || key];
};

export const getItemFromEntityState = (
  schema: any,
  entityId: any,
  state = store.getState()
) => {
  if (!schema || !entityId) return null;
  const entities = getSchemaState(schema, state);
  if (!entities) return null;
  return entities[entityId];
};
export const getAllItemFromEntityState = (
  schema: any,
  state = store.getState()
) => {
  if (!schema) return null;
  const entities = getSchemaState(schema, state);
  if (!entities) return null;
  return entities;
};

export function useEntityValue<T>(schema: any, entityId: any): T {
  const [, forceUpdate] = useReducer((x: any, initial: any) => x + 1, 0);

  const valueRef = useRef<T>();
  const previousIdRef = useRef<any>();
  const previousSchemaRef = useRef<any>();

  if (
    !valueRef.current ||
    entityId !== previousIdRef.current ||
    !previousSchemaRef.current ||
    schema !== previousSchemaRef.current
  ) {
    valueRef.current = getItemFromEntityState(schema, entityId);
    previousIdRef.current = entityId;
    previousSchemaRef.current = schema;
  }

  useLayoutEffect(() => {
    if (entityId === undefined || entityId === null) return;

    let didUnsubscribe = false;

    const listener = () => {
      if (didUnsubscribe) return;

      const newEntity = getItemFromEntityState(schema, entityId);

      if (newEntity === valueRef.current) return;
      if (
        Boolean(valueRef.current) &&
        isEquivalent(newEntity, valueRef.current)
      )
        return;

      valueRef.current = newEntity;
      forceUpdate(undefined);
    };
    const unsubscribe = store.subscribe(listener);

    return () => {
      didUnsubscribe = true;
      unsubscribe();
    };
  }, [entityId, schema]);

  return valueRef.current as T;
}

export function useGroupValue<T>(schema: any): T {
  const [, forceUpdate] = useReducer((x: any, initial: any) => x + 1, 0);

  const valueRef = useRef<T>();
  const previousSchemaRef = useRef<any>();

  if (
    !valueRef.current ||
    !previousSchemaRef.current ||
    schema !== previousSchemaRef.current
  ) {
    valueRef.current = getAllItemFromEntityState(schema);
    previousSchemaRef.current = schema;
  }

  useLayoutEffect(() => {
    if (schema === undefined || schema === null) return;

    let didUnsubscribe = false;

    const listener = () => {
      if (didUnsubscribe) return;

      const newEntity = getAllItemFromEntityState(schema);
      if (
        Boolean(valueRef.current) &&
        valueRef.current !== undefined &&
        JSON.stringify(newEntity) === JSON.stringify(valueRef.current)
      )
        return;

      valueRef.current = newEntity;
      forceUpdate(undefined);
    };
    const unsubscribe = store.subscribe(listener);

    return () => {
      didUnsubscribe = true;
      unsubscribe();
    };
  }, [schema]);

  return valueRef.current as T;
}

export function createEntityHook<T, B>(schema: any) {
  return (entityId: B) => useEntityValue<T>(schema, entityId);
}

export function createGroupEntityHook<T>(schema: any) {
  return () => useGroupValue<T>(schema);
}

export const useMappedAccountSpace = (accountId: any) => {
  const user = useAccount(accountId);
  const space = useSpace() as any;

  const accountSpaceId = useMemo(() => {
    if (!user) return null;
    const x = store.getState() as any;
    return x.AccountSpaceMapper[space.Id][user.Id];
  }, [user, space]);
  return useAccountSpace(accountSpaceId);
};

export const useCurrency = createEntityHook(currencySchema);
export const useBilling = createEntityHook(spaceBillingSchema);
export const useClassification = createEntityHook(spaceClassificationSchema);
export const useDeal = createEntityHook(spaceDealsSchema);
export const useIntervention = createEntityHook<Intervention, number>(
  spaceInterventionsSchema
);

export const useTravelLog = createEntityHook(spaceTravelReportsSchema);

export const useAllPipelines = createGroupEntityHook(spacePipelineSchema);

export const useSocialPost = createEntityHook(spaceSocialPostsSchema);
export const useInterventionType = createEntityHook(
  spaceInterventionTypeSchema
);
export const useTravelDuration = createEntityHook(spaceTravelTimeAmmountSchema);
export const useProject = createEntityHook<Project, number>(
  spaceProjectsSchema
);
export const useFeedbackQueue = createEntityHook(spaceFeedbackQueueSchema);
export const useNotificationQueue = createEntityHook(
  spaceNotificationsQueueSchema
);

export const useAllUserPermissionQueue = createEntityHook(
  spaceAllUsersPermissionsQueueSchema
);

export const useAutomationEmailConversationExportQueueSchema = createEntityHook<
  Call,
  number
>(spaceAutomationEmailConversationExportQueueSchema);
export const useAutomationTriggerQueue = createEntityHook(
  spaceAutomationTriggerQueueSchema
);
export const useAutomationEnrollQueue = createEntityHook(
  spaceAutomationEnrollQueueSchema
);
export const useWidgetEmailQueue = createEntityHook(
  spaceWidgetEmailQueueSchema
);
export const useOutboundEmailQueue = createEntityHook(
  emailOutboundWebhooksLogSchema
);
export const useReprocessingQueue = createEntityHook(reprocessingLogSchema);
export const useEnrollManagerQueue = createEntityHook(
  spaceEnrollManagerQueueSchema
);
export const useWidgetTicketImageQueue = createEntityHook(
  spaceWidgetTicketImageQueueSchema
);
export const usePermissionQueue = createEntityHook(spacePermissionQueueSchema);
export const useBreadcrumbsQueue = createEntityHook(
  spaceBreadcrumbsQueueSchema
);
export const useCall = createEntityHook<Call, number>(spaceCallsSchema);
export const useTask = createEntityHook(spaceTasksSchema);
export const useTicket = createEntityHook<Ticket, number>(spaceTicketsSchema);
export const useEmailConversation = createEntityHook(
  spaceEmailConversationSchema
);
export const useEmail = createEntityHook(spaceEmailSchema);
export const useContract = createEntityHook(spaceContractSchema);
export const useSubscription = createEntityHook(spaceSubscriptionSchema);
export const useClientType = createEntityHook(spaceClientTypeSchema);
export const useDealTag = createEntityHook(spaceDealsTagsSchema);
export const useCallTag = createEntityHook(spaceCallsTagsSchema);
export const useProjectTag = createEntityHook(spaceProjectTagsSchema);
export const useTaskTag = createEntityHook(spaceTaskTagsSchema);
export const useContractTag = createEntityHook(spaceContractTagsSchema);
export const useSubscriptionTag = createEntityHook(spaceSubscriptionTagsSchema);
export const useClientTag = createEntityHook(spaceClientTagsSchema);
export const useTicketType = createEntityHook(spaceTicketTagsSchema);
export const useTaskType = createEntityHook(spaceTaskTypeSchema);
export const useClient = createEntityHook(spaceClientSchema);
export const useAccount = createEntityHook<Account, number>(accountSchema);
export const useInvite = createEntityHook<Account, number>(inviteSchema);
export const useIneficientTypes = createEntityHook(spaceIneficientTypesSchema);
export const useContractType = createEntityHook(spaceContractTypeSchema);
export const useEmailDomain = createEntityHook(spaceEmailDomainsSchema);
export const useApiToken = createEntityHook(spaceApiTokensSchema);
export const useApiLog = createEntityHook(spaceApiLogsSchema);
export const useAlterEgoControlOverviewSchema = createEntityHook(
  AlterEgoControlOverviewSchema
);
export const useSubscriptionType = createEntityHook(
  spaceSubscriptionTypeSchema
);
export const useWidgetLanguage = createEntityHook(spaceWidgetLanguagesSchema);
export const useNotification = createEntityHook(spaceNotificationSchema);
export const useBellNotification = createEntityHook(
  spaceBellNotificationSchema
);
export const usePopupNotification = createEntityHook(
  spacePopupNotificationSchema
);
export const useBreadcrumbs = createEntityHook(spaceBreadcrumbsSchema);
export const useMissingTimeCharges = createEntityHook(
  spaceMissingTimeChargeSchema
);
export const useProjectType = createEntityHook(spaceProjectCategorySchema);
export const useOrganization = createEntityHook(spaceOrganizationSchema);
export const useExport = createEntityHook(spaceExportsSchema);
export const useAccountExport = createEntityHook(spaceAccountExportsSchema);
export const useImport = createEntityHook(spaceImportsSchema);
export const useEmailSignatureChoice = createEntityHook(
  spaceSignatureAggregationSchema
);
export const useDepartment = createEntityHook(spaceDepartmentSchema);
export const useDocTemplateFolder = createEntityHook(
  SpaceDocTemplateFoldersSchema
);

export const useUserDocumentType = createEntityHook(UserDocumentType);

export const useDocFolder = createEntityHook(SpaceDocFoldersSchema);
export const useTeam = createEntityHook(spaceTeamSchema);
export const useContact = createEntityHook(spaceContactSchema);
export const useEmailSignature = createEntityHook(spaceEmailSignatureSchema);
export const useAccountSpace = createEntityHook(accountSummarySpaceSchema);
export const useInviteAccountSpace = createEntityHook(spaceInviteSchema);
export const useUserCategory = createEntityHook(spaceUserCategoriesSchema);

export const useCostCenter = createEntityHook(spaceCostCentersSchema);
export const useNote = createEntityHook(spaceNoteSchema);

export const useProjectCategory = createEntityHook(spaceProjectCategorySchema);
export const useDocumentType = createEntityHook(spaceDocumentTypesSchema);
export const useCancelationReason = createEntityHook(
  spaceCancelationReasonSchema
);
export const useCupon = createEntityHook(spaceCuponSchema);
export const useChecklistTemplate = createEntityHook(
  spaceChecklistTemplatesSchema
);
export const useLostReason = createEntityHook(spaceLostReasonSchema);

export const usePipeline = createEntityHook(spacePipelineSchema);
export const useSocialAccount = createEntityHook(spaceSocialAccountSchema);
export const useWidget = createEntityHook(spaceWidgetSchema);
export const usePipelineStatus = createEntityHook(spacePipelineStatusSchema);

export const usePriority = createEntityHook(spacePrioritySchema);

export const useCountry = createEntityHook(countrySchema);

export const useEmailReception = createEntityHook(spaceEmailReceptionSchema);

export const useAdvancedFilter = createEntityHook(spaceAdvancedFilterSchema);
export const useColorFilter = createEntityHook(spaceColorFilterSchema);
export const useEmailHookLog = createEntityHook(emailWebhooksLogSchema);
export const useErrorLog = createEntityHook(spaceErrorLogSchema);
export const useUserControl = createEntityHook(UserControlSchema);
export const useAutomation = createEntityHook(spaceAutomationSchema);
export const useDocument = createEntityHook(spaceDocumentsSchema);
export const useDocTemplate = createEntityHook(spaceDocTemplatesSchema);
export const useAutomationActivity = createEntityHook(
  spaceAutomationActivitySchema
);
// const getInitialMultiState = (items: [any], schema: any, getObj: any) => {
//   const state = store.getState();

//   const arr = [];

//   for (const item of items) {
//     const val = getItemFromEntityState(schema, item, state);
//     arr.push(getObj(val));
//   }
//   return arr;
// };
