import React from "react";
import "./RangeInput.css";
import classnames from "classnames";

const RangeInput = ({ className, ...rest }) => {
  return (
    <input
      className={classnames("ar-range-input", className)}
      type="range"
      {...rest}
    />
  );
};

export default RangeInput;
