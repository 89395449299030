import { Extension } from "@tiptap/core";

const SpeechRecognition =
  (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

export interface TextToSpeechOptions {
  possibleLangs: string[];
  defaultLang: string;
  recognition: any;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    textToSpeech: {
      /**
       * Initiate the speech to text plugin
       */
      startSpeechToText: () => ReturnType;
      /**
       * Stop the speech to text plugin
       */
      stopSpeechToText: () => ReturnType;
      /**
       * Change the speech to Text Language
       */
      changeSpeechToTextLanguage: (lang: string) => ReturnType;
    };
  }
}

const TextToSpeech = Extension.create<TextToSpeechOptions>({
  name: "textToSpeech",

  addOptions() {
    return {
      possibleLangs: ["en-US", "pt-PT", "es-AR"],
      defaultLang: "en-US",
      recognition: null
    };
  },

  addCommands() {
    return {
      startSpeechToText:
        () =>
        ({ commands }) => {
          this.storage.speechListen = true;

          this.options.recognition = new SpeechRecognition();
          this.options.recognition.continous = true;
          this.options.recognition.interimResults = true;
          this.options.recognition.lang =
            this.storage.chosenLang || this.options.defaultLang;

          this.options.recognition.start();
          this.options.recognition.onend = () => {
            if (this.storage.speechListen) this.options.recognition.start();
          };
          this.options.recognition.onresult = (event: any) => {
            for (let i = event.resultIndex; i < event.results.length; i++) {
              const transcript = event.results[i][0].transcript;
              if (event.results[i].isFinal) {
                // console.log(transcript);
                this.editor.commands.insertContent(transcript);
              }
            }
          };

          return true;
        },
      stopSpeechToText:
        () =>
        ({ commands }) => {
          this.storage.speechListen = false;
          this.options.recognition?.stop();
          return true;
        },
      changeSpeechToTextLanguage:
        (lang) =>
        ({ commands }) => {
          if (!this.options.possibleLangs.includes(lang)) {
            return false;
          }
          if (this.storage.speechListen) {
            this.storage.speechListen = false;
            this.options.recognition?.stop();
          }
          this.storage.chosenLang = lang;
          return true;
        }
    };
  }
});

export default TextToSpeech;
