import React, { useEffect, useMemo } from "react";
import { useSpace } from "../../Contexts/SpaceContext";
import Notifications from "./Notifications";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import { useUnreadEntities } from "../../Components/UnreadEntities/UnreadEntities";

export const NotificationContext = React.createContext();

const NotificationProvider = ({ children }) => {
	const space = useSpace();
	const [openSidebar] = useSidebar();

	const baseEndpoint = `Spaces/${space.Id}/Notifications`;

	const Unreads = useUnreadEntities();
	const {UnreadBell: notificationsCount} = Unreads || {}


	const value = useMemo(() => {
		const openNotificationSidebar = () => {
			openSidebar(<Notifications />, "Notificações");
		};
		
		return {
			notificationsCount: notificationsCount|| 0,
			openNotificationSidebar,
			baseEndpoint
		};
	}, [notificationsCount, baseEndpoint, openSidebar]);


	useEffect(() => {
		if (value.notificationsCount !== null) {
			if (navigator && navigator.setAppBadge) {
				navigator.setAppBadge(value.notificationsCount).catch((error) => {
					console.log(error)
				});
			}
		}

		return () => {
			if (navigator && navigator.clearAppBadge) {
				navigator.clearAppBadge().catch((error) => {
					console.log(error)
				});
			}
		}
	}, [value])


	return (
		<NotificationContext.Provider value={value}>
			{children}
		</NotificationContext.Provider>
	);
};

export default NotificationProvider;
