import React, { Component } from "react";

import Button from "../Button/Button";
import { getNumberOfDays } from "../../Helpers/MiscHelper";
import { FormattedMessage } from "react-intl";

export class DatePickerW extends Component {
  constructor(props) {
    super(props);
    this.DatePickerContainer = React.createRef();
    this.amountOfYearsPerPage = 16;
    const maximumDate = this.props.disableFutureDate
      ? new Date(this.props.disableFutureDate)
      : "";

    this.todayDate = new Date();
    this.today = this.disableFutureDate
      ? {
          Day: maximumDate.getDate(),
          Month: maximumDate.getMonth() + 1,
          Year: maximumDate.getFullYear()
        }
      : {
          Day: this.todayDate.getDate(),
          Month: this.todayDate.getMonth() + 1,
          Year: this.todayDate.getFullYear()
        };

    this.years = [];

    for (let i = 0; i < this.amountOfYearsPerPage; i++) {
      this.years.push(this.today.Year + i);
    }

    this.selectedDate = this.props.selectedDate;

    const begindate = this.props.disablePastDate
      ? new Date(this.props.disablePastDate)
      : "";

    this.state = {
      HintDay: null,
      HintMonth: null,
      HintYear: null,
      Today: { ...this.today },
      CurrentDate:
        this.selectedDate.Day !== undefined
          ? this.selectedDate
          : { ...this.today },
      SelectedDate:
        this.selectedDate.Day !== undefined
          ? this.selectedDate
          : {
              Day: begindate.getDate(),
              Month: begindate.getMonth() + 1,
              Year: begindate.getFullYear()
            },
      BeginDate:
        this.props.disableDates && begindate
          ? {
              Day: begindate.getDate(),
              Month: begindate.getMonth() + 1,
              Year: begindate.getFullYear()
            }
          : undefined,
      FilteredYears: [...this.years],
      View: 0
    };

    this.header = React.createRef();
    this.header2 = React.createRef();
    this.header3 = React.createRef();
  }

  dayClick = (day) => {
    this.setState(
      {
        HintDay: null,
        HintMonth: null,
        HintYear: null,
        SelectedDate: {
          Day: day,
          Month: this.state.CurrentDate.Month,
          Year: this.state.CurrentDate.Year
        }
      },
      () => {
        this.props.change(this.state.SelectedDate);
      }
    );
  };

  todayClick = () => {
    this.setState(
      {
        SelectedDate: { ...this.state.Today }
      },
      () => {
        this.props.change(this.state.SelectedDate);
      }
    );
  };

  monthClick = (month) => {
    setTimeout(() => {
      this.setState({
        CurrentDate: {
          Day: this.state.CurrentDate.Day,
          Month: month,
          Year: this.state.CurrentDate.Year
        },
        View: 0
      });
    }, 0);
  };

  yearClick = (year) => {
    setTimeout(() => {
      this.setState({
        CurrentDate: {
          Day: this.state.CurrentDate.Day,
          Month: this.state.CurrentDate.Month,
          Year: year
        },
        View: 1
      });
    }, 0);
  };

  goBackMonth = () => {
    if (this.state.CurrentDate.Month === 1) {
      this.setState({
        CurrentDate: {
          Day: 1,
          Month: 12,
          Year: this.state.CurrentDate.Year - 1
        }
      });
    } else {
      this.setState({
        CurrentDate: {
          Day: 1,
          Month: this.state.CurrentDate.Month - 1,
          Year: this.state.CurrentDate.Year
        }
      });
    }
  };

  goForwardMonth = () => {
    if (this.state.CurrentDate.Month === 12) {
      this.setState({
        CurrentDate: { Day: 1, Month: 1, Year: this.state.CurrentDate.Year + 1 }
      });
    } else {
      this.setState({
        CurrentDate: {
          Day: 1,
          Month: this.state.CurrentDate.Month + 1,
          Year: this.state.CurrentDate.Year
        }
      });
    }
  };

  goBackYear = () => {
    if (this.state.CurrentDate.Year <= 1970) return;

    this.setState({
      CurrentDate: {
        Day: 1,
        Month: this.state.CurrentDate.Month,
        Year: this.state.CurrentDate.Year - 1
      }
    });
  };

  goForwardYear = () => {
    this.setState({
      CurrentDate: {
        Day: 1,
        Month: this.state.CurrentDate.Month,
        Year: this.state.CurrentDate.Year + 1
      }
    });
  };

  goBackYears = () => {
    const newBaseYear = this.state.FilteredYears[0] - this.amountOfYearsPerPage;

    const newArr = this.generateYears(newBaseYear);
    setTimeout(() => {
      this.setState({ FilteredYears: newArr });
    }, 0);
  };

  goForwardYears = () => {
    const newBaseYear = this.state.FilteredYears[0] + this.amountOfYearsPerPage;

    const newArr = this.generateYears(newBaseYear);
    this.setState({ FilteredYears: newArr });
  };

  generateYears = (baseYear) => {
    let newArr = [];
    for (let i = 0; i < this.amountOfYearsPerPage; i++) {
      newArr.push(baseYear + i);
    }
    return newArr;
  };

  setView = (view) => {
    setTimeout(() => {
      this.setState({ View: view });
    }, 0);
  };
  isWeekend = (day, month, year) => {
    const dayofweek = new Date(month + "/" + day + "/" + year).getDay();
    if (dayofweek === 0 || dayofweek === 6) return true;
    return false;
  };

  checkDisabledDates = (date) => {
    const dates = this.props.disabledDates;

    if (dates === undefined || dates.length === 0) return false;

    for (let i = 0; i < dates.length; i++) {
      const ddate = new Date(dates[i]);

      if (
        ddate.getDate() === date.Day &&
        ddate.getMonth() + 1 === date.Month &&
        ddate.getFullYear() === date.Year
      )
        return true;
    }
    return false;
  };

  createKeyboardEvents = () => {
    document.addEventListener("keydown", this.KeyMapping);
  };
  removeKeyboardEvents = () => {
    document.removeEventListener("keydown", this.KeyMapping);
  };

  componentDidMount = () => {
    // document.addEventListener("keydown", this.KeyMapping);
    this.DatePickerContainer.current.tabIndex = 0;
    this.DatePickerContainer.current.addEventListener(
      "focusin",
      this.createKeyboardEvents
    );
    this.DatePickerContainer.current.addEventListener(
      "focusout",
      this.removeKeyboardEvents
    );
    document.addEventListener("wheel", this.KeyMapping, { passive: false });
  };
  componentWillUnmount = () => {
    // document.removeEventListener("keydown", this.KeyMapping);
    this.DatePickerContainer.current.addEventListener(
      "focusin",
      this.createKeyboardEvents
    );
    this.DatePickerContainer.current.addEventListener(
      "focusout",
      this.removeKeyboardEvents
    );
    document.removeEventListener("wheel", this.KeyMapping, { passive: false });
  };

  daysKeyMapping = (event, callback) => {
    const numberOfDays = getNumberOfDays(
      this.state.CurrentDate.Year,
      this.state.CurrentDate.Month
    );
    let newDay;
    const baseDay =
      this.state.HintDay !== null
        ? this.state.HintDay
        : this.state.SelectedDate.Day !== null
        ? this.state.SelectedDate.Day
        : this.state.CurrentDate.Year === this.state.Today.Year &&
          this.state.CurrentDate.Month === this.state.Today.Month &&
          this.state.CurrentDate.Day === this.state.Today.Day
        ? this.state.Today.Day
        : 1;

    switch (event.code) {
      case "ArrowUp":
        event.preventDefault();
        newDay = baseDay - 7;
        if (newDay >= 1) {
          this.setState({ HintDay: newDay });
        } else {
          this.goBackMonth();
          const newHintDay = getNumberOfDays(
            this.state.CurrentDate.Year,
            this.state.CurrentDate.Month
          );
          this.setState({ HintDay: newHintDay });
        }
        callback();
        break;
      case "ArrowRight":
        newDay = baseDay + 1;
        if (newDay <= numberOfDays) {
          this.setState({ HintDay: newDay });
        } else {
          this.goForwardMonth();
          this.setState({ HintDay: 1 });
        }
        callback();
        break;
      case "ArrowDown":
        event.preventDefault();
        newDay = baseDay + 7;
        if (newDay <= numberOfDays) {
          this.setState({ HintDay: newDay });
        } else {
          this.goForwardMonth();
          this.setState({ HintDay: 1 });
        }
        callback();
        break;
      case "ArrowLeft":
        newDay = baseDay - 1;
        if (newDay >= 1) {
          this.setState({ HintDay: newDay });
        } else {
          this.goBackMonth();
          const newHintDay = getNumberOfDays(
            this.state.CurrentDate.Year,
            this.state.CurrentDate.Month
          );
          this.setState({ HintDay: newHintDay });
        }
        callback();
        break;
      case "Enter":
      case "Space":
      case "NumpadEnter":
        if (this.state.HintDay !== null) {
          this.dayClick(this.state.HintDay);
          callback();
        }
        break;
      case undefined:
        if (event.type === "wheel") {
          event.preventDefault();
          if (this.header.current.contains(event.target) === true) {
            if (event.deltaY === -100) {
              this.goBackMonth();
            } else if (event.deltaY === 100) {
              this.goForwardMonth();
            }
          } else {
            if (event.deltaY === -100) {
              newDay = baseDay - 1;
              if (newDay >= 1) {
                this.setState({ HintDay: newDay });
              } else {
                this.goBackMonth();
                const newHintDay = getNumberOfDays(
                  this.state.CurrentDate.Year,
                  this.state.CurrentDate.Month
                );
                this.setState({ HintDay: newHintDay });
              }
            } else if (event.deltaY === 100) {
              newDay = baseDay + 1;
              if (newDay <= numberOfDays) {
                this.setState({ HintDay: newDay });
              } else {
                this.goForwardMonth();
                this.setState({ HintDay: 1 });
              }
            }
          }
          callback();
        }
        break;
      default:
        break;
    }
  };

  monthsKeyMapping = (event, callback) => {
    const numberOfMonths = 12;
    let newMonth;
    const baseMonth =
      this.state.HintMonth !== null
        ? this.state.HintMonth
        : this.state.SelectedDate.Month !== null
        ? this.state.SelectedDate.Month
        : this.state.CurrentDate.Year === this.state.Today.Year &&
          this.state.CurrentDate.Month === this.state.Today.Month
        ? this.state.Today.Month
        : 1;

    switch (event.code) {
      case "ArrowUp":
        event.preventDefault();
        newMonth = baseMonth - 3;
        if (newMonth >= 1) {
          this.setState({ HintMonth: newMonth });
        } else {
          this.setState({ HintMonth: 12 });
        }
        // callback();
        break;
      case "ArrowRight":
        event.preventDefault();
        newMonth = baseMonth + 1;
        if (newMonth <= numberOfMonths) {
          this.setState({ HintMonth: newMonth });
        } else {
          this.setState({ HintMonth: 1 });
        }
        callback();
        break;
      case "ArrowDown":
        event.preventDefault();
        newMonth = baseMonth + 3;
        if (newMonth <= numberOfMonths) {
          this.setState({ HintMonth: newMonth });
        } else {
          this.setState({ HintMonth: 1 });
        }
        callback();
        break;
      case "ArrowLeft":
        event.preventDefault();
        newMonth = baseMonth - 1;
        if (newMonth >= 1) {
          this.setState({ HintMonth: newMonth });
        } else {
          this.setState({ HintMonth: 12 });
        }
        callback();
        break;
      case "Enter":
        if (this.state.HintMonth !== null) {
          this.monthClick(this.state.HintMonth);
          callback();
        }
        break;
      case "Space":
        break;
      case "NumpadEnter":
        if (this.state.HintMonth !== null) {
          this.monthClick(this.state.HintMonth);
          callback();
        }
        break;
      case undefined:
        if (event.type === "wheel") {
          if (this.header2.current.contains(event.target) === true) {
            if (event.deltaY === -100) {
              this.goBackYear();
            } else if (event.deltaY === 100) {
              this.goForwardYear();
            }
          } else {
            if (event.deltaY === -100) {
              newMonth = baseMonth - 1;
              if (newMonth >= 1) {
                this.setState({ HintMonth: newMonth });
              } else {
                this.setState({ HintMonth: 12 });
              }
            } else if (event.deltaY === 100) {
              newMonth = baseMonth + 1;
              if (newMonth <= numberOfMonths) {
                this.setState({ HintMonth: newMonth });
              } else {
                this.setState({ HintMonth: 1 });
              }
            }
          }
          callback();
        }
        break;
      default:
        break;
    }
  };

  yearsKeyMapping = (event, callback) => {
    const years = this.state.FilteredYears;
    let newYear;
    const baseYear =
      this.state.HintYear !== null
        ? this.state.HintYear
        : this.state.SelectedDate.Year !== null
        ? this.state.SelectedDate.Year
        : years[0];

    switch (event.code) {
      case "ArrowUp":
        newYear = baseYear - 4;
        if (newYear >= years[0]) {
          this.setState({ HintYear: newYear });
        } else {
          this.goBackYears();
          this.setState({ HintYear: newYear });
        }
        callback();
        break;
      case "ArrowRight":
        newYear = baseYear + 1;
        if (newYear <= years[years.length - 1]) {
          this.setState({ HintYear: newYear });
        } else {
          this.goForwardYears();
          this.setState({ HintYear: newYear });
        }
        callback();
        break;
      case "ArrowDown":
        newYear = baseYear + 4;
        if (newYear <= years[years.length - 1]) {
          this.setState({ HintYear: newYear });
        } else {
          this.goForwardYears();
          this.setState({ HintYear: newYear });
        }
        callback();
        break;
      case "ArrowLeft":
        newYear = baseYear - 1;
        if (newYear >= years[0]) {
          this.setState({ HintYear: newYear });
        } else {
          this.goBackYears();
          this.setState({ HintYear: newYear });
        }
        callback();
        break;
      case "Enter":
      case "Space":
      case "NumpadEnter":
        if (this.state.HintYear !== null) {
          this.yearClick(this.state.HintYear);
          callback();
        }
        break;
      case undefined:
        if (event.type === "wheel") {
          if (this.header3.current.contains(event.target) === true) {
            if (event.deltaY === -100) {
              this.goBackYears();
            } else if (event.deltaY === 100) {
              this.goForwardYears();
            }
            this.setState({ HintYear: null });
          } else {
            if (event.deltaY === -100) {
              newYear = baseYear - 1;
              if (newYear >= years[0]) {
                this.setState({ HintYear: newYear });
              } else {
                this.goBackYears();
                this.setState({ HintYear: newYear });
              }
            } else if (event.deltaY === 100) {
              newYear = baseYear + 1;
              if (newYear <= years[years.length - 1]) {
                this.setState({ HintYear: newYear });
              } else {
                this.goForwardYears();
                this.setState({ HintYear: newYear });
              }
            }
          }
          callback();
        }
        break;
      default:
        break;
    }
  };

  KeyMapping = (event) => {
    switch (this.state.View) {
      case 0:
        this.daysKeyMapping(event, () => {
          // event.preventDefault();
        });
        break;
      case 1:
        this.monthsKeyMapping(event, () => {
          // event.preventDefault();
        });
        break;
      case 2:
        this.yearsKeyMapping(event, () => {
          // event.preventDefault();
        });
        break;
      default:
        break;
    }
  };

  static getDerivedStateFromProps(newprops, state) {
    if (newprops.disableDates && newprops.disablePastDate) {
      const date = new Date(newprops.disablePastDate);
      const beginDate = {
        Day: date.getDate(),
        Month: date.getMonth() + 1,
        Year: date.getFullYear()
      };

      if (
        beginDate.Day !== state.BeginDate.Day ||
        beginDate.Month !== state.BeginDate.Month ||
        beginDate.Year !== state.BeginDate.Year ||
        beginDate.Hours !== state.BeginDate.Hours ||
        beginDate.Minutes !== state.BeginDate.Minutes
      ) {
        return { BeginDate: beginDate };
      }
    }
    return state;
  }

  dateIsMoreRecenter = (date1, date2) => {
    // if (new Date(date1.Month + "/" + date1.Day + "/" + date1.Year) > new Date(date2.Month + "/" + date2.Day + "/" + date2.Year)) return true;
    // return false;

    if (date1.Year > date2.Year) return true;
    else if (date1.Year < date2.Year) return false;

    if (date1.Month > date2.Month) return true;
    else if (date1.Month < date2.Month) return false;

    if (date1.Day > date2.Day) return true;

    return false;
  };

  dateIsMoreRecent = (date1, date2) => {
    // if (new Date(date1.Month + "/" + date1.Day + "/" + date1.Year) > new Date(date2.Month + "/" + date2.Day + "/" + date2.Year)) return true;
    // return false;

    if (date1.Year > date2.Year) return true;
    else if (date1.Year < date2.Year) return false;

    if (date1.Month > date2.Month) return true;
    else if (date1.Month < date2.Month) return false;

    if (date1.Day > date2.Day) return true;

    return false;
  };

  render() {
    const currentDate = this.state.CurrentDate;
    const selectedDate = this.state.SelectedDate;
    const todayDate = this.state.Today;

    let weekDays = this.props.weekDays;

    let weekStartsAtDay;
    let emptySpaces = [];

    let years = this.state.FilteredYears;

    let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    if (this.state.View === 0) {
      weekStartsAtDay = new Date(
        currentDate.Month + "/1/" + currentDate.Year
      ).getDay();

      for (let i = 0; i < weekStartsAtDay; i++) {
        emptySpaces.push(
          <div key={"e-" + i} className="DatePickerW-DayItem" />
        );
      }
    }

    return (
      <React.Fragment>
        {this.state.View === 0 ? (
          <div
            ref={this.DatePickerContainer}
            data-testid="calendar-popup"
            className="DatePickerW"
          >
            <div ref={this.header} className="DatePickerW-Header">
              <div
                onClick={this.goBackMonth}
                className="DatePickerW-LeftArrow"
              />
              <div
                onClick={() => this.setView(1)}
                className="DatePickerW-Header-Title selectable"
              >
                {this.props.getMonthName(currentDate.Month) +
                  " " +
                  currentDate.Year}
              </div>
              <div
                onClick={this.goForwardMonth}
                className="DatePickerW-RightArrow"
              />
            </div>
            <div className="DatePickerW-Content">
              {weekDays.map((item, i) => (
                <div
                  key={i}
                  className={
                    "DatePickerW-DayItem dayofweek" +
                    (this.props.disableWeekends === true &&
                    (item === weekDays[0] ||
                      item === weekDays[weekDays.length - 1])
                      ? " weekend-d"
                      : "")
                  }
                >
                  {item}
                </div>
              ))}
              {emptySpaces}
              {getNumberOfDays(currentDate.Year, currentDate.Month, true).map(
                (item, i) =>
                  (this.props.disableWeekends === true &&
                    this.isWeekend(
                      item,
                      this.state.CurrentDate.Month,
                      this.state.CurrentDate.Year
                    )) ||
                  (this.props.disablePastDays === true &&
                    this.dateIsMoreRecent(this.state.BeginDate, {
                      ...this.state.CurrentDate,
                      Day: item
                    })) ||
                  (this.props.disableDates &&
                    this.dateIsMoreRecenter(this.state.BeginDate, {
                      ...this.state.CurrentDate,
                      Day: item
                    })) ||
                  (this.props.disableFutureDays === true &&
                    this.dateIsMoreRecent(
                      { ...this.state.CurrentDate, Day: item },
                      this.state.Today
                    )) ||
                  this.checkDisabledDates({
                    ...this.state.CurrentDate,
                    Day: item
                  }) === true ? (
                    <div
                      key={i}
                      className={
                        "DatePickerW-DayItem disabled" +
                        (currentDate.Year === todayDate.Year &&
                        currentDate.Month === todayDate.Month &&
                        item === todayDate.Day
                          ? " today"
                          : "")
                      }
                    >
                      {item}
                    </div>
                  ) : (
                    <div
                      key={i}
                      onClick={() => this.dayClick(item)}
                      className={
                        "DatePickerW-DayItem selectable" +
                        (currentDate.Year === todayDate.Year &&
                        currentDate.Month === todayDate.Month &&
                        item === todayDate.Day
                          ? " today"
                          : "") +
                        (this.state.HintDay !== null &&
                        this.state.HintDay === item
                          ? " hint"
                          : "") +
                        (currentDate.Year === selectedDate.Year &&
                        currentDate.Month === selectedDate.Month &&
                        item === selectedDate.Day
                          ? " selected"
                          : "")
                      }
                    >
                      {item}
                    </div>
                  )
              )}
            </div>
            {!this.props.DisableButtonArea && (
              <div className="DatePickerW-ButtonArea">
                <Button
                  vType="primary-ghost"
                  className="w-100"
                  onClick={this.todayClick}
                >
                  <FormattedMessage id={"TODAY"} />
                </Button>
              </div>
            )}
          </div>
        ) : this.state.View === 1 ? (
          <div
            ref={this.DatePickerContainer}
            data-testid="calendar-popup"
            className="DatePickerW"
          >
            <div
              ref={this.header2}
              style={{
                borderColor: "rgb(30, 115, 240)",
                color: "rgb(30, 115, 240)"
              }}
              className="DatePickerW-Header"
            >
              <div
                onClick={this.goBackYear}
                className="DatePickerW-LeftArrow"
              />
              <div
                onClick={() => this.setView(2)}
                className="DatePickerW-Header-Title selectable"
              >
                {currentDate.Year}
              </div>
              <div
                onClick={this.goForwardYear}
                className="DatePickerW-RightArrow"
              />
            </div>
            <div className="DatePickerW-Content">
              {months.map((item, i) => (
                <div
                  key={i}
                  onClick={() => this.monthClick(item)}
                  className={
                    "DatePickerW-MonthItem selectable" +
                    (currentDate.Year === todayDate.Year &&
                    item === todayDate.Month
                      ? " today"
                      : "") +
                    (this.state.HintMonth !== null &&
                    this.state.HintMonth === item
                      ? " hint"
                      : "") +
                    (currentDate.Year === selectedDate.Year &&
                    item === selectedDate.Month
                      ? " selected"
                      : "")
                  }
                >
                  {this.props.getMonthName(item)}
                </div>
              ))}
            </div>
            {!this.props.DisableButtonArea && (
              <div className="DatePickerW-ButtonArea">
                <Button
                  className="w-100"
                  vType="primary-ghost"
                  onClick={this.todayClick}
                >
                  <FormattedMessage id={"TODAY"} />
                </Button>
              </div>
            )}
          </div>
        ) : (
          this.state.View === 2 && (
            <div
              ref={this.DatePickerContainer}
              data-testid="calendar-popup"
              className="DatePickerW"
            >
              <div ref={this.header3} className="DatePickerW-Header">
                {/* {years[0] >= 1900 && (
                                                                                )} */}
                <div
                  onClick={this.goBackYears}
                  className="DatePickerW-LeftArrow"
                />
                <div className="DatePickerW-Header-Title">
                  {years[0] + "-" + years[years.length - 1]}
                </div>
                <div
                  onClick={this.goForwardYears}
                  className="DatePickerW-RightArrow"
                />
              </div>
              <div className="DatePickerW-Content">
                {years.map((item, i) => (
                  <div
                    key={i}
                    onClick={() => this.yearClick(item)}
                    className={
                      "DatePickerW-YearItem selectable" +
                      (todayDate.Year === item ? " today" : "") +
                      (this.state.HintYear !== null &&
                      this.state.HintYear === item
                        ? " hint"
                        : "") +
                      (selectedDate.Year === item ? " selected" : "")
                    }
                  >
                    {item}
                  </div>
                ))}
              </div>
              {!this.props.DisableButtonArea && (
                <div className="DatePickerW-ButtonArea">
                  <Button
                    className="w-100"
                    vType="primary-ghost"
                    onClick={this.todayClick}
                  >
                    <FormattedMessage id={"TODAY"} />
                  </Button>
                </div>
              )}
            </div>
          )
        )}
      </React.Fragment>
    );
  }
}
