import React, { useMemo } from "react";
import { useSpace } from "../../../Contexts/SpaceContext";
import { usePostQuery } from "../../../Helpers/IOClient";

export const useLastHistoryItem = (schema, id) => {
  const space = useSpace();
  const url = useMemo(() => {
    return `${schema.getEndpoint(space.Id)}/${id}/timeline?$top=1`;
  }, [id, schema, space.Id]);

  const body = useMemo(() => {
    return {};
  }, []);

  return usePostQuery(url, body);
};

export const BaseHistoryUrlContext = React.createContext();
