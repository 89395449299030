import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext
} from "react";
import ReactDom from "react-dom";
import {
  dayMilisec,
  itemHeight,
  itemMargin,
  edgeMarginWidth,
  itemWidthCut,
  createDay,
  useTimelineDrag,
  useTimelineResize,
  useTimeline,
  openTaskForm,
  useTimelineEntityUrl,
  TimelineSettingsContext,
  useTimelineConfig,
  useTimelineZoom,
  TimelineRefetchValue,
  useTimelineSettings
} from "./TimelineHelper";
import moment from "moment";
import Axios from "axios";
import classnames from "classnames";
import { ConnectedAccountProfile } from "../Accounts/AccountProfile";
import { many } from "../../Helpers/SchemaHelper";
import { getEntity, client, useShortPost } from "../../Helpers/IOClient";
import { useDetailAwareParams, useServerAwareState } from "../CGrid/ServerGrid";
import { useTaskCommunicator } from "../../Containers/Tasks/TasksHelper";
import { useSpace } from "../../Contexts/SpaceContext";
import { useToast } from "../Toast/ToastProvider";
import { buildODataFilter } from "../../Helpers/ODataHelper";
import { useMultipleEntityValueSelector } from "../../Hooks/AdvancedEntityHooks";
import ContextMenu, { GridActionContext } from "../ContextMenu/ContextMenu";
import { FormattedMessage, useIntl } from "react-intl";

const getLines = (start, end) => {
  const lineSpace = itemHeight + itemMargin;

  const startLine = Math.ceil(start / lineSpace);
  const endLine = Math.ceil(end / lineSpace);
  return { startLine, endLine };
};

const getRowViewportInfo = (parent, child) => {
  const { offsetHeight, scrollTop } = parent;

  const totalParentHeight = offsetHeight + scrollTop;

  const { offsetTop, offsetHeight: childHeight } = child;

  let start = scrollTop - offsetTop;
  if (start < 0) start = 0;
  let end;
  if (offsetTop + childHeight > offsetHeight + scrollTop) {
    const rest = childHeight + offsetTop - offsetHeight - scrollTop;
    end = childHeight - rest;
  } else {
    end = childHeight;
  }

  const r = getLines(start, end);
  // if(end > childHeight) end = childHeight
  // ;

  if (offsetTop > totalParentHeight) return false;

  if (offsetTop + childHeight - scrollTop < 0) return false;

  return { isInViewport: true, lines: r };
};

window.getRowViewportInfo = getRowViewportInfo;

const useViewportInfo = (subscribeScrollListner, timelineRef, rowRef) => {
  const [isInViewport, setIsInViewport] = useState(false);
  const [lines, setLines] = useState({});
  // const timeoutRef = useRef();
  useEffect(() => {
    const { isInViewport, lines } = getRowViewportInfo(
      timelineRef.current,
      rowRef.current
    );
    setIsInViewport(isInViewport);
    setLines(lines);
    const listner = (e) => {
      const { isInViewport, lines } = getRowViewportInfo(
        e.target,
        rowRef.current
      );
      setIsInViewport(isInViewport);

      setLines((l) => {
        if (
          l &&
          lines &&
          l.startLine === lines.startLine &&
          l.endLine === lines.endLine
        )
          return l;
        return lines;
      });
      // clearTimeout(timeoutRef.current);
      // timeoutRef.current = setTimeout(() => {
      // }, 50);
    };

    const unsub = subscribeScrollListner(listner);

    return () => {
      unsub();
      // clearTimeout(timeoutRef.current);
    };
  }, [subscribeScrollListner, rowRef, timelineRef]);

  return { isInViewport, lines };
};

const selector = (v) => {
  const { CanEdit, Id, BeginDate, EndDate, HasUpdates } = v;

  return {
    CanEdit,
    Id,
    BeginDate,
    HasUpdates,
    EndDate
  };
};

const UpdatePreviewPopup = ({
  isResizing,
  isResizingLeft,
  isDragging,
  task,
  dayDiffrence
}) => {
  const [style, setStyle] = useState();

  useEffect(() => {
    const mouseMoveListner = (e) => {
      const { clientX, clientY } = e;
      setStyle({
        top: `${clientY}px`,
        left: `${clientX}px`
      });
    };
    document.addEventListener("mousemove", mouseMoveListner);

    return () => {
      document.removeEventListener("mousemove", mouseMoveListner);
    };
  }, []);

  const { startFormmated, endFormmated } = useMemo(() => {
    const { BeginDate, EndDate } = task;
    let start;
    let end;
    if (isResizing) {
      if (isResizingLeft) {
        start = new Date(BeginDate).getTime() + dayMilisec * dayDiffrence;
        end = EndDate;
      } else {
        start = BeginDate;
        end = new Date(EndDate).getTime() + dayMilisec * dayDiffrence;
      }
    } else {
      start = new Date(BeginDate).getTime() + dayMilisec * dayDiffrence;
      end = new Date(EndDate).getTime() + dayMilisec * dayDiffrence;
    }
    return {
      startFormmated: moment(start).format("DD/MM/YYYY HH:mm"),
      endFormmated: moment(end).format("DD/MM/YYYY HH:mm")
    };
  }, [dayDiffrence, isResizing, isResizingLeft, task]);

  if (!style) return null;

  const comp = (
    <div
      style={style}
      className="px-2 py-1 bg-black text-white ar-timeline-date-previewer fs-12"
    >
      <div>
        <span>
          <FormattedMessage id="START" />:
        </span>{" "}
        <span className="fw-medium">{startFormmated}</span>
      </div>
      <div>
        <span>
          <FormattedMessage id="END" />:
        </span>{" "}
        <span className="fw-medium">{endFormmated}</span>
      </div>
    </div>
  );

  return ReactDom.createPortal(comp, document.body);
};

const ItemContent = React.memo(
  React.forwardRef((props, ref) => {
    const { styleValues, item } = props;
    const { hasMultipleDays, item: entity, totalDays } = item;

    const [offset, setOffset] = useState(0);
    const [dayDiffrence, setDayDiffrence] = useState(0);

    const setters = useMemo(() => {
      return { setOffset, setDayDiffrence };
    }, []);

    const { Id, CanEdit, HasUpdates } = entity;

    const baseUrl = useTimelineEntityUrl();
    const { schema } = useServerAwareState();
    const [post, { loading }] = useShortPost(`${baseUrl}/${Id}/Dates`, schema);

    const {
      isResizing,
      handleLeftMouseDown,
      handleRightMouseDown,
      isMovingLeft
    } = useTimelineResize(setters, totalDays, entity, post);

    const { isDragging } = useTimelineDrag(
      CanEdit ? ref : null,
      setters,
      entity,
      post
    );

    const style = useMemo(() => {
      const { width: baseWidth, left: baseLeft, top } = styleValues;

      let width;
      let left;

      if (isDragging) {
        left = baseLeft + offset;
        width = baseWidth;
      } else if (isResizing) {
        left = isMovingLeft ? baseLeft + offset : baseLeft;
        width = isMovingLeft ? baseWidth + offset * -1 : baseWidth + offset;
      } else {
        width = baseWidth;
        left = baseLeft;
      }

      return {
        width,
        left,
        top
      };
    }, [styleValues, isDragging, isResizing, offset, isMovingLeft]);

    const { width: zoomWidth } = useTimelineZoom();

    const shadowStyle = useMemo(() => {
      if (!isResizing && !isDragging) return undefined;

      let width;
      let left;

      const { width: baseWidth, left: baseLeft, top } = styleValues;
      if (isResizing) {
        width = isMovingLeft
          ? baseWidth + dayDiffrence * -1 * zoomWidth
          : baseWidth + dayDiffrence * zoomWidth;
        left = isMovingLeft ? baseLeft + dayDiffrence * zoomWidth : baseLeft;
      } else if (isDragging) {
        width = baseWidth;
        left = baseLeft + dayDiffrence * zoomWidth;
      }

      return {
        width,
        left,
        top
      };
    }, [
      dayDiffrence,
      isDragging,
      isMovingLeft,
      isResizing,
      styleValues,
      zoomWidth
    ]);

    const { ItemModel, MinimalItemModel } = useContext(TimelineSettingsContext);

    const ResolvedItemModel =
      MinimalItemModel && style.width < 220 ? MinimalItemModel : ItemModel;

    return (
      <>
        {(isResizing || isDragging) && (
          <div className="ar-timeline-item-shadow" style={shadowStyle}></div>
        )}
        {(isResizing || isDragging) && !loading && (
          <UpdatePreviewPopup
            isResizing={isResizing}
            isResizingLeft={isMovingLeft}
            isDragging={isDragging}
            dayDiffrence={dayDiffrence}
            task={entity}
          />
        )}
        <div
          ref={ref}
          style={{ ...style, boxShadow: HasUpdates && "0 0 0 2px orange" }}
          className={classnames(
            "ar-timeline-item d-flex flex-column justify-content-between p-1 px-2",
            {
              resizing: isResizing,
              "skeleton-inverted-pulse": loading,
              dragging: isDragging
            }
          )}
        >
          <ResolvedItemModel hasMultipleDays={hasMultipleDays} id={entity.Id} />
          {CanEdit && !loading && !isDragging && (
            <>
              <div
                onMouseDown={handleLeftMouseDown}
                className="ar-timeline-item-handle"
              />
              <div
                onMouseDown={handleRightMouseDown}
                className="ar-timeline-item-handle right"
              />
            </>
          )}
        </div>
      </>
    );
  })
);

const Item = ({ item }) => {
  const { now, width: rowWidth, itemsToLeft } = useTimelineRowsContext();
  const { dateUnit } = useTimelineConfig();
  const ContextChildren = useContext(GridActionContext);
  const { width: zoomWidth } = useTimelineZoom();
  const ItemContainerRef = useRef();
  const styleValues = useMemo(() => {
    const { line, totalDays, start } = item;
    const diff = moment(start).diff(moment(now), dateUnit);

    const daysForward = diff;

    let left = itemsToLeft * zoomWidth + itemWidthCut;
    // console.log(`left: ${left}`)
    left += daysForward * zoomWidth;
    // if (dateUnit === "days") {
    // }

    let top = 0;
    if (line > 1) top = (line - 1) * (itemHeight + itemMargin);
    top += edgeMarginWidth;

    let width = totalDays * zoomWidth - itemWidthCut * 2;

    const maxWidth = rowWidth - left - itemWidthCut * 2 - zoomWidth;

    if (maxWidth <= 0) return null;

    if (width > maxWidth) width = maxWidth;

    return {
      width,
      left,
      top
    };
  }, [dateUnit, item, itemsToLeft, now, rowWidth, zoomWidth]);

  if (!styleValues) return null;

  return (
    <>
      <ItemContent
        ref={ItemContainerRef}
        styleValues={styleValues}
        item={item}
      />
      {ContextChildren && (
        <ContextMenu targetRef={ItemContainerRef}>
          <ContextChildren entity={item.item} />
        </ContextMenu>
      )}
    </>
  );
};

const RowItemLine = React.memo(function RowItemLine({ line }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = requestAnimationFrame(() => {
      setShow(true);
    });

    return () => cancelAnimationFrame(timeout);
  }, []);

  if (!show || !line) return null;

  return line.map((v) => {
    return <Item key={v.item.Id} item={v} />;
  });
});

const RowItems = React.memo(function RowItems({
  items,
  itemsArr,
  isInViewport,
  lines
}) {
  const children = useMemo(() => {
    if (!itemsArr || !lines || !isInViewport) return null;
    const arr = [];

    for (let i = lines.startLine; i <= lines.endLine; i++) {
      arr.push(<RowItemLine key={i} line={itemsArr[i]} />);
    }

    return arr;
  }, [isInViewport, itemsArr, lines]);

  return (
    <div className="ar-timeline-row-items">
      {children}
      {/* {items &&
        isInViewport &&
        items.map(v => (
          <Item
            width={width}
            nowMilisec={nowMilisec}
            itemsToLeft={itemsToLeft}
            // weeksToRight={weeksToRight}
            key={v.item.Id}
            item={v}
          />
        ))} */}
    </div>
  );
});

const RowUser = React.memo(function RowUser({ user }) {
  return (
    <div className="ar-timeline-grid-item ar-timeline-user overflow-hidden">
      {/* <div>{user}</div> */}
      <div>
        <div className="ar-timeline-user-content p-3">
          <ConnectedAccountProfile account={user} />
        </div>
      </div>
    </div>
  );
});

const useMultipleSearchQueries = (
  periods,
  user,
  { requestCallbacks, requestFilters, canFetch }
) => {
  const successPeriodsRef = useRef({});
  const hasPeriodsRef = useRef(false);

  const [periodResults, setPeriodResults] = useState({});

  const { schema, params, moduleType } = useServerAwareState();

  const { query } = params;

  const handleUpdateOrCreation = useCallback(
    ({ id: taskId }) => {
      //;
      const task = getEntity(schema, taskId);
      const { Manager, BeginDate, EndDate } = task;
      if (Manager !== user) return;

      setPeriodResults((periodResults) => {
        let hasChanged = false;

        const newPeriodResults = {};

        for (const periodStart in successPeriodsRef.current) {
          const start = periodStart;
          const end = periodStart + dayMilisec * (21 - 1);

          const beginMilsecs = createDay(BeginDate).getTime();
          const endMilisecs = createDay(EndDate).getTime();

          if (
            (beginMilsecs >= start && beginMilsecs <= end) ||
            (endMilisecs >= start && endMilisecs <= end)
          ) {
            hasChanged = true;
            if (periodResults[start]) {
              newPeriodResults[start] = new Set([
                ...periodResults[start],
                taskId
              ]);
            } else {
              newPeriodResults[start] = [taskId];
            }
          }
        }
        if (hasChanged) return { ...periodResults, ...newPeriodResults };
        else return periodResults;
      });

      // setResults(r => [...r, taskId]);
    },
    [schema, user]
  );

  useTaskCommunicator(handleUpdateOrCreation);

  const prevFiltersRef = useRef(requestFilters);

  const space = useSpace();
  const top = 25;
  const { advancedFilters } = useDetailAwareParams(params, moduleType);
  const createToast = useToast();
  const toastRef = useRef();
  toastRef.current = createToast;

  const requestSourcesRef = useRef([]);

  const baseUrl = useTimelineEntityUrl();

  const responseSchema = useMemo(() => {
    return {
      d: {
        results: many(schema)
      }
    };
  }, [schema]);

  const settings = useContext(TimelineSettingsContext);
  const isBuilt = useRef(false);
  const refetchVal = useContext(TimelineRefetchValue);
  const previousRefetchValRef = useRef(refetchVal);
  const intl = useIntl();
  useEffect(() => {
    if (!periods || !canFetch) return;

    if (requestFilters !== prevFiltersRef.current) {
      prevFiltersRef.current = requestFilters;
      successPeriodsRef.current = {};
      // setResults([]);
      setPeriodResults({});
    }

    // const requestSources = [];
    const start = () => {
      const { onRequestStart, onRequestEnd } = requestCallbacks;

      const request = (period, depth = 0) => {
        //usar start do period como key para possiblitar lazy loading horizontal
        const { startFormmated, endFormmated, start: key } = period;

        const skip = depth * top;

        const {
          baseField,
          BeginDateField = "BeginDateNoHours",
          EndDateField = "EndDateNoHours"
        } = settings;

        let filter = buildODataFilter(requestFilters, false, true);
        filter = filter.substring(9, filter.length - 1);
        const timelineFilter = `(Assigned/Id eq '${user}') and ((betweenFields(${baseField}/${BeginDateField},${baseField}/${EndDateField},datetime'${startFormmated}')) or (between(${baseField}/${BeginDateField},datetime'${startFormmated}',datetime'${endFormmated}')) or (between(${baseField}/${EndDateField},datetime'${startFormmated}',datetime'${endFormmated}')))`;

        filter = filter ? `${filter} and ${timelineFilter}` : timelineFilter;
        let url = `${baseUrl}/?$inlinecount=allpages&$orderby=${baseField}/EndDate asc&$skip=${skip}&$top=${top}&$filter=(${filter})`;
        if (query) url += `&query=${query}`;
        if (advancedFilters && advancedFilters.length > 0)
          url += `&customFilters=${advancedFilters.join(",")}`;
        const source = Axios.CancelToken.source();
        requestSourcesRef.current.push(source);
        const removeSource = () => {
          const index =
            requestSourcesRef.current &&
            requestSourcesRef.current.indexOf(source);
          requestSourcesRef.current.splice(index, 1);
        };

        client.get(url, {
          schema: responseSchema,
          // cache: false,
          cancelToken: source.token,
          onSuccess: ({ data }) => {
            removeSource();

            setPeriodResults((periodResults) => {
              let newPeriodValue;
              if (!periodResults[key]) {
                newPeriodValue = data.d.results;
              } else {
                newPeriodValue = [
                  ...new Set([...periodResults[key], ...data.d.results])
                ];
              }
              return { ...periodResults, [key]: newPeriodValue };
            });

            // setResults(r => {
            //   let arr = [...new Set([...r, ...data.d.results])];
            //   // arr = arr.sort(
            //   //   (a, b) => new Date(a.BeginDate) - new Date(b.BeginDate)
            //   // );
            //   // console.log(arr);
            //   return arr;
            // });

            const count = data.d.__count;

            if (count > top * (depth + 1)) {
              request(period, depth + 1);
            } else {
              onRequestEnd();
            }
          },
          onError: () => {
            removeSource();
            onRequestEnd();
            toastRef.current({
              pos: "tm",
              type: "danger",
              // description: `Houve um erro ao atualizar a timeline`,
              description: intl.formatMessage({ id: "ERROR_HAPPEND" }),
              title: `Erro`
            });
          }
        });
      };

      for (const period of periods) {
        const { start } = period;
        if (
          successPeriodsRef.current[start] &&
          refetchVal === previousRefetchValRef.current
        )
          continue;
        successPeriodsRef.current[start] = true;
        onRequestStart();
        request(period);
      }
    };

    // if (hasPeriodsRef.current) {
    //   const timeout = setTimeout(start, 200);
    //   return () => clearTimeout(timeout);
    // }

    isBuilt.current = true;
    start();
    hasPeriodsRef.current = true;
    previousRefetchValRef.current = refetchVal;
  }, [
    advancedFilters,
    baseUrl,
    canFetch,
    intl,
    periods,
    query,
    refetchVal,
    requestCallbacks,
    requestFilters,
    responseSchema,
    settings,
    space.Id,
    user
  ]);

  //clean sources
  useEffect(() => {
    const cancelSources = () => {
      if (requestSourcesRef.current.length === 0) return;

      for (const s of requestSourcesRef.current) {
        s.cancel();
      }
    };

    return () => cancelSources();
  }, []);

  return periodResults;
};

const buildItems = (results, config) => {
  const arr = [];
  const lineArrDict = [];
  const lineArr = [];

  const { dateUnit } = config;

  if (results.length === 0) return { totalLines: 0, items: arr };

  for (const entity of results) {
    const { BeginDate, EndDate } = entity;
    const start = createDay(BeginDate);
    // start.setDate(1)

    const end = createDay(EndDate);
    // end.setDate(1)

    if (dateUnit === "months") {
      start.setDate(1);
      end.setDate(1);
    }

    const startMilisecs = start.getTime();
    let endMilisecs = end.getTime();

    // if (endMilisecs === startMilisecs) endMilisecs += dayMilisec;
    endMilisecs += dayMilisec;

    const duration = endMilisecs - startMilisecs;

    const totalDays = moment(end).diff(moment(start), dateUnit) + 1;

    let line;

    for (const item of lineArrDict) {
      const { endMilisecs: iEndMilisecs } = item;

      if (startMilisecs >= iEndMilisecs) {
        line = item.line;
        break;
      }
    }

    if (!line) line = lineArrDict.length + 1;

    const hasMultipleDays = startMilisecs !== endMilisecs;
    const obj = {
      totalDays,
      duration,
      start,
      startMilisecs,
      endMilisecs,
      end,
      item: entity,
      line,
      hasMultipleDays
    };

    if (!lineArrDict[line - 1]) {
      lineArrDict.push(obj);
    } else {
      lineArrDict[line - 1] = obj;
    }

    const lineArrIndex = line - 1;

    if (!lineArr[lineArrIndex]) lineArr[lineArrIndex] = [];

    lineArr[lineArrIndex].push(obj);
    arr.push(obj);
  }

  return { totalLines: lineArrDict.length, itemsArr: lineArr, items: arr };
};

const Row = ({
  user,
  periods,
  requestCallbacks,
  requestFilters,
  subscribeScrollListner,
  timelineRef
}) => {
  // $filter=Task/BeginDateNoHours gt datetime'2019-08-13' and Task/BeginDateNoHours lt datetime'2019-08-19'&$top=50"
  const rowRef = useRef();
  const { isInViewport, lines } = useViewportInfo(
    subscribeScrollListner,
    timelineRef,
    rowRef
  );

  const { schema } = useServerAwareState();

  const periodResults = useMultipleSearchQueries(periods, user, {
    requestCallbacks,
    requestFilters,
    canFetch: isInViewport
  });

  const results = useMemo(() => {
    if (!periods) return [];
    let set = new Set();
    for (const period of periods) {
      //use start as key
      const { start: key } = period;

      if (periodResults[key]) {
        set = new Set([...set, ...periodResults[key]]);
      }
    }
    return [...set];
  }, [periodResults, periods]);

  const config = useTimelineConfig();

  // const resolvedSelector = useMemo(() => {
  //   const {dateUnit} = config

  //   if(dateUnit === "months"){
  //     return (value) => {
  //       const {BeginDate, EndDate} = selector(value)

  //       const BeginDate

  //     }
  //   }

  // },[])

  const connectedResults = useMultipleEntityValueSelector(
    schema,
    results,
    selector
  );

  const sortedResults = useMemo(() => {
    const arr = [...connectedResults];
    arr.sort((a, b) => new Date(a.BeginDate) - new Date(b.BeginDate));
    return arr;
  }, [connectedResults]);
  // console.log(sortedResults)

  const {
    totalLines = 0,
    items,
    itemsArr
  } = useMemo(() => {
    return buildItems(sortedResults, config);
  }, [sortedResults, config]);

  let style;
  if (totalLines) {
    const edgeHeight = edgeMarginWidth;
    const itemsHeight = (itemHeight + itemMargin) * totalLines;
    const height = edgeHeight + itemsHeight;
    style = {
      minHeight: `${height}px`
    };
  }
  const timeline = useTimeline();
  const { width: zoomWidth } = useTimelineZoom();
  const { RowButton } = useTimelineSettings();
  const { onClick } = RowButton || {};
  const { dateUnit } = useTimelineConfig();

  const handleClick = useCallback(
    (e) => {
      const { clientX } = e;
      const { domRef, startDateObj } = timeline;
      const elem = domRef.current;
      const { scrollLeft } = elem;
      const { left } = elem.getBoundingClientRect();

      const clickOffset = clientX - left;

      const position = clickOffset + scrollLeft - userWidth;
      const daysAhead = Math.floor(position / zoomWidth);

      // const startDate = new Date(startDateObj.key);
      const startDate = moment(startDateObj.key)
        .add(daysAhead, dateUnit)
        .toDate();

      // startDate.setDate(startDate.getDate() + daysAhead - 1);

      // const startDate = new Date(
      //   startDateObj.key + daysAhead * dayMilisec - dayMilisec
      // );
      if (onClick) onClick(startDate);
      else openTaskForm(startDate, { Manager: user });
    },
    [dateUnit, onClick, timeline, user, zoomWidth]
  );
  return (
    <div
      ref={rowRef}
      onDoubleClick={handleClick}
      className="ar-timeline-row"
      style={style}
    >
      <RowUser user={user} />
      <RowItems
        lines={lines}
        isInViewport={isInViewport}
        itemsArr={itemsArr}
        items={items}
      />
    </div>
  );
};

const TimlineRowsContext = React.createContext();
const useTimelineRowsContext = () => useContext(TimlineRowsContext);
const userWidth = 231;
const TimelineRows = React.memo(function Rows({
  users,
  times,
  itemsToLeft,
  nowMilisec,
  now,
  periods,
  requestCallbacks,
  requestFilters,
  subscribeScrollListner,
  timelineRef
}) {
  const { width: zoomWidth } = useTimelineZoom();
  const width = times.length * zoomWidth + userWidth;
  const style = {
    width: `${width}px`
  };

  const contextValue = useMemo(() => {
    return {
      width,
      nowMilisec,
      now,
      itemsToLeft
    };
  }, [itemsToLeft, now, nowMilisec, width]);

  if (!users || users.length === 0)
    return (
      <div className="ar-timeline-rows flex-1">
        <div className="ar-timeline-row">
          <div className="ar-timeline-grid-item ar-timeline-user">
            <div />
          </div>
          <div className="ar-timeline-row-items"></div>
        </div>
      </div>
    );

  return (
    <div style={style} className="ar-timeline-rows flex-1">
      <TimlineRowsContext.Provider value={contextValue}>
        {users.map((u) => (
          <Row
            subscribeScrollListner={subscribeScrollListner}
            timelineRef={timelineRef}
            requestFilters={requestFilters}
            requestCallbacks={requestCallbacks}
            periods={periods}
            key={u}
            user={u}
            times={times}
          />
        ))}
      </TimlineRowsContext.Provider>
    </div>
  );
});

export default TimelineRows;
