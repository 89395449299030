import React, { useCallback, useState, useRef, useContext } from "react";
import "./Pdf.css";
import {
  isAdmin,
  hasModulePermission,
  modulePermissions
} from "../../Helpers/ModulesHelper";
import ArDatePicker from "../DatePicker/ArDatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowRight,
  faFileExcel,
  faFilePdf,
  faEnvelope
} from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import { useSpace } from "../../Contexts/SpaceContext";
import Popup, { usePopupOpenState } from "../Popup/Popup";
import moment from "moment";
import { FormButtonSkeleton } from "../../Containers/Form/FormButtons";
import api from "../../api";
import Button from "../Button/Button";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import LoadableButton from "../Button/LoadableButton";
import querystring from "query-string";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import Checkbox from "../Checkbox/Checkbox";
import {
  useDownloadData,
  useDownloadExcelData,
  handleError
} from "../../Helpers/MiscHelper";
import { useToast } from "../Toast/ToastProvider";
import { FormattedMessage, useIntl } from "react-intl";
import { useMenuContext } from "../ContextMenu/ContextMenu";
import { useConnectionId } from "../../Containers/RealTime/RealTime";
import { EntityPopupContext } from "../EntityPopup/EntityPopupHelper";
import { useServerAwareState } from "../CGrid/ServerGrid";
import { useUnreadEntitiesFetcher } from "../UnreadEntities/UnreadEntities";
import { useSpaceQuery } from "../../Helpers/IOClient";
import {
  spaceExportsSchema,
  spaceAccountExportsSchema
} from "../../config/schema";

export const usePdfPermission = (moduleType) => {
  const accountSpace = useCurrentAccountSpace();
  const { Modules } = accountSpace;
  const hasAccess =
    isAdmin(accountSpace) ||
    hasModulePermission(Modules[moduleType], modulePermissions.Export);
  return hasAccess;
};

export const PdfDatePicker = ({ state, className }) => {
  const [{ toDate, fromDate }, setState] = state;

  const handleToDateChange = useCallback(
    (v) => {
      setState((s) => {
        return {
          ...s,
          toDate: v
        };
      });
    },
    [setState]
  );

  const handleFromDateChange = useCallback(
    (v) => {
      setState((s) => {
        return {
          ...s,
          fromDate: v
        };
      });
    },
    [setState]
  );

  // if (type === reportDateTypes.last7Days) return <div>last 7 days</div>;

  return (
    <div
      className={classnames("d-flex align-items-center", className)}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <ArDatePicker
        size="sm"
        className="mr-2"
        value={fromDate}
        enableHours={false}
        onChange={handleFromDateChange}
      />
      <FontAwesomeIcon className="text-primary mr-2" icon={faLongArrowRight} />
      <ArDatePicker
        size="sm"
        value={toDate}
        enableHours={false}
        onChange={handleToDateChange}
      />
    </div>
  );
};

export const createProjectPdfButton = ({
  generateUrl,
  buildUrl,
  chooseDate = true,
  moduleType,
  download = false,
  url
}) => {
  const InterventionPdfButton = ({
    id,
    skeleton,
    fromSettings,
    link,
    excel,
    LineButton,
    className,
    buttonStyle,
    directUrl
  }) => {
    const space = useSpace();
    const createToast = useToast();
    const disabled = !usePdfPermission(moduleType);
    const [isLoading, setIsLoading] = useState(false);
    const anchorRef = useRef();
    const intl = useIntl();
    const popupRef = useRef();
    const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
    const state = useState(() => {
      const now = moment();
      const fromDate = now.startOf("month").toDate();
      const toDate = now.endOf("month").toDate();
      return {
        fromDate,
        toDate
      };
    });
    const { refetch } = useServerAwareState() || {};
    const unreaddUpdater = useUnreadEntitiesFetcher();
    const resolvedLink = useMenuContext() || link;
    const providedOnSuccess = useContext(EntityPopupContext);
    const conId = useConnectionId();
    const downloadExcelData = useDownloadExcelData();
    const downloadData = useDownloadData();
    const [exportTypeEnum, setExportTypeEnum] = useState([1, 2, 4, 8]);
    const ExportNumSum = exportTypeEnum.reduce((a, b) => a + b, 0);

    if (skeleton) return <FormButtonSkeleton size="sm" />;
    const handleExport = () => {
      setIsLoading(true);
      const [{ fromDate, toDate }] = state;
      const fToDate = moment(toDate).format("YYYY-MM-DD");
      const fFromDate = moment(fromDate).format("YYYY-MM-DD");

      const queryObj = {
        fromDate: fFromDate,
        toDate: fToDate,
        exportTypeEnum: exportTypeEnum.reduce((a, b) => a + b, 0)
      };

      const qString = querystring.stringify(queryObj);

      let baseUrl =
        directUrl || `spaces/${space.Id}/${generateUrl({ id, excel, url })}`;
      baseUrl += fromSettings ? "" : `?${qString}`;
      const timeString = moment().format("YYYY-MM-DDTHH:mm:ss");
      api
        .get(baseUrl, {
          responseType: "arraybuffer",
          headers: {
            ConnectionID: conId
          }
        })
        .then((r) => {
          if (!/ToFile/.test(baseUrl)) {
            if (excel) downloadExcelData(r, `${timeString}.xlsx`);
            else downloadData(r, `${timeString}.pdf`);
            refetch && refetch();
            unreaddUpdater && unreaddUpdater();
          }
          setIsLoading(false);
          if (/ToFile/.test(baseUrl)) {
            toggleIsOpen(false);
            createToast({
              pos: "tm",
              type: "info",
              description: intl.formatMessage({
                id: "API_EXPORT_STARTED"
              })
            });
            providedOnSuccess && providedOnSuccess();
          }
        })
        .catch((e) => {
          handleError(createToast, "Ocorreu um erro a exportar");
          setIsLoading(false);
        });
    };
    return (
      <>
        <Popup
          domRef={popupRef}
          anchorEl={anchorRef.current}
          isOpen={isOpen}
          onClick={(e) => {
            e.nativeEvent.stopImmediatePropagation();
          }}
          className="ar-pdf-popup"
          placement="auto"
        >
          <div className="py-3 px-4 bg-white">
            <div style={{ fontSize: 14 }} className="mb-2 ml-0 label-title">
              <FormattedMessage id="DATA_TO_EXPORT" />
            </div>
            <PdfDatePicker checked={exportTypeEnum[0]} state={state} />
            <div style={{ fontSize: 14 }} className=" mt-2 ml-0 label-title">
              <FormattedMessage id="MORE_OPTIONS" />
            </div>
            <div className="text-black mt-3">
              <div>
                <Checkbox
                  onChange={() => {
                    let temp = [...exportTypeEnum];
                    if (temp[0]) temp[0] = 0;
                    else temp[0] = 1;
                    setExportTypeEnum(temp);
                  }}
                  checked={exportTypeEnum[0]}
                  textValue={intl.formatMessage({
                    id: "EXPORT_NOT_CLASSIFIED"
                  })}
                />
              </div>
              <div>
                <Checkbox
                  onChange={() => {
                    let temp = [...exportTypeEnum];
                    if (temp[1]) temp[1] = 0;
                    else temp[1] = 2;
                    setExportTypeEnum(temp);
                  }}
                  checked={exportTypeEnum[1]}
                  textValue={intl.formatMessage({ id: "CLASSIFIED" })}
                />
              </div>
              <div>
                <Checkbox
                  onChange={() => {
                    let temp = [...exportTypeEnum];
                    if (temp[2]) temp[2] = 0;
                    else temp[2] = 4;
                    setExportTypeEnum(temp);
                  }}
                  checked={exportTypeEnum[2]}
                  textValue={intl.formatMessage({ id: "BILLED" })}
                />
              </div>
              <div>
                <Checkbox
                  onChange={() => {
                    let temp = [...exportTypeEnum];
                    if (temp[3]) temp[3] = 0;
                    else temp[3] = 8;
                    setExportTypeEnum(temp);
                  }}
                  checked={exportTypeEnum[3]}
                  textValue={intl.formatMessage({
                    id: "CLASSIFIED_BUT_OTHER_ENTITY"
                  })}
                />
              </div>
            </div>
            <LoadableButton
              disabled={ExportNumSum === 0}
              onClick={handleExport}
              className="mt-3"
              isLoading={isLoading}
            >
              {intl.formatMessage({ id: "EXPORT" })}
            </LoadableButton>
          </div>
        </Popup>
        <Button
          ref={anchorRef}
          style={buttonStyle}
          title={
            !excel
              ? `${intl.formatMessage({ id: "EXPORT" })} PDF`
              : `${intl.formatMessage({ id: "EXPORT" })} XLS`
          }
          onClick={!chooseDate ? handleExport : () => toggleIsOpen(true)}
          disabled={disabled || isLoading}
          className={classnames(
            {
              "ssi-button-grid sm": !resolvedLink,
              "ssi-button-link text-left": resolvedLink
            },
            className
          )}
          vType={resolvedLink ? null : "primary-ghost"}
        >
          {LineButton ? (
            <div className="mr-1 ar-form-button-link-icon">
              {!excel ? (
                isLoading && !resolvedLink ? (
                  <LoaderSpinner />
                ) : (
                  <FontAwesomeIcon icon={faFilePdf} />
                )
              ) : isLoading && !resolvedLink ? (
                <LoaderSpinner />
              ) : (
                <FontAwesomeIcon icon={faFileExcel} />
              )}
            </div>
          ) : !excel ? (
            isLoading && !resolvedLink ? (
              <LoaderSpinner />
            ) : (
              <FontAwesomeIcon icon={faFilePdf} />
            )
          ) : isLoading && !resolvedLink ? (
            <LoaderSpinner />
          ) : (
            <FontAwesomeIcon icon={faFileExcel} />
          )}
          {resolvedLink && (
            <span className="fw-regular fs-14 ml-3">
              {!excel
                ? `${intl.formatMessage({ id: "EXPORT" })} PDF`
                : `${intl.formatMessage({ id: "EXPORT" })} XLS`}
            </span>
          )}
        </Button>
      </>
    );
  };

  return InterventionPdfButton;
};

export const createPdfButton = ({
  generateUrl,
  buildUrl,
  chooseDate = true,
  moduleType,
  download = false,
  url
}) => {
  const InterventionPdfButton = ({
    id,
    skeleton,
    fromSettings,
    link,
    excel,
    LineButton,
    moduleTypeExtra,
    InsideUnreadButton,
    className,
    buttonStyle,
    directUrl,
    payload
  }) => {
    const intl = useIntl();
    const space = useSpace();
    const resolvedLink = useMenuContext() || link;
    const createToast = useToast();
    const disabled = !usePdfPermission(moduleType || moduleTypeExtra);
    const [isLoading, setIsLoading] = useState(false);
    const anchorRef = useRef();
    const popupRef = useRef();
    const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
    const state = useState(() => {
      const now = moment();
      const fromDate = now.startOf("month").toDate();
      const toDate = now.endOf("month").toDate();
      return {
        fromDate,
        toDate
      };
    });
    const conId = useConnectionId();
    const { refetch } = useSpaceQuery(
      `export/user/${id}`,
      spaceAccountExportsSchema,
      {
        cache: false,
        autoFetch: false
      }
    );
    const unreaddUpdater = useUnreadEntitiesFetcher();
    const providedOnSuccess = useContext(EntityPopupContext);
    const downloadExcelData = useDownloadExcelData();
    const downloadData = useDownloadData();
    const [exportTypeEnum, setExportTypeEnum] = useState([1, 2, 4, 8]);
    const ExportNumSum = exportTypeEnum.reduce((a, b) => a + b, 0);
    if (skeleton) return <FormButtonSkeleton size="sm" />;

    const handleExport = () => {
      setIsLoading(true);

      const [{ fromDate, toDate }] = state;

      if (Array.isArray(id) && id.length > 50) {
        handleError(
          createToast,
          `Não é possível exportar os ${id.length} selecionados. O máximo permitido é 50.`
        );
        setIsLoading(false);
        return;
      }

      const fToDate = moment(toDate).format("YYYY-MM-DD");
      const fFromDate = moment(fromDate).format("YYYY-MM-DD");

      const queryObj = {
        fromDate: fFromDate,
        toDate: fToDate,
        himself: 1,
        exportTypeEnum: exportTypeEnum.reduce((a, b) => a + b, 0)
      };

      const qString = querystring.stringify(queryObj);

      let baseUrl =
        directUrl || `spaces/${space.Id}/${generateUrl({ id, url, excel })}`;
      baseUrl += fromSettings ? "" : `?${qString}`;

      const timeString = moment().format("YYYY-MM-DDTHH:mm:ss");
      api
        .post(
          baseUrl,
          {
            Data: payload
              ? JSON.parse(payload)
              : id
              ? Array.isArray(id)
                ? id
                : [id]
              : undefined
          },
          {
            responseType: "arraybuffer",
            headers: {
              ConnectionID: conId
            }
          }
        )
        .then((r) => {
          if (!/ToFile/.test(baseUrl)) {
            if (excel) downloadExcelData(r, `${timeString}.xlsx`);
            else downloadData(r, `${timeString}.pdf`);
            refetch && refetch();
            unreaddUpdater && unreaddUpdater();
          }

          setIsLoading(false);
          if (/ToFile/.test(baseUrl)) {
            toggleIsOpen(false);
            createToast({
              pos: "tm",
              type: "info",
              description: intl.formatMessage({
                id: "API_EXPORT_STARTED"
              })
            });
            providedOnSuccess && providedOnSuccess();
          }
        })
        .catch((e) => {
          handleError(createToast, "Ocorreu um erro a exportar");
          setIsLoading(false);
        });
    };

    if (InsideUnreadButton) {
      return (
        <>
          <Popup
            domRef={popupRef}
            anchorEl={anchorRef.current}
            isOpen={isOpen}
            onClick={(e) => {
              e.nativeEvent.stopImmediatePropagation();
            }}
            onDoubleClick={(e) => {
              e.stopPropagation();
            }}
            className="ar-pdf-popup"
            placement="right"
          >
            <div className="py-3 px-4 bg-white">
              <div style={{ fontSize: 14 }} className="mb-2 ml-0 label-title">
                <FormattedMessage id="DATA_TO_EXPORT" />
              </div>
              <PdfDatePicker checked={exportTypeEnum[0]} state={state} />
              <div style={{ fontSize: 14 }} className=" mt-2 ml-0 label-title">
                <FormattedMessage id="MORE_OPTIONS" />
              </div>
              <div className="text-black mt-3">
                <div>
                  <Checkbox
                    onChange={() => {
                      let temp = [...exportTypeEnum];
                      if (temp[0]) temp[0] = 0;
                      else temp[0] = 1;
                      setExportTypeEnum(temp);
                    }}
                    checked={exportTypeEnum[0]}
                    textValue={intl.formatMessage({
                      id: "EXPORT_NOT_CLASSIFIED"
                    })}
                  />
                </div>
                <div>
                  <Checkbox
                    onChange={() => {
                      let temp = [...exportTypeEnum];
                      if (temp[1]) temp[1] = 0;
                      else temp[1] = 2;
                      setExportTypeEnum(temp);
                    }}
                    checked={exportTypeEnum[1]}
                    textValue={intl.formatMessage({ id: "CLASSIFIED" })}
                  />
                </div>
                <div>
                  <Checkbox
                    onChange={() => {
                      let temp = [...exportTypeEnum];
                      if (temp[2]) temp[2] = 0;
                      else temp[2] = 4;
                      setExportTypeEnum(temp);
                    }}
                    checked={exportTypeEnum[2]}
                    textValue={intl.formatMessage({ id: "BILLED" })}
                  />
                </div>
                <div>
                  <Checkbox
                    onChange={() => {
                      let temp = [...exportTypeEnum];
                      if (temp[3]) temp[3] = 0;
                      else temp[3] = 8;
                      setExportTypeEnum(temp);
                    }}
                    checked={exportTypeEnum[3]}
                    textValue={intl.formatMessage({
                      id: "CLASSIFIED_BUT_OTHER_ENTITY"
                    })}
                  />
                </div>
              </div>
              <LoadableButton
                disabled={ExportNumSum === 0}
                onClick={handleExport}
                className="mt-3"
                isLoading={isLoading}
              >
                {intl.formatMessage({ id: "EXPORT" })}
              </LoadableButton>
            </div>
          </Popup>
          <Button
            ref={anchorRef}
            style={buttonStyle}
            title={
              !excel
                ? `${intl.formatMessage({ id: "EXPORT" })} PDF`
                : `${intl.formatMessage({ id: "EXPORT" })} XLS`
            }
            onClick={
              !chooseDate
                ? handleExport
                : (e) => {
                    e.stopPropagation();
                    toggleIsOpen(true);
                  }
            }
            disabled={disabled || isLoading}
            className={"p-1 AlignLeftLoadableButton border-radius-0"}
            vType={"link"}
          >
            <div className="d-flex align-items-center justify-content-start">
              {LineButton ? (
                !excel ? (
                  isLoading ? (
                    <LoaderSpinner size="xxs" />
                  ) : (
                    <FontAwesomeIcon icon={faFilePdf} />
                  )
                ) : isLoading ? (
                  <LoaderSpinner size="xxs" />
                ) : (
                  <FontAwesomeIcon icon={faFileExcel} />
                )
              ) : !excel ? (
                isLoading ? (
                  <LoaderSpinner size="xxs" />
                ) : (
                  <FontAwesomeIcon icon={faFilePdf} />
                )
              ) : isLoading ? (
                <LoaderSpinner size="xxs" />
              ) : (
                <FontAwesomeIcon icon={faFileExcel} />
              )}

              {resolvedLink && (
                <span className="fw-regular fs-14 ml-3">
                  {!excel ? (
                    <FormattedMessage id="EXPORT_PDF" />
                  ) : (
                    <FormattedMessage id="EXPORT_EXCEL" />
                  )}
                </span>
              )}
            </div>
          </Button>
        </>
      );
    }

    return (
      <>
        <Popup
          domRef={popupRef}
          anchorEl={anchorRef.current}
          isOpen={isOpen}
          onClick={(e) => {
            e.nativeEvent.stopImmediatePropagation();
          }}
          onDoubleClick={(e) => {
            e.stopPropagation();
          }}
          className="ar-pdf-popup"
          placement="right"
        >
          <div className="py-3 px-4 bg-white">
            <div style={{ fontSize: 14 }} className="mb-2 ml-0 label-title">
              <FormattedMessage id="EXPORT_DATE" />
            </div>
            <PdfDatePicker checked={exportTypeEnum[0]} state={state} />
            <div style={{ fontSize: 14 }} className=" mt-2 ml-0 label-title">
              <FormattedMessage id="MORE_OPTIONS" />
            </div>
            <div className="text-black mt-3">
              <div>
                <Checkbox
                  onChange={() => {
                    let temp = [...exportTypeEnum];
                    if (temp[0]) temp[0] = 0;
                    else temp[0] = 1;
                    setExportTypeEnum(temp);
                  }}
                  checked={exportTypeEnum[0]}
                  textValue="Não classificado"
                />
              </div>
              <div>
                <Checkbox
                  onChange={() => {
                    let temp = [...exportTypeEnum];
                    if (temp[1]) temp[1] = 0;
                    else temp[1] = 2;
                    setExportTypeEnum(temp);
                  }}
                  checked={exportTypeEnum[1]}
                  textValue="Classificado"
                />
              </div>
              <div>
                <Checkbox
                  onChange={() => {
                    let temp = [...exportTypeEnum];
                    if (temp[2]) temp[2] = 0;
                    else temp[2] = 4;
                    setExportTypeEnum(temp);
                  }}
                  checked={exportTypeEnum[2]}
                  textValue="Faturado"
                />
              </div>
              <div>
                <Checkbox
                  onChange={() => {
                    let temp = [...exportTypeEnum];
                    if (temp[3]) temp[3] = 0;
                    else temp[3] = 8;
                    setExportTypeEnum(temp);
                  }}
                  checked={exportTypeEnum[3]}
                  textValue="Associado mas classificado a outra entidade"
                />
              </div>
            </div>
            <LoadableButton
              disabled={ExportNumSum === 0}
              onClick={handleExport}
              className="mt-3"
              isLoading={isLoading}
            >
              Exportar
            </LoadableButton>
          </div>
        </Popup>

        <Button
          ref={anchorRef}
          style={buttonStyle}
          title={
            excel
              ? intl.formatMessage({ id: "EXPORTS_EXCEL" })
              : intl.formatMessage({ id: "EXPORT_PDF" })
          }
          onClick={
            !chooseDate
              ? handleExport
              : (e) => {
                  e.stopPropagation();
                  toggleIsOpen(true);
                }
          }
          disabled={disabled || isLoading}
          className={classnames(
            {
              "ssi-button-grid sm": !resolvedLink,
              "ssi-button-link text-left": resolvedLink,
              "d-flex": resolvedLink
            },
            className
          )}
          vType={resolvedLink ? null : "primary-ghost"}
        >
          {LineButton ? (
            !excel ? (
              isLoading ? (
                <LoaderSpinner size="xxs" />
              ) : (
                <FontAwesomeIcon icon={faFilePdf} />
              )
            ) : isLoading ? (
              <LoaderSpinner size="xxs" />
            ) : (
              <FontAwesomeIcon icon={faFileExcel} />
            )
          ) : !excel ? (
            isLoading ? (
              <LoaderSpinner size="xxs" />
            ) : (
              <FontAwesomeIcon icon={faFilePdf} />
            )
          ) : isLoading ? (
            <LoaderSpinner size="xxs" />
          ) : (
            <FontAwesomeIcon icon={faFileExcel} />
          )}

          {resolvedLink && (
            <span className="fw-regular fs-14 ml-3">
              {!excel ? "Exportar PDF" : "Exportar XLS"}
            </span>
          )}
        </Button>
      </>
    );
  };

  return InterventionPdfButton;
};

export const createContractNotificationButton = ({
  generateUrl,
  buildUrl,
  chooseDate = true,
  moduleType,
  download = false,
  url
}) => {
  const InterventionPdfButton = ({
    id,
    skeleton,
    link,
    excel,
    LineButton,
    className,
    buttonStyle,
    directUrl
  }) => {
    const intl = useIntl();
    const space = useSpace();
    const createToast = useToast();

    const disabled = !usePdfPermission(moduleType);

    const [isLoading, setIsLoading] = useState(false);
    const anchorRef = useRef();
    const popupRef = useRef();
    const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
    const state = useState(() => {
      const now = moment();
      const fromDate = now.startOf("month").toDate();
      const toDate = now.endOf("month").toDate();
      return {
        fromDate,
        toDate
      };
    });

    const [exportTypeEnum, setExportTypeEnum] = useState([1, 2, 4, 8]);
    const ExportNumSum = exportTypeEnum.reduce((a, b) => a + b, 0);
    if (skeleton) return <FormButtonSkeleton size="sm" />;

    const handleSend = () => {
      setIsLoading(true);

      const [{ fromDate, toDate }] = state;
      const fToDate = moment(toDate).format("YYYY-MM-DD");
      const fFromDate = moment(fromDate).format("YYYY-MM-DD");

      const queryObj = {
        fromDate: fFromDate,
        toDate: fToDate,
        exportTypeEnum: exportTypeEnum.reduce((a, b) => a + b, 0)
      };

      const qString = querystring.stringify(queryObj);

      let baseUrl =
        directUrl || `spaces/${space.Id}/${generateUrl({ id, url })}`;
      baseUrl += `?${qString}`;

      api
        .get(baseUrl)
        .then((r) => {
          createToast({
            pos: "tm",
            type: "success",
            description: intl.formatMessage({ id: "TEST_EMAIL_SEND_SUCCESS" })
          });
          setIsLoading(false);
        })
        .catch((e) => {
          handleError(createToast, e);
          setIsLoading(false);
        });
    };

    return (
      <>
        <Popup
          domRef={popupRef}
          anchorEl={anchorRef.current}
          isOpen={isOpen}
          onClick={(e) => {
            e.nativeEvent.stopImmediatePropagation();
          }}
          className="ar-pdf-popup"
          placement="right"
        >
          <div style={{ maxWidth: 400 }} className="py-3 px-4 bg-white">
            <div style={{ fontSize: 14 }} className="mb-2 ml-0 label-title">
              <FormattedMessage id="SENDING_DATE" />
            </div>
            <PdfDatePicker checked={exportTypeEnum[0]} state={state} />
            <div style={{ fontSize: 14 }} className=" mt-2 ml-0 label-title">
              <FormattedMessage id="MORE_OPTIONS" />
            </div>
            <div className="text-black mt-3">
              <div>
                <Checkbox
                  onChange={() => {
                    let temp = [...exportTypeEnum];
                    if (temp[0]) temp[0] = 0;
                    else temp[0] = 1;
                    setExportTypeEnum(temp);
                  }}
                  checked={exportTypeEnum[0]}
                  textValue={intl.formatMessage({
                    id: "EXPORT_NOT_CLASSIFIED"
                  })}
                />
              </div>
              <div>
                <Checkbox
                  onChange={() => {
                    let temp = [...exportTypeEnum];
                    if (temp[1]) temp[1] = 0;
                    else temp[1] = 2;
                    setExportTypeEnum(temp);
                  }}
                  checked={exportTypeEnum[1]}
                  textValue={intl.formatMessage({ id: "CLASSIFIED" })}
                />
              </div>
              <div>
                <Checkbox
                  onChange={() => {
                    let temp = [...exportTypeEnum];
                    if (temp[2]) temp[2] = 0;
                    else temp[2] = 4;
                    setExportTypeEnum(temp);
                  }}
                  checked={exportTypeEnum[2]}
                  textValue={intl.formatMessage({ id: "BILLED" })}
                />
              </div>
              <div>
                <Checkbox
                  onChange={() => {
                    let temp = [...exportTypeEnum];
                    if (temp[3]) temp[3] = 0;
                    else temp[3] = 8;
                    setExportTypeEnum(temp);
                  }}
                  checked={exportTypeEnum[3]}
                  textValue={intl.formatMessage({
                    id: "CLASSIFIED_BUT_OTHER_ENTITY"
                  })}
                />
              </div>
            </div>

            <div className="mt-3 fs-12">
              <FormattedMessage id="WARNING_SEND_MAIL_MESSAGE" />
            </div>

            <LoadableButton
              disabled={ExportNumSum === 0}
              onClick={handleSend}
              className="mt-3 w-100"
              isLoading={isLoading}
            >
              <FormattedMessage id="SEND_MAIL" />
            </LoadableButton>
          </div>
        </Popup>
        <Button
          ref={anchorRef}
          style={buttonStyle}
          onClick={!chooseDate ? handleSend : () => toggleIsOpen(true)}
          disabled={disabled || isLoading}
          className={classnames(
            {
              "ssi-button-grid sm": !link,
              "ssi-button-link text-left": link
            },
            className
          )}
          vType={link ? null : "primary-ghost"}
        >
          {LineButton ? (
            <div className="mr-1 ar-form-button-link-icon">
              {!excel ? (
                isLoading && !link ? (
                  <div className="mr-1 ar-form-button-link-icon">
                    <LoaderSpinner />
                  </div>
                ) : (
                  <div className="mr-1 ar-form-button-link-icon">
                    <FontAwesomeIcon icon={faFilePdf} />
                  </div>
                )
              ) : isLoading && !link ? (
                <div className="mr-1 ar-form-button-link-icon">
                  <LoaderSpinner />
                </div>
              ) : (
                <div className="mr-1 ar-form-button-link-icon">
                  <FontAwesomeIcon icon={faFileExcel} />
                </div>
              )}
            </div>
          ) : !excel ? (
            isLoading && !link ? (
              <div className="mr-1 ar-form-button-link-icon">
                <LoaderSpinner />
              </div>
            ) : (
              <div className="mr-1 ar-form-button-link-icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
            )
          ) : isLoading && !link ? (
            <LoaderSpinner />
          ) : (
            <div className="mr-1 ar-form-button-link-icon">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
          )}
          {link && (
            <span className="fw-regular fs-14">
              <FormattedMessage id="EMAIL_REPORT" />
            </span>
          )}
        </Button>
      </>
    );
  };

  return InterventionPdfButton;
};
