import React from "react";
import classnames from "classnames";
import moment from "moment";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import TextEllipsis from "../TextEllipsis/TextEllipsis";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntitySummaryHeaderTitle } from "../EntitySummaries/EntitySummariesComponents";
import { FormattedMessage } from "react-intl";
import DeleteHelperProvider from "../CGrid/DeleteHelper";

export const OriginDate = React.memo(({ date, className }) => {
  const formmatedDate = moment(date).format("DD/MM/YYYY HH:mm");

  return <span className={className}>{formmatedDate}</span>;
});

export const OriginHeader = ({
  title,
  Icon,
  type,
  id,
  EntityButtons,
  entity,
  TitleComponent,
  date,
  link,
  className,
  hideId,
  Dif
}) => {
  if (Dif && id) {
    return (
      <div className="mb-2 origin_header_dif_div text-black px-3 d-flex flex-1 of-hidden">
        <div className="pt-3 pb-2 d-flex align-items-center justify-content-between flex-1 of-hidden">
          <div className="d-flex flex-column flex-1 of-hidden">
            <div className="d-flex fs-16 align-items-center text-black flex-1 of-hidden">
              {Icon}
              {link && title && (
                <Link
                  className="of-hidden"
                  style={{ textDecoration: "none" }}
                  to={link}
                >
                  <EntitySummaryHeaderTitle
                    value={{ Name: title }}
                    noLink
                    FontSize="fw-medium of-hidden text-truncate"
                  />
                </Link>
              )}
              {!link && title && (
                <EntitySummaryHeaderTitle
                  value={{ Name: title }}
									noLink
                  FontSize="fw-medium of-hidden text-truncate"
                />
              )}
              {TitleComponent && TitleComponent}
            </div>
            {!hideId && (
              <div className="d-flex text-grey-light fs-12">
                <FormattedMessage id={entity} />
                <div className="ml-1">{id}</div>
              </div>
            )}
          </div>
          <div className="ml-2">
            <DeleteHelperProvider>{EntityButtons}</DeleteHelperProvider>
          </div>
        </div>
      </div>

      // <div className={classnames("px-3", className)}>
      //   <div className="text-black breakWord">
      // 	{/* <Icon className="mr-2" /> */}
      // 	<span className="fs-14">
      // 	  <TextEllipsis length={190} breakWord text={title} />
      // 	</span>
      //   </div>
      //   <div className="d-flex justify-content-between fs-14">
      // 	<div className="text-black">{/* {type} {id} */}</div>
      // 	<OriginDate date={date} />
      //   </div>
      // </div>
    );
  } else {
    if (!id) {
      return (
        <div className={classnames("px-3", className)}>
          <div className="d-flex justify-content-between">
            <div className="text-black breakWord">
              <Icon className="mr-2" />
              <span className="fw-medium">
                <TextEllipsis length={190} breakWord text={title} />
              </span>
            </div>
            <OriginDate className="fs-14" date={date} />
          </div>
        </div>
      );
    }
    return (
      <div className={classnames("px-3", className)}>
        <div className="text-black breakWord">
          {/* <Icon className="mr-2" /> */}
          <span className="fs-14">
            <TextEllipsis length={190} breakWord text={title} />
          </span>
        </div>
        <div className="d-flex justify-content-between fs-14">
          <div className="text-black">{/* {type} {id} */}</div>
          <OriginDate date={date} />
        </div>
      </div>
    );
  }
};

export const OriginItemGroup = ({
  title,
  children,
  icon,
  className,
  show = true
}) => {
  if (!show) return null;
  return (
    <div className={className}>
      <div
        className={classnames(
          "fs-12 px-3 fw-medium text-gray-darker d-flex align-items-center mb-1 py-1 bg-primary-light"
        )}
      >
        {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
        {title}
      </div>
      <div className="px-3 text-truncate">{children}</div>
    </div>
  );
};

export const OriginPopupLoader = () => {
  return (
    <div className="p-3 bg-white">
      <LoaderSpinner className="text-secondary" size="xs" />
    </div>
  );
};

export const OriginValue = (Type, value) => {
  return {
    Type,
    data: value
  };
};

export const OriginContainer = ({ children, className }) => {
  return (
    <div
      onClick={(e) => {
        // e.stopPropagation();
      }}
      onDoubleClick={(e) => {
        e.stopPropagation();
      }}
      className={classnames("ar-origin-popup bg-white", className)}
    >
      {children}
    </div>
  );
};
