import React from "react";
import moment from "moment";
import { useContract } from "../../../../Hooks/EntityHooks";
import {
  useTimelineIsLayoutActive,
  openContractForm
} from "../../../../Components/Timeline/TimelineHelper";
import { getBoardStyle } from "../../../../Helpers/GridHelper";
import { ContractEntityGridButtons } from "../../ContractsHelper";
import ContractOrigin from "../../../Origin/ContractOrigin";
import { ContractStatusProfile } from "../../../../Components/Contract/ContractProfile";
import Timeline from "../../../../Components/Timeline/Timeline";
import { TimelineYearlyConfig } from "../../../../Components/Timeline/TimelineYearly";
import { TimelineWeaklyConfig } from "../../../../Components/Timeline/TimelineWeakly";
import TimelineColors from "../../../../Components/Timeline/TimelineColors";
import { FormattedMessage } from "react-intl";

const buildDate = (startDate, endDate, hasMultipleDays) => {
  const sDate = moment(startDate);
  const eDate = moment(endDate);

  if (hasMultipleDays) {
    return `${sDate.format("YYYY-MM-DD")} -> ${eDate.format("YYYY-MM-DD")}`;
  } else {
    return `${sDate.format("YYYY-MM-DD")} -> ${eDate.format("YYYY-MM-DD")}`;
  }
};

const ItemModel = React.memo(function ItemModel({ id, hasMultipleDays }) {
  const item = useContract(id);
  const { Status, NoTagDescription, BeginDate, EndDate } = item;
  const date = buildDate(BeginDate, EndDate, hasMultipleDays);
  const isLayoutActive = useTimelineIsLayoutActive();
  const style = getBoardStyle(BeginDate, EndDate);
  return (
    <>
      <TimelineColors entity={item}>
        <div className="d-flex w-100 align-items-center justify-content-between mh-100">
          <div
            style={style}
            className="ar-timeline-item-stickey p-1 rounded h-100 d-flex align-items-center fs-12 fw-medium"
          >
            {date}
          </div>
          {/* <div className="ar-timeline-item-stickey h-100 d-flex align-items-center fs-12 text-black fw-medium">
          {date}
        </div> */}
          <div className="ar-timeline-item-stickey-2 d-flex align-items-center">
            <ContractEntityGridButtons noColors minimal entity={item} />
          </div>
        </div>
        <ContractOrigin
          disablePopup={!isLayoutActive}
          className="text-truncate of-hidden d-flex fs-12 text-black ar-timeline-item-stickey mw-100"
          contract={item}
        >
          <ContractStatusProfile
            className="d-inline-flex"
            dotClassName="mr-1"
            status={Status}
            hideName
          />
          <span className="text-truncate">{NoTagDescription}</span>
        </ContractOrigin>
      </TimelineColors>
    </>
  );
});

const MinimalItemModel = React.memo(function MinimalItemModel({ id }) {
  const item = useContract(id);

  const { Status, NoTagDescription } = item;
  const isLayoutActive = useTimelineIsLayoutActive();

  return (
    <>
      <ContractOrigin
        disablePopup={!isLayoutActive}
        className="of-hidden fs-12 text-black h-100 d-flex align-items-center"
        contract={item}
      >
        <ContractStatusProfile
          className="d-inline-flex"
          dotClassName="mr-1"
          status={Status}
          hideName
        />
        <span className="text-truncate">{NoTagDescription}</span>
      </ContractOrigin>
    </>
  );
});

const ContractTimelineSettings = {
  ItemModel,
  MinimalItemModel,
  baseField: "contract",
  RowButton: {
    onClick: (date) => {
      openContractForm(date, { Manager: null });
    },
    hoverTitle: <FormattedMessage id="CREATE_CONTRACT" />
  },
  HeaderButton: {
    onClick: (date) => {
      openContractForm(date, { Manager: null });
    },
    hoverTitle: <FormattedMessage id="CREATE_CONTRACT" />
  }
};

const configs = [TimelineWeaklyConfig, TimelineYearlyConfig];

const ContractTimeline = () => {
  return <Timeline settings={ContractTimelineSettings} configs={configs} />;
};

export default ContractTimeline;
