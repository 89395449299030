import React from "react";
import classnames from "classnames";

const DropdownInput = ({
  isTyping,
  enableInput,
  text,
  fallbackValue,
  textChange,
  enableRef,
  disabled,
  focus,
  className,
  focusonmount,
  style,
  placeholder,
}) => {
  if (!enableInput) {
    return (
      <span className="fs-14 text-black">{fallbackValue || placeholder}</span>
    );
  }

  let finalText = isTyping ? text : fallbackValue;
  if (!finalText) finalText = "";
  const handleChange = textChange || (() => {});
  return (
    <input
      ref={focusonmount ? enableRef : null}
      onClick={(e) => {
        e.target.select();
      }}
			name="search-text"
			autoComplete="off"
      className={classnames("w-100", className)}
      onChange={handleChange}
      disabled={disabled}
      onFocus={focus}
      value={finalText}
      style={style}
      placeholder={placeholder}
    />
  );
};

export const DropdownInputContainer = ({ children }) => (
  <div className="custom-dropdown-input d-flex align-items-center w-100">
    {children}
  </div>
);

export default DropdownInput;
