import React, { useMemo, useState, useCallback, useContext } from "react";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { useSpacePost } from "../../../Helpers/IOClient";
import { spaceContactSchema } from "../../../config/schema";
import { ContactStatusDropdown } from "../../AdvancedMultiInputs";
import {
  ServerAwareProvider,
  GridRequestProvider,
  ServerAwareQueryInput
} from "../../../Components/CGrid/ServerGrid";
import {
  ContactEntityActionsChildren,
  ContactEntityGridButtons
} from "../ContactsHelper";
import { ConnectedClientOrigin } from "../../Origin/ClientOrigin";
import { ConnectedClientProfile } from "../../../Components/Clients/ClientProfile";
import { ConnectedClientProfileStack } from "../../../Components/Accounts/AccountProfileStack";
import { ContactSidebarLink } from "../../../Components/EntitySidebarLinks/EntitySidebarLinks";
import AccountsInRoomStack from "../../../Components/AccountsInRoom/AccountsInRoomStack";
import {
  EmailColumn,
  PhoneColumn
} from "../../../Components/VirtualGrid/VirtualGridColumns";
import { ConnectedAccountPopup } from "../../Origin/AcountPopup";
import RelativeTime from "../../../Components/RelativeTime/RelativeTime";
import { useAirdeskTitle } from "../../../Hooks/MiscHooks";
import {
  createClientFilter,
  createContactStatusFilter,
  createUserFilter
} from "../../../Helpers/FilterHelper";
import { useCurrentUserContactPermission } from "../../../Helpers/ContactPermissionsHelper";
import {
  PageContainer,
  PageContent
} from "../../../Components/PageUtilities/PageUtilities";
import Modal from "../../../Components/Modal/Modal";
import { ColumnChooser } from "../../../Components/ColumnChooser/ColumnChooser";
import PageHeader from "../../../Components/Headers/PageHeader";
import { FormattedMessage, useIntl } from "react-intl";
import ActionsButton, {
  ActionButtonItem
} from "../../../Components/ActionsButton/ActionsButton";
import {
  ContactStatusMultipleButton,
  ContactMobileCodeMultipleButton,
  ContactPhoneCodeMultipleButton,
  ContactGDPRMultipleButton,
  ContactDescriptionMultipleButton,
  ContactMergeButton
} from "../ContactMultiActionForms";
import { ContactFormButton } from "../../Form/FormButtons";
import { ActionButtonOptions } from "../../../Components/ExtraActionsButton/ExtraActionButton";
import {
  canReadContacts,
  isDeveloper,
  moduleTypes
} from "../../../Helpers/ModulesHelper";
import ServerAwareFilterList from "../../../Components/FilterList/ServerAwareFilterList";
import { FilterViewTypeEnum } from "../../../Components/FilterList/FilterList";
import ContactGrid from "./ContactGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faEnvelope,
  faUserTie,
  faFileImport,
  faDrawCircle,
  faAlignJustify,
  faPhone,
  faTrashAlt,
  faSuitcase,
  faUserPlus
} from "@fortawesome/pro-light-svg-icons";
import {
  faUserPlus as faUserPlusSolid,
  faCalendarAlt as faCalendarAltSolid,
  faSuitcase as faSuitcaseSolid,
  faUserTie as faUserTieSolid,
  faEnvelope as faEnvelopeSolid,
  faPhone as faPhoneSolid,
  faAlignJustify as faAlignJustifySolid,
  faDrawCircle as faDrawCircleSolid,
  faPollPeople,
  faBullseyePointer
} from "@fortawesome/pro-solid-svg-icons";
import ContactsMerge from "../ContactsMerge/ContactsMerge";
import {
  useCurrentAccount,
  useCurrentAccountSpace
} from "../../../Contexts/UserContext";
import { GridActionContext } from "../../../Components/ContextMenu/ContextMenu";
import { SelectedArrMsgContext } from "../../../Components/UnOrReadOptions/UnreadOptions";
import { InboxTypeEnum } from "../../Reception/ReceptionHelpers";
import DeleteMultipleModalHandler, {
  useOpenDeleteModal
} from "../../../Components/CGrid/DeleteMultipleModal";
import { DefaultColumnsWidth } from "../../../Components/CGrid/GridDefinitions";
import TextEllipsis from "../../../Components/TextEllipsis/TextEllipsis";
import { ConnectedAccountProfile } from "../../../Components/Accounts/AccountProfile";
import { ContactsLink } from "../../../Helpers/HooksHelper";
import SaveFavoriteButton from "../../AppBar/SaveFavoriteButton";
import { ServerAwareFavoritesContext } from "../../AppBar/ServerAwareFavorite";
import { handleError } from "../../../Helpers/MiscHelper";

export const DeleteContactModalContext = React.createContext();

const ContactStatusChanger = ({ Data }) => {
  const intl = useIntl();
  const createToast = useToast();
  const [post, { loading }] = useSpacePost(
    `Contacts/${Data.Id}/Status`,
    spaceContactSchema,
    {
      onSuccess: () => {
        createToast({
          pos: "tm",
          type: "success",
          title: intl.formatMessage({ id: "CONTACT_NR" }) + ` ${Data.Id}`,
          description: intl.formatMessage({ id: "STATUS_CHANGED_SUCCESS" })
        });
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  const handleStatusChange = (v) => {
    post({ Status: v });
  };

  return (
    <ContactStatusDropdown
      preview={!Data.CanEdit}
      arrowClassnames={"text-primary ml-2 fs-14"}
      disabled={loading}
      onChange={handleStatusChange}
      className="fs-12 text-truncate"
      value={Data.Status}
    />
  );
};

const ContactsMultipleDeleteButton = () => {
  const openDeleteModal = useOpenDeleteModal();

  const handleDeleteOpenModal = () => {
    openDeleteModal();
  };

  return (
    <div className="h-100 Logout-button ">
      <div
        className="ar-logout-button d-flex align-items-center w-100 h-100 pb-2 pt-2 cursor-pointer"
        onClick={handleDeleteOpenModal}
      >
        <div className="ar-form-button-link-icon mr-1">
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
        <span>
          <FormattedMessage id={"DELETE_SELECTED"} />
        </span>
      </div>
    </div>
  );
};

export const ContactFilterColumns = [
  createClientFilter({ field: "Client/Id" }),
  {
    propField: "CreationDate",
    icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    titleId: "CREATION_DATE",
    field: "Contact/CreationDate",
    type: "dateTime"
  },
  {
    propField: "Email",
    title: "Email",
    field: "Contact/Email",
    type: "string",
    icon: <FontAwesomeIcon icon={faEnvelope} />
  },
  {
    propField: "Id",
    icon: <FontAwesomeIcon icon={faUserTie} />,
    titleId: "CONTACTS_NR",
    field: "Contact/Id",
    type: "number"
  },
  createUserFilter({
    propField: "Creator",
    titleId: "CREATED_BY",
    field: "Creator/Id",
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"CREATED_BY"} />{" "}
          <ConnectedAccountProfile account={value} />
        </div>
      );
    }
  }),
  createContactStatusFilter(),
  {
    icon: <FontAwesomeIcon icon={faFileImport} />,
    titleId: "IMPORT_NR",
    field: "Import/Id",
    type: "number"
  },
  {
    propField: "Name",
    icon: <FontAwesomeIcon icon={faAlignJustify} />,
    titleId: "NAME",
    field: "Contact/Name",
    type: "string"
  },
  {
    propField: "MobileNumber",
    icon: <FontAwesomeIcon icon={faPhone} />,
    titleId: "PHONE_NUMBER",
    field: "Contact/PhoneNumber",
    type: "string"
  }
];

export const contactsColumns = [
  {
    columnChooserIcon: faBullseyePointer,
    sticky: true,
    required: true,
    sortable: false,
    orderBy: false,
    noTitle: true,
    title: "Actions",
    minSize: 90,
    field: "Buttons",
    width: DefaultColumnsWidth.smallButton,
    className: "p-2",
    Cell: ({ Data: value }) => <ContactEntityGridButtons entity={value} />
  },
  {
    headerIcon: faAlignJustifySolid,
    sticky: true,
    required: true,
    width: DefaultColumnsWidth.Company,
    orderBy: true,
    sortable: true,
    field: "Contact/Name",
    title: <FormattedMessage id={"NAME"} />,
    Cell: ({ Data: value }) => (
      <div className="d-flex align-items-center">
        {value.AccountsInRoomView && value.AccountsInRoomView.length > 0 && (
          <span className="mr-2">
            <AccountsInRoomStack
              dontShowLine
              accountsInRoom={value.AccountsInRoomView}
            />
          </span>
        )}
        <ContactSidebarLink id={value.Id} className="fs-12">
          <TextEllipsis length={85} truncate text={value.Name} />
          {/* <ConnectedContactPopup contact={value.Id} /> */}
        </ContactSidebarLink>
      </div>
    )
  },
  {
    headerIcon: faDrawCircleSolid,
    filterIcon: faDrawCircle,
    width: DefaultColumnsWidth.status,
    field: "Contact/Attributes",
    minSize: DefaultColumnsWidth.minStatusSize,
    title: <FormattedMessage id={"STATUS"} />,
    Cell: ({ Data }) => {
      return <ContactStatusChanger Data={Data} />;
      //   return <ClientProfile justStatus hideId client={Data} />;
    }

    // Cell: ({ Data }) => (
    //   <div style={{ width: 75 }}>
    //     <EntityStatusUpdater
    //       fixedWidth={150}
    //       canEdit={false}
    //       StatusComponent={ContactStatusProfile}
    //       Component={ContactStatusDropdown}
    //       schema={spaceContactSchema}
    //       status={Data.Status}
    //       type="Contact"
    //       id={Data.Id}
    //       hideCancellation={true}
    //     />
    //   </div>
    // )
  },
  {
    headerIcon: faUserTieSolid,
    filterIcon: faUserTie,
    width: DefaultColumnsWidth.id,
    field: "Contact/Id",
    title: "Nº",
    Cell: ({ Data: value }) => (
      <ContactsLink id={value.Id}>{value.Id}</ContactsLink>
    )
  },
  {
    headerIcon: faSuitcaseSolid,
    filterIcon: faSuitcase,
    width: DefaultColumnsWidth.relativeTime,
    orderBy: false,
    sortable: false,
    field: "Empresas",
    title: <FormattedMessage id={"ASSOCIATED_ACCOUNTS"} />,
    Cell: ({ Data }) => {
      if (!Data.Clients) return null;

      if (Data.Clients.length === 1)
        return (
          <ConnectedClientOrigin client={Data.Clients[0]}>
            <ConnectedClientProfile showId client={Data.Clients[0]} />
          </ConnectedClientOrigin>
        );
      return <ConnectedClientProfileStack noAvatar clients={Data.Clients} />;
    }
  },

  // {
  //   headerIcon: faPollPeople,
  //   width: DefaultColumnsWidth.AssignedGroup,
  //   orderBy: false,
  //   sortable: false,
  //   minSize: 145,
  //   field: "AccountsInRoom",
  //   title: <FormattedMessage id={"WORKING_IN"} />,
  //   Cell: ({ Data }) => {
  //     return <AccountsInRoomStack accountsInRoom={Data.AccountsInRoom} />;
  //   }
  // },
  {
    headerIcon: faEnvelopeSolid,
    filterIcon: faEnvelope,
    width: DefaultColumnsWidth.Email,
    field: "Contact/Email",
    title: <FormattedMessage id={"EMAIL"} />,
    Cell: ({ Data: value }) => <EmailColumn email={value.Email} />
  },
  {
    headerIcon: faPhoneSolid,
    filterIcon: faPhone,
    width: DefaultColumnsWidth.Phone,
    field: "Contact/PhoneNumber",
    title: <FormattedMessage id={"PHONE_NUMBER"} />,
    Cell: ({ Data: value }) => <PhoneColumn phoneNumber={value.PhoneNumber} />
  },
  {
    headerIcon: faPhoneSolid,
    filterIcon: faPhone,
    width: DefaultColumnsWidth.Phone,
    field: "Contact/MobileNumber",
    sortable: true,
    orderBy: true,
    title: <FormattedMessage id={"MOBILE_NUMBER"} />,
    Cell: ({ Data: value }) => <PhoneColumn phoneNumber={value.MobileNumber} />
  },

  // {
  //   width: 140,
  //   field: "LastCall",
  //   title: "Último contacto",
  //   formatValue: value => (value ? new Date(value) : value)
  // },
  {
    headerIcon: faUserPlusSolid,
    filterIcon: faUserPlus,
    width: DefaultColumnsWidth.Company,
    field: "Creator/Name",
    title: <FormattedMessage id={"CREATED_BY"} />,
    Cell: ({ Data }) => <ConnectedAccountPopup account={Data.Creator} />
  },
  {
    headerIcon: faCalendarAltSolid,
    filterIcon: faCalendarAlt,
    orderBy: false,
    sortable: false,
    width: DefaultColumnsWidth.relativeTime,
    field: "Contact/CreationDate",
    title: <FormattedMessage id={"CREATION_DATE"} />,
    Cell: ({ Data }) => (
      <div className="text-truncate">
        <RelativeTime date={Data.CreationDate} />
      </div>
    )
  }
];
// const defaultOrderBy = { column: "Contact/Id", type: "desc" };
const ContactOverview = (props) => {
  const intl = useIntl();

  const favoriteItem = useContext(ServerAwareFavoritesContext);

  const pageTitle = useMemo(() => {
    if (favoriteItem) {
      return `${favoriteItem.data.title} - ${intl.formatMessage({
        id: "CONTACTS"
      })}`;
    } else return intl.formatMessage({ id: "CONTACTS" });
  }, [favoriteItem, intl]);

  useAirdeskTitle(pageTitle);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const CurrentSpaceAccount = useCurrentAccountSpace();
  const CanMerge = canReadContacts(CurrentSpaceAccount);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemSelection = useCallback((items) => {
    setSelectedItems(items);
  }, []);
  const columnResize = true;

  const [contactMergeState, setContactMergeState] = useState(false);

  const ChangeContactMergeState = useCallback(() => {
    setContactMergeState((e) => {
      return !e;
    });
  }, []);

  const CurrentUser = useCurrentAccount();
  const isDev = isDeveloper(CurrentUser);

  const noContactReadPermission = !useCurrentUserContactPermission();

  const ClearSelection = useCallback(() => {
    setSelectedItems([]);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);

  // const closeDeleteModal = () => {
  //   setDeleteModalStatus(false);
  // };
  const openDeleteModal = useCallback(() => {
    setDeleteModalStatus(true);
  }, []);

  const SelectedResolvedArr = useMemo(() => {
    return selectedItems.map((t) => {
      return { type: InboxTypeEnum.Contact, id: t };
    });
  }, [selectedItems]);

  return (
    <PageContainer>
      <DeleteContactModalContext.Provider value={openDeleteModal}>
        <SelectedArrMsgContext.Provider value={SelectedResolvedArr}>
          <GridActionContext.Provider value={ContactEntityActionsChildren}>
            <ServerAwareProvider
              columns={contactsColumns}
              filterColumns={ContactFilterColumns}
              moduleType={moduleTypes.contacts}
              // defaultOrderBy={defaultOrderBy}
              schema={spaceContactSchema}
            >
              <Modal
                // containerClassName=""
                isOpen={modalIsOpen}
                onClose={closeModal}
              >
                <ColumnChooser
                  schemaView={spaceContactSchema}
                  grid={"contacts"}
                  Name="contacts"
                  Columns={contactsColumns}
                  onClose={closeModal}
                  setIsOpen={setModalIsOpen}
                  isOpen={modalIsOpen}
                />
              </Modal>

              <GridRequestProvider toggleColumnChooser={setModalIsOpen}>
                <DeleteMultipleModalHandler
                  handleItemSelection={setSelectedItems}
                >
                  <PageHeader
                    schema={spaceContactSchema}
                    toggleFilterList
                    title={<FormattedMessage id="CONTACTS" />}
                  >
                    <div className="d-flex justify-content-start flex-1 align-items-center">
                      <ActionsButton disabled={selectedItems.length === 0}>
                        <ActionButtonItem
                          component={
                            <ContactStatusMultipleButton
                              selectedItems={{ selectedItems, ClearSelection }}
                            />
                          }
                        ></ActionButtonItem>
                        {!noContactReadPermission && (
                          <ActionButtonItem
                            component={
                              <ContactMobileCodeMultipleButton
                                selectedItems={{
                                  selectedItems,
                                  ClearSelection
                                }}
                              />
                            }
                          ></ActionButtonItem>
                        )}
                        {!noContactReadPermission && (
                          <ActionButtonItem
                            component={
                              <ContactPhoneCodeMultipleButton
                                selectedItems={{
                                  selectedItems,
                                  ClearSelection
                                }}
                              />
                            }
                          ></ActionButtonItem>
                        )}
                        <ActionButtonItem
                          component={
                            <ContactGDPRMultipleButton
                              selectedItems={{ selectedItems, ClearSelection }}
                            />
                          }
                        ></ActionButtonItem>
                        <ActionButtonItem
                          component={
                            <ContactDescriptionMultipleButton
                              selectedItems={{ selectedItems, ClearSelection }}
                            />
                          }
                        ></ActionButtonItem>
                        {CanMerge && (
                          <ActionButtonItem
                            component={
                              <ContactMergeButton
                                disabled={selectedItems.length <= 1}
                                openContactsMerge={ChangeContactMergeState}
                              />
                            }
                          ></ActionButtonItem>
                        )}
                        <ContactsMultipleDeleteButton />
                      </ActionsButton>
                      <ContactFormButton className="ml-2" />
                      <ServerAwareQueryInput
                        placeholder={intl.formatMessage({ id: "SEARCH" })}
                        className="mx-2"
                      />
                    </div>
                    <div className="d-flex">
                      <SaveFavoriteButton />
                      <ActionButtonOptions
                        moduleType={moduleTypes.contacts}
                        enableColumnEdit={() => {
                          setModalIsOpen(true);
                        }}
                        columnResize={columnResize}
                        spaceType={spaceContactSchema.name}
                        type="contacts"
                      />
                    </div>
                  </PageHeader>
                  {isDev && CanMerge && (
                    <ContactsMerge
                      selectedItems={selectedItems}
                      isOpen={contactMergeState}
                      ClearSelection={ClearSelection}
                      handleClose={ChangeContactMergeState}
                    />
                  )}
                  <PageContent>
                    <ServerAwareFilterList
                      filterType={FilterViewTypeEnum.Contact}
                    />

                    <ContactGrid
                      onItemSelect={handleItemSelection}
                      selectedItems={selectedItems}
                      className="flex-1"
                      columnResize={columnResize}
                    />
                  </PageContent>
                </DeleteMultipleModalHandler>
              </GridRequestProvider>
            </ServerAwareProvider>
          </GridActionContext.Provider>
        </SelectedArrMsgContext.Provider>
      </DeleteContactModalContext.Provider>
    </PageContainer>
  );
};

export default ContactOverview;
