import React, { useState, useContext, useMemo } from "react";
import { PipelineTypeEnum } from "../../Containers/Settings/Pipelines/NewPipelineHelper";
import {
  projectsCancellationReasonsDropdown,
  ticketsCancellationReasonsDropdown,
  callCancellationReasonsDropdown,
  tasksCancellationReasonsDropdown,
  dealsCancellationReasonsDropdown,
  OrganizationDropdown,
  dealsLostReasonsDropdown,
  contractsCancellationReasonsDropdown,
  SubscriptionsCancellationReasonsDropdown
} from ".";
import {
  spacePipelineSchema,
  spacePipelineStatusSchema
} from "../../config/schema";
import { getEntity } from "../../Helpers/IOClient";
import { usePipeline, usePipelineStatus } from "../../Hooks/EntityHooks";
import DropdownInput from "../../Components/Dropdown/DropdownInput";
import { ProjectStatusProfile } from "../../Components/Projects/ProjectProfile";
import classNames from "classnames";
import Button from "../../Components/Button/Button";
import LoadableButton from "../../Components/Button/LoadableButton";
import Modal from "../../Components/Modal/Modal";
import { useToast } from "../../Components/Toast/ToastProvider";
import { handleError } from "../../Helpers/MiscHelper";
import { useServerAwareState } from "../../Components/CGrid/ServerGrid";
import DealStatusProfile from "../../Components/Deals/DealStatusProfile";
import { ContractStatusProfile } from "../../Components/Contract/ContractProfile";
import {
  createDropdown,
  PipelineStatusMultiListItems
} from "./AdvancedMultiInputHelper";
import { useIntl, FormattedMessage } from "react-intl";
import { DocumentStatusProfile } from "../Settings/Documents/DocumentsStatusProfile";
// import HTMLInput from "../../Components/HTMLInput/HTMLInput";

// const PipelineDropdown = (type, ...props) => {
// 	const endpoint = `Pipelines?Type=${PipelineTypeEnum.Call}`
// 	const { loading, data, error } = useSpaceQuery(endpoint, spacePipelineSchema)
// 	const pipeline = usePipeline(data);
// 	const options = pipeline.map((e)=>{
// 		return e.Name;
// 	})
// 	if(loading || error) return <BarLoader />;
// 	return (
// 		<KeyedDropdown
// 			options={options}

// 			closeOnSelect
// 			{...props}
// 		/>
// 	);
// }

const pipelineTypeQuery = {
  endpoint: "query/Pipelines",
  key: "pipelines",
  name: "Pipeline",
  schema: [spacePipelineSchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedPipelineListItem pipeline={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedPipelineListItem pipeline={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { Type } = nextProps;
    const obj = {};
    if (Type) {
      obj.Type = Type;
    }
    return obj;
  }
};

const pipelineStatusTypeQuery = {
  endpoint: "query/PipelineStatus",
  key: "pipelines",
  name: "Pipeline",
  schema: [spacePipelineStatusSchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedPipelineStatusListItem pipeline={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedPipelineStatusListItem pipeline={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { pipelineId, Type, Status, filterBlock } = nextProps;
    const obj = {};
    if (pipelineId) {
      obj.pipelineId = pipelineId;
    } else if (Type) {
      obj.Type = Type;
    }

    if (filterBlock) {
      obj.isFilterEnabled = true;
    }

    if (Status) {
      if (Array.isArray(Status)) obj.Status = `${Status.join(",")}`;
      else {
        const newArr = [5, Status];
        obj.Status = `${newArr.join(",")}`;
      }
    }
    return obj;
  }
};

const upgradedPipelineStatusTypeQuery = {
  endpoint: "query/PipelineStatus/filter",
  key: "pipelines",
  name: "Pipeline",
  schema: [spacePipelineSchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedPipelineStatusListItem pipeline={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedPipelineStatusListItem pipeline={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { pipelineId, Type, Status, filterBlock } = nextProps;
    const obj = {};
    if (pipelineId) {
      obj.pipelineId = pipelineId;
    }
    if (Type) {
      obj.Type = Type;
    }

    if (filterBlock) {
      obj.isFilterEnabled = true;
    }

    if (Status) {
      if (Array.isArray(Status)) obj.Status = `${Status.join(",")}`;
      else {
        const newArr = [5, Status];
        obj.Status = `${newArr.join(",")}`;
      }
    }
    return obj;
  }
};

const pipelineStatusGridTypeQuery = {
  endpoint: `query/PipelineStatus`,
  key: "pipelines",
  name: "Pipeline",
  schema: [spacePipelineStatusSchema],
  ListItemComponent: ({ item, ...rest }) => {
    return (
      <ConnectedPipelineStatusListItem pipeline={item} {...rest} />
      // <ConnectedContractTypeProfile contracttype={item} />
    );
  },
  Component: ({ item, ...rest }) => (
    <ConnectedPipelineStatusGridListItem
      pipeline={item}
      grid={true}
      {...rest}
    />
  ),
  getUpdateParams: (nextProps) => {
    const { pipelineId, filterBlock } = nextProps;
    const obj = {};
    if (pipelineId) {
      obj.pipelineId = pipelineId;
    }
    if (filterBlock) {
      obj.isFilterEnabled = true;
    }
    return obj;
  }
};

export const PipelineDropdown = createDropdown({
  query: pipelineTypeQuery,
  requiresSpace: true,
  enableSearch: true,
  searchOnlyOnOpen: true,

  unremovable: true
});

export const PipelineStatusDropdown = createDropdown({
  query: pipelineStatusTypeQuery,
  requiresSpace: true,
  enableSearch: true,

  searchWhenValidProp: true,
  unremovable: true
});

export const UpgradedPipelineStatusDropdown = createDropdown({
  query: upgradedPipelineStatusTypeQuery,
  requiresSpace: true,
  enableSearch: true,
  ListComponent: PipelineStatusMultiListItems,
  searchWhenValidProp: true,
  unremovable: true
});

export const ServerAwarePipelineStatusDropdown = ({
  StatusProfile,
  Type,
  ...rest
}) => {
  const state = useServerAwareState();

  let pipelineId = state && state.params.pipeline;
  let resolvedType = pipelineId ? null : Type;

  const resolvedValue = StatusProfile
    ? StatusProfile
    : resolvedType === 6 || Type === 6
    ? DealStatusProfile
    : null;

  return (
    <PipelineStatusProfileContext.Provider value={resolvedValue}>
      <PipelineStatusDropdown
        pipelineId={pipelineId}
        Type={resolvedType}
        {...rest}
      />
    </PipelineStatusProfileContext.Provider>
  );
};

export const ServerAwareUpgradedPipelineStatusDropdown = ({
  StatusProfile,
  Type,
  ...rest
}) => {
  const state = useServerAwareState();

  let pipelineId = state && state.params.pipeline;
  let resolvedType = Type;

  const resolvedValue = StatusProfile
    ? StatusProfile
    : resolvedType === 6 || Type === 6
    ? DealStatusProfile
    : null;

  return (
    <PipelineStatusProfileContext.Provider value={resolvedValue}>
      <UpgradedPipelineStatusDropdown
        pipelineId={pipelineId}
        Type={resolvedType}
        {...rest}
      />
    </PipelineStatusProfileContext.Provider>
  );
};

export const PipelineStatusGridDropdown = createDropdown({
  query: pipelineStatusGridTypeQuery,
  closeOnClick: true,
  enableSearch: false,
  closeOnClickWhileOpen: true,
  requiresSpace: true,
  searchOnlyOnOpen: true,
  searchOnType: true,
  unremovable: true,
  grid: true
});

export const CallPipelineDropdown = (props) => {
  return <PipelineDropdown {...props} Type={PipelineTypeEnum.Call} />;
};

export const DocumentPipelineDropdown = (props) => {
  return <PipelineDropdown {...props} Type={PipelineTypeEnum.Documents} />;
};
export const DocumentPipelineStatusDropdown = (props) => {
  return (
    <PipelineStatusProfileContext.Provider value={DocumentStatusProfile}>
      <PipelineStatusDropdown {...props} Type={PipelineTypeEnum.Documents} />
    </PipelineStatusProfileContext.Provider>
  );
};

export const CallStatusPipelineDropdown = (props) => {
  return <PipelineStatusDropdown {...props} Type={PipelineTypeEnum.Call} />;
};

export const DealPipelineDropdown = (props) => {
  return <PipelineDropdown {...props} Type={PipelineTypeEnum.Deal} />;
};

export const DealStatusPipelineDropdown = (props) => {
  return (
    <PipelineStatusProfileContext.Provider value={DealStatusProfile}>
      <PipelineStatusDropdown {...props} Type={PipelineTypeEnum.Deal} />
    </PipelineStatusProfileContext.Provider>
  );
};

export const ContractPipelineDropdown = (props) => {
  return <PipelineDropdown {...props} Type={PipelineTypeEnum.Contract} />;
};

export const SubscriptionPipelineDropdown = (props) => {
  return <PipelineDropdown {...props} Type={PipelineTypeEnum.Subscription} />;
};

export const ProjectPipelineDropdown = (props) => {
  return <PipelineDropdown {...props} Type={PipelineTypeEnum.Project} />;
};

export const SubscriptionStatusPipelineDropdown = (props) => {
  return (
    <PipelineStatusProfileContext.Provider value={ContractStatusProfile}>
      <PipelineStatusDropdown {...props} Type={PipelineTypeEnum.Subscription} />
    </PipelineStatusProfileContext.Provider>
  );
};

export const ContractStatusPipelineDropdown = (props) => {
  return (
    <PipelineStatusProfileContext.Provider value={ContractStatusProfile}>
      <PipelineStatusDropdown {...props} Type={PipelineTypeEnum.Contract} />
    </PipelineStatusProfileContext.Provider>
  );
};

export const TicketPipelineDropdown = (props) => {
  return <PipelineDropdown {...props} Type={PipelineTypeEnum.Ticket} />;
};

export const TicketStatusPipelineDropdown = (props) => {
  return <PipelineStatusDropdown {...props} Type={PipelineTypeEnum.Ticket} />;
};

export const TaskPipelineDropdown = (props) => {
  return <PipelineDropdown {...props} Type={PipelineTypeEnum.Task} />;
};

export const TaskStatusPipelineDropdown = (props) => {
  return <PipelineStatusDropdown {...props} Type={PipelineTypeEnum.Task} />;
};

export const ConnectedPipelineStatusListItem = ({ pipeline, ...rest }) => {
  const c = usePipelineStatus(pipeline);
  return <PipelineStatusListItem pipeline={c} {...rest} />;
};

const handleCancelDropdown = (Type) => {
  switch (Type) {
    case 1:
      return projectsCancellationReasonsDropdown;
    case 2:
      return ticketsCancellationReasonsDropdown;
    case 3:
      return callCancellationReasonsDropdown;
    case 4:
      return tasksCancellationReasonsDropdown;
    case 5:
      return contractsCancellationReasonsDropdown;
    case 6:
      return dealsCancellationReasonsDropdown;
    case 8:
      return SubscriptionsCancellationReasonsDropdown;
    default:
      return <div></div>;
  }
};

export const CancelationReasonDropdown = ({ Type, ...rest }) => {
  const Component = useMemo(() => {
    return handleCancelDropdown(Type);
  }, [Type]);
  return <Component {...rest} />;
};

export const LostReasonDropdown = ({ ...rest }) => {
  const Component = useMemo(() => {
    return dealsLostReasonsDropdown;
  }, []);

  return <Component {...rest} />;
};

const CancelationModalContent = ({
  cancel,
  onPost,
  onSubmit,
  loading,
  isLost,
  Type,
  dropdown,
  company
}) => {
  const [organization, setOrganization] = useState(company);
  const [value, setValue] = useState(null);
  const [description, setDescription] = useState("");
  const CancelationsReasonDropdown = handleCancelDropdown(Type);

  return (
    <div
      onDoubleClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      className="p-4 w-450px"
    >
      <div className="mb-2">
        <div className="mb-2 text-black fw-medium fs-14">
          <FormattedMessage id="COMPANY" />
        </div>
        <OrganizationDropdown
          value={organization}
          onChange={(e) => {
            setOrganization(e);
            setValue(null);
          }}
        />
      </div>
      <div className="mb-2">
        <div className="mb-2 text-black fw-medium fs-14">
          <FormattedMessage id="CANCELATION_REASON" />
        </div>
        {isLost ? (
          <LostReasonDropdown
            value={value}
            company={organization}
            onChange={setValue}
          />
        ) : (
          <CancelationsReasonDropdown
            value={value}
            company={organization}
            onChange={setValue}
          />
        )}
      </div>
      <div className="mb-4">
        <div className="mb-2 text-black fw-medium fs-14">
          <FormattedMessage id="DESCRIPTION" />
        </div>
        <textarea
          className="ssi-control ar-html-input rounded w-100"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </div>
      <div className="d-flex justify-content-end">
        <Button onClick={cancel} vType="link-danger mr-2">
          <FormattedMessage id="CANCEL" />
        </Button>
        <LoadableButton
          disabled={!value}
          onClick={() => onPost(value, description, organization)}
          isLoading={loading}
        >
          <FormattedMessage id="SUBMIT" />
        </LoadableButton>
      </div>
    </div>
  );
};

const CancelationModal = ({ state, loading, company }) => {
  const {
    isCancelling,
    close,
    fallback,
    post,
    value,
    oldValue,
    oldType,
    setOldValue,
    type,
    isLost,
    id
  } = state;
  const forcedClose = () => {
    fallback();
    close();
  };

  const createToast = useToast();
  const intl = useIntl();
  const handlePost = (v, d, o) => {
    const onSuccess = () => {
      const trasnlatadeType = intl.formatMessage({ id: oldType });
      createToast({
        pos: "tm",
        type: "success",
        title: `${trasnlatadeType} ${id}`,
        description: intl.formatMessage({ id: "STATUS_CHANGED_SUCCESS" })
      });
      close();
    };

    const onError = ({ error }) => {
      setOldValue(oldValue);
      handleError(createToast, error);
    };
    post(
      {
        PipelineStatusId: value,
        organizationspaceid: o,
        StatusDetailId: v,
        StatusDetailDescription: d
      },
      onSuccess,
      onError
    );
  };
  return (
    <Modal isOpen={isCancelling} disableClosure={loading} onClose={forcedClose}>
      <CancelationModalContent
        Type={type}
        loading={loading}
        onPost={handlePost}
        close={close}
        cancel={forcedClose}
        company={company}
        isLost={isLost}
      />
    </Modal>
  );
};

export const StatusPipelineGridprofile = (props) => {
  const { status, ...rest } = props;
  const pipelineStatus = usePipelineStatus(status);
  return <PipelineStatusProfile pipeline={pipelineStatus} {...rest} />;
};

export const StatusContractPipelineGridprofile = (props) => {
  const { status } = props;
  const pipelineStatus = usePipelineStatus(status);
  return (
    <PipelineStatusProfile
      StatusProfileComponent={ContractStatusProfile}
      pipeline={pipelineStatus}
    />
  );
};

const PipelineType = React.createContext(null);

export const StatusPipelineGridDropdown = (props) => {
  const {
    post,
    setStatus,
    loading,
    type,
    id,
    forcedProfileStatus,
    entityStatusTypes,
    pipelineType
  } = props;

  const [cancelationState, setCancelationState] = useState({
    isCancelling: false,
    type: pipelineType,
    oldType: type
  });

  const updateCancelationState = (update) => {
    setCancelationState((c) => {
      return {
        ...c,
        ...update
      };
    });
  };

  const closeCancelationModal = () => {
    updateCancelationState({
      isCancelling: false,
      type: pipelineType,
      oldType: type
    });
  };

  const HandleChanges = (value) => {
    const x = getEntity(spacePipelineStatusSchema, value);

    if (value === props.value) return;

    //if canceld
    if (
      x.Status === entityStatusTypes.canceled ||
      (pipelineType === PipelineTypeEnum.Deal &&
        x.Status === entityStatusTypes.lost)
    ) {
      const errorFallback = () => {
        setStatus(props.value);
      };

      setStatus(value);
      updateCancelationState({
        isCancelling: true,
        type: pipelineType,
        isLost:
          pipelineType === PipelineTypeEnum.Deal &&
          x.Status === entityStatusTypes.lost,
        close: closeCancelationModal,
        fallback: errorFallback,
        oldType: type,
        post,
        value,
        id,
        setOldValue: setStatus,
        oldValue: props.value
      });
    } else {
      props.onChange(value);
    }
    // const PipelineStatus = usePipelineStatus(value);
  };

  return (
    <>
      <PipelineStatusProfileContext.Provider
        value={
          forcedProfileStatus
            ? forcedProfileStatus
            : props.pipelineType === 6
            ? DealStatusProfile
            : props.pipelineType === 5 || props.pipelineType === 8
            ? ContractStatusProfile
            : null
        }
      >
        <PipelineType.Provider value={props.pipelineType}>
          <CancelationModal
            state={cancelationState}
            loading={loading}
            company={props.Company}
          />
          <PipelineStatusGridDropdown
            {...props}
            gridDropdownStatus
            labelClassName="w-100 text-truncate"
            className="of-hidden"
            onChange={HandleChanges}
            grid={true}
            Type={props.pipelineType}
          />
        </PipelineType.Provider>
      </PipelineStatusProfileContext.Provider>
    </>
  );
};

export const ProjectStatusPipelineDropdown = (props) => {
  return <PipelineStatusDropdown {...props} Type={PipelineTypeEnum.Project} />;
};

const PipelineListItem = ({
  pipeline,
  enableInput,
  isTyping,
  dispatch,
  grid,
  text,
  ...inputProps
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100 of-hidden">
        <div className="d-flex flex-column w-100 text-truncate">
          <div
            className={`dropdown-item-maintext d-flex fs-12 ${
              grid ? "fs-12" : ""
            }`}
          >
            {/* {!isTyping && pipeline && `${pipeline.Id} - `} */}
            {!enableInput && pipeline ? (
              <span className="text-truncate fs-12 w-100">{pipeline.Name}</span>
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={pipeline && pipeline.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
              // <input
              //   {...inputProps}
              //   value={
              //     isTyping || !contract
              //       ? inputProps.value
              //       : contract.Description
              //   }
              // />
            )}
          </div>
          {/* {!isTyping && (
						<React.Fragment>
							<div className="dropdown-item-subtext">
								{pipeline && pipeline.Name}
							</div>
							{/* <Moment
                fromNow
                className="dropdown-item-time mr-5"
                date={contract.BeginDate}
              /> 
						</React.Fragment>
					*/}
        </div>
      </div>
    </div>
  );
};

export default PipelineListItem;

export const ConnectedPipelineListItem = ({
  pipeline,
  resolvedPipeline,
  ...rest
}) => {
  const c = usePipeline(pipeline);
  return <PipelineListItem pipeline={resolvedPipeline || c} {...rest} />;
};

export const PipelineStatusProfileContext = React.createContext();

export const PipelineStatusListItem = ({
  pipeline,
  enableInput,
  isTyping,
  dispatch,
  grid = false,
  hideCancelation,
  focusonmount,
  text,
  ...inputProps
}) => {
  const Context = useContext(PipelineStatusProfileContext);

  const StatusComponent = Context || ProjectStatusProfile;

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center of-hidden w-100">
        <div className="d-flex flex-column w-100 text-truncate">
          <div
            className={classNames("dropdown-item-maintext fs-12 d-flex", {
              "fs-12": grid
            })}
          >
            {!isTyping && (
              <React.Fragment>
                <StatusComponent
                  hideName={enableInput}
                  pipelineName={pipeline?.Name}
                  status={pipeline && pipeline.Status}
                />

                {/* <Moment
                fromNow
                className="dropdown-item-time mr-5"
                date={contract.BeginDate}
              /> */}
              </React.Fragment>
            )}
            {!enableInput && pipeline ? (
              // <span className="text-truncate">{pipeline.Name}</span>
              <span></span>
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={pipeline && pipeline.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
              // <input
              //   {...inputProps}
              //   value={
              //     isTyping || !contract
              //       ? inputProps.value
              //       : contract.Description
              //   }
              // />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ConnectedPipelineStatusGridListItem = ({ pipeline, ...rest }) => {
  const c = usePipelineStatus(pipeline);
  return <PipelineStatusGridListItem pipeline={c} {...rest} />;
};

export const StatusCompContext = React.createContext();

export const PipelineStatusProfile = ({
  pipeline,
  StatusProfileComponent,
  ...rest
}) => {
  const StatusCompProvided = useContext(StatusCompContext);
  const StatusComp =
    StatusCompProvided || StatusProfileComponent || ProjectStatusProfile;

  return (
    <>
      <PipelineType.Consumer>
        {(value) => {
          return value === 6 ? (
            <div className="d-flex text-truncate">
              <DealStatusProfile
                status={pipeline && pipeline.Status}
                pipelineName={pipeline.Name}
              />
            </div>
          ) : (
            <div className="d-flex text-truncate">
              <StatusComp
                status={pipeline && pipeline.Status}
                pipelineName={pipeline.Name}
              />
            </div>
          );
        }}
      </PipelineType.Consumer>
    </>
  );
};

export const PipelineStatusGridListItem = ({
  pipeline,
  enableInput,
  isTyping,
  dispatch,
  grid = false,
  text,
  ...inputProps
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center of-hidden w-100">
        <div className="d-flex flex-column w-100 text-truncate">
          <div
            className={classNames(
              "dropdown-item-maintext d-flex fs-12 w-100 text-truncate",
              {
                "fs-12": grid
              }
            )}
          >
            {!isTyping && (
              <PipelineStatusProfile {...inputProps} pipeline={pipeline} />
            )}

            {/* <DropdownInput
              className="flat-input mb-0 fs-12"
              enableInput={enableInput}
              isTyping={isTyping}
              {...inputProps}
            /> */}
            {/* //
              //   {...inputProps}
              //   value={
              //     isTyping || !contract
              //       ? inputProps.value
              //       : contract.Description
              //   }
              // /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
