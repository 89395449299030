import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import querystring from "query-string";
import { v1 as uuidv1 } from "uuid";
import {
  spaceBillingSchema,
  spaceCallsSchema,
  spaceClassificationSchema,
  spaceContactSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceInterventionsSchema,
  spaceProjectsSchema,
  spaceSocialPostsSchema,
  spaceSubscriptionSchema,
  spaceTasksSchema,
  spaceTicketsSchema
} from "../../config/schema";
import {
  useSpaceDelete,
  useSpaceMultiplePost,
  useSpacePostQuery
} from "../../Helpers/IOClient";
import { useSpace } from "../../Contexts/SpaceContext";
import SuspenseLoader from "../SuspenseLoader/SuspenseLoader";
import { useToast } from "../Toast/ToastProvider";
import { useIntl } from "react-intl";
import { AppFavoriteContext } from "../../Containers/AppBar/AppFavoriteProvider";
import { HandleQueryStringObjectMaker } from "../../Helpers/QueryStringerHelper";
import {
  Communicator,
  createCommunicatorHook
} from "../../Helpers/Communicator";
export const ServerAwareViewsContext = React.createContext();
const ServerAwareViewsDeleteLoading = React.createContext();
export const ServerAwareUpdateViewsContext = React.createContext();
export const ServerAwareUpdateTempViewsContext = React.createContext();

export const colorUpdateCommunicator = new Communicator();

export const useColorUpdaterCommunicator = createCommunicatorHook(
  colorUpdateCommunicator
);

export const useServerAwareViewsDeleteLoading = () => {
  return useContext(ServerAwareViewsDeleteLoading);
};

// eslint-disable-next-line no-unused-vars
const oldGridNamesDict = {
  [spaceBillingSchema.name]: "billing",
  [spaceCallsSchema.name]: "calls",
  [spaceClassificationSchema.name]: "classification",
  [spaceContactSchema.name]: "contacts",
  [spaceContractSchema.name]: "contracts",
  [spaceDealsSchema.name]: "deals",
  [spaceInterventionsSchema.name]: "interventions",
  [spaceProjectsSchema.name]: "projects",
  [spaceSocialPostsSchema.name]: "socialPosts",
  [spaceSubscriptionSchema.name]: "subscriptions",
  [spaceTasksSchema.name]: "tasks",
  [spaceTicketsSchema.name]: "tickets"
};

const ServerAwareViewsContent = ({
  schemaViews,
  setSchemaViews,
  viewName,
  children
}) => {
  const location = useLocation();
  const { search } = location;
  const space = useSpace();
  const queryObj = querystring.parse(search);

  const resolvedQueryObj = useMemo(() => {
    let tempQueryObj = HandleQueryStringObjectMaker(queryObj);
    Object.keys(tempQueryObj).forEach((key) => {
      if (tempQueryObj[key] === undefined) {
        delete tempQueryObj[key];
      }
    });
    return tempQueryObj;
  }, [queryObj]);
  const { viewPref } = resolvedQueryObj;

  // const [schemaViews, setSchemaViews] = useState(() => {
  //   let gridSavedViews = JSON.parse(
  //     window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
  //   );

  //   if (
  //     gridSavedViews &&
  //     gridSavedViews[viewName] &&
  //     gridSavedViews[viewName].length > 0
  //   ) {
  //     const { name } = gridSavedViews[viewName][0];
  //     if (name !== "Default View") {
  //       let gridOldLayouts = JSON.parse(
  //         window.localStorage.getItem("ar-grid-column-layout")
  //       );
  //       let gridOldKanbanLayouts = JSON.parse(
  //         window.localStorage.getItem("ar-grid-kanban-layout")
  //       );
  //       let gridOldSizes = JSON.parse(
  //         window.localStorage.getItem("ar-grid-columns-size")
  //       );
  //       let gridOldKanbanPipelineStatus = JSON.parse(
  //         window.localStorage.getItem("ar-grid-pipelineStatus-layout")
  //       );
  //       let gridOldToggleFIlterSidebar = JSON.parse(
  //         window.localStorage.getItem("ar-filter-list-open")
  //       );
  //       const oldColumnLayout = gridOldLayouts
  //         ? gridOldLayouts[oldGridNamesDict[viewName]]
  //         : undefined;
  //       const oldKanbanLayout = gridOldKanbanLayouts
  //         ? gridOldKanbanLayouts[oldGridNamesDict[viewName]]
  //         : [];
  //       const oldKanbanPipelineStatus = gridOldKanbanPipelineStatus
  //         ? gridOldKanbanPipelineStatus[oldGridNamesDict[viewName]]
  //         : {};
  //       const oldColumnSizes = gridOldSizes
  //         ? gridOldSizes[viewName]
  //         : undefined;

  //       const id = uuidv1();

  //       const Views = [...gridSavedViews[viewName]];
  //       const uniqViews = Views.filter((value, index, self) => {
  //         return self.findIndex((v) => v.id === value.id) === index;
  //       });

  //       const newGridViews = {
  //         ...gridSavedViews,
  //         [viewName]: [
  //           {
  //             search: search,
  //             name: "Default View",
  //             id,
  //             columnLayout: oldColumnLayout,
  //             kanbanLayout: oldKanbanLayout,
  //             pipelineStatus: oldKanbanPipelineStatus,
  //             columnSize: oldColumnSizes,
  //             filterSideToggleStatus: gridOldToggleFIlterSidebar || 1
  //           },
  //           ...uniqViews
  //         ]
  //       };

  //       // localStorage.setItem(
  //       //   `ar-grid-saved-views-space-${space.Id}`,
  //       //   JSON.stringify({
  //       //     ...newGridViews,
  //       //   })
  //       // );
  //       return newGridViews[viewName];
  //     } else return gridSavedViews[viewName];
  //   } else if (gridSavedViews) {
  //     let gridOldLayouts = JSON.parse(
  //       window.localStorage.getItem("ar-grid-column-layout")
  //     );
  //     let gridOldKanbanLayouts = JSON.parse(
  //       window.localStorage.getItem("ar-grid-kanban-layout")
  //     );
  //     let gridOldSizes = JSON.parse(
  //       window.localStorage.getItem("ar-grid-columns-size")
  //     );
  //     let gridOldKanbanPipelineStatus = JSON.parse(
  //       window.localStorage.getItem("ar-grid-pipelineStatus-layout")
  //     );
  //     let gridOldToggleFIlterSidebar = JSON.parse(
  //       window.localStorage.getItem("ar-filter-list-open")
  //     );
  //     const oldColumnLayout = gridOldLayouts
  //       ? gridOldLayouts[oldGridNamesDict[viewName]]
  //       : undefined;
  //     const oldKanbanLayout = gridOldKanbanLayouts
  //       ? gridOldKanbanLayouts[oldGridNamesDict[viewName]]
  //       : [];
  //     const oldKanbanPipelineStatus = gridOldKanbanPipelineStatus
  //       ? gridOldKanbanPipelineStatus[oldGridNamesDict[viewName]]
  //       : {};
  //     const oldColumnSizes = gridOldSizes ? gridOldSizes[viewName] : undefined;

  //     const id = uuidv1();
  //     const newGridViews = {
  //       ...gridSavedViews,
  //       [viewName]: [
  //         {
  //           search: search,
  //           name: "Default View",
  //           id,
  //           columnLayout: oldColumnLayout,
  //           kanbanLayout: oldKanbanLayout,
  //           pipelineStatus: oldKanbanPipelineStatus,
  //           columnSize: oldColumnSizes,
  //           filterSideToggleStatus: gridOldToggleFIlterSidebar || 1
  //         }
  //       ]
  //     };

  //     // localStorage.setItem(
  //     //   `ar-grid-saved-views-space-${space.Id}`,
  //     //   JSON.stringify({
  //     //     ...newGridViews,
  //     //   })
  //     // );
  //     return newGridViews[viewName];
  //   } else {
  //     let gridOldLayouts = JSON.parse(
  //       window.localStorage.getItem("ar-grid-column-layout")
  //     );
  //     let gridOldKanbanLayouts = JSON.parse(
  //       window.localStorage.getItem("ar-grid-kanban-layout")
  //     );
  //     let gridOldSizes = JSON.parse(
  //       window.localStorage.getItem("ar-grid-columns-size")
  //     );
  //     let gridOldKanbanPipelineStatus = JSON.parse(
  //       window.localStorage.getItem("ar-grid-pipelineStatus-layout")
  //     );
  //     let gridOldToggleFIlterSidebar = JSON.parse(
  //       window.localStorage.getItem("ar-filter-list-open")
  //     );
  //     const oldColumnLayout = gridOldLayouts
  //       ? gridOldLayouts[oldGridNamesDict[viewName]]
  //       : undefined;
  //     const oldKanbanPipelineStatus = gridOldKanbanPipelineStatus
  //       ? gridOldKanbanPipelineStatus[oldGridNamesDict[viewName]]
  //       : {};
  //     const oldColumnSizes = gridOldSizes ? gridOldSizes[viewName] : undefined;
  //     const oldKanbanLayout = gridOldKanbanLayouts
  //       ? gridOldKanbanLayouts[oldGridNamesDict[viewName]]
  //       : [];
  //     const id = uuidv1();
  //     // localStorage.setItem(
  //     //   `ar-grid-saved-views-space-${space.Id}`,
  //     //   JSON.stringify({
  //     //     [viewName]: [
  //     //       {
  //     //         search: search,
  //     //         name: "Default View",
  //     //         id,
  //     //         columnLayout: oldColumnLayout,
  //     //         kanbanLayout: oldKanbanLayout,
  //     //         pipelineStatus: oldKanbanPipelineStatus,
  //     //         columnSize: oldColumnSizes,
  //     //         filterSideToggleStatus: gridOldToggleFIlterSidebar || 1,
  //     //       },
  //     //     ],
  //     //   })
  //     // );
  //     return [
  //       {
  //         search: search,
  //         name: "Default View",
  //         id,
  //         kanbanLayout: oldKanbanLayout,
  //         columnLayout: oldColumnLayout,
  //         pipelineStatus: oldKanbanPipelineStatus,
  //         columnSize: oldColumnSizes,
  //         filterSideToggleStatus: gridOldToggleFIlterSidebar || 1
  //       }
  //     ];
  //   }
  // });

  const post = useSpaceMultiplePost();

  const UpdateSchemaViewMultipleBody = useMemo(() => {
    const keyArr = schemaViews?.map((e) => {
      return {
        Key: `air-abv-views-by-space-${space.Id}-view-${viewName}-tab-${e.id}`,
        value: JSON.stringify({ ...e })
      };
    });

    return keyArr;
  }, [schemaViews, space.Id, viewName]);

  const UpdateAbvSchemaViews = useMemo(() => {
    const resolvedSchemaViews = schemaViews?.map((e) => {
      return { name: e.name, id: e.id };
    });

    return {
      key: `air-abv-views-by-space-${space.Id}-view-${viewName}`,
      value: JSON.stringify(resolvedSchemaViews)
    };
  }, [schemaViews, space.Id, viewName]);

  const EnableUpdatePreference = useRef(false);
  const createToast = useToast();
  const intl = useIntl();

  useEffect(() => {
    if (EnableUpdatePreference.current) {
      EnableUpdatePreference.current = false;

      const handlePosts = async () => {
        const { error: ErrorSingle } = await post(
          "Preference/Set",
          UpdateAbvSchemaViews
        );
        const { error: ErrorMultiple } = await post(
          "Preference/SetMultiple",
          UpdateSchemaViewMultipleBody
        );

        if (ErrorSingle || ErrorMultiple) {
          createToast({
            pos: "tm",
            type: "danger",
            description: intl.formatMessage({
              id: "VIEW_SAVING_ERROR"
            })
          });
        } else
          createToast({
            pos: "tm",
            type: "success",
            description: intl.formatMessage({ id: "SUCCESS_SAVED" })
          });
      };
      handlePosts();
    }
  }, [
    UpdateAbvSchemaViews,
    UpdateSchemaViewMultipleBody,
    createToast,
    intl,
    post
  ]);

  const [deletePref, { loading }] = useSpaceDelete(`Preference/DeleteMultiple`);

  const updateSchemaViews = useCallback(() => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );
    const localStorageSchemaviews = gridSavedViews[viewName] || [];
    if (localStorageSchemaviews.length < schemaViews.length) {
      const missingItems = schemaViews.filter(
        (item) => localStorageSchemaviews.indexOf(item) === -1
      );

      if (missingItems.length > 0) {
        const body = missingItems.map((e) => {
          return {
            key: `air-abv-views-by-space-${space.Id}-view-${viewName}-tab-${e.id}`
          };
        });
        deletePref(body);
      }
    }
    EnableUpdatePreference.current = true;
    setSchemaViews(localStorageSchemaviews);
  }, [deletePref, schemaViews, setSchemaViews, space.Id, viewName]);

  const history = useHistory();
  const FavoriteId = useContext(AppFavoriteContext);

  const [isMounted, setIsMounted] = useState(false);

  useLayoutEffect(() => {
    if (isMounted) return;
    if (viewPref) {
      const Lsindex = schemaViews.findIndex((e) => e.id === viewPref);
      if (schemaViews[Lsindex]) {
        // const viewGridViews = schemaViews[viewName];
        // const oldSearch = schemaViews[Lsindex].search;
        // const index = viewGridViews.findIndex((e) => e.id === viewPref);
        const obj = querystring.parse(schemaViews[Lsindex].search);
        const resolvedObj = HandleQueryStringObjectMaker(obj);
        if (FavoriteId) resolvedObj.favoriteId = FavoriteId;
        const newObj = { ...resolvedObj, ...resolvedQueryObj };

        // viewGridViews[index].search = querystring.stringify(searchObj);
        // gridSavedViews[viewName] = viewGridViews;
        // localStorage.setItem(
        //   `ar-grid-saved-views-space-${space.Id}`,
        //   JSON.stringify(gridSavedViews)
        // );

        history.replace({
          search: querystring.stringify(newObj)
        });
      } else if (Lsindex === -1) {
        // const parsed = querystring.parse(search);
        // parsed.viewPref = viewGridViews[index].id;
        const obj = querystring.parse(schemaViews[0]?.search || "");
        const resolvedObj = HandleQueryStringObjectMaker(obj);
        if (FavoriteId) resolvedObj.favoriteId = FavoriteId;
        const newObj = { ...resolvedObj, ...resolvedQueryObj };

        history.replace({
          search: querystring.stringify(newObj)
        });
      }
    } else if (schemaViews) {
      // const viewGridViews = schemaViews[viewName];
      // const index = 0;
      // const parsed = querystring.parse(search);
      // parsed.viewPref = viewGridViews[index].id;

      const schemViewsObj = querystring.parse(schemaViews[0].search);
      const schemViewsresolvedObj = HandleQueryStringObjectMaker(schemViewsObj);
      if (FavoriteId) schemViewsresolvedObj.favoriteId = FavoriteId;
      const schemViewnewObj = { ...schemViewsresolvedObj, ...resolvedQueryObj };

      history.replace({
        search: querystring.stringify(schemViewnewObj)
      });
    }
    setIsMounted(true);
  }, [
    search,
    schemaViews,
    viewName,
    history,
    viewPref,
    space.Id,
    FavoriteId,
    queryObj,
    isMounted,
    resolvedQueryObj
  ]);

  if (!isMounted) return null;

  return (
    <ServerAwareViewsContext.Provider value={schemaViews}>
      <ServerAwareViewsDeleteLoading.Provider value={loading}>
        <ServerAwareUpdateViewsContext.Provider value={updateSchemaViews}>
          <ServerAwareTempViewsProvider viewName={viewName}>
            {children}
          </ServerAwareTempViewsProvider>
        </ServerAwareUpdateViewsContext.Provider>
      </ServerAwareViewsDeleteLoading.Provider>
    </ServerAwareViewsContext.Provider>
  );
};

const ServerAwareTempViewsProvider = ({ children, viewName }) => {
  const oldSchemaViews = useContext(ServerAwareViewsContext);
  const space = useSpace();
  const [newSchemaViews, setNewSchemaViews] = useState(() => {
    return oldSchemaViews;
  });

  const handleUpdateTempViews = useCallback(() => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );
    const localStorageSchemaviews = gridSavedViews[viewName] || [];
    setNewSchemaViews(localStorageSchemaviews);
  }, [space.Id, viewName]);

  const isMountRef = useRef(false);

  useEffect(() => {
    if (isMountRef.current) {
      setNewSchemaViews(oldSchemaViews);
      return;
    }
    isMountRef.current = true;
  }, [oldSchemaViews]);

  // const optionallyUpdateViewsUrl = useCallback(
  //   (newSearch) => {
  //
  //     const newSearchObj = querystring.parse(newSearch);
  //     const resolvedNewSearchObj = HandleQueryStringObjectMaker(newSearchObj);
  //     const { viewPref } = resolvedNewSearchObj;

  //     if (!viewPref) return;

  //     const obj = { ...resolvedNewSearchObj };
  //     delete obj.favoriteId;

  //     let gridSavedViews = JSON.parse(
  //       window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
  //     );
  //     const viewGridViews = gridSavedViews[viewName];
  //     const index = viewGridViews.findIndex((e) => e.id === viewPref);
  //     let resolvedIndex = index === -1 ? 0 : index;
  //     let viewGridObject = viewGridViews[resolvedIndex] || {};

  //     viewGridObject.search = querystring.stringify(obj);

  //     viewGridViews[resolvedIndex] = viewGridObject;
  //     gridSavedViews[viewName] = viewGridViews;
  //     localStorage.setItem(
  //       `ar-grid-saved-views-space-${space.Id}`,
  //       JSON.stringify(gridSavedViews)
  //     );
  //   },
  //   [space.Id, viewName]
  // );

  // const location = useLocation();

  // useEffect(() => {
  //   optionallyUpdateViewsUrl(location.search);
  // }, [location.search, optionallyUpdateViewsUrl]);

  return (
    <ServerAwareViewsContext.Provider value={newSchemaViews}>
      <ServerAwareUpdateTempViewsContext.Provider value={handleUpdateTempViews}>
        {children}
      </ServerAwareUpdateTempViewsContext.Provider>
    </ServerAwareViewsContext.Provider>
  );
};

function insertAndShift(arr, from, to) {
  let cutOut = arr.splice(from, 1)[0];
  arr.splice(to, 0, cutOut);
}

const ServerAwarePreContent = ({
  schemaViews,
  setSchemaViews,
  viewName,
  children,
  viewPref
}) => {
  const space = useSpace();

  const getSchemaViewMultipleBody = useMemo(() => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );

    if (!gridSavedViews) {
      return null;
    }

    let schemaViews = gridSavedViews[viewName];

    if (Array.isArray(schemaViews) && schemaViews.length > 0) {
      const keyArr = schemaViews.map((e) => {
        return {
          key: `air-abv-views-by-space-${space.Id}-view-${viewName}-tab-${e.id}`
        };
      });

      return keyArr;
    }

    return null;
  }, [space.Id, viewName]);

  const { loading } = useSpacePostQuery(
    Boolean(getSchemaViewMultipleBody) ? `Preference/GetMutiple` : null,
    getSchemaViewMultipleBody,
    null,
    {
      cache: false,
      onSuccess: ({ data }) => {
        try {
          let gridSavedViews =
            JSON.parse(
              window.localStorage.getItem(
                `ar-grid-saved-views-space-${space.Id}`
              )
            ) || {};

          if (data.length === 0 && gridSavedViews[viewName].length > 0) {
            throw Object.assign(new Error("Empty data. Use local storage"), {
              code: 509
            });
          }

          const resolvedParsedData = data.map((e) => {
            return JSON.parse(e.Value);
          });

          let sortedResolvedParsedData = [...resolvedParsedData];

          if (sortedResolvedParsedData[0].name !== "Default View") {
            // eslint-disable-next-line no-unused-vars
            const dVIndex = sortedResolvedParsedData.findIndex(
              (e) => e.name === "Default View"
            );
          }

          gridSavedViews[viewName] = sortedResolvedParsedData;
          // // if (gridSavedViews && gridSavedViews[viewName]) {
          setSchemaViews(gridSavedViews[viewName]);
          localStorage.setItem(
            `ar-grid-saved-views-space-${space.Id}`,
            JSON.stringify({
              ...gridSavedViews
            })
          );
        } catch (e) {
          let gridSavedViews2 =
            JSON.parse(
              window.localStorage.getItem(
                `ar-grid-saved-views-space-${space.Id}`
              )
            ) || {};
          if (Array.isArray(gridSavedViews2[viewName])) {
            const Views = [...gridSavedViews2[viewName]];
            const uniqViews = Views.filter((value, index, self) => {
              return self.findIndex((v) => v.id === value.id) === index;
            });

            const hasDefaultInuniq = uniqViews.findIndex(
              (e) => e.name === "Default View"
            );

            const newGridViews =
              hasDefaultInuniq >= 0
                ? {
                    ...gridSavedViews2,
                    [viewName]: [...uniqViews]
                  }
                : {
                    ...gridSavedViews2,
                    [viewName]: [
                      {
                        name: "Default View",
                        id: uuidv1(),
                        filterSideToggleStatus: 1
                      },
                      ...uniqViews
                    ]
                  };
            setSchemaViews(newGridViews[viewName]);
            localStorage.setItem(
              `ar-grid-saved-views-space-${space.Id}`,
              JSON.stringify({
                ...newGridViews
              })
            );
          } else {
            const newGridViews = {
              ...gridSavedViews2,
              [viewName]: [
                {
                  name: "Default View",
                  id: uuidv1(),
                  filterSideToggleStatus: 1
                }
              ]
            };
            setSchemaViews(newGridViews[viewName]);
            localStorage.setItem(
              `ar-grid-saved-views-space-${space.Id}`,
              JSON.stringify({
                ...newGridViews
              })
            );
          }
        }
      },
      onError: ({ error }) => {
        let gridSavedViews =
          JSON.parse(
            window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
          ) || {};
        if (Array.isArray(gridSavedViews[viewName])) {
          const Views = [...gridSavedViews[viewName]];
          const uniqViews = Views.filter((value, index, self) => {
            return self.findIndex((v) => v.id === value.id) === index;
          });

          const hasDefaultInuniq = uniqViews.findIndex(
            (e) => e.name === "Default View"
          );

          const newGridViews =
            hasDefaultInuniq >= 0
              ? {
                  ...gridSavedViews,
                  [viewName]: [...uniqViews]
                }
              : {
                  ...gridSavedViews,
                  [viewName]: [
                    {
                      name: "Default View",
                      id: uuidv1(),
                      filterSideToggleStatus: 1
                    },
                    ...uniqViews
                  ]
                };
          setSchemaViews(newGridViews[viewName]);
          localStorage.setItem(
            `ar-grid-saved-views-space-${space.Id}`,
            JSON.stringify({
              ...newGridViews
            })
          );
        } else {
          const newGridViews = {
            ...gridSavedViews,
            [viewName]: [
              {
                name: "Default View",
                id: uuidv1(),
                filterSideToggleStatus: 1
              }
            ]
          };
          setSchemaViews(newGridViews[viewName]);
          localStorage.setItem(
            `ar-grid-saved-views-space-${space.Id}`,
            JSON.stringify({
              ...newGridViews
            })
          );
        }

        if (error && error.status === 404) {
        } else if (error && error.status === 500) {
        }
      }
    }
  );

  if (loading) {
    return <SuspenseLoader />;
    // <LoaderSpinner size="sm" className="text-primary" center />;
  }
  return (
    <ServerAwareViewsContent
      setSchemaViews={setSchemaViews}
      schemaViews={schemaViews}
      key={viewPref}
      viewName={viewName}
    >
      {children}
    </ServerAwareViewsContent>
  );
};

function removeDuplicateGenralViews(arr) {
  const newArr = [];
  if (arr.length === 0) {
    newArr.push({
      name: "Default View",
      id: uuidv1(),
      filterSideToggleStatus: 1
    });
  } else {
    newArr.push(arr[0]);
  }
  const filtedArr = arr.filter((e) => e.name !== "Default View");
  newArr.push(...filtedArr);
  return newArr;
}

const ServerAwareViews = ({ viewPref, viewName, children }) => {
  const space = useSpace();

  const postPrefereceBody = useMemo(() => {
    return {
      key: `air-abv-views-by-space-${space.Id}-view-${viewName}`
    };
  }, [space, viewName]);

  const [schemaViews, setSchemaViews] = useState(null);

  const { loading } = useSpacePostQuery(`Preference`, postPrefereceBody, null, {
    cache: false,
    onSuccess: ({ data }) => {
      if (data !== "NO_ITEMS") {
        try {
          let gridSavedViews = JSON.parse(
            window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
          );
          let parsedData = JSON.parse(data) || [];
          if (gridSavedViews && gridSavedViews[viewName]) {
            const unsortedResolvedItems = [...parsedData];
            const indexOfGeneral = unsortedResolvedItems.findIndex(
              (e) => e.name === "Default View"
            );

            if (indexOfGeneral !== 0 && indexOfGeneral !== -1) {
              insertAndShift(unsortedResolvedItems, indexOfGeneral, 0);
            }
            const resolvedItems = Array.isArray(gridSavedViews[viewName])
              ? gridSavedViews[viewName].filter(
                  (e) => e.name !== "Default View"
                )
              : [];
            resolvedItems.unshift(unsortedResolvedItems[0]);

            if (indexOfGeneral !== 0 && indexOfGeneral !== -1) {
              gridSavedViews[viewName] =
                removeDuplicateGenralViews(resolvedItems);
            } else {
              gridSavedViews[viewName] = removeDuplicateGenralViews(parsedData);
            }

            setSchemaViews(gridSavedViews[viewName]);
            localStorage.setItem(
              `ar-grid-saved-views-space-${space.Id}`,
              JSON.stringify({
                ...gridSavedViews
              })
            );
          } else if (gridSavedViews) {
            const unsortedResolvedItemsl = [...parsedData];
            const indexOfGenerall = unsortedResolvedItemsl.findIndex(
              (e) => e.name === "Default View"
            );

            if (indexOfGenerall !== 0 && indexOfGenerall !== -1) {
              insertAndShift(unsortedResolvedItemsl, indexOfGenerall, 0);
            }
            const resolvedItems = Array.isArray(gridSavedViews[viewName])
              ? gridSavedViews[viewName].filter(
                  (e) => e.name !== "Default View"
                )
              : [];
            resolvedItems.unshift(unsortedResolvedItemsl[0]);

            if (indexOfGenerall !== 0 && indexOfGenerall !== -1) {
              gridSavedViews[viewName] =
                removeDuplicateGenralViews(resolvedItems);
            } else {
              gridSavedViews[viewName] = removeDuplicateGenralViews(parsedData);
            }

            const newGridViewsl = {
              ...gridSavedViews
            };
            setSchemaViews(newGridViewsl[viewName]);
            localStorage.setItem(
              `ar-grid-saved-views-space-${space.Id}`,
              JSON.stringify({
                ...newGridViewsl
              })
            );
          } else {
            const unsortedResolvedItemsll = parsedData || [];
            const indexOfGeneralll = unsortedResolvedItemsll.findIndex(
              (e) => e.name === "Default View"
            );

            if (indexOfGeneralll !== 0 && indexOfGeneralll !== -1) {
              insertAndShift(unsortedResolvedItemsll, indexOfGeneralll, 0);
            }

            setSchemaViews(unsortedResolvedItemsll);
            localStorage.setItem(
              `ar-grid-saved-views-space-${space.Id}`,
              JSON.stringify({
                [viewName]: unsortedResolvedItemsll
              })
            );
          }
        } catch {}
      } else {
        const resolvedDatallll = [];
        let localStorageItems =
          JSON.parse(
            localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
          ) || {};
        // const unsortedResolvedItemslll = [];
        // const indexOfGenerallllll = unsortedResolvedItemslll.findIndex(
        //   (e) => e.name === "Default View"
        // );

        // if (indexOfGenerallllll !== 0 && indexOfGenerallllll !== -1) {
        //   insertAndShift(unsortedResolvedItemslll, indexOfGenerallllll, 0);
        // }
        const resolvedItemsllllll = Array.isArray(localStorageItems[viewName])
          ? localStorageItems[viewName]
          : [];
        // resolvedItemsllllll.unshift(unsortedResolvedItemslll[0]);

        // if (indexOfGenerallllll !== 0 && indexOfGenerallllll !== -1) {
        //   resolvedDatallll.push(
        //     ...removeDuplicateGenralViews(resolvedItemsllllll)
        //   );
        // } else {
        resolvedDatallll.push(
          ...removeDuplicateGenralViews(resolvedItemsllllll)
        );
        // }
        setSchemaViews(resolvedDatallll);
        localStorage.setItem(
          `ar-grid-saved-views-space-${space.Id}`,
          JSON.stringify({
            ...localStorageItems,
            [viewName]: resolvedDatallll
          })
        );
      }
    },
    onError: ({ error }) => {
      const resolvedDataError = [];
      let gridSavedViewsError =
        JSON.parse(
          window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
        ) || {};

      const unsortedResolvedItemsError = gridSavedViewsError[viewName] || [];
      resolvedDataError.push(
        ...removeDuplicateGenralViews(unsortedResolvedItemsError)
      );

      setSchemaViews(resolvedDataError);

      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify({
          ...gridSavedViewsError,
          [viewName]: resolvedDataError
        })
      );

      if (error && error.status === 404) {
      } else if (error && error.status === 500) {
      }
    }
  });

  if (loading) {
    // return <LoaderSpinner size="sm" className="text-primary" center />;
    return <SuspenseLoader />;
  }

  return (
    <ServerAwarePreContent
      schemaViews={schemaViews}
      setSchemaViews={setSchemaViews}
      viewPref={viewPref}
      viewName={viewName}
    >
      {children}
    </ServerAwarePreContent>
  );
};

export default ServerAwareViews;
