const createMainFormReducer = reducer => (state = {}, action) => {
  const { internalType } = action;

  if (internalType !== "form") return state;

  const { name } = action;

  if (!name) return state;

  const currentFormState = state[name];

  const newFormState = reducer(currentFormState, action);

  if (newFormState !== undefined && newFormState !== currentFormState)
    return {
      ...state,
      [name]: newFormState
    };
  else return state;
};

const form = (
  state = {
    isProcessing: false,
    isUpdating: false,
    error: null,
    entityId: null
  },
  action
) => {
  const { actionName } = action;

  switch (action.type) {
    case `CREATE_OR_UPDATE_${actionName}_REQUEST`:
      return {
        ...state,
        isProcessing: true,
        entityId: action.entityId,
        isUpdate: action.isUpdate,
        error: null
      };

    case `CREATE_OR_UPDATE_${actionName}_SUCCESS`:
      return {
        ...state,
        entityId: action.entityId,
        isProcessing: false,
        error: null
      };

    case `CREATE_OR_UPDATE_${actionName}_ERROR`:
      return {
        ...state,
        isProcessing: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default createMainFormReducer(form);
