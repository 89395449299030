import React from "react";
import DropdownInput, {
  DropdownInputContainer,
} from "../../../Components/Dropdown/DropdownInput";
import { useApiToken } from "../../../Hooks/EntityHooks";

export const ApiKeyDropdownInput = (props) => {
  const { id } = props;

  const { Token } = useApiToken(id) || {};

  return (
    <DropdownInputContainer className="w-100">
      <DropdownInput {...props} className="w-100" fallbackValue={Token} />
    </DropdownInputContainer>
  );
};

export const ApiKeyDropdownValue = ({ id }) => {
  const { Token } = useApiToken(id) || {};
  return <span>{Token}</span>;
};
