import { faCamera } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";
import CreatedBy from "../../Components/CreatedByComponent/CreatedBy";
import DocTyper from "../../Components/DocTyper/DocTyper";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import { AccountProfileImg } from "../../Components/Img/Img";
import { ProfilePicture } from "../../Components/ProfilePicture/ProfilePicture";
import { useCurrentUserContactPermission } from "../../Helpers/ContactPermissionsHelper";
import { ClientDropdown, ContactStatusDropdown } from "../AdvancedMultiInputs";
import { ContactEmailInput, ContactPhoneInput } from "./ContactFormHelper";
import GDPR from "./GDPR";

const ContactFormContent = ({ formState, id, canEdit, isChild }) => {
  const { form, handleChange } = formState;
  const { Creator, Clients, Name, CreationDate, Image } = form;
  const handleClientChange = (e) => {
    handleChange({
      Clients: e.target.value,
      Contact: null
    });
  };

  const handleNameChange = (e) => {
    handleChange({
      Name: e.target.value
    });
  };

  const noContactReadPermission = !useCurrentUserContactPermission();

  const { Email, MobileNumber, PhoneNumber } = form;
  return (
    <>
      <FormInput
        name="Clients"
        textId="ACCOUNT"
        inputType={ClientDropdown}
        value={Clients}
        className="mb-3"
        onChange={handleClientChange}
        multiple
        allClients={false}
        disabled={isChild}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="DETAILS" />
      </div>
      <div className="d-flex mb-3">
        <FormInput
          name="Image"
          className="mr-3"
          textId="THUMBNAIL"
          inputType={ProfilePicture}
          uploadText={
            <div className="link-color fs-12 mt-1 mr-3">
              <FontAwesomeIcon icon={faCamera} />{" "}
              <FormattedMessage id="UPLOAD_IMAGE" />
            </div>
          }
          placeHolderComponent={
            <AccountProfileImg
              className="rounded-50"
              account={{ Name, Image }}
            />
          }
        />
        <FormInput
          className="flex-1"
          textId="NAME"
          name="Name"
          value={Name}
          onChange={handleNameChange}
        />
      </div>

      <ContactPhoneInput
        textId="PHONE_NUMBER"
        extraClients={Clients}
        name="PhoneNumber"
        value={PhoneNumber}
        noPermission={noContactReadPermission}
      />

      <ContactEmailInput
        value={Email}
        extraClients={Clients}
        preview={noContactReadPermission}
      />

      <ContactPhoneInput
        textId="MOBILE_NUMBER"
        extraClients={Clients}
        name="MobileNumber"
        value={MobileNumber}
        noPermission={noContactReadPermission}
      />
      <FormInput textId="ROLE" className="mb-3" name="Role" />
      <FormInput
        textId="NOTES"
        inputType={DocTyper}
        className="mb-3"
        name="Description"
      />
      <hr></hr>
      <div className="mb-3">
        <span className="text-black fs-16 fw-medium">
          <FormattedMessage id="STATUS" />
        </span>
      </div>

      <FormInput
        inputType={ContactStatusDropdown}
        className="mb-3"
        name="Status"
      />
      <FormInput
        preview={!canEdit}
        textId="LEGA_BASIS"
        inputType={GDPR}
        className="mb-3"
        name="GDPRType"
      />
      <CreatedBy Creator={Creator} CreationDate={CreationDate} />
    </>
  );
};

export default ContactFormContent;
