import React from "react";
import AccountDropdownInput from "../Accounts/AccountDropdownInput";
import { ConnectTeamEntity } from "./TeamProfile";

const TeamDropdownInput = ({ team, ...rest }) => {
  return(
  <AccountDropdownInput account={team} {...rest} />
);}

export default TeamDropdownInput;

export const ConnectedTeamDropdownInput = ConnectTeamEntity(
  TeamDropdownInput
);
