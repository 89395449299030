import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const ProjectDetailsComp = React.lazy(() =>
  import(/* webpackChunkName: "projects-details" */ "./ProjectDetails2Comp")
);

const ProjectDetails = () => {
  return (
    <SuspenseErrorBoundary>
      <ProjectDetailsComp />
    </SuspenseErrorBoundary>
  );
};

export default ProjectDetails;
