import React, { useMemo } from "react";
import "./SmallScreen.css";
import { ReactComponent as RedResize } from "../../Assets/Svg/red_resize.svg";
import { ReactComponent as AirdeskTitle } from "../../Assets/Svg/airdesk_title.svg";
import Button from "../../Components/Button/Button";
import { FormattedMessage } from "react-intl";
import { BasicFooter } from "../BasicPageContainer/BasicPageContainer";

function handleValiditionCookie(name, value) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 7); // Set expiration date to 7 days from now
  const cookieValue = `${name}=${value};expires=${expirationDate.toUTCString()};path=/`;
  document.cookie = cookieValue;
}

const SmallScreen = () => {
  const url = useMemo(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "market://details?id=com.algardata.airdesk";
    }

    return false;
  }, []);

  return (
    <div className="w-100 h-100 d-flex flex-column align-items-center  overflow-auto">
      <div className="flex-1 d-flex flex-column align-items-center overflow-auto">
        <div className="simple-header-small-screen d-flex align-items-center text-white test-white justify-content-start pl-4">
          <AirdeskTitle style={{ fill: "white" }} />
        </div>
        <div className="align-items-center flex-1 flex-column of-y-auto text-align-center">
          <RedResize style={{ marginTop: 50 }} />
          <div
            className="mt-4 text-danger-alt text-align-center fs-32 mx-2"
            style={{ lineHeight: "120%" }}
          >
            <FormattedMessage id="SMALL_SCREEN_TITLE" />
          </div>
          <div className="my-4 fs-18 text-black text-align-center mx-2">
            <FormattedMessage id="SMALL_SCREEN_SUBTITLE" />
          </div>
          {url && (
            <div className="mb-3">
              <a href={url} target="_parent">
                <Button>
                  <FormattedMessage id="OPEN_IN_APP" />
                </Button>
              </a>
            </div>
          )}
          <div className="mb-3">
            <Button
              onClick={() => {
                handleValiditionCookie("bypassSmallScreen", "YES");
                window.location.reload();
              }}
            >
              <FormattedMessage id="CONTINUE_TO_WEBSITE" />
            </Button>
          </div>
        </div>
      </div>
      <BasicFooter className="pb-2 text-align-center fs-14 text-black mx-4" />
    </div>
  );
};

export default SmallScreen;
