import moment from "moment";
import { useIntl } from "react-intl";

export const MaintenanceText = ({ data }) => {
  const { ScheduleMessage } = data;

  const intl = useIntl();

  if (!ScheduleMessage)
    return intl.formatMessage({ id: "MAINTENANCE_ONGOING_DESC" });

  const { ScheduleMessageDate, ScheduleMessageInterval } = ScheduleMessage;
  moment().locale(intl.locale)
  const mDate = moment(ScheduleMessageDate);
  const date = mDate.format("DD/MM/YYYY HH:mm");

  const fromNow = mDate.fromNow(true);

  const minutes = ScheduleMessageInterval / 60 / 1000;

  return intl.formatMessage(
    { id: "MAINTENANCE_WARNING_DESC" },
    { fromNow, date, minutes }
  );
  // return `Está prevista uma manutenção do sistema ${fromNow} (${date}), com duração de ${minutes} minutos. Durante a atualização, o sistema estará indisponível.`;
};
