import React from "react";
import { useEmailConversation } from "../../../../Hooks/EntityHooks";
import {
  HistoryHeader,
  HistoryContent,
  HistoryAccountProfile,
  HistoryOverview,
  useHistoryItem,
} from "./HistoryItem";
import { createItemEditDetailsComponent } from "./HistoryItemDetails/HistoryItemDetails";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox } from "@fortawesome/pro-light-svg-icons";
import { ConversationOriginAnchor, EmailConversationStatusProfile } from "../../../EmailConversations/EmailConversationProfile";

const EmailConversationHistoryOverview = () => {
	const { EmailConversation, IsCreation, Account } = useHistoryItem();

  const item = useEmailConversation(EmailConversation);
  const { Subject } = item;
  if (IsCreation)
    return (
      <HistoryOverview>
        <HistoryAccountProfile account={Account} />
        <span>
          {" "}
          <FormattedMessage id={"CREATED_CONVERSATION"} />{" "}
        </span>{" "}
        <b>{Subject}</b>
      </HistoryOverview>
    );

  return (
    <HistoryOverview>
      <HistoryAccountProfile account={Account} />
      <span>
        {" "}
        <FormattedMessage id={"EDITED_CONVERSATION"} />{" "}
      </span>{" "}
      <b>{Subject}</b>
    </HistoryOverview>
  );
};

const EmailConversationEditItemDetails = createItemEditDetailsComponent({
  Status: {
    NameId: "STATUS",
    ValueComponent: EmailConversationStatusProfile,
  },
});

const EmailConversationHistoryDetails = () => {
  const { IsCreation } = useHistoryItem();
  if (IsCreation) return null;

  return <EmailConversationEditItemDetails />;
};

export const EmailConversationIcon = (props) => {
  return <FontAwesomeIcon icon={faInbox} {...props} />;
};


const EmailConversationHistoryItem = () => {
  const { EmailConversation } = useHistoryItem();
	const ConnectedConversation = useEmailConversation(EmailConversation)
  return (
    <>
      <HistoryHeader>
				<ConversationOriginAnchor conversation={ConnectedConversation}/>
        {/* <ConnectedTaskOrigin task={EmailConversation}> */}
				{/* <div className="d-flex align-items-center text-black">

          <HistoryTitle Icon={EmailConversationIcon}>
            <FormattedMessage id={"CONVERSATION"} />
          </HistoryTitle>
				</div> */}
        {/* </ConnectedTaskOrigin> */}
      </HistoryHeader>
      <HistoryContent>
        <EmailConversationHistoryOverview />
        <EmailConversationHistoryDetails />
      </HistoryContent>
    </>
  );
};
export default EmailConversationHistoryItem;
