import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import {
  faBold,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faItalic,
  faUnderline,
  faStrikethrough,
  faCode,
  faHighlighter,
  faListUl,
  faListOl,
  // faTasks,
  faTerminal,
  faQuoteRight,
  faHorizontalRule,
  faParagraphRtl,
  faRemoveFormat,
  faUndo,
  faRedo,
  faWindowRestore,
  faWindowMaximize,
  faExpandArrows,
  faMicrophone,
  faUnlink,
  faFileCode
} from "@fortawesome/pro-regular-svg-icons";
import { ReactComponent as TableRowAddBeforeIcon } from "../../Assets/Svg/DocTyperIcons/table-row-plus-before.svg";
import { ReactComponent as TableIcon } from "../../Assets/Svg/DocTyperIcons/table.svg";
import { ReactComponent as TableRowAddAfterIcon } from "../../Assets/Svg/DocTyperIcons/table-row-plus-after.svg";
import { ReactComponent as TableRowRemoveIcon } from "../../Assets/Svg/DocTyperIcons/table-row-remove.svg";
import { ReactComponent as TableColumnAddBeforeIcon } from "../../Assets/Svg/DocTyperIcons/table-column-plus-before.svg";
import { ReactComponent as TableColumnAddAfterIcon } from "../../Assets/Svg/DocTyperIcons/table-column-plus-after.svg";
import { ReactComponent as TableColumnRemoveIcon } from "../../Assets/Svg/DocTyperIcons/table-column-remove.svg";
import { ReactComponent as TableRemoveIcon } from "../../Assets/Svg/DocTyperIcons/table-remove.svg";
import { ReactComponent as TableMergeCell } from "../../Assets/Svg/DocTyperIcons/table-merge-cells.svg";
import { ReactComponent as TableSplitCell } from "../../Assets/Svg/DocTyperIcons/table-split-cell.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { SketchPicker } from "react-color";
import { FormattedMessage, useIntl } from "react-intl";
import Popup, { usePopupOpenState } from "../Popup/Popup";
import { DocTyperMenuBarProps, MenuItem as iMenuItem } from "./DocTyperTypes";
import {
  CodeMirrorProvider,
  ColorStyleProvider,
  EditorNeededPropsProvider,
  FontFamilyStyleProvider,
  FontSizeStyleProvider,
  MaximizedSetStateProvider,
  MaximizedStateProvider,
  SetCodeMirrorProvider
} from "./DocTyperHelpers";
import { subscribeUniqueEvent } from "../../Helpers/ComponentsHelper";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import { BasicPortal } from "../Portal/Portal";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import KeyedDropdown from "../Dropdown/KeyedDropdown";
import { generateHTML } from "@tiptap/html";
import {
  TagsDictionary,
  TagsLinkDictionary,
  TagsTranslationDictionary
} from "../../Containers/EmailSignature/EmailSignatureFormHelper";
import DocTyperCodeMirror from "./DocTyperCodeMirror";

const DocumentTableTags = {
  "table.times": "{{table.times}}",
  "table.calls": "{{table.calls}}",
  "table.projects": "{{table.projects}}",
  "table.tasks": "{{table.tasks}}",
  "table.tickets": "{{table.tickets}}",
  "table.deals": "{{table.deals}}",
  "table.contracts": "{{table.contracts}}",
  "table.subscriptions": "{{table.subscriptions}}"
};
const DocumentTableTagsDictionary = {
  "table.times": "TABLE_TIMES",
  "table.calls": "TABLE_CALLS",
  "table.projects": "TABLE_PROJECTS",
  "table.tasks": "TABLE_TASKS",
  "table.tickets": "TABLE_TICKETS",
  "table.deals": "TABLE_DEALS",
  "table.contracts": "TABLE_CONTRACTS",
  "table.subscriptions": "TABLE_SUBSCRIPTIONS"
};

const DocTyperMenuBar: FC<DocTyperMenuBarProps> = ({
  editor,
  extraMenuItemsForEmailSignature,
  extraMenuItemsForDocuments,
  extraMenuItemHtmlEditorButton,
  disablePortal
}) => {
  const intl = useIntl();
  const { oldEditor, content } = useContext(EditorNeededPropsProvider);
  const setCodeMirrorState = useContext(SetCodeMirrorProvider) as any;
  const codeMirrorState = useContext(CodeMirrorProvider) as any;
  const maximized = useContext(MaximizedStateProvider) as Boolean;
  const setMaximized = useContext(MaximizedSetStateProvider);

  const maximizeButtonSettings = useMemo(() => {
    return {
      icon: maximized ? faWindowRestore : faWindowMaximize,
      enable: true,
      title: maximized
        ? intl.formatMessage({ id: "MINIMIZE" })
        : intl.formatMessage({ id: "MAXIMIZE" }),
      action: () => {
        if (!maximized && codeMirrorState) {
          setCodeMirrorState(false);
        }

        typeof setMaximized === "function" && setMaximized(!maximized);

        disablePortal &&
          oldEditor &&
          oldEditor.commands.setContent(editor.getHTML());
      },
      isActive: () => maximized
    };
  }, [
    codeMirrorState,
    disablePortal,
    editor,
    intl,
    maximized,
    oldEditor,
    setCodeMirrorState,
    setMaximized
  ]);

  const items: iMenuItem[] = useMemo(() => {
    const tempItems: iMenuItem[] = [
      {
        icon: faAlignLeft,
        title: intl.formatMessage({ id: "ALIGN_LEFT" }),
        action: () => editor.chain().focus().setTextAlign("left").run(),
        isActive: () => editor.isActive({ textAlign: "left" })
      },
      {
        icon: faAlignCenter,
        title: intl.formatMessage({ id: "ALIGN_CENTER" }),
        action: () => editor.chain().focus().setTextAlign("center").run(),
        isActive: () => editor.isActive({ textAlign: "center" })
      },
      {
        icon: faAlignRight,
        title: intl.formatMessage({ id: "ALIGN_RIGHT" }),
        action: () => editor.chain().focus().setTextAlign("right").run(),
        isActive: () => editor.isActive({ textAlign: "right" })
      },
      {
        icon: faAlignJustify,
        title: intl.formatMessage({ id: "ALIGN_JUSTIFY" }),
        action: () => editor.chain().focus().setTextAlign("justify").run(),
        isActive: () => editor.isActive({ textAlign: "justify" })
      },
      {
        type: "divider"
      },
      {
        icon: faBold,
        title: intl.formatMessage({ id: "BOLD" }),
        action: () => editor.chain().focus().toggleBold().run(),
        isActive: () => editor.isActive("bold")
      },
      {
        icon: faItalic,
        title: intl.formatMessage({ id: "ITALIC" }),
        action: () => editor.chain().focus().toggleItalic().run(),
        isActive: () => editor.isActive("italic")
      },
      {
        icon: faUnderline,
        title: intl.formatMessage({ id: "UNDERLINE" }),
        action: () => editor.chain().focus().toggleUnderline().run(),
        isActive: () => editor.isActive("underline")
      },
      {
        icon: faStrikethrough,
        title: intl.formatMessage({ id: "STRIKETHROUGH" }),
        action: () => editor.chain().focus().toggleStrike().run(),
        isActive: () => editor.isActive("strike")
      },
      {
        icon: faCode,
        title: intl.formatMessage({ id: "CODE_VIEW" }),
        action: () => editor.chain().focus().toggleCode().run(),
        isActive: () => editor.isActive("code")
      },
      {
        icon: faHighlighter,
        title: intl.formatMessage({ id: "HIGHLIGHT" }),
        action: () => editor.chain().focus().toggleHighlight().run(),
        isActive: () => editor.isActive("highlight")
      },
      {
        title: intl.formatMessage({ id: "COLOR" }),
        type: "color",
        value: editor.getAttributes("textStyle").color || "#0d0d0d",
        action: (value: string) => editor.chain().setColor(value).run()
      },
      {
        type: "divider"
      },
      // {
      //   icon: faH1,
      //   title: intl.formatMessage({ id: "HEADING_1" }),
      //   action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      //   isActive: () => editor.isActive("heading", { level: 1 })
      // },
      // {
      //   icon: faH2,
      //   title: intl.formatMessage({ id: "HEADING_2" }),
      //   action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      //   isActive: () => editor.isActive("heading", { level: 2 })
      // },
      {
        icon: faListUl,
        title: intl.formatMessage({ id: "BULLET_LIST" }),
        action: () => editor.chain().focus().toggleBulletList().run(),
        isActive: () => editor.isActive("bulletList")
      },
      {
        icon: faListOl,
        title: intl.formatMessage({ id: "NUMBERED_LIST" }),
        action: () => editor.chain().focus().toggleOrderedList().run(),
        isActive: () => editor.isActive("orderedList")
      },
      // {
      //   icon: faTasks,
      //   title: intl.formatMessage({ id: "TASK_LIST" }),
      //   action: () => editor.chain().focus().toggleTaskList().run(),
      //   isActive: () => editor.isActive("taskList")
      // },
      {
        icon: faTerminal,
        title: intl.formatMessage({ id: "CODE_CONTAINER" }),
        action: () => editor.chain().focus().toggleCodeBlock().run(),
        isActive: () => editor.isActive("codeBlock")
      },
      {
        type: "divider"
      },
      {
        icon: faQuoteRight,
        title: intl.formatMessage({ id: "QUOTE_BLOCK" }),
        action: () => editor.chain().focus().toggleBlockquote().run(),
        isActive: () => editor.isActive("blockquote")
      },
      {
        icon: faHorizontalRule,
        title: intl.formatMessage({ id: "SEPERATOR" }),
        action: () => editor.chain().focus().setHorizontalRule().run()
      },
      // {
      //   icon: faHorizontalRule,
      //   title: intl.formatMessage({ id: "SEPERATOR" }),
      //   action: () => editor.chain().focus().setHorizontalRule().run()
      // },

      {
        type: "divider"
      },
      {
        icon: faParagraphRtl,
        title: intl.formatMessage({ id: "LINE_BREAK" }),
        action: () => editor.chain().focus().setHardBreak().run()
      },
      {
        icon: faRemoveFormat,
        title: intl.formatMessage({ id: "REMOVE_FORMAT" }),
        action: () => editor.chain().focus().clearNodes().unsetAllMarks().run()
      },
      {
        icon: faUnlink,
        title: intl.formatMessage({ id: "REMOVE_LINK" }),
        action: () => editor.chain().focus().unsetLink().run()
      },
      {
        type: "divider"
      },
      {
        icon: faUndo,
        title: intl.formatMessage({ id: "UNDO" }),
        action: () => editor.chain().focus().undo().run()
      },
      {
        icon: faRedo,
        title: intl.formatMessage({ id: "REDO" }),
        action: () => editor.chain().focus().redo().run()
      },
      {
        type: "divider"
      },
      {
        icon: <TableIcon className="svg-inline--fa fa-th fa-w-16 "></TableIcon>,
        title: intl.formatMessage({ id: "INSERT_TABLE" }),
        action: () =>
          editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            .run()
      },
      {
        icon: (
          <TableRowAddBeforeIcon className="svg-inline--fa fa-th fa-w-16 " />
        ),
        title: intl.formatMessage({ id: "INSERT_ROW_BEFORE" }),
        action: () => editor.chain().focus().addRowBefore().run()
      },
      {
        icon: (
          <TableRowAddAfterIcon className="svg-inline--fa fa-th fa-w-16 "></TableRowAddAfterIcon>
        ),
        title: intl.formatMessage({ id: "INSERT_ROW_AFTER" }),
        action: () => editor.chain().focus().addRowAfter().run()
      },
      {
        icon: (
          <TableColumnAddBeforeIcon className="svg-inline--fa fa-th fa-w-16 "></TableColumnAddBeforeIcon>
        ),
        title: intl.formatMessage({ id: "INSERT_COLUMN_BEFORE" }),
        action: () => editor.chain().focus().addColumnBefore().run()
      },
      {
        icon: (
          <TableColumnAddAfterIcon className="svg-inline--fa fa-th fa-w-16 "></TableColumnAddAfterIcon>
        ),
        title: intl.formatMessage({ id: "INSERT_COLUMN_AFTER" }),
        action: () => editor.chain().focus().addColumnAfter().run()
      },
      {
        icon: (
          <TableRowRemoveIcon className="svg-inline--fa fa-th fa-w-16 "></TableRowRemoveIcon>
        ),
        title: intl.formatMessage({ id: "DELETE_ROW" }),
        action: () => editor.chain().focus().deleteRow().run()
      },
      {
        icon: (
          <TableMergeCell className="svg-inline--fa fa-th fa-w-16 "></TableMergeCell>
        ),
        title: intl.formatMessage({ id: "MERGE_CELLS" }),
        action: () => editor.chain().focus().mergeCells().run()
      },
      {
        icon: (
          <TableSplitCell className="svg-inline--fa fa-th fa-w-16 "></TableSplitCell>
        ),
        title: intl.formatMessage({ id: "SPLIT_CELLS" }),
        action: () => editor.chain().focus().splitCell().run()
      },
      {
        icon: (
          <TableColumnRemoveIcon className="svg-inline--fa fa-th fa-w-16 "></TableColumnRemoveIcon>
        ),
        title: intl.formatMessage({ id: "DELETE_COLUMN" }),
        action: () => editor.chain().focus().deleteColumn().run()
      },
      {
        icon: (
          <TableRemoveIcon className="svg-inline--fa fa-th fa-w-16 "></TableRemoveIcon>
        ),
        title: intl.formatMessage({ id: "REMOVE_TABLE" }),
        action: () => editor.chain().focus().deleteTable().run()
      },
      {
        type: "divider"
      },
      {
        type: "font-family",
        title: intl.formatMessage({ id: "FONT_FAMILY" }),
        action: (family) => editor.chain().focus().setFontFamily(family).run()
      },
      {
        type: "font-size",
        title: intl.formatMessage({ id: "FONT_SIZE" }),
        action: (fontSize) =>
          editor.chain().focus().setFontSize(`${fontSize}px`).run()
      }
    ];

    if (extraMenuItemsForDocuments) {
      tempItems.unshift({
        type: "doc-tags",
        title: "Tags",
        action: (tag) =>
          editor
            .chain()
            .focus()
            .insertContent(
              `<span data-tag="" class="email-tag" data-id="${tag}">${tag}</span>`
            )
            .run()
      });
    }

    if (extraMenuItemsForEmailSignature) {
      tempItems.unshift(
        {
          type: "email-tags",
          title: "Tags",
          action: (tag) =>
            editor
              .chain()
              .focus()
              .insertContent(
                `<span data-tag="" class="email-tag" data-id="${tag}">${tag}</span>`
              )
              .run()
        },
        {
          type: "email-tags-link",
          title: "Link Tags",
          action: (tag) => editor.commands.setLink({ href: tag })
        },
        {
          icon: faFileCode,
          enable: true,
          title: "Html",
          action: () => {
            if (codeMirrorState) {
              editor && editor.commands.setContent(content);
            }
            setCodeMirrorState(!codeMirrorState);
          },
          isActive: () => codeMirrorState
        },
        {
          type: "divider"
        }
      );
    }

    if (extraMenuItemHtmlEditorButton) {
      tempItems.unshift({
        icon: faFileCode,
        enable: true,
        title: "Html",
        action: () => {
          if (codeMirrorState) {
            editor && editor.commands.setContent(content);
          }
          setCodeMirrorState(!codeMirrorState);
        },
        isActive: () => codeMirrorState
      });
    }

    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      tempItems.push({
        icon: faMicrophone,
        type: "speech",
        title: intl.formatMessage({ id: "SPEECH_TO_TEXT" }),
        action: () =>
          editor.storage.textToSpeech.speechListen
            ? editor.commands.stopSpeechToText()
            : editor.commands.startSpeechToText(),
        isActive: () => editor.storage.textToSpeech.speechListen
      });
      tempItems.push({
        type: "speech-set",
        currentEditor: editor,
        title: intl.formatMessage({ id: "SPEECH_TO_TEXT_LANGUAGE" }),
        action: (value: string) =>
          editor.commands.changeSpeechToTextLanguage(value)
      });
    }

    return tempItems;
  }, [
    codeMirrorState,
    content,
    editor,
    extraMenuItemHtmlEditorButton,
    extraMenuItemsForDocuments,
    extraMenuItemsForEmailSignature,
    intl,
    setCodeMirrorState
  ]);

  const isOverflowActive = (bar: any) => {
    return bar.offsetWidth < bar.scrollWidth;
  };

  const MenuBarRef = useRef(null);
  const [overflowActive, setOverflowActive] = useState(false);
  const [isBuilt, setIsBuilt] = useState(false);
  useLayoutEffect(() => {
    if (isBuilt) {
      if (isOverflowActive(MenuBarRef.current)) {
        setOverflowActive(true);
        return;
      }

      setOverflowActive(false);
    }

    setIsBuilt(true);
  }, [isBuilt]);

  if (overflowActive) {
    return (
      <div ref={MenuBarRef} className="editor__header bg-white">
        {maximized && !disablePortal && (
          <DocTyperExtendedPortal
            extraMenuItemsForDocuments={extraMenuItemsForDocuments}
            extraMenuItemsForEmailSignature={extraMenuItemsForEmailSignature}
          />
        )}
        <OverflowedMenu MenuBarRef={MenuBarRef} items={items} />
        <div className="d-flex flex-1 justify-content-end align-items-center">
          <MenuItem {...maximizeButtonSettings} />
        </div>
      </div>
    );
  }

  return (
    <div ref={MenuBarRef} className="editor__header bg-white">
      {maximized && !disablePortal && (
        <DocTyperExtendedPortal
          extraMenuItemsForDocuments={extraMenuItemsForDocuments}
          extraMenuItemsForEmailSignature={extraMenuItemsForEmailSignature}
        />
      )}
      {items.map((item, index) => (
        <Fragment key={index}>
          {item.type === "divider" ? (
            <div className="divider" />
          ) : (
            <MenuItem {...item} />
          )}
        </Fragment>
      ))}
      {/* Maximize/Minimize Button */}
      <div className="d-flex flex-1 justify-content-end align-items-center">
        {maximized && (
          <div
            onClick={() => {
              if (!maximized && codeMirrorState) {
                setCodeMirrorState(false);
              }

              typeof setMaximized === "function" && setMaximized(!maximized);

              disablePortal &&
                oldEditor &&
                oldEditor.commands.setContent(editor.getHTML());
            }}
            className="fs-12 mr-1 cursor-pointer text-black fw-medium disable-selection"
          >
            ESC
          </div>
        )}
        <MenuItem {...maximizeButtonSettings} />
      </div>
    </div>
  );
};

interface OverflowedMenuProps {
  items: iMenuItem[];
  MenuBarRef: React.MutableRefObject<any>;
}

const getElementTrueWidth = (element: any) => {
  // we're assuming a reference to your element in a variable called 'element'
  var style = element.currentStyle || window.getComputedStyle(element),
    width = element.offsetWidth, // or use style.width
    margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight),
    padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight),
    border =
      parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);

  return width + margin + padding + border;
};

const OverflowedMenu = (props: OverflowedMenuProps) => {
  const { items, MenuBarRef } = props;

  const width = MenuBarRef.current?.offsetWidth || 474;

  const barChildren = MenuBarRef.current?.children || [];

  let CurrentChildren = 0;
  let CurrentChildrenWidth = 0;

  for (const child of barChildren) {
    const childWidth = getElementTrueWidth(child);
    CurrentChildrenWidth += childWidth;
    if (CurrentChildrenWidth > width) {
      break;
    } else {
      CurrentChildren++;
    }
  }

  // ;
  // const itemsNumber = Math.floor(width / 24) - 6;

  const visibleItems = items.slice(0, CurrentChildren);
  const reducedItems = items.slice(CurrentChildren);

  const popupRef = useRef(null);
  const ExtraActionButtonRef = useRef(null);
  const [isOpen, toggleIsOpen] = usePopupOpenState(
    popupRef,
    ExtraActionButtonRef
  );

  return (
    <>
      <Popup
        style={{ marginBottom: 3, marginTop: 3 }}
        domRef={popupRef}
        placement={"top-end"}
        popperClassName={"DocMoreMenuBar"}
        anchorEl={MenuBarRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={Boolean(isOpen)}
      >
        <div className="editor__header docOverflowedItemsBar flex-wrap">
          {reducedItems.map((item, index) => (
            <Fragment key={index}>
              {item.type === "divider" ? (
                <div className="divider" />
              ) : (
                <MenuItem {...item} />
              )}
            </Fragment>
          ))}
        </div>
      </Popup>
      {visibleItems.map((item, index) => (
        <Fragment key={index}>
          {item.type === "divider" ? (
            <div className="divider" />
          ) : (
            <MenuItem {...item} />
          )}
        </Fragment>
      ))}
      <button
        onClick={() => {
          typeof toggleIsOpen === "function" && toggleIsOpen(!isOpen);
        }}
        style={{ width: "auto" }}
        ref={ExtraActionButtonRef}
        type="button"
        className={`menu-item fs-14 fw-medium d-flex align-items-center`}
      >
        <FormattedMessage id="MORE" />
        <FontAwesomeIcon icon={faCaretUp} size="xs" className="ml-1" />
      </button>
    </>
  );
};

const MenuItem = (props: iMenuItem) => {
  const { action, icon, isActive, title, type, enable } = props;

  const codeMirrorState = useContext(CodeMirrorProvider) as any;

  const resolvedIcon = icon as IconDefinition;

  switch (type) {
    case "color":
      return <ColorInputMenuItem {...props} />;
    case "speech-set":
      return <SpeechSettingMenuItem {...props} />;
    case "font-family":
      return <FontFamilyMenuItem {...props} />;
    case "font-size":
      return <FontSizesMenuItem {...props} />;
    case "email-tags":
      return <EmailTagsMenuItem {...props} />;
    case "doc-tags":
      return <DocTagsMenuItem {...props} />;
    case "email-tags-link":
      return <EmailLinkTagsMenuItem {...props} />;
    case "speech":
      return (
        <button
          disabled={codeMirrorState}
          type="button"
          className={`menu-item menu-item-speech${
            isActive && isActive() ? " is-active" : ""
          }`}
          onClick={action}
          title={title}
        >
          {React.isValidElement(icon) ? (
            icon
          ) : (
            <FontAwesomeIcon icon={resolvedIcon} />
          )}
        </button>
      );
    default:
      return (
        <button
          disabled={enable ? false : codeMirrorState}
          type="button"
          className={`menu-item${isActive && isActive() ? " is-active" : ""}`}
          onClick={action}
          title={title}
        >
          {React.isValidElement(icon) ? (
            icon
          ) : (
            <FontAwesomeIcon icon={resolvedIcon} />
          )}
        </button>
      );
  }

  // if (type === "font-family") {
  //   return <FontFamilyMenuItem {...props} />;
  // }
  // if (type === "font-size") {
  //   return <FontSizesMenuItem {...props} />;
  // }
  // if (type === "email-tags") {
  //   return <EmailTagsMenuItem {...props} />;
  // }
  // if (type === "email-tags-link") {
  //   return <EmailLinkTagsMenuItem {...props} />;
  // }

  // if (type === "speech") {
  //   return (
  //     <button
  //       disabled={codeMirrorState}
  //       type="button"
  //       className={`menu-item menu-item-speech${
  //         isActive && isActive() ? " is-active" : ""
  //       }`}
  //       onClick={action}
  //       title={title}
  //     >
  //       {React.isValidElement(icon) ? (
  //         icon
  //       ) : (
  //         <FontAwesomeIcon size="xs" icon={resolvedIcon} />
  //       )}
  //     </button>
  //   );
  // }

  // return (
  //   <button
  //     disabled={enable ? false : codeMirrorState}
  //     type="button"
  //     className={`menu-item${isActive && isActive() ? " is-active" : ""}`}
  //     onClick={action}
  //     title={title}
  //   >
  //     {React.isValidElement(icon) ? (
  //       icon
  //     ) : (
  //       <FontAwesomeIcon size="xs" icon={resolvedIcon} />
  //     )}
  //   </button>
  // );
};

const ColorInputMenuItem = React.memo((props: iMenuItem) => {
  const { action, title, value } = props;
  const codeMirrorState = useContext(CodeMirrorProvider) as any;
  const editorColor = useContext(ColorStyleProvider);
  const [valueState, setValueState] = useState(value);

  const popupRef = useRef();

  const CategoryExtraActionButtonRef = useRef(null);
  const [isOpen, toggleIsOpen] = usePopupOpenState(
    popupRef,
    CategoryExtraActionButtonRef
  );

  useEffect(() => {
    if (!isOpen && valueState !== editorColor) setValueState(editorColor);
  }, [editorColor, isOpen, valueState]);

  const changeColor = (e: any) => {
    setValueState(e.hex);
    action && action(e.hex);
  };

  useEffect(() => {
    if (isOpen)
      return subscribeUniqueEvent((e: any) => {
        if (e.keyCode === 27) {
          e.preventDefault();
          typeof toggleIsOpen === "function" && toggleIsOpen(false);
        }
      });
  }, [isOpen, toggleIsOpen]);

  // useEffect(() => {
  //   if (!valueState) return;

  //   const timeout = setTimeout(() => {
  //     action && action(valueState);
  //   }, 50);

  //   return () => clearTimeout(timeout);
  // }, [valueState, action]);

  return (
    <>
      <Popup
        domRef={popupRef}
        anchorEl={CategoryExtraActionButtonRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        className={"docType"}
        // onClose={() => {
        //   typeof toggleIsOpen === "function" && toggleIsOpen(false);
        // }}
        isOpen={Boolean(isOpen)}
      >
        <div
          onDrag={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onFocus={(e) => {
            e.stopPropagation();
          }}
          onBlur={(e) => {
            e.stopPropagation();
          }}
        >
          <SketchPicker
            disableAlpha={true}
            color={valueState || {}}
            onChange={changeColor}
          />
        </div>
      </Popup>
      <button
        disabled={codeMirrorState}
        title={title}
        type="button"
        onClick={() => {
          typeof toggleIsOpen === "function" && toggleIsOpen(!isOpen);
        }}
        tabIndex={-1}
        className="d-flex menu-item justify-content-center align-items-center"
      >
        <span
          ref={CategoryExtraActionButtonRef}
          style={{ backgroundColor: `${valueState || "#0d0d0d"}` }}
          className={"docTypeColorInput"}
        ></span>
      </button>
    </>
  );
});

const DocTyperExtendedPortal: FC<any> = ({
  extraMenuItemsForEmailSignature,
  extraMenuItemsForDocuments
}) => {
  const { extensions, content, onChange } = useContext(
    EditorNeededPropsProvider
  );

  const editor = useEditor({
    onUpdate({ editor }) {
      onChange(generateHTML(editor.getJSON(), [...extensions]));
    },
    extensions: extensions,
    content: content
  });

  const codeMirror = useContext(CodeMirrorProvider);

  const maximized = useContext(MaximizedStateProvider) as Boolean;
  const setMaximized = useContext(MaximizedSetStateProvider);
  useEffect(() => {
    if (maximized)
      return subscribeUniqueEvent((e: any) => {
        if (e.keyCode === 27) {
          e.preventDefault();
          typeof setMaximized === "function" && setMaximized(false);
        }
      });
  }, [maximized, setMaximized]);

  return (
    <BasicPortal>
      <div className="docTyperMaximizedContainer">
        <ColorStyleProvider.Provider
          value={editor?.getAttributes("textStyle").color}
        >
          <div className="editor">
            {editor && (
              <DocTyperMenuBar
                extraMenuItemsForEmailSignature={
                  extraMenuItemsForEmailSignature
                }
                extraMenuItemsForDocuments={extraMenuItemsForDocuments}
                disablePortal
                editor={editor}
              />
            )}
            {!codeMirror && (
              <EditorContent className="editor__content" editor={editor} />
            )}

            {codeMirror && (
              <DocTyperCodeMirror onChange={onChange} text={content} />
            )}
          </div>
        </ColorStyleProvider.Provider>
      </div>
    </BasicPortal>
  );
};

const SpeechSettingMenuItem = (props: iMenuItem) => {
  const { title, action, currentEditor } = props;
  const resolvedEditor = currentEditor as Editor;
  const CurrentSpeechType =
    resolvedEditor.storage.textToSpeech.chosenLang || "en-US";
  const codeMirrorState = useContext(CodeMirrorProvider) as any;
  return (
    <button
      disabled={codeMirrorState}
      title={title}
      type="button"
      onClick={(e) => {
        if (CurrentSpeechType === "es-AR") {
          typeof action === "function" && action("en-US");
        } else if (CurrentSpeechType === "en-US") {
          typeof action === "function" && action("pt-PT");
        } else {
          typeof action === "function" && action("es-AR");
        }
      }}
      style={{ width: "auto" }}
      tabIndex={-1}
      className="mr-0 d-flex fw-medium menu-item justify-content-center align-items-center"
    >
      {CurrentSpeechType === "es-AR" ? (
        <span>ES</span>
      ) : CurrentSpeechType === "pt-PT" ? (
        <span>PT</span>
      ) : (
        <span>EN</span>
      )}
    </button>
  );
};

const FontFamilyOptions = [
  "Sans-Serif",
  "Arial",
  "Helvetica",
  "Verdana",
  "Trebuchet MS",
  "Gill Sans",
  "Noto Sans",
  "Avantgarde",
  "Optima",
  "Times New Roman",
  "Georgia",
  "Bookman",
  "New Century Schoolbook",
  "American Typewriter",
  "Andale Mono",
  "Courier",
  "OCR A Std",
  "DejaVu Sans Mono",
  "Comic Sans MS",
  "Bradley Hand",
  "Impact",
  "Stencil Std"
];

const FontFamilyOptionComponent = ({ item }: any) => {
  return <div style={{ fontFamily: item }}>{item}</div>;
};

const FontFamilyMenuItem = (props: iMenuItem) => {
  const { title, action } = props;
  const ProvidedFontFamily = useContext(FontFamilyStyleProvider);
  const CurrentFontFamily = ProvidedFontFamily || "Sans-Serif";
  const codeMirrorState = useContext(CodeMirrorProvider) as any;
  return (
    <KeyedDropdown
      disabled={codeMirrorState}
      className={"editor-menu-font-family-dropdown"}
      title={title}
      fixedWidth={180}
      value={CurrentFontFamily}
      onChange={action}
      options={FontFamilyOptions}
      valueComponent={FontFamilyOptionComponent}
      ListComponent={FontFamilyOptionComponent}
      closeOnSelect
      {...props}
    />
  );
};

const FontSizeOptions = [
  "9",
  "10",
  "11",
  "12",
  "14",
  "16",
  "18",
  "20",
  "22",
  "24",
  "26",
  "28",
  "36",
  "48",
  "72"
];

const FontSizeOptionComponent = ({ item }: any) => {
  return <div style={{ fontSize: `${item}px` }}>{item}</div>;
};

const FontSizesMenuItem = (props: iMenuItem) => {
  const { title, action } = props;
  const ProvidedFontFamily = useContext(FontSizeStyleProvider);
  const CurrentFontFamily = ProvidedFontFamily || "14";
  const codeMirrorState = useContext(CodeMirrorProvider) as any;
  return (
    <KeyedDropdown
      disabled={codeMirrorState}
      className={"editor-menu-font-family-dropdown"}
      title={title}
      fixedWidth={130}
      value={CurrentFontFamily}
      onChange={action}
      options={FontSizeOptions}
      ListComponent={FontSizeOptionComponent}
      closeOnSelect
      {...props}
    />
  );
};

const EmailTagsOptionComponent = React.memo(({ item }: any) => {
  const { tag } = item;
  return (
    <div>
      <FormattedMessage id={tag} />
    </div>
  );
});

const TranslatedNormalTags = [] as any[];
const AutoCompleteOptions = [] as any[];
const TranslatedLinkTags = [] as any[];
const LinkTags = [] as any[];

const resolvedTagsDictionary = TagsDictionary as Record<string, string>;
const resolvedLinkTagsDictionary = TagsLinkDictionary as Record<string, string>;
const resolvedTagsTranslationDictionary = TagsTranslationDictionary as Record<
  string,
  string
>;

for (const key in resolvedTagsDictionary) {
  if (resolvedTagsDictionary.hasOwnProperty(key)) {
    const element = resolvedTagsDictionary[key];
    TranslatedNormalTags.push([
      element,
      resolvedTagsTranslationDictionary[key]
    ]);
  }
}
for (const e of TranslatedNormalTags) {
  AutoCompleteOptions.push({
    code: e[0],
    tag: e[1]
  });
}

for (const key in resolvedLinkTagsDictionary) {
  if (TagsDictionary.hasOwnProperty(key)) {
    const element = resolvedLinkTagsDictionary[key];
    TranslatedLinkTags.push([element, resolvedTagsTranslationDictionary[key]]);
  }
}
for (const t of TranslatedLinkTags) {
  LinkTags.push({
    code: t[0],
    tag: t[1]
  });
}

const EmailTagsMenuItem = React.memo((props: iMenuItem) => {
  const { title, action } = props;

  const handleChange = (item: any) => {
    const { code } = item;
    let newcode = code.replace(/{/g, "");
    newcode = newcode.replace(/}/g, "");
    action && action(newcode);
  };
  const codeMirrorState = useContext(CodeMirrorProvider) as any;
  // const ProvidedFontFamily = useContext(FontSizeStyleProvider);
  // const CurrentFontFamily = ProvidedFontFamily || "";

  return (
    <KeyedDropdown
      disabled={codeMirrorState}
      className={
        "editor-menu-font-family-dropdown editor-dropdown-placeholder-replacer"
      }
      title={title}
      fixedWidth={250}
      placeholder={"Tags"}
      getKey={(e: Record<string, string>) => e.code}
      // value={CurrentFontFamily}
      onChange={handleChange}
      options={AutoCompleteOptions}
      ListComponent={EmailTagsOptionComponent}
      closeOnSelect
      {...props}
    />
  );
});

const TranslatedNormalDocTags = [] as any[];
const AutoCompleteDocOptions = [] as any[];

const resolvedDocTagsDictionary = DocumentTableTags as Record<string, string>;
const resolvedDocTagsTranslationDictionary =
  DocumentTableTagsDictionary as Record<string, string>;

for (const key in resolvedDocTagsDictionary) {
  if (resolvedDocTagsDictionary.hasOwnProperty(key)) {
    const element = resolvedDocTagsDictionary[key];
    TranslatedNormalDocTags.push([
      element,
      resolvedDocTagsTranslationDictionary[key]
    ]);
  }
}
for (const e of TranslatedNormalDocTags) {
  AutoCompleteDocOptions.push({
    code: e[0],
    tag: e[1]
  });
}

const DocTagsMenuItem = React.memo((props: iMenuItem) => {
  const { title, action } = props;

  const handleChange = (item: any) => {
    const { code } = item;
    action && action(code);
  };
  const codeMirrorState = useContext(CodeMirrorProvider) as any;
  // const ProvidedFontFamily = useContext(FontSizeStyleProvider);
  // const CurrentFontFamily = ProvidedFontFamily || "";

  return (
    <KeyedDropdown
      disabled={codeMirrorState}
      className={
        "editor-menu-font-family-dropdown editor-dropdown-placeholder-replacer"
      }
      title={title}
      fixedWidth={250}
      placeholder={"Tags"}
      getKey={(e: Record<string, string>) => e.code}
      // value={CurrentFontFamily}
      onChange={handleChange}
      options={AutoCompleteDocOptions}
      ListComponent={EmailTagsOptionComponent}
      closeOnSelect
      {...props}
    />
  );
});

const EmailLinkTagsOptionComponent = React.memo(({ item }: any) => {
  const { tag } = item;
  return (
    <div>
      <FormattedMessage id={tag} />
    </div>
  );
});

const EmailLinkTagsMenuItem = React.memo((props: iMenuItem) => {
  const { title, action } = props;

  const handleChange = (item: any) => {
    const { code } = item;
    action && action(code);
  };
  const codeMirrorState = useContext(CodeMirrorProvider) as any;
  // const ProvidedFontFamily = useContext(FontSizeStyleProvider);
  // const CurrentFontFamily = ProvidedFontFamily || "";

  return (
    <KeyedDropdown
      disabled={codeMirrorState}
      className={
        "editor-menu-font-family-dropdown editor-dropdown-placeholder-replacer"
      }
      title={title}
      fixedWidth={250}
      placeholder={"Link Tags"}
      getKey={(e: Record<string, string>) => e.code}
      // value={CurrentFontFamily}
      onChange={handleChange}
      options={LinkTags}
      ListComponent={EmailLinkTagsOptionComponent}
      closeOnSelect
      {...props}
    />
  );
});

export default DocTyperMenuBar;
