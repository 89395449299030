// import SplitCol from "../../Components/SplitCol/SplitCol";
import React, { useMemo, useState, useContext, useCallback } from "react";
import {
  useFormState,
  createFormRequestHook,
  FormValidationContext
} from "../../Components/Forms";
import {
  spaceTasksSchema,
  spaceTicketsSchema,
  spaceInterventionsSchema,
  spaceCallsSchema,
  spaceClientSchema,
  spaceProjectsSchema,
  spaceContractSchema,
  spacePipelineSchema,
  spaceOrganizationSchema,
  spaceDealsSchema,
  spacePipelineStatusSchema,
  spaceEmailConversationSchema,
  spaceEmailSchema
} from "../../config/schema";
import {
  SideFormFooter,
  SideFormContent,
  SideForm
} from "../../Components/Forms/SideMenuForm";
import { originTypes } from "../../Helpers/MiscHelper";
import {
  validateTaskForm,
  TasksStatusTypes,
  useTaskPost,
  useTaskDelete
} from "./TasksHelper";
import { useTask } from "../../Hooks/EntityHooks";

import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { FormattedMessage } from "react-intl";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRetweet } from "@fortawesome/pro-light-svg-icons";
import {
  isOrganzationRequestValid,
  OrganizationRequestSideError
} from "../../Components/Organizations/OrganizationHelper";
import Button from "../../Components/Button/Button";
import TaskRecurrenciesForm from "./TaskRecurrenciesForm";
import { buildTaskBaseForm, convertTaskEntityToForm } from "./TaskFormHelper";
import { SidebarAutomationContext } from "../../Components/Sidebar/SidebarV2Automation";
import TaskFormContent from "./TaskFormContent";
import {
  formatTaskForm,
  getInterventionTarget
} from "../../Helpers/FormFormmaterHelper";
import {
  FormTemplateProvider,
  useFormTemplateGetter
} from "../../Components/Forms/FormTemplatesHelper";

const convertTicketToTask = (ticketOrigin) => {
  if (ticketOrigin) {
    const {
      Id,
      Name,
      ManagerAccount,
      Contract,
      Contact,
      Company,
      Place,
      Description,
      Files
    } = ticketOrigin;

    let TaskTarget = {
      type: "Ticket",
      data: Id
    };

    return {
      TaskTarget,
      Name,
      Contact,
      Manager: ManagerAccount,
      Followers: [ManagerAccount],
      Contract,
      Company,
      Place,
      AssociatedFiles: Files,
      InternalDescription: Description
    };
  }
};

const convertTaskToTask = (taskOrigin) => {
  if (taskOrigin) {
    const {
      Id,
      Name,
      Manager,
      Files,
      Contact,
      IndirectClient,
      // Contract,
      Company,
      // Place,
      InternalDescription,
      Pipeline,
      PipelineStatus,
			...rest
    } = taskOrigin;

    let TaskTarget = {
      type: "Task",
      data: Id
    };

    return {
      TaskTarget,
      Name,
      IndirectClient,
      Contact,
      AssociatedFiles: Files,
      Manager: Manager,
      Followers: [Manager],
      // Contract,
      Company,
      // Place,
      InternalDescription,
      Pipeline,
      PipelineStatus,
			rest
    };
  }
};

const convertCallToTask = (callOrigin) => {
  if (callOrigin) {
    const {
      Id,
      ManagerAccount,
      Contract,
      Files,
      Place,
      Company,
      Contact,
      Description
    } = callOrigin;

    let TaskTarget = {
      type: "Call",
      data: Id
    };

    return {
      TaskTarget,
      Manager: ManagerAccount,
      Followers: [ManagerAccount],
      Contract,
      Contact,
      Company,
      Place,
      AssociatedFiles: Files,
      InternalDescription: Description
    };
  }
};

const convertInterventionToTask = (interventionOrigin) => {
  if (interventionOrigin) {
    const { Contract, Place, Company, Files } = interventionOrigin;

    return {
      TaskTarget: getInterventionTarget(interventionOrigin),
      Contract,
      Place,
      AssociatedFiles: Files,
      Company
    };
  }
};

const useTaskRequest = createFormRequestHook({
  schema: spaceTasksSchema,
  dependencies: [
    {
      schema: spaceCallsSchema,
      getEntityId: ({ callId }) => callId,
      convertEntityToForm: convertCallToTask
    },
    {
      schema: spaceTicketsSchema,
      getEntityId: ({ ticketId }) => ticketId,
      convertEntityToForm: convertTicketToTask
    },
    {
      schema: spaceInterventionsSchema,
      getEntityId: ({ interventionId }) => interventionId,
      convertEntityToForm: convertInterventionToTask
    },
    {
      schema: spaceContractSchema,
      getEntityId: ({ contractId }) => contractId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company, Files, Contact } = entity;
        return {
          TaskTarget: {
            type: "Contract",
            data: Id
          },
          Contact,
          AssociatedFiles: Files,
          Company
        };
      }
    },
    {
      schema: spaceProjectsSchema,
      getEntityId: ({ projectId }) => projectId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company, Files, Contact } = entity;
        return {
          Contact,
          TaskTarget: {
            type: "Project",
            data: Id
          },
          AssociatedFiles: Files,
          Company
        };
      }
    },
    {
      schema: spaceClientSchema,
      getEntityId: ({ clientId }) => clientId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company, Files } = entity;
        return {
          TaskTarget: {
            type: "Client",
            data: Id
          },
          AssociatedFiles: Files,
          Company
        };
      }
    },
    {
      schema: spaceTasksSchema,
      getEntityId: ({ taskId }) => taskId,
      convertEntityToForm: convertTaskToTask
    },
    {
      schema: spaceDealsSchema,
      getEntityId: ({ dealId }) => dealId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company, Files, Contact } = entity;
        return {
          TaskTarget: {
            type: originTypes.deals,
            data: Id
          },
          Contact,
          AssociatedFiles: Files,
          Company
        };
      }
    },
    {
      schema: spaceEmailConversationSchema,
      getEntityId: ({ emailConversationId }) => emailConversationId,
      convertEntityToForm: (entity, props) => {
        const { Organization, Client, LastEmail, Contact, Files } = entity;
        const email = getEntity(spaceEmailSchema, LastEmail);
        return {
          Company: Organization,
          TaskTarget: {
            type: originTypes.client,
            data: Client
          },
          Contact,
          AssociatedFiles: Files,
          Name: email.Subject
        };
      }
    }
  ],
  convertEntityToForm: convertTaskEntityToForm,
  formatDuplicationEntity: (entity) => {
    const { PipelineStatus } = entity;
    if (PipelineStatus) {
      const pipelineStatusEntity = getEntity(
        spacePipelineStatusSchema,
        PipelineStatus
      );
      if (pipelineStatusEntity.Status !== TasksStatusTypes.open) {
        entity.PipelineStatus = null;
      }
    }
    const TaskTarget = getInterventionTarget(entity);
    if (TaskTarget?.type === "Client") {
      const resolvedClient = getEntity(spaceClientSchema, TaskTarget.data);
      if (resolvedClient.Status === 2) {
        entity.Client = undefined;
      }
    }
    return entity;
  }
});

const TaskForm = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { key, loading, error, convertedForm, isChild, baseForm, childProps } =
    useTaskRequest(props);

  const { id, defaultForm, onSuccess, className } = props;
  // const space = useSpace();
  const task = useTask(id);
  const user = useCurrentAccount();
  const {
    loading: loadingPipelines,
    data: pipelines,
    error: errorPipelines
  } = useSpaceQuery(`query/pipelines?type=${PipelineTypeEnum.Task}`, [
    spacePipelineSchema
  ]);

  const orgState = useSpaceQuery(
    `query/organizations?$filter=((Account/Id eq '${user.Id}') or (Manager/Id eq '${user.Id}'))`,
    [spaceOrganizationSchema]
  );

  const { loading: loadingOrganizations, data: organizations } = orgState;

  const resolvedForm = useMemo(() => {
    const form = {
      ...defaultForm,
      ...convertedForm,
      ...baseForm
    };

    if (childProps) {
      form.TaskTarget = getInterventionTarget(childProps) || form.TaskTarget;
    }
    //;

    if (
      !id &&
      organizations &&
      organizations.findIndex((e) => e === form.Company) === -1
    ) {
      form.Company = organizations[0];
    }

    return form;
  }, [baseForm, childProps, convertedForm, defaultForm, id, organizations]);

  const { data: formTemplates, refetch: formTemplateRefetch } =
    useFormTemplateGetter(spaceTasksSchema);

  if (
    loadingPipelines ||
    errorPipelines ||
    loading ||
    error ||
    loadingOrganizations
  )
    return <LoaderSpinner center size="sm" className="text-secondary" />;

  if (!isOrganzationRequestValid(orgState))
    return <OrganizationRequestSideError requestState={orgState} />;

  const canEdit = task ? task.CanEdit || false : true;
  const CanCancel = task ? task.CanCancel || false : true;
  const canDelete = task?.CanDelete || false;

  return (
    <FormTemplateProvider forms={formTemplates} refetch={formTemplateRefetch}>
      <TaskFormBase
        className={className}
        isChild={isChild}
        onSuccess={onSuccess}
        organizations={organizations}
        pipelines={pipelines}
        resolvedForm={resolvedForm}
        id={id}
        user={user}
        canEdit={canEdit}
        canDelete={canDelete}
        canCancel={CanCancel}
        convertedForm={resolvedForm}
      />
    </FormTemplateProvider>
  );
};

const TaskFormBase = ({ id, convertedForm, resolvedForm, user, ...rest }) => {
  const resolvedDefaultFrom = useMemo(() => {
    return buildTaskBaseForm(user, id, resolvedForm);
  }, [id, resolvedForm, user]);

  const formState = useFormState(resolvedDefaultFrom, validateTaskForm);

  const [settingRecurrence, setSettingRecurrence] = useState(false);

  if (settingRecurrence) {
    return (
      <TaskRecurrenciesForm
        setSettingRecurrence={setSettingRecurrence}
        onSuccess={rest.onSuccess}
        taskForm={formState.form}
        {...rest}
      />
    );
  }

  return (
    <TaskFormBaseDetails
      formState={formState}
      id={id}
      setSettingRecurrence={setSettingRecurrence}
      {...rest}
    />
  );
};

const TaskFormBaseDetails = ({
  id,
  canEdit,
  pipelines,
  organizations,
  canCancel,
  canDelete,
  onSuccess,
  className,
  formState,
  setSettingRecurrence
}) => {
  // const [openSidebar, CloseSidebar] = useSidebar();

  const { form, setForm } = formState;
  const { TaskTarget } = form;

  // useMarkRead(spaceTasksSchema, id, Manager);

  const [post, { loading }] = useTaskPost({ onSuccess, id, TaskTarget });
  const [remove, { loading: deleteLoading }] = useTaskDelete({ onSuccess, id });

  //   const handleEstimatedTimeChanges = e => {
  //     const { value } = e.target;
  //     const estimatedTime = HourToMilSec(value[0]) + MinuteToMilSec(value[1]);
  //     handleChange({
  //       EstimatedCost: estimatedTime
  //     });
  //   };

  const handleSubmit = (form) => {
    post(formatTaskForm(form, { automation: Boolean(Automation) }));
  };

  // const followers = useJoinedFollowerAccounts(Manager);
  const Automation = useContext(SidebarAutomationContext);
  // const possibleFollowers = useAvailableFollowers(Creator, followers);

  // const handle

  return (
    <>
      <SideForm
        canEdit={canEdit}
        formState={formState}
        onSubmit={handleSubmit}
        validate={validateTaskForm}
      >
        <SideFormContent form={form} className={className}>
          <TaskFormContent
            canEdit={canEdit}
            formState={formState}
            id={id}
            organizations={organizations}
            pipelines={pipelines}
          />
        </SideFormContent>
        <SideFormFooter
          canDelete={canDelete}
          deleteLoading={deleteLoading}
          handleDelete={remove}
          disabled={deleteLoading}
          handleChange={setForm}
          form={form}
          schema={spaceTasksSchema}
          subComponent={
            !Automation && !id ? (
              <div className="w-100 d-flex justify-content-end">
                <RecurrenceButton setSettingRecurrence={setSettingRecurrence} />
              </div>
            ) : undefined
          }
          isLoading={loading}
        ></SideFormFooter>
      </SideForm>
    </>
  );
};

const RecurrenceButton = ({ setSettingRecurrence }) => {
  const validate = useContext(FormValidationContext);

  const handleClick = useCallback(() => {
    const { isValid } = validate();
    if (!isValid) return;
    setSettingRecurrence(true);
  },[setSettingRecurrence, validate]);

  return (
    <Button onClick={handleClick} type="button" vType="link-primary mr-3">
      <FontAwesomeIcon icon={faRetweet} />{" "}
      <FormattedMessage id="CREATE_RECURRENCE" />
    </Button>
  );
};

export default TaskForm;
