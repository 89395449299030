/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useMemo,
  useLayoutEffect,
  useCallback,
  Component,
  useContext
} from "react";
import ReactDOM from "react-dom";
import { subscribeUniqueEvent } from "../../Helpers/ComponentsHelper";
import "./ColumnChooser.css";
import Modal from "../Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { ActionButtonItem } from "../ActionsButton/ActionsButton";
import Button from "../Button/Button";
import classnames from "classnames";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import querystring from "query-string";
import { ColumnChooserListnerDict } from "./ColumnChooserHelper";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  ServerAwareUpdateTempViewsContext,
  ServerAwareUpdateViewsContext,
  ServerAwareViewsContext
} from "../CGrid/ServerAwareViews";
import { useSpace } from "../../Contexts/SpaceContext";
import ColumnChooserDrag from "./ColumnChooserdrag";
import { ServerAwareFavoritesContext } from "../../Containers/AppBar/ServerAwareFavorite";
import { AppBarFavoriteTreeSetter } from "../../Containers/AppBar/AppBarFavoriteQuery";
import { AppFavoriteContext } from "../../Containers/AppBar/AppFavoriteProvider";
import { useFavoriteOrViewChecker } from "../../Containers/AppBar/AppBarHelper";

const getState = (columns, schemaName, orderList) => {
  //local storage

  const orderListDict = {};

  let orderListExists = Array.isArray(orderList) ? true : false;
  if (orderListExists)
    for (const ol of orderList) {
      orderListDict[ol] = true;
    }

  const state = {};

  for (const c of columns) {
    if (!orderListExists) state[c.field] = true;
    else state[c.field] = orderListDict[c.field] || undefined;
  }

  return state;
};

const getActiveColumns = (Columns, orderList, stickyLayout) => {
  const stickyDict = {};
  const stickyFields = stickyLayout ? [...stickyLayout] : [];
  if (stickyLayout) {
    for (const field of stickyLayout) {
      stickyDict[field] = true;
    }
  } else {
    for (const column of Columns) {
      const { field, sticky } = column;
      if (sticky) {
        stickyDict[field] = true;
        stickyFields.push(field);
      }
    }
  }

  const orderedFields = [];

  if (orderList) {
    for (const field of orderList) {
      if (stickyDict[field]) continue;
      orderedFields.push(field);
    }
  } else {
    for (const column of Columns) {
      const { field } = column;
      if (stickyDict[field]) continue;
      orderedFields.push(field);
    }
  }

  return { orderedFields, stickyFields, stickyDict };
};

const getfieldTitleDict = (Columns) => {
  const result = {};
  for (const c of Columns) {
    result[c.field] = c;
  }
  return result;
};

export const useFavoriteSelectedColumn = (onClose) => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const { orderedFields, stickyFields } = FavoriteItem.data;
  const setTree = useContext(AppBarFavoriteTreeSetter);
  const handleColumnChange = useCallback(
    (result) => {
      setTree((oldTree) => {
        const { orderedFields, stickyFields } = result;
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items };
        const newItem = { ...newItems[FavoriteItem.id] };
        const newData = { ...newItem.data };
        newData.orderedFields = [...stickyFields, ...orderedFields];
        newData.stickyFields = stickyFields;
        newItem.data = newData;
        newItems[FavoriteItem.id] = newItem;
        return {
          rootId,
          items: newItems
        };
      });
      onClose();
    },
    [FavoriteItem, onClose, setTree]
  );

  const handleColumnReset = useCallback(
    (result) => {
      setTree((oldTree) => {
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items };
        const newItem = { ...newItems[FavoriteItem.id] };
        const newData = { ...newItem.data };

        newData.orderedFields = undefined;
        newData.stickyFields = undefined;
        newItem.data = newData;
        newItems[FavoriteItem.id] = newItem;
        return {
          rootId,
          items: newItems
        };
      });
      onClose();
    },
    [FavoriteItem.id, onClose, setTree]
  );

  return {
    handleColumnChange,
    handleColumnReset,
    orderedFields,
    stickyFields
  };
};

export const ColumnChooserFavorites = ({ ...rest }) => {
  const { onClose } = rest;
  const { handleColumnChange, handleColumnReset, orderedFields, stickyFields } =
    useFavoriteSelectedColumn(onClose);

  return (
    <ColumnChooserComp
      {...rest}
      handleChange={handleColumnChange}
      stickyLayout={stickyFields}
      columnLayout={orderedFields}
      handleReset={handleColumnReset}
    />
  );
};

export const ColumnChooser = ({ ...rest }) => {
  const result = useFavoriteOrViewChecker();

  if (result) return <ColumnChooserFavorites {...rest} />;
  return <ColumnChooserViews {...rest} />;
};

export const ColumnChooserViews = ({ ...rest }) => {
  const { schema, schemaView, name, onClose } = rest;
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const schemaViews = useContext(ServerAwareViewsContext);
  const lsIndex = schemaViews?.findIndex((e) => e.id === viewPref);
  const lsViews =
    schemaViews && lsIndex === -1
      ? schemaViews[0]
      : schemaViews
      ? schemaViews[lsIndex]
      : {};
  const schemaName = schemaView
    ? schemaView.name
    : schema
    ? schema.name
    : rest.Name;
  const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);
  const space = useSpace();

  const handleChange = (state) => {
    let gridSavedViews =
      JSON.parse(
        window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
      ) || {};
    const viewGridViews = gridSavedViews[schemaName] || [];
    const index = viewGridViews.findIndex((e) => e.id === viewPref);
    let resolvedIndex = index === -1 ? 0 : index;
    let viewGridObject = viewGridViews[resolvedIndex] || {};
    const { orderedFields, stickyFields } = state;

    viewGridObject.columnLayout = [...stickyFields, ...orderedFields];
    viewGridObject.stickyLayout = stickyFields;
    viewGridViews[resolvedIndex] = viewGridObject;
    gridSavedViews[schemaName] = viewGridViews;
    localStorage.setItem(
      `ar-grid-saved-views-space-${space.Id}`,
      JSON.stringify(gridSavedViews)
    );
    updateLsViews && updateLsViews();

    onClose();
  };

  const handleReset = () => {
    let gridSavedViews =
      JSON.parse(
        window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
      ) || {};
    const viewGridViews = gridSavedViews[schemaName] || [];
    const index = viewGridViews.findIndex((e) => e.id === viewPref);
    let resolvedIndex = index === -1 ? 0 : index;
    let viewGridObject = viewGridViews[resolvedIndex] || {};
    viewGridObject.columnLayout = undefined;
    viewGridObject.stickyLayout = undefined;
    viewGridViews[resolvedIndex] = viewGridObject;
    gridSavedViews[schemaName] = viewGridViews;
    localStorage.setItem(
      `ar-grid-saved-views-space-${space.Id}`,
      JSON.stringify(gridSavedViews)
    );
    updateLsViews && updateLsViews();
    onClose();
  };

  return (
    <ColumnChooserComp
      {...rest}
      handleChange={handleChange}
      stickyLayout={lsViews.stickyLayout}
      columnLayout={lsViews.columnLayout}
      handleReset={handleReset}
    />
  );
};

export const ColumnChooserComp = ({
  schema,
  onClose,
  setIsOpen,
  schemaView,
  isOpen,
  Columns,
  columnLayout,
  handleChange,
  stickyLayout,
  handleReset,
  ...rest
}) => {
  const space = useSpace();
  const fieldDict = getfieldTitleDict(Columns);

  useEffect(() => {
    return subscribeUniqueEvent((e) => {
      if (e.keyCode === 27) {
        e.preventDefault();
        setIsOpen(false);
      }
    });
  }, [isOpen, setIsOpen]);

  const schemaName = schemaView
    ? schemaView.name
    : schema
    ? schema.name
    : rest.Name;
  const [searchBarValue, setSearchBarValue] = useState("");
  const [selectionState, setSelectionState] = useState(() => {
    return getState(Columns, schemaName, columnLayout);
  });
  const [state, setState] = useState(() => {
    return getActiveColumns(Columns, columnLayout, stickyLayout);
  });

  // const [orderedColumns, setOrderedColumns] = useState(() => {
  //   return getActiveColumns(Columns, lsViews.columnLayout, lsViews.stickyLayout);
  // });

  const resolvedColumns = useMemo(() => {
    if (!searchBarValue) return Columns;
    //;
    const newColumns = Columns.filter(
      (v) =>
        v.title && v.title.toLowerCase().includes(searchBarValue.toLowerCase())
    );

    return newColumns;
  }, [Columns, searchBarValue]);

  const handleSelectionChange = useCallback(
    (field, isChecked) => {
      setState((val) => {
        const { stickyDict, stickyFields, orderedFields } = val;
        const isSticky = stickyDict[field];
        if (!isChecked) {
          if (isSticky) {
            const newStickyFields = [...stickyFields];
            const index = newStickyFields.indexOf(field);
            newStickyFields.splice(index, 1);
            return { ...val, stickyFields: newStickyFields };
          } else {
            const newOrderedFields = [...orderedFields];
            const index = newOrderedFields.indexOf(field);
            newOrderedFields.splice(index, 1);
            return { ...val, orderedFields: newOrderedFields };
          }
        } else {
          if (isSticky) {
            const newStickyFields = [...stickyFields];
            newStickyFields.unshift(field);
            return { ...val, stickyFields: newStickyFields };
          } else {
            const newOrderedFields = [...orderedFields];
            newOrderedFields.unshift(field);
            return { ...val, orderedFields: newOrderedFields };
          }
        }
      });

      setSelectionState((s) => {
        const newState = { ...s };
        newState[field] = isChecked;
        return newState;
      });
    },
    [setState]
  );

  const submit = () => {
    handleChange(state);
  };

  const reset = () => {
    handleReset();
  };

  return (
    <div className="ColumnChooser-ModalWrap">
      <div className="p-30">
        <div className="fs-16 mb-10 fw-bold text-black d-flex justify-content-between">
          <span className="pt-1">
            {" "}
            <FormattedMessage id={"CHOOSE_COLUMNS"} />
          </span>
        </div>
        <div className="d-flex mb-4">
          <div className="ColumnChooserContent ColumnChooserContent-borderRight d-flex flex-column justify-content-between of-hidden pr-4">
            {/* <ColumnChooserSearchBar
              value={searchBarValue}
              handleChange={setSearchBarValue}
              className="w-a mb-3"
            /> */}
            <div className="ColumnChooserCheckList d-flex flex-1 flex-column of-hidden">
              <ColumnChooserCheckList
                filterBy={searchBarValue}
                onChange={handleSelectionChange}
                Columns={resolvedColumns}
                selectionState={selectionState}
              />
            </div>
          </div>
          <div className="ColumnChooserContent justify-content-between pl-4">
            <ColumnChooserDrag
              value={state}
              dict={fieldDict}
              onChange={setState}
              removeColumn={handleSelectionChange}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <Button onClick={reset} vType="link-primary">
              <FormattedMessage id={"RESTORE_COLUMNS"} />
            </Button>
          </div>
          <div>
            <Button
              className="mr-3"
              onClick={() => {
                setIsOpen(false);
              }}
              vType="link-danger"
            >
              <FormattedMessage id={"CANCEL"} />
            </Button>
            <Button onClick={submit} vType="primary">
              <FormattedMessage id={"SAVE"} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColumnChooserCheckList = ({ Columns, selectionState, onChange }) => {
  let columnsList = "";
  let filteredList = null;

  if (Columns) {
    columnsList = Columns.map((e, index) => {
      const isChecked = selectionState[e.field] || false;
      const icon = e.columnChooserIcon || e.headerIcon;
      return (
        <div
          key={e ? e.field + "" + index + "" + e.title : new Date().toString}
          className="d-flex justify-content-start text-black mb-3"
        >
          <Checkbox
            disabled={e.required && isChecked}
            onChange={() => {
              onChange(e.field, !isChecked);
            }}
            checked={isChecked}
            // textValue={
            //   e && (typeof e.title === "function" ? e.title() : e.title)
            // }
          >
            <div className="d-flex align-items-center text-grey text-uppercase ml-1">
              {icon && (
                <FontAwesomeIcon className="mr-2" size="sm" icon={icon} />
              )}
              {e && (typeof e.title === "function" ? e.title() : e.title)}
            </div>
          </Checkbox>
        </div>
      );
    });
  }

  return (
    <>
      <div className="fs-14 mb-3 fw-medium text-black">
        <FormattedMessage id={"NAME"} />
      </div>
      <div className="flex-1 h-100 ml-2 of-y-auto">{columnsList}</div>
    </>
  );
};
