import React from "react";
import Input from "../../../Components/Input/Input";
import { isValidDomainName } from "../../../Helpers/MiscHelper";
import classnames from "classnames";

const SpaceDomainInput = ({ className, onChange, value, ...rest }) => {
  const handleChange = (e) => {
    const { value } = e.target;
    const isValid =
      value === "" || (value.length <= 30 && isValidDomainName(value));
    if (!isValid) return;

    onChange(value);
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Input
        className={classnames("text-right", className)}
        value={value}
        onChange={handleChange}
        {...rest}
      />
      <div className="ml-2">.airdesk.ai</div>
    </div>
  );
};

export default SpaceDomainInput;
