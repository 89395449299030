import React from "react";
import "./Button.css";

/**
 * @param {function} onClick - Click callback
 * @param {string} vType - Button Type ('primary', 'outline-primary'). Defaults to primary
 * @param {string} className - Class to add to button container
 * @param {component} children - Button's children
 * @param {string} color - Button's border-color and color
 */

const Button = React.forwardRef<HTMLButtonElement, any>((props, ref) => {
  const {
    className,
    dispatch,
    color,
    children,
    size,
    vType,
    onClick,
    propStyle,
    ...passThroughProps
  } = props;

  let classes = "ssi-button ssi-control disable-selection";

  if (vType !== null) {
    if (vType) classes += ` ssi-button-${vType}`;
    else classes += " ssi-button-primary";
  }

  if (size) classes += ` ${size}`;

  if (className != null) classes += " " + className;

  let style = propStyle;

  if (color)
    style = {
      color: color,
      border: "1px solid " + color,
      borderRadius: "5px"
    };

  return (
    <button
      ref={ref}
      className={classes}
      style={style}
      onClick={onClick}
      {...passThroughProps}
    >
      {children}
    </button>
  );
});

export default Button;
