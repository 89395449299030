import React, { useContext, useRef } from "react";
import { EntitySummaryConfigContext } from "./EntitySummariesHelper";
import { useHoverEvents } from "../../Containers/Origin/Origin";
import InfoPopup from "../Popup/InfoPopup";
import classnames from "classnames";

export const EntitySummaryHeaderTitle = ({
  TextComponent,
  value,
  FontSize,
  noLink
}) => {
  const { LinkComp } = useContext(EntitySummaryConfigContext) || {};
  const hoverEvents = useHoverEvents();
  const {
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen,
    onPopupMouseEnter,
    onPopupMouseLeave
  } = hoverEvents;
  const anchorRef = useRef();

  if (!value)
    return (
      <div className={"ar-details-id mx-auto text-black d-flex skeleton"}></div>
    );
  const { Name, NoTagDescription, Id } = value;

  const resolvedTitle = TextComponent ? (
    <TextComponent value={value} />
  ) : (
    Name || NoTagDescription
  );

  if (typeof resolvedTitle !== "string") {
    return (
      <div
        ref={anchorRef}
        // onMouseEnter={onAnchorMouseEnter}
        // onMouseLeave={onAnchorMouseLeave}
        className={classnames(
          "text-truncate",
          { "fs-24": !FontSize },
          FontSize
        )}
      >
        {resolvedTitle}
      </div>
    );
  }
  return (
    <>
      <div className="flex-1 d-flex text-truncate">
        {LinkComp && !noLink ? (
          <LinkComp
            innerRef={anchorRef}
            onMouseEnter={onAnchorMouseEnter}
            onMouseLeave={onAnchorMouseLeave}
            id={Id}
            className="fs-24 text-truncate"
          >
            {resolvedTitle}
          </LinkComp>
        ) : (
          <div
            ref={anchorRef}
            onMouseEnter={onAnchorMouseEnter}
            onMouseLeave={onAnchorMouseLeave}
            className={classnames(
              "text-truncate",
              { "fs-24": !FontSize },
              FontSize
            )}
          >
            {resolvedTitle}
          </div>
        )}
      </div>
      <InfoPopup
        anchorEl={anchorRef.current}
        placement="bottom"
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        {resolvedTitle}
      </InfoPopup>
    </>
  );
};
