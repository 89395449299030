import { getInterventionTarget } from "../../Helpers/FormFormmaterHelper";

export const convertTicketEntityToForm = (entity, props) => {
  if (!entity) {
    return;
  }

  const {
    Department,
    Team,
    Client,
    Call,
    Project,
    Tags,
    Contract,
    ...entityRest
  } = entity;

  let resolvedForm = { ...entityRest };

  if (Department) {
    resolvedForm.DepartmentTeam = {
      type: "Department",
      data: Department
    };
  } else if (Team) {
    resolvedForm.DepartmentTeam = {
      type: "Team",
      data: Team
    };
  }
  resolvedForm.TicketTarget = getInterventionTarget(entity);

  if (Array.isArray(Tags) && Tags.length > 0) {
    const resolvedTags = [];
    for (const t of Tags) {
      resolvedTags.push({
        data: t,
        type: "TicketTag"
      });
    }
    resolvedForm.Tags = resolvedTags;
  }

  return resolvedForm;
};
