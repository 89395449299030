import { faUserTie } from "@fortawesome/pro-solid-svg-icons";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import { formatContactAutomationForm } from "../../../../../Helpers/FormFormmaterHelper";
import ContactFormContent from "../../../../Contacts/ContactFormContent";
import { defaultContactForm } from "../../../../Contacts/ContactsHelper";
import { ElementsRefContext } from "../../AutomationDetails";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import SourceHandles from "../../Handles/SourceHandles";
import TargetHandles from "../../Handles/TargetHandles";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import AutomationCreation, {
  EntityAutomationCreationSidebar
} from "./CreationNode";

const convertAutomationToContactForm = (form, currencies) => {
  const {
    creator,
    clients,
    name,
    phone_number,
    email,
    mobile_number,
    description,
    status,
    gdpr_type
  } = form;

  return {
    Creator: creator,
    Clients: clients,
    Name: name,
    PhoneNumber: phone_number,
    Email: email,
    MobileNumber: mobile_number,
    Description: description,
    Status: status,
    GDPRType: gdpr_type
  };
};

const settings = {
  convertAutomationToForm: convertAutomationToContactForm,
  buildBaseForm: ({ user, id, convertedForm, currencies }) => {
    const defaultForm = defaultContactForm;
    return { ...defaultForm, ...convertedForm };
  },
  getDefaultForm: ({ user }) => {
    return defaultContactForm;
  },
  FormContent: (props) => {
    return <ContactFormContent {...props}></ContactFormContent>;
  },
  formatToSnake: formatContactAutomationForm
};

const ContactCreation = React.memo(({ selected, id, data, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const AllElementsRef = useContext(ElementsRefContext);
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleDoubleClick = () => {
    openSidebar(
      <EntityAutomationCreationSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        CurrentNodeId={id}
        settings={settings}
        data={data}
        handleSubmit={handleSubmit}
        AllElements={AllElementsRef.current}
      />
    );
  };
  const { enabled, config } = data;
  const intl = useIntl();
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <AutomationCreation
          config={config}
          type={type}
          enabled={enabled}
          selected={selected}
          onDoubleClick={handleDoubleClick}
          icon={faUserTie}
          text={intl.formatMessage({ id: "CREATE_CONTACT" })}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default ContactCreation;
