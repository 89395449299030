import React, { useState, useMemo } from "react";
import { useSidebar } from "../Sidebar/SidebarV2";
import { useGridRequest, useServerAwareState } from "../CGrid/ServerGrid";
import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import { useToast } from "../Toast/ToastProvider";
import {
  useSidebarSpacePost,
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent
} from "../Sidebar/SidebarV2Helper";
import { getEntity } from "../../Helpers/IOClient";
import { SideFormContent, SideMenuFormFooterBase } from "../Forms/SideMenuForm";
import FormInput from "../Forms/FormInput/FormInput";
import LoadableButton from "../Button/LoadableButton";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { handleError } from "../../Helpers/MiscHelper";
import {
  TicketTagDropdown,
  ClientTagDropdown,
  DealTagDropdown,
  ContractTagDropdown,
  SubscriptionTagDropdown,
  CallTagDropdown,
  ProjectTagDropdown,
  TaskTagDropdown
} from "../../Containers/AdvancedMultiInputs";
import {
  spaceClientTagsSchema,
  spaceDealsTagsSchema,
  spaceContractTagsSchema,
  spaceSubscriptionTagsSchema,
  spaceCallsTagsSchema,
  spaceTicketTagsSchema,
  spaceProjectTagsSchema,
  spaceTaskTagsSchema
} from "../../config/schema";

const MultiMarkerChanger = ({ selectedItems }) => {
  const [openSidebar] = useSidebar();
  const { refetch } = useGridRequest();

  const viewData = useServerAwareState();
  const handleStatusMultiAction = () => {
    openSidebar(
      <TagMultiActionForm
        selectedItems={selectedItems}
        viewData={viewData}
        refetch={refetch}
      />
    );
  };

  return (
    <Button
      className="p-1 AlignLeftLoadableButton justify-content-start d-flex align-items-center"
      vType={"link"}
      onClick={handleStatusMultiAction}
    >
      <div className="ar-form-button-link-icon mr-2">
        <FontAwesomeIcon icon={faTag} />
      </div>
      <FormattedMessage id="CHANGE_TAGS" />
    </Button>
  );
};

const getModuleItems = (type) => {
  let result = {};

  switch (type) {
    case moduleTypes.clients:
      result = {
        changed: "CLIENTS_CHANGED",
        notChanged: "CLIENTS_NOT_CHANGED",
        failed: "CLIENTS_FAILED_CHANGED",
        noPermission: "CLIENTS_NO_PERMISSION",
        tagsSchema: spaceClientTagsSchema,
        Dropdown: ClientTagDropdown
      };
      break;
    case moduleTypes.deals:
      result = {
        changed: "DEALS_CHANGED",
        notChanged: "DEALS_NOT_CHANGED",
        failed: "DEALS_FAILED_CHANGED",
        noPermission: "DEALS_NO_PERMISSION",
        tagsSchema: spaceDealsTagsSchema,
        Dropdown: DealTagDropdown
      };
      break;
    case moduleTypes.contracts:
      result = {
        changed: "CONTRACTS_CHANGED",
        notChanged: "CONTRACTS_NOT_CHANGED",
        failed: "CONTRACTS_FAILED_CHANGED",
        noPermission: "CONTRACTS_NO_PERMISSION",
        tagsSchema: spaceContractTagsSchema,
        Dropdown: ContractTagDropdown
      };
      break;
    case moduleTypes.subscriptions:
      result = {
        changed: "SUBSCRIPTIONS_CHANGED",
        notChanged: "SUBSCRIPTIONS_NOT_CHANGED",
        failed: "SUBSCRIPTIONS_FAILED_CHANGED",
        noPermission: "SUBSCRIPTIONS_NO_PERMISSION",
        tagsSchema: spaceSubscriptionTagsSchema,
        Dropdown: SubscriptionTagDropdown
      };
      break;
    case moduleTypes.calls:
      result = {
        changed: "CALLS_CHANGED",
        notChanged: "CALLS_NOT_CHANGED",
        failed: "CALLS_FAILED_CHANGED",
        noPermission: "CALL_NO_PERMISSION",
        tagsSchema: spaceCallsTagsSchema,
        Dropdown: CallTagDropdown
      };
      break;
    case moduleTypes.tickets:
      result = {
        changed: "TICKETS_CHANGED",
        notChanged: "TICKETS_NOT_CHANGED",
        failed: "TICKETS_FAILED_CHANGED",
        noPermission: "TICKETS_NO_PERMISSION",
        tagsSchema: spaceTicketTagsSchema,
        Dropdown: TicketTagDropdown
      };
      break;
    case moduleTypes.projects:
      result = {
        changed: "PROJECTS_CHANGED",
        notChanged: "PROJECTS_NOT_CHANGED",
        failed: "PROJECTS_FAILED_CHANGED",
        noPermission: "PROJECTS_NO_PERMISSION",
        tagsSchema: spaceProjectTagsSchema,
        Dropdown: ProjectTagDropdown
      };
      break;
    case moduleTypes.tasks:
      result = {
        changed: "TASKS_CHANGED",
        notChanged: "TASKS_NOT_CHANGED",
        failed: "TASKS_FAILED_CHANGED",
        noPermission: "TASKS_NO_PERMISSION",
        tagsSchema: spaceTaskTagsSchema,
        Dropdown: TaskTagDropdown
      };
      break;

    default:
      break;
  }

  return result;
};

const formatSubmition = (selectedItems, tagIds) => {
  return {
    itemIds: selectedItems,
    tagIds
  };
};

const TagMultiActionForm = ({ selectedItems, refetch, viewData }) => {
  const [Tags, setTags] = useState([]);

  const disableConfirm = useMemo(() => Tags.length === 0, [Tags]);

  const [removingTags, setRemovingTags] = useState(false);

  const handleTagChange = (e) => {
    const { value } = e.target;
    setTags(value);
  };

  const { schema, moduleType } = viewData;

  const resolvedItems = useMemo(() => getModuleItems(moduleType), [moduleType]);

  const resolvedPipeline = useMemo(() => {
    let currentPipeline = undefined;
    if (Array.isArray(selectedItems.selectedItems))
      for (const item of selectedItems.selectedItems) {
        const resolvedItem = getEntity(schema, item);
        if (!resolvedItem.Pipeline) break;
        if (currentPipeline === undefined) {
          currentPipeline = resolvedItem.Pipeline;
        } else if (currentPipeline !== resolvedItem.Pipeline) {
          currentPipeline = undefined;
          break;
        }
      }

    return currentPipeline;
  }, [schema, selectedItems.selectedItems]);

  const createToast = useToast();
  const [, closeSidebar] = useSidebar();
  const intl = useIntl();
  const [post, { loading }] = useSidebarSpacePost(
    `${moduleType}/tags/Multi`,
    null,
    {
      onSuccess: ({ openSidebar, disableClosure, data }) => {
        createToast({
          pos: "tm",
          type: "success",
          description: `${data.Success?.length} ${
            resolvedItems.changed &&
            intl.formatMessage({
              id: resolvedItems.changed
            })
          }, ${data.NotChange.length} ${
            resolvedItems.notChanged &&
            intl.formatMessage({
              id: resolvedItems.notChanged
            })
          }${
            data.Fail.length > 0
              ? `, ${data.Fail.length} ${
                  resolvedItems.failed &&
                  intl.formatMessage({
                    id: resolvedItems.failed
                  })
                }`
              : ""
          }${
            data.NoPermission?.length > 0
              ? `, ${data.NoPermission.length} ${intl.formatMessage({
                  id: resolvedItems.noPermission
                })}`
              : ""
          }`
        });
        closeSidebar();
        selectedItems.ClearSelection();
        refetch();
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  const submitDelete = () => {
    let tagsIds = [];
    post(formatSubmition(selectedItems.selectedItems, tagsIds));
  };

  const submit = () => {
    let tagsIds = [];
    if (Tags && Array.isArray(Tags))
      for (const e of Tags) {
        const { TempId } = getEntity(resolvedItems.tagsSchema, e.data);
        if (TempId) tagsIds.push(TempId);
      }

    post(formatSubmition(selectedItems.selectedItems, tagsIds));
  };

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"CHANGE_TAGS"} />
      </BaseSidebarHeader>
      <BaseSidebarContent className="d-flex flex-column">
        <SideFormContent className="px-4 py-3">
          <div className="d-flex w-100 flex-column h-100">
            <FormInput
              className="mb-3"
              name="Tags"
              textId="TAGS"
              onChange={handleTagChange}
              value={Tags}
              pipelineId={resolvedPipeline}
              // getKey={(e) => {
              //   const resolvedTag = getEntity(spaceTicketTagsSchema, e);
              //   return `${e}-${resolvedTag?.Pipeline}`;
              // }}
              multiple
              inputType={resolvedItems.Dropdown}
            />

            <div />
          </div>
        </SideFormContent>
        <SideMenuFormFooterBase className="d-flex align-items-center justify-content-between">
          {removingTags ? (
            <>
              <div className="fs-14 text-danger-alt mr-3">
                <FormattedMessage id={"REMOVE_TAGS"} />
              </div>
              <div className="d-flex flex-1 align-items-center justify-content-end">
                <Button
                  vType="primary-ghost"
                  className="mr-3"
                  onClick={() => {
                    setRemovingTags(false);
                  }}
                >
                  <FormattedMessage id={"NO"} />
                </Button>
                <LoadableButton
                  isLoading={loading}
                  onClick={submitDelete}
                  vType="danger-ghost-alt"
                >
                  <FormattedMessage id={"YES"} />
                </LoadableButton>
              </div>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setRemovingTags(true);
                }}
                className="fs-14"
                vType="link-danger"
              >
                <FormattedMessage id={"REMOVE_TAGS"} />
              </Button>
              <LoadableButton
                isLoading={loading}
                disabled={disableConfirm}
                onClick={submit}
                vType="primary-ghost"
              >
                <FormattedMessage id={"SUBMIT"} />
              </LoadableButton>
            </>
          )}
        </SideMenuFormFooterBase>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export default MultiMarkerChanger;
