import React, { Component, useCallback } from "react";
import Checkbox from "../Checkbox/Checkbox";
import {
  GenerateGuid,
  dateFormat,
  isDate,
  DoubleDigitFormat
} from "../../Assets/Utils";
import "./CGrid.css";
import Moment from "react-moment";
import { useGridRequest } from "./ServerGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/pro-solid-svg-icons";
import { PageSizeSelect } from "./PageSizeSelect";
import { BarLoader } from "../GlobalLoader/GlobalLoader";
import classnames from "classnames";
import { Communicator } from "../../Helpers/Communicator";

import GridSort from "./GridSort";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../Button/Button";
// import Tickets from "../../Containers/Tickets/Tickets";

/**
 * @param {bool} headerScroll - Enable scroll from the header
 * @param {bool} columnResize - Enable column resize
 * @param {Object[]} data - Grid data
 * @param {bool} sortable - Enable column sorting
 * @param {Object[]} columns - Grid columns
 * @param {int} page - Grid current page
 * @param {int} pages - Grid pages
 * @param {int} pageSize - Grid page size
 * @param {bool} totalItems - total items
 * @param {bool} groupable - Enable grid grouping
 * @param {int} height - Set grid height
 * @param {Function} onItemSelect - Checkbox toggle callback (returns selected items)
 * @param {Function} onSort - Callback for column sorting
 * @param {Function} onPageChange - Callback for page change
 * @param {String[]} groupBy - Columns that grid should be grouped by
 * @param {int} order - Orders grid by a especific column ({orderBy: "ColumnName", orderType: "asc"})
 * @param {bool} serverFiltering.enabled - Enables server filtering
 * @param {int} serverFiltering.pages - Amount of pages to show
 * @param {int} serverFiltering.page - Page index
 * @param {Component} loadingComponent - Loading component while grid is rendering or waiting for data
 * @param {bool} isLoading - Shows loading component
 * @param {bool} getRowKey - Shows loading component
 */

class CGrid extends Component {
  constructor(props) {
    super(props);

    let data = [...this.props.data] || [];

    // data = data.map(item => {
    //   const _c__id = GenerateGuid();
    //   return {
    //     _c__id,
    //     ...item
    //   };
    // });

    // data = this.setGridData(data);

    const isValidPropsFilter = Boolean(this.props.sort);

    const columns = this.props.columns || [];
    const groupBy = this.props.groupBy || [];

    const { groupColumns, propsGroupBy } = this.validatePropsGroup(
      columns,
      groupBy
    );

    this.lastGroupByProps = this.props.groupBy || [];
    this.lastDataProps = this.props.data || [];
    this.hasDifferentPropsData = true;

    this.serverFiltering =
      typeof props.serverFiltering === "object" ? props.serverFiltering : {};

    this.state = {
      groupColumns: groupColumns,
      data: data,
      columns: columns,
      groupBy: propsGroupBy || [],
      resizing: false,
      // groupBy: [],
      orderByFilter: isValidPropsFilter === true ? this.props.sort : [],
      //   orderBy: isValidPropsFilter === true ? this.props.sort.sortBy : "",
      //   orderType: isValidPropsFilter === true ? this.props.sort.sortType : "",
      pageSize: this.props.pageSize || 20,
      page: this.props.page || 1,
      pages: this.props.pages,
      selectedItems: [],
      selectedItemsObject: {},
      localStorageColumnSize: this.props.columnsSize,
      //   ColumnsLength: null;
      ColumnItemsWidth: () => {
        let result = 0;
        for (let i = 0; i < props.columns.length; i++) {
          result += parseInt(
            this.props.columnsSize[this.props.columns[i].field],
            10
          );
        }
        return result;
      }
    };

    this.firstTimeRender = true;

    this.updateLocalStorageColumnSize = this.props.onColumnsSizeChange;

    this.columns = [...this.state.columns];

    this.resizerLeftPosition = null;
    this.resizerMousePressed = false;

    this.draggedItem = {};
    this.dragStarted = false;
    this.dragColumnClonePosition = {};

    this.initialColumnItem = null;
    this.initialColumnIndex = null;
    this.initialColumnWidth = null;
    this.initialMousePosition = null;
    // this.ColumnItemsWidth = null;

    // this.renderGridData = true;

    this.columnsWidths = [];

    this.minColumnWidth = 114;
    this.groupColumnWidth = 30;

    this.firstPoint = null;
    this.startDragDistance = 10;
    this.dragElementWidth = 180;
    this.dragElementHeight = 40;
    this.startedDragging = false;

    this.hasBeenResizedAtLeastOnce = false;

    this.resizer = React.createRef();
    this.columnResizer = React.createRef();
    this.headScroller = React.createRef();
    this.bodyScroller = React.createRef();
    this.afterBodyScroller = React.createRef();
    this.dropzone = React.createRef();
    this.dragclone = React.createRef();
  }

  onTotalSroll = (e) => {
    if (this.props.showTotals) {
      this.afterBodyScroller.current.scrollLeft =
        this.bodyScroller.current.scrollLeft;
    }
    this.headScroller.current.scrollLeft = this.bodyScroller.current.scrollLeft;
  };

  onBodyScroll = (e) => {
    if (this.props.showTotals) {
      this.bodyScroller.current.scrollLeft =
        this.afterBodyScroller.current.scrollLeft;
    }
    this.headScroller.current.scrollLeft = this.bodyScroller.current.scrollLeft;
  };
  onHeaderScroll = (e) => {
    if (this.props.showTotals)
      this.afterBodyScroller.current.scrollLeft =
        this.headScroller.current.scrollLeft;
    this.bodyScroller.current.scrollLeft = this.headScroller.current.scrollLeft;
  };

  // setGridData = propsdata => {
  //   let data = [...propsdata];
  //   if (
  //     data.length > 0 &&
  //     typeof data[0] !== "object" &&
  //     this.props.getValue !== undefined
  //   ) {
  //     data = data.map((item, i) => {
  //       const itm = { ...this.props.getValue(item) };
  //       return itm;
  //     });
  //   }

  //   data = data.map(item => {
  //     const _c__id = GenerateGuid();
  //     return {
  //       _c__id,
  //       data: item
  //     };
  //   });
  //   return data;
  // };

  validatePropsGroup = (columns, propsGroupby) => {
    let propsGroupBy = [...propsGroupby];
    let groupColumns = [];
    if (Array.isArray(propsGroupBy)) {
      for (let i = 0; i < propsGroupBy.length; i++) {
        let found = false;
        let objFound = {};
        for (let j = 0; j < columns.length; j++) {
          if (columns[j].field === propsGroupBy[i]) {
            found = true;
            objFound = columns[j];
            break;
          }
        }

        if (found) groupColumns.push(objFound);
        else if (!found) {
          propsGroupBy.splice(i, 1);
          i--;
        }
      }
    }

    return { groupColumns: [...groupColumns], propsGroupBy: [...propsGroupBy] };
  };

  columnMouseDown = (e, col) => {
    if (this.props.groupable !== true) return;

    this.draggedItem = col;
    // this.setState({ draggedItem: col });
    document.addEventListener("mousemove", this.dragElement);
    document.addEventListener("mouseup", this.mouseUp);
  };

  setResizerPosition = () => {
    if (this.resizer.current !== null)
      this.resizer.current.style.left = this.resizerLeftPosition + "px";
  };

  columnMouseOut = (e, i) => {
    if (this.initialColumnItem)
      this.initialColumnItem.classList.remove("hovering");

    // this.setState({
    //   resizerLeftPosition: e.currentTarget.offsetWidth + e.currentTarget.offsetLeft - 5
    // });
  };

  ResizerMouseOver = (e) => {
    if (this.initialColumnItem)
      this.initialColumnItem.classList.add("hovering");
  };

  columnMouseOver = (e, i) => {
    e.currentTarget.classList.add("hovering");
    if (this.resizerMousePressed === false) {
      this.initialColumnItem = e.currentTarget;
      this.initialColumnIndex = i;
      this.initialColumnMinimumWidth = this.columns[i]
        ? this.columns[i].minimumWidth
          ? this.columns[i].minimumWidth
          : this.columns[i].title === "Actions"
          ? this.columns[i].width
          : 80
        : 80;
      this.resizerLeftPosition =
        e.currentTarget.offsetWidth + e.currentTarget.offsetLeft - 6;

      this.setResizerPosition();

      // this.setState({
      //   resizerLeftPosition: e.currentTarget.offsetWidth + e.currentTarget.offsetLeft - 5
      // });
    }
  };

  resizerMouseDown = (e) => {
    e.preventDefault();
    if (this.columnsWidths.length === 0) this.composeColumnsWidths();

    // this.resizerMousePressed = true;
    this.setState({ resizing: true });

    this.initialMousePosition = { x: e.clientX, y: e.clientY };
    this.initialColumnWidth = this.initialColumnItem.offsetWidth;

    document.addEventListener("mouseup", this.resizerMouseUp);
    document.addEventListener("mousemove", this.resizerMouseMove);
    document.querySelector("body").classList.add("c-column-resize");
  };
  resizerMouseMove = (e) => {
    e.target.classList.add("active");
    this.initialColumnItem.classList.add("hovering");
    const diff = e.clientX - this.initialMousePosition.x;

    const width = this.initialColumnWidth + diff;

    let items = [...this.state.columns];
    //THIS IS THE COLUMN LIMITER
    if (width <= this.initialColumnMinimumWidth) return;
    // if (width <= 75) return;
    //   if (width + this.initialColumnItem.offsetLeft - 5 > this.headScroller.current.clientWidth) return

    this.columnsWidths[
      this.initialColumnIndex +
        this.state.groupBy.length +
        (this.props.subComponent !== undefined ? 1 : 0)
    ] = width;

    this.setColumnItemsWidth();

    if (this.hasBeenResizedAtLeastOnce === false)
      this.hasBeenResizedAtLeastOnce = true;

    //console.log(this.ColumnItemsWidth);

    // console.log(this.initialColumnWidth);

    items[this.initialColumnIndex] = {
      ...items[this.initialColumnIndex],
      width: width
    };

    this.resizerLeftPosition = width + this.initialColumnItem.offsetLeft - 6;

    this.setResizerPosition();

    this.columns = items;

    this.updateColumnWidth();

    // this.setState({
    //   columns: items
    // });
  };

  updateColumnWidth = () => {
    let length = this.props.subComponent !== undefined ? 1 : 0;
    length += this.state.groupBy.length;
    this.resizerMousePressed = true;
    const headerCols = this.headScroller.current.querySelectorAll(
      ":scope > div > table > colgroup > col"
    );
    const bodyCols = this.bodyScroller.current.querySelectorAll(
      ":scope > table > colgroup > col"
    );
    const afterBodyCols = this.props.showTotals
      ? this.afterBodyScroller.current.querySelectorAll(
          ":scope > table > colgroup > col"
        )
      : null;

    headerCols[this.initialColumnIndex + length].style.width =
      this.columns[this.initialColumnIndex].width + "px";
    bodyCols[this.initialColumnIndex + length].style.width =
      this.columns[this.initialColumnIndex].width + "px";
    if (afterBodyCols)
      afterBodyCols[this.initialColumnIndex + length].style.width =
        this.columns[this.initialColumnIndex].width + "px";

    //   console.log(headerCols[this.initialColumnIndex + length])
    //   console.log(this.initialColumnIndex + length)
    //   console.log(headerCols.length)
    //   console.log(this.columns)
    // for (let i = 0; i < this.columns.length; i++) {
    //   headerCols[length + i].style.width = this.columns[i].width;
    //   bodyCols[length + i].style.width = this.columns[i].width;
    // }
  };

  setColumnItemsWidth = () => {
    let ColumnItemsWidth = 0;

    for (let i = 0; i < this.columnsWidths.length; i++) {
      ColumnItemsWidth += this.columnsWidths[i];
    }

    this.headScroller.current.querySelector(
      ":scope > .c-grid-table-wrap"
    ).style.width = ColumnItemsWidth + "px";
    this.bodyScroller.current.querySelector(":scope > table").style.width =
      ColumnItemsWidth + "px";
    if (this.props.showTotals)
      this.afterBodyScroller.current.querySelector(
        ":scope > table"
      ).style.width = ColumnItemsWidth + "px";
  };

  handleSubmitResize = () => {
    const headerCols = this.headScroller.current.querySelectorAll(
      ":scope > div > table > colgroup > col"
    );

    // if (this.props.showTotals)
    // this.afterBodyScroller.current.querySelector(":scope > table").style.width =
    //   ColumnItemsWidth + "px";

    const sizes = Array.from(headerCols).map((e) => {
      return e.style.width;
    });

    const result = {};

    // //;
    for (let i = 0; i < sizes.length - 1; i++) {
      result[this.columns[i].field] = sizes[i];
    }
    this.updateLocalStorageColumnSize(result);
  };

  resizerMouseUp = (e) => {
    this.resizerMousePressed = false;
    this.handleSubmitResize();
    this.initialColumnItem.classList.remove("hovering");
    this.resizer.current.classList.remove("active");
    this.setState({ resizing: false });
    document.removeEventListener("mouseup", this.resizerMouseUp);
    document.removeEventListener("mousemove", this.resizerMouseMove);
    document.querySelector("body").classList.remove("c-column-resize");
  };

  addColumnToColumnsWidth = (add) => {
    if (this.columnsWidths.length === 0) this.composeColumnsWidths();

    if (add === true) this.columnsWidths.unshift(this.groupColumnWidth);
    else this.columnsWidths.splice(0, 1);

    if (this.hasBeenResizedAtLeastOnce === true) this.setColumnItemsWidth();
  };

  componentDidMount = () => {
    this.composeColumnsWidths();
    this.setColumnItemsWidth();
  };

  componentDidUpdate = () => {
    this.composeColumnsWidths();
    this.setColumnItemsWidth();
  };

  composeColumnsWidths = (e) => {
    const headerItems = this.headScroller.current.querySelectorAll(
      ":scope.c-grid-header-wrap > .c-grid-table-wrap > table > colgroup > col"
    );

    this.columnsWidths = Array.from(headerItems).map((e) => {
      return parseInt(e.style.width, 10);
    });
  };

  // dynamicSort = property => {
  //   let sortOrder = 1;
  //   if (property[0] === "-") {
  //     sortOrder = -1;
  //     property = property.substr(1);
  //   }
  //   return (a, b) => {
  //     const result =
  //       a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
  //     return result * sortOrder;
  //   };
  // };

  columnMouseClick = (e, col) => {
    if (
      col.field === undefined ||
      col.orderBy === false ||
      this.startedDragging === true ||
      this.props.sortable !== true ||
      col.sortable === false
    ) {
      return;
    }
    // console.log(this.state.orderByFilter);
    // console.log(this.state.orderByFilter);
    let existingIndex = this.state.orderByFilter
      ? this.state.orderByFilter
          .map(function (e) {
            return e.column;
          })
          .indexOf(col.field)
      : -1;
    let orderT;

    // console.log(col.field)
    // console.log(existingIndex)
    // console.log(this.state.orderByFilter)
    if (existingIndex !== -1) {
      switch (this.state.orderByFilter[existingIndex].type) {
        case "asc":
          orderT = "desc";
          break;
        case "desc":
          orderT = "";
          break;
        default:
          orderT = this.state.orderByFilter[existingIndex].type;
          break;
      }
    } else {
      orderT = "asc";
    }

    let column;
    switch (col.field) {
      default:
        column = col.field;
        break;
    }
    // this.renderGridData = true;

    if (orderT !== "") {
      let orderByArr = [...this.state.orderByFilter];

      if (existingIndex !== -1) orderByArr[existingIndex].type = orderT;
      else orderByArr.push({ type: orderT, column });
      //   console.log(orderByArr);
      this.setState({ orderByFilter: orderByArr }, () => {
        if (this.state.groupBy.length > 0) this.toggleCollapseItems(true);
        else if (this.props.subComponent !== undefined) {
          this.toggleCollapseSubComponents(true);
        }
      });
      //;
      //   console.log(orderByArr);
      if (typeof this.props.onSort === "function") {
        this.props.onSort(orderByArr);
      }
    } else {
      //   console.log(this.state.orderByFilter)
      let orderByArr2 = this.state.orderByFilter.map((e) => {
        return e;
      });
      const filteredOrderByArr = orderByArr2.filter((e) => e.column !== column);
      //   console.log(filteredOrderByArr);
      this.setState({ orderByFilter: filteredOrderByArr }, () => {
        if (this.state.groupBy.length > 0) this.toggleCollapseItems(true);
        else if (this.props.subComponent !== undefined) {
          this.toggleCollapseSubComponents(true);
        }
        // console.log(this.state.orderByFilter);
      });
      if (typeof this.props.onSort === "function") {
        this.props.onSort(filteredOrderByArr);
      }
    }
  };

  sort = (property) => {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      const result =
        a.data[property] < b.data[property]
          ? -1
          : a.data[property] > b.data[property]
          ? 1
          : 0;
      return result * sortOrder;
    };
  };

  dynamicSort = (array, property, type) => {
    if (property === "" || property === undefined || type === "") return array;

    const newArr = array.sort(this.sort(property));
    let returnArr = [];

    if (type === "asc") {
      returnArr = newArr;
    } else if (type === "desc") {
      for (let i = newArr.length - 1; i >= 0; i--) {
        returnArr.push(newArr[i]);
      }
    }

    return returnArr;
  };

  groupedData = (data) => {
    let newArr = [];

    const groupFilters = this.state.groupBy;
    const columns = groupFilters.map((group) => {
      return this.getColumnByField(group);
    });

    // for (let i = 0; i < columns.length; i++) {

    //   // const data2 = this.state.data.map((item) => {

    //   //   if (item[columns[i].field] === columns[i].field) return item;

    //   // });

    //   newArr.push({Title: columns[i].title, Column: columns[i], Items: data2});
    // }

    // for (let j = 0; j < columns.length; j++) {
    // }
    this.addGroupInfo(newArr, data, columns, 0);

    // for (let j = 0; j < columns.length; j++) {

    //   if (j === 0) {
    //     for (let i = 0; i < this.state.data.length; i++) {
    //       this.addGroupData(newArr, this.state.data[i], columns, j);
    //     }
    //   }
    //   else {
    //     for (let i = 0; i < newArr.length; i++) {
    //       this.addGroupData(newArr[i].Data, newArr[i].Items, columns, j);
    //     }
    //   }
    // }
    return newArr;
  };

  addGroupInfo = (newArr, items, columns, index) => {
    if (index >= columns.length) return;

    for (let i = 0; i < items.length; i++) {
      this.addGroupData(newArr, items[i], columns, index);
    }

    for (let i = 0; i < newArr.length; i++) {
      this.addGroupInfo(newArr[i].Data, newArr[i].Items, columns, index + 1);
    }
  };

  addGroupData = (array, item, columns, filterIndex) => {
    //;
    for (let i = 0; i < array.length; i++) {
      if (
        item.data &&
        array[i].Collumn.field === item.data[columns[filterIndex].field]
      ) {
        if (array[i].Items !== undefined) array[i].Items.push(item);
        else array[i].Items = [item];
        return;
      }
    }

    array.push({
      Column: columns[filterIndex],
      Field: getObjectValueByField(item.data, columns[filterIndex]),
      Items: [item],
      Data: []
    });
  };

  // getPages = pages => {
  //   let arr = [];
  //   for (let i = 0; i < pages; i++) {
  //     arr.push(i + 1);
  //   }
  //   return arr;
  // }

  getColumnByField = (field) => {
    for (let i = 0; i < this.state.columns.length; i++) {
      if (this.state.columns[i].field === field) return this.state.columns[i];
    }
    return null;
  };
  getPages = (currentPage, pagesAmount, maxPages) => {
    let pages = [];
    if (pagesAmount <= maxPages) {
      for (let i = 0; i < pagesAmount; i++) {
        pages.push(i + 1);
      }
    } else {
      let pagesBefore = [];
      for (let i = 0; i < currentPage - 1; i++) {
        pagesBefore.push(i + 1);
      }
      let pagesAfter = [];
      for (let i = currentPage; i < pagesAmount; i++) {
        pagesAfter.push(i + 1);
      }
      const val = Math.floor((maxPages - 1) / 2);
      if (pagesBefore.length >= val && pagesAfter.length >= val) {
        for (let i = currentPage - val; i <= currentPage + val; i++) {
          pages.push(i);
        }
      } else if (pagesBefore.length < val) {
        for (let i = 0; i < maxPages; i++) {
          if (i >= pagesAmount) return pages;
          pages.push(i + 1);
        }
      } else if (pagesAfter.length < val) {
        for (let i = pagesAmount; pages.length < maxPages; i--) {
          if (i <= 0) {
            pages.sort(this.sortNumber);
            return pages;
          }
          pages.push(i);
        }
      }
    }
    pages.sort(this.sortNumber);
    return pages;
  };

  sortNumber = (a, b) => {
    return a - b;
  };

  pageClickCallBack = (page) => {
    if (typeof this.props.onPageChange === "function")
      this.props.onPageChange({ page: page });
  };

  prevPageClick = (page, pages) => {
    if (page <= 1) return;
    // this.renderGridData = true;
    if (this.serverFiltering.enabled !== true)
      this.setState({ page: page - 1 });
    this.pageClickCallBack(page - 1);
  };
  pageClick = (page) => {
    const currentPage =
      this.serverFiltering.enabled === true
        ? this.serverFiltering.page
        : this.state.page;
    if (page === currentPage) return;
    // this.renderGridData = true;
    if (this.serverFiltering.enabled !== true) this.setState({ page: page });
    this.pageClickCallBack(page);
  };
  nextPageClick = (page, pages) => {
    if (page >= pages) return;
    // this.renderGridData = true;
    if (this.serverFiltering.enabled !== true)
      this.setState({ page: page + 1 });
    this.pageClickCallBack(page + 1);
  };
  firstPageClick = () => {
    const currentPage =
      this.serverFiltering.enabled === true
        ? this.serverFiltering.page
        : this.state.page;
    if (currentPage === 1) return;
    // this.renderGridData = true;
    if (this.serverFiltering.enabled !== true) this.setState({ page: 1 });
    this.pageClickCallBack(1);
  };
  lastPageClick = (page) => {
    const currentPage =
      this.serverFiltering.enabled === true
        ? this.serverFiltering.page
        : this.state.page;
    if (page === currentPage) return;
    // this.renderGridData = true;
    if (this.serverFiltering.enabled !== true) this.setState({ page: page });
    this.pageClickCallBack(page);
  };

  areAllSelected = () => {
    for (let i = 0; i < this.state.data.length; i++) {
      const item = this.state.data[i];
      const col = this.state.selectableCol;

      const id = this.props.getRowKey
        ? this.props.getRowKey(item.data)
        : col.identifier
        ? item.data[col.identifier]
        : item._c__id;

      if (!this.state.selectedItemsObject[id]) return false;
    }
    return true;
  };

  isSelected = (item, col) => {
    if (!col) col = this.state.selectableCol;

    if (!col && !this.props.getRowKey) return false;

    const id = this.props.getRowKey
      ? this.props.getRowKey(item.data)
      : col.identifier
      ? item.data[col.identifier]
      : item._c__id;

    if (this.state.selectedItemsObject[id]) return true;

    return false;

    // for (let i = 0; i < this.state.selectedItems.length; i++) {
    //   // if (JSON.stringify(this.state.selectedItems[i]) === JSON.stringify(item))
    //   //   return true;
    //   if (this.state.selectedItems[i]._c__id === item._c__id) return true;
    // }
    // return false;
  };

  toggleItem = (item, col) => {
    const { selectedItems } = this.props;
    let newArr = selectedItems ? [...selectedItems] : [];
    const { selectedDict } = this.selectionState;

    const id = item;

    if (selectedDict[id]) {
      const index = newArr.findIndex((v) => v === id);

      newArr.splice(index, 1);
    } else {
      newArr.push(id);
    }

    // this.renderGridData = true;

    this.props.onItemSelect(newArr);
  };

  toggleAll = (col) => {
    const { selectableArr, selectedDict, areAllSelected } = this.selectionState;
    const { selectedItems, onItemSelect } = this.props;
    let newArr = selectedItems ? [...selectedItems] : [];
    if (areAllSelected) {
      for (const selectableKey of selectableArr) {
        const index = newArr.findIndex((v) => v === selectableKey);
        if (index === -1) continue;
        newArr.splice(index, 1);
      }
    } else {
      for (const selectableKey of selectableArr) {
        if (selectedDict[selectableKey]) continue;

        newArr.push(selectableKey);
      }
    }
    onItemSelect && onItemSelect(newArr);
  };

  getGroupedComponent = (data) => {
    let component;
    let flatData = [];

    this.getFlatGroupedData(flatData, data);

    flatData = flatData.map((item, i) => {
      return { ...item, FullIndex: i };
    });

    component = this.writeComponent(flatData);

    return component;
  };

  getFlatGroupedData = (flatData, data, index) => {
    //;
    for (let i = 0; i < data.length; i++) {
      index = index || 0;

      flatData.push({
        Title:
          data[i].Field !== undefined && !data[i].Column.noTitle
            ? data[i].Column.title +
              " - " +
              (isDate(data[i].Field)
                ? dateFormat(data[i].Field, "dd/MM/yyyy")
                : data[i].Field)
            : "",
        TitlesBefore: index,
        ParentId: GenerateGuid()
      });
      if (data[i].Data.length > 0) {
        this.getFlatGroupedData(flatData, data[i].Data, index + 1);
      } else if (data[i].Items.length > 0) {
        for (let j = 0; j < data[i].Items.length; j++) {
          flatData.push({ Item: data[i].Items[j] });
          let item = { ...data[i].Items[j] };
          delete item._c__id;
          if (this.props.subComponent !== undefined)
            flatData.push({ item, _c__sc: true });
        }
      }
    }
  };

  writeComponent = (data) => {
    return data.map((item, i) => {
      let comp;

      // const parentDisplay = item.TitlesBefore === 0 ? "table-row" : "none";

      this.props.subComponent !== undefined && item._c__sc === true
        ? (comp = (
            <tr style={{ display: "none" }} key={"tr-sc-" + item.FullIndex + i}>
              <td
                key={"td-sc-" + item.FullIndex + i}
                colSpan={
                  this.state.columns.length + this.state.groupBy.length + 1
                }
              >
                {this.props.subComponent(item.item.data)}
              </td>
            </tr>
          ))
        : item.Title !== undefined
        ? (comp = (
            <tr
              className="c-grid-collapsable" //c-grid-expanded
              key={"tr-" + item.FullIndex + item.ParentId + i}
              c-item-collapsed="true"
              style={{
                display: item.TitlesBefore === 0 ? "table-row" : "none"
              }}
              c-item-level={item.TitlesBefore}
            >
              {Array.from(Array(item.TitlesBefore), (e, i) => {
                return <td key={"td-w-" + item.ParentId + i} />;
              })}
              <td
                key={"td-" + item.FullIndex + item.ParentId + i}
                colSpan={
                  this.state.columns.length +
                  (this.state.groupBy.length - item.TitlesBefore) +
                  (this.props.subComponent !== undefined ? 1 : 0)
                }
              >
                <button
                  style={{ width: this.groupColumnWidth }}
                  onClick={this.collapseItemClick}
                >
                  <svg
                    fill="#1E73F0"
                    className="c-grid-expa"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" />
                  </svg>
                  <svg
                    fill="#1E73F0"
                    className="c-grid-coll"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 192 512"
                  >
                    <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                  </svg>
                </button>
                <span>{item.Title}</span>
              </td>
            </tr>
          ))
        : (comp = (
            <tr
              c-item-sc-collapsed="true"
              className={
                "c-collapse-item" +
                (this.props.subComponent !== undefined
                  ? " c-grid-subcomponent-collapsable"
                  : "")
              }
              style={{ display: "none" }}
              key={"tr-sc" + item.FullIndex + item.Item._c__id}
            >
              {this.state.groupBy.map((item, i) => (
                <td key={"td-w-" + i} />
              ))}
              {this.props.subComponent !== undefined && (
                <td
                  key={"td-sc-" + i}
                  className="c-grid-subcomponent-collapsable-td"
                >
                  <button
                    key={"b-sc-" + i}
                    style={{ width: this.groupColumnWidth }}
                    onClick={this.toggleSubComponent}
                  >
                    <svg
                      key={"b-sc-s-1-" + i}
                      fill="#1E73F0"
                      className="c-grid-expa"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" />
                    </svg>
                    <svg
                      key={"b-sc-s-2-" + i}
                      fill="#1E73F0"
                      className="c-grid-coll"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 192 512"
                    >
                      <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                    </svg>
                  </button>
                </td>
              )}
              {this.state.columns.map((col, j) =>
                col.selectable !== true ? (
                  col.Cell !== undefined ? (
                    <td key={"td-" + item.FullIndex + item.Item._c__id + i + j}>
                      {col.Cell({ Data: item.Item.data || "―" })}
                    </td>
                  ) : isDate(getObjectValueByField(item.Item.data, col)) ? (
                    <td key={"td-" + item.FullIndex + item.Item._c__id + i + j}>
                      {dateFormat(
                        getObjectValueByField(item.Item.data, col),
                        "dd/MM/yyyy"
                      )}
                    </td>
                  ) : (
                    <td key={"td-" + item.FullIndex + item.Item._c__id + i + j}>
                      {getObjectValueByField(item.Item.data || "―", col)}
                    </td>
                  )
                ) : (
                  <td key={"tr-" + item.FullIndex + item.Item._c__id + i + j}>
                    <Checkbox
                      key={
                        "checkbox-" + item.FullIndex + item.Item._c__id + i + j
                      }
                      checked={this.isSelected(item.Item, col)}
                      change={(e) => {
                        this.toggleItem(item.Item, col);
                      }}
                    />
                  </td>
                )
              )}
            </tr>
          ));

      return comp;
    });
  };

  collapseItemClick = (e) => {
    const tr = e.target.closest("tr");
    const isCollapsed = tr.getAttribute("c-item-collapsed") === "true";

    const itemLevel = tr.getAttribute("c-item-level");

    if (isCollapsed) {
      tr.setAttribute("c-item-collapsed", false);
      tr.classList.add("c-grid-expanded");
    } else {
      tr.setAttribute("c-item-collapsed", true);
      tr.classList.remove("c-grid-expanded");
    }

    let allItemsAreCollapsed = false;
    let currentItem = tr;
    // let gotToLastLevelChilds = false;

    let skipLevel = null;

    while (!allItemsAreCollapsed) {
      const prevItem = currentItem;
      currentItem = currentItem.nextElementSibling;

      // if (currentItem.classList.contains("c-grid-subcomponent-collapsable") === true) {
      //   currentItem.classList.remove("c-grid-sc-expanded");
      //   currentItem.setAttribute("c-item-sc-collapsed", "true");
      // }
      if (
        prevItem.classList.contains("c-grid-subcomponent-collapsable") === true
      ) {
        if (prevItem.getAttribute("c-item-sc-collapsed") === "true") {
          currentItem.style.display = "none";
          continue;
        }
      }

      if (currentItem === null) {
        allItemsAreCollapsed = true;
        break;
      }

      const prevLevel = prevItem.getAttribute("c-item-level");
      const currLevel = currentItem.getAttribute("c-item-level");

      if (skipLevel !== null) {
        if (skipLevel < currLevel || currLevel === null) continue;
        else if (currLevel <= skipLevel) {
          skipLevel = null;
        }
      }

      if (
        prevItem.getAttribute("c-item-collapsed") === "true" &&
        prevItem !== tr &&
        currLevel !== itemLevel
      ) {
        skipLevel = prevLevel;
        continue;
      }

      if (currLevel !== null && currLevel <= itemLevel) {
        allItemsAreCollapsed = true;
        break;
      }

      // if (currentItem.classList.contains("c-collapse-item") === true)
      //   gotToLastLevelChilds = true;
      // else if (gotToLastLevelChilds === true) {
      //   allItemsAreCollapsed = true;
      //   break;
      // }

      if (!isCollapsed) currentItem.style.display = "none";
      else currentItem.style.display = "table-row";
    }
  };

  filterData = (data, filter) => {
    if (
      filter === undefined ||
      typeof filter !== "object" ||
      !Array.isArray(filter.filters) ||
      data.length === 0 ||
      filter.filters.length === 0
    )
      return data;

    let returnArr = [...data];

    for (let i = 0; i < filter.filters.length; i++) {
      const columnName = filter.filters[i].field;
      const value = filter.filters[i].value || "";
      const operator = filter.filters[i].operator;

      for (let j = returnArr.length - 1; j >= 0; j--) {
        const colValue = returnArr[j].data[columnName] || "";

        if (operator === "eq") {
          if (isDate(colValue) && colValue.getTime() !== value.getTime())
            returnArr.splice(j, 1);
          else if (!isDate(colValue) && colValue.toString() !== value)
            returnArr.splice(j, 1);
        } else if (operator === "ne") {
          if (isDate(colValue) && colValue.getTime() === value.getTime())
            returnArr.splice(j, 1);
          else if (colValue.toString() === value) returnArr.splice(j, 1);
        } else if (operator === "lt") {
          if (isDate(colValue) && colValue.getTime() >= value.getTime())
            returnArr.splice(j, 1);
          else if (colValue >= value) returnArr.splice(j, 1);
        } else if (operator === "le") {
          if (colValue > value) returnArr.splice(j, 1);
        } else if (operator === "gt") {
          if (isDate(colValue) && colValue.getTime() <= value.getTime())
            returnArr.splice(j, 1);
          else if (colValue <= value) returnArr.splice(j, 1);
        } else if (operator === "ge") {
          if (colValue < value) returnArr.splice(j, 1);
        } else if (operator === "startswith") {
          if (!colValue.toString().startsWith(value)) returnArr.splice(j, 1);
        } else if (operator === "endswith") {
          if (!colValue.toString().endsWith(value)) returnArr.splice(j, 1);
        } else if (operator === "contains") {
          if (!colValue.toString().includes(value)) returnArr.splice(j, 1);
        } else if (operator === "doesnotcontain") {
          if (colValue.toString().includes(value)) returnArr.splice(j, 1);
        } else if (operator === "isempty") {
          if (colValue !== "" && colValue !== null) returnArr.splice(j, 1);
        } else if (operator === "isnotempty") {
          if (colValue === "" || colValue === null) returnArr.splice(j, 1);
        }
      }
    }

    return returnArr;
  };

  renderGrid = () => {
    let data = [];
    if (this.serverFiltering.enabled === true) {
      // this.dataToShow = this.setGridData(this.props.data);
      this.dataToShow = [...this.state.data];

      this.currentPage = this.serverFiltering.page;

      this.pageSize = this.state.pageSize;

      this.gridPages = this.serverFiltering.pages;

      if (this.state.groupBy.length > 0) {
        //console.log(this.dataToShow);
        const groupedData = this.groupedData(this.dataToShow);

        this.groupedComponent = this.getGroupedComponent(groupedData);
      } else if (this.props.subComponent !== undefined) {
        for (let i = 0; i < this.dataToShow.length; i++) {
          let item = { ...this.dataToShow[i] };
          delete item._c__id;
          this.dataToShow.splice(i + 1, 0, { item, _c__sc: true });
          i++;
        }
      }

      this.numericPages = this.getPages(this.currentPage, this.gridPages, 5);
    } else {
      data = this.filterData([...this.state.data], this.props.filter);

      data = this.dynamicSort(data, this.state.orderBy, this.state.orderType);

      // this.pageSize = data.length;
      this.currentPage = 1;

      this.pageSize = this.state.pageSize;

      this.gridPages = Math.ceil(data.length / this.pageSize);

      if (this.state.page !== undefined && this.state.page <= this.gridPages)
        this.currentPage = this.state.page;

      if (data.length > this.pageSize) {
        const next = this.pageSize * (this.currentPage - 1);
        this.dataToShow = data.slice(next, next + this.state.pageSize);
      } else this.dataToShow = data;

      if (this.state.groupBy.length > 0) {
        //console.log(this.dataToShow);
        const groupedData = this.groupedData(this.dataToShow);

        this.groupedComponent = this.getGroupedComponent(groupedData);
      } else if (this.props.subComponent !== undefined) {
        for (let i = 0; i < this.dataToShow.length; i++) {
          let item = { ...this.dataToShow[i] };
          delete item._c__id;
          this.dataToShow.splice(i + 1, 0, { item, _c__sc: true });
          i++;
        }
      }

      if (this.state.pages !== undefined) this.gridPages = this.state.pages;

      this.numericPages = this.getPages(this.currentPage, this.gridPages, 5);
    }
    // this.renderGridData = false;
  };

  toggleCollapseSubComponents = (collapse) => {
    const children = this.bodyScroller.current.querySelectorAll(
      ":scope.c-grid-body > table > tbody > tr"
    );

    for (let i = 0; i < children.length; i++) {
      if (children[i].getAttribute("c-item-sc-collapsed") !== null) {
        children[i].setAttribute("c-item-sc-collapsed", "true");
        children[i].classList.remove("c-grid-sc-expanded");

        if (collapse === true)
          children[i].nextElementSibling.style.display = "none";
        else children[i].nextElementSibling.style.display = "table-row";
      }
    }
  };

  toggleCollapseItems = (collapse) => {
    const children = this.bodyScroller.current.querySelectorAll(
      ":scope.c-grid-body > table > tbody > tr"
    );

    for (let i = 0; i < children.length; i++) {
      if (collapse === false) {
        children[i].style.display = "table-row";
      } else if (children[i].getAttribute("c-item-level") !== "0") {
        children[i].style.display = "none";
      } else {
        children[i].style.display = "table-row";
      }

      if (children[i].getAttribute("c-item-level") !== null) {
        children[i].setAttribute("c-item-collapsed", collapse);
        children[i].classList.remove("c-grid-expanded");
      }

      if (children[i].getAttribute("c-item-sc-collapsed") !== null) {
        children[i].setAttribute("c-item-sc-collapsed", "true");
        children[i].classList.remove("c-grid-sc-expanded");
      }
    }
    // if () {

    // }
  };

  toggleDragClone = (show) => {
    if (show) {
      this.dragclone.current.classList.add("clone-visible");
    } else {
      this.dragclone.current.classList.remove("clone-visible");
    }
  };
  setDragClonePosition = (x, y) => {
    this.dragclone.current.style.left = x + "px";
    this.dragclone.current.style.top = y + "px";
  };
  setDragCloneText = (text) => {
    this.dragclone.current.innerText = text;
  };

  mouseUp = (e) => {
    if (this.props.groupable !== true) return;

    document.removeEventListener("mousemove", this.dragElement);
    document.removeEventListener("mouseup", this.mouseUp);
    document.querySelector("body").classList.remove("c-grid-clonedrag");

    if (
      this.dropzone.current !== null &&
      (this.dropzone.current.contains(e.target) === true ||
        e.target === this.dropzone.current) &&
      this.draggedItem !== null
    ) {
      // let isNewItem = true;

      for (let i = 0; i < this.state.groupColumns.length; i++) {
        if (this.state.groupColumns[i].field === this.draggedItem.field) {
          this.draggedItem = null;
          this.dragStarted = false;
          this.toggleDragClone(false);

          // this.setState({ dragStarted: false, draggedItem: {} }, () => {
          setTimeout(() => {
            this.startedDragging = false;
          }, 0);
          // });
          return;
        }
      }
      // this.renderGridData = true;
      this.addColumnToColumnsWidth(true);

      this.dragStarted = false;
      this.toggleDragClone(false);

      this.setState(
        {
          groupColumns: [...this.state.groupColumns, this.draggedItem],
          groupBy: [...this.state.groupBy, this.draggedItem.field]
        },
        () => {
          this.draggedItem = {};
          // this.setState({draggedItem: {}});
          setTimeout(() => {
            this.startedDragging = false;
          }, 0);
          if (this.state.groupBy.length > 0) this.toggleCollapseItems(true);
          else if (this.props.subComponent !== undefined)
            this.toggleCollapseSubComponents(true);
        }
      );
    } else {
      this.dragStarted = false;
      this.toggleDragClone(false);
      this.draggedItem = {};
      // this.setState({ dragStarted: false, draggedItem: {} }, () => {
      setTimeout(() => {
        this.startedDragging = false;
      }, 0);
      // });
    }
  };

  dragElement = (ev) => {
    if (this.props.groupable !== true) return;

    const pointX = ev.clientX || ev.touches[0].clientX;
    const pointY = ev.clientY || ev.touches[0].clientY;

    if (this.firstPoint === null) {
      this.firstPoint = {
        x: pointX,
        y: pointY
      };
    } else if (this.dragStarted === false) {
      if (
        this.firstPoint.x + this.startDragDistance < pointX ||
        this.firstPoint.x - this.startDragDistance > pointX ||
        this.firstPoint.y + this.startDragDistance < pointY ||
        this.firstPoint.y - this.startDragDistance > pointY
      ) {
        document.querySelector("body").classList.add("c-grid-clonedrag");

        this.startedDragging = true;
        this.dragStarted = true;
        this.toggleDragClone(true);
        this.setDragCloneText(this.draggedItem.title);
        this.dragColumnClonePosition = {
          x: pointX - this.dragElementWidth / 2,
          y: pointY - this.dragElementHeight / 2
        };
        this.setDragClonePosition(
          this.dragColumnClonePosition.x,
          this.dragColumnClonePosition.y
        );

        // this.setState({
        //   dragStarted: true,
        //   dragColumnClonePosition: {
        //     x: pointX - this.dragElementWidth / 2,
        //     y: pointY - this.dragElementHeight / 2
        //   }
        // });
      }
    } else if (this.dragStarted === true) {
      this.dragColumnClonePosition = {
        x: pointX - this.dragElementWidth / 2,
        y: pointY - this.dragElementHeight / 2
      };
      this.setDragClonePosition(
        this.dragColumnClonePosition.x,
        this.dragColumnClonePosition.y
      );

      // this.setState({
      //   dragColumnClonePosition: {
      //     x: pointX - this.dragElementWidth / 2,
      //     y: pointY - this.dragElementHeight / 2
      //   }
      // });
    }
  };

  removeGroupColumn = (index) => {
    let cols = [...this.state.groupBy];
    let elCols = [...this.state.groupColumns];

    cols.splice(index, 1);
    elCols.splice(index, 1);

    // this.renderGridData = true;
    this.addColumnToColumnsWidth(false);
    this.setState({ groupBy: cols, groupColumns: elCols });
  };

  toggleSubComponent = (e) => {
    const tr = e.target.closest("tr");
    const isCollapsed = tr.getAttribute("c-item-sc-collapsed") === "true";

    if (isCollapsed === true) {
      tr.nextElementSibling.style.display = "table-row";
      tr.classList.add("c-grid-sc-expanded");
      tr.setAttribute("c-item-sc-collapsed", false);
    } else {
      tr.nextElementSibling.style.display = "none";
      tr.classList.remove("c-grid-sc-expanded");
      tr.setAttribute("c-item-sc-collapsed", true);
    }
  };

  // componentWillMount = () => {
  //   // this.renderGrid();
  // };

  shouldComponentUpdate = (props, state) => {
    if (this.props.columnsSize !== props.columnsSize) return true;

    if (props.shouldUpdate && props.shouldUpdate()) return true;

    this.serverFiltering =
      typeof props.serverFiltering === "object" ? props.serverFiltering : {};

    if (this.serverFiltering.enabled === true)
      this.hasDifferentPropsData =
        this.lastDataProps !== props.data ||
        this.pageSize !== props.pageSize ||
        this.currentPage !== this.hasDifferentPropsData.page ||
        this.gridPages !== this.hasDifferentPropsData.pages;
    else this.hasDifferentPropsData = true;

    this.lastDataProps = props.data;

    if (Array.isArray(props.groupBy)) {
      let isDiff = false;
      if (this.lastGroupByProps.length !== props.groupBy.length) {
        isDiff = true;
      } else {
        for (let i = 0; i < this.lastGroupByProps.length; i++) {
          if (this.lastGroupByProps[i] !== props.groupBy[i]) {
            isDiff = true;
            break;
          }
        }
      }
      if (isDiff === true) {
        const { groupColumns, propsGroupBy } = this.validatePropsGroup(
          state.columns,
          props.groupBy
        );

        this.lastGroupByProps = [...props.groupBy];

        this.setState({
          groupBy: [...propsGroupBy],
          groupColumns: [...groupColumns]
        });
        return false;
      }
    }

    // if (this.serverFiltering.enabled === true && Array.isArray(props.data)) {
    //   // let isDiff = false;
    //   // if (this.lastDataProps.length != props.data.length) {
    //   //   isDiff = true;
    //   // } else {
    //   //   for (let i = 0; i < this.lastDataProps.length; i++) {
    //   //     if (this.lastDataProps[i] != props.data[i]) {
    //   //       isDiff = true;
    //   //       break;
    //   //     }
    //   //   }
    //   // }
    //   // if (isDiff === true) {

    //     this.lastDataProps = [...props.data];

    //     const data = this.setGridData(this.lastDataProps)

    //     this.setState({
    //       data: [...data]
    //     });
    //     return false;
    //   // }
    // }
    this.columns = props.columns;
    return true;
  };

  static getDerivedStateFromProps(props, state) {
    const returnObj = {};

    // if (!state.ColumnItemsWidth || props.columns !== state.columns) {
    //   returnObj.ColumnItemsWidth = 0;
    //   for (let i = 0; i < props.columns.length; i++) {
    //     returnObj.ColumnItemsWidth += parseInt(
    //       props.columnsSize[props.columns[i].field],
    //       10
    //     );
    //   }
    // }

    if (props.columns !== state.columns) {
      returnObj.columns = props.columns;

      for (let i = 0; i < returnObj.columns.length; i++) {
        if (returnObj.columns[i].selectable === true) {
          returnObj.selectableCol = returnObj.columns[i];
          break;
        }
      }
    }

    if (props.columnsSize !== state.localStorageColumnSize) {
      returnObj.localStorageColumnSize = props.columnsSize;
    }

    if (props.data !== state.defaultData) {
      returnObj.defaultData = props.data;
    }
    if (
      returnObj.defaultData ||
      returnObj.columns ||
      returnObj.localStorageColumnSize ||
      returnObj.ColumnItemsWidth
    ) {
      if (returnObj.defaultData && returnObj.defaultData.length > 0) {
        let data = [],
          itms = [...returnObj.defaultData];
        if (typeof returnObj.defaultData[0] !== "object" && props.getValue) {
          itms = returnObj.defaultData.map((item, i) => {
            const itm = { ...props.getValue(item) };
            return itm;
          });
        }

        const _c__id = GenerateGuid();
        data = itms.map((item, i) => {
          return {
            _c__id: _c__id + "-" + i,
            data: item
          };
        });
        returnObj.data = [...data];
        if (props.sort) {
          //console.log(filteredOrderByArr)
          returnObj.orderByFilter = props.sort;
        }
      }

      return returnObj;
    }
    return null;
  }

  getItemKey = (item) => {
    return this.props.getRowKey ? this.props.getRowKey(item) || item : item;
  };

  getSelectionState = () => {
    const { data, selectedItems, selectionState } = this;
    const { canSelectRow } = this.props;

    if (
      data !== this.props.data ||
      selectedItems !== this.props.selectedItems
    ) {
      this.data = this.props.data || [];
      this.selectedItems = this.props.selectedItems;

      let selectableCount = 0;
      let selectedCount = 0;
      let selectableDict = {};
      let selectableArr = [];
      let selectedDict = {};
      for (const item of this.data) {
        const key = this.getItemKey(item);

        const canSelectItem = canSelectRow ? canSelectRow(item) : false;
        if (canSelectItem) {
          selectableCount++;
          selectableArr.push(key);
          selectableDict[key] = true;
        }
      }

      if (this.selectedItems) {
        for (const item of this.selectedItems) {
          if (!selectableDict[item]) continue;
          selectedCount++;
          selectedDict[item] = true;
        }
      }

      const areAllSelected =
        canSelectRow && selectableCount > 0
          ? selectedCount === selectableCount
          : false;

      this.selectionState = {
        areAllSelected,
        selectableDict,
        selectedDict,
        selectableArr
      };
      return this.selectionState;
    }
    return selectionState;
  };

  CheckIfNeedsOrdering(field, type) {
    let result = false;
    //   console.log(  this.state.orderByFilter)
    if (!Array.isArray(this.state.orderByFilter)) return false;
    this.state.orderByFilter.forEach((e) => {
      if (e.column === field && e.type === type) {
        result = true;
      }
    });
    return result;
  }

  FilterOrderNumber(field, type) {
    let result = -1;
    //   console.log(  this.state.orderByFilter)
    this.state.orderByFilter.forEach((e, index) => {
      if (e.column === field && e.type === type) {
        result = index;
      }
    });
    return <span style={{ color: "rgb(30, 115, 240)" }}>{result + 1}</span>;
  }

  render() {
    // if (this.renderGridData === true) this.renderGrid();
    // if (this.state.ColumnsLength !== this.state.columns.length) {
    // 	this.columnsWidths = [];
    // 	this.composeColumnsWidths();
    //    this.setColumnItemsWidth();
    // }
    if (this.hasDifferentPropsData) this.renderGrid();
    // console.log(this.state.orderByFilter)
    const selectionState = this.getSelectionState();
    // const { onParamsChange } = useServerAwareState();

    // let groupedComponent;

    // if (this.state.groupBy.length > 0) {
    //   const groupedData = this.groupedData();

    //   console.log(groupedData);
    //   groupedComponent = this.getGroupedComponent(groupedData);
    // }

    // let data = this.dynamicSort(
    //   [...this.state.data],
    //   this.state.orderBy,
    //   this.state.orderType
    // );

    // let pageSize = data.length;
    // let page = 1;

    // if (this.state.pageSize !== undefined) pageSize = this.state.pageSize;

    // let pages = Math.ceil(data.length / pageSize);

    // if (this.state.page !== undefined && this.state.page <= pages)
    //   page = this.state.page;

    // if (data.length > pageSize) {
    //   const next = pageSize * (page - 1);
    //   data = data.slice(next, next + this.state.pageSize);
    // }

    // if (this.state.pages !== undefined) pages = this.state.pages;

    // const numericPages = this.getPages(page, pages, 5);

    const groupedComponent = this.groupedComponent;

    // const pageSizes = [50, 100, 250, 500];

    const page = this.currentPage;

    const pages = this.gridPages;

    // const data = this.dataToShow;

    const numericPages = this.numericPages;

    // data = this.state.data.sort(this.dynamicSort(this.state.orderBy));

    if (this.firstTimeRender && this.state.groupBy.length > 0)
      setTimeout(() => {
        this.toggleCollapseItems(true);
      });

    if (this.firstTimeRender) this.firstTimeRender = false;

    const { rowComponent: RowComponent, rowProps, trClass } = this.props;

    // var asdasda = this.state.orderBy;
    // var asdasda2 = this.state.orderType;

    const filteredColumns = this.state.columns.filter((e) => {
      if (e) return e;
      else return null;
    });

    return (
      <div
        style={{ height: this.props.height }}
        className={
          "CGrid justify-content-between position-relative" +
          (this.props.headerScroll === true ? " header-scroll" : "") +
          (this.state.resizing === true ? " c-resizing" : "") +
          (this.props.className ? ` ${this.props.className}` : "")
        }
      >
        {this.props.groupable === true && (
          <div ref={this.dropzone} className="c-grid-dropzone">
            {this.state.groupColumns.length > 0 ? (
              this.state.groupColumns.map((item, i) => (
                <div key={i} className="c-grid-dropzone-item">
                  {item.title}
                </div>
              ))
            ) : (
              <div className="c-grid-dropzone-nodata">
                Drag a column header and drop it here to group by that column
              </div>
            )}
          </div>
        )}

        <div className="flex-1 of-y-auto">
          <BarLoader
            className="grid-loader w-100"
            isLoading={this.props.isLoading}
          />

          <div className="h-100 of-y-auto c-grid-content rounded">
            <div>
              <div className="c-grid-header">
                <div
                  className="c-grid-header-wrap"
                  // onScroll={this.onHeaderScroll}
                  ref={this.headScroller}
                >
                  <div
                    className="c-grid-table-wrap"
                    style={{ width: this.state.ColumnItemsWidth }}
                  >
                    <table
                      className={
                        (this.props.sortable === true ? "c-sortable" : "") +
                        (this.props.groupable === true ? " c-groupable" : "")
                      }
                    >
                      <colgroup>
                        {this.props.subComponent !== undefined && (
                          <col
                            key={"col-sc"}
                            style={{ width: this.groupColumnWidth }}
                          />
                        )}
                        {this.state.groupBy.map((item, i) => (
                          <col
                            key={"col" + i}
                            style={{ width: this.groupColumnWidth }}
                          />
                        ))}
                        {filteredColumns.map((item, i) => (
                          <col
                            key={"col-" + i}
                            style={{
                              width: this.props.columnsSize
                                ? this.props.columnsSize[item.field] ||
                                  item.width
                                : item.width
                              //   parseInt(
                              //     this.props.columnsSize[item.field],
                              //     10
                              //   ) || item.width
                            }}
                          />
                        ))}
                        <col
                          style={{ pointerEvents: "none" }}
                          key="colheaderend"
                        />
                      </colgroup>
                      <thead>
                        <tr>
                          {this.props.subComponent !== undefined && (
                            <th style={{ cursor: "default" }} key={"th-sc"} />
                          )}
                          {this.state.groupBy.map((item, i) => (
                            <th
                              style={{ cursor: "default" }}
                              key={"th-w-" + i}
                            />
                          ))}
                          {filteredColumns.map((col, i) =>
                            col.selectable !== true ? (
                              <th
                                className={classnames(
                                  {
                                    "c-sorting-asc ": this.CheckIfNeedsOrdering(
                                      col.field,
                                      "asc"
                                    )
                                  },
                                  {
                                    "c-sorting-desc ":
                                      this.CheckIfNeedsOrdering(
                                        col.field,
                                        "desc"
                                      )
                                  },
                                  { unsortable: !this.props.columnResize },
                                  "pl-2",
                                  {
                                    "static-background":
                                      col.field &&
                                      col.field.toUpperCase() === "BUTTONS"
                                  }
                                )}
                                onClick={(e) => this.columnMouseClick(e, col)}
                                onMouseDown={(e) =>
                                  this.columnMouseDown(e, col)
                                }
                                onMouseMove={(e) => {
                                  if (!this.state.resizing)
                                    this.columnMouseOver(e, i);
                                }}
                                onMouseOut={(e) => this.columnMouseOut(e, i)}
                                key={"th" + i}
                                ref={this.columnResizer}
                              >
                                <div
                                  key={"th-span-" + i}
                                  className="mr-4 text-truncate"
                                >
                                  {!col.noTitle &&
                                  typeof col.title === "function" ? (
                                    <col.title />
                                  ) : col.noTitle === true ? (
                                    ""
                                  ) : (
                                    col.title
                                  )}
                                </div>

                                {col.sortable !== false &&
                                  !this.CheckIfNeedsOrdering(
                                    col.field,
                                    "asc"
                                  ) &&
                                  !this.CheckIfNeedsOrdering(
                                    col.field,
                                    "desc"
                                  ) && (
                                    <div className="sortable-icon mr-3">
                                      <GridSort />
                                    </div>
                                  )}
                                {this.CheckIfNeedsOrdering(col.field, "asc") ? (
                                  <div className="sortable-icon mr-3">
                                    {this.FilterOrderNumber(col.field, "asc")}
                                    <GridSort asc="true" />
                                  </div>
                                ) : (
                                  this.CheckIfNeedsOrdering(
                                    col.field,
                                    "desc"
                                  ) && (
                                    <span className="sortable-icon mr-3">
                                      {this.FilterOrderNumber(
                                        col.field,
                                        "desc"
                                      )}
                                      <GridSort desc="true" />
                                    </span>
                                  )
                                )}
                              </th>
                            ) : (
                              <th
                                key={"tr-" + i}
                                className="pl-3 static-background"
                                style={{
                                  cursor: "default",
                                  borderRight: "none"
                                }}
                              >
                                <Checkbox
                                  key={"checkbox-" + i}
                                  checked={selectionState.areAllSelected}
                                  change={(e) => {
                                    this.toggleAll(col);
                                  }}
                                />
                              </th>
                            )
                          )}
                          <th
                            style={{
                              pointerEvents: "none",
                              borderRight: "none",
                              paddingLeft: "1rem"
                            }}
                            key="columnsheaderend"
                          ></th>
                        </tr>
                      </thead>
                    </table>
                    {this.props.columnResize === true && (
                      <div
                        onMouseDown={this.resizerMouseDown}
                        onMouseOver={(e) => this.ResizerMouseOver(e)}
                        onMouseOut={(e) => this.columnMouseOut(e)}
                        ref={this.resizer}
                        className="c-resize"
                      />
                    )}
                  </div>
                </div>
              </div>
              {!this.props.showTotals && (
                <div
                  // onScroll={this.onBodyScroll}
                  className="c-grid-body"
                  ref={this.bodyScroller}
                >
                  <GridBody
                    groupColumnWidth={this.groupColumnWidth}
                    RowComponent={RowComponent}
                    toggleSubComponent={this.toggleSubComponent}
                    toggleItem={this.toggleItem}
                    trClass={trClass}
                    rowProps={rowProps}
                    selectionState={selectionState}
                    data={this.props.data}
                    groupedComponent={groupedComponent}
                    columns={filteredColumns}
                    groupBy={this.state.groupBy}
                    subComponent={this.props.subComponent}
                    columnsSize={this.props.columnsSize}
                    getRowKey={this.props.getRowKey}
                    ColumnItemsWidth={this.state.ColumnItemsWidth}
                    showTotals={this.props.showTotals}
                  />
                </div>
              )}

              {this.props.showTotals && (
                <div
                  style={{ overflowX: "auto" }}
                  className="c-grid-body"
                  ref={this.bodyScroller}
                  // onScroll={this.onTotalSroll}
                >
                  <GridBody
                    groupColumnWidth={this.groupColumnWidth}
                    RowComponent={RowComponent}
                    toggleSubComponent={this.toggleSubComponent}
                    toggleItem={this.toggleItem}
                    trClass={trClass}
                    rowProps={rowProps}
                    selectionState={selectionState}
                    data={this.props.data}
                    groupedComponent={groupedComponent}
                    columns={filteredColumns}
                    groupBy={this.state.groupBy}
                    subComponent={this.props.subComponent}
                    columnsSize={this.props.columnsSize}
                    getRowKey={this.props.getRowKey}
                    ColumnItemsWidth={this.state.ColumnItemsWidth}
                    showTotals={this.props.showTotals}
                  />
                </div>
              )}

              {this.props.showTotals && (
                <div
                  // onScroll={this.onBodyScroll}
                  ref={this.afterBodyScroller}
                  style={{
                    overflowY: "hidden",
                    background: "#EBF1F7",
                    maxHeight: 50
                  }}
                  className="c-grid-body-footer"
                >
                  <GridAfterBody
                    groupColumnWidth={this.groupColumnWidth}
                    RowComponent={RowComponent}
                    toggleSubComponent={this.toggleSubComponent}
                    toggleItem={this.toggleItem}
                    trClass={trClass}
                    rowProps={rowProps}
                    selectionState={selectionState}
                    data={this.props.data}
                    groupedComponent={groupedComponent}
                    columns={filteredColumns}
                    groupBy={this.state.groupBy}
                    subComponent={this.props.subComponent}
                    columnsSize={this.props.columnsSize}
                    getRowKey={this.props.getRowKey}
                    ColumnItemsWidth={this.state.ColumnItemsWidth}
                    showTotals={this.props.showTotals}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {!this.props.noFooter && (
          <div className="c-grid-footer d-flex justify-content-between align-items-center">
            <div />
            <div className="d-flex align-items-center">
              <div
                className="c-grid-page c-grid-backward"
                onClick={this.firstPageClick}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    fill="currentColor"
                    d="M64 468V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v176.4l195.5-181C352.1 22.3 384 36.6 384 64v384c0 27.4-31.9 41.7-52.5 24.6L136 292.7V468c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12z"
                  />
                </svg>
              </div>
              <div
                className="c-grid-page"
                onClick={(e) => {
                  this.prevPageClick(page, pages);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                  <path
                    fill="currentColor"
                    d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"
                  />
                </svg>
              </div>
              {numericPages.map((p, i) => (
                <div
                  onClick={() => this.pageClick(p)}
                  key={p}
                  className={
                    "c-grid-page" + (page === p ? " c-current-page" : "")
                  }
                >
                  {p}
                </div>
              ))}
              <div
                className="c-grid-page"
                onClick={(e) => {
                  this.nextPageClick(page, pages);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                  <path
                    fill="currentColor"
                    d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
                  />
                </svg>
              </div>
              <div
                className="c-grid-page c-grid-forward"
                onClick={(e) => {
                  this.lastPageClick(pages);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    fill="currentColor"
                    d="M384 44v424c0 6.6-5.4 12-12 12h-48c-6.6 0-12-5.4-12-12V291.6l-195.5 181C95.9 489.7 64 475.4 64 448V64c0-27.4 31.9-41.7 52.5-24.6L312 219.3V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12z"
                  />
                </svg>
              </div>
              {/* <div className="c-grid-pagesize text-primary">
                <PageSizeSelect className="c-grid-page-selector bg-white" />
                <FormattedMessage id={"PER_PAGE"} />
              </div> */}
            </div>

            <TotalGridItemsWidget
              totalItems={this.props.totalItems}
              currentPage={this.props.page}
              pageSize={this.props.pageSize}
            />
          </div>
        )}
        {/* {this.props.noFooter && (
          <div className="mb-2 d-flex justify-content-between align-items-center"></div>
        )} */}
        {/* <div className={"c-grid-dragclone"} ref={this.dragclone} /> */}
      </div>

      //   <div className="CGrid">
      //     <div className="c-grid-header">
      //       <div className="c-grid-header-item">teste</div>
      //       <div className="c-grid-header-item">teste</div>
      //       <div className="c-grid-header-item">teste</div>
      //     </div>
      //     <div className="c-grid-body">
      //       <div className="c-grid-item">teste</div>
      //       <div className="c-grid-item">teste</div>
      //       <div className="c-grid-item">teste</div>
      //       <div className="c-grid-item">teste</div>
      //       <div className="c-grid-item">teste</div>
      //     </div>
      //     <div className="c-grid-footer">
      //       <div className="c-grid-footer-item">teste</div>
      //       <div className="c-grid-footer-item">teste</div>
      //       <div className="c-grid-footer-item">teste</div>
      //       <div className="c-grid-footer-item">teste</div>
      //     </div>
      //   </div>
    );
  }
}

export const getObjectValueByField = (object, col) => {
  const { field } = col;
  if (!object) return object;
  let ret = null;

  if (field && field.indexOf(".") === -1) {
    ret = object[field];
  } else {
    const fields = field.split(".");
    ret = object;
    for (let i = 0; i < fields.length; i++) {
      if (ret === undefined) {
        ret = "";
        break;
      }
      ret = ret[fields[i]];
    }
  }

  if (col.formatValue) ret = col.formatValue(ret);

  if (typeof ret === "object") {
    // if (isDate(ret)) ret = dateFormat(ret);
    // else ret = JSON.stringify(ret);
    if (!isDate(ret)) ret = JSON.stringify(ret);
  } else if (typeof ret === "number") ret = String(ret);
  //   console.log(ret);
  return ret;
};

export const GridDateComponent = ({
  date,
  messages,
  hideHours,
  style,
  alternateFormat,
  removeSlice,
  className,
  ...rest
}) => {
  const intl = useIntl();

  const todayDate = new Date();
  const todayDay = todayDate.getDate();
  const todayMonth = todayDate.getMonth() + 1;
  const todayYear = todayDate.getFullYear();
  const targetDay = date.getDate();
  const targetMonth = date.getMonth() + 1;
  const targetYear = date.getFullYear();
  const isToday =
    targetDay === todayDay &&
    targetMonth === todayMonth &&
    targetYear === todayYear;
  const isYesterday =
    targetDay === todayDay - 1 &&
    targetMonth === todayMonth &&
    targetYear === todayYear;

  let today = intl.formatMessage({ id: "TODAY" }),
    yesterday = intl.formatMessage({ id: "YESTERDAY" });

  if (messages) {
    if (messages.today) today = messages.today;
    if (messages.yesterday) yesterday = messages.yesterday;
  }

  let stringDate = isToday ? (
    today
  ) : isYesterday ? (
    yesterday
  ) : (
    <Moment date={date} format="MMM DD, YYYY" />
  );

  const hourDivider = removeSlice ? ", " : " - ";

  const stringDateHours =
    hourDivider +
    DoubleDigitFormat(date.getHours()) +
    ":" +
    DoubleDigitFormat(date.getMinutes());

  return (
    <div
      style={{ textTransform: "capitalize", ...style }}
      className={classnames("text-truncate", className)}
      {...rest}
    >
      {stringDate}
      {!hideHours && stringDateHours}
    </div>
  );
};

export const TotalGridItemsWidget = ({ totalItems, pageSize, currentPage }) => {
  const intl = useIntl();
  const value = useGridRequest();
  if (!totalItems || !pageSize || !currentPage) return <div />;

  const firstEntry = pageSize * (currentPage - 1) + 1;
  let secondEntry = pageSize * currentPage;
  if (secondEntry > totalItems) secondEntry = totalItems;

  const text = `${firstEntry} - ${secondEntry} ${intl.formatMessage({
    id: "OF"
  })} ${totalItems} ${intl.formatMessage({ id: "ITEMS" })}`;
  return (
    <div style={{ textAlign: "left" }} className="fs-14">
      <span className="text-gray-darker mr-2">{text}</span>
      {value && (
        <Button vType="link-primary" className="h-auto" onClick={value.refetch}>
          <FontAwesomeIcon size="sm" icon={faSync} />
        </Button>
      )}
    </div>
  );
};

export const GridRowBase = React.memo(function GridRowBase({
  RowComponent,
  item,
  toggleItem,
  ...rest
}) {
  const handleToggle = useCallback(
    (col) => {
      toggleItem(item, col);
    },
    [item, toggleItem]
  );
  //   const arr = id.split("-");
  return (
    <RowComponent
      //   index={parseInt(arr[arr.length - 1])}
      {...rest}
      toggleItem={handleToggle}
    />
  );
});

export const GridRow = React.memo(function GridRow({
  item: oldItem,
  trClass,
  index: i,
  value,
  columns,
  groupBy,
  subComponent,
  groupColumnWidth,
  toggleSubComponent,
  isSelected,
  toggleItem,
  messages,
  canSelect,
  className
}) {
  const item = {
    ...oldItem,
    data: value
  };
  //   console.log(value);
  const ResolvedtrClass =
    //   console.log(ResolvedtrClass);
    typeof trClass === "function" ? trClass(value) : "";
  return subComponent !== undefined && item._c__sc === true ? (
    <tr
      className={classnames(ResolvedtrClass, className)}
      style={{ display: "none" }}
      // key={"tr-sc-" + i}
    >
      <td
        key={"td-sc-" + i}
        className="pl-2"
        colSpan={columns.length + groupBy.length + 1}
      >
        {subComponent(value)}
      </td>
    </tr>
  ) : (
    <tr
      c-item-sc-collapsed="true"
      className={classnames(
        (subComponent !== undefined ? "c-grid-subcomponent-collapsable" : "") +
          (i % 2 !== 1 ? " c-isOdd" : "") +
          (" " + ResolvedtrClass),
        { "c-grid-selected": isSelected },
        className
      )}
      // key={"tr-" + i}
    >
      {subComponent !== undefined && (
        <td
          key={"td-sc-" + i}
          className="c-grid-subcomponent-collapsable-td pl-2"
        >
          <button
            key={"b-sc-" + i}
            style={{ width: groupColumnWidth }}
            onClick={toggleSubComponent}
          >
            <svg
              key={"b-sc-s-1-" + i}
              fill="#1E73F0"
              className="c-grid-expa"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" />
            </svg>
            <svg
              key={"b-sc-s-2-" + i}
              fill="#1E73F0"
              className="c-grid-coll"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 192 512"
            >
              <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
            </svg>
          </button>
        </td>
      )}

      {columns.map((col, j) =>
        col.selectable !== true ? (
          col.Cell !== undefined ? (
            <td
              key={j}
              className={classnames(col.className ? col.className : "", "pl-2")}
            >
              <col.Cell Data={item.data || "―"} />
              {/* {col.Cell({ Data: item.data })} */}
            </td>
          ) : isDate(getObjectValueByField(item.data, col)) ? (
            <td
              key={j}
              className={classnames(col.className ? col.className : "", "pl-2")}
            >
              <GridDateComponent
                messages={messages}
                date={getObjectValueByField(item.data, col)}
              />
              {/* {dateFormat(
                getObjectValueByField(item.data, col.field),
                "dd/MM/yyyy"
              )} */}
            </td>
          ) : (
            <td
              key={j}
              className={classnames(col.className ? col.className : "", "pl-2")}
            >
              {getObjectValueByField(item.data || "―", col)}
            </td>
          )
        ) : (
          <td
            key={j}
            className={classnames(col.className ? col.className : "", "pl-3")}
          >
            {canSelect && (
              <Checkbox
                key={"checkbox-" + i + j}
                checked={isSelected || false}
                change={(e) => {
                  toggleItem(col);
                }}
              />
            )}
          </td>
        )
      )}
      <td style={{ paddingLeft: "1rem" }} key="columnbodyend"></td>
    </tr>
  );
});

export default CGrid;

const GridRowTotals = React.memo(
  ({ RowComponen, trClass, columns, style, groupColumnWidth, ...rowProps }) => {
    return (
      <tr className={trClass} style={style}>
        {columns.map((col, j) => (
          <td
            style={{ borderBottom: `hidden` }}
            key={j}
            className={classnames(col.className ? col.className : "", "pl-2")}
          >
            {col.TotalCell && <col.TotalCell />}

            {/* {col.Cell({ Data: item.data })} */}
          </td>
        ))}
        <td style={{ paddingLeft: "1rem" }} key="columnbodyend"></td>
      </tr>
    );
  }
);

const GridBody = React.memo(
  ({
    groupColumnWidth,
    ColumnItemsWidth,
    getRowKey,
    subComponent,
    groupBy,
    columns,
    groupedComponent,
    data,
    selectionState,
    RowComponent,
    trClass,
    toggleSubComponent,
    toggleItem,
    columnsSize,
    showTotals,
    rowProps
  }) => {
    return (
      <>
        <table style={{ width: ColumnItemsWidth }}>
          <colgroup>
            {subComponent !== undefined && (
              <col key={"col-sc"} style={{ width: groupColumnWidth }} />
            )}
            {groupBy.map((item, i) => (
              <col key={"col" + i} style={{ width: groupColumnWidth }} />
            ))}
            {columns.map((item, i) => (
              <col
                key={"col" + i}
                style={{
                  width: columnsSize
                    ? columnsSize[item.field] || item.width
                    : item.width
                }}
              />
            ))}
            <col key={"colbodyend"} />
          </colgroup>
          <tbody>
            {groupBy.length > 0
              ? groupedComponent
              : data.map((item, i) => {
                  const key = (getRowKey ? getRowKey(item) || item : i) || i;
                  return (
                    <GridRowBase
                      canSelect={selectionState.selectableDict[key]}
                      key={key}
                      RowComponent={RowComponent || GridRow}
                      index={i}
                      trClass={trClass}
                      value={item}
                      item={item}
                      columns={columns}
                      groupBy={groupBy}
                      subComponent={subComponent}
                      groupColumnWidth={groupColumnWidth}
                      toggleSubComponent={toggleSubComponent}
                      isSelected={selectionState.selectedDict[key]}
                      toggleItem={toggleItem}
                      {...rowProps}
                    />
                  );
                  // const rowProps =
                  // const resolvedProps = {
                  //   key: this.props.getRowKey
                  //     ? this.props.getRowKey(item.data) || i
                  //     : i,
                  //   trClass: trClass,
                  //   value: item.data,
                  //   index: i,
                  //   item: item,
                  //   columns: this.state.columns,
                  //   groupBy: this.state.groupBy,
                  //   subComponent: this.props.subComponent,
                  //   groupColumnWidth: this.groupColumnWidth,
                  //   toggleSubComponent: this.toggleSubComponent,
                  //   isSelected: this.isSelected(item),
                  //   toggleItem: col => {
                  //     this.toggleItem(item, col);
                  //   },
                  //   ...rowProps
                  // };

                  // return RowComponent ? (
                  //   <RowComponent {...resolvedProps} />
                  // ) : (
                  //   <GridRow {...resolvedProps} />
                  // );
                })}
          </tbody>
        </table>

        {/* {showTotals && (
          <table style={{ width: ColumnItemsWidth }}>
            <colgroup>
              {subComponent !== undefined && (
                <col key={"col-sc"} style={{ width: groupColumnWidth }} />
              )}
              {groupBy.map((item, i) => (
                <col key={"col" + i} style={{ width: groupColumnWidth }} />
              ))}
              {columns.map((item, i) => (
                <col
                  key={"col" + i}
                  style={{
                    width: columnsSize
                      ? columnsSize[item.field] || item.width
                      : item.width
                  }}
                />
              ))}
              <col key={"colbodyend"} />
            </colgroup>
            <tfoot>
              <GridRowTotals
                RowComponent={RowComponent || GridRow}
                trClass={trClass}
                columns={columns}
                groupColumnWidth={groupColumnWidth}
                {...rowProps}
              />
            </tfoot>
          </table>
        )} */}
      </>
    );
  }
);

const GridAfterBody = React.memo(
  ({
    groupColumnWidth,
    ColumnItemsWidth,
    getRowKey,
    subComponent,
    groupBy,
    columns,
    groupedComponent,
    data,
    selectionState,
    RowComponent,
    trClass,
    toggleSubComponent,
    toggleItem,
    columnsSize,
    showTotals,
    rowProps
  }) => {
    return (
      <>
        {showTotals && (
          <table style={{ width: ColumnItemsWidth }}>
            <colgroup>
              {subComponent !== undefined && (
                <col key={"col-sc"} style={{ width: groupColumnWidth }} />
              )}
              {groupBy.map((item, i) => (
                <col key={"col" + i} style={{ width: groupColumnWidth }} />
              ))}
              {columns.map((item, i) => (
                <col
                  key={"col" + i}
                  style={{
                    width: columnsSize
                      ? columnsSize[item.field] || item.width
                      : item.width
                  }}
                />
              ))}
              <col key={"colbodyend"} />
            </colgroup>
            <tbody>
              <GridRowTotals
                style={{ background: "#EBF1F7" }}
                RowComponent={RowComponent || GridRow}
                trClass={trClass}
                columns={columns}
                groupColumnWidth={groupColumnWidth}
                {...rowProps}
              />
            </tbody>
          </table>
        )}
      </>
    );
  }
);

export const ColumnResizerCommunicator = new Communicator();
