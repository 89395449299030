import * as UserActions from "../Actions/UserActions";

const initialState = {
  isFetching: false,
  accountId: null,
  error: null
};

export default function CurrentUserReducer(state = initialState, action) {
  switch (action.type) {
    case UserActions.FETCH_CURRENT_USER_SUMMARY_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case UserActions.FETCH_CURRENT_USER_SUMMARY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        accountId: action.response.result
      };

    default:
      return state;
  }
}
