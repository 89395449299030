import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext
} from "react";
import "./AnimatedAccordion.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { NotificationsPermissionToggleContext } from "../../Containers/Accounts/AccountNotifications/AccountNotificationHelper";

const useAccordionState = (
  isOpenByDefault,
  disableToggle,
  storageName,
  numberedOpenDefault
) => {
  const resolvedOpenByDefault = useMemo(() => {
    if (!storageName) return isOpenByDefault;
    else {
      const AccordionState =
        JSON.parse(localStorage.getItem("AccordionState")) || {};
      if (typeof AccordionState[storageName] === "undefined") {
        return true;
      } else {
        return Boolean(AccordionState[storageName]);
      }
    }
  }, [isOpenByDefault, storageName]);

  const contentRef = useRef();
  const [isOpen, setIsOpen] = useState(resolvedOpenByDefault);
  const [isAnimating, setIsAnimating] = useState(resolvedOpenByDefault);

  const hasMountedRef = useRef();

  const toggleOpen = useCallback(
    (cmd) => {
      if (cmd === true && !isOpen) {
        setIsOpen((isOpen) => true);
        setIsAnimating(true);
      } else if (cmd === false && isOpen) {
        setIsOpen((isOpen) => false);
        setIsAnimating(true);
      } else if (!disableToggle && typeof cmd !== "boolean") {
        let AccordionState =
          JSON.parse(localStorage.getItem("AccordionState")) || {};
        AccordionState[storageName] = !isOpen;
        localStorage.setItem("AccordionState", JSON.stringify(AccordionState));
        setIsOpen((isOpen) => !isOpen);
        setIsAnimating(true);
      }
    },
    [disableToggle, isOpen, storageName]
  );

  useLayoutEffect(() => {
    const contentElem = contentRef.current;
    const hasMounted = hasMountedRef.current;
    if (isOpen) {
      if (!hasMounted) {
        contentElem.style.opacity = 1;
        const timeout = setTimeout(() => {
          contentElem.style.height = `auto`;
        }, 500);
        return () => clearTimeout(timeout);
      }
      let timeout;
      let frame = requestAnimationFrame(() => {
        contentElem.style.height = `0px`;
        frame = requestAnimationFrame(() => {
          const { scrollHeight } = contentElem;
          contentElem.style.height = `${scrollHeight}px`;
          contentElem.style.opacity = 1;
          timeout = setTimeout(() => {
            contentElem.style.height = `auto`;
          }, 500);
        });
      });

      return () => {
        cancelAnimationFrame(frame);
        clearTimeout(timeout);
      };
    } else if (contentElem) {
      const { scrollHeight } = contentElem;

      let timeout;
      let frame = requestAnimationFrame(() => {
        contentElem.style.height = `${scrollHeight}px`;
        frame = requestAnimationFrame(() => {
          contentElem.style.height = `0px`;
          contentElem.style.opacity = 0;
          timeout = setTimeout(() => {
            setIsAnimating(false);
          }, 500);
        });
      });

      return () => {
        cancelAnimationFrame(frame);
        clearTimeout(timeout);
      };
    }
  }, [isOpen, isAnimating]);

  useEffect(() => {
    hasMountedRef.current = true;
  }, []);

  const ToggleOpenmArrayProvider = useContext(
    NotificationsPermissionToggleContext
  );

  useEffect(() => {
    if (ToggleOpenmArrayProvider && ToggleOpenmArrayProvider.current) {
      ToggleOpenmArrayProvider.current.push(toggleOpen);
      const current = ToggleOpenmArrayProvider.current;
      return () => {
        const index = current.indexOf(toggleOpen);
        current.splice(index, 1);
      };
    }
  }, [ToggleOpenmArrayProvider, toggleOpen]);

  return { toggleOpen, isOpen, isAnimating, contentRef };
};

const AnimatedAccordion = ({
  title,
  children,
  className,
  icon,
  onExtraClick,
  titleClassnames,
  isOpenByDefault = false,
  disableToggle = false,
  storageName
}) => {
  const { toggleOpen, isOpen, isAnimating, contentRef } = useAccordionState(
    isOpenByDefault,
    disableToggle,
    storageName
  );

  const shouldShow = isOpen || isAnimating;

  return (
    <div
      className={classnames(
        "ar-animated-accordion",
        {
          active: isOpen
        },
        className
      )}
      onClick={onExtraClick}
    >
      <div
        className="d-inline-flex align-items-center title"
        onClick={toggleOpen}
      >
        <div className="ar-animated-accordion-icon">
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div
          className={
            titleClassnames
              ? titleClassnames
              : "text-black d-flex align-items-center fs-14 lh-normal"
          }
        >
          {icon && <FontAwesomeIcon icon={icon} size="sm" className="mr-2" />}
          {title}
        </div>
      </div>
      {shouldShow && (
        <div
          className={classnames("ar-animated-accordion-content")}
          ref={contentRef}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default AnimatedAccordion;
