import { faTicketSimple } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

import TriggerCreation from "./TriggerCreation";
import { spaceTicketsSchema } from "../../../../../config/schema";
import { ConnectedTicketOrigin } from "../../../../Origin/TicketOrigin";
import { useIntl } from "react-intl";

const PreviewTarget = ({ val }) => <ConnectedTicketOrigin ticket={val} />;

const TriggerTicketCreation = React.memo(({ ...props }) => {
	const intl = useIntl()
  return (
    <TriggerCreation
      {...props}
      TargetPreview={PreviewTarget}
      schema={spaceTicketsSchema}
      icon={faTicketSimple}
			text={intl.formatMessage({ id: "CASE" })}
    />
  );
});

export default TriggerTicketCreation;
