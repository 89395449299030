import React from "react";

const AirdeskUploaderSvg = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="28.453"
      height="27.919"
      viewBox="0 0 28.453 27.919"
    >
      <g
        id="Group_4419"
        data-name="Group 4419"
        transform="translate(-830 -625.651)"
      >
        <path
          id="arrow-alt-up"
          d="M1.177,6.037H2.986V8.877A1.125,1.125,0,0,0,4.162,9.943H6.816A1.125,1.125,0,0,0,7.992,8.877V6.037H9.8a1.057,1.057,0,0,0,.832-1.819L6.321.312a1.265,1.265,0,0,0-1.663,0L.346,4.218A1.057,1.057,0,0,0,1.177,6.037ZM5.489,1.065,9.8,4.971H6.816V8.877H4.162V4.971H1.176Z"
          transform="translate(850.518 635.594) rotate(180)"
          fill="#1e73f0"
        />
        <path
          id="file-pdf"
          d="M11.982,2.759,9.39.385A1.518,1.518,0,0,0,8.328,0L1.868.154A1.556,1.556,0,0,0,.313,1.587L0,13.67a1.377,1.377,0,0,0,1.483,1.358l9.115-.216a1.552,1.552,0,0,0,1.555-1.43L12.4,3.735a1.277,1.277,0,0,0-.421-.977Zm-1.219.9-2.408.057.057-2.21ZM1.52,13.634,1.832,1.551,6.9,1.431,6.818,4.452a.687.687,0,0,0,.742.679l3.291-.078-.216,8.365ZM9.546,9.272c-.377-.339-1.481-.217-2.033-.14a2.4,2.4,0,0,1-1.13-1.317A3.424,3.424,0,0,0,6.6,6.184c-.113-.758-1.179-.657-1.344-.139a3.809,3.809,0,0,0,.171,1.944,23.951,23.951,0,0,1-1.176,2.188c-.641.314-1.507.8-1.649,1.38-.116.461.781,1.584,2.432-.963a17.429,17.429,0,0,1,2.18-.635,4.332,4.332,0,0,0,1.753.452A.688.688,0,0,0,9.546,9.272ZM3.218,11.681a3.869,3.869,0,0,1,.988-1.039c-.624.894-.989,1.06-.988,1.039Zm2.726-5.6c.234-.006.188.927.026,1.184A2.237,2.237,0,0,1,5.944,6.083Zm-.875,3.986a11.636,11.636,0,0,0,.823-1.607,3.06,3.06,0,0,0,.926,1.009A11.876,11.876,0,0,0,5.069,10.069Zm4.169-.244s-.163.178-1.175-.2C9.176,9.525,9.353,9.753,9.237,9.825Z"
          transform="matrix(0.966, 0.259, -0.259, 0.966, 841.663, 635.844)"
          fill="#1e73f0"
        />
        <path
          id="image"
          d="M8.127.174.823,0A.764.764,0,0,0,0,.754L.12,5.395a.861.861,0,0,0,.863.793l7.3.173a.764.764,0,0,0,.823-.753L8.99.967A.861.861,0,0,0,8.127.174Zm.035,5.412L1.068,5.417a.108.108,0,0,1-.108-.1L.846.87a.1.1,0,0,1,.1-.094L8.042.945a.108.108,0,0,1,.108.1l.115,4.447A.1.1,0,0,1,8.162,5.585Zm-5.9-4.134a.636.636,0,0,0-.686.628.717.717,0,0,0,.719.661.636.636,0,0,0,.686-.628A.717.717,0,0,0,2.264,1.452ZM1.786,4.661,7.4,4.794,7.371,3.505,5.8,2.059a.224.224,0,0,0-.3-.007L3.451,3.928l-.71-.653a.225.225,0,0,0-.3-.007l-.677.62Z"
          transform="matrix(0.966, -0.259, 0.259, 0.966, 830, 642.565)"
          fill="#1e73f0"
        />
        <path
          id="file-word"
          d="M5.327,1.4,4.094.267A.757.757,0,0,0,3.6.07L.673,0A.626.626,0,0,0,0,.617L.142,6.1a.7.7,0,0,0,.705.649l4.134.1a.624.624,0,0,0,.673-.616L5.541,1.854A.643.643,0,0,0,5.327,1.4Zm-.532.384L3.7,1.759l-.026-1ZM.831,6.114.689.634l2.3.055.035,1.37a.351.351,0,0,0,.353.324l1.493.035.1,3.794ZM3.919,3.449a.158.158,0,0,0-.165.121c-.262,1.28-.26,1.25-.266,1.356,0-.016-.007-.034-.012-.057-.013-.067,0,0-.373-1.319a.181.181,0,0,0-.171-.125l-.191,0a.163.163,0,0,0-.165.116c-.317,1.3-.312,1.259-.321,1.358,0-.015,0-.033-.009-.055-.012-.069-.227-.97-.308-1.311A.18.18,0,0,0,1.768,3.4l-.241-.006a.154.154,0,0,0-.163.191c.126.432.421,1.452.523,1.8a.179.179,0,0,0,.171.124l.362.009A.158.158,0,0,0,2.583,5.4l.233-.935a1.468,1.468,0,0,0,.037-.227l.048.229c0,.005.2.67.281.947a.181.181,0,0,0,.17.124l.355.008a.158.158,0,0,0,.163-.116c.271-1.072.393-1.557.449-1.78a.17.17,0,0,0-.172-.2l-.227-.005Z"
          transform="matrix(0.966, -0.259, 0.259, 0.966, 851.22, 643.084)"
          fill="#1e73f0"
        />
      </g>
    </svg>
  );
};

export default AirdeskUploaderSvg;
