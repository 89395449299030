import React from "react";
import { connect } from "react-redux";
import { CountryProfileImg } from "../Img/Img";
import classnames from "classnames";

const CountryProfile = ({ country, className }) => {
  if (!country) return null;
  return (
    <div className={classnames("d-flex align-items-center", className)}>
      <CountryProfileImg size="sm" country={country} />
      <div>{country.Name}</div>
    </div>
  );
};

export default CountryProfile;

const mapStateToProps = (state, ownProps) => {
  return {
    country: state.Entities.Countries[ownProps.country]
  };
};

export const ConnectedCountryProfile = connect(mapStateToProps)(CountryProfile);
