import React, { useContext } from "react";

export const EntitySummaryParentSchemaProviderForMessagesContext =
  React.createContext();

export const useEntitySummarySchemaProvider = () => {
  return useContext(EntitySummaryParentSchemaProviderForMessagesContext);
};

export const EntitySummaryParentIdProviderForMessagesContext =
  React.createContext();

export const useEntitySummaryParentIdProvider = () => {
  return useContext(EntitySummaryParentIdProviderForMessagesContext);
};
