import React, { useMemo, useContext, useEffect } from "react";
import { getEntity } from "../../Helpers/IOClient";
import {
  AreaChart,
  AreaChartTickets
} from "./Echarts/AverageEvolutionTicketsLineChart";
// import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faEllipsisV,
//   faTicketSimple,
//   faCaretDown
// } from "@fortawesome/pro-light-svg-icons";
import {
  DashboardTitle,
  ReportsDateProvider,
  DashboardEmptyResponse
} from "./Dashboard";
import {
  spacePipelineSchema
  //   spacePipelineStatusSchema,
  //   accountSchema
} from "../../config/schema";
import { BarLoader } from "../../Components/GlobalLoader/GlobalLoader";
import { PieChart, BigBarChartPerformance } from "./Echarts/PerformanceChart";
import {
  //   ComboChart,
  ComboEvolution
} from "./Echarts/TicketsEvolutionLineChart";
import classnames from "classnames";
// import { faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
// import Moment from "react-moment";
// import { msToTime } from "../../Assets/Utils";
import { ReverseBarChart } from "./Echarts/OpenTicketsBarChart";
import {
  ComboLoadChart,
  PipelineWorkloadComboLoadChart
} from "./Echarts/TicketLoadBarChart";
import { ClosedBarChart } from "./Echarts/GlobalAverageCloseTime";
import { TicketMainViewLink } from "../../Helpers/HooksHelper";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { FormattedMessage } from "react-intl";
import { useDashboardQuery } from "./DashboardQueryHelper";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import { DashboardSettingsContext } from "./DashboardLayout";
import Button from "../../Components/Button/Button";
import DashboardCardSettingsForm, {
  handleIntervalCalculation
} from "./DashboardCardSettingsForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import CreateDashboardFilterLinksHelper from "./DashboardFilterLinksHelper";

const defaultTimeFrame = moment().subtract(180, "d").format("YYYY-MM-DD");
const lS_Group = "dashboard-layout-tickets";
export const OpenTickets = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "openTickets";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const pipeline =
    DataReports[0] === "Tickets" ? DataReports[3] : Settings.Pipeline;
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Tickets/Counter?pipeline=${
      pipeline ? pipeline : Settings.Pipeline
    }&status=1&todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  var account = useCurrentAccount().Id;

  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );

  if (!loading && !error) {
    const resolvedData = data.CurrentMonth;
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"OPEN_CASES"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"OPEN_CASES"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <TicketMainViewLink
          className="ar-dashboard-active-values mb-2"
          params={`$filter=PipelineStatus/Status eq '1'${CreateDashboardFilterLinksHelper(
            DataReports,
            account
          )}`}
        >
          {resolvedData}
        </TicketMainViewLink>
        {/* {data.Percentage !== 0 && (
          <div
            className={classnames(
              "fs-14 font-weight-bold",
              {
                "ar-dashboard-open-description": data.Percentage > 0
              },
              {
                "ar-dashboard-closed-description": data.Percentage < 0
              }
            )}
          >
            {data.Percentage > 0 ? (
              <FontAwesomeIcon icon={faCaretUp} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} />
            )}
            {Math.abs(Math.floor(data.Percentage))}% Ultimo Mês
          </div>
        )} */}
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const ClosedTickets = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "closedTickets";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const pipeline =
    DataReports[0] === "Tickets" ? DataReports[3] : Settings.Pipeline;
  var account = useCurrentAccount().Id;
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Tickets/Counter?pipeline=${
      pipeline ? pipeline : Settings.Pipeline
    }&status=4&todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );

  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );

  if (!loading && !error) {
    const resolvedData = data.CurrentMonth;
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"CLOSED_CASES"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"CLOSED_CASES"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <TicketMainViewLink
          className="ar-dashboard-closed-values mb-2"
          params={`$filter=PipelineStatus/Status eq '4'${CreateDashboardFilterLinksHelper(
            DataReports,
            account
          )}`}
        >
          {resolvedData}
        </TicketMainViewLink>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const CanceledTickets = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "canceledTickets";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const pipeline =
    DataReports[0] === "Tickets" ? DataReports[3] : Settings.Pipeline;
  var account = useCurrentAccount().Id;
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Tickets/Counter?pipeline=${
      pipeline ? pipeline : Settings.Pipeline
    }&status=3&todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );

  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );

  if (!loading && !error) {
    const resolvedData = data.CurrentMonth;
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"CANCELLED_CASES"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"CANCELED"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <TicketMainViewLink
          className="ar-dashboard-canceled-values mb-2"
          params={`$filter=PipelineStatus/Status eq '4'${CreateDashboardFilterLinksHelper(
            DataReports,
            account
          )}`}
        >
          {resolvedData}
        </TicketMainViewLink>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const AverageOpenTimeBeforeCloseTicket = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "averageOpenTime";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/tickets/AverageClose?todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const resolvedData = new moment.duration(data.Average, "s");
    let resolvedTime = Math.round(resolvedData.asDays() * 10) / 10;
    let Spec = "Days";
    if (resolvedTime < 1) {
      resolvedTime = Math.round(resolvedData.asHours() * 10) / 10;
      Spec = "Hours";
    }
    if (resolvedTime < 1) {
      resolvedTime = Math.round(resolvedData.asMinutes() * 10) / 10;
      Spec = "Minutes";
    }
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"AVERAGE_CLOSE_CASE"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"AVERAGE_CLOSE_CASE"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <div className="d-flex flex-column">
          <div className="ar-dashboard-values">{resolvedTime}</div>
          <div className="fs-14 text-black fw-bold">{Spec}</div>
        </div>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const TicketStatusTotals = () => {
  const { data, error, loading } = useDashboardQuery(`query/Pipelines?Type=2`, [
    spacePipelineSchema
  ]);

  if (!loading && !error) {
    return <TicketStatusTotalsResult results={data} />;
  } else {
    return <BarLoader style={{ marginTop: -16 }} isLoading={loading} />;
  }
};

const TicketStatusTotalsResult = ({ results }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "ticketsStatus";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings = (DashboardSettings &&
    DashboardSettings[lS_Group] &&
    DashboardSettings[lS_Group][lS_Field]) || { Pipeline: results[0] };
  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  var account = useCurrentAccount().Id;
  const { ...DataReports } = useContext(ReportsDateProvider);
  const pipeline =
    DataReports[0] === "Tickets" ? DataReports[3] : Settings.Pipeline;

  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Tickets/${
      pipeline ? pipeline : Settings.Pipeline
    }/StatusCounter?todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2] && DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const resolvedData = data;
    return (
      <div className="px-3 of-y-auto h-100">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"CASES_STATUS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"PIPELINE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {
                  getEntity(
                    spacePipelineSchema,
                    pipeline ? pipeline : Settings.Pipeline
                  )?.Name
                }
              </span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    pipelineType={2}
                    Title={"CASES_STATUS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: true
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <div className="d-flex flex-wrap w-100 mt-3 px-2">
          {resolvedData.map((e, i) => {
            return (
              <div key={i} className="d-flex flex-column mb-3 mr-3">
                <TicketMainViewLink
                  className={classnames({
                    "ar-dashboard-active-values": e.PipelineStatus.Status === 1,
                    "ar-dashboard-closed-values": e.PipelineStatus.Status === 4,
                    "ar-dashboard-canceled-values":
                      e.PipelineStatus.Status === 3,
                    "ar-dashboard-draft-values": e.PipelineStatus.Status === 5
                  })}
                  params={`$filter=Ticket/PipelineStatusId eq '${
                    e.PipelineStatus.Id
                  }'${CreateDashboardFilterLinksHelper(
                    DataReports,
                    account
                  )}&pipeline=${pipeline}`}
                >
                  {e.Count}
                </TicketMainViewLink>

                <div
                  className={classnames({
                    "ar-dashboard-active-description":
                      e.PipelineStatus.Status === 1,
                    "ar-dashboard-closed-description":
                      e.PipelineStatus.Status === 4,
                    "ar-dashboard-canceled-description":
                      e.PipelineStatus.Status === 3,
                    "ar-dashboard-draft-description":
                      e.PipelineStatus.Status === 5
                  })}
                >
                  {e.PipelineStatus?.Name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const AverageEvolutionClosedTickets = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "evolutionClosedTickets";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/tickets/AverageCloseTime?todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const { Results, HasData } = data;

    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"EVOLUTION_AVERAGE"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"EVOLUTION_AVERAGE"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <AreaChartTickets Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const PerformanceTicket = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "performanceTickets";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Tickets/Performance?todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );

  const resolvedData = useMemo(() => {
    if (!data) return null;
    const resolvedData = {};
    const { Results = [] } = data;
    for (const e of Results) {
      if (e.Status === 1) {
        resolvedData.Open = [e.Count, Math.round(e.Percentage)];
      } else if (e.Status === 3) {
        resolvedData.Canceled = [e.Count, Math.round(e.Percentage)];
      } else if (e.Status === 4) {
        resolvedData.Closed = [e.Count, Math.round(e.Percentage)];
      }
    }
    return resolvedData;
  }, [data]);

  if (!loading && !error) {
    const { HasData } = data;
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"TICKET_PERFORMANCE"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"TICKET_PERFORMANCE"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <PieChart Data={resolvedData} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const EvolutionTicket = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "evolutionTickets";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Tickets/Evolution?todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );

  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );

  if (!loading && !error) {
    const { Results, HasData } = data;
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"EVOLUTION_ACCOUNTS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"EVOLUTION_ACCOUNTS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <ComboEvolution Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const DepartmentWorkloadTicket = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "departmentWorkloadTickets";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/tickets/Rank?top=7&todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"MY_DEPARTMENTS"} />
    );

  if (!loading && !error) {
    // data.splice(20, data.length - 21);
    // const [Results,HasData] = data;
    const Results = data;

    let HasData = false;
    for (const e of Results) {
      const { Data } = e;
      for (const key in Data) {
        if (Object.hasOwnProperty.call(Data, key)) {
          const element = Data[key];
          if (parseInt(element) > 0) {
            HasData = true;
            break;
          }
        }
      }
      if (HasData) {
        break;
      }
    }
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"DEPARTMENT_LOAD"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"DEPARTMENT_LOAD"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            {Results.length > 0 && <ComboLoadChart Data={Results} />}
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        )}
        {/* {resolvedData.length === 0 ? (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        ) : (
          <div className="h-100">
            {resolvedData.length > 0 && <ComboLoadChart Data={resolvedData} />}
          </div>
        )} */}
      </div>
    );
  } else {
    if (!loading && error.status === 403) {
      return (
        <div className="px-3 of-hidden h-100 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <DashboardTitle
              className="mb-0 fs-20"
              text={<FormattedMessage id={"DEPARTMENT_LOAD"} />}
            />
            <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
          </div>
          {DataReports[0] !== "Tickets" && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-2">
                <span className="ar-dashboard-description">
                  <FormattedMessage id={"INTERVAL"} />:
                </span>
                <span className="text-primary pl-1 fs-14">
                  {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                    <FormattedMessage id={Settings.Interval.title} />
                  ) : (
                    <FormattedMessage id={"ALL"} />
                  )}
                </span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">{user}</span>
              </div>
              <Button
                className="ar-dashboard-card-settings-link"
                vType="link-primary"
                onClick={() =>
                  openSidebar(
                    <DashboardCardSettingsForm
                      lS_Field={lS_Field}
                      lS_Group={lS_Group}
                      Settings={Settings}
                      closeSidebar={closeSidebar}
                      Title={"DEPARTMENT_LOAD"}
                      handleChanges={handleLayoutChange}
                      AvailableSettings={{
                        interval: true,
                        scope: true,
                        pipeline: false
                      }}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faCog} />
              </Button>
            </div>
          )}

          <DashboardEmptyResponse type="tickets">
            {DataReports ? (
              DataReports[4] === "Account" ? (
                <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
              ) : (
                <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
              )
            ) : (
              <FormattedMessage id={"EMPLOYEE"} />
            )}
          </DashboardEmptyResponse>
        </div>
      );
    }

    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const TeamWorkloadTicket = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "teamWorkloadTickets";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/tickets/TeamRank?top=7&todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    data.splice(20, data.length - 21);
    const Results = data;

    let HasData = false;
    for (const e of Results) {
      const { Data } = e;
      for (const key in Data) {
        if (Object.hasOwnProperty.call(Data, key)) {
          const element = Data[key];
          if (parseInt(element) > 0) {
            HasData = true;
            break;
          }
        }
      }
      if (HasData) {
        break;
      }
    }

    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"TEAM_LOAD"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"TEAM_LOAD"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <ComboLoadChart Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        )}
        {/* {Results.length === 0 ? (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        ) : (
          <div className="h-100">
            <ComboLoadChart Data={Results} />
          </div>
        )} */}
      </div>
    );
  } else {
    if (!loading && error.status === 403) {
      return (
        <div className="px-3 of-hidden h-100 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <DashboardTitle
              className="mb-0 fs-20"
              text={<FormattedMessage id={"TEAM_LOAD"} />}
            />
            <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
          </div>
          {DataReports[0] !== "Tickets" && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-2">
                <span className="ar-dashboard-description">
                  <FormattedMessage id={"INTERVAL"} />:
                </span>
                <span className="text-primary pl-1 fs-14">
                  {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                    <FormattedMessage id={Settings.Interval.title} />
                  ) : (
                    <FormattedMessage id={"ALL"} />
                  )}
                </span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">{user}</span>
              </div>
              <Button
                className="ar-dashboard-card-settings-link"
                vType="link-primary"
                onClick={() =>
                  openSidebar(
                    <DashboardCardSettingsForm
                      lS_Field={lS_Field}
                      lS_Group={lS_Group}
                      Settings={Settings}
                      closeSidebar={closeSidebar}
                      Title={"TEAM_LOAD"}
                      handleChanges={handleLayoutChange}
                      AvailableSettings={{
                        interval: true,
                        scope: true,
                        pipeline: false
                      }}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faCog} />
              </Button>
            </div>
          )}

          <DashboardEmptyResponse type="tickets">
            {DataReports ? (
              DataReports[4] === "Account" ? (
                <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
              ) : (
                <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
              )
            ) : (
              <FormattedMessage id={"EMPLOYEE"} />
            )}
          </DashboardEmptyResponse>
        </div>
      );
    }

    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const ResolutionTimeOpenedTickets = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "resolutionTimeOpenTickets";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/tickets/RankDayTime?todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );

  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );

  if (!loading && !error) {
    const { Results, HasData } = data;
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"TICKET_PROGRESS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"TICKET_PROGRESS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <ReverseBarChart Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const DepartmentAverageCloseTime = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "averageTimeClosingTickets";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/tickets/UserCloseRank?top=7&todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );

  if (!loading && !error) {
    const { Results, HasData } = data;
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"DEPARTMENT_CASE_AVERAGE"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"DEPARTMENT_CASE_AVERAGE"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <ClosedBarChart Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        )}
        {/* {Results.length === 0 ? (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        ) : (
          <div className="h-100">
            <ClosedBarChart Data={Results} />
          </div>
        )} */}
      </div>
    );
  } else {
    if (!loading && error.status === 403) {
      return (
        <div className="px-3 of-hidden h-100 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <DashboardTitle
              className="mb-0 fs-20"
              text={<FormattedMessage id={"DEPARTMENT_CASE_AVERAGE"} />}
            />
            <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
          </div>
          {DataReports[0] !== "Tickets" && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-2">
                <span className="ar-dashboard-description">
                  <FormattedMessage id={"INTERVAL"} />:
                </span>
                <span className="text-primary pl-1 fs-14">
                  {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                    <FormattedMessage id={Settings.Interval.title} />
                  ) : (
                    <FormattedMessage id={"ALL"} />
                  )}
                </span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">{user}</span>
              </div>
              <Button
                className="ar-dashboard-card-settings-link"
                vType="link-primary"
                onClick={() =>
                  openSidebar(
                    <DashboardCardSettingsForm
                      lS_Field={lS_Field}
                      lS_Group={lS_Group}
                      Settings={Settings}
                      closeSidebar={closeSidebar}
                      Title={"DEPARTMENT_CASE_AVERAGE"}
                      handleChanges={handleLayoutChange}
                      AvailableSettings={{
                        interval: true,
                        scope: true,
                        pipeline: false
                      }}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faCog} />
              </Button>
            </div>
          )}

          <DashboardEmptyResponse type="tickets">
            {DataReports ? (
              DataReports[4] === "Account" ? (
                <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
              ) : (
                <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
              )
            ) : (
              <FormattedMessage id={"EMPLOYEE"} />
            )}
          </DashboardEmptyResponse>
        </div>
      );
    }

    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const TeamAverageCloseTime = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "teamAverageTimeClosingTickets";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/tickets/TeamCloseRank?top=7&todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const { Results, HasData } = data;
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"TEAM_CASE_AVERAGE"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"TEAM_CASE_AVERAGE"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <ClosedBarChart Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        )}
        {/* {resolvedData.length === 0 ? (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        ) : (
          <div className="h-100">
            <ClosedBarChart Data={resolvedData} />
          </div>
        )} */}
      </div>
    );
  } else {
    if (!loading && error.status === 403) {
      return (
        <div className="px-3 of-hidden h-100 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <DashboardTitle
              className="mb-0 fs-20"
              text={<FormattedMessage id={"TEAM_CASE_AVERAGE"} />}
            />
            <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
          </div>
          {DataReports[0] !== "Tickets" && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-2">
                <span className="ar-dashboard-description">
                  <FormattedMessage id={"INTERVAL"} />:
                </span>
                <span className="text-primary pl-1 fs-14">
                  {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                    <FormattedMessage id={Settings.Interval.title} />
                  ) : (
                    <FormattedMessage id={"ALL"} />
                  )}
                </span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">{user}</span>
              </div>
              <Button
                className="ar-dashboard-card-settings-link"
                vType="link-primary"
                onClick={() =>
                  openSidebar(
                    <DashboardCardSettingsForm
                      lS_Field={lS_Field}
                      lS_Group={lS_Group}
                      Settings={Settings}
                      closeSidebar={closeSidebar}
                      Title={"TEAM_CASE_AVERAGE"}
                      handleChanges={handleLayoutChange}
                      AvailableSettings={{
                        interval: true,
                        scope: true,
                        pipeline: false
                      }}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faCog} />
              </Button>
            </div>
          )}

          <DashboardEmptyResponse type="tickets">
            {DataReports ? (
              DataReports[4] === "Account" ? (
                <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
              ) : (
                <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
              )
            ) : (
              <FormattedMessage id={"EMPLOYEE"} />
            )}
          </DashboardEmptyResponse>
        </div>
      );
    }

    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const GlobalPerformanceTicket = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "globalProductivityTickets";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Tickets/Performance?todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  const resolvedData = useMemo(() => {
    let count = 0;
    if (!data) return null;
    const { Results = [] } = data;
    const resolvedData = {};
    for (const e of Results) {
      if (e.Status === 1) {
        resolvedData.Open = [e.Count, Math.round(e.Percentage)];
        count += e.Count;
      } else if (e.Status === 3) {
        resolvedData.Canceled = [e.Count, Math.round(e.Percentage)];
        count += e.Count;
      } else if (e.Status === 4) {
        resolvedData.Closed = [e.Count, Math.round(e.Percentage)];
        count += e.Count;
      }
    }
    return [resolvedData, count];
  }, [data]);

  if (!loading && !error) {
    const { HasData } = data;
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"OVERALL_TICKET_PERFORMANCE"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"OVERALL_TICKET_PERFORMANCE"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <div className="fs-40 text-black pb-2">
              {resolvedData[1]} <FormattedMessage id={"CASES"} />
            </div>
            <BigBarChartPerformance Data={resolvedData[0]} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const PipelineWorkloadTicket = () => {
  const { data, error, loading } = useDashboardQuery(`query/Pipelines?Type=2`, [
    spacePipelineSchema
  ]);

  if (!loading && !error) {
    return <TicketStatusTimesTotals results={data} />;
  } else {
    return <BarLoader style={{ marginTop: -16 }} isLoading={loading} />;
  }
};

const TicketStatusTimesTotals = ({ results }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "PipelineWorkloadTicket";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings = (DashboardSettings &&
    DashboardSettings[lS_Group] &&
    DashboardSettings[lS_Group][lS_Field]) || { Pipeline: results[0] };
  const handleLayoutChange = useContext(DashboardSettingsContext);
  const { ...DataReports } = useContext(ReportsDateProvider);
  const pipeline =
    DataReports[0] === "Tickets" ? DataReports[3] : Settings.Pipeline;
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/tickets/AverageStatus?pipelineId=${
      pipeline ? pipeline : Settings.Pipeline
    }&todate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Tickets"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Tickets" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );

  if (!loading && !error) {
    // data.splice(20, data.length - 21);
    const { Results, HasData } = data;

    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"AVERAGE_STATUS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Tickets" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"PIPELINE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {
                  getEntity(
                    spacePipelineSchema,
                    pipeline ? pipeline : Settings.Pipeline
                  )?.Name
                }
              </span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    pipelineType={2}
                    Title={"AVERAGE_STATUS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: true
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <PipelineWorkloadComboLoadChart Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        )}
        {/* {Results.length === 0 ? (
          <DashboardEmptyResponse className="header h-100" type="tickets">
            <FormattedMessage id={"EMPTY_CASES"} />
          </DashboardEmptyResponse>
        ) : (
          <div className="h-100">
              <PipelineWorkloadComboLoadChart Data={Results} />
          </div>
        )} */}
      </div>
    );
  } else {
    if (!loading && error.status === 403) {
      return (
        <div className="px-3 of-hidden h-100 d-flex flex-column">
          <div className="d-flex justify-content-between">
            <DashboardTitle
              className="mb-0 fs-20"
              text={<FormattedMessage id={"AVERAGE_STATUS"} />}
            />
            <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
          </div>
          {DataReports[0] !== "Tickets" && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-2">
                <span className="ar-dashboard-description">
                  <FormattedMessage id={"INTERVAL"} />:
                </span>
                <span className="text-primary pl-1 fs-14">
                  {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                    <FormattedMessage id={Settings.Interval.title} />
                  ) : (
                    <FormattedMessage id={"ALL"} />
                  )}
                </span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"SCOPE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">{user}</span>
                <span className="ar-dashboard-description ml-1">
                  | <FormattedMessage id={"PIPELINE"} />:
                </span>
                <span className="text-primary pl-1 fs-14">
                  {
                    getEntity(
                      spacePipelineSchema,
                      pipeline ? pipeline : Settings.Pipeline
                    )?.Name
                  }
                </span>
              </div>
              <Button
                className="ar-dashboard-card-settings-link"
                vType="link-primary"
                onClick={() =>
                  openSidebar(
                    <DashboardCardSettingsForm
                      lS_Field={lS_Field}
                      lS_Group={lS_Group}
                      Settings={Settings}
                      closeSidebar={closeSidebar}
                      pipelineType={2}
                      Title={"AVERAGE_STATUS"}
                      handleChanges={handleLayoutChange}
                      AvailableSettings={{
                        interval: true,
                        scope: true,
                        pipeline: true
                      }}
                    />
                  )
                }
              >
                <FontAwesomeIcon icon={faCog} />
              </Button>
            </div>
          )}

          <DashboardEmptyResponse type="tickets">
            {DataReports ? (
              DataReports[4] === "Account" ? (
                <FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
              ) : (
                <FormattedMessage id={"EMPLOYE_NO_FILTER"} />
              )
            ) : (
              <FormattedMessage id={"EMPLOYEE"} />
            )}
          </DashboardEmptyResponse>
        </div>
      );
    }

    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};
