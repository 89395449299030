import { useMemo } from "react";
import { usePipelineSettings } from "../Components/Pipelines/PipelineProvider";
import { buildODataQuery } from "./ODataHelper";
import Axios from "axios";
import { getCurrentPage } from "./MiscHelper";

export const defaultODataParameters = {
	count: true,
	orderBy: null
};

export const useOdataQuery = (params, disablePipeline) => {
	const pipelineSettings = usePipelineSettings()
	return useMemo(() => {
		const resolvedParams = {
			...defaultODataParameters,
			...params
		};
		
		return buildODataQuery(resolvedParams, true, {
			pipelineSettings,
			disablePipeline
		});
	}, [disablePipeline, params, pipelineSettings]);
};

export const getOData = (url, optionalParameters, config) => {
	const parameters = {
		...defaultODataParameters,
		...optionalParameters
	};

	var query = buildODataQuery(parameters);

	const { top, count: willCount, skip } = parameters;

	return Axios.get(url + query, config).then(response => {
		let obj;

		if (willCount) {
			const { results, __count: count } = response.d;
			obj = {
				results,
				count
			};
		} else {
			obj = {
				results: response
			};
		}

		if (top) {
			obj.pageSize = top;
			if (willCount) {
				let totalCount = response.d.__count;
				obj.pages = Math.ceil(totalCount / top);

				if (!isNaN(skip)) {
					obj.currentPage = getCurrentPage(top, skip);
				}
			}
		}

		return obj;
	});
};