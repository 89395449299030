import { faStopwatch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import { ElementsRefContext } from "../../AutomationDetails";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import SourceHandles from "../../Handles/SourceHandles";
import NodeBaseSidebar from "../NodeBaseSidebar";
import styles from "../../AutomationDetails.module.css";
import Button from "../../../../../Components/Button/Button";
import { FormattedMessage } from "react-intl";
import KeyedDropdown from "../../../../../Components/Dropdown/KeyedDropdown";
import FormInput from "../../../../../Components/Forms/FormInput/FormInput";
import AutomationTestResults from "../AutomationTestResults";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import AutomationTrigger from "./AutomationTrigger";

const cronTypeOptions = ["minute", "hour", "day", "week", "month", "everyX"];
const cronEveryXTypeOptions = ["minute", "hour"];

const cronTypeOptionsDict = {
  minute: "EVERY_MINUTE",
  hour: "EVERY_HOUR",
  day: "EVERY_DAY",
  week: "EVERY_WEEK",
  month: "EVERY_MONTH",
  everyX: "EVERY_X"
};

const cronTypeValueComponent = ({ item }) => {
  if (!item) return <div></div>;
  return (
    <div className={styles.keyDropdownTextStyle}>
      <FormattedMessage id={`${cronTypeOptionsDict[item]}`} />
    </div>
  );
};

const CronTypeDropdown = ({ value, onChange }) => {
  return (
    <KeyedDropdown
      value={value}
      onChange={onChange}
      options={cronTypeOptions}
      closeOnSelect
      valueComponent={cronTypeValueComponent}
    />
  );
};

const CronEveryXTypeDropdown = ({ value, onChange }) => {
  return (
    <KeyedDropdown
      value={value}
      name={"everyType"}
      onChange={onChange}
      options={cronEveryXTypeOptions}
      closeOnSelect
      valueComponent={cronTypeValueComponent}
    />
  );
};

const cronDayOfWeekOptions = [1, 2, 3, 4, 5, 6, 7];
const cronDayOfWeekOptionsDict = {
  1: "MONDAY",
  2: "TUESDAY",
  3: "WEDNESDAY",
  4: "THURSDAY",
  5: "FRIDAY",
  6: "SATURDAY",
  7: "SUNDAY"
};

const cronDayOfWeekValueComponent = ({ item }) => {
  if (!item) return <div></div>;
  return (
    <div className={styles.keyDropdownTextStyle}>
      <FormattedMessage id={`${cronDayOfWeekOptionsDict[item]}`} />
    </div>
  );
};

const CronDayOfWeekDropdown = ({ value, onChange }) => {
  return (
    <KeyedDropdown
      value={value}
      name={"weekDay"}
      onChange={onChange}
      options={cronDayOfWeekOptions}
      closeOnSelect
      valueComponent={cronDayOfWeekValueComponent}
    />
  );
};

const CronTimeInputsSwitcher = React.memo(({ type, item, onChange }) => {
  const { minutes, hour, weekDay, monthDay, everyX, everyType } = item;
  switch (type) {
    case "hour":
      return (
        <FormInput
          disableAutomation
          name={"minutes"}
          textId="MINUTE"
          className="mb-3"
          onChange={onChange}
          value={minutes}
          type="number"
          min="0"
          max="59"
        />
      );
    case "day":
      return (
        <>
          <FormInput
            disableAutomation
            name={"hour"}
            textId="HOUR"
            className="mb-3"
            onChange={onChange}
            value={hour}
            type="number"
            min="0"
            max="23"
          />
          <FormInput
            disableAutomation
            name={"minutes"}
            textId="MINUTE"
            className="mb-3"
            onChange={onChange}
            value={minutes}
            type="number"
            min="0"
            max="59"
          />
        </>
      );
    case "week":
      return (
        <>
          <FormInput
            disableAutomation
            name={"hour"}
            textId="HOUR"
            className="mb-3"
            onChange={onChange}
            value={hour}
            type="number"
            min="0"
            max="23"
          />
          <FormInput
            disableAutomation
            name={"minutes"}
            textId="MINUTE"
            className="mb-3"
            onChange={onChange}
            value={minutes}
            type="number"
            min="0"
            max="59"
          />
          <FormInput
            disableAutomation
            name={"weekDay"}
            textId="WEEKDAY"
            className="mb-3"
            inputType={CronDayOfWeekDropdown}
            onChange={onChange}
            value={weekDay}
          />
        </>
      );
    case "month":
      return (
        <>
          <FormInput
            disableAutomation
            name={"hour"}
            textId="HOUR"
            className="mb-3"
            onChange={onChange}
            value={hour}
            type="number"
            min="0"
            max="23"
          />
          <FormInput
            disableAutomation
            name={"minutes"}
            textId="MINUTE"
            className="mb-3"
            onChange={onChange}
            value={minutes}
            type="number"
            min="0"
            max="59"
          />
          <FormInput
            disableAutomation
            name={"monthDay"}
            textId="DAY_OF_MONTH"
            className="mb-3"
            onChange={onChange}
            value={monthDay}
            type="number"
            min="1"
            max="31"
          />
        </>
      );
    case "everyX":
      return (
        <>
          <FormInput
            disableAutomation
            name={"everyX"}
            textId="VALUE"
            className="mb-3"
            onChange={onChange}
            value={everyX}
            type="number"
          />
          <FormInput
            disableAutomation
            name={"everyType"}
            textId="UNIT"
            className="mb-3"
            onChange={onChange}
            value={everyType}
            inputType={CronEveryXTypeDropdown}
          />
        </>
      );
    default:
      return <div></div>;
  }
});

const SingleCron = React.memo(({ item, handleChange, index }) => {
  const { type } = item;

  const onChange = (e) => {
    const { value, name } = e.target;
    handleChange((oldV) => {
      const newV = { ...oldV };
      const newItems = [...newV.crons];
      newItems[index] = {
        ...newItems[index],
        [name]: value
      };
      newV.crons = newItems;
      return newV;
    });
  };

  const removeNode = () => {
    handleChange((oldV) => {
      const newV = { ...oldV };
      const newItems = [...newV.crons];
      newItems.splice(index, 1);
      newV.crons = newItems;
      return newV;
    });
  };

  return (
    <div className="border-bottom mb-3">
      <div className="d-flex align-items-center justify-content-end">
        <div className="text-danger-alt cursor-pointer" onClick={removeNode}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
      </div>
      <FormInput
        disableAutomation
        name={"type"}
        textId="MODE"
        className="mb-3"
        onChange={onChange}
        value={type}
        inputType={CronTypeDropdown}
      />
      <CronTimeInputsSwitcher type={type} item={item} onChange={onChange} />
    </div>
  );
});

const FormContainer = ({ formState, id, canEdit }) => {
  const { form, handleChange } = formState;
  const { crons } = form;

  const addCronTime = () => {
    const newCrons = [...crons];
    newCrons.push({
      type: "minute",
      minutes: 0,
      hour: 0,
      weekDay: 1,
      monthDay: 1,
      everyX: 0,
      everyType: "minute"
    });
    handleChange({
      crons: newCrons
    });
  };

  return (
    <div className={styles.disableInputArrows}>
      <Button type="button" className="mb-3 w-100" onClick={addCronTime}>
        <FormattedMessage id="ADD_CRON_TIME" />
      </Button>
      {crons.map((e, i) => {
        return (
          <SingleCron key={i} item={e} handleChange={handleChange} index={i} />
        );
      })}
    </div>
  );
};

const settings = {
  defaultForm: {
    crons: []
  },
  FormContent: (props) => {
    return <FormContainer {...props}></FormContainer>;
  }
};

const CronNode = React.memo(({ selected, data, id, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);

  const AutomationTestRequest = useContext(AutomationTestRequestContext);

  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };
  const { enabled, config } = data;

  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer type={type} data={data} id={id}>
        <AutomationTrigger
          enabled={enabled}
          selected={selected}
          text={<FormattedMessage id={"CRON"} />}
          config={config}
          type={type}
          onDoubleClick={handleClick}
          icon={faStopwatch}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default CronNode;
