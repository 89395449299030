import {
  convertInterventionTarget,
  getInterventionTarget
} from "../../../Helpers/FormFormmaterHelper";
import { getEntity } from "../../../Helpers/IOClient";
import {
  spaceDealsTagsSchema,
  spaceTicketTagsSchema
} from "../../../config/schema";

export const InboundEmailType = {
  none: 0,
  ticket: 1,
  task: 2,
  deal: 3
};

export const defaultEmailReceptionForm = ({ user, id }) => {
  return {
    EmailName: "",
    EmailNameDomain: "",
    Domain: null,
    Type: 0,
    Enabled: true,
    SendDisabledEmailMessage: false,
    Company: null,
    Departments: [],
    Teams: [],
    DepartmentTeam: null,
    Manager: null,
    Assigned: null,
    Pipeline: null,
    PipelineStatus: null,
    Followers: [],
    TicketTarget: null,
    TaskTarget: null,
    DealManager: undefined
  };
};

export const formatEmailReceptionForm = (form) => {
  const {
    Company,
    Departments,
    Teams,
    IndirectClient,
    Manager,
    Pipeline,
    Type,
    PipelineStatus,
    Domain,
    DealManager,
    Assigned,
    DepartmentTeam,
    TicketTarget,
    Tags,
    TaskTarget,
    Client,
    ...rest
  } = form;

  if (Type === InboundEmailType.none) {
    return {
      Type,
      CompanyId: Company,
      ManagerId: Manager,
      DomainId: Domain,
      ...rest
    };
  }

  let tagsIds = [];
  if (Tags && Array.isArray(Tags))
    for (const e of Tags) {
      if (InboundEmailType.deal === Type) {
        const { TempId } = getEntity(spaceDealsTagsSchema, e.data);
        if (TempId) tagsIds.push(TempId);
      } else if (InboundEmailType.ticket === Type) {
        const { TempId } = getEntity(spaceTicketTagsSchema, e.data);
        if (TempId) tagsIds.push(TempId);
      }
    }

  const obj = {
    DealManagerId: DealManager,
    CompanyId: Company,
    DepartmentIds: Departments,
    // ClientId: Client,
    ...convertInterventionTarget(TicketTarget || TaskTarget),
    TeamIds: Teams,
    AssignedId: Assigned,
    Type,
    tagsIds,
    ManagerId: Manager,
    PipelineId: Pipeline,
    PipelineStatusId: PipelineStatus,
    DomainId: Domain,
    ...rest
  };

  if (Type === InboundEmailType.ticket) {
    if (DepartmentTeam) {
      const { type, data } = DepartmentTeam;
      if (type === "Department") {
        obj.DepartmentIds = [data];
        obj.TeamIds = [];
      } else {
        obj.DepartmentIds = [];
        obj.TeamIds = [data];
      }
    }
  }

  return obj;
};

export const ValidateTicketReceptionForm = (form, addError) => {
  const { Manager, Pipeline, PipelineStatus, Assigned } = form;

  if (!Manager) addError("Manager");
  if (!Pipeline) addError("Pipeline");
  if (!PipelineStatus) addError("PipelineStatus");
  if (!Assigned) addError("Assigned");
};
export const ValidateDealReceptionForm = (form, addError) => {
  const { DealManager, Pipeline, PipelineStatus, Assigned } = form;

  if (!DealManager) addError("Manager");
  if (!Pipeline) addError("Pipeline");
  if (!PipelineStatus) addError("PipelineStatus");
  if (!Assigned) addError("Assigned");
};

export const ValidateEmailReceptionForm = (form, addError) => {
  const { Manager, EmailName, EmailNameDomain, Domain, Type, Company } = form;

  if (!Company) addError("Company");

  if (!EmailName) addError("EmailName");

  if ((!EmailNameDomain && Domain) || (!Domain && EmailNameDomain))
    addError("EmailNameDomain");

  if (!Manager) addError("Manager");

  switch (Type) {
    case InboundEmailType.ticket:
      ValidateTicketReceptionForm(form, addError);
      break;
    case InboundEmailType.deal:
      ValidateDealReceptionForm(form, addError);
      break;

    default:
      break;
  }
};

export const convertEmailReceptionEntityToForm = (entity) => {
  const {
    Email,
    EmailConversationBounds,
    InboundEmailFollows,
    IsDeleted,
    TicketTags,
    DealTags,
    OrganizationSpaceInboundEmails,
    ...entityRest
  } = entity;

  const obj = {
    ...entityRest
  };

  if (Array.isArray(TicketTags) && TicketTags.length > 0) {
    const resolvedTags = [];
    for (const t of TicketTags) {
      resolvedTags.push({
        data: t,
        type: "TicketTag"
      });
    }
    obj.Tags = resolvedTags;
  } else if (Array.isArray(DealTags) && DealTags.length > 0) {
    const resolvedTags = [];
    for (const t of DealTags) {
      resolvedTags.push({
        data: t,
        type: "DealTag"
      });
    }
    obj.Tags = resolvedTags;
  }

  if (entity.Type === InboundEmailType.ticket) {
    if (entity.Departments && entity.Departments.length > 0) {
      obj.Departments = [];
      obj.DepartmentTeam = { type: "Department", data: entity.Departments[0] };
    } else if (entity.Teams && entity.Teams.length > 0) {
      obj.Teams = [];
      obj.DepartmentTeam = { type: "Team", data: entity.Teams[0] };
    }
  }

  obj.TicketTarget = getInterventionTarget(entity);
  // obj.TaskTarget = getInterventionTarget(entity);

  return obj;
};
