import { faCamera, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { faArrowsH } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ConnectedClientProfileStack } from "../../../Components/Accounts/AccountProfileStack";
import Button from "../../../Components/Button/Button";
import LoadableButton from "../../../Components/Button/LoadableButton";
import { ConnectedClientProfile } from "../../../Components/Clients/ClientProfile";
import KeyedDropdown from "../../../Components/Dropdown/KeyedDropdown";
import { AccountProfileImg } from "../../../Components/Img/Img";
import LabeledInput from "../../../Components/Input/LabeledInput";
import { ProfilePicture } from "../../../Components/ProfilePicture/ProfilePicture";

import Table, { Tr } from "../../../Components/Table";

import { spaceContactSchema } from "../../../config/schema";
import { useMultipleEntityValueSelector } from "../../../Hooks/AdvancedEntityHooks";

import { ConnectedClientOrigin } from "../../Origin/ClientOrigin";
import classnames from "classnames";
import { ContactStatusProfile } from "../../../Components/Contacts/ContactProfile";
import DockTyper from "../../../Components/DocTyper/DocTyperSimple";

const StatusChanger = ({ Data }) => {
  return <ContactStatusProfile status={1} />;
};

const EmailDropdown = ({ Data }) => {
  const [handleChange, MergeOptions] = useContext(ContactsSecondPageContext);

  const performEmailChange = (value) => {
    handleChange({ name: "Email", value });
  };
  if (MergeOptions.Emails.length === 0) return "―";
  else if (MergeOptions.Emails.length === 1) return MergeOptions.Emails[0];
  else
    return (
      <KeyedDropdown
        className="fs-12"
        closeOnSelect
        onChange={performEmailChange}
        options={MergeOptions.Emails}
        value={Data.Email || "―"}
      />
    );
};

const PhoneComponent = ({ item = "", ...props }) => {
  return <span>{item.split(".").join(" ")}</span>;
};

const MobilePhoneDropdown = ({ Data }) => {
  const [handleChange, MergeOptions] = useContext(ContactsSecondPageContext);

  const performMobileChange = (value) => {
    handleChange({ name: "MobileNumber", value });
  };

  if (MergeOptions.Cellphones.length === 0) return "―";
  else if (MergeOptions.Cellphones.length === 1)
    return <PhoneComponent item={MergeOptions.Cellphones[0]} />;
  else
    return (
      <KeyedDropdown
        className="fs-12"
        closeOnSelect
        valueComponent={PhoneComponent}
        ListComponent={PhoneComponent}
        onChange={performMobileChange}
        options={MergeOptions.Cellphones}
        value={Data.MobileNumber || "―"}
      />
    );
};

const PhoneDropdown = ({ Data }) => {
  const [handleChange, MergeOptions] = useContext(ContactsSecondPageContext);

  const performChange = (value) => {
    handleChange({ name: "PhoneNumber", value });
  };

  if (MergeOptions.Phones.length === 0) return "―";
  else if (MergeOptions.Phones.length === 1)
    return <PhoneComponent item={MergeOptions.Phones[0]} />;
  else
    return (
      <KeyedDropdown
        className="fs-12"
        closeOnSelect
        valueComponent={PhoneComponent}
        ListComponent={PhoneComponent}
        onChange={performChange}
        options={MergeOptions.Phones}
        value={Data.PhoneNumber || "―"}
      />
    );
};

const columns = [
  {
    title: (
      <div className="d-flex align-items-center">
        <FormattedMessage id="ID" />
      </div>
    ),
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: ({ Id }) => Id
  },
  {
    title: (
      <div className="d-flex align-items-center">
        <FormattedMessage id="STATUS" />
      </div>
    ),
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: (Data) => <StatusChanger Data={Data} />
  },
  {
    title: (
      <div>
        <FormattedMessage id="EMAIL" />
      </div>
    ),
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: (Data) => <EmailDropdown Data={Data} />
  },
  {
    title: (
      <div className="d-flex align-items-center">
        <FormattedMessage id={"PHONE_NUMBER"} />
      </div>
    ),
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: (Data) => <PhoneDropdown Data={Data} />
  },
  {
    title: (
      <div className="d-flex align-items-center">
        <FormattedMessage id={"MOBILE_NUMBER"} />
      </div>
    ),
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: (Data) => <MobilePhoneDropdown Data={Data} />
  },
  {
    title: (
      <div className="d-flex align-items-center">
        <FormattedMessage id="ACCOUNTS" />
      </div>
    ),
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: (Data) => {
      if (!Data.Clients) return null;

      if (Data.Clients.length === 1)
        return (
          <ConnectedClientOrigin client={Data.Clients[0]}>
            <ConnectedClientProfile showId client={Data.Clients[0]} />
          </ConnectedClientOrigin>
        );
      return <ConnectedClientProfileStack noAvatar clients={Data.Clients} />;
    }
  }
];

const RowTr = ({ value, ...rest }) => {
  return <Tr value={value} {...rest} />;
};

const Selector = (v) => {
  return v;
};

const ContactsSecondPageContext = React.createContext();

const ContactsMergeNameInput = ({ options, ...props }) => {
  if (options.length === 0)
    return <div className="text-black mt-3 fs-14">―</div>;
  else if (options.length === 1)
    return <div className="text-black fs-14 mt-3">{options[0]}</div>;
  else return <KeyedDropdown {...props} options={options} />;
};

const ContactsMergeSecondPage = ({
  changePage,
  selectedContact,
  SetSelectedContact,
  handleClose,
  handleNext,
  selectedGridContacts,
  handlePrevious,
  dNone
}) => {
  const intl = useIntl();

  const [FinalContact, setFinalContact] = useState({ ...selectedContact });

  const connectedSelectedGridContacts = useMultipleEntityValueSelector(
    spaceContactSchema,
    selectedGridContacts,
    Selector
  );

  const handleTableChanges = (e) => {
    const { name, value } = e;
    setFinalContact((t) => {
      return { ...t, [name]: value };
    });
  };

  const MergeOptions = useMemo(() => {
    let Names = [],
      Emails = [],
      Phones = [],
      Clients = [],
      FullDescription = "",
      Cellphones = [];

    for (const contact of connectedSelectedGridContacts) {
      if (contact.Name) Names.push(contact.Name);
      if (contact.Email) Emails.push(contact.Email);
      if (contact.PhoneNumber) Phones.push(contact.PhoneNumber);
      if (contact.MobileNumber) Cellphones.push(contact.MobileNumber);
      Clients.push(...(contact.Clients || []));
      FullDescription +=
        contact.Description !== "" &&
        contact.Description &&
        FullDescription === ""
          ? contact.Description
          : contact.Description !== "" &&
            contact.Description &&
            FullDescription !== ""
          ? "<br/>" + contact.Description
          : "";
    }

    setFinalContact((t) => {
      return {
        ...t,
        Clients: [...new Set(Clients)].filter((e) => e),
        Description: FullDescription,
        PhoneNumber:
          !t.PhoneNumber || t.PhoneNumber === "" ? Phones[0] : t.PhoneNumber,
        MobileNumber:
          !t.MobileNumber || t.MobileNumber === ""
            ? Cellphones[0]
            : t.MobileNumber,
        Email: !t.Email || t.Email === "" ? Emails[0] : t.Email
      };
    });

    return {
      Names: [...new Set(Names)].filter((e) => e),
      Emails: [...new Set(Emails)].filter((e) => e),
      Phones: [...new Set(Phones)].filter((e) => e),
      Cellphones: [...new Set(Cellphones)].filter((e) => e)
    };
  }, [connectedSelectedGridContacts]);

  const { Name, Image, Description } = FinalContact;

  const handleImageChange = (e) => {
    setFinalContact((t) => {
      return { ...t, Image: e };
    });
  };

  const handleNameChange = (e) => {
    setFinalContact((t) => {
      return { ...t, Name: e };
    });
  };
  const handleDescriptionChange = (e) => {
    setFinalContact((t) => {
      return { ...t, Description: e };
    });
  };

  return (
    <div
      className={classnames("flex-column h-100 of-hidden w-100", {
        "d-none": dNone,
        "d-flex": !dNone
      })}
    >
      <div className="d-flex flex-column of-y-auto">
        <div className="d-flex justify-content-center text-black fs-20 mb-2">
          <FormattedMessage id="CORRESPOND_CONTACT_INFORMATION" />
        </div>
        <div className="d-flex justify-content-center text-black fs-14 mb-4">
          <FormattedMessage id="CORRESPOND_CONTACT_INFORMATION_DESCRIPTION" />
        </div>
        {/* table */}
        <div className="mb-2">
          <div className="d-flex align-items-center fs-16 text-black mb-3">
            <FontAwesomeIcon icon={faArrowsH} className="mr-2" />
            <FormattedMessage id="ASSOCIATIONS_CONTACT_DATA" />
          </div>

          <div className="d-flex mb-3">
            <LabeledInput
              name="Image"
              className="mr-3"
              value={Image}
              onChange={handleImageChange}
              text={intl.formatMessage({ id: "THUMBNAIL" })}
              InputComponent={ProfilePicture}
              uploadText={
                <div className="link-color fs-12 mt-1 mr-3">
                  <FontAwesomeIcon icon={faCamera} />{" "}
                  <FormattedMessage id="UPLOAD_IMAGE" />
                </div>
              }
              placeHolderComponent={
                <AccountProfileImg
                  className="rounded-50"
                  account={{ Name, Image }}
                />
              }
            />
            <LabeledInput
              className="w-450px"
              text={intl.formatMessage({ id: "NAME" })}
              InputComponent={ContactsMergeNameInput}
              closeOnSelect
              onChange={handleNameChange}
              options={MergeOptions.Names}
              value={Name}
            />
          </div>
        </div>
        <ContactsSecondPageContext.Provider
          value={[handleTableChanges, MergeOptions]}
        >
          <div className="Border-Radius-table border flex-0-0-auto of-y-auto">
            <Table
              className="ar-dashboard-table"
              TrComponent={RowTr}
              columns={columns}
              data={[FinalContact]}
            />
          </div>
        </ContactsSecondPageContext.Provider>
        <div className="mt-3">
          <LabeledInput
            text={intl.formatMessage({ id: "NOTES" })}
            InputComponent={DockTyper}
            DisabledmaximizeButton
            onChange={handleDescriptionChange}
            value={Description}
          />
        </div>
      </div>
      <div className="align-items-end d-flex flex-1 justify-content-between mt-3">
        <Button
          className="p-1 mr-3"
          vType="primary-ghost"
          onClick={() => {
            handlePrevious();
          }}
        >
          <span>
            <FormattedMessage id="PREVIOUS" />
          </span>
        </Button>
        <div className="d-flex">
          <Button
            className="p-1 mr-3"
            vType="link-danger"
            onClick={() => {
              handleClose();
            }}
          >
            <span>
              <FormattedMessage id="CANCEL" />
            </span>
          </Button>

          <LoadableButton
            //   isLoading={loading}
            disabled={selectedContact === undefined}
            vType="primary"
            className="p-1"
            onClick={() => {
              handleNext(FinalContact);
            }}
          >
            <span className="mr-2">
              <FormattedMessage id="NEXT" />
            </span>
            <FontAwesomeIcon icon={faChevronRight} />
          </LoadableButton>
        </div>
      </div>
    </div>
  );
};

export default ContactsMergeSecondPage;
