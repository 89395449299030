import React from "react";
import { CSSTransition } from "react-transition-group";
import { useCurrentSidebarItem } from "./SidebarViewsHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import SuspenseErrorBoundary from "../Suspense/SuspenseErrorBoundary";

const SidebarContentItem = React.memo(function SidebarContentItem({
  item,
  closeSidebar,
}) {
  const { Component, props } = item;

  return (
    <div className="ar-sidebar-container">
      <div className="ar-sidebar-close-button" onClick={() => closeSidebar()}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <SuspenseErrorBoundary sidebar>
        {React.cloneElement(Component, props)}
      </SuspenseErrorBoundary>
    </div>
  );
});

const SidebarContent = React.memo(({ tabs, closeSidebar }) => {
  const item = useCurrentSidebarItem(tabs);

  if (!item) return null;

  return <SidebarContentItem item={item} closeSidebar={closeSidebar} />;
});

const SidebarDefaultView = ({
  open,
  onEntered,
  onExited,
  tabs,
  onCloseSidebar,
}) => {
	const resolvedCanOpen = Boolean(open)
  return (
    <CSSTransition
      in={resolvedCanOpen}
      timeout={300}
      appear
      unmountOnExit
      onEntered={onEntered}
      onExited={onExited}
      classNames={{
        appearActive: "active",
        appearDone: "active",
        enterActive: "active",
        enterDone: "active",
      }}
    >
      <SidebarContent tabs={tabs} closeSidebar={onCloseSidebar} />
    </CSSTransition>
  );
};

export default SidebarDefaultView;
