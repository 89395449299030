import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import SourceHandles from "../../Handles/SourceHandles";
import { faGlobe } from "@fortawesome/pro-solid-svg-icons";
import { useSpacePost } from "../../../../../Helpers/IOClient";
import LoaderSpinner from "../../../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import styles from "../AutomationNodes.module.css";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import {
  AutomationIdContext,
  ElementsRefContext
} from "../../AutomationDetails";
import NodeBaseSidebar from "../NodeBaseSidebar";
import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage } from "react-intl";
import Popup from "../../../../../Components/Popup/Popup";
import { copyToClipboard } from "../../../../../Helpers/MiscHelper";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import NodeIconNameContainer from "../NodeIconNameContainer";

const DomainKeyPre = ({ value, wrap }) => {
  const anchorRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    const timeout = setTimeout(() => {
      setIsOpen(false);
      //   onAnchorMouseLeave();
    }, 600);

    return () => clearTimeout(timeout);
  }, [isOpen]);
  return (
    <>
      <Popup
        placement="top"
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
      >
        <div
          className="bg-black text-white px-2 py-1 fs-14 rounded"
          style={{ cursor: "context-menu" }}
        >
          <span>
            <FormattedMessage id="COPIED" />
          </span>
        </div>
      </Popup>
      <div
        ref={anchorRef}
        onClick={(e) => {
          copyToClipboard(value);
          setIsOpen(true);
        }}
        className={classnames(styles.keyPre, {
          [styles.wrap]: wrap
        })}
      >
        <pre className={"p-3"}>{value}</pre>
        <FontAwesomeIcon icon={faCopy} />
      </div>
    </>
  );
};

const useWebhookUrl = (formState, automationId) => {
  const { form, handleChange } = formState;
  const { url, key } = form;
  const isRunned = useRef(false);
  const resolvedUrl = useMemo(() => {
    if (url === "" && key === "") {
      return `Automation/${automationId}/Webhook`;
    } else return null;
  }, [automationId, key, url]);

  const [post, res] = useSpacePost(resolvedUrl, null, {
    onSuccess: ({ data }) => {
      isRunned.current = true;
      const { Url, Key } = data;
      handleChange({
        url: Url,
        key: Key
      });
    },
    onError: ({ error }) => {
      isRunned.current = true;
    }
  });

  return [post, res];
};

const FormContainer = ({
  formState,
  CurrentNodeId,
  handleOnlySave,
  automationId,
  ...props
}) => {
  const [post, { data, loading, error }] = useWebhookUrl(
    formState,
    automationId
  );
  const { form } = formState;
  const { url } = form;
  useEffect(() => {
    if (url === "") post();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) handleOnlySave();
  }, [data, handleOnlySave, url]);

  if (loading || error) {
    return <LoaderSpinner center size="sm" className="text-primary h-100" />;
  }

  return <FormContainerBase formState={formState} {...props} />;
};

const FormContainerBase = ({ formState, id, canEdit }) => {
  const { form } = formState;
  const { url } = form;

  return (
    <div>
      <div className="text-black mb-3">Webhook URL - Post</div>
      <DomainKeyPre value={url} wrap />
    </div>
  );
};

const settings = {
  defaultForm: {
    url: "",
    key: ""
  },
  FormContent: (props) => {
    return <FormContainer {...props}></FormContainer>;
  }
};

const WebhookTrigger = React.memo(({ selected, data, id, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);
  const automationId = useContext(AutomationIdContext);
  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        updater={updater}
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        automationId={automationId}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };
  const { enabled, config } = data;
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer type={type} data={data} id={id}>
        <NodeIconNameContainer
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleClick}
          selected={selected}
          icon={faGlobe}
          text={"WebHook"}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default WebhookTrigger;
