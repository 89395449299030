import React from "react";
import { Switch, withRouter, Route } from "react-router-dom";

// import {
//   RealTimePageEnum,
//   RealTimePageProvider
// } from "../RealTime/RealTimePages";
import DealsOverview from "./DealsOverview/DealsOverview";
import DealDetails from "./DealDetails/DealDetails";
import { RealTimePageEnum } from "../RealTime/RealTimePages";
import {
  useEnterViewSignalR,
  PageTypeProvider
} from "../../Components/EntitySummaries/EntitySummariesHelper";
// import { TestDealReadOverview } from "./DealSummary";

const Deals = withRouter(({ match }) => {
  // useEnterViewSignalR(RealTimePageEnum.Deal)

  return (
    <PageTypeProvider pageType={RealTimePageEnum.Deal}>
      <Switch>
        <Route path={`${match.url}/:dealId`}>
          <DealDetails />
        </Route>
        <Route>
          {/* <RealTimePageProvider pageType={RealTimePageEnum.Deal}> */}
          <DealsOverview />
          {/* </RealTimePageProvider> */}
        </Route>
      </Switch>
    </PageTypeProvider>
  );
});

export default Deals;
