import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import classnames from "classnames";
import classes from "./InfoBars.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faRocketLaunch,
  faTimes
} from "@fortawesome/pro-regular-svg-icons";
import { useMaintenanceState } from "../../Containers/Maintenance/Maintenance";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { useSpace } from "../../Contexts/SpaceContext";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { isOwner } from "../../Helpers/ModulesHelper";
import { AccountSpace, ViewsEnum } from "../../Interfaces/EntityInterfaces";
import { Link } from "react-router-dom";
import { AppViewContext } from "../../Containers/AppBar/AppBarHelper";

export const InfoBarTypes = {
  Warning: "Warning",
  Info: "Info",
  PaymentFail: "PaymentFail",
  Trial: "Trial"
};

type Keys = keyof typeof InfoBarTypes;
type InfobarTypeValues = typeof InfoBarTypes[Keys];

interface InfoBarsI {
  type: InfobarTypeValues;
  content?: any;
  extraAction?: () => {};
}

const InfoBars: FC<InfoBarsI> = ({ type, content, extraAction }) => {
  const [isShowing, setIsShowing] = useState(true);

  const toggleShow = () => {
    if (extraAction) extraAction();
    setIsShowing(false);
  };

  if (!isShowing) return null;

  return (
    <div
      className={classnames(
        classes.InforBarMaster,
        classes[`info-bar-${type}`],
        "disable-selection d-flex position-relative justify-content-between align-items-center"
      )}
    >
      <div
        className={classnames(
          "d-flex align-items-center flex-1 justify-content-center",
          classes.infoBarContent
        )}
      >
        {content ? content : <InfoBarContent type={type} />}
      </div>

      {
        <div className={classes.infoBarClose} onClick={toggleShow}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      }
    </div>
  );
};

const InfoBarMaintenanceContent: FC = () => {
  const { data } = useMaintenanceState() || {};
  const { ScheduleMessage } = data;

  const intl = useIntl();

  const { ScheduleMessageDate, ScheduleMessageInterval } =
    ScheduleMessage || {};
  moment().locale(intl.locale);
  const mDate = moment(ScheduleMessageDate);
  const date = mDate.format("DD/MM/YYYY HH:mm");

  const fromNow = mDate.fromNow(true);

  const minutes = ScheduleMessageInterval / 60 / 1000;

  return (
    <div className="d-flex align-items-center justify-content-center mx-4">
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <span className="ml-3 text-truncate">
        {!ScheduleMessage
          ? intl.formatMessage({ id: "MAINTENANCE_ONGOING_DESC" })
          : intl.formatMessage(
              { id: "MAINTENANCE_WARNING_DESC" },
              { fromNow, date, minutes }
            )}
      </span>
    </div>
  );
};

const InfoBarPaymentFailContent: FC = () => {
  const space = useSpace();
  const intl = useIntl();
  const timeLeft = moment(space.PaymentFail.CancelDate).diff(moment(), "days");

  return (
    <Link to={`/s/${space.Id}/workspace/paymentMethods`} className="w-100">
      <div className="d-flex align-items-center justify-content-center mx-4">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <span className="ml-3 text-truncate">
          {`${intl.formatMessage({
            id: "UPDATE_PAYMENT_METHOD_WARNING_PART_ONE"
          })} ${timeLeft} ${intl.formatMessage({
            id: "UPDATE_PAYMENT_METHOD_WARNING_PART_TWO"
          })}`}
        </span>
      </div>
    </Link>
  );
};

const TrialBarContent = () => {
  const intl = useIntl();
  const space = useSpace();
  const currentAccount = useCurrentAccountSpace() as AccountSpace;
  if (!space.TrialEnd || !isOwner(currentAccount)) {
    return <div></div>;
  }
  const timeLeft = moment(space.TrialEnd).diff(moment(), "days");

  return (
    <Link to={`/s/${space.Id}/plans`} className="w-100">
      <div className="d-flex align-items-center justify-content-center">
        <FontAwesomeIcon icon={faRocketLaunch} />
        <span className="ml-3 text-truncate">
          {`${timeLeft} ${intl.formatMessage({
            id: "FREE_TRIAL_DAYS_LEFT"
          })}`}
        </span>
      </div>
    </Link>
  );
};

const InfoBarContent: FC<InfoBarsI> = ({ type }) => {
  switch (type) {
    case InfoBarTypes.PaymentFail:
      return <InfoBarPaymentFailContent />;

    case InfoBarTypes.Trial:
      return <TrialBarContent />;

    case InfoBarTypes.Warning:
      return <InfoBarMaintenanceContent />;

    default:
      return null;
  }
};

function setCookie(name: string, value: string, days: number) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const InfoBarController: FC = () => {
  const [infoBarStates, setInfoBarStates] = useState({
    Warning: false,
    Trial: false,
    PaymentFail: false,
    Info: false
  });

  const InfoContentRef = useRef<any>(null);
  const ExtraActionRef = useRef<any>(null);

  const { data, loading } = useMaintenanceState() || {};

  useEffect(() => {
    if (!data && loading) return;
    if (!data && !loading) return;
    const { CurrentlyInMaintenance, ScheduleMessage } = data || {};
    if (!CurrentlyInMaintenance && !ScheduleMessage) return;
    setInfoBarStates((oldT) => {
      const newT = { ...oldT };
      newT.Warning = true;
      return newT;
    });
  }, [data, loading]);

  const space = useSpace();
  const currentAccount = useCurrentAccountSpace() as AccountSpace;

  useEffect(() => {
    if (space.TrialEnd && isOwner(currentAccount)) {
      setInfoBarStates((oldT) => {
        const newT = { ...oldT };
        newT.Trial = true;
        return newT;
      });
    }
  }, [currentAccount, space.TrialEnd]);

  useEffect(() => {
    if (space.PaymentFail && !isOwner(currentAccount)) {
      setInfoBarStates((oldT) => {
        const newT = { ...oldT };
        newT.PaymentFail = true;
        return newT;
      });
    }
  }, [currentAccount, space.PaymentFail]);

  const [viewState, toggleViewState] = useContext(AppViewContext);

  /*   useEffect(() => {
    switch (viewState) {
      case ViewsEnum.basic:
        const horiCookie = getCookie("air-horizontal-bar-cookie");
        ExtraActionRef.current = () => {
          setCookie("air-horizontal-bar-cookie", "958349", 8);
        };
        if (horiCookie === null) {
          InfoContentRef.current = (
            <div
              className="cursor-pointer"
              onClick={() => {
                typeof toggleViewState === "function" &&
                  toggleViewState(ViewsEnum.advanced);
              }}
            >
              <FormattedMessage id="INFO_NEW_VERTICAL_BAR_ALERT_FROM_HORIZONTAL" />
            </div>
          );
          setInfoBarStates((oldT) => {
            const newT = { ...oldT };
            newT.Info = true;
            return newT;
          });
        }

        break;
      case ViewsEnum.advanced:
        const veriCookie = getCookie("air-vertical-bar-cookie");
        if (veriCookie === null) {
          ExtraActionRef.current = () => {
            setCookie("air-vertical-bar-cookie", "958349", 5343);
          };
          InfoContentRef.current = (
            <FormattedMessage id="INFO_NEW_VERTICAL_BAR_ALERT_FROM_VERTICAL" />
          );
          setInfoBarStates((oldT) => {
            const newT = { ...oldT };
            newT.Info = true;
            return newT;
          });
        }
        break;
      default:
        break;
    }
  }, [toggleViewState, viewState]); */

  return (
    <>
      {infoBarStates.Info && (
        <InfoBars
          extraAction={ExtraActionRef.current}
          content={InfoContentRef.current}
          type={InfoBarTypes.Info}
        />
      )}
      {infoBarStates.Warning && <InfoBars type={InfoBarTypes.Warning} />}
      {infoBarStates.Trial && <InfoBars type={InfoBarTypes.Trial} />}
      {infoBarStates.PaymentFail && (
        <InfoBars type={InfoBarTypes.PaymentFail} />
      )}
    </>
  );
};

export default InfoBarController;
