import React, { useContext, useEffect, useRef, useState } from "react";

export const OneSginalPlayerIdContext = React.createContext();

export const UseOneSignalPlayerId = () => {
  const PlayerId = useContext(OneSginalPlayerIdContext);
  return PlayerId;
};

// const defaultPlayerId = localStorage.getItem('onesignal_player_id') || undefined

export const OneSignalPlayerIdProvider = ({ children }) => {
  const playerIdRef = useRef(null);

  const [playerId, setPlayerId] = useState(null);

  useEffect(() => {
    if (window.OneSignal)
      window.OneSignal.push(async function () {
        const id = await window.OneSignal.getUserId();
        if (typeof id === "string") {
          // localStorage.setItem('onesignal_player_id', id)
          playerIdRef.current = id;
          setPlayerId(id);
        }
      });
  }, []);

  return (
    // <OneSginalPlayerIdContext.Provider value={playerIdRef}>
    <OneSginalPlayerIdContext.Provider value={playerId}>
      {children}
    </OneSginalPlayerIdContext.Provider>
  );
};
