import React from "react";
import Popup from "./Popup";
import classnames from "classnames";

const InfoPopup = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Popup
      modifiers={{
        preventOverflow: { boundariesElement: "viewport" },
      }}
      {...rest}
    >
      <div
        className={classnames(
          "bg-black text-white px-2 py-1 fs-14 rounded",
          className
        )}
        style={{ maxWidth: 520 }}
      >
        {children}
      </div>
    </Popup>
  );
};

export default InfoPopup;
