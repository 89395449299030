import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const TicketOverviewComp = React.lazy(() =>
  import(/* webpackChunkName: "tickets-overview" */ "./TicketOverviewComp")
);

const TicketOverview = () => {
  return (
    <SuspenseErrorBoundary>
      <TicketOverviewComp />
    </SuspenseErrorBoundary>
  );
};

export default TicketOverview;
