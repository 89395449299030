import { useEffect } from "react";
import { useEntitiesHub } from "./RealTime";
import { RealTimeRoomTypes } from "./RealTimeHelper";
import {
  spaceCallsSchema,
  spaceClientSchema,
  spaceContactSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceProjectsSchema,
  spaceSubscriptionSchema,
  spaceTasksSchema,
  spaceTicketsSchema,
  spaceInterventionsSchema,
  spaceBillingSchema,
  spaceSocialPostsSchema,
  spaceEmailSignatureSchema,
  spaceOrganizationSchema,
  spaceDepartmentSchema,
  spaceTeamSchema,
} from "../../config/schema";
import { updateNormalizedSchemaCache, getEntity } from "../../Helpers/IOClient";
import { Communicator } from "../../Helpers/Communicator";
import { normalize } from "../../Helpers/SchemaHelper";

const mappings = {
  [RealTimeRoomTypes.Call]: spaceCallsSchema,
  [RealTimeRoomTypes.Client]: spaceClientSchema,
  [RealTimeRoomTypes.Contact]: spaceContactSchema,
  [RealTimeRoomTypes.Contract]: spaceContractSchema,
  [RealTimeRoomTypes.Deal]: spaceDealsSchema,
  [RealTimeRoomTypes.Project]: spaceProjectsSchema,
  [RealTimeRoomTypes.Subscription]: spaceSubscriptionSchema,
  [RealTimeRoomTypes.Task]: spaceTasksSchema,
  [RealTimeRoomTypes.Ticket]: spaceTicketsSchema,
  [RealTimeRoomTypes.TimeCharge]: spaceInterventionsSchema,
  [RealTimeRoomTypes.Billing]: spaceBillingSchema,
  [RealTimeRoomTypes.SocialPost]: spaceSocialPostsSchema,
  [RealTimeRoomTypes.EmailSignature]: spaceEmailSignatureSchema,
  [RealTimeRoomTypes.Company]: spaceOrganizationSchema,
  [RealTimeRoomTypes.Department]: spaceDepartmentSchema,
  [RealTimeRoomTypes.Team]: spaceTeamSchema,
};

export const EntityUpdateCommunicator = new Communicator();

const RealTimeEntityUpdater = ({ children }) => {
  const entitiesHub = useEntitiesHub();

  useEffect(() => {
    if (!entitiesHub) return;

    const listner = (type, id, data, userId, isCreation) => {

      if (isCreation) return;

      const schema = mappings[type];
      if (!schema) return;

      const parseData = data ? JSON.parse(data) : undefined;
      
      if (Array.isArray(parseData)) {
        const rData = parseData.map((v) => ({ ...v, HasUpdates: true }));

        const normalized = normalize(rData, [schema]);

        const update = normalized.entities;

        updateNormalizedSchemaCache(update);
      } else {
        const normalized = normalize(
          parseData ? { ...parseData, HasUpdates: true } : { Id: id, HasUpdates: true },
          schema
        );

        const update = normalized.entities;

        const entityToUpdate = getEntity(schema, id);

        updateNormalizedSchemaCache(update);

        const updatedEntity = getEntity(schema, id);
        //;
        EntityUpdateCommunicator.dispatch(
          schema,
          id,
          entityToUpdate,
          updatedEntity,
          isCreation
        );
      }
    };

    entitiesHub.on("EntityUpdate", listner);

    return () => entitiesHub.off("EntityUpdate", listner);
  }, [entitiesHub]);

  return children;
};

export default RealTimeEntityUpdater;
