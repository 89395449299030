import React from "react";
import withSpaceEntityForm from "../../Components/Forms";
import { spaceInviteSchema } from "../../config/schema";
import SideMenuForm from "../../Components/Forms/SideMenuForm";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import { FormattedMessage } from "react-intl";
import { isEmail } from "../../Helpers/MiscHelper";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent
} from "../../Components/Sidebar/SidebarV2Helper";

const InviteForm = ({ formStatus, setFormState, form }) => {
  const handleChange = e => {
    const { name, value } = e.target;
    setFormState({
      [name]: value
    });
  };

  const { email } = form;
  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>Adicionar Utilizador</BaseSidebarHeader>
      <BaseSidebarContent>
        <SideMenuForm formStatus={formStatus}>
          <FormInput
            name="email"
            type="email"
            text={<FormattedMessage id="email" />}
            value={email}
            onChange={handleChange}
          />
        </SideMenuForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export default withSpaceEntityForm(InviteForm, {
  schema: spaceInviteSchema,
  shouldNormalize: false,
  validateForm: (form, addError) => {
    const { email } = form;
    if (!isEmail(email))
      addError("email", <FormattedMessage id="form_invalid_email" />);
  },
  defaultForm: {
    email: ""
  }
});
