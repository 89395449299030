import React from "react";
import InterventionOrigin, {
  ConnectedInterventionOrigin
} from "../Origin/InterventionOrigin";
import BreadcrumbsPopup from "../../Components/Breadcrumbs/BreadcrumbsPopup";
import { originTypes } from "../../Helpers/MiscHelper";
import { InterventionIcon } from "../../Components/Common/EntityIcons";

const ClassificationTimechargePreview = ({ id, intervention }) => {
  return (
    <div className="d-flex">
      <BreadcrumbsPopup
        className="pr-2 text-primary"
        originType={originTypes.timecharge}
        id={id}
      />
      {intervention ? (
        <InterventionOrigin intervention={intervention}>
          <InterventionIcon />
          <span className="ml-1">{id}</span>
        </InterventionOrigin>
      ) : (
        <ConnectedInterventionOrigin intervention={id}>
          <InterventionIcon />
          <span className="ml-1">{id}</span>
        </ConnectedInterventionOrigin>
      )}
    </div>
  );
};

export default ClassificationTimechargePreview;
