import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import AccountProfile from "../../../Components/Accounts/AccountProfile";
import ContactProfile from "../../../Components/Contacts/ContactProfile";
import FileUploader, {
  ComponentElementByExtension
} from "../../../Components/FileUploader/FileUploader";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import RelativeTime from "../../../Components/RelativeTime/RelativeTime";
import TextEllipsis from "../../../Components/TextEllipsis/TextEllipsis";
import { useSpace } from "../../../Contexts/SpaceContext";
import { useQuery } from "../../../Helpers/IOClient";
import { DashboardTable } from "../../Dashboard/Dashboard";
import { SummaryAttachmentsPreview } from "../ReceptionComp";
import { validadeFilePreview } from "../../../Components/FileUploader/EmailPreview";
import { downloadFile } from "../EmailChatComponent";

export const ReceptionEmailAttachments = () => {
  const params = useParams();
  const { id } = params;
  const space = useSpace();
  const { data, loading, error } = useQuery(
    `Spaces/${space.Id}/EmailConversation/${id}/Files`,
    null,
    {
      cache: false
    }
  );

  if (loading || error)
    return (
      <div>
        <LoaderSpinner size="sm" center />
      </div>
    );

  if (data && data.length === 0) {
    return (
      <div className="d-flex align-items-center justify-content-center w-100 mt-3 text-black fw-medium">
        <FormattedMessage id="NO_ATTACHMENTS" />
      </div>
    );
  }

  return (
    <FileUploader value={data} enableSwapper preview />

    // <DashboardTable
    //   basic
    //   className="ar-dashboard-table"
    //   columns={columns}
    //   data={data}
    // />
  );
};

const NameColumn = ({ Data }) => {
  const SummaryAttachmentsPreviewRef = useContext(SummaryAttachmentsPreview);

  const { Name, Extension, Url } = Data;

  const handleNameClick = () => {
    if (
      validadeFilePreview(Data) &&
      SummaryAttachmentsPreviewRef.current &&
      typeof SummaryAttachmentsPreviewRef.current === "function"
    ) {
      SummaryAttachmentsPreviewRef.current(Data);
    } else {
      downloadFile(Url, Name);
    }
  };

  return (
    <span onClick={handleNameClick} className="text-black cursor-pointer">
      <TextEllipsis
        text={Name.substring(0, Name.length - Extension.length)}
        length={10}
        span={true}
        truncate
        beforeEnd={3}
        afterText={Extension}
      />
    </span>
  );
};

const columns = [
  {
    title: <FormattedMessage id={"TYPE"} />,
    Cell: ({ Extension }) => (
      <span>
        <ComponentElementByExtension extension={Extension} />
      </span>
    )
  },
  {
    title: <FormattedMessage id={"NAME"} />,
    Cell: (data) => <NameColumn Data={data} />
  },
  {
    title: <FormattedMessage id={"DATE"} />,
    Cell: ({ Date }) => <RelativeTime date={Date} />
  },
  {
    title: <FormattedMessage id={"SENT_BY"} />,
    Cell: ({ Account, Contact }) => {
      if (Account) {
        return <AccountProfile account={Account} />;
      } else if (Contact) {
        return <ContactProfile contact={Contact} />;
      } else return <div></div>;
    }
  }
];

export const ReceptionNotesAttachments = () => {
  const params = useParams();
  const { id } = params;
  const space = useSpace();
  const { data, loading, error } = useQuery(
    `Spaces/${space.Id}/EmailConversation/${id}/ChatFiles`,
    null,
    {
      cache: false
    }
  );

  if (loading || error)
    return (
      <div>
        <LoaderSpinner size="sm" center />
      </div>
    );

  if (data && data.length === 0) {
    return (
      <div className="d-flex align-items-center justify-content-center w-100 mt-3 text-black fw-medium">
        <FormattedMessage id="NO_ATTACHMENTS" />
      </div>
    );
  }

  return (
    <FileUploader value={data} enableSwapper preview />
    // <DashboardTable
    //   basic
    //   className="ar-dashboard-table"
    //   columns={columns}
    //   data={data}
    // />
  );
};
