import React, { useState } from "react";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import { handleError } from "../../../Helpers/MiscHelper";
import { withSpace } from "../../../Contexts/SpaceContext";
import withSpaceEntityForm from "../../../Components/Forms";
import SideMenuForm from "../../../Components/Forms/SideMenuForm";
import { SpaceDocFoldersSchema } from "../../../config/schema";
import { OrganizationDropdown } from "../../AdvancedMultiInputs";
import Button from "../../../Components/Button/Button";
import LoadableButton from "../../../Components/Button/LoadableButton";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";

const DocFoldersForm = ({ setFormState, refetchRef, ...props }) => {
  const { formStatus } = props;
  const { form } = formStatus;
  // eslint-disable-next-line no-unused-vars
  const { Id, Name, Organizations, CanDelete } = form;
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"FOLDERS"} />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideMenuForm
          formStatus={formStatus}
          footerComponent={
            !Id ? (
              <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
                <div></div>
                <LoadableButton
                  isLoading={formStatus.isProcessing}
                  onClick={formStatus.submit}
                >
                  <FormattedMessage id={"CONFIRM"} />
                </LoadableButton>
              </div>
            ) : !isDeleting ? (
              <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
                {CanDelete ? (
                  <Button
                    vType="link-danger fw-bold"
                    className="mr-3"
                    onClick={() => setIsDeleting(true)}
                  >
                    <span style={{ fontWeight: 500 }}>
                      <FormattedMessage id="DELETE" />
                    </span>
                  </Button>
                ) : (
                  <div></div>
                )}
                <LoadableButton
                  isLoading={formStatus.isProcessing}
                  onClick={formStatus.submit}
                >
                  <FormattedMessage id={"CONFIRM"} />
                </LoadableButton>
              </div>
            ) : (
              CanDelete && (
                <RemovalFooter
                  endpoint={`Folders/0/${Id}`}
                  refetchRef={refetchRef}
                  onCancel={() => setIsDeleting(false)}
                />
              )
            )
          }
        >
          <FormInput
            name="Organizations"
            textId="COMPANIES"
            multiple
            inputType={OrganizationDropdown}
            className="mb-3"
          />

          <FormInput name="Name" textId="NAME" className="mb-3" />
        </SideMenuForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export default withSpaceEntityForm(withSpace(injectIntl(DocFoldersForm)), {
  defaultForm: {
    Name: "",
    Organizations: []
  },
  onSuccess: ({ createToast }) => {
    createToast({
      pos: "tm",
      type: "success",
      description: <FormattedMessage id="FOLDER_SAVE_SUCCESS" />
    });
  },
  onError: ({ error, createToast }) => {
    try {
      const parsedError = JSON.parse(error.data);
      handleError(createToast, parsedError);
    } catch (e) {
      handleError(createToast, error);
    }
  },
  validateForm: (form, addError) => {
    const { Organizations, Name } = form;

    if (Organizations.length === 0) addError("Organizations");
    if (!Name) addError("Name");
  },
  formatForm: (form) => {
    return form;
  },
  schema: SpaceDocFoldersSchema
  // getEntityId: (state, ownProps) => ownProps.departmentId,
});

const RemovalFooter = ({ endpoint, refetchRef, onCancel }) => {
  const createToast = useToast();
  const intl = useIntl();
  const handleTranslatedError = useHandleError(SpaceDocFoldersSchema);
  const [deleteUser, { loading }] = useSidebarSpaceDelete(
    endpoint,
    SpaceDocFoldersSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "FOLDER" })}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  return (
    <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
      <div className="text-black">
        <FormattedMessage id="ELIMINATE_DEPARTMENT_CONFIRMATION" />
      </div>

      <div className="d-flex align-items-center">
        <Button
          type="button"
          vType="outline-danger"
          className="mr-3"
          onClick={onCancel}
        >
          <FormattedMessage id="BACK" />
        </Button>

        <LoadableButton
          type="button"
          vType="danger"
          isLoading={loading}
          onClick={() => {
            deleteUser();
          }}
        >
          <FormattedMessage id="DELETE" />
        </LoadableButton>
      </div>
    </div>
  );
};
