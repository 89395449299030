import React, { useCallback } from "react";
import { useGridRequest } from "../../../Components/CGrid/ServerGrid";
import EmptyListError from "../../../Components/EmptyListContainer/EmptyListError";
import { useBilling } from "../../../Hooks/EntityHooks";
import EmptyListContainer from "../../../Components/EmptyListContainer/EmptyListContainer";
import { VirtualGridRow } from "../../../Components/VirtualGrid/VirtualGrid";
import VirtualServerAwareGrid from "../../../Components/VirtualGrid/VirtualServerAwareGrid";
import { moduleTypes } from "../../../Helpers/ModulesHelper";
import { bilingColumns } from "./BillingOverviewComp";
import { FormattedMessage } from "react-intl";

const BillingRow = React.memo(({ value, ...rest }) => {
  const Billing = useBilling(value);

  // const canSelectRow = useCallback(v => !v.Billing, []);
  const canSelectRow = useCallback((v) => !v.Billing, []);
  return (
    <VirtualGridRow
      formType={moduleTypes.billing}
      value={Billing}
      {...rest}
      canSelectRow={canSelectRow}
    />
  );
});
const canSelectRow = (value) => {
  if (!value) return false;

  return true;

  // const state = store.getState();

  // const intervention = getEntityFromState(
  //   state,
  //   spaceInterventionsSchema,
  //   value
  // );

  // return !intervention.Billing;
};

const BillingGrid = ({ onItemSelect, ...rest }) => {
  const { error, results } = useGridRequest();


  if (error) return <EmptyListError />;

  if (results && results.length === 0)
    return (
      <EmptyListContainer type="interventions">
        <div className="fw-bold fs-24 text-black ">
          <FormattedMessage id="EMPTY_BILLING_LIST" />
        </div>
        <div className="fs-18 text-black text-center mb-4">
          <FormattedMessage id="NO_BIILING_LIST" />
        </div>
        {/* <InterventionFormButton>Adicionar intervenção</InterventionFormButton> */}
      </EmptyListContainer>
    );

  return (
    <VirtualServerAwareGrid
      sortable
      //   showTotals
      canSelectRow={canSelectRow}
      onSelectedItemsChange={onItemSelect}
      rowComponent={BillingRow}
      columns={bilingColumns}
      {...rest}
    />
  );
};

export default BillingGrid;
