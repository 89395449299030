import React from "react";
import TriggerCreation from "./TriggerCreation";
import { spaceClassificationSchema } from "../../../../../config/schema";
import { faClipboardCheck } from "@fortawesome/pro-solid-svg-icons";
import { useIntl } from "react-intl";


// const PreviewTarget = ({ val }) => <ConnectedCallOrigin call={val} />;

const TriggerClassificationCreation = React.memo(({ ...props }) => {
  const intl = useIntl();
  return (
    <TriggerCreation
      {...props}
      // TargetPreview={PreviewTarget}
      schema={spaceClassificationSchema}
      icon={faClipboardCheck}
      text={intl.formatMessage({ id: "CLASSIFICATION" })}
    />
  );
});

export default TriggerClassificationCreation;
