import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { ConnectedClientProfileStack } from "../../../Components/Accounts/AccountProfileStack";
import Button from "../../../Components/Button/Button";
import LoadableButton from "../../../Components/Button/LoadableButton";
import { ConnectedClientProfile } from "../../../Components/Clients/ClientProfile";
import {
  ConnectedContactProfile,
  ContactStatusProfile
} from "../../../Components/Contacts/ContactProfile";
import Radio from "../../../Components/Radio/Radio";
import Table, { Tr } from "../../../Components/Table";
import { PhoneColumn } from "../../../Components/VirtualGrid/VirtualGridColumns";
import { useContact } from "../../../Hooks/EntityHooks";

import { ConnectedClientOrigin } from "../../Origin/ClientOrigin";
import classnames from "classnames";
const ColumnRadioButton = ({ Data, Id }) => {
  const [selectedContact, SetSelectedContact] = useContext(
    RadioButtonFuntionsContext
  );

  const isSelected = useMemo(() => {
    const selectedId = selectedContact ? selectedContact.Id : undefined;
    return Id === selectedId;
  }, [Id, selectedContact]);

  const handleRadioChange = useCallback(() => {
    SetSelectedContact(Data);
  }, [Data, SetSelectedContact]);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Radio checked={isSelected} change={handleRadioChange} />
      {/* {!isSelected && <Radio defaultChecked={false} change={handleRadioChange} />} */}
    </div>
  );
};

const columns = [
  {
    title: "",
    minWidth: 100,
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    width: 100,
    Cell: (Data) => <ColumnRadioButton Data={Data} Id={Data.Id} />
  },
  {
    title: (
      <div className="d-flex align-items-center">
        <FormattedMessage id="NAME" />
      </div>
    ),

    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: ({ Id }) => <ConnectedContactProfile contact={Id} />
  },
  {
    title: (
      <div className="d-flex align-items-center">
        <FormattedMessage id="ID" />
      </div>
    ),
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: ({ Id }) => Id
  },
  {
    title: (
      <div className="d-flex align-items-center">
        <FormattedMessage id="STATUS" />
      </div>
    ),
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: (Data) => {
      return <ContactStatusProfile entity={Data} />;
    }
  },
  {
    title: (
      <div>
        <FormattedMessage id="EMAIL" />
      </div>
    ),
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: ({ Email }) => Email || "―"
  },
  {
    title: (
      <div className="d-flex align-items-center">
        <FormattedMessage id={"PHONE_NUMBER"} />
      </div>
    ),
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: ({ PhoneNumber }) => {
      return <PhoneColumn phoneNumber={PhoneNumber} />;
    }
  },
  {
    title: (
      <div className="d-flex align-items-center">
        <FormattedMessage id={"MOBILE_NUMBER"} />
      </div>
    ),
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: ({ MobileNumber }) => {
      return <PhoneColumn phoneNumber={MobileNumber} />;
    }
  },
  {
    title: (
      <div className="d-flex align-items-center">
        <FormattedMessage id="ACCOUNTS" />
      </div>
    ),
    titleClass: "Bg-HeaderBlueCollumn py-2  px-3",
    cellClass: "px-3",
    Cell: (Data) => {
      if (!Data.Clients) return null;

      if (Data.Clients.length === 1)
        return (
          <ConnectedClientOrigin client={Data.Clients[0]}>
            <ConnectedClientProfile showId client={Data.Clients[0]} />
          </ConnectedClientOrigin>
        );
      return <ConnectedClientProfileStack noAvatar clients={Data.Clients} />;
    }
  }
];

const RadioButtonFuntionsContext = React.createContext();

const RowTr = ({ value, ...rest }) => {
	const contact = useContact(value);
	// eslint-disable-next-line no-unused-vars
	const [selectedContact, SetSelectedContact] = useContext(
    RadioButtonFuntionsContext
  );
	const handleRadioChange = useCallback(() => {
    SetSelectedContact(contact);
  }, [contact, SetSelectedContact]);

  return <Tr value={contact} className="cursor-pointer" onClick={handleRadioChange} {...rest} />;
};

const ContactsMergeFirstPage = ({
  changePage,
  selectedContact,
  SetSelectedContact,
  handleClose,
  selectedGridContacts,
  handleNext,
  dNone
}) => {
  return (
    <RadioButtonFuntionsContext.Provider
      value={[selectedContact, SetSelectedContact]}
    >
      <div
        className={classnames("flex-column h-100 of-hidden w-100", {
          "d-none": dNone,
          "d-flex": !dNone
        })}
      >
        <div className="d-flex justify-content-center text-black fs-20 mb-2">
          <FormattedMessage id="SELECT_PRIMARY_CONTACT" />
        </div>
        <div className="d-flex justify-content-center text-black fs-14 mb-4">
          <FormattedMessage id="SELECT_PRIMARY_CONTACT_DESCRIPTION" />
        </div>
        <div className="Border-Radius-table border of-y-auto">
          <Table
            className="ar-dashboard-table ar-contacts-merge-table"
            TrComponent={RowTr}
            columns={columns}
            data={selectedGridContacts}
          />
        </div>
        <div className="align-items-end d-flex flex-1 justify-content-end mt-3">
          <Button
            className="p-1 mr-3"
            vType="link-danger"
            onClick={() => {
              handleClose();
            }}
          >
            <span>
              <FormattedMessage id="CANCEL" />
            </span>
          </Button>
          <LoadableButton
            //   isLoading={loading}
            disabled={selectedContact === undefined}
            vType="primary"
            className="p-1"
            onClick={() => {
              handleNext();
            }}
          >
            <span className="mr-2">
              <FormattedMessage id="NEXT" />
            </span>
            <FontAwesomeIcon icon={faChevronRight} />
          </LoadableButton>
        </div>
      </div>
    </RadioButtonFuntionsContext.Provider>
  );
};

export default ContactsMergeFirstPage;
