import classnames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../../../Components/Button/Button";
import LoadableButton from "../../../../Components/Button/LoadableButton";
import styles from "./AutomationNodes.module.css";

export const AutomationSidebarHeader = ({
  title,
  CurrentNodeId,
  formState,
  testLoading,
  baseRequest,
  automationTestClick
}) => {
  const handleClick = () => {
    if (automationTestClick)
      automationTestClick(CurrentNodeId, {
        form: formState.form.form,
        config: formState.form.config
      });
  };

  return (
    <div
      className={classnames(
        styles.AutomationSidebarHeader,
        "d-flex align-items-center justify-content-between"
      )}
    >
      <div className="text-black fs-18">
        {/* {title} */}
        <FormattedMessage id="SETTINGS" />
      </div>
      <LoadableButton
        isLoading={testLoading}
        onClick={handleClick}
        type="button"
        vType={"primary-ghost"}
      >
        <FormattedMessage id="TEST" />
      </LoadableButton>
    </div>
  );
};

export const AutomationSidebarFooter = ({ onClick }) => {
  return (
    <div
      className={classnames(
        styles.AutomationSidebarFooter,
        "d-flex align-items-center justify-content-end"
      )}
    >
      <Button onClick={onClick}>
        <FormattedMessage id="SAVE" />
      </Button>
    </div>
  );
};

export const AutomationSidebar = () => {
  return <div></div>;
};
