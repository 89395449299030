import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Uppload,
  en,
  Facebook,
  URL,
  Twitter,
  Screenshot,
  LinkedIn
} from "uppload";
import "uppload/dist/uppload.css";
import "uppload/dist/themes/light.css";
import Modal from "../Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as UnsplashLogo } from "../../Assets/Svg/unsplash_logo.svg";
import { ReactComponent as GiphyLogo } from "../../Assets/Svg/giphy.svg";
import "./FileUploader.css";
import { faGlobe } from "@fortawesome/pro-solid-svg-icons";
import UnsplashReact, { BlobUploader, withDefaultProps } from "unsplash-react";
import { faRecordVinyl } from "@fortawesome/pro-regular-svg-icons";
import InformationComponent from "../InformationComponent/InformationComponent";
import { useIntl } from "react-intl";
import ReactGiphySearchBox from "../GiphyComponent";
import { useToast } from "../Toast/ToastProvider";
// import ReactGiphySearchBox from "../GiphyComponent";
const uppload = new Uppload({
  lang: en,
  hideHelp: true,
  customClass: "ar-custom-upploader",
  uploader: (file, updateProgress) =>
    new Promise((resolve) => {
      resolve(file);
    })
});
uppload.use([
  new Facebook(),
  new URL(),
  new Twitter(),
  new Screenshot(),
  new LinkedIn()
]);

document
  .getElementsByClassName("uppload-modal")[0]
  .insertAdjacentHTML(
    "beforeend",
    `<div  id="air-uppload-modal-extra-close" class="ar-modal-preview-file-content-close-button text-color-link"><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" class="svg-inline--fa fa-times fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg></div>`
  );

document
  .getElementById("air-uppload-modal-extra-close")
  .addEventListener("click", close);

function close() {
  uppload.close();
}

export const UpploadReact = ({ onFileDone, Chat = false, settings = {} }) => {
  useEffect(() => {
    uppload.on("upload", (file) => {
      if (/[.][\S]+$/.test(file.name)) {
        onFileDone(file);
        uppload.close();
      } else {
        let name = file.name;
        const blob = file.slice(0, file.size, "image/png");
        const newFile = new File([blob], `${name}.jpeg`, {
          type: "image/jpeg"
        });
        onFileDone(newFile);
        uppload.close();
      }
    });
  }, [onFileDone]);

  const open = () => {
    uppload.open();
  };

  const [unsplashModalStatus, setUnsplashModalStatus] = useState(false);
  const [giphyModalStatus, setGiphyModalStatus] = useState(false);

  const MY_ACCESS_KEY = "Uj-V3_dkr8Upp3Hf0rX7bMWUKMJz2VDX69TqQftB-A4";

  const setModalClose = () => {
    setUnsplashModalStatus(false);
  };

  const setModalOpen = () => {
    setUnsplashModalStatus(true);
  };

  const setGiphyModalClose = () => {
    setGiphyModalStatus(false);
  };

  const setGiphyModalOpen = () => {
    giphyOnly1.current = true;
    setGiphyModalStatus(true);
  };

  const handleUnsplashDownloadDone = (e) => {
    const now = new Date();
    e.lastModifiedDate = now;
    e.name = `unsplash_${now.getTime()}.jpeg`;
    onFileDone(e);
    setModalClose();
  };
  const createToast = useToast();
  const stream = useRef();
  const recorder = useRef();
  const recordButtonRef = useRef();
  async function startRecording() {
    try {
      stream.current = await navigator.mediaDevices.getDisplayMedia({
        video: { mediaSource: "screen" },
        audio: true
      });
      recorder.current = new MediaRecorder(stream.current, {
        mimeType: "video/webm;codecs=vp8"
      });

      const chunks = [];
      recorder.current.onstart = () => {
        recordButtonRef.current.style.color = "#ff5962";
        recordButtonRef.current.classList.toggle("text-primary");
      };
      recorder.current.ondataavailable = (e) => chunks.push(e.data);

      stream.current.getVideoTracks()[0].onended = function () {
        recorder.current.stop();
      };

      recorder.current.onstop = (e) => {
        recordButtonRef.current.style.color = "";
        recordButtonRef.current.classList.toggle("text-primary");
        const completeBlob = new Blob(chunks, { type: chunks[0].type });
        const now = new Date();
        completeBlob.lastModifiedDate = now;
        completeBlob.name = `screenRecord_${now.getTime()}.webm`;

        onFileDone(completeBlob);
      };

      recorder.current.start();
    } catch (error) {
      console.error(error);
      createToast({
        pos: "tm",
        type: "danger",
        description: "Browser - No Support",
        title: intl.formatMessage({ id: "ERROR" })
      });
    }
  }

  const get = async (url = "", data = {}) => {
    const response = await fetch(url, {
      method: "GET"
    });
    return response.blob();
  };

  const giphyOnly1 = useRef();
  const handleGifSelect = useCallback(
    async (item) => {
      if (giphyOnly1.current) {
        giphyOnly1.current = false;
        setGiphyModalClose();
        const { images, title } = item;
        const { downsized } = images;
        const { url } = downsized;
        const data = await get(url);
        const now = new Date();
        data.lastModifiedDate = now;
        data.name = `${title}.gif`;
        onFileDone(data);
      }
    },
    [onFileDone]
  );

  const handleRecordClick = () => {
    if (recorder.current && recorder.current.state === "recording") {
      stream.current.getVideoTracks()[0].stop();
      recorder.current.stop();
    } else {
      startRecording();
    }
  };
  const intl = useIntl();

  const { blockScreenRecord } = settings;

  if (Chat) {
    return (
      <>
        <Modal
          isOpen={unsplashModalStatus}
          className="of-visible"
          enableCloseButton
          onClose={setModalClose}
        >
          <div className="UnsplashModal">
            {/* eslint-disable-next-line jsx-a11y/no-access-key*/}
            <UnsplashReact
              accessKey={MY_ACCESS_KEY}
              applicationName="unsplash_react"
              photoRatio={16 / 9}
              preferredSize={{ width: 1500, height: 800 }}
              Uploader={withDefaultProps(BlobUploader, {
                onBlobLoaded: handleUnsplashDownloadDone
              })}
            />
          </div>
        </Modal>
        <Modal
          className="of-visible"
          isOpen={giphyModalStatus}
          enableCloseButton
          onClose={setGiphyModalClose}
        >
          <div className="UnsplashModal">
            {/* eslint-disable-next-line jsx-a11y/no-access-key*/}
            <ReactGiphySearchBox
              wrapperClassName={"w-100 h-100"}
              apiKey="LlnWova1HjRAhsbK6dqyIHBZNzOUZ8n2"
              onSelect={handleGifSelect}
            />
          </div>
        </Modal>
        <div className="d-flex align-items-center">
          <InformationComponent
            Component={
              <div
                style={{ fill: "#7c98b6" }}
                className="ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button"
                onClick={setModalOpen}
              >
                <UnsplashLogo />
              </div>
            }
            text={intl.formatMessage({ id: "UNSPLASH_TOOLTIP" })}
          />
          <InformationComponent
            Component={
              <div
                style={{ fill: "#7c98b6" }}
                className="ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button"
                onClick={setGiphyModalOpen}
              >
                <GiphyLogo style={{ fontSize: 12 }} />
              </div>
            }
            text={"Giphy"}
          />
          {/* <InformationComponent
            Component={
              <div
                className="ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button"
                onClick={open}
              >  ->  TA BROKEN 16/03/2023
                <FontAwesomeIcon size="sm" icon={faGlobe} />
              </div>
            }
            text={intl.formatMessage({ id: "GLOBE_PICTURE_TOOLTIP" })}
          /> */}
          <InformationComponent
            Component={
              <div
                ref={recordButtonRef}
                className="ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button"
                onClick={handleRecordClick}
              >
                <FontAwesomeIcon size="sm" icon={faRecordVinyl} />
              </div>
            }
            text={intl.formatMessage({ id: "SCREEN_RECORD_TOOLTIP" })}
          />
        </div>
      </>
    );
  } else
    return (
      <>
        <Modal
          isOpen={unsplashModalStatus}
          className="of-visible"
          enableCloseButton
          onClose={setModalClose}
        >
          <div className="UnsplashModal">
            {/* eslint-disable-next-line jsx-a11y/no-access-key*/}
            <UnsplashReact
              accessKey={MY_ACCESS_KEY}
              applicationName="unsplash_react"
              photoRatio={16 / 9}
              // preferredSize={{ width: 800, height: 450 }}
              Uploader={withDefaultProps(BlobUploader, {
                onBlobLoaded: handleUnsplashDownloadDone
              })}
            />
          </div>
        </Modal>
        <Modal
          className="of-visible"
          isOpen={giphyModalStatus}
          enableCloseButton
          onClose={setGiphyModalClose}
        >
          <div className="UnsplashModal">
            {/* eslint-disable-next-line jsx-a11y/no-access-key*/}
            <ReactGiphySearchBox
              wrapperClassName={"w-100 h-100"}
              apiKey="LlnWova1HjRAhsbK6dqyIHBZNzOUZ8n2" // Required: get your on https://developers.giphy.com
              onSelect={handleGifSelect}
            />
          </div>
        </Modal>
        <div className="d-flex align-items-center">
          <InformationComponent
            Component={
              <div
                className="text-primary cursor-pointer d-flex align-items-top mr-3"
                onClick={setModalOpen}
              >
                <UnsplashLogo />
              </div>
            }
            text={intl.formatMessage({ id: "UNSPLASH_TOOLTIP" })}
          />

          <InformationComponent
            Component={
              <div
                className="text-primary d-flex align-items-top cursor-pointer mr-3"
                onClick={setGiphyModalOpen}
              >
                <GiphyLogo style={{ fontSize: 12 }} />
              </div>
            }
            text={"Giphy"}
          />
          {/* <InformationComponent
            Component={
              <div
                className="text-primary d-flex align-items-top cursor-pointer mr-3"
                onClick={open}
              >  ->  TA BROKEN 16/03/2023
                <FontAwesomeIcon size="sm" icon={faGlobe} />
              </div>
            }
            text={intl.formatMessage({ id: "GLOBE_PICTURE_TOOLTIP" })}
          /> */}
          {blockScreenRecord ? null : (
            <InformationComponent
              Component={
                <div
                  ref={recordButtonRef}
                  className="text-primary d-flex align-items-top cursor-pointer"
                  onClick={handleRecordClick}
                >
                  <FontAwesomeIcon size="sm" icon={faRecordVinyl} />
                </div>
              }
              text={intl.formatMessage({ id: "SCREEN_RECORD_TOOLTIP" })}
            />
          )}
        </div>
      </>
    );
};

// const ModalSplash = () => {
// 	state = { imageUrl: null }

// 	handleFinishedUploading = imageUrl => {
// 	  this.setState({ imageUrl })
// 	}

// 	render() {
// 	  const { imageUrl } = this.state

// 	  return (
// 		<div style={{ display: "flex" }}>
// 		  <div style={{ height: "350px", width: "450px" }}>
// 			<UnsplashReact
// 			  accessKey={process.env.UNSPLASH_ACCESS_KEY}
// 			  applicationName="unsplash_react"
// 			  Uploader={InsertIntoApplicationUploader}
// 			  photoRatio={16 / 9}
// 			  preferredSize={{ width: 800, height: 450 }}
// 			  onFinishedUploading={this.handleFinishedUploading}
// 			/>
// 		  </div>

// 		  <div>
// 			<img src={imageUrl} />
// 		  </div>
// 		</div>
// 	  )
// 	}
//   }

// const ModalSplash = ({}) => {
//   const [imageUrl, setImageUrl] = useState(null);

//   const handleFinishedUploading = (imageUrl) => {
//     setImageUrl(imageUrl);
//   };
//   return (
//     <div style={{ display: "flex" }}>
//       <div style={{ height: "350px", width: "450px" }}>
//         <UnsplashReact
//           apiKey={"Uj-V3_dkr8Upp3Hf0rX7bMWUKMJz2VDX69TqQftB-A4"}
//           applicationName="unsplash_react"
//           Uploader={InsertIntoApplicationUploader}
//           photoRatio={16 / 9}
//           preferredSize={{ width: 800, height: 450 }}
//           onFinishedUploading={handleFinishedUploading}
//         />
//       </div>

//       <div>
//         <img src={imageUrl} alt="placeholder" />
//       </div>
//     </div>
//   );
// };
