import React from "react";
import { createRoot } from "react-dom/client";
import "./Reboot.css";
// import "./Bootstrap.css";
import "./Bootstrap.css";
import "./index.css";

import App from "./App";
// import register from "./registerServiceWorker";
import Intl18nProvider from "./Containers/Intl18n/Intl18nProvider";
import { Provider } from "react-redux";
import store from "./store";
import GlobalLoader, {
  GlobalLoadingProvider
} from "./Components/GlobalLoader/GlobalLoader";
import { ToastProvider } from "./Components/Toast/ToastProvider";
import { GoogleMapsProvider } from "./Containers/GoogleMaps/GoogleMaps";
import { MaintenanceProvider } from "./Containers/Maintenance/Maintenance";
import { RealTimeProvider } from "./Containers/RealTime/RealTime";
import RealTimeEntityUpdater from "./Containers/RealTime/RealTimeEntityUpdater";
import { OneSignalPlayerIdProvider } from "./Helpers/OneSignalHelper";
import NotificationSoundProvider from "./Components/NotificationSoundProvider/NotificationSound";
// import { testCondition } from "./Components/FilterList/AdvancedFilter/AdvancedFilterColors";
// testCondition

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <OneSignalPlayerIdProvider>
      <Intl18nProvider>
        <RealTimeProvider>
          <RealTimeEntityUpdater>
            <ToastProvider portalElem={document.body}>
              <GoogleMapsProvider>
                <GlobalLoadingProvider>
                  <MaintenanceProvider>
                    <NotificationSoundProvider>
                      <GlobalLoader />
                      <App />
                    </NotificationSoundProvider>
                  </MaintenanceProvider>
                </GlobalLoadingProvider>
              </GoogleMapsProvider>
            </ToastProvider>
          </RealTimeEntityUpdater>
        </RealTimeProvider>
      </Intl18nProvider>
    </OneSignalPlayerIdProvider>
  </Provider>
);

// ReactDOM.render(

// 	document.getElementById("root")
// );

// register();
