import React, { Component } from "react";
import "./Switch.css";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
class Switch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      className,
      hasTextActiveOnly,
      hasText,
      preview,
      handleClassName,
      checked,
      value,
      ...rest
    } = this.props;
    let classes = classNames("switch", className, {
      lg: hasText,
      "Switch-larger lg": hasTextActiveOnly
    });

    const resolvedChecked = checked === undefined ? value : checked;
    return (
      <label className={classes}>
        <input type="checkbox" checked={resolvedChecked} {...rest} />
        <div className="slider">
          {hasText && !hasTextActiveOnly && <span className="on">On</span>}
          {hasTextActiveOnly && <span className="on">{hasTextActiveOnly}</span>}
          {hasText && <span className="off">Off</span>}
          <div className={classNames("handle", handleClassName)}>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        </div>
      </label>
    );
  }
}

export default Switch;
