import React, { FC } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { EditorView } from "@codemirror/view";
import { json } from "@codemirror/lang-json";
import "./OverideCodeMirror.css";
interface CodeMirrorProps {
  value: string;
  height?: string;
  className?: string;
  readOnly?: boolean;
  chosenExtensions?: any;
  onChange?: (val: string) => void | undefined;
}

export const CodeMirrorComponent: FC<CodeMirrorProps> = ({
  value,
  height,
  readOnly,
  className,
  chosenExtensions,
  onChange
}) => {
  const handleChange = React.useCallback(
    (newVal: any, viewUpdate: any) => {
      onChange && onChange(newVal);
    },
    [onChange]
  );

  return (
    <CodeMirror
      className={className}
      height={height}
      value={value}
      readOnly={readOnly}
      extensions={
        chosenExtensions
          ? chosenExtensions
          : [javascript(), json(), EditorView.lineWrapping]
      }
      onChange={handleChange}
    />
  );
};
