import React, { useMemo } from "react";
import {
  DetailsEntities,
  DetailsDealQueryableEntities,
  DetailsProjectQueryableEntities,
  DetailsContractQueryableEntities,
  DetailsContactQueryableEntities,
  DetailsTicketQueryableEntities,
  DetailsTaskQueryableEntities
} from "../../../Components/DetailsView/DetailsChildren/DetailsChildren";

export const ClientDetailsChildren = ({ id }) => {
  const formProps = useMemo(() => {
    return { clientId: id };
  }, [id]);
  return (
    <DetailsEntities formProps={formProps}>
      <DetailsDealQueryableEntities filter={`(Deal/ClientId eq ${id})`} />
      <DetailsProjectQueryableEntities
        filter={`(DirectIndirectClient/Id eq ${id})`}
      />
      <DetailsContractQueryableEntities
        filter={`(DirectIndirectClient/Id eq ${id})`}
      />
      <DetailsContactQueryableEntities filter={`(Client/Id eq ${id})`} />
      <DetailsTicketQueryableEntities
        filter={`(DirectIndirectClient/Id eq ${id})`}
      />
      <DetailsTaskQueryableEntities
        filter={`(DirectIndirectClient/Id eq ${id})`}
      />
    </DetailsEntities>
  );
};
