import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import classnames from "classnames";
import classes from "./AppBar.module.css";
import { FavoritesContainer } from "./AppBarOld";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
interface AppSidebarHorizontalFavorites {
  isFixed: boolean;
  isOpen: boolean;
  horizontal: boolean | undefined;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onPopupMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onPopupMouseLeave: React.MouseEventHandler<HTMLDivElement>;
  stickyFavorites?: boolean;
  setStickyFavorites?: React.Dispatch<React.SetStateAction<boolean>>;
}

const useAppBarDragEvent = (
  commitChange: (value: number) => void,
  size: number,
  minSize: number,
  maxSize: number
) => {
  const sizeRef = useRef<number | undefined>();
  const [tempSize, setTempSize] = useState<number | undefined>(size);
  const [baseX, setBaseX] = useState<number | undefined>();
  const baseSizeRef = useRef<number | undefined>();

  const onMouseDown = useCallback(
    (e: any) => {
      baseSizeRef.current = size;
      setBaseX(e.clientX);
    },
    [size]
  );

  useEffect(() => {
    if (baseX === undefined) return;

    const moveListener = (e: any) => {
      const { clientX: newClientX } = e;
      const incrementedSize = newClientX - baseX;
      const base = baseSizeRef.current as number;
      let newSize = base + incrementedSize;
      if (newSize < minSize) newSize = minSize;
      if (newSize > maxSize) newSize = maxSize;
      sizeRef.current = newSize;
      setTempSize(newSize);
      //console.log("moved");
    };

    document.addEventListener("mousemove", moveListener);

    const upListener = () => {
      if (sizeRef.current) commitChange(sizeRef.current);
      setBaseX(undefined);
    };

    document.addEventListener("mouseup", upListener);

    return () => {
      document.removeEventListener("mousemove", moveListener);
      document.removeEventListener("mouseup", upListener);
    };
  }, [baseX, commitChange, maxSize, minSize]);

  const result = useMemo(() => {
    return { onMouseDown, tempSize };
  }, [onMouseDown, tempSize]);

  return result;
};

interface IAppSidebarHorizontalFavoritesContainerProps {
  isFixed: boolean;
  isOpen?: boolean;
  setIsOpen: any;
  horizontal: boolean | undefined;
  onPopupMouseEnter: MouseEventHandler<HTMLDivElement>;
  onPopupMouseLeave: MouseEventHandler<HTMLDivElement>;
  resolvedIsOpen: boolean;
  stickyFavorites: boolean;
  children?: React.ReactNode;
}

const AppSidebarHorizontalFavoritesContainer: FC<
  IAppSidebarHorizontalFavoritesContainerProps
> = ({
  isFixed,
  horizontal,
  onPopupMouseEnter,
  onPopupMouseLeave,
  setIsOpen,
  resolvedIsOpen,
  children,
  isOpen,
  stickyFavorites
}) => {
  const [width, setWidth] = useState(() => {
    const lsWidth = localStorage.getItem("app-sidebar-width");
    return lsWidth ? parseInt(lsWidth) : 240;
  });

  const comitWidth = useCallback((newWidth: number) => {
    setWidth(newWidth);
    localStorage.setItem("app-sidebar-width", `${newWidth}`);
  }, []);

  const { onMouseDown, tempSize } = useAppBarDragEvent(
    comitWidth,
    width,
    240,
    480
  );

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [isFixed, width]);

  const horiSettings = useMemo(() => {
    if (horizontal === true)
      return {
        height: "calc(100% - 64px)",
        top: 64
      };
    else return {};
  }, [horizontal]);

  const resolvedSize = tempSize as number;
  return (
    <div
      style={{
        width: stickyFavorites ? resolvedSize : undefined,
        left: `-2px`,
        ...horiSettings
      }}
      onMouseEnter={!stickyFavorites ? onPopupMouseEnter : undefined}
      onMouseLeave={!stickyFavorites ? onPopupMouseLeave : undefined}
      className={classnames(
        classes.appBarSecondaryContainer,
        "overflow-visible text-white flex-0-0-auto Smaller-Scroll-Bar",
        {
          [classes.IsFixed]: isFixed,
          "overflow-visible": isOpen,
          "overflow-hidden": !isOpen,
          [classes.IsOpen]: resolvedIsOpen,
          "py-3 px-2": resolvedIsOpen || isFixed || stickyFavorites,
          [classes.IsSticky]: stickyFavorites
        }
      )}
    >
      {children}
      {stickyFavorites && (
        <div
          onMouseDown={onMouseDown}
          className={classes.appBarSecondaryContainerDragger}
        ></div>
      )}
      <div
        // ref={AppBarStickerButtonRef}
        // onMouseEnter={onButtonEnter}
        // onMouseLeave={onButtonLeave}
        className={classnames(
          classes.appbarStickyBlock,
          classes.forcedActivate
        )}
      >
        <div
          onClick={() => {
            localStorage.setItem(`ar-horizontal-bar-favorite-bar-status`, "0");
            setIsOpen(false);
          }}
          className={classnames(classes.appbarStickyButton)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  );
};

const AppSidebarHorizontalFavoritesComponent: FC<
  AppSidebarHorizontalFavorites
> = ({
  isFixed,
  horizontal,
  isOpen,
  setIsOpen,
  stickyFavorites,
  setStickyFavorites,
  onPopupMouseEnter,
  onPopupMouseLeave
}) => {
  const [resolvedIsOpen, setResolvedIsOpen] = useState(false);

  useLayoutEffect(() => {
    const animationFrame = requestAnimationFrame(() => {
      setResolvedIsOpen(isOpen);
    });
    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [isOpen]);

  return (
    <AppSidebarHorizontalFavoritesContainer
      onPopupMouseEnter={onPopupMouseEnter}
      onPopupMouseLeave={onPopupMouseLeave}
      horizontal={horizontal}
      resolvedIsOpen={resolvedIsOpen}
      isFixed={isFixed}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      key={isFixed ? 0 : 1}
      stickyFavorites={stickyFavorites as boolean}
    >
      {isOpen && (
        <FavoritesContainer
          setIsOpen={setIsOpen}
          stickyFavorites={stickyFavorites}
          setStickyFavorites={setStickyFavorites}
        />
      )}
    </AppSidebarHorizontalFavoritesContainer>
  );
};

export default AppSidebarHorizontalFavoritesComponent;
