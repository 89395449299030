
import React from 'react'

export const SidebarAutomationContext = React.createContext()

const SidebarV2Automation = ({config, children}) => {
	return (
		<SidebarAutomationContext.Provider value={config}>
			{children}
		</SidebarAutomationContext.Provider>
	)
}

export default SidebarV2Automation
