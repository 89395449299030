import React from "react";
import { useSidebar } from "./SidebarV2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";

const SidebarBasicCloseButton = ({ className }) => {
  const [, closeSidebar] = useSidebar();
  return (
    <div
      className={classnames(
        "cursor-pointer ar-sidebar-close-button-alt mr-2 p-3",
        className
      )}
      onClick={() => closeSidebar()}
    >
      <FontAwesomeIcon icon={faTimes} />
    </div>
  );
};

export default SidebarBasicCloseButton;
