import React, { useRef } from "react";
import EntityIconOverview from "../EntityIconOverview/EntityIconOverview";
import {
  InnerColorFilterBars,
  TriggeredColoredFilterProvider,
  useTriggeredColorFilters
} from "../FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import classnames from "classnames";
import moment from "moment";
import EntityFollowIcon from "../EntityFollow/EntityFollowIcon";
import "./CardList.css";
import Checkbox from "../Checkbox/Checkbox";
import { usePipelineStatus } from "../../Hooks/EntityHooks";
import { PipelineStatusProfile } from "../../Containers/AdvancedMultiInputs/PipelineDropdowns";

export const CardItemHeader = ({
  id,
  schema,
  value,
  name,
  LinkComponent,
  ButtonsComponent,
  Associations,
  startDate,
  betterDate
}) => {
  const formmatedDate = moment(startDate).format("DD MMM YYYY HH:mm");
  const connectedPipelineStatus = usePipelineStatus(value.PipelineStatus);

  return (
    <>
      <InnerColorFilterBars kanban entity={value} />
      <div>
        <div className="position-relative d-flex flex-column">
          <div className="d-flex align-items-center justify-content-between fs-14 mb-2">
            <PipelineStatusProfile pipeline={connectedPipelineStatus} />
            {betterDate ? (
              betterDate
            ) : (
              <div className="ml-2 fs-12 text-grey">{formmatedDate}</div>
            )}
          </div>
          <div className="w-100 border-bottom"></div>
          <div className="mt-2 d-flex justify-content-between">
            <EntityIconOverview
              noChildren
              className="fs-12"
              schema={schema}
              value={value}
              noHistory
            />
            <div className="d-flex flex-1 justify-content-end">
              {Associations}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const BoardItemTools = ({
  ButtonsComponent,
  isFollowing,
  value,
  id,
  Icon,
  isSelected,
  LinkComponent,
  name,
  onItemSelect,
  schema
}) => {
  const handleCheckChange = () => {
    onItemSelect((oldItems) => {
      const index = oldItems.findIndex((e) => e === id);
      if (index >= 0) {
        const newItems = [...oldItems];
        newItems.splice(index, 1);
        return newItems;
      } else return [...oldItems, id];
    });
  };

  return (
    <div className="d-flex pt-3 px-3">
      <div className="d-flex flex-1 overflow-hidden">
        <div style={{ width: 24, height: 24 }} className="mr-2 d-flex">
          <Checkbox
            className={"air-cards-checkbox"}
            checked={isSelected}
            onChange={handleCheckChange}
          />
        </div>
        <div className="d-flex flex-1 mr-2 overflow-hidden flex-column">
          <LinkComponent className="h-100 d-flex align-items-center" id={id}>
            {/* <div
              className="mr-2 text-color-link fs-14"
              style={{ fontWeight: 500 }}
            >
              {Icon}
            </div> */}
            <div
              className="breakWord fs-14 text-left text-truncate"
              style={{ fontWeight: 500 }}
            >
              {name}
            </div>
          </LinkComponent>
          <div className="fs-12">Nº {id}</div>
        </div>
      </div>
      <div className="ar-card-item-header-tools ">
        <ButtonsComponent minimal noColors entity={value} />
        <EntityFollowIcon
          className="ml-2"
          id={id}
					canEdit={value.CanEdit}
          isFollowing={isFollowing}
          schema={schema}
        />
      </div>
    </div>
  );
};

export const CardContainer = React.memo(
  ({
    children,
    className,
    isRead,
    isFollowing,
    isSelected,
    id,
    value,
    ...rest
  }) => {
    const headerRef = useRef();
    const { HasUpdates } = value;
    const conds = useTriggeredColorFilters(value);

    let paddingText = "";
    if (conds?.length > 0) {
      paddingText = "0px 8px";
    }

    return (
      <TriggeredColoredFilterProvider conds={conds}>
        <div
          className={classnames("air-card-block", {
            selected: isSelected,
            "air-card-block-updated": HasUpdates
          })}
          style={{ padding: paddingText }}
        >
          <BoardItemTools
            isSelected={isSelected}
            value={value}
            id={id}
            isFollowing={isFollowing}
            {...rest}
          />
          <div
            ref={headerRef}
            className={classnames(
              "text-black ar-card-item py-2 px-3",
              {
                unread: isFollowing && isRead === false
              },
              className
            )}
          >
            <CardItemHeader value={value} {...rest} />
            {children}
          </div>
        </div>
      </TriggeredColoredFilterProvider>
    );
  }
);
