import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const ProjectOverviewComp = React.lazy(() =>
  import(/* webpackChunkName: "projects-overview" */ "./ProjectOverviewComp")
);

const ProjectOverview = () => {
  return (
    <SuspenseErrorBoundary>
      <ProjectOverviewComp />
    </SuspenseErrorBoundary>
  );
};

export default ProjectOverview;
