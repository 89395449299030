import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";
import { client } from "../../Helpers/IOClient";
import { useToast } from "../../Components/Toast/ToastProvider";
import { MaintenanceText } from "./MaintenanceHelper";
import { FormattedMessage } from "react-intl";

const MaintenanceContext = React.createContext();
export const MaintenanceUpdateContext = React.createContext();

export const ServerStatusEnum = {
  Online: 1,
  Maintenance: 2,
};

export const MaintenanceProvider = ({ children }) => {
  const [state, setState] = useState({
    loading: true,
    error: null,
    data: null,
  });

  const timeoutRef = useRef();
  const unmountRef = useRef();

  const updateData = useCallback((data) => {
    setState((state) => {
      return {
        ...state,
        data,
      };
    });
  }, []);

  const createToast = useToast();

  useEffect(() => {
    const fetchState = async () => {
      const { data, error } = await client.get("ServerStatus");

      if (unmountRef.current) return;

      if (data) {
        const { CurrentlyInMaintenance, ScheduleMessage } = data;
        if (CurrentlyInMaintenance || ScheduleMessage) {
          createToast({
            pos: "tm",
            type: "danger",
            description: <MaintenanceText data={data} />,
            title: <FormattedMessage id="MAINTENANCE" />,
          });
        }
      }

      setState({
        data,
        error,
        loading: false,
      });

      if (data) {
        const checkServerStatus = () => {
          timeoutRef.current = setTimeout(async () => {
            const { data } = await client.get("ServerStatus");
            if (unmountRef.current) return;

            setState((state) => {
              return {
                ...state,
                data,
              };
            });
            checkServerStatus();
          }, 120000); // 2m
        };

        checkServerStatus();
      }
    };
    fetchState();
  }, [createToast]);

  useEffect(() => {
    return () => {
      unmountRef.current = true;
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <MaintenanceContext.Provider value={state}>
      <MaintenanceUpdateContext.Provider value={updateData}>
        {children}
      </MaintenanceUpdateContext.Provider>
    </MaintenanceContext.Provider>
  );
  // const state = useQuery("Maintenance");
};

export const useMaintenanceState = () => {
  return useContext(MaintenanceContext);
};
export const useMaintenanceUpdateState = () => {
  return useContext(MaintenanceUpdateContext);
};
