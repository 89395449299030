import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from "react";
import { FilterColumnColorProvider } from "../FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { useCardRequestProvider, useGridRequest } from "../CGrid/ServerGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/pro-light-svg-icons";
import { PageSizeSelect } from "../CGrid/PageSizeSelect";
import { FormattedMessage, useIntl } from "react-intl";
import { BarLoader } from "../GlobalLoader/GlobalLoader";
import "./CardList.css";
import { SelectedArrMsgContext } from "../UnOrReadOptions/UnreadOptions";
import { createKinectHook } from "../../Hooks/MiscHooks";
import { HoverStateContext } from "../../Helpers/MiscHelper";
import Button from "../Button/Button";

export const TotalGridItemsWidget = ({ totalItems, pageSize, currentPage }) => {
  const value = useGridRequest();
  const intl = useIntl();
  if (!totalItems || !pageSize || !currentPage) return <div />;

  const firstEntry = pageSize * (currentPage - 1) + 1;
  let secondEntry = pageSize * currentPage;
  if (secondEntry > totalItems) secondEntry = totalItems;

  const text = `${firstEntry} - ${secondEntry} ${intl.formatMessage({
    id: "OF"
  })} ${totalItems} ${intl.formatMessage({ id: "ITEMS" })}`;
  return (
    <div className="fs-14">
      <span className="text-gray-darker mr-2">{text}</span>
      {value && (
        <Button vType="link-primary" className="h-auto" onClick={value.refetch}>
          <FontAwesomeIcon size="sm" icon={faSync} />
        </Button>
      )}
    </div>
  );
};

const GridFooter = React.memo(
  ({
    currentPage,
    pages,
    pageSize,
    disablePageSelector,
    count,
    changePage
  }) => {
    const firstPageClick = () => {
      changePage(1);
    };

    const prevPageClick = () => {
      changePage(currentPage - 1 || 1);
    };

    const nextPageClick = () => {
      const nextPage = currentPage + 1;
      if (nextPage > pages) return;

      changePage(nextPage);
    };

    const lastPageClick = () => {
      changePage(pages);
    };

    const viewportPages = useMemo(() => {
      if (!pages) return [];

      let minPage = currentPage - 2;
      if (minPage < 1) minPage = 1;

      let maxPage = currentPage + 2;

      if (maxPage > pages) maxPage = pages;

      const pagesArr = [];

      for (let i = minPage; i <= maxPage; i++) {
        pagesArr.push(i);
      }
      return pagesArr;
    }, [currentPage, pages]);

    return (
      <div className="c-grid-footer d-flex justify-content-between align-items-center">
        <div />
        <div>
          <div className="c-grid-page c-grid-backward" onClick={firstPageClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill="currentColor"
                d="M64 468V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v176.4l195.5-181C352.1 22.3 384 36.6 384 64v384c0 27.4-31.9 41.7-52.5 24.6L136 292.7V468c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12z"
              />
            </svg>
          </div>
          <div className="c-grid-page" onClick={prevPageClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
              <path
                fill="currentColor"
                d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"
              />
            </svg>
          </div>
          {viewportPages.map((p, i) => (
            <div
              onClick={() => changePage(p)}
              key={p}
              className={
                "c-grid-page" + (currentPage === p ? " c-current-page" : "")
              }
            >
              {p}
            </div>
          ))}
          <div className="c-grid-page" onClick={nextPageClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
              <path
                fill="currentColor"
                d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
              />
            </svg>
          </div>
          <div
            className="c-grid-page c-grid-forward"
            onClick={(e) => {
              lastPageClick(pages);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill="currentColor"
                d="M384 44v424c0 6.6-5.4 12-12 12h-48c-6.6 0-12-5.4-12-12V291.6l-195.5 181C95.9 489.7 64 475.4 64 448V64c0-27.4 31.9-41.7 52.5-24.6L312 219.3V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12z"
              />
            </svg>
          </div>
          {/* {!disablePageSelector && (
            <div className="c-grid-pagesize text-primary">
              <PageSizeSelect className="c-grid-page-selector bg-white" />
              <FormattedMessage id={"PER_PAGE"} />
            </div>
          )} */}
        </div>

        <TotalGridItemsWidget
          totalItems={count}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      </div>
    );
  }
);

const useKinect = createKinectHook({
  filterTarget: function (target, e) {
    const { offsetParent } = target;

    if (
      offsetParent &&
      offsetParent.classList &&
      offsetParent.classList.contains("air-card-block")
    )
      return false;

    return true;
    // if (!/down|start/.test(e.type)) {
    //   return !/area|a|input/i.test(target.tagName);
    // }
  }
});

const CardList = ({ settings, data }) => {
  const {
    isLoading,
    params,
    count,
    currentPage,
    pageSize,
    pages,
    results,
    onParamsChange
  } = useGridRequest();

  const { ItemComponent, onItemSelect } = settings;

  const updateParams = useCallback(
    (newParams) => {
      onParamsChange((params) => {
        return {
          ...params,
          ...newParams
        };
      });
    },
    [onParamsChange]
  );

  const handlePageChange = useCallback(
    (page) => {
      //   ;
      const { top: pageSize } = params;

      // this.setState({
      //   currentPage: page
      // });

      updateParams({
        skip: pageSize * (page - 1)
      });
    },
    [params, updateParams]
  );

  const ref = useRef();
  useKinect(ref);

  return (
    <FilterColumnColorProvider>
      <div className="flex-1 h-100 d-flex flex-column position-relative">
        <BarLoader isLoading={isLoading} />
        <div ref={ref} className="of-y-auto cardList-grid flex-1 ">
          <ItemsCreator
            onItemSelect={onItemSelect}
            ItemComponent={ItemComponent}
            results={results}
          />
        </div>
        <GridFooter
          changePage={handlePageChange}
          currentPage={currentPage}
          pages={pages}
          pageSize={pageSize}
          count={count}
        />
      </div>
    </FilterColumnColorProvider>
  );
};

const ItemsCreator = React.memo(({ ItemComponent, onItemSelect, results }) => {
  const selectedArr = useContext(SelectedArrMsgContext);

  const selectedDict = useMemo(() => {
    return selectedArr.reduce((dict, currentValue) => {
      dict[currentValue.id] = true;
      return dict;
    }, {});
  }, [selectedArr]);

  // const { onItemSelect } = useCardRequestProvider();

  return results.map((e) => {
    return (
      <Item
        ItemComponent={ItemComponent}
        key={e}
        isSelected={selectedDict[e] || false}
        id={e}
        onItemSelect={onItemSelect}
      ></Item>
    );
  });
});

const Item = React.memo(({ isSelected, id, onItemSelect, ItemComponent }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <HoverStateContext.Provider value={isHovering}>
      <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <ItemComponent
          isSelected={isSelected}
          id={id}
          onItemSelect={onItemSelect}
        ></ItemComponent>
      </div>
    </HoverStateContext.Provider>
  );
});

export default CardList;
