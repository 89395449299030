import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import loaderData from "../../Assets/Animations/main_loader.json";
const MainLoader = () => {
  const loaderRef = useRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: loaderRef.current,
      renderer: "svg",
      loop: true,
      autoPlay: true,
      animationData: loaderData
    });

    const cleanup = () => {
      anim.destroy();
    };

    return cleanup;
  }, []);

  return (
    <div className="main-loader-container">
      <div ref={loaderRef} className="main-loader" />
    </div>
  );
};

export default MainLoader;
