import { useEffect, useState } from "react";

export const ReverseCardChooserListnerDict = {};

const calculateColumns = (columns, orderList, resetLayout) => {
  const fieldDict = getfieldTitleDict(columns);
  const result = [];
  if (!orderList) return result;
  for (const c of orderList) {
    if (fieldDict[c]) result.push(fieldDict[c]);
  }
  return result;
};

const getfieldTitleDict = Columns => {
  const result = {};
  for (const c of Columns) {
    result[c.field] = c;
  }
  return result;
};

export const useReversedResolvedCard = (columns, id, disableAll = false) => {
  const [resolvedColumn, setResolvedColumn] = useState(() => {
    //ir a localstorage
    let gridColumnLayout = JSON.parse(
      window.localStorage.getItem("ar-grid-card-layout")
    );

    const orderList = gridColumnLayout ? gridColumnLayout[id] : [];

    if (orderList && orderList.length > 0)
      return calculateColumns(columns, orderList);
    else {
      if (disableAll) return [];
      else return columns;
    }
  });

  useEffect(() => {
    if (!ReverseCardChooserListnerDict[id])
      ReverseCardChooserListnerDict[id] = [];

    const listner = orderList => {
      setResolvedColumn(calculateColumns(columns, orderList));
    };

    ReverseCardChooserListnerDict[id].push(listner);

    return () => {
      const index = ReverseCardChooserListnerDict[id].indexOf(listner);
      ReverseCardChooserListnerDict[id].splice(index, 1);
    };
  }, [columns, id]);

  return resolvedColumn;
};
