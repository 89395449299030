import { ProjectStatusProfileWithIcon } from "../../Components/Projects/ProjectProfile";
import { ClientStatusProfileWithIcon } from "../../Components/Clients/ClientProfile";
import {
  spaceInterventionsSchema,
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceCallsSchema,
  spaceClientSchema,
  spaceTasksSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceSubscriptionSchema
} from "../../config/schema";
import { useEntityValue, useContact } from "../../Hooks/EntityHooks";
import { useMemo } from "react";
import { TaskEntityGridButtons } from "../Tasks/TasksHelper";
import { ProjectEntityGridButtons } from "../Projects/ProjectsHelper";
import { TicketEntityGridButtons } from "../Tickets/TicketsHelper";
import { InterventionEntityGridButtons } from "../Interventions/InterventionsHelper";
import { CallEntityGridButtons } from "../Calls/CallsHelper";
import { ClientEntityGridButtons } from "../Clients/ClientsHelper";
import { ContractEntityGridButtons } from "../Contracts/ContractsHelper";
import { DealEntityGridButtons } from "../Deals/DealsHelper";
import {
  TaskIcon,
  ProjectIcon,
  TicketIcon,
  InterventionIcon,
  CallIcon,
  ClientIcon,
  ContractIcon,
  DealIcon,
  SubscriptionIcon
} from "../../Components/Common/EntityIcons";
import { ConnectedTaskOrigin, taskOriginOptions } from "../Origin/TaskOrigin";
import {
  ConnectedProjectOrigin,
  projectOriginOptions
} from "../Origin/ProjectOrigin";
import {
  ConnectedTicketOrigin,
  ticketOriginOptions
} from "../Origin/TicketOrigin";
import {
  ConnectedInterventionOrigin,
  InterventionOriginOptions
} from "../Origin/InterventionOrigin";
import { callOriginOptions, ConnectedCallOrigin } from "../Origin/CallOrigin";
import {
  clientOriginOptions,
  ConnectedClientOrigin
} from "../Origin/ClientOrigin";
import {
  ConnectedContractOrigin,
  contractOriginOptions
} from "../Origin/ContractOrigin";
import { ConnectedDealOrigin, dealOriginOptions } from "../Origin/DealOrigin";
import { DealStatusProfileWithIcon } from "../../Components/Deals/DealStatusProfile";
import { ContractStatusProfileWithIcon } from "../../Components/Contract/ContractProfile";
import { SubscriptionEntityButtons } from "../Subscriptions/SubscriptionsHelper";
import { SubscriptionStatusProfileWithIcon } from "../../Components/Subscription/SubscriptionProfile";
import {
  ConnectedSubscriptionOrigin,
  SubscriptionOriginOptions
} from "../Origin/SubscriptionOrigin";

const CallNameComponent = ({ value }) => {
  const contact = useContact(value.Contact);

  return contact.Name;
};

export const useTreeViewOptions = (item) => {
  const settings = useMemo(() => {
    const {
      Task,
      Project,
      Ticket,
      TimeCharge,
      Call,
      Client,
      Contract,
      Deal,
      Subscription
    } = item;

    let Id,
      schema,
      IconComponent,
      StatusComponent,
      EntityButton,
      NameComponent,
      Popup,
      PopupOptions;
    if (Task) {
      schema = spaceTasksSchema;
      IconComponent = TaskIcon;
      StatusComponent = ProjectStatusProfileWithIcon;
      Id = Task;
      Popup = ConnectedTaskOrigin;
      EntityButton = TaskEntityGridButtons;
      PopupOptions = taskOriginOptions;
    } else if (Project) {
      schema = spaceProjectsSchema;
      IconComponent = ProjectIcon;
      Popup = ConnectedProjectOrigin;
      StatusComponent = ProjectStatusProfileWithIcon;
      Id = Project;
      EntityButton = ProjectEntityGridButtons;
      PopupOptions = projectOriginOptions;
    } else if (Ticket) {
      schema = spaceTicketsSchema;
      IconComponent = TicketIcon;
      StatusComponent = ProjectStatusProfileWithIcon;
      Id = Ticket;
      EntityButton = TicketEntityGridButtons;
      Popup = ConnectedTicketOrigin;
      PopupOptions = ticketOriginOptions;
    } else if (TimeCharge) {
      schema = spaceInterventionsSchema;
      IconComponent = InterventionIcon;
      StatusComponent = ProjectStatusProfileWithIcon;
      Id = TimeCharge;
      EntityButton = InterventionEntityGridButtons;
      Popup = ConnectedInterventionOrigin;
      PopupOptions = InterventionOriginOptions;
    } else if (Call) {
      schema = spaceCallsSchema;
      IconComponent = CallIcon;
      StatusComponent = ProjectStatusProfileWithIcon;
      Id = Call;
      EntityButton = CallEntityGridButtons;
      NameComponent = CallNameComponent;
      Popup = ConnectedCallOrigin;
      PopupOptions = callOriginOptions;
    } else if (Client) {
      schema = spaceClientSchema;
      IconComponent = ClientIcon;
      StatusComponent = ClientStatusProfileWithIcon;
      Id = Client;
      EntityButton = ClientEntityGridButtons;
      Popup = ConnectedClientOrigin;
      PopupOptions = clientOriginOptions;
    } else if (Contract) {
      schema = spaceContractSchema;
      IconComponent = ContractIcon;
      Id = Contract;
      EntityButton = ContractEntityGridButtons;
      StatusComponent = ContractStatusProfileWithIcon;
      Popup = ConnectedContractOrigin;
      PopupOptions = contractOriginOptions;
    } else if (Subscription) {
      schema = spaceSubscriptionSchema;
      IconComponent = SubscriptionIcon;
      Id = Subscription;
      EntityButton = SubscriptionEntityButtons;
      StatusComponent = SubscriptionStatusProfileWithIcon;
      Popup = ConnectedSubscriptionOrigin;
      PopupOptions = SubscriptionOriginOptions;
    } else if (Deal) {
      schema = spaceDealsSchema;
      IconComponent = DealIcon;
      Id = Deal;
      StatusComponent = DealStatusProfileWithIcon;
      EntityButton = DealEntityGridButtons;
      Popup = ConnectedDealOrigin;
      PopupOptions = dealOriginOptions;
    }
    return {
      schema,
      Id,
      IconComponent,
      StatusComponent,
      EntityButton,
      NameComponent,
      Popup,
      PopupOptions,
      Task,
      Project,
      Ticket,
      TimeCharge,
      Call,
      Client,
      Contract,
      Subscription,
      Deal
    };
  }, [item]);

  const { schema, Id } = settings;

  const value = useEntityValue(schema, Id);
  const x = useMemo(() => {
    return {
      ...settings,
      value
    };
  }, [settings, value]);
  return x;
};
