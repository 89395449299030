import React, { useContext } from "react";

import ClientProfile, {
  ConnectedClientProfile
} from "../../../../Components/Clients/ClientProfile";
import classnames from "classnames";
import { useNotificationElapsedTime } from "../FilteredNotificationsHelper";
import { hasFlag, addFlag } from "../../../../Helpers/MiscHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-light-svg-icons";
import {
  ProjectIcon,
  CallIcon,
  TaskIcon,
  DealIcon,
  TicketIcon,
  ContractIcon,
  ClientIcon,
  InterventionIcon,
  ContactIcon
} from "../../../../Components/Common/EntityIcons";
import { useSchemaMutation } from "../../../../Helpers/HooksHelper";
import { spaceNotificationSchema } from "../../../../config/schema";
import { withRouter } from "react-router-dom";
import { useSidebar } from "../../../../Components/Sidebar/SidebarV2";
import { useSpace } from "../../../../Contexts/SpaceContext";
import { usePost } from "../../../../Helpers/IOClient";
import { FormattedMessage } from "react-intl";
import { useUnreadEntitiesFetcher } from "../../../../Components/UnreadEntities/UnreadEntities";
import { useEntitySummarySidebar } from "../../../../Components/EntitySummaries/EntitySummariesHelper";

export const NotificationContext = React.createContext();

export const NotificationSchemaContext = React.createContext();

export const useNotificationSchema = () =>
  useContext(NotificationSchemaContext);

export const notificationStatus = {
  default: 0,
  read: 1,
  deleted: 2,
  dismiss: 4
};

export const isNotificationRead = (status) => {
  return hasFlag(status, notificationStatus.read);
};

const NotificationElapsedTime = () => {
  const { Date: creationDate, Status } = useContext(NotificationContext);

  const isNotified = isNotificationRead(Status);

  const elapsedTime = useNotificationElapsedTime(creationDate);

  return (
    <div
      className={classnames("fw-medium fs-14 white-space-pre ml-3", {
        "d-flex align-items-center flex-0-0-auto text-primary": !isNotified
      })}
    >
      {!isNotified && (
        <FontAwesomeIcon size="xs" icon={faCircle} className="mr-1" />
      )}
      {elapsedTime}
    </div>
  );
};

export const GeneralEntityTitle = ({ icon: Icon, text, className }) => {
  return (
    <div className="d-flex justify-content-between">
      <div className={classnames("d-flex align-items-center", className)}>
        <Icon />
        <div className="text-uppercase fs-14 fw-medium ml-2 mr-2">
          <FormattedMessage id={text} />
        </div>
      </div>
      <NotificationElapsedTime />
    </div>
  );
};

export const NotificationClientProfile = ({ client }) => (
  <ClientProfile className="mt-2" client={client} />
);

export const ProjectTitle = (props) => (
  <GeneralEntityTitle icon={ProjectIcon} text="PROJECT" {...props} />
);
export const ContactTitle = (props) => (
  <GeneralEntityTitle icon={ContactIcon} text="CONTACT" {...props} />
);

export const CallTitle = (props) => (
  <GeneralEntityTitle icon={CallIcon} text="CALL" {...props} />
);

export const TaskTitle = (props) => (
  <GeneralEntityTitle icon={TaskIcon} text="TASK" {...props} />
);

export const DealTitle = (props) => (
  <GeneralEntityTitle icon={DealIcon} text="DEAL" {...props} />
);

export const TicketTitle = (props) => (
  <GeneralEntityTitle icon={TicketIcon} text="CASE" {...props} />
);

export const ContractTitle = (props) => (
  <GeneralEntityTitle icon={ContractIcon} text="CONTRACT" {...props} />
);

export const SubscriptionTitle = (props) => (
  <GeneralEntityTitle icon={ContractIcon} text="SUBSCRIPTION" {...props} />
);

export const ClientTitle = (props) => (
  <GeneralEntityTitle icon={ClientIcon} text="ACCOUNT" {...props} />
);

export const InterventionTitle = (props) => (
  <GeneralEntityTitle icon={InterventionIcon} text="TIME_RECORD" {...props} />
);

export const NotificationContainer = withRouter(
  ({
    children,
    formOptions,
    redirectUrl,
    history,
    staticContext,
    location,
    ...props
  }) => {
    const notf = useContext(NotificationContext);
    const { schema } = useContext(NotificationSchemaContext);
    const refetchCount = useUnreadEntitiesFetcher();
    const mutateNotification = useSchemaMutation(schema, notf ? notf.Id : null);
    const [, closeSidebar] = useSidebar() || [];
    const space = useSpace();
    const openForm = useEntitySummarySidebar();
    const [post] = usePost(
      `spaces/${space.Id}/notifications/${notf ? notf.Id : null}/read`,
      { notify: false },
      {
        onSuccess: () => {
          if (formOptions) {
            openForm({
              type: formOptions.formType,
              props: {
                id: formOptions.Id
              }
            });
          }
          refetchCount && setTimeout(() => refetchCount(), 4000);
        }
      }
    );
    if (!notf) return <div>error</div>;

    const handleClick = () => {
      if (!isNotificationRead(notf.Status)) {
        mutateNotification({
          ...notf,
          Status: addFlag(notf.Status, notificationStatus.read)
        });
        post();
      } else {
        if (formOptions) {
          openForm({
            type: formOptions.formType,
            props: {
              id: formOptions.Id
            }
          });
        }
      }
      if (!formOptions && redirectUrl) {
        history.push(`/s/${space.Id}/${redirectUrl}`);
        closeSidebar && closeSidebar();
      }
    };

    return (
      <div
        onClick={handleClick}
        className={"ar-notf-item py-3 px-4 text-black"}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export const GeneralNotificationItem = ({
  TitleComponent,
  redirectUrl,
  OriginComponent,
  className,
  formOptions,
  body
}) => {
  if (OriginComponent) {
    return (
      <NotificationContainer
        formOptions={formOptions}
        redirectUrl={redirectUrl}
      >
        <div className="d-flex justify-content-between of-hidden mb-2">
          <div
            className={classnames(
              "d-flex align-items-center text-truncate",
              className
            )}
          >
            {OriginComponent}
          </div>
          <NotificationElapsedTime />
        </div>
        {body}
      </NotificationContainer>
    );
  }
  if (TitleComponent)
    return (
      <NotificationContainer
        formOptions={formOptions}
        redirectUrl={redirectUrl}
      >
        <TitleComponent className="mb-1" />
        {body}
      </NotificationContainer>
    );
  else
    return (
      <NotificationContainer
        formOptions={formOptions}
        redirectUrl={redirectUrl}
      >
        <div className="d-flex justify-content-between mb-2">
          <div></div>
          <NotificationElapsedTime />
        </div>
        {body}
      </NotificationContainer>
    );
};

export const buildNotificationEndpoint = (
  routeName,
  fieldName,
  value,
  filter
) => {
  return `${routeName}/${value}?${filter}`;
  // return `${routeName}?$filter=${fieldName} eq '${value}'`;
};

export const buildLegacyNotificationEndpoint = (
  routeName,
  fieldName,
  value
) => {
  return `${routeName}?$filter=${fieldName} eq '${value}'`;
};
