import React, { useEffect, useState, useContext, useCallback, useRef } from 'react'

import { useSpaceQuery } from '../../Helpers/IOClient'

const Context = React.createContext()

export const DashboardProvider = ({ children, autoRefresh = false, timeout = 900000 }) => {
	const listenersRef = useRef([])

	const updateListeners = useCallback((update) => {
		listenersRef.current = update(listenersRef.current)
	}, [])
	const mountedRef = useRef()
	useEffect(() => {
		if (!autoRefresh) {
			mountedRef.current = true;
			return
		}

		if (mountedRef.current === true) {
			for (const listener of listenersRef.current) {
				listener()
			}
		}
		mountedRef.current = true;
		const interval = setInterval(() => {
			for (const listener of listenersRef.current) {
				listener()
			}
		}, timeout);
		return () => clearInterval(interval)
	}, [autoRefresh, timeout])


	return <Context.Provider value={updateListeners}>
		{children}
	</Context.Provider>
}

export const useDashboardKey = () => {

	const [key, setKey] = useState(0)
	const setListener = useContext(Context)
	useEffect(() => {

		const listener = () => {
			setKey(k => k + 1)
		}

		setListener((listeners) => [...listeners, listener])

		return () => {
			setListener((listeners) => {
				const newListeners = [...listeners]
				const index = listeners.indexOf(listener)
				newListeners.splice(index, 1)
				return newListeners
			})
		}
	}, [setListener])
	return key
}

export function useDashboardQuery(endpoint, schema = null, options) {
	const setListener = useContext(Context)
	const response = useSpaceQuery(endpoint, schema, { ...options, cache: false, })
	const { refetch } = response
	useEffect(() => {
		const listener = refetch

		setListener((listeners) => [...listeners, listener])

		return () => {
			setListener((listeners) => {
				const newListeners = [...listeners]
				const index = listeners.indexOf(listener)
				newListeners.splice(index, 1)
				return newListeners
			})
		}
	}, [refetch, setListener])

	return response
}