import React from "react";

import { Switch, Route, useRouteMatch } from "react-router-dom";
import AccountDetails from "./AccountDetails/AccountDetails";
// import CallDetails from "./CallDetails/CallDetails";

const Accounts = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/:accountId`}>
        <AccountDetails />
      </Route>
      {/* <Route>
        <RealTimePageProvider pageType={RealTimePageEnum.Call}>
          <CallOverview />
        </RealTimePageProvider>
      </Route> */}
    </Switch>
  );
};

export default Accounts;
