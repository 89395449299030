import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const ContractDetailsComp = React.lazy(() =>
  import(/* webpackChunkName: "contracts-details" */ "./ContractDetails2Comp")
);

const ContractDetails = () => {
  return (
    <SuspenseErrorBoundary>
      <ContractDetailsComp />
    </SuspenseErrorBoundary>
  );
};

export default ContractDetails;
