import React from "react";
import classnames from "classnames";
import "./Badge.css";

const Badge = ({ ...props }) => {
  const {
    className,
    extraClassName,
    type,
    noTruncate = false,
    children,
    style
  } = props;
  return (
    <div
      style={style}
      className={classnames(
        "ar-badge d-flex align-items-center ",
        type,
        className,
        { "text-truncate": !noTruncate }
      )}
    >
      <span
        className={classnames(extraClassName, { "text-truncate": !noTruncate })}
      >
        {children}
      </span>
    </div>
  );
};

export default Badge;
