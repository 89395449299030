import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
// import RedirectToStart from "./RedirectToStart";

const PrivateRoute = ({ isAuthenticated, children, ...rest }) => {
  if (!isAuthenticated) return <Redirect to="/login" />;

  return <Route {...rest}>{children}</Route>;
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.Auth.isAuthenticated
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     onTodoClick: id => {
//       dispatch(toggleTodo(id))
//     }
//   }
// }

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(PrivateRoute);
