import React, { useContext, useMemo, useState } from "react";
import { useSpaceQuery, useSpacePost } from "../../../Helpers/IOClient";
// import { spaceDocumentsSchema } from "../../../config/schema";
import Button from "../../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faPrint } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
// import LoadableButton from "../../../Components/Button/LoadableButton";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import edjsParser from "editorjs-parser";
import { customParsers } from "./DocPlugins/DocHtmlCustomParses";
// import { useCurrentAccount } from "../../../Contexts/UserContext";
import { useSpace } from "../../../Contexts/SpaceContext";
import print from "print-js";
import { GetResolvedDocHtml } from "./DocPlugins/TestFile";
import { convertDocumentEntityToForm } from "../DocumentsOverview/DocumentsHelper";
// import { getInterventionTarget } from "../../../Helpers/FormFormmaterHelper";
import {
  AccountTags,
  ProjectTags,
  CallTags,
  InterventionTags,
  TaskTags,
  ContractTags,
  SubscriptionTags,
  DealTags,
  CaseTags
} from "../DocumentSidebarTabs/DocumentsTags/OriginTags";
import { EntityPopupContext } from "../../../Components/EntityPopup/EntityPopupHelper";
import { handleError } from "../../../Helpers/MiscHelper";
import { useToast } from "../../../Components/Toast/ToastProvider";

export const PreviewPdfDocumentContext = React.createContext();

const initialResolvedJson = (resolvedData) => {
  const { Json, Files } = resolvedData;
  let resolvedJs = JSON.parse(Json || "{}");
  if (resolvedJs.blocks && resolvedJs.blocks.length > 0) {
    for (const t of resolvedJs.blocks) {
      if (t.type === "image") {
        const { label } = t.data;
        if (typeof Files === "object" && Files[label]) {
          t.data.url = Files[label].Url;
          t.data.label = label;
          t.data.name = Files[label].Name + Files[label].Extension;
          t.data.isLink = true;
        }
      }
      t.data.tunes = t.tunes;
    }
  }
  return resolvedJs;
};

export const DocumentAutoSaveActionsContext = React.createContext();

export const DocumentGeneratePdfFromDocument = ({ id, preview }) => {
  const contextValue = useContext(DocumentAutoSaveActionsContext);
  const [docData, setDocData] = useState(null);
  const closePopup = useContext(EntityPopupContext);
  const createToast = useToast();
  const intl = useIntl();
  const handlePreview = useContext(PreviewPdfDocumentContext);
  // eslint-disable-next-line no-unused-vars
  const { hasChanges, handleAutoSave, autoSaveLoading } = useMemo(() => {
    if (contextValue) {
      return { ...contextValue };
    } else return {};
  }, [contextValue]);

  const [postPrint, { loading: printLoading }] = useSpacePost(
    `Documents/${id}/PDF`,
    null,
    {
      cache: false,
      onSuccess: ({ data }) => {
        let binaryString = window.atob(data);

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        if (preview) {
          handlePreview(url);
        } else print(url);
        setDocData(null);
        closePopup();
      },
      onError: (error) => {
        handleError(createToast, error);
        setDocData(null);
        closePopup();
      }
    }
  );
  const space = useSpace();

  const resolvedUrl = useMemo(() => {
    if (!docData) return null;
    const { DocTarget } = convertDocumentEntityToForm(docData);
    const {
      type,
      data: { Id }
    } = DocTarget;

    switch (type) {
      case "Client":
        return `clients/${Id}`;

      case "Project":
        return `projects/${Id}`;

      case "Call":
        return `calls/${Id}`;

      case "Intervention":
        return `interventions/${Id}`;

      case "Task":
        return `tasks/${Id}`;

      case "Contract":
        return `contracts/${Id}`;

      case "Subscription":
        return `subscriptions/${Id}`;

      case "Deal":
        return `deals/${Id}`;

      case "Ticket":
        return `tickets/${Id}`;

      default:
        return null;
    }
  }, [docData]);

  const { loading: loadingorigin } = useSpaceQuery(resolvedUrl, null, {
    onSuccess: ({ data: originData }) => {
      const { DocTarget } = convertDocumentEntityToForm(docData);
      const { type } = DocTarget;
      let resolvedPrintTags, originVal;
      switch (type) {
        case "Client":
          resolvedPrintTags = [...AccountTags];
          originVal = originData;
          break;
        case "Project":
          resolvedPrintTags = [...ProjectTags];
          originVal = originData;
          break;
        case "Call":
          resolvedPrintTags = [...CallTags];
          originVal = originData;
          break;
        case "Intervention":
          resolvedPrintTags = [...InterventionTags];
          originVal = originData;
          break;
        case "Task":
          resolvedPrintTags = [...TaskTags];
          originVal = originData;
          break;
        case "Contract":
          resolvedPrintTags = [...ContractTags];
          originVal = originData;
          break;
        case "Subscription":
          resolvedPrintTags = [...SubscriptionTags];
          originVal = originData;
          break;
        case "Deal":
          resolvedPrintTags = [...DealTags];
          originVal = originData;
          break;
        case "Ticket":
          resolvedPrintTags = [...CaseTags];
          originVal = originData;
          break;

        default:
          break;
      }
      const { blocks, tagsState } = JSON.parse(docData.Json);
      function getFirstGroup(regexp, str) {
        return Array.from(str.matchAll(regexp), (m) => m[1]);
      }
      const stringifed = JSON.stringify(blocks);
      const meTags = getFirstGroup(/{\[([.\w]+)\]}/g, stringifed);
      // stringifed.matchAll() || [];

      var uniqueMeTags = meTags.filter((v, i, a) => a.indexOf(v) === i);

      if (tagsState && typeof tagsState === "object") {
        for (const key in tagsState) {
          if (Object.hasOwnProperty.call(tagsState, key)) {
            const element = tagsState[key];
            resolvedPrintTags.push({
              name: key,
              val: () => element
            });
          }
        }
      }

      const parsedJson = initialResolvedJson(docData);
      const parser = new edjsParser(null, customParsers);
      const parsed = parser.parse(parsedJson);
      const resolvedMargin =
        parsedJson.marginType === "normal"
          ? "10mm"
          : parsedJson.marginType === "small"
          ? "5mm"
          : "20mm";

      let markup = GetResolvedDocHtml(parsed, {
        newFontSize: parsedJson.fontSize + "px",
        fontFamily: parsedJson.fontFamily,
        newFontLineHeight:
          parsedJson.fontSize === "12"
            ? "20px"
            : parsedJson.fontSize === "14"
            ? "22px"
            : "24px",
        newPadding: resolvedMargin
      });

      for (const tag of uniqueMeTags) {
        const { val } = resolvedPrintTags.find((y) => y.name === tag) || {};
        let tagValue = typeof val === "function" ? val(originVal, intl) : "";
        markup = markup.replaceAll(`{[${tag}]}`, tagValue || "");
      }

      postPrint({
        SpaceId: space.Id,
        Html: markup,
        Margins: {
          Top: resolvedMargin,
          Bottom: resolvedMargin,
          Left: resolvedMargin,
          Right: resolvedMargin
        }
      });
    },
    onError: (error) => {
      handleError(createToast, error);
      setDocData(null);
      closePopup();
    }
  });

  const { loading, refetch } = useSpaceQuery(`documents/${id}`, null, {
    autoFetch: false,
    onSuccess: ({ data }) => {
      setDocData(data);
    },
    onError: (error) => {
      handleError(createToast, error);
    }
  });

  const handleClick = () => {
    if (!hasChanges) {
      refetch();
    }
  };

  return (
    <Button
      // innerRef={innerRef}
      vType={"link"}
      disabled={hasChanges}
      className={"text-left"}
      onClick={(e) => {
        if (!(loading || printLoading || autoSaveLoading || loadingorigin)) {
          handleClick();
        }
      }}
    >
      <div className="d-flex align-items-center">
        <div className="mr-1 ar-form-button-link-icon">
          {loading || printLoading || autoSaveLoading ? (
            <LoaderSpinner size={"xxs"} className="text-primary" />
          ) : preview ? (
            <FontAwesomeIcon icon={faFilePdf} />
          ) : (
            <FontAwesomeIcon icon={faPrint} />
          )}
        </div>
        <span className="fw-regular fs-14">
          {preview ? (
            <FormattedMessage id={"EXPORT_PDF"} />
          ) : (
            <FormattedMessage id="PRINT" />
          )}
        </span>
      </div>
    </Button>
  );
};
