import React from "react";
import { connect } from "react-redux";
import {  Redirect } from "react-router-dom";

const AutoRedirect = ({ isAuthenticated }) => {
  if (isAuthenticated) return <Redirect to="/s" />;
  else return <Redirect to="/login" />;
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.Auth.isAuthenticated
  };
};

export default connect(
  mapStateToProps
)(AutoRedirect);
