import React, { useState, useContext, useMemo, useRef, useEffect } from "react";
import GridLayout, { WidthProvider } from "react-grid-layout";
import { usePrevious } from "../../Helpers/HooksHelper";
// import './ReactGridLayoutStyles.css';

// import {
//   ServicesLayout,
//   ServicesTicketLayout
// } from "./DashboardDefaultLayouts";

const createDashboardLayout = (layout) => {
  const layoutDict = {};
  for (const module of layout) {
    layoutDict[module.i] = module;
  }
  return layoutDict;
};

// const dashboardLayoutDict = createDashboardLayout(dashboardLayout);

export const LayoutContext = React.createContext({});

export const LayoutContextProvider = ({ name, layout, children }) => {
  const [resolvedLayout, setResolvedLayout] = useState(() => {
    return getFromLS(name) || layout;
  });

  const value = useMemo(() => {
    const resetLayout = (disableAll) => {
      setResolvedLayout([...layout]);
      saveToLS(name, layout, disableAll);
    };

    return {
      resetLayout,
			setResolvedLayout,
      layout: resolvedLayout,
      name
    };
  }, [resolvedLayout, name, layout]);

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export const createLayoutProvider = (Component, props) => {
  return () => {
    return (
      <LayoutContextProvider {...props}>
        <Component />
      </LayoutContextProvider>
    );
  };
};

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function getAllFromLS() {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls;
}

function saveToLS(key, value, disableAll = false) {
  const ls = getAllFromLS();
  if (global.localStorage && ls) {
    ls[key] = value;
    global.localStorage.setItem("rgl-7", JSON.stringify(ls));
  } else if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-7",
      JSON.stringify({
        [key]: value
      })
    );
  }
}

const DynamicGridLayout = WidthProvider(GridLayout);

const creatLayoutDict = (layout) => {
  return layout.map((e) => {
    return e.i;
  });
};

export const DashboardSettingsContext = React.createContext();

const DashboardLayout = ({ children, cols, layoutConfig, ...rest }) => {
  const { layout: baseLayout, name, setResolvedLayout } = useContext(LayoutContext);
  // const [layout, setLayout] = useState(baseLayout);

  const isResetRef = useRef(false);
  const prevBaseLayout = usePrevious(baseLayout);

  const activeLayoutDict = useMemo(
    () => createDashboardLayout(layoutConfig),
    [layoutConfig]
  );

  const timeoutRef = useRef();

  useEffect(() => {
    if (
      prevBaseLayout &&
      baseLayout !== prevBaseLayout &&
      !isResetRef.current
    ) {
      isResetRef.current = true;
      // setLayout(baseLayout);
    }
  }, [baseLayout, prevBaseLayout]);

  const handleLayoutDifrences = (newlayout) => {
    const newLayoutDict = creatLayoutDict(newlayout);
    const prevLaoutoutDict = creatLayoutDict(baseLayout);
    return newLayoutDict.filter((x) => !prevLaoutoutDict.includes(x));
  };

  const handleLayoutChange = (Receivedlayout) => {
    isResetRef.current = false;
    let newLayout = [...Receivedlayout];
    if (newLayout.length > baseLayout.length) {
      const layoutDifrence = handleLayoutDifrences(newLayout);
      newLayout = [...baseLayout];
      for (const obj of layoutDifrence) {
        newLayout.unshift(activeLayoutDict[obj]);
      }
    }
    saveToLS(name, newLayout);
    setResolvedLayout(newLayout);
  };

  return (
    <DashboardSettingsContext.Provider value={handleLayoutChange}>
      <DynamicGridLayout
        cols={cols}
        rowHeight={175}
        layout={baseLayout}
        margin={[30, 30]}
        onResize={() => {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 300);
        }}
        onLayoutChange={handleLayoutChange}
        {...rest}
      >
        {children}
      </DynamicGridLayout>
    </DashboardSettingsContext.Provider>
  );
};

export default DashboardLayout;
