import React from "react";
import { useBilling } from "../../../../Hooks/EntityHooks";
import {
  HistoryHeader,
  HistoryContent,
  HistoryAccountProfile,
  HistoryOverview,
  useHistoryItem,
} from "./HistoryItem";
import { createItemEditDetailsComponent } from "./HistoryItemDetails/HistoryItemDetails";
import { ConnectedBillingOrigin } from "../../../../Containers/Origin/BillingOrigin";
import { BillingTimeCharges } from "./HistoryItemDetails/HistoryItemProperties";
import { BillingStatusProfile } from "../../../Billing/Billing";
import { FormattedMessage } from "react-intl";

const BillingHistoryOverview = () => {
  const { Billing, IsCreation, Account } = useHistoryItem();
  const item = useBilling(Billing);
  const { Id } = item;
  if (IsCreation)
    return (
      <HistoryOverview>
        <HistoryAccountProfile account={Account} />
        <span>
          {" "}
          <FormattedMessage id={"CREATED_CLASSIFICATION"} />{" "}
        </span>
        <b>{Id}</b>
      </HistoryOverview>
    );

  return (
    <HistoryOverview>
      <HistoryAccountProfile account={Account} />
      <span>
        {" "}
        <FormattedMessage id={"EDITED_CLASSIFICATION"} />{" "}
      </span>
      <b>{Id}</b>
    </HistoryOverview>
  );
};

const BillingEditItemDetails = createItemEditDetailsComponent({
  TimeCharges: BillingTimeCharges,
  Status: {
    NameId: "status",
    ValueComponent: ({ value }) => <BillingStatusProfile status={value} />,
  },
  // Attributes: {
  //   NameId: "status",
  //   ValueComponent: ProjectStatusProfile
  // }
});

const BillingHistoryDetails = () => {
  const { IsCreation } = useHistoryItem();
  if (IsCreation) return null;

  return <BillingEditItemDetails />;
};

const BillingHistoryItem = () => {
  const { Billing } = useHistoryItem();

  return (
    <>
      <HistoryHeader>
        <ConnectedBillingOrigin billing={Billing}>
          {/* <HistoryTitle Icon={BillingIcon}>
            <FormattedMessage id={"CLASSIFICATION"} /> {Billing}
          </HistoryTitle> */}
        </ConnectedBillingOrigin>
      </HistoryHeader>
      <HistoryContent>
        <BillingHistoryOverview />
        <BillingHistoryDetails />
      </HistoryContent>
    </>
  );
};
export default BillingHistoryItem;
