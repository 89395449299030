import React from "react";
import styles from "../AutomationDetails.module.css";
import classnames from "classnames";
const NodeContainer = ({ onDoubleClick, children, selected, enabled }) => {
  return (
    <div
      onDoubleClick={onDoubleClick}
      className={classnames(
        styles.node,
        styles.outline,
        {
          [styles.selectedOutline]: selected,
          [styles.DisabledNode]: enabled === false
        },
        "d-flex of-hidden"
      )}
    >
      {children}
    </div>
  );
};

export default NodeContainer;
