import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";
import {
  CanceledStatusDot,
  CompanyInactiveStatusDot,
  DealsWonStatusDot,
  OpenStatusDot
} from "../../Components/StatusHelper/StatusHelper";

const buildContactStatusType = (status) => {
  switch (status) {
    case false:
    case 0:
    case "0":
      return "inactive";

    default:
      return "purple";
  }
};

const statusNameByValue = (status) => {
  switch (status) {
    case true:
    case 1:
    case "1":
      return <FormattedMessage id={"ACTIVE"} />;
    case false:
    case 0:
    case "0":
      return <FormattedMessage id={"INACTIVE"} />;
    default:
      return null;
  }
};

const ProjectStatusSvgIcons = (status, sensitive = false) => {
  const s = status ? status.toString() : status;
  switch (s) {
    case true:
    case "true":
    case 1:
    case "1":
      return <OpenStatusDot />;
    case false:
    case "false":
    case 0:
    case "0":
      return <CompanyInactiveStatusDot />;
    default:
      return null;
  }
};

const AutomationStatusProfile = ({
  entity,
  status,
  item,
  hideName,
  dotClassName,
  className
}) => {
  //   const resovledStatus = entity ? entity.Status : status || item;
  const resovledStatus = status === undefined ? item : status;
	
  const SvgComponent = useMemo(() => {
    return ProjectStatusSvgIcons(resovledStatus);
  }, [resovledStatus]);
  if (resovledStatus === undefined) return null;

  return (
    <div
      className={classnames(
        `d-flex align-items-center link-color fs-12  status-color-${buildContactStatusType(
          resovledStatus
        )}`,
        className
      )}
    >
      <div
        className={classnames("position-relative status-dot-svg", dotClassName)}
      >
        {SvgComponent}
      </div>
      {!hideName && (
        <div
          style={{ fontWeight: 400 }}
          className="status-label text-black fs-12 text-truncate flex-1 fw-medium"
        >
          {statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

export default AutomationStatusProfile;

export const AutomationStatusTypes = {
  active: 1,
  inactive: 0
};

export const ContactStatusOptions = [
  AutomationStatusTypes.active,
  AutomationStatusTypes.inactive
];

export const AutomationStatusDropdown = (props) => {
  return (
    <KeyedDropdown
      dropdownClassname="text-truncate"
      options={ContactStatusOptions}
      valueComponent={AutomationStatusProfile}
      closeOnSelect
      {...props}
    />
  );
};
