import React, { useMemo, useRef, useState } from "react";
import Input from "./Input";
import classnames from "classnames";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LabeledPasswordInput = ({
  className,
  compclassname,
  text,
  error,
  type,
  InputComponent,
  ...rest
}) => {
  const [typeState, setTypeState] = useState("password");

  const InputRef = useRef();

  const eyeIcon = useMemo(() => {
    if (typeState === "password") {
      return faEyeSlash;
    } else return faEye;
  }, [typeState]);

  const onEyeClick = (e) => {
		e.stopPropagation()
    InputRef.current.focus();

    setTypeState((oldTypeState) => {
      if (oldTypeState === "password") return "text";
      else return "password";
    });
  };

  const onBlur = (e) => {
		e.stopPropagation()
    setTypeState("password");
  };

  const Comp = InputComponent || Input;

  return (
    <div
      onMouseLeave={onBlur}
      className={classnames(className, "position-relative")}
    >
      <div
        className={classnames("mb-1 fs-14 text-black", {
          "text-black": !error,
          "text-danger": Boolean(error)
        })}
      >
        {text}
      </div>
      <Comp
        ref={InputRef}
        type={typeState}
        // onBlur={onBlur}
        {...rest}
        style={{ paddingRight: 30 }}
        className={classnames(error ? "error" : undefined, compclassname)}
      />
      <FontAwesomeIcon
        onClick={typeState === "password" ? onEyeClick : onBlur}
        icon={eyeIcon}
        style={{
          pointerEvents:  "auto",
          cursor: "pointer",
          right: 9,
          top: text ? 37 : 13,
          position: "absolute"
        }}
      />
      {error && <div className="text-danger fs-14 mt-1">{error}</div>}
    </div>
  );
};

const LabeledInput = ({ ...rest }) => {
  const { className, compclassname, text, error, type, InputComponent } = rest;
  const Comp = InputComponent || Input;

  if (type === "password") {
    return <LabeledPasswordInput {...rest} />;
  }

  return (
    <div className={className}>
      <div
        className={classnames("mb-1 fs-14 text-black", {
          "text-black": !error,
          "text-danger": Boolean(error)
        })}
      >
        {text}
      </div>
      <Comp
        type={type}
        {...rest}
        className={classnames(error ? "error" : undefined, compclassname)}
      />
      {error && <div className="text-danger fs-14 mt-1">{error}</div>}
    </div>
  );
};

export default LabeledInput;
