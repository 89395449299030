import React, { useContext, useMemo } from "react";
import { AccountProfileImg } from "../Img/Img";
import { useAccount } from "../../Hooks/EntityHooks";
import classnames from "classnames";
import { NoAvatarProvider } from "./AccountProfileHelper";
import { FormattedMessage } from "react-intl";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { accountSchema } from "../../config/schema";

const AccountProfile = ({
  account,
  noName,
  showRole,
  justImage,
  className,
  forceimage,
  inline,
  ListItem,
  ListItemMultiple,
  maxWidth,
  removeLine,
  Manager,
  PopupShowImage,
  noManager,
  dynamicFont,
  size,
  ...rest
}) => {
  const noAvatar = useContext(NoAvatarProvider);
  const ManagerItem = useAccount(Manager);
  if (!account) return null;
  if (justImage && (!noAvatar || PopupShowImage)) {
    return (
      <AccountProfileImg
        size={ListItemMultiple ? "xsm" : ListItem ? "mds" : size || "sm"}
        account={account}
        circular
      />
    );
  } else if (noAvatar && !forceimage) {
    return (
      <div
        className={classnames(
          "align-items-center text-truncate",
          {
            "d-flex": !inline,
            "d-inline-flex": inline
          },
          className
        )}
      >
        <div className="flex-1 text-truncate">
          <div className="text-white fs-14-enforced mb-1 text-truncate">
            {account.Name}
          </div>
          {showRole && <div className="text-truncate">{account.RoleName}</div>}
        </div>
      </div>
    );
  } else
    return (
      <div
        className={classnames(
          "align-items-center text-truncate",
          {
            "d-flex": !inline,
            "d-inline-flex": inline
          },
          className
        )}
      >
        <AccountProfileImg
          size={ListItemMultiple ? "xsm" : ListItem ? "mds" : size || "sm"}
          account={account}
          circular
          className="mr-1 text-truncate"
        />
        <div className="d-flex flex-column w-100 text-truncate flex-1">
          <div
            style={{ maxWidth: maxWidth ? 102 : "unset" }}
            className={classnames("d-flex flex-column text-truncate", {
              "dropdown-item-maintext": !dynamicFont
            })}
          >
            {!noName && account.Name && (
              <div
                style={{ color: ListItemMultiple && "black" }}
                className={classnames("text-truncate", {
                  "fs-12": !dynamicFont,
                  "text-black": !ListItemMultiple
                })}
              >
                {account.Name}
              </div>
            )}
            {showRole && (
              <div className="dropdown-item-subtext-dif">
                {account.RoleName}
              </div>
            )}
          </div>
          {!noManager && ManagerItem && (
            <div className="dropdown-item-subtext-dif text-truncate">
              <span>
                <FormattedMessage id={"MANAGER"} />: {ManagerItem.Name}
              </span>
            </div>
          )}
        </div>
      </div>
    );
};

export default AccountProfile;

// const mapStateToProps = (state, {account}) => {
//   if(!account) return null;

//   const accountEntity = state.Entities.Accounts[account];

//   const { Id, Name, Description, Image } = accountEntity;

//   return { Id, Name, Description, Image };
// };

export const ConnectAccountEntity = (Component) => {
  return function ConnectedAccountComponent({ account, ...rest }) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const a = useAccount(account);
    const url = useMemo(() => {
      if (!account) return null;
      return !a
        ? `query/accounts?%24filter=((Account%2FId%20eq%20%27${account}%27))`
        : null;
    }, [a, account]);
    useSpaceQuery(url, [accountSchema]);
    return <Component account={a} {...rest} />;
  };
};

// export const ConnectedAccountProfile = ConnectAccountEntity(AccountProfile);

export const ConnectedAccountProfile = ({ account, ...rest }) => {
  const a = useAccount(account);
  const url = useMemo(() => {
    if (!account) return null;
    return !a
      ? `query/accounts?query=${account}`
      : null;
  }, [a, account]);
  useSpaceQuery(url, [accountSchema]);
  return <AccountProfile account={a} {...rest} />;
};
