import React from "react";
import { useCurrency } from "../../Hooks/EntityHooks";
import DropdownInput, {
  DropdownInputContainer
} from "../Dropdown/DropdownInput";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { currencySchema } from "../../config/schema";

export const ConnectedCurrencyProfile = ({ currency }) => {
  const c = useCurrency(currency);
  if (!c) return null;
  return <div>{c.Name}</div>;
};

const CurrencyDropdownInput = props => {
  const { currency } = props;

  return (
    <DropdownInputContainer>
      <DropdownInput {...props} fallbackValue={currency && currency.Name} />
    </DropdownInputContainer>
  );
};

export default CurrencyDropdownInput;

export const ConnectedCurrencyDropdownInput = ({ currency, ...rest }) => {
  const c = useCurrency(currency);
  if (!c) return null;
  return <CurrencyDropdownInput currency={c} {...rest} />;
};

export const useSpaceCurrenciesFetcher = () => {
  const state = useSpaceQuery("query/currency", [currencySchema]);

  return state;
};
