import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const DealsOverviewComp = React.lazy(() =>
  import(/* webpackChunkName: "deals-overview" */ "./DealsOverviewComp")
);

const DealsOverview = () => {
  return (
    <SuspenseErrorBoundary>
      <DealsOverviewComp />
    </SuspenseErrorBoundary>
  );
};

export default DealsOverview;
