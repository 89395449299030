import React, { FC, useEffect, useRef, useState } from "react";
import querystring from "query-string";
import { useLocation } from "react-router-dom";
type AppFavoriteIdType = string | null;

export const AppFavoriteContext = React.createContext<AppFavoriteIdType>(null);
export const AppFavoriteSetterContext = React.createContext<React.Dispatch<
  React.SetStateAction<AppFavoriteIdType>
> | null>(null);

const getFavoriteId = (search: string) => {
  const queryObj = querystring.parse(search);
  return queryObj?.favoriteId;
};

const FavoriteProvider: FC<{children?: React.ReactNode}> = ({ children }) => {
  const [favoriteId, setFavoriteId] = useState<AppFavoriteIdType>(
    () => getFavoriteId(window.location.search) as AppFavoriteIdType
  );

  const location = useLocation();
  const mountRef = useRef(false);
  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
      return;
    }
    const { search } = location;
    const newFavoriteId = getFavoriteId(search);
    setFavoriteId(newFavoriteId as AppFavoriteIdType);
  }, [location]);
  // const favoriteId = useMemo(() => {
  //   const queryObj = querystring.parse(search);
  //   const { favoriteId } = queryObj;
  //   return favoriteId;
  // }, [location]);

  return (
    <AppFavoriteContext.Provider value={favoriteId}>
      <AppFavoriteSetterContext.Provider value={setFavoriteId}>
        {children}
      </AppFavoriteSetterContext.Provider>
    </AppFavoriteContext.Provider>
  );
};

export default FavoriteProvider;
