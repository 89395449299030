import React from "react";
import { ClientStatusTypes, ClientStatusProfile } from "./ClientProfile";
import KeyedDropdown from "../Dropdown/KeyedDropdown";
import "./Clients.css";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

const options = [
  ClientStatusTypes.active,
  ClientStatusTypes.disabled,
  ClientStatusTypes.onApproval,
  ClientStatusTypes.blocked
];

export const ClientStatusDropdown = React.memo(function ClientStatusDropdown(
  props
) {
  const { ...rest } = props;

  return (
    <KeyedDropdown
      arrowClassnames={"text-primary ml-2 fs-14"}
      className="fs-12 text-truncate"
      options={options}
      dropdownClassname="text-truncate"
      valueComponent={ClientStatusProfile}
      closeOnSelect
      {...rest}
    />
  );
});

const buildText = (value) => {
  switch (value) {
    case ClientStatusTypes.active:
      return <FormattedMessage id={"ACTIVE"} />;

    case ClientStatusTypes.disabled:
      return <FormattedMessage id={"INACTIVE"} />;
    case ClientStatusTypes.onApproval:
      return <FormattedMessage id={"IN_APPROVAL"} />;
    case ClientStatusTypes.blocked:
      return <FormattedMessage id={"BLOCKED"} />;

    default:
      return null;
  }
};

export const DetailsClientStatusDropdown = (props) => {
  const { value, preview, ...rest } = props;

  let color;

  switch (value) {
    case ClientStatusTypes.active:
      color = "success";
      break;

    case ClientStatusTypes.disabled:
      color = "grey";
      break;
    case ClientStatusTypes.onApproval:
      color = "purple";
      break;
    case ClientStatusTypes.blocked:
      color = "orange";
      break;

    default:
      color = "grey";
      break;
  }

  return (
    <KeyedDropdown
      skeleton={value === undefined}
      preventChange={preview}
      className={classnames(
        "alt ar-details-view-status-button sm mx-auto",
        color
      )}
      options={options}
      valueComponent={({ item }) => buildText(item)}
      closeOnSelect
      onTop
      value={value}
      {...rest}
    />
  );
};
