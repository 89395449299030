import React, { useContext, useEffect, useState } from "react";
import { OmniButtonBase } from "./OmniButtonHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { ObfuscateProvider } from "../Space/Space2";
import ContextMenu, {
  MenuCloseContext
} from "../../Components/ContextMenu/ContextMenu";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";
import classnames from "classnames";
import Modal from "../../Components/Modal/Modal";
import { subscribeUniqueEvent } from "../../Helpers/ComponentsHelper";
import styles from "./OmniButton.module.css";

const OmniButton = ({
  labelText,
  tooltipStyle,
  tooltipPlacement,
  buttonClass,
  size,
  className,
  placement,
  style,
  ...rest
}: any) => {
  const ObfuscateState = useContext(ObfuscateProvider);
  // const anchorRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const listener = (e: any) => {
      //F1
      if (e.keyCode === 113) {
        e.preventDefault();
        setIsOpen(true);
      }
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, []);

  useEffect(() => {
    return subscribeUniqueEvent((e: any) => {
      if (e.keyCode === 27) {
        e.preventDefault();
        setIsOpen(false);
      }
    });
  }, [isOpen]);

  const handleCLick = () => {
    setIsOpen(!isOpen);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div
      style={{ pointerEvents: ObfuscateState === true ? "none" : undefined }}
      className={className || "cursor-pointer ml-1"}
    >
      <div className="d-flex align-items-center">
        <div
          style={{
            opacity: ObfuscateState === true ? 0.3 : undefined,
            pointerEvents: ObfuscateState === true ? "none" : undefined
          }}
          className={classnames(
            "ar-header-rounded-icon d-flex align-items-center flex-0-0-auto",
            className,
            buttonClass
          )}
          onClick={handleCLick}
        >
          <FontAwesomeIcon size={size ? size : "lg"} icon={faPlus} />
          {labelText && (
            <span style={{ fontWeight: 500 }} className="ml-12">
              {labelText}
            </span>
          )}
        </div>
      </div>

      <Modal enableCloseButton isOpen={isOpen} onClose={handleClose}>
        <MenuCloseContext.Provider value={handleClose}>
          <div
            className={classnames(styles.OmniButtonModalContainer, "px-3 py-2")}
          >
            <OmniButtonBase />
          </div>
        </MenuCloseContext.Provider>
      </Modal>

      {/* <TicketFormButton hideWithoutPermission />

        <TaskFormButton hideWithoutPermission />

        <InterventionFormButton hideWithoutPermission />

        <CallFormButton hideWithoutPermission />

        <ProjectFormButton hideWithoutPermission />

        <ClientFormButton hideWithoutPermission />

        <ContractFormButton hideWithoutPermission />

        <ContactFormButton hideWithoutPermission /> */}
    </div>
  );
};

export default OmniButton;

export const OmniButtonContext = ({ targetRef, date }: any) => {
  return (
    <ContextMenu targetRef={targetRef}>
      <OmniButtonBase date={date} />
    </ContextMenu>
  );
};
