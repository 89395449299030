import React, {
  useContext,
} from "react";
import { ReportsDateProvider } from "../../../Containers/Dashboard/Dashboard";
import { History } from "./History";

export const ReportHistory = ({ reportsType, ...rest }) => {
  const DataReports = useContext(ReportsDateProvider);

  if (DataReports[0] === reportsType && DataReports[4] === "Account")
    return <History accountId={DataReports[2].Id} {...rest} />;
  else return <History {...rest} />;
};
