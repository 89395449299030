import React, { useState } from "react";
import FormInput from "../../../Components/Forms/FormInput/FormInput";

import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { withSpace } from "../../../Contexts/SpaceContext";
import withSpaceEntityForm from "../../../Components/Forms";
import SideMenuForm from "../../../Components/Forms/SideMenuForm";
import { spaceInterventionTypeSchema } from "../../../config/schema";
import {
  OrganizationDropdown,
  //   DepartmentTeamDropdown,
  convertToDepartmentTeams,
  convertDepartmentTeams
} from "../../AdvancedMultiInputs";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import LoadableButton from "../../../Components/Button/LoadableButton";
import Button from "../../../Components/Button/Button";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";
import { useToast } from "../../../Components/Toast/ToastProvider";
import Switch from "../../../Components/Switch/Switch";

const RemovalFooter = ({ endpoint, refetchRef, onCancel, Name }) => {
  const createToast = useToast();
  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceInterventionTypeSchema);
  const [deleteUser, { loading }] = useSidebarSpaceDelete(
    endpoint,
    spaceInterventionTypeSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "INTERVENTION_TYPE" })} ${Name}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  return (
    <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
      <div className="text-black">
        <FormattedMessage id="ELIMINATE_INTERVENTION_TYPE" />
      </div>

      <div className="d-flex align-items-center">
        <Button vType="outline-danger" className="mr-3" onClick={onCancel}>
          <FormattedMessage id="BACK" />
        </Button>

        <LoadableButton
          vType="danger"
          isLoading={loading}
          onClick={() => {
            deleteUser();
          }}
        >
          <FormattedMessage id="DELETE" />
        </LoadableButton>
      </div>
    </div>
  );
};

const InterventionTypeForm = ({ refetchRef, ...props }) => {
  const { formStatus, isChild, form, setFormState } = props;

  const handleCheckChanges = ({ target: { name, checked } }) => {
    setFormState((oldF) => {
      return {
        ...oldF,
        [name]: checked
      };
    });
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const { Id, CanDelete, Name } = form;

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id="INTERVENTION_TYPE" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideMenuForm
          formStatus={formStatus}
          footerComponent={
            !Id ? (
              <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
                <div></div>
                <LoadableButton
                  isLoading={formStatus.isProcessing}
                  onClick={formStatus.submit}
                >
                  <FormattedMessage id={"CONFIRM"} />
                </LoadableButton>
              </div>
            ) : !isDeleting ? (
              <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
                {CanDelete ? (
                  <Button
                    vType="link-danger fw-bold"
                    className="mr-3"
                    onClick={() => setIsDeleting(true)}
                  >
                    <span style={{ fontWeight: 500 }}>
                      <FormattedMessage id="DELETE" />
                    </span>
                  </Button>
                ) : (
                  <div></div>
                )}
                <LoadableButton
                  isLoading={formStatus.isProcessing}
                  onClick={formStatus.submit}
                >
                  <FormattedMessage id={"CONFIRM"} />
                </LoadableButton>
              </div>
            ) : (
              CanDelete && (
                <RemovalFooter
                  Name={Name}
                  endpoint={`interventionTypes/${Id}`}
                  refetchRef={refetchRef}
                  onCancel={() => setIsDeleting(false)}
                />
              )
            )
          }
        >
          <FormInput
            disabled={isChild}
            name="Organizations"
            className="mb-3"
            textId="COMPANIES"
            inputType={OrganizationDropdown}
            multiple
          />

          <FormInput name="Name" textId="NAME" className="mb-3" />

          <FormInput
            name="Enabled"
            onChange={handleCheckChanges}
            textId={"ACTIVE"}
            inputType={Switch}
            className="mb-3"
          />

          <FormInput
            name="Billable"
            onChange={handleCheckChanges}
            textId={"BILLABLE"}
            inputType={Switch}
            className="mb-3"
          />
          <FormInput
            name="IsFutureTime"
            onChange={handleCheckChanges}
            textId={"ALLOW_FUTURE"}
            inputType={Switch}
            className="mb-3"
          />

          {/* <FormInput
          name="DepartmentTeams"
          className="mb-3"
          text={intl.formatMessage({ id: "department_team" })}
          inputType={DepartmentTeamDropdown}
          multiple
        /> */}

          {/* <FormInput
          name="Files"
          text={intl.formatMessage({ id: "files" })}
          inputType={FileUploader}
          className="mb-3"
        /> */}
        </SideMenuForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export default withSpaceEntityForm(
  withSpace(injectIntl(InterventionTypeForm)),
  {
    defaultForm: {
      Name: "",
      Enabled: true,
      Billable: true,
      IsFutureTime: false,
      Organizations: [],
      DepartmentTeams: [],
      Files: []
    },
    validateForm: ({ Name }, addError) => {
      if (!Name) addError("Name");
    },
    convertEntityToForm: (entity) => {
      const { Departments, Teams, ...rest } = entity;

      const DepartmentTeams = convertToDepartmentTeams(entity);

      return {
        ...rest,
        DepartmentTeams
      };
    },
    onSuccess: (props) => {
      const { createToast, intl, form } = props;
      const { Name } = form;
      createToast({
        pos: "tm",
        type: "success",
        title: `${intl.formatMessage({ id: "INTERVENTION_TYPE" })} ${Name}`,
        description: `${intl.formatMessage({
          id: "SUCCESS_SAVED"
        })}`
      });
      // handleToast(props)
    },
    formatForm: (form) => {
      let { DepartmentTeams, Organizations, ...rest } = form;

      const { departments, teams } = convertDepartmentTeams(DepartmentTeams);

      return {
        organizations: Organizations.length === 0 ? undefined : Organizations,
        departments: departments.length === 0 ? undefined : departments,
        teams: teams.length === 0 ? undefined : teams,
        ...rest
      };
      // return createFormData(
      //   {
      //     ...rest,
      //     organizations: Organizations.length === 0 ? undefined : Organizations,
      //     departments: departments.length === 0 ? undefined : departments,
      //     teams: teams.length === 0 ? undefined : teams
      //   }
      // );
    },
    schema: spaceInterventionTypeSchema
    // getEntityId: (state, ownProps) => ownProps.interventionTypeId
  }
);
