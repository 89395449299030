import React, { useMemo, useCallback, useContext, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import querystring from "query-string";

export function useUrlQueryString() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}



const QueryContext = React.createContext();

export const QueryProvider = withRouter(props => {
  const { location, children, history } = props;
  const [queryObj, setQueryObj] = useState(() => {
    return querystring.parse(location.search);
  });

  const updateQuery = useCallback(
    newParams => {
      setQueryObj(q => {
        const newObj = {
          ...q,
          ...newParams
        };
        const search = querystring.stringify(newObj);
        history.push({
          search
        });
        return newObj;
      });
    },
    [history]
  );

  const value = useMemo(() => [queryObj, updateQuery], [queryObj, updateQuery]);

  return (
    <QueryContext.Provider value={value}>{children}</QueryContext.Provider>
  );
});

export const useQueryState = () => useContext(QueryContext);
