import React, { useCallback } from "react";
import { useGridRequest } from "../../../Components/CGrid/ServerGrid";
import EmptyListError from "../../../Components/EmptyListContainer/EmptyListError";
import { useContract } from "../../../Hooks/EntityHooks";
import EmptyListContainer from "../../../Components/EmptyListContainer/EmptyListContainer";
import { ContractFormButton } from "../../Form/FormButtons";
import { VirtualGridRow } from "../../../Components/VirtualGrid/VirtualGrid";
import VirtualServerAwareGrid from "../../../Components/VirtualGrid/VirtualServerAwareGrid";
import { moduleTypes } from "../../../Helpers/ModulesHelper";
import { contractsColumns } from "./ContractOverviewComp";
import { FormattedMessage } from "react-intl";

const ContractRow = React.memo(({ value, ...rest }) => {
  const v = useContract(value);
  const canSelectRow = useCallback((v) => !v.Contract, []);
  return (
    <VirtualGridRow
      formType={moduleTypes.contracts}
      className={v && v.IsFollowing && v.IsRead === false && "unread"}
      value={v}
      {...rest}
      canSelectRow={canSelectRow}
    />
  );
});

const canSelectRow = (value) => {
  if (!value) return false;

  return true;

  // const state = store.getState();

  // const intervention = getEntityFromState(
  //   state,
  //   spaceInterventionsSchema,
  //   value
  // );

  // return !intervention.Billing;
};

const ContractGrid = ({ onItemSelect, ...rest }) => {
  // let gridColumnLayout = JSON.parse(
  //   window.localStorage.getItem("ar-grid-column-layout")
  // );
  const { error, results } = useGridRequest();
 
  if (error) return <EmptyListError />;

  if (results && results.length === 0)
    return (
      <EmptyListContainer type="contracts">
        <div className="fw-bold fs-24 text-black ">
          <FormattedMessage id={"EMPTY_CONTRACTS"} />
        </div>
        <div className="fs-18 text-black text-center mb-4">
          <FormattedMessage id={"NO_CONTRACTS"} />
        </div>
        <ContractFormButton></ContractFormButton>
      </EmptyListContainer>
    );

  return (
    <VirtualServerAwareGrid
      sortable
      showTotals
      rowComponent={ContractRow}
      onSelectedItemsChange={onItemSelect}
      canSelectRow={canSelectRow}
      columns={contractsColumns}
      {...rest}
    />
  );
};

export default ContractGrid;
