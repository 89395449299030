import { moduleTypes } from "./ModulesHelper";
import React, { useMemo } from "react";
import { useServerAwareState } from "../Components/CGrid/ServerGrid";
import { useSpace } from "../Contexts/SpaceContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faSuitcase,
  faHandshake,
  faFileContract,
  faCalendarExclamation,
  faClipboardCheck,
  faFileInvoice,
  faPhone,
  faSquareParking,
  faTicketSimple,
  faListCheck,
  faUserClock,
  faShareAlt,
  faFileImport
} from "@fortawesome/pro-light-svg-icons";
import { useCurrentAccount } from "../Contexts/UserContext";

export const fieldDict = {
  [moduleTypes.clients]: "Client",
  [moduleTypes.contacts]: "Contact",
  [moduleTypes.deals]: "Deal",
  [moduleTypes.contracts]: "Contract",
  [moduleTypes.subscriptions]: "Subscription",
  [moduleTypes.classification]: "TimeCharge",
  [moduleTypes.billing]: "Billing",
  [moduleTypes.calls]: "Call",
  [moduleTypes.projects]: "Project",
  [moduleTypes.tickets]: "Ticket",
  [moduleTypes.tasks]: "Task",
  [moduleTypes.interventions]: "TimeCharge",
  [moduleTypes.imports]: "Import"
};

const gridPosition = {
  [moduleTypes.contracts]: 6,
  [moduleTypes.subscriptions]: 6,
  [moduleTypes.billing]: 2,
  [moduleTypes.contacts]: 3
};

const lastIdsDict = {
  [moduleTypes.clients]: 18214,
  [moduleTypes.contacts]: 8623,
  [moduleTypes.deals]: 2326,
  [moduleTypes.contracts]: 522,
  [moduleTypes.subscriptions]: 34,
  [moduleTypes.classification]: 43017,
  [moduleTypes.billing]: 21812,
  [moduleTypes.calls]: 10618,
  [moduleTypes.projects]: 7024,
  [moduleTypes.tickets]: 8520,
  [moduleTypes.tasks]: 11044,
  [moduleTypes.interventions]: 43017
};

const specialUsersDict = {
  "f8c7e29c-4104-4b89-8035-8273e5751277": true,
  "62f02353-eb87-4b8a-b8a5-cc78f5984485": true,
  "15bd28e7-dcfe-4b6e-9162-d5c6dfa86c12": true
};

export const useIsSpecialUser = () => {
  const account = useCurrentAccount();

  return specialUsersDict[account.Id] || false;
};

export const useTempColumns = (columns) => {
  const isSpecial = useIsSpecialUser();
  // return columns;
  const { moduleType } = useServerAwareState() || {};
  const space = useSpace();
  return useMemo(() => {
    if (!isSpecial) return columns;
    if (space.Id !== 1) return columns;
    const field = fieldDict[moduleType];
    const newColumns = [...columns];

    newColumns.splice(gridPosition[moduleType] || 4, 0, {
      width: 100,
      orderBy: false,
      sortable: false,
      field: `${field}/TempId`,
      title: "Nº Antigo",
      type: "number",
      Cell: ({ Data: value }) => {
        if (
          !value.TempId ||
          (lastIdsDict[moduleType] && value.TempId > lastIdsDict[moduleType])
        )
          return null;

        return <div>{value.TempId}</div>;
      }
    });

    return newColumns;
  }, [columns, isSpecial, moduleType, space.Id]);
};

export const IconModuleTypeLib = {
  contacts: <FontAwesomeIcon icon={faUserTie} />,
  clients: <FontAwesomeIcon icon={faSuitcase} />,
  deals: <FontAwesomeIcon icon={faHandshake} />,
  contracts: <FontAwesomeIcon icon={faFileContract} />,
  subscriptions: <FontAwesomeIcon icon={faCalendarExclamation} />,
  classification: <FontAwesomeIcon icon={faClipboardCheck} />,
  billing: <FontAwesomeIcon icon={faFileInvoice} />,
  calls: <FontAwesomeIcon icon={faPhone} />,
  projects: <FontAwesomeIcon icon={faSquareParking} />,
  tickets: <FontAwesomeIcon icon={faTicketSimple} />,
  tasks: <FontAwesomeIcon icon={faListCheck} />,
  interventions: <FontAwesomeIcon icon={faUserClock} />,
  social: <FontAwesomeIcon icon={faShareAlt} />,
  imports: <FontAwesomeIcon icon={faFileImport} />
};

export const useTempFilters = (columns, optionalModuleType) => {
  const isSpecial = useIsSpecialUser();
  // return columns;
  const { moduleType } = useServerAwareState() || {};
  const resolvedModuleType = optionalModuleType || moduleType;

  const space = useSpace();
  return useMemo(() => {
    if (!isSpecial) return columns;
    if (space.Id !== 1 || !columns) return columns;
    const field = fieldDict[resolvedModuleType];
    const newColumns = [...columns];

    newColumns.push({
      icon: IconModuleTypeLib[resolvedModuleType],
      title: "Nº Antigo",
      field: `${field}/TempId`,
      type: "string"
    });

    return newColumns;
  }, [columns, isSpecial, resolvedModuleType, space.Id]);
};
