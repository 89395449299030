// import React, { useRef, useEffect } from "react";
// import classnames from "classnames";
import {
  darkred,
  //   red,
  //   lightred,
  //   darkgrey,
  //   grey,
  //   darkgreen,
  //   green,
  //   monthNames,
  createChartComponent,
  // darkblue,
  // opaqueDarkBlue,
  darkgreen,
  // lightgreen,
  green
  //   getMonth
} from "./echartDefaults";
// include ECharts main module
// include bar chart
require("echarts/lib/chart/bar");
// include tooltip and title component
require("echarts/lib/component/title");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");

export const ClosedBarChart = createChartComponent(
  (Data, intl, difrentCloseColor) => {
    const mappedArr = [];

    for (const obj of Data) {
      const v = parseFloat(obj.Average);
      mappedArr.push(v / 1000 / 60 / 60 / 24);
    }

    //   var maxNumGraph = Math.max(...mappedArr);

    //   var restGraph = Data.map(e => {
    //     return (
    //       parseInt(maxNumGraph) +
    //       6 -
    //       Math.round(parseInt(e.Average) / 1000 / 60 / 60 / 24)
    //     );
    //   }).reverse();

    return {
      tooltip: {
        show: false,
        trigger: "item",
        textStyle: {
          color: "#FFFFFF",
          fontWight: "bolder"
        },
        axisPointer: {
          type: "shadow"
        }
      },
      // legend: {
      //   data: [intl.formatMessage({ id: "AVERAGE_CLOSE_TIME" })],
      //   selectedMode: false,
      //   show: true
      // },
      grid: {
        //   bottom: "5%",
        //   top: "7%",
        containLabel: true
        //   show: true
      },
      xAxis: {
        type: "value",
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      yAxis: [
        {
          type: "category",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: Data.map((e) => {
            return {
              value: e.ManagerAccount?.Name,
              textStyle: {
                color: "#002B63",
                fontWeight: "bold"
              }
            };
          }).reverse()
        }
        //   {
        //     type: "category",
        //     axisLine: {
        //       show: false
        //     },
        //     axisTick: {
        //       show: false
        //     },
        //     data: Data.map(e => {
        //       return { value: "Dias" };
        //     }).reverse()
        //   }
      ],
      series: [
        {
          name: "Background",
          type: "bar",
          stack: "stack",
          itemStyle: {
            color: difrentCloseColor ? "#FFD5D7" : green,
            borderRadius: [0, 0, 0, 0]
          },
          barGap: "40%",
          data: mappedArr
            .map((e) => {
              return {
                value:
                  parseFloat(e) >= 1
                    ? Math.round(e)
                    : parseFloat(e) > 0 && parseFloat(e) < 1
                    ? 1
                    : 0,
                label: {
                  show: parseFloat(e) > 0,
                  position: "right",
                  color: difrentCloseColor ? darkred : darkgreen,
                  fontWeight: "bold",
                  formatter: "{c} Dia(s)"
                }
              };
            })
            .reverse()
        }
        //   {
        //     name: "",
        //     type: "bar",
        //     stack: "stack",
        //     itemStyle: {
        //       color: "#f2f2f2",
        //       borderRadius: [0, 0, 0, 0],
        //       emphasis: {
        //         color: "#f2f2f2 "
        //       }
        //     },
        //     barGap: "40%",
        //     data: restGraph
        //   }
      ]
    };
  }
);
