import React from "react";
import { ReactComponent as WarningBox } from "../../Assets/Svg/error_view_unsubscribed.svg";
import { BasicFooter } from "../BasicPageContainer/BasicPageContainer";
import "./CanceledView.css";
import { FormattedMessage } from "react-intl";
import { useSpace } from "../../Contexts/SpaceContext";
import { Link } from "react-router-dom";
import Button from "../../Components/Button/Button";

export const SubscriptionTrialEndView = () => {
  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center">
      <div className="align-items-sm-center d-flex flex-1 flex-column justify-content-center">
        <div className="mb-3">
          <WarningBox />
        </div>
        <div className="CancelationViewTitle mb-4">
          <FormattedMessage id="TRIAL_ENDED" />
        </div>
        <div style={{ textAlign: "center" }} className="text-black fs-22 mb-3">
          <FormattedMessage id="SUBSCRIPTION_OF_WORKSPACE_TRIAL_ENDED" />
        </div>
        <div className="text-black fs-16 mb-4">
          <FormattedMessage id="PLEASE_CONTACT_YOUR_ADMIN" />
        </div>
        <div>
          <Link to="/s">
            <Button
              onclick={() => {
                localStorage.removeItem("Airdesk-Previous-Space");
              }}
            >
              <FormattedMessage id="RETURN" />
            </Button>
          </Link>
        </div>
      </div>
      <BasicFooter />
    </div>
  );
};

const SubscriptionCanceledView = () => {
  const space = useSpace();
  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center">
      <div className="align-items-sm-center d-flex flex-1 flex-column justify-content-center">
        <div className="mb-3">
          <WarningBox />
        </div>
        <div className="CancelationViewTitle mb-4">
          <FormattedMessage id="CANCELED_SUBSCRIPTION" />
        </div>
        <div style={{ textAlign: "center" }} className="text-black fs-22 mb-3">
          <FormattedMessage id="SUBSCRIPTION_OF_WORKSPACE" />
          <br />

          <span className="mx-2 fw-medium">{space.Name}</span>
          <FormattedMessage id="IS_SUSPENDED" />
        </div>
        <div className="text-black fs-16 mb-4">
          <FormattedMessage id="PLEASE_CONTACT_YOUR_ADMIN" />
        </div>
        <div>
          <Link to="/s">
            <Button
              onclick={() => {
                localStorage.removeItem("Airdesk-Previous-Space");
              }}
            >
              <FormattedMessage id="RETURN" />
            </Button>
          </Link>
        </div>
      </div>
      <BasicFooter />
    </div>
  );
};

export default SubscriptionCanceledView;
