import React, { useContext, useEffect } from "react";

import { ConnectedClientOrigin } from "../../Origin/ClientOrigin";
import { ConnectedAccountPopup } from "../../Origin/AcountPopup";

import { spaceContractSchema } from "../../../config/schema";
import { useContract } from "../../../Hooks/EntityHooks";
import { ContractEntityGridButtons } from "../../Contracts/ContractsHelper";
import { ContractsLink } from "../../../Helpers/HooksHelper";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { Tr } from "../../../Components/Table";
import {
  DashboardTitle,
  DashboardTable,
  DashboardEmptyResponse,
  ReportsDateProvider,
} from "../Dashboard";
import RelativeTime from "../../../Components/RelativeTime/RelativeTime";

import { faFileContract, faCog } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage } from "react-intl";
import { useDashboardQuery } from "../DashboardQueryHelper";
import { useSidebar } from "../../../Components/Sidebar/SidebarV2";
import { DashboardSettingsContext } from "../DashboardLayout";
import DashboardCardSettingsForm, { handleIntervalCalculation } from "../DashboardCardSettingsForm";
import Button from "../../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const columns = [
  {
    minWidth: 120,
    width: 120,
    Cell: (value) => <ContractEntityGridButtons entity={value} />,
  },
  {
    title: "Id",
    Cell: ({ Id }) => <ContractsLink id={Id}>{Id}</ContractsLink>,
  },
  {
    maxWidth: 300,
    title: <FormattedMessage id={"DESCRIPTION"} />,
    Cell: ({ Description }) => (
      <span className="text-black">{Description}</span>
    ),
  },
  {
    maxWidth: 200,
    title: <FormattedMessage id={"ACCOUNT"} />,
    Cell: ({ Client, IndirectClient }) => (
      <ConnectedClientOrigin client={Client || IndirectClient} />
    ),
  },
  {
    title: <FormattedMessage id={"MANAGER"} />,
    Cell: ({ Manager }) => <ConnectedAccountPopup account={Manager} />,
  },
  {
    title: <FormattedMessage id={"CONTRACT_END"} />,
    Cell: ({ EndDate }) => <RelativeTime date={EndDate} />,
  },
];

const ContractTr = ({ value, ...rest }) => {
  const contract = useContract(value);

  return <Tr value={contract} {...rest} />;
};
const lS_Group = "dashboard-layout-contratos";
const ExpiredContracts = (props) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "expiredContracts";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const {
    data,
    loading,
    error,
  } = useDashboardQuery(
    `Dashboard/Services/Contracts/Expired?top=15&todate=${
      DataReports[0] === "Contracts"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(Settings.Interval.title, 'toDate', Settings.Interval)
        : ""
    }&fromdate=${
      DataReports[0] === "Contracts"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(Settings.Interval.title, 'fromDate', Settings.Interval)
        : ""
    }${
      DataReports[0] === "Contracts"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`,
    [spaceContractSchema],
    { cache: false }
  );
  const user =
    DataReports[0] === "Contracts" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  const buildBody = () => {
    if (loading || error)
      return <LoaderSpinner size="sm text-secondary" center />;

    if (data?.length === 0)
      return (
        <DashboardEmptyResponse type="contracts">
          <FormattedMessage id={"EMPTY_CONTRACTS"} />
        </DashboardEmptyResponse>
      );

    return (
      <DashboardTable TrComponent={ContractTr} columns={columns} data={data} />
    );
  };

  return (
    <React.Fragment>
      <DashboardTitle
        text={<FormattedMessage id={"EXPIRED_CONTRACTS"} />}
        icon={faFileContract}
      />
      {DataReports[0] !== "Contracts" && (
        <div className="d-flex px-4 mb-3 justify-content-between align-items-center">
          <div className="mb-2">
            <span className="ar-dashboard-description">
              <FormattedMessage id={"INTERVAL"} />:
            </span>
            <span className="text-primary pl-1 fs-14">
              {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                <FormattedMessage id={Settings.Interval.title} />
              ) : (
                <FormattedMessage id={"ALL"} />
              )}
            </span>
            <span className="ar-dashboard-description ml-1">
              | <FormattedMessage id={"SCOPE"} />:
            </span>
            <span className="text-primary pl-1 fs-14">{user}</span>
          </div>
          <Button
            className="ar-dashboard-card-settings-link"
            vType="link-primary"
            onClick={() =>
              openSidebar(
                <DashboardCardSettingsForm
                  lS_Field={lS_Field}
                  lS_Group={lS_Group}
                  Settings={Settings}
                  closeSidebar={closeSidebar}
                  Title={"EXPIRED_CONTRACTS"}
                  handleChanges={handleLayoutChange}
                  AvailableSettings={{
                    interval: true,
                    scope: true,
                    pipeline: false,
                  }}
                />
              )
            }
          >
            <FontAwesomeIcon icon={faCog} />
          </Button>
        </div>
      )}
      {buildBody()}
    </React.Fragment>
  );
};

export default ExpiredContracts;
