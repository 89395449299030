import React, { useState, useRef } from "react";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import classnames from "classnames";

const VideoPreviewContent = ({ Url, handleWidthChange }) => {
  const [loading, setLoading] = useState(true);
  const ImageRef = useRef();
  const handleLoad = (e) => {
    setLoading(false);
  };

  return (
    <div className="Preview_Image_Container position-relative bg-dark d-flex flex-1 h-100 of-hidden">
      {loading && (
        <div
          style={{ top: 0, left: 0, zIndex: 3 }}
          className="position-absolute h-100 bg-dark w-100"
        >
          <LoaderSpinner
            size="sm"
            className="d-flex justify-content-center align-items-center h-100 text-primary"
          />
        </div>
      )}
      <video
        className={classnames("d-inline-block mx-auto")}
        ref={ImageRef}
        onLoadedData={handleLoad}
        width="100%"
        height="100%"
        controls
        autoPlay={true}
      >
        <source src={Url} type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const VideoPreview = ({ src, handleWidthChange }) => {
  return (
    <VideoPreviewContent
      key={src}
      handleWidthChange={handleWidthChange}
      Url={src}
    />
  );
};

export default VideoPreview;
