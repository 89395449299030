import React, { useCallback } from "react";
import { useGridRequest } from "../../../Components/CGrid/ServerGrid";
import EmptyListError from "../../../Components/EmptyListContainer/EmptyListError";
import { useContact } from "../../../Hooks/EntityHooks";
import EmptyListContainer from "../../../Components/EmptyListContainer/EmptyListContainer";
import { ContactFormButton } from "../../Form/FormButtons";
import VirtualServerAwareGrid from "../../../Components/VirtualGrid/VirtualServerAwareGrid";
import { VirtualGridRow } from "../../../Components/VirtualGrid/VirtualGrid";
import { moduleTypes } from "../../../Helpers/ModulesHelper";
import { contactsColumns } from "./ContactOverviewComp";
import { FormattedMessage } from "react-intl";

const ContactRow = React.memo(({ value, ...rest }) => {
  const v = useContact(value);

  const canSelectRow = useCallback((v) => !v.Contact, []);
  if (v.IsDeleted)
    return (
      <VirtualGridRow
        formType={moduleTypes.contacts}
        value={null}
        {...rest}
        canSelectRow={canSelectRow}
      />
    );

  return (
    <VirtualGridRow
      formType={moduleTypes.contacts}
      value={v}
      {...rest}
      canSelectRow={canSelectRow}
    />
  );
});

const canSelectRow = (value) => {
  if (!value) return false;

  return true;

  // const state = store.getState();

  // const intervention = getEntityFromState(
  //   state,
  //   spaceInterventionsSchema,
  //   value
  // );

  // return !intervention.Billing;
};

const ContactGrid = ({ onItemSelect, ...rest }) => {
  const { error, results } = useGridRequest();

  if (error) return <EmptyListError />;

  if (results && results.length === 0)
    return (
      <EmptyListContainer type="contacts">
        <div className="fw-bold fs-24 text-black ">
          <FormattedMessage id="NO_CONTACTS" />
        </div>
        <div className="fs-18 text-black text-center mb-4">
          <FormattedMessage id="EMPTY_CONTACTS" />
        </div>
        <ContactFormButton>
          <FormattedMessage id="ADD_NEW_CONTACT" />
        </ContactFormButton>
      </EmptyListContainer>
    );

  return (
    <VirtualServerAwareGrid
      sortable
      rowComponent={ContactRow}
      canSelectRow={canSelectRow}
      onSelectedItemsChange={onItemSelect}
      columns={contactsColumns}
      {...rest}
    />
  );
};

export default ContactGrid;
