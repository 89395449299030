import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
  useCallback,
  useRef
} from "react";
import "./SidebarV2.css";
import { subscribeUniqueEvent } from "../../Helpers/ComponentsHelper";
import SidebarDefaultView from "./SidebarDefaultView";
import SidebarModalView from "./SidebarModalView";
import {
  SidebarViewContext,
  SidebarVisualTypeEnum,
  SidebarViewSetterContext,
  SidebarViewLsKey
} from "./SidebarViewsHelper";
import SidebarFullscsreenView from "./SidebarFullscreenView";
import { useIntl } from "react-intl";

export const SidebarContext = React.createContext<any>(undefined);
export const SidebarCountContext = React.createContext<any>(undefined);
export const SidebaHasChangesContext = React.createContext<any>(undefined);
interface ISidebarTab {
  Component: any;
  props?: any;
}

// interface ISidebarContent {
//   tabs: ISidebarTab[];
//   closeSidebar: () => void;
// }

const SidebarAnimationContext = React.createContext(false);

export const useSidebarAnimationState = () =>
  useContext(SidebarAnimationContext);

interface ISidebarProvider {
  children: any;
}

interface ISidebarClosureOptions {
  oldProps: any;
  askConfirmation?: boolean;
}

interface ISidebarOpenerOptions {
  oldProps: any;
  pushOnTop?: boolean;
  replaceTab?: boolean;
}

export const SidebarProvider = ({ children }: ISidebarProvider) => {
  const [tabs, setTabs] = useState<ISidebarTab[]>([]);
  const intl = useIntl();
  const hasChanges = useRef(false);

  const changesContext = useMemo(() => {
    const handleChanges = (b: boolean | undefined) => {
      hasChanges.current = typeof b === "boolean" ? b : true;
    };

    return [handleChanges, hasChanges.current];
  }, [hasChanges]);

  const contextValue = useMemo((): [
    (Component: any, options?: ISidebarOpenerOptions) => void,
    (closeAll?: boolean, options?: ISidebarClosureOptions) => void
  ] => {
    const openSidebar = (Component: any, options?: ISidebarOpenerOptions) => {
      if (hasChanges.current && !options?.pushOnTop) {
        if (
          window.confirm(intl.formatMessage({ id: "SIDEBAR_CONFIRM_EXIT" }))
        ) {
          setTabs((tabs) => {
            const {
              oldProps = undefined,
              pushOnTop = false,
              replaceTab = false
            } = options || {};

            let newTabs;

            if (replaceTab) {
              newTabs = [...tabs];
              newTabs.splice(newTabs.length - 1, 1);
              newTabs.push({ Component });
            } else if (pushOnTop) {
              newTabs = [...tabs, { Component }];
            } else {
              newTabs = [{ Component }];
            }

            if (newTabs.length > 1 && oldProps) {
              const prevTab = { ...newTabs[newTabs.length - 2] };
              prevTab.props = {
                ...prevTab.props,
                ...oldProps
              };
              newTabs[newTabs.length - 2] = prevTab;
            }
            return newTabs;
          });
          hasChanges.current = false;
        }
      } else {
        setTabs((tabs) => {
          const {
            oldProps = undefined,
            pushOnTop = false,
            replaceTab = false
          } = options || {};

          let newTabs;

          if (replaceTab) {
            newTabs = [...tabs];
            newTabs.splice(newTabs.length - 1, 1);
            newTabs.push({ Component });
          } else if (pushOnTop) {
            newTabs = [...tabs, { Component }];
          } else {
            newTabs = [{ Component }];
          }

          if (newTabs.length > 1 && oldProps) {
            const prevTab = { ...newTabs[newTabs.length - 2] };
            prevTab.props = {
              ...prevTab.props,
              ...oldProps
            };
            newTabs[newTabs.length - 2] = prevTab;
          }
          return newTabs;
        });
        hasChanges.current = false;
      }
    };

    const closeSidebar = (
      closeAll = false,
      options?: ISidebarClosureOptions
    ) => {
      if (hasChanges.current) {
        if (
          window.confirm(intl.formatMessage({ id: "SIDEBAR_CONFIRM_EXIT" }))
        ) {
          setTabs((tabs) => {
            if (closeAll) return [];
            const arr = [...tabs];
            arr.pop();

            if (options) {
              const { oldProps } = options;
              if (arr.length > 0) {
                const item = { ...arr[arr.length - 1] };

                item.props = { ...item.props, ...oldProps };
                arr[arr.length - 1] = item;
              }
            }

            return arr;
          });
          hasChanges.current = false;
        }
      } else if (!hasChanges.current) {
        setTabs((tabs) => {
          if (closeAll) {
            return [];
          }
          const arr = [...tabs];
          arr.pop();

          if (options) {
            const { oldProps } = options;
            if (arr.length > 0) {
              const item = { ...arr[arr.length - 1] };

              item.props = { ...item.props, ...oldProps };
              arr[arr.length - 1] = item;
            }
          }
          if (arr.length === 0) {
            hasChanges.current = false;
          } else if (options?.oldProps?.childProps && arr.length > 0) {
            hasChanges.current = true;
          }
          return arr;
        });
      }
      // if (options?.oldProps?.childProps) {
      //   hasChanges.current = true;
      // }
    };
    return [openSidebar, closeSidebar];
  }, [intl]);

  const isOpen = tabs.length > 0;
  const [, closeSidebar] = contextValue;

  useEffect(() => {
    if (tabs.length === 0 && hasChanges.current) {
      hasChanges.current = false;
    }
    return () => {
      hasChanges.current = false;
    };
  }, [tabs]);

  useEffect(() => {
    if (isOpen) {
      const handleEsc = (e: any) => {
        if (e.key === "Escape") {
          // e.stopPropagation();

          closeSidebar();
        }
      };

      const unsubscribe = subscribeUniqueEvent(handleEsc);
      // document.body.style = "overflow-y: hidden";

      return unsubscribe;
    } else if (!isOpen) {
      // document.body.style = "";
    }
  }, [closeSidebar, isOpen]);

  const [animating, setAnimating] = useState(true);

  const handleEnter = useCallback(() => {
    setAnimating(false);
  }, []);

  const handleExit = useCallback(() => {
    setAnimating(true);
  }, []);

  const [sidebarViewType, setSidebarViewType] = useState(() => {
    const lsType = Number(localStorage.getItem(SidebarViewLsKey));

    return lsType || SidebarVisualTypeEnum.side;
  });

  const handleSidebarViewChange = useCallback(
    (newType: number) => {
      if (newType === sidebarViewType) return;
      setAnimating(true);
      setSidebarViewType(newType);
      localStorage.setItem(SidebarViewLsKey, String(newType));
    },
    [sidebarViewType]
  );

  const SidebarComponent = useMemo(() => {
    switch (sidebarViewType) {
      case SidebarVisualTypeEnum.modal:
        return SidebarModalView;

      case SidebarVisualTypeEnum.fullscreen:
        return SidebarFullscsreenView;

      default:
        return SidebarDefaultView;
    }
  }, [sidebarViewType]);

  return (
    <SidebarContext.Provider value={contextValue}>
      <SidebarAnimationContext.Provider value={animating}>
        <SidebaHasChangesContext.Provider value={changesContext}>
          <SidebarCountContext.Provider value={tabs.length}>
            <SidebarViewContext.Provider value={sidebarViewType}>
              <SidebarViewSetterContext.Provider
                value={handleSidebarViewChange}
              >
                <SidebarComponent
                  open={isOpen}
                  tabs={tabs}
                  onCloseSidebar={closeSidebar}
                  onEntered={handleEnter}
                  onExited={handleExit}
                />
                {children}
              </SidebarViewSetterContext.Provider>
            </SidebarViewContext.Provider>
          </SidebarCountContext.Provider>
        </SidebaHasChangesContext.Provider>
      </SidebarAnimationContext.Provider>
    </SidebarContext.Provider>
  );
};

export const useSidebarChanges = () =>
  useContext(SidebaHasChangesContext) as [(b?: boolean) => void, boolean];

export const useSidebar = () =>
  useContext(SidebarContext) as [
    (Component: any, options?: ISidebarOpenerOptions) => void,
    (closeAll?: boolean, options?: ISidebarClosureOptions) => void
  ];
