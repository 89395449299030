import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const ContactDetailsComp = React.lazy(() =>
  import(/* webpackChunkName: "contacts-details" */ "./ContactDetailsComp")
);

const ContactDetails = () => {
  return (
    <SuspenseErrorBoundary>
      <ContactDetailsComp />
    </SuspenseErrorBoundary>
  );
};

export default ContactDetails;
