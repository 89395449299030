import React from "react";
import { Handle } from "react-flow-renderer";

import classes from "../AutomationDetails.module.css";
import classnames from "classnames";
const SourceHandles = (props) => {
  // const ElementsRef = useContext(ElementsRefContext);
  const { id, labelstyle } = props;
  // const validateConnection = (e) => {
  //   const { source, sourceHandle } = e;
  //   const sourceIndex = ElementsRef.current.findIndex((e) => e.id === source);
  //   if (sourceIndex !== -1) {
  //     const outgoers = getOutgoers(
  //       ElementsRef.current[sourceIndex],
  //       ElementsRef.current
  //     );
  //     if (sourceHandle && outgoers.length === 1) {
  //       const otherEdge = ElementsRef.current.find((e) => e.source === source);
  //       if (otherEdge.sourceHandle !== sourceHandle) return true;
  //       else return false;
  //     }
  //     return outgoers.length === 0;
  //   } else return false;
  // };

  return (
    <>
      <Handle
        type="source"
        position="bottom"
        // isValidConnection={true}
        className={classnames(classes.handle, props.className)}
        // onConnect={(params) => console.log("handle onConnect", params)}
        {...props}
      />
      {id && (
        <div
          style={{
            textTransform: "capitalize",
            borderRadius: 3,
            pointerEvents: "none",
            ...labelstyle
          }}
          className={classnames(
            "text-black d-flex justify-content-center fw-medium fs-14 bg-white",
            classes["disable-selection"],
            classes["EdgeLabelsBottom"]
          )}
        >
          {id}
        </div>
      )}
    </>
  );
};

export default SourceHandles;
