export enum NodeActionsEnum {
  delay = "delay",
  timeReports = "time_reports",
  condition_if = "condition_if",
  taskCreation = "task_creation",
  callCreation = "call_creation",
  dealCreation = "deal_creation",
  contactCreation = "contact_creation",
  contractCreation = "contract_creation",
  subscriptionCreation = "subscription_creation",
  ticketCreation = "ticket_creation",
  projectCreation = "project_creation",
  emailCreation = "email_creation",
  splitInBatches = "split_in_batches",
  httpRequest = "http_request",
  clientCreation = "client_creation",
  propertySetter = "property_setter",
  doNothing = "do_nothing",
  connectWorkflow = "connect_workflow",
  getDeal = "get_deal",
  getClient = "get_client",
  getContact = "get_contact",
  getContract = "get_contract",
  getSubscription = "get_subscription",
  getTask = "get_task",
  getCall = "get_call",
  getTicket = "get_ticket",
  getProject = "get_project",
  exportEmailsConversations = "export_emails_conversations",
  getTicketConversations = "get_ticket_conversations",
  getExportFileById = "get_export_file_by_id",
  httpStatusRequest = "http_status_request",
  conversationCreation = "conversation_creation"
}

export enum TriggerTypeEnum {
  trigger_client_creation = "trigger_client_creation",
  trigger_contact_creation = "trigger_contact_creation",
  trigger_classification_creation = "trigger_classification_creation",
  trigger_deal_creation = "trigger_deal_creation",
  trigger_contract_creation = "trigger_contract_creation",
  trigger_subscription_creation = "trigger_subscription_creation",
  trigger_ticket_creation = "trigger_ticket_creation",
  trigger_task_creation = "trigger_task_creation",
  trigger_project_creation = "trigger_project_creation",
  trigger_time_creation = "trigger_time_creation",
  trigger_call_creation = "trigger_call_creation",
  trigger_webhook = "trigger_webhook",
  trigger_manual = "trigger_manual",
  trigger_cron = "trigger_cron"
}

export type NodeTypes = NodeActionsEnum | TriggerTypeEnum;

export enum IfElseTriggerTypeEnum {
  creation = 1,
  updates = 2,
  statusChange = 4,
  renew = 8,
  any = 15
}

export enum IfElseConditionEnum {
  all = "all",
  or = "or"
}

export type IfElseValue = string | number | boolean;

export enum IfElseTypeEnum {
  boolean = "boolean",
  number = "number",
  string = "string",
  dates = "dates"
}

export enum ifElseItemStringOperators {
  contains = "contains",
  equal = "equal",
  notContains = "not_contains",
  startsWith = "starts_with",
  endsWith = "ends_with",
  isEmpty = "is_empty"
}

export enum ifElseItemNumbersOperators {
  greater = "greater",
  lesser = "lesser",
  greaterEqual = "greater_equal",
  lesserEqual = "lesser_equal",
  equal = "equal",
  notEqual = "not_equal",
  isEmpty = "is_empty"
}
export enum ifElseItemDateOperators {
  occuredAfter = "occured_after",
  occuredBefore = "occured_before"
}
export enum ifElseItemBooleanOperators {
  equal = "equal",
  notEqual = "not_equal"
}

export interface IfElseItem {
  value1: IfElseValue;
  value2: IfElseValue;
  type: IfElseTypeEnum;
  operator:
    | ifElseItemStringOperators
    | ifElseItemDateOperators
    | ifElseItemNumbersOperators
    | ifElseItemBooleanOperators;
}

export interface NodeIfElseData {
  condition: IfElseConditionEnum;
  items: IfElseItem[];
}

export enum TicketPropertiesEnum {
  ClientId = "ClientId",
  call_id = "call_id",
  project_id = "project_id"
}

export enum TargetEmailsTypeEnum {
  ticket = 1,
  deal = 2
}
