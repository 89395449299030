import React from "react";
import { useSocialAccount } from "../../../Hooks/EntityHooks";
import { spaceSocialAccountSchema } from "../../../config/schema";
import {
  SideFormFooter,
  SideFormContent,
  SideForm,
} from "../../../Components/Forms/SideMenuForm";
import { OrganizationDropdown } from "../../AdvancedMultiInputs";
import { FormattedMessage } from "react-intl";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import { handleError } from "../../../Helpers/MiscHelper";
import { useFormState } from "../../../Components/Forms";
import { useToast } from "../../../Components/Toast/ToastProvider";
import {
  BaseSidebarHeader,
  BaseSidebarContainer,
  useSidebarSpacePost,
  BaseSidebarContent,
} from "../../../Components/Sidebar/SidebarV2Helper";

const SocialAccountForm = (props) => {
  const { id, onSuccess } = props;

  const socialAccount = useSocialAccount(id);

  const canEdit = socialAccount ? socialAccount.CanEdit : true;

  return (
    <SocialForm
      onSuccess={onSuccess}
      id={id}
      canEdit={canEdit}
      convertedForm={socialAccount}
      {...props}
    />
  );
};

const defaultSocialForm = () => {
  return {
    Organizations: null,
  };
};

const SocialForm = ({
  convertedForm,
  id,
  canEdit,
  onSuccess,
	isChild,
	refetchRef,
  ...props
}) => {
  const formState = useFormState(() => {
    return {
      ...defaultSocialForm(),
      ...convertedForm,
    };
  });
  const { form } = formState;
  const { Organizations } = form;

  const createToast = useToast();

  const [post, { loading }] = useSidebarSpacePost(
    `social/${id}`,
    spaceSocialAccountSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
      },
      onError: ({ error }) => {
        handleError(createToast, error);
			},
			refetchRef
    }
  );

  const handleSubmit = (form) => {
    post(formatLostForm(form));
  };

  const validateCancelForm = ({ Organizations }, addError) => {
    if (!Organizations) addError("Organizations");
  };

  const formatLostForm = (form) => {
    const { Organizations, ...restForm } = form;
    return {
      ...restForm,
      Organizations,
    };
  };

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id="EDIT_ACCOUNT" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideForm
          className="h-100"
          canEdit={canEdit}
          formState={formState}
          onSubmit={handleSubmit}
          validate={validateCancelForm}
        >
          <SideFormContent className="p-4">
            <FormInput
              name="Organizations"
              className="mb-3"
              value={Organizations}
              text={<FormattedMessage id={"COMPANY"} />}
              inputType={OrganizationDropdown}
              multiple
            />
          </SideFormContent>
          <SideFormFooter isLoading={loading} />
        </SideForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export default SocialAccountForm;
