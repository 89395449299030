import React, { useState, useCallback, useMemo } from "react";
import { useNote } from "../../../../Hooks/EntityHooks";
import {
  HistoryHeader,
  HistoryTitle,
  HistoryContent,
  HistoryAccountProfile,
  HistoryOverview,
  useHistoryItem,
} from "./HistoryItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faComment } from "@fortawesome/pro-light-svg-icons";
import HTMLInput from "../../../HTMLInput/HTMLInput";
import { useSchemaPost, useQuery } from "../../../../Helpers/IOClient";
import { spaceNoteSchema } from "../../../../config/schema";
import Button from "../../../Button/Button";
import LoadableButton from "../../../Button/LoadableButton";
import { DetailsAccordion } from "../../DetailsView";
import RelativeTime from "../../../RelativeTime/RelativeTime";
import { fk } from "../../../../Helpers/SchemaHelper";
import { useSpace } from "../../../../Contexts/SpaceContext";
import LoaderSpinner from "../../../Loader/LoaderSpinner/LoaderSpinner";
import { NoteIcon } from "../../../Common/EntityIcons";
import { FormattedMessage } from "react-intl";

const CommentObject = {
  Note: fk(spaceNoteSchema),
};

const NotePreviewer = ({ note: noteId }) => {
  const Note = useNote(noteId);

  const { NoTagDescription } = Note;
  const [isEditing, setIsEditing] = useState(false);

  if (!isEditing)
    return (
      <div style={{ marginLeft: 40 }}>
        <span style={{}} className="d-inline-block mr-2">
          {NoTagDescription}
          <div
            style={{ marginLeft: 10 }}
            className="text-primary d-inline-block cursor-pointer"
            onClick={() => setIsEditing(true)}
          >
            <FontAwesomeIcon size="sm" icon={faPen} />
          </div>
        </span>
      </div>
    );

  return <NoteUpdater note={Note} onClose={() => setIsEditing(false)} />;
};

const NoteUpdater = ({ note, onClose }) => {
  const { Description, Id } = note;

  const [text, setText] = useState(Description);

  const [post, { loading }] = useSchemaPost(spaceNoteSchema, Id, {
    normalizedSchema: CommentObject,
    onSuccess: () => onClose(),
  });

  return (
    <div>
      <HTMLInput
        disabled={loading}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <LoadableButton
        size="sm"
        isLoading={loading}
        onClick={() =>
          post({
            Description: text,
          })
        }
      >
        <FormattedMessage id={"SAVE"} />
      </LoadableButton>
      <Button className="ml-2" vType="link-danger" onClick={onClose}>
        <FormattedMessage id={"CANCEL"} />
      </Button>
    </div>
  );
};

const CommentInserter = ({ noteID, onClose, addComment }) => {
  const [text, setText] = useState("");

  const [post, { loading }] = useSchemaPost(spaceNoteSchema, null, {
    onSuccess: ({ data }) => {
      addComment(data);
      onClose();
    },
  });

  return (
    <div>
      <HTMLInput
        disabled={loading}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <LoadableButton
        size="sm"
        isLoading={loading}
        onClick={() =>
          post({
            Description: text,
            noteId: noteID,
          })
        }
      >
        <FormattedMessage id={"SAVE"} />
      </LoadableButton>
      <Button className="ml-2" vType="link-danger" onClick={onClose}>
        <FormattedMessage id={"CANCEL"} />
      </Button>
    </div>
  );
};

const CommentInsertion = ({ note: noteId, addComment }) => {
  const [isInserting, setIsInserting] = useState(false);

  if (!isInserting) {
    return (
      <div
        className="text-primary d-inline-block cursor-pointer"
        onClick={() => {
          setIsInserting(true);
        }}
      >
        <FontAwesomeIcon style={{ fontSize: 14, width: 14 }} icon={faComment} />
        <span style={{ paddingLeft: 5, fontSize: 14 }}><FormattedMessage id="COMMENT"/></span>
      </div>
    );
  } else {
    return (
      <CommentInserter
        addComment={addComment}
        noteID={noteId}
        onClose={() => setIsInserting(false)}
      />
    );
  }
};

// const Mapper = ({ data, addCount }) => {
//   const [notes, setNotes] = useState(data);

//   const addNote = newNote => {
//     addCount();
//     setNotes(prevNotes => {
//       return [...prevNotes, newNote];
//     });
//   };
// };

const CommentComponent = ({ Lv, comment }) => {
  const c = useNote(comment);
  const { Account, Date, CommentsCount, Id } = c;
  const [count, setCount] = useState(CommentsCount || 0);

  const [newNotes, setNewNotes] = useState([]);

  const addNote = useCallback((newNote) => {
    setCount((c) => c + 1);
    setNewNotes((prevNotes) => {
      return [...prevNotes, newNote];
    });
  }, []);

  return (
    <div
      style={{
        padding: "8px 8px 5px",
        backgroundColor: "#FCFCFF",
        marginBottom: 10,
        border: "1px solid rgb(205, 213, 234)",
        borderRadius: 5,
      }}
    >
      <HistoryAccountProfile account={Account} />{" "}
      {/* <div className="d-inline-block"> comentou o seguinte: </div> */}
      <CommentPreviewer comment={c} date={Date} />
      {Lv < 2 && count > 0 ? (
        <DetailsAccordion
          //   isOpenByDefault={true}
          title={`Comentários (${count})`}
        >
          <CommentsComponent Lv={Lv + 1} newNotes={newNotes} Id={Id} />
        </DetailsAccordion>
      ) : (
        ""
      )}
      {Lv < 2 && (
        <>
          <hr style={{ margin: 0, width: "100%", borderColor: "#cdd5ea" }} />
          <div style={{ padding: "0.75rem 1rem" }}>
            <CommentInsertion addComment={addNote} note={Id} />
          </div>
        </>
      )}
    </div>
  );
};

const CommentsComponent = ({ Lv, Id, newNotes }) => {
  const space = useSpace();
  const { data, error, loading } = useQuery(`spaces/${space.Id}/Notes/${Id}`, [
    spaceNoteSchema,
  ]);

  const resolvedValues = useMemo(() => {
    if (!data) return;
    //;
    return [...new Set([...data, ...newNotes])];
  }, [data, newNotes]);

  if (!loading && !error) {
    return resolvedValues.map((e) => {
      return <CommentComponent Lv={Lv} key={e} comment={e} />;
    });
  } else {
    return <LoaderSpinner size="xs" />;
  }
};

const CommentPreviewer = ({ comment, date }) => {
  const { NoTagDescription } = comment;
  const [isEditing, setIsEditing] = useState(false);

  if (!isEditing)
    return (
      <div style={{ wordBreak: "break-word", marginLeft: 40 }}>
        <span className="d-inline-block mr-2">
          {NoTagDescription}
          <div
            style={{ marginLeft: 10 }}
            className="text-primary d-inline-block cursor-pointer"
            onClick={() => setIsEditing(true)}
          >
            <FontAwesomeIcon size="sm" icon={faPen} />
          </div>
        </span>
        <br />

        <span style={{ fontSize: 11, color: "#002b63cf" }}>
          <RelativeTime date={date} />
        </span>
      </div>
    );

  return (
    <CommentUpdater comment={comment} onClose={() => setIsEditing(false)} />
  );
};

const CommentUpdater = ({ comment, onClose }) => {
  const { Description } = comment;

  const [text, setText] = useState(Description);

  const [post, { loading }] = useSchemaPost(spaceNoteSchema, comment.Id, {
    // normalizedSchema: CommentObject,
    onSuccess: () => onClose(),
  });

  return (
    <div>
      <HTMLInput
        disabled={loading}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <LoadableButton
        size="sm"
        isLoading={loading}
        onClick={() =>
          post({
            Description: text,
          })
        }
      >
        <FormattedMessage id={"SAVE"} />
      </LoadableButton>
      <Button className="ml-2" vType="link-danger" onClick={onClose}>
        <FormattedMessage id={"CANCEL"} />
      </Button>
    </div>
  );
};

//  <DetailsAccordion title="Comentários" changesCount={resolvedProperties.length}>

// </DetailsAccordion>

const NoteHistoryItem = () => {
  const { Note, Account } = useHistoryItem();
  const NoteItem = useNote(Note);
  const { CommentsCount, Id } = NoteItem;

  const [count, setCount] = useState(CommentsCount);

  const [newNotes, setNewNotes] = useState([]);

  const addNote = useCallback((newNote) => {
    setCount((c) => c + 1);
    setNewNotes((prevNotes) => {
      return [...prevNotes, newNote];
    });
  }, []);

  return (
    <>
      <div style={{ padding: "0.75rem 1rem" }}>
        <HistoryHeader>
          <HistoryTitle className="text-black" Icon={NoteIcon}>
            <FormattedMessage id={"NOTE"} /> {Note}
          </HistoryTitle>
        </HistoryHeader>
        <HistoryContent>
          <HistoryOverview>
            <HistoryAccountProfile account={Account} />{" "}
            <div className="d-inline-block">
              {" "}
              <FormattedMessage id={"CREATED_NOTE"} />{" "}
            </div>
            <NotePreviewer note={Note} />
            {count > 0 ? (
              <DetailsAccordion
                isOpenByDefault={true}
                title={`Comentários (${count})`}
              >
                <CommentsComponent Lv={1} newNotes={newNotes} Id={Id} />
              </DetailsAccordion>
            ) : (
              ""
            )}
          </HistoryOverview>
        </HistoryContent>
      </div>
      <hr style={{ margin: 0, width: "100%", borderColor: "#cdd5ea" }} />
      <div style={{ padding: "0.75rem 1rem" }}>
        <CommentInsertion addComment={addNote} note={Note} />
      </div>
    </>
  );
};
export default NoteHistoryItem;
