import React, {
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
  useEffect,
  FC
} from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { RootElement } from "../../Helpers/MiscHelper";
import "./Modal.css";
import { subscribeUniqueEvent } from "../../Helpers/ComponentsHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  containerClassName?: string;
  disableClosure?: boolean;
  extraCloseButton?: React.ReactNode;
  onEntered?: () => void;
  enableCloseButton?: boolean;
  onExited?: () => void;
  customContent?: React.ReactNode;
  children?: React.ReactNode;
}

const Modal: FC<IModalProps> = ({
  isOpen,
  onClose,
  className,
  containerClassName,
  disableClosure,
  extraCloseButton,
  children,
  onEntered,
  enableCloseButton,
  onExited,
  customContent
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isOpen) {
      return subscribeUniqueEvent((e: any) => {
        if (e.keyCode === 27) {
          e.preventDefault();
          onClose && onClose();
        }
      });
    }
  }, [isOpen, onClose]);

  useLayoutEffect(() => {
    const elem = popupRef.current;
    if (!elem) return;
    if (isOpen) {
      let frame = requestAnimationFrame(() => {
        frame = requestAnimationFrame(() => {
          elem.classList.add("active");
          if (onEntered) {
            let timeout = setTimeout(() => {
              onEntered();
            }, 300);
            return () => clearTimeout(timeout);
          }
        });
      });
      return () => cancelAnimationFrame(frame);
    } else {
      elem.classList.remove("active");
      let timeout = setTimeout(() => {
        setIsAnimating(false);
        onExited && onExited();
      }, 300);
      return () => clearTimeout(timeout);
    }
  }, [isOpen, onEntered, onExited]);

  const handleClick = useCallback(
    (e:any) => {
      if (e.target !== popupRef.current) {
        return;
      } else {
        onClose && onClose();
      }
    },
    [onClose]
  );

  if (isOpen && !isAnimating) {
    setIsAnimating(true);
    return null;
  }

  if (!isOpen && !isAnimating) return null;

  const comp = (
    <div
      ref={popupRef}
      onMouseDown={!disableClosure ? handleClick : undefined}
      className={classnames(`ar-modal`, containerClassName)}
    >
      {customContent ? (
        <>
          {children}
          {extraCloseButton ? extraCloseButton : null}
          {enableCloseButton && (
            <div
              className="ar-modal-preview-file-content-close-button text-color-link"
              onClick={() => {
                onClose && onClose();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </>
      ) : (
        <div className={classnames("ar-modal-content", className)}>
          {children}
          {extraCloseButton ? extraCloseButton : null}
          {enableCloseButton && (
            <div
              className="ar-modal-preview-file-content-close-button text-color-link"
              onClick={() => {
                onClose && onClose();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>
      )}
    </div>
  );

  return ReactDOM.createPortal(comp, RootElement as HTMLElement);
};

export default Modal;
