import {
  faChevronLeft,
  faCompressAlt,
  faExpandAlt
} from "@fortawesome/pro-regular-svg-icons";
import { withRouter } from "react-router-dom";
import { Prompt } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useParams } from "react-router";
import styles from "./AutomationDetails.module.css";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useSpace } from "../../../Contexts/SpaceContext";
import { useSpacePost, useSpaceQuery } from "../../../Helpers/IOClient";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { spaceAutomationSchema } from "../../../config/schema";
import { useAutomation } from "../../../Hooks/EntityHooks";
import ReactFlow, {
  addEdge,
  Controls,
  MiniMap,
  ReactFlowProvider,
  removeElements,
  updateEdge,
  useStoreState,
  useStoreActions
} from "react-flow-renderer";
import TriggerTicketCreation from "./Nodes/Triggers/TriggerTicketCreation";
import IFNode from "./Nodes/IFNode";
import {
  SidebarCountContext,
  useSidebar,
  SidebaHasChangesContext
} from "../../../Components/Sidebar/SidebarV2";
import NodesSidebar from "./NodesSidebar/NodesSidebar";
import Button from "../../../Components/Button/Button";
import { NodeActionsEnum, TriggerTypeEnum } from "./NodeTypes";
import BasicEdge from "./BasicEdge";
import {
  NodeUpdaterContext,
  AutomationTestClickContext,
  AutomationTestRequestContext,
  NodeStatusUpdaterContext,
  NodeCloneFunctionContext,
  NodeGetItemContext,
  NodeTriggerTargetContext,
  NodeShowDetailsContext
} from "./AutomationDetailsHelper";
import DelayNode from "./Nodes/DelayNode";
import { FormattedMessage, useIntl } from "react-intl";
import {
  faBookReader,
  faEllipsisV,
  faFileExport,
  faFileImport,
  faPencil,
  faSave,
  faTrashAlt
} from "@fortawesome/pro-light-svg-icons";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { handleError } from "../../../Helpers/MiscHelper";
import { automationCommunicator, formatAutomationValue } from "./NodeHelper";
import LoadableButton from "../../../Components/Button/LoadableButton";
import Switch from "../../../Components/Switch/Switch";
import { NodeTypeConfigDict } from "../Expressions/ExpressionDict";
import TasksCreationNode from "./Nodes/Creation/TasksCreation";
import PropertySetterNode from "./Nodes/PropertySetterNode";
import EmailCreationNode from "./Nodes/Creation/EmailCreationNode";
import DoNothingNode from "./Nodes/DoNothingNode";
import SplitInBatchesNode from "./Nodes/SplitInBatchesNode";
import AutomationTest from "./Nodes/AutomationTest";
import ManualTrigger from "./Nodes/Triggers/ManualTrigger";
import ConnectWorkflow from "./Nodes/ConnectWorkflowNode";
import WebhookTrigger from "./Nodes/Triggers/WebhookTrigger";
import GetTaskNode from "./Nodes/GetNodes/GetTaskNode";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import {
  DepartmentDropdown,
  OrganizationDropdown,
  TeamDropdown,
  UserDropdown
} from "../../AdvancedMultiInputs";
import ActivityOverview from "./Activity/ActivityOverview";
import CronNode from "./Nodes/Triggers/CronNode";
import ArDatePicker from "../../../Components/DatePicker/ArDatePicker";
import WorkflowTimeTablePicker from "../../../Components/WorkflowTimetablePicker/WorkflowTimeTablePicker";
import ContractsCreation from "./Nodes/Creation/ContractsCreation";
import GetContractNode from "./Nodes/GetNodes/GetContractNode";
import TriggerContractCreation from "./Nodes/Triggers/TriggerContractCreation";
import GetCallNode from "./Nodes/GetNodes/GetCallNode";
import GetProjectode from "./Nodes/GetNodes/GetProjectNode";
import TicketsCreation from "./Nodes/Creation/TicketsCreation";
import GetDealNode from "./Nodes/GetNodes/GetDealNode";
import GetTicketNode from "./Nodes/GetNodes/GetTicketNode";
import CallsCreationNode from "./Nodes/Creation/CallsCreation";
import ProjectsCreationNode from "./Nodes/Creation/ProjectsCreation";
import TriggerClientCreation from "./Nodes/Triggers/TriggerClientCreation";
import TriggerContactCreation from "./Nodes/Triggers/TriggerContactCreation";
import TriggerDealCreation from "./Nodes/Triggers/TriggerDealCreation";
import TriggerSubscriptionCreation from "./Nodes/Triggers/TriggerSubscriptionCreation";
import TriggerProjectCreation from "./Nodes/Triggers/TriggerProjectCreation";
import TriggerTaskCreation from "./Nodes/Triggers/TriggerTaskCreation";
import TriggerCallCreation from "./Nodes/Triggers/TriggerCallCreation";
import DealsCreationNode from "./Nodes/Creation/DealCreation";
import SubscriptionCreation from "./Nodes/Creation/SubscriptionCreation";
import ClientCreation from "./Nodes/Creation/ClientCreation";
import ContactCreation from "./Nodes/Creation/ContactCreation";
import HttpRequestNode from "./Nodes/HttpRequestNode";
import { BarLoader } from "../../../Components/GlobalLoader/GlobalLoader";
import { ServerAwareButtons } from "../../../Components/GroupButton/GroupButton";
import InformationComponent from "../../../Components/InformationComponent/InformationComponent";
import HTMLInput from "../../../Components/HTMLInput/HTMLInput";
import { FormCanEditContext } from "../../../Components/Forms";
import GetClientNode from "./Nodes/GetNodes/GetClientNode";
import GetContactNode from "./Nodes/GetNodes/GetContactNode";
import GetSubscriptionNode from "./Nodes/GetNodes/GetSubscriptionNode";
import TriggerTimeCreation from "./Nodes/Triggers/TriggerTimeCreation";
import Popup, { usePopupOpenState } from "../../../Components/Popup/Popup";
import {
  ActionLoadableButton,
  ActionPopupTogglerContext
} from "../../../Components/ExtraActionsButton/ExtraActionButton";
import Modal from "../../../Components/Modal/Modal";
import TriggerClassificationCreation from "./Nodes/Triggers/TriggerClassificationCreation";
import TimeReportsNode from "./Nodes/Reports/TimeReportsNode";
import CaseEmailExportNode from "./Nodes/Exports/CaseEmailsExportNode";
import GetTicketConversations from "./Nodes/GetNodes/GetTicketConversations";
import GetGeneralExportNode from "./Nodes/Exports/GetGeneralExportNode";
import HttpStatusRequestNode from "./Nodes/HttpStatusRequestNode";
import EmailConversationCreationNode from "./Nodes/Creation/EmailConversationCreationNode";

// function dateCompare(date1, date2) {
//   return new Date(date2) > new Date(date1);
// }

const TriggerBaseIdDict = {
  "Client Trigger": 1,
  "Contact Trigger": 2,
  "Deal Trigger": 3,
  "Contract Trigger": 4,
  "Subscription Trigger": 5,
  "Ticket Trigger": 6,
  "Task Trigger": 7,
  "Project Trigger": 8,
  "Call Trigger": 9,
  "Time Trigger": 10
};

const TriggerTranslationBaseIdDict = {
  1: "ENROLL_DESCRIPTION_CLIENT",
  2: "ENROLL_DESCRIPTION_CONTACT",
  3: "ENROLL_DESCRIPTION_DEAL",
  4: "ENROLL_DESCRIPTION_CONTRACT",
  5: "ENROLL_DESCRIPTION_SUBSCRIPTION",
  6: "ENROLL_DESCRIPTION_TICKET",
  7: "ENROLL_DESCRIPTION_TASK",
  8: "ENROLL_DESCRIPTION_PROJECT",
  9: "ENROLL_DESCRIPTION_CALL",
  10: "ENROLL_DESCRIPTION_TIME"
};

const EnrollModalContent = ({ automationId, rootId, closeModal }) => {
  const resolvedUrl = useMemo(() => {
    const type = TriggerBaseIdDict[rootId];
    if (!type) return undefined;
    return `Automation/${automationId}/${type}/EnRoll`;
  }, [automationId, rootId]);

  const {
    data,
    loading: countLoading,
    error: countError
  } = useSpaceQuery(resolvedUrl);

  const [post, { loading }] = useSpacePost(resolvedUrl, null, {
    onSuccess: ({ data }) => {
      createToast({
        pos: "tm",
        type: "info",
        description: <FormattedMessage id="WORKFLOW_CURRENTLY_RUNNING" />
      });
      closeModal();
    },
    onError: ({ error }) => {
      createToast({
        pos: "tm",
        type: "danger",
        title: <FormattedMessage id={"ERROR"} />
      });
    }
  });

  const createToast = useToast();

  const submit = () => {
    post();
  };

  if (countLoading || countError) {
    return (
      <div
        style={{ maxHeight: 550 }}
        className="p-30 w-450px d-flex justify-content-center align-items-center flex-column"
      >
        <LoaderSpinner className="text-primary" size="sm" />
      </div>
    );
  }

  return (
    <div style={{ maxHeight: 550 }} className="p-30 w-450px d-flex flex-column">
      <div className="text-black mb-4 fs-16">
        <FormattedMessage
          id={TriggerTranslationBaseIdDict[TriggerBaseIdDict[rootId]]}
          values={{ count: data }}
        />
      </div>
      <div className="d-flex w-100 justify-content-between">
        <Button vType="link-danger" className="mr-3" onClick={closeModal}>
          <FormattedMessage id="CANCEL" />
        </Button>
        <LoadableButton isLoading={loading} onClick={submit}>
          <FormattedMessage id="CONFIRM" />
        </LoadableButton>
      </div>
    </div>
  );
};

const ImportModalContent = ({ setElements, closeModal }) => {
  const [inputValue, setValue] = useState("");
  const createToast = useToast();
  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
  };

  const submit = () => {
    try {
      const parsedJson = JSON.parse(inputValue);
      setElements(parsedJson);
      createToast({
        pos: "tm",
        type: "success",
        description: <FormattedMessage id="SUCCESS_SAVED" />
      });
      closeModal();
    } catch (error) {
      createToast({
        pos: "tm",
        type: "danger",
        description: "JSON",
        title: <FormattedMessage id={"ERROR"} />
      });
    }
  };

  return (
    <div style={{ maxHeight: 550 }} className="p-30 w-450px d-flex flex-column">
      <div className="text-black mb-3 fs-16">
        <FormattedMessage id="IMPORT_WORKFLOW_DESCRIPTION" />
      </div>
      <FormInput
        value={inputValue}
        className="mb-4"
        componentClassName={classnames("bg-airdesk", styles["import-textarea"])}
        onChange={handleChange}
        inputType={HTMLInput}
      />
      <div className="d-flex w-100 justify-content-between">
        <Button vType="link-danger" className="mr-3" onClick={closeModal}>
          <FormattedMessage id="CANCEL" />
        </Button>
        <Button onClick={submit}>
          <FormattedMessage id="IMPORT" />
        </Button>
      </div>
    </div>
  );
};

const ExcludedDateComponent = React.memo(
  ({ value, index, onChange, preview }) => {
    const handleTimeChange = (e) => {
      const { value, name } = e.target;
      onChange((oldV) => {
        const newV = [...oldV];
        const oldObject = oldV[index];
        const newObject = { ...oldObject };
        newObject[name] = value;
        newV[index] = newObject;
        return newV;
      });
    };
    const handleAnnualyChange = (e) => {
      const { checked } = e.target;
      onChange((oldV) => {
        const newV = [...oldV];
        const oldObject = oldV[index];
        const newObject = { ...oldObject };
        newObject.Annual = checked;
        newV[index] = newObject;
        return newV;
      });
    };

    const remove = () => {
      onChange((oldV) => {
        const newV = [...oldV];
        newV.splice(index, 1);
        return newV;
      });
    };

    return (
      <div
        className="d-flex align-items-center justify-content-between
		 mt-2"
      >
        <FormInput
          onChange={handleTimeChange}
          className="mr-3 w-100"
          componentClassName="w-100"
          name="Date"
          inputType={ArDatePicker}
          enableHours={false}
          value={value.Date}
        />
        <div className="d-flex align-items-center mr-3">
          <div className="text-black fs-14 mr-3">
            <FormattedMessage id="ANNUALY" />
          </div>
          <Switch
            disabled={preview}
            onChange={handleAnnualyChange}
            checked={value.Annual}
          />
        </div>
        <Button
          disabled={preview}
          style={{ minWidth: 40, width: 40 }}
          type="button"
          className="p-0"
          onClick={remove}
          vType="outline-danger"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </div>
    );
  }
);

const ExcludedDatesContainer = ({ value, onChange, preview }) => {
  const addDate = () => {
    onChange((oldV) => {
      const newV = [...oldV];
      newV.push({
        Date: new Date(),
        Annual: false
      });
      return newV;
    });
  };

  return (
    <div>
      <div className="text-black">
        <FormattedMessage id="WHAT_DATES_TO_EXCLUDE" />
      </div>
      {value.map((e, i) => {
        return (
          <ExcludedDateComponent
            preview={preview}
            key={i}
            value={e}
            index={i}
            onChange={onChange}
          />
        );
      })}
      {!preview && (
        <div
          onClick={addDate}
          className="d-inline-block mt-3 text-primary cursor-pointer"
        >
          <FormattedMessage id="ADD_DATE" />
        </div>
      )}
    </div>
  );
};

const ScheduleSwitch = ({ ...props }) => {
  const { value, onChange } = props;

  const handleChange = (e) => {
    const { checked } = e.target;
    onChange(checked);
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="text-black fs-14">
        <FormattedMessage id="ENABLE_SCHEDULE" />
      </div>
      <Switch {...props} onChange={handleChange} checked={value} />
    </div>
  );
};

const WorkflowTabstrip = ({
  SelectedItem,
  Items,
  setSelectedItem,
  ...rest
}) => {
  const { alignLeft, headerClassName, topClassName, className } = rest;

  const headerClasses = classnames(
    "Tabstrip-top-menu border-0",
    headerClassName,
    {
      left: alignLeft
    }
  );

  const navLinks = Items.map((item, index) => {
    if (item.isActive === false) return null;
    return (
      <div
        className={
          "ar-details-tabstrip disable-selection Tabstrip-link" +
          (SelectedItem === index ? " selected" : "")
        }
        key={index}
        onClick={() => {
          setSelectedItem(index);
        }}
      >
        {item.name}
      </div>
    );
  });

  return (
    <div
      className={classnames(
        `Tabstrip-container d-flex flex-column`,
        topClassName,
        className
      )}
    >
      <div className={headerClasses}>{navLinks}</div>
    </div>
  );
};

const Header = ({
  title,
  isActive,
  showDetails,
  setShowDetails,
  handleActiveChange,
  handleTittleChange,
  toggleEnrollModal,
  handleClick,
  clearAllElements,
  ActiveItem,
  canEdit,
  canDelete,
  postSave,
  rootId,
  postLoading,
  automationId,
  elements,
  SetActiveItem,
  toggleImportModal,
  exportJson
}) => {
  const space = useSpace();
  const DisableEnrollButton = !Boolean(TriggerBaseIdDict[rootId]);
  const to = `/s/${space.Id}/automation`;

  const [EditingTitle, setEditingTitle] = useState(false);
  const [tempTitle, setTempTitle] = useState(title);
  const toggleEditing = () => {
    setEditingTitle(true);
  };

  const cancelEdit = () => {
    setEditingTitle(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.stopPropagation();
      e.preventDefault();
      if (/\S/.test(e.target.value)) {
        handleTittleChange(e.target.value);
        cancelEdit();
      } else {
        cancelEdit();
      }
    }
  };
  const intl = useIntl();
  const OpenTesteModal = useContext(AutomationTestClickContext);
  const { loading } = useContext(AutomationTestRequestContext);
  return (
    <>
      <div
        className={classnames(
          styles.header,
          "d-flex align-items-center justify-content-between px-4 w-100"
        )}
      >
        <div className="d-flex flex-1 of-hidden h-100 align-items-center mr-2 justify-content-start">
          <Link to={to} className="d-flex align-items-center h-100">
            <div className="px-2">
              <FontAwesomeIcon
                icon={faChevronLeft}
                size="lg"
                className="text-primary"
              />
            </div>
          </Link>
          {canEdit && (
            <>
              {EditingTitle ? (
                <>
                  <div
                    style={{ width: 30 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleTittleChange(tempTitle);
                      cancelEdit();
                    }}
                    className="cursor-pointer d-flex align-items-center mx-2 px-2 h-100 text-primary"
                  >
                    <FontAwesomeIcon size="sm" icon={faSave} />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="cursor-pointer d-flex align-items-center h-100 mx-2 px-2 text-primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      toggleEditing();
                    }}
                  >
                    <FontAwesomeIcon size="sm" icon={faPencil} />
                  </div>
                </>
              )}
            </>
          )}
          {EditingTitle ? (
            <div
              className={classnames(
                "mr-2 d-flex align-items-center flex-1 of-hidden border-0 h-100",
                styles.headerTitle
              )}
            >
              <input
                //   onBlur={cancelEdit}
                style={{
                  width: `100%`,
                  maxWidth: `100%`,
                  minWidth: "36px"
                }}
                autoFocus
                className={classnames(
                  "mr-2 d-flex p-0 align-items-center bg-airdesk border-0 h-100",
                  styles["outline-0"],
                  styles["border-0"],
                  styles.headerTitle
                )}
                value={tempTitle}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  setTempTitle(e.target.value);
                }}
              />
            </div>
          ) : (
            <>
              <div
                style={{
                  maxWidth: `100%`,
                  minWidth: "36px"
                }}
                className={classnames(
                  "mr-2 d-flex of-hidden text-truncate p-0 align-items-center border-0 h-100",
                  styles.headerTitle,
                  styles["outline-0"],
                  styles["border-0"]
                )}
              >
                <div className="text-truncate disable-selection">
                  {tempTitle || "Workflow sem titulo"}
                </div>
              </div>
            </>
          )}
        </div>
        <WorkflowTabstrip
          Items={workflowTabItems}
          SelectedItem={ActiveItem}
          setSelectedItem={SetActiveItem}
        />
        <div className="d-flex flex-0-0-auto align-items-center justify-content-end">
          <div className="mx-3 py-2 disable-selection d-flex align-items-center fs-12">
            <InformationComponent text={<FormattedMessage id="STATUS" />}>
              <Switch
                disabled={!canEdit}
                preview={!canEdit}
                value={isActive}
                onChange={() => {
                  handleActiveChange(!isActive);
                }}
                hasText
              />
            </InformationComponent>
          </div>
          <div className="d-flex align-items-center justify-content-center mr-3">
            <ServerAwareButtons>
              <InformationComponent
                text={intl.formatMessage({ id: "SHOW_NOTES" })}
                onClick={() => {
                  setShowDetails(true);
                }}
                className={classnames("ar-groupbutton", {
                  "ar-selected": showDetails
                })}
              >
                <FontAwesomeIcon
                  transform={{ rotate: 135 }}
                  icon={faExpandAlt}
                />
              </InformationComponent>
              <InformationComponent
                text={intl.formatMessage({ id: "HIDE_NOTES" })}
                onClick={() => {
                  setShowDetails(false);
                }}
                className={classnames("ar-groupbutton", {
                  "ar-selected": !showDetails
                })}
              >
                <FontAwesomeIcon
                  transform={{ rotate: 135 }}
                  icon={faCompressAlt}
                />
              </InformationComponent>
            </ServerAwareButtons>
          </div>
          {canEdit && (
            <>
              <LoadableButton
                type="button"
                isLoading={loading}
                vType="primary-ghost"
                className="mr-3"
                onClick={OpenTesteModal}
              >
                <FormattedMessage id="TEST" />
              </LoadableButton>
              <Button onClick={handleClick} className="text-truncate">
                <FormattedMessage id="ADD_NODES" />
              </Button>
              <LoadableButton
                isLoading={postLoading}
                vType={"primary-ghost"}
                className="ml-3"
                onClick={postSave}
              >
                <FormattedMessage id="SAVE" />
              </LoadableButton>
              <ActionButton>
                <div className="label-title">
                  <FormattedMessage id={"ACTIONS"} />
                </div>
                <ActionLoadableButton
                  className="p-1 AlignLeftLoadableButton w-100"
                  vType="link-warning"
                  onClick={() => clearAllElements()}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                  <span className="ml-1">
                    <FormattedMessage id={"CLEAN_BOARD"} />
                  </span>
                </ActionLoadableButton>
                <ActionLoadableButton
                  className="p-1 AlignLeftLoadableButton w-100"
                  vType="link"
                  onClick={exportJson}
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  <span className="ml-1">
                    <FormattedMessage id={"EXPORT_WORKFLOW"} />
                  </span>
                </ActionLoadableButton>
                <ActionLoadableButton
                  className="p-1 AlignLeftLoadableButton w-100"
                  vType="link"
                  onClick={toggleImportModal}
                >
                  <FontAwesomeIcon icon={faFileImport} />
                  <span className="ml-1">
                    <FormattedMessage id={"IMPORT_WORKFLOW"} />
                  </span>
                </ActionLoadableButton>
                <ActionLoadableButton
                  className="p-1 AlignLeftLoadableButton w-100"
                  vType="link"
                  disabled={DisableEnrollButton}
                  onClick={toggleEnrollModal}
                >
                  <FontAwesomeIcon icon={faBookReader} />
                  <span className="ml-1">Enroll</span>
                </ActionLoadableButton>
              </ActionButton>
            </>
          )}
        </div>
      </div>
      <BarLoader isLoading={loading} />
    </>
  );
};

const ActionButton = ({ ...rest }) => {
  const { children } = rest;
  const anchorRef = useRef();
  const popupRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  return (
    <ActionPopupTogglerContext.Provider value={toggleIsOpen}>
      <Button
        style={{ minWidth: 40 }}
        ref={anchorRef}
        className={classnames(
          "ml-3 ssi-button-primary-ghost p-0 pt-1 ExtraActionButton"
        )}
        onClick={(e) => toggleIsOpen(true)}
      >
        <span style={{ verticalAlign: "sub" }}>
          <FontAwesomeIcon size="2x" icon={faEllipsisV} />
        </span>
      </Button>
      <Popup
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        domRef={popupRef}
        anchorEl={anchorRef.current}
        isOpen={isOpen}
        placement="bottom-start"
      >
        {() => {
          return (
            <div className="py-2 ar-grid-buttons-popup bg-white rounded">
              {React.Children.map(children, (child) => {
                if (!child) return child;
                return React.cloneElement(child, {});
              })}
            </div>
          );
        }}
      </Popup>
    </ActionPopupTogglerContext.Provider>
  );
};

const edgeTypes = {
  BasicEdge: BasicEdge
};

const workflowTabItems = [
  {
    default: true,
    exact: true,
    name: <FormattedMessage id="ACTIONS" />
    // component: ,
  },
  {
    default: true,
    exact: true,
    name: <FormattedMessage id="SETTINGS" />
    // component: <WorkflowFormSettings />,
  },
  {
    default: true,
    exact: true,
    name: <FormattedMessage id="HISTORY" />
    // component: <WorkflowFormHistory />,
  }
];

const AutomationDetails = (props) => {
  const createToast = useToast();
  const { automationId } = useParams();

  const { data, loading, error } = useSpaceQuery(
    `automation/${automationId}`,
    spaceAutomationSchema
  );

  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     var confirmationMessage =
  //       "It looks like you have been editing something. " +
  //       "If you leave before saving, your changes will be lost.";

  //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE
  //     return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  // 		// var confirmationMessage =
  // 		// "It looks like you have been editing something. " +
  // 		// "If you leave before saving, your changes will be lost.";
  // 		// window.alert(confirmationMessage);
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const [post, { loading: postLoading }] = useSpacePost(
    automationId ? `Automation/${automationId}` : `Automation`,
    spaceAutomationSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          description: <FormattedMessage id="SUCCESS_SAVED" />
        });
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  if (loading || error)
    return <LoaderSpinner size="sm" className="text-primary" center />;

  return (
    <ReactFlowProvider>
      <AutomationDetailsComp
        postSave={post}
        postLoading={postLoading}
        data={data}
        {...props}
      />
    </ReactFlowProvider>
  );
};

const getId = (type, InternalNodeIdDict) => {
  let newId = NodeTypeConfigDict[type].baseId;
  let iter = 0;
  while (true) {
    const internalNewId = iter === 0 ? newId : `${newId} ${iter}`;
    if (!InternalNodeIdDict[internalNewId]) {
      return internalNewId;
    }
    iter++;
  }
};

const AddNode = (setNode, setRootId, Node, InternalNodeIdDict) => {
  const { type } = Node;
  let resolvedNewId = getId(type, InternalNodeIdDict);
  setNode((oldNodes) => {
    return oldNodes.concat({ data: {}, ...Node, id: resolvedNewId });
  });
  if (Object.values(TriggerTypeEnum).includes(type)) {
    setRootId(resolvedNewId);
    automationCommunicator.dispatch({ rootId: resolvedNewId });
  }
};

// const nodes = useStoreState(state => state.nodes)
// console.log(nodes)
// const getLayoutedElements = (elements, direction = "TB") => {
//   const dagreGraph = new dagre.graphlib.Graph();
//   dagreGraph.setDefaultEdgeLabel(() => ({}));

//   const isHorizontal = direction === "LR";
//   dagreGraph.setGraph({ rankdir: direction });

//   elements.forEach((el) => {
//     const nodeItems = document.querySelectorAll(`[data-id="${el.id}"]`);
//     if (nodeItems.length > 0) {
//       const nodeMeasures = nodeItems[0].getBoundingClientRect();

//       if (isNode(el)) {
//         dagreGraph.setNode(el.id, {
//           width: nodeMeasures?.width,
//           height: nodeMeasures?.height
//         });
//       } else {
//         dagreGraph.setEdge(el.source, el.target);
//       }
//     }
//   });

//   dagre.layout(dagreGraph);

//   return elements.map((el) => {
//     if (isNode(el)) {
//       const nodeWithPosition = dagreGraph.node(el.id);
//       el.targetPosition = isHorizontal ? "left" : "top";
//       el.sourcePosition = isHorizontal ? "right" : "bottom";

//       const nodeItems = document.querySelectorAll(`[data-id="${el.id}"]`);
//       const nodeMeasures = nodeItems[0].getBoundingClientRect();
//       // unfortunately we need this little hack to pass a slightly different position
//       // to notify react flow about the change. Moreover we are shifting the dagre node position
//       // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
//       el.position = {
//         x: nodeWithPosition.x - nodeMeasures?.width / 2 + Math.random() / 1000,
//         y: nodeWithPosition.y - nodeMeasures?.height / 2
//       };
//     }

//     return el;
//   });
// };

const AutomationDetailsComp = ({ data, postSave, postLoading, location }) => {
  const currentPage = useRef(location.pathname);
  const hasChangesNotSaved = useRef(false);
  const createToast = useToast();
  const value = useAutomation(data);
  const { CanEdit, CanDelete } = value;
  const oldJson = useMemo(() => {
    return JSON.parse(value.Json);
  }, [value.Json]);
  const SelectedNodesRef = useRef();
  const [description, setDescription] = useState(value.Description);
  const [company, setCompany] = useState(value.Company);
  const [departments, setDepartments] = useState(value.Departments);
  const [teams, setTeams] = useState(value.Teams);

  const [assignedTo, setAssignedTo] = useState(value.AssignedTo);
  const [enableSchedule, setEnableSchedule] = useState(
    value.EnableSchedule || false
  );
  const [schedules, setSchedules] = useState(() => {
    if (Array.isArray(value.Schedules)) return value.Schedules;
    return [];
  });
  const [excludedDates, setExcludedDates] = useState(() => {
    if (Array.isArray(value.ExcludedDates)) return value.ExcludedDates;
    return [];
  });
  // const nodes = useStoreState((state) => {
  //   console.log(state);
  //   return state.nodes;
  // });
  const setSelectedElements = useStoreActions(
    (actions) => actions.setSelectedElements
  );

  const [title, setTitle] = useState(value.Name);
  const [isActive, setIsActive] = useState(value.IsActive);
  const [rootId, setRootId] = useState(oldJson.rootId);

  // const [elements, setElements] = useState(() => {
  //   return oldJson.items ? oldJson.items : [];
  // });

  const [elementsHistory, setElementsHistory] = useState({
    past: [],
    present: oldJson.items ? oldJson.items : [],
    future: []
  });

  const [elements, setElements] = useMemo(() => {
    const presentElements = [...elementsHistory.present];

    const handleElementsChange = (obj) => {
      if (typeof obj === "function") {
        hasChangesNotSaved.current = true;
        setElementsHistory((oldHistory) => {
          const newPast = [...oldHistory.past, oldHistory.present];
          const newPresent = obj(oldHistory.present);
          if (newPast.length > 10) newPast.splice(9, 1);
          return {
            past: newPast,
            present: newPresent,
            future: []
          };
        });
      } else {
        hasChangesNotSaved.current = true;
        setElementsHistory((oldHistory) => {
          const newPast = [...oldHistory.past, oldHistory.present];
          const newPresent = obj;
          if (newPast.length > 10) newPast.splice(9, 1);
          return {
            past: newPast,
            present: newPresent,
            future: []
          };
        });
      }
    };

    return [presentElements, handleElementsChange];
  }, [elementsHistory.present]);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [activeFormIndex, setActiveFormIndex] = useState(0);
  const NodeTypeIdDictRef = useRef({});

  const getElementData = useCallback(
    (nodeId) => {
      const item = elements.find((e) => {
        return e.id === nodeId;
      });
      return item;
    },
    [elements]
  );

  useEffect(() => {
    if (!rootId && elements) {
      if (elements.length > 0)
        for (const element of elements) {
          if (Object.values(TriggerTypeEnum).includes(element?.type)) {
            setRootId(element?.id);
            automationCommunicator.dispatch({ rootId: element?.id });
            break;
          }
        }
    }

    for (const el of elements) {
      NodeTypeIdDictRef.current[el.id] = true;
    }
  }, [elements, rootId]);

  const savePast = useCallback(() => {
    setElementsHistory((oldHistory) => {
      hasChangesNotSaved.current = true;
      const currentElements = reactFlowInstance.toObject().elements;
      const newPast = [...oldHistory.past, oldHistory.present];
      if (newPast.length > 20) newPast.splice(0, 1);
      return {
        past: newPast,
        present: currentElements,
        future: []
      };
    });
  }, [reactFlowInstance]);

  const onConnect = useCallback(
    (params) => {
      hasChangesNotSaved.current = true;
      setElements((els) =>
        addEdge(
          {
            ...params,
            animated: true,
            type: "BasicEdge",
            data: { ...params }
          },
          els
        )
      );
    },
    [setElements]
  );
  const onElementsRemove = useCallback(
    (elementsToRemove) => {
      if (CanEdit) {
        if (rootId) {
          for (const e of elementsToRemove) {
            if (Object.values(TriggerTypeEnum).includes(e?.type)) {
              automationCommunicator.dispatch({ rootId: null });
              setTargetTriggerTest(null);
              setRootId(null);
            }
          }
        }
        hasChangesNotSaved.current = true;
        setElements((els) => removeElements(elementsToRemove, els));
      }
    },
    [CanEdit, rootId, setElements]
  );

  const handlePost = () => {
    const rootNode = reactFlowInstance
      .toObject()
      .elements.find((e) => e.id === rootId);
    const { type } = rootNode || {};
    const formmatedValue = formatAutomationValue({
      ...value,
      Description: description,
      IsActive: isActive,
      IsManual: type === TriggerTypeEnum.trigger_manual,
      EnableSchedule: enableSchedule,
      Schedules: schedules,
      ExcludedDates: excludedDates,
      OrganizationId: company,
      Departments: departments,
      Teams: teams,
      AssignedTo: assignedTo,
      Json: { items: reactFlowInstance.toObject().elements, rootId: rootId },
      Name: title
    });
    hasChangesNotSaved.current = false;
    postSave(formmatedValue);
  };

  const onDragOver = (event) => {
    event.preventDefault();
    const type = event.dataTransfer.getData("application/reactflow");
    if (!type) return;
    event.dataTransfer.dropEffect = "move";
  };

  const reactFlowWrapper = useRef(null);

  const AddNodes = (
    setNode,
    setRootId,
    Nodes,
    InternalNodeIdDict,
    updateInternalNodeIdDict
  ) => {
    let resolvedEdges = Nodes.filter((e) => Boolean(e.source));
    hasChangesNotSaved.current = true;
    const newNodes = Nodes.filter((e) => !Boolean(e.source)).map((node) => {
      const { type } = node;
      let resolvedNewId = getId(type, InternalNodeIdDict);
      updateInternalNodeIdDict(resolvedNewId);
      return {
        ...node,
        id: resolvedNewId
      };
    });

    for (const t of newNodes) {
      const { oldId, id } = t;
      if (!oldId) return;

      const filteredEdges = resolvedEdges
        .filter((e) => e.source === oldId || e.target === oldId)
        .map((l) => {
          const newL = { ...l };
          if (newL.source === oldId) {
            newL.source = id;
          }
          if (newL.target === oldId) {
            newL.target = id;
          }
          return newL;
        });

      for (const k of filteredEdges) {
        const { oldId } = k;
        const oIndex = resolvedEdges.findIndex((n) => n.oldId === oldId);
        if (oIndex !== -1)
          resolvedEdges[oIndex] = {
            ...k,
            id: `reactflow__edge-${k.source}null-${k.target}null`
          };
      }
    }
    CopyElementsArrayRef.current = [...newNodes, ...resolvedEdges];
    setNode((oldNodes) => {
      let result = [...newNodes, ...resolvedEdges];
      return oldNodes.concat(result);
    });
  };

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const onDrop = useCallback(
    (event) => {
      hasChangesNotSaved.current = true;
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      if (!type) return;
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      });
      const newNode = {
        type,
        position
        // data: { label: `${type} node` },
      };

      AddNode(setElements, setRootId, newNode, NodeTypeIdDictRef.current);
    },
    [reactFlowInstance, setElements]
  );

  const [handleChanges] = useContext(SidebaHasChangesContext);

  const handleNodeUpdate = useCallback(
    (nodeId, update) => {
      handleChanges(false);
      hasChangesNotSaved.current = true;
      setElements((els) =>
        els.map((el) => {
          if (el.id === nodeId) {
            // it's important that you create a new object here
            // in order to notify react flow about the change
            el.data = {
              ...el.data,
              ...update
            };
          }

          return el;
        })
      );
    },
    [handleChanges, setElements]
  );

  const handleNodeStatusUpdate = useCallback(
    (nodeId, status) => {
      hasChangesNotSaved.current = true;
      setElements((els) =>
        els.map((el) => {
          if (el.id === nodeId) {
            // it's important that you create a new object here
            // in order to notify react flow about the change
            el.data = {
              ...el.data
            };
            el.data.enabled = status;
          }

          return el;
        })
      );
    },
    [setElements]
  );

  const [openSidebar, closeSidebar] = useSidebar();

  const handleContainerClick = useCallback(
    (e) => {
      if (!e.altKey) return;
      hasChangesNotSaved.current = true;
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: e.clientX - reactFlowBounds.left,
        y: e.clientY - reactFlowBounds.top
      });

      const addNewNode = (nodeType) => {
        const newNode = {
          type: nodeType,
          position
          // data: { label: `${type} node` },
        };

        AddNode(setElements, setRootId, newNode, NodeTypeIdDictRef.current);

        closeSidebar();
      };
      openSidebar(
        <NodesSidebar
          rootId={rootId}
          onNodeClick={addNewNode}
          position={position}
        />
      );
    },
    [closeSidebar, openSidebar, reactFlowInstance, rootId, setElements]
  );

  useEffect(() => {
    return () => {
      closeSidebar();
    };
  }, [closeSidebar]);

  const handleCloneNodeButton = useCallback(
    (nodeId) => {
      hasChangesNotSaved.current = true;
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: reactFlowBounds.width / 2,
        y: reactFlowBounds.height / 2
      });
      const oldNode = elements.find((e) => e.id === nodeId) || {};
      const { id, ...rest } = oldNode;

      const newNode = {
        ...rest,
        position
      };
      AddNode(setElements, setRootId, newNode, NodeTypeIdDictRef.current);
    },
    [elements, reactFlowInstance, setElements]
  );

  const clonedStateRef = useRef(false);

  const handleCloneNodesFunction = useCallback(
    (nodes) => {
      const newNodes = nodes
        .map((el) => {
          const { id, position, ...rest } = el;
          if (position) {
            const newPosition = {
              x: position.x + 80,
              y: position.y + 80
            };
            return {
              ...rest,
              oldId: id,
              position: newPosition
            };
          } else
            return {
              oldId: id,
              ...rest
            };
        })
        .filter((el) => {
          return !Object.values(TriggerTypeEnum).includes(el.type);
        });
      clonedStateRef.current = true;
      CopyElementsArrayRef.current = [];
      AddNodes(
        setElements,
        setRootId,
        newNodes,
        NodeTypeIdDictRef.current,
        updateInternalNodeIdDict
      );
    },
    [setElements]
  );
  const setSelection = useStoreActions((actions) => actions.setSelection);

  const [targetTriggerTest, setTargetTriggerTest] = useState();
  const unsetNodesSelection = useStoreActions(
    (actions) => actions.unsetNodesSelection
  );
  const state = useStoreState((state) => state);

  useEffect(() => {
    if (clonedStateRef.current) {
      clonedStateRef.current = false;
      unsetNodesSelection(false);
      setSelection(false);
      setSelectedElements(
        CopyElementsArrayRef.current.map((node) => ({
          id: node.id,
          type: node.type
        }))
      );
      CopyElementsArrayRef.current = [];
    }
  }, [elements, setSelectedElements, setSelection, state, unsetNodesSelection]);

  const updateInternalNodeIdDict = (newId) => {
    NodeTypeIdDictRef.current[newId] = true;
  };

  const handleSidebarOpen = useCallback(
    (e) => {
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: reactFlowBounds.width / 2,
        y: reactFlowBounds.height / 2
      });

      const addNewNode = (nodeType) => {
        const newNode = {
          type: nodeType,
          position
          // data: { },
        };
        AddNode(setElements, setRootId, newNode, NodeTypeIdDictRef.current);
        closeSidebar();
      };
      openSidebar(
        <NodesSidebar
          onNodeClick={addNewNode}
          rootId={rootId}
          position={position}
        />
      );
    },
    [closeSidebar, openSidebar, reactFlowInstance, rootId, setElements]
  );

  const handleSelection = (e) => {
    if (e) SelectedNodesRef.current = [...e];
  };

  // const onSelectNode = (e) => {

  // };

  const handleClick = (e) => {
    if (e && e.id && !e.source) SelectedNodesRef.current = [e];
  };

  const clearAllElements = () => {
    setElements([]);
    setRootId(undefined);
    automationCommunicator.dispatch({ rootId: undefined });
  };

  const elementsRef = useRef();
  elementsRef.current = elements;

  const handleAssignedToChange = (e) => {
    hasChangesNotSaved.current = true;
    const { value } = e.target;
    setAssignedTo(value);
  };
  const handleDescriptionChange = (e) => {
    hasChangesNotSaved.current = true;
    const { value } = e.target;
    setDescription(value);
  };
  const handleCompanyChange = (e) => {
    hasChangesNotSaved.current = true;
    const { value } = e.target;
    setCompany(value);
  };
  const handleDepartmentsChange = (e) => {
    hasChangesNotSaved.current = true;
    const { value } = e.target;
    setDepartments(value);
  };
  const handleTeamsChange = (e) => {
    hasChangesNotSaved.current = true;
    const { value } = e.target;
    setTeams(value);
  };
  const handleEnableScheduleChange = (e) => {
    hasChangesNotSaved.current = true;
    const { value } = e.target;
    setEnableSchedule(value);
  };
  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      hasChangesNotSaved.current = true;
      setElements((els) => updateEdge(oldEdge, newConnection, els));
    },
    [setElements]
  );

  // const onLayout = useCallback(
  //   (direction) => {
  //     const layoutedElements = getLayoutedElements(elements, direction);
  //     setElements(layoutedElements);
  //   },
  //   [elements, setElements]
  // );

  const handleZoom = (event) => {
    const { ctrlKey } = event;
    if (ctrlKey) {
      event.preventDefault();
      return;
    }
  };
  const intl = useIntl();
  const [showDetails, setShowDetails] = useState(true);
  const ReactFlowContainerRef = useRef();
  const ResolvedActiveItem = useMemo(() => {
    switch (activeFormIndex) {
      case 0:
        return (
          <div
            ref={ReactFlowContainerRef}
            onClick={handleContainerClick}
            className={classnames(
              styles.automationContainer,
              "w-100",
              styles.WorkflowFormBackground
            )}
            tabIndex="1"
          >
            <ElementsRefContext.Provider value={elementsRef}>
              <ReactFlow
                onSelectionChange={handleSelection}
                onElementClick={handleClick}
                deleteKeyCode={46}
                nodeTypes={nodeTypes}
                elements={elements}
                onConnect={onConnect}
                multiSelectionKeyCode={17}
                onEdgeUpdate={onEdgeUpdate}
                onElementsRemove={onElementsRemove}
                onNodeDragStop={savePast}
                onLoad={onLoad}
                onDrop={onDrop}
                onDragOver={onDragOver}
                edgeTypes={edgeTypes}
                snapToGrid={true}
                snapGrid={[15, 15]}
              >
                {/* <Nodes /> */}
                <MiniMap
                  className={classnames(styles.reactFlowMinimap)}
                  nodeStrokeColor={(n) => {
                    // 								background: ;
                    // color: #002b63;
                    // if (n.style?.background) return n.style.background;
                    // if (n.type === "input") return "#0041d0";
                    // if (n.type === "output") return "#ff0072";
                    // if (n.type === "default") return "#1a192b";

                    return "#d5dee8";
                  }}
                  nodeColor={(n) => {
                    const { data } = n;
                    if (data.enabled === false) return "#a0a0a0";
                    // if (n.style?.background) return n.style.background;

                    return "#d5dee8";
                  }}
                  nodeBorderRadius={2}
                />
                <Controls>
                  {/* <ControlButton
                    style={{ color: "black" }}
                    onClick={() => onLayout("LR")}
                  >
                    <FontAwesomeIcon icon={faCaretSquareDown} />
                  </ControlButton>
                  <ControlButton
                    style={{ color: "black" }}
										onClick={() => onLayout("TB")}
                    
                  >
                    <FontAwesomeIcon icon={faCaretSquareRight} />
                  </ControlButton> */}
                </Controls>
              </ReactFlow>
            </ElementsRefContext.Provider>
          </div>
        );
      case 1:
        return (
          <div
            className={classnames(
              styles.automationContainer,
              "w-100 bg-white overflow-auto h-100 pb-4"
            )}
          >
            <div
              style={{ margin: "auto" }}
              className="d-flex w-450px flex-column mt-5"
            >
              <FormInput
                textId="COMPANY"
                name="Company"
                value={company}
                inputType={OrganizationDropdown}
                onChange={handleCompanyChange}
                className="mb-3"
              />

              <FormInput
                value={description}
                textId="DESCRIPTION"
                name="Description"
                className="mb-3"
                onChange={handleDescriptionChange}
                inputType={HTMLInput}
              />

              <FormInput
                value={departments}
                name="Departments"
                className="mb-3"
                multiple
                text={intl.formatMessage({ id: "DEPARTMENT" })}
                inputType={DepartmentDropdown}
                onChange={handleDepartmentsChange}
              />
              <FormInput
                value={teams}
                name="Teams"
                multiple
                className="mb-3"
                text={intl.formatMessage({ id: "TEAM" })}
                onChange={handleTeamsChange}
                inputType={TeamDropdown}
              />

              <FormInput
                value={assignedTo}
                textId="ASSIGNED_TO"
                className="mb-3"
                onChange={handleAssignedToChange}
                inputType={UserDropdown}
              />
              <FormInput
                inputType={ScheduleSwitch}
                disabled={!CanEdit}
                preview={!CanEdit}
                className="mb-3"
                value={enableSchedule}
                onChange={handleEnableScheduleChange}
                name="Schedule"
              />
              {enableSchedule && (
                <div className="mb-3">
                  <WorkflowTimeTablePicker
                    onChange={setSchedules}
                    preview={!CanEdit}
                    value={schedules}
                  />
                </div>
              )}
              {enableSchedule && (
                <ExcludedDatesContainer
                  canEdit={CanEdit}
                  preview={!CanEdit}
                  onChange={setExcludedDates}
                  value={excludedDates}
                />
              )}
            </div>
          </div>
        );
      case 2:
        return (
          <div
            className={classnames(
              styles.automationContainer,
              "w-100 bg-white mt-3 of-hidden"
            )}
          >
            <ActivityOverview automationId={data} />
          </div>
        );
      default:
        return null;
    }
  }, [
    CanEdit,
    activeFormIndex,
    assignedTo,
    company,
    data,
    departments,
    description,
    elements,
    enableSchedule,
    excludedDates,
    handleContainerClick,
    intl,
    onConnect,
    onDrop,
    onEdgeUpdate,
    onElementsRemove,
    savePast,
    schedules,
    teams
  ]);

  const CopyElementsArrayRef = useRef([]);

  const handleKeyDowns = useCallback(
    (event) => {
      if (CanEdit) {
        if (event.ctrlKey && event.key === "z") {
          setElementsHistory((oldHistory) => {
            const newPast = [...oldHistory.past];
            if (newPast.length === 0) return oldHistory;

            const newPresent = newPast.pop();
            const newFuture = [...oldHistory.future, oldHistory.present];

            return {
              past: newPast,
              present: newPresent,
              future: newFuture
            };
          });
          setRootId(null);
          automationCommunicator.dispatch({ rootId: null });
        } else if (event.ctrlKey && event.key === "y") {
          setElementsHistory((oldHistory) => {
            const newFuture = [...oldHistory.future];
            if (newFuture.length === 0) return oldHistory;

            const newPresent = newFuture.pop();
            const newPast = [...oldHistory.past, oldHistory.present];
            return {
              past: newPast,
              present: newPresent,
              future: newFuture
            };
          });
          setRootId(null);
          automationCommunicator.dispatch({ rootId: null });
        } else if (event.ctrlKey && event.key === "c") {
          CopyElementsArrayRef.current = SelectedNodesRef.current;
        } else if (event.ctrlKey && event.key === "v") {
          CopyElementsArrayRef.current &&
            handleCloneNodesFunction(CopyElementsArrayRef.current);
        }
      }
    },
    [CanEdit, handleCloneNodesFunction]
  );

  const openTabs = useContext(SidebarCountContext);

  useEffect(() => {
    if (openTabs === 0) {
      window.addEventListener("keydown", handleKeyDowns);
      window.addEventListener("wheel", handleZoom, { passive: false });
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDowns);
      window.removeEventListener("wheel", handleZoom, { passive: false });
    };
  }, [handleKeyDowns, openTabs]);

  const handleWorkflowExport = () => {
    var tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = JSON.stringify({
      items: reactFlowInstance.toObject().elements,
      rootId: rootId
    });
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    createToast({
      pos: "tm",
      type: "success",
      description: `${intl.formatMessage({
        id: "WORKFLOW"
      })} ${intl.formatMessage({ id: "COPIED" })}`
    });
  };

  const [importModalStatus, setImportModalStatus] = useState(false);

  const importSubmit = (parsedJson) => {
    const { items, rootId } = parsedJson;
    setRootId(rootId);
    automationCommunicator.dispatch({ rootId: rootId });
    setElements(items);
  };

  const toggleImportModalStatus = () => {
    setImportModalStatus(!importModalStatus);
  };

  const [enrollModalStatus, setEnrollModalStatus] = useState(false);

  const toggleEnrollModalStatus = () => {
    setEnrollModalStatus(!enrollModalStatus);
  };

  return (
    <NodeUpdaterContext.Provider value={handleNodeUpdate}>
      <Prompt
        message={(location, action) => {
          if (
            hasChangesNotSaved.current &&
            location.pathname !== currentPage.current
          ) {
            return intl.formatMessage({ id: "WARN_UNSAVED" });
          } else if (!hasChangesNotSaved.current) return true;
          //
          // if (location.pathname === currentPage.current) return false;
          // else return hasChangesNotSaved.current;
        }}
      />
      <NodeStatusUpdaterContext.Provider value={handleNodeStatusUpdate}>
        <NodeTriggerTargetContext.Provider value={targetTriggerTest}>
          <NodeCloneFunctionContext.Provider value={handleCloneNodeButton}>
            <NodeShowDetailsContext.Provider value={showDetails}>
              <ElementRemoveContext.Provider value={onElementsRemove}>
                <NodeGetItemContext.Provider value={getElementData}>
                  <AutomationIdContext.Provider value={data}>
                    <FormCanEditContext.Provider value={CanEdit}>
                      {/* Import Modal */}
                      <Modal
                        enableCloseButton={"true"}
                        isOpen={importModalStatus}
                        onClose={toggleImportModalStatus}
                      >
                        <ImportModalContent
                          setElements={importSubmit}
                          closeModal={toggleImportModalStatus}
                        />
                      </Modal>
                      {/* Enroll Modal */}
                      <Modal
                        enableCloseButton={"true"}
                        isOpen={enrollModalStatus}
                        onClose={toggleEnrollModalStatus}
                      >
                        <EnrollModalContent
                          automationId={data}
                          rootId={rootId}
                          closeModal={toggleEnrollModalStatus}
                        />
                      </Modal>
                      <AutomationTest
                        assinggTargetTrigger={setTargetTriggerTest}
                        assignedTo={assignedTo}
                        instaces={reactFlowInstance}
                        automationId={data}
                        rootId={rootId}
                      >
                        <div
                          ref={reactFlowWrapper}
                          className="d-flex align-items-center h-100 flex-column"
                        >
                          <Header
                            toggleEnrollModal={toggleEnrollModalStatus}
                            toggleImportModal={toggleImportModalStatus}
                            exportJson={handleWorkflowExport}
                            title={title}
                            canEdit={CanEdit}
                            canDelete={CanDelete}
                            showDetails={showDetails}
                            setShowDetails={setShowDetails}
                            clearAllElements={clearAllElements}
                            isActive={isActive}
                            handleActiveChange={setIsActive}
                            ActiveItem={activeFormIndex}
                            SetActiveItem={setActiveFormIndex}
                            handleTittleChange={setTitle}
                            handleClick={handleSidebarOpen}
                            rootId={rootId}
                            automationId={data}
                            elements={reactFlowInstance}
                            postSave={handlePost}
                            postLoading={postLoading}
                          />
                          {ResolvedActiveItem}
                        </div>
                      </AutomationTest>
                    </FormCanEditContext.Provider>
                  </AutomationIdContext.Provider>
                </NodeGetItemContext.Provider>
              </ElementRemoveContext.Provider>
            </NodeShowDetailsContext.Provider>
          </NodeCloneFunctionContext.Provider>
        </NodeTriggerTargetContext.Provider>
      </NodeStatusUpdaterContext.Provider>
    </NodeUpdaterContext.Provider>
  );
};

const nodeTypes = {
  [NodeActionsEnum.condition_if]: IFNode,
  [NodeActionsEnum.delay]: DelayNode,
  [NodeActionsEnum.taskCreation]: TasksCreationNode,
  [NodeActionsEnum.contractCreation]: ContractsCreation,
  [NodeActionsEnum.propertySetter]: PropertySetterNode,
  [NodeActionsEnum.emailCreation]: EmailCreationNode,
  [NodeActionsEnum.doNothing]: DoNothingNode,
  [NodeActionsEnum.splitInBatches]: SplitInBatchesNode,
  [NodeActionsEnum.connectWorkflow]: ConnectWorkflow,
  [NodeActionsEnum.getTask]: GetTaskNode,
  [NodeActionsEnum.getContract]: GetContractNode,
  [NodeActionsEnum.getCall]: GetCallNode,
  [NodeActionsEnum.getContract]: GetContractNode,
  [NodeActionsEnum.getProject]: GetProjectode,
  [NodeActionsEnum.ticketCreation]: TicketsCreation,
  [NodeActionsEnum.getDeal]: GetDealNode,
  [NodeActionsEnum.getTicket]: GetTicketNode,
  [NodeActionsEnum.callCreation]: CallsCreationNode,
  [NodeActionsEnum.projectCreation]: ProjectsCreationNode,
  [NodeActionsEnum.contactCreation]: ContactCreation,
  [TriggerTypeEnum.trigger_manual]: ManualTrigger,
  [TriggerTypeEnum.trigger_webhook]: WebhookTrigger,
  [TriggerTypeEnum.trigger_cron]: CronNode,
  [TriggerTypeEnum.trigger_client_creation]: TriggerClientCreation,
  [TriggerTypeEnum.trigger_contact_creation]: TriggerContactCreation,
  [TriggerTypeEnum.trigger_deal_creation]: TriggerDealCreation,
  [TriggerTypeEnum.trigger_contract_creation]: TriggerContractCreation,
  [TriggerTypeEnum.trigger_subscription_creation]: TriggerSubscriptionCreation,
  [TriggerTypeEnum.trigger_ticket_creation]: TriggerTicketCreation,
  [TriggerTypeEnum.trigger_project_creation]: TriggerProjectCreation,
  [TriggerTypeEnum.trigger_task_creation]: TriggerTaskCreation,
  [TriggerTypeEnum.trigger_classification_creation]:
    TriggerClassificationCreation,
  [TriggerTypeEnum.trigger_call_creation]: TriggerCallCreation,
  [NodeActionsEnum.dealCreation]: DealsCreationNode,
  [NodeActionsEnum.subscriptionCreation]: SubscriptionCreation,
  [NodeActionsEnum.clientCreation]: ClientCreation,
  [NodeActionsEnum.httpRequest]: HttpRequestNode,
  [NodeActionsEnum.httpStatusRequest]: HttpStatusRequestNode,
  [NodeActionsEnum.getClient]: GetClientNode,
  [NodeActionsEnum.getContact]: GetContactNode,
  [NodeActionsEnum.getSubscription]: GetSubscriptionNode,
  [TriggerTypeEnum.trigger_time_creation]: TriggerTimeCreation,
  [NodeActionsEnum.timeReports]: TimeReportsNode,
  [NodeActionsEnum.exportEmailsConversations]: CaseEmailExportNode,
  [NodeActionsEnum.getTicketConversations]: GetTicketConversations,
  [NodeActionsEnum.getExportFileById]: GetGeneralExportNode,
  [NodeActionsEnum.conversationCreation]: EmailConversationCreationNode
};

export const ElementsRefContext = React.createContext();
export const ElementRemoveContext = React.createContext();
export const AutomationIdContext = React.createContext();

export default withRouter(AutomationDetails);
