import React, { Component, Suspense } from "react";
import SuspenseLoader from "../SuspenseLoader/SuspenseLoader";
import WarningExclamationSvg from "../Svg/WarningExclamationSvg";
import { withSpace } from "../../Contexts/SpaceContext";
// import { withUser } from "../../Contexts/UserContext";
// import { apiUrl } from "../../config/config.prod";
import { client } from "../../Helpers/IOClient";
import Button from "../Button/Button";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

class SuspenseErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, chunkError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // if(process.env.NODE_ENV === "development"){
    //   throw error;
    // }
    const { name } = error;
    return { hasError: true, chunkError: name === "ChunkLoadError" };
  }

  componentDidCatch(error, info) {
    if (error.name === "ChunkLoadError") return;

    const { space } = this.props;

    const message = {
      url: window.location.href,
      error: error.stack,
      reactInfo: info,
    };

    const body = {
      Type: 1,
      Category: 0,
      Message: JSON.stringify(message),
    };

    // You can also log the error to an error reporting service
    const url = `spaces/${space.Id}/logs`;
    // const url = `logs`;
    client.post(url, body);

    //   logErrorToMyService(error, info);
  }

  render() {
    const { sidebar } = this.props;
    const { hasError, chunkError } = this.state;
    if (chunkError) return <ChunkError />;

    if (hasError)
      return (
        <div
          style={sidebar ? { width: 520 } : undefined}
          className={classnames(
            "text-center h-100 d-flex align-items-center justify-content-center flex-column",
            { "bg-white": sidebar }
          )}
        >
          <WarningExclamationSvg className="ar-exclamation-warning-svg mx-auto mb-1" />
          <div className="text-warning fw-bold fs-22">
            <FormattedMessage id="MODULE_ERROR" />
          </div>
          <br />
          <div>
            <Button onClick={() => window.location.reload(true)}>
              <FormattedMessage id="REFRESH" />
            </Button>
          </div>
        </div>
      );

    let fallback;

    if (sidebar) {
      fallback = (
        <div className="bg-white h-100" style={{ width: 520 }}>
          <SuspenseLoader />
        </div>
      );
    } else fallback = <SuspenseLoader />;

    // return <Suspense fallback={fallback}>{this.props.children}</Suspense>;
    return <Suspense fallback={fallback}>{this.props.children}</Suspense>;
  }
}

const ChunkError = ({ sidebar }) => {
  return (
    <div
      style={{ minWidth: 520 }}
      className={classnames(
        "text-center h-100 w-100 d-flex align-items-center justify-content-center flex-column bg-white"
      )}
    >
      <WarningExclamationSvg className="ar-exclamation-warning-svg mx-auto mb-2" />
      <div className="text-warning fw-bold fs-22 mb-3">
        <FormattedMessage id="AIRDESK_UPDATE_TITLE" />
        <br />
        <FormattedMessage id="AIRDESK_UPDATE_DESCRIPTION" />
      </div>
      <Button className="mt-3" onClick={() => window.location.reload(true)}>
        <FormattedMessage id="REFRESH" />
      </Button>
    </div>
  );
};

export default withSpace(SuspenseErrorBoundary);
