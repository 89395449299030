import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from "react";
import {
  spaceTasksSchema,
  spaceInterventionsSchema
} from "../../config/schema";
import moment from "moment";
import { useTimelineQueryString } from "../../Helpers/ODataHelper";
import { Task } from "../../Interfaces/EntityInterfaces";
import { useMultipleEntityValueSelector } from "../../Hooks/AdvancedEntityHooks";
import { FormattedMessage } from "react-intl";
import { useHoverEvents } from "../Origin/Origin";
import Popup from "../../Components/Popup/Popup";
import TaskOrigin from "../Origin/TaskOrigin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { useSpaceQuery } from "../../Helpers/IOClient";
import InterventionOrigin from "../Origin/InterventionOrigin";

interface IGroup {
  startDate: number;
  endDate: number;
  tasks: any[];
}

const doDatesConflict = (
  taskBegin: number,
  taskEnd: number,
  groupBegin: number,
  groupEnd: number
) => {
  if (taskBegin > groupBegin && taskBegin < groupEnd) return true;
  //data inicial Comeca no grupo
  else if (taskEnd > groupBegin && taskEnd < groupEnd) return true;
  //data final acaba no grupo
  else if (taskBegin < groupBegin && taskEnd > groupEnd) return true; //ja engloba o grupo
  return false;
};

const addToGroups = (
  Groups: IGroup[],
  task: Task,
  fBeginDate: number,
  fEndDate: number
) => {
  for (const group of Groups) {
    const { startDate, endDate } = group;
    if (doDatesConflict(fBeginDate, fEndDate, startDate, endDate)) {
      group.tasks.push(task);
      if (fBeginDate < group.startDate) group.startDate = fBeginDate;

      if (fEndDate > group.endDate) group.endDate = fEndDate;
      return true;
    }
  }
  return false;
};

const calculateGroups = (tasks: Task[]) => {
  const Groups: IGroup[] = [];

  for (const task of tasks) {
    const { BeginDate, EndDate } = task;
    const fBeginDate = new Date(BeginDate).getTime();
    const fEndDate = new Date(EndDate).getTime();
    const added = addToGroups(Groups, task, fBeginDate, fEndDate);

    if (!added) {
      Groups.push({
        startDate: fBeginDate,
        endDate: fEndDate,
        tasks: [task]
      });
    }
  }

  return Groups;
};

const ConflictsResponse = {
  results: [spaceTasksSchema]
};

const TaskConflicts = ({ user, beginDate, endDate, taskId }: any) => {
  const timelineSettings = useMemo(() => {
    if (!user) return {};
    return {
      user: user,
      baseUrl: "tasks",
      baseField: "Task",
      ownId: taskId,
      BeginDateField: "BeginDate",
      EndDateField: "EndDate",
      AttributesField: "PipelineStatus/Status",
      Attribute: 1,
      schema: ConflictsResponse,
      startFormmated: moment(beginDate).toISOString(),
      endFormmated: moment(endDate).toISOString()
    };
  }, [beginDate, endDate, user, taskId]);

  const { data, loading, refetch } = useTimelineQueryString(timelineSettings);

  const OverlapedInterventionsUrl = useMemo(() => {
    if (moment(beginDate).isBefore("1972-01-01T00:00:00Z")) return null;

    const start = moment(beginDate).toISOString();
    const end = moment(endDate).toISOString();

    if (!user) return null;

    return `Interventions/${user}/overlap?beginDate=${start}&endDate=${end}`;
  }, [beginDate, endDate, user]);

  const { data: InterventionData, loading: interventionLoading } =
    useSpaceQuery(OverlapedInterventionsUrl, [spaceInterventionsSchema], {
      cache: false
    });

  // const mountedRef = useRef(false);

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (timelineSettings) handleRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineSettings]);

  const tasks = useMultipleEntityValueSelector(
    spaceTasksSchema,
    data?.d?.results || []
  ) as Task[];

  const times = useMultipleEntityValueSelector(
    spaceInterventionsSchema,
    InterventionData || []
  ) as any[];

  const Result = useMemo(() => {
    if (loading || interventionLoading) return null;
    if (tasks.length > 0 || times.length > 0) {
      const resolvedData = [...tasks, ...times];
      return (
        <ConflictBar
          beginDate={beginDate}
          endDate={endDate}
          data={resolvedData}
        />
      );
    } else return null;
  }, [beginDate, endDate, interventionLoading, loading, tasks, times]);

  return Result;
};

const ConflictBar = ({ beginDate, endDate, data }: any) => {
  const groups = useMemo(() => {
    if (Array.isArray(data)) {
      return calculateGroups(data);
    }
  }, [data]);

  const [MsMaxDate, MsBeginDate] = useMemo(() => {
    const MsBeginDate = moment(beginDate).valueOf();
    const MsEndDate = moment(endDate).valueOf();
    return [MsEndDate - MsBeginDate, MsBeginDate];
  }, [beginDate, endDate]);

  if (!groups) return null;

  return (
    <div className="w-100">
      <div className="mb-1 fs-14 d-flex align-items-center">
        <FontAwesomeIcon
          style={{ color: "#FF7A81" }}
          icon={faExclamationTriangle}
          className="mr-2"
        />
        <span style={{ color: "#FF8066" }}>
          <FormattedMessage id="TASK_OVERIMPOSED_FOUND" />
        </span>
      </div>
      <div
        style={{ height: 18, background: "#E7F1FD", borderRadius: "5px" }}
        className="d-inline-block of-hidden position-relative w-100 mb-1 border"
      >
        {groups.map((e, i) => {
          return (
            <ConflictContainer
              key={i}
              group={e}
              maxPercentage={MsMaxDate}
              msBeginDate={MsBeginDate}
            />
          );
        })}
      </div>
      <div className="d-flex text-black justify-content-between fs-12">
        <div>{moment(beginDate).format("YYYY-MM-DD - HH:mm")}</div>
        <div>{moment(endDate).format("YYYY-MM-DD - HH:mm")}</div>
      </div>
    </div>
  );
};

interface IConflictContainerProps {
  group: IGroup;
  maxPercentage: number;
  msBeginDate: number;
}

const ConflictContainer: FunctionComponent<IConflictContainerProps> = ({
  group,
  maxPercentage,
  msBeginDate
}) => {
  const { startDate, endDate, tasks } = group;

  const barPercentage = useMemo(() => {
    const Max = endDate - startDate;
    return (Max * 100) / maxPercentage;
  }, [endDate, maxPercentage, startDate]);

  const leftPercentage = useMemo(() => {
    const Max = startDate - msBeginDate;
    return (Max * 100) / maxPercentage;
  }, [maxPercentage, msBeginDate, startDate]);

  const anchorRef = useRef<HTMLDivElement>() as any;
  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  return (
    <>
      <Popup
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <div className="bg-white px-4 py-2 rounded">
          {tasks.map((e) => {
            if (e.IsTime) {
              return (
                <InterventionOrigin
                  className="my-2"
                  key={e.Id}
                  intervention={e}
                />
              );
            } else return <TaskOrigin className="my-2" key={e.Id} task={e} />;
          })}
        </div>
      </Popup>
      <div
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        ref={anchorRef}
        style={{
          width: `${barPercentage > 100 ? 100 : barPercentage}%`,
          left: `${leftPercentage < 0 ? 0 : leftPercentage}%`,
          borderLeft: `2px solid #7C98B6`,
          borderRight: `2px solid #7C98B6`,
          background: `repeating-linear-gradient(-45deg, #E7F1FD, #E7F1FD 3px, #7C98B6 0px, #7C98B6 8px)`
        }}
        className="position-absolute d-inline-block h-100"
      ></div>
    </>
  );
};

export default TaskConflicts;
