import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  useEmailConversation,
  useOrganization
} from "../../../Hooks/EntityHooks";
import { spaceEmailConversationSchema } from "../../../config/schema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDoubleRight,
  faAt,
  faEdit,
  faPaperclip,
  faThumbtack,
  faEnvelope
} from "@fortawesome/pro-light-svg-icons";
import { IconOverviewItem } from "../../../Components/EntityIconOverview/EntityIconOverview";
import { useReceptionTreeviewPermissionQuery } from "../../Treeview/Treeview";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import {
  EntitySummaryTabstrip,
  EntitySummaryTab
} from "../../../Components/EntitySummaries/EntitySummaries";
import { useSpacePost } from "../../../Helpers/IOClient";
import { MinimizeContext } from "../ReceptionComp";
import {
  ClientDropdown,
  ReceptionTargetDropdown,
  UserDropdown
} from "../../AdvancedMultiInputs";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import { useFormState } from "../../../Components/Forms";
import { ConnectedContactPopup } from "../../Origin/ContactPopup";
import LoadableButton from "../../../Components/Button/LoadableButton";
import { handleError } from "../../../Helpers/MiscHelper";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { ConnectedOrganizationProfile } from "../../../Components/Organizations/OrganizationProfile";
import { inboxEmailDomain } from "../../../config";
import {
  AssociationsList,
  AssociatorActionsButton
} from "./ReceptionEmailAssociations";
import TabStrip from "../../../Components/TabStrip/TabStrip";
import {
  ReceptionEmailAttachments,
  ReceptionNotesAttachments
} from "./ReceptionEmailAttachmentsComponents";
import { useMinimizeEvents } from "../ReceptionDetails";
import Popup from "../../../Components/Popup/Popup";
import { updateManagerFollowers } from "../../../Components/EntityFollow/EntityFollowHelper";

// const SummaryContentSingleContact = ({ contact }) => {
//   const { Name, Email } = useContact(contact);

//   return (
//     <>
//       <EntityOverviewItem
//         icon={faUserTieLight}
//         titleId="NAME"
//         className="text-black"
//       >
//         <TextEllipsis truncate={true} length={25} text={Name} />
//       </EntityOverviewItem>
//       <EntityOverviewItem
//         className="text-black"
//         icon={faEnvelope}
//         titleId="EMAIL"
//       >
//         <TextEllipsis truncate={true} length={25} text={Email} />
//       </EntityOverviewItem>
//     </>
//   );
// };

// const SummaryContentSingleAccount = ({ account }) => {
//   // const { Name, Email, Phone,...rest } = useAccount(account);
//   return (
//     <EntityOverviewItem className="text-black" icon={faUser} titleId="USER">
//       <ConnectedAccountPopup account={account} />
//     </EntityOverviewItem>
//   );
// };

// const SummaryContent = ({ ConversationId }) => {
//   const data = useContext(ConversationDataContext);

//   const { Accounts, Contacts, Manager, Followers } = data || {};

//   return (
//     <div className="of-y-auto h-100">
//       <div className="d-flex flex-column">
//         <EntitySummaryAccordion
//           className="px-0"
//           icon={faUserTie}
//           textId="CONTACTS"
//         >
//           {Contacts &&
//             Contacts.map((e, i) => {
//               return (
//                 <div key={i}>
//                   {i !== 0 && <hr className="my-2" />}
//                   <SummaryContentSingleContact contact={e} />
//                 </div>
//               );
//             })}
//         </EntitySummaryAccordion>
//         {Accounts && (
//           <EntitySummaryAccordion
//             className="px-0"
//             icon={faUserTie}
//             textId="USERS"
//           >
//             {Accounts.map((e, i) => {
//               return (
//                 <div
//                   className={classnames({ "mt-3": i === 0 }, "mb-3")}
//                   key={i}
//                 >
//                   {/* {i!== 0 && <hr className="my-2" />} */}
//                   <SummaryContentSingleAccount account={e} />
//                 </div>
//               );
//             })}
//           </EntitySummaryAccordion>
//         )}
//         <EntitySummaryAccordion
//           className="px-0"
//           icon={faUsers}
//           textId="ASSIGNED_TO"
//         >
//           <EntityOverviewItem
//             className="text-black"
//             icon={faUserPlus}
//             titleId="MANAGER"
//           >
//             <ConnectedAccountPopup account={Manager} />
//           </EntityOverviewItem>
//         </EntitySummaryAccordion>
//         <EntitySummaryAccordion
//           className="px-0"
//           icon={faUserCheck}
//           textId="FOLLOWERS"
//         >
//           <EntityOverviewItem
//             className="text-black"
//             icon={faUsersMedical}
//             titleId="USERS"
//           >
//             <ConnectedAccountProfileStack accounts={Followers} />
//           </EntityOverviewItem>
//         </EntitySummaryAccordion>
//       </div>
//     </div>
//   );
// };

// const TicketReceptionDescription = ({
//   Id,
//   Subject,
//   Status,
//   handleClose,
//   Conversation,
//   closeStatus,
// }) => {
//   const Ticket = useTicket(Id);

//   const { data, loading, error } = useSpaceQuery(
//     `tickets/${Ticket.Id}`,
//     spaceTicketsSchema
//   );

//   return (
//     <div className="d-flex flex-column h-100 of-hidden">
//       <div className="fs-18 d-flex justify-items-between mb-3 align-items-center">
//         <div onClick={handleClose} className="text-primary mr-3 cursor-pointer">
//           <FontAwesomeIcon
//             rotation={closeStatus ? 180 : undefined}
//             icon={faChevronDoubleRight}
//           />
//         </div>
//         <div className="mr-2">
//           {!closeStatus && <MessageItemProfileStatus Status={Status} />}
//         </div>
//         <div className="text-black">{!closeStatus && Subject}</div>
//       </div>
//       <div className="d-flex justify-content-between align-items-center">
//         {!closeStatus && (
//           <>
//             <div className="fs-14 d-flex align-items-center white-space-pre">
//               <EntityIconOverview
//                 noHistory={false}
//                 noChildren={false}
//                 schema={spaceTicketsSchema}
//                 value={Ticket}
//               />
//             </div>
//             <div className="d-flex">
//               <TicketFormButton
//                 vType="outline-primary"
//                 className="mr-2"
//                 textId={"EDIT"}
//                 canEdit={Ticket.CanEdit}
//                 id={Ticket.Id}
//               />
//               <TicketEntityActionsButton sidebar entity={Ticket.Id} />
//             </div>
//           </>
//         )}
//       </div>
//       {!closeStatus && (
//         <>
//           <hr className="w-100 mb-2" />
//           <div className="mb-2">
//             <FormattedMessage id="ORIGIN" />
//           </div>
//           <div style={{ width: "fit-content" }}>
//             {data && <OriginOverview entity={{ Ticket: Id }} />}
//           </div>
//           <hr className="w-100 my-2" />
//           <EntitySummaryTabstrip className="mt-2 mb-3 mx-0 flex-1">
//             <EntitySummaryTab titleId="SUMMARY">
//               <SummaryContent ConversationId={Conversation.Id} />
//             </EntitySummaryTab>
//             <EntitySummaryTab titleId="ATTACHMENTS">
//               <div></div>
//             </EntitySummaryTab>
//           </EntitySummaryTabstrip>
//         </>
//       )}
//     </div>
//   );
// };

// const TaskReceptionDescription = ({ Id, LastEmailId, Status, handleClose }) => {
//   const Task = useTask(Id);
//   return (
//     <div className="mt-1 d-flex align-items-center justify-content-between">
//       <div className="fs-14 d-flex align-items-center">
//         <EntityIconOverview
//           noHistory={false}
//           noChildren={false}
//           schema={spaceTasksSchema}
//           value={Task}
//         />
//       </div>
//       <div className="d-flex">
//         {Task.CanEdit && <TaskFormButton CanEdit={Task.CanEdit} id={Task} />}
//         <TaskEntityActionsButton entity={Task} minimal={true} sidebar={true} />
//       </div>
//     </div>
//   );
// };

// const DealReceptionDescription = ({ Id, LastEmailId, Status, handleClose }) => {
//   const Deal = useDeal(Id);
//   return (
//     <div className="mt-1 d-flex align-items-center justify-content-between">
//       <div className="fs-14 d-flex align-items-center">
//         <EntityIconOverview
//           noHistory={false}
//           noChildren={false}
//           schema={spaceDealsSchema}
//           value={Deal}
//         />
//       </div>
//       <div className="d-flex">
//         {Deal.CanEdit && <DealFormButton CanEdit={Deal.CanEdit} id={Deal} />}
//         <DealEntityActionsButton entity={Deal} minimal={true} sidebar={true} />
//       </div>
//     </div>
//   );
// };

const SummaryIconComponent = ({ Icon, Value }) => {
  if (!Value) return null;

  return (
    <div className="d-flex align-items-center fs-14 mr-2">
      <FontAwesomeIcon icon={Icon} className="mr-1" />
      <span>{Value}</span>
    </div>
  );
};

const formatConversationForm = (form) => {
  const {
    Followers,
    Client,
    Manager,
    Tasks = [],
    Tickets = [],
    Deals = []
  } = form;
  const TicketIds = [...Tickets];
  const TaskIds = [...Tasks];
  const DealIds = [...Deals];

  const resolvedForm = {
    ClientId: Client,
    TicketIds,
    TaskIds,
    DealIds,
    Followers,
    ManagerId: Manager
  };
  return resolvedForm;
};

const TabItems = [
  {
    name: (
      <div className="d-flex align-items-center">
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
        <FormattedMessage id="EMAIL_ATTACHMENTS" />
      </div>
    ),
    component: <ReceptionEmailAttachments />
  },
  {
    name: (
      <div className="d-flex align-items-center">
        <FontAwesomeIcon icon={faEdit} className="mr-2" />
        <FormattedMessage id="NOTE_ATTACHMENTS" />
      </div>
    ),
    component: <ReceptionNotesAttachments />
  }
];

const ReceptionEmailSummary = ({ OpenedId }) => {
  const Conversation = useEmailConversation(OpenedId);

  const {
    Tickets: TicksConv,
    Tasks: TasksConv,
    Deals: DealsConv,
    // Subject,
    // Status,
    Client,
    FirstContacts,
    Contacts,
    CanEdit,
    Manager,
    SupportEmail,
    InboundEmail,
    Organization,
    Followers,
    FilesCount,
    NotesCount,
    PinnedNotesCount,
    MessagesCount
  } = Conversation || {};
  let options = {};
  const { minimized, toggleMinimized } = useContext(MinimizeContext);
  const { handleMouseEnter, handleMouseLeave } = useMinimizeEvents();
  const isOpen = !minimized;

  const handleClose = useCallback(() => {
    toggleMinimized();
  }, [toggleMinimized]);

  const { loading } = useReceptionTreeviewPermissionQuery(
    options.schema,
    options.value
  );

  const formState = useFormState(() => {
    return {
      Client,
      FirstContacts,
      Contacts: [...new Set(Contacts)],
      Tasks: TasksConv,
      Tickets: TicksConv,
      Deals: DealsConv,
      Manager,
      Followers
    };
  });
  const { form, handleChange } = formState;

  const originalFormRef = useRef(form);
  const originalForm = originalFormRef.current;

  const hasChanged = useMemo(() => {
    for (const key in form) {
      if (form.hasOwnProperty(key)) {
        if (form[key] !== originalForm[key]) return true;
      }
    }
    return false;
  }, [form, originalForm]);

  const {
    Client: ClientForm,
    // FirstContact: FirstContactFrom,
    Contacts: ContactsForm,
    Tasks,
    Tickets,
    Deals,
    Manager: ManagerForm,
    Followers: FollowersForm
  } = form;

  const handleManagerChange = ({ target: { value } }) => {
    handleChange({
      Manager: value,
      Followers: updateManagerFollowers(FollowersForm, ManagerForm, value, [])
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value
    });
  };

  const { Name } = useOrganization(Organization) || {};

  const onAssociationChange = (e) => {
    const { value } = e.target;
    const { type, data } = value;
    switch (type) {
      case "Ticket":
        const newTickets = [...Tickets, data];
        const uniqTickets = [...new Set(newTickets)];
        handleChange({
          Tickets: uniqTickets
        });
        break;
      case "Deal":
        const newDeals = [...Deals, data];
        const uniqDeals = [...new Set(newDeals)];
        handleChange({
          Deals: uniqDeals
        });
        break;
      case "Task":
        const newTasks = [...Tasks, data];
        const uniqTasks = [...new Set(newTasks)];
        handleChange({
          Tasks: uniqTasks
        });
        break;
      default:
        break;
    }
  };

  const RemoveAssosiation = (i, entity) => {
    switch (entity) {
      case "case":
        const newTickets = [...(Tickets || [])];
        newTickets.splice(i, 1);
        handleChange({
          Tickets: newTickets
        });
        break;
      case "task":
        const newTasks = [...(Tasks || [])];
        newTasks.splice(i, 1);
        handleChange({
          Tasks: newTasks
        });
        break;
      case "deal":
        const newDeals = [...(Deals || [])];
        newDeals.splice(i, 1);
        handleChange({
          Deals: newDeals
        });
        break;
      default:
        break;
    }
  };
  const anchorRef = useRef();
  const createToast = useToast();
  const intl = useIntl();
  const [post, { loading: postLoading }] = useSpacePost(
    `EmailConversation/${OpenedId}`,
    spaceEmailConversationSchema,
    {
      onSuccess: ({ data: newId }) => {
        originalFormRef.current = form;
        createToast({
          pos: "tm",
          type: "success",
          description: `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`
        });
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  const [errorState, setErrorState] = useState({});
  const [copiedPopupState, setCopiedPopupState] = useState(false);

  useEffect(() => {
    if (!copiedPopupState) return;

    const timeout = setTimeout(() => {
      setCopiedPopupState(false);
    }, 600);

    return () => clearTimeout(timeout);
  }, [copiedPopupState]);

  const handleFormValidation = (form) => {
    const Error = {};
    let result = true;
    if (!form.Client) {
      Error.Client = true;
      result = false;
    }
    if (!form.Manager) {
      Error.Manager = true;
      result = false;
    }
    setErrorState(Error);
    return result;
  };

  const handleSubmit = () => {
    const isFormValid = handleFormValidation(form);
    if (isFormValid) {
      const FormatedForm = formatConversationForm(form);
      post(FormatedForm);
    }
  };

  const resolvedEmail = useMemo(() => {
    const { DomainEmail, Email } = InboundEmail || {};

    const isNotCustomEmail = RegExp(`${inboxEmailDomain}$`).test(SupportEmail);

    return isNotCustomEmail ? Email : DomainEmail;
  }, [InboundEmail, SupportEmail]);

  if (loading) {
    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ zIndex: 6 }}
        className="d-flex flex-column"
      >
        <div
          className={classnames(
            "air-reception-description-container pb-0 overflow-hidden d-flex flex-column",
            {
              closed: !isOpen
            }
          )}
        >
          <div className="d-flex flex-column align-items-center justify-content-center h-100 of-hidden ">
            <LoaderSpinner size="sm" center />
          </div>
        </div>
      </div>
    );
  }

  const copyTextToClipboard = () => {
    const text = SupportEmail;
    var textArea = document.createElement("textarea");

    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    textArea.style.width = "2em";
    textArea.style.height = "2em";

    textArea.style.padding = 0;

    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    textArea.style.background = "transparent";

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      setCopiedPopupState(true);
    } catch (err) {
      console.log("Oops, unable to copy");
    }

    document.body.removeChild(textArea);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ zIndex: 6 }}
      className="d-flex flex-column"
    >
      <div
        className={classnames(
          "air-reception-description-container pb-0 overflow-hidden d-flex flex-column",
          {
            closed: !isOpen
          }
        )}
      >
        <div className="d-flex flex-column h-100 of-hidden ">
          <div
            style={{ height: 70 }}
            className="fs-18 d-flex justify-items-between align-items-center"
          >
            <div
              onClick={handleClose}
              className="text-primary ar-reception-minimize-button d-flex align-items-center justify-content-center cursor-pointer"
            >
              <FontAwesomeIcon
                size="lg"
                rotation={!isOpen ? 180 : undefined}
                icon={faChevronDoubleRight}
              />
            </div>

            <div className="mr-2 mt-1 d-flex align-items-center">
              {isOpen && (
                <ConnectedOrganizationProfile
                  justImage
                  size="md"
                  organization={Organization}
                />
              )}
              {isOpen && (
                <div className="ml-2">
                  <div className="text-truncate text-black fs-14">{Name}</div>
                  <div className="text-truncate fs-12">{resolvedEmail}</div>
                </div>
              )}
            </div>
            {/* <div className="text-black">{isOpen && Subject}</div> */}
          </div>
          {/* <div className="border-bottom mx-3 mb-1"></div> */}
          {isOpen && (
            <div className="d-flex align-items-center air-reception-description-padding py-0">
              <SummaryIconComponent Icon={faEnvelope} Value={MessagesCount} />
              <SummaryIconComponent Icon={faEdit} Value={NotesCount} />
              <SummaryIconComponent Icon={faPaperclip} Value={FilesCount} />
              <SummaryIconComponent
                Icon={faThumbtack}
                Value={PinnedNotesCount}
              />
              <div ref={anchorRef}>
                <IconOverviewItem
                  onClick={copyTextToClipboard}
                  className="fs-14 cursor-pointer"
                  icon={faAt}
                  title={`Email: ${SupportEmail}`}
                />
                <Popup
                  placement="top"
                  anchorEl={anchorRef.current}
                  modifiers={{
                    preventOverflow: { boundariesElement: "viewport" }
                  }}
                  isOpen={copiedPopupState}
                >
                  <div
                    className="bg-black text-white px-2 py-1 fs-14 rounded"
                    style={{ cursor: "context-menu" }}
                  >
                    <span>
                      <FormattedMessage id="COPIED" />
                    </span>
                  </div>
                </Popup>
              </div>
            </div>
          )}
          {isOpen && (
            <div className="air-reception-description-padding of-y-auto d-flex flex-column py-0">
              <hr className="w-100 mb-2" />
              <div className="mb-2 text-black fs-14">
                <FormattedMessage id="ACCOUNT" />
              </div>
              <div className="w-100">
                <FormInput
                  error={errorState.Client}
                  name="Client"
                  inputType={ClientDropdown}
                  value={ClientForm}
                  preview={!CanEdit}
                  className="mb-3 text-truncate"
                  onChange={onChange}
                  allClients={false}
                />
              </div>
              <div className="mb-2 text-black fs-14">
                <FormattedMessage id="MAIN_CONTACTS" />
              </div>
              <div className="w-100">
                {/* <ConnectedContactProfileStack contacts={FirstContacts} /> */}
                {FirstContacts?.map((v, i) => {
                  return (
                    <ConnectedContactPopup
                      className={
                        i !== FirstContacts.length - 1 ? "mb-2" : undefined
                      }
                      maincontact="true"
                      key={i}
                      dynamicFont
                      size="mds"
                      contact={v}
                    />
                  );
                }) || <div></div>}
                {/* <ConnectedContactPopup
                  className="fs-14 fw-medium"
                  dynamicFont
                  size="md"
                  contact={FirstContactFrom}
                /> */}
              </div>
              <hr className="w-100 my-3" />
              <EntitySummaryTabstrip noFlex className="mt-2  mx-0">
                <EntitySummaryTab titleId="DETAILS">
                  <div className="w-100 pt-3 px-2">
                    <div className="fs-14 fw-medium mb-3 text-black">
                      <FormattedMessage id="ASSOCIATED_TO" />
                    </div>
                    {ContactsForm?.length > 0 && (
                      <>
                        <div className="fs-14 mb-1 text-black">
                          <FormattedMessage id="CONTACTS" />
                        </div>
                        <div className="mb-3">
                          {ContactsForm.map((e, i, arr) => {
                            return (
                              <div
                                key={i}
                                className={i !== arr.length - 1 ? "mb-2" : ""}
                              >
                                <ConnectedContactPopup
                                  showemail="true"
                                  size="sm"
                                  contact={e}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                    {CanEdit && (
                      <FormInput
                        text={
                          <div className="d-flex justify-content-between align-items-center">
                            <FormattedMessage id="ASSOCIATED" />
                            <AssociatorActionsButton
                              handleChange={handleChange}
                              minimal
                              entity={Conversation}
                            />
                          </div>
                        }
                        className="mb-3"
                        inputType={ReceptionTargetDropdown}
                        placeholder={intl.formatMessage({
                          id: "SEARCH_ASSOCIATION"
                        })}
                        allClients={false}
                        onChange={onAssociationChange}
                      />
                    )}

                    <AssociationsList
                      canAddEntities
                      CanEdit={CanEdit}
                      removeEntity={RemoveAssosiation}
                      Tickets={Tickets}
                      Tasks={Tasks}
                      Deals={Deals}
                    />

                    <hr className="w-100 mb-2" />

                    <div className="w-100">
                      <div className="fs-14 fw-medium mb-3 text-black">
                        <FormattedMessage id="RECEPTION_ASSIGNED_TO" />
                      </div>
                      <FormInput
                        error={errorState.Manager}
                        className="mb-3"
                        text={<FormattedMessage id="MANAGER" />}
                        inputType={UserDropdown}
                        name="Manager"
                        preview={!CanEdit}
                        value={ManagerForm}
                        onChange={handleManagerChange}
                      />
                      <FormInput
                        className="mb-3"
                        text={<FormattedMessage id="FOLLOWERS" />}
                        inputType={UserDropdown}
                        preview={!CanEdit}
                        multiple
                        name="Followers"
                        value={FollowersForm}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </EntitySummaryTab>
                <EntitySummaryTab titleId="ATTACHMENTS">
                  <TabStrip
                    route={false}
                    alignLeft
                    items={TabItems}
                    className="ar-details-tabstrip mt-3 h-100"
                  />
                </EntitySummaryTab>
              </EntitySummaryTabstrip>
            </div>
          )}
        </div>
        {hasChanged && (
          <div
            style={{ width: !isOpen && "0px" }}
            className="py-3 px-4 text-truncate shadow-top details-form-footer d-flex justify-content-between align-items-center shadow-top"
          >
            <div></div>
            <LoadableButton
              isLoading={postLoading}
              onClick={handleSubmit}
              className="text-truncate"
              style={{ display: !isOpen && "none" }}
            >
              <FormattedMessage id="CONFIRM" />
            </LoadableButton>
          </div>
        )}
        {/* {isOpen && (
          <>
            <hr className="w-100 mb-2" />
            <div className="mb-2 text-black fs-14">
              <FormattedMessage id="ACCOUNT" />
            </div>
            <div className="w-100">
              <FormInput
                name="Client"
                //   text={ <FormattedMessage id="customer" />}
                inputType={ClientDropdown}
                value={ClientForm}
                className="mb-3"
                onChange={onChange}
                allClients={false}
              />
            </div>
            <div className="mb-2 text-black fs-14">
              <FormattedMessage id="CONTACT" />
            </div>
            <div className="w-100">
              <ConnectedContactPopup
                className="fs-14 fw-medium"
                dynamicFont
                size="md"
                contact={FirstContactFrom}
              />
            </div>
            <hr className="w-100 my-3" />
            <EntitySummaryTabstrip className="mt-2 mb-3 mx-0">
              <EntitySummaryTab titleId="DETAILS">
                <div className="fs-14 fw-medium mb-3 text-black">
                  <FormattedMessage id="ASSOCIATED_TO" />
                </div>
                <div className="fs-14 mb-3 text-black">
                  <FormattedMessage id="CONTACTS" />
                </div>
                {ContactsForm.map((e, i) => {
                  return (
                    <div className="mb-2" key={i}>
                      <ConnectedContactPopup size="md" contact={e} />
                    </div>
                  );
                })}
              </EntitySummaryTab>
              <EntitySummaryTab titleId="ATTACHMENTS">
                <div></div>
              </EntitySummaryTab>
            </EntitySummaryTabstrip>
          </>
        )} */}
      </div>
    </div>
  );
};

export default ReceptionEmailSummary;
