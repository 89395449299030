import React from "react";
import { spaceTasksSchema } from "../../config/schema";
import { TaskOriginPopup } from "../../Containers/Origin/TaskOrigin";
import { taskCommunicator } from "../../Containers/Tasks/TasksHelper";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { useTask } from "../../Hooks/EntityHooks";
import Calendar from "./Calendar";

const TaskPopup = ({ entityId }) => {
  const { loading, error, data } = useSpaceQuery(
    `tasks/${entityId}/`,
    spaceTasksSchema
  );

  const task = useTask(data);

  if (loading || error) return null;

  return <TaskOriginPopup task={task} />;
};

const settings = {
  schema: spaceTasksSchema,
  communicator: taskCommunicator,
  getKey: (v) => v,
  TextComp: ({ value }) => {
    const { Name } = useTask(value);
    return <span>{Name}</span>;
  },
  BeginDateField: "Task/BeginDateNoHours",
  EndDateField: "Task/EndDateNoHours",
  moduleType: moduleTypes.tasks,
  EntityPopup: TaskPopup,
};

const CalendarTestView = () => {
  return <Calendar settings={settings} />;
};

export default CalendarTestView;
