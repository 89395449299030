import React, { useCallback, useContext, useMemo, useState } from "react";
import { FilterViewTypeEnum } from "../FilterList";
import { FilterConditionsEnum } from "./AdvancedFilterConditions";
import {
  dateOperatorsOptions,
  FilterOperatorsEnum,
  numberOperatorsOptions,
  stringOperatorsOptions
} from "./AdvancedFilterOperators";

export interface IFilterProps {
  availableFilters: IFilterOption[];
  filterType: FilterViewTypeEnum;
  onSuccess: (advancedFilter: number) => void;
  onRemoval: (advancedFilter: number) => void;
  id: number;
  advancedfilter: any;
}

interface IMappedField {
  resolvedField: string;
  urlField: string;
}

export interface IFilterOption {
  field?: string;
  icon?: {};
  mappedFields?: IMappedField[];
  getCompleteValue: (resolvedField: string, value: any) => any;
  getNormalizationSchema: () => any;
  createAdvanvedFilterValue: (value: any) => any | undefined;
  getSchemaFromCompleteValue: (value: any) => any;
  getEntityEndpoint?: (spaceId: number, entityId: number) => string;
  title: any;
  titleId?: string;
  type?: FilterOptionTypeEnum;
  component?: any;
  resolvedFilter?: any;
  disableAdvancedFilter?: boolean;
}

export interface IFilter {
  Condition?: FilterConditionsEnum;
  Parameter?: IFilterOption;
  Operator?: FilterOperatorsEnum;
  Value?: any;
  TemplateValue?: any;
  Groups: IFilter[];
}

export enum FilterOptionTypeEnum {
  number = "number",
  string = "string",
  datetime = "dateTime",
  date = "date"
}

export const getDefaultParameterValues = (newParam: IFilterOption) => {
  let Operator;
  let Value;
  const { type } = newParam;

  switch (type) {
    case FilterOptionTypeEnum.string:
      Operator = stringOperatorsOptions[0];
      Value = "";
      break;

    case FilterOptionTypeEnum.number:
      Operator = numberOperatorsOptions[0];
      Value = "0";
      break;

    case FilterOptionTypeEnum.datetime:
      Operator = dateOperatorsOptions[0];
      Value = new Date();
      break;

    default:
      Operator = FilterOperatorsEnum.Eq;
      Value = null;
      break;
  }

  return {
    Operator,
    Value
  };
};

export const AvailableFiltersContext = React.createContext({
  availableFilters: [] as IFilterOption[],
  fieldDict: {},
  mappedFieldDict: {}
});

export const useAvailableFilters = () => useContext(AvailableFiltersContext);

export const createNewCondition = (
  resolvedAvailableFilters: IFilterOption[]
) => {
  const firstFilter = resolvedAvailableFilters[0];

  const { Operator, Value } = getDefaultParameterValues(firstFilter);

  const newCondition: IFilter = {
    Condition: FilterConditionsEnum.And,
    Parameter: firstFilter,
    Operator: Operator,
    Value: Value,
    Groups: []
  };
  return newCondition;
};

export const useAdvancedFilters = (
  availableFilters: any[],
  startFilter?: IFilter
) => {
  const [filter, setFilter] = useState<IFilter>(() => {
    //;

    if (startFilter) return startFilter;
    const firstFilter = availableFilters[0];

    const { Operator, Value } = getDefaultParameterValues(firstFilter);

    const newGroup: IFilter = {
      Condition: FilterConditionsEnum.And,
      Parameter: firstFilter,
      Operator: Operator,
      Value: Value,
      Groups: []
    };

    const newGroups = [newGroup];

    return {
      Groups: newGroups
    };
  });

  const addCondition = useCallback(() => {
    const newCondition = createNewCondition(availableFilters);

    setFilter((f) => {
      const Condition =
        f.Groups.length > 0 ? f.Groups[0].Condition : FilterConditionsEnum.And;
      newCondition.Condition = Condition;
      const newGroups = [...f.Groups, newCondition];
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, [availableFilters]);

  const addGroup = useCallback(() => {
    setFilter((f) => {
      const newCondition = createNewCondition(availableFilters);

      const Condition =
        f.Groups.length > 0 ? f.Groups[0].Condition : FilterConditionsEnum.And;

      const newGroup = {
        Condition,
        Groups: [newCondition]
      };

      const newGroups = [...f.Groups, newGroup];
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, [availableFilters]);

  const updateGroup = useCallback((index: number, newGroupsFunc: any) => {
    setFilter((f) => {
      const newGroups = [...f.Groups];
      newGroups[index] = newGroupsFunc(newGroups[index]);
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, []);

  const removeGroup = useCallback((index: number) => {
    setFilter((f) => {
      const newGroups = [...f.Groups];
      newGroups.splice(index, 1);
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, []);

  const updateCondition = useCallback((newCondition: FilterConditionsEnum) => {
    setFilter((f) => {
      const newGroups = [...f.Groups];

      for (const g of newGroups) {
        g.Condition = newCondition;
      }

      return {
        ...f,
        Groups: newGroups
      };
    });
  }, []);

  return {
    updateCondition,
    removeGroup,
    updateGroup,
    addGroup,
    addCondition,
    filter
  };
};

const replaceFieldSlash = (param: string) => {
  return param.replace("/", ".");
};

export const useResolvedAvailableFilters = (filters: IFilterOption[]) => {
  return useMemo(() => {
    // const arr = [...filters];
    const arr = filters.reduce<IFilterOption[]>((val: any, originalElem) => {
      if (originalElem.disableAdvancedFilter) return val;

      const element = { ...originalElem };
      delete element.icon;
      if (originalElem.resolvedFilter) {
        element.field = element.field?.replace("/", ".");
        val.push(element);

        return val;
      }

      if (element.field) element.field = replaceFieldSlash(element.field);

      if (element.mappedFields) {
        element.mappedFields = [...element.mappedFields];

        for (let i = 0; i < element.mappedFields.length; i++) {
          const newMappedField = { ...element.mappedFields[i] };
          newMappedField.resolvedField = replaceFieldSlash(
            newMappedField.resolvedField
          );

          element.mappedFields[i] = newMappedField;
        }
      }
      delete val.icon;
      val.push(element);
      return val;
    }, []);
    // for (let i = 0; i < arr.length; i++) {
    //   const element = { ...arr[i] };
    //   if (element.field) element.field = replaceFieldSlash(element.field);

    //   if (element.mappedFields) {
    //     element.mappedFields = [...element.mappedFields];

    //     for (let i = 0; i < element.mappedFields.length; i++) {
    //       const newMappedField = { ...element.mappedFields[i] };
    //       newMappedField.resolvedField = replaceFieldSlash(
    //         newMappedField.resolvedField
    //       );

    //       element.mappedFields[i] = newMappedField;
    //     }
    //   }
    //   arr[i] = element;
    // }
    return arr;
  }, [filters]);
};

export const useFiltersDict = (filters: IFilterOption[]) => {
  return useMemo(() => {
    const fieldDict: { [field: string]: IFilterOption } = {};
    const mappedFieldDict: { [field: string]: IFilterOption } = {};

    for (const filter of filters) {
      const { field, mappedFields } = filter;

      if (!field) continue;

      fieldDict[field] = filter;

      if (mappedFields) {
        for (const mappedField of mappedFields) {
          const { resolvedField } = mappedField;
          mappedFieldDict[resolvedField] = filter;
        }
      }
    }

    return { fieldDict, mappedFieldDict };
  }, [filters]);
};

export const useResolvedAvailableFiltersValue = (filters: IFilterOption[]) => {
  const resolvedAvailableFilters = useResolvedAvailableFilters(filters);

  const { fieldDict, mappedFieldDict } = useFiltersDict(
    resolvedAvailableFilters
  );

  const availableFiltersValue = useMemo(() => {
    return {
      availableFilters: resolvedAvailableFilters,
      mappedFieldDict,
      fieldDict
    };
  }, [fieldDict, mappedFieldDict, resolvedAvailableFilters]);

  return availableFiltersValue;
};
