import { faFileSpreadsheet } from "@fortawesome/pro-light-svg-icons";
import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import DockTyper from "../../../../../Components/DocTyper/DocTyper";
import FormInput from "../../../../../Components/Forms/FormInput/FormInput";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import { spaceSignatureAggregationSchema } from "../../../../../config/schema";
import { getEntity } from "../../../../../Helpers/IOClient";
import {
  ClientDropdown,
  ContactDropdown,
  EmailSignaturesTypesDropdown,
  InterventionDropdown,
  OrganizationDropdown,
  UserDropdown
} from "../../../../AdvancedMultiInputs";
import { ElementsRefContext } from "../../AutomationDetails";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import SourceHandles from "../../Handles/SourceHandles";
import TargetHandles from "../../Handles/TargetHandles";
import AutomationTestResults from "../AutomationTestResults";
import AutomationCreation, {
  EntityAutomationCreationSidebar
} from "../Creation/CreationNode";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
const EmailFormContent = ({
  formState,
  organizations,
  pipelines,
  Automation,
  id,
  canEdit,
  disableAttachments,
  disableConversations
}) => {
  const { form, handleChange } = formState;
  const { Subject } = form;

  const changes = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value
    });
  };

  // const {
  //   Tickets,
  //   Deals,
  //   Tasks,
  //   ticketId: fTicketId,
  //   dealId: fDealId,
  //   taskId: fTaskId
  // } = form;

  return (
    <>
      <FormInput
        textId="TIMES"
        name="TimeCharges"
        multiple
        inputType={InterventionDropdown}
        className="mb-3"
      />

      <FormInput
        textId="CREATED_BY"
        name="Creator"
        inputType={UserDropdown}
        className="mb-3"
      />

      <FormInput
        name="Company"
        text={<FormattedMessage id={"COMPANY"} />}
        inputType={OrganizationDropdown}
        // onChange={handleCompanyChange}
        className="mb-3"
      />

      <FormInput
        className="mb-3"
        name="Client"
        text={<FormattedMessage id={"ACCOUNT"} />}
        inputType={ClientDropdown}
      />

      <FormInput
        className="mb-3"
        name="To"
        text={<FormattedMessage id={"EMAIL_TO"} />}
        inputType={ContactDropdown}
        multiple
        hasEmail
      />

      <FormInput
        className="mb-3"
        name="Cc"
        text={"Cc"}
        inputType={ContactDropdown}
        multiple
        hasEmail
      />

      <FormInput
        className="mb-3"
        name="Bcc"
        text={"Bcc"}
        inputType={ContactDropdown}
        multiple
        hasEmail
      />

      <FormInput
        className="mb-3"
        name="Subject"
        onChange={changes}
        value={Subject}
        text={<FormattedMessage id={"SUBJECT"} />}
      />

      <FormInput
        className="mb-3"
        name="Text"
        isHtmlInput
        inputType={DockTyper}
        text={<FormattedMessage id={"MESSAGE"} />}
      />

      <FormInput
        className="mb-3"
        name="Signature"
        inputType={EmailSignaturesTypesDropdown}
        type={1}
        text={<FormattedMessage id={"SIGNATURE"} />}
      />
    </>
  );
};

const formatAutomationEmailForm = ({ form, currencies }) => {
  const {
    Text,
    Subject,
    To,
    Cc,
    Bcc,
    Company,
    Client,
    Creator,
    TimeCharges,
    // Tasks,
    Signature
    // Tickets,
    // Deals,
  } = form;

  let resolvedSignature = {};
  if (Signature) {
    const { type, data } = Signature;
    const connectedSignature = getEntity(spaceSignatureAggregationSchema, data);
    const { Company, Department, Team, Id: id } = connectedSignature || {};
    let organization_id = undefined;
    switch (type) {
      case "Company":
        organization_id = Company;
        resolvedSignature = {
          type: "Company",
          organization_id,
          id
        };
        break;
      case "Department":
        organization_id = Department;
        resolvedSignature = {
          type: "Department",
          organization_id,
          id
        };
        break;
      case "Team":
        organization_id = Team;
        resolvedSignature = {
          type: "Team",
          organization_id,
          id
        };
        break;
      default:
        break;
    }
  }

  return {
    organization_space_id: Company,
    creator: Creator,
    client: Client,
    to_ids: isValueExpression(To) ? To : To?.join(","),
    cc_ids: isValueExpression(Cc) ? Cc : Cc?.join(","),
    bcc_ids: isValueExpression(Bcc) ? Bcc : Bcc?.join(","),
    time_charge_ids: isValueExpression(TimeCharges)
      ? TimeCharges
      : TimeCharges?.join(","),
    signature: resolvedSignature,
    subject: Subject,
    text: Text
  };
};

const isValueExpression = (val) => {
  if (typeof val === "string" && val[0] === "=") return true;
  else return false;
};

const convertAutomationToEmailForm = (form, userId) => {
  const {
    client,
    to_ids,
    cc_ids,
    bcc_ids,
    subject,
    text,
    tickets,
    creator,
    organization_space_id,
    tasks,
    time_charge_ids,
    deals,
    signature
  } = form;

  return {
    Company: organization_space_id,
    Creator: creator,
    Client: client,
    TimeCharges: isValueExpression(time_charge_ids)
      ? time_charge_ids
      : time_charge_ids?.length > 0
      ? time_charge_ids?.split(",")
      : [],
    To: isValueExpression(to_ids)
      ? to_ids
      : to_ids?.length > 0
      ? to_ids?.split(",")
      : [],
    Cc: isValueExpression(cc_ids)
      ? cc_ids
      : cc_ids?.length > 0
      ? cc_ids?.split(",")
      : [],
    Bcc: isValueExpression(bcc_ids)
      ? bcc_ids
      : bcc_ids?.length > 0
      ? bcc_ids?.split(",")
      : [],
    Subject: subject,
    Text: text,
    Signature: signature.id ? { type: signature.type, data: signature.id } : null,
    Tickets: tickets,
    Tasks: tasks,
    Deals: deals
  };
};

const settings = {
  convertAutomationToForm: convertAutomationToEmailForm,
  buildBaseForm: ({ user, id, convertedForm }) => {
    const EmailForm = defaultReportTimechargeForm();
    return {
      ...EmailForm,
      ...convertedForm
    };
  },
  getDefaultForm: () => {
    return defaultReportTimechargeForm();
  },
  formatToSnake: formatAutomationEmailForm,
  FormContent: (props) => {
    return (
      <EmailFormContent
        disableAttachments
        Automation
        disableConversations
        {...props}
      ></EmailFormContent>
    );
  }
};

const TimeReportsNode = React.memo(({ selected, data, id, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();
  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleClick = () => {
    openSidebar(
      <EntityAutomationCreationSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        data={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };
  const { enabled, config } = data;
  const intl = useIntl();
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <AutomationCreation
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleClick}
          selected={selected}
          icon={faFileSpreadsheet}
          text={intl.formatMessage({ id: "CREATE_TIME_REPORT" })}
        />
        {/* <NodeContainer
          enabled={enabled}
					type={type}
          selected={selected}
          onDoubleClick={handleClick}
        >
          <div className={classnames(styles.nodeIcon, "p-3")}>
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
          <div className="px-3 d-flex justify-content-center bg-white flex-column text-black fs-14">
            <div className="fw-medium">Email</div>
          </div>
        </NodeContainer> */}

        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

const defaultReportTimechargeForm = () => {
  return {
    Company: null,
    Client: null,
    Signature: null,
    To: [],
    Cc: [],
    Bcc: [],
    TimeCharges: [],
    Subject: "",
    Text: "",
    Tickets: [],
    Tasks: [],
    Deals: []
  };
};

export default TimeReportsNode;
