import React from "react";
import {
  isValidDomainName,
  isValidSubDomainName,
} from "../../../Helpers/MiscHelper";
import Input from "../../../Components/Input/Input";
import classnames from "classnames";
import { EmailDomainDropdown } from "../../AdvancedMultiInputs";

export const EmailDomainInput = ({
  value,
  onChange,
  domainValue,
  onDomainChange,
  className,
  Company,
  error,
  subDomain,
}) => {
  const handleChange = (e) => {
    const { value } = e.target;
    const isValid =
      value === "" ||
      (value.length <= 30 && subDomain
        ? isValidSubDomainName(value)
        : isValidDomainName(value));
    if (!isValid) return;

    onChange(value);
  };

  //   const { DomainName } = useSpace();

  return (
    <div
      className={classnames(
        "d-flex align-items-center justify-content-center",
        className
      )}
    >
      <Input
        disabled={!Company}
        className={classnames("text-right flex-1", { error: error })}
        value={value}
        onChange={handleChange}
      />
      <div className="mx-2">@</div>
      <div style={{ width: "60%" }}>
        <EmailDomainDropdown
          className={error && "error"}
          error={error}
          disabled={!Company}
          value={domainValue}
          onChange={onDomainChange}
          Company={Company}
        />
      </div>
    </div>
  );
};
