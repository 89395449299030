// @flow
import React from 'react'
import styles from './PoweredByGiphy.module.css'
import classnames from 'classnames'


const PoweredByGiphy = ({ image }) => (
  <div className={classnames(styles.poweredByGiphy, 'd-flex align-items-center justify-content-end')}>
		<span className="fs-14 text-black mr-2">Powered by</span>
    <img src={image} alt="Powered by Giphy" data-testid="PoweredByGiphy" />
  </div>
)

export default PoweredByGiphy
