import React, { useContext, useEffect } from "react";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { Tr } from "../../Components/Table";
import {
  // spaceTasksSchema,

  spaceDealsSchema
} from "../../config/schema";
import RelativeTime from "../../Components/RelativeTime/RelativeTime";
// import { MilSecToTime } from "../../Helpers/MiscHelper";
import {
  DashboardTable,
  DashboardTitle,
  DashboardEmptyResponse,
  ReportsDateProvider
} from "./Dashboard";
// import { faListCheck } from "@fortawesome/pro-regular-svg-icons";

import {
  ConnectedClientOrigin
  //   ConnectedClientOriginAnchor
} from "../Origin/ClientOrigin";

// import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import { DealEntityGridButtons } from "../Deals/DealsHelper";
// import { ConnectedPipelineStatusGridListItem } from "../AdvancedMultiInputs/PipelineDropdowns";
import {
  useDeal,
  usePipeline,
  usePipelineStatus,
  useCurrency
} from "../../Hooks/EntityHooks";
// import { DealFormButton } from "../Form/FormButtons";
// import { faHandshake } from "@fortawesome/pro-light-svg-icons";
// import { ConnectedClientListItem } from "../../Components/Clients/ClientListItem";
import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";
// import { ConnectedClientProfileStack } from "../../Components/Accounts/AccountProfileStack";
import { useSpace } from "../../Contexts/SpaceContext";
import { DealLink } from "../../Helpers/HooksHelper";
import { FormattedMessage } from "react-intl";
import { DashboardSettingsContext } from "./DashboardLayout";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import DashboardCardSettingsForm, {
  handleIntervalCalculation
} from "./DashboardCardSettingsForm";
import Button from "../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import { useDashboardQuery } from "./DashboardQueryHelper";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const columns = [
  {
    minWidth: 90,
    width: 90,
    Cell: (value) => <DealEntityGridButtons entity={value} />
  },
  {
    title: "Nº",
    Cell: ({ Id }) => (
      <span className="text-primary">
        <DealLink id={Id}>{Id}</DealLink>
      </span>
    )
  },
  {
    title: <FormattedMessage id={"NAME"} />,
    Cell: ({ Name }) => <span>{Name}</span>
  },
  {
    title: <FormattedMessage id={"PIPELINE"} />,
    Cell: ({ Pipeline }) => {
      const resolvedPipe = usePipeline(Pipeline);
      return <span>{resolvedPipe?.Name}</span>;
    }
  },
  {
    title: <FormattedMessage id={"PIPELINE_STATUS"} />,
    Cell: ({ PipelineStatus }) => {
      const resolvedPipelineStatus = usePipelineStatus(PipelineStatus);
      return (
        <span className="fs-14" style={{ color: "#0E6400", fontWeight: 400 }}>
          {resolvedPipelineStatus?.Name}
        </span>
      );
    }
  },
  {
    title: <FormattedMessage id={"ACCOUNT"} />,
    Cell: ({ Client, IndirectClient }) => (
      <ConnectedClientOrigin client={Client}>
        <ConnectedClientProfile client={Client} justImage />
      </ConnectedClientOrigin>
    )
  },
  {
    title: <FormattedMessage id={"DEADLINE"} />,
    Cell: ({ EndDate }) => <RelativeTime date={EndDate} />
  },
  {
    title: function Title() {
      const { Currency: CurrencyId } = useSpace();
      const Currency = useCurrency(CurrencyId);
      return (
        <>
          <FormattedMessage id={"VALUE"} />
          {` ${Currency && Currency.Symbol}`}
        </>
      );
    },
    Cell: ({ EstimatedCost }) => {
      const formatter = new Intl.NumberFormat("de-DE", {
        style: "decimal",
        minimumFractionDigits: 2
      });
      return <span>{formatter.format(EstimatedCost.Cost)}</span>;
    }
  }
];

const TaskTr = ({ value, ...rest }) => {
  const task = useDeal(value);

  return <Tr value={task} {...rest} />;
};

// const defaultTimeFrame = moment().subtract(180, "d").format("YYYY-MM-DD");

const lS_Group = "dashboard-layout-deals";
const DashboardOpenDealsOverview = (props) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "dealsOpenDealsOverview";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const {
    data,
    loading,
    error
  } = useDashboardQuery(
    `Dashboard/Services/Deals/1/list?todate=${
      DataReports[0] === "Deals"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Deals"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Deals"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`,
    [spaceDealsSchema],
    { cache: false }
  );

  const buildBody = () => {
    if (loading)
      return <LoaderSpinner size="sm text-secondary" center />;

    
			if (!loading && error?.status === 403) {
				return	(<DashboardEmptyResponse type="deals">
						{DataReports ? (
							DataReports[4] === "Account" ? (
								<FormattedMessage id={"EMPLOYEE_NO_DEPARTMNET"} />
							) : (
								<FormattedMessage id={"EMPLOYE_NO_FILTER"} />
							)
						) : (
							<FormattedMessage id={"EMPLOYEE"} />
						)}
					</DashboardEmptyResponse>

			);
		}

    if (data?.length === 0)
      return (
        <DashboardEmptyResponse className="h-50" type="deals">
          <FormattedMessage id={"EMPTY_DEALS"} />
        </DashboardEmptyResponse>
      );
    if (error)
      return (
        <DashboardEmptyResponse className="h-50" type="deals">
          <FormattedMessage id={"EMPTY_DEALS"} />
        </DashboardEmptyResponse>
      );

    return (
      <DashboardTable
        advanced
        className="ar-dashboard-table"
        TrComponent={TaskTr}
        columns={columns}
        data={data}
      />
    );
  };
  const user =
    DataReports[0] === "Deals" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  return (
    <div className="px-3 d-flex flex-column h-100">
      <DashboardTitle
        className="mb-1 fs-20"
        text={<FormattedMessage id={"OPEN_DEALS"} />}
      />
      {DataReports[0] !== "Deals" && (
        <div className="mb-2 d-flex justify-content-between align-items-center">
          <div className="mb-2 ">
            <span className="ar-dashboard-description">
              <FormattedMessage id={"INTERVAL"} />:
            </span>
            <span className="btn-link pl-1 fs-14">
              {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                <FormattedMessage id={Settings.Interval.title} />
              ) : (
                <FormattedMessage id={"ALL"} />
              )}
            </span>
            <span className="ar-dashboard-description ml-1">
              | <FormattedMessage id={"SCOPE"} />:
            </span>
            <span className="btn-link pl-1 fs-14">{user}</span>
          </div>
          <Button
            className="ar-dashboard-card-settings-link"
            vType="link-primary"
            onClick={() =>
              openSidebar(
                <DashboardCardSettingsForm
                  lS_Field={lS_Field}
                  lS_Group={lS_Group}
                  Settings={Settings}
                  closeSidebar={closeSidebar}
                  Title={"OPEN_DEALS"}
                  handleChanges={handleLayoutChange}
                  AvailableSettings={{
                    interval: true,
                    scope: true,
                    pipeline: false
                  }}
                />
              )
            }
          >
            <FontAwesomeIcon icon={faCog} />
          </Button>
        </div>
      )}
      {buildBody()}
    </div>
  );
};

export default DashboardOpenDealsOverview;
