import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";
// import logo from './logo.svg';
import "./App.css";
import UserProvider from "./Contexts/UserContext";
import SpaceRoutes from "./Containers/Space/SpaceRoutes";
import AutoRedirect from "./Containers/Routes/AutoRedirect";
import { UserRealTimeProvider } from "./Containers/RealTime/RealTimeUser";
import InviteProviderChecker from "./UserInviteProvider";

class MainApp extends PureComponent {
  render() {
    return (
      <UserRealTimeProvider>
        <InviteProviderChecker>
          <UserProvider>
            <Switch>
              <Route path="/s" component={SpaceRoutes} />
              <AutoRedirect />
            </Switch>
          </UserProvider>
        </InviteProviderChecker>
      </UserRealTimeProvider>
    );
  }
}

export default MainApp;
