import React, { useState, useMemo, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSpacePostQuery } from "../../Helpers/IOClient";
import {
  DetailsSettingsContext,
  DetailsValueIdContext,
} from "../DetailsView2/DetailsView2Helper";
import { MainViewPreferencesSetterContext } from "../Preferences/Preferences";
import SuspenseLoader from "../SuspenseLoader/SuspenseLoader";

export const ServerAwarePreferencesKeyContext = React.createContext();

export const ServerAwarePreferences = ({ moduleType, children }) => {
  const { search } = useLocation();
  const history = useHistory();
  const detailSettings = useContext(DetailsSettingsContext);
  const detailsEntityId = useContext(DetailsValueIdContext);

  const [shouldGetPreference] = useState(() => !Boolean(search));

  const updatePreferenceSearch = useContext(MainViewPreferencesSetterContext);

  const body = useMemo(() => {
    let Key;
    if (!moduleType) return {};

    if (detailSettings) {
      const { type: detailType } = detailSettings;

      Key = `${detailType}-${detailsEntityId}-details-${moduleType}-overview`;
    } else {
      Key = `${moduleType}-overview`;
    }

    return {
      Key,
    };
  }, [detailSettings, detailsEntityId, moduleType]);

  useEffect(() => {
    if (detailSettings) return;
    updatePreferenceSearch(moduleType, search);
  }, [
    detailSettings,
    moduleType,
    search,
    shouldGetPreference,
    updatePreferenceSearch,
  ]);

  const { loading } = useSpacePostQuery(
    shouldGetPreference && body.Key ? `Preference` : null,
    body,
    null,
    {
      cache: false,
      onSuccess: ({ data }) => {
        try {
          const search = JSON.parse(data).search;
          if (!detailSettings) updatePreferenceSearch(moduleType, search);
          // history.replace({ search });
        } catch {}
      },
      onError: ({ error }) => {
        if (error && error.status === 404) {
          if (detailsEntityId) history.replace({ search: "$filter=" });
        }
      },
    }
  );

  if (loading) return <SuspenseLoader />;
  // <LoaderSpinner size="sm" className="text-primary" center />;

  return (
    <ServerAwarePreferencesKeyContext.Provider
      value={body ? body.Key : undefined}
    >
      {children}
    </ServerAwarePreferencesKeyContext.Provider>
  );
};
