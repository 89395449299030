/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useMemo,
  useLayoutEffect,
  useCallback,
  Component
} from "react";
import ReactDOM from "react-dom";
import { subscribeUniqueEvent } from "../../Helpers/ComponentsHelper";

import Modal from "../Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { ActionButtonItem } from "../ActionsButton/ActionsButton";
import Button from "../Button/Button";
import classnames from "classnames";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { spaceChecklistTemplatesSchema } from "../../config/schema";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import OrganizationProfile from "../Organizations/OrganizationProfile";
import "./TemplateChooser.css";
import Checklist from "../Checklist/Checklist";
import { FormattedMessage } from "react-intl";
export const ChecklistTemplateChooser = ({
  schema,
  onClose,
  setIsOpen,
  isOpen,
  sumbitToDo,
  Columns,
  ...rest
}) => {
  const { data, loading, error } = useSpaceQuery(`query/todotemplates`, null);
  const [chosenChecklist, setChosenChecklist] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [chosenItem, setChosenItem] = useState(null);

  const handleToDoClick = (ToDos) => {
    const data = ToDos;
    const returnData = (e) => {
      return {
        ...e,
        title: e.Name || e.title,
        Name: e.Name || e.title,
        children: [],
        ParentId: e.ParentId,
        Id: e.Id,
        Checked: e.Checked,
        expanded: true
      };
    };

    let initialData = data.filter((e) => !e.ParentId).map((e) => returnData(e));

    const buildObject = (arr) => {
      if (!data.some((e) => e.ParentId)) return [];

      arr.forEach((item) => {
        item.children = data
          .filter((e) => e.ParentId === item.Id)
          .map((e) => returnData(e));

        buildObject(item.children);
      });
      return arr;
    };

    //   const getFlatList = (arr, current) => {
    // 	current.forEach(item => {
    // 	  arr.push({ name: item.title, Id: item.Id, parentId: item.parentId });
    // 	  if (item.children) {
    // 		getFlatList(arr, item.children);
    // 	  }
    // 	});
    // 	return arr;
    //   };

    buildObject(initialData);
    setChosenChecklist(initialData);
  };

  const fixChecklist = (todo) => {
    const data = todo;
    const returnData2 = (e) => {
      return {
        ...e,
        Id: undefined
      };
    };

    let initialData = data.filter((e) => !e.ParentId).map((e) => returnData2(e));

    const buildObject = (arr) => {
      arr.forEach((item) => {
				item.children = item.children.map((e) => returnData2(e));
        buildObject(item.children);
      });
      return arr;
    };
   
    return  buildObject(initialData);;
  };

  const resolvedData = useMemo(() => {
    if (!searchBarValue) return data;

    const newData = data.map((e) => {
      return {
        Organization: e.Organization,
        Data: e.Data.filter(
          (v) =>
            v.Name &&
            v.Name.toLowerCase().includes(searchBarValue.toLowerCase())
        )
      };
    });

    return newData;
  }, [data, searchBarValue]);

  return (
    <div className="ColumnChooser-ModalWrap">
      <div className="p-30">
        <div className="d-flex justify-content-between">
          <div className="fs-16 w-50 mb-10 fw-bold text-black d-flex justify-content-between">
            <span className="pt-1">
              <FormattedMessage id={"TEMPLATES"} />
            </span>
          </div>

          <div className="w-50 fs-16 mb-10 fw-medium text-black d-flex justify-content-between pl-4">
            <span className="pt-1">
              <FormattedMessage id={"PREVIEW"} />
            </span>
          </div>
        </div>
        <div className="d-flex mb-4">
          <div className="ColumnChooserContent justify-content-between pr-4 ColumnChooserContent-borderRight">
            <ColumnChooserSearchBar
              value={searchBarValue}
              handleChange={setSearchBarValue}
              className="w-a mb-3"
            />
            {loading || error ? (
              <div className="align-items-center d-flex h-100 justify-content-center of-y-auto">
                <LoaderSpinner className="text-secondary w-75 h-25" />
              </div>
            ) : (
              <div className="h-100 of-y-auto">
                <ChecklistChooser
                  pickedItem={chosenItem}
                  handleItemPick={setChosenItem}
                  handleClick={handleToDoClick}
                  Data={resolvedData}
                />
              </div>
            )}
          </div>
          <div className="ColumnChooserContent justify-content-between pl-4">
            <Checklist
              disableMaximise
              nodeWidth={290}
              onChange={handleToDoClick}
              value={chosenChecklist}
              preview={true}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div></div>
          <div>
            <Button
              className="mr-3"
              onClick={() => {
                setIsOpen(false);
              }}
              vType="link-danger"
            >
              <FormattedMessage id={"CANCEL"} />
            </Button>
            <Button
              onClick={() => {
                sumbitToDo(fixChecklist(chosenChecklist));
                setIsOpen(false);
              }}
              vType="primary"
            >
              <FormattedMessage id={"APPLY"} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColumnChooserSearchBar = ({
  handleChange,
  className,
  value,
  focused,
  ...rest
}) => {
  const { inputRef, handleFocus, handleBlur } = rest;
  const placeholder = "Procurar Template";

  const onChange = (e) => {
    handleChange(e.target.value);
  };

  return (
    <div
      className={classnames(
        "ssi-control rounded column-chooser-search-bar text-black ar-server-aware-input d-flex align-items-center p-2 mb-2",
        className,
        {
          selected: focused
        }
      )}
    >
      <div className="ar-server-aware-input-icon mr-2">
        {/* {isLoading ? (
			<LoaderSpinner className="text-primary" />
		  ) : (
			<FontAwesomeIcon icon={faSearch} className="text-primary" />
		  )} */}
        <FontAwesomeIcon icon={faSearch} className="text-primary" />
      </div>
      <Input
        ref={inputRef}
        name="search-text"
        autoComplete="off"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={() => {
          if (handleBlur) handleBlur();
        }}
        className="flat-input p-0"
        {...rest}
      />
      {value.length > 0 && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleChange("");
          }}
        >
          {" "}
          <FontAwesomeIcon icon={faTimes} className="text-warning" />
        </div>
      )}
    </div>
  );
};

const ChecklistChooser = ({
  pickedItem,
  handleItemPick,
  Data,
  handleClick
}) => {
  const result = Data.map((e, i) => {
    if (!e.Value.Data.length > 0) return null;

    return (
      <div className="pr-1" key={i}>
        <div className="mb-2 cursor-auto ar-dropdown-list-button ar-dropdown-list-button-hint">
          <OrganizationProfile organization={e.Value.Organization} />
        </div>
        <div className="text-black mb-2 ">
          {e.Value.Data.map((t, ti) => {
            if (parseInt(t.ToDosCount) === 0) return null;
            return (
              <div
                onClick={() => {
                  handleClick(t.ToDos);
                  handleItemPick({
                    Organization: e.Value.Organization.Id,
                    Todo: t.Id
                  });
                }}
                key={ti}
                className={classnames(
                  "fs-14 cursor-pointer mb-1 invisibleBorderItem ar-dropdown-list-button rounded",
                  {
                    "ar-dropdown-list-button-hint":
                      pickedItem &&
                      pickedItem.Organization === e.Value.Organization.Id &&
                      pickedItem.Todo === t.Id,
                    chosenItemActive:
                      pickedItem &&
                      pickedItem.Organization === e.Value.Organization.Id &&
                      pickedItem.Todo === t.Id
                  }
                )}
              >
                {t.Name}
              </div>
            );
          })}
        </div>
      </div>
    );
  });
  return <div>{result}</div>;
};
