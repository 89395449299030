import React from "react";
import { usePipelineStatus, useSubscription } from "../../Hooks/EntityHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { spaceSubscriptionSchema } from "../../config/schema";
import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";
import SplitCol from "../../Components/SplitCol/SplitCol";
import {
  ConnectedDepartmentProfileStack,
  ConnectedAccountsTeamsDepartmentsProfileStack
} from "../../Components/Accounts/AccountProfileStack";

import {
  ConnectSubscriptionEntity,
  SubscriptionStatusProfile,
  SubscriptionStatusProfileWithIcon
} from "../../Components/Subscription/SubscriptionProfile";
import { ConnectedSubscriptionTypeProfile } from "../../Components/SubscriptionType/SubscriptionTypeProfile";
import {
  OriginHeader,
  OriginItemGroup,
  OriginDate,
  OriginContainer
} from "../../Components/Origin/Origin";
import { createOriginPopup } from "./Origin";
import { ConnectedClientOrigin } from "./ClientOrigin";
import { ConnectedAccountPopup } from "./AcountPopup";
import { ConnectedContactPopup } from "./ContactPopup";
import OriginOverview from "./OriginOverview";
import { SubscriptionEntityGridButtons } from "../Subscriptions/SubscriptionsHelper";
import {
  faCalendarAlt,
  faCalendarExclamation,
  faDrawCircle,
  // faFileContract,
  faSuitcase,
  faUser,
  faUsersClass,
  faUserTie
} from "@fortawesome/pro-solid-svg-icons";
import { SubscriptionIcon } from "../../Components/Common/EntityIcons";
import { useIntl } from "react-intl";
import classnames from "classnames";
import Button from "../../Components/Button/Button";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { PipelineStatusProfile } from "../AdvancedMultiInputs/PipelineDropdowns";
import { useSpace } from "../../Contexts/SpaceContext";
import { useConnectedSchemaGetter } from "../../Helpers/HelperHooks";
// import { buildProjectStatusType } from "../../Components/Projects/ProjectProfile";
export const SubscriptionOriginAnchor = React.forwardRef(
  ({ Subscription, children, dynamicFont, ...rest }, ref) => {
    const openForm = useEntitySummarySidebar();
    if (!Subscription) return null;
    const { IsDeleted } = Subscription;
    return (
      <div
        ref={ref}
        className={classnames("link-color", {
          "item-was-deleted": IsDeleted
        })}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <>
            <div
              className={classnames(
                "link-color d-flex align-items-center text-truncate",
                {
                  "fs-12": !dynamicFont
                }
              )}
            >
              <div className="d-inline-flex align-items-center mr-1">
                <SubscriptionStatusProfileWithIcon
                  Icon={<FontAwesomeIcon icon={faCalendarExclamation} />}
                  inline
                  hideName
                  status={Subscription.Status}
                />
              </div>
              <Button
                vType="link-primary"
                className="EntityLinkClassID fs-12 text-black"
                onClick={() => {
                  openForm({
                    type: moduleTypes.subscriptions,
                    props: {
                      id: Subscription.Id
                    }
                  });
                }}
              >
                <span>{Subscription.NoTagDescription}</span>
              </Button>
              {/* <div className="d-inline-flex align-items-center">
                <div className="position-relative d-inline-block mr-1">
                  <FontAwesomeIcon icon={faFileContract} />
                </div>
              </div> */}
              {/* <SubscriptionsLink id={Subscription.Id}>
                <span>
                  {Subscription.Id}{" "}
                  {Subscription.NoTagDescription || Subscription.Description}
                </span>
              </SubscriptionsLink> */}
              {/* {Id && <div className="fs-10">Nº: {Id}</div>} */}
            </div>
          </>
        )}
      </div>
    );
  }
);
export const ConnectedSubscriptionOriginAnchor = ({
  Subscription,
  ...rest
}) => {
  const resolvedSubscription = useSubscription(Subscription);

  const Result = useConnectedSchemaGetter(
    Subscription,
    spaceSubscriptionSchema
  );

  if (Result) return Result;

  return (
    <SubscriptionOriginAnchor Subscription={resolvedSubscription} {...rest} />
  );
};

export const SubscriptionOriginPopup = ({ Subscription }) => {
  const intl = useIntl();
  const c = useSubscription(Subscription.Id);

  const {
    Id,
    // Status,
    Client,
    IndirectClient,
    NoTagDescription,
    Contact,
    Manager,
    Departments,
    // ReceivedDate,
    PipelineStatus,
    EndDate,
    BeginDate,
    Collaborators,
    SubscriptionType,
    CreationDate
  } = c;
  const space = useSpace();
  const resolvedClient = Client || IndirectClient;

  const hasDepartments = Boolean(Departments && Departments.length > 0);
  const hasAccounts = Boolean(
    Collaborators.Accounts && Collaborators.Accounts.length > 0
  );
  const hasTeams = Boolean(
    Collaborators.Teams && Collaborators.Teams.length > 0
  );
  const connectedPipelineStatus = usePipelineStatus(PipelineStatus);
  return (
    <OriginContainer>
      <OriginHeader
        Dif
        className="mb-1"
        date={CreationDate}
        type="Subscription"
        entity="SUBSCRIPTION"
        Icon={<SubscriptionIcon className="mr-2" />}
        EntityButtons={<SubscriptionEntityGridButtons entity={c} minimal />}
        id={Id}
        link={`/s/${space.Id}/subscription/${Id}`}
        title={NoTagDescription}
      />
      <div className="mx-3 mb-1">
        {/* <div className="text-black fs-14 mb-2">{Name}</div> */}
        <div className="mb-1 d-flex align-items-center justify-content-between">
          <PipelineStatusProfile
            StatusProfileComponent={SubscriptionStatusProfile}
            pipeline={connectedPipelineStatus}
          />
          <div>
            <OriginDate className="fs-12" date={CreationDate} />
          </div>
        </div>
      </div>
      {/* <div className=" mb-2 origin_header_div text-white px-3">
        <span className="origin_header">
          <SubscriptionIcon className="mr-2" />{" "}
          <FormattedMessage id={"SUBSCRIPTION_NR"} /> {Id}
        </span>
      </div> */}
      {/* <OriginHeader
        id={Id}
        Icon={SubscriptionIcon}
        className="mb-1"
        date={ReceivedDate}
        title={Description}
        type="Contrato"
      /> */}
      {/* <div className="d-flex justify-content-between px-3">
        <div className="d-flex align-items-center text-black fw-medium">
          <ProjectIcon className="mr-1" />
          <div className="mr-1">Projeto</div>
          {Id}
        </div>
        <OriginDate className="fs-14" date={ReceivedDate} />
	  </div> */}
      {/* <OriginItemGroup title="STATUS"></OriginItemGroup> */}
      {/* <div className="mx-3 mb-3"> */}
      {/* <div className="text-black fs-14 mb-2">{Name}</div> */}
      {/* <SubscriptionStatusProfile className="mb-1" status={Status} /> */}
      {/* <ProjectHourPercentageBar
          title={intl.formatMessage({ id: "INPUTED_HOURS" })}
          className="mb-2"
          project={c}
        />
        <EstimatedMonthsBar
          title={intl.formatMessage({ id: "DURATION" })}
          EndDate={EndDate}
          BeginDate={BeginDate}
        /> */}
      {/* </div> */}
      {/* <ConnectedBreadcrumbs className="px-3 mb-2" value={{
        type: "Project",
        data: Id
      }} /> */}
      <SplitCol
        className="mb-2"
        left={
          <OriginItemGroup
            icon={faSuitcase}
            title={intl.formatMessage({ id: "ACCOUNT" })}
            className="mb-2"
          >
            <ConnectedClientOrigin client={resolvedClient}>
              <ConnectedClientProfile showId client={resolvedClient} />
            </ConnectedClientOrigin>
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faCalendarExclamation}
            title={intl.formatMessage({ id: "TYPE_SUBSCRIPTION" })}
          >
            <ConnectedSubscriptionTypeProfile
              className="fs-12 text-black"
              subscriptionType={SubscriptionType}
            />
            {/* <ConnectedAccountProfile account={Manager} /> */}
          </OriginItemGroup>
        }
      />

      <SplitCol
        className="mb-2"
        left={
          <OriginItemGroup
            icon={faUser}
            title={intl.formatMessage({ id: "CONTACT" })}
          >
            <ConnectedContactPopup contact={Contact} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUserTie}
            title={intl.formatMessage({ id: "MANAGER" })}
          >
            <ConnectedAccountPopup account={Manager} />
          </OriginItemGroup>
        }
      />

      <SplitCol
        className="mb-2"
        left={
          <OriginItemGroup
            icon={faCalendarAlt}
            title={intl.formatMessage({ id: "START_DATE" })}
          >
            <OriginDate className="fs-12 text-black" date={BeginDate} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faCalendarAlt}
            title={intl.formatMessage({ id: "END_DATE" })}
          >
            <OriginDate className="fs-12 text-black" date={EndDate} />
          </OriginItemGroup>
        }
      />

      <OriginItemGroup
        icon={faDrawCircle}
        className="mb-2"
        title={intl.formatMessage({ id: "ORIGIN" })}
      >
        <OriginOverview entity={c} />
      </OriginItemGroup>

      {hasDepartments && !(hasAccounts || hasTeams) && (
        <OriginItemGroup
          icon={faUsersClass}
          show={hasDepartments}
          title={intl.formatMessage({ id: "DEPARTMENTS" })}
        >
          <ConnectedDepartmentProfileStack departments={Departments} />
        </OriginItemGroup>
      )}

      {!hasDepartments && (hasAccounts || hasTeams) && (
        <OriginItemGroup
          icon={faUsersClass}
          show={hasAccounts || hasTeams}
          title={intl.formatMessage({ id: "TEAMS_CONTRIBUTORS" })}
        >
          <ConnectedAccountsTeamsDepartmentsProfileStack
            accounts={Collaborators.Accounts}
            teams={Collaborators.Teams}
          />
        </OriginItemGroup>
      )}

      <SplitCol
        show={hasDepartments && (hasAccounts || hasTeams)}
        left={
          <OriginItemGroup
            icon={faUsersClass}
            show={hasDepartments}
            title={intl.formatMessage({ id: "DEPARTMENTS" })}
          >
            <ConnectedDepartmentProfileStack departments={Departments} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUsersClass}
            show={hasAccounts || hasTeams}
            title={intl.formatMessage({ id: "TEAMS_CONTRIBUTORS" })}
          >
            <ConnectedAccountsTeamsDepartmentsProfileStack
              accounts={Collaborators.Accounts}
              teams={Collaborators.Teams}
            />
          </OriginItemGroup>
        }
      />
      <div className="pb-3"></div>
      {/* <div className="d-flex align-items-center justify-content-center shadow-top py-3 mt-3">
        <SubscriptionEntityButtons
          //   className="mt-3 mb-3"

          entity={c}
        />
      </div> */}
    </OriginContainer>
  );
};

export const SubscriptionOriginOptions = {
  valueProp: "Subscription",
  schema: spaceSubscriptionSchema
};

export const SubscriptionOrigin = createOriginPopup(
  SubscriptionOriginAnchor,
  SubscriptionOriginPopup,
  SubscriptionOriginOptions
);

export default SubscriptionOrigin;

export const ConnectedSubscriptionOrigin =
  ConnectSubscriptionEntity(SubscriptionOrigin);
