import React, { useCallback, useState, useMemo } from "react";
import {
  ServerAwareProvider,
  ServerAwareQueryInput,
  GridRequestProvider,
  useServerAwareState
} from "../../../Components/CGrid/ServerGrid";
import { spaceBillingSchema } from "../../../config/schema";
import {
  PageContainer,
  PageContent
} from "../../../Components/PageUtilities/PageUtilities";
import PageHeader from "../../../Components/Headers/PageHeader";
import { FormattedMessage, useIntl } from "react-intl";
import ServerAwareFilterList from "../../../Components/FilterList/ServerAwareFilterList";
import BillingGrid from "./BillingGrid";
import { useAirdeskTitle } from "../../../Hooks/MiscHooks";
import { ActionButtonOptions } from "../../../Components/ExtraActionsButton/ExtraActionButton";
import { FilterViewTypeEnum } from "../../../Components/FilterList/FilterList";
import {
  createIndirectClientFilter,
  createOrganizationFilter,
  createUserFilter
} from "../../../Helpers/FilterHelper";
import { ConnectedOrganizationProfile } from "../../../Components/Organizations/OrganizationProfile";
import { GridDateComponent } from "../../../Components/CGrid/CGrid";
import OriginOverview from "../../Origin/OriginOverview";
import FormattedTimePreview from "../../../Components/FormattedTimePreview/FormattedTimePreview";
import Modal from "../../../Components/Modal/Modal";
import { ColumnChooser } from "../../../Components/ColumnChooser/ColumnChooser";
import { ClassificationFormButton } from "../../Classification/ClassificationForm";
import { moduleTypes } from "../../../Helpers/ModulesHelper";
import { MilSecToFormatedHourMinute } from "../../../Helpers/MiscHelper";
import { useSpaceQuery } from "../../../Helpers/IOClient";
import { buildODataQuery } from "../../../Helpers/ODataHelper";
import { ConnectedClientOrigin } from "../../Origin/ClientOrigin";
// import {
//   RealTimePageProvider,
//   RealTimePageEnum
// } from "../../RealTime/RealTimePages";
import AccountsInRoomStack from "../../../Components/AccountsInRoom/AccountsInRoomStack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardCheck,
  faFileInvoice,
  faBuilding,
  faCalendarAlt,
  faSuitcase,
  faSitemap,
  faClock,
  faUserPlus
} from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import {
  faSitemap as faSitemapSolid,
  faUserPlus as faUserPlusSolid,
  faCalendarAlt as faCalendarAltSolid,
  faSuitcase as faSuitcaseSolid,
  faClipboardCheck as faClipboardCheckSolid,
  faFileInvoice as faFileInvoiceSolid,
  faClock as faClockSolid,
  faBuilding as faBuildingSolid,
  faPollPeople,
  faBullseyePointer
} from "@fortawesome/pro-solid-svg-icons";
import { useResolvedTotalsUrl } from "../../../Helpers/GridHelper";
import { SelectedArrMsgContext } from "../../../Components/UnOrReadOptions/UnreadOptions";
import { InboxTypeEnum } from "../../Reception/ReceptionHelpers";
import DeleteMultipleModalHandler from "../../../Components/CGrid/DeleteMultipleModal";
import { DefaultColumnsWidth } from "../../../Components/CGrid/GridDefinitions";
import {
  InnerColorFilterBars,
  TriggeredColoredFilterProvider,
  useTriggeredColorFilters
} from "../../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { ConnectedAccountProfile } from "../../../Components/Accounts/AccountProfile";
import { ConnectedAccountPopup } from "../../Origin/AcountPopup";
import SaveFavoriteButton from "../../AppBar/SaveFavoriteButton";
import { ClassificationSidebarLink } from "../../../Components/EntitySidebarLinks/EntitySidebarLinks";

const groupableColumns = ["Client/Name"];

const TotalEstimatedComponent = (props) => {
  const { params } = useServerAwareState();

  const sumEndpoint = useMemo(() => {
    // const pOverride = buildBoardParamOverride(
    //   params.filter,
    //   pipelineStatus,
    //   field
    // );
    // pOverride.query = params.query;
    const query = buildODataQuery(params, true);

    const sumEndpoint = `billing/totalSum${query}&SumParams=Billing.InputtedHours`;

    return sumEndpoint;
  }, [params]);

  const resolvedEndpoint = useResolvedTotalsUrl(sumEndpoint);

  const {
    loading: loadingSum,
    data: sumDict,
    error: sumError
    // refetch: refetchSum
  } = useSpaceQuery(resolvedEndpoint);

  if (loadingSum || sumError) return null;

  const sumValue = sumDict ? sumDict["Billing.InputtedHours"] : 0;

  if (sumValue) return MilSecToFormatedHourMinute(sumValue);
  else return null;
};

const BillingEntityGridButtons = ({ entity, minimal, noColors }) => {
  // const isPositiveStatus = entity.Status !== ContractStatusTypes.closed;
  const conds = useTriggeredColorFilters(entity);
  return (
    <TriggeredColoredFilterProvider conds={conds}>
      <React.Fragment>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="d-flex align-items-center"
        >
          <ClassificationFormButton
            id={entity && entity.Id}
            canEdit
            className={classnames("mr-2 FormButtonClassID", {
              "ml-1": conds?.length > 0
            })}
            // disabled={!value.Billing}
          />
          {!noColors && <InnerColorFilterBars />}
        </div>
      </React.Fragment>
    </TriggeredColoredFilterProvider>
  );
};

export const bilingColumns = [
  {
    columnChooserIcon: faBullseyePointer,
    sticky: true,
    required: true,
    sortable: false,
    orderBy: false,
    noTitle: true,
    title: "Actions",
    field: "Buttons",
    width: 50,
    minSize: 50,
    className: "",
    Cell: ({ Data: value }) => <BillingEntityGridButtons entity={value} />
  },
  {
    sticky: true,
    headerIcon: faFileInvoiceSolid,
    filterIcon: faFileInvoice,
    field: "Billing/Identifier",
    title: <FormattedMessage id={"NR_BILLING"} />,
    width: DefaultColumnsWidth.billingNumber,
    Cell: ({ Data }) => {
      return (
        <div className="d-flex align-items-center">
          {Data.AccountsInRoomView && Data.AccountsInRoomView.length > 0 && (
            <span className="mr-2">
              <AccountsInRoomStack
                dontShowLine
                accountsInRoom={Data.AccountsInRoomView}
              />
            </span>
          )}
          <div className="f-12">{Data.Identifier || "―"}</div>
        </div>
      );
    }
  },
  {
    headerIcon: faClipboardCheckSolid,
    filterIcon: faClipboardCheck,
    field: "Billing/Id",
    title: <FormattedMessage id={"CLASSIFICATION_NR"} />,
    width: 180,
    Cell: ({ Data }) => {
      return (
        (
          <ClassificationSidebarLink className="fs-12" id={Data.Id}>
            {Data.Id}
          </ClassificationSidebarLink>
        ) || "―"
      );
    }
  },

  // {
  //   headerIcon: faPollPeople,
  //   width: DefaultColumnsWidth.AssignedGroup,
  //   orderBy: false,
  //   sortable: false,
  //   minSize: 145,
  //   field: "AccountsInRoom",
  //   title: <FormattedMessage id={"WORKING_IN"} />,
  //   Cell: ({ Data }) => {
  //     return <AccountsInRoomStack accountsInRoom={Data.AccountsInRoom} />;
  //   }
  // },
  {
    headerIcon: faBuildingSolid,
    filterIcon: faBuilding,
    field: "Company/Name",
    title: <FormattedMessage id={"COMPANY"} />,
    width: DefaultColumnsWidth.Company,
    Cell: ({ Data }) =>
      Data.Organization ? (
        <ConnectedOrganizationProfile organization={Data.Organization} />
      ) : (
        <div>―</div>
      )
  },
  {
    headerIcon: faCalendarAltSolid,
    filterIcon: faCalendarAlt,
    width: DefaultColumnsWidth.relativeTime,
    field: "Billing/Date",
    title: <FormattedMessage id={"DATE"} />,
    Cell: ({ Data }) =>
      Data.CreationDate ? (
        <GridDateComponent date={new Date(Data.CreationDate)} />
      ) : (
        <div>―</div>
      )
  },
  {
    headerIcon: faSuitcaseSolid,
    filterIcon: faSuitcase,
    width: DefaultColumnsWidth.client,
    field: "Client/Name",
    title: <FormattedMessage id={"ACCOUNT"} />,
    Cell: ({ Data }) =>
      Data.Client || Data.IndirectClient ? (
        <ConnectedClientOrigin client={Data.Client || Data.IndirectClient} />
      ) : (
        <div>―</div>
      )
  },
  {
    headerIcon: faSitemapSolid,
    filterIcon: faSitemap,
    required: true,
    width: DefaultColumnsWidth.Origem,
    orderBy: false,
    field: "Origin",
    title: <FormattedMessage id={"CLASSIFICATION_ORIGIN"} />,
    sortable: false,
    Cell: ({ Data }) => <OriginOverview entity={Data} />
  },
  {
    headerIcon: faClockSolid,
    filterIcon: faClock,
    width: 180,
    field: "Billing/InputtedHours",
    title: <FormattedMessage id={"INPUTED_HOURS"} />,
    Cell: ({ Data }) => {
      return Data.InputtedHours ? (
        <FormattedTimePreview value={Data.InputtedHours} asHourMinute />
      ) : (
        "―"
      );
    },
    TotalCell: TotalEstimatedComponent
    // Cell: ({ Data }) => MilSecToHour(Data.EstimatedCost)
  },
  {
    headerIcon: faUserPlusSolid,
    filterIcon: faUserPlus,
    width: DefaultColumnsWidth.Company,
    field: "Creator/Name",
    title: <FormattedMessage id={"CREATED_BY"} />,
    Cell: ({ Data }) => <ConnectedAccountPopup account={Data.Creator} />
  }
];

const filterColumns = [
  createIndirectClientFilter({
    field: "DirectIndirectClient/Id"
  }),
  createOrganizationFilter({ field: "Company/Id", propField: "Organization" }),
  createUserFilter({
    propField: "Creator",
    titleId: "CREATED_BY",
    field: "Creator/Id",
    DescriptionComponent: ({ item, value }) => {
      return (
        <div>
          <FormattedMessage id={"CREATED_BY"} />{" "}
          <ConnectedAccountProfile account={value} />
        </div>
      );
    }
  }),
  {
    propField: "Id",
    icon: <FontAwesomeIcon icon={faClipboardCheck} />,
    titleId: "CLASSIFICATION_NR",
    field: "Billing/Id",
    type: "number"
  },
  {
    propField: "Identifier",
    icon: <FontAwesomeIcon icon={faFileInvoice} />,
    titleId: "NR_BILLING",
    field: "Billing/Identifier",
    type: "string"
  }
  // createOriginFilter({
  // 	propField: "Billing",
  // 	propFields: originPropFields,
  // 	titleId: "CLASSIFICATION_ORIGIN",
  // 	baseFieldName: "Billing",
  // 	clientName: "ClientIdBySpace",
  // 	uniqueUrlClientName: "ClientIdBySpace",
  // 	uniqueUrl: true,
  // 	dropdownType: ClassificationTargetDropdown
  // }),
  // createOriginFilter({
  //   baseFieldName: "Billing",
  //   dropdownType: ClassificationTargetDropdown
  // })
];

const Billing = () => {
  const intl = useIntl();
  useAirdeskTitle(intl.formatMessage({ id: "BILLING" }));

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemSelection = useCallback((items) => {
    setSelectedItems(items);
  }, []);

  const columnResize = true;

  const localStorageColumns = JSON.parse(
    window.localStorage.getItem("ar-grid-column-layout")
  )
    ? window.localStorage.getItem("ar-grid-column-layout")["billing"]
    : undefined;

  // const groupableColumns = ["Organization/Name", "DealClient/Name"];
  const ordenableColums = [];
  if (localStorageColumns) {
    bilingColumns.forEach((e) => {
      if (
        localStorageColumns.findIndex(e.field) !== -1 &&
        e.sortable !== false
      ) {
        ordenableColums.push(e.field);
      }
    });
  } else {
    bilingColumns.forEach((e) => {
      if (e.sortable !== false) {
        ordenableColums.push(e.field);
      }
    });
  }
  // const groupableColumns = ["Organization/Name", "DealClient/Name"];

  const SelectedResolvedArr = useMemo(() => {
    return selectedItems.map((t) => {
      return { type: InboxTypeEnum.Billing, id: t };
    });
  }, [selectedItems]);

  return (
    // <RealTimePageProvider pageType={RealTimePageEnum.Billing}>
    <SelectedArrMsgContext.Provider value={SelectedResolvedArr}>
      <PageContainer>
        <ServerAwareProvider
          columns={bilingColumns}
          filterColumns={filterColumns}
          moduleType={moduleTypes.billing}
          schema={spaceBillingSchema}
        >
          <DeleteMultipleModalHandler handleItemSelection={setSelectedItems}>
            <PageHeader
              toggleFilterList
              schema={spaceBillingSchema}
              title={<FormattedMessage id="BILLING" />}
              unreadOptions={{
                Text: true,
                IsService: true,
                EnableDeleteAll: true
              }}
            >
              <Modal
                // containerClassName=""
                isOpen={modalIsOpen}
                onClose={closeModal}
              >
                <ColumnChooser
                  grid={"billing"}
                  Name="billing"
                  schemaView={spaceBillingSchema}
                  Columns={bilingColumns}
                  onClose={closeModal}
                  setIsOpen={setModalIsOpen}
                  isOpen={modalIsOpen}
                />
              </Modal>

              <div className="d-flex ml-2 justify-content-start flex-1 align-items-center">
                <ServerAwareQueryInput
                  placeholder={intl.formatMessage({ id: "SEARCH" })}
                />
              </div>
              <div className="d-flex">
                <SaveFavoriteButton />
                <ActionButtonOptions
                  moduleType={moduleTypes.billing}
                  enableColumnEdit={() => {
                    setModalIsOpen(true);
                  }}
                  columnResize={columnResize}
                  spaceType={spaceBillingSchema.name}
                  type="Billing"
                />
              </div>
            </PageHeader>
            <PageContent>
              <ServerAwareFilterList
                groupableColumns={groupableColumns}
                filterType={FilterViewTypeEnum.Billing}
              />
              <GridRequestProvider toggleColumnChooser={setModalIsOpen}>
                <BillingGrid
                  columnResize={columnResize}
                  className="flex-1"
                  onItemSelect={handleItemSelection}
                  selectedItems={selectedItems}
                />
              </GridRequestProvider>
            </PageContent>
          </DeleteMultipleModalHandler>
        </ServerAwareProvider>
      </PageContainer>
    </SelectedArrMsgContext.Provider>
    // </RealTimePageProvider>
  );
};

export default Billing;
