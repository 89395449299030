import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SocialPostButton } from "../Form/FormButtons";

import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import { LabelTitle } from "../../Helpers/GridHelper";

import facebookSVG from "./svgs/facebook-icon.svg";
import twitterSVG from "./svgs/twitter-3.svg";
import linkedinSVG from "./svgs/linkedin-icon-2.svg";
import instagramSVG from "./svgs/instagram-2-1.svg";
import DropdownInput from "../../Components/Dropdown/DropdownInput";
import classnames from "classnames";
import { useSocialAccount } from "../../Hooks/EntityHooks";
import { SocialPostFormButton } from "./SocialPostsOverview/SocialPostsOverview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlink } from "@fortawesome/pro-light-svg-icons";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";
import {
  SocialPostsCanceledStatusDot,
  SocialPostsDeletedStatusDot,
  SocialPostsDraftStatusDot,
  SocialPostsFailedStatusDot,
  SocialPostsPublishedStatusDot,
  SocialPostsPublishingStatusDot,
  SocialPostsScheduledStatusDot
} from "../../Components/StatusHelper/StatusHelper";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";
import { ColorFilterBars } from "../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
export const convertSocialPostEntityToForm = (entity, props) => {
  const { Social, Files, Schedule, IsSchedule, Thumbnail, FirstLink, ...rest } =
    entity;
  //;
  return {
    ...rest,

    SocialId: [Social],
    ScheduleComponent: {
      Time: new Date(Schedule),
      PublishNow: !IsSchedule
    },
    FilesUnsorted: Files,
    Thumbnail: {
      ...Thumbnail,
      MetadataThumbnail: FirstLink ? true : false,
      MetadataUrl: FirstLink
    }
  };
};

export const SocialPostsStatus = {
  Open: 1,
  Cancelled: 2,
  Published: 3,
  Draft: 4,
  Deleted: 5,
  Publishing: 6
};

export const defaultSocialPostForm = () => {
  return {
    SocialId: null,
    Creator: null,
    FilesUnsorted: [],

    Text: "",
    Status: 1,
    ScheduleComponent: {
      Time: new Date(),
      PublishNow: false
    },
    IsSchedule: true
  };
};

export const validateSocialPostForm = (
  { SocialId, Text, Schedule, IsSchedule },
  addError
) => {
  if (!SocialId) addError("SocialId");
  if (!Text) addError("Text");
  if (!Schedule) addError("Schedule");
  if (!IsSchedule) addError("IsSchedule");
};

export const SocialPostsEntityButtons = ({
  entity,
  skeleton,
  minimal,
  onSuccess
}) => {
  return (
    <React.Fragment>
      {/* <DealFormButton
        minimal={minimal}
        id={entity.Id}
        canEdit={entity.CanEdit}
        skeleton={skeleton}
        className="mr-2"
      />

      <ContractFormButton
        alt
        skeleton={skeleton}
        className="mr-2"
        formProps={{ dealId: entity.Id }}
      />
      <CallFormButton
        alt
        skeleton={skeleton}
        className="mr-2"
        formProps={{ dealId: entity.Id }}
      />
      <ProjectFormButton
        alt
        skeleton={skeleton}
        className="mr-2"
        formProps={{ dealId: entity.Id }}
      />
      <TicketFormButton
        alt
        skeleton={skeleton}
        className="mr-2"
        formProps={{ dealId: entity.Id }}
      />
      <TaskFormButton
        alt
        skeleton={skeleton}
        className="mr-2"
        formProps={{ dealId: entity.Id }}
      />
      <InterventionFormButton
        alt
        skeleton={skeleton}
        className="mr-2"
        formProps={{ dealId: entity.Id }}
      /> */}
    </React.Fragment>
  );
};

export const SocialPostsEntityGridChildren = ({
  entity,
  skeleton,
  minimal,
  hideBreadCrumbs
}) => {
  return (
    <>
      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      {entity.CanEdit && (
        <SocialPostButton
          id={entity.Id}
          canEdit={entity.CanEdit}
          contextIcons
        />
      )}
      <SocialPostButton id={entity.Id} duplicate />
    </>
  );
};

export const SocialPostsEntityGridButtons = ({
  entity,
  skeleton,
  minimal,
  hideBreadCrumbs
}) => {
  // const isPositiveStatus = entity.Status === DealStatusTypes.open;
  // const accountSpace = useCurrentAccountSpace();

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
			className="d-flex align-items-center"
    >
      <SocialPostFormButton
        minimal={minimal}
        id={entity.Id}
        canEdit={entity.CanEdit}
        skeleton={skeleton}
        className="mr-2 FormButtonClassID"
      />
      <EntityPopup minimal={minimal}>
        <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
        {entity.CanEdit && (
          <SocialPostButton
            id={entity.Id}
            canEdit={entity.CanEdit}
            contextIcons
          />
        )}
        <SocialPostButton id={entity.Id} duplicate />
      </EntityPopup>
      <ColorFilterBars entity={entity} />
    </div>
  );
};

// const RepublishPostNow = ({ id }) => {
//   const createToast = useToast();

//   const [post, { loading }] = useSpacePost(
//     `Social/Post/${id}/Resets`,
//     spaceSocialPostsSchema,
//     {
//       onSuccess: ({ data }) => {
//         createToast({
//           pos: "tm",
//           type: "success",
//           title: `Publicações nº ${id}`,
//           description: `Republicação programada`,
//         });
//       },
//     }
//   );

//   return (
//     <Button vType="link" className="text-left" onClick={post}>
//       <div className="mr-1 ar-form-button-link-icon">
//         <FontAwesomeIcon icon={faShareSquare} />
//       </div>
//       <span className="fw-regular fs-14">
//         {" "}
//         <FormattedMessage id={"REPUBLISH"} />
//       </span>
//     </Button>
//   );
// };

export const PostStatusTypesForFilter = {
  scheduled: "1",
  canceled: "2",
  published: "3",
  draft: "4",
  deleted: "5",
  publishing: "6",
  publishingFail: "256"
};

export const PostStatusTypes = {
  scheduled: 1,
  canceled: 2,
  published: 3,
  draft: 4,
  deleted: 5,
  publishing: 6,
  publishingFail: 256
};

export const PostStatusTypesDict = {
  1: "purple",
  2: "grey",
  3: "success",
  4: "yellow",
  5: "danger",
  6: "purple",
  256: "danger"
};

const PostStatusTranslationsDict = {
  1: "SCHEDULED",
  2: "CANCELLED",
  3: "PUBLISHED",
  4: "DRAFT",
  5: "DELETED",
  6: "PUBLISHING",
  256: "PUBLISHING_FAIL"
};

export const ClientStatusSvgIcons = (value) => {
  switch (value.toString()) {
    case PostStatusTypesForFilter.published:
      return <SocialPostsPublishedStatusDot />;

    case PostStatusTypesForFilter.scheduled:
      return <SocialPostsScheduledStatusDot />;

    case PostStatusTypesForFilter.publishing:
      return <SocialPostsPublishingStatusDot />;
    case PostStatusTypesForFilter.publishingFail:
      return <SocialPostsFailedStatusDot />;

    case PostStatusTypesForFilter.draft:
      return <SocialPostsDraftStatusDot />;

    case PostStatusTypesForFilter.deleted:
      return <SocialPostsDeletedStatusDot />;

    case PostStatusTypesForFilter.canceled:
      return <SocialPostsCanceledStatusDot />;

    default:
      return <SocialPostsFailedStatusDot />;
  }
};

export const PostStatusProfile = ({
  post,
  status,
  item,
  hideName,
  className,
  dotClassName,
  errors,
  inline,
  sensitive,
  value
}) => {
  const resovledStatus = post ? post.Status : status || item || value;
  const SvgComponent = useMemo(() => {
    if (!resovledStatus) return null;
    return ClientStatusSvgIcons(resovledStatus);
  }, [resovledStatus]);
  if (!resovledStatus && !sensitive) return null;

  const hasErrors =
    resovledStatus === 256 && Array.isArray(errors) && errors.length > 0;

  if (hasErrors) {
    return (
      <InformationComponent
        Component={
          <div
            className={classnames(
              `postsProfile link-color text-truncate status-color-${PostStatusTypesDict[resovledStatus]}`,
              className,
              { "d-flex align-items-center": !inline, "d-inline-block": inline }
            )}
          >
            <div
              className={classnames(
                "position-relative status-dot-svg",
                dotClassName
              )}
            >
              {SvgComponent}
            </div>
            {!hideName && (
              <div
                style={{ fontWeight: 400 }}
                className="status-label text-truncate flex-1 fs-12 text-truncate"
              >
                <FormattedMessage
                  id={PostStatusTranslationsDict[resovledStatus]}
                />
              </div>
            )}
          </div>
        }
        text={errors.map((e, i) => {
          return <div key={i}>{e}</div>;
        })}
      />
    );
  }

  return (
    <div
      className={classnames(
        `postsProfile link-color text-truncate status-color-${PostStatusTypesDict[resovledStatus]}`,
        className,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div
        className={classnames("position-relative status-dot-svg", dotClassName)}
      >
        {SvgComponent}
      </div>
      {!hideName && (
        <div
          style={{ fontWeight: 400 }}
          className="status-label text-truncate flex-1 fs-12 text-truncate"
        >
          <FormattedMessage id={PostStatusTranslationsDict[resovledStatus]} />
        </div>
      )}
    </div>
  );
};

export const getSocialIcon = (Type, style) => {
  let Icon = null;
  switch (Type) {
    case 2:
      Icon = <img style={style} alt="facebook" src={facebookSVG} />;
      break;
    case 3:
      Icon = <img style={style} alt="twitter" src={twitterSVG} />;
      break;
    case 4:
      Icon = <img style={style} alt="linkedin" src={linkedinSVG} />;
      break;
    case 5:
      Icon = <img style={style} alt="linkedin" src={linkedinSVG} />;
      break;
    case 6:
      Icon = <img style={style} alt="instagram" src={instagramSVG} />;
      break;
    case 7:
      Icon = <img style={style} alt="facebook" src={facebookSVG} />;
      break;
    default:
      break;
  }
  return { Icon };
};

export const PostStatusDropdown = (props) => {
  const options = useMemo(() => {
    return [
      PostStatusTypes.published,
      PostStatusTypes.draft,
      PostStatusTypes.publishing,
      PostStatusTypes.scheduled,
      PostStatusTypes.deleted,
      PostStatusTypes.publishingFail
    ];
  }, []);

  return (
    <KeyedDropdown
      options={options}
      valueComponent={PostStatusProfile}
      closeOnSelect
      {...props}
    />
  );
};

export const SocialAccountComponent = ({
  data,
  text,
  style,
  enableInput,
  isTyping,
  ...inputProps
}) => {
  const { Name, Tag, Type } = data || {};
  const { Icon } = getSocialIcon(Type, style);

  return (
    <div className="d-flex w-100 align-items-center">
      <div className="mr-2">{Icon}</div>
      <DropdownInput
        className="flat-input"
        fallbackValue={Tag || Name}
        text={text}
        enableInput={enableInput}
        isTyping={isTyping}
        {...inputProps}
      />
    </div>
  );
};

export const ConnectedSocialAccountComponentForSocialPostGrid = ({
  SocialAccount,
  ...rest
}) => {
  const Data = useSocialAccount(SocialAccount);
  return <SocialAccountComponentForSocialPostGrid data={Data} {...rest} />;
};

export const SocialAccountComponentForSocialPostGrid = ({
  style,
  data,
  containerStyle
}) => {
  const intl = useIntl();
  if (!data) return <div></div>;
  const {
    Name,
    // Tag,
    Type,
    IsBrokenLink
  } = data;
  const { Icon } = getSocialIcon(Type, style);
  return (
    <div className="d-flex align-items-center" style={containerStyle}>
      <div className="mr-2 flex-0-0-auto">{Icon}</div>
      <div className="text-black fs-12 text-truncate">{Name}</div>
      {IsBrokenLink && (
        <InformationComponent
          text={intl.formatMessage({ id: "PLEASE_RECONECT" })}
          Component={
            <div className="text-danger-alt ml-2">
              <FontAwesomeIcon icon={faUnlink} />
            </div>
          }
        />
      )}
    </div>
  );
};
