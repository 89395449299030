import React, { useMemo, useCallback, useState } from "react";
import { useFormState } from "../../Components/Forms";
import { spaceCuponSchema } from "../../config/schema";
import { useIntl, FormattedMessage } from "react-intl";
import { useToast } from "../../Components/Toast/ToastProvider";
import {
  BaseSidebarHeader,
  BaseSidebarContent,
  BaseSidebarContainer,
  useSidebarPost
} from "../../Components/Sidebar/SidebarV2Helper";
import { getEntity, useDelete } from "../../Helpers/IOClient";
import { handleError, handleErrorWithIntl } from "../../Helpers/MiscHelper";
import {
  SideForm,
  SideFormContent,
  SideFormFooter,
  SideMenuFormFooterBase
} from "../../Components/Forms/SideMenuForm";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";
import SplitCol from "../../Components/SplitCol/SplitCol";
import ArDatePicker from "../../Components/DatePicker/ArDatePicker";
import { SpacesDropdown } from "../AdvancedMultiInputs";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Switch from "../../Components/Switch/Switch";
import "./Cupons.css";
import Button from "../../Components/Button/Button";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import LoadableButton from "../../Components/Button/LoadableButton";
import moment from "moment";
const convertCuponEntityToForm = (entity) => {
  const {
    FromPlan,
    Id,
    MaxRedemptions,
    Name,
    OncePerSpace,
    PercentOff,
    AmountOff,
    RedeemBy,
    Duration,
    TargetSpaces,
    ToPlan,
    Type
  } = entity;

  return {
    Id: Id,
    Name: Name,
    CouponTypeUsage: PercentOff ? 2 : 1,
    PercentOff: PercentOff ? PercentOff : 1,
    AmountOff: AmountOff ? AmountOff : 1,
    CouponType: Type,
    Duration: Duration ? Duration : 1,
    MaxRedemptions: MaxRedemptions ? MaxRedemptions : 1,
    RedeemBySwitch: RedeemBy ? true : false,
    RedeemBy: RedeemBy ? RedeemBy : moment().add(1, "day"),
    MaxRedemptionsSwitch: MaxRedemptions ? true : false,
    OncePerSpace: OncePerSpace ? OncePerSpace : false,
    TargetSpaceIdSwitch: TargetSpaces && TargetSpaces.length > 0 ? true : false,
    TargetSpaceId: TargetSpaces ? TargetSpaces : [],
    FromToPlanSwitch: ToPlan > 0 || FromPlan > 1 ? true : false,
    FromPlan: FromPlan
      ? PlanEnumOptions.filter((e) => {
          if (FromPlan & e) return true;
          else return false;
        })
      : [],
    ToPlan: ToPlan
      ? ToPlanEnumOptions.filter((e) => {
          if (ToPlan & e) return true;
          else return false;
        })
      : []
  };
};

// const useCuponRequest = createFormRequestHook({
//   schema: spaceCuponSchema,
//   convertEntityToForm: convertCuponEntityToForm,
//   formatDuplicationEntity: (entity) => {
//     return entity;
//   },
// });

const CuponForm = (props) => {
  const { id, onSuccess, className, refetchRef } = props;

  const resolvedForm = useMemo(() => {
    if (id) {
      return {
        ...defaultCuponForm,
        ...convertCuponEntityToForm(getEntity(spaceCuponSchema, id))
      };
    } else return { ...defaultCuponForm };
  }, [id]);

  const canEdit = true;

  return (
    <CuponFormBase
      className={className}
      onSuccess={onSuccess}
      refetchRef={refetchRef}
      id={id}
      canEdit={canEdit}
      key={id}
      convertedForm={resolvedForm}
    ></CuponFormBase>
  );
};

export const StripeCouponTypeEnum = {
  Creation: 1,
  Upgrade: 2
};

export const StripeCouponTypeEnumDict = {
  1: "CREATION",
  2: "UPGRADE"
};

export const PlanEnum = {
  None: 1,
  Starter: 2,
  Basic: 4,
  Pro: 8,
  Company: 16
};

const PlanEnumOptions = [1, 2, 4, 8, 16];
const ToPlanEnumOptions = [2, 4, 8, 16];
export const PlanEnumDict = {
  1: "NONE",
  2: "PLAN_STARTER",
  4: "PLAN_BASIC",
  8: "PLAN_PRO",
  16: "PLAN_COMPANY"
};

const defaultCuponForm = {
  Name: "",
  AmountOff: 1,
  PercentOff: 1,
  Duration: 0,
  MaxRedemptions: 1,
  RedeemBy: moment().add(1, "day"),
  CouponType: 1,
  CouponTypeUsage: 2,
  FromPlan: "",
  ToPlan: "",
  OncePerSpace: false,
  TargetSpaceId: "",
  RedeemBySwitch: false,
  FromToPlanSwitch: false,
  MaxRedemptionsSwitch: false
};

export const CouponDurationEnum = {
  Once: 1,
  Repeating: 2,
  Forever: 3
};

export const CouponDurationEnumGrid = {
  once: 1,
  repeating: 2,
  forever: 3
};

export const CouponDurationEnumDict = {
  1: "ONCE",
  2: "REPEATING",
  3: "FOREVER"
};

export const CouponTypeUsageEnumDict = {
  2: "PERCENTAGE",
  1: "AMOUNT"
};

export const CouponTypeUsageEnum = {
  Percentage: 2,
  Amount: 1
};

const DiscountTypeDropdownComponent = ({ item }) => {
  return <FormattedMessage id={CouponTypeUsageEnumDict[item]} />;
};

const DurationTypeDropdownComponent = ({ item }) => {
  return <FormattedMessage id={CouponDurationEnumDict[item]} />;
};

const validateCuponForm = (
  {
    Name,
    Type,
    CouponType,
    FromPlan = [],
    ToPlan,
    FromToPlanSwitch,
    TargetSpaceIdSwitch,
    TargetSpaceId
  },
  addError
) => {
  if (!Name) addError("Name");
  if (!CouponType) addError("CouponType");
  if (FromToPlanSwitch && (!FromPlan || FromPlan.length === 0))
    addError("FromPlan");
  if (FromToPlanSwitch && (!ToPlan || ToPlan.length === 0)) addError("ToPlan");
  if (
    FromToPlanSwitch &&
    ToPlan.length === 1 &&
    FromPlan.findIndex((e) => ToPlan[0] === e) !== -1
  ) {
    addError("ToPlan");
  }
  if (
    FromToPlanSwitch &&
    FromPlan.length === 1 &&
    ToPlan.findIndex((e) => FromPlan[0] === e) !== -1
  ) {
    addError("FromPlan");
  }
  if (TargetSpaceIdSwitch && !TargetSpaceId) addError("TargetSpaceId ");
};

const formatCuponForm = (form) => {
  const {
    AmountOff,
    PercentOff,
    Name,
    CouponTypeUsage,
    CouponType,
    Duration,
    RedeemBy,
    RedeemBySwitch,
    FromToPlanSwitch,
    MaxRedemptionsSwitch,
    TargetSpaceIdSwitch,
    MaxRedemptions,
    TargetSpaceId,
    ToPlan,
    FromPlan,
    OncePerSpace
  } = form;

  let FromPlanSum = 0;
  let ToPlanSum = 0;

  if (FromToPlanSwitch) {
    for (const e of FromPlan) {
      FromPlanSum += e;
    }
    for (const e of ToPlan) {
      ToPlanSum += e;
    }
  }

  return JSON.stringify({
    Name: Name,
    AmountOff:
      CouponTypeUsage === CouponTypeUsageEnum.Amount
        ? parseFloat(AmountOff)
        : undefined,
    PercentOff:
      CouponTypeUsage === CouponTypeUsageEnum.Percentage
        ? parseFloat(PercentOff)
        : undefined,
    Type: CouponType,
    Duration:
      CouponType === CouponDurationEnum.Repeating ? Duration : undefined,
    RedeemBy: RedeemBySwitch ? RedeemBy : undefined,
    MaxRedemptions: MaxRedemptionsSwitch ? MaxRedemptions : undefined,
    TargetSpaceIds: TargetSpaceIdSwitch ? TargetSpaceId : undefined,
    FromPlan: FromToPlanSwitch ? FromPlanSum : undefined,
    ToPlan: FromToPlanSwitch ? ToPlanSum : undefined,
    OncePerSpace: OncePerSpace
  });
};

// const useArePlansValid = () => {
//   return useMemo((FromPlans, ToPlans) => {
//     if(!FromPlans || !ToPlans) return true;

//   }, [])
// }

const CuponFormBase = ({
  convertedForm,
  id,
  canEdit,
  onSuccess,
  isChild,
  refetchRef,
  className
}) => {
  // const user = useCurrentAccount();
  const formState = useFormState(() => {
    return {
      ...convertedForm
    };
  });

  const { form, handleChange } = formState;

  const {
    Id,
    // Name,
    // AmountOff,
    // PercentOff,
    // Duration,
    // MaxRedemptions,
    // RedeemBy,
    RedeemBySwitch,
    FromToPlanSwitch,
    MaxRedemptionsSwitch,
    TargetSpaceIdSwitch,
    CouponType,
    // FromPlan,
    CouponTypeUsage
    // ToPlan,
    // TargetSpaceId,
  } = form;

  const intl = useIntl();

  const createToast = useToast();
  const [, closeSidebar] = useSidebar();
  const handleCheckChanges = useCallback(
    (v) => {
      const {
        target: { checked, name }
      } = v;
      handleChange({ [name]: checked });
    },
    [handleChange]
  );

  const handleDelete = () => {
    deleteCoupon();
  };

  const [deleteCoupon, { loading: deleteCouponLoading }] = useDelete(
    `Payment/Coupons/${Id}`,
    spaceCuponSchema,
    {
      onSuccess: () => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "COUPON" })} nº ${Id}`,
          description: intl.formatMessage({ id: "SUCCESS_DELETED" })
        });
        if (refetchRef.current) {
          refetchRef.current();
        }
        closeSidebar();
      },
      onError: (error) => {
        handleError(createToast, error);
      }
    }
  );

  const [post, { loading }] = useSidebarPost(
    Id ? `Payment/Coupons/${Id}` : "Payment/Coupons",
    spaceCuponSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "COUPON" })} nº ${newId || Id}`,
          description: `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`
        });
        if (refetchRef.current) {
          refetchRef.current();
        }
      },
      onError: ({ error }) => {
        if (error.data.Message === "1") {
          handleErrorWithIntl(createToast, "COUPON_ALREDY_EXISTS");
        } else {
          handleError(createToast, error);
        }
        // console.log(error);
      }
    }
  );

  // const handleChanges = (e) => {
  //   const { name, value } = e.target;

  //   handleChange({
  //     [name]: value,
  //   });
  // };

  const handleSubmit = () => {
    post(formatCuponForm(form));
  };

  const DiscountTypeObjects = useMemo(() => {
    const objects = [];
    for (const key in CouponTypeUsageEnum) {
      if (CouponTypeUsageEnum.hasOwnProperty(key)) {
        objects.push(CouponTypeUsageEnum[key]);
      }
    }
    return objects;
  }, []);

  const DurationTypeObjects = useMemo(() => {
    const objects = [];
    for (const key in CouponDurationEnum) {
      if (CouponDurationEnum.hasOwnProperty(key)) {
        objects.push(CouponDurationEnum[key]);
      }
    }
    return objects;
  }, []);

  const [isDeleting, setisDeleting] = useState(false);

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id="ADD_NEW_COUPON" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideForm
          canEdit={canEdit}
          formState={formState}
          onSubmit={handleSubmit}
          validate={validateCuponForm}
        >
          <SideFormContent className={className || "px-3 pt-3 pb-5"}>
            <FormInput
              name="Name"
              text={<FormattedMessage id="NAME" />}
              className="mb-3"
            />
            <div className="mb-3 text-black fs-14 fw-medium">
              <FormattedMessage id={"TYPE"} />
            </div>
            <SplitCol
              className="mb-3"
              left={
                <FormInput
                  disabled={Id}
                  name="CouponTypeUsage"
                  text={<FormattedMessage id="DISCOUNT" />}
                  inputType={KeyedDropdown}
                  closeOnSelect
                  valueComponent={DiscountTypeDropdownComponent}
                  ListComponent={DiscountTypeDropdownComponent}
                  options={DiscountTypeObjects}
                />
              }
              right={
                CouponTypeUsage === 1 ? (
                  <FormInput
                    disabled={Id}
                    name="AmountOff"
                    className="disable-input-default-arrows"
                    text={intl.formatMessage({ id: "AMOUNT" })}
                    type="number"
                    insideRightLabel={"€"}
                    min="1"
                    step="any"
                    autoComplete="off"
                  />
                ) : (
                  <FormInput
                    disabled={Id}
                    name="PercentOff"
                    className="disable-input-default-arrows"
                    text={intl.formatMessage({ id: "PERCENTAGE" })}
                    type="number"
                    insideRightLabel={"%"}
                    min="1"
                    max="100"
                    step="1"
                    autoComplete="off"
                  />
                )
              }
            />
            <SplitCol
              className="mb-4"
              left={
                <FormInput
                  disabled={Id}
                  name="CouponType"
                  text={<FormattedMessage id="DURATION" />}
                  inputType={KeyedDropdown}
                  closeOnSelect
                  valueComponent={DurationTypeDropdownComponent}
                  ListComponent={DurationTypeDropdownComponent}
                  options={DurationTypeObjects}
                />
              }
              right={
                <FormInput
                  name="Duration"
                  insideRightLabel={intl.formatMessage({ id: "MONTHS" })}
                  disabled={Id || CouponType !== CouponDurationEnum.Repeating}
                  text={intl.formatMessage({ id: "DURATION" })}
                  type="number"
                  className="disable-input-default-arrows"
                  min="1"
                  step="1"
                  autoComplete="off"
                />
              }
            />

            <SplitCol
              className="mb-3"
              left={
                <div className="text-black fs-14 fw-medium">
                  <FormattedMessage id={"VALIDITY"} />
                </div>
              }
              right={
                <div className="d-flex align-items-center justify-content-end">
                  <FormInput
                    disabled={Id}
                    name="RedeemBySwitch"
                    onChange={handleCheckChanges}
                    inputType={Switch}
                  />
                </div>
              }
            />
            {RedeemBySwitch && (
              <FormInput
                name="RedeemBy"
                disabled={Id}
                className="mb-4"
                inputType={ArDatePicker}
                disableDates={true}
                disablePastDate={new Date()}
                componentClassName="w-100"
                text={intl.formatMessage({ id: "END" })}
                autoComplete="off"
                openHourAfterDate
              />
            )}

            <hr></hr>

            <div className="mb-3 d-flex align-items-center justify-content-between">
              <div className="text-black fs-14 fw-medium">
                <FormattedMessage id={"MAX_NUMBER_OF_REDEEMS"} />
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <FormInput
                  disabled={Id}
                  name="MaxRedemptionsSwitch"
                  onChange={handleCheckChanges}
                  inputType={Switch}
                />
              </div>
            </div>

            {MaxRedemptionsSwitch && (
              <FormInput
                disabled={Id}
                name="MaxRedemptions"
                type="number"
                className="disable-input-default-arrows mb-4"
                min="1"
                step="1"
                autoComplete="off"
              />
            )}

            <hr />

            <div className="mb-3 d-flex align-items-center justify-content-between">
              <div className="text-black fs-14 fw-medium">
                <FormattedMessage id={"UNIQUE_USAGE_PER_SPACE"} />
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <FormInput
                  name="OncePerSpace"
                  onChange={handleCheckChanges}
                  inputType={Switch}
                />
              </div>
            </div>

            <hr />
            <div className="text-black fs-14 mb-3 fw-medium">
              <FormattedMessage id={"REDEEM_RULES"} />
            </div>
            <div className="mb-3 d-flex align-items-center justify-content-between">
              <div className="text-black fs-14 fw-medium">
                <FormattedMessage id={"EXCLUSIVITY"} />
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <FormInput
                  // disabled={Id}
                  name="TargetSpaceIdSwitch"
                  onChange={handleCheckChanges}
                  inputType={Switch}
                />
              </div>
            </div>

            {TargetSpaceIdSwitch && (
              <FormInput
                // disabled={Id}
                name="TargetSpaceId"
                className="mb-3"
                text={intl.formatMessage({ id: "SPACE" }) + "s"}
                inputType={SpacesDropdown}
                multiple
              />
            )}

            <hr></hr>

            <div className="mb-3 d-flex align-items-center justify-content-between">
              <div className="text-black fs-14 fw-medium">
                <FormattedMessage id={"PLAN_RULE_APPLICATION"} />
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <FormInput
                  name="FromToPlanSwitch"
                  onChange={handleCheckChanges}
                  inputType={Switch}
                />
              </div>
            </div>

            {FromToPlanSwitch && (
              <>
                <FormInput
                  name="FromPlan"
                  className="mb-3"
                  text={intl.formatMessage({ id: "FROM_PLAN" })}
                  inputType={PlansDropdownPicker}
                  removable
                  multiple
                  items={PlanEnumOptions}
                  listItemComponent={TranslatedPlan}
                  valueComponent={TranslatedPlan}
                />
                <FormInput
                  name="ToPlan"
                  className="mb-3"
                  text={intl.formatMessage({ id: "TO_PLAN" })}
                  inputType={PlansDropdownPicker}
                  removable
                  items={ToPlanEnumOptions}
                  multiple
                  listItemComponent={TranslatedPlan}
                  valueComponent={TranslatedPlan}
                />
              </>
            )}
          </SideFormContent>
          {!isDeleting ? (
            <SideFormFooter
              // handleDiffrentOnClick={handleSubmit}
              subComponent={
                Id ? (
                  <Button
                    vType="link-danger"
                    type="button"
                    onClick={() => {
                      setisDeleting(true);
                    }}
                  >
                    <FormattedMessage id="DELETE" />
                  </Button>
                ) : null
              }
              isLoading={loading}
            />
          ) : (
            <SideMenuFormFooterBase className="details-form-footer d-flex justify-content-between align-items-center shadow-top">
              <Button
                className="text-black mr-3"
                vType="link-primary"
                type="button"
                onClick={() => {
                  setisDeleting(false);
                }}
              >
                <FormattedMessage id="CANCEL" />
              </Button>
              <LoadableButton
                isLoading={deleteCouponLoading}
                type="button"
                vType="danger"
                onClick={handleDelete}
              >
                <FormattedMessage id="DELETE_COUPON" />
              </LoadableButton>
            </SideMenuFormFooterBase>
          )}
        </SideForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

const TranslatedPlan = ({ item }) => {
  return (
    <span className={`plan-dropdown-text-${PlanEnumDict[item]}`}>
      <FormattedMessage id={PlanEnumDict[item]} />
    </span>
  );
};

const PlansDropdownPicker = ({ ...rest }) => {
  return <Dropdown PlansDropdown {...rest} />;
};

export default CuponForm;
