import React, { useContext } from "react";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { Tr } from "../../Components/Table";
import { useTicket } from "../../Hooks/EntityHooks";
import { spaceTicketsSchema } from "../../config/schema";
import { ConnectedContactProfile } from "../../Components/Contacts/ContactProfile";
import {
  DashboardTable,
  DashboardTitle,
  DashboardEmptyResponse,
  ReportsDateProvider
} from "./Dashboard";
import { faTicketSimple } from "@fortawesome/pro-solid-svg-icons";
import { TicketFormButton } from "../Form/FormButtons";
import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
import { TicketEntityGridButtons } from "../Tickets/TicketsHelper";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import { TicketsLink } from "../../Helpers/HooksHelper";
import { FormattedMessage } from "react-intl";
import { useDashboardQuery } from "./DashboardQueryHelper";
import { StatusPipelineGridprofile } from "../AdvancedMultiInputs/PipelineDropdowns";

const columns = [
  {
    minWidth: 90,
    width: 90,
    Cell: (value) => <TicketEntityGridButtons entity={value} />
  },
  {
    title: "Nº",
    Cell: ({ Id }) => <TicketsLink id={Id}>{Id}</TicketsLink>
  },
  {
    title: <FormattedMessage id={"NAME"} />,
    Cell: ({ Name }) => <span className="text-black">{Name}</span>
  },
  {
    title: <FormattedMessage id={"PIPELINE_STATUS"} />,
    Cell: ({ PipelineStatus }) =>
      PipelineStatus ? (
        <StatusPipelineGridprofile status={PipelineStatus} />
      ) : (
        <div>―</div>
      )
  },
  {
    maxWidth: 200,
    title: <FormattedMessage id={"ACCOUNT"} />,
    Cell: ({ Client, IndirectClient }) => (
      <ConnectedClientOrigin client={Client || IndirectClient} />
    )
  },
  {
    title: <FormattedMessage id={"CONTACT"} />,
    Cell: ({ Contact }) =>
      Contact ? <ConnectedContactProfile contact={Contact} /> : <div>―</div>
  }
];

const TicketTr = ({ value, ...rest }) => {
  const task = useTicket(value);

  return <Tr value={task} {...rest} />;
};

const DashboardTicketsOverview = (props) => {
  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, loading, error } = useDashboardQuery(
    `dashboard/tickets?todate=${
      DataReports[0] === "Tickets" ? DataReports[1].toDateString : ""
    }&fromdate=${
      DataReports[0] === "Tickets" ? DataReports[1].fromDateString : ""
    }${
      DataReports[0] === "Tickets"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`,
    [spaceTicketsSchema],
    { cache: false }
  );

  const buildBody = () => {
    if (loading || error)
      return <LoaderSpinner size="sm text-secondary" center />;

    if (data?.length === 0)
      return (
        <DashboardEmptyResponse type="tickets">
          <FormattedMessage id={"EMPTY_CASES"} />
        </DashboardEmptyResponse>
      );

    return (
      <DashboardTable TrComponent={TicketTr} columns={columns} data={data} />
    );
  };

  return (
    <React.Fragment>
      <DashboardTitle
        text={<FormattedMessage id={"CASES"} />}
        icon={faTicketSimple}
        actionButton={<TicketFormButton vType="primary-ghost" />}
      />
      {buildBody()}
    </React.Fragment>
  );
};

export default DashboardTicketsOverview;
