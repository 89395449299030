import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Input from "../../Input/Input";
import {
  FormErrorContext,
  FormStateContext,
  FormChangeContext,
  FormCanEditContext,
  FormInputRefsContext
} from "..";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { SidebarAutomationContext } from "../../Sidebar/SidebarV2Automation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faTimes } from "@fortawesome/pro-light-svg-icons";
import styles from "./FormInput.module.css";
const isSyntheticEvent = (e) => {
  if (e && e.nativeEvent instanceof Event) return true;
  else return false;
};

const FormInpuError = ({ error, errorMessage, errors, hasError, name }) => {
  const resolvedErrorMessage = useMemo(() => {
    if (!hasError) return;
    if (errorMessage) {
      return errorMessage;
    } else if (
      (typeof error === "string" && error.length > 0) ||
      React.isValidElement(error)
    ) {
      return error;
    } else if (
      name &&
      errors &&
      errors[name] &&
      (typeof errors[name] === "string" || React.isValidElement(errors[name]))
    ) {
      return errors[name];
    }
  }, [error, errorMessage, errors, hasError, name]);

  return <div className="text-danger fs-14 mt-1">{resolvedErrorMessage}</div>;
};

const FormInputText = React.memo(function FormInputText({
  text,
  textId,
  hideText,
  optional,
  extraElement,
  hasError,
  extraClasses
}) {
  if (hideText || (!text && !textId)) return <div></div>;
  const resolvedText = text ? (
    typeof text === "string" ? (
      <span>{text}</span>
    ) : (
      text
    )
  ) : (
    <FormattedMessage id={textId} />
  );
  const optionalElem = optional ? (
    <FormattedMessage id="OPTIONAL">
      {(text) => <span className="text-grey"> - {text}</span>}
    </FormattedMessage>
  ) : null;

  const textClasses = classnames(
    "mb-1 fs-14 w-100 fw-medium disable-selection",
    {
      "text-danger": hasError,
      "text-black": !hasError
    },
    extraClasses
  );
  return (
    <div className={textClasses}>
      {resolvedText}
      {optionalElem}
      {extraElement}
    </div>
  );
});

const FormInputComp = ({ InputType, inputRef, ...rest }) => {
  const resolvedComponent = useMemo(() => {
    if (InputType) {
      return <InputType ref={inputRef} {...rest} />;
    } else return <Input autoComplete="off" ref={inputRef} {...rest} />;
  }, [InputType, inputRef, rest]);

  return <>{resolvedComponent}</>;
};

const FormInput = (props) => {
  const {
    text,
    textId,
    hideText,
    optional,
    name,
    value,
    className,
    subText,
    style,
    error,
    errorMessage,
    inputType: InputType,
    componentClassName,
    isHtmlInput,
    onChange,
    preview,
    inpuRef,
    textInputClassname,
    focusonmount,
    disableAutomation,
    ...rest
  } = props;
  const errors = useContext(FormErrorContext);

  const [htmlInputKey, setHtmlInputKey] = useState(1);

  const Automation = useContext(SidebarAutomationContext);

  let hasError = error || (name && errors && errors[name]);

  const inputClasses = classnames({
    error: hasError
  });

  const handleChange = useContext(FormChangeContext);

  const resolvedHandleChange = useMemo(() => {
    if (onChange)
      return (v) => {
        if (isSyntheticEvent(v)) return onChange(v);
        onChange({
          target: {
            name,
            value: v
          }
        });
      };
    else if (handleChange)
      return (v) => {
        if (isSyntheticEvent(v)) return handleChange(v);

        handleChange((currentValue) => {
          return {
            target: {
              name,
              value: typeof v === "function" ? v(currentValue[name]) : v
            }
          };
        });
      };
    else return undefined;
  }, [onChange, handleChange, name]);

  const form = useContext(FormStateContext);

  let resolvedValue;

  if (value !== undefined) {
    resolvedValue = value;
  } else if (form && name) {
    resolvedValue = form[name];
  } else {
    resolvedValue = value;
  }

  const canEdit = useContext(FormCanEditContext);

  const resolvedPreview = preview ? preview : !canEdit;

  const classNames = classnames(
    inputClasses,
    {
      [componentClassName]: Boolean(InputType)
    },
    textInputClassname
  );

  const { extraElement, extraClasses } = Automation || {};

  const elemRef = useRef();

  const inputsRef = useContext(FormInputRefsContext);

  useEffect(() => {
    if (!inputsRef || !name) return;

    const dict = inputsRef.current;
    dict[name] = { elem: elemRef.current, textId };

    return () => delete dict[name];
  }, [inputsRef, name, textId]);

  const isValueExpression = useMemo(() => {
    if (typeof resolvedValue === "string" && resolvedValue[0] === "=")
      return true;
  }, [resolvedValue]);

  const AutomationValueRef = useRef(resolvedValue);
  const { OnExpressionModalOpen, defaultForm } = Automation || {};
  const intl = useIntl();

  const FormInputResult = useMemo(() => {
    if (Automation && isValueExpression && !isHtmlInput) {
      return (
        <div className="d-flex align-items-center justify-content-between">
          <div
            onClick={() => {
              OnExpressionModalOpen(
                text || intl.formatMessage({ id: textId }),
                getAutomationExpression(resolvedValue),
                resolvedHandleChange
              );
            }}
            className={classnames(
              "align-items-center of-hidden flex-1 border d-flex text-black",
              styles.FormInputExpressionContainer
            )}
          >
            <span className="text-truncate">{resolvedValue?.substr(1)}</span>
          </div>
          <div
            onClick={() => {
              const defaultValue = defaultForm[name];
              resolvedHandleChange(defaultValue);
            }}
            className="text-danger-alt cursor-pointer ml-2"
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      );
    } else
      return (
        <FormInputComp
          error={hasError}
          value={resolvedValue}
          name={name}
          key={isHtmlInput ? htmlInputKey : undefined}
          onChange={resolvedHandleChange}
          className={classNames}
          preview={resolvedPreview}
          inputRef={inpuRef}
          focusonmount={focusonmount}
          InputType={InputType}
          {...rest}
        />
      );
  }, [
    Automation,
    InputType,
    OnExpressionModalOpen,
    classNames,
    defaultForm,
    focusonmount,
    hasError,
    htmlInputKey,
    inpuRef,
    intl,
    isHtmlInput,
    isValueExpression,
    name,
    resolvedHandleChange,
    resolvedPreview,
    resolvedValue,
    rest,
    text,
    textId
  ]);

  return (
    <div ref={elemRef} className={className} style={style}>
      <div className="d-flex w-100 align-items-center justify-content-between">
        <FormInputText
          hideText={hideText}
          text={text}
          textId={textId}
          optional={optional}
          hasError={hasError}
          extraClasses={extraClasses}
          extraElement={extraElement}
        />
        {Automation && !disableAutomation ? (
          <div
            type="button"
            className="text-primary cursor-pointer"
            onClick={() => {
              AutomationValueRef.current = resolvedValue;
              OnExpressionModalOpen(
                text || intl.formatMessage({ id: textId }),
                getAutomationExpression(resolvedValue),
                (t) => {
                  setHtmlInputKey((oldI) => oldI + 1);
                  if (t[0] === "=") {
                    if (isHtmlInput) resolvedHandleChange(t.substring(1));
                    else resolvedHandleChange(t);
                  } else {
                    if (isHtmlInput) resolvedHandleChange(t);
                    else resolvedHandleChange("=" + t);
                  }

                  // if (isHtmlInput) resolvedHandleChange(t);
                  // else resolvedHandleChange("=" + t);
                }
              );
            }}
          >
            <FontAwesomeIcon icon={faCogs} />
          </div>
        ) : (
          !disableAutomation && <div></div>
        )}
      </div>
      {subText && <div className="fs-12 mb-1">{subText}</div>}
      {/* {resolvedText} */}
      {FormInputResult}
      {/* {ResolvedInput} */}
      {hasError && (
        // <div className="text-danger fs-14 mt-1">{resolvedErrorMessage}</div>
        <FormInpuError
          error={error}
          errorMessage={errorMessage}
          errors={errors}
          hasError={hasError}
          name={name}
        />
      )}
    </div>
  );
};

const getAutomationExpression = (value) => {
  switch (true) {
    case value instanceof Date:
      return new Date(value).toISOString();
    case typeof value === "string":
      return value;
    case typeof value === "number":
      return value;
    case typeof value === "boolean":
      return value;
    default:
      return "";
  }
};

export default FormInput;
