import React, { useContext } from "react";
import { moduleTypes, canUserEditModule } from "../../Helpers/ModulesHelper";
import {
  faTicketSimple,
  faListCheck,
  faUserClock,
  faPhone,
  faSquareParking,
  faSuitcase,
  faFileContract,
  faUserTie,
  faHandshake,
  faCalendarExclamation,
  faShareAlt,
  faSignature
} from "@fortawesome/pro-solid-svg-icons";
import classnames from "classnames";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { useMemo } from "react";
import { AccountSpace } from "../../Interfaces/EntityInterfaces";
import styles from "./OmniButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useSidebar } from "../../Components/Sidebar/Sidebar";
import { EntityPopupHeaderTogglerContext } from "../../Components/EntityPopup/EntityPopup";
import { FormattedMessage } from "react-intl";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import { MenuCloseContext } from "../../Components/ContextMenu/ContextMenu";

interface IOmniButton {
  formType: string;
  moduleType: any;
  groupTitle: any;
  icon: any;
  titleId: string;
}

const ticketButton: IOmniButton = {
  formType: moduleTypes.tickets,
  moduleType: moduleTypes.tickets,
  groupTitle: <FormattedMessage id={"SERVICES"} />,
  icon: faTicketSimple,
  titleId: "CASE"
};

const taskButton: IOmniButton = {
  formType: moduleTypes.tasks,
  groupTitle: <FormattedMessage id={"SERVICES"} />,
  moduleType: moduleTypes.tasks,
  icon: faListCheck,
  titleId: "TASK"
};

const interventionButton: IOmniButton = {
  formType: moduleTypes.interventions,
  moduleType: moduleTypes.interventions,
  groupTitle: <FormattedMessage id={"SERVICES"} />,
  icon: faUserClock,
  titleId: "TIME"
};

const callButton: IOmniButton = {
  formType: moduleTypes.calls,
  groupTitle: <FormattedMessage id={"SERVICES"} />,
  moduleType: moduleTypes.calls,
  icon: faPhone,
  titleId: "CALL"
};

const projectButton: IOmniButton = {
  formType: moduleTypes.projects,
  moduleType: moduleTypes.projects,
  groupTitle: "Serviços",
  icon: faSquareParking,
  titleId: "PROJECT"
};

const clientButton: IOmniButton = {
  formType: moduleTypes.clients,
  moduleType: moduleTypes.clients,
  groupTitle: <FormattedMessage id={"CONTACTS"} />,
  icon: faSuitcase,
  titleId: "ACCOUNT"
};

const contractButton: IOmniButton = {
  formType: moduleTypes.contracts,
  moduleType: moduleTypes.contracts,
  groupTitle: "Comercial",
  icon: faFileContract,
  titleId: "CONTRACT"
};

const subscriptionButton: IOmniButton = {
  formType: moduleTypes.subscriptions,
  moduleType: moduleTypes.subscriptions,
  groupTitle: <FormattedMessage id={"COMERCIAL"} />,
  icon: faCalendarExclamation,
  titleId: "SUBSCRIPTION"
};

const contactButton: IOmniButton = {
  formType: moduleTypes.contacts,
  moduleType: moduleTypes.contacts,
  groupTitle: <FormattedMessage id={"CONTACTS"} />,
  icon: faUserTie,
  titleId: "CONTACT"
};

const dealButton: IOmniButton = {
  formType: moduleTypes.deals,
  moduleType: moduleTypes.deals,
  icon: faHandshake,
  groupTitle: <FormattedMessage id={"COMERCIAL"} />,
  titleId: "DEAL"
};

const socialButton: IOmniButton = {
  formType: moduleTypes.socialPost,
  moduleType: moduleTypes.socialPost,
  icon: faShareAlt,
  groupTitle: <FormattedMessage id={"MARKETING"} />,
  titleId: "SOCIAL_POST"
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const emailSignatureButton: IOmniButton = {
  formType: moduleTypes.emailSignatures,
  moduleType: moduleTypes.emailSignatures,
  icon: faSignature,
  groupTitle: <FormattedMessage id={"MARKETING"} />,
  titleId: "EMAIL_SIGNATURE"
};

const serviceGroup = [
  callButton,
  projectButton,
  ticketButton,
  taskButton,
  interventionButton
];
const contactsGroup = [clientButton, contactButton];
const commercialGroup = [dealButton, contractButton, subscriptionButton];
// const marketingGroup = [socialButton, emailSignatureButton];
const marketingGroup = [socialButton];

const groups = [serviceGroup, contactsGroup, commercialGroup, marketingGroup];

const buildGroup = (group: IOmniButton[], accountSpace: AccountSpace) => {
  const resolvedGroup = [];

  for (const button of group) {
    if (canUserEditModule(accountSpace, button.moduleType))
      resolvedGroup.push(button);
  }
  return resolvedGroup;
};

const buildGroups = (groups: IOmniButton[][], accountSpace: AccountSpace) => {
  const resolvedGroups = [];

  for (const group of groups) {
    const resolvedGroup = buildGroup(group, accountSpace);
    if (resolvedGroup.length > 0) resolvedGroups.push(resolvedGroup);
  }
  return resolvedGroups;
};

const OmniButton = ({ button, date }: { button: IOmniButton; date: any }) => {
  const { icon, formType, titleId } = button;
  const openSidebar = useEntitySummarySidebar();

  const toggleOpen = useContext(EntityPopupHeaderTogglerContext) as any;

  const closeContext = useContext(MenuCloseContext) as any;

  const handleClick = () => {
    toggleOpen && toggleOpen();
    closeContext && closeContext();
    openSidebar({
      type: formType,
      props: {
        defaultForm: date
          ? {
              BeginDate: date,
              ReceivedDate: date
            }
          : undefined
      }
      // title: <FormattedMessage id={titleId} />,
      // push: true
    });
  };

  return (
    <div
      onClick={handleClick}
      className={classnames(
        "p-1 d-flex align-items-center",
        styles.favoriteCreator_singleview
      )}
    >
      <div
        className={classnames(styles.favoriteCreator_singleview_icon_container)}
      >
        <FontAwesomeIcon icon={icon} className="text-white" />
      </div>
      <div className="breakWord d-inline text-black text-capitalize text-truncate">
        <FormattedMessage id={titleId} />
      </div>
    </div>
  );

  // return (
  //   <Button
  //     onClick={handleClick}
  //     vType="link"
  //     className="text-left border-0 text-truncate"
  //   >
  //     <div className="mr-1 ar-form-button-link-icon">
  //       <FontAwesomeIcon icon={icon} />
  //     </div>
  //     <FormattedMessage id={titleId} />
  //     {/* <span className="fw-regular fs-14">click</span> */}
  //   </Button>
  // );
};

const OmniButtonGroup = ({
  group,
  hideSeperator,
  date
}: {
  group: any;
  hideSeperator: boolean;
  date: any;
}) => {
  return (
    <>
      <div
        className={classnames(
          styles["label-title"],
          "px-0 text-black fw-medium mx-0 mb-2"
        )}
      >
        {group[0].groupTitle}
      </div>
      <div className={styles.favoriteCreatorSingleViewContainer}>
        {group.map((g: IOmniButton, i: any) => {
          return <OmniButton date={date} key={i} button={g} />;
        })}
      </div>
      {/* {!hideSeperator && <hr className="my-2" />} */}
    </>
  );
};

export const OmniButtonBase = ({ date }: any) => {
  const userAccountSpace = useCurrentAccountSpace() as AccountSpace;

  const resolvedGroups = useMemo(() => {
    return buildGroups(groups, userAccountSpace);
  }, [userAccountSpace]);

  return (
    <>
      <div style={{boxShadow: "none"}} className="ar-entity-summary-header d-flex align-items-center justify-content-between text-black fw-medium fs-18">
        <FormattedMessage id={"ADD"} />
      </div>
      {resolvedGroups.map((group, i) => {
        return (
          <OmniButtonGroup
            key={i}
            group={group}
            date={date}
            hideSeperator={i + 1 === resolvedGroups.length}
          />
        );
      })}
    </>
  );
};
