import React, { useMemo } from "react";
import { useProject, useProjectCategory } from "../../Hooks/EntityHooks";
import classnames from "classnames";
import DropdownInput from "../Dropdown/DropdownInput";
import { FormattedMessage } from "react-intl";
import {
  CanceledStatusDot,
  ClosedStatusDot,
  DraftStatusDot,
  OpenStatusDot
} from "../StatusHelper/StatusHelper";
import { AccountProfileImg } from "../Img/Img";
import { ProjectStatusTypes } from "../../Containers/Projects/ProjectsStatusHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareParking } from "@fortawesome/pro-solid-svg-icons";

export const buildProjectStatusType = (status, sensitive = false) => {
  const s = status ? status.toString() : status;
  switch (s) {
    case "2":
      return "danger";
    case "5":
      return "orange";

    case "3":
      return "grey";

    case "4":
      return "success";

    case "1":
      return "purple";

    default:
      return sensitive ? "outline-grey" : "success";
  }
};

export const ProjectStatusProfileWithIcon = ({
  project,
  Icon,
  status,
  item,
  hideName,
  className,
  dotClassName,
  inline,
  sensitive,
  pipelineName,
  value,
  size = 24
}) => {
  const resovledStatus = project ? project.Status : status || item || value;

  if (!resovledStatus && !sensitive) return null;

  return (
    <div
      className={classnames(
        `link-color status-color-${buildProjectStatusType(
          resovledStatus,
          sensitive
        )}`,
        className,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div
        style={{ width: size, height: size }}
        className={`position-relative d-flex align-items-center justify-content-center text-truncate status-dot m-0 status-dot-background-template`}
      >
        {Icon || <FontAwesomeIcon icon={faSquareParking} />}
      </div>

      {!hideName && (
        <div className="status-label text-black fw-medium fs-12">
          {pipelineName || statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

const ProjectStatusSvgIcons = (status, sensitive = false) => {
  const s = status ? status.toString() : status;
  switch (s) {
    case "3":
      return <CanceledStatusDot />;

    case "4":
      return <ClosedStatusDot />;

    case "5":
      return <DraftStatusDot />;

    case "1":
      return <OpenStatusDot />;

    default:
      return sensitive ? <CanceledStatusDot /> : <OpenStatusDot />;
  }
};

export const ProjectStatusProfile = ({
  project,
  status,
  item,
  hideName,
  className,
  BuildStrongerStatusType,
  dotClassName,
  inline,
  sensitive,
  pipelineName,
  value
}) => {
  const resovledStatus = project ? project.Status : status || item || value;

  const SvgComponent = useMemo(() => {
    if (!resovledStatus && !sensitive) return null;
    return ProjectStatusSvgIcons(resovledStatus, sensitive);
  }, [resovledStatus, sensitive]);

  if (!resovledStatus && !sensitive) return null;

  const resolvedStatusType = BuildStrongerStatusType
    ? BuildStrongerStatusType(resovledStatus)
    : buildProjectStatusType(resovledStatus, sensitive);

  return (
    <div
      className={classnames(
        `d-flex align-items-center flex-0-0-auto link-color text-truncate status-color-${resolvedStatusType}`,
        className
      )}
    >
      <div
        className={classnames("position-relative status-dot-svg", dotClassName)}
      >
        {SvgComponent}
      </div>
      {!hideName && (
        <div className="status-label flex-1 text-truncate fs-12">
          {pipelineName || statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

// Open = 1,
// Pending = 2,
// Cancelled = 3,
// Closed = 4

const statusNameByValue = (status) => {
  switch (status.toString()) {
    case "1":
      return <FormattedMessage id={"ACTIVE"} />;
    // case 2:
    //   return "Pendente";
    case "3":
      return <FormattedMessage id={"CANCELLED"} />;
    case "4":
      return <FormattedMessage id={"CONCLUDED"} />;
    case "5":
      return <FormattedMessage id={"REVIEW"} />;
    default:
      return null;
  }
};

export const ConnectProjectEntity = (Component) => {
  return ({ project, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const p = useProject(project);
    return <Component project={p} {...rest} />;
  };
};

export const ConnectedProjectCategoryItem = ({ type, ...rest }) => {
  const c = useProjectCategory(type);
  return <ProjectCategoryItem type={c} {...rest} />;
};

const ProjectCategoryItem = ({
  type,
  enableInput,
  isTyping,
  dispatch,
  grid,
  text,
  ...inputProps
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100">
        <div className="d-flex flex-column text-truncate flex-1">
          <div
            className={`dropdown-item-maintext d-flex ${grid ? "fs-12" : ""}`}
          >
            {/* {!isTyping && pipeline && `${pipeline.Id} - `} */}
            {!enableInput && type ? (
              <span className="text-truncate">{type.Name}</span>
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={type && type.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const DifrentProjectProfile = ({ project }) => {
  if (!project) return null;
  const { Status, Image } = project;

  if (Image)
    return (
      <AccountProfileImg
        size={"mdx"}
        account={project}
        circular
        className={classnames("flex-shrink-0")}
      />
    );

  let colorStatus;

  switch (Status) {
    case ProjectStatusTypes.open:
      colorStatus = "status-in-progress-colors";
      break;
    case ProjectStatusTypes.canceled:
      colorStatus = "status-canceled-colors";
      break;
    case ProjectStatusTypes.finished:
      colorStatus = "status-closed-colors";
      break;
    case ProjectStatusTypes.draft:
      colorStatus = "status-orange-colors";
      break;
    default:
      break;
  }

  return (
    <div className={classnames("air-new-profiles-circle", colorStatus)}>
      <FontAwesomeIcon icon={faSquareParking} size="xl" />
    </div>
  );
};
