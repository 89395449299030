import React from "react";

import Moment from "react-moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faParkingCircle,
  faSuitcase,
  faPhone,
  faFileSpreadsheet,
  faUserClock,
  faFileContract,
  faTicketSimple,
  faListCheck,
  faFileChartPie,
  faClipboardCheck,
  faUserTie,
  faFileInvoice,
  faCalendarExclamation,
  faGlobe,
  faMessagesDollar,
  faEnvelope
} from "@fortawesome/pro-light-svg-icons";

import querystring from "query-string";
import moment from "moment";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import { useAccountExport } from "../../../Hooks/EntityHooks";
import { createPdfButton } from "../../../Components/Pdf/PdfHelper";
import { useExcelQuery } from "../../../Components/Excel/ExcelHelper";
import Button from "../../../Components/Button/Button";
import { useGridRequest } from "../../../Components/CGrid/ServerGrid";
import RelativeTime from "../../../Components/RelativeTime/RelativeTime";
import EmptyListError from "../../../Components/EmptyListContainer/EmptyListError";
import EmptyListContainer from "../../../Components/EmptyListContainer/EmptyListContainer";
import VirtualServerAwareGrid from "../../../Components/VirtualGrid/VirtualServerAwareGrid";
import { VirtualGridRow } from "../../../Components/VirtualGrid/VirtualGrid";
import { moduleTypes } from "../../../Helpers/ModulesHelper";
import {
  faCalendar,
  faFile,
  faFileExport,
  faHashtag,
  faWindow
} from "@fortawesome/pro-solid-svg-icons";
const OrganizationRow = React.memo(({ value, ...rest }) => {
  const v = useAccountExport(value);
  return <VirtualGridRow value={v} {...rest} />;
});

const IconText = ({ routeModule, textOveride }) => {
  const { text, icon } = routeModule;
  return (
    <div className="d-flex">
      {icon && (
        <div className="ar-header-link-icon-grid mr-2">
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      <span className="text-truncate">{textOveride ? textOveride : text}</span>
    </div>
  );
};

// const ExportHistoryViewTypeEnum = {
//   1: "Client",
//   2: "Project",
//   3: "ClientContract",
//   4: "TimeCharge",
//   5: "Report",
//   6: "Call",
//   7: "Contact",
//   8: "Ticket",
//   9: "ProjectTask",
//   10: "Deal",
//   11: "Classification",
//   12: "Billing",
// 	13: "Subscripions"
// };

export const ExportStatusDict = {
  Success: 1,
  Failed: 2,
  Pending: 0
};

export const ExportHistoryViewTypeEnumDictForTranslation = {
  1: "ACCOUNTS",
  2: "PROJECTS",
  3: "CONTRACTS",
  4: "TIMES",
  5: "CLASSIFICATION_REPORT",
  6: "CALLS",
  7: "CONTACTS",
  8: "CASES",
  9: "TASKS",
  10: "DEALS",
  11: "CLASSIFICATION",
  12: "BILLING",
  13: "SUBSCRIPTIONS",
  14: "SPACES",
  15: "EMAIL",
  16: "PRODUCTIVITY",
  17: "TRAVEL_TIMES"
};

export const ExportModulesViewTypeEnum = {
  1: moduleTypes.clients,
  2: moduleTypes.projects,
  3: moduleTypes.contracts,
  4: moduleTypes.interventions,
  5: moduleTypes.reportsClassification,
  6: moduleTypes.calls,
  7: moduleTypes.contacts,
  8: moduleTypes.tickets,
  9: moduleTypes.projects,
  10: moduleTypes.deals,
  11: moduleTypes.classification,
  12: moduleTypes.billing,
  13: moduleTypes.subscriptions,
  14: moduleTypes.spaceList,
  15: moduleTypes.emailConversations,
  16: moduleTypes.classification,
  17: moduleTypes.classification,
  18: moduleTypes.emailConversations
};

export const ExportHistoryViewTypeEnumDict = {
  1: (
    <IconText
      routeModule={{
        icon: faSuitcase,
        text: <FormattedMessage id="ACCOUNTS" />
      }}
    />
  ),
  2: (
    <IconText
      routeModule={{
        icon: faParkingCircle,
        text: <FormattedMessage id="PROJECTS" />
      }}
    />
  ),
  3: (
    <IconText
      routeModule={{
        icon: faFileContract,
        text: <FormattedMessage id="CONTRACTS" />
      }}
    />
  ),
  4: (
    <IconText
      routeModule={{ icon: faUserClock, text: <FormattedMessage id="TIMES" /> }}
    />
  ),
  5: (
    <IconText
      routeModule={{
        icon: faFileSpreadsheet,
        text: <FormattedMessage id="TIMES" />
      }}
      textOveride={<FormattedMessage id="CLASSIFICATION_REPORT" />}
    />
  ),
  6: (
    <IconText
      routeModule={{ icon: faPhone, text: <FormattedMessage id="CALLS" /> }}
    />
  ),
  7: (
    <IconText
      routeModule={{
        icon: faUserTie,
        text: <FormattedMessage id="CONTACTS" />
      }}
    />
  ),
  8: (
    <IconText
      routeModule={{
        icon: faTicketSimple,
        text: <FormattedMessage id="CASES" />
      }}
    />
  ),
  9: (
    <IconText
      routeModule={{
        icon: faListCheck,
        text: <FormattedMessage id="TASKS" />
      }}
    />
  ),
  10: (
    <IconText
      routeModule={{
        icon: faMessagesDollar,
        text: <FormattedMessage id="DEALS" />
      }}
    />
  ),
  11: (
    <IconText
      routeModule={{
        icon: faClipboardCheck,
        text: <FormattedMessage id="CLASSIFICATION" />
      }}
    />
  ),
  12: (
    <IconText
      routeModule={{
        icon: faFileInvoice,
        text: <FormattedMessage id="BILLING" />
      }}
    />
  ),
  13: (
    <IconText
      routeModule={{
        icon: faCalendarExclamation,
        text: <FormattedMessage id="SUBSCRIPTIONS" />
      }}
    />
  ),
  14: (
    <IconText
      routeModule={{
        icon: faGlobe,
        text: <FormattedMessage id="SPACE" />
      }}
    />
  ),
  15: (
    <IconText
      routeModule={{
        icon: faEnvelope,
        text: <FormattedMessage id="EMAIL" />
      }}
      textOveride={<FormattedMessage id="EMAIL" />}
    />
  ),
  16: (
    <IconText
      routeModule={{
        icon: faCalendarExclamation,
        text: <FormattedMessage id="PRODUCTIVITY" />
      }}
      textOveride={<FormattedMessage id="PRODUCTIVITY_REPORT" />}
    />
  ),
  17: (
    <IconText
      routeModule={{
        icon: faFileSpreadsheet,
        text: <FormattedMessage id="TIMES" />
      }}
      textOveride={<FormattedMessage id="TRAVEL_TIMES" />}
    />
  ),
  18: (
    <IconText
      routeModule={{
        icon: faEnvelope,
        text: <FormattedMessage id="EMAIL_CONVERSATION" />
      }}
      textOveride={<FormattedMessage id="EMAIL_CONVERSATION" />}
    />
  )
};

export const ExportHistoryTypeEnumDict = {
  1: (
    <IconText
      routeModule={{
        icon: faSuitcase,
        text: <FormattedMessage id="ACCOUNTS" />
      }}
    />
  ),
  2: (
    <IconText
      routeModule={{
        icon: faParkingCircle,
        text: <FormattedMessage id="PROJECTS" />
      }}
    />
  ),
  3: (
    <IconText
      routeModule={{
        icon: faFileContract,
        text: <FormattedMessage id="CONTRACTS" />
      }}
    />
  ),
  4: (
    <IconText
      routeModule={{ icon: faUserClock, text: <FormattedMessage id="TIMES" /> }}
    />
  ),
  5: (
    <IconText
      routeModule={{
        icon: faFileSpreadsheet,
        text: <FormattedMessage id="TIMES" />
      }}
      textOveride={<FormattedMessage id="CLASSIFICATION_REPORT" />}
    />
  ),
  6: (
    <IconText
      routeModule={{ icon: faPhone, text: <FormattedMessage id="CALLS" /> }}
    />
  ),
  7: (
    <IconText
      routeModule={{
        icon: faUserTie,
        text: <FormattedMessage id="CONTACTS" />
      }}
    />
  ),
  8: (
    <IconText
      routeModule={{
        icon: faTicketSimple,
        text: <FormattedMessage id="CASES" />
      }}
    />
  ),
  9: (
    <IconText
      routeModule={{
        icon: faListCheck,
        text: <FormattedMessage id="TASKS" />
      }}
    />
  ),
  10: (
    <IconText
      routeModule={{
        icon: faMessagesDollar,
        text: <FormattedMessage id="DEALS" />
      }}
    />
  ),
  11: (
    <IconText
      routeModule={{
        icon: faClipboardCheck,
        text: <FormattedMessage id="CLASSIFICATION" />
      }}
    />
  ),
  12: (
    <IconText
      routeModule={{
        icon: faFileInvoice,
        text: <FormattedMessage id="BILLING" />
      }}
    />
  ),
  13: (
    <IconText
      routeModule={{
        icon: faCalendarExclamation,
        text: <FormattedMessage id="SUBSCRIPTIONS" />
      }}
    />
  ),
  14: (
    <IconText
      routeModule={{
        icon: faGlobe,
        text: <FormattedMessage id="SPACE" />
      }}
    />
  ),
  15: (
    <IconText
      routeModule={{
        icon: faEnvelope,
        text: <FormattedMessage id="EMAIL" />
      }}
      textOveride={<FormattedMessage id="EMAIL" />}
    />
  ),
  16: (
    <IconText
      routeModule={{
        icon: faClipboardCheck,
        text: <FormattedMessage id="PRODUCTIVITY" />
      }}
    />
  ),
  17: (
    <IconText
      routeModule={{
        icon: faFileSpreadsheet,
        text: <FormattedMessage id="TIMES" />
      }}
      textOveride={<FormattedMessage id="TRAVEL_TIMES" />}
    />
  ),
  18: (
    <IconText
      routeModule={{
        icon: faEnvelope,
        text: <FormattedMessage id="EMAIL_CONVERSATION" />
      }}
      textOveride={<FormattedMessage id="EMAIL_CONVERSATION" />}
    />
  )
};

// const ExportHistoryTypeEnumDict = {
//   1: <IconText routeModule={clientRoute} />,
//   2: <IconText routeModule={projectRoute} />,
//   3: <IconText routeModule={contractRoute} />,
//   4: <IconText routeModule={interventionRoute} />,
//   5: <IconText routeModule={reportsRoute} textOveride="Report Tempos" />,
//   6: <IconText routeModule={callRoute} />,
//   7: <IconText routeModule={contactRoute} />,
//   8: <IconText routeModule={ticketRoute} />,
//   9: <IconText routeModule={taskRoute} />,
//   10: <IconText routeModule={dealRoute} />,
//   11: <IconText routeModule={classificationRoute} />,
//   12: <IconText routeModule={billingRoute} />
// };

export const StatusDict = {
  1: (
    <div className="text-success-green">
      <FormattedMessage id={"SUCCESS"} />
    </div>
  ),
  2: (
    <div className="text-danger-alt">
      <FormattedMessage id={"EXPORT_FAILLED"} />
    </div>
  ),
  0: (
    <div className="text-Expired">
      <FormattedMessage id={"PROCESSING"} />
    </div>
  )
};

const PdfButton = createPdfButton({
  // generateUrl: ({ id }) => Data.ExportFile,
  download: true,
  // url: Data.ExportFile,
  //   buildUrl: ({ id, token }) => `interventions/Export/BuildPdf/${id}/${token}`,
  chooseDate: false
  // moduleType: moduleTypes.interventions
});

const ExcelButton = ({ directUrl, className, link, ExportType, fileId }) => {
  const post = useExcelQuery(directUrl, ExportType, fileId);

  return (
    <Button
      style={{ visibility: "visible" }}
      className={classnames(
        {
          "ssi-button-grid sm": !link,
          "ssi-button-link text-left": link
        },
        className
      )}
      vType={link ? null : "primary-ghost"}
      onClick={post}
    >
      <FontAwesomeIcon icon={faFileExcel} />
    </Button>
  );
};

export const ExportButton = ({
  url = "",
  ExportType,
  id,
  PathFile,
  EndDateInterval,
  BeginDateInterval
}) => {
  // console.log(url, ExportType)
  const resolvedUrl = /\?[\S]+=/g.test(url)
    ? "&fromSettings=true"
    : "?fromSettings=true";
  const queryObj = querystring.parse(url.split("?")[1]);
  const intl = useIntl();
  if (
    queryObj.isexcel ||
    queryObj.excel ||
    new RegExp("^.*excel?.*$").test(url.toLowerCase())
  ) {
    if (PathFile) {
      return (
        <ExcelButton
          fromSettings={true}
          fileId={id}
          directUrl={PathFile}
          ExportType={
            intl.formatMessage({
              id: ExportHistoryViewTypeEnumDictForTranslation[ExportType] || ""
            }) +
            "_" +
            moment(BeginDateInterval).format("DD-MM-YYYY") +
            "_" +
            moment(EndDateInterval).format("DD-MM-YYYY")
          }
        />
      );
    } else
      return (
        <ExcelButton
          fileId={id}
          fromSettings={true}
          directUrl={url + resolvedUrl}
          moduleTypeExtra={ExportModulesViewTypeEnum[ExportType]}
        />
      );
  } else {
    if (PathFile) {
      return (
        <PdfButton
          id={id}
          moduleTypeExtra={ExportModulesViewTypeEnum[ExportType]}
          fromSettings={true}
          buttonStyle={{ visibility: "visible" }}
          directUrl={PathFile}
        />
      );
    } else
      return (
        <PdfButton
          id={id}
          moduleTypeExtra={ExportModulesViewTypeEnum[ExportType]}
          payload={queryObj["$payload"]}
          fromSettings={true}
          buttonStyle={{ visibility: "visible" }}
          directUrl={url + resolvedUrl}
        />
      );
  }
};

const columns = [
  // {
  //   sortable: false,
  //   field: "Id",
  //   title: "Id",
  //   width: 80,
  // },
  {
    headerIcon: faFile,
    sortable: false,
    width: 150,
    field: "ExportFile",
    title: <FormattedMessage id={"DOWNLOAD"} />,
    Cell: ({ Data }) => {
      return (
        <ExportButton
          id={Data.Id}
          ExportType={Data.ExportType}
          url={Data.ExportFile}
          PathFile={Data.PathFile}
        />
      );
    }
  },
  {
    headerIcon: faCalendar,
    sortable: false,
    width: 180,
    field: "Date",
    title: <FormattedMessage id={"DATE"} />,
    Cell: ({ Data }) => <RelativeTime date={Data.Date} />
  },
  {
    headerIcon: faHashtag,
    sortable: false,
    width: 120,
    field: "ExportQuantity",
    title: <FormattedMessage id={"QUANTITY"} />,
    Cell: ({ Data }) => (
      <div className="d-flex justify-content-end align-items-center">
        {!Data.Success && Data.FullRequest ? "―" : Data.ExportQuantity}
      </div>
    )
  },
  {
    headerIcon: faFileExport,
    sortable: false,
    width: 180,
    field: "ExportType",
    title: <FormattedMessage id={"EXPORT_TYPE"} />,
    Cell: ({ Data }) => ExportHistoryTypeEnumDict[Data.ExportType] || null
  },
  {
    headerIcon: faWindow,
    sortable: false,
    width: 180,
    field: "ViewType",
    title: <FormattedMessage id={"VIEW"} />,
    Cell: ({ Data }) => ExportHistoryViewTypeEnumDict[Data.ViewType] || null
  },
  // {
  //   sortable: false,
  //   width: 78,
  //   field: "State",
  //   title: <FormattedMessage id={"STATUS"} />,
  //   Cell: ({ Data }) => StatusDict[Data.Status]
  // },

  {
    headerIcon: faCalendar,
    sortable: false,
    width: 124,
    field: "BeginDateInterval",
    title: <FormattedMessage id={"START_DATE"} />,
    Cell: ({ Data }) =>
      Data.BeginDateInterval ? (
        <Moment
          className="text-black"
          date={Data.BeginDateInterval}
          format="DD/MM/YYYY HH:mm"
        />
      ) : (
        <div>―</div>
      )
  },
  {
    headerIcon: faCalendar,
    sortable: false,
    width: 124,
    field: "EndDateInterval",
    title: <FormattedMessage id={"END_DATE"} />,
    Cell: ({ Data }) =>
      Data.EndDateInterval ? (
        <Moment
          className="text-black"
          date={Data.EndDateInterval}
          format="DD/MM/YYYY HH:mm"
        />
      ) : (
        <div>―</div>
      )
  },
  {
    headerIcon: faHashtag,
    sortable: false,
    width: 130,
    field: "QuantityType",
    title: <FormattedMessage id={"EXPORT_QUANTITY_TYPE"} />,
    Cell: ({ Data }) => {
      if (Data.FullRequest) {
        return <FormattedMessage id={"EXPORT_QUANTITY_TYPE_COMPLETE"} />;
      } else {
        return <FormattedMessage id={"EXPORT_QUANTITY_TYPE_PARCIAL"} />;
      }
    }
  }
];

const AccountExportsGrid = ({ ...rest }) => {
  const { error, results } = useGridRequest();

  if (error) return <EmptyListError />;

  if (results && results.length === 0)
    return (
      <EmptyListContainer type="interventions">
        <div className="fw-bold fs-24 text-black ">
          <FormattedMessage id={"EMPTY_EXPORT"} />
        </div>
      </EmptyListContainer>
    );
  // const columnResize = true;

  return (
    <VirtualServerAwareGrid
      disableResize
      noSelect
      columnResize={false}
      rowComponent={OrganizationRow}
      columns={columns}
      height={50}
      {...rest}
    />
  );
};

export default AccountExportsGrid;
