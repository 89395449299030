import React, { useState } from "react";
import Button from "../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/pro-light-svg-icons";
import api from "../../api";
import { useSpace } from "../../Contexts/SpaceContext";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { setAlterEgo } from "../../Helpers/AuthHelper";
import classnames from "classnames";


const UserAlterEgoButton = ({ canEdit, userId, className, minimal }) => {
  const [isLoading, setIsLoading] = useState(false);

  const space = useSpace();

  const handleClick = () => {
    setIsLoading(true);
    api
      .post(`spaces/${space.Id}/AlterEgo`, JSON.stringify(userId), {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(r => {
          setAlterEgo(r.authToken);
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  return (
    <Button
      onClick={handleClick}
      className={classnames( minimal ? "ar-minimal-button" : "ssi-button-grid sm", className)} 
      vType={"primary-ghost"}
      disabled={!canEdit || isLoading}
    >
      {isLoading ? <LoaderSpinner /> : <FontAwesomeIcon icon={faSignInAlt} />}
    </Button>
  );
};

export default UserAlterEgoButton;
