import React, { useContext, useLayoutEffect, useRef, useState } from "react";

import classnames from "classnames";
import styles from "../AutomationDetails.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSidebar } from "../../../../Components/Sidebar/SidebarV2";

import Button from "../../../../Components/Button/Button";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../AutomationDetailsHelper";
import { ElementsRefContext } from "../AutomationDetails";
import SourceHandles from "../Handles/SourceHandles";
import TargetHandles from "../Handles/TargetHandles";
import NodeBaseSidebar from "./NodeBaseSidebar";
import {
  faChevronDown,
  faPencil,
  faTrashAlt
} from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import FormInput from "../../../../Components/Forms/FormInput/FormInput";
import Popup, { usePopupOpenState } from "../../../../Components/Popup/Popup";
import { IfElseTypeEnum } from "../NodeTypes";
import AutomationTestResults from "./AutomationTestResults";
import NodeBaseContainer from "./NodeExtraOptions/NodeExtraOptions";
import NodeIconNameContainer from "./NodeIconNameContainer";

const PropertyItem = React.memo(({ item, index, handleChange, text }) => {
  const { name, value } = item;

  const onChange = (e) => {
    const { name, value } = e.target;
    handleChange((oldV) => {
      const newV = { ...oldV };
      const newItems = [...newV.properties];
      newItems[index] = {
        ...newItems[index],
        [name]: value
      };
      newV.properties = newItems;
      return newV;
    });
  };

  const removeItem = () => {
    handleChange((oldV) => {
      const newV = { ...oldV };
      const newItems = [...newV.properties];
      newItems.splice(index, 1);

      newV.properties = newItems;
      return newV;
    });
  };

  return (
    <div
      className={classnames(
        styles.AutomationPropertySetterContainer,
        "p-2 border mb-3 bg-airdesk"
      )}
    >
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <span className="text-black fs-16">{text}:</span>
        <div
          onClick={removeItem}
          className="d-flex cursor-pointer align-items-center"
        >
          <FontAwesomeIcon icon={faTrashAlt} className="text-danger-alt" />
        </div>
      </div>
      <FormInput
        className="mb-2"
        textInputClassname="bg-white"
        textId={"NAME"}
        onChange={onChange}
        name="name"
        value={name}
      />

      <FormInput
        className="mb-2"
        textInputClassname="bg-white"
        textId={"VALUE"}
        onChange={onChange}
        name="value"
        value={value}
      />
    </div>
  );
});

const BuiltItems = ({ properties, handleChange }) => {
  if (!properties || properties?.length === 0) return null;

  return (
    <div>
      {properties?.map((e, i) => {
        switch (e.type) {
          case IfElseTypeEnum.string:
            return (
              <PropertyItem
                key={i}
                item={e}
                handleChange={handleChange}
                index={i}
                text="String"
              />
            );
          case IfElseTypeEnum.boolean:
            return (
              <PropertyItem
                key={i}
                item={e}
                handleChange={handleChange}
                index={i}
                text="Boolean"
              />
            );
          case IfElseTypeEnum.number:
            return (
              <PropertyItem
                key={i}
                item={e}
                handleChange={handleChange}
                index={i}
                text="Number"
              />
            );
          // case IfElseTypeEnum.dates:
          //   return (
          //     <DateIfElseItem
          //       item={e}
          //       handleChange={handleChange}
          //       index={i}
          //     />
          //   );
          default:
            return null;
        }
      })}
    </div>
  );
};

const FormContainer = ({
  formState,
  organizations,
  pipelines,
  id,
  canEdit
}) => {
  const { form, handleChange } = formState;
  const { properties } = form;

  return (
    <div>
      <PropertySetterFormContent
        handleChange={handleChange}
        properties={properties}
      />
    </div>
  );
};

const PropertySetterFormContent = ({ handleChange, properties }) => {
  const popupRef = useRef();
  const anchorRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  const [barWidth, setBarWidth] = useState(0);
  useLayoutEffect(() => {
    setBarWidth(anchorRef.current.offsetWidth);
  }, []);

  const addStringItem = () => {
    handleChange({
      properties: [
        ...properties,
        {
          type: IfElseTypeEnum.string,
          name: "",
          value: ""
        }
      ]
    });
    toggleIsOpen();
  };
  const addBooleanItem = () => {
    handleChange({
      properties: [
        ...properties,
        {
          type: IfElseTypeEnum.boolean,
          name: "",
          value: ""
        }
      ]
    });
    toggleIsOpen();
  };
  const addNumberItem = () => {
    handleChange({
      properties: [
        ...properties,
        {
          type: IfElseTypeEnum.number,
          name: "",
          value: ""
        }
      ]
    });
    toggleIsOpen();
  };

  return (
    <>
      <BuiltItems properties={properties} handleChange={handleChange} />

      <Button
        ref={anchorRef}
        type={"button"}
        vType="primary-ghost"
        onClick={toggleIsOpen}
        className="d-flex w-100 align-items-center justify-content-center"
      >
        <FormattedMessage id="ADD_CONDITION" />
        <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
      </Button>
      <Popup
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        style={{ width: barWidth }}
        domRef={popupRef}
        anchorEl={anchorRef.current}
        isOpen={isOpen}
        placement="bottom-start"
      >
        <div className="w-100 bg-white p-2" style={{ borderRadius: 3 }}>
          <div
            onClick={addNumberItem}
            className="mb-2 p-2 text-black fs-14 cursor-pointer ar-dropdown-list-button"
          >
            <FormattedMessage id="ADD_NUMBER" />
          </div>
          <div
            onClick={addStringItem}
            className="mb-2 p-2  text-black  fs-14 cursor-pointer ar-dropdown-list-button"
          >
            <FormattedMessage id="ADD_STRING" />
          </div>
          <div
            onClick={addBooleanItem}
            className="p-2  text-black fs-14 cursor-pointer ar-dropdown-list-button"
          >
            <FormattedMessage id="ADD_BOOLEAN" />
          </div>
        </div>
      </Popup>
    </>
  );
};

// const PropertySetterFormContent = ({
//   formState,
//   organizations,
//   pipelines,
//   id,
//   canEdit
// }) => {
//   const { form, handleChange } = formState;
//   const { properties } = form;

//   const addItem = () => {
//     handleChange({
//       properties: [
//         ...properties,
//         {
//           name: "",
//           value: ""
//         }
//       ]
//     });
//   };

//   return (
//     <BaseSidebarContent>
//       <BuiltItems items={properties} handleChange={handleChange} />
//       <Button
//         type="button"
//         vType="primary-ghost"
//         onClick={addItem}
//         className="d-flex w-100 align-items-center justify-content-center"
//       >
//         <FormattedMessage id="ADD_PROPERTY" />
//         <FontAwesomeIcon icon={faPlus} className="ml-2" />
//       </Button>
//     </BaseSidebarContent>
//   );
// };

const settings = {
  defaultForm: {
    properties: [
      {
        type: IfElseTypeEnum.string,
        name: "",
        value: ""
      }
    ]
  },
  FormContent: (props) => {
    return <FormContainer {...props}></FormContainer>;
  }
};

const PropertySetterNode = React.memo(({ selected, data, id, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);
  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);
  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };
  const { enabled, config } = data;
  const intl = useIntl();
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <NodeIconNameContainer
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleClick}
          selected={selected}
          icon={faPencil}
          text={intl.formatMessage({ id: "PROPERTY_SETTER" })}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default PropertySetterNode;
