import React, { useState } from "react";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";

const PdfPreviewContent = ({ src }) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div className="h-100 of-hidden w-100">
      {loading && (
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
          <LoaderSpinner size="lg" className="m-4 text-primary" />
        </div>
      )}
      <object
        onLoad={handleLoad}
        // style={{ display: loading ? "none" : "" }}
        className="w-100 h-100"
        data={src}
        type="application/pdf"
      >
        <div></div>
      </object>
    </div>
  );
};

const PdfPreview = ({ src }) => {
  return <PdfPreviewContent key={src} src={src} />;
};

export default PdfPreview;
