import React, { Component } from "react";
import "./Radio.css";

class Radio extends Component {

  onChange = (e) => {
    
    this.props.change &&
      this.props.change({
        isChecked: e.target.checked,
        senderValue: this.props.value,
      });
  };

  render() {
    return (
      <label className="a-radio-button-container">
        <input
          type="radio"
          onChange={this.onChange}
          name={this.props.name}
          value={this.props.value}
					checked={this.props.checked}
        />
        <div
          className={`a-radio-button ${this.props.bgWhite ? "bg-white" : ""}`}
        >
          <div className="a-radio-button-checked"></div>
        </div>
        {this.props.textValue && (
          <div className="a-radio-button-text fs-14">
            {this.props.textValue}
          </div>
        )}
      </label>
    );
  }
}

export default Radio;
