import React, { useContext } from "react";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import {
  useNodeUpdater,
  AutomationTestRequestContext,
  AutomationTestClickContext
} from "../../AutomationDetailsHelper";
import { ElementsRefContext } from "../../AutomationDetails";
import NodeBaseSidebar from "../NodeBaseSidebar";
import { useIntl } from "react-intl";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import TargetHandles from "../../Handles/TargetHandles";
import NodeIconNameContainer from "../NodeIconNameContainer";
import { faInbox } from "@fortawesome/pro-solid-svg-icons";
import SourceHandles from "../../Handles/SourceHandles";
import FormInput from "../../../../../Components/Forms/FormInput/FormInput";
import { TicketDropdown } from "../../../../AdvancedMultiInputs";

const FormContainer = ({ formState, id, canEdit }) => {
  const { form, handleChange } = formState;
  const { TargetId, EnableMultiple } = form;
  const intl = useIntl();
  const onChange = (e) => {
    const { value } = e.target;
    handleChange({
      TargetId: value
    });
  };

  return (
    <div>
      {!EnableMultiple && (
        <FormInput
          // disabled={Id}
          name="targetId"
          className="mb-3"
          onChange={onChange}
          value={TargetId}
          allTickets
          text={intl.formatMessage({ id: "CASE" })}
          inputType={TicketDropdown}
        />
      )}
    </div>
  );
};

const settings = {
  defaultForm: {
    TargetId: null
  },
  FormContent: (props) => {
    return <FormContainer {...props}></FormContainer>;
  }
};

const GetTicketConversations = React.memo(({ selected, data, id, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    let resolvedNewData = { ...newData };
    // if (resolvedNewData.form.Filters.length > 0) {
    //   resetParameters(resolvedNewData.form.Filters);
    // }

    updater(id, { ...resolvedNewData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };
  const { enabled, config } = data;
  const intl = useIntl();
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <NodeIconNameContainer
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleClick}
          selected={selected}
          icon={faInbox}
          text={intl.formatMessage({ id: "CASE_CONVERSATIONS" })}
        />

        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default GetTicketConversations;
