import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { ReactComponent as NoPermissionSVG } from "../../Assets/Svg/unavaiable_workspace.svg";
import Button from "../../Components/Button/Button";
import AirdeskTextSvg from "../../Components/Svg/AirdeskTextSvg";
import { useSpace } from "../../Contexts/SpaceContext";
import "./NoPermission.css";

const NoPermission = ({ newUrl }) => {
  const history = useHistory();
  const space = useSpace();
  return (
    <div className="w-100 h-100 d-flex position-relative flex-column align-items-center">
      <AirdeskTextSvg className="no-permission-airdesk-title text-secondary" />
      <div
        style={{ color: "#FF8066", zIndex: 3 }}
        className="fw-bold mt-5 fs-32"
      >
        <FormattedMessage id="NO_PERMISSION_DISCLAIMER_TITLE" />
      </div>
      <div style={{ zIndex: 3 }} className="mt-4 text-black fs-18">
        <FormattedMessage id="NO_PERMISSION_DISCLAIMER_DESCRIPTION" />
      </div>
      <div style={{ zIndex: 3 }} className="mt-4">
        <Button
          onClick={() => {
            history.push(newUrl ? newUrl : `/s/${space.Id}`);
          }}
        >
          <FormattedMessage id="BACK" />
        </Button>
      </div>
      <NoPermissionSVG className="no-permission-svg" />
    </div>
  );
};

export default NoPermission;
