const AccountRoleEnum = {
  Default: 0,
  Manager: 1,
};

// const responseObj = {
//     Accountspace: {
//         Account: fk(accountSchema)
//     }
// }

const HistoryItemClientContractDetails = {
  ValueComponent: () => null,
  procedure: (arr, addproperty, property) => {
    const { NewValue, OldValue } = property;

    let oldResponsible, oldResponsibledId, newResponsible, newResponsibleId;

    for (const v of OldValue) {
      if (v.AccountSpaceClientContractRoleId === AccountRoleEnum.Manager) {
        oldResponsible = v.AccountSpace.Account;
        oldResponsibledId = oldResponsible.Id;
      }
    }

    for (const v of NewValue) {
      if (v.AccountSpaceClientContractRoleId === AccountRoleEnum.Manager) {
        newResponsible = v.AccountSpace.Account;
        newResponsibleId = newResponsible.Id;
      }
    }

    if (oldResponsibledId !== newResponsibleId) {
      addproperty({
        Name: "MANAGER",
        OldValue: oldResponsible,
        NewValue: newResponsible,
      });
    }
  },
};

export default HistoryItemClientContractDetails;
