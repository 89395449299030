import React from "react";
import { FormattedMessage } from "react-intl";
import Dropdown from "../../Components/Dropdown/Dropdown";
import moment from "moment";

export const RecurrencyType = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  Yearly: 4,
};

export const dayTypes = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

const dayTrans = {
  [dayTypes.Sunday]: "SUNDAY",
  [dayTypes.Monday]: "MONDAY",
  [dayTypes.Tuesday]: "TUESDAY",
  [dayTypes.Wednesday]: "WEDNESDAY",
  [dayTypes.Thursday]: "THURSDAY",
  [dayTypes.Friday]: "FRIDAY",
  [dayTypes.Saturday]: "SATURDAY",
};

const RecurrencyDaysValueComponent = ({ item }) => {
  if (isNaN(item) || item === null) return null;

  return (
    <div>
      <FormattedMessage id={dayTrans[item]} />
    </div>
  );
};

const options = [
  dayTypes.Sunday,
  dayTypes.Monday,
  dayTypes.Tuesday,
  dayTypes.Wednesday,
  dayTypes.Thursday,
  dayTypes.Friday,
  dayTypes.Saturday,
];

export const RecurrencyDaysDropdown = ({ ...rest }) => {
  return (
    <Dropdown
      items={options}
      listItemComponent={RecurrencyDaysValueComponent}
      valueComponent={RecurrencyDaysValueComponent}
      {...rest}
    />
  );
};

export const MonthWeekTypes = {
  Day: 0,
  First: 1,
  Second: 2,
  Third: 3,
  Quarter: 4,
  Last: 5,
};

const monthWeekTrans = {
  [MonthWeekTypes.Day]: "DAY",
  [MonthWeekTypes.First]: "FIRST",
  [MonthWeekTypes.Second]: "SECOND",
  [MonthWeekTypes.Third]: "THIRD",
  [MonthWeekTypes.Quarter]: "QUARTER",
  [MonthWeekTypes.Last]: "LAST",
};

const RecurrencyMonthWeekValueComponent = ({ item }) => {
  if (isNaN(item)) return null;
  return (
    <div>
      <FormattedMessage id={monthWeekTrans[item]} />
    </div>
  );
};

const monthWeekOptions = [
  MonthWeekTypes.Day,
  MonthWeekTypes.First,
  MonthWeekTypes.Second,
  MonthWeekTypes.Third,
  MonthWeekTypes.Quarter,
  MonthWeekTypes.Last,
];

export const RecurrencyMonthWeekDropdown = ({ ...rest }) => {
  return (
    <Dropdown
      items={monthWeekOptions}
      closeOnSelect
      listItemComponent={RecurrencyMonthWeekValueComponent}
      valueComponent={RecurrencyMonthWeekValueComponent}
      {...rest}
    />
  );
};

export const MonthTypes = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

const monthTrans = {
  [MonthTypes.January]: "JANUARY",
  [MonthTypes.February]: "FEBRUARY",
  [MonthTypes.March]: "MARCH",
  [MonthTypes.April]: "APRIL",
  [MonthTypes.May]: "MAY",
  [MonthTypes.June]: "JUNE",
  [MonthTypes.July]: "JULY",
  [MonthTypes.August]: "AUGUST",
  [MonthTypes.September]: "SEPTEMBER",
  [MonthTypes.October]: "OCTOBER",
  [MonthTypes.November]: "NOVEMBER",
  [MonthTypes.December]: "DECEMBER",
};

const RecurrencyMonthValueComponent = ({ item }) => {
  return (
    <div>
      <FormattedMessage id={monthTrans[item]} />
    </div>
  );
};

const monthOptions = [
  MonthTypes.January,
  MonthTypes.February,
  MonthTypes.March,
  MonthTypes.April,
  MonthTypes.May,
  MonthTypes.June,
  MonthTypes.July,
  MonthTypes.August,
  MonthTypes.September,
  MonthTypes.October,
  MonthTypes.November,
  MonthTypes.December,
];

export const RecurrencyMonthsDropdown = ({ ...rest }) => {
  return (
    <Dropdown
      items={monthOptions}
      closeOnSelect
      listItemComponent={RecurrencyMonthValueComponent}
      valueComponent={RecurrencyMonthValueComponent}
      {...rest}
    />
  );
};

export const buildRecurrenceForm = (BeginDate) => {
  const EndDate = new Date(BeginDate);
  EndDate.setDate(EndDate.getDate() + 7);
  return {
    Type: RecurrencyType.Daily,
    Every: 1,
    EndDate,
    WeekDays: [],
    Month: MonthTypes.January,
    MonthWeekType: MonthWeekTypes.Day,
    MonthDay: 1,
    MonthWeekDay: dayTypes.Monday,
  };
};

const getDayofWeek = (d) => {
  return d.day();
};

const addWorkingDays = (date, days, workingWeekDays) => {
  let addedDays = 0;
  const isPositive = days > 0;

  while (addedDays !== days) {
    date.add(isPositive ? 1 : -1, "d");
    if (workingWeekDays.includes(getDayofWeek(date))) {
      if (isPositive) addedDays++;
      else addedDays--;
    }
  }
  return date;
};

const getDayOfTheMonth = (date, weekOfTheMonth, dayOfWeek) => {
  let fday = moment(new Date(date.year(), date.month(), 1));

  let occurrence = weekOfTheMonth;

  if (getDayofWeek(fday) !== dayOfWeek) {
    fday.add(dayOfWeek - getDayofWeek(fday), "d");
  }
  // else if (
  //   getDayofWeek(fday) === dayOfWeek &&
  //   occurrence === MonthWeekTypes.First
  // ) {
  //   return fday;
  // }

  if (fday.month() !== date.month()) occurrence++;


  let loopDate;

  while (true) {
    loopDate = moment(fday);
    loopDate.add(7 * (occurrence - 1), "d");

    if (loopDate.month() === date.month()) return loopDate;

    occurrence--;
  }
};

export const getRecurrentTasksDates = (recurrency, BeginDate) => {
  const {
    Type,
    Every,
    EndDate,
    WeekDays,
    Month,
    MonthWeekType: WeekOfTheMonth,
    MonthDay,
    MonthWeekDay,
  } = recurrency;
  let resolvedWeekDays = WeekDays;

  if (
    (Type === RecurrencyType.Monthly || Type === RecurrencyType.Yearly) &&
    WeekOfTheMonth !== MonthWeekTypes.Day
  ) {
    resolvedWeekDays = [MonthWeekDay];
  }

  const dates = [];
  const EndDateMill = EndDate.getTime();
  let currentDate = moment(BeginDate);

  let skipItems = Every;

  let itemsAdded = 0;

  const addDate = (d) => {
    dates.push(new Date(d.valueOf()));
  };

  while (true) {
    if (dates.length === 100) return dates;
    var firstTimeSkipping = skipItems === 2;

    switch (Type) {
      case RecurrencyType.Daily:
        currentDate.add(Every, "d");

        if (currentDate.valueOf() <= EndDateMill) addDate(currentDate);
        else return dates;

        break;
      case RecurrencyType.Weekly:
        var hasWeekDays = resolvedWeekDays.length > 0;

        if (hasWeekDays) {
          if (skipItems !== Every) {
            skipItems++;
            currentDate.add(7, "d");
            if (
              getDayofWeek(currentDate) !== dayTypes.Saturday &&
              firstTimeSkipping
            )
              currentDate.add(-(getDayofWeek(currentDate) + 1), "d");
            continue;
          } else {
            addWorkingDays(currentDate, 1, resolvedWeekDays);
            itemsAdded++;

            if (itemsAdded === resolvedWeekDays.length) {
              skipItems = 1;
              itemsAdded = 0;
            }
          }
        } else currentDate = currentDate.add(7 * Every, "d");

        if (currentDate.valueOf() <= EndDateMill) addDate(currentDate);
        else return dates;

        break;
      case RecurrencyType.Monthly:

        if (skipItems !== Every) {
          skipItems++;
          currentDate.add(1, "month");
          if (firstTimeSkipping)
            currentDate = moment(
              new Date(
                currentDate.year(),
                currentDate.month(),
                1,
                currentDate.hours(),
                currentDate.minutes(),
                currentDate.seconds(),
                currentDate.milliseconds()
              )
            );
          continue;
        } else {
          if (MonthDay && WeekOfTheMonth === MonthWeekTypes.Day) {
            var daysInMonth = currentDate.daysInMonth();
            var day = daysInMonth < MonthDay ? daysInMonth : MonthDay;
            //currentDate = currentDate.Add(new DateTime(currentDate.Year, currentDate.Month, day, currentDate.Hour, currentDate.Minute, currentDate.Second, currentDate.Millisecond) - currentDate);
            currentDate = moment(
              new Date(
                currentDate.year(),
                currentDate.month(),
                day,
                currentDate.hours(),
                currentDate.minutes(),
                currentDate.seconds(),
                currentDate.milliseconds()
              )
            );
          } else {
            currentDate = getDayOfTheMonth(
              currentDate,
              WeekOfTheMonth,
              resolvedWeekDays[0]
            );
          }

          if (Every > 1) {
            skipItems = 1;
          }
        }
        if (currentDate.valueOf() < BeginDate.getTime()) {
          currentDate.add(1, "month");
          skipItems = Every;
          continue;
        }

        if (currentDate.valueOf() <= EndDateMill) {
          addDate(currentDate);
          currentDate = currentDate.add(1, "month");
        } else return dates;

        break;
      case RecurrencyType.Yearly:
        if (skipItems !== Every) {
          skipItems++;
          currentDate = currentDate.add(1, "year");
          if (firstTimeSkipping)
            currentDate = moment(
              new Date(
                currentDate.year(),
                1,
                1,
                currentDate.hours(),
                currentDate.minutes(),
                currentDate.seconds(),
                currentDate.milliseconds()
              )
            );
          continue;
        } else {
          if (MonthDay && WeekOfTheMonth === MonthWeekTypes.Day) {
            currentDate = moment(
              new Date(
                currentDate.year(),
                Month - 1,
                MonthDay,
                currentDate.hours(),
                currentDate.minutes(),
                currentDate.seconds(),
                currentDate.milliseconds()
              )
            );
          } else {
            currentDate = moment(
              new Date(
                currentDate.year(),
                Month - 1,
                1,
                currentDate.hours(),
                currentDate.minutes(),
                currentDate.seconds(),
                currentDate.milliseconds()
              )
            );
            currentDate = getDayOfTheMonth(
              currentDate,
              WeekOfTheMonth,
              resolvedWeekDays[0]
            );
          }

          if (Every > 1) {
            skipItems = 1;
          }
        }

        if (currentDate.valueOf() < BeginDate.getTime()) {
          currentDate.add(1, "year");
          skipItems = Every;
          continue;
        }
        if (currentDate.valueOf() <= EndDateMill) {
          addDate(currentDate);
          currentDate.add(1, "year");
        } else return dates;

        break;
      default:
        break;
    }
  }
};
