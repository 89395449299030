import React, { useContext, useRef } from "react";
import { Communicator } from "../../Helpers/Communicator";
import {
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceCallsSchema,
  spaceClientSchema,
  spaceTasksSchema,
  spaceContractSchema,
  spaceContactSchema,
  spaceDealsSchema,
  spaceSubscriptionSchema,
  spaceInterventionsSchema
} from "../../config/schema";
import {
  ProjectsLink,
  TicketsLink,
  CallLink,
  ClientLink,
  TasksLink,
  ContractsLink,
  ContactsLink,
  DealLink,
  SubscriptionsLink,
  InterventionsLink
} from "../../Helpers/HooksHelper";
import { useSpaceEntitiesHub } from "../../Containers/RealTime/RealTimeSpace";
import { useState, useEffect, useMemo } from "react";
import { GetSchemaLink } from "../DetailsView2/DetailsView2Helper";
import EmptyListContainer from "../EmptyListContainer/EmptyListContainer";
import { FormattedMessage } from "react-intl";
import Button from "../Button/Button";
import {
  sendSignalRAdvance,
  useReconnectionStatus
} from "../../Containers/RealTime/RealTime";
import { useUserEntitiesHub } from "../../Containers/RealTime/RealTimeUser";
import { useGlobalLoader } from "../GlobalLoader/GlobalLoader";
import { HubConnectionState } from "@microsoft/signalr";

export const EntitySumaryCommunicator = new Communicator();

export const useEntitySummarySidebar = () => {
  // const [openSidebar] = useSidebar();

  const openEntitySidebar = (props) => {
    //   const ResolvedSummary = SummaryMapper[type];
    //   openSidebar(<ResolvedSummary {...props} />);
    EntitySumaryCommunicator.dispatch(props);
  };

  return openEntitySidebar;
};

export const EntitySummaryConfigContext = React.createContext();

export const useSummaryConfig = (schema) => {
  let LinkComp, SidebarLinkComp;

  switch (schema) {
    case spaceProjectsSchema:
      LinkComp = ProjectsLink;
      break;
    case spaceTicketsSchema:
      LinkComp = TicketsLink;
      break;
    case spaceCallsSchema:
      LinkComp = CallLink;
      break;
    case spaceClientSchema:
      LinkComp = ClientLink;
      break;
    case spaceTasksSchema:
      LinkComp = TasksLink;
      break;
    case spaceContractSchema:
      LinkComp = ContractsLink;
      break;

    case spaceContactSchema:
      LinkComp = ContactsLink;
      break;

    case spaceDealsSchema:
      LinkComp = DealLink;
      break;

    case spaceSubscriptionSchema:
      LinkComp = SubscriptionsLink;
      break;

    case spaceInterventionsSchema:
      LinkComp = InterventionsLink;
      break;
    default:
      break;
    // return null;
  }

  return { LinkComp, SidebarLinkComp };
};

export const useEntityRoom = (roomType, entityId) => {
  const entitiesHub = useSpaceEntitiesHub();
  const isReconecting = useReconnectionStatus();
  const [loading, setLoading] = useState(true);
  const [reconnect, setReconnect] = useState();
  const [dumbError, setDumbError] = useState();

  // const space = useSpace();
  const isReconnecting = useReconnectionStatus();

  useEffect(() => {
    if (!entitiesHub || !entityId) return;

    let isValid = true;
    let hasConnected = false;
    const resolvedEntityId = isNaN(entityId) ? null : Number(entityId);
    const resolvedEntityGuid = isNaN(entityId) ? entityId : null;
    const connector = () => {
      setLoading(true);
      setReconnect();

      sendSignalRAdvance(
        entitiesHub,
        "JoinRoom",
        roomType,
        resolvedEntityId,
        resolvedEntityGuid,
        () => {
          hasConnected = true;
          if (isValid) {
            setDumbError(null);
            setLoading(false);
            // console.log("joined room");
          } else {
            try {
              setLoading(false);
              setDumbError(null);
              if (!isReconecting) {
                entitiesHub.invoke(
                  "LeaveRoom",
                  roomType,
                  resolvedEntityId,
                  resolvedEntityGuid
                );
              }
            } catch (e) {
              console.log(e);
              setDumbError(e);
              setLoading(false);
            }
          }
        },
        (e) => {
          // if (isValid) {
          setLoading(false);
          setReconnect(() => connector);
          setDumbError(e);
          console.log("failed to join room");
          // }else{
          // 	setLoading(false);
          // }
        }
      );

      // entitiesHub
      //   .invoke("JoinRoom", roomType, resolvedEntityId, resolvedEntityGuid)
      //   .done(() => {
      //     hasConnected = true;
      //     if (isValid) {
      //       setLoading(false);
      //       // console.log("joined room");
      //     } else {
      //       try {
      //         entitiesHub.invoke(
      //           "LeaveRoom",
      //           roomType,
      //           resolvedEntityId,
      //           resolvedEntityGuid
      //         );
      //       } catch (e) {}
      //     }
      //   })
      //   .fail((e) => {

      //     if (isValid) {
      //       setLoading(false);
      //       setReconnect(() => connector);
      //       // console.log("failed to join room");
      //     }
      //   });
    };

    connector();

    const listener = () => {
      setTimeout(() => {
        connector();
      }, 450);
    };

    entitiesHub.on("RunEverythingAgain", listener);

    return () => {
      entitiesHub.off("RunEverythingAgain", listener);
      isValid = false;
      if (hasConnected) {
        try {
          setLoading(false);
          setDumbError(null);
          if (!isReconecting) {
            entitiesHub.invoke(
              "LeaveRoom",
              roomType,
              resolvedEntityId,
              resolvedEntityGuid
            );
          }
        } catch (e) {
          setLoading(false);
          setDumbError(e);
          console.log(e);
        }
      }
    };
  }, [entitiesHub, entityId, isReconecting, roomType]);
  // console.log(loading);

  const timeoutRef = useRef();
  // useEffect(() => {
  //   clearTimeout(timeoutRef.current);
  //   if (loading) {
  //     timeoutRef.current = setTimeout(() => {
  //       setErrorTimeout(true);
  //     }, 4000);
  //   } else if (!(!loading && !reconnect)) {
  //     setErrorTimeout(true);
  //   }
  // }, [loading, reconnect]);

  const state = useMemo(() => {
    return {
      loading,
      reconnect,
      errorTimeout: isReconnecting,
      hasError: dumbError,
      isConnected: !loading && !reconnect
    };
  }, [dumbError, isReconnecting, loading, reconnect]);

  return state;
};

export const ItemEliminatedComponent = ({ schema }) => {
  const CompButton = GetSchemaLink(schema);

  const loader = useGlobalLoader();

  useEffect(() => {
    loader.stop();
  }, [loader]);

  return (
    <EmptyListContainer type="deleted-item">
      <div className="fw-bold fs-24 text-black ">
        <FormattedMessage id="DELETED_ITEM" />
      </div>
      <div className="fs-18 text-black text-center mb-4">
        <FormattedMessage id="CURRENT_ITEM_WAS_DELETED" />
      </div>
      <CompButton>
        <Button vType="primary-ghost">
          <FormattedMessage id="RETURN" />
        </Button>
      </CompButton>
    </EmptyListContainer>
  );
};

const PageTypeContext = React.createContext();

export const usePageType = () => useContext(PageTypeContext);

export const PageTypeProvider = ({ pageType, children }) => {
  return (
    <PageTypeContext.Provider value={pageType}>
      {children}
    </PageTypeContext.Provider>
  );
};

export const useEnterViewSignalR = (pageType, idList) => {
  const entitiesHub = useUserEntitiesHub();

  useEffect(() => {
    const connector = () => {
      if (idList) entitiesHub.invoke("EnterView", pageType, idList);
    };

    const listener = () => {
      setTimeout(() => {
        connector();
      }, 150);
    };

    if (entitiesHub) {
      listener();

      entitiesHub.on("RunEverythingAgain", listener);
    }

    return () => {
      if (entitiesHub) entitiesHub.off("RunEverythingAgain", listener);
    };
  }, [entitiesHub, idList, pageType]);
};
