import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useRef,
  useCallback
} from "react";
import {
  useConnectionId,
  useEntitiesHub
  // useReconnectionStatus
} from "../../../Containers/RealTime/RealTime";
import { normalize } from "../../../Helpers/SchemaHelper";
import { spaceNoteSchema, accountSchema } from "../../../config/schema";
import {
  updateNormalizedSchemaCache,
  getEntity,
  useMultiplePost,
  useSpacePost,
  client,
  useSpaceQuery,
  useQuery
} from "../../../Helpers/IOClient";
import { Chat } from "./MessagesChat";
import {
  useMessagesQuery,
  AddedNewMessageManualyContext,
  SetAddedNewMessageManualyContext
} from "./MessagesHelper";
import {
  MessageSender,
  EmailMessageSender
} from "./MessageSender/MessageSender";
import { useUserEntitiesHub } from "../../../Containers/RealTime/RealTimeUser";
import { useCurrentAccount } from "../../../Contexts/UserContext";
import { useSpace } from "../../../Contexts/SpaceContext";
import {
  useEntityValue,
  useEmailConversation,
  useEmail,
  useClient
} from "../../../Hooks/EntityHooks";
import { useEntityRoom } from "../EntitySummariesHelper";
import {
  emailChatResponseSchema,
  EmailChatMessagesItem
} from "../../../Containers/Reception/EmailChatComponent";
import ReceptionEmailNoteChat from "../../../Containers/Reception/ReceptionEmails/ReceptionEmailNoteChat";

import { useSpaceReconnect } from "../../../Containers/RealTime/RealTimeSpace";
import { FormattedMessage } from "react-intl";
import Button from "../../Button/Button";
import LoaderSpinner from "../../Loader/LoaderSpinner/LoaderSpinner";

const MessengerTypingAccountsContext = React.createContext();

export const useMessengerTypingAccounts = () =>
  useContext(MessengerTypingAccountsContext);

const MessengerTypingAccountsProvider = ({ children }) => {
  const entitiesHub = useUserEntitiesHub();

  const [accounts, setAccounts] = useState([]);

  const currentUserId = useCurrentAccount().Id;

  useEffect(() => {
    if (!entitiesHub) return;
    const listner = (roomType, entityId, accounts) => {
      const normalized = normalize(JSON.parse(accounts), [accountSchema]);
      updateNormalizedSchemaCache(normalized.entities);

      const index = normalized.result.indexOf(currentUserId);
      if (index !== -1) normalized.result.splice(index, 1);

      setAccounts(normalized.result);
    };

    entitiesHub.on("EntityMessageTyping", listner);

    return () => entitiesHub.off("EntityMessageTyping", listner);
  }, [currentUserId, entitiesHub]);

  return (
    <MessengerTypingAccountsContext.Provider value={accounts}>
      {children}
    </MessengerTypingAccountsContext.Provider>
  );
};

const MessengerNotesTypingAccountsProvider = ({ children }) => {
  const entitiesHub = useUserEntitiesHub();

  const [accounts, setAccounts] = useState([]);

  const currentUserId = useCurrentAccount().Id;

  useEffect(() => {
    if (!entitiesHub) return;
    const listner = (roomType, entityId, accounts) => {
      const normalized = normalize(JSON.parse(accounts), [accountSchema]);
      updateNormalizedSchemaCache(normalized.entities);

      const index = normalized.result.indexOf(currentUserId);
      if (index !== -1) normalized.result.splice(index, 1);

      setAccounts(normalized.result);
    };

    entitiesHub.on("EntityNoteTyping", listner);

    return () => entitiesHub.off("EntityNoteTyping", listner);
  }, [currentUserId, entitiesHub]);

  return (
    <MessengerTypingAccountsContext.Provider value={accounts}>
      {children}
    </MessengerTypingAccountsContext.Provider>
  );
};

const getUpdatedNote = (note, accountId, remove = false) => {
  let newNote;

  if (remove) {
    if (!note.ReadUsers) return note;
    const index = note.ReadUsers.indexOf(accountId);
    if (index === -1) return note;

    newNote = { ...note, ReadUsers: [...note.ReadUsers] };
    newNote.ReadUsers.splice(index, 1);
  } else {
    if (!note.ReadUsers) {
      newNote = { ...note, ReadUsers: [accountId] };
    } else if (!note.ReadUsers.includes(accountId)) {
      newNote = { ...note, ReadUsers: [...note.ReadUsers, accountId] };
    }
  }

  return newNote || note;
};

const useMessengerReadSync = (
  lastUserReadMessagesRef,
  schema,
  entityId,
  Settings
) => {
  const entitiesHub = useUserEntitiesHub();

  const currentUserId = useCurrentAccount().Id;

  useEffect(() => {
    if (!entitiesHub) return;
    const { Schema } = Settings || {};
    const listner = (roomType, entityId, noteId, userId) => {
      const noteUpdates = {};
      const accountId = userId;
      const prevNoteId = lastUserReadMessagesRef.current[accountId];
      const prevNote = getEntity(Schema || spaceNoteSchema, prevNoteId);
      const account = getEntity(accountSchema, userId);

      const note = getEntity(Schema || spaceNoteSchema, noteId);
      const normalizedAccounts = normalize(account, accountSchema).entities;
      if (prevNote) {
        const newPrevNote = getUpdatedNote(prevNote, accountId, true);
        noteUpdates[prevNoteId] = newPrevNote;
      }

      if (note) {
        lastUserReadMessagesRef.current[accountId] = noteId;
        const newNote = getUpdatedNote(note, accountId);
        noteUpdates[noteId] = newNote;
      }

      updateNormalizedSchemaCache({
        ...normalizedAccounts,
        [(Schema || spaceNoteSchema).name]: noteUpdates
      });
    };

    entitiesHub.on("EntityMessageRead", listner);

    return () => entitiesHub.off("EntityMessageRead", listner);
  }, [Settings, currentUserId, entitiesHub, lastUserReadMessagesRef]);
};

const markEntityAsNonRead = (schema, entityId) => {
  const update = {
    [schema.name]: {
      [entityId]: {
        IsRead: false
      }
    }
  };

  updateNormalizedSchemaCache(update);
};

export const useMessagesSynchronizer = (
  addMessage,
  roomType,
  entityId,
  schema,
  Settings,
  refetchConv
) => {
  const canReadEntitiesRef = useRef(false);

  const [unreadCount, setUnreadCount] = useState(0);

  const unreadRef = useRef(0);

  const entitiesHub = useEntitiesHub();
  const post = useMultiplePost();
  const { Id: spaceId } = useSpace();

  const following = (useEntityValue(schema, entityId) || {}).IsFollowing;
  const readStatus = (useEntityValue(schema, entityId) || {}).IsRead;
  const currentFiles = (useEntityValue(schema, entityId) || {}).Files;

  const url = useMemo(() => {
    const { getEndpoint, noteCreator } = Settings || {};
    if (getEndpoint) {
      const urli = getEndpoint(entityId);
      return noteCreator
        ? `spaces/${spaceId}/${urli}/Read?IsNote=1`
        : `spaces/${spaceId}/${urli}/Read`;
    }
    return noteCreator
      ? `spaces/${spaceId}/${schema.getEndpoint(
          spaceId
        )}/${entityId}/Read?IsNote=1`
      : `${schema.getEndpoint(spaceId)}/${entityId}/Read`;
  }, [Settings, entityId, schema, spaceId]);

  const toggleReadState = useCallback(
    (v) => {
      let newValue;
      if (v !== undefined) newValue = Boolean(v);
      else newValue = !canReadEntitiesRef.current;
      canReadEntitiesRef.current = newValue;

      if (newValue && unreadRef.current > 0) {
        unreadRef.current = 0;
        setUnreadCount(0);
        post(url, null, {
          schema
        });
      }
    },
    [post, schema, url]
  );

  const newMessageUrl = useCallback(
    (noteId) => {
      const { noteCreator } = Settings || {};
      const base = schema.getEndpoint(spaceId);

      return noteCreator
        ? `${base}/${entityId}/comments?isNote=1`
        : `${base}/${entityId}/comments/${noteId}`;
    },
    [Settings, schema, spaceId, entityId]
  );

  const [newMessageUrlFixed, setNewMessageUrlFixed] = useState(null);
  const isCreationRef = useRef(false);
  const { refetch } = useQuery(newMessageUrlFixed, spaceNoteSchema, {
    cache: false,
    onSuccess: ({ data }) => {
      const normalNote = getEntity(spaceNoteSchema, data);
      if (isCreationRef.current) {
        addMessage(data);
        if (following && !normalNote.LastMessageRead && !readStatus) post(url);
        // if (normalNote.Files) {
        //   const newFiles = Array.isArray(currentFiles)
        //     ? [...currentFiles, ...normalNote.Files]
        //     : [...normalNote.Files];
        //   const newSchemaFiles = [...new Set(newFiles.map((item) => item.Id))];
        //   const normalizedEntities = normalize(
        //     { Id: entityId, Files: newSchemaFiles },
        //     schema
        //   );
        //   updateNormalizedSchemaCache(normalizedEntities.entities);
        // }
      }
      // if(!isCreationRef.current && normalNote.IsPinned){
      // 	const normalizedEntities = normalize({Id: normalNote.Id, IsPinned: normalNote.IsPinned}, spaceNoteSchema);
      // 	updateNormalizedSchemaCache(normalizedEntities.entities);
      // }
      setNewMessageUrlFixed(null);
    }
  });

  const conId = useConnectionId();

  useEffect(() => {
    if (!entitiesHub) return;
    const { convertNote } = Settings || {};

    const listner = (roomType, entityId, noteId, isCreation, IsDeleted) => {
      if (refetchConv) {
        // refetchConv();
        // ;
        // const normalNote = getEntity(spaceNoteSchema, noteId);
        // if (!normalNote.LastMessageRead) post(url);
        if (IsDeleted) {
          // const normalNote = getEntity(spaceNoteSchema, noteId);

          const normalizedEntities = normalize(
            { Id: noteId, IsDeleted },
            spaceNoteSchema
          );
          updateNormalizedSchemaCache(normalizedEntities.entities);

          const thisMessage = getEntity(spaceNoteSchema, noteId);
          if (
            Array.isArray(thisMessage?.Files) &&
            thisMessage?.Files?.length > 0
          ) {
            const currentItemFiles = currentFiles ? [...currentFiles] : [];

            const filteredFiles = [];

            for (const e of currentItemFiles) {
              if (thisMessage.Files.findIndex((t) => t.Id === e.Id) < 0) {
                filteredFiles.push(e);
              }
            }

            const normalizedEntities2 = normalize(
              { Id: entityId, Files: filteredFiles },
              schema
            );
            updateNormalizedSchemaCache(normalizedEntities2.entities);
          }

          // if (Array.isArray(normalNote.Files)) {
          //   const deletedNoteFilesArr = normalNote.Files.map((e) => e.Id);
          //   const newFiles = currentFiles.filter(
          //     (item) => !deletedNoteFilesArr.includes(item.Id)
          //   );
          //   const normalizedEntities2 = normalize(
          //     { Id: entityId, Files: newFiles },
          //     schema
          //   );
          //   updateNormalizedSchemaCache(normalizedEntities2.entities);
          // }
        } else {
          const newUrlNewMessage = newMessageUrl(noteId);
          isCreationRef.current = isCreation;
          setNewMessageUrlFixed(newUrlNewMessage);
        }
        // client.dumbGet(newUrlNewMessage, null, {
        //   // cancelToken: sourceRef.current.token,
        //   schema: spaceNoteSchema,
        //   onSuccess: (state) => {
        //     console.log(state);
        //     ;
        //     const normalNote = getEntity(spaceNoteSchema, noteId);
        //     addMessage(normalNote);
        //     if (!normalNote.LastMessageRead) post(url);
        //   },

        //   headers: { ConnectionID: conId }
        // });

        // if (following === false) return;
        // if (canReadEntitiesRef.current) post(url);
        // else {
        //   markEntityAsNonRead(schema, entityId);
        //   setUnreadCount((c) => c + 1);
        //   unreadRef.current++;
        // }
      } else {
        // const parseData = data ? JSON.parse(data) : undefined;

        // const normalizedEntities = normalize(parseData, spaceNoteSchema);

        // updateNormalizedSchemaCache(normalizedEntities.entities);
        // if (isCreation)
        //   addMessage(
        //     convertNote
        //       ? convertNote(normalizedEntities.result)
        //       : normalizedEntities.result
        //   );
        if (following === false) return;
        if (canReadEntitiesRef.current) post(url);
        else {
          markEntityAsNonRead(schema, entityId);
          setUnreadCount((c) => c + 1);
          unreadRef.current++;
        }
      }
    };

    entitiesHub.on("EntityNote", listner);

    return () => entitiesHub.off("EntityNote", listner);
  }, [
    Settings,
    addMessage,
    conId,
    currentFiles,
    entitiesHub,
    entityId,
    following,
    newMessageUrl,
    post,
    refetchConv,
    roomType,
    schema,
    url
  ]);

  return { unreadCount, toggleReadState };
};

export const useNotesSynchronizer = (
  addMessage,
  roomType,
  entityId,
  schema,
  Settings,
  refetchConv
) => {
  const canReadEntitiesRef = useRef(false);

  const [unreadCount, setUnreadCount] = useState(0);

  const unreadRef = useRef(0);

  const entitiesHub = useEntitiesHub();
  const post = useMultiplePost();
  const { Id: spaceId } = useSpace();

  const following = (useEntityValue(schema, entityId) || {}).IsFollowing;
  const readStatus = (useEntityValue(schema, entityId) || {}).IsRead;
  const currentFiles = (useEntityValue(schema, entityId) || {}).Files;
  const url = useMemo(() => {
    const { getEndpoint, noteCreator } = Settings || {};
    if (getEndpoint) {
      const url = getEndpoint(entityId);
      return noteCreator
        ? `spaces/${spaceId}/${url}/Read?IsNote=1`
        : `spaces/${spaceId}/${url}/Read`;
    }
    return noteCreator
      ? `${schema.getEndpoint(spaceId)}/${entityId}/Read?IsNote=1`
      : `${schema.getEndpoint(spaceId)}/${entityId}/Read`;
  }, [Settings, entityId, schema, spaceId]);

  const toggleReadState = useCallback(
    (v) => {
      let newValue;
      if (v !== undefined) newValue = Boolean(v);
      else newValue = !canReadEntitiesRef.current;
      canReadEntitiesRef.current = newValue;

      if (newValue && unreadRef.current > 0) {
        unreadRef.current = 0;
        setUnreadCount(0);
        post(url, null, {
          schema
        });
      }
    },
    [post, schema, url]
  );

  const newMessageUrl = useCallback(
    (noteId) => {
      const { noteCreator } = Settings;
      const base = schema.getEndpoint(spaceId);

      return noteCreator
        ? `${base}/${entityId}/comments/${noteId}?isNote=1`
        : `${base}/${entityId}/comments/${noteId}`;
    },
    [Settings, schema, spaceId, entityId]
  );

  const [newMessageUrlFixed, setNewMessageUrlFixed] = useState(null);

  const isCreationRef = useRef(false);
  const { refetch } = useQuery(newMessageUrlFixed, spaceNoteSchema, {
    cache: false,
    onSuccess: ({ data }) => {
      const normalNote = getEntity(spaceNoteSchema, data);

      if (isCreationRef.current) {
        addMessage(data);
        if (following && !normalNote.LastMessageRead && !readStatus) post(url);
      }
      // if(!isCreationRef.current && normalNote.IsPinned){
      // 	const normalizedEntities = normalize({Id: normalNote.Id, IsPinned: normalNote.IsPinned}, spaceNoteSchema);
      // 	updateNormalizedSchemaCache(normalizedEntities.entities);
      // }
      setNewMessageUrlFixed(null);
    }
  });
  const conId = useConnectionId();
  useEffect(() => {
    if (!entitiesHub) return;
    const { convertNote } = Settings || {};

    // const listner = (roomType, entityId, noteId, data, isCreation) => {
    //   const parseData = data ? JSON.parse(data) : undefined;

    //   const normalizedEntities = normalize(parseData, spaceNoteSchema);

    //   updateNormalizedSchemaCache(normalizedEntities.entities);
    //   if (isCreation)
    //     addMessage(
    //       convertNote
    //         ? convertNote(normalizedEntities.result)
    //         : normalizedEntities.result
    //     );
    //   if (following === false) return;
    //   if (canReadEntitiesRef.current) post(url);
    //   else {
    //     markEntityAsNonRead(schema, entityId);
    //     setUnreadCount((c) => c + 1);
    //     unreadRef.current++;
    //   }
    // };

    const listner = (roomType, entityId, noteId, isCreation, IsDeleted) => {
      if (refetchConv) {
        // const newUrlNewMessage = newMessageUrl(noteId);
        // setNewMessageUrlFixed(newUrlNewMessage);
        // client.post(newUrlNewMessage, null, {
        //   // cancelToken: sourceRef.current.token,
        //   schema: spaceNoteSchema,
        //   onSuccess: (state) => {
        //     const normalNote = getEntity(spaceNoteSchema, noteId);
        //     addMessage(normalNote);
        //     if (!normalNote.LastMessageRead) post(url);
        //   },

        //   headers: { ConnectionID: conId }
        // });

        if (IsDeleted) {
          const normalizedEntities = normalize(
            { Id: noteId, IsDeleted },
            spaceNoteSchema
          );
          updateNormalizedSchemaCache(normalizedEntities.entities);

          const thisMessage = getEntity(spaceNoteSchema, noteId);
          if (
            Array.isArray(thisMessage?.Files) &&
            thisMessage?.Files?.length > 0
          ) {
            const currentItemFiles = currentFiles ? [...currentFiles] : [];

            const filteredFiles = [];

            for (const e of currentItemFiles) {
              if (thisMessage.Files.findIndex((t) => t.Id === e.Id) < 0) {
                filteredFiles.push(e);
              }
            }

            const normalizedEntities2 = normalize(
              { Id: entityId, Files: filteredFiles },
              schema
            );
            updateNormalizedSchemaCache(normalizedEntities2.entities);
          }
        } else {
          const newUrlNewMessage = newMessageUrl(noteId);
          isCreationRef.current = isCreation;
          setNewMessageUrlFixed(newUrlNewMessage);
        }

        // refetchConv();
        // ;
        // const normalNote = getEntity(spaceNoteSchema, noteId);
        // if (!normalNote.LastMessageRead) post(url);
        // if (following === false) return;
        // if (canReadEntitiesRef.current) post(url);
        // else {
        //   markEntityAsNonRead(schema, entityId);
        //   setUnreadCount((c) => c + 1);
        //   unreadRef.current++;
        // }
      } else {
        // const parseData = data ? JSON.parse(data) : undefined;

        // const normalizedEntities = normalize(parseData, spaceNoteSchema);

        // updateNormalizedSchemaCache(normalizedEntities.entities);
        // if (isCreation)
        //   addMessage(
        //     convertNote
        //       ? convertNote(normalizedEntities.result)
        //       : normalizedEntities.result
        //   );
        if (following === false) return;
        if (canReadEntitiesRef.current) post(url);
        else {
          markEntityAsNonRead(schema, entityId);
          setUnreadCount((c) => c + 1);
          unreadRef.current++;
        }
      }
    };

    entitiesHub.on("EntityNoteNote", listner);

    return () => entitiesHub.off("EntityNoteNote", listner);
  }, [
    Settings,
    addMessage,
    conId,
    currentFiles,
    entitiesHub,
    entityId,
    following,
    newMessageUrl,
    post,
    refetchConv,
    roomType,
    schema,
    url
  ]);

  return { unreadCount, toggleReadState };
};

export const UnreadMessagesContext = React.createContext();

const MessageConnectionContext = React.createContext();
export const useMessageConnectionState = () =>
  useContext(MessageConnectionContext);

export const ConnectionContext = React.createContext();

export const MessagesConnection = ({
  children,
  roomType,
  entityId,
  Settings,
  disableMessages,
  schema
}) => {
  const connectionState = useEntityRoom(roomType, entityId);
  const { isConnected, hasError } = connectionState;

  //
  const state = useMessagesQuery(
    schema,
    isConnected ? entityId : undefined,
    // entityId,
    Settings,
    disableMessages
  );

  const { addMessage, lastUserReadMessagesRef, refetch } = state;

  const { toggleReadState, unreadCount } = useMessagesSynchronizer(
    addMessage,
    roomType,
    entityId,
    schema,
    Settings,
    refetch
  );

  useMessengerReadSync(lastUserReadMessagesRef, schema, entityId, Settings);

  const val = useMemo(() => {
    return {
      ...state,
      connectionState,
      toggleReadState
    };
  }, [connectionState, state, toggleReadState]);
  return (
    <ConnectionContext.Provider value={val}>
      <UnreadMessagesContext.Provider value={unreadCount}>
        <MessengerTypingAccountsProvider>
          {children}
        </MessengerTypingAccountsProvider>
      </UnreadMessagesContext.Provider>
    </ConnectionContext.Provider>
  );
};

const viewEnum = {
  messages: 1,
  files: 2,
  notes: 3
};

export const ConnectedNoteLog = ({
  entityId,
  schema,
  type,
  roomType,
  disableMessages,
  noteCreator
}) => {
  const { connectionState } = useContext(ConnectionContext);
  const { isConnected, errorTimeout, hasError } = connectionState;
  const state = useMessagesQuery(
    schema,
    // isConnected ? entityId : undefined,
    entityId,
    { noteCreator },
    disableMessages
  );

  const {
    messages,
    loadMore,
    loading,
    error,
    addMessage,
    refetch,
    lastUserReadMessagesRef
  } = state;

  const { toggleReadState } = useNotesSynchronizer(
    addMessage,
    roomType,
    entityId,
    schema,
    { noteCreator },
    refetch
  );

  useMessengerReadSync(lastUserReadMessagesRef, schema, entityId, {
    noteCreator
  });

  // const {
  //   messages,
  //   loadMore,
  //   addMessage,
  //   loading,
  //   error,
  //   // lastUserReadMessagesRef,
  //   connectionState,
  //   toggleReadState
  // } = useContext(ConnectionContext);

  useEffect(() => {
    toggleReadState(true);
    return () => toggleReadState(false);
  }, [toggleReadState]);

  const entitiesHub = useUserEntitiesHub();
  useEffect(() => {
    if (entitiesHub) {
      const resolvedEntityId = isNaN(entityId) ? null : Number(entityId);
      const resolvedEntityGuid = isNaN(entityId) ? entityId : null;
      setTimeout(() => {
        try {
          entitiesHub.invoke(
            "WhoIsTypingNote",
            roomType,
            resolvedEntityId,
            resolvedEntityGuid
          );
        } catch (error) {
          console.log(error);
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [replyMessageId, setReplyMessageId] = useState();
  const [editMessageId, setEditMessageId] = useState();

  return (
    <MessageConnectionContext.Provider value={connectionState}>
      <MessengerNotesTypingAccountsProvider>
        <Chat
          noteCreator={noteCreator}
          messages={messages}
          loading={loading}
          error={error}
          loadMore={loadMore}
          replyMessageId={replyMessageId}
          onChangeReplyMessageId={setReplyMessageId}
          editMessageId={editMessageId}
          onChangeEditMessageId={setEditMessageId}
        />
        {Boolean(messages) && isConnected && !errorTimeout && (
          <MessageSender
            noteCreator={noteCreator}
            roomType={roomType}
            addMessage={addMessage}
            replyMessageId={replyMessageId}
            onChangeReplyMessageId={setReplyMessageId}
            editMessageId={editMessageId}
            onChangeEditMessageId={setEditMessageId}
            schema={schema}
            entityId={entityId}
            messageType={viewEnum.notes}
            type={type}
          />
        )}
        {(errorTimeout || hasError) && <RetryComponent />}
      </MessengerNotesTypingAccountsProvider>
    </MessageConnectionContext.Provider>
  );
};

export const ConnectedMessenger = (props) => {
  const { entityId, schema, type, roomType, noteCreator } = props;
  // const connectionState = useEntityRoom(roomType, entityId);
  const {
    messages,
    loadMore,
    addMessage,
    loading,
    error,
    refetch,
    // lastUserReadMessagesRef,
    connectionState,
    toggleReadState
  } = useContext(ConnectionContext);
  const { isConnected, errorTimeout, isConnecting, hasError } = connectionState;
  const entitiesHub = useUserEntitiesHub();

  useEffect(() => {
    // refetch();
    if (entitiesHub) {
      const resolvedEntityId = isNaN(entityId) ? null : Number(entityId);
      const resolvedEntityGuid = isNaN(entityId) ? entityId : null;
      setTimeout(() => {
        try {
          entitiesHub.invoke(
            "WhoIsTypingMessage",
            roomType,
            resolvedEntityId,
            resolvedEntityGuid
          );
        } catch (error) {
          console.log(error);
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toggleReadState(true);
    return () => toggleReadState(false);
  }, [toggleReadState]);

  const [replyMessageId, setReplyMessageId] = useState();
  const [editMessageId, setEditMessageId] = useState();
  const [addedNewMessageManualy, setAddedNewMessageManualy] = useState(false);
  return (
    <MessageConnectionContext.Provider value={connectionState}>
      <AddedNewMessageManualyContext.Provider value={addedNewMessageManualy}>
        <SetAddedNewMessageManualyContext.Provider
          value={setAddedNewMessageManualy}
        >
          <Chat
            noteCreator={noteCreator}
            messages={messages}
            loading={loading}
            error={error}
            loadMore={loadMore}
            replyMessageId={replyMessageId}
            onChangeReplyMessageId={setReplyMessageId}
            editMessageId={editMessageId}
            onChangeEditMessageId={setEditMessageId}
          />
          {Boolean(messages) && isConnected && !errorTimeout && (
            <MessageSender
              noteCreator={noteCreator}
              roomType={roomType}
              addMessage={addMessage}
              replyMessageId={replyMessageId}
              onChangeReplyMessageId={setReplyMessageId}
              editMessageId={editMessageId}
              onChangeEditMessageId={setEditMessageId}
              schema={schema}
              entityId={entityId}
              type={type}
            />
          )}
          {(errorTimeout || hasError) && <RetryComponent />}
        </SetAddedNewMessageManualyContext.Provider>
      </AddedNewMessageManualyContext.Provider>
    </MessageConnectionContext.Provider>
  );
};

const RetryComponent = () => {
  const reconnect = useSpaceReconnect();
  const { reconnect: reconnectRoom } = useMessageConnectionState();

  if (reconnect || reconnectRoom)
    return (
      <div className="border-top p-4 w-100 justify-content-center d-flex flex-column">
        <div className="text-center">
          <div className="mb-3 d-flex align-items-center justify-content-center fw-medium text-warning">
            <span className="mr-3">
              <FormattedMessage id="CHAT_RECONNECTING" />
            </span>
            <LoaderSpinner size="xs" />
          </div>
          {/* <Button onClick={reconnect || reconnectRoom}>
            <FormattedMessage id="RECONNECT" />
          </Button> */}
        </div>
      </div>
    );
  else return null;
};

const EmailChatMessage = ({ message, ...rest }) => {
  if (message.Email) return <EmailChatMessagesItem Data={message.Email} />;
  else return <ReceptionEmailNoteChat message={message.Note} {...rest} />;
};

const EmailSelector = (v, link) => {
  const Email = link("Email");
  const Note = link("Note");
  return { Email, Note };
};

export const EmailConnectedMessenger = ({
  entityId,
  schema,
  type,
  roomType
}) => {
  // const connectionState = useEntityRoom(roomType, entityId);

  const Conversation = useEmailConversation(entityId);
  const { Client } = Conversation;
  const ResolvedClient = useClient(Client);

  const {
    messages,
    loadMore,
    addMessage,
    loading,
    error,
    // lastUserReadMessagesRef,
    connectionState,
    toggleReadState
  } = useContext(ConnectionContext);
  const { isConnected, errorTimeout, isConnecting } = connectionState;

  useEffect(() => {
    toggleReadState(true);
    return () => toggleReadState(false);
  }, [toggleReadState]);

  const [replyMessageId, setReplyMessageId] = useState();
  const [editMessageId, setEditMessageId] = useState();
  const [addedNewMessageManualy, setAddedNewMessageManualy] = useState(false);
  // return null;
  return (
    <MessageConnectionContext.Provider value={connectionState}>
      <AddedNewMessageManualyContext.Provider value={addedNewMessageManualy}>
        <SetAddedNewMessageManualyContext.Provider
          value={setAddedNewMessageManualy}
        >
          <Chat
            noTyping
            isEmail
            selector={EmailSelector}
            messageSchema={emailChatResponseSchema}
            MessageComponent={EmailChatMessage}
            messages={messages}
            loading={loading}
            error={error}
            loadMore={loadMore}
            replyMessageId={replyMessageId}
            onChangeReplyMessageId={setReplyMessageId}
            editMessageId={editMessageId}
            onChangeEditMessageId={setEditMessageId}
          />
          {Boolean(messages) &&
            isConnected &&
            !errorTimeout &&
            !ResolvedClient?.IsDeleted && (
              <EmailMessageSender
                messages={messages}
                roomType={roomType}
                addMessage={addMessage}
                replyMessageId={replyMessageId}
                onChangeReplyMessageId={setReplyMessageId}
                editMessageId={editMessageId}
                onChangeEditMessageId={setEditMessageId}
                schema={schema}
                entityId={entityId}
                type={type}
              />
            )}
          {errorTimeout && <RetryComponent />}
        </SetAddedNewMessageManualyContext.Provider>
      </AddedNewMessageManualyContext.Provider>
    </MessageConnectionContext.Provider>
  );
};

// const MessageStatusProvider = () => {
//   const addMessageStatus
// }
