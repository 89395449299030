import React from "react";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";
import { FormattedMessage, useIntl } from "react-intl";

const GDPR = ({ onChange, value, preview, className }) => {
  const intl = useIntl();
  if (preview)
    return (
      <div className="fs-14 text-black pt-1">
        {" "}
        <FormattedMessage id={GDPREnumDict[value]} />
      </div>
    );
  else
    return (
      <KeyedDropdown
        key={value}
        placeholder={intl.formatMessage({ id: "SELECT_LEGAL_BASIS" })}
        getKey={(e) => e}
        className={`w-100 ${className}`}
        value={value}
        valueComponent={SimpleComponent}
        onChange={onChange}
        options={Object.keys(GDPREnumDict)}
      />
    );
};

const SimpleComponent = ({ item }) => {
  return (
    <div style={{ color: !item && "#7C98B6" }}>
      {item ? (
        <FormattedMessage id={GDPREnumDict[item]} />
      ) : (
        <FormattedMessage id={"SELECT_LEGAL_BASIS"} />
      )}
    </div>
  );
};

export default GDPR;

export const GDPREnumDict = {
  1: "GDPR_OPTION1",
  2: "GDPR_OPTION2",
  3: "GDPR_OPTION3",
  4: "GDPR_OPTION4",
  5: "GDPR_OPTION5",
  6: "GDPR_OPTION6",
};
