import React, { useContext } from "react";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import { ElementsRefContext } from "../../AutomationDetails";
import TargetHandles from "../../Handles/TargetHandles";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import SourceHandles from "../../Handles/SourceHandles";
import {
  defaultEmailForm,
  formatAutomationEmailConversationForm
} from "../../../../Reception/ReceptionNewConvForm/EmailFormContentHelper";
import EmailFormContent from "../../../../Reception/ReceptionNewConvForm/EmailFormContent";
import AutomationCreation, {
  EntityAutomationCreationSidebar
} from "./CreationNode";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import { useIntl } from "react-intl";
import { isValueExpression } from "../../../../../Helpers/MiscHelper";

const convertAutomationToEmailForm = (form, userId) => {
  const {
    client,
    to_ids,
    cc_ids,
    bcc_ids,
    to,
    cc,
    bcc,
    target,
    enableManualBCC,
    enableManualCC,
    enableManualTo,
    subject,
    followers,
    text,
    // tickets,
    creator,
    organization_space_id,
    // tasks,
    // deals,
    // task_ids,
    // deal_ids,
    // ticket_ids,
    signature
  } = form;
  return {
    Company: organization_space_id,
    Creator: creator,
    Client: client,
    enableManualBCC,
    enableManualCC,
    enableManualTo,
    To: isValueExpression(to_ids)
      ? to_ids
      : to_ids?.length > 0
      ? to_ids?.split(",")
      : undefined,
    Cc: isValueExpression(cc_ids)
      ? cc_ids
      : cc_ids?.length > 0
      ? cc_ids?.split(",")
      : undefined,
    Bcc: isValueExpression(bcc_ids)
      ? bcc_ids
      : bcc_ids?.length > 0
      ? bcc_ids?.split(",")
      : undefined,
    ToManual: to,
    CcManual: cc,
    Followers: isValueExpression(followers)
      ? followers
      : followers?.length > 0
      ? followers?.split(",")
      : undefined,
    BccManual: bcc,
    Subject: subject,
    Text: text,
    Signature: signature
      ? {
          type: signature.type,
          data: signature.uniq_id,
          Company: signature.organization_id
        }
      : undefined,
    ConversationTarget: target
  };
};

const settings = {
  convertAutomationToForm: convertAutomationToEmailForm,
  buildBaseForm: ({ user, id, convertedForm }) => {
    const EmailForm = defaultEmailForm();
    return {
      ...EmailForm,
      ...convertedForm,
      canEdit: true
    };
  },
  getDefaultForm: () => {
    return defaultEmailForm();
  },
  formatToSnake: formatAutomationEmailConversationForm,
  FormContent: (props) => {
    return (
      <EmailFormContent
        canEdit
        disableAttachments
        Automation
        disableManual
        {...props}
      ></EmailFormContent>
    );
  }
};

const EmailConversationCreationNode = React.memo(
  ({ selected, data, id, type }) => {
    const [openSidebar, closeSidebar] = useSidebar();
    const updater = useNodeUpdater();
    const handleSubmit = (newData) => {
      updater(id, { ...newData });
      closeSidebar();
    };

    const AllElementsRef = useContext(ElementsRefContext);

    const AutomationTestRequest = useContext(AutomationTestRequestContext);
    const AutomationTestClick = useContext(AutomationTestClickContext);

    const handleClick = () => {
      openSidebar(
        <EntityAutomationCreationSidebar
          baseRequest={AutomationTestRequest}
          automationTestClick={AutomationTestClick}
          AllElements={AllElementsRef.current}
          CurrentNodeId={id}
          data={data}
          settings={settings}
          handleSubmit={handleSubmit}
        />
      );
    };
    const { enabled, config } = data;
    const intl = useIntl();
    return (
      <div className="position-relative">
        <AutomationTestResults id={id} />
        <NodeBaseContainer data={data} id={id}>
          <TargetHandles />
          <AutomationCreation
            config={config}
            type={type}
            enabled={enabled}
            onDoubleClick={handleClick}
            selected={selected}
            icon={faEnvelope}
            text={intl.formatMessage({ id: "CREATE_CONVERSATION" })}
          />
          {/* <NodeContainer
          enabled={enabled}
					type={type}
          selected={selected}
          onDoubleClick={handleClick}
        >
          <div className={classnames(styles.nodeIcon, "p-3")}>
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
          <div className="px-3 d-flex justify-content-center bg-white flex-column text-black fs-14">
            <div className="fw-medium">Email</div>
          </div>
        </NodeContainer> */}

          <SourceHandles />
        </NodeBaseContainer>
      </div>
    );
  }
);

export default EmailConversationCreationNode;
