import React from "react";
import "./SpaceOnboardingDepartments.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Item = ({ dep, updateState, index }) => {
  const { DepartmentName, IsSelected, icon } = dep;

  const updateX = () => {
    updateState((s) => {
      const newState = [...s];
      newState[index] = { ...newState[index] };
      newState[index].IsSelected = !newState[index].IsSelected;
      return newState;
    });
  };

  return (
    <div
      onClick={updateX}
      className={classnames(
        "ar-onboarding-dep-item d-flex flex-column justify-content-center align-items-center",
        {
          selected: IsSelected,
        }
      )}
    >
      <div className="ar-onboarding-dep-item-icon d-flex align-items-center justify-content-center mb-3">
        <FontAwesomeIcon size="2x" icon={icon} />
      </div>
      <div className="text-black fs-18 fw-medium">{DepartmentName}</div>
    </div>
  );
};

export const SpaceOnboardingDepartmentItems = ({
  disabled,
  state,
  updateState,
}) => {
  return (
    <div className="d-flex justify-content-around flex-wrap ar-onboarding-dep-items">
      {state.map((dep, i) => {
        return <Item key={i} index={i} dep={dep} updateState={updateState} />;
      })}
    </div>
  );
};
