import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import classNames from "classnames";
import "./GlobalLoader.css";

export const GlobalLoadingContext = React.createContext();
export class GlobalLoadingProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnimating: false,
      isInSuccessAnimation: false,
      isProcessing: false,
      timeout: null,
      hasLoadedAWebsite: false
    };

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
  }

  start() {
    // if (isAnimating) return;
    clearTimeout(this.state.timeout);

    let timeout = setTimeout(() => {
      this.setState({
        isAnimating: true,
        timeout: null
      });
    });

    this.setState({
      isAnimating: false,
      isProcessing: true,
      isInSuccessAnimation: false,
      timeout
    });
  }

  stop(isForced = false) {
    const { isInSuccessAnimation } = this.state;

    // if (!isAnimating) return;

    if (isForced) {
      clearTimeout(this.state.timeout);
      this.setState({
        isAnimating: false,
        isInSuccessAnimation: false,
        timeout: null
      });
    } else {
      if (isInSuccessAnimation) return;

      if (this.state.timeout) clearTimeout(this.state.timeout);

      let timeout = setTimeout(() => {
        this.setState({
          isAnimating: false,
          isInSuccessAnimation: false,
          isProcessing: false,
          timeout: null
        });
      }, 300);

      this.setState({
        isInSuccessAnimation: true,
        timeout
      });
    }
  }

  finishWebsiteLoad = () => {
    this.setState({
      hasLoadedAWebsite: true
    });
  };

  startWebsiteLoad = () => {
    this.setState({
      hasLoadedAWebsite: false
    });
  };

  render() {
    const { isProcessing } = this.state;

    const { start, stop } = this;

    let contextValue = {
      animationState: this.state,
      start,
      stop,
      isAnimating: isProcessing,
      finishWebsiteLoad: this.finishWebsiteLoad,
      startWebsiteLoad: this.startWebsiteLoad
    };
    return (
      <GlobalLoadingContext.Provider value={contextValue}>
        {this.props.children}
      </GlobalLoadingContext.Provider>
    );
  }
}

export const GlobalLoader = ({ className }) => {
  return (
    <GlobalLoadingContext.Consumer>
      {({ animationState }) => {
        const { isAnimating, isInSuccessAnimation, hasLoadedAWebsite } =
          animationState;
        let baseClasses = classNames({
          "ar-loading-bar-progress": true,
          animating: isAnimating,
          success: isInSuccessAnimation
        });

        return (
          <div
            className={`ar-loading-bar z-index-10 ${className ? className : ""} 
            ${hasLoadedAWebsite ? "subheader" : ""}`}
          >
            <div className={baseClasses} />
          </div>
        );
      }}
    </GlobalLoadingContext.Consumer>
  );
};

export const InlineBarLoader = ({ className, isLoading, style }) => {
  const timeoutRef = useRef();

  const [state, setState] = useState({});
  const hasMountedRef = useRef();
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (isLoading) {
      setState({ isAnimating: true });
    } else {
      if (hasMountedRef.current) {
        setState((state) => {
          return {
            ...state,
            isInSuccessAnimation: true
          };
        });

        timeoutRef.current = setTimeout(() => {
          setState({});
        }, 300);
      }
    }

    hasMountedRef.current = true;
  }, [isLoading]);

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const { isAnimating, isInSuccessAnimation } = state;
  let baseClasses = classNames({
    "ar-loading-bar-progress": true,
    animating: isAnimating,
    success: isInSuccessAnimation
  });

  return <div className={baseClasses} />;
};

export const BarLoader = (props) => {
  const { className, isLoading, style } = props;
  const timeoutRef = useRef();

  const [state, setState] = useState({});
  const hasMountedRef = useRef();
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (isLoading) {
      setState({ isAnimating: true });
    } else {
      if (hasMountedRef.current) {
        setState((state) => {
          return {
            ...state,
            isInSuccessAnimation: true
          };
        });

        timeoutRef.current = setTimeout(() => {
          setState({});
        }, 300);
      }
    }

    hasMountedRef.current = true;
    return () => clearTimeout(timeoutRef.current);
  }, [isLoading]);

  const { isAnimating, isInSuccessAnimation } = state;
  let baseClasses = classNames({
    "ar-loading-bar-progress": true,
    animating: isAnimating,
    success: isInSuccessAnimation
  });

  return (
    <div
      style={style}
      className={`ar-loading-bar absolute z-index-10 ${
        className ? className : ""
      }`}
    >
      <div className={baseClasses} />
    </div>
  );
};

export const withGlobalLoader = (WrappedComponent) => {
  return (props) => {
    const loaderState = useContext(GlobalLoadingContext);

    useEffect(() => {
      return () => {
        if (loaderState.isAnimating) loaderState.stop(true);
      };
    }, [loaderState]);

    return <WrappedComponent {...props} globalLoader={loaderState} />;
  };
};

export const useGlobalLoader = () => {
  return useContext(GlobalLoadingContext);
};

export default GlobalLoader;

// const LoadingBar = () => {
//     return (
//         <div></div>
//     );
// }

// export default LoadingBar;
