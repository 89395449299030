import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getResolvedColumnWidth } from "./VirtualGridHelper";

const ResizerContext = React.createContext();

const SizeContext = React.createContext();

export const useColumnsSize = () => useContext(SizeContext);

const StickyInfoContext = React.createContext();

export const useStickyInfo = () => useContext(StickyInfoContext);

export const ResizeProvider = ({
  columnsSize,
  onColumnsSizeChange,
  children,
  columns,
}) => {
  const [tempColumnsSize, setTempColumnsSize] = useState(
    () => columnsSize || {}
  );

  const mountedRef = useRef(false);

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      return;
    }

    setTempColumnsSize(columnsSize || {});
  }, [columnsSize]);

  const tempColumnsSizeRef = useRef();
  useLayoutEffect(() => {
    tempColumnsSizeRef.current = tempColumnsSize;
  }, [tempColumnsSize]);

  const commitSizeChange = useCallback(() => {
    onColumnsSizeChange(tempColumnsSizeRef.current);
  }, [onColumnsSizeChange]);

  const resizerValue = useMemo(() => {
    return {
      setTempColumnsSize,
      commitSizeChange,
    };
  }, [commitSizeChange]);

  const stickyInfo = useStickyInfoComputer(columns, tempColumnsSize);

  return (
    <ResizerContext.Provider value={resizerValue}>
      <SizeContext.Provider value={tempColumnsSize}>
        <StickyInfoContext.Provider value={stickyInfo}>
          {children}
        </StickyInfoContext.Provider>
      </SizeContext.Provider>
    </ResizerContext.Provider>
  );
};
const useColumnWidthDict = (columns, columnsSize) => {
  return useMemo(() => {
    const dict = {};
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      const { field, 
				// width 
			} = column;
      const overwriteWidth = columnsSize && columnsSize[field];
      dict[i] = getResolvedColumnWidth(column, overwriteWidth);
    }
    return dict;
  }, [columns, columnsSize]);
};

const useStickyInfoComputer = (rColumns, columnsSize) => {
  const widthDict = useColumnWidthDict(rColumns, columnsSize);
  const { totalSticky, leftDict } = useMemo(() => {
    const leftDict = {};
    let totalSticky = 0;
    let accumulatedLeft = 0;
    for (let i = 0; i < rColumns.length; i++) {
      const column = rColumns[i];
      const width = widthDict[i];
      if (column.sticky) {
        leftDict[i] = accumulatedLeft;
        accumulatedLeft += width;
        totalSticky++;
      }
    }

    return { totalSticky, rColumns, leftDict };
  }, [rColumns, widthDict]);

  return { widthDict, rColumns, totalSticky, leftDict };
};

export const ColumnResizer = ({ field, size, minSize }) => {
  const sizeRef = useRef();

  const { setTempColumnsSize, commitSizeChange } = useContext(ResizerContext);

  const [baseX, setBaseX] = useState();
  const baseSizeRef = useRef();
	
  const handleEnter = (e) => {
    baseSizeRef.current = size;
    setBaseX(e.clientX);
  };

  useEffect(() => {
    if (baseX === undefined) return;

    const moveListener = (e) => {
      const { clientX: newClientX } = e;
      const incrementedSize = newClientX - baseX;

      let newSize = baseSizeRef.current + incrementedSize;
      if (newSize < minSize) newSize = minSize;

      sizeRef.current = newSize;
      setTempColumnsSize((colsSizes) => {
        return {
          ...colsSizes,
          [field]: newSize,
        };
      });
      //   console.log("moved");
    };

    document.addEventListener("mousemove", moveListener);

    const upListener = () => {
      if (sizeRef.current) commitSizeChange();
      setBaseX(undefined);
    };

    document.addEventListener("mouseup", upListener);

    return () => {
      document.removeEventListener("mousemove", moveListener);
      document.removeEventListener("mouseup", upListener);
    };
  }, [baseX, commitSizeChange, field, minSize, setTempColumnsSize]);

  return (
    <div
      onMouseDown={handleEnter}
      onClick={(e) => e.stopPropagation()}
      className="ar-virtual-grid-resizer"
    ></div>
  );
};
