import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useGridRequest } from "../../../Components/CGrid/ServerGrid";
import EmptyListContainer from "../../../Components/EmptyListContainer/EmptyListContainer";
import EmptyListError from "../../../Components/EmptyListContainer/EmptyListError";
import { VirtualGridRow } from "../../../Components/VirtualGrid/VirtualGridBody";
import VirtualServerAwareGrid from "../../../Components/VirtualGrid/VirtualServerAwareGrid";
import { useApiToken } from "../../../Hooks/EntityHooks";
import { ApiTokensFormButton } from "../../Form/FormButtons";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./ApiKeys.module.css";
import {
  faCheckCircle,
  faExclamationCircle
} from "@fortawesome/pro-solid-svg-icons";
import { ApiKeysColumns } from "./ApiKeys";
import { useSpace } from "../../../Contexts/SpaceContext";

const EmailDomainsRow = React.memo(({ value, ...rest }) => {
  const v = useApiToken(value);
  // const canSelectRow = useCallback((v) => !v.EmailDomain, []);
  return <VirtualGridRow value={v} {...rest} canSelectRow={false} />;
});

export const ApiKeysState = ({
  text,
  textId,
  state,
  className,
  noBackground
}) => {
  const intl = useIntl();
  return (
    <div className={classnames("d-flex align-items-center", className)}>
      {(text || textId) && (
        <span
          className={classnames("mr-2 fw-medium", {
            "text-danger-alt": !state
          })}
        >
          {textId ? intl.formatMessage({ id: textId }) : text}
        </span>
      )}
      <div
        style={{ background: noBackground ? "none" : undefined }}
        className={classnames(classes.Badge, "d-flex align-items-center", {
          [classes.error]: !state
        })}
      >
        <FontAwesomeIcon
          className="mr-2"
          icon={state ? faCheckCircle : faExclamationCircle}
        />
        <div className={`${classes.text} fs-12 fw-medium`}>
          <FormattedMessage id={state ? "ACTIVE" : "INACTIVE"} />
        </div>
      </div>
    </div>
  );
};

// const useDomainRefresh = ({id, DomainKeyType})=>{
// 	const {data, loading, error} = useSpacePost(`EmailDomain/${id}/Refresh/${DomainKeyType}`)

// }

const canSelectRow = (value) => {
  if (!value) return false;

  return true;
};

const ApiKeyGrid = ({ onItemSelect, ...rest }) => {
  const Workspace = useSpace();

  const { error, results } = useGridRequest();

  if (error) return <EmptyListError />;

  if (results && results.length === 0)
    return (
      <EmptyListContainer type="interventions">
        <div className="fw-bold fs-24 text-black ">
          <FormattedMessage id="EMPTY_API_KEYS" />
        </div>
        <div className="fs-18 text-black text-center mb-4">
          <FormattedMessage id="NO_API_KEYS" />
        </div>
        {Workspace.CurrentPId > 2 && <ApiTokensFormButton />}
      </EmptyListContainer>
    );

  return (
    <VirtualServerAwareGrid
      onItemSelect={onItemSelect}
      canSelectRow={canSelectRow}
      rowComponent={EmailDomainsRow}
      columns={ApiKeysColumns}
      {...rest}
    />
  );
};

export default ApiKeyGrid;
