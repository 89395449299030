import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { InfoAlertAlt, WarningAlert } from "../../Components/Alerts/Alerts";
import Button from "../../Components/Button/Button";
import ArDatePicker from "../../Components/DatePicker/ArDatePicker";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";
import { useFormState } from "../../Components/Forms";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../Components/Forms/SideMenuForm";
import NumberInput from "../../Components/Input/NumberInput";
import { formatTaskForm } from "../../Helpers/FormFormmaterHelper";
import {
  buildRecurrenceForm,
  getRecurrentTasksDates,
  MonthWeekTypes,
  RecurrencyDaysDropdown,
  RecurrencyMonthsDropdown,
  RecurrencyMonthWeekDropdown,
  RecurrencyType
} from "./TaskRecurrenciesHelper";
import { useTaskPost } from "./TasksHelper";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/pro-regular-svg-icons";
import { originTypes } from "../../Helpers/MiscHelper";
import {
  spaceCallsSchema,
  spaceClientSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceProjectsSchema,
  spaceTasksSchema,
  spaceTicketsSchema
} from "../../config/schema";
import { useSchemaQuery } from "../../Helpers/IOClient";
import { useEntityValue } from "../../Hooks/EntityHooks";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";

const options = [
  RecurrencyType.Daily,
  RecurrencyType.Weekly,
  RecurrencyType.Monthly,
  RecurrencyType.Yearly
];
const transDict = {
  [RecurrencyType.Daily]: "DAILY",
  [RecurrencyType.Weekly]: "WEEKLY",
  [RecurrencyType.Monthly]: "MONTHLY",
  [RecurrencyType.Yearly]: "YEARLY"
};

const RecurrencyValue = ({ item }) => {
  return (
    <div>
      <FormattedMessage id={transDict[item]} />
    </div>
  );
};
const RecurrencyDropdown = ({ ...rest }) => {
  return (
    <KeyedDropdown
      className="fs-12 text-truncate"
      options={options}
      dropdownClassname="text-truncate"
      valueComponent={RecurrencyValue}
      closeOnSelect
      {...rest}
    />
  );
};

const RecurrencyDailyInputs = () => {
  return (
    <div className="d-flex align-items-center text-black mb-3">
      <div className="mr-2 fs-14 text-black">
        <FormattedMessage id="RECURRENCY_EVERY" />
      </div>
      <FormInput
        name="Every"
        className="mr-2"
        style={{ width: 72 }}
        inputType={NumberInput}
      />
      <div className="fs-14 text-black">
        <FormattedMessage id="RECURRENCY_DAYS" />
      </div>
    </div>
  );
};

const RecurrencyWeeklyInputs = () => {
  return (
    <>
      <div className="d-flex align-items-center text-black mb-3">
        <div className="mr-2 fs-14 text-black">
          <FormattedMessage id="RECURRENCY_EVERY" />
        </div>
        <FormInput
          name="Every"
          className="mr-2"
          style={{ width: 72 }}
          inputType={NumberInput}
        />
        <div className="fs-14 text-black">
          <FormattedMessage id="RECURRENCY_WEEKS" />
        </div>
      </div>

      <div className="d-flex align-items-center text-black mb-3">
        <div className="mr-2 fs-14 text-black">
          <FormattedMessage id="RECURRENCY_ON" />
        </div>
        <FormInput
          name="WeekDays"
          className="mr-2 w-100"
          multiple
          removable
          inputType={RecurrencyDaysDropdown}
        />
      </div>
    </>
  );
};

const RecurrencyMonthlyInputs = ({ form, recurrentDates }) => {
  const { MonthWeekType, MonthDay } = form;

  return (
    <>
      <div className="d-flex align-items-center text-black mb-3">
        <div className="mr-2 fs-14 text-black">
          <FormattedMessage id="RECURRENCY_EVERY" />
        </div>
        <FormInput
          name="Every"
          className="mr-2"
          style={{ width: 72 }}
          inputType={NumberInput}
        />
        <div className="fs-14 text-black">
          <FormattedMessage id="RECURRENCY_MONTHS" />
        </div>
      </div>

      <div className="d-flex align-items-center text-black mb-3">
        <div className="mr-2 fs-14 text-black">
          <FormattedMessage id="RECURRENCY_ON" />
        </div>
        <FormInput
          name="MonthWeekType"
          className="mr-3"
          style={{ width: 150 }}
          inputType={RecurrencyMonthWeekDropdown}
        />
        {MonthWeekType === MonthWeekTypes.Day ? (
          <FormInput
            name="MonthDay"
            className="w-100"
            inputType={NumberInput}
          />
        ) : (
          <FormInput
            closeOnSelect
            name="MonthWeekDay"
            className="w-100"
            inputType={RecurrencyDaysDropdown}
          />
        )}
      </div>
      {MonthWeekType === MonthWeekTypes.Day &&
        MonthDay > 28 &&
        MonthDay < 32 && (
          <MonthDayWarning day={MonthDay} recurrentDates={recurrentDates} />
        )}
    </>
  );
};

const RecurrencyYearlyInputs = ({ form }) => {
  const { MonthWeekType } = form;

  return (
    <>
      <div className="d-flex align-items-center text-black mb-3">
        <div className="mr-2 fs-14 text-black">
          <FormattedMessage id="RECURRENCY_EVERY" />
        </div>
        <FormInput
          name="Every"
          className="mr-2"
          style={{ width: 72 }}
          inputType={NumberInput}
        />
        <div className="fs-14 text-black">
          <FormattedMessage id="RECURRENCY_YEARS" />
        </div>
      </div>

      <div className="d-flex align-items-center text-black mb-3">
        <div className="mr-2 fs-14 text-black">
          <FormattedMessage id="RECURRENCY_ON" />
        </div>
        <FormInput
          name="MonthWeekType"
          className="mr-3 w-100"
          // style={{ width: 150 }}
          inputType={RecurrencyMonthWeekDropdown}
        />
        {MonthWeekType === MonthWeekTypes.Day ? (
          <FormInput
            name="MonthDay"
            className="w-100 mr-3"
            inputType={NumberInput}
          />
        ) : (
          <FormInput
            closeOnSelect
            name="MonthWeekDay"
            className="w-100 mr-3"
            inputType={RecurrencyDaysDropdown}
          />
        )}
        <FormInput
          name="Month"
          className="w-100"
          style={{ width: 150 }}
          inputType={RecurrencyMonthsDropdown}
        />
      </div>
    </>
  );
};

const RecurrencyInputs = ({ type, form, recurrentDates }) => {
  switch (type) {
    case RecurrencyType.Daily:
      return <RecurrencyDailyInputs form={form} />;

    case RecurrencyType.Weekly:
      return <RecurrencyWeeklyInputs form={form} />;

    case RecurrencyType.Monthly:
      return (
        <RecurrencyMonthlyInputs form={form} recurrentDates={recurrentDates} />
      );

    case RecurrencyType.Yearly:
      return (
        <RecurrencyYearlyInputs form={form} recurrentDates={recurrentDates} />
      );

    default:
      return null;
  }
};

function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export const validateTaskRecurrencyForm = (
  {
    Type,
    EndDate,
    Every,
    WeekDays,
    MonthWeekType,
    MonthDay,
    MonthWeekDay,
    Month
  },
  addError
) => {
  if (Type === RecurrencyType.Monthly || Type === RecurrencyType.Yearly) {
    if (MonthWeekType === MonthWeekTypes.Day && (MonthDay > 31 || MonthDay < 1))
      addError("MonthDay");
  }
};

const MonthDayWarning = ({ recurrentDates, day }) => {
  const shouldRender = useMemo(() => {
    if (recurrentDates.length === 0) return false;

    for (const date of recurrentDates) {
      const daysInMonth = getDaysInMonth(date.getMonth(), date.getFullYear());
      if (day > daysInMonth) return true;
    }

    return false;
  }, [day, recurrentDates]);
  if (!shouldRender) return null;
  return (
    <WarningAlert className="p-2 fs-14 mb-3">
      <FormattedMessage id="RECURRENCY_MONTH_WARNING" values={{ day }} />
      {/* Há meses que têm menos de {day} dias, nesses casos será criada a tarefa no
      último dia do mês. */}
    </WarningAlert>
  );
};

const shouldPreview = (form) => {
  const { Every, Type, MonthWeekType, MonthDay } = form;

  if (!Every) return false;

  if (
    (Type === RecurrencyType.Monthly || Type === RecurrencyType.Yearly) &&
    MonthWeekType === MonthWeekTypes.Day &&
    !MonthDay
  )
    return false;

  return true;
};

const RecurrentDatesPreview = ({ recurrentDates, durationMill }) => {
  if (recurrentDates.lenth === 0) return null;

  return (
    <div>
      <div className="mb-2 fw-medium text-black">
        <FormattedMessage id="PREVIEW" />
      </div>

      <div className="mb-2 text-black">
        <FormattedMessage
          id="RECURRENCY_WILL_CREATE"
          values={{
            count: recurrentDates.length,
            b: (...chunks) => <b>{chunks}</b>
          }}
        />
      </div>

      <InfoAlertAlt className="p-3">
        <div style={{ margin: "-.5rem 0" }}>
          {recurrentDates.map((d, i) => {
            const Begin = moment(d);

            const End = moment(d);
            End.add(durationMill, "milliseconds");
            const BeginFormatted = Begin.format("DD/MM/YYYY HH:mm");
            const EndFormatted = End.format("DD/MM/YYYY HH:mm");

            return (
              <div className="d-flex align-items-center my-2" key={d.getTime()}>
                <b className="mr-2">{i + 1}.</b>
                <div className="d-flex align-items-center justify-content-between flex-1 ml-3">
                  <div>{BeginFormatted}</div>
                  <FontAwesomeIcon
                    className="mx-3 text-primary"
                    icon={faLongArrowAltRight}
                  />
                  <div className="text-right">{EndFormatted}</div>
                </div>
              </div>
            );
          }, [])}
        </div>
      </InfoAlertAlt>
    </div>
  );
};

const useTaskTargetQuery = (tasktarget) => {
  const { type, data: value } = tasktarget;
  const schema = useMemo(() => {
    switch (type) {
      case originTypes.project:
        return spaceProjectsSchema;

      case originTypes.ticket:
        return spaceTicketsSchema;
      case originTypes.call:
        return spaceCallsSchema;
      case originTypes.task:
        return spaceTasksSchema;
      case originTypes.contract:
        return spaceContractSchema;
      case originTypes.client:
        return spaceClientSchema;
      case originTypes.deals:
        return spaceDealsSchema;
      default:
        break;
    }
  }, [type]);

  const { data, loading, error } = useSchemaQuery(schema, value);

  const resolvedValue = useEntityValue(schema, data);

  return { loading, error, data: resolvedValue };
};

const TaskRecurrenciesForm = ({ taskForm, ...rest }) => {
  const { TaskTarget } = taskForm;

  const { loading, error, data } = useTaskTargetQuery(TaskTarget);

  if (loading || error) return <LoaderSpinner size="sm" center />;

  const { EndDate: originEndDate } = data;

  return (
    <InnerTaskRecurrenciesForm
      originEndDate={originEndDate}
      taskForm={taskForm}
      {...rest}
    />
  );
};

const InnerTaskRecurrenciesForm = ({
  taskForm,
  onSuccess,
  setSettingRecurrence,
  originEndDate
}) => {
  const { BeginDate, EndDate, TaskTarget } = taskForm;

  const formState = useFormState(() => buildRecurrenceForm(BeginDate));

  const { form } = formState;
  const { Type, EndDate: formEndDate } = form;

  const durationMill = useMemo(() => {
    return new Date(EndDate).getTime() - new Date(BeginDate).getTime();
  }, [BeginDate, EndDate]);

  const recurrentDates = useMemo(() => {
    if (!shouldPreview(form)) return [];
    return getRecurrentTasksDates(form, new Date(BeginDate));
  }, [BeginDate, form]);

  if (recurrentDates.length) {
    console.log(getRecurrentTasksDates(form, new Date(BeginDate)));
  }
  const [post, { loading }] = useTaskPost({ TaskTarget, id: null, onSuccess });

  const handleSubmit = (form) => {
    post(formatTaskForm(taskForm, { recurrencies: form }));
  };

  return (
    <SideForm
      // canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateTaskRecurrencyForm}
    >
      <SideFormContent className="p-3">
        <div className="mb-3 text-black fs-16 fw-medium">
          <FormattedMessage id={"RECURRENCE_CONFIGURATION"} />
        </div>

        <FormInput
          name={"Type"}
          textId="REPEATS"
          className="mb-3"
          inputType={RecurrencyDropdown}
          // value={TaskTarget}
          allClients={false}
          // Task={id}
          // onChange={handleChanges}
        />

        <RecurrencyInputs
          form={form}
          type={Type}
          recurrentDates={recurrentDates}
        />

        <div className="mb-3">
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="mb-1 fs-14 w-100 disable-selection text-black">
              <FormattedMessage id="START"/>
            </div>
            <div></div>
          </div>
          <div className="fw-14 text-black w-100">
            <div>{moment(BeginDate).format("DD/MM/YYYY")}</div>
          </div>
        </div>

        {/* <FormInput
          className="mb-3"
          // name="EndDate"
          textId="START"
          inputType={ArDatePicker}
          componentClassName="w-100"
          value={BeginDate}
          preview
        /> */}

        <FormInput
          className="mb-4"
          name="EndDate"
          textId="END"
          inputType={ArDatePicker}
          enableHours={false}
          componentClassName="w-100"
          // disableDates={true}
          // disablePastDate={BeginDate}
          // openHourAfterDate
        />

        {originEndDate &&
          formEndDate.getTime() > new Date(originEndDate).getTime() && (
            <WarningAlert className="p-2 fs-14 mb-3">
              <FormattedMessage id="RECURRENCY_ORIGIN_DATE_WARNING" />
            </WarningAlert>
          )}

        {recurrentDates.length === 100 && (
          <WarningAlert className="p-2 fs-14 mb-3">
            <FormattedMessage id="RECURRENCY_LIMIT_EXCEEDED" />
          </WarningAlert>
        )}

        <RecurrentDatesPreview
          recurrentDates={recurrentDates}
          durationMill={durationMill}
        />
      </SideFormContent>
      <SideFormFooter
        disabled={recurrentDates.length === 0}
        subComponent={
          <div className="">
            <Button
              vType="link-primary"
              onClick={() => setSettingRecurrence(false)}
            >
              <FormattedMessage id="BACK" />
            </Button>
          </div>
        }
        isLoading={loading}
      ></SideFormFooter>
    </SideForm>
  );
};

export default TaskRecurrenciesForm;
