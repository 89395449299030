import Auth from "./AuthReducer";
import Pages from "./Pages";
import Entities from "./Entities";
import EntityDetails from "./EntityDetails";
import CurrentUser from "./CurrentUserReducer";
import SpaceGridViews from "./SpaceGridViews";
import Forms from "./Forms";
import InviteManager from "./InviteManagerReducer";
import Notifications from "./NotificationsReducer";
// import SpaceUsers from "./SpaceUsersReducer";

import AccountSpaceMapper from "./AccountSpaceMapperReducer";

import { combineReducers } from "redux";

export default combineReducers({
  Auth,
  Pages, // necessário até atualizar vistas legacy (login, register)
  Entities,
  EntityDetails, // necessário até atualizar settings/users
  SpaceGridViews, // necessário até atualizar settings/users
  Forms, // necessário até atualizar settings/users
  CurrentUser,
  AccountSpaceMapper,
  InviteManager,
  Notifications // necessário até atualizar setup de notificações
});
