import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect
} from "react";
import { useSpace } from "../../Contexts/SpaceContext";
import { NavLink } from "react-router-dom";
import "./HeaderV2.css";
import {
  useSpaceMainView,
  useSpaceRoutesAccess
} from "../../Containers/Space/SpaceHelper";
import { getAlterEgo } from "../../Helpers/AuthHelper";
import classnames from "classnames";
import {
  MainViewsSearchLSKey,
  MainViewsSearchCommunicator,
  NewMainViewsSearchLSKey,
  LastUsedSpaceLSKey
} from "../../Helpers/MiscHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUnreadEntities } from "../UnreadEntities/UnreadEntities";
import { ObfuscateProvider } from "../../Containers/Space/Space2";
import { useIntl } from "react-intl";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";

const HeaderActiveGroupContext = React.createContext();

export const useHeaderActiveGroup = () => useContext(HeaderActiveGroupContext);

export const MainHeaderSection = ({ children }) => {
  const sectionRef = useRef();
  const [activeGroup, setActiveGroup] = useState(null);

  const toggleActiveGroup = useCallback((group) => {
    setActiveGroup((activeGroup) => {
      return activeGroup === group ? null : group;
    });
  }, []);

  const isGroupActive = Boolean(activeGroup);

  useEffect(() => {
    if (!isGroupActive) return;

    const listner = (e) => {
      if (!sectionRef.current.contains(e.target)) setActiveGroup(null);
    };

    document.addEventListener("click", listner);

    return () => document.removeEventListener("click", listner);
  });

  const ActiveGroupValue = useMemo(() => {
    return {
      activeGroup,
      toggleActiveGroup
    };
  }, [activeGroup, toggleActiveGroup]);

  const ObfuscateState = useContext(ObfuscateProvider);
  const handleClick = (e) => {
    if (ObfuscateState === true) e.preventDefault();
  };

  return (
    <HeaderActiveGroupContext.Provider value={ActiveGroupValue}>
      <ul
        ref={sectionRef}
        onClick={handleClick}
        style={{
          opacity: ObfuscateState && 0.3,
          pointerEvents: ObfuscateState === true && "none"
        }}
        className="ar-header-main-section"
      >
        {children}
      </ul>
    </HeaderActiveGroupContext.Provider>
  );
};

const HeaderLinkUnreadCount = ({ unreadEntityName }) => {
  const data = useUnreadEntities();
  const intl = useIntl();
  if (!data) return null;

  const count = data[`Unread${unreadEntityName}`];
  if (!count) return null;

  return (
    <div
      title={intl.formatMessage({ id: "NOT_READ" })}
      className="ssi-unread-count-pill ml-2"
    >
      {count}
    </div>
  );
};

const parseParams = (querystring) => {
  // parse query string
  const params = new URLSearchParams(querystring);

  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  delete obj["favoriteId"];
  delete obj["query"];

  const qs = Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");

  return qs;
};

export const HeaderLink = ({
  routeModule,
  onClick,
  viewDict,
  hideIcon,
  forcedChildren
}) => {
  const { Id: spaceId } = useSpace();

  // const { path, text, schema, icon, lsKey, unreadEntityName } = routeModule;
  const {
    path,
    text,
    icon,
    unreadEntityName,
    hasAccess,
    schema
    // moduleType
  } = routeModule;

  const dict = useMainViewSearchDict();

  const search =
    schema && dict && dict[schema.name] ? "?" + dict[schema.name] : "";

  // const searchDict = useContext(MainViewPreferencesContext);

  // const search = searchDict[moduleType] || "";

  // const basePath = `/s/${spaceId}/${path}${search}`;

  const resolvedSearch = search.length > 0 ? "?" + parseParams(search) : "";

  const basePath = `/s/${spaceId}/${path}${resolvedSearch}`;

  // let search;

  // if ((lsKey || schema) && dict) search = dict[lsKey ? lsKey : schema.name];

  let resolvedPath = basePath;
  // if (search) resolvedPath += `?${search}`;

  const currentView = useSpaceMainView();

  const isSelected = currentView === path;

  const currentAccountSpace = useCurrentAccountSpace();

  if (typeof hasAccess === "function" && !hasAccess(currentAccountSpace))
    return null;

  if (forcedChildren) {
    return (
      <li
        style={{ marginLeft: 14 }}
        className={isSelected ? "selected" : undefined}
      >
        <NavLink
          onClick={onClick}
          className="ar-header-rounded-icon alternative-color disable-selection"
          activeClassName="selected"
          to={resolvedPath}
        >
          {forcedChildren}
        </NavLink>
      </li>
    );
  }

  return (
    <li className={isSelected ? "selected" : undefined}>
      <NavLink
        onClick={onClick}
        className="ar-header-link  disable-selection"
        activeClassName="selected"
        to={resolvedPath}
      >
        {!hideIcon && icon && (
          <div className="ar-header-link-icon">
            <FontAwesomeIcon icon={icon} />
          </div>
        )}
        <span className="disable-selection">{text}</span>
        {unreadEntityName && (
          <HeaderLinkUnreadCount unreadEntityName={unreadEntityName} />
        )}
      </NavLink>
    </li>
  );
};

export const HeaderNavLink = ({
  routeModule,
  className,
  children,
  activeClassName
}) => {
  const { Id: spaceId } = useSpace();

  const { path } = routeModule;
  const accessDict = useSpaceRoutesAccess();
  const ObfuscateState = useContext(ObfuscateProvider);
  const handleClick = (e) => {
    if (ObfuscateState === true) e.preventDefault();
  };
  const asAccess = useMemo(() => {
    return accessDict[routeModule.path];
  }, [accessDict, routeModule.path]);

  if (!asAccess) return null;

  return (
    <NavLink
      onClick={handleClick}
      style={{ pointerEvents: ObfuscateState === true && "none" }}
      className={className || "ar-header-link"}
      activeClassName={activeClassName || "selected"}
      to={`/s/${spaceId}/${path}`}
    >
      {children}
    </NavLink>
  );
};

const isInAlterEgo = Boolean(getAlterEgo());

export const MainViewSearchContext = React.createContext();
export const useMainViewSearchDict = () => useContext(MainViewSearchContext);

const HeaderV2 = ({
  children,
  disableHeader,
  objuscateButtons,
  absoluteHead
}) => {
  const [dict, setDict] = useState(() => {
    const lastUsedSpace = localStorage.getItem(LastUsedSpaceLSKey);

    const NewDictString = localStorage.getItem(
      NewMainViewsSearchLSKey + lastUsedSpace
    );
    const NullNewDictString = localStorage.getItem(
      NewMainViewsSearchLSKey + "null"
    );

    const dictString = localStorage.getItem(MainViewsSearchLSKey);

    if (NewDictString) return JSON.parse(NewDictString);
    else if (NullNewDictString) {
      localStorage.setItem(
        NewMainViewsSearchLSKey + lastUsedSpace,
        NullNewDictString
      );
      localStorage.removeItem(NewMainViewsSearchLSKey + "null");
      return JSON.parse(NullNewDictString);
    } else if (dictString) {
      localStorage.setItem(NewMainViewsSearchLSKey + lastUsedSpace, dictString);
      localStorage.removeItem(MainViewsSearchLSKey);
      return JSON.parse(dictString);
    } else return {};
  });

  useEffect(() => {
    return MainViewsSearchCommunicator.subscribe((newDict) => {
      setDict(newDict);
    });
  }, []);
  // const space = useSpace();

  // const [welcomeBarState, toggleWelcomeBarState] = useContext(
  //   HeaderWelcomeBarContext
  // );

  // const history = useHistory();

  // const showBar = useContext(HeaderWelcomeBarViewContext);

  // const { data, loading } = useMaintenanceState() || {};

  return (
    <MainViewSearchContext.Provider value={dict}>
      <nav
        style={{
          backgroundColor:
            process.env.NODE_ENV !== "production"
              ? window.location.hostname === "localhost"
                ? "rgb(133 53 58)"
                : "black"
              : ""
        }}
        className={classnames("ar-header", {
          ego: isInAlterEgo,
          "d-none": disableHeader,
          "position-absolute": absoluteHead
        })}
      >
        {children}
      </nav>
    </MainViewSearchContext.Provider>
  );
};

export default HeaderV2;
