import React, { useState } from "react";
// import SplitCol from "../../../Components/SplitCol/SplitCol";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import withSpaceEntityForm from "../../../Components/Forms";
import { withSpace } from "../../../Contexts/SpaceContext";
import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import { spaceIneficientTypesSchema } from "../../../config/schema";
import SideMenuForm from "../../../Components/Forms/SideMenuForm";
import { OrganizationDropdown } from "../../AdvancedMultiInputs";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import Button from "../../../Components/Button/Button";
import LoadableButton from "../../../Components/Button/LoadableButton";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";

const RemovalFooter = ({ endpoint, refetchRef, onCancel, Name }) => {
  const createToast = useToast();
  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceIneficientTypesSchema);
  const [deleteUser, { loading }] = useSidebarSpaceDelete(
    endpoint,
    spaceIneficientTypesSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({
            id: "INEFFICIENT_TIME_TYPE"
          })} ${Name}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  return (
    <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
      <div className="text-black">
        <FormattedMessage id="ELIMINATE_INEFICIENT_TYPE" />
      </div>

      <div className="d-flex align-items-center">
        <Button vType="outline-danger" className="mr-3" onClick={onCancel}>
          <FormattedMessage id="BACK" />
        </Button>

        <LoadableButton
          vType="danger"
          type="button"
          isLoading={loading}
          onClick={() => {
            deleteUser();
          }}
        >
          <FormattedMessage id="DELETE" />
        </LoadableButton>
      </div>
    </div>
  );
};

const IneficientTypesForm = ({ refetchRef, ...props }) => {
  // const handleChange = (e) => {
  //   const { setFormState } = props;
  //   const { name, value } = e.target;
  //   setFormState({
  //     [name]: value,
  //   });
  // };

  const [isDeleting, setIsDeleting] = useState(false);
  const { formStatus, form } = props;
  const { Id, CanDelete, Name } = form;
  // let leftpercent = 60;

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id="INEFFICIENT_TIME_TYPE" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideMenuForm
          formStatus={formStatus}
          footerComponent={
            !Id ? (
              <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
                <div></div>
                <LoadableButton
                  isLoading={formStatus.isProcessing}
                  onClick={formStatus.submit}
                >
                  <FormattedMessage id={"CONFIRM"} />
                </LoadableButton>
              </div>
            ) : !isDeleting ? (
              <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
                {CanDelete ? (
                  <Button
                    vType="link-danger fw-bold"
                    className="mr-3"
                    onClick={() => setIsDeleting(true)}
                  >
                    <span style={{ fontWeight: 500 }}>
                      <FormattedMessage id="DELETE" />
                    </span>
                  </Button>
                ) : (
                  <div></div>
                )}
                <LoadableButton
                  isLoading={formStatus.isProcessing}
                  onClick={formStatus.submit}
                >
                  <FormattedMessage id={"CONFIRM"} />
                </LoadableButton>
              </div>
            ) : (
              CanDelete && (
                <RemovalFooter
                  Name={Name}
                  endpoint={`TimeDetail/settingsInterventions/1/${Id}`}
                  refetchRef={refetchRef}
                  onCancel={() => setIsDeleting(false)}
                />
              )
            )
          }
        >
          <FormInput
            name="Organizations"
            textId="COMPANIES"
            className="mb-3"
            inputType={OrganizationDropdown}
            closeOnSelect={false}
            multiple
          />

          <FormInput name="Name" textId="NAME" className="mb-3" />
        </SideMenuForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};
export default withSpaceEntityForm(withSpace(injectIntl(IneficientTypesForm)), {
  defaultForm: {
    Organizations: [],
    Name: ""
  },
  validateForm: ({ Name, Organizations }, addError) => {
    // const { Name, Email, PhoneNumber } = form;
    // let isNameValid = Name.length > 0;
    // let isPhoneNumberValid = isValidPhoneNumber(PhoneNumber.value);
    // let isEmailValid = isEmail(Email);
    if (Organizations.length === 0) addError("Organizations");
    if (!Name) addError("Name");
    // if (!isPhoneNumberValid) addError("PhoneNumberError");
    // if (!isEmailValid) addError("EmailError");
  },
  onSuccess: (props) => {
    const { createToast, intl, form } = props;
    const { Name } = form;
    createToast({
      pos: "tm",
      type: "success",
      title: `${intl.formatMessage({ id: "INEFFICIENT_TIME_TYPE" })} ${Name}`,
      description: `${intl.formatMessage({
        id: "SUCCESS_SAVED"
      })}`
    });
    // handleToast(props)
  },
  schema: spaceIneficientTypesSchema,
  // getEntityId: (state, ownProps) => ownProps.contractTypeId,
  formatForm: (form) => {
    const { ...restForm } = form;

    return {
      ...restForm
    };
  }
});
