import {
  MonthWeekTypes,
  RecurrencyType
} from "../Containers/Tasks/TaskRecurrenciesHelper";
import {
  createFormData,
  toIsoString,
  originTypes,
  isValueExpression,
  canSubmitPhoneNumber
} from "./MiscHelper";
import { getEntity } from "./IOClient";
import {
  spaceTicketTagsSchema,
  spaceDealsTagsSchema,
  spaceCallsTagsSchema,
  spaceProjectTagsSchema,
  spaceTaskTagsSchema
} from "../config/schema";

export const convertInterventionTarget = (target) => {
  if (!target) return undefined;

  switch (target.type) {
    case "Project":
      return { projectId: target.data };
    case "Task":
      return { taskId: target.data };
    case "Ticket":
      return { ticketId: target.data };
    case "Call":
      return { callId: target.data };
    case "Contract":
      return { contractId: target.data };
    case "Subscription":
      return { subscriptionId: target.data };
    case originTypes.deals:
      return { dealId: target.data };
    case "Client":
      return { clientId: target.data };
    default:
      break;
  }
};

export const getSnakeCaseTarget = (entity) => {
  const {
    project_id,
    task_id,
    client_id,
    deal_id,
    ticket_id,
    call_id,
    team_id,
    subscription_id,
    contract_id
  } = entity || {};

  if (task_id) {
    return {
      type: "Task",
      data: task_id
    };
  } else if (ticket_id) {
    return {
      type: "Ticket",
      data: ticket_id
    };
  } else if (call_id) {
    return {
      type: "Call",
      data: call_id
    };
  } else if (project_id) {
    return {
      type: "Project",
      data: project_id
    };
  } else if (client_id) {
    return {
      type: "Client",
      data: client_id
    };
  } else if (team_id) {
    return {
      type: "Team",
      data: team_id
    };
  } else if (deal_id) {
    return {
      type: "Team",
      data: deal_id
    };
  } else if (contract_id) {
    return {
      type: "Contract",
      data: contract_id
    };
  } else if (subscription_id) {
    return {
      type: "Subscription",
      data: subscription_id
    };
  } else {
    return null;
  }
};

export const getResultAddresses = (AddressSelector = {}) => {
  let Addresses = {
    Id: AddressSelector.Id || null,
    Address1: AddressSelector.Address1 || null,
    Address2: AddressSelector.Address2 || null,
    CountryId: AddressSelector.Country || null,
    Locality: AddressSelector.Locality || null,
    Latitude: AddressSelector.Latitude || null,
    Longitude: AddressSelector.Longitude || null,
    District: AddressSelector.District || null,
    PostalCode: AddressSelector.PostalCode || null
  };

  const clean = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  };
  clean(Addresses);
  // if (!Addresses[0].Id && !Addresses[0].Address1 && !Addresses[0].Address2 && !Addresses[0].Country
  // 	&& !Addresses[0].Locality && !Addresses[0].Latitude && !Addresses[0].Longitude && !Addresses[0].District && !Addresses[0].PostalCode) {
  // 	return [];
  // }
  if (Object.keys(Addresses).length === 0) return [];

  return [Addresses];
};

export const formatContactAutomationForm = ({ form }) => {
  const { GDPRType, ...rest } = form;

  const obj = { ...rest, gdpr_type: GDPRType };

  return convertObjKeysToSnakeCase(obj);
};

export const formatInterventionForm = (form) => {
  const {
    InterventionTarget,
    TotalPauses,
    Files,
    BeginDate,
    EndDate,
    InterventionType,
    Organization,
    AssignedAssociations,
    DeslocationTime,
    AssociatedFiles,
    Contact,
    AddressSelector,
    TimeDetail,
    InefficientDuration,
    TimeDetailDescription,
    Followers,
    ...formRest
  } = form;

  let projectId, taskId, clientId, ticketId, callId, contractId, dealId;
  let resolvedTotalPauses = TotalPauses ? TotalPauses : 0;
  let resolvedTotalTravel = DeslocationTime ? DeslocationTime : 0;
  let resolvedTotalIneficient = InefficientDuration ? InefficientDuration : 0;
  switch (InterventionTarget.type) {
    case "Project":
      projectId = InterventionTarget.data;
      break;
    case "Task":
      taskId = InterventionTarget.data;
      break;
    case "Client":
      clientId = InterventionTarget.data;
      break;
    case "Ticket":
      ticketId = InterventionTarget.data;
      break;
    case "Call":
      callId = InterventionTarget.data;
      break;
    case "Contract":
      contractId = InterventionTarget.data;
      break;
    case "Deal":
      dealId = InterventionTarget.data;
      break;
    default:
      break;
  }
  const resolvedFiles = Array.isArray(Files) ? [...Files] : [];
  if (Array.isArray(AssignedAssociations) && AssignedAssociations.length > 0) {
    for (const e of AssignedAssociations) {
      const asfile = AssociatedFiles.find((t) => t.Id === e);
      if (asfile) resolvedFiles.push(asfile);
    }
  }
  return createFormData(
    {
      ...formRest,
      ContactId: Contact || "",
      InefficientDuration: resolvedTotalIneficient || 0,
      DeslocationTime: resolvedTotalTravel || 0,
      TimeDetailId: resolvedTotalIneficient ? TimeDetail || "" : "",
      TimeDetailDescription: resolvedTotalIneficient
        ? TimeDetailDescription
        : "",
      Followers: JSON.stringify(Followers),
      projectId,
      callId,
      taskId,
      clientId,
      ticketId,
      contractId,
      Addresses: JSON.stringify(getResultAddresses(AddressSelector)),
      dealId,
      InterventionTypeId: InterventionType || "",
      organizationId: Organization || "",
      TotalPauses: resolvedTotalPauses,
      BeginDate: toIsoString(BeginDate),
      EndDate: toIsoString(EndDate)
    },
    resolvedFiles
  );
};

export const formatCustomerAutomationForm = ({ form }) => {
  const {
    Manager,
    Image,
    PhoneNumber,
    ClientTypes,
    Latitude,
    Longitude,
    Address,
    Locale,
    PostalCode,
    Region,
    AddressSelector,
    ...rest
  } = form;

  const obj = {
    ...rest,
    PhoneNumber: canSubmitPhoneNumber(PhoneNumber) ? PhoneNumber : "",
    managerId: Manager,
    ClientTypes: ClientTypes,
    Addresses: getResultAddresses(AddressSelector)
  };

  return convertObjKeysToSnakeCase(obj);
};

export const formatProjectForm = (form, currencies) => {
  const {
    ProjectTarget,
    Contact,
    Manager,
    Contract,
    Files,
    TeamCollab,
    Image,
    ReceivedDate,
    BeginDate,
    EndDate,
    Company,
    AssociatedFiles,
    Pipeline,
    Tags,
    StatusDetailDescription,
    AssignedAssociations,
    ProjectType,
    PipelineStatus,
    EstimatedCostsDict,
    StatusDetailId,
    StatusDetail,
    ...restForm
  } = form;

  const managerId = Manager;
  const contactId = Contact;
  const organizationid = Company;
  const contractId = Contract;

  let accounts = [],
    teams = [];

  for (const item of TeamCollab) {
    if (item.type === "Account") accounts.push(item.data);
    else if (item.type === "Team") teams.push(item.data);
  }

  let tagsIds = [];
  if (Tags && Array.isArray(Tags))
    for (const e of Tags) {
      const { TempId } = getEntity(spaceProjectTagsSchema, e.data);
      if (TempId) tagsIds.push(TempId);
    }

  const obj = {
    ...restForm,
    ...convertInterventionTarget(ProjectTarget),
    managerId,
    ProjectTypeId: ProjectType,
    contractId,
    tagsIds,
    contactId,
    accounts,
    EstimatedCosts: createDealEstimatedCost(EstimatedCostsDict, currencies),
    teams,
    PipelineId: Pipeline,
    PipelineStatusId: PipelineStatus,
    organizationid,
    ReceivedDate: toIsoString(ReceivedDate),
    BeginDate: toIsoString(BeginDate),
    EndDate: toIsoString(EndDate)
  };

  if (StatusDetailId || StatusDetail) {
    obj.StatusDetailId = StatusDetailId || StatusDetail;
    obj.StatusDetailDescription = StatusDetailDescription;
  }

  const resolvedFiles = Array.isArray(Files) ? [...Files] : [];
  if (Array.isArray(AssignedAssociations) && AssignedAssociations.length > 0) {
    for (const e of AssignedAssociations) {
      const asfile = AssociatedFiles.find((t) => t.Id === e);
      if (asfile) resolvedFiles.push(asfile);
    }
  }

  const formData = createFormData(obj, resolvedFiles);

  if (Image && typeof Image !== "string")
    formData.append("Images", Image.file, Image.file.name);
  else {
    formData.append("Image", Image);
  }
  return formData;
};

const resolveTodos = (ToDos) => {
  for (const todo of ToDos) {
    // todo.ParentId = undefined;
    // todo.Id = undefined;
    if (todo.children && todo.children.length > 0) {
      resolveTodos(todo.children);
    }
  }
  return ToDos;
  // const returnData = e => {
  // 	console.log(e)
  // 	return {
  // 		title: e.Name || e.title,
  // 		Name: e.Name || e.title,
  // 		children: [],
  // 		Checked: e.Checked,
  // 		expanded: true,
  // 		...e,
  // 		Id: undefined,
  // 		ParentId: undefined,
  // 	};

  // };

  // let initialData = data.filter(e => !e.ParentId).map(e => returnData(e));

  // const buildObject = arr => {
  // 	if (!data.some(e => e.ParentId)) return [];

  // 	arr.forEach(item => {
  // 		item.children = data
  // 			.filter(e => e.ParentId === item.Id)
  // 			.map(e => returnData(e));

  // 		buildObject(item.children);
  // 	});
  // 	return arr;
  // };

  // buildObject(initialData);

  // return initialData;
};

const formatTaskRecurrenccy = (recurrencies) => {
  const {
    Type,
    EndDate,
    Every,
    WeekDays,
    MonthWeekType,
    MonthDay,
    MonthWeekDay,
    Month
  } = recurrencies;

  const response = {
    Type,
    EndDate
  };

  switch (Type) {
    case RecurrencyType.Daily:
      response.Every = Every;
      break;

    case RecurrencyType.Weekly:
      response.Every = Every;
      response.WeekDays = WeekDays;
      break;

    case RecurrencyType.Monthly:
      response.Every = Every;
      if (MonthWeekType === MonthWeekTypes.Day) {
        response.Day = MonthDay;
      } else {
        response.WeekOfTheMonth = MonthWeekType;
        response.WeekDays = [MonthWeekDay];
      }
      break;

    case RecurrencyType.Yearly:
      response.Every = Every;
      response.Month = Month;
      if (MonthWeekType === MonthWeekTypes.Day) {
        response.Day = MonthDay;
      } else {
        response.WeekOfTheMonth = MonthWeekType;
        response.WeekDays = [MonthWeekDay];
      }
      break;

    default:
      break;
  }

  return response;
};

export const formatAutomationTaskForm = ({ form }) => {
  const {
    TaskTarget,
    Manager,
    BeginDate,
    Company,
    EndDate,
    Pipeline,
    PipelineStatus,
    StatusDetailId,
    AddressSelector,
    ToDos,
    InternalDescription,
    Name,
    Creator,
    TaskType,
    Contact,
    StatusDetailDescription,
    EstimatedCost,
    Description,
    Followers
  } = form;

  const resolvedBeginDate = isValueExpression(BeginDate)
    ? BeginDate
    : toIsoString(BeginDate);
  const resolvedEndDate = isValueExpression(EndDate)
    ? EndDate
    : toIsoString(EndDate);

  // const target = convertInterventionTarget(TaskTarget)
  return {
    creator: Creator,
    target: TaskTarget,
    // "project_id": target.projectId,
    // "task_id": target.taskId,
    // "client_id": target.clientId,
    // "deal_id": target.dealId,
    // "ticket_id": target.ticketId,
    // "call_id": target.callId,
    // "contract_id": target.contractId,
    name: Name,
    begin_date: resolvedBeginDate,
    end_date: resolvedEndDate,
    estimated_cost: EstimatedCost,
    contact_id: Contact,
    task_type_id: TaskType,
    manager_id: Manager,
    pipeline_id: Pipeline,
    pipeline_status_id: PipelineStatus,
    status_detail_id: StatusDetailId || undefined,
    status_detail_description: StatusDetailDescription,
    organization_id: Company,
    description: Description,
    internal_description: InternalDescription,
    todos: JSON.stringify(resolveTodos(ToDos)),
    followers: JSON.stringify(Followers),
    addresses: JSON.stringify(getResultAddresses(AddressSelector))
  };
};

export const formatAutomationProjectForm = ({ form }) => {
  const {
    ProjectTarget,
    Contact,
    Manager,
    Contract,
    Files,
    TeamCollab,
    Image,
    ReceivedDate,
    BeginDate,
    EndDate,
    Company,
    Pipeline,
    StatusDetailDescription,
    ProjectType,
    PipelineStatus,
    StatusDetailId,
    StatusDetail,
    ...restForm
  } = form;

  const managerId = Manager;
  const contactId = Contact;
  const organizationid = Company;
  const contractId = Contract;

  let accounts = [],
    teams = [];

  for (const item of TeamCollab) {
    if (item.type === "Account") accounts.push(item.data);
    else if (item.type === "Team") teams.push(item.data);
  }

  const obj = {
    ...restForm,
    target: ProjectTarget,
    managerId,
    ProjectTypeId: ProjectType,
    contractId,
    contactId,
    accounts,
    teams,
    PipelineId: Pipeline,
    PipelineStatusId: PipelineStatus,
    OrganizationId: organizationid,
    ReceivedDate: toIsoString(ReceivedDate),
    BeginDate: toIsoString(BeginDate),
    EndDate: toIsoString(EndDate)
  };

  if (StatusDetailId || StatusDetail) {
    obj.StatusDetailId = StatusDetailId || StatusDetail;
    obj.StatusDetailDescription = StatusDetailDescription;
  }
  const resolveObj = convertObjKeysToSnakeCase(obj);

  return resolveObj;
};

function convert_to_snake_case(string) {
  return (
    string.charAt(0).toLowerCase() +
    string
      .slice(1) // lowercase the first character
      .replace(/\W+/g, " ") // Remove all excess white space and replace & , . etc.
      .replace(/([a-z])([A-Z])([a-z])/g, "$1 $2$3") // Put a space at the position of a camelCase -> camel Case
      .split(/\B(?=[A-Z]{2,})/) // Now split the multi-uppercases customerID -> customer,ID
      .join(" ") // And join back with spaces.
      .split(" ") // Split all the spaces again, this time we're fully converted
      .join("_") // And finally snake_case things up
      .toLowerCase()
  ); // With a nice lower case
}

export const formatAutomationContractForm = ({ form, currencies }) => {
  const {
    ContractTarget,
    Description,
    Contact,
    EndDate,
    EstimatedCost,
    SalesOpportunity,
    Manager,
    ContractType,
    InternalDescription,
    Covenant,
    // Files,
    Deslocations,
    Creator,
    Pipeline,
    PipelineStatus,
    Company,
    // StatusDetailDescription,
    BeginDate,
    StatusDetail,
    EstimatedCostsDict,
    PeriodTime,
    Departments,
    BillingPeriod,
    Schedules,
    EstimatedCostsHourDict,
    // Comments,
    // CreationDate,
    TeamCollab,
    Followers,
    // Client,
    // IndirectClient,
    IntervalMinimum,
    RoundingMargin
    // ...formRest
  } = form;

  const contactId = Contact;
  const managerId = Manager;
  const organizationid = Company;
  let accounts = [],
    teams = [];

  for (const item of TeamCollab) {
    if (item.type === "Account") accounts.push(item.data);
    else if (item.type === "Team") teams.push(item.data);
  }

  const { TimeValue, TimeUnit } = Covenant;

  const createDealEstimatedCost = (dict, currencies) => {
    const arr = [];
    const resolvedArr = [];
    for (const curr of currencies) {
      arr.push(dict[curr]);
    }
    for (const e of arr) {
      let currentObj = {};
      for (const t in e) {
        currentObj[convert_to_snake_case(t)] = e[t];
      }
      resolvedArr.push(currentObj);
    }
    return resolvedArr;
  };

  const resolvedSchedules = [];

  if (Schedules.length > 0) {
    for (const e of Schedules) {
      let currentObj = {};
      for (const t in e) {
        currentObj[convert_to_snake_case(t)] = e[t];
      }
      resolvedSchedules.push(currentObj);
    }
  }

  const resolvedBeginDate = isValueExpression(BeginDate)
    ? BeginDate
    : toIsoString(BeginDate);
  const resolvedEndDate = isValueExpression(EndDate)
    ? EndDate
    : toIsoString(EndDate);

  return {
    creator: Creator,
    description: Description,
    internal_description: InternalDescription,
    target: ContractTarget,
    contact_id: contactId,
    departments: Departments,
    pipeline_status_id: PipelineStatus.Id ? PipelineStatus.Id : PipelineStatus,
    pipeline_id: Pipeline.Id ? Pipeline.Id : Pipeline,
    manager_id: managerId,
    contract_type_id: ContractType,
    time_value: TimeValue,
    time_unit: TimeUnit,
    accounts,
    followers: Followers,
    status_detail_id: StatusDetail,
    teams,
    sales_opportunity: SalesOpportunity,
    schedules: resolvedSchedules,
    organization_id: organizationid,
    begin_date: resolvedBeginDate,
    end_date: resolvedEndDate,
    estimated_costs: createDealEstimatedCost(EstimatedCostsDict, currencies),
    estimated_costs_hour: createDealEstimatedCost(
      EstimatedCostsHourDict,
      currencies
    ),
    billing_period_time_value: BillingPeriod.TimeValue,
    billing_period_time_unit: BillingPeriod.TimeUnit,
    period_time: PeriodTime || 0,
    interval_minimum: IntervalMinimum,
    rounding_margin: RoundingMargin,
    estimated_cost: EstimatedCost,
    deslocations: Deslocations
  };
};

export const formatAutomationSubscriptionForm = ({ form, currencies }) => {
  const {
    Contact,
    Manager,
    Subscription,
    Client,
    SubscriptionType,
    Covenant,
    TeamCollab,
    BeginDate,
    EndDate,
    Company,
    Pipeline,
    PipelineStatus,
    Departments,
    // StatusDetail,
    SalesOpportunity,
    Description,
    InternalDescription,
    StatusDetailId,
    Followers,
    ...rest
    // BillingPeriod,
    // ...formRest
  } = form;

  const contactId = Contact;
  const managerId = Manager;
  // const subscriptionId = Subscription;
  const organizationid = Company;
  let accounts = [],
    teams = [];

  for (const item of TeamCollab) {
    if (item.type === "Account") accounts.push(item.data);
    else if (item.type === "Team") teams.push(item.data);
  }

  const { TimeValue, TimeUnit } = Covenant;

  const resolvedBeginDate = isValueExpression(BeginDate)
    ? BeginDate
    : toIsoString(BeginDate);
  const resolvedEndDate = isValueExpression(EndDate)
    ? EndDate
    : toIsoString(EndDate);

  const obj = {
    //   ...formRest,
    ...rest,
    client_id: Client,
    manager_id: managerId,
    contact_id: contactId,
    departments: Departments,
    entity_type_id: SubscriptionType,
    BeginDate: resolvedBeginDate,
    EndDate: resolvedEndDate,
    time_value: TimeValue,
    time_unit: TimeUnit,
    sales_opportunity: SalesOpportunity,
    description: Description,
    internal_description: InternalDescription,
    pipeline_status_id: PipelineStatus.Id ? PipelineStatus.Id : PipelineStatus,
    pipeline_id: Pipeline.Id ? Pipeline.Id : Pipeline,
    subscription_id: Subscription,
    accounts,
    status_detail_id: StatusDetailId,
    teams,
    organization_id: organizationid,
    followers: Followers
  };

  return convertObjKeysToSnakeCase(obj);
};

export const getSnakeCaseInterventionTarget = (entity) => {
  const {
    project_id,
    task_id,
    client_id,
    deal_id,
    ticket_id,
    call_id,
    contract_id
  } = entity;
  if (task_id) {
    return {
      type: "Task",
      data: task_id
    };
  } else if (ticket_id) {
    return {
      type: "Ticket",
      data: ticket_id
    };
  } else if (call_id) {
    return {
      type: "Call",
      data: call_id
    };
  } else if (project_id) {
    return {
      type: "Project",
      data: project_id
    };
  } else if (client_id) {
    return {
      type: "Client",
      data: client_id
    };
  } else if (contract_id) {
    return {
      type: "Contract",
      data: contract_id
    };
  } else if (deal_id) {
    return {
      type: originTypes.deals,
      data: deal_id
    };
  }
  return undefined;
};

export const formatTaskForm = (form, { recurrencies, automation } = {}) => {
  if (automation) return formatAutomationTaskForm(form);
  const {
    Files,
    TaskTarget,
    Manager,
    BeginDate,
    Contact,
    Company,
    EndDate,
    Pipeline,
    PipelineStatus,
    TaskType,
    Tags,
    AssociatedFiles,
    StatusDetailId,
    StatusDetail,
    AddressSelector,
    ToDos,
    AssignedAssociations,
    StatusDetailDescription,
    ...formRest
  } = form;

  const managerId = Manager;

  let tagsIds = [];
  if (Tags && Array.isArray(Tags))
    for (const e of Tags) {
      const { TempId } = getEntity(spaceTaskTagsSchema, e.data);
      if (TempId) tagsIds.push(TempId);
    }

  const obj = {
    ...formRest,
    tagsIds,
    ...convertInterventionTarget(TaskTarget),
    ToDos: JSON.stringify(resolveTodos(ToDos)),
    PipelineId: Pipeline,
    OrganizationId: Company,
    ContactId: Contact,
    PipelineStatusId: PipelineStatus,
    BeginDate: toIsoString(BeginDate),
    TaskTypeId: TaskType,
    EndDate: toIsoString(EndDate),
    Addresses: JSON.stringify(getResultAddresses(AddressSelector)),
    Recurrency: recurrencies
      ? JSON.stringify(formatTaskRecurrenccy(recurrencies))
      : null,
    managerId
  };

  if (StatusDetailId || StatusDetail) {
    obj.StatusDetailId = StatusDetailId || StatusDetail;
    obj.StatusDetailDescription = StatusDetailDescription;
  }

  const resolvedFiles = Array.isArray(Files) ? [...Files] : [];
  if (Array.isArray(AssignedAssociations) && AssignedAssociations.length > 0) {
    for (const e of AssignedAssociations) {
      const asfile = AssociatedFiles.find((t) => t.Id === e);
      if (asfile) resolvedFiles.push(asfile);
    }
  }

  return automation ? obj : createFormData(obj, resolvedFiles);
};

const convertObjKeysToSnakeCase = (obj) => {
  let newObj = {};
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const element = obj[key];
      const newKey = `${key}`
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map((s) => s.toLowerCase())
        .join("_");
      let resolvedElement = element;
      if (Array.isArray(element) && typeof element[0] === "object") {
        resolvedElement = element.map((e) => {
          return convertObjKeysToSnakeCase(e);
        });
      } else if (
        !Array.isArray(element) &&
        typeof element === "object" &&
        element !== null
      ) {
        resolvedElement = convertObjKeysToSnakeCase(element);
      }
      newObj[newKey] = resolvedElement;
    }
  }
  return newObj;
};

export const formatAutomationTicketForm = ({ form }) => {
  const {
    Contact,
    TicketTarget,
    DepartmentTeam,
    ManagerAccount,
    Files,
    Pipeline,
    PipelineStatus,
    ReceivedDate,
    StatusDetailId,
    Company,
    StatusDetail,
    AddressSelector,
    StatusDetailDescription,
    ...restForm
  } = form;

  const contactId = Contact;

  let departmentId, teamId;

  if (DepartmentTeam) {
    switch (DepartmentTeam.type) {
      case "Department":
        departmentId = DepartmentTeam.data;
        break;

      case "Team":
        teamId = DepartmentTeam.data;
        break;

      default:
        break;
    }
  }

  const managerId = ManagerAccount;

  const resolvedReceivedDate = isValueExpression(ReceivedDate)
    ? ReceivedDate
    : toIsoString(ReceivedDate);

  const obj = {
    ...restForm,
    target: TicketTarget,
    contactId,
    departmentId,
    PipelineId: Pipeline,
    PipelineStatusId: PipelineStatus,
    teamId,
    OrganizationId: Company,
    Addresses: getResultAddresses(AddressSelector),
    managerId,
    ReceivedDate: resolvedReceivedDate
  };

  if (StatusDetailId || StatusDetail) {
    obj.StatusDetailId = StatusDetailId || StatusDetail;
    obj.StatusDetailDescription = StatusDetailDescription;
  }

  return convertObjKeysToSnakeCase(obj);
};

export const formatAutomationCallForm = ({ form }) => {
  const {
    Contact,
    CallTarget,
    DepartmentTeam,
    ManagerAccount,
    Files,
    Pipeline,
    PipelineStatus,
    ReceivedDate,
    StatusDetailId,
    Company,
    StatusDetail,
    AddressSelector,
    StatusDetailDescription,
    ...restForm
  } = form;

  const contactId = Contact;

  let departmentId, teamId;

  if (DepartmentTeam) {
    switch (DepartmentTeam.type) {
      case "Department":
        departmentId = DepartmentTeam.data;
        break;

      case "Team":
        teamId = DepartmentTeam.data;
        break;

      default:
        break;
    }
  }

  const resolvedReceivedDate = isValueExpression(ReceivedDate)
    ? ReceivedDate
    : toIsoString(ReceivedDate);

  const managerId = ManagerAccount;

  const obj = {
    ...restForm,
    target: CallTarget,
    contactId,
    departmentId,
    PipelineId: Pipeline,
    PipelineStatusId: PipelineStatus,
    teamId,
    OrganizationId: Company,
    Addresses: getResultAddresses(AddressSelector),
    managerId,
    ReceivedDate: resolvedReceivedDate
  };

  if (StatusDetailId || StatusDetail) {
    obj.StatusDetailId = StatusDetailId || StatusDetail;
    obj.StatusDetailDescription = StatusDetailDescription;
  }

  return convertObjKeysToSnakeCase(obj);
};
export const formatAutomationDealForm = ({ form, currencies }) => {
  const {
    Company,
    Client,
    Pipeline,
    PipelineStatus,
    BeginDate,
    EndDate,
    Name,
    Contact,
    TeamCollab,
    Assigned,
    DealManager,
    StatusDetail,
    StatusDetailDescription,
    EstimatedCostsDict,
    ...formRest
  } = form;
  let Accounts = [],
    Teams = [];

  for (const item of TeamCollab) {
    if (item.type === "Account") Accounts.push(item.data);
    else if (item.type === "Team") Teams.push(item.data);
  }

  const createAutoDealEstimatedCost = (dict, currencies) => {
    const arr = [];
    const resolvedArr = [];
    for (const curr of currencies) {
      arr.push(dict[curr]);
    }
    for (const e of arr) {
      let currentObj = {};
      for (const t in e) {
        currentObj[convert_to_snake_case(t)] = e[t];
      }
      resolvedArr.push(currentObj);
    }
    return resolvedArr;
  };

  const resolvedBeginDate = isValueExpression(BeginDate)
    ? BeginDate
    : toIsoString(BeginDate);
  const resolvedEndDate = isValueExpression(EndDate)
    ? EndDate
    : toIsoString(EndDate);

  const obj = {
    ...formRest,
    OrganizationId: Company,
    ClientId: Client,
    PipelineId: Pipeline,
    PipelineStatusId: PipelineStatus,
    BeginDate: resolvedBeginDate,
    EndDate: resolvedEndDate,
    ContactId: Contact,
    Accounts,
    Name,
    Teams,
    ManagerId: Assigned,
    DealManagerId: DealManager,
    EstimatedCosts: createAutoDealEstimatedCost(EstimatedCostsDict, currencies)
  };

  if (StatusDetail) {
    obj.StatusDetailId = StatusDetail;
    obj.StatusDetailDescription = StatusDetailDescription;
  }

  return convertObjKeysToSnakeCase(obj);
};

export const formatTicketForm = (form) => {
  const {
    Contact,
    TicketTarget,
    DepartmentTeam,
    ManagerAccount,
    Files,
    Pipeline,
    AssignedAssociations,
    PipelineStatus,
    AssociatedFiles,
    ReceivedDate,
    StatusDetailId,
    Tags,
    Company,
    StatusDetail,
    AddressSelector,
    StatusDetailDescription,
    ...restForm
  } = form;

  const contactId = Contact;

  let departmentId, teamId;

  if (DepartmentTeam) {
    switch (DepartmentTeam.type) {
      case "Department":
        departmentId = DepartmentTeam.data;
        break;

      case "Team":
        teamId = DepartmentTeam.data;
        break;

      default:
        break;
    }
  }
  let tagsIds = [];
  if (Tags && Array.isArray(Tags))
    for (const e of Tags) {
      const { TempId } = getEntity(spaceTicketTagsSchema, e.data);
      if (TempId) tagsIds.push(TempId);
    }

  const managerId = ManagerAccount;

  const obj = {
    ...restForm,
    ...convertInterventionTarget(TicketTarget),
    contactId,
    tagsIds,
    departmentId,
    PipelineId: Pipeline,
    PipelineStatusId: PipelineStatus,
    teamId,
    OrganizationId: Company,
    Addresses: JSON.stringify(getResultAddresses(AddressSelector)),
    managerId,
    ReceivedDate: toIsoString(ReceivedDate)
  };

  if (StatusDetailId || StatusDetail) {
    obj.StatusDetailId = StatusDetailId || StatusDetail;
    obj.StatusDetailDescription = StatusDetailDescription;
  }

  const resolvedFiles = Array.isArray(Files) ? [...Files] : [];
  if (Array.isArray(AssignedAssociations) && AssignedAssociations.length > 0) {
    for (const e of AssignedAssociations) {
      const asfile = AssociatedFiles.find((t) => t.Id === e);
      if (asfile) resolvedFiles.push(asfile);
    }
  }

  return createFormData(obj, resolvedFiles);
};

export const formatCallForm = (form) => {
  const {
    CallTarget,
    Contact,
    DepartmentTeam,
    ManagerAccount,
    ReceivedDate,
    Client,
    Company,
    IndirectClient,
    PipelineStatus,
    StatusDetail,
    StatusDetailId,
    AddressSelector,
    Tags,
    StatusDetailDescription,
    Pipeline,
    ...restForm
  } = form;

  const contactId = Contact;

  let departmentId, teamId;

  if (DepartmentTeam) {
    switch (DepartmentTeam.type) {
      case "Department":
        departmentId = DepartmentTeam.data;
        break;

      case "Team":
        teamId = DepartmentTeam.data;
        break;

      default:
        break;
    }
  }

  let tagsIds = [];
  if (Tags && Array.isArray(Tags))
    for (const e of Tags) {
      const { TempId } = getEntity(spaceCallsTagsSchema, e.data);
      if (TempId) tagsIds.push(TempId);
    }

  const managerId = ManagerAccount;

  return {
    ...restForm,
    ...convertInterventionTarget(CallTarget),
    contactId,
    departmentId,
    teamId,
    tagsIds,
    PipelineId: Pipeline,
    PipelineStatusId: PipelineStatus,
    StatusDetailId: StatusDetailId ? StatusDetailId : StatusDetail,
    StatusDetailDescription: StatusDetailId ? StatusDetailDescription : null,
    OrganizationId: Company,
    managerId,
    Addresses: getResultAddresses(AddressSelector),
    ReceivedDate: toIsoString(ReceivedDate)
  };
};

const createDealEstimatedCost = (dict, currencies) => {
  const arr = [];

  for (const curr of currencies) {
    arr.push(dict[curr]);
  }
  return JSON.stringify(arr);
};

export const formatDealForm = (form, currencies) => {
  const {
    Company,
    Client,
    Pipeline,
    PipelineStatus,
    BeginDate,
    EndDate,
    Contact,
    Tags,
    TeamCollab,
    DealTarget,
    AssignedAssociations,
    AssociatedFiles,
    Assigned,
    DealManager,
    StatusDetail,
    StatusDetailDescription,
    EstimatedCostsDict,
    EstimatedCost,
    Files,
    ...formRest
  } = form;

  let Accounts = [],
    // Departments = [],
    Teams = [];

  for (const item of TeamCollab) {
    if (item.type === "Account") Accounts.push(item.data);
    else if (item.type === "Team") Teams.push(item.data);
    // else if (item.type === "Department") Departments.push(item.data);
  }
  const resolvedFiles = Array.isArray(Files) ? [...Files] : [];
  if (Array.isArray(AssignedAssociations) && AssignedAssociations.length > 0) {
    for (const e of AssignedAssociations) {
      const asfile = AssociatedFiles.find((t) => t.Id === e);
      if (asfile) resolvedFiles.push(asfile);
    }
  }

  let tagsIds = [];
  if (Tags && Array.isArray(Tags))
    for (const e of Tags) {
      const { TempId } = getEntity(spaceDealsTagsSchema, e.data);
      if (TempId) tagsIds.push(TempId);
    }

  const obj = {
    ...formRest,
    OrganizationId: Company,
    tagsIds,
    // Departments,
    ClientId: Client,
    PipelineId: Pipeline,
    PipelineStatusId: PipelineStatus,
    BeginDate: toIsoString(BeginDate),
    EndDate: toIsoString(EndDate),
    ContactId: Contact,
    Accounts,
    Teams,
    ManagerId: Assigned,
    DealManagerId: DealManager,
    EstimatedCosts: createDealEstimatedCost(EstimatedCostsDict, currencies)
  };

  if (StatusDetail) {
    obj.StatusDetailId = StatusDetail;
    obj.StatusDetailDescription = StatusDetailDescription;
  }

  return createFormData(obj, resolvedFiles);
};
export const formatSocialPost = (form) => {
  const {
    SocialId,
    Text,
    Schedule,
    IsSchedule,
    // Status,
    FilesUnsorted = []
    // ...formRest
  } = form;

  const FilesUrl = [];
  const Files = [];

  FilesUnsorted.forEach((e) => {
    if (e.Url) {
      FilesUrl.push(e.Url);
    } else {
      Files.push(e);
    }
  });

  const obj = {
    SocialId,
    Text,
    Schedule: toIsoString(Schedule),
    IsSchedule: !IsSchedule,
    Status: 1,
    FilesUrl: JSON.stringify(FilesUrl)
  };

  return createFormData(obj, Files);
};

export const getInterventionTarget = (entity) => {
  const {
    Call,
    Project,
    Task,
    Client,
    Ticket,
    Team,
    Contract,
    Deal,
    Subscription
  } = entity;
  if (Task && Task?.Id !== 0) {
    return {
      type: "Task",
      data: Task
    };
  } else if (Ticket && Ticket?.Id !== 0) {
    return {
      type: "Ticket",
      data: Ticket
    };
  } else if (Call && Call?.Id !== 0) {
    return {
      type: "Call",
      data: Call
    };
  } else if (Project && Project?.Id !== 0) {
    return {
      type: "Project",
      data: Project
    };
  } else if (Contract && Contract?.Id !== 0) {
    return {
      type: "Contract",
      data: Contract
    };
  } else if (Subscription && Subscription?.Id !== 0) {
    return {
      type: "Subscription",
      data: Subscription
    };
  } else if (Deal && Deal?.Id !== 0) {
    return {
      type: originTypes.deals,
      data: Deal
    };
  } else if (Client && Client?.Id !== 0) {
    return {
      type: "Client",
      data: Client
    };
  } else if (Team && Team?.Id !== 0) {
    return {
      type: "Team",
      data: Team
    };
  }
  return undefined;
};

export const getColumnDropdownTarget = (entity) => {
  const { projectId, taskId, callId, ticketId, clientId, teamId, contractId } =
    entity || {};

  if (taskId) {
    return {
      type: "Task",
      data: taskId
    };
  } else if (ticketId) {
    return {
      type: "Ticket",
      data: ticketId
    };
  } else if (callId) {
    return {
      type: "Call",
      data: callId
    };
  } else if (projectId) {
    return {
      type: "Project",
      data: projectId
    };
  } else if (clientId) {
    return {
      type: "Client",
      data: clientId
    };
  } else if (teamId) {
    return {
      type: "Team",
      data: teamId
    };
  } else if (contractId) {
    return {
      type: "Contract",
      data: contractId
    };
  } else {
    return null;
  }
};
