import api from "../../api";

import { accountSchema } from "../../config/schema";
import { normalize } from "../../Helpers/SchemaHelper";
import { logout } from "../../Helpers/AuthHelper";

export const LoginStatus = {
  Success: 0,
  InvalidUserName: 1,
  InvalidPassword: 2,
  UserRejected: 8,
  ProviderError: 11,
  PendingValidation: 32,
  InvalidPasswordFormat: 34,
  UserLocked: 35,
  UserNotActive: 36
};

export const AuthErrors = {
  EMAIL_EXISTS: "EMAIL_EXISTS"
};

export const FETCH_CURRENT_USER_SUMMARY_REQUEST =
  "FETCH_CURRENT_USER_SUMMARY_REQUEST";
export const FETCH_CURRENT_USER_SUMMARY_SUCCESS =
  "FETCH_CURRENT_USER_SUMMARY_SUCCESS";
export const FETCH_CURRENT_USER_SUMMARY_ERROR =
  "FETCH_CURRENT_USER_SUMMARY_ERROR";

const setGlobalUserObject = ({ Id, Name }) => {
  const resolvedUser = {
    Id,
    Name
  };

  window.user = resolvedUser;

  if ("onUserFetch" in window && typeof window.onUserFetch === "function") {
    window.onUserFetch(resolvedUser);
  }
};

export const getCurrentUser = (form) => (dispatch, getState) => {
  dispatch({ type: FETCH_CURRENT_USER_SUMMARY_REQUEST });

  api
    .get("account/summary")
    .then((response) => {
      let normalizedData = normalize(response, accountSchema);
      console.log(normalizedData);
      setGlobalUserObject(response);
      dispatch({
        type: FETCH_CURRENT_USER_SUMMARY_SUCCESS,
        response: normalizedData
      });
    })
    .catch((error) => {
      dispatch({ type: FETCH_CURRENT_USER_SUMMARY_ERROR, error });
      if (error.response.status === 401) {
        logout();
      }
    });
};
