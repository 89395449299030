import React, { useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import ClipboardProvider from "../../Components/ClipbordProvider/ClipboardProvider";
import CreatedBy from "../../Components/CreatedByComponent/CreatedBy";
import ArDatePicker from "../../Components/DatePicker/ArDatePicker";
import DocTyper from "../../Components/DocTyper/DocTyper";
import {
  updateCollabFollowers,
  updateManagerFollowers,
  useCanEditFollowers,
  useDropdownFollowers
} from "../../Components/EntityFollow/EntityFollowHelper";
import FileAssociations from "../../Components/FileUploader/FileAssociations";
import FileUploader from "../../Components/FileUploader/FileUploader";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import HTMLInput from "../../Components/HTMLInput/HTMLInput";
import { SidebarAutomationContext } from "../../Components/Sidebar/SidebarV2Automation";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { hasDealFinancialPermission } from "../../Helpers/ModulesHelper";
import { useDeal, usePipelineStatus } from "../../Hooks/EntityHooks";
import {
  ClientDropdown,
  ContactDropdown,
  dealsCancellationReasonsDropdown,
  dealsLostReasonsDropdown,
  DepartmentDropdown,
  OrganizationDropdown,
  TeamCollabDropdown,
  UserDropdown,
  DealTagDropdown
} from "../AdvancedMultiInputs";
import {
  DealPipelineDropdown,
  DealStatusPipelineDropdown
} from "../AdvancedMultiInputs/PipelineDropdowns";
import DealCostSelector from "./DealCostSelector";
import { DealStatusTypes } from "./DealsHelper";
import { useSpaceQuery } from "../../Helpers/IOClient";

const DealFormContent = ({
  formState,
  organizations,
  pipelines,
  id,
  canEdit,
  currencies
}) => {
  const { form, handleChange } = formState;
  const {
    Pipeline,
    BeginDate,
    EndDate,
    // Name,
    AssociatedFiles,
    AssignedAssociations,
    Creator,
    Contact,
    // Comments,
    Client,
    PipelineStatus,
    Assigned,
    StatusDetailDescription,
    Company,
    CreationDate,
    Followers,
    TeamCollab,
    DealManager

    // SalesOpportunity
  } = form;

  const currentAccountSpace = useCurrentAccountSpace();
  const PrivateFinancialPermission =
    hasDealFinancialPermission(currentAccountSpace);

  const handleAssociationsChanges = (value) => {
    handleChange((oldF) => {
      const newF = { ...oldF };
      const newAssignedAssociations = Array.isArray(newF.AssignedAssociations)
        ? [...newF.AssignedAssociations]
        : [];
      const index = newAssignedAssociations.findIndex((e) => e === value);
      if (index !== -1) {
        newAssignedAssociations.splice(index, 1);
      } else newAssignedAssociations.push(value);
      newF.AssignedAssociations = newAssignedAssociations;
      return newF;
    });
  };

  const PipelineStatusObj = usePipelineStatus(PipelineStatus);
  const handlePipelineChanges = (e) => {
    const { value } = e.target;
    handleChange({
      Pipeline: value,
      PipelineStatus: null
    });
  };

  function dateCompare(date1, date2) {
    return new Date(date2) > new Date(date1);
  }

  const handleTimeChange = (e) => {
    const { name, value } = e.target;

    if (name === "BeginDate") {
      if (dateCompare(EndDate, value)) {
        let endValue = new Date(value);
        endValue.setTime(endValue.getTime() + 60 * 60 * 1000);
        handleChange({
          BeginDate: value,
          EndDate: endValue
        });
        return;
      }
    }

    handleChange({
      [name]: value
    });
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value
    });
  };
  const handleOrganizationChanges = (e) => {
    const { value } = e.target;
    handleChange({
      Company: value,
      StatusDetail: null
    });
  };

  const handleStatusChange = (e) => {
    handleChange({
      PipelineStatus: e.target.value,
      StatusDetail: null
    });
  };

  const remainingAccounts = useDropdownFollowers(TeamCollab);
  // const followers = useJoinedFollowerAccounts(
  //   Assigned,
  //   DealManager,
  //   remainingAccounts
  // );
  // const possibleFollowers = useAvailableFollowers(Creator, followers);

  const handleTeamCollabChange = ({ target: { value } }) => {
    handleChange({
      TeamCollab: value,
      Followers: updateCollabFollowers(Followers, TeamCollab, value, [
        Assigned,
        DealManager
      ])
    });
  };

  const handleDealManagerChange = ({ target: { value } }) => {
    handleChange({
      DealManager: value,
      Followers: updateManagerFollowers(Followers, DealManager, value, [
        Assigned,
        remainingAccounts
      ])
    });
  };

  const handleManagerChange = ({ target: { value } }) => {
    handleChange({
      Assigned: value,
      Followers: updateManagerFollowers(Followers, Assigned, value, [
        DealManager,
        remainingAccounts
      ])
    });
  };
  const handleClientChange = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value
      // Contact: null
    });
  };

  const contactValidationURL = useMemo(() => {
    if (!Contact) return null;
    if (!Client) return null;

    return `Query/Contacts/${Client}/${Contact}`;
  }, [Client, Contact]);

  const { loading: contactaValidation } = useSpaceQuery(
    contactValidationURL,
    null,
    {
      onSuccess: ({ data }) => {
        if (!Boolean(data)) {
          handleChange({
            Contact: null
          });
        }
      },
      onError: (err) => {
        handleChange({
          Contact: null
        });
      }
    }
  );

  if (
    !Pipeline &&
    pipelines &&
    pipelines.length > 0 &&
    pipelines.length === 1
  ) {
    handleChange({
      Pipeline: pipelines[0]
    });
  }

  if (organizations.length === 1 && !Company) {
    handleChange({
      Company: organizations[0]
    });
  }
  const Automation = useContext(SidebarAutomationContext);
  return (
    <>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="ASSOCIATE_DEAL_TO" />
      </div>

      {Automation && (
        <FormInput
          textId="CREATED_BY"
          name="Creator"
          inputType={UserDropdown}
          className="mb-3"
        />
      )}

      {(Automation || (organizations && organizations.length > 1)) && (
        <FormInput
          textId="COMPANY"
          name="Company"
          inputType={OrganizationDropdown}
          // items={organizations}
          onChange={handleOrganizationChanges}
          className="mb-3"
        />
      )}

      <FormInput
        className="mb-3"
        textId="ACCOUNT"
        name="Client"
        allClients={false}
        onChange={handleClientChange}
        inputType={ClientDropdown}
      />

      <FormInput
        className="mb-3"
        name="Contact"
        textId="CONTACT"
        inputType={ContactDropdown}
        value={Contact}
        disabled={contactaValidation}
        IndirectClient={Client}
        Client={Client}
        onChange={handleChanges}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="CLASSIFICATION" />
      </div>

      <FormInput
        className="mb-3"
        name="Pipeline"
        textId="PIPELINE"
        disabled={pipelines && pipelines.length > 0 && pipelines.length === 1}
        inputType={DealPipelineDropdown}
        onChange={handlePipelineChanges}
      />

      <FormInput
        className="mb-3"
        disabled={!Pipeline}
        textId="DEAL_STATUS"
        name="PipelineStatus"
        Status={!id && DealStatusTypes.open}
        value={PipelineStatus}
        onLoad={({ data }) => {
          handleChange({
            PipelineStatus: data[0],
            StatusDetail: null,
            StatusDetailDescription: null
          });
        }}
        filterBlock={true}
        inputType={DealStatusPipelineDropdown}
        pipelineId={Pipeline}
        onChange={handleStatusChange}
      />

      {id &&
        PipelineStatus &&
        PipelineStatusObj.Status === DealStatusTypes.lost && (
          <FormInput
            name="StatusDetail"
            textId="REASONS_LOSS"
            inputType={dealsLostReasonsDropdown}
            company={Company}
            //   value={
            //     StatusDetailId
            //       ? StatusDetailId
            //       : StatusDetail
            //       ? StatusDetail
            //       : null
            //   }
            onChange={handleChanges}
            className="mb-3"
          />
        )}
      {id &&
        PipelineStatus &&
        PipelineStatusObj.Status === DealStatusTypes.lost && (
          <FormInput
            name="StatusDetailDescription"
            textId="LOSS_DESCRIPTION"
            inputType={HTMLInput}
            value={StatusDetailDescription ? StatusDetailDescription : ""}
            onChange={handleChanges}
            className="mb-3"
          />
        )}

      {id &&
        PipelineStatus &&
        PipelineStatusObj.Status === DealStatusTypes.canceled && (
          <FormInput
            name="StatusDetail"
            textId="CANCELATION_REASON"
            inputType={dealsCancellationReasonsDropdown}
            company={Company}
            //   value={
            //     StatusDetailId
            //       ? StatusDetailId
            //       : StatusDetail
            //       ? StatusDetail
            //       : null
            //   }
            onChange={handleChanges}
            className="mb-3"
          />
        )}
      {id &&
        PipelineStatus &&
        PipelineStatusObj.Status === DealStatusTypes.canceled && (
          <FormInput
            name="StatusDetailDescription"
            textId="CANCELATION_DESCRIPTION"
            inputType={HTMLInput}
            value={StatusDetailDescription ? StatusDetailDescription : ""}
            onChange={handleChanges}
            className="mb-3"
          />
        )}

      <FormInput
        className="mb-3"
        name="Tags"
        textId="DEAL_TAGS"
        pipelineId={Pipeline}
        // getKey={(e) => {
        //   const resolvedTag = getEntity(spaceTicketTagsSchema, e);
        //   return `${e}-${resolvedTag?.Pipeline}`;
        // }}
        multiple
        inputType={DealTagDropdown}
      />
      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"ASSIGN_TO"} />
      </div>

      <FormInput
        className="mb-3"
        textId="SALESPERSON"
        inputType={UserDropdown}
        name="DealManager"
        onChange={handleDealManagerChange}
      />

      <FormInput
        name="Departments"
        className="mb-3"
        textId="DEPARTMENTS"
        inputType={DepartmentDropdown}
        multiple
      />

      <FormInput
        name="TeamCollab"
        className="mb-3"
        textId="TEAMS_CONTRIBUTORS"
        inputType={TeamCollabDropdown}
        multiple
        onChange={handleTeamCollabChange}
      />

      <FormInput
        className="mb-3"
        textId="ATTRIBUTED_TO"
        inputType={UserDropdown}
        name="Assigned"
        onChange={handleManagerChange}
      />

      <FormInput
        name="Followers"
        textId="FOLLOWERS"
        inputType={UserDropdown}
        multiple
        // items={possibleFollowers}
        className="mb-3"
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="DETAILS" />
      </div>
      <FormInput className="mb-3" textId="NAME" name="Name" />
      <FormInput
        className="mb-3"
        name="BeginDate"
        textId="START"
        inputType={ArDatePicker}
        onChange={handleTimeChange}
        componentClassName="w-100"
        enableHours={false}
      />

      <FormInput
        className="mb-3"
        textId="END"
        inputType={ArDatePicker}
        name="EndDate"
        onChange={handleTimeChange}
        disablePastDate={BeginDate}
        disableDates={true}
        componentClassName="w-100"
        enableHours={false}
      />

      <FormInput
        className="mb-3"
        name="SalesOpportunity"
        maxLength={50}
        textId="NR_SALE_OPPORTUNITY"
        onChange={handleChanges}
      />

      {PrivateFinancialPermission && (
        <>
          <hr className="mb-3"></hr>
          <div className="mb-3 text-black fs-16 fw-medium">
            <FormattedMessage id={"FINANCIAL_DATA"} />
          </div>
          <FormInput
            disableAutomation
            textId="VALUE"
            name="EstimatedCostsDict"
            currencies={currencies}
            inputType={DealCostSelector}
            className="mb-3"
          />
        </>
      )}

      {/* {Name && Name.length > 60 ? (
          <div
            //   style={{ maxWidth: 320 }}
            className="mb-3 fs-12 mt-2  text-danger"
          >
            {`O nome deve ter no máximo 60 caracteres (${Name.length} introduzidos)`}
          </div>
        ) : (
          <div className="mb-3"></div>
        )} */}
      <ClipboardProvider>
        <FormInput
          className="mb-3"
          textId="DESCRIPTION"
          inputType={DocTyper}
          name="Description"
        />
        {!Automation && (
          <>
            {Array.isArray(AssociatedFiles) && AssociatedFiles.length > 0 && (
              <>
                <hr className="mb-3"></hr>
                <div className="text-black fs-16 mb-3 fw-medium">
                  <FormattedMessage id="RELATED_ATTACHMENTS" />
                </div>
                <FormInput
                  disableAutomation
                  inputType={FileAssociations}
                  name="Files"
                  className="mb-3 form-Anexos-floater"
                  extendedMode
                  handleAssociationsChanges={handleAssociationsChanges}
                  AssignedAssociations={AssignedAssociations}
                  onChange={() => {}}
                  value={AssociatedFiles}
                />
              </>
            )}
            <hr className="mb-3"></hr>
            <div className="text-black fs-16 mb-3 fw-medium">
              <FormattedMessage id="ATTACHMENTS" />
            </div>

            <FormInput
              name="Files"
              enableSwapper
              className="mb-3 form-Anexos-floater"
              inputType={FileUploader}
            />
          </>
        )}
      </ClipboardProvider>
      {/*  */}

      {/* {id && (
          <>
            <hr className="mb-3"></hr>
            <div className="mb-3">
              <span className="text-black fs-16 fw-medium">Comentários</span>
            </div>
            <div className="mb-3">
              <CommentModule
                Type="Deal"
                Schema={spaceDealsSchema}
                Id={id}
                Comments={Comments || []}
              />
            </div>
          </>
        )} */}

      <CreatedBy Creator={Creator} CreationDate={CreationDate} />
    </>
  );
};

export default DealFormContent;
