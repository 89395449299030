import React, {
  useMemo,
  useRef,
  useLayoutEffect,
  useState,
  useContext
} from "react";
import { useSpaceQuery } from "../../Helpers/IOClient";
import {
  spaceClientSchema,
  spaceContactSchema,
  spaceDealsSchema,
  spaceContractSchema,
  spaceProjectsSchema,
  spaceTasksSchema,
  spaceSubscriptionSchema,
  spaceCallsSchema,
  spaceTicketsSchema,
  spaceInterventionsSchema,
  spaceDocumentsSchema,
  spaceDocTemplatesSchema
} from "../../config/schema";
import "./EntitySummaryBreadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faFileWord } from "@fortawesome/pro-light-svg-icons";
import "./EntitySummaryBreadcrumbs.css";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { ClientStatusProfileWithIcon } from "../Clients/ClientProfile";
import { ContractStatusProfileWithIcon } from "../Contract/ContractProfile";
import { ProjectStatusProfileWithIcon } from "../Projects/ProjectProfile";
import Popup, { usePopupOpenState } from "../Popup/Popup";
import {
  ClientLink,
  ProjectsLink,
  TasksLink,
  TicketsLink,
  CallLink,
  ContractsLink,
  DealLink
} from "../../Helpers/HooksHelper";
import {
  ProjectOriginPopup,
  projectOriginOptions
} from "../../Containers/Origin/ProjectOrigin";
import {
  TicektOriginPopup,
  ticketOriginOptions
} from "../../Containers/Origin/TicketOrigin";
import {
  CallOriginPopup,
  callOriginOptions
} from "../../Containers/Origin/CallOrigin";
import {
  ClientOriginPopup,
  clientOriginOptions
} from "../../Containers/Origin/ClientOrigin";
import {
  TaskOriginPopup,
  taskOriginOptions
} from "../../Containers/Origin/TaskOrigin";
import {
  ContractOriginPopup,
  contractOriginOptions
} from "../../Containers/Origin/ContractOrigin";
import {
  dealOriginOptions,
  DealOriginPopup
} from "../../Containers/Origin/DealOrigin";
import classnames from "classnames";
import { useHoverEvents } from "../../Containers/Origin/Origin";
import { FormattedMessage } from "react-intl";
import { DealStatusProfileWithIcon } from "../Deals/DealStatusProfile";
import {
  CallFormButton,
  ClientFormButton,
  ContractFormButton,
  DealFormButton,
  ProjectFormButton,
  TaskFormButton,
  TicketFormButton
} from "../../Containers/Form/FormButtons";
import { DetailsBreadcrumbLinkContext } from "../DetailsView2/DetailsView2Helper";
import {
  faTicketSimple,
  faPhone,
  faSuitcase,
  faListCheck,
  faFileContract,
  faHandshake,
  faSquareParking
} from "@fortawesome/pro-solid-svg-icons";


const useBreadcrumbsEndpoint = (schema, id) => {
  return useMemo(() => {
    let type, textId;
    switch (schema) {
      case spaceClientSchema:
        return { textId: "ACCOUNT" };

      case spaceContactSchema:
        return { textId: "CONTACT" };
				
      case spaceDocumentsSchema:
        type = "document";
        textId = "DOCUMENT";
				break;

      case spaceDocTemplatesSchema:
        return { textId: "TEMPLATE" };

      case spaceDealsSchema:
        type = "deal";
        textId = "DEAL";
        break;

      case spaceContractSchema:
        type = "contract";
        textId = "CONTRACT";
        break;

      case spaceProjectsSchema:
        type = "project";
        textId = "PROJECT";
        break

      case spaceSubscriptionSchema:
        return { textId: "SUBSCRIPTION" };

      case spaceCallsSchema:
        type = "call";
        textId = "CALL";
        break;

      case spaceTicketsSchema:
        type = "ticket";
        textId = "CASE";
        break;

      case spaceTasksSchema:
        type = "task";
        textId = "TASK";
        break;

      case spaceInterventionsSchema:
        type = "TimeCharge";
        textId = "TIME";
        break;

      default:
        return {};
    }

    return { endpoint: `breadcrumbs/${type}/${id}`, textId };
  }, [id, schema]);
};

const SingleItem = ({ textId, id, className }) => {
  return (
    <div
      className={classnames(
        "text-black d-flex align-items-center fs-14 fw-medium",
        className
      )}
    >
      <FormattedMessage id={textId} />
      <div className="px-2 py-1 ml-2 fs-12 border rounded">Nº {id}</div>
    </div>
  );
};
// const LinkMapping = {
//   project: ProjectsLink,
//   ticket: TicketsLink,
//   call: CallLink,
//   client: ClientLink,
//   task: TasksLink,
//   contract: ContractsLink,
//   deal: DealLink
// };

const useBreadcrumbSettings = (item) => {
  return useMemo(() => {
    let icon, typeExtense, PopupComp, popupOptions, LinkComp, TestLinkComp;
    let popupProps = null;
    const { Type } = item;
    switch (Type) {
      case "project":
        typeExtense = "Projeto";
        icon = faSquareParking;
        PopupComp = ProjectOriginPopup;
        popupProps = { project: item };
        popupOptions = projectOriginOptions;
        LinkComp = ProjectsLink;
        TestLinkComp = ProjectFormButton;
        break;
      case "ticket":
        typeExtense = "Ticket";
        icon = faTicketSimple;
        PopupComp = TicektOriginPopup;
        popupProps = { ticket: item };
        popupOptions = ticketOriginOptions;
        TestLinkComp = TicketFormButton;
        LinkComp = TicketsLink;
        break;
      case "call":
        typeExtense = "Chamada";
        icon = faPhone;
        PopupComp = CallOriginPopup;
        popupProps = { call: item };
        popupOptions = callOriginOptions;
        TestLinkComp = CallFormButton;
        LinkComp = CallLink;
        break;
      case "client":
        typeExtense = "Cliente";
        icon = faSuitcase;
        PopupComp = ClientOriginPopup;
        popupProps = { client: item };
        popupOptions = clientOriginOptions;
        TestLinkComp = ClientFormButton;
        LinkComp = ClientLink;
        //   subComponent = (
        //     <ClientStatusBadge className="ml-2 fs-10 sm" status={Status} />
        //   );
        break;
      case "task":
        typeExtense = "Tarefa";
        icon = faListCheck;
        PopupComp = TaskOriginPopup;
        popupProps = { task: item };
        popupOptions = taskOriginOptions;
        TestLinkComp = TaskFormButton;
        LinkComp = TasksLink;
        break;
      case "contract":
        typeExtense = "Contrato";
        icon = faFileContract;
        PopupComp = ContractOriginPopup;
        popupProps = { contract: item };
        popupOptions = contractOriginOptions;
        LinkComp = ContractsLink;
        TestLinkComp = ContractFormButton;
        break;
      case "deal":
        typeExtense = "Negócio";
        icon = faHandshake;
        PopupComp = DealOriginPopup;
        popupProps = { deal: item };
        popupOptions = dealOriginOptions;
        LinkComp = DealLink;
        TestLinkComp = DealFormButton;
        break;
      case "document":
        typeExtense = "Documento";
        icon = faFileWord;
        // PopupComp = DealOriginPopup;
        // popupProps = { deal: item };
        // popupOptions = dealOriginOptions;
        // LinkComp = DealLink;
        // TestLinkComp = DealFormButton;
        break;

      default:
        break;
      // return null;
    }

    return {
      icon,
      typeExtense,
      PopupComp,
      popupOptions,
      LinkComp,
      TestLinkComp,
      popupProps
    };
  }, [item]);
};

const BreadcrumbsItem = ({ breadcrumb, className, placement }) => {
  const { Name, Type, Status, Id } = breadcrumb;
  const BreadCrumbLinkType = useContext(DetailsBreadcrumbLinkContext);
  const {
    icon,
    // PopupComp,
    // popupOptions,
    LinkComp,
    TestLinkComp
    // popupProps
  } = useBreadcrumbSettings(breadcrumb);
  const hoverEvents = useHoverEvents();
  const { onAnchorMouseEnter, onAnchorMouseLeave } = hoverEvents;

  const anchorRef = useRef();

  return (
    <>
      {/* {PopupComp && (
        <OriginPopup
          onClick={(e) => {
            e.nativeEvent.stopImmediatePropagation();
          }}
          placement={placement || "bottom"}
          props={popupProps}
          hoverEvents={hoverEvents}
          anchorRef={anchorRef}
          PopupComp={PopupComp}
          options={popupOptions}
        />
      )} */}
      {!BreadCrumbLinkType && TestLinkComp ? (
        <TestLinkComp
          className={classnames(
            "d-flex align-items-center text-truncate ar-sidebar-button-reset",
            className
          )}
          forceOnlyChildren
          id={Id}
          innerRef={anchorRef}
          onMouseEnter={onAnchorMouseEnter}
          onMouseLeave={onAnchorMouseLeave}
        >
          {Type === "client" ? (
            <ClientStatusProfileWithIcon
              sensitive
              hideName
              value={Status}
              className=" mr-1"
            />
          ) : Type === "contract" ? (
            <ContractStatusProfileWithIcon
              Icon={<FontAwesomeIcon icon={faFileContract} />}
              sensitive
              hideName
              status={Status}
              className=" mr-1"
            />
          ) : Type === "deal" ? (
            <DealStatusProfileWithIcon
              sensitive
              hideName
              status={Status}
              className=" mr-1"
            />
          ) : (
            <ProjectStatusProfileWithIcon
              Icon={<FontAwesomeIcon icon={icon} size="sm" />}
              sensitive
              hideName
              status={Status}
              className=" mr-1"
            />
          )}
          {/* <FontAwesomeIcon icon={icon} size="sm" /> */}
          <span className="ml-1 text-truncate fs-14">{Name}</span>
        </TestLinkComp>
      ) : (
        <LinkComp
          id={Id}
          innerRef={anchorRef}
          className={classnames(
            "d-flex align-items-center text-truncate",
            className
          )}
          onMouseEnter={onAnchorMouseEnter}
          onMouseLeave={onAnchorMouseLeave}
        >
          {Type === "client" ? (
            <ClientStatusProfileWithIcon
              sensitive
              hideName
              value={Status}
              className=" mr-1"
            />
          ) : Type === "contract" ? (
            <ContractStatusProfileWithIcon
              Icon={<FontAwesomeIcon icon={faFileContract} />}
              sensitive
              hideName
              status={Status}
              className=" mr-1"
            />
          ) : Type === "deal" ? (
            <DealStatusProfileWithIcon
              sensitive
              hideName
              status={Status}
              className=" mr-1"
            />
          ) : (
            <ProjectStatusProfileWithIcon
              Icon={<FontAwesomeIcon icon={icon} size="sm" />}
              sensitive
              hideName
              status={Status}
              className=" mr-1"
            />
          )}
          {/* <FontAwesomeIcon icon={icon} size="sm" /> */}
          <span className="ml-1 text-truncate fs-14">{Name}</span>
        </LinkComp>
      )}
    </>
  );
};

const ArrowedBreadcrumbsItem = ({ breadcrumb, isLast, showBackArrow }) => {
  return (
    <div className="ar-entity-summary-breadcrumbs-item align-items-center text-truncate">
      {showBackArrow && (
        <div>
          <FontAwesomeIcon className="mx-2" size="lg" icon={faAngleRight} />
        </div>
      )}
      <BreadcrumbsItem breadcrumb={breadcrumb} />
      {!isLast && (
        <FontAwesomeIcon className="mx-2" size="lg" icon={faAngleRight} />
      )}
    </div>
  );
};

const BreadcrumbsMap = ({ breadcrumbs, showArrow, showBackArrow }) => {
  return breadcrumbs.map((v, index) => {
    const { Type, Id } = v;

    const isLast = showArrow ? false : index === breadcrumbs.length - 1;
    const resolvedShowBackArrow = showBackArrow && index === 0;
    return (
      <ArrowedBreadcrumbsItem
        showBackArrow={resolvedShowBackArrow}
        breadcrumb={v}
        isLast={isLast}
        key={`${Type}${Id}`}
      />
    );
  });
};

const dotsWidth = 60;

const calculateChildren = (
  domElement,
  removedChildrenCount = 0,
  totalWidthRemoved = 0
) => {
  const { scrollWidth, offsetWidth, children } = domElement;

  let completeScrollWidth = scrollWidth - totalWidthRemoved;

  if (removedChildrenCount > 0) completeScrollWidth += dotsWidth;

  if (completeScrollWidth > offsetWidth) {
    const childIndex = children.length - 2 - removedChildrenCount;
    if (childIndex === -1) return removedChildrenCount;
    const childElement = children[childIndex];
    const { offsetWidth: removedWidth } = childElement;
    removedChildrenCount += 1;
    totalWidthRemoved += removedWidth;

    return calculateChildren(
      domElement,
      removedChildrenCount,
      totalWidthRemoved
    );
  } else return removedChildrenCount;
};

const PopupBreadcrumbs = ({ breadcrumbs }) => {
  return (
    <div className="bg-white p-2 rounded">
      {breadcrumbs.map((v) => {
        return (
          <BreadcrumbsItem
            placement="left"
            className="ssi-link py-2 mb-2 px-3"
            key={v.Id}
            breadcrumb={v}
          />
        );
      })}
    </div>
  );
};

const ResizedBreadcrumbs = ({ value }) => {
  const { leftBreadcrumbs, middleBreadcrumbs, rightBreadcrumbs } = value;

  const anchorRef = useRef();
  const popupRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);

  return (
    <>
      <BreadcrumbsMap breadcrumbs={leftBreadcrumbs} showArrow />
      <div className="d-inline-flex h-100 align-items-center">
        <div
          ref={anchorRef}
          onClick={toggleIsOpen}
          className="d-inline-block align-items-center ar-entity-summary-breadcrumbs-icon"
        >
          <FontAwesomeIcon style={{ width: 16 }} icon={faEllipsisV} size="lg" />
        </div>
      </div>
      <BreadcrumbsMap breadcrumbs={rightBreadcrumbs} showBackArrow />
      <Popup
        domRef={popupRef}
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={Boolean(isOpen)}
      >
        <PopupBreadcrumbs breadcrumbs={middleBreadcrumbs} />
      </Popup>
    </>
  );
};

const ResolvedBreadcrumbs = ({ breadcrumbs, width, textId, id }) => {
  const ref = useRef();

  const [removedChildrenIndexes, setRemovedChildrenIndexes] = useState(0);

  useLayoutEffect(() => {
    const domElem = ref.current;
    const removedChildren = calculateChildren(domElem);
    setRemovedChildrenIndexes(removedChildren);
  }, []);

  const resizedBreadcrumbs = useMemo(() => {
    if (removedChildrenIndexes === 0) return;

    const lastIndex = breadcrumbs.length;
    const startIndex = lastIndex - removedChildrenIndexes;

    const leftBreadcrumbs = breadcrumbs.slice(0, startIndex);
    const middleBreadcrumbs = breadcrumbs.slice(
      startIndex,
      startIndex + removedChildrenIndexes
    );
    const rightBreadcrumbs = breadcrumbs.slice(lastIndex);

    return { leftBreadcrumbs, middleBreadcrumbs, rightBreadcrumbs };
  }, [breadcrumbs, removedChildrenIndexes]);

  return (
    <div className="h-100" ref={ref} style={{ width }}>
      {resizedBreadcrumbs ? (
        <ResizedBreadcrumbs value={resizedBreadcrumbs} />
      ) : (
        <BreadcrumbsMap breadcrumbs={breadcrumbs} />
      )}
      <div className="ar-entity-summary-breadcrumbs-item ">
        <div>
          <FontAwesomeIcon className="mx-2" size="lg" icon={faAngleRight} />
        </div>
        <div className="d-flex align-items-center text-black fs-14 fw-medium">
          <FormattedMessage id={textId} />
          <div className="px-2 ml-2 fs-12 border rounded">Nº {id}</div>
        </div>
      </div>
    </div>
  );
};

const EndpointBreadcrumbs = ({ endpoint, width, textId, id, className }) => {
  const { loading, data, error } = useSpaceQuery(endpoint, null, {
    cache: false
  });

  const breadcrumbs = useMemo(() => {
    if (!data) return;
    const b = [...data];
    b.pop();
    return b;
  }, [data]);

  if (loading || error) return <div />;

  return (
    <div
      style={{ width }}
      className={classnames(
        "of-hidden ar-entity-summary-breadcrumbs",
        className
      )}
    >
      <ResolvedBreadcrumbs
        id={id}
        key={width}
        breadcrumbs={breadcrumbs}
        width={width}
        textId={textId}
      />
    </div>
  );
};

const EntitySummaryBreadcrumbs = ({ schema, id, width, className }) => {
  const { endpoint, textId } = useBreadcrumbsEndpoint(schema, id);

  if (!endpoint)
    return <SingleItem textId={textId} id={id} className={className} />;

  return (
    <EndpointBreadcrumbs
      className={className}
      textId={textId}
      id={id}
      endpoint={endpoint}
      width={width}
    />
  );
};

export default EntitySummaryBreadcrumbs;
