import Tree, { mutateTree } from "@atlaskit/tree";
import {
  faBracketsCurly,
  faChevronDown,
  faChevronRight
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from "react";
import { getIncomers } from "react-flow-renderer";
import { FormattedMessage } from "react-intl";
import styles from "../Automation.module.css";
import classnames from "classnames";
import { NodeTypeConfigDict } from "./ExpressionDict";
import Button from "../../../Components/Button/Button";

const getRecursiveParents = (
  AllElements,
  CurrentNode,
  parents = [],
  nodeDict = {}
) => {
  nodeDict[CurrentNode.id] = true;
  const IncomingNodes = getIncomers(CurrentNode, AllElements);

  for (const n of IncomingNodes) {
    if (nodeDict[n.id]) continue;
    parents.unshift(n);
  }
  for (const n of IncomingNodes) {
    if (nodeDict[n.id]) continue;
    getRecursiveParents(AllElements, n, parents, nodeDict);
  }
  return parents;
};

const getRecursiveProperties = (
  CurrentProperty,
  parentNodeId,
  items = {},
  recursiveNodeId = parentNodeId
) => {
  const propertyId = `${recursiveNodeId}_${CurrentProperty.propertyName}`;

  const propertyItem = {
    id: propertyId,
    isExpanded: false,
    children: [],
    hasChildren: Boolean(CurrentProperty.children),
    data: {
      parentNodeId,
      title: CurrentProperty.propertyTitle,
      name: CurrentProperty.propertyName,
      type: CurrentProperty.propertyType,
      sample: CurrentProperty.propertySample
    }
  };
  items[propertyId] = propertyItem;
  if (CurrentProperty.children) {
    for (const child of CurrentProperty.children) {
      propertyItem.children.push(`${propertyId}_${child.propertyName}`);
      getRecursiveProperties(child, parentNodeId, items, propertyId);
    }
  }
  return items;
};

const RenderItem = (props) => {
  return <RenderItemComponent {...props} />;
};

const RenderItemComponent = ({ item, onExpand, onCollapse, provided }) => {
  const addExpression = useContext(AddExpressionContext);

  const { isExpanded, id, children } = item;

  const { name, title, sample, type, parentNodeId } = item.data;

  const handleClick = () => {
    return isExpanded ? onCollapse(id) : onExpand(id);
  };

  const isFolder = children && children.length > 0;

  const handleExpressionClick = () => {
    addExpression(parentNodeId, name);
  };

  return (
    <div ref={provided.innerRef} {...provided.draggableProps}>
      <div
        className={classnames(
          styles["disable-selection"],
          "d-flex fs-14 text-black align-items-center mb-2"
        )}
      >
        {isFolder && (
          <div className="mr-2 cursor-pointer" onClick={handleClick}>
            <FontAwesomeIcon
              icon={isExpanded ? faChevronDown : faChevronRight}
            />
          </div>
        )}
        {isFolder && (
          <span onClick={handleClick} className="cursor-pointer">
            {title ? title : ""}
          </span>
        )}
        {!isFolder && (
          <div
            style={{ borderRadius: 3 }}
            onClick={handleExpressionClick}
            className="border bg-white cursor-pointer p-2 fs-12 text-grey"
          >
            <div> {title ? title : ""}</div>
            <div className="d-flex align-items-center">
              {"Type: " + type} {sample ? ` | E.g: ${sample}` : ""}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ExpressionTree = React.memo(({ aboveNodes, includesSelf }) => {
  const [treeData, setTreeData] = useState(() => {
    const rootItem = {
      children: [],
      id: "base-0",
      isExpanded: false,
      hasChildren: true,
      data: {
        title: "Nodes"
      }
    };

    const TreeItems = {
      rootId: "base-0",
      items: {
        "base-0": rootItem
      }
    };

    for (const node of aboveNodes) {
      const {
        ownProperties,
        getOwnProperties,
        propertiesTitle,
        groupedProperties
      } = NodeTypeConfigDict[node.type] || {};
      let NodeProperties;

      if (getOwnProperties) {
        NodeProperties = getOwnProperties(node);
      } else {
        NodeProperties = ownProperties;
      }
      if (!NodeProperties || NodeProperties.length === 0) continue;

      const item = {
        id: node.id,
        isExpanded: false,
        children: [],
        hasChildren: true,
        data: {
          title: node.id
        }
      };

      TreeItems.items[node.id] = item;
      rootItem.children.push(node.id);
      let propertyParent;
      if (!propertiesTitle) {
        propertyParent = item;
      } else {
        item.children.push(node.id + node.type);
        propertyParent = {
          id: node.id + node.type,
          isExpanded: false,
          children: [],
          hasChildren: true,
          data: {
            title: propertiesTitle
          }
        };
        TreeItems.items[node.id + node.type] = propertyParent;
      }

      for (const property of NodeProperties) {
        const items = getRecursiveProperties(property, node.id);
        propertyParent.children.push(`${node.id}_${property.propertyName}`);
        TreeItems.items = {
          ...TreeItems.items,
          ...items
        };
      }

      if (Array.isArray(groupedProperties) && groupedProperties.length > 0) {
        for (const property of groupedProperties) {
          const items = getRecursiveProperties(property, node.id);
          item.children.push(`${node.id}_${property.propertyName}`);
          TreeItems.items = {
            ...TreeItems.items,
            ...items
          };
        }
      }
    }

    return TreeItems;
  }, [aboveNodes]);

  const onExpand = useCallback((itemId) => {
    setTreeData((oldTreeData) => {
      return mutateTree(oldTreeData, itemId, { isExpanded: true });
    });
  }, []);

  const onCollapse = useCallback((itemId) => {
    setTreeData((oldTreeData) => {
      return mutateTree(oldTreeData, itemId, { isExpanded: false });
    });
  }, []);

  return (
    <Tree
      tree={treeData}
      renderItem={RenderItem}
      onExpand={onExpand}
      onCollapse={onCollapse}
    />
  );
});

const AddExpressionContext = React.createContext();

const ExpressionModal = ({
  isOpen,
  includesSelf,
  closeModal,
  handleChange,
  AllElements,
  CurrentNodeId,
  ModalOptionsRef
}) => {
  const textAreaRef = useRef();
  const { inputString, value, onChange } = ModalOptionsRef.current;

  const [expressionText, setExpressionText] = useState(() => {
    if (!value) {
      return "";
    } else {
      if (value[0] === "=") {
        return value.substring(1);
      } else return `${value}`;
    }
  });

  const onExpressionChange = (e) => {
    const { value } = e.target;
    setExpressionText(value);
  };

  const data = useMemo(() => {
    const CurrentNode = AllElements.find((e) => e.id === CurrentNodeId);

    let aboveNodes = [];
    if (includesSelf) aboveNodes.push(CurrentNode);
    getRecursiveParents(AllElements, CurrentNode, aboveNodes);

    return aboveNodes;
  }, [AllElements, CurrentNodeId, includesSelf]);

  // console.log(data);

  const addExpression = (title, val) => {
    setExpressionText((oldText) => {
      const CursorPos = textAreaRef.current.selectionStart || 0;
      let resolvedVal = val.split(".");
      for (let index = 0; index < resolvedVal.length; index++) {
        resolvedVal[index] = `["${resolvedVal[index]}"]`;
      }
      const newExpress = val
        ? `{{adn["${title}"]${resolvedVal.join("")}}}`
        : `{{adn["${title}"]}}`;
      return (
        oldText.slice(0, CursorPos) +
        newExpress +
        oldText.slice(CursorPos, oldText.length - 1)
      );
    });
    textAreaRef.current.focus();
  };

  const handleSave = () => {
    if (expressionText.length === 0) {
      onChange("");
      closeModal();
    } else if (expressionText[0] === "=") {
      onChange(expressionText);
      closeModal();
    } else {
      onChange(`=${expressionText}`);
      closeModal();
    }
  };

  return (
    <div
      className={classnames(
        styles.ExpressionModalContainer,
        "d-flex flex-column"
      )}
    >
      <div className="d-flex position-relative of-hidden flex-1">
        {/* Esquerda */}
        <div
          className={classnames(
            styles.ExpressionModalContainerLeft,
            "of-hidden flex-1 d-flex flex-column of-hidden text-black"
          )}
        >
          <div className="d-flex align-items-center p-4 border-bottom fs-16">
            <FontAwesomeIcon className="mr-2" icon={faBracketsCurly} />
            <FormattedMessage id="VARIABLE_SELCTION" />
          </div>
          <div className="p-4 flex-1 overflow-auto">
            <AddExpressionContext.Provider value={addExpression}>
              <ExpressionTree aboveNodes={data} />
            </AddExpressionContext.Provider>
          </div>
        </div>
        {/* Direita */}
        <div
          className={classnames(
            styles.ExpressionModalContainerRight,
            "of-hidden p-4 flex-1 d-flex flex-column text-black"
          )}
        >
          <div className="d-flex align-items-center fs-16 mb-3">
            <FontAwesomeIcon className="mr-2" icon={faBracketsCurly} />
            <FormattedMessage className="mr-2" id="VARIABLE_SELCTION" />
            <div className="border-1 p-1">{inputString}</div>
          </div>
          <textarea
            value={expressionText}
            onChange={onExpressionChange}
            ref={textAreaRef}
            className={classnames(
              styles.ExpressionModalContainerRightContainer,
              "border bg-airdesk  w-100 flex-1 text-black p-2"
            )}
          ></textarea>
        </div>
      </div>
      {/* Footer */}
      <div
        className={classnames(
          styles.ExpressionModalContainerBottom,
          "of-hidden position-relative d-flex justify-content-end aling-items-center bg-white"
        )}
      >
        <Button onClick={handleSave}>
          <FormattedMessage id="SAVE" />
        </Button>
      </div>
    </div>
  );
};

export default ExpressionModal;
