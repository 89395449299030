import React from "react";
import "./BillingPlan.css";
import { FormattedMessage, useIntl } from "react-intl";
import { useSpace } from "../../Contexts/SpaceContext";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRocketLaunch,
  faExclamationTriangle,
  faCheck
} from "@fortawesome/pro-light-svg-icons";
import { isOwner } from "../../Helpers/ModulesHelper";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import classnames from "classnames";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";

export const BillingPlansDict = {
  1: "STARTER",
  2: "BASIC",
  3: "PROFESSIONAL",
  4: "COMPANY"
};

export const BillingPlansClassnameDict = {
  1: "starter",
  2: "basic",
  3: "pro",
  4: "company"
};

export const BillingPlansEnum = {
  starter: 1,
  basic: 2,
  pro: 3,
  company: 4
};

export const spaceBlockTypeEnumReversed = {
  0: "Active",
  1: "NotRenovated",
  2: "Canceled",
  3: "WaitingForPayment",
  5: "TrialEnded"
};

export const spaceBlockTypeEnum = {
  Active: 0,
  NotRenovated: 1,
  Canceled: 2,
  WaitingForPayment: 3,
  TrialEnded: 5
};

export const BillingPlansComponentDict = {
  1: (
    <div className="BillingPlan-Payment-Plan starter d-inline-flex align-items-center">
      <FormattedMessage id="PLAN_STARTER" />
    </div>
  ),
  2: (
    <div className="BillingPlan-Payment-Plan basic d-inline-flex align-items-center">
      <FormattedMessage id="PLAN_BASIC" />
    </div>
  ),
  3: (
    <div className="BillingPlan-Payment-Plan pro d-inline-flex align-items-center">
      <FormattedMessage id="PLAN_PRO" />
    </div>
  ),
  4: (
    <div className="BillingPlan-Payment-Plan company d-inline-flex align-items-center">
      <FormattedMessage id="PLAN_COMPANY" />
    </div>
  )
};

export const buildBillingStatusType = (status, showExpired) => {
  switch (parseInt(status)) {
    case spaceBlockTypeEnum.Canceled:
    case spaceBlockTypeEnum.NotRenovated:
    case spaceBlockTypeEnum.TrialEnded:
      return "danger";
    case spaceBlockTypeEnum.WaitingForPayment:
      return "yellow";

    default:
      return "success";
  }
};
const statusNameByValue = (status) => {
  switch (parseInt(status)) {
    case spaceBlockTypeEnum.Canceled:
      return <FormattedMessage id={"SUBSCRIPTION_CANCELED"} />;
    case spaceBlockTypeEnum.TrialEnded:
      return <FormattedMessage id={"TRIAL_ENDED"} />;
    case spaceBlockTypeEnum.NotRenovated:
      return <FormattedMessage id={"SUBSCRIPTION_NOT_RENOVATED"} />;
    case spaceBlockTypeEnum.WaitingForPayment:
      return <FormattedMessage id={"WAITING_PAYMENT"} />;
    default:
      return <FormattedMessage id={"ACTIVE"} />;
  }
};
export const BillingPlanStatusProfile = ({
  status,
  // item,
  hideName,
  className,
  dotClassName,
  inline,
  showExpired,
  spaceItem
  // value,
}) => {
  const resovledStatus = status;

  if (resovledStatus === undefined) return null;

  if (
    spaceItem &&
    spaceItem.BlockType === spaceBlockTypeEnum.Active &&
    !spaceItem.NextInvoiceDate &&
    !spaceItem.IsManualPayment &&
    !spaceItem.IsSubscribed &&
    spaceItem.TrialEndDate &&
    new Date(spaceItem.TrialEndDate) < new Date()
  ) {
    return (
      <div
        className={classnames(`link-color status-color-orange`, className, {
          "d-flex align-items-center": !inline,
          "d-inline-block": inline
        })}
      >
        <div
          className={classnames("position-relative status-dot", dotClassName)}
        />
        {!hideName && (
          <div className="status-label text-black fw-medium text-truncate fs-12">
            <FormattedMessage id={"SUBSCRIPTION_EXPIRED"} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={classnames(
        `link-color status-color-${buildBillingStatusType(resovledStatus)}`,
        className,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div
        className={classnames("position-relative status-dot", dotClassName)}
      />
      {!hideName && (
        <div className="status-label text-black text-truncate fw-medium fs-12">
          {statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

export const BillingPlanCheck = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center rounded-50"
      style={{ width: 32, height: 32, background: "#E6FAF4", color: "#36AB80" }}
    >
      <FontAwesomeIcon icon={faCheck} size="lg" />
    </div>
  );
};

const BillingPlanPayment = () => {
  return <div></div>;
};

export const TrialPlanHeaderButton = ({ enableVertical }) => {
  const intl = useIntl();
  const space = useSpace();
  const currentAccount = useCurrentAccountSpace();
  if (!space.TrialEnd || !isOwner(currentAccount)) {
    return null;
  }
  const timeLeft = moment(space.TrialEnd).diff(moment(), "days");

  if (enableVertical)
    return (
      <InformationComponent
        placement="right"
				disablePortal
        style={{ margin: "10px 10px" }}
        text={`${timeLeft} ${intl.formatMessage({
          id: "FREE_TRIAL_DAYS_LEFT"
        })}`}
      >
        <Link to={`/s/${space.Id}/plans`}>
          <Button size="sm" className="text-truncate" type="button">
            <FontAwesomeIcon icon={faRocketLaunch} />
          </Button>
        </Link>
      </InformationComponent>
    );
  else
    return (
      <Link to={`/s/${space.Id}/plans`}>
        <Button size="sm" className="text-truncate" type="button">
          <span className="mr-1">
            <FontAwesomeIcon icon={faRocketLaunch} />
          </span>
          <span className="mr-1">{timeLeft}</span>
          <FormattedMessage id="FREE_TRIAL_DAYS_LEFT" />
        </Button>
      </Link>
    );
};

export const CancelationPlanHeaderButton = ({ enableVertical }) => {
  const space = useSpace();
  const intl = useIntl();
  const currentAccount = useCurrentAccountSpace();
  if (!space.PaymentFail || !isOwner(currentAccount)) {
    return null;
  }

  const timeLeft = moment(space.PaymentFail.CancelDate).diff(moment(), "days");

  if (enableVertical)
    return (
      <InformationComponent
        text={`${intl.formatMessage({
          id: "UPDATE_PAYMENT_METHOD_WARNING_PART_ONE"
        })} ${timeLeft} ${intl.formatMessage({
          id: "UPDATE_PAYMENT_METHOD_WARNING_PART_TWO"
        })}`}
      >
        <Link to={`/s/${space.Id}/plans`} className="my-1">
          <Button
            vType="danger-ghost-alt"
            className="fs-14"
            size="sm"
            type="button"
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </Button>
        </Link>
      </InformationComponent>
    );
  else
    return (
      <Link to={`/s/${space.Id}/plans`}>
        <Button
          vType="danger-ghost-alt"
          className="fs-14"
          size="sm"
          type="button"
        >
          <span className="mr-1">
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </span>
          <FormattedMessage id="UPDATE_PAYMENT_METHOD_WARNING_PART_ONE" />
          <span className="mr-1">{timeLeft}</span>
          <FormattedMessage id="UPDATE_PAYMENT_METHOD_WARNING_PART_TWO" />
        </Button>
      </Link>
    );
};

export default BillingPlanPayment;
