import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import NodeContainer from "./NodeContainer";
import classnames from "classnames";
import styles from "../AutomationDetails.module.css";
import { NodeShowDetailsContext } from "../AutomationDetailsHelper";
import { shadeColor } from "../../../../Helpers/EntityHelper";
const NodeIconNameContainer = ({
  enabled,
  icon,
  onDoubleClick,
  text,
  subtittle,
  selected,
  config,
  type
}) => {
  const { note, hasCustomColor, customColor } = config || {};
  const darkerCustomColor = hasCustomColor
    ? shadeColor(customColor, -40)
    : undefined;
  const canShowDetails = useContext(NodeShowDetailsContext);
  return (
    <NodeContainer
      enabled={enabled}
      selected={selected}
      onDoubleClick={onDoubleClick}
    >
      <div
        style={{
          background: hasCustomColor ? customColor : undefined,
          color: darkerCustomColor
        }}
        className={classnames(styles.nodeIcon, styles[type], "p-3")}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="px-3 py-2 d-flex justify-content-center flex-column bg-white text-black fs-14">
        <div className="fw-medium">{text}</div>
        {canShowDetails && note && (
          <div
            className={classnames(styles.nodeNoteContainer, "fs-10 breakWord")}
          >
            {note}
          </div>
        )}
      </div>
    </NodeContainer>
  );
};

export default NodeIconNameContainer;
