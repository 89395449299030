import React from "react";
import { createFormRequestHook, useFormState } from "../../../Components/Forms";
import { spaceTaskTypeSchema } from "../../../config/schema";
import { useTaskType } from "../../../Hooks/EntityHooks";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";
import { useIntl, FormattedMessage } from "react-intl";
import {
  useSidebarSpaceDelete,
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpacePost
} from "../../../Components/Sidebar/SidebarV2Helper";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../../Components/Forms/SideMenuForm";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import { OrganizationDropdown } from "../../AdvancedMultiInputs";
import Switch from "../../../Components/Switch/Switch";

export const convertEntityToForm = (entity) => {
  return {
    ...entity
  };
};

const useTaskTypeRequest = createFormRequestHook({
  schema: spaceTaskTypeSchema,
  convertEntityToForm: convertEntityToForm
});

const TaskTypeForm = (props) => {
  const { id, onSuccess, ...rest } = props;

  const {
    // key,
    loading,
    error,
    convertedForm
    // isChild,
    // childProps,
  } = useTaskTypeRequest(props);

  const taskType = useTaskType(id);

  const canEdit = taskType ? taskType.CanEdit : true;

  if (loading || error)
    return <LoaderSpinner center size="sm" className="text-secondary" />;

  return (
    <Form
      onSuccess={onSuccess}
      id={id}
      canEdit={canEdit}
      convertedForm={convertedForm}
      canDelete={taskType?.CanDelete}
      {...rest}
    />
  );
};

const defaultForm = () => {
  return {
    Name: "",
    Organizations: [],
    Enabled: true
  };
};

const Form = ({
  convertedForm,
  id,
  canEdit,
  onSuccess,
  refetchRef,
  isChild,
  canDelete,
  ...props
}) => {
  const formState = useFormState(() => {
    return {
      ...defaultForm(),
      ...convertedForm
    };
  });
  const { form, handleChange } = formState;
  const { Name } = form;

  const createToast = useToast();

  const handleTranslatedError = useHandleError(spaceTaskTypeSchema);

  const intl = useIntl();
  const [post, { loading }] = useSidebarSpacePost(
    id ? `ProjectTaskType/${id}` : `ProjectTaskType`,
    spaceTaskTypeSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "TASK_TYPE" })} ${Name}`,
          description: intl.formatMessage({ id: "SUCCESS_SAVED" })
        });
        onSuccess && onSuccess();
      },
      refetchRef,
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `ProjectTaskType/${id}`,
    spaceTaskTypeSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "TASK_TYPE" })} ${Name}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
        onSuccess && onSuccess();
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  const formatCancelForm = (form) => {
    const { Name, Organizations, Enabled, ...rest } = form;
    return {
      Name,
      Organizations,
      Enabled,
      ...rest
    };
  };

  const handleSubmit = (form) => {
    post(formatCancelForm(form));
  };

  const validateCancelForm = ({ Name, DepartmentTeams }, addError) => {
    if (!Name) addError("Name");
  };

  const handleCheckChanges = ({ target: { name, checked } }) => {
    handleChange((oldF) => {
      return {
        ...oldF,
        [name]: checked
      };
    });
  };

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"TASK_TYPE"} />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideForm
          canEdit={canEdit}
          formState={formState}
          onSubmit={handleSubmit}
          validate={validateCancelForm}
        >
          <SideFormContent className="px-4 py-3">
            <FormInput
              disabled={isChild}
              name="Organizations"
              className="mb-3"
              textId="COMPANIES"
              closeOnSelect
              inputType={OrganizationDropdown}
              multiple
            />

            <FormInput name="Name" textId="NAME" className="mb-3" />

            <FormInput
              name="Enabled"
              onChange={handleCheckChanges}
              textId={"ACTIVE"}
              inputType={Switch}
              className="mb-3"
            />
          </SideFormContent>
          <SideFormFooter
            canDelete={canDelete}
            deleteLoading={deleteLoading}
            handleDelete={remove}
            isLoading={loading}
          />
        </SideForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export default TaskTypeForm;
