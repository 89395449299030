import axios from "axios";
import { apiUrl } from "./config";
import { logout } from "./Helpers/AuthHelper";

export const updateApiToken = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const removeApiToken = () => {
  delete axios.defaults.headers.common["Authorization"];
};

export const configureApi = (store) => {
  let authState = store.getState().Auth;

  axios.defaults.baseURL = apiUrl;
  if (authState.isAuthenticated) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${authState.token}`;
  }

  axios.interceptors.response.use(
    (r) => {
      return Promise.resolve(r.data);
    },
    (e) => {
      if (!e.response) return Promise.reject(e);

      if (e.response.status === 401) {
        delete axios.defaults.headers.common["Authorization"];

        store.dispatch(logout());
      }
      // if (
      //   e.response.data &&
      //   e.response.data.Error &&
      //   e.response.data.Error.Type
      // )
      //   return Promise.reject(e.response.data.Error.Type);

      return Promise.reject({
        status: e.response.status,
        data: e.response.data
      });
    }
  );
};

export default axios;
