import React, { useEffect, useState } from "react";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import { useFormState } from "../../../Components/Forms";
import { FormattedMessage, useIntl } from "react-intl";
import { spaceEmailDomainsSchema } from "../../../config/schema";
import "./Domains.css";
import {
  SideFormContent,
  SideForm,
  SideFormFooter
} from "../../../Components/Forms/SideMenuForm";
import { handleError } from "../../../Helpers/MiscHelper";
import { OrganizationDropdown } from "../../AdvancedMultiInputs";
import { useEmailDomain } from "../../../Hooks/EntityHooks";
import { useSpaceQuery } from "../../../Helpers/IOClient";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useToast } from "../../../Components/Toast/ToastProvider";
import "./Domains.css";
import LoadableButton from "../../../Components/Button/LoadableButton";
import Button from "../../../Components/Button/Button";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpacePost,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import DomainKeys from "./DomainKeys/DomainKeys";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";

const EmailDomainForm = (props) => {
  const { id, onSuccess } = props;

  const { loading, data, error } = useSpaceQuery(
    `EmailDomain/${id ? id : ""}`,
    null,
    {
      cache: false
    }
  );

  const cancel = useEmailDomain(id);

  const canEdit = cancel ? cancel.CanEdit : true;

  return (
    <BaseSidebarContainer width={600}>
      <BaseSidebarHeader>
        {id ? (
          <FormattedMessage id="EDIT_EMAIL_DOMAIN" values={{ id }} />
        ) : (
          <FormattedMessage id="ADD_EMAIL_DOMAIN" />
        )}
      </BaseSidebarHeader>

      <BaseSidebarContent>
        {loading || error ? (
          <LoaderSpinner size="sm text-secondary" center />
        ) : (
          <DomainFormF
            onSuccess={onSuccess}
            id={id}
            canEdit={canEdit}
            keyData={data.DomainKeys}
            convertedForm={cancel}
            {...props}
          />
        )}
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

const defaultForm = () => {
  return { Organizations: [], BaseDomain: "", ResolvedDomain: "" };
};

const validateForm = (
  { Organizations, BaseDomain, ResolvedDomain },
  addError
) => {
  if (!Organizations) addError("Organizations");
  if (!BaseDomain) addError("BaseDomain");
  if (!ResolvedDomain) addError("BaseDomain");
};

const formatForm = (form) => {
  const { Organizations, ResolvedDomain } = form;

  return {
    Companies: Organizations,
    Domain: ResolvedDomain
  };
};

const DomainFormF = ({
  convertedForm,
  id,
  canEdit,
  onSuccess,

  keyData,
  isChild,
  refetchRef,
  ...props
}) => {
  const formState = useFormState(() => {
    return {
      ...defaultForm(),
      ...convertedForm
    };
  });
  const { form, handleChange } = formState;
  const [isDeleting, setIsDeleting] = useState(false);
  const { BaseDomain, ResolvedDomain, Id, CanDelete } = form;
  const emailDomain = useEmailDomain(id);

  const intl = useIntl();
  const createToast = useToast();

  const [post, { loading }] = useSidebarSpacePost(
    id ? `EmailDomain/${id}` : `EmailDomain`,
    spaceEmailDomainsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: intl.formatMessage({ id: "DOMAIN" }),
          description: intl.formatMessage({ id: "SAVE_SUCCESS" })
        });
        if (!id) openSidebar(<EmailDomainForm id={newId} />);
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      },
      refetchRef
    }
  );

  const { loading: loadingRefresh, refetch: postRefresh } = useSpaceQuery(
    `EmailDomain/${Id}/Refresh`,
    spaceEmailDomainsSchema,
    {
      onSuccess: () => {
        createToast({
          pos: "tm",
          type: "success",
          title: <FormattedMessage id="DOMAIN" />,
          description: <FormattedMessage id="VALIDATION_COMPLETE" />
        });
      },
      autoFetch: false
    }
  );

  useEffect(() => {
    if (BaseDomain && ResolvedDomain === "" && Id) {
      handleChange({
        ResolvedDomain: BaseDomain
      });
    }
  }, [BaseDomain, Id, ResolvedDomain, handleChange]);

  const handleSubmit = (form) => {
    post(formatForm(form));
  };

  const handleDomainChange = (e) => {
    const { value } = e.target;
    if (/^[\w\d-_.]+@[a-zA-Z0-9-]+([.][\w]+){1,}$/.test(value)) {
      handleChange({
        BaseDomain: value,
        ResolvedDomain: value
          .match(/@([a-zA-Z0-9-]+[.][\w]+){1,}$/)[0]
          .replace("@", "")
      });
    } else if (/^[@]{0,1}([a-zA-Z0-9-]+[.][\w]+){1,}$/.test(value)) {
      handleChange({
        BaseDomain: value,
        ResolvedDomain: value.match(/([a-zA-Z0-9-]+[.][\w]+){1,}$/)[0]
      });
    } else {
      handleChange({
        ResolvedDomain: "",
        BaseDomain: value
      });
    }
  };

  return (
    <SideForm
      className="flex-1 of-y-auto d-flex flex-column"
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      <SideFormContent noPadding className="p-4">
        <FormInput
          name="Organizations"
          text={<FormattedMessage id="COMPANIES_SUBSIDIARIES" />}
          className="mb-3"
          inputType={OrganizationDropdown}
          closeOnSelect={false}
          multiple
        />
        <FormInput
          disabled={Id && true}
          // preview={Id && true}
          name="BaseDomain"
          onChange={handleDomainChange}
          text={intl.formatMessage({ id: "EMAIL_DOMAIN" })}
          className="mb-2"
        />

        <div className="text-black fs-14 mb-3">
          <FormattedMessage
            id="EMAIL_DOMAIN_WILL_SEND_DESCRIPTION"
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              Domain:
                Id || ResolvedDomain
                  ? `*@${Id ? BaseDomain : ResolvedDomain}`
                  : ""
            }}
          />
        </div>

        <hr />

        {id && <DomainKeys form={emailDomain} />}
      </SideFormContent>
      <SideFormFooter
        isLoading={loading}
        subComponent={
          Id ? (
            <div className="w-100 justify-content-between d-flex">
              {CanDelete ? (
                <Button
                  vType="link-danger fw-bold"
                  className="mr-2"
                  onClick={() => setIsDeleting(true)}
                >
                  <span style={{ fontWeight: 500 }}>
                    <FormattedMessage id="DELETE" />
                  </span>
                </Button>
              ) : (
                <div></div>
              )}
              <LoadableButton
                className="mr-2"
                vType="outline-primary"
                onClick={postRefresh}
                isLoading={loadingRefresh}
              >
                <FormattedMessage id="EMAIL_DOMAIN_VERIFY" />
              </LoadableButton>
            </div>
          ) : null
        }
      >
        {isDeleting && (
          <RemovalFooter
            endpoint={`EmailDomain/${Id}`}
            refetchRef={refetchRef}
            onCancel={() => setIsDeleting(false)}
          />
        )}
      </SideFormFooter>
    </SideForm>
  );
};

export const DomainTypeDictionary = {
  1: "TXT",
  2: "CNAME"
};

export const DomainTypeEnum = {
  TXT: 1,
  CNAME: 2
};

const RemovalFooter = ({ endpoint, refetchRef, onCancel }) => {
  const createToast = useToast();
  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceEmailDomainsSchema);
  const [deleteUser, { loading }] = useSidebarSpaceDelete(
    endpoint,
    spaceEmailDomainsSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "DOMAIN" })}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  return (
    <div className="details-form-footer d-flex justify-content-between align-items-center shadow-top p-3">
      <div className="text-black">
        <FormattedMessage id={"DELETE_DOMAIN_QUESTION"} />
      </div>

      <div className="d-flex align-items-center">
        <Button
          type="button"
          vType="outline-danger"
          className="mr-3"
          onClick={onCancel}
        >
          <FormattedMessage id={"BACK"} />
        </Button>

        <LoadableButton
          type="button"
          vType="danger"
          isLoading={loading}
          onClick={() => {
            deleteUser();
          }}
        >
          <FormattedMessage id={"DELETE"} />
        </LoadableButton>
      </div>
    </div>
  );
};

export default EmailDomainForm;
