import React from "react";
import ReactDOM from "react-dom";
import { RootElement } from "../../Helpers/MiscHelper";

function getContainer(container, defaultContainer) {
  container = typeof container === "function" ? container() : container;
  return ReactDOM.findDOMNode(container) || defaultContainer;
}

/**
 * Portals provide a first-class way to render children into a DOM node
 * that exists outside the DOM hierarchy of the parent component.
 */
class Portal extends React.Component {
  componentDidMount() {
    this.setMountNode(this.props.container);

    // Only rerender if needed

    this.forceUpdate(this.props.onRendered);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.container !== this.props.container ||
      prevProps.disablePortal !== this.props.disablePortal
    ) {
      this.setMountNode(this.props.container);

      // Only rerender if needed
      if (!this.props.disablePortal) {
        this.forceUpdate(this.props.onRendered);
      }
    }
  }

  componentWillUnmount() {
    this.mountNode = null;
  }

  setMountNode(container) {
    if (this.props.disablePortal) {
      this.mountNode = ReactDOM.findDOMNode(this).parentElement;
      return;
    }

    this.mountNode = getContainer(container, RootElement);
  }

  getMountNode = () => {
    return this.mountNode;
  };

  render() {
    const { children, disablePortal } = this.props;

    if (disablePortal) {
      return children;
    }
    // return children;
    return this.mountNode
      ? ReactDOM.createPortal(children, this.mountNode)
      : null;
  }
}

export default Portal;

export const BasicPortal = ({ children }) => {
  return ReactDOM.createPortal(children, RootElement);
};
