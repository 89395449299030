import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const SubscriptionOverviewComp = React.lazy(() =>
  import(
    /* webpackChunkName: "subscriptions-overview" */ "./SubscriptionOverviewComp"
  )
);

const SubscriptionOverview = () => {
  return (
    <SuspenseErrorBoundary>
      <SubscriptionOverviewComp />
    </SuspenseErrorBoundary>
  );
};

export default SubscriptionOverview;
