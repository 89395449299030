const createMainEntityDetailsReducer = reducer => (state = {}, action) => {
  const { internalType } = action;

  if (internalType !== "entityDetail") return state;

  const { name } = action;

  if (!name) return state;

  const currentFormState = state[name];

  const newFormState = reducer(currentFormState, action);

  if (newFormState !== undefined && newFormState !== currentFormState)
    return {
      ...state,
      [name]: newFormState
    };
  else return state;
};

const form = (
  state = {},
  action
) => {
  const { actionName } = action;
  
  switch (action.type) {
    case `FETCH_${actionName}_DETAIL_REQUEST`:
      return {
        ...state,
        [action.entityId]: {
          ...[action.entityId],
          isFetching: true
        }
      };

    case `FETCH_${actionName}_DETAIL_SUCCESS`:
    return {
      ...state,
      [action.entityId]: {
        ...[action.entityId],
        isFetching: false
      }
    };

    case `FETCH_${actionName}_DETAIL_ERROR`:
    return {
      ...state,
      [action.entityId]: {
        ...[action.entityId],
        isFetching: false,
        error: action.error
      }
    };

    default:
      return state;
  }
};

export default createMainEntityDetailsReducer(form);
