import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CreatedBy from "../../Components/CreatedByComponent/CreatedBy";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import PhoneInput from "../../Components/Input/PhoneInput";
import { NifValidator } from "../../Components/NifValidator/NifValidator";
import {
  ClientTypeDropdown,
  ContactDropdown,
  CountryDropdown,
  UserDropdown,
  ClientTagDropdown
} from "../AdvancedMultiInputs";

import { ClientStatusDropdown } from "../../Components/Clients/Clients";
import { AccountProfileImg } from "../../Components/Img/Img";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-light-svg-icons";
import { ProfilePicture } from "../../Components/ProfilePicture/ProfilePicture";
import {
  updateManagerFollowers,
  useCanEditFollowers
} from "../../Components/EntityFollow/EntityFollowHelper";
import { useClient } from "../../Hooks/EntityHooks";
import { useCurrentUserContactPermission } from "../../Helpers/ContactPermissionsHelper";
import { SidebarAutomationContext } from "../../Components/Sidebar/SidebarV2Automation";
import DocTyper from "../../Components/DocTyper/DocTyper";
import { MultipleAddressSelector } from "./ClientFormHelper";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { useSpace } from "../../Contexts/SpaceContext";

const ValidationClientField = {
  NIF: 1,
  Phone: 2,
  Email: 3,
  Domain: 4
};

const ClientFormContent = ({
  formState,
  organizations,
  pipelines,
  id,
  canEdit
}) => {
  const { form, handleChange } = formState;
  const {
    Name,
    Id,
    Image,
    Creator,
    FiscalNumber,
    Manager,
    FiscalCountry,
    Addresses,
    PhoneNumber,
    Email,
    CreationDate,
    ClientTypes,
    Domain,
    // Comments,
    Followers
  } = form;
  const intl = useIntl();
  const handleChanges = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value
    });
  };

  const toTitleCase = (phrase = "") => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleVatVerification = (values) => {
    const ELC = values.CompanyAddress.split("\n");
    const CompanyName = values.CompanyName;
    const CompanyAddress = ELC[0];
    const CompanyLocale = ELC[1];
    const CompanyPostalCode = ELC[2];
    const newAddresses = [...Addresses];
    newAddresses.push({
      Name: `${intl.formatMessage({ id: "NIF" })} ${intl.formatMessage({
        id: "ADDRESS"
      })}`,
      Address1: toTitleCase(CompanyAddress),
      Country: FiscalCountry,
      Locality: toTitleCase(CompanyLocale),
      PostalCode: CompanyPostalCode?.split(" ")[0]
    });

    handleChange({
      Name: toTitleCase(CompanyName),
      Addresses: newAddresses
    });
  };

  const handleAddressSelectChanges = (e) => {
    handleChange({
      ...e
    });
  };

  const noContactReadPermission = !useCurrentUserContactPermission();

  // const followers = useJoinedFollowerAccounts(Manager);
  // const possibleFollowers = useAvailableFollowers(Creator, followers);

  const entity = useClient(id);
  const currentManager = entity ? entity.Manager : Creator;
  const canEditFollowers = useCanEditFollowers(Creator, currentManager);

  const handleManagerChange = useCallback(
    ({ target: { value } }) => {
      handleChange({
        Manager: value,
        Followers: updateManagerFollowers(Followers, Manager, value, [])
      });
    },
    [Followers, Manager, handleChange]
  );

  const [updateNifUrl, setUpdateNifUrl] = useState(false);

  const nifTimeoutRef = useRef();

  useEffect(() => {
    clearTimeout(nifTimeoutRef.current);
    if (FiscalNumber && FiscalNumber.length > 3) {
      nifTimeoutRef.current = setTimeout(() => {
        setUpdateNifUrl(
          `clients/${ValidationClientField.NIF}/Validation?query=${FiscalNumber}&id=${Id}`
        );
      }, 600);
    } else {
      setUpdateNifUrl(null);
    }
    return () => {
      clearTimeout(nifTimeoutRef.current);
    };
  }, [FiscalNumber, Id]);

  const { data: fiscalValidData } = useSpaceQuery(updateNifUrl, null, {
    cache: false
  });

  const [phoneValidationUrl, setPhoneValidationUrl] = useState(null);

  const phoneTimeoutRef = useRef();

  useEffect(() => {
    clearTimeout(phoneTimeoutRef.current);
    if (PhoneNumber && PhoneNumber.length > 3) {
      phoneTimeoutRef.current = setTimeout(() => {
        setPhoneValidationUrl(
          `clients/${ValidationClientField.Phone}/Validation?query=${
            PhoneNumber.split(".")[1]
          }&id=${Id}`
        );
      }, 600);
    } else {
      setPhoneValidationUrl(null);
    }
    return () => {
      clearTimeout(phoneTimeoutRef.current);
    };
  }, [Id, PhoneNumber]);

  const { data: phoneValidData } = useSpaceQuery(phoneValidationUrl, null, {
    cache: false
  });

  const [emailValidationUrl, setemailValidationUrl] = useState(null);

  const emailTimeoutRef = useRef();

  useEffect(() => {
    clearTimeout(emailTimeoutRef.current);
    if (Email && Email.length > 3) {
      emailTimeoutRef.current = setTimeout(() => {
        setemailValidationUrl(
          `clients/${ValidationClientField.Email}/Validation?query=${Email}&id=${Id}`
        );
      }, 600);
    } else {
      setemailValidationUrl(null);
    }
    return () => {
      clearTimeout(emailTimeoutRef.current);
    };
  }, [Email, Id]);

  const { data: emailValidData } = useSpaceQuery(emailValidationUrl, null, {
    cache: false
  });

  const [domainValidationUrl, setdomainValidationUrl] = useState(null);

  const domainTimeoutRef = useRef();

  useEffect(() => {
    clearTimeout(domainTimeoutRef.current);
    if (Domain && Domain.length > 3) {
      domainTimeoutRef.current = setTimeout(() => {
        setdomainValidationUrl(
          `clients/${ValidationClientField.Domain}/Validation?query=${Domain}&id=${Id}`
        );
      }, 600);
    } else {
      setdomainValidationUrl(null);
    }
    return () => {
      clearTimeout(domainTimeoutRef.current);
    };
  }, [Domain, Id]);

  const { data: domainValidData } = useSpaceQuery(domainValidationUrl, null, {
    cache: false
  });

  const Automation = useContext(SidebarAutomationContext);

  return (
    <>
      {Automation && (
        <FormInput
          textId="CREATED_BY"
          name="Creator"
          inputType={UserDropdown}
          className="mb-3"
        />
      )}

      <div className="d-flex mb-3">
        {!Automation && (
          <FormInput
            name="Image"
            className="mr-3"
            textId="THUMBNAIL"
            inputType={ProfilePicture}
            uploadText={
              <div className="link-color fs-12 mt-1 mr-3">
                <FontAwesomeIcon icon={faCamera} />{" "}
                <FormattedMessage id="UPLOAD_IMAGE" />
              </div>
            }
            placeHolderComponent={
              <AccountProfileImg
                className="rounded-50"
                account={{ Name, Image }}
              />
            }
          />
        )}
        <FormInput className="flex-1" textId="NAME" name="Name" />
      </div>
      <FormInput name="CodeERP" className="mb-3" textId="CODEERP" />

      <FormInput
        className="mb-3"
        name="Tags"
        textId="ACCOUNT_TAGS"
        // getKey={(e) => {
        //   const resolvedTag = getEntity(spaceTicketTagsSchema, e);
        //   return `${e}-${resolvedTag?.Pipeline}`;
        // }}
        multiple
        inputType={ClientTagDropdown}
      />

      <FormInput
        textId="STATUS"
        className="mb-3"
        name="Status"
        inputType={ClientStatusDropdown}
      />

      <FormInput
        name="ClientTypes"
        className="mb-3"
        textId="TYPE"
        value={ClientTypes}
        multiple
        inputType={ClientTypeDropdown}
      />

      <FormInput
        disableSelect="disable-selection"
        className="mb-3"
        name={"FiscalCountry"}
        value={FiscalCountry}
        text={<FormattedMessage id="FISCAL_COUNTRY" />}
        inputType={CountryDropdown}
        onChange={handleChanges}
      />

      <FormInput
        name="FiscalNumber"
        disabled={Id ? noContactReadPermission : false}
        textId="NIF"
        value={FiscalNumber}
        country={FiscalCountry}
        onChange={handleChanges}
        onUpdate={handleVatVerification}
        inputType={NifValidator}
      />
      <DuplicatedNifsMessage count={fiscalValidData} nif={FiscalNumber} />

      {Id ? (
        noContactReadPermission ? (
          <></>
        ) : (
          <>
            <hr className="mb-3"></hr>
            <div className="mb-3 text-black fs-16 fw-medium">
              <FormattedMessage id={"LOCATION_ADDRESS"} />
            </div>
            <FormInput
              name="Addresses"
              className="mb-3"
              value={Addresses}
              handleChange={handleAddressSelectChanges}
              preview={!canEdit}
              inputType={MultipleAddressSelector}
            />
          </>
        )
      ) : (
        <>
          <hr className="mb-3"></hr>
          <div className="mb-3 text-black fs-16 fw-medium">
            <FormattedMessage id={"LOCATION_ADDRESS"} />
          </div>
          <FormInput
            name="Addresses"
            className="mb-3"
            value={Addresses}
            handleChange={handleAddressSelectChanges}
            // preview={noContactReadPermission}
            inputType={MultipleAddressSelector}
          />
        </>
      )}

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"CONTACTS"} />
      </div>

      <FormInput
        name="PhoneNumber"
        textId="PHONE_NUMBER"
        className="mb-3"
        disabled={Id ? noContactReadPermission : false}
        inputType={PhoneInput}
      />
      <DuplicatedPhoneNumberMessage
        count={phoneValidData}
        phone={PhoneNumber.split(".")[1]}
      />

      <FormInput
        name="Email"
        disabled={Id ? noContactReadPermission : false}
        textId="EMAIL"
      />
      <DuplicatedEmailMessage count={emailValidData} email={Email} />
      <FormInput
        name="Contacts"
        textId="CONTACTS"
        className="mb-3"
        inputType={ContactDropdown}
        multiple
      />

      <FormInput
        className="mb-3"
        name="Manager"
        textId="MANAGER"
        inputType={UserDropdown}
        onChange={handleManagerChange}
      />

      <FormInput
        name="Followers"
        textId="FOLLOWERS"
        inputType={UserDropdown}
        multiple
        // items={possibleFollowers}
        className="mb-3"
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id="DETAILS" />
      </div>

      {/* <FormInput
          name="Industries"
          textId="industry"
          className="mb-3"
          inputType={IndustryDropdown}
          multiple
        /> */}

      <FormInput placeholder="example.com" name="Domain" textId="DOMAIN" />
      <DuplicatedDomainMessage count={domainValidData} domain={Domain} />
      <FormInput
        name="Description"
        textId="DESCRIPTION"
        inputType={DocTyper}
        className="mb-3"
      />

      {/* <div className="height-1"></div>
        {id && (
          <>
            <hr className="mb-3"></hr>
            <div className="mb-3">
              <span className="text-black fs-16 fw-medium">Comentários</span>
            </div>
            <div className="mb-3">
              <CommentModule Type="client" Id={id} Comments={Comments || []} />
            </div>
          </>
        )} */}

      {/* <div className="mb-3">
          <CommentModule Type="client" Id={id} Comments={Comments || []} />
        </div> */}
      <CreatedBy Creator={Creator} CreationDate={CreationDate} />
    </>
  );
};

const DuplicatedDomainMessage = ({ count, domain }) => {
  const space = useSpace();
  const intl = useIntl();
  if (parseInt(count) > 0) {
    const url = `${window.location.origin}/s/${space.Id}/customers?%24filter=Client%2FDomain%20contains%20%27${domain}%27&query=`;
    const type = intl.formatMessage({ id: "DOMAIN" });
    return (
      <div className="mb-3 fs-12 text-danger-alt">
        <FormattedMessage
          id="DUPLICATE_MESSAGE_FOR_CLIENTS"
          values={{
            a: (...chunks) => (
              <a
                target={"_blank"}
                rel="noreferrer"
                className="text-danger-alt"
                href={url}
              >
                {chunks}
              </a>
            ),
            type,
            count
          }}
        />
      </div>
    );
  } else {
    return <div className="mb-3"></div>;
  }
};
const DuplicatedNifsMessage = ({ count, nif }) => {
  const space = useSpace();
  const intl = useIntl();
  if (parseInt(count) > 0) {
    const url = `${window.location.origin}/s/${space.Id}/customers?%24filter=Client%2FFiscalNumber%20contains%20%27${nif}%27&query=`;
    const type = intl.formatMessage({ id: "NIF" });
    return (
      <div className="mb-3 fs-12 text-danger-alt">
        <FormattedMessage
          id="DUPLICATE_MESSAGE_FOR_CLIENTS"
          values={{
            a: (...chunks) => (
              <a
                target={"_blank"}
                rel="noreferrer"
                className="text-danger-alt"
                href={url}
              >
                {chunks}
              </a>
            ),
            type,
            count
          }}
        />
      </div>
    );
  } else {
    return <div className="mb-3"></div>;
  }
};
const DuplicatedEmailMessage = ({ count, email }) => {
  const space = useSpace();
  const intl = useIntl();
  if (parseInt(count) > 0) {
    const url = `${window.location.origin}/s/${space.Id}/customers?%24filter=Client%2FEmail%20contains%20%27${email}%27&query=`;
    const type = intl.formatMessage({ id: "EMAIL" });
    return (
      <div className="mb-3 fs-12 text-danger-alt">
        <FormattedMessage
          id="DUPLICATE_MESSAGE_FOR_CLIENTS"
          values={{
            a: (...chunks) => (
              <a
                target={"_blank"}
                rel="noreferrer"
                className="text-danger-alt"
                href={url}
              >
                {chunks}
              </a>
            ),
            type,
            count
          }}
        />
      </div>
    );
  } else {
    return <div className="mb-3"></div>;
  }
};
const DuplicatedPhoneNumberMessage = ({ count, phone }) => {
  const space = useSpace();
  const intl = useIntl();
  if (parseInt(count) > 0) {
    const url = `${window.location.origin}/s/${space.Id}/customers?%24filter=Client%2FPhoneNumber%20contains%20%27${phone}%27&query=`;
    const type = intl.formatMessage({ id: "PHONE_NUMBER" });
    return (
      <div className="mb-3 fs-12 text-danger-alt">
        <FormattedMessage
          id="DUPLICATE_MESSAGE_FOR_CLIENTS"
          values={{
            a: (...chunks) => (
              <a
                target={"_blank"}
                rel="noreferrer"
                className="text-danger-alt"
                href={url}
              >
                {chunks}
              </a>
            ),
            type,
            count
          }}
        />
      </div>
    );
  } else {
    return <div className="mb-3"></div>;
  }
};

export default ClientFormContent;
