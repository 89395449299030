import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useRef
} from "react";
import SpaceProvider, {
  useSpace,
  useSpaceEntity
} from "../../Contexts/SpaceContext";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from "react-router";
import { ReactComponent as AirdeskLogo } from "../../Assets/Svg/airdesk_icon_med.svg";
import {
  hasAtLeastOneModuleAccess,
  getAccessLevel,
  accessLevels,
  isAdmin,
  isDeveloper
} from "../../Helpers/ModulesHelper";
import HeaderV2, {
  MainHeaderSection,
  HeaderNavLink,
  useMainViewSearchDict,
  HeaderLink
} from "../../Components/Header/HeaderV2";
import {
  useCurrentAccount,
  useCurrentAccountSpace
} from "../../Contexts/UserContext";
import { ViewsEnum } from "../../Interfaces/EntityInterfaces";
import NotificationProvider from "../Notifications/NotificationProvider";
import SuspenseErrorBoundary from "../../Components/Suspense/SuspenseErrorBoundary";
import {
  contactsGroup,
  commercialGroup,
  servicesGroup,
  availableRoutes,
  SpaceAccessProvider,
  useSpaceRoutesAccess,
  canAccessRouteFromDict,
  settingsRoute,
  SpaceMainUrlProvider,
  // MarketingGroup,
  MarketingGroup,
  spaceCanceledRoutes,
  // tempAvailableRoutes,
  // ReportsGroupMaster,
  reportingOverview,
  // reportDashboardRoute,
  // ReportsGroupMaster,
  documentsGroup
  // documentsRoute
} from "./SpaceHelper";
import HeaderLinkGroup from "../../Components/Header/HeaderLinkGroup";
import { NavLink } from "react-router-dom";
import AirdeskIconSvg from "../../Components/Svg/AirdeskIconSvg";
import OmniButton from "../OmniButton/OmniButton";
import GlobalSearch from "../../Components/GlobalSearch/GlobalSearch";
import { TimeTrackingFormButton } from "../TimeTracking/TimeTracking";
import NotificationsBell from "../Notifications/NotificationsBell";
import AccountPopup, { AccountSpaceSelector } from "../AccountPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "../AppBar/AppBar.module.css";
import classnames from "classnames";
import EntitySynchronizer from "../EntitySynchronizer/EntitySynchronizer";
import { lsDashboardKey } from "../Dashboard/DashboardHelper";
import { PersonalHistoryButton } from "../PersonalHistory/PersonalHistory";
// import MaintenanceIcon from "../Maintenance/MaintenanceIcon";
import { SidebarProvider } from "../../Components/Sidebar/SidebarV2";
import { EntitySummarySidebarProvider } from "../../Components/EntitySummaries/EntitySummarySidebar";
import { UnreadEntitiesProvider } from "../../Components/UnreadEntities/UnreadEntities";
import { SpaceRealTimeProvider } from "../RealTime/RealTimeSpace";
import AccountsInRoomSynchronizer from "../../Components/AccountsInRoom/AccountsInRoomSynchronizer";
import { FormattedMessage, useIntl } from "react-intl";
import {
  useSpaceOnboardingState,
  onboardingStateEnum
} from "../SpaceOnboarding/SpaceOnboardingHelper";
import MainLoader from "../../Components/Loader/MainLoader";
import { MainViewPreferenceProvider } from "../../Components/Preferences/Preferences";
import {
  // TrialPlanHeaderButton,
  spaceBlockTypeEnum
  // CancelationPlanHeaderButton
} from "../BillingPlans/BillingPlanPayment";
import ReceptionInboxButton from "../Reception/ReceptionInboxButton";
import SpaceNotificator from "./SpaceNotificator";
import PersonalExportButton from "../Accounts/ExportsGrid/PersonalExportsButton";
import NotificationsSetup from "../NotificationsSetup/NotificationsSetup";
import { NotificationsSetupContext } from "./SpaceRoutes";
import NoPermission from "../NoPermission/NoPermission";
import SmallScreen from "../SmallScreen/SmallScreen";
import { AppViewContext, AppViewProvider } from "../AppBar/AppBarHelper";
import AppBar from "../AppBar/AppBar";
import FavoriteProvider from "../AppBar/AppFavoriteProvider";
import AppBarFavoriteQuery from "../AppBar/AppBarFavoriteQuery";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";
import AppSidebarHorizontalFavoritesComponent from "../AppBar/AppSidebarHorizontalFavorites";
import { useHoverEvents } from "../Origin/Origin";
import {
  faChevronDown
  // faSidebar as faWindowAlt
} from "@fortawesome/pro-light-svg-icons";
import {
  faCog
  // faHouse,
  // faSquareStar,
  // faStar
} from "@fortawesome/pro-duotone-svg-icons";
import { NotificationBrowserBoxProvider } from "../../Components/NotificationBrowserBox/NotificationBrowserBox";
import Popup, { usePopupOpenState } from "../../Components/Popup/Popup";
import {
  MainViewsSearchLSKey,
  LastUsedSpaceLSKey,
  NewMainViewsSearchLSKey
} from "../../Helpers/MiscHelper";
import Button from "../../Components/Button/Button";
import InfoBarController from "../../Components/Header/InfoBars";
import { connect } from "react-redux";
import { ASSIGN_CURRENT_SPACE } from "../../Redux/Actions/Space";
// eslint-disable-next-line no-unused-vars
const AirdeskIcon = ({ className }) => {
  const dict = useMainViewSearchDict();
  const ObfuscateState = useContext(ObfuscateProvider);
  const { Id } = useSpace();
  let search = dict[lsDashboardKey];
  if (search) search = `?${search}`;

  const handleClick = (e) => {
    if (ObfuscateState === true) e.preventDefault();
  };

  return (
    <NavLink
      activeClassName={"ar-header-link selected"}
      style={{ pointerEvents: ObfuscateState && "none" }}
      onClick={handleClick}
      to={`/s/${Id}/dashboard${search}`}
      className={classnames(className)}
    >
      <span style={{ opacity: ObfuscateState && 0.3 }}>
        <AirdeskIconSvg />
      </span>
    </NavLink>
  );
};

const SettingsLink = ({ ...props }) => {
  const ObfuscateState = useContext(ObfuscateProvider);
  const { text, tooltipPlacement } = props;
  const accessDict = useSpaceRoutesAccess();
  const asAccess = useMemo(() => {
    return accessDict[settingsRoute.path];
  }, [accessDict]);

  if (!asAccess) return null;

  return (
    <>
      <div className={classnames("ml-1")}>
        <InformationComponent
          placement={tooltipPlacement || "right"}
          style={{ margin: "10px 10px" }}
          text={text}
        >
          <HeaderNavLink
            routeModule={settingsRoute}
            className={"ar-header-rounded-icon"}
          >
            <span
              style={{
                opacity: ObfuscateState && 0.3,
                pointerEvents: ObfuscateState && "none"
              }}
            >
              <FontAwesomeIcon icon={faCog} size="lg" />
            </span>
          </HeaderNavLink>
        </InformationComponent>
      </div>
    </>
  );
};

export const ObfuscateProvider = React.createContext();

const AirdeskHeader = React.memo(function AirdeskHeader({
  disableHeader,
  stickyFavorites,
  setStickyFavorites,
  objuscateButtons,
  onAnchorMouseEnter,
  isOpen,
  onAnchorMouseLeave
}) {
  let resolvedCommercialGroup = commercialGroup;
  // if (space.Id < 4) {
  //   resolvedCommercialGroup = commercialGroup;
  // } else {
  //   resolvedCommercialGroup = commercialLitGroup;
  // }
  const space = useSpace();

  let resolvedServicesGroup;
  // if (space.Id < 4) {
  resolvedServicesGroup = servicesGroup;
  // } else {
  //   resolvedServicesGroup = servicesLitGroup;
  // }

  // let resolvedReportsGroup = reportsGroup;
  // if (space.Id < 4) {
  //   resolvedReportsGroup = reportsGroup;
  // } else {
  //   resolvedReportsGroup = reportsLitGroup;
  // }

  const spaceSelectorRef = useRef(null);
  const spaceSelectorPopupRef = useRef(null);

  const [spaceSelectiorIsOpen, spaceSelectiorToggleIsOpen] = usePopupOpenState(
    spaceSelectorPopupRef,
    spaceSelectorRef
  );

  const lastUsedSpace = localStorage.getItem(LastUsedSpaceLSKey);

  const NewDictString = localStorage.getItem(
    NewMainViewsSearchLSKey + lastUsedSpace
  );
  const NullNewDictString = localStorage.getItem(
    NewMainViewsSearchLSKey + "null"
  );

  const dictString = localStorage.getItem(MainViewsSearchLSKey);

  let dict = {};

  if (NewDictString) dict = JSON.parse(NewDictString);
  else if (NullNewDictString) {
    localStorage.setItem(
      NewMainViewsSearchLSKey + lastUsedSpace,
      NullNewDictString
    );
    localStorage.removeItem(NewMainViewsSearchLSKey + "null");
    dict = JSON.parse(NullNewDictString);
  } else if (dictString) {
    localStorage.setItem(NewMainViewsSearchLSKey + lastUsedSpace, dictString);
    localStorage.removeItem(MainViewsSearchLSKey);
    dict = JSON.parse(dictString);
  }

  // const lsItem = localStorage.getItem(MainViewsSearchLSKey);

  let search = dict[lsDashboardKey];
  if (search) search = `?${search}`;

  const history = useHistory();
  const handleClickForSpaceList = () => {
    localStorage.removeItem("Airdesk-Previous-Space");
    history.push("/s");
  };

  const account = useCurrentAccount();

  const intl = useIntl();

  return (
    <>
      <HeaderV2
        disableHeader={disableHeader}
        objuscateButtons={objuscateButtons}
      >
        <ObfuscateProvider.Provider value={objuscateButtons}>
          {/* <AirdeskIcon className="ar-header-dashboard-icon" />
          <span
            style={{ maxWidth: 160 }}
            className="fw-medium flex-0-0-auto fs-14 text-white text-truncate"
          >
            {space.Name}
          </span> */}
          <Popup
            domRef={spaceSelectorPopupRef}
            placement="bottom-start"
            modifiers={{
              preventOverflow: { boundariesElement: "viewport" }
            }}
            anchorEl={spaceSelectorRef.current}
            isOpen={Boolean(spaceSelectiorIsOpen)}
          >
            <div
              className={classnames(
                "pt-2",
                classes.appBarSpaceSelectorContainer
              )}
            >
              <div className="d-flex flex-column h-100">
                <div className="flex-0-0-auto text-black fs-16 fw-medium">
                  <FormattedMessage id="WORKSPACES" />
                </div>
                <div className={classes.appBarSpaceSelectorDividerBefore}></div>
                <div className="of-x-hidden of-y-auto flex-1">
                  <AccountSpaceSelector
                    user={account}
                    children={undefined}
                  ></AccountSpaceSelector>
                </div>
                <div className={classes.appBarSpaceSelectorDividerAfter}></div>
                <Button
                  vType="primary-ghost"
                  onClick={handleClickForSpaceList}
                  className="justify-content-center py-2 d-flex align-items-center"
                >
                  <FormattedMessage id="VIEW_ALL_ITEMS" />
                </Button>
              </div>
            </div>
          </Popup>
          <div
            style={{ width: 224, marginBottom: 0 }}
            ref={spaceSelectorRef}
            className={classnames(
              "d-flex align-items-center",
              classes.appBarContainerHeader
            )}
          >
            <NavLink
              activeClassName={classes["appBarActiveClass"]}
              to={`/s/${space.Id}/dashboard${search ? search : ""}`}
              className={classnames(
                "d-flex justify-content-center flex-0-0-auto align-items-center",
                classes.appBarAirdeskLogoContainer
              )}
            >
              <AirdeskLogo />
            </NavLink>
            <div
              onClick={() => {
                typeof spaceSelectiorToggleIsOpen === "function" &&
                  spaceSelectiorToggleIsOpen(undefined);
              }}
              className="d-flex cursor-pointer overflow-hidden flex-column mx-2 flex-1 disable-selection"
            >
              <div className="d-flex align-items-center">
                <div className="text-white text-truncate fs-14 fw-medium">
                  {space.Name}
                </div>
                <div
                  className={classnames(
                    "ml-2 text-white flex-0-0-auto d-flex align-items-center",
                    classes["appBarSpaceSelectorChev"]
                  )}
                >
                  <FontAwesomeIcon size="sm" icon={faChevronDown} />
                </div>
              </div>
              <div
                className="fs-12"
                style={{
                  color: "#9a9ae8",
                  wordBreak: "keep-all",
                  whiteSpace: "nowrap"
                }}
              >
                <FormattedMessage id="WORKSPACE_LABEL" />
              </div>
            </div>
            {/* <SettingsLink
              size="xl"
              style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
              className="bg-transparent ActionButtonNewColorDefault"
              text={intl.formatMessage({ id: "SETTINGS" })}
            /> */}
          </div>

          <div className="header-splitter ml-2 mr-3" />

          <div
            onClick={() => {
              if (isOpen) {
                localStorage.setItem(
                  `ar-horizontal-bar-favorite-bar-status`,
                  0
                );
                onAnchorMouseLeave();
              } else {
                localStorage.setItem(
                  `ar-horizontal-bar-favorite-bar-status`,
                  1
                );
                onAnchorMouseEnter();
              }
            }}
            // onMouseLeave={onAnchorMouseLeave}
            className={classnames(
              classes["ar-header-horizontal-favorite-button"],
              { [classes["active"]]: isOpen },
              "disable-selection d-flex text-white align-items-center cursor-pointer"
            )}
          >
            <div>
              <FormattedMessage id="FAVORITES" />
            </div>
          </div>

          <div className="d-flex justify-content-between disable-selection h-100 w-100">
            <MainHeaderSection>
              {/* <HeaderLink
                routeModule={reportDashboardRoute}
                forcedChildren={<FontAwesomeIcon icon={faHouse} />}
              ></HeaderLink> */}
              <HeaderLinkGroup
                text={<FormattedMessage id={"CONTACTS"} />}
                group={contactsGroup}
              />
              <HeaderLinkGroup
                text={<FormattedMessage id={"COMERCIAL"} />}
                group={resolvedCommercialGroup}
              />
              <HeaderLinkGroup
                text={<FormattedMessage id={"SERVICES"} />}
                group={resolvedServicesGroup}
              />
              <HeaderLinkGroup
                text={<FormattedMessage id={"MARKETING"} />}
                group={MarketingGroup}
              />
              <HeaderLinkGroup
                text={<FormattedMessage id={"DOCUMENTATION"} />}
                group={documentsGroup}
              />

              {/* <HeaderLink hideIcon routeModule={documentsRoute}></HeaderLink> */}
              <HeaderLink hideIcon routeModule={reportingOverview}></HeaderLink>
              {/* <HeaderLinkGroup
                text={<FormattedMessage id={"REPORTING"} />}
                group={ReportsGroupMaster}
              /> */}

              {/* <HeaderLinkGroup
            text={<FormattedMessage id={"REPORTING"} />}
            group={resolvedReportsGroup}
          /> */}
            </MainHeaderSection>
            <div className="d-flex align-items-center">
              {/* <TrialPlanHeaderButton />
              <CancelationPlanHeaderButton />
              <MaintenanceIcon /> */}

              <OmniButton
                tooltipText={intl.formatMessage({ id: "ACTIONS" })}
                // tooltipStyle={{ margin: "10px 10px" }}
                tooltipPlacement={"bottom"}
                style={{ margin: "10px 10px" }}
                className="cursor-pointer"
              />

              <GlobalSearch
                placement="bottom"
                style={{ margin: "10px 10px" }}
                text={intl.formatMessage({ id: "SEARCH" })}
              />
              <InformationComponent
                placement="bottom"
                style={{ margin: "10px 10px" }}
                text={intl.formatMessage({ id: "INBOX" })}
              >
                <ReceptionInboxButton disableCount />
              </InformationComponent>

              <InformationComponent
                placement="bottom"
                style={{ margin: "10px 10px" }}
                text={intl.formatMessage({ id: "NOTIFICATIONS" })}
              >
                <NotificationsBell />
              </InformationComponent>
              <InformationComponent
                placement="bottom"
                style={{ margin: "10px 10px" }}
                text={intl.formatMessage({ id: "HISTORY" })}
              >
                <PersonalHistoryButton />
              </InformationComponent>

              <InformationComponent
                placement="bottom"
                style={{ margin: "10px 10px" }}
                text={intl.formatMessage({ id: "TIME_CONTROL" })}
              >
                <TimeTrackingFormButton disableCount />
              </InformationComponent>

              <InformationComponent
                placement="bottom"
                style={{ margin: "10px 10px" }}
                text={intl.formatMessage({ id: "EXPORTS" })}
              >
                <PersonalExportButton disableCount />
              </InformationComponent>

              <SettingsLink
                text={intl.formatMessage({ id: "SETTINGS" })}
                tooltipPlacement={"bottom"}
              />

              <div className="header-splitter ml-2 mr-3" />
              <AccountPopup
                disableSpaceName
                disableName
                disableExtraActionButton
                className="pl-2 text-black"
              />
            </div>
          </div>
        </ObfuscateProvider.Provider>
      </HeaderV2>
    </>
  );
});

const NoModulesView = () => (
  <React.Fragment>
    {/* <OnboardingHeader step /> */}
    <div className="page w-100 d-flex flex-column justify-content-center align-items-center">
      <p>
        <FormattedMessage id="NO_MODULES" />
      </p>
      <span>
        <FormattedMessage id="NO_MODULES_EXPANDED" />
      </span>
    </div>
  </React.Fragment>
);

// interface ISpaceRouter {
//   spaceId: number;
//   disableHeader: Boolean;
// }

const SpaceRouteComponent = React.memo(({ Component }) => {
  return <Component />;
});

const SpaceCanceledRouter = React.memo(function SpaceCanceledRouter({
  spaceId,
  disableHeader
}) {
  // const dict = useSpaceRoutesAccess();
  const user = useCurrentAccountSpace();
  const dict = useSpaceRoutesAccess();
  const currentUserAccessLevel = getAccessLevel(user);
  const space = useSpace();
  const { BlockType } = space;
  return (
    <div
      className={classnames("page w-100 h-100 d-flex flex-column", {
        "p-0": disableHeader
      })}
    >
      {/* <RealTimeReconnectionHeader /> */}
      <SuspenseErrorBoundary>
        <Switch>
          {spaceCanceledRoutes.map((r) => {
            // console.log(`route: ${r.path}`);
            // console.log(canAccessRouteFromDict(dict, r));
            if (!canAccessRouteFromDict(dict, r)) return null;
            return (
              <Route
                key={r.path}
                exact={r.exact}
                path={`/s/${spaceId}/${r.path}`}
              >
                <SpaceRouteComponent Component={r.Component} />
                {/* <r.Component /> */}
              </Route>
            );
          })}
          {currentUserAccessLevel === accessLevels.owner ? (
            <Redirect to={`/s/${spaceId}/plans`} />
          ) : BlockType === spaceBlockTypeEnum.TrialEnded ? (
            <Redirect to={`/s/${spaceId}/SubscriptionTrialEnd`} />
          ) : (
            <Redirect to={`/s/${spaceId}/SubscriptionCanceled`} />
          )}
        </Switch>
      </SuspenseErrorBoundary>
    </div>
  );
});

// const TempSpaceRouter = React.memo(function SpaceRouter({
//   spaceId,
//   disableHeader
// }) {
//   const dict = useSpaceRoutesAccess();

//   return (
//     <div
//       className={classnames("page w-100 h-100 d-flex flex-column", {
//         "p-0": disableHeader
//       })}
//     >
//       {/* <RealTimeReconnectionHeader /> */}
//       <SuspenseErrorBoundary>
//         <Switch>
//           {tempAvailableRoutes.map((r) => {
//             // console.log(`route: ${r.path}`);
//             // console.log(canAccessRouteFromDict(dict, r));
//             if (!canAccessRouteFromDict(dict, r)) return null;
//             return (
//               <Route
//                 key={r.path}
//                 exact={r.exact}
//                 path={`/s/${spaceId}/${r.path}`}
//               >
//                 <SpaceRouteComponent Component={r.Component} />
//                 {/* <r.Component /> */}
//               </Route>
//             );
//           })}
//           <Redirect to={`/s/${spaceId}/dashboard`} />
//         </Switch>
//       </SuspenseErrorBoundary>
//     </div>
//   );
// });

const SpaceRouter = React.memo(function SpaceRouter({
  spaceId,
  disableHeader
}) {
  const dict = useSpaceRoutesAccess();
  // const StickyContextValue = useContext(HorizontalStickyStateProvider);
  const space = useSpace();
  // const { stickyFavorites, setStickyFavorites } = useMemo(() => {
  //   if (typeof StickyContextValue === "object") {
  //     return StickyContextValue;
  //   } else return {};
  // }, [StickyContextValue]);

  const [welcomeBarState, toggleWelcomeBarState] = useContext(
    HeaderWelcomeBarContext
  );
  const history = useHistory();

  const objuscateButtons =
    !space.IsManualPayment &&
    (space.BlockType !== 0 ||
      (space.BlockType === spaceBlockTypeEnum.TrialEnded &&
        !space.TrialEnd &&
        !space.CurrentPlan));

  const showBar = useContext(HeaderWelcomeBarViewContext);

  return (
    <>
      <div
        // style={{ paddingTop: showBar && welcomeBarState ? 104 : undefined }}
        className={classnames("page w-100 h-100 d-flex", {
          "p-0": disableHeader,
          "flex-column": true
        })}
      >
        {showBar && welcomeBarState && !objuscateButtons && (
          <div className="header-welcome-bar">
            <div className="d-flex align-items-center">
              <span className="text-secondary fs-14 mr-1">
                <FormattedMessage id="HEADER_WELCOME_BAR_TEXT" />
              </span>
              <span className="text-secondary fs-14 fw-bold mr-2">Airdesk</span>
              <Button
                style={{
                  height: "min-content",
                  padding: "2px 10px",
                  fontSize: "14px"
                }}
                vType="secondary"
                onClick={() => {
                  history.push(`/s/${space.Id}/welcome`);
                }}
              >
                <FormattedMessage id="WELCOME_VIEW_LINK" />
              </Button>
            </div>
            <div
              style={{ right: 10 }}
              onClick={toggleWelcomeBarState}
              className="header-ignore-welcome text-secondary fs-12 position-absolute cursor-pointer"
            >
              <FormattedMessage id="HEADER_WELCOME_BAR_IGNORE" />
            </div>
          </div>
        )}
        <InfoBarController />
        {/* {stickyFavorites && (
          <AppSidebarHorizontalFavoritesComponent
            isOpen={true}
            isSticky={false}
            stickyFavorites={stickyFavorites}
            setStickyFavorites={setStickyFavorites}
          />
        )} */}
        {/* <RealTimeReconnectionHeader /> */}

        <SuspenseErrorBoundary>
          <Switch>
            {availableRoutes.map((r) => {
              // console.log(`route: ${r.path}`);
              // console.log(canAccessRouteFromDict(dict, r));

              if (!canAccessRouteFromDict(dict, r))
                return (
                  <Route
                    key={r.path}
                    exact={r.exact}
                    path={`/s/${spaceId}/${r.path}`}
                  >
                    <NoPermission></NoPermission>
                    {/* <r.Component /> */}
                  </Route>
                );
              return (
                <Route
                  key={r.path}
                  exact={r.exact}
                  path={`/s/${spaceId}/${r.path}`}
                >
                  <SpaceRouteComponent Component={r.Component} />
                  {/* <r.Component /> */}
                </Route>
              );
            })}
            <Redirect
              exact
              from={`/s/${spaceId}`}
              to={`/s/${spaceId}/dashboard`}
            />
          </Switch>
        </SuspenseErrorBoundary>
      </div>
    </>
  );
});

export const HeaderContext = React.createContext();

// const isSmall = () => {
//   const width = window.innerWidth;
//   return width < 960 ? true : false;
// };

function getCookie(name) {
  const cookieString = document.cookie;
  const cookies = cookieString.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

const isMobile = () => {
  const myCookieValue = getCookie("bypassSmallScreen");
  if (myCookieValue) {
    return false;
  }

  var userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return true;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};

export const HeaderWelcomeBarContext = React.createContext(undefined);
export const HeaderWelcomeBarViewContext = React.createContext(undefined);

const SpaceApp = React.memo(function SpaceApp() {
  const userAccountSpace = useCurrentAccountSpace();
  const space = useSpace();
  // const AdminOrOwner = isAdmin(userAccountSpace);
  // const [welcomeBarState, setWelcomeBarState] = useState(() => {
  //   const welcomeBarState = localStorage.getItem(
  //     `air-welcome-bar-state-${space.Id}`
  //   );
  //   if (!AdminOrOwner) return false;
  //   else if (welcomeBarState) {
  //     return false;
  //   } else return true;
  // });
  const history = useHistory();

  // const [showBar, setShowBar] = useState(() => {
  //   const pathName = history.location.pathname;
  //   const pathArr = pathName.split("/");
  //   const last = pathArr.pop();
  //   return last !== "welcome";
  // });

  const match = useRouteMatch();

  useEffect(() => {
    const unslist = history.listen((location, action) => {
      if (match.path !== location.pathname) {
        // const pathName = location.pathname;
        // const pathArr = pathName.split("/");
        // const last = pathArr.pop();
        // setShowBar(last !== "welcome");
      }
    });
    return unslist;
  }, [history, match.path]);

  const [isSmallScreen, setIsSmallScreen] = useState(() => {
    return isMobile();
  });

  useEffect(() => {
    const eventListener = () => {
      if (isMobile()) {
        if (!isSmallScreen) setIsSmallScreen(true);
      } else {
        if (isSmallScreen) setIsSmallScreen(false);
      }
    };

    window.addEventListener("resize", eventListener);

    return () => {
      window.removeEventListener("resize", eventListener);
    };
  }, [isSmallScreen]);

  const { loading, error, obState } = useSpaceOnboardingState(space.Id);

  const areNotificationsSetup = useContext(NotificationsSetupContext);
  var account = useCurrentAccount();
  const isDev = isDeveloper(account);
  if (isSmallScreen && !isDev) {
    return <SmallScreen />;
  }
  if (
    (obState && obState !== onboardingStateEnum.done) ||
    (error && error.status === 404)
  )
    return <Redirect to={`/s/${space.Id}/onboarding`} />;

  if (!areNotificationsSetup) return <NotificationsSetup />;

  if (loading || (error && error.status !== 402)) return <MainLoader />;

  // const modulesConfig = useModulesConfig(userAccountSpace, currentPath, intl);
  if (!userAccountSpace || !space) {
    return <Redirect to="/s" />;
  }
  if (!hasAtLeastOneModuleAccess(userAccountSpace)) return <NoModulesView />;

  return (
    <SpaceRealTimeProvider spaceId={space.Id}>
      <AccountsInRoomSynchronizer>
        <UnreadEntitiesProvider>
          <FavoriteProvider>
            <AppBarFavoriteQuery>
              <SidebarProvider>
                <EntitySynchronizer>
                  <NotificationProvider>
                    <SpaceAccessProvider>
                      <SpaceMainUrlProvider>
                        <EntitySummarySidebarProvider>
                          <SpaceNotificator>
                            <AppViewProvider>
                              <SpaceAppContent />
                            </AppViewProvider>
                          </SpaceNotificator>
                        </EntitySummarySidebarProvider>
                      </SpaceMainUrlProvider>
                    </SpaceAccessProvider>
                  </NotificationProvider>
                </EntitySynchronizer>
              </SidebarProvider>
            </AppBarFavoriteQuery>
          </FavoriteProvider>
        </UnreadEntitiesProvider>
      </AccountsInRoomSynchronizer>
    </SpaceRealTimeProvider>
  );
});

export const HorizontalStickyStateProvider = React.createContext();

const SpaceAppContentComp = ({
  setHeaderState,
  welcomeBarState,
  toggleWelcomeBarState,
  showBar,
  headerState,
  RouterComponent
}) => {
  const [stickyFavorites, setStickyFavorites] = useState(() => {
    const lsFixed = localStorage.getItem("air-vertical-sidebar-fixed-status");
    if (lsFixed) {
      return Boolean(parseInt(lsFixed));
    } else return false;
  });
  const space = useSpace();

  const {
    // onPopupMouseEnter,
    // onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen,
    setIsOpen
  } = useHoverEvents(100);

  useEffect(() => {
    const status = window.localStorage.getItem(
      `ar-horizontal-bar-favorite-bar-status`
    );

    if (status !== null && status !== undefined && parseInt(status)) {
      onAnchorMouseEnter();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App Vertical_bar">
      <HorizontalStickyStateProvider.Provider
        value={{ stickyFavorites, setStickyFavorites }}
      >
        <div className="App position-relative flex-1 overflow-auto">
          <HeaderContext.Provider value={setHeaderState}>
            <MainViewPreferenceProvider>
              <HeaderWelcomeBarContext.Provider
                value={[welcomeBarState, toggleWelcomeBarState]}
              >
                <HeaderWelcomeBarViewContext.Provider value={showBar}>
                  <AirdeskHeader
                    stickyFavorites={stickyFavorites}
                    setStickyFavorites={setStickyFavorites}
                    onAnchorMouseEnter={onAnchorMouseEnter}
                    isOpen={isOpen}
                    onAnchorMouseLeave={onAnchorMouseLeave}
                    disableHeader={headerState}
                    objuscateButtons={
                      !space.IsManualPayment &&
                      (space.BlockType !== 0 ||
                        (space.BlockType === spaceBlockTypeEnum.TrialEnded &&
                          !space.TrialEnd &&
                          !space.CurrentPlan))
                    }
                  />
                  <div className="d-flex w-100 h-100">
                    <AppSidebarHorizontalFavoritesComponent
                      horizontal
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      // onPopupMouseEnter={onPopupMouseEnter}
                      // onPopupMouseLeave={onPopupMouseLeave}
                      stickyFavorites={isOpen}
                      setStickyFavorites={() => {}}
                    />
                    {RouterComponent}
                  </div>
                </HeaderWelcomeBarViewContext.Provider>
              </HeaderWelcomeBarContext.Provider>
            </MainViewPreferenceProvider>
          </HeaderContext.Provider>
        </div>
      </HorizontalStickyStateProvider.Provider>
    </div>
  );
};

const SpaceAppContent = () => {
  const userAccountSpace = useCurrentAccountSpace();
  const space = useSpace();

  const history = useHistory();

  const [showBar, setShowBar] = useState(() => {
    const pathName = history.location.pathname;
    const pathArr = pathName.split("/");
    const last = pathArr.pop();
    return last !== "welcome";
  });

  const match = useRouteMatch();

  useEffect(() => {
    const unslist = history.listen((location, action) => {
      if (match.path !== location.pathname) {
        const pathName = location.pathname;
        const pathArr = pathName.split("/");
        const last = pathArr.pop();
        setShowBar(last !== "welcome");
      }
    });
    return unslist;
  }, [history, match.path]);

  const AdminOrOwner = isAdmin(userAccountSpace);
  const [headerState, setHeaderState] = useState(false);

  const [welcomeBarState, setWelcomeBarState] = useState(() => {
    const welcomeBarState = localStorage.getItem(
      `air-welcome-bar-state-${space.Id}`
    );
    if (!AdminOrOwner) return false;
    else if (welcomeBarState) {
      return false;
    } else return true;
  });
  const toggleWelcomeBarState = useCallback(() => {
    localStorage.setItem(`air-welcome-bar-state-${space.Id}`, "disable");
    setWelcomeBarState(false);
  }, [space.Id]);

  const [viewState] = useContext(AppViewContext);

  const RouterComponent = useMemo(() => {
    return !space.IsManualPayment &&
      (space.BlockType !== 0 ||
        (space.BlockType === spaceBlockTypeEnum.TrialEnded &&
          !space.CurrentPlan)) ? (
      <SpaceCanceledRouter disableHeader={false} spaceId={space.Id} />
    ) : (
      <SpaceRouter disableHeader={headerState} spaceId={space.Id} />
    );
  }, [
    headerState,
    space.BlockType,
    space.CurrentPlan,
    space.Id,
    space.IsManualPayment
  ]);

  if (viewState === ViewsEnum.basic)
    return (
      <SpaceAppContentComp
        showBar={showBar}
        headerState={headerState}
        RouterComponent={RouterComponent}
        setHeaderState={setHeaderState}
        welcomeBarState={welcomeBarState}
        toggleWelcomeBarState={toggleWelcomeBarState}
      />
    );
  else
    return (
      <div className="App Vertical">
        <HeaderContext.Provider value={setHeaderState}>
          <MainViewPreferenceProvider>
            <HeaderWelcomeBarContext.Provider
              value={[welcomeBarState, toggleWelcomeBarState]}
            >
              <HeaderWelcomeBarViewContext.Provider value={showBar}>
                <AppBar
                  disableHeader={headerState}
                  objuscateButtons={
                    !space.IsManualPayment &&
                    (space.BlockType !== 0 ||
                      (space.BlockType === spaceBlockTypeEnum.TrialEnded &&
                        !space.TrialEnd &&
                        !space.CurrentPlan))
                  }
                />
                {RouterComponent}
              </HeaderWelcomeBarViewContext.Provider>
            </HeaderWelcomeBarContext.Provider>
          </MainViewPreferenceProvider>
        </HeaderContext.Provider>
      </div>
    );
};

const Space = ({ match, assignSpace }) => {
  const { spaceId } = match.params;

  const space = useSpaceEntity(spaceId);

  useEffect(() => {
    assignSpace(spaceId);
  }, [assignSpace, spaceId]);

  if (!space) {
    localStorage.removeItem("Airdesk-Previous-Space");
    return <Redirect to="/s" />;
  }

  localStorage.setItem("Airdesk-Previous-Space", spaceId);

  return (
    <SpaceProvider spaceId={spaceId}>
      <NotificationBrowserBoxProvider>
        <SpaceApp />
      </NotificationBrowserBoxProvider>
    </SpaceProvider>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    assignSpace: (spaceId) => {
      dispatch({ type: ASSIGN_CURRENT_SPACE, assignedSpace: spaceId });
    }
  };
};

export default connect(null, mapDispatchToProps)(Space);
