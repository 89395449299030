import React from "react";
import classnames from "classnames";
import { useContractType } from "../../Hooks/EntityHooks";

const ContractTypeProfile = ({ contracttype, className }) => {
  if (!contracttype) return null;
  return (
    <div
      className={classnames(
        "d-flex align-items-center text-truncate",
        className
      )}
    >
      <div className="flex-1 text-truncate">
        <div className="text-truncate">
          {contracttype.Type} - {contracttype.Name}
        </div>
      </div>
    </div>
  );
};

export default ContractTypeProfile;

export const ConnectContractTypeEntity = Component => {
  return ({ contracttype, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const a = useContractType(contracttype);
    return <Component contracttype={a} {...rest} />;
  };
};

export const ConnectedContractTypeProfile = ConnectContractTypeEntity(
  ContractTypeProfile
);

// const mapStateToProps = (state, ownProps) => {
//   return {
//     contracttype: state.Entities.SpaceContractTypes[ownProps.contracttype]
//   };
// };

// export const ConnectedContractTypeProfile = connect(mapStateToProps)(
//   ContractTypeProfile
// );
