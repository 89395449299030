import React, { useContext, useMemo } from "react";
import { usePostQuery } from "../../Helpers/IOClient";
import { useSpace } from "../../Contexts/SpaceContext";
import { spaceInterventionsSchema } from "../../config/schema";
import { SidebarVisualTypeEnum } from "../../Components/Sidebar/SidebarViewsHelper";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";

export const ChildrenCountsContext = React.createContext();

export const ChildrenCountsProvider = ({
  children,
  schema,
  id,
  forcedWidth,
  sidebarView
}) => {
  const { data, loading } = useChildrenCountQuery(schema, id);

  if (loading) {
    return (
      <div
        style={{
          width: forcedWidth
            ? "100%"
            : sidebarView === SidebarVisualTypeEnum.fullscreen
            ? "100%"
            : 520
        }}
        className="flex-1 of-y-auto d-flex ar-entity-summary-container flex-column justify-content-center align-content-center"
      >
        <LoaderSpinner size="sm" center />
      </div>
    );
  }

  return (
    <ChildrenCountsContext.Provider value={data}>
      {children}
    </ChildrenCountsContext.Provider>
  );
};

export const ChildrenCountBody = {
  CallsOdata: "((Call/Attributes ne '1'))",
  TicketsOdata: "((Ticket/Attributes ne '1'))",
  TasksOdata: "((Task/Attributes ne '1'))",
  ProjectsOdata: "((Project/Attributes ne '1'))",
  ContractsOdata: "((Contract/Attributes ne '1'))"
};

export const useChildrenCountQuery = (schema, id) => {
  const previousChildrenCount = useContext(ChildrenCountsContext);
  const space = useSpace();
  const url = useMemo(() => {
    if (schema.name === spaceInterventionsSchema.name) return null;
    if (previousChildrenCount) return null;
    return `${schema.getEndpoint(space.Id)}/${id}/ChildrenCount`;
  }, [id, previousChildrenCount, schema, space.Id]);

  const result = usePostQuery(url, ChildrenCountBody);

  return previousChildrenCount ? { data: previousChildrenCount } : result;
};
