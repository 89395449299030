import React, { useEffect } from "react";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { useSidebar } from "../Sidebar/SidebarV2";
import { EntitySumaryCommunicator } from "./EntitySummariesHelper";
import { formTypes } from "../../Containers/Form/FormHelper";
import LostReasonForm from "../../Containers/Settings/LostReasons/LostReasonForm";
import CancelationReasonForm from "../../Containers/Settings/CancelationReasons/CancelationReasonForm";
import ContractTypeForm from "../../Containers/Settings/ContractTypes/ContractTypeForm";
import SubscriptionTypeForm from "../../Containers/Settings/SubscriptionTypes/SubscriptionTypeForm";
import ProjectCategoryForm from "../../Containers/Settings/ProjectCategories/ProjectCategoryForm";
import ChecklistTemplatenForm from "../../Containers/Settings/ChecklistTemplates/ChecklistTemplateForm";
import InterventionTypeForm from "../../Containers/Settings/InterventionTypes/InterventionTypeForm";
import IneficientTypesForm from "../../Containers/Settings/IneficientTypes/IneficientTypesForm";
import OrganizationForm from "../../Containers/Settings/Organizations/OrganizationForm";
import DepartmentForm from "../../Containers/Settings/Departments/DepartmentForm";
import TeamForm from "../../Containers/Settings/Teams/TeamForm";
import DomainsForm from "../../Containers/Settings/Domains/DomainsForm";
import InviteForm from "../../Containers/Invites/InviteForm";
import SocialPostForm from "../../Containers/SocialPosts/SocialPostsForm";
import SocialAccountForm from "../../Containers/Settings/Social/SocialForm";
import ClientTypeForm from "../../Containers/Settings/ClientTypes/ClientTypeForm";
import CuponForm from "../../Containers/CuponList/CuponForm";
import SpaceForm from "../../Containers/Spaces/SpaceForm";
import EmailReceptionForm from "../../Containers/Settings/EmailReception/EmailReceptionForm";
import ReceptionNewConvEmailForm from "../../Containers/Reception/ReceptionNewConvForm/ReceptionNewConvEmailForm";
import EmailSignatureForm from "../../Containers/EmailSignature/EmailSignatureForm";
import ClassificationForm from "../../Containers/Classification/ClassificationForm";
import ApiKeysForm from "../../Containers/Settings/ApiKeys/ApiKeysForm";
import ApiKeyLogForm from "../../Containers/Settings/ApiKeyLogs/ApiKeyLogsForm";
import { AutomationFormContent } from "../../Containers/Automation/AutomationOverview/AutomationFormButton";
import DocTemplatesForm from "../../Containers/DocTemplates/DocTemplates/DocTemplatesForm";
import DocumentsForm from "../../Containers/Documents/DocumentsOverview/DocumentsForm";
import DocTemplateFoldersForm from "../../Containers/Settings/DocFolders/DocTemplateFoldersForm";
import DocFoldersForm from "../../Containers/Settings/Documents/DocFoldersForm";
import DocumentTypesForm from "../../Containers/Settings/DocumentTypes/DocumentTypesForm";
import CategoryForm from "../../Containers/Settings/Users/Categories/CategoryForm";
import TaskTypeForm from "../../Containers/Settings/TaskTypes/TaskTypeForm";
import CostCenterForm from "../../Containers/Settings/Users/CostCenters/CostCenterForm";
import TravelTimesForm from "../../Containers/Settings/TravelTimes/TravelTimesForm";
import TicketTypeForm from "../../Containers/Settings/TicketTypes/TicketTypesForm";
import DealTagForm from "../../Containers/Settings/DealsTags/DealTagsForm";
import ClientTagsForm from "../../Containers/Settings/ClientTags/ClientTagsForm";
import ContractTagForm from "../../Containers/Settings/ContractTags/ContractTagsForm";
import SubscriptionTagForm from "../../Containers/Settings/SubscriptionTags/SubscriptionTagsForm";
import CallTagForm from "../../Containers/Settings/CallTags/CallTagsForm";
import ProjectTagForm from "../../Containers/Settings/ProjectTags/ProjectTagsForm";
import TaskTagForm from "../../Containers/Settings/TaskTags/TaskTagsForm";

const ClientSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "client-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Clients/ClientSummary"
  )
);

const ProjectSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "project-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Projects/ProjectSummary/ProjectSummary"
  )
);

const ContactSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "contact-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Contacts/ContactSummary"
  )
);

const DealSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "deal-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Deals/DealSummary"
  )
);

const DocumentSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "document-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Documents/DocumentsOverview/DocumentSummary"
  )
);
const DocTemplateSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "doc-template-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Documents/DocTemplates/DocTemplateSummary"
  )
);

const ContractSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "contract-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Contracts/ContractSummary"
  )
);

const CallSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "call-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Calls/CallSummary"
  )
);

const TicketSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "ticket-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Tickets/TicketSummary"
  )
);

const TaskSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "task-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Tasks/TaskSummary"
  )
);

const InterventionSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "intervention-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Interventions/InterventionSummary"
  )
);

const SubscriptionSummary = React.lazy(() =>
  import(
    /* webpackChunkName: "subscription-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Subscriptions/SubscriptionSummary"
  )
);

const NoteForm = React.lazy(() =>
  import(
    /* webpackChunkName: "subscription-summary" */
    /* webpackPrefetch: true */
    "../../Containers/Notes/NoteForm"
  )
);

const WidgetForm = React.lazy(() =>
  import(
    /* webpackChunkName: "widgets-form" */
    /* webpackPrefetch: true */
    "../../Containers/Settings/Widgets/WidgetsForm"
  )
);

const SummaryMapper = {
  [moduleTypes.calls]: CallSummary,
  [moduleTypes.clients]: ClientSummary,
  [moduleTypes.projects]: ProjectSummary,
  [moduleTypes.contacts]: ContactSummary,
  [moduleTypes.deals]: DealSummary,
  [moduleTypes.contracts]: ContractSummary,
  [moduleTypes.tickets]: TicketSummary,
  [moduleTypes.tasks]: TaskSummary,
  [moduleTypes.interventions]: InterventionSummary,
  [moduleTypes.subscriptions]: SubscriptionSummary,
  [moduleTypes.emailSignatures]: EmailSignatureForm,
  [moduleTypes.billing]: ClassificationForm,
  // [moduleTypes.classification]:ClassificationForm,
  [moduleTypes.spaceUserCategories]: CategoryForm,
  [moduleTypes.spaceCostCenters]: CostCenterForm,
  [formTypes.docTemplates]: DocTemplatesForm,
  [formTypes.lostReason]: LostReasonForm,
  [formTypes.cancelationReason]: CancelationReasonForm,
  [formTypes.clientTypes]: ClientTypeForm,
  [formTypes.taskType]: TaskTypeForm,
  [formTypes.contractTypes]: ContractTypeForm,
  [formTypes.contractTags]: ContractTagForm,
  [formTypes.subscriptionTags]: SubscriptionTagForm,
  [formTypes.emailSignature]: EmailSignatureForm,
  [formTypes.docTemplateFolders]: DocTemplateFoldersForm,
  [formTypes.docTemplateFolders]: DocFoldersForm,
  [formTypes.subscriptionTypes]: SubscriptionTypeForm,
  [formTypes.documentType]: DocumentTypesForm,
  [formTypes.ticketTags]: TicketTypeForm,
  [formTypes.callTags]: CallTagForm,
  [formTypes.dealTags]: DealTagForm,
  [formTypes.projectTags]: ProjectTagForm,
  [formTypes.taskTags]: TaskTagForm,
  [formTypes.clientTags]: ClientTagsForm,
  [formTypes.projectCategory]: ProjectCategoryForm,
  [moduleTypes.checklistTemplate]: ChecklistTemplatenForm,
  [formTypes.interventionTypes]: InterventionTypeForm,
  [formTypes.ineficientTypes]: IneficientTypesForm,
  [formTypes.documents]: DocumentSummary,
  [formTypes.docTemplates]: DocTemplateSummary,
  [formTypes.organizations]: OrganizationForm,
  [formTypes.widgets]: WidgetForm,
  [formTypes.departments]: DepartmentForm,
  [formTypes.teams]: TeamForm,
  [formTypes.emailDomains]: DomainsForm,
  [formTypes.apiTokens]: ApiKeysForm,
  [formTypes.apiLogs]: ApiKeyLogForm,
  [moduleTypes.settingsUsers]: InviteForm,
  [formTypes.socialPost]: SocialPostForm,
  [formTypes.socialAccount]: SocialAccountForm,
  [moduleTypes.cupons]: CuponForm,
  [formTypes.notes]: NoteForm,
  [formTypes.travelTimes]: TravelTimesForm,
  [formTypes.spaces]: SpaceForm,
  [formTypes.settingsInboundEmails]: EmailReceptionForm,
  [moduleTypes.settingsInboundEmails]: EmailReceptionForm,
  [moduleTypes.emailConversations]: ReceptionNewConvEmailForm,
  [formTypes.automation]: AutomationFormContent
};

export const EntitySummarySidebarProvider = ({ children }) => {
  const [openSidebar] = useSidebar();

  useEffect(() => {
    const handleOpen = ({ type, props, options }) => {
      const ResolvedForm = SummaryMapper[type];
      if (ResolvedForm) {
        openSidebar(<ResolvedForm {...props} />, options);
      }
      // dispatch(OpenSidebar(<ResolvedForm {...props} />));
    };

    EntitySumaryCommunicator.subscribe((config) => {
      handleOpen(config);
    });
  }, [openSidebar]);

  return children;
};
