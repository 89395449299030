import React, { useRef, useContext, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faTable,
  faRedoAlt,
  faRetweet,
  faFileImport,
  faSync,
  faWatch,
  faStar
} from "@fortawesome/pro-light-svg-icons";
import Button from "../Button/Button";
import Popup, { usePopupOpenState } from "../Popup/Popup";
import classnames from "classnames";
import {
  hasModulePermission,
  modulePermissions,
  isAdmin,
  hasImportAccess
} from "../../Helpers/ModulesHelper";
import createExcelButton from "../Excel/ExcelHelper";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { ExtraActionLoadableButton } from "../Button/LoadableButton";
import "./ExtraActionButton.css";
import { faPen } from "@fortawesome/pro-light-svg-icons";
import { CardChooserListnerDict } from "../../Containers/Dashboard/CardChooser/CardChooserHelper";
import { ReverseCardChooserListnerDict } from "../../Containers/Dashboard/ReverseCardChooser/ReverseCardChooserHelper";
import { Link, useLocation } from "react-router-dom";
import { useSpace } from "../../Contexts/SpaceContext";
import { FormattedMessage } from "react-intl";
import Switch from "../Switch/Switch";
import { DashboardAutoRefreshProvider } from "../../Containers/Dashboard/Dashboard";
import { useSidebar } from "../Sidebar/SidebarV2";
import DashboardAutoUpdateSidebar from "./DashboardAutoUpdateSidebar";
import querystring from "query-string";
import { ServerAwareUpdateTempViewsContext } from "../CGrid/ServerAwareViews";
import { ServerAwareFavoritesContext } from "../../Containers/AppBar/ServerAwareFavorite";
import { AppBarFavoriteTreeSetter } from "../../Containers/AppBar/AppBarFavoriteQuery";
import useAppBarAddViewFavorite from "../../Containers/AppBar/AppBarGridAddFavorite";
import { useServerAwareState } from "../CGrid/ServerGrid";

const labels = {
  property: "COLUMNS",
  export: "EXPORT",
  import: "IMPORT",
  update: "UPDATE",
  actions: "ACTIONS"
};

const ResetDashboard = (schemaName) => {
  let gridColumnLayout = JSON.parse(
    window.localStorage.getItem("ar-grid-card-layout")
  );
  if (gridColumnLayout) {
    delete gridColumnLayout[schemaName];

    localStorage.setItem(
      "ar-grid-card-layout",
      JSON.stringify(gridColumnLayout)
    );
    if (CardChooserListnerDict[schemaName]) {
      for (const listner of CardChooserListnerDict[schemaName]) {
        listner(null);
      }
    }
  }

  return;
};

const ReverseResetDashboard = (schemaName) => {
  let gridColumnLayout = JSON.parse(
    window.localStorage.getItem("ar-grid-card-layout")
  );
  if (gridColumnLayout) {
    delete gridColumnLayout[schemaName];

    localStorage.setItem(
      "ar-grid-card-layout",
      JSON.stringify(gridColumnLayout)
    );
    if (ReverseCardChooserListnerDict[schemaName]) {
      for (const listner of ReverseCardChooserListnerDict[schemaName]) {
        listner(null);
      }
    }
  }

  return;
};

export const DashboardActionButton = ({
  enableColumnEdit,
  resetLayout,
  activeLayout,
  ...rest
}) => {
  const ResetLayout = useCallback(() => {
    const timeout = setTimeout(() => {
      resetLayout();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [resetLayout]);

  const {
    autoRefresh,
    setAutoRefresh,
    setTimeout: UpdateTimeout,
    timeout
  } = useContext(DashboardAutoRefreshProvider);

  const handleAutoRefreshSwitchChanges = useCallback(() => {
    setAutoRefresh(!autoRefresh);
  }, [autoRefresh, setAutoRefresh]);

  const [openSidebar, closeSidebar] = useSidebar();
  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  return (
    <ActionButton {...rest}>
      <div className="label-title">
        <FormattedMessage id={labels.property} />
      </div>
      {enableColumnEdit && (
        <ActionLoadableButton
          className="p-1 pl-3"
          vType="link"
          onClick={enableColumnEdit}
        >
          <span className="icon-width">
            <FontAwesomeIcon icon={faPen} />
          </span>
          <span className="ml-2">
            <FormattedMessage id={"EDIT_MODULES"} />
          </span>
        </ActionLoadableButton>
      )}
      <ActionLoadableButton
        className="p-1 pl-3"
        vType="link"
        onClick={resetLayout}
      >
        <span className="icon-width">
          <FontAwesomeIcon icon={faRetweet} />
        </span>
        <span className="ml-2">
          <FormattedMessage id={"RESTORE_MODULES"} />
        </span>
      </ActionLoadableButton>

      <ActionLoadableButton
        className="p-1 pl-3"
        vType="link"
        onClick={() => {
          ResetDashboard(activeLayout.lsKey);
          ResetLayout();
        }}
      >
        <span className="icon-width">
          <FontAwesomeIcon icon={faRedoAlt} />
        </span>
        <span className="ml-2">
          <FormattedMessage id={"RESTORE_ALL"} />
        </span>
      </ActionLoadableButton>
      <div className="label-title">
        <FormattedMessage id={labels.update} />
      </div>
      <ActionLoadableButton
        disableToggle
        className="p-1 pl-3"
        vType="link"
        onClick={(e) => {}}
      >
        <div className="icon-width">
          <FontAwesomeIcon icon={faSync} />
        </div>
        <div className="flex-1 ml-2 d-flex text-truncate align-items-center justify-content-between">
          <div className="text-truncate">
            <FormattedMessage id={"AUTO_REFRESH"} />
          </div>

          <Switch
            checked={autoRefresh}
            onChange={handleAutoRefreshSwitchChanges}
            className="sm mr-3"
          />
        </div>
      </ActionLoadableButton>
      <ActionLoadableButton
        className="p-1 pl-3"
        vType="link"
        onClick={() => {
          openSidebar(
            <DashboardAutoUpdateSidebar
              Timeout={timeout}
              UpdateTimeout={UpdateTimeout}
              closeSidebar={closeSidebar}
            />
          );
        }}
      >
        <span className="icon-width">
          <FontAwesomeIcon icon={faWatch} />
        </span>
        <span className="ml-2">
          <FormattedMessage id={"SETUP"} />
        </span>
      </ActionLoadableButton>
    </ActionButton>
  );
};

export const ReverseDashboardActionButton = ({
  enableColumnEdit,
  resetLayout,
  activeLayout,
  ...rest
}) => {
  const {
    autoRefresh,
    setAutoRefresh,
    setTimeout: UpdateTimeout,
    timeout
  } = useContext(DashboardAutoRefreshProvider);

  const handleAutoRefreshSwitchChanges = useCallback(() => {
    setAutoRefresh(!autoRefresh);
  }, [autoRefresh, setAutoRefresh]);

  const [openSidebar, closeSidebar] = useSidebar();
  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  return (
    <ActionButton {...rest}>
      <div className="label-title">
        <FormattedMessage id={labels.property} />
      </div>
      {enableColumnEdit && (
        <ActionLoadableButton
          className="p-1 pl-3"
          vType="link"
          onClick={enableColumnEdit}
        >
          <span className="icon-width">
            <FontAwesomeIcon icon={faPen} />
          </span>
          <span className="ml-2">
            <FormattedMessage id={"EDIT_MODULES"} />
          </span>
        </ActionLoadableButton>
      )}
      <ActionLoadableButton
        className="p-1 pl-3"
        vType="link"
        onClick={resetLayout}
      >
        <span className="icon-width">
          <FontAwesomeIcon icon={faRetweet} />
        </span>
        <span className="ml-2">
          <FormattedMessage id={"RESTORE_MODULES"} />
        </span>
      </ActionLoadableButton>

      <ActionLoadableButton
        className="p-1 pl-3"
        vType="link"
        onClick={() => {
          ReverseResetDashboard(activeLayout.lsKey);
        }}
      >
        <span className="icon-width">
          <FontAwesomeIcon icon={faRedoAlt} />
        </span>
        <span className="ml-2">
          <FormattedMessage id={"RESTORE_ALL"} />
        </span>
      </ActionLoadableButton>
      <div className="label-title">
        <FormattedMessage id={labels.update} />
      </div>
      <ActionLoadableButton
        disableToggle
        className="p-1 pl-3"
        vType="link"
        onClick={(e) => {}}
      >
        <div className="icon-width">
          <FontAwesomeIcon icon={faSync} />
        </div>
        <div className="flex-1 ml-2 d-flex align-items-center justify-content-between">
          <span>
            <FormattedMessage id={"AUTO_REFRESH"} />
          </span>

          <Switch
            checked={autoRefresh}
            onChange={handleAutoRefreshSwitchChanges}
            className="sm mr-3"
          />
        </div>
      </ActionLoadableButton>
      <ActionLoadableButton
        className="p-1 pl-3"
        vType="link"
        onClick={() => {
          openSidebar(
            <DashboardAutoUpdateSidebar
              Timeout={timeout}
              UpdateTimeout={UpdateTimeout}
              closeSidebar={closeSidebar}
            />
          );
        }}
      >
        <span className="icon-width">
          <FontAwesomeIcon icon={faWatch} />
        </span>
        <span className="ml-2">
          <FormattedMessage id={"SETUP"} />
        </span>
      </ActionLoadableButton>
    </ActionButton>
  );
};

const useFavoriteColumnSizeReset = () => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const setTree = useContext(AppBarFavoriteTreeSetter);
  const handleColumnSizesChange = useCallback(
    (result) => {
      if (!FavoriteItem) return () => {};
      setTree((oldTree) => {
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items };
        const newItem = { ...newItems[FavoriteItem.id] };
        const newData = { ...newItem.data };
        newData.columnSizes = result;
        newItem.data = newData;
        newItems[FavoriteItem.id] = newItem;
        return {
          rootId,
          items: newItems
        };
      });
    },
    [FavoriteItem, setTree]
  );
  return () => handleColumnSizesChange(undefined);
};

const useViewColumnSizeReset = (viewName) => {
  const location = useLocation();
  const space = useSpace();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);

  return () => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );
    const viewGridViews = gridSavedViews[viewName];
    const index = viewGridViews.findIndex((e) => e.id === viewPref);
    if (index !== -1) {
      viewGridViews[index].columnSize = undefined;
    } else {
      viewGridViews[0].columnSize = undefined;
    }
    gridSavedViews[viewName] = viewGridViews;
    localStorage.setItem(
      `ar-grid-saved-views-space-${space.Id}`,
      JSON.stringify(gridSavedViews)
    );
    updateLsViews();
    return;
  };
};

const useViewResetEverything = (viewName) => {
  const location = useLocation();
  const { search } = location;
  const space = useSpace();
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);

  return () => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );
    const viewGridViews = gridSavedViews[viewName];
    const index = viewGridViews.findIndex((e) => e.id === viewPref);
    if (index !== -1) {
      viewGridViews[index].columnSize = undefined;
      viewGridViews[index].columnLayout = undefined;
      viewGridViews[index].stickyLayout = undefined;
    } else {
      viewGridViews[0].columnSize = undefined;
      viewGridViews[0].columnLayout = undefined;
      viewGridViews[0].stickyLayout = undefined;
    }
    gridSavedViews[viewName] = viewGridViews;
    localStorage.setItem(
      `ar-grid-saved-views-space-${space.Id}`,
      JSON.stringify(gridSavedViews)
    );
    updateLsViews();
    return;
  };
};

const useFavoriteResetEverything = () => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const setTree = useContext(AppBarFavoriteTreeSetter);
  const handleColumnSizesChange = useCallback(
    (result) => {
      setTree((oldTree) => {
        if (!FavoriteItem) return () => {};
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items };
        const newItem = { ...newItems[FavoriteItem.id] };
        const newData = { ...newItem.data };
        newData.columnSizes = result;
        newData.orderedFields = result;
        newData.stickyFields = result;
        newItem.data = newData;
        newItems[FavoriteItem.id] = newItem;
        return {
          rootId,
          items: newItems
        };
      });
    },
    [FavoriteItem, setTree]
  );
  return () => handleColumnSizesChange(undefined);
};

export const ActionLoadableButton = ({
  onClick,
  className,
  disableToggle,
  ...rest
}) => {
  const ToggleIsOpen = useActionPopupToggler();

  const handleClick = useCallback(
    (e) => {
      if (!disableToggle) {
        ToggleIsOpen(false);
      }
      onClick(e);
    },
    [ToggleIsOpen, disableToggle, onClick]
  );

  return (
    <ExtraActionLoadableButton
      className={className}
      onClick={handleClick}
      {...rest}
    />
  );
};

export const ActionButtonOptions = ({
  enableColumnEdit,
  type,
  excelUrl,
  spaceType,
  moduleType,
  enableImport,
  columnResize,
  extraExportButtons,
  pipelineFilter,
  disableAllExports = false,
  enableViewSaver: reorderViewSaver
}) => {
  const { Modules } = useCurrentAccountSpace();

  const canExport = hasModulePermission(
    Modules[moduleType],
    modulePermissions.Export
  );

  const accountSpace = useCurrentAccountSpace();
  const isAdmino = isAdmin(accountSpace);
  const space = useSpace();
  // if (!isAdmino) {
  //   if (!canExport) {
  //     return null;
  //   }
  // }
  const { params } = useServerAwareState();
  const { view } = params;
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const resolvedType = type === "customers" ? "Clients" : type;
  const viewResetColumnSize = useViewColumnSizeReset(spaceType);
  const favoriteResetColumnSize = useFavoriteColumnSizeReset();
  const viewResetEverything = useViewResetEverything(spaceType);
  const favoriteResetEverything = useFavoriteResetEverything(spaceType);
  const addFavorite = useAppBarAddViewFavorite(spaceType);
  const ExcelButton = createExcelButton({
    getUrl: () => (excelUrl ? excelUrl : "Excel/" + resolvedType + "/ToFile"),
    moduleType: moduleType,
    pipelineFilter
  });
  // const account = useCurrentAccount();

  return (
    <ActionButton>
      <>
        <div className="label-title">
          <FormattedMessage id={labels.actions} />
        </div>
        <ActionLoadableButton
          className="p-1 AlignLeftLoadableButton"
          vType="link"
          onClick={() => {
            addFavorite();
          }}
        >
          <FontAwesomeIcon icon={faStar} />
          <span className="ml-1">
            <FormattedMessage id={"ADD_FAVORITE"} />
          </span>
        </ActionLoadableButton>
        {/* 
          <Link
            className="AlignLeftLoadableButton p-2 ssi-link"
            to={`/s/${space.Id}/imports`}
          >
            <FontAwesomeIcon icon={faFileImport} />
            <span className="ml-1">
              <FormattedMessage id={"IMPORT"} />
            </span>
          </Link> */}
      </>
      {Boolean(view) === false && (
        <>
          {!disableAllExports &&
            (canExport || isAdmino ? (
              <>
                <div className="label-title">
                  <FormattedMessage id={labels.export} />
                </div>
                <ExcelButton ExportType="Total" />
                <ExcelButton ExportType="SinglePage" max={50} />
                {extraExportButtons && extraExportButtons}
              </>
            ) : null)}
          {(type === "customers" || type === "contacts") &&
          hasImportAccess(accountSpace) ? (
            <>
              <div className="label-title">
                <FormattedMessage id={labels.import} />
              </div>

              <Link
                className="AlignLeftLoadableButton p-2 ssi-link"
                to={`/s/${space.Id}/imports`}
              >
                <FontAwesomeIcon icon={faFileImport} />
                <span className="ml-1">
                  <FormattedMessage id={"IMPORT"} />
                </span>
              </Link>
            </>
          ) : null}
        </>
      )}
      {view !== "calendar" && view !== "timeline" && (
        <>
          {(columnResize || enableColumnEdit) && (
            <div className="label-title">
              <FormattedMessage id={labels.property} />
            </div>
          )}
          {enableColumnEdit && (
            <ActionLoadableButton
              className="p-1 AlignLeftLoadableButton"
              vType="link"
              onClick={() => {
                enableColumnEdit();
              }}
            >
              <FontAwesomeIcon icon={faPen} />
              <span className="ml-1">
                <FormattedMessage id={"EDIT_COLUMNS"} />
              </span>
            </ActionLoadableButton>
          )}
          {columnResize && (
            <ActionLoadableButton
              className="p-1 AlignLeftLoadableButton"
              vType="link"
              onClick={() => {
                if (FavoriteItem) favoriteResetColumnSize();
                else viewResetColumnSize(spaceType);
              }}
            >
              <FontAwesomeIcon icon={faTable} />
              <span className="ml-1">
                <FormattedMessage id={"RESTORE_COLUMNS"} />
              </span>
            </ActionLoadableButton>
          )}
          {columnResize && enableColumnEdit && (
            <ActionLoadableButton
              className="p-1 AlignLeftLoadableButton"
              vType="link"
              onClick={() => {
                if (FavoriteItem) favoriteResetEverything();
                else viewResetEverything(spaceType);
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />{" "}
              <span className="ml-1">
                <FormattedMessage id={"RESTORE_ALL"} />
              </span>
            </ActionLoadableButton>
          )}
        </>
      )}
    </ActionButton>
  );
};

const useFavoriteResetKanbanEverything = () => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const setTree = useContext(AppBarFavoriteTreeSetter);
  return (result) => {
    if (!setTree) return () => {};
    setTree((oldTree) => {
      const newTree = { ...oldTree };
      const { rootId, items } = newTree;
      const newItems = { ...items };
      const newItem = { ...newItems[FavoriteItem.id] };
      const newData = { ...newItem.data };
      newData.kanbanLayout = undefined;
      newItem.data = newData;
      newItems[FavoriteItem.id] = newItem;
      return {
        rootId,
        items: newItems
      };
    });
  };
};

const useViewResetKanbanEverything = (viewName) => {
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);
  const space = useSpace();
  return () => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );

    const viewGridViews = gridSavedViews[viewName];
    const index = viewGridViews.findIndex((e) => e.id === viewPref);
    viewGridViews[index === -1 ? 0 : index].kanbanLayout = [];
    gridSavedViews[viewName] = viewGridViews;
    localStorage.setItem(
      `ar-grid-saved-views-space-${space.Id}`,
      JSON.stringify(gridSavedViews)
    );
    updateLsViews();
    return;
  };
};
const useResetViewCardEverything = (viewName) => {
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);
  const space = useSpace();
  return () => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );

    const viewGridViews = gridSavedViews[viewName];
    const index = viewGridViews.findIndex((e) => e.id === viewPref);
    viewGridViews[index === -1 ? 0 : index].cardLayout = [];
    gridSavedViews[viewName] = viewGridViews;
    localStorage.setItem(
      `ar-grid-saved-views-space-${space.Id}`,
      JSON.stringify(gridSavedViews)
    );
    updateLsViews();
    return;
  };
};

const useFavoriteResetPipelineStatus = () => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const setTree = useContext(AppBarFavoriteTreeSetter);
  return (result) => {
    if (!setTree) return () => {};
    setTree((oldTree) => {
      const newTree = { ...oldTree };
      const { rootId, items } = newTree;
      const newItems = { ...items };
      const newItem = { ...newItems[FavoriteItem.id] };
      const newData = { ...newItem.data };

      newData.pipelineStatus = undefined;
      newItem.data = newData;
      newItems[FavoriteItem.id] = newItem;
      return {
        rootId,
        items: newItems
      };
    });
  };
};

const useResetPipelineStatus = (viewName, pipelineId) => {
  const location = useLocation();
  const { search } = location;
  const space = useSpace();
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);

  return () => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );
    const viewGridViews = gridSavedViews[viewName];
    const index = viewGridViews.findIndex((e) => e.id === viewPref);
    viewGridViews[index === -1 ? 0 : index].pipelineStatus = [];
    gridSavedViews[viewName] = viewGridViews;
    localStorage.setItem(
      `ar-grid-saved-views-space-${space.Id}`,
      JSON.stringify(gridSavedViews)
    );
    updateLsViews();
    return;
  };
};

export const ActionButtonBoardOptions = ({
  enableColumnEdit,
  type,
  spaceType,
  moduleType,
  columnResize,
  pipelineId,
  reorderViewSaver,
  enableStatusEdit
}) => {
  const { Modules } = useCurrentAccountSpace();

  const canExport = hasModulePermission(
    Modules[moduleType],
    modulePermissions.Export
  );

  const accountSpace = useCurrentAccountSpace();
  const isAdmino = isAdmin(accountSpace);

  // if (!isAdmino) {
  //   if (!canExport) {
  //     return null;
  //   }
  // }
  const { params } = useServerAwareState();
  const { view } = params;
  const resetViewKanbanEverything = useViewResetKanbanEverything(spaceType);
  const resetFavoriteKanbanEverything = useFavoriteResetKanbanEverything();
  const resetFavoritePipelineStatus = useFavoriteResetPipelineStatus();
  const addFavorite = useAppBarAddViewFavorite(spaceType);
  const resetPipelineStatus = useResetPipelineStatus(spaceType, pipelineId);
  const ExcelButton = createExcelButton({
    getUrl: () => type + "/excel"
  });
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  return (
    <ActionButton>
      <div className="label-title">
        <FormattedMessage id={labels.actions} />
      </div>
      <ActionLoadableButton
        className="p-1 AlignLeftLoadableButton"
        vType="link"
        onClick={() => {
          addFavorite();
        }}
      >
        <FontAwesomeIcon icon={faStar} />
        <span className="ml-1">
          <FormattedMessage id={"ADD_FAVORITE"} />
        </span>
      </ActionLoadableButton>

      {Boolean(view) === false &&
        type !== "deals" &&
        type !== "customers" &&
        (canExport || isAdmino) && (
          <>
            <div className="label-title">
              <FormattedMessage id={labels.export} />
            </div>
            <ExcelButton ExportType="Total" />
            <ExcelButton ExportType="SinglePage" max={50} />
          </>
        )}
      <div className="label-title">
        <FormattedMessage id={labels.property} />
      </div>
      {enableColumnEdit && (
        <ActionLoadableButton
          className="p-1 AlignLeftLoadableButton"
          vType="link"
          onClick={() => {
            enableColumnEdit();
          }}
        >
          <FontAwesomeIcon icon={faPen} />
          <span className="ml-1">
            <FormattedMessage id={"EDIT_CARDS"} />
          </span>
        </ActionLoadableButton>
      )}

      {enableColumnEdit && (
        <ActionLoadableButton
          className="p-1 AlignLeftLoadableButton"
          vType="link"
          onClick={() => {
            if (FavoriteItem) resetFavoriteKanbanEverything();
            else resetViewKanbanEverything(spaceType, type);
          }}
        >
          <FontAwesomeIcon icon={faRedoAlt} />
          <span className="ml-1">
            <FormattedMessage id={"RESTORE_CARDS"} />
          </span>
        </ActionLoadableButton>
      )}

      <div className="label-title">
        <FormattedMessage id={"PIPELINE_STATUS"} />
      </div>
      {enableColumnEdit && (
        <ActionLoadableButton
          className="p-1 AlignLeftLoadableButton"
          vType="link"
          onClick={() => {
            enableStatusEdit();
          }}
        >
          <FontAwesomeIcon icon={faPen} />
          <span className="ml-1">
            <FormattedMessage id={"EDIT_STATUS"} />
          </span>
        </ActionLoadableButton>
      )}

      {enableColumnEdit && (
        <ActionLoadableButton
          className="p-1 AlignLeftLoadableButton"
          vType="link"
          onClick={() => {
            if (FavoriteItem) resetFavoritePipelineStatus();
            else resetPipelineStatus(spaceType, pipelineId);
          }}
        >
          <FontAwesomeIcon icon={faRedoAlt} />
          <span className="ml-1">
            <FormattedMessage id={"RESTORE_STATES"} />
          </span>
        </ActionLoadableButton>
      )}
    </ActionButton>
  );
};

const useResetFavoriteCardEverything = () => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const setTree = useContext(AppBarFavoriteTreeSetter);
  return (result) => {
    if (!setTree) return () => {};
    setTree((oldTree) => {
      const newTree = { ...oldTree };
      const { rootId, items } = newTree;
      const newItems = { ...items };
      const newItem = { ...newItems[FavoriteItem.id] };
      const newData = { ...newItem.data };
      newData.cardLayout = undefined;
      newItem.data = newData;
      newItems[FavoriteItem.id] = newItem;
      return {
        rootId,
        items: newItems
      };
    });
  };
};

export const ActionButtonCardsOptions = ({
  enableColumnEdit,
  type,
  spaceType,
  moduleType,
  columnResize,
  pipelineId,
  reorderViewSaver,
  enableStatusEdit
}) => {
  const { Modules } = useCurrentAccountSpace();

  const canExport = hasModulePermission(
    Modules[moduleType],
    modulePermissions.Export
  );

  const accountSpace = useCurrentAccountSpace();
  const isAdmino = isAdmin(accountSpace);

  // if (!isAdmino) {
  //   if (!canExport) {
  //     return null;
  //   }
  // }
  const { params } = useServerAwareState();
  const { view } = params;
  const resetFavoriteKanbanEverything = useResetFavoriteCardEverything();
  const addFavorite = useAppBarAddViewFavorite(spaceType);
  const resetViewsKanbanEverything = useResetViewCardEverything(spaceType);
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const ExcelButton = createExcelButton({
    getUrl: () => type + "/excel"
  });

  return (
    <ActionButton>
      <div className="label-title">
        <FormattedMessage id={labels.actions} />
      </div>
      <ActionLoadableButton
        className="p-1 AlignLeftLoadableButton"
        vType="link"
        onClick={() => {
          addFavorite();
        }}
      >
        <FontAwesomeIcon icon={faStar} />
        <span className="ml-1">
          <FormattedMessage id={"ADD_FAVORITE"} />
        </span>
      </ActionLoadableButton>
      {Boolean(view) === false &&
        type !== "deals" &&
        type !== "customers" &&
        (canExport || isAdmino) && (
          <>
            <div className="label-title">
              <FormattedMessage id={labels.export} />
            </div>
            <ExcelButton ExportType="Total" />
            <ExcelButton ExportType="SinglePage" max={50} />
          </>
        )}
      <div className="label-title">
        <FormattedMessage id={labels.property} />
      </div>
      {enableColumnEdit && (
        <ActionLoadableButton
          className="p-1 AlignLeftLoadableButton"
          vType="link"
          onClick={() => {
            enableColumnEdit();
          }}
        >
          <FontAwesomeIcon icon={faPen} />
          <span className="ml-1">
            <FormattedMessage id={"EDIT_CARDS"} />
          </span>
        </ActionLoadableButton>
      )}

      {enableColumnEdit && (
        <ActionLoadableButton
          className="p-1 AlignLeftLoadableButton"
          vType="link"
          onClick={() => {
            if (FavoriteItem) resetFavoriteKanbanEverything();
            else resetViewsKanbanEverything(spaceType, type);
          }}
        >
          <FontAwesomeIcon icon={faRedoAlt} />
          <span className="ml-1">
            <FormattedMessage id={"RESTORE_CARDS"} />
          </span>
        </ActionLoadableButton>
      )}
    </ActionButton>
  );
};

export const ActionPopupTogglerContext = React.createContext();

const useActionPopupToggler = () => useContext(ActionPopupTogglerContext);

const ActionButton = ({ ...rest }) => {
  const { children } = rest;
  const anchorRef = useRef();
  const popupRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  return (
    <ActionPopupTogglerContext.Provider value={toggleIsOpen}>
      <Button
        style={{ minWidth: 40 }}
        ref={anchorRef}
        className={classnames(
          "ml-2 ssi-button-primary-ghost p-0 pt-1 ExtraActionButton"
        )}
        onClick={(e) => toggleIsOpen(true)}
      >
        <span style={{ verticalAlign: "sub" }}>
          <FontAwesomeIcon size="2x" icon={faEllipsisV} />
        </span>
      </Button>
      <Popup
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        domRef={popupRef}
        anchorEl={anchorRef.current}
        isOpen={isOpen}
        placement="bottom-start"
      >
        {() => {
          return (
            <div className="py-2 ar-grid-buttons-popup bg-white rounded">
              {React.Children.map(children, (child) => {
                if (!child) return child;
                return React.cloneElement(child, {});
              })}
            </div>
          );
        }}
      </Popup>
    </ActionPopupTogglerContext.Provider>
  );
};
