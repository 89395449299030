import React from "react";
import { msToTime } from "../../Assets/Utils";

function FormattedTimePreview({ value, asHourMinute, className }) {
	if (!value) return "―";

	let time = "";

	if (asHourMinute) {
		time = msToTime(value, "hh:mm");
	} else {
		const objTime = msToTime(value, null, true);

		if (objTime.minutes > 0) time = objTime.hours + 1;
		else time = parseInt(objTime.hours);
	}

	return <span className={className}>{time}</span>;
}

export default FormattedTimePreview;
