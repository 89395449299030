import React from "react";
import Button from "../Button/Button";
import { useEntitySummarySidebar } from "../EntitySummaries/EntitySummariesHelper";
import { moduleTypes } from "../../Helpers/ModulesHelper";

const createSidebarLink = ({ moduleType }) => {
  const EntitySidebarLink = ({ children, id, className }) => {
    const open = useEntitySummarySidebar();

    const handleClick = () => {
      open({ type: moduleType, props: { id } });
    };

    return (
      <Button vType="link-primary" className={className} onClick={handleClick}>
        {children}
      </Button>
    );
  };
  return EntitySidebarLink;
};

export const ProjectSidebarLink = createSidebarLink({
  moduleType: moduleTypes.projects
});
export const TicketSidebarLink = createSidebarLink({
  moduleType: moduleTypes.tickets
});
export const CallSidebarLink = createSidebarLink({
  moduleType: moduleTypes.calls
});
export const ClientSidebarLink = createSidebarLink({
  moduleType: moduleTypes.clients
});
export const TaskSidebarLink = createSidebarLink({
  moduleType: moduleTypes.tasks
});
export const ClassificationSidebarLink = createSidebarLink({
  moduleType: moduleTypes.billing
});
export const ContractSidebarLink = createSidebarLink({
  moduleType: moduleTypes.contracts
});
export const AutomationSidebarLink = createSidebarLink({
  moduleType: moduleTypes.automation
});
export const SocialPostSidebarLink = createSidebarLink({
  moduleType: moduleTypes.socialPost
});
export const EmailSignatureSidebarLink = createSidebarLink({
  moduleType: moduleTypes.emailSignature
});
export const ContactSidebarLink = createSidebarLink({
  moduleType: moduleTypes.contacts
});
export const DealSidebarLink = createSidebarLink({
  moduleType: moduleTypes.deals
});
export const SubscriptionSidebarLink = createSidebarLink({
  moduleType: moduleTypes.subscriptions
});
export const InterventionSidebarLink = createSidebarLink({
  moduleType: moduleTypes.interventions
});
