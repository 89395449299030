import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const TicketDetailsComp = React.lazy(() =>
  import(/* webpackChunkName: "tickets-details" */ "./TicketDetails2Comp")
);

const TicketDetails = () => {
  return (
    <SuspenseErrorBoundary>
      <TicketDetailsComp />
    </SuspenseErrorBoundary>
  );
};

export default TicketDetails;
