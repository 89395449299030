import React, { useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../Components/Button/Button";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import PercentageBar from "../../Components/PercentageBar/PercentageBar";
import { useToast } from "../../Components/Toast/ToastProvider";
import { useUnreadEntitiesFetcher } from "../../Components/UnreadEntities/UnreadEntities";
import {
  handleError,
  useDownloadData,
  useDownloadExcelData
} from "../../Helpers/MiscHelper";
import { useSpaceEntitiesHub } from "../RealTime/RealTimeSpace";
import api from "../../api";
import moment from "moment";

const APINotificationJobTextEnum = {
  ProjectAddOrUpdate: 1,
  TicketAddOrUpdate: 2,
  UserUpdate: 3,
  ProjectTaskAddOrUpdate: 4,
  CallAddOrUpdate: 5,
  TimeChargeAddOrUpdate: 6,
  DealAddOrUpdate: 7,
  ContractAddOrUpdate: 8,
  SubscriptionAddOrUpdate: 9,
  BillingAddOrUpdate: 10,
  ClientAddOrUpdate: 11,
  ContactAddOrUpdate: 12,
  SocialPostAddOrUpdate: 13,
  EmailSignatureAddOrUpdate: 14,
  RecurrentTaskCreationDelay: 15,
  RecurrentTaskCreation: 16,
  TaskCancelled: 17,
  ProjectTaskDeleteDelay: 18,
  ProjectTaskDelete: 19,
  CallDeleteDelay: 20,
  CallDelete: 21,
  TicketDeleteDelay: 22,
  TicketDelete: 23,
  ProjectDeleteDelay: 24,
  ProjectDelete: 25,
  TimeChargeDeleteDelay: 26,
  TimeChargeDelete: 27,
  DealDeleteDelay: 28,
  DealDelete: 29,
  ContactDeleteDelay: 30,
  ContactDelete: 31,
  ContractDeleteDelay: 32,
  ContractDelete: 33,
  SubscriptionDeleteDelay: 34,
  SubscriptionDelete: 35,
  BillingDeleteDelay: 36,
  BillingDelete: 37,
  ClientDeleteDelay: 38,
  ClientDelete: 39,
  EmailSignatureDeleteDelay: 40,
  EmailSignatureDelete: 41,
  CompanyAddOrUpdate: 42,
  DepartmentAddOrUpdate: 43,
  TeamAddOrUpdate: 44,
  EmailConversationAddOrUpdate: 45,
  ApiKeyAddOrUpdate: 46,
  AutomationAddOrUpdate: 47
};

const startTranslationDict = {
  [APINotificationJobTextEnum.ProjectAddOrUpdate]: "SHARING_PROJECT_TITLE",
  [APINotificationJobTextEnum.TicketAddOrUpdate]: "SHARING_TICKET_TITLE",
  [APINotificationJobTextEnum.UserUpdate]: "UPDATING_USER_PERMISSION",

  [APINotificationJobTextEnum.CallAddOrUpdate]: "SHARING_CALL_TITLE",
  [APINotificationJobTextEnum.ProjectTaskAddOrUpdate]: "SHARING_TASK_TITLE",
  [APINotificationJobTextEnum.TimeChargeAddOrUpdate]:
    "SHARING_TIMECHARGE_TITLE",

  [APINotificationJobTextEnum.DealAddOrUpdate]: "SHARING_DEAL_TITLE",
  [APINotificationJobTextEnum.ContractAddOrUpdate]: "SHARING_CONTRACT_TITLE",
  [APINotificationJobTextEnum.SubscriptionAddOrUpdate]:
    "SHARING_SUBSCRIPTION_TITLE",

  [APINotificationJobTextEnum.AccountAddOrUpdate]: "SHARING_CLIENT_TITLE",
  [APINotificationJobTextEnum.ContactAddOrUpdate]: "SHARING_CONTACT_TITLE",
  [APINotificationJobTextEnum.ContractAddOrUpdate]: "SHARING_CONTRACT_TITLE",
  [APINotificationJobTextEnum.SubscriptionAddOrUpdate]:
    "SHARING_SUBSCRIPTION_TITLE",

  [APINotificationJobTextEnum.BillingAddOrUpdate]: "SHARING_BILLING_TITLE",
  [APINotificationJobTextEnum.EmailSignatureAddOrUpdate]:
    "SHARING_EMAIL_SIGNATURE_TITLE",
  [APINotificationJobTextEnum.ClientAddOrUpdate]: "SHARING_CLIENT_TITLE",
  [APINotificationJobTextEnum.ContactAddOrUpdate]: "SHARING_CONTACT_TITLE",
  [APINotificationJobTextEnum.SocialPostAddOrUpdate]:
    "SHARING_SOCIAL_POST_TITLE",
  [APINotificationJobTextEnum.EmailSignatureAddOrUpdate]:
    "SHARING_EMAIL_SIGNATURE_TITLE",
  [APINotificationJobTextEnum.RecurrentTaskCreationDelay]:
    "RECURRENT_TASKS_CREATION_DELAY_TITLE",
  [APINotificationJobTextEnum.RecurrentTaskCreation]:
    "RECURRENT_TASKS_CREATION_TITLE",
  [APINotificationJobTextEnum.ProjectTaskDeleteDelay]:
    "TASKS_DELETE_DELAY_TITLE",
  [APINotificationJobTextEnum.ProjectTaskDelete]: "TASKS_DELETE_TITLE",
  [APINotificationJobTextEnum.CallDeleteDelay]: "CALLS_DELETE_DELAY_TITLE",
  [APINotificationJobTextEnum.CallDelete]: "CALLS_DELETE_TITLE",
  [APINotificationJobTextEnum.TicketDeleteDelay]: "TICKETS_DELETE_DELAY_TITLE",
  [APINotificationJobTextEnum.TicketDelete]: "TICKETS_DELETE_TITLE",
  [APINotificationJobTextEnum.ProjectDeleteDelay]:
    "PROJECTS_DELETE_DELAY_TITLE",
  [APINotificationJobTextEnum.ProjectDelete]: "PROJECTS_DELETE_TITLE",
  [APINotificationJobTextEnum.TimeChargeDeleteDelay]:
    "INTERVENTIONS_DELETE_DELAY_TITLE",
  [APINotificationJobTextEnum.TimeChargeDelete]: "INTERVENTIONS_DELETE_TITLE",
  [APINotificationJobTextEnum.DealDeleteDelay]: "DEALS_DELETE_DELAY_TITLE",
  [APINotificationJobTextEnum.DealDelete]: "DEALS_DELETE_TITLE",
  [APINotificationJobTextEnum.ClientDeleteDelay]: "CLIENTS_DELETE_DELAY_TITLE",
  [APINotificationJobTextEnum.ClientDelete]: "CLIENTS_DELETE_TITLE",
  [APINotificationJobTextEnum.ContactDeleteDelay]:
    "CONTACTS_DELETE_DELAY_TITLE",
  [APINotificationJobTextEnum.ContactDelete]: "CONTACTS_DELETE_TITLE",
  [APINotificationJobTextEnum.ContractDeleteDelay]:
    "CONTRACTS_DELETE_DELAY_TITLE",
  [APINotificationJobTextEnum.ContractDelete]: "CONTRACTS_DELETE_TITLE",
  [APINotificationJobTextEnum.SubscriptionDeleteDelay]:
    "SUBSCRIPTIONS_DELETE_DELAY_TITLE",
  [APINotificationJobTextEnum.SubscriptionDelete]: "SUBSCRIPTIONS_DELETE_TITLE",
  [APINotificationJobTextEnum.BillingDeleteDelay]:
    "BILLINGS_DELETE_DELAY_TITLE",
  [APINotificationJobTextEnum.BillingDelete]: "BILLINGS_DELETE_TITLE",
  [APINotificationJobTextEnum.EmailSignatureDeleteDelay]:
    "EMAIL_SIGNATURES_DELETE_DELAY_TITLE",
  [APINotificationJobTextEnum.EmailSignatureDelete]:
    "EMAIL_SIGNATURES_DELETE_TITLE",

  [APINotificationJobTextEnum.CompanyAddOrUpdate]: "SHARING_COMPANY_TITLE",
  [APINotificationJobTextEnum.DepartmentAddOrUpdate]:
    "SHARING_DEPARTMENT_TITLE",
  [APINotificationJobTextEnum.TeamAddOrUpdate]: "SHARING_TEAM_TITLE",
  [APINotificationJobTextEnum.EmailConversationAddOrUpdate]:
    "SHARING_CONVERSATION",
  [APINotificationJobTextEnum.ApiKeyAddOrUpdate]: "SHARING_API_KEY",
  [APINotificationJobTextEnum.AutomationAddOrUpdate]: "SHARING_AUTOMATION_TITLE"
};

const endTranslationDict = {
  [APINotificationJobTextEnum.ProjectAddOrUpdate]:
    "SHARING_PROJECT_SUCCESS_TITLE",
  [APINotificationJobTextEnum.TicketAddOrUpdate]:
    "SHARING_TICKET_SUCCESS_TITLE",
  [APINotificationJobTextEnum.UserUpdate]: "UPDATE_USER_PERMISSION_SUCCESS",

  [APINotificationJobTextEnum.CallAddOrUpdate]: "SHARING_CALL_SUCCESS_TITLE",
  [APINotificationJobTextEnum.ProjectTaskAddOrUpdate]:
    "SHARING_TASK_SUCCESS_TITLE",
  [APINotificationJobTextEnum.TimeChargeAddOrUpdate]:
    "SHARING_TIMECHARGE_SUCCESS_TITLE",
  [APINotificationJobTextEnum.EmailConversationAddOrUpdate]:
    "SHARING_CONVERSATION_SUCCESS_TITLE",
  [APINotificationJobTextEnum.DealAddOrUpdate]: "SHARING_DEAL_SUCCESS_TITLE",
  [APINotificationJobTextEnum.ContractAddOrUpdate]:
    "SHARING_CONTRACT_SUCCESS_TITLE",
  [APINotificationJobTextEnum.SubscriptionAddOrUpdate]:
    "SHARING_SUBSCRIPTION_SUCCESS_TITLE",
  [APINotificationJobTextEnum.ApiKeyAddOrUpdate]:
    "SHARING_API_KEY_SUCCESS_TITLE",
  [APINotificationJobTextEnum.AutomationAddOrUpdate]:
    "SHARING_AUTOMATION_SUCCESS_TITLE",
  [APINotificationJobTextEnum.AccountAddOrUpdate]:
    "SHARING_CLIENT_SUCCESS_TITLE",
  [APINotificationJobTextEnum.ContactAddOrUpdate]:
    "SHARING_CONTACT_SUCCESS_TITLE",
  [APINotificationJobTextEnum.ContractAddOrUpdate]:
    "SHARING_CONTRACT_SUCCESS_TITLE",
  [APINotificationJobTextEnum.SubscriptionAddOrUpdate]:
    "SHARING_SUBSCRIPTION_SUCCESS_TITLE",

  [APINotificationJobTextEnum.BillingAddOrUpdate]:
    "SHARING_BILLING_SUCCESS_TITLE",
  [APINotificationJobTextEnum.ClientAddOrUpdate]:
    "SHARING_CLIENT_SUCCESS_TITLE",
  [APINotificationJobTextEnum.ContactAddOrUpdate]:
    "SHARING_CONTACT_SUCCESS_TITLE",
  [APINotificationJobTextEnum.SocialPostAddOrUpdate]:
    "SHARING_SOCIAL_POST_SUCCESS_TITLE",
  [APINotificationJobTextEnum.EmailSignatureAddOrUpdate]:
    "SHARING_EMAIL_SIGNATURE_SUCCESS_TITLE",
  [APINotificationJobTextEnum.RecurrentTaskCreation]:
    "RECURRENT_TASKS_CREATION_SUCCESS",
  [APINotificationJobTextEnum.TaskCancelled]: "BACKGROUND_JOB_CANCELED_TITLE",
  [APINotificationJobTextEnum.ProjectTaskDelete]: "TASKS_DELETE_TITLE",
  [APINotificationJobTextEnum.CallDelete]: "CALLS_DELETE_TITLE",
  [APINotificationJobTextEnum.TicketDelete]: "TICKETS_DELETE_TITLE",
  [APINotificationJobTextEnum.ProjectDelete]: "PROJECTS_DELETE_TITLE",
  [APINotificationJobTextEnum.TimeChargeDelete]: "INTERVENTIONS_DELETE_TITLE",
  [APINotificationJobTextEnum.DealDelete]: "DEALS_DELETE_TITLE",
  [APINotificationJobTextEnum.ContactDelete]: "CONTACTS_DELETE_TITLE",
  [APINotificationJobTextEnum.ClientDelete]: "CLIENTS_DELETE_TITLE",
  [APINotificationJobTextEnum.ContractDelete]: "CONTRACTS_DELETE_TITLE",
  [APINotificationJobTextEnum.SubscriptionDelete]: "SUBSCRIPTIONS_DELETE_TITLE",
  [APINotificationJobTextEnum.BillingDelete]: "BILLINGS_DELETE_TITLE",
  [APINotificationJobTextEnum.EmailSignatureDelete]:
    "EMAIL_SIGNATURE_DELETE_TITLE",

  [APINotificationJobTextEnum.CompanyAddOrUpdate]:
    "SHARING_COMPANY_SUCCESS_TITLE",
  [APINotificationJobTextEnum.DepartmentAddOrUpdate]:
    "SHARING_DEPARTMENT_SUCCESS_TITLE",
  [APINotificationJobTextEnum.TeamAddOrUpdate]: "SHARING_TEAM_SUCCESS_TITLE"
};
const startDescTranslationDict = {
  [APINotificationJobTextEnum.RecurrentTaskCreationDelay]:
    "RECURRENT_TASKS_CREATION_DELAY_DESCRIPTION",
  [APINotificationJobTextEnum.RecurrentTaskCreation]:
    "RECURRENT_TASKS_CREATION_DESCRIPTION",
  [APINotificationJobTextEnum.ProjectTaskDeleteDelay]:
    "TASKS_DELETE_DELAY_DESCRIPTION",
  [APINotificationJobTextEnum.ProjectTaskDelete]: "TASKS_DELETE_DESCRIPTION",
  [APINotificationJobTextEnum.TicketDeleteDelay]:
    "TICKETS_DELETE_DELAY_DESCRIPTION",
  [APINotificationJobTextEnum.TicketDelete]: "TICKETS_DELETE_DESCRIPTION",
  [APINotificationJobTextEnum.ProjectDeleteDelay]:
    "PROJECTS_DELETE_DELAY_DESCRIPTION",
  [APINotificationJobTextEnum.ProjectDelete]: "PROJECTS_DELETE_DESCRIPTION",
  [APINotificationJobTextEnum.TimeChargeDeleteDelay]:
    "INTERVENTIONS_DELETE_DELAY_DESCRIPTION",
  [APINotificationJobTextEnum.TimeChargeDelete]:
    "INTERVENTIONS_DELETE_DESCRIPTION",
  [APINotificationJobTextEnum.DealDeleteDelay]:
    "DEALS_DELETE_DELAY_DESCRIPTION",
  [APINotificationJobTextEnum.DealDelete]: "DEALS_DELETE_DESCRIPTION",
  [APINotificationJobTextEnum.ContactDeleteDelay]:
    "CONTACTS_DELETE_DELAY_DESCRIPTION",
  [APINotificationJobTextEnum.ContactDelete]: "CLIENTS_DELETE_DESCRIPTION",
  [APINotificationJobTextEnum.ClientDeleteDelay]:
    "CONTACTS_DELETE_DELAY_DESCRIPTION",
  [APINotificationJobTextEnum.ClientDelete]: "CLIENTS_DELETE_DESCRIPTION",
  [APINotificationJobTextEnum.ContractDeleteDelay]:
    "CONTRACTS_DELETE_DELAY_DESCRIPTION",
  [APINotificationJobTextEnum.ContractDelete]: "CONTRACTS_DELETE_DESCRIPTION",
  [APINotificationJobTextEnum.SubscriptionDeleteDelay]:
    "SUBSCRIPTIONS_DELETE_DELAY_DESCRIPTION",
  [APINotificationJobTextEnum.SubscriptionDelete]:
    "SUBSCRIPTIONS_DELETE_DESCRIPTION",
  [APINotificationJobTextEnum.BillingDeleteDelay]:
    "BILLINGS_DELETE_DELAY_DESCRIPTION",
  [APINotificationJobTextEnum.BillingDelete]: "BILLINGS_DELETE_DESCRIPTION",
  [APINotificationJobTextEnum.EmailSignatureDeleteDelay]:
    "EMAIL_SIGNATURES_DELETE_DELAY_DESCRIPTION",
  [APINotificationJobTextEnum.EmailSignatureDelete]:
    "EMAIL_SIGNATURES_DELETE_DESCRIPTION",
  [APINotificationJobTextEnum.ApiKeyAddOrUpdate]:
    "SHARING_API_KEY_SUCCESS_DESCRIPTION",

  [APINotificationJobTextEnum.AutomationAddOrUpdate]: "SHARING_DESCRIPTION"
};

const getParsedJSON = (response) => {
  if (response) {
    try {
      return JSON.parse(response);
    } catch (e) {
      return {};
    }
  }
};

const SpaceNotificator = ({ children }) => {
  const entitiesHub = useSpaceEntitiesHub();
  const unreadRefetch = useUnreadEntitiesFetcher();
  const createToast = useToast();
  const intl = useIntl();
  const toastRef = useRef({});
  const downloadExcelData = useDownloadExcelData();
  const downloadData = useDownloadData();
  const unreaddUpdater = useUnreadEntitiesFetcher();

  useEffect(() => {
    if (!entitiesHub) return;
    const listner = (type, title, text, jsonObj) => {
      // console.log(type, title, text, jsonObj);
      const textObject = getParsedJSON(jsonObj);
      const { MessageKey, Data, Link, Excel } = textObject || {};

      if (
        MessageKey === "API_IMPORT_CLIENT_END" ||
        MessageKey === "API_IMPORT_CONTACT_END"
      ) {
        createToast({
          pos: "tm",
          type: "info",
          description: intl.formatMessage({ id: MessageKey })
        });
      } else if (MessageKey === "API_EXPORT_COMPLETED_SUCCESSFULLY") {
        const handleClick = () => {
          let baseUrl = Link;
          const timeString = moment().format("YYYY-MM-DDTHH:mm:ss");
          api
            .post(
              baseUrl,
              {
                Data: undefined
              },
              {
                responseType: "arraybuffer"
              }
            )
            .then((r) => {
              if (Excel) downloadExcelData(r, `${timeString}.xlsx`);
              else downloadData(r, `${timeString}.pdf`);
              unreaddUpdater && unreaddUpdater();
            })
            .catch((e) => {
              handleError(createToast, "Ocorreu um erro");
            });
        };

        const description = (
          <div>
            <div>{intl.formatMessage({ id: MessageKey }, Data)}</div>
            {Link && (
              <Button
                className="mt-2"
                style={{ width: 150 }}
                vType="primary"
                onClick={handleClick}
              >
                <FormattedMessage id="DOWNLOAD" />
              </Button>
            )}
          </div>
        );

        unreadRefetch();

        createToast({
          pos: "tm",
          title:
            type === "danger" && title === "Error"
              ? intl.formatMessage({ id: "ERROR" })
              : title,
          type: type,
          description
        });
      } else {
        const description = MessageKey
          ? intl.formatMessage({ id: MessageKey }, Data)
          : text;

        createToast({
          pos: "tm",
          title:
            type === "danger" && title === "Error"
              ? intl.formatMessage({ id: "ERROR" })
              : title,
          type: type,
          description
        });
      }
    };
    entitiesHub.on("APINotification", listner);

    return () => entitiesHub.off("APINotification", listner);
  }, [
    createToast,
    downloadData,
    downloadExcelData,
    entitiesHub,
    intl,
    unreadRefetch,
    unreaddUpdater
  ]);

  useEffect(() => {
    if (!entitiesHub) return;
    const listner = (obj) => {
      const {
        identifier,
        id,
        type,
        title,
        text,
        textId,
        isLoading,
        currentProgress,
        progress,
        cancelationToken,
        errors
      } = JSON.parse(obj);

      const entityType = textId;

      const key = `${identifier}`;

      const updateOrAddToast = toastRef.current[key] || createToast;

      if (type === "error" || type === "danger") {
        if (entityType !== APINotificationJobTextEnum.UserUpdate) {
          const transId = startTranslationDict[entityType];
          toastRef.current[key] = updateOrAddToast({
            pos: "bl",
            title: transId ? (
              <FormattedMessage id={transId} />
            ) : (
              <FormattedMessage id={"SHARE_ERROR_TITLE"} />
            ),
            type: "danger",
            description: text || (
              <FormattedMessage id={"SHARE_ERROR_DESCRIPTION"} />
            )
          });
        } else {
          toastRef.current[key] = updateOrAddToast({
            pos: "bl",
            title: title || (
              <FormattedMessage id={"USER_PERMISSION_ERROR_TITLE"} />
            ),
            type: "danger",
            description: text || (
              <FormattedMessage id={"USER_PERMISSION_ERROR_DESCRIPTION"} />
            )
          });
        }
      } else if (isLoading) {
        const transId = startTranslationDict[entityType];
        const resolvedText = startDescTranslationDict[entityType] ? (
          <FormattedMessage id={startDescTranslationDict[entityType]} />
        ) : (
          text || <FormattedMessage id={"SHARING_DESCRIPTION"} />
        );

        toastRef.current[key] = updateOrAddToast({
          pos: "bl",
          title: title || <FormattedMessage id={transId} values={{ id: id }} />,
          type: type,
          ForcedIcon: <LoaderSpinner svgOnly />,
          timeout: 30000,
          description: (
            <>
              <div>{resolvedText}</div>
              {Boolean(currentProgress) && Boolean(progress) && (
                <div>
                  <PercentageBar
                    percentage={(currentProgress / progress) * 100}
                    className="mt-2"
                    disableAnimation
                  />
                  <div className="mt-1">
                    <b>{currentProgress}</b> de {progress}
                  </div>
                </div>
              )}
              {cancelationToken && (
                <Button
                  vType="link-danger"
                  className="mt-2"
                  onClick={(e) => {
                    // console.log("clicked");
                    e.stopPropagation();
                    try {
                      entitiesHub.invoke(
                        "CancelBackgroundJob",
                        cancelationToken
                      );
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  <FormattedMessage id={"CANCEL"} />
                </Button>
              )}
            </>
          )
        });
      } else {
        const transId = endTranslationDict[entityType];
        toastRef.current[key] = updateOrAddToast({
          pos: "bl",
          title: title || <FormattedMessage id={transId} values={{ id: id }} />,
          type: type,
          timeout: 3000,
          description:
            progress && errors ? (
              <FormattedMessage
                id={"SHARE_SUCCESS_DESCRIPTION_ALT"}
                values={{ errors: errors || 0, total: progress - errors || 0 }}
              />
            ) : (
              <FormattedMessage id={"SHARE_SUCCESS_DESCRIPTION"} />
            )
        });
      }
    };
    entitiesHub.on("APINotificationJob", listner);

    return () => entitiesHub.off("APINotificationJob", listner);
  }, [createToast, entitiesHub, intl]);

  return children;
};

export default SpaceNotificator;
