import React, { useMemo } from "react";
import {
  EntitySummaryTabstrip,
  EntitySummaryTab,
  EntitySummaryOverview,
  EntitySummaryAccordion,
  DescriptionOverview,
  EntitySummaryFilesAccordion,
  EntitySummaryAddress,
  EntityPipelineHistoryAccordion,
  TagsOverViewSummary
} from "../../Components/EntitySummaries/EntitySummaries";
import { History } from "../../Components/DetailsView/History/History";
import { spaceTicketsSchema, spaceTicketTagsSchema } from "../../config/schema";
import TicketForm from "./TicketForm";
import { TicketEntityActionsButton, TicketsStatusTypes } from "./TicketsHelper";
import {
  faAnalytics,
  faInfoSquare,
  faCity,
  faUsers
} from "@fortawesome/pro-solid-svg-icons";
import {
  EntityOverviewItem,
  EntityDescriptionOverviewItem,
  EntityOverviewRoundedItem,
  EntityOverviewPlaceholder,
  EntityOverviewChildrenProgress
} from "../../Components/EntityOverview/EntityOverview";
import {
  faCalendarAlt,
  faFileAlt,
  faUserTie,
  faUsersClass,
  faLink,
  faUserFriends,
  faUserPlus,
  faUserCog,
  faBell,
  faDrawCircle,
  faTag
} from "@fortawesome/pro-light-svg-icons";
import RelativeTime from "../../Components/RelativeTime/RelativeTime";
import { ConnectedTeamProfile } from "../../Components/Teams/TeamProfile";
import { ConnectedDepartmentProfile } from "../../Components/Departments/DepartmentProfile";
import OriginProfile from "../Origin/OriginProfile";
import { EntityOverviewPipeline } from "../../Components/EntityOverview/EntityOverviewPipeline";
import Treeview from "../Treeview/Treeview";
import {
  TicketDetailsChildren,
  TicketDetailsEmailConversations
} from "./TicketDetails/TicketDetailsChildren";
import { ConnectedAccountProfileStack } from "../../Components/Accounts/AccountProfileStack";
import { ConnectedContactPopup } from "../Origin/ContactPopup";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import { RealTimeRoomTypes } from "../RealTime/RealTimeHelper";
import { FormattedMessage } from "react-intl";
import { TicketFormButton } from "../Form/FormButtons";
import TicketRatingAccordion from "../../Components/TicketRatingAccordion/TicketRatingAccordion";
import { getInterventionTarget } from "../../Helpers/FormFormmaterHelper";
import {
  usePipelineStatus,
  useCancelationReason
} from "../../Hooks/EntityHooks";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { canUserReadModule, moduleTypes } from "../../Helpers/ModulesHelper";

export const TicketSummaryContent = ({ value }) => {
  const {
    Contact,
    ReceivedDate,
    NoTagDescription,
    Description,
    Pipeline,
    PipelineStatus,
    StatusDetail,
    Department,
    Team,
    ManagerAccount,
    Addresses,
    Feedback,
    Tags,
    Creator,
    CanEdit,
    IsFollowing,
    Files,
    Id,
    Rating,
    StatusDetailDescription,
    Followers
  } = value;
  const origin = useMemo(() => getInterventionTarget(value), [value]);
  const pipelineStatusObj = usePipelineStatus(PipelineStatus);
  const detail = useCancelationReason(StatusDetail);
  return (
    <>
      <EntitySummaryAccordion icon={faAnalytics} textId="STATUS">
        <EntityOverviewPipeline
          pipeline={Pipeline}
          pipelineStatus={PipelineStatus}
          statusDetail={StatusDetail}
        />

        {StatusDetail &&
          pipelineStatusObj.Status === TicketsStatusTypes.canceled && (
            <EntityOverviewRoundedItem
              icon={faDrawCircle}
              titleId="CANCELATION_REASON"
            >
              {detail.Name}
            </EntityOverviewRoundedItem>
          )}
        {StatusDetailDescription &&
          pipelineStatusObj.Status === TicketsStatusTypes.canceled && (
            <EntityDescriptionOverviewItem
              icon={faDrawCircle}
              titleId="CANCELATION_DESCRIPTION"
            >
              <DescriptionOverview
                Description={StatusDetailDescription}
                NoTagDescription={StatusDetailDescription}
              />
            </EntityDescriptionOverviewItem>
          )}
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faInfoSquare} textId={"DETAILS"}>
        <EntityOverviewItem icon={faLink} titleId="ASSIGNED_TO">
          <OriginProfile dynamicFont origin={origin} />
        </EntityOverviewItem>
        <EntityOverviewChildrenProgress schema={spaceTicketsSchema} id={Id} />

        <EntityOverviewRoundedItem icon={faTag} titleId="CASE_TAGS">
          <TagsOverViewSummary tags={Tags} schema={spaceTicketTagsSchema} />
        </EntityOverviewRoundedItem>

        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="DESCRIPTION">
          <DescriptionOverview
            Description={Description}
            NoTagDescription={NoTagDescription}
          />
        </EntityDescriptionOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faCity} textId="ASSOCIATED">
        <EntityOverviewItem icon={faUserTie} titleId="CONTACT">
          <ConnectedContactPopup
            placement="right"
            dynamicFont
            contact={Contact}
          />
        </EntityOverviewItem>
        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="ENTRY">
          <RelativeTime date={ReceivedDate} />
        </EntityOverviewRoundedItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faUsers} textId="ASSOCIATED_TO">
        <EntityOverviewItem icon={faUsersClass} titleId="DEPARTMENT">
          {Department ? (
            <ConnectedDepartmentProfile dynamicFont department={Department} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>

        <EntityOverviewItem icon={faUserFriends} titleId="TEAM">
          {Team ? (
            <ConnectedTeamProfile dynamicFont team={Team} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>

        <EntityOverviewItem icon={faUserPlus} titleId="CREATED_BY">
          <ConnectedAccountPopup
            placement="right"
            dynamicFont
            account={Creator}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUserCog} titleId="ASSIGNED_TO">
          <ConnectedAccountPopup
            placement="right"
            dynamicFont
            account={ManagerAccount}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faBell} titleId="FOLLOWERS">
          {Followers && Followers.length > 0 ? (
            <ConnectedAccountProfileStack dynamicFont accounts={Followers} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAddress addresses={Addresses} />
      {Rating && <TicketRatingAccordion value={Rating} feedback={Feedback} />}
      <EntitySummaryFilesAccordion
        CanEdit={CanEdit}
        IsFollowing={IsFollowing}
        Files={Files}
      />
      {/* <EntityPipelineHistoryAccordion
        endpoint={`Tickets/${Id}/PipelineHistory`}
        id={Id}
        schema={spaceTicketsSchema}
      />
      <div className="mb-3"></div> */}
      <EntityPipelineHistoryAccordion
        endpoint={`history/ticket/${Id}/pipeline`}
        id={Id}
        schema={spaceTicketsSchema}
      />
    </>
  );
};

export const InnerTicketSummary = ({ value }) => {
  const currentAccountSpace = useCurrentAccountSpace();

  const hasEmailPermission = canUserReadModule(
    currentAccountSpace,
    moduleTypes.emailConversations
  );

  return (
    <EntitySummaryTabstrip className="mt-2 mb-3">
      <EntitySummaryTab titleId="SUMMARY">
        <TicketSummaryContent value={value} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="HISTORY">
        <History ticket={value.Id} disableTabs />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ORIGINATED">
        <Treeview
          className="flex-1"
          schema={spaceTicketsSchema}
          id={value.Id}
        />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ASSOCIATIONS">
        <TicketDetailsChildren />
      </EntitySummaryTab>
      {hasEmailPermission && (
        <EntitySummaryTab titleId="EMAILS">
          <TicketDetailsEmailConversations id={value.Id} />
        </EntitySummaryTab>
      )}
    </EntitySummaryTabstrip>
  );
};

const TicketSummary = (props) => {
  return (
    <EntitySummaryOverview
      {...props}
      url={"tickets"}
      type={"ticket"}
      schema={spaceTicketsSchema}
      FormComponent={TicketForm}
      Summary={InnerTicketSummary}
      creationHeaderText={<FormattedMessage id="CREATE_CASE" />}
      ActionButton={TicketEntityActionsButton}
      FormButton={TicketFormButton}
      roomType={RealTimeRoomTypes.Ticket}
    ></EntitySummaryOverview>
  );
};

export default TicketSummary;
