import React, { useRef, useContext } from "react";
import {
  faCalendarExclamation,
  faFileAlt,
  faFileContract,
  faListCheck,
  faMessageDollar,
  faMessagesDollar,
  faPhoneAlt,
  faSquareParking,
  faSuitcase,
  faTableLayout,
  faTicketSimple,
  faUserClock,
  faCalendarAlt,
  faUserPlus,
  faDrawCircle,
  faAlignJustify,
  faFileLines
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColorFilterBars } from "../../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import {
  DocTemplatesFormButton,
  DocumentsFormButton
} from "../../Form/FormButtons";
import { createFormData } from "../../../Helpers/MiscHelper";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import classes from "./DocTemplates.module.css";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import InformationComponent from "../../../Components/InformationComponent/InformationComponent";
import { ConnectedAccountProfile } from "../../../Components/Accounts/AccountProfile";
import {
  createUserFilter,
  createPipelineStatusFilter,
  createDocumentTypeFilter,
  createTemplateDealAssociationFilter,
  createTemplateAccountAssociationFilter,
  createTemplateContractAssociationFilter,
  createTemplateSubscriptionAssociationFilter,
  createTemplateCallAssociationFilter,
  createTemplateCaseAssociationFilter,
  createTemplateProjectsAssociationFilter,
  createTemplateTaskAssociationFilter,
  createTemplateTimesAssociationFilter,
	createUpgradedPipelineStatusFilter
} from "../../../Helpers/FilterHelper";
import { documentPipelineSettings } from "../DocumentsOverview/DocumentsHelper";
import { DocumentsStatusTypes } from "../../Settings/Documents/DocumentsHelper";
import { usePipelineStatus } from "../../../Hooks/EntityHooks";
import { faTableLayout as faTableLayoutSolid } from "@fortawesome/pro-solid-svg-icons";
import {
  NewEntityPopup,
  EntityPopup
} from "../../../Components/EntityPopup/EntityPopup";
import { useCurrentAccountSpace } from "../../../Contexts/UserContext";
import { EntityPopupContext } from "../../../Components/EntityPopup/EntityPopupHelper";
import { LabelTitle } from "../../../Helpers/GridHelper";
import EntityReadButton from "../../../Components/EntityRead/EntityReadButton";
import { spaceDocTemplatesSchema } from "../../../config/schema";
// import { NewEntityPopup } from "../../../Components/EntityPopup/EntityPopup";
// import EntityPopup from "../../EntityPopup/EntityPopup";
// import { useCurrentAccountSpace } from "../../../Contexts/UserContext";
// import { EntityPopupContext } from "../../../Components/EntityPopup/EntityPopupHelper";
// import { LabelTitle } from "../../../Helpers/GridHelper";
// // import { DocTemplatesClipboardButton } from "../../../Helpers/HooksHelper";
// import EntityReadButton from "../../../Components/EntityRead/EntityReadButton";
// import { spaceDocTemplatesSchema } from "../../../config/schema";

const DocTemplatesEditButton = ({ id, contextMenu }) => {
  const url = `templates/${id}`;

  if (contextMenu) {
    return (
      <Link to={url}>
        <Button vType="link" type="button" className="text-left">
          <div className="mr-1 ar-form-button-link-icon">
            <FontAwesomeIcon icon={faTableLayout} />
          </div>
          <span className="fw-regular fs-14">
            {" "}
            <FormattedMessage id="SPACE_OPEN" />
          </span>
        </Button>
      </Link>
    );
  }

  return (
    <Link to={url}>
      <Button
        vType="primary-ghost"
        size="sm"
        type="button"
        className="d-flex align-items-center justify-content-center"
      >
        <FontAwesomeIcon icon={faTableLayout} />
      </Button>
    </Link>
  );
};

// const DocTemplatesEditButton = ({ id }) => {
//   const url = `templates/${id}`;
//   return (
//     <Link to={url}>
//       <Button
//         vType="primary-ghost"
//         size="sm"
//         type="button"
//         className="d-flex align-items-center justify-content-center"
//       >
//         <FontAwesomeIcon icon={faTableLayout} />
//       </Button>
//     </Link>
//   );
// };

export const DocTemplatesButtonGroup = ({
  entity,
  hideBreadCrumbs,
  minimal,
  skeleton,
  noColors
}) => {
  return (
    <div className="d-flex align-items-center">
      {!entity.IsLock && <DocTemplatesEditButton id={entity.Id} />}

      <DocTemplatesFormButton
        minimal={minimal}
        id={entity.Id}
        canEdit={entity.CanEdit && !entity.IsLock}
        skeleton={skeleton}
        className="mx-2 FormButtonClassID"
      />
      <DocTemplatesEntityActionsButton minimal={minimal} entity={entity} />
      {!noColors && <ColorFilterBars entity={entity} />}
    </div>
  );
};

export const DocTemplateEntityActionsChildren = ({
  entity,
  minimal,
  sidebar,
  extraActionButton,
  detailsView
}) => {
  const accountSpace = useCurrentAccountSpace();

  const toggleOff = useContext(EntityPopupContext);

  return (
    <>
      {!sidebar && !detailsView && <DocTemplatesFormButton id={entity.Id} />}
      {!sidebar && !detailsView && (
        <DocTemplatesEditButton contextMenu id={entity.Id} />
      )}
      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      <DocTemplatesFormButton id={entity.Id} duplicate />
      {/* <DocTemplatesClipboardButton id={entity.Id} /> */}
      {extraActionButton && extraActionButton(toggleOff)}
      <EntityReadButton value={entity} schema={spaceDocTemplatesSchema} />
      {/* <DeleteButtonContextMenu entity={entity} /> */}
    </>
  );
};

export const DocTemplatesEntityActionsButton = ({
  minimal,
  newEntity,
  entity,
  ...props
}) => {
  if (newEntity) {
    return (
      <>
        <NewEntityPopup minimal={minimal}>
          <DocTemplateEntityActionsChildren
            minimal={minimal}
            entity={entity}
            {...props}
          />
        </NewEntityPopup>
      </>
    );
  }

  return (
    <>
      <EntityPopup minimal={minimal}>
        <DocTemplateEntityActionsChildren
          minimal={minimal}
          entity={entity}
          {...props}
        />
      </EntityPopup>
    </>
  );
};

export const getDocTemplatesFilters = () => [
  {
    propField: "Id",
    icon: <FontAwesomeIcon icon={faTableLayout} />,
    title: "Nº",
    field: "Document/Id",
    type: "string"
  },
  createDocumentTypeFilter({
    icon: <FontAwesomeIcon icon={faFileLines} />,
    propField: "DocumentType",
    field: "DocumentType/Id"
  }),
  createTemplateAccountAssociationFilter(),
  createTemplateDealAssociationFilter(),
  createTemplateContractAssociationFilter(),
  createTemplateSubscriptionAssociationFilter(),
  createTemplateCallAssociationFilter(),
  createTemplateCaseAssociationFilter(),
  createTemplateProjectsAssociationFilter(),
  createTemplateTaskAssociationFilter(),
  createTemplateTimesAssociationFilter(),
  {
    propField: "Name",
    icon: <FontAwesomeIcon icon={faAlignJustify} />,
    titleId: "NAME",
    field: "Document/Name",
    type: "string"
  },
  // createPipelineStatusFilter({
  //   icon: <FontAwesomeIcon icon={faDrawCircle} />,
  //   pipelineSettings: documentPipelineSettings
  // }),
  createUpgradedPipelineStatusFilter({
    icon: <FontAwesomeIcon icon={faDrawCircle} />,
    pipelineSettings: documentPipelineSettings
  }),
  createUserFilter({
    propField: "Assigned",
    icon: <FontAwesomeIcon icon={faUserPlus} />,
    titleId: "ASSIGNED_TO",
    field: "Assigned/Id",
    DescriptionComponent: ({ item, value }) => {
      return (
        <div className="d-flex flex-column align-items-center">
          <FormattedMessage id="ASSIGNED_TO" className="mr-2" />
          <ConnectedAccountProfile account={value} />
        </div>
      );
    }
  }),
  {
    propField: "CreationDate",
    icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    titleId: "CREATION_DATE",
    field: "Document/CreationDateNoHours",
    type: "dateTime"
  },
  {
    propField: "LastChange",
    icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    titleId: "LAST_CHANGE_DATE",
    field: "Document/LastChangeNoHours",
    type: "dateTime"
  }
];

export const AssociationTypeEnum = {
  Client: 1,
  Deal: 2,
  Contract: 4,
  Subscription: 8,
  Call: 16,
  Ticket: 32,
  Project: 64,
  Task: 128,
  Time: 256
};

export const formatDocTemplateForm = (form) => {
  const {
    DocTarget,
    Company,
    Pipeline,
    PipelineStatus,
    Companies,
    AssociateTo,
    AssignedTo,
    DocumentType,
    Collaborators = [],
    PublicStatus,
    Files,
    ...rest
  } = form;

  // const orgs = [],
  //   deps = [],
  //   teams = [],
  //   people = [];

  // for (const item of Collaborators) {
  //   if (item.type === "Account") people.push(item.data);
  //   else if (item.type === "Team") teams.push(item.data);
  //   else if (item.type === "organization") orgs.push(item.data);
  //   else if (item.type === "Department") deps.push(item.data);
  // }

  const obj = {
    ...rest,
    AssociateTo: AssociateTo.reduce((partialSum, a) => partialSum + a, 0),
    OrganizationId: Company,
    PipelineId: Pipeline,
    PipelineStatusId: PipelineStatus,
    DocumentTypeId: DocumentType,
    AssignedToId: AssignedTo,
    Organizations: Companies,
    // Departments: deps,
    // Teams: teams,
    // Collaborator: people,
    IsPublic: PublicStatus
  };

  let NewFiles = [];
  if (!Array.isArray(Files)) {
    for (const key in Files) {
      if (Object.hasOwnProperty.call(Files, key)) {
        const element = Files[key] || {};
        const { Label, Name, Id, Extension } = element;
        NewFiles.push({ Label, Name, Id, Extension, IsRecovery: true });
      }
    }
  } else NewFiles = [...Files];

  return createFormData(obj, NewFiles);
};

export const validateDocTemplateForm = (
  { AssociateTo, Company, DocumentType, Pipeline, PipelineStatus, Name, AssignedTo },
  addError
) => {
  if (!DocumentType) addError("DocumentType");
  if (!AssignedTo) addError("AssignedTo");
  if (!Company) addError("Company");
  if (!AssociateTo || AssociateTo.length === 0) addError("AssociateTo");
  if (!Name) addError("Name");
  if (!Pipeline) addError("Pipeline");
  if (!PipelineStatus) addError("PipelineStatus");
};

export const convertDocTemplateEntityToForm = (entity, props) => {
  const { Collaborators, Associated, Company, ...entityRest } = entity;

  const { Accounts, Teams, Companies, Departments } = Collaborators || {};
  const data = [];

  if (Accounts || Teams || Companies || Departments) {
    if (Accounts && Array.isArray(Accounts)) {
      for (let i = 0; i < Accounts.length; i++) {
        if (Accounts[i].type === "Account" && Accounts[i].data)
          data.push(Accounts[i]);
        else data.push({ type: "Account", data: Accounts[i] });
      }
    }
    if (Teams && Array.isArray(Teams)) {
      for (let i = 0; i < Teams.length; i++) {
        if (Teams[i].type === "Team" && Teams[i].data) data.push(Teams[i]);
        else data.push({ type: "Team", data: Teams[i] });
      }
    }
    if (Companies && Array.isArray(Companies)) {
      for (let i = 0; i < Companies.length; i++) {
        if (Companies[i].type === "organization" && Companies[i].data)
          data.push(Companies[i]);
        else data.push({ type: "organization", data: Companies[i] });
      }
    }

    if (Departments && Array.isArray(Departments)) {
      for (let i = 0; i < Departments.length; i++) {
        if (Departments[i].type === "Department" && Departments[i].data)
          data.push(Departments[i]);
        else data.push({ type: "Department", data: Departments[i] });
      }
    }
  }

  const resolvedAssociated = resolveAssociations(Associated);

  return {
    ...entityRest,
    Companies,
    Company,
    AssociateTo: resolvedAssociated,
    Collaborators: data
  };
};

const resolveAssociations = (Associations) => {
  const result = [];

  for (const e of DocTemplateTargetOptions) {
    if ((Associations & e) === e) result.push(e);
  }
  return result;
};

const DocTemplateTargetOptions = [
  AssociationTypeEnum.Client,
  AssociationTypeEnum.Deal,
  AssociationTypeEnum.Contract,
  AssociationTypeEnum.Subscription,
  AssociationTypeEnum.Call,
  AssociationTypeEnum.Ticket,
  AssociationTypeEnum.Project,
  AssociationTypeEnum.Task,
  AssociationTypeEnum.Time
];

export const DocTemplateTargetProfileComp = ({ entity, status, item }) => {
  const resovledStatus = entity ? entity.Status : status || item;

  switch (resovledStatus) {
    case AssociationTypeEnum.Client:
      return (
        <div className={classes.DocTemplateTargetItem}>
          <FontAwesomeIcon icon={faSuitcase} className="mr-2" />
          <FormattedMessage id="ACCOUNTS" />
        </div>
      );
    case AssociationTypeEnum.Deal:
      return (
        <div className={classes.DocTemplateTargetItem}>
          <FontAwesomeIcon icon={faMessagesDollar} className="mr-2" />
          <FormattedMessage id="DEALS" />
        </div>
      );
    case AssociationTypeEnum.Contract:
      return (
        <div className={classes.DocTemplateTargetItem}>
          <FontAwesomeIcon icon={faFileContract} className="mr-2" />
          <FormattedMessage id="CONTRACTS" />
        </div>
      );
    case AssociationTypeEnum.Subscription:
      return (
        <div className={classes.DocTemplateTargetItem}>
          <FontAwesomeIcon icon={faCalendarExclamation} className="mr-2" />
          <FormattedMessage id="SUBSCRIPTIONS" />
        </div>
      );
    case AssociationTypeEnum.Call:
      return (
        <div className={classes.DocTemplateTargetItem}>
          <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
          <FormattedMessage id="CALLS" />
        </div>
      );
    case AssociationTypeEnum.Ticket:
      return (
        <div className={classes.DocTemplateTargetItem}>
          <FontAwesomeIcon icon={faTicketSimple} className="mr-2" />
          <FormattedMessage id="CASES" />
        </div>
      );
    case AssociationTypeEnum.Project:
      return (
        <div className={classes.DocTemplateTargetItem}>
          <FontAwesomeIcon icon={faSquareParking} className="mr-2" />
          <FormattedMessage id="PROJECTS" />
        </div>
      );
    case AssociationTypeEnum.Task:
      return (
        <div className={classes.DocTemplateTargetItem}>
          <FontAwesomeIcon icon={faListCheck} className="mr-2" />
          <FormattedMessage id="TASKS" />
        </div>
      );
    case AssociationTypeEnum.Time:
      return (
        <div className={classes.DocTemplateTargetItem}>
          <FontAwesomeIcon icon={faUserClock} className="mr-2" />
          <FormattedMessage id="TIMES" />
        </div>
      );

    default:
      return <></>;
  }
};

const DocTemplateTargetProfileSimpleComp = ({ entity, status, item }) => {
  const resovledStatus = entity ? entity.Status : status || item;

  switch (resovledStatus) {
    case AssociationTypeEnum.Client:
      return (
        <InformationComponent text={<FormattedMessage id="ACCOUNTS" />}>
          <div className={classes.DocTemplateTargetItemSimple}>
            <FontAwesomeIcon icon={faSuitcase} />
          </div>
        </InformationComponent>
      );
    case AssociationTypeEnum.Deal:
      return (
        <InformationComponent text={<FormattedMessage id="DEALS" />}>
          <div className={classes.DocTemplateTargetItemSimple}>
            <FontAwesomeIcon icon={faMessagesDollar} />
          </div>
        </InformationComponent>
      );
    case AssociationTypeEnum.Contract:
      return (
        <InformationComponent text={<FormattedMessage id="CONTRACTS" />}>
          <div className={classes.DocTemplateTargetItemSimple}>
            <FontAwesomeIcon icon={faFileContract} />
          </div>
        </InformationComponent>
      );
    case AssociationTypeEnum.Subscription:
      return (
        <InformationComponent text={<FormattedMessage id="SUBSCRIPTIONS" />}>
          <div className={classes.DocTemplateTargetItemSimple}>
            <FontAwesomeIcon icon={faCalendarExclamation} />
          </div>
        </InformationComponent>
      );
    case AssociationTypeEnum.Call:
      return (
        <InformationComponent text={<FormattedMessage id="CALLS" />}>
          <div className={classes.DocTemplateTargetItemSimple}>
            <FontAwesomeIcon icon={faPhoneAlt} />
          </div>
        </InformationComponent>
      );
    case AssociationTypeEnum.Ticket:
      return (
        <InformationComponent text={<FormattedMessage id="CASES" />}>
          <div className={classes.DocTemplateTargetItemSimple}>
            <FontAwesomeIcon icon={faTicketSimple} />
          </div>
        </InformationComponent>
      );
    case AssociationTypeEnum.Project:
      return (
        <InformationComponent text={<FormattedMessage id="PROJECTS" />}>
          <div className={classes.DocTemplateTargetItemSimple}>
            <FontAwesomeIcon icon={faSquareParking} />
          </div>
        </InformationComponent>
      );
    case AssociationTypeEnum.Task:
      return (
        <InformationComponent text={<FormattedMessage id="TASKS" />}>
          <div className={classes.DocTemplateTargetItemSimple}>
            <FontAwesomeIcon icon={faListCheck} />
          </div>
        </InformationComponent>
      );
    case AssociationTypeEnum.Time:
      return (
        <InformationComponent text={<FormattedMessage id="TIMES" />}>
          <div className={classes.DocTemplateTargetItemSimple}>
            <FontAwesomeIcon icon={faUserClock} />
          </div>
        </InformationComponent>
      );

    default:
      return <></>;
  }
};

export const DocTemplatesTarget = ({ error, ...props }) => {
  return (
    <Dropdown
      className={classnames({ error: error })}
      items={DocTemplateTargetOptions}
      removable
      multiple
      listItemComponent={DocTemplateTargetProfileComp}
      valueComponent={DocTemplateTargetProfileComp}
      {...props}
    />
    // <KeyedDropdown
    //   options={DocTemplateTargetOptions}
    //   multiple
    //   valueComponent={DocTemplateTargetProfileComp}
    //   closeOnSelect
    //   {...props}
    // />
  );
};

export const DocTemplateAssociateToGridComponent = ({ associate }) => {
  const resolvedAssociated = resolveAssociations(associate);

  const body = resolvedAssociated.map((e, i) => {
    return <DocTemplateTargetProfileSimpleComp key={i} item={e} />;
  });

  return <div className="d-flex align-items-center">{body}</div>;
};

export const DifrentDocTemplateProfile = ({ doc, size, ...rest }) => {
  const { PipelineStatus } = doc || {};

  const { Status } = usePipelineStatus(PipelineStatus) || {};

  if (!doc || !Status) return null;

  let colorStatus;

  switch (Status) {
    case DocumentsStatusTypes.active:
      colorStatus = "status-in-progress-colors";
      break;
    case DocumentsStatusTypes.cancelled:
      colorStatus = "status-canceled-colors";
      break;
    case DocumentsStatusTypes.closed:
      colorStatus = "status-closed-colors";
      break;
    case DocumentsStatusTypes.draft:
      colorStatus = "status-yellow-colors";
      break;
    case DocumentsStatusTypes.review:
      colorStatus = "status-orange-colors";
      break;
    default:
      break;
  }

  return (
    <div className={classnames("air-new-profiles-circle", colorStatus, size)}>
      <FontAwesomeIcon icon={faTableLayoutSolid} size={size || "xl"} />
    </div>
  );
};
