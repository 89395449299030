import { useEffect, useState } from "react";
import { dateFormat } from "../Assets/Utils";
import { useSpaceQuery } from "./IOClient";
import { MinuteToMilSec } from "./MiscHelper";

const calculateUrl = (settings) => {
  const {
    baseUrl,
    baseField,
    user,
    ownId,
    BeginDateField,
    Attribute,
    AttributesField,
    EndDateField,
    startFormmated,
    endFormmated
  } = settings;
  if (!startFormmated || !endFormmated) return null;
  const timelineFilter = `${
    ownId ? `(${baseField}/Id ne '${ownId}') and ` : ""
  }(${AttributesField} eq '${Attribute}') and (Assigned/Id eq '${user}') and ((betweenTimesFields(${baseField}/${BeginDateField},${baseField}/${EndDateField},datetime'${startFormmated}')) or (betweenTimes(${baseField}/${BeginDateField},datetime'${startFormmated}',datetime'${endFormmated}')) or (betweenTimes(${baseField}/${EndDateField},datetime'${startFormmated}',datetime'${endFormmated}')))`;
  return `${baseUrl}/?$inlinecount=allpages&$orderby=${baseField}/EndDate asc&$skip=${0}&$top=${10}&$filter=(${timelineFilter})`;
};

const calculateNoTimeUrl = (settings) => {
  const {
    baseUrl,
    baseField,
    user,
    ownId,
    BeginDateField,
    Attribute,
    AttributesField,
    EndDateField,
    startFormmated,
    endFormmated
  } = settings;
  if (!startFormmated || !endFormmated) return null;
  const timelineFilter = `${
    ownId ? `(${baseField}/Id ne '${ownId}') and ` : ""
  }(${AttributesField} eq '${Attribute}') and (Assigned/Id eq '${user}') and ((betweenFields(${baseField}/${BeginDateField},${baseField}/${EndDateField},datetime'${startFormmated}')) or (between(${baseField}/${BeginDateField},datetime'${startFormmated}',datetime'${endFormmated}')) or (between(${baseField}/${EndDateField},datetime'${startFormmated}',datetime'${endFormmated}')))`;
  return `${baseUrl}?$inlinecount=allpages&$orderby=${baseField}/EndDate asc&$skip=${0}&$top=${10}&$filter=(${timelineFilter})`;
};

export const useTimelineQueryString = (settings) => {
  const { schema, noTime } = settings || {};

  const [url, setUrl] = useState(() => {
    if (!schema) return null;
    if (noTime) return calculateNoTimeUrl(settings);
    return calculateUrl(settings);
  });

  useEffect(() => {
    let timeout;
    if (schema) {
      const newUrl = noTime
        ? calculateNoTimeUrl(settings)
        : calculateUrl(settings);
      if (newUrl === url) return;
      timeout = setTimeout(() => {
        setUrl(newUrl);
      }, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [noTime, schema, settings, url]);

  const queryData = useSpaceQuery(url, schema, {
    autoFetch: false,
    cache: false
  });

  return queryData;
};

export const buildODataOrderBy = (orderBy) => {
  // if (Array.isArray(orderBy)) orderBy = orderBy[0]
  let orderByString = "$orderby=";

  // if (orderBy.length === 0) return orderByString;
  if (orderBy.length === 0) return undefined;
  const resolvedOrderBy = orderBy.slice(0, 2);
  let hasSetFistOrderBy = false;

  for (const ob of resolvedOrderBy) {
    const { column, type } = ob;
    if (hasSetFistOrderBy) orderByString += ",";
    orderByString += `${column} ${type}`;
    hasSetFistOrderBy = true;
  }

  return orderByString;
};

const getResolvedDateTimeValue = (value, isUrl) => {
  let date;

  if (typeof value === "string" && value.includes("datetime")) {
    return value;
  } else if (typeof value === "string") {
    if (isUrl) return `'${value}'`;
    date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    switch (value) {
      case "today":
        break;
      case "yesterday":
        date.setDate(date.getDate() - 1);
        break;
      case "tomorow":
        date.setDate(date.getDate() + 1);
        break;
      case "last7":
        date.setDate(date.getDate() - 7);
        break;

      case "last15":
        date.setDate(date.getDate() - 15);
        break;

      case "last30":
        date.setDate(date.getDate() - 30);
        break;

      case "last180":
        date.setDate(date.getDate() - 180);
        break;

      case "last365":
        date.setDate(date.getDate() - 365);
        break;

      case "next7":
        date.setDate(date.getDate() + 7);
        break;

      case "next15":
        date.setDate(date.getDate() + 15);
        break;

      case "next30":
        date.setDate(date.getDate() + 30);
        break;

      case "next180":
        date.setDate(date.getDate() + 180);
        break;

      case "next365":
        date.setDate(date.getDate() + 365);
        break;

      default:
        const split = value.split("X");
        const xValue = parseInt(split[1]);
        if (typeof xValue === "number") {
          switch (split[0]) {
            case "next":
            case "notNext":
              date.setDate(date.getDate() + xValue);
              break;
            case "last":
            case "notLast":
              date.setDate(date.getDate() - xValue);
              break;
            default:
              break;
          }
        }
        break;
    }
  } else date = value;

  return `datetime'${dateFormat(date, "yyyy-MM-dd")}'`;
};

const getResolvedValue = (value, type, isUrl, field) => {
  if (
    type === "number" &&
    (field === "TimeCharge/DeslocationTime" ||
      field === "TimeCharge/PauseTime" ||
      field === "TimeCharge/InputtedDuration" ||
      field === "TimeCharge/InefficientDuration")
  ) {
    return MinuteToMilSec(value);
  }

  switch (type) {
    case "date":
    case "dateTimeOffset":
      return getResolvedDateTimeValue(value);

    case "dateTime":
      return getResolvedDateTimeValue(value, isUrl);
    case "number":
      return value;

    default:
      if (
        typeof value === "string" &&
        value?.charAt(0) === "'" &&
        value?.charAt(value.length - 1) === "'"
      )
        return `${value}`;
      else return `'${value}'`;
  }
};

const getResolvedFieldName = (originalFilterName, isUrl) =>
  typeof originalFilterName === "object"
    ? isUrl
      ? originalFilterName.urlField
      : originalFilterName.resolvedField
    : originalFilterName;

export const buildODataFilter = (filters, isUrl, isApiRequest) => {
  let query = isUrl ? "" : "$filter=";
  if (isApiRequest) query += "(";
  let hasSetFilters = false;

  const setFilter = (p) => {
    if (hasSetFilters) {
      if (isApiRequest) query += ` and (${p})`;
      else query += ` and ${p}`;
    } else {
      hasSetFilters = true;
      if (isApiRequest) query += `(${p})`;
      else query += `${p}`;
    }
  };

  const setFilterOperators = (
    originalFilterName,
    operator,
    operatorValue,
    dataType
  ) => {
    const filterName = getResolvedFieldName(originalFilterName, isUrl);
    let resolvedValue;

    if (operator !== "isempty" && operator !== "isnotempty") {
      if (operator === "isbetween") {
        resolvedValue = {
          start: getResolvedValue(operatorValue.start, dataType),
          end: getResolvedValue(operatorValue.end, dataType)
        };
      } else {
        resolvedValue = getResolvedValue(operatorValue, dataType);
      }
    }

    if (
      (dataType === "dateTime" ||
        dataType === "date" ||
        dataType === "dateTimeOffset") &&
      typeof operatorValue === "string"
    ) {
      if (operatorValue.includes("notLast")) {
        setFilter(`${filterName} lt ${resolvedValue}`);
      } else if (operatorValue.includes("last")) {
        setFilter(
          `(${filterName} ge ${resolvedValue}) and (${filterName} le ${getResolvedValue(
            new Date(),
            dataType
          )})`
        );
      }

      if (operatorValue.includes("notNext")) {
        setFilter(`(${filterName} gt ${resolvedValue})`);
      } else if (operatorValue.includes("next")) {
        setFilter(
          `(${filterName} ge ${getResolvedValue(
            new Date(),
            dataType
          )}) and (${filterName} le ${resolvedValue})`
        );
      }

      if (operatorValue.includes("today")) {
        setFilter(`${filterName} eq ${resolvedValue}`);
      }
      if (operatorValue.includes("yesterday")) {
        setFilter(`${filterName} eq ${resolvedValue}`);
      }
      if (operatorValue.includes("tomorow")) {
        setFilter(`${filterName} eq ${resolvedValue}`);
      }
      // return;
    }

    switch (operator) {
      case "doesnotcontain":
        setFilter(`substringof(${filterName}, ${operatorValue}) eq false`);
        break;

      case "contains":
        setFilter(`substringof(${filterName}, ${operatorValue}) eq true`);
        break;

      case "endswith":
        setFilter(`endswith(${filterName}, ${operatorValue}) eq true`);
        break;

      case "startswith":
        setFilter(`startswith(${filterName}, ${operatorValue}) eq true`);
        break;

      case "isempty":
        setFilter(`${filterName} eq null or ${filterName} eq ''`);
        break;

      case "isnotempty":
        setFilter(`${filterName} ne null and ${filterName} ne ''`);
        break;

      case "isbetween":
        setFilter(
          `${filterName} gt ${resolvedValue.start} and ${filterName} lt ${resolvedValue.end}`
        );
        break;

      default:
        setFilter(`${filterName} ${operator} ${resolvedValue}`);
    }
  };

  for (var filterName in filters) {
    // if (!filters.hasOwnProperty(filterName)) continue;
    const filter = filters[filterName];
    // if (filterName === "undefined") continue;

    const {
      field,
      operator,
      value,
      resolvedValue,
      createResolvedApiFilter,
      // outboundFields,
      data
    } = filter;

    const dataType = filter.data.type;
    if (isApiRequest && createResolvedApiFilter) {
      const f = createResolvedApiFilter(value);
      if (f) setFilter(f);
      else
        setFilterOperators(field, operator, resolvedValue || value, dataType);
    } else if (isApiRequest) {
      if (data?.outboundFields) {
        const { outboundFields } = data;
        let s = "";
        for (const field of outboundFields) {
          if (s) s += " or ";

          s += `${getResolvedFieldName(
            field,
            isUrl
          )} ${operator} ${getResolvedValue(
            resolvedValue || value,
            dataType,
            isUrl
          )}`;
        }
        setFilter(s);
      } else {
        setFilterOperators(
          field,
          operator,
          getResolvedValue(resolvedValue || value, dataType, isUrl, field),
          dataType,
          field
        );
      }
    } else {
      setFilter(
        `${getResolvedFieldName(field, isUrl)} ${operator} ${getResolvedValue(
          resolvedValue || value,
          dataType,
          isUrl
        )}`
      );
    }

    // if (typeof filter !== "object") setFilter(`${filterName} eq ${filter}`);
    // else {
    //   const { field, operator, value } = filter;
    //   setFilterOperators(field, operator, value);
    // }
  }

  if (isApiRequest) {
    query += ")";
    query.replace("+", "%2B");
  }
  return query;
};

export const buildODataConversationFilter = (filters, isUrl, isApiRequest) => {
  let query = isUrl ? "" : "$conversationFilter=";
  if (isApiRequest) query += "(";
  let hasSetFilters = false;

  const setFilter = (p) => {
    if (hasSetFilters) {
      if (isApiRequest) query += ` and (${p})`;
      else query += ` and ${p}`;
    } else {
      hasSetFilters = true;
      if (isApiRequest) query += `(${p})`;
      else query += `${p}`;
    }
  };

  const setFilterOperators = (
    originalFilterName,
    operator,
    operatorValue,
    dataType
  ) => {
    const filterName = getResolvedFieldName(originalFilterName, isUrl);
    let resolvedValue;

    if (operator !== "isempty" && operator !== "isnotempty") {
      if (operator === "isbetween") {
        resolvedValue = {
          start: getResolvedValue(operatorValue.start, dataType),
          end: getResolvedValue(operatorValue.end, dataType)
        };
      } else {
        resolvedValue = getResolvedValue(operatorValue, dataType);
      }
    }

    switch (operator) {
      case "doesnotcontain":
        setFilter(`substringof(${filterName}, ${resolvedValue}) eq false`);
        break;

      case "contains":
        setFilter(`substringof(${filterName}, ${resolvedValue}) eq true`);
        break;

      case "endswith":
        setFilter(`endswith(${filterName}, ${resolvedValue}) eq true`);
        break;

      case "startswith":
        setFilter(`startswith(${filterName}, ${resolvedValue}) eq true`);
        break;

      case "isempty":
        setFilter(`${filterName} eq null or ${filterName} eq ''`);
        break;

      case "isnotempty":
        setFilter(`${filterName} ne null and ${filterName} ne ''`);
        break;

      case "isbetween":
        setFilter(
          `${filterName} gt ${resolvedValue.start} and ${filterName} lt ${resolvedValue.end}`
        );
        break;

      default:
        setFilter(`${filterName} ${operator} ${resolvedValue}`);
    }
  };

  for (var filterName in filters) {
    const filter = filters[filterName];

    const { field, operator, value, resolvedValue, createResolvedApiFilter } =
      filter;
    const dataType = filter.data.type;

    if (isApiRequest && createResolvedApiFilter) {
      const f = createResolvedApiFilter(value);
      if (f) setFilter(f);
      else
        setFilterOperators(field, operator, resolvedValue || value, dataType);
    } else if (isApiRequest) {
      setFilterOperators(field, operator, resolvedValue || value, dataType);
    } else {
      // setFilterOperators(field, operator, resolvedValue || value, dataType);
      setFilter(
        `${getResolvedFieldName(field, isUrl)} ${operator} ${getResolvedValue(
          resolvedValue || value,
          dataType
        )}`
      );
    }

    // if (typeof filter !== "object") setFilter(`${filterName} eq ${filter}`);
    // else {
    //   const { field, operator, value } = filter;
    //   setFilterOperators(field, operator, value);
    // }
  }

  if (isApiRequest) {
    query += ")";
    query.replace("+", "%2B");
  }
  return query;
};

export const buildODataQuery = (
  parameters,
  isApiRequest = false,
  { pipelineSettings, disablePipeline, forcePipeline } = {}
) => {
  let query = "";
  let hasSetParameters = false;

  const setParameter = (p) => {
    if (hasSetParameters) query += `&${p}`;
    else {
      hasSetParameters = true;
      query += `?${p}`;
    }
  };
  // setParameter("$Includes=teste")
  let {
    count,
    $orderBy,
    filter,
    top,
    conversationFilter,
    skip,
    query: queryParam,
    advancedFilters,
    pipeline,
    clientId,
    isFull
  } = parameters;

  if (count) setParameter("$inlinecount=allpages");

  if ($orderBy) {
    const resolvedOrderBy = buildODataOrderBy($orderBy);
    if (resolvedOrderBy) setParameter(resolvedOrderBy);
  }

  //add pipeline to filters if valid
  if (
    (forcePipeline && pipeline) ||
    (!disablePipeline && pipeline && pipelineSettings)
  ) {
    const { field } = pipelineSettings || {};
    filter = [
      ...filter,
      {
        field: field || "Pipeline.Id",
        operator: "eq",
        value: pipeline,
        data: { type: "string" }
      }
    ];
  }

  if (filter && filter.length !== 0)
    setParameter(buildODataFilter(filter, undefined, isApiRequest));

  if (conversationFilter && conversationFilter.length !== 0)
    setParameter(buildODataConversationFilter(filter, undefined, isApiRequest));

  if (advancedFilters)
    setParameter(`customFilters=${advancedFilters.join(",")}`);

  if (skip) setParameter(`$skip=${skip}`);

  if (top) setParameter(`$top=${top}`);

  if (clientId) setParameter(`clientIdBySpace=${clientId}`);

  if (queryParam) setParameter(`query=${queryParam}`);

  if (isFull) setParameter(`isFull=1`);

  return query;
};

window.buildODataQuery = buildODataQuery;

// export const useOdataQuery = (params, disablePipeline) => {
// 	const pipelineSettings = usePipelineSettings()
// 	return useMemo(() => {
// 		const resolvedParams = {
// 			...defaultODataParameters,
// 			...params
// 		};

// 		return buildODataQuery(resolvedParams, true, {
// 			pipelineSettings,
// 			disablePipeline
// 		});
// 	}, [disablePipeline, params, pipelineSettings]);
// };
