import { getResolvedSchema, keyedSchemas } from "../../Helpers/SchemaHelper";

const handleCustomUpdate = ({
  schema,
  currentValue,
  newValue,
  isMainEntity,
  EntityUpdater
}) => {
  const resolvedSchema = getResolvedSchema(
    isMainEntity ? schema._schema : schema
  );
  const handleEntityUpdate =
    EntityUpdater || resolvedSchema?.handleEntityUpdate;

  // let resolvedValue;
  if (currentValue) {
    if (handleEntityUpdate) {
      // resolvedValue = handleEntityUpdate(currentValue, newValue);
      return handleEntityUpdate(currentValue, newValue);
    } else {
      return { ...currentValue, ...newValue };
    }
  } else {
    return newValue;
  }
};

const updateEntities = (
  entityName,
  entityState,
  entities,
  schema,
  EntityUpdater
) => {
  for (const entityI in entities) {
    if (entities.hasOwnProperty(entityI)) {
      const entity = entities[entityI];
      entityState[entityI] = handleCustomUpdate({
        schema,
        currentValue: entityState[entityI],
        newValue: entity,
        isMainEntity: false,
        EntityUpdater
      });
    }
  }
};

const entities = (state = {}, action) => {
  if (action.response && action.response.entities) {
    let newState = { ...state };
    const updaters = action.response.entityUpdater;
    for (const entityI in action.response.entities) {
      if (action.response.entities.hasOwnProperty(entityI)) {
        const entities = action.response.entities[entityI];
        if (!newState[entityI]) newState[entityI] = entities;
        else {
          let resolvedSchema = keyedSchemas[entityI];
          const EntityUpdater = updaters && updaters[entityI];
          updateEntities(
            entityI,
            newState[entityI],
            entities,
            resolvedSchema,
            EntityUpdater
          );
        }
      }
    }

    return newState;
  }

  if (action.type === UPDATE_EMAIl_CONVERSATION_NOTE_COUNT) {
    let newState = { ...state };
    const newConversation = {
      ...newState.spaceEmailConversation[action.payload]
    };
    newConversation.NotesCount = newConversation.NotesCount
      ? newConversation.NotesCount + 1
      : 1;
    newState.spaceEmailConversation[action.payload] = newConversation;
    return newState;
  }
  if (action.type === UPDATE_EMAIl_CONVERSATION_EMAIL_COUNT) {
    let newState = { ...state };
    const newConversation = {
      ...newState.spaceEmailConversation[action.payload]
    };
    newConversation.MessagesCount = newConversation.MessagesCount
      ? newConversation.MessagesCount + 1
      : 1;
    newState.spaceEmailConversation[action.payload] = newConversation;
    return newState;
  }

  return state;
};

export const UPDATE_EMAIl_CONVERSATION_NOTE_COUNT =
  "UPDATE_EMAIl_CONVERSATION_NOTE_COUNT";

export const UPDATE_EMAIl_CONVERSATION_EMAIL_COUNT =
  "UPDATE_EMAIl_CONVERSATION_EMAIL_COUNT";

export const NoteCountEmailConversation = (emailConversationId) => {
  return {
    type: UPDATE_EMAIl_CONVERSATION_NOTE_COUNT,
    payload: emailConversationId
  };
};

export const MessageCountEmailConversation = (emailConversationId) => {
  return {
    type: UPDATE_EMAIl_CONVERSATION_EMAIL_COUNT,
    payload: emailConversationId
  };
};

export default entities;
