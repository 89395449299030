import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import {
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd
} from "react-flow-renderer";
import { useHoverEvents } from "../../Origin/Origin";
import { ElementRemoveContext, ElementsRefContext } from "./AutomationDetails";
import styles from "./AutomationDetails.module.css";
import "./OverwriteDefaultStyles.css";

export default function BasicEdge({ ...props }) {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    // data = {},
    arrowHeadType,
    markerEndId,
    selected
  } = props;
  // const { sourceHandle } = data;
  const removeElements = useContext(ElementRemoveContext);
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const ElementsRef = useContext(ElementsRefContext);

  const onEdgeClick = (evt) => {
    const edgeIndex = ElementsRef.current.findIndex((e) => e.id === id);
    if (edgeIndex !== -1) {
      removeElements([ElementsRef.current[edgeIndex]]);
    }
  };

  const {
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    onPopupMouseEnter,
    onPopupMouseLeave,
    isOpen
  } = useHoverEvents();

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY
  });

  const foreignObjectSize = 31;

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <path
        style={{ zIndex: 312 }}
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
        className="react-flow__edge-fat-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {/* {sourceHandle && (
        <foreignObject
          width={40}
          height={21}
          x={sourceX - 20}
          y={sourceY + 6}
          className="edgebutton-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <div
            style={{ textTransform: "capitalize", borderRadius: 3 }}
            className={classnames(
              "text-black d-flex justify-content-center fw-medium fs-14 bg-white",
              styles["disable-selection"]
            )}
          >
            {sourceHandle}
          </div>
        </foreignObject>
      )} */}
      <foreignObject
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
        style={{ display: selected ? "" : isOpen ? "" : "none" }}
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <button
          className={styles.edgeButton}
          onClick={(event) => onEdgeClick(event, id)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </foreignObject>
    </>
  );
}
