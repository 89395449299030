import React from "react";
import { ConnectedTicketOrigin } from "../../Origin/TicketOrigin";
import { ConnectedDealOrigin } from "../../Origin/DealOrigin";
import { ConnectedTaskOrigin } from "../../Origin/TaskOrigin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import { FormattedMessage, useIntl } from "react-intl";
import { ReceptionTargetDropdown } from "../../AdvancedMultiInputs";
import { useCurrentAccountSpace } from "../../../Contexts/UserContext";
import { EntityPopup } from "../../../Components/EntityPopup/EntityPopup";
import { LabelTitle } from "../../../Helpers/GridHelper";
import { canUserEditModule, moduleTypes } from "../../../Helpers/ModulesHelper";
import {
  DealFormButton,
  TaskFormButton,
  TicketFormButton
} from "../../Form/FormButtons";

const AssociationTicketStatusItem = ({ Item }) => {
  return <ConnectedTicketOrigin placement="left" ticket={Item} />;
};

const AssociationDealStatusItem = ({ Item }) => {
  return <ConnectedDealOrigin placement="left" deal={Item} />;
};

const AssociationTaskStatusItem = ({ Item }) => {
  return <ConnectedTaskOrigin placement="left" task={Item} />;
};

export const AssociationsList = ({
  Tickets = [],
  Tasks = [],
  Deals = [],
  removeEntity,
  CanEdit,
  canAddEntities
}) => {
  const TicketsArr = Tickets.map((e, i) => {
    return (
      <div key={e} className="mb-3 d-flex align-items-center px-2">
        <div className="flex-1 d-flex align-items-center mr-3 of-hidden">
          <AssociationTicketStatusItem Item={e} />
        </div>
        {CanEdit && (
          <div
            className="cursor-pointer"
            onClick={() => {
              removeEntity(i, "case");
            }}
          >
            <FontAwesomeIcon className="text-danger-alt" icon={faTrashAlt} />
          </div>
        )}
      </div>
    );
  });

  const DealsArr = Deals.map((e, i) => {
    return (
      <div key={e + i} className="mb-3 d-flex align-items-center px-2">
        <div className="flex-1 d-flex align-items-center mr-3 of-hidden">
          <AssociationDealStatusItem Item={e} />
        </div>
        {CanEdit && (
          <div
            className="cursor-pointer"
            onClick={() => {
              removeEntity(i, "deal");
            }}
          >
            <FontAwesomeIcon className="text-danger-alt" icon={faTrashAlt} />
          </div>
        )}
      </div>
    );
  });
  const TasksArr = Tasks.map((e, i) => {
    return (
      <div key={e + i} className="mb-3 d-flex align-items-center px-2">
        <div className="flex-1 d-flex align-items-center mr-3 of-hidden">
          <AssociationTaskStatusItem Item={e} />
        </div>
        {CanEdit && (
          <div
            className="cursor-pointer"
            onClick={() => {
              removeEntity(i, "task");
            }}
          >
            <FontAwesomeIcon className="text-danger-alt" icon={faTrashAlt} />
          </div>
        )}
      </div>
    );
  });

  return [...TicketsArr, ...DealsArr, ...TasksArr];
};

export const ReceptionEmailAssociator = ({
  Tickets,
  Deals,
  Tasks,
  CanEdit,
  onChange
}) => {
  const intl = useIntl();

  const onAssociationChange = (e) => {
    const { value } = e.target;
    const { type, data } = value;
    switch (type) {
      case "Ticket":
        const newTickets = [...Tickets, data];
        onChange({
          Tickets: newTickets
        });
        break;
      case "Deal":
        const newDeals = [...Deals, data];

        onChange({
          Deals: newDeals
        });
        break;
      case "Task":
        const newTasks = [...Tasks, data];

        onChange({
          Tasks: newTasks
        });
        break;
      default:
        break;
    }
  };

  const RemoveAssosiation = (i, entity) => {
    switch (entity) {
      case "case":
        const newTickets = [...(Tickets || [])];
        newTickets.splice(i, 1);
        onChange({
          Tickets: newTickets
        });
        break;
      case "task":
        const newTasks = [...(Tasks || [])];
        newTasks.splice(i, 1);
        onChange({
          Tasks: newTasks
        });
        break;
      case "deal":
        const newDeals = [...(Deals || [])];
        newDeals.splice(i, 1);
        onChange({
          Deals: newDeals
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FormInput
        text={<FormattedMessage id="ASSOCIATED" />}
        className="mb-3"
        inputType={ReceptionTargetDropdown}
        placeholder={intl.formatMessage({
          id: "SEARCH_ASSOCIATION"
        })}
        allClients={false}
        onChange={onAssociationChange}
      />

      <AssociationsList
        removeEntity={RemoveAssosiation}
        Tickets={Tickets}
        CanEdit={CanEdit}
        Tasks={Tasks}
        Deals={Deals}
      />
    </>
  );
};

export const AssociatorActionsButton = ({ entity, minimal, handleChange }) => {
  // const isPositiveStatus =
  //   entity.Status !== ProjectStatusTypes.canceled &&
  //   entity.Status !== ProjectStatusTypes.finished;

  const handleCreation = (type, id) => {
    handleChange((v) => {
      const newVal = { ...v };

      switch (type) {
        case "Ticket":
          const newTickets = [...newVal.Tickets, id];
          newVal.Tickets = newTickets;
          break;
        case "Deal":
          const newDeals = [...newVal.Deals, id];
          newVal.Deals = newDeals;
          break;
        case "Task":
          const newTasks = [...newVal.Tasks, id];
          newVal.Tasks = newTasks;
          break;
        default:
          break;
      }

      return newVal;
    });
  };

  const AccountSpace = useCurrentAccountSpace();
  return (
    <EntityPopup minimal={minimal}>
      <LabelTitle title="Serviços" />
      {canUserEditModule(AccountSpace, moduleTypes.tasks) && (
        <TaskFormButton
          // hasAccess={isPositiveStatus}
          formProps={{
            emailConversationId: entity.Id,
            onSuccess: (id) => handleCreation("Task", id)
          }}
        />
      )}
      {canUserEditModule(AccountSpace, moduleTypes.tickets) && (
        <TicketFormButton
          // hasAccess={isPositiveStatus}
          formProps={{
            emailConversationId: entity.Id,
            onSuccess: (id) => handleCreation("Ticket", id)
          }}
        />
      )}
      {canUserEditModule(AccountSpace, moduleTypes.interventions) && (
        <DealFormButton
          // hasAccess={isPositiveStatus}
          formProps={{
            emailConversationId: entity.Id,
            onSuccess: (id) => handleCreation("Deal", id)
          }}
        />
      )}
    </EntityPopup>
  );
};
