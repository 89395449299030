import React from "react";
import { DashboardTitle } from "../Dashboard";
import {
  TimelineTypeEnum
} from "../../../Components/DetailsView/History/History";
import { FormattedMessage } from "react-intl";
import { useDashboardKey } from "../DashboardQueryHelper";
import { ReportHistory } from "../../../Components/DetailsView/History/ReportHistory";

const TasksHistory = () => {
  const key = useDashboardKey();
  return (
    <>
      <DashboardTitle
        className="mb-0 px-4 fs-20"
        text={<FormattedMessage id={"TASK_HISTORY"} />}
      />
      <ReportHistory
        type={TimelineTypeEnum.Task}
        reportsType={"Tasks"}
        key={key}
        noFilter
        onMouseDown={(e) => e.stopPropagation()}
        disableTabs
        tasks
      />
    </>
  );
};

export default TasksHistory;
