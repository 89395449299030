import React, { useMemo } from "react";
import {
  createFormRequestHook,
  useFormState
} from "../../../../Components/Forms";
import { spaceUserCategoriesSchema } from "../../../../config/schema";
import LoaderSpinner from "../../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useUserCategory } from "../../../../Hooks/EntityHooks";
import { useIntl, FormattedMessage } from "react-intl";
import { useToast } from "../../../../Components/Toast/ToastProvider";
import { useHandleError } from "../../../../Components/CGrid/ServerAwareHelper";
import {
  useSidebarSpacePost,
  useSidebarSpaceDelete,
  BaseSidebarHeader
} from "../../../../Components/Sidebar/SidebarV2Helper";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../../../Components/Forms/SideMenuForm";
import FormInput from "../../../../Components/Forms/FormInput/FormInput";

const validateForm = ({ Name }, addError) => {
  if (!Name || Name.length === 0) addError("Name");
};

const CategoryFormBase = ({
  convertedForm,
  canDelete,
  onSuccess,
  id,
  canEdit,
  refetchRef,
  className
}) => {
  const formState = useFormState(() => {
    return { ...convertedForm };
  });

  const { form, setForm } = formState;

  const intl = useIntl();
  const createToast = useToast();
  const handleTranslatedError = useHandleError(spaceUserCategoriesSchema);

  const [post, { loading }] = useSidebarSpacePost(
    id ? `Settings/Users/Category/${id}` : "Settings/Users/Category",
    spaceUserCategoriesSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess(newId);

        createToast({
          pos: "tm",
          type: "success",
          description: `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`,
          title: `${intl.formatMessage({ id: "CATEGORY" })} nº ${newId || id}`
        });

        // if (id) return;
      },
      refetchRef,
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `Settings/Users/Category/${id}`,
    spaceUserCategoriesSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "CATEGORY" })} nº ${id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const handleSubmit = (form) => {
    post(form);
  };

  return (
    <SideForm
      className="bg-white"
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      <BaseSidebarHeader>
        <FormattedMessage id={"CATEGORY"} />
      </BaseSidebarHeader>
      <SideFormContent style={{ width: 520 }} className={"px-3 pt-3 pb-5"}>
        <div className="mb-3 text-black fs-16 fw-medium">
          <FormattedMessage id={"DETAILS"} />
        </div>
        <FormInput className="mb-3" textId="NAME" name="Name" />
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        form={form}
        handleChange={setForm}
        deleteLoading={deleteLoading}
        handleDelete={remove}
        isLoading={loading}
      />
    </SideForm>
  );
};

const defaultForm = {
  Name: ""
};

const convertCategoryToForm = (entity, props) => {
  if (!entity) {
    return {};
  }

  return { ...entity };
};

const useCategoryRequest = createFormRequestHook({
  schema: spaceUserCategoriesSchema,
  convertEntityToForm: convertCategoryToForm,
  formatDuplicationEntity: (entity) => {
    return { ...entity, Id: undefined };
  }
});

const CategoryForm = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { key, loading, error, convertedForm, isChild, baseForm, childProps } =
    useCategoryRequest(props);
  const { id, onSuccess, className, ...rest } = props;
  const resolvedForm = useMemo(() => {
    const form = {
      ...defaultForm,
      ...convertedForm,
      ...baseForm
    };
    return form;
  }, [baseForm, convertedForm]);

  const category = useUserCategory(id);

  if (loading || error)
    return <LoaderSpinner center size="sm" className="text-secondary" />;

  const canEdit = category ? category.CanEdit : true;

  return (
    <CategoryFormBase
      {...rest}
      id={id}
      canDelete={category?.CanDelete}
      className={className}
      onSuccess={onSuccess}
      canEdit={canEdit}
      convertedForm={resolvedForm}
    ></CategoryFormBase>
  );
};

export default CategoryForm;
