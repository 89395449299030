import { useEffect } from "react";
export class Communicator {
  subscriptions = [];
  subscribe = listener => {
    const subscriptions = this.subscriptions;
    subscriptions.push(listener);
    function unsubscribe() {
      const index = subscriptions.indexOf(listener);
      subscriptions.splice(index, 1);
    }
    return unsubscribe;
  };

  dispatch = (...args) => {
    for (const subscription of this.subscriptions) {
      subscription(...args);
    }
  };
}

export const createCommunicatorHook = (communicator = new Communicator()) => {
  const useCommunicator = callback => {
    useEffect(() => {
      if (!callback) return;
      const unsubscribe = communicator.subscribe(callback);
      return unsubscribe;
    }, [callback]);

    return communicator.dispatch;
  };

  return useCommunicator;
};
