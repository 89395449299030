import React, { useState, useEffect } from "react";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import { CodeMirrorComponent } from "../../Containers/CodeMirrorReact/CodeMirrorReact";

const DumbFilesPreviewContent = ({ src }) => {
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");

  async function readCSVFile(url) {
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], data.name, {
      type: data.type
    });

    // FileReader Object
    var reader = new FileReader();

    // Load event
    reader.onload = function (event) {
      // Read file data
      var csvdata = event.target.result;
      setText(csvdata);
      setLoading(false);
    };

    // Read file as string
    reader.readAsText(file);
  }

  useEffect(() => {
    readCSVFile(src);
  }, [src]);

  return (
    <div className="h-100 of-auto w-100">
      {loading && (
        <div className="d-flex justify-content-center align-items-center h-100 w-100">
          <LoaderSpinner size="lg" className="m-4 text-primary" />
        </div>
      )}
      {!loading && (
        <CodeMirrorComponent
          height={"100%"}
          className="h-100"
          readOnly={true}
          onChange={() => {}}
          value={text}
        />
      )}
    </div>
  );
};

const DumbFilesPreview = ({ src }) => {
  return <DumbFilesPreviewContent src={src} />;
};

export default DumbFilesPreview;
