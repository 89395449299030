import React, { useEffect, useState, useMemo } from "react";
import { useSpaceQuery } from "../../Helpers/IOClient";
import Input from "../Input/Input";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import "./AccountSelector.css";
import { accountSchema } from "../../config/schema";
import Checkbox from "../Checkbox/Checkbox";
import { ConnectedAccountProfile } from "../Accounts/AccountProfile";

const useTimedSpaceQuery = (url, ...args) => {
  const [resolvedUrl, setResolvedUrl] = useState(url);

  useEffect(() => {
    if (!url || resolvedUrl === url) return;

    const timeout = setTimeout(() => {
      setResolvedUrl(url);
    }, 300);

    return () => clearTimeout(timeout);
  }, [url, resolvedUrl]);

  // const {loading, data, error} = useSpaceQuery("query/value");

  return useSpaceQuery(resolvedUrl, ...args);
};

const SelectorCheckbox = ({ account, onChange, checked }) => {
  // const { Name } = useAccount(account);

  return (
    <Checkbox
      className="d-flex mb-1 align-items-center"
      checked={checked}
      onChange={() => onChange(account)}
    >
      <ConnectedAccountProfile
        inline
        className="d-inline-flex"
        account={account}
      />
    </Checkbox>
  );
};

const SelectedAccounts = ({ accounts, onChange, mappedAccounts }) => {
  if (!accounts || accounts.length === 0) return null;

  return (
    <div className="">
      {accounts.map((v) => {
        return (
          <SelectorCheckbox
            key={v}
            account={v}
            onChange={onChange}
            checked={mappedAccounts[v] || false}
          />
        );
      })}
      <hr />
    </div>
  );
};

const Selector = ({ query, value, onChange, selectedAccounts }) => {
  const { loading, data, error } = query;

  const mappedAccounts = useMemo(() => {
    const dict = {};
    for (const account of value) {
      dict[account] = true;
    }
    return dict;
  }, [value]);

  const selectedMappedAccounts = useMemo(() => {
    const dict = {};
    for (const account of selectedAccounts) {
      dict[account] = true;
    }
    return dict;
  }, [selectedAccounts]);

  const toggleAccount = (userId) => {
    const isSelected = mappedAccounts[userId];

    if (!isSelected) {
      const newValue = [...value, userId];
      onChange(newValue);
    } else if (value) {
      const index = value.indexOf(userId);
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange(newValue);
    }
  };

  if (loading)
    return <LoaderSpinner center className="text-secondary" size="sm" />;

  if (error) return <div>error</div>;

  return (
    <div className="d-flex flex-column LowerHeightOnDropdownItems">
      <SelectedAccounts
        accounts={selectedAccounts}
        mappedAccounts={mappedAccounts}
        onChange={toggleAccount}
      />
      {data.map((v) => {
        if (selectedMappedAccounts[v]) return null;
        return (
          <SelectorCheckbox
            key={v}
            account={v}
            onChange={toggleAccount}
            checked={mappedAccounts[v] || false}
          />
        );
      })}
    </div>
  );
};

const AccountSelector = (props) => {
  const [query, setQuery] = useState("");

  const url = query ? `query/accounts?query=${query}` : "query/accounts";

  const queryS = useTimedSpaceQuery(url, [accountSchema]);

  return (
    <div>
      <Input
        name="search-text"
        autoComplete="off"
        placeholder={"Pesquisar utilizadores"}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <div className="ar-account-selector mt-4">
        <Selector query={queryS} {...props} />
      </div>
    </div>
  );
};

export default AccountSelector;
