import React, { useRef, useMemo, useContext } from "react";
import Popup, { usePopupOpenState } from "../Popup/Popup";
import Button from "../Button/Button";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faEnvelopeOpen,
  faEnvelope,
  faTrashAlt,
  faSquare
} from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage } from "react-intl";
import {
  ActionLoadableButton,
  ActionPopupTogglerContext
} from "../ExtraActionsButton/ExtraActionButton";
import {
  useSpacePost,
  updateNormalizedSchemaCache,
  getEntity
} from "../../Helpers/IOClient";

import {
  spaceCallsSchema,
  spaceProjectsSchema,
  spaceDealsSchema,
  spaceTicketsSchema,
  spaceInterventionsSchema,
  spaceClientSchema,
  spaceSubscriptionSchema,
  spaceTasksSchema,
  spaceEmailConversationSchema,
  spaceContractSchema
} from "../../config/schema";
import { useUnreadEntitiesFetcher } from "../UnreadEntities/UnreadEntities";
import { InboxTypeEnum } from "../../Containers/Reception/ReceptionHelpers";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { useOpenDeleteModal } from "../CGrid/DeleteMultipleModal";
export const SelectedArrMsgContext = React.createContext();

export const getInboxTypeSchema = (type) => {
  switch (type) {
    case InboxTypeEnum.Email:
      return { schema: spaceEmailConversationSchema, isService: false };
    case InboxTypeEnum.Call:
      return { schema: spaceCallsSchema, isService: true };
    case InboxTypeEnum.Project:
      return { schema: spaceProjectsSchema, isService: true };
    case InboxTypeEnum.Ticket:
      return { schema: spaceTicketsSchema, isService: true };
    case InboxTypeEnum.Task:
      return { schema: spaceTasksSchema, isService: true };
    case InboxTypeEnum.Time:
      return { schema: spaceInterventionsSchema, isService: true };
    case InboxTypeEnum.Deal:
      return { schema: spaceDealsSchema, isService: true };
    case InboxTypeEnum.Contract:
      return { schema: spaceContractSchema, isService: true };
    case InboxTypeEnum.Client:
      return { schema: spaceClientSchema, isService: true };
    case InboxTypeEnum.Subscription:
      return { schema: spaceSubscriptionSchema, isService: true };
    default:
      return null;
  }
};

export const ObjNameDictionary = {
  1: "EmailConversationIds",
  4: "ClientIds",
  5: "DealIds",
  6: "ContractIds",
  7: "SubscriptionIds",
  8: "CallIds",
  9: "ProjectIds",
  10: "TicketIds",
  11: "TaskIds",
  12: "TimeChargeIds"
};

const FormatPost = (SelectedArr) => {
  let Results = {
    EmailConversationIds: [],
    ClientIds: [],
    DealIds: [],
    ContractIds: [],
    SubscriptionIds: [],
    CallIds: [],
    ProjectIds: [],
    TicketIds: [],
    TaskIds: [],
    TimeChargeIds: []
  };

  for (const e of SelectedArr) {
    const Name = ObjNameDictionary[e.type];
    Results[Name] = [...Results[Name], e.id];
  }
  return Results;
};

export const UpdateAllItems = (list, markRead = true, Refetch, IsService) => {
  const updates = {};

  for (const item of list) {
    let settings;
    const { type, id } = item;
    settings = getInboxTypeSchema(type);
    if (settings) {
      const { schema, isService: typeService } = settings;

      if (!updates[schema.name]) updates[schema.name] = {};

      const entity = getEntity(schema, id);

      if (entity.IsFollowing) {
        if (IsService || typeService) {
          if (markRead) {
            if (!entity.IsRead) {
              updates[schema.name][id] = { IsRead: true };
            }
          } else {
            if (entity.IsRead) {
              updates[schema.name][id] = { IsRead: false };
            }
          }
        } else {
          if (markRead) {
            if (!entity.IsMessageRead) {
              updates[schema.name][id] = { IsMessageRead: true };
            }
          } else {
            if (entity.IsMessageRead) {
              updates[schema.name][id] = { IsMessageRead: false };
            }
          }
        }
      }
    }
  }

  updateNormalizedSchemaCache(updates);
  Refetch();
  // updateNormalizedSchemaCache({
  //   [spaceInterventionsSchema.name]: interventions,
  //   [spaceBillingSchema.name]: { [billingId]: newBilling }
  // });
};

const UnreadOptionsButton = ({
  Text,
  IsService = false,
  disableReadOptions = false,
  EnableDelteAll,
  children,
  DisableOnlySetButtons,
  handleDelete,
  ...props
}) => {
  const Refetch = useUnreadEntitiesFetcher();
  const anchorRef = useRef();
  const popupRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  const SelectedArr = useContext(SelectedArrMsgContext);
  const ButtonContent = useMemo(() => {
    if (Text) {
      return (
        <div className="d-flex justify-content-between align-items-center">
          <FormattedMessage id={"ACTIONS"} />
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      );
    } else {
      return <FontAwesomeIcon size="2x" icon={faEllipsisV} />;
    }
  }, [Text]);

  const [readPost, { loading: loadingRead }] = useSpacePost(
    `EntityFollow/Read`,
    null,
    {
      onSuccess: ({ data }) => {
        UpdateAllItems(SelectedArr, true, Refetch, IsService);
      },
      onError: ({ error }) => {
        console.log(error);
      }
    }
  );
  //   updateNormalizedSchemaCache;

  const ButtonDisabledStatus = useMemo(() => {
    if (DisableOnlySetButtons) return false;
    return SelectedArr.length === 0;
  }, [DisableOnlySetButtons, SelectedArr.length]);

  const [unreadPost, { loading: loadingUnread }] = useSpacePost(
    `EntityFollow/Unread`,
    null,
    {
      onSuccess: ({ data }) => {
        UpdateAllItems(SelectedArr, false, Refetch, IsService);
      },
      onError: ({ error }) => {
        console.log(error);
      }
    }
  );

  const handleReadOption = () => {
    const ResolvedData = FormatPost(SelectedArr);
    readPost(ResolvedData);
  };

  const handleUnreadOption = () => {
    const ResolvedData = FormatPost(SelectedArr);
    unreadPost(ResolvedData);
  };

  // const [deleteModalStatus, setDeleteModalStatus] = useState(false);

  // const closeDeleteModal = () => {
  //   setDeleteModalStatus(false);
  // };

  const openDeleteModal = useOpenDeleteModal();

  const handleDeleteOpenModal = () => {
    openDeleteModal();
  };

  return (
    <ActionPopupTogglerContext.Provider value={toggleIsOpen}>
      {/* <Modal
        className="overflow-hidden d-flex"
        isOpen={deleteModalStatus}
        onClose={closeDeleteModal}
      >
        <MultipleDeleteModalContent closeModal={closeDeleteModal} />
      </Modal> */}
      <Button
        disabled={ButtonDisabledStatus}
        ref={anchorRef}
        className={classnames({
          "d-flex justify-content-center align-items-center mw-0 px-3": !Text,
          "ar-actions-button px-2": Text
        })}
        vType={"primary-ghost"}
        onClick={(e) => toggleIsOpen(true)}
      >
        {ButtonContent}
      </Button>
      <Popup
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        domRef={popupRef}
        anchorEl={anchorRef.current}
        isOpen={isOpen}
        placement="bottom-start"
      >
        <div
          style={{ width: 320, padding: "0 8px" }}
          className="py-2 bg-white rounded"
        >
          <div className="label-title">
            <FormattedMessage id="ACTIONS" />
          </div>
          {/* <ActionLoadableButton
            disabled={SelectedArr.length === 0}
            isLoading={loadingRead}
            className="p-1 AlignLeftLoadableButton"
            vType="link"
            onClick={()=>{}}
          >
            <FontAwesomeIcon icon={faCheck} />
            <span className="ml-1">
              <FormattedMessage id={"SELECT_ALL"} />
            </span>
          </ActionLoadableButton> */}
          {/* <ActionLoadableButton
            disabled={SelectedArr.length === 0}
            isLoading={loadingRead}
            className="p-1 AlignLeftLoadableButton"
            vType="link"
            onClick={() => {
              Refetch();
            }}
          >
            <FontAwesomeIcon icon={faSquare} />
            <span className="ml-1">
              <FormattedMessage id={"CLEAN_ALL"} />
            </span>
          </ActionLoadableButton> */}
          {!disableReadOptions && (
            <>
              <ActionLoadableButton
                disabled={SelectedArr.length === 0}
                isLoading={loadingRead}
                className="p-1 AlignLeftLoadableButton"
                vType="link"
                onClick={handleReadOption}
              >
                <FontAwesomeIcon icon={faEnvelopeOpen} />
                <span className="ml-1">
                  <FormattedMessage id={"MARK_AS_READ"} />
                </span>
              </ActionLoadableButton>
              <ActionLoadableButton
                disabled={SelectedArr.length === 0}
                isLoading={loadingUnread}
                className="p-1 AlignLeftLoadableButton"
                vType="link"
                onClick={handleUnreadOption}
              >
                <FontAwesomeIcon icon={faEnvelope} />
                <span className="ml-1">
                  <FormattedMessage id={"MARK_AS_UNREAD"} />
                </span>
              </ActionLoadableButton>
            </>
          )}
          {children}
          {handleDelete && (
            <ActionLoadableButton
              disabled={SelectedArr.length === 0}
              isLoading={false}
              className="p-1 AlignLeftLoadableButton"
              vType="link-warning"
              onClick={handleDeleteOpenModal}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
              <span className="ml-1">
                <FormattedMessage id={"DELETE_SELECTED"} />
              </span>
            </ActionLoadableButton>
          )}
        </div>
      </Popup>
    </ActionPopupTogglerContext.Provider>
  );
};

// handleDelete && (
//   <div className="h-100 Logout-button ">
//     <div
//       disabled={SelectedArr.length === 0}
//       className="ar-logout-button d-flex align-items-center w-100 h-100 pb-2 pt-2 cursor-pointer"
//       onClick={handleDeleteOpenModal}
//     >
//       <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faTrashAlt} />
//       <span style={{ marginLeft: 9 }}>
//         <FormattedMessage id={"DELETE_SELECTED"} />
//       </span>
//     </div>
//   </div>
// <ActionLoadableButton
//   disabled={SelectedArr.length === 0}
//   isLoading={loadingUnread}
//   className="p-1 AlignLeftLoadableButton"
//   vType="link-danger"
//   onClick={() => {
//     setDeleteModalStatus(true);
//   }}
// >
//   <FontAwesomeIcon icon={faTrashAlt} />
//   <span className="ml-1">
//     <FormattedMessage id={"DELETE_ALL"} />
//   </span>
// </ActionLoadableButton>
// )

export default UnreadOptionsButton;
