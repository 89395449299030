import React, { useCallback, useState } from "react";
import { spaceExportsSchema } from "../../../config/schema";
import {
  PageContainer,
  PageContent
} from "../../../Components/PageUtilities/PageUtilities";
import {
  ServerAwareProvider,
  GridRequestProvider
} from "../../../Components/CGrid/ServerGrid";

import ExportsGrid from "./ExportsGrid";
import PageHeader from "../../../Components/Headers/PageHeader";
import { FormattedMessage } from "react-intl";

const Exports = (props) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemSelection = useCallback((items) => {
    setSelectedItems(items);
  }, []);

  return (
    <PageContainer>
      <ServerAwareProvider schema={spaceExportsSchema}>
        <PageHeader title={<FormattedMessage id="EXPORTS" />}></PageHeader>
        <PageContent>
          <GridRequestProvider>
            <ExportsGrid
              className="flex-1"
              onItemSelect={handleItemSelection}
              selectedItems={selectedItems}
            />
          </GridRequestProvider>
        </PageContent>
      </ServerAwareProvider>
    </PageContainer>
  );
};

export default Exports;
