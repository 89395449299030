import { success, request, error, areEqualShallow } from "./MiscHelper";
import api from "../api";
import { normalize } from "./SchemaHelper";
import queryString from "query-string";
import { connect } from "react-redux";

export const getActionName = (name) => {
  let resolvedName = "";
  const splitName = name.split(/(?=[A-Z])/);

  for (const namePart of splitName) {
    if (resolvedName) resolvedName += "_";
    resolvedName += namePart.toUpperCase();
  }

  return resolvedName;
};

export const createSpaceAwareReducer = (reducer) => (state = {}, action) => {
  const spaceId = action.spaceId;

  if (!spaceId) return state;

  const currentSpaceState = state[spaceId];

  const newSpaceState = reducer(currentSpaceState, action);

  if (newSpaceState !== undefined && newSpaceState !== currentSpaceState)
    return {
      ...state,
      [spaceId]: newSpaceState,
    };
  else return state;
};

export const createMainSpaceReducer = (requestName) =>
  createSpaceAwareReducer((state, action) => {
    switch (action.type) {
      case `${requestName}_REQUEST`:
        return {
          ...state,
          requestSource: action.requestSource,
          params: action.params,
          isFetching: true,
        };

      case `${requestName}_SUCCESS`:
        return {
          ...state,
          isFetching: false,
          requestSource: null,
          value: action.response.result,
        };

      case `${requestName}_ERROR`:
        return {
          ...state,
          isFetching: false,
          error: action.error,
        };

      default:
        return state;
    }
  });

export const createSidebarFormReducer = (requestName) => (
  state = {
    isProcessing: false,
    isUpdating: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case request(requestName):
      return {
        ...state,
        isProcessing: true,
        isUpdate: action.isUpdate,
        error: null,
      };

    case success(requestName):
      return {
        ...state,
        isProcessing: false,
        error: null,
      };

    case error(requestName):
      return {
        ...state,
        isProcessing: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const createSearchReducer = (requestName, { getResult } = {}) =>
  createSpaceAwareReducer((state, action) => {
    const updateResults = () => {
      if (state && state.results)
        return {
          ...state.results,
          [action.query]: getResult
            ? getResult(action)
            : action.response.result,
        };
      else
        return {
          [action.query]: getResult
            ? getResult(action)
            : action.response.result,
        };
    };

    switch (action.type) {
      case request(requestName):
        return {
          ...state,
          isFetching: true,
          query: action.query,
          error: null,
          results: action.disableCache ? null : state && state.results,
          cancelSource: action.cancelSource,
        };

      case success(requestName):
        let test = {
          ...state,
          isFetching: false,
          error: null,
          results: updateResults(),
          cancelSource: null,
        };

        return test;
      case error(requestName):
        return {
          ...state,
          isFetching: false,
          error: action.error,
          cancelSource: null,
        };

      case `${requestName}_UPDATE`:
        return {
          ...state,
          isFetching: false,
          error: null,
          query: action.query,
          cancelSource: null,
        };

      default:
        return state;
    }
  });

export const createEntityDetailReducer = (requestName) => (
  state = {},
  action
) => {
  switch (action.type) {
    case `${requestName}_REQUEST`:
      return {
        ...state,
        [action.entityId]: {
          ...[action.entityId],
          isFetching: true,
        },
      };

    case `${requestName}_SUCCESS`:
      return {
        ...state,
        [action.entityId]: {
          ...[action.entityId],
          isFetching: false,
        },
      };

    case `${requestName}_ERROR`:
      return {
        ...state,
        [action.entityId]: {
          ...[action.entityId],
          isFetching: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export const createBasicGetAction = ({
  requestName,
  endpoint,
  schema,
  dispatchBody,
}) => (dispatch, getState) => {
  dispatch({
    ...dispatchBody,
    type: request(requestName),
  });

  api
    .get(endpoint)
    .then((response) => {
      let normalizedData = schema ? normalize(response, schema) : response;

      dispatch({
        ...dispatchBody,
        type: success(requestName),
        response: normalizedData,
      });
    })
    .catch((e) => {
      if (api.isCancel(e)) return;

      dispatch({ ...dispatchBody, type: error(requestName), error: e });
    });
};

export const createBasicPostAction = ({
  requestName,
  endpoint,
  schema,
  body,
  onSuccess,
  dispatchBody,
  getSuccessBody,
  headers: optionalHeaders,
}) => (dispatch, getState) => {
  dispatch({
    ...dispatchBody,
    type: request(requestName),
  });

  api
    .post(endpoint, body, {
      headers: {
        ...optionalHeaders,
        "Content-Type":
          body instanceof FormData ? "multipart/form-data" : "application/json",
      },
    })
    .then((response) => {
      let normalizedData = schema ? normalize(response, schema) : response;

      const successBody = getSuccessBody
        ? getSuccessBody(normalizedData)
        : undefined;
      dispatch({
        ...dispatchBody,
        type: success(requestName),
        response: normalizedData,
        ...successBody,
      });

      if (typeof onSuccess === "function") onSuccess(dispatch, getState);
    })
    .catch((e) => {
      if (api.isCancel(e)) return;
      dispatch({ ...dispatchBody, type: error(requestName), error: e });
    });
};

// export const createMainSpaceRequest = ({
//   requestName,
//   getPageFromState,
//   getEndpoint,
//   schema,
//   dispatchBody,
//   getSchema
// }) => (spaceId, params) => (dispatch, getState) => {
//   const source = api.CancelToken.source();

//   const currentPageState = getPageFromState(spaceId, getState());

//   if (currentPageState && currentPageState.requestSource) {
//     currentPageState.requestSource.cancel();
//   }

//   if (!params && currentPageState) params = currentPageState.params;

//   dispatch({
//     ...dispatchBody,
//     type: `${requestName}_REQUEST`,
//     spaceId,
//     params: params,
//     requestSource: source
//   });

//   let resolvedSchema;

//   if (typeof getSchema === "function") resolvedSchema = getSchema(spaceId);
//   else resolvedSchema = schema;

//   getOData(getEndpoint(spaceId), params, {
//     cancelToken: source.token
//   })
//     .then(response => {
//       let normalizedData = normalize(response.results, resolvedSchema);
//       dispatch({
//         ...dispatchBody,
//         type: `${requestName}_SUCCESS`,
//         spaceId,
//         response: {
//           ...normalizedData,
//           result: {
//             ...response,
//             results: normalizedData.result
//           }
//         }
//       });
//     })
//     .catch(error => {
//       if (api.isCancel(error)) return;
//       dispatch({
//         ...dispatchBody,
//         type: `${requestName}_ERROR`,
//         spaceId,
//         error
//       });
//       throw error;
//     });
// };

export const createSpaceSearchAction = ({
  requestName,
  getSearchFromState,
  getEndpoint,
  schema,
}) => {
  return {
    getSearchState: (state, spaceId) => {
      return getSearchFromState(state)[spaceId];
    },
    search: (query, spaceId, { urlParams, disableCache } = {}) => (
      dispatch,
      getState
    ) => {
      const searchState = getSearchFromState(getState())[spaceId];
      if (searchState && searchState.cancelSource) {
        searchState.cancelSource.cancel();
      }

      if (!disableCache) {
        if (searchState && searchState.results && searchState.results[query]) {
          dispatch({
            type: `${requestName}_UPDATE`,
            spaceId,
            query,
          });
          return;
        }
      }

      const source = api.CancelToken.source();

      let formatedEndpoint = getEndpoint(spaceId);

      const baseParams = {
        ...urlParams,
        $top: 15,
      };

      if (query) baseParams.query = query;

      const resolvedParams = queryString.stringify(baseParams);

      formatedEndpoint += `?${resolvedParams}`;

      dispatch({
        type: `${requestName}_REQUEST`,
        spaceId,
        disableCache,
        query,
        cancelSource: source,
      });

      api
        .get(formatedEndpoint, {
          cancelToken: source.token,
        })
        .then((response) => {
          window.state = getState();
          let normalizedData = normalize(response, schema);
          dispatch({
            type: `${requestName}_SUCCESS`,
            spaceId,
            query,
            response: normalizedData,
          });
        })
        .catch((error) => {
          if (api.isCancel(error)) return;
          console.error(error);
          dispatch({ type: `${requestName}_ERROR`, spaceId, error });
        });
    },
  };
};

export const getEntityFromState = (state, schema, key) => {
  const entities = state.Entities[schema.name];
  if (!entities) return undefined;
  return entities[key];
};

export const connectShallowEntity = (propName, mapStateToProps) => {
  const resolvedMapStateToProps = (state, props) => {
    return {
      [propName]: mapStateToProps(state, props),
    };
  };

  const options = {
    areStatePropsEqual: (next, prev) => {
      return areEqualShallow(prev[propName], next[propName]);
    },
  };

  return connect(resolvedMapStateToProps, null, null, options);
};
