import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useSocialAccount } from "../../Hooks/EntityHooks";
import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faLink, faTimes } from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import "emoji-mart/css/emoji-mart.css";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";
import TextEllipsis from "../TextEllipsis/TextEllipsis";
import { SocialAccountComponentForSocialPostGrid } from "../../Containers/SocialPosts/SocialPostsHelper";
import { AccountProfileImg } from "../Img/Img";
import moment from "moment";
import { SocialAccountDropdown } from "../../Containers/AdvancedMultiInputs";
import { SocialPostsFileInput } from "../SocialPostsFileInput/SocialPostsFileInput";
import { SocialPostsDescription } from "../SocialPostsDescription/SocialPostsDescription";
import ArDatePickerWithSocialPresetSchedules from "../DatePicker/ArDatePickerWithSocialPresetSchedules";
import Checkbox from "../Checkbox/Checkbox";
import PreviewTweet, { ImageAlbumTwitter } from "./TweetPreview/Tweet";
import { spaceSocialAccountSchema } from "../../config/schema";
import { SocialNetworkDict_Reverse } from "../../Containers/SocialPosts/SocialPostsForm";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import equal from "react-fast-compare";

export const SocialNetworkDict = {
  1: "Facebook",
  2: "Facebook",
  3: "Twitter",
  4: "LinkedIn",
  5: "LinkedIn",
  6: "Instagram",
  7: "Facebook"
};

const regex = /https?:\/\/[-A-Za-z0-9+&@#/%?=~_|$!:,.;]*/g;

const MultipleSocialPostPreview = ({
  value,
  isPreviewing,
  onLinkDataChange,
  onPreviewForTwitterMetadataSaveData
}) => {
  const Previews = value.SocialId.map((e, i, arr) => {
    return (
      <div key={i} className={classnames({ "mb-3": i + 1 < arr.length })}>
        <SingleSocialPostPreview
          value={{ ...value, SocialId: [e] }}
          isPreviewing={isPreviewing}
          index={i}
          onPreviewForTwitterMetadataSaveData={
            onPreviewForTwitterMetadataSaveData
          }
          onLinkDataChange={onLinkDataChange}
        />
      </div>
    );
  });
  return <div className="d-flex flex-column of-y-auto">{Previews}</div>;
};

const useMetadataPreviewLink = (text, Type, Thumbnail) => {
  const [links, setLinks] = useState(() => {
    if (Thumbnail && Thumbnail.MetadataThumbnail) {
      return [Thumbnail.MetadataUrl];
    } else return [];
  });
  const [previewLinks, setPreviewLinks] = useState(() => {
    if (Thumbnail && Thumbnail.MetadataThumbnail) {
      return [Thumbnail.MetadataUrl];
    } else return [];
  });
  useEffect(() => {
    if (Type) {
      const regexResult = text.match(regex) || [];
      if (links.length > 0 && regexResult.length === 0) {
        return;
      }
      if (!equal(links, regexResult)) {
        setLinks(regexResult);
        setPreviewLinks(regexResult);
      }
    }
  }, [Type, links, text]);

  const handleRemoveMetadataInfo = () => {
    setPreviewLinks((e) => {
      if (e.length > 0) {
        const newArr = [...e];
        newArr.splice(0, 1);

        return newArr;
      } else {
        return e;
      }
    });
  };

  const FirstLink = previewLinks[0];

  return [FirstLink, handleRemoveMetadataInfo, previewLinks];
};

const CheckIfItDosentExists = (Array = [], element) => {
  return Array.every((e) => {
    return e.MetadataUrl !== element;
  });
};

const SingleSocialPostPreview = ({
  value,
  isPreviewing,
  index = 0,
  onPreviewForTwitterMetadataSaveData,
  onLinkDataChange
}) => {
  const unresolvedSocialAccount = useSocialAccount(value.SocialId);

  const SocialAccount = useMemo(() => {
    if (unresolvedSocialAccount) return unresolvedSocialAccount;
    else return {};
  }, [unresolvedSocialAccount]);

  const { Type, Name, Tag, Avatar } = SocialAccount;
  const { Thumbnail, MetadataChangesData, FilesUnsorted } = value;
  const Files = useMemo(() => {
    if (FilesUnsorted) return FilesUnsorted;
    else return [];
  }, [FilesUnsorted]);

  const [unresolvedLink, handleRemoveMetadataInfo, links] =
    useMetadataPreviewLink(value.Text, Type, Thumbnail);

  const [firstLink, setFirstLink] = useState();

  useEffect(() => {
    const link = unresolvedLink;

    const resolvedLink = link
      ? window.encodeURI(`Social/Post/Link?type=${Type}&url=${link}`)
      : null;
    let timeout = undefined;

    timeout =
      resolvedLink !== firstLink
        ? setTimeout(() => {
            setFirstLink(resolvedLink);
          }, 500)
        : undefined;

    return () => {
      clearTimeout(timeout);
    };
  }, [Type, firstLink, unresolvedLink]);

  const { data } = useSpaceQuery(firstLink);
  const metadata = firstLink ? data : undefined;

  useEffect(() => {
    if (
      metadata &&
      metadata.ImageUrl &&
      Type === SocialNetworkDict_Reverse.Twitter &&
      metadata.Url &&
      CheckIfItDosentExists(MetadataChangesData, metadata.Url)
    ) {
      onPreviewForTwitterMetadataSaveData(metadata);
    }
    if (
      Files.length === 0 &&
      metadata &&
      metadata.ImageUrl &&
      index === 0 &&
      (!value.Thumbnail ||
        (value.Thumbnail && value.Thumbnail.Url === "") ||
        (value.Thumbnail && value.Thumbnail.Url !== metadata.ImageUrl))
    ) {
      onLinkDataChange({
        Url: metadata.ImageUrl,
        MetadataThumbnail: true,
        MetadataUrl: metadata.Url
      });
    } else if (
      Files.length > 0 &&
      value.Thumbnail &&
      value.Thumbnail.MetadataThumbnail === true
    ) {
      onLinkDataChange({ Url: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    Files.length,
    Type,
    index,
    metadata,
    onLinkDataChange,
    onPreviewForTwitterMetadataSaveData,
    value.Thumbnail
  ]);

  const [FinishedFiles] = useMemo(() => {
    const finished = [];
    const uploading = [];

    Files.forEach((e) => {
      if (e.TimeM !== "uploading") {
        finished.push(e);
      } else {
        uploading.push(e);
      }
    });
    return [finished, uploading];
  }, [Files]);

  let PreviewBottom = null;

  if (FinishedFiles.length > 0) {
    let ResolvedFiles = [...FinishedFiles];
    const FirstElement = ResolvedFiles.shift();
    PreviewBottom = (
      <>
        <div className="mt-1 d-flex flex-column w-100 justify-content-center">
          <img
            alt="Link"
            className="w-100"
            src={
              FirstElement.imagePreviewUrl ||
              FirstElement.Url ||
              FirstElement.Data
            }
            style={{
              objectPosition: "center",
              objectFit: "contain"
            }}
          />
          {ResolvedFiles.length > 0 && (
            <div className="mt-1 d-flex">
              {ResolvedFiles.map((e, i) => {
                return (
                  <img
                    key={i}
                    alt="Link"
                    className="w-50 h-100 flex-1"
                    src={e.imagePreviewUrl || e.Url || e.Data}
                    style={{
                      objectPosition: "center",
                      objectFit: "contain"
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </>
    );

    PreviewBottom = <ImageAlbumTwitter Image={FinishedFiles} />;
  } else if (metadata && metadata.ImageUrl && metadata.Title) {
    PreviewBottom = (
      <div className="mt-3">
        <div
          style={{ borderRadius: 11 }}
          className="d-flex border of-hidden PreviewLinkContainer"
        >
          <img
            alt="Link"
            src={metadata && metadata.ImageUrl}
            style={{
              width: 165,
              objectFit: "cover",
              objectPosition: "left"
            }}
          ></img>
          <div className="px-2 d-flex flex-column justify-content-between">
            <div
              className="text-black"
              style={{ fontSize: 13, textTransform: "capitalize" }}
            >
              <TextEllipsis text={metadata.Title} length={65} />
            </div>
            <div style={{ color: "#647786" }} className="fs-12">
              <TextEllipsis text={metadata.Description} length={75} />
            </div>
            <div
              style={{ color: "#647786" }}
              className="fs-12 d-flex align-items-center"
            >
              <FontAwesomeIcon className="mr-2" icon={faLink} />
              <TextEllipsis text={metadata.Url} length={33} />
            </div>
          </div>
          {Type !== 3 && !isPreviewing && (
            <div className="px-2">
              <div
                onClick={handleRemoveMetadataInfo}
                className="text-danger-alt h-auto cursor-pointer fs-16"
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (Type === 3 && isPreviewing) {
    return (
      <div className="px-4 pt-3 bg-airdesk PreviewWidth">
        <div>
          <SocialAccountComponentForSocialPostGrid
            data={{
              Name: SocialNetworkDict[Type],
              Type: Type
            }}
          />
          <div className="w-100 mt-3">
            <PreviewTweet
              previewBottom={FinishedFiles.length === 0 && PreviewBottom}
              config={{
                user: {
                  nickname: `${Tag}`,
                  name: `${Name}`,
                  avatar: Avatar,
                  verified: false,
                  locked: false
                },
                text: `${value.Text}`,
                image:
                  FinishedFiles.length > 1
                    ? FinishedFiles
                    : FinishedFiles[0]
                    ? FinishedFiles[0].imagePreviewUrl ||
                      FinishedFiles[0].Url ||
                      FinishedFiles[0].Data
                    : "",
                links: links,
                linkData:
                  metadata && metadata.ImageUrl && metadata.Title
                    ? { ...metadata }
                    : null,
                date: `${moment(value.ScheduleComponent.Time).format(
                  "DD MMMM YYYY, HH:mm"
                )}`,
                app: "",
                retweets: 32000,
                likes: 12700
              }}
            />
            {/* {PreviewBottom} */}
          </div>
        </div>
      </div>
    );
  }

  if (Type && isPreviewing)
    return (
      <div className="px-4 pt-3 bg-airdesk PreviewWidth">
        <div>
          <SocialAccountComponentForSocialPostGrid
            style={{ width: 24 }}
            data={{
              Name: SocialNetworkDict[Type],
              Type: Type
            }}
          />
          <div className="w-100 mt-3 border rounded mb-3 px-2 py-2 bg-white breakWord">
            <div className="d-flex">
              <div className="pr-2">
                <AccountProfileImg circular account={{ Image: Avatar }} />
              </div>
              <div className="flex-1">
                <div className="d-flex align-items-center">
                  {Name && (
                    <span className="fs-14 fw-medium text-black mr-2">
                      {Name}
                    </span>
                  )}
                  {Tag && (
                    <span style={{ color: "#657786" }} className="mr-2 fs-12">
                      {Tag}
                    </span>
                  )}
                  {value.ScheduleComponent && (
                    <span style={{ color: "#657786" }} className="fs-12">
                      {moment(value.ScheduleComponent.Time).format(
                        "DD MMMM YYYY, HH:mm"
                      )}
                    </span>
                  )}
                </div>
                <div
                  style={{ fontSize: 13, whiteSpace: " pre-wrap" }}
                  className="mt-1 text-black"
                >
                  {value.Text}
                </div>
              </div>
            </div>
            {PreviewBottom}
          </div>
        </div>
      </div>
    );
  else return <div className="bg-airdesk PreviewWidth"></div>;
};

export const SingleSocial = React.memo(
  ({
    style,
    onChange,
    states,
    canEdit,
    length,
    EnableAddMorePosts,
    index,
    IncreasePostsCount,
    value,
    convertedForm,
    RemoveItem,
    SplitAccounts,
    isPreviewing
  }) => {
    const {
      hasError,
      IsTwitterAllowed,
      Id,
      // Comments,
      SocialId,
      // Creator,
      Thumbnail,
      Text,
      // Schedule,
      // IsSchedule,
      MetadataChangesData = [],
      FilesUnsorted,
      ScheduleComponent,
      Status
      // CreationDate,
    } = value;

    const [imageToggler, setImageToggler] = useState(() => {
      if (Array.isArray(FilesUnsorted) && FilesUnsorted.length > 0) return true;
      else return false;
    });

    const isPublished = Status === 3;

    const toggleImages = () => {
      setImageToggler((e) => {
        if (Array.isArray(FilesUnsorted) && FilesUnsorted.length > 0)
          return true;
        else return !e;
      });
    };

    const handleChanges = (e) => {
      const { name, valueForm } = e.target;

      if (
        name === "FilesUnsorted" &&
        valueForm.length > 0 &&
        valueForm[0].TimeM !== "uploading"
      ) {
        if (Thumbnail === undefined || Thumbnail === "") {
          onChange({ [name]: valueForm, Thumbnail: valueForm[0] || "" }, index);
        } else if (
          Thumbnail.dataKey !== undefined &&
          Thumbnail.dataKey !== valueForm[0].dataKey
        ) {
          onChange({ [name]: valueForm, Thumbnail: valueForm[0] || "" }, index);
        } else if (
          Thumbnail.Url !== undefined &&
          Thumbnail.Url !== valueForm[0].Url
        ) {
          onChange({ [name]: valueForm, Thumbnail: valueForm[0] || "" }, index);
        } else onChange({ [name]: valueForm }, index);
      } else if (name === "FilesUnsorted" && valueForm.length === 0)
        onChange({ [name]: valueForm, Thumbnail: "" }, index);
      else onChange({ [name]: valueForm }, index);
    };

    let socialAccountError = false;
    let TextError = false;

    if (hasError) {
      if (!SocialId) {
        socialAccountError = true;
      }
      if (SocialId && Array.isArray(SocialId)) {
        SocialId.forEach((t) => {
          const { Type } = getEntity(spaceSocialAccountSchema, t);
          if (
            Type === SocialNetworkDict_Reverse.Facebook ||
            Type === SocialNetworkDict_Reverse.Facebook_two ||
            Type === SocialNetworkDict_Reverse.Facebook_three
          ) {
            if (Text && Text.length > 2200) {
              TextError = true;
            }
          } else if (Type === SocialNetworkDict_Reverse.Twitter) {
            if (Text && Text.length > 280) {
              TextError = true;
            }
          } else if (
            Type === SocialNetworkDict_Reverse.LinkedIn ||
            Type === SocialNetworkDict_Reverse.LinkedIn_two
          ) {
            if (Text && Text.length > 2200) {
              TextError = true;
            }
          } else if (Type === SocialNetworkDict_Reverse.Instagram) {
            if (Text && Text.length > 2200) {
              TextError = true;
            }
          }
        });
      }
      if (!Text) {
        TextError = true;
      }
      if (Text && Text.length === 0) {
        TextError = true;
      }
    }

    const handleLinkDataSetting = useCallback(
      (e) => {
        const { MetadataThumbnail, MetadataUrl, Url } = e;

        onChange(
          {
            Thumbnail: { Url, MetadataUrl, MetadataThumbnail },
            FirstLink: MetadataUrl
          },
          index
        );
      },
      [index, onChange]
    );

    const onPreviewForTwitterMetadataSaveData = useCallback(
      (e) => {
        const { Url, ImageUrl } = e;
        const NewMetadataChangesData = [...MetadataChangesData];
        NewMetadataChangesData.push({ MetadataUrl: Url, Url: ImageUrl });
        onChange(
          {
            MetadataChangesData: NewMetadataChangesData
          },
          index
        );
      },
      [MetadataChangesData, index, onChange]
    );

    return (
      <div
        style={style}
        className={classnames("d-flex bg-airdesk", {
          "flex-1": length === 1 || index === length - 1,
          "flex-0-0-auto": length > 1 && index !== length - 1
        })}
      >
        <div
          className="d-flex flex-column bg-white SideBarSocialPost-InputSide"
          style={{ width: 520, zIndex: 0 }}
        >
          <div className="d-flex flex-column ">
            <div className="px-4 py-3">
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <span className="text-black fs-14 fw-medium">
                  <FormattedMessage id="PUBLISH_FROM" />:
                </span>
                {!Id && length > 1 && (
                  <div
                    onClick={() => {
                      RemoveItem(index);
                    }}
                    className="text-danger-alt cursor-pointer"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </div>
                )}
              </div>

              <div className={classnames({ "mb-3": !canEdit })}>
                <div className="mb-1 fs-14  text-black">
                  <div
                    className={classnames(
                      "d-flex justify-content-between align-items-center",
                      {
                        "text-danger": socialAccountError
                      }
                    )}
                  >
                    <span>
                      <FormattedMessage id="SOCIAL_MEDIA_ACCOUNTS" />
                    </span>
                    {Array.isArray(SocialId) && SocialId.length > 1 && (
                      <div
                        onClick={() => {
                          SplitAccounts(index);
                        }}
                        className="text-color-link d-inline fs-14 cursor-pointer disable-selection text-decoration-underline-with-hover"
                      >
                        <FormattedMessage id="SPLIT" />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={classnames({ "mb-3": !canEdit || isPublished })}
                >
                  <SocialAccountDropdown
                    IsTwitterAllowed={IsTwitterAllowed}
                    value={SocialId}
                    preview={!canEdit || isPublished}
                    clearTextOnChanges={true}
                    multiple={!Boolean(Id)}
                    removable={!Boolean(Id)}
                    excludeOnSelect={!Boolean(Id)}
                    className={classnames("mb-3", {
                      error: socialAccountError
                    })}
                    onChange={(e) => {
                      handleChanges({
                        target: { name: "SocialId", valueForm: e }
                      });
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <div className="mb-1 fs-14  text-black">
                  <FormattedMessage id="PUBLISHING_DATE" />
                </div>
                <SocialPostsSchedulePublishComponent
                  preview={!canEdit || isPublished}
                  value={ScheduleComponent}
                  onChange={(e) => {
                    handleChanges({
                      target: { name: "ScheduleComponent", valueForm: e }
                    });
                  }}
                />
              </div>

              <div className="mb-3 text-black">
                <div
                  className={classnames("mb-1 fs-14", {
                    "text-danger": TextError
                  })}
                >
                  <FormattedMessage id="DESCRIPTION" />
                </div>
                <SocialPostsDescription
                  value={Text}
                  preview={!canEdit || isPublished}
                  TextError={TextError}
                  textInputClassname={"PostFormDescription"}
                  account={SocialId}
                  toggleImages={toggleImages}
                  onChange={(e) => {
                    handleChanges({ target: { name: "Text", valueForm: e } });
                  }}
                />
              </div>
              {imageToggler && (
                <div className="mb-4">
                  <div className="mb-1 fs-14  text-black">
                    <FormattedMessage id="ATTACHMENTS" />
                  </div>
                  <SocialPostsFileInput
                    preview={!canEdit || isPublished}
                    value={FilesUnsorted}
                    textInputClassname={"PostFormDescription"}
                    account={SocialId}
                    onChange={(e) => {
                      handleChanges({
                        target: { name: "FilesUnsorted", valueForm: e }
                      });
                    }}
                  />
                </div>
              )}
              {EnableAddMorePosts && index === length - 1 && canEdit && (
                <div>
                  <Button
                    vType="primary-ghost"
                    className="w-100"
                    onClick={IncreasePostsCount}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="ml-2">
                        <FormattedMessage id="ADD_ANOTHER_PUBLICATION" />
                      </span>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {Array.isArray(value.SocialId) && value.SocialId.length > 1 ? (
          <MultipleSocialPostPreview
            isPreviewing={isPreviewing}
            onLinkDataChange={handleLinkDataSetting}
            value={value}
            onPreviewForTwitterMetadataSaveData={
              onPreviewForTwitterMetadataSaveData
            }
          />
        ) : (
          <SingleSocialPostPreview
            isPreviewing={isPreviewing}
            onLinkDataChange={handleLinkDataSetting}
            value={value}
            onPreviewForTwitterMetadataSaveData={
              onPreviewForTwitterMetadataSaveData
            }
          />
        )}
      </div>
    );
  }
);

const SocialPostsSchedulePublishComponent = ({ value, onChange, preview }) => {
  const handleTimeChange = (e) => {
    onChange({
      PublishNow: false,
      Time: e
    });
  };

  const handleCheckChange = (e) => {
    onChange({
      Time: e.target.checked ? new Date() : (value && value.Time) || new Date(),
      PublishNow: e.target.checked
    });
  };

  const { Time, PublishNow } = useMemo(() => {
    return value
      ? value
      : {
          Time: new Date(),
          PublishNow: false
        };
  }, [value]);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div
        className={classnames("mr-2 fs-12 RemoveFullContainer", {
          disableTimePicker: PublishNow
        })}
      >
        <ArDatePickerWithSocialPresetSchedules
          preview={preview}
          value={Time}
          onChange={handleTimeChange}
        />
      </div>
      <div className="d-flex align-items-center">
        {!preview && (
          <>
            <span className="text-black fs-14 mr-2">
              <FormattedMessage id="PUBLISH_NOW" />
            </span>
            <div style={{ width: 24 }}>
              <Checkbox
                disabled={preview}
                checked={PublishNow}
                onChange={handleCheckChange}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
