import React, {
  Component,
  useMemo,
  useRef,
  useState,
  useCallback,
  useContext
} from "react";
import { connect } from "react-redux";
import { withSpace, useSpace } from "../../Contexts/SpaceContext";
// import { CloseSidebar, OpenSidebar } from "../../Redux/Actions/Sidebar_Actions";
import {
  getActionName,
  createBasicGetAction,
  createBasicPostAction,
  getEntityFromState
} from "../../Helpers/ReduxHelper";
import { withGlobalLoader } from "../GlobalLoader/GlobalLoader";
import { useQuery } from "../../Helpers/IOClient";
import store from "../../store";
import { injectToast, useToast } from "../Toast/ToastProvider";
import { SidebarContext, useSidebarChanges } from "../Sidebar/SidebarV2";
import { OnboardingUpdateContext } from "../../Containers/SpaceOnboarding/SpaceOnboardingHelper";
import { FormattedMessage, useIntl, injectIntl } from "react-intl";
import { withConnectionId } from "../../Containers/RealTime/RealTime";
import { SidebarAutomationContext } from "../Sidebar/SidebarV2Automation";

export const FormErrorContext = React.createContext(null);
export const FormChangeContext = React.createContext(undefined);
export const FormStateContext = React.createContext(null);
export const FormCanEditContext = React.createContext(true);
export const FormInputRefsContext = React.createContext();

const withSpaceEntityForm = (
  WrappedComponent,
  {
    schema,
    getEndpoint,
    shouldConvertEntityOnAdd,
    shouldConvert,
    shouldNormalize = true,
    convertEntityToForm,
    defaultForm,
    validateForm,
    formatForm,
    getEntityId,
    onSuccess,
    onError,
    dependencies,
    hideUrlId,
    canEdit: canEditFunc
  } = {}
) => {
  if (!getEndpoint) {
    if (schema && schema.getEndpoint) getEndpoint = schema.getEndpoint;
    else
      throw new Error(
        "Expected to either schema to have a 'getEndpoint' or have it manually defined"
      );
  }

  const baseAction = {
    name: schema.name,
    actionName: getActionName(schema.name),
    internalType: "form"
  };

  const getBaseAction = (schema) => {
    return {
      name: schema.name,
      actionName: getActionName(schema.name),
      internalType: "form"
    };
  };

  const createOrUpdateActionName = `CREATE_OR_UPDATE_${baseAction.actionName}`;

  const getRequestInfo = (spaceId, entityId, ownProps, dependencyIndex) => {
    let baseAction;
    let resolvedGetEndpoint, resolvedSchema;
    if (dependencyIndex === undefined) {
      resolvedGetEndpoint = getEndpoint;
      baseAction = getBaseAction(schema);
      resolvedSchema = schema;
    } else {
      const dependency = dependencies[dependencyIndex];

      baseAction = getBaseAction(dependency.schema);
      resolvedSchema = dependency.schema;
      resolvedGetEndpoint = dependency.schema.getEndpoint;
    }

    const endpoint = hideUrlId
      ? resolvedGetEndpoint(spaceId, ownProps)
      : `${resolvedGetEndpoint(spaceId, ownProps)}/${entityId}`;

    return {
      requestName: `FETCH_${baseAction.actionName}_DETAIL`,
      endpoint,
      dispatchBody: {
        ...baseAction,
        internalType: "entityDetail",
        entityId
      },
      schema: shouldNormalize ? resolvedSchema : undefined
    };
  };

  const getEntityDetailsAction = (
    spaceId,
    entityId,
    ownProps,
    dependencyIndex
  ) =>
    createBasicGetAction(
      getRequestInfo(spaceId, entityId, ownProps, dependencyIndex)
    );

  const createEntityAction = (spaceId, form, ownProps) =>
    createBasicPostAction({
      requestName: createOrUpdateActionName,
      body: form,
      endpoint: getEndpoint(spaceId, ownProps),
      dispatchBody: { ...baseAction, spaceId },
      getSuccessBody: ({ result }) => {
        return { entityId: result };
      },
      hideUrlId,
      schema: shouldNormalize ? schema : undefined,
      headers: {
        ConnectionID: ownProps.connectionId
      }
    });

  const updateEntityAction = (spaceId, entityId, form, ownProps) =>
    createBasicPostAction({
      requestName: createOrUpdateActionName,
      body: form,
      dispatchBody: { ...baseAction, isUpdate: true, spaceId },
      endpoint: hideUrlId
        ? getEndpoint(spaceId, ownProps)
        : `${getEndpoint(spaceId, ownProps)}/${entityId}`,
      hideUrlId,
      schema: shouldNormalize ? schema : undefined,
      headers: {
        ConnectionID: ownProps.connectionId
      }
    });

  class Form extends Component {
    static contextType = SidebarContext;
    constructor(props) {
      super(props);
      this.inputRefs = React.createRef();
      this.inputRefs.current = {};
      this.state = {
        form: Form.getResetForm(props),
        validation: {
          hasErrors: false,
          errors: {}
        },
        canEdit: true
      };
    }

    handleEntityChanged = () => {
      const { getEntityDetails, EntityId, hasDetails, dependencyIndex } =
        this.props;
      if (EntityId !== undefined && !hasDetails) {
        getEntityDetails(EntityId, dependencyIndex);
      } else if (EntityId === undefined || hasDetails) this.resetForm();
    };

    componentDidMount() {
      const { getEntityDetails, EntityId, hasDetails, dependencyIndex } =
        this.props;

      if (EntityId !== undefined && !hasDetails) {
        getEntityDetails(EntityId, dependencyIndex);
      }
    }

    validateSchemaFields = (addError) => {
      if (!schema.fields) return;

      const { form } = this.state;

      for (const fieldI in schema.fields) {
        if (schema.fields.hasOwnProperty(fieldI)) {
          const field = schema.fields[fieldI];

          if (field.isRequired) {
            const value = form[fieldI];
            if (typeof field.isValid === "function") {
              if (!field.isValid(value)) addError(fieldI);
            } else {
              if (!value) addError(fieldI);
            }
          }
        }
      }
    };

    validateForm = (callback) => {
      let newState = {
        ...this.state
      };

      const addError = (error, value) => {
        if (value !== undefined) newState.validation.errors[error] = value;
        else newState.validation.errors[error] = true;
        newState.validation.hasErrors = true;
      };

      if (validateForm) validateForm(this.state.form, addError);
      this.validateSchemaFields(addError);

      if (newState.validation.hasErrors) {
        this.setState(newState);
        return false;
      }

      return true;
    };

    handleValidation = () => {
      return handleValidation(this.state.form, validateForm, (v) => {
        this.setState({
          validation: v
        });
      });
    };

    handleSubmit = (e, optionalFlag) => {
      const { isValid, firstError } = this.handleValidation();
      if (!isValid) {
        triggerSubmitError(
          this.props.toastInstance,
          this.inputRefs,
          this.props.intl,
          firstError,
          this.state.form
        );
        return;
      }

      let form;

      if (typeof formatForm === "function")
        form = formatForm(this.state.form, optionalFlag);
      else form = this.state.form;

      const { createEntity, updateEntity } = this.props;

      // this.props.globalLoader.start();

      if (this.props.dependencyIndex === undefined && this.props.EntityId)
        updateEntity(this.props.EntityId, form);
      else createEntity(form);
    };

    static getResetForm = (props) => {
      const originForm = props.form;
      const entityObj = props.EntityId ? props.Entity : undefined;

      let resolvedDefaultForm =
        typeof defaultForm === "function" ? defaultForm(props) : defaultForm;

      if (props.defaultForm)
        resolvedDefaultForm = { ...resolvedDefaultForm, ...props.defaultForm };

      if (props.duplicationForm) {
        let duplicationForm = props.duplicationForm;

        if (convertEntityToForm)
          duplicationForm = convertEntityToForm(duplicationForm);

        resolvedDefaultForm = { ...resolvedDefaultForm, ...duplicationForm };
      }

      if (!entityObj && !shouldConvertEntityOnAdd)
        return {
          ...resolvedDefaultForm,
          ...originForm
        };
      else {
        let entityData;

        if (props.dependencyIndex !== undefined) {
          const dependency = dependencies[props.dependencyIndex];
          entityData = dependency.convertEntityToForm(
            entityObj,
            props,
            originForm
          );
        } else if (convertEntityToForm) {
          entityData = convertEntityToForm(entityObj, props, originForm);
        } else {
          entityData = entityObj;
        }

        return {
          ...resolvedDefaultForm,
          ...entityData,
          ...originForm
        };
      }
    };

    static canEdit = (props) => {
      if (props.preview !== undefined) return !props.preview;

      if (!canEditFunc) return true;

      const { EntityId, Entity, dependencyIndex } = props;

      if (dependencyIndex === undefined && EntityId) {
        return canEditFunc(Entity);
      } else return true;
    };

    resetForm = () => {
      this.setFormState(Form.getResetForm(this.props));
    };

    static shouldResetForm(props, state) {
      const { hasDetails, EntityId } = props;

      const { hasDetails: hadDetails } = state;

      if (EntityId && hasDetails && !hadDetails) return true;
      else return false;
    }

    static getDerivedStateFromProps(props, state) {
      const { hasDetails } = props;

      if (Form.shouldResetForm(props, state))
        return {
          hasDetails,
          canEdit: Form.canEdit(props),
          form: Form.getResetForm(props)
        };

      return {
        hasDetails,
        canEdit: Form.canEdit(props)
      };
    }

    handleChange = (e) => {
      this.setFormState((f) => {
        const { name, value } =
          typeof e === "function" ? e(f).target : e.target;
        return {
          [name]: value
        };
      });
    };

    getResolvedEntityId = () => {
      const { dependencyIndex, EntityId: entityId } = this.props;
      return dependencyIndex !== undefined ? null : entityId;
    };

    getResolvedEntity = () => {
      const { dependencyIndex, Entity } = this.props;
      return dependencyIndex !== undefined ? null : Entity;
    };

    componentDidUpdate(prevProps) {
      const { isProcessing, error } = this.getFormState();
      //verify if update/creation has finished

      const entityChanged =
        this.props.EntityId !== prevProps.EntityId ||
        (shouldConvert && shouldConvert(prevProps, this.props));

      if (entityChanged) this.handleEntityChanged();

      const [OpenSidebar, CloseSidebar] = this.context || [];

      if (
        !isProcessing &&
        prevProps.FormState &&
        prevProps.FormState.isProcessing
      ) {
        // this.props.globalLoader.stop();
        const { getParentForm, oldForm, isChild, FormState, refetchRef } =
          this.props;
        if (!error) {
          if (refetchRef && refetchRef.current) refetchRef.current();
          let willOpenSidebar;
          const openSidebar = (...args) => {
            willOpenSidebar = true;
            OpenSidebar && OpenSidebar(...args);
          };
          if (onSuccess || this.props.onSuccess) {
            const props = {
              openSidebar,
              form: this.state.form,
              entityId: this.getResolvedEntityId(),
              createToast: this.props.toastInstance,
              intl: this.props.intl
            };

            if (onSuccess) {
              onSuccess(props);
            }
            if (this.props.onSuccess) {
              this.props.onSuccess(props);
            }
          }

          if (willOpenSidebar) return;

          const newOldForm = getParentForm
            ? { ...oldForm, ...getParentForm(FormState.entityId) }
            : undefined;

          CloseSidebar &&
            CloseSidebar(isChild ? false : true, {
              oldProps: {
                form: newOldForm
              }
            });
        } else {
          onError && onError({ error, createToast: this.props.toastInstance });
        }
      }
    }
    setFormState = (formState) => {
      const newState =
        typeof formState === "function"
          ? formState(this.state.form)
          : formState;

      this.setState({
        form: {
          ...this.state.form,
          ...newState
        },
        validation: {
          errors: {},
          hasErrors: false
        }
      });
    };

    getFormState = () => {
      const { FormState } = this.props;
      return FormState ? FormState : {};
    };

    shouldComponentUpdate(nextProps) {
      const wasFetching = this.props.isFetchingDetails;
      if (!wasFetching) return true;

      const isFetching = nextProps.isFetchingDetails;

      if (wasFetching === isFetching) return false;
      else return true;
    }

    render() {
      const { isProcessing, formError } = this.getFormState();

      const {
        isFetchingDetails,
        hasDetails,
        detailsError,
        EntityId: entityId
      } = this.props;

      const resolvedEntityId = this.getResolvedEntityId();
      const entity = this.getResolvedEntity();

      return (
        <OnboardingUpdateContext.Consumer>
          {(obContext) => {
            const { retry } = obContext || {};
            return (
              <FormChangeContext.Provider value={this.handleChange}>
                <FormErrorContext.Provider value={this.state.validation.errors}>
                  <FormStateContext.Provider value={this.state.form}>
                    <FormCanEditContext.Provider value={this.state.canEdit}>
                      <FormInputRefsContext.Provider value={this.inputRefs}>
                        <WrappedComponent
                          refetchRef={this.props.refetchRef}
                          form={this.state.form}
                          setFormState={this.setFormState}
                          formStatus={{
                            isChild: this.props.isChild,
                            form: this.state.form,
                            setFormState: this.setFormState,
                            validateForm: this.handleValidation,
                            submit: retry || this.handleSubmit,
                            entityId: resolvedEntityId,
                            isFetchingDetails:
                              isFetchingDetails ||
                              Boolean(!hasDetails && entityId),
                            validation: this.state.validation,
                            isProcessing,
                            error: formError || detailsError,
                            entity
                          }}
                          submit={retry || this.handleSubmit}
                          entityId={resolvedEntityId}
                          entity={entity}
                        />
                      </FormInputRefsContext.Provider>
                    </FormCanEditContext.Provider>
                  </FormStateContext.Provider>
                </FormErrorContext.Provider>
              </FormChangeContext.Provider>
            );
          }}
        </OnboardingUpdateContext.Consumer>
      );
    }
  }

  const mapStateToProps = (state, ownProps) => {
    let entityId = undefined,
      details = undefined,
      dependencyIndex = undefined;

    entityId = getEntityId ? getEntityId(ownProps) : ownProps.id;

    //get entity info
    if (entityId) {
      details = getEntityState(state, schema, entityId);
    }
    //if no entity is found verify dependicies for form inheritance
    else if (Array.isArray(dependencies)) {
      for (let dependecyI = 0; dependecyI < dependencies.length; dependecyI++) {
        const dependency = dependencies[dependecyI];

        const dependecyEntityId = dependency.getEntityId(ownProps);

        if (dependecyEntityId) {
          entityId = dependecyEntityId;
          details = getEntityState(state, dependency.schema, entityId);
          dependencyIndex = dependecyI;
          break;
        }
      }
    }

    if (!details) details = getEntityState();

    let FormState = state.Forms[baseAction.name]
      ? state.Forms[baseAction.name]
      : null;

    return {
      FormState,
      EntityId: entityId,
      ...details,
      dependencyIndex
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      createEntity: (form) =>
        dispatch(createEntityAction(ownProps.space.Id, form, ownProps)),
      updateEntity: (entityId, form) =>
        dispatch(
          updateEntityAction(ownProps.space.Id, entityId, form, ownProps)
        ),
      getEntityDetails: (entityId, dependencyIndex) =>
        dispatch(
          getEntityDetailsAction(
            ownProps.space.Id,
            entityId,
            ownProps,
            dependencyIndex
          )
        ),
      dispatch
    };
  };

  return withSpace(
    withConnectionId(
      withGlobalLoader(
        injectIntl(
          injectToast(connect(mapStateToProps, mapDispatchToProps)(Form))
        )
      )
    )
  );
};

const getEntityState = (state, schema, entityId) => {
  let Entity = undefined,
    isFetchingDetails = false,
    hasDetails = false,
    detailsError = null;

  if (!state)
    return {
      Entity,
      isFetchingDetails,
      hasDetails,
      detailsError
    };
  const name = schema.name;
  if (state.Entities[name]) {
    Entity = state.Entities[name][entityId];
  }

  let currentEntityDetails = state.EntityDetails[name];

  if (currentEntityDetails)
    currentEntityDetails = currentEntityDetails[entityId];

  if (currentEntityDetails) {
    isFetchingDetails = currentEntityDetails.isFetching;
    hasDetails = !currentEntityDetails.isFetching;
    detailsError = currentEntityDetails.error;
  }

  return {
    Entity,
    isFetchingDetails,
    hasDetails,
    detailsError
  };
};

const defaultValidation = {
  hasErrors: false,
  errors: {}
};

const handleValidation = (form, validator, setValidation) => {
  let validation = { ...defaultValidation, errors: {} };
  let firstError;

  const addError = (error, value) => {
    if (value !== undefined) validation.errors[error] = value;
    else validation.errors[error] = true;
    if (!firstError) firstError = { name: error, value };
    validation.hasErrors = true;
  };

  if (validator) validator(form, addError);

  if (validation.hasErrors) {
    setValidation(validation);
    return { isValid: false, firstError, validation };
  }
  return { isValid: true, validation };
};

export const useFormState = (defaultForm, validate) => {
  const defaultFormRef = useRef(defaultForm);
  const [form, setForm] = useState(defaultForm);
  const [validation, setValidation] = useState(defaultValidation);
  const [toggleSidebarChanges, hasChanges] = useSidebarChanges();
  const setError = useCallback((name, error) => {
    setValidation((v) => {
      return {
        ...v,
        errors: {
          ...v.errors,
          [name]: error || true
        },
        hasErrors: true
      };
    });
  }, []);

  const handleChange = useCallback(
    (v, dontTrigger) => {
      if (!hasChanges && !dontTrigger) toggleSidebarChanges();
      setForm((f) => {
        ////
        const newForm = typeof v === "function" ? v(f) : v;

        return {
          ...f,
          ...newForm
        };
      });
    },
    [hasChanges, toggleSidebarChanges]
  );

  const reset = useCallback((newDefault) => {
    if (newDefault) {
      setForm(newDefault);
      defaultFormRef.current = newDefault;
    } else {
      setForm(defaultFormRef.current);
    }
  }, []);

  const changes = useMemo(() => {
    let changes = 0;
    const defaultForm = defaultFormRef.current;
    const hasTyped = defaultFormRef.current !== form;
    if (hasTyped) {
      for (const key in form) {
        if (form.hasOwnProperty(key)) {
          const formValue = form[key];
          if (defaultForm[key] !== formValue) changes++;
        }
      }
    }
    return changes;
  }, [form]);

  const triggerValidation = useCallback(() => {
    return handleValidation(form, validate, setValidation);
  }, [form, validate]);

  return {
    form,
    setForm,
    handleChange,
    validation,
    setValidation,
    setError,
    reset,
    changes,
    triggerValidation
  };
};

const triggerSubmitError = (createToast, inputRefs, intl, firstError, form) => {
  const f = intl.formatMessage;

  const {
    elem: errorInput,
    textId: errorFieldTextId,
    text: translatedFieldText
  } = inputRefs.current[firstError.name] || {};

  let title, desc;

  const { name: ErrorProp, value: ErrorDescription } = firstError;

  const value = form[ErrorProp];

  if (Boolean(value) || ErrorDescription) {
    if (ErrorDescription) {
      title = <FormattedMessage id="INVALID_FIELD" />;
      desc = ErrorDescription;
    } else {
      title = (
        <span>
          {errorFieldTextId
            ? `${
                errorFieldTextId
                  ? f({ id: errorFieldTextId })
                  : translatedFieldText
              } - ${f({
                id: "INVALID_FIELD"
              })}`
            : f({ id: "INVALID_FIELD" })}
        </span>
      );

      desc = <FormattedMessage id="INVALID_FIELD_DESCRIPTION" />;
    }
  } else {
    title = (
      <span>
        {errorFieldTextId
          ? `${
              errorFieldTextId
                ? f({ id: errorFieldTextId })
                : translatedFieldText
            } - ${f({
              id: "FIELD_IS_REQUIRED"
            })}`
          : f({ id: "FIELD_IS_REQUIRED" })}
      </span>
    );

    desc = <FormattedMessage id="FIELD_IS_REQUIRED_DESCRIPTION" />;
  }

  if (errorInput)
    errorInput.scrollIntoView({ behavior: "smooth", block: "nearest" });
  createToast({
    pos: "tm",
    type: "danger",
    title,
    description: desc
  });
};

export const FormValidationContext = React.createContext();

export const Form = ({
  formState,
  children,
  onSubmit,
  validate,
  className,
  canEdit,
  hideForm
}) => {
  const { form, setForm, validation, setValidation } = formState;
  const [toggleSidebarChanges, hasChanges] = useSidebarChanges();
  const handleChange = useCallback(
    (e) => {
      if (!hasChanges) toggleSidebarChanges();
      let target;
      if (typeof e !== "function") target = e.target;

      setForm((f) => {
        if (!target) target = e(f).target;
        const { name, value } = target;

        return {
          ...f,
          [name]: value
        };
      });

      setValidation((v) => {
        return defaultValidation;
      });
    },
    [hasChanges, setForm, setValidation, toggleSidebarChanges]
  );

  const createToast = useToast();

  const inputRefs = useRef({});
  const intl = useIntl();

  const validateForm = useCallback(() => {
    const response = handleValidation(form, validate, setValidation);

    if (!response.isValid)
      triggerSubmitError(
        createToast,
        inputRefs,
        intl,
        response.firstError,
        form
      );

    return response;
  }, [createToast, form, intl, setValidation, validate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid } = validateForm();
    if (isValid) {
      toggleSidebarChanges(false);

      onSubmit(form);
    }
  };

  const Providers = (
    <React.Fragment>
      <FormChangeContext.Provider value={handleChange}>
        <FormErrorContext.Provider value={validation?.errors}>
          <FormStateContext.Provider value={form}>
            <FormInputRefsContext.Provider value={inputRefs}>
              <FormValidationContext.Provider value={validateForm}>
                <FormCanEditContext.Provider
                  value={canEdit !== undefined ? canEdit : true}
                >
                  {/* <FormCanEditContext.Provider value={this.state.canEdit}>
            <WrappedComponent
            form={this.state.form}
            setFormState={this.setFormState}
            formStatus={currentStatus}
            submit={this.handleSubmit}
            entityId={resolvedEntityId}
            entity={entity}
            />
          </FormCanEditContext.Provider> */}
                  {children}
                </FormCanEditContext.Provider>
              </FormValidationContext.Provider>
            </FormInputRefsContext.Provider>
          </FormStateContext.Provider>
        </FormErrorContext.Provider>
      </FormChangeContext.Provider>
    </React.Fragment>
  );

  if (hideForm) return Providers;

  return (
    <form autoComplete="off" onSubmit={handleSubmit} className={className}>
      {Providers}
    </form>
  );
};

export const createFormRequestHook = ({
  schema,
  dependencies,
  convertEntityToForm,
  convertAutomationToForm,
  formatDuplicationEntity,
  getEndpoint
}) => {
  const useRequest = (props) => {
    const space = useSpace();
    const {
      id,
      entity,
      duplicationForm,
      form,
      childProps,
      isChild: isChildProp,
      baseForm,
      allwaysNewRequest,
      options
    } = props;

    const hasEntity = entity !== undefined;

    const {
      endpoint,
      resolvedSchema,
      resolvedId,
      resolvedConvertEntityToForm,
      isChild
    } = useMemo(() => {
      let endpoint;
      let resolvedSchema = schema;
      let resolvedId;
      let resolvedConvertEntityToForm = convertEntityToForm;
      let isChild = false;

      if (!hasEntity) {
        if (id) {
          endpoint = getEndpoint
            ? `${getEndpoint(space.Id)}/${id}`
            : `${schema.getEndpoint(space.Id)}/${id}`;
          resolvedId = id;
        } else if (dependencies) {
          for (const dependency of dependencies) {
            const {
              getEntityId,
              schema: dependencySchema,
              convertEntityToForm: convert
            } = dependency;

            const key = getEntityId(props);
            if (!key && !allwaysNewRequest) continue;

            resolvedConvertEntityToForm = convert;
            resolvedSchema = dependencySchema;
            resolvedId = key;
            endpoint = `${dependencySchema.getEndpoint(space.Id)}/${key}`;
            isChild = true;
          }
        }
      }

      return {
        endpoint,
        resolvedSchema,
        resolvedId,
        resolvedConvertEntityToForm,
        isChild
      };
    }, [allwaysNewRequest, hasEntity, id, props, space.Id]);
    const automation = Boolean(useContext(SidebarAutomationContext));

    const { loading, error, data } = useQuery(
      endpoint,
      resolvedSchema,
      options
    );
    const resolvedLoading = hasEntity ? !Boolean(entity) : loading;
    const convertedForm = useMemo(() => {
      //;

      if (entity) return resolvedConvertEntityToForm(entity, props);

      if (form) {
        if (automation && convertAutomationToForm) {
          return convertAutomationToForm(form);
        }
        return form;
      }
      if (duplicationForm && convertEntityToForm) {
        let duplicationEntity = { ...duplicationForm };
        duplicationEntity.AssociatedFiles = duplicationEntity?.Files &&
          Array.isArray(duplicationEntity?.Files) && [
            ...duplicationEntity.Files
          ];
        duplicationEntity.Files = [];
        duplicationEntity.Image = null;
        if (formatDuplicationEntity)
          duplicationEntity = formatDuplicationEntity(duplicationEntity);
        return convertEntityToForm(duplicationEntity);
      }

      const state = store.getState();

      if (data === undefined || data === null) return {};

      const entityValue = getEntityFromState(state, resolvedSchema, data);
      if (resolvedConvertEntityToForm && data) {
        return resolvedConvertEntityToForm(entityValue, props);
      }
      return entityValue;
    }, [
      entity,
      resolvedConvertEntityToForm,
      props,
      form,
      duplicationForm,
      data,
      resolvedSchema,
      automation
    ]);

    const resolvedKey = resolvedSchema
      ? `${resolvedSchema.name}-${resolvedId}`
      : undefined;

    return {
      key: resolvedKey,
      convertedForm,
      loading: resolvedLoading,
      error,
      isChild: isChild || isChildProp,
      childProps,
      baseForm
    };
  };

  return useRequest;
};

export default withSpaceEntityForm;
