export const notificationTypes = {
  create: 1,
  edit: 2,
  delete: 3,
  statusChanged: 4,
  managerDiscard: 5,
  discard: 6,
  userAssigned: 7,
  invite: 8,
  response: 10,
  sendResponse: 11,
  sendEmailConversationNote: 12,
  emailConversationParticipation: 13,
  emailNewmessageReceivedParticipated: 14
};
