import React, { useEffect, useState } from "react";
import ClientsCard from "./Assets/Clients_card.png";
import ContactsCard from "./Assets/Contacts_card.png";
import ContractsCard from "./Assets/Contracts_card.png";
import DealsCard from "./Assets/Deals_card.png";
import DeskBackdrop from "./Assets/Desk_backdrop.png";
import ServicesCard from "./Assets/Services_card.png";
import TimelineBackdrop from "./Assets/Timeline_backdrop.png";
import { ReactComponent as Facebook } from "./Assets/facebook-brands.svg";
import { ReactComponent as Twitter } from "./Assets/twitter-brands.svg";
import { ReactComponent as Linkedin } from "./Assets/linkedin-brands.svg";
import { ReactComponent as Instagram } from "./Assets/instagram-brands.svg";
import WorkDesk from "./Assets/Work_desk.png";
import "./FirstWelcome.css";
import { FormattedMessage, useIntl } from "react-intl";
import {
  useCurrentAccount,
  useCurrentAccountSpace
} from "../../Contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeadset,
  faBook,
  faRoute,
  faTimes,
  faArrowRight
} from "@fortawesome/pro-light-svg-icons";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";
import {
  hasModulePermission,
  modulePermissions,
  moduleTypes
} from "../../Helpers/ModulesHelper";
import {
  TasksLink,
  CallLink,
  TicketsLink,
  InterventionsLink,
  ProjectsLink,
  ContractsLink,
  DealLink,
  ContactsLink,
  ClientLink
} from "../../Helpers/HooksHelper";
import {
  faPhoneAlt,
  faTicketSimple,
  faUserClock,
  faSquareParking,
  faListCheck
} from "@fortawesome/pro-solid-svg-icons";
import { HeaderNavLink } from "../../Components/Header/HeaderV2";
import { dashboardRoute } from "../Space/SpaceHelper";

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const ClientContactRow = ({ handleCloseRow, rowState }) => {
  const accountspace = useCurrentAccountSpace();
  const { Modules } = accountspace;

  const Clients = hasModulePermission(
    Modules[moduleTypes.clients],
    modulePermissions.Access
  );
  const Contacts = hasModulePermission(
    Modules[moduleTypes.contacts],
    modulePermissions.Access
  );

  const ClientContactState = Clients || Contacts;

  const handleCloseClick = () => {
    handleCloseRow("first");
  };
  const intl = useIntl();

  useEffect(() => {
    if (!ClientContactState) {
      handleCloseRow("first");
    }
  }, [ClientContactState, handleCloseRow]);

  if (rowState && ClientContactState) {
    return (
      <div className="d-flex flex-column rowWidth">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <span className="cardTitle">
            <FormattedMessage id={"WELCOME_FIRST_TITLE"} />
          </span>
          <div onClick={handleCloseClick} className="cursor-pointer">
            <FontAwesomeIcon size="lg" icon={faTimes} />
          </div>
        </div>
        <div className="d-flex align-items-center mb-3">
          {Clients && (
            <div className="card mr-3">
              <ClientLink target="_blank">
                <div className="content clients">
                  <img src={ClientsCard} alt="clientes" />
                </div>

                <div className="subtitle clients">
                  <div className="fs-20">
                    <FormattedMessage id={"ACCOUNTS"} />
                  </div>
                  <div className="fs-16">
                    <FormattedMessage id={"ADD_CLIENTS_WORKSPACE"} />
                  </div>
                </div>
              </ClientLink>
            </div>
          )}
          {Contacts && (
            <div className="card">
              <ContactsLink target="_blank">
                <div className="content contacts d-flex justify-content-end">
                  <img src={ContactsCard} alt="contacts" />
                </div>

                <div className="subtitle contacts">
                  <div className="fs-20">
                    <FormattedMessage id={"CONTACTS"} />
                  </div>
                  <div className="fs-16">
                    <FormattedMessage id={"ADD_CONTACTS_WORKSPACE"} />
                  </div>
                </div>
              </ContactsLink>
            </div>
          )}
        </div>
        <div className="w-100 d-flex align-items-center">
          <a
            className="link-primary"
            target="_blank"
            rel="noopener noreferrer"
            href={
              intl.locale === "en"
                ? `https://en.docs.airdesk.ai/interface/contacts`
                : "https://docs.airdesk.ai/interface/contactos"
            }
          >
            <FormattedMessage id="WELCOME_CLIENTS_CONTACTS_SUBTITLE" />
          </a>
        </div>
      </div>
    );
  } else return null;
};

const DealContractsRow = ({ handleCloseRow, rowState }) => {
  const accountspace = useCurrentAccountSpace();
  const { Modules } = accountspace;

  const Deals = hasModulePermission(
    Modules[moduleTypes.deals],
    modulePermissions.Access
  );
  const Contracts = hasModulePermission(
    Modules[moduleTypes.contracts],
    modulePermissions.Access
  );

  const DealContractState = Deals || Contracts;
  const handleCloseClick = () => {
    handleCloseRow("second");
  };

  useEffect(() => {
    if (!DealContractState) {
      handleCloseRow("second");
    }
  }, [DealContractState, handleCloseRow]);

  const intl = useIntl();
  if (rowState && DealContractState) {
    return (
      <div className="d-flex flex-column rowWidth">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <span className="cardTitle">
            <FormattedMessage id={"WELCOME_SECOND_TITLE"} />
          </span>
          <div onClick={handleCloseClick} className="cursor-pointer">
            <FontAwesomeIcon size="lg" icon={faTimes} />
          </div>
        </div>
        <div className="d-flex align-items-center mb-3">
          {Deals && (
            <div className="card mr-3">
              <DealLink target="_blank">
                <div className="content deals">
                  <img src={DealsCard} alt="deals" />
                </div>

                <div className="subtitle deals">
                  <div className="fs-20">
                    <FormattedMessage id={"DEALS"} />
                  </div>
                  <div className="fs-16">
                    <FormattedMessage id={"ADD_DEALS_WORKSPACE"} />
                  </div>
                </div>
              </DealLink>
            </div>
          )}
          {Contracts && (
            <div className="card">
              <ContractsLink target="_blank">
                <div className="content contracts d-flex justify-content-end">
                  <img src={ContractsCard} alt="contacts" />
                </div>

                <div className="subtitle contracts">
                  <div className="fs-20">
                    <FormattedMessage id={"CONTRACTS"} />
                  </div>
                  <div className="fs-16">
                    <FormattedMessage id={"ADD_CONTRACTS_WORKSPACE"} />
                  </div>
                </div>
              </ContractsLink>
            </div>
          )}
        </div>
        <div className="w-100 d-flex align-items-center ">
          <a
            className="link-primary"
            target="_blank"
            rel="noopener noreferrer"
            href={
              intl.locale === "en"
                ? "https://en.docs.airdesk.ai/interface/comercial"
                : "https://docs.airdesk.ai/interface/comercial"
            }
          >
            <FormattedMessage id="WELCOME_DEALS_CONTRACTS_SUBTITLE" />
          </a>
        </div>
      </div>
    );
  } else return null;
};

const ServicesButtons = () => {
  const accountspace = useCurrentAccountSpace();
  const { Modules } = accountspace;

  const Calls = hasModulePermission(
    Modules[moduleTypes.calls],
    modulePermissions.Access
  );
  const Projects = hasModulePermission(
    Modules[moduleTypes.projects],
    modulePermissions.Access
  );
  const Times = hasModulePermission(
    Modules[moduleTypes.interventions],
    modulePermissions.Access
  );
  const Tickets = hasModulePermission(
    Modules[moduleTypes.tickets],
    modulePermissions.Access
  );
  const Tasks = hasModulePermission(
    Modules[moduleTypes.tasks],
    modulePermissions.Access
  );

  return (
    <>
      {Calls && (
        <CallLink target="_blank" className="WelcomeButtonContainer">
          <div className="rounded mr-3 WelcomeButtonIcon">
            <FontAwesomeIcon size="lg" icon={faPhoneAlt} />
          </div>
          <span>
            <FormattedMessage id="CALLS" />
          </span>
        </CallLink>
      )}
      {Tickets && (
        <TicketsLink target="_blank" className="WelcomeButtonContainer">
          <div className="rounded mr-3 WelcomeButtonIcon">
            <FontAwesomeIcon icon={faTicketSimple} />
          </div>
          <span>
            <FormattedMessage id="CASES" />
          </span>
        </TicketsLink>
      )}
      {Times && (
        <InterventionsLink target="_blank" className="WelcomeButtonContainer">
          <div className="rounded mr-3 WelcomeButtonIcon">
            <FontAwesomeIcon icon={faUserClock} />
          </div>
          <span>
            <FormattedMessage id="TIME_REGISTER" />
          </span>
        </InterventionsLink>
      )}
      {Projects && (
        <ProjectsLink target="_blank" className="WelcomeButtonContainer">
          <div className="rounded mr-3 WelcomeButtonIcon">
            <FontAwesomeIcon size="lg" icon={faSquareParking} />
          </div>
          <span>
            <FormattedMessage id="PROJECTS" />
          </span>
        </ProjectsLink>
      )}
      {Tasks && (
        <TasksLink target="_blank" className="WelcomeButtonContainer">
          <div className="rounded mr-3 WelcomeButtonIcon">
            <FontAwesomeIcon icon={faListCheck} />
          </div>
          <span>
            <FormattedMessage id="TASKS" />
          </span>
        </TasksLink>
      )}
    </>
  );
};

const ServicesRow = ({ handleCloseRow, rowState }) => {
  const accountspace = useCurrentAccountSpace();
  const { Modules } = accountspace;

  const Calls = hasModulePermission(
    Modules[moduleTypes.calls],
    modulePermissions.Access
  );
  const Projects = hasModulePermission(
    Modules[moduleTypes.projects],
    modulePermissions.Access
  );
  const Times = hasModulePermission(
    Modules[moduleTypes.interventions],
    modulePermissions.Access
  );
  const Tickets = hasModulePermission(
    Modules[moduleTypes.tickets],
    modulePermissions.Access
  );
  const Tasks = hasModulePermission(
    Modules[moduleTypes.tasks],
    modulePermissions.Access
  );

  const ServicesState = Calls || Projects || Times || Tickets || Tasks;

  useEffect(() => {
    if (!ServicesState) {
      handleCloseRow("third");
    }
  }, [ServicesState, handleCloseRow]);

  const handleCloseClick = () => {
    handleCloseRow("third");
  };
  const intl = useIntl();
  if (rowState && ServicesState) {
    return (
      <div className="d-flex flex-column rowWidth">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <span className="cardTitle">
            <FormattedMessage id={"WELCOME_THIRD_TITLE"} />
          </span>
          <div onClick={handleCloseClick} className="cursor-pointer">
            <FontAwesomeIcon size="lg" icon={faTimes} />
          </div>
        </div>
        <div className="d-flex align-items-center mb-3">
          <div className="card fullWidth">
            <div className="d-flex content services">
              <img src={ServicesCard} alt="services" />
              <div className="flex-1 d-inline-block services-links-container">
                <ServicesButtons />
              </div>
            </div>
            <div className="subtitle services">
              <div className="fs-20">
                <FormattedMessage id={"SERVICES"} />
              </div>
              <div className="fs-16">
                <FormattedMessage id={"ADD_SERVICES_WORKSPACE"} />
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex align-items-center ">
          <a
            className="link-primary"
            target="_blank"
            rel="noopener noreferrer"
            href={
              intl.locale === "en"
                ? "https://en.docs.airdesk.ai/interface/servicos"
                : "https://docs.airdesk.ai/interface/servicos"
            }
          >
            <FormattedMessage id="WELCOME_SERVICES_SUBTITLE" />
          </a>
        </div>
      </div>
    );
  } else return null;
};

const FirstWelcome = () => {
  const user = useCurrentAccount();
  const cookieRowState = getCookie("air-welcome-row-state");
  const intl = useIntl();

  const [rowState, setRowState] = useState(() => {
    return cookieRowState
      ? JSON.parse(cookieRowState)
      : { first: true, second: true, third: true };
  });

  const handleRowClose = (row) => {
    setRowState((r) => {
      const p = { ...r };
      p[row] = false;
      setCookie("air-welcome-row-state", JSON.stringify(p), 10000);
      return p;
    });
  };

  return (
    <div className="of-y-auto">
      <div className="airWelcome_banner">
        <div className="LeftBackdrop">
          <img src={DeskBackdrop} alt="left" />
        </div>
        <div className="CenterBackdrop">
          <img src={WorkDesk} alt="center" />
        </div>
        <div className="RightBackdrop">
          <img src={TimelineBackdrop} alt="right" />
        </div>
        <div className="WelcomeMsg d-flex justify-content-center">
          <div className="flex-column justify-content-center d-inline-flex rowWidth">
            <div className="HelloMsg">
              <FormattedMessage id={"HELLO"} />{" "}
              <span aria-label="wave" role="img">
                👋
              </span>
              {" " + user.Name}
            </div>
            <div className="ConfigurationMsg">
              <FormattedMessage id={"CONFIGURE_WORK_START"} />
            </div>
            <div className="d-inline-flex align-items-center">
              <InformationComponent
                placement="top"
                text={<FormattedMessage id={"SUPPORT"} />}
                Component={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="CircleLinkLeft mr-3 d-flex align-items-center justify-content-center"
                    href={
                      intl.locale === "en"
                        ? "https://en.docs.airdesk.ai/help-support/how-to-ask-for-help"
                        : "https://docs.airdesk.ai/ajuda-suporte/como-pedir-ajuda"
                    }
                  >
                    <FontAwesomeIcon size="lg" icon={faHeadset} />
                  </a>
                }
              />
              <InformationComponent
                placement="top"
                text={<FormattedMessage id={"DOCUMENTATION"} />}
                Component={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="CircleLinkLeft mr-3 d-flex align-items-center justify-content-center"
                    href={
                      intl.locale === "en"
                        ? "https://en.docs.airdesk.ai/"
                        : "https://docs.airdesk.ai/"
                    }
                  >
                    <FontAwesomeIcon size="lg" icon={faBook} />
                  </a>
                }
              />
              <div className="Divider mr-3"></div>
              <InformationComponent
                placement="top"
                text={<FormattedMessage id={"ROADMAP"} />}
                Component={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="CircleLinkLeft mr-3 d-flex align-items-center justify-content-center"
                    href={
                      intl.locale === "en"
                        ? "https://en.docs.airdesk.ai/help-support/roadmap"
                        : "https://docs.airdesk.ai/ajuda-suporte/roadmap-v"
                    }
                  >
                    <FontAwesomeIcon size="lg" icon={faRoute} />
                  </a>
                }
              />
            </div>
          </div>
        </div>
      </div>
      {(rowState.first || rowState.second || rowState.third) && (
        <div className="airWelcome_Cards_Container">
          <ClientContactRow
            handleCloseRow={handleRowClose}
            rowState={rowState.first}
          />
          <DealContractsRow
            handleCloseRow={handleRowClose}
            rowState={rowState.second}
          />
          <ServicesRow
            handleCloseRow={handleRowClose}
            rowState={rowState.third}
          />
        </div>
      )}
      {!rowState.first && !rowState.second && !rowState.third && (
        <HeaderNavLink
          className="airWelcome_allReady"
          routeModule={dashboardRoute}
        >
          <span className="mr-3">
            <FormattedMessage id="WELCOME_ALL_READY" />
          </span>
          <FontAwesomeIcon icon={faArrowRight} />
        </HeaderNavLink>
      )}
      <div className="d-flex justify-content-center align-items-center airWelcome_footer mb-3">
        <div className="mr-3 d-flex">
          {/* <span className="mx-3">•</span> */}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              intl.locale === "en"
                ? "https://en.docs.airdesk.ai/"
                : "https://docs.airdesk.ai/"
            }
          >
            <FormattedMessage id="DOCUMENTATION" />
          </a>
        </div>
        <div className="mr-3 d-flex">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.airdesk.ai/facebook"
            className="mr-3"
          >
            <Facebook />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.airdesk.ai/instagram"
            className="mr-3"
          >
            <Instagram />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.airdesk.ai/twitter"
            className="mr-3"
          >
            <Twitter />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.airdesk.ai/linkedin"
          >
            <Linkedin />
          </a>
        </div>
        <div className="d-flex white-space-pre">
          <span>© Copyright {new Date().getFullYear()} Airdesk. </span>{" "}
          <FormattedMessage id="COPYRIGHT" />
        </div>
      </div>
    </div>
  );
};

export default FirstWelcome;
