import { faClipboardCheck, faSuitcase } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useClassification, useClient } from "../../Hooks/EntityHooks";
import DropdownInput from "../Dropdown/DropdownInput";
import { AccountProfileImg } from "../Img/Img";

const ClassificationListItem = ({
  classification,
  enableInput,
  isTyping,
  text,
  ...rest
}) => {
  const { NoTagInternalDescription, Client, Status, Id, IndirectClient } =
    classification || {};

  const ClientData = useClient(Client || IndirectClient);

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100 of-hidden text-truncate">
        {!isTyping && ClientData && (
          <AccountProfileImg
            size="mds"
            account={ClientData}
            circular
            className="mr-2 flex-0-0-auto"
          />
        )}
        <div className="d-flex flex-column align-items-baseline w-100 of-hidden mr-2">
          <div className="dropdown-item-maintext ">
            {!enableInput && NoTagInternalDescription ? (
              NoTagInternalDescription.replace(/\s\s+/g, " ")
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={NoTagInternalDescription}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...rest}
              />
            )}
          </div>
          {!isTyping && (
            <div className="dropdown-item-subtext-dif text-truncate">
              {ClientData && ClientData.Name && (
                <span>
                  <FontAwesomeIcon className="mr-1" icon={faSuitcase} />
                  {ClientData.Name}
                </span>
              )}
            </div>
          )}
        </div>
        {!isTyping && classification && (
          <div
            className={`d-flex align-items-center justify-content-end status-color-${Status}`}
          >
            <div className="d-flex py-1 px-2 fs-12 align-items-center status-list-template">
              <FontAwesomeIcon className="mr-2" icon={faClipboardCheck} />
              <span>{Id}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ConnectedClassificationListItem = ({
  classification,
  ...rest
}) => {
  const i = useClassification(classification);
  return <ClassificationListItem classification={i} {...rest} />;
};
