import { faArrowDown, faArrowUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./CounterInput.module.css";

const CounterInput = ({
  value,
  enableInput,
  onChange,
  step,
  min,
  max,
  ...rest
}) => {
  const [move, setMove] = useState("");
  const resolvedStep = useMemo(() => {
    if (typeof step === "number" && step > 0) return step;
    else return 1;
  }, [step]);
  const [count, setCount] = useState(value);

  const increment = useCallback(() => {
    setCount((oldC) => {
      const resultOldC = Number(oldC);
      if (max && resultOldC + resolvedStep > max) return resultOldC;
      else return resultOldC + resolvedStep;
    });
  }, [max, resolvedStep]);

  const decrement = useCallback(() => {
    setCount((oldC) => {
      const resultOldC = Number(oldC);
			if (resultOldC - resolvedStep < 0) return 0;
      else if (min && resultOldC - resolvedStep < min) return resultOldC;
      else return resultOldC - resolvedStep;
    });
  }, [min, resolvedStep]);

  const onInputChange = (e) => {
    const { value } = e.target;

    setCount(Number(value));
  };

  useEffect(() => {
    let timeout, interval;
    if (move === "") return;

    if (move === "up" || move === "down") {
      timeout = setTimeout(function () {
        interval = setInterval(function () {
          switch (move) {
            case "up":
              increment();
              break;
            case "down":
              decrement();
              break;
            default:
              break;
          }
        }, 50);
      }, 150);

      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
      };
    }
  }, [decrement, increment, move]);

  useEffect(() => {
    if (move === "" && value !== count) onChange(count);
  }, [count, move, onChange, value]);

  const onClickUp = () => {
    increment();
    setMove("up");
  };

  function clearTimers() {
    setMove("");
  }
  const onClickDown = () => {
    decrement();
    setMove("down");
  };

  return (
    <div className={styles.counterInputContainer}>
      <div
        onMouseDown={onClickDown}
        onMouseUp={clearTimers}
        onMouseOut={clearTimers}
        className="d-flex align-items-center cursor-pointer justify-content-center px-2 py-1 text-primary"
      >
        <FontAwesomeIcon icon={faArrowDown} />
      </div>
      {enableInput ? (
        <div className="align-items-center  bg-transparent border-bottom-0 border-left border-right border-top-0 d-flex justify-content-center  px-5 py-1 text-black">
          <input
            onChange={onInputChange}
            value={count}
            min={min}
            max={max}
            type="number"
            className="outline-0 border-0 text-align-center bg-transparent"
          />
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center px-5 py-1 border-right border-left text-black">
          {count}
        </div>
      )}
      <div
        onMouseDown={onClickUp}
        onMouseUp={clearTimers}
        onMouseOut={clearTimers}
        className="d-flex align-items-center cursor-pointer justify-content-center px-2 py-1 text-primary"
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </div>
    </div>
  );
};

export default CounterInput;
