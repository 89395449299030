// import React from "react";
// import classnames from "classnames";
import {
  darkred,
  red,
  lightred,
  darkgrey,
  //   grey,
  darkgreen,
  //   green,
  createChartComponent,
  darkblue,
  darkPurple,
  // purple,
  green,
  lightPurple
  //   blue,
  //   lightgrey
} from "./echartDefaults";
import { DealStatusTypes } from "../../Deals/DealsHelper";

export const DealStatusGlobalCounter = createChartComponent((Data, intl) => {
  const AllValues = Data.map((t) => t.Total);
  const MaxValue =
    AllValues.length === 0
      ? 0
      : AllValues.reduce((a, b) => {
          return Math.max(a, b);
        });
  const MinValue =
    AllValues.length === 0
      ? 0
      : AllValues.reduce((a, b) => {
          return Math.min(a, b);
        });

  const enableTooltips = MaxValue - MinValue > 10;

  return {
    tooltip: {
      show: enableTooltips,
      trigger: "item",
      formatter: "{a}: {c}",
      axisPointer: {
        type: "shadow"
      },
      renderMode: "richText",
      textStyle: {
        color: "#FFFFFF"
        // fontWeight: "500"
      },
      backgroundColor: "#002B63"
    },
    legend: {
      show: false,
      left: "left",
      selectedMode: false,
      borderRadius: 3,
      icon: "roundRect",
      textStyle: {
        color: darkgrey,
        backgroundColor: lightred
      },
      data: [
        {
          name: intl.formatMessage({ id: "LOSES" }),
          textStyle: {
            color: darkred,

            backgroundColor: "#fff",
            borderRadius: 3
          }
        },
        {
          name: intl.formatMessage({ id: "OPEN" }),
          textStyle: {
            color: darkgreen,

            backgroundColor: "#fff",
            borderRadius: 3
          }
        },
        {
          name: intl.formatMessage({ id: "SALES" }),
          textStyle: {
            color: darkblue,

            backgroundColor: "#fff",
            borderRadius: 3
          }
        },

        {
          name: intl.formatMessage({ id: "CANCELLED" }),
          textStyle: {
            color: darkgrey,

            backgroundColor: "#fff",
            borderRadius: 3
          }
        }
      ]
    },
    grid: {
      right: "0%",
      bottom: "0%",
      top: "0%",
      left: "3%",
      containLabel: true
    },
    xAxis: {
      type: "value",
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      splitLine: {
        show: false
      }
    },
    yAxis: [
      {
        name: intl.formatMessage({ id: "DEAL_STATUS" }),
        type: "category",
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        data: Data.map((t) => {
          return {
            value: t.PipelineStatus?.Name,
            textStyle: {
              color:
                t.PipelineStatus.Status === DealStatusTypes.open
                  ? darkPurple
                  : t.PipelineStatus.Status === DealStatusTypes.lost
                  ? darkred
                  : t.PipelineStatus.Status === DealStatusTypes.won
                  ? darkgreen
                  : darkgrey
            }
          };
        }).reverse()
      }
      //   {
      //     name: "Contagem dos Negócios",
      //     type: "category",
      //     axisLine: {
      //       show: false
      //     },
      //     axisTick: {
      //       show: false
      //     },
      //     data: []
      //   }
    ],
    series: [
      {
        name: intl.formatMessage({ id: "LOSES" }),
        type: "bar",
        stack: "stack",

        itemStyle: {
          color: red
        },
        // tooltip: {
        //   show: enableTooltips,
        //   trigger: "item",
        //   formatter: "{a}: {c}",
        //   axisPointer: {
        //     type: "shadow"
        //   },
        //   renderMode: "richText",
        //   textStyle: {
        //     // fontWeight: "500"
        //   },
        //   backgroundColor: "#002B63"
        // },
        data: Data.map((t) => {
          if (t.PipelineStatus.Status === DealStatusTypes.lost)
            return {
              value: t.Total,
              label: {
                show: t.Total > (MaxValue - MinValue) / 9,
                position: "insideLeft",
                fontWeight: "bold",
                color: darkred
              }
            };
          else
            return {
              value: 0,
              label: {
                show: false,
                position: "insideLeft",
                fontWeight: "bold",
                color: darkred
              }
            };
        }).reverse()
      },
      {
        // tooltip: {
        //   show: enableTooltips,
        //   trigger: "item",
        //   formatter: "{a}: {c}",
        //   axisPointer: {
        //     type: "shadow"
        //   },
        //   renderMode: "richText",
        //   textStyle: {
        //     // fontWeight: "500"
        //   },
        //   backgroundColor: "#002B63"
        // },
        name: intl.formatMessage({ id: "OPEN" }),
        type: "bar",
        stack: "stack",
        itemStyle: {
          color: lightPurple
        },
        data: Data.map((t) => {
          if (t.PipelineStatus.Status === DealStatusTypes.open)
            return {
              value: t.Total,
              label: {
                show: t.Total > (MaxValue - MinValue) / 9,
                position: "insideLeft",
                fontWeight: "bold",
                color: darkPurple
              }
            };
          else
            return {
              value: 0,
              label: {
                show: false,
                position: "insideLeft",
                fontWeight: "bold",
                color: darkPurple
              }
            };
        }).reverse()
      },
      {
        // tooltip: {
        //   show: enableTooltips,
        //   trigger: "item",
        //   formatter: "{a}: {c}",
        //   axisPointer: {
        //     type: "shadow"
        //   },
        //   renderMode: "richText",
        //   textStyle: {
        //     // fontWeight: "500"
        //   },
        //   backgroundColor: "#002B63"
        // },
        name: intl.formatMessage({ id: "SALES" }),
        type: "bar",
        stack: "stack",
        itemStyle: {
          color: green
        },
        data: Data.map((t) => {
          if (t.PipelineStatus.Status === DealStatusTypes.won)
            return {
              value: t.Total,
              label: {
                show: t.Total > (MaxValue - MinValue) / 9,
                position: "insideLeft",
                fontWeight: "bold",
                color: darkgreen
              }
            };
          else
            return {
              value: 0,
              label: {
                show: false,
                position: "insideLeft",
                fontWeight: "bold",
                color: darkgreen
              }
            };
        }).reverse()
      }
    ]
  };
});

export const LostReasonCounter = createChartComponent((Data, intl) => {
  const AllValues = Data.map((t) => t.Total);
  const MaxValue = AllValues.reduce((a, b) => {
    return Math.max(a, b);
  });
  const MinValue = AllValues.reduce((a, b) => {
    return Math.min(a, b);
  });

  const enableTooltips = MaxValue - MinValue > 10;

  return {
    tooltip: {
      show: enableTooltips,
      trigger: "item",
      formatter: "{a}: {c}",
      axisPointer: {
        type: "shadow"
      },
      renderMode: "richText",
      textStyle: {
        color: "#FFFFFF"
        // fontWeight: "500"
      },
      backgroundColor: "#002B63"
    },
    legend: {
      show: false,
      left: "left",
      selectedMode: false,
      borderRadius: 3,
      icon: "roundRect",
      textStyle: {
        color: darkgrey,
        backgroundColor: lightred
      },
      data: [
        {
          name: intl.formatMessage({ id: "LOSES" }),
          textStyle: {
            color: darkred,

            backgroundColor: "#fff",
            borderRadius: 3
          }
        }
      ]
    },
    grid: {
      right: "0%",
      bottom: "0%",
      top: "3%",
      left: "0%",
      containLabel: true
    },
    xAxis: {
      type: "value",
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      splitLine: {
        show: false
      }
    },
    yAxis: [
      {
        name: "",
        type: "category",
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },

        axisLabel: {
          show: false
        },
        data: Data.map((t) => {
          return {
            value: t?.Name,
            textStyle: {
              color: "#831F12"
            }
          };
        }).reverse()
      }
    ],
    series: [
      {
        barCategoryGap: "60%",
        clip: false,
        name: intl.formatMessage({ id: "LOSES" }),
        type: "bar",
        stack: "stack2",
        // padding: [0, 0, 0, 0],
        itemStyle: {
          color: red
        },
        data: Data.map((t, i) => {
          return {
            value: t.Total,
            label: {
              show: true,
              padding: t.Total !== 0 ? [10, 0, 0, 0] : [10, 0, 0, 0],
              position: [0, -30],
              // align: t.Total !== 0 ? "left" : "left",
              formatter: `${t?.Name}`,
              fontSize: 13,
              color: darkred
            }
          };
        }).reverse()
      },
      {
        clip: false,
        barGap: "-100%",
        name: intl.formatMessage({ id: "LOSES" }),
        type: "bar",
        stack: "stack",
        // padding: [10, 0, 10, 0],
        itemStyle: {
          color: red
        },
        data: Data.map((t) => {
          return {
            value: t.Total,
            label: {
              show: t.Total > (MaxValue - MinValue) / 9,
              position: "insideLeft",
              fontWeight: "bold",
              color: darkred
            }
          };
        }).reverse()
      }
    ]
  };
});
