import React, { useEffect, useState, useContext } from "react";

const GoogleMapsContext = React.createContext();

export const GoogleMapsProvider = ({ children }) => {
  const [googleMaps, setGoogleMaps] = useState(window.google);

  useEffect(() => {
    window.onGoogleMapsInit = () => {
      setGoogleMaps(window.google);
    };
  }, []);

  return (
    <GoogleMapsContext.Provider value={googleMaps}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);
