import * as SpaceActions from "../../Actions/Space";

//set initial auth state by verifying if token exists
const initialAuthState = {
  isProcessing: false,
  error: null
};

function spaceSetupPageReducer(state = initialAuthState, action) {
  switch (action.type) {
    case SpaceActions.CREATE_SPACE_REQUEST:
      return {
        ...state,
        isProcessing: true
      };

    case SpaceActions.CREATE_SPACE_SUCCESS:
      return {
        ...state,
        isProcessing: false
      };

    case SpaceActions.CREATE_SPACE_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: action.error
      };

    case SpaceActions.REMOVE_SPACE_SETUP_ERROR:
      return {
        ...state,
        error: null
      };

    case SpaceActions.ASSIGN_CURRENT_SPACE:
			
      return {
        ...state,
        AssignedSpace: action.assignedSpace
      };

    default:
      return state;
  }
}

export default spaceSetupPageReducer;
