import { useEffect } from "react";
import { sendSignalRSimple } from "./RealTime";
import { useSpaceEntitiesHub } from "./RealTimeSpace";

export const RealTimePageEnum = {
  Client: 1,
  Contact: 2,
  Deal: 3,
  Contract: 4,
  Call: 5,
  Project: 6,
  Ticket: 7,
  ProjectTask: 8,
  TimeCharge: 9,
  Subscriptions: 10,
  Classification: 11,
  Billing: 12,
  Priority: 13,
  Posts: 14,
  ReportingTimeCharge: 15,
  ReportingTicket: 16,
  ReportingProjectTask: 17,
  ReportingDeal: 18,
  ReportingClientContract: 19,
  ReportingProject: 20,
  ReportingClassification: 21,
  Settings: 22,
  Profile: 23,
  DeveloperUsers: 24,
  DeveloperCache: 25,
  DeveloperLogs: 26,
  DeveloperMaintenance: 27,
  Dashboard: 28,
  Import: 29,
  Reception: 30,
  EmailSignature: 31,
  Automation: 32,
  Documents: 33
};

export const RealTimePageProvider = ({ children, pageType }) => {
  const entitiesHub = useSpaceEntitiesHub();

  useEffect(() => {
    if (!entitiesHub) return;

    let hasLeft = false;
    let hasConnected = false;

    sendSignalRSimple(entitiesHub, "JoinPage", pageType, () => {
      hasConnected = true;
      if (hasLeft) {
        try {
          sendSignalRSimple(entitiesHub, "LeavePage", pageType);
        } catch (e) {}
      }
    });

    // entitiesHub.invoke("JoinPage", pageType).done(() => {
    //   hasConnected = true;
    //   if (hasLeft) {
    //     try {
    //       entitiesHub.invoke("LeavePage", pageType);
    //     } catch (e) {}
    //   }
    // });
    //   .fail(() => {
    //     if (isValid) {
    //       setLoading(false);
    //       setReconnect(() => connector);
    //       // console.log("failed to join room");
    //     }
    //   });

    const listener = () => {
      setTimeout(() => {
        sendSignalRSimple(entitiesHub, "JoinPage", pageType, () => {
          hasConnected = true;
          if (hasLeft) {
            try {
              sendSignalRSimple(entitiesHub, "LeavePage", pageType);
            } catch (e) {}
          }
        });
      }, 100);
    };

    entitiesHub.on("RunEverythingAgain", listener);

    return () => {
      entitiesHub.off("RunEverythingAgain", listener);
      hasLeft = true;
      if (hasConnected) {
        try {
          sendSignalRSimple(entitiesHub, "LeavePage", pageType);
          //  entitiesHub?.invoke("LeavePage", pageType);
        } catch (e) {}
      }
    };
  }, [entitiesHub, pageType]);

  return children;
};
