import { faCodeMerge } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Button from "../../../Components/Button/Button";
// import { DeleteButtonContextMenu } from "../../../Components/CGrid/DeleteMultipleModal";
import { EntityPopup } from "../../../Components/EntityPopup/EntityPopup";
import { ColorFilterBars } from "../../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { LabelTitle } from "../../../Helpers/GridHelper";
import { AutomationClipboardButton } from "../../../Helpers/HooksHelper";
import { AutomationFormButton } from "../../Form/FormButtons";

export const defaultAutomationForm = ({ user, organizations }) => {
  return {
    Company: Array.isArray(organizations) ? organizations[0] : "",
    AssignedTo: user.Id,
    Description: "",
    EnableSchedule: false,
    IsActive: false,
    Simple: true,
    Name: ""
  };
};

export const ValidateAutomationForm = ({ Name, Description }, addError) => {
  if (!Name) addError("Name");
  if (!Description) addError("Description");
};

const AutomationEntityActionsChildren = ({ entity }) => {
  return (
    <>
      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      <AutomationFormButton id={entity.Id} duplicate />
      <AutomationClipboardButton id={entity.Id} />
      {/* <DeleteButtonContextMenu entity={{ ...entity }} /> */}
    </>
  );
};

const AutomationEntityActionsButton = ({ minimal, entity }) => {
  return (
    <EntityPopup minimal={minimal}>
      <AutomationEntityActionsChildren minimal={minimal} entity={entity} />
    </EntityPopup>
  );
};

export const AutomationButtonGroup = ({
  entity,
  hideBreadCrumbs,
  minimal,
	skeleton,
  noColors
}) => {
  return (
    <div className="d-flex align-items-center">
      <AutomationEditButton id={entity.Id} />
      <AutomationFormButton
        id={entity.Id}
        canEdit={entity.CanEdit}
    		className="mx-2 FormButtonClassID"
        minimal={minimal}
				skeleton={skeleton}
		
      />
      <AutomationEntityActionsButton minimal={minimal} entity={entity} />
      {!noColors && <ColorFilterBars entity={entity} />}
    </div>
  );
};

const AutomationEditButton = ({ id }) => {
  const url = `automation/${id}`;
  return (
    <Link to={url}>
      <Button
        vType="primary-ghost"
        size="sm"
        className="d-flex align-items-center justify-content-center"
      >
        <FontAwesomeIcon icon={faCodeMerge} />
      </Button>
    </Link>
  );
};
