import { faEdit } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSpace } from "../../Contexts/SpaceContext";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import {
  canUserEditModule,
  hasAtLeastOneConfigurationModuleAccess,
  moduleTypes
} from "../../Helpers/ModulesHelper";
import Button from "../Button/Button";
import { useSidebar } from "../Sidebar/SidebarV2";

export const isOrganzationRequestValid = ({ error, data }) => {
  if ((data && data.length === 0) || Boolean(error)) return false;

  return true;
};

const OrganizationRequestSideErrorMessage = ({ requestState }) => {
  const { data, error } = requestState;

  const accountSpace = useCurrentAccountSpace();

  const CompanyPermission = canUserEditModule(
    accountSpace,
    moduleTypes.settingsOrganizations
  );
  // eslint-disable-next-line no-unused-vars
  const [openSidebar, closeSidebar] = useSidebar();
  const space = useSpace();
  const history = useHistory();
  const hasSettingsAcess = hasAtLeastOneConfigurationModuleAccess(accountSpace);
  if (error && error.status === 403) {
    return <FormattedMessage id="ORGANIZATION_REQUEST_NO_PERMISSION" />;
  } else if (data && data.length === 0) {
    if (CompanyPermission && hasSettingsAcess) {
      return (
        <div className="d-flex flex-column align-items-center">
          <div className="mb-3">
            <FormattedMessage id="ORGANIZATION_REQUEST_NO_DATA" />
          </div>
          <Button
            onClick={() => {
              history.push(`/s/${space.Id}/settings/companies`);
              closeSidebar();
            }}
            vType="primary"
            type="button"
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faEdit} />
              <span className="ml-2">
                <FormattedMessage id="ADD_COMPANY" />
              </span>
            </div>
          </Button>
        </div>
      );
    } else {
      return (
        <FormattedMessage id="ORGANIZATION_REQUEST_NO_DATA_NO_PERMISSION" />
      );
    }
  } else return <FormattedMessage id="ORGANIZATION_REQUEST_GENERAL_ERROR" />;
};

export const OrganizationRequestSideError = (props) => {
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <OrganizationRequestSideErrorMessage {...props} />
    </div>
  );
};
