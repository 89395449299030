import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileImage,
  faFilePdf,
  faFileExcel,
  faFileWord,
  faEnvelope,
  faFile,
  faFileVideo
} from "@fortawesome/pro-light-svg-icons";

export const getFileExtension = (file) => {
  return file.name.match(/.[\w]+$/g)[0];
};

export const FileIcon = ({ extension, ...rest }) => {
  let icon;
  let color;
  switch (extension) {
    case ".svg":
    case ".jpg":
    case ".png":
    case ".gif":
    case ".jpeg":
    case ".webp":
      icon = faFileImage;
      color = "#1e73f0";
      break;

    case ".pdf":
      icon = faFilePdf;
      color = "#e10707";
      break;

    case ".xlsx":
    case ".xlam":
    case ".csv":
    case ".xls":
    case ".xlr":
      icon = faFileExcel;
      color = "#1e6e42";
      break;

    case ".docx":
    case ".dotx":
    case ".doc":
      icon = faFileWord;
      color = "#295394";
      break;

    case ".msg":
    case ".eml":
      icon = faEnvelope;
      color = "#212529";
      break;
    case ".webm":
    case ".mp4":
      icon = faFileVideo;
      color = "#212529";
      break;
    default:
      icon = faFile;
      color = "#212529";
      break;
  }

  return <FontAwesomeIcon style={{ color }} icon={icon} {...rest} />;

  //   return /.svg$|.jpg$|.png$|.gif$|.jpeg$/.test(extension) ? (
  //     <FontAwesomeIcon icon={faFileImage} />
  //   ) : /.pdf$/.test(extension) ? (
  //     <FontAwesomeIcon icon={faFilePdf} />
  //   ) : /.xlsx$|.xml$|.xlam$|.csv$|.xls$|.xlr$/.test(extension) ? (
  //     <FontAwesomeIcon icon={faFileExcel} />
  //   ) : /.docx$|.doc$/.test(extension) ? (
  //     <FontAwesomeIcon icon={faFileWord} />
  //   ) : /.msg$|.eml$/.test(extension) ? (
  //     <FontAwesomeIcon icon={faEnvelope} />
  //   ) : (
  //     <FontAwesomeIcon icon={faFile} />
  //   );
};

const readEntryContentAsync = async (entry) => {
  return new Promise((resolve, reject) => {
    let reading = 0;
    const contents = [];

    var readReaderContent = (reader) => {
      reading++;

      reader.readEntries(function (entries) {
        reading--;
        for (const entry of entries) {
          readEntry(entry);
        }

        if (reading === 0) {
          resolve(contents);
        }
      });
    };

    var readEntry = (entry) => {
      if (entry.isFile) {
        reading++;
        entry.file((file) => {
          reading--;
          contents.push(file);

          if (reading === 0) {
            resolve(contents);
          }
        });
      } else if (entry.isDirectory) {
        readReaderContent(entry.createReader());
      }
    };

    readEntry(entry);
  });
};

export const getFilesAsync = async (dataTransfer) => {
  const files = [];
  for (let i = 0; i < dataTransfer.items.length; i++) {
    const item = dataTransfer.items[i];

    if (item.kind === "file") {
      if (typeof item.webkitGetAsEntry === "function" && item.type === "") {
        const entry = item.webkitGetAsEntry();
        const entryContent = await readEntryContentAsync(entry);
        files.push(...entryContent);

        continue;
      }

      const file = item.getAsFile();
      if (file) {
        files.push(file);
      }
    }
  }

  return files;
};

export const download_file = (fileURL, fileName) => {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_self";

    save.download = fileName;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, "_blank");
    _window.document.close();
    _window.document.execCommand("SaveAs", true, fileName || fileURL);
    _window.close();
  }
};
