import React from "react";
import classnames from "classnames";
import "./TypingLoader.css";

const TypingLoader = ({ className }) => {
  return (
    <div className={classnames("ar-typing-loader-container", className)}>
      <div className="ar-typing-loader" />
    </div>
  );
};

export default TypingLoader;
