import React, { useContext, useMemo } from "react";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { Tr } from "../../Components/Table";
import { useTask } from "../../Hooks/EntityHooks";
import { spaceTasksSchema } from "../../config/schema";
import RelativeTime from "../../Components/RelativeTime/RelativeTime";
import {
  MilSecToFormatedHourMinute,
  MilSecToTime
} from "../../Helpers/MiscHelper";
import {
  DashboardTable,
  DashboardTitle,
  DashboardEmptyResponse,
  ReportsDateProvider
} from "./Dashboard";
import { faListCheck } from "@fortawesome/pro-solid-svg-icons";
import { TaskFormButton } from "../Form/FormButtons";
import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
import { TaskEntityGridButtons } from "../Tasks/TasksHelper";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import { TasksLink } from "../../Helpers/HooksHelper";
import { FormattedMessage } from "react-intl";
import { useDashboardQuery } from "./DashboardQueryHelper";
import { StatusPipelineGridprofile } from "../AdvancedMultiInputs/PipelineDropdowns";

const columns = [
  {
    minWidth: 90,
    width: 90,
    Cell: (value) => <TaskEntityGridButtons entity={value} />
  },
  {
    title: "Nº",
    Cell: ({ Id }) => <TasksLink id={Id}>{Id}</TasksLink>
  },
  {
    title: <FormattedMessage id={"NAME"} />,
    Cell: ({ Name }) => <span className="text-black">{Name}</span>
  },
  {
    title: <FormattedMessage id={"PIPELINE_STATUS"} />,
    Cell: ({ PipelineStatus }) => (
      <StatusPipelineGridprofile status={PipelineStatus} />
    )
  },
  {
    maxWidth: 200,
    title: <FormattedMessage id={"ACCOUNT"} />,
    Cell: ({ Client, IndirectClient }) => (
      <ConnectedClientOrigin client={Client || IndirectClient} />
    )
  },
  // {
  //   title: <FormattedMessage id={"ASSIGNED_TO"} />,
  //   Cell: ({ Manager }) => <ConnectedAccountPopup account={Manager} />,
  // },
  {
    width: 52,
    title: <FormattedMessage id={"ESTIMATED_HOUR"} />,
    Cell: ({ EstimatedCost }) => MilSecToFormatedHourMinute(EstimatedCost)
  },
  {
    title: <FormattedMessage id={"DEADLINE"} />,
    Cell: ({ EndDate }) => <RelativeTime date={EndDate} />
  }
];

const TaskTr = ({ value, ...rest }) => {
  const task = useTask(value);

  return <Tr value={task} {...rest} />;
};

const DashboardTaskOverview = (props) => {
  const { ...DataReports } = useContext(ReportsDateProvider);

  const { data, loading, error } = useDashboardQuery(
    `dashboard/tasks?todate=${
      DataReports[0] === "Tasks" ? DataReports[1].toDateString : ""
    }&fromdate=${
      DataReports[0] === "Tasks" ? DataReports[1].fromDateString : ""
    }${
      DataReports[0] === "Tasks"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`,
    [spaceTasksSchema],
    { cache: false }
  );

  const buildBody = useMemo(() => {
    if (loading || error)
      return <LoaderSpinner size="sm text-secondary" center />;

    if (data?.length === 0)
      return (
        <DashboardEmptyResponse type="tasks">
          <FormattedMessage id={"EMPTY_TASKS"} />
        </DashboardEmptyResponse>
      );

    return (
      <DashboardTable
        className="ar-dashboard-table"
        TrComponent={TaskTr}
        columns={columns}
        data={data}
      />
    );
  }, [data, error, loading]);

  return (
    <React.Fragment>
      <DashboardTitle
        text={<FormattedMessage id={"SCHEDULE_TASKS"} />}
        icon={faListCheck}
        actionButton={<TaskFormButton vType="primary-ghost" />}
      />
      {buildBody}
    </React.Fragment>
  );
};

export default DashboardTaskOverview;
