import { useMemo } from "react";
import { useSpaceQuery } from "../../Helpers/IOClient";
import {
  pLinkType,
  SocialNetworkType
} from "../Settings/Organizations/OrganizationForm";

export const useHandleTagReplaces = (form) => {
  const {
    Signature,
    AppliedToUser,
    ApplyToOption,
    BannerUrl,
    Banner,
    Logo,
    LogoUrl
  } = form;

  let NewSignature = Signature || " ";

  const dataFetchQueryString = useMemo(() => {
    const { optionSchema, optionId } = ApplyToOption || {};
    switch (optionSchema && optionSchema.name) {
      case "SpaceOrganizations":
        return `EmailSignatures/KeysData?CompanyId=${optionId}`;

      case "SpaceDepartments":
        return `EmailSignatures/KeysData?DepartmentId=${optionId}`;

      case "SpaceTeams":
        return `EmailSignatures/KeysData?TeamId=${optionId}`;

      case "Accounts":
        return `EmailSignatures/KeysData/?CompanyId=`;
      default:
        return "";
    }
  }, [ApplyToOption]);

  const { data } = useSpaceQuery(
    AppliedToUser && dataFetchQueryString !== ""
      ? `${dataFetchQueryString}&UserId=${AppliedToUser}`
      : null
  );

  const { optionSchema } = ApplyToOption || {};
  if (data) {
    switch (optionSchema && optionSchema.name) {
      case "SpaceOrganizations":
        NewSignature = CompanyApplied(data, NewSignature, form);
        break;
      case "SpaceDepartments":
        NewSignature = DepartmentApplied(data, NewSignature, form);
        break;
      case "SpaceTeams":
        NewSignature = TeamApplied(data, NewSignature, form);
        break;
      case "Accounts":
        NewSignature = UserApplied(data, NewSignature, form);
        break;
      default:
        break;
    }
  }

  if (LogoUrl) {
    NewSignature = NewSignature.replace(
      /\{\{signature.logo\}\}/g,
      `<a
  		href="${LogoUrl}"
  		rel="noopener noreferrer"
  		target="_blank"
  		><img style="max-width:180px;max-height:40px;" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAoCAMAAABgtyA4AAAAAXNSR0IArs4c6QAAAJNQTFRFAAAA////8/n/8/f/9Pf/9fj/8/f98/f99Pf99Pj99Pf99Pf89Pb99Pf99Pb98/b99Pf99Pf91d7o2ODq2eHr2+Pt3eXu3uTu3+fw4ejw4+rx4+ry5Ory5evz5uv06O316u/26/H37PH37vL58PT68fX88/b98/f89Pb89Pf89Pf99Pf+9Pj99ff99ff+9fj99fj+6P0+AwAAABJ0Uk5TAAMqQENJgIOGiJy4zeDo7/3+9Hmm/AAAAThJREFUWMPt2NtugkAQBuDfU7WiiGNZu60Vl4OHbVHf/+l6ISLVRryYkJ3E/3Ynux+TCZAF0BtNrJhMRj0ArcHUisq030LHSkvQhce01T6vzZ7pKA8+yz759pGq7YHlMB88D79jLasLDzpnL2wA/c1e2BzaaKWNMHRERERaFNrQKStJaF2glSR0WKBJElr9i84WibjxyEKaJQ6j4wIdV1cUEb1lzr/yltWFU/fDzOGPS/ox1/GtuaJ2EH2ds/midh+9oEuU0+jP9/MoRFSNdhity6b+NRMZZ9HLsqnXZnfRUTkKhqSgy+7qZCYFfTMRAtApCUSbJ/qJvjPTX3eTCvlhEnHvIQtt16xlDaF/HivLmdA8V732uKmv2Rx5zvLZLtUbjIduIM0cdIBXaegXAGgPx3LE42Eb+AUwjXMH7REgPQAAAABJRU5ErkJggg=='"
  		src="${Logo && typeof Logo === "object" ? Logo.image : Logo}"
  		alt=""
  		/></a>`
    );
  } else {
    NewSignature = NewSignature.replace(
      /\{\{signature.logo\}\}/g,
      `<img style="max-width:180px;max-height:40px;" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAoCAMAAABgtyA4AAAAAXNSR0IArs4c6QAAAJNQTFRFAAAA////8/n/8/f/9Pf/9fj/8/f98/f99Pf99Pj99Pf99Pf89Pb99Pf99Pb98/b99Pf99Pf91d7o2ODq2eHr2+Pt3eXu3uTu3+fw4ejw4+rx4+ry5Ory5evz5uv06O316u/26/H37PH37vL58PT68fX88/b98/f89Pb89Pf89Pf99Pf+9Pj99ff99ff+9fj99fj+6P0+AwAAABJ0Uk5TAAMqQENJgIOGiJy4zeDo7/3+9Hmm/AAAAThJREFUWMPt2NtugkAQBuDfU7WiiGNZu60Vl4OHbVHf/+l6ISLVRryYkJ3E/3Ynux+TCZAF0BtNrJhMRj0ArcHUisq030LHSkvQhce01T6vzZ7pKA8+yz759pGq7YHlMB88D79jLasLDzpnL2wA/c1e2BzaaKWNMHRERERaFNrQKStJaF2glSR0WKBJElr9i84WibjxyEKaJQ6j4wIdV1cUEb1lzr/yltWFU/fDzOGPS/ox1/GtuaJ2EH2ds/midh+9oEuU0+jP9/MoRFSNdhity6b+NRMZZ9HLsqnXZnfRUTkKhqSgy+7qZCYFfTMRAtApCUSbJ/qJvjPTX3eTCvlhEnHvIQtt16xlDaF/HivLmdA8V732uKmv2Rx5zvLZLtUbjIduIM0cdIBXaegXAGgPx3LE42Eb+AUwjXMH7REgPQAAAABJRU5ErkJggg=='"
  			src="${Logo && typeof Logo === "object" ? Logo.image : Logo}"
  			alt=""
  		/>`
    );
  }

  if (BannerUrl) {
    NewSignature = NewSignature.replace(
      /\{\{signature.banner\}\}/g,
      `<a
  			href="${BannerUrl}"
  			rel="noopener noreferrer"
  			target="_blank"
  		><img
  			src="${
          Banner && typeof Banner === "object" ? Banner.image : Banner
        }" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAABkCAMAAABHGTJPAAAAAXNSR0IArs4c6QAAAJNQTFRFAAAA////8/n/8/f/9Pf/9fj/8/f98/f99Pf99Pj99Pf99Pf89Pb99Pf99Pb98/b99Pf99Pf91d7o2ODq2eHr2+Pt3eXu4ejw4+rx4+ry5Ory5evz5uv06O316u/26/H37PH37vL58PT68fX88/b88/b98/f88/f99Pb89Pb99Pf89Pf99Pj99Pj+9ff99fj99fj+UAvPrgAAABJ0Uk5TAAMqQENJgIOGiJy4zeDo7/3+9Hmm/AAAAfxJREFUeNrt3EtT2zAUgFHx6oNACAohrYOh1KQPQoH//+u6IBOczsSdhA3WPd/em3vGsuSFUkpHJ2cLBens5CiltPfp3Cgidf5xLx0YQ7BGh2lgCtEapKEhRGuYzCBe0KELuqALuqALuqALuqALuqALuqALuqALuqBDF3RBF3RBf6c9LubbP3QPvdfmc691NPQ/Oz/5G3pfu3vLdwF6uB6gx+sn9P53XVU30EOhV+Occx7X0OOgT/OyKfQo6FVedQ09Bnrzap4voMdAr1vo+QZ6CPSqjX4FHfrLee4WemFd/W95n+ZcQy+r7y3z8aYDXQ29rL52ru7LQ3wNvawmHT9nVj9uauhl9eVlbZ91mP+jDr3/NfVsVjed5uvq0IttzXxNHXoQ87Y69CjmLXXohVTny6bb/FUdeinmOU+abvOcoZdm3lLfYA69PPOV+iZz6AWaL9U3mkMv0TznSdNhDr1I85wnHebQyzTvDno8c+iFdAkdOnTo0KFDhw69n91+2yLocYMOHXpvesOFYs/Qe9ruF4PNn6D3tN3lir5RrPQbI3/t9p6XbV78Ru5x8WPb7h4KN7d7jxh06IIu6IIu6IIu6IIu6IIu6IIu6IIu6IIu6NAFXdBVDvrQDKI1TANDiNYgHY5MIVajg5Q+G0OsPqSU0v7xqUlE6fR4P6W/T9R5nL3bWEEAAAAASUVORK5CYII='"
  			alt="banner"
				border="0"
				style="max-width: 5.8645in; max-height: 1.9375in"
  		/></a>`
    );
  } else {
    NewSignature = NewSignature.replace(
      /\{\{signature.banner\}\}/g,
      `<img
  			src="${Banner && typeof Banner === "object" ? Banner.image : Banner}" 
				onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAABkCAMAAABHGTJPAAAAAXNSR0IArs4c6QAAAJNQTFRFAAAA////8/n/8/f/9Pf/9fj/8/f98/f99Pf99Pj99Pf99Pf89Pb99Pf99Pb98/b99Pf99Pf91d7o2ODq2eHr2+Pt3eXu4ejw4+rx4+ry5Ory5evz5uv06O316u/26/H37PH37vL58PT68fX88/b88/b98/f88/f99Pb89Pb99Pf89Pf99Pj99Pj+9ff99fj99fj+UAvPrgAAABJ0Uk5TAAMqQENJgIOGiJy4zeDo7/3+9Hmm/AAAAfxJREFUeNrt3EtT2zAUgFHx6oNACAohrYOh1KQPQoH//+u6IBOczsSdhA3WPd/em3vGsuSFUkpHJ2cLBens5CiltPfp3Cgidf5xLx0YQ7BGh2lgCtEapKEhRGuYzCBe0KELuqALuqALuqALuqALuqALuqALuqALuqBDF3RBF3RBf6c9LubbP3QPvdfmc691NPQ/Oz/5G3pfu3vLdwF6uB6gx+sn9P53XVU30EOhV+Occx7X0OOgT/OyKfQo6FVedQ09Bnrzap4voMdAr1vo+QZ6CPSqjX4FHfrLee4WemFd/W95n+ZcQy+r7y3z8aYDXQ29rL52ru7LQ3wNvawmHT9nVj9uauhl9eVlbZ91mP+jDr3/NfVsVjed5uvq0IttzXxNHXoQ87Y69CjmLXXohVTny6bb/FUdeinmOU+abvOcoZdm3lLfYA69PPOV+iZz6AWaL9U3mkMv0TznSdNhDr1I85wnHebQyzTvDno8c+iFdAkdOnTo0KFDhw69n91+2yLocYMOHXpvesOFYs/Qe9ruF4PNn6D3tN3lir5RrPQbI3/t9p6XbV78Ru5x8WPb7h4KN7d7jxh06IIu6IIu6IIu6IIu6IIu6IIu6IIu6IIu6NAFXdBVDvrQDKI1TANDiNYgHY5MIVajg5Q+G0OsPqSU0v7xqUlE6fR4P6W/T9R5nL3bWEEAAAAASUVORK5CYII='"
  			alt="banner"
				border="0"
				style="max-width: 5.8645in; max-height: 1.9375in"
  		>`
    );
  }

  return NewSignature;
};

const CompanyApplied = (data, signature, form) => {
  const { Company, User } = data;
  const {
    Address1,
    Address2,
    Email,
    Name,
    PhoneNumber,
    Website,
    Image,
    FiscalNumber,
    SocialNetworks,
    Industry,
    ReportImage
  } = Company;
  const {
    Name: UserName,
    Email: UserEmail,
    PhoneExtension,
    Image: UserImage,
    CollaboratorNumber,
    RoleName,
    Departments,
    Teams,
    PhoneNumber: UserPhoneNumber
  } = User;

  let NewSignature = `${signature}`;

  if (Array.isArray(SocialNetworks)) {
    for (const e of SocialNetworks) {
      const type = SocialNetworkType(e.Url);

      switch (type) {
        case pLinkType.Facebook:
          NewSignature = NewSignature.replace(
            /\{\{company.facebook\}\}/g,
            `${e.Url || ""}`
          );
          break;
        case pLinkType.Twitter:
          NewSignature = NewSignature.replace(
            /\{\{company.twitter\}\}/g,
            `${e.Url || ""}`
          );
          break;
        case pLinkType.Instagram:
          NewSignature = NewSignature.replace(
            /\{\{company.instagram\}\}/g,
            `${e.Url || ""}`
          );
          break;
        case pLinkType.Linkedin:
          NewSignature = NewSignature.replace(
            /\{\{company.linkedin\}\}/g,
            `${e.Url || ""}`
          );
          break;
        case pLinkType.Youtube:
          NewSignature = NewSignature.replace(
            /\{\{company.youtube\}\}/g,
            `${e.Url || ""}`
          );
          break;
        default:
          break;
      }
    }
  }

  NewSignature = NewSignature.replace(/\{\{company.name\}\}/g, `${Name || ""}`);
  NewSignature = NewSignature.replace(
    /\{\{company.phone_number\}\}/g,
    `${PhoneNumber?.Contact?.replace(".", " ") || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.website\}\}/g,
    `${Website || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.vat\}\}/g,
    `${FiscalNumber || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.industries\}\}/g,
    `${Industry || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{company.logo\}\}/g,
    `<img onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAoCAMAAABgtyA4AAAAAXNSR0IArs4c6QAAAJNQTFRFAAAA////8/n/8/f/9Pf/9fj/8/f98/f99Pf99Pj99Pf99Pf89Pb99Pf99Pb98/b99Pf99Pf91d7o2ODq2eHr2+Pt3eXu3uTu3+fw4ejw4+rx4+ry5Ory5evz5uv06O316u/26/H37PH37vL58PT68fX88/b98/f89Pb89Pf89Pf99Pf+9Pj99ff99ff+9fj99fj+6P0+AwAAABJ0Uk5TAAMqQENJgIOGiJy4zeDo7/3+9Hmm/AAAAThJREFUWMPt2NtugkAQBuDfU7WiiGNZu60Vl4OHbVHf/+l6ISLVRryYkJ3E/3Ynux+TCZAF0BtNrJhMRj0ArcHUisq030LHSkvQhce01T6vzZ7pKA8+yz759pGq7YHlMB88D79jLasLDzpnL2wA/c1e2BzaaKWNMHRERERaFNrQKStJaF2glSR0WKBJElr9i84WibjxyEKaJQ6j4wIdV1cUEb1lzr/yltWFU/fDzOGPS/ox1/GtuaJ2EH2ds/midh+9oEuU0+jP9/MoRFSNdhity6b+NRMZZ9HLsqnXZnfRUTkKhqSgy+7qZCYFfTMRAtApCUSbJ/qJvjPTX3eTCvlhEnHvIQtt16xlDaF/HivLmdA8V732uKmv2Rx5zvLZLtUbjIduIM0cdIBXaegXAGgPx3LE42Eb+AUwjXMH7REgPQAAAABJRU5ErkJggg=='"
  			src="${ReportImage || ""}"
  			alt=""
  		/>`
  );

  NewSignature = NewSignature.replace(
    /\{\{company.email\}\}/g,
    `${Email || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.address1\}\}/g,
    `${Address1 || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.address2\}\}/g,
    `${Address2 || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{company.avatar\}\}/g,
    `<img style="
			width: 48px;
			height: 48px;
			border-radius: 32px;
			" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA+CAMAAACr3TNIAAAAAXNSR0IArs4c6QAAAYlQTFRFAAAA////////////////////////////////////////9P//9f//9/f/9/f/+Pj/+Pj/+Pj/+fn/8/n/8/n/9Pn/9Pr/9Pr/9vb/8/f/9Pf/9Pj/9Pj/9fj/9fj/9fn/9vn/9vn/8/b88/b89Pf89Pf89Pf89fj98/j98/j98/j99Pj99Pb99Pb99Pb99Pf99Pj99Pj99Pb98/f98/f98/f99Pf99Pf99Pf99Pf99Pf99Pf99Pf89Pf89Pf88/f88/f88/f88/f88/f88/b89Pb89Pb99Pf99Pf98/f98/f98/f98/f98/f98/f99Pf99Pf99Pf99Pb99Pb98/b98/f98/f99Pf99Pf99Pf99Pf99Pf91d7o1t7o19/p1+Dp1+Hq2ODp2OHq2eHr2uLr2+Ps2+Ts3eTt3eXu3uXv3ubv3+bv4Ofv4ejw4enw4+nx5Ory5Ovz5uz06O316e726u/26+/27PD37fL47vH57vP58PT78fT78fX88vX88vb88/b98/f99Pf9rVjHjQAAAFx0Uk5TAAECBAUJDQ4REhQYGR8gJCYnKCssLi8wOEBDRUhKTE9TVFZYWl1haWprbHBzdHV4iIyQl5mboKGjpKWotre4wMHDxMXHy8zS09bX2Nna3N/h4uXm7/Dx9fb3+fwJXXSrAAACc0lEQVQYGaXBi0NLYRwG4BeTXCOXGbm15FJkobB0IuZSkVit9lUz2lw2Num6kb1/OcnsfDu/7+wcngcGzZ2R/rux8QQT47GB/khnM3xovTqUpC451N0Cby49o2zsAhra1j5Ks9Gz2+DqZIzuHp6AWeAmG7segMHeB/RieDdER1/Sm+dBCNoT9GqqAw7n6Ec76hxL0I9EEJo9L+jPiz2w2XGfft3bgZqb9O8G/mqj2TeahFBlUVYuvHutXr8rlCmx8EeIsq/zasv8V0pC2GJRlFM1OQos/BaiaFHZLVIQwiaLktKcspsr0cnCL00zlHxSuk90mtkFIEzRgtItUBAGEKWkMqt0sxU69QGBSUrWVb11Ok0AIYo2VL0NCo6ji7K3SveWki70UpZTuhwl1xClbE3p1ijpg0WDgrIrUGRhhAaVjKrJVCgaQZwmPz6rqs8/KIsjTrPV9ymlVOr9Kk3iGKGr8kqZLkZg8X9YiFLwfbn4MZvN5lcqJCsr+Ww2+7G4/J0OUfTSaSmlqtILaVWVWmK9XvTQIa9M8qzTgzbWW1Rmi9S1IRCnrjSnzOZKtJsMAFHqispNkXZRAGHqPig3H2gXBrBrhpq0cpOmzUwTfhmkRrmjzSA2hahJKzdp2oTw2yDtispNkTWD2HKEmi+ZNyaZL6xJHsYfUf6LPlQdmKZ/0/vxVxf964LNLfp1B3Y7n9CfpzuhaX1FP+KHUOc0/TgFh44pejXVAUFwgt5MBCHaN0wvhvfBIHCbjd0OwOz0I7p7fAautp9/TrOx8HY0dPEZZWOX4c3B7qEkdcmhKy3wobkz0j8QG08wMR4b6I90NkP2E9FVstM6GBNQAAAAAElFTkSuQmCC'"
			src="${Image || ""}"
			alt=""
			/>`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.name\}\}/g,
    `${UserName || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.email\}\}/g,
    `${UserEmail || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.extension\}\}/g,
    `${PhoneExtension || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.role\}\}/g,
    `${RoleName || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.phone_number\}\}/g,
    `${UserPhoneNumber?.replace(".", " ") || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.companies\}\}/g,
    `${Name || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.departements\}\}/g,
    `${Departments?.join(", ") || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.teams\}\}/g,
    `${Teams?.map((e) => e.Name).join(", ") || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.collaborator_number\}\}/g,
    `${CollaboratorNumber || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.avatar\}\}/g,
    `<img style="
			width: 48px;
			height: 48px;
			border-radius: 32px;
			margin-right: 10px;
			" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA+CAMAAACr3TNIAAAAAXNSR0IArs4c6QAAAYlQTFRFAAAA////////////////////////////////////////9P//9f//9/f/9/f/+Pj/+Pj/+Pj/+fn/8/n/8/n/9Pn/9Pr/9Pr/9vb/8/f/9Pf/9Pj/9Pj/9fj/9fj/9fn/9vn/9vn/8/b88/b89Pf89Pf89Pf89fj98/j98/j98/j99Pj99Pb99Pb99Pb99Pf99Pj99Pj99Pb98/f98/f98/f99Pf99Pf99Pf99Pf99Pf99Pf99Pf89Pf89Pf88/f88/f88/f88/f88/f88/b89Pb89Pb99Pf99Pf98/f98/f98/f98/f98/f98/f99Pf99Pf99Pf99Pb99Pb98/b98/f98/f99Pf99Pf99Pf99Pf99Pf91d7o1t7o19/p1+Dp1+Hq2ODp2OHq2eHr2uLr2+Ps2+Ts3eTt3eXu3uXv3ubv3+bv4Ofv4ejw4enw4+nx5Ory5Ovz5uz06O316e726u/26+/27PD37fL47vH57vP58PT78fT78fX88vX88vb88/b98/f99Pf9rVjHjQAAAFx0Uk5TAAECBAUJDQ4REhQYGR8gJCYnKCssLi8wOEBDRUhKTE9TVFZYWl1haWprbHBzdHV4iIyQl5mboKGjpKWotre4wMHDxMXHy8zS09bX2Nna3N/h4uXm7/Dx9fb3+fwJXXSrAAACc0lEQVQYGaXBi0NLYRwG4BeTXCOXGbm15FJkobB0IuZSkVit9lUz2lw2Num6kb1/OcnsfDu/7+wcngcGzZ2R/rux8QQT47GB/khnM3xovTqUpC451N0Cby49o2zsAhra1j5Ks9Gz2+DqZIzuHp6AWeAmG7segMHeB/RieDdER1/Sm+dBCNoT9GqqAw7n6Ec76hxL0I9EEJo9L+jPiz2w2XGfft3bgZqb9O8G/mqj2TeahFBlUVYuvHutXr8rlCmx8EeIsq/zasv8V0pC2GJRlFM1OQos/BaiaFHZLVIQwiaLktKcspsr0cnCL00zlHxSuk90mtkFIEzRgtItUBAGEKWkMqt0sxU69QGBSUrWVb11Ok0AIYo2VL0NCo6ji7K3SveWki70UpZTuhwl1xClbE3p1ijpg0WDgrIrUGRhhAaVjKrJVCgaQZwmPz6rqs8/KIsjTrPV9ymlVOr9Kk3iGKGr8kqZLkZg8X9YiFLwfbn4MZvN5lcqJCsr+Ww2+7G4/J0OUfTSaSmlqtILaVWVWmK9XvTQIa9M8qzTgzbWW1Rmi9S1IRCnrjSnzOZKtJsMAFHqispNkXZRAGHqPig3H2gXBrBrhpq0cpOmzUwTfhmkRrmjzSA2hahJKzdp2oTw2yDtispNkTWD2HKEmi+ZNyaZL6xJHsYfUf6LPlQdmKZ/0/vxVxf964LNLfp1B3Y7n9CfpzuhaX1FP+KHUOc0/TgFh44pejXVAUFwgt5MBCHaN0wvhvfBIHCbjd0OwOz0I7p7fAautp9/TrOx8HY0dPEZZWOX4c3B7qEkdcmhKy3wobkz0j8QG08wMR4b6I90NkP2E9FVstM6GBNQAAAAAElFTkSuQmCC'"
			src="${UserImage || ""}"
			alt=""
			/>`
  );

  NewSignature = NewSignature.replace(
    /\{\{department.name\}\}/g,
    `${Departments?.Name || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{department.phone_number\}\}/g,
    `${Departments?.PhoneNumber?.Contact?.replace(".", " ") || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{department.email\}\}/g,
    `${Departments?.Email || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{team.name\}\}/g,
    `${Teams?.Name || ""}`
  );

  return NewSignature;
};
const DepartmentApplied = (data, signature, form) => {
  const { Department, User } = data;
  const { Email, Name, PhoneNumber, Company } = Department;
  const {
    Name: UserName,
    Email: UserEmail,
    PhoneExtension,
    Image: UserImage,
    RoleName,
    CollaboratorNumber,
    Companies,
    Departments,
    Teams,
    PhoneNumber: UserPhoneNumber
  } = User;

  let NewSignature = `${signature}`;

  NewSignature = NewSignature.replace(
    /\{\{department.name\}\}/g,
    `${Name || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{department.phone_number\}\}/g,
    `${PhoneNumber?.Contact?.replace(".", " ") || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{department.email\}\}/g,
    `${Email || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.name\}\}/g,
    `${UserName || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.email\}\}/g,
    `${UserEmail || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.extension\}\}/g,
    `${PhoneExtension || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.role\}\}/g,
    `${RoleName || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.phone_number\}\}/g,
    `${UserPhoneNumber?.replace(".", " ") || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.avatar\}\}/g,
    `<img style="
			width: 48px;
			height: 48px;
			border-radius: 32px;
			margin-right: 10px;
			" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA+CAMAAACr3TNIAAAAAXNSR0IArs4c6QAAAYlQTFRFAAAA////////////////////////////////////////9P//9f//9/f/9/f/+Pj/+Pj/+Pj/+fn/8/n/8/n/9Pn/9Pr/9Pr/9vb/8/f/9Pf/9Pj/9Pj/9fj/9fj/9fn/9vn/9vn/8/b88/b89Pf89Pf89Pf89fj98/j98/j98/j99Pj99Pb99Pb99Pb99Pf99Pj99Pj99Pb98/f98/f98/f99Pf99Pf99Pf99Pf99Pf99Pf99Pf89Pf89Pf88/f88/f88/f88/f88/f88/b89Pb89Pb99Pf99Pf98/f98/f98/f98/f98/f98/f99Pf99Pf99Pf99Pb99Pb98/b98/f98/f99Pf99Pf99Pf99Pf99Pf91d7o1t7o19/p1+Dp1+Hq2ODp2OHq2eHr2uLr2+Ps2+Ts3eTt3eXu3uXv3ubv3+bv4Ofv4ejw4enw4+nx5Ory5Ovz5uz06O316e726u/26+/27PD37fL47vH57vP58PT78fT78fX88vX88vb88/b98/f99Pf9rVjHjQAAAFx0Uk5TAAECBAUJDQ4REhQYGR8gJCYnKCssLi8wOEBDRUhKTE9TVFZYWl1haWprbHBzdHV4iIyQl5mboKGjpKWotre4wMHDxMXHy8zS09bX2Nna3N/h4uXm7/Dx9fb3+fwJXXSrAAACc0lEQVQYGaXBi0NLYRwG4BeTXCOXGbm15FJkobB0IuZSkVit9lUz2lw2Num6kb1/OcnsfDu/7+wcngcGzZ2R/rux8QQT47GB/khnM3xovTqUpC451N0Cby49o2zsAhra1j5Ks9Gz2+DqZIzuHp6AWeAmG7segMHeB/RieDdER1/Sm+dBCNoT9GqqAw7n6Ec76hxL0I9EEJo9L+jPiz2w2XGfft3bgZqb9O8G/mqj2TeahFBlUVYuvHutXr8rlCmx8EeIsq/zasv8V0pC2GJRlFM1OQos/BaiaFHZLVIQwiaLktKcspsr0cnCL00zlHxSuk90mtkFIEzRgtItUBAGEKWkMqt0sxU69QGBSUrWVb11Ok0AIYo2VL0NCo6ji7K3SveWki70UpZTuhwl1xClbE3p1ijpg0WDgrIrUGRhhAaVjKrJVCgaQZwmPz6rqs8/KIsjTrPV9ymlVOr9Kk3iGKGr8kqZLkZg8X9YiFLwfbn4MZvN5lcqJCsr+Ww2+7G4/J0OUfTSaSmlqtILaVWVWmK9XvTQIa9M8qzTgzbWW1Rmi9S1IRCnrjSnzOZKtJsMAFHqispNkXZRAGHqPig3H2gXBrBrhpq0cpOmzUwTfhmkRrmjzSA2hahJKzdp2oTw2yDtispNkTWD2HKEmi+ZNyaZL6xJHsYfUf6LPlQdmKZ/0/vxVxf964LNLfp1B3Y7n9CfpzuhaX1FP+KHUOc0/TgFh44pejXVAUFwgt5MBCHaN0wvhvfBIHCbjd0OwOz0I7p7fAautp9/TrOx8HY0dPEZZWOX4c3B7qEkdcmhKy3wobkz0j8QG08wMR4b6I90NkP2E9FVstM6GBNQAAAAAElFTkSuQmCC'"
			src="${UserImage || ""}"
			alt=""
			/>`
  );

  if (Array.isArray(Companies?.SocialNetworks)) {
    for (const e of Companies.SocialNetworks) {
      const type = SocialNetworkType(e.Url);

      switch (type) {
        case pLinkType.Facebook:
          NewSignature = NewSignature.replace(
            /\{\{company.facebook\}\}/g,
            `${e.Url || ""}`
          );
          break;
        case pLinkType.Twitter:
          NewSignature = NewSignature.replace(
            /\{\{company.twitter\}\}/g,
            `${e.Url || ""}`
          );
          break;
        case pLinkType.Instagram:
          NewSignature = NewSignature.replace(
            /\{\{company.instagram\}\}/g,
            `${e.Url || ""}`
          );
          break;
        case pLinkType.Linkedin:
          NewSignature = NewSignature.replace(
            /\{\{company.linkedin\}\}/g,
            `${e.Url || ""}`
          );
          break;
        case pLinkType.Youtube:
          NewSignature = NewSignature.replace(
            /\{\{company.youtube\}\}/g,
            `${e.Url || ""}`
          );
          break;
        default:
          break;
      }
    }
  }

  NewSignature = NewSignature.replace(
    /\{\{user.companies\}\}/g,
    `${Companies?.Name || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.departements\}\}/g,
    `${Departments?.join(", ") || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.teams\}\}/g,
    `${Teams?.map((e) => e.Name).join(", ") || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{company.name\}\}/g,
    `${Company?.Name || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.phone_number\}\}/g,
    `${Company?.PhoneNumber?.Contact?.replace(".", " ") || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.website\}\}/g,
    `${Company?.Website || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.vat\}\}/g,
    `${Company?.FiscalNumber || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.industries\}\}/g,
    `${Company?.Industry || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{company.logo\}\}/g,
    `<img onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAoCAMAAABgtyA4AAAAAXNSR0IArs4c6QAAAJNQTFRFAAAA////8/n/8/f/9Pf/9fj/8/f98/f99Pf99Pj99Pf99Pf89Pb99Pf99Pb98/b99Pf99Pf91d7o2ODq2eHr2+Pt3eXu3uTu3+fw4ejw4+rx4+ry5Ory5evz5uv06O316u/26/H37PH37vL58PT68fX88/b98/f89Pb89Pf89Pf99Pf+9Pj99ff99ff+9fj99fj+6P0+AwAAABJ0Uk5TAAMqQENJgIOGiJy4zeDo7/3+9Hmm/AAAAThJREFUWMPt2NtugkAQBuDfU7WiiGNZu60Vl4OHbVHf/+l6ISLVRryYkJ3E/3Ynux+TCZAF0BtNrJhMRj0ArcHUisq030LHSkvQhce01T6vzZ7pKA8+yz759pGq7YHlMB88D79jLasLDzpnL2wA/c1e2BzaaKWNMHRERERaFNrQKStJaF2glSR0WKBJElr9i84WibjxyEKaJQ6j4wIdV1cUEb1lzr/yltWFU/fDzOGPS/ox1/GtuaJ2EH2ds/midh+9oEuU0+jP9/MoRFSNdhity6b+NRMZZ9HLsqnXZnfRUTkKhqSgy+7qZCYFfTMRAtApCUSbJ/qJvjPTX3eTCvlhEnHvIQtt16xlDaF/HivLmdA8V732uKmv2Rx5zvLZLtUbjIduIM0cdIBXaegXAGgPx3LE42Eb+AUwjXMH7REgPQAAAABJRU5ErkJggg=='"
  			src="${Companies?.ReportImage || ""}"
  			alt=""
  		/>`
  );

  NewSignature = NewSignature.replace(
    /\{\{company.email\}\}/g,
    `${Company?.Email || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.address1\}\}/g,
    `${Company?.Address1 || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.address2\}\}/g,
    `${Company?.Address2 || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.collaborator_number\}\}/g,
    `${CollaboratorNumber || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.avatar\}\}/g,
    `<img style="
			width: 48px;
			height: 48px;
			border-radius: 32px;
			" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA+CAMAAACr3TNIAAAAAXNSR0IArs4c6QAAAYlQTFRFAAAA////////////////////////////////////////9P//9f//9/f/9/f/+Pj/+Pj/+Pj/+fn/8/n/8/n/9Pn/9Pr/9Pr/9vb/8/f/9Pf/9Pj/9Pj/9fj/9fj/9fn/9vn/9vn/8/b88/b89Pf89Pf89Pf89fj98/j98/j98/j99Pj99Pb99Pb99Pb99Pf99Pj99Pj99Pb98/f98/f98/f99Pf99Pf99Pf99Pf99Pf99Pf99Pf89Pf89Pf88/f88/f88/f88/f88/f88/b89Pb89Pb99Pf99Pf98/f98/f98/f98/f98/f98/f99Pf99Pf99Pf99Pb99Pb98/b98/f98/f99Pf99Pf99Pf99Pf99Pf91d7o1t7o19/p1+Dp1+Hq2ODp2OHq2eHr2uLr2+Ps2+Ts3eTt3eXu3uXv3ubv3+bv4Ofv4ejw4enw4+nx5Ory5Ovz5uz06O316e726u/26+/27PD37fL47vH57vP58PT78fT78fX88vX88vb88/b98/f99Pf9rVjHjQAAAFx0Uk5TAAECBAUJDQ4REhQYGR8gJCYnKCssLi8wOEBDRUhKTE9TVFZYWl1haWprbHBzdHV4iIyQl5mboKGjpKWotre4wMHDxMXHy8zS09bX2Nna3N/h4uXm7/Dx9fb3+fwJXXSrAAACc0lEQVQYGaXBi0NLYRwG4BeTXCOXGbm15FJkobB0IuZSkVit9lUz2lw2Num6kb1/OcnsfDu/7+wcngcGzZ2R/rux8QQT47GB/khnM3xovTqUpC451N0Cby49o2zsAhra1j5Ks9Gz2+DqZIzuHp6AWeAmG7segMHeB/RieDdER1/Sm+dBCNoT9GqqAw7n6Ec76hxL0I9EEJo9L+jPiz2w2XGfft3bgZqb9O8G/mqj2TeahFBlUVYuvHutXr8rlCmx8EeIsq/zasv8V0pC2GJRlFM1OQos/BaiaFHZLVIQwiaLktKcspsr0cnCL00zlHxSuk90mtkFIEzRgtItUBAGEKWkMqt0sxU69QGBSUrWVb11Ok0AIYo2VL0NCo6ji7K3SveWki70UpZTuhwl1xClbE3p1ijpg0WDgrIrUGRhhAaVjKrJVCgaQZwmPz6rqs8/KIsjTrPV9ymlVOr9Kk3iGKGr8kqZLkZg8X9YiFLwfbn4MZvN5lcqJCsr+Ww2+7G4/J0OUfTSaSmlqtILaVWVWmK9XvTQIa9M8qzTgzbWW1Rmi9S1IRCnrjSnzOZKtJsMAFHqispNkXZRAGHqPig3H2gXBrBrhpq0cpOmzUwTfhmkRrmjzSA2hahJKzdp2oTw2yDtispNkTWD2HKEmi+ZNyaZL6xJHsYfUf6LPlQdmKZ/0/vxVxf964LNLfp1B3Y7n9CfpzuhaX1FP+KHUOc0/TgFh44pejXVAUFwgt5MBCHaN0wvhvfBIHCbjd0OwOz0I7p7fAautp9/TrOx8HY0dPEZZWOX4c3B7qEkdcmhKy3wobkz0j8QG08wMR4b6I90NkP2E9FVstM6GBNQAAAAAElFTkSuQmCC'"
			src="${Companies?.Image || ""}"
			alt=""
			/>`
  );

  NewSignature = NewSignature.replace(
    /\{\{team.name\}\}/g,
    `${Teams?.Name || ""}`
  );

  return NewSignature;
};
const TeamApplied = (data, signature, form) => {
  const { Team, User } = data;
  const { Name, Department } = Team;
  const { Company } = Department;
  const {
    Name: UserName,
    Email: UserEmail,
    PhoneExtension,
    Image: UserImage,
    RoleName,
    Departments,
    Companies,
    // Teams,
    CollaboratorNumber,
    PhoneNumber: UserPhoneNumber
  } = User;

  let NewSignature = `${signature}`;

  NewSignature = NewSignature.replace(/\{\{team.name\}\}/g, `${Name || ""}`);

  if (Array.isArray(Company?.SocialNetworks)) {
    for (const e of Company.SocialNetworks) {
      const type = SocialNetworkType(e.Url);

      switch (type) {
        case pLinkType.Facebook:
          NewSignature = NewSignature.replace(
            /\{\{company.facebook\}\}/g,
            `${e.Url || ""}`
          );
          break;
        case pLinkType.Twitter:
          NewSignature = NewSignature.replace(
            /\{\{company.twitter\}\}/g,
            `${e.Url || ""}`
          );
          break;
        case pLinkType.Instagram:
          NewSignature = NewSignature.replace(
            /\{\{company.instagram\}\}/g,
            `${e.Url || ""}`
          );
          break;
        case pLinkType.Linkedin:
          NewSignature = NewSignature.replace(
            /\{\{company.linkedin\}\}/g,
            `${e.Url || ""}`
          );
          break;
        case pLinkType.Youtube:
          NewSignature = NewSignature.replace(
            /\{\{company.youtube\}\}/g,
            `${e.Url || ""}`
          );
          break;
        default:
          break;
      }
    }
  }

  NewSignature = NewSignature.replace(
    /\{\{user.companies\}\}/g,
    `${Company?.Name || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.departements\}\}/g,
    `${Departments?.join(", ") || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.teams\}\}/g,
    `${Team?.Name || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{company.name\}\}/g,
    `${Company?.Name || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.phone_number\}\}/g,
    `${Company?.PhoneNumber?.Contact?.replace(".", " ") || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.website\}\}/g,
    `${Company?.Website || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.vat\}\}/g,
    `${Company?.FiscalNumber || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.industries\}\}/g,
    `${Company?.Industry || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{company.logo\}\}/g,
    `<img onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAoCAMAAABgtyA4AAAAAXNSR0IArs4c6QAAAJNQTFRFAAAA////8/n/8/f/9Pf/9fj/8/f98/f99Pf99Pj99Pf99Pf89Pb99Pf99Pb98/b99Pf99Pf91d7o2ODq2eHr2+Pt3eXu3uTu3+fw4ejw4+rx4+ry5Ory5evz5uv06O316u/26/H37PH37vL58PT68fX88/b98/f89Pb89Pf89Pf99Pf+9Pj99ff99ff+9fj99fj+6P0+AwAAABJ0Uk5TAAMqQENJgIOGiJy4zeDo7/3+9Hmm/AAAAThJREFUWMPt2NtugkAQBuDfU7WiiGNZu60Vl4OHbVHf/+l6ISLVRryYkJ3E/3Ynux+TCZAF0BtNrJhMRj0ArcHUisq030LHSkvQhce01T6vzZ7pKA8+yz759pGq7YHlMB88D79jLasLDzpnL2wA/c1e2BzaaKWNMHRERERaFNrQKStJaF2glSR0WKBJElr9i84WibjxyEKaJQ6j4wIdV1cUEb1lzr/yltWFU/fDzOGPS/ox1/GtuaJ2EH2ds/midh+9oEuU0+jP9/MoRFSNdhity6b+NRMZZ9HLsqnXZnfRUTkKhqSgy+7qZCYFfTMRAtApCUSbJ/qJvjPTX3eTCvlhEnHvIQtt16xlDaF/HivLmdA8V732uKmv2Rx5zvLZLtUbjIduIM0cdIBXaegXAGgPx3LE42Eb+AUwjXMH7REgPQAAAABJRU5ErkJggg=='"
  			src="${Company?.ReportImage || ""}"
  			alt=""
  		/>`
  );

  NewSignature = NewSignature.replace(
    /\{\{company.email\}\}/g,
    `${Company?.Email || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.address1\}\}/g,
    `${Company?.Address1 || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{company.address2\}\}/g,
    `${Company?.Address2 || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{company.avatar\}\}/g,
    `<img style="
			width: 48px;
			height: 48px;
			border-radius: 32px;
			" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA+CAMAAACr3TNIAAAAAXNSR0IArs4c6QAAAYlQTFRFAAAA////////////////////////////////////////9P//9f//9/f/9/f/+Pj/+Pj/+Pj/+fn/8/n/8/n/9Pn/9Pr/9Pr/9vb/8/f/9Pf/9Pj/9Pj/9fj/9fj/9fn/9vn/9vn/8/b88/b89Pf89Pf89Pf89fj98/j98/j98/j99Pj99Pb99Pb99Pb99Pf99Pj99Pj99Pb98/f98/f98/f99Pf99Pf99Pf99Pf99Pf99Pf99Pf89Pf89Pf88/f88/f88/f88/f88/f88/b89Pb89Pb99Pf99Pf98/f98/f98/f98/f98/f98/f99Pf99Pf99Pf99Pb99Pb98/b98/f98/f99Pf99Pf99Pf99Pf99Pf91d7o1t7o19/p1+Dp1+Hq2ODp2OHq2eHr2uLr2+Ps2+Ts3eTt3eXu3uXv3ubv3+bv4Ofv4ejw4enw4+nx5Ory5Ovz5uz06O316e726u/26+/27PD37fL47vH57vP58PT78fT78fX88vX88vb88/b98/f99Pf9rVjHjQAAAFx0Uk5TAAECBAUJDQ4REhQYGR8gJCYnKCssLi8wOEBDRUhKTE9TVFZYWl1haWprbHBzdHV4iIyQl5mboKGjpKWotre4wMHDxMXHy8zS09bX2Nna3N/h4uXm7/Dx9fb3+fwJXXSrAAACc0lEQVQYGaXBi0NLYRwG4BeTXCOXGbm15FJkobB0IuZSkVit9lUz2lw2Num6kb1/OcnsfDu/7+wcngcGzZ2R/rux8QQT47GB/khnM3xovTqUpC451N0Cby49o2zsAhra1j5Ks9Gz2+DqZIzuHp6AWeAmG7segMHeB/RieDdER1/Sm+dBCNoT9GqqAw7n6Ec76hxL0I9EEJo9L+jPiz2w2XGfft3bgZqb9O8G/mqj2TeahFBlUVYuvHutXr8rlCmx8EeIsq/zasv8V0pC2GJRlFM1OQos/BaiaFHZLVIQwiaLktKcspsr0cnCL00zlHxSuk90mtkFIEzRgtItUBAGEKWkMqt0sxU69QGBSUrWVb11Ok0AIYo2VL0NCo6ji7K3SveWki70UpZTuhwl1xClbE3p1ijpg0WDgrIrUGRhhAaVjKrJVCgaQZwmPz6rqs8/KIsjTrPV9ymlVOr9Kk3iGKGr8kqZLkZg8X9YiFLwfbn4MZvN5lcqJCsr+Ww2+7G4/J0OUfTSaSmlqtILaVWVWmK9XvTQIa9M8qzTgzbWW1Rmi9S1IRCnrjSnzOZKtJsMAFHqispNkXZRAGHqPig3H2gXBrBrhpq0cpOmzUwTfhmkRrmjzSA2hahJKzdp2oTw2yDtispNkTWD2HKEmi+ZNyaZL6xJHsYfUf6LPlQdmKZ/0/vxVxf964LNLfp1B3Y7n9CfpzuhaX1FP+KHUOc0/TgFh44pejXVAUFwgt5MBCHaN0wvhvfBIHCbjd0OwOz0I7p7fAautp9/TrOx8HY0dPEZZWOX4c3B7qEkdcmhKy3wobkz0j8QG08wMR4b6I90NkP2E9FVstM6GBNQAAAAAElFTkSuQmCC'"
			src="${Company?.Image || ""}"
			alt=""
			/>`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.name\}\}/g,
    `${UserName || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.email\}\}/g,
    `${UserEmail || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.extension\}\}/g,
    `${PhoneExtension || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.companies\}\}/g,
    `${Companies?.join(", ") || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.collaborator_number\}\}/g,
    `${CollaboratorNumber || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.role\}\}/g,
    `${RoleName || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.phone_number\}\}/g,
    `${UserPhoneNumber?.replace(".", " ") || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.avatar\}\}/g,
    `<img style="
			width: 48px;
			height: 48px;
			border-radius: 32px;
			margin-right: 10px;
			" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA+CAMAAACr3TNIAAAAAXNSR0IArs4c6QAAAYlQTFRFAAAA////////////////////////////////////////9P//9f//9/f/9/f/+Pj/+Pj/+Pj/+fn/8/n/8/n/9Pn/9Pr/9Pr/9vb/8/f/9Pf/9Pj/9Pj/9fj/9fj/9fn/9vn/9vn/8/b88/b89Pf89Pf89Pf89fj98/j98/j98/j99Pj99Pb99Pb99Pb99Pf99Pj99Pj99Pb98/f98/f98/f99Pf99Pf99Pf99Pf99Pf99Pf99Pf89Pf89Pf88/f88/f88/f88/f88/f88/b89Pb89Pb99Pf99Pf98/f98/f98/f98/f98/f98/f99Pf99Pf99Pf99Pb99Pb98/b98/f98/f99Pf99Pf99Pf99Pf99Pf91d7o1t7o19/p1+Dp1+Hq2ODp2OHq2eHr2uLr2+Ps2+Ts3eTt3eXu3uXv3ubv3+bv4Ofv4ejw4enw4+nx5Ory5Ovz5uz06O316e726u/26+/27PD37fL47vH57vP58PT78fT78fX88vX88vb88/b98/f99Pf9rVjHjQAAAFx0Uk5TAAECBAUJDQ4REhQYGR8gJCYnKCssLi8wOEBDRUhKTE9TVFZYWl1haWprbHBzdHV4iIyQl5mboKGjpKWotre4wMHDxMXHy8zS09bX2Nna3N/h4uXm7/Dx9fb3+fwJXXSrAAACc0lEQVQYGaXBi0NLYRwG4BeTXCOXGbm15FJkobB0IuZSkVit9lUz2lw2Num6kb1/OcnsfDu/7+wcngcGzZ2R/rux8QQT47GB/khnM3xovTqUpC451N0Cby49o2zsAhra1j5Ks9Gz2+DqZIzuHp6AWeAmG7segMHeB/RieDdER1/Sm+dBCNoT9GqqAw7n6Ec76hxL0I9EEJo9L+jPiz2w2XGfft3bgZqb9O8G/mqj2TeahFBlUVYuvHutXr8rlCmx8EeIsq/zasv8V0pC2GJRlFM1OQos/BaiaFHZLVIQwiaLktKcspsr0cnCL00zlHxSuk90mtkFIEzRgtItUBAGEKWkMqt0sxU69QGBSUrWVb11Ok0AIYo2VL0NCo6ji7K3SveWki70UpZTuhwl1xClbE3p1ijpg0WDgrIrUGRhhAaVjKrJVCgaQZwmPz6rqs8/KIsjTrPV9ymlVOr9Kk3iGKGr8kqZLkZg8X9YiFLwfbn4MZvN5lcqJCsr+Ww2+7G4/J0OUfTSaSmlqtILaVWVWmK9XvTQIa9M8qzTgzbWW1Rmi9S1IRCnrjSnzOZKtJsMAFHqispNkXZRAGHqPig3H2gXBrBrhpq0cpOmzUwTfhmkRrmjzSA2hahJKzdp2oTw2yDtispNkTWD2HKEmi+ZNyaZL6xJHsYfUf6LPlQdmKZ/0/vxVxf964LNLfp1B3Y7n9CfpzuhaX1FP+KHUOc0/TgFh44pejXVAUFwgt5MBCHaN0wvhvfBIHCbjd0OwOz0I7p7fAautp9/TrOx8HY0dPEZZWOX4c3B7qEkdcmhKy3wobkz0j8QG08wMR4b6I90NkP2E9FVstM6GBNQAAAAAElFTkSuQmCC'"
			src="${UserImage || ""}"
			alt=""
			/>`
  );

  NewSignature = NewSignature.replace(
    /\{\{department.name\}\}/g,
    `${Department?.Name || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{department.phone_number\}\}/g,
    `${Department?.PhoneNumber?.Contact?.replace(".", " ") || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{department.email\}\}/g,
    `${Department?.Email || ""}`
  );

  return NewSignature;
};
const UserApplied = (data, signature, form) => {
  const { User } = data;
  const {
    Name: UserName,
    Email: UserEmail,
    PhoneExtension,
    Image: UserImage,
    RoleName,
    CollaboratorNumber,
    Companies,
    Teams,
    Departments,
    PhoneNumber: UserPhoneNumber
  } = User;

  let NewSignature = `${signature}`;

  // NewSignature = NewSignature.replace(
  //   /\{\{team.name\}\}/g,
  //   `${Teams?.join(", ") || ""}`
  // );

  // if (Array.isArray(Companies?.SocialNetworks)) {
  //   for (const e of Companies.SocialNetworks) {
  //     const type = SocialNetworkType(e.Url);

  //     switch (type) {
  //       case pLinkType.Facebook:
  //         NewSignature = NewSignature.replace(
  //           /\{\{company.facebook\}\}/g,
  //           `${e.Url || ""}`
  //         );
  //         break;
  //       case pLinkType.Twitter:
  //         NewSignature = NewSignature.replace(
  //           /\{\{company.twitter\}\}/g,
  //           `${e.Url || ""}`
  //         );
  //         break;
  //       case pLinkType.Instagram:
  //         NewSignature = NewSignature.replace(
  //           /\{\{company.instagram\}\}/g,
  //           `${e.Url || ""}`
  //         );
  //         break;
  //       case pLinkType.Linkedin:
  //         NewSignature = NewSignature.replace(
  //           /\{\{company.linkedin\}\}/g,
  //           `${e.Url || ""}`
  //         );
  //         break;
  //       case pLinkType.Youtube:
  //         NewSignature = NewSignature.replace(
  //           /\{\{company.youtube\}\}/g,
  //           `${e.Url || ""}`
  //         );
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }

  NewSignature = NewSignature.replace(
    /\{\{user.companies\}\}/g,
    `${Companies?.map((e) => e.Name)?.join(", ") || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.departements\}\}/g,
    `${Departments?.map((e) => e.Name)?.join(", ") || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.teams\}\}/g,
    `${Teams?.map((e) => e.Name).join(", ") || ""}`
  );

  // NewSignature = NewSignature.replace(
  //   /\{\{company.name\}\}/g,
  //   `${Companies?.Name || ""}`
  // );
  // NewSignature = NewSignature.replace(
  //   /\{\{company.phone_number\}\}/g,
  //   `${Companies?.PhoneNumber?.Contact?.replace(".", " ") || ""}`
  // );
  // NewSignature = NewSignature.replace(
  //   /\{\{company.website\}\}/g,
  //   `${Companies?.Website || ""}`
  // );
  // NewSignature = NewSignature.replace(
  //   /\{\{company.vat\}\}/g,
  //   `${Companies?.FiscalNumber || ""}`
  // );
  // NewSignature = NewSignature.replace(
  //   /\{\{company.industries\}\}/g,
  //   `${Companies?.Industry || ""}`
  // );

  // NewSignature = NewSignature.replace(
  //   /\{\{company.logo\}\}/g,
  //   `<img onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAoCAMAAABgtyA4AAAAAXNSR0IArs4c6QAAAJNQTFRFAAAA////8/n/8/f/9Pf/9fj/8/f98/f99Pf99Pj99Pf99Pf89Pb99Pf99Pb98/b99Pf99Pf91d7o2ODq2eHr2+Pt3eXu3uTu3+fw4ejw4+rx4+ry5Ory5evz5uv06O316u/26/H37PH37vL58PT68fX88/b98/f89Pb89Pf89Pf99Pf+9Pj99ff99ff+9fj99fj+6P0+AwAAABJ0Uk5TAAMqQENJgIOGiJy4zeDo7/3+9Hmm/AAAAThJREFUWMPt2NtugkAQBuDfU7WiiGNZu60Vl4OHbVHf/+l6ISLVRryYkJ3E/3Ynux+TCZAF0BtNrJhMRj0ArcHUisq030LHSkvQhce01T6vzZ7pKA8+yz759pGq7YHlMB88D79jLasLDzpnL2wA/c1e2BzaaKWNMHRERERaFNrQKStJaF2glSR0WKBJElr9i84WibjxyEKaJQ6j4wIdV1cUEb1lzr/yltWFU/fDzOGPS/ox1/GtuaJ2EH2ds/midh+9oEuU0+jP9/MoRFSNdhity6b+NRMZZ9HLsqnXZnfRUTkKhqSgy+7qZCYFfTMRAtApCUSbJ/qJvjPTX3eTCvlhEnHvIQtt16xlDaF/HivLmdA8V732uKmv2Rx5zvLZLtUbjIduIM0cdIBXaegXAGgPx3LE42Eb+AUwjXMH7REgPQAAAABJRU5ErkJggg=='"
  // 			src="${Companies?.ReportImage || ""}"
  // 			alt=""
  // 		/>`
  // );

  // NewSignature = NewSignature.replace(
  //   /\{\{company.email\}\}/g,
  //   `${Companies?.Email || ""}`
  // );
  // NewSignature = NewSignature.replace(
  //   /\{\{company.address\}\}/g,
  //   `${Companies?.Address?.Address1 || ""}`
  // );

  // NewSignature = NewSignature.replace(
  //   /\{\{company.avatar\}\}/g,
  //   `<img style="
  // 		width: 64px;
  // 		height: 64px;
  // 		border-radius: 32px;
  // 		" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA+CAMAAACr3TNIAAAAAXNSR0IArs4c6QAAAYlQTFRFAAAA////////////////////////////////////////9P//9f//9/f/9/f/+Pj/+Pj/+Pj/+fn/8/n/8/n/9Pn/9Pr/9Pr/9vb/8/f/9Pf/9Pj/9Pj/9fj/9fj/9fn/9vn/9vn/8/b88/b89Pf89Pf89Pf89fj98/j98/j98/j99Pj99Pb99Pb99Pb99Pf99Pj99Pj99Pb98/f98/f98/f99Pf99Pf99Pf99Pf99Pf99Pf99Pf89Pf89Pf88/f88/f88/f88/f88/f88/b89Pb89Pb99Pf99Pf98/f98/f98/f98/f98/f98/f99Pf99Pf99Pf99Pb99Pb98/b98/f98/f99Pf99Pf99Pf99Pf99Pf91d7o1t7o19/p1+Dp1+Hq2ODp2OHq2eHr2uLr2+Ps2+Ts3eTt3eXu3uXv3ubv3+bv4Ofv4ejw4enw4+nx5Ory5Ovz5uz06O316e726u/26+/27PD37fL47vH57vP58PT78fT78fX88vX88vb88/b98/f99Pf9rVjHjQAAAFx0Uk5TAAECBAUJDQ4REhQYGR8gJCYnKCssLi8wOEBDRUhKTE9TVFZYWl1haWprbHBzdHV4iIyQl5mboKGjpKWotre4wMHDxMXHy8zS09bX2Nna3N/h4uXm7/Dx9fb3+fwJXXSrAAACc0lEQVQYGaXBi0NLYRwG4BeTXCOXGbm15FJkobB0IuZSkVit9lUz2lw2Num6kb1/OcnsfDu/7+wcngcGzZ2R/rux8QQT47GB/khnM3xovTqUpC451N0Cby49o2zsAhra1j5Ks9Gz2+DqZIzuHp6AWeAmG7segMHeB/RieDdER1/Sm+dBCNoT9GqqAw7n6Ec76hxL0I9EEJo9L+jPiz2w2XGfft3bgZqb9O8G/mqj2TeahFBlUVYuvHutXr8rlCmx8EeIsq/zasv8V0pC2GJRlFM1OQos/BaiaFHZLVIQwiaLktKcspsr0cnCL00zlHxSuk90mtkFIEzRgtItUBAGEKWkMqt0sxU69QGBSUrWVb11Ok0AIYo2VL0NCo6ji7K3SveWki70UpZTuhwl1xClbE3p1ijpg0WDgrIrUGRhhAaVjKrJVCgaQZwmPz6rqs8/KIsjTrPV9ymlVOr9Kk3iGKGr8kqZLkZg8X9YiFLwfbn4MZvN5lcqJCsr+Ww2+7G4/J0OUfTSaSmlqtILaVWVWmK9XvTQIa9M8qzTgzbWW1Rmi9S1IRCnrjSnzOZKtJsMAFHqispNkXZRAGHqPig3H2gXBrBrhpq0cpOmzUwTfhmkRrmjzSA2hahJKzdp2oTw2yDtispNkTWD2HKEmi+ZNyaZL6xJHsYfUf6LPlQdmKZ/0/vxVxf964LNLfp1B3Y7n9CfpzuhaX1FP+KHUOc0/TgFh44pejXVAUFwgt5MBCHaN0wvhvfBIHCbjd0OwOz0I7p7fAautp9/TrOx8HY0dPEZZWOX4c3B7qEkdcmhKy3wobkz0j8QG08wMR4b6I90NkP2E9FVstM6GBNQAAAAAElFTkSuQmCC'"
  // 		src="${Companies?.Image || ""}"
  // 		alt=""
  // 		/>`
  // );

  NewSignature = NewSignature.replace(
    /\{\{user.name\}\}/g,
    `${UserName || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.email\}\}/g,
    `${UserEmail || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.collaborator_number\}\}/g,
    `${CollaboratorNumber || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.extension\}\}/g,
    `${PhoneExtension || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.role\}\}/g,
    `${RoleName || ""}`
  );
  NewSignature = NewSignature.replace(
    /\{\{user.phone_number\}\}/g,
    `${UserPhoneNumber?.replace(".", " ") || ""}`
  );

  NewSignature = NewSignature.replace(
    /\{\{user.avatar\}\}/g,
    `<img style="
			width: 48px;
			height: 48px;
			border-radius: 32px;
			margin-right: 10px;
			" onerror="this.onerror=null;this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD8AAAA+CAMAAACr3TNIAAAAAXNSR0IArs4c6QAAAYlQTFRFAAAA////////////////////////////////////////9P//9f//9/f/9/f/+Pj/+Pj/+Pj/+fn/8/n/8/n/9Pn/9Pr/9Pr/9vb/8/f/9Pf/9Pj/9Pj/9fj/9fj/9fn/9vn/9vn/8/b88/b89Pf89Pf89Pf89fj98/j98/j98/j99Pj99Pb99Pb99Pb99Pf99Pj99Pj99Pb98/f98/f98/f99Pf99Pf99Pf99Pf99Pf99Pf99Pf89Pf89Pf88/f88/f88/f88/f88/f88/b89Pb89Pb99Pf99Pf98/f98/f98/f98/f98/f98/f99Pf99Pf99Pf99Pb99Pb98/b98/f98/f99Pf99Pf99Pf99Pf99Pf91d7o1t7o19/p1+Dp1+Hq2ODp2OHq2eHr2uLr2+Ps2+Ts3eTt3eXu3uXv3ubv3+bv4Ofv4ejw4enw4+nx5Ory5Ovz5uz06O316e726u/26+/27PD37fL47vH57vP58PT78fT78fX88vX88vb88/b98/f99Pf9rVjHjQAAAFx0Uk5TAAECBAUJDQ4REhQYGR8gJCYnKCssLi8wOEBDRUhKTE9TVFZYWl1haWprbHBzdHV4iIyQl5mboKGjpKWotre4wMHDxMXHy8zS09bX2Nna3N/h4uXm7/Dx9fb3+fwJXXSrAAACc0lEQVQYGaXBi0NLYRwG4BeTXCOXGbm15FJkobB0IuZSkVit9lUz2lw2Num6kb1/OcnsfDu/7+wcngcGzZ2R/rux8QQT47GB/khnM3xovTqUpC451N0Cby49o2zsAhra1j5Ks9Gz2+DqZIzuHp6AWeAmG7segMHeB/RieDdER1/Sm+dBCNoT9GqqAw7n6Ec76hxL0I9EEJo9L+jPiz2w2XGfft3bgZqb9O8G/mqj2TeahFBlUVYuvHutXr8rlCmx8EeIsq/zasv8V0pC2GJRlFM1OQos/BaiaFHZLVIQwiaLktKcspsr0cnCL00zlHxSuk90mtkFIEzRgtItUBAGEKWkMqt0sxU69QGBSUrWVb11Ok0AIYo2VL0NCo6ji7K3SveWki70UpZTuhwl1xClbE3p1ijpg0WDgrIrUGRhhAaVjKrJVCgaQZwmPz6rqs8/KIsjTrPV9ymlVOr9Kk3iGKGr8kqZLkZg8X9YiFLwfbn4MZvN5lcqJCsr+Ww2+7G4/J0OUfTSaSmlqtILaVWVWmK9XvTQIa9M8qzTgzbWW1Rmi9S1IRCnrjSnzOZKtJsMAFHqispNkXZRAGHqPig3H2gXBrBrhpq0cpOmzUwTfhmkRrmjzSA2hahJKzdp2oTw2yDtispNkTWD2HKEmi+ZNyaZL6xJHsYfUf6LPlQdmKZ/0/vxVxf964LNLfp1B3Y7n9CfpzuhaX1FP+KHUOc0/TgFh44pejXVAUFwgt5MBCHaN0wvhvfBIHCbjd0OwOz0I7p7fAautp9/TrOx8HY0dPEZZWOX4c3B7qEkdcmhKy3wobkz0j8QG08wMR4b6I90NkP2E9FVstM6GBNQAAAAAElFTkSuQmCC'"
			src="${UserImage || ""}"
			alt=""
			/>`
  );

  // NewSignature = NewSignature.replace(
  //   /\{\{department.name\}\}/g,
  //   `${Departments?.Name || ""}`
  // );
  // NewSignature = NewSignature.replace(
  //   /\{\{department.phone_number\}\}/g,
  //   `${Departments?.PhoneNumber?.Contact?.replace(".", " ") || ""}`
  // );

  // NewSignature = NewSignature.replace(
  //   /\{\{department.email\}\}/g,
  //   `${Departments?.Email || ""}`
  // );

  return NewSignature;
};
