import React, { useEffect, useMemo, useRef } from "react";
import "./Breadcrumbs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListCheck,
  faSuitcase,
  faPhone,
  faTicketSimple,
  faFileContract,
  faHandshake,
  faSquareParking
} from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import { ClientStatusProfile } from "../Clients/ClientProfile";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import { useHoverEvents, OriginPopup } from "../../Containers/Origin/Origin";
import {
  ProjectOriginPopup,
  projectOriginOptions
} from "../../Containers/Origin/ProjectOrigin";
import {
  ClientOriginPopup,
  clientOriginOptions
} from "../../Containers/Origin/ClientOrigin";
import {
  TicektOriginPopup,
  ticketOriginOptions
} from "../../Containers/Origin/TicketOrigin";
import {
  CallOriginPopup,
  callOriginOptions
} from "../../Containers/Origin/CallOrigin";
import {
  TaskOriginPopup,
  taskOriginOptions
} from "../../Containers/Origin/TaskOrigin";
import {
  ContractOriginPopup,
  contractOriginOptions
} from "../../Containers/Origin/ContractOrigin";
import { useSpaceQuery } from "../../Helpers/IOClient";
import {
  DealOriginPopup,
  dealOriginOptions
} from "../../Containers/Origin/DealOrigin";
import { ContractStatusProfile } from "../Contract/ContractProfile";
import { useIntl } from "react-intl";
import { originTypes } from "../../Helpers/MiscHelper";
import DealStatusProfile from "../Deals/DealStatusProfile";
import { ProjectStatusProfile } from "../Projects/ProjectProfile";
import { ProjectStatusTypes } from "../../Containers/Projects/ProjectsStatusHelper";

const Breadcrumbs = ({ items, highlightValue, className, onLoad }) => {
  if (!items) return null;
  const hasHighlight = Boolean(highlightValue);
  return (
    <div
      className={classnames("ar-breadcrumbs p-3 w-100", className, {
        "border rounded": !hasHighlight,
        highlight: hasHighlight
      })}
    >
      {items.map((item) => {
        const { Type, Id } = item;
        return (
          <BreadcrumbsItem
            highlightValue={highlightValue}
            key={`${Type}${Id}`}
            item={item}
          />
        );
      })}
    </div>
  );
};

const BreadcrumbsItem = ({ item, highlightValue }) => {
  const intl = useIntl();
  let subComponent, icon, typeExtense;
  const wasHighlightRef = useRef();
  const { Id, Type, Status, Name } = item;

  let isHighlight = false;

  if (highlightValue) {
    const { data, type } = highlightValue;
    if (Type === type.toLowerCase() && data === Id) {
      isHighlight = true;
      wasHighlightRef.current = true;
    }
  }

  let resolvedStatus;

  if (!highlightValue && wasHighlightRef.current) {
    resolvedStatus = ProjectStatusTypes.finished;
  } else {
    resolvedStatus = Type === "client" || Type === "contract" ? null : Status;
  }

  let PopupComp;
  let popupProps = null;
  let popupOptions;
  switch (Type) {
    case "project":
      typeExtense = intl.formatMessage({ id: "PROJECT" });
      icon = faSquareParking;
      PopupComp = ProjectOriginPopup;
      popupProps = { project: item };
      popupOptions = projectOriginOptions;
      break;
    case "ticket":
      typeExtense = intl.formatMessage({ id: "CASE" });
      icon = faTicketSimple;
      PopupComp = TicektOriginPopup;
      popupProps = { ticket: item };
      popupOptions = ticketOriginOptions;
      break;
    case "call":
      typeExtense = intl.formatMessage({ id: "CALL" });
      icon = faPhone;
      PopupComp = CallOriginPopup;
      popupProps = { call: item };
      popupOptions = callOriginOptions;
      break;
    case "client":
      typeExtense = intl.formatMessage({ id: "ACCOUNT" });
      icon = faSuitcase;
      PopupComp = ClientOriginPopup;
      popupProps = { client: item };
      popupOptions = clientOriginOptions;
      //   subComponent = (
      //     <ClientStatusBadge className="ml-2 fs-10 sm" status={Status} />
      //   );
      break;
    case "task":
      typeExtense = intl.formatMessage({ id: "TASK" });
      icon = faListCheck;
      PopupComp = TaskOriginPopup;
      popupProps = { task: item };
      popupOptions = taskOriginOptions;
      break;
    case "contract":
      typeExtense = intl.formatMessage({ id: "CONTRACT" });
      icon = faFileContract;
      PopupComp = ContractOriginPopup;
      popupProps = { contract: item };
      popupOptions = contractOriginOptions;
      break;
    case "deal":
      typeExtense = intl.formatMessage({ id: "DEAL" });
      icon = faHandshake;
      PopupComp = DealOriginPopup;
      popupProps = { deal: item };
      popupOptions = dealOriginOptions;
      break;

    default:
      break;
    // return null;
  }

  const anchorRef = useRef();

  const hoverEvents = useHoverEvents();

  if (!typeExtense) return null;

  const { onAnchorMouseEnter, onAnchorMouseLeave } = hoverEvents;

  return (
    <>
      <div
        className="ar-breadcrumbs-item  d-flex align-items-center text-black"
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
        ref={anchorRef}
      >
        <div className="of-hidden ar-breadcrumbs-item  d-flex align-items-center text-black">
          {Type === "client" ? (
            <ClientStatusProfile
              sensitive
              hideName
              value={Status}
              className="ar-breadcrumbs-circle mr-2"
            />
          ) : Type === "deal" ? (
            <DealStatusProfile
              sensitive
              hideName
              value={Status}
              className="ar-breadcrumbs-circle mr-2"
            />
          ) : Type === "contract" ? (
            <ContractStatusProfile
              sensitive
              hideName
              status={Status}
              className="ar-breadcrumbs-circle mr-2"
            />
          ) : Type === "deal" ? (
            <DealStatusProfile
              sensitive
              hideName
              status={Status}
              className="ar-breadcrumbs-circle mr-2"
            />
          ) : (
            <ProjectStatusProfile
              sensitive
              hideName
              status={resolvedStatus}
              className="ar-breadcrumbs-circle mr-2"
            />
          )}
          <div
            className={classnames(
              "d-flex flex-column ar-breadcrumbs-detail rounded flex-1 justify-content-between",
              { highlight: isHighlight }
            )}
            key={Id}
          >
            <div className="d-inline-flex align-items-center">
              <FontAwesomeIcon className="mr-1" size="sm" icon={icon} />
              <div className="d-flex flex-1">
                <span className="fs-12 text-truncate">{Name}</span>
                {subComponent}
              </div>
            </div>
            <div className="text-black fs-10 fw-medium">
              {typeExtense} {Id}
            </div>
          </div>
        </div>
      </div>
      {PopupComp && (
        <OriginPopup
          placement="left"
          props={popupProps}
          hoverEvents={hoverEvents}
          anchorRef={anchorRef}
          PopupComp={PopupComp}
          options={popupOptions}
        />
      )}
    </>
  );
};

export const ConnectedBreadcrumbs = ({
  value,
  highlightValue,
  className,
  onLoad
}) => {
  let endpoint = useMemo(() => {
    if (value) {
      if (value.type !== originTypes.client)
        return `breadcrumbs/${value.type}/${value.data}`;
    }
    return null;
  }, [value]);

  const { data, error, loading, refetch } = useSpaceQuery(endpoint, null, {
    autoFetch: false,
    onSuccess: () => {
      onLoad && onLoad();
    }
  });
  const isBuiltRef = useRef(false);
  useEffect(() => {
    if (!isBuiltRef.current) {
      isBuiltRef.current = true;
      refetch();
    }
  }, [refetch]);

  if (error || !endpoint) return <></>;

  if (loading)
    return (
      <LoaderSpinner
        centerHorizontal
        size="sm"
        className={classnames("breadcrumps-loader-c text-primary", className)}
      />
    );

  return (
    <Breadcrumbs
      highlightValue={highlightValue}
      items={data}
      className={className}
    />
  );
};

export default Breadcrumbs;
