import React, {
  useContext,
  useRef,
  useState,
  useMemo,
  useCallback
} from "react";
import {
  useSpaceMultiplePost,
  useSpacePostQuery,
  useSpacePost
} from "../../Helpers/IOClient";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { isOwner } from "../../Helpers/ModulesHelper";
import { Redirect } from "react-router-dom";
import { useSpace } from "../../Contexts/SpaceContext";
import { FormattedMessage } from "react-intl";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";

export const OnboardingContext = React.createContext();

export const useOnboarding = () => useContext(OnboardingContext);

export const useOnboardingRequest = (endpoint, onSuccess) => {
  const retryRef = useRef([]);

  const post = useSpaceMultiplePost();

  const [retry, setRetry] = useState();
  const [loading, setLoading] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (items, buildBody) => {
    const finishRef = { current: 0 };
    setSubmitted(true);
    const handleEnd = () => {
      if (finishRef.current !== items.length) return;
      if (retryRef.current.length > 0) {
        //had errors
        setRetry(() => {
          return () => {
            finishRef.current = finishRef.current - retryRef.current.length;
            for (const retry of retryRef.current) {
              retry();
            }
            retryRef.current = [];
            setRetry(undefined);
            setLoading(true);
          };
        });
      } else {
        // console.log("success");
        onSuccess();
      }
      setLoading(false);
    };
    setLoading(true);

    for (const item of items) {
      const body = buildBody(item);

      const startPost = () => {
        post(endpoint, body, {
          onSuccess: () => {
            finishRef.current++;
            handleEnd();
          },
          onError: () => {
            finishRef.current++;
            retryRef.current.push(startPost);
            handleEnd();
          }
        });
      };
      startPost();
    }
  };

  return {
    loading,
    error: Boolean(retry),
    submit: retry || handleSubmit,
    submitted
  };
};

export const OnboardingAdditionItem = ({ onAdd }) => {
  const [name, setName] = useState("");

  const hasName = name.length > 0;
  const inputRef = useRef();
  const addItem = () => {
    setName("");
    onAdd(name);
    inputRef.current.blur();
  };

  const handleKeyup = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (hasName) addItem();
    }
  };

  return (
    <div className="d-flex align-items-center py-2">
      <div
        style={{ width: 520 }}
        className={classnames(
          "ssi-control lg px-3 d-flex align-items-center ar-onboarding-list-item rounded"
          // { selected: IsSelected }
        )}
      >
        <div
          onClick={hasName ? addItem : undefined}
          className={classnames("pr-3", {
            "text-primary cursor-pointer": hasName
          })}
        >
          <FontAwesomeIcon icon={faPlus} />
        </div>
        <input
          ref={inputRef}
          onKeyUp={handleKeyup}
          className="ar-onboarding-list-input h-100 py-2"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
    </div>
  );
};

export const onboardingStateEnum = {
  org: "organization",
  departments: "departments",
  invites: "invites",
  teams: "teams",
  done: "done"
};

const onboardingStateLsKey = "ar-ob-state";

const getLsOnboardingState = (spaceId) => {
  return localStorage.getItem(`${onboardingStateLsKey}-${spaceId}`);
};

const setLsOnboardingState = (spaceId, value) => {
  localStorage.setItem(`${onboardingStateLsKey}-${spaceId}`, value);
};

export const useSpaceOnboardingState = (spaceId) => {
  const currentAccountSpace = useCurrentAccountSpace();

  const [obState, setObState] = useState(() => {
    if (isOwner(currentAccountSpace)) return getLsOnboardingState(spaceId);
    else return onboardingStateEnum.done;
  });

  const body = useMemo(() => {
    return {
      Key: `${onboardingStateLsKey}-${spaceId}`
    };
  }, [spaceId]);

  const { error, loading } = useSpacePostQuery(
    obState ? null : `Preference`,
    body,
    null,
    {
      onSuccess: ({ data }) => {
				if(data === "NO_ITEMS"){
					setObState(onboardingStateEnum.org);
				}
        else if (data !== null) {
          setObState(data);
          if (data === onboardingStateEnum.done) {
            setLsOnboardingState(spaceId, onboardingStateEnum.done);
            setObState(onboardingStateEnum.org);
          }
        }
      },
      onError: ({ error }) => {
        if (error && error.status === 404) {
          setObState(onboardingStateEnum.org);
        }
      },
      cache: false
    }
  );

  return { error, loading, obState, updateState: setObState };
};

export const OnboardingStateContext = React.createContext();

export const OnboardingUpdateContext = React.createContext();

export const useSpaceOnboardingStateUpdater = (onSuccess) => {
  const { updateState } = useContext(OnboardingStateContext);
  const updateRef = useRef();

  const [retry, setRetry] = useState();

  const [post, { loading, error }] = useSpacePost("Preference/Set", null, {
    onSuccess: () => {
      onSuccess && onSuccess();
      updateState(updateRef.current);
    },
    onError: () => {
      setRetry(() => {
        return () => {
          post({
            Key: `${onboardingStateLsKey}-${space.Id}`,
            Value: updateRef.current
          });
        };
      });
    }
  });
  const space = useSpace();
  const update = useCallback(
    (value) => {
      updateRef.current = value;
      post({
        Key: `${onboardingStateLsKey}-${space.Id}`,
        Value: value
      });
    },
    [post, space.Id]
  );

  return { update, retry, loading, error };
};

export const OnboardingAutoRedirect = ({ url }) => {
  const { obState } = useContext(OnboardingStateContext);
  const space = useSpace();

  switch (obState) {
    case onboardingStateEnum.org:
      return <Redirect to={`${url}/company`} />;

    case onboardingStateEnum.departments:
      return <Redirect to={`${url}/departments`} />;

    case onboardingStateEnum.teams:
      return <Redirect to={`${url}/teams`} />;

    case onboardingStateEnum.invites:
      return <Redirect to={`${url}/invites`} />;

    default:
      return <Redirect to={`/s/${space.Id}/welcome`} />;
  }
};

export const SkipOnboardingLink = ({ textId }) => {
  // const history = useHistory();
  // const space = useSpace();
  const onboardingUpdate = useSpaceOnboardingStateUpdater(() => {
    // history.replace(`/s/${space.Id}`);
  });
  const { update, loading } = onboardingUpdate;
  const updateState = () => {
    update(onboardingStateEnum.done);
  };

  return (
    <div className="d-flex align-items-center">
      <div className="link-secondary cursor-pointer" onClick={updateState}>
        <FormattedMessage id={textId} />
      </div>
      {loading && <LoaderSpinner size="xxxs" className="ml-2 text-secondary" />}
    </div>
  );
};
