import React, { useState, useCallback } from "react";

export const MainViewPreferencesContext = React.createContext();
export const MainViewPreferencesSetterContext = React.createContext();

export const MainViewPreferenceProvider = ({ children }) => {
  const [values, setValues] = useState({});

  const updatePreference = useCallback((moduleType, search) => {
    setValues((v) => {
      return { ...v, [moduleType]: search };
    });
  }, []);

  return (
    <MainViewPreferencesContext.Provider value={values}>
      <MainViewPreferencesSetterContext.Provider value={updatePreference}>
        {children}
      </MainViewPreferencesSetterContext.Provider>
    </MainViewPreferencesContext.Provider>
  );
};
