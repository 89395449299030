import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const ClassificationOverviewComp = React.lazy(() =>
  import(
    /* webpackChunkName: "classifications-overview" */ "./ClassificationOverviewComp"
  )
);

const ClassificationOverview = () => {
  return (
    <SuspenseErrorBoundary>
      {/* <PageTypeProvider pageType={RealTimePageEnum.Classification}> */}
      <ClassificationOverviewComp />
      {/* </PageTypeProvider> */}
    </SuspenseErrorBoundary>
  );
};

export default ClassificationOverview;
