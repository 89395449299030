import React from "react";
import { connect } from "react-redux";
import withOptionalInvite from "../../Hoc/withOptionalInvite";
import { Redirect } from "react-router-dom";

const Invites = ({ inviteId }) => {
  return <Redirect to={`/register?invite=${inviteId}`} />;
};

const mapStateToProps = state => {
  return {
    registerPage: state.Pages.Register
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //   registerUser: form => dispatch(registerUser(form)),
    //   removeRegisterError: form => dispatch(removeRegisterError(form))
  };
};

export default withOptionalInvite(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Invites),
  ({ match }) => match.params.inviteId
);
