// @flow
import React from 'react'
import styles from './Spinner.module.css'


const Spinner = ({ show, message, image }) =>
  show && (
    <div role="status" className={styles.spinnerWrapper}>
      <div
        className={styles.spinner}
        style={{ backgroundImage: `url(${image})` }}
        data-testid="Spinner"
      />
      <div className={styles.spinnerText} data-testid="SpinnerText">
        {message}
      </div>
    </div>
  )

export default Spinner
