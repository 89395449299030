import React, { useMemo, useState } from "react";
import { getEntity } from "../../Helpers/IOClient";
import { spacesListSchema } from "../../config/schema";
import { useFormState } from "../../Components/Forms";
import { FormattedMessage } from "react-intl";
import { useToast } from "../../Components/Toast/ToastProvider";
import {
  useSidebarPost,
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent
} from "../../Components/Sidebar/SidebarV2Helper";
import { handleError } from "../../Helpers/MiscHelper";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../Components/Forms/SideMenuForm";
import Switch from "../../Components/Switch/Switch";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import { BillingPlansEnum } from "../BillingPlans/BillingPlanPayment";
import { BillingPlanDropdown } from "../BillingPlans/BillingPlanDropdown";
import SplitCol from "../../Components/SplitCol/SplitCol";
import NumberInput from "../../Components/Input/NumberInput";
import ArDatePicker from "../../Components/DatePicker/ArDatePicker";
import { useEntityValue } from "../../Hooks/EntityHooks";
import { DangerAlert } from "../../Components/Toast/ToastComponents";
import { BillingPlanStatusDropdown } from "../BillingPlans/BillingPlanStatusDropdown";

const defaultSpaceForm = {
  BlockType: null,
  IsManualPayment: false,
  PlanId: BillingPlansEnum.starter,
  TrialEndDate: null,
  MaxAmountOfUsers: undefined
};

const convertSpaceEntityToForm = (entity) => {
  const { BlockType, IsManualPayment, TrialEndDate, MaxAmountOfUsers, Plan } =
    entity;

  return {
    BlockType,
    IsManualPayment,
    TrialEndDate,
    MaxAmountOfUsers,
    PlanId: Plan.Id
  };
};

const SpaceForm = (props) => {
  const { id, onSuccess, className } = props;

  const resolvedForm = useMemo(() => {
    if (id) {
      return {
        ...defaultSpaceForm,
        ...convertSpaceEntityToForm(getEntity(spacesListSchema, id))
      };
    } else return { ...defaultSpaceForm };
  }, [id]);

  //   const canEdit = true;

  return (
    <SpaceFormBase
      className={className}
      onSuccess={onSuccess}
      id={id}
      //   canEdit={canEdit}
      key={id}
      convertedForm={resolvedForm}
    ></SpaceFormBase>
  );
};

const SpaceFormBase = ({
  convertedForm,
  id,
  canEdit,
  onSuccess,
  isChild,
  refetchRef,
  className
}) => {
  const formState = useFormState(() => {
    const { IsManualPayment, IsDeleted, ...rest } = convertedForm;

    return {
      IsManualPayment: IsManualPayment === "true",
      IsDeleted: IsDeleted === "true",
      ...rest
    };
  });

  const { form, handleChange } = formState;

  // const intl = useIntl();

  const createToast = useToast();
  // const [, closeSidebar] = useSidebar();

  const [post, { loading }] = useSidebarPost(
    id ? `Spaces/${id}` : "Spaces",
    spacesListSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        // createToast({
        //   pos: "tm",
        //   type: "success",
        //   title: `${intl.formatMessage({ id: "COUPON" })} nº ${newId || id}`,
        //   description: `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`,
        // });
      },
      onError: ({ error }) => {
        handleError(createToast, error);

        // console.log(error);
      }
    }
  );

  const handleIsManualPaymentChange = (e) => {
    handleChange({
      IsManualPayment: e.target.checked
    });
  };

  const { IsManualPayment } = form;

  const [canEditUsers, setCanEditUsers] = useState(false);
  const handleSubmit = () => {
    // post(formatCuponForm(form));

    if (!canEditUsers) {
      const { MaxAmountOfUsers, ...rest } = form;
      post(rest);
    } else {
      post(form);
    }
  };

  const toggleCanEditUsers = (e) => {
    setCanEditUsers((e) => !e);
  };

  const { IsSubscribed } = useEntityValue(spacesListSchema, id);

  const isNotManualPayment = !IsManualPayment;

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id="WORKSPACE_TEXT" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideForm
          canEdit={canEdit}
          formState={formState}
          onSubmit={handleSubmit}
          //   validate={validateCuponForm}
        >
          <SideFormContent className={className || "px-3 pt-3 pb-5"}>
            {IsSubscribed && IsManualPayment && (
              <DangerAlert className="mt-3 mb-2 rounded">
                <FormattedMessage id="SPACE_SUBSCRIPTION_WARNING" />
              </DangerAlert>
            )}

            <div className="d-flex justify-content-between border-bottom py-3 mb-3">
              <div className="text-black fs-14">
                <FormattedMessage id="POST_PAYMENT" />
              </div>

              <Switch
                name="IsManualPayment"
                checked={IsManualPayment}
                onChange={handleIsManualPaymentChange}
              />
            </div>

            <SplitCol
              className="mb-3"
              leftpercent={60}
              left={
                <FormInput
                  disabled={isNotManualPayment}
                  text={
                    <div style={{ height: 30 }}>
                      <FormattedMessage id="SUBSCRIPTION" />
                    </div>
                  }
                  name="PlanId"
                  inputType={BillingPlanDropdown}
                />
              }
              right={
                <FormInput
                  disabled={isNotManualPayment || !canEditUsers}
                  text={
                    <div
                      style={{ height: 30 }}
                      className="d-flex justify-content-between mb-1"
                    >
                      <FormattedMessage id="USERS_NUMBER" />
                      <Switch
                        name="canEditUsers"
                        checked={canEditUsers}
                        onChange={toggleCanEditUsers}
                      />
                    </div>
                  }
                  name="MaxAmountOfUsers"
                  inputType={NumberInput}
                />
                //   <CovenantPeriodicity
                //     onChange={handleBaseChange}
                //     TimeUnit={TimeUnit}
                //     TimeValue={TimeValue}
                //   />
              }
            />

            <FormInput
              className="mb-3"
              preview={IsManualPayment}
              text={<FormattedMessage id="FREE_TRIAL" />}
              name="TrialEndDate"
              componentClassName="w-100"
              inputType={ArDatePicker}
            />

            <FormInput
              disabled={IsSubscribed}
              text={<FormattedMessage id="STATUS" />}
              name="BlockType"
              componentClassName="w-100"
              inputType={BillingPlanStatusDropdown}
            />
          </SideFormContent>
          <SideFormFooter isLoading={loading} />
        </SideForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export default SpaceForm;
