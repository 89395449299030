// import classnames from "classnames";
import {
  darkred,
  red,
  lightred,
  darkgrey,
  //   grey,
  //   darkgreen,
  //   green,
  getMonth,
  createChartComponent,
  darkgreen,
  green,
  lightgreen,
  grey,
  darkblue,
  // opaqueDarkBlue,
  purple,
  lightPurple,
  blue,
  darkPurple
} from "./echartDefaults";
import moment from "moment";

// include bar chart
require("echarts/lib/echarts.js");
require("echarts/lib/chart/line");
// include  title component
require("echarts/lib/component/tooltip");

export const decideFormat = (data) => {
  let voteAsDays = 0;
  let voteAsHours = 0;
  let voteAsMinutes = 0;

  for (const e of data) {
    const resolvedData = new moment.duration(e.Average);
    let resolvedTime = Math.round(resolvedData.asDays() * 10) / 10;
    let Spec = "Days";
    if (resolvedTime < 1) {
      resolvedTime = Math.round(resolvedData.asHours() * 10) / 10;
      Spec = "Hours";
    }
    if (resolvedTime < 1) {
      resolvedTime = Math.round(resolvedData.asMinutes() * 10) / 10;
      Spec = "Minutes";
    }
    if (Spec === "Days") voteAsDays++;
    else if (Spec === "Hours") voteAsHours++;
    else if (Spec === "Minutes") voteAsMinutes++;
  }

  if (voteAsDays >= voteAsHours >= voteAsMinutes) return "Days";
  else if (voteAsHours > voteAsDays > voteAsMinutes) return "Hours";
  else if (voteAsMinutes > voteAsHours > voteAsDays) return "Minutes";
  else return "Days";
};

export const AreaChart = createChartComponent(
  (Data, intl, difrentCloseColor) => {
    const timeFormat = decideFormat(Data);

    const timeFormatTranslation =
      timeFormat === "Days"
        ? intl.formatMessage({ id: "DAY" }) + "(s)"
        : timeFormat === "Hours"
        ? intl.formatMessage({ id: "HOUR" }) + "(s)"
        : intl.formatMessage({ id: "MINUTE" }) + "(s)";

    return {
      grid: {
        //   left: "5%",
        //   right: "5%",
        //   bottom: "10%",
        //   top: "5%",
        containLabel: true
      },
      tooltip: {
        renderMode: "richText",
        trigger: "item",
        formatter: `{c}  ${timeFormatTranslation}`,
        textStyle: {
          color: "#FFFFFF",
          fontWeight: "bold"
        },
        backgroundColor: "#002B63",
        axisPointer: {
          animation: false
        }
      },
      legend: {
        show: false,
        left: "left",
        selectedMode: false,
        borderRadius: 3,
        icon: "roundRect",
        textStyle: {
          color: darkgrey,
          backgroundColor: lightred
        },
        data: [
          {
            name: intl.formatMessage({ id: "CLOSED" }),
            textStyle: {
              color: difrentCloseColor ? darkred : darkblue,
              padding: 5,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          }
        ]
      },
      xAxis: {
        offset: 10,
        name: intl.formatMessage({ id: "MONTH" }),
        nameLocation: "end",
        nameTextStyle: {
          padding: [47, 15, 0, 0],
          color: "#7c98b6"
        },
        boundaryGap: false,
        type: "category",
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        data: Data.map((e) => {
          return {
            value: `${getMonth(e.Month - 1)} ${e.Year.toString().substring(2)}`,
            textStyle: {
              color: "#7c98b6"
            }
          };
        })
      },
      yAxis: {
        boundaryGap: false,
        //   interval: 5,
        axisLabel: {
          formatter: `{value} ${timeFormatTranslation}`,
          color: "#7c98b6"
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ["#eff4f8"]
          }
        },
        type: "value",
        axisTick: {
          show: false
        }
      },
      series: [
        {
          name: intl.formatMessage({ id: "CLOSED" }),
          data: Data.map((e) => {
            const resolvedData = new moment.duration(e.Average);
            const formatedTime =
              timeFormat === "Days"
                ? Math.round(resolvedData.asDays() * 10) / 10
                : timeFormat === "Hours"
                ? Math.round(resolvedData.asHours() * 10) / 10
                : Math.round(resolvedData.asMinutes() * 10) / 10;
            return formatedTime;
          }),
          type: "line",
          symbol: "circle",
          symbolSize: 7,
          itemStyle: {
            color: difrentCloseColor ? darkred : purple
          },
          lineStyle: {
            color: difrentCloseColor ? darkred : purple
          },
          areaStyle: {
            color: difrentCloseColor ? red : lightPurple,
            opacity: 1
          }
        }
      ]
    };
  }
);
export const AreaChartTickets = createChartComponent(
  (Data, intl, difrentCloseColor) => {
    const timeFormat = decideFormat(Data);

    const timeFormatTranslation =
      timeFormat === "Days"
        ? intl.formatMessage({ id: "DAY" }) + "(s)"
        : timeFormat === "Hours"
        ? intl.formatMessage({ id: "HOUR" }) + "(s)"
        : intl.formatMessage({ id: "MINUTE" }) + "(s)";

    return {
      grid: {
        //   left: "5%",
        //   right: "5%",
        //   bottom: "10%",
        //   top: "5%",
        containLabel: true
      },
      tooltip: {
        renderMode: "richText",
        trigger: "item",
        formatter: `{c}  ${timeFormatTranslation}`,
        textStyle: {
          color: "#FFFFFF",
          fontWeight: "bold"
        },
        backgroundColor: "#002B63",
        axisPointer: {
          animation: false
        }
      },
      legend: {
        show: true,
        left: "left",
        selectedMode: false,
        borderRadius: 3,
        icon: "roundRect",
        textStyle: {
          color: darkgreen
        },
        data: [
          {
            name: intl.formatMessage({ id: "CLOSED" }),
            textStyle: {
              color: darkgreen,
              padding: 5,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          }
        ]
      },
      xAxis: {
        offset: 10,
        name: intl.formatMessage({ id: "MONTH" }),
        nameLocation: "end",
        nameTextStyle: {
          padding: [47, 15, 0, 0],
          color: "D"
        },
        boundaryGap: false,
        type: "category",
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        data: Data.map((e) => {
          return {
            value: `${getMonth(e.Month - 1)} ${e.Year.toString().substring(2)}`,
            textStyle: {
              color: "#7c98b6"
            }
          };
        })
      },
      yAxis: {
        boundaryGap: false,
        //   interval: 5,
        axisLabel: {
          formatter: `{value} ${timeFormatTranslation}`,
          color: "#7c98b6"
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ["#eff4f8"]
          }
        },
        type: "value",
        axisTick: {
          show: false
        }
      },
      series: [
        {
          name: intl.formatMessage({ id: "CLOSED" }),
          data: Data.map((e) => {
            const resolvedData = new moment.duration(e.Average);
            const formatedTime =
              timeFormat === "Days"
                ? Math.round(resolvedData.asDays() * 10) / 10
                : timeFormat === "Hours"
                ? Math.round(resolvedData.asHours() * 10) / 10
                : Math.round(resolvedData.asMinutes() * 10) / 10;
            return formatedTime;
          }),
          type: "line",
          symbol: "circle",
          symbolSize: 7,
          itemStyle: {
            color: darkgreen
          },
          lineStyle: {
            color: darkgreen
          },
          areaStyle: {
            color: green,
            opacity: 1
          }
        }
      ]
    };
  }
);

export const ContractsOpenChart = createChartComponent((Data, intl) => {
  return {
    grid: {
      //   left: "5%",
      //   right: "5%",
      //   bottom: "10%",
      //   top: "5%",
      containLabel: true
    },
    tooltip: {
      renderMode: "richText",
      trigger: "item",
      formatter: `{c}`,
      textStyle: {
        color: "#FFFFFF",
        fontWeight: "bold"
      },
      backgroundColor: "#002B63",
      axisPointer: {
        animation: false
      }
    },
    legend: {
      show: true,
      left: "left",
      selectedMode: false,
      borderRadius: 3,
      icon: "roundRect",
      textStyle: {
        color: darkgrey,
        backgroundColor: lightgreen
      },
      data: [
        {
          name: intl.formatMessage({ id: "OPEN" }),
          textStyle: {
            color: "#7979e1", //9d9dff
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 3
          }
        }
      ]
    },
    xAxis: {
      offset: 10,
      name: intl.formatMessage({ id: "MONTH" }),
      nameLocation: "end",
      nameTextStyle: {
        padding: [47, 15, 0, 0],
        color: "#7c98b6"
      },
      boundaryGap: false,
      type: "category",
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      data: Data.map((e) => {
        return {
          value: `${getMonth(e.Month - 1)} ${e.Year.toString().substring(2)}`,
          textStyle: {
            color: "#7c98b6"
          }
        };
      })
    },
    yAxis: {
      boundaryGap: false,
      //   interval: 5,
      axisLabel: {
        formatter: `{value}`,
        color: "#7c98b6"
      },
      axisLine: {
        show: false
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#eff4f8"]
        }
      },
      type: "value",
      axisTick: {
        show: false
      }
    },
    series: [
      {
        name: intl.formatMessage({ id: "OPEN" }),
        data: Data.map((e) => {
          return e.Data[1];
        }),
        type: "line",
        symbol: "circle",
        symbolSize: 7,
        itemStyle: {
          color: "#7979e1"
        },
        lineStyle: {
          color: "#7979e1"
        },
        areaStyle: {
          color: "#acacf5",
          opacity: 1
        }
      },
      {
        name: intl.formatMessage({ id: "CLOSED" }),
        data: Data.map((e) => {
          return e.Data[2];
        }),
        type: "line",
        symbol: "circle",
        symbolSize: 7,
        itemStyle: {
          color: darkred
        },
        lineStyle: {
          color: darkred
        },
        areaStyle: {
          color: red,
          opacity: 1
        }
      }
    ]
  };
});

export const ContractsChart = createChartComponent((Data, intl) => {
  return {
    grid: {
      //   left: "5%",
      //   right: "5%",
      //   bottom: "10%",
      //   top: "5%",
      containLabel: true
    },
    tooltip: {
      renderMode: "richText",
      trigger: "item",
      formatter: `{c}`,
      textStyle: {
        color: "#FFFFFF",
        fontWeight: "bold"
      },
      backgroundColor: "#002B63",
      axisPointer: {
        animation: false
      }
    },
    legend: {
      show: true,
      left: "left",
      selectedMode: false,
      borderRadius: 3,
      icon: "roundRect",
      textStyle: {
        color: darkgrey,
        backgroundColor: lightgreen
      },
      data: [
        {
          name: intl.formatMessage({ id: "OPEN" }),
          textStyle: {
            color: darkPurple,
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 3
          }
        },

        {
          name: intl.formatMessage({ id: "CLOSED" }),
          textStyle: {
            color: darkblue,
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 3
          }
        }
      ]
    },
    xAxis: {
      offset: 10,
      name: intl.formatMessage({ id: "MONTH" }),
      nameLocation: "end",
      nameTextStyle: {
        padding: [47, 15, 0, 0],
        color: "#7c98b6"
      },
      boundaryGap: false,
      type: "category",
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      data: Data.map((e) => {
        return {
          value: `${getMonth(e.Month - 1)} ${e.Year.toString().substring(2)}`,
          textStyle: {
            color: "#7c98b6"
          }
        };
      })
    },
    yAxis: {
      boundaryGap: false,
      //   interval: 5,
      axisLabel: {
        formatter: `{value}`,
        color: "#7c98b6"
      },
      axisLine: {
        show: false
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#eff4f8"]
        }
      },
      type: "value",
      axisTick: {
        show: false
      }
    },
    series: [
      {
        name: intl.formatMessage({ id: "OPEN" }),
        data: Data.map((e) => {
          return e.Data[1];
        }),
        type: "line",
        symbol: "circle",
        symbolSize: 7,
        itemStyle: {
          color: purple
        },
        lineStyle: {
          color: purple
        },
        areaStyle: {
          color: lightPurple,
          opacity: 1
        }
      },
      {
        name: intl.formatMessage({ id: "CLOSED" }),
        data: Data.map((e) => {
          return e.Data[2];
        }),
        type: "line",
        symbol: "circle",
        symbolSize: 7,
        itemStyle: {
          color: darkblue
        },
        lineStyle: {
          color: darkblue
        },
        areaStyle: {
          color: blue,
          opacity: 1
        }
      }
    ]
  };
});

export const ProjectsActivityChart = createChartComponent(
  (Data, intl, difrentCloseColor) => {
    return {
      grid: {
        //   left: "5%",
        //   right: "5%",
        //   bottom: "10%",
        //   top: "5%",
        containLabel: true
      },
      tooltip: {
        renderMode: "richText",
        trigger: "item",
        formatter: `{c}`,
        textStyle: {
          color: "#FFFFFF",
          fontWeight: "bold"
        },
        backgroundColor: "#002B63",
        axisPointer: {
          animation: false
        }
      },
      legend: {
        show: true,
        left: "left",
        selectedMode: false,
        borderRadius: 3,
        icon: "roundRect",
        textStyle: {
          color: darkgrey,
          backgroundColor: lightgreen
        },
        data: [
          {
            name: intl.formatMessage({ id: "OPEN" }),
            textStyle: {
              color: purple,
              padding: 5,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          },
          {
            name: intl.formatMessage({ id: "CLOSED" }),
            textStyle: {
              color: difrentCloseColor ? darkred : darkgreen,
              padding: 5,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          },
          {
            name: intl.formatMessage({ id: "CANCELLED" }),
            textStyle: {
              color: darkgrey,
              padding: 5,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          }
        ]
      },
      xAxis: {
        offset: 10,
        name: intl.formatMessage({ id: "MONTH" }),
        nameLocation: "start",
        nameTextStyle: {
          padding: [47, 15, 0, 0],
          color: "#7c98b6"
        },
        boundaryGap: false,
        type: "category",
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        data: Data.map((e) => {
          return {
            value: `${getMonth(e.Month - 1)} ${e.Year.toString().substring(2)}`,
            textStyle: {
              color: "#7c98b6"
            }
          };
        })
      },
      yAxis: {
        boundaryGap: false,
        //   interval: 5,
        axisLabel: {
          formatter: `{value}`,
          color: "#7c98b6"
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ["#eff4f8"]
          }
        },
        type: "value",
        axisTick: {
          show: false
        }
      },
      series: [
        {
          name: intl.formatMessage({ id: "OPEN" }),
          data: Data.map((e) => {
            return e.Data[1];
          }),
          type: "line",
          symbol: "circle",
          symbolSize: 7,
          itemStyle: {
            color: purple
          },
          lineStyle: {
            color: purple
          },
          areaStyle: {
            color: lightPurple,
            opacity: 1
          }
        },
        {
          name: intl.formatMessage({ id: "CLOSED" }),
          data: Data.map((e) => {
            return e.Data[4];
          }),
          type: "line",
          symbol: "circle",
          symbolSize: 7,
          itemStyle: {
            color: difrentCloseColor ? darkred : darkgreen
          },
          lineStyle: {
            color: difrentCloseColor ? darkred : darkgreen
          },
          areaStyle: {
            color: difrentCloseColor ? red : green,
            opacity: 1
          }
        },
        {
          name: intl.formatMessage({ id: "CANCELLED" }),
          data: Data.map((e) => {
            return e.Data[3];
          }),
          type: "line",
          symbol: "circle",
          symbolSize: 7,
          itemStyle: {
            color: darkgrey
          },
          lineStyle: {
            color: darkgrey
          },
          areaStyle: {
            color: grey,
            opacity: 1
          }
        }
      ]
    };
  }
);
