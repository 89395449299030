import React, {
  PureComponent,
  useEffect,
  useLayoutEffect,
  useRef
} from "react";
import { CSSTransition } from "react-transition-group";
import { BasicPortal } from "../Portal/Portal";

const getScrollParent = (node) => {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
};

const DropdownPopper = (props) => {
  const popupRef = useRef();

  const { anchorEl, popupContentRef, onTop, onClose, children, isLoading } =
    props;
  useLayoutEffect(() => {
    const resize = () => {
      const popupEl = popupRef.current;
      const popupContentEl = popupContentRef.current;
      const scrollParentEl = getScrollParent(anchorEl);

      const popupHeight = popupEl?.offsetHeight || 80;
      const offset = 80;

      let {
        width,
        height: baseHeight,
        top,
        bottom,
        left
      } = anchorEl.getBoundingClientRect();

      const height = onTop ? 0 : baseHeight;
      width = props.fixedWidth ? props.fixedWidth : width;
      const viewportHeight = window.innerHeight;

      let shouldUpdateUpwards;
      const viewportBottomSpacing =
        viewportHeight - (bottom + popupHeight + offset);

      if (viewportBottomSpacing < 0) {
        const viewportTopSpacing = top - offset - popupHeight;
        if (viewportTopSpacing > viewportBottomSpacing)
          shouldUpdateUpwards = true;
      }

      let scrollTop = 0;
      // let scrollBottom = 0;

      if (scrollParentEl) {
        scrollTop = scrollParentEl.getBoundingClientRect().top;
        // scrollBottom = scrollParentEl.getBoundingClientRect().bottom;
      }

      if (shouldUpdateUpwards) {
        const maxHeight = top - offset;

        let resolvedTop = top - popupHeight;
        if (onTop) resolvedTop = resolvedTop + baseHeight;
        if (resolvedTop < 0 && resolvedTop < maxHeight) {
          resolvedTop = 0 + offset;
        }

        // if (resolvedTop + popupHeight > scrollBottom) {
        //   // popupEl.style.display = "none";
        //   return;
        // }

        popupEl.style.top = resolvedTop + "px";
        popupContentEl.style.maxHeight = maxHeight + "px";
        popupEl.classList.add("up");
        anchorEl.classList.add("up");
        // popupEl.style.display = "block";
      } else {
        const resolvedTop = height + top;

        if (resolvedTop < scrollTop) {
          // popupEl.style.display = "none";
          return;
        }

        const maxHeight = viewportHeight - resolvedTop - offset;

        popupEl.style.top = resolvedTop + "px";
        popupContentEl.style.maxHeight = maxHeight + "px";
        popupEl.classList.remove("up");
        anchorEl.classList.remove("up");

        // popupEl.style.display = "block";
      }
      // const resolvedLeft = left;
      // popupEl.style.left = resolvedLeft + "px";

      const windowWidth = window.innerWidth;

      if (left + width + 1 > windowWidth) {
        const { width: anchorWidth } = anchorEl.getBoundingClientRect();
        const removeLeft = width - anchorWidth;
        popupEl.style.width = width + "px";
        popupEl.style.left = left - removeLeft + 1 + "px";
      } else {
        popupEl.style.width = width + "px";
        popupEl.style.left = left + 1 + "px";
      }
    };
    const stopResizing = () => {
      onClose();
    };

    const handleScroll = (e) => {
      const popupEl = popupRef.current;

      if (popupEl === e.target || popupEl.contains(e.target)) return;

      stopResizing();
    };

    const popupCont = popupContentRef.current;

    const timedResize = () => {
      setTimeout(() => {
        if (popupRef.current) resize();
      }, 50);
    };

    if (popupCont) {
      popupCont.addEventListener("click", timedResize);
    }

    window.addEventListener("resize", stopResizing);
    window.addEventListener("scroll", handleScroll);
    resize();
    return () => {
      if (popupCont) {
        popupCont.removeEventListener("click", timedResize);
      }
      window.removeEventListener("resize", stopResizing);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [anchorEl, onClose, onTop, popupContentRef, props.fixedWidth, isLoading]);

  useEffect(() => {
    const scrollParentEl = getScrollParent(anchorEl);
    if (!scrollParentEl) return;

    const listener = () => {
      onClose();
    };
    scrollParentEl.addEventListener("scroll", listener);

    return () => scrollParentEl.removeEventListener("scroll", listener);
  }, [anchorEl, onClose]);

  return (
    <BasicPortal>
      <div className="ar-dropdown-base" ref={popupRef}>
        {children}
      </div>
    </BasicPortal>
  );
};

class DropdownPopover extends PureComponent {
  constructor(props) {
    super(props);

    if (props.domRef) this.popupRef = props.domRef;
    else this.popupRef = React.createRef();
    this.popupContentRef = React.createRef();
    this.handleClosure = this.handleClosure.bind(this);
  }

  handleClosure = (e) => {
    if (e.target === this.popupRef) {
      return;
    } else if (
      this.popupRef &&
      this.popupRef.current &&
      !this.popupRef.current.contains(e.target)
    ) {
      this.close();
    }
  };

  close = () => {
    this.props.onClose();
    document.removeEventListener("click", this.handleClosure);
  };

  componentDidUpdate(prevProps) {
    if (!this.props.onClose) return;

    if (prevProps.isOpen === this.props.isOpen) return;
    // ////
    if (this.props.isOpen)
      document.addEventListener("click", this.handleClosure);
    else document.removeEventListener("click", this.handleClosure);
  }

  componentWillUnmount() {
    if (this.props.onClose)
      document.removeEventListener("click", this.handleClosure);
  }

  render() {
    const {
      isOpen,
      anchorEl,
      className,
      children,
      loadingComponent: LoadingComponent,
      isLoading,
      onTop,
      fixedWidth
      // DopdownPopupContentComponent: CustomComponent
    } = this.props;

    let animationClass = "dropdown-popup";

    const canOpen = isOpen && Boolean(anchorEl);
    if (!canOpen) return null;

    const compProps = {
      canOpen: canOpen,
      onClose: this.close,
      animationClass: animationClass,
      className: className,
      anchorEl: this.props.anchorEl,
      popupContentRef: this.popupContentRef
    };
    return (
      <DropdownPopper
        isLoading={isLoading}
        fixedWidth={fixedWidth}
        onTop={onTop}
        onClose={this.close}
        anchorEl={this.props.anchorEl}
        popupContentRef={this.popupContentRef}
      >
        {LoadingComponent && <LoadingComponent isLoading={isLoading} />}
        <DopdownPopupContentComponent {...compProps}>
          {children}
        </DopdownPopupContentComponent>
      </DropdownPopper>
    );
  }
}

export const DopdownPopupContentComponent = React.memo(
  ({ canOpen, animationClass, className, children, popupContentRef }) => {
		const resolvedCanOpen = Boolean(canOpen)
    return (
      <CSSTransition
        in={resolvedCanOpen}
        timeout={300}
        unmountOnExit
        appear
        classNames={animationClass}
        // onExited={handleExited}
      >
        <div
          ref={popupContentRef}
          // scheduleUpdate={scheduleUpdate}
          className={`${animationClass} my-0 border bg-white of-y-auto ${
            className ? className : ""
          }`}
        >
          {children}
        </div>
      </CSSTransition>
    );
  }
);

export default DropdownPopover;
