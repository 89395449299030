import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import querystring from "query-string";
import {
  MainViewsSearchLSKey,
  MainViewsSearchCommunicator,
  NewMainViewsSearchLSKey,
  LastUsedSpaceLSKey
} from "../../Helpers/MiscHelper";
import { useMainViewSearchDict } from "../../Components/Header/HeaderV2";
import { HandleQueryStringObjectMaker } from "../../Helpers/QueryStringerHelper";

export const lsDashboardKey = "ar-dashboard-view";

const saveToLocalStorageKey = (newSearch, key) => {
  const name = key;
  const searchDictString = localStorage.getItem(MainViewsSearchLSKey);

  const lastUsedSpace = localStorage.getItem(LastUsedSpaceLSKey);

  const NewDictString = localStorage.getItem(
    NewMainViewsSearchLSKey + lastUsedSpace
  );
  const NullNewDictString = localStorage.getItem(
    NewMainViewsSearchLSKey + "null"
  );

  let searchDict;

  if (NewDictString) searchDict = JSON.parse(NewDictString);
  else if (NullNewDictString) {
    localStorage.setItem(
      NewMainViewsSearchLSKey + lastUsedSpace,
      NullNewDictString
    );
    localStorage.removeItem(NewMainViewsSearchLSKey + "null");
    searchDict = JSON.parse(NullNewDictString);
  } else if (searchDictString) {
    searchDict = JSON.parse(searchDictString);
  } else searchDict = {};

  const parsedNewSearch = querystring.parse(newSearch);
  const resolvedNewSearch = HandleQueryStringObjectMaker(parsedNewSearch);
  searchDict[name] = querystring.stringify(resolvedNewSearch);

  localStorage.setItem(
    NewMainViewsSearchLSKey + lastUsedSpace,
    JSON.stringify(searchDict)
  );
  MainViewsSearchCommunicator.dispatch(searchDict);
};

export const useDashboardViewUpdater = (dashboardLayout) => {
  const dict = useMainViewSearchDict() || {};
  const search = dict[lsDashboardKey];
  useEffect(() => {
    const { lsKey } = dashboardLayout;
    const { view } = querystring.parse(search);

    if (view === lsKey) return;

    saveToLocalStorageKey(`view=${lsKey}`, lsDashboardKey);
  }, [dashboardLayout, search]);
};

export const useDashboardLayoutState = (layouts, customLayouts) => {
  const location = useLocation();

  const initialValue = () => {
    const { search } = location;

    const queryObj = querystring.parse(search);

    const { view } = queryObj;

    if (view) {
      const layout = layouts.find((l) => l.lsKey === view);
      const custom = customLayouts.find((l) => l.lsKey === view);
      if (custom) return custom;
      if (!layout) return layouts[0];
      return layout;
    }

    return layouts[0];
  };

  return useState(initialValue);
};
