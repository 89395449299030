import React from "react";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useAccount } from "../../Hooks/EntityHooks";
import { accountSchema } from "../../config/schema";
import { DashboardTitle, DashboardEmptyResponse } from "./Dashboard";
import { faBirthdayCake } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import classnames from "classnames";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import Moment from "react-moment";
import { useIntl, FormattedMessage } from "react-intl";
import { useDashboardQuery } from "./DashboardQueryHelper";

const AniversaryDateComponent = ({
  date,
  messages,
  hideHours,
  alternateFormat,
  removeSlice,
  className,
  ...rest
}) => {
  const intl = useIntl();
  const todayDate = new Date();
  const todayDay = todayDate.getDate();
  const todayMonth = todayDate.getMonth() + 1;
  const todayYear = todayDate.getFullYear();
  const targetDay = date.getDate();
  const targetMonth = date.getMonth() + 1;
  const targetYear = date.getFullYear();
  const isToday =
    targetDay === todayDay &&
    targetMonth === todayMonth &&
    targetYear === todayYear;
  const isYesterday =
    targetDay === todayDay - 1 &&
    targetMonth === todayMonth &&
    targetYear === todayYear;
  const isTomorow =
    targetDay === todayDay + 1 &&
    targetMonth === todayMonth &&
    targetYear === todayYear;

  let today = intl.formatMessage({ id: "TODAY" }),
    yesterday = intl.formatMessage({ id: "YESTERDAY" }),
    tomorow = intl.formatMessage({ id: "TOMOROW" });

  if (messages) {
    if (messages.today) today = messages.today;
    if (messages.yesterday) yesterday = messages.yesterday;
    if (messages.tomorow) tomorow = messages.tomorow;
  }

  let stringDate = isToday ? (
    `${today}, ${targetDay}`
  ) : isYesterday ? (
    `${yesterday}, ${targetDay}`
  ) : isTomorow ? (
    `${tomorow}, ${targetDay}`
  ) : (
    <Moment date={date} format="dddd, DD" />
  );

  return (
    <div
      style={{ textTransform: "capitalize" }}
      className={classnames(
        "text-truncate p-1 rounded fs-12",
        {
          "ar-aniversaryDateComponent-past": date < todayDate,
          "ar-aniversaryDateComponent-present": isToday,
          "ar-aniversaryDateComponent-future": date > todayDate
        },
        className
      )}
      {...rest}
    >
      {stringDate}
    </div>
  );
};

const AniversaryComponent = ({ Value }) => {
  const Account = useAccount(Value);
  return (
    <div className="d-flex py-2 pl-4 pr-4 align-items-center justify-content-between">
      <ConnectedAccountPopup account={Value} />
      <AniversaryDateComponent date={new Date(Account.Birthday)} />
    </div>
  );
};

const DashboardAniversaryOverview = (props) => {
  const today = moment();
  const weekStart = moment(today).startOf("isoWeek");
  // weekStart.add(1, "days");
  const weekEnd = moment(today).endOf("isoWeek");
  // weekEnd.add(2, "days");
  //   weekEnd.day(1);

  const { data, loading, error } = useDashboardQuery(
    `Birthdays?daysRangeLeft=${today.diff(
      weekStart,
      "days"
    )}&daysRangeRight=${weekEnd.diff(today, "days")}`,
    [accountSchema],
    { cache: true }
  );

  const buildBody = () => {
    if (loading || error)
      return <LoaderSpinner size="sm text-secondary" center />;

    if (data?.length === 0)
      return (
        <div className="w-100 h-100 of-y-auto">
          <div
            style={{ top: 0 }}
            className="d-flex position-sticky fw-medium align-items-center text-black w-100 fs-14 py-2 pl-3 bg-lightBlue"
          >
            <span className="mr-1" style={{ textTransform: "capitalize" }}>
              <FormattedMessage id={"BIRTHDAY_FROM"} />
            </span>
            {`${weekStart.format("DD MMM, YYYY")} `}
            <span
              className="mx-1  white-space-pre"
              style={{ textTransform: "none" }}
            >
              <FormattedMessage id={"BIRTHDAY_TO"} />
            </span>
            {` ${weekEnd.format("DD MMM, YYYY")}`}
          </div>

          <DashboardEmptyResponse className="h-75" type="birthday">
            <FormattedMessage id={"NO_BIRTHDAY_TODAY"} />
          </DashboardEmptyResponse>
        </div>
      );

    return (
      <div className="w-100 h-100 of-y-auto">
        <div
          style={{ top: 0 }}
          className="d-flex position-sticky fw-medium align-items-center text-black w-100 fs-14 py-2 pl-3 bg-lightBlue"
        >
          <span className="mr-1" style={{ textTransform: "capitalize" }}>
            <FormattedMessage id={"BIRTHDAY_FROM"} />
          </span>
          {`${weekStart.format("DD MMM, YYYY")} `}
          <span style={{ textTransform: "none" }} className="mx-1 white-space-pre">
            <FormattedMessage id={"BIRTHDAY_TO"} />
          </span>
          {` ${weekEnd.format("DD MMM, YYYY")}`}
        </div>
        {data.map((e, i) => {
          return (
            <div
              key={i}
              className={classnames("w-100", { "c-isOdd": i % 2 === 1 })}
            >
              <AniversaryComponent Value={e} />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      <DashboardTitle
        text={<FormattedMessage id={"WHOS_NEXT"} />}
        icon={faBirthdayCake}
      >
        <div className="mt-1 text-black fs-14">
          <FormattedMessage id={"BIRTHDAY_TODAY"} />
        </div>
      </DashboardTitle>
      {buildBody()}
    </React.Fragment>
  );
};

export default DashboardAniversaryOverview;
