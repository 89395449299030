import React, { useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import classes from "./CalendarWeekView.module.css";
import {
  calendarToEpochDays,
  useCalendarDateUrl,
  useReftchRefProvider,
  weekDayTranslation
} from "../../CalendarHelper";
import { FormattedMessage } from "react-intl";
import { many } from "../../../../Helpers/SchemaHelper";
import { useToast } from "../../../Toast/ToastProvider";
import { useQuery } from "../../../../Helpers/IOClient";
import { useMultipleEntityValueSelector } from "../../../../Hooks/AdvancedEntityHooks";
import { getResolvedSizes, getSize } from "./CalendarWeekViewHelper";
import { useHoverEvents } from "../../../../Containers/Origin/Origin";
import Popup from "../../../Popup/Popup";
import {
  buildItems,
  buildResolvedValueItem,
  sortRowItems
} from "../CalendarMonthView/CalendarMonthView";
import { useEntitySummarySidebar } from "../../../EntitySummaries/EntitySummariesHelper";
import { BarLoader } from "../../../GlobalLoader/GlobalLoader";
import { ColorFilterBars } from "../../../FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";

export const WeeklyHeaderDay = ({ time, i }) => {
  const { dayOfMonth } = useMemo(() => {
    const d = new Date(time + dayMilsec * i);

    const dayOfMonth = d.getDate();
    return { dayOfMonth };
  }, [i, time]);

  return (
    <div
      className={classnames(
        "d-flex justify-content-center text-black text-uppercase fs-14",
        classes.headerDay
      )}
    >
      <FormattedMessage id={weekDayTranslation[i]} /> {dayOfMonth}
    </div>
  );
};
const dayMilsec = 86400000;
const WeekDayHeader = React.memo(function WeekDayHeader({ startMil }) {
  return (
    <div className={classnames("d-flex", classes.headerContainer)}>
      <div className={classnames("d-flex flex-1")}>
        <WeeklyHeaderDay i={0} time={startMil} />
        <WeeklyHeaderDay i={1} time={startMil} />
        <WeeklyHeaderDay i={2} time={startMil} />
        <WeeklyHeaderDay i={3} time={startMil} />
        <WeeklyHeaderDay i={4} time={startMil} />
        <WeeklyHeaderDay i={5} time={startMil} />
        <WeeklyHeaderDay i={6} time={startMil} />
      </div>
      <div className={classes.fakeScroll} />
    </div>
  );
});

const getAllWeekDays = (year, month, day) => {
  const date = new Date(year, month, day);
  const end = 6 - date.getDay();
  const start = date.getDay();
  const endDate = new Date(year, month, day + end);

  const startDate = new Date(year, month, day - start, 18);

  return { startDate, endDate, month, startDateMil: startDate.getTime() };
};

window.getAllWeekDays = getAllWeekDays;

const HourItem = ({ hour }) => {
  return (
    <div
      className={classnames(
        "p-1 d-flex justify-content-center fs-14",
        classes.hourItem
      )}
    >
      {hour}
    </div>
  );
};

export const CalendarHourList = () => {
  return (
    <div className={classes.hourList}>
      <HourItem hour="00:00" />
      <HourItem hour="01:00" />
      <HourItem hour="02:00" />
      <HourItem hour="03:00" />
      <HourItem hour="04:00" />
      <HourItem hour="05:00" />
      <HourItem hour="06:00" />
      <HourItem hour="07:00" />
      <HourItem hour="08:00" />
      <HourItem hour="09:00" />
      <HourItem hour="10:00" />
      <HourItem hour="11:00" />
      <HourItem hour="12:00" />
      <HourItem hour="13:00" />
      <HourItem hour="14:00" />
      <HourItem hour="15:00" />
      <HourItem hour="16:00" />
      <HourItem hour="17:00" />
      <HourItem hour="18:00" />
      <HourItem hour="19:00" />
      <HourItem hour="20:00" />
      <HourItem hour="21:00" />
      <HourItem hour="22:00" />
      <HourItem hour="23:00" />
    </div>
  );
};

// const DayItem

const ContentPlaceholderGridItem = () => {
  return <div className={classes.dayHourItem}></div>;
};

const baseHeight = 60;

const hourMilsec = dayMilsec / 24;
window.getSize = getSize;

const ItemDetails = ({ settings, item }) => {
  const { Id } = item.metadata;

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  const anchorRef = useRef();
  const openForm = useEntitySummarySidebar();

  const { moduleType, EntityPopup, WeeklyItem } = settings;

  const handleClick = (e) => {
    onAnchorMouseLeave();
    openForm({
      type: moduleType,
      props: {
        id: Id
      }
    });
  };

  return (
    <>
      <div
        onClick={handleClick}
        className="h-100 p-1 text-truncate"
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
        ref={anchorRef}
      >
        <ColorFilterBars entity={item.metadata} />
        <WeeklyItem item={item} />
      </div>
      <Popup
        placement="left"
        anchorEl={anchorRef.current}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <EntityPopup entityId={Id} />
        {/* <TaskOriginPopup task={value} /> */}
      </Popup>
    </>
  );
};
const GridColmnItem = ({ item, gridDate, left, width, settings }) => {
  const { BeginDate, EndDate } = item;

  const maxDate = new Date(gridDate);
  maxDate.setDate(maxDate.getDate() + 1);

  // const resolvedBeginDate = BeginDate < gridDate ? gridDate : BeginDate;
  // const resolvedEndDate = EndDate > maxDate ? maxDate : EndDate;

  const durationMil = EndDate.getTime() - BeginDate.getTime();

  const height = (durationMil * baseHeight) / hourMilsec;

  const startMil = BeginDate.getHours();

  const top = startMil * baseHeight;
  // const startMil = resolvedBeginDate.getTime() - gridDate.getTime();

  // const top = (startMil * baseHeight) / hourMilsec;

  return (
    <div
      something={item.metadata.Id}
      style={{ width: `${width}%`, left: `${left}%`, height, top }}
      className={classnames(
        "p-1 text-el overflow-hidden text-truncate",
        classes.columnItem
      )}
    >
      <ItemDetails item={item} settings={settings} />
      {/* <div title={item.metadata.Id} className="h-100 p-1 text-truncate">
        {item.metadata.Id}
      </div> */}
    </div>
  );
};

const GridColumnItems = ({ items, gridDate, settings }) => {
  const resolvedValues = useMemo(
    () => items.length > 0 && getResolvedSizes(items),
    [items]
  );

  if (items.length === 0) return null;

  // const itemsPerLevel = [];
  // for (const item of items) {
  //   const { BeginDate, EndDate, metadata } = item;

  //   let currentLevel = 0;

  //   const resolvedBeginDate =
  //     BeginDate < gridDate ? new Date(gridDate) : BeginDate;
  //   const resolvedEndDate =
  //     EndDate.getTime() > gridDate.getTime() + dayMilsec
  //       ? new Date(gridDate.getTime() + dayMilsec)
  //       : EndDate;

  //   const newItem = {
  //     BeginDate: resolvedBeginDate,
  //     EndDate: resolvedEndDate,
  //     metadata,
  //   };

  //   let hasFinished = false;

  //   while (!hasFinished) {
  //     const doesConflict = doItemsConflict(item, itemsPerLevel[currentLevel]);
  //     if (doesConflict) currentLevel++;
  //     else {
  //       hasFinished = true;
  //     }
  //   }

  //   if (!itemsPerLevel[currentLevel]) itemsPerLevel[currentLevel] = [];

  //   itemsPerLevel[currentLevel].push(newItem);
  // }
  // console.log(gridDate);
  // console.log(itemsPerLevel);

  return resolvedValues.map((item, i) => {
    return (
      <GridColmnItem
        settings={settings}
        left={item.left}
        width={item.width}
        gridDate={gridDate}
        item={item.value}
        key={item.value.metadata.Id}
      />
    );
  });
};

const hourHeight = 60;

export const WeeklyContentGridColumn = ({
  currentDate,
  startDateMil,
  i,
  items,
  settings,
  containerRef
}) => {
  const { isSelected, gridDate } = useMemo(() => {
    const gridDate = new Date(startDateMil + i * dayMilsec);
    const isSelected = currentDate.getTime() === startDateMil + i * dayMilsec;
    return { isSelected, gridDate };
  }, [currentDate, i, startDateMil]);

  const openSidebar = useEntitySummarySidebar();
  const handleDoubleClick = (e) => {
    const { moduleType } = settings;
    const { clientY } = e;
    const elem = containerRef.current;
    const { top } = elem.getBoundingClientRect();
    const clickOffset = clientY - top;
    const { scrollTop } = elem;
    const position = clickOffset + scrollTop;
    const hoursAhead = Math.floor(position / hourHeight);
    const newStartDate = new Date(gridDate);
    newStartDate.setHours(hoursAhead);
    const newEndDate = new Date(newStartDate);
    newEndDate.setHours(newEndDate.getHours() + 1);
    openSidebar({
      type: moduleType,
      props: {
        defaultForm: {
          BeginDate: newStartDate,
          EndDate: newEndDate
        }
      }
    });
  };

  return (
    <div
      onDoubleClick={handleDoubleClick}
      className={classnames("flex-1", classes.dayHourColumn, {
        [classes.selected]: isSelected
      })}
    >
      <GridColumnItems settings={settings} gridDate={gridDate} items={items} />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
      <ContentPlaceholderGridItem />
    </div>
  );
};

const ContentGrid = ({ startDateMil, items, settings, containerRef }) => {
  const currentDate = useMemo(() => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }, []);

  const { gridItems } = items;

  return (
    <div className="flex-1 d-flex">
      <WeeklyContentGridColumn
        containerRef={containerRef}
        settings={settings}
        startDateMil={startDateMil}
        i={0}
        currentDate={currentDate}
        items={gridItems[0]}
      />
      <WeeklyContentGridColumn
        containerRef={containerRef}
        settings={settings}
        startDateMil={startDateMil}
        i={1}
        currentDate={currentDate}
        items={gridItems[1]}
      />
      <WeeklyContentGridColumn
        containerRef={containerRef}
        settings={settings}
        startDateMil={startDateMil}
        i={2}
        currentDate={currentDate}
        items={gridItems[2]}
      />
      <WeeklyContentGridColumn
        containerRef={containerRef}
        settings={settings}
        startDateMil={startDateMil}
        i={3}
        currentDate={currentDate}
        items={gridItems[3]}
      />
      <WeeklyContentGridColumn
        containerRef={containerRef}
        settings={settings}
        startDateMil={startDateMil}
        i={4}
        currentDate={currentDate}
        items={gridItems[4]}
      />
      <WeeklyContentGridColumn
        containerRef={containerRef}
        settings={settings}
        startDateMil={startDateMil}
        i={5}
        currentDate={currentDate}
        items={gridItems[5]}
      />
      <WeeklyContentGridColumn
        containerRef={containerRef}
        settings={settings}
        startDateMil={startDateMil}
        i={6}
        currentDate={currentDate}
        items={gridItems[6]}
      />
    </div>
  );
};

const selector = (v) => {
  return v;
};

const sortColumnItems = (items) => {
  // const minRowDay = gridNum * 7 + 1;
  // const maxRowDay = minRowDay + 6;

  // const newArr = [];
  // for (const item of items) {
  //   const { gridStartDay, gridEndDay } = item;
  //   const rowStartDay = gridStartDay < minRowDay ? minRowDay : gridStartDay;
  //   const rowEndDay = gridEndDay > maxRowDay ? maxRowDay : gridEndDay;
  //   const totalRowDays = rowEndDay - rowStartDay + 1;
  //   newArr.push({ ...item, rowStartDay, rowEndDay, totalRowDays });
  // }

  const sortedArr = items.sort((a, b) => {
    return a.epochStart - b.epochStart || a.epochEnd - b.epochEnd;
  });

  return sortedArr;
};

export const buildResolvedWeeklyValueItem = (v, settings, startDate) => {
  const { BeginDateFieldObj, EndDateFieldObj } = settings;
  let BeginDate = new Date(
    BeginDateFieldObj ? v[BeginDateFieldObj] : v.BeginDate
  );
  BeginDate.setSeconds(0);
  BeginDate.setMilliseconds(0);
  let EndDate = new Date(
    EndDateFieldObj ? v[EndDateFieldObj] : v.EndDate || BeginDate
  );
  EndDate.setSeconds(0);
  EndDate.setMilliseconds(0);

  let epochStart = BeginDate.getTime();
  let epochEnd = EndDate.getTime();

  if (epochEnd - epochStart < 3600000) {
    EndDate.setHours(BeginDate.getHours() + 1);
    EndDate.setMinutes(0);
    epochEnd = EndDate.getTime();
  }

  const epochStartDay = calendarToEpochDays(BeginDate);
  const epochEndDay = calendarToEpochDays(EndDate);

  const epochGridStartDate = calendarToEpochDays(startDate);

  let gridStartDay = epochStartDay - epochGridStartDate;
  let gridEndDay = epochEndDay - epochGridStartDate;

  if (gridEndDay > 7) {
    gridEndDay = 7;
  }

  const newV = {
    gridStartDay,
    gridEndDay,
    epochStart,
    epochEnd,
    // epochStartDay,
    // epochEndDay,
    // totalDays: epochEndDay - epochStartDay,
    BeginDate,
    EndDate,
    metadata: v
  };

  return newV;
};

const useWeekGridItems = (data, startDate, settings) => {
  return useMemo(() => {
    const gridItems = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] };
    if (!data || data.length === 0) {
      return { gridItems };
    }

    const resolvedResults = data.map((v) =>
      buildResolvedWeeklyValueItem(v, settings, startDate)
    );

    const outOfGridItems = [];

    for (const item of resolvedResults) {
      const { epochStart, epochEnd, gridStartDay, gridEndDay } = item;

      if (epochEnd - epochStart >= dayMilsec) {
        outOfGridItems.push(item);
        continue;
      }

      const firstGrid = gridStartDay;
      const lastGrid = gridEndDay;

      for (let i = firstGrid; i <= lastGrid; i++) {
        if (gridItems[i]) gridItems[i].push(item);
      }
    }

    gridItems[0] = sortColumnItems(gridItems[0]);
    gridItems[1] = sortColumnItems(gridItems[1]);
    gridItems[2] = sortColumnItems(gridItems[2]);
    gridItems[3] = sortColumnItems(gridItems[3]);
    gridItems[4] = sortColumnItems(gridItems[4]);
    gridItems[5] = sortColumnItems(gridItems[5]);
    gridItems[6] = sortColumnItems(gridItems[6]);

    // console.log(gridItems);

    return { gridItems, outOfGridItems };
  }, [data, settings, startDate]);
};

const ItemsHeader = ({ outOfGridItems, settings, startDate }) => {
  const x = useMemo(() => {
    const x = outOfGridItems.map((v) =>
      buildResolvedValueItem(v.metadata, settings, startDate)
    );
    return sortRowItems(x, 0);
  }, [outOfGridItems, settings, startDate]);

  const { children, elapsedDays } = buildItems(x, 0, 3, startDate, settings);
  return (
    <div
      className="d-flex border-bottom"
      style={
        {
          // padding: "4px 0px 0px 52px",
        }
      }
    >
      <div
        className={classnames(
          classes.hourList,
          classes.hourItem,
          "h-100 border-0"
        )}
      ></div>
      <div
        className="flex-1"
        style={{
          position: "relative",
          height: 29 * elapsedDays,
          margin: "4px 0px 0px 0px"
        }}
      >
        {children}
      </div>
      <div className={classes.fakeScroll} />
    </div>
  );
};

const InnerCalendarView = ({ dateInfo, settings, data, loading }) => {
  const { startDateMil, startDate } = dateInfo;

  const { schema } = settings;
  const connectedResults = useMultipleEntityValueSelector(
    schema,
    data,
    selector
  );

  const items = useWeekGridItems(connectedResults, startDate, settings);

  const { outOfGridItems } = items;

  const containerRef = useRef();

  return (
    <div
      className={classnames(
        "flex-1 w-100 d-flex flex-column overflow-hidden position-relative rounded",
        classes.weekView
      )}
    >
      <BarLoader isLoading={loading} />
      <WeekDayHeader startMil={startDateMil} />
      {outOfGridItems && outOfGridItems.length > 0 && (
        <ItemsHeader
          outOfGridItems={outOfGridItems}
          startDate={startDate}
          settings={settings}
        />
      )}
      <div
        ref={containerRef}
        className={classnames("w-100 flex-1", classes.gridContainer)}
      >
        <div className="d-flex w-100">
          <CalendarHourList />
          <ContentGrid
            containerRef={containerRef}
            settings={settings}
            items={items}
            startDateMil={startDateMil}
          />
        </div>
      </div>
    </div>
  );
};

const CalendarWeekView = ({ selectedDate, settings }) => {
  const [resolvedDate, setResolvedDate] = useState(selectedDate);
  const refetchRef = useReftchRefProvider();
  const dateInfo = useMemo(() => {
    const year = resolvedDate.getFullYear();
    const month = resolvedDate.getMonth();
    const day = resolvedDate.getDate();
    return getAllWeekDays(year, month, day);
  }, [resolvedDate]);

  const url = useCalendarDateUrl(dateInfo, settings);

  const { schema } = settings;
  const RSchema = useMemo(
    () => ({
      d: {
        results: many(schema)
      }
    }),
    [schema]
  );

  const createToast = useToast();
  const [data, setData] = useState([]);

  useEffect(() => {
    const { communicator } = settings;

    if (!communicator) return;

    const unsub = communicator.subscribe((e) => {
      const { type, id } = e;
      if (type !== "creation") return;
      setData((d) => [...d, id]);
      // console.log(v);
    });
    return unsub;
  }, [settings]);

  const { loading, refetch } = useQuery(url, RSchema, {
    onSuccess: ({ data }) => {
      setData(data.d.results);
      // if (data.d.__count > top)
      //   createToast({
      //     pos: "tm",
      //     type: "danger",
      //     description: <FormattedMessage id="CALENDAR_GENERAL_ERROR" />,
      //     title: <FormattedMessage id="ERROR" />,
      //   });
    },
    onError: () => {
      createToast({
        pos: "tm",
        type: "danger",
        description: <FormattedMessage id="CALENDAR_GENERAL_ERROR" />,
        title: <FormattedMessage id="ERROR" />
      });
    },
    cache: false
  });

  useEffect(() => {
    refetchRef.current = refetch;
  }, [refetch, refetchRef]);

  const hasMountedRef = useRef(false);

  useEffect(() => {
    if (!hasMountedRef.current) {
      hasMountedRef.current = true;
      return;
    }

    setData([]);
    setResolvedDate(selectedDate);
  }, [selectedDate]);

  return (
    <InnerCalendarView
      loading={loading}
      settings={settings}
      data={data}
      dateInfo={dateInfo}
    />
  );
};

export default CalendarWeekView;
