/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  FC,
  FunctionComponent,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  MouseEvent,
  useRef,
  useState
} from "react";
import { useSpace } from "../../Contexts/SpaceContext";

import { ObfuscateProvider } from "../Space/Space2";
import { PersonalHistoryButton } from "../PersonalHistory/PersonalHistory";
import ReceptionInboxButton from "../Reception/ReceptionInboxButton";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";
import Popup, { usePopupOpenState } from "../../Components/Popup/Popup";
import Button from "../../Components/Button/Button";
import {
  commercialGroup,
  contactsGroup,
  IMainRoute,
  MarketingGroup,
  reportDashboardRoute,
  reportingOverview,
  ReportsGroupMaster,
  RouteAccessDict,
  servicesGroup,
  documentsGroup,
  settingsRoute,
  useSpaceMainView,
  useSpaceRoutesAccess,
  documentsRoute,
  templateRoute
} from "../Space/SpaceHelper";
import MaintenanceIcon from "../Maintenance/MaintenanceIcon";
import {
  TrialPlanHeaderButton,
  CancelationPlanHeaderButton
} from "../BillingPlans/BillingPlanPayment";
import PersonalExportButton, {
  hasAnyExportPermission
} from "../Accounts/ExportsGrid/PersonalExportsButton";
import { TimeTrackingFormButton } from "../TimeTracking/TimeTracking";
import NotificationsBell from "../Notifications/NotificationsBell";
import AccountPopup, { AccountSpaceSelector } from "../AccountPopup";
import classnames from "classnames";
import GlobalSearch from "../../Components/GlobalSearch/GlobalSearch";
import classes from "./AppBar.module.css";
import {
  HeaderNavLink,
  MainViewSearchContext,
  useMainViewSearchDict
} from "../../Components/Header/HeaderV2";
import { lsDashboardKey } from "../Dashboard/DashboardHelper";
import { matchPath, NavLink, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as AirdeskLogo } from "../../Assets/Svg/airdesk_icon_med.svg";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderPlus,
  faStar as faStarLight,
  faSidebar as faWindowAlt,
  faArrowRightArrowLeft,
  faThumbtack,
  faPlus as faPlusLight,
  faChevronDoubleLeft,
  faCog,
  faChevronDown,
  faFileAlt
} from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import { useHoverEvents } from "../Origin/Origin";
import OmniButton from "../OmniButton/OmniButton";
import {
  faAnalytics,
  faBuilding,
  faHandshake,
  faHouse,
  faFileInvoice,
  faShareAlt,
  faStar,
  faUserCog,
  faPlusLarge as faPlus,
  faEllipsis,
  faLifeRing,
  faBook,
  faBallot,
  faBracketsCurly,
  faEnvelope,
  faAddressCard,
  faBullseye,
  faMessagesDollar,
  faMessages,
  faPenRuler,
  faFileLines,
  faFiles
} from "@fortawesome/pro-duotone-svg-icons";
import AppBarFavorites from "./AppBarFavoritesWithLibrary";
import Modal from "../../Components/Modal/Modal";

import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import {
  AppTreeValueContext,
  AppTreeSetValueContext,
  AppTreeChildFavoriteCreatorContext,
  AppTreeChildFolderCreatorContext,
  AppDraggingFavoriteContext
} from "./AppBarHelper";
import {
  AppBarFavoriteQueryContext,
  AppBarFavoriteTreeSetterResponse,
  IAppBarFavoriteContet,
  IAppBarFavoriteTreeSetterResponseProps
} from "./AppBarFavoriteQuery";
import { AppFavoriteContext } from "./AppFavoriteProvider";
import { TreeItem } from "@atlaskit/tree";
import FavoritesCreator, {
  FavoriteCreatorTypes,
  FontAwesomeIconsProvider
} from "./AppBarFavoriteCreator";
import {
  MainViewsSearchLSKey,
  LastUsedSpaceLSKey,
  NewMainViewsSearchLSKey
} from "../../Helpers/MiscHelper";
import { getAlterEgo } from "../../Helpers/AuthHelper";
import { useUnreadEntities } from "../../Components/UnreadEntities/UnreadEntities";
import Badge from "../../Components/Badge/Badge";
import { useGroupUnreadCount } from "../../Components/Header/HeaderLinkGroup";
import {
  useCurrentAccount,
  useCurrentAccountSpace
} from "../../Contexts/UserContext";
import { AccountSpace } from "../../Interfaces/EntityInterfaces";
import { faChevronLeft, faThumbTack } from "@fortawesome/pro-solid-svg-icons";
import AppbarAccordion from "./AppBarAccordion/AppbarAccordion";
import Switch from "../../Components/Switch/Switch";
import { Intl18nContext } from "../Intl18n/Intl18nProvider";

interface AppBarProps {
  disableHeader: boolean;
  objuscateButtons: boolean;
}

const AccountHelpToggler = () => {
  const [status, setStatus] = useState(() => {
    const { $airdesk } = window as any;
    return $airdesk.status || false;
  });
  const intl = useIntl();

  const handleChange = (e: any) => {
    const { checked } = e.target;
    setStatus(checked);
    var { $airdesk } = window as any;
    if ($airdesk) {
      if (checked) {
        $airdesk.visible();
        $airdesk.status = true;
        $airdesk.open();
      } else {
        $airdesk.hidden();
        $airdesk.status = false;
      }
    }
  };

  const handleNameClick = () => {
    var { $airdesk } = window as any;
    if ($airdesk) {
      if (!status) {
        $airdesk.visible();
        $airdesk.status = true;
        $airdesk.open();
        setStatus(true);
      } else {
        $airdesk.hidden();
        $airdesk.status = false;
        setStatus(false);
      }
    }
  };

  return (
    <div
      className={classnames(
        "ar-header-rounded-icon position-relative d-flex align-items-center flex-0-0-auto",
        classes.appBarButtonWithLabel,
        "fs-14 fw-regular"
      )}
    >
      <FontAwesomeIcon
        icon={faMessages}
        className="flex-0-0-auto"
        size={"lg"}
      />
      <span
        onClick={handleNameClick}
        style={{ fontWeight: 500, textDecoration: "none" }}
        className="ml-3 flex-1 text-truncate"
      >
        <FormattedMessage id="REQUEST_HELP" />
      </span>

      <Switch
        handleClassName="shadow-none"
        className="sm flex-0-0-auto"
        checked={status}
        onChange={handleChange}
      />
    </div>
  );
};

const AppBarResizer = (props: any) => {
  const { appBarRef, isFixed } = props as any;
  let startX: number, startWidth: number | null;
  const timeoutRef = useRef(null);
  const ResizerRef = useRef(null);

  function initDrag(e: any) {
    startX = e.clientX;
    //@ts-ignore
    ResizerRef.current.classList.add(classes.appBarResizerBarDragging);
    appBarRef.current.classList.add(classes.appBarStopHideWhileDragging);
    const doc = document.defaultView || null;
    startWidth =
      doc && parseInt(doc.getComputedStyle(appBarRef.current).width, 10);
    //startHeight = parseInt(document.defaultView.getComputedStyle( resizable ).height, 10);
    document.documentElement.addEventListener("mousemove", doDrag, false);
    document.documentElement.addEventListener("mouseup", stopDrag, false);
  }

  function doDrag(e: any) {
    const newWidth = startWidth + e.clientX - startX;
    appBarRef.current.style.width =
      newWidth > 480
        ? 480 + "px"
        : newWidth < 240
        ? 240 + "px"
        : newWidth + "px";
    //resizable.style.height = (startHeight + e.clientY - startY) + 'px';
    if (isFixed) {
      //@ts-ignore
      clearTimeout(timeoutRef.current);
      //@ts-ignore
      timeoutRef.current = setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 300);
    }
  }

  function stopDrag(e: any) {
    appBarRef.current.classList.remove(classes.appBarStopHideWhileDragging);
    //@ts-ignore
    ResizerRef.current.classList.remove(classes.appBarResizerBarDragging);
    document.documentElement.removeEventListener("mousemove", doDrag, false);
    document.documentElement.removeEventListener("mouseup", stopDrag, false);
  }

  return (
    <div
      ref={ResizerRef}
      onMouseDown={initDrag}
      className={classes.appBarResizerBar}
    ></div>
  );
};

const AccountHelpOptions = () => {
  const ObfuscateState = useContext(ObfuscateProvider);
  const { Id: spaceId } = useSpace();
  const { value } = useContext(Intl18nContext);
  return (
    <>
      <div
        style={{
          opacity: ObfuscateState && 0.3,
          pointerEvents: ObfuscateState && "none"
        }}
      >
        <NavLink
          to={`/s/${spaceId}/welcome`}
          activeClassName={classes["appBarActiveClass"]}
          className={classnames(
            "ar-header-rounded-icon position-relative d-flex align-items-center flex-0-0-auto",
            classes.appBarButtonWithLabel,
            "fs-14 fw-regular"
          )}
        >
          <FontAwesomeIcon
            className="flex-0-0-auto"
            icon={faBallot}
            size={"lg"}
          />

          <span
            style={{ fontWeight: 500, textDecoration: "none" }}
            className="ml-3 flex-1 text-truncate"
          >
            <FormattedMessage id="WELCOME_VIEW_LINK" />
          </span>
        </NavLink>
      </div>
      <a
        href={
          value.Value === "pt"
            ? `https://docs.airdesk.ai/`
            : "https://en.docs.airdesk.ai/"
        }
        target="_blank"
        rel="noopener noreferrer"
        className={classnames(
          "ar-header-rounded-icon position-relative d-flex align-items-center flex-0-0-auto",
          classes.appBarButtonWithLabel,
          "fs-14 fw-regular"
        )}
        // onClick={this.props.close}
      >
        <FontAwesomeIcon className="flex-0-0-auto" icon={faBook} />
        <span
          style={{ fontWeight: 500, textDecoration: "none" }}
          className="ml-3 flex-1 text-truncate"
        >
          <FormattedMessage id="KNOWLEDGE_BASE" />
        </span>
      </a>
      <a
        href="https://airdesk.stoplight.io/"
        target="_blank"
        rel="noopener noreferrer"
        className={classnames(
          "ar-header-rounded-icon position-relative d-flex align-items-center flex-0-0-auto",
          classes.appBarButtonWithLabel,
          "fs-14 fw-regular"
        )}
        // onClick={this.props.close}
      >
        <FontAwesomeIcon className="flex-0-0-auto" icon={faBracketsCurly} />
        <span
          style={{ fontWeight: 500, textDecoration: "none" }}
          className="ml-3 flex-1 text-truncate"
        >
          <FormattedMessage id="API_REFERENCE" />
        </span>
      </a>
      <AccountHelpToggler />
    </>
  );
};

// const AirdeskIcon = ({ className }: { className?: string }) => {
//   const dict = useMainViewSearchDict() || {};
//   const ObfuscateState = useContext(ObfuscateProvider);
//   const { Id } = useSpace();
//   let search = dict[lsDashboardKey];
//   if (search) search = `?${search}`;
//   const handleClick = (e: any) => {
//     if (ObfuscateState === true) e.preventDefault();
//   };

//   return (
//     <NavLink
//       style={{ pointerEvents: ObfuscateState && "none" }}
//       onClick={handleClick}
//       // to={`/s/${Id}${search}`}
//       to={`/s/${Id}/dashboard`}
//       className={classnames(className)}
//     >
//       <span style={{ opacity: ObfuscateState && 0.3 }}>
//         <AirdeskIconSvg />
//       </span>
//     </NavLink>
//   );
// };

const ContactSingleGroup: IAppBarSingleGroup = {
  id: "contacts",
  titleId: "CONTACTS",
  group: contactsGroup
};
const ComercialSingleGroup: IAppBarSingleGroup = {
  id: "comercial",
  titleId: "COMERCIAL",
  group: commercialGroup
};
const ServicesSingleGroup: IAppBarSingleGroup = {
  id: "services",
  titleId: "SERVICES",
  group: servicesGroup
};
const MarketingSingleGroup: IAppBarSingleGroup = {
  id: "marketing",
  titleId: "MARKETING",
  group: MarketingGroup
};

const DocumentsSingleGroup: IAppBarSingleGroup = {
  id: "documents",
  titleId: "DOCUMENTATION",
  group: documentsGroup
};
const ReportsSingleGroup: IAppBarSingleGroup = {
  id: "reports",
  titleId: "REPORTING",
  group: ReportsGroupMaster
};

const isInAlterEgo = Boolean(getAlterEgo());
interface IAppBarSingleGroup {
  id: string;
  titleId: string;
  group: IMainRoute[][];
}

const getCurrentMatchedGroup = (url: string) => {
  const allGroups = [
    ContactSingleGroup,
    ComercialSingleGroup,
    ServicesSingleGroup,
    MarketingSingleGroup
  ];

  let matchResult = null;
  let result;
  for (const g of allGroups) {
    const { id, group } = g;

    for (const routeGroup of group) {
      for (const route of routeGroup) {
        matchResult = matchPath(url, {
          path: `/s/:id/${route.path}`,
          exact: false,
          strict: false
        });
        if (matchResult !== null) break;
      }
      if (matchResult !== null) break;
    }

    if (matchResult !== null) {
      result = id;
      break;
    }
  }
  if (matchResult) return result;
  else return null;
};

const lsItem = localStorage.getItem(MainViewsSearchLSKey);

const dict = lsItem ? JSON.parse(lsItem) : {};

const AppBar: FunctionComponent<AppBarProps> = ({
  disableHeader,
  objuscateButtons
}) => {
  const intl = useIntl();
  const [activeGroup, setActiveGroup] = useState<string | undefined>();

  const [hoverGroupo, setHoverGroup] = useState<
    IAppBarSingleGroup | undefined
  >();

  const resolvedGroup = hoverGroupo || activeGroup;

  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const location = useLocation();
  const { search: routerSearch } = useLocation();
  useEffect(() => {
    const BetterSearch = new URLSearchParams(routerSearch);

    if (BetterSearch.get("favoriteId")) {
      setActiveGroup(undefined);
    } else {
      const matchResult = getCurrentMatchedGroup(location.pathname);

      if (matchResult) setActiveGroup(matchResult);
    }
  }, [location.pathname, routerSearch]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleHover = useCallback(
    (group: IAppBarSingleGroup) => {
      if (isFavorite) setIsFavorite(false);
      setHoverGroup(group);
    },
    [isFavorite]
  );

  const ObfuscateState = useContext(ObfuscateProvider);

  const handleClick = (e: any) => {};

  const [isFixed, setIsFixed] = useState(() => {
    const lsFixed = localStorage.getItem("air-vertical-sidebar-fixed-status");
    if (lsFixed) {
      return Boolean(parseInt(lsFixed));
    } else return true;
  });

  const toggleFavoriteView = () => {
    if (activeGroup) setActiveGroup(undefined);
    setIsFavorite(!isFavorite);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleHoverFavoriteView = () => {
    setHoverGroup(undefined);
    setIsFavorite(true);
  };

  // const toggleFixed = () => {
  //   setIsFixed(!isFixed);
  // };

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen,
    setIsOpen
  } = useHoverEvents(0);

  const previousActiveGroup = useRef<boolean>();
  const previousFavoriteState = useRef<boolean>();
  useEffect(() => {
    // if (isFixed && Boolean(activeGroup) !== previousActiveGroup.current) {
    //   window.dispatchEvent(new Event("resize"));
    //   previousActiveGroup.current = Boolean(activeGroup);
    // } else if (
    //   isFixed &&
    //   Boolean(isFavorite) !== previousFavoriteState.current
    // ) {
    //   window.dispatchEvent(new Event("resize"));
    //   previousFavoriteState.current = Boolean(isFavorite);
    // }else{

    // }
    window.dispatchEvent(new Event("resize"));
  }, [activeGroup, isFavorite, isFixed]);

  const MouseEnterTimeout = useRef<any>();
  const handleMouseEnter = useCallback(() => {
    if (MouseEnterTimeout.current) clearTimeout(MouseEnterTimeout.current);
    if (!isFixed) {
      MouseEnterTimeout.current = setTimeout(() => {
        onAnchorMouseEnter();
      }, 100);
    }
  }, [isFixed, onAnchorMouseEnter]);

  const handleMouseLeave = useCallback(
    (e: any) => {
      const nextElement = e.toElement || e.relatedTarget;
      if (
        typeof nextElement?.id === "string" &&
        (nextElement?.id === "app-bar-side-right" ||
          nextElement?.id === "app-bar-side-left")
      )
        return;
      if (MouseEnterTimeout.current) clearTimeout(MouseEnterTimeout.current);
      if (!isFixed) {
        MouseEnterTimeout.current = setTimeout(() => {
          onAnchorMouseLeave();
        }, 100);
      }
    },
    [isFixed, onAnchorMouseLeave]
  );

  const spaceSelectorRef = useRef<HTMLDivElement>(null);
  const spaceSelectorPopupRef = useRef<HTMLDivElement>(null);

  const [spaceSelectiorIsOpen, spaceSelectiorToggleIsOpen] = usePopupOpenState(
    spaceSelectorPopupRef,
    spaceSelectorRef
  );

  const space = useSpace();
  const account = useCurrentAccount();
  const history = useHistory();
  const handleClickForSpaceList = () => {
    localStorage.removeItem("Airdesk-Previous-Space");
    history.push("/s");
  };

  const AppBarContainerRef = useRef<HTMLDivElement>(null);
  const AppBarStickerButtonRef = useRef<HTMLDivElement>(null);

  const onContainerEnter = (e: any) => {
    AppBarContainerRef.current?.classList.add(classes.appBarHoverActive);
  };
  const onContainerLeave = (e: any) => {
    if (
      e.currentTarget === AppBarStickerButtonRef.current ||
      AppBarStickerButtonRef.current?.contains(e.currentTarget)
    ) {
      return;
    } else
      AppBarContainerRef.current?.classList.remove(classes.appBarHoverActive);
  };
  const onButtonEnter = (e: any) => {
    AppBarContainerRef.current?.classList.add(classes.appBarHoverActive);
  };
  const onButtonLeave = (e: any) => {
    e.preventDefault();
    // if (
    //   e.target === AppBarContainerRef.current ||
    //   AppBarContainerRef.current?.contains(e.target)
    // ) {
    //   return;
    // } else
    //   AppBarContainerRef.current?.classList.remove(classes.appBarHoverActive);
  };

  const [showMorePinnedItems, setShowMorePinnedItems] = useState(() => {
    const LocalStorageSets =
      localStorage.getItem("app-sidebar-more-options-items") || "[]";
    const resolvedState = JSON.parse(LocalStorageSets);
    return resolvedState as string[];
  });

  const handlePinShowMoreItem = (id: string) => {
    setShowMorePinnedItems((oldP) => {
      const newP = [...oldP];
      if (newP.includes(id)) {
        const newNewP = newP.filter((e) => e !== id);
        localStorage.setItem(
          "app-sidebar-more-options-items",
          JSON.stringify(newNewP)
        );
        return newNewP;
      } else {
        newP.push(id);
        localStorage.setItem(
          "app-sidebar-more-options-items",
          JSON.stringify(newP)
        );
        return newP;
      }
    });
  };
  const accountSpace = useCurrentAccountSpace();
  const ShowMorePinnedItemsDict: Record<string, JSX.Element> = useMemo(() => {
    let result = {
      "Air-Activity": (
        <PersonalHistoryButton
          key={"personalHistory"}
          pinClass={classes.pinnedIcon}
          isPinned={showMorePinnedItems.includes("Air-Activity")}
          buttonClass={classnames(
            classes.appBarButtonWithLabel,
            "fs-14 fw-regular"
          )}
          labelText={intl.formatMessage({ id: "HISTORY" })}
          pinItem={(e: Event) => {
            e.stopPropagation();
            handlePinShowMoreItem("Air-Activity");
          }}
          className="cursor-pointer"
        />
      ),
      "Air-Timetracking": (
        <TimeTrackingFormButton
          disableCount={false}
          key={"tiemtracker"}
          pinClass={classes.pinnedIcon}
          pinItem={(e: Event) => {
            e.stopPropagation();
            handlePinShowMoreItem("Air-Timetracking");
          }}
          isPinned={showMorePinnedItems.includes("Air-Timetracking")}
          buttonClass={classnames(
            classes.appBarButtonWithLabel,
            "fs-14 fw-regular"
          )}
          labelText={intl.formatMessage({ id: "TIME_CONTROL" })}
          className="cursor-pointer position-relative"
        />
      )
    } as Record<string, any>;

    if (hasAnyExportPermission(accountSpace)) {
      result["Air-Exports"] = (
        <PersonalExportButton
          disableCount={false}
          key={"personalExport"}
          pinClass={classes.pinnedIcon}
          pinItem={(e: Event) => {
            e.stopPropagation();
            handlePinShowMoreItem("Air-Exports");
          }}
          isPinned={showMorePinnedItems.includes("Air-Exports")}
          buttonClass={classnames(
            classes.appBarButtonWithLabel,
            "fs-14 fw-regular"
          )}
          labelText={intl.formatMessage({ id: "EXPORTS" })}
          className="cursor-pointer position-relative"
        />
      );
    }
    return result;
  }, [accountSpace, intl, showMorePinnedItems]);

  const [pinnedShowMoreItems, hiddenShowMoreItems] = useMemo(() => {
    const pinnedElements = [];
    const remainElements = [];
    for (const key in ShowMorePinnedItemsDict) {
      if (Object.prototype.hasOwnProperty.call(ShowMorePinnedItemsDict, key)) {
        const element = ShowMorePinnedItemsDict[key];
        if (showMorePinnedItems.includes(key)) {
          pinnedElements.push(element);
        } else remainElements.push(element);
      }
    }

    return [pinnedElements, remainElements];
  }, [ShowMorePinnedItemsDict, showMorePinnedItems]);

  // const FavoriteQueryResponse = useContext(
  //   AppBarFavoriteQueryContext
  // ) as IAppBarFavoriteContet;
  // const { data, handleChange, loading, error } = FavoriteQueryResponse;

  let search = dict[lsDashboardKey];
  if (search) search = `?${search}`;

  const handleToggle = useCallback((group: IAppBarSingleGroup | undefined) => {
    setActiveGroup(group?.id);
  }, []);

  const ContactsUnreads = useGroupUnreadCount(ContactSingleGroup.group);
  const ComercialUnreads = useGroupUnreadCount(ComercialSingleGroup.group);
  const ServicesUnreads = useGroupUnreadCount(ServicesSingleGroup.group);

  const accessDict = useSpaceRoutesAccess();

  const hasOneContactItem = useMemo(() => {
    let result = false;
    for (const g of ContactSingleGroup.group) {
      for (const i of g) {
        const { path } = i;
        if (accessDict[path]) result = true;
      }
    }
    return result;
  }, [accessDict]);

  const hasOneServiceItem = useMemo(() => {
    let result = false;
    for (const g of ServicesSingleGroup.group) {
      for (const i of g) {
        const { path } = i;
        if (accessDict[path]) result = true;
      }
    }
    return result;
  }, [accessDict]);

  const hasOneComercialItem = useMemo(() => {
    let result = false;
    for (const g of ComercialSingleGroup.group) {
      for (const i of g) {
        const { path } = i;
        if (accessDict[path]) result = true;
      }
    }
    return result;
  }, [accessDict]);

  const hasOnemarketingItem = useMemo(() => {
    let result = false;
    for (const g of MarketingSingleGroup.group) {
      for (const i of g) {
        const { path } = i;
        if (accessDict[path]) result = true;
      }
    }
    return result;
  }, [accessDict]);

  const hasOneDocumentationItem = useMemo(() => {
    let result = false;
    for (const g of DocumentsSingleGroup.group) {
      for (const i of g) {
        const { path } = i;
        if (accessDict[path]) result = true;
      }
    }
    return result;
  }, [accessDict]);

  const handleDisableExitWhileFavoriteDragging = (val: boolean) => {
    if (val) {
      AppBarContainerRef.current &&
        AppBarContainerRef.current.classList.add(
          classes.appBarStopHideWhileDragging
        );
    } else {
      AppBarContainerRef.current &&
        AppBarContainerRef.current.classList.remove(
          classes.appBarStopHideWhileDragging
        );
    }
  };

  return (
    <ObfuscateProvider.Provider value={objuscateButtons}>
      <MainViewSearchContext.Provider value={dict}>
        <div
          className={classnames(classes.appBarPadderContainer, {
            [classes.appBarFixedToggled]: isFixed
          })}
        >
          <div
            onMouseEnter={onContainerEnter}
            onMouseLeave={onContainerLeave}
            ref={AppBarContainerRef}
            className={classnames(
              classes.appBarContainer,

              {
                [classes.alterEgo]: isInAlterEgo,
                [classes.appBarHoverActive]: isFixed,
                [classes.appBarDisableAccordionContent]: !isFixed
              }
            )}
          >
            <div
              id="app-bar-side-left"
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
              style={{ zIndex: 10005, background: "#11112b" }}
              className="align-items-start d-flex flex-column h-100 w-100"
            >
              <div className={classes.appBarContainerPadder}>
                <div
                  ref={spaceSelectorRef}
                  className={classnames(
                    "d-flex align-items-center",
                    classes.appBarContainerHeader
                  )}
                >
                  <NavLink
                    activeClassName={classes["appBarActiveClass"]}
                    to={`/s/${space.Id}/dashboard${search ? search : ""}`}
                    className={classnames(
                      "d-flex justify-content-center flex-0-0-auto align-items-center",
                      classes.appBarAirdeskLogoContainer
                    )}
                  >
                    <AirdeskLogo />
                  </NavLink>
                  <div
                    onClick={() => {
                      typeof spaceSelectiorToggleIsOpen === "function" &&
                        spaceSelectiorToggleIsOpen(undefined);
                    }}
                    className="d-flex cursor-pointer overflow-hidden flex-column mx-2 flex-1 disable-selection"
                  >
                    <div className="d-flex align-items-center">
                      <div className="text-white text-truncate fs-14 fw-medium">
                        {space.Name}
                      </div>
                      <div
                        className={classnames(
                          "ml-2 text-white flex-0-0-auto d-flex align-items-center",
                          classes["appBarSpaceSelectorChev"]
                        )}
                      >
                        <FontAwesomeIcon size="sm" icon={faChevronDown} />
                      </div>
                    </div>
                    <div
                      className="fs-12"
                      style={{
                        color: "#9a9ae8",
                        wordBreak: "keep-all",
                        whiteSpace: "nowrap"
                      }}
                    >
                      <FormattedMessage id="WORKSPACE_LABEL" />
                    </div>
                  </div>
                  <SettingsLink
                    size="xl"
                    style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
                    className="bg-transparent ActionButtonNewColorDefault"
                    // text={intl.formatMessage({ id: "SETTINGS" })}
                  />
                </div>
              </div>
              <Popup
                domRef={spaceSelectorPopupRef}
                placement="bottom-start"
                modifiers={{
                  preventOverflow: { boundariesElement: "viewport" }
                }}
                anchorEl={spaceSelectorRef.current}
                isOpen={Boolean(spaceSelectiorIsOpen)}
              >
                <div
                  className={classnames(
                    "pt-2",
                    classes.appBarSpaceSelectorContainer
                  )}
                >
                  <div className="d-flex flex-column h-100">
                    <div className="flex-0-0-auto text-black fs-16 fw-medium">
                      <FormattedMessage id="WORKSPACES" />
                    </div>
                    <div
                      className={classes.appBarSpaceSelectorDividerBefore}
                    ></div>
                    <div className="of-x-hidden of-y-auto flex-1">
                      <AccountSpaceSelector
                        user={account}
                        children={undefined}
                      ></AccountSpaceSelector>
                    </div>
                    <div
                      className={classes.appBarSpaceSelectorDividerAfter}
                    ></div>
                    <Button
                      vType="primary-ghost"
                      onClick={handleClickForSpaceList}
                      className="justify-content-center py-2 d-flex align-items-center"
                    >
                      <FormattedMessage id="VIEW_ALL_ITEMS" />
                    </Button>
                  </div>
                </div>
              </Popup>
              {/* <div className={classnames(classes.appBarContainerPadder)}>
                <div
                  className={classnames(classes.appBarDivider, "mb-2")}
                ></div>
              </div> */}
              {/* <div className="app-sidebar-icons">
            <HeaderLink
              onClick={() => { 
                handleToggle(undefined);
              }}
              routeModule={reportDashboardRoute}
              forcedChildren={<FontAwesomeIcon icon={faHouse} size="lg" />}
            ></HeaderLink>
          </div> */}

              <div
                // @ts-ignore
                style={{ overflowY: "overlay" }}
                className={classnames(
                  "d-flex flex-column h-100 of-x-hidden w-100 Smaller-Scroll-Bar",
                  classes.appBarNewContainerPadder,
                  classes.appBarWithOverflowOverlay
                )}
              >
                <OmniButton
                  size="xl"
                  labelText={intl.formatMessage({ id: "ADD" })}
                  buttonClass={classnames(
                    classes.appBarButtonWithLabel,
                    "fs-14 fw-regular"
                  )}
                  className="w-100"
                />

                <GlobalSearch
                  className="w-100"
                  buttonClass={classnames(
                    classes.appBarButtonWithLabel,
                    "fs-14 fw-regular"
                  )}
                  size="xl"
                  labelText={intl.formatMessage({ id: "SEARCH" })}
                />

                <ReceptionInboxButton
                  className="w-100"
                  vertical
                  activeClassName={classes["appBarActiveClass"]}
                  buttonClass={classnames(
                    classes.appBarButtonWithLabel,
                    "fs-14 fw-regular"
                  )}
                  disableCount={false}
                  size="xl"
                  labelText={intl.formatMessage({ id: "INBOX" })}
                />

                <NotificationsBell
                  disableCount={false}
                  vertical
                  className="cursor-pointer w-100"
                  buttonClass={classnames(
                    classes.appBarButtonWithLabel,
                    "fs-14 fw-regular"
                  )}
                  size="xl"
                  labelText={intl.formatMessage({ id: "NOTIFICATIONS" })}
                />

                {pinnedShowMoreItems}

                {hiddenShowMoreItems.length > 0 && (
                  <AppbarAccordion
                    extraContentClassName={
                      classes.appBarAccordionDisableContentContent
                    }
                    localStorageLabel="showMore"
                    icon={faEllipsis}
                    active={false}
                    extraClassName=""
                    label={intl.formatMessage({ id: "SHOW_MORE" })}
                    toggledLabel={intl.formatMessage({ id: "SHOW_LESS" })}
                    isOpenByDefault={false}
                  >
                    {hiddenShowMoreItems}
                  </AppbarAccordion>
                )}

                <div
                  style={{ borderColor: "#232357" }}
                  className={classnames(classes.appBarDivider, "mt-2 mb-2")}
                ></div>

                <AppDraggingFavoriteContext.Provider
                  value={handleDisableExitWhileFavoriteDragging}
                >
                  <FavoritesAcordion
                    dontShowAny
                    // extraContentClass={
                    //   classes.appBarAccordionDisableContentContent
                    // }
                  />
                </AppDraggingFavoriteContext.Provider>
                {/* <AppFavoriteBarGroup
                  isSelected={isFavorite}
                  handleToggle={toggleFavoriteView}
                  // handleHover={toggleHoverFavoriteView}
                ></AppFavoriteBarGroup> */}
                <div
                  style={{ borderColor: "#232357" }}
                  className={classnames(classes.appBarDivider, "mt-2 mb-2")}
                ></div>
                {hasOneContactItem && (
                  <>
                    <AppbarAccordion
                      // extraContentClassName={
                      //   classes.appBarAccordionDisableContentContent
                      // }
                      localStorageLabel="contacts"
                      icon={faBuilding}
                      hasUnreads={ContactsUnreads > 0 ? true : false}
                      active={activeGroup === ContactSingleGroup.id}
                      extraClassName=""
                      label={intl.formatMessage({
                        id: ContactSingleGroup.titleId
                      })}
                      isOpenByDefault={false}
                    >
                      {ContactSingleGroup.group.map((sectionRoutes, i) => {
                        return (
                          <HeaderLinkGroupSection
                            key={i}
                            toggleActiveGroup={() => {
                              handleToggle(ContactSingleGroup);
                            }}
                            showSeperator={false}
                            sectionRoutes={sectionRoutes}
                          />
                        );
                      })}
                    </AppbarAccordion>
                    <div
                      style={{ borderColor: "#232357" }}
                      className={classnames(classes.appBarDivider, "mt-2 mb-2")}
                    ></div>
                  </>
                )}
                {hasOneComercialItem && (
                  <>
                    <AppbarAccordion
                      // extraContentClassName={
                      //   classes.appBarAccordionDisableContentContent
                      // }
                      localStorageLabel="comercial"
                      hasUnreads={ComercialUnreads > 0 ? true : false}
                      icon={faHandshake}
                      active={activeGroup === ComercialSingleGroup.id}
                      extraClassName=""
                      label={intl.formatMessage({
                        id: ComercialSingleGroup.titleId
                      })}
                      isOpenByDefault={false}
                    >
                      {ComercialSingleGroup.group.map((sectionRoutes, i) => {
                        return (
                          <HeaderLinkGroupSection
                            key={i}
                            toggleActiveGroup={() => {
                              handleToggle(ComercialSingleGroup);
                            }}
                            showSeperator={false}
                            sectionRoutes={sectionRoutes}
                          />
                        );
                      })}
                    </AppbarAccordion>
                    <div
                      style={{ borderColor: "#232357" }}
                      className={classnames(classes.appBarDivider, "mt-2 mb-2")}
                    ></div>
                  </>
                )}

                {hasOneServiceItem && (
                  <>
                    <AppbarAccordion
                      // extraContentClassName={
                      //   classes.appBarAccordionDisableContentContent
                      // }
                      localStorageLabel="services"
                      hasUnreads={ServicesUnreads > 0 ? true : false}
                      icon={faPenRuler}
                      active={activeGroup === ServicesSingleGroup.id}
                      extraClassName=""
                      label={intl.formatMessage({
                        id: ServicesSingleGroup.titleId
                      })}
                      isOpenByDefault={false}
                    >
                      {ServicesSingleGroup.group.map((sectionRoutes, i) => {
                        return (
                          <HeaderLinkGroupSection
                            key={i}
                            toggleActiveGroup={() => {
                              handleToggle(ServicesSingleGroup);
                            }}
                            showSeperator={false}
                            sectionRoutes={sectionRoutes}
                          />
                        );
                      })}
                    </AppbarAccordion>
                    <div
                      style={{ borderColor: "#232357" }}
                      className={classnames(classes.appBarDivider, "mt-2 mb-2")}
                    ></div>
                  </>
                )}
                {hasOnemarketingItem && (
                  <>
                    <AppbarAccordion
                      icon={faBullseye}
                      localStorageLabel="marketing"
                      // extraContentClassName={
                      //   classes.appBarAccordionDisableContentContent
                      // }
                      active={activeGroup === MarketingSingleGroup.id}
                      extraClassName=""
                      label={intl.formatMessage({
                        id: MarketingSingleGroup.titleId
                      })}
                      isOpenByDefault={false}
                    >
                      {MarketingSingleGroup.group.map((sectionRoutes, i) => {
                        return (
                          <HeaderLinkGroupSection
                            key={i}
                            toggleActiveGroup={() => {
                              handleToggle(MarketingSingleGroup);
                            }}
                            showSeperator={false}
                            sectionRoutes={sectionRoutes}
                          />
                        );
                      })}
                    </AppbarAccordion>
                    <div
                      style={{ borderColor: "#232357" }}
                      className={classnames(classes.appBarDivider, "mt-2 mb-2")}
                    ></div>
                  </>
                )}

                {hasOneDocumentationItem && (
                  <>
                    <AppbarAccordion
                      // extraContentClassName={
                      //   classes.appBarAccordionDisableContentContent
                      // }
                      localStorageLabel="documentation"
                      hasUnreads={false}
                      icon={faFiles}
                      active={activeGroup === DocumentsSingleGroup.id}
                      extraClassName=""
                      label={intl.formatMessage({
                        id: DocumentsSingleGroup.titleId
                      })}
                      isOpenByDefault={false}
                    >
                      {DocumentsSingleGroup.group.map((sectionRoutes, i) => {
                        return (
                          <HeaderLinkGroupSection
                            key={i}
                            toggleActiveGroup={() => {
                              handleToggle(DocumentsSingleGroup);
                            }}
                            showSeperator={false}
                            sectionRoutes={sectionRoutes}
                          />
                        );
                      })}
                    </AppbarAccordion>
                    <div
                      style={{ borderColor: "#232357" }}
                      className={classnames(classes.appBarDivider, "mt-2 mb-2")}
                    ></div>
                  </>
                )}

                {/* <DocumentsLink />
                <DocumentsLink template /> */}
                <ReportingLink />

                {/* <AppBarGroup
                  text={intl.formatMessage({ id: ContactSingleGroup.titleId })}
                  handleToggle={handleToggle}
                  // handleHover={handleHover}
                  singleGroup={ContactSingleGroup}
                  icon={}
                  isSelected={ContactSingleGroup.id === resolvedGroup?.id}
                /> */}

                {/* <AppBarGroup
                  text={intl.formatMessage({
                    id: ComercialSingleGroup.titleId
                  })}
                  handleToggle={handleToggle}
                  // handleHover={handleHover}
                  singleGroup={ComercialSingleGroup}
                  icon={faHandshake}
                  isSelected={ComercialSingleGroup.id === resolvedGroup?.id}
                ></AppBarGroup> */}

                {/* <AppBarGroup
                  text={intl.formatMessage({ id: ServicesSingleGroup.titleId })}
                  handleToggle={handleToggle}
                  // handleHover={handleHover}
                  singleGroup={ServicesSingleGroup}
                  icon={faUserCog}
                  isSelected={ServicesSingleGroup.id === resolvedGroup?.id}
                ></AppBarGroup> */}

                {/* <AppBarGroup
                  text={intl.formatMessage({
                    id: MarketingSingleGroup.titleId
                  })}
                  handleToggle={handleToggle}
                  // handleHover={handleHover}
                  singleGroup={MarketingSingleGroup}
                  icon={faShareAlt}
                  isSelected={MarketingSingleGroup.id === resolvedGroup?.id}
                ></AppBarGroup> */}

                {/* <div className="app-sidebar-icons">
                  <HeaderLink
                    onClick={() => {
                      handleToggle(undefined);
                    }}
                    routeModule={reportingOverview}
                    buttonClass="m-auto"
                    forcedChildren={
                      <FontAwesomeIcon
                        icon={faAnalytics}
                        className="fs-18"
                        size="lg"
                      />
                    }
                  ></HeaderLink>
                </div> */}

                {/* <AppBarGroup
            text={intl.formatMessage({ id: ReportsSingleGroup.titleId })}
            handleToggle={handleToggle}
            // handleHover={handleHover}
            singleGroup={ReportsSingleGroup}
            icon={faAnalytics}
            isSelected={ReportsSingleGroup.id === resolvedGroup?.id}
          ></AppBarGroup> */}

                {/* <div
              className={classnames(classes.appBarDivider, "mt-2 mb-2")}
            ></div> */}

                {/* <div className="flex-1"></div> */}

                {/* <div className="d-flex app-sidebar-icons flex-column align-items-center justify-content-end"> */}
                {/* <TrialPlanHeaderButton enableVertical="true" /> */}
                {/* <CancelationPlanHeaderButton enableVertical="true" /> */}
                {/* <MaintenanceIcon /> */}

                {/* <OmniButton
              tooltipText={intl.formatMessage({ id: "ACTIONS" })}
              tooltipStyle={{ margin: "10px 10px" }}
              tooltipPlacement={"right"}
              placement={"right-end"}
              style={{ margin: "10px 10px" }}
              className="cursor-pointer my-1"
            />

            <GlobalSearch
              className="cursor-pointer mb-1"
              placement="right"
              style={{ margin: "10px 10px" }}
              text={intl.formatMessage({ id: "SEARCH" })}
            /> */}
                {/* </div> */}
              </div>

              <div className={classnames(classes.appBarNewContainerPadder)}>
                {/* <div
                  className={classnames(classes.appBarDivider, "mt-2 mb-2")}
                ></div> */}
                <div
                  style={{ borderColor: "#232357" }}
                  className={classnames(classes.appBarDivider, "mt-2 mb-2")}
                ></div>
                <AppbarAccordion
                  icon={faLifeRing}
                  localStorageLabel="help"
                  // extraContentClassName={
                  //   classes.appBarAccordionDisableContentContent
                  // }
                  active={false}
                  extraClassName="w-100 mb-1 of-x-hidden"
                  label={intl.formatMessage({ id: "HELP" })}
                  isOpenByDefault={false}
                >
                  <AccountHelpOptions />
                </AppbarAccordion>
                <div
                  className={classnames(classes.appBarDivider, "mt-2 mb-2")}
                ></div>
                <AccountPopup
                  imageSize="mds"
                  disableSpaceName="true"
                  className="h-100 flex-1"
                  // placement="right-end"
                />
              </div>
            </div>
            <div
              ref={AppBarStickerButtonRef}
              onMouseEnter={onButtonEnter}
              onMouseLeave={onButtonLeave}
              className={classes.appbarStickyBlock}
            >
              <div
                onClick={() => {
                  localStorage.setItem(
                    "air-vertical-sidebar-fixed-status",
                    `${!isFixed ? 1 : 0}`
                  );
                  setIsFixed(!isFixed);
                }}
                className={classnames(classes.appbarStickyButton)}
              >
                <FontAwesomeIcon
                  icon={isFixed ? faChevronDoubleLeft : faThumbtack}
                />
              </div>
            </div>
            <AppBarResizer isFixed={isFixed} appBarRef={AppBarContainerRef} />
          </div>
        </div>

        {/* {(isFavorite || resolvedGroup) && (
          <AppSidebar
            isFavorite={isFavorite}
            isFixed={isFixed}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setIsFixed={setIsFixed}
            onPopupMouseEnter={handleMouseEnter}
            onPopupMouseLeave={handleMouseLeave}
            activeGroup={resolvedGroup}
            handleClick={handleClick}
          />
        )} */}
      </MainViewSearchContext.Provider>
    </ObfuscateProvider.Provider>
  );
};

const ReportingLink = () => {
  const ObfuscateState = useContext(ObfuscateProvider);
  return (
    <HeaderNavLink
      activeClassName={classes["appBarActiveClass"]}
      routeModule={reportingOverview}
      className={classnames("ar-header-rounded-icon w-100")}
    >
      <div
        style={{
          opacity: ObfuscateState && 0.3,
          pointerEvents: ObfuscateState && "none"
        }}
        className={"w-100"}
      >
        <div
          className={classnames(
            `mr-0 ar-header-rounded-icon position-relative InboxButton position-relative cursor-pointer d-flex align-items-center`,
            classes.appBarButtonWithLabel,
            "fs-14 fw-regular"
          )}
        >
          <FontAwesomeIcon icon={faAnalytics} size={"lg"} />

          <span
            style={{ fontWeight: 500, textDecoration: "none" }}
            className="ml-12"
          >
            <FormattedMessage id="REPORTING" />
          </span>
        </div>
      </div>
    </HeaderNavLink>
  );
};

const DocumentsLink = (props: any) => {
  const ObfuscateState = useContext(ObfuscateProvider);
  const { template } = props;
  return (
    <HeaderNavLink
      activeClassName={classes["appBarActiveClass"]}
      routeModule={template ? templateRoute : documentsRoute}
      className={classnames("ar-header-rounded-icon w-100")}
    >
      <div
        style={{
          opacity: ObfuscateState && 0.3,
          pointerEvents: ObfuscateState && "none"
        }}
        className={"w-100"}
      >
        <div
          className={classnames(
            `mr-0 ar-header-rounded-icon position-relative InboxButton position-relative cursor-pointer d-flex align-items-center`,
            classes.appBarButtonWithLabel,
            "fs-14 fw-regular"
          )}
        >
          <FontAwesomeIcon
            icon={template ? faFileAlt : faFileLines}
            size={"lg"}
          />

          <span
            style={{ fontWeight: 500, textDecoration: "none" }}
            className="ml-12"
          >
            <FormattedMessage id={template ? "TEMPLATES" : "DOCUMENTS"} />
          </span>
        </div>
      </div>
    </HeaderNavLink>
  );
};

interface IAppSidebarProps {
  activeGroup?: IAppBarSingleGroup;
  handleClick: React.MouseEventHandler<HTMLAnchorElement>;
  isFixed: boolean;
  isFavorite: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFixed?: React.Dispatch<React.SetStateAction<boolean>>;
  onPopupMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onPopupMouseLeave: React.MouseEventHandler<HTMLDivElement>;
}

const useAppBarDragEvent = (
  commitChange: (value: number) => void,
  size: number,
  minSize: number,
  maxSize: number
) => {
  const sizeRef = useRef<number | undefined>();
  const [tempSize, setTempSize] = useState<number | undefined>(size);
  const [baseX, setBaseX] = useState<number | undefined>();
  const baseSizeRef = useRef<number | undefined>();

  const onMouseDown = useCallback(
    (e: any) => {
      baseSizeRef.current = size;
      setBaseX(e.clientX);
    },
    [size]
  );

  useEffect(() => {
    if (baseX === undefined) return;

    const moveListener = (e: any) => {
      const { clientX: newClientX } = e;
      const incrementedSize = newClientX - baseX;
      const base = baseSizeRef.current as number;
      let newSize = base + incrementedSize;
      if (newSize < minSize) newSize = minSize;
      if (newSize > maxSize) newSize = maxSize;
      sizeRef.current = newSize;
      setTempSize(newSize);
      //   console.log("moved");
    };

    document.addEventListener("mousemove", moveListener);

    const upListener = () => {
      if (sizeRef.current) commitChange(sizeRef.current);
      setBaseX(undefined);
    };

    document.addEventListener("mouseup", upListener);

    return () => {
      document.removeEventListener("mousemove", moveListener);
      document.removeEventListener("mouseup", upListener);
    };
  }, [baseX, commitChange, maxSize, minSize]);

  const result = useMemo(() => {
    return { onMouseDown, tempSize };
  }, [onMouseDown, tempSize]);

  return result;
};

interface IAppSidebarContainerProps {
  resolvedIsOpen: boolean;
  onPopupMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onPopupMouseLeave: React.MouseEventHandler<HTMLDivElement>;
  isFixed: boolean;
  children?: React.ReactNode;
}

const AppSidebarContainer: FC<IAppSidebarContainerProps> = ({
  children,
  isFixed,
  resolvedIsOpen,
  onPopupMouseEnter,
  onPopupMouseLeave
}) => {
  const [width, setWidth] = useState(() => {
    const lsWidth = localStorage.getItem("app-sidebar-width");
    return lsWidth ? parseInt(lsWidth) : 240;
  });

  const comitWidth = useCallback((newWidth: any) => {
    setWidth(newWidth);
    localStorage.setItem("app-sidebar-width", newWidth);
  }, []);

  const { onMouseDown, tempSize } = useAppBarDragEvent(
    comitWidth,
    width,
    240,
    480
  );
  const resolvedSize = tempSize as number;

  return (
    <>
      <div
        id="app-bar-side-right"
        style={{ width: isFixed ? resolvedSize : undefined }}
        onMouseEnter={!isFixed ? onPopupMouseEnter : undefined}
        onMouseLeave={!isFixed ? onPopupMouseLeave : undefined}
        className={classnames(
          classes.appBarSecondaryContainer,
          "py-4 of-hidden text-white",
          {
            [classes.IsFixed]: isFixed,
            [classes.IsOpen]: resolvedIsOpen,
            [classes.alterEgo]: isInAlterEgo
          }
        )}
      >
        {children}
        {isFixed && (
          <div
            onMouseDown={onMouseDown}
            className={classes.appBarSecondaryContainerDragger}
          ></div>
        )}
      </div>
    </>
  );
};

const AppSidebar: FunctionComponent<IAppSidebarProps> = ({
  activeGroup,
  handleClick,
  isFixed,
  setIsOpen,
  isFavorite,
  setIsFixed,
  isOpen,
  onPopupMouseEnter,
  onPopupMouseLeave
}) => {
  const [resolvedIsOpen, setResolvedIsOpen] = useState(false);
  const intl = useIntl();
  useLayoutEffect(() => {
    const animationFrame = requestAnimationFrame(() => {
      setResolvedIsOpen(isOpen);
    });
    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [isOpen]);

  // const mountedRef = useRef(false);

  return (
    <AppSidebarContainer
      key={isFixed ? 0 : 1}
      onPopupMouseEnter={onPopupMouseEnter}
      onPopupMouseLeave={onPopupMouseLeave}
      isFixed={isFixed}
      resolvedIsOpen={resolvedIsOpen}
    >
      {isFavorite ? (
        <FavoritesContainer
          stickyFavorites={isFixed}
          setStickyFavorites={setIsFixed}
        />
      ) : (
        <div className={classes.SecondaryContent}>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div
              className={classnames(
                classes.appBarLinksTitle,
                "disable-selection"
              )}
            >
              <FormattedMessage id={activeGroup?.titleId} />
            </div>
            <InformationComponent
              placement="right"
              style={{ margin: "10px 13px" }}
              text={intl.formatMessage({
                id: !isFixed ? "STICKY" : "UNSTICKY"
              })}
            >
              <div
                onClick={(event) => {
                  localStorage.setItem(
                    "air-vertical-sidebar-fixed-status",
                    `${!isFixed ? 1 : 0}`
                  );
                  typeof setIsFixed === "function" && setIsFixed(!isFixed);
                  typeof setResolvedIsOpen === "function" &&
                    setResolvedIsOpen(false);
                  setIsOpen(false);
                }}
                className={classnames(
                  "d-flex ml-3 p-1 cursor-pointer rounded"
                  // classes.appBarFavoriteAddItemButton
                )}
              >
                <FontAwesomeIcon
                  style={{
                    height: "16px",
                    width: "16px"
                  }}
                  icon={!isFixed ? faThumbTack : faChevronLeft}
                />
              </div>
            </InformationComponent>
          </div>
          {activeGroup
            ? activeGroup.group.map((sectionRoutes, i) => {
                return (
                  <HeaderLinkGroupSection
                    key={i}
                    toggleActiveGroup={handleClick}
                    showSeperator={i !== 0}
                    sectionRoutes={sectionRoutes}
                  />
                );
              })
            : null}
        </div>
      )}
    </AppSidebarContainer>
  );
};

interface FavoritesContaienrProps {
  stickyFavorites?: boolean;
  setStickyFavorites?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  dontShowAny?: boolean;
}

const FavoritesAcordion = (props: any) => {
  const { extraContentClass } = props;
  const anchorRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const defaultParent = useRef<TreeItem | null>(null);
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState<FavoriteCreatorTypes | null>();

  const UpdateResponse = useContext(
    AppBarFavoriteTreeSetterResponse
  ) as IAppBarFavoriteTreeSetterResponseProps;
  const { loading: updateLoading, error: updateError } = UpdateResponse;
  const FavoriteQueryResponse = useContext(
    AppBarFavoriteQueryContext
  ) as IAppBarFavoriteContet;

  const { data, handleChange, loading, error } = FavoriteQueryResponse;

  const createFavoriteWithParent = useCallback((item: any) => {
    defaultParent.current = item;
    setModalType(FavoriteCreatorTypes.favorite);
    onModalOpen();
  }, []);

  const createFolderWithParent = useCallback((item: any) => {
    defaultParent.current = item;
    setModalType(FavoriteCreatorTypes.folder);
    onModalOpen();
  }, []);

  const amountOfNodes = useMemo(() => {
    if (data) {
      const { rootId, items } = data;
      const rootItem = items[rootId];
      return rootItem.children.length;
    } else return 0;
  }, [data]);

  const onClose = () => {
    if (defaultParent.current) defaultParent.current = null;
    setModalIsOpen(false);
  };
  const onModalOpen = () => {
    setModalIsOpen(true);
  };
  const intl = useIntl();

  const ExButton = useMemo(() => {
    return (
      <div
        ref={anchorRef}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          typeof toggleIsOpen === "function" && toggleIsOpen();
        }}
        style={{ opacity: 1 }}
        className={classnames(
          "d-flex p-1 cursor-pointer rounded",
          classes["ar-favorite-adder-button-new"]
        )}
      >
        <FontAwesomeIcon
          style={{
            height: "16px",
            width: "16px"
          }}
          icon={faPlusLight}
        />
      </div>
    );
  }, [toggleIsOpen]);

  return (
    <Suspense
      fallback={
        <AppbarAccordion
          icon={faStar}
          active={false}
          // localStorageLabel="favorites"
          extraClassName=""
          extraAnchorClasses={classes.DisableExtraButtonOnNotHover}
          extraContentClassName={classnames(
            extraContentClass,
            classes.ExtraPaddingLeft,
            {
              isOpen: "d-block"
            }
          )}
          extraButton={ExButton}
          label={intl.formatMessage({ id: "FAVORITES" })}
          isOpenByDefault={false}
        >
          <div></div>
        </AppbarAccordion>
      }
    >
      <AppTreeChildFavoriteCreatorContext.Provider
        value={createFavoriteWithParent}
      >
        <AppTreeChildFolderCreatorContext.Provider
          value={createFolderWithParent}
        >
          <FontAwesomeIconsProvider>
            <AppTreeValueContext.Provider value={data}>
              <AppTreeSetValueContext.Provider value={handleChange}>
                <Modal isOpen={modalIsOpen} onClose={onClose}>
                  <FavoritesCreator
                    defaultParent={defaultParent.current as TreeItem}
                    onClose={onClose}
                    type={modalType || undefined}
                  ></FavoritesCreator>
                </Modal>
                <Popup
                  domRef={popupRef}
                  anchorEl={anchorRef.current}
                  placement="bottom-start"
                  modifiers={{
                    preventOverflow: { boundariesElement: "viewport" }
                  }}
                  isOpen={isOpen}
                >
                  <div
                    className="bg-white text-black px-2 d-flex flex-column py-2 fs-14 rounded"
                    style={{ cursor: "context-menu" }}
                  >
                    <Button
                      onClick={() => {
                        setModalType(FavoriteCreatorTypes.favorite);
                        onModalOpen();
                        typeof toggleIsOpen === "function" && toggleIsOpen();
                      }}
                      vType="link"
                      className="d-flex pl-2 justify-content-start align-items-center text-left"
                    >
                      <div className="mr-1 ar-form-button-link-icon">
                        <FontAwesomeIcon size="lg" icon={faStarLight} />
                      </div>
                      <span>
                        <FormattedMessage id="ADD_FAVORITE" />
                      </span>
                    </Button>
                    <Button
                      onClick={() => {
                        setModalType(FavoriteCreatorTypes.folder);
                        onModalOpen();
                        typeof toggleIsOpen === "function" && toggleIsOpen();
                      }}
                      vType="link"
                      className="d-flex pl-2 align-items-center justify-content-start text-left"
                    >
                      <div className="mr-1 ar-form-button-link-icon">
                        <FontAwesomeIcon icon={faFolderPlus} />
                      </div>
                      <span>
                        <FormattedMessage id="ADD_FOLDER" />
                      </span>
                    </Button>
                  </div>
                </Popup>

                <AppbarAccordion
                  icon={faStar}
                  active={false}
                  extraClassName=""
                  // localStorageLabel="favorites"
                  extraAnchorClasses={classes.DisableExtraButtonOnNotHover}
                  extraContentClassName={classnames(
                    extraContentClass,
                    classes.ExtraPaddingLeft,
                    {
                      isOpen: "d-block"
                    }
                  )}
                  singleExceptionForFavorites={amountOfNodes === 0}
                  extraButton={
                    <div
                      ref={anchorRef}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        typeof toggleIsOpen === "function" && toggleIsOpen();
                      }}
                      style={{ opacity: 1 }}
                      className={classnames(
                        "d-flex p-1 cursor-pointer rounded",
                        classes["ar-favorite-adder-button-new"]
                      )}
                    >
                      <FontAwesomeIcon
                        style={{
                          height: "16px",
                          width: "16px"
                        }}
                        icon={faPlusLight}
                      />
                    </div>
                  }
                  label={intl.formatMessage({ id: "FAVORITES" })}
                  isOpenByDefault={false}
                >
                  <FavoritesContainer dontShowAny />
                </AppbarAccordion>
              </AppTreeSetValueContext.Provider>
            </AppTreeValueContext.Provider>
          </FontAwesomeIconsProvider>
        </AppTreeChildFolderCreatorContext.Provider>
      </AppTreeChildFavoriteCreatorContext.Provider>
    </Suspense>
  );
};

export const FavoritesContainer: FunctionComponent<FavoritesContaienrProps> = ({
  dontShowAny
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const defaultParent = useRef<TreeItem | null>(null);
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  const intl = useIntl();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState<FavoriteCreatorTypes | null>();

  const onClose = () => {
    if (defaultParent.current) defaultParent.current = null;
    setModalIsOpen(false);
  };
  const onModalOpen = () => {
    setModalIsOpen(true);
  };

  const FavoriteQueryResponse = useContext(
    AppBarFavoriteQueryContext
  ) as IAppBarFavoriteContet;

  const { data, handleChange, loading, error } = FavoriteQueryResponse;

  const UpdateResponse = useContext(
    AppBarFavoriteTreeSetterResponse
  ) as IAppBarFavoriteTreeSetterResponseProps;
  const { loading: updateLoading, error: updateError } = UpdateResponse;

  const createFavoriteWithParent = useCallback((item: any) => {
    defaultParent.current = item;
    setModalType(FavoriteCreatorTypes.favorite);
    onModalOpen();
  }, []);

  const createFolderWithParent = useCallback((item: any) => {
    defaultParent.current = item;
    setModalType(FavoriteCreatorTypes.folder);
    onModalOpen();
  }, []);

  const amountOfNodes = useMemo(() => {
    if (data) {
      const { rootId, items } = data;
      const rootItem = items[rootId];
      return rootItem.children.length;
    } else return 0;
  }, [data]);

  if (!data || loading || error) {
    return (
      <div className={classes.SecondaryContent}>
        {/* <div className="mb-3 d-flex align-items-center justify-content-between">
          <div className={classnames(classes.appBarLinksTitle)}>
            <FormattedMessage id={"FAVORITES"} />
          </div>
        </div> */}
        <div className="d-flex flex-1 justify-content-center align-items-center">
          <LoaderSpinner size="sm" center />
        </div>
      </div>
    );
  }

  if (dontShowAny && amountOfNodes === 0) return null;

  return (
    <div className={classnames(classes.SecondaryContent)}>
      <AppTreeChildFavoriteCreatorContext.Provider
        value={createFavoriteWithParent}
      >
        <AppTreeChildFolderCreatorContext.Provider
          value={createFolderWithParent}
        >
          <Suspense fallback={<LoaderSpinner center size="sm" />}>
            <FontAwesomeIconsProvider>
              <AppTreeValueContext.Provider value={data}>
                <AppTreeSetValueContext.Provider value={handleChange}>
                  <Modal isOpen={modalIsOpen} onClose={onClose}>
                    <FavoritesCreator
                      defaultParent={defaultParent.current as TreeItem}
                      onClose={onClose}
                      type={modalType || undefined}
                    ></FavoritesCreator>
                  </Modal>
                  <Popup
                    domRef={popupRef}
                    anchorEl={anchorRef.current}
                    placement="bottom-start"
                    modifiers={{
                      preventOverflow: { boundariesElement: "viewport" }
                    }}
                    isOpen={isOpen}
                  >
                    <div
                      className="bg-white text-black px-2 d-flex flex-column py-2 fs-14 rounded"
                      style={{ cursor: "context-menu" }}
                    >
                      <Button
                        onClick={() => {
                          setModalType(FavoriteCreatorTypes.favorite);
                          onModalOpen();
                          typeof toggleIsOpen === "function" && toggleIsOpen();
                        }}
                        vType="link"
                        className="d-flex pl-2 justify-content-start align-items-center text-left"
                      >
                        <div className="mr-1 ar-form-button-link-icon">
                          <FontAwesomeIcon size="lg" icon={faStarLight} />
                        </div>
                        <span>
                          <FormattedMessage id="ADD_FAVORITE" />
                        </span>
                      </Button>
                      <Button
                        onClick={() => {
                          setModalType(FavoriteCreatorTypes.folder);
                          onModalOpen();
                          typeof toggleIsOpen === "function" && toggleIsOpen();
                        }}
                        vType="link"
                        className="d-flex pl-2 align-items-center justify-content-start text-left"
                      >
                        <div className="mr-1 ar-form-button-link-icon">
                          <FontAwesomeIcon icon={faFolderPlus} />
                        </div>
                        <span>
                          <FormattedMessage id="ADD_FOLDER" />
                        </span>
                      </Button>
                    </div>
                  </Popup>
                  {/* <div className="mb-3 d-flex disable-selection align-items-center justify-content-between">
                    <div className={classnames(classes.appBarLinksTitle)}>
                      <FormattedMessage id={"FAVORITES"} />
                    </div>
                    <div className="d-flex align-items-center">
                      {(updateLoading || updateError) && (
                        <LoaderSpinner className="mr-2" size="xxs" />
                      )}
                      <div
                        ref={anchorRef}
                        onClick={() => {
                          typeof toggleIsOpen === "function" && toggleIsOpen();
                        }}
                        className={classnames(
                          "d-flex p-1 cursor-pointer rounded",
                          classes.appBarFavoriteAddItemButton
                        )}
                      >
                        <FontAwesomeIcon
                          style={{
                            height: "16px",
                            width: "16px"
                          }}
                          icon={faPlus}
                        />
                      </div>
                      {setStickyFavorites && (
                        <InformationComponent
                          placement="right"
                          style={{ margin: "10px 13px" }}
                          text={intl.formatMessage({
                            id: !stickyFavorites ? "STICKY" : "UNSTICKY"
                          })}
                        >
                          <div
                            onClick={() => {
                              localStorage.setItem(
                                "air-vertical-sidebar-fixed-status",
                                `${!stickyFavorites ? 1 : 0}`
                              );
                              typeof setStickyFavorites === "function" &&
                                setStickyFavorites(!stickyFavorites);
                              typeof setIsOpen === "function" &&
                                setIsOpen(false);
                            }}
                            className={classnames(
                              "d-flex ml-3 p-1 cursor-pointer rounded",
                              classes.appBarFavoriteAddItemButton
                            )}
                          >
                            <FontAwesomeIcon
                              style={{
                                height: "16px",
                                width: "16px"
                              }}
                              icon={
                                !stickyFavorites ? faThumbTack : faChevronLeft
                              }
                            />
                          </div>
                        </InformationComponent>
                      )}
                    </div>
                  </div> */}
                  {amountOfNodes === 0 && (
                    <div className="d-flex flex-column flex-1 justify-content-center align-items-center">
                      {/* <FontAwesomeIcon
                        icon={faStar}
                        size="3x"
                        className="text-white mb-4"
                      /> */}
                      <div className="text-white fs-16 fw-medium text-center mx-2 disable-selection">
                        <FormattedMessage id="EMPTY_FAVORITE_LIST" />
                      </div>
                    </div>
                  )}
                  {amountOfNodes > 0 && (
                    <AppBarFavorites
                      childClassName={classnames(
                        classes.appBarFavoriteChildItem
                      )}
                      extraClassNames="h-100"
                    />
                  )}
                </AppTreeSetValueContext.Provider>
              </AppTreeValueContext.Provider>
            </FontAwesomeIconsProvider>
          </Suspense>
        </AppTreeChildFolderCreatorContext.Provider>
      </AppTreeChildFavoriteCreatorContext.Provider>
    </div>
  );
};

interface IHeadLinkProps {
  routeModule: IMainRoute;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  forcedChildren?: any;
  buttonClass?: string;
  vertical?: string;
}

const parseParams = (querystring: string) => {
  // parse query string
  const params = new URLSearchParams(querystring) as any;

  const obj: any = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  delete obj["favoriteId"];
  delete obj["query"];

  const qs = Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");

  return qs;
};

export const HeaderLink: FunctionComponent<IHeadLinkProps> = ({
  routeModule,
  onClick,
  forcedChildren,
  ...rest
}) => {
  const { Id: spaceId } = useSpace();
  const { buttonClass } = rest as any;
  // const { path, text, schema, icon, lsKey, unreadEntityName } = routeModule;
  const { path, text, icon, unreadEntityName, hasAccess, schema } = routeModule;

  // const dict = useMainViewSearchDict();

  // const searchDict = useContext(MainViewSearchContext) as any;
  let searchDictString;
  try {
    const lastUsedSpace = localStorage.getItem(LastUsedSpaceLSKey);

    const NewDictString = localStorage.getItem(
      NewMainViewsSearchLSKey + lastUsedSpace
    );
    const NullNewDictString = localStorage.getItem(
      NewMainViewsSearchLSKey + "null"
    );

    const dictString = localStorage.getItem(MainViewsSearchLSKey);

    if (NewDictString) searchDictString = JSON.parse(NewDictString);
    else if (NullNewDictString) {
      localStorage.setItem(
        NewMainViewsSearchLSKey + lastUsedSpace,
        NullNewDictString
      );
      localStorage.removeItem(NewMainViewsSearchLSKey + "null");
      searchDictString = JSON.parse(NullNewDictString);
    } else if (dictString) {
      localStorage.setItem(NewMainViewsSearchLSKey + lastUsedSpace, dictString);
      localStorage.removeItem(MainViewsSearchLSKey);
      searchDictString = JSON.parse(dictString);
    } else searchDictString = undefined;

    // searchDictString = JSON.parse(
    //   localStorage.getItem(MainViewsSearchLSKey) as string
    // );
  } catch (error) {
    searchDictString = undefined;
  }

  const search =
    schema && searchDictString && searchDictString[schema.name]
      ? "?" + searchDictString[schema.name]
      : "";

  const resolvedSearch = search.length > 0 ? "?" + parseParams(search) : "";

  const basePath = `/s/${spaceId}/${path}${resolvedSearch}`;
  // const basePath = `/s/${spaceId}/${path}`;

  // let search;

  // if ((lsKey || schema) && dict) search = dict[lsKey ? lsKey : schema.name];

  let resolvedPath = basePath;
  // if (search) resolvedPath += `?${search}`;

  const currentView = useSpaceMainView();

  const currentAccountSpace = useCurrentAccountSpace() as AccountSpace;

  const accessDict = useSpaceRoutesAccess();

  const asAccess = useMemo(() => {
    return accessDict[path];
  }, [accessDict, path]);

  if (!asAccess) return null;

  if (typeof hasAccess === "function" && !hasAccess(currentAccountSpace))
    return null;

  const isSelected = currentView === path;

  if (forcedChildren) {
    return (
      <div
        className="position-relative mb-2"
        // className={isSelected ? "selected" : undefined}
      >
        <NavLink
          onClick={onClick}
          activeClassName={classes["appBarActiveClass"]}
          className={classnames(
            "ar-header-rounded-icon position-relative d-flex align-items-center flex-0-0-auto",
            classes.appBarButtonWithLabel,
            "fs-14 fw-regular"
          )}
          to={resolvedPath}
        >
          {forcedChildren}
        </NavLink>
      </div>
    );
  }

  return (
    <NavLink
      onClick={onClick}
      activeClassName={classes["appBarActiveClass"]}
      className={classnames(
        "ar-header-rounded-icon position-relative d-flex align-items-center flex-0-0-auto",
        classes.appBarButtonWithLabel,
        classes.appBarAccordionChildLink,
        "fs-14 fw-regular"
      )}
      to={resolvedPath}
    >
      {icon && (
        <FontAwesomeIcon className="flex-0-0-auto" icon={icon} size={"lg"} />
      )}

      <span
        style={{ fontWeight: 500, textDecoration: "none" }}
        className="ml-2 flex-1 text-truncate"
      >
        {text}
      </span>

      {unreadEntityName && (
        <HeaderLinkUnreadCount unreadEntityName={unreadEntityName} />
      )}
    </NavLink>
  );
};

const HeaderLinkUnreadCount = ({ ...props }) => {
  const data = useUnreadEntities();
  const intl = useIntl();
  const { unreadEntityName } = props;
  if (!data) return null;

  const count = data[`Unread${unreadEntityName}`];
  if (!count) return null;

  return (
    <>
      {/* <div
       style={{ left: 29 }}
        className="unread-count newDesign position-absolute"
      > */}
      {/* <span>{resolvedUnreadCount}</span> */}
      {/* </div> */}

      <div style={{ marginRight: "6px" }}>
        <div
          title={intl.formatMessage({ id: "NOT_READ" })}
          className="ssi-unread-count-pill"
        >
          {count}
        </div>
      </div>
    </>

    // <Badge
    //   type="primary"
    //   className={classnames(classes.badgeSelected, "span-badge ml-2")}
    // >

    // </Badge>
  );
};

interface IHeaderLinkGroupSection {
  sectionRoutes: IMainRoute[];
  showSeperator: boolean;
  toggleActiveGroup: React.MouseEventHandler<HTMLAnchorElement>;
}

const HeaderLinkGroupSection: FunctionComponent<IHeaderLinkGroupSection> = ({
  sectionRoutes,
  showSeperator,
  toggleActiveGroup
}) => {
  return (
    <>
      {showSeperator && (
        <div className={classnames("my-3", classes.appBarLinksDivider)} />
      )}
      {sectionRoutes.map((route, i) => {
        return (
          <HeaderLink
            key={route.path + i}
            onClick={toggleActiveGroup}
            routeModule={route}
          />
        );
      })}
    </>
  );
};

const createResolvedSectionRoutes = (
  section: IMainRoute[],
  accessDict: RouteAccessDict,
  viewDict: RouteAccessDict
) => {
  const resolvedSectionRoutes = [];

  for (const route of section) {
    if (accessDict[route.path]) {
      resolvedSectionRoutes.push(route);
      viewDict[route.path] = true;
    }
  }
  return resolvedSectionRoutes;
};

const createResolvedGroup = (
  group: IMainRoute[][],
  accessDict: RouteAccessDict
) => {
  const resolvedGroup = [];
  const viewDict = {};
  for (const section of group) {
    const sectionRoutes = createResolvedSectionRoutes(
      section,
      accessDict,
      viewDict
    );
    if (sectionRoutes.length !== 0) resolvedGroup.push(sectionRoutes);
  }
  return { resolvedGroup, viewDict };
};

interface IBarGroup {
  singleGroup: IAppBarSingleGroup;
  icon: IconProp;
  text?: string;
  handleHover?: (group: IAppBarSingleGroup) => void;
  isSelected: boolean;
  handleToggle: (group: IAppBarSingleGroup) => void;
}

interface IFBarGroup {
  handleToggle: () => void;
  isSelected: boolean;
  handleHover?: () => void;
}

const AppBarGroup: FunctionComponent<IBarGroup> = ({
  singleGroup,
  icon,
  handleHover,
  text,
  isSelected,
  handleToggle
}) => {
  // const [] = useState(null)
  const accessDict = useSpaceRoutesAccess();
  const { group } = singleGroup;
  const unreadCount = useGroupUnreadCount(group);
  // verifies permissions
  const { resolvedGroup, viewDict } = useMemo(() => {
    return createResolvedGroup(group, accessDict);
  }, [accessDict, group]);

  if (resolvedGroup.length === 0) return null;

  const handleClick = () => {
    handleToggle({ ...singleGroup, group: resolvedGroup });
  };

  const handleMouseHover = () => {
    handleHover && handleHover({ ...singleGroup, group: resolvedGroup });
  };
  const resolvedNotificationsCount = unreadCount
    ? unreadCount > 99
      ? "99+"
      : unreadCount
    : null;
  return (
    <div className="position-relative">
      {resolvedNotificationsCount && (
        <div
          style={{
            top: 0,
            right: 4,
            width: 12,
            height: 12
          }}
          className={classnames("unread-count position-absolute")}
        >
          {/* <span>{resolvedNotificationsCount}</span> */}
        </div>
      )}
      <HeaderLinkButton
        isActiveGroup={isSelected}
        handleHover={() => {}}
        handleClick={() => {}}
        viewDict={viewDict}
        group={resolvedGroup}
        icon={icon}
      />
    </div>
  );
};

const AppFavoriteBarGroup: FunctionComponent<IFBarGroup> = ({
  handleToggle,
  isSelected,
  handleHover
}) => {
  const FavoriteId = useContext(AppFavoriteContext);
  const toggle = () => {
    handleToggle();
  };
  const hover = () => {
    handleHover && handleHover();
  };

  return (
    <HeaderFavoriteLinkButton
      isSelected={isSelected}
      isActiveGroup={Boolean(FavoriteId)}
      handleClick={toggle}
      handleHover={hover}
      icon={faStar}
    />
  );
};

interface IHeaderLinkProps {
  group: IMainRoute[][];
  handleHover: () => void;
  viewDict: RouteAccessDict;
  icon: IconProp;
  isActiveGroup: boolean;
  handleClick: () => void;
}
interface IFHeaderLinkProps {
  icon: IconProp;
  isActiveGroup: boolean;
  handleClick: () => void;
  handleHover: () => void;
  isSelected: boolean;
}

const HeaderFavoriteLinkButton: FunctionComponent<IFHeaderLinkProps> = ({
  isActiveGroup,
  isSelected,
  icon,
  handleHover,
  handleClick
}) => {
  // const unreadCount = useGroupUnreadCount(group);

  const ObfuscateState = useContext(ObfuscateProvider);

  const handleTouch = (e: any) => {
    if (ObfuscateState === true) e.preventDefault();
    else {
      handleClick();
    }
  };

  useEffect(() => {
    if (isActiveGroup) {
      handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      onClick={handleTouch}
      onMouseEnter={handleHover}
      className={classnames(
        "text-white d-flex mb-2 mt-2 align-items-center justify-content-center",
        classes.appBarGroupIcon,
        {
          [classes.selected]: isSelected,
          [classes.active]: isActiveGroup
        }
      )}
    >
      <FontAwesomeIcon size="lg" icon={icon} />
    </div>
  );
};

const HeaderLinkButton: FunctionComponent<IHeaderLinkProps> = ({
  group,
  viewDict,
  isActiveGroup,
  handleHover,
  icon,
  handleClick
}) => {
  // const unreadCount = useGroupUnreadCount(group);
  const ActiveFavoriteView = useContext(AppFavoriteContext);
  const ObfuscateState = useContext(ObfuscateProvider);

  const handleTouch = (e: any) => {
    if (ObfuscateState === true) e.preventDefault();
    else {
      handleClick();
    }
  };

  const currentView = useSpaceMainView();

  const isSelected = viewDict[currentView] && !ActiveFavoriteView;

  useEffect(() => {
    if (isSelected) {
      handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      onMouseEnter={handleHover}
      onClick={handleTouch}
      className={classnames(
        "text-white d-flex mb-2 align-items-center justify-content-center",
        classes.appBarGroupIcon,
        {
          [classes.active]: isSelected,
          [classes.selected]: isActiveGroup
        }
      )}
    >
      <FontAwesomeIcon size="lg" icon={icon} />
    </div>
  );
};

const SettingsLink = ({ ...props }: any) => {
  const ObfuscateState = useContext(ObfuscateProvider);
  const { text, size, className } = props;
  const accessDict = useSpaceRoutesAccess();
  const asAccess = useMemo(() => {
    return accessDict[settingsRoute.path];
  }, [accessDict]);

  if (!asAccess) return null;

  return (
    <>
      {/* <div className={classnames(classes.appBarDivider, "mt-2 mb-2")}></div> */}

      <HeaderNavLink
        activeClassName={classes.appBarActiveClass}
        routeModule={settingsRoute}
        className={classnames("ar-header-rounded-icon", className)}
      >
        <span
          style={{
            opacity: ObfuscateState && 0.3,
            pointerEvents: ObfuscateState && "none"
          }}
        >
          <FontAwesomeIcon icon={faCog} size={size ? size : "lg"} />
        </span>
      </HeaderNavLink>
    </>
  );
};

export default AppBar;
