import React, { FC } from "react";
import PipelineForm from "./PipelineForm";
import Button from "../../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { useSidebar } from "../../../Components/Sidebar/SidebarV2";
import { FormattedMessage } from "react-intl";
import { useServerAwareState } from "../../../Components/CGrid/ServerGrid";
import { PipelineTypeEnum } from "./NewPipelineHelper";
import { SidebarRefetchContext } from "../../../Components/Sidebar/SidebarV2Helper";

export const usePipelineFormOpener = (
  pipelineType: PipelineTypeEnum,
  id: number,
  className: any,
  formProps?: any
) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const { refetchRef } = useServerAwareState() || {};
  const openPipelineForm = () => {
    openSidebar(
      <SidebarRefetchContext.Provider value={refetchRef}>
        <PipelineForm
          hideCancelation={pipelineType === PipelineTypeEnum.Contract}
          type={pipelineType}
          closeForm={closeSidebar}
          id={id}
          {...formProps}
          refetchRef={refetchRef}
          deleteRefetchRef={refetchRef}
        />
      </SidebarRefetchContext.Provider>
    );
  };

  if (id)
    return (
      <Button
        vType="primary-ghost"
        className={className}
        size="sm"
        onClick={openPipelineForm}
      >
        <FontAwesomeIcon icon={faPencil} />
      </Button>
    );
  else
    return (
      <Button vType="primary" className={className} onClick={openPipelineForm}>
        <FormattedMessage id="ADD_PIPELINE" />
      </Button>
    );
};

interface PipelineFromButtonProps {
  pipelineType: PipelineTypeEnum;
  id: number;
  className: any;
  formProps?: any;
}

export const PipelineFormButtone: FC<PipelineFromButtonProps> = ({
  pipelineType,
  id,
  className,
  formProps
}) => {
	
  const [openSidebar, closeSidebar] = useSidebar();
  const { refetchRef } = useServerAwareState() || {};
  const openPipelineForm = () => {
    openSidebar(
      <SidebarRefetchContext.Provider value={refetchRef}>
        <PipelineForm
          hideCancelation={pipelineType === PipelineTypeEnum.Contract}
          type={pipelineType}
          closeForm={closeSidebar}
          id={id}
          {...formProps}
          refetchRef={refetchRef}
          deleteRefetchRef={refetchRef}
        />
      </SidebarRefetchContext.Provider>
    );
  };

  if (id)
    return (
      <Button
        vType="primary-ghost"
        className={className}
        size="sm"
        onClick={openPipelineForm}
      >
        <FontAwesomeIcon icon={faPencil} />
      </Button>
    );
  else
    return (
      <Button vType="primary" className={className} onClick={openPipelineForm}>
        <FormattedMessage id="ADD_PIPELINE" />
      </Button>
    );
};

export const PipeEntityGridButtons = ({
  entity,
  className,
  PipelineType,
  pipelineConfig
}: {
  PipelineType: PipelineTypeEnum;
  entity: any;
  className: any;
  pipelineConfig: any;
}) => {
  const EditButton = usePipelineFormOpener(
    PipelineType || entity.Type,
    entity.Id,
    className,
    pipelineConfig
  );

  return <React.Fragment>{EditButton}</React.Fragment>;
};
