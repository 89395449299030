import { useEffect, useState } from "react";

export const CardChooserListnerDict = {};

const calculateColumns = (columns, orderList, resetLayout) => {
  const fieldDict = getfieldTitleDict(columns);
  const result = [];
  for (const c of orderList) {
    if (fieldDict[c]) result.push(fieldDict[c]);
  }
  return result;
};

const getfieldTitleDict = Columns => {
  const result = {};
  for (const c of Columns) {
    result[c.field] = c;
  }
  return result;
};

export const useResolvedCard = (columns, id) => {
  const [resolvedColumn, setResolvedColumn] = useState(() => {
    //ir a localstorage
    let gridColumnLayout = JSON.parse(
      window.localStorage.getItem("ar-grid-card-layout")
    );

    const orderList = gridColumnLayout ? gridColumnLayout[id] : undefined;

    if (orderList && Array.isArray(orderList))
      return calculateColumns(columns, orderList);
    else return columns;
  });
  useEffect(() => {
    if (!CardChooserListnerDict[id]) CardChooserListnerDict[id] = [];

    const listner = orderList => {
      if (!orderList) setResolvedColumn(columns);
      else setResolvedColumn(calculateColumns(columns, orderList));
    };

    CardChooserListnerDict[id].push(listner);

    return () => {
      const index = CardChooserListnerDict[id].indexOf(listner);
      CardChooserListnerDict[id].splice(index, 1);
    };
  }, [columns, id]);
  return resolvedColumn;
};
