import React, { useMemo } from "react";
import {
  EntitySummaryTabstrip,
  EntitySummaryTab,
  EntitySummaryOverview,
  EntitySummaryAccordion,
  DescriptionOverview,
  EntitySummaryFilesAccordion,
  EntitySummaryAddress,
  EntitySummarySignatureAccordion
} from "../../Components/EntitySummaries/EntitySummaries";
import { History } from "../../Components/DetailsView/History/History";
import { spaceInterventionsSchema } from "../../config/schema";
import InterventionForm from "./InterventionForm";
import {
  useIneficientTypes,
  useInterventionType
} from "../../Hooks/EntityHooks";
import {
  faInfoSquare,
  faUsers,
  faCity
} from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import OriginProfile from "../Origin/OriginProfile";
import {
  EntityOverviewItem,
  EntityDescriptionOverviewItem,
  EntityOverviewRoundedItem
} from "../../Components/EntityOverview/EntityOverview";
import {
  faLink,
  faCalendarAlt,
  faFileAlt,
  faUserTie,
  faAlarmExclamation,
  faClipboard,
  faPauseCircle,
  faMapMarker,
  faSuitcase,
  faUserPlus,
  faCar
} from "@fortawesome/pro-light-svg-icons";
import FormattedTimePreview from "../../Components/FormattedTimePreview/FormattedTimePreview";
import { InterventionPlaceTypesProfile } from "../../Components/Interventions/Interventions";
import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
import { InterventionDetailsChildren } from "./InterventionDetails/InterventionDetailsChildren";
import { ConnectedContactPopup } from "../Origin/ContactPopup";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import { RealTimeRoomTypes } from "../RealTime/RealTimeHelper";
import { FormattedMessage } from "react-intl";
import { InterventionFormButton } from "../Form/FormButtons";
import { getInterventionTarget } from "../../Helpers/FormFormmaterHelper";
import {
  getTotalInterventionDuration,
  InterventionEntityActionsButton
} from "./InterventionsHelper";

export const InterventionSummaryHeader = ({ value }) => {
  const { NoTagDescription } = value;

  return NoTagDescription;
};

export const InterventionSummaryContent = ({ value }) => {
  const {
    Contact,
    PlaceType,
    Addresses,
    BeginDate,
    EndDate,
    InefficientDuration,
    TimeDetail,
    Signature,
    UserSignature,
    // TimeDetailDescription,
    TotalPauses,
    TimeDetailDescription,
    DeslocationTime,
    InterventionType: InterventionTypeId,
    Description,
    CanEdit,
    CanViewChat,
    IsFollowing,
    NoTagDescription,
    NoTagInternalDescription,
    InternalDescription,
    Account,
    Files,
    Client,
    IndirectClient
  } = value;

  const inneficientType = useIneficientTypes(TimeDetail);

  const interventionType = useInterventionType(InterventionTypeId);

  const origin = useMemo(() => getInterventionTarget(value), [value]);

  const date = moment(BeginDate).format("DD/MM/YYYY");

  const beginHours = moment(BeginDate).format("HH:mm");
  const endHours = moment(EndDate).format("HH:mm");

  let totalDuration =
    BeginDate && EndDate
      ? getTotalInterventionDuration(
          BeginDate,
          EndDate,
          TotalPauses + InefficientDuration
        )
      : 0;
  return (
    <>
      {/* <EntitySummaryAccordion icon={faAnalytics} textId="STATUS">
        <EntityOverviewItem icon={faCalendarAlt} titleId="CONCLUSION">
          <GridColloredEndDateComponent Data={value} />
        </EntityOverviewItem>
      </EntitySummaryAccordion> */}

      <EntitySummaryAccordion icon={faInfoSquare} textId={"DETAILS"}>
        <EntityOverviewItem icon={faLink} titleId="ASSIGNED_TO">
          <OriginProfile dynamicFont origin={origin} />
        </EntityOverviewItem>
        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="DATE">
          {date}
        </EntityOverviewRoundedItem>
        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="START">
          {beginHours}
        </EntityOverviewRoundedItem>
        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="END">
          {endHours}
        </EntityOverviewRoundedItem>
        {inneficientType && (
          <EntityOverviewRoundedItem
            icon={faClipboard}
            titleId="INEFFICIENT_TIME_TYPE"
          >
            {inneficientType ? inneficientType.Name : "-"}
          </EntityOverviewRoundedItem>
        )}
        {TimeDetailDescription && (
          <EntityOverviewRoundedItem
            icon={faClipboard}
            titleId="INEFFICIENT_TIME_REASONS"
          >
            {TimeDetailDescription}
          </EntityOverviewRoundedItem>
        )}
        {/* <EntityOverviewItem
              icon={faCalendarAlt}
              title="Motivo de Tempo Ineficiente:"
            >
              <FormattedTimePreview value={InefficientDuration} asHourMinute />
            </EntityOverviewItem> */}

        <EntityOverviewRoundedItem
          icon={faPauseCircle}
          titleId="TOTAL_PAUSES_HOURS_ABRV"
        >
          {TotalPauses ? (
            <FormattedTimePreview value={TotalPauses} asHourMinute />
          ) : (
            "-"
          )}
        </EntityOverviewRoundedItem>

        <EntityOverviewRoundedItem
          icon={faCar}
          titleId="TRAVEL_TIME_HOURS_ABRV"
        >
          {DeslocationTime ? (
            <FormattedTimePreview value={DeslocationTime} asHourMinute />
          ) : (
            "-"
          )}
        </EntityOverviewRoundedItem>

        <EntityOverviewRoundedItem icon={faAlarmExclamation} titleId="DURATION">
          {totalDuration ? (
            <FormattedTimePreview value={totalDuration} asHourMinute />
          ) : (
            "-"
          )}
        </EntityOverviewRoundedItem>

        <EntityOverviewRoundedItem icon={faClipboard} titleId="TIME_TYPE">
          {interventionType ? interventionType.Name : "-"}
        </EntityOverviewRoundedItem>

        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="DESCRIPTION">
          <DescriptionOverview
            Description={Description}
            NoTagDescription={NoTagDescription}
          />
        </EntityDescriptionOverviewItem>

        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="INTERNAL_NOTE">
          <DescriptionOverview
            Description={InternalDescription}
            NoTagDescription={NoTagInternalDescription}
          />
        </EntityDescriptionOverviewItem>

        <EntityOverviewRoundedItem icon={faMapMarker} titleId="WHERE">
          <InterventionPlaceTypesProfile value={PlaceType} />
        </EntityOverviewRoundedItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faCity} textId="ASSOCIATED">
        <EntityOverviewItem icon={faSuitcase} titleId="ACCOUNT">
          <ConnectedClientOrigin
            placement="right"
            dynamicFont
            client={Client || IndirectClient}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUserTie} titleId="CONTACT">
          <ConnectedContactPopup
            placement="right"
            dynamicFont
            contact={Contact}
          />
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faUsers} textId="ASSIGNED_TO">
        <EntityOverviewItem icon={faUserPlus} titleId="CREATED_BY">
          <ConnectedAccountPopup
            placement="right"
            dynamicFont
            account={Account}
          />
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAddress addresses={Addresses} />

      <EntitySummaryFilesAccordion
        CanEdit={CanViewChat}
        IsFollowing={IsFollowing}
        Files={Files}
      />
      <EntitySummarySignatureAccordion Signature={Signature} />
      <EntitySummarySignatureAccordion UserSignature={UserSignature} />
    </>
  );
};

export const InnerInterventionSummary = ({ value }) => {
  return (
    <EntitySummaryTabstrip className="mt-2 mb-3">
      <EntitySummaryTab titleId="SUMMARY">
        <InterventionSummaryContent value={value} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="HISTORY">
        <History intervention={value.Id} disableTabs />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ASSOCIATIONS">
        <InterventionDetailsChildren />
      </EntitySummaryTab>
    </EntitySummaryTabstrip>
  );
};

const InterventionSummary = (props) => {
  return (
    <EntitySummaryOverview
      {...props}
      url={"interventions"}
      type={"TimeCharge"}
      translatedTitleId={"TIME"}
      schema={spaceInterventionsSchema}
      FormComponent={InterventionForm}
      Summary={InnerInterventionSummary}
      creationHeaderText={<FormattedMessage id="CREATE_TIME_RECORD" />}
      ActionButton={InterventionEntityActionsButton}
      FormButton={InterventionFormButton}
      noImage
      TextComponent={InterventionSummaryHeader}
      noChildren
      noFollow
      roomType={RealTimeRoomTypes.TimeCharge}
    ></EntitySummaryOverview>
  );
};

export default InterventionSummary;
