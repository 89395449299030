import React, {
  useMemo,
  useRef,
  useContext,
  useState,
  useCallback
} from "react";
import { useSpace } from "../../../../Contexts/SpaceContext";
import { useQuery } from "../../../../Helpers/IOClient";
import { FileIcon, download_file } from "../../../Files/Files";
import Popup, { usePopupOpenState } from "../../../Popup/Popup";
import "./FilesOverview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faFileDownload } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage } from "react-intl";
import Button from "../../../Button/Button";
import LoaderSpinner from "../../../Loader/LoaderSpinner/LoaderSpinner";
import { accountSchema } from "../../../../config/schema";
import { fk } from "../../../../Helpers/SchemaHelper";
import { ConnectedAccountPopup } from "../../../../Containers/Origin/AcountPopup";
import { useEndlessScroll } from "../../../EndlessScrollPage/EndlessScrollPage";
import { FilePreview } from "../Message/MessageFiles";
import RelativeTime from "../../../RelativeTime/RelativeTime";
import { DetailsValueContext } from "../../../DetailsView2/DetailsView2Helper";
import { DetailsEntityContext } from "../../../DetailsView/DetailsView";

const responseSchema = [
  {
    Account: fk(accountSchema)
  }
];

const top = 15;

const useFilesQuery = (schema, entityId) => {
  const space = useSpace();

  const [skip, setSkip] = useState(0);

  const [files, setFiles] = useState();

  const [hasMore, setHasMore] = useState(true);

  const url = useMemo(() => {
    return `${schema.getEndpoint(
      space.Id
    )}/${entityId}/ChatFiles?$top=${top}&$skip=${skip}`;
  }, [entityId, schema, skip, space.Id]);

  const fetchMore = useCallback(() => {
    setSkip((s) => s + top);
  }, []);

  const { error, loading } = useQuery(url, responseSchema, {
    cache: false,
    onSuccess: ({ data }) => {
      if (!data || data.length < top) setHasMore(false);

      setFiles((f) => (f ? [...f, ...data] : [...data]));
    }
  });

  return { error, loading, files, fetchMore, hasMore };
};

const FileOptionsButton = ({ onClick, icon, children }) => {
  const toggleIsOpen = useContext(MessageOptionsContext);
  const handleClick = (e) => {
    toggleIsOpen();
    onClick && onClick(e);
  };

  return (
    <Button className="rounded-0 text-left" onClick={handleClick} vType="link">
      <FontAwesomeIcon icon={icon} className="mr-2" />
      {children}
    </Button>
  );
};

const FileOptionsPopup = ({ onDownload }) => {
  return (
    <div className="bg-white py-2 rounded">
      <FileOptionsButton icon={faFileDownload} onClick={onDownload}>
        <FormattedMessage id="DOWNLOAD" />
      </FileOptionsButton>
    </div>
  );
};

const MessageOptionsContext = React.createContext();

const FileOptions = ({ onDownload }) => {
  const anchorRef = useRef();
  const popupRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  return (
    <>
      <div className="h-100 float-right px-2">
        <div
          ref={anchorRef}
          onClick={() => toggleIsOpen()}
          className="cursor-pointer px-1"
        >
          <FontAwesomeIcon size="lg" icon={faEllipsisV} />
        </div>
      </div>
      <Popup
        domRef={popupRef}
        anchorEl={anchorRef.current}
        isOpen={Boolean(isOpen)}
      >
        <MessageOptionsContext.Provider value={toggleIsOpen}>
          <FileOptionsPopup onDownload={onDownload} />
        </MessageOptionsContext.Provider>
      </Popup>
    </>
  );
};

const FileItem = ({ file }) => {
  const { Name, Extension, Url, Account, Date } = file;

  const fullName = `${Name}${Extension}`;

  const [previewing, setPreviewing] = useState(false);

  const handleDownload = () => {
    if (!Url) return;
    download_file(Url, fullName);
  };

  return (
    <>
      {Url && (
        <FilePreview
          open={previewing}
          file={file}
          closeModal={() => setPreviewing(false)}
        />
      )}
      <tr>
        <td>
          <FileIcon extension={Extension} />
        </td>
        <td>
          <div
            className={Url ? "cursor-pointer" : undefined}
            onClick={Url ? () => setPreviewing(true) : undefined}
          >
            {fullName}
          </div>
        </td>
        <td>{Date && <RelativeTime date={Date} />}</td>
        <td>
          <ConnectedAccountPopup account={Account} />
        </td>
        <td>
          <FileOptions onDownload={handleDownload} file={file} />
        </td>
      </tr>
    </>
  );
};

const FilesTable = ({ files }) => {
  return (
    <table className="px-3 ar-messages-files-table" style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="TYPE" />
          </th>
          <th>
            <FormattedMessage id="NAME" />
          </th>
          <th>
            <FormattedMessage id="DATE" />
          </th>
          <th>
            <FormattedMessage id="SENT_BY" />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody className="text-black fs-14">
        {files.map((file, index) => {
          return <FileItem key={file.Id} file={file} />;
        })}
      </tbody>
    </table>
  );
};

const NoFilesContent = () => {
  return (
    <div className="ar-entity-summary-no-files">
      <FormattedMessage id="NO_FILES" />
    </div>
  );
};

const FilesOverviewContent = ({ state }) => {
  const { files, loading } = state;
  const value = useContext(DetailsValueContext);
  const value2 = useContext(DetailsEntityContext);

  // console.log(files)
  const resolvedFiles = useMemo(() => {
    let res = [];
    // if (value && Array.isArray(value.Files)) {
    //   res = [...value.Files];
    // } else if (value2 && Array.isArray(value2.Files)) {
    //   res = [...value2.Files];
    // }
    if (files && Array.isArray(files)) {
      for (const f of files) {
        res.push(f);
      }
    }
    return res;
  }, [files]);

  if (!resolvedFiles || loading)
    return <LoaderSpinner size="sm" className="text-primary" center />;
  if (resolvedFiles.length === 0)
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <NoFilesContent />
      </div>
    );
  return (
    <>
      {!loading && <FilesTable files={resolvedFiles} />}
      {loading && (
        <LoaderSpinner
          size="xxs"
          className="text-primary my-2"
          centerHorizontal
        />
      )}
    </>
  );
};

const FilesOverview = ({ schema, entityId }) => {
  const pageRef = useRef();
  const state = useFilesQuery(schema, entityId);

  const { fetchMore, error, loading, hasMore } = state;

  useEndlessScroll({
    listen: hasMore && !loading && !error,
    onFetchMore: fetchMore,
    offsettMultiplier: 0.5,
    pageRef
  });

  return (
    <div ref={pageRef} className="h-100 of-y-auto px-3">
      <FilesOverviewContent state={state} />
    </div>
  );
};

export default FilesOverview;
