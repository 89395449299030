import React, { useState } from "react";
import {
  ReportsDateProvider,
  DashboardContent,
  DashboardAutoRefreshProvider,
} from "./Dashboard";
import { useAirdeskTitle } from "../../Hooks/MiscHooks";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { isAdmin } from "../../Helpers/ModulesHelper";
import { useIntl } from "react-intl";
import { DashboardProvider } from "./DashboardQueryHelper";

const ReportingDashboard = ({ layout, ReportData, reportFilters }) => {
  const intl = useIntl();
  useAirdeskTitle(intl.formatMessage({ id: "DASHBOARD" }));
  const accountSpace = useCurrentAccountSpace();
  const admin = isAdmin(accountSpace);

  const [autoRefresh, setAutoRefresh] = useState(false);

  const lsTimeout = parseInt(localStorage.getItem("air-dash-update"));

  const [timeout, setTimeout] = useState(lsTimeout || 900000);

  return (
    <ReportFiltersContext.Provider value={reportFilters}>
      <ReportsDateProvider.Provider value={ReportData}>
        <DashboardProvider autoRefresh={autoRefresh} timeout={timeout}>
          <DashboardAutoRefreshProvider.Provider
            value={{ autoRefresh, setAutoRefresh, setTimeout, timeout }}
          >
            <DashboardContent
              // availableLayouts={[layout]}
              hasAdminAccess={admin}
              key={layout.lsKey}
              accountSpace={accountSpace}
              activeLayout={layout}
              // hasAdminAccess={hasAdminAccess}
              // setActivelayout={setActivelayout}
              // {...props}
            />
          </DashboardAutoRefreshProvider.Provider>
        </DashboardProvider>
      </ReportsDateProvider.Provider>
    </ReportFiltersContext.Provider>
  );
};

export const ReportFiltersContext = React.createContext();

export default ReportingDashboard;
