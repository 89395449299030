import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const TaskOverviewComp = React.lazy(() =>
  import(/* webpackChunkName: "tasks-overview" */ "./TaskOverviewComp")
);

const TaskOverview = () => {
  return (
    <SuspenseErrorBoundary>
      <TaskOverviewComp />
    </SuspenseErrorBoundary>
  );
};

export default TaskOverview;
