import React from "react";
import { connect } from "react-redux";

const IndustryProfile = ({ industry }) => {
  if (!industry) return null;
  return (
    <div className="d-flex align-items-center">
      <div className="flex-1">
        <div>{industry.Name}</div>
      </div>
    </div>
  );
};

export default IndustryProfile;

const mapStateToProps = (state, ownProps) => {
  
  if (!ownProps.industry) return {};
  
  return {
    industry: state.Entities.Industries[ownProps.industry]
  };
};

export const ConnectedIndustryProfile = connect(mapStateToProps)(IndustryProfile);
