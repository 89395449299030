import React from "react";

const WarningExclamationSvg = ({ className }) => {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 43.5"
      enableBackground="new 0 0 50 43.5"
      //   xml:space="preserve"
    >
      <g id="Group_7520" transform="translate(-935 -448)">
        <g id="Untitled-2" transform="translate(935 447.5)">
          <path
            id="Path"
            fill="#FF8066"
            d="M45,0.5H8.7C7.3,0.5,6,1,5.1,2C4.2,3,3.6,4.3,3.6,5.6v22.8c0,2.8,2.2,5,5,5.1h7.9L23.1,44h3.6
			l10.2-10.4H45c2.8,0,5-2.3,5-5.1c0,0,0,0,0,0V5.6C50,4.2,49.5,3,48.5,2C47.6,1,46.3,0.5,45,0.5z"
          />
          <path
            id="Path-2"
            fill="#FF9A86"
            d="M41.3,0.5H5C3.7,0.5,2.4,1,1.5,2C0.5,2.9,0,4.2,0,5.6v22.8c0,2.8,2.2,5,5,5.1h7.9L23.2,44
			l10.2-10.4h7.9c2.8,0,5-2.3,5-5.1c0,0,0,0,0,0V5.6c0-1.4-0.5-2.6-1.5-3.6C43.9,1,42.6,0.5,41.3,0.5z"
          />
          <path
            id="Path_6965"
            fill="#FFFFFF"
            d="M28.3,26.2c0,2.3-1.8,4.1-4.1,4.1s-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1c1.1,0,2.1,0.4,2.9,1.2
			C27.9,24.1,28.3,25.1,28.3,26.2z M20.6,5.4c0-0.3,0.1-0.7,0.4-0.9c0.2-0.2,0.6-0.4,0.9-0.4h4.8c0.3,0,0.7,0.1,0.9,0.4
			C27.8,4.7,28,5.1,28,5.4l-0.7,13.9c0,0.3-0.1,0.6-0.4,0.8c-0.2,0.2-0.6,0.3-0.9,0.3h-3.4c-0.3,0-0.6-0.1-0.9-0.3
			c-0.2-0.2-0.4-0.5-0.4-0.8L20.6,5.4z"
          />
        </g>
      </g>
    </svg>
  );
};

export default WarningExclamationSvg;
