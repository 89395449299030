import React, {
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useEffect
} from "react";
import classnames from "classnames";
import styles from "../AutomationDetails.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRoute } from "@fortawesome/pro-solid-svg-icons";
import { useSidebar } from "../../../../Components/Sidebar/SidebarV2";
import SourceHandles from "../Handles/SourceHandles";
import TargetHandles from "../Handles/TargetHandles";
import { FormattedMessage, useIntl } from "react-intl";
import KeyedDropdown from "../../../../Components/Dropdown/KeyedDropdown";
import {
  IfElseConditionEnum,
  ifElseItemBooleanOperators,
  ifElseItemNumbersOperators,
  ifElseItemStringOperators,
  IfElseTriggerTypeEnum,
  IfElseTypeEnum
} from "../NodeTypes";
import Popup, { usePopupOpenState } from "../../../../Components/Popup/Popup";
import Button from "../../../../Components/Button/Button";
import { faChevronDown, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { ElementsRefContext } from "../AutomationDetails";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../AutomationDetailsHelper";
import NodeBaseSidebar from "./NodeBaseSidebar";
import FormInput from "../../../../Components/Forms/FormInput/FormInput";
import AutomationTestResults from "./AutomationTestResults";
import NodeBaseContainer from "./NodeExtraOptions/NodeExtraOptions";
import NodeIconNameContainer from "./NodeIconNameContainer";

const BuiltItems = ({ items, handleChange }) => {
  const intl = useIntl();
  if (items?.length === 0) return null;

  return (
    <div>
      {items.map((e, i) => {
        switch (e.type) {
          case IfElseTypeEnum.string:
            return (
              <IfElseItem
                key={i}
                item={e}
                handleChange={handleChange}
                index={i}
                operatorOptions={StringOperatorOptions}
                text={intl.formatMessage({ id: "STRING" })}
              />
            );
          case IfElseTypeEnum.boolean:
            return (
              <IfElseItem
                key={i}
                item={e}
                handleChange={handleChange}
                index={i}
                operatorOptions={BooleanOperatorOptions}
                text={intl.formatMessage({ id: "BOOLEAN" })}
              />
            );
          case IfElseTypeEnum.number:
            return (
              <IfElseItem
                key={i}
                item={e}
                handleChange={handleChange}
                index={i}
                operatorOptions={NumberOperatorOptions}
                text={intl.formatMessage({ id: "NUMBER" })}
              />
            );
          // case IfElseTypeEnum.dates:
          //   return (
          //     <DateIfElseItem
          //       item={e}
          //       handleChange={handleChange}
          //       index={i}
          //     />
          //   );
          default:
            return null;
        }
      })}
    </div>
  );
};

const StringOperatorOptions = [
  ifElseItemStringOperators.contains,
  ifElseItemStringOperators.endsWith,
  ifElseItemStringOperators.equal,
  ifElseItemStringOperators.isEmpty,
  ifElseItemStringOperators.notContains,
  ifElseItemStringOperators.startsWith
];
const BooleanOperatorOptions = [
  ifElseItemBooleanOperators.equal,
  ifElseItemBooleanOperators.notEqual
];
const NumberOperatorOptions = [
  ifElseItemNumbersOperators.equal,
  ifElseItemNumbersOperators.greater,
  ifElseItemNumbersOperators.isEmpty,
  ifElseItemNumbersOperators.greaterEqual,
  ifElseItemNumbersOperators.lesser,
  ifElseItemNumbersOperators.lesserEqual,
  ifElseItemNumbersOperators.notEqual
];

const IfElseItem = React.memo(
  ({ item, handleChange, index, operatorOptions, text }) => {
    const { value1, value2, operator } = item;
    const intl = useIntl();

    const onOperatorChange = (value) => {
      handleChange((oldV) => {
        const newV = { ...oldV };
        const newItems = [...newV.items];
        newItems[index] = {
          ...newItems[index],
          operator: value
        };
        newV.items = newItems;
        return newV;
      });
    };

    const onValue1Change = (e) => {
      const { value } = e.target;
      handleChange((oldV) => {
        const newV = { ...oldV };
        const newItems = [...newV.items];
        newItems[index] = {
          ...newItems[index],
          value1: value
        };
        newV.items = newItems;
        return newV;
      });
    };
    const onValue2Change = (e) => {
      const { value } = e.target;
      handleChange((oldV) => {
        const newV = { ...oldV };
        const newItems = [...newV.items];
        newItems[index] = {
          ...newItems[index],
          value2: value
        };
        newV.items = newItems;
        return newV;
      });
    };

    const removeItem = () => {
      handleChange((oldV) => {
        const newV = { ...oldV };
        const newItems = [...newV.items];
        newItems.splice(index, 1);
        newV.items = newItems;
        return newV;
      });
    };

    return (
      <div
        className={classnames(
          styles.ifElseValueItem,
          "p-2 border mb-3 bg-airdesk"
        )}
      >
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <span className="text-black fs-16">{text}:</span>
          <div
            onClick={removeItem}
            className="d-flex cursor-pointer align-items-center"
          >
            <FontAwesomeIcon icon={faTrashAlt} className="text-danger-alt" />
          </div>
        </div>
        <FormInput
          className="mb-2"
          textInputClassname="bg-white"
          text={intl.formatMessage({ id: "VALUE" }) + " 1"}
          onChange={onValue1Change}
          name="value1"
          value={value1}
        />
        <div className="d-flex align-items-center mt-3 mb-2 justify-content-between">
          <span className="text-black">{`${intl.formatMessage({
            id: "OPERATION"
          })}`}</span>
          <div></div>
        </div>
        <KeyedDropdown
          value={operator}
          className={classnames("bg-white")}
          onChange={onOperatorChange}
          options={operatorOptions}
          closeOnSelect
          valueComponent={CombineValueComponent}
        />
        <FormInput
          className="my-2"
          textInputClassname="bg-white"
          text={intl.formatMessage({ id: "VALUE" }) + " 2"}
          onChange={onValue2Change}
          name="value2"
          value={value2}
        />
      </div>
    );
  }
);

const IfElseItemsButton = ({ handleChange, items }) => {
  const popupRef = useRef();
  const anchorRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  const [barWidth, setBarWidth] = useState(0);
  useLayoutEffect(() => {
    setBarWidth(anchorRef.current.offsetWidth);
  }, []);

  const addStringItem = () => {
    handleChange({
      items: [
        ...items,
        {
          type: IfElseTypeEnum.string,
          value1: "",
          value2: "",
          operator: ""
        }
      ]
    });
    toggleIsOpen();
  };
  const addBooleanItem = () => {
    handleChange({
      items: [
        ...items,
        {
          type: IfElseTypeEnum.boolean,
          value1: "",
          value2: "",
          operator: ""
        }
      ]
    });
    toggleIsOpen();
  };
  const addNumberItem = () => {
    handleChange({
      items: [
        ...items,
        {
          type: IfElseTypeEnum.number,
          value1: "",
          value2: "",
          operator: ""
        }
      ]
    });
    toggleIsOpen();
  };

  return (
    <>
      <BuiltItems items={items} handleChange={handleChange} />

      <Button
        type="button"
        ref={anchorRef}
        vType="primary-ghost"
        onClick={toggleIsOpen}
        className="d-flex w-100 align-items-center justify-content-center"
      >
        <FormattedMessage id="ADD_CONDITION" />
        <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
      </Button>
      <Popup
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        style={{ width: barWidth }}
        domRef={popupRef}
        anchorEl={anchorRef.current}
        isOpen={isOpen}
        placement="bottom-start"
      >
        <div className="w-100 bg-white p-2" style={{ borderRadius: 3 }}>
          <div
            onClick={addNumberItem}
            className="mb-2 p-2 text-black fs-14 cursor-pointer ar-dropdown-list-button"
          >
            <FormattedMessage id="ADD_NUMBER" />
          </div>
          <div
            onClick={addStringItem}
            className="mb-2 p-2  text-black  fs-14 cursor-pointer ar-dropdown-list-button"
          >
            <FormattedMessage id="ADD_STRING" />
          </div>
          <div
            onClick={addBooleanItem}
            className="p-2  text-black fs-14 cursor-pointer ar-dropdown-list-button"
          >
            <FormattedMessage id="ADD_BOOLEAN" />
          </div>
        </div>
      </Popup>
    </>
  );
};

const IfElseItemsContainer = ({ handleChange, items }) => {
  return (
    <div>
      <IfElseItemsButton handleChange={handleChange} items={items} />
    </div>
  );
};

const CombineOptions = [IfElseConditionEnum.all, IfElseConditionEnum.or];
const TriggerTypeOptions = [
  IfElseTriggerTypeEnum.any,
  IfElseTriggerTypeEnum.creation,
  IfElseTriggerTypeEnum.statusChange,
  IfElseTriggerTypeEnum.updates,
  IfElseTriggerTypeEnum.renew
];

const CombineValueComponent = ({ item }) => {
  if (!item) return <div></div>;
  return (
    <div className={styles.keyDropdownTextStyle}>
      <FormattedMessage id={`${item}`.toUpperCase()} />
    </div>
  );
};
const TriggerValueComponent = ({ item }) => {
  if (!item) return <div></div>;

  let resovledItem;
  switch (item) {
    case IfElseTriggerTypeEnum.any:
      resovledItem = "ANY_SITUATION";
      break;
    case IfElseTriggerTypeEnum.creation:
      resovledItem = "CREATION";
      break;
    case IfElseTriggerTypeEnum.updates:
      resovledItem = "EDITION";
      break;
    case IfElseTriggerTypeEnum.renew:
      resovledItem = "RENOVATE";
      break;
    case IfElseTriggerTypeEnum.statusChange:
      resovledItem = "STATUS";
      break;

    default:
      resovledItem = "ANY_SITUATION";
  }
  return (
    <div className={styles.keyDropdownTextStyle}>
      <FormattedMessage id={resovledItem} />
    </div>
  );
};

export const IfElseFormContainer = ({
  formState,
  organizations,
  pipelines,
  triggerType,
  id,
  canEdit
}) => {
  const { form, handleChange } = formState;
  const { condition, items, trigger_type } = form;

  useLayoutEffect(() => {
    if (!TriggerTypeOptions.includes(trigger_type)) {
      handleChange({
        trigger_type: IfElseTriggerTypeEnum.any
      });
    }
  }, [handleChange, trigger_type]);

  const handleCombinationChange = (value) => {
    handleChange({
      condition: value
    });
  };
  const handleTriggerChange = (value) => {
    handleChange({
      trigger_type: value
    });
  };

  return (
    <div>
      {triggerType && (
        <div className="mb-3 d-flex align-items-center">
          <span className="text-black fs-16 w-175px fw-medium mr-3">
            <FormattedMessage id="ACTIVATE_TRIGGER_ON_CREATION_OR_EDITION" />:
          </span>
          <KeyedDropdown
            value={trigger_type}
            onChange={handleTriggerChange}
            options={TriggerTypeOptions}
            closeOnSelect
            valueComponent={TriggerValueComponent}
          />
        </div>
      )}
      <div className="mb-3 d-flex align-items-center">
        <span className="text-black fs-16 fw-medium mr-3">
          <FormattedMessage id="COMBINE" />:
        </span>
        <KeyedDropdown
          value={condition}
          onChange={handleCombinationChange}
          options={CombineOptions}
          closeOnSelect
          valueComponent={CombineValueComponent}
        />
      </div>
      <IfElseItemsContainer items={items} handleChange={handleChange} />
    </div>
  );
};

const settings = {
  defaultForm: {
    items: [],
    condition: IfElseConditionEnum.all
  },
  FormContent: (props) => {
    return <IfElseFormContainer {...props}></IfElseFormContainer>;
  }
};

const IFNode = React.memo(({ selected, id, data, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AllElementsRef = useContext(ElementsRefContext);
  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);
  const handleClick = () => {
    openSidebar(
      <NodeBaseSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        key={id}
        AllElements={AllElementsRef.current}
        CurrentNodeId={id}
        NodeData={data}
        settings={settings}
        handleSubmit={handleSubmit}
      />
    );
  };
  const { enabled, config } = data;

  const ifTestResulstObject = useMemo(() => {
    const resolvedData = AutomationTestRequest?.data
      ? JSON.parse(AutomationTestRequest?.data)
      : undefined;
    if (!resolvedData) return;
    const { cycles } = resolvedData?.data?.workflowResult;

    if (!cycles[id]) return;

    const { results } = cycles[id];
    const length = results.length;
    const { result } = results[length - 1];

    return result?.result;
  }, [AutomationTestRequest, id]);

  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <NodeIconNameContainer
          config={config}
          type={type}
          enabled={enabled}
          onDoubleClick={handleClick}
          selected={selected}
          icon={faRoute}
          text={"If"}
        />
        <SourceHandles
          id="true"
          labelstyle={{
            left: 6,
            background: "#555"
          }}
          className={ifTestResulstObject === true && "bg-success"}
          style={{ left: 20, width: 16, height: 16, background: "#555" }}
        />
        <SourceHandles
          labelstyle={{
            right: 6,
            background: "#555"
          }}
          className={ifTestResulstObject === false && "bg-success"}
          id="false"
          style={{
            right: 8,
            left: "auto",
            width: 16,
            height: 16,
            background: "#555"
          }}
        />
      </NodeBaseContainer>
    </div>
  );
});

export default IFNode;
