import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import Badge from "../../../../Components/Badge/Badge";
import {
  AutomationTestLoadingRequestContext,
  AutomationTestRequestContext
} from "../AutomationDetailsHelper";
import styles from "../AutomationDetails.module.css";
import { TriggerTypeEnum } from "../NodeTypes";
import classnames from "classnames";
export const TriggerList = [
  TriggerTypeEnum.trigger_client_creation,
  TriggerTypeEnum.trigger_contact_creation,
  TriggerTypeEnum.trigger_deal_creation,
  TriggerTypeEnum.trigger_contract_creation,
  TriggerTypeEnum.trigger_subscription_creation,
  TriggerTypeEnum.trigger_ticket_creation,
  TriggerTypeEnum.trigger_task_creation,
  TriggerTypeEnum.trigger_project_creation,
  TriggerTypeEnum.trigger_time_creation,
  TriggerTypeEnum.trigger_call_creation
];

const AutomationTestResults = ({ id, type }) => {
  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationLoadingTestRequest = useContext(
    AutomationTestLoadingRequestContext
  );
  const resolvedData = AutomationTestRequest?.data
    ? JSON.parse(AutomationTestRequest?.data)
    : undefined;

  if (AutomationLoadingTestRequest) return null;

  if (!resolvedData) return null;
  if (resolvedData.data.userError && TriggerList.includes(type)) {
    return (
      <div
        style={{
          left: 0,
          top: "50%",
          transform: "translate(-200%, -50%)",
          pointerEvents: "none"
        }}
        className="position-absolute text-danger-alt"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </div>
    );
  } else if (resolvedData.data.userError) {
    return null;
  }

  const { cycles } = resolvedData.data.workflowResult;

  if (!cycles[id]) return null;

  const {
    // cyclesCount,
    errorsCount
    // sucessesCount,
    // results
  } = cycles[id];

  if (errorsCount === 0) {
    return (
      <div
        style={{
          left: 0,
          top: "50%",
          transform: "translate(-200%, -50%)",
          pointerEvents: "none"
        }}
        className="position-absolute text-green"
      >
        <FontAwesomeIcon size="lg" icon={faCheck} />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          left: 0,
          top: "50%",
          transform: "translate(-200%, -50%)",
          pointerEvents: "none"
        }}
        className={classnames(
          "position-absolute text-danger-alt",
          styles.outlineDanger
        )}
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </div>
      <div
        style={{
          right: 0,
          top: "50%",
          transform: "translate(140%, -50%)",
          pointerEvents: "none"
        }}
        className={classnames(
          "position-absolute text-danger-alt",
          styles.outlineDanger
        )}
      >
        <Badge type={"danger-alt"}>{errorsCount}</Badge>
      </div>
    </>
  );
};

export default AutomationTestResults;
