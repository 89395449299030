import React from "react";
import {
  spaceBlockTypeEnum,
  BillingPlanStatusProfile,
} from "./BillingPlanPayment";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";

const options = [
  spaceBlockTypeEnum.Active,
  spaceBlockTypeEnum.NotRenovated,
  spaceBlockTypeEnum.Canceled,
];

const ValComp = ({ item }) => {
  return <BillingPlanStatusProfile status={item} />;
};

export const BillingPlanStatusDropdown = (props) => {
  return (
    <KeyedDropdown
      options={options}
      valueComponent={ValComp}
      closeOnSelect
      {...props}
    />
  );
};
