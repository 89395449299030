import React, { useMemo } from "react";
import EmptyListContainer from "./EmptyListContainer";
import { useGridRequest, useServerAwareState } from "../CGrid/ServerGrid";
import { FormattedMessage } from "react-intl";

const EmptyListError = ({ children, }) => {
  const { params, } = useServerAwareState();
  //;
	const {error} = useGridRequest() || {}
  const hasAdvancedFilters =
    params.advancedFilters && params.advancedFilters.length > 0;

		const resolvedStatus = useMemo(() => {
		if(error){
			return error?.status;
		}else return null
	}, [error])

  return (
    <EmptyListContainer type="error">
      {children ? (
        children
      ) : (
        <React.Fragment>
          <div className="fw-bold fs-24 text-black ">
            <FormattedMessage id="ERROR_OCCURRED" />
          </div>
          <div className="fs-18 text-black text-center mb-4">
            <div>
             {resolvedStatus === 406 ? <FormattedMessage id="FILTER_ERROR_OCCURRED_SORRY" /> : <FormattedMessage id="ERROR_OCCURRED_SORRY" />}
            </div>
            <div>
              <FormattedMessage id="ERROR_OCCURRED_CONTACT_ADMIN" />
            </div>
            {hasAdvancedFilters && (
              <div>
                <b>
                  <FormattedMessage id="ERROR_OCCURRED_TRY_ADVANCED" />
                </b>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </EmptyListContainer>
  );
};

export default EmptyListError;
