import React, { useMemo } from "react";
import Input from "./Input";
import { MilSecToMinute, MinuteToMilSec } from "../../Helpers/MiscHelper";

const MinuteInput = ({ value, name, onChange, ...rest }) => {
  const handleChange = !onChange
    ? undefined
    : e => {
        const newValue = e.target.value ? MinuteToMilSec(e.target.value) : "";
        if (isNaN(newValue)) return;
        onChange(newValue);
      };

  const resolvedValue = useMemo(() => {
    if (!value) return value;
    return MilSecToMinute(value, true);
  }, [value]);

  return (
    <Input
      name={name}
      value={resolvedValue}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default MinuteInput;
