import { faHandshake } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

import TriggerCreation from "./TriggerCreation";
import { spaceDealsSchema } from "../../../../../config/schema";
import { ConnectedDealOrigin } from "../../../../Origin/DealOrigin";
import { useIntl } from "react-intl";

const PreviewTarget = ({ val }) => <ConnectedDealOrigin deal={val} />;

const TriggerDealCreation = React.memo(({ ...props }) => {
	const intl = useIntl()
  return (
    <TriggerCreation
      TargetPreview={PreviewTarget}
      {...props}
      schema={spaceDealsSchema}
      icon={faHandshake}
			text={intl.formatMessage({ id: "DEAL" })}
    />
  );
});

export default TriggerDealCreation;
