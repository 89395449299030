import React, { useMemo, useRef } from "react";
import { useHoverEvents } from "../../../../Containers/Origin/Origin";
import classnames from "classnames";
import { ConnectedAccountProfileImg } from "../MessagesHelper";
import InfoPopup from "../../../Popup/InfoPopup";
import { useAccount } from "../../../../Hooks/EntityHooks";
import "./MessageReadUsersPreview.css";


const ConnectedAccountName = ({ account }) => {
  const { Name } = useAccount(account);

  return Name;
};

export const MessageReadUsersPreview = ({ accounts, className, limit = 3 }) => {
  const { shownAccounts, remainingAccounts } = useMemo(() => {
    const shownAccounts = accounts.slice(0, limit);
    const remainingAccounts = accounts.slice(limit);

    return { shownAccounts, remainingAccounts };
  }, [accounts, limit]);

  const hoverEvents = useHoverEvents();
  const {
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen,
    onPopupMouseEnter,
    onPopupMouseLeave
  } = hoverEvents;
  const anchorRef = useRef();
  return (
    <>
      <div
        ref={anchorRef}
        className={classnames("d-flex", className)}
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
      >
        {shownAccounts.map(v => {
          return <ConnectedAccountProfileImg account={v} key={v} />;
        })}
        {remainingAccounts.length > 0 && (
          <div
            className={
              "img circular xsm fs-12 d-flex align-items-center justify-content-center"
            }
          >
            +{remainingAccounts.length > 9 ? 9 : remainingAccounts.length}
          </div>
        )}
      </div>
      <InfoPopup
        anchorEl={anchorRef.current}
        className="of-y-auto ar-message-account-popup"
        placement="left"
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        {accounts.map(v => {
          return (
            <div key={v}>
              <ConnectedAccountName account={v} />
            </div>
          );
        })}
      </InfoPopup>
    </>
  );
};
