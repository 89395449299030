import React, {
  useMemo,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect
} from "react";
import "react-grid-layout/css/styles.css";
import { ReactComponent as Facebook } from "../FirstWelcome/Assets/facebook-brands.svg";
import { ReactComponent as Twitter } from "../FirstWelcome/Assets/twitter-brands.svg";
import { ReactComponent as Linkedin } from "../FirstWelcome/Assets/linkedin-brands.svg";
import { ReactComponent as Instagram } from "../FirstWelcome/Assets/instagram-brands.svg";
import {
  isAdmin,
  hasModuleAccess,
  moduleTypes,
  hasDealFinancialPermission,
  hasContractFinancialPermission
} from "../../Helpers/ModulesHelper";
import "./Dashboard.css";
import DashboardTaskOverview from "./DashboardTaskOverview";
import DashboardTicketsOverview from "./DashboardTicketsOverview";
import DashboardProjectOverview from "./DashboardProjectOverview";
import DashboardCallOverview from "./DashboardCallOverview";
import MissingTimeChargesOverview from "./MissingTimeChargesOverview";
// import { injectIntl } from "react-intl";
import DashboardLayout, {
  //   createLayoutProvider,
  LayoutContext,
  LayoutContextProvider
} from "./DashboardLayout";
import Table from "../../Components/Table";
import DashboardInterventionOverview from "./DashboardInterventionOverview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import DashboardDepartmentOverview from "./DashboardDepartmentOverview";
import { useAirdeskTitle } from "../../Hooks/MiscHooks";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import Modal from "../../Components/Modal/Modal";
import {
  DashboardActionButton,
  ReverseDashboardActionButton
} from "../../Components/ExtraActionsButton/ExtraActionButton";
import { CardChooser } from "./CardChooser/CardChooser";
import { useResolvedCard } from "./CardChooser/CardChooserHelper";
// import DropdownPopover from "../../Components/_Dropdown/DropdownPopover";

import {
  OpenTickets,
  ClosedTickets,
  CanceledTickets,
  AverageOpenTimeBeforeCloseTicket,
  TicketStatusTotals,
  AverageEvolutionClosedTickets,
  PerformanceTicket,
  EvolutionTicket,
  ResolutionTimeOpenedTickets,
  GlobalPerformanceTicket,
  DepartmentWorkloadTicket,
  TeamWorkloadTicket,
  DepartmentAverageCloseTime,
  TeamAverageCloseTime,
  PipelineWorkloadTicket
} from "./DashboardServicesTicketsOverview";
import Popup, { usePopupOpenState } from "../../Components/Popup/Popup";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import {
  ServicesLayout,
  ServicesTicketLayout,
  ServicesTaskLayout,
  ServicesDealLayout,
  ServicesProjectLayout,
  ServicesContractLayout,
  CustomizedLayout
} from "./DashboardDefaultLayouts";
// import LoadableButton from "../../Components/Button/LoadableButton";
import {
  OpenTasks,
  ClosedTasks,
  AverageOpenTimeBeforeCloseTasks,
  TasksStatusTotals,
  AverageEvolutionClosedTasks,
  PerformanceTasks,
  EvolutionTasks,
  DepartmentWorkloadTasks,
  TeamWorkloadTasks,
  ResolutionTimeOpenedTasks,
  DepartmentAverageCloseTimeTasks,
  TeamAverageCloseTimeTasks,
  GlobalPerformanceTasks,
  PipelineWorkloadTask
} from "./DashboardServicesTasksOverview";
import DealsHistory from "./Deals/DealsHistory";
import {
  OpenDeals,
  LostDeals,
  WonDeals,
  OpenDealsSum,
  LostDealsSum,
  WonDealsSum,
  AverageGlobalEvolutionClosedDeals,
  PerformanceDeals,
  EvolutionDeals,
  DealsTopClients,
  DealsTeamsActivity,
  DealGlobalStatus,
  GlobalPerformanceDeals,
  DealLostReasonsChart,
  PipelineWorkloadDeal,
  AverageEvolutionClosedDeals
} from "./DashboardServicesDealsOverview";
import DashboardOpenDealsOverview from "./DashboardOpenDealsOverview";
import DashboardSalesOverview from "./DashboardSalesTableOverview";
import DashboardLostSalesOverview from "./DashbordLostSalesTableOverview";
import TicketsHistory from "./Tickets/TicketsHistory";
import TasksHistory from "./Tasks/TasksHistory";
import {
  useDashboardLayoutState,
  useDashboardViewUpdater
} from "./DashboardHelper";
import { Link } from "react-router-dom";
import { useSpace } from "../../Contexts/SpaceContext";
import { ReportFiltersContext } from "./ReportingDashboard";
import {
  PageContent,
  PageContainer
} from "../../Components/PageUtilities/PageUtilities";
import {
  OpenCounterProjects,
  ClosedCounterProjects,
  CanceledCounterProjects,
  PipelineWorkloadProject
} from "./Projects/CounterProjects";
import {
  OpenProjectsEvolution,
  WorkloadProjects,
  ProjectActivityEvolution,
  ProjectStatusTotals
} from "./Projects/DashboardServicesProjects";
import ExcededTimeProject from "./Projects/ExcededTimeProjects";
import AlmostExcededTimeProject from "./Projects/AlmostExcededTimeProjects";
import AlmostOutOfTimeProjects from "./Projects/AlmostOutOfTimeProjects";
import InactiveProjects from "./Projects/InactiveProjects";
import ClosedProjects from "./Projects/ClosedProjects";
import BudgetRiskProjects from "./Projects/BudgetRiskProjects";
import DeadlineRiskProjects from "./Projects/DeadlineRiskProjects";
import {
  ContractStatusTotals,
  OpenContractsEvolution,
  AverageEvolutionContracts
} from "./Contracts/DashboardServicesContracts";
import BudgetRiskContracts from "./Contracts/BudgetRiskContracts";
import DeadlineRiskContracts from "./Contracts/DeadlineRiskContracts";
import InactiveContracts from "./Contracts/InactiveContracts";
import ExpiredContracts from "./Contracts/ExpiredContracts";
import {
  ClosedCounterContract,
  OpenCounterContract,
  PipelineWorkloadContract
} from "./Contracts/CounterContracts";
import AlmostExpiredContract from "./Contracts/AlmostExpiredContracts";
import AlmostExcededTimeContract from "./Contracts/AlmostCompletionContracts";
import ExcededTimeContract from "./Contracts/ExcededTimeContract";
import DashboardAniversaryOverview from "./DashboardAniversaryOverview";
import { ReverseCardChooser } from "./ReverseCardChooser/ReverseCardChooser";
import { useReversedResolvedCard } from "./ReverseCardChooser/ReverseCardChooserHelper";
// import {
//   RealTimePageProvider,
//   RealTimePageEnum
// } from "../RealTime/RealTimePages";
import { FormattedMessage, useIntl } from "react-intl";
import { DashboardProvider } from "./DashboardQueryHelper";
import Button from "../../Components/Button/Button";
import {
  faExpandAlt,
  faCompressAlt,
  faPlus,
  faTicketSimple,
  faSquareParking,
  faHandshake,
  faFileContract,
  faListCheck,
  faUserAlt,
  faCogs
} from "@fortawesome/pro-light-svg-icons";
import { HeaderContext } from "../Space/Space2";

export const PersonalLayoutModules = [
  {
    titleId: "LAST_TIME_REGISTRATION",
    field: "missingTimeCharges",
    key: "missingTimeCharges",
    module: "interventions",
    component: MissingTimeChargesOverview
  },
  {
    titleId: "DEPARTMENT_TIME_RECORDS",
    field: "departmentTimeCharges",
    key: "departmentTimeCharges",
    hasAccess: (accountSpace) => {
      const { IsResponsibleInAnyDepartment } = accountSpace;

      return IsResponsibleInAnyDepartment;
    },
    component: DashboardDepartmentOverview
  },
  {
    titleId: "TIME_RECORDS",
    field: "interventions",
    module: "interventions",
    component: DashboardInterventionOverview
  },
  {
    titleId: "SCHEDULE_TASKS",
    field: "tasks",
    module: "tasks",
    component: DashboardTaskOverview
  },
  {
    titleId: "CASES",
    field: "tickets",
    module: "tickets",
    component: DashboardTicketsOverview
  },
  {
    titleId: "PROJECTS",
    field: "projects",
    module: "projects",
    component: DashboardProjectOverview
  },
  {
    titleId: "CALLS",
    field: "calls",
    module: "calls",
    component: DashboardCallOverview
  },
  {
    titleId: "BIRTHDAY",
    field: "aniversary",
    key: "aniversary",
    module: "tasks",
    component: DashboardAniversaryOverview
  }
];

export const TicketsLayoutModules = [
  {
    titleId: "OPEN_CASES",
    field: "openTickets",
    key: "openTickets",
    module: moduleTypes.tickets,
    component: OpenTickets
  },
  {
    titleId: "CLOSED_CASES",
    field: "closedTickets",
    key: "closedTickets",
    module: moduleTypes.tickets,
    component: ClosedTickets
  },
  {
    titleId: "CANCELLED_CASES",
    field: "canceledTickets",
    key: "canceledTickets",
    module: moduleTypes.tickets,
    component: CanceledTickets
  },
  {
    titleId: "AVERAGE_CLOSE_CASE",
    field: "averageOpenTime",
    key: "averageOpenTime",
    module: moduleTypes.tickets,
    component: AverageOpenTimeBeforeCloseTicket
  },
  {
    titleId: "CASES_STATUS",
    field: "ticketsStatus",
    key: "ticketsStatus",
    module: moduleTypes.tickets,
    component: TicketStatusTotals
  },
  {
    titleId: "EVOLUTION_AVERAGE",
    field: "evolutionClosedTickets",
    key: "evolutionClosedTickets",
    module: moduleTypes.tickets,
    component: AverageEvolutionClosedTickets
  },
  {
    titleId: "TICKET_PERFORMANCE",
    field: "performanceTickets",
    key: "performanceTickets",
    module: moduleTypes.tickets,
    component: PerformanceTicket
  },
  {
    titleId: "EVOLUTION_ACCOUNTS",
    field: "evolutionTickets",
    key: "evolutionTickets",
    module: moduleTypes.tickets,
    component: EvolutionTicket
  },
  {
    titleId: "DEPARTMENT_LOAD",
    field: "departmentWorkloadTickets",
    key: "departmentWorkloadTickets",

    hasAccess: (accountSpace) => {
      const { IsResponsibleInAnyDepartment } = accountSpace;

      return IsResponsibleInAnyDepartment;
    },
    component: DepartmentWorkloadTicket
  },
  {
    titleId: "TEAM_LOAD",
    field: "teamWorkloadTickets",
    key: "teamWorkloadTickets",
    module: moduleTypes.tickets,
    component: TeamWorkloadTicket
  },
  {
    titleId: "TICKET_PROGRESS",
    field: "resolutionTimeOpenTickets",
    key: "resolutionTimeOpenTickets",
    module: moduleTypes.tickets,
    component: ResolutionTimeOpenedTickets
  },
  {
    titleId: "DEPARTMENT_CASE_AVERAGE",
    field: "averageTimeClosingTickets",
    key: "averageTimeClosingTickets",
    hasAccess: (accountSpace) => {
      const { IsResponsibleInAnyDepartment } = accountSpace;

      return IsResponsibleInAnyDepartment;
    },
    component: DepartmentAverageCloseTime
  },
  {
    titleId: "TEAM_CASE_AVERAGE",
    field: "teamAverageTimeClosingTickets",
    key: "teamAverageTimeClosingTickets",
    module: moduleTypes.tickets,
    component: TeamAverageCloseTime
  },
  {
    titleId: "CASES",
    field: "tickets",
    module: moduleTypes.tickets,
    component: DashboardTicketsOverview
  },
  {
    titleId: "OVERALL_TICKET_PERFORMANCE",
    field: "globalProductivityTickets",
    key: "globalProductivityTickets",
    module: moduleTypes.tickets,
    component: GlobalPerformanceTicket
  },
  {
    titleId: "OVERALL_TICKET_PERFORMANCE",
    field: "PipelineWorkloadTicket",
    key: "PipelineWorkloadTicket",
    module: moduleTypes.tickets,
    component: PipelineWorkloadTicket
  },
  {
    titleId: "TICKET_HISTORY",
    field: "ticketsHistory",
    key: "ticketsHistory",
    hasAccess: (accountSpace) => {
      return isAdmin(accountSpace);
    },
    module: moduleTypes.tickets,
    component: TicketsHistory
  }
];

export const DealLayoutModules = [
  {
    titleId: "HISTORY_DEALS",
    field: "dealHistory",
    key: "dealHistory",
    hasAccess: (accountSpace) => {
      return isAdmin(accountSpace);
    },
    module: moduleTypes.deals,
    component: DealsHistory
  },
  {
    titleId: "OPEN_DEAL",
    field: "openDeals",
    key: "openDeals",
    module: moduleTypes.deals,
    component: OpenDeals
  },
  {
    titleId: "LOST_DEALS",
    field: "lostDeals",
    key: "lostDeals",
    module: moduleTypes.deals,
    component: LostDeals
  },
  {
    titleId: "SALES",
    field: "wonDeals",
    key: "wonDeals",
    module: moduleTypes.deals,
    component: WonDeals
  },
  {
    titleId: "OPEN_DEALS_AMOUNTS",
    field: "openDealsSum",
    key: "openDealsSum",
    module: moduleTypes.deals,
    component: OpenDealsSum
  },
  {
    titleId: "LOST_DEALS_AMOUNTS",
    field: "lostDealsSum",
    key: "lostDealsSum",
    module: moduleTypes.deals,
    component: LostDealsSum
  },
  {
    titleId: "DEALS_SALES_AMOUNTS",
    field: "wonDealsSum",
    key: "wonDealsSum",
    module: moduleTypes.deals,
    component: WonDealsSum
  },
  {
    titleId: "EVOLUTION_DEALS",
    field: "dealsAverageEvolution",
    key: "dealsAverageEvolution",
    module: moduleTypes.deals,
    component: AverageEvolutionClosedDeals
  },
  {
    titleId: "DEALS_PERFORMANCE",
    field: "dealsPerformance",
    key: "dealsPerformance",
    module: moduleTypes.deals,
    component: PerformanceDeals
  },
  {
    titleId: "DEALS_ANALYSIS",
    field: "dealsEvolution",
    key: "dealsEvolution",
    module: moduleTypes.deals,
    component: EvolutionDeals
  },
  {
    titleId: "TEAM_ACTIVITY",
    field: "dealsTeamActivity",
    key: "dealsTeamActivity",
    module: moduleTypes.deals,
    hasAccess: (accountSpace) => {
      return isAdmin(accountSpace);
    },
    component: DealsTeamsActivity
  },
  {
    titleId: "DEALS_STATUS",
    field: "dealsGlobalStates",
    key: "dealsGlobalStates",
    module: moduleTypes.deals,
    component: DealGlobalStatus
  },
  {
    titleId: "TOP_ACCOUNTS",
    field: "dealsTopClients",
    key: "dealsTopClients",
    module: moduleTypes.deals,
    hasAccess: (accountSpace) => {
      return isAdmin(accountSpace);
    },
    component: DealsTopClients
  },
  {
    titleId: "OPEN_DEALS",
    field: "dealsOpenDealsOverview",
    key: "dealsOpenDealsOverview",
    module: moduleTypes.deals,
    component: DashboardOpenDealsOverview
  },
  {
    titleId: "SALES",
    field: "dealsSalesOverview",
    key: "dealsSalesOverview",
    module: moduleTypes.deals,
    component: DashboardSalesOverview
  },
  {
    titleId: "LOST_DEALS",
    field: "dealsLostOverview",
    key: "dealsLostOverview",
    module: moduleTypes.deals,
    component: DashboardLostSalesOverview
  },
  {
    titleId: "DEALS_PERFORMANCE",
    field: "dealsGlobalPerformance",
    key: "dealsGlobalPerformance",
    module: moduleTypes.deals,
    component: GlobalPerformanceDeals
  },
  {
    titleId: "AVERAGE_STATUS",
    field: "PipelineWorkloadDeal",
    key: "PipelineWorkloadDeal",
    module: moduleTypes.deals,
    component: PipelineWorkloadDeal
  },
  {
    titleId: "REASONS_LOSS",
    field: "dealsLostReasons",
    key: "dealsLostReasons",
    module: moduleTypes.deals,
    component: DealLostReasonsChart
  }
];

export const TasksLayoutModules = [
  {
    titleId: "OPEN_TASKS",
    field: "openTasks",
    key: "openTasks",
    module: moduleTypes.tasks,
    component: OpenTasks
  },
  {
    titleId: "CLOSED_TASKS",
    field: "closedTasks",
    key: "closedTasks",
    module: moduleTypes.tasks,
    component: ClosedTasks
  },
  {
    titleId: "AVERAGE_CLOSE_TASKS",
    field: "averageOpenTimeTasks",
    key: "averageOpenTimeTasks",
    module: moduleTypes.tasks,
    component: AverageOpenTimeBeforeCloseTasks
  },
  {
    titleId: "TASKS_STATUS",
    field: "StatusTasks",
    key: "StatusTasks",
    module: moduleTypes.tasks,
    component: TasksStatusTotals
  },
  {
    titleId: "PROGRESS_CLOSED_TASKS",
    field: "evolutionClosedTasks",
    key: "evolutionClosedTasks",
    module: moduleTypes.tasks,
    component: AverageEvolutionClosedTasks
  },
  {
    titleId: "TASKS_PERFORMANCE",
    field: "performanceTasks",
    key: "performanceTasks",
    module: moduleTypes.tasks,
    component: PerformanceTasks
  },
  {
    titleId: "TASKS_PROGRESS",
    field: "evolutionTasks",
    key: "evolutionTasks",
    module: moduleTypes.tasks,
    component: EvolutionTasks
  },
  {
    titleId: "DEPARTMENT_LOAD",
    field: "departmentWorkloadTasks",
    key: "departmentWorkloadTasks",

    hasAccess: (accountSpace) => {
      const { IsResponsibleInAnyDepartment } = accountSpace;

      return IsResponsibleInAnyDepartment;
    },
    component: DepartmentWorkloadTasks
  },
  {
    titleId: "TEAM_LOAD",
    field: "teamWorkloadTasks",
    key: "teamWorkloadTasks",
    module: moduleTypes.tasks,
    component: TeamWorkloadTasks
  },
  {
    titleId: "OPEN_TASKS_PROGRESS",
    field: "resolutionTimeOpenTasks",
    key: "resolutionTimeOpenTasks",
    module: moduleTypes.tasks,
    component: ResolutionTimeOpenedTasks
  },
  {
    titleId: "DEPARTMENT_TASKS_AVERAGE",
    field: "averageTimeClosingTasks",
    key: "averageTimeClosingTasks",
    hasAccess: (accountSpace) => {
      const { IsResponsibleInAnyDepartment } = accountSpace;

      return IsResponsibleInAnyDepartment;
    },
    component: DepartmentAverageCloseTimeTasks
  },
  {
    titleId: "TEAM_TASK_AVERAGE",
    field: "teamAverageTimeClosingTasks",
    key: "teamAverageTimeClosingTasks",
    module: moduleTypes.tasks,
    component: TeamAverageCloseTimeTasks
  },
  {
    titleId: "SCHEDULE_TASKS",
    field: "tasks",
    module: moduleTypes.tasks,
    component: DashboardTaskOverview
  },
  {
    titleId: "OVERALL_TASK_PERFORMANCE",
    field: "globalProductivityTasks",
    key: "globalProductivityTasks",
    module: moduleTypes.tasks,
    component: GlobalPerformanceTasks
  },
  {
    titleId: "AVERAGE_STATUS",
    field: "PipelineWorkloadTask",
    key: "PipelineWorkloadTask",
    module: moduleTypes.tasks,
    component: PipelineWorkloadTask
  },
  {
    titleId: "TASK_HISTORY",
    field: "tasksHistory",
    key: "tasksHistory",
    hasAccess: (accountSpace) => {
      return isAdmin(accountSpace);
    },
    module: moduleTypes.tasks,
    component: TasksHistory
  }
];

export const ProjectLayoutModules = [
  {
    titleId: "OPEN_PROJECTS",
    field: "openProjects",
    key: "openProjects",
    module: moduleTypes.projects,
    component: OpenCounterProjects
  },
  {
    titleId: "CLOSED_PROJECTS",
    field: "closedProjects",
    key: "closedProjects",
    module: moduleTypes.projects,
    component: ClosedCounterProjects
  },
  {
    titleId: "CANCELLED_PROJECTS",
    field: "canceledProjects",
    key: "canceledProjects",
    module: moduleTypes.projects,
    component: CanceledCounterProjects
  },
  {
    titleId: "PROJECTS_OPEN_EVOLUTION",
    field: "openEvolutionProjects",
    key: "openEvolutionProjects",
    module: moduleTypes.projects,
    component: OpenProjectsEvolution
  },
  {
    titleId: "PROJECTS_EXCEEDED_HOURS",
    field: "ExcededTimeProject",
    key: "ExcededTimeProject",
    module: moduleTypes.projects,
    component: ExcededTimeProject
  },
  {
    titleId: "PROJECTS_ALMOST_EXCEEDED",
    field: "AlmostExcededTimeProject",
    key: "AlmostExcededTimeProject",
    module: moduleTypes.projects,
    component: AlmostExcededTimeProject
  },
  {
    titleId: "ALMOST_EXPIRED_PROJECTS",
    field: "AlmostOutOfTimeProjects",
    key: "AlmostOutOfTimeProjects",
    module: moduleTypes.projects,
    component: AlmostOutOfTimeProjects
  },
  {
    titleId: "INACTIVE_PROJECTS",
    field: "InactiveProjects",
    key: "InactiveProjects",
    module: moduleTypes.projects,
    component: InactiveProjects
  },
  {
    titleId: "OPEN_PROJECT_ATRIBUTED",
    field: "WorkloadProjects",
    key: "WorkloadProjects",
    module: moduleTypes.projects,
    component: WorkloadProjects
  },
  {
    titleId: "PROJECTS_ACTIVITY",
    field: "ProjectsActivity",
    key: "ProjectsActivity",
    module: moduleTypes.projects,
    component: ProjectActivityEvolution
  },
  {
    titleId: "PROJECTS_CLOSED",
    field: "ClosedProjects",
    key: "ClosedProjects",
    module: moduleTypes.projects,
    component: ClosedProjects
  },
  {
    titleId: "PROJECTS_BUDGET_RISK",
    field: "BudgetRisk",
    key: "BudgetRisk",
    module: moduleTypes.projects,
    component: BudgetRiskProjects
  },
  {
    titleId: "PROJECTS_DEADLINE_RISK",
    field: "DeadlineRisk",
    key: "DeadlineRisk",
    module: moduleTypes.projects,
    component: DeadlineRiskProjects
  },
  {
    titleId: "AVERAGE_STATUS",
    field: "PipelineWorkloadProject",
    key: "PipelineWorkloadProject",
    module: moduleTypes.projects,
    component: PipelineWorkloadProject
  },
  {
    titleId: "PROJECTS_STATUS",
    field: "ProjectStates",
    key: "ProjectStates",
    module: moduleTypes.projects,
    component: ProjectStatusTotals
  }
];

export const ContractLayoutModules = [
  {
    titleId: "CONTRACTS_EXCEEDED_HOURS",
    field: "excededHours",
    key: "excededHours",
    module: moduleTypes.contracts,
    component: ExcededTimeContract
  },
  {
    titleId: "CONTRACTS_ALMOST_EXCEEDED",
    field: "almostExcededHours",
    key: "almostExcededHours",
    module: moduleTypes.contracts,
    component: AlmostExcededTimeContract
  },
  {
    titleId: "ALMOST_EXPIRED_CONTRACTS",
    field: "almostExpiredHours",
    key: "almostExpiredHours",
    module: moduleTypes.contracts,
    component: AlmostExpiredContract
  },
  {
    titleId: "OPEN_CONTRACTS",
    field: "openContracts",
    key: "openContracts",
    module: moduleTypes.contracts,
    component: OpenCounterContract
  },
  {
    titleId: "CLOSED_CONTRACTS",
    field: "closedContracts",
    key: "closedContracts",
    module: moduleTypes.contracts,
    component: ClosedCounterContract
  },
  {
    titleId: "EXPIRED_CONTRACTS",
    field: "expiredContracts",
    key: "expiredContracts",
    module: moduleTypes.contracts,
    component: ExpiredContracts
  },
  {
    titleId: "INACTIVE_CONTRACTS",
    field: "inactiveContracts",
    key: "inactiveContracts",
    module: moduleTypes.contracts,
    component: InactiveContracts
  },
  {
    titleId: "CONTRACTS_ACTIVITY",
    field: "evolutionContracts",
    key: "evolutionContracts",
    module: moduleTypes.contracts,
    component: AverageEvolutionContracts
  },
  {
    titleId: "OPEN_CONTRACTS_PROGRESS",
    field: "openEvolutionContracts",
    key: "openEvolutionContracts",
    module: moduleTypes.contracts,
    component: OpenContractsEvolution
  },
  {
    titleId: "CONTRACTS_DEADLINE_RISK",
    field: "DeadlineRisk",
    key: "DeadlineRisk",
    module: moduleTypes.contracts,
    component: DeadlineRiskContracts
  },
  {
    titleId: "CONTRACTS_BUDGET_RISK",
    field: "BudgetRisk",
    key: "BudgetRisk",
    module: moduleTypes.contracts,
    component: BudgetRiskContracts
  },
  {
    titleId: "AVERAGE_STATUS",
    field: "PipelineWorkloadContract",
    key: "PipelineWorkloadContract",
    module: moduleTypes.contracts,
    component: PipelineWorkloadContract
  },
  {
    titleId: "CONTRACT_STATUS",
    field: "ContractStates",
    key: "ContractStates",
    module: moduleTypes.contracts,
    component: ContractStatusTotals
  }
];

export const CustomizedLayoutModules = [
  {
    titleId: "LAST_TIME_REGISTRATION",
    field: "missingTimeCharges",
    key: "missingTimeCharges",
    module: "interventions",
    component: MissingTimeChargesOverview
  },
  {
    titleId: "DEPARTMENT_TIME_RECORDS",
    field: "departmentTimeCharges",
    key: "departmentTimeCharges",
    hasAccess: (accountSpace) => {
      const { IsResponsibleInAnyDepartment } = accountSpace;

      return IsResponsibleInAnyDepartment;
    },
    component: DashboardDepartmentOverview
  },
  {
    titleId: "TIME_RECORDS",
    field: "interventions",
    module: "interventions",
    component: DashboardInterventionOverview
  },
  {
    titleId: "SCHEDULE_TASKS",
    field: "tasks",
    module: "tasks",
    component: DashboardTaskOverview
  },
  {
    titleId: "CASES",
    field: "tickets",
    module: "tickets",
    component: DashboardTicketsOverview
  },
  {
    titleId: "PROJECTS",
    field: "projects",
    module: "projects",
    component: DashboardProjectOverview
  },
  {
    titleId: "CALLS",
    field: "calls",
    module: "calls",
    component: DashboardCallOverview
  },
  {
    titleId: "BIRTHDAY",
    field: "aniversary",
    key: "aniversary",
    module: "tasks",
    component: DashboardAniversaryOverview
  },
  {
    titleId: "OPEN_CASES",
    field: "openTickets",
    key: "openTickets",
    module: moduleTypes.tickets,
    component: OpenTickets
  },
  {
    titleId: "CLOSED_CASES",
    field: "closedTickets",
    key: "closedTickets",
    module: moduleTypes.tickets,
    component: ClosedTickets
  },
  {
    titleId: "AVERAGE_CLOSE_CASE",
    field: "averageOpenTime",
    key: "averageOpenTime",
    module: moduleTypes.tickets,
    component: AverageOpenTimeBeforeCloseTicket
  },
  {
    titleId: "CASES_STATUS",
    field: "ticketsStatus",
    key: "ticketsStatus",
    module: moduleTypes.tickets,
    component: TicketStatusTotals
  },
  {
    titleId: "EVOLUTION_AVERAGE",
    field: "evolutionClosedTickets",
    key: "evolutionClosedTickets",
    module: moduleTypes.tickets,
    component: AverageEvolutionClosedTickets
  },
  {
    titleId: "TICKET_PERFORMANCE",
    field: "performanceTickets",
    key: "performanceTickets",
    module: moduleTypes.tickets,
    component: PerformanceTicket
  },
  {
    titleId: "EVOLUTION_ACCOUNTS",
    field: "evolutionTickets",
    key: "evolutionTickets",
    module: moduleTypes.tickets,
    component: EvolutionTicket
  },
  {
    titleId: "DEPARTMENT_LOAD",
    field: "departmentWorkloadTickets",
    key: "departmentWorkloadTickets",

    hasAccess: (accountSpace) => {
      const { IsResponsibleInAnyDepartment } = accountSpace;

      return IsResponsibleInAnyDepartment;
    },
    component: DepartmentWorkloadTicket
  },
  {
    titleId: "TEAM_LOAD",
    field: "teamWorkloadTickets",
    key: "teamWorkloadTickets",
    module: moduleTypes.tickets,
    component: TeamWorkloadTicket
  },
  {
    titleId: "TICKET_PROGRESS",
    field: "resolutionTimeOpenTickets",
    key: "resolutionTimeOpenTickets",
    module: moduleTypes.tickets,
    component: ResolutionTimeOpenedTickets
  },
  {
    titleId: "DEPARTMENT_CASE_AVERAGE",
    field: "averageTimeClosingTickets",
    key: "averageTimeClosingTickets",
    hasAccess: (accountSpace) => {
      const { IsResponsibleInAnyDepartment } = accountSpace;

      return IsResponsibleInAnyDepartment;
    },
    component: DepartmentAverageCloseTime
  },
  {
    titleId: "TEAM_CASE_AVERAGE",
    field: "teamAverageTimeClosingTickets",
    key: "teamAverageTimeClosingTickets",
    module: moduleTypes.tickets,
    component: TeamAverageCloseTime
  },
  {
    titleId: "CANCELLED_CASES",
    field: "canceledTickets",
    key: "canceledTickets",
    module: moduleTypes.tickets,
    component: CanceledTickets
  },
  {
    titleId: "CASES",
    field: "tickets",
    module: moduleTypes.tickets,
    component: DashboardTicketsOverview
  },
  {
    titleId: "OVERALL_TICKET_PERFORMANCE",
    field: "globalProductivityTickets",
    key: "globalProductivityTickets",
    module: moduleTypes.tickets,
    component: GlobalPerformanceTicket
  },
  {
    titleId: "OVERALL_TICKET_PERFORMANCE",
    field: "PipelineWorkloadTicket",
    key: "PipelineWorkloadTicket",
    module: moduleTypes.tickets,
    component: PipelineWorkloadTicket
  },
  {
    titleId: "TICKET_HISTORY",
    field: "ticketsHistory",
    key: "ticketsHistory",
    hasAccess: (accountSpace) => {
      return isAdmin(accountSpace);
    },
    module: moduleTypes.tickets,
    component: TicketsHistory
  },
  {
    titleId: "HISTORY_DEALS",
    field: "dealHistory",
    key: "dealHistory",
    hasAccess: (accountSpace) => {
      return isAdmin(accountSpace);
    },
    module: moduleTypes.deals,
    component: DealsHistory
  },
  {
    titleId: "OPEN_DEAL",
    field: "openDeals",
    key: "openDeals",
    module: moduleTypes.deals,
    component: OpenDeals
  },
  {
    titleId: "LOST_DEALS",
    field: "lostDeals",
    key: "lostDeals",
    module: moduleTypes.deals,
    component: LostDeals
  },
  {
    titleId: "SALES",
    field: "wonDeals",
    key: "wonDeals",
    module: moduleTypes.deals,
    component: WonDeals
  },
  {
    titleId: "OPEN_DEALS_AMOUNTS",
    field: "openDealsSum",
    key: "openDealsSum",
    module: moduleTypes.deals,
    component: OpenDealsSum
  },
  {
    titleId: "LOST_DEALS_AMOUNTS",
    field: "lostDealsSum",
    key: "lostDealsSum",
    module: moduleTypes.deals,
    component: LostDealsSum
  },
  {
    titleId: "DEALS_SALES_AMOUNTS",
    field: "wonDealsSum",
    key: "wonDealsSum",
    module: moduleTypes.deals,
    component: WonDealsSum
  },
  {
    titleId: "EVOLUTION_DEALS",
    field: "dealsAverageEvolution",
    key: "dealsAverageEvolution",
    module: moduleTypes.deals,
    component: AverageGlobalEvolutionClosedDeals
  },
  {
    titleId: "DEALS_PERFORMANCE",
    field: "dealsPerformance",
    key: "dealsPerformance",
    module: moduleTypes.deals,
    component: PerformanceDeals
  },
  {
    titleId: "DEALS_ANALYSIS",
    field: "dealsEvolution",
    key: "dealsEvolution",
    module: moduleTypes.deals,
    component: EvolutionDeals
  },
  {
    titleId: "TEAM_ACTIVITY",
    field: "dealsTeamActivity",
    key: "dealsTeamActivity",
    module: moduleTypes.deals,
    hasAccess: (accountSpace) => {
      return isAdmin(accountSpace);
    },
    component: DealsTeamsActivity
  },
  {
    titleId: "DEALS_STATUS",
    field: "dealsGlobalStates",
    key: "dealsGlobalStates",
    module: moduleTypes.deals,
    component: DealGlobalStatus
  },
  {
    titleId: "TOP_ACCOUNTS",
    field: "dealsTopClients",
    key: "dealsTopClients",
    module: moduleTypes.deals,
    hasAccess: (accountSpace) => {
      return isAdmin(accountSpace);
    },
    component: DealsTopClients
  },
  {
    titleId: "OPEN_DEALS",
    field: "dealsOpenDealsOverview",
    key: "dealsOpenDealsOverview",
    module: moduleTypes.deals,
    component: DashboardOpenDealsOverview
  },
  {
    titleId: "SALES",
    field: "dealsSalesOverview",
    key: "dealsSalesOverview",
    module: moduleTypes.deals,
    component: DashboardSalesOverview
  },
  {
    titleId: "LOST_DEALS",
    field: "dealsLostOverview",
    key: "dealsLostOverview",
    module: moduleTypes.deals,
    component: DashboardLostSalesOverview
  },
  {
    titleId: "DEALS_PERFORMANCE",
    field: "dealsGlobalPerformance",
    key: "dealsGlobalPerformance",
    module: moduleTypes.deals,
    component: GlobalPerformanceDeals
  },
  {
    titleId: "AVERAGE_STATUS",
    field: "PipelineWorkloadDeal",
    key: "PipelineWorkloadDeal",
    module: moduleTypes.deals,
    component: PipelineWorkloadDeal
  },
  {
    titleId: "REASONS_LOSS",
    field: "dealsLostReasons",
    key: "dealsLostReasons",
    module: moduleTypes.deals,
    component: DealLostReasonsChart
  },
  {
    titleId: "OPEN_TASKS",
    field: "openTasks",
    key: "openTasks",
    module: moduleTypes.tasks,
    component: OpenTasks
  },
  {
    titleId: "CLOSED_TASKS",
    field: "closedTasks",
    key: "closedTasks",
    module: moduleTypes.tasks,
    component: ClosedTasks
  },
  {
    titleId: "AVERAGE_CLOSE_TASKS",
    field: "averageOpenTimeTasks",
    key: "averageOpenTimeTasks",
    module: moduleTypes.tasks,
    component: AverageOpenTimeBeforeCloseTasks
  },
  {
    titleId: "TASKS_STATUS",
    field: "StatusTasks",
    key: "StatusTasks",
    module: moduleTypes.tasks,
    component: TasksStatusTotals
  },
  {
    titleId: "PROGRESS_CLOSED_TASKS",
    field: "evolutionClosedTasks",
    key: "evolutionClosedTasks",
    module: moduleTypes.tasks,
    component: AverageEvolutionClosedTasks
  },
  {
    titleId: "TASKS_PERFORMANCE",
    field: "performanceTasks",
    key: "performanceTasks",
    module: moduleTypes.tasks,
    component: PerformanceTasks
  },
  {
    titleId: "TASKS_PROGRESS",
    field: "evolutionTasks",
    key: "evolutionTasks",
    module: moduleTypes.tasks,
    component: EvolutionTasks
  },
  {
    titleId: "DEPARTMENT_LOAD",
    field: "departmentWorkloadTasks",
    key: "departmentWorkloadTasks",

    hasAccess: (accountSpace) => {
      const { IsResponsibleInAnyDepartment } = accountSpace;

      return IsResponsibleInAnyDepartment;
    },
    component: DepartmentWorkloadTasks
  },
  {
    titleId: "TEAM_LOAD",
    field: "teamWorkloadTasks",
    key: "teamWorkloadTasks",
    module: moduleTypes.tasks,
    component: TeamWorkloadTasks
  },
  {
    titleId: "OPEN_TASKS_PROGRESS",
    field: "resolutionTimeOpenTasks",
    key: "resolutionTimeOpenTasks",
    module: moduleTypes.tasks,
    component: ResolutionTimeOpenedTasks
  },
  {
    titleId: "DEPARTMENT_TASKS_AVERAGE",
    field: "averageTimeClosingTasks",
    key: "averageTimeClosingTasks",
    hasAccess: (accountSpace) => {
      const { IsResponsibleInAnyDepartment } = accountSpace;

      return IsResponsibleInAnyDepartment;
    },
    component: DepartmentAverageCloseTimeTasks
  },
  {
    titleId: "TEAM_TASK_AVERAGE",
    field: "teamAverageTimeClosingTasks",
    key: "teamAverageTimeClosingTasks",
    module: moduleTypes.tasks,
    component: TeamAverageCloseTimeTasks
  },
  {
    titleId: "SCHEDULE_TASKS",
    field: "tasks",
    module: moduleTypes.tasks,
    component: DashboardTaskOverview
  },
  {
    titleId: "OVERALL_TASK_PERFORMANCE",
    field: "globalProductivityTasks",
    key: "globalProductivityTasks",
    module: moduleTypes.tasks,
    component: GlobalPerformanceTasks
  },
  {
    titleId: "AVERAGE_STATUS",
    field: "PipelineWorkloadTask",
    key: "PipelineWorkloadTask",
    module: moduleTypes.tasks,
    component: PipelineWorkloadTask
  },
  {
    titleId: "TASK_HISTORY",
    field: "tasksHistory",
    key: "tasksHistory",
    hasAccess: (accountSpace) => {
      return isAdmin(accountSpace);
    },
    module: moduleTypes.tasks,
    component: TasksHistory
  },
  {
    titleId: "OPEN_PROJECTS",
    field: "openProjects",
    key: "openProjects",
    module: moduleTypes.projects,
    component: OpenCounterProjects
  },
  {
    titleId: "CLOSED_PROJECTS",
    field: "closedProjects",
    key: "closedProjects",
    module: moduleTypes.projects,
    component: ClosedCounterProjects
  },
  {
    titleId: "CANCELLED_PROJECTS",
    field: "canceledProjects",
    key: "canceledProjects",
    module: moduleTypes.projects,
    component: CanceledCounterProjects
  },
  {
    titleId: "PROJECTS_OPEN_EVOLUTION",
    field: "openEvolutionProjects",
    key: "openEvolutionProjects",
    module: moduleTypes.projects,
    component: OpenProjectsEvolution
  },
  {
    titleId: "PROJECTS_EXCEEDED_HOURS",
    field: "ExcededTimeProject",
    key: "ExcededTimeProject",
    module: moduleTypes.projects,
    component: ExcededTimeProject
  },
  {
    titleId: "PROJECTS_ALMOST_EXCEEDED",
    field: "AlmostExcededTimeProject",
    key: "AlmostExcededTimeProject",
    module: moduleTypes.projects,
    component: AlmostExcededTimeProject
  },
  {
    titleId: "ALMOST_EXPIRED_PROJECTS",
    field: "AlmostOutOfTimeProjects",
    key: "AlmostOutOfTimeProjects",
    module: moduleTypes.projects,
    component: AlmostOutOfTimeProjects
  },
  {
    titleId: "INACTIVE_PROJECTS",
    field: "InactiveProjects",
    key: "InactiveProjects",
    module: moduleTypes.projects,
    component: InactiveProjects
  },
  {
    titleId: "OPEN_PROJECT_ATRIBUTED",
    field: "WorkloadProjects",
    key: "WorkloadProjects",
    module: moduleTypes.projects,
    component: WorkloadProjects
  },
  {
    titleId: "PROJECTS_ACTIVITY",
    field: "ProjectsActivity",
    key: "ProjectsActivity",
    module: moduleTypes.projects,
    component: ProjectActivityEvolution
  },
  {
    titleId: "PROJECTS_CLOSED",
    field: "ClosedProjects",
    key: "ClosedProjects",
    module: moduleTypes.projects,
    component: ClosedProjects
  },
  {
    titleId: "PROJECTS_BUDGET_RISK",
    field: "BudgetRisk",
    key: "BudgetRisk",
    module: moduleTypes.projects,
    component: BudgetRiskProjects
  },
  {
    titleId: "PROJECTS_DEADLINE_RISK",
    field: "DeadlineRisk",
    key: "DeadlineRisk",
    module: moduleTypes.projects,
    component: DeadlineRiskProjects
  },
  {
    titleId: "AVERAGE_STATUS",
    field: "PipelineWorkloadProject",
    key: "PipelineWorkloadProject",
    module: moduleTypes.projects,
    component: PipelineWorkloadProject
  },
  {
    titleId: "AVERAGE_STATUS",
    field: "ProjectStates",
    key: "ProjectStates",
    module: moduleTypes.projects,
    component: ProjectStatusTotals
  },
  {
    titleId: "CONTRACTS_EXCEEDED_HOURS",
    field: "excededHours",
    key: "excededHours",
    module: moduleTypes.contracts,
    component: ExcededTimeContract
  },
  {
    titleId: "CONTRACTS_ALMOST_EXCEEDED",
    field: "almostExcededHours",
    key: "almostExcededHours",
    module: moduleTypes.contracts,
    component: AlmostExcededTimeContract
  },
  {
    titleId: "ALMOST_EXPIRED_CONTRACTS",
    field: "almostExpiredHours",
    key: "almostExpiredHours",
    module: moduleTypes.contracts,
    component: AlmostExpiredContract
  },
  {
    titleId: "OPEN_CONTRACTS",
    field: "openContracts",
    key: "openContracts",
    module: moduleTypes.contracts,
    component: OpenCounterContract
  },
  {
    titleId: "CLOSED_CONTRACTS",
    field: "closedContracts",
    key: "closedContracts",
    module: moduleTypes.contracts,
    component: ClosedCounterContract
  },
  {
    titleId: "EXPIRED_CONTRACTS",
    field: "expiredContracts",
    key: "expiredContracts",
    module: moduleTypes.contracts,
    component: ExpiredContracts
  },
  {
    titleId: "INACTIVE_CONTRACTS",
    field: "inactiveContracts",
    key: "inactiveContracts",
    module: moduleTypes.contracts,
    component: InactiveContracts
  },
  {
    titleId: "CONTRACTS_ACTIVITY",
    field: "evolutionContracts",
    key: "evolutionContracts",
    module: moduleTypes.contracts,
    component: AverageEvolutionContracts
  },
  {
    titleId: "OPEN_CONTRACTS_PROGRESS",
    field: "openEvolutionContracts",
    key: "openEvolutionContracts",
    module: moduleTypes.contracts,
    component: OpenContractsEvolution
  },
  {
    titleId: "CONTRACTS_DEADLINE_RISK",
    field: "DeadlineRisk",
    key: "DeadlineRisk",
    module: moduleTypes.contracts,
    component: DeadlineRiskContracts
  },
  {
    titleId: "CONTRACTS_BUDGET_RISK",
    field: "BudgetRisk",
    key: "BudgetRisk",
    module: moduleTypes.contracts,
    component: BudgetRiskContracts
  },
  {
    titleId: "AVERAGE_STATUS",
    field: "PipelineWorkloadContract",
    key: "PipelineWorkloadContract",
    module: moduleTypes.contracts,
    component: PipelineWorkloadContract
  },
  {
    titleId: "CONTRACT_STATUS",
    field: "ContractStates",
    key: "ContractStates",
    module: moduleTypes.contracts,
    component: ContractStatusTotals
  }
];

// --------------------------------------------------------------------------------------------------------

const standardLayouts = [
  {
    text: <FormattedMessage id={"PERSONAL"} />,
    lsKey: "dashboard-layout",
    icon: faUserAlt,
    layout: PersonalLayoutModules,
    layoutConfig: ServicesLayout,
    cols: 2
  },
  {
    text: <FormattedMessage id={"CASES"} />,
    lsKey: "dashboard-layout-tickets",
    icon: faTicketSimple,
    layout: TicketsLayoutModules,
    layoutConfig: ServicesTicketLayout,
    cols: 12,
    moduleType: moduleTypes.tickets
  },
  {
    text: <FormattedMessage id={"TASKS"} />,
    lsKey: "dashboard-layout-tasks",
    layout: TasksLayoutModules,
    icon: faListCheck,
    layoutConfig: ServicesTaskLayout,
    cols: 12,
    moduleType: moduleTypes.tasks
  },
  {
    text: <FormattedMessage id={"PROJECTS"} />,
    lsKey: "dashboard-layout-projetos",
    layout: ProjectLayoutModules,
    icon: faSquareParking,
    layoutConfig: ServicesProjectLayout,
    cols: 12,
    moduleType: moduleTypes.projects
  },
  {
    text: <FormattedMessage id={"CONTRACTS"} />,
    lsKey: "dashboard-layout-contratos",
    layout: ContractLayoutModules,
    icon: faFileContract,
    layoutConfig: ServicesContractLayout,
    cols: 12,
    moduleType: moduleTypes.contracts
  },
  {
    text: <FormattedMessage id={"DEALS"} />,
    lsKey: "dashboard-layout-deals",
    icon: faHandshake,
    layout: DealLayoutModules,
    layoutConfig: ServicesDealLayout,
    cols: 12,
    moduleType: moduleTypes.deals
  }
];

const CustomizedLayouts = [
  {
    text: <FormattedMessage id={"CUSTOM"} />,
    lsKey: "dashboard-layout-customized",
    layout: CustomizedLayoutModules,
    icon: faCogs,
    layoutConfig: CustomizedLayout,
    cols: 12,
    moduleType: moduleTypes.tickets
  }
];

const useResolvedLayoutModules = (isAdmin, accountSpace, activeLayout) => {
  const layout = activeLayout.layout;
  const resolvedLayoutModules = useResolvedCard(layout, activeLayout.lsKey);
  const resolvedReversedLayoutModules = useReversedResolvedCard(
    layout,
    activeLayout.lsKey,
    true
  );

  return useMemo(() => {
    const layouts = [];
    const cards = [];
    const modules = accountSpace.Modules;

    if (activeLayout.lsKey === "dashboard-layout-customized") {
      for (const layoutModule of resolvedReversedLayoutModules) {
        const { module, component: Component, key, hasAccess } = layoutModule;
        if (hasAccess) {
          if (!hasAccess(accountSpace)) continue;
        } else if (!isAdmin && !hasModuleAccess(modules[module])) continue;

        layouts.push(
          <div key={key || module}>
            <Component />
          </div>
        );
      }

      for (const layoutModule of layout) {
        const { module, hasAccess, titleId, field } = layoutModule;
        if (hasAccess) {
          if (!hasAccess(accountSpace)) continue;
        } else if (!isAdmin && !hasModuleAccess(modules[module])) continue;

        cards.push({ titleId: titleId, field: field });
      }
    } else {
      for (const layoutModule of resolvedLayoutModules) {
        const { module, component: Component, key, hasAccess } = layoutModule;
        if (hasAccess) {
          if (!hasAccess(accountSpace)) continue;
        } else if (!isAdmin && !hasModuleAccess(modules[module])) continue;

        layouts.push(
          <div key={key || module}>
            <Component />
          </div>
        );
      }

      for (const layoutModule of layout) {
        const { module, hasAccess, titleId, field } = layoutModule;
        if (hasAccess) {
          if (!hasAccess(accountSpace)) continue;
        } else if (!isAdmin && !hasModuleAccess(modules[module])) continue;

        cards.push({ titleId: titleId, field: field });
      }
    }
    return [layouts, cards];
  }, [
    accountSpace,
    activeLayout.lsKey,
    resolvedReversedLayoutModules,
    isAdmin,
    layout,
    resolvedLayoutModules
  ]);
};

export const DashboardTable = ({
  type,
  advanced,
  basic,
  topClassName,
  ...rest
}) => {
  return (
    <div
      className={
        advanced
          ? "ar-dashboard-table-container of-y-auto h-75"
          : basic
          ? `ar-dashboard-table-container of-y-auto ${topClassName}`
          : `ar-dashboard-table-container of-y-auto px-4 ${topClassName}`
      }
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Table className="ar-dashboard-table" {...rest} />
    </div>
  );
};

export const DashboardTitle = ({
  icon,
  text,
  actionButton,
  children,
  className
}) => {
  return (
    <div className={(className || "mb-1 px-4") + " hideActionButton"}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="text-black fw-medium lh-normal d-flex ">
          {icon && (
            <FontAwesomeIcon icon={icon} className="mr-2 d-inline-block" />
          )}
          <span>{text}</span>
        </div>
        {actionButton}
      </div>
      {children}
    </div>
  );
};

export const DashboardEmptyResponse = ({
  type,
  icon,
  children,
  className,
  style
}) => {
  return (
    <div
      className={classnames(
        "flex-1 d-flex flex-column align-items-center h-auto position-relative d-block justify-content-center text-black fs-14",
        className
      )}
    >
      <div style={style} className={`ar-dashboard-empty-icon ${type} mb-2`} />
      {children}
    </div>
  );
};

export const DashboardSelector = ({
  onChange,
  availableLayouts,
  setActivelayout,
  activeLayout,
  value,
  text,
  availablePermissions
}) => {
  const anchorRef = useRef();
  const popupRef = useRef();

  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);

  const CustomsIndex = availableLayouts.findIndex((element) =>
    Array.isArray(element)
  );
  const normalLayouts =
    CustomsIndex !== -1
      ? availableLayouts.slice(0, CustomsIndex)
      : availableLayouts;
  const CustomsArray =
    CustomsIndex !== -1 ? availableLayouts[CustomsIndex] : [];
  //   const handleChange = newPermission => {
  //     toggleIsOpen(false);
  //     onChange(newPermission);
  //   };

  // const allValue = type === "view" ?

  //   const links = availablePermissions.map(permission => {
  //     return (
  //       <ServiceLink
  //         key={permission}
  //         permission={value}
  //         permissionType={permission}
  //         onClick={handleChange}
  //       />
  //     );
  //   });

  const { Id: spaceId } = useSpace();

  return (
    <React.Fragment>
      <div
        ref={anchorRef}
        className={classnames("d-flex align-items-center cursor-pointer", {
          "text-primary": !text
        })}
        onClick={(e) => toggleIsOpen(true)}
      >
        <div className="fs-18 mr-2 btn-link text-truncate">
          {activeLayout.text}
        </div>

        <FontAwesomeIcon icon={faCaretDown} />
      </div>
      <Popup
        domRef={popupRef}
        anchorEl={anchorRef.current}
        isOpen={isOpen}
        placement="bottom-start"
        className="ml-2"
      >
        <div
          style={{ padding: "0px 8px" }}
          className="pb-2 w-175px bg-white rounded"
        >
          <div className="pt-2 label-title">
            <FormattedMessage id={"DASHBOARDS"} />
          </div>
          {/* <div className="cursor-pointer btn-link pl-2 py-3 fs-14">
           
      </div> */}
          {normalLayouts.map((layout, i) => {
            const { icon } = layout;
            return (
              <Link
                to={`/s/${spaceId}/dashboard?view=${layout.lsKey}`}
                className="text-left ssi-link air-rounded-selection d-flex align-items-center px-3 py-2"
                key={layout.lsKey}
                style={{ borderRadius: 0 }}
                onClick={() => {
                  ChangeDefaultLayout(i);
                  setActivelayout(layout);
                }}
                // vType={"link"}
              >
                {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
                {layout.text}
              </Link>
            );
          })}
          {CustomsArray.length > 0 && (
            <div className="pt-2 mt-0 label-title">
              <FormattedMessage id={"CUSTOM_DASHBOARDS"} />
            </div>
          )}
          {CustomsArray.length > 0 &&
            CustomsArray.map((layout, i) => {
              const { icon } = layout;
              return (
                <Link
                  to={`/s/${spaceId}/dashboard?view=${layout.lsKey}`}
                  className="text-left ssi-link d-flex align-items-center px-3 py-2"
                  key={layout.lsKey}
                  style={{ borderRadius: 0 }}
                  onClick={() => {
                    ChangeDefaultLayout(i);
                    setActivelayout(layout);
                  }}
                  // vType={"link"}
                >
                  {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
                  {layout.text}
                </Link>
              );
            })}
          {/* <LoadableButton onClick={switchToServicesTickets} vType={"link"}>
            {" "}
            Serviços-Tickets
          </LoadableButton> */}
          {/* {text && <div className="mb-2 px-3 fs-14">Aplica a todos</div>} */}
          {/* {links} */}
        </div>
      </Popup>
    </React.Fragment>
  );
};

export const DashboardContent = ({
  accountSpace,
  hasAdminAccess,
  activeLayout,
  setActivelayout,
  availableLayouts,
  ...props
}) => {
  const layouts = useResolvedLayoutModules(
    hasAdminAccess,
    accountSpace,
    activeLayout
  );

  return (
    <LayoutContextProvider
      name={activeLayout.lsKey}
      layout={activeLayout.layoutConfig}
    >
      <DashboardMoreContent
        availableLayouts={availableLayouts}
        accountSpace={accountSpace}
        hasAdminAccess={hasAdminAccess}
        activeLayout={activeLayout}
        setActivelayout={setActivelayout}
        layouts={layouts}
        {...props}
      />
    </LayoutContextProvider>
  );
};

const DashboardMoreContent = ({
  accountSpace,
  hasAdminAccess,
  activeLayout,
  setActivelayout,
  layouts,
  availableLayouts,
  ...props
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);
  const ReportData = useContext(ReportsDateProvider);
  const DashboardSelectorRef = useRef();
  const { resetLayout } = useContext(LayoutContext);
  const filterColumns = useContext(ReportFiltersContext);
  const FullScreenStatus = useRef(false);

  const ToggleFullscreen = useContext(HeaderContext);

  const handleFullScreenToggle = () => {
    FullScreenStatus.current = !FullScreenStatus.current;
    ToggleFullscreen(FullScreenStatus.current);
    if (FullScreenStatus.current) {
      openFullscreen();
    } else {
      closeFullscreen();
    }
  };

  const handleFullScreenChangesWithNoButton = useCallback(() => {
    if (document.fullscreenElement) {
    } else if (FullScreenStatus.current) {
      FullScreenStatus.current = !FullScreenStatus.current;
      ToggleFullscreen(FullScreenStatus.current);
    }
  }, [ToggleFullscreen]);

  useEffect(() => {
    if (FullScreenStatus) {
      document.addEventListener(
        "fullscreenchange",
        handleFullScreenChangesWithNoButton
      );
    } else {
      document.removeEventListener(
        "fullscreenchange",
        handleFullScreenChangesWithNoButton
      );
    }
    return () => {
      document.removeEventListener(
        "fullscreenchange",
        handleFullScreenChangesWithNoButton
      );
    };
  }, [handleFullScreenChangesWithNoButton]);

  function openFullscreen() {
    const elem = document.documentElement;
    if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  function closeFullscreen() {
    if (document.fullscreen) {
      if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  }

  const intl = useIntl();

  return (
    <>
      {!availableLayouts && (
        <div className="text-black bg-white px-4 mb-2 py-2 d-flex align-items-center dashboardHeader justify-content-between">
          {!availableLayouts && ReportData && (
            <div className="mt-3">
              <span className="fs-24 text-black">
                <FormattedMessage id={"REPORT"} />{" "}
                {ReportData[0] === "Deals" ? (
                  <FormattedMessage id={"DEALS"} />
                ) : ReportData[0] === "Tasks" ? (
                  <FormattedMessage id={"TASKS"} />
                ) : ReportData[0] === "Contracts" ? (
                  <FormattedMessage id={"CONTRACTS"} />
                ) : ReportData[0] === "Projects" ? (
                  <FormattedMessage id={"PROJECTS"} />
                ) : (
                  <FormattedMessage id={"CASES"} />
                )}
              </span>
            </div>
          )}
          <div className="d-flex flex-1 justify-content-end align-items-center">
            {/* <div>
            Intervalo: 
            <span className="ml-1 text-primary">Todos os dados</span>
          </div> */}
            {filterColumns}
            <div className="d-flex flex-column">
              <span className="mb-1" style={{ paddingTop: 21 }}></span>
              <div className="d-flex align-items-center">
                <Button
                  style={{ width: 40, height: 40 }}
                  size="sm"
                  className="mr-2"
                  onClick={handleFullScreenToggle}
                  vType="primary-ghost"
                >
                  {!FullScreenStatus.current && (
                    <FontAwesomeIcon size="lg" icon={faExpandAlt} />
                  )}
                  {FullScreenStatus.current && (
                    <FontAwesomeIcon size="lg" icon={faCompressAlt} />
                  )}
                </Button>
                <DashboardActionButton
                  size="sm"
                  enableColumnEdit={() => {
                    setModalIsOpen(true);
                  }}
                  resetLayout={resetLayout}
                  activeLayout={activeLayout}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="ar-dashboard-container">
        <Modal
          // containerClassName=""
          isOpen={modalIsOpen}
          onClose={closeModal}
        >
          {activeLayout.lsKey === "dashboard-layout-customized" ? (
            <ReverseCardChooser
              grid={activeLayout.lsKey}
              Name={activeLayout.lsKey}
              Columns={layouts[1]}
              onClose={closeModal}
              setIsOpen={setModalIsOpen}
              isOpen={modalIsOpen}
            />
          ) : (
            <CardChooser
              grid={activeLayout.lsKey}
              Name={activeLayout.lsKey}
              Columns={layouts[1]}
              onClose={closeModal}
              setIsOpen={setModalIsOpen}
              isOpen={modalIsOpen}
            />
          )}
        </Modal>

        {availableLayouts && (
          <div className="text-black px-2 mt-4 d-flex bg-airdesk align-items-center justify-content-between">
            <div className="d-flex unselectable">
              <div ref={DashboardSelectorRef} className="fs-18 mr-3">
                Dashboard
              </div>
              <DashboardSelector
                availableLayouts={availableLayouts}
                activeLayout={activeLayout}
                setActivelayout={setActivelayout}
              />
            </div>
            <div className="d-flex align-items-center">
              <Button
                style={{ width: 40, height: 40 }}
                size="sm"
                className="mr-2"
                onClick={handleFullScreenToggle}
                vType="primary-ghost"
              >
                {!FullScreenStatus.current && (
                  <FontAwesomeIcon size="lg" icon={faExpandAlt} />
                )}
                {FullScreenStatus.current && (
                  <FontAwesomeIcon size="lg" icon={faCompressAlt} />
                )}
              </Button>
              {activeLayout.lsKey === "dashboard-layout-customized" ? (
                <ReverseDashboardActionButton
                  size="sm"
                  enableColumnEdit={() => {
                    setModalIsOpen(true);
                  }}
                  resetLayout={resetLayout}
                  activeLayout={activeLayout}
                ></ReverseDashboardActionButton>
              ) : (
                <DashboardActionButton
                  size="sm"
                  enableColumnEdit={() => {
                    setModalIsOpen(true);
                  }}
                  resetLayout={resetLayout}
                  activeLayout={activeLayout}
                ></DashboardActionButton>
              )}
            </div>
          </div>
        )}
        {layouts[0]?.length === 0 ? (
          <div className="layout ar-dashboard-layout d-flex align-items-center justify-content-center h-100">
            <Button
              onClick={() => {
                setModalIsOpen(true);
              }}
            >
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                <FormattedMessage id="CHOOSE_MODULES" />
              </div>
            </Button>
          </div>
        ) : (
          <DashboardLayout
            cols={activeLayout.cols}
            layoutConfig={activeLayout.layoutConfig}
            className="layout ar-dashboard-layout"
            // width={1200}
          >
            {layouts[0]}
          </DashboardLayout>
        )}
        <div className="d-flex justify-content-center align-items-center airWelcome_footer mb-3">
          <div className="mr-3 d-flex">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                intl.locale === "en"
                  ? "https://www.airdesk.ai/contact-us/"
                  : "https://www.airdesk.ai/pt-pt/contactos"
              }
            >
              <FormattedMessage id="SUPPORT" />
            </a>
            <span className="mx-3">•</span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                intl.locale === "en"
                  ? "https://en.docs.airdesk.ai"
                  : "https://docs.airdesk.ai"
              }
            >
              <FormattedMessage id="DOCUMENTATION" />
            </a>
          </div>
          <div className="mr-3 d-flex">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.airdesk.ai/facebook"
              className="mr-3"
            >
              <Facebook />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.airdesk.ai/instagram"
              className="mr-3"
            >
              <Instagram />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.airdesk.ai/twitter"
              className="mr-3"
            >
              <Twitter />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.airdesk.ai/linkedin"
            >
              <Linkedin />
            </a>
          </div>
          <div className="d-flex white-space-pre">
            <span>© Copyright {new Date().getFullYear()} Airdesk. </span>{" "}
            <FormattedMessage id="COPYRIGHT" />
          </div>
        </div>
      </div>
    </>
  );
};

const ChangeDefaultLayout = (layout) => {
  global.localStorage.setItem(
    "default_dashboard",
    JSON.stringify({
      layout: layout
    })
  );
};

export const ReportsDateProvider = React.createContext({});

export const DashboardSettingsProvider = React.createContext({});

export const DashboardAutoRefreshProvider = React.createContext({});

const Dashboard = ({ ...props }) => {
  const intl = useIntl();
  useAirdeskTitle(intl.formatMessage({ id: "DASHBOARD" }));
  // const { Name } = useCurrentAccount();

  const accountSpace = useCurrentAccountSpace();
  const modules = accountSpace.Modules;

  const admin = isAdmin(accountSpace);

  const resolvedLayouts = useMemo(() => {
    const arr = [];

    for (const layout of standardLayouts) {
      if (
        layout.reportsOnly &&
        !props.ReportsTickets &&
        !props.ReportsTasks &&
        !props.ReportsDeals
      )
        continue;
      if (layout.moduleType) {
        if (!admin && !hasModuleAccess(modules[layout.moduleType])) continue;
        if (
          layout.moduleType === moduleTypes.deals &&
          !hasDealFinancialPermission(accountSpace)
        )
          continue;
        if (
          layout.moduleType === moduleTypes.contracts &&
          !hasContractFinancialPermission(accountSpace)
        )
          continue;
      }
      arr.push(layout);
    }
    arr.push([...CustomizedLayouts]);
    return arr;
  }, [
    accountSpace,
    admin,
    modules,
    props.ReportsDeals,
    props.ReportsTasks,
    props.ReportsTickets
  ]);

  // const layoutChoice = CheckDefaultLayout();
  // const [activeLayout, setActivelayout] = useState(
  //   props.ReportsTickets
  //     ? dashboardLayouts[4]
  //     : props.ReportsTasks
  //     ? dashboardLayouts[5]
  //     : props.ReportsDeals
  //     ? dashboardLayouts[6]
  //     : arrayHasIndex(resolvedLayouts, layoutChoice)
  //     ? resolvedLayouts[layoutChoice]
  //     : resolvedLayouts[0]
  // );

  const [activeLayout, setActivelayout] = useDashboardLayoutState(
    standardLayouts,
    CustomizedLayouts
  );

  useDashboardViewUpdater(activeLayout);
  const hasAdminAccess = isAdmin(accountSpace);

  const [autoRefresh, setAutoRefresh] = useState(false);

  const lsTimeout = parseInt(localStorage.getItem("air-dash-update"));

  const [timeout, setTimeout] = useState(lsTimeout || 900000);
  return (
    // <RealTimePageProvider pageType={RealTimePageEnum.Dashboard}>
    <DashboardProvider autoRefresh={autoRefresh} timeout={timeout}>
      <DashboardAutoRefreshProvider.Provider
        value={{ autoRefresh, setAutoRefresh, setTimeout, timeout }}
      >
        <PageContainer className={"overflow-auto"}>
          <PageContent
            className={`${
              props.ReportData ? "bg-white" : "bg-airdesk"
            } px-0 flex-column`}
          >
            <ReportsDateProvider.Provider
              value={props.ReportData ? props.ReportData : []}
            >
              <DashboardContent
                availableLayouts={resolvedLayouts}
                key={activeLayout.lsKey}
                accountSpace={accountSpace}
                activeLayout={activeLayout}
                hasAdminAccess={hasAdminAccess}
                setActivelayout={setActivelayout}
                {...props}
              />
            </ReportsDateProvider.Provider>
          </PageContent>
        </PageContainer>
      </DashboardAutoRefreshProvider.Provider>
    </DashboardProvider>
    // </RealTimePageProvider>
  );
};

export default Dashboard;
