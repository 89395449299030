export enum PipelineTypeEnum {
  Project = 1,
  Ticket = 2,
  Call = 3,
  Task = 4,
  Contract = 5,
  Deal = 6,
  Subscription = 8,
  Documents = 9,
  Quotes = 10
}
