import React, { useState } from "react";
import FlagIcon from "../FlagIcon/FlagIcon";
import "./Img.css";

const buildImgClass = ({ size, circular, className }) => {
  let baseClass = "img";

  switch (size) {
    case "lg":
      baseClass += " lg";
      break;
    case "sm":
      baseClass += " sm";
      break;
    case "md":
      baseClass += " md";
      break;
    case "mds":
      baseClass += " mds";
      break;
    case "xsm":
      baseClass += " xsm";
      break;
    default:
      break;
  }

  if (circular) baseClass += " circular";

  if (className) baseClass += ` ${className}`;

  return baseClass;
};

const Img = (props) => {
  const { src, Name, ...rest } = props;

  const [imageError, setImageError] = useState(false);
  if (!src) return null;
  if (imageError) {
    let classes = buildImgClass(rest);
    classes += " account";
    if (!Name)
      return <div style={{ background: "#d5dee8" }} className={classes}></div>;

    let names = Name.match(/[\w\u00C0-\u017F]+/g);
    let firstName = names ? names[0] : "";
    let lastName = names ? names[names.length - 1] : "";

    let firstLetter = firstName.charAt(0);
    let lastLetter = lastName.charAt(firstName === lastName);

    return (
      <div className={classes}>
        <span>{firstLetter}</span>
        <span>{lastLetter}</span>
      </div>
    );
	}
	
  return (
    <img
      onError={() => {
        setImageError(true);
      }}
      className={buildImgClass(props)}
      alt=""
      src={src}
    />
  );
};

export const AccountProfileImg = ({ account = {}, ...rest }) => {
  const { Image, Name } = account || {};

  if (Image) return <Img src={Image} Name={Name} {...rest} />;

  let classes = buildImgClass(rest);
  classes += " account";
  if (!Name)
    return <div style={{ background: "#d5dee8" }} className={classes}></div>;

  let names = Name.match(/[\w\u00C0-\u017F]+/g);
  let firstName = names ? names[0] : "";
  let lastName = names ? names[names.length - 1] : "";

  let firstLetter = firstName.charAt(0);
  let lastLetter = lastName.charAt(firstName === lastName);

  return (
    <div className={classes}>
      <span>{firstLetter}</span>
      <span>{lastLetter}</span>
    </div>
  );
};

export const AccountLogoImg = ({
  showRectagle,
  account,
  Image,
  url,
  ...rest
}) => {
  if (url) return <Img className="logo" src={url} {...rest} />;
  const { Name } = account;
  if (!Name && showRectagle) {
    let classes = buildImgClass(rest);
    return <div className={classes}></div>;
  }
  if (!Name) return null;

  let classes = buildImgClass(rest);
  classes += " account logo";
  let names = Name.match(/[\w\u00C0-\u017F]+/g);
  let firstName = names[0];
  let lastName = names[names.length - 1];

  let firstLetter = firstName.charAt(0);
  let lastLetter = lastName.charAt(firstName === lastName);

  return (
    <div className={classes}>
      <span>{firstLetter}</span>
      <span>{lastLetter}</span>
    </div>
  );
};

export const MultipleAccountProfileImg = ({ items, ...rest }) => {
  const width = items.length * 32 - (items.length - 1) * 7;
  return (
    <div className="account-profiles" style={{ width }}>
      {items.map((item, i) => (
        <div title={item.Name} key={i} style={{ zIndex: items.length - i }}>
          <AccountProfileImg key={i} size="sm" account={item} circular />
        </div>
      ))}
    </div>
  );
};

export const CountryProfileImg = ({ country, ...rest }) => {
  // console.log(country)
  if (!country) return null;
  if (!country.Code) return null;
  let classes = buildImgClass(rest);
  classes += " country";

  return (
    <div className={classes}>
      <FlagIcon code={country.Code.toLowerCase()} size="2x" />
    </div>
  );
};

export default Img;
