import React, { useContext, useEffect } from "react";

import classnames from "classnames";

import moment from "moment";
import {
  ReportsDateProvider,
  DashboardTitle,
  DashboardEmptyResponse
} from "../Dashboard";
import { getEntity } from "../../../Helpers/IOClient";
import {
  ContractsOpenChart,
  ContractsChart
} from "../Echarts/AverageEvolutionTicketsLineChart";
import { BarLoader } from "../../../Components/GlobalLoader/GlobalLoader";
import { spacePipelineSchema } from "../../../config/schema";
import { ContractMainViewLink } from "../../../Helpers/HooksHelper";
import { useCurrentAccount } from "../../../Contexts/UserContext";
import { FormattedMessage } from "react-intl";
import { useSidebar } from "../../../Components/Sidebar/SidebarV2";
import { DashboardSettingsContext } from "../DashboardLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import DashboardCardSettingsForm, {
  handleIntervalCalculation
} from "../DashboardCardSettingsForm";
import Button from "../../../Components/Button/Button";
import { useDashboardQuery } from "../DashboardQueryHelper";
import CreateDashboardFilterLinksHelper from "../DashboardFilterLinksHelper";

const defaultTimeFrame = moment().subtract(180, "d").format("YYYY-MM-DD");

const lS_Group = "dashboard-layout-contratos";
export const AverageEvolutionContracts = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "evolutionContracts";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Contracts/evolution?todate=${
      DataReports[0] === "Contracts"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Contracts"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Contracts"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Contracts" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const Results = data;
    let HasData = false;
    for (const e of Results) {
      const { Data } = e;
      for (const key in Data) {
        if (Object.hasOwnProperty.call(Data, key)) {
          const element = Data[key];
          if (parseInt(element) > 0) {
            HasData = true;
            break;
          }
        }
      }
      if (HasData) {
        break;
      }
    }
    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"CONTRACTS_ACTIVITY"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Contracts" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"180DAYS"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    disableAll={{
                      title: "180DAYS",
                      fromDate: moment()
                        .subtract(180, "days")
                        .format("YYYY-MM-DD"),
                      toDate: moment().add(1, "day").format("YYYY-MM-DD")
                    }}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"CONTRACTS_ACTIVITY"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <ContractsChart Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="contracts">
            <FormattedMessage id={"EMPTY_CONTRACTS"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const OpenContractsEvolution = () => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "openEvolutionContracts";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings =
    (DashboardSettings &&
      DashboardSettings[lS_Group] &&
      DashboardSettings[lS_Group][lS_Field]) ||
    {};

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Contracts/OpenEvolution?todate=${
      DataReports[0] === "Contracts"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Contracts"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? defaultTimeFrame
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : defaultTimeFrame
    }${
      DataReports[0] === "Contracts"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  const user =
    DataReports[0] === "Contracts" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const { Results, HasData } = data;

    return (
      <div className="px-3 of-hidden h-100 d-flex flex-column">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"ACTIVE_CONTRACTS_PROGRESS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Contracts" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"180DAYS"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    disableAll={{
                      title: "180DAYS",
                      fromDate: moment()
                        .subtract(180, "days")
                        .format("YYYY-MM-DD"),
                      toDate: moment().add(1, "day").format("YYYY-MM-DD")
                    }}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    Title={"OPEN_CONTRACTS_PROGRESS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: false
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        {HasData ? (
          <div className="h-100">
            <ContractsOpenChart Data={Results} />
          </div>
        ) : (
          <DashboardEmptyResponse className="header h-100" type="contracts">
            <FormattedMessage id={"EMPTY_CONTRACTS"} />
          </DashboardEmptyResponse>
        )}
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};

export const ContractStatusTotals = () => {
  const { data, error, loading } = useDashboardQuery("query/Pipelines?Type=5", [
    spacePipelineSchema
  ]);

  if (!loading && !error) {
    return <ContractStatusTotalsResult results={data} />;
  } else {
    return <BarLoader style={{ marginTop: -16 }} isLoading={loading} />;
  }
};

const ContractStatusTotalsResult = ({ results }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const lS_Field = "ContractStates";

  const DashboardSettings =
    JSON.parse(localStorage.getItem("ar-dashboard-settings")) || {};
  const Settings = (DashboardSettings &&
    DashboardSettings[lS_Group] &&
    DashboardSettings[lS_Group][lS_Field]) || { Pipeline: results[0] };

  const handleLayoutChange = useContext(DashboardSettingsContext);

  useEffect(() => {
    return () => closeSidebar();
  }, [closeSidebar]);

  const { ...DataReports } = useContext(ReportsDateProvider);
  const pipeline =
    DataReports[0] === "Contracts" ? DataReports[3] : Settings.Pipeline;
  const { data, error, loading } = useDashboardQuery(
    `Dashboard/Services/Contracts/PipelineStatus?pipelineid=${
      pipeline ? pipeline : Settings.Pipeline
    }&todate=${
      DataReports[0] === "Contracts"
        ? DataReports[1].toDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "toDate",
              Settings.Interval
            )
        : ""
    }&fromdate=${
      DataReports[0] === "Contracts"
        ? DataReports[1].fromDateString
        : Settings.Interval
        ? Settings.Interval.title === "ALL"
          ? ""
          : handleIntervalCalculation(
              Settings.Interval.title,
              "fromDate",
              Settings.Interval
            )
        : ""
    }${
      DataReports[0] === "Contracts"
        ? DataReports[5]
          ? `&workspace=true`
          : DataReports[4] === "Account"
          ? `&userid=${DataReports[2].Id}`
          : DataReports[4] === "Department"
          ? `&department=${DataReports[2].Id}`
          : DataReports[4] === "organization"
          ? `&company=${DataReports[2] && DataReports[2].Id}`
          : `&team=${DataReports[2].Id}`
        : ""
    }`
  );
  var account = useCurrentAccount().Id;
  const user =
    DataReports[0] === "Contracts" ? (
      DataReports[2]?.Name
    ) : (
      <FormattedMessage id={"ME"} />
    );
  if (!loading && !error) {
    const resolvedData = data;
    return (
      <div className="px-3 of-hidden">
        <div className="d-flex justify-content-between">
          <DashboardTitle
            className="mb-0 fs-20"
            text={<FormattedMessage id={"CONTRACT_STATUS"} />}
          />
          <div>{/* <FontAwesomeIcon icon={faEllipsisV} /> */}</div>
        </div>
        {DataReports[0] !== "Contracts" && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2">
              <span className="ar-dashboard-description">
                <FormattedMessage id={"INTERVAL"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {Settings.Interval && Settings.Interval.title !== "ALL" ? (
                  <FormattedMessage id={Settings.Interval.title} />
                ) : (
                  <FormattedMessage id={"ALL"} />
                )}
              </span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"SCOPE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">{user}</span>
              <span className="ar-dashboard-description ml-1">
                | <FormattedMessage id={"PIPELINE"} />:
              </span>
              <span className="text-primary pl-1 fs-14">
                {
                  getEntity(
                    spacePipelineSchema,
                    pipeline ? pipeline : Settings.Pipeline
                  )?.Name
                }
              </span>
            </div>
            <Button
              className="ar-dashboard-card-settings-link"
              vType="link-primary"
              onClick={() =>
                openSidebar(
                  <DashboardCardSettingsForm
                    lS_Field={lS_Field}
                    lS_Group={lS_Group}
                    Settings={Settings}
                    closeSidebar={closeSidebar}
                    pipelineType={5}
                    Title={"CONTRACT_STATUS"}
                    handleChanges={handleLayoutChange}
                    AvailableSettings={{
                      interval: true,
                      scope: true,
                      pipeline: true
                    }}
                  />
                )
              }
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          </div>
        )}
        <div className="d-flex justify-content-between px-2">
          {resolvedData.map((e, i) => {
            return (
              <div key={i} className="d-flex flex-column">
                <ContractMainViewLink
                  className={classnames({
                    "ar-dashboard-active-values": e.PipelineStatus.Status === 1,
                    "ar-dashboard-closed-blue-values":
                      e.PipelineStatus.Status === 2,
                    "ar-dashboard-canceled-values":
                      e.PipelineStatus.Status === 3,
                    "ar-dashboard-review-values": e.PipelineStatus.Status === 5,
                    "ar-dashboard-draft-values": e.PipelineStatus.Status === 6,
                    "ar-dashboard-closed-values": e.PipelineStatus.Status === 7
                  })}
                  params={`$filter=Contract/PipelineStatusId eq '${
                    e.PipelineStatus.Id
                  }'${CreateDashboardFilterLinksHelper(
                    DataReports,
                    account
                  )}&pipeline=${pipeline}`}
                >
                  {e.Count}
                </ContractMainViewLink>

                <div
                  className={classnames({
                    "ar-dashboard-active-description":
                      e.PipelineStatus.Status === 1,
                    "ar-dashboard-won-description":
                      e.PipelineStatus.Status === 2,
                    "ar-dashboard-canceled-description":
                      e.PipelineStatus.Status === 3,
                    "ar-dashboard-review-description":
                      e.PipelineStatus.Status === 5,
                    "ar-dashboard-draft-description":
                      e.PipelineStatus.Status === 6,
                    "ar-dashboard-closed-description":
                      e.PipelineStatus.Status === 7
                  })}
                >
                  {e.PipelineStatus?.Name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <BarLoader style={{ marginTop: -16 }} isLoading={loading || error} />
    );
  }
};
