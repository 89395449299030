import React from "react";
import {
  spaceClientSchema,
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceCallsSchema,
  spaceTasksSchema,
  spaceContractSchema,
  accountSchema,
  spaceContactSchema,
  spaceSubscriptionSchema,
  spaceDealsSchema
} from "../../config/schema";
import { ConnectedClientProfile } from "../Clients/ClientProfile";

import {
  SearchPreviewClient,
  SearchPreviewProject,
  SearchPreviewTicket,
  SearchPreviewCall,
  SearchPreviewTask,
  SearchPreviewContract,
  SearchPreviewAccount,
  SearchPreviewContacts,
  SearchPreviewSubscription,
  SearchPreviewDeals
} from "./SearchProfiles/SearchProfiles";
import { ConnectedProjectListItem } from "../Projects/ProjectListItem";
import { ConnectedTicketListItem } from "../Tickets/TicketListItem";
import { ConnectedCallListItem } from "../Calls/CallListItem";
import { ConnectedTaskListItem } from "../Tasks/TaskListItem";
import { ConnectedContractListItem } from "../Contract/ContractListItem";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { originTypes } from "../../Helpers/MiscHelper";
import { ConnectedAccountProfile } from "../Accounts/AccountProfile";
import { FormattedMessage } from "react-intl";
import { ConnectedContactProfile } from "../Contacts/ContactProfile";
import { ConnectedSubscriptionListItem } from "../Subscription/SubscriptionListItem";
import { ConnectedDealProfile } from "../Deals/DealProfile";
import {
  CallFormButton,
  ClientFormButton,
  ContactFormButton,
  ContractFormButton,
  DealFormButton,
  ProjectFormButton,
  SubscriptionFormButton,
  TaskFormButton,
  TicketFormButton
} from "../../Containers/Form/FormButtons";

export const clientQuery = {
  type: originTypes.client,
  endpoint: "query/clients",
  name: <FormattedMessage id={"ACCOUNTS"} />,
  schema: spaceClientSchema,
  Component: ({ value }) => <ConnectedClientProfile client={value} />,
  PreviewComponent: SearchPreviewClient,
  getLink: ({ id }) => `customers/${id}`,
  moduleType: moduleTypes.clients,
  GetFormButton: () => ClientFormButton
};

export const contactQuery = {
  type: originTypes.contact,
  endpoint: "query/contacts",
  name: <FormattedMessage id={"CONTACTS"} />,
  schema: spaceContactSchema,
  Component: ({ value }) => <ConnectedContactProfile contact={value} />,
  PreviewComponent: SearchPreviewContacts,
  getLink: ({ id }) => `contacts/${id}`,
  moduleType: moduleTypes.contacts,
  GetFormButton: () => ContactFormButton
};

export const subscriptionQuery = {
  type: originTypes.subscription,
  endpoint: "query/subscriptions",
  name: <FormattedMessage id={"SUBSCRIPTIONS"} />,
  schema: spaceSubscriptionSchema,
  Component: ({ value }) => (
    <ConnectedSubscriptionListItem Subscription={value} />
  ),
  PreviewComponent: SearchPreviewSubscription,
  getLink: ({ id }) => `subscriptions/${id}`,
  moduleType: moduleTypes.subscriptions,
  GetFormButton: () => SubscriptionFormButton
};

export const dealQuery = {
  type: originTypes.deals,
  endpoint: "query/deals",
  name: <FormattedMessage id={"DEALS"} />,
  schema: spaceDealsSchema,
  Component: ({ value }) => <ConnectedDealProfile deal={value} />,
  PreviewComponent: SearchPreviewDeals,
  getLink: ({ id }) => `deals/${id}`,
  moduleType: moduleTypes.deals,
  GetFormButton: () => DealFormButton
};

export const projectQuery = {
  type: originTypes.project,
  endpoint: "query/projects",
  name: <FormattedMessage id={"PROJECTS"} />,
  schema: spaceProjectsSchema,
  Component: ({ value }) => <ConnectedProjectListItem project={value} />,
  PreviewComponent: SearchPreviewProject,
  getLink: ({ id }) => `projects/${id}`,
  moduleType: moduleTypes.projects,
  GetFormButton: () => ProjectFormButton
};

export const ticketQuery = {
  type: originTypes.ticket,
  endpoint: "query/tickets",
  name: <FormattedMessage id={"CASES"} />,
  schema: spaceTicketsSchema,
  Component: ({ value }) => <ConnectedTicketListItem ticket={value} />,
  PreviewComponent: SearchPreviewTicket,
  getLink: ({ id }) => `tickets/${id}`,
  moduleType: moduleTypes.tickets,
  GetFormButton: () => TicketFormButton
};

export const callQuery = {
  type: originTypes.call,
  endpoint: "query/calls",
  name: <FormattedMessage id={"CALLS"} />,
  schema: spaceCallsSchema,
  Component: ({ value }) => <ConnectedCallListItem call={value} />,
  PreviewComponent: SearchPreviewCall,
  getLink: ({ id }) => `calls/${id}`,
  moduleType: moduleTypes.calls,
  GetFormButton: () => CallFormButton
};

export const taskQuery = {
  type: originTypes.task,
  endpoint: "query/tasks",
  name: <FormattedMessage id={"TASKS"} />,
  schema: spaceTasksSchema,
  Component: ({ value }) => <ConnectedTaskListItem task={value} />,
  PreviewComponent: SearchPreviewTask,
  getLink: ({ id }) => `tasks/${id}`,
  moduleType: moduleTypes.tasks,
  GetFormButton: () => TaskFormButton
};

export const contractQuery = {
  type: originTypes.contract,
  endpoint: "query/contracts",
  name: <FormattedMessage id={"CONTRACTS"} />,
  schema: spaceContractSchema,
  Component: ({ value }) => <ConnectedContractListItem contract={value} />,
  PreviewComponent: SearchPreviewContract,
  getLink: ({ id }) => `contracts/${id}`,
  moduleType: moduleTypes.contracts,
  GetFormButton: () => ContractFormButton
};

export const userQuery = {
  type: originTypes.users,
  endpoint: "query/accounts",
  name: <FormattedMessage id={"USERS"} />,
  schema: accountSchema,
  Component: ({ value }) => <ConnectedAccountProfile account={value} />,
  PreviewComponent: SearchPreviewAccount,
  // getLink: ({ id }) => `accounts/${id}`,
  moduleType: moduleTypes.users
};
