import 'moment/locale/pt'
import moment from "moment";
moment.locale("pt");
// moment.updateLocale('pt', {
//     relativeTime : {
//         future : 'em %s',
//         past : 'há %s',
//         s : 'alguns segundos',
//         ss : '%d segundos',
//         m : 'um min',
//         mm : '%d min',
//         h : 'uma h',
//         hh : '%d h',
//         d : 'um dia',
//         dd : '%d dias',
//         M : 'um mês',
//         MM : '%d meses',
//         y : 'um ano',
//         yy : '%d anos'
//     }
//   });