import { faCalendarExclamation } from "@fortawesome/pro-solid-svg-icons";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import { formatAutomationSubscriptionForm } from "../../../../../Helpers/FormFormmaterHelper";
import { isValueExpression } from "../../../../../Helpers/MiscHelper";
import { PipelineTypeEnum } from "../../../../Settings/Pipelines/NewPipelineHelper";
import SubscriptionsFormContent from "../../../../Subscriptions/SubscriptionsFormContent";
import { defaultSubscriptionForm } from "../../../../Subscriptions/SubscriptionsHelper";
import { ElementsRefContext } from "../../AutomationDetails";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import SourceHandles from "../../Handles/SourceHandles";
import TargetHandles from "../../Handles/TargetHandles";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import AutomationCreation, {
  EntityAutomationCreationSidebar,
  snakeToFormCase
} from "./CreationNode";

const convertAutomationToSubscriptionForm = (form, currencies) => {
  const {
    deslocations,
    description,
    estimated_cost,
    sales_opportunity,
    internal_description,
    departments,
    followers,
    status,
    interval_minimum,
    creator,
    rounding_margin,
    deal_id,
    contact_id,
    pipeline_status_id,
    pipeline_id,
    manager_id,
    client_id,
    entity_type_id,
    time_value,
    time_unit,
    // covenant,
    accounts,
    teams,
    // schedules,
    organization_id,
    begin_date,
    end_date,
    target,
    billing_period_time_value,
    billing_period_time_unit,
    period_time
  } = form;

  const TeamCollab = [];

  for (const t of teams) {
    TeamCollab.push({
      data: t,
      type: "Team"
    });
  }
  for (const t of accounts) {
    TeamCollab.push({
      data: t,
      type: "Account"
    });
  }

  const resolvedBeginDate = isValueExpression(begin_date)
    ? begin_date
    : new Date(begin_date);
  const resolvedEndDate = isValueExpression(end_date)
    ? end_date
    : new Date(end_date);

  const resolvedCovnent = { TimeUnit: time_unit, TimeValue: time_value };
  const resolvedBillingPeriod = {
    TimeUnit: billing_period_time_unit,
    TimeValue: billing_period_time_value
  };

  let resolvedForm = {
    Deslocations: deslocations,
    Client: client_id,
    Description: description,
    EstimatedCost: estimated_cost,
    SalesOpportunity: sales_opportunity,
    InternalDescription: internal_description,
    Departments: departments ? departments : [],
    Followers: followers ? followers : [],
    Status: status,
    TeamCollab,
    Company: organization_id,
    Covenant: resolvedCovnent,
    IntervalMinimum: interval_minimum,
    RoundingMargin: rounding_margin,
    Deal: deal_id,
    Creator: creator,
    Contact: contact_id,
    PipelineStatus: pipeline_status_id,
    Pipeline: pipeline_id,
    Manager: manager_id,
    Accounts: accounts ? accounts : [],
    Teams: teams ? teams : [],
    BeginDate: resolvedBeginDate,
    EndDate: resolvedEndDate,
    SubscriptionType: entity_type_id,
    // EstimatedCostsDict: estimated_costs ? estimated_costs : [],
    // EstimatedCostsHour: estimated_costs_hour
    //   ? JSON.parse(estimated_costs_hour)
    //   : [],
    BillingPeriod: resolvedBillingPeriod,
    PeriodTime: period_time
  };

  const { estimated_costs, estimated_costs_hour } = form;
  if (estimated_costs) {
    const EstimatedCostsDict = {};

    for (const val of estimated_costs) {
      const newVal = {};
      for (const t in val) {
        newVal[snakeToFormCase(t)] = val[t];
      }
      EstimatedCostsDict[newVal.CurrencyId] = newVal;
    }

    for (const curr of currencies) {
      if (!EstimatedCostsDict[curr]) {
        EstimatedCostsDict[curr] = {
          Cost: 0,
          CurrencyId: curr
        };
      }
    }

    resolvedForm = {
      ...resolvedForm,
      EstimatedCostsDict
    };
  }
  if (estimated_costs_hour) {
    const EstimatedCostsHourDict = {};

    for (const val of estimated_costs_hour) {
      const newVal = {};
      for (const t in val) {
        newVal[snakeToFormCase(t)] = val[t];
      }
      EstimatedCostsHourDict[newVal.CurrencyId] = newVal;
    }

    for (const curr of currencies) {
      if (!EstimatedCostsHourDict[curr]) {
        EstimatedCostsHourDict[curr] = {
          Cost: 0,
          CurrencyId: curr
        };
      }
    }

    resolvedForm = {
      ...resolvedForm,
      EstimatedCostsHourDict
    };
  }

  return resolvedForm;
};

const settings = {
  convertAutomationToForm: convertAutomationToSubscriptionForm,
  buildBaseForm: ({ user, id, convertedForm, currencies }) => {
    const defaultForm = defaultSubscriptionForm;
    return { ...defaultForm, ...convertedForm };
  },
  getDefaultForm: () => {
    return defaultSubscriptionForm;
  },
  pipelineType: PipelineTypeEnum.Subscription,
  FormContent: (props) => {
    return <SubscriptionsFormContent {...props}></SubscriptionsFormContent>;
  },
  formatToSnake: formatAutomationSubscriptionForm
};

const SubscriptionCreation = React.memo(({ selected, id, data, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const AllElementsRef = useContext(ElementsRefContext);
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleDoubleClick = () => {
    openSidebar(
      <EntityAutomationCreationSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        CurrentNodeId={id}
        settings={settings}
        data={data}
        handleSubmit={handleSubmit}
        AllElements={AllElementsRef.current}
      />
    );
  };
  const { enabled, config } = data;
  const intl = useIntl();
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <AutomationCreation
          config={config}
          enabled={enabled}
          selected={selected}
          type={type}
          onDoubleClick={handleDoubleClick}
          icon={faCalendarExclamation}
          text={intl.formatMessage({ id: "CREATE_SUBSCRIPTION" })}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default SubscriptionCreation;
