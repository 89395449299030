import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

export const SuccessAlert = ({ children, ...rest }) => (
  <Alert type="success" {...rest}>
    {children}
  </Alert>
);

export const InfoAlert = ({ children, ...rest }) => (
  <Alert type="info" {...rest}>
    {children}
  </Alert>
);

export const WarningAlert = ({ children, ...rest }) => (
  <Alert type="warning" {...rest}>
    {children}
  </Alert>
);

export const DangerAlert = ({ children, ...rest }) => (
  <Alert type="danger" {...rest}>
    {children}
  </Alert>
);

export const NeutralAlert = ({ children, ...rest }) => (
  <Alert type="neutral" {...rest}>
    {children}
  </Alert>
);

const Alert = ({ type, children, className, isDiv, ...rest }) => {
  return (
    <div
      {...rest}
      className={classnames(`ar-toast__item ar-toast__${type}`, className)}
    >
      <div className="ar-toast__item__content">
        {isDiv ? (
          <div className="ar-toast__item__content__msg">{children}</div>
        ) : (
          <div className="ar-toast__item__content__msg">{children}</div>
        )}
      </div>
    </div>
  );
};

export const NotificationAlert = ({
  customWidth,
  zdex,
  exit,
  placementTop,
  placementBot,
  transition,
  children,
  title,
  transitionStyle,
  icon,
  handleClick,
  type,
  entry,
  ...props
}) => (
  <div
    style={{
      width: customWidth,
      zIndex: zdex,
      marginTop: placementTop,
      marginBottom: placementBot,
      transition: transitionStyle,
    }}
    {...props}
    onClick={handleClick}
    className={`ar-toast__item ar-toast__${type} ${entry} ${exit}`}
  >
    <div className="w-100">
      <div className="d-flex align-items-center justify-content-center w-100 pr-2 pt-2">
        <div className="ar-toast__item__content__icon">{icon}</div>
        <div className="flex-1 text-truncate mr-2">
          <b className="ar-toast__item__content__title">
            {title ? title : <FormattedMessage id="SUCCESS" />}
          </b>
        </div>
        <div>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="ar-toast__item__content__msg pb-2 pr-2">{children}</div>
    </div>
    {/* {icon ? <div className="ar-toast__item__content__icon">{icon}</div> : ""}
    <div className="ar-toast__item__content d-flex flex-column">
      {title && <b className="ar-toast__item__content__title">{title}</b>}
      <span className="ar-toast__item__content__msg">{children}</span>
    </div>
    <div className="ar-toast__item__content__closer">
      <FontAwesomeIcon icon={faTimes} />
    </div> */}
  </div>
);
