import React, { useMemo } from "react";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { InterventionMainViewLink } from "../../Helpers/HooksHelper";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import Table from "../../Components/Table";
import { GridDateComponent } from "../../Components/CGrid/CGrid";
import { MilSecToFormatedHourMinute } from "../../Helpers/MiscHelper";
import Badge from "../../Components/Badge/Badge";
import { spaceMissingTimeChargeSchema } from "../../config/schema";
import { useMissingTimeCharges } from "../../Hooks/EntityHooks";
import { DashboardTitle } from "./Dashboard";
import {
  useInterventionCommunicator,
  INTERVENTION_CREATED,
  INTERVENTION_UPDATED
} from "../Interventions/InterventionsHelper";
import { InterventionFormButton } from "../Form/FormButtons";
import { useCurrentAccount } from "../../Contexts/UserContext";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";

const TimeChargeItem = ({ id, fetch }) => {
  // const PositiveDuration = 0;
  const item = useMissingTimeCharges(id);
  const { Duration, Date: IssueDate, IsWeekend } = item;

  // const resolvedDuration = MilSecToHour(Duration);
  const newDuration = MilSecToFormatedHourMinute(Duration);

  // const mutate = useSchemaMutation(spaceMissingTimeChargeSchema, id);

  const getFormProps = () => {
    const BeginDate = new Date(IssueDate);
    BeginDate.setHours(9);

    const EndDate = new Date(IssueDate);
    EndDate.setHours(10);
    const formProps = {
      key: IssueDate,
      defaultForm: {
        BeginDate,
        EndDate
      }
    };
    return formProps;
  };

  const button = (
    <InterventionFormButton size={"sm"} alt formProps={getFormProps} />
  );
  let text;

  if (IsWeekend)
    text = <span className="text-truncate fs-14 mr-2">{newDuration}</span>;
  else if (Duration > 0)
    text = (
      <span className="text-success mr-2 fs-14 text-truncate">
        {newDuration}
      </span>
    );
  else
    text = (
      <Badge extraClassName="fs-14" type="danger-alt" className="mr-2">
        {newDuration}
      </Badge>
    );

  return (
    <div className="d-flex align-items-center fs-14">
      {text} {button}
    </div>
  );
};
const FilterBegin = "$filter=";

const TimeChargeTable = ({ items, fetch, isLoading }) => {
  const Account = useCurrentAccount();

  const { columns, data } = useMemo(() => {
    if (!items) return {};
    const cols = [];
    const data = {};

    for (const itemId of items) {
      const item = getEntity(spaceMissingTimeChargeSchema, itemId);

      const col = {
        title: (
          <InterventionMainViewLink
            params={
              FilterBegin +
              `Account/Id eq '${
                Account.Id
              }' and TimeCharge/BeginDateNoHours eq datetime'${moment(
                item.Date
              ).format("YYYY-MM-DD")}'`
            }
          >
            <GridDateComponent
              style={{ background: "white" }}
              className={
                item.IsWeekend
                  ? "weekend-dashboard"
                  : item.IsHoliday
                  ? "weekend-dashboard"
                  : "text-black fw-medium"
              }
              hideHours
              alternateFormat
              date={new Date(item.Date)}
            />
          </InterventionMainViewLink>
        ),
        field: item.Date,
        titleClass: item.IsHoliday
          ? "weekend-dashboard border-right"
          : item.IsWeekend
          ? "weekend-dashboard border-right"
          : "border-right",
        cellClass: item.IsHoliday
          ? "bg-weekendStripes border-right"
          : item.IsWeekend
          ? "bg-weekendStripes border-right"
          : "border-right",
        Cell: () => <TimeChargeItem fetch={fetch} id={itemId} />
      };
      cols.push(col);
      data[item.Date] = item;
    }
    return { columns: cols, data: [data] };
  }, [Account.Id, fetch, items]);

  return (
    <Table
      className="ar-time-charge-table ClassificationReport"
      onMouseDown={(e) => e.stopPropagation()}
      // shouldUpdateRow={(prevProps, nextProps) =>
      //   prevProps.value.module !== nextProps.value.module
      // }
      columns={columns}
      data={data}
    />
  );
};

const MissingTimeChargesOverview = ({ className }) => {
  // const space = useSpace();

  const { data, loading, error, refetch } = useSpaceQuery(
    `Interventions/Report`,
    [spaceMissingTimeChargeSchema],
    { cache: false }
  );

  useInterventionCommunicator(({ type, ...rest }) => {
    if (type === INTERVENTION_CREATED || type === INTERVENTION_UPDATED)
      refetch();
  });

  return (
    <>
      <DashboardTitle
        text={<FormattedMessage id={"TIME_REPORT"} />}
        className="mb-3 px-4"
        icon={faClock}
      >
        <div className="mt-1 text-black fs-14">
          <FormattedMessage id={"TIME_SUMMARY"} />
        </div>
      </DashboardTitle>

      <div className="" />
      {loading || error ? (
        <LoaderSpinner size="xs" center className="text-secondary" />
      ) : (
        <TimeChargeTable
          fetch={refetch}
          items={data}
          isLoading={loading || error}
        />
      )}
    </>
  );
};

export default MissingTimeChargesOverview;
