import React, { useMemo } from "react";
import { useClient, useCountry } from "../../Hooks/EntityHooks";
import { createOriginPopup } from "./Origin";
import {
  spaceClientSchema,
  spaceContractSchema,
  spaceProjectsSchema
} from "../../config/schema";
import SplitCol from "../../Components/SplitCol/SplitCol";
import {
  ClientStatusBadge,
  ClientStatusProfileWithIcon,
  ConnectedClientProfile
} from "../../Components/Clients/ClientProfile";
import { FormattedPhoneNumber } from "../../Components/Input/PhoneInput";
import {
  OriginContainer,
  OriginHeader,
  OriginItemGroup
} from "../../Components/Origin/Origin";
import { ConnectedProjectOrigin } from "./ProjectOrigin";
import { ConnectedContractOrigin } from "./ContractOrigin";
import { ConnectedAccountPopup } from "./AcountPopup";
import { useSpaceQuery } from "../../Helpers/IOClient";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { ClientEntityGridButtons } from "../Clients/ClientsHelper";
import { ClientLink } from "../../Helpers/HooksHelper";
import classnames from "classnames";
import { useIntl } from "react-intl";
import { usePopperResize } from "../../Components/Popper/Popper";
import {
  faEnvelope,
  faFileContract,
  faGlobe,
  faIdCardAlt,
  faMap,
  faSquareParking,
  faPhone,
  faUserTie
} from "@fortawesome/pro-solid-svg-icons";
import {
  accountPermissions,
  hasModulePermission,
  moduleTypes
} from "../../Helpers/ModulesHelper";
import Button from "../../Components/Button/Button";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import { EntitySummaryHeaderTitle } from "../../Components/EntitySummaries/EntitySummariesComponents";
import { ConnectedContactProfileStack } from "../../Components/Accounts/AccountProfileStack";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { ContractStatusTypes } from "../../Components/Contract/ContractProfile";
import { ProjectStatusTypes } from "../Projects/ProjectsStatusHelper";
import { faIdCardClip } from "@fortawesome/pro-light-svg-icons";
import { useConnectedSchemaGetter } from "../../Helpers/HelperHooks";

export const ClientOriginAnchor = React.forwardRef(
  (
    { client, children, onClick, dynamicFont, hideStatus, hideId, ...rest },
    ref
  ) => {
    const openForm = useEntitySummarySidebar();
    if (!client) return null;
    const { Id, IsDeleted, Status, Attributes } = client;
    return (
      <div
        ref={ref}
        className={classnames("link-color text-truncate", {
          "item-was-deleted": IsDeleted
        })}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <>
            <div
              className={classnames(
                "text-truncate  d-flex align-items-center",
                { "fs-12": !dynamicFont }
              )}
            >
              <div className="d-inline-flex align-items-center mr-1">
                <ClientStatusProfileWithIcon
                  value={Status || Attributes}
                  hideName={true}
                  {...rest}
                />
              </div>
              <Button
                vType="link-primary"
                className="EntityLinkClassID fs-12 text-black"
                onClick={() => {
                  openForm({
                    type: moduleTypes.clients,
                    props: {
                      id: Id
                    }
                  });
                }}
              >
                <span>{client.Name}</span>
              </Button>
              {/* <ClientLink onClick={onClick} id={Id}>
              
              </ClientLink> */}

              {/* <ClientStatusBadge
                className="d-inline-flex"
                status={client.Status}
              /> */}
            </div>
            {/* <div>{task.Id}</div> */}
            {/* {Id && <div className="fs-10">Nº: {Id}</div>} */}
          </>
        )}
      </div>
    );
  }
);
export const ConnectedClientOriginAnchor = ({ client, ...rest }) => {
  const resolvedClient = useClient(client);
  const Result = useConnectedSchemaGetter(client, spaceClientSchema);

  if (Result) return Result;
  return <ClientOriginAnchor client={resolvedClient} {...rest} />;
};

export const ClientOriginPopup = ({ client }) => {
  const intl = useIntl();
  const c = useClient(client.Id);
  usePopperResize();
  const {
    FiscalNumber,
    Id,
    Addresses,
    Manager,
    Contacts,
    Domain,
    CreationDate,
    Status,
    Email,
    CodeERP,
    Name,
    // CreationDate,
    PhoneNumber
  } = c;
  const {
    data: dataContracts
    // loading: loadingContracts,
    // error: errorContracts
  } = useSpaceQuery(
    `contracts?$orderby=Contract/Id%20desc&$filter=((Client/Id%20eq%20%27${Id}%27) and (PipelineStatus/Status ne '${ContractStatusTypes.canceled}') and (PipelineStatus/Status ne '${ContractStatusTypes.closed}'))&$top=5`,
    [spaceContractSchema]
  );

  const {
    data: dataProjects
    // loading: loadingProjects,
    // error: errorProjects
  } = useSpaceQuery(
    `projects?$orderby=Project/Id%20desc&$filter=((Client/Id%20eq%20%27${Id}%27) and (PipelineStatus/Status ne '${ProjectStatusTypes.canceled}') and (PipelineStatus/Status ne '${ProjectStatusTypes.finished}'))&$top=5`,
    [spaceProjectsSchema]
  );

  //   const projectsArr = Projects
  //     ? Projects.map(t => <ConnectedProjectOrigin key={t} project={t} />)
  //     : "";
  //   const contractsArr = Contracts
  //     ? Contracts.map(t => <ConnectedContractOrigin key={t} contract={t} />)
  //     : "";

  const accountSpace = useCurrentAccountSpace();
  const CanSeePrivateInfo = hasModulePermission(
    accountSpace.Modules.clients,
    accountPermissions.ReadContacts
  );

  const hasPhoneNumber = Boolean(PhoneNumber);
  const hasEmail = Boolean(Email);
  //   const hasContracts = Boolean(Contracts && Contracts.length > 0);
  //   const hasProjects = Boolean(Projects && Projects.length > 0);

  const finalAddress = useMemo(() => {
    if (Addresses && Addresses[0]) return Addresses[0];
    else return {};
  }, [Addresses]);

  const ConnectedCountry = useCountry(finalAddress?.Country);

  const addressString = `${finalAddress.Address1}${
    finalAddress?.Address2 ? `, ${finalAddress.Address2}` : ""
  }${finalAddress?.PostalCode ? `, ${finalAddress.PostalCode}` : ""}${
    finalAddress?.Locality ? `, ${finalAddress.Locality}` : ""
  }${ConnectedCountry ? `, ${ConnectedCountry.Name}` : ""}`;

  const linkString = `${finalAddress.Address1}${
    finalAddress?.Address2 ? `, ${finalAddress.Address2}` : ""
  }${finalAddress?.Locality ? `, ${finalAddress.Locality}` : ""}`
    .replace(/  +/g, " ")
    .replace(/,/g, "");

  return (
    <OriginContainer>
      <OriginHeader
        Dif
        className="mb-1"
        type="Client"
        date={CreationDate}
        entity="ACCOUNT"
        hideId
        Icon={
          <ConnectedClientProfile
            className="flex-0-0"
            size="md"
            justImage
            client={Id}
          />
        }
        EntityButtons={<ClientEntityGridButtons noColors entity={c} minimal />}
        id={Id}
        TitleComponent={
          <div className="d-flex align-items-center flex-1 w-100 of-x-hidden">
            <div className="flex-1 d-flex flex-column of-hidden">
              <div className="d-flex flex-1 of-hidden fw-medium ml-2 align-items-center">
                {/* <TextEllipsis length={45} breakWord text={Name} /> */}
                <ClientLink
                  className="EntityLinkClassID flex-1"
                  id={Id}
                  style={{ textDecoration: "none" }}
                >
                  <EntitySummaryHeaderTitle
                    value={{ Name }}
                    FontSize="mr-2 fw-medium of-hidden text-truncate"
                  />
                </ClientLink>
              </div>
              <div className="d-flex text-grey-light ml-2 fs-12">
                <div>{Id}</div>
              </div>
            </div>
            <ClientStatusBadge status={Status} />
          </div>
        }
      />
      {/* <div className="mx-3 mb-1 d-flex align-items-center justify-content-between">
        <div className={`fw-medium fs-12`}>
           <ClientStatusBadge status={Status} /> 
        </div>
        <div>
          <OriginDate className="fs-12" date={CreationDate} />
        </div>
      </div>  */}

      {/* <ClientProfile hideid={"true"}client={c} className="px-3 mb-2" /> */}

      {CodeERP && (
        <OriginItemGroup
          icon={faIdCardClip}
          show={Boolean(CodeERP)}
          title={intl.formatMessage({ id: "CODEERP" })}
        >
          <span className="text-black d-flex align-items-center fs-12">
            {CodeERP}
          </span>
        </OriginItemGroup>
      )}

      {!FiscalNumber && Domain && (
        <OriginItemGroup
          icon={faGlobe}
          show={Boolean(Domain)}
          title={intl.formatMessage({ id: "DOMAIN" })}
        >
          <span className="text-black fs-12">{Domain}</span>
        </OriginItemGroup>
      )}

      {Domain && FiscalNumber && (
        <SplitCol
          className="mb-1"
          left={
            <OriginItemGroup
              icon={faIdCardAlt}
              title={intl.formatMessage({ id: "NIF" })}
            >
              <span className="text-black fs-12">{FiscalNumber}</span>
            </OriginItemGroup>
          }
          right={
            <OriginItemGroup
              icon={faGlobe}
              show={Boolean(Domain)}
              title={intl.formatMessage({ id: "DOMAIN" })}
            >
              <span className="text-black fs-14">{Domain}</span>
            </OriginItemGroup>
          }
        />
      )}
      {FiscalNumber && !Domain && (
        <OriginItemGroup
          icon={faIdCardAlt}
          title={intl.formatMessage({ id: "NIF" })}
        >
          <span className="text-black fs-12">{FiscalNumber}</span>
        </OriginItemGroup>
      )}

      {Addresses && finalAddress.Address1 && (
        <OriginItemGroup
          icon={faMap}
          title={intl.formatMessage({ id: "ADDRESS" })}
        >
          <a
            href={`https://www.google.com/maps/place/${linkString}`}
            rel="noopener noreferrer"
            target="_blank"
            className="fs-12"
          >
            {addressString}
          </a>
        </OriginItemGroup>
      )}

      {hasEmail && !hasPhoneNumber && (
        <OriginItemGroup
          icon={faEnvelope}
          show={hasEmail && CanSeePrivateInfo}
          className="mb-1"
          title="Email"
        >
          <div className="text-black d-flex align-items-center">
            <a href={`mailto:${Email}`} className="fs-12">
              {Email}
            </a>
          </div>
        </OriginItemGroup>
      )}
      <SplitCol
        show={hasPhoneNumber && hasEmail}
        className="mb-1"
        left={
          <OriginItemGroup
            icon={faPhone}
            show={hasPhoneNumber && CanSeePrivateInfo}
            title={intl.formatMessage({ id: "PHONE_NUMBER" })}
          >
            <div className="text-black d-flex align-items-center">
              <FormattedPhoneNumber
                noIcon
                className="fs-12"
                number={PhoneNumber}
              />
            </div>
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faEnvelope}
            show={hasEmail && CanSeePrivateInfo}
            title="Email"
          >
            <div className="text-black d-flex align-items-center">
              <a href={`mailto:${Email}`} className="fs-12">
                {Email}
              </a>
            </div>
          </OriginItemGroup>
        }
      />
      {!Email && hasPhoneNumber && (
        <OriginItemGroup
          icon={faPhone}
          show={hasPhoneNumber && CanSeePrivateInfo}
          className="mb-1"
          title={intl.formatMessage({ id: "MOBILE_NUMBER" })}
        >
          <div className="text-black d-flex align-items-center">
            <FormattedPhoneNumber
              noIcon
              className="fs-12"
              number={PhoneNumber}
            />
          </div>
        </OriginItemGroup>
      )}

      {Array.isArray(Contacts) && Contacts.length > 0 && !Manager && (
        <OriginItemGroup
          icon={faUserTie}
          title={intl.formatMessage({ id: "CONTACTS" })}
        >
          <ConnectedContactProfileStack contacts={Contacts} />
        </OriginItemGroup>
      )}

      {Array.isArray(Contacts) && Contacts.length > 0 && Manager && (
        <SplitCol
          className="mb-1"
          left={
            Array.isArray(Contacts) &&
            Contacts.length > 0 && (
              <OriginItemGroup
                icon={faUserTie}
                title={intl.formatMessage({ id: "CONTACTS" })}
              >
                <ConnectedContactProfileStack contacts={Contacts} />
              </OriginItemGroup>
            )
          }
          right={
            Manager && (
              <OriginItemGroup
                icon={faUserTie}
                title={intl.formatMessage({ id: "MANAGER" })}
              >
                <ConnectedAccountPopup account={Manager} />
              </OriginItemGroup>
            )
          }
        />
      )}

      {(!Contacts || Contacts?.length === 0) && Manager && (
        <OriginItemGroup
          icon={faUserTie}
          title={intl.formatMessage({ id: "MANAGER" })}
        >
          <ConnectedAccountPopup account={Manager} />
        </OriginItemGroup>
      )}

      {dataContracts || dataProjects ? (
        <>
          {dataContracts && dataContracts.length > 0 && (
            <OriginItemGroup
              icon={faFileContract}
              className="mb-2"
              title={intl.formatMessage({ id: "ASSOCIATED_CONTRACTS" })}
            >
              {dataContracts.map((t) => (
                <ConnectedContractOrigin
                  className="mb-1"
                  key={t}
                  contract={t}
                />
              ))}
            </OriginItemGroup>
          )}

          {dataProjects && dataProjects.length > 0 && (
            <OriginItemGroup
              icon={faSquareParking}
              className="mb-2"
              title={intl.formatMessage({ id: "ASSOCIATED_PROJECTS" })}
            >
              {dataProjects.map((t) => (
                <ConnectedProjectOrigin className="mb-1" key={t} project={t} />
              ))}
            </OriginItemGroup>
          )}
        </>
      ) : (
        <>
          <OriginItemGroup
            icon={faFileContract}
            className="mb-2"
            title={intl.formatMessage({ id: "ASSOCIATED_CONTRACTS" })}
          >
            <LoaderSpinner size={"xs"} />
          </OriginItemGroup>
          <OriginItemGroup
            icon={faSquareParking}
            className="mb-2"
            title={intl.formatMessage({ id: "ASSOCIATED_PROJECTS" })}
          >
            <LoaderSpinner size={"xs"} />
          </OriginItemGroup>
        </>
      )}

      {/* <OriginItemGroup
        className="mb-1"
        show={hasContracts}
        title="Contratos associados"
      >
        {contractsArr}
      </OriginItemGroup>
      <OriginItemGroup
        className="mb-1"
        show={hasProjects}
        title="Projetos associados"
      >
        {projectsArr}
      </OriginItemGroup> */}

      <div className="pb-3">
        {/* <ClientFormButton id={Id} canEdit={CanEdit} className="mr-2" />
        <NoteFormButton
          alt
          formProps={{ clientId: Id }}
          className="ssi-button-primary-ghost mr-2"
        />
        <CallFormButton
          alt
          formProps={{ clientId: Id }}
          className="ssi-button-primary-ghost mr-2"
        />
        <TicketFormButton
          alt
          formProps={{ clientId: Id }}
          className="ssi-button-primary-ghost mr-2"
        >
          Converter Ticket
        </TicketFormButton>
        <TaskFormButton
          className="ssi-button-primary-ghost mr-2"
          alt
          formProps={{ clientId: Id }}
        >
          Agendar Tarefa
        </TaskFormButton>
        <InterventionFormButton alt formProps={{ clientId: Id }} /> */}
        {/* <ClientEntityButtons
          //   className="mt-3 mb-3"

          entity={c}
        /> */}
      </div>
    </OriginContainer>
  );
};

export const clientOriginOptions = {
  valueProp: "client",
  schema: spaceClientSchema
};

export const ClientOrigin = createOriginPopup(
  ClientOriginAnchor,
  ClientOriginPopup,
  clientOriginOptions
);

export default ClientOrigin;

export const ConnectedClientOrigin = ({ client, ...rest }) => {
  const c = useClient(client);

  const Result = useConnectedSchemaGetter(client, spaceClientSchema);

  if (Result) return Result;

  return <ClientOrigin client={c} {...rest} />;
};
