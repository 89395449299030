const CreateDashboardFilterLinksHelper = (DataReports: any, account: any) => {
  let Type = DataReports ? DataReports[4] : "Account";
	if(!Type) Type = "Account";
	// if(DataReports && DataReports[5]) return ``;
	
  switch (Type) {
    case "Account":
      return ` and Assigned/Id eq '${
        DataReports[0] === "Tasks" ? DataReports[2].Id : account
      }'`;
    case "organization":
      return ` and Company/Id eq '${DataReports[2].Id}'`;
    case "Team":
      return ` and Team/Id eq '${DataReports[2].Id}'`;
    case "Department":
      return ` and Department/Id eq '${DataReports[2].Id}'`;
    default:
      return ``;
  }
};

export default CreateDashboardFilterLinksHelper;
