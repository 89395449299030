import React from "react";
import { createFormRequestHook, useFormState } from "../../../Components/Forms";
import {
  spaceEmailReceptionSchema,
  spaceOrganizationSchema
} from "../../../config/schema";
import { useMemo } from "react";
import { useSpaceQuery } from "../../../Helpers/IOClient";
import { useCurrentAccount } from "../../../Contexts/UserContext";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import {
  isOrganzationRequestValid,
  OrganizationRequestSideError
} from "../../../Components/Organizations/OrganizationHelper";
import {
  defaultEmailReceptionForm,
  InboundEmailType,
  formatEmailReceptionForm,
  ValidateEmailReceptionForm,
  convertEmailReceptionEntityToForm
} from "./EmailReceptionHelper";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../../Components/Forms/SideMenuForm";
import {
  BaseSidebarContent,
  BaseSidebarContainer,
  BaseSidebarHeader,
  useSidebarSpacePost,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import { FormattedMessage, useIntl } from "react-intl";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import EmailReceptionTypeDropdown from "./EmailReceptionTypeDropdown";
import {
  OrganizationDropdown,
  DepartmentDropdown,
  TeamDropdown,
  UserDropdown,
  DepartmentTeamDropdown,
  TaskTargetDropdown,
  TicketTargetDropdown,
  ClientDropdown,
  TicketTagDropdown,
  DealTagDropdown,
  RemoveDefaultActiveButtonProvider
} from "../../AdvancedMultiInputs";
import {
  TicketPipelineDropdown,
  TicketStatusPipelineDropdown,
  TaskPipelineDropdown,
  TaskStatusPipelineDropdown,
  DealStatusPipelineDropdown,
  DealPipelineDropdown
} from "../../AdvancedMultiInputs/PipelineDropdowns";
import { useToast } from "../../../Components/Toast/ToastProvider";
import EmailReceptionInput from "./EmailReceptionInput";
import { useEmailReception, useOrganization } from "../../../Hooks/EntityHooks";
import { EmailDomainInput } from "./EmailDomainInput";
import { DealStatusTypes } from "../../Deals/DealsHelper";
import { TicketsStatusTypes } from "../../Tickets/TicketsHelper";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";
import { updateManagerFollowers } from "../../../Components/EntityFollow/EntityFollowHelper";
import { useManagerVerifier } from "../../Form/FormHelper";
import CreatedBy from "../../../Components/CreatedByComponent/CreatedBy";
import Switch from "../../../Components/Switch/Switch";
import { useSpace } from "../../../Contexts/SpaceContext";

const useEmailReceptionRequest = createFormRequestHook({
  schema: spaceEmailReceptionSchema,

  convertEntityToForm: convertEmailReceptionEntityToForm
});

const EmailReceptionForm = (props) => {
  const {
    key,
    loading,
    error,
    convertedForm,
    isChild
    //   childProps,
  } = useEmailReceptionRequest({ ...props, options: { cache: false } });

  const { id, defaultForm, onSuccess, className, refetchRef } = props;

  const resolvedForm = useMemo(() => {
    let form = {
      ...defaultForm,
      ...convertedForm
    };

    return form;
  }, [convertedForm, defaultForm]);

  const user = useCurrentAccount();

  const orgState = useSpaceQuery(
    `query/organizations?$filter=((Account/Id eq '${user.Id}') or (Manager/Id eq '${user.Id}'))`,
    [spaceOrganizationSchema]
  );

  const { loading: loadingOrganizations, data: organizations } = orgState;

  // if (loading || error || loadingOrganizations)
  //   return <LoaderSpinner center size="sm" className="text-secondary" />;

  // if (!isOrganzationRequestValid(orgState))
  //   return <OrganizationRequestSideError requestState={orgState} />;

  const entity = useEmailReception(id);

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id="INBOX_EMAIL" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        {loading || error || loadingOrganizations ? (
          <LoaderSpinner center size="sm" className="text-secondary" />
        ) : !isOrganzationRequestValid(orgState) ? (
          <OrganizationRequestSideError requestState={orgState} />
        ) : (
          <EmailReceptionFormBase
            canDelete={entity?.CanDelete}
            refetchRef={refetchRef}
            className={className}
            isChild={isChild}
            onSuccess={onSuccess}
            id={id}
            key={key}
            convertedForm={resolvedForm}
            organizations={organizations}
          />
        )}
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

const TicketInputs = ({
  form,
  onPipelineChange,
  handleChange,
  handleFormChanges
}) => {
  const {
    Pipeline,
    TicketTarget,
    DepartmentTeam,
    Assigned,
    Followers,
    Company
  } = form;

  const handleManagerChange = ({ target: { value } }) => {
    handleChange({
      Assigned: value,
      Followers: updateManagerFollowers(Followers, Assigned, value, [])
    });
  };
  const validatingManager = useManagerVerifier(Assigned, DepartmentTeam, {
    onUpdateManager: (manager) => {
      handleChange({
        Assigned: manager,
        Followers: updateManagerFollowers(Followers, Assigned, manager, [])
      });
    }
  });

  return (
    <>
      {/* <FormInput
        name="Departments"
        text={<FormattedMessage id={"DEPARTMENTS"} />}
        inputType={DepartmentDropdown}
        multiple
        className="mb-3"
      />

      <FormInput
        name="Teams"
        text={<FormattedMessage id={"TEAMS"} />}
        inputType={TeamDropdown}
        multiple
        className="mb-3"
      /> */}

      <FormInput
        name="TicketTarget"
        textId="ASSOCIATE_TO"
        inputType={TicketTargetDropdown}
        allClients={false}
        OrganizationSpaceId={Company}
        value={TicketTarget}
        onChange={handleFormChanges}
        className="mb-3"
      />

      <FormInput
        name="DepartmentTeam"
        text={<FormattedMessage id={"DEPARTMENT_TEAM"} />}
        inputType={DepartmentTeamDropdown}
        className="mb-3"
      />
      <RemoveDefaultActiveButtonProvider>
        <FormInput
          className="mb-3"
          name="Assigned"
          disabled={validatingManager}
          textId="ASSIGNED_TO"
          disabledDefault
          inputType={UserDropdown}
          DepartmentTeam={DepartmentTeam}
          onChange={handleManagerChange}
        />
      </RemoveDefaultActiveButtonProvider>

      <FormInput
        name="Pipeline"
        text={<FormattedMessage id={"PIPELINE"} />}
        onChange={onPipelineChange}
        inputType={TicketPipelineDropdown}
        className="mb-3"
      />

      <FormInput
        name="PipelineStatus"
        text={<FormattedMessage id={"STATUS"} />}
        inputType={TicketStatusPipelineDropdown}
        pipelineId={Pipeline ? Pipeline : null}
        filterBlock
        Status={TicketsStatusTypes.open}
        className="mb-3"
      />

      <FormInput
        className="mb-3"
        name="Tags"
        textId="CASE_TAGS"
        multiple
        pipelineId={Pipeline}
        inputType={TicketTagDropdown}
      />
    </>
  );
};

const TaskInputs = ({ form, onPipelineChange, handleFormChanges }) => {
  const { Pipeline, TaskTarget } = form;

  return (
    <>
      <FormInput
        name={"TaskTarget"}
        textId="ASSOCIATE_TO"
        className="mb-3"
        inputType={TaskTargetDropdown}
        onChange={handleFormChanges}
        value={TaskTarget}
        allClients={false}
      />

      <FormInput
        name="Pipeline"
        text={<FormattedMessage id={"PIPELINE"} />}
        onChange={onPipelineChange}
        inputType={TaskPipelineDropdown}
        className="mb-3"
      />

      <FormInput
        name="PipelineStatus"
        text={<FormattedMessage id={"STATUS"} />}
        inputType={TaskStatusPipelineDropdown}
        pipelineId={Pipeline ? Pipeline : null}
        className="mb-3"
      />
    </>
  );
};

const DealInputs = ({ form, onPipelineChange, handleFormChanges }) => {
  const { Pipeline, Client } = form;

  return (
    <>
      <FormInput
        className="mb-3"
        textId="ACCOUNT"
        name="Client"
        value={Client}
        allClients={false}
        onChange={handleFormChanges}
        inputType={ClientDropdown}
      />

      <FormInput
        name="Departments"
        text={<FormattedMessage id={"DEPARTMENTS"} />}
        inputType={DepartmentDropdown}
        multiple
        className="mb-3"
      />

      <FormInput
        name="Teams"
        text={<FormattedMessage id={"TEAMS"} />}
        inputType={TeamDropdown}
        multiple
        className="mb-3"
      />

      <FormInput
        name="DealManager"
        text={<FormattedMessage id={"COMERCIAL"} />}
        inputType={UserDropdown}
        className="mb-3"
      />

      <FormInput
        className="mb-3"
        name="Assigned"
        textId="ASSIGNED_TO"
        disabledDefault
        inputType={UserDropdown}
      />

      <FormInput
        name="Pipeline"
        text={<FormattedMessage id={"PIPELINE"} />}
        onChange={onPipelineChange}
        inputType={DealPipelineDropdown}
        className="mb-3"
      />

      <FormInput
        name="PipelineStatus"
        text={<FormattedMessage id={"STATUS"} />}
        inputType={DealStatusPipelineDropdown}
        pipelineId={Pipeline ? Pipeline : null}
        filterBlock
        Status={DealStatusTypes.open}
        className="mb-3"
      />

      <FormInput
        className="mb-3"
        name="Tags"
        textId="DEAL_TAGS"
        multiple
        pipelineId={Pipeline}
        inputType={DealTagDropdown}
      />
    </>
  );
};

const DynamicInputs = ({ Type, form, handleChange }) => {
  const handlePipelineChange = (e) => {
    const { value } = e.target;
    // console.log(e);

    handleChange({
      Pipeline: value,
      PipelineStatus: null
    });
  };

  const handleFormChanges = (e) => {
    const { value, name } = e.target;
    // console.log(e);

    handleChange({
      [name]: value
    });
  };

  switch (Type) {
    case InboundEmailType.ticket:
      return (
        <TicketInputs
          handleChange={handleChange}
          handleFormChanges={handleFormChanges}
          form={form}
          onPipelineChange={handlePipelineChange}
        />
      );

    case InboundEmailType.task:
      return (
        <TaskInputs
          handleFormChanges={handleFormChanges}
          form={form}
          onPipelineChange={handlePipelineChange}
        />
      );

    case InboundEmailType.deal:
      return <DealInputs form={form} onPipelineChange={handlePipelineChange} />;

    default:
      return null;
  }
};

const EmailReceptionFormBase = ({
  id,
  convertedForm,
  refetchRef,
  onSuccess,
  canDelete
}) => {
  const user = useCurrentAccount();

  const formState = useFormState(() => {
    const defaultForm = defaultEmailReceptionForm({ id, user });

    return {
      ...defaultForm,
      ...convertedForm
    };
  });

  const { form, handleChange } = formState;

  const {
    Type,
    Company,
    EmailNameDomain,
    Domain,
    EmailName,
    Manager,
    Creator,
    Enabled,
    CreationDate,
    Followers
  } = form;

  const createToast = useToast();

  const handleServiceChange = (e) => {
    const { value } = e.target;
    handleChange({
      Type: value,
      Pipeline: null,
      PipelineStatus: null,
      Tags: []
    });
  };
  const handleTranslatedError = useHandleError(spaceEmailReceptionSchema);

  const [post, { loading }] = useSidebarSpacePost(
    id ? `InboundEmails/${id}` : "InboundEmails",
    spaceEmailReceptionSchema,
    {
      refetchRef: refetchRef,
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        if (!id) defineChildProps({ InboundEmail: newId });
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "INBOX_EMAIL" })}  ${EmailName}`,
          description: `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const intl = useIntl();

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `InboundEmails/${id}`,
    spaceEmailReceptionSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "INBOX_EMAIL" })} ${EmailName}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  const handleDomainNameChange = (e) => {
    handleChange((vals) => {
      const newVals = {
        ...vals
      };
      const {
        target: { value: v }
      } = e;
      if (newVals.EmailNameDomain === newVals.EmailName) {
        newVals.EmailName = v;
      }
      newVals.EmailNameDomain = v;
      return newVals;
    });
  };

  const handleDomainChange = (v) => {
    handleChange({
      Domain: v
    });
  };

  const handleCompanyChange = (e) => {
    handleChange({
      Company: e.target.value,
      Domain: null,
      EmailNameDomain: ""
    });
  };

  const handleSubmit = () => {
    post(formatEmailReceptionForm(form));
  };

  const handleManagerChange = ({ target: { value } }) => {
    handleChange({
      Manager: value,
      Followers: updateManagerFollowers(Followers, Manager, value, [])
    });
  };

  const handleCheckChanges = ({ target: { name, checked } }) => {
    handleChange((oldF) => {
      return {
        ...oldF,
        [name]: checked
      };
    });
  };

  const space = useSpace();
  const CompanyData = useOrganization(Company);
  const DomainName = useMemo(() => {
    return CompanyData && CompanyData.DomainName;
  }, [CompanyData]);

  return (
    <SideForm
      canEdit
      formState={formState}
      onSubmit={handleSubmit}
      validate={ValidateEmailReceptionForm}
    >
      <SideFormContent className="p-3">
        <FormInput
          name="Company"
          size="xsm"
          textId="COMPANY"
          inputType={OrganizationDropdown}
          onChange={handleCompanyChange}
          className="mb-3"
        />

        <FormInput
          name="EmailNameDomain"
          disabled={!EmailNameDomain}
          subDomain
          inputType={EmailDomainInput}
          Company={Company}
          onChange={handleDomainNameChange}
          domainValue={Domain}
          onDomainChange={handleDomainChange}
          textId="OPTIONAL_INBOX_EMAIL"
          className="mb-3"
        />

        <FormInput
          name="EmailName"
          disabled={!DomainName}
          inputType={EmailReceptionInput}
          CompanyEmail={DomainName}
          textId="SYTEM_AIRDESK_EMAIL"
          className="mb-3"
        />

        <FormInput
          name="Enabled"
          onChange={handleCheckChanges}
          textId={"ACTIVE"}
          inputType={Switch}
          className="mb-3"
        />
        {space.Id === 1 && (
          <FormInput
            name="SendDisabledEmailMessage"
            disabled={Enabled}
            onChange={handleCheckChanges}
            textId={"SEND_DISABLED_EMAIL_MESSAGE"}
            inputType={Switch}
            className="mb-3"
          />
        )}
        <FormInput
          name="Manager"
          size="xsm"
          textId="MANAGER"
          onChange={handleManagerChange}
          inputType={UserDropdown}
          className="mb-3"
        />
        <FormInput
          name="Followers"
          textId="FOLLOWERS"
          inputType={UserDropdown}
          multiple
          className="mb-3"
        />

        <FormInput
          name="Type"
          textId="SERVICE"
          inputType={EmailReceptionTypeDropdown}
          onChange={handleServiceChange}
          className="mb-3"
        />
        <DynamicInputs Type={Type} form={form} handleChange={handleChange} />
        <CreatedBy Creator={Creator} CreationDate={CreationDate} />
      </SideFormContent>
      <SideFormFooter
        canDelete={canDelete}
        deleteLoading={deleteLoading}
        handleDelete={remove}
        isLoading={loading}
      />
    </SideForm>
  );
};

export default EmailReceptionForm;
