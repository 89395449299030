import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";
import { PageTypeProvider } from "../../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimePageEnum } from "../../RealTime/RealTimePages";

const AutomationOverviewComp = React.lazy(() =>
  import(
    /* webpackChunkName: "automation-overview" */ "./AutomationOverviewComp"
  )
);

const AutomationOverview = () => {
  return (
    <SuspenseErrorBoundary>
      <PageTypeProvider pageType={RealTimePageEnum.Automation}>
        <AutomationOverviewComp />
      </PageTypeProvider>
    </SuspenseErrorBoundary>
  );
};

export default AutomationOverview;
