import React from "react";
import AccountDropdownInput from "../Accounts/AccountDropdownInput";
import { useDepartment } from "../../Hooks/EntityHooks";

const DepartmentDropdownInput = ({ department, ...rest }) => {
  return(
  <AccountDropdownInput account={department} {...rest} />
);}

export default DepartmentDropdownInput;

export const ConnectedDepartmentDropdownInput = ({department, ...rest}) => {
  const d = useDepartment(department)
  return <DepartmentDropdownInput ListItem department={d} {...rest} />
}