import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../../Components/Button/Button";
import LoadableButton from "../../../Components/Button/LoadableButton";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";
// import DockTyper from "../../../Components/DocTyper/DocTyperSimple";
import KeyedDropdown from "../../../Components/Dropdown/KeyedDropdown";
import { useFormState } from "../../../Components/Forms";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../../Components/Forms/SideMenuForm";
import HTMLInput from "../../../Components/HTMLInput/HTMLInput";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import {
  BaseSidebarContainer,
  BaseSidebarContent,
  BaseSidebarHeader,
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../../Components/Sidebar/SidebarV2Helper";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { spaceApiTokensSchema } from "../../../config/schema";
import { getEntity, useSpaceQuery } from "../../../Helpers/IOClient";
import { OrganizationDropdown, UserDropdown } from "../../AdvancedMultiInputs";
import { DomainKeyPre } from "../Domains/DomainKeys/DomainKeysClipboard";
import { ApiKeysState } from "./ApiKeysGrid";

const options = [true, false];

const ApiKeysStateValueComponent = ({ item }) => {
  return <ApiKeysState noBackground state={item} />;
  // if (item === true)
  //   return (
  //     <div>
  //       <FormattedMessage id="ACTIVE" />
  //     </div>
  //   );
  // else
  //   return (
  //     <div>
  //       <FormattedMessage id="INACTIVE" />
  //     </div>
  //   );
};

const StateDropdown = (props) => {
  return (
    <KeyedDropdown
      options={options}
      valueComponent={ApiKeysStateValueComponent}
      closeOnSelect
      {...props}
    />
  );
};

const validateForm = ({ Organization, Account, Description }, addError) => {
  if (!Organization) addError("Organization");
  if (!Account) addError("Account");
  if (!Description) addError("Description");
};

const useApiKeysFormState = (id) => {
  return useFormState(() => {
    const entity = id ? getEntity(spaceApiTokensSchema, id) : undefined;
    return {
      Organization: null,
      Account: null,
      State: true,
      Description: "",
      ...entity
    };
  });
};

const InnerForm = ({ id, refetchRef }) => {
  const formState = useApiKeysFormState(id);
  const isEditing = Boolean(id);
  const [post, { loading }] = useSidebarSpacePost(
    id ? `ApiKey/${id}` : `ApiKey`,
    spaceApiTokensSchema,
    { refetchRef }
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const { form, handleChange } = formState;
  const { Organization, Token } = form;

  const handleSubmit = () => {
    const { Account, Organization, State, Description } = form;
    post({
      AccountId: Account,
      OrganizationId: Organization,
      State,
      Description
    });
  };

  const handleOrgChange = (e) => {
    handleChange({
      Account: null,
      Organization: e.target.value
    });
  };

  return (
    <SideForm
      className="flex-1 of-y-auto d-flex flex-column"
      // canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      <SideFormContent noPadding className="p-3">
        <FormInput
          disabled={isEditing}
          name="Organization"
          textId="COMPANY"
          className="mb-3"
          onChange={handleOrgChange}
          inputType={OrganizationDropdown}
          closeOnSelect
        />
        <FormInput
          disabled={isEditing}
          name="Account"
          textId="USER"
          className="mb-3"
          Company={Organization}
          inputType={UserDropdown}
          closeOnSelect
        />
        <FormInput
          name="State"
          textId="STATUS"
          className="mb-3"
          inputType={StateDropdown}
          closeOnSelect
        />
        <FormInput
          name="Description"
          textId="DESCRIPTION"
          className="mb-3"
          inputType={HTMLInput}
          closeOnSelect
          multiple
        />

        {Token && (
          <FormInput
            wrap
            name="Token"
            textId="API_KEY"
            inputType={DomainKeyPre}
          />
        )}
        {/* {Token && <DomainKeyPre value={Token} />} */}
      </SideFormContent>

      <SideFormFooter
        isLoading={loading}
        subComponent={
          isEditing ? (
            <div className="w-100 justify-content-between d-flex">
              <Button
                vType="link-danger fw-bold"
                className="mr-2"
                onClick={() => setIsDeleting(true)}
              >
                <span style={{ fontWeight: 500 }}>
                  <FormattedMessage id="DELETE" />
                </span>
              </Button>
            </div>
          ) : null
        }
      >
        {isDeleting && (
          <RemovalFooter
            endpoint={`ApiKey/${id}`}
            refetchRef={refetchRef}
            onCancel={() => setIsDeleting(false)}
          />
        )}
      </SideFormFooter>
    </SideForm>
  );
};

const RemovalFooter = ({ endpoint, refetchRef, onCancel }) => {
  const createToast = useToast();
  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceApiTokensSchema);
  const [deleteUser, { loading }] = useSidebarSpaceDelete(
    endpoint,
    spaceApiTokensSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "API_KEY" })}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  return (
    <div className="details-form-footer d-flex justify-content-between align-items-center shadow-top p-3">
      <div className="text-black">
        <FormattedMessage id={"DELETE_API_KEY_QUESTION"} />
      </div>

      <div className="d-flex align-items-center">
        <Button
          type="button"
          vType="outline-danger"
          className="mr-3"
          onClick={onCancel}
        >
          <FormattedMessage id={"BACK"} />
        </Button>

        <LoadableButton
          type="button"
          vType="danger"
          isLoading={loading}
          onClick={() => {
            deleteUser();
          }}
        >
          <FormattedMessage id={"DELETE"} />
        </LoadableButton>
      </div>
    </div>
  );
};

const EditForm = ({ id, ...rest }) => {
  const { data, loading, error } = useSpaceQuery(
    `ApiKey/${id}`,
    spaceApiTokensSchema
  );

  return loading || error ? (
    <LoaderSpinner size="sm text-primary" center />
  ) : (
    <InnerForm id={data} {...rest} />
  );
};

const ApiKeysForm = ({ id, ...rest }) => {
  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        {id ? (
          <FormattedMessage id="EDIT_API_KEY" values={{ id }} />
        ) : (
          <FormattedMessage id="ADD_API_KEY" />
        )}
      </BaseSidebarHeader>
      <BaseSidebarContent>
        {id ? <EditForm id={id} {...rest} /> : <InnerForm {...rest} />}
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export default ApiKeysForm;
