import React, {
  useMemo,
  useContext,
  useState,
  useReducer,
  useRef
} from "react";
import Button from "../../Components/Button/Button";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faEnvelope,
  faCommentAltDots,
  faChevronRight,
  faCommentsAltDollar,
  faPhone,
  faSquareParking,
  faTicketSimple,
  faListCheck,
  faUserClock,
  faSuitcase,
  faHandshake,
  faFileContract,
  faCalendarExclamation,
  faMessage,
  faMessages
} from "@fortawesome/pro-light-svg-icons";
import { useIntl } from "react-intl";
import { useServerAwareState } from "../../Components/CGrid/ServerGrid";
import { ResetSelectContext } from "./ReceptionComp";
import "./Reception.css";

import { useUnreadEntities } from "../../Components/UnreadEntities/UnreadEntities";
import {
  ConversationTypeEnumFlags,
  EntityTypeNoteEnumFlags
} from "./ReceptionHelpers";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { canUserReadModule, moduleTypes } from "../../Helpers/ModulesHelper";
import { useSpace } from "../../Contexts/SpaceContext";

const useAccordionState = (open) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const isAnimatingRef = useRef(open);

  const prevOpenRef = useRef(open);
  const timeoutRef = useRef();

  if (prevOpenRef.current !== open) {
    prevOpenRef.current = open;
    clearTimeout(timeoutRef.current);
    if (!open) {
      timeoutRef.current = setTimeout(() => {
        isAnimatingRef.current = false;
        forceUpdate();
      }, 300);
    } else {
      isAnimatingRef.current = true;
    }
  }

  return isAnimatingRef.current;
};

const Accordion = ({ ...props }) => {
  const {
    open,
    onToggle,
    className,
    TranslatedText,
    children,
    Icon,
    Unreads,
    onClick,
    Selected,
    NavStatus
  } = props;
  const isShowing = useAccordionState(open);
  const intl = useIntl();
  const UnreadComponent = useMemo(() => {
    if (Unreads > 0) {
      return (
        <div
          title={intl.formatMessage({ id: "NOT_READ" })}
          className="ar-badge bg-primary"
        >
          {Unreads}
        </div>
      );
    } else return null;
  }, [Unreads, intl]);

  return (
    <div
      className={classnames(
        "ar-accordion disable-selection p-0 mb-1",
        className,
        {
          active: open
        }
      )}
    >
      <div
        style={{ height: 40 }}
        className="SidebarMenu-link d-flex justify-content-between pr-0 w-100 mb-1 d-inline-flex align-items-center title"
      >
        <div
          onClick={onToggle}
          className="ar-accordion-toggleContainer h-100 mr-2 ar-accordion-icon text-primary"
        >
          <FontAwesomeIcon size="sm" icon={faChevronRight} />
        </div>
        <Button
          className={classnames("NavBarItemFilter p-0 ", className, {
            selected: Selected
          })}
          vType="link"
          onClick={onClick}
        >
          <div className="d-flex align-items-center text-truncate of-hidden px-2">
            <FontAwesomeIcon icon={Icon} className="mr-2" />
            {NavStatus && (
              <div className="d-flex w-100 align-items-center text-truncate justify-content-between">
                <div className="mr-2  text-truncate of-hidden white-space-pre">
                  {TranslatedText}
                </div>
                <div>{UnreadComponent}</div>
              </div>
            )}
          </div>
        </Button>
      </div>
      {isShowing && (
        <div
          className={classnames("ar-accordion-content", {
            active: open
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const NavBarAccordion = ({
  Icon,
  children,
  TranslatedText,
  HandleClick,
  NavStatus,
  className,
  disabledControl,
  GroupIndex,
  ChildrenClick,
  Selected,
  Unreads,
  UnreadOptions,
  Index,
  Options
}) => {
  const { params } = useServerAwareState();

  // const UnreadComponent = useMemo(() => {
  //   if (Unreads > 0) {
  //     return <div className="ar-badge bg-primary">{Unreads}</div>;
  //   } else return null;
  // }, [Unreads]);

  const onClick = (e) => {
    if (!e.ctrlKey) {
      HandleClick(Index, GroupIndex);
      return;
    } else if (!disabledControl) {
      const { conversationTypes, entityNoteType } = params;
      let parsedConversationTypes = parseInt(conversationTypes);
      let parsedTypeEnum = parseInt(entityNoteType);
      if (Selected) {
        const TypeEnumResult = parsedTypeEnum - GroupIndex;
        if (TypeEnumResult > 0) {
          HandleClick(parsedConversationTypes, TypeEnumResult);
        } else {
          HandleClick(
            parsedConversationTypes - ConversationTypeEnumFlags.EntityNotes,
            TypeEnumResult
          );
        }
        return;
      }
      for (const obj of Options) {
        if (parsedTypeEnum & obj.Index) {
          parsedTypeEnum -= obj.Index;
        }
      }
      if (ConversationTypeEnumFlags.EntityNotes & parsedConversationTypes) {
        HandleClick(parsedConversationTypes, parsedTypeEnum + GroupIndex);
      } else {
        HandleClick(
          parsedConversationTypes + ConversationTypeEnumFlags.EntityNotes,
          parsedTypeEnum + GroupIndex
        );
      }
    } else {
      HandleClick(Index, GroupIndex);
    }
  };
  const intl = useIntl();
  const currentAccountSpace = useCurrentAccountSpace();

  const resolvedOptions = useMemo(() => {
    return Options.filter((e) => {
      return canUserReadModule(currentAccountSpace, e.moduleType);
    });
  }, [Options, currentAccountSpace]);

  const [isOpen, setIsOpen] = useState(() => {
    const { conversationTypes } = params;
    const parsedType = parseInt(conversationTypes);
    if (parsedType & Index) {
      return true;
    } else {
      return false;
    }
  });

  if (resolvedOptions.length === 0) return null;

  return (
    <Accordion
      className="SidebarMenu-accordion"
      TranslatedText={TranslatedText}
      open={isOpen}
      Icon={Icon}
      onClick={onClick}
      Index={Index}
      Unreads={Unreads}
      Selected={Selected}
      NavStatus={NavStatus}
      onToggle={() => setIsOpen((o) => !o)}
    >
      {children
        ? children
        : resolvedOptions &&
          resolvedOptions.map((v, i) => {
            return (
              <NavBarAccordionItem
                key={i}
                GroupIndex={GroupIndex}
                Icon={v.Icon}
                NavStatus={NavStatus}
                DisabledSelect={Selected}
                HandleClick={ChildrenClick}
                Unreads={UnreadOptions[v.UnreadName]}
                TranslatedText={intl.formatMessage({ id: v.Name })}
                Index={v.Index}
              />
            );
          })}
    </Accordion>
  );
};

const NavBarAccordionItem = ({
  Icon,
  TranslatedText,
  HandleClick,
  DisabledSelect,
  GroupIndex,
  NavStatus,
  className,
  Unreads,
  Index
}) => {
  const { params } = useServerAwareState();
  const intl = useIntl();
  const { entityNoteType } = params;
  const parsedEntityNoteType = parseInt(entityNoteType);
  const UnreadComponent = useMemo(() => {
    if (Unreads > 0) {
      return (
        <div
          title={intl.formatMessage({ id: "NOT_READ" })}
          className="ar-badge bg-primary"
        >
          {Unreads}
        </div>
      );
    } else return null;
  }, [Unreads, intl]);

  const Selected = DisabledSelect
    ? false
    : parsedEntityNoteType & Index &&
      parsedEntityNoteType !== EntityTypeNoteEnumFlags.All &&
      parsedEntityNoteType !== GroupIndex;

  const onClick = (e) => {
    if (
      e.ctrlKey &&
      Index !== EntityTypeNoteEnumFlags.All &&
      Index !== GroupIndex
    ) {
      const { entityNoteType } = params;
      const parsedType = parseInt(entityNoteType);
      if (parsedType & Index) {
        HandleClick(parsedType - Index);
      } else {
        HandleClick(parsedType + Index);
      }
    } else {
      HandleClick(Index);
    }
  };

  return (
    <div className="d-flex">
      <div className="ar-accordion-toggleContainer mr-2"></div>
      <Button
        className={classnames("NavBarItemFilter p-0 fs-12", className, {
          selected: Selected
        })}
        vType="link"
        onClick={onClick}
      >
        <div className="d-flex align-items-center text-truncate of-hidden px-2">
          <FontAwesomeIcon icon={Icon} className="mr-2" />
          {NavStatus && (
            <div className="d-flex w-100 align-items-center text-truncate justify-content-between">
              <div className="mr-2 text-truncate of-hidden white-space-pre">
                {TranslatedText}
              </div>
              <div>{UnreadComponent}</div>
            </div>
          )}
        </div>
      </Button>
    </div>
  );
};

const NavBarItem = ({
  Icon,
  TranslatedText,
  HandleClick,
  NavStatus,
  disabledControl,
  className,
  Selected,
  Unreads,
  Index
}) => {
  const { params } = useServerAwareState();
  const intl = useIntl();

  const UnreadComponent = useMemo(() => {
    if (Unreads > 0) {
      return (
        <div
          title={intl.formatMessage({ id: "NOT_READ" })}
          className="ar-badge bg-primary"
        >
          {Unreads}
        </div>
      );
    } else return null;
  }, [Unreads, intl]);

  const onClick = (e) => {
    if (
      !disabledControl &&
      e.ctrlKey &&
      Index !== ConversationTypeEnumFlags.All
    ) {
      const { conversationTypes, entityNoteType } = params;
      const parsedType = parseInt(conversationTypes);
      const parsedNoteType = parseInt(entityNoteType);
      if (parsedType & Index) {
        HandleClick(parsedType - Index, parsedNoteType);
      } else {
        HandleClick(parsedType + Index, parsedNoteType);
      }
    } else {
      HandleClick(Index, 0);
    }
  };

  return (
    <div className="d-flex">
      <div className="ar-accordion-toggleContainer mr-2"></div>
      <Button
        className={classnames("NavBarItemFilter p-0", className, {
          selected: Selected
        })}
        vType="link"
        onClick={onClick}
      >
        <div className="d-flex align-items-center w-100 text-truncate of-hidden px-2">
          <FontAwesomeIcon icon={Icon} className="mr-2" />
          {NavStatus && (
            <div className="d-flex w-100 align-items-center text-truncate justify-content-between">
              <div className="d-flex flex-1 justify-content-start mr-2 of-hidden text-truncate white-space-pre">
                {TranslatedText}
              </div>
              <div>{UnreadComponent}</div>
            </div>
          )}
        </div>
      </Button>
    </div>
  );
};

export const ServicesObjects = [
  {
    Name: "CALL",
    Index: EntityTypeNoteEnumFlags.Call,
    Icon: faPhone,
    UnreadName: "Calls",
    moduleType: moduleTypes.calls
  },
  {
    Name: "PROJECT",
    Index: EntityTypeNoteEnumFlags.Project,
    Icon: faSquareParking,
    UnreadName: "Projects",
    moduleType: moduleTypes.projects
  },
  {
    Name: "CASE",
    Index: EntityTypeNoteEnumFlags.Ticket,
    Icon: faTicketSimple,
    UnreadName: "Cases",
    moduleType: moduleTypes.tickets
  },
  {
    Name: "TASK",
    Index: EntityTypeNoteEnumFlags.Task,
    Icon: faListCheck,
    moduleType: moduleTypes.tasks,
    UnreadName: "Tasks"
  },
  {
    Name: "TIME",
    Index: EntityTypeNoteEnumFlags.Time,
    Icon: faUserClock,
    UnreadName: "Time",
    moduleType: moduleTypes.interventions
  }
];

const ComercialObjects = [
  {
    Name: "ACCOUNT",
    Index: EntityTypeNoteEnumFlags.Client,
    Icon: faSuitcase,
    UnreadName: "Clients",
    moduleType: moduleTypes.clients
  },
  {
    Name: "DEAL",
    Index: EntityTypeNoteEnumFlags.Deal,
    Icon: faHandshake,
    UnreadName: "Deals",
    moduleType: moduleTypes.deals
  },
  {
    Name: "CONTRACT",
    Index: EntityTypeNoteEnumFlags.Contract,
    Icon: faFileContract,
    moduleType: moduleTypes.contracts,
    UnreadName: "Contracts"
  },
  {
    Name: "SUBSCRIPTION",
    Index: EntityTypeNoteEnumFlags.Subscription,
    Icon: faCalendarExclamation,
    UnreadName: "Subscriptions",
    moduleType: moduleTypes.subscriptions
  }
];

const useChildrenSelectCheck = (entityNoteType, ServicesObjects, All) => {
  const parsedTypeEnum = parseInt(entityNoteType);
  return (parsedTypeEnum & All) === All;
};

export const ReceptionNavFilters = (onChange = () => {}, status) => {
  const { onParamsChange, params } = useServerAwareState();
  const ResetState = useContext(ResetSelectContext);
  const { conversationTypes, entityNoteType } = params;
  const CurrentSelected = parseInt(conversationTypes);
  const ChildrenSelected = parseInt(entityNoteType);

  const onCurrentAllSelectedChange = (e) => {
    if (EntityTypeNoteEnumFlags.All !== ChildrenSelected) {
      ResetState();
      onParamsChange((params) => {
        const { additionalParams } = params;
        return {
          ...params,
          additionalParams: {
            ...additionalParams,
            conversationTypes: e,
            entityNoteType: EntityTypeNoteEnumFlags.All
          }
        };
      });
    }
  };

  const onCurrentSelectedChange = (e, i) => {
    if (parseInt(conversationTypes) !== e) {
      ResetState();
      onParamsChange((params) => {
        const { additionalParams } = params;
        return {
          ...params,
          additionalParams: {
            ...additionalParams,
            conversationTypes: e,
            entityNoteType: i
          }
        };
      });
    }
  };

  const onEntityCurrentSelectedChange = (e, t) => {
    ResetState();
    onParamsChange((params) => {
      const { additionalParams } = params;
      return {
        ...params,
        additionalParams: {
          ...additionalParams,
          conversationTypes: e,
          entityNoteType: t
        }
      };
    });
  };
  const onEntityServicesCurrentSelectedChange = (e) => {
    if (parseInt(entityNoteType) !== e) {
      ResetState();
      onParamsChange((params) => {
        const { additionalParams } = params;
        return {
          ...params,
          additionalParams: {
            ...additionalParams,
            conversationTypes: ConversationTypeEnumFlags.EntityNotes,
            entityNoteType: e
          }
        };
      });
    }
  };

  const AllMessages = useMemo(() => {
    let allSum = 0;
    let alls = [...ServicesObjects, ...ComercialObjects];
    for (const e of alls) {
      allSum += e.Index;
    }
    return allSum;
  }, []);

  const ServicesAll = useMemo(() => {
    let allSum = 0;
    for (const e of ServicesObjects) {
      allSum += e.Index;
    }
    return allSum;
  }, []);

  const ComercialAll = useMemo(() => {
    let allSum = 0;
    for (const e of ComercialObjects) {
      allSum += e.Index;
    }
    return allSum;
  }, []);

  const intl = useIntl();

  const unresolvedUnreads = useUnreadEntities();
  const unreads = useMemo(() => {
    if (!unresolvedUnreads) return {};
    else return unresolvedUnreads;
  }, [unresolvedUnreads]);

  const ResolvedUnreadsCount = useMemo(() => {
    const serviceObj = {};

    serviceObj.Calls = unreads.UnreadCallMessages;
    serviceObj.Cases = unreads.UnreadTicketMessages;
    serviceObj.Tasks = unreads.UnreadTaskMessages;
    serviceObj.Projects = unreads.UnreadProjectMessages;
    serviceObj.Time = unreads.UnreadTimeChargeMessages;
    let serviceCount = 0;
    for (const key in serviceObj) {
      if (serviceObj.hasOwnProperty(key)) {
        const element = serviceObj[key];
        serviceCount += element;
      }
    }
    serviceObj.ServiceAll = serviceCount;

    const comercialObj = {};
    comercialObj.Subscriptions = unreads.UnreadSubscriptionMessages;
    comercialObj.Contracts = unreads.UnreadContractMessages;
    comercialObj.Clients = unreads.UnreadClientMessages;
    comercialObj.Deals = unreads.UnreadDealMessages;
    let comercialCount = 0;
    for (const key in comercialObj) {
      if (comercialObj.hasOwnProperty(key)) {
        const element = comercialObj[key];
        comercialCount += element;
      }
    }
    comercialObj.ComercialAll = comercialCount;

    return {
      Email: unreads.UnreadEmailConversations,
      All: comercialCount + serviceCount + unreads.UnreadEmailConversations,
      ...comercialObj,
      ...serviceObj
    };
  }, [unreads]);

  const ServicesChildrenSelectCheck = useChildrenSelectCheck(
    entityNoteType,
    ServicesObjects,
    ServicesAll
  );

  // eslint-disable-next-line no-unused-vars
  const MessagesChildrenSelectCheck = useChildrenSelectCheck(
    entityNoteType,
    [...ServicesObjects, ...ComercialObjects],
    AllMessages
  );

  const ComercialChildrenSelectCheck = useChildrenSelectCheck(
    entityNoteType,
    ComercialObjects,
    ComercialAll
  );
  const currentUserSpace = useCurrentAccountSpace();
  const space = useSpace();
  const hasEmailPermission = useMemo(() => {
    if (!space.TrialEnd) {
      return canUserReadModule(
        currentUserSpace,
        moduleTypes.emailConversations
      );
    }
  }, [currentUserSpace, space.TrialEnd]);

  return (
    <>
      {hasEmailPermission && (
        <NavBarItem
          disabledControl
          Icon={faEnvelope}
          TranslatedText={intl.formatMessage({ id: "EMAILS" })}
          HandleClick={onCurrentSelectedChange}
          NavStatus={status}
          Index={ConversationTypeEnumFlags.Email}
          Selected={
            CurrentSelected & ConversationTypeEnumFlags.Email &&
            CurrentSelected !== ConversationTypeEnumFlags.All
          }
          Unreads={ResolvedUnreadsCount.Email}
        />
      )}
      <div className="mb-2"></div>
      <NavBarAccordion
        Icon={faMessages}
        disabledControl
        TranslatedText={intl.formatMessage({ id: "MESSAGES" })}
        HandleClick={onCurrentAllSelectedChange}
        NavStatus={status}
        GroupIndex={AllMessages}
        Index={ConversationTypeEnumFlags.EntityNotes}
        Selected={
          CurrentSelected === ConversationTypeEnumFlags.EntityNotes &&
          ChildrenSelected === EntityTypeNoteEnumFlags.All
        }
        Unreads={
          ResolvedUnreadsCount.ComercialAll + ResolvedUnreadsCount.ServiceAll
        }
        Options={[...ServicesObjects, ...ComercialObjects]}
        UnreadOptions={ResolvedUnreadsCount}
      >
        <div className="mt-2 ml-3">
          <NavBarAccordion
            Icon={faCommentAltDots}
            TranslatedText={intl.formatMessage({ id: "SERVICES" })}
            HandleClick={onEntityCurrentSelectedChange}
            ChildrenClick={onEntityServicesCurrentSelectedChange}
            NavStatus={status}
            GroupIndex={ServicesAll}
            Index={ConversationTypeEnumFlags.EntityNotes}
            Selected={
              CurrentSelected & ConversationTypeEnumFlags.EntityNotes &&
              ServicesChildrenSelectCheck &&
              CurrentSelected !== ConversationTypeEnumFlags.All
            }
            Options={ServicesObjects}
            UnreadOptions={ResolvedUnreadsCount}
            Unreads={ResolvedUnreadsCount.ServiceAll}
          />
          <NavBarAccordion
            Icon={faCommentsAltDollar}
            TranslatedText={intl.formatMessage({ id: "COMERCIAL" })}
            HandleClick={onEntityCurrentSelectedChange}
            ChildrenClick={onEntityServicesCurrentSelectedChange}
            GroupIndex={ComercialAll}
            NavStatus={status}
            Index={ConversationTypeEnumFlags.EntityNotes}
            Selected={
              CurrentSelected & ConversationTypeEnumFlags.EntityNotes &&
              ComercialChildrenSelectCheck &&
              CurrentSelected !== ConversationTypeEnumFlags.All
            }
            Options={ComercialObjects}
            UnreadOptions={ResolvedUnreadsCount}
            Unreads={ResolvedUnreadsCount.ComercialAll}
          />
        </div>
      </NavBarAccordion>

      {/* <NavBarItem
        Icon={faCommentAlt}
        TranslatedText={intl.formatMessage({ id: "CHAT_ONLINE" })}
        HandleClick={onCurrentSelectedChange}
        NavStatus={status}
        Index={ConversationTypeEnumFlags.Chat}
        Selected={
          CurrentSelected & ConversationTypeEnumFlags.Chat &&
          CurrentSelected !== ConversationTypeEnumFlags.All
        }
        Unreads={0}
      />
      <NavBarItem
        Icon={faCommentSmile}
        TranslatedText={intl.formatMessage({ id: "SOCIAL_MEDIA" })}
        HandleClick={onCurrentSelectedChange}
        NavStatus={status}
        Index={ConversationTypeEnumFlags.SocialMedia}
        Selected={
          CurrentSelected & ConversationTypeEnumFlags.SocialMedia &&
          CurrentSelected !== ConversationTypeEnumFlags.All
        }
        Unreads={0}
      /> */}
      {/* <NavBarAccordion
        Icon={faCommentsAlt}
        TranslatedText={intl.formatMessage({ id: "DIRECT" })}
        HandleClick={onEntityCurrentSelectedChange}
        ChildrenClick={onEntityCurrentSelectedChange}
        NavStatus={status}
        Index={ConversationTypeEnumFlags.EntityNotes}
        Selected={
          CurrentSelected & ConversationTypeEnumFlags.EntityNotes &&
          CurrentSelected !== ConversationTypeEnumFlags.All
        }
        Unreads={0}
      /> */}
      {/* <NavBarItem
        Icon={faCommentAltDollar}
        TranslatedText={intl.formatMessage({ id: "COMERCIAL" })}
        HandleClick={onCurrentSelectedChange}
        NavStatus={status}
        Index={ReceptionNavEnum.comercial}
        Selected={
          CurrentSelected & ReceptionNavEnum.comercial &&
          CurrentSelected !== ReceptionNavEnum.all
        }
        Unreads={1}
      />
      <NavBarItem
        Icon={faCommentsAlt}
        TranslatedText={intl.formatMessage({ id: "DIRECT" })}
        HandleClick={onCurrentSelectedChange}
        NavStatus={status}
        Index={ReceptionNavEnum.direct}
        Selected={
          CurrentSelected & ReceptionNavEnum.direct &&
          CurrentSelected !== ReceptionNavEnum.all
        }
        Unreads={1}
      /> */}
    </>
  );
};
