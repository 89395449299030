import { faNote } from "@fortawesome/pro-solid-svg-icons";
import React from "react";

import TriggerCreation from "./TriggerCreation";
import { spaceTasksSchema } from "../../../../../config/schema";
import { ConnectedTaskOrigin } from "../../../../Origin/TaskOrigin";
import { useIntl } from "react-intl";

const PreviewTarget = ({ val }) => <ConnectedTaskOrigin task={val} />;

const TriggerTaskCreation = React.memo(({ ...props }) => {
	const intl = useIntl()
  return (
    <TriggerCreation
      {...props}
			TargetPreview={PreviewTarget}
      schema={spaceTasksSchema}
      icon={faNote}
			text={intl.formatMessage({ id: "TASK" })}
    />
  );
});

export default TriggerTaskCreation;
