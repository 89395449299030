import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const TaskDetailsComp = React.lazy(() =>
  import(/* webpackChunkName: "tasks-details" */ "./TaskDetails2Comp")
);

const TaskDetails = () => {
  return (
    <SuspenseErrorBoundary>
      <TaskDetailsComp />
    </SuspenseErrorBoundary>
  );
};

export default TaskDetails;
