import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { usePost, useQuery } from "../../Helpers/IOClient";
import classnames from "classnames";
import classes from "./Feedback.module.css";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as Angry } from "../../Assets/Svg/Feedbacksvg/angry.svg";
import { ReactComponent as Angry1 } from "../../Assets/Svg/Feedbacksvg/angry-1.svg";
import { ReactComponent as Angry2 } from "../../Assets/Svg/Feedbacksvg/angry-2.svg";
import { ReactComponent as Frown } from "../../Assets/Svg/Feedbacksvg/frown.svg";
import { ReactComponent as Frown1 } from "../../Assets/Svg/Feedbacksvg/frown-1.svg";
import { ReactComponent as Frown2 } from "../../Assets/Svg/Feedbacksvg/frown-2.svg";
import { ReactComponent as Meh } from "../../Assets/Svg/Feedbacksvg/meh.svg";
import { ReactComponent as Meh1 } from "../../Assets/Svg/Feedbacksvg/meh-1.svg";
import { ReactComponent as Meh2 } from "../../Assets/Svg/Feedbacksvg/meh-2.svg";
import { ReactComponent as Smile } from "../../Assets/Svg/Feedbacksvg/smile.svg";
import { ReactComponent as Smile1 } from "../../Assets/Svg/Feedbacksvg/smile-1.svg";
import { ReactComponent as Smile2 } from "../../Assets/Svg/Feedbacksvg/smile-2.svg";
import { ReactComponent as Laugh } from "../../Assets/Svg/Feedbacksvg/laugh.svg";
import { ReactComponent as Laugh1 } from "../../Assets/Svg/Feedbacksvg/laugh-1.svg";
import { ReactComponent as Laugh2 } from "../../Assets/Svg/Feedbacksvg/laugh-2.svg";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import querystring from "query-string";
import {
  enConfig,
  esConfig,
  Intl18nContext,
  ptConfig
} from "../Intl18n/Intl18nProvider";
import { useToast } from "../../Components/Toast/ToastProvider";
import { handleError } from "../../Helpers/MiscHelper";
import LoadableButton from "../../Components/Button/LoadableButton";

const Feedback = () => {
  const intl = useIntl();
  const location = useLocation();
  let history = useHistory();

  const { search } = location;
  const queryObj = querystring.parse(search);
  const { hash, val } = queryObj;
  const [rating, setRating] = useState((val && parseInt(val)) || null);
  const [description, setDescription] = useState("");
  const { data, loading, error } = useQuery(`spaces/1/feedback?hash=${hash}`);
  const createToast = useToast();
  const [post, { loading: loadingPost }] = usePost(`spaces/1/feedback`, null, {
    onSuccess: ({ data }) => {
      createToast({
        pos: "tm",
        type: "success",
        description: intl.formatMessage({ id: "FEEDBACK_SUBMITED_SUCCESS" })
      });
      history.push("/");
    },
    onError: ({ error }) => {
      handleError(createToast, error);
    }
  });

  const handleRatingClick = (rate) => {
    setRating(rate);
  };

  const { changeLanguage, value } = useContext(Intl18nContext);

  useEffect(() => {
    if (data?.LangCode && value.Value !== data.LangCode) {
      switch (data?.LangCode) {
        case "en":
          changeLanguage(enConfig);
          break;
        case "pt":
          changeLanguage(ptConfig);
          break;
        case "es":
          changeLanguage(esConfig);
          break;
        default:
          break;
      }
    }
  }, [changeLanguage, data, value]);

  useEffect(() => {
    if (error) {
      const type = error?.data?.Message;
      if (type === "HASH_ALREADY_SUBMIT") {
        createToast({
          pos: "tm",
          type: "warning",
          description: intl.formatMessage({ id: "FEEDBACK_ALREDY_SUBMITED" })
        });
        history.push("/");
      }
    }
  }, [createToast, error, history, intl]);

  if (error) {
    const type = error?.data?.Message;

    switch (type) {
      case "HASH_ALREADY_SUBMIT":
        return (
          <div className="w-100 h-100 bg-white">
            <div className="d-flex justify-content-center h-100 align-items-center">
              <LoaderSpinner size="sm" className="text-primary" center />
            </div>
          </div>
        );
      default:
        return <Redirect to={"/"} />;
    }
  }

  if (loading)
    return (
      <div className="w-100 h-100 bg-white">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <LoaderSpinner size="sm" className="text-primary" center />
        </div>
      </div>
    );

  const handlePost = () => {
    const form = {};
    form.Hash = hash;
    form.Body = description;
    form.Rating = rating;
    post(JSON.stringify(form));
  };

  return (
    <div className="w-100 h-100 bg-white">
      <div className={classnames(classes.headerContainer)}>
        <img src={data?.CompanyImage} alt="Banner goes here" />
      </div>
      <div className={classnames(classes.bodyContainer)}>
        {/* <div className={classnames(classes.ticketTitle)}>{data?.Name}</div> */}
        {/* <div className={classnames(classes.subTitle)}>
          <span>
            <FormattedMessage id={"CASE"} /> nº {data?.TicketId}
          </span>
          <span>{data?.ReceivedDate}</span>
        </div> */}
        <div className="text-black fs-18 d-flex justify-content-center fw-bold mb-3">
          <FormattedMessage id="WEED_LOVE_FEEDBACK" />
        </div>
        <div className={classnames(classes.textContainer)}>
          {/* <FormattedMessage id="EMAIL_FEEDBACK_START" /> <b>{data?.Client}</b>{" "} */}
          <FormattedMessage
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              caseName: data?.Name,
              caseId: data?.TicketId
            }}
            id="EMAIL_FEEDBACK_CONTENT"
          />
          {/* <FormattedMessage id="ASSOCIATED_TO" /> <b>{data?.Associate}</b>{" "}
          <FormattedMessage id="WITH_THE_SUBJECT" /> <b>{data?.Description}</b> */}
        </div>
        <div className={classnames(classes.feedbackTitle)}>
          <FormattedMessage id="HOW_WAS_THE_EXPERIENCE" />
        </div>
        <div className={classnames(classes["feedback-rating-faces-container"])}>
          <div
            className={classnames(classes["feedback-face-box"], {
              [classes.active]: rating === 1
            })}
            onClick={() => {
              handleRatingClick(1);
            }}
          >
            <Angry className={classnames(classes["default"])} />
            <Angry1 className={classnames(classes["hover"])} />
            <Angry2 className={classnames(classes["active"])} />
          </div>
          <div
            className={classnames(classes["feedback-face-box"], {
              [classes.active]: rating === 2
            })}
            onClick={() => {
              handleRatingClick(2);
            }}
          >
            <Frown className={classnames(classes["default"])} />
            <Frown1 className={classnames(classes["hover"])} />
            <Frown2 className={classnames(classes["active"])} />
          </div>
          <div
            className={classnames(classes["feedback-face-box"], {
              [classes.active]: rating === 3
            })}
            onClick={() => {
              handleRatingClick(3);
            }}
          >
            <Meh className={classnames(classes["default"])} />
            <Meh1 className={classnames(classes["hover"])} />
            <Meh2 className={classnames(classes["active"])} />
          </div>
          <div
            className={classnames(classes["feedback-face-box"], {
              [classes.active]: rating === 4
            })}
            onClick={() => {
              handleRatingClick(4);
            }}
          >
            <Smile className={classnames(classes["default"])} />
            <Smile1 className={classnames(classes["hover"])} />
            <Smile2 className={classnames(classes["active"])} />
          </div>
          <div
            className={classnames(classes["feedback-face-box"], {
              [classes.active]: rating === 5
            })}
            onClick={() => {
              handleRatingClick(5);
            }}
          >
            <Laugh className={classnames(classes["default"])} />
            <Laugh1 className={classnames(classes["hover"])} />
            <Laugh2 className={classnames(classes["active"])} />
          </div>
        </div>
        <div
          className={classnames(
            "d-flex justify-content-center mt-4",
            classes["textareaContainer"],
            {
              [classes["active"]]: Boolean(rating)
            }
          )}
        >
          <textarea
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            autocomplete="off"
            placeholder={intl.formatMessage({ id: "YOUR_FEEDBACK" })}
            type="text"
          ></textarea>
        </div>
        <div className="d-flex mt-3 justify-content-center">
          <LoadableButton
            isLoading={loadingPost}
            onClick={handlePost}
            disabled={!Boolean(rating)}
          >
            <FormattedMessage id="SUBMIT_FEEDBACK" />
          </LoadableButton>
        </div>
        <div className={classnames(classes.UnderSubText, "mt-4")}>
          <FormattedMessage
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              CompanyName: data?.CompanyName
            }}
            id="WE_APPRICIATE_YOUR_FEEDBACK"
          />
        </div>
        {/* <div className={classnames(classes.UnderSubText, "mt-4")}>
          <FormattedMessage id="WE_APPRICIATE_YOUR_PATIANCE" />
        </div>
        <div className={classnames(classes.UnderSubText)}>
          {"- "}
          <FormattedMessage id="THE_TEAM" /> {data?.CompanyName}
        </div> */}
      </div>
    </div>
  );
};

export default Feedback;
