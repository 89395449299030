import React from "react";

const AirdeskInviteBackgroundSvg = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="811.532"
      height="716.703"
      viewBox="0 0 811.532 716.703"
      className={className}
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.182"
          y1="-0.245"
          x2="0.452"
          y2="0.386"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#a1a1e6" />
          <stop offset="1" stop-color="#ebebf9" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="0.961"
          y1="0.053"
          x2="0.015"
          y2="0.818"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#e9eef3" />
          <stop offset="1" stop-color="#dfe3ea" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="0.401"
          y1="1.002"
          x2="0.651"
          y2="-0.266"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#c3c3e6" />
          <stop offset="1" stop-color="#8e8ecc" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="0.036"
          y1="0.618"
          x2="0.406"
          y2="0.447"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#fa9" />
          <stop offset="0.95" stop-color="#ff8066" />
          <stop offset="1" stop-color="#ff8066" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="0.615"
          y1="-0.238"
          x2="0.263"
          y2="1.643"
        />
      </defs>
      <g
        id="Group_6342"
        data-name="Group 6342"
        transform="translate(-771.395 -175.291)"
      >
        <path
          id="Path"
          d="M588.719,117.194C412.055,142.406,285.326,17.943,197.522,2.65,65.694-20.356-86.76,106.5,59.973,379.694S272.315,718.506,469.307,716.666c157.58-1.5,278.615-132.759,320.7-230.061C855.892,334.317,765.369,92,588.719,117.194Z"
          transform="translate(771.395 175.291)"
          fill="#f3f8fe"
        />
        <path
          id="Path-2"
          data-name="Path"
          d="M1.358,68.529c-4.045,29.127,1.173,56.282,12.652,74.9C30.192,128.695,42.692,103.987,46.778,74.9S45.6,18.619,34.126,0C17.974,14.735,5.474,39.4,1.358,68.529Z"
          transform="translate(1425.328 629.502)"
          fill="#dfe3ea"
        />
        <path
          id="Path-3"
          data-name="Path"
          d="M15.842,0A343.86,343.86,0,0,0,4.2,40.393C-1.22,64.231.156,92.579.156,92.579"
          transform="translate(1447.648 693.379)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path-4"
          data-name="Path"
          d="M0,0S3.257,27.307,7.272,48.424s6.736,48.919,6.736,48.919"
          transform="translate(1433.635 685.611)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path-5"
          data-name="Path"
          d="M0,0S6.523,17.81,11.287,29.329,20.53,52.742,20.53,52.742"
          transform="translate(1427.111 731.517)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path-6"
          data-name="Path"
          d="M.879,46.188C-1.8,65.8,1.8,84.064,9.567,96.554,20.409,86.6,28.783,69.976,31.453,50.365S30.532,12.49,22.765,0C11.923,9.952,3.56,26.578.879,46.188Z"
          transform="translate(1451.849 534.151)"
          fill="#e9eef3"
        />
        <path
          id="Path-7"
          data-name="Path"
          d="M22.563,87.988C23.575,65.172,25.112,24,25.982,0,10.154,19.6,0,49.617,0,83.325c0,33.314,9.921,63.028,25.446,82.638C23.706,142.935,21.633,108.751,22.563,87.988Z"
          transform="translate(1408.836 537.549)"
          fill="#e9eef3"
        />
        <path
          id="Path-8"
          data-name="Path"
          d="M30.985,84.185C30.985,49.991,20.548,19.57,4.326,0c-.233.273-.445.576-.678.86-.87,24-2.4,65.182-3.418,87.988C-.7,109.621,1.383,143.794,3.1,166.813c.4.516.8,1.011,1.214,1.557C20.538,148.8,30.985,118.369,30.985,84.185Z"
          transform="translate(1431.17 536.68)"
          fill="#dfe3ea"
        />
        <path
          id="Path-9"
          data-name="Path"
          d="M8.68,41.111C-.159,60.6-2.061,80.645,2.126,96c14.3-6.968,28.116-21.613,36.945-41.111S49.781,15.3,45.635,0C31.324,6.968,17.519,21.613,8.68,41.111Z"
          transform="translate(1455.862 616.485)"
          fill="#e9eef3"
        />
        <path
          id="Path-10"
          data-name="Path"
          d="M67.4,76.064H8.708L0,0H76.114L67.4,76.064Z"
          transform="translate(1409.736 767.713)"
          fill="#e9eef3"
        />
        <path
          id="Path-11"
          data-name="Path"
          d="M0,0,8.708,76.064H31.17L24.667,0Z"
          transform="translate(1409.736 767.713)"
          fill="#dfe3ea"
        />
        <rect
          id="Rectangle"
          width="82.061"
          height="18.74"
          transform="translate(1406.763 765.539)"
          fill="#dfe3ea"
        />
        <rect
          id="Rectangle-2"
          data-name="Rectangle"
          width="4.136"
          height="111.916"
          rx="1.37"
          transform="translate(1080.399 414.924) rotate(45)"
          fill="#dfe3ea"
        />
        <circle
          id="Oval"
          cx="11.833"
          cy="11.833"
          r="11.833"
          transform="translate(1086.497 449.219) rotate(-32.51)"
          fill="#dfe3ea"
        />
        <rect
          id="Rectangle-3"
          data-name="Rectangle"
          width="4.136"
          height="111.916"
          rx="1.37"
          transform="translate(1074.503 409.023) rotate(45)"
          fill="#e9eef3"
        />
        <rect
          id="Rectangle-4"
          data-name="Rectangle"
          width="4.136"
          height="162.079"
          rx="1.37"
          transform="translate(1002.383 488.083) rotate(-10.66)"
          fill="#dfe3ea"
        />
        <rect
          id="Rectangle-5"
          data-name="Rectangle"
          width="13.967"
          height="56.405"
          rx="2.97"
          transform="matrix(-0.841, 0.541, -0.541, -0.841, 1109.892, 451.301)"
          fill="url(#linear-gradient)"
        />
        <circle
          id="Oval-2"
          data-name="Oval"
          cx="8.425"
          cy="8.425"
          r="8.425"
          transform="translate(1091.203 450.261) rotate(-32.51)"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="Path-12"
          data-name="Path"
          d="M9.468,2.93c11.651-7.423,29.5-.253,39.878,16.02L7.142,45.841C-3.225,29.569-2.183,10.353,9.468,2.93Z"
          transform="translate(1076.666 423.715)"
          fill="#dfe3ea"
        />
        <path
          id="Path-13"
          data-name="Path"
          d="M38.037,18.952,26.7,26.173C19.115,14.28,9.244,5.673,0,1.9,11.62-3.733,28.2,3.509,38.037,18.952Z"
          transform="translate(1087.974 423.712)"
          fill="#e9eef3"
        />
        <path
          id="Path-14"
          data-name="Path"
          d="M1.386.03H2.741a1.386,1.386,0,0,1,1.4,1.355V160.724a1.4,1.4,0,0,1-1.4,1.4H1.386A1.386,1.386,0,0,1,0,160.734V1.386A1.386,1.386,0,0,1,1.386,0Z"
          transform="translate(994.177 489.582) rotate(-10.66)"
          fill="#e9eef3"
        />
        <circle
          id="Oval-3"
          data-name="Oval"
          cx="10.093"
          cy="10.093"
          r="10.093"
          transform="translate(993.564 480.762)"
          fill="#dfe3ea"
        />
        <circle
          id="Oval-4"
          data-name="Oval"
          cx="10.093"
          cy="10.093"
          r="10.093"
          transform="translate(991.683 480.762)"
          fill="#fff"
        />
        <circle
          id="Oval-5"
          data-name="Oval"
          cx="5.057"
          cy="5.057"
          r="5.057"
          transform="translate(996.72 485.798)"
          fill="#dfe3ea"
        />
        <rect
          id="Rectangle-6"
          data-name="Rectangle"
          width="487.705"
          height="15.049"
          transform="translate(970.263 642.133)"
          fill="#dfe3ea"
        />
        <path
          id="Rectangle-7"
          data-name="Rectangle"
          d="M0,0H89.737V15.049H0Z"
          transform="translate(1368.655 642.133)"
          fill="#dfe3ea"
        />
        <rect
          id="Rectangle-8"
          data-name="Rectangle"
          width="487.705"
          height="4.895"
          transform="translate(970.263 652.297)"
          fill="#dfe3ea"
        />
        <rect
          id="Rectangle-9"
          data-name="Rectangle"
          width="89.313"
          height="4.895"
          transform="translate(1368.655 652.297)"
          fill="#e9eef3"
        />
        <path
          id="Path-15"
          data-name="Path"
          d="M12.794,186.645H0L50.011,0H62.805L12.794,186.645Z"
          transform="translate(970.263 657.192)"
          fill="#e9eef3"
        />
        <path
          id="Path-16"
          data-name="Path"
          d="M50.011,186.645H62.805L12.794,0H0L50.011,186.645Z"
          transform="translate(1033.452 657.192)"
          fill="#e9eef3"
        />
        <path
          id="Path-17"
          data-name="Path"
          d="M12.794,186.645H0L50.011,0H62.805L12.794,186.645Z"
          transform="translate(1282.831 657.192)"
          fill="#e9eef3"
        />
        <path
          id="Path-18"
          data-name="Path"
          d="M50.011,186.645H62.805L12.794,0H0L50.011,186.645Z"
          transform="translate(1346.021 657.192)"
          fill="#e9eef3"
        />
        <path
          id="Path-19"
          data-name="Path"
          d="M18.872,93.5H60.145L28.9,0H0L18.872,93.5Z"
          transform="translate(1267.692 548.634)"
          fill="#dfe3ea"
        />
        <path
          id="Path-20"
          data-name="Path"
          d="M18.862,93.5H60.145L28.9,0H0L18.862,93.5Z"
          transform="translate(1264.92 548.634)"
          fill="#e9eef3"
        />
        <rect
          id="Rectangle-10"
          data-name="Rectangle"
          width="71.29"
          height="3.702"
          transform="translate(1254.837 638.432)"
          fill="#dfe3ea"
        />
        <path
          id="Path-21"
          data-name="Path"
          d="M171.689,118.491H5.372a5.279,5.279,0,0,1-5.34-5.856L9.377,5.857A6.523,6.523,0,0,1,15.748,0h166.3a5.279,5.279,0,0,1,5.34,5.856L178.04,112.635A6.523,6.523,0,0,1,171.689,118.491Z"
          transform="translate(1204.501 496.103)"
          fill="#e9eef3"
        />
        <path
          id="Path-22"
          data-name="Path"
          d="M171.678,118.491H5.372a5.279,5.279,0,0,1-5.34-5.856L9.377,5.857A6.523,6.523,0,0,1,15.748,0h166.3a5.279,5.279,0,0,1,5.34,5.856l-9.294,106.8A6.523,6.523,0,0,1,171.678,118.491Z"
          transform="translate(1201.133 496.103)"
          fill="#dfe3ea"
        />
        <path
          id="Path-23"
          data-name="Path"
          d="M166.5,115.254H4.232a4.157,4.157,0,0,1-4.207-4.612L9.3,4.612A5.138,5.138,0,0,1,14.356,0H176.587a4.157,4.157,0,0,1,4.207,4.612l-9.274,105.98a5.138,5.138,0,0,1-5.016,4.662Z"
          transform="translate(1205.792 497.722)"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path-24"
          data-name="Path"
          d="M2.692,109.347l9.1-103.492A6.523,6.523,0,0,1,18.165,0h-2.66A6.523,6.523,0,0,0,9.134,5.856L.032,109.347a5.279,5.279,0,0,0,5.34,5.856h2.66a5.279,5.279,0,0,1-5.34-5.856Z"
          transform="translate(1203.227 497.773)"
          fill="#e9eef3"
        />
        <path
          id="Path-25"
          data-name="Path"
          d="M1.463,0,.027,16.394a5,5,0,0,0,5.057,5.512H171.876a6.351,6.351,0,0,0,6.189-5.674L179.481.051Z"
          transform="translate(1201.098 592.719)"
          fill="#e9eef3"
        />
        <path
          id="Path-26"
          data-name="Path"
          d="M6.5,2.255A6.25,6.25,0,0,1,6.938.5,3.772,3.772,0,0,0,5.087,0C2.569,0,.324,2.356.03,5.269s1.578,5.269,4.045,5.269A5.188,5.188,0,0,0,8.657,7.029,4.854,4.854,0,0,1,6.5,2.255Z"
          transform="translate(1285.987 598.392)"
          fill="#dfe3ea"
        />
        <path
          id="Path-27"
          data-name="Path"
          d="M0,0A30.684,30.684,0,0,1,11.9,6.068L6.078,9.163l8-1.123a30.5,30.5,0,0,1,4.45,5.6L7.868,16.677,19.61,15.565c.212.415.415.85.617,1.284L8.293,22.6l12.824-3.631c.293.789.566,1.588.8,2.407a30.725,30.725,0,0,1,1.133,9.881L11.722,32.606,22.907,33a7.7,7.7,0,0,1-.091.829l-9.729,1.517,9.517-.172a30.654,30.654,0,0,1-1.871,6.372L13.34,41.7l6.179,2.417a30.614,30.614,0,0,1-5.006,6.938C8.708,25.709,0,0,0,0Z"
          transform="translate(1061.335 488.59)"
          fill="#e9eef3"
        />
        <path
          id="Path-28"
          data-name="Path"
          d="M18.124,41.678l-6.169,3.894A30.634,30.634,0,0,1,7.96,41.9l7.524-6.766L5.431,38.7a30.725,30.725,0,0,1-4.268-9.1c-.071-.263-.131-.526-.192-.789l10.366-4.207L.274,25.223C.162,24.4.091,23.575.051,22.755L10.61,17.871,0,21.41a30.492,30.492,0,0,1,.708-6.705l9.881-4.045L1.255,12.692A30.7,30.7,0,0,1,2.721,8.718l6.655-2.3L3.166,7.767A30.634,30.634,0,0,1,8.6,0S17.3,25.709,23.069,51.033a30.563,30.563,0,0,1-5.35-1.922Z"
          transform="translate(1052.738 488.63)"
          fill="#dfe3ea"
        />
        <path
          id="Path-29"
          data-name="Path"
          d="M27.33,0A52.216,52.216,0,0,0,10.137,14.907l10.933,2.68L7.386,19.054a51.518,51.518,0,0,0-4.976,11l18.882.556L1.409,33.648c-.182.779-.334,1.568-.475,2.367l22.139,4.521L.347,39.868c-.162,1.416-.283,2.842-.324,4.3A51.771,51.771,0,0,0,2.268,60.873l19.3-2.488L3.229,63.665c.162.445.324.89.506,1.325l16.718-1.527-15.8,3.651A51.9,51.9,0,0,0,10.41,76.8l12.3-2.8-9.193,6.554A52.166,52.166,0,0,0,24.64,89.879C23.648,45.845,27.33,0,27.33,0Z"
          transform="translate(1062.708 503.699)"
          fill="#dfe3ea"
        />
        <path
          id="Path-30"
          data-name="Path"
          d="M4.45,72.443l11.772,3.863a51.851,51.851,0,0,0,5.057-7.686l-15.271-8L24.151,62.33a51.771,51.771,0,0,0,3.277-16.677V44.277L8.536,41.648l18.548-3.59c-.162-1.4-.394-2.771-.667-4.136L6.928,30.25l19.024,1.426a51.5,51.5,0,0,0-3.975-10.72l-18-2.478,16.263-.647a52.055,52.055,0,0,0-4.045-5.937L4.228,10.882l10.832-.364A52.186,52.186,0,0,0,2.832,0S-.849,45.845.182,89.869a52.124,52.124,0,0,0,8.091-5.38Z"
          transform="translate(1087.235 503.72)"
          fill="#dfe3ea"
        />
        <path
          id="Path-31"
          data-name="Path"
          d="M27.974,0A35.023,35.023,0,0,0,14.917,7.656l5.573,3.772L12.4,10.134A44.5,44.5,0,0,0,7.11,17.1l10.538,3.621L5.734,19.479c-.283.516-.536,1.011-.8,1.6l11.489,7L3.742,23.716c-.4,1.011-.8,2.023-1.153,3.034A47.877,47.877,0,0,0,.091,39L11.55,40.535,0,41.152v1.011l9.79,1.77-9.749-.1A41.182,41.182,0,0,0,1.1,51.7l7.575.111L2,54.846A33.142,33.142,0,0,0,6.21,63.381C15.555,31.928,27.974,0,27.974,0Z"
          transform="translate(1075.909 506.683)"
          fill="#e9eef3"
        />
        <path
          id="Path-32"
          data-name="Path"
          d="M6.442,51.751,12.217,56.5a41.465,41.465,0,0,0,4.6-4.592L9.982,43.62l9.851,4.308a47.867,47.867,0,0,0,5.613-11.287c.111-.324.2-.657.3-1.011L15.636,30.573l11.287.637c.222-1.011.4-2.023.556-3.034L17.294,22.24,27.721,26.5a44.651,44.651,0,0,0,.172-8.293L18.285,13.37l9.345,2.286a39.726,39.726,0,0,0-1.011-4.915L20.1,7.969l6.2,1.588A33.5,33.5,0,0,0,21.764,0S9.345,31.928,0,63.351A32.02,32.02,0,0,0,5.755,60.9Z"
          transform="translate(1082.017 506.693)"
          fill="#e9eef3"
        />
        <path
          id="Path-33"
          data-name="Path"
          d="M13.987,0A40.363,40.363,0,0,0,3.509,14.068l8.7.212L2.094,17.658a39.817,39.817,0,0,0-1.932,9.1l14.351-2.71L0,29.633v1.861l17.466-.263L.2,34.5c.111,1.092.263,2.195.465,3.3A40.019,40.019,0,0,0,5.239,50.113L19.4,45.056,6.432,52.135c.2.3.394.617.6.92L19.4,49.061,8.071,54.441A40.12,40.12,0,0,0,14.017,60.8l8.819-4.157L16.96,63.1a40.323,40.323,0,0,0,9.982,5.188C18.821,35.225,13.987,0,13.987,0Z"
          transform="translate(1049.34 528.72)"
          fill="#dfe3ea"
        />
        <path
          id="Path-34"
          data-name="Path"
          d="M13.309,54.38l9.527,1.011A40.08,40.08,0,0,0,25.4,48.747L12.521,45.2l13.957-1.719a40.019,40.019,0,0,0-.293-13.148c-.071-.354-.162-.688-.233-1.011L11.256,30.472l13.4-5.8c-.354-1.011-.759-2.023-1.183-3.034l-15.3.445,14.6-2.023A39.807,39.807,0,0,0,18,12.632L3.995,13.744l12.136-3.226a40.242,40.242,0,0,0-4.045-3.8l-9.2,1.214,8.091-2.023A40.343,40.343,0,0,0,0,0S4.834,35.225,12.915,68.287a40.292,40.292,0,0,0,5.178-5.4Z"
          transform="translate(1063.297 528.791)"
          fill="#e9eef3"
        />
        <path
          id="Path-35"
          data-name="Path"
          d="M46.684,52.681H6.038L0,0H52.722L46.684,52.681Z"
          transform="translate(1052.759 589.452)"
          fill="#e9eef3"
        />
        <path
          id="Path-36"
          data-name="Path"
          d="M0,0,6.038,52.681H21.592L17.082,0Z"
          transform="translate(1052.759 589.452)"
          fill="#dfe3ea"
        />
        <rect
          id="Rectangle-11"
          data-name="Rectangle"
          width="56.838"
          height="12.986"
          transform="translate(1050.696 587.945)"
          fill="#dfe3ea"
        />
        <path
          id="Rectangle-12"
          data-name="Rectangle"
          d="M0,0H26.568V5.168H0Z"
          transform="translate(1288 636.965)"
          fill="#dfe3ea"
        />
        <rect
          id="Rectangle-13"
          data-name="Rectangle"
          width="84.863"
          height="3.702"
          transform="translate(1203.976 638.432)"
          fill="#e9eef3"
        />
        <path
          id="Path-37"
          data-name="Path"
          d="M25.6,0H110.46l-25.6,4.5H0L25.6,0Z"
          transform="translate(1203.976 633.921)"
          fill="#dfe3ea"
        />
        <ellipse
          id="Oval-6"
          data-name="Oval"
          cx="2.994"
          cy="4.106"
          rx="2.994"
          ry="4.106"
          transform="translate(1311.574 633.921)"
          fill="#e9eef3"
        />
        <path
          id="Path-38"
          data-name="Path"
          d="M33.141,10.908a1.375,1.375,0,0,0,1.143-2.154A20.925,20.925,0,0,0,17.263,0,21.155,21.155,0,0,0,4.9,4.043,20.441,20.441,0,0,0,.242,8.754a1.375,1.375,0,0,0,1.143,2.154Z"
          transform="translate(1325.067 631.225)"
          fill="#dfe3ea"
        />
        <path
          id="Path-39"
          data-name="Path"
          d="M14.805,7.676A28.773,28.773,0,0,1,19.862.162,21.127,21.127,0,0,0,17.263,0,20.9,20.9,0,0,0,.242,8.748,1.375,1.375,0,0,0,1.385,10.9H15.948C14.836,10.943,14.158,9.062,14.805,7.676Z"
          transform="translate(1325.046 631.191)"
          fill="#e9eef3"
        />
        <path
          id="Path-40"
          data-name="Path"
          d="M8.263,65.4c9.315,23.595,24.859,41.8,41.465,50.9C26.781,90.385,3.621,0,3.621,0-2.265,17.9-1.152,41.779,8.263,65.4Z"
          transform="translate(1383.097 630.776)"
          fill="#e9eef3"
        />
        <path
          id="Path-41"
          data-name="Path"
          d="M41.465,50.871C32.1,27.246,16.556,9.082,0,0,0,0,23.17,90.425,46.108,116.306,51.943,98.374,50.831,74.5,41.465,50.871Z"
          transform="translate(1386.687 630.705)"
          fill="#dfe3ea"
        />
        <path
          id="Path-42"
          data-name="Path"
          d="M583.915,11.1H0L40.08,0H546.95l36.965,11.1Z"
          transform="translate(930.183 843.838)"
          fill="#dfe3ea"
        />
        <rect
          id="Rectangle-14"
          data-name="Rectangle"
          width="23.16"
          height="16.283"
          transform="translate(1333.055 598.342)"
          opacity="0.45"
          fill="url(#linear-gradient-4)"
        />
        <path
          id="Path-43"
          data-name="Path"
          d="M0,21.613,3.53,20.6c3.53-1.011,3.388-8.354,3.388-8.354V0h23.16V12.247s.142,7.332-3.388,8.354l-3.53,1.011Z"
          transform="translate(1326.138 596.39)"
          fill="#dfe3ea"
        />
        <g
          id="Artwork_5"
          data-name="Artwork 5"
          transform="translate(1278.088 524.979)"
        >
          <g id="Artwork_5-2" data-name="Artwork 5" transform="translate(0 0)">
            <path
              id="Path_60"
              data-name="Path 60"
              d="M33.719.692A1.134,1.134,0,0,0,32.621,0H25.975a.987.987,0,0,0-.982.693L17.939,20.249a.867.867,0,0,0,.674,1.151q3.309.525,6.716.727a.87.87,0,0,0,.875-.6l3.18-9.742,3.179,9.739a.87.87,0,0,0,.875.6q3.376-.2,6.659-.724a.866.866,0,0,0,.674-1.15Z"
              transform="translate(-10.93 0.003)"
              fill="#fff"
              opacity="0.5"
            />
            <path
              id="Path_61"
              data-name="Path 61"
              d="M36.85,61.78a.867.867,0,0,0-1.092-.836,68.269,68.269,0,0,1-17.333,2.227A68.269,68.269,0,0,1,1.091,60.944.867.867,0,0,0,0,61.78v5.861a.867.867,0,0,0,.661.844q1.094.262,2.2.492L.077,76.689c-.23.578.059.982.695.982H8.283a.925.925,0,0,0,.982-.693l2.2-6.724q3.451.311,6.962.318t6.97-.319l2.189,6.724a.924.924,0,0,0,.98.693h7.512c.636,0,.924-.4.693-.982l-2.781-7.712q1.1-.229,2.2-.492a.867.867,0,0,0,.661-.844Z"
              transform="translate(0.001 -37.222)"
              fill="#fff"
            />
          </g>
        </g>
        <path
          id="Path_8804"
          data-name="Path 8804"
          stroke="#ff9f8c"
          d="M131.473,196.042s3.035,24.409-5.634,34.664-20.931-8.091-15.394-16.989,22.581.983,24.544,12.575.245,23.6,3.849,36.121,13.234,11.326,13.234,11.326"
          fill="none"
          transform="matrix(-0.788, -0.616, 0.616, -0.788, 1266.736, 730.563)"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.5"
          stroke-dasharray="3"
        />
        <g
          id="Group_4401"
          data-name="Group 4401"
          transform="translate(-4.605 -27.709)"
        >
          <rect
            id="Rectangle-15"
            data-name="Rectangle"
            width="39.641"
            height="39.641"
            rx="6.36"
            transform="translate(1301.23 431.86)"
            fill="url(#linear-gradient-5)"
          />
          <rect
            id="Rectangle-16"
            data-name="Rectangle"
            width="39.641"
            height="39.641"
            rx="6.36"
            transform="translate(1299.25 431.86)"
            fill="url(#linear-gradient-6)"
          />
          <path
            id="Path_8803"
            data-name="Path 8803"
            d="M22.781-4.729V-16.444a2.413,2.413,0,0,0-.818-1.681c-1.108-.868-2.025-1.574-7.306-5.407-.748-.546-2.234-1.859-3.266-1.844-1.032-.016-2.518,1.3-3.266,1.844C2.843-19.7,1.927-18.994.818-18.125A2.413,2.413,0,0,0,0-16.444V-4.729A2.136,2.136,0,0,0,2.136-2.594h18.51A2.136,2.136,0,0,0,22.781-4.729Zm-2.922-8.748.4.587a.417.417,0,0,1,.063.2.4.4,0,0,1-.144.286c-1.018.752-2.467,1.81-4.685,3.42-.9.659-2.516,2.128-4.105,2.117-1.59.011-3.2-1.459-4.105-2.117C5.067-10.592,3.618-11.65,2.6-12.4a.4.4,0,0,1-.144-.286.417.417,0,0,1,.063-.2l.4-.587a.356.356,0,0,1,.5-.085c1.017.751,2.468,1.811,4.7,3.43.75.547,2.234,1.859,3.266,1.843,1.032.016,2.518-1.3,3.266-1.843,2.23-1.618,3.681-2.679,4.7-3.43A.356.356,0,0,1,19.859-13.477Z"
            transform="translate(1309.109 465.109)"
            fill="#f9fbff"
          />
        </g>
      </g>
    </svg>
  );
};

export default AirdeskInviteBackgroundSvg;
