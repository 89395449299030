import React from "react";
import {
  Communicator,
  createCommunicatorHook
} from "../../../Helpers/Communicator";

export const NodeUpdaterContext = React.createContext();

export const formatAutomationValue = (form) => {
  return {
    Name: form.Name,
    Description: form.Description,
    AssignedTo: form.AssignedTo,
    Id: form.Id,
    IsManual: form.IsManual,
    IsActive: form.IsActive || false,
    Json: JSON.stringify(form.Json),
    EnableSchedule: form.EnableSchedule,
    Schedules: form.Schedules,
    ExcludedDates: form.ExcludedDates,
    OrganizationId: form.OrganizationId,
    Departments: form.Departments,
    Teams: form.Teams
  };
};

export const baseConfig = {
  hasCustomColor: false,
  customColor: "#EBF1F7",
  repeatOnFail: false,
  maxFailRepeat: 0,
  failTimeout: 1000,
  continueOnFail: false,
  note: ""
};

export const automationCommunicator = new Communicator();

export const useAutomationCommunicator = createCommunicatorHook(
  automationCommunicator
);
