import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
// import RedirectToStart from "./RedirectToStart";

const PublicRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to="/s" />
      )
    }
  />
);

const mapStateToProps = state => {
  return {
    isAuthenticated: state.Auth.isAuthenticated
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     onTodoClick: id => {
//       dispatch(toggleTodo(id))
//     }
//   }
// }

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(PublicRoute);
