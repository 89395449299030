import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const InterventionDetailsComp = React.lazy(() =>
  import(
    /* webpackChunkName: "interventions-details" */ "./InterventionDetails2Comp"
  )
);

const InterventionDetails = () => {
  return (
    <SuspenseErrorBoundary>
      <InterventionDetailsComp />
    </SuspenseErrorBoundary>
  );
};

export default InterventionDetails;
