import {
  ClosedStatusDot,
  OpenStatusDot,
  EmailClosedStatusDot,
  EmailOpenStatusDot
} from "../StatusHelper/StatusHelper";
import React, { useMemo } from "react";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useSpace } from "../../Contexts/SpaceContext";
import { ReceptionItemTypeEnum } from "../../Containers/Reception/ReceptionCompMsgList";

export const EmailConversationStatusSvgIcons = (status) => {
  switch (status.toString()) {
    case "0":
      return <EmailOpenStatusDot />;
    case "1":
      return <EmailClosedStatusDot />;
    default:
      return null;
  }
};

const buildStatusType = (status, sensitive = false) => {
  const s = status ? status.toString() : status;
  switch (s) {
    case "1":
      return "black";

    case "0":
      return "success";

    default:
      return sensitive ? "outline-grey" : "success";
  }
};

const statusNameByValue = (status) => {
  switch (status.toString()) {
    case "0":
      return <FormattedMessage id={"OPEN"} />;
    case "1":
      return <FormattedMessage id={"CLOSED_RECEPTION"} />;
    default:
      return null;
  }
};

export const ConversationStatusProfileWithIcon = ({
  conversation,
  Icon,
  status,
  item,
  hideName,
  className,
  dotClassName,
  inline,
  sensitive,
  pipelineName,
  value,
  size = 24
}) => {
  const resovledStatus = `${
    conversation ? conversation.Status : status || item || value
  }`;

  if (!resovledStatus && !sensitive) return null;

  return (
    <div
      className={classnames(
        `link-color status-color-${buildStatusType(resovledStatus, sensitive)}`,
        className,
        { "d-flex align-items-center": !inline, "d-inline-block": inline }
      )}
    >
      <div
        style={{ width: size, height: size }}
        className={`position-relative d-flex align-items-center justify-content-center text-truncate status-dot m-0 status-dot-background-template`}
      >
        {Icon}
      </div>

      {!hideName && (
        <div className="status-label fw-medium text-black fw-medium fs-12">
          {pipelineName || statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

export const ConversationOriginAnchor = React.forwardRef(
  ({ conversation, children, onClick, dynamicFont, ...rest }, ref) => {
    const space = useSpace();

    const { IsDeleted } = conversation;
    return (
      <Link
        className="disableTextDecoration"
        to={`/s/${space.Id}/inbox/${ReceptionItemTypeEnum.Email}/${conversation.Id}`}
      >
        <div
          ref={ref}
          className={classnames("link-color text-truncate", {
            "item-was-deleted": IsDeleted
          })}
          {...rest}
        >
          {children ? (
            children
          ) : (
            <>
              <div
                className={classnames(
                  "link-color d-flex align-items-center text-truncate",
                  {
                    "fs-12": !dynamicFont
                  }
                )}
              >
                <div className="mr-1 d-flex justify-content-center align-items-center">
                  <ConversationStatusProfileWithIcon
                    Icon={<FontAwesomeIcon icon={faEnvelope} />}
                    inline
                    hideName
                    status={conversation.Status}
                  />
                </div>

                {/* <div className="d-inline-flex align-items-center">
                <div className="position-relative d-inline-block mr-1">
                  <FontAwesomeIcon icon={faParkingCircle} />
                </div>
              </div> */}
                {/* <Button
                vType="link-primary"
                className="EntityLinkClassID fs-12 text-black"
                onClick={() => {
                  openForm({
                    type: moduleTypes.projects,
                    props: {
                      id: project.Id
                    }
                  });
                }}
              > */}
                <span>{conversation.Subject}</span>
                {/* </Button> */}
              </div>
            </>
          )}
        </div>
      </Link>
    );
  }
);

export const EmailConversationStatusProfile = ({
  item,
  status,
  hideName,
  className,
  BuildStrongerStatusType,
  dotClassName,
  inline,
  sensitive,
  pipelineName,
  value
}) => {
  const resovledStatus = item || value || status || 0;

  const SvgComponent = useMemo(() => {
    return EmailConversationStatusSvgIcons(resovledStatus, sensitive);
  }, [resovledStatus, sensitive]);

  const resolvedStatusType = BuildStrongerStatusType
    ? BuildStrongerStatusType(resovledStatus)
    : buildStatusType(resovledStatus, sensitive);

  return (
    <div
      className={classnames(
        `d-flex align-items-center flex-0-0-auto link-color text-truncate status-color-${resolvedStatusType}`,
        className
      )}
    >
      <div
        className={classnames("position-relative status-dot-svg", dotClassName)}
      >
        {SvgComponent}
      </div>
      {!hideName && (
        <div className="status-label flex-1 text-truncate fs-12">
          {pipelineName || statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};
