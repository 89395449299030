import React, { useContext, useMemo } from "react";
import { useSpace } from "../../Contexts/SpaceContext";
import { usePost } from "../../Helpers/IOClient";
import { faEnvelopeOpen } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUnreadEntitiesFetcher } from "../UnreadEntities/UnreadEntities";
import "./EntityReadButton.css";
import Button from "../Button/Button";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import { useMenuContext } from "../ContextMenu/ContextMenu";
import { EntityPopupContext } from "../EntityPopup/EntityPopupHelper";
import { useIntl } from "react-intl";

const EntityReadButton = ({ value, link, onSuccess, schema }) => {
  const { Id: spaceId } = useSpace();
	const intl = useIntl()
  const resolvedLink = useMenuContext() || link;
  const { IsRead, IsFollowing, Id } = value;
  const url = useMemo(() => {
    return `${schema.getEndpoint(spaceId)}/${Id}/${IsRead ? "Unread" : "Read"}`;
  }, [Id, IsRead, schema, spaceId]);
  const providedOnSuccess = useContext(EntityPopupContext);
  const text = IsRead ? intl.formatMessage({id: "MARK_SINGLE_AS_UNREAD"}) : intl.formatMessage({id: "MARK_SINGLE_AS_READ"});
  const fetch = useUnreadEntitiesFetcher();
  const [post, { loading }] = usePost(url, schema, {
    onSuccess: () => {
      onSuccess && onSuccess();
      providedOnSuccess && providedOnSuccess();
      fetch();
    },
  });

  const handleClick = () => {
    post();
  };
  if (!IsFollowing) return null;
  return (
    <Button
      // isLoading={loading}
      vType={resolvedLink ? "link" : undefined}
      className={resolvedLink ? "text-left" : undefined}
      disabled={loading}
      onClick={(e) => {
        handleClick();
        // e.stopPropagation();
      }}
    >
      <div className="ar-form-button-link-icon mr-1">
        {loading ? (
          <LoaderSpinner />
        ) : (
          <FontAwesomeIcon icon={faEnvelopeOpen} />
        )}
      </div>
      <span className="ar-read-button d-inline-block">{text}</span>
    </Button>
  );
};

export default EntityReadButton;
