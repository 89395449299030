import React from "react";
import { createCommunicatorHook } from "../../Helpers/Communicator";
import { originTypes } from "../../Helpers/MiscHelper";
import { getEntity } from "../../Helpers/IOClient";
import { spaceInterventionsSchema } from "../../config/schema";
import { BillingStatusTypes } from "../../Components/Billing/Billing";
import {
  InnerColorFilterBars,
  TriggeredColoredFilterProvider,
  useTriggeredColorFilters
} from "../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { InterventionFormButton } from "../Form/FormButtons";
import { ClassificationFormButton } from "./ClassificationForm";
import classnames from "classnames";
export const CLASSIFICATION_SUCCESS = "CLASSIFICATION_SUCCESS";

export const useClassificationCallback = createCommunicatorHook();

const getClassificationOrigin = (intervention) => {
  const {
    Project,
    Contract,
    Deal,
    Client,
    IndirectProject,
    IndirectContract,
    IndirectDeal,
    IndirectClient
  } = intervention;

  if (Project) {
    return {
      type: originTypes.project,
      data: Project
    };
  } else if (Contract) {
    return {
      type: originTypes.contract,
      data: Contract
    };
  } else if (Deal) {
    return {
      type: originTypes.deals,
      data: Deal
    };
  } else if (Client) {
    return {
      type: originTypes.client,
      data: Client
    };
  } else if (IndirectProject) {
    return {
      type: originTypes.project,
      data: IndirectProject
    };
  } else if (IndirectContract) {
    return {
      type: originTypes.contract,
      data: IndirectContract
    };
  } else if (IndirectDeal) {
    return {
      type: originTypes.deals,
      data: IndirectDeal
    };
  } else if (IndirectClient) {
    return {
      type: originTypes.client,
      data: IndirectClient
    };
  }
};

export const getDefaultOrganizationAndOrigin = (interventions) => {
  let Organization = null;
  let Target = null;

  let hasSetOrg = false;

  let hasSetTarget = false;

  for (const i of interventions) {
    const resolvedIntervention = getEntity(spaceInterventionsSchema, i);
    const iOrigin = getClassificationOrigin(resolvedIntervention);
    const { Organization: iOrg } = resolvedIntervention;
    ////;
    //orgs are diffrent, don't set org
    if (iOrg && !hasSetOrg) {
      if (Organization && iOrg !== Organization) {
        Organization = null;
        hasSetOrg = true;
      } else {
        Organization = iOrg;
      }
    }

    if (iOrigin && !hasSetTarget) {
      if (
        Target &&
        (Target.type !== iOrigin.type || Target.data !== iOrigin.data)
      ) {
        Target = null;
        hasSetTarget = true;
      } else {
        Target = iOrigin;
      }
    }
  }

  // if (!Target)
  //   Organization = getEntity(spaceInterventionsSchema, interventions[0])
  //     .Organization;

  return { Target, Organization };
};

export const getClassificationTargetDefaultStatus = (origin) => {
  switch (origin.type) {
    case originTypes.project:
      return BillingStatusTypes.UnderProject;

    case originTypes.contract:
      return BillingStatusTypes.UnderContract;

    case originTypes.client:
      return BillingStatusTypes.UnderClient;

    case originTypes.deals:
      return BillingStatusTypes.UnderDeal;

    default:
      break;
  }
};

export const ClassificationEntityGridButtons = ({
  entity,
  minimal,
  hideBreadCrumbs
}) => {
  const conds = useTriggeredColorFilters(entity);
  return (
    <TriggeredColoredFilterProvider conds={conds}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="d-flex align-items-center"
      >
        <InnerColorFilterBars />
        <InterventionFormButton
          className={classnames("mr-2 fs-14 d-inline-flex", {
            "ml-1": conds?.length > 0
          })}
          id={entity.Id}
          canEdit={entity.CanEdit}
          formProps={{
            NoMinDescription: true
          }}
        />
        <ClassificationFormButton
          id={entity.Billing && entity.Billing.Id}
          alt
          disabled={!entity.Billing}
        />
      </div>
    </TriggeredColoredFilterProvider>
  );
};
