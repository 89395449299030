import React, { useState, useEffect, useMemo, useRef } from "react";
import "./SpaceSelector.css";
import { injectIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { Link, Redirect } from "react-router-dom";
import AirdeskTextSvg from "../../Components/Svg/AirdeskTextSvg";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { useAccountSpace } from "../../Hooks/EntityHooks";
import { useSpaceEntity } from "../../Contexts/SpaceContext";
import { accountSummarySpaceSchema } from "../../config/schema";
import MaintenanceIcon from "../Maintenance/MaintenanceIcon";
import VirtualGrid from "../../Components/VirtualGrid/VirtualGrid";
import { VirtualGridRow } from "../../Components/VirtualGrid/VirtualGridBody";
import { useMultipleEntityValueSelector } from "../../Hooks/AdvancedEntityHooks";
import Button from "../../Components/Button/Button";
import { BillingPlansComponentDict } from "../BillingPlans/BillingPlanPayment";
import {
  getAccessLevel,
  accessLevels,
  isOwner
} from "../../Helpers/ModulesHelper";
import classnames from "classnames";
import {
  faRocketLaunch,
  faExclamationTriangle,
  faUser,
  faCloud
} from "@fortawesome/pro-regular-svg-icons";
import { useHoverEvents } from "../Origin/Origin";
import Popup from "../../Components/Popup/Popup";
import moment from "moment";
import TextEllipsis from "../../Components/TextEllipsis/TextEllipsis";
import { HandleStorageSizeComponent } from "../Spaces/SpaceList";
import { LogoutButton } from "../LogOutButton";

const SpaceStatusIconForSelector = ({ Data }) => {
  const { Space } = Data;
  // const currentUserAccessLevel = getAccessLevel(Data);
  const anchorRef = useRef();
  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  const text = useMemo(() => {
    if (Space.TrialEnd) {
      const timeLeft = moment(Space.TrialEnd).diff(moment(), "days");
      return (
        <div className="d-flex">
          <span className="mr-1">{timeLeft}</span>
          <FormattedMessage id="FREE_TRIAL_DAYS_LEFT" />
        </div>
      );
    } else if (Space.BlockType === 5) {
      return <FormattedMessage id="FREE_TRIAL_ENDED" />;
    } else if (Space.BlockType && Space.BlockType !== 0) {
      return <FormattedMessage id="SUBSCRIPTION_CANCELED" />;
    } else if (Space.PaymentFail && isOwner(Data)) {
      const timeLeft = moment(Space.PaymentFail.CancelDate).diff(
        moment(),
        "days"
      );
      return (
        <div>
          <FormattedMessage id="UPDATE_PAYMENT_METHOD_WARNING_PART_ONE" />
          <span> {timeLeft} </span>
          <FormattedMessage id="UPDATE_PAYMENT_METHOD_WARNING_PART_TWO" />
        </div>
      );
    } else return "";
  }, [Data, Space.BlockType, Space.PaymentFail, Space.TrialEnd]);

  const Icon = useMemo(() => {
    if (Space.TrialEnd) {
      return (
        <div className="text-primary fs-16">
          <FontAwesomeIcon icon={faRocketLaunch} />
        </div>
      );
    } else if (
      (Space.BlockType && Space.BlockType !== 0) ||
      Space.BlockType === 5
    ) {
      return (
        <div className="text-danger-alt fs-16">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
      );
    } else if (Space.PaymentFail && isOwner(Data)) {
      return (
        <div className="text-warning-alt fs-16">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
      );
    } else return <div></div>;
  }, [Data, Space.BlockType, Space.PaymentFail, Space.TrialEnd]);

  const InformationData = useMemo(() => {
    if (Space.TrialEnd) {
      return 1;
    } else if (
      (Space.BlockType && Space.BlockType !== 0) ||
      Space.BlockType === 5
    ) {
      return 2;
    } else if (Space.PaymentFail && isOwner(Data)) {
      return 3;
    } else return 0;
  }, [Data, Space.BlockType, Space.PaymentFail, Space.TrialEnd]);

  return (
    <>
      <Popup
        placement="top"
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <div
          className={classnames(
            "InformationTooltip px-2 py-1 fs-14 breakWord rounded",
            {
              DangerousTooltip: InformationData === 2,
              TrialEndTooltip: InformationData === 1,
              WarningPaymentAlmostUp: InformationData === 3
            }
          )}
        >
          {text}
        </div>
      </Popup>

      <div
        className="position-relative d-block"
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
        //   onMouseLeave={onAnchorMouseLeave}
        ref={anchorRef}
      >
        {Icon}
      </div>
    </>
  );
};

const SpaceSelectorItem = ({ accountSpaceId }) => {
  const accountSpace = useAccountSpace(accountSpaceId);
  const space = useSpaceEntity(accountSpace.Space);

  // const history = useHistory();

  return (
    <Link
      to={`/s/${space.Id}/dashboard`}
      //   className="selector-item d-flex align-items-center justify-content-between"
      key={space.Id}
    >
      <button
        onClick={() => {
          localStorage.setItem("Airdesk-Previous-Space", space.Id);
        }}
        className="ssi-button  ssi-control SpaceSelectorEntityClassID ssi-button-primary-ghost"
        style={{ height: 33, lineHeight: "14px", minWidth: 90 }}
      >
        <FormattedMessage id="SPACE_OPEN" />
      </button>

      {/* <span className="fs-18">{space.Name}</span>
      <FontAwesomeIcon icon={faAngleRight} className="text-primary" size="2x" /> */}
    </Link>
  );
};

const columns = [
  {
    field: "Id",

    sortable: false,
    orderBy: false,
    noTitle: true,
    title: "Id",
    Cell: ({ Data }) => <span className="text-black">{Data.Space.Id}</span>
  },
  {
    field: "Name",
    width: 250,
    sortable: false,
    orderBy: false,
    title: <FormattedMessage id="NAME" />,
    Cell: ({ Data }) => {
      return (
        <Link to={`/s/${Data.Space.Id}/dashboard`} key={Data.Space.Id}>
          <div className="text-black">
            <TextEllipsis length={150} truncate text={Data.Space.Name} />
          </div>
        </Link>
      );
    }
  },

  //   {
  //     field: "Dominio",
  //     width: 200,
  //     sortable: false,
  //     orderBy: false,
  //     title: <FormattedMessage id="DOMAIN" />,
  //     Cell: ({ Data }) => <div className="text-black">{Data.Domain}</div>,
  //   },
  {
    field: "Plan",

    sortable: false,
    HighAccess: true,
    orderBy: false,
    title: "Plan",
    Cell: ({ Data }) =>
      BillingPlansComponentDict[Data.Space.CurrentPlan] || null
  },
  {
    field: "Job",

    sortable: false,
    orderBy: false,
    title: "Job",
    Cell: ({ Data }) => {
      const currentUserAccessLevel = getAccessLevel(Data);
      return (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <div
            style={{
              borderRadius: "3px",
              color: "#7C98B6",
              fontWeight: 400,
              height: 34
            }}
            className="border fs-14 bg-white d-flex align-items-center justify-content-center px-2"
          >
            <FormattedMessage id={currentUserAccessLevel.toUpperCase()} />
          </div>
        </div>
      );
    }
  },
  {
    field: "Users",
    sortable: false,
    orderBy: false,
    HighAccess: true,
    title: "Users",
    Cell: ({ Data }) => {
      const currentUserAccessLevel = getAccessLevel(Data);
      if (
        currentUserAccessLevel === accessLevels.admin ||
        currentUserAccessLevel === accessLevels.owner
      ) {
        return (
          <div className="d-flex align-items-center">
            <div
              style={{
                background: "#E7F1FD",
                borderRadius: "50%",
                color: "#1E73F0",
                height: 30,
                width: 30
              }}
              className="d-flex align-items-center mr-3 justify-content-center"
            >
              <FontAwesomeIcon icon={faUser} />
            </div>
            <span className="text-black fs-14">
              {Data.Space.UsersAmount || 1}
            </span>
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  },
  {
    field: "Storage",
    HighAccess: true,
    sortable: false,
    orderBy: false,
    title: "Storage",
    width: 150,
    Cell: ({ Data }) => {
      const currentUserAccessLevel = getAccessLevel(Data);
      if (
        currentUserAccessLevel === accessLevels.admin ||
        currentUserAccessLevel === accessLevels.owner
      ) {
        const resolvedStorage = HandleStorageSizeComponent(
          Data.Space.StorageSize || 0
        );
        return (
          <div className="d-flex align-items-center">
            <div
              style={{
                background: "#E7F1FD",
                borderRadius: "50%",
                color: "#1E73F0",
                height: 30,
                width: 30
              }}
              className="d-flex align-items-center mr-3 justify-content-center"
            >
              <FontAwesomeIcon icon={faCloud} />
            </div>
            <span className="text-black fs-14">{resolvedStorage}</span>
          </div>
        );
      } else return <div></div>;
    }
  },

  {
    field: "Button",

    sortable: false,
    orderBy: false,
    title: "Button",
    Cell: ({ Data }) => (
      <SpaceSelectorItem key={Data.Id} accountSpaceId={Data.Id} />
    )
  },
  {
    field: "Status",
    sortable: false,
    orderBy: false,
    title: "Status",
    Cell: ({ Data }) => {
      if (
        Data.Space.TrialEnd ||
        (Data.Space.BlockType && Data.Space.BlockType !== 0) ||
        (Data.Space.BlockType === 0 && !Data.Space.TrialEnd)
      ) {
        return (
          <div className="w-100 d-flex justify-content-center pr-4 align-items-center">
            <SpaceStatusIconForSelector Data={Data} />
          </div>
        );
      } else return <div></div>;
    }
  }
];

const accountSpaceSelector = (v, link) => {
  const spaceData = link("Space");
  return { ...v, Space: spaceData };
};

const LogTr = React.memo(({ value, ...rest }) => {
  return (
    <VirtualGridRow
      // className={call && call.IsRead === false && "unread"}
      value={value}
      {...rest}
      // canSelectRow={canSelectRow}
    />
  );
});

const getKey = (v) => v.Id;

const SpaceSelectorBody = injectIntl(({ user, intl }) => {
  // const {data, loading, error} = useQuery(`spaces/List`, [spacesListSchema])
  const userAccountSpaces = user.AccountSpaces;
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTemp, setSearchQueryTemp] = useState("");
  const Spaces = useMultipleEntityValueSelector(
    accountSummarySpaceSchema,
    userAccountSpaces,
    accountSpaceSelector
  );

  const handleTempChange = (e) => {
    setSearchQueryTemp(e.target.value);
  };

  useEffect(() => {
    const setChanges = setTimeout(() => {
      setSearchQuery(searchQueryTemp);
    }, 200);
    return () => {
      clearTimeout(setChanges);
    };
  }, [searchQueryTemp]);

  const FilteredSpaces = useMemo(() => {
    return Spaces.filter((e) =>
      e.Space.Name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [Spaces, searchQuery]);

  return (
    <>
      <div className="mb-4 fs-32 text-black text-align-center">
        {intl.formatMessage({ id: "WELCOME_USERNAME" })}, {user.Name}!
      </div>
      <div className="w-100 max-w-1200 mb-1 d-flex justify-content-between align-items-center">
        <span className="text-black fs-22">
          <FormattedMessage id="WORKSPACE" />
        </span>
        <div className="d-flex align-items-center">
          <div className="ssi-control rounded ar-server-aware-input d-flex align-items-center p-2 mr-2">
            <div className="ar-server-aware-input-icon mr-2">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <input
              onChange={handleTempChange}
              className="ssi-input ssi-control flat-input p-0"
              id="ServerInputQueryBar"
              placeholder={`${intl.formatMessage({ id: "SEARCH" })}...`}
              value={searchQueryTemp}
            />
          </div>
          <Link style={{ textDecoration: "none" }} to="s/create">
            <Button>
              <FormattedMessage id="CREATE_NEW_WORKSPACE" />
            </Button>
          </Link>
        </div>
      </div>
      <div className="d-flex flex-column max-w-1200 flex-1 overflow-auto">
        <VirtualGrid
          className="w-100 ar-main-grid"
          columns={columns}
          RowComponent={LogTr}
          disableHeader={true}
          getKey={getKey}
          disableBorder={true}
          disableBackground={true}
          isLoading={false}
          height={50}
          data={FilteredSpaces}
        />
        {/* <div className="fs-14 mb-3">
          {intl.formatMessage({ id: "WORKSPACE" })}
        </div>
        <div className="selector-item-container">
          {user.AccountSpaces.map((AccountSpaceId) => (
            <SpaceSelectorItem
              key={AccountSpaceId}
              accountSpaceId={AccountSpaceId}
            />
          ))}
        </div> */}
      </div>
    </>
  );
});

const SpaceSelector = () => {
  const user = useCurrentAccount();
  if (!user || !user.AccountSpaces) return null;
  if (user.AccountSpaces.length === 0) return <Redirect to="/s/create" />;

  if (localStorage.getItem("Airdesk-Previous-Space")) {
    return (
      <Redirect to={`/s/${localStorage.getItem("Airdesk-Previous-Space")}`} />
    );
  }

  // if (user.AccountSpaces.length === 1) {
  //   const AccountSpace = getEntity(
  //     accountSummarySpaceSchema,
  //     user.AccountSpaces[0]
  //   );
  //   let spaceId = AccountSpace.Space;
  //   return <Redirect to={`/s/${spaceId}`} />;
  // }

  return (
    <div className="d-flex position-relative p-4 flex-column align-items-center h-100">
      <MaintenanceIcon absolute />
      <div className="mt-2 mr-2 LogOutButton-SelectorSpace Logout-button">
        <LogoutButton />
      </div>
      <div
        style={{ paddingTop: "5rem" }}
        className="mb-3 mx-auto d-block airdesk-text-svg airdesk-logo"
      >
        <AirdeskTextSvg />
      </div>
      {/* <AirdeskIconSvg className="selector-icon mb-5" /> */}

      <SpaceSelectorBody user={user} />
    </div>
  );
};

// class SpaceSelector extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   renderSelection = () => {
//     const { CurrentUser, Accounts, Spaces, AccountSpaces, intl } = this.props;
//     intl.formatMessage({ id: "phone" });
//     if (CurrentUser.accountId === null)
//       return <LoaderSpinner className="text-primary" size="lg" />;

//     let user = Accounts[CurrentUser.accountId];

//     if (user.AccountSpaces.length === 0) return <Redirect to="/s/create" />;
//     if (user.AccountSpaces.length === 1) {
//       let AccountSpace = AccountSpaces[user.AccountSpaces[0]];
//       let space = Spaces[AccountSpace.Space];
//       return <Redirect to={`/s/${space.Id}`} />;
//     }

//     return (
//       <div>
//         <div className="mb-3 fs-28 fw-light">
//           {intl.formatMessage({ id: "welcome_back" })}, {user.Name}!
//         </div>
//         <div className="w-100 max-w-540px">
//           <div className="fs-14 mb-3">
//             {intl.formatMessage({ id: "select_workspace" })}
//           </div>
//           <div className="selector-item-container">
//             {user.AccountSpaces.map(AccountSpaceId => {
//               let AccountSpace = AccountSpaces[AccountSpaceId];
//               let space = Spaces[AccountSpace.Space];

//               return (
//                 <Link
//                   to={`/s/${space.Id}`}
//                   className="selector-item d-flex align-items-center justify-content-between"
//                   key={space.Id}
//                 >
//                   <span className="fs-18">{space.Name}</span>
//                   <FontAwesomeIcon
//                     icon={faAngleRight}
//                     className="text-primary"
//                     size="2x"
//                   />
//                 </Link>
//               );
//             })}
//           </div>
//         </div>
//       </div>
//     );
//   };

//   render() {
//     return (
//       <div className="d-flex flex-column align-items-center justify-content-center min-h-100">
//         <AirdeskTextSvg className="mb-5 airdesk-text-svg airdesk-logo" />
//         {/* <AirdeskIconSvg className="selector-icon mb-5" /> */}

//         {this.renderSelection()}
//       </div>
//     );
//   }
// }

// const mapStateToProps = state => {
//   return {
//     Spaces: state.Entities.Spaces,
//     Accounts: state.Entities.Accounts,
//     AccountSpaces: state.Entities.AccountSpaces,
//     CurrentUser: state.CurrentUser
//   };
// };

export default injectIntl(SpaceSelector);
