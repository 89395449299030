import React, { useState, useMemo, useCallback } from "react";
import LoadableButton from "../../Components/Button/LoadableButton";
import Button from "../../Components/Button/Button";
import {
  spaceCallsSchema,
  spaceTasksSchema,
  spaceTicketsSchema
} from "../../config/schema";
import { getEntityFromState } from "../../Helpers/ReduxHelper";
import store from "../../store";
import { useToast } from "../../Components/Toast/ToastProvider";
import { ConnectedBreadcrumbs } from "../../Components/Breadcrumbs/Breadcrumbs";

import {
  SideFormContent,
  SideMenuFormFooterBase
} from "../../Components/Forms/SideMenuForm";

import { handleError, originTypes } from "../../Helpers/MiscHelper";

import { PipelineStatusDropdown } from "../AdvancedMultiInputs/PipelineDropdowns";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";
import Checkbox from "../../Components/Checkbox/Checkbox";
import { getEntity, useSchemaQuery } from "../../Helpers/IOClient";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import { ProjectStatusTypes } from "../Projects/ProjectsStatusHelper";
import { FormattedMessage, useIntl } from "react-intl";
import { useEntityValue } from "../../Hooks/EntityHooks";
import { Link } from "react-router-dom";
import { useSpace } from "../../Contexts/SpaceContext";

export const canUpdateEntity = (schema, key) => {
  const entity = getEntityFromState(store.getState(), schema, key);
  return entity.CanUpdateStatus;
};

const config = {
  [originTypes.ticket]: {
    schema: spaceTicketsSchema,
    toastTitle: ({ id }) => `Ticket ${id}`,
    status: ProjectStatusTypes.finished,
    getEndpoint: ({ id }) => `tickets/${id}/status`,
    originType: originTypes.ticket,
    footerText: "CONCLUDE_TICKET_ASK"
  },
  [originTypes.call]: {
    schema: spaceCallsSchema,
    toastTitle: ({ id }) => `Chamada ${id}`,
    status: ProjectStatusTypes.finished,
    getEndpoint: ({ id }) => `calls/${id}/status`,
    originType: originTypes.call,
    footerText: "CONCLUDE_CALL_ASK"
  },
  [originTypes.task]: {
    schema: spaceTasksSchema,
    toastTitle: ({ id }) => `Tarefa ${id}`,
    status: ProjectStatusTypes.finished,
    getEndpoint: ({ id }) => `tasks/${id}/status`,
    originType: originTypes.task,
    footerText: "CONCLUDE_TASK_ASK"
  }
};

const buildConclusionItemState = (props) => {
  const { ticket, call, task } = props;

  if (ticket) return { type: originTypes.ticket, data: ticket };
  else if (call) return { type: originTypes.call, data: call };
  else if (task) return { type: originTypes.task, data: task };
  else return null;
};

const buildConclusionItemStateFromEntity = (entity) => {
  const { Ticket, Call, Task } = entity;

  if (Ticket) return { type: originTypes.ticket, data: Ticket };
  else if (Call) return { type: originTypes.call, data: Call };
  else if (Task) return { type: originTypes.task, data: Task };
  else return null;
};

const useCustomPipelineSettings = (item) => {
  return useMemo(() => {
    switch (item.type) {
      case originTypes.ticket:
        return {
          PipelineType: PipelineTypeEnum.Ticket
          // BaseStatus: TicketsStatusTypes.closed
        };

      case originTypes.call:
        return {
          PipelineType: PipelineTypeEnum.Call
          // BaseStatus: CallStatusTypes.closed
        };

      case originTypes.task:
        return {
          PipelineType: PipelineTypeEnum.Task
          // BaseStatus: TasksStatusTypes.closed
        };

      default:
        return {};
    }
  }, [item.type]);
};

const useEntityText = (item) => {
  const intl = useIntl();
  return useMemo(() => {
    switch (item.type) {
      case originTypes.ticket:
        return intl.formatMessage({ id: "CASE" });

      case originTypes.call:
        return intl.formatMessage({ id: "CALL" });

      case originTypes.task:
        return intl.formatMessage({ id: "TASK" });

      default:
        return null;
    }
  }, [intl, item.type]);
};

const useConclusionPipeline = (schema, id) => {
  const { data } = useSchemaQuery(schema, id);

  const pipelineId = useMemo(() => {
    if (!data) return null;

    const v = getEntity(schema, id);
    return v.Pipeline;
  }, [data, id, schema]);
  return pipelineId;
};

export const ConclusionForm = (props) => {
  const baseState = useMemo(() => buildConclusionItemState(props), [props]);
  const [item, setItem] = useState(baseState);
  const intl = useIntl();
  const originSettings = config[item.type];
  const { getEndpoint, schema, toastTitle, footerText } = originSettings;

  const { data: id } = item;
  const entity = useEntityValue(schema, id);
  const { HasConversation } = entity;
  const pipelineId = useConclusionPipeline(schema, id);
  // const pipelineId = useMemo(() => {
  //   const v = getEntity(schema, id);
  //   return v.Pipeline;
  // }, [id, schema]);

  const { PipelineType, BaseStatus } = useCustomPipelineSettings(item);

  const [isRemoving, setIsRemoving] = useState(false);

  const [pipelineStatus, setPipelineStatus] = useState(null);

  const createToast = useToast();
  const [, closeSidebar] = useSidebar();

  const entityText = useEntityText(item);

  const [post, { loading }] = useSidebarSpacePost(getEndpoint({ id }), schema, {
    onSuccess: ({ openSidebar, disableClosure, data }) => {
      createToast({
        pos: "tm",
        type: "success",
        description: intl.formatMessage({ id: "SUCCESSFULLY_CONCLUDED" }),
        title: toastTitle({ id })
      });

      ////;
      const currentClosedEntity = getEntityFromState(
        store.getState(),
        schema,
        data
      );

      const origin = buildConclusionItemStateFromEntity(currentClosedEntity);

      if (!origin) return;
      const { type, data: originId } = origin;

      const originSchema = config[type].schema;
      const canUpdate = canUpdateEntity(originSchema, originId);

      if (!canUpdate) return;

      setItem(origin);
      setIsRemoving(false);
      setPipelineStatus(false);
      disableClosure();
    },
    onError: ({ error }) => {
      handleError(createToast, error);
    }
  });

  const submit = () => {
    post({ PipelineStatusId: pipelineStatus });
  };

  const handlePipelineStatusChange = useCallback((ps) => {
    setPipelineStatus(ps);
  }, []);

  const handleCheck = useCallback((e) => {
    setIsRemoving(e.target.checked);
  }, []);
  const space = useSpace();

  const conversationUrl = useMemo(() => {
    if (!HasConversation) return "";
    else {
      if (schema === spaceTicketsSchema) {
        return `/s/${space.Id}/inbox/1/?%24filter=Ticket%2FId%20eq%20%27${id}%27&%24top=50&basicFilter=0&conversationTypes=1&entityNoteType=0`;
      } else if (schema === spaceTasksSchema) {
        return `/s/${space.Id}/inbox/1/?%24filter=Task%2FId%20eq%20%27${id}%27&%24top=50&basicFilter=0&conversationTypes=1&entityNoteType=0`;
      } else return "";
    }
  }, [HasConversation, id, schema, space.Id]);

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage
          id="CONCLUDE_ENTITY"
          values={{ entityText: entityText }}
        />
      </BaseSidebarHeader>
      <BaseSidebarContent className="d-flex flex-column">
        <SideFormContent className="px-4 py-3">
          <div className="d-flex w-100 flex-column h-100 justify-content-center">
            <div className="fw-medium text-black fs-14">
              <FormattedMessage id="ORIGIN" />
            </div>
            <ConnectedBreadcrumbs value={baseState} highlightValue={item} />

            <hr className="w-100" />
            <div className="mb-3 fw-medium fs-14 text-black">
              <FormattedMessage id="PIPELINE_STATUS" />
            </div>
            <Checkbox
              className="mb-2"
              checked={isRemoving}
              onChange={handleCheck}
              textValue={`${intl.formatMessage(
                {
                  id: "CONCLUDE_ENTITY"
                },
                {
                  entityText: entityText
                }
              )}`}
            />
            {/* <span className="text-black ml-2 fs-14">Concluir Entidade</span> */}

            {pipelineId && (
              <PipelineStatusDropdown
                // onLoad={({ data }) => {
                //   //////
                //   setPipelineStatus(data[0])
                // }}
                pipelineId={pipelineId}
                value={pipelineStatus}
                disabled={!isRemoving}
                onChange={handlePipelineStatusChange}
                Status={BaseStatus}
                Type={PipelineType}
              />
            )}

            <div />

            {Boolean(HasConversation) && (
              <div className="my-3 text-black">
                <FormattedMessage
                  id="EXISTING_EMAIL_CONVERSATIONS"
                  values={{
                    br: (...chunks) => <br />,
                    a: (...chunks) => (
                      <Link className="link-primary" to={conversationUrl}>
                        {chunks}
                      </Link>
                    )
                  }}
                />
              </div>
            )}
          </div>
        </SideFormContent>
        <SideMenuFormFooterBase className="d-flex align-items-center justify-content-between">
          <div className="text-black">
            {intl.formatMessage({ id: footerText })}
          </div>

          <div className="d-flex ml-3">
            <Button
              vType="outline-danger"
              className="mr-3"
              disabled={loading}
              onClick={closeSidebar}
            >
              <FormattedMessage id="NO" />
            </Button>
            <LoadableButton
              disabled={!isRemoving || !pipelineStatus}
              isLoading={loading}
              onClick={submit}
              vType="primary-ghost"
            >
              <FormattedMessage id="CONCLUDE" />
            </LoadableButton>
          </div>
        </SideMenuFormFooterBase>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};
