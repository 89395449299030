import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useMemo,
  Suspense,
  useState
} from "react";
import { ItemId, TreeItem } from "@atlaskit/tree";
import { AppTreeSetValueContext, TreeData } from "./AppBarHelper";
import { v1 as uuidv1 } from "uuid";
import classnames from "classnames";
import classes from "./AppBar.module.css";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { FormattedMessage, useIntl } from "react-intl";
import FavoriteIconPicker from "../../Components/FavoriteIconPicker/FavoriteIconPicker";
import LabeledInput from "../../Components/Input/LabeledInput";
import FavoriteTypePicker, { AppBarCurrentType } from "./FavoriteTypePicker";
import Button from "../../Components/Button/Button";

export const FontAwesomeIconsProvider = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FontAwesomeIconsProvider" */ "../../Components/FavoriteIconPicker/FontAwesomeIconsProvider"
    )
);

export interface IFavoriteCreatorProps {
  type?: FavoriteCreatorTypes;
  defaultParent?: TreeItem;
  onClose?: () => void;
  children?: any;
}

export enum FavoriteCreatorTypes {
  folder = "folder",
  favorite = "favorite"
}

const FavoriteCreator: FC<IFavoriteCreatorProps> = ({
  type,
  onClose,
  defaultParent,
  children
}) => {
  const intl = useIntl();

  const [titleId, subTextId] = useMemo(() => {
    if (type === FavoriteCreatorTypes.folder) {
      return ["NEW_FOLDER", "NEW_FOLDER_TEXT"];
    } else return ["NEW_FAVORITE", "NEW_FAVORITE_TEXT"];
  }, [type]);
  const setTree = useContext(AppTreeSetValueContext) as Dispatch<
    SetStateAction<TreeData>
  >;
  const [color, setColor] = useState<string>("#232357");
  const [icon, setIcon] = useState<string>("fas folder");
  const [name, setName] = useState("");
  const [entityType, setEntityType] = useState<AppBarCurrentType | undefined>(
    undefined
  );
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
  };

  const handleFolderSubmit = () => {
    setTree((oldTree) => {
      const newTree = { ...oldTree };
      const { rootId, items } = newTree;
      const newItems = { ...items } as Record<ItemId, TreeItem>;
      const newRootItem = newItems[rootId] as TreeItem;
      const newRootItemChildren = newRootItem.children;
      const newId = uuidv1();
      if (!defaultParent) newRootItemChildren.unshift(newId);
      newRootItem.children = newRootItemChildren;
      const newChild = {
        id: newId,
        children: [],
        hasChildren: false,
        isExpanded: false,
        isChildrenLoading: false,
        data: { title: name, iconText: icon, background: color, isFolder: true }
      } as TreeItem;
      newItems[rootId] = newRootItem;
      newItems[newId] = newChild;
      if (defaultParent) {
        const { id } = defaultParent;
        const currentParent = { ...newItems[id] };
        const parentChildren = [...currentParent.children];
        parentChildren.push(newId);
        currentParent.children = parentChildren;
        newItems[id] = currentParent;
      }
      return {
        rootId,
        items: newItems
      };
    });
    onClose && onClose();
  };

  const handleFavoriteSubmit = () => {
    setTree((oldTree) => {
      const newTree = { ...oldTree };
      const { rootId, items } = newTree;
      const newItems = { ...items } as Record<ItemId, TreeItem>;
      const newRootItem = newItems[rootId] as TreeItem;
      const newRootItemChildren = newRootItem.children;
      const newId = uuidv1();
      if (!defaultParent) newRootItemChildren.unshift(newId);
      newRootItem.children = newRootItemChildren;
      const newChild = {
        id: newId,
        children: [],
        hasChildren: false,
        isExpanded: false,
        isChildrenLoading: false,
        data: {
          title: name,
          ...entityType
        }
      };
      newItems[rootId] = newRootItem;
      newItems[newId] = newChild;
      if (defaultParent) {
        const { id } = defaultParent;
        const currentParent = { ...newItems[id] };
        const parentChildren = [...currentParent.children];
        parentChildren.push(newId);
        currentParent.children = parentChildren;
        newItems[id] = currentParent;
      }
      return {
        rootId,
        items: newItems
      };
    });
    onClose && onClose();
  };

  const handleSubmition = () => {
    if (type === FavoriteCreatorTypes.folder) {
      handleFolderSubmit();
    } else handleFavoriteSubmit();
  };

  const isDisabled = useMemo(() => {
    if (type === FavoriteCreatorTypes.favorite) {
      if (entityType && name.replace(/\s/g, "").length) return false;
      else return true;
    } else {
      if (name.replace(/\s/g, "").length) return false;
      else return true;
    }
  }, [entityType, name, type]);

  return (
    <div
      className={classnames(classes.AppBarFavoriteCreatorContainer, {
        "h-100": type === FavoriteCreatorTypes.favorite,
        "p-4": type === FavoriteCreatorTypes.folder,
        "pb-4 pt-2": type === FavoriteCreatorTypes.favorite
      })}
    >
      <Suspense fallback={<LoaderSpinner center size="sm" />}>
        <FontAwesomeIconsProvider>
          {type === FavoriteCreatorTypes.folder && (
            <>
              <div className="text-black fw-bold fs-18 mb-2">
                <FormattedMessage id={titleId} />
              </div>
              <div className="text-black fs-18 mb-4">
                <FormattedMessage id={subTextId} />
              </div>
              <div className="d-flex align-items-end mb-4">
                <FavoriteIconPicker
                  icon={icon}
                  setIcon={setIcon}
                  setColor={setColor}
                  color={color}
                />
                <LabeledInput
                  autoComplete="off"
                  text={intl.formatMessage({ id: "NAME" })}
                  className={classnames("flex-1", {
                    "ml-2": type === FavoriteCreatorTypes.folder
                  })}
                  name="Name"
                  onChange={handleNameChange}
                  value={name}
                />
              </div>
            </>
          )}
          {type === FavoriteCreatorTypes.favorite && (
            <div className="d-flex flex-column">
              <FavoriteTypePicker
                handleNameChange={handleNameChange}
                name={name}
                currentEntityType={entityType}
                handleChange={setEntityType}
              />
            </div>
          )}
          <div className="flex-1" />
          <div
            className={classnames(
              "d-flex align-items-center justify-content-end",
              {
                "px-4": type === FavoriteCreatorTypes.favorite
              }
            )}
          >
            <Button vType="link-danger" className="mr-3" onClick={onClose}>
              <FormattedMessage id="CANCEL" />
            </Button>
            <Button disabled={isDisabled} onClick={handleSubmition}>
              <FormattedMessage id="SUBMIT" />
            </Button>
          </div>
        </FontAwesomeIconsProvider>
      </Suspense>
    </div>
  );
};

export default FavoriteCreator;
