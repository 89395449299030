import React, { useMemo } from "react";
import { ConnectedAccountProfileStack } from "../Accounts/AccountProfileStack";

const AccountsInRoomStack = ({ accountsInRoom, dontShowLine, ...props }) => {
  const resolvedAccounts = useMemo(() => {
    if (!accountsInRoom || accountsInRoom.length === 0) return null;

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    return accountsInRoom.filter(onlyUnique);
  }, [accountsInRoom]);

  if (!accountsInRoom || accountsInRoom.length === 0)
    return !dontShowLine ? <div>―</div> : null;

  return <ConnectedAccountProfileStack accounts={resolvedAccounts} />;
};

export const StaticAccountsInRoomStack = ({
  accountsInRoom = [],
  width,
  dontShowLine,
  disableMessages
}) => {
  if (disableMessages) return null;

  return (
    <div className="px-3" style={{ width }}>
      {!accountsInRoom || accountsInRoom.length === 0 ? (
        !dontShowLine && <div className="float-right">―</div>
      ) : (
        <ConnectedAccountProfileStack
          className="float-right"
          accounts={accountsInRoom}
        />
      )}
    </div>
  );
};

export default AccountsInRoomStack;
