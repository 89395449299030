import React from "react";

export const HeightContext = React.createContext<number>(0);

export interface VirtualGridColumnProps {
  Cell: (props: any) => any;
  title: string | any;
  width: number;
  field: string;
  sortable?: boolean;
  noTitle?: boolean;
  selectable?: boolean;
  minSize?: number;
  TotalCell?: any;
  sticky?: boolean;
  headerIcon?: any;
}

export const getResolvedColumnWidth = (column: any, columnSize: any) => {
  const { minSize, width } = column;

  let rColumnSize = typeof columnSize === "number" ? columnSize : undefined;

  let resolvedWidth = rColumnSize || width;

  if (resolvedWidth < minSize) resolvedWidth = minSize;

  return resolvedWidth;
};
