import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo
} from "react";
import "./Dropdown.css";
import "./AltDropdown.css";
import DropdownBase from "../_Dropdown/DropdownBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { isEquivalent } from "../../Helpers/MiscHelper";
import classnames from "classnames";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage } from "react-intl";

/**
 * @param {function} onTextChange - callback for text change
 * @param {function} onChange - callback when item is selected
 * @param {function} onOpen - callback when popup is opened
 * @param {function} onClose - callback when popup is close
 * @param {bool} multiple - allows multiple values on the input
 * @param {bool} serverFiltering - if set to true list won't filter automatically when typing
 * @param {bool} enableSearch - enables search
 * @param {bool} freeText - enables free text input (ignores items and server filtering)
 * @param {bool} excludeOnSelect - excludes item from list when selecting (when multiple is enabled)
 * @param {bool} serverFiltering - disables local filtering and value set
 * @param {bool} isLoading - enables list loading
 * @param {bool} closeOnSelect - closes popup when selecting an item
 * @param {bool} allowToAddDuplicateValues - allows duplicate values (when multiple is enabled)
 * @param {Component} loadingComponent - loading component when isLoading is enabled
 * @param {Component} noDataComponent - component for list when there are no items
 * @param {Component} valueComponent - component for input
 * @param {Component} listComponent - component for list
 * @param {Component} listItemComponent - component for list item
 * @param {Component} headerComponent - component for list column (data has to be sent from COLUMN props)
 * @param {bool} disabled - Disable dropdown
 */

class Dropdown extends Component {
  constructor(props) {
    super(props);

    let columns = props.columns || props.items || [];

    if (columns.length > 0 && typeof columns[0] !== "object") columns = [];

    this.state = {
      processedGuid: "b42d6598-6c8b-4b29-87e3-662f8f5da03a",
      isOpened: false,
      value: null,
      columns,
      filterText: "",
      isTyping: false,
      lastValue: undefined
    };

    this.popupRef = React.createRef();
    this.labelRef = React.createRef();

    this.currentHint = null;
    this.hints = [];

    this.hintContextValue = {
      setHint: this.setHint,
      updateHint: this.updateHint
    };
  }
  showMenu = (event) => {
    // event.preventDefault();

    // const { isOpened } = this.state;

    // if (isOpened) {
    //   this.setState({
    //     isOpened: false
    //   });
    //   document.removeEventListener("click", this.handleClosure);
    //   return;
    // }
    if (this.props.closeOnClickWhileOpen && this.state.isOpened) this.close();

    if (this.state.isOpened) return;

    if (this.props.freeText && this.props.multiple) return;
    if (!event.target.closest(".fa-times")) {
      this.setState({ isOpened: true });
      document.addEventListener("click", this.handleClosure);

      const { onOpen } = this.props;

      if (onOpen) onOpen();
    }
  };

  handleClosure = (e) => {
    if (this.popupRef.current == null) return;

    if (
      this.popupRef.current === e.target ||
      this.popupRef.current.contains(e.target) ||
      this.labelRef.current === e.target ||
      this.labelRef.current.contains(e.target)
    )
      return;
    this.close();
  };

  close = () => {
    this.hintIndex = -1;
    if (this.labelRef.current && this.labelRef.current.querySelector("input"))
      this.labelRef.current.querySelector("input").blur();
    this.setState({ isOpened: false, filterText: "", isTyping: false });
    document.removeEventListener("click", this.handleClosure);

    const { onClose } = this.props;

    if (onClose) onClose();
  };

  inputKeyDownHandler = (e) => {
    const { freeText, multiple, value } = this.props;

    switch (e.keyCode) {
      case 8: //Backspace
        if (value && value.length > 0 && e.currentTarget.value === "") {
          this.removeItem(value.length - 1, e);
          setTimeout(() => {
            this.labelRef.current.querySelector("input").focus();
          }, 50);
        }
        break;
      case 13: //Enter
        if (freeText && multiple) this.addFreeText(e.currentTarget.value);
        break;
      default:
        break;
    }
  };

  inputFocusHandler = (e) => {
    this.showMenu(e);
    // if (!(this.props.freeText && this.props.multiple) && !this.state.isOpened) {
    //   this.showMenu();
    //   // this.setState({ isOpened: true });
    // }
  };

  textChangeHandler = (e) => {
    const text = e.target.value;
    const { onTextChange, serverFiltering, freeText, multiple, isTextValid } =
      this.props;

    if (isTextValid && !isTextValid(text)) return;

    if (onTextChange) onTextChange(text);

    if (serverFiltering || (freeText && multiple)) {
      this.setState({ filterText: text, isTyping: true });
    } else this.filterItems(text);
  };

  filterItems = (text) => {
    const { columns: itms } = this.state;

    const stateItems = [...itms];

    const items = this.getFilteredItems(stateItems, text);

    this.setState({ items, filterText: text, isTyping: true });
  };

  handleChange = (value, removing) => {
    const {
      onChange,
      closeOnSelect,
      onTextChange,
      clearTextOnChanges,
      serverFiltering,
      freeText,
      multiple
    } = this.props;

    if (onChange) {
      // this.setState({ isTyping: false });
      let retValue;
      if (Array.isArray(value)) {
        // if (isColumn)
        //   retValue = value.map(item => {
        //     return { data: item.data, type: item.type };
        //   });
        // else retValue = value.map(item => item.data);
        retValue = value;
      } else {
        // if (isColumn)
        //   retValue = value ? { data: value.data, type: value.type } : null;
        // else
        retValue = value;
      }

      // if (name) {
      //   onChange({
      //     target: {
      //       name,
      //       value: retValue
      //     }
      //   });
      // } else onChange(retValue);

      onChange(retValue);
      // this.close();
    }

    if (clearTextOnChanges && onTextChange) onTextChange("");

    if (!removing) {
      if (!serverFiltering)
        this.setState({
          value,
          filterText: "",
          isTyping: false,
          isOpened: !(freeText && multiple) && !closeOnSelect
        });
      else
        this.setState({
          filterText: "",
          isTyping: false,
          isOpened: !(freeText && multiple) && !closeOnSelect
        });
      if (multiple) this.labelRef.current.querySelector("input")?.focus();
    }
  };

  addItem = (item, field, type) => {
    const { multiple, allowToAddDuplicateValues } = this.props;

    let objItem = {};
    if (multiple) {
      let value = Array.isArray(this.props.value) ? [...this.props.value] : [];
      if (!allowToAddDuplicateValues) {
        if (!value || !Array.isArray(value)) value = [];
        for (let k = 0; k < value.length; k++) {
          if (value[k] === item) {
            return;
          } else if (
            typeof item === "object" &&
            typeof item === typeof value[k] &&
            isEquivalent(item, value[k])
          ) {
            return;
          }
        }
      }

      objItem = item;

      value.push(objItem);

      this.handleChange(value);
    } else {
      if (item === undefined || item === null) objItem = null;
      else objItem = item;

      this.handleChange(objItem);
    }
  };
  addFreeText = (text) => {
    let valueArr = this.state.value ? [...this.state.value] : [];
    if (!this.props.allowToAddDuplicateValues) {
      for (let i = 0; i < valueArr.length; i++) {
        if (valueArr[i].value === text) return;
      }
    }
    valueArr.push({ data: text, value: text });
    this.handleChange(valueArr);
  };

  removeItem = (index, e) => {
    e.preventDefault();
    let valueArr = this.props.value ? [...this.props.value] : [];
    valueArr.splice(index, 1);

    this.handleChange(valueArr, true);
    if (this.state.isOpened) this.close();
  };

  getFilteredItems = (items, filter) => {
    const { value, isColumn } = this.state;
    const { enableSearch, excludeOnSelect, serverFiltering, getValue } =
      this.props;
    if (
      (!enableSearch && !excludeOnSelect) ||
      (!filter && (!value || value.length === 0)) ||
      serverFiltering
    )
      return items;

    if (items && items.length > 0 && typeof items !== "object") {
      items = items.map((item) => getValue(item));
    }

    let returnCols = [];

    if (isColumn) {
      for (let i = 0; i < items.length; i++) {
        let ittms = [];
        const field = items[i].field;
        if (!field) continue;
        for (let j = 0; j < items[i].items.length; j++) {
          if (
            items[i].items[j][field] !== undefined &&
            items[i].items[j][field]
              .toLowerCase()
              .includes(filter.toLowerCase())
          ) {
            if (excludeOnSelect && value && value.length > 0) {
              let isSelected = false;
              for (let k = 0; k < value.length; k++) {
                if (isEquivalent(items[i].items[j], value[k].data)) {
                  isSelected = true;
                  break;
                }
              }
              if (!isSelected) ittms.push(items[i].items[j]);
            } else ittms.push(items[i].items[j]);
          }
        }
        let col = { ...items[i] };
        col.items = [...ittms];
        if (ittms.length > 0) returnCols.push(col);
      }
    } else {
      return items.reduce((array, item) => {
        if (
          item.Name !== undefined &&
          item.Name.toLowerCase().includes(filter.toLowerCase())
        ) {
          if (excludeOnSelect && value && value.length > 0) {
            let isSelected = false;
            for (let k = 0; k < value.length; k++) {
              if (isEquivalent(item, value[k].data)) {
                isSelected = true;
                break;
              }
            }
            if (!isSelected) array.push(item);
          } else array.push(item);
        }
        return array;
      }, []);
    }

    return returnCols;
  };

  setHint = (ref, func) => {
    const hintItem = {
      dom: ref.current,
      func
    };

    this.hints.push(hintItem);

    const unsubscribe = () => {
      const index = this.hints.indexOf(hintItem);
      if (this.currentHint && this.currentHint === hintItem) {
        this.currentHint.dom.classList.remove("ar-dropdown-list-button-hint");
      }
      this.currentHint = null;
      this.hints.splice(index, 1);
    };
    return { unsubscribe, hintItem };
  };

  updateHint = (newHint, { updateScroll = true, up = true } = {}) => {
    if (this.currentHint) {
      this.currentHint.dom.classList.remove("ar-dropdown-list-button-hint");
    }
    const popup = this.popupRef.current;

    if (updateScroll) {
      const totalHeight =
        popup.parentElement.scrollTop + popup.parentElement.offsetHeight;

      if (up) {
        if (newHint.dom.offsetTop <= popup.parentElement.scrollTop)
          newHint.dom.scrollIntoView(true);
      } else {
        if (newHint.dom.offsetTop + newHint.dom.offsetHeight >= totalHeight)
          newHint.dom.scrollIntoView(false);
      }
      // newHint.dom.scrollIntoView(false)
    }
    newHint.dom.classList.add("ar-dropdown-list-button-hint");
    this.currentHint = newHint;
  };

  changeHint = (up = true) => {
    if (this.hints.length === 0) return;

    const update = (hint, optionalUp) => {
      this.updateHint(hint, {
        up: optionalUp !== undefined ? optionalUp : up
      });
    };

    if (!this.currentHint) {
      const hint = this.hints[0];
      update(hint);
    } else {
      const totalItems = this.hints.length;
      const index = this.hints.findIndex((v) => v === this.currentHint);
      //is last hint
      if (!up) {
        if (index + 1 === totalItems) {
          const hint = this.hints[0];
          update(hint, true);
        } else {
          const hint = this.hints[index + 1];
          update(hint);
        }
      } else {
        if (index === 0) {
          const hint = this.hints[this.hints.length - 1];
          update(hint, false);
        } else {
          const hint = this.hints[index - 1];
          update(hint);
        }
      }
    }
  };

  arrowDown = (e) => {
    this.changeHint(false);
  };
  arrowUp = (e) => {
    this.changeHint(true);
  };

  enterPress = (e) => {
    e.preventDefault();
    if (this.currentHint) this.currentHint.func();
  };

  // handleEsc = e => {
  //   if (e.key === "Escape") {
  //     this.close();

  //   }
  // };

  keyDownHandler = (e) => {
    //console.log(e.keyCode);
    if (!this.state.isOpened) return;

    switch (e.keyCode) {
      case 9: //tab
      case 27: //Esc
        e.nativeEvent.stopImmediatePropagation();
        this.close();

        // this.hintIndex = -1;
        // if (this.labelRef.current)
        //   this.labelRef.current.querySelector("input").blur();
        // this.setState({ isOpened: false });
        break;
      case 40: //ArrowDown
        this.arrowDown();
        break;
      case 38: //ArrowDown
        this.arrowUp();
        break;
      case 13: //Enter
        this.enterPress(e);
        break;
      default:
        break;
    }
  };

  render() {
    const {
      valueComponent,
      listComponent: ListComponent,
      listItemComponent,
      defaultActiveButton,
      headerComponent,
      enableSearch,
      multiple,
      isLoading,
      loadingComponent,
      hideCancelation,
      freeText,
      fixedWidth,
      labelClassName,
      disabled,
      preview,
      className,
      large,
      items,
      dontShowAddButton,
      value,
      removable,
      inpuRef,
      gridDropdownStatus,
      style,
      containerClassName,
      placeholder
    } = this.props;

    const { filterText, isTyping } = this.state;

    if (preview)
      return (
        <div
          style={{ flexFlow: "row wrap" }}
          className={classnames(
            "flex-1 d-flex input-preview-mode-container of-hidden w-100",
            {
              "w-100 text-truncate": gridDropdownStatus
            }
          )}
        >
          <DropdownButton
            filterText={filterText}
            removeClick={this.removeItem}
            textChange={this.textChangeHandler}
            keydown={this.inputKeyDownHandler}
            onfocus={this.inputFocusHandler}
            value={value}
            component={valueComponent}
            // enableSearch={enableSearch}
            multiple={multiple}
            // isTyping={isTyping}
            fixedWidth={fixedWidth}
            disabled={disabled}
            preview={preview}
            inpuRef={inpuRef}
          />
        </div>
      );

    let resolvedClasses = classnames(
      this.props.isPopup
        ? "ar-alt-dropdown-container"
        : "ar-dropdown-container",
      className,
      {
        "ar-disabled": disabled
      }
    );

    // const items = this.getFilteredItems(stateColumns, filterText);
    const enableInput = enableSearch && !disabled && !preview;
    return (
      <DropdownBase
        focusonmount={this.props.focusonmount}
        style={style}
        className={resolvedClasses}
        large={large}
        // onClick={this.showMenu}
        onClose={this.close}
        fixedWidth={fixedWidth}
        domRef={this.labelRef}
        tabIndex="1"
        onKeyDown={this.keyDownHandler}
        isPopup={this.props.isPopup}
        isOpen={!(freeText && multiple) && this.state.isOpened && !disabled}
        isLoading={isLoading}
        loadingComponent={loadingComponent}
        popup={
          <DropdownHintContext.Provider value={this.hintContextValue}>
            <div
              ref={this.popupRef}
              onClick={(e) => e.nativeEvent.stopImmediatePropagation()}
              className="ar-dropdown-menu"
            >
              {ListComponent ? (
                <ListComponent
                  {...this.props}
                  // multiple={multiple}
                  value={value}
                  headerComponent={headerComponent}
                  component={listItemComponent}
                  defaultActiveButton={defaultActiveButton}
                  hideCancelation={hideCancelation}
                  items={items}
                  onClick={this.addItem}
                  isLoading={isLoading}
                  dontShowAddButton={dontShowAddButton}
                  removable={!multiple && removable}

                  // noDataComponent={noDataComponent}
                  // isColumn={isColumn}
                  // filterText={filterText}
                />
              ) : (
                <DropdownList
                  {...this.props}
                  // multiple={multiple}
                  value={value}
                  headerComponent={headerComponent}
                  hideCancelation={hideCancelation}
                  component={listItemComponent}
                  defaultActiveButton={defaultActiveButton}
                  items={items}
                  onClick={this.addItem}
                  dontShowAddButton={dontShowAddButton}
                  removable={!multiple && removable}
                  // noDataComponent={noDataComponent}
                  // isColumn={isColumn}
                />
              )}
            </div>
          </DropdownHintContext.Provider>
        }
      >
        <label
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          onClick={(e) => (disabled ? null : this.showMenu(e))}
          className={classnames(
            "d-flex ",
            {
              "ar-dropdown-label-search": enableSearch,
              multiple: multiple
            },
            labelClassName
          )}
        >
          <div
            style={{ flexFlow: "row wrap", maxHeight: 350 }}
            className={classnames(
              "flex-1 d-flex of-y-auto of-x-hidden w-100",
              containerClassName,
              {
                "w-100 text-truncate": gridDropdownStatus
              }
            )}
          >
            <DropdownButton
              fixedWidth={fixedWidth}
              filterText={filterText}
              removeClick={this.removeItem}
              textChange={this.textChangeHandler}
              keydown={this.inputKeyDownHandler}
              onfocus={this.inputFocusHandler}
              value={value}
              component={valueComponent}
              multiple={multiple}
              isTyping={isTyping}
              disabled={disabled}
              enableInput={enableInput}
              focusonmount={this.props.focusonmount}
              removable={removable}
              inpuRef={inpuRef}
              placeholder={placeholder}
            />
          </div>
          <div className="text-primary ml-2 d-flex align-items-center justify-content-center">
            {enableSearch ? (
              <FontAwesomeIcon icon={faSearch} />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} />
            )}
          </div>
        </label>
      </DropdownBase>
    );
  }
}

export const DropdownButton = ({
  value,
  filterText = "",
  component: Component,
  textChange,
  onfocus,
  keydown,
  multiple,
  isTyping,
  removeClick,
  disabled,
  preview,
  enableInput,
  fixedWidth,
  removable,
  inpuRef,
  focusonmount,
  placeholder,
  ...rest
}) => {
  const targetRef = useRef();
  useLayoutEffect(() => {
    if (focusonmount && targetRef.current) {
      targetRef.current.focus();
    }
  }, [focusonmount, targetRef]);

  let valueText = "";
  if (isTyping) valueText = filterText;
  else valueText = value ? value || "" : "";

  if (multiple) {
    return (
      <React.Fragment>
        {value &&
          value.length > 0 &&
          value.map((item, i) => (
            <div
              key={i}
              style={{ maxWidth: "100%", overflow: "hidden" }}
              className={classnames(
                "ar-dropdown-button-value d-flex align-items-center",
                {
                  preview: preview
                }
              )}
            >
              <div className="flex-1 overflow-hidden disable-selection">
                {Component ? <Component item={item} isMultiple /> : item}
              </div>
              {(enableInput || removable) && (
                <FontAwesomeIcon
                  onClick={(e) => !disabled && removeClick(i, e)}
                  icon={faTimes}
                  className="ar-dropdown-button-value-remove"
                  // onClick={this.removeItem.bind(this, i)}
                />
              )}
            </div>
          ))}
        {enableInput && (
          <input
            //jsaction="paste:puy29d" aria-autoComplete="both" aria-haspopup="false"
            disabled={disabled}
            type="text"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            name="ar-dropdown"
            onKeyDown={keydown}
            onChange={textChange}
            className={
              "ar-dropdown-button ar-dropdown-button-multiple flex-1 w-100"
            }
            value={filterText}
            onFocus={onfocus}
            ref={inpuRef}
          />
        )}
      </React.Fragment>
    );
  } else if (!multiple) {
    return Component ? (
      <Component
        enableRef={targetRef}
        item={value}
        isTyping={isTyping}
        text={filterText}
        disabled={disabled}
        focus={onfocus}
        textChange={textChange}
        enableInput={enableInput}
        focusonmount={focusonmount}
        placeholder={placeholder}
      />
    ) : enableInput ? (
      <input
        type="text"
        autoComplete="off"
        disabled={disabled}
        autoCorrect="off"
        spellCheck="false"
        name="ar-dropdown"
        onChange={textChange}
        className="ar-dropdown-button"
        value={filterText}
        onFocus={onfocus}
        focusonmount={focusonmount}
        placeholder={placeholder}
      />
    ) : (
      <span ref={targetRef} className="ar-dropdown-button">
        {valueText}
      </span>
    );
  }
};

export const DropdownList = React.memo(
  ({
    items,
    component,
    onClick,
    value,
    defaultActiveButton,
    removable,
    dontShowAddButton,
    getkey,
    NoEntityComponent,
    ...rest
  }) => {
    const defaultItem =
      typeof defaultActiveButton === "function"
        ? defaultActiveButton(rest)
        : null;
    const resolvedItems = useMemo(() => {
      if (defaultItem) {
        const result = items.filter((e) => e !== defaultItem);
        return result;
      } else return items;
    }, [items, defaultItem]);

    return (
      <React.Fragment>
        {defaultItem && !value && (
          <DropdownListItem
            component={component}
            onClick={onClick}
            item={defaultItem}
          />
        )}
        {removable && value && (
          <DropdownListRemovalItem
            onClick={onClick}
            // field="Name"
          />
        )}
        {defaultItem && value && (
          <DropdownListItem
            component={component}
            onClick={onClick}
            item={defaultItem}
          />
        )}
        {resolvedItems.map((itm, i) => (
          <DropdownListItem
            key={getkey ? getkey(itm) : i}
            component={component}
            onClick={onClick}
            item={itm}
          />
        ))}

        {NoEntityComponent && !dontShowAddButton && (
          <DropdownListItem
            className={"py-0"}
            key={"no-entity"}
            // component={component}
            // onClick={onClick}
            item={NoEntityComponent}
          />
        )}
      </React.Fragment>
    );
  }
);

const DropdownHintContext = React.createContext();

const useDropdownHint = (ref, func) => {
  const { setHint, updateHint } = useContext(DropdownHintContext);
  const hintRef = useRef();
  useEffect(() => {
    const { unsubscribe, hintItem } = setHint(ref, func);
    hintRef.current = hintItem;
    return unsubscribe;
  }, [ref, func, setHint]);

  const triggerHint = (e) => {
    if (!hintRef.current) return;
    updateHint(hintRef.current, {
      updateScroll: false
    });
  };

  return triggerHint;
};

const DropdownListItem = ({
  item,
  component,
  itemComponent,
  className,
  onClick,
  type,
  title
}) => {
  const Comp = itemComponent || component;
  const handleClick = () => {
    onClick && onClick(item);
  };
  const compRef = useRef();
  const triggerHint = useDropdownHint(compRef, handleClick);

  return Comp ? (
    <div
      onMouseOver={triggerHint}
      ref={compRef}
      className={classnames("ar-dropdown-list-button", className)}
      onClick={handleClick}
    >
      <Comp item={item} />
    </div>
  ) : (
    <div
      onMouseOver={triggerHint}
      ref={compRef}
      className={classnames("ar-dropdown-list-button", className)}
      onClick={handleClick}
    >
      {item}
    </div>
  );
};

export const DropdownListRemovalItem = ({ onClick }) => {
  return (
    <DropdownListItem
      component={() => (
        <React.Fragment>
          <FontAwesomeIcon icon={faTimes} className="mr-2 text-danger-alt" />
          <span>
            <FormattedMessage id="REMOVE" />
          </span>
        </React.Fragment>
      )}
      onClick={() => onClick(null)}
      // field="Name"
    />
  );
};

export default Dropdown;

// const MultiDropdownValue = {
//   type: "Client",
//   id: 1
// };

// return (
//   <Dropdown
//     search
//     isLoading
//     loadingComponent
//     multiple
//     value={MultiDropdownValue}  //  se for array
//     listComponent={listComp => (isFatching ? <loading /> : listComp)}
//     valueComponent={({ SearchInput, value, valueType }) => (
//       <div>
//         <img /> {SearchInput}
//       </div>
//     )}
//     getValue={({ type, value }) => {
//       return value.id;
//     }}

//         items: [
//           {
//             Name: "Fern",
//             Id: 1
//           }
//         ]

//     columns={[  // prioridade
//       {
//         type: "Client",
//         headerComponent: <div />,
//         itemComponent: <div />,
//         items: [
//           {
//             Name: "Fern",
//             Id: 1
//           }
//         ],
//         field: "Name"
//       }
//     ]}
//   />
// );
