import React, { useContext } from "react";
import {
  faEnvelope,
  faPhone,
  faListCheck,
  faTicketSimple,
  faUserCircle,
  faUsersClass,
  faBadge,
  faIdCardClip,
  faIdCard
} from "@fortawesome/pro-light-svg-icons";
import {
  AccountDetailsResponse,
  spaceTicketsSchema,
  spaceTasksSchema
} from "../../config/schema";
import { ConnectedTeamProfile } from "../../Components/Teams/TeamProfile";
import { ConnectedDepartmentProfile } from "../../Components/Departments/DepartmentProfile";
import { ConnectedOrganizationProfile } from "../../Components/Organizations/OrganizationProfile";
import SplitCol from "../../Components/SplitCol/SplitCol";
import AccountProfile, {
  ConnectAccountEntity
} from "../../Components/Accounts/AccountProfile";
import {
  OriginPopupLoader,
  OriginItemGroup,
  OriginHeader,
  OriginContainer
} from "../../Components/Origin/Origin";
import { createOriginPopup } from "./Origin";
import { ConnectedTicketOrigin } from "./TicketOrigin";
import { ConnectedTaskOrigin } from "./TaskOrigin";
import {
  ConnectedOrganizationProfileStack,
  ConnectedDepartmentProfileStack,
  ConnectedTeamProfileStack,
  ShowIconOnPopUpProvider
} from "../../Components/Accounts/AccountProfileStack";
import {
  //   usePostQuery,
  useSpaceQuery
  //   useSchemaQuery
} from "../../Helpers/IOClient";
// import { useCurrentAccount } from "../../Contexts/UserContext";
// import { BarLoader } from "../../Components/GlobalLoader/GlobalLoader";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { isDeveloper } from "../../Helpers/ModulesHelper";
import { useIntl } from "react-intl";
import { usePopperResize } from "../../Components/Popper/Popper";
import { AccountEntityActionsButton } from "../Accounts/AccountHelper";
import classnames from "classnames";
export const PopupImageEnforcer = React.createContext();

export const AccountPopupAnchor = React.forwardRef(
  (
    {
      account,
      dispatch,
      children,
      justImage,
      dynamicFont,
      size,
      showRole,
      noName,
      History,
      ...rest
    },
    ref
  ) => {
    if (!account) {
      return null;
    }

    // if (justImage) {
    //   return (
    //     <div ref={ref} {...rest}>
    //       <AccountProfileImg size="sm" account={account} circular />
    //     </div>
    //   );
    // }
    const { IsDeleted } = account;
    return (
      <div
        ref={ref}
        className={classnames("link-color text-primary text-truncate", {
          "item-was-deleted": IsDeleted
        })}
        {...rest}
      >
        <AccountProfile
          size={size}
          dynamicFont={dynamicFont}
          justImage={justImage}
          account={account}
          noName={noName}
          showRole={showRole}
        />
      </div>
    );
  }
);
export const ConnectedAccountPopupAnchor = ({ account, ...rest }) => {
  return <AccountPopupAnchor account={account} {...rest} />;
};

export const AccountPopupComponent = ({ value: data }) => {
  const intl = useIntl();
  const {
    Account,
    AccountSpace,
    Departments,
    Teams,
    Organizations,
    CreationDate
  } = data;
  const PopupShowImageValue = useContext(ShowIconOnPopUpProvider);
  const account = useCurrentAccount();

  const developer = isDeveloper(account);
  usePopperResize();
  const {
    data: dataTickets
    // loading: loadingTickets,
    // error: errorTickets
  } = useSpaceQuery(
    `tickets?$orderby=Ticket/Id%20desc&$filter=((Assigned/Id%20eq%20%27${Account.Id}%27))&$top=5`,
    [spaceTicketsSchema]
  );

  const {
    data: dataTasks
    // loading: loadingTasks,
    // error: errorTasks
  } = useSpaceQuery(
    `tasks?$orderby=Task/Id%20desc&$filter=((Assigned/Id%20eq%20%27${Account.Id}%27))&$top=5`,
    [spaceTasksSchema]
  );

  if (!data) return <OriginPopupLoader />;

  //   if (!loadingTasks && !errorTasks) {
  //     resolvedTasks.current = dataTasks.map(t => (
  //       <ConnectedTaskOrigin key={t.Id} task={t.Id} />
  //     ));
  //   }

  return (
    <PopupImageEnforcer.Provider value={true}>
      <OriginContainer>
        <OriginHeader
          Dif
          className="mb-1"
          type="Client"
          entity="ACCOUNT"
          hideId
          date={CreationDate}
          EntityButtons={
            <AccountEntityActionsButton
              Account={Account}
              minimal
              developer={developer}
            />
          }
          Icon={
            <AccountProfile
              PopupShowImage={PopupShowImageValue}
              size="md"
              justImage
              account={Account}
            />
          }
          id={Account.Id}
          title={
            <>
              <div className="d-flex ml-2 align-items-center">
                {/* <AccountLink className="mr-2" id={Account.Id}>
                  {Account.Name}
                </AccountLink> */}
                <div className="mr-2" id={Account.Id}>
                  {Account.Name}
                </div>
                {/* <span className="mr-2">{Account.Name}</span> */}
              </div>
              {AccountSpace.RoleName && (
                <div className="ml-2 fs-12 text-grey-light">
                  {AccountSpace.RoleName}
                </div>
              )}
              {/* <div className="d-flex text-grey-light ml-2 fs-12">
              <FormattedMessage id={"COLLABORATOR"} />
              <div className="ml-1">{account.Id}</div>
            </div> */}
            </>
          }
        />
        {/* <div className=" mb-2 origin_header_div text-white px-3">
          <span className="origin_header">
            <FontAwesomeIcon icon={faUserAlt} className="mr-2" />{" "}
            <FormattedMessage id={"COLLABORATOR_NR"} />{" "}
            {AccountSpace.CollaboratorNumber}
          </span>
        </div> */}
        {/* <OriginHeader
				className="mb-2"
				type="Account"
				Icon={Account.Image}
				id={Account.Id}
				title={Account.name}

			/> */}
        {/* <div className="mx-3 mb-3 fs-14">
          <ConnectedAccountProfile
            inline
            forceimage={true}
            account={Account.Id}
          />
        </div> */}
        {/* <SplitCol
				className="mb-2"
				left={
				
					
				}></SplitCol>

			// right={
			//   <OriginItemGroup title="Contato">
			//     <ConnectedContactProfile contact={Contact} />
			//   </OriginItemGroup>
			//  */}
        {Organizations && Departments ? (
          <SplitCol
            className="mb-2"
            left={
              Organizations.length > 0 ? (
                <OriginItemGroup
                  icon={faUsersClass}
                  title={intl.formatMessage({ id: "COMPANIES_SUBSIDIARIES" })}
                >
                  {Organizations.length > 1 ? (
                    <ConnectedOrganizationProfileStack
                      organizations={Organizations}
                      forceimage={true}
                    />
                  ) : (
                    <ConnectedOrganizationProfile
                      forceimage={true}
                      organization={Organizations[0]}
                    />
                  )}
                  {/* <ConnectedAccountProfile account={ManagerAccount} /> */}
                </OriginItemGroup>
              ) : (
                <></>
              )
            }
            right={
              Departments ? (
                <OriginItemGroup
                  icon={faUsersClass}
                  title={intl.formatMessage({ id: "DEPARTMENTS" })}
                >
                  {Departments.length > 1 ? (
                    <ConnectedDepartmentProfileStack
                      forceimage={true}
                      departments={Departments}
                    />
                  ) : (
                    <ConnectedDepartmentProfile
                      forceimage={true}
                      department={Departments[0]}
                    />
                  )}
                  {/* {buildDepartmentTeam()} */}
                </OriginItemGroup>
              ) : (
                <></>
              )
            }
          />
        ) : Organizations ? (
          <div className="mb-3 fs-12">
            <OriginItemGroup
              icon={faUsersClass}
              title={intl.formatMessage({ id: "COMPANIES_SUBSIDIARIES" })}
            >
              {Organizations.length > 1 ? (
                <ConnectedOrganizationProfileStack
                  forceimage={true}
                  organizations={Organizations}
                />
              ) : (
                <ConnectedOrganizationProfile
                  forceimage={true}
                  organization={Organizations[0]}
                />
              )}
              {/* <ConnectedAccountProfile account={ManagerAccount} /> */}
            </OriginItemGroup>
          </div>
        ) : (
          <></>
        )}
        {Teams && AccountSpace.PhoneExtension ? (
          <SplitCol
            className="mb-2"
            left={
              Teams.length > 0 ? (
                <OriginItemGroup
                  icon={faUsersClass}
                  title={intl.formatMessage({ id: "TEAMS" })}
                >
                  {Teams.length > 1 ? (
                    <ConnectedTeamProfileStack
                      forceimage={true}
                      teams={Teams}
                    />
                  ) : (
                    <ConnectedTeamProfile forceimage={true} team={Teams[0]} />
                  )}
                </OriginItemGroup>
              ) : (
                <></>
              )
            }
            right={
              AccountSpace.PhoneExtension ? (
                <OriginItemGroup
                  icon={faPhone}
                  title={intl.formatMessage({ id: "EXTENSION" })}
                >
                  <span className="text-black fs-12">
                    {AccountSpace.PhoneExtension}
                  </span>
                </OriginItemGroup>
              ) : (
                <></>
              )
            }
          />
        ) : Teams ? (
          <div className="mb-3 fs-12">
            <OriginItemGroup
              icon={faUsersClass}
              title={intl.formatMessage({ id: "TEAMS" })}
            >
              {Teams.length > 1 ? (
                <ConnectedTeamProfileStack forceimage={true} teams={Teams} />
              ) : (
                <ConnectedTeamProfile forceimage={true} team={Teams[0]} />
              )}
            </OriginItemGroup>
          </div>
        ) : AccountSpace.PhoneExtension ? (
          <OriginItemGroup
            icon={faPhone}
            title={intl.formatMessage({ id: "EXTENSION" })}
          >
            <span className="text-black fs-12">
              {AccountSpace.PhoneExtension}
            </span>
          </OriginItemGroup>
        ) : (
          <></>
        )}

        {(AccountSpace?.External?.CAE || AccountSpace?.External?.NIF) && (
          <SplitCol
            className="mb-2"
            left={
              AccountSpace?.External?.CAE?.length > 0 ? (
                <OriginItemGroup icon={faIdCardClip} title={"CAE"}>
                  <span className="text-black fs-12">
                    {AccountSpace?.External?.CAE}
                  </span>
                </OriginItemGroup>
              ) : (
                <></>
              )
            }
            right={
              AccountSpace?.External?.NIF?.length > 0 ? (
                <OriginItemGroup
                  icon={faIdCard}
                  title={intl.formatMessage({ id: "NIF" })}
                >
                  <span className="text-black fs-12">
                    {AccountSpace?.External?.NIF}
                  </span>
                </OriginItemGroup>
              ) : (
                <></>
              )
            }
          />
        )}

        <OriginItemGroup icon={faEnvelope} className="mb-2" title="Email">
          <a href={`mailto:${Account.Email}`} className="fs-12">
            {Account.Email}
          </a>
        </OriginItemGroup>
        {AccountSpace?.RoleName && (
          <OriginItemGroup
            icon={faUserCircle}
            className="mb-2"
            title={intl.formatMessage({ id: "ROLE" })}
          >
            <span className="fs-12 text-black">{AccountSpace?.RoleName}</span>
          </OriginItemGroup>
        )}

        {dataTasks || dataTickets ? (
          <>
            {dataTickets && dataTickets.length > 0 && (
              <OriginItemGroup
                icon={faTicketSimple}
                className="mb-2"
                title={intl.formatMessage({ id: "LAST_CASES" })}
              >
                {dataTickets.map((t) => (
                  <ConnectedTicketOrigin className="mb-1" key={t} ticket={t} />
                ))}
              </OriginItemGroup>
            )}

            {dataTasks && dataTasks.length > 0 && (
              <OriginItemGroup
                icon={faListCheck}
                className="mb-2"
                title={intl.formatMessage({ id: "LAST_TASKS" })}
              >
                {dataTasks.map((t) => (
                  <ConnectedTaskOrigin className="mb-1" key={t} task={t} />
                ))}
              </OriginItemGroup>
            )}
          </>
        ) : (
          <>
            <OriginItemGroup
              className="mb-2"
              icon={faTicketSimple}
              title={intl.formatMessage({ id: "LAST_CASES" })}
            >
              <LoaderSpinner size={"xs"} />
            </OriginItemGroup>
            <OriginItemGroup
              className="mb-2"
              icon={faListCheck}
              title={intl.formatMessage({ id: "LAST_TASKS" })}
            >
              <LoaderSpinner size={"xs"} />
            </OriginItemGroup>
          </>
        )}
      </OriginContainer>
    </PopupImageEnforcer.Provider>
  );
};

const AccountPopup = createOriginPopup(
  AccountPopupAnchor,
  AccountPopupComponent,
  {
    valueProp: "account",
    schema: AccountDetailsResponse,
    endpoint: (spaceId) => `spaces/${spaceId}/User`
  }
);

export default AccountPopup;

export const ConnectedAccountPopup = ConnectAccountEntity(AccountPopup);
