import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";

const SubscriptionDetailsComp = React.lazy(() =>
  import(
    /* webpackChunkName: "subscriptions-details" */ "./SubscriptionDetails2Comp"
  )
);

const SubscriptionDetails = () => {
  return (
    <SuspenseErrorBoundary>
      <SubscriptionDetailsComp />
    </SuspenseErrorBoundary>
  );
};

export default SubscriptionDetails;
