import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
  useContext,
  useEffect
} from "react";
import {
  useMultiplePost,
  usePost,
  //   useSpaceQuery,
  getEntity,
  client
} from "../../Helpers/IOClient";
import { useGlobalLoader } from "../GlobalLoader/GlobalLoader";
import {
  useServerAwareState,
  buildGridUrl,
  useDetailAwareParams
} from "../CGrid/ServerGrid";
import classnames from "classnames";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  BoardListContainer,
  BoardHeader,
  BoardList
} from "../BoardList/BoardList";
import { useSpace } from "../../Contexts/SpaceContext";
import { usePipelineSettings } from "../Pipelines/PipelineProvider";

import Button from "../Button/Button";
import LoadableButton from "../Button/LoadableButton";
import Modal from "../Modal/Modal";
import { spacePipelineStatusSchema } from "../../config/schema";
import "./EntityBoards.css";
import Axios from "axios";
import { buildODataQuery } from "../../Helpers/ODataHelper";
import { many } from "../../Helpers/SchemaHelper";
import { usePipelineStatus } from "../../Hooks/EntityHooks";
import { useToast } from "../Toast/ToastProvider";
import {
  KanbanCreateNewContext,
  updateEntityPipelineStatus,
  updateMultipleEntityPipelineStatus
} from "./EntityBoardsHelper";
import {
  CancelationReasonDropdown,
  LostReasonDropdown
} from "../../Containers/AdvancedMultiInputs/PipelineDropdowns";
import { OrganizationDropdown } from "../../Containers/AdvancedMultiInputs";
import { createKinectHook } from "../../Hooks/MiscHooks";
// import Settings from "../../Containers/Settings/Settings";
import { PipelineTypeEnum } from "../../Containers/Settings/Pipelines/NewPipelineHelper";
import { handleError, HoverStateContext } from "../../Helpers/MiscHelper";
import { useConnectionId } from "../../Containers/RealTime/RealTime";
import { EntityUpdateCommunicator } from "../../Containers/RealTime/RealTimeEntityUpdater";
import { FormattedMessage, useIntl } from "react-intl";
import { FilterColumnColorProvider } from "../FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { useResolvedPipelineStatus } from "../PipelineStatusBoardChooser/PipelineStatusBoardChooserHelper";
import { ServerAwareFavoritesContext } from "../../Containers/AppBar/ServerAwareFavorite";
import { useResolvedDownsizedPipelineStatus } from "../KanbanChooser/KanbanResizerHelper";
import { AppBarFavoriteTreeSetter } from "../../Containers/AppBar/AppBarFavoriteQuery";
import { useFavoriteOrViewChecker } from "../../Containers/AppBar/AppBarHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const buildStatusEndpoint = (space, schema, id) => {
  return `${schema.getEndpoint(space.Id)}/${id}/status`;
};

const useDragEvents = (onStatusChange, boardObject) => {
  const selectedItemRef = useRef();
  const { schema } = useServerAwareState();
  const post = useMultiplePost();

  const space = useSpace();

  const loader = useGlobalLoader();

  // useEffect(() => {
  //   loader.stop();
  // }, [loader])

  const requestCountRef = useRef(0);
  const changeStatus = useCallback(
    (id, status, fallback) => {
      if (requestCountRef.current === 0) loader.start();
      requestCountRef.current++;

      const onFinish = () => {
        if (requestCountRef.current === 0) loader.stop();
      };
      post(
        `${schema.getEndpoint(space.Id)}/${id}/status`,
        { pipelineStatusId: status },
        {
          onSuccess: () => {
            requestCountRef.current--;
            onFinish();
          },
          onError: (e) => {
            requestCountRef.current--;
            onFinish();
            const { canceled } = e;
            if (canceled) return;

            fallback(e);
          },
          schema
        }
      );
    },
    [loader, post, schema, space.Id]
  );

  const createToast = useToast();

  const onDragEnd = useCallback(
    (r) => {
      boardObject.isDragging = false;

      const entityId = selectedItemRef.current;
      selectedItemRef.current = undefined;

      const { destination } = r;
      if (destination) {
        const { droppableId: destId, index: destIndex } = destination;
        // const { droppableId: sourceId, index: sourceIndex } = source;

        const update = {
          entityId,
          newPipelineStatus: Number(destId),
          targetIndex: destIndex
        };

        if (boardObject.pendingUpdates.length > 0) {
          // ;
          boardObject.pendingUpdates.push(update);
          boardObject.updateMultipleEntityPipelineStatus(
            boardObject.pendingUpdates
          );
          boardObject.pendingUpdates = [];
        } else {
          boardObject.updateEntityPipelineStatus(update);
        }

        const { PipelineStatus } = getEntity(schema, entityId);

        if (Number(destId) === PipelineStatus) return;

        if (onStatusChange) {
          let isPrevented = false;
          const preventRequest = () => {
            isPrevented = true;
          };

          const errorFallback = ({ error }) => {
            if (error) {
              handleError(createToast, error);
            }

            const { PipelineStatus } = getEntity(schema, entityId);

            boardObject.updateEntityPipelineStatus({
              entityId,
              newPipelineStatus: PipelineStatus,
              targetIndex: 0
            });
          };

          onStatusChange({
            status: destId,
            preventRequest,
            id: entityId,
            errorFallback
          });

          if (isPrevented) return;
          changeStatus(entityId, destId, errorFallback);
        }
      }

      if (boardObject.pendingUpdates.length > 0) {
        // ;
        boardObject.updateMultipleEntityPipelineStatus(
          boardObject.pendingUpdates
        );
        boardObject.pendingUpdates = [];
      }
    },
    [boardObject, changeStatus, createToast, onStatusChange, schema]
  );

  const onDragStart = useCallback(
    ({ draggableId }) => {
      boardObject.isDragging = true;
      selectedItemRef.current = Number(draggableId);
    },
    [boardObject]
  );

  return {
    onDragEnd,
    onDragStart
  };
};

const BoardItemContext = React.createContext();

const ItemContainer = React.memo(function ItemContainer({ id, Component }) {
  return <Component id={id} />;
});

const Item = React.memo(({ value, draggableId, index }) => {
  const ItemComponent = useContext(BoardItemContext);

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <HoverStateContext.Provider value={isHovering}>
          <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            className={classnames("ar-board-item", {
              dragging: snapshot.isDragging
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <ItemContainer Component={ItemComponent} id={value} />
          </div>
        </HoverStateContext.Provider>
      )}
    </Draggable>
  );
});

const ListItems = React.memo(({ items }) => {
  if (!items) return null;
  return items.map((v, i) => {
    return <Item value={v} index={i} draggableId={v.toString()} key={v} />;
  });
});

export const EntityBoardItem = React.memo(
  ({
    className,
    pipelineStatus,
    data,
    getBorderType,
    refresh,
    toggleDownsize,
    refreshSingleStatus,
    isDownsized,
    skipLoadingStatus,
    loadMoreItems,
    currentLoadMoreItemsAmount
  }) => {
    const { Name: headerTitle, Status } = usePipelineStatus(pipelineStatus);
    const { params } = useServerAwareState();

    const { handleNewItemFromKanban, TranslationTag, icon } =
      useContext(KanbanCreateNewContext) || {};

    const handleAddNewItem = useCallback(() => {
      handleNewItemFromKanban(parseInt(params.pipeline), pipelineStatus);
    }, [handleNewItemFromKanban, params.pipeline, pipelineStatus]);

    const borderType = useMemo(() => {
      return getBorderType(Status);
    }, [Status, getBorderType]);

    if (isDownsized) {
      return (
        <BoardListContainer isDownsized={isDownsized} className={className}>
          <BoardHeader
            refetch={refreshSingleStatus}
            isDownsized={isDownsized}
            pipelineStatus={pipelineStatus}
            toggleDownsize={toggleDownsize}
            text={headerTitle}
            type={borderType}
          />
          <Droppable droppableId={pipelineStatus.toString()}>
            {(provided, snapshot) => (
              <BoardList
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...provided.droppablePlaceholder}
                isDragging={snapshot.draggingFromThisWith}
                isDraggingOver={snapshot.isDraggingOver}
              >
                <ListItems items={data} />
                {/* {Status === 1 && TranslationTag && isDownsized && (
                  <div
                    onClick={handleAddNewItem}
                    className="text-primary cursor-pointer board-item-resized-add"
                  >
                    +
                  </div>
                )}
                {Status === 1 && TranslationTag && !isDownsized && (
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      onClick={handleAddNewItem}
                      className="d-inline-block text-primary cursor-pointer"
                    >
                      + <FormattedMessage id={TranslationTag} />
                    </div>
                  </div>
                )} */}
                {provided.placeholder}
              </BoardList>
            )}
          </Droppable>
        </BoardListContainer>
      );
    } else
      return (
        <BoardListContainer className={className}>
          <BoardHeader
            refetch={refreshSingleStatus}
            isDownsized={isDownsized}
            pipelineStatus={pipelineStatus}
            toggleDownsize={toggleDownsize}
            text={headerTitle}
            type={borderType}
          />

          <Droppable droppableId={pipelineStatus.toString()}>
            {(provided, snapshot) => (
              <BoardList
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...provided.droppablePlaceholder}
                isDragging={snapshot.draggingFromThisWith}
                isDraggingOver={snapshot.isDraggingOver}
              >
                <ListItems items={data} />
                {currentLoadMoreItemsAmount[pipelineStatus] ? (
                  <div className="d-flex align-items-center my-3 justify-content-center">
                    <LoadableButton
                      isLoading={skipLoadingStatus[pipelineStatus]}
                      vType="link-primary"
                      onClick={() => {
                        loadMoreItems(pipelineStatus);
                      }}
                      className="text-align-center w-auto"
                    >
                      + <FormattedMessage id={"LOAD_MORE"} />
                    </LoadableButton>
                  </div>
                ) : (
                  <div className="mb-3"></div>
                )}
                {Status === 1 && TranslationTag && (
                  <div className="d-flex align-items-center justify-content-center">
                    <Button
                      vType="primary-ghost"
                      onClick={handleAddNewItem}
                      className="d-flex align-items-center justify-content-center w-auto"
                    >
                      {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
                      <FormattedMessage id={TranslationTag} />
                    </Button>
                  </div>
                )}
                {provided.placeholder}
              </BoardList>
            )}
          </Droppable>
        </BoardListContainer>
      );
  }
);

const useKinect = createKinectHook({
  filterTarget: function (target, e) {
    const { offsetParent } = target;
    if (
      offsetParent &&
      offsetParent.classList &&
      offsetParent.classList.contains("ar-board-item")
    )
      return false;

    return true;
    // if (!/down|start/.test(e.type)) {
    //   return !/area|a|input/i.test(target.tagName);
    // }
  }
});

const BoardWrapper = ({ children }) => {
  const ref = useRef();
  useKinect(ref);
  return (
    <div ref={ref} className="of-y-auto h-100 ar-board-container">
      <div>{children}</div>
    </div>
  );
};

const CancelationModalContent = ({
  cancel,
  onPost,
  loading,
  Dropdown,
  settings,
  taskId,
  status,
  schema
}) => {
  const [company, setCompany] = useState(() => {
    const x = getEntity(schema, taskId);
    return x.Company || null;
  });

  const [value, setValue] = useState(null);
  const [description, setDescription] = useState("");
  const { Type } = settings;
  const StatusType = usePipelineStatus(status);

  return (
    <div className="p-4 w-450px">
      <div className="mb-2">
        <div className="mb-2 text-black fw-medium fs-14">
          <FormattedMessage id="COMPANY" />
        </div>
        <OrganizationDropdown
          value={company}
          onChange={(e) => {
            setCompany(e);
            setValue(null);
          }}
        />
      </div>
      <div className="mb-4">
        <div className="mb-2 text-black fw-medium fs-14">
          {Type === PipelineTypeEnum.Deal &&
          StatusType.Status === settings?.lostStatus ? (
            <FormattedMessage id="LOST_REASON" />
          ) : (
            <FormattedMessage id="CANCELATION_REASON" />
          )}
        </div>
        {Type === PipelineTypeEnum.Deal &&
        StatusType.Status === settings?.lostStatus ? (
          <LostReasonDropdown
            company={company}
            Type={Type}
            value={value}
            onChange={setValue}
          />
        ) : (
          <CancelationReasonDropdown
            company={company}
            Type={Type}
            value={value}
            onChange={setValue}
          />
        )}
      </div>
      <div className="mb-4">
        <div className="mb-2 text-black fw-medium fs-14">
          <FormattedMessage id="DESCRIPTION" />
        </div>
        <textarea
          className="ssi-control ar-html-input rounded w-100"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </div>
      <div className="d-flex justify-content-end">
        <Button onClick={cancel} vType="link-danger mr-2">
          <FormattedMessage id="CANCEL" />
        </Button>
        <LoadableButton
          disabled={!value || !description || !company}
          onClick={() =>
            onPost({
              StatusDetailId: value,
              StatusDetailDescription: description,
              organizationspaceid: company
            })
          }
          isLoading={loading}
        >
          <FormattedMessage id="CONFIRM" />
        </LoadableButton>
      </div>
    </div>
  );
};

export const BoardCancelationModal = ({ state, settings }) => {
  const { isCancelling, close, fallback, id, status } = state;
  const intl = useIntl();
  const forcedClose = () => {
    fallback({ error: null });
    close();
  };

  const space = useSpace();
  const createToast = useToast();
  const { schema } = useServerAwareState();
  const [post, { loading }] = usePost(
    buildStatusEndpoint(space, schema, id),
    null,
    {
      onSuccess: () => {
        createToast({
          pos: "tm",
          type: "success",
          description: `${intl.formatMessage({
            id: "SUCCESS_SAVED"
          })}`
        });
        close();
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  const handlePost = (v) => {
    post({
      pipelineStatusId: status,
      ...v
    });
  };

  return (
    <Modal isOpen={isCancelling} disableClosure={loading} onClose={forcedClose}>
      <CancelationModalContent
        status={status}
        settings={settings}
        schema={schema}
        loading={loading}
        onPost={handlePost}
        close={close}
        taskId={id}
        cancel={forcedClose}
      />
    </Modal>
  );
};

const EntityBoardsRefreshButton = ({ onClick }) => {
  return (
    <div className="ar-entity-board-refresh-container">
      <div className="d-flex align-items-center px-3 py-2 rounded">
        <div className="mr-2 text-black">
          <FormattedMessage id="NEW_ITEMS_ADDDED_OR_UPDATED" />
        </div>
        <Button className="ar-entity-board-refresh-button sm" onClick={onClick}>
          <FormattedMessage id="UPDATE" />
        </Button>
      </div>
    </div>
  );
};

export const buildBoardParamOverride = (filter, statusId, field) => {
  const f = filter;
  const statusFilter = {
    field,
    operator: "eq",
    value: statusId,
    data: { type: "string" }
  };

  if (!f || f.length === 0) return { filter: [statusFilter] };
  const index = f.findIndex((v) => v.field === field);
  if (index === -1) return { filter: [...f, statusFilter] };

  const newFilter = [...f];
  newFilter[index] = statusFilter;
  return { filter: newFilter };
};

const useEntityBoardQuery = (statuses, onRefresh, boardObject) => {
  const [loading, setLoading] = useState([]);

  const [error, setError] = useState();
  const countRef = useRef({});
  const statusSkipsRef = useRef();
  const [increaseStatusLoading, setIncreaseStatusLoading] = useState({});
  const canLoadMoreItemsRef = useRef({});

  useEffect(() => {
    const res = {};
    for (const stat of statuses) {
      res[stat] = 0;
    }
    const resLoading = {};
    for (const stat of statuses) {
      resLoading[stat] = false;
    }

    statusSkipsRef.current = res;
    setIncreaseStatusLoading(resLoading);
  }, [statuses]);

  const { endpoint, schema, params, moduleType } = useServerAwareState();
  const resolvedParams = useDetailAwareParams(params, moduleType);
  const resolvedSchema = useMemo(() => {
    return {
      d: {
        results: many(schema)
      }
    };
  }, [schema]);
  const requestsRef = useRef([]);
  const pipelineSettings = usePipelineSettings();
  const { pipelineStatusField: field } = pipelineSettings;
  const space = useSpace();

  const [refreshCount, setRefreshCount] = useState(0);
  const conId = useConnectionId();

  useEffect(() => {
    const requests = requestsRef.current;
    setLoading(true);
    boardObject.setter();
    boardObject.entityStatusDict = {};
    const results = {};

    for (const status of statuses) {
      const pOverride = buildBoardParamOverride(
        resolvedParams.filter,
        status,
        field
      );
      pOverride.query = resolvedParams.query;
      pOverride.$orderBy = resolvedParams.$orderBy;

      pOverride.count = true;

      pOverride.advancedFilters = resolvedParams.advancedFilters;
      const query = buildODataQuery(pOverride, true, {
        pipelineSettings
      });

      const url = buildGridUrl(endpoint, schema, query, space.Id);

      const source = Axios.CancelToken.source();
      requests.push(source);

      const onEnd = (r) => {
        const { data } = r;
        const sourceIndex = requests.indexOf(source);
        countRef.current[status] = data.d.__count;
        requests.splice(sourceIndex, 1);
        // ;
        if (data) {
          results[status] = data.d.results;
          for (const result of data.d.results) {
            boardObject.entityStatusDict[result] = status;
          }
          canLoadMoreItemsRef.current[status] = 25 < data.d.__count;
        }
        if (requests.length === 0) {
          // console.log(`requests: ${requests.length}`);
          setLoading(false);

          if (boardObject.pendingUpdates.length > 0) {
            boardObject.updateMultipleEntityPipelineStatus(
              boardObject.pendingUpdates,
              (func) => {
                boardObject.setter(func(results));
              }
            );
            boardObject.pendingUpdates = [];
          } else {
            boardObject.setter(results);
          }

          if (refreshCount > 0) onRefresh();
        }
      };

      const onError = (r) => {
        setError(r.error);
        onEnd(r);
      };

      client.get(url, {
        schema: resolvedSchema,
        cancelToken: source.token,
        onSuccess: onEnd,
        onError,
        headers: {
          ConnectionID: conId
        }
      });
    }

    const cleanup = () => {
      for (const r of requests) {
        r.cancel();
      }
      requestsRef.current = [];
    };

    return cleanup;
  }, [
    endpoint,
    field,
    resolvedParams,
    pipelineSettings,
    schema,
    space.Id,
    statuses,
    refreshCount,
    onRefresh,
    boardObject,
    conId,
    resolvedSchema
  ]);

  const refresh = useCallback(() => {
    setRefreshCount((c) => c + 1);
  }, []);

  const increaseStatusSkip = (stat) => {
    statusSkipsRef.current[stat] = statusSkipsRef.current[stat] + 25;

    const onEnd = (r) => {
      const { data } = r;
      if (data) {
        countRef.current[stat] = data.d.__count;
        boardObject.setter((oldData) => {
          let newData = { ...oldData };
          newData[stat] = [...oldData[stat], ...data.d.results];
          for (const result of data.d.results) {
            boardObject.entityStatusDict[result] = stat;
          }
          return newData;
        });
        canLoadMoreItemsRef.current[stat] =
          statusSkipsRef.current[stat] + 25 < data.d.__count;
      }
      setIncreaseStatusLoading((oldS) => {
        const newS = { ...oldS };
        newS[stat] = false;
        return newS;
      });
    };

    const onError = (r) => {
      setError(r.error);
      onEnd(r);
    };

    const pOverride = buildBoardParamOverride(
      resolvedParams.filter,
      stat,
      field
    );
    pOverride.query = resolvedParams.query;
    pOverride.count = true;
    pOverride.$orderBy = resolvedParams.$orderBy;
    pOverride.skip = statusSkipsRef.current[stat]
      ? statusSkipsRef.current[stat]
      : 0;
    pOverride.advancedFilters = resolvedParams.advancedFilters;
    const query = buildODataQuery(pOverride, true, {
      pipelineSettings
    });
    setIncreaseStatusLoading((oldS) => {
      const newS = { ...oldS };
      newS[stat] = true;
      return newS;
    });
    const url = buildGridUrl(endpoint, schema, query, space.Id);

    client.get(url, {
      schema: resolvedSchema,
      onSuccess: onEnd,
      onError,
      headers: {
        ConnectionID: conId
      }
    });
  };

  const refreshSingleStatus = (stat) => {
    const onEnd = (r) => {
      const { data } = r;
      if (data) {
        countRef.current[stat] = data.d.__count;
        boardObject.setter((oldData) => {
          let newData = { ...oldData };
          newData[stat] = [...data.d.results];
          for (const result of data.d.results) {
            boardObject.entityStatusDict[result] = stat;
          }
          return newData;
        });
        canLoadMoreItemsRef.current[stat] =
          statusSkipsRef.current[stat] + 25 < data.d.__count;
      }
      setIncreaseStatusLoading((oldS) => {
        const newS = { ...oldS };
        newS[stat] = false;
        return newS;
      });
    };

    const onError = (r) => {
      setError(r.error);
      onEnd(r);
    };

    const pOverride = buildBoardParamOverride(
      resolvedParams.filter,
      stat,
      field
    );
    pOverride.query = resolvedParams.query;
    pOverride.count = true;
    pOverride.$orderBy = resolvedParams.$orderBy;
    pOverride.skip = 0;
    statusSkipsRef.current[stat] = 0;
    pOverride.advancedFilters = resolvedParams.advancedFilters;
    const query = buildODataQuery(pOverride, true, {
      pipelineSettings
    });
    setIncreaseStatusLoading((oldS) => {
      const newS = { ...oldS };
      newS[stat] = true;
      return newS;
    });
    const url = buildGridUrl(endpoint, schema, query, space.Id);

    client.get(url, {
      schema: resolvedSchema,
      onSuccess: onEnd,
      onError,
      headers: {
        ConnectionID: conId
      }
    });
  };

  return {
    loading,
    error,
    refresh,
    increaseStatusSkip,
    refreshSingleStatus,
    increaseStatusLoading,
    fullItemsCount: countRef,
    currentStatusSkip: canLoadMoreItemsRef.current
  };
};

const EntityBoards = React.memo(({ onStatusChange, settings, statuses }) => {
  const boardRef = useRef();
  const { schema } = useServerAwareState();
  const [data, setData] = useState();
  if (!boardRef.current) {
    const entityStatusDict = {};
    boardRef.current = {
      isDragging: false,
      pendingUpdates: [],
      setter: setData,
      entityStatusDict,
      updateEntityPipelineStatus: ({
        entityId,
        newPipelineStatus,
        targetIndex
      }) => {
        updateEntityPipelineStatus({
          entityId,
          entityStatusDict: boardRef.current.entityStatusDict,
          setter: setData,
          newPipelineStatus,
          targetIndex
        });
      },
      updateMultipleEntityPipelineStatus: (updates, customSetter) => {
        updateMultipleEntityPipelineStatus(
          updates,
          customSetter || setData,
          boardRef.current.entityStatusDict
        );
      }
    };
  }
  const [hasNewItems, setHasNewItems] = useState(false);

  const handleRefresh = useCallback(() => {
    setHasNewItems(false);
  }, []);

  const {
    loading,
    error,
    refresh,
    increaseStatusSkip,
    fullItemsCount,
    refreshSingleStatus,
    increaseStatusLoading,
    currentStatusSkip
  } = useEntityBoardQuery(statuses, handleRefresh, boardRef.current);

  const { onDragEnd, onDragStart } = useDragEvents(
    onStatusChange,
    boardRef.current
  );

  // const handleDragStart = useCallback(() => {
  //   isDraggingRef.current = true
  // }, [])

  const createToast = useToast();

  const {
    // creationCommunicator,
    // updateCommunicator,
    ItemComponent,
    getBorderType,
    updateMessage,
    BoardItemComponent
  } = settings;
  const intl = useIntl();
  useEffect(() => {
    return EntityUpdateCommunicator.subscribe(
      (itemSchema, entityId, originalEntity, newEntity, isCreation) => {
        if (itemSchema !== schema) return;

        if (isCreation) {
          setHasNewItems(true);
          return;
        }

        if (!originalEntity || !boardRef.current.entityStatusDict[entityId]) {
          setHasNewItems(true);
          return;
        }

        if (updateMessage) {
          createToast({
            width: 520,
            pos: "tm",
            type: "info",
            description: updateMessage(entityId, newEntity, intl)
          });
        }
        if (originalEntity.Pipeline !== newEntity.Pipeline) return;
        if (originalEntity.PipelineStatus === newEntity.PipelineStatus) return;

        const update = {
          entityId: entityId,
          newPipelineStatus: newEntity.PipelineStatus,
          targetIndex: 0
        };

        if (
          boardRef.current.isDragging ||
          (!data && boardRef.current.entityStatusDict[entityId] !== undefined)
        )
          boardRef.current.pendingUpdates.push(update);
        else boardRef.current.updateEntityPipelineStatus(update);
      }
    );
  }, [createToast, data, intl, schema, updateMessage]);

  // useEffect(() => {
  //   if (!updateCommunicator) return;
  //   return updateCommunicator.subscribe(
  //     (entityId, originalEntity, newEntity) => {
  //       // if (!originalEntity) return;

  //       if (!originalEntity || !boardRef.current.entityStatusDict[entityId]) {
  //         setHasNewItems(true);
  //         return;
  //       }

  //       if (updateMessage) {
  //         createToast({
  //           width: 520,
  //           pos: "tm",
  //           type: "info",
  //           description: updateMessage(entityId, newEntity)
  //         });
  //       }
  //       if (originalEntity.Pipeline !== newEntity.Pipeline) return;
  //       if (originalEntity.PipelineStatus === newEntity.PipelineStatus) return;

  //       const update = {
  //         entityId: entityId,
  //         newPipelineStatus: newEntity.PipelineStatus,
  //         targetIndex: 0
  //       };
  //       // ;
  //       if (
  //         boardRef.current.isDragging ||
  //         (!data && boardRef.current.entityStatusDict[entityId] !== undefined)
  //       )
  //         boardRef.current.pendingUpdates.push(update);
  //       else boardRef.current.updateEntityPipelineStatus(update);
  //     }
  //   );
  // }, [updateCommunicator, createToast, schema, data, updateMessage]);

  // useEffect(() => {
  //   //
  //   if (!creationCommunicator) return;

  //   return creationCommunicator.subscribe(() => {
  //     setHasNewItems(true);
  //   });
  // }, [creationCommunicator]);

  // console.log(loading || error);

  if (loading || error)
    return (
      <LoaderSpinner
        className="w-100 d-flex align-items-center justify-content-around text-primary"
        size="sm"
      />
    );
  const BoardComponent = BoardItemComponent || EntityBoardItem;
  return (
    <BoardItemContext.Provider value={ItemComponent}>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div className="flex-1 of-hidden h-100 position-relative px-2">
          <BoardWrapper>
            {statuses.map((s) => {
              return (
                <BoardComponent
                  key={s}
                  pipelineStatus={s}
                  getBorderType={getBorderType}
                  data={data[s]}
                  fullItemsCount={fullItemsCount}
                  refresh={refresh}
                  refreshSingleStatus={refreshSingleStatus}
                  skipLoadingStatus={increaseStatusLoading}
                  loadMoreItems={increaseStatusSkip}
                  currentLoadMoreItemsAmount={currentStatusSkip}
                  // headerTitle={Name}
                  // type={type}
                  // status={pipelineStatus}
                />
              );
            }, [])}

            {/* {children} */}
          </BoardWrapper>
          {!loading && hasNewItems && (
            <EntityBoardsRefreshButton onClick={refresh} />
          )}
        </div>
      </DragDropContext>
    </BoardItemContext.Provider>
  );
});

export default EntityBoards;

export const CancellableBoard = ({ ...rest }) => {
  const result = useFavoriteOrViewChecker();

  if (result) return <CancellableBoardFavorites {...rest} />;
  return <CancellableBoardViews {...rest} />;
};

const CancellableBoardFavorites = ({ data, ...rest }) => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const { pipelineStatus, downsizedPipelineStatus } = FavoriteItem.data;
  const resolvedPipelineStatus = useMemo(() => {
    if (pipelineStatus && pipelineStatus.length > 0) {
      const result = [];
      for (const status of data) {
        if (pipelineStatus.some((v) => parseInt(v) === status.Id))
          result.push(status.Id);
      }
      return result;
    } else return data.map((e) => e.Id);
  }, [data, pipelineStatus]);
  const resolvedDownsizedPipelineStatus = useMemo(() => {
    if (downsizedPipelineStatus && downsizedPipelineStatus.length > 0) {
      const result = [];
      for (const status of data) {
        if (downsizedPipelineStatus.some((v) => parseInt(v) === status.Id))
          result.push(status.Id);
      }
      return result;
    } else return undefined;
  }, [data, downsizedPipelineStatus]);
  return (
    <CancellableBoardComp
      {...rest}
      data={resolvedPipelineStatus}
      downsizedItems={resolvedDownsizedPipelineStatus}
    />
  );
};

const CancellableBoardViews = ({ ...rest }) => {
  const { data, settings, pipelineId } = rest;

  const resolveData = useResolvedPipelineStatus(
    data,
    settings.schema.name,
    pipelineId
  );

  const downsizedItems = useResolvedDownsizedPipelineStatus(
    data,
    settings.schema.name
  );

  return (
    <CancellableBoardComp
      {...rest}
      data={resolveData}
      downsizedItems={downsizedItems}
    />
  );
};

const CancellableBoardComp = ({
  settings,
  data,
  allPipelineStatus,
  downsizedItems,
  ...rest
}) => {
  const { params } = useServerAwareState();

  // console.log(params);
  const pipelineId = params.pipeline;

  const [cancelationState, setCancelationState] = useState({
    isCancelling: false
  });

  const updateCancelationState = (update) => {
    setCancelationState((c) => {
      return {
        ...c,
        ...update
      };
    });
  };

  const closeCancelationModal = () => {
    updateCancelationState({
      isCancelling: false
    });
  };

  const { canceledStatus } = settings;

  const handleStatusChange = ({
    id,
    status: pipelineStatus,
    errorFallback,
    preventRequest
  }) => {
    const { Status } = getEntity(spacePipelineStatusSchema, pipelineStatus);
    if (
      Status === canceledStatus ||
      (settings.Type === PipelineTypeEnum.Deal &&
        Status === settings.lostStatus)
    ) {
      //;
      preventRequest();

      updateCancelationState({
        isCancelling: true,
        close: closeCancelationModal,
        fallback: errorFallback,
        id,
        status: pipelineStatus
      });
    } else return;
  };

  if (!pipelineId)
    return (
      <div className="d-flex align-items-center justify-content-around text-black w-100">
        <FormattedMessage id={"SELECT_ONE_PIPELINE"} />
      </div>
    );

  if (!data) {
    return (
      <LoaderSpinner
        size="sm"
        className="text-primary w-100 d-flex align-items-center justify-content-around"
      />
    );
  }

  return (
    <DownsizedColumnsContext.Provider value={downsizedItems}>
      <BoardCancelationModal settings={settings} state={cancelationState} />
      <FilterColumnColorProvider>
        <EntityBoards
          settings={settings}
          onStatusChange={handleStatusChange}
          key={pipelineId}
          statuses={data}
          statusField="PipelineStatus/Status"
          {...rest}
        />
      </FilterColumnColorProvider>
    </DownsizedColumnsContext.Provider>
  );
};

export const DownsizedColumnsContext = React.createContext();

export const useFavoriteToggleDownsize = (pipelineStatus) => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const setTree = useContext(AppBarFavoriteTreeSetter);
  const handleColumnChange = useCallback(() => {
    setTree((oldTree) => {
      const newTree = { ...oldTree };
      const { rootId, items } = newTree;
      const newItems = { ...items };
      const newItem = { ...newItems[FavoriteItem.id] };
      const newData = { ...newItem.data };
      const newDownsizedPipelineStatus = newData.downsizedPipelineStatus || [];
      const newResult = [...newDownsizedPipelineStatus];
      const index = newResult.findIndex((e) => e === pipelineStatus);
      if (index !== -1) {
        newResult.splice(index, 1);
      } else newResult.push(pipelineStatus);
      newData.downsizedPipelineStatus = [...newResult];
      newItem.data = newData;
      newItems[FavoriteItem.id] = newItem;
      return {
        rootId,
        items: newItems
      };
    });
  }, [FavoriteItem, pipelineStatus, setTree]);
  return () => {
    handleColumnChange();
  };
};
