import { useCallback, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import querystring from "query-string";
import {
  ServerAwareUpdateTempViewsContext,
  ServerAwareViewsContext
} from "../CGrid/ServerAwareViews";
import { useSpace } from "../../Contexts/SpaceContext";

export const ColumnChooserListnerDict = {};

const getStickyDict = (stickyColumns) => {
  const dict = {};
  for (const field of stickyColumns) {
    dict[field] = true;
  }
  return dict;
};

const calculateColumns = (columns, orderList, stickyLayout) => {
  const fieldDict = getfieldTitleDict(columns);

  const result = [];
  if (!orderList || orderList.length === 0) {
    for (const col of columns) {
      result.push({ ...col });
    }
  } else {
    for (const c of orderList) {
      if (fieldDict[c]) result.push(fieldDict[c]);
    }
  }

  if (stickyLayout) {
    const dict = getStickyDict(stickyLayout);
    for (const column of result) {
      const { field } = column;
      const isSticky = Boolean(dict[field]);
      column.sticky = isSticky;
    }
  }

  return result;
};

const getfieldTitleDict = (Columns) => {
  const result = {};
  for (const c of Columns) {
    result[c.field] = { ...c };
  }
  return result;
};

export const useResolvedColumns = (columns, id) => {
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const lsViews = useContext(ServerAwareViewsContext);
  const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);
  const space = useSpace();
  const index = lsViews?.findIndex((e) => e.id === viewPref);
  const orderList = useMemo(() => {
    return index === undefined
      ? []
      : index !== -1
      ? lsViews[index].columnLayout
      : lsViews[0]?.columnLayout || [];
  }, [index, lsViews]);

  const stickyLayout = useMemo(() => {
    return index === undefined
      ? []
      : index !== -1
      ? lsViews[index].stickyLayout
      : lsViews[0]?.stickyLayout;
  }, [index, lsViews]);

  const resolvedColumn = useMemo(() => {
    return calculateColumns(columns, orderList, stickyLayout);
  }, [columns, orderList, stickyLayout]);

  const updateLocalStorage = useCallback(
    (columns) => {
      let gridSavedViews = JSON.parse(
        window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
      );
      const viewGridViews = gridSavedViews[id];
      const index = viewGridViews.findIndex((e) => e.id === viewPref);
      let resolvedIndex = index === -1 ? 0 : index;
      let viewGridObject = viewGridViews[resolvedIndex] || {};
      viewGridObject.columnLayout = columns;
      viewGridViews[resolvedIndex] = viewGridObject;
      gridSavedViews[id] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
      updateLsViews();
    },
    [id, space.Id, updateLsViews, viewPref]
  );

  useEffect(() => {
    if (!ColumnChooserListnerDict[id]) ColumnChooserListnerDict[id] = [];

    const listner = (orderList) => {
      //;
      if (!orderList) updateLocalStorage(columns);
      else updateLocalStorage(calculateColumns(columns, orderList));
    };

    ColumnChooserListnerDict[id].push(listner);

    return () => {
      const index = ColumnChooserListnerDict[id].indexOf(listner);
      ColumnChooserListnerDict[id].splice(index, 1);
    };
  }, [columns, id, updateLocalStorage]);
  return resolvedColumn;
};
