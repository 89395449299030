import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDetailsEntityId } from "../../Components/DetailsView/DetailsView";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import PhoneInput, { PhoneNumber } from "../../Components/Input/PhoneInput";
import { WarningAlert } from "../../Components/Toast/ToastComponents";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { DuplicateContactDropdown } from "../AdvancedMultiInputs";

const Validator = ({ phone, email, contact, extraClients }) => {
  const url = useMemo(() => {
    let url = "Contacts/CheckDuplicates?";
    if (phone) url += `phoneNumber=${phone}`;
    else url += `email=${email}`;

    if (contact) url += `&ContactId=${contact}`;

    return url;
  }, [contact, email, phone]);

  const { loading, data, error } = useSpaceQuery(url);

  const openForm = useEntitySummarySidebar();

  if (loading || error) return null;

  const { DuplicatesCount, CanSeeAtLeastOneDuplicate } = data;

  if (DuplicatesCount === 0) return null;

  if (!CanSeeAtLeastOneDuplicate)
    return (
      <WarningAlert className="mb-3">
        <FormattedMessage
          id="CONTACTS_DUPLICATE_DESCRIPTION"
          values={{ count: DuplicatesCount }}
        />
      </WarningAlert>
    );

  const handleChange = (v) => {
    openForm({
      type: moduleTypes.contacts,
      props: {
        edit: true,
        id: v,
        childProps: {
          Client: extraClients[0]
        }
      },
      options: {
        replaceTab: true
      }
    });
  };

  return (
    <WarningAlert className="mb-3">
      <div className="mb-2">
        <FormattedMessage
          id="CONTACTS_DUPLICATE_DESCRIPTION"
          values={{ count: DuplicatesCount }}
        />
      </div>
      <DuplicateContactDropdown
        className="w-100"
        onChange={handleChange}
        phoneNumber={phone}
        email={email}
        contact={contact}
      />
    </WarningAlert>
  );
};

export const ContactEmailInput = (props) => {
  const { value, extraClients, ...rest } = props;

  const [validationEmail, setValidationEmail] = useState("");

  const handleBlur = () => {
    setValidationEmail(value);
  };

  const handleFocus = () => {
    setValidationEmail("");
  };

  const eId = useDetailsEntityId();

  return (
    <>
      <FormInput
        onBlur={handleBlur}
        onFocus={handleFocus}
        text={<FormattedMessage id="EMAIL" />}
        className="mb-3"
        name="Email"
        value={value}
        {...rest}
      />
      {validationEmail && (
        <Validator
          extraClients={extraClients}
          contact={eId}
          email={validationEmail}
        />
      )}
    </>
  );
};

export const ContactPhoneInput = (props) => {
  const { value, extraClients, ...rest } = props;

  const [validationPhone, setValidationPhone] = useState("");

  const handleBlur = () => {
    const val = PhoneNumber(value);
    if (!val || !val.number) return;
    setValidationPhone(encodeURIComponent(val.completeNumber));
  };

  const handleFocus = () => {
    setValidationPhone("");
  };

  const eId = useDetailsEntityId();

  return (
    <>
      <FormInput
        onBlur={handleBlur}
        onFocus={handleFocus}
        inputType={PhoneInput}
        className="mb-3"
        value={value}
        {...rest}
      />
      {validationPhone && (
        <Validator
          contact={eId}
          extraClients={extraClients}
          phone={validationPhone}
        />
      )}
    </>
  );
};
