import { useCallback, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import querystring from "query-string";
import {
  ServerAwareUpdateTempViewsContext,
  ServerAwareViewsContext
} from "../CGrid/ServerAwareViews";
import { useSpace } from "../../Contexts/SpaceContext";
import { ServerAwareFavoritesContext } from "../../Containers/AppBar/ServerAwareFavorite";
export const KanbanChooserListnerDict = {};

const calculateColumns = (columns, orderList = []) => {
  const fieldDict = getfieldTitleDict(columns);
  const result = [];
  for (const c of orderList) {
    if (fieldDict[c]) result.push(fieldDict[c]);
  }

  return result;
};

const getfieldTitleDict = (Columns) => {
  const result = {};
  for (const c of Columns) {
    result[c.title] = c;
  }
  return result;
};

const useResolvedFavoriteKanbans = (columns) => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  if (!FavoriteItem) return null;
  const { kanbanLayout } = FavoriteItem.data;
  return calculateColumns(columns, kanbanLayout);
};

export const useResolvedKanbans = (columns, id) => {
  const favoriteResult = useResolvedFavoriteKanbans(columns);
  const viewsResult = useResolvedViewKanbans(columns, id);
  if (favoriteResult) return favoriteResult;
  else return viewsResult;
};

export const useResolvedViewKanbans = (columns, id) => {
  const location = useLocation();
  const space = useSpace();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const lsViews = useContext(ServerAwareViewsContext);
  const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);

  const index = lsViews?.findIndex((e) => e.id === viewPref);

  const orderList = useMemo(() => {
    if (index !== -1 && lsViews) {
      return lsViews[index]?.kanbanLayout || [];
    } else if (lsViews) {
      return lsViews[0]?.kanbanLayout || [];
    } else return [];
  }, [index, lsViews]);

  const resolvedColumn = useMemo(
    () => calculateColumns(columns, orderList),
    [columns, orderList]
  );

  const updateLocalStorage = useCallback(
    (columns) => {
      let gridSavedViews = JSON.parse(
        window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
      );
      const viewGridViews = gridSavedViews[id];
      const index = viewGridViews.findIndex((e) => e.id === viewPref);
      let resolvedIndex = index === -1 ? 0 : index;
      let viewGridObject = viewGridViews[resolvedIndex] || {};
      viewGridObject.kanbanLayout = columns;
      viewGridViews[resolvedIndex] = viewGridObject;
      gridSavedViews[id] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
      updateLsViews && updateLsViews();
    },
    [id, space.Id, updateLsViews, viewPref]
  );

  useEffect(() => {
    if (!KanbanChooserListnerDict[id]) KanbanChooserListnerDict[id] = [];

    const listner = (orderList) => {
      //;
      if (!orderList) updateLocalStorage(columns);
      else updateLocalStorage(calculateColumns(columns, orderList));
    };

    KanbanChooserListnerDict[id].push(listner);

    return () => {
      const index = KanbanChooserListnerDict[id].indexOf(listner);
      KanbanChooserListnerDict[id].splice(index, 1);
    };
  }, [columns, id, updateLocalStorage]);

  return resolvedColumn;
};
