import { faNote } from "@fortawesome/pro-solid-svg-icons";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import { formatAutomationTaskForm } from "../../../../../Helpers/FormFormmaterHelper";
import { isValueExpression } from "../../../../../Helpers/MiscHelper";
import { PipelineTypeEnum } from "../../../../Settings/Pipelines/NewPipelineHelper";
import TaskFormContent from "../../../../Tasks/TaskFormContent";
import {
  buildTaskBaseForm,
  defaultTaskForm
} from "../../../../Tasks/TaskFormHelper";
import { ElementsRefContext } from "../../AutomationDetails";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import SourceHandles from "../../Handles/SourceHandles";
import TargetHandles from "../../Handles/TargetHandles";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import AutomationCreation, {
  EntityAutomationCreationSidebar
} from "./CreationNode";

const convertAutomationToTaskForm = (form, userId) => {
  const {
    name,
    creator,
    begin_date,
    contact_id,
    task_type_id,
    target,
    end_date,
    estimated_cost,
    manager_id,
    pipeline_id,
    pipeline_status_id,
    status_detail_id,
    status_detail_description,
    organization_id,
    description,
    internal_description,
    todos,
    followers,
    addresses
  } = form;

  // const target = getSnakeCaseTarget(form);

  const resolvedBeginDate = isValueExpression(begin_date)
    ? begin_date
    : new Date(begin_date);
  const resolvedEndDate = isValueExpression(end_date)
    ? end_date
    : new Date(end_date);

  return {
    TaskTarget: target,
    Name: name,
    BeginDate: resolvedBeginDate,
    EndDate: resolvedEndDate,
    EstimatedCost: estimated_cost,
    Manager: manager_id,
    Pipeline: pipeline_id,
    PipelineStatus: pipeline_status_id,
    StatusDetailId: status_detail_id,
    StatusDetailDescription: status_detail_description,
    Company: organization_id,
    Contact: contact_id,
    TaskType: task_type_id,
    Creator: creator,
    Description: description,
    InternalDescription: internal_description,
    ToDos: todos ? JSON.parse(todos) : [],
    Followers: followers ? JSON.parse(followers) : [],
    Addresses: addresses ? JSON.parse(addresses) : []
  };
};

const settings = {
  convertAutomationToForm: convertAutomationToTaskForm,
  buildBaseForm: ({ user, id, convertedForm }) => {
    return buildTaskBaseForm(user, id, convertedForm);
  },
  getDefaultForm: ({ user }) => {
    return defaultTaskForm({ user });
  },
  pipelineType: PipelineTypeEnum.Task,
  FormContent: (props) => {
    return <TaskFormContent {...props}></TaskFormContent>;
  },
  formatToSnake: formatAutomationTaskForm
};

const TasksCreationNode = React.memo(({ selected, id, data, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const AllElementsRef = useContext(ElementsRefContext);
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleDoubleClick = () => {
    openSidebar(
      <EntityAutomationCreationSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        CurrentNodeId={id}
        settings={settings}
        data={data}
        handleSubmit={handleSubmit}
        AllElements={AllElementsRef.current}
      />
    );
  };
  const { enabled, config } = data;
  const intl = useIntl();
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <AutomationCreation
          config={config}
          type={type}
          enabled={enabled}
          selected={selected}
          onDoubleClick={handleDoubleClick}
          icon={faNote}
          text={intl.formatMessage({ id: "CREATE_TASK" })}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default TasksCreationNode;
