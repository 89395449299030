import React from "react";
import { EditItem } from "./HistoryItemDetails";
import OrganizationProfile from "../../../../Organizations/OrganizationProfile";
import DepartmentProfile from "../../../../Departments/DepartmentProfile";
import TeamProfile from "../../../../Teams/TeamProfile";
import AccountProfile from "../../../../Accounts/AccountProfile";
import FileUploader from "../../../../FileUploader/FileUploader";
import FormattedTimePreview from "../../../../FormattedTimePreview/FormattedTimePreview";
import { ContactMediumTypes } from "../../../../../config/schema";
import { FormattedPhoneNumber } from "../../../../Input/PhoneInput";
import CountryProfile from "../../../../Country/CountryProfile";
import { toCurrencyFromDecimal } from "../../../../../Helpers/CurrencyHelper";
import { InterventionIcon } from "../../../../Common/EntityIcons";
import { FormattedMessage } from "react-intl";
import ContactProfile from "../../../../Contacts/ContactProfile";

const getArrayUpdatesFromProp = (prop) => {
  const { NewValue = [], OldValue = [] } = prop;

  const oldDict = {};
  const newDict = {};

  for (const v of OldValue) {
    oldDict[v.Id] = true;
  }

  for (const v of NewValue) {
    newDict[v.Id] = true;
  }

  const addedValues = [];
  const removedValues = [];

  for (const v of NewValue) {
    if (!oldDict[v.Id]) addedValues.push(v);
  }

  for (const v of OldValue) {
    if (!newDict[v.Id]) removedValues.push(v);
  }

  return { added: addedValues, removed: removedValues };
};

/*-----------------------------------Accounts-----------------------------------*/
export const Accounts = {
  procedure: (arr, prop) => {
    const { NewValue, OldValue, ...rest } = prop;

    const { added, removed } = getArrayUpdatesFromProp(prop);

    const AccountComponent = ({ value }) => {
      return (
        <div>
          {/* <div className="mb-2">{text}</div> */}
          {value.map((v) => {
            return (
              <div
                key={v.Id}
                className="d-inline-block vertical-align-middle my-1 mr-2"
              >
                <AccountProfile account={v} />
              </div>
            );
          })}
        </div>
      );
    };

    if (added.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, NewValue: added }}
          NameValue={"USERS"}
          key={"newAccounts"}
          ValueComponent={AccountComponent}
          // ValueExists={ValueExists}
        />
      );

    if (removed.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, OldValue: removed }}
          NameValue={"USERS"}
          key={"removedAccounts"}
          ValueComponent={AccountComponent}
          // ValueExists={ValueExists}
        />
      );
  }
};

export const Followers = {
  procedure: (arr, prop) => {
    const { NewValue, OldValue, ...rest } = prop;

    const { added, removed } = getArrayUpdatesFromProp(prop);

    const AccountComponent = ({ value }) => {
      return (
        <div>
          {/* <div className="mb-2">{text}</div> */}
          {value.map((v) => {
            return (
              <div
                key={v.Id}
                className="d-inline-block vertical-align-middle my-1 mr-2"
              >
                <AccountProfile account={v} />
              </div>
            );
          })}
        </div>
      );
    };

    if (added.length === 0 && removed.length === 0) {
      arr.push(
        <EditItem
          property={{ ...rest, NewValue, OldValue }}
          NameValue={"FOLLOWERS"}
          key={"newFollowers"}
          ValueComponent={AccountComponent}
          // ValueExists={ValueExists}
        />
      );
    }

    if (added.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, NewValue: added }}
          NameValue={"FOLLOWERS"}
          key={"newFollowers"}
          ValueComponent={AccountComponent}
          // ValueExists={ValueExists}
        />
      );

    if (removed.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, OldValue: removed }}
          NameValue={"FOLLOWERS"}
          key={"removedFollowers"}
          ValueComponent={AccountComponent}
          // ValueExists={ValueExists}
        />
      );
  }
};

/*-----------------------------------Tags-----------------------------------*/

export const Tags = {
  NameId: "TAGS",
  procedure: (arr, prop) => {
    const { NewValue, OldValue, ...rest } = prop;

    const { added, removed } = getArrayUpdatesFromProp(prop);

    const TagsComponent = ({ value }) => {
      return (
        <div className="d-flex ">
          {/* <div className="mb-2">{text}</div> */}
          {value.map((v) => {
            return (
              <div
                key={v.Id}
                className="ar-dropdown-button-value d-flex mr-2 align-items-center"
              >
                {v.Name}
              </div>
            );
          })}
        </div>
      );
    };

    if (added.length === 0 && removed.length === 0) {
      arr.push(
        <EditItem
          property={{ ...rest, NewValue, OldValue }}
          NameValue={"TAGS"}
          key={"newTags"}
          ValueComponent={TagsComponent}
          // ValueExists={ValueExists}
        />
      );
    }

    if (added.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, NewValue: added }}
          NameValue={"TAGS"}
          key={"newTags"}
          ValueComponent={TagsComponent}
          // ValueExists={ValueExists}
        />
      );

    if (removed.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, OldValue: removed }}
          NameValue={"TAGS"}
          key={"removedTags"}
          ValueComponent={TagsComponent}
          // ValueExists={ValueExists}
        />
      );
  }
};

/*-----------------------------------Organizations-----------------------------------*/
const OrganizationTypes = {
  Company: 1,
  Department: 2,
  Team: 3
};
const OrganizationTitles = {
  [OrganizationTypes.Company]: "COMPANIES",
  [OrganizationTypes.Department]: "DEPARTMENTS",
  [OrganizationTypes.Team]: "TEAMS"
};

const OrganizationAggregation = ({ value }) => {
  return (
    <div>
      {/* <div className="mb-2">{text}</div> */}
      {value.map((v) => {
        const { OrganizationSpaceTypeId } = v;

        let comp;

        switch (OrganizationSpaceTypeId) {
          case OrganizationTypes.Company:
            comp = <OrganizationProfile organization={v} />;
            break;

          case OrganizationTypes.Department:
            comp = <DepartmentProfile department={v} />;
            break;

          case OrganizationTypes.Team:
            comp = <TeamProfile team={v} />;
            break;

          default:
            break;
        }

        return (
          <div
            key={v.Id}
            className="d-inline-block vertical-align-middle my-1 mr-2"
          >
            {comp}
          </div>
        );
      })}
    </div>
  );
};

export const Organizations = {
  procedure: (arr, prop) => {
    const { NewValue = [], OldValue = [], ...rest } = prop;

    const enumDict = {};

    const updateDict = (arr, propName) => {
      for (const org of arr) {
        const { OrganizationSpaceTypeId, Id } = org;

        if (!enumDict[OrganizationSpaceTypeId])
          enumDict[OrganizationSpaceTypeId] = {};

        const orgDict = enumDict[OrganizationSpaceTypeId];

        if (!orgDict[propName]) orgDict[propName] = {};

        orgDict[propName][Id] = true;
      }
    };

    updateDict(OldValue, "old");
    updateDict(NewValue, "new");

    const arraysDict = {};

    const exists = (org, propName) => {
      const { OrganizationSpaceTypeId, Id } = org;

      const typeDict = enumDict[OrganizationSpaceTypeId];

      if (!typeDict) return false;

      if (!typeDict[propName]) return false;

      if (!typeDict[propName][Id]) return false;

      return true;
    };

    const addToArray = (org, propName) => {
      const { OrganizationSpaceTypeId } = org;

      if (!arraysDict[OrganizationSpaceTypeId])
        arraysDict[OrganizationSpaceTypeId] = {};

      if (!arraysDict[OrganizationSpaceTypeId][propName])
        arraysDict[OrganizationSpaceTypeId][propName] = [];

      arraysDict[OrganizationSpaceTypeId][propName].push(org);
    };

    for (const org of OldValue) {
      //value was removed
      if (!exists(org, "new")) addToArray(org, "removed");
    }

    for (const org of NewValue) {
      //value was added
      if (!exists(org, "old")) addToArray(org, "added");
    }

    ////;

    for (const type in arraysDict) {
      if (arraysDict.hasOwnProperty(type)) {
        const TypeChanges = arraysDict[type];

        if (TypeChanges.added)
          arr.push(
            <EditItem
              property={{ ...rest, NewValue: TypeChanges.added }}
              NameValue={OrganizationTitles[type]}
              key={`org-${type}-added`}
              ValueComponent={OrganizationAggregation}
              // ValueExists={ValueExists}
            />
          );
        if (TypeChanges.removed)
          arr.push(
            <EditItem
              property={{ ...rest, OldValue: TypeChanges.removed }}
              NameValue={OrganizationTitles[type]}
              key={`org-${type}-removed`}
              ValueComponent={OrganizationAggregation}
              // ValueExists={ValueExists}
            />
          );
      }
    }

    //   for (const org of OldValue) {
    //     const { OrganizationSpaceTypeId, Id } = org;

    //     if (!enumDict[OrganizationSpaceTypeId])
    //       enumDict[OrganizationSpaceTypeId] = {};

    //     const orgDict = enumDict[OrganizationSpaceTypeId];

    //     orgDict.removedValues[Id] = true;
    //   }

    //   for (const org of NewValue) {
    //     const { OrganizationSpaceTypeId, Id } = org;

    //     if (!enumDict[OrganizationSpaceTypeId])
    //       enumDict[OrganizationSpaceTypeId] = {};

    //     const orgDict = enumDict[OrganizationSpaceTypeId];

    //     orgDict.addedValues[Id] = true;
    //   }
    // }
  }
};

/*-----------------------------------Contacts--------------------------------*/

export const Contacts = {
  procedure: (arr, prop) => {
    const { NewValue, OldValue, ...rest } = prop;

    const { added, removed } = getArrayUpdatesFromProp(prop);

    const ContactComponent = ({ value }) => {
      return (
        <div>
          {/* <div className="mb-2">{text}</div> */}
          {value.map((v) => {
            return (
              <div
                key={v.Id}
                className="d-inline-block vertical-align-middle my-1 mr-2"
              >
                <ContactProfile contact={v} />
              </div>
            );
          })}
        </div>
      );
    };

    if (added.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, NewValue: added }}
          NameValue={"CONTACTS"}
          key={"newContacts"}
          ValueComponent={ContactComponent}
          // ValueExists={ValueExists}
        />
      );

    if (removed.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, OldValue: removed }}
          NameValue={"CONTACTS"}
          key={"removedContacts"}
          ValueComponent={ContactComponent}
          // ValueExists={ValueExists}
        />
      );
  }
};

/*-----------------------------------Files-----------------------------------*/
export const Files = {
  procedure: (arr, prop) => {
    const { NewValue, OldValue, ...rest } = prop;

    const { added, removed } = getArrayUpdatesFromProp(prop);

    if (added.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, NewValue: added }}
          NameValue={"FILES"}
          key={"newFiles"}
          ValueComponent={({ value }) => (
            <FileUploader extendedMode preview value={value} />
          )}
        />
      );

    if (removed.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, OldValue: removed }}
          NameValue={"FILES"}
          key={"oldFiles"}
          ValueComponent={({ value }) => (
            <FileUploader extendedMode preview value={value} />
          )}
        />
      );
  }
};

/*-----------------------------------Billing TimeCharges---------------------*/
const BillingTimeChargeAgregation = ({ value }) => {
  return (
    <div>
      {value.map((v) => {
        const { TimeCharge, InputtedDuration } = v;

        const { Id } = TimeCharge;

        return (
          <div key={Id} className="mb-2">
            <div className="d-flex">
              <InterventionIcon />
              <div className="ml-2">{Id}</div>
            </div>
            <div>
              <FormattedMessage id={"INPUTED_HOURS"} />:{" "}
              <FormattedTimePreview value={InputtedDuration} asHourMinute />{" "}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const BillingTimeCharges = {
  procedure: (arr, prop) => {
    const { NewValue = [], OldValue = [], ...rest } = prop;

    const oldDict = {};
    const newDict = {};

    for (const v of OldValue) {
      oldDict[v.Id] = v;
    }

    for (const v of NewValue) {
      newDict[v.Id] = v;
    }

    const added = [];
    const removed = [];

    const updatedValues = {
      NewValue: [],
      OldValue: []
    };

    const updatedValuesDict = {};

    const addUpdate = (v) => {
      updatedValuesDict[v.Id] = true;
      updatedValues.NewValue.push(newDict[v.Id]);
      updatedValues.OldValue.push(oldDict[v.Id]);
    };

    const isUpdated = (id) => {
      return updatedValuesDict[id];
    };

    for (const newVal of NewValue) {
      ////;
      if (!Boolean(oldDict[newVal.Id])) {
        added.push(newVal);
      } else {
        if (oldDict[newVal.Id].InputtedDuration !== newVal.InputtedDuration)
          addUpdate(newVal);
      }
    }

    for (const oldVal of OldValue) {
      ////;
      if (!Boolean(newDict[oldVal.Id])) {
        removed.push(oldVal);
      } else if (!isUpdated(oldVal.Id)) {
        if (newDict[oldVal.Id].InputtedDuration !== oldVal.InputtedDuration)
          addUpdate(oldVal);
      }
    }

    if (added.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, NewValue: added }}
          NameValue={"TIME_RECORDS"}
          key={"newBillingTimeCharges"}
          ValueComponent={BillingTimeChargeAgregation}
        />
      );

    if (removed.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, OldValue: removed }}
          NameValue={"TIME_RECORDS"}
          key={"oldBillingTimeCharges"}
          ValueComponent={BillingTimeChargeAgregation}
        />
      );

    if (updatedValues.NewValue.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, ...updatedValues }}
          NameValue={"TIME_RECORDS"}
          key={"updatedBillingTimeCharges"}
          ValueComponent={BillingTimeChargeAgregation}
        />
      );
  }
};

const ContactMediumPhoneNumberPreview = ({ value }) => (
  <FormattedPhoneNumber number={value} />
);

export const ContactMedium = {
  procedure: (arr, prop) => {
    const { NewValue = [], OldValue = [] } = prop;

    let oldEmail;
    let newEmail;

    let oldPhone;
    let newPhone;
    for (const v of OldValue) {
      const { Type, Contact } = v;

      switch (Type) {
        case ContactMediumTypes.Email:
          oldEmail = Contact;
          break;

        case ContactMediumTypes.PhoneNumber:
          oldPhone = Contact;
          break;

        default:
          break;
      }
    }

    for (const v of NewValue) {
      const { Type, Contact } = v;

      switch (Type) {
        case ContactMediumTypes.Email:
          newEmail = Contact;
          break;

        case ContactMediumTypes.PhoneNumber:
          newPhone = Contact;
          break;

        default:
          break;
      }
    }

    if (oldPhone !== newPhone)
      arr.push(
        <EditItem
          property={{ OldValue: oldPhone, NewValue: newPhone }}
          NameValue={"PHONE_NUMBER"}
          key={"telephone"}
          ValueComponent={ContactMediumPhoneNumberPreview}
        />
      );

    if (oldEmail !== newEmail)
      arr.push(
        <EditItem
          property={{ OldValue: oldEmail, NewValue: newEmail }}
          NameValue={"Email"}
          key={"email"}
        />
      );
  }
};

export const Addresses = {
  ValueExists: (value) => Array.isArray(value) && Boolean(value[0]),
  NameId: "ADDRESS",
  ValueComponent: ({ value }) => {
    const {
      Address1,
      Address2,
      Country,
      District,
      Latitude,
      Longitude,
      Locality,
      PostalCode
    } = value[0]; // address info
    return (
      // <HTMLEditor value={value} preview />
      <div>
        <div className="mb-2">
          <b>
            <FormattedMessage id={"COUNTRY"} />:
          </b>
          <CountryProfile country={Country} />
        </div>
        <div className="mb-2">
          <b>
            <FormattedMessage id={"ADDRESS"} />:
          </b>
          <div>{Address1}</div>
          {Address2 && <div>{Address2}</div>}
        </div>

        <div className="mb-2">
          <b>
            <FormattedMessage id={"CITY"} />:
          </b>
          <div>{Locality}</div>
        </div>

        <div className="mb-2">
          <b>
            <FormattedMessage id={"REGION_STATE_DISCTRICT"} />:
          </b>
          <div>{District}</div>
        </div>

        <div className="mb-2">
          <b>
            <FormattedMessage id={"POSTAL_CODE"} />:
          </b>
          <div>{PostalCode}</div>
        </div>

        <div>
          <b>
            <FormattedMessage id={"LATITUDE"} />:
          </b>
          <div>{Latitude}</div>
        </div>

        <div>
          <b>
            <FormattedMessage id={"LONGITUDE"} />:
          </b>
          <div>{Longitude}</div>
        </div>
      </div>
    );
  }
};

const CurrenciesEnum = {
  totalValue: 1,
  valuePerHour: 2
};

const buildCurrencyName = (type, Name, Symbol) => {
  const CurrencyTypeName =
    type === CurrenciesEnum.totalValue ? "TOTAL_VALUE" : "VALUE_HOUR";
  const name = ` - ${Name} (${Symbol})`;
  return [name, CurrencyTypeName];
};

export const ContractCurrencies = {
  procedure: (arr, prop) => {
    const { NewValue = [], OldValue = [], ...rest } = prop;

    const newDict = {
      [CurrenciesEnum.totalValue]: {},
      [CurrenciesEnum.valuePerHour]: {}
    };

    const oldDict = {
      [CurrenciesEnum.totalValue]: {},
      [CurrenciesEnum.valuePerHour]: {}
    };

    for (const v of OldValue) {
      const { Type, Currency } = v;
      const { Id } = Currency;
      oldDict[Type][Id] = v;
    }

    for (const v of NewValue) {
      const { Type, Currency } = v;
      const { Id } = Currency;
      newDict[Type][Id] = v;
    }

    const added = [];
    // const removed = [];
    const edited = [];

    // for (const oldVal of OldValue) {
    //   const { Type, Currency } = oldVal;
    //   const { Id } = Currency;
    //   if (!newDict[Type][Id]) added.push(oldVal);
    // }

    for (const newVal of NewValue) {
      const { Type, Currency } = newVal;
      const { Id } = Currency;
      if (!oldDict[Type][Id]) added.push(newVal);
      else {
        if (oldDict[Type][Id].EstimatedCost !== newVal.EstimatedCost)
          edited.push({ newVal, oldVal: oldDict[Type][Id] });
      }
    }

    for (const { Type, Currency, EstimatedCost } of added) {
      const { Name, Symbol } = Currency;
      const [name, type] = buildCurrencyName(Type, Name, Symbol);
      arr.push(
        <EditItem
          property={{
            ...rest,
            NewValue: EstimatedCost
          }}
          NameValueFinal={name}
          NameValueFinalSecond={type}
          key={name}
          ValueComponent={({ value }) => {
            return toCurrencyFromDecimal(value);
          }}
        />
      );
    }

    for (const { newVal, oldVal } of edited) {
      const { Type, Currency, EstimatedCost } = newVal;
      const { Name, Symbol } = Currency;
      const [name, type] = buildCurrencyName(Type, Name, Symbol);

      arr.push(
        <EditItem
          property={{
            ...rest,
            NewValue: EstimatedCost,
            OldValue: oldVal.EstimatedCost
          }}
          NameValueFinal={name}
          NameValueFinalSecond={type}
          key={name}
          ValueComponent={({ value }) => {
            return toCurrencyFromDecimal(value);
          }}
        />
      );
    }
  }
};

const EntityTypesList = ({ value }) => {
  return (
    <div>
      {value.map((v) => {
        return <div key={v.Id}>{v.Name}</div>;
      })}
    </div>
  );
};

export const ClientEntityTypes = {
  procedure: (arr, prop) => {
    const { NewValue = [], OldValue = [], ...rest } = prop;

    const oldDict = {};
    const newDict = {};

    for (const v of OldValue) {
      oldDict[v.Id] = v;
    }

    for (const v of NewValue) {
      newDict[v.Id] = v;
    }

    const added = [];
    const removed = [];
    // const edited = [];

    for (const oldVal of OldValue) {
      if (!newDict[oldVal.Id]) removed.push(oldVal);
    }

    for (const newVal of NewValue) {
      if (!oldDict[newVal.Id]) added.push(newVal);
      // else if (oldDict[newVal.Id].Name !== newVal.Name)
      //   edited.push({ newVal, oldVal: oldDict[newVal.Id] });
    }

    if (added.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, NewValue: added }}
          NameValue={"ACCOUNT_TYPE"}
          key={"newClientEntityTypes"}
          ValueComponent={EntityTypesList}
        />
      );

    if (removed.length > 0)
      arr.push(
        <EditItem
          property={{ ...rest, OldValue: removed }}
          NameValue={"ACCOUNT_TYPE"}
          key={"oldClientEntityTypes"}
          ValueComponent={EntityTypesList}
        />
      );

    // if (edited.length > 0) {
    //   for (const { newVal, oldVal } of edited) {
    //     arr.push(
    //       <EditItem
    //         property={{ ...rest, OldValue: [oldVal], NewValue: [newVal] }}
    //         NameValue={"Tipos de Cliente"}
    //         key={"oldClientEntityTypes"}
    //         ValueComponent={EntityTypesList}
    //       />
    //     );
    //   }
    // }

    ////;
  }
};
