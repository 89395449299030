import React, { useCallback, useContext } from "react";
import { useGlobalLoader } from "../GlobalLoader/GlobalLoader";
import { useSidebar, useSidebarChanges } from "./SidebarV2";
import { useDelete, usePost } from "../../Helpers/IOClient";
import classnames from "classnames";
import { useSidebarView, SidebarVisualTypeEnum } from "./SidebarViewsHelper";
import SidebarBasicCloseButton from "./SidebarBasicCloseButton";
import SidebarViewSelector from "./SidebarViewSelector";

import { useSpace } from "../../Contexts/SpaceContext";
import { useConnectionId } from "../../Containers/RealTime/RealTime";
import { SidebarAutomationContext } from "./SidebarV2Automation";

export const SidebarRefetchContext = React.createContext();
export const SidebarDeleteRefetchContext = React.createContext();

export const useSidebarPost = (endpoint, schema, options = {}) => {
  const [toggleSidebarChanges] = useSidebarChanges();

  const loader = useGlobalLoader();
  const {
    onError,
    onSuccess,
    refetchRef: optionalRefetchRef,
    ...rest
  } = options;
  const Automation = useContext(SidebarAutomationContext);

  const [openSidebar, closeSidebar] = useSidebar();
  const refetchRef = useContext(SidebarRefetchContext) || optionalRefetchRef;
  const conId = useConnectionId();
  const [post, postState] = usePost(endpoint, schema, {
    onSuccess: (args) => {
      let willOpenSidebar = false;

      const open = (...args) => {
        openSidebar(...args);
        willOpenSidebar = true;
      };
      let options;
      const defineChildProps = (props) => {
        options = {
          oldProps: {
            childProps: props
          }
        };
      };

      const disableClosure = () => (willOpenSidebar = true);

      if (refetchRef && refetchRef.current) refetchRef.current();

      if (onSuccess) {
        onSuccess({
          openSidebar: open,
          disableClosure,
          defineChildProps,
          ...args
        });
      }
      toggleSidebarChanges(false);
      loader.stop();
      !willOpenSidebar && closeSidebar(undefined, options);
    },
    onError: (e) => {
      ////;
      if (onError) onError(e);
      loader.stop();
    },
    headers: {
      ConnectionID: conId
    },
    ...rest
  });

  const handlePost = useCallback(
    (...args) => {
      if (Automation) {
        Automation.submit(...args);
      } else {
        loader.start();
        post(...args);
      }
    },
    [Automation, loader, post]
  );
  return [handlePost, postState];
};

export const useSidebarDelete = (endpoint, schema, options = {}) => {
  const loader = useGlobalLoader();
  const {
    onError,
    onSuccess,
    refetchRef: optionalRefetchRef,
    ...rest
  } = options;
  const [openSidebar, closeSidebar] = useSidebar();
  const refetchRef =
    useContext(SidebarDeleteRefetchContext) || optionalRefetchRef;
  const conId = useConnectionId();
  const [eliminate, eliminateState] = useDelete(endpoint, schema, {
    onSuccess: (args) => {
      let willOpenSidebar = false;

      const open = (...args) => {
        openSidebar(...args);
        willOpenSidebar = true;
      };
      let options;
      const defineChildProps = (props) => {
        options = {
          oldProps: {
            childProps: props
          }
        };
      };

      const disableClosure = () => (willOpenSidebar = true);

      if (refetchRef && refetchRef.current) refetchRef.current();

      if (onSuccess) {
        onSuccess({
          openSidebar: open,
          disableClosure,
					closeSidebar,
          defineChildProps,
          ...args
        });
      }

      loader.stop();
      !willOpenSidebar && closeSidebar(undefined, options);
    },
    onError: (e) => {
      ////;
      if (onError) onError(e);
      loader.stop();
    },
    headers: {
      ConnectionID: conId
    },
    ...rest
  });

  const handlePost = (...args) => {
    loader.start();
    eliminate(...args);
  };
  return [handlePost, eliminateState];
};

export const useSidebarSpacePost = (...rest) => {
  const endpoint = rest[0];

  const space = useSpace();
  const resolvedEndpoint = !endpoint
    ? undefined
    : `spaces/${space.Id}/${endpoint}`;
  rest[0] = resolvedEndpoint;
  return useSidebarPost(...rest);
};
export const useSidebarSpaceDelete = (...rest) => {
  const endpoint = rest[0];

  const space = useSpace();
  const resolvedEndpoint = !endpoint
    ? undefined
    : `spaces/${space.Id}/${endpoint}`;
  rest[0] = resolvedEndpoint;
  return useSidebarDelete(...rest);
};

export const SidebarContainer = ({ children, className = "", fullWidth }) => {
  // const sidebarView = useSidebarView();

  // if (sidebarView === SidebarVisualTypeEnum.modal) {
  //   return (
  //     <div className={classnames(" bg-white d-flex flex-column", className)}>
  //       {children}
  //     </div>
  //   );
  // }

  return (
    <div className={classnames("bg-white d-flex flex-column h-100", className)}>
      {children}
    </div>
  );
};

const BaseSidebarWidthContext = React.createContext();

export const BaseSidebarFooter = ({ className, children }) => {
  return (
    <div
      className={classnames(
        "py-3 px-4 d-flex align-items-center shadow-top",
        className
      )}
    >
      {children}
    </div>
  );
};

export const BaseSidebarContainer = ({
  children,
  className = "",
  defaultSidebarView = "",
  width = 520
}) => {
  const sidebarView = useSidebarView();

  if (
    sidebarView === SidebarVisualTypeEnum.fullscreen ||
    defaultSidebarView === SidebarVisualTypeEnum.fullscreen
  )
    return (
      <BaseSidebarWidthContext.Provider value={width}>
        <div
          className={classnames("bg-white d-flex flex-column h-100", className)}
        >
          {children}
        </div>
      </BaseSidebarWidthContext.Provider>
    );

  return (
    <div
      className={classnames("bg-white d-flex flex-column h-100", className)}
      style={{ width }}
    >
      {children}
    </div>
  );
};

export const BaseSidebarHeader = ({
  children,
  className = "",
  disableSizeSelector = false,
  ...rest
}) => {
  const sidebarView = useSidebarView();

  const showCloseButton = sidebarView === SidebarVisualTypeEnum.fullscreen;

  return (
    <div
      className={classnames(
        "ar-sidebar-header text-black fw-medium fs-18 d-flex justify-content-between align-items-center",
        className,
        showCloseButton ? "pr-3" : "px-3"
      )}
      {...rest}
    >
      <div className="d-flex align-items-center w-100">
        {showCloseButton && (
          <SidebarBasicCloseButton className="pl-3 mr-3 h-100 d-flex align-items-center" />
        )}
        {children}
      </div>
      {!disableSizeSelector && <SidebarViewSelector />}
    </div>
  );
};

export const BaseSidebarContent = ({ children, className = "" }) => {
  const sidebarWidth = useContext(BaseSidebarWidthContext);
  const sidebarView = useSidebarView();

  if (sidebarView === SidebarVisualTypeEnum.fullscreen)
    return (
      <div
        style={{ width: sidebarWidth }}
        className={classnames("flex-1 of-y-auto shadow", className)}
      >
        {children}
      </div>
    );

  return (
    <div className={classnames("flex-1 of-y-auto", className)}>{children}</div>
  );
};
