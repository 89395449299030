import { spaceSignatureAggregationSchema } from "../../../config/schema";
import { getEntity } from "../../../Helpers/IOClient";
import { createFormData } from "../../../Helpers/MiscHelper";

export const formateEmailForm = (form) => {
  const {
    Text,
    Subject,
    To,
    Cc,
    Bcc,
    Company,
    Client,
    Tasks,
    Tickets,
    Deals,
    ticketId,
    Signature,
    Followers,
    dealId,
    taskId,
    Files
  } = form;

  let resolvedTickets = Tickets;
  let resolvedDeals = Deals;
  let resolvedTasks = Tasks;

  if (ticketId) resolvedTickets = [ticketId];
  if (dealId) resolvedDeals = [dealId];
  if (taskId) resolvedTasks = [taskId];

  let resolvedSignature = undefined;
  if (Signature) {
    const { type, data } = Signature;
    const connectedSignature = getEntity(spaceSignatureAggregationSchema, data);
    const { Company, Department, Team, SignatureId } = connectedSignature || {};
    let OrganizationId = undefined;
    switch (type) {
      case "Company":
        OrganizationId = Company;
        break;
      case "Department":
        OrganizationId = Department;
        break;
      case "Team":
        OrganizationId = Team;
        break;
      default:
        break;
    }

    resolvedSignature = {
      OrganizationId,
      Id: SignatureId
    };
  }

  return createFormData(
    {
      ClientId: Client,
      OrganizationSpaceId: Company,
      Text,
      Subject,
      Followers,
      To: JSON.stringify(To),
      Cc: JSON.stringify(Cc),
      Bcc: JSON.stringify(Bcc),
      TaskIds: JSON.stringify(resolvedTasks),
      TicketIds: JSON.stringify(resolvedTickets),
      DealIds: JSON.stringify(resolvedDeals),
      Signature: JSON.stringify(resolvedSignature)
    },
    Files
  );
};

export const defaultEmailForm = () => {
  return {
    Company: null,
    Client: null,
    Signature: null,
    To: [],
    Cc: [],
    Bcc: [],
    Subject: "",
    Text: "",
    Tickets: [],
    Tasks: [],
    Deals: []
  };
};

export const formatAutomationEmailForm = ({ form, currencies }) => {
  const {
    Text,
    Subject,
    To,
    Cc,
    ToManual,
    CcManual,
    BccManual,
    enableManualBCC,
    enableManualCC,
    enableManualTo,
    Bcc,
    Company,
    Client,
    Creator,
    // Tasks,
    Signature
    // Tickets,
    // Deals,
  } = form;

  let resolvedSignature = {};
  if (Signature) {
    const { type, data } = Signature;
    const connectedSignature = getEntity(spaceSignatureAggregationSchema, data);
    const { Company, Department, Team, SignatureId, Id } =
      connectedSignature || {};
    let OrganizationId = undefined;
    switch (type) {
      case "Company":
        OrganizationId = Company;
        resolvedSignature = {
          Type: "Company",
          OrganizationId,
          Id: SignatureId,
          UniqId: Id
        };
        break;
      case "Department":
        OrganizationId = Department;
        resolvedSignature = {
          Type: "Department",
          OrganizationId,
          Id: SignatureId,
          UniqId: Id
        };
        break;
      case "Team":
        OrganizationId = Team;
        resolvedSignature = {
          Type: "Team",
          OrganizationId,
          UniqId: Id,
          Id: SignatureId
        };
        break;
      default:
        break;
    }
  }

  const resolveForm = {
    organization_space_id: Company,
    creator: Creator,
    client: Client,
    signature: resolvedSignature,
    subject: Subject,
    text: Text,
    enableManualBCC,
    enableManualCC,
    enableManualTo
  };

  if (enableManualTo) {
    resolveForm.to = ToManual;
  } else resolveForm.to_ids = Array.isArray(To) ? To.join(",") : To;

  if (enableManualCC) {
    resolveForm.cc = CcManual;
  } else resolveForm.cc_ids = Array.isArray(Cc) ? Cc.join(",") : Cc;

  if (enableManualBCC) {
    resolveForm.bcc = BccManual;
  } else resolveForm.bcc_ids = Array.isArray(Bcc) ? Bcc.join(",") : Bcc;

  return resolveForm;
};

export const formatAutomationEmailConversationForm = ({ form, currencies }) => {
  const {
    Text,
    Subject,
    To,
    Cc,
    ConversationTarget,
    ToManual,
    CcManual,
    BccManual,
    enableManualBCC,
    enableManualCC,
    enableManualTo,
    Bcc,
    Company,
    Client,
    Creator,
    Followers,
    Tasks,
    Tickets,
    Deals,
    // Tasks,
    Signature
    // Tickets,
    // Deals,
  } = form;

  let resolvedSignature = {};
  if (Signature) {
    const { type, data } = Signature;
    const connectedSignature = getEntity(spaceSignatureAggregationSchema, data);
    const { Company, Department, Team, SignatureId, Id } =
      connectedSignature || {};
    let OrganizationId = undefined;
    switch (type) {
      case "Company":
        OrganizationId = Company;
        resolvedSignature = {
          type: "Company",
          organization_id: OrganizationId,
          id: SignatureId,
          uniq_id: Id
        };
        break;
      case "Department":
        OrganizationId = Department;
        resolvedSignature = {
          type: "Department",
          organization_id: OrganizationId,
          id: SignatureId,
          uniq_id: Id
        };
        break;
      case "Team":
        OrganizationId = Team;
        resolvedSignature = {
          type: "Team",
          organization_id: OrganizationId,
          uniq_id: Id,
          id: SignatureId
        };
        break;
      default:
        break;
    }
  }

  const resolveForm = {
    organization_space_id: Company,
    creator: Creator,
    client: Client,
    signature: resolvedSignature,
    subject: Subject,
    text: Text,
    enableManualBCC,
    followers: Array.isArray(Followers) ? Followers.join(",") : Followers,
    enableManualCC,
    target: ConversationTarget,
    enableManualTo
  };

  if (enableManualTo) {
    resolveForm.to = ToManual;
  } else resolveForm.to_ids = Array.isArray(To) ? To.join(",") : To;

  if (enableManualCC) {
    resolveForm.cc = CcManual;
  } else resolveForm.cc_ids = Array.isArray(Cc) ? Cc.join(",") : Cc;

  if (enableManualBCC) {
    resolveForm.bcc = BccManual;
  } else resolveForm.bcc_ids = Array.isArray(Bcc) ? Bcc.join(",") : Bcc;

  return resolveForm;
};
