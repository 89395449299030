import React from "react";
import { RealTimeRoomTypes } from "../RealTime/RealTimeHelper";
import {
  spaceProjectsSchema,
  spaceTasksSchema,
  spaceClientSchema,
  spaceDealsSchema,
  spaceContractSchema,
  spaceSubscriptionSchema,
  spaceCallsSchema,
  spaceTicketsSchema,
  spaceInterventionsSchema,
} from "../../config/schema";
import { InnerProjectSummary } from "../Projects/ProjectSummary/ProjectSummary";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import {
  ProjectStatusProfile,
  ProjectStatusProfileWithIcon,
} from "../../Components/Projects/ProjectProfile";
import {
  ProjectIcon,
  TaskIcon,
  ContractIcon,
  SubscriptionIcon,
  CallIcon,
  TicketIcon,
  InterventionIcon,
} from "../../Components/Common/EntityIcons";
import { InnerTaskSummary } from "../Tasks/TaskSummary";
import { InnerClientSummary } from "../Clients/ClientSummary";
import {
  ClientStatusProfile,
  ClientStatusProfileWithIcon,
} from "../../Components/Clients/ClientProfile";
import { InnerDealSummary } from "../Deals/DealSummary";
import DealStatusProfile, {
  DealStatusProfileWithIcon,
} from "../../Components/Deals/DealStatusProfile";
import { InnerContractSummary } from "../Contracts/ContractSummary";
import {
  ContractStatusProfile,
  ContractStatusProfileWithIcon,
} from "../../Components/Contract/ContractProfile";
import { InnerSubscriptionSummary } from "../Subscriptions/SubscriptionSummary";
import { SubscriptionStatusProfileWithIcon } from "../../Components/Subscription/SubscriptionProfile";
import { InnerCallSummary } from "../Calls/CallSummary";
import { InnerTicketSummary } from "../Tickets/TicketSummary";
import { InnerInterventionSummary } from "../Interventions/InterventionSummary";
import { InboxTypeEnum } from "./ReceptionHelpers";
import { SubscriptionStatusProfile } from "../../Components/DetailsView/History/HistoryItem/SubscriptionHistoryItem";
import { ClientEntityActionsButton } from "../Clients/ClientsHelper";
import { DealEntityActionsButton } from "../Deals/DealsHelper";
import { ContractEntityActionsButton } from "../Contracts/ContractsHelper";
import { CallEntityActionsButton } from "../Calls/CallsHelper";
import { TicketEntityActionsButton } from "../Tickets/TicketsHelper";
import { InterventionEntityActionsButton } from "../Interventions/InterventionsHelper";
import { ProjectEntityActionsButton } from "../Projects/ProjectsHelper";
import { TaskEntityActionsButton } from "../Tasks/TasksHelper";
export const PostalEmailStatusModelEventEnum = {
  Default: 0,
  MessageSent: 1,
  MessageDelayed: 2,
  MessageDeliveryFailed: 3,
  MessageHeld: 4,
  MessageBounced: 5,
};

export const clientSettings = {
  ActionButton: ClientEntityActionsButton,
  roomType: RealTimeRoomTypes.Client,
  schema: spaceClientSchema,
  type: "client",
  ConversationType: InboxTypeEnum.Client,
  SummaryComponent: InnerClientSummary,
  moduleType: moduleTypes.clients,
  StatusComponent: ({ status }) => (
    <ClientStatusProfileWithIcon size={40} hideName value={status} />
  ),
  StatusComponentNoIcon: ({ status }) => (
    <ClientStatusProfile hideName status={status} />
  ),
};

export const dealSettings = {
  ActionButton: DealEntityActionsButton,
  roomType: RealTimeRoomTypes.Deal,
  schema: spaceDealsSchema,
  type: "deal",
  ConversationType: InboxTypeEnum.Deal,
  SummaryComponent: InnerDealSummary,
  moduleType: moduleTypes.deals,
  StatusComponent: ({ status }) => (
    <DealStatusProfileWithIcon size={40} hideName value={status} />
  ),
  StatusComponentNoIcon: ({ status }) => (
    <DealStatusProfile hideName status={status} />
  ),
};

export const contractSettings = {
  ActionButton: ContractEntityActionsButton,
  roomType: RealTimeRoomTypes.Contract,
  schema: spaceContractSchema,
  type: "contract",
  ConversationType: InboxTypeEnum.Contract,
  SummaryComponent: InnerContractSummary,
  moduleType: moduleTypes.contracts,
  TextComponent: ({ value }) => {
    const { NoTagDescription } = value;

    return NoTagDescription || null;
  },
  StatusComponent: ({ status }) => (
    <ContractStatusProfileWithIcon
      size={40}
      hideName
      value={status}
      Icon={<ContractIcon />}
    />
  ),
  StatusComponentNoIcon: ({ status }) => (
    <ContractStatusProfile hideName status={status} />
  ),
  noImage: true,
};

export const subscriptionSettings = {
  // ActionButton: ContractEntityActionsButton,
  roomType: RealTimeRoomTypes.Subscription,
  schema: spaceSubscriptionSchema,
  type: "subscription",
  ConversationType: InboxTypeEnum.Subscription,
  SummaryComponent: InnerSubscriptionSummary,
  TextComponent: ({ value }) => {
    const { NoTagDescription } = value;

    return NoTagDescription || null;
  },
  moduleType: moduleTypes.subscriptions,
  StatusComponent: ({ status }) => (
    <SubscriptionStatusProfileWithIcon
      size={40}
      hideName
      value={status}
      Icon={<SubscriptionIcon />}
    />
  ),
  StatusComponentNoIcon: ({ status }) => (
    <SubscriptionStatusProfile hideName status={status} />
  ),
  noImage: true,
};

export const callSettings = {
  ActionButton: CallEntityActionsButton,
  roomType: RealTimeRoomTypes.Call,
  schema: spaceCallsSchema,
  type: "call",
  ConversationType: InboxTypeEnum.Call,
  SummaryComponent: InnerCallSummary,
  TextComponent: ({ value }) => {
    const { NoTagDescription } = value;

    return NoTagDescription || null;
  },
  moduleType: moduleTypes.calls,
  StatusComponent: ({ status }) => (
    <ProjectStatusProfileWithIcon
      size={40}
      hideName
      value={status}
      Icon={<CallIcon />}
    />
  ),
  StatusComponentNoIcon: ({ status }) => (
    <ProjectStatusProfile hideName status={status} />
  ),
  noImage: true,
};

export const ticketSettings = {
  ActionButton: TicketEntityActionsButton,
  roomType: RealTimeRoomTypes.Ticket,
  schema: spaceTicketsSchema,
  type: "ticket",
  ConversationType: InboxTypeEnum.Ticket,
  SummaryComponent: InnerTicketSummary,
  // TextComponent: ({ value }) => {
  //   const { NoTagDescription } = value;

  //   return NoTagDescription || null;
  // },
  moduleType: moduleTypes.tickets,
  StatusComponent: ({ status }) => (
    <ProjectStatusProfileWithIcon
      size={40}
      hideName
      value={status}
      Icon={<TicketIcon />}
    />
  ),
  StatusComponentNoIcon: ({ status }) => (
    <ProjectStatusProfile hideName status={status} />
  ),
};

export const timeChargeSettings = {
  ActionButton: InterventionEntityActionsButton,
  roomType: RealTimeRoomTypes.TimeCharge,
  schema: spaceInterventionsSchema,
  type: "intervention",
  ConversationType: InboxTypeEnum.Time,
  SummaryComponent: InnerInterventionSummary,
  TextComponent: ({ value }) => {
    const { NoTagDescription } = value;

    return NoTagDescription || null;
  },
  moduleType: moduleTypes.interventions,
  StatusComponent: ({ status }) => (
    <ProjectStatusProfileWithIcon
      size={40}
      hideName
      value={1}
      Icon={<InterventionIcon />}
    />
  ),
  StatusComponentNoIcon: ({ status }) => (
    <ProjectStatusProfile hideName status={status} />
  ),
  noImage: true,
};

export const projectSettings = {
  ActionButton: ProjectEntityActionsButton,
  roomType: RealTimeRoomTypes.Project,
  schema: spaceProjectsSchema,
  type: "project",
  ConversationType: InboxTypeEnum.Project,
  SummaryComponent: InnerProjectSummary,
  moduleType: moduleTypes.projects,
  StatusComponent: ({ status }) => (
    <ProjectStatusProfileWithIcon
      size={40}
      Icon={<ProjectIcon />}
      hideName
      status={status}
    />
  ),
  StatusComponentNoIcon: ({ status }) => (
    <ProjectStatusProfile hideName status={status} />
  ),
};

export const taskSettings = {
  ActionButton: TaskEntityActionsButton,
  roomType: RealTimeRoomTypes.Task,
  schema: spaceTasksSchema,
  type: "task",
  ConversationType: InboxTypeEnum.Task,
  SummaryComponent: InnerTaskSummary,
  moduleType: moduleTypes.tasks,
  StatusComponent: ({ status }) => (
    <ProjectStatusProfileWithIcon
      size={40}
      Icon={<TaskIcon />}
      hideName
      status={status}
    />
  ),
  StatusComponentNoIcon: ({ status }) => (
    <ProjectStatusProfile hideName status={status} />
  ),
};
