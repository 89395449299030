import React, { useMemo } from "react";
import {
  faAnalytics,
  faUsers,
  faInfoSquare,
  faCity
} from "@fortawesome/pro-solid-svg-icons";
import {
  spaceContractSchema,
  spaceEmailReceptionSchema
} from "../../config/schema";
import {
  useContractType,
  useCancelationReason,
  usePipelineStatus
} from "../../Hooks/EntityHooks";
import {
  faCalendarAlt,
  faUsers as faUsersLight,
  faAnalytics as faAnalyticsLight,
  faHandshake,
  faFileAlt,
  faBuilding,
  faUserTie,
  faClipboard,
  faUsersClass,
  faCarBuilding,
  faRepeatAlt,
  faClock,
  faLink,
  faBusinessTime,
  faWatchCalculator,
  faRetweet,
  faUserCog,
  faSuitcase,
  faBell,
  faUser,
  faDrawCircle,
  faTicketSimple,
  faEnvelope
} from "@fortawesome/pro-light-svg-icons";
import {
  EntityOverviewItem,
  EntityDescriptionOverviewItem,
  EntityOverviewRoundedItem,
  EntityOverviewPlaceholder,
  CurrencyValuesAccordion,
  EntityOverviewChildrenProgress
} from "../../Components/EntityOverview/EntityOverview";
import { GridColloredEndDateComponent } from "../../Helpers/GridHelper";
import RelativeTime from "../../Components/RelativeTime/RelativeTime";
import { ConnectedOrganizationProfile } from "../../Components/Organizations/OrganizationProfile";
import {
  EntitySummaryAccordion,
  DescriptionOverview,
  EntitySummaryFilesAccordion,
  EntitySummaryTabstrip,
  EntitySummaryTab,
  EntitySummaryOverview,
  EntityPipelineHistoryAccordion
} from "../../Components/EntitySummaries/EntitySummaries";
import {
  ConnectedDepartmentProfileStack,
  ConnectedAccountsTeamsDepartmentsProfileStack,
  ConnectedAccountProfileStack
} from "../../Components/Accounts/AccountProfileStack";
import OriginProfile from "../Origin/OriginProfile";
import { CovenantPeriodicity } from "../../Components/CovenantSelector/CovenantSelector";
import TimeTablePicker from "../../Components/TimetablePicker/TimeTablePicker";
import { History } from "../../Components/DetailsView/History/History";
import ContractForm from "./ContractForm";
import {
  ContractEntityActionsButton,
  OutsideContractHours,
  HourControll
} from "./ContractsHelper";
import { EntityOverviewPipeline } from "../../Components/EntityOverview/EntityOverviewPipeline";
import {
  ContractStatusProfile,
  buildContractStatusType,
  ContractStatusTypes
} from "../../Components/Contract/ContractProfile";
import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
import SpentHoursPreview from "../../Components/SpentHoursPreview/SpentHoursPreview";
import {
  MilSecToFormatedHourMinute,
  MilSecToHour,
  MilSecToMinute
} from "../../Helpers/MiscHelper";
import Treeview from "../Treeview/Treeview";
import { ContractDetailsChildren } from "./ContractDetails/ContractDetailsChildren";
import { ConnectedContactPopup } from "../Origin/ContactPopup";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import { RealTimeRoomTypes } from "../RealTime/RealTimeHelper";
import { FormattedMessage } from "react-intl";
import { ContractFormButton } from "../Form/FormButtons";
import { getInterventionTarget } from "../../Helpers/FormFormmaterHelper";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { hasContractFinancialPermission } from "../../Helpers/ModulesHelper";
import { getEntity } from "../../Helpers/IOClient";

export const ContractSummaryHeader = ({ value }) => {
  const { Description } = value;

  return Description;
};

export const ContractSummaryContent = ({ value }) => {
  const {
    Pipeline,
    PipelineStatus,
    StatusDetail,
    Manager,
    SalesOpportunity,
    // SupportEmail,
    // InboundEmail,
    BeginDate,
    Company,
    Contact,
    ReportEmail,
    // ReceivedDate,
    NoTagDescription,
    Description,
    InternalDescription,
    NoTagInternalDescription,
    Files,
    TicketLimit,
    Departments,
    ContractType: contractTypeId,
    Collaborators,
    Deslocations,
    Covenant,
    Schedules,
    Client,
    IndirectClient,
    SpentHours,
    EstimatedCost,
    LeftDurationNotInRange,
    RightDurationNotInRange,
    TimeChargesDurationByRenovation,
    PeriodTime,
    NextRenovation,
    IntervalMinimum,
    Creator,
    RoundingMargin,
    EstimatedCosts,
    CanEdit,
    IsFollowing,
    EstimatedCostsHour,
    Id,
    InboundEmails,
    StatusDetailDescription,
    Followers,
    CreationDate
  } = value;

  const { Accounts, Teams } = Collaborators;

  const contractType = useContractType(contractTypeId);
  const currentAccountSpace = useCurrentAccountSpace();
  const PrivateFinancialPermission =
    hasContractFinancialPermission(currentAccountSpace);
  const origin = useMemo(() => getInterventionTarget(value), [value]);
  const pipelineStatusObj = usePipelineStatus(PipelineStatus);
  const detail = useCancelationReason(StatusDetail);

  let resolvedInboundEmail = null;

  if (Array.isArray(InboundEmails) && InboundEmails.length > 0) {
    const resolvedEmailList = InboundEmails.map((e) => {
      const resolEmailObject = getEntity(spaceEmailReceptionSchema, e);
      return resolEmailObject.Email;
    });
    resolvedInboundEmail = resolvedEmailList.join(", ");
  }

  return (
    <>
      <EntitySummaryAccordion icon={faAnalytics} textId="STATUS">
        <EntityOverviewPipeline
          StatusProfileComponent={ContractStatusProfile}
          buildFunction={buildContractStatusType}
          pipeline={Pipeline}
          pipelineStatus={PipelineStatus}
        />

        {StatusDetail &&
          pipelineStatusObj.Status === ContractStatusTypes.canceled && (
            <EntityOverviewRoundedItem
              icon={faDrawCircle}
              titleId="CANCELATION_REASON"
            >
              {detail.Name}
            </EntityOverviewRoundedItem>
          )}
        {StatusDetailDescription &&
          pipelineStatusObj.Status === ContractStatusTypes.canceled && (
            <EntityDescriptionOverviewItem
              icon={faDrawCircle}
              titleId="CANCELATION_DESCRIPTION"
            >
              <DescriptionOverview
                Description={StatusDetailDescription}
                NoTagDescription={StatusDetailDescription}
              />
            </EntityDescriptionOverviewItem>
          )}

        <EntityOverviewItem icon={faCalendarAlt} titleId="CONCLUSION">
          <GridColloredEndDateComponent Data={value} />
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faInfoSquare} textId={"DETAILS"}>
        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="START">
          <RelativeTime date={BeginDate} />
        </EntityOverviewRoundedItem>

        <EntityOverviewRoundedItem icon={faTicketSimple} titleId="TICKET_LIMIT">
          {TicketLimit ? TicketLimit : "-"}
        </EntityOverviewRoundedItem>

        {resolvedInboundEmail && (
          <EntityOverviewRoundedItem icon={faEnvelope} titleId="SUPPORT_EMAIL">
            {resolvedInboundEmail ? resolvedInboundEmail : "-"}
          </EntityOverviewRoundedItem>
        )}

        <EntityOverviewRoundedItem icon={faClock} titleId="SPENT_HOURS">
          {MilSecToFormatedHourMinute(SpentHours)}
        </EntityOverviewRoundedItem>

        <EntityOverviewItem icon={faAnalyticsLight} titleId="PERFORMANCE">
          <SpentHoursPreview
            onlyPercentage
            SpentHours={SpentHours}
            EstimatedHours={EstimatedCost}
          />
        </EntityOverviewItem>

        <EntityOverviewRoundedItem
          icon={faBusinessTime}
          titleId="ESTIMATED_HOURS_ABRV"
        >
          {MilSecToHour(EstimatedCost, true)}
        </EntityOverviewRoundedItem>

        <EntityOverviewItem icon={faClock} titleId="OUT_OF_CONTRACT_HOURS_ABRV">
          <OutsideContractHours
            placeholder={<EntityOverviewPlaceholder rounded />}
            LeftDurationNotInRange={LeftDurationNotInRange}
            RightDurationNotInRange={RightDurationNotInRange}
            Entity={value}
          />
        </EntityOverviewItem>

        <EntityOverviewItem icon={faCalendarAlt} titleId="CONTROL_HOURS">
          <HourControll
            placeholder={<EntityOverviewPlaceholder rounded />}
            Entity={value}
            TimeChargesDurationByRenovation={TimeChargesDurationByRenovation}
            PeriodTime={PeriodTime}
          />
        </EntityOverviewItem>

        <EntityOverviewRoundedItem
          icon={faWatchCalculator}
          titleId="MAXIMUM_PERIOD_HOURS_ABRV"
        >
          {PeriodTime ? MilSecToHour(PeriodTime) : "-"}
        </EntityOverviewRoundedItem>

        <EntityOverviewItem icon={faRetweet} titleId="NEXT_PERIOD">
          {NextRenovation ? (
            <GridColloredEndDateComponent
              Data={{
                BeginDate: new Date(),
                EndDate: NextRenovation
              }}
            />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>

        {contractType && (
          <EntityOverviewRoundedItem icon={faClipboard} titleId="TYPE">
            {contractType ? `${contractType.Type} - ${contractType.Name}` : "-"}
          </EntityOverviewRoundedItem>
        )}

        <EntityOverviewRoundedItem icon={faCarBuilding} titleId="TRAVELS">
          {Deslocations}
        </EntityOverviewRoundedItem>

        <EntityOverviewRoundedItem icon={faRepeatAlt} titleId="RENEWAL">
          <CovenantPeriodicity value={Covenant} preview />
        </EntityOverviewRoundedItem>

        <EntityOverviewItem icon={faClock} titleId="WORK_HOURS">
          {Schedules && Schedules.length > 0 ? (
            <TimeTablePicker value={Schedules} multiple preview />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>

        <EntityOverviewItem icon={faLink} titleId="ASSIGNED_TO">
          <OriginProfile dynamicFont origin={origin} />
        </EntityOverviewItem>

        <EntityOverviewRoundedItem
          icon={faHandshake}
          titleId="NR_SALE_OPPORTUNITY"
        >
          {SalesOpportunity ? SalesOpportunity : "-"}
        </EntityOverviewRoundedItem>

        <EntityOverviewRoundedItem
          icon={faHandshake}
          titleId="MINIMUM_INTERVAL"
        >
          {IntervalMinimum ? MilSecToMinute(IntervalMinimum) : "-"}
        </EntityOverviewRoundedItem>

        <EntityOverviewRoundedItem icon={faHandshake} titleId="ROUNDING_MARGIN">
          {RoundingMargin ? MilSecToMinute(RoundingMargin) : "-"}
        </EntityOverviewRoundedItem>

        <EntityOverviewChildrenProgress schema={spaceContractSchema} id={Id} />

        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="DESCRIPTION">
          <DescriptionOverview
            Description={Description}
            NoTagDescription={NoTagDescription}
          />
        </EntityDescriptionOverviewItem>

        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="INTERNAL_NOTE">
          <DescriptionOverview
            Description={InternalDescription}
            NoTagDescription={NoTagInternalDescription}
          />
        </EntityDescriptionOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faCity} textId="ASSOCIATED">
        <EntityOverviewItem icon={faBuilding} titleId="COMPANY">
          <ConnectedOrganizationProfile dynamicFont organization={Company} />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faSuitcase} titleId="ACCOUNT">
          <ConnectedClientOrigin
            placement="right"
            dynamicFont
            client={Client || IndirectClient}
          />
        </EntityOverviewItem>
        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="CREATION_DATE">
          <RelativeTime date={CreationDate} />
        </EntityOverviewRoundedItem>

        <EntityOverviewItem icon={faUserTie} titleId="CONTACT">
          <ConnectedContactPopup
            placement="right"
            dynamicFont
            contact={Contact}
          />
        </EntityOverviewItem>

        <EntityOverviewRoundedItem icon={faEnvelope} titleId="REPORT_EMAIL">
          {ReportEmail || "―"}
        </EntityOverviewRoundedItem>

        <EntityOverviewItem icon={faUsersClass} titleId="DEPARTMENTS">
          {Departments ? (
            <ConnectedDepartmentProfileStack departments={Departments} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUsersLight} titleId="TEAMS_CONTRIBUTORS">
          {(Accounts && Accounts.length > 0) || (Teams && Teams.length > 0) ? (
            <ConnectedAccountsTeamsDepartmentsProfileStack
              accounts={Accounts}
              teams={Teams}
            />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUser} titleId="CREATED_BY">
          <ConnectedAccountPopup
            placement="right"
            dynamicFont
            account={Creator}
          />
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faUsers} textId="ASSIGNED_TO">
        {/* <EntityOverviewItem icon={faUser} titleId="CREATED_BY">
          <ConnectedAccountProfile account={Creator} />
        </EntityOverviewItem> */}
        <EntityOverviewItem icon={faUserCog} titleId="MANAGER">
          <ConnectedAccountPopup
            placement="right"
            dynamicFont
            account={Manager}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faBell} titleId="FOLLOWERS">
          {Followers && Followers.length > 0 ? (
            <ConnectedAccountProfileStack dynamicFont accounts={Followers} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
      </EntitySummaryAccordion>
      {PrivateFinancialPermission && (
        <CurrencyValuesAccordion
          EstimatedCosts={EstimatedCosts}
          titleId="TOTAL_VALUE"
        />
      )}
      {PrivateFinancialPermission && (
        <CurrencyValuesAccordion
          EstimatedCosts={EstimatedCostsHour}
          titleId="VALUE_HOUR"
        />
      )}
      <EntitySummaryFilesAccordion
        CanEdit={CanEdit}
        IsFollowing={IsFollowing}
        Files={Files}
      />
      {/* <EntityPipelineHistoryAccordion
        endpoint={`Contracts/${Id}/PipelineHistory`}
        id={Id}
        schema={spaceContractSchema}
      />
      <div className="mb-3"></div> */}
      <EntityPipelineHistoryAccordion
        endpoint={`history/Contract/${Id}/pipeline`}
        id={Id}
        schema={spaceContractSchema}
      />
    </>
  );
};

export const InnerContractSummary = ({ value }) => {
  return (
    <EntitySummaryTabstrip className="mt-2 mb-3">
      <EntitySummaryTab titleId="SUMMARY">
        <ContractSummaryContent value={value} />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="HISTORY">
        <History contract={value.Id} disableTabs />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ORIGINATED">
        <Treeview
          className="flex-1"
          schema={spaceContractSchema}
          id={value.Id}
        />
      </EntitySummaryTab>
      <EntitySummaryTab titleId="ASSOCIATIONS">
        <ContractDetailsChildren />
      </EntitySummaryTab>
    </EntitySummaryTabstrip>
  );
};

const ContractSummary = (props) => {
  return (
    <EntitySummaryOverview
      {...props}
      url={"contracts"}
      type="contract"
      schema={spaceContractSchema}
      FormComponent={ContractForm}
      Summary={InnerContractSummary}
      creationHeaderText={<FormattedMessage id="CREATE_CONTRACT" />}
      // noImage
      // TextComponent={ContractSummaryHeader}
      ActionButton={ContractEntityActionsButton}
      FormButton={ContractFormButton}
      roomType={RealTimeRoomTypes.Contract}
    ></EntitySummaryOverview>
  );
};

export default ContractSummary;
