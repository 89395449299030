import React, { useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import classes from "../CalendarWeekView/CalendarWeekView.module.css";
import {
  useCalendarDateUrl,
  useReftchRefProvider,
  weekDayTranslation
} from "../../CalendarHelper";
import { FormattedMessage } from "react-intl";
import { many } from "../../../../Helpers/SchemaHelper";
import { useToast } from "../../../Toast/ToastProvider";
import { useQuery } from "../../../../Helpers/IOClient";
import { useMultipleEntityValueSelector } from "../../../../Hooks/AdvancedEntityHooks";
import {
  buildItems,
  buildResolvedValueItem,
  sortRowItems
} from "../CalendarMonthView/CalendarMonthView";
import { BarLoader } from "../../../GlobalLoader/GlobalLoader";
import {
  buildResolvedWeeklyValueItem,
  CalendarHourList,
  WeeklyContentGridColumn
} from "../CalendarWeekView/CalendarWeekView";

const dayMilsec = 86400000;
const WeekDayHeader = React.memo(function WeekDayHeader({ startMil }) {
  const { dayOfWeek, day } = useMemo(() => {
    const d = new Date(startMil);
    return {
      dayOfWeek: d.getDay(),
      day: d.getDate()
    };
  }, [startMil]);
  return (
    <div className={classnames("d-flex", classes.headerContainer)}>
      <div className={classnames("d-flex flex-1")}>
        <div
          className={classnames(
            "d-flex justify-content-center text-black text-uppercase fs-14",
            classes.headerDay
          )}
        >
          <FormattedMessage id={weekDayTranslation[dayOfWeek]} /> {day}
        </div>
      </div>
      <div className={classes.fakeScroll} />
    </div>
  );
});

const getAllWeekDays = (year, month, day) => {
  const startDate = new Date(year, month, day);
  const endDate = new Date(year, month, day + 1);

  return { startDate, endDate, month, startDateMil: startDate.getTime() };
};

const ContentGrid = ({ startDateMil, items, settings, containerRef }) => {
  const currentDate = useMemo(() => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }, []);

  const { gridItems } = items;

  return (
    <div className="flex-1 d-flex">
      <WeeklyContentGridColumn
        containerRef={containerRef}
        settings={settings}
        startDateMil={startDateMil}
        i={0}
        currentDate={currentDate}
        items={gridItems}
      />
    </div>
  );
};

const selector = (v) => {
  return v;
};

const useDayGridItems = (data, startDate, settings) => {
  return useMemo(() => {
    const gridItems = [];
    if (!data || data.length === 0) return { gridItems };

    const resolvedResults = data.map((v) =>
      buildResolvedWeeklyValueItem(v, settings, startDate)
    );

    const outOfGridItems = [];

    for (const item of resolvedResults) {
      const { epochStart, epochEnd } = item;

      if (epochEnd - epochStart >= dayMilsec) {
        outOfGridItems.push(item);
        continue;
      }

      gridItems.push(item);
    }

    // console.log(gridItems);

    return { gridItems, outOfGridItems };
  }, [data, settings, startDate]);
};

const ItemsHeader = ({ outOfGridItems, settings, startDate }) => {
  const x = useMemo(() => {
    const x = outOfGridItems.map((v) =>
      buildResolvedValueItem(v.metadata, settings, startDate)
    );
    return sortRowItems(x, 0);
  }, [outOfGridItems, settings, startDate]);

  const { children, elapsedDays } = buildItems(x, 0, 3, startDate, settings, {
    isDaily: true
  });
  return (
    <div
      className="d-flex border-bottom"
      style={
        {
          // padding: "4px 0px 0px 52px",
        }
      }
    >
      <div
        className={classnames(
          classes.hourList,
          classes.hourItem,
          "h-100 border-0"
        )}
      ></div>
      <div
        className="flex-1"
        style={{
          position: "relative",
          height: 29 * elapsedDays,
          margin: "4px 0px 0px 0px"
        }}
      >
        {children}
      </div>
      <div className={classes.fakeScroll} />
    </div>
  );
};

const InnerCalendarView = ({ dateInfo, settings, data, loading }) => {
  const { startDateMil, startDate } = dateInfo;

  const { schema } = settings;
  const connectedResults = useMultipleEntityValueSelector(
    schema,
    data,
    selector
  );

  const items = useDayGridItems(connectedResults, startDate, settings);

  const { outOfGridItems } = items;
  const containerRef = useRef();
  return (
    <div
      className={classnames(
        "flex-1 w-100 d-flex flex-column overflow-hidden position-relative rounded",
        classes.weekView
      )}
    >
      <BarLoader isLoading={loading} />
      <WeekDayHeader startMil={startDateMil} />
      {outOfGridItems && outOfGridItems.length > 0 && (
        <ItemsHeader
          outOfGridItems={outOfGridItems}
          startDate={startDate}
          settings={settings}
        />
      )}
      <div
        ref={containerRef}
        className={classnames("w-100 flex-1", classes.gridContainer)}
      >
        <div className="d-flex w-100">
          <CalendarHourList />
          <ContentGrid
            containerRef={containerRef}
            settings={settings}
            items={items}
            startDateMil={startDateMil}
          />
        </div>
      </div>
    </div>
  );
};

const CalendarDailyView = ({ selectedDate, settings }) => {
  const [resolvedDate, setResolvedDate] = useState(selectedDate);
  const refetchRef = useReftchRefProvider();
  const dateInfo = useMemo(() => {
    const year = resolvedDate.getFullYear();
    const month = resolvedDate.getMonth();
    const day = resolvedDate.getDate();
    return getAllWeekDays(year, month, day);
  }, [resolvedDate]);

  const url = useCalendarDateUrl(dateInfo, settings);

  const { schema } = settings;
  const RSchema = useMemo(
    () => ({
      d: {
        results: many(schema)
      }
    }),
    [schema]
  );

  const createToast = useToast();
  const [data, setData] = useState([]);

  useEffect(() => {
    const { communicator } = settings;
    if (!communicator) return;

    const unsub = communicator.subscribe((e) => {
      const { type, id } = e;
      if (type !== "creation") return;
      setData((d) => [...d, id]);
      // console.log(v);
    });
    return unsub;
  }, [settings]);

  const { loading, refetch } = useQuery(url, RSchema, {
    onSuccess: ({ data }) => {
      setData(data.d.results);
      // if (data.d.__count > top)
      //   createToast({
      //     pos: "tm",
      //     type: "danger",
      //     description: <FormattedMessage id="CALENDAR_GENERAL_ERROR" />,
      //     title: <FormattedMessage id="ERROR" />,
      //   });
    },
    onError: () => {
      createToast({
        pos: "tm",
        type: "danger",
        description: <FormattedMessage id="CALENDAR_GENERAL_ERROR" />,
        title: <FormattedMessage id="ERROR" />
      });
    },
    cache: false
  });

  useEffect(() => {
    refetchRef.current = refetch;
  }, [refetch, refetchRef]);

  const hasMountedRef = useRef(false);

  useEffect(() => {
    if (!hasMountedRef.current) {
      hasMountedRef.current = true;
      return;
    }

    setData([]);
    setResolvedDate(selectedDate);
  }, [selectedDate]);

  return (
    <InnerCalendarView
      loading={loading}
      settings={settings}
      data={data}
      dateInfo={dateInfo}
    />
  );
};

export default CalendarDailyView;
