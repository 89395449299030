// @flow
import React from 'react'
import styles from './Alert.module.css'

const Alert = ({ show, message }) =>
  show && (
    <p role="alert" data-testid="Alert" className={styles.message}>
      {message}
    </p>
  )

export default Alert

