import React, { useMemo, useRef, useContext } from "react";
import { AccountProfileImg } from "../Img/Img";
import { connect } from "react-redux";
import { ConnectAccountEntity } from "./AccountProfile";
import { ConnectTeamEntity } from "../Teams/TeamProfile";
import { ConnectDepartmentEntity } from "../Departments/DepartmentProfile";
import {
  ConnectedAccountPopup,
  PopupImageEnforcer
} from "../../Containers/Origin/AcountPopup";
import {
  ConnectClientEntity,
  ConnectedClientProfile
} from "../Clients/ClientProfile";
import { ConnectedClientOrigin } from "../../Containers/Origin/ClientOrigin";
import { useHoverEvents } from "../../Containers/Origin/Origin";
import Popup from "../Popup/Popup";
import { ConnectContactEntity } from "../Contacts/ContactProfile";
import { NoAvatarProvider } from "./AccountProfileHelper";
import classnames from "classnames";
import {
  ConnectedSpaceProfile,
  ConnectSpaceEntity
} from "../Spaces/SpacesProfile";
import { v1 as uuidv1 } from "uuid";
import { ConnectedContactOrigin } from "../../Containers/Origin/ContactOrigin";

const AccountStackItem = ({ account, zIndex, size }) => {
  const noAvatar = useContext(NoAvatarProvider);
  const ImageEnforcer = useContext(PopupImageEnforcer);
  if (!account) return null;
  return (
    <div key={account.id} style={{ zIndex }}>
      <ConnectedAccountPopup
        size={size}
        placement={noAvatar ? "right" : ""}
        justImage
        account={account.Id}
        forceimage={ImageEnforcer}
      />

      {/* <AccountProfileImg size="sm" account={account} circular />  */}
    </div>
  );
};

const ContactStackItem = ({ contact, zIndex, size }) => {
  const noAvatar = useContext(NoAvatarProvider);
  const ImageEnforcer = useContext(PopupImageEnforcer);

  if (!contact) return null;
  return (
    <div key={contact.Id} style={{ zIndex }}>
      <ConnectedContactOrigin
        size={size}
        placement={noAvatar ? "top" : ""}
        noStatus
        justImage
        hideName
        noAvatar={noAvatar}
        contact={contact.Id}
        forceimage={ImageEnforcer}
      />

      {/* <AccountProfileImg size="sm" account={account} circular />  */}
    </div>
  );
};

// const SpaceStackItem = ({ space, zIndex, size }) => {
//   const noAvatar = useContext(NoAvatarProvider);
//   const ImageEnforcer = useContext(PopupImageEnforcer);
//   if (!space) return null;
//   return (
//     <div key={space.id} style={{ zIndex }}>
//       <ConnectedSpaceProfile
//         size={size}
//         placement={noAvatar ? "right" : ""}
//         justImage
//         space={space.Id}
//         forceimage={ImageEnforcer}
//       />

//       {/* <AccountProfileImg size="sm" account={account} circular />  */}
//     </div>
//   );
// };

const SpaceStackItem = ({ space, zIndex, forceimage, size }) => {
  const ImageEnforcer = useContext(PopupImageEnforcer);
  const noAvatar = useContext(NoAvatarProvider);

  const anchorRef = useRef();
  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  if (!space) return null;
  if (noAvatar && !ImageEnforcer) {
    return (
      <div key={space.id} style={{ zIndex }}>
        <div className="text-white fs-12">{space.Name}</div>
      </div>
    );
  } else
    return (
      <>
        <Popup
          anchorEl={anchorRef.current}
          modifiers={{
            preventOverflow: { boundariesElement: "viewport" }
          }}
          isOpen={isOpen}
          onMouseEnter={onPopupMouseEnter}
          onMouseLeave={onPopupMouseLeave}
        >
          <div
            className="bg-black text-white px-2 py-1 fs-14 rounded"
            style={{ cursor: "context-menu" }}
          >
            <span>{space.Name}</span>
          </div>
        </Popup>
        <div
          key={space.id}
          style={{ zIndex }}
          ref={anchorRef}
          onMouseOver={onAnchorMouseEnter}
          onMouseOut={onAnchorMouseLeave}
        >
          <ConnectedSpaceProfile
            size={size}
            placement={noAvatar ? "right" : ""}
            justImage
            space={space.Id}
            forceimage={ImageEnforcer}
          />
        </div>
      </>
    );
};

const ClientStackItem = ({ client, zIndex, size }) => {
  const noAvatar = useContext(NoAvatarProvider);
  // const ImageEnforcer = useContext(PopupImageEnforcer);
  if (!client) return null;

  return (
    <div key={client.id} className="mr-1" style={{ zIndex }}>
      <ConnectedClientOrigin client={client.Id}>
        <ConnectedClientProfile
          size={size}
          placement={noAvatar ? "right" : ""}
          showId={false}
          justImage
          hideStatus={"true"}
          client={client.Id}
        />
      </ConnectedClientOrigin>

      {/* <AccountProfileImg size="sm" account={account} circular />  */}
    </div>
  );
};

// const ClientStackItem = ({ client, zIndex }) => {
//   if (!client) return null;
//   return (
//     <div key={client.id} style={{ zIndex }}>
//       <ConnectedClientOrigin client={client.Id}>
//         <ConnectedClientProfile justImage client={client.Id} />
//       </ConnectedClientOrigin>

//       {/* <ConnectedClientOrigin  size="sm" client={client.Id} circular />  */}
//     </div>
//   );
// };

const TeamStackItem = ({ account, zIndex, forceimage, size }) => {
  const ImageEnforcer = useContext(PopupImageEnforcer);
  const noAvatar = useContext(NoAvatarProvider);

  const anchorRef = useRef();
  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  if (!account) return null;
  if (noAvatar && !ImageEnforcer) {
    return (
      <div key={account.id} style={{ zIndex }}>
        <div className="text-white fs-12">{account.Name}</div>
      </div>
    );
  } else
    return (
      <>
        <Popup
          anchorEl={anchorRef.current}
          modifiers={{
            preventOverflow: { boundariesElement: "viewport" }
          }}
          isOpen={isOpen}
          onMouseEnter={onPopupMouseEnter}
          onMouseLeave={onPopupMouseLeave}
        >
          <div
            className="bg-black text-white px-2 py-1 fs-14 rounded"
            style={{ cursor: "context-menu" }}
          >
            <span>{account.Name}</span>
          </div>
        </Popup>
        <div
          key={account.id}
          style={{ zIndex }}
          ref={anchorRef}
          onMouseOver={onAnchorMouseEnter}
          onMouseOut={onAnchorMouseLeave}
        >
          <AccountProfileImg size={size || "sm"} account={account} circular />
        </div>
      </>
    );
};

export const ShowIconOnPopUpProvider = React.createContext();

const RemainingStackItem = ({ remainingItems, items, size }) => {
  const anchorRef = useRef();
  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  return (
    <>
      <Popup
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <div
          className="bg-black text-white px-2 d-flex flex-column py-1 fs-14 rounded"
          style={{
            cursor: "context-menu",
            maxHeight: "600px",
            overflowY: "auto"
          }}
        >
          <ShowIconOnPopUpProvider.Provider value={true}>
            <NoAvatarProvider.Provider value={true}>
              {items}
            </NoAvatarProvider.Provider>
          </ShowIconOnPopUpProvider.Provider>
        </div>
      </Popup>
      <div>
        <div
          onMouseOver={onAnchorMouseEnter}
          onMouseOut={onAnchorMouseLeave}
          ref={anchorRef}
          className={classnames("img circular account", size || "sm")}
        >
          + {remainingItems > 9 ? 9 : remainingItems}
        </div>
      </div>
    </>
  );
};

const mapStateToPropsOrganizations = (state, ownProps) => {
  if (!ownProps.organization) return {};
  return {
    account: state.Entities.SpaceOrganizations[ownProps.organization]
  };
};
// const mapStateToPropsClients = (state, ownProps) => {
//   if (!ownProps.client) return {};
//   return {
//     account: state.Entities.SpaceClients[ownProps.client]
//   };
// };
const ConnectedSpaceStackItem = ConnectSpaceEntity(SpaceStackItem);

const ConnectedAccountStackItem = ConnectAccountEntity(AccountStackItem);

const ConnectedTeamStackItem = ConnectTeamEntity(({ team, ...rest }) => (
  <TeamStackItem account={team} {...rest} />
));
const ConnectedDepartmentStackItem = ConnectDepartmentEntity(
  ({ department, ...rest }) => <TeamStackItem {...rest} account={department} />
);
const ConnectedOrganizationStackItem = connect(mapStateToPropsOrganizations)(
  TeamStackItem
);
const ConnectedClientProfileStackItem = ConnectClientEntity(ClientStackItem);

const ConnectedContactStackItem = ConnectContactEntity(
  ({ contact, ...rest }) => <ContactStackItem {...rest} contact={contact} />
);

const AccountProfileStack = ({ accounts }) => {
  if (!Array.isArray(accounts)) return null;

  const width = accounts.length * 32 - (accounts.length - 1) * 7;

  return (
    <div className="account-profiles" style={{ width }}>
      {accounts.map((item, i) => (
        <AccountStackItem
          key={item.Id + "/" + i}
          account={item}
          zIndex={accounts.length - i}
        />
      ))}
    </div>
  );
};
export default AccountProfileStack;

const getElem = (index, size, ...depends) => {
  let acumulatedLength = 0;

  for (const depend of depends) {
    const idsArray = depend.array;
    const Component = depend.component;
    if (index <= acumulatedLength + idsArray.length - 1) {
      const id = idsArray[index - acumulatedLength];
      return <Component size={size} id={id} key={index} />;
    }

    acumulatedLength += idsArray.length;
  }

  return null;
};

const useList = (depends, arrayDep, size, maxItems) => {
  const list = useMemo(
    () => {
      const getTotalItems = () => {
        let totalItems = 0;
        for (const dep of depends) {
          totalItems += dep.array.length;
        }

        // totalItems -= newChildren.length;
        return totalItems;
      };

      const totalItems = getTotalItems();

      const max = maxItems || 4;
      const newChildren = [];
      const remainingChildren = [];
      for (let index = 0; index < totalItems; index++) {
        const newElem = getElem(index, size, ...depends);

        if (!newElem) break;
        if (index < max) newChildren.push(newElem);
        else {
          remainingChildren.push(newElem);
          // if (index !== totalItems - 1)
          //   remainingChildren.push(
          //     <div key={uuidv1()} className="mr-1">
          //       ,
          //     </div>
          //   );
        }
      }
      const remainingItems = totalItems - newChildren.length;

      // const totalWItems = totalItems > maxItems ? maxItems + 2 : totalItems;

      // const itemsWidth = totalWItems * 32;
      // const width = itemsWidth - (totalItems - 1) * 7;

      return { newChildren, remainingItems, remainingChildren };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    arrayDep
  );
  return list;
};

const createStackComponent = ({ dependencies }) => {
  return (props) => {
    const resolvedArrays = [];
    const depArray = [];
    for (const dpend of dependencies) {
      const arr = dpend.getArray(props) || [];
      depArray.push(arr);
      resolvedArrays.push({
        component: dpend.component,
        array: arr
      });
    }
    const { size, maxItems, className } = props;
    const { newChildren, remainingItems, remainingChildren } = useList(
      resolvedArrays,
      depArray,
      size,
      maxItems
    );

    return (
      <div
        className={classnames(
          "account-profiles d-flex align-items-center",
          className
        )}
      >
        {newChildren}

        {remainingItems > 0 && (
          <RemainingStackItem
            size={size}
            items={remainingChildren}
            remainingItems={remainingItems}
          />
        )}
      </div>
    );
  };
};

export const ConnectedAccountsTeamsDepartmentsProfileStack =
  createStackComponent({
    dependencies: [
      {
        getArray: (props) => props.organizations,
        component: ({ id, ...rest }) => (
          <ConnectedOrganizationStackItem organization={id} {...rest} />
        )
      },
      {
        getArray: (props) => props.teams,
        component: ({ id, ...rest }) => (
          <ConnectedTeamStackItem team={id} {...rest} />
        )
      },
      {
        getArray: (props) => props.departments,
        component: ({ id, ...rest }) => (
          <ConnectedDepartmentStackItem department={id} {...rest} />
        )
      },
      {
        getArray: (props) => props.accounts,
        component: ({ id, ...rest }) => (
          <ConnectedAccountStackItem account={id} {...rest} />
        )
      }
    ]
  });

export const ConnectedAccountProfileStack = createStackComponent({
  dependencies: [
    {
      getArray: (props) => props.accounts,
      component: ({ id }) => <ConnectedAccountStackItem account={id} />
    }
  ]
});

export const ConnectedSpaceProfileStack = createStackComponent({
  dependencies: [
    {
      getArray: (props) => props.spaces,
      component: ({ id }) => <ConnectedSpaceStackItem space={id} />
    }
  ]
});

export const ConnectedClientProfileStack = createStackComponent({
  dependencies: [
    {
      getArray: (props) => props.clients,
      component: ({ id }) => <ConnectedClientProfileStackItem client={id} />
    }
  ]
});

export const ConnectedOrganizationProfileStack = createStackComponent({
  dependencies: [
    {
      getArray: (props) => props.organizations,
      component: ({ id, ...rest }) => (
        <ConnectedOrganizationStackItem organization={id} {...rest} />
      )
    }
  ]
});

export const ConnectedDepartmentProfileStack = createStackComponent({
  dependencies: [
    {
      getArray: (props) => props.departments,
      component: ({ id, ...rest }) => (
        <ConnectedDepartmentStackItem department={id} {...rest} />
      )
    }
  ]
});

export const ConnectedTeamProfileStack = createStackComponent({
  dependencies: [
    {
      getArray: (props) => props.teams,
      component: ({ id, ...rest }) => (
        <ConnectedTeamStackItem team={id} {...rest} />
      )
    }
  ]
});

export const ConnectedContactProfileStack = createStackComponent({
  dependencies: [
    {
      getArray: (props) => props.contacts,
      component: ({ id, ...rest }) => (
        <ConnectedContactStackItem contact={id} {...rest} />
      )
    }
  ]
});
