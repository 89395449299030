import React, {
  useContext,
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
  useEffect
} from "react";
import "./DetailsView.css";
import { AccountProfileImg } from "../Img/Img";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLink,
  faChevronRight
} from "@fortawesome/pro-light-svg-icons";
import { Form } from "../Forms";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import Button from "../Button/Button";
import LoadableButton from "../Button/LoadableButton";

import { useSchemaQuery } from "../../Helpers/IOClient";
import { addhttp, isEmail } from "../../Helpers/MiscHelper";
import { useEntityValue } from "../../Hooks/EntityHooks";
import { DetailsFormProvider } from "../../Helpers/FormHelper";
import { FormattedMessage } from "react-intl";

const DetailsStateContext = React.createContext();

export const useDetailsState = () => {
  return useContext(DetailsStateContext);
};

export const DetailsEntityContext = React.createContext();

export const useDetailsEntity = () => {
  return useContext(DetailsEntityContext);
};

export const DetailsEntityIdContext = React.createContext();
export const useDetailsEntityId = () => {
  return useContext(DetailsEntityIdContext);
};

export const DetailsView = ({ children, schema, id }) => {
  const { data } = useSchemaQuery(schema, id);
  const entity = useEntityValue(schema, data);

  return (
    <DetailsEntityIdContext.Provider value={id}>
      <DetailsEntityContext.Provider value={entity}>
        <div className="d-flex flex-column h-100">{children}</div>
      </DetailsEntityContext.Provider>
    </DetailsEntityIdContext.Provider>
  );
};

export const DetailsContainer = ({ children }) => {
  return <div className="flex-1 d-flex of-y-auto">{children}</div>;
};

export const DetailsOverview = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((o) => !o);
  }, []);

  return (
    <DetailsFormProvider>
      <div
        className={classnames("ar-details-view-overview", {
          closed: !isOpen
        })}
      >
        <DetailsSidebarToggler left isOpen={isOpen} onClick={toggleIsOpen} />{" "}
        {isOpen && children}
      </div>
    </DetailsFormProvider>
  );
};

export const DetailsSidebarToggler = ({ isOpen, left, ...rest }) => {
  if (left) {
    return (
      <div
        className="border-bottom cursor-pointer ar-details-view-overview-toggler"
        {...rest}
      >
        <div
          className={classnames("text-primary", {
            closed: !isOpen
          })}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      </div>
    );
  }

  return (
    <div
      className="border-bottom cursor-pointer ar-details-view-sidebar-toggler"
      {...rest}
    >
      <div
        className={classnames("text-primary", {
          closed: !isOpen
        })}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    </div>
  );
};

export const DetailsOptionalSidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((o) => !o);
  }, []);

  return (
    <div
      className={classnames("ar-details-view-sidebar", {
        closed: !isOpen
      })}
    >
      <DetailsSidebarToggler isOpen={isOpen} onClick={toggleIsOpen} />

      {isOpen && children}
    </div>
  );
};

export const DetailsImage = ({ name, img, className }) => {
  if (!name && !img)
    return (
      <div
        className={classnames("img lg skeleton circular border-0", className)}
      />
    );

  return (
    <AccountProfileImg
      account={{ Name: name, Image: img }}
      size="lg"
      circular
      className={className}
    />
  );
};

export const DetailsEntityId = ({ id, Icon, name, className }) => {
  const isLoading = isNaN(id);

  return (
    <div
      className={classnames(
        "ar-details-id mx-auto text-black d-flex",
        {
          skeleton: isLoading
        },
        className
      )}
    >
      {!isLoading && (
        <>
          <Icon size="sm" className="mr-1" />
          <div className="mr-1">{name}</div>
          <div>{id}</div>
        </>
      )}
    </div>
  );
};

export const DetailsName = ({ name, className }) => {
  const isLoading = name === undefined;
  return (
    <div
      className={classnames(
        "ar-details-name mx-auto text-black fs-22 d-flex align-items-center",
        {
          skeleton: isLoading
        },
        className
      )}
    >
      {!isLoading && (
        <span className="text-black text-center fs-22 lh-normal breakWord">
          {name}
        </span>
      )}
    </div>
  );
};

export const DetailsDomain = ({ Domain }) => {
  if (!Domain) return null;

  return (
    <a
      href={addhttp(Domain)}
      target="_blank"
      rel="noopener noreferrer"
      className={classnames("mx-auto")}
    >
      <span className="mr-2">{Domain}</span>
      <FontAwesomeIcon icon={faExternalLink} />
    </a>
  );
};

export const DetailsEmail = ({ Email }) => {
  if (!Email || !isEmail(Email)) return null;

  return (
    <a href={`mailto:${Email}`} className={classnames("mx-auto")}>
      <span>{Email}</span>
    </a>
  );
};

export const DetailsFormButtons = ({ className, ButtonsComponent, entity }) => {
  const data = useDetailsEntity();
  const isLoading = !data;

  return (
    <div
      style={{ right: 9 }}
      className={classnames("position-absolute", className)}
    >
      <ButtonsComponent skeleton={isLoading} entity={entity || {}} />
      {/* <Button id={id} alt canEdit={CanEdit} skeleton={isLoading} />
      {AditionalButtons &&
        AditionalButtons.map((B, i) => {
          return (
            <B
              alt
              key={i}
              formProps={{ [entityProp]: id }}
              skeleton={isLoading}
            />
          );
        })} */}
    </div>
  );
};

export const DetailsProfileDropdown = ({ Dropdown }) => {
  const { formState, canEdit } = useDetailsState();

  const { handleChange, form } = formState;

  return (
    <Dropdown
      preview={!canEdit}
      onChange={(v) =>
        handleChange({
          Status: v
        })
      }
      value={form.Status}
    />
  );
};

export const DetailsProfile = ({ children }) => {
  return (
    <div className="px-4 py-4 d-flex flex-column fs-14 border-bottom position-relative">
      {children}
    </div>
  );
};

const DetailsFormSkeleton = () => {
  return (
    <div className="py-3 px-4">
      <DetailsFormInputSkeleton />
      <DetailsFormInputSkeleton />
      <DetailsFormInputSkeleton />
      <DetailsFormInputSkeleton />
      <DetailsFormInputSkeleton />
      <DetailsFormInputSkeleton />
      <DetailsFormInputSkeleton />
      <DetailsFormInputSkeleton />
    </div>
  );
};

export const DetailsFormInputSkeleton = () => {
  return (
    <div className="mb-3">
      <div className="pb-1">
        <div className="ar-details-form-title-skeleton skeleton rounded" />
      </div>

      <div className="ar-details-form-input-skeleton skeleton rounded" />
    </div>
  );
};

// export const DetailsForm = ({ children, loading, title }) => {
//   const { data } = useContext(DetailsFormContext);

//   if (!data || loading) return <DetailsFormSkeleton />;

//   return (
//     <div className="py-3 px-4 ar-details-form">
//       <DetailsAccordion title={title || "Detalhes"} isOpenByDefault>
//         {children}
//       </DetailsAccordion>
//     </div>
//   );
// };

export const useDetailsForm = () => {
  return useDetailsState().formState.form;
};

export const DetailsForm = ({
  children,
  title,
  loading: loadingOrganizations,
  ...rest
}) => {
  const { formState, canEdit, data, loading } = useDetailsState();
  if (!data || loading || loadingOrganizations) return <DetailsFormSkeleton />;
  return (
    <div className="px-4 py-3">
      <DetailsAccordion title={title || "Detalhes"} isOpenByDefault>
        <Form hideForm formState={formState} canEdit={canEdit}>
          {children}
        </Form>
      </DetailsAccordion>
    </div>
  );
};

export const DetailsFormSubmmiter = () => {
  const { formState, postState, handleSubmit } = useDetailsState();

  const { changes, reset } = formState;

  const { loading } = postState;

  if (!changes) return null;

  const text =
    changes > 1
      ? `Foram alteradas ${changes} propriedades`
      : "Foi alterada 1 propriedade";

  return (
    <div className="py-3 px-5 ar-details-form-submmiter bg-white border-top d-flex align-items-center">
      <LoadableButton
        isLoading={loading}
        className="mr-2"
        onClick={handleSubmit}
      >
        <FormattedMessage id={"SAVE"} />
      </LoadableButton>
      <Button className="mr-2" vType="outline-danger" onClick={() => reset()}>
        <FormattedMessage id={"CANCEL"} />
      </Button>
      <div className="text-black fs-14">{text}</div>
    </div>
  );
};

export default DetailsView;

export const DetailsAccordion = ({
  children,
  changesCount,
  title,
  isOpenByDefault
}) => {
  const contentRef = useRef();
  const hasMountedRef = useRef();
  const [isOpen, setIsOpen] = useState(isOpenByDefault);
  const [isAnimating, setIsAnimating] = useState(isOpenByDefault);

  const toggleOpen = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    setIsAnimating(true);
  };

  const isValidAccordion = title;

  useLayoutEffect(() => {
    if (!isValidAccordion) return;
    const contentElem = contentRef.current;
    const hasMounted = hasMountedRef.current;
    if (isOpen) {
      if (!hasMounted) {
        contentElem.style.opacity = 1;
        const timeout = setTimeout(() => {
          contentElem.style.height = `auto`;
        }, 500);
        return () => clearTimeout(timeout);
      }
      let timeout;
      let frame = requestAnimationFrame(() => {
        contentElem.style.height = `0px`;
        frame = requestAnimationFrame(() => {
          const { scrollHeight } = contentElem;
          contentElem.style.height = `${scrollHeight}px`;
          contentElem.style.opacity = 1;
          timeout = setTimeout(() => {
            contentElem.style.height = `auto`;
          }, 500);
        });
      });

      return () => {
        cancelAnimationFrame(frame);
        clearTimeout(timeout);
      };
    } else if (contentElem) {
      const { scrollHeight } = contentElem;

      let timeout;
      let frame = requestAnimationFrame(() => {
        contentElem.style.height = `${scrollHeight}px`;
        frame = requestAnimationFrame(() => {
          contentElem.style.height = `0px`;
          contentElem.style.opacity = 0;
          timeout = setTimeout(() => {
            setIsAnimating(false);
          }, 500);
        });
      });

      return () => {
        cancelAnimationFrame(frame);
        clearTimeout(timeout);
      };
    }
  }, [isOpen, isAnimating, isValidAccordion]);

  useEffect(() => {
    hasMountedRef.current = true;
  }, []);
  const shouldShow = isOpen || isAnimating;

  if (!isValidAccordion) return null;

  let changesText =
    title ||
    (changesCount > 1 ? (
      <span>{changesCount} Alterações</span>
    ) : (
      <span>{changesCount} Alteração</span>
    ));

  return (
    <div
      className={classnames("ar-history-accordion mt-3", {
        active: isOpen
      })}
    >
      <div
        className="d-inline-flex align-items-center title"
        onClick={toggleOpen}
      >
        <div className="ar-history-accordion-icon text-primary">
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className="text-black fs-14 lh-normal">{changesText}</div>
      </div>
      {shouldShow && (
        <div
          className={classnames("ar-history-accordion-content overflow-auto")}
          ref={contentRef}
        >
          {children}
        </div>
      )}
    </div>
  );
};
