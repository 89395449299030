import React from "react";
import { isValidSubDomainName } from "../../../Helpers/MiscHelper";
import Input from "../../../Components/Input/Input";
import classnames from "classnames";
import { inboxEmailDomain } from "../../../config";

const EmailReceptionInput = ({
  value,
  onChange,
  CompanyEmail,
  className,
  ...rest
}) => {
  const handleChange = (e) => {
    const { value } = e.target;
    const isValid =
      value === "" || (value.length <= 30 && isValidSubDomainName(value));
    if (!isValid) return;

    onChange(value);
  };

  //   const { DomainName } = useSpace();

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Input
        className={classnames("text-right flex-1", className)}
        value={value}
        onChange={handleChange}
        {...rest}
      />
      <div className="ml-2">
        .{CompanyEmail}@{inboxEmailDomain}
      </div>
    </div>
  );
};

export default EmailReceptionInput;
