import React, { useMemo, useRef } from "react";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { Tr } from "../../Components/Table";
import { useIntervention, useInterventionType } from "../../Hooks/EntityHooks";
import { spaceInterventionsSchema } from "../../config/schema";
import {
  MilSecToFormatedHourMinute,
  MilSecToTime
} from "../../Helpers/MiscHelper";
import {
  DashboardTable,
  DashboardTitle,
  DashboardEmptyResponse
} from "./Dashboard";
import { faUserClock } from "@fortawesome/pro-solid-svg-icons";
import Moment from "react-moment";
import OriginOverview from "../Origin/OriginOverview";
import { InterventionFormButton } from "../Form/FormButtons";
import { ConnectedClientOrigin } from "../Origin/ClientOrigin";
import {
  InterventionEntityGridButtons,
  useInterventionCommunicator,
  INTERVENTION_CREATED
} from "../Interventions/InterventionsHelper";
import { InterventionsLink } from "../../Helpers/HooksHelper";
import { FormattedMessage } from "react-intl";
import { useDashboardQuery } from "./DashboardQueryHelper";
// import { InterventionFormButton } from "../Interventions/InterventionForm";

const InterventionTypeOverview = ({ type }) => {
  const resolvedType = useInterventionType(type);
  if (!resolvedType) return null;

  return <span>{resolvedType?.Name}</span>;
};

const columns = [
  {
    minWidth: 90,
    width: 90,
    Cell: (value) => <InterventionEntityGridButtons entity={value} />
  },
  {
    title: "Nº",
    // width: 70,
    Cell: ({ Id }) => <InterventionsLink id={Id}>{Id}</InterventionsLink>
  },
  {
    title: <FormattedMessage id={"START_DATE"} />,
    Cell: ({ BeginDate }) => (
      <Moment
        className="text-black"
        date={BeginDate}
        format="DD/MM/YYYY HH:mm"
      />
    )
  },
  {
    title: <FormattedMessage id={"END_DATE"} />,
    Cell: ({ EndDate }) => (
      <Moment className="text-black" date={EndDate} format="DD/MM/YYYY HH:mm" />
    )
  },
  {
    maxWidth: 200,
    title: <FormattedMessage id={"ORIGIN"} />,
    Cell: (entity) => <OriginOverview entity={entity} />
  },
  {
    maxWidth: 200,
    title: <FormattedMessage id={"ACCOUNT"} />,
    Cell: ({ Client, IndirectClient }) => (
      <ConnectedClientOrigin client={Client || IndirectClient} />
    )
  },
  {
    title: <FormattedMessage id={"DURATION"} />,
    Cell: ({ Duration }) => MilSecToFormatedHourMinute(Duration)
  },
  {
    title: <FormattedMessage id={"TYPE"} />,
    Cell: ({ InterventionType }) => (
      <InterventionTypeOverview type={InterventionType} />
    )
  }
];

const InterventionTr = ({ value, ...rest }) => {
  const intervention = useIntervention(value);

  return <Tr value={intervention} {...rest} />;
};

const DashboardInterventionOverview = (props) => {
  const { data, loading, error, refetch } = useDashboardQuery(
    "dashboard/timeCharges",
    [spaceInterventionsSchema],
    {
      cache: false
    }
  );

  const buildBody = useMemo(() => {
    if (loading || error)
      return <LoaderSpinner size="sm text-secondary" center />;

    if (data?.length === 0)
      return (
        <DashboardEmptyResponse type="interventions">
          <FormattedMessage id={"EMPTY_TIME"} />
        </DashboardEmptyResponse>
      );

    return (
      <DashboardTable
        TrComponent={InterventionTr}
        columns={columns}
        data={data}
      />
    );
  }, [data, error, loading]);

  useInterventionCommunicator(({ type }) => {
    if (type === INTERVENTION_CREATED) refetch();
  });

  return (
    <React.Fragment>
      <DashboardTitle
        text={<FormattedMessage id={"LAST_RECORDS"} />}
        icon={faUserClock}
        actionButton={<InterventionFormButton vType="primary-ghost" />}
      />
      {buildBody}
    </React.Fragment>
  );
};

export default DashboardInterventionOverview;
