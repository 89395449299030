import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useFormState } from "../../Components/Forms";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../Components/Forms/SideMenuForm";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import SidebarBasicCloseButton from "../../Components/Sidebar/SidebarBasicCloseButton";
import { useSidebarPost } from "../../Components/Sidebar/SidebarV2Helper";
import SidebarViewSelector from "../../Components/Sidebar/SidebarViewSelector";
import { useToast } from "../../Components/Toast/ToastProvider";
import { useQuery } from "../../Helpers/IOClient";
import { handleError } from "../../Helpers/MiscHelper";
import classnames from "classnames";
import {
  SidebarVisualTypeEnum,
  useSidebarView
} from "../../Components/Sidebar/SidebarViewsHelper";
const MobileVersionForm: FC = () => {
  const sidebarView = useSidebarView();
  const showCloseButton = sidebarView === SidebarVisualTypeEnum.fullscreen;
  const { data, loading, error } = useQuery(`ServerStatus`, null, {
    cache: false
  });

  if (loading || error)
    return (
      <div className="d-flex h-100 flex-column bg-white">
        <div
          className={classnames(
            "ar-entity-summary-header d-flex align-items-center justify-content-between text-black fw-medium fs-18",
            !showCloseButton && "pl-3"
          )}
        >
          <div className="d-flex align-items-center">
            {showCloseButton && (
              <SidebarBasicCloseButton className="pl-3 mr-3 h-100 d-flex align-items-center" />
            )}
            Versões Mobile
          </div>
          <SidebarViewSelector />
        </div>
        <div className="flex-1 of-y-auto shadow" style={{ width: 520 }}>
          <LoaderSpinner center size="sm" className="text-secondary" />;
        </div>
      </div>
    );

  const resolvedForm = { ...data.Mobile };

  return (
    <MobileVersionFormBase
      loading={loading}
      error={error}
      resolvedForm={resolvedForm}
    />
  );
};

interface MobileObject {
  iOSVersion: string;
  LastiOSVersionRequired: string;
  AndroidVersion: string;
  LastAndroidVersionRequired: string;
}

interface MobileVersionFormBaseProps {
  resolvedForm: MobileObject;
  loading: boolean;
  error: boolean;
}

const MobileVersionFormBase: FC<MobileVersionFormBaseProps> = ({
  resolvedForm
}) => {
  const intl = useIntl();
  const createToast = useToast();

  const formState = useFormState(() => {
    return {
      ServerStatus: 1,
      ...resolvedForm
    };
  });
  const { form } = formState;

  const [post, state] = useSidebarPost("ServerStatus", null, {
    onSuccess: () => {
      createToast({
        pos: "tm",
        type: "success",
        description: `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`
      });
    },
    onError: ({ error }: any) => {
      handleError(createToast, error);
    }
  });
  const { loading } = state as {
    loading: boolean;
  };

  const resolvedPost = post as (...args: any[]) => void;

  const handleSubmit = () => {
    resolvedPost(form);
  };
  const sidebarView = useSidebarView();
  const showCloseButton = sidebarView === SidebarVisualTypeEnum.fullscreen;
  return (
    <div className="d-flex h-100 flex-column bg-white">
      <div
        className={classnames(
          "ar-entity-summary-header d-flex align-items-center justify-content-between text-black fw-medium fs-18",
          !showCloseButton && "pl-3"
        )}
      >
        <div className="d-flex align-items-center">
          {showCloseButton && (
            <SidebarBasicCloseButton className="pl-3 mr-3 h-100 d-flex align-items-center" />
          )}
          Versões Mobile
        </div>
        <SidebarViewSelector />
      </div>
      <div className="flex-1 of-y-auto shadow" style={{ width: 520 }}>
        <SideForm
          canEdit={true}
          formState={formState}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          <SideFormContent className={"px-3 pt-3 pb-5"}>
            <FormInput className="mb-3" text="iOS Version" name="iOSVersion" />
            <FormInput
              className="mb-3"
              text="Last iOS Version Required"
              name="LastiOSVersionRequired"
            />
            <FormInput
              className="mb-3"
              text="Android Version"
              name="AndroidVersion"
            />
            <FormInput
              className="mb-3"
              text="Last Android Version Required"
              name="LastAndroidVersionRequired"
            />
          </SideFormContent>
          <SideFormFooter canDelete={false} isLoading={loading} />
        </SideForm>
      </div>
    </div>
  );
};

interface ValidadeFormProps {
  iOSVersion: string;
  LastiOSVersionRequired: string;
  AndroidVersion: string;
  LastAndroidVersionRequired: string;
}

const validateForm = (
  form: ValidadeFormProps,
  addError: (e: string) => void
) => {
  const {
    iOSVersion,
    LastiOSVersionRequired,
    AndroidVersion,
    LastAndroidVersionRequired
  } = form;
  if (!iOSVersion) addError("iOSVersion");
  if (!LastiOSVersionRequired) addError("LastiOSVersionRequired");
  if (!AndroidVersion) addError("AndroidVersion");
  if (!LastAndroidVersionRequired) addError("LastAndroidVersionRequired");
};

export default MobileVersionForm;
