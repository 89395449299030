import React, { useContext } from "react";
import moment from "moment";
import { Intl18nContext } from "../../Containers/Intl18n/Intl18nProvider";
import "moment/locale/es";
import "moment/locale/pt";

const RelativeTime = ({ date, className }) => {
  const { value } = useContext(Intl18nContext);
  moment.locale(value.Value);
  const d = moment(date);
  const momentDate = d.fromNow();

  return (
    <span className={className}>
      {d.format("DD/MM/YYYY")}, {momentDate}
    </span>
  );
};

export const LocalizedTimeDate = ({ date, className }) => {
  const { value } = useContext(Intl18nContext);
  moment.locale(value.Value);
  const d = moment(date);

  return <span className={className}>{d.format("HH:mm DD/MM/YYYY")}</span>;
};

export default RelativeTime;
