import * as AuthActions from "../Actions/AuthActions";
import { getCurrentUserAuthToken } from "../../Helpers/AuthHelper";

const initialState = {
  isAuthenticated: getCurrentUserAuthToken() ? true : false,
  token: getCurrentUserAuthToken(),
  userId: null
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case AuthActions.REGISTER_USER_SUCCESS:
    case AuthActions.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.authToken
      };
    case AuthActions.REMOVE_LOGIN:
      return {
        userId: null,
        isAuthenticated: null,
        token: undefined
      };

    default:
      return state;
  }
}
