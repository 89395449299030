import React from "react";
import { DealLink } from "../../Helpers/HooksHelper";
import DealStatusProfile, { buildDealStatusType } from "./DealStatusProfile";
import classnames from "classnames";
import { AccountProfileImg } from "../Img/Img";
import { useClient, useDeal } from "../../Hooks/EntityHooks";
import {  faSuitcase } from "@fortawesome/pro-light-svg-icons";
import DropdownInput from "../Dropdown/DropdownInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DealStatusTypes } from "../../Containers/Deals/DealsHelper";
import { faHandshake } from "@fortawesome/pro-solid-svg-icons";

export const DealListItem = ({
  deal,
  enableInput,
  isTyping,
  dispatch,
  text,
  ...inputProps
}) => {
  const resolvedDeal = useDeal(deal) || {};

  const { Client, IndirectClient, Status, Manager } = resolvedDeal || {};

  const resolvedClient = useClient(Client || IndirectClient);
  const resolvedStatus = buildDealStatusType(Status);

  if (!deal && enableInput) {
    return (
      <DropdownInput
        className="flat-input"
        fallbackValue={resolvedDeal && resolvedDeal.Name}
        text={text}
        enableInput={enableInput}
        isTyping={isTyping}
        {...inputProps}
      />
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100 of-hidden text-truncate">
        {/* {!isTyping && resolvedStatus && (
          <div
            className={`mr-2 status-color-${resolvedStatus} d-flex justify-content-center align-items-center`}
          >
            <div
              style={{ width: 36, height: 36, borderRadius: "50%" }}
              className="status-list-template d-flex justify-content-center align-items-center"
            >
              <FontAwesomeIcon icon={faHandshake} />
            </div>
          </div>
        )} */}
        {!isTyping && Manager && (
          <AccountProfileImg
            size="mds"
            account={Manager}
            circular
            className="mr-2"
          />
        )}
        <div className="d-flex flex-column w-100 of-hidden mr-2 flex-1">
          {/* <AccountProfileImg
		  size="mds"
		  account={resolvedClient}
		  circular
		  className="mr-2"
		/> */}
          <div className="dropdown-item-maintext ">
            {/* {!isTyping && resolvedProject && `${resolvedProject.Id || ""} - `} */}
            {!enableInput ? (
              resolvedDeal.Name
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={resolvedDeal && resolvedDeal.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
          {!isTyping && (
            <div className="dropdown-item-subtext-dif text-truncate">
              {resolvedClient && resolvedClient.Name && (
                <span>
                  <FontAwesomeIcon icon={faSuitcase} className="mr-1" />
                  {resolvedClient.Name}
                </span>
              )}
            </div>
          )}
        </div>
        {!isTyping && (
          <div
            className={` d-flex align-items-center justify-content-end status-color-${resolvedStatus}`}
          >
            <div className="d-flex py-1 px-2 fs-12 align-items-center status-list-template">
              <FontAwesomeIcon className="mr-2" icon={faHandshake} />
              <span>{resolvedDeal.Id}</span>
            </div>
          </div>
        )}
      </div>
    </div>

    //   <div className={classnames("d-flex align-items-center", className)}>
    // 	<AccountProfileImg
    // 	  size={size || "sm"}
    // 	  account={deal}
    // 	  circular
    // 	  className="mr-1 flex-shrink-0"
    // 	/>
    // 	{!justImage && (
    // 	  <div className="d-flex flex-column text-truncate flex-shrink-1">
    // 		<div className="d-flex text-truncate align-items-center">
    // 		  {/* <Link to={`/s/${space.Id}/customers/${client.Id}`}> */}
    // 		  <div
    // 			title={`${deal.Id} - ${deal.Name}`}
    // 			className="flex-1 lh-normal text-truncate text-black fs-12"
    // 		  >
    // 			{`${deal.Id} - ${deal.Name}`}
    // 		  </div>
    // 		  {/* </Link> */}
    // 		  {!hidestatus && (
    // 			<DealStatusProfile className="ml-2" status={deal.Status} />
    // 		  )}
    // 		</div>
    // 		{showId && (
    // 		  <DealLink id={deal.Id}>
    // 			<div className="link-color lh-normal fw-medium fs-12">
    // 			  Nº{deal.Id}
    // 			</div>
    // 		  </DealLink>
    // 		)}
    // 	  </div>
    // 	)}
    //   </div>
  );
};

export const DealProfile = ({
  deal,
  showId,
  size,
  justImage,
  justStatus,
  hidestatus,
  className,
  showWithLink
}) => {
  if (!deal) return null;
  if (justStatus)
    return (
      <div>
        <DealStatusProfile status={deal.Status} />
      </div>
    );
  return (
    <div className={classnames("d-flex align-items-center", className)}>
      <AccountProfileImg
        size={size || "sm"}
        account={deal}
        circular
        className="mr-1 flex-shrink-0"
      />
      {!justImage && (
        <div className="d-flex flex-column text-truncate flex-shrink-1">
          <div className="d-flex text-truncate align-items-center">
            {/* <Link to={`/s/${space.Id}/customers/${client.Id}`}> */}
            <div
              title={`${deal.Id} - ${deal.Name}`}
              className="flex-1 lh-normal text-truncate text-black fs-12"
            >
              {`${deal.Id} - ${deal.Name}`}
            </div>
            {/* </Link> */}
            {!hidestatus && (
              <DealStatusProfile className="ml-2" status={deal.Status} />
            )}
          </div>
          {showId && (
            <DealLink id={deal.Id}>
              <div className="link-color lh-normal fw-medium fs-12">
                Nº{deal.Id}
              </div>
            </DealLink>
          )}
        </div>
      )}
    </div>
  );
};

export const ConnectedDealProfile = ({ deal, ...rest }) => {
  const d = useDeal(deal);
  return <DealProfile deal={d} {...rest} />;
};

export const DifrentDealProfile = ({ deal }) => {
  if (!deal) return null;
  const { Status } = deal;
  let colorStatus;

  switch (Status) {
    case DealStatusTypes.open:
      colorStatus = "status-in-progress-colors";
      break;
    case DealStatusTypes.draft:
      colorStatus = "status-orange-colors";
      break;
    case DealStatusTypes.lost:
      colorStatus = "status-lost-colors";
      break;
    case DealStatusTypes.won:
      colorStatus = "status-open-colors";
      break;
    case DealStatusTypes.canceled:
      colorStatus = "status-canceled-colors";
      break;
    default:
      break;
  }

  return (
    <div className={classnames("air-new-profiles-circle", colorStatus)}>
      <FontAwesomeIcon icon={faHandshake} size="xl" />
    </div>
  );
};
