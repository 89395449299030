import React, { useRef, useEffect } from "react";
import { useIntl } from "react-intl";
// import classnames from "classnames";
// include ECharts main module
import * as echarts from 'echarts/lib/echarts';
// include bar chart
import 'echarts/lib/component/grid';
require("echarts/lib/chart/pie");
// include tooltip and title component
require("echarts/lib/component/title");

export const lightgreen = "#E5FAF4";
export const green = "#85E7C9";
export const darkgreen = "#0E6400";

export const purple = "#7979e1" 
export const lightPurple = "#bcbcf0" 
export const darkPurple = "#4646ae" 


export const lightred = "#FFEEEF";
export const red = "#FFD5D7";
export const darkred = "#831F12";

export const lightblue = "#E7F1FD";
export const blue = "#8EB8F7";

export const lightgrey = "#D5DEE8";
export const grey = "#D5DEE8";
export const darkgrey = "#657B94";

export const lightorange = "#ffccc2";
export const orange = "#ff9985";
export const darkorange = "#cc6652";

export const lightyellow = "#fce5a3";
export const yellow = "#f9cc47";
export const darkyellow = "#c69914";


export const darkblue = "#002B63";
export const opaqueDarkBlue = "#348DFF"

export const monthNames = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

export const createChartComponent = (creator) => {
  const useChartHook = (data, divRef, intl, DifrentCloseColor) => {
    const chartRef = useRef();
    useEffect(() => {
      const timeout = setTimeout(() => {
        chartRef.current = echarts.init(divRef.current);
        const optionGraph = creator(data, intl, DifrentCloseColor);

        chartRef.current.setOption(optionGraph);
      }, 100);
      const listner = () => {
        //   console.log("resizing");
        if (chartRef.current) chartRef.current.resize();
      };

      window.addEventListener("resize", listner);

      return () => {
        clearTimeout(timeout);
        if (chartRef.current) {
          //   chartRef.current.dispose();
          chartRef.current = undefined;
        }
        window.removeEventListener("resize", listner);
      };
    }, [DifrentCloseColor, data, divRef, intl]);
  };

  const Chart = ({ className, Data,  DifrentCloseColor}) => {
    const barChartRef = useRef();
    const intl = useIntl();
    useChartHook(Data, barChartRef, intl, DifrentCloseColor);

    return <div ref={barChartRef} className="w-100 h-100"></div>;
  };

  return Chart;
};

export const getMonth = (amount) => {
  if (amount < 0) {
    return monthNames[Math.abs(amount + 1)];
  } else {
    return monthNames[amount];
  }
};
