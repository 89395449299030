import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSitemap } from "@fortawesome/pro-light-svg-icons";
import { usePopperRef } from "../Popper/Popper";
import { ConnectedBreadcrumbs } from "./Breadcrumbs";
import { createOriginPopup } from "../../Containers/Origin/Origin";
import classnames from "classnames";
const BreadcrumbsAnchor = React.forwardRef(({ id, minimal, originType, className, ...rest }, ref) => {
  if (minimal) {
    return (
		<div className={classnames("ar-breadcrumbs-icon", className)} ref={ref} {...rest}>
        <FontAwesomeIcon icon={faSitemap} />
      </div>
    );
  }

  return (
    <div className={classnames("ar-breadcrumbs-icon", className)} ref={ref} {...rest}>
      <FontAwesomeIcon icon={faSitemap} />
    </div>
  );
});

const TimeChargeBreadcrumbsPopup = ({ id, originType }) => {
  const popperRef = usePopperRef();

  const onLoaded = () => {
    if (popperRef.current) popperRef.current.scheduleUpdate();
  };
  const v = { type: originType, data: id };
  return (
    <div className="bg-white ar-classification-breadcrumbs p-3">
      <ConnectedBreadcrumbs onLoad={onLoaded} value={v} />
    </div>
  );
};

const BreadcrumbsPopup = createOriginPopup(
  BreadcrumbsAnchor,
  TimeChargeBreadcrumbsPopup
);

export default BreadcrumbsPopup;
