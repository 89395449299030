import { faSuitcase } from "@fortawesome/pro-solid-svg-icons";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { useSidebar } from "../../../../../Components/Sidebar/SidebarV2";
import { formatCustomerAutomationForm } from "../../../../../Helpers/FormFormmaterHelper";
import ClientFormContent from "../../../../Clients/ClientFormContent";
import { defaultCustomerForm } from "../../../../Clients/ClientsHelper";
import { ElementsRefContext } from "../../AutomationDetails";
import {
  AutomationTestClickContext,
  AutomationTestRequestContext,
  useNodeUpdater
} from "../../AutomationDetailsHelper";
import SourceHandles from "../../Handles/SourceHandles";
import TargetHandles from "../../Handles/TargetHandles";
import AutomationTestResults from "../AutomationTestResults";
import NodeBaseContainer from "../NodeExtraOptions/NodeExtraOptions";
import AutomationCreation, {
  EntityAutomationCreationSidebar,
  snakeToFormCase
} from "./CreationNode";

const convertAutomationToClientForm = (form, currencies) => {
  const {
    name,
    creator,
    manager_id,
    contacts,
    description,
    status,
    client_types,
    email,
    phone_number,
    fiscal_number,
    domain,
    followers,
    addresses = []
  } = form;

  let resolvedAddresses = [];

  for (const e of addresses) {
    let newObj = {};
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const element = e[key];
        const newKey = snakeToFormCase(key);
        newObj[newKey] = element;
      }
    }
    resolvedAddresses.push(newObj);
  }

  let AddressSelector = {
    Id: resolvedAddresses[0]?.Id || undefined,
    Country: resolvedAddresses[0]?.CountryId || undefined,
    Address1: resolvedAddresses[0]?.Address1 || "",
    Address2: resolvedAddresses[0]?.Address2 || "",
    District: resolvedAddresses[0]?.District || "",
    Locality: resolvedAddresses[0]?.Locality || "",
    PostalCode: resolvedAddresses[0]?.PostalCode || "",
    Latitude: resolvedAddresses[0]?.Latitude || "",
    Longitude: resolvedAddresses[0]?.Longitude || ""
  };

  return {
    Manager: manager_id,
    Name: name,
    Creator: creator,
    Email: email,
    Description: description,
    Status: status,
    Followers: followers,
    ClientTypes: client_types,
    PhoneNumber: phone_number,
    Contacts: contacts,
    FiscalNumber: fiscal_number,
    Domain: domain,
    AddressSelector
  };
};

const settings = {
  convertAutomationToForm: convertAutomationToClientForm,
  buildBaseForm: ({ user, id, convertedForm, currencies }) => {
    const defaultForm = defaultCustomerForm();
    return { ...defaultForm, ...convertedForm };
  },
  getDefaultForm: ({ user }) => {
    return defaultCustomerForm();
  },
  FormContent: (props) => {
    return <ClientFormContent {...props}></ClientFormContent>;
  },
  formatToSnake: formatCustomerAutomationForm
};

const ClientCreation = React.memo(({ selected, id, data, type }) => {
  const [openSidebar, closeSidebar] = useSidebar();
  const AllElementsRef = useContext(ElementsRefContext);
  const updater = useNodeUpdater();

  const handleSubmit = (newData) => {
    updater(id, { ...newData });
    closeSidebar();
  };

  const AutomationTestRequest = useContext(AutomationTestRequestContext);
  const AutomationTestClick = useContext(AutomationTestClickContext);

  const handleDoubleClick = () => {
    openSidebar(
      <EntityAutomationCreationSidebar
        baseRequest={AutomationTestRequest}
        automationTestClick={AutomationTestClick}
        CurrentNodeId={id}
        settings={settings}
        data={data}
        handleSubmit={handleSubmit}
        AllElements={AllElementsRef.current}
      />
    );
  };
  const { enabled, config } = data;
	const intl = useIntl();
  return (
    <div className="position-relative">
      <AutomationTestResults id={id} />
      <NodeBaseContainer data={data} id={id}>
        <TargetHandles />
        <AutomationCreation
          config={config}
          type={type}
          enabled={enabled}
          selected={selected}
          onDoubleClick={handleDoubleClick}
          icon={faSuitcase}
					text={intl.formatMessage({ id: "CREATE_ACCOUNT" })}
        />
        <SourceHandles />
      </NodeBaseContainer>
    </div>
  );
});

export default ClientCreation;
