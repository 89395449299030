import React, { useMemo } from "react";
import { createFormRequestHook, useFormState } from "../../Components/Forms";
import {
  spaceCallsSchema,
  spaceClientSchema,
  spaceContractSchema,
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceTasksSchema,
  spacePipelineSchema,
  spaceOrganizationSchema,
  spaceDealsSchema,
  spacePipelineStatusSchema
} from "../../config/schema";
import {
  convertCallEntityToForm,
  defaultCallForm,
  CallStatusTypes,
  ValidateCallForm
} from "./CallsHelper";
import { useCall } from "../../Hooks/EntityHooks";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useToast } from "../../Components/Toast/ToastProvider";
import {
  formatCallForm,
  getInterventionTarget
} from "../../Helpers/FormFormmaterHelper";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../Components/Forms/SideMenuForm";
import { useIntl } from "react-intl";

import { entityActionType, originTypes } from "../../Helpers/MiscHelper";
import { useManagerVerifier } from "../Form/FormHelper";

import { useCurrentAccount } from "../../Contexts/UserContext";
import { canUpdateEntity, ConclusionForm } from "../ChildForms/ChildForms";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";
import { PipelineTypeEnum } from "../Settings/Pipelines/NewPipelineHelper";
import {
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import { updateManagerFollowers } from "../../Components/EntityFollow/EntityFollowHelper";
import {
  isOrganzationRequestValid,
  OrganizationRequestSideError
} from "../../Components/Organizations/OrganizationHelper";
import { useHandleError } from "../../Components/CGrid/ServerAwareHelper";
import { callCommunicator } from "./CallCommunicator";
import CallFormContent from "./CallFormContent";
import {
  FormTemplateProvider,
  useFormTemplateGetter
} from "../../Components/Forms/FormTemplatesHelper";

const useCallRequest = createFormRequestHook({
  schema: spaceCallsSchema,
  dependencies: [
    {
      schema: spaceClientSchema,
      getEntityId: ({ clientId }) => clientId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          CallTarget: {
            type: "Client",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceContractSchema,
      getEntityId: ({ contractId }) => contractId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          CallTarget: {
            type: "Contract",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceProjectsSchema,
      getEntityId: ({ projectId }) => projectId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          CallTarget: {
            type: "Project",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceTicketsSchema,
      getEntityId: ({ ticketId }) => ticketId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          CallTarget: {
            type: "Ticket",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceTasksSchema,
      getEntityId: ({ taskId }) => taskId,
      convertEntityToForm: (entity, props) => {
        const { Id, Company } = entity;
        return {
          CallTarget: {
            type: "Task",
            data: Id
          },
          Company
        };
      }
    },
    {
      schema: spaceDealsSchema,
      getEntityId: ({ dealId }) => dealId,
      convertEntityToForm: (entity, props) => {
        const { Id, Contact, Company } = entity;
        return {
          CallTarget: {
            type: originTypes.deals,
            data: Id
          },
          Contact,
          Company
        };
      }
    }
  ],
  convertEntityToForm: convertCallEntityToForm,
  formatDuplicationEntity: (entity) => {
    const { PipelineStatus } = entity;
    if (PipelineStatus) {
      const pipelineStatusEntity = getEntity(
        spacePipelineStatusSchema,
        PipelineStatus
      );
      if (pipelineStatusEntity.Status !== CallStatusTypes.open) {
        entity.PipelineStatus = null;
      }
    }
    return entity;
  }
});

const CallForm = (props) => {
  const { key, loading, error, convertedForm, isChild, childProps } =
    useCallRequest(props);

  const { id, defaultForm, onSuccess, className } = props;

  const call = useCall(id);

  const canEdit = call ? call.CanEdit : true;
  const user = useCurrentAccount();
  const {
    loading: loadingPipelines,
    data: pipelines,
    error: errorPipelines
  } = useSpaceQuery(`query/pipelines?type=${PipelineTypeEnum.Call}`, [
    spacePipelineSchema
  ]);

  const orgState = useSpaceQuery(
    `query/organizations?$filter=((Account/Id eq '${user.Id}') or (Manager/Id eq '${user.Id}'))`,
    [spaceOrganizationSchema]
  );

  const { loading: loadingOrganizations, data: organizations } = orgState;

  const resolvedForm = useMemo(() => {
    let form = {
      ...defaultForm,
      ...convertedForm
    };

    if (childProps) {
      const { CallTarget, ...rest } = childProps;
      form.CallTarget = getInterventionTarget(childProps) || form.CallTarget;
      form = { ...form, ...rest };
    }

    if (
      !id &&
      organizations &&
      organizations.findIndex((e) => e === form.Company) === -1
    ) {
      form.Company = organizations[0];
    }

    return form;
  }, [childProps, convertedForm, defaultForm, id, organizations]);

  const { data: formTemplates, refetch: formTemplateRefetch } =
    useFormTemplateGetter(spaceCallsSchema);

  if (
    loadingPipelines ||
    errorPipelines ||
    loading ||
    error ||
    loadingOrganizations
  )
    return <LoaderSpinner center size="sm" className="text-secondary" />;

  if (!isOrganzationRequestValid(orgState))
    return <OrganizationRequestSideError requestState={orgState} />;

  const canDelete = call?.CanDelete || false;

  return (
    <FormTemplateProvider forms={formTemplates} refetch={formTemplateRefetch}>
      <CallFormBase
        className={className}
        canDelete={canDelete}
        isChild={isChild}
        pipelines={pipelines}
        onSuccess={onSuccess}
        id={id}
        canEdit={canEdit}
        key={key}
        convertedForm={resolvedForm}
        organizations={organizations}
      />
    </FormTemplateProvider>
  );
};

// const CommentObject = {
// 	Note: fk(spaceNoteSchema)
// };

// const CommentInserter = ({ callID, onClose, addComment }) => {
// 	const [text, setText] = useState("");

// 	const [post, { loading }] = useSchemaPost(spaceNoteSchema, null, {
// 		normalizedSchema: CommentObject,
// 		onSuccess: ({ data }) => {
// 			addComment(data);
// 			onClose();
// 		}
// 	});

// 	return (
// 		<div>
// 			<HTMLInput
// 				disabled={loading}
// 				value={text}
// 				onChange={e => setText(e.target.value)}
// 			/>
// 			<LoadableButton
// 				size="sm"
// 				isLoading={loading}
// 				onClick={() =>
// 					post({
// 						Description: text,
// 						callId: callID,
// 						Type: 2,
// 					})
// 				}
// 			>
// 				Guardar
//       </LoadableButton>
// 			<Button className="ml-2" vType="link-danger" onClick={onClose}>
// 				Cancelar
//       </Button>
// 		</div>
// 	);
// };

// const CommentInsertion = ({ call: callId, addComment }) => {
// 	const [isInserting, setIsInserting] = useState(false);

// 	if (!isInserting) {
// 		return (
// 			<div
// 				className="text-primary d-inline-block cursor-pointer"
// 				onClick={() => {
// 					setIsInserting(true);
// 				}}
// 			>
// 				<FontAwesomeIcon style={{ fontSize: 14, width: 14 }} icon={faComment} />
// 				<span style={{ paddingLeft: 5, fontSize: 14 }}>Comentar</span>
// 			</div>
// 		);
// 	} else {
// 		return (
// 			<CommentInserter
// 				addComment={addComment}
// 				callID={callId}
// 				onClose={() => setIsInserting(false)}
// 			/>
// 		);
// 	}
// };

// const CommentComponent = ({ comment }) => {
// 	const { Account, Date } = comment;

// 	const e = useCall(comment.Call);

// 	return (
// 		<div
// 			style={{
// 				padding: "8px 8px 5px",
// 				backgroundColor: "#f7f7f7",
// 				marginBottom: 10,
// 				borderRadius: 5
// 			}}
// 		>
// 			<HistoryAccountProfile account={Account.Id} />{" "}
// 			{/* <div className="d-inline-block"> comentou o seguinte: </div> */}
// 			<CommentPreviewer comment={e} date={Date} />
// 		</div>
// 	);
// };

// const CommentsComponent = ({ comments }) => {
// 	return comments.map(e => {
// 		return <CommentComponent key={e.Project} comment={e} />;
// 	});
// };

// const CommentPreviewer = ({ comment, date }) => {
// 	const { NoTagDescription } = comment;
// 	const [isEditing, setIsEditing] = useState(false);

// 	if (!isEditing)
// 		return (
// 			<div style={{ wordBreak: "break-word", marginLeft: 40 }}>
// 				<span className="d-inline-block mr-2">
// 					{NoTagDescription}
// 					<div
// 						style={{ marginLeft: 10 }}
// 						className="text-primary d-inline-block cursor-pointer"
// 						onClick={() => setIsEditing(true)}
// 					>
// 						<FontAwesomeIcon size="sm" icon={faPen} />
// 					</div>
// 				</span>
// 				<br />

// 				<span style={{ fontSize: 11, color: "#002b63cf" }}>
// 					<RelativeTime date={date} />
// 				</span>
// 			</div>
// 		);

// 	return (
// 		<CommentUpdater comment={comment} onClose={() => setIsEditing(false)} />
// 	);
// };

// const CommentUpdater = ({ comment, onClose }) => {
// 	const { Description } = comment;

// 	const [text, setText] = useState(Description);

// 	const [post, { loading }] = useSchemaPost(spaceNoteSchema, comment.Id, {
// 		normalizedSchema: CommentObject,
// 		onSuccess: () => onClose()
// 	});

// 	return (
// 		<div>
// 			<HTMLInput
// 				disabled={loading}
// 				value={text}
// 				onChange={e => setText(e.target.value)}
// 			/>
// 			<LoadableButton
// 				size="sm"
// 				isLoading={loading}
// 				onClick={() =>
// 					post({
// 						Description: text
// 					})
// 				}
// 			>
// 				Guardar
//       </LoadableButton>
// 			<Button className="ml-2" vType="link-danger" onClick={onClose}>
// 				Cancelar
//       </Button>
// 		</div>
// 	);
// };

const CallFormBase = ({
  convertedForm,
  id,
  canEdit,
  onSuccess,
  isChild,
  pipelines,
  organizations,
  canDelete,
  className
}) => {
  const user = useCurrentAccount();

  // let CallItem = useCall(id ? id : undefined);
  // if (!CallItem) {
  // 	CallItem = {};
  // 	CallItem.Comments = [];
  // }
  // const { Comments } = CallItem;

  // const [c, setC] = useState(Comments);

  // const addComment = c => {
  // 	return
  // };

  const formState = useFormState(() => {
    const defaultCallFor = defaultCallForm({ user, id });
    let Pipeline, Company;
    let AddressSelector;

    if (
      !Pipeline &&
      pipelines &&
      pipelines.length > 0 &&
      pipelines.length === 1
    ) {
      Pipeline = pipelines[0];
    }

    if (organizations.length === 1 && !Company) {
      Company = organizations[0];
    }

    if (
      convertedForm &&
      convertedForm.Addresses &&
      convertedForm.Addresses.length > 0
    ) {
      AddressSelector = {
        Id: convertedForm.Addresses[0].Id || undefined,
        Country: convertedForm.Addresses[0].Country || undefined,
        Address1: convertedForm.Addresses[0].Address1 || "",
        Address2: convertedForm.Addresses[0].Address2 || "",
        District: convertedForm.Addresses[0].District || "",
        Locality: convertedForm.Addresses[0].Locality || "",
        PostalCode: convertedForm.Addresses[0].PostalCode || "",
        Latitude: convertedForm.Addresses[0].Latitude || "",
        Longitude: convertedForm.Addresses[0].Longitude || ""
      };
    } else {
      AddressSelector = {
        Id: undefined,
        Country: defaultCallFor.Country,
        Address1: defaultCallFor.Address,
        Address2: defaultCallFor.Address,
        District: defaultCallFor.Region,
        Locality: defaultCallFor.Locale,
        PostalCode: defaultCallFor.PostalCode,
        Latitude: defaultCallFor.Latitude,
        Longitude: defaultCallFor.Longitude
      };
    }

    return {
      ...defaultCallFor,
			Company,
			Pipeline,
      ...convertedForm,
      AddressSelector
    };
  });
  const { form, handleChange, setForm } = formState;

  const {
    CallTarget,
    ManagerAccount,
    DepartmentTeam,
    // Pipeline,
    // Company,
    Followers
  } = form;

  const intl = useIntl();
  const createToast = useToast();
  // useMarkRead(spaceCallsSchema, id, ManagerAccount);
  const handleTranslatedError = useHandleError(spaceCallsSchema);
  const [post, { loading }] = useSidebarSpacePost(
    id ? `calls/${id}` : "calls",
    spaceCallsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        if (!id) {
          defineChildProps({ Call: newId });
          callCommunicator.dispatch({
            type: entityActionType.creation,
            id: newId
          });
        } else {
          callCommunicator.dispatch({ type: entityActionType.update, id });
        }
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "CALL" })} nº ${newId || id}`,
					description: !id ? `${intl.formatMessage({ id: "CREATED_SUCCEFULLY" })}` :`${intl.formatMessage({ id: "SAVE_SUCCESS" })}`
        });

        const { data, type } = CallTarget;

        if (type === "Ticket" && canUpdateEntity(spaceTicketsSchema, data)) {
          return openSidebar(
            <ConclusionForm ticket={data} />,
            intl.formatMessage({ id: "CONCLUDE_TICKET" })
          );
        } else if (type === "Task" && canUpdateEntity(spaceTasksSchema, data)) {
          return openSidebar(
            <ConclusionForm task={data} />,
            intl.formatMessage({ id: "CONCLUDE_TASK" })
          );
        }
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  const validatingManager = useManagerVerifier(ManagerAccount, DepartmentTeam, {
    onUpdateManager: (manager) => {
      handleChange({
        ManagerAccount: manager,
        Followers: updateManagerFollowers(
          Followers,
          ManagerAccount,
          manager,
          []
        )
      });
    }
  });

  const handleSubmit = (form) => {
    post(formatCallForm(form));
  };

  // const followers = useJoinedFollowerAccounts(ManagerAccount);
  // const possibleFollowers = useAvailableFollowers(Creator, followers);

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `calls/${id}`,
    spaceCallsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        onSuccess && onSuccess();
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "CALL" })} nº ${id}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      }
    }
  );

  return (
    <SideForm
      canEdit={canEdit}
      formState={formState}
      onSubmit={handleSubmit}
      validate={ValidateCallForm}
    >
      <SideFormContent className={className}>
        <CallFormContent
          formState={formState}
          canEdit={canEdit}
          organizations={organizations}
          pipelines={pipelines}
          id={id}
        />
      </SideFormContent>

      <SideFormFooter
        canDelete={canDelete}
        deleteLoading={deleteLoading}
        handleDelete={remove}
        form={form}
        handleChange={setForm}
        schema={spaceCallsSchema}
        disabled={validatingManager}
        isLoading={loading}
      />
    </SideForm>
  );
};

export default CallForm;
