import React from "react";
import { usePipelineStatus, useProject } from "../../Hooks/EntityHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDrawCircle,
  faSquareParking,
  faSuitcase,
  faUserEdit,
  faUsers,
  faUsersClass,
  faUsersMedical,
  faUserTie
} from "@fortawesome/pro-solid-svg-icons";
import {
  ConnectProjectEntity,
  ProjectStatusProfileWithIcon
} from "../../Components/Projects/ProjectProfile";
import { spaceProjectsSchema } from "../../config/schema";
import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";
import SplitCol from "../../Components/SplitCol/SplitCol";
import {
  ProjectHourPercentageBar,
  EstimatedMonthsBar
} from "../../Components/PercentageBar/PercentageBar";
import {
  ConnectedDepartmentProfileStack,
  ConnectedAccountsTeamsDepartmentsProfileStack
} from "../../Components/Accounts/AccountProfileStack";
import {
  OriginContainer,
  OriginDate,
  OriginHeader,
  OriginItemGroup
} from "../../Components/Origin/Origin";
import { createOriginPopup } from "./Origin";

import { ConnectedClientOrigin } from "./ClientOrigin";
import { ConnectedAccountPopup } from "./AcountPopup";
import { ConnectedContactPopup } from "./ContactPopup";
import OriginOverview from "./OriginOverview";
import { ProjectEntityGridButtons } from "../Projects/ProjectsHelper";
import classnames from "classnames";
import { ProjectIcon } from "../../Components/Common/EntityIcons";
import { useIntl } from "react-intl";
import Button from "../../Components/Button/Button";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { PipelineStatusProfile } from "../AdvancedMultiInputs/PipelineDropdowns";
import { useSpace } from "../../Contexts/SpaceContext";
import { faIdCardClip } from "@fortawesome/pro-light-svg-icons";
import { useConnectedSchemaGetter } from "../../Helpers/HelperHooks";

export const ProjectOriginAnchor = React.forwardRef(
  ({ project, children, onClick, className, dynamicFont, ...rest }, ref) => {
    const openForm = useEntitySummarySidebar();
    if (!project) return null;
    const { IsDeleted } = project;
    return (
      <div
        ref={ref}
        className={classnames("link-color text-truncate w-fit", className, {
          "item-was-deleted": IsDeleted
        })}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <>
            <div
              className={classnames(
                "link-color w-fit d-flex align-items-center text-truncate",
                {
                  "fs-12": !dynamicFont
                }
              )}
            >
              <div className="mr-1 d-flex justify-content-center align-items-center">
                <ProjectStatusProfileWithIcon
                  Icon={<FontAwesomeIcon icon={faSquareParking} />}
                  inline
                  hideName
                  status={project.Status || project.Attributes}
                />
              </div>

              {/* <div className="d-inline-flex align-items-center">
                <div className="position-relative d-inline-block mr-1">
                  <FontAwesomeIcon icon={faSquareParking} />
                </div>
              </div> */}
              <Button
                vType="link-primary"
                className="EntityLinkClassID fs-12 text-black"
                onClick={() => {
                  openForm({
                    type: moduleTypes.projects,
                    props: {
                      id: project.Id
                    }
                  });
                }}
              >
                <span>{project.Name}</span>
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
);
export const ConnectedProjectOriginAnchor = ({ project, ...rest }) => {
  const resolvedProject = useProject(project);
  const Result = useConnectedSchemaGetter(project, spaceProjectsSchema);

  if (Result) return Result;
  return <ProjectOriginAnchor project={resolvedProject} {...rest} />;
};

export const ProjectOriginPopup = ({ project }) => {
  const intl = useIntl();
  const p = useProject(project.Id);

  const {
    Name,
    Id,
    // Status,
    Client,
    IndirectClient,
    Contact,
    Manager,
    CodeERP,
    Departments,
    Creator,
    ReceivedDate,
    PipelineStatus,
    SalesOpportunity,
    EndDate,
    BeginDate,
    Collaborators,
    CreationDate
  } = p;
  const connectedPipelineStatus = usePipelineStatus(PipelineStatus);
  const resolvedClient = Client || IndirectClient;

  const hasDepartments = Boolean(Departments && Departments.length > 0);
  const hasAccounts = Boolean(
    Collaborators.Accounts && Collaborators.Accounts.length > 0
  );
  const hasTeams = Boolean(
    Collaborators.Teams && Collaborators.Teams.length > 0
  );
  const space = useSpace();
  return (
    <OriginContainer>
      <OriginHeader
        Dif
        date={CreationDate}
        className="mb-1"
        type="Projeto"
        entity="PROJECT"
        Icon={<ProjectIcon className="mr-2" />}
        EntityButtons={<ProjectEntityGridButtons noColors entity={p} minimal />}
        id={Id}
        link={`/s/${space.Id}/projects/${Id}`}
        title={Name}
      />

      {/* <div className="d-flex justify-content-between px-3">
        <div className="d-flex align-items-center text-black fw-medium">
          <ProjectIcon className="mr-1" />
          <div className="mr-1">Projeto</div>
          {Id}
        </div>
        <OriginDate className="fs-14" date={ReceivedDate} />
	  </div> */}
      {/* <OriginItemGroup
        title={intl.formatMessage({ id: "STATUS" })}
      ></OriginItemGroup> */}
      <div className="mx-3 mb-3">
        {/* <div className="text-black fs-14 mb-2">{Name}</div> */}
        <div className="mb-1 d-flex align-items-center justify-content-between">
          <PipelineStatusProfile pipeline={connectedPipelineStatus} />

          <div>
            <OriginDate className="fs-12" date={ReceivedDate} />
          </div>
        </div>
        <ProjectHourPercentageBar
          title={intl.formatMessage({ id: "INPUTED_HOURS" })}
          className="mb-2"
          project={p}
        />
        <EstimatedMonthsBar
          title={intl.formatMessage({ id: "DURATION" })}
          EndDate={EndDate}
          BeginDate={BeginDate}
        />
        {SalesOpportunity && (
          <span className="text-black fs-12">OPV: {SalesOpportunity}</span>
        )}
      </div>
      {/* <ConnectedBreadcrumbs className="px-3 mb-2" value={{
        type: "Project",
        data: Id
      }} /> */}
      {CodeERP && (
        <OriginItemGroup
          icon={faIdCardClip}
          show={Boolean(CodeERP)}
          title={intl.formatMessage({ id: "CODEERP" })}
        >
          <span className="text-black d-flex align-items-center fs-12">
            {CodeERP}
          </span>
        </OriginItemGroup>
      )}
      <OriginItemGroup
        icon={faSuitcase}
        title={intl.formatMessage({ id: "ACCOUNT" })}
        className="mb-2"
      >
        {/* <ConnectedClientPopup client={Client} /> */}
        <ConnectedClientOrigin client={resolvedClient}>
          <ConnectedClientProfile showId client={resolvedClient} />
        </ConnectedClientOrigin>
      </OriginItemGroup>
      <SplitCol
        className="mb-2 fs-12"
        left={
          <OriginItemGroup
            icon={faUserTie}
            title={intl.formatMessage({ id: "CONTACT" })}
          >
            <ConnectedContactPopup contact={Contact} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUserEdit}
            title={intl.formatMessage({ id: "MANAGER" })}
          >
            <ConnectedAccountPopup account={Manager} />
          </OriginItemGroup>
        }
      />

      {hasDepartments && !(hasAccounts || hasTeams) && (
        <OriginItemGroup
          icon={faUsers}
          show={hasDepartments}
          title={intl.formatMessage({ id: "DEPARTMENTS" })}
        >
          <ConnectedDepartmentProfileStack departments={Departments} />
        </OriginItemGroup>
      )}

      {!hasDepartments && (hasAccounts || hasTeams) && (
        <OriginItemGroup
          icon={faUsersClass}
          show={hasAccounts || hasTeams}
          title={intl.formatMessage({ id: "TEAMS_CONTRIBUTORS" })}
        >
          <ConnectedAccountsTeamsDepartmentsProfileStack
            accounts={Collaborators.Accounts}
            teams={Collaborators.Teams}
          />
        </OriginItemGroup>
      )}

      <SplitCol
        className="mb-2"
        show={hasDepartments && (hasAccounts || hasTeams)}
        left={
          <OriginItemGroup
            icon={faUsers}
            show={hasDepartments}
            title={intl.formatMessage({ id: "DEPARTMENTS" })}
          >
            <ConnectedDepartmentProfileStack departments={Departments} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUsersClass}
            show={hasAccounts || hasTeams}
            title={intl.formatMessage({ id: "TEAMS_CONTRIBUTORS" })}
          >
            <ConnectedAccountsTeamsDepartmentsProfileStack
              accounts={Collaborators.Accounts}
              teams={Collaborators.Teams}
            />
          </OriginItemGroup>
        }
      />

      <SplitCol
        left={
          <OriginItemGroup
            icon={faUsersMedical}
            title={intl.formatMessage({ id: "CREATED_BY" })}
          >
            <ConnectedAccountPopup account={Creator} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faDrawCircle}
            title={intl.formatMessage({ id: "ORIGIN" })}
          >
            <OriginOverview entity={p} />
          </OriginItemGroup>
        }
      />

      <div className="pb-3"></div>
      {/* <div className="d-flex align-items-center justify-content-center shadow-top py-3 mt-3">
        {/* <ProjectFormButton id={Id} canEdit={CanEdit} className="mx-1" />
        <TaskFormButton className="mx-1" alt formProps={{ projectId: Id }} />
        <InterventionFormButton
          alt
          className="mx-1"
          formProps={{ projectId: Id }}
        /> */}
      {/* <ProjectEntityButtons entity={p} /> */}
      {/* </div>  */}
    </OriginContainer>
  );
};

export const projectOriginOptions = {
  schema: spaceProjectsSchema,
  valueProp: "project"
};

export const ProjectOrigin = createOriginPopup(
  ProjectOriginAnchor,
  ProjectOriginPopup,
  // ({ project }) => {
  //   return <EntityPopup id={project.Id} schema={spaceProjectsSchema} />;
  // },
  projectOriginOptions
);

export default ProjectOrigin;

export const ConnectedProjectOrigin = ConnectProjectEntity(ProjectOrigin);
