import React, { useContext, useState } from "react";
import {
  BaseSidebarContainer,
  BaseSidebarContent,
  BaseSidebarHeader
} from "../../../../Components/Sidebar/SidebarV2Helper";
import classnames from "classnames";
import styles from "./NodesSidebar.module.css";
import automationStyles from "../AutomationDetails.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCodeBranch,
  faGlobe,
  faRoute,
  faStopwatch,
  faTicketSimple,
  faBolt,
  faEnvelope,
  faPencil,
  faRedoAlt,
  faNote,
  faFileContract,
  faHandshake,
  faCalendarExclamation,
  faPhoneAlt,
  faSquareParking,
  faSuitcase,
  faUserTie,
  faAt,
  faArrowCircleRight,
  faUserClock,
  faInbox,
  faClipboardCheck,
  faFileSpreadsheet,
  faEnvelopeOpen,
  faFile
} from "@fortawesome/pro-solid-svg-icons";

import { useIntl } from "react-intl";
import { NodeActionsEnum, TriggerTypeEnum } from "../NodeTypes";
import { useAutomationCommunicator } from "../NodeHelper";
import AnimatedAccordion from "../../../../Components/AnimatedAccordion/AnimatedAccordion";
import { SidebarVisualTypeEnum } from "../../../../Components/Sidebar/SidebarViewsHelper";

const NodeItem = ({ icon, text, type, disabled }) => {
  const onDragStart = (event) => {
    event.dataTransfer.setData("application/reactflow", type);
    event.dataTransfer.effectAllowed = "move";
  };

  const addNode = useContext(NodeContext);

  return (
    <div
      onClick={() => addNode(type)}
      onDragStart={onDragStart}
      draggable
      className={classnames(
        automationStyles.node,
        styles.item,
        { [styles.disabledNode]: disabled },
        "d-flex w-100 bg-white"
      )}
    >
      <div
        className={classnames(
          automationStyles.nodeIcon,
          automationStyles[type],
          "p-3 d-flex align-items-center justify-content-center"
        )}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="px-3 d-flex justify-content-center  flex-column text-black fs-14">
        <div className="fw-medium">{text}</div>
      </div>
    </div>
  );
};

const NodeItems = ({ title, children }) => {
  return (
    <AnimatedAccordion
      className={classnames("py-2")}
      storageName={title}
      title={<div className="fw-medium text-black fs-16">{title}</div>}
      isOpenByDefault
    >
      <div className={classnames(styles.items, "px-3")}>{children}</div>
    </AnimatedAccordion>
  );

  // return (
  //   <div className="d-flex flex-column mb-4">
  //     <div className="fw-medium text-black mb-2">{title}</div>
  //     <div className={classnames(styles.items)}>{children}</div>
  //   </div>
  // );
};

const NodeContext = React.createContext();

const NodesSidebar = ({ onNodeClick, rootId }) => {
  const [hasRootId, setHasRootId] = useState(Boolean(rootId));
  useAutomationCommunicator(({ rootId }) => {
    setHasRootId(Boolean(rootId));
  });
  const intl = useIntl();
  return (
    <NodeContext.Provider value={onNodeClick}>
      <BaseSidebarContainer
        defaultSidebarView={SidebarVisualTypeEnum.side}
        width="560"
      >
        <BaseSidebarHeader disableSizeSelector>
          {intl.formatMessage({ id: "ADD_NODES" })}
        </BaseSidebarHeader>
        <BaseSidebarContent>
          <div className="p-3">
            <NodeItems title="Triggers">
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "ACCOUNT" })}
                type={TriggerTypeEnum.trigger_client_creation}
                icon={faSuitcase}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "CONTACT" })}
                type={TriggerTypeEnum.trigger_contact_creation}
                icon={faSuitcase}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "DEAL" })}
                type={TriggerTypeEnum.trigger_deal_creation}
                icon={faHandshake}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "CONTRACT" })}
                type={TriggerTypeEnum.trigger_contract_creation}
                icon={faFileContract}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "SUBSCRIPTION" })}
                type={TriggerTypeEnum.trigger_subscription_creation}
                icon={faCalendarExclamation}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "CLASSIFICATION" })}
                type={TriggerTypeEnum.trigger_classification_creation}
                icon={faClipboardCheck}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "CALL" })}
                type={TriggerTypeEnum.trigger_call_creation}
                icon={faPhoneAlt}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "PROJECT" })}
                type={TriggerTypeEnum.trigger_project_creation}
                icon={faSquareParking}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "CASE" })}
                type={TriggerTypeEnum.trigger_ticket_creation}
                icon={faTicketSimple}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "TASK" })}
                type={TriggerTypeEnum.trigger_task_creation}
                icon={faNote}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "TIME" })}
                type={TriggerTypeEnum.trigger_time_creation}
                icon={faUserClock}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "MANUAL" })}
                type={TriggerTypeEnum.trigger_manual}
                icon={faBolt}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "WEBHOOK" })}
                type={TriggerTypeEnum.trigger_webhook}
                icon={faGlobe}
              />
              <NodeItem
                disabled={hasRootId}
                text={intl.formatMessage({ id: "CRON" })}
                type={TriggerTypeEnum.trigger_cron}
                icon={faStopwatch}
              />
            </NodeItems>
            <NodeItems title="Workflow">
              <NodeItem
                text="If/Else"
                type={NodeActionsEnum.condition_if}
                icon={faRoute}
              />
              <NodeItem
                text={intl.formatMessage({ id: "PROPERTY_SETTER" })}
                type={NodeActionsEnum.propertySetter}
                icon={faPencil}
              />
              <NodeItem
                text={"Connect Workflow"}
                type={NodeActionsEnum.connectWorkflow}
                icon={faCodeBranch}
              />
              <NodeItem
                text={intl.formatMessage({ id: "SPLIT_IN_BATCHES" })}
                type={NodeActionsEnum.splitInBatches}
                icon={faRedoAlt}
              />
              <NodeItem
                text={intl.formatMessage({ id: "DELAY" })}
                type={NodeActionsEnum.delay}
                icon={faRoute}
              />
              <NodeItem
                text={intl.formatMessage({ id: "DO_NOTHING" })}
                type={NodeActionsEnum.doNothing}
                icon={faArrowCircleRight}
              />
            </NodeItems>
            <NodeItems title={intl.formatMessage({ id: "CREATION" })}>
              <NodeItem
                text={"Email"}
                type={NodeActionsEnum.emailCreation}
                icon={faEnvelope}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CONVERSATION" })}
                type={NodeActionsEnum.conversationCreation}
                icon={faEnvelope}
              />
              <NodeItem
                text={intl.formatMessage({ id: "ACCOUNT" })}
                type={NodeActionsEnum.clientCreation}
                icon={faSuitcase}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CONTACT" })}
                type={NodeActionsEnum.contactCreation}
                icon={faUserTie}
              />
              <NodeItem
                text={intl.formatMessage({ id: "DEAL" })}
                type={NodeActionsEnum.dealCreation}
                icon={faHandshake}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CONTRACT" })}
                type={NodeActionsEnum.contractCreation}
                icon={faFileContract}
              />
              <NodeItem
                text={intl.formatMessage({ id: "SUBSCRIPTION" })}
                type={NodeActionsEnum.subscriptionCreation}
                icon={faCalendarExclamation}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CALL" })}
                type={NodeActionsEnum.callCreation}
                icon={faPhoneAlt}
              />
              <NodeItem
                text={intl.formatMessage({ id: "PROJECT" })}
                type={NodeActionsEnum.projectCreation}
                icon={faSquareParking}
              />
              <NodeItem
                text={intl.formatMessage({ id: "TASK" })}
                type={NodeActionsEnum.taskCreation}
                icon={faNote}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CASE" })}
                type={NodeActionsEnum.ticketCreation}
                icon={faTicketSimple}
              />
            </NodeItems>
            <NodeItems title={intl.formatMessage({ id: "GET_ITEMS" })}>
              <NodeItem
                text={intl.formatMessage({ id: "ACCOUNT" })}
                type={NodeActionsEnum.getClient}
                icon={faSuitcase}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CONTACT" })}
                type={NodeActionsEnum.getContact}
                icon={faUserTie}
              />
              <NodeItem
                text={intl.formatMessage({ id: "DEAL" })}
                type={NodeActionsEnum.getDeal}
                icon={faHandshake}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CONTRACT" })}
                type={NodeActionsEnum.getContract}
                icon={faFileContract}
              />
              <NodeItem
                text={intl.formatMessage({ id: "SUBSCRIPTION" })}
                type={NodeActionsEnum.getSubscription}
                icon={faCalendarExclamation}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CALL" })}
                type={NodeActionsEnum.getCall}
                icon={faPhoneAlt}
              />
              <NodeItem
                text={intl.formatMessage({ id: "PROJECT" })}
                type={NodeActionsEnum.getProject}
                icon={faSquareParking}
              />
              <NodeItem
                text={intl.formatMessage({ id: "TASK" })}
                type={NodeActionsEnum.getTask}
                icon={faNote}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CASE" })}
                type={NodeActionsEnum.getTicket}
                icon={faTicketSimple}
              />
              <NodeItem
                text={intl.formatMessage({ id: "HTTP_REQUEST" })}
                type={NodeActionsEnum.httpRequest}
                icon={faAt}
              />
              <NodeItem
                text={intl.formatMessage({ id: "HTTP_STATUS_REQUEST" })}
                type={NodeActionsEnum.httpStatusRequest}
                icon={faAt}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CASE_CONVERSATIONS" })}
                type={NodeActionsEnum.getTicketConversations}
                icon={faInbox}
              />
            </NodeItems>
            <NodeItems title={intl.formatMessage({ id: "EXPORTS" })}>
              <NodeItem
                text={intl.formatMessage({ id: "EMAIL_CONVERSATION" })}
                type={NodeActionsEnum.exportEmailsConversations}
                icon={faEnvelopeOpen}
              />
              <NodeItem
                text={intl.formatMessage({ id: "GET_EXPORT_FILE_BY_ID" })}
                type={NodeActionsEnum.getExportFileById}
                icon={faFile}
              />
            </NodeItems>
            <NodeItems title={intl.formatMessage({ id: "REPORTS" })}>
              <NodeItem
                text={intl.formatMessage({ id: "TIME_REPORT" })}
                type={NodeActionsEnum.timeReports}
                icon={faFileSpreadsheet}
              />
            </NodeItems>
            {/* <NodeItems title="Edition">
              <NodeItem
                text={intl.formatMessage({ id: "CLIENT_EDITION" })}
                type={NodeActionsEnum.clientEdition}
                icon={faSuitcase}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CONTACT_EDITION" })}
                type={NodeActionsEnum.contactEdition}
                icon={faUserTie}
              />
              <NodeItem
                text={intl.formatMessage({ id: "DEAL_EDITION" })}
                type={NodeActionsEnum.dealEdition}
                icon={faHandshake}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CONTRACT_EDITION" })}
                type={NodeActionsEnum.contractEdition}
                icon={faFileContract}
              />
              <NodeItem
                text={intl.formatMessage({ id: "SUBSCRIPTION_EDITION" })}
                type={NodeActionsEnum.subscriptionEdition}
                icon={faCalendarExclamation}
              />
              <NodeItem
                text={intl.formatMessage({ id: "CALL_EDITION" })}
                type={NodeActionsEnum.callEdition}
                icon={faPhoneAlt}
              />
              <NodeItem
                text={intl.formatMessage({ id: "PROJECT_EDITION" })}
                type={NodeActionsEnum.projectEdition}
                icon={faSquareParking}
              />
              <NodeItem
                text={intl.formatMessage({ id: "TASK_EDITION" })}
                type={NodeActionsEnum.taskEdition}
                icon={faNote}
              />
              <NodeItem
                text={intl.formatMessage({ id: "TICKET_EDITION" })}
                type={NodeActionsEnum.ticketEdition}
                icon={faTicketSimple}
              />
            </NodeItems> */}
          </div>
        </BaseSidebarContent>
      </BaseSidebarContainer>
    </NodeContext.Provider>
  );
};

export default NodesSidebar;
