export const getAccountSpaceIdFromMapper = (mapper, spaceId, userId) => {
  return mapper[spaceId][userId];
};

function accountSpacesEntityReducer(state = {}, action) {
  if (
    !action.response ||
    !action.response.entities ||
    !action.response.entities.Accounts ||
    !action.response.entities.AccountSpaces
  )
    return state;

  const actionSpaceId = action.spaceId;

  const newState = { ...state };
  
  for (const accountI of Object.keys(action.response.entities.Accounts)) {
    const account = action.response.entities.Accounts[accountI];

    if (!account.AccountSpaces) continue;

    for (const accountSpaceI of account.AccountSpaces) {
      const accountSpace =
        action.response.entities.AccountSpaces[accountSpaceI];
      if (accountSpace.Space) {
        newState[accountSpace.Space] = {
          ...newState[accountSpace.Space],
          [account.Id]: accountSpace.Id
        };
      } else if (actionSpaceId) {
        newState[actionSpaceId] = {
          ...newState[actionSpaceId],
          [account.Id]: accountSpace.Id
        };
      }
    }
  }

  return newState;
}

export default accountSpacesEntityReducer;
