import React, { useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import AddressSelector from "../../Components/AddressSelector/AddressSelector";
import { ConnectedBreadcrumbs } from "../../Components/Breadcrumbs/Breadcrumbs";
import ClipboardProvider from "../../Components/ClipbordProvider/ClipboardProvider";
import CreatedBy from "../../Components/CreatedByComponent/CreatedBy";
import ArDatePicker from "../../Components/DatePicker/ArDatePicker";
import DocTyper from "../../Components/DocTyper/DocTyper";
import { updateManagerFollowers } from "../../Components/EntityFollow/EntityFollowHelper";
import FileAssociations from "../../Components/FileUploader/FileAssociations";
import FileUploader from "../../Components/FileUploader/FileUploader";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import HTMLInput from "../../Components/HTMLInput/HTMLInput";
import { SidebarAutomationContext } from "../../Components/Sidebar/SidebarV2Automation";
import { isValueExpression, originTypes } from "../../Helpers/MiscHelper";
import { usePipelineStatus } from "../../Hooks/EntityHooks";
import {
  ContactDropdown,
  DepartmentTeamDropdown,
  OrganizationDropdown,
  ticketsCancellationReasonsDropdown,
  TicketTargetDropdown,
  UserDropdown,
  TicketTagDropdown,
  RemoveDefaultActiveButtonProvider
} from "../AdvancedMultiInputs";
import {
  TicketPipelineDropdown,
  TicketStatusPipelineDropdown
} from "../AdvancedMultiInputs/PipelineDropdowns";
import { TicketsStatusTypes } from "./TicketsHelper";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";
import {
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceTasksSchema,
  spaceContractSchema,
  spaceCallsSchema,
  spaceDealsSchema
} from "../../config/schema";

export const TicketFormContent = ({
  formState,
  organizations,
  pipelines,
  id,
  canEdit
}) => {
  const { form, handleChange } = formState;
  const {
    TicketTarget,
    ManagerAccount,
    DepartmentTeam,
    Creator,
    // Name,
    Pipeline,
    Contact,
    PipelineStatus,
    AssociatedFiles,
    AssignedAssociations,
    StatusDetailId,
    StatusDetailDescription,
    StatusDetail,
    Company,
    CreationDate,
    Followers,
    IndirectClient,
    Client
    // Comments
  } = form;

  const PipelineId = Pipeline;
  const PipelineStatusId = PipelineStatus;
  const PipelieStatusObj = usePipelineStatus(PipelineStatusId);

  const handleChanges = (e) => {
    const { name, value } = e.target;

    handleChange({
      [name]: value
    });
  };

  if (
    !Pipeline &&
    pipelines &&
    pipelines.length > 0 &&
    pipelines.length === 1
  ) {
    handleChange({
      Pipeline: pipelines[0]
    });
  }

  if (organizations.length === 1 && !Company) {
    handleChange({
      Company: organizations[0]
    });
  }

  const handlePipelineChanges = (e) => {
    const { value } = e.target;
    if (PipelineId === value) return;
    handleChange({
      Pipeline: value,
      PipelineStatus: ""
    });
  };

  if (
    PipelieStatusObj &&
    PipelieStatusObj.Status !== TicketsStatusTypes.canceled &&
    (Boolean(StatusDetail) ||
      Boolean(StatusDetailId) ||
      Boolean(StatusDetailDescription))
  ) {
    handleChange({
      StatusDetail: "",
      StatusDetailId: "",
      StatusDetailDescription: ""
    });
  }

  const handleOrganizationChanges = (e) => {
    const { value } = e.target;
    handleChange({
      Company: value,
      StatusDetailId: null,
      StatusDetail: null
    });
  };

  const handleAssociationsChanges = (value) => {
    handleChange((oldF) => {
      const newF = { ...oldF };
      const newAssignedAssociations = Array.isArray(newF.AssignedAssociations)
        ? [...newF.AssignedAssociations]
        : [];
      const index = newAssignedAssociations.findIndex((e) => e === value);
      if (index !== -1) {
        newAssignedAssociations.splice(index, 1);
      } else newAssignedAssociations.push(value);
      newF.AssignedAssociations = newAssignedAssociations;
      return newF;
    });
  };

  const handleManagerChange = ({ target: { value } }) => {
    handleChange({
      ManagerAccount: value,
      Followers: updateManagerFollowers(Followers, ManagerAccount, value, [])
    });
  };

  const handleTicketTargetChange = (e) => {
    handleChange({
      TicketTarget: e.target.value
      // Contact: null
    });
  };

  const contactValidationURL = useMemo(() => {
    if (!Contact) return null;

    let projectId, ticketId, taskId, contractId, clientId, callId, dealId;
    // indirectClientId;
    if (TicketTarget) {
      switch (TicketTarget.type) {
        case originTypes.project:
          projectId = TicketTarget.data;
          break;

        case originTypes.ticket:
          ticketId = TicketTarget.data;
          break;

        case originTypes.task:
          taskId = TicketTarget.data;
          break;

        case originTypes.contract:
          contractId = TicketTarget.data;
          break;

        case originTypes.client:
          clientId = TicketTarget.data;
          // indirectClientId = IndirectClient;
          break;

        case originTypes.call:
          callId = TicketTarget.data;
          break;

        case originTypes.deals:
          dealId = TicketTarget.data;
          break;

        default:
          break;
      }
    }

    const project = getEntity(spaceProjectsSchema, projectId);
    const ticket = getEntity(spaceTicketsSchema, ticketId);
    const task = getEntity(spaceTasksSchema, taskId);
    const contract = getEntity(spaceContractSchema, contractId);
    const call = getEntity(spaceCallsSchema, callId);
    const deal = getEntity(spaceDealsSchema, dealId);

    const Client =
      clientId ||
      (project && (project.Client || project.IndirectClient)) ||
      (deal && (deal.Client || deal.IndirectClient)) ||
      (ticket && (ticket.Client || ticket.IndirectClient)) ||
      (task && (task.Client || task.IndirectClient)) ||
      (call && (call.Client || call.IndirectClient)) ||
      (contract && (contract.Client || contract.IndirectClient)) ||
      IndirectClient;

    return `Query/Contacts/${Client}/${Contact}`;
  }, [Contact, IndirectClient, TicketTarget]);

  const { loading: contactaValidation } = useSpaceQuery(
    contactValidationURL,
    null,
    {
      onSuccess: ({ data }) => {
        if (!Boolean(data)) {
          handleChange({
            Contact: null
          });
        }
      },
      onError: (err) => {
        handleChange({
          Contact: null
        });
      }
    }
  );

  // const validatingManager = useManagerVerifier(ManagerAccount, DepartmentTeam, {
  //   onUpdateManager: (manager) => {
  //     handleChange({
  //       ManagerAccount: manager,
  //       Followers: updateManagerFollowers(
  //         Followers,
  //         ManagerAccount,
  //         manager,
  //         []
  //       )
  //     });
  //   }
  // });

  // const entity = useTicket(id);
  // const currentManager = entity ? entity.ManagerAccount : Creator;
  const Automation = useContext(SidebarAutomationContext);

  const TargetIsVariable = isValueExpression(TicketTarget);
  return (
    <>
      {Automation && (
        <FormInput
          textId="CREATED_BY"
          name="Creator"
          inputType={UserDropdown}
          className="mb-3"
        />
      )}
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"ASSOCIATE_CASE"} />
      </div>

      {(Automation || (organizations && organizations.length > 1)) && (
        <FormInput
          textId="COMPANY"
          name="Company"
          value={Company}
          inputType={OrganizationDropdown}
          onChange={handleOrganizationChanges}
          className="mb-3"
        />
      )}

      {TicketTarget && (
        <div className="my-1 fs-14 text-black">
          {<FormattedMessage id={"ORIGIN"} />}
        </div>
      )}
      {!TargetIsVariable && (
        <ConnectedBreadcrumbs value={TicketTarget} className="mb-3" />
      )}
      <FormInput
        name="TicketTarget"
        textId="ASSOCIATE_TO"
        inputType={TicketTargetDropdown}
        allClients={false}
        value={TicketTarget}
        onChange={handleTicketTargetChange}
        className="mb-3"
        Ticket={id}
      />

      <FormInput
        className="mb-3"
        name="Contact"
        disabled={contactaValidation}
        textId="CONTACT"
        inputType={ContactDropdown}
        IndirectClient={IndirectClient || Client}
        originTarget={TicketTarget}
      />
      <FormInput className="mb-3" textId="TITLE" name="Name" />

      <FormInput
        className="mb-3"
        name="ReceivedDate"
        textId="ENTRY"
        inputType={ArDatePicker}
        componentClassName="w-100"
        openHourAfterDate
        disableFutureDays
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"CLASSIFICATION"} />
      </div>

      <FormInput
        name="Pipeline"
        className="mb-3"
        disabled={pipelines && pipelines.length > 0 && pipelines.length === 1}
        value={PipelineId}
        textId="PIPELINE"
        inputType={TicketPipelineDropdown}
        onChange={handlePipelineChanges}
      />

      <FormInput
        className="mb-3"
        disabled={!PipelineId}
        textId="STATUS"
        name="PipelineStatus"
        value={PipelineStatusId}
        inputType={TicketStatusPipelineDropdown}
        pipelineId={PipelineId}
        filterBlock={true}
        Status={!id && TicketsStatusTypes.open}
        onLoad={({ data }) => {
          handleChange(
            {
              PipelineStatus: data[0],
              StatusDetail: null,
              StatusDetailDescription: null
            },
            true
          );
        }}
        onChange={handleChanges}
      />

      {id &&
        PipelieStatusObj &&
        PipelieStatusObj.Status === TicketsStatusTypes.canceled && (
          <FormInput
            name="StatusDetailId"
            textId="CANCELATION_REASON"
            inputType={ticketsCancellationReasonsDropdown}
            value={
              StatusDetailId
                ? StatusDetailId
                : StatusDetail
                ? StatusDetail
                : null
            }
            onChange={handleChanges}
            company={Company}
            className="mb-3"
          />
        )}
      {id &&
        PipelieStatusObj &&
        PipelieStatusObj.Status === TicketsStatusTypes.canceled && (
          <FormInput
            name="StatusDetailDescription"
            textId="CANCELATION_DESCRIPTION"
            inputType={HTMLInput}
            value={StatusDetailDescription ? StatusDetailDescription : ""}
            onChange={handleChanges}
            className="mb-3"
          />
        )}

      <FormInput
        className="mb-3"
        name="Tags"
        textId="CASE_TAGS"
        pipelineId={Pipeline}
        // getKey={(e) => {
        //   const resolvedTag = getEntity(spaceTicketTagsSchema, e);
        //   return `${e}-${resolvedTag?.Pipeline}`;
        // }}
        multiple
        inputType={TicketTagDropdown}
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"ASSIGN_TO"} />
      </div>

      <FormInput
        name="DepartmentTeam"
        // disabled={validatingManager}
        textId="DEPARTMENT_TEAM"
        inputType={DepartmentTeamDropdown}
        className="mb-3"
      />
      <RemoveDefaultActiveButtonProvider>
        <FormInput
          className="mb-3"
          name="ManagerAccount"
          // disabled={validatingManager}
          textId="ASSIGNED_TO"
          inputType={UserDropdown}
          DepartmentTeam={DepartmentTeam}
          onChange={handleManagerChange}
        />
      </RemoveDefaultActiveButtonProvider>

      <FormInput
        name="Followers"
        textId="FOLLOWERS"
        inputType={UserDropdown}
        multiple
        // items={possibleFollowers}
        className="mb-3"
      />

      <hr className="mb-3"></hr>
      <div className="mb-3 text-black fs-16 fw-medium">
        <FormattedMessage id={"DETAILS"} />
      </div>

      <ClipboardProvider>
        <FormInput
          textId="DESCRIPTION"
          name="Description"
          // docType="complex"
          inputType={DocTyper}
          className="mb-3"
        />

        <hr className="mb-3"></hr>
        <div className="mb-3 text-black fs-16 fw-medium">
          <FormattedMessage id={"LOCATION_ADDRESS"} />
        </div>

        <FormInput
          name="AddressSelector"
          className="mb-3"
          inputType={AddressSelector}
        />
        {!Automation && (
          <>
            {Array.isArray(AssociatedFiles) && AssociatedFiles.length > 0 && (
              <>
                <hr className="mb-3"></hr>
                <div className="text-black fs-16 mb-3 fw-medium">
                  <FormattedMessage id="RELATED_ATTACHMENTS" />
                </div>
                <FormInput
                  disableAutomation
                  inputType={FileAssociations}
                  name="Files"
                  className="mb-3 form-Anexos-floater"
                  extendedMode
                  handleAssociationsChanges={handleAssociationsChanges}
                  AssignedAssociations={AssignedAssociations}
                  onChange={() => {}}
                  value={AssociatedFiles}
                />
              </>
            )}
            <hr className="mb-3"></hr>
            <div className="text-black fs-16 fw-medium">
              <FormattedMessage id={"ATTACHMENTS"} />
            </div>

            <FormInput
              name="Files"
              inputType={FileUploader}
              enableSwapper
              className="mb-3 form-Anexos-floater"
            />
          </>
        )}
      </ClipboardProvider>

      <CreatedBy Creator={Creator} CreationDate={CreationDate} />
    </>
  );
};
