import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import Checkbox from "../../../Components/Checkbox/Checkbox";
import { FormChangeContext } from "../../../Components/Forms";
import ModelOne from "../EmailSignatureModels/ModelOne";
import ModelTwo from "../EmailSignatureModels/ModelTwo";
import ModelThree from "../EmailSignatureModels/ModelThree";
import ModelFour from "../EmailSignatureModels/ModelFour";
import ModelFive from "../EmailSignatureModels/ModelFive";
import { ReactComponent as ModalOneExample } from "../EmailSignatureModels/ModelOneExample.svg";
import { ReactComponent as ModalTwoExample } from "../EmailSignatureModels/ModelTwoExample.svg";
import { ReactComponent as ModalThreeExample } from "../EmailSignatureModels/ModelThreeExample.svg";
import { ReactComponent as ModalFourExample } from "../EmailSignatureModels/ModelFourExample.svg";
import { ReactComponent as ModalFiveExample } from "../EmailSignatureModels/ModelFiveExample.svg";
import { ReactComponent as ModalBlankExample } from "../EmailSignatureModels/ModelBlankExample.svg";

const Models = {
  blank: ` `,
  one: `${ModelOne}`,
  two: `${ModelTwo}`,
  three: `${ModelThree}`,
  four: `${ModelFour}`,
  five: `${ModelFive}`
};

const EmailSignatureFormTabModels = () => {
  const [checkedIndex, setCheckedIndex] = useState(undefined);
  const handleChange = useContext(FormChangeContext);

  const handleCheckBoxClick = (e) => {
    const name = e.target.getAttribute("name") || e.target.name;
    setCheckedIndex(name);
    handleChange({
      target: {
        name: "Signature",
        value: Models[name]
      }
    });
  };

  return (
    <div className="overflow-hidden py-3">
      <div className="mb-3 text-black fs-16 fw-bold">
        <FormattedMessage id="CHOOSE_A_TEMPLATE_TO_USE" />
      </div>
      <div style={{ margin: "-0.5rem" }} className="d-inline-flex flex-wrap">
        {/* Blank */}
        <div className="air-signature-modal-container mr-3">
          <div className="mb-2 text-black ">
            <FormattedMessage id="MODEL_BLANK" />
          </div>
          <div
            onClick={handleCheckBoxClick}
            name="blank"
            style={{
              border:
                checkedIndex === "blank"
                  ? "1px solid #1E73F0"
                  : "1px solid #d5dee8"
            }}
            className=" cursor-pointer air-modeal-border-radius flex-1 overflow-hidden w-100  p-3 d-flex justify-content-between"
          >
            <div  className="flex-1 d-flex justify-content-center align-items-center Disable-svg-events">
							<ModalBlankExample className="blank-svg"/>
						</div>
            <div className="checkbox">
              <Checkbox
                checked={checkedIndex === "blank"}
                onChange={handleCheckBoxClick}
                name="blank"
              ></Checkbox>
            </div>
          </div>
        </div>
        {/* One */}
        <div className="air-signature-modal-container mr-3">
          <div className="mb-2 text-black">
            <FormattedMessage id="MODEL" />
            {" 1"}
          </div>
          <div
            name="one"
            style={{
              border:
                checkedIndex === "one"
                  ? "1px solid #1E73F0"
                  : "1px solid #d5dee8"
            }}
            onClick={handleCheckBoxClick}
            className=" flex-1 cursor-pointer air-modeal-border-radius overflow-hidden w-100  p-3 d-flex justify-content-between"
          >
            <div className="flex-1 Disable-svg-events">
              <ModalOneExample />
            </div>
            <div className="checkbox">
              <Checkbox
                checked={checkedIndex === "one"}
                onChange={handleCheckBoxClick}
                name="one"
              ></Checkbox>
            </div>
          </div>
        </div>
        {/* Two */}
        <div className="air-signature-modal-container mr-3">
          <div className="mb-2 text-black">
            <FormattedMessage id="MODEL" />
            {" 2"}
          </div>
          <div
            name="two"
            style={{
              border:
                checkedIndex === "two"
                  ? "1px solid #1E73F0"
                  : "1px solid #d5dee8"
            }}
            onClick={handleCheckBoxClick}
            className="air-modeal-border-radius cursor-pointer flex-1 overflow-hidden w-100  p-3 d-flex justify-content-between"
          >
            <div className="flex-1 Disable-svg-events">
              <ModalTwoExample />
            </div>
            <div className="checkbox">
              <Checkbox
                checked={checkedIndex === "two"}
                onChange={handleCheckBoxClick}
                name="two"
              ></Checkbox>
            </div>
          </div>
        </div>
        {/* Three */}
        <div className="air-signature-modal-container mr-3">
          <div className="mb-2 text-black">
            <FormattedMessage id="MODEL" />
            {" 3"}
          </div>
          <div
            name="three"
            style={{
              border:
                checkedIndex === "three"
                  ? "1px solid #1E73F0"
                  : "1px solid #d5dee8"
            }}
            onClick={handleCheckBoxClick}
            className="air-modeal-border-radius cursor-pointer flex-1 overflow-hidden w-100  p-3 d-flex justify-content-between"
          >
            <div className="flex-1 Disable-svg-events">
              <ModalThreeExample />
            </div>
            <div className="checkbox">
              <Checkbox
                checked={checkedIndex === "three"}
                onChange={handleCheckBoxClick}
                name="three"
              ></Checkbox>
            </div>
          </div>
        </div>
        {/* Four */}
        <div className="air-signature-modal-container mr-3">
          <div className="mb-2 text-black">
            <FormattedMessage id="MODEL" />
            {" 4"}
          </div>
          <div
            name="four"
            style={{
              border:
                checkedIndex === "four"
                  ? "1px solid #1E73F0"
                  : "1px solid #d5dee8"
            }}
            onClick={handleCheckBoxClick}
            className="air-modeal-border-radius cursor-pointer flex-1 overflow-hidden w-100  p-3 d-flex justify-content-between"
          >
            <div className="flex-1 Disable-svg-events">
              <ModalFourExample />
            </div>
            <div className="checkbox">
              <Checkbox
                checked={checkedIndex === "four"}
                onChange={handleCheckBoxClick}
                name="four"
              ></Checkbox>
            </div>
          </div>
        </div>
        {/* Five */}
        <div className="air-signature-modal-container mr-3">
          <div className="mb-2 text-black">
            <FormattedMessage id="MODEL" />
            {" 5"}
          </div>
          <div
            name="five"
            style={{
              border:
                checkedIndex === "five"
                  ? "1px solid #1E73F0"
                  : "1px solid #d5dee8"
            }}
            onClick={handleCheckBoxClick}
            className="air-modeal-border-radius cursor-pointer flex-1 overflow-hidden w-100  p-3 d-flex justify-content-between"
          >
            <div className="flex-1 Disable-svg-events">
              <ModalFiveExample />
            </div>
            <div className="checkbox">
              <Checkbox
                checked={checkedIndex === "five"}
                onChange={handleCheckBoxClick}
                name="five"
              ></Checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSignatureFormTabModels;
