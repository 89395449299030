import React, { Component } from "react";
import classnames from "classnames";
import { isEquivalent } from "../../Helpers/MiscHelper";

export const Tr = React.memo(
	({ columns, value, className,shouldupdate,toggleItem, ...rest }) => {
		return (
			<tr className={className} {...rest}>
				{columns.map((column, colI) => {
					return <Td key={colI} column={column} value={value} />;
				})}
			</tr>
		);
	},
	(prevProps, nextProps) => {
		if (nextProps.shouldupdate)
			return !nextProps.shouldupdate(prevProps, nextProps);
		else {
			return isEquivalent(prevProps, nextProps);
		}
	}
);

export const Td = ({ value, column, ...rest }) => {
	if (column.Cell) return <td className={column.cellClass} {...rest}>{column.Cell(value)}</td>;
	else if (column.field) return <td  {...rest} className={column.cellClass}>{value[column.field]}</td>;
	else return <td {...rest} className={column.cellClass} />;
};

export const Th = ({ column }) => {
	const style = {
		width: column.width ? column.width + "px" : undefined,
		minWidth: column.minWidth ? column.minWidth + "px" : undefined,
		maxWidth: column.maxWidth ? column.maxWidth + "px" : undefined
	};

	if (column.Header) return <th className={column.titleClass} style={style}>{column.Header()}</th>;
	else if (column.title) return <th className={column.titleClass} style={style}>{typeof column.title === 'function' ? column.title() : column.title}</th>;
	else return <th className={column.titleClass} style={style} />;
};

class Table extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	shouldComponentUpdate(nextProps) {
		if (
			nextProps.columns !== this.props.columns ||
			nextProps.data !== this.props.data
		)
			return true;

		return false;
	}

	buildHeader = () => {
		return (
			<tr>
				{this.props.columns.map((column, i) => {
					return <Th key={i} column={column} />;
				})}
			</tr>
		);
	};

	buildBody = () => {
		const { data, columns, shouldUpdateRow, TrComponent } = this.props;
		const Component = TrComponent || null;
		const ComponentTr = Tr

		return data.map((value, i) => {
			if (Component) {
				return (
					<Component
						key={i}
						columns={columns}
						shouldupdate={shouldUpdateRow}
						value={value}
					/>
				)
			} else {
				return (
					<ComponentTr
						key={i}
						columns={columns}
					
						value={value}
					/>
				)
			}
		});
	};

	render() {
		const { buildBody, data, columns, className, ...rest } = this.props;
		const header = this.buildHeader();
		const body = buildBody ? buildBody(data, columns, rest) : this.buildBody();
		return (
			<table
				className={classnames("ar-table", className)}
				onMouseDown={this.props.onMouseDown}
			>
				<thead>{header}</thead>

				<tbody>{body}</tbody>
			</table>
		);
	}
}

export default Table;
