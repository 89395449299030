import React, { useContext } from "react";
import NotificationItem from "./NotificationItem/NotificationItem";
import "../../../Helpers/MomentLangHelper";
import { useIntl } from "react-intl";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
const useCurrentNotificationDate = (date) => {
  const { currentDate } = useContext(FilteredNotificationsContext);
  const intl = useIntl();
  const now = currentDate;

  const isToday = date.isSame(now, "day");
  if (isToday) return intl.formatMessage({ id: "TODAY" });

  return date.format("DD/MM/YYYY");
};

const FilteredNotificationItemTitle = ({ date }) => {
  const resolvedDate = useCurrentNotificationDate(date);
  return (
    <div
      style={{ marginBottom: -1 }}
      className="ar-sidebar-sticky-title border-bottom fs-14 text-black py-3 px-4"
    >
      {resolvedDate}
    </div>
  );
};

const FilteredNotificationItem = ({ value }) => {
  // ////

  const resovledDate = new moment(value.Date);

  return (
    <div className={"ar-filtered-notf-item"}>
      <FilteredNotificationItemTitle date={resovledDate} />
      <div className="ar-notf-item-container">
        {value.notifications.map((v) => {
          return <NotificationItem value={v} key={uuidv4()} />;
        })}
      </div>
    </div>
  );
};

const FilteredNotificationsContext = React.createContext();

const FilteredNotifications = React.memo(({ values }: any) => {
  const currentDate = new Date();

  return (
    <div>
      <FilteredNotificationsContext.Provider value={{ currentDate }}>
        {values.map((value, i) => {
          return <FilteredNotificationItem key={i} value={value} />;
        })}
      </FilteredNotificationsContext.Provider>
    </div>
  );
});

export default FilteredNotifications;
