import React from "react";
import "react-table/react-table.css";
import { Switch, Route, withRouter } from "react-router-dom";
// import {
//   RealTimePageEnum,
//   RealTimePageProvider
// } from "../RealTime/RealTimePages";
import SubscriptionDetails from "./SubscriptionDetails/SubscriptionDetails";
import SubscriptionOverview from "./SubscriptionOverview/SubscriptionOverview";
import {
  // useEnterViewSignalR,
  PageTypeProvider
} from "../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimePageEnum } from "../RealTime/RealTimePages";

const Subscriptions = withRouter(({ match }) => {
  // useEnterViewSignalR(RealTimePageEnum.Subscriptions)

  return (
    <PageTypeProvider pageType={RealTimePageEnum.Subscriptions}>
      <Switch>
        <Route path={`${match.url}/:subscriptionId`}>
          <SubscriptionDetails />
        </Route>
        <Route>
          {/* <RealTimePageProvider pageType={RealTimePageEnum.Subscriptions}> */}
          <SubscriptionOverview />
          {/* </RealTimePageProvider> */}
        </Route>
      </Switch>
    </PageTypeProvider>
  );
});

export default Subscriptions;
