/* eslint-disable no-unused-vars */
import { useEntitiesHub, useConnectionStatus, useReconnect } from "./RealTime";
import React, { useState, useMemo, useContext } from "react";
import { ConnectionStatusEnum } from "./RealTimeHelper";
// import { getCurrentUserAuthToken } from "../../Helpers/AuthHelper";
import { useSpace } from "../../Contexts/SpaceContext";

const UserConnectionStatusContext = React.createContext();

const UserReconnectContext = React.createContext();

const UserEntitiesHubContext = React.createContext();

export const useUserEntitiesHub = () => useContext(UserEntitiesHubContext);

export const useUserConnectionStatus = () =>
  useContext(UserConnectionStatusContext);

export const useUserReconnect = () => useContext(UserReconnectContext);

export const UserRealTimeProvider = ({ children }) => {
  const entitiesHub = useEntitiesHub();
  const space = useSpace();
  const [loading, setLoading] = useState(false);

  const [hadError, setHadError] = useState(false);

  const connectionStatus = useConnectionStatus();

  const userConnectionStatus = useMemo(() => {
    if (hadError) return ConnectionStatusEnum.userIdError;
    if (loading) return ConnectionStatusEnum.loading;

    return connectionStatus;
  }, [connectionStatus, hadError, loading]);

  const [retryCount, setRetryCount] = useState(0);

  // useEffect(() => {
  //   if (!entitiesHub) return;

  //   let hasChanged = false;
  //   setLoading(true);
  //   setHadError(false);
  //   const tkn = getCurrentUserAuthToken();

  //   // sendSignalRMessage(
  //   //   entitiesHub,
  //   //   "SetUserId",
  //   //   tkn,
  //   //   space?.Id,
  //   //   () => {
  //   //     if (!hasChanged) {
  //   //       setLoading(false);
  //   //     }
  //   //   },
  //   //   () => {
  //   //     setLoading(false);
  //   //     setHadError(true);
  //   //   }
  //   // );

  //   // try {
  //   //   entitiesHub.invoke("SetUserId", tkn, space?.Id).then(() => {
  //   //     if (!hasChanged) {
  //   //       setLoading(false);
  //   //     }
  //   //   });
  //   // } catch (error) {
  //   //   if (!hasChanged) {
  //   //     setLoading(false);
  //   //     setHadError(true);
  //   //   }
  //   // }

  //   // entitiesHub
  //   //   .invoke("SetUserId", tkn, space?.Id)
  //   //   .done(() => {
  //   //     if (!hasChanged) {
  //   //       setLoading(false);
  //   //     }
  //   //   })
  //   //   .fail(() => {
  //   //     if (!hasChanged) {
  //   //       setLoading(false);
  //   //       setHadError(true);
  //   //     }
  //   //   });

  //   return () => {
  //     hasChanged = true;
  //   };
  // }, [entitiesHub, retryCount, space]);

  const reconnect = useReconnect();

  const userReconnect = useMemo(() => {
    if (reconnect) return reconnect;

    if (hadError) {
      return () => {
        setRetryCount((c) => c + 1);
      };
    }
  }, [hadError, reconnect]);

  const UserEntitiesHub = !loading && !hadError ? entitiesHub : undefined;

  return (
    <UserConnectionStatusContext.Provider value={userConnectionStatus}>
      <UserReconnectContext.Provider value={userReconnect}>
        <UserEntitiesHubContext.Provider value={UserEntitiesHub}>
          {children}
        </UserEntitiesHubContext.Provider>
      </UserReconnectContext.Provider>
    </UserConnectionStatusContext.Provider>
  );
};
