// <!--

// var css = 'h1 { background: red; }',
// head = document.head || document.getElementsByTagName('head')[0],
// style = document.createElement('style');

// head.appendChild(style);

// style.type = 'text/css';
// if (style.styleSheet){
// // This is required for IE8 and below.
// style.styleSheet.cssText = css;
// } else {
// style.appendChild(document.createTextNode(css));
// }

// @media print {
// 	div {
// 		break-inside: avoid;
// 	}
// }

// -->

export const GetResolvedDocHtml = (parsedHTML, options) => `
<link
href="https://fonts.googleapis.com/css?family=Roboto+Mono:100,300,400,500,700,900"
rel="stylesheet"
/>
<link
href="https://fonts.googleapis.com/css?family=Roboto+Serif:100,300,400,500,700,900"
rel="stylesheet"
/>
<link
href="https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,500,700,900"
rel="stylesheet"
/>
<style>

body {
	--BasedTextSize: ${options.newFontSize};
	--FontFamily: ${options.fontFamily};
	--FontLineHeight: ${options.newFontLineHeight};
	--ContainerPadding: ${options.newPadding};
	line-height: 1.5;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
}

.BaseSettings {
    color: #191e24;
		width: 210mm;
		margin: 0 auto;
		box-sizing: border-box;
		padding: var(--ContainerPadding);
		font-size: var(--BasedTextSize);
		font-family: var(--FontFamily);
    --color-background: #f2f4f8;
    --color-text-secondary: #7b7e89;
    --color-border: #cbd6e2;
  }

.BaseSettings .Redactor{
	width: auto;
}

.BaseSettings * {
	max-width: 100% !important;
}

*,
*::before,
*::after {
  box-sizing: border-box; 
}
@media print {
	body {
		--BasedTextSize: ${options.newFontSize};
		--FontFamily: ${options.fontFamily};
		--FontLineHeight: ${options.newFontLineHeight};
		--ContainerPadding: ${options.newPadding};
		line-height: 1.5;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
	}
	
	.BaseSettings {
			color: #191e24;
			width: 210mm;
			margin: 0 auto;
			box-sizing: border-box;
			padding: var(--ContainerPadding);
			font-size: var(--BasedTextSize);
			font-family: var(--FontFamily);
			--color-background: #f2f4f8;
			--color-text-secondary: #7b7e89;
			--color-border: #cbd6e2;
		}
	
	.BaseSettings .Redactor{
		width: auto;
	}
	
	.BaseSettings * {
		max-width: 100% !important;
	}
	
	*,
	*::before,
	*::after {
		box-sizing: border-box; 
	}

	.pageBreaker {
		page-break-after: always;
	}
	.tc-row{
					page-break-inside: avoid;
					page-break-after: auto;
			}

			.BaseSettings blockquote{
				margin: 0
			}
		
			.text-align-left{
				text-align: left !important;
			}
			.text-align-right{
				text-align: right !important;
			}
			.text-align-center{
				text-align: center !important;
			}
		
			.cdx-block {
				padding: 0.4em 0;
			}
			.img-stretched img{
				width: 100% !important;
				max-width: none !important;
			}
			h1 {
				font-size: calc(var(--BasedTextSize) * 2);
			}
		
			h2 {
				font-size: calc(var(--BasedTextSize) * 1.5);
			}
		
			h3 {
				font-size: calc(var(--BasedTextSize) * 1.25);
			}
		
			h4 {
				font-size: calc(var(--BasedTextSize) * 1.125);
			}
		
			p {
				line-height: var(--FontLineHeight);
			}
		
			.BaseSettings img{
				margin-bottom: 15px
			}
		
			.CheckListItem {
				display: flex;
				box-sizing: content-box;
			}
			.CheckListItem .Checkbox {
				display: inline-block;
				flex-shrink: 0;
				position: relative;
				width: 20px;
				height: 20px;
				margin: 5px;
				margin-left: 0;
				margin-right: 7px;
				border-radius: 50%;
				border: 1px solid #cbd6e2;
				background: #fff;
				cursor: pointer;
				user-select: none;
			}
		
			.CheckListItem.CheckList--checked .Checkbox {
				background: #36ab80;
				border-color: #36ab80;
			}
		
			.CheckListItem.CheckList--checked .Checkbox::after {
				opacity: 1;
			}
		
			.Checklist-text {
				outline: none;
				flex-grow: 1;
				padding: 5px 0;
			}
		
			.Checkbox::after {
				position: absolute;
				top: 6px;
				left: 5px;
				width: 9px;
				height: 4px;
				border: 2px solid #fff;
				border-top: none;
				border-right: none;
				background: transparent;
				content: "";
				opacity: 0;
				transform: rotate(-45deg);
			}
		
			.cdx-quote__text {
				min-height: 48px;
				font-style: italic;
				color: #4a5b6d;
				background-color: #f2f4f8;
				border: none;
				box-shadow: none;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				font-size: calc(var(--BasedTextSize) * 0.85);
				margin-bottom: 0;
				padding: 10px 12px;
			}
			.cdx-quote__caption {
				border-top-left-radius: 0;
				text-align: right;
				padding: 10px 12px;
				font-weight: bold;
				border-top-right-radius: 0;
				color: #4a5b6d;
				background-color: #f2f4f8;
				border: none;
				font-size: calc(var(--BasedTextSize) * 0.85);
				outline: none;
				box-shadow: none;
			}
			.cdx-quote__caption::before {
				width: fit-content !important;
			}
		
			.ce-code__textarea {
				min-height: 200px;
				font-family: Menlo, Monaco, Consolas, Courier New, monospace;
				line-height: 1.6em;
				font-size: 12px;
				box-shadow: none;
				white-space: pre;
				word-wrap: normal;
				resize: vertical;
				background: #f2f4f8;
				border: none;
				color: #4a5b6d;
				border-radius: 3px;
				padding: 10px 12px;
				outline: none;
			}
		
			.cdx-list {
				margin: 0;
				padding-left: 40px;
				outline: none;
			}
		
			.cdx-list--unordered {
				list-style: disc;
			}
		
			.cdx-list--ordered {
				list-style: decimal;
			}
		
			.cdx-list__item {
				padding: 5.5px 0 5.5px 3px;
				line-height: 1.6em;
			}
		
			.delimiter {
				line-height: 1.6em;
				width: 100%;
				text-align: center;
				padding: 0;
				margin: 0.4em 0;
				border-bottom: 2px solid #96adc5;
			}
		
			.tc-table {
				position: relative;
				display: flex;
				flex-direction: column;
				margin-bottom: 32px;
				margin-right: 8px;
				border-top: 1px solid var(--color-border);
				border-left: 1px solid var(--color-border);
			}
		
			.inline-code {
				background: rgba(250, 239, 240, 0.78);
				color: #b44437;
				padding: 3px 4px;
				border-radius: 5px;
				margin: 0 1px;
				font-size: var(--BasedTextSize);
				letter-spacing: 0.3px;
		}
			
			.tc-row {
				display: flex;
				position: relative;
			}
		
			code{
				font-size: var(--BasedTextSize);
				font-family: var(--FontFamily) !important;
			}
		
			.tc-cell {
				border-right: 1px solid var(--color-border);
				padding: 6px 13px 6px 12px;
				overflow:hidden;
				font-size: var(--BasedTextSize);
				font-family: var(--FontFamily);
				outline: none;
				word-wrap: break-word;
				word-break: normal;
				box-sizing: border-box;
				flex: 1 1;
				line-break: normal;
			}
		
			.tc-cell:last-child{
				flex: 1 1 !important;
			}
			
			.tc-cell:last-child::after{
				content: none;
			}
		
			img{
				max-width: 100%;
				vertical-align: bottom;
			}

}
			.tc-row:first-of-type{
				 border-top: 1px solid var(--color-border); 
			}
			.tc-row:last-of-type, .tc-row:{
				border-bottom: 1px solid var(--color-border); 
			}
			.tc-row{
				border-top: 1px solid var(--color-border); 
					border-bottom: 1px solid var(--color-border); 
					border-left: 1px solid var(--color-border);
				}



	.BaseSettings blockquote{
		margin: 0
	}

	.text-align-left{
		text-align: left !important;
	}
	.text-align-right{
		text-align: right !important;
	}
	.text-align-center{
		text-align: center !important;
	}

  .cdx-block {
    padding: 0.4em 0;
  }
	.img-stretched img{
		width: 100% !important;
		max-width: none !important;
	}
  h1 {
    font-size: calc(var(--BasedTextSize) * 2);
  }

  h2 {
    font-size: calc(var(--BasedTextSize) * 1.5);
  }

  h3 {
    font-size: calc(var(--BasedTextSize) * 1.25);
  }

  h4 {
    font-size: calc(var(--BasedTextSize) * 1.125);
  }

  p {
    line-height: var(--FontLineHeight);
  }

	.BaseSettings img{
		margin-bottom: 15px
	}

  .CheckListItem {
    display: flex;
    box-sizing: content-box;
  }
  .CheckListItem .Checkbox {
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    margin: 5px;
    margin-left: 0;
    margin-right: 7px;
    border-radius: 50%;
    border: 1px solid #cbd6e2;
    background: #fff;
    cursor: pointer;
    user-select: none;
  }

  .CheckListItem.CheckList--checked .Checkbox {
    background: #36ab80;
    border-color: #36ab80;
  }

  .CheckListItem.CheckList--checked .Checkbox::after {
    opacity: 1;
  }

  .Checklist-text {
    outline: none;
    flex-grow: 1;
    padding: 5px 0;
  }

  .Checkbox::after {
    position: absolute;
    top: 6px;
    left: 5px;
    width: 9px;
    height: 4px;
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    background: transparent;
    content: "";
    opacity: 0;
    transform: rotate(-45deg);
  }

  .cdx-quote__text {
    min-height: 48px;
    font-style: italic;
    color: #4a5b6d;
    background-color: #f2f4f8;
    border: none;
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: calc(var(--BasedTextSize) * 0.85);
    margin-bottom: 0;
    padding: 10px 12px;
  }
  .cdx-quote__caption {
    border-top-left-radius: 0;
    text-align: right;
    padding: 10px 12px;
    font-weight: bold;
    border-top-right-radius: 0;
    color: #4a5b6d;
    background-color: #f2f4f8;
    border: none;
    font-size: calc(var(--BasedTextSize) * 0.85);
    outline: none;
    box-shadow: none;
  }
  .cdx-quote__caption::before {
    width: fit-content !important;
  }

  .ce-code__textarea {
    min-height: 200px;
    font-family: Menlo, Monaco, Consolas, Courier New, monospace;
    line-height: 1.6em;
    font-size: 12px;
    box-shadow: none;
    white-space: pre;
    word-wrap: normal;
    resize: vertical;
    background: #f2f4f8;
    border: none;
    color: #4a5b6d;
    border-radius: 3px;
    padding: 10px 12px;
    outline: none;
  }

  .cdx-list {
    margin: 0;
    padding-left: 40px;
    outline: none;
  }

  .cdx-list--unordered {
    list-style: disc;
  }

  .cdx-list--ordered {
    list-style: decimal;
  }

  .cdx-list__item {
    padding: 5.5px 0 5.5px 3px;
    line-height: 1.6em;
  }

  .delimiter {
    line-height: 1.6em;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0.4em 0;
    border-bottom: 2px solid #96adc5;
  }

  .tc-table {
    position: relative;
    display: flex;
		flex-direction: column;
		margin-bottom: 32px;
		margin-right: 8px;
    border-top: 1px solid var(--color-border);
    border-left: 1px solid var(--color-border);
  }

	.inline-code {
    background: rgba(250, 239, 240, 0.78);
    color: #b44437;
    padding: 3px 4px;
    border-radius: 5px;
    margin: 0 1px;
		font-size: var(--BasedTextSize);
    letter-spacing: 0.3px;
}
	
  .tc-row {
    display: flex;
    position: relative;
  }

	code{
		font-size: var(--BasedTextSize);
		font-family: var(--FontFamily) !important;
	}

  .tc-cell {
		border-right: 1px solid var(--color-border);
    padding: 6px 13px 6px 12px;
		overflow:hidden;
    font-size: var(--BasedTextSize);
    font-family: var(--FontFamily);
    outline: none;
    word-wrap: break-word;
    word-break: normal;
    box-sizing: border-box;
		flex: 1 1;
    line-break: normal;
  }

	.tc-cell:last-child{
		flex: 1 1 !important;
	}
	
	.tc-cell:last-child::after{
		content: none;
	}

	img{
		max-width: 100%;
    vertical-align: bottom;
	}

	
</style>



<div class="BaseSettings">
	<div class="Redactor">
		${parsedHTML}
	</div>
</div>`;
