import React, {
  useContext,
  useCallback,
  useState,
  useMemo,
  useRef
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as EmptyListIcon } from "../../Assets/Svg/empty_inbox.svg";
import { ReactComponent as EmptySearchIcon } from "../../Assets/Svg/global_search_no_results.svg";
import {
  faBell,
  faCopy,
  faEnvelope,
  faEnvelopeOpen,
  faInboxIn,
  faPen,
  faRedo,
  faStar,
  faChevronDoubleRight
} from "@fortawesome/pro-light-svg-icons";
import { faBell as faBellSolid } from "@fortawesome/pro-solid-svg-icons";
import classnames from "classnames";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import {
  useEmail,
  useEmailConversation,
  useContact,
  useNote,
  useEntityValue
} from "../../Hooks/EntityHooks";
import { ConnectedAccountProfile } from "../../Components/Accounts/AccountProfile";
import { Link, useRouteMatch } from "react-router-dom";
import {
  projectSettings,
  taskSettings,
  clientSettings,
  dealSettings,
  contractSettings,
  subscriptionSettings,
  callSettings,
  ticketSettings,
  timeChargeSettings
} from "./ReceptionSettings";
import {
  useServerAwareState,
  ServerAwareQueryInput
} from "../../Components/CGrid/ServerGrid";
import { useMultipleEntityValueSelector } from "../../Hooks/AdvancedEntityHooks";
import {
  spaceContactSchema,
  spaceEmailConversationSchema,
  spaceEmailSchema,
  spaceNoteSchema
} from "../../config/schema";
import {
  MinimizeContext,
  ResetSelectContext,
  SelectedAllMsgsContext,
  SelectedMsgContext,
  SetResponseContext
} from "./ReceptionComp";
import { FormattedMessage, useIntl } from "react-intl";
import { EmailSendingStatus } from "./EmailChatComponent";
import Checkbox from "../../Components/Checkbox/Checkbox";
import UnreadOptionsButton, {
  SelectedArrMsgContext,
  UpdateAllItems
} from "../../Components/UnOrReadOptions/UnreadOptions";
import { ConversationTypeEnum, InboxTypeEnum } from "./ReceptionHelpers";
import EntityIconOverview from "../../Components/EntityIconOverview/EntityIconOverview";
import { ActionLoadableButton } from "../../Components/ExtraActionsButton/ExtraActionButton";
import Modal from "../../Components/Modal/Modal";
import { MessageListComponentChooser } from "./MessageListComponentChooser/MessageListComponentChooser";
import {
  MessageChooserListnerDict,
  useResolvedMessage
} from "./MessageListComponentChooser/MessageListComponentChooserHelper";
import moment from "moment";
import { getEntity, useSpacePost } from "../../Helpers/IOClient";
import TextEllipsis from "../../Components/TextEllipsis/TextEllipsis";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import ContextMenu, {
  ContextLoadableItem
} from "../../Components/ContextMenu/ContextMenu";
import { useUnreadEntitiesFetcher } from "../../Components/UnreadEntities/UnreadEntities";
import { useUrlPost } from "../../Components/EntityFollow/EntityFollowIcon";
import { ReceptionDuplicateConvForm } from "./ReceptionNewConvForm/ReceptionNewConvForm";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import { useToast } from "../../Components/Toast/ToastProvider";
import { handleError } from "../../Helpers/MiscHelper";
import {
  EmailClosedStatusDot,
  EmailDisabledClosedStatusDot,
  EmailDisabledOpenStatusDot,
  EmailOpenStatusDot
} from "../../Components/StatusHelper/StatusHelper";
import SaveFavoriteButton from "../AppBar/SaveFavoriteButton";
import useAppBarAddViewFavorite from "../AppBar/AppBarGridAddFavorite";
import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";

export const InboxMessageListComponents = [
  {
    titleId: "TYPE",
    field: "status",
    key: "status",
    default: true
  },
  {
    titleId: "DESCRIPTION",
    field: "description",
    key: "description",
    default: true
  },
  {
    titleId: "USER",
    field: "avatar",
    key: "avatar",
    default: true
  },
  {
    titleId: "MANAGER",
    field: "manager",
    key: "manager",
    default: true
  },
  {
    titleId: "SENT_STATUS",
    field: "sentStatus",
    key: "sentStatus",
    default: true
  },
  {
    titleId: "DATE",
    field: "date",
    key: "date",
    default: true
  },
  // {
  //   titleId: "ASSOCIATIONS",
  //   field: "associations",
  //   key: "associations"
  // },
  {
    titleId: "ENTITY_ICONS",
    field: "entityIcons",
    key: "entityIcons"
  }
];

const ReceptionCompMsgListHeader = ({
  Messages = [],
  Loading,
  detailsView,
  isHidden,
  setIsHidden
}) => {
  // const placeholder = intl.formatMessage({ id: "SEARCH_INBOX_PLACEHOLDER" });

  const reset = useContext(ResetSelectContext);
  // const SelectedArr = useContact(SelectedArrMsgContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const handleCheckChanges = useContext(SelectedAllMsgsContext);
  const SelectedMEssagesArr = useContext(SelectedArrMsgContext);
  const resetOptions = () => {
    let gridColumnLayout2 = JSON.parse(
      window.localStorage.getItem("ar-grid-inbox-msg-layout")
    );

    if (gridColumnLayout2) {
      delete gridColumnLayout2["air-inbox"];
      localStorage.setItem(
        "ar-grid-inbox-msg-layout",
        JSON.stringify(gridColumnLayout2)
      );

      if (MessageChooserListnerDict["air-inbox"]) {
        for (const listner of MessageChooserListnerDict["air-inbox"]) {
          listner();
        }
      }
    }
    return;
  };

  const selectAllCheckedStatus = useMemo(() => {
    if (Messages.length !== SelectedMEssagesArr.length) {
      return false;
    } else return true;
  }, [Messages.length, SelectedMEssagesArr.length]);

  const SelectedAreAllEmails = useMemo(() => {
    let result = true;
    if (!SelectedMEssagesArr?.length) return false;
    for (const e of SelectedMEssagesArr) {
      if (e.type !== 1) {
        result = false;
        break;
      }
    }
    return result;
  }, [SelectedMEssagesArr]);

  const SelectedEmailsPostBody = useMemo(() => {
    if (SelectedAreAllEmails) {
      return SelectedMEssagesArr.map((e) => {
        return e.id;
      });
    } else return [];
  }, [SelectedAreAllEmails, SelectedMEssagesArr]);

  const createToast = useToast();

  const [postCloseConversations, { loading: closingConvLoading }] =
    useSpacePost("EmailConversation/1/Status", [spaceEmailConversationSchema], {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          description: intl.formatMessage({ id: "STATUS_CHANGED_SUCCESS" })
        });
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    });
  const [postOpenConversations, { loading: openConvLoading }] = useSpacePost(
    "EmailConversation/0/Status",
    [spaceEmailConversationSchema],
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          description: intl.formatMessage({ id: "STATUS_CHANGED_SUCCESS" })
        });
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  const handleToggleHidden = () => {
    setIsHidden(!isHidden);
  };

  const addFavorite = useAppBarAddViewFavorite(
    spaceEmailConversationSchema.name
  );
  const intl = useIntl();

  return (
    <div className="air-reception-msg-list-header d-flex align-items-center justify-content-between">
      <div
        style={{
          width: isHidden ? 0 : "100%",
          transition: "width 0.3s ease-in-out"
        }}
        className={classnames(
          "align-items-center justify-content-between d-flex of-hidden",
          { "mr-3": !isHidden, "visibility-none": isHidden }
        )}
      >
        <Modal
          // containerClassName=""
          isOpen={modalIsOpen}
          onClose={closeModal}
        >
          <MessageListComponentChooser
            grid={"air-inbox"}
            Name={"air-inbox"}
            Columns={InboxMessageListComponents}
            onClose={closeModal}
            setIsOpen={setModalIsOpen}
            isOpen={modalIsOpen}
          />
        </Modal>
        <div className="mr-2 air-reception-msg-list-checkbox">
          {Messages.length > 0 && (
            <Checkbox
              checked={selectAllCheckedStatus}
              onChange={handleCheckChanges}
            />
          )}
        </div>
        <ServerAwareQueryInput
          placeholder={intl.formatMessage({ id: "SEARCH" })}
          className="flex-1"
          onChange={reset}
        />
        {!detailsView && <SaveFavoriteButton minimal className="ml-1" />}
        <div className="d-flex align-items-center ml-1">
          <UnreadOptionsButton DisableOnlySetButtons>
            <ActionLoadableButton
              isLoading={openConvLoading}
              disabled={!SelectedAreAllEmails}
              className="p-1 AlignLeftLoadableButton"
              vType="link"
              onClick={() => {
                postOpenConversations(JSON.stringify(SelectedEmailsPostBody));
              }}
            >
              {SelectedAreAllEmails ? (
                <EmailOpenStatusDot />
              ) : (
                <EmailDisabledOpenStatusDot />
              )}
              <span className="ml-1">
                <FormattedMessage id={"MARK_CONVERSARION_AS_OPEN"} />
              </span>
            </ActionLoadableButton>
            <ActionLoadableButton
              isLoading={closingConvLoading}
              disabled={!SelectedAreAllEmails}
              className="p-1 AlignLeftLoadableButton"
              vType="link"
              onClick={() => {
                postCloseConversations(JSON.stringify(SelectedEmailsPostBody));
              }}
            >
              {SelectedAreAllEmails ? (
                <EmailClosedStatusDot />
              ) : (
                <EmailDisabledClosedStatusDot />
              )}
              <span className="ml-1">
                <FormattedMessage id={"MARK_CONVERSARION_AS_CLOSED"} />
              </span>
            </ActionLoadableButton>
            <ActionLoadableButton
              className="p-1 AlignLeftLoadableButton"
              vType="link"
              onClick={() => {
                addFavorite();
              }}
            >
              <FontAwesomeIcon icon={faStar} />
              <span className="ml-1">
                <FormattedMessage id={"ADD_FAVORITE"} />
              </span>
            </ActionLoadableButton>
            <div className="label-title">
              <FormattedMessage id="MODULES" />
            </div>
            <ActionLoadableButton
              className="p-1 AlignLeftLoadableButton"
              vType="link"
              onClick={openModal}
            >
              <FontAwesomeIcon icon={faPen} />
              <span className="ml-1">
                <FormattedMessage id={"EDIT_MODULES"} />
              </span>
            </ActionLoadableButton>
            <ActionLoadableButton
              className="p-1 AlignLeftLoadableButton"
              vType="link"
              onClick={resetOptions}
            >
              <FontAwesomeIcon icon={faRedo} />
              <span className="ml-1">
                <FormattedMessage id={"RESTORE_MODULES"} />
              </span>
            </ActionLoadableButton>
          </UnreadOptionsButton>
        </div>
        {/* <div className="text-primary d-flex align-items-center cursor-pointer">
        <FormattedMessage id="ACTIONS" />
        <FontAwesomeIcon className="ml-2" icon={faSortDown} />
      </div>
      <div className="d-flex align-items-center">
        <div className="text-primary mr-3">
          <FontAwesomeIcon icon={faSearch} />
        </div>
      </div> */}
      </div>
      <div
        style={{ width: 40, height: 40 }}
        className={classnames(
          "cursor-pointer d-flex flex-1 justify-content-center align-items-center"
        )}
        onClick={handleToggleHidden}
      >
        <FontAwesomeIcon
          className="text-primary"
          size="lg"
          rotation={!isHidden ? 180 : undefined}
          icon={faChevronDoubleRight}
        />
      </div>
    </div>
  );
};

export const ReceptionItemTypeEnum = {
  Email: 1,
  Chat: 2,
  EntityNotes: 3
};

const EmailConversationStatus = {
  Open: 0,
  Closed: 1
};

export const ObjNameDictionary = {
  1: "EmailConversationIds",
  4: "ClientIds",
  5: "DealIds",
  6: "ContractIds",
  7: "SubscriptionIds",
  8: "CallIds",
  9: "ProjectIds",
  10: "TicketIds",
  11: "TaskIds",
  12: "TimeChargeIds"
};

const FormatPost = (SelectedArr) => {
  let Results = {
    EmailConversationIds: [],
    ClientIds: [],
    DealIds: [],
    ContractIds: [],
    SubscriptionIds: [],
    CallIds: [],
    ProjectIds: [],
    TicketIds: [],
    TaskIds: [],
    TimeChargeIds: []
  };

  for (const e of SelectedArr) {
    const Name = ObjNameDictionary[e.type];
    Results[Name] = [...Results[Name], e.id];
  }
  return Results;
};

const ReceptionMsgItemContainer = ({
  className,
  children,
  conversationType,
  Data,
  schema,
  IsRead,
  IsFollowing,
  IsService
}) => {
  const setResponse = useContext(SetResponseContext);
  const [openSidebar] = useSidebar();
  const divRef = useRef();
  const Refetch = useUnreadEntitiesFetcher();

  const [readPost, { loading: loadingRead }] = useSpacePost(
    `EntityFollow/Read`,
    null,
    {
      onSuccess: ({ data }) => {
        UpdateAllItems(
          [{ type: conversationType, id: Data }],
          true,
          Refetch,
          IsService
        );
      },
      onError: ({ error }) => {
        console.log(error);
      }
    }
  );

  const [followHandle, { loading: loadingFollows }] = useUrlPost(
    schema,
    Data,
    IsFollowing
  );

  const [unreadPost, { loading: loadingUnread }] = useSpacePost(
    `EntityFollow/Unread`,
    null,
    {
      onSuccess: ({ data }) => {
        UpdateAllItems(
          [{ type: conversationType, id: Data }],
          false,
          Refetch,
          IsService
        );
      },
      onError: ({ error }) => {
        console.log(error);
      }
    }
  );

  const handleClick = () => {
    openSidebar(
      <ReceptionDuplicateConvForm
        conversationId={Data}
        setResponse={setResponse}
      />
    );
  };
  const currentMatch = useRouteMatch();
  let match = useRouteMatch(`${currentMatch.url}/${conversationType}/${Data}`);
  return (
    <div
      ref={divRef}
      className={classnames(
        { Unread: IsFollowing && IsRead === false, active: Boolean(match) },

        "air-reception-msg-item cursor-pointer border-bottom position-relative"
      )}
    >
      <ReceptionMsgItemChildren children={children} />
      <ContextMenu targetRef={divRef}>
        {!IsRead ? (
          <ContextLoadableItem
            isLoading={loadingRead}
            onChange={() => {
              const ResolvedData = FormatPost([
                { type: conversationType, id: Data }
              ]);
              readPost(ResolvedData);
            }}
            icon={faEnvelopeOpen}
            text="MARK_SINGLE_AS_READ"
          />
        ) : (
          <ContextLoadableItem
            isLoading={loadingUnread}
            onChange={() => {
              const ResolvedData = FormatPost([
                { type: conversationType, id: Data }
              ]);
              unreadPost(ResolvedData);
            }}
            icon={faEnvelope}
            text="MARK_SINGLE_AS_UNREAD"
          />
        )}
        {IsFollowing ? (
          <ContextLoadableItem
            isLoading={loadingFollows}
            onChange={followHandle}
            icon={faBellSolid}
            text="UNFOLLOW"
          />
        ) : (
          <ContextLoadableItem
            isLoading={loadingFollows}
            onChange={followHandle}
            icon={faBell}
            text="FOLLOW"
          />
        )}
        {conversationType === ConversationTypeEnum.Email && (
          <ContextLoadableItem
            icon={faCopy}
            onChange={handleClick}
            text="DUPLICATE"
          />
        )}
      </ContextMenu>
    </div>
  );
};

const ReceptionMsgItemChildren = React.memo(({ children }) => {
  return children;
});

export const MessageItemProfileStatus = ({ Status, style, size }) => {
  return (
    <div
      style={style}
      className={classnames(
        "air-reception-msg-item-email-icon d-flex align-items-center justify-content-center",
        {
          open: Status === EmailConversationStatus.Open,
          closed: Status === EmailConversationStatus.Closed
        }
      )}
    >
      <FontAwesomeIcon size={size} icon={faEnvelope} />
    </div>
  );
};

export const MessageitemProfileDot = ({ Status }) => {
  return (
    <div
      className={classnames(
        {
          "status-color-success": Status === EmailConversationStatus.Open,
          "status-color-danger": Status === EmailConversationStatus.Closed
        },
        "link-color h-100 d-flex align-items-center"
      )}
    >
      <div className="position-relative status-dot"></div>
    </div>
  );
  // return (
  //   <div
  //     className={classnames(
  //       "air-reception-msg-item-email-icon d-flex align-items-center justify-content-center",
  //       {
  //         open: Status === EmailConversationStatus.Open,
  //         closed: Status === EmailConversationStatus.Closed
  //       }
  //     )}
  //   >
  //     <div className="position-relative status-dot"></div>
  //   </div>
  // );
};

const selector = (v) => v.Name;

const MessageItemEmail = ({ Data, onMessageClick, OpenedId, extraClient }) => {
  const Conversation = useEmailConversation(Data);
  const { LastEmail, Status, IsMessageRead, IsFollowing, Manager, Client } =
    Conversation;

  const EmailContent = useEmail(LastEmail);
  const {
    FromContact,
    ToContacts,
    Account,
    SentDate,
    Status: EmailStatus
  } = EmailContent;

  const Contact = useContact(FromContact);

  //   const handleClick = () => {
  //     onMessageClick(EmailConversation);
  //   };

  const { paramsUrl } = useServerAwareState();

  const toContacts = useMultipleEntityValueSelector(
    spaceContactSchema,
    ToContacts || [],
    selector
  );

  // const handleSelection = useContext(SelectedMsgContext);

  // const handleCheckboxChange = useCallback(
  //   (e) => {
  //     handleSelection({ type: ReceptionItemTypeEnum.Email, id: Data });
  //   },
  //   [Data, handleSelection]
  // );

  const match = useRouteMatch();
  const LocalStorageModules = useResolvedMessage(
    InboxMessageListComponents,
    "air-inbox"
  );

  let ResolvedModules = {};
  for (const e of LocalStorageModules) {
    const { field } = e;
    ResolvedModules[field] = true;
  }

  // const content = useMemo(() => {
  //   return (
  //     <div className="d-flex w-100">
  //       <div className="mx-2 air-reception-msg-list-checkbox">
  //         <Checkbox onChange={handleCheckboxChange} />
  //       </div>
  //       <Link
  //         className="d-flex w-100 of-hidden disableTextDecoration p-2"
  //         to={`/s/${space.Id}/inbox/${ReceptionItemTypeEnum.Email}/${Data}`}
  //       >
  //         <div className="mr-2">
  //           <MessageItemProfileStatus Status={Status} />
  //         </div>
  //         <div className="d-flex flex-1 flex-column justify-content-between of-hidden">
  //           <div className="d-flex justify-content-between text-black">
  //             <span className="fs-14 fw-medium text-truncate">
  //               {Contact ? Contact.Name : toContacts.join(", ")}
  //             </span>
  //             <div className="flex-0-0-auto fs-12 ml-3">
  //               {ResolvedModules.DATE && <RelativeTime date={SentDate} />}
  //             </div>
  //           </div>
  //           <div className="d-flex align-items-center">
  //             <div className="d-flex align-items-center w-100">
  //               {Account ? (
  //                 <>
  //                   {ResolvedModules.SENT_STATUS && (
  //                     <EmailSendingStatus
  //                       className="mr-2"
  //                       Status={EmailStatus}
  //                     />
  //                   )}
  //                   {ResolvedModules.USER && (
  //                     <ConnectedAccountProfile
  //                       size="xsm"
  //                       justImage
  //                       account={Account}
  //                     />
  //                   )}
  //                 </>
  //               ) : (
  //                 ResolvedModules.SENT_STATUS && (
  //                   <FontAwesomeIcon size="sm" icon={faInboxIn} />
  //                 )
  //               )}
  //               <div className="fs-12 ml-2 text-grey-light text-truncate">
  //                 {ResolvedModules.DESCRIPTION && (
  //                   <div className="text-truncate">{EmailContent.Subject}</div>
  //                 )}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </Link>
  //     </div>
  //   );
  // }, [
  //   Account,
  //   Contact,
  //   Data,
  //   EmailContent.Subject,
  //   EmailStatus,
  //   ResolvedModules.DATE,
  //   ResolvedModules.DESCRIPTION,
  //   ResolvedModules.SENT_STATUS,
  //   ResolvedModules.USER,
  //   SentDate,
  //   Status,
  //   handleCheckboxChange,
  //   space.Id,
  //   toContacts
  // ]);

  return (
    <ReceptionMsgItemContainer
      conversationType={ReceptionItemTypeEnum.Email}
      IsRead={IsMessageRead}
      IsFollowing={IsFollowing}
      Data={Data}
      schema={spaceEmailConversationSchema}
      // children={content}
    >
      <div className="d-flex w-100">
        <div className="mx-2 air-reception-msg-list-checkbox">
          <CheckboxMessageComponent type={InboxTypeEnum.Email} id={Data} />
        </div>
        <Link
          className="d-flex w-100 of-hidden disableTextDecoration p-2"
          to={`${match.url}/${ReceptionItemTypeEnum.Email}/${Data}${paramsUrl}`}
        >
          <div className="mr-2 d-flex flex-column">
            {ResolvedModules.status && (
              <MessageItemProfileStatus Status={Status} />
            )}
            {!ResolvedModules.status && (
              <MessageitemProfileDot Status={Status} />
            )}
            {ResolvedModules.sentStatus && Account && (
              <EmailSendingStatus
                className="my-auto text-align-center"
                Status={EmailStatus}
              />
            )}
            {ResolvedModules.sentStatus && !Account && (
              <FontAwesomeIcon
                size="sm"
                className="my-auto text-align-center"
                icon={faInboxIn}
              />
            )}
          </div>
          <div className="d-flex flex-1 flex-column justify-content-between of-hidden">
            <div className="d-flex justify-content-between text-black">
              <span className="fs-14 fw-medium text-truncate">
                {Contact ? Contact.Name : toContacts.join(", ")}
              </span>
              <div className="flex-0-0-auto fs-12 ml-3">
                {ResolvedModules.date &&
                  moment(SentDate).format("DD/MM/YYYY, HH:mm")}
              </div>
            </div>
            {(extraClient || Client) && (
              <div className="d-flex flex-1 my-1 align-items-center">
                <ConnectedClientProfile
                  hideStatus
                  client={extraClient || Client}
                />
              </div>
            )}
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center w-100">
                {Account ? (
                  <>
                    {ResolvedModules.avatar && (
                      <ConnectedAccountProfile
                        size="xsm"
                        justImage
                        account={Account}
                      />
                    )}
                  </>
                ) : null}
                <div className="fs-12 ml-2 flex-1 text-grey-light text-truncate">
                  {ResolvedModules.description && (
                    <div className="text-truncate">
                      <TextEllipsis
                        length={85}
                        truncate
                        text={EmailContent.Subject}
                      />
                    </div>
                  )}
                </div>
                <div className="ml-2">
                  {ResolvedModules.manager && (
                    <ConnectedAccountPopup
                      size="xsm"
                      justImage
                      account={Manager}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </ReceptionMsgItemContainer>
  );
};

const EntityType = (Data) => {
  let EntityKey;

  const {
    EmailConversation,
    Client,
    Deal,
    Contract,
    Subscription,
    Call,
    Project,
    Ticket,
    Task,
    TimeCharge
  } = Data;

  if (EmailConversation)
    EntityKey = `${ConversationTypeEnum.Email}-${EmailConversation}`;
  else if (Client) EntityKey = `${ConversationTypeEnum.Client}-${Client}`;
  else if (Deal) EntityKey = `${ConversationTypeEnum.Deal}-${Deal}`;
  else if (Contract) EntityKey = `${ConversationTypeEnum.Contract}-${Contract}`;
  else if (Subscription)
    EntityKey = `${ConversationTypeEnum.Subscription}-${Subscription}`;
  else if (Call) EntityKey = `${ConversationTypeEnum.Call}-${Call}`;
  else if (Project) EntityKey = `${ConversationTypeEnum.Project}-${Project}`;
  else if (Ticket) EntityKey = `${ConversationTypeEnum.Ticket}-${Ticket}`;
  else if (Task) EntityKey = `${ConversationTypeEnum.Task}-${Task}`;
  else if (TimeCharge) EntityKey = `${ConversationTypeEnum.Time}-${TimeCharge}`;

  return EntityKey;
};

const CheckboxMessageComponent = ({ type, id }) => {
  const SelectedArrMsg = useContext(SelectedArrMsgContext);

  const handleSelection = useContext(SelectedMsgContext);

  const handleCheck = useCallback(() => {
    handleSelection({ type, id });
  }, [handleSelection, id, type]);

  const CheckedState = useMemo(() => {
    const index = SelectedArrMsg.findIndex(
      (e) => e.id === id && e.type === type
    );
    if (index >= 0) return true;
    else return false;
  }, [SelectedArrMsg, id, type]);

  return <Checkbox checked={CheckedState} onChange={handleCheck} />;
};

const EntityMessageItem = ({ id, settings, extraClient }) => {
  const {
    schema,
    StatusComponent,
    StatusComponentNoIcon,
    ConversationType,
    TextComponent
    // roomType
  } = settings;

  // const { type, id: urlId } = useParams();
  const entity = useEntityValue(schema, id);

  const {
    LastNote,
    Status,
    Name,
    // IsMessageRead,
    IsRead,
    IsFollowing,
    Id
  } = entity;

  const Note = useNote(LastNote);
  const { CreationDate, Description } = Note;

  const { paramsUrl } = useServerAwareState();

  const LocalStorageModules = useResolvedMessage(
    InboxMessageListComponents,
    "air-inbox"
  );

  let ResolvedModules = {};
  for (const e of LocalStorageModules) {
    const { field } = e;
    ResolvedModules[field] = true;
  }
  const match = useRouteMatch();
  return (
    <ReceptionMsgItemContainer
      conversationType={ConversationType}
      IsRead={IsRead}
      IsFollowing={IsFollowing}
      Data={id}
      schema={schema}
      // IsService={true}
    >
      <div className="d-flex w-100">
        <div className="mx-2 air-reception-msg-list-checkbox">
          <CheckboxMessageComponent type={ConversationType} id={id} />
        </div>
        <Link
          className="d-flex w-100 of-hidden disableTextDecoration p-2"
          to={`${match.url}/${ConversationType}/${Id}${paramsUrl}`}
        >
          <div className="mr-2 d-flex align-items-center">
            {ResolvedModules.status && <StatusComponent status={Status} />}
            {!ResolvedModules.status && (
              <StatusComponentNoIcon status={Status} />
            )}
          </div>
          <div className="d-flex flex-1 flex-column justify-content-between of-hidden">
            <div className="d-flex justify-content-between text-black">
              <span className="fs-14 fw-medium text-truncate">
                {TextComponent ? <TextComponent value={entity} /> : Name}
              </span>
              {ResolvedModules.date && (
                <div className="flex-0-0-auto fs-12 ml-3">
                  {moment(CreationDate).format("DD/MM/YYYY, HH:mm")}
                </div>
              )}
            </div>
            {extraClient && (
              <div className="d-flex flex-1 my-1 align-items-center">
                <ConnectedClientProfile hideStatus client={extraClient} />
              </div>
            )}

            <div className="fs-12 text-grey-light d-flex">
              {ResolvedModules.description && (
                <div className="text-truncate flex-1">
                  <TextEllipsis length={85} truncate text={Description} />
                </div>
              )}
              {/* <ConversationAssociation value={Note} /> */}
              {ResolvedModules.entityIcons && (
                <EntityIconOverview className="ml-3" value={entity} />
              )}
            </div>
          </div>
        </Link>
      </div>
    </ReceptionMsgItemContainer>
  );
};

// const ConversationAssociation = ({ value }) => {
//   const { Assigned, Manager, Company, Client, Contact } = value;
//   return (
//     <>
//       <ConnectedAccountsTeamsDepartmentsProfileStack
//         size="xsm"
//         organizations={[Company]}
//         accounts={[Assigned, Manager]}
//       />
//       <div className="vertical-splitter sm mx-2" />
//       <div className="ar-board-pics align-items-center d-flex">
//         <ConnectedClientOrigin client={Client}>
//           <ConnectedClientProfile size="xsm" client={Client} justImage />
//         </ConnectedClientOrigin>
//         <ConnectedContactPopup size="xsm" justImage contact={Contact} />
//       </div>
//     </>
//   );
// };

// const MessageItemProject = ({ project }) => {
//   const p = useProject(project);
//   return <EntityMessageItem entity={p} type={ConversationTypeEnum.Project} />;
// };

const ReceptionMessageItem = React.memo(function ReceptionMessageItem({
  item
}) {
  const {
    EmailConversation,
    Client,
    ExtraClient,
    Deal,
    Contract,
    Subscription,
    Call,
    Project,
    Ticket,
    Task,
    TimeCharge
  } = item;

  if (EmailConversation)
    return (
      <MessageItemEmail extraClient={ExtraClient} Data={EmailConversation} />
    );
  else if (Project)
    return (
      <EntityMessageItem
        id={Project}
        extraClient={ExtraClient}
        settings={projectSettings}
      />
    );
  else if (Task)
    return (
      <EntityMessageItem
        extraClient={ExtraClient}
        id={Task}
        settings={taskSettings}
      />
    );
  else if (Client)
    return (
      <EntityMessageItem
        id={Client}
        extraClient={ExtraClient}
        settings={clientSettings}
      />
    );
  else if (Deal)
    return (
      <EntityMessageItem
        extraClient={ExtraClient}
        id={Deal}
        settings={dealSettings}
      />
    );
  else if (Contract)
    return (
      <EntityMessageItem
        id={Contract}
        extraClient={ExtraClient}
        settings={contractSettings}
      />
    );
  else if (Subscription)
    return (
      <EntityMessageItem
        id={Subscription}
        extraClient={ExtraClient}
        settings={subscriptionSettings}
      />
    );
  else if (Call)
    return (
      <EntityMessageItem
        extraClient={ExtraClient}
        id={Call}
        settings={callSettings}
      />
    );
  else if (Ticket)
    return (
      <EntityMessageItem
        extraClient={ExtraClient}
        id={Ticket}
        settings={ticketSettings}
      />
    );
  else if (TimeCharge)
    return (
      <EntityMessageItem
        extraClient={ExtraClient}
        id={TimeCharge}
        settings={timeChargeSettings}
      />
    );
  else return null;
});

const TodayDate = moment();
const YesterdayDate = moment().subtract(1, "day");
const PreviousWeek = moment().subtract(1, "week");
const LastMonthDate = moment().subtract(1, "month");

const MessageItemTimeGroupLabel = (
  Data,
  VeryOld,
  LastMonth,
  ThisMonth,
  LastWeek,
  ThisWeek,
  Yesterday,
  Today
) => {
  const Conversation = getEntity(spaceEmailConversationSchema, Data);
  const { LastEmail } = Conversation;
  const EmailContent = getEntity(spaceEmailSchema, LastEmail);
  const { SentDate } = EmailContent;
  const ResolvedDate = moment(SentDate);

  if (ResolvedDate.isSame(TodayDate, "day")) {
    Today.push({ EmailConversation: Data });
  } else if (ResolvedDate.isSame(YesterdayDate, "day")) {
    Yesterday.push({ EmailConversation: Data });
  } else if (ResolvedDate.isSame(TodayDate, "week")) {
    ThisWeek.push({ EmailConversation: Data });
  } else if (ResolvedDate.isSame(PreviousWeek, "week")) {
    LastWeek.push({ EmailConversation: Data });
  } else if (ResolvedDate.isSame(TodayDate, "month")) {
    ThisMonth.push({ EmailConversation: Data });
  } else if (ResolvedDate.isSame(LastMonthDate, "month")) {
    LastMonth.push({ EmailConversation: Data });
  } else {
    VeryOld.push({ EmailConversation: Data });
  }
};

const EntityMessageItemTImeGroupLabel = (
  id,
  settings,
  VeryOld,
  LastMonth,
  ThisMonth,
  LastWeek,
  ThisWeek,
  Yesterday,
  Today,
  Data
) => {
  const { schema } = settings;

  // const { type, id: urlId } = useParams();
  const entity = getEntity(schema, id);

  const { LastNote } = entity;

  const Note = getEntity(spaceNoteSchema, LastNote);
  const { CreationDate } = Note;
  const ResolvedDate = moment(CreationDate);

  if (ResolvedDate.isSame(TodayDate, "day")) {
    Today.push(Data);
  } else if (ResolvedDate.isSame(YesterdayDate, "day")) {
    Yesterday.push(Data);
  } else if (ResolvedDate.isSame(TodayDate, "week")) {
    ThisWeek.push(Data);
  } else if (ResolvedDate.isSame(PreviousWeek, "week")) {
    LastWeek.push(Data);
  } else if (ResolvedDate.isSame(TodayDate, "month")) {
    ThisMonth.push(Data);
  } else if (ResolvedDate.isSame(LastMonthDate, "month")) {
    LastMonth.push(Data);
  } else {
    VeryOld.push(Data);
  }
};

const TimeLabelComponent = ({
  className,
  icon,
  textId,
  text,
  Arr = [],
  ...rest
}) => {
  if (Arr.length === 0) return null;
  return (
    <div>
      <div className="py-2 border-bottom bg-airdesk  text-black fs-12 fw-medium px-2">
        {textId ? <FormattedMessage id={textId} /> : text}
      </div>

      {/* <AnimatedAccordion
      disableToggle={true}
      className={classnames("py-2", { "px-3": !/px-0/i.test(className) })}
      title={
        <div className="fs-16 fw-medium">
          {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
         
        </div>
      }
      isOpenByDefault
      {...rest}
    >
     
    </AnimatedAccordion> */}
      {Arr.map((e, i) => {
        const key = EntityType(e);
        if (!key) return null;
        return <ReceptionMessageItem key={i + key} item={e} />;
      })}
    </div>
  );
};

const ReceptionCompMsgListWithTimeLabels = React.memo(
  ({ Messages = [], orderBy }) => {
    const VeryOld = [];
    const LastMonth = [];
    const ThisMonth = [];
    const LastWeek = [];
    const ThisWeek = [];
    const Yesterday = [];
    const Today = [];

    const hasDateOrderBy = orderBy.findIndex(
      (e) => e.column === "LastMessage/Date"
    );
    for (const m of Messages) {
      const {
        EmailConversation,
        Client,
        Deal,
        Contract,
        Subscription,
        Call,
        Project,
        Ticket,
        Task,
        TimeCharge
      } = m;

      if (EmailConversation)
        MessageItemTimeGroupLabel(
          EmailConversation,
          VeryOld,
          LastMonth,
          ThisMonth,
          LastWeek,
          ThisWeek,
          Yesterday,
          Today
        );
      else if (Project)
        EntityMessageItemTImeGroupLabel(
          Project,
          projectSettings,
          VeryOld,
          LastMonth,
          ThisMonth,
          LastWeek,
          ThisWeek,
          Yesterday,
          Today,
          m
        );
      else if (Task)
        EntityMessageItemTImeGroupLabel(
          Task,
          taskSettings,
          VeryOld,
          LastMonth,
          ThisMonth,
          LastWeek,
          ThisWeek,
          Yesterday,
          Today,
          m
        );
      else if (Client)
        EntityMessageItemTImeGroupLabel(
          Client,
          clientSettings,
          VeryOld,
          LastMonth,
          ThisMonth,
          LastWeek,
          ThisWeek,
          Yesterday,
          Today,
          m
        );
      else if (Deal)
        EntityMessageItemTImeGroupLabel(
          Deal,
          dealSettings,
          VeryOld,
          LastMonth,
          ThisMonth,
          LastWeek,
          ThisWeek,
          Yesterday,
          Today,
          m
        );
      else if (Contract)
        EntityMessageItemTImeGroupLabel(
          Contract,
          contractSettings,
          VeryOld,
          LastMonth,
          ThisMonth,
          LastWeek,
          ThisWeek,
          Yesterday,
          Today,
          m
        );
      else if (Subscription)
        EntityMessageItemTImeGroupLabel(
          Subscription,
          subscriptionSettings,
          VeryOld,
          LastMonth,
          ThisMonth,
          LastWeek,
          ThisWeek,
          Yesterday,
          Today,
          m
        );
      else if (Call)
        EntityMessageItemTImeGroupLabel(
          Call,
          callSettings,
          VeryOld,
          LastMonth,
          ThisMonth,
          LastWeek,
          ThisWeek,
          Yesterday,
          Today,
          m
        );
      else if (Ticket)
        EntityMessageItemTImeGroupLabel(
          Ticket,
          ticketSettings,
          VeryOld,
          LastMonth,
          ThisMonth,
          LastWeek,
          ThisWeek,
          Yesterday,
          Today,
          m
        );
      else if (TimeCharge)
        EntityMessageItemTImeGroupLabel(
          TimeCharge,
          timeChargeSettings,
          VeryOld,
          LastMonth,
          ThisMonth,
          LastWeek,
          ThisWeek,
          Yesterday,
          Today,
          m
        );
    }

    if (orderBy[hasDateOrderBy].type === "desc") {
      return (
        <>
          {Today.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_TODAY" Arr={Today} />
          )}
          {Yesterday.length > 0 && (
            <TimeLabelComponent textId="YESTERDAY" Arr={Yesterday} />
          )}
          {ThisWeek.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_THIS_WEEK" Arr={ThisWeek} />
          )}
          {LastWeek.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_LAST_WEEK" Arr={LastWeek} />
          )}
          {ThisMonth.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_THIS_MONTH" Arr={ThisMonth} />
          )}
          {LastMonth.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_LAST_MONTH" Arr={LastMonth} />
          )}
          {VeryOld.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_EVEN_OLDER" Arr={VeryOld} />
          )}
        </>
      );
    } else
      return (
        <>
          {VeryOld.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_EVEN_OLDER" Arr={VeryOld} />
          )}
          {LastMonth.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_LAST_MONTH" Arr={LastMonth} />
          )}
          {ThisMonth.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_THIS_MONTH" Arr={ThisMonth} />
          )}
          {LastWeek.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_LAST_WEEK" Arr={LastWeek} />
          )}
          {ThisWeek.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_THIS_WEEK" Arr={ThisWeek} />
          )}
          {Yesterday.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_YESTERDAY" Arr={Yesterday} />
          )}
          {Today.length > 0 && (
            <TimeLabelComponent textId="INTERVAL_TODAY" Arr={Today} />
          )}
        </>
      );
  }
);

const ReceptionCompMsgList = React.memo(
  ({
    Messages = [],
    ScrollRef,
    Loading,
    HandleMessageClick,
    OpenedId,
    detailsView,
    setResponse
  }) => {
    const [isHidden, setIsHidden] = useState(false);

    const { hasSpace } = useContext(MinimizeContext);
    const { params } = useServerAwareState();
    const { $orderBy, query = "" } = params;

    const hasDateOrderBy = $orderBy.findIndex(
      (e) => e.column === "LastMessage/Date"
    );

    return (
      <div
        className="bg-white h-100 air-reception-msg-list d-flex flex-column"
        style={{
          marginLeft: !hasSpace ? 50 : undefined,
          width: isHidden ? 70 : undefined
        }}
      >
        <ReceptionCompMsgListHeader
          detailsView={detailsView}
          Loading={Loading}
          Messages={Messages}
          isHidden={isHidden}
          setIsHidden={setIsHidden}
        />
        <div
          ref={ScrollRef}
          className={classnames("of-y-auto border-right h-100")}
        >
          <div
            className={classnames("flex-column position-relative", {
              "disable-checkbox-on-list": isHidden,
              "d-flex": !isHidden
            })}
          >
            {hasDateOrderBy >= 0 && (
              <ReceptionCompMsgListWithTimeLabels
                orderBy={$orderBy}
                Messages={Messages}
              />
            )}
            {hasDateOrderBy < 0 &&
              Messages.map((e, i) => {
                const key = EntityType(e);
                if (!key) return null;
                return <ReceptionMessageItem key={i + key} item={e} />;
              })}
            {Loading && (
              <LoaderSpinner className="mx-auto my-2" size="xs" center />
            )}
          </div>
          {!Loading && Messages.length === 0 && query !== "" && !isHidden && (
            <div className="d-flex flex-column align-items-center h-100 justify-content-center">
              <div style={{ width: 64 }} className="mb-2">
                <EmptySearchIcon />
              </div>
              <div className="fs-14 fw-bold text-black mb-1">
                <FormattedMessage id={"EMPTY_INBOX_RECEPTION_QUERY"} />
              </div>
              <div className="fs-14 text-black">
                <FormattedMessage id={"EMPTY_INBOX_RECEPTION_QUERY_SUBTEXT"} />
              </div>
            </div>
          )}
          {!Loading && Messages.length === 0 && query === "" && !isHidden && (
            <div className="d-flex flex-column align-items-center h-100 justify-content-center">
              <div className="mb-2">
                <EmptyListIcon style={{ width: 64, height: 64 }} />
              </div>
              <div className="fs-14 fw-bold text-black mb-1">
                <FormattedMessage id={"EMPTY_INBOX_RECEPTION"} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ReceptionCompMsgList;
