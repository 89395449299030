import React, { Component } from "react";
import { NavLink, Route, Switch, Redirect } from "react-router-dom";
import "./TabStrip.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faChevronDoubleRight } from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage } from "react-intl";

class TabStrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: this.props.index != null ? this.props.index : 0
    };
  }

  menuWithRoute = (items) => {
    const { defaultPath } = this.props;

    const navLinks = items.map((item, index) =>
      item ? (
        <NavLink
          className={`Tabstrip-link ar-details-tabstrip Tabstrip-linkSmallerPadding Tabstrip-link`}
          exact={item.exact}
          to={item.to ? `${defaultPath}/${item.to}` : defaultPath}
          activeClassName="selected"
          key={index}
        >
          {item.name}
        </NavLink>
      ) : null
    );

    let defaultItem;
    const routes = items.map((item, index) => {
      if (!item) return null;
      const resolvedTo = item.to ? `${defaultPath}/${item.to}` : defaultPath;
      if (item.isActive === false) return null;
      if (item.default) defaultItem = <Redirect to={resolvedTo} />;

      return (
        <Route
          path={resolvedTo}
          strict
          exact={item.exact}
          component={item.component}
          key={index}
        />
      );
    });

    return (
      <div
        className={classnames(
          "Tabstrip-container d-flex flex-column",
          this.props.className
        )}
      >
        <div
          className={classnames(
            "Tabstrip-top-menu",
            this.props.headerClassName
          )}
        >
          {navLinks}
        </div>
        <div
          className={classnames(
            "Tabstrip-content of-y-auto flex-1",
            this.props.contentClassName
          )}
        >
          <Switch>
            {routes}
            {defaultItem}
          </Switch>
        </div>
      </div>
    );
  };

  clickEvent(i) {
    if (typeof this.props.switchTab === "function") {
      this.props.switchTab(i);
    }
    this.setState({ tab: i });
  }

  menuWithoutRoute = (items) => {
    const {
      alignLeft,
      headerClassName,
      contentClassName,
      tabClassName,
      topClassName,
      ExtraFooter,
      className
    } = this.props;
    const headerClasses = classnames("Tabstrip-top-menu", headerClassName, {
      left: alignLeft
    });

    const contentClasses = classnames(
      "Tabstrip-content of-y-auto flex-1",
      contentClassName
    );

    const navLinks = items.map((item, index) => {
      if (item.isActive === false) return null;
      return (
        <div
          className={classnames(
            "ar-details-tabstrip Tabstrip-link" +
              (this.state.tab === index ? " selected" : ""),
            tabClassName
          )}
          key={index}
          onClick={this.clickEvent.bind(this, index)}
        >
          {item.name}
        </div>
      );
    });

    const route = items[this.state.tab].component;

    return (
      <div
        className={classnames(
          `Tabstrip-container d-flex flex-column`,
          topClassName,
          className
        )}
      >
        <div className={headerClasses}>{navLinks}</div>
        <div className={contentClasses}>{route}</div>
        {ExtraFooter && ExtraFooter}
      </div>
    );
  };

  menuWithNoDestruction = (items) => {
    const {
      alignLeft,
      headerClassName,
      contentClassName,
      topClassName,
      ExtraFooter,
      className,
      extraHeaderContent
    } = this.props;
    const headerClasses = classnames("Tabstrip-top-menu", headerClassName, {
      left: alignLeft
    });

    const contentClasses = classnames(
      "Tabstrip-content of-y-auto flex-1",
      contentClassName
    );

    const navLinks = items.map((item, index) => {
      if (item.isActive === false)
        return <React.Fragment key={index}></React.Fragment>;
      return (
        <div
          className={
            "ar-details-tabstrip Tabstrip-link" +
            (this.state.tab === index ? " selected" : "")
          }
          key={index}
          onClick={this.clickEvent.bind(this, index)}
        >
          {item.name}
        </div>
      );
    });

    // const route = items[this.state.tab].component;

    return (
      <div
        className={classnames(
          `Tabstrip-container d-flex flex-column`,
          topClassName,
          className
        )}
      >
        <div className={headerClasses}>
          <div>{navLinks}</div>
          {extraHeaderContent && extraHeaderContent}
        </div>
        {items.map((e, i) => {
          return (
            <div
              key={i}
              className={classnames(contentClasses, {
                "d-none": i !== this.state.tab
              })}
            >
              {e.component}
            </div>
          );
        })}
        {/* <div className={contentClasses}>{route}</div> */}
        {ExtraFooter && ExtraFooter}
      </div>
    );
  };

  menuOfWidgets = (items) => {
    const {
      alignLeft,
      headerClassName,
      // contentClassName,
      topClassName
      // PreviewBarComponent,
    } = this.props;
    const headerClasses = classnames("Tabstrip-top-menu", headerClassName, {
      left: alignLeft
    });

    // const contentClasses = classnames(
    //   "Tabstrip-content of-y-auto flex-1",
    //   contentClassName
    // );

    const navLinks = items.map((item, index) => {
      if (item.isActive === false) return null;
      return (
        <div
          className={
            "ar-details-tabstrip Tabstrip-link" +
            (this.state.tab === index ? " selected" : "")
          }
          key={index}
          onClick={this.clickEvent.bind(this, index)}
        >
          {item.name}
        </div>
      );
    });

    const route = items[this.state.tab].component;

    return (
      <div className={classnames("d-flex flex-1 flex-column of-hidden")}>
        <div className={classnames("d-flex bg-airdesk")}>
          <div className="Widget-Left-Side-Width shadow">
            <div
              className={classnames(
                `Tabstrip-container d-flex flex-column`,
                `${topClassName}`
              )}
            >
              <div className={headerClasses}>{navLinks}</div>
            </div>
          </div>
          <div
            className={classnames("Widget-Right-Side-Width flex-1 bg-airdesk")}
          >
            {this.props.OpenPreview ? (
              <div
                style={{
                  padding: "14px 15px 15px 15px"
                }}
                className="mx-4 d-flex border-bottom justify-content-between align-items-center"
              >
                <div className="text-black text-truncate fs-16">
                  <FontAwesomeIcon icon={faEye} className="mr-2" />
                  <span>
                    <FormattedMessage id="PREVIEW" />
                  </span>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    this.props.setOpenPreview((e) => {
                      return !e;
                    });
                  }}
                >
                  <FontAwesomeIcon
                    rotation={!this.state.OpenPreviewSide ? 180 : undefined}
                    icon={faChevronDoubleRight}
                    className="text-color-link"
                  />
                </div>
              </div>
            ) : (
              <div className="p-2 h-100 d-flex justify-content-center align-items-center">
                <div
                  className="cursor-pointer bg-airdesk ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button"
                  onClick={() => {
                    this.props.setOpenPreview((e) => {
                      return !e;
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faEye} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex">
          {route}
          <div className="Widget-Right-Side-Width bg-airdesk"></div>
        </div>
      </div>
    );
  };

  render() {
    let items = this.props.items;

    if (items == null) items = [];
    if (this.props.widget) {
      return this.menuOfWidgets(items);
    } else if (this.props.noUnmout === true) {
      return this.menuWithNoDestruction(items);
    } else if (this.props.route === false) {
      return this.menuWithoutRoute(items);
    } else {
      return this.menuWithRoute(items);
    }
  }
}

export default TabStrip;
