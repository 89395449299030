import React, { useCallback } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";
import Input from "../../Components/Input/Input";
import DeleteButton from "../../Components/Button/DeleteButton";
import AddressSelectorr from "../../Components/AddressSelector/AddressSelector";
// import { useCurrentUserContactPermission } from "../../Helpers/ContactPermissionsHelper";
import Button from "../../Components/Button/Button";
import { FormattedMessage } from "react-intl";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const MultipleAddressSelector = ({ value, handleChange, name }) => {
  const onDragEnd = useCallback(
    (result) => {
      if (
        !result.destination ||
        result.destination.index === result.source.index
      ) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const newOrderedColumns = reorder(
        value,
        result.source.index,
        result.destination.index
      );
      handleChange({
        [name]: newOrderedColumns
      });
    },
    [handleChange, name, value]
  );

  const handleRemoval = useCallback(
    (index) => {
      const newOrderedColumns = [...value];
      newOrderedColumns.splice(index, 1);
      handleChange({
        [name]: newOrderedColumns
      });
    },
    [handleChange, name, value]
  );

  const onChange = (newV, index) => {
    const newOrderedColumns = [...value];
    newOrderedColumns[index] = newV;
    handleChange({
      [name]: newOrderedColumns
    });
  };

  const onAdd = () => {
    const newOrderedColumns = [...value];
    newOrderedColumns.push({
      Id: undefined,
      Country: undefined,
      Address1: "",
      Address2: "",
      Name: "",
      District: "",
      Locality: "",
      PostalCode: "",
      Latitude: "",
      Longitude: ""
    });

    handleChange({
      [name]: newOrderedColumns
    });
  };

  return (
    <div style={{ overflow: "auto" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="dropable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`position-relative`}
            >
              <AddressesList
                onRemove={handleRemoval}
                onChange={onChange}
                items={value}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        onClick={onAdd}
        type="button"
        vType="primary-ghost"
        className="w-100 mt-3"
      >
        + <FormattedMessage id={"ADD_ADDRESS"} />
      </Button>
    </div>
  );
};

const AddressesList = React.memo(({ items, onRemove, onChange }) => {
  return items.map((item, index) => {
    return (
      <AddressItem
        item={item}
        index={index}
        key={index}
        onChange={onChange}
        onRemove={onRemove}
      />
    );
  });
});

const AddressItem = ({ item, index, onChange, onRemove }) => {
  return (
    <Draggable draggableId={`drag-${index}`} index={index}>
      {(provided, snapshot) => (
        <AddressItemComp
          className={`${snapshot.isDragging ? "Dragging" : ""}`}
          provided={provided}
          snapshot={snapshot}
          onRemove={onRemove}
          onChange={onChange}
          item={item}
          index={index}
        />
      )}
    </Draggable>
  );
};

const AddressItemComp = ({
  className,
  provided,
  snapshot,
  onRemove,
  onChange,
  item,
  index
}) => {
  const { Name } = item;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newItem = { ...item };
    newItem[name] = value;
    onChange(newItem, index);
  };

  const addressChange = (e) => {
    onChange(e, index);
  };

  // const noContactReadPermission = !useCurrentUserContactPermission();
  const { style: baseStyle, ...rest } = provided.draggableProps;
  const style = { ...baseStyle };
  style.zIndex = 999999999999;
  const Comp = (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...rest}
      style={style}
      className="py-2"
    >
      <div
        className={classnames(
          "border rounded  bg-white p-2 flex-column ar-deals-pipeline-item d-flex align-items-center",
          { dragging: snapshot.isDragging }
        )}
      >
        <div className="d-flex align-items-center w-100 mb-3">
          <div
            {...provided.dragHandleProps}
            className="h-100 px-2 ar-deals-pipeline-item-drag"
          >
            <FontAwesomeIcon icon={faGripVertical} />
          </div>

          <Input
            className={classnames("flex-1")}
            name="Name"
            value={Name}
            onChange={handleChange}
          />

          <DeleteButton
            size="sm"
            className="ml-3"
            onClick={() => {
              onRemove(index);
            }}
          />
        </div>
        <div className="w-100">
          <AddressSelectorr
            key={index}
            value={item}
            onChange={addressChange}
            // preview={noContactReadPermission}
          />
        </div>
      </div>
    </div>
  );

  if (snapshot.isDragging) return ReactDOM.createPortal(Comp, document.body);
  else return Comp;
};
