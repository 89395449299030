import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect
} from "react";
import {
  generalComputeDates,
  generalComputeAdittionalDate,
  createDay,
  TimelineContext,
  TimelineLayoutStateContext,
  TimelineKinectProvider,
  useTimelineZoom
} from "./TimelineHelper";
import { useServerAwareState, useDetailAwareParams } from "../CGrid/ServerGrid";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { accountSchema } from "../../config/schema";
import moment from "moment";
import { createKinectHook } from "../../Hooks/MiscHooks";
import classnames from "classnames";
import TimelineRows from "./TimelineRows";
import { BarLoader } from "../GlobalLoader/GlobalLoader";
import { FilterColumnColorProvider } from "../FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { buildODataFilter } from "../../Helpers/ODataHelper";
import { many } from "../../Helpers/SchemaHelper";

// const dayOffset = 7;
const baseRightWeeks = 2;
const leftItemsPerRequest = 7;
const rightItemsPerRequest = 14;
const totalDays = leftItemsPerRequest + rightItemsPerRequest;

const useTime = (config) => {
  const { now, nowMilisec } = useMemo(() => {
    const now = config.getStartDate();

    return { now, nowMilisec: now.getTime() };
  }, [config]);

  const [times, setTimes] = useState(() => {
    const { dateUnit, itemsPerCycle } = config;
    const times = generalComputeDates(now, itemsPerCycle, dateUnit);

    return times;
  });

  return { times, setTimes, now, nowMilisec };
};

const useTimelineValueCreator = (setIsLayoutActive, timelineRef, times) => {
  const valueRef = useRef();
  if (!valueRef.current)
    valueRef.current = {
      enableLayout: () => {
        setIsLayoutActive(true);
      },
      disableLayout: () => {
        setIsLayoutActive(false);
      },
      domRef: timelineRef,
      startDateObj: times[0]
    };

  return valueRef.current;
};

const useTimelineFilters = (serverAwareState) => {
  const { params, moduleType } = serverAwareState;
  const { filter, advancedFilters, query } = useDetailAwareParams(
    params,
    moduleType
  );

  return useMemo(() => {
    const accounts = [];
    const departmentTeams = [];
    const requestFilters = [];

    for (const f of filter) {
      const { field, value } = f;
      if (field === "Assigned/Id") {
        accounts.push(value);
      } else if (field === "Department/Id") {
        departmentTeams.push(value);
      } else if (field === "Team/Id") {
        departmentTeams.push(value);
      } else if (field === "Company/Id") {
        departmentTeams.push(value);
      } else {
        requestFilters.push(f);
      }
    }

    return {
      accounts,
      departmentTeams,
      requestFilters,
      advancedFilters,
      query
    };
  }, [advancedFilters, filter, query]);
};

const useTimelineUsers = (tlFilters) => {
  const [resolvedUsers, setResolvedUsers] = useState([]);
  const usersCount = useRef(null);
  const search = useMemo(() => {
    const {
      accounts,
      departmentTeams,
      requestFilters,
      advancedFilters,
      query = ""
    } = tlFilters;
    //
    // console.log(query);
    let search = `&query=${query}&$orderby=Account.Name asc&$filter=(`;
    let filterCount = 0;
    // if(accounts.length > 0)

    const addFilter = (filter) => {
      if (filterCount > 0) search += " and ";
      filterCount++;
      search += `(${filter})`;
    };

    if (accounts.length > 0)
      addFilter(`Account/Id in('${accounts.join("','")}')`);

    if (departmentTeams.length > 0)
      addFilter(`Organization/Id in('${departmentTeams.join("','")}')`);

    if (requestFilters.length > 0) {
      const resolvedOdataFilters = buildODataFilter(requestFilters, true, true);
      search += ` and ${resolvedOdataFilters}`;
    }

    if (filterCount > 0) {
      search += ")";

      if (advancedFilters && advancedFilters.length > 0) {
        search += `&customFilters=${advancedFilters.join(",")}`;
      }

      return search;
    }

    return advancedFilters && advancedFilters.length > 0
      ? `&query=${query}&$orderby=Account.Name asc&customFilters=${advancedFilters.join(
          ","
        )}`
      : `&query=${query}&$orderby=Account.Name asc`;
  }, [tlFilters]);

  // const { Id } = useCurrentAccount();

  const [skip, setSkip] = useState(0);

  const url = useMemo(() => {
    return `query/accounts/timeline?$inlinecount=allpages&$top=25&$skip=${skip}${search}`;
  }, [search, skip]);

  const responseSchema = useMemo(() => {
    return {
      d: {
        results: many(accountSchema)
      }
    };
  }, []);

  const { loading } = useSpaceQuery(url, responseSchema, {
    cache: false,
    onSuccess: ({ data }) => {
      const { d } = data;
      const { __count, results } = d;
      usersCount.current = __count;
      const tempHolder = [...resolvedUsers, ...results];

      setResolvedUsers([...new Set(tempHolder)]);
    }
  });

  useEffect(() => {
    if (
      !loading &&
      resolvedUsers.length > 0 &&
      usersCount.current &&
      usersCount.current > skip &&
      25 + skip < usersCount.current
    ) {
      setSkip(skip + 25);
    }
  }, [loading, resolvedUsers, skip]);

  return { loading, users: resolvedUsers };
};

const getDateDiffrence = (el, itemsToLeft, zoomWidth) => {
  const { scrollLeft, clientWidth } = el;
  let start = Math.floor(scrollLeft / zoomWidth);
  start = start - itemsToLeft;
  let end = Math.floor((scrollLeft + clientWidth - zoomWidth - 1) / zoomWidth);
  end = end - itemsToLeft;

  return { start, end };
};

const calcPeriods = (start, end, date, dateUnit) => {
  const resolvedStart = start + leftItemsPerRequest;
  const leftCycle = Math.floor(resolvedStart / totalDays);

  const resolvedEnd = end + leftItemsPerRequest;
  const rightCycle = Math.floor(resolvedEnd / totalDays) + 1;
  // console.log(`leftcycle: ${leftCycle} rightCycle: ${rightCycle}`)
  const arr = [];
  // console.log(new Date(date))
  for (let i = leftCycle; i < rightCycle; i++) {
    const sDate = moment(date);
    sDate.add(totalDays * i, dateUnit);
    // const sDate = date + totalDays * dayMilisec * i;

    const startDate = moment(sDate);
    startDate.subtract(leftItemsPerRequest, dateUnit);
    // const startDate = sDate - leftItemsPerRequest * dayMilisec;

    const endDate = moment(sDate);
    endDate.add(rightItemsPerRequest - 1, dateUnit);
    // const endDate = sDate + rightItemsPerRequest * dayMilisec - dayMilisec;

    arr.push({
      start: startDate.valueOf(),
      startFormmated: startDate.format("YYYY-MM-DD"),
      end: endDate.valueOf(),
      endFormmated: endDate.format("YYYY-MM-DD")
    });
  }

  const minStart =
    leftCycle === 0
      ? 0 - leftItemsPerRequest
      : leftCycle * totalDays - leftItemsPerRequest;
  const maxStart = minStart + totalDays - 1;

  const convertedRightCycle = rightCycle - 1;
  const minEnd =
    convertedRightCycle === 0
      ? 0 - leftItemsPerRequest
      : convertedRightCycle * totalDays - leftItemsPerRequest;
  const maxEnd = minEnd + totalDays - 1;

  return { periods: arr, minStart, maxStart, minEnd, maxEnd };
};

const useKinect = createKinectHook({
  filterTarget: function (target, e) {
    if (
      target.classList &&
      target.classList.contains("ar-timeline-item-handle")
    )
      return false;

    if (!/down|start/.test(e.type)) {
      return !/area|a|input/i.test(target.tagName);
    }
  }
});

const useRequestState = () => {
  const requestsRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const onRequestStart = useCallback(() => {
    setLoading(true);
    requestsRef.current++;
  }, []);

  const onRequestEnd = useCallback(() => {
    requestsRef.current--;
    if (requestsRef.current === 0) setLoading(false);
  }, []);

  const requestCallbacks = useMemo(() => {
    return { onRequestStart, onRequestEnd };
  }, [onRequestEnd, onRequestStart]);

  return { loading, requestCallbacks };
};

const convertHolidaysToDict = (holidays, dict) => {
  for (const holiday of holidays) {
    const { Date: dateString } = holiday;
    const date = createDay(dateString);
    const milsecs = date.getTime();
    dict[milsecs] = holiday;
  }

  return dict;
};

const useHolidays = (year) => {
  const [holidays, setHolidays] = useState({});

  const [url, setUrl] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setUrl(`query/holidays?year=${year}`);
    }, 300);

    return () => clearTimeout(timeout);
  }, [year]);

  const { loading } = useSpaceQuery(url, null, {
    onSuccess: ({ data }) => {
      setHolidays((dict) => {
        const newDict = { ...dict };
        return convertHolidaysToDict(data, newDict);
      });
    }
  });

  return { loading, holidays };
};

const GridItem = React.memo(function GridItem({
  nowMilisec,
  value,
  holiday,
  disableWeekends,
  disableHolidays
}) {
  const { key, isWeekend } = value;
  // const handleDblClick = () => {
  //   openTaskFrom(value);
  // };
  const { width } = useTimelineZoom();
  return (
    <div
      // onDoubleClick={handleDblClick}
      className={classnames("ar-timeline-grid-column", {
        today: key === nowMilisec,
        weekend: !disableWeekends && isWeekend,
        holiday: !disableHolidays && Boolean(holiday)
      })}
      style={{ width }}
    >
      {/* {v.key === nowMilisec && <div>totay</div> } */}
      {/* {v.format("DD dddd")} */}
    </div>
  );
});

const useTimelineVirtualInfoMemo = (periods, times) => {
  const value = useMemo(() => {
    if (!periods) return null;
    const startkey = periods[0].start;
    const endkey = periods[periods.length - 1].end;

    let startIndex = times.findIndex((t) => t.key === startkey);
    let endIndex = times.findIndex((t) => t.key === endkey);

    if (startIndex === -1) startIndex = 0;
    if (endIndex === -1) endIndex = times.length - 1;

    const resolvedTimes = times.slice(startIndex, endIndex);

    return { startIndex, endIndex, resolvedTimes };
  }, [periods, times]);

  const { width } = useTimelineZoom();

  const style = useMemo(() => {
    if (!value) return null;
    const { startIndex, endIndex } = value;

    const paddingLeft = width * startIndex;
    const paddingRight = width * (times.length - 1 - endIndex);

    return {
      paddingLeft,
      paddingRight
    };
  }, [times.length, value, width]);

  return useMemo(() => {
    return {
      ...value,
      style
    };
  }, [style, value]);
};

const Grid = React.memo(function Grid({
  virtualInfo,
  nowMilisec,
  holidays,
  disableHolidays,
  disableWeekends
}) {
  const gridRef = useRef();
  const { style, resolvedTimes } = virtualInfo;

  if (!resolvedTimes) return null;

  // const {width} = useTimelineZoom()
  return (
    <div className="position-absolute d-flex flex-column h-100">
      <div
        ref={gridRef}
        className="ar-timeline-grid"
        style={{ marginLeft: 230, ...style }}
      >
        {resolvedTimes.map((v) => (
          <GridItem
            disableWeekends={disableWeekends}
            disableHolidays={disableHolidays}
            holiday={holidays[v.key]}
            key={v.key}
            value={v}
            nowMilisec={nowMilisec}
          />
        ))}
      </div>
    </div>
  );
});

export const TimelineContent = ({ config, innerRef }) => {
  const { nowMilisec, now, times, setTimes } = useTime(config);

  const [isLayoutActive, setIsLayoutActive] = useState(true);

  const timelineRef = useRef();

  const timelineValue = useTimelineValueCreator(
    setIsLayoutActive,
    timelineRef,
    times
  );

  const [periodState, setPeriodState] = useState();
  const periodStateRef = useRef();

  const updatePeriodState = useCallback((v) => {
    setPeriodState(v);
    periodStateRef.current = v;
  }, []);

  const scrolledLeftRef = useRef();
  const prevScrollWidth = useRef();

  const itemsToLeftRef = useRef(config.itemsPerCycle);
  const itemsToRightRef = useRef(baseRightWeeks);

  const serverAwareState = useServerAwareState();

  const tlFilters = useTimelineFilters(serverAwareState);

  // const users = useMemo(() => [Id, ...baseUsers], [Id]);
  const { loading: loadingUsers, users } = useTimelineUsers(tlFilters);
  const [day, setDay] = useState(nowMilisec);
  const currentDateRef = useRef(nowMilisec);
  const { year } = useMemo(() => {
    const d = new Date(day);
    const year = d.getFullYear();
    const month = d.getMonth();

    return { year, month };
  }, [day]);

  const scrollListnersRef = useRef([]);

  const subscribeScrollListner = useCallback((listner) => {
    scrollListnersRef.current.push(listner);

    const unsbscribe = () => {
      const index = scrollListnersRef.current.indexOf(listner);
      scrollListnersRef.current.splice(index, 1);
    };
    return unsbscribe;
  }, []);

  const { width: zoomWidth } = useTimelineZoom();

  const handleScroll = useCallback(
    (e) => {
      const t = timelineRef.current;
      const { scrollLeft, clientWidth, scrollWidth } = t;
      const { dateUnit, itemsPerCycle } = config;
      const { start, end } = getDateDiffrence(
        t,
        itemsToLeftRef.current,
        zoomWidth
      );
      // console.log(`start: ${start}`)
      // console.log(`end: ${end}`)

      const currentDate = moment(nowMilisec).add(start, dateUnit).valueOf();

      // const currentDate = nowMilisec + start * dayMilisec;
      // console.log(new Date(currentDate));
      if (e) {
        for (const listner of scrollListnersRef.current) {
          listner(e);
        }
      }

      setDay(currentDate);
      currentDateRef.current = currentDate;

      if (periodStateRef.current) {
        const { minStart, maxStart, minEnd, maxEnd } = periodStateRef.current;

        if (
          start < minStart ||
          start > maxStart ||
          end < minEnd ||
          end > maxEnd
        ) {
          // console.log("calc periods");
          updatePeriodState(calcPeriods(start, end, nowMilisec, dateUnit));
        }
      } else {
        updatePeriodState(calcPeriods(start, end, nowMilisec, dateUnit));
      }

      // console.log(`start: ${start}`);
      // console.log(`end: ${end}`);

      // scrolled left | backwards

      if (scrollLeft < 500) {
        //;
        scrolledLeftRef.current = true;
        itemsToLeftRef.current += itemsPerCycle;
        setTimes((t) => {
          const newTimes = generalComputeAdittionalDate(
            t[0].date,
            itemsPerCycle * -1,
            dateUnit
          );
          timelineValue.startDateObj = newTimes[0];
          return [...newTimes, ...t];
        });
      } else if (scrollWidth - (scrollLeft + clientWidth) < 500) {
        //;
        scrolledLeftRef.current = false;
        setTimes((t) => {
          //;
          const newTimes = generalComputeAdittionalDate(
            t[t.length - 1].date,
            itemsPerCycle,
            dateUnit
          );
          itemsToRightRef.current += itemsPerCycle;
          return [...t, ...newTimes];
        });
      }
    },
    [config, nowMilisec, setTimes, timelineValue, updatePeriodState, zoomWidth]
  );

  // const prevZoomWidth = useRef(zoomWidth)
  // useLayoutEffect(() => {
  //   handleScroll();
  // }, [handleScroll, zoomWidth]);

  const hasSetScrollRef = useRef(false);

  useLayoutEffect(() => {
    const t = timelineRef.current;

    if (!periodStateRef.current) {
      handleScroll(null, true);
      return;
    }
    const targetDate =
      hasSetScrollRef.current === false ? nowMilisec : currentDateRef.current;
    hasSetScrollRef.current = true;
    const x = times.findIndex((t) => t.key === targetDate);
    //;
    t.scrollLeft = zoomWidth * x;

    prevScrollWidth.current = t.scrollWidth;
  }, [handleScroll, nowMilisec, times, zoomWidth]);

  const kinectRef = useRef();
  useKinect(timelineRef, kinectRef);

  const { loading, requestCallbacks } = useRequestState();

  const { loading: loadingHolidays, holidays } = useHolidays(year);

  const { requestFilters } = tlFilters;

  useEffect(() => {
    if (!innerRef) return;

    innerRef.current = {
      handleFocusToday: () => {
        const x = itemsToLeftRef.current * zoomWidth;

        timelineRef.current.scrollLeft = x;
      }
    };

    return () => (innerRef.current = null);
  }, [innerRef, zoomWidth]);

  const { HeaderComponent, disableHolidays, disableWeekends } = config;

  const virtualInfo = useTimelineVirtualInfoMemo(
    periodState && periodState.periods,
    times
  );

  return (
    <TimelineContext.Provider value={timelineValue}>
      <TimelineLayoutStateContext.Provider value={isLayoutActive}>
        <TimelineKinectProvider value={kinectRef}>
          <BarLoader isLoading={loading || loadingHolidays || loadingUsers} />
          <div
            ref={timelineRef}
            className="ar-timeline w-100"
            onScroll={handleScroll}
          >
            <div className="position-relative min-h-100 d-inline-flex flex-column">
              <Grid
                disableWeekends={disableWeekends}
                disableHolidays={disableHolidays}
                virtualInfo={virtualInfo}
                nowMilisec={nowMilisec}
                holidays={holidays}
              />
              <HeaderComponent
                holidays={holidays}
                virtualInfo={virtualInfo}
                nowMilisec={nowMilisec}
              />
              <FilterColumnColorProvider>
                <TimelineRows
                  subscribeScrollListner={subscribeScrollListner}
                  timelineRef={timelineRef}
                  requestFilters={requestFilters}
                  requestCallbacks={requestCallbacks}
                  periods={periodState && periodState.periods}
                  nowMilisec={nowMilisec}
                  now={now}
                  itemsToLeft={itemsToLeftRef.current}
                  // weeksToRight={weeksToRightRef.current}
                  users={users}
                  times={times}
                />
              </FilterColumnColorProvider>
            </div>
          </div>
        </TimelineKinectProvider>
      </TimelineLayoutStateContext.Provider>
    </TimelineContext.Provider>
  );
};
