import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef
} from "react";
import { GridRequestContext, useGridRequest } from "../CGrid/ServerGrid";
import { ColumnResizerCommunicator } from "../CGrid/CGrid";
import VirtualGrid from "./VirtualGrid";
import classnames from "classnames";
import { BarLoader } from "../GlobalLoader/GlobalLoader";
import { PageSizeSelect } from "../CGrid/PageSizeSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/pro-light-svg-icons";
import "../CGrid/CGrid.css";
import { ServerAwareGroupedGrid } from "./ServerAwareGroupedGrid";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import querystring from "query-string";
import {
  ServerAwareUpdateTempViewsContext,
  ServerAwareViewsContext
} from "../CGrid/ServerAwareViews";
import { useSpace } from "../../Contexts/SpaceContext";
import SuspenseLoader from "../SuspenseLoader/SuspenseLoader";
import { ServerAwareFavoritesContext } from "../../Containers/AppBar/ServerAwareFavorite";
import { useFavoriteOrViewChecker } from "../../Containers/AppBar/AppBarHelper";
import { AppBarFavoriteTreeSetter } from "../../Containers/AppBar/AppBarFavoriteQuery";
import { useResolvedColumns } from "../ColumnChooser/ColumnChooserHelper";
import { useCheckIfInSettings } from "../../Containers/Settings/SettingsHelper";
import Button from "../Button/Button";
import { TotalsContext } from "../../Helpers/GridHelper";

// const getLocalStorageSize = (schema, currentView, views) => {
//   const index = views.findIndex((e) => e.id === currentView);
//   return index !== -1 ? views[index].columnSize : views[0].columnSize;
// };

export const useFavoriteColumnSizing = () => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const { columnSizes } = FavoriteItem.data;
  const setTree = useContext(AppBarFavoriteTreeSetter);
  const handleColumnSizesChange = useCallback(
    (result) => {
      setTree((oldTree) => {
        const newTree = { ...oldTree };
        const { rootId, items } = newTree;
        const newItems = { ...items };
        const newItem = { ...newItems[FavoriteItem.id] };
        const newData = { ...newItem.data };
        newData.columnSizes = result;
        newItem.data = newData;
        newItems[FavoriteItem.id] = newItem;
        return {
          rootId,
          items: newItems
        };
      });
    },
    [FavoriteItem.id, setTree]
  );

  return { handleColumnSizesChange, columnSizes };
};

const getStickyDict = (stickyColumns) => {
  const dict = {};
  for (const field of stickyColumns) {
    dict[field] = true;
  }
  return dict;
};

const getfieldTitleDict = (Columns) => {
  const result = {};
  for (const c of Columns) {
    result[c.field] = { ...c };
  }
  return result;
};

export const useFavoriteActiveColumns = (columns) => {
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const { orderedFields, stickyFields } = FavoriteItem.data;
  const resolvedColumns = useMemo(() => {
    const resolvedcolumnLayout = orderedFields || [];
    const resolvedstickyLayout = stickyFields || [];
    const fieldDict = getfieldTitleDict(columns);

    const result = [];

    if (resolvedcolumnLayout.length === 0) {
      for (const col of columns) {
        result.push({ ...col });
      }
    } else {
      for (const c of resolvedcolumnLayout) {
        if (fieldDict[c]) result.push(fieldDict[c]);
      }
    }
    if (resolvedstickyLayout.length !== 0) {
      const StickyDict = getStickyDict(resolvedstickyLayout);
      for (const column of result) {
        const { field } = column;
        const isSticky = Boolean(StickyDict[field]);
        column.sticky = isSticky;
      }
    }

    return result;
  }, [columns, orderedFields, stickyFields]);
  return resolvedColumns;
};

export const useColumnSizing = (schema) => {
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const lsViews = useContext(ServerAwareViewsContext);
  const updateLsViews = useContext(ServerAwareUpdateTempViewsContext);
  const space = useSpace();
  const index = lsViews?.findIndex((e) => e.id === viewPref);

  const columnSizes = useMemo(() => {
    if (index !== -1 && lsViews) {
      return lsViews[index]?.columnSize || {};
    } else if (lsViews) return lsViews[0]?.columnSize || {};
    else return {};
  }, [index, lsViews]);

  // const columnSizes =
  //   index !== -1 && lsViews
  //     ? lsViews[index].columnSize
  //     : lsViews
  //     ? lsViews[0] && lsViews[0].columnSize
  //     : {};

  // const [columnSizes, setColumnSizes] = useState(() => {
  //   return getLocalStorageSize(schema, view, lsViews);
  // });

  const handleColumnSizesChange = useCallback(
    (result) => {
      // let gridColumnLayout = JSON.parse(
      //   window.localStorage.getItem("ar-grid-columns-size")
      // );
      // if (gridColumnLayout) {
      //   gridColumnLayout[schema.name] = result;
      //   localStorage.setItem(
      //     "ar-grid-columns-size",
      //     JSON.stringify(gridColumnLayout)
      //   );
      // } else {
      //   const gridColumnLayout = { [schema.name]: result };
      //   localStorage.setItem(
      //     "ar-grid-columns-size",
      //     JSON.stringify(gridColumnLayout)
      //   );
      // }

      // if (view) {
      //   const Lsindex = lsViews.findIndex((e) => e.id === view);
      //   if (Lsindex !== -1) {
      //     let gridSavedViews = JSON.parse(
      //       window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
      //     );
      //     const viewGridViews = gridSavedViews[schema.name];
      //     const index = viewGridViews.findIndex((e) => e.id === view);
      //     viewGridViews[index].columnSize = result;
      //     gridSavedViews[schema.name] = viewGridViews;
      //     localStorage.setItem(
      //       `ar-grid-saved-views-space-${space.Id}`,
      //       JSON.stringify(gridSavedViews)
      //     );
      //     updateLsViews();
      //   // }
      // }
      let gridSavedViews = JSON.parse(
        window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
      );

      const viewGridViews = gridSavedViews[schema.name];
      const index = viewGridViews.findIndex((e) => e.id === viewPref);
      let resolvedIndex = index === -1 ? 0 : index;
      let viewGridObject = viewGridViews[resolvedIndex] || {};
      viewGridObject.columnSize = result;
      viewGridViews[resolvedIndex] = viewGridObject;
      gridSavedViews[schema.name] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
      updateLsViews && updateLsViews();

      // setColumnSizes(getLocalStorageSize(schema));
    },
    [schema.name, space.Id, updateLsViews, viewPref]
  );

  useEffect(() => {
    return ColumnResizerCommunicator.subscribe(() => {
      let gridSavedViews = JSON.parse(
        window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
      );
      let viewGridViews = gridSavedViews[schema.name];
      let index = viewGridViews.findIndex((e) => e.id === viewPref);
      let resolvedIndex = index === -1 ? 0 : index;
      let viewGridObject = viewGridViews[resolvedIndex] || {};
      viewGridObject.columnSize = {};
      viewGridViews[resolvedIndex] = viewGridObject;
      gridSavedViews[schema.name] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
    });
  }, [columnSizes, lsViews, schema.name, space.Id, viewPref]);

  return { handleColumnSizesChange, columnSizes };
};

export const TotalGridItemsWidget = ({ totalItems, pageSize, currentPage }) => {
  const value = useGridRequest();
  const totalsVal = useContext(TotalsContext);
  const intl = useIntl();

  const handleRefetcher = useCallback(() => {
    if (totalsVal?.refetch) totalsVal.refetch();
    if (value?.refetch) value.refetch();
  }, [totalsVal, value]);

  if (!totalItems || !pageSize || !currentPage) return <div />;

  const firstEntry = pageSize * (currentPage - 1) + 1;
  let secondEntry = pageSize * currentPage;
  if (secondEntry > totalItems) secondEntry = totalItems;

  const text = `${firstEntry} - ${secondEntry} ${intl.formatMessage({
    id: "OF"
  })} ${totalItems} ${intl.formatMessage({ id: "ITEMS" })}`;

  return (
    <div className="fs-14">
      <span className="text-gray-darker mr-2">{text}</span>
      {value && (
        <Button
          vType="link-primary"
          className="h-auto"
          onClick={handleRefetcher}
        >
          <FontAwesomeIcon size="sm" icon={faSync} />
        </Button>
      )}
    </div>
  );
};

const GridFooter = React.memo(
  ({
    currentPage,
    pages,
    pageSize,
    disablePageSelector,
    count,
    changePage
  }) => {
    const firstPageClick = () => {
      changePage(1);
    };

    const prevPageClick = () => {
      changePage(currentPage - 1 || 1);
    };

    const nextPageClick = () => {
      const nextPage = currentPage + 1;
      if (nextPage > pages) return;

      changePage(nextPage);
    };

    const lastPageClick = () => {
      changePage(pages);
    };

    const viewportPages = useMemo(() => {
      if (!pages) return [];

      let minPage = currentPage - 2;
      if (minPage < 1) minPage = 1;

      let maxPage = currentPage + 2;

      if (maxPage > pages) maxPage = pages;

      const pagesArr = [];

      for (let i = minPage; i <= maxPage; i++) {
        pagesArr.push(i);
      }
      return pagesArr;
    }, [currentPage, pages]);

    return (
      <div className="c-grid-footer d-flex justify-content-between align-items-center">
        <div />
        <div>
          <div
            className="c-grid-page c-grid-backward "
            onClick={firstPageClick}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill="currentColor"
                d="M64 468V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v176.4l195.5-181C352.1 22.3 384 36.6 384 64v384c0 27.4-31.9 41.7-52.5 24.6L136 292.7V468c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12z"
              />
            </svg>
          </div>
          <div className="c-grid-page " onClick={prevPageClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
              <path
                fill="currentColor"
                d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"
              />
            </svg>
          </div>
          {viewportPages.map((p, i) => (
            <div
              onClick={() => changePage(p)}
              key={p}
              className={
                "c-grid-page" + (currentPage === p ? " c-current-page" : "")
              }
            >
              {p}
            </div>
          ))}
          <div className="c-grid-page " onClick={nextPageClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
              <path
                fill="currentColor"
                d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
              />
            </svg>
          </div>
          <div
            className="c-grid-page c-grid-forward "
            onClick={(e) => {
              lastPageClick(pages);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill="currentColor"
                d="M384 44v424c0 6.6-5.4 12-12 12h-48c-6.6 0-12-5.4-12-12V291.6l-195.5 181C95.9 489.7 64 475.4 64 448V64c0-27.4 31.9-41.7 52.5-24.6L312 219.3V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12z"
              />
            </svg>
          </div>
          {!disablePageSelector && (
            <div className="c-grid-pagesize text-primary">
              <PageSizeSelect className="c-grid-page-selector bg-white" />
              {/* <FormattedMessage id={"PER_PAGE"} /> */}
            </div>
          )}
        </div>

        <TotalGridItemsWidget
          totalItems={count}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      </div>
    );
  }
);

const VirtualServerAwareGrid = (props) => {
  const result = useFavoriteOrViewChecker();
  if (result) return <VirtualServerAwareFavoriteGrid {...props} />;
  return <VirtualServerAwareViewGrid {...props} />;
};

const VirtualServerAwareViewGrid = (props) => {
  const { columns, noSelect } = props;
  const { schema, gridSchema } = useContext(GridRequestContext);
  const resolvedColumns = useResolvedColumns(columns, schema.name);
  const isSettings = useCheckIfInSettings();
  const resolvedColumnsWithSelect = useMemo(() => {
    if (isSettings || noSelect) return resolvedColumns;
    else
      return [
        {
          selectable: true,
          sticky: true,
          identifier: "Id",
          orderBy: false,
          sortable: false,
          noTitle: true,
          field: "select",
          title: "Selecionar",
          width: 60,
          minimumWidth: 60,
          TotalCell: () => (
            <div className="fw-medium fs-14">
              <FormattedMessage id="TOTALS" />
            </div>
          )
        },
        ...resolvedColumns
      ];
  }, [isSettings, noSelect, resolvedColumns]);

  const { columnSizes, handleColumnSizesChange } = useColumnSizing(
    gridSchema || schema
  );

  return (
    <VirtualServerAwareGridComp
      {...props}
      columns={resolvedColumnsWithSelect}
      columnSizes={columnSizes}
      handleColumnSizesChange={handleColumnSizesChange}
    />
  );
};
const VirtualServerAwareFavoriteGrid = (props) => {
  const { columnSizes, handleColumnSizesChange } = useFavoriteColumnSizing();
  const { columns, noSelect } = props;
  const resolvedColumns = useFavoriteActiveColumns(columns);

  const resolvedColumnsWithSelect = useMemo(() => {
    if (noSelect) return resolvedColumns;
    return [
      {
        selectable: true,
        sticky: true,
        identifier: "Id",
        orderBy: false,
        sortable: false,
        noTitle: true,
        field: "select",
        title: "Selecionar",
        width: 60,
        minimumWidth: 60,
        TotalCell: () => (
          <div className="fw-medium fs-14">
            <FormattedMessage id="TOTALS" />
          </div>
        )
      },
      ...resolvedColumns
    ];
  }, [noSelect, resolvedColumns]);
  return (
    <VirtualServerAwareGridComp
      {...props}
      columns={resolvedColumnsWithSelect}
      columnSizes={columnSizes}
      handleColumnSizesChange={handleColumnSizesChange}
    />
  );
};

const VirtualServerAwareGridComp = (props) => {
  const {
    params,
    onParamsChange,
    isLoading,
    results,
    currentPage,
    pages,
    pageSize,
    count,
    schema,
    // gridSchema,
    toggleColumnChooser
  } = useContext(GridRequestContext);

  const {
    className,
    columns,
    rowComponent,
    selectedItems,
    onSelectedItemsChange,
    showTotals,
    groupByColumnDict,
    disableResize,
    columnSizes,
    handleColumnSizesChange
  } = props;
  // const resolvedColumns = useTempColumns(columns);
  const updateParams = useCallback(
    (newParams) => {
      onParamsChange((params) => {
        return {
          ...params,
          ...newParams
        };
      });
    },
    [onParamsChange]
  );

  const handlePageChange = useCallback(
    (page) => {
      //   ;
      const { top: pageSize } = params;

      // this.setState({
      //   currentPage: page
      // });

      updateParams({
        skip: pageSize * (page - 1)
      });
    },
    [params, updateParams]
  );

  const { $orderBy, groupBy } = params;

  const [tempOrderBy, setTempOrderBy] = useState($orderBy);

  const orderTimeoutRef = useRef();
  const handleOrderChange = useCallback(
    (data) => {
      setTempOrderBy(data);
      clearTimeout(orderTimeoutRef.current);

      orderTimeoutRef.current = setTimeout(() => {
        let newParams = {
          $orderBy: data
        };
        updateParams(newParams);
      }, 600);
    },
    [updateParams]
  );

  useEffect(() => {
    setTempOrderBy($orderBy);
  }, [$orderBy]);

  if (!results && isLoading)
    return <SuspenseLoader className="text-primary flex-1" />;

  if (groupBy.length > 0) {
    return (
      <div
        className={classnames(
          className,
          "d-flex of-hidden w-100 h-100 position-relative"
        )}
      >
        <div className="d-flex flex-column h-100 w-100">
          <BarLoader isLoading={isLoading} />
          <div className="w-100 h-100 of-hidden">
            <ServerAwareGroupedGrid
              groupByColumnDict={groupByColumnDict}
              schema={schema}
              showTotals={showTotals}
              selectedItems={selectedItems}
              onSelectedItemsChange={onSelectedItemsChange}
              onSort={handleOrderChange}
              sort={tempOrderBy}
              height={600}
              className="bg-white flex-1 p-2 w-100 border ar-main-grid"
              columnsSize={columnSizes}
              onColumnsSizeChange={handleColumnSizesChange}
              isLoading={isLoading}
              groupBy={groupBy}
              RowComponent={rowComponent}
              columns={columns}
              data={results}
            />
          </div>
          <GridFooter
            changePage={handlePageChange}
            currentPage={currentPage}
            pages={pages}
            pageSize={pageSize}
            count={count}
          />
        </div>
      </div>
    );
  } else
    return (
      <div
        className={classnames(
          className,
          "d-flex of-hidden w-100 h-100 position-relative"
        )}
      >
        <BarLoader style={{ zIndex: 5 }} isLoading={isLoading} />
        <div className="d-flex flex-column w-100">
          <VirtualGrid
            onToggleColumnChooser={toggleColumnChooser}
            disableResize={disableResize}
            showTotals={showTotals}
            //sortable
            // schemaName={schema.name}
            // getRowKey={getRowKey}
            selectedItems={selectedItems}
            onSelectedItemsChange={onSelectedItemsChange}
            onSort={handleOrderChange}
            sort={tempOrderBy}
            height={50}
            className="m-h-100 flex-1 w-100 ar-main-grid"
            // serverFiltering={serverFiltering}
            columnsSize={columnSizes}
            onColumnsSizeChange={handleColumnSizesChange}
            // messages={{
            //   today: intl.formatMessage({ id: "today" }),
            //   yesterday: intl.formatMessage({ id: "yesterday" })
            // }}
            isLoading={isLoading}
            // filter={filter}
            groupBy={groupBy}
            RowComponent={rowComponent}
            // page={currentPage}
            // pageSize={pageSize}
            // totalItems={count}
            // headerScroll={false}
            //   change={data => console.log(data)}
            columns={columns}
            // onPageChange={handlePageChange}
            data={results}
            // getValue={getValue}
          />
          <GridFooter
            changePage={handlePageChange}
            currentPage={currentPage}
            pages={pages}
            pageSize={pageSize}
            count={count}
          />
        </div>
      </div>
    );
};

export default VirtualServerAwareGrid;
