import React, { Component } from "react";
import "./SpentHoursPreview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { faEquals } from "@fortawesome/pro-light-svg-icons";
import { MilSecToFormatedHourMinute, MilSecToFormatedTime, MilSecToHour } from "../../Helpers/MiscHelper";

class SpentHoursPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {

    let SpentHours = this.props?.SpentHours || 0;
    let EstimatedHours = this.props?.EstimatedHours || 0;

    if (isNaN(EstimatedHours))
      return <div className="mr-2">{Math.ceil(SpentHours)}</div>;

    // if (EstimatedHours === 0) return null;

    let isOnTime, isEqual, isDone;
    if (SpentHours === 0) isEqual = true;
    // else if (SpentHours === EstimatedHours) isDone = true;
    else if (SpentHours <= EstimatedHours) isOnTime = true;
    else isOnTime = false;

    let percentage;
    if (isEqual) percentage = 0;
		else if(EstimatedHours === 0) {	
			percentage = MilSecToHour(SpentHours, true) + 100
		}
    else percentage = Math.ceil((SpentHours * 100) / EstimatedHours);

    if (percentage >= 101) percentage -= 100;

    return (
      <div className="d-flex align-items-center">
        {/* <div className="mr-2">{SpentHours}</div> */}
        {!this.props.onlyPercentage && (
          <div>{MilSecToFormatedHourMinute(SpentHours)}</div>
        )}
        {!this.props.onlyHours && (
          <div
            className={classnames(
              "ml-2 px-2 py-1 ar-spent-hours-preview d-flex align-items-center justify-content-center",
              {
                negative: isOnTime === false,
                equal: isEqual,
								isDone: isDone,
              }
            )}
          >
            <div>{percentage}%</div>
          </div>
        )}
      </div>
    );
  }
}

export default SpentHoursPreview;
