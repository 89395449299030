import React, { useState, useEffect, useMemo, useContext } from "react";
import { useCurrentAccount } from "../../Contexts/UserContext";
// import { getCurrentUserAuthToken } from "../../Helpers/AuthHelper";
import { sendSignalRMessage } from "./RealTime";
import { ConnectionStatusEnum } from "./RealTimeHelper";
import {
  useUserEntitiesHub,
  useUserConnectionStatus,
  useUserReconnect
} from "./RealTimeUser";

const SpaceConnectionStatusContext = React.createContext();

const SpaceReconnectContext = React.createContext();

const SpaceEntitiesHubContext = React.createContext();

export const useSpaceEntitiesHub = () => useContext(SpaceEntitiesHubContext);

export const useSpaceConnectionStatus = () =>
  useContext(SpaceConnectionStatusContext);

export const useSpaceReconnect = () => useContext(SpaceReconnectContext);

export const SpaceRealTimeProvider = ({ spaceId, children }) => {
  const entitiesHub = useUserEntitiesHub();

  // const [loading, setLoading] = useState(true);

  // const [hadError, setHadError] = useState(false);

  const connectionStatus = useUserConnectionStatus();

  const spaceConnectionStatus = useMemo(() => {
    // if (hadError) return ConnectionStatusEnum.spaceError;
    // if (loading) return ConnectionStatusEnum.loading;

    return connectionStatus;
  }, [connectionStatus]);

  const [retryCount, setRetryCount] = useState(0);
  const user = useCurrentAccount();

  useEffect(() => {
    if (!entitiesHub || !spaceId) return;

    // const tkn = getCurrentUserAuthToken();
    // let hasChanged = false;
    // setLoading(true);
    // setHadError(false);

    // sendSignalRMessage(
    //   entitiesHub,
    //   "SetUserId",
    //   user.Id,
    //   //tkn,
    //   spaceId,
    //   () => {
    //     if (!hasChanged) {
    //       setLoading(false);
    //     }
    //   },
    //   () => {
    //     setLoading(false);
    //     setHadError(true);
    //   }
    // );

    // sendSignalRSimple(
    //   entitiesHub,
    //   "ChooseSpace",
    //   spaceId,
    //   () => {
    //     if (!hasChanged) {
    //       setLoading(false);
    //     }
    //   },
    //   () => {
    //     if (!hasChanged) {
    //       setLoading(false);
    //       setHadError(true);
    //     }
    //   }
    // );

    // entitiesHub
    //   .invoke("ChooseSpace", spaceId)
    //   .done(() => {
    //     if (!hasChanged) {
    //       setLoading(false);
    //     }
    //   })
    //   .fail(() => {
    //     if (!hasChanged) {
    //       setLoading(false);
    //       setHadError(true);
    //     }
    //   });

    // const listener = () => {
    //   sendSignalRMessage(
    //     entitiesHub,
    //     "SetUserId",
    //     user.Id,
    //     // tkn,
    //     spaceId,
    //     () => {
    //       setLoading(false);
    //     },
    //     () => {
    //       setLoading(false);
    //       setHadError(true);
    //     }
    //   );
    // };

    // entitiesHub.on("RunEverythingAgain", listener);

    const RespondPing = () => {
      sendSignalRMessage(
        entitiesHub,
        "SendPong",
        user.Id,
        // tkn,
        spaceId,
        () => {
          //setLoading(false);
        },
        () => {
          // setLoading(false);
          // setHadError(true);
        }
      );
    };

    entitiesHub.on("PingMeNow", RespondPing);

    return () => {
      // entitiesHub.off("RunEverythingAgain", listener);
      entitiesHub.off("PingMeNow", RespondPing);
      // hasChanged = true;
    };
  }, [entitiesHub, spaceId, retryCount, user.Id]);

  // useEffect(() => {

  // 	 if (!entitiesHub) return;
  // 	 const listner = (type, text, textId) => {

  // 		 const translated_Id = BackgroundJobsTypeIdEnum[parseInt(textId)];

  // 		 createToast({
  // 			 pos: "tm",
  // 			 type: type,
  // 			 description: `${intl.formatMessage({ id: translated_Id })}`
  // 		 });
  // 	 };

  // 	 entitiesHub.on("APINotification", listner);

  // 	 return () => entitiesHub.off("APINotification", listner);
  //  }, [createToast, entitiesHub, intl]);

  const reconnect = useUserReconnect();

  const spaceReconnect = useMemo(() => {
    if (reconnect) return reconnect;

    // if (hadError) {
    //   return () => {
    //     setRetryCount((c) => c + 1);
    //   };
    // }
  }, [reconnect]);

  // const SpaceEntitiesHub = !loading && !hadError ? entitiesHub : undefined;
  const SpaceEntitiesHub = entitiesHub;

  return (
    <SpaceConnectionStatusContext.Provider value={spaceConnectionStatus}>
      <SpaceReconnectContext.Provider value={spaceReconnect}>
        <SpaceEntitiesHubContext.Provider value={SpaceEntitiesHub}>
          {children}
        </SpaceEntitiesHubContext.Provider>
      </SpaceReconnectContext.Provider>
    </SpaceConnectionStatusContext.Provider>
  );
};
