import React, { useMemo } from "react";
import { useDetailsEntityId } from "../../../Components/DetailsView/DetailsView";
import { useIntervention } from "../../../Hooks/EntityHooks";
import {
  DetailsEntities,
  DetailsClientEntity
} from "../../../Components/DetailsView/DetailsChildren/DetailsChildren";

export const InterventionDetailsChildren = () => {
  const entityId = useDetailsEntityId();
  const formProps = useMemo(() => {
    return { interventionId: entityId };
  }, [entityId]);

  const { Client, IndirectClient } = useIntervention(entityId) || {};

  return (
    <DetailsEntities formProps={formProps}>
      <DetailsClientEntity id={Client || IndirectClient} />
    </DetailsEntities>
  );
};
