import React from "react";

import { Switch, Route, withRouter } from "react-router-dom";
// import {
//   RealTimePageProvider,
//   RealTimePageEnum,
// } from "../RealTime/RealTimePages";
import AutomationDetails from "./AutomationDetails/AutomationDetails";
import AutomationOverview from "./AutomationOverview/AutomationOverview";
// import CallDetails from "./CallDetails/CallDetails";

const Automation = withRouter(({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/:automationId`}>
        {/* <CallDetails /> */}
        <AutomationDetails />
      </Route>
      <Route>
        {/* <RealTimePageProvider pageType={RealTimePageEnum.Automation}> */}
          <AutomationOverview />
        {/* </RealTimePageProvider> */}
      </Route>
    </Switch>
  );
});

export default Automation;
