import React, { useMemo, useState } from "react";
import "./EntityPopup.css";
// import { useEntityValue } from "../../Hooks/EntityHooks";
import { useSpace } from "../../Contexts/SpaceContext";
import { getEntity, useQuery } from "../../Helpers/IOClient";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { buildEntityOptions } from "./EntityPopupHelper";
import EntityPopupTreeview from "./EntityPopupTreeview";
import {
  DetailsEntityContext,
  DetailsEntityIdContext
} from "../../Components/DetailsView/DetailsView";
import { FormattedMessage } from "react-intl";
// import { usePopperResize } from "../../Components/Popper/Popper";

const EntityPopupDetails = ({ item }) => {
  const { schema, Id } = item || {};
  const { DetailsComponent, IconComponent } = useMemo(
    () => buildEntityOptions(schema),
    [schema]
  );

  const entity = getEntity(schema, Id);

  const space = useSpace();

  const url = useMemo(() => {
    if (!item) return null;
    return `${schema?.getEndpoint(space.Id)}/${Id}`;
  }, [Id, item, schema, space.Id]);

  const { data, error } = useQuery(url, schema);

  // const { Name, NoTagDescription } = entity;

  // usePopperResize();
  // const { IconComponent } = useMemo(() => buildEntityOptions(schema), [schema]);

  if (!item) {
    return (
      <div className="ar-entity-popup-details" style={{ width: 520 }}>
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <LoaderSpinner size="sm" className="text-primary" />
        </div>
      </div>
    );
  }

  if (error && error.status === 403)
    return (
      <div
        className="ar-entity-popup-details d-flex align-items-center justify-content-center flex-column"
        style={{ width: 520 }}
      >
        <b className="mb-2 text-warning">
          <FormattedMessage id="API_NOT_FOUND_OR_NO_PERMISSIONS_VIEW" />
        </b>
        <div className="mb-1">
          <FormattedMessage id="PLEASE_CONTACT_YOUR_ADMIN" />
        </div>
        <div className="text-truncate text-black">
          <IconComponent />
          {/* <span className="ml-2 text-truncate">{Name || NoTagDescription}</span> */}
        </div>
      </div>
    );

  if (!data)
    return (
      <div className="ar-entity-popup-details" style={{ width: 520 }}>
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <LoaderSpinner size="sm" className="text-primary" />
        </div>
      </div>
    );

  return (
    <div className="ar-entity-popup-details">
      <DetailsEntityIdContext.Provider value={Id}>
        <DetailsEntityContext.Provider value={entity}>
          {DetailsComponent && (
            <DetailsComponent value={entity} forceOverview />
          )}
        </DetailsEntityContext.Provider>
      </DetailsEntityIdContext.Provider>
    </div>
  );
};

const EntityPopup = ({ schema, id, forcedType }) => {
  const [item, setItem] = useState();
  // 	{
  //   schema,
  //   Id: id
  // }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onDoubleClick={(e) => e.stopPropagation()}
      className="bg-white rounded"
      style={{ height: 400 }}
    >
      <div className="h-100 d-flex">
        <EntityPopupTreeview
          forcedType={forcedType}
          onItemChange={setItem}
          id={id}
          schema={schema}
        />
        <EntityPopupDetails item={item} />
      </div>
    </div>
  );
};

export default EntityPopup;
