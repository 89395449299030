import React from "react";
import AirdeskTextSvg from "../../../Components/Svg/AirdeskTextSvg";
import "./MaintenanceView.css";

const MaintenanceView = () => {
  return (
    <React.Fragment>
      {/* <OnboardingHeader /> */}
      <div className="page d-flex flex-column justify-content-center align-items-center w-100 text-black">
        <div className="ar-maintenance text-center">
          <AirdeskTextSvg className="mw-290px text-secondary mb-4" />
          <div className="text-orange fs-32 fw-bold mb-2">Em Manutenção…</div>
          <div>Estamos a executar uma manutenção planeada.</div>
          <div>
            Pedimos desculpa pelo inconveniente, por favor volte mais tarde.
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MaintenanceView;
