import React from "react";
import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import UserAlterEgoButton from "../../Components/UserAlterEgoButton/UserAlterEgoButton";
import {
  useCurrentAccount,
  useCurrentAccountSpace,
} from "../../Contexts/UserContext";
import { LabelTitle } from "../../Helpers/GridHelper";
import {
  canUserEditModule,
  isDeveloper,
  moduleTypes,
} from "../../Helpers/ModulesHelper";
import {
  CallFormButton,
  TaskFormButton,
  TicketFormButton,
} from "../Form/FormButtons";

export const AccountEntityButtons = ({ entity, skeleton, onSuccess }) => {
  const thisAccount = useCurrentAccount();
  const AccountSpace = useCurrentAccountSpace();

  const developer = isDeveloper(thisAccount);
  return (
    <React.Fragment>
      {developer && (
        <UserAlterEgoButton className="mr-3 ml-1" canEdit userId={entity.Id} />
      )}
      {canUserEditModule(AccountSpace, moduleTypes) && (
        <CallFormButton
          formProps={{ defaultForm: { ManagerAccount: entity.Id } }}
          alt
          className="ssi-button-primary-ghost mx-1"
          onSuccess={onSuccess}
          skeleton={skeleton}
        />
      )}
      {canUserEditModule(AccountSpace, moduleTypes.tickets) && (
        <TicketFormButton
          formProps={{ defaultForm: { ManagerAccount: entity.Id } }}
          onSuccess={onSuccess}
          skeleton={skeleton}
          alt
          className="ssi-button-primary-ghost mx-1"
        />
      )}
      {canUserEditModule(AccountSpace, moduleTypes.tasks) && (
        <TaskFormButton
          formProps={{ defaultForm: { Manager: entity.Id } }}
          onSuccess={onSuccess}
          skeleton={skeleton}
          className="ssi-button-primary-ghost mx-1"
          alt
        />
      )}
    </React.Fragment>
  );
};

export const AccountEntityActionsButton = ({
  Account,
  entity,
  minimal,
  sidebar,
  developer,
}) => {
  const value = Account || entity;
  const AccountSpace = useCurrentAccountSpace();
  return (
    <>
      {developer && (
        <UserAlterEgoButton
          minimal={minimal}
          className="mr-3 ml-1"
          canEdit
          userId={value.Id}
        />
      )}
      <EntityPopup minimal={minimal}>
        <LabelTitle title="Serviços" />
        {canUserEditModule(AccountSpace, moduleTypes.calls) && (
          <CallFormButton
            formProps={{ defaultForm: { ManagerAccount: value.Id } }}
          />
        )}
        {canUserEditModule(AccountSpace, moduleTypes.tasks) && (
          <TaskFormButton formProps={{ defaultForm: { Manager: value.Id } }} />
        )}
        {canUserEditModule(AccountSpace, moduleTypes.tickets) && (
          <TicketFormButton
            formProps={{ defaultForm: { ManagerAccount: value.Id } }}
          />
        )}
      </EntityPopup>
    </>
  );
};
