import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useSidebar } from "../../../Components/Sidebar/SidebarV2";
import {
  BaseSidebarContainer,
  BaseSidebarContent,
  BaseSidebarHeader
} from "../../../Components/Sidebar/SidebarV2Helper";
import { spaceAccountExportsSchema } from "../../../config/schema";
import { useSpaceQuery } from "../../../Helpers/IOClient";

import RelativeTime from "../../../Components/RelativeTime/RelativeTime";
import {
  ExportButton,
  ExportHistoryTypeEnumDict,
  ExportHistoryViewTypeEnumDict
} from "./ExportsGrid";
import Table, { Tr } from "../../../Components/Table";
import { useAccountExport } from "../../../Hooks/EntityHooks";
import Moment from "react-moment";
import Button from "../../../Components/Button/Button";
import { useHistory } from "react-router-dom";
import { useSpace } from "../../../Contexts/SpaceContext";
import { ServerAwareContext } from "../../../Components/CGrid/ServerGrid";
import { DashboardEmptyResponse } from "../../Dashboard/Dashboard";

const columns = [
  {
    sortable: false,
    field: "Unread",
    title: " ",
    width: 10,
    Cell: ({ IsRead }) => {
      if (!IsRead)
        return <div className="bg-blue" style={{ width: 5, height: 43 }}></div>;
      else return <div></div>;
    }
  },

  {
    sortable: false,
    width: 50,
    field: "ExportFile",
    // title: <FormattedMessage id={"FILE"} />,
    Cell: ({
      ExportType,
      ExportFile,
      PathFile,
      BeginDateInterval,
      EndDateInterval,
      Id
    }) => {
      return (
        <ExportButton
          id={Id}
          ExportType={ExportType}
          url={ExportFile}
          BeginDateInterval={BeginDateInterval}
          EndDateInterval={EndDateInterval}
          PathFile={PathFile}
        />
      );
    }
  },
  {
    sortable: false,
    width: 180,
    field: "Date",
    title: <FormattedMessage id={"DATE"} />,
    Cell: ({ Date }) => <RelativeTime date={Date} />
  },
  {
    sortable: false,
    width: 74,
    field: "ExportQuantity",
    title: <FormattedMessage id={"QUANTITY"} />,
    Cell: ({ Success, FullRequest, ExportQuantity }) => (
      <div className="d-flex justify-content-end align-items-center">
        {!Success && FullRequest ? "―" : ExportQuantity}
      </div>
    )
  },
  {
    sortable: false,
    width: 100,
    field: "ExportType",
    title: <FormattedMessage id={"TYPE"} />,
    Cell: ({ ExportType }) => ExportHistoryTypeEnumDict[ExportType] || null
  },
  {
    sortable: false,
    width: 150,
    field: "ViewType",
    title: <FormattedMessage id={"VIEW"} />,
    Cell: ({ ViewType }) => ExportHistoryViewTypeEnumDict[ViewType] || null
  },
  {
    sortable: false,
    width: 124,
    field: "BeginDateInterval",
    title: <FormattedMessage id={"START_DATE"} />,
    Cell: ({ BeginDateInterval }) =>
      BeginDateInterval ? (
        <Moment
          className="text-black"
          date={BeginDateInterval}
          format="DD/MM/YYYY HH:mm"
        />
      ) : (
        <div>―</div>
      )
  },
  {
    sortable: false,
    width: 124,
    field: "EndDateInterval",
    title: <FormattedMessage id={"END_DATE"} />,
    Cell: ({ EndDateInterval }) =>
      EndDateInterval ? (
        <Moment
          className="text-black"
          date={EndDateInterval}
          format="DD/MM/YYYY HH:mm"
        />
      ) : (
        <div>―</div>
      )
  },
  {
    sortable: false,
    width: 80,
    field: "QuantityType",
    title: <FormattedMessage id={"EXPORT_QUANTITY_TYPE"} />,
    Cell: ({ FullRequest }) => {
      if (FullRequest) {
        return <FormattedMessage id={"EXPORT_QUANTITY_TYPE_COMPLETE"} />;
      } else {
        return <FormattedMessage id={"EXPORT_QUANTITY_TYPE_PARCIAL"} />;
      }
    }
  }
];

const ExportTr = React.memo(({ value, ...rest }) => {
  const Item = useAccountExport(value);
  return <Tr value={Item} {...rest} />;
});

const rSchema = [spaceAccountExportsSchema];

const PersonalExportSidebar = () => {
  const [, closeSidebar] = useSidebar();
  const history = useHistory();
  const { Id: spaceId } = useSpace();
  const handleViewAll = () => {
    history.push(`/s/${spaceId}/account/exports`);
    closeSidebar();
  };

  const { data, loading, error, refetch } = useSpaceQuery(
    `Export/User?$orderby=Id%20desc&$top=50`,
    rSchema,
    { cache: false }
  );

  const contexValue = useMemo(() => {
    return { refetch };
  }, [refetch]);

  const intl = useIntl();
  return (
    <ServerAwareContext.Provider value={contexValue}>
      <BaseSidebarContainer width={900}>
        <BaseSidebarHeader>
          <FormattedMessage id="EXPORTS" />
        </BaseSidebarHeader>
        <BaseSidebarContent className="w-100">
          {(loading || error) && (
            <div className="w-100 h-100">
              <LoaderSpinner className="text-primary" size="sm" center />
            </div>
          )}
          {data && data.length === 0 && (
            <DashboardEmptyResponse type="interventions" className="h-100">
              <FormattedMessage id={"EMPTY_EXPORT"} />
            </DashboardEmptyResponse>
          )}
          {data && data.length > 0 && (
            <div className="pt-3">
              <div className="mb-3 d-flex w-100 justify-content-end pr-3">
                <Button onClick={handleViewAll}>
                  {intl.formatMessage({ id: "VIEW" })}{" "}
                  {intl.formatMessage({ id: "ALL" })}
                </Button>
              </div>
              <Table
                className="ar-dashboard-table"
                TrComponent={ExportTr}
                columns={columns}
                data={data}
              />
            </div>
          )}
        </BaseSidebarContent>
      </BaseSidebarContainer>
    </ServerAwareContext.Provider>
  );
};

export default PersonalExportSidebar;
