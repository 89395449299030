import React from "react";
import { GeneralListItem } from "./InterventionTargetItem";
import { useIntervention } from "../../Hooks/EntityHooks";

const InterventionListItem = ({ intervention, ...rest }) => (
  <GeneralListItem intervention item={intervention} {...rest} />
);

export default InterventionListItem;

export const ConnectedInterventionListItem = ({ intervention, ...rest }) => {
  const i = useIntervention(intervention);
  // const tClient = useClient(i && i.Client);
  return <InterventionListItem intervention={i} {...rest} />;
};
