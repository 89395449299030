import React, { useCallback, useRef, useState } from "react";
import { FilterConditionsEnum } from "../../../../../Components/FilterList/AdvancedFilter/AdvancedFilterConditions";
import {
  AdvancedFilterButtons,
  AdvancedFilterhasErrorSetterContext,
  AdvancedFilterItemList
} from "../../../../../Components/FilterList/AdvancedFilter/AdvancedFilterGroup";
import { getDefaultParameterValues } from "../../../../../Components/FilterList/AdvancedFilter/AdvancedFilterHelper";

const replaceFieldSlash = (param) => {
  return param.replace("/", ".");
};

const replaceFieldDot = (param) => {
  return param.replace(".", "/");
};

export const buildResolvedFilters = (filter, fieldMappings) => {
  const { fieldDict, mappedFieldDict } = fieldMappings;
  let arr = [];
  const newFilter = { ...filter };
  const { Parameter, Groups } = newFilter;
  let parameterObj;

  if (Parameter) {
    const getEntityEndpoint = fieldDict[Parameter]
      ? fieldDict[Parameter].getEntityEndpoint
      : undefined;
    if (fieldDict[Parameter]) {
      parameterObj = fieldDict[Parameter];
      newFilter.Parameter = fieldDict[Parameter];
    } else if (mappedFieldDict[Parameter]) {
      parameterObj = mappedFieldDict[Parameter];
      newFilter.Parameter = mappedFieldDict[Parameter];
    }
    if (parameterObj) {
      const {
        getCompleteValue,
        getSchemaFromCompleteValue,
        getNormalizationSchema
      } = parameterObj;
      const oldValue = newFilter.Value;
      if (getCompleteValue) {
        newFilter.Value = getCompleteValue(
          replaceFieldDot(Parameter),
          newFilter.Value
        );
      }
      if (getSchemaFromCompleteValue && getEntityEndpoint) {
        const schema = getSchemaFromCompleteValue(newFilter.Value);
        arr.push({
          schema,
          getEntityEndpoint,
          normalizationSchema: getNormalizationSchema
            ? getNormalizationSchema()
            : schema,
          value: oldValue,
          parameter: newFilter
        });
      }
    }
  }
  if (Groups && Groups.length > 0) {
    newFilter.Groups = [...newFilter.Groups];
    for (let i = 0; i < newFilter.Groups.length; i++) {
      const element = newFilter.Groups[i];
      const { filter, dependencies } = buildResolvedFilters(
        element,
        fieldMappings
      );
      newFilter.Groups[i] = filter;
      arr = [...arr, ...dependencies];
    }
  }
  return { filter: newFilter, dependencies: arr };
};

export const resetParameters = (filter) => {
  //;
  if (!Array.isArray(filter) && filter.Parameter) {
    const { mappedFields, getResolvedValue, getField } = filter.Parameter;
    if (mappedFields) {
      const uris = getField(filter.Value);
      const { resolvedField } = uris;
      filter.Parameter = replaceFieldSlash(resolvedField);
      filter.Value = getResolvedValue(filter.Value);
    } else if (typeof filter.Parameter !== "string") {
      filter.Parameter = replaceFieldSlash(filter.Parameter.field);
    }
  }
  if (Array.isArray(filter) && filter.length > 0) {
    for (let i = 0; i < filter.length; i++) {
      const newGroup = { ...filter[i] };
      resetParameters(newGroup);
      filter[i] = newGroup;
    }
  }
};

const createNewCondition = (resolvedAvailableFilters) => {
  const firstFilter = resolvedAvailableFilters[0];
  const { Operator, Value } = getDefaultParameterValues(firstFilter);
  const newCondition = {
    Condition: FilterConditionsEnum.And,
    Parameter: firstFilter,
    Operator: Operator,
    Value: Value,
    Groups: []
  };
  return newCondition;
};

const AdvancedAutomationFilters = ({
  availableFilters,
  filters = [],
  handleChange
}) => {
  const errorRef = useRef(0);
  // eslint-disable-next-line no-unused-vars
  const [hasErrors, setHasErrors] = useState(false);

  const removeGroup = useCallback(
    (index) => {
      handleChange((oldForm) => {
        const newForm = { ...oldForm };
        const newFilters = [...newForm.Filters];
        newFilters.splice(index, 1);
        return {
          ...newForm,
          Filters: newFilters
        };
      });
    },
    [handleChange]
  );

  const updateCondition = useCallback(
    (newCondition) => {
      handleChange((oldForm) => {
        const newForm = { ...oldForm };
        const newFilters = [...newForm.Filters];
        for (const g of newFilters) {
          g.Condition = newCondition;
        }
        return {
          ...newForm,
          Filters: newFilters
        };
      });
    },
    [handleChange]
  );

  const updateGroup = useCallback(
    (index, newGroupsFunc) => {
      handleChange((oldForm) => {
        const newForm = { ...oldForm };
        const newFilters = [...newForm.Filters];
        newFilters[index] = newGroupsFunc(newFilters[index]);
        return {
          ...newForm,
          Filters: newFilters
        };
      });
    },
    [handleChange]
  );

  const addCondition = useCallback(() => {
    handleChange((oldForm) => {
      const newCondition = createNewCondition(availableFilters);
      const newForm = { ...oldForm };

      const newFilters = [...newForm.Filters];
      const Condition =
        newFilters.length > 0
          ? newFilters[0].Condition
          : FilterConditionsEnum.And;
      newCondition.Condition = Condition;
      newFilters.push(newCondition);
      return {
        ...oldForm,
        Filters: newFilters
      };
    });
  }, [availableFilters, handleChange]);

  const addGroup = useCallback(() => {
    handleChange((oldForm) => {
      const newForm = { ...oldForm };
      const newFilters = [...newForm.Filters];
      const newCondition = createNewCondition(availableFilters);
      const Condition =
        newFilters.length > 0
          ? newFilters[0].Condition
          : FilterConditionsEnum.And;
      const newGroup = {
        Condition,
        Groups: [newCondition]
      };
      newFilters.push(newGroup);
      return {
        ...oldForm,
        Filters: newFilters
      };
    });
  }, [availableFilters, handleChange]);

  return (
    <div className="d-flex flex-column">
      <AdvancedFilterhasErrorSetterContext.Provider value={setHasErrors}>
        <AdvancedFilterItemList
          errorRef={errorRef}
          Groups={filters}
          removeGroup={removeGroup}
          updateGroup={updateGroup}
          updateCondition={updateCondition}
        />
      </AdvancedFilterhasErrorSetterContext.Provider>
      <div className="mt-3">
        <AdvancedFilterButtons
          addCondition={addCondition}
          addGroup={addGroup}
        />
      </div>
    </div>
  );
};

export default AdvancedAutomationFilters;
