import { useMemo } from "react";

const formatDecimalNumber = (decimal: number) => {
  const splitDec = decimal.toString().split(".");

  let [leftPart, rightPart] = splitDec;

  let val = (leftPart = leftPart.replace(/\B(?=(\d{3})+(?!\d))/g, "."));

  if (val[val.length - 1] === ".") val = val.slice(0, -1);

  if (rightPart) val += `,${rightPart}`;
  return val;
};

const FormattedDecimal = ({ value }: { value?: number }) => {
  const resolvedValue = useMemo(() => {
    if (typeof value !== "number") return null;

    return formatDecimalNumber(value);
  }, [value]);

  return resolvedValue;
};

export default FormattedDecimal;
