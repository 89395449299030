import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useCallback,
  useMemo
} from "react";
import { msToTime } from "../../Assets/Utils";
import classnames from "classnames";
import "./TimePicker.css";
import Button from "../Button/Button";
import Popup, { usePopupOpenState } from "../Popup/Popup";
import { FormattedMessage } from "react-intl";

const TimePicker = ({
  value = 0,
  onChange,
  className,
  innerRef,
  preview,
  onChangeHours,
  onChangeMins,
  test,
  ...props
}) => {
  //   const [hours, minutes] = msToTime(value, "hh:mm").split(":");

  const [hours, minutes] = useMemo(() => {
    return msToTime(value, "hh:mm").split(":");
  }, [value]);

  //   const [hour, setHour] = useState(hours);
  //   const [minute, setMinute] = useState(minutes);
  const [hoveredValueHours, setHoveredValueHours] = useState(hours);
  const [hoveredValueMins, setHoveredValueMins] = useState(minutes);
  const hourPopupRef = useRef();
  const minutePopupRef = useRef();

  const hoursRef = useRef();
  const minutesRef = useRef();
  const popupAnchorRef = useRef();

  const [choosingHours, setChoosingHours] = usePopupOpenState(
    hourPopupRef,
    hoursRef
  );
  const [choosingMins, setChoosingMins] = usePopupOpenState(
    minutePopupRef,
    minutesRef
  );

  const [, setIsFocused] = useState(false);

  const prevSelectionRef = useRef();
  const lastUsedKey = useRef();

  const activateTimePicker = useCallback(() => {
    if (!choosingMins) {
      hoursRef.current.select();
      setChoosingHours(true);
    }
  }, [choosingMins, setChoosingHours]);

  useEffect(() => {
    innerRef.current = {
      activateTimePicker
    };
  }, [activateTimePicker, innerRef]);

  const handleFocus = (e) => {
    prevSelectionRef.current = e.target.selectionStart;
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    setIsFocused(false);
  };

  const handleHourKeyDown = (e) => {
    if (!choosingHours && (e.keyCode === 37 || e.keyCode === 39)) {
      if (
        e.keyCode === 39 &&
        e.target &&
        e.target.selectionStart === e.target.value.length
      ) {
        e.preventDefault();
        e.target.nextElementSibling.nextElementSibling.focus();
        e.target.nextElementSibling.nextElementSibling.setSelectionRange(0, 0);
      } else if (
        e.keyCode === 37 &&
        e.target &&
        e.target.selectionStart === 0
      ) {
        e.preventDefault();
        if (e.target.parentNode.previousElementSibling) {
          e.target.parentNode.previousElementSibling.lastElementChild.focus();
          e.target.parentNode.previousElementSibling.lastElementChild.setSelectionRange(
            e.target.parentNode.previousElementSibling.lastElementChild.value
              .length,
            e.target.parentNode.previousElementSibling.lastElementChild.value
              .length
          );
        }
      }
    }
    if (choosingHours) {
      if (
        e.keyCode === 37 ||
        e.keyCode === 38 ||
        e.keyCode === 39 ||
        e.keyCode === 40 ||
        e.keyCode === 9 ||
        e.keyCode === 13
      )
        e.preventDefault();
      //left
      // if(e.keyCode === 27){
      // 	setChoosingHours(false)
      // }
      if (e.keyCode === 37 && choosingHours) {
        // if (hours > 0 && (hours < 12 || hours > 12)) {
        //   // const activeHour = hourPopupRef.current.getElementsByClassName("active")
        //   // prevSelectionRef.current = true;
        //   // activeHour[0].previousSibling.click();
        //   setHoveredValueHours((v) => {
        //     return parseInt(v) - 1 === -1 ? 23 : parseInt(v) - 1;
        //   });
        // }
        // setChoosingHours(false)
      }
      //up
      // else if (e.keyCode === 38 && choosingHours) {
      //   if (hoveredValueHours >= 12 && hoveredValueHours <= 23) {
      //     setHoveredValueHours((v) => {
      //       return parseInt(v) - 12;
      //     });
      //     // const activeHour = document.getElementById("hourPickerFirstLine")
      //     // prevSelectionRef.current = true;

      //     // activeHour.childNodes[hours - 12].click()
      //   }
      // }
      //right
      else if (e.keyCode === 39 && choosingHours) {
        // if (hours < 23 && (hours < 11 || hours > 11)) {
        // 	const activeHour = hourPopupRef.current.getElementsByClassName("active")
        // 	prevSelectionRef.current = true;

        // 	activeHour[0].nextSibling.click();
        // }
        setHoveredValueHours((v) => {
          return parseInt(v) + 1 === 24 ? 0 : parseInt(v) + 1;
        });
      }
      //down
      // else if (e.keyCode === 40 && choosingHours) {
      //   if (hoveredValueHours >= 0 && hoveredValueHours <= 11) {
      //     setHoveredValueHours((v) => {
      //       return parseInt(v) + 12;
      //     });

      //     // if (hours >= 0 && hours <= 11) {
      //     // 	const activeHour = document.getElementById("hourPickerSecondLine")
      //     // 	prevSelectionRef.current = true;

      //     // 	activeHour.childNodes[Number(hours)].click()
      //     // }
      //   }
      // }
    } else {
      // if (e.keyCode === 38 && parseInt(hoursRef.current.value) < 23) {
      //   prevSelectionRef.current = hoursRef.current;
      //   lastUsedKey.current = "arrowHour";
      //   onChangeHours(parseInt(hoursRef.current.value) + 1);
      // } else if (e.keyCode === 38 && parseInt(hoursRef.current.value) === 23) {
      //   lastUsedKey.current = "arrowHour";
      //   onChangeHours(0, "increaseDay");
      // } else if (e.keyCode === 40 && parseInt(hoursRef.current.value) > 0) {
      //   lastUsedKey.current = "arrowHour";
      //   prevSelectionRef.current = hoursRef.current;
      //   onChangeHours(parseInt(hoursRef.current.value) - 1);
      // } else if (e.keyCode === 40 && parseInt(hoursRef.current.value) === 0) {
      //   lastUsedKey.current = "arrowHour";
      //   onChangeHours(23, "decreaseDay");
      // }
    }
  };

  const handleMinuteKeyDown = (e) => {
    if (
      !choosingMins &&
      e.keyCode === 37 &&
      e.target &&
      e.target.selectionStart === 0
    ) {
      e.preventDefault();
      e.target.previousElementSibling.previousElementSibling.focus();
      e.target.previousElementSibling.previousElementSibling.setSelectionRange(
        e.target.previousElementSibling.previousElementSibling.value.length,
        e.target.previousElementSibling.previousElementSibling.value.length
      );
    }

    if (choosingMins) {
      if (
        e.keyCode === 37 ||
        e.keyCode === 38 ||
        e.keyCode === 39 ||
        e.keyCode === 40 ||
        e.keyCode === 9 ||
        e.keyCode === 13
      )
        e.preventDefault();
      //Left
      if (e.keyCode === 37 && choosingMins) {
        // if (minutes > 0 && (minutes < 15 || minutes > 15) && (minutes < 30 || minutes > 30) && (minutes < 45 || minutes > 45)) {
        // 	const activeMin = minutePopupRef.current.getElementsByClassName("active")
        // 	prevSelectionRef.current = true;
        // 	activeMin[0].previousSibling.click();
        // }
        setHoveredValueMins((v) => {
          return parseInt(v) - 1 === -1 ? 59 : parseInt(v) - 1;
        });
      }
      //up
      // else if (
      //   e.keyCode === 38 &&
      //   choosingMins &&
      //   minutes >= 15 &&
      //   minutes <= 59
      // ) {
      //   // const element = minutePopupRef.current.getElementsByClassName("active")
      //   // const index = [...element[0].parentNode.children].indexOf(element[0]);
      //   // prevSelectionRef.current = true;
      //   // element[0].parentNode.previousSibling.previousSibling.children[index].click();
      //   if (hoveredValueMins - 15 >= 0) {
      //     setHoveredValueMins((v) => {
      //       return parseInt(v) - 15;
      //     });
      //   }
      // }
      //right
      else if (e.keyCode === 39 && choosingMins) {
        // if (minutes < 59 && (minutes < 44 || minutes > 44) && (minutes < 29 || minutes > 29) && (minutes < 14 || minutes > 14)) {
        // 	const activeMin = minutePopupRef.current.getElementsByClassName("active")
        // 	prevSelectionRef.current = true;
        // 	activeMin[0].nextSibling.click();
        // }
        setHoveredValueMins((v) => {
          return parseInt(v) + 1 === 60 ? 0 : parseInt(v) + 1;
        });
      }
      //down
      // else if (
      //   e.keyCode === 40 &&
      //   choosingMins &&
      //   minutes >= 0 &&
      //   minutes <= 44
      // ) {
      //   if (hoveredValueMins + 15 <= 59) {
      //     setHoveredValueMins((v) => {
      //       return parseInt(v) + 15;
      //     });
      //   }
      //   // const element = minutePopupRef.current.getElementsByClassName("active")
      //   // const index = [...element[0].parentNode.children].indexOf(element[0]);
      //   // prevSelectionRef.current = true;
      //   // element[0].parentNode.nextSibling.nextSibling.children[index].click();
      // }
    } else {
      // if (e.keyCode === 38 && parseInt(minutesRef.current.value) < 59) {
      //   prevSelectionRef.current = minutesRef.current;
      //   lastUsedKey.current = "arrowMinute";
      //   onChangeMins(parseInt(minutesRef.current.value) + 1);
      // } else if (
      //   e.keyCode === 38 &&
      //   parseInt(minutesRef.current.value) === 59
      // ) {
      //   prevSelectionRef.current = minutesRef.current;
      //   lastUsedKey.current = "arrowMinute";
      //   parseInt(hoursRef.current.value) === 23
      //     ? onChangeHours(0, "increaseDay")
      //     : onChangeHours(parseInt(hoursRef.current.value) + 1);
      //   onChangeMins(0);
      // } else if (e.keyCode === 40 && parseInt(minutesRef.current.value) > 0) {
      //   prevSelectionRef.current = minutesRef.current;
      //   lastUsedKey.current = "arrowMinute";
      //   onChangeMins(parseInt(minutesRef.current.value) - 1);
      // } else if (e.keyCode === 40 && parseInt(minutesRef.current.value) === 0) {
      //   prevSelectionRef.current = minutesRef.current;
      //   lastUsedKey.current = "arrowMinute";
      //   if (parseInt(hoursRef.current.value) === 0) {
      //     onChangeHours(23, "decreaseDay");
      //     onChangeMins(59, 23);
      //   } else onChangeMins(59, parseInt(hoursRef.current.value) - 1);
      // }
    }
  };

  const handleHoursKeyUp = (e) => {
    const { selectionStart } = e.target;

    if (e.keyCode === 9 && choosingHours) {
      onChangeHours(hoursRef.current.value);
      setChoosingHours(false);
      setChoosingMins(true);
      window.getSelection().removeAllRanges();
    }

    if (e.keyCode !== 13) {
      prevSelectionRef.current = selectionStart;
      return;
    }

    if (
      parseInt(hoveredValueHours) !== parseInt(hoursRef.current.value) &&
      choosingHours
    )
      onChangeHours(hoveredValueHours);
    else onChangeHours(hoursRef.current.value);
    if (choosingHours) {
      setChoosingHours(false);
      setChoosingMins(true);
    } else {
      e.preventDefault();
    }
    window.getSelection().removeAllRanges();
  };

  const handleMinutesKeyUp = (e) => {
    const { selectionStart } = e.target;

    if (e.keyCode === 9 && choosingMins) {
      onChangeMins(minutesRef.current.value);
      setChoosingMins(false);
      window.getSelection().removeAllRanges();
    }

    if (e.keyCode !== 13) {
      prevSelectionRef.current = selectionStart;
      return;
    }

    if (
      parseInt(hoveredValueMins) !== parseInt(minutesRef.current.value) &&
      choosingMins
    )
      onChangeMins(hoveredValueMins);
    else onChangeMins(minutesRef.current.value);

    setChoosingMins(false);
    window.getSelection().removeAllRanges();
  };

  const onHourClick = useCallback(
    (hour, e) => {
      onChangeHours(hour);
      handleBlur();
      setChoosingHours(false);
      setChoosingMins(true);
    },
    [onChangeHours, setChoosingHours, setChoosingMins]
  );

  const onMinuteClick = useCallback(
    (minute, e) => {
      onChangeMins(minute);
      setChoosingMins(false);
    },
    [onChangeMins, setChoosingMins]
  );

  // useLayoutEffect(() => {
  //   if (choosingHours) {
  //     hoursRef.current.select();
  //   }
  //   if (choosingMins) {
  //     minutesRef.current.select();
  //   }
  //   if (lastUsedKey.current === "arrowHour") hoursRef.current.select();
  //   if (lastUsedKey.current === "arrowMinute") minutesRef.current.select();

  //   if (hoveredValueHours !== hours && !choosingHours) {
  //     // if (minutes !== minute && hours !== hour) {
  //     //   setHour(hours);
  //     //   setMinute(minutes);
  //     //   if (lastUsedKey.current === "arrow") minutesRef.current.select();
  //     //   lastUsedKey.current = "";
  //     // }
  //     // if (hours !== hour) {
  //     //   setHour(hours);
  //     //   if (lastUsedKey.current === "arrow") hoursRef.current.select();
  //     //   lastUsedKey.current = "";
  //     // }
  //     // if (minutes !== minute) {
  //     //   setMinute(minutes);
  //     //   if (lastUsedKey.current === "arrow") minutesRef.current.select();
  //     //   lastUsedKey.current = "";
  //     // }
  //     setHoveredValueHours(hours);
  //   }
  //   if (hoveredValueMins !== minutes && !choosingMins) {
  //     setHoveredValueMins(minutes);
  //   }
  // }, [
  //   choosingHours,
  //   choosingMins,
  //   hours,
  //   hoveredValueHours,
  //   hoveredValueMins,
  //   minutes
  // ]);

  useEffect(() => {
    if (!choosingHours) return;
    const handleWheel = (e) => {
      e.preventDefault();

      if (choosingHours) {
        if (e.deltaY === -100) {
          setHoveredValueHours((v) => {
            return parseInt(v) - 1 === -1 ? 23 : parseInt(v) - 1;
          });
        } else if (e.deltaY === 100) {
          setHoveredValueHours((v) => {
            return parseInt(v) + 1 === 24 ? 0 : parseInt(v) + 1;
          });
        }
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    return () =>
      window.removeEventListener("wheel", handleWheel, { passive: false });
  }, [choosingHours]);

  useEffect(() => {
    if (!choosingMins) return;
    const handleWheel = (e) => {
      e.preventDefault();
      if (choosingMins) {
        if (e.deltaY === -100) {
          setHoveredValueMins((v) => {
            return parseInt(v) - 1 === -1 ? 59 : parseInt(v) - 1;
          });
        } else if (e.deltaY === 100) {
          setHoveredValueMins((v) => {
            return parseInt(v) + 1 === 60 ? 0 : parseInt(v) + 1;
          });
        }
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    return () =>
      window.removeEventListener("wheel", handleWheel, { passive: false });
  }, [choosingMins]);

  //   if (!isFocused) {
  //     onChangeMins(minutesRef.current ? minutesRef.current.value : minutes);
  //     onChangeHours(hoursRef.current ? hoursRef.current.value : hours);
  //   }

  if (preview)
    return (
      <span>
        {hours}:{minutes}
      </span>
    );

  // const onHourChangeValue = e => {
  //   onChangeHours();
  // };

  return (
    <div ref={popupAnchorRef} className="d-flex">
      <HoursInput
        autoComplete="off"
        name="Hrs"
        key={`h-${hours}`}
        ref={hoursRef}
        value={hours}
        onFocus={handleFocus}
        onKeyUp={handleHoursKeyUp}
        onKeyDown={handleHourKeyDown}
        onChange={onChangeHours}
        popupState={choosingHours}
        onBlur={() => {
          if (handleBlur) handleBlur();
        }}
      />
      <div style={{ display: "inline" }}>:</div>
      <MinutesInput
        autoComplete="off"
        name="Mins"
        key={`m-${minutes}`}
        ref={minutesRef}
        value={minutes}
        onFocus={handleFocus}
        onKeyDown={handleMinuteKeyDown}
        onKeyUp={handleMinutesKeyUp}
        popupState={choosingMins}
        onChange={onChangeMins}
        onBlur={() => {
          if (handleBlur) handleBlur();
        }}
      />

      <Popup
        domRef={hourPopupRef}
        anchorEl={popupAnchorRef.current}
        isOpen={choosingHours}
        placement="bottom-start"
      >
        <HourChooser
          value={hours}
          hoveredHour={hoveredValueHours}
          onButtonClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setChoosingHours(false);
            setChoosingMins(true);
            window.getSelection().removeAllRanges();
          }}
          onHourClick={onHourClick}
        />
      </Popup>
      <Popup
        domRef={minutePopupRef}
        anchorEl={popupAnchorRef.current}
        isOpen={choosingMins}
        placement="bottom-start"
      >
        <MinuteChooser
          value={minutes}
          hoveredMin={hoveredValueMins}
          onButtonClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setChoosingMins(false);
            window.getSelection().removeAllRanges();
          }}
          onMinuteClick={onMinuteClick}
        />
      </Popup>
    </div>
  );
};

const hourOptions = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23"
];

const minutesOptions = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59"
];

const Item = React.memo(({ isActive, isHovering, onHourClick, hintValue }) => {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      onHourClick(hintValue);
    },
    [hintValue, onHourClick]
  );

  return (
    <span
      onClick={handleClick}
      className={classnames("ar-timePickerSquareSelecter mx-1 p-1 text-black", {
        "ar-timePickerSquareSelecter--hovering": isHovering,
        active: isActive
      })}
    >
      {hintValue}
    </span>
  );
});

const HourChooser = ({ value, hoveredHour, onHourClick, onButtonClick }) => {
  const zeroToEleven = [];
  const twelveToTwentyThree = [];

  hourOptions.forEach((e, i) => {
    if (i < 12) {
      zeroToEleven.push(
        <Item
          key={e}
          onHourClick={onHourClick}
          isActive={value === e}
          isHovering={hoveredHour === parseInt(e)}
          hintValue={e}
        />
      );
    } else
      twelveToTwentyThree.push(
        <Item
          key={e}
          onHourClick={onHourClick}
          isActive={value === e}
          isHovering={hoveredHour === parseInt(e)}
          hintValue={e}
        />
      );
  });

  return (
    <div className="ar-Timepicker">
      <div className="ar-hourPicker">
        <div id="hourPickerFirstLine" className="mx-3 my-1">
          {zeroToEleven}
        </div>
        <hr style={{ margin: 0 }} />
        <div id="hourPickerSecondLine" className="mx-3 my-1">
          {twelveToTwentyThree}
        </div>
      </div>
      <div className="ar-TimePicker-Footer px-3 py-2 d-flex justify-content-between">
        <span className="text-black mt-1">
          <FormattedMessage id="KEYBOARD_TO_NAVIGATE" />
        </span>
        <Button onClick={onButtonClick} size="sm" vType="primary-ghost">
          <FormattedMessage id={"NEXT"} />
        </Button>
      </div>
    </div>
  );
};

const MinuteChooser = ({ value, hoveredMin, onMinuteClick, onButtonClick }) => {
  const zeroToFourTeen = [];
  const fifteenToTwentyNine = [];
  const thirtyToFortyfour = [];
  const fortyfiveTofiftynine = [];
  minutesOptions.forEach((e, i) => {
    if (i < 15)
      zeroToFourTeen.push(
        <Item
          key={e}
          onHourClick={onMinuteClick}
          isActive={value === e}
          isHovering={hoveredMin === parseInt(e)}
          hintValue={e}
        />
      );
    else if (i < 30)
      fifteenToTwentyNine.push(
        <Item
          key={e}
          onHourClick={onMinuteClick}
          isActive={value === e}
          isHovering={hoveredMin === parseInt(e)}
          hintValue={e}
        />
      );
    else if (i < 45)
      thirtyToFortyfour.push(
        <Item
          key={e}
          onHourClick={onMinuteClick}
          isActive={value === e}
          isHovering={hoveredMin === parseInt(e)}
          hintValue={e}
        />
      );
    else
      fortyfiveTofiftynine.push(
        <Item
          key={e}
          onHourClick={onMinuteClick}
          isActive={value === e}
          isHovering={hoveredMin === parseInt(e)}
          hintValue={e}
        />
      );
  });

  return (
    <div className="ar-Timepicker">
      <div className="ar-minutePicker">
        <div id="minutePickerFirstLine" className="mx-3 my-1">
          {zeroToFourTeen}
        </div>
        <hr style={{ margin: 0 }} />
        <div id="minutePickerSecondLine" className="mx-3 my-1">
          {fifteenToTwentyNine}
        </div>
        <hr style={{ margin: 0 }} />
        <div id="minutePickerThirdLine" className="mx-3 my-1">
          {thirtyToFortyfour}
        </div>
        <hr style={{ margin: 0 }} />
        <div id="minutePickeForthLine" className="mx-3 my-1">
          {fortyfiveTofiftynine}
        </div>
      </div>
      <div className="ar-TimePicker-Footer px-3 py-2 d-flex justify-content-between">
        <span className="text-black mt-1">
          {" "}
          <FormattedMessage id="KEYBOARD_TO_NAVIGATE" />
        </span>
        <Button onClick={onButtonClick} size="sm">
          <FormattedMessage id="APPLY" />
        </Button>
      </div>
    </div>
  );
};

const createInput = ({ Converter, isValueValid }) => {
  const Component = React.forwardRef(
    ({ value, onChange, onBlur, popupState, ...rest }, ref) => {
      const [time, setTime] = useState(value);
      const blurOccurredRef = useRef(false);
      const handleHoursChange = (e) => {
        const { value: textValue } = e.target;

        if (textValue === "") return setTime("");

        const resolvedValue = Number(textValue);
        if (
          !Number.isInteger(resolvedValue) ||
          (isValueValid && !isValueValid(resolvedValue))
        )
          return;

        setTime(textValue);
      };

      const handleBlur = (e) => {
        // if (Number(time) === Number(value)) return setTime(value);

        if (!popupState) onChange(Number(time));
        blurOccurredRef.current = true;
        // const newValue = Number(value) + milsecs;
      };

      useEffect(() => {
        if (blurOccurredRef.current) {
          blurOccurredRef.current = false;
          setTime(value);
        }
      }, [value]);

      return (
        <input
          ref={ref}
          onChange={handleHoursChange}
          onBlur={() => {
            if (handleBlur) handleBlur();
          }}
          value={time}
          {...rest}
        />
      );
    }
  );

  return Component;
};

const HoursInput = createInput({ isValueValid: (v) => v >= 0 && v < 24 });

const MinutesInput = createInput({
  isValueValid: (v) => v >= 0 && v < 60
});

export default TimePicker;
