import React from "react";
import FormInput from "../../../Components/Forms/FormInput/FormInput";

import { FormattedMessage, useIntl } from "react-intl";
import { createFormRequestHook, useFormState } from "../../../Components/Forms";
import {
  SideFormContent,
  SideFormFooter,
  SideForm
} from "../../../Components/Forms/SideMenuForm";
import { spaceDealsTagsSchema } from "../../../config/schema";
import { useTicketType, useDealTag } from "../../../Hooks/EntityHooks";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useToast } from "../../../Components/Toast/ToastProvider";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  useSidebarSpacePost,
  BaseSidebarContent,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import Switch from "../../../Components/Switch/Switch";
import { handleError } from "../../../Helpers/MiscHelper";
import {
  TicketPipelineDropdown,
  DealPipelineDropdown
} from "../../AdvancedMultiInputs/PipelineDropdowns";

export const convertEntityToForm = (entity) => {
  //;
  const { Name, Enabled, Pipelines } = entity;

  return {
    Enabled,
    Name,
    Pipeline: Pipelines
  };
};

const useDealTypeRequest = createFormRequestHook({
  schema: spaceDealsTagsSchema,
  convertEntityToForm: convertEntityToForm
});

const DealTagForm = ({ ...props }) => {
  const { id, onSuccess } = props;

  const {
    // key,
    loading,
    error,
    convertedForm
    // isChild,
    // childProps,
  } = useDealTypeRequest(props);

  const dealTag = useDealTag(id);

  const canEdit = dealTag ? dealTag.CanEdit : true;
  if (loading || error)
    return (
      <BaseSidebarContainer>
        <BaseSidebarHeader>
          <FormattedMessage id={"DEAL_TAG"} />
        </BaseSidebarHeader>
        <BaseSidebarContent>
          <div className="d-flex h-100 w-100 justify-content-center align-items-center">
            <LoaderSpinner center size="sm" className="text-secondary" />;
          </div>
        </BaseSidebarContent>
      </BaseSidebarContainer>
    );
  return (
    <Form
      onSuccess={onSuccess}
      id={id}
      canEdit={canEdit}
      convertedForm={convertedForm}
      canDelete={dealTag?.CanDelete}
      {...props}
    />
  );
};

export const defaultForm = () => {
  return {
    Name: "",
    Enabled: true,
    Pipeline: []
  };
};

const Form = ({
  convertedForm,
  id,
  canEdit,
  onSuccess,
  refetchRef,
  isChild,
  canDelete,
  ...props
}) => {
  const formState = useFormState(() => {
    return {
      ...defaultForm(),
      ...convertedForm
    };
  });
  const { form, handleChange } = formState;
  const { Name, Pipeline } = form;

  const PipelineId = Pipeline;

  const createToast = useToast();

  const intl = useIntl();
  const [post, { loading }] = useSidebarSpacePost(
    id ? `Tags/Deals/${id}` : `Tags/Deals`,
    spaceDealsTagsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "DEAL_TAG" })} ${Name}`,
          description: intl.formatMessage({ id: "SUCCESS_SAVED" })
        });
        onSuccess && onSuccess();
      },
      refetchRef,
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    `Tags/Deals/${id}`,
    spaceDealsTagsSchema,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "DEAL_TAG" })} ${Name}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleError(error);
      },
      refetchRef
    }
  );

  const formatCancelForm = (form) => {
    const { Name, Enabled, Pipeline } = form;
    return {
      Name,
      Enabled,
      PipelineIds: Pipeline
    };
  };

  const handleSubmit = (form) => {
    post(formatCancelForm(form));
  };

  const validateCancelForm = ({ Name, DepartmentTeams }, addError) => {
    if (!Name) addError("Name");
  };

  const handleCheckChanges = ({ target: { name, checked } }) => {
    handleChange((oldF) => {
      return {
        ...oldF,
        [name]: checked
      };
    });
  };

  const handlePipelineChanges = (e) => {
    const { value } = e.target;
    handleChange({
      Pipeline: value
    });
  };

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"DEAL_TAG"} />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideForm
          canEdit={canEdit}
          formState={formState}
          onSubmit={handleSubmit}
          validate={validateCancelForm}
        >
          <SideFormContent className="px-4 py-3">
            <FormInput name="Name" textId="NAME" className="mb-3" />

            <FormInput
              name="Enabled"
              onChange={handleCheckChanges}
              textId={"ACTIVE"}
              inputType={Switch}
              className="mb-3"
            />
            <FormInput
              name="Pipeline"
              className="mb-3"
              value={PipelineId}
              textId="PIPELINE"
              multiple
              inputType={DealPipelineDropdown}
              onChange={handlePipelineChanges}
            />
          </SideFormContent>

          {/* <FormInput
          name="Files"
          text={intl.formatMessage({ id: "files" })}
          inputType={FileUploader}
          className="mb-3"
        /> */}
          <SideFormFooter
            canDelete={canDelete}
            deleteLoading={deleteLoading}
            handleDelete={remove}
            isLoading={loading}
          />
        </SideForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

// export default withSpaceEntityForm(withSpace(injectIntl(ClientTypeForm)), {
//   defaultForm: {
//     Name: ""
//   },
//   validateForm: ({ Name }, addError) => {
//     if (!Name) addError("Name");
//   },
//   convertEntityToForm: entity => {
//     const { Departments, Teams, ...rest } = entity;

//     const DepartmentTeams = convertToDepartmentTeams(entity);

//     return {
//       ...rest,
//       DepartmentTeams
//     };
//   },
//   formatForm: form => {
//     let { DepartmentTeams, Organizations, ...rest } = form;

//     return {
//       ...rest
//     };
//     // return createFormData(
//     //   {
//     //     ...rest,
//     //     organizations: Organizations.length === 0 ? undefined : Organizations,
//     //     departments: departments.length === 0 ? undefined : departments,
//     //     teams: teams.length === 0 ? undefined : teams
//     //   }
//     // );
//   },
//   schema: spaceClientTypeSchema
//   // getEntityId: (state, ownProps) => ownProps.interventionTypeId
// });
export default DealTagForm;
