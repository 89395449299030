import React, {
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect
} from "react";
import "./VirtualGrid.css";
import classnames from "classnames";
import GridSort from "../CGrid/GridSort";
import Checkbox from "../Checkbox/Checkbox";
import {
  getResolvedColumnWidth,
  HeightContext,
  VirtualGridColumnProps
} from "./VirtualGridHelper";
import {
  VirtualGridRow as VirtualGridRowComp,
  VirtualGridBody
} from "./VirtualGridBody";
import { VirtualGridFooter } from "./VirtualGridFooter";
import {
  ColumnResizer,
  ResizeProvider,
  useColumnsSize,
  useStickyInfo
} from "./VirtualGridResizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VirtualGridColumnChooserProvider, {
  VirtualGridColumnChooserContext
} from "./VirtualGridColumnChooser";
import { faPlus } from "@fortawesome/pro-light-svg-icons";

interface SortType {
  column: string;
  type: string;
}

export interface ColumnsSizeType {
  [columnField: string]: string | number; // string for compatability eg: "240px"
}

interface VirtualGridProps {
  height: number;
  noBody: any;
  data: any[];
  columns: VirtualGridColumnProps[];
  RowComponent?: any;
  disableHeader?: any;
  getKey?: any;
  disableBackground?: any;
  disableBorder?: any;
  columnsSize?: ColumnsSizeType;
  onColumnsSizeChange?: (newColumnSizes: ColumnsSizeType) => void;
  sort?: SortType[];
  onSort?: (newSort: SortType[]) => void;
  selectedItems?: any[];
  isLoading?: boolean;
  onSelectedItemsChange?: (selectedItems: any[]) => void;
  showTotals?: boolean;
  disableResize?: boolean;
  onToggleColumnChooser: any;
}

const itemsOffset = 10;
const minVirtualLength = 51;

const useVirtualGridData = (height: number, data: any[], containerRef: any) => {
  const [resolvedIndexes, setResolvedIndexes] = useState<any | undefined>();
  const [resolvedData, setResolvedData] = useState<any | undefined>();

  const [style, setStyle] = useState<any>();

  const helperRef = useRef<any>({});

  const verifyCicle = useCallback(
    (forceUpdate = false) => {
      if (helperRef.current.frame) {
        clearTimeout(helperRef.current.frame);
        helperRef.current.frame = undefined;
      }

      if (!data) return;
      if (!containerRef.current) return;
      //   ;

      if (data.length <= minVirtualLength) {
        setResolvedData(data);
        setResolvedIndexes({ startIndex: 0, endIndex: data.length - 1 });
        setStyle({
          paddingTop: 0,
          paddingBottom: 0
        });
        return;
      }

      const { offsetHeight, scrollTop } =
        containerRef.current as HTMLDivElement;
      //
      const availableItemsInViewport =
        Math.round(offsetHeight / height) + itemsOffset * 2;

      let scrolledItems = Math.floor(scrollTop / height) - itemsOffset;

      if (scrolledItems < 0) scrolledItems = 0;

      const startIndex = scrolledItems;

      let endIndex = scrolledItems + availableItemsInViewport;
      if (endIndex > data.length - 1) endIndex = data.length - 1;

      if (
        !forceUpdate &&
        helperRef.current.startIndex === startIndex &&
        helperRef.current.endIndex === endIndex
      )
        return;

      // timeoutRef.current.timeout = setTimeout(() => {
      //   timeoutRef.current.timeout = undefined;

      // });
      helperRef.current.frame = setTimeout(() => {
        helperRef.current.frame = undefined;

        helperRef.current.startIndex = startIndex;
        helperRef.current.endIndex = endIndex;

        setResolvedData(data);
        setResolvedIndexes({ startIndex, endIndex });
        const paddingTop = scrolledItems * height;
        const paddingBottom = (data.length - 1 - endIndex) * height;

        setStyle({
          paddingTop,
          paddingBottom
        });
      }, 10);
    },
    [height, data, containerRef]
  );

  useLayoutEffect(() => {
    let frame = requestAnimationFrame(() => {
      verifyCicle(true);
      frame = undefined;
    }) as undefined | number;
    const listner = () => {
      verifyCicle();
    };
    const container = containerRef.current;

    if (data && data.length > minVirtualLength) {
      if (container) container.addEventListener("scroll", listner);

      return () => {
        if (container) container.removeEventListener("scroll", listner);
        if (frame) cancelAnimationFrame(frame);
      };
    }
  }, [verifyCicle, containerRef, data]);

  return {
    resolvedIndexes,
    containerRef,
    timeoutRef: helperRef,
    style,
    verifyCicle,
    resolvedData
  };
};

// interface ColumnResizerProps {
//   baseSize: number;
//   minSize: number;
//   commitSize: (size: number) => void;
//   setSize: (size: number) => void;
// }

// const ColumnResizer = React.memo(
//   ({ commitSize, baseSize, minSize, setSize }: ColumnResizerProps) => {
//     const sizeRef = useRef<any>();

//     const [baseX, setBaseX] = useState<number>();

//     const handleEnter = (e: any) => {
//       setBaseX(e.clientX);
//     };

//     useEffect(() => {
//       if (baseX === undefined) return;

//       const moveListener = (e: any) => {
//         const { clientX: newClientX } = e;
//         const incrementedSize = newClientX - baseX;

//         let newSize = baseSize + incrementedSize;
//         if (newSize < minSize) newSize = minSize;

//         sizeRef.current = newSize;
//         setSize(newSize);
//         commitSize(sizeRef.current);
//       };

//       document.addEventListener("mousemove", moveListener);

//       const upListener = () => {
//         // if (sizeRef.current) commitSize(sizeRef.current);
//         setBaseX(undefined);
//       };

//       document.addEventListener("mouseup", upListener);

//       return () => {
//         document.removeEventListener("mousemove", moveListener);
//         document.removeEventListener("mouseup", upListener);
//       };
//     }, [baseSize, baseX, commitSize, minSize, setSize]);

//     return (
//       <div
//         onMouseDown={handleEnter}
//         onClick={(e) => e.stopPropagation()}
//         className="ar-virtual-grid-resizer"
//       ></div>
//     );
//   }
// );

interface HeaderColumnProps {
  column: VirtualGridColumnProps;
  columnSize?: string | number;
  sortValue?: SortDictValue;
  onSortValueChange: (columnField: string, type?: string) => void;
  areAllItemsSelected?: boolean;
  onSelectionChange?: (newSelection: any) => void;
  disableResize?: boolean;
  left?: number;
}

const ColumnSorter = ({ sortValue, sortType }: any) => {
  return (
    <div className="sortable-icon">
      {sortValue && (
        <span style={{ color: "rgb(30, 115, 240)" }}>{sortValue.position}</span>
      )}
      <GridSort asc={sortType === "asc"} desc={sortType === "desc"} />
    </div>
  );
};

const ColumnTitle = ({ column, sortValue, sortType, style }: any) => {
  const { noTitle, headerIcon, title: Title, sortable } = column;
  const canSort = sortable !== undefined ? sortable : true;
  if (noTitle) {
    if (!canSort) {
      return headerIcon ? (
        <div className="w-100 d-flex justify-content-center">
          <FontAwesomeIcon className="mx-2" icon={headerIcon} />
        </div>
      ) : null;
    } else {
      return (
        <>
          {headerIcon && <FontAwesomeIcon className="mr-2" icon={headerIcon} />}
          <ColumnSorter sortValue={sortValue} sortType={sortType} />
        </>
      );
    }
  } else {
    return (
      <>
        <div className="d-flex align-items-center">
          {headerIcon && <FontAwesomeIcon className="mr-2" icon={headerIcon} />}
          <div className="text-truncate disable-selection" style={style}>
            {typeof Title === "function" ? <Title /> : column.title}
          </div>
          {canSort && (
            <ColumnSorter sortValue={sortValue} sortType={sortType} />
          )}
        </div>
      </>
    );
  }
};

const HeaderColumn = ({
  column,
  columnSize,
  sortValue,
  onSortValueChange,
  areAllItemsSelected,
  onSelectionChange,
  disableResize,
  left
}: HeaderColumnProps) => {
  const canSort = column.sortable !== undefined ? column.sortable : true;
  const sortType = sortValue && sortValue.type;

  const handleToggleOrder = () => {
    let type;

    if (!sortValue) type = "asc";
    else if (sortValue.type === "asc") type = "desc";

    onSortValueChange(column.field, type);
  };

  const style = canSort
    ? {
        marginRight: 30
      }
    : undefined;

  const {
    // title: Title,
    sticky
    // headerIcon
  } = column;
  const resolvedColumnSize = getResolvedColumnWidth(column, columnSize);
  return (
    <th
      className={classnames({
        sortable: canSort,
        sorted: Boolean(sortType)
      })}
      onClick={canSort ? handleToggleOrder : undefined}
      style={{
        width: resolvedColumnSize,
        left,
        zIndex: sticky ? 4 : undefined
      }}
    >
      {column.selectable ? (
        <Checkbox
          className="m-0"
          onChange={onSelectionChange}
          checked={areAllItemsSelected}
        />
      ) : (
        <>
          <ColumnTitle
            column={column}
            sortValue={sortValue}
            sortType={sortType}
            style={style}
          />
          {!disableResize && (
            <ColumnResizer
              field={column.field}
              minSize={column.minSize || 40}
              size={resolvedColumnSize}
            />
          )}
        </>
      )}
      {/* <div className="d-flex align-items-center">
        {headerIcon && <FontAwesomeIcon className="mr-2" icon={headerIcon} />}
        {!Boolean(column.noTitle) && (
          <div className="text-truncate disable-selection" style={style}>
            {typeof Title === "function" ? <Title /> : column.title}
          </div>
        )}
      </div>
      {column.selectable ? (
        <Checkbox
          className="m-0"
          onChange={onSelectionChange}
          checked={areAllItemsSelected}
        />
      ) : (
        <>
          {canSort && (
            <div className="sortable-icon">
              {sortValue && (
                <span style={{ color: "rgb(30, 115, 240)" }}>
                  {sortValue.position}
                </span>
              )}
              <GridSort asc={sortType === "asc"} desc={sortType === "desc"} />
            </div>
          )}
          {!disableResize && (
            <ColumnResizer
              field={column.field}
              minSize={column.minSize || 40}
              size={resolvedColumnSize}
            />
          )}
        </>
      )} */}
    </th>
  );
};

interface VirtualGridHeader {
  columns: VirtualGridColumnProps[];
  sort?: SortType[];
  disableHeader?: any;
  onSort?: (newSort: SortType[]) => void;
  areAllItemsSelected: boolean;
  onSelectedItemsChange?: any;
  data: any[];
  disableResize?: boolean;
}

interface SortDictValue {
  type: string;
  position: number;
}

interface SortDict {
  [columnField: string]: SortDictValue;
}

const ColumnChooserShortcut = ({ toggleColumnChooser }: any) => {
  return (
    <th className="chooser " onClick={toggleColumnChooser}>
      <div className="d-flex align-items-center justify-content-center">
        <FontAwesomeIcon className="text-primary" icon={faPlus} />
      </div>
    </th>
  );
};

const VirtualGridHeaderComponent = React.memo(function Header({
  columns,
  sort,
  disableHeader,
  onSort,
  areAllItemsSelected,
  onSelectedItemsChange,
  data,
  disableResize
}: VirtualGridHeader) {
  const sortDict = useMemo(() => {
    if (!sort) return {};

    const dict: SortDict = {};

    for (let i = 0; i < sort.length; i++) {
      const sortItem = sort[i];

      const { column, type } = sortItem;

      dict[column] = {
        type,
        position: i + 1
      };
    }

    return dict;
  }, [sort]);

  const handleSortChange = (columnField: string, type?: string) => {
    if (!sort || !onSort) return;

    const index = sort.findIndex((sv) => sv.column === columnField);
    const valueExists = index !== -1;

    const newSort = [...sort];
    if (!type) {
      if (valueExists) {
        newSort.splice(index, 1);
      }
    } else {
      if (valueExists) {
        const oldItem = newSort[index];
        newSort[index] = { ...oldItem, type };
      } else {
        newSort.push({
          column: columnField,
          type
        });
      }
    }
    onSort(newSort);
  };

  const handleSelectionChange = () => {
    if (!onSelectedItemsChange) return;
    onSelectedItemsChange((selectedItems: any[]) => {
      const newSelectedItems = [...selectedItems];

      if (areAllItemsSelected) {
        for (const item of data) {
          const index = newSelectedItems ? newSelectedItems.indexOf(item) : -1;
          if (index !== -1) newSelectedItems.splice(index, 1);
        }
      } else {
        for (const item of data) {
          const index = newSelectedItems ? newSelectedItems.indexOf(item) : -1;
          if (index === -1) newSelectedItems.push(item);
        }
      }
      return newSelectedItems;
    });
  };
  //
  const columnsSize = useColumnsSize();

  const { leftDict } = useStickyInfo();

  const toggleColumnChooser = useContext(VirtualGridColumnChooserContext);

  return (
    <thead>
      <tr>
        {columns.map((c, i) => {
          return (
            <HeaderColumn
              left={leftDict[i]}
              disableResize={disableResize}
              key={i}
              columnSize={columnsSize[c.field]}
              column={c}
              sortValue={sortDict[c.field]}
              onSortValueChange={handleSortChange}
              areAllItemsSelected={
                c.selectable ? areAllItemsSelected : undefined
              }
              onSelectionChange={
                c.selectable ? handleSelectionChange : undefined
              }
            />
          );
        })}
        {toggleColumnChooser && (
          <ColumnChooserShortcut toggleColumnChooser={toggleColumnChooser} />
        )}
        <th style={{ width: "auto" }} />
      </tr>
    </thead>
  );
});

export const VirtualGridRow = VirtualGridRowComp;

interface SelectionType {
  areAllItemsSelected: boolean;
  dict: { [valueKey: number]: boolean };
}

const useSelectionDict = (data: any[], selectedItems?: any[]) => {
  return useMemo(() => {
    if (!selectedItems) return { dict: {}, areAllItemsSelected: false };

    const dict: any = {};
    let totalCount = 0;
    for (const selectedItem of selectedItems) {
      if (data && data.indexOf(selectedItem) !== -1)
        totalCount = totalCount + 1;

      dict[selectedItem] = true;
    }
    const areAllItemsSelected = data ? totalCount === data.length : false;

    return { dict, areAllItemsSelected } as SelectionType;
  }, [selectedItems, data]);
};
const VirtualGrid = React.memo((props: VirtualGridProps) => {
  const {
    data,
    RowComponent,
    height,
    columns,
    columnsSize,
    onColumnsSizeChange,
    sort,
    onSort,
    getKey,
    disableBorder,
    isLoading,
    disableBackground,
    selectedItems,
    disableHeader,
    onSelectedItemsChange,
    showTotals,
    disableResize,
    onToggleColumnChooser
  } = props;

  const containerRef = useRef<any>();

  const { style, resolvedIndexes, resolvedData } = useVirtualGridData(
    height,
    data,
    containerRef
  );

  const { dict, areAllItemsSelected } = useSelectionDict(data, selectedItems);

  useEffect(() => {
    if (isLoading && containerRef.current) containerRef.current.scrollTop = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const rColumns = useMemo(() => {
    const rColumns = [...columns];
    return rColumns.sort((a, b) => {
      return a.sticky === b.sticky ? 0 : a.sticky ? -1 : 1;
    });
  }, [columns]);

  return (
    <HeightContext.Provider value={height}>
      <div
        tabIndex={0}
        ref={containerRef}
        // onScroll={verifyCicle}
        className={classnames("of-y-auto ar-virtual-grid-container rounded", {
          disableBorder: disableBorder
        })}
      >
        <div>
          <table
            className={classnames("ar-virtual-grid", {
              disableHeader: disableHeader,
              disableBackground: disableBackground
            })}
          >
            <ResizeProvider
              columns={rColumns}
              columnsSize={columnsSize}
              onColumnsSizeChange={onColumnsSizeChange}
            >
              <VirtualGridColumnChooserProvider
                setModalOpen={onToggleColumnChooser}
              >
                <VirtualGridHeaderComponent
                  disableResize={disableResize}
                  areAllItemsSelected={areAllItemsSelected}
                  onSelectedItemsChange={onSelectedItemsChange}
                  sort={sort}
                  disableHeader={disableHeader}
                  onSort={onSort}
                  columns={rColumns}
                  data={data}
                />

                {resolvedIndexes && (
                  <VirtualGridBody
                    getKey={getKey}
                    selectedItemsDict={dict}
                    onSelectedItemsChange={onSelectedItemsChange}
                    style={style}
                    columns={rColumns}
                    data={resolvedData}
                    indexes={resolvedIndexes}
                    RowComponent={RowComponent}
                  />
                )}
                {showTotals && data && <VirtualGridFooter columns={columns} />}
              </VirtualGridColumnChooserProvider>
            </ResizeProvider>
          </table>
        </div>
      </div>
    </HeightContext.Provider>
  );
});

export default VirtualGrid;
