import React from "react";
import RoundedBadge from "../../Components/RoundedBadge/RoundedBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import classnames from "classnames";
import {
  CallFormButton,
  ClientFormButton,
  ContractFormButton,
  DealFormButton,
  InterventionFormButton,
  ProjectFormButton,
  SubscriptionFormButton,
  TaskFormButton,
  TicketFormButton
} from "../Form/FormButtons";
import TextEllipsis from "../../Components/TextEllipsis/TextEllipsis";

const GetFormButtonViaProps = (props) => {
  const {
    Task,
    Project,
    Ticket,
    TimeCharge,
    Call,
    Client,
    Contract,
    Deal,
    Subscription
  } = props;

  if (Task) return TaskFormButton;
  else if (Project) return ProjectFormButton;
  else if (Subscription) return SubscriptionFormButton;
  else if (Ticket) return TicketFormButton;
  else if (TimeCharge) return InterventionFormButton;
  else if (Call) return CallFormButton;
  else if (Client) return ClientFormButton;
  else if (Contract) return ContractFormButton;
  else if (Deal) return DealFormButton;
  else return null;
};

const DynamicItem = ({
  StatusComponent,
  IconComponent,
  NameComponent,
  item,
  onClick,
  Popup,
  disablePopup,
  PopupOptions,
  CanView,
  ...props
}) => {
  const FormButton = GetFormButtonViaProps(props);
  const {
    Task,
    Project,
    Ticket,
    TimeCharge,
    Call,
    Client,
    Contract,
    Deal,
    Subscription
  } = props;

  const { Status, Name, NoTagDescription, Id } = item;

  return (
    <div
      // onClick={onClick}
      onMouseOver={onClick}
      className={classnames(
        "h-100 fs-12 text-truncate text-black d-flex align-items-center w-100",
        {
          "cursor-pointer": Boolean(onClick)
        }
      )}
    >
      <div className="flex-1 d-flex align-items-center text-truncate">
        <div className="d-inline-flex align-items-center mr-1">
          {Status && StatusComponent ? (
            <StatusComponent Icon={IconComponent()} value={Status} hideName />
          ) : typeof IconComponent === "function" ? (
            IconComponent()
          ) : null}
          {/* <IconComponent /> */}
        </div>
        {!disablePopup && Popup ? (
          <Popup
            task={Task}
            project={Project}
            ticket={Ticket}
            intervention={TimeCharge}
            call={Call}
            client={Client}
            contract={Contract}
            deal={Deal}
            subscription={Subscription}
          >
            <FormButton
              forceOnlyChildren
              id={Id}
              vType="link-primary"
              className="ar-treeview-orin-item-sidebar-link fs-12 overflow-hidden text-black"
              onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
                e.preventDefault();
                // openForm({
                //   type: moduleTypes[valueProp + "s"],
                //   props: {
                //     id: Id,
                //   },
                // });
              }}
            >
              <TextEllipsis
                // length={15}
                truncate
                text={Name || NoTagDescription}
              />
            </FormButton>
          </Popup>
        ) : CanView ? (
          <FormButton
            forceOnlyChildren
            id={Id}
            vType="link-primary"
            className="ar-treeview-orin-item-sidebar-link fs-12 overflow-hidden text-black"
            onClick={(e) => {
              e.nativeEvent.stopImmediatePropagation();
              e.preventDefault();
              // openForm({
              //   type: moduleTypes[valueProp + "s"],
              //   props: {
              //     id: Id,
              //   },
              // });
            }}
          >
            <div
              style={{ textAlign: "initial" }}
              className="mr-2 text-truncate"
            >
              <TextEllipsis
                length={15}
                truncate
                text={Name || NoTagDescription}
              />
            </div>
          </FormButton>
        ) : (
          <div style={{ textAlign: "initial" }} className="mr-2 text-truncate">
            <TextEllipsis
              length={15}
              truncate
              text={Name || NoTagDescription}
            />
          </div>
        )}
      </div>

      {CanView === false && <FontAwesomeIcon className="ml-1" icon={faLock} />}

      <RoundedBadge type="white" className="flex-0-0-auto ml-2 border">
        Nº {Id}
      </RoundedBadge>

      {/* <span className="flex-0-0-auto"></span> */}
    </div>
  );
};

export const TreeviewItemDetail = ({
  options,
  onClick,
  CanView,
  disablePopup
}) => {
  const {
    StatusComponent,
    IconComponent,
    NameComponent,
    value,
    Popup,
    PopupOptions,
    ...rest
  } = options;

  return (
    <DynamicItem
      disablePopup={disablePopup}
      CanView={CanView}
      onClick={onClick}
      NameComponent={NameComponent}
      IconComponent={IconComponent}
      StatusComponent={StatusComponent}
      item={value}
      Popup={Popup}
      PopupOptions={PopupOptions}
      {...rest}
    />
  );
};
