import React from "react";
import { connect } from "react-redux";
import AccountDropdownInput from "../Accounts/AccountDropdownInput";
import ContactListItem from "./ContactListItem";

const ContactDropdownInput = ({ contact, ...rest }) => {
  return <AccountDropdownInput account={contact} {...rest} />;
};

const ContactDropdownInputt = ({ ...props }) => {
  const { contact } = props;

  return (
    // <DropdownInputContainer className="w-100">
    <ContactListItem contact={contact} enableInput {...props} />
    // </DropdownInputContainer>
  );
};

export default ContactDropdownInput;

const mapStateToProps = (state, ownProps) => {
	
  if (!ownProps.contact) return {};
  return {
    contact: state.Entities.SpaceContacts && state.Entities.SpaceContacts[ownProps.contact],
  };
};

export const ConnectedContactDropdownInput = connect(mapStateToProps)(
  ContactDropdownInputt
);
