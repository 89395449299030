import { useQuery, getEntity } from "./IOClient";
import { useMemo } from "react";
import { useSpace } from "../Contexts/SpaceContext";
import { BarLoader } from "../Components/GlobalLoader/GlobalLoader";

export const useConnectedSchemaGetter = (id, schema, getV) => {
  const space = useSpace();
  const resolvedItem = getEntity(schema, id);

  const resolvedSchema = useMemo(() => {
    if (getV) return [schema];
    else return schema;
  }, [getV, schema]);

  const resolvedUrl = useMemo(() => {
    if (!id) return null;
    if (!resolvedItem) {
      if (schema.options.getForcedEndpoint)
        return (
          schema.options.getForcedEndpoint(space.Id) +
          `?query=${getV ? getV(id) : id}`
        );
      return schema.getEndpoint(space.Id) + `/${getV ? getV(id) : id}`;
    } else return null;
  }, [getV, id, resolvedItem, schema, space.Id]);

  useQuery(resolvedUrl, resolvedSchema);

  if (!id) return <div>―</div>;

  if (!resolvedItem)
    return (
      <div className="position-relative mt-2" style={{ height: 5 }}>
        <BarLoader isLoading />
      </div>
    );

  return null;
};
