import React, { useRef, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/pro-duotone-svg-icons";
import {
  History,
  HistoryType
} from "../../Components/DetailsView/History/History";
import Popup, { usePopupOpenState } from "../../Components/Popup/Popup";
import classnames from "classnames";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent
} from "../../Components/Sidebar/SidebarV2Helper";
import { FormattedMessage } from "react-intl";
import { ObfuscateProvider } from "../Space/Space2";
import { faThumbtack, faXmark } from "@fortawesome/pro-light-svg-icons";

const HistorySettings = {
  containerClassName: "cursor-auto flex-1 h-100 of-y-auto",
  itemClassName: "mx-4",
  loadingClassName: "mx-4 my-3",
  TitleComponent: ({ formattedDate }) => {
    return (
      <div className="ar-sidebar-sticky-title border-top fs-14 fw-medium text-black text-capitalize py-3 px-4 mb-2 border-bottom">
        {formattedDate}
      </div>
    );
  }
};

const options = [
  HistoryType.AllButNotes,
  HistoryType.Billing,
  HistoryType.Call,
  HistoryType.Client,
  HistoryType.Contract,
  // HistoryType.Notes,
  HistoryType.Project,
  HistoryType.Task,
  HistoryType.Ticket,
  HistoryType.Timecharge,
  HistoryType.Deals,
  HistoryType.Subscriptions
];

// const optionsText = {
//   [HistoryType.All]: "Todos",
//   [HistoryType.Billing]: "Faturação",
//   [HistoryType.Call]: "Chamadas",
//   [HistoryType.Client]: "Clientes",
//   [HistoryType.Contract]: "Contratos",
//   [HistoryType.Notes]: "Notas",
//   [HistoryType.Project]: "Projetos",
//   [HistoryType.Task]: "Tarefas",
//   [HistoryType.Ticket]: "Tickets",
//   [HistoryType.Timecharge]: "Registos de tempo"
// };
const optionsTranslatedText = {
  [HistoryType.AllButNotes]: "ALL",
  [HistoryType.Billing]: "BILLING",
  [HistoryType.Call]: "CALLS",
  [HistoryType.Client]: "ACCOUNTS",
  [HistoryType.Contract]: "CONTRACTS",
  // [HistoryType.Notes]: "NOTES",
  [HistoryType.Project]: "PROJECTS",
  [HistoryType.Task]: "TASKS",
  [HistoryType.Ticket]: "CASES",
  [HistoryType.Timecharge]: "TIMES",
  [HistoryType.Deals]: "DEALS",
  [HistoryType.Subscriptions]: "SUBSCRIPTIONS"
};

export const BasicFilterLink = ({ value, onClick, isSelected }) => {
  return (
    <div
      className={classnames("ssi-link py-2 px-3 mb-1", {
        selected: isSelected
      })}
      onClick={() => onClick(value)}
    >
      <FormattedMessage id={optionsTranslatedText[value]} />
      {/* {optionsText[value]} */}
    </div>
  );
};

const HistoryFilter = React.memo(({ value, onClick }) => {
  const anchorRef = useRef();
  const popupRef = useRef();

  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);

  const handleClick = (v) => {
    toggleIsOpen(false);
    onClick(v);
  };

  return (
    <React.Fragment>
      <div
        ref={anchorRef}
        className="d-inline-flex align-items-center cursor-pointer"
        onClick={(e) => toggleIsOpen(true)}
      >
        <div className="mr-2">
          <FormattedMessage id="FILTERS" />:
        </div>
        <div className="text-primary">
          <span className="mr-1">
            <FormattedMessage id={optionsTranslatedText[value]} />
          </span>
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      </div>
      <Popup
        isOpen={isOpen}
        domRef={popupRef}
        placement="bottom-start"
        anchorEl={anchorRef.current}
      >
        <div style={{ padding: 8 }} className="bg-white">
          {options.map((o, i) => {
            return (
              <BasicFilterLink
                key={o}
                value={o}
                onClick={handleClick}
                isSelected={o === value}
              />
            );
          })}
        </div>
      </Popup>
    </React.Fragment>
  );
});

const PersonalHistoryForm = () => {
  const [type, setType] = useState(HistoryType.AllButNotes);

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id={"HISTORY"} />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <div className="h-100 d-flex flex-column">
          <div className="px-4 py-3 shadow-sm">
            <HistoryFilter value={type} onClick={setType} />
          </div>
          <History
            personal
            disableTabs
            noFilter
            settings={HistorySettings}
            type={type}
          />
        </div>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export const PersonalHistoryButton = ({
  className,
  pinItem,
  isPinned,
  labelText,
  buttonClass,
	pinClass
}) => {
  const [openSidebar] = useSidebar();

  const ObfuscateState = useContext(ObfuscateProvider);
  const handleClick = (e) => {
		e.stopPropagation()
    if (ObfuscateState === true) e.preventDefault();
    else {
      openSidebar(<PersonalHistoryForm />, "Histórico");
    }
  };

  return (
    <div
      style={{
        opacity: ObfuscateState === true && 0.3,
        pointerEvents: ObfuscateState === true && "none"
      }}
      className={className || "ml-1 "}
    >
      <div
        onClick={handleClick}
        // className={`mr-0 ar-header-rounded-icon BellButton position-relative cursor-pointer d-flex align-items-center justify-content-center`}
        className={classnames("ar-header-rounded-icon flex-0-0-auto", buttonClass)}
      >
        <FontAwesomeIcon icon={faHistory} size="lg" />
        {labelText && (
          <span style={{ fontWeight: 500 }} className="ml-3 flex-1 text-truncate">
            {labelText}
          </span>
        )}
        {pinItem && (
          <div className={classnames("flex-0-0-auto", pinClass)} onClick={pinItem}>
            <FontAwesomeIcon
              icon={isPinned ? faXmark : faThumbtack}
              
            />
          </div>
        )}
      </div>
    </div>
  );
};
