import { faPeopleArrows } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import "./ContactsMerge.css";
import Modal from "../../../Components/Modal/Modal";
import classnames from "classnames";
import ContactsMergeFirstPage from "./ContactsMergeFirstPage";
import ContactsMergeSecondPage from "./ContactsMergeSecondPage";
import ContactsMergeThirdPage from "./ContactsMergeThirdPage";

const HeaderImportPage = ({ currentPage }) => {
  return (
    <div className="flex-1 d-flex justify-content-center mb-5">
      <div className="d-flex flex-column">
        <div className={classnames("d-flex HeaderImportPaging")}>
          <div
            className={classnames(
              "HeaderPagingCircle d-flex justify-content-center align-items-center",
              { Midway: currentPage === 0, Complete: currentPage > 0 }
            )}
          >
            <div className="HeaderPageTitle mt-2 text-black">
              <FormattedMessage id="SELECT_PRIMARY" />
            </div>
            <FontAwesomeIcon size="sm" icon={faCheck} />
          </div>
          <div
            className={classnames("HeaderPagingCircle-WithLine", {
              Complete: currentPage > 0
            })}
          ></div>
          <div
            className={classnames(
              "HeaderPagingCircle d-flex justify-content-center align-items-center",
              { Midway: currentPage === 1, Complete: currentPage > 1 }
            )}
          >
            <div className="HeaderPageTitle mt-2 text-black">
              <FormattedMessage id="CORRESPONDENCE" />
            </div>
            <FontAwesomeIcon size="sm" icon={faCheck} />
          </div>
          <div
            className={classnames("HeaderPagingCircle-WithLine", {
              Complete: currentPage > 1
            })}
          ></div>
          <div
            className={classnames(
              "HeaderPagingCircle d-flex justify-content-center align-items-center",
              { Midway: currentPage === 2, Complete: currentPage > 2 }
            )}
          >
            <div className="HeaderPageTitle mt-2 text-black">
              <FormattedMessage id="CONFIRM" />
            </div>
            <FontAwesomeIcon size="sm" icon={faCheck} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactsMergeContent = ({ isOpen, handleClose, selectedItems, ClearSelection }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedContact, setSelectedContact] = useState(undefined);
  const [finalContact, setFinalContact] = useState(undefined);

  return (
    <div className="bg-white ImportComponent ModalContainer position-relative">
      <div className="h-100 d-flex flex-column">
        <div className="d-flex fs-18 text-black mb-2">
          <div className="mr-2">
            <FontAwesomeIcon icon={faPeopleArrows} />
          </div>
          <div className="fw-bold">
            <FormattedMessage id="MERGE_CONTACTS" />
          </div>
        </div>
        <div className="text-black fs-14 d-flex">
          <div className="position-absolute">
            <FormattedMessage id="STEP" /> {currentStep + 1}{" "}
            <FormattedMessage id="OF" /> 3
          </div>
          <HeaderImportPage currentPage={currentStep} />
        </div>

        {currentStep === 0 && (
          <ContactsMergeFirstPage
            selectedGridContacts={selectedItems}
            changePage={setCurrentStep}
            selectedContact={selectedContact}
            SetSelectedContact={setSelectedContact}
            handleClose={handleClose}
            handleNext={() => {
              setCurrentStep(1);
            }}
          />
        )}
        {currentStep === 1 && (
          <ContactsMergeSecondPage
            dNone={currentStep !== 1}
            selectedGridContacts={selectedItems}
            selectedContact={finalContact || selectedContact}
            handleClose={handleClose}
            handlePrevious={() => {
              setCurrentStep(0);
            }}
            handleNext={(contact) => {
              setFinalContact(contact);
              setCurrentStep(2);
            }}
          />
        )}
        {currentStep === 2 && (
          <ContactsMergeThirdPage
						finalContact={finalContact}
						ClearSelection={ClearSelection}
            handleClose={handleClose}
            selectedGridContacts={selectedItems}
            handlePrevious={() => {
              setCurrentStep(1);
            }}
          />
        )}
      </div>
    </div>
  );
};

const ContactsMerge = ({ isOpen, handleClose, selectedItems, ClearSelection }) => {
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      {isOpen && (
        <ContactsMergeContent
					isOpen={isOpen}
					ClearSelection={ClearSelection}
          handleClose={handleClose}
          selectedItems={selectedItems}
        />
      )}
    </Modal>
  );
};

export default ContactsMerge;
