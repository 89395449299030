import React, { useEffect } from "react";
import KeyedDropdown from "../Dropdown/KeyedDropdown";
import { DropdownBaseClassnameContext } from "../_Dropdown/DropdownBase";

import { useServerAwareState } from "./ServerGrid";

// const pageSizes = [50, 100, 250, 500];
// const pageSizes = [25, 50, 100];
const pageSizes = [25, 50];

export const PageSizeSelect = (props) => {
  const stuff = useServerAwareState();

  useEffect(() => {
    const { onParamsChange, params } = stuff;
    const { top } = params;
    if (top !== 25) {
      onParamsChange({
        top: 25
      });
    }
  }, [stuff]);

  return null;

  // eslint-disable-next-line no-unreachable
  // if (!stuff)
  //   return (
  //     <div className="dropdown-container bg-white ar-dropdown-container c-grid-page-selector">
  //       50
  //     </div>
  //   );

  // const handleChange = (v) => {
  //   onParamsChange({
  //     top: v
  //   });
  // };

  // eslint-disable-next-line no-unreachable
  // return (
  //   <DropdownBaseClassnameContext.Provider value={"px-2"}>
  //     <KeyedDropdown
  //       className="px-2"
  //       closeOnSelect
  //       onChange={handleChange}
  //       value={top}
  //       options={pageSizes}
  //       {...props}
  //     />
  //   </DropdownBaseClassnameContext.Provider>
  // );
};
