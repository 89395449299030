import React, { useContext, useMemo } from "react";
import "./ReceptionInboxButton.css";
import { ObfuscateProvider } from "../Space/Space2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox } from "@fortawesome/pro-duotone-svg-icons";
import { ReceptionControlRoute } from "../Space/SpaceHelper";
import { HeaderNavLink } from "../../Components/Header/HeaderV2";
import { useUnreadEntities } from "../../Components/UnreadEntities/UnreadEntities";
import classnames from "classnames";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { useIntl } from "react-intl";

const ReceptionInboxButton = ({
  className,
  labelText,
  vertical,
  buttonClass,
  disableCount,
  size,
  activeClassName
}) => {
  const intl = useIntl();
  const unresolvedUnreads = useUnreadEntities();
  const unreads = useMemo(() => {
    if (!unresolvedUnreads) return {};
    else return unresolvedUnreads;
  }, [unresolvedUnreads]);
  const ResolvedUnreadsCount = useMemo(() => {
    const serviceObj = {};

    serviceObj.Calls = unreads.UnreadCallMessages;
    serviceObj.Cases = unreads.UnreadTicketMessages;
    serviceObj.Tasks = unreads.UnreadTaskMessages;
    serviceObj.Projects = unreads.UnreadProjectMessages;
    serviceObj.Time = unreads.UnreadTimeChargeMessages;
    let serviceCount = 0;
    for (const key in serviceObj) {
      if (serviceObj.hasOwnProperty(key)) {
        const element = serviceObj[key];
        serviceCount += element;
      }
    }
    serviceObj.ServiceAll = serviceCount;

    const comercialObj = {};
    comercialObj.Subscriptions = unreads.UnreadSubscriptionMessages;
    comercialObj.Contracts = unreads.UnreadContractMessages;
    comercialObj.Clients = unreads.UnreadClientMessages;
    comercialObj.Deals = unreads.UnreadDealMessages;
    let comercialCount = 0;
    for (const key in comercialObj) {
      if (comercialObj.hasOwnProperty(key)) {
        const element = comercialObj[key];
        comercialCount += element;
      }
    }
    comercialObj.ComercialAll = comercialCount;

    return {
      Email: unreads.UnreadEmailConversations,
      All: comercialCount + serviceCount + unreads.UnreadEmailConversations,
      ...comercialObj,
      ...serviceObj
    };
  }, [unreads]);

  const resolvedUnreadCount = ResolvedUnreadsCount.All
    ? ResolvedUnreadsCount.All > 99
      ? "99+"
      : ResolvedUnreadsCount.All
    : null;

  const ObfuscateState = useContext(ObfuscateProvider);
  const { Notifications } = useCurrentAccount();
  // const accountSpace = useCurrentAccountSpace();

  // if(!(accountSpace.Space === 1 ||accountSpace.Space === 3)) return null

  return (
    <HeaderNavLink
      activeClassName={activeClassName}
      routeModule={ReceptionControlRoute}
      className={classnames("ar-header-rounded-icon", className || "ml-1")}
    >
      <div
        style={{
          opacity: ObfuscateState && 0.3,
          pointerEvents: ObfuscateState && "none"
        }}
        className={className}
      >
        <div
          className={classnames(
            `mr-0 ar-header-rounded-icon position-relative InboxButton position-relative cursor-pointer d-flex align-items-center`,
            buttonClass
          )}
        >
          {resolvedUnreadCount > 0 && (
            <div
              // style={{ left: 34, top: 4, width: 10, height: 10, outline: "none", background: "#FF8066" }}
              className={classnames("unread-count newDesign position-absolute")}
            >
              {/* <span>{resolvedNotificationsCount}</span> */}
            </div>
          )}
          <FontAwesomeIcon icon={faInbox} size={size ? size : "lg"} />
          {labelText && (
            <span
              style={{ fontWeight: 500, textDecoration: "none" }}
              className="ml-12"
            >
              {labelText}
            </span>
          )}
          {!disableCount && resolvedUnreadCount && (
            <div style={{ marginRight: "6px", marginLeft: "auto" }}>
              <div
                title={intl.formatMessage({ id: "NOT_READ" })}
                className="ssi-unread-count-pill"
              >
                {resolvedUnreadCount}
              </div>
            </div>
          )}
        </div>
      </div>
    </HeaderNavLink>
  );
};

export default ReceptionInboxButton;
