import React, { useEffect, useState } from "react";
import { getConnectedEdges, getOutgoers, isEdge } from "react-flow-renderer";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../../../Components/Button/Button";
import FormInput from "../../../../Components/Forms/FormInput/FormInput";
import Modal from "../../../../Components/Modal/Modal";
import { useSpacePost } from "../../../../Helpers/IOClient";
import {
  CallDropdown,
  ClassificationDropdown,
  ClientDropdown,
  ContactDropdown,
  ContractDropdown,
  DealDropdown,
  InterventionDropdown,
  ProjectDropdown,
  SubscriptionDropdown,
  TaskDropdown,
  TicketDropdown
} from "../../../AdvancedMultiInputs";
import {
  AutomationTestClickContext,
  AutomationTestLoadingRequestContext,
  AutomationTestRequestCommunicator,
  AutomationTestRequestContext
} from "../AutomationDetailsHelper";
import classnames from "classnames";
import { TriggerTypeEnum } from "../NodeTypes";
import { useAccount } from "../../../../Hooks/EntityHooks";
import { useToast } from "../../../../Components/Toast/ToastProvider";
import { handleError } from "../../../../Helpers/MiscHelper";

const TextArea = ({ className, ...props }) => {
  return (
    <textarea
      {...props}
      className={classnames(
        className,
        "ssi-control ar-html-input rounded w-100"
      )}
    />
  );
};

const TriggerTargetDropdown = ({ error, triggerType, onChange, value }) => {
  const intl = useIntl();
  switch (triggerType) {
    case TriggerTypeEnum.trigger_client_creation:
      return (
        <FormInput
          inputType={ClientDropdown}
          value={value}
          allAccounts
          textId={"ACCOUNTS"}
          onChange={onChange}
        />
      );
    case TriggerTypeEnum.trigger_contact_creation:
      return (
        <FormInput
          inputType={ContactDropdown}
          value={value}
          allContacts
          textId={"CONTACTS"}
          onChange={onChange}
        />
      );

    case TriggerTypeEnum.trigger_deal_creation:
      return (
        <FormInput
          inputType={DealDropdown}
          value={value}
          allDeals
          textId={"DEAL"}
          onChange={onChange}
        />
      );
    case TriggerTypeEnum.trigger_contract_creation:
      return (
        <FormInput
          inputType={ContractDropdown}
          value={value}
          allContracts
          textId={"CONTRACT"}
          onChange={onChange}
        />
      );
    case TriggerTypeEnum.trigger_subscription_creation:
      return (
        <FormInput
          inputType={SubscriptionDropdown}
          value={value}
          allSubscriptions
          textId={"SUBSCRIPTION"}
          onChange={onChange}
        />
      );
    case TriggerTypeEnum.trigger_call_creation:
      return (
        <FormInput
          inputType={CallDropdown}
          value={value}
          textId={"CALLS"}
          onChange={onChange}
        />
      );

    case TriggerTypeEnum.trigger_project_creation:
      return (
        <FormInput
          inputType={ProjectDropdown}
          value={value}
          allProjects
          textId={"PROJECTS"}
          onChange={onChange}
        />
      );
    case TriggerTypeEnum.trigger_ticket_creation:
      return (
        <FormInput
          inputType={TicketDropdown}
          value={value}
          allTickets
          textId={"CASES"}
          onChange={onChange}
        />
      );
    case TriggerTypeEnum.trigger_task_creation:
      return (
        <FormInput
          inputType={TaskDropdown}
          value={value}
          allTasks
          textId={"TASKS"}
          onChange={onChange}
        />
      );
    case TriggerTypeEnum.trigger_time_creation:
      return (
        <FormInput
          inputType={InterventionDropdown}
          value={value}
          textId={"TIMES"}
          onChange={onChange}
        />
      );
    case TriggerTypeEnum.trigger_classification_creation:
      return (
        <FormInput
          inputType={ClassificationDropdown}
          value={value}
          textId={"CLASSIFICATIONS"}
          onChange={onChange}
        />
      );

    case TriggerTypeEnum.trigger_webhook:
    case TriggerTypeEnum.trigger_manual:
      return (
        <FormInput
          error={error}
          errorMessage={"Json Invalid"}
          inputType={TextArea}
          value={value}
          textId={"VALUE"}
          onChange={onChange}
        />
      );
    default:
      return null;
  }
};

function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const WorkflowTestModalContent = ({
  triggerType,
  closeModal,
  handleWebhookSubmit,
  defaultInputValue,
  handleSubmit
}) => {
  const [target, setTarget] = useState(defaultInputValue);
  const [error, setError] = useState(false);
  const handleChange = (e) => {
    const { value } = e.target;
    if (error) setError(false);
    setTarget(value);
  };

  const onClick = (e) => {
    switch (triggerType) {
      case TriggerTypeEnum.trigger_webhook:
      case TriggerTypeEnum.trigger_manual:
        if (IsJsonString(target)) handleWebhookSubmit(target);
        else setError(true);
        break;
      case TriggerTypeEnum.trigger_cron:
        handleWebhookSubmit(
          JSON.stringify({
            targetDate: new Date().toISOString()
          })
        );
        break;

      default:
        handleSubmit(target);
        break;
    }
  };

  return (
    <div style={{ borderRadius: 7 }} className="p-4 bg-white w-450px">
      <div className="fs-16 mb-2 text-black fw-medium">
        <FormattedMessage id="SELECT_TEST_TARGET" />
      </div>
      <div className="mb-4">
        <TriggerTargetDropdown
          error={error}
          onChange={handleChange}
          value={target}
          triggerType={triggerType}
        />
      </div>
      <div className="d-flex w-100 align-items-center justify-content-end">
        <Button onClick={closeModal} className="mr-3" vType="danger-ghost">
          <FormattedMessage id="CANCEL" />
        </Button>
        <Button onClick={onClick}>
          <FormattedMessage id="SUBMIT" />
        </Button>
      </div>
    </div>
  );
};

const GetCutElement = (
  elements,
  currentNode,
  targetNodeId,
  ComputedElements = [],
  NodeDict = {}
) => {
  if (!currentNode) return elements;
  if (!NodeDict[currentNode.id]) ComputedElements.unshift(currentNode);
  NodeDict[currentNode.id] = true;
  if (currentNode.id === targetNodeId) return ComputedElements;
  const OutgoingNodes = getOutgoers(currentNode, elements);
  // for (const n of OutgoingNodes) {
  //   if (NodeDict[n.id]) continue;
  //   ComputedElements.unshift(n);
  // }
  for (const n of OutgoingNodes) {
    if (NodeDict[n.id]) continue;
    GetCutElement(elements, n, targetNodeId, ComputedElements, NodeDict);
  }
  return ComputedElements;
};

const AutomationTest = ({
  assignedTo,
  instaces,
  rootId,
  assinggTargetTrigger,
  children,
  automationId
}) => {
  // const intl = useIntl();
  const createToast = useToast();
  const [post, testRequest] = useSpacePost(
    `Automation/${automationId}/TestWorkflow`,
    null,
    {
      cache: false,
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success"
          // description: intl.formatMessage({ id: "SUCCESS" })
        });
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  const { data, loading, error } = testRequest;

  useEffect(() => {
    AutomationTestRequestCommunicator.dispatch({ data, loading, error });
  }, [data, error, loading, testRequest]);

  const [selectedNodeData, setSelectedNodeData] = useState();
  const [selectedNodeId, setSelectedNodeId] = useState();

  const [triggerNodeType, setTriggerNodeType] = useState();
  const user = useAccount(assignedTo);

  const handleTargetTest = (targetData) => {
    const elements = instaces.toObject().elements;
    const rootNode = elements.find((e) => e.id === rootId);
    const resolvedElements = GetCutElement(elements, rootNode, selectedNodeId);
    const allEdges = elements.filter((e) => isEdge(e));
    const resovledEdges = getConnectedEdges(resolvedElements, allEdges).filter(
      (e) => e.source !== selectedNodeId
    );

    const SelectedNodeIndex = resolvedElements.findIndex(
      (e) => e.id === selectedNodeId
    );
    const newBody = { ...resolvedElements[SelectedNodeIndex] };
    newBody.data = selectedNodeData;
    resolvedElements[SelectedNodeIndex] = newBody;
    const testForm = {
      json: JSON.stringify({
        items: [...resolvedElements, ...resovledEdges],
        rootId: rootId
      }),
      data: JSON.stringify({
        type: triggerNodeType,
        id: targetData
      }),
      userId: user.Id
    };
    setDefaultInputValue(targetData);
    toggleModal();
    post(testForm);
    assinggTargetTrigger(targetData);
  };
  const [defaultInputValue, setDefaultInputValue] = useState();

  const handleWebhookTest = (targetData) => {
    const elements = instaces.toObject().elements;
    const rootNode = elements.find((e) => e.id === rootId);
    const resolvedElements = GetCutElement(elements, rootNode, selectedNodeId);
    const allEdges = elements.filter((e) => isEdge(e));
    const resovledEdges = getConnectedEdges(resolvedElements, allEdges).filter(
      (e) => e.source !== selectedNodeId
    );

    const SelectedNodeIndex = resolvedElements.findIndex(
      (e) => e.id === selectedNodeId
    );
    const newBody = { ...resolvedElements[SelectedNodeIndex] };
    newBody.data = selectedNodeData;
    resolvedElements[SelectedNodeIndex] = newBody;
    const testForm = {
      json: JSON.stringify({
        items: [...resolvedElements, ...resovledEdges],
        rootId: rootId
      }),
      data: JSON.stringify(JSON.parse(targetData)),
      userId: user.Id
    };
    setDefaultInputValue(targetData);
    toggleModal();
    post(testForm);
  };

  const [modalStatus, setModalStatus] = useState(false);

  const toggleModal = (nodeId, nodeData) => {
    if (!instaces) return;
    const triggerNode = instaces
      .toObject()
      .elements.find((e) => e.id === rootId);
    const { type } = triggerNode || {};
    setTriggerNodeType(type);
    setSelectedNodeId(nodeId);
    setSelectedNodeData(nodeData);
    setModalStatus(!modalStatus);
  };

  const closeModal = () => {
    setSelectedNodeId(undefined);
    setModalStatus(false);
  };

  return (
    <AutomationTestClickContext.Provider value={toggleModal}>
      <AutomationTestRequestContext.Provider value={testRequest}>
        <AutomationTestLoadingRequestContext.Provider
          value={testRequest.loading}
        >
          <Modal enableCloseButton isOpen={modalStatus} onClose={closeModal}>
            <WorkflowTestModalContent
              closeModal={closeModal}
              defaultInputValue={defaultInputValue}
              handleSubmit={handleTargetTest}
              handleWebhookSubmit={handleWebhookTest}
              triggerType={triggerNodeType}
            />
          </Modal>
          {children}
        </AutomationTestLoadingRequestContext.Provider>
      </AutomationTestRequestContext.Provider>
    </AutomationTestClickContext.Provider>
  );
};

export default AutomationTest;
