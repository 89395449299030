const events = [];
window.events = events
const handleEscEvent = e => {
  const event = events[events.length - 1];
  event(e);
  // events.pop();
  // if (events.length === 0)
  //   document.removeEventListener("keydown", handleEscEvent);
};

const unsubscribeEvent = (length, func) => {
  events.pop();

  if (events.length === 0)
    document.removeEventListener("keydown", handleEscEvent);
};

export const subscribeUniqueEvent = func => {
  if (events.length === 0) document.addEventListener("keydown", handleEscEvent);

  events.push(func);

  return () => {
    unsubscribeEvent();
  };
};

const clickEvents = [];
const handleClick = e => {
  const event = clickEvents[clickEvents.length - 1];
  event(e);
};

export const subscribeUniqueClickEvent = func => {
  clickEvents.push(func);
  if (clickEvents.length === 0) document.addEventListener("click", handleClick);

  return () => {
    const i = clickEvents.findIndex(v => v === func);
    clickEvents.splice(i, 1);
    if (clickEvents.length === 0)
      document.removeEventListener("click", handleClick);
  };
};
