import React from "react";
import KeyedDropdown from "../../Dropdown/KeyedDropdown";
import { useIntl } from "react-intl";

export enum FilterConditionsEnum {
  Or = 1,
  And = 2
}

const ConditionOptions = [FilterConditionsEnum.Or, FilterConditionsEnum.And];

const ConditionProfile = ({ item, className }: any) => {
  let text;
  const intl = useIntl()
  switch (item) {
    case FilterConditionsEnum.Or:
      text = intl.formatMessage({id: "OR"});
      break;

    case FilterConditionsEnum.And:
      text = intl.formatMessage({id: "AND"});
      break;

    default:
      break;
  }

  return <span className={className}>{text}</span>;
};

export const FilterConditionsDropdown = (props: any) => {
  return (
    <KeyedDropdown
      options={ConditionOptions}
      valueComponent={ConditionProfile}
      closeOnSelect
      {...props}
    />
  );
};
