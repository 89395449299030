import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { useUserDocumentType } from "../../Hooks/EntityHooks";
import { DropdownInputContainer } from "../Dropdown/DropdownInput";

const DropdownInput = (props) => {
  const { userDocumentType, isTyping, ListItem } = props;

  return (
    <DropdownInputContainer className="w-100">
      {userDocumentType && !isTyping && <div>{userDocumentType.Name}</div>}
      <DropdownInput
        {...props}
        className="w-100"
        fallbackValue={userDocumentType && userDocumentType.Name}
      />
    </DropdownInputContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (!ownProps.account) return {};
  return {
    userDocumentType: state.Entities.UserDocumentType[ownProps.doc]
  };
};

export const ConnectedAccountDropdownInput =
  connect(mapStateToProps)(DropdownInput);

export const ConnectedUserDocumentTypeDropdownInput = ({ doc, ...rest }) => {
  const d = useUserDocumentType(doc);
  return <DropdownInput ListItem doc={d} {...rest} />;
};

export const ConnectedUserDocumentTypeProfile = ({ doc, ...rest }) => {
  const d = useUserDocumentType(doc);
  const intl = useIntl();
  if (!d) return <></>;
  else return <div>{intl.formatMessage({ id: d.Key })}</div>;
};
