import React, { useState, useCallback } from "react";
import { useSpaceQuery } from "../../../Helpers/IOClient";
import { spaceEmailConversationSchema } from "../../../config/schema";
import ReceptionEmailSummary from "./ReceptionEmailSummary";
import ReceptionEmailChat from "./ReceptionEmailChat";
import { useEntityReader } from "../../../Helpers/EntityHelper";
import { useEmailConversation } from "../../../Hooks/EntityHooks";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useParams, useHistory } from "react-router-dom";
import { useSpace } from "../../../Contexts/SpaceContext";
import { v4 as uuidv4 } from "uuid";
import { RefreshEmailChatConversation } from "../EmailChatComponent";

export const ConversationDataContext = React.createContext();

const InnerReceptionEmailDetails = ({ Loading, Error, id }) => {
  const [resolvedKey, setResolvedKey] = useState(id);

  const handleNewKey = useCallback(() => {
    const u = uuidv4();
    setResolvedKey(u);
  }, []);

  return (
    <RefreshEmailChatConversation.Provider value={handleNewKey}>
      <ReceptionEmailChat
        Loading={Loading || Error}
        key={resolvedKey}
        OpenedId={id}
      />
      {!Error && !Loading ? (
        <ReceptionEmailSummary OpenedId={id} />
      ) : (
        <div className="d-flex flex-column air-reception-description-container w-0"></div>
      )}
    </RefreshEmailChatConversation.Provider>
  );
};

export const ReceptionEmailDetails = ({ id }) => {
  const space = useSpace();
  const history = useHistory();

  const { data, loading, error } = useSpaceQuery(
    `EmailConversation/${id}`,
    spaceEmailConversationSchema,
    { cache: false }
  );

  const emailConv = useEmailConversation(data);

  useEntityReader(
    data ? emailConv : undefined,
    spaceEmailConversationSchema,
    "IsMessageRead",
    true
  );

  if (error && error.status) {
    history.replace(`/s/${space.Id}/inbox`);
  }

  return (
    <ConversationDataContext.Provider value={data}>
      <InnerReceptionEmailDetails Loading={loading} Error={error} id={id} />
    </ConversationDataContext.Provider>
  );
};
