import React, { useState, useMemo, useRef, useContext, useEffect } from "react";
import { usePostQuery, useQuery } from "../../Helpers/IOClient";
import { useSpace } from "../../Contexts/SpaceContext";
import "./Treeview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { TreeviewItemDetail } from "./TreeviewItems";
import classnames from "classnames";
import { useTreeViewOptions } from "./TreeviewHelper";
import { fk } from "../../Helpers/SchemaHelper";
import {
  spaceCallsSchema,
  spaceTicketsSchema,
  spaceTasksSchema,
  spaceInterventionsSchema,
  spaceProjectsSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceSubscriptionSchema,
  spaceClientSchema
} from "../../config/schema";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { FormattedMessage } from "react-intl";

export const useTreeviewPermissionQuery = (schema, id) => {
  const space = useSpace();
  return useQuery(
    `${schema?.getEndpoint(space.Id)}/${id}/Permissions`,
    schema,
    {
      autoFetch: false
    }
  );
};

export const useReceptionTreeviewPermissionQuery = (schema, id) => {
  const space = useSpace();
  return useQuery(
    schema && schema?.getEndpoint
      ? `${schema.getEndpoint(space.Id)}/${id}/Permissions`
      : null,
    schema
  );
};

const TreeviewIcon = ({
  hasChildren,
  isOpen,
  hasLoaded,
  IconComponent,
  CanView
}) => {
  if (!hasChildren || CanView === false) return null;

  if (isOpen && !hasLoaded) {
    return <LoaderSpinner className="text-primary" />;
  }
  return <FontAwesomeIcon icon={faChevronRight} />;
};

const TreeviewItem = React.memo(function TreeviewItem({
  item,
  isChild,
  disableActionButtons,
  disablePopup
}) {
  const [isOpen, setIsOpen] = useState(false);

  const itemOptions = useTreeViewOptions(item);

  const { HasChildren } = item;

  const { schema, Id, EntityButton, value, IconComponent } = itemOptions;

  const { refetch, data, loading } = useTreeviewPermissionQuery(schema, Id);

  const hasDetails = Boolean(data);

  const timeoutRef = useRef();

  const onEnter = () => {
    if (loading || hasDetails) return;
    timeoutRef.current = setTimeout(() => {
      refetch();
    }, 50);
  };

  const clearFetch = () => {
    if (!timeoutRef.current) return;
    clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
  };

  const onLeave = () => {
    clearFetch();
  };

  const [hasLoaded, setHasLoaded] = useState(false);

  // const [hasChildren, setHasChildren] = useState(true);

  const handleLoaded = (hasChildren) => {
    setHasLoaded(true);
    // setHasChildren(hasChildren);
  };

  const { CanView } = value;

  const handleChevronClick = () => {
    if (!CanView) return;
    setIsOpen((open) => !open);
  };

  // const [isSelected, setIsSelected] = useState(false)

  const handleSelection = useContext(TreeviewSelectionContext);

  const handleClick = handleSelection
    ? () => {
        if (handleSelection) handleSelection({ schema, Id });
      }
    : undefined;

  return (
    <>
      <div
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        className={classnames("ar-treeview-item", {
          open: isOpen,
          child: isChild
        })}
      >
        <div className="flex-1 d-flex align-items-center ar-treeview-item-content h-100">
          <div
            onClick={handleChevronClick}
            className={classnames("px-2 d-flex align-items-center h-100", {
              "cursor-pointer": HasChildren
            })}
          >
            <div className="ar-treeview-item-icon flex-0-0-auto">
              <TreeviewIcon
                CanView={CanView}
                hasChildren={HasChildren}
                isOpen={isOpen}
                IconComponent={IconComponent}
                hasLoaded={hasLoaded}
              />
            </div>
          </div>
          <TreeviewItemDetail
            disablePopup={disablePopup}
            CanView={CanView}
            onClick={handleClick}
            options={itemOptions}
          />
        </div>
        {!disableActionButtons && hasDetails && CanView && (
          <div
            className="ar-treeview-buttons justify-content-end d-flex align-items-center px-2"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <EntityButton minimal entity={value} />
          </div>
        )}
      </div>
      {isOpen && HasChildren && (
        <ChildTreeview
          disablePopup={disablePopup}
          disableActionButtons={disableActionButtons}
          onFirstLoad={handleLoaded}
          id={Id}
          schema={schema}
        />
      )}
    </>
  );
});

// const scrollOffset = 500;
const itemsPerRequest = 20;

const TreeviewSchema = {
  d: {
    results: [
      {
        Call: fk(spaceCallsSchema),
        Ticket: fk(spaceTicketsSchema),
        Task: fk(spaceTasksSchema),
        TimeCharge: fk(spaceInterventionsSchema),
        Project: fk(spaceProjectsSchema),
        Contract: fk(spaceContractSchema),
        Deal: fk(spaceDealsSchema),
        Subscription: fk(spaceSubscriptionSchema)
      }
    ]
  }
};

const useTreeviewQuery = (schema, id, onFirstLoad, isChild, forcedType) => {
  const [items, setItems] = useState([]);
  const space = useSpace();
  const [skip, setSkip] = useState(0);

  const [hasMore, setHasMore] = useState(true);

  const { loading, error } = usePostQuery(
    `${schema.getEndpoint(
      space.Id
    )}/${id}/Children?$inlinecount=allpages&$top=${itemsPerRequest}&$skip=${skip}&IncludeType=${
      !isChild && forcedType ? forcedType : "255"
    }`,
    null,
    TreeviewSchema,
    {
      cache: false,
      onSuccess: ({ data }) => {
        setItems((items) => {
          return [...items, ...data.d.results];
        });

        if (data.d.results.length === 0) {
          setHasMore(false);
        }
        if (onFirstLoad && items.length === 0) {
          const hasChildren = data.d.results.length > 0;
          onFirstLoad(hasChildren);
        }
      }
    }
  );

  const loadMore = useMemo(() => {
    if (!hasMore || loading || error) return;

    const listener = () => {
      setSkip((skip) => skip + itemsPerRequest);
    };

    return listener;
  }, [error, hasMore, loading]);

  return { items, loading, error, loadMore };
};

const TreeviewContext = React.createContext();

const TreeviewSelectionContext = React.createContext();

const Treeview = ({
  schema,
  id,
  className,
  disableActionButtons,
  disablePopup,
  forcedType,
  onSelect
}) => {
  const { items, loading, error, loadMore } = useTreeviewQuery(
    schema,
    id,
    null,
    null,
    forcedType
  );
  const containerRef = useRef();
  const loaded = useRef(false);

  const handleScroll = useMemo(() => {
    if (loading || !loadMore) return undefined;

    const listener = () => {
      if (!containerRef.current) return;
      const { scrollTop, scrollHeight, offsetHeight } = containerRef.current;

      const x = offsetHeight + scrollTop;

      if (x >= scrollHeight - 100) loadMore();
    };

    return listener;
  }, [containerRef, loadMore, loading]);

  useEffect(() => {
    if (handleScroll) handleScroll();
  }, [handleScroll]);

  useEffect(() => {
    if (items.length > 0 && !loaded.current) {
      const {
        Task,
        Project,
        Ticket,
        TimeCharge,
        Call,
        Client,
        Contract,
        Deal,
        Subscription
      } = items[0];
      let schema, Id;
      if (Task) {
        schema = spaceTasksSchema;
        Id = Task;
      } else if (Project) {
        schema = spaceProjectsSchema;
        Id = Project;
      } else if (Ticket) {
        schema = spaceTicketsSchema;
        Id = Ticket;
      } else if (TimeCharge) {
        schema = spaceInterventionsSchema;
        Id = TimeCharge;
      } else if (Call) {
        schema = spaceCallsSchema;
        Id = Call;
      } else if (Client) {
        schema = spaceClientSchema;
        Id = Client;
      } else if (Contract) {
        schema = spaceContractSchema;
        Id = Contract;
      } else if (Subscription) {
        schema = spaceSubscriptionSchema;
        Id = Subscription;
      } else if (Deal) {
        Id = Deal;
        schema = spaceDealsSchema;
      }
      loaded.current = true;
      typeof onSelect === "function" &&
        onSelect({
          schema,
          Id
        });
    }
  }, [items, onSelect]);

  if (items.length === 0) {
    if (error) return <div className="ml-2"></div>;
    // else if(loading) return <LoaderSpinner size="xs" className="text-primary" />;
    else if (loading)
      return (
        <div className="d-flex justify-content-center p-3">
          <LoaderSpinner size="xs" className="text-primary" />
        </div>
      );
    else
      return (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="ar-treeview-no-items text-black fs-16">
            <FormattedMessage id={"TREEVIEW_NO_CHILDREN"} />
          </div>
        </div>
      );
  }

  return (
    <TreeviewContext.Provider value={containerRef}>
      <TreeviewSelectionContext.Provider value={onSelect}>
        <div
          style={{ maxWidth: 800 }}
          onScroll={handleScroll}
          ref={containerRef}
          className={classnames("of-y-auto h-100 position-relative", className)}
        >
          {items.map((item, i) => {
            // const { Type, Id } = item;
            // const key = `${Type}${Id}`;
            return (
              <TreeviewItem
                disablePopup={disablePopup}
                disableActionButtons={disableActionButtons}
                key={i}
                item={item}
              />
            );
          })}
        </div>
      </TreeviewSelectionContext.Provider>
    </TreeviewContext.Provider>
  );
};

export const ChildTreeview = ({
  schema,
  id,
  onFirstLoad,
  disablePopup,
  disableActionButtons
}) => {
  const { items, loading, error, loadMore } = useTreeviewQuery(
    schema,
    id,
    onFirstLoad,
    true
  );

  const containerRef = useContext(TreeviewContext);

  const childRef = useRef();

  const handleScroll = useMemo(() => {
    if (loading || !loadMore) return undefined;

    const listener = () => {
      const { offsetHeight, scrollTop } = containerRef.current;
      const totalHeight = offsetHeight + scrollTop;

      const { offsetTop, offsetHeight: childHeight } = childRef.current;

      if (childHeight + offsetTop < totalHeight - 100) loadMore();
    };

    return listener;
  }, [containerRef, loadMore, loading]);

  useEffect(() => {
    if (handleScroll) handleScroll();
  }, [handleScroll]);

  useEffect(() => {
    if (!handleScroll) return;
    const container = containerRef.current;

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [containerRef, handleScroll]);

  if (items.length === 0) {
    if (error) return <div className="ml-2">error</div>;
    // else if(loading) return <LoaderSpinner size="xs" className="text-primary" />;
    else if (loading) return null;
    else return null;
  }

  return (
    <div ref={childRef} className="ar-treeview-child">
      {items.map((item, i) => {
        // const { Type, Id } = item;
        // const key = `${Type}${Id}`;
        return (
          <TreeviewItem
            disableActionButtons={disableActionButtons}
            isChild
            key={i}
            disablePopup={disablePopup}
            item={item}
          />
        );
      })}
    </div>
  );
};

export default Treeview;

export const TreeviewPopup = ({ ...rest }) => {
  return (
    <div className="bg-white" style={{ width: 300, height: 500 }}>
      <Treeview {...rest} />
    </div>
  );
};
