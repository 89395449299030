import React, { useMemo, useState, useCallback, useRef } from "react";
import { useSpace } from "../../../Contexts/SpaceContext";
import { normalize } from "../../../Helpers/SchemaHelper";
import {
  updateNormalizedSchemaCache,
  useQuery,
  getEntity,
  useSpacePostQuery,
  useSpacePost
} from "../../../Helpers/IOClient";
import { spaceNoteSchema } from "../../../config/schema";
import { useAccount } from "../../../Hooks/EntityHooks";
import { AccountProfileImg } from "../../Img/Img";

export const AddedNewMessageManualyContext = React.createContext();
export const SetAddedNewMessageManualyContext = React.createContext();
// export const MessagesSetterContext = React.createContext();

export const useMessagesQuery = (schema, id, Settings, disableMessages) => {
  const { Schema: SettingsSchema } = Settings || {};
  const rSchema = [SettingsSchema || spaceNoteSchema];

  const { Id: spaceId } = useSpace();
  const { SettingsTop, noteCreator } = Settings || {};
  const top = SettingsTop || 15;
  const [skip, setSkip] = useState(0);

  const lastUserReadMessagesRef = useRef();

  const url = useMemo(() => {
    if (!id) return null;
    if (disableMessages) return null;

    const { getEndpoint } = Settings || {};
    if (getEndpoint) {
      const url = getEndpoint(id);
      return noteCreator
        ? `spaces/${spaceId}/${url}?$top=${top}&$skip=${skip}&isNote=1`
        : `spaces/${spaceId}/${url}?$top=${top}&$skip=${skip}`;
    }
    const base = schema.getEndpoint(spaceId);

    return noteCreator
      ? `${base}/${id}/comments?$top=${top}&$skip=${skip}&isNote=1`
      : `${base}/${id}/comments?$top=${top}&$skip=${skip}`;
  }, [id, disableMessages, Settings, schema, spaceId, noteCreator, top, skip]);
  const [messages, setMessages] = useState();

  const [hasMore, setHasMore] = useState(true);

  const { loading, error, refetch } = useQuery(url, rSchema, {
    cache: false,
    // autoFetch: false,
    onSuccess: ({ data }) => {
      if (data.length !== top) setHasMore(false);

      if (!lastUserReadMessagesRef.current)
        lastUserReadMessagesRef.current = {};

      const { Schema } = Settings || {};

      for (const messageId of data) {
        const message = getEntity(Schema || spaceNoteSchema, messageId);
        if (!message) continue;

        const { ReadUsers } = message;

        if (ReadUsers) {
          for (const readUser of ReadUsers) {
            lastUserReadMessagesRef.current[readUser] = messageId;
          }
        }
      }

      setMessages((messages) => {
        if (!messages) return data;

        return [...new Set([...data, ...messages])];
      });
    }
  });

  const loadMore = useMemo(() => {
    if (!hasMore) return null;

    return () => {
      setSkip((skip) => skip + top);
    };
  }, [hasMore, top]);

  const addMessage = useCallback(
    (message) => {
      const newSchema = SettingsSchema || spaceNoteSchema;
      if (messages.findIndex((e) => e === message) === -1) {
        const thisMessage = getEntity(newSchema, message);
        const currentItem = getEntity(schema, id);
        if (
          Array.isArray(thisMessage?.Files) &&
          thisMessage?.Files?.length > 0
        ) {
          const currentItemFiles = currentItem.Files
            ? [...currentItem.Files]
            : [];

          const newFiles = [...currentItemFiles, ...thisMessage.Files];

          const uniqFilesIds = [...new Set(newFiles.map((item) => item.Id))];

          const filteredFiles = newFiles.filter(
            (t) => uniqFilesIds.indexOf(t.Id) >= 0
          );

          const normalizedEntities = normalize(
            { Id: id, Files: filteredFiles },
            schema
          );
          updateNormalizedSchemaCache(normalizedEntities.entities);
        }
        setMessages((messages) => [...messages, message]);
      }
    },
    [SettingsSchema, id, messages, schema]
  );

  const value = useMemo(() => {
    return {
      messages,
      loadMore,
      addMessage,
      loading,
      error,
      refetch,
      lastUserReadMessagesRef
    };
  }, [addMessage, error, loadMore, loading, messages, refetch]);

  return value;
};

export const ConnectedAccountProfileImg = ({ account: accountId, ...rest }) => {
  const account = useAccount(accountId);
  return <AccountProfileImg size="xsm" circular account={account} {...rest} />;
};

export const ContainerProvider = React.createContext();
