import { IntlShape } from "react-intl";
import { ClientStausTypeDict } from "../../../../Components/Clients/ClientProfile";
import { getEntity } from "../../../../Helpers/IOClient";
import { currencySchema } from "../../../../config/schema";
import { MilSecToFormatedHourMinute } from "../../../../Helpers/MiscHelper";

type TagType = {
  name: string;
  titleId: string;
  id: string;
	type: string;
  val: (e: any, intl: IntlShape) => string | number;
};

export const AccountTags: TagType[] = [
  {
		type: "ACCOUNT",
    name: "Account.Id",
    titleId: "ACCOUNT_NR",
    id: "Id",
    val: (e) => e.Id
  },
  {
		type: "ACCOUNT",
    titleId: "NAME",
    name: "Account.Name",
    id: "Name",
    val: (e) => e.Name
  },
  {
		type: "ACCOUNT",
    titleId: "STATUS",
    name: "Account.Status",
    id: "Status",
    val: (e, intl) => intl.formatMessage({ id: ClientStausTypeDict[e.Status] })
  },
  {
		type: "ACCOUNT",
    titleId: "NIF",
    name: "Account.FiscalNumber",
    id: "FiscalNumber",
    val: (e) => e.FiscalNumber
  },
  {
		type: "ACCOUNT",
    titleId: "DOMAIN",
    name: "Account.Domain",
    id: "Domain",
    val: (e) => e.Domain
  },
  {
		type: "ACCOUNT",
    titleId: "EMAIL",
    name: "Account.Email",
    id: "Email",
    val: (e) => e.Email
  },
  {
		type: "ACCOUNT",
    titleId: "PHONE_NUMBER",
    name: "Account.PhoneNumber",
    id: "PhoneNumber",
    val: (e) => e.PhoneNumber
  },
  {
		type: "ACCOUNT",
    titleId: "DESCRIPTION",
    name: "Account.NoTagDescription",
    id: "NoTagDescription",
    val: (e) => e.NoTagDescription
  },
  {
		type: "ACCOUNT",
    titleId: "ADDRESS",
    name: "Account.Address",
    id: "Address",
    val: (e, intl) => {
      if (Array.isArray(e.Addresses)) {
        const { Address1, Address2, Country, Locality, PostalCode } =
          e.Addresses[0] || {};
        return `${Address1||""} ${Address2||""} ${PostalCode||""} ${Locality||""} ${Country?.Name||""}`;
      } else return intl.formatMessage({ id: "EMPTY" });
    }
  }
];

export const DealTags: TagType[] = [
  {
		type: "DEAL",
    name: "Deal.Id",
    titleId: "ID",
    id: "Id",
    val: (e) => e.Id
  },
  {
		type: "DEAL",
    titleId: "NAME",
    name: "Deal.Name",
    id: "DealName",
    val: (e) => e.Name
  },
  {
		type: "DEAL",
    titleId: "DESCRIPTION",
    name: "Deal.NoTagDescription",
    id: "NoTagDescription",
    val: (e) => e.NoTagDescription
  },
  {
		type: "DEAL",
    titleId: "COMPANY",
    name: "Company.Name",
    id: "CompanyName",
    val: (e) => e.Company.Name
  },
  {
		type: "DEAL",
    titleId: "ORIGIN",
    name: "Deal.Origin",
    id: "OriginName",
    val: (e) => {
      const {
        Call,
        Project,
        Task,
        Client,
        Ticket,
        Contract,
        Deal,
        Subscription
      } = e;
      if (Task) {
        return Task.Name;
      } else if (Ticket) {
        return Ticket.Name;
      } else if (Call) {
        return Call.Name;
      } else if (Project) {
        return Project.Name;
      } else if (Client) {
        return Client.Name;
      } else if (Contract) {
        return Contract.Name;
      } else if (Deal) {
        return Deal.Name;
      } else if (Subscription) {
        return Subscription.Name;
      }
      return "";
    }
  },
  {
		type: "DEAL",
    titleId: "ACCOUNT",
    name: "Deal.Client.Name",
    id: "ClientName",
    val: (e) =>
      e.IndirectClient ? e.IndirectClient.Name : e.Client ? e.Client.Name : ""
  },
  {
		type: "DEAL",
    titleId: "CONTACT",
    name: "Contact.Name",
    id: "ContactName",
    val: (e) => e.Contact.Name
  },
  {
		type: "DEAL",
    titleId: "START_DATE",
    name: "Deal.BeginDate",
    id: "BeginDate",
    val: (e) => {
      const { BeginDate } = e;
      const formatedDate = new Date(BeginDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "DEAL",
    titleId: "END_DATE",
    name: "Deal.EndDate",
    id: "EndDate",
    val: (e) => {
      const { EndDate } = e;
      const formatedDate = new Date(EndDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "DEAL",
    titleId: "CREATION_DATE",
    name: "Deal.CreationDate",
    id: "CreationDate",
    val: (e) => {
      const { CreationDate } = e;
      const formatedDate = new Date(CreationDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "DEAL",
    titleId: "NR_SALE_OPPORTUNITY",
    name: "Deal.SalesOpportunity",
    id: "SalesOpportunity",
    val: (e) => e.SalesOpportunity
  },
  {
		type: "DEAL",
    titleId: "VALUE",
    name: "Deal.EstimatedCost",
    id: "EstimatedCost",
    val: (e) => {
      const { Cost, CurrencyId } = e.EstimatedCost;
      const Currency = getEntity(currencySchema, CurrencyId);
      return `${Currency && Currency.Symbol} ${Cost}`;
    }
  },
  {
		type: "DEAL",
    titleId: "PIPELINE",
    name: "Deal.Pipeline",
    id: "Pipeline",
    val: (e) => e.Pipeline.Name
  },
  {
		type: "DEAL",
    titleId: "PIPELINE_STATUS",
    name: "Deal.PipelineStatus",
    id: "PipelineStatus",
    val: (e) => e.PipelineStatus.Name
  },
  {
		type: "DEAL",
    titleId: "MANAGER",
    name: "Deal.DealManager",
    id: "DealManager",
    val: (e) => e.DealManager.Name
  },
  {
		type: "DEAL",
    titleId: "ASSIGNED_TO",
    name: "Deal.Assigned",
    id: "DealAssigned",
    val: (e) => e.Assigned.Name
  }
];

export const ContractTags: TagType[] = [
  {
		type: "CONTRACT",
    name: "Contract.Id",
    titleId: "ID",
    id: "Id",
    val: (e) => e.Id
  },
  {
		type: "CONTRACT",
    titleId: "NAME",
    name: "Contract.Name",
    id: "ContractName",
    val: (e) => e.NoTagDescription
  },
  {
		type: "CONTRACT",
    titleId: "CONTRACT_TYPE",
    name: "Contract.Type.Name",
    id: "ContractTypeName",
    val: (e) => e.ContractType.Name
  },
  {
		type: "CONTRACT",
    titleId: "PIPELINE",
    name: "Contract.Pipeline",
    id: "Pipeline",
    val: (e) => e.Pipeline.Name
  },
  {
		type: "CONTRACT",
    titleId: "PIPELINE_STATUS",
    name: "Contract.PipelineStatus",
    id: "PipelineStatus",
    val: (e) => e.PipelineStatus.Name
  },
  {
		type: "CONTRACT",
    titleId: "COMPANY",
    name: "Company.Name",
    id: "CompanyName",
    val: (e) => e.Company.Name
  },
  {
		type: "CONTRACT",
    titleId: "ORIGIN",
    name: "Contract.Origin",
    id: "OriginName",
    val: (e) => {
      const {
        Call,
        Project,
        Task,
        Client,
        Ticket,
        Contract,
        Deal,
        Subscription
      } = e;
      if (Task) {
        return Task.Name;
      } else if (Ticket) {
        return Ticket.Name;
      } else if (Call) {
        return Call.Name;
      } else if (Project) {
        return Project.Name;
      } else if (Client) {
        return Client.Name;
      } else if (Contract) {
        return Contract.Name;
      } else if (Deal) {
        return Deal.Name;
      } else if (Subscription) {
        return Subscription.Name;
      }
      return "";
    }
  },
  {
		type: "CONTRACT",
    titleId: "ACCOUNT",
    name: "Contract.Client.Name",
    id: "ClientName",
    val: (e) =>
      e.IndirectClient ? e.IndirectClient.Name : e.Client ? e.Client.Name : ""
  },
  {
		type: "CONTRACT",
    titleId: "CONTACT",
    name: "Contact.Name",
    id: "ContactName",
    val: (e) => e.Contact.Name
  },
  {
		type: "CONTRACT",
    titleId: "ESTIMATED_TIME",
    name: "Contract.EstimatedCost",
    id: "ContractEstimatedCost",
    val: (e) => {
      const { EstimatedCost } = e;
      return MilSecToFormatedHourMinute(EstimatedCost);
    }
  },
  {
		type: "CONTRACT",
    titleId: "START_DATE",
    name: "Contract.BeginDate",
    id: "BeginDate",
    val: (e) => {
      const { BeginDate } = e;
      const formatedDate = new Date(BeginDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "CONTRACT",
    titleId: "END_DATE",
    name: "Contract.EndDate",
    id: "EndDate",
    val: (e) => {
      const { EndDate } = e;
      const formatedDate = new Date(EndDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "CONTRACT",
    titleId: "NEXT_PERIOD",
    name: "Contract.NextRenovation",
    id: "NextRenovation",
    val: (e, intl) => {
      const { NextRenovation } = e;
      if (!NextRenovation) return intl.formatMessage({ id: "EMPTY" });
      const formatedDate = new Date(NextRenovation).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "CONTRACT",
    titleId: "CLOSE_DATE",
    name: "Contract.CloseDate",
    id: "CloseDate",
    val: (e, intl) => {
      const { CloseDate } = e;
      if (!CloseDate) return intl.formatMessage({ id: "EMPTY" });
      const formatedDate = new Date(CloseDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "CONTRACT",
    titleId: "CREATION_DATE",
    name: "Contract.CreationDate",
    id: "CreationDate",
    val: (e) => {
      const { CreationDate } = e;
      const formatedDate = new Date(CreationDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "CONTRACT",
    titleId: "MANAGER",
    name: "Contract.Manager",
    id: "Manager",
    val: (e) => e.Manager.Name
  }
];

export const SubscriptionTags: TagType[] = [
  {
		type: "SUBSCRIPTION",
    name: "Subscription.Id",
    titleId: "SUBSCRIPTION_NR",
    id: "Id",
    val: (e) => e.Id
  },
  {
		type: "SUBSCRIPTION",
    titleId: "DESCRIPTION",
    name: "Subscription.Description",
    id: "SubscriptionDescription",
    val: (e) => e.NoTagDescription
  },
  {
		type: "SUBSCRIPTION",
    titleId: "CONTRACT_TYPE",
    name: "Subscription.Type.Name",
    id: "SubscriptionTypeName",
    val: (e) => e.SubscriptionType.Name
  },
  {
		type: "SUBSCRIPTION",
    titleId: "PIPELINE",
    name: "Subscription.Pipeline",
    id: "Pipeline",
    val: (e) => e.Pipeline.Name
  },
  {
		type: "SUBSCRIPTION",
    titleId: "PIPELINE_STATUS",
    name: "Subscription.PipelineStatus",
    id: "PipelineStatus",
    val: (e) => e.PipelineStatus.Name
  },
  {
		type: "SUBSCRIPTION",
    titleId: "COMPANY",
    name: "Company.Name",
    id: "CompanyName",
    val: (e) => e.Company.Name
  },
  {
		type: "SUBSCRIPTION",
    titleId: "ORIGIN",
    name: "Subscription.Origin",
    id: "OriginName",
    val: (e) => {
      const {
        Call,
        Project,
        Task,
        Client,
        Ticket,
        Contract,
        Deal,
        Subscription
      } = e;
      if (Task) {
        return Task.Name;
      } else if (Ticket) {
        return Ticket.Name;
      } else if (Call) {
        return Call.Name;
      } else if (Project) {
        return Project.Name;
      } else if (Client) {
        return Client.Name;
      } else if (Contract) {
        return Contract.Name;
      } else if (Deal) {
        return Deal.Name;
      } else if (Subscription) {
        return Subscription.Name;
      }
      return "";
    }
  },
  {
		type: "SUBSCRIPTION",
    titleId: "ACCOUNT",
    name: "Subscription.Client.Name",
    id: "ClientName",
    val: (e) =>
      e.IndirectClient ? e.IndirectClient.Name : e.Client ? e.Client.Name : ""
  },
  {
		type: "SUBSCRIPTION",
    titleId: "CONTACT",
    name: "Contact.Name",
    id: "ContactName",
    val: (e) => e.Contact.Name
  },
  {
		type: "SUBSCRIPTION",
    titleId: "START_DATE",
    name: "Subscription.BeginDate",
    id: "BeginDate",
    val: (e) => {
      const { BeginDate } = e;
      const formatedDate = new Date(BeginDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "SUBSCRIPTION",
    titleId: "END_DATE",
    name: "Subscription.EndDate",
    id: "EndDate",
    val: (e) => {
      const { EndDate } = e;
      const formatedDate = new Date(EndDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "SUBSCRIPTION",
    titleId: "CLOSE_DATE",
    name: "Subscription.CloseDate",
    id: "CloseDate",
    val: (e) => {
      const { CloseDate } = e;
      const formatedDate = new Date(CloseDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "SUBSCRIPTION",
    titleId: "CREATION_DATE",
    name: "Subscription.CreationDate",
    id: "CreationDate",
    val: (e) => {
      const { CreationDate } = e;
      const formatedDate = new Date(CreationDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "SUBSCRIPTION",
    titleId: "MANAGER",
    name: "Subscription.Manager",
    id: "Manager",
    val: (e) => e.Manager.Name
  }
];

export const CallTags: TagType[] = [
  {
		type: "CALL",
    name: "Call.Id",
    titleId: "CALL_NR",
    id: "Id",
    val: (e) => e.Id
  },
  {
		type: "CALL",
    titleId: "NAME",
    name: "Call.Name",
    id: "CallName",
    val: (e) => e.NoTagDescription
  },
  {
		type: "CALL",
    titleId: "PIPELINE",
    name: "Contract.Pipeline",
    id: "Pipeline",
    val: (e) => e.Pipeline.Name
  },
  {
		type: "CALL",
    titleId: "PIPELINE_STATUS",
    name: "Contract.PipelineStatus",
    id: "PipelineStatus",
    val: (e) => e.PipelineStatus.Name
  },
  {
		type: "CALL",
    titleId: "COMPANY",
    name: "Company.Name",
    id: "CompanyName",
    val: (e) => e.Company.Name
  },
  {
		type: "CALL",
    titleId: "ORIGIN",
    name: "Call.Origin",
    id: "OriginName",
    val: (e) => {
      const {
        Call,
        Project,
        Task,
        Client,
        Ticket,
        Contract,
        Deal,
        Subscription
      } = e;
      if (Task) {
        return Task.Name;
      } else if (Ticket) {
        return Ticket.Name;
      } else if (Call) {
        return Call.Name;
      } else if (Project) {
        return Project.Name;
      } else if (Client) {
        return Client.Name;
      } else if (Contract) {
        return Contract.Name;
      } else if (Deal) {
        return Deal.Name;
      } else if (Subscription) {
        return Subscription.Name;
      }
      return "";
    }
  },
  {
		type: "CALL",
    titleId: "ACCOUNT",
    name: "Call.Account.Name",
    id: "AccountName",
    val: (e) =>
      e.IndirectClient ? e.IndirectClient.Name : e.Client ? e.Client.Name : ""
  },
  {
		type: "CALL",
    titleId: "ENTRY",
    name: "Call.EntryDate",
    id: "EntryDate",
    val: (e) => {
      const { ReceivedDate } = e;
      const formatedDate = new Date(ReceivedDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "CALL",
    titleId: "CLOSE_DATE",
    name: "Call.CloseDate",
    id: "CloseDate",
    val: (e) => {
      const { CloseDate } = e;
      const formatedDate = new Date(CloseDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "CALL",
    titleId: "MANAGER",
    name: "Call.ManagerAccount",
    id: "ManagerAccount",
    val: (e) => e.ManagerAccount.Name
  }
];

export const TaskTags: TagType[] = [
  {
		type: "TASK",
    name: "Task.Id",
    titleId: "TASK_NR",
    id: "Id",
    val: (e) => e.Id
  },
  {
		type: "TASK",
    titleId: "NAME",
    name: "Task.Name",
    id: "Name",
    val: (e) => e.Name
  },
  {
		type: "TASK",
    titleId: "DESCRIPTION",
    name: "Task.NoTagDescription",
    id: "NoTagDescription",
    val: (e) => e.NoTagDescription
  },
  {
		type: "TASK",
    titleId: "ESTIMATED_TIME",
    name: "Task.EstimatedCost",
    id: "TaskEstimatedCost",
    val: (e) => {
      const { EstimatedCost } = e;
      return MilSecToFormatedHourMinute(EstimatedCost);
    }
  },
  {
		type: "TASK",
    titleId: "COMPANY",
    name: "Company.Name",
    id: "CompanyName",
    val: (e) => e.Company.Name
  },
  {
		type: "TASK",
    titleId: "PIPELINE",
    name: "Task.Pipeline",
    id: "Pipeline",
    val: (e) => e.Pipeline.Name
  },
  {
		type: "TASK",
    titleId: "PIPELINE_STATUS",
    name: "Task.PipelineStatus",
    id: "PipelineStatus",
    val: (e) => e.PipelineStatus.Name
  },
  {
		type: "TASK",
    titleId: "ORIGIN",
    name: "Task.Origin",
    id: "OriginName",
    val: (e) => {
      const {
        Call,
        Project,
        Task,
        Client,
        Ticket,
        Contract,
        Deal,
        Subscription
      } = e;
      if (Task) {
        return Task.Name;
      } else if (Ticket) {
        return Ticket.Name;
      } else if (Call) {
        return Call.Name;
      } else if (Project) {
        return Project.Name;
      } else if (Client) {
        return Client.Name;
      } else if (Contract) {
        return Contract.Name;
      } else if (Deal) {
        return Deal.Name;
      } else if (Subscription) {
        return Subscription.Name;
      }
      return "";
    }
  },
  {
		type: "TASK",
    titleId: "ACCOUNT",
    name: "Task.Client.Name",
    id: "ClientName",
    val: (e) =>
      e.IndirectClient ? e.IndirectClient.Name : e.Client ? e.Client.Name : ""
  },
  {
		type: "TASK",
    titleId: "CONTACT",
    name: "Contact.Name",
    id: "ContactName",
    val: (e) => e.Contact.Name
  },
  {
		type: "TASK",
    titleId: "START_DATE",
    name: "Task.BeginDate",
    id: "BeginDate",
    val: (e) => {
      const { BeginDate } = e;
      const formatedDate = new Date(BeginDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "TASK",
    titleId: "END_DATE",
    name: "Task.EndDate",
    id: "EndDate",
    val: (e) => {
      const { EndDate } = e;
      const formatedDate = new Date(EndDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "TASK",
    titleId: "MANAGER",
    name: "Task.Manager",
    id: "Manager",
    val: (e) => e.Manager.Name
  }
];
export const ProjectTags: TagType[] = [
  {
		type: "PROJECT",
    name: "Project.Id",
    titleId: "PROJECT_NR",
    id: "Id",
    val: (e) => e.Id
  },
  {
		type: "PROJECT",
    titleId: "NAME",
    name: "Project.Name",
    id: "Name",
    val: (e) => e.Name
  },
  {
		type: "PROJECT",
    titleId: "DESCRIPTION",
    name: "Project.NoTagDescription",
    id: "NoTagDescription",
    val: (e) => e.NoTagDescription
  },
  {
		type: "PROJECT",
    titleId: "ESTIMATED_TIME",
    name: "Project.EstimatedCost",
    id: "ProjectEstimatedCost",
    val: (e) => {
      const { EstimatedCost } = e;
      return MilSecToFormatedHourMinute(EstimatedCost);
    }
  },
  {
		type: "PROJECT",
    titleId: "COMPANY",
    name: "Company.Name",
    id: "CompanyName",
    val: (e) => e.Company.Name
  },
  {
		type: "PROJECT",
    titleId: "PIPELINE",
    name: "Project.Pipeline",
    id: "Pipeline",
    val: (e) => e.Pipeline.Name
  },
  {
		type: "PROJECT",
    titleId: "PIPELINE_STATUS",
    name: "Project.PipelineStatus",
    id: "PipelineStatus",
    val: (e) => e.PipelineStatus.Name
  },
  {
		type: "PROJECT",
    titleId: "NR_SALE_OPPORTUNITY",
    name: "Project.SalesOpportunity",
    id: "SalesOpportunity",
    val: (e) => e.SalesOpportunity
  },
  {
		type: "PROJECT",
    titleId: "ORIGIN",
    name: "Project.Origin",
    id: "OriginName",
    val: (e) => {
      const {
        Call,
        Project,
        Task,
        Client,
        Ticket,
        Contract,
        Deal,
        Subscription
      } = e;
      if (Task) {
        return Task.Name;
      } else if (Ticket) {
        return Ticket.Name;
      } else if (Call) {
        return Call.Name;
      } else if (Project) {
        return Project.Name;
      } else if (Client) {
        return Client.Name;
      } else if (Contract) {
        return Contract.Name;
      } else if (Deal) {
        return Deal.Name;
      } else if (Subscription) {
        return Subscription.Name;
      }
      return "";
    }
  },
  {
		type: "PROJECT",
    titleId: "ACCOUNT",
    name: "Project.Client.Name",
    id: "ClientName",
    val: (e) =>
      e.IndirectClient ? e.IndirectClient.Name : e.Client ? e.Client.Name : ""
  },
  {
		type: "PROJECT",
    titleId: "CONTACT",
    name: "Contact.Name",
    id: "ContactName",
    val: (e) => e.Contact.Name
  },
  {
		type: "PROJECT",
    titleId: "START_DATE",
    name: "Project.BeginDate",
    id: "BeginDate",
    val: (e) => {
      const { BeginDate } = e;
      const formatedDate = new Date(BeginDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "PROJECT",
    titleId: "END_DATE",
    name: "Project.EndDate",
    id: "EndDate",
    val: (e) => {
      const { EndDate } = e;
      const formatedDate = new Date(EndDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "PROJECT",
    titleId: "PROJECT_TYPE",
    name: "ProjectType.Name",
    id: "ProjectTypeName",
    val: (e) => e.ProjectType.Name
  },
  {
		type: "PROJECT",
    titleId: "MANAGER",
    name: "Project.Manager",
    id: "Manager",
    val: (e) => e.Manager.Name
  }
];

export const CaseTags: TagType[] = [
  {
		type: "CASE",
    name: "Case.Id",
    titleId: "CASE_NR",
    id: "Id",
    val: (e) => e.Id
  },
  {
		type: "CASE",
    titleId: "NAME",
    name: "Case.Name",
    id: "Name",
    val: (e) => e.Name
  },
  {
		type: "CASE",
    titleId: "DESCRIPTION",
    name: "Case.NoTagDescription",
    id: "NoTagDescription",
    val: (e) => e.NoTagDescription
  },
  {
		type: "CASE",
    titleId: "COMPANY",
    name: "Company.Name",
    id: "CompanyName",
    val: (e) => e.Company.Name
  },
  {
		type: "CASE",
    titleId: "PIPELINE",
    name: "Case.Pipeline",
    id: "Pipeline",
    val: (e) => e.Pipeline.Name
  },
  {
		type: "CASE",
    titleId: "PIPELINE_STATUS",
    name: "Case.PipelineStatus",
    id: "PipelineStatus",
    val: (e) => e.PipelineStatus.Name
  },
  {
		type: "CASE",
    titleId: "ORIGIN",
    name: "Case.Origin",
    id: "OriginName",
    val: (e) => {
      const {
        Call,
        Project,
        Task,
        Client,
        Ticket,
        Contract,
        Deal,
        Subscription
      } = e;
      if (Task) {
        return Task.Name;
      } else if (Ticket) {
        return Ticket.Name;
      } else if (Call) {
        return Call.Name;
      } else if (Project) {
        return Project.Name;
      } else if (Client) {
        return Client.Name;
      } else if (Contract) {
        return Contract.Name;
      } else if (Deal) {
        return Deal.Name;
      } else if (Subscription) {
        return Subscription.Name;
      }
      return "";
    }
  },
  {
		type: "CASE",
    titleId: "ACCOUNT",
    name: "Case.Client.Name",
    id: "ClientName",
    val: (e) =>
      e.IndirectClient ? e.IndirectClient.Name : e.Client ? e.Client.Name : ""
  },
  {
		type: "CASE",
    titleId: "CONTACT",
    name: "Contact.Name",
    id: "ContactName",
    val: (e) => e.Contact.Name
  },
  {
		type: "CASE",
    titleId: "ENTRY",
    name: "Case.BeginDate",
    id: "BeginDate",
    val: (e) => {
      const { ReceivedDate } = e;
      const formatedDate = new Date(ReceivedDate).toLocaleString();
      return formatedDate;
    }
  },
  {
		type: "CASE",
    titleId: "MANAGER",
    name: "Case.Manager",
    id: "Manager",
    val: (e) => e.ManagerAccount.Name
  }
];

export const InterventionTags: TagType[] = [
  {
		type: "TIME",
    name: "Time.Id",
    titleId: "TIME_NR",
    id: "Id",
    val: (e) => e.Id
  },
  {
		type: "TIME",
    titleId: "DESCRIPTION",
    name: "Time.NoTagDescription",
    id: "NoTagDescription",
    val: (e) => e.NoTagDescription
  },
  {
		type: "TIME",
    titleId: "ORIGIN",
    name: "Time.Origin",
    id: "OriginName",
    val: (e) => {
      const {
        Call,
        Project,
        Task,
        Client,
        Ticket,
        Contract,
        Deal,
        Subscription
      } = e;
      if (Task) {
        return Task.Name;
      } else if (Ticket) {
        return Ticket.Name;
      } else if (Call) {
        return Call.Name;
      } else if (Project) {
        return Project.Name;
      } else if (Client) {
        return Client.Name;
      } else if (Contract) {
        return Contract.Name;
      } else if (Deal) {
        return Deal.Name;
      } else if (Subscription) {
        return Subscription.Name;
      }
      return "";
    }
  },
  {
		type: "TIME",
    titleId: "ACCOUNT",
    name: "Time.Client.Name",
    id: "ClientName",
    val: (e) =>
      e.IndirectClient ? e.IndirectClient.Name : e.Client ? e.Client.Name : ""
  },
  {
		type: "TIME",
    titleId: "CONTACT",
    name: "Contact.Name",
    id: "ContactName",
    val: (e) => e.Contact.Name
  },
  {
		type: "TIME",
    titleId: "START_DATE",
    name: "Time.BeginDate",
    id: "BeginDate",
    val: (e) => {
      const { BeginDate } = e;
      const formatedDate = new Date(BeginDate).toLocaleString();
      return formatedDate;
    }
  },
	{
		type: "TIME",
    titleId: "END_DATE",
    name: "Time.EndDate",
    id: "EndDate",
    val: (e) => {
      const { EndDate } = e;
      const formatedDate = new Date(EndDate).toLocaleString();
      return formatedDate;
    }
  },
	{
		type: "TIME",
    titleId: "DURATION",
    name: "Time.EstimatedCost",
    id: "TaskEstimatedCost",
    val: (e) => {
      const { EstimatedCost } = e;
      return MilSecToFormatedHourMinute(EstimatedCost);
    }
  },
	{
		type: "TIME",
    titleId: "PAUSES",
    name: "Time.TotalPauses",
    id: "TimePauses",
    val: (e) => {
      const { TotalPauses } = e;
      return MilSecToFormatedHourMinute(TotalPauses);
    }
  },
  {
		type: "TIME",
    titleId: "MANAGER",
    name: "Time.Manager",
    id: "Manager",
    val: (e) => e.Account.Name
  }
];
