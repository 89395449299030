import React, { Dispatch, SetStateAction } from "react";
import { ObjectProps } from "./DocTyperTypes";

export const ValueTextProvider = React.createContext(null);

export const ColorStyleProvider = React.createContext(null);
export const FontFamilyStyleProvider = React.createContext(null);
export const FontSizeStyleProvider = React.createContext(null);
export const MaximizedStateProvider = React.createContext<Boolean | null>(null);
export const MaximizedSetStateProvider = React.createContext<Dispatch<
  SetStateAction<boolean>
> | null>(null);

export const CodeMirrorProvider = React.createContext<Boolean | null>(null);
export const SetCodeMirrorProvider = React.createContext<Dispatch<
  SetStateAction<boolean>
> | null>(null);

export const EditorNeededPropsProvider = React.createContext({} as ObjectProps);
