import React, { useState, useCallback, useContext, useMemo } from "react";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import Button from "../../../Components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import DeleteButton from "../../../Components/Button/DeleteButton";
import Input from "../../../Components/Input/Input";

import { ProjectStatusDropdown } from "../../AdvancedMultiInputs";
import { useSpaceQuery } from "../../../Helpers/IOClient";
import {
  spacePipelineSchema,
  spacePipelineStatusSchema
} from "../../../config/schema";
import { usePipeline } from "../../../Hooks/EntityHooks";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import classnames from "classnames";
import "./Pipeline.css";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { handleError } from "../../../Helpers/MiscHelper";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpacePost,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import { FormattedMessage, useIntl } from "react-intl";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";
import { ProjectStatusTypes } from "../../Projects/ProjectsStatusHelper";
import { PipelineTypeEnum } from "./NewPipelineHelper";
import Checkbox from "../../../Components/Checkbox/Checkbox";
import { SideFormFooter } from "../../../Components/Forms/SideMenuForm";
import { useMultipleEntityValueSelector } from "../../../Hooks/AdvancedEntityHooks";
import Switch from "../../../Components/Switch/Switch";

const PipelineTypeContext = React.createContext();

const PipelineItemContent = ({
  item,
  provided,
  snapshot,
  onRemove,
  onChange,
  StatusDropdown,
  hideCancelation
}) => {
  const { style: baseStyle, ...rest } = provided.draggableProps;
  const style = { ...baseStyle };
  style.zIndex = 999999999999;

  const { Name, Status, Error, Feedback, Locked, Enabled } = item;

  const handleNameChange = useCallback(
    (e) => {
      onChange({
        Name: e.target.value
      });
    },
    [onChange]
  );

  const handleStatusChange = useCallback(
    (e) => {
      onChange({
        Status: e
      });
    },
    [onChange]
  );

  const handleEnabledChange = useCallback(
    (e) => {
      const { checked } = e.target;
      onChange({
        Enabled: checked
      });
    },
    [onChange]
  );

  const handleFeedbackChange = useCallback(() => {
    onChange({
      Feedback: !Feedback
    });
  }, [Feedback, onChange]);

  const pipelineType = useContext(PipelineTypeContext);

  const comp = (
    <div ref={provided.innerRef} {...rest} style={style} className="py-2">
      <div
        className={classnames(
          "border rounded py-2 pr-2 flex-column ar-deals-pipeline-item d-flex align-items-center",
          { dragging: snapshot.isDragging }
        )}
      >
        <div className="d-flex align-items-center w-100">
          <div
            {...provided.dragHandleProps}
            className="h-100 px-2 ar-deals-pipeline-item-drag"
          >
            <FontAwesomeIcon icon={faGripVertical} />
          </div>
          <Switch checked={Enabled} onChange={handleEnabledChange} />
          <StatusDropdown
            hideCancellation={hideCancelation}
            className="mx-3 w-auto"
            fixedWidth={150}
            hideName
            value={Status}
            disabled={Locked}
            hideCan
            onChange={handleStatusChange}
          />

          <Input
            className={classnames("flex-1", { error: Error })}
            value={Name}
            onChange={handleNameChange}
          />

          {!Locked && <DeleteButton className="ml-3" onClick={onRemove} />}
        </div>
        {pipelineType === PipelineTypeEnum.Ticket &&
          Status === ProjectStatusTypes.finished && (
            <div className="d-flex text-black mt-2 w-100 pl-4 align-items-center">
              <Checkbox
                checked={Feedback}
                change={handleFeedbackChange}
                textValue={"Feedback"}
              />
            </div>
          )}
      </div>
    </div>
  );

  if (snapshot.isDragging) return ReactDOM.createPortal(comp, document.body);
  else return comp;
};

const PipelineItem = ({
  item,
  index,
  onRemove,
  onChange,
  StatusDropdown,
  hideCancelation
}) => {
  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [index, onRemove]);

  const handleUpdate = useCallback(
    (update) => {
      onChange(index, update);
    },
    [index, onChange]
  );

  return (
    <Draggable draggableId={`drag-${index}`} index={index}>
      {(provided, snapshot) => (
        <PipelineItemContent
          hideCancelation={hideCancelation}
          className={`${snapshot.isDragging ? "Dragging" : ""}`}
          provided={provided}
          snapshot={snapshot}
          onRemove={handleRemove}
          onChange={handleUpdate}
          StatusDropdown={StatusDropdown}
          item={item}
        />
      )}
    </Draggable>
  );
};

const PipelineItemList = React.memo(
  ({ items, onRemove, onChange, StatusDropdown, hideCancelation }) => {
    return items.map((item, index) => {
      return (
        <PipelineItem
          hideCancelation={hideCancelation}
          StatusDropdown={StatusDropdown}
          item={item}
          index={index}
          key={index}
          onRemove={onRemove}
          onChange={onChange}
        />
      );
    });
  }
);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const PipelineSelector = ({
  defaultStatus,
  hideCancelation,
  StatusDropdown,
  items,
  onAdd,
  onRemove,
  onChange,
  onCompleteChange
}) => {
  const onDragEnd = useCallback(
    (result) => {
      // dropped outside the list
      if (
        !result.destination ||
        result.destination.index === result.source.index
      ) {
        return;
      }

      // no movement
      if (result.destination.index === result.source.index) {
        return;
      }

      onCompleteChange((items) => {
        const newOrderedColumns = reorder(
          items,
          result.source.index,
          result.destination.index
        );
        return newOrderedColumns;
      });
    },
    [onCompleteChange]
  );

  return (
    <div>
      {items.length > 0 && (
        <div>
          <span className="mb-1 fs-14 fw-medium text-black ml-4 mr-3">
            <FormattedMessage id={"STATUS"} />
          </span>
          <span className="mb-1 fs-14 fw-medium text-black pl-1">
            <FormattedMessage id={"NAME"} />
          </span>
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="pipeline">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              //   style={{
              //     backgroundColor: snapshot.isDraggingOver ? "blue" : "grey"
              //   }}
              {...provided.droppableProps}
              className={`position-relative`}
            >
              <PipelineItemList
                hideCancelation={hideCancelation}
                StatusDropdown={StatusDropdown}
                onRemove={onRemove}
                onChange={onChange}
                items={items}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button onClick={onAdd} vType="primary-ghost" className="w-100 mt-3">
        + <FormattedMessage id={"ADD_STATUS"} />
      </Button>
    </div>
  );
};

// const RemovalFooter = ({ endpoint, refetchRef, onCancel, Name }) => {
//   const createToast = useToast();
//   const intl = useIntl();
//   const handleTranslatedError = useHandleError(spacePipelineSchema);
//   const [deleteUser, { loading }] = useSidebarSpaceDelete(endpoint, null, {
//     onSuccess: ({ data }) => {
//       createToast({
//         pos: "tm",
//         type: "success",
//         title: `${intl.formatMessage({ id: "PIPELINE" })} ${Name}`,
//         description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
//       });
//     },
//     onError: ({ error }) => {
//       handleTranslatedError(error);
//     },
//     refetchRef
//   });

//   return (
//     <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
//       <div className="text-black">
//         <FormattedMessage id={"PIPELINE_DELETE_MSG"} />
//       </div>

//       <div className="d-flex align-items-center">
//         <Button
//           vType="outline-danger"
//           type="button"
//           className="mr-3"
//           onClick={onCancel}
//         >
//           <FormattedMessage id={"BACK"} />
//         </Button>

//         <LoadableButton
//           vType="danger"
//           type="button"
//           isLoading={loading}
//           onClick={() => {
//             deleteUser();
//           }}
//         >
//           <FormattedMessage id={"DELETE"} />
//         </LoadableButton>
//       </div>
//     </div>
//   );
// };

const PipelineFormContent = (props) => {
  const intl = useIntl();
  const {
    hideCancelation,
    defaultStatus = ProjectStatusTypes.open,
    StatusDropdown = ProjectStatusDropdown,
    type,
    closeForm,
    refetchRef,
    pipelineStatus,
    // refetch,
    pipeline
  } = props;

  // const [isDeleting, setIsDeleting] = useState(false);
  const [pipelineName, setPipelineName] = useState(
    pipeline ? pipeline.Name : ""
  );

  const { Id, CanDelete, Enabled, Type } = pipeline || {};

  const [items, setItems] = useState(pipelineStatus ? pipelineStatus : []);

  const handleAddItem = useCallback(() => {
    setItems((items) => {
      const item = {
        Status: defaultStatus,
        Enabled: true,
        Name: ""
      };
      return [...items, item];
    });
  }, [defaultStatus]);

  const handleRemoval = useCallback((index) => {
    setItems((items) => {
      const newItems = [...items];
      newItems.splice(index, 1);
      return newItems;
    });
  }, []);

  const handleChange = useCallback((index, update) => {
    setItems((items) => {
      const newItems = [...items];

      const oldItem = newItems[index];

      newItems[index] = {
        ...oldItem,
        ...update,
        Error: false
      };

      return newItems;
    });
  }, []);

  const createToast = useToast();

  const [post, { loading }] = useSidebarSpacePost(
    Id ? `Pipelines/${Id}` : "Pipelines",
    spacePipelineSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "PIPELINE" })} ${pipelineName}`,
          description: Id
            ? `${intl.formatMessage({
                id: "SUCCESS_SAVED"
              })}`
            : `${intl.formatMessage({
                id: "CREATED_SUCCEFULLY"
              })}`
        });

        closeForm();
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      },
      refetchRef
    }
  );

  const [PipelineNameError, setPipelineNameError] = useState(false);

  const validatePipeline = (name = "", arr = []) => {
    let nameTest = false;
    if (!/\S/.test(name)) {
      setPipelineNameError(true);
      nameTest = true;
    }
    let arrTest = false;
    const newArr = arr.map((e) => {
      if (!/\S/.test(e.Name)) {
        arrTest = true;
        return {
          ...e,
          Error: true
        };
      } else {
        return { ...e };
      }
    });
    if (arrTest) {
      setItems(newArr);
    }
    return nameTest || arrTest;
  };
  const handleTranslatedError = useHandleError(spacePipelineSchema);
  const endpoint = useMemo(() => `Pipelines/${Id}`, [Id]);
  const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
    endpoint,
    spacePipelineSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "PIPELINE" })} ${pipelineName}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  const [enabled, setEnabled] = useState(Enabled);

  const submit = useCallback(() => {
    const resolvedItems = items.map((e, i) => {
      return { Order: i + 1, ...e };
    });
    const Error = validatePipeline(pipelineName, items);
    if (Error) {
      return;
    } else if (Id)
      post({
        Id: Id,
        Name: pipelineName,
        Enabled: enabled,
        PipelineStatus: resolvedItems,
        Type
      });
    else {
      post({
        Name: pipelineName,
        Enabled: enabled,
        PipelineStatus: resolvedItems,
        Type: type
      });
    }
  }, [Id, Type, enabled, items, pipelineName, post, type]);

  const handleCheckChanges = ({ target: { name, checked } }) => {
    setEnabled(checked);
  };

  return (
    <PipelineTypeContext.Provider value={type}>
      <div className="d-flex flex-column h-100">
        <div className="p-4 d-flex flex-column flex-1 of-y-auto">
          <FormInput
            error={PipelineNameError}
            className="mb-3"
            text={intl.formatMessage({ id: "NAME" })}
            value={pipelineName}
            onChange={(e) => {
              setPipelineName(e.target.value);
              setPipelineNameError(false);
            }}
          />

          <FormInput
            name="Enabled"
            value={enabled}
            onChange={handleCheckChanges}
            textId={"ACTIVE"}
            inputType={Switch}
            className="mb-3"
          />

          <PipelineSelector
            hideCancelation={hideCancelation}
            items={items}
            onAdd={handleAddItem}
            onRemove={handleRemoval}
            onChange={handleChange}
            onCompleteChange={setItems}
            defaultStatus={defaultStatus}
            StatusDropdown={StatusDropdown}
          />
        </div>
        <SideFormFooter
          canDelete={CanDelete}
          handleDiffrentOnClick={submit}
          deleteLoading={deleteLoading}
          handleDelete={remove}
          isLoading={loading}
        />
        {/* {!Id ? (
          <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
            <div></div>
            <LoadableButton isLoading={loading} onClick={submit}>
              <FormattedMessage id={"CONFIRM"} />
            </LoadableButton>
          </div>
        ) : !isDeleting ? (
          <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
            {CanDelete ? (
              <Button
                vType="link-danger fw-bold"
                className="mr-3"
                onClick={() => setIsDeleting(true)}
              >
                <span style={{ fontWeight: 500 }}>
                  <FormattedMessage id="DELETE" />
                </span>
              </Button>
            ) : (
              <div></div>
            )}
            <LoadableButton isLoading={loading} onClick={submit}>
              <FormattedMessage id={"CONFIRM"} />
            </LoadableButton>
          </div>
        ) : (
          <SideFormFooter
            canDelete={CanDelete}
            handleDiffrentOnClick={submit}
            deleteLoading={deleteLoading}
            handleDelete={remove}
            isLoading={loading}
          />
          // <RemovalFooter
          //   Name={Name}
          //   endpoint={`Pipelines/${Id}`}
          //   refetchRef={refetchRef}
          //   onCancel={() => setIsDeleting(false)}
          // />
        )} */}
        {/* <LoadableButton isLoading={loading} vtype="primary" onClick={submit}>
          Submeter
        </LoadableButton> */}
      </div>
    </PipelineTypeContext.Provider>
  );
};

const selector = (v) => {
  return v;
};

const defaultPipelines = {
  canDelete: false,
  Name: "",
  Items: [],
  Enabled: true
};

const PipelineForm = (props) => {
  const { id, type } = props;
  let endpoint;

  if (id) endpoint = `pipelines/${id}`;
  const { loading, data, error } = useSpaceQuery(endpoint, spacePipelineSchema);

  const pipeline = usePipeline(data);

  const resolvedPipeline = useMemo(() => {
    let uniPipeline = pipeline || {};
    return {
      ...defaultPipelines,
      ...uniPipeline,
      type
    };
  }, [pipeline, type]);

  const pipelineStatus = pipeline?.PipelineStatus || [];
  const connectedPipelineStatus = useMultipleEntityValueSelector(
    spacePipelineStatusSchema,
    pipelineStatus,
    selector
  );

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id="PIPELINE" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        {loading || error ? (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <LoaderSpinner className="text-primary" size="sm" />
          </div>
        ) : (
          <PipelineFormContent
            {...props}
            pipeline={resolvedPipeline}
            pipelineStatus={connectedPipelineStatus}
          />
        )}
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
  // if (loading || error)
  //   return <LoaderSpinner className="text-primary" size="sm" />;

  // return <PipelineFormContent {...props} pipeline={pipeline} />;
};

export default PipelineForm;
