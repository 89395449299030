import React from "react";
import { FormattedMessage } from "react-intl";
import { usePrivacyPolicyUrl } from "../../Helpers/CopyrightHelper";
import moment from "moment";

export const BasicFooter = ({className}) => {
  const href = usePrivacyPolicyUrl();
  const mDate = moment();
  const date = mDate.format("YYYY");
  return (
    <div className={className ||"py-3 fs-14 text-black mx-auto"}>
      <FormattedMessage
        id={"COPYRIGHT_FOOTER"}
        values={{
          date: date,
          a: (...chunks) => (
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="link-secondary ml-1"
              href={href}
            >
              {chunks}
            </a>
          )
        }}
      />
    </div>
  );
};

export const BasePageContainer = ({ children }) => {
  return (
    <div className="d-flex flex-column content">
      <div className="flex-1 p-5 d-flex flex-column justify-content-center">
        {children}
      </div>
      <BasicFooter />
    </div>
  );
};
