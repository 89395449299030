import React, { useMemo } from "react";
import { ConnectOrganizationEntity } from "../Organizations/OrganizationProfile";
import { useDepartment, useAccount } from "../../Hooks/EntityHooks";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import AccountProfile from "../Accounts/AccountProfile";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { spaceDepartmentSchema } from "../../config/schema";

const OrganizationContent = ConnectOrganizationEntity(
  ({ organization, name, Manager, dynamicFont }) => {
    const { Name: AccountName } = useAccount(Manager) || {};

    if (organization) {
      // const { Name: OrganizationName } = organization;
      return (
        <div className="d-flex flex-column w-100 text-truncate flex-1">
          <div
            className={classnames("text-truncate d-flex", {
              "dropdown-item-maintext": !dynamicFont
            })}
          >
            <div className="fs-1  text-truncate">{name}</div>
            {/* <div
              className={classnames("text-truncate", { "fs-12": !dynamicFont })}
            >
              {OrganizationName}
            </div> */}
          </div>
          {AccountName && (
            <div className="dropdown-item-subtext-dif">
              <span>
                <FormattedMessage id={"MANAGER"} />: {AccountName}
              </span>
            </div>
          )}
        </div>
      );
    } else if (Manager) {
      return (
        <div className="d-flex flex-column w-100 text-truncate flex-1">
          <div
            className={classnames("text-truncate d-flex", {
              "dropdown-item-maintext": !dynamicFont
            })}
          >
            <div
              className={classnames("text-truncate", { "fs-12": !dynamicFont })}
            >
              {name}
            </div>
          </div>
          {AccountName && (
            <div className="dropdown-item-subtext-dif">
              <span>
                <FormattedMessage id={"MANAGER"} />: {AccountName}
              </span>
            </div>
          )}
        </div>
      );
    } else
      return (
        <div className="d-flex flex-column w-100 text-truncate flex-1">
          <div
            className={classnames("text-truncate d-flex", {
              "dropdown-item-maintext": !dynamicFont
            })}
          >
            <div
              className={classnames("text-truncate", { "fs-12": !dynamicFont })}
            >
              {name}
            </div>
          </div>
        </div>
      );
  }
);

const DepartmentProfile = ({
  department,
  ListItem,
  ListItemMultiple,
  dynamicFont,
  NoOrganization,
  ...rest
}) => {
  const { Name, ParentOrganization, Managers } = department || {};
  if (!department) return null;
  return (
    <div className="d-flex align-items-center text-truncate">
      <div className="mr-1">
        <AccountProfile
          ListItemMultiple={ListItemMultiple}
          ListItem={ListItem}
          account={department}
          circular
          justImage={!NoOrganization}
        />
      </div>
      {!NoOrganization && (
        <OrganizationContent
          dynamicFont={dynamicFont}
          organization={ParentOrganization}
          Manager={
            rest.noManager
              ? null
              : Managers && Managers.length > 0
              ? Managers[0]
              : null
          }
          name={Name}
        />
      )}
    </div>
  );
};

export default DepartmentProfile;

// const mapStateToProps = (state, { department }) => {
//   if (!department) return null;
//   return state.Entities.SpaceDepartments
//     ? state.Entities.SpaceDepartments[department]
//     : null;
// };

export const ConnectDepartmentEntity = (Component) => {
  const Comp = ({ department, ...rest }) => {
    const d = useDepartment(department);
    return <Component department={d} {...rest} />;
  };
  return Comp;
};
export const ConnectedDepartmentProfile = ({ department, ...rest }) => {
  const dep = useDepartment(department);

  const url = useMemo(() => {
    if (dep === null) {
      return `query/departments?query=${department}`;
    } else return null;
  }, [dep, department]);

  const { loading, error } = useSpaceQuery(url, [spaceDepartmentSchema]);

  if (loading || error) return null;

  return <DepartmentProfile department={dep} {...rest} />;
};
// export const ConnectedDepartmentProfile = connectShallowEntity(
//   "department",
//   mapStateToProps
// )(DepartmentProfile);
