import React, { useRef } from "react";
import { useHoverEvents } from "../../Containers/Origin/Origin";
import Popup from "../Popup/Popup";

export const buildText = (text, length = 300, beforeEnd = 0) => {
  if (text.length > length && beforeEnd > 0)
    return `${text.substring(0, length)}...${text.substring(
      text.length - beforeEnd - 1,
      text.length
    )}`;
  else if (text.length > length) return `${text.substring(0, length)}...`;
  else return text;
};

const TextEllipsis = ({
  text,
  length,
  truncate,
  beforeEnd,
  afterText,
  Component,
  breakWord,
  span
}) => {
  const anchorRef = useRef();
  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,

    isOpen
  } = useHoverEvents();
  if (!text) return null;
  const resolvedText = buildText(text, length, beforeEnd);

  if (truncate && span) {
    return (
      <>
        <Popup
          anchorEl={anchorRef.current}
          modifiers={{
            preventOverflow: { boundariesElement: "viewport" }
          }}
          isOpen={isOpen && text.length > length}
          onMouseEnter={onPopupMouseEnter}
          onMouseLeave={onPopupMouseLeave}
        >
          <div
            className="bg-black text-white px-2 py-1 fs-14 breakWord rounded"
            style={{
              cursor: "context-menu",
              maxWidth: 400,
              whiteSpace: "normal"
            }}
          >
            {text + afterText}
          </div>
        </Popup>
        <span
          className="text-truncate"
          ref={anchorRef}
          onMouseOver={onAnchorMouseEnter}
          onMouseOut={onAnchorMouseLeave}
        >
          <span className="text-truncate">
            {resolvedText}
            {afterText}
          </span>
        </span>
      </>
    );
  } else if (truncate && Component) {
    return (
      <>
        <Popup
          anchorEl={anchorRef.current}
          modifiers={{
            preventOverflow: { boundariesElement: "viewport" }
          }}
          isOpen={isOpen}
          onMouseEnter={onPopupMouseEnter}
          onMouseLeave={onPopupMouseLeave}
        >
          <div
            className="bg-black text-white px-2 py-1 fs-14 breakWord rounded"
            style={{
              cursor: "context-menu",
              maxWidth: 400,
              whiteSpace: "normal"
            }}
          >
            {text}
          </div>
        </Popup>
        <div
          ref={anchorRef}
          onMouseOver={onAnchorMouseEnter}
          onMouseOut={onAnchorMouseLeave}
        >
          {Component}
        </div>
      </>
    );
  } else if (truncate)
    return (
      <>
        <Popup
          anchorEl={anchorRef.current}
          modifiers={{
            preventOverflow: { boundariesElement: "viewport" }
          }}
          isOpen={isOpen}
          // onMouseEnter={onPopupMouseEnter}
          // onMouseLeave={onPopupMouseLeave}
        >
          <div
            className="bg-black text-white px-2 py-1 fs-14 breakWord rounded"
            style={{
              cursor: "context-menu",
              maxWidth: 400,
              whiteSpace: "normal"
            }}
          >
            {text}
          </div>
        </Popup>
        <div
          className="text-truncate"
          ref={anchorRef}
          onMouseOver={onAnchorMouseEnter}
          onMouseOut={onAnchorMouseLeave}
        >
          <span className="text-truncate">
            {resolvedText}
            {afterText}
          </span>
        </div>
      </>
    );
  else if (breakWord) {
    return (
      <>
        <Popup
          anchorEl={anchorRef.current}
          modifiers={{
            preventOverflow: { boundariesElement: "viewport" }
          }}
          isOpen={isOpen && text.length > length}
          onMouseEnter={onPopupMouseEnter}
          onMouseLeave={onPopupMouseLeave}
        >
          <div
            className="bg-black text-white px-2 py-1 fs-14 breakWord rounded"
            style={{
              cursor: "context-menu",
              maxWidth: 400,
              whiteSpace: "normal"
            }}
          >
            {text}
          </div>
        </Popup>
        <div
          ref={anchorRef}
          onMouseOver={onAnchorMouseEnter}
          onMouseOut={onAnchorMouseLeave}
        >
          <span>
            {resolvedText}
            {afterText}
          </span>
        </div>
      </>
    );
  } else return resolvedText;
};

export default TextEllipsis;
