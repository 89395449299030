import React, { useMemo } from "react";
import { useContact } from "../../Hooks/EntityHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserTie } from "@fortawesome/pro-solid-svg-icons";
import { spaceContactSchema } from "../../config/schema";
import SplitCol from "../../Components/SplitCol/SplitCol";
import { ConnectContactEntity } from "../../Components/Contacts/ContactProfile";
import {
  OriginContainer,
  OriginHeader,
  OriginItemGroup
} from "../../Components/Origin/Origin";
import { createOriginPopup } from "./Origin";
import { FormattedPhoneNumber } from "../../Components/Input/PhoneInput";
import { ContactEntityGridButtons } from "../Contacts/ContactsHelper";
import { ContactIcon } from "../../Components/Common/EntityIcons";
import { useIntl } from "react-intl";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import Button from "../../Components/Button/Button";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import classnames from "classnames";
import { ContractStatusProfileWithIcon } from "../../Components/Contract/ContractProfile";
import AccountProfile from "../../Components/Accounts/AccountProfile";
import { PipelineStatusProfile } from "../AdvancedMultiInputs/PipelineDropdowns";
import { AccountProfileImg } from "../../Components/Img/Img";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { BarLoader } from "../../Components/GlobalLoader/GlobalLoader";
import { useConnectedSchemaGetter } from "../../Helpers/HelperHooks";

export const ContactOriginAnchor = React.forwardRef(
  (
    {
      contact,
      children,
      justImage,
      noStatus,
      noAvatar,
      hideName,
      dynamicFont,
      ...rest
    },
    ref
  ) => {
    const openForm = useEntitySummarySidebar();

    if (!contact) return null;

    const { Id, IsDeleted } = contact;

    return (
      <div
        ref={ref}
        className={classnames("link-color", {
          "item-was-deleted": IsDeleted
        })}
        {...rest}
      >
        {children ? (
          children
        ) : noAvatar ? (
          <div className={classnames("align-items-center text-truncate")}>
            <div className="flex-1 text-truncate">
              <div className="text-white fs-14-enforced text-truncate">
                {contact.Name}
              </div>
            </div>
          </div>
        ) : hideName ? (
          <>
            <div
              className={classnames(
                "text-truncate  d-flex align-items-center",
                { "fs-12": !dynamicFont }
              )}
            >
              <div className="d-inline-flex align-items-center mr-1">
                {noStatus ? (
                  <AccountProfile
                    Icon={<FontAwesomeIcon icon={faUserTie} />}
                    inline
                    hideName
                    justImage
                    account={contact}
                    noStatus
                    status={contact.Status}
                  />
                ) : (
                  <ContractStatusProfileWithIcon
                    Icon={<FontAwesomeIcon icon={faUserTie} />}
                    inline
                    hideName
                    status={contact.Status}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={classnames(
                "text-truncate  d-flex align-items-center",
                { "fs-12": !dynamicFont }
              )}
            >
              <div className="d-inline-flex align-items-center mr-1">
                {noStatus ? (
                  <AccountProfile
                    Icon={<FontAwesomeIcon icon={faUserTie} />}
                    inline
                    hideName
                    justImage
                    noStatus
                    account={contact}
                    status={contact.Status}
                  />
                ) : (
                  <ContractStatusProfileWithIcon
                    Icon={<FontAwesomeIcon icon={faUserTie} />}
                    inline
                    hideName
                    status={contact.Status}
                  />
                )}
              </div>
              <Button
                vType="link-primary"
                className="EntityLinkClassID fs-12 text-black"
                onClick={() => {
                  openForm({
                    type: moduleTypes.contacts,
                    props: {
                      id: Id
                    }
                  });
                }}
              >
                <span>{contact.Name}</span>
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
);
export const ConnectedContactOriginAnchor = ({ contact, ...rest }) => {
  const resolvedContact = useContact(contact);

  const Result = useConnectedSchemaGetter(contact, spaceContactSchema);

  if (Result) return Result;

  return <ContactOriginAnchor contact={resolvedContact} {...rest} />;
};

export const ContactOriginPopup = ({ contact }) => {
  const intl = useIntl();
  const c = useContact(contact.Id);

  const { Id, Name, Email, PhoneNumber, MobileNumber, CreationDate } = c;

  const hasPhoneNumber = Boolean(PhoneNumber);
  const hasMobileNumber = Boolean(MobileNumber);
  const hasEmail = Boolean(Email);

  return (
    <OriginContainer>
      <OriginHeader
        Dif
        className="mb-1"
        type="Contacto"
        date={CreationDate}
        entity="CONTACT"
        Icon={<ContactIcon className="mr-2" />}
        EntityButtons={<ContactEntityGridButtons entity={c} minimal />}
        id={Id}
        title={Name}
      />
      {/* <div className="d-flex">
        <AccountProfileImg account={c} className="mr-1" />
        <div className="d-flex flex-column justify-content-between">
          <span>{Name}</span>
          <span>{Id}</span>
        </div>
      </div> */}

      <div className="mx-3 mb-1">
        <div className="d-flex align-items-center justify-content-between">
          <PipelineStatusProfile pipeline={{ Status: contact.Status }} />

          <div></div>
        </div>
      </div>
      <OriginItemGroup
        show={hasMobileNumber || hasPhoneNumber}
        title={intl.formatMessage({ id: "PHONE_NUMBER" })}
      >
        <div className="text-black d-flex align-items-center fs-12 pb-2 pt-1">
          {(MobileNumber || PhoneNumber || "").replace(".", "")}
          {/* <FormattedPhoneNumber
            className="fs-12"
            number={MobileNumber || PhoneNumber}
          /> */}
        </div>
      </OriginItemGroup>

      <OriginItemGroup show={hasEmail} title="Email">
        <div className="text-black d-flex align-items-center">
          <a href={`mailto:${Email}`} className="fs-12">
            {Email}
          </a>
        </div>
      </OriginItemGroup>

      {/* <SplitCol
        show={hasPhoneNumber || hasMobileNumber || hasEmail}
        // className="mb-2"
        left={
          <OriginItemGroup
            show={hasMobileNumber || hasPhoneNumber}
            title={intl.formatMessage({ id: "PHONE_NUMBER" })}
          >
            <div className="text-black d-flex align-items-center">
              <FormattedPhoneNumber
                className="fs-12"
                number={MobileNumber || PhoneNumber}
              />
            </div>
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup show={hasEmail} title="Email">
            <div className="text-black d-flex align-items-center">
              <a href={`mailto:${Email}`} className="fs-12">
                {Email}
              </a>
            </div>
          </OriginItemGroup>
        }
      /> */}
      <div className="pb-3"></div>
      {/* <div className="d-flex align-items-center justify-content-center shadow-top py-3 mt-3">
        <ContactEntityButtons
          //   className="mt-3 mb-3"

          entity={c}
        />
      </div> */}
    </OriginContainer>
  );
};

export const ContactOrigin = createOriginPopup(
  ContactOriginAnchor,
  ContactOriginPopup,
  { valueProp: "contact", schema: spaceContactSchema }
);

export default ContactOrigin;

export const ConnectedContactOrigin = ConnectContactEntity(ContactOrigin);
