import React from "react";
import {
  useHistoryItem,
  HistoryHeader,
  HistoryContent,
  HistoryOverview,
  HistoryAccountProfile,
} from "./HistoryItem";
import { ConnectedDealOrigin } from "../../../../Containers/Origin/DealOrigin";
import {
  createItemEditDetailsComponent,
  EditItem,
} from "./HistoryItemDetails/HistoryItemDetails";
import { useDeal } from "../../../../Hooks/EntityHooks";
import DealStatusProfile from "../../../Deals/DealStatusProfile";
import { PipelineStatusProfile } from "../../../../Containers/AdvancedMultiInputs/PipelineDropdowns";
import FormattedDecimal from "../../../FormattedDecimal/FormattedDecimal";
import AccountProfile from "../../../Accounts/AccountProfile";
import { ContractCurrencies } from "./HistoryItemDetails/HistoryItemProperties";
import { FormattedMessage } from "react-intl";

const getArrayUpdatesFromProp = (prop) => {
  const { NewValue = [], OldValue = [] } = prop;

  const oldDict = {};
  const newDict = {};

  for (const v of OldValue) {
    oldDict[v.Currency.Id] = v;
  }

  for (const v of NewValue) {
    newDict[v.Currency.Id] = v;
  }

  const changes = [];
  const added = [];
  const removed = [];
  for (const v of NewValue) {
    if (oldDict[v.Currency.Id]) {
      if (oldDict[v.Currency.Id].EstimatedCost !== v.EstimatedCost)
        changes.push({
          New: v,
          Old: oldDict[v.Currency.Id],
        });
    } else {
      added.push(v);
    }
  }

  for (const v of OldValue) {
    if (!newDict[v.Currency.Id]) removed.push(v);
  }

  return { changes, added, removed };
};

const CurrencyComponent = ({ value }) => {
  return (
    <span>
      <FormattedDecimal value={value.EstimatedCost} /> {value.Currency.Symbol}
    </span>
  );
};

export const DealCurrency = {
  procedure: (arr, prop) => {
    const { NewValue, OldValue, ...rest } = prop;

    const { changes, added, removed } = getArrayUpdatesFromProp(prop);
    // const AccountComponent = ({ value }) => {
    //   return (
    //     <div>
    //       {/* <div className="mb-2">{text}</div> */}
    //       {value.map(v => {
    //         return (
    //           <div
    //             key={v.Id}
    //             className="d-inline-block vertical-align-middle my-1 mr-2"
    //           >
    //             <AccountProfile account={v} />
    //           </div>
    //         );
    //       })}
    //     </div>
    //   );
    // };

    if (changes.length > 0) {
      for (const change of changes) {
        arr.push(
          <EditItem
            property={{ ...rest, NewValue: change.New, OldValue: change.Old }}
            NameValue={`Valor - ${change.New.Currency.Name}`}
            key={`newCurrency${change.New.Currency.Id}}`}
            ValueComponent={CurrencyComponent}
          />
        );
      }
    }

    if (added.length > 0) {
      for (const v of added) {
        arr.push(
          <EditItem
            property={{ ...rest, NewValue: v }}
            NameValue={`Valor - ${v.Currency.Name}`}
            key={`newCurrency${v.Currency.Id}}`}
            ValueComponent={CurrencyComponent}
          />
        );
      }
    }

    if (removed.length > 0) {
      for (const v of removed) {
        arr.push(
          <EditItem
            property={{ ...rest, OldValue: v }}
            NameValue={`Valor - ${v.Currency.Name}`}
            key={`newCurrency${v.Currency.Id}}`}
            ValueComponent={CurrencyComponent}
          />
        );
      }
    }
  },
};

const DealHistoryOverview = () => {
  const { Deal, IsCreation, Account } = useHistoryItem();
  const item = useDeal(Deal);
  const { Name } = item;
  if (IsCreation)
    return (
      <HistoryOverview>
        <HistoryAccountProfile account={Account} />
        <span>
          {" "}
          <FormattedMessage id={"CREATED_DEAL"} />{" "}
        </span>{" "}
        <b>{Name}</b>
      </HistoryOverview>
    );

  return (
    <HistoryOverview>
      <HistoryAccountProfile account={Account} />
      <span>
        {" "}
        <FormattedMessage id={"EDITED_DEAL"} />{" "}
      </span>{" "}
      <b>{Name}</b>
    </HistoryOverview>
  );
};

const DealEditItemDetails = createItemEditDetailsComponent(
  {
    Status: {
      NameId: "STATUS",
      ValueComponent: DealStatusProfile,
    },
    PipelineStatus: {
      NameId: "PIPELINE_STATUS",
      ValueComponent: ({ value }) => (
        <PipelineStatusProfile
          StatusProfileComponent={DealStatusProfile}
          pipeline={value}
        />
      ),
    },
    DealCurrency,
    Assigned: {
      NameId: "ASSIGNED_TO",
      ValueComponent: ({ value }) => <AccountProfile account={value} />,
    },
    DealManager: {
      NameId: "MANAGER",
      ValueComponent: ({ value }) => <AccountProfile account={value} />,
    },
    EntityCurrencys: ContractCurrencies,
    Currencies: ContractCurrencies,
  },
  true
);

const DealHistoryDetails = () => {
  const { IsCreation } = useHistoryItem();
  if (IsCreation) return null;

  return <DealEditItemDetails />;
};

const DealHistoryItem = () => {
  const { Deal } = useHistoryItem();

  return (
    <>
      <HistoryHeader>
        <ConnectedDealOrigin deal={Deal}>
          {/* <HistoryTitle Icon={DealIcon}>
            <FormattedMessage id={"DEAL"} /> {Deal}
          </HistoryTitle> */}
        </ConnectedDealOrigin>
      </HistoryHeader>
      <HistoryContent>
        <DealHistoryOverview />
        <DealHistoryDetails />
      </HistoryContent>
    </>
  );
};

export default DealHistoryItem;
