import { faPeopleArrows, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../Components/Button/Button";
import LoadableButton from "../../Components/Button/LoadableButton";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import { DeleteModalContent } from "../../Components/Forms/SideMenuForm";
import Modal from "../../Components/Modal/Modal";
import { useToast } from "../../Components/Toast/ToastProvider";
import { useSpace } from "../../Contexts/SpaceContext";
import {
  useCurrentAccount,
  useCurrentAccountSpace
} from "../../Contexts/UserContext";
import { useDelete, useSpacePost } from "../../Helpers/IOClient";
import { handleError } from "../../Helpers/MiscHelper";
import { isOwner } from "../../Helpers/ModulesHelper";
import { useAccount } from "../../Hooks/EntityHooks";
import { UserDropdown } from "../AdvancedMultiInputs";
import "./WorkspaceSubscription.css";

const TransferModalContent = ({
  space,
  user,
  loading,
  submit,
  toggleTransfer,
  onChange
}) => {
  const currentUser = useCurrentAccount();
  const excluded = useMemo(() => {
    return [currentUser.Id];
  }, [currentUser]);

  const ConnectedUser = useAccount(user);
  return (
    <div
      className="p-3 text-black d-flex flex-column"
      style={{ width: 520, height: 270 }}
    >
      <div className="d-flex flex-1 flex-column">
        <div className="fs-24 mb-3 text-center">
          <FormattedMessage id="TRANSFER_OWNERSHIP" />
        </div>
        <FormInput
          className="mb-3"
          name="user"
          value={user}
          textId="USER"
          inputType={UserDropdown}
          ExcludedUsers={excluded}
          onChange={onChange}
        />
        {user && (
          <div className="fs-14 mb-3 text-center">
            <FormattedMessage
              id="TRANSFER_OWNERSHIP_DESCRIPTION"
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                spaceName: space.Name,
                userName: ConnectedUser.Name
              }}
            />
          </div>
        )}
      </div>
      <div className="text-center">
        <LoadableButton
          isLoading={loading}
          disabled={!user}
          vType="outline-primary"
          onClick={submit}
          className="mr-3"
        >
          <FormattedMessage id="TRANSFER" />
        </LoadableButton>
        <LoadableButton disabled={loading} onClick={toggleTransfer}>
          <FormattedMessage id="BACK" />
        </LoadableButton>
      </div>
    </div>
  );
};

const TransferOwnershipButton = () => {
  const [post, { loading }] = useSpacePost("Ownership", null, {
    onSuccess: () => {
      window.location.reload(true);
    }
  });

  const [user, setUser] = useState(null);
  const [confirmTransfer, setConfirmTransfer] = useState(false);
  const ConnectedUser = useAccount(user);
  const toggleTransfer = () => {
    setConfirmTransfer((t) => !t);
  };

  const submit = () => {
    post(JSON.stringify(ConnectedUser.Id));
  };

  const space = useSpace();

  const { IsSubscribed } = space;

  const handleChange = useCallback((e) => {
    const { value } = e.target;
    setUser(value);
  }, []);
  return (
    <>
      <Modal
        isOpen={confirmTransfer}
        disableClosure={loading}
        onClose={toggleTransfer}
      >
        {IsSubscribed ? (
          <div className="p-3 text-black" style={{ maxWidth: 430 }}>
            <div className="fs-24 mb-1 text-center">
              <FormattedMessage id="TRANSFER_OWNERSHIP" />
            </div>
            <div className="fs-14 mb-3 text-center">
              <FormattedMessage id="TRANSFER_OWNERSHIP_SUBSCRIPTION_WARNING" />
            </div>
            <div className="text-center">
              <LoadableButton
                disabled={loading}
                vType="danger-ghost"
                onClick={toggleTransfer}
              >
                <FormattedMessage id="OK" />
              </LoadableButton>
            </div>
          </div>
        ) : (
          <TransferModalContent
            space={space}
            user={user}
            loading={loading}
            submit={submit}
            onChange={handleChange}
            toggleTransfer={toggleTransfer}
          />
        )}
      </Modal>

      <Button
        className="danger-zone-button d-flex text-truncate"
        onClick={toggleTransfer}
        vType="danger-ghost"
      >
        <FormattedMessage id="TRANSFER_PROPERTY" />
      </Button>
    </>
  );
};

const DangerZoneOptions = () => {
  const space = useSpace();
  const intl = useIntl();
  const createToast = useToast();
  const [remove, { loading: deleteLoading }] = useDelete(
    `Spaces/${space.Id}`,
    null,
    {
      onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
        setTimeout(() => {
          window.location.href = "/s";
        }, 300);
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "SPACE" })} ${intl.formatMessage({
            id: "SUCCESS_DELETED"
          })}`
        });
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  const user = useCurrentAccountSpace();
  const owner = isOwner(user);

  const [modalState, setModalState] = useState(false);

  const closeModal = () => {
    setModalState(false);
  };
  const openModal = () => {
    setModalState(true);
  };

  if (!owner) return null;

  return (
    <div className="mt-5 mr-5 pb-4">
      <Modal isOpen={modalState} onClose={closeModal}>
        <DeleteModalContent
          loadingDelete={deleteLoading}
          closeModal={closeModal}
          handleDelete={remove}
        />
      </Modal>
      <div className="text-black fw-regular fs-22 mb-2">
        <FormattedMessage id="DANGER_ZONE" />
      </div>

      <div className="border-top border-left border-right danger-zone-container-top p-3 bg-white d-flex w-100 align-items-center justify-content-between">
        <div className="d-flex flex-1 align-items-center">
          <div className="danger-zone-icon mr-3">
            <FontAwesomeIcon size="lg" icon={faPeopleArrows} />
          </div>
          <div className="text-black">
            <div className="fs-16 fw-medium">
              <FormattedMessage id="TRANSFER_PROPERTY_TITLE" />
            </div>
            <div className="fs-14 ">
              <FormattedMessage id="TRANSFER_PROPERTY_TITLE_DESCRIPTION" />
            </div>
          </div>
        </div>
        <TransferOwnershipButton />
      </div>
      <div className="border-top danger-zone-container-bottom border-left border-right bg-white border-bottom p-3 d-flex w-100 align-items-center justify-content-between">
        <div className="d-flex flex-1 align-items-center">
          <div className="danger-zone-icon mr-3">
            <FontAwesomeIcon size="lg" icon={faTrashAlt} />
          </div>
          <div className="text-black">
            <div className="fs-16 fw-medium">
              <FormattedMessage id="DELETE_WORKSPACE_TITLE" />
            </div>
            <div className="fs-14">
              <FormattedMessage id="DELETE_WORKSPACE_DESC" />
            </div>
          </div>
        </div>
        <Button
          className="danger-zone-button d-flex text-truncate"
          onClick={openModal}
          vType="danger-ghost"
        >
          <FormattedMessage id="DELETE_WORKSPACE" />
        </Button>
      </div>
    </div>
  );
};

export default DangerZoneOptions;
