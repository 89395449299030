import React from "react";
import { connect } from "react-redux";

const CancellationReasonProfile = ({ cancellationReason }) => {
  if (!cancellationReason) return null;
  return (
    <div className="d-flex align-items-center">
      <div className="flex-1">
        <div>{cancellationReason.Name}</div>
      </div>
    </div>
  );
};

export default CancellationReasonProfile;

const mapStateToProps = (state, { cancellationReason }) => {
  if (!cancellationReason) return {};
  
  return {
    cancellationReason:
      state.Entities.SpaceCancellationReasons[cancellationReason]
  };
};

export const ConnectedCancellationReasonProfile = connect(mapStateToProps)(
  CancellationReasonProfile
);
