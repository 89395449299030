import React, { useContext } from "react";
import { msToTime } from "../Assets/Utils";
import { Communicator } from "./Communicator";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { PhoneNumber } from "../Components/Input/PhoneInput";
import { EntityPopupContext } from "../Components/EntityPopup/EntityPopupHelper";

export function isEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const isValueExpression = (value) => {
  if (typeof value === "string" && value[0] === "=") return true;
  else return false;
};

export const isValidPhoneNumberStructure = (number) => {
  return /\+[0-9]{1,5}\.[0-9]{0,15}$/.test(number);
};

export const isValidPhoneNumber = (phoneNumber) => {
  var re = /\+[0-9]{3}\.[0-9]{5,15}$/;
  return re.test(String(phoneNumber));
};

export const hasPhoneNumberError = (pn) => {
  if (Boolean(pn) && /\S/.test(pn)) {
    const x = PhoneNumber(pn);
    if (
      x &&
      x.number &&
      (x.number.length < 7 ||
        !/^(([\d]{1,5}([ ]|[-]){0,1}){2,5})$/.test(x.number))
    )
      return true;

    return false;
  } else return false;
};

export const canSubmitPhoneNumber = (pn) => {
  const x = PhoneNumber(pn);
  if (x && x.number) return true;
  return false;
};

export const isPhoneNumber = (phoneNumber) => {
  var re = /[0-9]{5,15}$/;
  return re.test(String(phoneNumber));
};

export const RootElement = document.getElementById("root");

export const generalErrors = {
  NOT_FOUND: "NOT_FOUND",
  UNKNOWN_ERROR: "UNKNOWN_ERROR"
};

export const checkIfBeginDateIsInFuture = (value) => {
  const resolvedValue = formatDate(value);
  const today = formatDate();
  if (resolvedValue === today) return false;
  return moment(resolvedValue, "DD/MM/YYYY").isAfter(
    moment(today, "DD/MM/YYYY")
  );
};

export const formatDate = (date) => {
  var d = date ? new Date(date) : new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
};

export const reverseMapping = (o) =>
  Object.keys(o).reduce(
    (r, k) =>
      Object.assign(r, {
        [typeof o[k] === "object" ? o[k].props.id : o[k]]: r[o[k]]
          ? (r[o[k]] || "").concat(".", k)
          : (r[o[k]] || "").concat(k)
      }),
    {}
  );

export const checkIfEndDateIsInFuture = (value) => {
  const resolvedValue = formatDate(value);
  const today = formatDate();

  if (resolvedValue === today) return true;
  return moment(resolvedValue, "DD/MM/YYYY").isAfter(
    moment(today, "DD/MM/YYYY")
  );
};

export const HoursBetweenDates = (value) => {
  const resolvedValue = formatDate(value);
  const today = formatDate();

  return moment(resolvedValue, "DD/MM/YYYY").diff(
    moment(today, "DD/MM/YYYY"),
    "hours"
  );
};

export const largeNumberFormatter = (value) => {
  let result = value;
  if (value <= 1e4) {
    result = value.toFixed(0);
  }
  if (value >= 1e4 && value < 1e6) {
    result =
      (value / 1e3).toFixed(1).replace(".", ",").replace(/\.0$/, "") + "K";
  }
  if (value >= 9.999e5 && value < 1e6) {
    result =
      (value / 1e6).toFixed(1).replace(".", ",").replace(/\.0$/, "") + "M";
  }
  if (value >= 1e6 && value < 1e9) {
    result =
      (value / 1e6).toFixed(1).replace(".", ",").replace(/\.0$/, "") + "M";
  }
  if (value >= 1e9) {
    result =
      (value / 1e9).toFixed(1).replace(".", ",").replace(/\.0$/, "") + "B";
  }

  return result;
};

export const isEquivalent = (a, b) => {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

export const getCurrentPage = (pageSize, offset) => {
  var currentPage = (offset + 1) / pageSize;

  //if (currentPage == 0)
  //    return 1;

  if (currentPage % 1 === 0) {
    return Math.floor(currentPage);
  } else return Math.floor(currentPage) + 1;
};

export const request = (request) => {
  return request + "_REQUEST";
};

export const success = (request) => {
  return request + "_SUCCESS";
};

export const error = (request) => {
  return request + "_ERROR";
};

export function areEqualShallow(a, b) {
  for (var keyA in a) {
    if (!(keyA in b) || a[keyA] !== b[keyA]) {
      return false;
    }
  }
  for (var keyB in b) {
    if (!(keyB in a) || a[keyB] !== b[keyB]) {
      return false;
    }
  }
  return true;
}

export const convertObjToFormData = (obj) => {
  if (obj instanceof Date) {
    return obj.toISOString();
  } else return obj;
};

export const createFormData = (object, files) => {
  const formData = new FormData();
  for (const objKey in object) {
    if (object.hasOwnProperty(objKey)) {
      const objProp = convertObjToFormData(object[objKey]);

      if (objProp !== undefined) formData.append(objKey, objProp);
    }
  }

  if (files && Array.isArray(files) && files?.length > 0)
    appendFormDataFiles(formData, files);
  else if (
    files &&
    typeof files === "object" &&
    Object.keys(files)?.length > 0
  ) {
    formData.append("Files", JSON.stringify(files));
  }
  return formData;
};
export const createEmailSignatureFormData = (object, files, images) => {
  const formData = new FormData();
  for (const objKey in object) {
    if (object.hasOwnProperty(objKey)) {
      const objProp = convertObjToFormData(object[objKey]);

      if (objProp !== undefined) formData.append(objKey, objProp);
    }
  }

  if (Array.isArray(files) && files.length > 0)
    appendFormDataFiles(formData, files);
  if (Array.isArray(images) && images.length > 0)
    appendFormDataImages(formData, images);
  return formData;
};

export const appendFormDataFiles = (formData, files) => {
  for (let i = 0; i < files.length; i++) {
    const obj = files[i];
    if (obj instanceof File || obj instanceof Blob) {
      formData.append("Files", obj, obj.name);
    } else if (files[i].File) {
      formData.append(
        "Files",
        files[i].File,
        files[i].Label
          ? files[i].Name + `({(${files[i].Label})})` + files[i].Extension
          : files[i].Name + files[i].Extension
      );
    } else {
      const { Id, Label, Name, Extension, ...rest } = files[i];
      formData.append(
        "Files",
        JSON.stringify({ Id, Label, Name, Extension, ...rest })
      );
    }
  }
};

export const appendFormDataImages = (formData, files) => {
  for (let i = 0; i < files.length; i++) {
    const obj = files[i];
    if (obj instanceof File || obj instanceof Blob) {
      formData.append("Images", obj, obj.name);
    } else if (files[i].File) {
      formData.append(
        "Images",
        files[i].File,
        files[i].Name + files[i].Extension
      );
    } else {
      formData.append("Images", files[i].Id);
    }
  }
};

export const createImportFormData = (object, files) => {
  const formData = new FormData();
  for (const objKey in object) {
    if (object.hasOwnProperty(objKey)) {
      const objProp = convertObjToFormData(object[objKey]);

      if (objProp !== undefined) formData.append(objKey, objProp);
    }
  }

  if (files) appendImportFormDataFiles(formData, files);
  return formData;
};

export const appendImportFormDataFiles = (formData, files) => {
  for (let i = 0; i < files.length; i++) {
    if (files[i].File) {
      formData.append(
        "Files",
        files[i].File,
        files[i].Name + files[i].Extension
      );
    } else {
      formData.append("Files", files[i].Id);
    }
  }
};

export const removeTags = (text) => {
  const resolvedText = text ? text : "";
  return resolvedText.toString().replace(new RegExp("<[^>]*>", "g"), "");
};

export const hasFlag = (bitwiseEnum, flag) => {
  return Boolean(bitwiseEnum & flag);
};

export const addFlag = (bitwiseEnum, flag) => {
  return (bitwiseEnum |= flag);
};

export const MilSecToMinute = (time, shouldRound = false) => {
  var timeobj = msToTime(time, null, true);

  var mins = timeobj.hours * 60 + timeobj.minutes;

  if (shouldRound) {
    return timeobj.seconds > 0 ? mins + 1 : mins;
  } else return mins;
};

export const MilSecToTime = (duration = 0, showText = false) => {
  let hours;
  let minutes = Math.floor((duration / (1000 * 60)) % 60);

  if (duration >= 86400000) {
    hours = parseInt(duration / (1000 * 60 * 60));
  } else {
    hours = parseInt((duration / (1000 * 60 * 60)) % 24);
  }

  if (showText) {
    if (!hours) {
      if (!minutes) return "0h";
      return minutes + "m";
    }

    if (!minutes) return hours + "h";

    return hours + "h, " + minutes + "m";
  }

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes === 0 ? "00" : minutes < 10 ? "0" + minutes : minutes;

  if (!minutes) return hours;

  return hours + ":" + minutes;
};

export const MinuteToMilSec = (time = 0) => {
  const resolvedTime = time * 1000 * 60;

  return resolvedTime;
};

export const MilSecToHour = (time, shouldRound = false) => {
  var timeobj = msToTime(time, null, true);

  var hours = timeobj.hours;

  if (shouldRound) {
    return timeobj.minutes > 0 ? hours + 1 : hours;
  } else return hours;
};

export const MilSecToFormatedHourMinute = (time = 0) => {
  const hours = Math.floor(time / 1000 / 3600);
  const minutes = Math.floor((time / 1000 - hours * 3600) / 60);

  const timeString =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0");

  return timeString;
};

export const MilSecToFormatedTimeWithDescription = (time = 0) => {
  const resolvedData = new moment.duration(time);
  let resolvedTime = Math.round(resolvedData.asDays() * 10) / 10;
  let Spec = <FormattedMessage id={"DAYS"} />;
  if (resolvedTime < 1) {
    resolvedTime = Math.round(resolvedData.asHours() * 10) / 10;
    Spec = <FormattedMessage id={"HOURS"} />;
  }
  if (resolvedTime < 1) {
    resolvedTime = Math.round(resolvedData.asMinutes() * 10) / 10;
    Spec = <FormattedMessage id={"MINUTES"} />;
  }
  return [resolvedTime, Spec];
};

export const MilSecToFormatedTime = (time) => {
  const hours = Math.floor(time / 1000 / 3600);
  const minutes = Math.floor((time / 1000 - hours * 3600) / 60);
  const seconds = time / 1000 - hours * 3600 - minutes * 60;

  const timeString =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0");

  return timeString;
};

export const HourToMilSec = (time) => {
  const resolvedTime = time * 1000 * 60 * 60;

  return resolvedTime;
};

export const statusCodes = {
  NotAcceptable: 406
};

export const isEntityStatusCancelled = (entity) => {
  if (!entity) return false;
  if (entity.Status === 3) return true;

  return false;
};

export const getNumberOfDays = (year, month, asArray) => {
  let isLeap = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
  const days = [31, isLeap ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][
    month - 1
  ];

  let valueToReturn = days;
  if (asArray === true) {
    valueToReturn = [];
    for (let index = 1; index <= days; index++) {
      valueToReturn.push(index);
    }
    return valueToReturn;
  }
  return valueToReturn;
};

export const isPasswordValid = (password) => {
  return password.length > 4;
};

// export const toLocalIsoString = () => {
// 	var tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
//   var localISOTime = new Date(date - tzoffset).toISOString().slice(0, -1);
//   return localISOTime;
// }

export const toIsoString = function (d) {
  if (!d) return null;

  if (isValueExpression(d)) return d;
  let date;

  if (!(date instanceof Date)) {
    date = new Date(d);
  }

  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? "0" : "") + norm;
    };
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};

export const addhttp = (url) => {
  if (!/^(f|ht)tps?:\/\//i.test(url)) {
    url = "http://" + url;
  }
  return url;
};

export const handleErrorWithIntl = (createToast, error) => {
  // console.log(error)

  createToast({
    pos: "tm",
    type: "danger",
    description: <FormattedMessage id={error} />,
    title: <FormattedMessage id={"ERROR"} />
  });
};

export function findVal(object, key) {
  var value;
  // eslint-disable-next-line array-callback-return
  Object.keys(object).some(function (k) {
    if (k === key) {
      value = object[k];
      return true;
    }
    if (object[k] && typeof object[k] === "object") {
      value = findVal(object[k], key);
      return value !== undefined;
    }
  });
  return value;
}

export const handleError = (createToast, error) => {
  let errorType = findVal(error, "Type");
  let notifications = findVal(error, "notifications");

  if (notifications && notifications.length > 0) {
    createToast({
      pos: "tm",
      type: "danger",
      description: notifications[0].text || (
        <FormattedMessage id="ERROR_OCCURRED" />
      ),
      title: <FormattedMessage id={"ERROR"} />
    });
  } else if (errorType === "POTENTIALLY_DANGEROUS") {
    let msgKey = findVal(error, "MessageKey");
    createToast({
      pos: "tm",
      type: "danger",
      description: <FormattedMessage id={msgKey} />,
      title: <FormattedMessage id={"ERROR"} />
    });
  } else if (errorType === "INVALID_DATA_KEY") {
    let resolvedMsg = findVal(error, "Message") || findVal(error, "MessageKey");
    createToast({
      pos: "tm",
      type: "danger",
      description: <FormattedMessage id={resolvedMsg} />,
      title: <FormattedMessage id={"ERROR"} />
    });
  } else {
    let msgKey = findVal(error, "MessageKey");
    if (msgKey) {
      createToast({
        pos: "tm",
        type: "danger",
        description: <FormattedMessage id={msgKey} />,
        title: <FormattedMessage id={"ERROR"} />
      });
    } else {
      let resolvedMsg = findVal(error, "Message");

      if (typeof resolvedMsg === "string") {
        createToast({
          pos: "tm",
          type: "danger",
          description: resolvedMsg,
          title: <FormattedMessage id={"ERROR"} />
        });
        return;
      }

      if (typeof resolvedMsg === "object") {
        resolvedMsg = findVal(resolvedMsg, "Message");
      }

      if (!resolvedMsg) {
        resolvedMsg = <FormattedMessage id="ERROR_OCCURRED" />;
      }

      createToast({
        pos: "tm",
        type: "danger",
        description: resolvedMsg,
        title: <FormattedMessage id={"ERROR"} />
      });
    }
  }
};

export const MainViewsSearchLSKey = "ar-main-views-search";

export const NewMainViewsSearchLSKey = "ar-main-views-search-";

export const LastUsedSpaceLSKey = "Airdesk-Previous-Space";

export const MainViewsSearchCommunicator = new Communicator();

export function getDates(startDate, stopDate) {
  var dateArray = [];
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate));
    currentDate = currentDate.add(1, "days");
  }
  return dateArray;
}

export const originTypes = {
  client: "Client",
  timecharge: "timecharge",
  subscription: "Subscription",
  call: "Call",
  project: "Project",
  classification: "Classification",
  task: "Task",
  ticket: "Ticket",
  contact: "Contact",
  contract: "Contract",
  department: "Department",
  team: "Team",
  account: "Account",
  billing: "Billing",
  users: "Users",
  deals: "Deal",
  deal: "Deal",
  organization: "Company",
  emailConversation: "EmailConversation",
  userCategory: "UserCategory",
  costCenter: "CostCenter",
  userDocumentType: "UserDocumentType"
};

export const getTeamCollabValue = (Collaborators) => {
  if (!Collaborators) return [];

  const { Accounts, Teams } = Collaborators;

  let data = [];
  if (Accounts || Teams) {
    if (Accounts && Array.isArray(Accounts)) {
      for (let i = 0; i < Accounts.length; i++) {
        if (Accounts[i].type === "Account" && Accounts[i].data)
          data.push(Accounts[i]);
        else data.push({ type: "Account", data: Accounts[i] });
      }
    }
    if (Teams && Array.isArray(Teams)) {
      for (let i = 0; i < Teams.length; i++) {
        if (Teams[i].type === "Team" && Teams[i].data) data.push(Teams[i]);
        else data.push({ type: "Team", data: Teams[i] });
      }
    }
  }

  return data;
};

export const getDeptTeamCollabValue = (Collaborators) => {
  if (!Collaborators) return [];

  const { Accounts, Teams, Departments } = Collaborators;

  let data = [];
  if (Accounts || Teams || Departments) {
    if (Accounts && Array.isArray(Accounts)) {
      for (let i = 0; i < Accounts.length; i++) {
        if (Accounts[i].type === "Account" && Accounts[i].data)
          data.push(Accounts[i]);
        else data.push({ type: "Account", data: Accounts[i] });
      }
    }
    if (Teams && Array.isArray(Teams)) {
      for (let i = 0; i < Teams.length; i++) {
        if (Teams[i].type === "Team" && Teams[i].data) data.push(Teams[i]);
        else data.push({ type: "Team", data: Teams[i] });
      }
    }
    if (Departments && Array.isArray(Departments)) {
      for (let i = 0; i < Departments.length; i++) {
        if (Departments[i].type === "Department" && Departments[i].data)
          data.push(Departments[i]);
        else data.push({ type: "Department", data: Departments[i] });
      }
    }
  }

  return data;
};

export const reorderArray = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const useDownloadData = () => {
  const providedOnSuccess = useContext(EntityPopupContext);
  return (data, type, filename) => {
    var blob = new Blob([data], { type: "application/pdf" });
    var objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl);
    providedOnSuccess && providedOnSuccess();
  };
};

export const useDownloadExcelData = () => {
  const providedOnSuccess = useContext(EntityPopupContext);
  return (data, filename) => {
    var blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    const link = document.createElement("a");
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    if (document.body.contains(link)) document.body.removeChild(link);
    providedOnSuccess && providedOnSuccess();
  };
};

function parseDuration(duration) {
  let remain = duration;

  let hours = Math.floor(remain / (1000 * 60 * 60));
  remain = remain % (1000 * 60 * 60);

  let minutes = Math.floor(remain / (1000 * 60));
  remain = remain % (1000 * 60);

  let seconds = Math.floor(remain / 1000);
  remain = remain % 1000;

  let milliseconds = remain;

  return {
    hours,
    minutes,
    seconds,
    milliseconds
  };
}

function formatTime(o, useMilli = false) {
  let parts = [];
  if (o.days) {
    let ret = o.days + " day";
    if (o.days !== 1) {
      ret += "s";
    }
    parts.push(ret);
  }
  if (o.hours) {
    let ret = o.hours + " hour";
    if (o.hours !== 1) {
      ret += "s";
    }
    parts.push(ret);
  }
  if (o.minutes) {
    let ret = o.minutes + " minute";
    if (o.minutes !== 1) {
      ret += "s";
    }
    parts.push(ret);
  }
  if (o.seconds) {
    let ret = o.seconds + " second";
    if (o.seconds !== 1) {
      ret += "s";
    }
    parts.push(ret);
  }
  if (useMilli && o.milliseconds) {
    let ret = o.milliseconds + " millisecond";
    if (o.milliseconds !== 1) {
      ret += "s";
    }
    parts.push(ret);
  }
  if (parts.length === 0) {
    return "instantly";
  } else {
    return parts.join(" ");
  }
}

function formatTimeHMS(o) {
  let hours = o.hours.toString();
  if (hours.length === 1) hours = "0" + hours;

  let minutes = o.minutes.toString();
  if (minutes.length === 1) minutes = "0" + minutes;

  if (hours >= 100) return hours + ":" + minutes;

  return hours + ":" + minutes + " h";
}

export function formatDurationHMS(duration) {
  let time = parseDuration(duration);
  return formatTimeHMS(time);
}

export function formatDuration(duration, useMilli = false) {
  let time = parseDuration(duration);
  return formatTime(time, useMilli);
}

export const HoverStateContext = React.createContext(false);

export const useHoverState = () => useContext(HoverStateContext);

export const isValidDomainName = (domain) => {
  return /^[\w\d-_]+$/.test(domain);
};

export const isValidSubDomainName = (domain) => {
  return /^[\w-.]+$/.test(domain);
};

export const isValidDomain = (domain) => {
  return /^http[s]{0,1}:\/\/([\w\d-_]+[.]){1,}[\w\d-_]+((\/|\?)[\S]*){0,}$/.test(
    domain
  );
};

export const entityActionType = {
  creation: "creation",
  update: "update"
};

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea"); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
};
