import { spaceCallsSchema, spaceClientSchema, spaceContractSchema, spaceDealsSchema, spaceEmailConversationSchema, spaceInterventionsSchema, spaceProjectsSchema, spaceSubscriptionSchema, spaceTasksSchema, spaceTicketsSchema } from "../../config/schema";
import {
  ConversationTypeEnumFlags,
  EntityTypeNoteEnumFlags,
  EntityTypeNoteEnum,
  ConversationTypeEnum,
} from "./ReceptionHelpers";

interface IInboxFilterSettings {
  filterKey: string;
  entityNoteTypeFlag?: number;
  conversationTypeFlag: number;
	entityKey: string;
	schema: any;
}

export const emailFilterSettings: IInboxFilterSettings = {
  filterKey: "Conversation/EmailConversationId",
  // entityNoteTypeFlag: ConversationTypeEnumFlags.Email,
  conversationTypeFlag: ConversationTypeEnumFlags.Email,
	entityKey: "EmailConversation",
	schema: spaceEmailConversationSchema,
};

export const clientFilterSettings: IInboxFilterSettings = {
  filterKey: "Client/Id",
  entityNoteTypeFlag: EntityTypeNoteEnumFlags.Client,
  conversationTypeFlag: ConversationTypeEnumFlags.EntityNotes,
	entityKey: "Client",
	schema: spaceClientSchema,
};

export const dealFilterSettings: IInboxFilterSettings = {
  filterKey: "Deal/Id",
  entityNoteTypeFlag: EntityTypeNoteEnumFlags.Deal,
  conversationTypeFlag: ConversationTypeEnumFlags.EntityNotes,
	entityKey: "Deal",
	schema: spaceDealsSchema,
};

export const contractFilterSettings: IInboxFilterSettings = {
  filterKey: "Contract/Id",
  entityNoteTypeFlag: EntityTypeNoteEnumFlags.Contract,
  conversationTypeFlag: ConversationTypeEnumFlags.EntityNotes,
	entityKey: "Contract",
	schema: spaceContractSchema,
};

export const subscriptionFilterSettings: IInboxFilterSettings = {
  filterKey: "Subscription/Id",
  entityNoteTypeFlag: EntityTypeNoteEnumFlags.Subscription,
  conversationTypeFlag: ConversationTypeEnumFlags.EntityNotes,
	entityKey: "Subscription",
	schema: spaceSubscriptionSchema,
};

export const callFilterSettings: IInboxFilterSettings = {
  filterKey: "Call/Id",
  entityNoteTypeFlag: EntityTypeNoteEnumFlags.Call,
  conversationTypeFlag: ConversationTypeEnumFlags.EntityNotes,
	entityKey: "Call",
	schema: spaceCallsSchema,
};

export const projectFilterSettings: IInboxFilterSettings = {
  filterKey: "Project/Id",
  entityNoteTypeFlag: EntityTypeNoteEnumFlags.Project,
  conversationTypeFlag: ConversationTypeEnumFlags.EntityNotes,
	entityKey: "Project",
	schema: spaceProjectsSchema,
};

export const ticketFilterSettings: IInboxFilterSettings = {
  filterKey: "Ticket/Id",
  entityNoteTypeFlag: EntityTypeNoteEnumFlags.Ticket,
  conversationTypeFlag: ConversationTypeEnumFlags.EntityNotes,
	entityKey: "Ticket",
	schema: spaceTicketsSchema,
};

export const taskFilterSettings: IInboxFilterSettings = {
  filterKey: "Task/Id",
  entityNoteTypeFlag: EntityTypeNoteEnumFlags.Task,
  conversationTypeFlag: ConversationTypeEnumFlags.EntityNotes,
	entityKey: "Task",
	schema: spaceTasksSchema,
};

export const timeFilterSettings: IInboxFilterSettings = {
  filterKey: "TimeCharge/Id",
  entityNoteTypeFlag: EntityTypeNoteEnumFlags.Time,
  conversationTypeFlag: ConversationTypeEnumFlags.EntityNotes,
	entityKey: "TimeCharge",
	schema: spaceInterventionsSchema,
};

export const getInboxFilterSetting = (
  conversationType: number,
  entityNoteType?: number
) => {
  if (conversationType === ConversationTypeEnum.Email)
    return emailFilterSettings;

  switch (entityNoteType) {
    case EntityTypeNoteEnum.Client:
      return clientFilterSettings;
    case EntityTypeNoteEnum.Deal:
      return dealFilterSettings;
    case EntityTypeNoteEnum.Contract:
      return contractFilterSettings;
    case EntityTypeNoteEnum.Subscription:
      return subscriptionFilterSettings;
    case EntityTypeNoteEnum.Call:
      return callFilterSettings;
    case EntityTypeNoteEnum.Project:
      return projectFilterSettings;
    case EntityTypeNoteEnum.Ticket:
      return ticketFilterSettings;
    case EntityTypeNoteEnum.Task:
      return taskFilterSettings;
    case EntityTypeNoteEnum.Time:
      return timeFilterSettings;
    default:
      break;
  }
};
