import React, { useMemo } from "react";
import { AccountProfileImg } from "../Img/Img";
import classnames from "classnames";
import { FormattedPhoneNumber } from "../Input/PhoneInput";
import { useContact } from "../../Hooks/EntityHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { isPhoneNumber } from "../../Helpers/MiscHelper";
import { FormattedMessage } from "react-intl";
import DropdownInput from "../Dropdown/DropdownInput";
import { buildProjectStatusType } from "../Projects/ProjectProfile";
import {
  CompanyActiveStatusDot,
  CompanyInactiveStatusDot,
} from "../StatusHelper/StatusHelper";
import { ContactStatusTypes } from "../../Containers/AdvancedMultiInputs";
import { faUserTie } from "@fortawesome/pro-solid-svg-icons";

const buildContactStatusType = (status) => {
  switch (`${status}`) {
    case "2":
      return "inactive";

    default:
      return "purple";
  }
};

const statusNameByValue = (status) => {
  switch (status.toString()) {
    case "1":
      return <FormattedMessage id={"ACTIVE"} />;
    case "2":
      return <FormattedMessage id={"INACTIVE"} />;
    default:
      return null;
  }
};

export const ContactStatusSvgIcons = (status) => {
  switch (status.toString()) {
    case "1":
      return <CompanyActiveStatusDot />;
    case "2":
      return <CompanyInactiveStatusDot />;
    default:
      return null;
  }
};

export const ContactStatusProfile = ({
  entity,
  status,
  item,
  hideName,
  className,
  pipelineName,
  dotClassName,
  value,
}) => {
  const resovledStatus = entity ? entity.Status : status || item || value;

  const SvgComponent = useMemo(() => {
    if (!resovledStatus) return null;
    return ContactStatusSvgIcons(resovledStatus);
  }, [resovledStatus]);

  if (!resovledStatus) return null;

  return (
    <div
      className={classnames(
        `d-flex align-items-center link-color fs-12  status-color-${buildContactStatusType(
          resovledStatus
        )}`,
        className
      )}
    >
      <div
        className={classnames("position-relative status-dot-svg", dotClassName)}
      >
        {SvgComponent}
      </div>
      {!hideName && (
        <div
          style={{ fontWeight: 400 }}
          className="status-label fs-12 text-truncate flex-1"
        >
          {pipelineName || statusNameByValue(resovledStatus)}
        </div>
      )}
    </div>
  );
};

const ContactListProfile = ({
  contact,
  className,
  size,
  dropdownInfo,
  enableInput,
  disableName,
  text,
  shownumber,
  isTyping,
  inline,
  dynamicFont,
  ...inputProps
}) => {
  if (!contact) return null;
  // var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const resolvedStatus = buildContactStatusType(contact.Status);
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100 of-hidden text-truncate">
        {!isTyping && contact && (
          <AccountProfileImg
            size="mds"
            account={contact}
            circular
            className="mr-2"
          />
        )}
        <div className="d-flex flex-column w-100 of-hidden mr-2 flex-1">
          <div className="dropdown-item-maintext ">
            {!enableInput ? (
              contact.Name
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={contact && contact.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
          {!isTyping && (
            <div className="dropdown-item-subtext-dif text-truncate d-flex align-items-center">
              {contact.MobileNumber && shownumber ? (
                <FormattedPhoneNumber
                  noHref
                  className="text-truncate"
                  number={contact.MobileNumber}
                />
              ) : contact.PhoneNumber && shownumber ? (
                contact.PhoneNumber &&
                shownumber && (
                  <FormattedPhoneNumber
                    noHref
                    className="text-truncate"
                    number={contact.PhoneNumber}
                  />
                )
              ) : null}
              {contact.Email && (
                <span>
                  <FontAwesomeIcon className="mr-1" icon={faEnvelope} />
                  {contact.Email}
                </span>
              )}
            </div>
          )}
        </div>
        {!isTyping && (
          <div
            className={`d-flex align-items-center justify-content-end status-color-${resolvedStatus}`}
          >
            <div className="d-flex py-1 px-2 fs-12 align-items-center status-list-template">
              <FontAwesomeIcon className="mr-2" icon={faUserTie} />
              <span>{contact.Id}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  //   return (
  //     <div
  //       className={classnames(
  //         "align-items-center",
  //         {
  //           "d-flex": !inline,
  //           "d-inline-flex": inline
  //         },
  //         className
  //       )}
  //     >
  //       <AccountProfileImg
  //         size={size ? size : "sm"}
  //         account={contact}
  //         circular
  //         className="mr-1"
  //       />
  //       <div
  //         className={classnames("flex-1 d-flex flex-column text-truncate", {
  //           "fs-12": !dynamicFont
  //         })}
  //       >
  //         {!disableName && (
  //           <div className={"text-black fs-14-enforced text-truncate"}>
  //             {contact.Name}
  //           </div>
  //         )}
  //         {dropdownInfo &&
  //           (isPhoneNumber(contact.PhoneNumber) ||
  //             isPhoneNumber(contact.MobileNumber) ||
  //             contact.Email) && (
  //             <div
  //               style={{ bottom: 0 }}
  //               className="position-absolute fs-10 text-black"
  //             >
  //               {isPhoneNumber(contact.MobileNumber) ? (
  //                 <FormattedPhoneNumber
  //                   withIcon
  //                   className="link-color lh-normal text-truncate mr-2"
  //                   number={contact.MobileNumber}
  //                 />
  //               ) : isPhoneNumber(contact.PhoneNumber) ? (
  //                 <FormattedPhoneNumber
  //                   withIcon
  //                   className="link-color lh-normal text-truncate mr-2"
  //                   number={contact.PhoneNumber}
  //                 />
  //               ) : null}
  //               {contact.Email && (
  //                 <a href={`mailto:${contact.Email}`} target="_top">
  //                   <FontAwesomeIcon className="mr-1" icon={faEnvelope} />
  //                   {contact.Email}
  //                 </a>
  //               )}
  //             </div>
  //           )}
  //         {contact.MobileNumber && shownumber ? (
  //           <FormattedPhoneNumber
  //             className="link-color lh-normal text-truncate"
  //             number={contact.MobileNumber}
  //           />
  //         ) : contact.PhoneNumber && shownumber ? (
  //           contact.PhoneNumber &&
  //           shownumber && (
  //             <FormattedPhoneNumber
  //               className="link-color lh-normal text-truncate"
  //               number={contact.PhoneNumber}
  //             />
  //           )
  //         ) : null}
  //       </div>
  //     </div>
  //   );
};

const ContactProfile = ({
  contact,
  className,
  size,
  dropdownInfo,
  disableName,
  shownumber,
  inline,
  showemail,
  dynamicFont,
  maincontact,
}) => {

  if (!contact) return null;
  // var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (maincontact) {
    return (
      <div
        className={classnames(
          "align-items-center",
          {
            "d-flex": !inline,
            "d-inline-flex": inline,
          },
          className
        )}
      >
        <AccountProfileImg
          size={size ? size : "sm"}
          account={contact}
          circular
          className="mr-1"
        />
        <div
          className={classnames("flex-1 d-flex flex-column text-truncate align-items-baseline", {
            "fs-12": !dynamicFont,
          })}
        >
          {!disableName && (
            <div className={"text-black fs-16 fw-medium text-truncate"}>
              {contact.Name}
            </div>
          )}
          {contact.Email ? (
            <span className="fs-12">
              <FontAwesomeIcon className="mr-1" icon={faEnvelope} />
              {contact.Email}
            </span>
          ) : null}
        </div>
      </div>
    );
  } else
    return (
      <div
        className={classnames(
          "align-items-center",
          {
            "d-flex": !inline,
            "d-inline-flex": inline,
          },
          className
        )}
      >
        <AccountProfileImg
          size={size ? size : "sm"}
          account={contact}
          circular
          className="mr-1"
        />
        <div
          className={classnames("flex-1 d-flex flex-column text-truncate align-items-baseline", {
            "fs-12": !dynamicFont,
          })}
        >
          {!disableName && (
            <div className={"text-truncate"}>{contact.Name}</div>
          )}
          {dropdownInfo &&
            (isPhoneNumber(contact.PhoneNumber) ||
              isPhoneNumber(contact.MobileNumber) ||
              contact.Email) && (
              <div
                style={{ bottom: 0 }}
                className="position-absolute fs-10 text-black"
              >
                {isPhoneNumber(contact.MobileNumber) ? (
                  <FormattedPhoneNumber
                    withIcon
                    className="link-color lh-normal text-truncate mr-2"
                    number={contact.MobileNumber}
                  />
                ) : isPhoneNumber(contact.PhoneNumber) ? (
                  <FormattedPhoneNumber
                    withIcon
                    className="link-color lh-normal text-truncate mr-2"
                    number={contact.PhoneNumber}
                  />
                ) : null}
                {contact.Email && (
                  <a href={`mailto:${contact.Email}`} target="_top">
                    <FontAwesomeIcon className="mr-1" icon={faEnvelope} />
                    {contact.Email}
                  </a>
                )}
              </div>
            )}
          {contact.MobileNumber && shownumber ? (
            <FormattedPhoneNumber
              className="link-color lh-normal text-truncate"
              number={contact.MobileNumber}
            />
          ) : contact.PhoneNumber && shownumber ? (
            <FormattedPhoneNumber
              className="link-color lh-normal text-truncate"
              number={contact.PhoneNumber}
            />
          ) : contact.Email && showemail ? (
            <span className="fs-12">
              <FontAwesomeIcon className="mr-1" icon={faEnvelope} />
              {contact.Email}
            </span>
          ) : null}
        </div>
      </div>
    );
};

export const ConnectContactEntity = (Component) => {
  return ({ contact, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const c = useContact(contact);
    return <Component contact={c} {...rest} />;
  };
};

export default ContactProfile;

// const mapStateToProps = (state, { contact }) => {
//   if (!contact) return null;

//   const contactEntity = state.Entities.SpaceContacts[contact];

//   const { Id, Name, Description, PhoneNumber } = contactEntity;

//   return { Id, Name, Description, PhoneNumber };
// };



export const DifrentContactProfile = ({ contact }) => {
  if (!contact) return null;
  const { Status } = contact;

  let colorStatus;

  switch (Status) {
    case ContactStatusTypes.active:
      colorStatus = "status-in-progress-colors";
      break;
    case ContactStatusTypes.inactive:
      colorStatus = "status-canceled-colors";
      break;
    default:
      break;
  }

  return (
    <div className={classnames("air-new-profiles-circle", colorStatus)}>
      <FontAwesomeIcon icon={faUserTie} size="xl" />
    </div>
  );
};




export const ConnectedContactListProfile = ConnectContactEntity(
  ContactListProfile
);

export const ConnectedContactProfile = ConnectContactEntity(ContactProfile);

// export const ConnectedContactProfile = connectShallowEntity(
//   "contact",
//   mapStateToProps
// )(ContactProfile);
