import React, { useState, useMemo, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSpaceQuery } from "../../../Helpers/IOClient";
import LoadableButton from "../../../Components/Button/LoadableButton";
import { spaceOrganizationSchema } from "../../../config/schema";
import { useCurrentAccount } from "../../../Contexts/UserContext";
import {
  useOnboardingRequest,
  useSpaceOnboardingStateUpdater,
  onboardingStateEnum,
  SkipOnboardingLink,
  OnboardingStateContext,
  OnboardingAutoRedirect
} from "../SpaceOnboardingHelper";
import "../SpaceOnboarding.css";
import { SpaceOnboardingDepartmentItems } from "./SpaceOnboardingDepartmentsItems";
import {
  faBullhorn,
  faChartLine,
  faCogs,
  faLaptopCode,
  faSuitcase,
  faTools,
  faUserCog,
  faUsers,
  faUserTie
} from "@fortawesome/pro-solid-svg-icons";
import InformationComponent from "../../../Components/InformationComponent/InformationComponent";
import OnboardingHeader from "../../Headers/OnboardingHeader";
import OnboardingContainer from "../../Headers/OnboardingContainer";

const buildInitialState = (intl) => {
  return [
    {
      DepartmentName: intl.formatMessage({ id: "ADMINISTRATION_DEPARTMENT" }),
      IsSelected: true,
      icon: faSuitcase
    },
    {
      DepartmentName: intl.formatMessage({ id: "FINANCIAL_DEPARTMENT" }),
      IsSelected: false,
      icon: faChartLine
    },
    {
      DepartmentName: intl.formatMessage({ id: "MAINTENANCE" }),
      IsSelected: false,
      icon: faTools
    },
    {
      DepartmentName: intl.formatMessage({ id: "IT_DEPARTMENT" }),
      IsSelected: false,
      icon: faLaptopCode
    },
    {
      DepartmentName: intl.formatMessage({ id: "SERVICES" }),
      IsSelected: false,
      icon: faUserCog
    },
    {
      DepartmentName: intl.formatMessage({ id: "OPERATIONS_DEPARTMENT" }),
      IsSelected: false,
      icon: faCogs
    },
    {
      DepartmentName: intl.formatMessage({ id: "COMMERCIAL_DEPARTMENT" }),
      IsSelected: false,
      icon: faUserTie
    },
    {
      DepartmentName: intl.formatMessage({ id: "HUMAN_RESOURCES_DEPARTMENT" }),
      IsSelected: false,
      icon: faUsers
    },
    {
      DepartmentName: intl.formatMessage({ id: "MARKETING" }),
      IsSelected: true,
      icon: faBullhorn
    }
  ];
};

// const DepartmentsListItem = ({ item, updateState, disabled, index }) => {
//   const updateItem = (update) => {
//     updateState((state) => {
//       const newItem = { ...state[index], ...update };

//       const newState = [...state];
//       newState[index] = newItem;
//       return newState;
//     });
//   };
//   const { DepartmentName, IsSelected, Email } = item;
//   const intl = useIntl();
//   return (
//     <div className="d-flex align-items-center py-2">
//       <div
//         style={{ width: 520 }}
//         className={classnames(
//           "ssi-control lg px-3 d-flex align-items-center justify-content-between ar-onboarding-list-item rounded",
//           { selected: IsSelected }
//         )}
//       >
//         <div>{DepartmentName}</div>
//         <Checkbox
//           disabled={disabled}
//           checked={IsSelected}
//           onChange={(e) => updateItem({ IsSelected: e.target.checked })}
//         />
//       </div>
//       <FontAwesomeIcon
//         className={classnames("mx-3", { "text-secondary": IsSelected })}
//         icon={faArrowRight}
//       />

//       <Input
//         style={{ width: 520 }}
//         placeholder={intl.formatMessage({
//           id: "ENTER_DEPARTMENT_EMAIL_PLACEHOLDER",
//         })}
//         className="lg ar-onboarding-list-item"
//         disabled={disabled || !IsSelected}
//         value={Email}
//         onChange={(e) => updateItem({ Email: e.target.value })}
//       />
//     </div>
//   );
// };

// const DepartmentsList = ({ state, updateState, disabled }) => {
//   const addItem = (name) => {
//     updateState((state) => {
//       return [
//         ...state,
//         {
//           DepartmentName: name,
//           IsSelected: true,
//           Email: "",
//         },
//       ];
//     });
//   };

//   return (
//     <div>
//       {state.map((item, index) => {
//         return (
//           <DepartmentsListItem
//             disabled={disabled}
//             item={item}
//             key={index}
//             index={index}
//             updateState={updateState}
//           />
//         );
//       })}
//       <OnboardingAdditionItem onAdd={addItem} />
//     </div>
//   );
// };

const canSubmit = (state) => {
  let hasAtLeastOneSelected = false;
  for (const item of state) {
    const { IsSelected } = item;

    if (IsSelected) {
      hasAtLeastOneSelected = true;
    }
  }
  return hasAtLeastOneSelected ? true : false;
};

const SpaceOnboardingDepartments = ({ path }) => {
  const intl = useIntl();

  const [state, setState] = useState(() => buildInitialState(intl));
  const { obState } = useContext(OnboardingStateContext);
  const {
    loading: loadingOrganizations,
    data: organizations,
    error: organizationsError
  } = useSpaceQuery(`query/organizations`, [spaceOrganizationSchema]);

  const onboardingUpdate = useSpaceOnboardingStateUpdater();
  const { update, retry, loading: loadingPreference } = onboardingUpdate;
  const updateState = () => {
    update(onboardingStateEnum.invites);
  };

  const { submit, loading } = useOnboardingRequest("departments", updateState);
  const currentUser = useCurrentAccount();
  const handleSubmit = () => {
    const newArr = [];

    for (const item of state) {
      if (item.IsSelected) newArr.push(item);
    }

    const buildBody = (item) => {
      const { DepartmentName } = item;
      const body = {
        Name: DepartmentName,
        ParentOrganizationId: organizations[0],
        Collaborators: [],
        Managers: [currentUser.Id],
        ContactMediums: []
      };
      // if (Email !== "") {
      //   body.ContactMediums = [
      //     {
      //       ContactMediumTypeId: 1,
      //       Contact: Email,
      //     },
      //   ];
      // }
      return body;
    };

    submit(newArr, buildBody);
  };

  const cantSubmit = useMemo(() => !canSubmit(state), [state]);
  // console.log(obState);
  if (obState !== onboardingStateEnum.departments)
    return <OnboardingAutoRedirect url={path} />;

  return (
    <OnboardingContainer>
      <OnboardingHeader newWidth={790} step={3} />
      <div className="mx-4">
        <div className="mx-auto w-100 py-4">
          <div style={{ maxWidth: 790 }} className="mx-auto w-100">
            <div className="fs-28 mb-2 text-black">
              <FormattedMessage id="CREATE_DEPARTMENTS" />
            </div>
            <div className="mb-3">
              <div className="d-flex align-items-center">
                <span className="mr-2">
                  <FormattedMessage id="CREATE_DEPARTMENTS_DESC" />
                </span>
                <InformationComponent
                  text={
                    <FormattedMessage id="CREATE_DEPARTMENTS_DESC_INFO_DESC" />
                  }
                />
              </div>
            </div>
            <div className="">
              <SpaceOnboardingDepartmentItems
                state={state}
                updateState={setState}
              />
              {/* <DepartmentsList
            disabled={submitted}
            state={state}
            updateState={setState}
          /> */}
            </div>
            <LoadableButton
              disabled={cantSubmit}
              className="mt-3 w-100"
              vType="secondary"
              onClick={retry || handleSubmit}
              isLoading={
                loading ||
                loadingOrganizations ||
                organizationsError ||
                loadingPreference
              }
            >
              <FormattedMessage id="SUBMIT" />
            </LoadableButton>
            <div className="mt-2 fs-12">
              <SkipOnboardingLink textId="IGNORE_DEPARTMENTS_DESC" />
              {/* <Link className="link-secondary" to={`${path}/teams`}>
          <FormattedMessage id="IGNORE_DEPARTMENTS_DESC" />
        </Link> */}
            </div>
          </div>
        </div>
      </div>
    </OnboardingContainer>
  );
};

export default SpaceOnboardingDepartments;
