import React from "react";
import FormInput from "../Forms/FormInput/FormInput";
import { FormattedMessage } from "react-intl";
import Dropdown from "../Dropdown/Dropdown";
import SplitCol from "../SplitCol/SplitCol";
import Input from "../Input/Input";

const CovenantTimeUnitType = {
  day: {
    key: 1,
    maxPeriodicity: 50,
    defaultPeriodicity: 1,
    translationId: "day"
  },
  month: {
    key: 2,
    maxPeriodicity: 50,
    defaultPeriodicity: 1,
    translationId: "month"
  },
  year: {
    key: 3,
    maxPeriodicity: 50,
    defaultPeriodicity: 1,
    translationId: "year"
  },
  week: {
    key: 4,
    maxPeriodicity: 50,
    defaultPeriodicity: 1,
    translationId: "week"
  }
};

const CovenantItems = [1, 2, 3, 4];
const CovenantItemsTrans = {
  1: {
    key: 1,
    maxPeriodicity: 50,
    defaultPeriodicity: 1,
    translationId: "DAY"
  },
  2: {
    key: 2,
    maxPeriodicity: 50,
    defaultPeriodicity: 1,
    translationId: "MONTH"
  },
  3: {
    key: 3,
    maxPeriodicity: 50,
    defaultPeriodicity: 1,
    translationId: "YEAR"
  },
  4: {
    key: 4,
    maxPeriodicity: 50,
    defaultPeriodicity: 1,
    translationId: "WEEK"
  }
};

export const getCovenantUnitsArray = () => {
  const options = [];

  for (const key in CovenantTimeUnitType) {
    if (CovenantTimeUnitType.hasOwnProperty(key)) {
      const element = CovenantTimeUnitType[key];
      options.push(element);
    }
  }
  return options;
};

export class CovenantValue {
  constructor() {
    this.Value = "0";
    this.TimeValue = 1;
    this.TimeUnit = 2;
  }
}

// const CovenantPeriodicityDropdownValue = props => {
//   return <span className="ar-dropdown-button">{props.item.data}</span>;
// };

const CovenantPeriodicityUnitDropdownValue = ({ item, noClass }) => {
  return item ? (
    <FormattedMessage id={CovenantItemsTrans[item].translationId}>
      {(text) => (
        <div
          className={
            noClass ? undefined : "ar-dropdown-button d-flex align-items-center"
          }
        >
          {text}
        </div>
      )}
    </FormattedMessage>
  ) : null;
};

export const CovenantPeriodicity = ({ value, preview, name, onChange }) => {
  const { TimeUnit: tUnit, TimeValue } = value || new CovenantValue();

  const TimeUnit = tUnit;

  // const periodicityOptions = useMemo(() => {
  //   const options = [];
  //   for (let i = 1; i <= TimeUnit.maxPeriodicity; i++) {
  //     options.push(i.toString());
  //   }
  //   return options;
  // }, [TimeUnit.maxPeriodicity]);

  const handleBaseChange = (v) => {
    onChange({ ...value, ...v });
  };

  const handleTimeValueChange = (e) => {
    const v = e.target.value ? Number(e.target.value) : null;

    if (!v) return;

    handleBaseChange({
      TimeValue: v
    });
  };

  const handleTimeUnitChange = (v) => {
    handleBaseChange({
      TimeUnit: v
    });
  };

  if (preview)
    return (
      <div className="d-flex align-items-center">
        <div className="fs-14 text-black w-50 mr-2">{TimeValue}</div>

        <CovenantPeriodicityUnitDropdownValue noClass item={TimeUnit} />
      </div>
    );

  return (
    <div className="d-flex align-items-center">
      <Input
        preview={preview}
        // type="number"
        // min="1"
        value={TimeValue}
        onChange={handleTimeValueChange}
        className="w-50 mr-2"
        name="search-text"
        autoComplete="off"
      />
      {/* <Dropdown
        items={periodicityOptions}
        value={TimeValue}
        listItemComponent={CovenantPeriodicityDropdownValue}
        valueComponent={CovenantPeriodicityDropdownValue}
        closeOnSelect
        onChange={handleTimeValueChange}
        className="w-50 mr-2"
      /> */}
      <Dropdown
        preview={preview}
        items={CovenantItems}
        value={TimeUnit}
        // processValue={(data) => {
        //   return {data: unitOptions[data - 1]};
        // }}
        listItemComponent={CovenantPeriodicityUnitDropdownValue}
        valueComponent={CovenantPeriodicityUnitDropdownValue}
        closeOnSelect
        onChange={handleTimeUnitChange}
      />
    </div>
  );
};

// const CovenantValueOverview = ({ value }) => {
//   const { Value: CovenantValue, TimeValue, TimeUnit } = value;

//   if (!CovenantValue) return "--";

//   let resolvedValue;

//   switch (TimeUnit) {
//     case value:
//       break;

//     default:
//       break;
//   }
// };

const CovenantSelector = ({ value, name, onChange }) => {
  const { Value: CovenantValue } = value;

  const handleBaseChange = (v) => {
    onChange({ ...value, ...v });
  };

  const handleValueChange = (e) => {
    if (isNaN(e.target.value)) return;

    handleBaseChange({
      Value: e.target.value
    });
  };

  return (
    <React.Fragment>
      <SplitCol
        className="mb-3"
        leftpercent={60}
        left={
          <FormInput
            text={<FormattedMessage id="total_covenant_value" />}
            value={CovenantValue}
            onChange={handleValueChange}
          />
        }
        right={
          <FormInput
            text={<FormattedMessage id="periodicity" />}
            value={value}
            onChange={handleBaseChange}
            inputType={CovenantPeriodicity}
          />
          //   <CovenantPeriodicity
          //     onChange={handleBaseChange}
          //     TimeUnit={TimeUnit}
          //     TimeValue={TimeValue}
          //   />
        }
      />
    </React.Fragment>
  );
};

export default CovenantSelector;
