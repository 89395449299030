import { useReducer, useRef, useEffect } from "react";
import { getItemFromEntityState } from "./EntityHooks";
import store from "../store";
import { keyedSchemas } from "../Helpers/SchemaHelper";
import { isEquivalent } from "../Helpers/MiscHelper";

interface ILinks {
  key: string | number;
  schema: any;
  value: any;
}

const isValueValid = (value: any) => {
  return value !== undefined && value !== null;
};

const linksChanged = (links: ILinks[]) => {
  for (const link of links) {
    const { key, schema, value } = link;
    const newValue = getItemFromEntityState(schema, key);
    if (newValue !== value) return true;
  }
};

const getLinkSchema = (parentSchema: any, propName: string) => {
  if (parentSchema.relationships) {
    return parentSchema.relationships[propName];
  } else {
    return parentSchema[propName];
  }
};

const getLinkedValue = (
  schema: any,
  entityId: any,
  selector: any,
  links: ILinks[] = []
) => {
  const state = store.getState();
  let value: any;
  if (typeof entityId === "object") {
    value = entityId;
  } else {
    value = getItemFromEntityState(schema, entityId, state);
  }

  links.push({
    key: entityId,
    schema,
    value,
  });

  if (selector && isValueValid(value)) {
    const link = (propName: string) => {
      let key = value[propName];
      const linkOriginalSchema: any = getLinkSchema(schema, propName);
      if (!linkOriginalSchema) return key;
      var schemas = keyedSchemas as any;
      const linkSchema: any = schemas[linkOriginalSchema.key];

      let linkValue = getItemFromEntityState(linkSchema, key, state);
      links.push({ key, schema: linkSchema, value: linkValue });
      return linkValue;
    };
    value = selector(value, link);
  }

  return { links, value };
};

const getLinkedValues = (
  schema: any,
  items: any[],
  selector: any,
  prevArr?: any[]
) => {
  const links: ILinks[] = [];
  const valueArr = [];
  let hasChanged;

  for (let itemI = 0; itemI < items.length; itemI++) {
    const item = items[itemI];
    const { value } = getLinkedValue(schema, item, selector, links);
    valueArr.push(value);

    if (!hasChanged && prevArr) {
      const prevValue = prevArr[itemI];
      let prevType = typeof prevValue;
      let newType = typeof value;

      if (prevType === newType) {
        if (newType === "object" && !isEquivalent(prevValue, value))
          hasChanged = true;
        else if (prevValue !== value) hasChanged = true;
      } else {
        hasChanged = true;
      }
    }
  }

  return { value: valueArr, links, hasChanged };
};

export function useEntityValueSelector<T>(
  schema: any,
  entityId: any,
  selector?: (value: any, link: any) => any
): T {
  const [, forceUpdate] = useReducer((x: any, initial: any) => x + 1, 0);

  const valueRef = useRef<T>();
  const linksRef = useRef<ILinks[]>([]);

  const prevSelectorRef = useRef<any>();
  const previousIdRef = useRef<any>();

  if (
    !valueRef.current ||
    entityId !== previousIdRef.current ||
    selector !== prevSelectorRef.current
  ) {
    const { value, links } = getLinkedValue(schema, entityId, selector);
    valueRef.current = value;
    linksRef.current = links;

    previousIdRef.current = entityId;
    prevSelectorRef.current = selector;
  }

  useEffect(() => {
    if (entityId === undefined || entityId === null) return;

    let didUnsubscribe = false;

    const listener = () => {
      if (didUnsubscribe) return;

      if (linksChanged(linksRef.current)) {
        const { value, links } = getLinkedValue(schema, entityId, selector);
        let prevType = typeof valueRef.current;
        let newType = typeof value;
        const prevValue = valueRef.current;
        valueRef.current = value;
        linksRef.current = links;

        if (prevType === newType) {
          if (newType === "object" && !isEquivalent(prevValue, value))
            forceUpdate(1);
          else if (prevValue !== value) forceUpdate(1);
        } else {
          forceUpdate(1);
        }
      }
    };
    const unsubscribe = store.subscribe(listener);

    return () => {
      didUnsubscribe = true;
      unsubscribe();
    };
  }, [entityId, schema, selector]);

  return valueRef.current as T;
}

export function useMultipleEntityValueSelector<T>(
  schema: any,
  items: any[],
  selector?: (value: any, link: any) => any
): T[] {
  const [, forceUpdate] = useReducer((x: any, initial: any) => x + 1, 0);

  const valueRef = useRef<T[]>();
  const linksRef = useRef<ILinks[]>([]);

  const prevSelectorRef = useRef<any>();
  const previousItemsRef = useRef<any>();

  if (
    !valueRef.current ||
    items !== previousItemsRef.current ||
    selector !== prevSelectorRef.current
  ) {
    const { value, links } = getLinkedValues(schema, items, selector);
    valueRef.current = value;
    linksRef.current = links;

    previousItemsRef.current = items;
    prevSelectorRef.current = selector;
  }

  useEffect(() => {
    if (items === undefined || items === null) return;

    let didUnsubscribe = false;

    const listener = () => {
      if (didUnsubscribe) return;

      if (linksChanged(linksRef.current)) {
        const { value, links, hasChanged } = getLinkedValues(
          schema,
          items,
          selector,
          valueRef.current
        );

        valueRef.current = value;
        linksRef.current = links;

        if (hasChanged) {
          forceUpdate(1);
        }
      }
    };
    const unsubscribe = store.subscribe(listener);

    return () => {
      didUnsubscribe = true;
      unsubscribe();
    };
  }, [items, schema, selector]);

  return valueRef.current as T[];
}

export function useMultipleMultipleEntityValueSelector<T>(
  schema: any,
  items: any,
  keys: any,
  selector?: (value: any, link: any) => any
): T[] {
  const [, forceUpdate] = useReducer((x: any, initial: any) => x + 1, 0);

  const valueRef = useRef<any>();
  const linksRef = useRef<any>([]);

  const prevSelectorRef = useRef<any>();
  const previousItemsRef = useRef<any>();

  if (
    !valueRef.current ||
    items !== previousItemsRef.current ||
    selector !== prevSelectorRef.current
  ) {
    const values: any[] = [];
    const linksArray: any[] = [];

    keys.forEach((e: any) => {
      const { value, links } = getLinkedValues(schema, items[e], selector);
      values.push(value);
      linksArray.push(links);
    });
    valueRef.current = values;
    linksRef.current = linksArray;
    previousItemsRef.current = items;
    prevSelectorRef.current = selector;
  }

  useEffect(() => {
    if (items === undefined || items === null) return;

    let didUnsubscribe = false;

    const listener = () => {
      if (didUnsubscribe) return;

      if (linksChanged(linksRef.current)) {
        const values: any[] = [];
        const linksArray: any[] = [];
        const hasChangedArr: any[] = [];
        keys.forEach((e: any, i: any) => {
          const { value, links, hasChanged } = getLinkedValues(
            schema,
            items[e],
            selector,
            valueRef.current[i]
          );
          values.push(value);
          linksArray.push(links);
          hasChangedArr.push(hasChanged);
        });
        valueRef.current = values;
        linksRef.current = linksArray;

        hasChangedArr.forEach((e: any) => {
          if (e) {
            forceUpdate(1);
          }
        });
      }
    };
    const unsubscribe = store.subscribe(listener);

    return () => {
      didUnsubscribe = true;
      unsubscribe();
    };
  }, [items, keys, schema, selector]);

  return valueRef.current as T[];
}
