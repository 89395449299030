import React from "react";
import { useAccount, useEntityValue } from "../../../../Hooks/EntityHooks";
import {
  GeneralNotificationItem,
  NotificationClientProfile
} from "./NotificationHelper";
import { FormattedMessage } from "react-intl";
import TaskOrigin from "../../../Origin/TaskOrigin";
import TicketOrigin from "../../../Origin/TicketOrigin";
import ContractOrigin from "../../../Origin/ContractOrigin";
import ClientOrigin from "../../../Origin/ClientOrigin";
import DealOrigin from "../../../Origin/DealOrigin";
import ProjectOrigin from "../../../Origin/ProjectOrigin";
import CallOrigin from "../../../Origin/CallOrigin";
import {
  spaceCallsSchema,
  spaceClientSchema,
  spaceContactSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceInterventionsSchema,
  spaceProjectsSchema,
  spaceSubscriptionSchema,
  spaceTasksSchema,
  spaceTicketsSchema
} from "../../../../config/schema";
import ContactOrigin from "../../../Origin/ContactOrigin";
import SubscriptionOrigin from "../../../Origin/SubscriptionOrigin";
import InterventionOrigin from "../../../Origin/InterventionOrigin";

// const NotificationDeleteProject = ({ project, notf }) => {
//   const resolvedProject = useProject(project);

//   const { FromAccount } = notf;
//   const { Name: FromName } = FromAccount;
//   const { Client, IndirectClient } = resolvedProject;
//   return (
//     <GeneralNotificationItem
//       OriginComponent={<ProjectOrigin project={project} />}
//       redirectUrl={buildNotificationEndpoint("projects", "Project/Id", project)}
//       body={
//         <div className="text-black ar-double-line-elipsis fs-14">
//           <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
//           <FormattedMessage id={"PROJECT_UPDATE"} />{" "}
//           <b>{resolvedProject.Name}</b>
//           <br />
//           <NotificationClientProfile client={Client || IndirectClient} />
//         </div>
//       }
//     />
//   );
// };

const NotificationDelete = ({
  item,
  notf,
  textId,
  schema,
  IndexName,
  originC
}) => {
  const resolvedItem = item;

  const Name = resolvedItem[IndexName];
  const { FromAccount } = notf;
  const { Name: FromName } = FromAccount;
  const { Client, IndirectClient } = resolvedItem;
  return (
    <GeneralNotificationItem
      OriginComponent={originC}
      body={
        <div className="text-black ar-double-line-elipsis fs-14">
          <FormattedMessage id={"THE_USER"} /> <b>{FromName}</b>{" "}
          <FormattedMessage id={textId} /> <b>{Name}</b>
          <br />
          {(Client || IndirectClient) && (
            <NotificationClientProfile client={Client || IndirectClient} />
          )}
        </div>
      }
    />
  );
};

const NotificationDeleteItem = ({ value }) => {
  const {
    Project,
    Call,
    Task,
    Ticket,
    Contract,
    Client,
    Deal,
    Contact,
		TimeCharge,
    Subscription
  } = value;

  if (Project) {
    return (
      <NotificationDelete
        originC={<ProjectOrigin project={Project} />}
        schema={spaceProjectsSchema}
        notf={value}
        textId="PROJECT_DELETED"
        item={Project}
        IndexName="Name"
      />
    );
  } else if (Call)
    return (
      <NotificationDelete
        originC={<CallOrigin call={Call} />}
        schema={spaceCallsSchema}
        notf={value}
        textId="CALL_DELETED"
        IndexName="NoTagDescription"
        item={Call}
      />
    );
  else if (Contact)
    return (
      <NotificationDelete
        originC={<ContactOrigin contact={Contact} />}
        schema={spaceContactSchema}
        notf={value}
        textId="CONTACTED_DELETED"
        IndexName="Name"
        item={Contact}
      />
    );
  else if (Subscription)
    return (
      <NotificationDelete
        originC={<SubscriptionOrigin Subscription={Subscription} />}
        schema={spaceSubscriptionSchema}
        notf={value}
        textId="SUBSCRIPTION_DELETED"
        IndexName="NoTagDescription"
        item={Subscription}
      />
    );
  else if (Task)
    return (
      <NotificationDelete
        originC={<TaskOrigin task={Task} />}
        schema={spaceTasksSchema}
        notf={value}
        textId="TASK_DELETED"
        item={Task}
        IndexName="Name"
      />
    );
  else if (TimeCharge)
    return (
      <NotificationDelete
        originC={<InterventionOrigin intervention={TimeCharge} />}
        schema={spaceInterventionsSchema}
        notf={value}
        textId="TIMECHARGE_DELETED"
        item={TimeCharge}
        IndexName="NoTagDescription"
      />
    );
  else if (Ticket)
    return (
      <NotificationDelete
        originC={<TicketOrigin ticket={Ticket} />}
        notf={value}
        schema={spaceTicketsSchema}
        item={Ticket}
        textId="CASE_DELETED"
        IndexName="Name"
      />
    );
  else if (Contract)
    return (
      <NotificationDelete
        originC={<ContractOrigin contract={Contract} />}
        schema={spaceContractSchema}
        notf={value}
        item={Contract}
        textId="CONTRACT_DELETED"
        IndexName="NoTagDescription"
      />
    );
  else if (Client)
    return (
      <NotificationDelete
        originC={<ClientOrigin client={Client} />}
        notf={value}
        schema={spaceClientSchema}
        item={Client}
        textId="ACCOUNT_DELETED"
        IndexName="Name"
      />
    );
  else if (Deal)
    return (
      <NotificationDelete
        originC={<DealOrigin deal={Deal} />}
        notf={value}
        schema={spaceDealsSchema}
        textId="DEAL_DELETED"
        IndexName="Name"
        item={Deal}
      />
    );
  else return null;
};

export default NotificationDeleteItem;
