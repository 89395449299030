import React, {
  useMemo,
  useContext,
  useState,
  useCallback,
  useRef
} from "react";

import "./History.css";
import classnames from "classnames";
import { useSpacePostQuery } from "../../../Helpers/IOClient";
import { useRouteMatch } from "react-router-dom";
import TabStrip from "../../TabStrip/TabStrip";
import { spaceHistoryObject } from "../../../config/schema";
import { createDateGroupingHook } from "../../../Hooks/MiscHooks";
import moment from "moment";
import { HistoryItem } from "./HistoryItem/HistoryItem";
import { buildODataQuery } from "../../../Helpers/ODataHelper";
import { useEndlessScroll } from "../../EndlessScrollPage/EndlessScrollPage";
import LoaderSpinner from "../../Loader/LoaderSpinner/LoaderSpinner";
import Button from "../../Button/Button";
import LineHeader from "../../LineHeader/LineHeader";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup, { usePopupOpenState } from "../../Popup/Popup";
import AccountSelector from "../../AccountSelector/AccountSelector";
import { useCurrentAccountSpace } from "../../../Contexts/UserContext";
import { hasModuleAccess, moduleTypes } from "../../../Helpers/ModulesHelper";
import { BaseHistoryUrlContext } from "./HistoryHelper";
import { FormattedMessage, useIntl } from "react-intl";

const HistoryItemSkeleton = ({ className }) => {
  return (
    <div
      className={classnames("ar-history-item p-3 rounded bg-white", className)}
    >
      <div className="d-flex mb-4 justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div className="ar-history-item-title-image-skeleton skeleton mr-2" />
          <div className="ar-history-item-title-skeleton skeleton mr-2" />
          <div className="ar-history-item-title-skeleton skeleton mr-2" />
        </div>
        <div className="ar-history-item-title-skeleton skeleton ml-2" />
      </div>
      <div className="px-4">
        <div className="ar-history-item-desc-skeleton skeleton mb-3" />
        <div className="ar-history-item-desc-skeleton skeleton" />
      </div>
    </div>
  );
};

const buildHistoryUrl = (historyContext) => {
  const {
    call,
    ticket,
    tickets,
    project,
    task,
    tasks,
    intervention,
    client,
    contact,
    contract,
    deals,
    deal,
    onlyDeals,
    subscription,
    personal
  } = historyContext;

  if (personal) return `Account/Timeline`;
  else if (call) return `calls/${call}/timeline`;
  else if (ticket) return `tickets/${ticket}/timeline`;
  else if (subscription) return `subscriptions/${subscription}/timeline`;
  else if (tickets) return `tickets/timeline`;
  else if (project) return `projects/${project}/timeline`;
  else if (task) return `tasks/${task}/timeline`;
  else if (tasks) return `tasks/timeline`;
  else if (intervention) return `interventions/${intervention}/timeline`;
  else if (client) return `clients/${client}/timeline`;
  else if (contact) return `contacts/${contact}/timeline`;
  else if (contract) return `contracts/${contract}/timeline`;
  else if (onlyDeals) return `deals/timeline/OnlyDeals`;
  else if (deal) return `deals/${deal}/timeline`;
  else if (deals) return `deals/timeline`;
  return "";
};

const top = 20;
const baseSkip = 0;
const spaceHistoryResponse = [spaceHistoryObject];
export const HistoryType = {
  All: 0,
  Client: 1,
  Project: 2,
  Ticket: 4,
  Call: 8,
  Task: 16,
  Contract: 32,
  Timecharge: 64,
  Billing: 128,
  Notes: 256,
  Deals: 512,
  Subscriptions: 1024,
  AllButNotes: 1791
};
const useHistoryQuery = (defaultFilter, type, accountId) => {
  const context = useContext(HistoryContext);

  const { pageRef } = context;

  const [skip, setSkip] = useState(baseSkip);

  const [data, setData] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [accounts, setAccounts] = useState([]);

  const setAccountsFilter = (v) => {
    setAccounts(v);
    setSkip(baseSkip);
    setData(undefined);
  };

  const fetchMore = useCallback(() => {
    setSkip((s) => s + top);
  }, []);

  const baseUrl = useMemo(() => {
    return buildHistoryUrl(context);
  }, [context]);

  const url = useMemo(() => {
    const params = buildODataQuery({
      top,
      skip,
      count: false
    });
    return `${baseUrl}${params}`;
  }, [baseUrl, skip]);

  const filter = useMemo(() => {
    // console.log(defaultFilter);
    return {
      Type: type || 0,
      ...defaultFilter,
      AccountId: accountId,
      Accounts: accounts
    };
  }, [type, defaultFilter, accountId, accounts]);

  const { loading, error, refetch } = useSpacePostQuery(
    url,
    filter,
    spaceHistoryResponse,
    {
      cache: false,
      onSuccess: ({ data }) => {
        const resolvedData = [];

        for (const e in data) {
          resolvedData.push(data[e]);
        }

        if (top > resolvedData.length) setHasMore(false);
        // ;
        setData((d) => (d ? [...d, ...resolvedData] : resolvedData));
        //setData(d => (d ? [...d, ...data.d.results] : data.d.results));
      }
    }
  );

  useEndlessScroll({
    listen: hasMore && !loading && !error,
    onFetchMore: fetchMore,
    offsettMultiplier: 1.5,
    pageRef
  });

  return {
    data,
    loading,
    error,
    fetchMore,
    hasMore,
    refetch,
    accountsFilter: accounts,
    setAccountsFilter,
    baseUrl
  };
};

const isDiffrentMonth = (d1, d2) => {
  const rd1 = new Date(d1);
  const rd2 = new Date(d2);

  if (
    rd1.getMonth() !== rd2.getMonth() ||
    rd1.getFullYear() !== rd2.getFullYear()
  )
    return false;

  return true;
};
const buildDateKey = (date) => {
  const d = new Date(date);
  const month = d.getMonth();
  const year = d.getFullYear();

  return `${month}${year}`;
};

const useGroupedHistory = createDateGroupingHook(
  isDiffrentMonth,
  buildDateKey,
  "Date"
);

export const HistorySkeleton = () => {
  const { loadingClassName } = useContext(HistorySettingsContext) || {};

  return (
    <div className={loadingClassName}>
      <div className="skeleton ar-history-date-skeleton rounded mb-4" />
      <HistoryItemSkeleton className="mb-3" />
      <HistoryItemSkeleton />
    </div>
  );
};

const HistoryDateGroup = ({ item }) => {
  const { date, items } = item;

  const { TitleComponent, itemClassName } =
    useContext(HistorySettingsContext) || {};

  const formattedDate = useMemo(() => moment(date).format("MMMM YYYY"), [date]);
  return (
    <div className="ar-history-date-group">
      {TitleComponent ? (
        <TitleComponent formattedDate={formattedDate} />
      ) : (
        <div className="fs-22 text-black text-capitalize mb-2">
          {formattedDate}
        </div>
      )}
      <div>
        {items.map((v, index) => {
          return (
            <HistoryItem
              className={itemClassName}
              index={index}
              item={v}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

const HistoryDateGroupedList = React.memo(({ items }) => {
  const groupedItems = useGroupedHistory(items);
  return groupedItems.map((v) => {
    return <HistoryDateGroup item={v} key={v.key} />;
  });
});

const HistoryUsersFilterContent = ({
  accountsFilter,
  setAccountsFilter,
  toggleIsOpen
}) => {
  const [accounts, setAccounts] = useState(accountsFilter || []);
  return (
    <div className="px-4 py-3 bg-white">
      <AccountSelector
        multiple
        selectedAccounts={accountsFilter}
        value={accounts}
        onChange={setAccounts}
      />

      {/* {links} */}

      <Button
        className="w-100 mt-4"
        onClick={() => {
          setAccountsFilter(accounts);
          toggleIsOpen(false);
        }}
      >
        <FormattedMessage id={"SAVE"} />
      </Button>
    </div>
  );
};

const FilterButton = React.forwardRef(
  ({ children, className, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={classnames(
          "d-inline-flex align-items-center cursor-pointer text-primary position-relative",
          className
        )}
        {...rest}
      >
        <div className="mr-2 text-truncate">{children}</div>

        <FontAwesomeIcon icon={faCaretDown} />
      </div>
    );
  }
);

const HistoryUsersFilter = (props) => {
  const anchorRef = useRef();
  const popupRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef, anchorRef);
  const { accountsFilter } = props;
  return (
    <>
      <Popup
        domRef={popupRef}
        anchorEl={anchorRef.current}
        isOpen={isOpen}
        placement="bottom-start"
      >
        <HistoryUsersFilterContent toggleIsOpen={toggleIsOpen} {...props} />
      </Popup>

      <FilterButton ref={anchorRef} onClick={(e) => toggleIsOpen(true)}>
        {accountsFilter.length === 0 ? "Todos os utilizadores" : "Customizado"}
      </FilterButton>
    </>
  );
};

// const HistoryActivityFilter = props => {
//   const anchorRef = useRef();
//   const popupRef = useRef();
//   const [isOpen, toggleIsOpen] = usePopupOpenState(popupRef);
//   const { accountsFilter } = props;
//   return (
//     <>
//       <Popup
//         domRef={popupRef}
//         anchorEl={anchorRef.current}
//         isOpen={isOpen}
//         placement="bottom-start"
//       >
//         <HistoryUsersFilterContent toggleIsOpen={toggleIsOpen} {...props} />
//       </Popup>

//       <FilterButton ref={anchorRef} onClick={e => toggleIsOpen(true)}>
//         Filtrar atividade
//       </FilterButton>
//     </>
//   );
// };

export const EndlessHistory = ({
  items,
  error,
  hasMore,
  onRetry,
  accountsFilter,
  noFilter,
  setAccountsFilter
}) => {
  const hasNoItems = !error && !hasMore && items.length === 0;

  if (hasNoItems)
    return (
      <div className="ar-history">
        {!noFilter && (
          <div className="mb-3 fs-14">
            <div className="d-inline-block text-black mr-2">
              <FormattedMessage id={"FILTER_BY"} />
            </div>
            <HistoryUsersFilter
              accountsFilter={accountsFilter}
              setAccountsFilter={setAccountsFilter}
            />
          </div>
        )}
        <div className="h-100 w-100 d-flex align-items-center">
          <div className="ar-history-no-items text-black fs-16">
            <FormattedMessage id={"EMPTY_HISTORY"} />
          </div>
        </div>
      </div>
    );

  return (
    <div className="ar-history">
      {!noFilter && (
        <div className="mb-3 fs-14">
          <div className="d-inline-block text-black mr-2">
            <FormattedMessage id={"FILTER_BY"} />
          </div>
          <HistoryUsersFilter
            accountsFilter={accountsFilter}
            setAccountsFilter={setAccountsFilter}
          />
        </div>
      )}
      <HistoryDateGroupedList items={items} />
      {hasMore && !error && (
        <LoaderSpinner size="sm" className="text-primary" centerHorizontal />
      )}
      {error && <HistoryError onRetry={onRetry} />}
      {!hasMore && (
        <LineHeader className="text-black fs-22">
          <FormattedMessage id={"HISTORY_STARTS"} />
        </LineHeader>
      )}
    </div>
  );
};

const HistoryError = ({ onRetry }) => {
  return (
    <div className="text-center h-100 d-flex flex-column justify-content-center align-items-center">
      <div className="ar-exclamation-warning-svg mx-auto mb-2" />
      <div className="ar-history-title fw-bold fs-22">
        <FormattedMessage id={"HISTORY_ERROR"} />
      </div>

      {onRetry && (
        <Button className="mt-3" onClick={onRetry}>
          <FormattedMessage id={"TRY_AGAIN"} />
        </Button>
      )}
      {/* <div className="text-black">
        Pedimos desculpa mas foi detetado um erro
      </div> */}
    </div>
  );
};

export const BaseHistory = ({ defaultFilter, type, accountId, ...rest }) => {
  const {
    data,
    loading,
    error,
    hasMore,
    refetch,
    setAccountsFilter,
    accountsFilter,
    baseUrl
  } = useHistoryQuery(defaultFilter, type, accountId);

  if (data)
    return (
      <BaseHistoryUrlContext.Provider value={baseUrl}>
        <EndlessHistory
          onRetry={refetch}
          items={data}
          loading={loading}
          error={error}
          hasMore={hasMore}
          setAccountsFilter={setAccountsFilter}
          accountsFilter={accountsFilter}
          {...rest}
        />
      </BaseHistoryUrlContext.Provider>
    );
  if (loading) return <HistorySkeleton />;

  if (error || !data) return <HistoryError onRetry={refetch} />;
};

const HistoryContext = React.createContext();

const HistoryProvider = ({ children, value }) => {
  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
};

const createHistoryTab = ({ filter, name, to }) => {
  const Component = () => {
    return <BaseHistory defaultFilter={filter} />;
  };

  return { component: Component, name, to };
};

// eslint-disable-next-line no-unused-vars
const NotesTab = createHistoryTab({
  name: <FormattedMessage id={"NOTE"} />,
  to: "notes",
  filter: { Type: HistoryType.Notes }
});
const CallsTab = createHistoryTab({
  name: <FormattedMessage id={"CALL"} />,
  to: "calls",
  filter: { Type: HistoryType.Call }
});
const TicketsTab = createHistoryTab({
  name: <FormattedMessage id={"CASE"} />,
  to: "tickets",
  filter: { Type: HistoryType.Ticket }
});
const ProjectsTab = createHistoryTab({
  name: <FormattedMessage id={"PROJECT"} />,
  to: "projects",
  filter: { Type: HistoryType.Project }
});
const TasksTab = createHistoryTab({
  name: <FormattedMessage id={"TASK"} />,
  to: "tasks",
  filter: { Type: HistoryType.Task }
});
const InterventionsTab = createHistoryTab({
  name: <FormattedMessage id={"TIME_RECORD"} />,
  to: "interventions",
  filter: { Type: HistoryType.Timecharge }
});

const useAdditionalHistoryTabs = (props, accountSpace) => {
  const tabs = [];
  if (hasModuleAccess(accountSpace.Modules[moduleTypes.calls]))
    tabs.push(CallsTab);
  if (hasModuleAccess(accountSpace.Modules[moduleTypes.tickets]))
    tabs.push(TicketsTab);
  if (hasModuleAccess(accountSpace.Modules[moduleTypes.projects]))
    tabs.push(ProjectsTab);
  if (hasModuleAccess(accountSpace.Modules[moduleTypes.tasks]))
    tabs.push(TasksTab);
  if (hasModuleAccess(accountSpace.Modules[moduleTypes.interventions]))
    tabs.push(InterventionsTab);
  // const {
  //   call,
  //   ticket,
  //   project,
  //   task,
  //   intervention,
  //   client,
  //   contact,
  //   contract
  // } = props;

  // if (call || client)
  return tabs;
  // return [];
};

export const TimelineTypeEnum = {
  All: 0,
  Client: 1,
  Project: 2,
  Ticket: 4,
  Call: 8,
  Task: 16,
  Contract: 32,
  Timecharge: 64,
  Billing: 128,
  Note: 256,
  Deal: 512,
  Subscription: 1024
};

const HistorySettingsContext = React.createContext({});

export const History = React.memo((props) => {
  const { disableTabs, onMouseDown, settings, type, ...rest } = props;
  const accountSpace = useCurrentAccountSpace();
  const additionalTabs = useAdditionalHistoryTabs(props, accountSpace);
  const intl = useIntl();
  const match = useRouteMatch();
  const path = match.url;

  const tabItems = useMemo(() => {
    if (disableTabs) return null;
    return [
      {
        default: true,
        exact: true,
        name: intl.formatMessage({ id: "ACTIVITY" }),
        component: BaseHistory
      },
      ...additionalTabs
    ];
  }, [additionalTabs, disableTabs, intl]);
  const pageRef = useRef();
  const { containerClassName } = settings || {};
  return (
    <HistorySettingsContext.Provider value={settings}>
      <div
        onMouseDown={onMouseDown}
        ref={pageRef}
        className={
          containerClassName || "flex-1 px-4 py-3 of-y-auto cursor-auto h-100"
        }
      >
        <HistoryProvider value={{ ...rest, pageRef }}>
          {disableTabs ? (
            <BaseHistory key={type} type={type} {...rest} />
          ) : (
            <TabStrip
              defaultPath={path}
              alignLeft
              items={tabItems}
              className="ar-details-tabstrip"
            />
          )}
        </HistoryProvider>
      </div>
    </HistorySettingsContext.Provider>
  );
});
