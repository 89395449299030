import React from "react";
import "./LineHeader.css";
import classnames from "classnames";

const LineHeader = ({ children, className, id }) => {
  return (
    <div id={id} className={classnames("ar-line-header", className)}>
      {children}
    </div>
  );
};

export default LineHeader;
