import React from "react";
import KeyedDropdown from "../../../Components/Dropdown/KeyedDropdown";
import {
  TaskIcon,
  TicketIcon,
  DealIcon,
} from "../../../Components/Common/EntityIcons";
import { ProjectStatusProfileWithIcon } from "../../../Components/Projects/ProjectProfile";
import { FormattedMessage } from "react-intl";
import { InboundEmailType } from "./EmailReceptionHelper";

const itemsDict = {
  [InboundEmailType.ticket]: { Icon: <TicketIcon size="sm" />, textId: "CASE" },
  [InboundEmailType.task]: { Icon: <TaskIcon size="sm" />, textId: "TASK" },
  [InboundEmailType.deal]: { Icon: <DealIcon size="sm" />, textId: "DEAL" },
};

export const EmailReceptionTypeItem = ({ item = 0 }) => {
  if (item === InboundEmailType.none) {
    return (
      <div className="d-flex">
        <div className="ml-2">
          <FormattedMessage id={"NONE"} />
        </div>
      </div>
    );
  }

  const dictValue = itemsDict[item];

  if (!dictValue) return null;

  const { Icon, textId } = dictValue;

  return (
    <div className="d-flex">
      <ProjectStatusProfileWithIcon hideName status={1} Icon={Icon} />
      <div className="ml-2">
        <FormattedMessage id={textId} />
      </div>
    </div>
  );
};

const options = [
  0,
  InboundEmailType.ticket,
		//Assis Carlos: Não está implementado e calendarização da mesma. Coloca isso lá para depois a gente saber o motivo de estar comentado.
  // InboundEmailType.task,
  InboundEmailType.deal,
];

const EmailReceptionTypeDropdown = (props) => {
  return (
    <KeyedDropdown
      options={options}
      valueComponent={EmailReceptionTypeItem}
      closeOnSelect
      {...props}
    />
  );
};

export default EmailReceptionTypeDropdown;
