import React from "react";
import ProjectOrigin, {
  ConnectedProjectOrigin,
  ProjectOriginAnchor
} from "./ProjectOrigin";
import CallOrigin, {
  ConnectedCallOrigin,
  CallOriginAnchor
} from "./CallOrigin";
import TicketOrigin, {
  ConnectedTicketOrigin,
  TicketOriginAnchor
} from "./TicketOrigin";
import TaskOrigin, {
  ConnectedTaskOrigin,
  TaskOriginAnchor
} from "./TaskOrigin";
import ClientOrigin, {
  ConnectedClientOrigin,
  ClientOriginAnchor
} from "./ClientOrigin";
import ContractOrigin, {
  ConnectedContractOrigin,
  ContractOriginAnchor
} from "./ContractOrigin";
import InterventionOrigin, {
  ConnectedInterventionOrigin,
  InterventionOriginAnchor
} from "./InterventionOrigin";
import { originTypes } from "../../Helpers/MiscHelper";
import DealOrigin, {
  ConnectedDealOrigin,
  DealOriginAnchor
} from "./DealOrigin";
import { ConnectedSubscriptionOrigin } from "./SubscriptionOrigin";

const OriginOverview = ({ className, entity }) => {
  const {
    Project,
    Call,
    Ticket,
    Client,
    Task,
    Contract,
    Deal,
    Subscription,
    Intervention
  } = entity;

  if (Project)
    return <ConnectedProjectOrigin className={className} project={Project} />;
  else if (Call)
    return <ConnectedCallOrigin className={className} call={Call} />;
  else if (Ticket)
    return <ConnectedTicketOrigin className={className} ticket={Ticket} />;
  else if (Client)
    return <ConnectedClientOrigin className={className} client={Client} />;
  else if (Task)
    return <ConnectedTaskOrigin className={className} task={Task} />;
  else if (Contract)
    return (
      <ConnectedContractOrigin className={className} contract={Contract} />
    );
  else if (Deal)
    return <ConnectedDealOrigin className={className} deal={Deal} />;
  else if (Subscription)
    return (
      <ConnectedSubscriptionOrigin
        className={className}
        subscription={Subscription}
      />
    );
  else if (Intervention)
    return (
      <ConnectedInterventionOrigin
        className={className}
        intervention={Intervention}
      />
    );
  else return <div>―</div>;
};

export const OriginAnchorOverview = ({ entity }) => {
  const { Project, Call, Ticket, Client, Task, Contract } = entity;

  if (Project) return <ProjectOriginAnchor project={Project} />;
  else if (Call) return <CallOriginAnchor call={Call} />;
  else if (Ticket) return <TicketOriginAnchor ticket={Ticket} />;
  else if (Client) return <ClientOriginAnchor client={Client} />;
  else if (Task) return <TaskOriginAnchor task={Task} />;
  else if (Contract) return <ContractOriginAnchor contract={Contract} />;
  else return <div>―</div>;
};

// const InterventionOrigin = ({ intervention }) => {
//   const { Id, Status, Name } = intervention;

//   return (
//     <div>
//       <ProjectStatusProfile hideName project={{ Status }} />
//       <InterventionIcon className={"mr-2"} />
//       <div className="d-flex flex-column lh-normal">
//         <div className="d-flex">
//           <span className="fs-12 mw-120px text-truncate">{Name}</span>
//         </div>
//         <div className="fs-10 text-left">
//           <span className=" mr-2">Nº: {Id}</span>
//         </div>
//       </div>
//     </div>
//   );
// };

export const DirectOriginOverview = ({ origin, disablePopup }) => {
  if (!origin) return null;

  const { Type, Id, Name, NoTagDescription } = origin;

  switch (Type) {
    case "timecharge":
      return (
        <InterventionOrigin
          disablePopup={disablePopup}
          intervention={{ Id, Description: NoTagDescription || Name }}
        />
      );
    case "project":
      return <ProjectOrigin disablePopup={disablePopup} project={origin} />;
    case "ticket":
      return <TicketOrigin disablePopup={disablePopup} ticket={origin} />;
    case "call":
      return <CallOrigin disablePopup={disablePopup} call={origin} />;
    case "client":
      return <ClientOrigin disablePopup={disablePopup} client={origin} />;
    case "task":
      return <TaskOrigin disablePopup={disablePopup} task={origin} />;
    case "contract":
      return <ContractOrigin contract={{ Id, Description: Name }} />;
    case "deal":
      return <DealOrigin disablePopup={disablePopup} deal={origin} />;
    default:
      return null;
  }
};

export const DirectOriginAnchorOverview = ({ origin, disablePopup }) => {
  if (!origin) return null;

  const { Type, Id, Name, NoTagDescription } = origin;

  switch (Type) {
    case "timecharge":
      return (
        <InterventionOriginAnchor
          disablePopup={disablePopup}
          intervention={{ Id, Description: NoTagDescription || Name }}
        />
      );
    case "project":
      return (
        <ProjectOriginAnchor disablePopup={disablePopup} project={origin} />
      );
    case "ticket":
      return <TicketOriginAnchor disablePopup={disablePopup} ticket={origin} />;
    case "call":
      return <CallOriginAnchor disablePopup={disablePopup} call={origin} />;
    case "client":
      return <ClientOriginAnchor disablePopup={disablePopup} client={origin} />;
    case "task":
      return <TaskOriginAnchor disablePopup={disablePopup} task={origin} />;
    case "contract":
      return (
        <ContractOriginAnchor
          disablePopup={disablePopup}
          contract={{ Id, Description: Name }}
        />
      );
    case "deal":
      return <DealOriginAnchor disablePopup={disablePopup} deal={origin} />;
    default:
      return null;
  }
};

export const HistoryOriginOverview = ({ value }) => {
  if (!value) return null;

  const { Type, data } = value;

  if (typeof data !== "object") return <span>invalid origin</span>;

  switch (Type) {
    case originTypes.timecharge:
      return <InterventionOrigin intervention={data} />;
    case originTypes.project:
      return <ProjectOrigin project={data} />;
    case originTypes.ticket:
      return <TicketOrigin ticket={data} />;
    case originTypes.call:
      return <CallOrigin call={data} />;
    case originTypes.client:
      return <ClientOrigin client={data} />;
    case originTypes.task:
      return <TaskOrigin task={data} />;
    case originTypes.contract:
      return <ContractOrigin contract={data} />;
    case originTypes.deals:
      return <DealOrigin deal={data} />;
    default:
      return null;
  }
};

export default OriginOverview;
