import React from "react";

import AccountProfile from "../Accounts/AccountProfile";
import { useSpaceEntity } from "../../Contexts/SpaceContext";


const SpaceProfile = ({ space, ...rest }) => (
  <AccountProfile account={space} {...rest} />
);

export default SpaceProfile;

export const ConnectSpaceEntity = Component => {
  return ({ space, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const o = useSpaceEntity(space);
    return <Component space={o} {...rest} />;
  };
};

export const ConnectedSpaceProfile = ConnectSpaceEntity(
	SpaceProfile
);
