import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../../Components/Button/Button";
import LoadableButton from "../../../Components/Button/LoadableButton";
import Input from "../../../Components/Input/Input";
import { useCurrentAccountSpace } from "../../../Contexts/UserContext";
import { useSpacePost } from "../../../Helpers/IOClient";
import { isEmail } from "../../../Helpers/MiscHelper";
import OnboardingHeader from "../../Headers/OnboardingHeader";
import {
  onboardingStateEnum,
  useSpaceOnboardingStateUpdater
} from "../SpaceOnboardingHelper";
import classnames from "classnames";
import OnboardingContainer from "../../Headers/OnboardingContainer";

function hasDuplicates(array) {
  var valuesSoFar = Object.create(null);
  const resultArr = [];
  for (var i = 0; i < array.length; ++i) {
    var value = array[i];
    if (value in valuesSoFar) {
      resultArr.push(i);
    }
    valuesSoFar[value] = true;
  }
  return resultArr;
}

const SpaceOnboardingInvites = () => {
  const intl = useIntl();
  const [state, setState] = useState([""]);
  // const { obState } = useContext(OnboardingStateContext);
  const onboardingUpdate = useSpaceOnboardingStateUpdater();
  const {
    update
    // retry,
    // loading: loadingPreference
  } = onboardingUpdate;
  const updateState = () => {
    update(onboardingStateEnum.done);
  };

  const [validationState, setValidationState] = useState([false]);

  const [submit, { loading }] = useSpacePost("invite/multi", null, {
    onSuccess: ({ data }) => {
      updateState();
    }
  });

  const addNewInput = () => {
    const validation = validateAddNewInputs();
    const DuplicatesIndexArr = hasDuplicates(state);
    if (validation && DuplicatesIndexArr.length === 0) {
      setState((oldState) => {
        const newState = [...oldState];
        newState.push("");
        return newState;
      });
      setValidationState((oldState) => {
        const newState = [...oldState];
        newState.push(false);
        return newState;
      });
    } else {
      let netValidationState = [];
      for (const element of state) {
        if (isEmail(element)) {
          netValidationState.push(false);
        } else netValidationState.push(true);
      }
      for (const index of DuplicatesIndexArr) {
        netValidationState[index] = true;
      }
      setValidationState(netValidationState);
    }
  };

  const validateInputs = () => {
    return !state.some((e) => e.length > 0 && !isEmail(e));
  };

  const validateAddNewInputs = () => {
    return !state.some((e) => !isEmail(e));
  };

  const AccountSpaceData = useCurrentAccountSpace();
  const { Company } = AccountSpaceData;
  const handleSubmit = () => {
    const validation = validateInputs();
    const DuplicatesIndexArr = hasDuplicates(state);
    if (validation) {
      const resolvedEmails = state.filter((e) => e.length > 0);
      if (resolvedEmails.length === 0) {
        setValidationState((oldState) => {
          let newState = [...oldState];
          newState[0] = true;
          return newState;
        });
      } else {
        const newForm = {
          ...DefaultForm,
          Email: resolvedEmails,
          Organizations: Company ? [Company.Id] : []
        };
        submit(JSON.stringify(newForm));
      }
    } else {
      let netValidationState = [];
      for (const element of state) {
        if (element.length === 0 || isEmail(element)) {
          netValidationState.push(false);
        } else netValidationState.push(true);
      }
      for (const index of DuplicatesIndexArr) {
        netValidationState[index] = true;
      }
      setValidationState(netValidationState);
    }
  };

  return (
    <OnboardingContainer>
      <OnboardingHeader step={4} />
      <div className="mx-4">
        <div className="mx-auto py-4">
          <div style={{ maxWidth: 520 }} className="mx-auto w-100">
            <div className="fs-28 mb-2 text-black">
              <FormattedMessage id="SEND_INVITES" />
            </div>
            <div className="mb-3">
              <div className="d-flex align-items-center">
                <span className="mr-2">
                  <FormattedMessage id="SEND_INVITES_DESC" />
                </span>
              </div>
            </div>
            <div className="">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="text-black d-inline-block fs-14">
                  <FormattedMessage id="SEND_INVITES_FORM_LABEL" />
                </div>
                <Button
                  style={{ height: 28 }}
                  disabled={state.length === 5}
                  vType={"secondary"}
                  className="px-2 py-0"
                  onClick={addNewInput}
                >
                  + {` ` + intl.formatMessage({ id: "ADD_NEW_EMAIL_INPUT" })}
                </Button>
              </div>
              <OnboardInvitesInputs
                validationState={validationState}
                values={state}
                handleChange={setState}
                handleValidationChange={setValidationState}
              />
            </div>
            {state.length === 5 && (
              <div className="fs-14 mt-2 text-purple">
                <span>
                  <FormattedMessage id="ONLY_MAX_FIVE_EMAILS" />
                </span>
                <span className="fw-bold">
                  <FormattedMessage id="ONLY_MAX_FIVE_EMAILS_PART_TWO" />
                </span>
              </div>
            )}
            <div className="d-flex align-items-center mt-3 justify-content-between">
              <Button
                className="mr-3 w-50"
                vType="secondary-ghost"
                onClick={updateState}
              >
                <FormattedMessage id="ADVANCE_TO_AIRDESK" />
              </Button>
              <LoadableButton
                className="w-50"
                vType="secondary"
                onClick={handleSubmit}
                isLoading={loading}
              >
                <FormattedMessage id="SEND_INVITES" />
              </LoadableButton>
            </div>
          </div>
        </div>
      </div>
    </OnboardingContainer>
  );
};

const OnboardInvitesInputs = ({
  values,
  handleChange,
  validationState,
  handleValidationChange
}) => {
  const handleInputChange = useCallback(
    (e) => {
      const { value, name } = e.target;
      const newName = name.replace("Invite-", "");
      const index = parseInt(newName);
      handleChange((oldState) => {
        const newState = [...oldState];
        newState[index] = value;
        return newState;
      });
      handleValidationChange((oldState) => {
        const newState = [...oldState];
        newState[index] = false;
        return newState;
      });
    },
    [handleChange, handleValidationChange]
  );

  return values.map((e, i) => {
    return (
      <InviteInput
        key={`Invite-${i}`}
        validationState={validationState[i]}
        value={e}
        index={i}
        onChange={handleInputChange}
      />
    );
  });
};

const InviteInput = React.memo(
  ({ value, onChange, index, validationState }) => {
    return (
      <div className="mt-2">
        <Input
          error={validationState}
          placeholder="example@domain.com"
          className={classnames("bg-white", { error: validationState })}
          value={value}
          name={`Invite-${index}`}
          onChange={onChange}
        />
        {validationState && (
          <div className="text-danger fs-14 mt-1">
            <FormattedMessage id="REGISTER_INVALID_EMAIL_OR_DUPLICATED" />
          </div>
        )}
      </div>
    );
  }
);

const DefaultForm = {
  Name: "",
  Organizations: [],
  Teams: [],
  RoleName: "",
  Departments: [],
  PhoneNumber: "",
  CollaboratorNumber: "",
  PhoneExtension: "",
  blocked: false,
  Gender: null,
  Modules: {
    calls: 352,
    tickets: 352,
    projects: 288,
    tasks: 352,
    interventions: 352,
    billing: 0,
    clients: 0,
    contacts: 0,
    contracts: 0,
    helpdesk: 0,
    configurations: 0,
    organizations: 0,
    departments: 0,
    teams: 0,
    users: 0,
    settingsUsers: 0,
    settingsTeams: 0,
    settingsDepartments: 0,
    settingsOrganizations: 0,
    classification: 0,
    settingsCalls: 0,
    settingsTickets: 0,
    settingsProjects: 0,
    settingsTasks: 0,
    settingsContracts: 0,
    settingsDeals: 0,
    deals: 0,
    settingsInterventions: 0,
    subscriptions: 0,
    reportsInterventions: 0,
    reportsTickets: 0,
    reportsTasks: 0,
    reportsProjects: 0,
    reportsDeals: 0,
    reportsContracts: 0,
    reportsClassification: 0,
    settingsSubscriptions: 0,
    settingsSocial: 0,
    socialPost: 0,
    social: 0,
    settingsClient: 0,
    imports: 0,
    settingsSpace: 0,
    settingsWidget: 0,
    emailConversations: 0,
    settingsInboundEmails: 0,
    settingsDomains: 0,
    emailSignature: 0,
    apiKeySettings: 0
  },
  Role: 0,
  Permissions: 1
};

export default SpaceOnboardingInvites;
