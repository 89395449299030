import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSuitcase,
  faHandshake,
  faTicketSimple,
  faPhone,
  faListCheck,
  faFileContract,
  faCalendarExclamation,
  faUserTie,
  faClipboardCheck,
  faUserClock,
  faStopwatch,
  faBell,
  faEnvelope,
  faFileInvoice,
  faEdit,
  faUserAlt,
  faSignature,
	faFileWord,
	faCircleParking,
} from "@fortawesome/pro-solid-svg-icons";

export const ProjectIcon = (props) => {
  return <FontAwesomeIcon icon={faCircleParking} {...props} />;
};
export const ClientIcon = (props) => {
  return <FontAwesomeIcon icon={faSuitcase} {...props} />;
};
export const DealIcon = (props) => {
  return <FontAwesomeIcon icon={faHandshake} {...props} />;
};
export const TicketIcon = (props) => {
  return <FontAwesomeIcon icon={faTicketSimple} {...props} />;
};
export const CallIcon = (props) => {
  return <FontAwesomeIcon icon={faPhone} {...props} />;
};
export const TaskIcon = (props) => {
  return <FontAwesomeIcon icon={faListCheck} {...props} />;
};
export const ContractIcon = (props) => {
  return <FontAwesomeIcon icon={faFileContract} {...props} />;
};
export const SubscriptionIcon = (props) => {
  return <FontAwesomeIcon icon={faCalendarExclamation} {...props} />;
};
export const ContactIcon = (props) => {
  return <FontAwesomeIcon icon={faUserTie} {...props} />;
};
export const ClassificationIcon = (props) => {
  return <FontAwesomeIcon icon={faClipboardCheck} {...props} />;
};
export const InterventionIcon = (props) => {
  return <FontAwesomeIcon icon={faUserClock} {...props} />;
};
export const TemporizadorIcon = (props) => {
  return <FontAwesomeIcon icon={faStopwatch} {...props} />;
};
export const NotificationIcon = (props) => {
  return <FontAwesomeIcon icon={faBell} {...props} />;
};
export const EmailIcon = (props) => {
  return <FontAwesomeIcon icon={faEnvelope} {...props} />;
};
export const BillingIcon = (props) => {
  return <FontAwesomeIcon icon={faFileInvoice} {...props} />;
};
export const NoteIcon = (props) => {
  return <FontAwesomeIcon icon={faEdit} {...props} />;
};
export const UserIcon = (props) => {
  return <FontAwesomeIcon icon={faUserAlt} {...props} />;
};
export const DocIcon = (props) => {
  return <FontAwesomeIcon icon={faFileWord} {...props} />;
};

export const EmailSignatureIcon = (props) => {
  return <FontAwesomeIcon icon={faSignature} {...props} />;
};
