import * as AuthActions from "../../Actions/AuthActions";

//set initial auth state by verifying if token exists
const initialAuthState = {
  isProcessing: false,
  error: null
};

function loginPageReducer(state = initialAuthState, action) {
  switch (action.type) {
    case AuthActions.LOGIN_REQUEST:
      return {
        ...state,
        isProcessing: true
      };

    case AuthActions.LOGIN_SUCCESS:
      return {
        ...state,
        isProcessing: false
      };

    case AuthActions.LOGIN_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: action.error
      };

    case AuthActions.ASSIGN_SIGNAL_R:
      return {
        ...state,
        SignalRToken: action.signalRToken
      };

    case AuthActions.REMOVE_LOGIN_ERROR:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
}

export default loginPageReducer;
