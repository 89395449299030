import React from "react";

import { Route, Switch, withRouter } from "react-router-dom";
import {
  useEnterViewSignalR,
  PageTypeProvider
} from "../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimePageEnum } from "../RealTime/RealTimePages";
import ClientDetails from "./ClientDetails/ClientDetails";
// import { RealTimePageProvider, RealTimePageEnum } from "../RealTime/RealTimePages";
import ClientOverview from "./ClientOverview/ClientOverview";

const Clients = withRouter(({ match }) => {
  // useEnterViewSignalR(RealTimePageEnum.Client)

  return (
    <PageTypeProvider pageType={RealTimePageEnum.Client}>
      <Switch>
        <Route path={`${match.url}/:clientId`}>
          <ClientDetails />
        </Route>
        <Route>
          <ClientOverview />
          {/* <RealTimePageProvider pageType={RealTimePageEnum.Client}> */}
          {/* </RealTimePageProvider> */}
        </Route>
      </Switch>
    </PageTypeProvider>
  );
});

export default Clients;
