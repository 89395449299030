// import React, { useRef, useEffect, useCallback } from "react";
// import classnames from "classnames";
import {
  darkred,
  red,
  lightred,
  darkgrey,
  //   grey,
  darkgreen,
  //   green,
  //   monthNames,
  createChartComponent,
  //   getMonth,
  // darkblue,
  green,
  // purple,
  darkPurple,
  lightPurple
} from "./echartDefaults";
import { DealStatusTypes } from "../../Deals/DealsHelper";
import { largeNumberFormatter } from "../../../Helpers/MiscHelper";
// import { ConnectedClientProfile } from "../../../Components/Clients/ClientProfile";
// import { ConnectedClientOrigin } from "../../Origin/ClientOrigin";
// import { useClient, useCurrency } from "../../../Hooks/EntityHooks";
// import { getEntity } from "../../../Helpers/IOClient";
// import { spaceClientSchema, accountSchema } from "../../../config/schema";
// import { useSpace } from "../../../Contexts/SpaceContext";
// include bar chart
require("echarts/lib/echarts.js");
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
// include  title component

// include ECharts main module

// include bar chart

require("echarts/lib/chart/line");
// include  title component
require("echarts/lib/component/tooltip");

export const MultiBarChartDeals = createChartComponent((Data, intl) => {
  const Clients = [],
    Manager = [],
    Open = [],
    Lost = [],
    Won = [],
    EstimatedCost = [];

  for (const e of Data) {
    e.DealManager && Manager.push(e.DealManager);
    e.Client && Clients.push(e.Client);
    Open.push(e.Data[DealStatusTypes.open]);
    Lost.push(e.Data[DealStatusTypes.lost]);
    Won.push(e.Data[DealStatusTypes.won]);
    EstimatedCost.push(e.EstimatedCost);
  }
  // const formatter = new Intl.NumberFormat("de-DE", {
  //   style: "decimal",
  //   minimumFractionDigits: 2
  // });
  const AllValues = [...Open, ...Lost, ...Won];
  const MaxValue =
    AllValues.length > 0
      ? AllValues.reduce((a, b) => {
          return Math.max(a, b);
        })
      : 0;
  const MinValue =
    AllValues.length > 0
      ? AllValues.reduce((a, b) => {
          return Math.min(a, b);
        })
      : 0;

  const enableTooltips = MaxValue - MinValue > 10;

  return {
    tooltip: {
      show: true
    },
    legend: {
      left: "left",
      selectedMode: false,
      borderRadius: 3,
      icon: "roundRect",
      textStyle: {
        color: darkgrey,
        backgroundColor: lightred
      },
      data: [
        {
          name: intl.formatMessage({ id: "LOSES" }),
          textStyle: {
            color: darkred,
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 3
          }
        },
        {
          name: intl.formatMessage({ id: "OPEN" }),
          textStyle: {
            color: darkPurple,
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 3
          }
        },
        {
          name: intl.formatMessage({ id: "SALES" }),
          textStyle: {
            color: darkgreen,
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 3
          }
        }
      ]
    },
    grid: {
      left: "3%",
      right: "2%",
      bottom: "0%",
      containLabel: true
    },
    xAxis: {
      type: "value",
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      splitLine: {
        show: false
      }
    },
    yAxis: [
      {
        type: "category",
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        data:
          Clients.length === 0
            ? Manager.map((e) => {
                return e?.Name;
              }).reverse()
            : Clients.map((e) => {
                return e?.Name;
              }).reverse()
      },
      {
        name: intl.formatMessage({ id: "SALES" }),
        type: "category",
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        nameTextStyle: {
          padding: [0, -50, 0, 0],
          color: "#002b63"
        },

        data: EstimatedCost.map((e) => {
          return {
            value: `€ ${largeNumberFormatter(e)}`,
            textStyle: {
              color: "#002b63",
              fontWeight: "600",
              align: "left"
            }
          };
        }).reverse()
      }
    ],
    series: [
      {
        name: intl.formatMessage({ id: "LOSES" }),
        type: "bar",
        stack: "stack",

        itemStyle: {
          color: red
        },
        data: Lost.map((e) => {
          return {
            value: e,
            label: {
              show: e > (MaxValue - MinValue) / 9,
              position: "insideRight",
              fontWeight: "bold",
              color: darkred
            }
          };
        }).reverse(),
        tooltip: {
          show: enableTooltips,
          axisPointer: {
            animation: false
          },
          formatter: "{a}: {c}",
          renderMode: "richText",
          trigger: "item",
          textStyle: {
            color: "#FFFFFF"
            // fontWeight: "500"
          },
          backgroundColor: "#002B63"
        }
      },
      {
        name: intl.formatMessage({ id: "OPEN" }),
        type: "bar",
        stack: "stack",
        itemStyle: {
          color: lightPurple
        },

        data: Open.map((e) => {
          return {
            value: e,
            label: {
              show: e > (MaxValue - MinValue) / 9,
              position: "insideRight",
              fontWeight: "bold",
              color: darkPurple
            }
          };
        }).reverse(),
        tooltip: {
          show: enableTooltips,
          axisPointer: {
            animation: false
          },
          formatter: "{a}: {c}",
          renderMode: "richText",
          trigger: "item",
          textStyle: {
            color: "#FFFFFF"
            // fontWeight: "500"
          },
          backgroundColor: "#002B63"
        }
      },
      {
        name: intl.formatMessage({ id: "SALES" }),
        type: "bar",
        stack: "stack",

        itemStyle: {
          color: green
        },
        data: Won.map((e) => {
          return {
            value: e,
            label: {
              show: e > (MaxValue - MinValue) / 9,
              position: "insideRight",
              fontWeight: "bold",
              color: darkgreen
            }
          };
        }).reverse(),
        tooltip: {
          show: enableTooltips,
          axisPointer: {
            animation: false
          },
          formatter: "{a}: {c}",
          renderMode: "richText",
          trigger: "item",
          textStyle: {
            color: "#FFFFFF"
            // fontWeight: "500"
          },
          backgroundColor: "#002B63"
        }
      }
    ]
  };
});
