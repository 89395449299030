import React, { useState } from "react";
// import SplitCol from "../../../Components/SplitCol/SplitCol";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import withSpaceEntityForm from "../../../Components/Forms";
import { withSpace } from "../../../Contexts/SpaceContext";
// import FileUploader from "../../../Components/FileUploader/FileUploader";
import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import { spaceSubscriptionTypeSchema } from "../../../config/schema";
import SideMenuForm from "../../../Components/Forms/SideMenuForm";
import { createFormData } from "../../../Helpers/MiscHelper";
import { OrganizationDropdown } from "../../AdvancedMultiInputs";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpaceDelete
} from "../../../Components/Sidebar/SidebarV2Helper";
import LoadableButton from "../../../Components/Button/LoadableButton";
import Button from "../../../Components/Button/Button";
import { injectToast, useToast } from "../../../Components/Toast/ToastProvider";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";

const RemovalFooter = ({ endpoint, refetchRef, onCancel, Name }) => {
  const createToast = useToast();
  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceSubscriptionTypeSchema);
  const [deleteUser, { loading }] = useSidebarSpaceDelete(
    endpoint,
    spaceSubscriptionTypeSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "SUBSCRIPTION_TYPE" })} ${Name}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  return (
    <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
      <div className="text-black">
        <FormattedMessage id="DELETE_SUBSCRIPTION_TYPE" />
      </div>

      <div className="d-flex align-items-center">
        <Button
          type="button"
          vType="outline-danger"
          className="mr-3"
          onClick={onCancel}
        >
          <FormattedMessage id="BACK" />
        </Button>

        <LoadableButton
          type="button"
          vType="danger"
          isLoading={loading}
          onClick={() => {
            deleteUser();
          }}
        >
          <FormattedMessage id="DELETE" />
        </LoadableButton>
      </div>
    </div>
  );
};

const SubscriptionTypeForm = ({ ...props }) => {
  const { form } = props;
  const { Id, CanDelete, Name } = form || {};
  const [isDeleting, setIsDeleting] = useState(false);
  // const handleChange = (e) => {
  //   const { setFormState } = props;
  //   const { name, value } = e.target;
  //   setFormState({
  //     [name]: value,
  //   });
  // };

  const { formStatus, isChild } = props;

  // let leftpercent = 60;

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id="SUBSCRIPTION_TYPE" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideMenuForm
          footerComponent={
            !Id ? (
              <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
                <div></div>
                <LoadableButton
                  type="button"
                  isLoading={formStatus.isProcessing}
                  onClick={formStatus.submit}
                >
                  <FormattedMessage id={"CONFIRM"} />
                </LoadableButton>
              </div>
            ) : !isDeleting ? (
              <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
                {CanDelete ? (
                  <Button
                    type="button"
                    vType="link-danger fw-bold"
                    className="mr-3"
                    onClick={() => setIsDeleting(true)}
                  >
                    <span style={{ fontWeight: 500 }}>
                      <FormattedMessage id="DELETE" />
                    </span>
                  </Button>
                ) : (
                  <div></div>
                )}
                <LoadableButton
                  type="button"
                  isLoading={formStatus.isProcessing}
                  onClick={formStatus.submit}
                >
                  <FormattedMessage id={"CONFIRM"} />
                </LoadableButton>
              </div>
            ) : (
              <RemovalFooter
                Name={Name}
                endpoint={`subscriptionTypes/${Id}`}
                onSuccess={() => window.location.reload(true)}
                onCancel={() => setIsDeleting(false)}
              />
            )
          }
          formStatus={formStatus}
        >
          <FormInput
            disabled={isChild}
            name="Organizations"
            textId="COMPANIES"
            className="mb-3"
            inputType={OrganizationDropdown}
            closeOnSelect={false}
            multiple
          />
          <FormInput name="Type" textId="CODE" className="mb-3" />
          <FormInput name="Name" type="text" textId="NAME" className="mb-3" />
          {/* 
          <FormInput
            textId="FILES"
            name="Files"
            inputType={FileUploader}
            extendedMode
          /> */}
        </SideMenuForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

// const useHandleSuccess = () => {
// 	const intl = useIntl();
// 	const createToast = useToast();
// 	return (props) => {
// 		const {form} = props;
// 		const {Name} = form;
// 		createToast({
// 			pos: "tm",
// 			type: "success",
// 			title: `${intl.formatMessage({ id: "SUBSCRIPTION_TYPE" })} ${Name}`,
// 			description: `${intl.formatMessage({
// 				id: "SUCCESS_SAVED"
// 			})}`
// 		})
// 	}
// }
// const handleToast = useHandleSuccess();

export default withSpaceEntityForm(
  withSpace(injectToast(injectIntl(SubscriptionTypeForm))),
  {
    defaultForm: {
      Type: "",
      Organizations: [],
      // ValueToPay: "",
      // Deslocations: "",
      Name: "",
      Schedules: [],
      Files: []
    },
    validateForm: ({ Name, Type }, addError) => {
      // const { Name, Email, PhoneNumber } = form;
      // let isNameValid = Name.length > 0;
      // let isPhoneNumberValid = isValidPhoneNumber(PhoneNumber.value);
      // let isEmailValid = isEmail(Email);
      if (!Type) addError("Type");
      if (!Name) addError("Name");
      // if (!isPhoneNumberValid) addError("PhoneNumberError");
      // if (!isEmailValid) addError("EmailError");
    },
    onSuccess: (props) => {
      const { createToast, intl, form } = props;
      const { Name } = form;
      createToast({
        pos: "tm",
        type: "success",
        title: `${intl.formatMessage({ id: "SUBSCRIPTION_TYPE" })} ${Name}`,
        description: `${intl.formatMessage({
          id: "SUCCESS_SAVED"
        })}`
      });
      // handleToast(props)
    },
    schema: spaceSubscriptionTypeSchema,
    // getEntityId: (state, ownProps) => ownProps.contractTypeId,
    formatForm: (form) => {
      const { Schedules, Files, ...restForm } = form;

      return createFormData(
        { ...restForm, Schedules: JSON.stringify(Schedules) },
        Files
      );
    }
  }
);
