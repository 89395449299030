import React from "react";
import { connect } from "react-redux";
import AccountDropdownInput from "../Accounts/AccountDropdownInput";

const OrganizationDropdownInput = ({ organization, ...rest }) => {
  return(
  <AccountDropdownInput account={organization} {...rest} />
);
}

export default OrganizationDropdownInput;

const mapStateToProps = (state, {organization}) => {
  if(!organization) return {};

  return {
    organization: state?.Entities?.SpaceOrganizations && state.Entities.SpaceOrganizations[organization]
  };
};

export const ConnectedOrganizationDropdownInput = connect(mapStateToProps)(
  OrganizationDropdownInput
);
