// import React, { useRef, useEffect } from "react";
// import classnames from "classnames";
import {
  //   darkred,
  //   red,
  // lightred,
  // darkgrey,
  //   grey,
  darkgreen,
  //   green,
  //   monthNames,
  createChartComponent
  //   getMonth
} from "./echartDefaults";
// include ECharts main module
// include bar chart
require("echarts/lib/chart/bar");
// include tooltip and title component
require("echarts/lib/component/title");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
export const ReverseBarChart = createChartComponent((Data, intl) => {
  const yLabels = [];

  for (const entry of Data) {
    yLabels.push(entry.Days);
  }
  const resolvedYLabels = yLabels => {
    const slicedLabels = yLabels.slice(0, 6);
    const resolvedSlicedLabels = slicedLabels.map(e => {
      return `${e} ${intl.formatMessage({ id: "DAY" })}(s)`;
    });
    if (Boolean(yLabels[6])) {
      resolvedSlicedLabels.push(
        `+${yLabels[6]} ${intl.formatMessage({ id: "DAY" })}(s)`
      );
    }
    return resolvedSlicedLabels;
  };

  const mappedArr = [];

  for (const obj of Data) {
    const v = parseInt(obj.Count);
    mappedArr.push(v);
  }

  let overSum = 0;
  for (const count of mappedArr.slice(6)) {
    overSum += count;
  }
  let resolvedMappedArr = [];
  for (let i = 0; i < 6; i++) {
    resolvedMappedArr.push(mappedArr[i]);
  }
  if (Data.length > 5) {
    resolvedMappedArr.push(overSum);
  }
  resolvedMappedArr = resolvedMappedArr.filter(element => {
    return element !== undefined;
  });
  //   var maxNumGraph = Math.max(...resolvedMappedArr);

  //   var restGraph = resolvedMappedArr.map(e => {
  //     return parseInt(maxNumGraph) + 7 - parseInt(e);
  //   });

  return {
    // legend: {
    //   left: "left",
    //   top: 10,
    //   selectedMode: false,
    //   borderRadius: 3,
    //   icon: "roundRect",
    //   textStyle: {
    //     color: darkgrey,
    //     backgroundColor: lightred
    //   },

    //   data: [
    //     {
    //       name: intl.formatMessage({ id: "OPEN" }),
    //       textStyle: {
    //         color: darkgreen,
    //         backgroundColor: "#fff",
    //         borderRadius: 3
    //       }
    //     }
    //   ]
    // },
    tooltip: {
      show: false,
      trigger: "item",
			textStyle: {
				color: "#FFFFFF",
        fontWight: "bolder",
      },
      axisPointer: {
        type: "shadow"
      }
    },
    grid: {
      //   show: true,
      //   right: "6px",
      //   bottom: "5%",
      //   top: "7%",
      containLabel: true
    },
    xAxis: {
      type: "value",
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: "category",
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },

      data: resolvedYLabels(yLabels).reverse()
    },
    series: [
      {
        name: "Background",
        type: "bar",
        stack: "stack",
        itemStyle: {
          color: "#85E7C9",
          borderRadius: [0, 0, 0, 0]
        },
        data: resolvedMappedArr
          .map(e => {
            return {
              value: e,
              label: {
            
                  show: e > 0,
                  position: "right",
                  fontWeight: "bold",
                  color: darkgreen
               
              }
            };
          })
          .reverse()
      }
      //   {
      //     name: "",
      //     type: "bar",
      //     stack: "stack",
      //     itemStyle: {
      //       color: "#f2f2f2",
      //       borderRadius: [0, 0, 0, 0],
      //       emphasis: {
      //         color: "#f2f2f2 "
      //       }
      //     },
      //     data: restGraph.reverse()
      //   }
    ]
  };
});
