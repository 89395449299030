import React from "react";
import {
  TimelineTypeEnum
} from "../../../Components/DetailsView/History/History";
import { DashboardTitle } from "../Dashboard";
import { FormattedMessage } from "react-intl";
import { useDashboardKey } from "../DashboardQueryHelper";
import { ReportHistory } from "../../../Components/DetailsView/History/ReportHistory";

const TicketsHistory = () => {
  const key = useDashboardKey();
  return (
    <>
      <DashboardTitle
        className="mb-0 px-4 fs-20"
        text={<FormattedMessage id={"TICKET_HISTORY"} />}
      />
      <ReportHistory
        type={TimelineTypeEnum.Ticket}
        reportsType={"Tickets"}
        key={key}
        noFilter
        onMouseDown={(e) => e.stopPropagation()}
        disableTabs
        tickets
      />
    </>
  );
};

export default TicketsHistory;
