import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDetailsEntityId } from "../../../Components/DetailsView/DetailsView";
import { useTicket } from "../../../Hooks/EntityHooks";
import {
  DetailsEntities,
  DetailsClientEntity,
  DetailsContactEntity,
  DetailsTaskQueryableEntities,
  DetailsInterventionQueryableEntities
} from "../../../Components/DetailsView/DetailsChildren/DetailsChildren";
import { ResponseSchema } from "../../Reception/ReceptionComp";
import { useSpaceQuery } from "../../../Helpers/IOClient";
import { useConnectionId } from "../../RealTime/RealTime";
import { useEndlessScroll } from "../../../Components/EndlessScrollPage/EndlessScrollPage";
import DetailsEmailList from "../../../Components/DetailsEmailList/DetailsEmailList";
import { FormattedMessage } from "react-intl";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";

export const TicketDetailsChildren = () => {
  const entityId = useDetailsEntityId();
  const formProps = useMemo(() => {
    return { ticketId: entityId };
  }, [entityId]);

  const { Client, IndirectClient, Contact } = useTicket(entityId) || {};

  return (
    <DetailsEntities formProps={formProps}>
      <DetailsClientEntity id={Client || IndirectClient} />
      <DetailsContactEntity id={Contact} />

      <DetailsTaskQueryableEntities
        filter={`(Task/TicketId eq ${entityId}) and (PipelineStatus/Status eq '1')`}
      />
      <DetailsInterventionQueryableEntities
        filter={`Timecharge/TicketId eq ${entityId})`}
      />
    </DetailsEntities>
  );
};

const TopValueSkip = 20;

export const TicketDetailsEmailConversations = ({ id }) => {
  const [skip, setSkip] = useState(0);

  const resolvedUrl = useMemo(() => {
    return `tickets/${id}/conversations?$top=20&$skip=${skip}&$inlinecount=allpages`;
  }, [id, skip]);

  const [, setCount] = useState(0);
  const [response, setResponse] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const conId = useConnectionId();
  const pageRef = useRef();
  const { loading, error } = useSpaceQuery(resolvedUrl, ResponseSchema, {
    onSuccess: ({ data }) => {
      if (data.d.results.length < TopValueSkip) setHasMore(false);
      setCount(data.d.__count);
      setResponse((r) => [...r, ...data.d.results]);
    },
    onError: () => {
      setCount(0);
      setResponse([]);
    },
    cache: false,
    headers: {
      ConnectionID: conId
    }
  });

  const fetchMore = useCallback(() => {
    setSkip((s) => s + TopValueSkip);
  }, []);

  useEndlessScroll({
    listen: hasMore && !loading && !error,
    onFetchMore: fetchMore,
    offsettMultiplier: 0.2,
    pageRef
  });

  if (loading) {
    return (
      <div ref={pageRef} className="d-flex flex-column h-100 of-y-auto">
        <div className="h-100 d-flex text-primary align-items-center justify-content-center">
          <LoaderSpinner size="sm" center />
        </div>
      </div>
    );
  }

  if (!loading && response.length === 0) {
    return (
      <div ref={pageRef} className="d-flex flex-column h-100 of-y-auto">
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="detailsEmailList-noItemsIcon text-black fs-16">
            <FormattedMessage id={"EMPTY_INBOX_RECEPTION"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div ref={pageRef} className="d-flex flex-column h-100 of-y-auto">
      <DetailsEmailList Messages={response} />
    </div>
  );
};
