import React from "react";
import Button from "../Button/Button";
import { HourToMilSec, MinuteToMilSec } from "../../Helpers/MiscHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import Dropdown from "../Dropdown/Dropdown";
import "./TimeTablePicker.css";
import Checkbox from "../Checkbox/Checkbox";
import DatePicker from "../DatePicker/DatePicker";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

//value={begin:time, end:time, Days:tags???}
const TimeTableComponent = ({
  value,
  StartTimeChanges,
  EndTimeChanges,
  DaysChange,
  twentyFourCheckboxChanges,
  onClose,
	index,
	error,
  preview,
  onboarding
}) => {
  const StartTime = (
    <DatePicker
      preview={preview}
      key={index + "StartTime"}
      enableDate={false}
      value={value.StartTime || 0}
      onChange={(e) => StartTimeChanges(index, e)}
    />
  );
  const EndTime = (
    <DatePicker
      preview={preview}
      key={index + "EndTime"}
      enableDate={false}
      value={value.EndTime || 0}
      onChange={(e) => EndTimeChanges(index, e)}
    />
  );

  const DaysDict = {
    0: "Domingo",
    1: "Segunda",
    2: "Terça",
    3: "Quarta",
    4: "Quinta",
    5: "Sexta",
    6: "Sabado",
    7: "Feriado"
  };

  const DaysValueArr = value.Days
    ? value.Days.map((e) => {
        return DaysDict[e];
      })
    : [];

  const DaysArray = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sabado",
    "Feriado"
  ];

  const DaysTranslationDict = {
    Domingo: <FormattedMessage id={"SUNDAY"} />,
    Segunda: <FormattedMessage id={"MONDAY"} />,
    Terça: <FormattedMessage id={"TUESDAY"} />,
    Quarta: <FormattedMessage id={"WEDNESDAY"} />,
    Quinta: <FormattedMessage id={"THURSDAY"} />,
    Sexta: <FormattedMessage id={"FRIDAY"} />,
    Sabado: <FormattedMessage id={"SATURDAY"} />,
    Feriado: <FormattedMessage id={"HOLIDAY"} />
  };

  const TranslatedDay = ({ item }) => {
    return DaysTranslationDict[item];
  };

  const DayTypes = (
    <Dropdown
			className={classnames({"error": error})}
      preview={preview}
      items={DaysArray}
      onChange={(e) => DaysChange(index, e)}
      removable
      multiple
      listItemComponent={TranslatedDay}
      valueComponent={TranslatedDay}
      value={DaysValueArr}
    />
  );

  return (
    <div
      style={{ borderRadius: 5 }}
      className={classnames("d-flex align-items-center mt-1 border of-y-auto", {
        "bg-white": onboarding
      })}
    >
      <div className="flex-1">
        <div className="d-flex align-items-center px-3 pt-2">
          <div style={{ width: 31 }} className="mr-3 pr-1 text-black">
            <FormattedMessage id={"FROM"} />
          </div>
          <span className="ScheduleTimePicker">{StartTime}</span>
          <span className="mx-2 text-black">
            <FormattedMessage id={"TO"} />
          </span>
          <span className="ScheduleTimePicker">{EndTime}</span>
          {!preview && (
            <div className="d-flex align-items-center mr-3">
              <span className="mx-2 text-black">24h</span>
              <div style={{ width: 24 }} className="mr-2">
                <Checkbox
                  onChange={(e) => twentyFourCheckboxChanges(index, e)}
                />
              </div>
            </div>
          )}
          {!preview && (
            <div className="d-flex w-100 justify-content-end">
              <Button
                type="button"
                className="p-0"
                propStyle={{ minWidth: 40, width: 40 }}
                onClick={() => {
                  onClose(index);
                }}
                vType="outline-danger"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center px-3 py-2">
          <span className="mr-3 text-black">
            <FormattedMessage id={"DAYS"} />
          </span>
          {DayTypes}
        </div>
      </div>
    </div>
  );
};

const TimeTablePicker = ({
  value = [{}],
  onChange,
  className,
  preview,
  onboarding,
  error
}) => {
  const DaysDict = {
    Domingo: 0,
    Segunda: 1,
    Terça: 2,
    Quarta: 3,
    Quinta: 4,
    Sexta: 5,
    Sabado: 6,
    Feriado: 7
  };

  const closeContainer = (index) => {
    let tempArr = [...value];
    tempArr.splice(index, 1);
    onChange(tempArr);
  };

  const StartTimeChanges = (index, e) => {
    let tempArr = [...value];
    tempArr[index].StartTime = HourToMilSec(e[0]) + MinuteToMilSec(e[1]);
    onChange(tempArr);
  };

  const EndTimeChanges = (index, e) => {
    let tempArr = [...value];
    tempArr[index].EndTime = HourToMilSec(e[0]) + MinuteToMilSec(e[1]);
    onChange(tempArr);
  };

  const DaysChange = (index, e) => {
    let tempArr = [...value];
    tempArr[index].Days = e.map((e) => {
      return DaysDict[e];
    });
    onChange(tempArr);
  };

  const twentyFourCheckboxChanges = (index, e) => {
    let tempArr = [...value];
    if (e.target.checked) {
      tempArr[index].StartTime = 0;
      tempArr[index].EndTime = 86399000;
    }
    onChange(tempArr);
  };

  // const onChanges = (index, label, e) => {
  // 	let tempArr = [...TimeContainers];
  // 	const value = e

  // 	switch(label){
  // 		case "StartTime":
  // 			tempArr[index].StartTime = value.HourTimestamp;
  // 			break;
  // 		case "closeTime":
  // 			tempArr[index].EndTime = value.HourTimestamp;
  // 			break;
  // 		case "24hours":
  // 			if(value.target.checked){
  // 				tempArr[index].StartTime = 0;
  // 				tempArr[index].EndTime = 86399000;
  // 			};
  // 			break;
  // 		case "Days":
  // 			tempArr[index].Days = value.map((e)=>{
  // 				return DaysDict[e];
  // 			});
  // 			break;
  // 		default:
  // 			console.log('i was defaulted')
  // 	}
  // 	onChange(tempArr);
  // }

  return (
    <>
      <div className="mb-3">
        {value.map((e, index) => {
          return (
            <TimeTableComponent
              onboarding={onboarding}
              preview={preview}
              key={index}
              error={Boolean(error) && e?.Days?.length === 0}
              value={e}
              index={index}
              StartTimeChanges={StartTimeChanges}
              EndTimeChanges={EndTimeChanges}
              DaysChange={DaysChange}
              twentyFourCheckboxChanges={twentyFourCheckboxChanges}
              onClose={closeContainer}
            />
          );
        })}
      </div>
      {!preview && (
        <Button
          onClick={() => {
            onChange([...value, { StartTime: 0, EndTime: 0, Days: [] }]);
          }}
          type="button"
          className="ssi-input ssi-button-primary-ghost"
        >
          <span>
            <FontAwesomeIcon icon={faPlus} />{" "}
            <FormattedMessage id={"ADD_NEW_PERIOD"} />
          </span>
        </Button>
      )}
    </>
  );
};

export default TimeTablePicker;
