
export const iconList: string[] = [
  "fas address-book",
  "fas address-card",
  "fas adjust",
  "fas alarm-clock",
  "fas align-center",
  "fas align-justify",
  "fas align-left",
  "fas align-right",
  "fas allergies",
  "fas ambulance",
  "fas american-sign-language-interpreting",
  "fas anchor",
  "fas angle-double-down",
  "fas angle-double-left",
  "fas angle-double-right",
  "fas angle-double-up",
  "fas angle-down",
  "fas angle-left",
  "fas angle-right",
  "fas angle-up",
  "fas archive",
  "fas arrow-alt-circle-down",
  "fas arrow-alt-circle-left",
  "fas arrow-alt-circle-right",
  "fas arrow-alt-circle-up",
  "fas arrow-alt-down",
  "fas arrow-alt-from-bottom",
  "fas arrow-alt-from-left",
  "fas arrow-alt-from-right",
  "fas arrow-alt-from-top",
  "fas arrow-alt-left",
  "fas arrow-alt-right",
  "fas arrow-alt-square-down",
  "fas arrow-alt-square-left",
  "fas arrow-alt-square-right",
  "fas arrow-alt-square-up",
  "fas arrow-alt-to-bottom",
  "fas arrow-alt-to-left",
  "fas arrow-alt-to-right",
  "fas arrow-alt-to-top",
  "fas arrow-alt-up",
  "fas arrow-circle-down",
  "fas arrow-circle-left",
  "fas arrow-circle-right",
  "fas arrow-circle-up",
  "fas arrow-down",
  "fas arrow-from-bottom",
  "fas arrow-from-left",
  "fas arrow-from-right",
  "fas arrow-from-top",
  "fas arrow-left",
  "fas arrow-right",
  "fas arrow-square-down",
  "fas arrow-square-left",
  "fas arrow-square-right",
  "fas arrow-square-up",
  "fas arrow-to-bottom",
  "fas arrow-to-left",
  "fas arrow-to-right",
  "fas arrow-to-top",
  "fas arrow-up",
  "fas arrows",
  "fas arrows-alt",
  "fas arrows-alt-h",
  "fas arrows-alt-v",
  "fas arrows-h",
  "fas arrows-v",
  "fas assistive-listening-systems",
  "fas asterisk",
  "fas at",
  "fas audio-description",
  "fas backward",
  "fas badge",
  "fas badge-check",
  "fas balance-scale",
  "fas ban",
  "fas band-aid",
  "fas barcode",
  "fas barcode-alt",
  "fas barcode-read",
  "fas barcode-scan",
  "fas bars",
  "fas baseball",
  "fas baseball-ball",
  "fas basketball-ball",
  "fas basketball-hoop",
  "fas bath",
  "fas battery-bolt",
  "fas battery-empty",
  "fas battery-full",
  "fas battery-half",
  "fas battery-quarter",
  "fas battery-slash",
  "fas battery-three-quarters",
  "fas bed",
  "fas beer",
  "fas bell",
  "fas bell-slash",
  "fas bicycle",
  "fas binoculars",
  "fas birthday-cake",
  "fas blanket",
  "fas blind",
  "fas bold",
  "fas bolt",
  "fas bomb",
  "fas book",
  "fas book-heart",
  "fas bookmark",
  "fas bowling-ball",
  "fas bowling-pins",
  "fas box",
  "fas box-alt",
  "fas box-check",
  "fas box-fragile",
  "fas box-full",
  "fas box-heart",
  "fas box-open",
  "fas box-up",
  "fas box-usd",
  "fas boxes",
  "fas boxes-alt",
  "fas boxing-glove",
  "fas braille",
  "fas briefcase",
  "fas briefcase-medical",
  "fas browser",
  "fas bug",
  "fas building",
  "fas bullhorn",
  "fas bullseye",
  "fas burn",
  "fas bus",
  "fas calculator",
  "fas calendar",
  "fas calendar-alt",
  "fas calendar-check",
  "fas calendar-edit",
  "fas calendar-exclamation",
  "fas calendar-minus",
  "fas calendar-plus",
  "fas calendar-times",
  "fas camera",
  "fas camera-alt",
  "fas camera-retro",
  "fas capsules",
  "fas car",
  "fas caret-circle-down",
  "fas caret-circle-left",
  "fas caret-circle-right",
  "fas caret-circle-up",
  "fas caret-down",
  "fas caret-left",
  "fas caret-right",
  "fas caret-square-down",
  "fas caret-square-left",
  "fas caret-square-right",
  "fas caret-square-up",
  "fas caret-up",
  "fas cart-arrow-down",
  "fas cart-plus",
  "fas certificate",
  "fas chart-area",
  "fas chart-bar",
  "fas chart-line",
  "fas chart-pie",
  "fas check",
  "fas check-circle",
  "fas check-square",
  "fas chess",
  "fas chess-bishop",
  "fas chess-bishop-alt",
  "fas chess-board",
  "fas chess-clock",
  "fas chess-clock-alt",
  "fas chess-king",
  "fas chess-king-alt",
  "fas chess-knight",
  "fas chess-knight-alt",
  "fas chess-pawn",
  "fas chess-pawn-alt",
  "fas chess-queen",
  "fas chess-queen-alt",
  "fas chess-rook",
  "fas chess-rook-alt",
  "fas chevron-circle-down",
  "fas chevron-circle-left",
  "fas chevron-circle-right",
  "fas chevron-circle-up",
  "fas chevron-double-down",
  "fas chevron-double-left",
  "fas chevron-double-right",
  "fas chevron-double-up",
  "fas chevron-down",
  "fas chevron-left",
  "fas chevron-right",
  "fas chevron-square-down",
  "fas chevron-square-left",
  "fas chevron-square-right",
  "fas chevron-square-up",
  "fas chevron-up",
  "fas child",
  "fas circle",
  "fas circle-notch",
  "fas clipboard",
  "fas clipboard-check",
  "fas clipboard-list",
  "fas clock",
  "fas clone",
  "fas closed-captioning",
  "fas cloud",
  "fas cloud-download",
  "fas cloud-download-alt",
  "fas cloud-upload",
  "fas cloud-upload-alt",
  "fas club",
  "fas code",
  "fas code-branch",
  "fas code-commit",
  "fas code-merge",
  "fas coffee",
  "fas cog",
  "fas cogs",
  "fas columns",
  "fas comment",
  "fas comment-alt",
  "fas comment-alt-check",
  "fas comment-alt-dots",
  "fas comment-alt-edit",
  "fas comment-alt-exclamation",
  "fas comment-alt-lines",
  "fas comment-alt-minus",
  "fas comment-alt-plus",
  "fas comment-alt-slash",
  "fas comment-alt-smile",
  "fas comment-alt-times",
  "fas comment-check",
  "fas comment-dots",
  "fas comment-edit",
  "fas comment-exclamation",
  "fas comment-lines",
  "fas comment-minus",
  "fas comment-plus",
  "fas comment-slash",
  "fas comment-smile",
  "fas comment-times",
  "fas comments",
  "fas comments-alt",
  "fas compass",
  "fas compress",
  "fas compress-alt",
  "fas compress-wide",
  "fas container-storage",
  "fas conveyor-belt",
  "fas conveyor-belt-alt",
  "fas copy",
  "fas copyright",
  "fas couch",
  "fas credit-card",
  "fas credit-card-blank",
  "fas credit-card-front",
  "fas cricket",
  "fas crop",
  "fas crosshairs",
  "fas cube",
  "fas cubes",
  "fas curling",
  "fas cut",
  "fas database",
  "fas deaf",
  "fas desktop",
  "fas desktop-alt",
  "fas diagnoses",
  "fas diamond",
  "fas dna",
  "fas dollar-sign",
  "fas dolly",
  "fas dolly-empty",
  "fas dolly-flatbed",
  "fas dolly-flatbed-alt",
  "fas dolly-flatbed-empty",
  "fas donate",
  "fas dot-circle",
  "fas dove",
  "fas download",
  "fas dumbbell",
  "fas edit",
  "fas eject",
  "fas ellipsis-h",
  "fas ellipsis-h-alt",
  "fas ellipsis-v",
  "fas ellipsis-v-alt",
  "fas envelope",
  "fas envelope-open",
  "fas envelope-square",
  "fas eraser",
  "fas euro-sign",
  "fas exchange",
  "fas exchange-alt",
  "fas exclamation",
  "fas exclamation-circle",
  "fas exclamation-square",
  "fas exclamation-triangle",
  "fas expand",
  "fas expand-alt",
  "fas expand-arrows",
  "fas expand-arrows-alt",
  "fas expand-wide",
  "fas external-link",
  "fas external-link-alt",
  "fas external-link-square",
  "fas external-link-square-alt",
  "fas eye",
  "fas eye-dropper",
  "fas eye-slash",
  "fas fast-backward",
  "fas fast-forward",
  "fas fax",
  "fas female",
  "fas field-hockey",
  "fas fighter-jet",
  "fas file",
  "fas file-alt",
  "fas file-archive",
  "fas file-audio",
  "fas file-check",
  "fas file-code",
  "fas file-edit",
  "fas file-excel",
  "fas file-exclamation",
  "fas file-image",
  "fas file-medical",
  "fas file-medical-alt",
  "fas file-minus",
  "fas file-pdf",
  "fas file-plus",
  "fas file-powerpoint",
  "fas file-times",
  "fas file-video",
  "fas file-word",
  "fas film",
  "fas film-alt",
  "fas filter",
  "fas fire",
  "fas fire-extinguisher",
  "fas first-aid",
  "fas flag",
  "fas flag-checkered",
  "fas flask",
  "fas folder",
  "fas folder-open",
  "fas font",
  "fas football-ball",
  "fas football-helmet",
  "fas forklift",
  "fas forward",
  "fas fragile",
  "fas frown",
  "fas futbol",
  "fas gamepad",
  "fas gavel",
  "fas gem",
  "fas genderless",
  "fas gift",
  "fas glass-martini",
  "fas globe",
  "fas golf-ball",
  "fas golf-club",
  "fas graduation-cap",
  "fas h-square",
  "fas h1",
  "fas h2",
  "fas h3",
  "fas hand-heart",
  "fas hand-holding",
  "fas hand-holding-box",
  "fas hand-holding-heart",
  "fas hand-holding-seedling",
  "fas hand-holding-usd",
  "fas hand-holding-water",
  "fas hand-lizard",
  "fas hand-paper",
  "fas hand-peace",
  "fas hand-point-down",
  "fas hand-point-left",
  "fas hand-point-right",
  "fas hand-point-up",
  "fas hand-pointer",
  "fas hand-receiving",
  "fas hand-rock",
  "fas hand-scissors",
  "fas hand-spock",
  "fas hands",
  "fas hands-heart",
  "fas hands-helping",
  "fas hands-usd",
  "fas handshake",
  "fas handshake-alt",
  "fas hashtag",
  "fas hdd",
  "fas heading",
  "fas headphones",
  "fas heart",
  "fas heart-circle",
  "fas heart-square",
  "fas heartbeat",
  "fas hexagon",
  "fas history",
  "fas hockey-puck",
  "fas hockey-sticks",
  "fas home",
  "fas home-heart",
  "fas hospital",
  "fas hospital-alt",
  "fas hospital-symbol",
  "fas hourglass",
  "fas hourglass-end",
  "fas hourglass-half",
  "fas hourglass-start",
  "fas i-cursor",
  "fas id-badge",
  "fas id-card",
  "fas id-card-alt",
  "fas image",
  "fas images",
  "fas inbox",
  "fas inbox-in",
  "fas inbox-out",
  "fas indent",
  "fas industry",
  "fas industry-alt",
  "fas info",
  "fas info-circle",
  "fas info-square",
  "fas inventory",
  "fas italic",
  "fas jack-o-lantern",
  "fas key",
  "fas keyboard",
  "fas lamp",
  "fas language",
  "fas laptop",
  "fas leaf",
  "fas leaf-heart",
  "fas lemon",
  "fas level-down",
  "fas level-down-alt",
  "fas level-up",
  "fas level-up-alt",
  "fas life-ring",
  "fas lightbulb",
  "fas link",
  "fas lira-sign",
  "fas list",
  "fas list-alt",
  "fas list-ol",
  "fas list-ul",
  "fas location-arrow",
  "fas lock",
  "fas lock-alt",
  "fas lock-open",
  "fas lock-open-alt",
  "fas long-arrow-alt-down",
  "fas long-arrow-alt-left",
  "fas long-arrow-alt-right",
  "fas long-arrow-alt-up",
  "fas long-arrow-down",
  "fas long-arrow-left",
  "fas long-arrow-right",
  "fas long-arrow-up",
  "fas loveseat",
  "fas low-vision",
  "fas luchador",
  "fas magic",
  "fas magnet",
  "fas male",
  "fas map",
  "fas map-marker",
  "fas map-marker-alt",
  "fas map-pin",
  "fas map-signs",
  "fas mars",
  "fas mars-double",
  "fas mars-stroke",
  "fas mars-stroke-h",
  "fas mars-stroke-v",
  "fas medkit",
  "fas meh",
  "fas mercury",
  "fas microchip",
  "fas microphone",
  "fas microphone-alt",
  "fas microphone-slash",
  "fas minus",
  "fas minus-circle",
  "fas minus-hexagon",
  "fas minus-octagon",
  "fas minus-square",
  "fas mobile",
  "fas mobile-alt",
  "fas mobile-android",
  "fas mobile-android-alt",
  "fas money-bill",
  "fas money-bill-alt",
  "fas moon",
  "fas motorcycle",
  "fas mouse-pointer",
  "fas music",
  "fas neuter",
  "fas newspaper",
  "fas notes-medical",
  "fas object-group",
  "fas object-ungroup",
  "fas octagon",
  "fas outdent",
  "fas paint-brush",
  "fas pallet",
  "fas pallet-alt",
  "fas paper-plane",
  "fas paperclip",
  "fas parachute-box",
  "fas paragraph",
  "fas paste",
  "fas pause",
  "fas pause-circle",
  "fas paw",
  "fas pen",
  "fas pen-alt",
  "fas pen-square",
  "fas pencil",
  "fas pencil-alt",
  "fas pennant",
  "fas people-carry",
  "fas percent",
  "fas person-carry",
  "fas person-dolly",
  "fas person-dolly-empty",
  "fas phone",
  "fas phone-plus",
  "fas phone-slash",
  "fas phone-square",
  "fas phone-volume",
  "fas piggy-bank",
  "fas pills",
  "fas plane",
  "fas plane-alt",
  "fas play",
  "fas play-circle",
  "fas plug",
  "fas plus",
  "fas plus-circle",
  "fas plus-hexagon",
  "fas plus-octagon",
  "fas plus-square",
  "fas podcast",
  "fas poo",
  "fas portrait",
  "fas pound-sign",
  "fas power-off",
  "fas prescription-bottle",
  "fas prescription-bottle-alt",
  "fas print",
  "fas procedures",
  "fas puzzle-piece",
  "fas qrcode",
  "fas question",
  "fas question-circle",
  "fas question-square",
  "fas quidditch",
  "fas quote-left",
  "fas quote-right",
  "fas racquet",
  "fas ramp-loading",
  "fas random",
  "fas rectangle-landscape",
  "fas rectangle-portrait",
  "fas rectangle-wide",
  "fas recycle",
  "fas redo",
  "fas redo-alt",
  "fas registered",
  "fas repeat",
  "fas repeat-1",
  "fas repeat-1-alt",
  "fas repeat-alt",
  "fas reply",
  "fas reply-all",
  "fas retweet",
  "fas retweet-alt",
  "fas ribbon",
  "fas road",
  "fas rocket",
  "fas route",
  "fas rss",
  "fas rss-square",
  "fas ruble-sign",
  "fas rupee-sign",
  "fas save",
  "fas scanner",
  "fas scanner-keyboard",
  "fas scanner-touchscreen",
  "fas scrubber",
  "fas search",
  "fas search-minus",
  "fas search-plus",
  "fas seedling",
  "fas server",
  "fas share",
  "fas share-all",
  "fas share-alt",
  "fas share-alt-square",
  "fas share-square",
  "fas shekel-sign",
  "fas shield",
  "fas shield-alt",
  "fas shield-check",
  "fas ship",
  "fas shipping-fast",
  "fas shipping-timed",
  "fas shopping-bag",
  "fas shopping-basket",
  "fas shopping-cart",
  "fas shower",
  "fas shuttlecock",
  "fas sign",
  "fas sign-in",
  "fas sign-in-alt",
  "fas sign-language",
  "fas sign-out",
  "fas sign-out-alt",
  "fas signal",
  "fas sitemap",
  "fas sliders-h",
  "fas sliders-h-square",
  "fas sliders-v",
  "fas sliders-v-square",
  "fas smile",
  "fas smile-plus",
  "fas smoking",
  "fas snowflake",
  "fas sort",
  "fas sort-alpha-down",
  "fas sort-alpha-up",
  "fas sort-amount-down",
  "fas sort-amount-up",
  "fas sort-down",
  "fas sort-numeric-down",
  "fas sort-numeric-up",
  "fas sort-up",
  "fas space-shuttle",
  "fas spade",
  "fas spinner",
  "fas spinner-third",
  "fas square",
  "fas square-full",
  "fas star",
  "fas star-exclamation",
  "fas star-half",
  "fas step-backward",
  "fas step-forward",
  "fas stethoscope",
  "fas sticky-note",
  "fas stop",
  "fas stop-circle",
  "fas stopwatch",
  "fas street-view",
  "fas strikethrough",
  "fas subscript",
  "fas subway",
  "fas suitcase",
  "fas sun",
  "fas superscript",
  "fas sync",
  "fas sync-alt",
  "fas syringe",
  "fas table",
  "fas table-tennis",
  "fas tablet",
  "fas tablet-alt",
  "fas tablet-android",
  "fas tablet-android-alt",
  "fas tablet-rugged",
  "fas tablets",
  "fas tachometer",
  "fas tachometer-alt",
  "fas tag",
  "fas tags",
  "fas tape",
  "fas tasks",
  "fas taxi",
  "fas tennis-ball",
  "fas terminal",
  "fas text-height",
  "fas text-width",
  "fas th",
  "fas th-large",
  "fas th-list",
  "fas thermometer",
  "fas thermometer-empty",
  "fas thermometer-full",
  "fas thermometer-half",
  "fas thermometer-quarter",
  "fas thermometer-three-quarters",
  "fas thumbs-down",
  "fas thumbs-up",
  "fas thumbtack",
  "fas ticket",
  "fas ticket-alt",
  "fas times",
  "fas times-circle",
  "fas times-hexagon",
  "fas times-octagon",
  "fas times-square",
  "fas tint",
  "fas toggle-off",
  "fas toggle-on",
  "fas trademark",
  "fas train",
  "fas transgender",
  "fas transgender-alt",
  "fas trash",
  "fas trash-alt",
  "fas tree",
  "fas tree-alt",
  "fas triangle",
  "fas trophy",
  "fas trophy-alt",
  "fas truck",
  "fas truck-container",
  "fas truck-couch",
  "fas truck-loading",
  "fas truck-moving",
  "fas truck-ramp",
  "fas tty",
  "fas tv",
  "fas tv-retro",
  "fas umbrella",
  "fas underline",
  "fas undo",
  "fas undo-alt",
  "fas universal-access",
  "fas university",
  "fas unlink",
  "fas unlock",
  "fas unlock-alt",
  "fas upload",
  "fas usd-circle",
  "fas usd-square",
  "fas user",
  "fas user-alt",
  "fas user-circle",
  "fas user-md",
  "fas user-plus",
  "fas user-secret",
  "fas user-times",
  "fas users",
  "fas utensil-fork",
  "fas utensil-knife",
  "fas utensil-spoon",
  "fas utensils",
  "fas utensils-alt",
  "fas venus",
  "fas venus-double",
  "fas venus-mars",
  "fas vial",
  "fas vials",
  "fas video",
  "fas video-plus",
  "fas video-slash",
  "fas volleyball-ball",
  "fas volume-down",
  "fas volume-mute",
  "fas volume-off",
  "fas volume-up",
  "fas warehouse",
  "fas warehouse-alt",
  "fas watch",
  "fas weight",
  "fas wheelchair",
  "fas whistle",
  "fas wifi",
  "fas window",
  "fas window-alt",
  "fas window-close",
  "fas window-maximize",
  "fas window-minimize",
  "fas window-restore",
  "fas wine-glass",
  "fas won-sign",
  "fas wrench",
  "fas x-ray",
  "fas yen-sign",
  "fab 500px",
  "fab accessible-icon",
  "fab accusoft",
  "fab adn",
  "fab adversal",
  "fab affiliatetheme",
  "fab algolia",
  "fab amazon",
  "fab amazon-pay",
  "fab amilia",
  "fab android",
  "fab angellist",
  "fab angrycreative",
  "fab angular",
  "fab app-store",
  "fab app-store-ios",
  "fab apper",
  "fab apple",
  "fab apple-pay",
  "fab asymmetrik",
  "fab audible",
  "fab autoprefixer",
  "fab avianex",
  "fab aviato",
  "fab aws",
  "fab bandcamp",
  "fab behance",
  "fab behance-square",
  "fab bimobject",
  "fab bitbucket",
  "fab bitcoin",
  "fab bity",
  "fab black-tie",
  "fab blackberry",
  "fab blogger",
  "fab blogger-b",
  "fab bluetooth",
  "fab bluetooth-b",
  "fab btc",
  "fab buromobelexperte",
  "fab buysellads",
  "fab cc-amazon-pay",
  "fab cc-amex",
  "fab cc-apple-pay",
  "fab cc-diners-club",
  "fab cc-discover",
  "fab cc-jcb",
  "fab cc-mastercard",
  "fab cc-paypal",
  "fab cc-stripe",
  "fab cc-visa",
  "fab centercode",
  "fab chrome",
  "fab cloudscale",
  "fab cloudsmith",
  "fab cloudversify",
  "fab codepen",
  "fab codiepie",
  "fab connectdevelop",
  "fab contao",
  "fab cpanel",
  "fab creative-commons",
  "fab css3",
  "fab css3-alt",
  "fab cuttlefish",
  "fab d-and-d",
  "fab dashcube",
  "fab delicious",
  "fab deploydog",
  "fab deskpro",
  "fab deviantart",
  "fab digg",
  "fab digital-ocean",
  "fab discord",
  "fab discourse",
  "fab dochub",
  "fab docker",
  "fab draft2digital",
  "fab dribbble",
  "fab dribbble-square",
  "fab dropbox",
  "fab drupal",
  "fab dyalog",
  "fab earlybirds",
  "fab edge",
  "fab elementor",
  "fab ember",
  "fab empire",
  "fab envira",
  "fab erlang",
  "fab ethereum",
  "fab etsy",
  "fab expeditedssl",
  "fab facebook",
  "fab facebook-f",
  "fab facebook-messenger",
  "fab facebook-square",
  "fab firefox",
  "fab first-order",
  "fab firstdraft",
  "fab flickr",
  "fab flipboard",
  "fab fly",
  "fab font-awesome",
  "fab font-awesome-alt",
  "fab font-awesome-flag",
  "fab fonticons",
  "fab fonticons-fi",
  "fab fort-awesome",
  "fab fort-awesome-alt",
  "fab forumbee",
  "fab foursquare",
  "fab free-code-camp",
  "fab freebsd",
  "fab get-pocket",
  "fab gg",
  "fab gg-circle",
  "fab git",
  "fab git-square",
  "fab github",
  "fab github-alt",
  "fab github-square",
  "fab gitkraken",
  "fab gitlab",
  "fab gitter",
  "fab glide",
  "fab glide-g",
  "fab gofore",
  "fab goodreads",
  "fab goodreads-g",
  "fab google",
  "fab google-drive",
  "fab google-play",
  "fab google-plus",
  "fab google-plus-g",
  "fab google-plus-square",
  "fab google-wallet",
  "fab gratipay",
  "fab grav",
  "fab gripfire",
  "fab grunt",
  "fab gulp",
  "fab hacker-news",
  "fab hacker-news-square",
  "fab hips",
  "fab hire-a-helper",
  "fab hooli",
  "fab hotjar",
  "fab houzz",
  "fab html5",
  "fab hubspot",
  "fab imdb",
  "fab instagram",
  "fab internet-explorer",
  "fab ioxhost",
  "fab itunes",
  "fab itunes-note",
  "fab jenkins",
  "fab joget",
  "fab joomla",
  "fab js",
  "fab js-square",
  "fab jsfiddle",
  "fab keycdn",
  "fab kickstarter",
  "fab kickstarter-k",
  "fab korvue",
  "fab laravel",
  "fab lastfm",
  "fab lastfm-square",
  "fab leanpub",
  "fab less",
  "fab line",
  "fab linkedin",
  "fab linkedin-in",
  "fab linode",
  "fab linux",
  "fab lyft",
  "fab magento",
  "fab maxcdn",
  "fab medapps",
  "fab medium",
  "fab medium-m",
  "fab medrt",
  "fab meetup",
  "fab microsoft",
  "fab mix",
  "fab mixcloud",
  "fab mizuni",
  "fab modx",
  "fab monero",
  "fab napster",
  "fab nintendo-switch",
  "fab node",
  "fab node-js",
  "fab npm",
  "fab ns8",
  "fab nutritionix",
  "fab odnoklassniki",
  "fab odnoklassniki-square",
  "fab opencart",
  "fab openid",
  "fab opera",
  "fab optin-monster",
  "fab osi",
  "fab page4",
  "fab pagelines",
  "fab palfed",
  "fab patreon",
  "fab paypal",
  "fab periscope",
  "fab phabricator",
  "fab phoenix-framework",
  "fab php",
  "fab pied-piper",
  "fab pied-piper-alt",
  "fab pied-piper-pp",
  "fab pinterest",
  "fab pinterest-p",
  "fab pinterest-square",
  "fab playstation",
  "fab product-hunt",
  "fab pushed",
  "fab python",
  "fab qq",
  "fab quinscape",
  "fab quora",
  "fab ravelry",
  "fab react",
  "fab readme",
  "fab rebel",
  "fab red-river",
  "fab reddit",
  "fab reddit-alien",
  "fab reddit-square",
  "fab rendact",
  "fab renren",
  "fab replyd",
  "fab resolving",
  "fab rocketchat",
  "fab rockrms",
  "fab safari",
  "fab sass",
  "fab schlix",
  "fab scribd",
  "fab searchengin",
  "fab sellcast",
  "fab sellsy",
  "fab servicestack",
  "fab shirtsinbulk",
  "fab simplybuilt",
  "fab sistrix",
  "fab skyatlas",
  "fab skype",
  "fab slack",
  "fab slack-hash",
  "fab slideshare",
  "fab snapchat",
  "fab snapchat-ghost",
  "fab snapchat-square",
  "fab soundcloud",
  "fab speakap",
  "fab spotify",
  "fab stack-exchange",
  "fab stack-overflow",
  "fab staylinked",
  "fab steam",
  "fab steam-square",
  "fab steam-symbol",
  "fab sticker-mule",
  "fab strava",
  "fab stripe",
  "fab stripe-s",
  "fab studiovinari",
  "fab stumbleupon",
  "fab stumbleupon-circle",
  "fab superpowers",
  "fab supple",
  "fab telegram",
  "fab telegram-plane",
  "fab tencent-weibo",
  "fab themeisle",
  "fab trello",
  "fab tripadvisor",
  "fab tumblr",
  "fab tumblr-square",
  "fab twitch",
  "fab twitter",
  "fab twitter-square",
  "fab typo3",
  "fab uber",
  "fab uikit",
  "fab uniregistry",
  "fab untappd",
  "fab usb",
  "fab ussunnah",
  "fab vaadin",
  "fab viacoin",
  "fab viadeo",
  "fab viadeo-square",
  "fab viber",
  "fab vimeo",
  "fab vimeo-square",
  "fab vimeo-v",
  "fab vine",
  "fab vk",
  "fab vnv",
  "fab vuejs",
  "fab weibo",
  "fab weixin",
  "fab whatsapp",
  "fab whatsapp-square",
  "fab whmcs",
  "fab wikipedia-w",
  "fab windows",
  "fab wordpress",
  "fab wordpress-simple",
  "fab wpbeginner",
  "fab wpexplorer",
  "fab wpforms",
  "fab xbox",
  "fab xing",
  "fab xing-square",
  "fab y-combinator",
  "fab yahoo",
  "fab yandex",
  "fab yandex-international",
  "fab yelp",
  "fab yoast",
  "fab youtube",
  "fab youtube-square"
];
