import React, { useMemo } from "react";
import KeyedDropdown from "../../Dropdown/KeyedDropdown";
import { useIntl } from "react-intl";
import { IFilterOption } from "./AdvancedFilterHelper";

interface IOptionProfileProps {
  item?: IFilterOption;
  className?: string;
}

const FilterOptionProfile = ({ item, className }: IOptionProfileProps) => {
  const intl = useIntl();
  if (!item) return null;
  const { title, titleId = "" } = item;

  return (
    <span className={className}>
      {title ? title : titleId ? intl.formatMessage({ id: titleId }) : ""}
    </span>
  );
};

const getKey = (option: IFilterOption) => {
  return (
    "_" +
    (
      Number(String(Math.random()).slice(2)) +
      Date.now() +
      Math.round(performance.now())
    ).toString(36)
  );
};
export const FilterParametersDropdown = (props: any) => {
  const { options } = props;
  const intl = useIntl();
  const orderedOptions = useMemo(() => {
    let result = [...options];
    result.sort(function (a, b) {
      const { title: titleA, titleId: titleIdA } = a;
      const { title: titleB, titleId: titleIdB } = b;

      const resolvedTitleA = titleA
        ? titleA
        : titleIdA
        ? intl.formatMessage({ id: titleIdA })
        : "";
      const resolvedTitleB = titleB
        ? titleB
        : titleIdB
        ? intl.formatMessage({ id: titleIdB })
        : "";

      return resolvedTitleA
        .toLowerCase()
        .localeCompare(resolvedTitleB.toLowerCase());
    });

    return result;
  }, [intl, options]);

  return (
    <KeyedDropdown
      {...props}
      options={orderedOptions}
      valueComponent={FilterOptionProfile}
      getKey={getKey}
      closeOnSelect
    />
  );
};
