import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
  useEffect
} from "react";
import classnames from "classnames";
import { ServerAwareFilterListToggler } from "../FilterList/ServerAwareFilterList";
import Modal from "../Modal/Modal";
import ViewSaver from "../ViewSaver/ViewSaver";
import Button from "../Button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import UnreadOptionsButton from "../UnOrReadOptions/UnreadOptions";
import Popup, { usePopupOpenState } from "../Popup/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCheck,
  faChevronDown,
  faCog,
  faColumns,
  faList,
  faPencil,
  faPlus,
  faStream,
  faTrashAlt,
  faTableCellsLarge
} from "@fortawesome/pro-light-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import querystring from "query-string";
import "./PageHeaderViews.css";
import {
  ServerAwareUpdateViewsContext,
  ServerAwareViewsContext,
  useServerAwareViewsDeleteLoading
} from "../CGrid/ServerAwareViews";
import FormInput from "../Forms/FormInput/FormInput";
import LoadableButton from "../Button/LoadableButton";
import PageViewSorter from "./PageViewSorter";
import { useSpace } from "../../Contexts/SpaceContext";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { ServerAwareFavoritesContext } from "../../Containers/AppBar/ServerAwareFavorite";
import {
  MainViewsSearchCommunicator,
  MainViewsSearchLSKey,
  NewMainViewsSearchLSKey,
  LastUsedSpaceLSKey
} from "../../Helpers/MiscHelper";
import { HandleQueryStringObjectMaker } from "../../Helpers/QueryStringerHelper";

const PageHeaderItems = ({ schema, openViewModal, screenWidth }) => {
  const location = useLocation();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const views = useContext(ServerAwareViewsContext);
  const resolvedViewIndex = views?.findIndex((e) => e.id === viewPref);
  const resolvedView = resolvedViewIndex === -1 ? views[0].id : viewPref;
  // const updateviews = useContext(ServerAwareUpdateViewsContext);
  const [hiddenViews, setHiddenViews] = useState(0);
  // useEffect(() => {}, []);

  // const unsortedResolvedItems = [...views];
  // const indexOfGeneral = unsortedResolvedItems.findIndex(
  //   (e) => e.name === "Default View"
  // );
  // if (indexOfGeneral !== 0 && indexOfGeneral !== -1) {
  //   insertAndShift(unsortedResolvedItems, indexOfGeneral, 0);
  // }
  // const resolvedItems = Array.isArray(views)
  //   ? views.filter((e) => e.name !== "Default View")
  //   : [];
  // resolvedItems.unshift(unsortedResolvedItems[0]);

  // if (indexOfGeneral !== 0 && indexOfGeneral !== -1) {
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }
  //   let gridSavedViews = JSON.parse(
  //     window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
  //   );
  //   gridSavedViews[schema.name] = resolvedItems;
  //   localStorage.setItem(
  //     `ar-grid-saved-views-space-${space.Id}`,
  //     JSON.stringify(gridSavedViews)
  //   );
  //   timeoutRef.current = setTimeout(() => {
  //     updateviews();
  //   }, 3000);
  // }

  const resolvedItems = [...views];

  const hiddenItems =
    hiddenViews > 0
      ? resolvedItems.splice(resolvedItems.length - hiddenViews, hiddenViews)
      : [];

  const viewItems = resolvedItems.map((e, i, arr) => {
    if (e.id === resolvedView) {
      return (
        <PageHeaderCurrentViewItem
          key={i}
          index={i}
          viewItem={e}
          schema={schema}
          length={arr.length}
        />
      );
    } else
      return (
        <PageHeaderViewItem
          key={i}
          index={i}
          schema={schema}
          viewItem={e}
          length={arr.length}
        />
      );
  });

  const hiddenViewItems = hiddenItems.map((e, i, arr) => (
    <PageHeaderViewMoreItem
      key={i}
      index={i}
      viewItem={e}
      schema={schema}
      length={arr.length}
    />
  ));

  const MoreViewsButtonRef = useRef();
  const MoreViewsModalRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(
    MoreViewsModalRef,
    MoreViewsButtonRef
  );

  const containerRef = useRef();

  useLayoutEffect(() => {
    const handleResize = () => {
      let ContainerWidth = containerRef.current.offsetWidth;
      let removeCount = 0;
      if (ContainerWidth > screenWidth - 120) {
        const refChildren = containerRef.current.children;
        for (let i = refChildren.length; i--; ) {
          const child = refChildren[i];
          ContainerWidth -= child.offsetWidth;
          removeCount++;
          if (ContainerWidth < screenWidth - 120) {
            break;
          }
        }
      }
      setHiddenViews(removeCount);
    };

    handleResize();
  }, [screenWidth]);

  return (
    <div ref={containerRef} className="air-page-views-line fs-14">
      <Popup
        anchorEl={MoreViewsButtonRef.current}
        domRef={MoreViewsModalRef}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        placement="bottom-end"
        isOpen={isOpen}
      >
        <div className="bg-white d-flex flex-column">{hiddenViewItems}</div>
      </Popup>
      <PageHeaderViewBarBurger
        hiddenViewItems={hiddenItems}
        schema={schema}
        openViewModal={openViewModal}
      />
      {viewItems}
      {hiddenViewItems.length > 0 && (
        <div
          ref={MoreViewsButtonRef}
          onClick={() => {
            toggleIsOpen(!isOpen);
          }}
          style={{
            borderTopRightRadius: 3
          }}
          className={classnames(
            "air-page-view-tab p-2 border-top border-right text-black bg-white"
          )}
        >
          <div className="text-truncate mr-2 flex-1">
            <FormattedMessage id="MORE" />
          </div>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      )}
      <div
        onClick={openViewModal}
        className="align-items-center air-add-new-view d-inline-flex p-2 text-color-link cursor-pointer"
      >
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        <FormattedMessage id="ADD_VIEW" />
      </div>
    </div>
  );
};

const PageViewsHiddenOptionsIncrementerContext = React.createContext();

export const ReorderChangesContext = React.createContext();

const ViewsBarPopupContent = ({ hiddenViewItems, schema }) => {
  const location = useLocation();
  const space = useSpace();
  const history = useHistory();
  const { search } = location;
  const queryObj = querystring.parse(search);
  const { viewPref } = queryObj;
  const views = useContext(ServerAwareViewsContext);
  const resolvedViewIndex = views.findIndex((e) => e.id === viewPref);
  // const updateviews = useContext(ServerAwareUpdateViewsContext);
  const handleKeyChange = useContext(PageViewsHiddenOptionsIncrementerContext);
  const intl = useIntl();
  const handleViewClick = (e) => {
    const { search: searchI, id } = e;

    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );
    const viewGridViews = gridSavedViews[schema.name];
    const hiddenIndex = hiddenViewItems.findIndex((t) => t.id === id);

    if (hiddenIndex !== -1) {
      const index = viewGridViews.findIndex((t) => t.id === id);
      viewGridViews.splice(index, 1);
      viewGridViews.splice(1, 0, e);
      gridSavedViews[schema.name] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
      // updateviews();
    }

    const parsed = querystring.parse(searchI);
    parsed.viewPref = id;
    history.push({
      search: querystring.stringify(parsed)
    });

    if (handleKeyChange) handleKeyChange();
    //Filters Layout Change
  };

  return (
    <>
      {views.map((e, i) => {
        const parsedSearch = querystring.parse(e.search);

        const { view } = parsedSearch;
        return (
          <button
            key={i}
            className={classnames(
              "ssi-button ssi-control ssi-button-link py-2 px-1 AlignLeftLoadableButton",
              {
                "air-page-view-active": i === resolvedViewIndex
              }
            )}
            onClick={() => {
              handleViewClick(e);
            }}
          >
            <div className="d-flex align-items-center">
              <PageViewTypeIconComponent view={view} />
              <span
                style={{ textAlign: "left" }}
                className="text-truncate ml-2 flex-1"
              >
                {e.name === "Default View"
                  ? intl.formatMessage({ id: "DEFAULT_VIEW" })
                  : e.name}
              </span>
              {i === resolvedViewIndex && (
                <FontAwesomeIcon icon={faCheck} className="mr-3" />
              )}
            </div>
          </button>
        );
      })}
    </>
  );
};

const PageHeaderViewBar = ({ schema, openViewModal }) => {
  const [contWidth, setContWidth] = useState(window.innerWidth);
  const [hiddenChanges, setHiddenChanges] = useState(0);

  const ReorderChangesRef = useContext(ReorderChangesContext);

  const handleHiddenViewChanges = useCallback(() => {
    setHiddenChanges((e) => {
      return e + 1;
    });
  }, []);

  useLayoutEffect(() => {
    if (!ReorderChangesRef.current) {
      ReorderChangesRef.current = () => {
        handleHiddenViewChanges();
      };
    }
  }, [ReorderChangesRef, handleHiddenViewChanges]);

  useLayoutEffect(() => {
    if (PageHeaderBarRef.current) {
      setContWidth(PageHeaderBarRef.current.offsetWidth);
    }
  }, []);

  useLayoutEffect(() => {
    var timeout;
    const handleResizeChange = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (PageHeaderBarRef.current) {
          setContWidth(PageHeaderBarRef.current.offsetWidth);
        }
      }, 200);
    };
    window.addEventListener("resize", handleResizeChange);

    return () => {
      window.removeEventListener("resize", handleResizeChange);
    };
  }, []);

  const PageHeaderBarRef = useRef();

  return (
    <div
      ref={PageHeaderBarRef}
      className="w-100 flex-0-0-auto border-bottom bg-white pt-3"
    >
      <PageViewsHiddenOptionsIncrementerContext.Provider
        value={handleHiddenViewChanges}
      >
        <PageHeaderItems
          openViewModal={openViewModal}
          schema={schema}
          screenWidth={contWidth}
          key={contWidth + "|" + hiddenChanges}
        />
      </PageViewsHiddenOptionsIncrementerContext.Provider>
    </div>
  );
};

const PageHeaderViewBarBurger = ({
  schema,
  openViewModal,
  hiddenViewItems
}) => {
  const [pageViewModalIsOpen, setPageViewModalIsOpen] = useState(false);
  const schemaViews = useContext(ServerAwareViewsContext);
  const closePageViewModal = useCallback(() => {
    setPageViewModalIsOpen(false);
  }, []);

  const BarButtonRef = useRef();
  const BarButtonPopupRef = useRef();
  const [optionsIsOpen, optionToggle] = usePopupOpenState(
    BarButtonPopupRef,
    BarButtonRef
  );

  return (
    <>
      <Modal isOpen={pageViewModalIsOpen} onClose={closePageViewModal}>
        <PageViewSorter
          schemaView={schema}
          onClose={closePageViewModal}
          setIsOpen={setPageViewModalIsOpen}
          isOpen={pageViewModalIsOpen}
        />
      </Modal>
      <Popup
        anchorEl={BarButtonRef.current}
        domRef={BarButtonPopupRef}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        placement="bottom-start"
        isOpen={optionsIsOpen}
      >
        <div
          // ref={BarButtonPopupRef}
          className="air-page-view-bars-popup d-flex flex-column py-2"
        >
          <div className="d-flex flex-column pb-2 of-y-auto">
            <ViewsBarPopupContent
              schema={schema}
              hiddenViewItems={hiddenViewItems}
            />
          </div>
          <div className="w-100 border-top py-2"></div>
          <div className="d-flex flex-column">
            <button
              disabled={schemaViews.length === 1}
              className="ssi-button ssi-control ssi-button-link p-1 AlignLeftLoadableButton"
              onClick={() => {
                setPageViewModalIsOpen(true);
              }}
            >
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faCog} />
                <span>
                  <FormattedMessage id="REORDER_VIEWS" />
                </span>
              </div>
            </button>
            <button
              className="ssi-button air-add-new-view ssi-control text-color-link bg-white ssi-button-link p-1 AlignLeftLoadableButton"
              onClick={() => {
                openViewModal(true);
              }}
            >
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faPlus} />
                <span>
                  <FormattedMessage id="ADD_VIEW" />
                </span>
              </div>
            </button>
          </div>
        </div>
      </Popup>
      <div
        className="d-inline-flex"
        style={{ paddingRight: 10, paddingLeft: 10 }}
        ref={BarButtonRef}
      >
        <div
          onClick={() => {
            optionToggle(!optionsIsOpen);
          }}
          style={{
            padding: "2px 11px 2px 11px",
            height: 35,
            borderRadius: "50%"
          }}
          className={classnames("air-page-view-tab ", {
            "text-black": !optionsIsOpen,
            "text-color-link": optionsIsOpen
          })}
        >
          <div className="air-page-views-bar-item-icon">
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>
      </div>
    </>
  );
};

const PageHeaderCurrentViewItem = ({ viewItem, index, length, schema }) => {
  const { name, id, search } = viewItem;
  const views = useContext(ServerAwareViewsContext);
  const intl = useIntl();
  const space = useSpace();
  const parsedSearch = querystring.parse(search);
  const history = useHistory();
  const { view } = parsedSearch;

  const updateViews = useContext(ServerAwareUpdateViewsContext);

  const OptionButtonRef = useRef();
  const OptionPopupRef = useRef();

  const [optionsIsOpen, optionToggle] = usePopupOpenState(
    OptionPopupRef,
    OptionButtonRef
  );

  const [renameModalStatus, setRenameModalStatus] = useState(false);
  const handleRenemeModalClose = () => {
    setRenameModalStatus(false);
  };

  const [nameChange, setNameChange] = useState(name);
  const handleChange = (e) => {
    const {
      target: { value }
    } = e;
    setNameChange(value);
  };

  let resolvedName = "";
  if (name === "Default View") {
    resolvedName = `${intl.formatMessage({
      id: "DEFAULT_VIEW"
    })}`;
  } else {
    resolvedName = name;
  }
  const handleSubmit = () => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );
    const viewGridViews = gridSavedViews[schema.name];
    const index = viewGridViews.findIndex((e) => e.id === id);
    if (index !== -1) {
      viewGridViews[index].name = nameChange;
      gridSavedViews[schema.name] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
    }
    updateViews();
    handleRenemeModalClose();
  };

  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const updateLsViews = useContext(ServerAwareUpdateViewsContext);
  const handleDeleteModalClose = () => {
    setDeleteModalStatus(false);
  };

  const saveToLocalStorageKey = (newSearch, key) => {
    const name = key;
    const lastUsedSpace = localStorage.getItem(LastUsedSpaceLSKey);
    const NewDictString = localStorage.getItem(
      NewMainViewsSearchLSKey + lastUsedSpace
    );
    const NullNewDictString = localStorage.getItem(
      NewMainViewsSearchLSKey + "null"
    );
    const searchDictString = localStorage.getItem(MainViewsSearchLSKey);

    let searchDict;

    if (NewDictString) searchDict = JSON.parse(NewDictString);
    else if (NullNewDictString) {
      localStorage.setItem(
        NewMainViewsSearchLSKey + lastUsedSpace,
        NullNewDictString
      );
      localStorage.removeItem(NewMainViewsSearchLSKey + "null");
      searchDict = JSON.parse(NullNewDictString);
    } else if (searchDictString) {
      searchDict = JSON.parse(searchDictString);
    } else searchDict = {};

    const parsedNewSearch = querystring.parse(newSearch);
    const resolvedNewSearch = HandleQueryStringObjectMaker(parsedNewSearch);
    searchDict[name] = querystring.stringify({
      ...resolvedNewSearch
    });
    localStorage.setItem(
      NewMainViewsSearchLSKey + lastUsedSpace,
      JSON.stringify(searchDict)
    );
    MainViewsSearchCommunicator.dispatch(searchDict);
  };

  const AfterRemove = () => {
    const { search: resolvedSearch } = views[0];
    const newParsed = querystring.parse(resolvedSearch);
    const newStringed = querystring.stringify(newParsed);
    history.push({
      search: newStringed
    });
    saveToLocalStorageKey(newStringed, schema.name);
  };

  const removeRef = useRef();

  const handleDeleteCurrentView = () => {
    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );
    const viewGridViews = gridSavedViews[schema.name];
    const index = viewGridViews.findIndex((e) => e.id === id);
    if (index !== -1) {
      viewGridViews.splice(index, 1);
      gridSavedViews[schema.name] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
    }
    // updateViews();
    updateLsViews();
    removeRef.current = true;
  };

  const loadingDelete = useServerAwareViewsDeleteLoading();

  useEffect(() => {
    if (removeRef.current && !loadingDelete) {
      AfterRemove();
      handleDeleteModalClose();
    }
  }, [AfterRemove, loadingDelete]);

  return (
    <>
      <Modal isOpen={deleteModalStatus} onClose={handleDeleteModalClose}>
        <div className="w-450px">
          <div
            style={{ height: 51 }}
            className="text-black px-4 d-flex fw-medium align-items-center ar-entity-summary-header"
          >
            <FormattedMessage id="DELETE_VIEW_CONFIRMATION" />
          </div>
          <div className="p-4 mt-3 text-black fs-18">{resolvedName}</div>
          <div className="d-flex p-4 align-items-center justify-content-end">
            <Button
              onClick={handleDeleteModalClose}
              vType="outline-danger"
              className="mr-3"
            >
              <FormattedMessage id="CANCEL" />
            </Button>
            <Button vType="danger" onClick={handleDeleteCurrentView}>
              <FormattedMessage id="DELETE_VIEW" />
            </Button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={renameModalStatus} onClose={handleRenemeModalClose}>
        <div className="w-450px">
          <div
            style={{ height: 51 }}
            className="text-black px-4 d-flex align-items-center ar-entity-summary-header"
          >
            <FormattedMessage id="RENAME_VIEW" />
          </div>
          <div className="p-4">
            <FormInput
              value={nameChange}
              text={intl.formatMessage({ id: "NAME" })}
              className="mb-4"
              onChange={handleChange}
            />
            <div className="d-flex justify-content-end">
              <Button onClick={handleRenemeModalClose} vType="link-danger mr-3">
                <FormattedMessage id="CANCEL" />
              </Button>
              <LoadableButton
                disabled={!name || name.match(/^\s*$/)}
                onClick={handleSubmit}
              >
                <FormattedMessage id="SAVE" />
              </LoadableButton>
            </div>
          </div>
        </div>
      </Modal>
      <Popup
        domRef={OptionPopupRef}
        anchorEl={OptionButtonRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        placement="bottom-start"
        isOpen={optionsIsOpen}
      >
        <div
          style={{ width: 180, borderRadius: 3 }}
          ref={OptionPopupRef}
          className="bg-white d-flex  flex-column py-2"
        >
          <button
            className="ssi-button ssi-control ssi-button-link p-1 AlignLeftLoadableButton"
            onClick={() => {
              setRenameModalStatus(true);
            }}
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faPencil} />
              <span>
                <FormattedMessage id="RENAME" />
              </span>
            </div>
          </button>
          <button
            className="ssi-button ssi-control border-0 p-1 AlignLeftLoadableButton ar-logout-button "
            onClick={() => {
              setDeleteModalStatus(true);
            }}
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faTrashAlt} />
              <span>
                <FormattedMessage id="DELETE" />
              </span>
            </div>
          </button>
        </div>
      </Popup>
      <div
        onClick={() => {
          if (index !== 0) optionToggle();
        }}
        ref={OptionButtonRef}
        style={{
          borderTopLeftRadius: index === 0 ? 3 : 0,
          borderTopRightRadius: index === length - 1 ? 3 : 0
        }}
        className={classnames(
          "air-page-view-tab p-2 border-top border-right fw-medium text-color-link active-view",
          {
            "border-left": index === 0
          }
        )}
      >
        {index === 0 && (
          <>
            <PageViewTypeIconComponent view={view} />
            <div className="text-truncate flex-1 ml-2">{resolvedName}</div>
          </>
        )}
        {index !== 0 && (
          <div className="d-flex align-items-center">
            <PageViewTypeIconComponent view={view} />
            <div className="text-truncate flex-1 ml-2 mr-2">{resolvedName}</div>
            <div>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const PageHeaderViewMoreItem = ({ viewItem, index, length, schema }) => {
  const { name, id, search } = viewItem;
  const history = useHistory();
  const space = useSpace();
  const handleKeyChange = useContext(PageViewsHiddenOptionsIncrementerContext);
  // const updateviews = useContext(ServerAwareUpdateViewsContext);
  const handleViewClick = () => {
    //Filters Layout Change

    let gridSavedViews = JSON.parse(
      window.localStorage.getItem(`ar-grid-saved-views-space-${space.Id}`)
    );
    const viewGridViews = gridSavedViews[schema.name];
    const index = viewGridViews.findIndex((e) => e.id === id);

    if (index !== -1) {
      viewGridViews.splice(index, 1);
      viewGridViews.splice(1, 0, viewItem);
      gridSavedViews[schema.name] = viewGridViews;
      localStorage.setItem(
        `ar-grid-saved-views-space-${space.Id}`,
        JSON.stringify(gridSavedViews)
      );
      // updateviews();
    }

    const parsed = querystring.parse(search);
    parsed.viewPref = id;
    history.push({
      search: querystring.stringify(parsed)
    });
    if (handleKeyChange) handleKeyChange();
  };

  return (
    <Button onClick={handleViewClick} vType="link">
      <div className="text-truncate">{name}</div>
    </Button>
  );
};

const PageViewTypeIconComponent = ({ view }) => {
  switch (view) {
    case "timeline":
      return <FontAwesomeIcon icon={faStream} />;
    case "board":
      return <FontAwesomeIcon icon={faColumns} />;
    case "calendar":
      return <FontAwesomeIcon icon={faCalendarAlt} />;
    case "cards":
      return <FontAwesomeIcon icon={faTableCellsLarge} />;
    default:
      return <FontAwesomeIcon icon={faList} />;
  }
};

// const SchemaNameIntlDictionary = {
//   SpaceContracts: "DEFAULT_VIEW_CONTRACTS",
//   SpaceClients: "DEFAULT_VIEW_ACCOUNTS",
//   SpaceContacts: "DEFAULT_VIEW_CONTACTS",
//   SpaceDeals: "DEFAULT_VIEW_DEALS",
//   SpaceSubscriptions: "DEFAULT_VIEW_SUBSCRIPTIONS",
//   SpaceClassificações: "DEFAULT_VIEW_CLASSIFICATIONS",
//   SpaceBilling: "DEFAULT_VIEW_BILLING",
//   SpaceCalls: "DEFAULT_VIEW_CALLS",
//   SpaceProjects: "DEFAULT_VIEW_PROJECTS",
//   SpaceTickets: "DEFAULT_VIEW_TICKETS",
//   SpaceTasks: "DEFAULT_VIEW_TASKS",
//   SpaceInterventions: "DEFAULT_VIEW_TIMES",
//   SpaceSocialPosts: "DEFAULT_VIEW_PUBLICATIONS",
//   SpaceEmailSignature: "DEFAULT_VIEW_EMAIL_SIGNATURES"
// };

const PageHeaderViewItem = ({ viewItem, index, length, schema }) => {
  const saveToLocalStorageKey = (newSearch, key) => {
    const name = key;

    const lastUsedSpace = localStorage.getItem(LastUsedSpaceLSKey);

    const NewDictString = localStorage.getItem(
      NewMainViewsSearchLSKey + lastUsedSpace
    );
    const NullNewDictString = localStorage.getItem(
      NewMainViewsSearchLSKey + "null"
    );
    const searchDictString = localStorage.getItem(MainViewsSearchLSKey);

    let searchDict;

    if (NewDictString) searchDict = JSON.parse(NewDictString);
    else if (NullNewDictString) {
      localStorage.setItem(
        NewMainViewsSearchLSKey + lastUsedSpace,
        NullNewDictString
      );
      localStorage.removeItem(NewMainViewsSearchLSKey + "null");
      searchDict = JSON.parse(NullNewDictString);
    } else if (searchDictString) {
      searchDict = JSON.parse(searchDictString);
    } else searchDict = {};

    const parsedNewSearch = querystring.parse(newSearch);
    const resolvedNewSearch = HandleQueryStringObjectMaker(parsedNewSearch);
    searchDict[name] = querystring.stringify({
      ...resolvedNewSearch
    });
    localStorage.setItem(
      NewMainViewsSearchLSKey + lastUsedSpace,
      JSON.stringify(searchDict)
    );
    MainViewsSearchCommunicator.dispatch(searchDict);
  };

  const { name, id, search } = viewItem;
  const history = useHistory();
  const parsedSearch = querystring.parse(search);
  const intl = useIntl();
  const { view } = parsedSearch;

  const handleViewClick = () => {
    //Filters Layout Change
    const parsed = querystring.parse(search);
    parsed.viewPref = id;
    const stringed = querystring.stringify(parsed);
    history.push({
      search: stringed
    });

    saveToLocalStorageKey(stringed, schema.name);
  };

  let resolvedName = "";
  if (name === "Default View") {
    resolvedName = `${intl.formatMessage({
      id: "DEFAULT_VIEW"
    })}`;
  } else {
    resolvedName = name;
  }

  return (
    <div
      id={id}
      onClick={handleViewClick}
      style={{
        borderTopLeftRadius: index === 0 ? 3 : 0,
        borderTopRightRadius: index === length - 1 ? 3 : 0
      }}
      className={classnames(
        "air-page-view-tab p-2 border-top border-right text-black bg-white",
        {
          "border-left": index === 0
        }
      )}
    >
      <PageViewTypeIconComponent view={view} />
      <div className="text-truncate flex-1 ml-2">{resolvedName}</div>
    </div>
  );
};

export const HeaderTitle = ({ title, toggleFilterList }) => {
  const favoriteItem = useContext(ServerAwareFavoritesContext);

  if (favoriteItem) {
    return (
      <div className="overflow-hidden flex-1">
        <div
          className={classnames("fs-18 mt-1 text-black text-truncate", {
            "ml-3": toggleFilterList
          })}
          style={{ lineHeight: "20px" }}
        >
          {favoriteItem.data.title}
        </div>
        <div
          className={classnames("text-black fs-12 text-truncate", {
            "ml-3": toggleFilterList
          })}
        >
          {title}
        </div>
      </div>
    );
  } else
    return (
      <span
        className={classnames("fs-22 mt-1 text-black w-100 text-truncate", {
          "ml-3": toggleFilterList
        })}
      >
        {title}
      </span>
    );
};

const PageHeader = (props) => {
  const {
    title,
    children,
    className,
    settings,
    toggleFilterList,
    schema,
    layoutName,
    unreadOptions,
    justViews,
    noTitle
    // handleMultipleDelete
  } = props;

  const [viewModalStatus, setViewModalStatus] = useState(false);
  const closeViewModal = () => {
    setViewModalStatus(false);
  };

  const openViewModal = () => {
    setViewModalStatus(true);
  };
  const lsViews = useContext(ServerAwareViewsContext);
  const ReorderChangesRef = useRef();

  return (
    <ReorderChangesContext.Provider value={ReorderChangesRef}>
      {lsViews && schema && (
        <Modal isOpen={viewModalStatus} onClose={closeViewModal}>
          <ViewSaver
            schema={schema}
            onClose={closeViewModal}
            layoutName={layoutName}
          />
        </Modal>
      )}
      {lsViews && schema && (
        <PageHeaderViewBar openViewModal={openViewModal} schema={schema} />
      )}
      {justViews ? (
        children
      ) : (
        <div
          className={classnames(
            "d-flex",
            { innerHeader: !settings, innerHeaderSettings: settings },
            className
          )}
        >
          {(toggleFilterList || title) && (
            <div className="align-items-center align-items-md-end d-flex justify-content-center overflow-hidden">
              {toggleFilterList && (
                <ServerAwareFilterListToggler className="h-100 m-auto" />
              )}
              {title && (
                <HeaderTitle
                  toggleFilterList={toggleFilterList}
                  title={title}
                />
              )}
            </div>
          )}
          {noTitle && <div className="d-none"></div>}
          <div className="flex-1 h-100 d-flex align-items-center justify-content-between ">
            {unreadOptions && (
              <div className="d-flex align-items-center">
                <UnreadOptionsButton
                  Text={unreadOptions.Text}
                  IsService={unreadOptions.IsService}
                  disableReadOptions={unreadOptions.disableReadOptions}
                  handleDelete={unreadOptions.EnableDeleteAll}
                >
                  {unreadOptions.Children}
                </UnreadOptionsButton>
              </div>
            )}
            {children}
          </div>
        </div>
      )}
    </ReorderChangesContext.Provider>
  );
};

export default PageHeader;
