import React, { useMemo } from "react";
import AccountProfile from "../Accounts/AccountProfile";
import { useTeam } from "../../Hooks/EntityHooks";
import { useSpaceQuery } from "../../Helpers/IOClient";
import { spaceTeamSchema } from "../../config/schema";

const TeamProfile = ({ team, ...rest }) => {
  return (
    <AccountProfile
      account={team}
      Manager={team && team.Managers && team.Managers[0]}
      {...rest}
    />
  );
};

export default TeamProfile;

export const ConnectTeamEntity = (Component) => {
  const Comp = ({ team, ...rest }) => {
    const t = useTeam(team);

    const url = useMemo(() => {
      if (t === null) {
        return `query/teams?query=${team}`;
      } else return null;
    }, [t, team]);

    const { loading, error } = useSpaceQuery(url, [spaceTeamSchema]);

    if (loading || error) return null;

    return <Component team={t} {...rest} />;
  };
  return Comp;
};

export const ConnectedTeamProfile = ConnectTeamEntity(TeamProfile);
