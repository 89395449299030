import React, { useState } from "react";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent
} from "../../../Components/Sidebar/SidebarV2Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./ReceptionNewConvForm.module.css";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import classnames from "classnames";

import ReceptionNewConvEmailForm from "./ReceptionNewConvEmailForm";
import { FormattedMessage } from "react-intl";
import { ConversationTypeEnum } from "../ReceptionHelpers";

const Card = ({ type, icon, children, setConvType }) => {
  const handleClick = () => {
    setConvType(type);
  };

  return (
    <div
      onClick={handleClick}
      className={classnames(
        "border rounded d-flex align-items-center p-2",
        classes.card
      )}
    >
      <div
        className={classnames(
          "d-flex align-items-center justify-content-center rounded",
          classes.cardIcon
        )}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="ml-2 text-black">{children}</div>
    </div>
  );
};

const ReceptionNewConvSelector = ({ setConvType }) => {
  return (
    <div className="py-4 px-3">
      <div className="fw-medium text-black mb-2">
        <FormattedMessage id="CREATE" />
      </div>
      <Card
        type={ConversationTypeEnum.Email}
        icon={faEnvelope}
        setConvType={setConvType}
      >
        Email
      </Card>
    </div>
  );
};

export const ReceptionDuplicateConvForm = ({ setResponse, ...rest }) => {
  return <ReceptionNewConvEmailForm setResponse={setResponse} {...rest} />;
};

const ReceptionNewConvForm = ({ setResponse, ...rest }) => {
  const [convType, setConvType] = useState();

  if (convType) {
    switch (convType) {
      case ConversationTypeEnum.Email:
        return (
          <ReceptionNewConvEmailForm setResponse={setResponse} {...rest} />
        );

      default:
        return null;
    }
  }

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id="NEW_CONVERSATION" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <ReceptionNewConvSelector setConvType={setConvType} />
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export default ReceptionNewConvForm;
