import React, { useMemo } from "react";
import {
  accountPermissions,
  isAdmin,
  isOwner,
  modulePermissions,
  moduleTypes as mt
} from "../../../../Helpers/ModulesHelper";
import { FormattedMessage, useIntl } from "react-intl";
import LoadableButton from "../../../../Components/Button/LoadableButton";
import {
  getEntity,
  useSpacePost,
  useSpaceQuery
} from "../../../../Helpers/IOClient";
import {
  accountSchema,
  accountSummarySpaceSchema,
  spaceSchema,
  spaceUserCategoriesSchema,
  UserDocumentType
} from "../../../../config/schema";
import { handleError } from "../../../../Helpers/MiscHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/pro-light-svg-icons";
import { useToast } from "../../../../Components/Toast/ToastProvider";
import { useCurrentAccountSpace } from "../../../../Contexts/UserContext";
import { PrintHtml } from "./PrintUserDataModel";
import { useAccount, useCurrency } from "../../../../Hooks/EntityHooks";
import { useSpace } from "../../../../Contexts/SpaceContext";
import print from "print-js";
// export const PermissionReportsModuleTypes = [
//   {
//     type: mt.reportsInterventions,
//     translationId: "reportsInterventions",
//     isService: true
//   },
//   { type: mt.reportsTickets, translationId: "reportsTickets", isReport: true }
//   //   { type: mt.settingsDeals, translationId: "deals", isService: false },
//   //   { type: mt.settingsProjects, translationId: "projects", isService: false },
//   //   { type: mt.settingsContracts, translationId: "contracts", isService: false },
// ];

export const PermissionModuleTypes = [
  { textId: "CONTACTS", isService: true, isHeader: true },
  {
    type: "clients",
    isService: true,
    translationId: "ACCOUNTS",
    permissions: {
      view: [
        modulePermissions.ReadAll,
        modulePermissions.PrivateReadOnly,
        modulePermissions.Default
      ],
      edit: [
        modulePermissions.EditAll,
        modulePermissions.PrivateEditOnly,
        modulePermissions.Default
      ],
      delete: [
        modulePermissions.DeleteAll,
        modulePermissions.PrivateDeleteOnly,
        modulePermissions.Default
      ]
    }
  },
  {
    type: "contacts",
    isService: true,
    translationId: "CONTACTS",
    permissions: {
      view: [
        modulePermissions.ReadAll,
        modulePermissions.PrivateReadOnly,
        modulePermissions.Default
      ],
      edit: [
        modulePermissions.EditAll,
        modulePermissions.PrivateEditOnly,
        modulePermissions.Default
      ],
      delete: [
        modulePermissions.DeleteAll,
        modulePermissions.PrivateDeleteOnly,
        modulePermissions.Default
      ]
    }
  },
  { textId: "COMMERCIAL", isService: true, isHeader: true },
  { type: "deals", isService: true, translationId: "DEALS" },
  { type: "contracts", isService: true, translationId: "CONTRACTS" },
  { type: "subscriptions", isService: true, translationId: "SUBSCRIPTIONS" },
  {
    type: "classification",
    translationId: "CLASSIFICATIONS",
    isService: true,
    PermissionsComponent: () => {
      return (
        <span className="text-primary">
          <FormattedMessage id={"TIME_EQUIVALENT"} />
        </span>
      );
    }
  },
  {
    type: "billing",
    isService: true,
    translationId: "BILLING",
    permissions: {
      view: [
        modulePermissions.ReadAll,
        modulePermissions.OrganizationReadOnly,
        modulePermissions.Default
      ],
      edit: [
        modulePermissions.EditAll,
        modulePermissions.OrganizationEditOnly,
        modulePermissions.Default
      ],
      delete: [
        modulePermissions.DeleteAll,
        modulePermissions.OrganizationDeleteOnly,
        modulePermissions.Default
      ]
    }
  },
  { textId: "SERVICES", isService: true, isHeader: true },
  { type: "calls", isService: true, translationId: "CALLS" },
  { type: "projects", isService: true, translationId: "PROJECTS" },
  { type: "tickets", isService: true, translationId: "CASES" },
  { type: "tasks", isService: true, translationId: "TASKS" },
  { type: "interventions", isService: true, translationId: "TIMES" },

  { textId: "MARKETING", isService: true, isHeader: true },
  {
    type: mt.social,
    translationId: "SOCIAL_MEDIA_ACCOUNTS",
    isService: true,
    noExport: true,
    noMenu: true
  },
  {
    type: mt.socialPost,
    translationId: "PUBLICATIONS",
    isService: true,
    noExport: true
  },
  {
    type: mt.emailSignatures,
    translationId: "EMAIL_SIGNATURES",
    isService: true,
    noExport: true
  },
  { textId: "PROFILE", isService: true, isHeader: true },
  {
    type: "organizations",
    isService: true,
    noExport: true,
    noMenu: true,
    translationId: "COMPANIES"
  },
  {
    type: "departments",
    isService: true,
    noExport: true,
    noMenu: true,
    translationId: "DEPARTMENTS"
  },
  {
    type: "teams",
    isService: true,
    noExport: true,
    noMenu: true,
    translationId: "TEAMS"
  },
  {
    type: "users",
    isService: true,
    noExport: true,
    noMenu: true,
    translationId: "USERS"
  },
  { textId: "INBOX", isService: true, isHeader: true },
  {
    type: "emailConversations",
    isService: true,
    // noExport: true,
    translationId: "EMAILS"
  },
  { textId: "WORKFLOW", isService: true, isHeader: true },
  {
    type: "automation",
    isService: true,
    noExport: true,
    translationId: "AUTOMATION"
  },
  { textId: "DOCUMENTS", isService: true, isHeader: true },
  {
    type: "templates",
    isService: true,
    noExport: true,
    translationId: "TEMPLATES",
    permissions: {
      view: [
        modulePermissions.ReadAll,
        modulePermissions.OrganizationReadOnly,
        modulePermissions.Default
      ],
      edit: [
        modulePermissions.EditAll,
        modulePermissions.OrganizationEditOnly,
        modulePermissions.Default
      ],
      delete: [
        modulePermissions.DeleteAll,
        modulePermissions.OrganizationDeleteOnly,
        modulePermissions.Default
      ]
    }
  },
  {
    type: "documents",
    isService: true,
    noExport: true,
    translationId: "DOCUMENTS",
    permissions: {
      view: [
        modulePermissions.ReadAll,
        modulePermissions.OrganizationReadOnly,
        modulePermissions.Default
      ],
      edit: [
        modulePermissions.EditAll,
        modulePermissions.OrganizationEditOnly,
        modulePermissions.Default
      ],
      delete: [
        modulePermissions.DeleteAll,
        modulePermissions.OrganizationDeleteOnly,
        modulePermissions.Default
      ]
    }
  },
  { textId: "SPACE", isService: false, isHeader: true },
  {
    type: "settingsSpace",
    isService: false,
    translationId: "GERAL",
    noExport: true
  },
  {
    type: mt.settingsOrganizations,
    translationId: "COMPANIES",
    isService: false,
    noExport: true
  },
  {
    type: mt.settingsDepartments,
    translationId: "DEPARTMENTS",
    isService: false,
    noExport: true
  },
  {
    type: mt.settingsTeams,
    translationId: "TEAMS",
    isService: false,
    noExport: true
  },
  {
    type: mt.settingsUsers,
    translationId: "USERS",
    isService: false,
    noExport: true
  },
  {
    type: mt.settingsWidget,
    translationId: "WIDGETS",
    isService: false,
    noExport: true
  },
  {
    type: mt.apiKeySettings,
    translationId: "API_KEYS",
    isService: false,
    noExport: true
  },

  { textId: "COMERCIAL", isService: false, noExport: true, isHeader: true },
  {
    type: mt.settingsClient,
    translationId: "ACCOUNTS",
    isService: false,
    noExport: true
  },
  {
    type: mt.settingsDeals,
    translationId: "DEALS",
    isService: false,
    noExport: true
  },
  {
    type: mt.settingsContracts,
    translationId: "CONTRACTS",
    isService: false,
    noExport: true
  },
  {
    type: mt.settingsSubscriptions,
    translationId: "SUBSCRIPTIONS",
    isService: false,
    noExport: true
  },
  { textId: "SERVICES", isService: false, noExport: true, isHeader: true },
  {
    type: mt.settingsCalls,
    translationId: "CALLS",
    isService: false,
    noExport: true
  },
  {
    type: mt.settingsProjects,
    translationId: "PROJECTS",
    isService: false,
    noExport: true
  },
  {
    type: mt.settingsTickets,
    translationId: "CASES",
    isService: false,
    noExport: true
  },
  {
    type: mt.settingsTasks,
    translationId: "TASKS",
    isService: false,
    noExport: true
  },
  {
    type: mt.SettingsTimeCharge,
    translationId: "TIMES",
    isService: false,
    noExport: true
  },
  // { textId: "DOCUMENTS", isService: false, noExport: true, isHeader: true },
  // {
  //   type: mt.settingsDocuments,
  //   translationId: "DOCUMENTS",
  //   isService: false,
  //   noExport: true
  // },
  { textId: "MARKETING", isService: false, noExport: true, isHeader: true },
  {
    type: mt.settingsSocial,
    translationId: "SOCIAL_MEDIA_ACCOUNTS",
    isService: false,
    noExport: true
  },
  {
    type: mt.emailSignatures,
    translationId: "EMAIL_SIGNATURES",
    isService: false,
    noExport: true
  },
  { textId: "INBOX", isService: false, noExport: true, isHeader: true },
  {
    type: "settingsInboundEmails",
    isService: false,
    noExport: true,
    translationId: "EMAILS"
  },
  {
    type: "settingsDomains",
    isService: false,
    noExport: true,
    translationId: "DOMAINS"
  },
  { textId: "DOCUMENTS", isService: false, noExport: true, isHeader: true },
  {
    type: mt.settingsDocuments,
    translationId: "DOCUMENTS",
    isService: false,
    noExport: true
  },
  // {
  //   type: mt.contractTypes,
  //   translationId: "type_of_contract",
  //   isService: false
  // },
  // {
  //   type: mt.interventionTypes,
  //   translationId: "intervention_types",
  //   isService: false
  // },
  // { type: mt.projectTypes, translationId: "type_of_project", isService: false },
  // {
  //   type: mt.cancelationReasons,
  //   translationId: "cancelation_reason",
  //   isService: false
  // },
  {
    type: mt.reportsTickets,
    translationId: "CASES",
    isReport: true
  },
  {
    type: mt.reportsInterventions,
    translationId: "TIMES",
    isReport: true
  },
  {
    type: mt.reportsClassification,
    translationId: "CLASSIFICATION",
    isReport: true
  },
  { type: mt.reportsTasks, translationId: "TASKS", isReport: true },
  { type: mt.reportsDeals, translationId: "DEALS", isReport: true },
  { type: mt.reportsProjects, translationId: "PROJECTS", isReport: true },
  { type: mt.reportsContracts, translationId: "CONTRACTS", isReport: true },

  { type: "configurations", translationId: "SETTINGS" }
];

export const defaultPermissionConfig = {
  view: [
    modulePermissions.ReadAll,
    modulePermissions.OrganizationReadOnly,
    modulePermissions.DepartementReadOnly,
    modulePermissions.TeamReadOnly,
    modulePermissions.PrivateReadOnly,
    modulePermissions.Default
  ],
  edit: [
    modulePermissions.EditAll,
    modulePermissions.OrganizationEditOnly,
    modulePermissions.DepartementEditOnly,
    modulePermissions.TeamEditOnly,
    modulePermissions.PrivateEditOnly,
    modulePermissions.Default
  ],
  delete: [
    modulePermissions.DeleteAll,
    modulePermissions.OrganizationDeleteOnly,
    modulePermissions.DepartementDeleteOnly,
    modulePermissions.TeamDeleteOnly,
    modulePermissions.PrivateDeleteOnly,
    modulePermissions.Default
  ]
};

export const PrintPrivateDataButton = ({ id }) => {
  const currentUser = useCurrentAccountSpace();
  const intl = useIntl();
  const isO = isOwner(currentUser);
  const isA = isAdmin(currentUser);
  const space = useSpace();
  const { Symbol } = useCurrency(space.Currency);
  const createToast = useToast();

  const [postPrint, { loading: printLoading }] = useSpacePost(
    `Settings/Users/Pdf`,
    null,
    {
      cache: false,
      onSuccess: ({ data }) => {
        let binaryString = window.atob(data);

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        print(url);
      }
    }
  );

  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: userRefetch
  } = useSpaceQuery(`settings/users/${id}`, accountSchema, {
    autoFetch: false,
    onSuccess: ({ data }) => {
      const resolvedData = getEntity(accountSchema, data);
      const { AccountSpaces, Id } = resolvedData;
      const AccountSpace = getEntity(
        accountSummarySpaceSchema,
        AccountSpaces[0]
      );
      const Category = getEntity(
        spaceUserCategoriesSchema,
        resolvedData.Category
      );
      const AccountDocumentType = getEntity(
        UserDocumentType,
        AccountSpace.AccountDocumentType
      );
      const user = getEntity(accountSchema, Id);
      let printBase = `${PrintHtml}`;

      const translationTags = printBase.match(/\{\w+\}/gm);

      for (const t of translationTags) {
        let subT = t.substr(1, t.length - 2);
        printBase = printBase.replace(t, intl.formatMessage({ id: subT }));
      }

      const valueTags = printBase.match(/\{\[\w+\]\}/gm);

      for (const v of valueTags) {
        const resV = v.substr(2, v.length - 4);
        switch (resV) {
          case "NAME":
            printBase = printBase.replace(v, user?.Name || "");
            break;
          case "IMAGE_URL":
            printBase = printBase.replace(v, user?.Image || "");
            break;
          case "Category":
            const c = Category || {};
            printBase = printBase.replace(v, c?.Name || "");
            break;
          case "AccountDocumentType":
            const adt = AccountDocumentType || {};
            printBase = printBase.replace(
              v,
              adt?.Key ? intl.formatMessage({ id: adt.Key }) : ""
            );
            break;
          case "StandardCost":
            const resCost = AccountSpace?.StandardCost
              ? `${AccountSpace?.StandardCost} ${Symbol}`
              : "";
            printBase = printBase.replace(v, resCost);
            break;
          case "BIExpirationDate":
          case "BIIssueDate":
            const resDate = intl.formatDate(AccountSpace[resV]);
            printBase = printBase.replace(v, resDate || "");
            break;
          default:
            printBase = printBase.replace(
              v,
              AccountSpace[resV] || resolvedData[resV] || ""
            );
            break;
        }
      }

      postPrint({
        SpaceId: space.Id,
        AccountId: user.Id,
        Html: printBase
      });
    },
    onError: ({ error }) => {
      handleError(createToast, error);
    }
  });

  const resolvedLoading = useMemo(() => {
    return userLoading || printLoading;
  }, [printLoading, userLoading]);

  if (
    isO ||
    Boolean(
      currentUser.SpacePermissions & accountPermissions.ViewUserPrivateData
    )
  ) {
    return (
      <LoadableButton
        isLoading={resolvedLoading}
        vType={"primary-ghost"}
        className="px-2 py-1 mr-3"
        size="sm"
        onClick={() => {
          userRefetch();
        }}
      >
        <FontAwesomeIcon icon={faPrint} />
      </LoadableButton>
    );
  }

  return null;
};
