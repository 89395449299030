import { getCookie, setCookie } from "./CookiesHelper";

export const getCurrentUserAuthToken = () => {
  let tkn = getAlterEgo() || getCookie("tkn");

  if (!tkn || tkn === "") return null;

  return tkn;
};

export const removeCurrentUserAuthToken = () => {
  if (getAlterEgo()) setCookie("ego_tkn", "", -1);
  else setCookie("tkn", "", -1);
};

export const setCurrentUserAuthToken = (token) => {
  setCookie("tkn", token, 360);
};

export const getAlterEgo = () => {
  return getCookie("ego_tkn");
};

export const setAlterEgo = (token) => {
  setCookie("ego_tkn", token, 0.167);

  window.top.location.href = "/";
};

export const logout = () => {
  removeCurrentUserAuthToken();
  localStorage.removeItem("VNI-T");
  window.location.href = "/";
  // return { type: LOGOUT };
};
