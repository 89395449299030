import React, { useCallback } from "react";
import { useGridRequest } from "../../../Components/CGrid/ServerGrid";
import EmptyListError from "../../../Components/EmptyListContainer/EmptyListError";
import { useSocialPost } from "../../../Hooks/EntityHooks";
import EmptyListContainer from "../../../Components/EmptyListContainer/EmptyListContainer";

import VirtualServerAwareGrid from "../../../Components/VirtualGrid/VirtualServerAwareGrid";
import { VirtualGridRow } from "../../../Components/VirtualGrid/VirtualGrid";
import { socialColumns, SocialPostFormButton } from "./SocialPostsOverview";
import { moduleTypes } from "../../../Helpers/ModulesHelper";
import { FormattedMessage } from "react-intl";

const SocialPostsRow = React.memo(({ value, ...rest }) => {
  const v = useSocialPost(value);
  const canSelectRow = useCallback((v) => !v.Task, []);
  return (
    <VirtualGridRow
      formType={moduleTypes.socialPost}
      className={v && v.IsFollowing && v.IsRead === false && "unread"}
      canSelectRow={canSelectRow}
      value={v}
      {...rest}
    />
  );
});
const canSelectRow = (value) => {
  if (!value) return false;

  return true;

  // const state = store.getState();

  // const intervention = getEntityFromState(
  //   state,
  //   spaceInterventionsSchema,
  //   value
  // );

  // return !intervention.Billing;
};

const SocialPostsGrid = ({ onItemSelect, columns, ...rest }) => {
  const { error, results } = useGridRequest();

  if (error) return <EmptyListError />;

  if (results && results.length === 0)
    return (
      <EmptyListContainer type="socialPosts">
        <div className="fw-bold fs-24 text-black ">
          <FormattedMessage id="EMPTY_SOCIAL_POST_LIST" />
        </div>
        <div className="fs-18 text-black text-center mb-4">
          <FormattedMessage id="NO_SOCIAL_POST_LIST" />
        </div>
        <SocialPostFormButton />
      </EmptyListContainer>
    );

  return (
    <VirtualServerAwareGrid
      sortable
      showTotals
      onSelectedItemsChange={onItemSelect}
      canSelectRow={canSelectRow}
      rowComponent={SocialPostsRow}
      columns={socialColumns}
      {...rest}
    />
  );
};

export default SocialPostsGrid;
