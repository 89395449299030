import React, { useState, useCallback, useMemo } from "react";

import { createFormRequestHook } from "../../Components/Forms";
import {
  spaceSocialPostsSchema,
  spaceSocialAccountSchema
} from "../../config/schema";
import {
  convertSocialPostEntityToForm,
  defaultSocialPostForm,
  PostStatusProfile
} from "./SocialPostsHelper";
import { useSocialPost } from "../../Hooks/EntityHooks";

import { useToast } from "../../Components/Toast/ToastProvider";
import {
  entityActionType,
  handleError,
  toIsoString
} from "../../Helpers/MiscHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faChevronDoubleRight } from "@fortawesome/pro-light-svg-icons";

import {
  BaseSidebarHeader,
  useSidebarSpacePost,
  BaseSidebarContainer,
  BaseSidebarContent
} from "../../Components/Sidebar/SidebarV2Helper";
import classnames from "classnames";

import "./SocialPosts.css";

import Button from "../../Components/Button/Button";

// import Picker from "emoji-picker-react";

import LoadableButton from "../../Components/Button/LoadableButton";
import { FormattedMessage } from "react-intl";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";

import { SingleSocial } from "../../Components/SocialPostSingleComponent/SocialPostSingleComponent";
import {
  getEntity,
  useSpaceDelete,
  useSpaceQuery
} from "../../Helpers/IOClient";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import { useEntityRoom } from "../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimeRoomTypes } from "../RealTime/RealTimeHelper";
import moment from "moment";
import {
  SidebarVisualTypeEnum,
  useSidebarView
} from "../../Components/Sidebar/SidebarViewsHelper";

import { v1 as uuidv1 } from "uuid";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { isOrganzationRequestValid } from "../../Components/Organizations/OrganizationHelper";
import { SocialAccountsRequestSideError } from "../../Components/SocialAccountComponenent/SocialAccountComponent";
import { socialPostCommunicator } from "./socialPostCommunicator";
const regex = /https?:\/\/[-A-Za-z0-9+&@#/%?=~_|$!:,.;]*/g;

const useSocialPostRequest = createFormRequestHook({
  schema: spaceSocialPostsSchema,
  dependencies: [
    {
      schema: spaceSocialPostsSchema,
      getEntityId: ({ clientId }) => clientId,
      convertEntityToForm: (entity, props) => {
        const { Id } = entity;
        return {
          Client: Id
        };
      }
    }
  ],
  convertEntityToForm: convertSocialPostEntityToForm
});

const SocialPostForm = (props) => {
  const { key, loading, error, convertedForm, isChild } =
    useSocialPostRequest(props);

  const { id, onSuccess, duplicationForm, refetchRef } = props;

  const socialPost = useSocialPost(id);

  const resolvedConvertedForm = useMemo(() => {
    if (!convertedForm) return null;

    if (duplicationForm)
      return {
        ...convertedForm,
        Id: null,
        Status: null,
        duplicationId: (duplicationForm && duplicationForm.Id) || null
      };

    return convertedForm;
  }, [convertedForm, duplicationForm]);

  const canEdit = socialPost ? socialPost.CanEdit || false : true;
  const canDelete = socialPost ? socialPost.CanDelete || false : false;

  const socialAccountsState = useSpaceQuery(
    `Query/connections?%24top=50&istwitterallowed=true`,
    [spaceSocialAccountSchema],
    { cache: false }
  );

  const { loading: loadingSocialNetWorks } = socialAccountsState;

  if (loading || error || loadingSocialNetWorks)
    return (
      <BaseSidebarContainer width={520}>
        <BaseSidebarHeader className="flex-0-0-auto">
          <FormattedMessage id="PUBLICATIONS" />
        </BaseSidebarHeader>
        <BaseSidebarContent>
          <LoaderSpinner center size="sm" className="text-secondary" />;
        </BaseSidebarContent>
      </BaseSidebarContainer>
    );

  if (!isOrganzationRequestValid(socialAccountsState))
    return (
      <BaseSidebarContainer width={520}>
        <BaseSidebarHeader className="flex-0-0-auto">
          <FormattedMessage id="PUBLICATIONS" />
        </BaseSidebarHeader>
        <BaseSidebarContent>
          <SocialAccountsRequestSideError requestState={socialAccountsState} />
        </BaseSidebarContent>
      </BaseSidebarContainer>
    );

  //;
  return (
    <SocialPostsFormBase
      isChild={isChild}
      refetchRef={refetchRef}
      convertedForm={resolvedConvertedForm}
      onSuccess={onSuccess}
      id={id}
      canEdit={canEdit}
      canDelete={canDelete}
      key={key}
    />
  );
};

const SocialPostsFormBase = ({
  convertedForm = {},
  id,
  organizations,
  pipelines,
  canEdit,
  canDelete,
  onSuccess,
  refetch,
  currencies,
  refetchRef
}) => {
  const [globalState, setGlobalState] = useState(
    (convertedForm && convertedForm.Status) || 4
  );
  const duplicatedPost = useSocialPost(
    convertedForm && convertedForm.duplicationId
  );
  const [postsState, setPostsState] = useState(() => {
    if (convertedForm && convertedForm.duplicationId) {
      return [
        {
          key: uuidv1(),
          state: {
            ...defaultSocialPostForm(),
            ...convertedForm,
            Thumbnail: {
              Url: duplicatedPost.Thumbnail.Url
            },
            FilesUnsorted: Array.isArray(duplicatedPost.Files)
              ? duplicatedPost.Files.map((e) => {
                  return {
                    ContentType: e.ContentType,
                    Title: e.Title,
                    Url: e.Url
                  };
                })
              : []
          }
        }
      ];
    } else
      return [
        {
          key: uuidv1(),
          state: {
            ...defaultSocialPostForm(),
            ...convertedForm
          }
        }
      ];
  });

  const createToast = useToast();
  //;
  useEntityRoom(RealTimeRoomTypes.SocialPost, id);

  const [post, { loading }] = useSidebarSpacePost(
    id ? `Social/post/${id}` : "Social/post",
    [spaceSocialPostsSchema],
    {
      refetchRef: id ? undefined : refetchRef,
      onSuccess: ({ openSidebar, data, defineChildProps }) => {
        onSuccess && onSuccess();
        // ;
        if (!id) {
          for (const postId of data) {
            socialPostCommunicator.dispatch({
              type: entityActionType.creation,
              id: postId
            });
          }
        } else {
          for (const post of data) {
            socialPostCommunicator.dispatch({
              type: entityActionType.update,
              id: post
            });
          }
        }

        createToast({
          pos: "tm",
          type: "success",
          title: `${
            data.length > 1
              ? `Publicações ${data.join(", ")}`
              : `Publicação ${data.join("")}`
          }`,
          description:
            data.length > 1 ? `Colocadas com Sucesso` : `Colocada com Sucesso`
        });
        refetchRef && refetchRef.current && refetchRef.current();
      },
      onError: ({ error }) => {
        handleError(createToast, error);
      }
    }
  );

  const validateForm = () => {
    let report = false;
    const tempPostsStates = [...postsState];
    tempPostsStates.forEach((e) => {
      const { SocialId, Text } = e.state;
      if (!SocialId) {
        e.state.hasError = true;
        report = true;
      }
      if (SocialId && Array.isArray(SocialId)) {
        SocialId.forEach((t) => {
          const { Type } = getEntity(spaceSocialAccountSchema, t);

          if (
            Type === SocialNetworkDict_Reverse.Facebook ||
            Type === SocialNetworkDict_Reverse.Facebook_two
          ) {
            if (Text && Text.length > 2200) {
              e.state.hasError = true;
              report = true;
            }
          } else if (Type === SocialNetworkDict_Reverse.Twitter) {
            if (Text && Text.length > 280) {
              e.state.hasError = true;
              report = true;
            }
          } else if (
            Type === SocialNetworkDict_Reverse.LinkedIn ||
            Type === SocialNetworkDict_Reverse.LinkedIn_two
          ) {
            if (Text && Text.length > 2200) {
              e.state.hasError = true;
              report = true;
            }
          } else if (Type === SocialNetworkDict_Reverse.Instagram) {
            if (Text && Text.length > 2200) {
              e.state.hasError = true;
              report = true;
            }
          }
        });
      }
      //   if (!e.state.Text) {
      //     e.state.hasError = true;
      //     report = true;
      //   }
      //   if (e.state.Text && e.state.Text.length === 0) {
      //     e.state.hasError = true;
      //     report = true;
      //   }
    });
    if (report) {
      setPostsState(tempPostsStates);
    }
    return report;
  };

  const handleSubmiting = () => {
    const hasErrors = validateForm();
    if (hasErrors) return;

    let PostImages = postsState.map((e, i) => {
      const tempArray = e.state.FilesUnsorted || [];
      const tempFiles = tempArray.filter((f) => {
        return !Boolean(f.Url);
      });
      tempFiles.forEach((e) => {
        e.PostId = i;
      });
      if (tempFiles.length > 0) return tempFiles;
      return null;
    });

    let ThumbnailPostImages = postsState.map((e, i) => {
      const tempThumb = e.state.Thumbnail;
      if (typeof tempThumb === "object" && !Boolean(tempThumb.Url)) {
        tempThumb.PostId = i;
        return tempThumb;
      } else return null;
    });

    const filteredThumbnails = ThumbnailPostImages.filter((e) => {
      if (e && (e.Url === undefined || (e.Url === "" && e.File))) {
        return e;
      } else return false;
    });

    const SeparatedTwitterPosts = [];

    const handleSeparationTwitterCheck = (id, index) => {
      const socialAccountTemp = getEntity(spaceSocialAccountSchema, id);
      if (socialAccountTemp.Type === SocialNetworkDict_Reverse.Twitter) {
        SeparatedTwitterPosts.push({ id, index });
        return false;
      } else {
        return true;
      }
    };

    const postsUnstrigifiedUnresolved = postsState.map((e, i) => {
      const tempArray = e.state.FilesUnsorted || [];
      const FilesUrl = tempArray
        .filter((f) => {
          return Boolean(f.Url);
        })
        .map((h) => {
          return { Id: h.Id, Url: h.Url, ContentType: h.contentType };
        });

      const Links = [...new Set(e.state.Text.match(regex))];
      const firstlink = e.state.Thumbnail
        ? e.state.Thumbnail.MetadataThumbnail
          ? e.state.Thumbnail.MetadataUrl
          : ""
        : "";
      const resolvedLinks = Links.filter((value) => {
        return value !== firstlink;
      });

      return {
        Id: e.state.Id,
        SocialIds: Array.isArray(e.state.SocialId)
          ? e.state.SocialId.length > 1
            ? e.state.SocialId.filter((sid) => {
                return handleSeparationTwitterCheck(sid, i);
              })
            : e.state.SocialId
          : [e.state.SocialId],
        Text: e.state.Text,
        Links: resolvedLinks,
        IsSchedule: !e.state.ScheduleComponent.PublishNow,
        FirstLink: firstlink !== "" ? firstlink : undefined,
        Schedule: toIsoString(moment(e.state.ScheduleComponent.Time).utc()),
        Status: globalState,
        FilesUrl: FilesUrl,
        Thumbnail:
          e.state.Thumbnail === undefined || e.state.Thumbnail === ""
            ? ""
            : {
                Id: e.state.Thumbnail.Id,
                Url: e.state.Thumbnail.Url,
                ContentType: e.state.Thumbnail.ContentType
              }
      };
    });

    const postsUnstrigifiedFromSeparatedTwitterAccounts =
      SeparatedTwitterPosts.length > 0
        ? SeparatedTwitterPosts.map((sid, i) => {
            const e = postsState[sid.index];
            const tempArray = e.state.FilesUnsorted || [];
            const FilesUrl = tempArray
              .filter((f) => {
                return Boolean(f.Url);
              })
              .map((h) => {
                return { Id: h.Id, Url: h.Url, ContentType: h.contentType };
              });

            const Links = [...new Set(e.state.Text.match(regex))];
            const firstlink = e.state.Thumbnail
              ? e.state.Thumbnail.MetadataThumbnail
                ? Links[0]
                : ""
              : "";
            const resolvedLinks = Links.filter((value) => {
              return value !== firstlink;
            });

            const metadataIndex =
              firstlink !== ""
                ? e.state.MetadataChangesData.findIndex((el) => {
                    return el.MetadataUrl === firstlink;
                  })
                : -1;

            if (metadataIndex !== -1 && e.state.Thumbnail) {
              e.state.Thumbnail.Url =
                e.state.MetadataChangesData[metadataIndex].Url;
            } else if (e.state.Thumbnail) {
              e.state.Thumbnail.Url = undefined;
            }

            return {
              SocialIds: [sid.id],
              Text: e.state.Text,
              Links: resolvedLinks,
              ImageId: sid.index,
              IsSchedule: !e.state.ScheduleComponent.PublishNow,
              FirstLink: firstlink !== "" ? firstlink : undefined,
              Schedule: toIsoString(
                moment(e.state.ScheduleComponent.Time).utc()
              ),
              Status: globalState,
              FilesUrl: FilesUrl,
              Thumbnail:
                e.state.Thumbnail === undefined || e.state.Thumbnail === ""
                  ? ""
                  : {
                      Id: e.state.Thumbnail.Id,
                      Url: e.state.Thumbnail.Url,
                      ContentType: e.state.Thumbnail.ContentType
                    }
            };
          })
        : [];

    const postsStringified = JSON.stringify(
      postsUnstrigifiedUnresolved.concat(
        postsUnstrigifiedFromSeparatedTwitterAccounts
      )
    );
    const filteredPostImages = PostImages.filter((e) => {
      return e;
    });

    const formData = new FormData();
    formData.append("Posts", postsStringified);

    for (const postImg of filteredPostImages) {
      for (const { File, PostId, Name, Extension } of postImg) {
        formData.append(`${PostId}`, File, Name + Extension);
      }
    }

    for (const postThumb of filteredThumbnails) {
      const {
        File: FileThumb,
        PostId: PostIdThumb,
        Name: NameThumb,
        Extension: ExtensionThumb
      } = postThumb;
      if (FileThumb) {
        formData.append(
          `ThumbnailFile_${PostIdThumb}`,
          FileThumb,
          NameThumb + ExtensionThumb
        );
      }
    }

    post(formData);
  };

  const handleChildrenChanges = useCallback((form, index) => {
    setPostsState((e) => {
      const tempState = [...e];
      tempState[index] = {
        ...tempState[index],
        state: { ...tempState[index].state, ...form }
      };
      return tempState;
    });
  }, []);

  const IncreasePostsCount = () => {
    setPostsState((e) => [
      ...e,
      {
        key: uuidv1(),
        state: {
          ...defaultSocialPostForm()
        }
      }
    ]);
  };

  const HandleAccountSplit = (index) => {
    const ParentObj = postsState[index];
    const SonsObjs = ParentObj.state.SocialId.map((e) => {
      return {
        key: uuidv1(),
        state: {
          ...ParentObj.state,
          SocialId: [e]
        }
      };
    });
    const tempState = [...postsState];
    tempState.splice(index, 1);
    setPostsState([...tempState, ...SonsObjs]);
  };

  const RemovePostItem = (index) => {
    setPostsState((e) => {
      const tempState = [...e];
      tempState.splice(index, 1);
      return tempState;
    });
  };

  const [togglePreview, setTogglePreview] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  let EnableAddMorePosts = false;

  if (globalState === 1 || globalState === 4 || globalState === 6)
    EnableAddMorePosts = true;

  return (
    <div
      className={classnames("bg-white  d-flex flex-column h-100", {
        PreviewOpen: togglePreview,
        PreviewClosed: !togglePreview
      })}
    >
      <BaseSidebarHeader className="flex-0-0-auto">
        <FormattedMessage id="PUBLICATIONS" />
      </BaseSidebarHeader>
      <div className="flex-1 of-y-auto d-flex">
        <div className="d-flex flex-column h-100 flex-1 SideBarSocialPost-InputSide">
          <PreviewHeader
            Id={id}
            canEdit={canEdit}
            state={postsState}
            InitialState={convertedForm ? convertedForm.Status || null : null}
            globalState={globalState}
            onChange={setGlobalState}
            isOpen={togglePreview}
            toggle={setTogglePreview}
          />
          <div className="of-y-auto flex-1 d-flex flex-column">
            {postsState.map((e, i, arr) => {
              return (
                <React.Fragment key={e.key}>
                  <SingleSocial
                    // style={{ marginBottom: i === arr.length - 1 ? "-9px" : "" }}
                    canEdit={canEdit}
                    SplitAccounts={HandleAccountSplit}
                    IncreasePostsCount={IncreasePostsCount}
                    isPreviewing={togglePreview}
                    index={i}
                    value={e.state}
                    states={postsState}
                    length={arr.length}
                    EnableAddMorePosts={EnableAddMorePosts}
                    onChange={handleChildrenChanges}
                    RemoveItem={RemovePostItem}
                  />
                  {i !== arr.length - 1 ? (
                    <hr
                      //   className="mx-4"
                      style={{ margin: 0, borderColor: "#cdd5ea" }}
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>

          {/*Footer  */}
          {(canDelete || canEdit) && (
            <div className="d-flex align-items-center bg-airdesk w-100">
              <div
                style={{
                  width: 520,
                  boxShadow: "5px -3px 10px rgba(227, 231, 241, 0.62)"
                }}
                className={classnames(
                  "py-3 bg-white px-4 details-form-footer d-flex justify-content-between align-items-center shadow-top"
                )}
              >
                {isDeleting ? (
                  <RemovalFooter
                    onSuccess={() => {
                      refetchRef.current && refetchRef.current();
                    }}
                    onCancel={() => setIsDeleting(false)}
                    SocialPostId={id}
                  />
                ) : (
                  <>
                    {id && canDelete ? (
                      <Button
                        vType="link-danger fw-bold"
                        className="mr-3"
                        onClick={() => setIsDeleting(true)}
                      >
                        <FormattedMessage id="DELETE" />
                      </Button>
                    ) : (
                      <div></div>
                    )}
                    {canEdit ? (
                      <LoadableButton
                        onClick={handleSubmiting}
                        isLoading={loading}
                        disabled={postsState.length === 0}
                        vType="primary"
                      >
                        <FormattedMessage id="CONFIRM" />
                      </LoadableButton>
                    ) : (
                      <div></div>
                    )}
                  </>
                )}
              </div>

              <div className="bg-airdesk h-100 ar-entity-summary-messages PreviewWidth"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const RemovalFooter = ({ onSuccess, onCancel, SocialPostId, refetchRef }) => {
  const [, closeSidebar] = useSidebar();

  const [deletePost, { loading }] = useSpaceDelete(
    `Social/Post/${SocialPostId}`,
    null,
    {
      onSuccess: () => {
        closeSidebar();
        onSuccess();
      }
    }
  );

  return (
    <>
      <div className="text-black mr-1">
        <FormattedMessage id="PUBLICATION_REMOVE_MSG" />
      </div>

      <div className="d-flex align-items-center">
        <Button vType="outline-danger" className="mr-3" onClick={onCancel}>
          <FormattedMessage id="BACK" />
        </Button>

        <LoadableButton
          vType="danger"
          isLoading={loading}
          onClick={() => {
            deletePost();
          }}
        >
          <FormattedMessage id="REMOVE" />
        </LoadableButton>
      </div>
    </>
  );
};

const DropdownComponent = ({ item }) => {
  if (!item)
    return (
      <span style={{ color: "#7C98B6" }}>
        <FormattedMessage id="SELECT_STATUS" />
      </span>
    );
  else return <PostStatusProfile value={item} />;
};

const PreviewHeader = ({
  isOpen,
  toggle,
  canEdit,
  state,
  globalState,
  onChange,
  Id,
  InitialState
}) => {
  const resolvedOptions = [];

  const ViewState = useSidebarView();
  const ShowHidePreviewButton = ViewState !== SidebarVisualTypeEnum.fullscreen;
  if (!Id) {
    //New Post
    resolvedOptions.push(4);
    resolvedOptions.push(6);
  } else if (Id && InitialState === 3) {
    //Published
    resolvedOptions.push(3);
    resolvedOptions.push(5);
  } else if (Id && InitialState === 1) {
    //Scheduled
    resolvedOptions.push(1);
    resolvedOptions.push(2);
    resolvedOptions.push(4);
  } else if (Id && InitialState === 4) {
    //Draft
    if (state.length === 1) resolvedOptions.push(2);
    resolvedOptions.push(4);
    resolvedOptions.push(6);
  } else if (Id && InitialState === 2) {
    //Canceled
    resolvedOptions.push(4);
    resolvedOptions.push(6);
  } else if (Id && InitialState === 5) {
    //Deleted
    resolvedOptions.push(4);
    resolvedOptions.push(6);
  } else if (Id && InitialState === 256) {
    //Publishing Fail
    resolvedOptions.push(4);
    resolvedOptions.push(6);

    resolvedOptions.push(2);
  }

  return (
    <>
      <div
        style={{ borderColor: "#cdd5ea" }}
        className={classnames(
          "ar-entity-summary-messages border-bottom flex-0-0-auto d-flex",
          {
            "justify-content-center": !isOpen
          }
        )}
      >
        <div
          className="bg-white SideBarSocialPost-InputSide px-4 pt-3 pb-3"
          style={{ width: 520, zIndex: 0 }}
        >
          <div className="mb-1 fs-14  text-black">
            <FormattedMessage id="STATUS" />
          </div>
          <KeyedDropdown
            preview={!canEdit}
            placeholder="Selecionar estado"
            valueComponent={DropdownComponent}
            getKey={(e) => e}
            closeOnSelect
            onChange={onChange}
            value={globalState}
            options={resolvedOptions}
          />
        </div>
        {!isOpen ? (
          <div
            style={{ width: 62 }}
            className="d-flex align-items-center ar-entity-summary-messages PreviewWidth justify-content-center"
          >
            <div
              onClick={() => {
                toggle(!isOpen);
              }}
              className="bg-airdesk ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button"
            >
              <FontAwesomeIcon icon={faEye} />
            </div>
          </div>
        ) : (
          <>
            <div className="bg-airdesk ar-entity-summary-messages PreviewWidth px-4 pt-3 pb-2 d-flex align-items-center justify-content-between">
              <div className="d-flex flex-1 align-items-center text-black">
                <FontAwesomeIcon icon={faEye} />
                <span className="ml-2">
                  <FormattedMessage id="PREVIEW" />
                </span>
              </div>
              {ShowHidePreviewButton ? (
                <div
                  onClick={() => {
                    toggle(!isOpen);
                  }}
                  className="text-color-link cursor-pointer"
                >
                  <FontAwesomeIcon icon={faChevronDoubleRight} />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const SocialNetworkDict_Reverse = {
  Facebook: 1,
  Facebook_two: 2,
  Twitter: 3,
  LinkedIn: 4,
  LinkedIn_two: 5,
  Instagram: 6,
  FaceBook_three: 7
};

export default SocialPostForm;
