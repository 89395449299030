import React, { useRef, useState, useEffect, useContext } from "react";
import "./Timeline.css";
import {
  TImelineEntityUrlProvider,
  TimelineSettingsContext,
  TimelineConfigContext,
  TimelineZoomContext,
  TimelineRefetchValue,
  TimelineRefetchSetValue
} from "./TimelineHelper";
import Button from "../Button/Button";
import { useServerAwareState } from "../CGrid/ServerGrid";
import { TimelineContent } from "./TimelineContent";
import RangeInput from "../RangeInput/RangeInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faSearchMinus,
  faSearchPlus
} from "@fortawesome/pro-light-svg-icons";
import { FormattedMessage } from "react-intl";

const Timeline = ({ settings, configs }) => {
  const [config, setConfig] = useState(configs[0]);

  const [refetchValue, setRefetchValue] = useState(0);

  const serverAwareState = useServerAwareState();

  const innerRef = useRef();

  const handleFocus = () => {
    if (innerRef.current) innerRef.current.handleFocusToday();
  };

  const [zoomValue, setZoomValue] = useState(() => {
    return {
      width: 150,
      zoom: 1
    };
  });

  return (
    <div className="ar-timeline-container flex-1">
      <TimelineConfigContext.Provider value={config}>
        <TImelineEntityUrlProvider schema={serverAwareState.schema}>
          <TimelineZoomContext.Provider value={zoomValue}>
            <TimelineSettingsContext.Provider value={settings}>
              <TimelineRefetchValue.Provider value={refetchValue}>
                <TimelineRefetchSetValue.Provider value={setRefetchValue}>
                  <TimelineContent
                    innerRef={innerRef}
                    key={config.key}
                    config={config}
                  />
                  <TimelineFooter
                    zoomValue={zoomValue}
                    setZoomValue={setZoomValue}
                    config={config}
                    onClick={handleFocus}
                    availableConfigs={configs}
                    setConfig={setConfig}
                  />
                </TimelineRefetchSetValue.Provider>
              </TimelineRefetchValue.Provider>
            </TimelineSettingsContext.Provider>
          </TimelineZoomContext.Provider>
        </TImelineEntityUrlProvider>
      </TimelineConfigContext.Provider>
    </div>
  );
};

export default Timeline;

const maxZoom = 3;
const minZoom = 0.28;

const ZoomInput = ({ zoomValue, setZoomValue }) => {
  const [value, setValue] = useState(zoomValue.zoom);

  useEffect(() => {
    const num = Number(value);
    if (isNaN(num)) return;

    const width = 230 * num;
    const timeout = setTimeout(() => {
      setZoomValue({
        width,
        zoom: num
      });
    }, 100);

    return () => clearTimeout(timeout);
  }, [setZoomValue, value]);

  useEffect(() => {
    setValue(zoomValue.zoom);
  }, [zoomValue.zoom]);

  return (
    <RangeInput
      value={value}
      className="mx-2"
      min={minZoom}
      // step={0.5}
      step={0.01}
      max={maxZoom}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

const TimelineFooter = ({
  onClick,
  availableConfigs,
  config,
  setConfig,
  zoomValue,
  setZoomValue
}) => {
  const setRefetchValue = useContext(TimelineRefetchSetValue);

  const handleRefetch = () => {
    setRefetchValue((oldV) => {
      return oldV + 1;
    });
  };

  const handleZoomMinusClick = () => {
    let num = Number(zoomValue.zoom);
    num = num - 0.2;
    if (num < minZoom) {
      num = minZoom;
    }
    if (num === zoomValue.zoom) return;

    const width = 230 * num;

    setZoomValue({
      width,
      zoom: num
    });
  };

  const handleZoomPlusClick = () => {
    let num = Number(zoomValue.zoom);
    num = num + 0.2;
    if (num > maxZoom) {
      num = maxZoom;
    }
    if (num === zoomValue.zoom) return;
    const width = 230 * num;

    setZoomValue({
      width,
      zoom: num
    });
  };

  return (
    <div className="ar-timeline-buttons d-flex align-items-center mr-2 disable-selection">
      <div className="rounded bg-white mr-2 d-flex ar-timeline-zoomInput align-items-center p-2">
        <FontAwesomeIcon
          icon={faSearchMinus}
          onMouseDown={handleZoomMinusClick}
        />
        <ZoomInput zoomValue={zoomValue} setZoomValue={setZoomValue} />
        <FontAwesomeIcon
          icon={faSearchPlus}
          onMouseDown={handleZoomPlusClick}
        />
      </div>
      <div className="bg-white mr-2 d-flex rounded border">
        {availableConfigs.map((c, i, r) => {
          if (i === 0) {
            return (
              <Button
                className={`${config === c && "selected"} rounded-left`}
                key={c.key}
                onClick={() => setConfig(c)}
                vType="link"
              >
                {c.title}
              </Button>
            );
          } else if (i === r.length - 1) {
            return (
              <Button
                className={`${config === c && "selected"} rounded-right`}
                key={c.key}
                onClick={() => setConfig(c)}
                vType="link"
              >
                {c.title}
              </Button>
            );
          } else {
            return (
              <Button
                className={config === c && "selected"}
                key={c.key}
                onClick={() => setConfig(c)}
                vType="link"
              >
                {c.title}
              </Button>
            );
          }
        })}
      </div>
      <div className="bg-white mr-2 rounded">
        {/* <div className="ar-timeline-seperator mx-2"></div> */}
        <Button onClick={onClick} className="rounded border" vType="link">
          <FormattedMessage id={"TODAY"} />
        </Button>
      </div>
      <div className="bg-white mr-2 rounded">
        {/* <div className="ar-timeline-seperator mx-2"></div> */}
        <Button
          style={{ height: 40 }}
          onClick={handleRefetch}
          className="rounded border"
          size="sm"
          vType="link"
        >
          <FontAwesomeIcon icon={faArrowsRotate} />
        </Button>
      </div>
    </div>
  );
};
