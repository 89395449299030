import React, {
  useState,
  useCallback,
  useReducer,
  useRef,
  useEffect
} from "react";
import Popper from "../Popper/Popper";
import "./Popup.css";
import { CSSTransition } from "react-transition-group";

export const usePopupOpenState = (popupRef, baseRef, settings = {}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(
    (e) => {
      const popupEl = popupRef.current;
      const baseEl = baseRef && baseRef.current;

      if (!popupEl) return document.removeEventListener("click", handleClick);

      if (
        popupEl === e.target ||
        popupEl.contains(e.target) ||
        (baseEl && baseEl.contains(e.target))
      )
        return;

      // document.removeEventListener("click", handleClick);

      setIsOpen(false);
    },
    [baseRef, popupRef]
  );
  const { blocked } = settings;
  useEffect(() => {
    //will open
    if (!isOpen || blocked) return;

    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleClick);
    };
  }, [blocked, handleClick, isOpen]);

  const toggleIsOpen = useCallback(
    (providedState) => {
      const newState = providedState !== undefined ? providedState : !isOpen;

      setIsOpen(newState);

      //will open
      // if (newState) {
      //   document.addEventListener("click", handleClick);
      //   document.addEventListener("contextmenu", handleClick);
      // } else {
      //   document.removeEventListener("click", handleClick);
      //   document.removeEventListener("contextmenu", handleClick);
      // }
    },
    [isOpen]
  );

  return [isOpen, toggleIsOpen];
};

const Popup = (props) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const {
    isOpen,
    children,
    anchorEl,
    placement,
    className,
    domRef,
    onMouseEnter,
    onMouseLeave,
    modifiers,
    onClick,
    disablePortal,
    style,
    exit,
    popperClassName
  } = props;

  const popperOpenRef = useRef(false);

  if (isOpen) popperOpenRef.current = true;

  const handleExited = useCallback(() => {
    popperOpenRef.current = false;
    forceUpdate();
  }, []);
	const resolvedCanOpen = Boolean(isOpen)
  return (
    <Popper
      isOpen={popperOpenRef.current}
      modifiers={modifiers}
      transition
      disablePortal={disablePortal}
      anchorEl={anchorEl}
      placement={placement}
      className={popperClassName}
    >
      <CSSTransition
        in={resolvedCanOpen}
        timeout={{ enter: 300, exit: exit !== undefined ? exit : 500 }}
        unmountOnExit
        appear
        classNames="popup"
        onExited={handleExited}
      >
        <div
          style={{
            margin: placement === "auto-start" ? "0px 10px" : "",
            ...style
          }}
          ref={domRef}
          className={`popup ${className ? className : ""}`}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
        >
          {typeof children === "function" && popperOpenRef.current
            ? children()
            : children}
        </div>
      </CSSTransition>
    </Popper>
  );
};

// class Popup extends Component {
//   render() {
//     const {
//       isOpen,
//       children,
//       anchorEl,
//       placement,
//       className,
//       domRef,
//       onMouseEnter,
//       onMouseLeave,
//       modifiers
//     } = this.props;

//     return (
//       <Popper
//         isOpen={isOpen}
//         modifiers={modifiers}
//         transition
//         anchorEl={anchorEl}
//         placement={placement}
//       >
//         {handleExited => (
//           <CSSTransition
//             in={isOpen}
//             timeout={{ enter: 300, exit: 500 }}
//             unmountOnExit
//             appear
//             classNames="popup"
//             onExited={handleExited}
//           >
//             <div
//               ref={domRef}
//               className={`popup ${className ? className : ""}`}
//               onMouseEnter={onMouseEnter}
//               onMouseLeave={onMouseLeave}
//             >
//               {children}
//             </div>
//           </CSSTransition>
//         )}
//       </Popper>
//     );
//   }
// }

export default Popup;
