import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useEditor, EditorContent, Editor } from "@tiptap/react";
import "./DocTyper.scss";
import StarterKit from "@tiptap/starter-kit";
import { DocTyperProps } from "./DocTyperTypes";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TaskItem from "@tiptap/extension-task-item";
// import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";
// import TaskList from "@tiptap/extension-task-list";
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
// import Focus from "@tiptap/extension-focus";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import { Color } from "@tiptap/extension-color";
import CharacterCount from "@tiptap/extension-character-count";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import Typography from "@tiptap/extension-typography";
import DocTyperMenuBar from "./DocTyperMenuBar";
import classnames from "classnames";
import DocTyperCodeMirror from "./DocTyperCodeMirror";
import {
  ColorStyleProvider,
  EditorNeededPropsProvider,
  FontFamilyStyleProvider,
  FontSizeStyleProvider,
  MaximizedSetStateProvider,
  MaximizedStateProvider,
  CodeMirrorProvider,
  SetCodeMirrorProvider
} from "./DocTyperHelpers";
import TextToSpeech from "./TiptapExtensions/TextToSpeech/TextToSpeech";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uniqolor from "uniqolor";
import { FormattedMessage } from "react-intl";
import {
  faArrowFromBottom,
  faArrowFromTop
} from "@fortawesome/pro-light-svg-icons";
import { fontSize } from "./TiptapExtensions/FontSizes/FontSizes";
import FontFamily from "./TiptapExtensions/FontFamilies/FontFamily";
// import { generateHTML } from "@tiptap/html";
import { unescape } from "html-escaper";
import { useCurrentAccount } from "../../Contexts/UserContext";
import { CustomImage } from "./TiptapExtensions/CustomImage/CustomImage";

const DocTyperSimple: FunctionComponent<DocTyperProps> = ({
  value,
  yDoc,
  websocketProvider,
  name,
  editorRef,
  DisabledmaximizeButton,
  className,
  sendUpdate,
  onChange,
  extraMenuItemsForEmailSignature,
  extraMenuItemHtmlEditorButton,
  extraMenuItemsForDocuments,
  autoGrow,
  config,
  editorKey = 1,
  maxCharCount,
  disableCharCount,
  closeOnSelect,
  noToolbar,
  preview,
  extraStyles = "",
  extraExtensions = [],
  ...rest
}) => {
  const currentUser = useCurrentAccount();

  const extensionsList = useMemo(() => {
    var color = uniqolor.random({ lightness: [70, 80] }).color;
    let result = !yDoc
      ? [
          StarterKit.configure({
            history: {
              newGroupDelay: 100
            }
          }),
          CustomImage.configure({
            inline: true,
            allowBase64: true
          }),
          Highlight,
          CharacterCount.configure({
            limit: maxCharCount || 10000
          }),
          Table.configure({
            resizable: true
          }),
          TableCell,
          TextAlign.configure({
            types: ["heading", "paragraph", "image"]
          }),
          TableHeader,
          Link,
          TableRow,
          TaskItem,
          Typography,
          // Focus.configure({
          //   className: "has-focus",
          //   mode: "deepest"
          // }),
          Underline,
          TextStyle,
          FontFamily,
          fontSize,
          Color,
          // TaskList,
          TextToSpeech,
          ...extraExtensions
        ]
      : [
          StarterKit.configure({
            history: false
          }),
          CustomImage.configure({
            inline: true
          }),
          Highlight,
          CharacterCount.configure({
            limit: 10000
          }),
          Table.configure({
            resizable: true
          }),
          TableCell,
          TextAlign.configure({
            types: ["heading", "paragraph", "image"]
          }),
          TableHeader,
          Link,
          TableRow,
          TaskItem,
          Typography,
          // Focus.configure({
          //   className: "has-focus",
          //   mode: "deepest"
          // }),
          Underline,
          TextStyle,
          FontFamily,
          fontSize,
          Color,
          // TaskList,
          TextToSpeech,
          ...extraExtensions,
          Collaboration.configure({
            document: yDoc
          }),
          CollaborationCursor.configure({
            provider: websocketProvider,
            user: {
              name: currentUser.Name,
              color: color
            }
          })
        ];
    if (disableCharCount) {
      result = result.filter((e) => e.name !== "characterCount");
    }
    return result;
  }, [
    currentUser.Name,
    disableCharCount,
    extraExtensions,
    maxCharCount,
    websocketProvider,
    yDoc
  ]);

  const handleChanges = (html: string) => {
    onChange && onChange(html + extraStyles);
  };

  const oldEscapedString = useRef(value) as any;

  const editor = useEditor(
    typeof sendUpdate === "function"
      ? {
          onUpdate({ editor }) {
            sendUpdate();
          },
          extensions: extensionsList,
          editable: preview ? false : true,
          content: value ? value : ""
        }
      : typeof onChange === "function"
      ? {
          onUpdate({ editor }) {
            const resolvedText = editor.getHTML();
            const escapedString = unescape(resolvedText);
            oldEscapedString.current = escapedString;
            handleChanges(escapedString);
          },
          extensions: extensionsList,
          editable: preview ? false : true,
          content: value ? value : ""
        }
      : {
          extensions: extensionsList,
          editable: preview ? false : true,
          content: value ? value : ""
        }
  );

  useEffect(() => {
    if (editorRef) editorRef.current = editor;
  }, [editor, editorRef]);

  // useEffect(() => {
  //   if (editor && typeof value === "string" && value === "") {
  //     if (editor.getHTML()) {
  //       editor.commands.setContent("");
  //     }
  //   }
  // }, [editor, value]);

  // useEffect(() => {
  //   if (value && oldEscapedString.current !== value) {
  // 		console.log("oi i setContent")
  //     oldEscapedString.current = value;
  //     editor?.commands.setContent(value);
  //   }
  // }, [editor?.commands, sendUpdate, value]);

  const [maximized, setMaximized] = useState(false);

  const [codeMirror, setCodeMirror] = useState(false);

  // useEffect(() => {
  //   if (!codeMirror) {
  //     editor && editor.commands.setContent(value);
  //   }
  // }, [codeMirror, editor, value]);

  const [removeOverflow, setRemoveOverflow] = useState(autoGrow || false);

  const toggleOverflow = () => {
    setRemoveOverflow(!removeOverflow);
  };
  const [built, setBuilt] = useState(false);

  useEffect(() => {
    if (autoGrow && built) {
      const nodes1 = Array.from(
        document.getElementsByClassName(
          "ProseMirror"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const nodes2 = Array.from(
        document.getElementsByClassName(
          "editor__content"
        ) as HTMLCollectionOf<HTMLElement>
      );
      if (nodes1[0] && nodes2[0]) {
        nodes1[0].style.minHeight = `${config?.autoGrow_minHeight}px`;
        nodes1[0].style.maxHeight = `${config?.autoGrow_maxHeight}px`;
        nodes2[0].style.minHeight = `${config?.autoGrow_minHeight}px`;
        nodes2[0].style.maxHeight = `${config?.autoGrow_maxHeight}px`;
      }
    } else if (!built) {
      setBuilt(true);
    }
  }, [autoGrow, built, config]);

  return (
    <EditorNeededPropsProvider.Provider
      value={{
        extensions: extensionsList,
        content: value,
        onChange: handleChanges,
        oldEditor: editor as Editor
      }}
    >
      <CodeMirrorProvider.Provider value={codeMirror}>
        <SetCodeMirrorProvider.Provider value={setCodeMirror}>
          <MaximizedStateProvider.Provider value={maximized}>
            <MaximizedSetStateProvider.Provider value={setMaximized}>
              <ColorStyleProvider.Provider
                value={editor?.getAttributes("textStyle")?.color}
              >
                <FontFamilyStyleProvider.Provider
                  value={editor?.getAttributes("textStyle")?.fontFamily}
                >
                  <FontSizeStyleProvider.Provider
                    value={editor?.getAttributes("textStyle")?.fontSize}
                  >
                    <div
                      className={classnames("editor", className, {
                        "remove-overflow-from-editor": removeOverflow
                      })}
                      {...rest}
                    >
                      {editor && !noToolbar && (
                        <DocTyperMenuBar
                          extraMenuItemHtmlEditorButton={
                            extraMenuItemHtmlEditorButton
                          }
                          extraMenuItemsForDocuments={
                            extraMenuItemsForDocuments
                          }
                          extraMenuItemsForEmailSignature={
                            extraMenuItemsForEmailSignature
                          }
                          disablePortal={false}
                          editor={editor}
                        />
                      )}
                      {!codeMirror && (
                        <EditorContent
                          className="editor__content"
                          editor={editor}
                        />
                      )}

                      {codeMirror && (
                        <DocTyperCodeMirror onChange={onChange} text={value} />
                      )}
                    </div>
                    {!DisabledmaximizeButton && (
                      <div className="d-flex fs-14 mt-1 justify-content-end">
                        <span
                          onClick={toggleOverflow}
                          className="d-flex cursor-pointer text-primary align-items-center"
                        >
                          {removeOverflow ? (
                            <>
                              <FontAwesomeIcon
                                className="mr-2"
                                icon={faArrowFromBottom}
                              />
                              <FormattedMessage id={"MINIMIZE_DESCRIPTION"} />
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon
                                className="mr-2"
                                icon={faArrowFromTop}
                              />
                              <FormattedMessage id={"MAXIMIZE_DESCRIPTION"} />
                            </>
                          )}
                        </span>
                      </div>
                    )}
                  </FontSizeStyleProvider.Provider>
                </FontFamilyStyleProvider.Provider>
              </ColorStyleProvider.Provider>
            </MaximizedSetStateProvider.Provider>
          </MaximizedStateProvider.Provider>
        </SetCodeMirrorProvider.Provider>
      </CodeMirrorProvider.Provider>
    </EditorNeededPropsProvider.Provider>
  );
};

export default DocTyperSimple;
