import React from "react";
// import { FormattedMessage } from "react-intl";
import {
  spaceCallsSchema,
  spaceClientSchema,
  spaceContactSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceInterventionsSchema,
  spaceProjectsSchema,
  spaceSubscriptionSchema,
  spaceTasksSchema,
  spaceTicketsSchema
} from "../../config/schema";
import {
  CallLink,
  ClientLink,
  ContactsLink,
  ContractsLink,
  DealLink,
  InterventionsLink,
  ProjectsLink,
  SubscriptionsLink,
  TasksLink,
  TicketsLink
} from "../../Helpers/HooksHelper";
import { moduleTypes } from "../../Helpers/ModulesHelper";

export interface IDetailsViewItem {
  Component: any;
  moduleType: moduleTypes;
  children: IDetailsViewItem[];
}

export const DetailsSettingsContext = React.createContext<any>(undefined);
export const DetailsValueIdContext = React.createContext<any>(undefined);
export const DetailsValueContext = React.createContext<any>(undefined);
export const DetailsBreadcrumbLinkContext = React.createContext<any>(undefined);

export const GetSchemaLink = (schema: any) => {
  let LinkComp;
  switch (schema) {
    case spaceProjectsSchema:
      LinkComp = ProjectsLink;
      break;
    case spaceTicketsSchema:
      LinkComp = TicketsLink;
      break;
    case spaceCallsSchema:
      LinkComp = CallLink;
      break;
    case spaceClientSchema:
      LinkComp = ClientLink;
      break;
    case spaceTasksSchema:
      LinkComp = TasksLink;
      break;
    case spaceContractSchema:
      LinkComp = ContractsLink;
      break;

    case spaceContactSchema:
      LinkComp = ContactsLink;
      break;

    case spaceDealsSchema:
      LinkComp = DealLink;
      break;

    case spaceSubscriptionSchema:
      LinkComp = SubscriptionsLink;
      break;

    case spaceInterventionsSchema:
      LinkComp = InterventionsLink;
      break;
    default:
      break;
  }
  return LinkComp;
};


