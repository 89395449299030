import React from "react";
import moment from "moment";
import { ConnectedAccountProfile } from "../Accounts/AccountProfile";
import { FormattedMessage } from "react-intl";

const CreatedBy = ({ Creator, CreationDate }) => {
  if (!Creator || !CreationDate) return null;

  return (
    <div className="w-100">
      <div className="mb-3 fs-14 fw-medium text-black">
        <FormattedMessage id={"CREATED_BY"} />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="mb-3 fs-14 fw-medium text-black">
          <ConnectedAccountProfile account={Creator} />
        </div>
        <div className="mb-2 text-black">
          <span className="fs-12 ml-1">
            {moment(CreationDate).format("DD/MM/YYYY HH:mm")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CreatedBy;
