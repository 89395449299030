import React, { FunctionComponent, useMemo, useRef } from "react";
import {
  spaceProjectsSchema,
} from "../../config/schema";
import moment from "moment";
import { useTimelineQueryString } from "../../Helpers/ODataHelper";
import { Project } from "../../Interfaces/EntityInterfaces";
import { useMultipleEntityValueSelector } from "../../Hooks/AdvancedEntityHooks";
import { FormattedMessage } from "react-intl";
import { useHoverEvents } from "../Origin/Origin";
import Popup from "../../Components/Popup/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import ProjectOrigin from "../Origin/ProjectOrigin";

interface IGroup {
  startDate: number;
  endDate: number;
  projects: Project[];
}

const doDatesConflict = (
  dealBegin: number,
  dealEnd: number,
  groupBegin: number,
  groupEnd: number
) => {
  if (dealBegin > groupBegin && dealBegin < groupEnd) return true;
  //data inicial Comeca no grupo
  else if (dealEnd > groupBegin && dealEnd < groupEnd) return true;
  //data final acaba no grupo
  else if (dealBegin < groupBegin && dealEnd > groupEnd) return true; //ja engloba o grupo
  return false;
};

const addToGroups = (
  Groups: IGroup[],
  project: Project,
  fBeginDate: number,
  fEndDate: number
) => {
  for (const group of Groups) {
    const { startDate, endDate } = group;
    if (doDatesConflict(fBeginDate, fEndDate, startDate, endDate)) {
      group.projects.push(project);
      if (fBeginDate < group.startDate) group.startDate = fBeginDate;

      if (fEndDate > group.endDate) group.endDate = fEndDate;
      return true;
    }
  }
  return false;
};

const calculateGroups = (projects: Project[]) => {
  const Groups: IGroup[] = [];

  for (const project of projects) {
    const { BeginDate, EndDate } = project;
    const fBeginDate = new Date(BeginDate).getTime();
    const fEndDate = new Date(EndDate).getTime();
    const added = addToGroups(Groups, project, fBeginDate, fEndDate);

    if (!added) {
      Groups.push({
        startDate: fBeginDate,
        endDate: fEndDate,
        projects: [project]
      });
    }
  }

  return Groups;
};

const ConflictsResponse = {
  results: [spaceProjectsSchema]
};

const ProjectConflicts = ({ user, beginDate, endDate, projectId }: any) => {
  const timelineSettings = useMemo(() => {
    return {
      user: user,
      baseUrl: "projects",
      baseField: "Project",
      ownId: projectId,
      BeginDateField: "BeginDate",
      EndDateField: "EndDate",
      AttributesField: "PipelineStatus/Status",
      Attribute: 1,
      noTime: true,
      schema: ConflictsResponse,
      startFormmated: beginDate
        ? moment(beginDate).toISOString()
        : moment().toISOString(),
      endFormmated: endDate
        ? moment(endDate).toISOString()
        : moment().toISOString()
    };
  }, [beginDate, endDate, user, projectId]);

  const {
    data,
    // loading: timelineloading,
    // error
  } = useTimelineQueryString(timelineSettings);

  if (Array.isArray(data?.d?.results) && data?.d?.__count > 0) {
    return (
      <ConflictBar
        beginDate={beginDate}
        endDate={endDate}
        data={data}
      ></ConflictBar>
    );
  } else return null;
};

const ConflictBar = ({ data, beginDate, endDate }: any) => {
  const projects = useMultipleEntityValueSelector(
    spaceProjectsSchema,
    data?.d?.results
  ) as Project[];

  const groups = useMemo(() => {
    if (Array.isArray(projects)) {
      return calculateGroups(projects);
    }
  }, [projects]);

  const [MsMaxDate, MsBeginDate] = useMemo(() => {
    const MsBeginDate = moment(beginDate).valueOf();
    const MsEndDate = moment(endDate).valueOf();
    return [MsEndDate - MsBeginDate, MsBeginDate];
  }, [beginDate, endDate]);

  return (
    <div className="w-100">
      <div className="mb-1 fs-14 d-flex align-items-center">
        <FontAwesomeIcon
          style={{ color: "#FF7A81" }}
          icon={faExclamationTriangle}
          className="mr-2"
        />
        <span style={{ color: "#FF8066" }}>
          <FormattedMessage id="PROJECT_OVERIMPOSED_FOUND" />
        </span>
      </div>
      <div
        style={{ height: 18, background: "#E7F1FD", borderRadius: "5px" }}
        className="d-inline-block of-hidden position-relative w-100 mb-1 border"
      >
        {groups?.map((e, i) => {
          return (
            <ConflictContainer
              key={i}
              group={e}
              maxPercentage={MsMaxDate}
              msBeginDate={MsBeginDate}
            />
          );
        })}
      </div>
      <div className="d-flex text-black justify-content-between fs-12">
        <div>{moment(beginDate).format("YYYY-MM-DD - HH:mm")}</div>
        <div>{moment(endDate).format("YYYY-MM-DD - HH:mm")}</div>
      </div>
    </div>
  );
};

interface IConflictContainerProps {
  group: IGroup;
  maxPercentage: number;
  msBeginDate: number;
}

const ConflictContainer: FunctionComponent<IConflictContainerProps> = ({
  group,
  maxPercentage,
  msBeginDate
}) => {
  const { startDate, endDate, projects } = group;

  const barPercentage = useMemo(() => {
    const Max = endDate - startDate;
    return (Max * 100) / maxPercentage;
  }, [endDate, maxPercentage, startDate]);

  const leftPercentage = useMemo(() => {
    const Max = startDate - msBeginDate;
    return (Max * 100) / maxPercentage;
  }, [maxPercentage, msBeginDate, startDate]);

  const anchorRef = useRef<HTMLDivElement>() as any;
  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  return (
    <>
      <Popup
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <div className="bg-white px-4 py-2 rounded">
          {projects.map((e) => {
            return <ProjectOrigin className="my-2" key={e.Id} project={e} />;
          })}
        </div>
      </Popup>
      <div
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        ref={anchorRef}
        style={{
          width: `${barPercentage > 100 ? 100 : barPercentage}%`,
          left: `${leftPercentage < 0 ? 0 : leftPercentage}%`,
          borderLeft: `2px solid #7C98B6`,
          borderRight: `2px solid #7C98B6`,
          background: `repeating-linear-gradient(-45deg, #E7F1FD, #E7F1FD 3px, #7C98B6 0px, #7C98B6 8px)`
        }}
        className="position-absolute d-inline-block h-100"
      ></div>
    </>
  );
};

export default ProjectConflicts;
