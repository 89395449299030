import React, { useState } from "react";
import "./SocialAccountComponent.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUnlink } from "@fortawesome/pro-light-svg-icons";
import InformationComponent from "../InformationComponent/InformationComponent";
import { FormattedMessage } from "react-intl";
import { useSocialAccount } from "../../Hooks/EntityHooks";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { canUserEditModule, hasAtLeastOneConfigurationModuleAccess, moduleTypes } from "../../Helpers/ModulesHelper";
import { useSidebar } from "../Sidebar/SidebarV2";
import { useSpace } from "../../Contexts/SpaceContext";
import { NavLink } from "react-router-dom";
import Button from "../Button/Button";

export const ConnectedSocialAccountComponent = ({ SocialAccount }) => {
  const ResolvedSocialAccount = useSocialAccount(SocialAccount);
  return <SocialAccountComponent Data={ResolvedSocialAccount} />;
};

const SocialAccountComponent = ({ Data }) => {
  // const { Name, Avatar, Type, Tag, IsBrokenLink } = Data;
	if(!Data) return <div></div>
  const { Name, Avatar, Type, IsBrokenLink } = Data;
  return (
    <div className="d-flex justify-content-start align-items-center">
      <div className="mr-3">
        <AccountLogoImg className="avatar" url={Avatar} Name={Name} size="sm" />
        <div className="SmallSocialIconCircle border d-flex justify-content-center align-items-center">
          <div
            style={{ height: 18, width: 18 }}
            className={classnames({
              SocialIconFacebook: Type === 1 || Type === 7 || Type === 2,
              SocialIconInstagram: Type === 6,
              SocialIconTwitter: Type === 3,
              SocialIconLinkedIn: Type === 4 || Type === 5
            })}
          ></div>
        </div>
      </div>
      <div className="fs-14 d-flex flex-column of-hidden">
        <div className="text-black text-truncate">{Name}</div>
        {/* <div className="AtDescript text-truncate">
          {Tag ? (Tag.charAt(0) === "@" ? "" : "@") : "@"}
          {Tag || Name}
        </div> */}
      </div>
      {IsBrokenLink && (
        <InformationComponent
          text={<FormattedMessage id="BROKEN_SOCIAL_CONNECTION" />}
          Component={
            <div className="text-danger-alt ml-2">
              <FontAwesomeIcon icon={faUnlink} />
            </div>
          }
        />
      )}
    </div>
  );
};

const buildImgClass = ({ size, circular, className }) => {
  let baseClass = "img";

  switch (size) {
    case "lg":
      baseClass += " lg";
      break;
    case "sm":
      baseClass += " sm";
      break;
    case "xsm":
      baseClass += " xsm";
      break;
    default:
      break;
  }

  if (circular) baseClass += " circular";

  if (className) baseClass += ` ${className}`;

  return baseClass;
};

const Img = (props) => {
  const { src, Name, ...rest } = props;
  const [imageError, setImageError] = useState(false);
  if (!src) return null;
  if (imageError) {
    if (!Name) return null;

    let classes = buildImgClass(rest);
    classes += " account logo rounded-circle";
    let names = Name.match(/[\w\u00C0-\u017F]+/g);
    let firstName = names[0];
    let lastName = names[names.length - 1];

    let firstLetter = firstName.charAt(0);
    let lastLetter = lastName.charAt(firstName === lastName);

    return (
      <div className={classes}>
        <span>{firstLetter}</span>
        <span>{lastLetter}</span>
      </div>
    );
  }
  return (
    <img
      onError={() => {
        setImageError(true);
      }}
      className={buildImgClass(props)}
      alt=""
      src={src}
    />
  );
};

const AccountLogoImg = ({ account, Name, Image, url, ...rest }) => {
  if (url)
    return (
      <Img className="logo rounded-circle" src={url} Name={Name} {...rest} />
    );

  if (!Name) return null;

  let classes = buildImgClass(rest);
  classes += " account logo rounded-circle";
  let names = Name.match(/[\w\u00C0-\u017F]+/g);
  let firstName = names[0];
  let lastName = names[names.length - 1];

  let firstLetter = firstName.charAt(0);
  let lastLetter = lastName.charAt(firstName === lastName);

  return (
    <div className={classes}>
      <span>{firstLetter}</span>
      <span>{lastLetter}</span>
    </div>
  );
};


const SocialAccountsRequestSideErrorMessage = (props) => {
	const accountSpace = useCurrentAccountSpace();

  const SocialPermission = canUserEditModule(
    accountSpace,
		moduleTypes.settingsSocial
  );
  // eslint-disable-next-line no-unused-vars
  const [openSidebar, closeSidebar] = useSidebar();
  const space = useSpace();
	const hasSettingsAcess = hasAtLeastOneConfigurationModuleAccess(accountSpace);
	

	if (SocialPermission && hasSettingsAcess) {
		return (
			<div className="d-flex flex-column align-items-center">
				<div className="mb-3">
					<FormattedMessage id="SOCIAL_ACCOUNTS_REQUEST_NO_DATA" />
				</div>
				<NavLink
					onClick={() => {
						closeSidebar();
					}}
					to={`/s/${space.Id}/settings/social`}
				>
					<Button vType="primary" type="button">
						<div className="d-flex align-items-center">
							<FontAwesomeIcon icon={faEdit} />
							<span className="ml-2">
								<FormattedMessage id="ADD_ACCOUNT" />
							</span>
						</div>
					</Button>
				</NavLink>
			</div>
		);
	} else {
		return (
			<FormattedMessage id="SOCIAL_ACCOUNTS_REQUEST_NO_DATA_NO_PERMISSION" />
		);
	}


}

export const SocialAccountsRequestSideError = (props) => {
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <SocialAccountsRequestSideErrorMessage {...props} />
    </div>
  );
};

export default SocialAccountComponent;
