import React, { useRef } from "react";
import "./ProfilePicture.css";
import classnames from "classnames";
import {
  faImage,
  faArrowAltDown,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, useIntl } from "react-intl";

export const OldProfilePicture = ({
  placeHolderPhoto,
  placeHolderComponent,
  uploadText,
  onChange,
  value,
  name,
  preview,
}) => {
  // const [image, setImage] = useState(value);
  // const [file, setFile] = useState(null);

  const image = value ? value.image : null;

  const inputFile = (event) => {
    event.preventDefault();

    const data = event.currentTarget.files;

    if (!data || data.length === 0) return;

    updateFile(data);
  };

  const getOnChangeValue = (value) => {
    return value;
  };

  const updateFile = (data) => {
    const file = data[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      // setImage(e.target.result);
      // setFile(file);
      const value = {
        image: e.target.result,
        file: file,
      };
      if (onChange) onChange(getOnChangeValue(value));
    };
    reader.readAsDataURL(file);
  };
  return (
    <div className={classnames({ "ar-profilepicture": !preview })}>
      <label className="m-0">
        <div
          className="ar-picture"
          style={
            placeHolderComponent && !image
              ? null
              : { backgroundImage: "url(" + (image || placeHolderPhoto) + ")" }
          }
        >
          {placeHolderComponent && !image && placeHolderComponent}
        </div>
        {!preview && (
          <input
            className="d-none"
            type="file"
            accept="image/*"
            onChange={inputFile}
          />
        )}
        {!preview && uploadText && uploadText}
      </label>
    </div>
  );
};

export const ProfilePicture = ({
  placeHolderPhoto,
  placeHolderComponent,
  uploadText,
  onChange,
  value,
  name,
  preview,
}) => {
  let image = value ? value.image : null;
  if (typeof value === "string") {
    image = value;
  }
  const inputFile = (event) => {
    event.preventDefault();

    const data = event.currentTarget.files;

    if (!data || data.length === 0) return;

    updateFile(data);
  };

  const getOnChangeValue = (value) => {
    return value;
  };

  const updateFile = (data) => {
    const file = data[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      // setImage(e.target.result);
      // setFile(file);
      const value = {
        image: e.target.result,
        file: file,
      };
      if (onChange) onChange(getOnChangeValue(value));
    };
    reader.readAsDataURL(file);
  };

  const removeFile = () => {
    if (onChange) onChange(false);
  };

  const ContainerPicture = useRef();

  const onDragEnter = (e) => {
    e.preventDefault();
    ContainerPicture.current.style.borderStyle = "solid";
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    ContainerPicture.current.style.borderStyle = "dashed";
  };

  const onDrop = (e) => {
    e.preventDefault();
    var file = e.dataTransfer.files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      if (onChange) {
        onChange({
          image: event.target.result,
          file: file,
        });
      }
    };
    reader.readAsDataURL(file);
    ContainerPicture.current.style.borderStyle = "dashed";
  };

  return (
    <div
      style={{ height: 67 }}
      onDragEnter={onDragEnter}
      onDragLeaveCapture={onDragLeave}
      className={classnames("position-relative", {
        "ar-logopicture": !preview,
      })}
    >
      <label
        onDrop={onDrop}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        className="m-0"
      >
        <div
          ref={ContainerPicture}
          className={classnames("ar-organizationPicture", {
            border: image || placeHolderPhoto,
          })}
          style={{
            width: 67,
            height: 67,
            backgroundImage: "url(" + (image || placeHolderPhoto) + ")",
          }}
        >
          {!image && !placeHolderPhoto && (
            <div className="d-flex justify-content-center fs-14 text-primary flex-column align-items-center">
              <FontAwesomeIcon className="fs-10 mb-1" icon={faArrowAltDown} />
              <FontAwesomeIcon className="rotate-45" icon={faImage} />
            </div>
          )}
        </div>
        {!preview && (
          <input
            className="d-none"
            type="file"
            accept="image/*"
            onChange={inputFile}
          />
        )}
      </label>
      {(image || placeHolderPhoto) && (
        <div
          onClick={removeFile}
          className="cursor-pointer d-flex justify-content-center align-items-center ar-logos-close_circular_corner"
        >
          <FontAwesomeIcon size="sm" icon={faTrashAlt} />
        </div>
      )}
    </div>
  );
};

export const OrganizationPicture = ({
  placeHolderPhoto,
  placeHolderComponent,
  uploadText,
  onChange,
  value,
  name,
  preview,
}) => {
  // const [image, setImage] = useState(value);
  // const [file, setFile] = useState(null);

  let image = value ? value.image : null;
  if (typeof value === "string") {
    image = value;
  }
  const inputFile = (event) => {
    event.preventDefault();

    const data = event.currentTarget.files;

    if (!data || data.length === 0) return;

    updateFile(data);
  };

  const getOnChangeValue = (value) => {
    return value;
  };

  const updateFile = (data) => {
    const file = data[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      // setImage(e.target.result);
      // setFile(file);
      const value = {
        image: e.target.result,
        file: file,
      };
      if (onChange) onChange(getOnChangeValue(value));
    };
    reader.readAsDataURL(file);
  };

  const removeFile = () => {
    if (onChange) onChange(null);
  };

  const ContainerPicture = useRef();

  const onDragEnter = (e) => {
    e.preventDefault();
    ContainerPicture.current.style.borderStyle = "solid";
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    ContainerPicture.current.style.borderStyle = "dashed";
  };

  const onDrop = (e) => {
    e.preventDefault();
    var file = e.dataTransfer.files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      if (onChange) {
        onChange({
          image: event.target.result,
          file: file,
        });
      }
    };
    reader.readAsDataURL(file);
    ContainerPicture.current.style.borderStyle = "dashed";
  };

  return (
    <div
      onDragEnter={onDragEnter}
      onDragLeaveCapture={onDragLeave}
      className={classnames("position-relative", {
        "ar-logopicture": !preview,
      })}
    >
      <label
        onDrop={onDrop}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        className="m-0"
      >
        <div
          ref={ContainerPicture}
          className={classnames("ar-organizationPicture", {
            border: image || placeHolderPhoto,
          })}
          style={
            placeHolderComponent && !image
              ? null
              : { backgroundImage: "url(" + (image || placeHolderPhoto) + ")" }
          }
        >
          {!image && !placeHolderPhoto && (
            <div className="d-flex justify-content-center fs-14 text-primary flex-column align-items-center">
              <FontAwesomeIcon className="fs-10 mb-1" icon={faArrowAltDown} />
              <FontAwesomeIcon className="rotate-45" icon={faImage} />
            </div>
          )}
        </div>
        {!preview && (
          <input
            className="d-none"
            type="file"
            accept="image/*"
            onChange={inputFile}
          />
        )}
      </label>
      {(image || placeHolderPhoto) && (
        <div
          onClick={removeFile}
          className="cursor-pointer d-flex justify-content-center align-items-center ar-logos-close_circular_corner"
        >
          <FontAwesomeIcon size="sm" icon={faTrashAlt} />
        </div>
      )}
    </div>
  );
};

export const LogoPictures = ({
  placeHolderPhoto,
  placeHolderComponent,
  uploadText,
  onChange,
	value,
	className,
  name,
  preview,
}) => {
  // const [image, setImage] = useState(value);
  // const [file, setFile] = useState(null);

  let image = value ? value.image : null;
  if (typeof value === "string") {
    image = value;
  }
  const inputFile = (event) => {
    event.preventDefault();

    const data = event.currentTarget.files;

    if (!data || data.length === 0) return;

    updateFile(data);
  };

  const getOnChangeValue = (value) => {
    return value;
  };

  const updateFile = (data) => {
    const file = data[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      // setImage(e.target.result);
      // setFile(file);
      const value = {
        image: e.target.result,
        file: file,
      };
      if (onChange) onChange(getOnChangeValue(value));
    };
    reader.readAsDataURL(file);
  };

  const removeFile = () => {
    if (onChange) onChange(null);
  };

  const ContainerPicture = useRef();

  const intl = useIntl();

  const onDragEnter = (e) => {
    e.preventDefault();
    ContainerPicture.current.style.borderStyle = "solid";
    descriptionRef.current.innerText = intl.formatMessage({
      id: "DROP_THE_FILE",
    });
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    ContainerPicture.current.style.borderStyle = "dashed";
    descriptionRef.current.innerText = intl.formatMessage({
      id: "LOGOTYPE_DESCRIPTION_INPUT",
    });
  };

  const onDrop = (e) => {
    e.preventDefault();
    var file = e.dataTransfer.files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      if (onChange) {
        onChange({
          image: event.target.result,
          file: file,
        });
      }
    };
    reader.readAsDataURL(file);
    ContainerPicture.current.style.borderStyle = "dashed";
    descriptionRef.current.innerText = intl.formatMessage({
      id: "LOGOTYPE_DESCRIPTION_INPUT",
    });
  };

  const descriptionRef = useRef();

  return (
    <div
      onDragLeaveCapture={onDragLeave}
      className={classnames("position-relative", className,{
        "ar-logopicture": !preview,
      })}
    >
      <label
        onDrop={onDrop}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragEnter={onDragEnter}
        className="m-0"
      >
        <div
          ref={ContainerPicture}
          className={classnames("ar-organizationLogo", {
            border: image || placeHolderPhoto,
          })}
          style={
            placeHolderComponent && !image
              ? null
              : { backgroundImage: "url(" + (image || placeHolderPhoto) + ")" }
          }
        >
          {!image && !placeHolderPhoto && (
            <div className="d-flex fs-14 text-primary justify-content-center flex-column align-items-center">
              <FontAwesomeIcon className="fs-10 mb-1" icon={faArrowAltDown} />
              <FontAwesomeIcon className="rotate-45" icon={faImage} />
              <span ref={descriptionRef} className="mt-1">
                <FormattedMessage id="LOGOTYPE_DESCRIPTION_INPUT" />
              </span>
            </div>
          )}
          {/* {placeHolderComponent && !image && placeHolderComponent} */}
        </div>
        {!preview && (
          <input
            className="d-none"
            type="file"
            accept="image/*"
            onChange={inputFile}
          />
        )}
        {/* {!preview && uploadText && uploadText} */}
      </label>
      {(image || placeHolderPhoto) && (
        <div
          onClick={removeFile}
          className="cursor-pointer d-flex justify-content-center align-items-center ar-logos-close_circular_corner"
        >
          <FontAwesomeIcon size="sm" icon={faTrashAlt} />
        </div>
      )}
    </div>
  );
};
