import React from "react";
import Button from "../Button/Button";
import { HourToMilSec, MinuteToMilSec } from "../../Helpers/MiscHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import Dropdown from "../Dropdown/Dropdown";
import classes from "./WorkflowTimeTablePicker.module.css";
import DatePicker from "../DatePicker/DatePicker";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

const TimeTableComponent = React.memo(
  ({
    value,
    StartTimeChanges,
    EndTimeChanges,
    DaysChange,
    twentyFourCheckboxChanges,
    onClose,
    index,
    error,
    preview,
    onboarding
  }) => {
    // const DaysDict = {
    //   1: "Segunda",
    //   2: "Terça",
    //   3: "Quarta",
    //   4: "Quinta",
    //   5: "Sexta",
    //   6: "Sabado",
    //   7: "Domingo"
    // };

    // const DaysValueArr = value.Days
    //   ? value.Days.map((e) => {
    //       return DaysDict[e];
    //     })
    //   : [];

    const DaysArray = [1, 2, 3, 4, 5, 6, 7];

    const DaysTranslationDict = {
      1: <FormattedMessage id={"MONDAY"} />,
      2: <FormattedMessage id={"TUESDAY"} />,
      3: <FormattedMessage id={"WEDNESDAY"} />,
      4: <FormattedMessage id={"THURSDAY"} />,
      5: <FormattedMessage id={"FRIDAY"} />,
      6: <FormattedMessage id={"SATURDAY"} />,
      7: <FormattedMessage id={"SUNDAY"} />
    };

    const TranslatedDay = ({ item }) => {
      return DaysTranslationDict[item];
    };

    return (
      <div
        style={{ borderRadius: 5 }}
        className={classnames(
          "d-flex align-items-center mt-1 border of-y-auto",
          {
            "bg-white": onboarding
          }
        )}
      >
        <div className="flex-1">
          <div className="d-flex align-items-center px-3 pt-2">
            <div style={{ width: 31 }} className="mr-3 pr-1 text-black">
              <FormattedMessage id={"FROM"} />
            </div>
            <span className={classes.ScheduleTimePicker}>
              <DatePicker
                preview={preview}
                key={index + "StartTime"}
                className={classes["DatePicker-Width-Small"]}
                enableDate={false}
                value={value.StartTime}
                onChange={(e) => StartTimeChanges(index, e)}
              />
            </span>
            <span className="mx-2 text-black">
              <FormattedMessage id={"TO"} />
            </span>
            <span className={classes.ScheduleTimePicker}>
              <DatePicker
                preview={preview}
                className={classes["DatePicker-Width-Small"]}
                key={index + "EndTime"}
                enableDate={false}
                value={value.EndTime}
                onChange={(e) => EndTimeChanges(index, e)}
              />
            </span>
            {!preview && (
              <div className="d-flex w-100 justify-content-end">
                <Button
                  type="button"
                  className="p-0"
                  propStyle={{ minWidth: 40, width: 40 }}
                  onClick={() => {
                    onClose(index);
                  }}
                  vType="outline-danger"
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center px-3 py-2">
            <span className="mr-3 text-black">
              <FormattedMessage id={"DAYS"} />
            </span>
            <Dropdown
              className={classnames({ error: error })}
              preview={preview}
              items={DaysArray}
              onChange={(e) => DaysChange(index, e)}
              removable
              multiple
              listItemComponent={TranslatedDay}
              valueComponent={TranslatedDay}
              value={value.WeekDays}
            />
          </div>
        </div>
      </div>
    );
  }
);

const WorkflowTimeTablePicker = ({
  value = [{}],
  onChange,
  className,
  preview,
  onboarding,
  error
}) => {
  const closeContainer = (index) => {
    onChange((oldV) => {
      const newV = [...oldV];
      newV.splice(index, 1);
      return newV;
    });
  };

  const StartTimeChanges = (index, e) => {
    onChange((oldV) => {
      const newV = [...oldV];
      const oldObject = oldV[index];
      const newObject = { ...oldObject };
      const milsecs = HourToMilSec(e[0]) + MinuteToMilSec(e[1]);
      newObject.StartTime = milsecs;
      newV[index] = newObject;
      return newV;
    });
  };

  const EndTimeChanges = (index, e) => {
    onChange((oldV) => {
      const newV = [...oldV];
      const oldObject = oldV[index];
      const newObject = { ...oldObject };
      const milsecs = HourToMilSec(e[0]) + MinuteToMilSec(e[1]);
      newObject.EndTime = milsecs;
      newV[index] = newObject;
      return newV;
    });
  };

  const DaysChange = (index, e) => {
    onChange((oldV) => {
      const newV = [...oldV];
      const oldObject = oldV[index];
      const newObject = { ...oldObject };
      newObject.WeekDays = e;
      newV[index] = newObject;
      return newV;
    });
  };

  return (
    <>
      <div className="mb-3">
        {value.map((e, index) => {
          return (
            <TimeTableComponent
              onboarding={onboarding}
              preview={preview}
              key={index}
              error={Boolean(error) && e?.Days?.length === 0}
              value={e}
              index={index}
              StartTimeChanges={StartTimeChanges}
              EndTimeChanges={EndTimeChanges}
              DaysChange={DaysChange}
              onClose={closeContainer}
            />
          );
        })}
      </div>
      {!preview && (
        <Button
          disabled={preview}
          onClick={() => {
            onChange((oldV) => {
              const date = new Date();
              date.setHours(date.getHours() + 1);
              const newV = [...oldV];
              newV.push({
                WeekDays: [],
                StartTime: new Date().getTime(),
                EndTime: date.getTime()
              });
              return newV;
            });
          }}
          type="button"
          className="ssi-input ssi-button-primary-ghost"
        >
          <span>
            <FontAwesomeIcon icon={faPlus} />{" "}
            <FormattedMessage id={"ADD_NEW_PERIOD"} />
          </span>
        </Button>
      )}
    </>
  );
};

export default WorkflowTimeTablePicker;
