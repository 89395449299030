import React, {useState } from "react";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import withSpaceEntityForm from "../../../Components/Forms";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { spaceContractTypeSchema } from "../../../config/schema";
import { createFormData } from "../../../Helpers/MiscHelper";
import { OrganizationDropdown } from "../../AdvancedMultiInputs";
import TimeTablePicker from "../../../Components/TimetablePicker/TimeTablePicker";
import MinuteInput from "../../../Components/Input/MinuteInput";
import {
  BaseSidebarContainer,
  BaseSidebarHeader,
  BaseSidebarContent,
  useSidebarSpaceDelete,
} from "../../../Components/Sidebar/SidebarV2Helper";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { useHandleError } from "../../../Components/CGrid/ServerAwareHelper";
import { withSpace } from "../../../Contexts/SpaceContext";
import SideMenuForm from "../../../Components/Forms/SideMenuForm";
import LoadableButton from "../../../Components/Button/LoadableButton";
import Button from "../../../Components/Button/Button";


const RemovalFooter = ({ endpoint, refetchRef, onCancel, Name }) => {
  const createToast = useToast();
  const intl = useIntl();
  const handleTranslatedError = useHandleError(spaceContractTypeSchema);
  const [deleteUser, { loading }] = useSidebarSpaceDelete(
    endpoint,
    spaceContractTypeSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          title: `${intl.formatMessage({ id: "CONTRACT_TYPE" })} ${Name}`,
          description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
        });
      },
      onError: ({ error }) => {
        handleTranslatedError(error);
      },
      refetchRef
    }
  );

  return (
    <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
      <div className="text-black">
        <FormattedMessage id="ELIMINATE_CONTRACT_TYPE" />
      </div>

      <div className="d-flex align-items-center">
        <Button
          vType="outline-danger"
          type="button"
          className="mr-3"
          onClick={onCancel}
        >
          <FormattedMessage id="BACK" />
        </Button>

        <LoadableButton
          vType="danger"
          type="button"
          isLoading={loading}
          onClick={() => {
            deleteUser();
          }}
        >
          <FormattedMessage id="DELETE" />
        </LoadableButton>
      </div>
    </div>
  );
};

const ContractTypeForm = ({ refetchRef, ...props }) => {
  const { formStatus, isChild, form } = props;

  const [isDeleting, setIsDeleting] = useState(false);

  const { Id, CanDelete, Name } = form;

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id="CONTRACT_TYPE" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <SideMenuForm
          formStatus={formStatus}
          footerComponent={
            !Id ? (
              <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
                <div></div>
                <LoadableButton
                  isLoading={formStatus.isProcessing}
                  onClick={formStatus.submit}
                >
                  <FormattedMessage id={"CONFIRM"} />
                </LoadableButton>
              </div>
            ) : !isDeleting ? (
              <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
                {CanDelete ? (
                  <Button
                    type="button"
                    vType="link-danger fw-bold"
                    className="mr-3"
                    onClick={() => setIsDeleting(true)}
                  >
                    <span style={{ fontWeight: 500 }}>
                      <FormattedMessage id="DELETE" />
                    </span>
                  </Button>
                ) : (
                  <div></div>
                )}
                <LoadableButton
                  isLoading={formStatus.isProcessing}
                  onClick={formStatus.submit}
                >
                  <FormattedMessage id={"CONFIRM"} />
                </LoadableButton>
              </div>
            ) : (
              CanDelete && (
                <RemovalFooter
                  Name={Name}
                  type="button"
                  endpoint={`contractTypes/${Id}`}
                  refetchRef={refetchRef}
                  onCancel={() => setIsDeleting(false)}
                />
              )
            )
          }
        >
          <FormInput
            disabled={isChild}
            name="Organizations"
            textId="COMPANIES"
            className="mb-3"
            inputType={OrganizationDropdown}
            closeOnSelect={false}
            multiple
          />
          <FormInput name="Type" textId="CODE" className="mb-3" />
          <FormInput name="Name" textId="NAME" className="mb-3" />
          <FormInput
            name="IntervalMinimum"
            textId="MINIMUM_BILLING_INTERVAL"
            className="mb-3"
            inputType={MinuteInput}
          />
          <FormInput
            name="RoundingMargin"
            textId="ROUNDING_MARGIN"
            className="mb-3"
            inputType={MinuteInput}
          />

          <FormInput
            className="mb-3"
            name="Schedules"
            textId="WORK_HOURS"
            inputType={TimeTablePicker}
            multiple
          />
        </SideMenuForm>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export default withSpaceEntityForm(withSpace(injectIntl(ContractTypeForm)), {
  defaultForm: {
    Type: "",
    Organizations: [],
    IntervalMinimum: "",
    RoundingMargin: "",
    Name: "",
    Schedules: []
  },
  validateForm: ({ Name, Type, Organizations }, addError) => {
    if (!Name) addError("Name");
    if (!Type) addError("Type");
    if (!Organizations || Organizations.length === 0) addError("Organizations");
  },
  convertEntityToForm: (entity) => {
    return {
      ...entity
    };
  },
  onSuccess: (props) => {
    const { createToast, intl, form } = props;
    const { Name } = form;
    createToast({
      pos: "tm",
      type: "success",
      title: `${intl.formatMessage({ id: "CONTRACT_TYPE" })} ${Name}`,
      description: `${intl.formatMessage({
        id: "SUCCESS_SAVED"
      })}`
    });
    // handleToast(props)
  },
  formatForm: (form) => {
    const { Schedules, ...restForm } = form;

    return createFormData({
      ...restForm,
      Schedules: JSON.stringify(Schedules)
    });
  },
  schema: spaceContractTypeSchema
});
