import { faCalendarAlt, faDrawCircle } from "@fortawesome/pro-light-svg-icons";
import { faInfoSquare } from "@fortawesome/pro-solid-svg-icons";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  EntityOverviewItem,
  EntityOverviewRoundedItem,
} from "../../../Components/EntityOverview/EntityOverview";
import { EntitySummaryAccordion } from "../../../Components/EntitySummaries/EntitySummaries";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import RelativeTime from "../../../Components/RelativeTime/RelativeTime";
import {
  BaseSidebarContainer,
  BaseSidebarContent,
  BaseSidebarHeader,
} from "../../../Components/Sidebar/SidebarV2Helper";
import { spaceApiLogsSchema } from "../../../config/schema";
import { useSpaceQuery } from "../../../Helpers/IOClient";
import { useApiLog } from "../../../Hooks/EntityHooks";
import { DomainKeyPre } from "../Domains/DomainKeys/DomainKeysClipboard";
import { ApiKeyLogResponseCode } from "./ApiKeyLogsHelper";

const InnerApiKeyDetails = ({ data }) => {
  const {
    RequestUrl,
    RequestType,
    ResponseCode,
    CreationDate,
    Response,
    Body,
  } = useApiLog(data);

  const rResponse = useMemo(
    () =>
      Response ? JSON.stringify(JSON.parse(Response), undefined, 2) : undefined,
    [Response]
  );

  const rBody = useMemo(
    () => (Body ? JSON.stringify(JSON.parse(Body), undefined, 2) : undefined),
    [Body]
  );

  return (
    <div className=" pt-4">
      <div className="fs-28 text-black mb-2 px-3 text-truncate">
        <span className="mr-2">{RequestType}</span>
        <span>{RequestUrl}</span>
      </div>
      <EntitySummaryAccordion icon={faInfoSquare} textId={"DETAILS"}>
        <EntityOverviewItem icon={faDrawCircle} titleId="RESPONSE_CODE">
          <ApiKeyLogResponseCode responseCode={ResponseCode} />
        </EntityOverviewItem>

        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="CREATION_DATE">
          <RelativeTime date={CreationDate} />
        </EntityOverviewRoundedItem>
      </EntitySummaryAccordion>

      {rBody && (
        <EntitySummaryAccordion textId={"REQUEST_BODY"}>
          <DomainKeyPre value={rBody} />
        </EntitySummaryAccordion>
      )}

      {rResponse && (
        <EntitySummaryAccordion textId={"RESPONSE_BODY"}>
          <DomainKeyPre value={rResponse} />
        </EntitySummaryAccordion>
      )}
    </div>
  );
};

const ApiKeyLogForm = ({ id, ...rest }) => {
  const { data } = useSpaceQuery(`ApiLog/${id}`, spaceApiLogsSchema);

  return (
    <BaseSidebarContainer>
      <BaseSidebarHeader>
        <FormattedMessage id="LOG" />
      </BaseSidebarHeader>
      <BaseSidebarContent>
        {!data ? (
          <LoaderSpinner size="sm" className="text-primary" center />
        ) : (
          <InnerApiKeyDetails data={data} />
        )}
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};

export default ApiKeyLogForm;
