import React, { useState } from "react";
import { useSpace } from "../../Contexts/SpaceContext";
import { useShortPost } from "../../Helpers/IOClient";
import { useToast } from "../Toast/ToastProvider";
import { handleError } from "../../Helpers/MiscHelper";
import { useGridRequest } from "../CGrid/ServerGrid";
import { FormattedMessage, useIntl } from "react-intl";

const EntityStatusUpdaterComp = ({
  schema,
  hideName,
  id,
  status: startStatus,
  canEdit,
  hideCancellation,
  Component,
  type,
  itsLogs,
  ...props
}) => {
  const value = useGridRequest();
  const space = useSpace();
  const [status, setStatus] = useState(startStatus);
  const url = itsLogs
    ? `${schema.getEndpoint(space.Id)}/${id}`
    : `${schema.getEndpoint(space.Id)}/${id}/status`;
  const createToast = useToast();
  const [post, { loading }] = useShortPost(url, itsLogs ? undefined : schema);

  //   const onChange = newStatus => {
  //     if (newStatus !== status) {
  //       setStatus(newStatus);
  //       post({ PipelineStatusId: newStatus });
  //     }
  //   };
  const intl = useIntl();
  const onChange = (newStatus, cancelReason = undefined) => {
    if (newStatus !== status) {
      const onSuccess = () => {
        setStatus(newStatus);
        const translated_Type = intl.formatMessage({ id: type });
        createToast({
          pos: "tm",
          type: "success",
          title: translated_Type + ` ${id}`,
          description: <FormattedMessage id={"STATUS_CHANGED_SUCCESS"} />
        });
        if (itsLogs) {
          value.refetch();
        }
      };

      const onError = ({ error }) => {
        handleError(createToast, error);
      };

      if (itsLogs) {
        post({ Status: newStatus }, onSuccess, onError);
      } else
        post(
          { PipelineStatusId: newStatus, StatusDetailId: cancelReason },
          onSuccess,
          onError
        );
    }
  };

  return (
    <div
      className="w-100 h-100"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Component
        hideName={hideName}
        disabled={loading}
        value={status}
        onChange={onChange}
        setStatus={setStatus}
        type={type}
        id={id}
        post={post}
        loading={loading}
        preview={!canEdit}
        hideCancellation={true}
        {...props}
      />
    </div>
  );
};

const EntityStatusUpdater = (props) => {
  const { canEdit, StatusComponent, status } = props;

  if (!canEdit) return <StatusComponent status={status} {...props} />;

  return <EntityStatusUpdaterComp key={status} {...props} />;
};

export default EntityStatusUpdater;
