import React, { useMemo } from "react";
import { useDetailsEntityId } from "../../../Components/DetailsView/DetailsView";
import { useCall } from "../../../Hooks/EntityHooks";
import {
  DetailsEntities,
  DetailsClientEntity,
  DetailsContactEntity
} from "../../../Components/DetailsView/DetailsChildren/DetailsChildren";

export const CallDetailsChildren = () => {
  const entityId = useDetailsEntityId();
  const formProps = useMemo(() => {
    return { projectId: entityId };
  }, [entityId]);

  const { Client, IndirectClient, Contact } = useCall(entityId) || {};

  return (
    <DetailsEntities formProps={formProps}>
      <DetailsClientEntity id={Client || IndirectClient} />
      <DetailsContactEntity id={Contact} />
    </DetailsEntities>
  );
};
