import React from "react";
import { GeneralListItem } from "../Interventions/InterventionTargetItem";
import { useTask } from "../../Hooks/EntityHooks";

const TaskListItem = ({ task, ...inputProps }) => {
  return <GeneralListItem task item={task} {...inputProps} />;
};

export default TaskListItem;
export const ConnectedTaskListItem = ({ task, ...rest }) => {
  const t = useTask(task);
  return <TaskListItem task={t} {...rest} />;
};
