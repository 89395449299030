import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import {
  useEnterViewSignalR,
  PageTypeProvider
} from "../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimePageEnum } from "../RealTime/RealTimePages";
// import {
//   RealTimePageProvider,
//   RealTimePageEnum
// } from "../RealTime/RealTimePages";
import InterventionDetails from "./InterventionDetails/InterventionDetails";
import InterventionOverview from "./InterventionOverview/InterventionOverview";

const Interventions = withRouter(({ match }) => {
  // useEnterViewSignalR(RealTimePageEnum.TimeCharge)

  return (
    <PageTypeProvider pageType={RealTimePageEnum.TimeCharge}>
      <Switch>
        <Route path={`${match.url}/:interventionId`}>
          <InterventionDetails />
        </Route>
        <Route>
          {/* <RealTimePageProvider pageType={RealTimePageEnum.TimeCharge}> */}
          <InterventionOverview />
          {/* </RealTimePageProvider> */}
        </Route>
      </Switch>
    </PageTypeProvider>
  );
});

export default Interventions;
