import React from "react"
import { ClassificationFormButton } from "../Classification/ClassificationForm";
import BillingOverview from "./BillingOverview/BillingOverview";

export const BillingEntityGridButtons = ({
  entity,
  minimal,
  hideBreadCrumbs
}) => {
  return (
    <React.Fragment>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ClassificationFormButton
          minimal={minimal}
          className="mr-2 FormButtonClassID"
          id={entity.Id}
          canEdit={entity.CanEdit}
        />
      </div>
    </React.Fragment>
  );
};






export default BillingOverview;
