import React from "react";
import { useContract, usePipelineStatus } from "../../Hooks/EntityHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faClock,
  faDrawCircle,
  faFileContract,
  faSuitcase,
  faUser,
  faUsersClass,
  faUserTie
} from "@fortawesome/pro-solid-svg-icons";
import { spaceContractSchema } from "../../config/schema";
import { ConnectedClientProfile } from "../../Components/Clients/ClientProfile";
import SplitCol from "../../Components/SplitCol/SplitCol";
import {
  ProjectHourPercentageBar,
  EstimatedMonthsBar
} from "../../Components/PercentageBar/PercentageBar";
import {
  ConnectedDepartmentProfileStack,
  ConnectedAccountsTeamsDepartmentsProfileStack
} from "../../Components/Accounts/AccountProfileStack";

import {
  ConnectContractEntity,
  ContractStatusProfile,
  ContractStatusProfileWithIcon
} from "../../Components/Contract/ContractProfile";
import { ConnectedContractTypeProfile } from "../../Components/ContractType/ContractTypeProfile";
import {
  OriginHeader,
  OriginItemGroup,
  OriginDate,
  OriginContainer
} from "../../Components/Origin/Origin";
import { createOriginPopup } from "./Origin";
import { ConnectedClientOrigin } from "./ClientOrigin";
import { ConnectedAccountPopup } from "./AcountPopup";
import { ConnectedContactPopup } from "./ContactPopup";
import OriginOverview from "./OriginOverview";
import {
  ContractEntityGridButtons,
  HourControll,
  OutsideContractHours
} from "../Contracts/ContractsHelper";
import classnames from "classnames";
import { ContractIcon } from "../../Components/Common/EntityIcons";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../Components/Button/Button";
import { useEntitySummarySidebar } from "../../Components/EntitySummaries/EntitySummariesHelper";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { PipelineStatusProfile } from "../AdvancedMultiInputs/PipelineDropdowns";
import { useSpace } from "../../Contexts/SpaceContext";
import { useConnectedSchemaGetter } from "../../Helpers/HelperHooks";

export const ContractOriginAnchor = React.forwardRef(
  (
    { contract = {}, children, onClick, dynamicFont, className, ...rest },
    ref
  ) => {
    const openForm = useEntitySummarySidebar();
    const { IsDeleted, Status, Attributes, PipelineStatus, Id } =
      contract || {};
    const resolvedPipelineStatus = usePipelineStatus(PipelineStatus);
    if (!Id) return null;

    return (
      <div
        ref={ref}
        className={classnames("link-color w-fit", className, {
          "item-was-deleted": IsDeleted
        })}
        {...rest}
      >
        {children ? (
          children
        ) : (
          <>
            <div
              className={classnames(
                "link-color w-fit d-flex align-items-center text-truncate",
                {
                  "fs-12": !dynamicFont
                }
              )}
            >
              <div className="d-inline-flex align-items-center mr-1">
                <ContractStatusProfileWithIcon
                  Icon={<FontAwesomeIcon icon={faFileContract} />}
                  inline
                  hideName
                  status={
                    (resolvedPipelineStatus && resolvedPipelineStatus.Status) ||
                    Status ||
                    Attributes
                  }
                />
              </div>
              <Button
                vType="link-primary"
                className="EntityLinkClassID fs-12 text-black"
                onClick={() => {
                  openForm({
                    type: moduleTypes.contracts,
                    props: {
                      id: contract.Id
                    }
                  });
                }}
              >
                <span>
                  {/* {contract.Id}{" "} */}
                  {contract.NoTagDescription || contract.Description}
                </span>
              </Button>
              {/* <div className="d-inline-flex align-items-center">
                <div className="position-relative d-inline-block mr-1">
                  <FontAwesomeIcon icon={faFileContract} />
                </div>
              </div> */}
              {/* <ContractsLink onClick={onClick} id={contract.Id}>
                <span>
                  {/* {contract.Id}{" "} */}
              {/* {contract.NoTagDescription || contract.Description} */}
              {/* </span> */}
              {/* </ContractsLink> */}
              {/* {Id && <div className="fs-10">Nº: {Id}</div>} */}
            </div>
          </>
        )}
      </div>
    );
  }
);
export const ConnectedContractOriginAnchor = ({ contract, ...rest }) => {
  const resolvedContract = useContract(contract);

  const Result = useConnectedSchemaGetter(contract, spaceContractSchema);

  if (Result) return Result;

  return <ContractOriginAnchor contract={resolvedContract} {...rest} />;
};

export const ContractOriginPopup = ({ contract }) => {
  const intl = useIntl();
  const c = useContract(contract.Id);

  const {
    Id,
    // Status,
    Client,
    IndirectClient,
    NoTagDescription,
    Contact,
    Manager,
    Departments,
    // ReceivedDate,
    EndDate,
    BeginDate,
    Collaborators,
    PipelineStatus,
    LeftDurationNotInRange,
    RightDurationNotInRange,
    ContractType,
    CreationDate
  } = c;
  const connectedPipelineStatus = usePipelineStatus(PipelineStatus);
  const resolvedClient = Client || IndirectClient;

  const hasDepartments = Boolean(Departments && Departments.length > 0);
  const hasAccounts = Boolean(
    Collaborators.Accounts && Collaborators.Accounts.length > 0
  );
  const hasTeams = Boolean(
    Collaborators.Teams && Collaborators.Teams.length > 0
  );
  const space = useSpace();
  return (
    <OriginContainer>
      {/* <div className=" mb-2 origin_header_div text-white px-3">
        <span className="origin_header">
          <ContractIcon className="mr-2" />{" "}
          <FormattedMessage id={"CONTRACT_NR"} /> {Id}
        </span>
      </div> */}
      <OriginHeader
        Dif
        className="mb-1"
        type="Contrato"
        date={CreationDate}
        entity="CONTRACT"
        Icon={<ContractIcon className="mr-2" />}
        EntityButtons={
          <ContractEntityGridButtons noColors entity={c} minimal />
        }
        id={Id}
        link={`/s/${space.Id}/contracts/${Id}`}
        title={NoTagDescription}
      />
      {/* <OriginHeader
        id={Id}
        Icon={ContractIcon}
        className="mb-1"
        date={ReceivedDate}
        title={Description}
        type="Contrato"
      /> */}
      {/* <div className="d-flex justify-content-between px-3">
        <div className="d-flex align-items-center text-black fw-medium">
          <ProjectIcon className="mr-1" />
          <div className="mr-1">Projeto</div>
          {Id}
        </div>
        <OriginDate className="fs-14" date={ReceivedDate} />
	  </div> */}

      <div className="mx-3 mb-1">
        {/* <div className="text-black fs-14 mb-2">{Name}</div> */}
        <div className="mb-1 d-flex align-items-center justify-content-between">
          <PipelineStatusProfile
            StatusProfileComponent={ContractStatusProfile}
            pipeline={connectedPipelineStatus}
          />
          <div>
            <OriginDate className="fs-12" date={CreationDate} />
          </div>
        </div>
      </div>
      <div className="mx-3 mb-3">
        {/* <div className="text-black fs-14 mb-2">{Name}</div> */}

        <ProjectHourPercentageBar
          title={<FormattedMessage id="GIVEN_HOURS" />}
          className="mb-2"
          project={c}
        />
        <EstimatedMonthsBar
          title={<FormattedMessage id={"DURATION"} />}
          EndDate={EndDate}
          BeginDate={BeginDate}
        />
      </div>
      {/* <ConnectedBreadcrumbs className="px-3 mb-2" value={{
        type: "Project",
        data: Id
      }} /> */}
      <SplitCol
        className="mb-1"
        left={
          <OriginItemGroup
            icon={faClock}
            title={intl.formatMessage({ id: "OUTSIDE_CONTRACT_HOURS" })}
          >
            <OutsideContractHours
              LeftDurationNotInRange={LeftDurationNotInRange}
              RightDurationNotInRange={RightDurationNotInRange}
              Entity={c}
            />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faClock}
            title={intl.formatMessage({ id: "CONTROL_HOURS" })}
          >
            <HourControll
              Entity={c}
              TimeChargesDurationByRenovation={
                c.TimeChargesDurationByRenovation
              }
              PeriodTime={c.PeriodTime}
            />
          </OriginItemGroup>
        }
      />
      <SplitCol
        className="mb-2"
        left={
          <OriginItemGroup
            icon={faSuitcase}
            title={intl.formatMessage({ id: "ACCOUNT" })}
            className="mb-2"
          >
            <ConnectedClientOrigin client={resolvedClient}>
              <ConnectedClientProfile showId client={resolvedClient} />
            </ConnectedClientOrigin>
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faFileContract}
            title={intl.formatMessage({ id: "TYPE_CONTRACT" })}
          >
            <ConnectedContractTypeProfile
              className="fs-12 text-black"
              contracttype={ContractType}
            />
            {/* <ConnectedAccountProfile account={Manager} /> */}
          </OriginItemGroup>
        }
      />

      <SplitCol
        className="mb-2"
        left={
          <OriginItemGroup
            icon={faUser}
            title={intl.formatMessage({ id: "CONTACT" })}
          >
            <ConnectedContactPopup contact={Contact} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUserTie}
            title={intl.formatMessage({ id: "MANAGER" })}
          >
            <ConnectedAccountPopup account={Manager} />
          </OriginItemGroup>
        }
      />

      <SplitCol
        className="mb-2"
        left={
          <OriginItemGroup
            icon={faCalendarAlt}
            title={intl.formatMessage({ id: "START_DATE" })}
          >
            <OriginDate className="fs-12 text-black" date={BeginDate} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faCalendarAlt}
            title={intl.formatMessage({ id: "END_DATE" })}
          >
            <OriginDate className="fs-12 text-black" date={EndDate} />
          </OriginItemGroup>
        }
      />

      <OriginItemGroup
        className="mb-2"
        icon={faDrawCircle}
        title={intl.formatMessage({ id: "ORIGIN" })}
      >
        <OriginOverview entity={c} />
      </OriginItemGroup>

      {hasDepartments && !(hasAccounts || hasTeams) && (
        <OriginItemGroup
          icon={faUsersClass}
          show={hasDepartments}
          title={intl.formatMessage({ id: "DEPARTMENTS" })}
        >
          <ConnectedDepartmentProfileStack departments={Departments} />
        </OriginItemGroup>
      )}

      {!hasDepartments && (hasAccounts || hasTeams) && (
        <OriginItemGroup
          icon={faUsersClass}
          show={hasAccounts || hasTeams}
          title={intl.formatMessage({ id: "TEAMS_CONTRIBUTORS" })}
        >
          <ConnectedAccountsTeamsDepartmentsProfileStack
            accounts={Collaborators.Accounts}
            teams={Collaborators.Teams}
          />
        </OriginItemGroup>
      )}

      <SplitCol
        show={hasDepartments && (hasAccounts || hasTeams)}
        left={
          <OriginItemGroup
            icon={faUsersClass}
            show={hasDepartments}
            title={intl.formatMessage({ id: "DEPARTMENTS" })}
          >
            <ConnectedDepartmentProfileStack departments={Departments} />
          </OriginItemGroup>
        }
        right={
          <OriginItemGroup
            icon={faUsersClass}
            show={hasAccounts || hasTeams}
            title={intl.formatMessage({ id: "TEAMS_CONTRIBUTORS" })}
          >
            <ConnectedAccountsTeamsDepartmentsProfileStack
              accounts={Collaborators.Accounts}
              teams={Collaborators.Teams}
            />
          </OriginItemGroup>
        }
      />
      {/* <div className="d-flex align-items-center justify-content-center shadow-top py-3 mt-3">
        <ContractEntityButtons
          //   className="mt-3 mb-3"

          entity={c}
        />
      </div> */}
      <div className="pb-3"></div>
    </OriginContainer>
  );
};

export const contractOriginOptions = {
  valueProp: "contract",
  schema: spaceContractSchema
};

export const ContractOrigin = createOriginPopup(
  ContractOriginAnchor,
  ContractOriginPopup,
  contractOriginOptions
);

export default ContractOrigin;

export const ConnectedContractOrigin = ConnectContractEntity(ContractOrigin);
