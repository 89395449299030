import React, { useState, useEffect, useRef, useMemo } from "react";
import "./PercentageBar.css";
import classnames from "classnames";
import { MilSecToHour } from "../../Helpers/MiscHelper";
// import moment from "moment";

const PercentageBar = ({
  percentage,
  className,
  danger,
  disableAnimation,
  skeleton
}) => {
  const resolvedPercentage = percentage > 100 ? 100 : percentage;
  const [width, setWidth] = useState(() =>
    disableAnimation ? resolvedPercentage : 0
  );
  const mountRef = useRef();

  useEffect(() => {
    if (disableAnimation || mountRef.current) return;
    mountRef.current = true;

    setWidth(resolvedPercentage);
  }, [disableAnimation, resolvedPercentage]);

  const resolvedValue =
    disableAnimation || mountRef.current ? resolvedPercentage : width;

  return (
    <div
      className={classnames("ar-percentage-bar position-relative", className, {
        danger: danger,
        skeleton: skeleton
      })}
    >
      {!skeleton && (
        <div
          className="slider"
          style={{
            width: `${resolvedValue}%`
          }}
        />
      )}
    </div>
  );
};

export default PercentageBar;

const EstimatedPerccentageBar = ({
  estimatedHours,
  hours,
  title,
  className,
  skeleton
}) => {
  if (skeleton) {
    return (
      <div className={classnames("text-black fs-12", className)}>
        {title && <div className="ar-percentage-bar-title-skeleton skeleton" />}
        <div className="d-flex align-items-center">
          <PercentageBar skeleton className="flex-1 mr-2" />
          <div className="skeleton ar-percentage-bar-percentage-skeleton" />
        </div>
        <div className="ar-percentage-bar-description-skeleton skeleton" />
      </div>
    );
  }

  const percentage = !estimatedHours
    ? 100
    : Math.floor((hours * 100) / estimatedHours);
  const shouldWarn = percentage > 100;
  return (
    <div className={classnames("text-black fs-12", className)}>
      {title && <div>{title}</div>}
      <div className="d-flex align-items-center">
        <PercentageBar
          danger={shouldWarn}
          className="flex-1 mr-2"
          percentage={percentage}
        />
        <div
          className={classnames("fw-medium", {
            "text-danger-alt": shouldWarn
          })}
        >
          {percentage}%
        </div>
      </div>
      <div
        className={classnames({
          "text-danger-alt": shouldWarn
        })}
      >
        {hours} de {estimatedHours}
      </div>
    </div>
  );
};

// const ElapsedMonthTime = React.memo(({ BeginDate, EndDate, warn }) => {
//   const resolvedEndDate = new Date(EndDate);

//   const today = new Date();

//   const elapsedTime = moment(today).diff(resolvedEndDate);
//   const elapsedDuration = moment
//     .duration(elapsedTime)
//     .locale("pt")
//     .humanize();

//   return (
//     <span
//       className={{
//         "text-danger-alt": warn
//       }}
//     >
//       Ultrapassou {elapsedDuration}
//     </span>
//   );
// });

export const EstimatedMonthsBar = ({
  BeginDate,
  EndDate,
  skeleton,
  className
}) => {
  return null;
  // if (skeleton)
  //   return (
  //     <div
  //       className={classnames(
  //         "d-flex align-items-center text-black fs-12",
  //         className
  //       )}
  //     >
  //       <div className="flex-1 mr-2">
  //         <div className="d-flex justify-content-between mb-1">
  //           <div className="ar-percentage-bar-month-title-skeleton skeleton" />
  //           <div className="ar-percentage-bar-month-title-skeleton skeleton" />
  //         </div>
  //         <PercentageBar skeleton className="flex-1" />
  //       </div>

  //       <div className="ar-percentage-bar-percentage-skeleton skeleton" />
  //     </div>
  //   );

  // const resolvedBeginDate = new Date(BeginDate);
  // const resolvedEndDate = new Date(EndDate);
  // const today = new Date();

  // const estimatedProgress = resolvedEndDate - resolvedBeginDate;
  // // const estimatedDate = resolvedBeginDate + estimatedProgress;
  // const currentProgress = resolvedEndDate - today;

  // let percentage = Math.round(
  //   100 - (currentProgress * 100) / estimatedProgress
  // );
  // // if (percentage > 100) percentage = 100;

  // const shouldWarn = percentage >= 100;
  // return (
  //   <div
  //     className={classnames(
  //       "d-flex align-items-center text-black fs-12",
  //       className
  //     )}
  //   >
  //     <div className="flex-1 mr-2">
  //       <div className="d-flex justify-content-between mb-1">
  //         <div>Inicio</div>
  //         <div>Fim</div>
  //       </div>
  //       <PercentageBar
  //         danger={shouldWarn}
  //         className="flex-1"
  //         percentage={percentage}
  //       />
  //       {shouldWarn && (
  //         <div className={classnames("text-right text-danger-alt")}>
  //           <ElapsedMonthTime
  //             BeginDate={BeginDate}
  //             EndDate={EndDate}
  //             warn={shouldWarn}
  //           />
  //         </div>
  //       )}
  //     </div>

  //     <div
  //       className={classnames("fw-medium", {
  //         "text-danger-alt": shouldWarn
  //       })}
  //     >
  //       {percentage}%
  //     </div>
  //   </div>
  // );
};

export const ProjectHourPercentageBar = ({ project, ...rest }) => {
  const { spentHours, estimatedHours } = useMemo(() => {
    if (project) {
      const {
        EstimatedCost: EstimatedCostMil,
        SpentHours: SpentHoursMil
      } = project;
      return {
        spentHours: MilSecToHour(SpentHoursMil, true) || 0,
        estimatedHours: MilSecToHour(EstimatedCostMil)
      };
    }

    return {
      spentHours: 0,
      estimatedHours: 0
    };
  }, [project]);

  return (
    <EstimatedPerccentageBar
      hours={spentHours}
      estimatedHours={estimatedHours}
      {...rest}
    />
  );
};
