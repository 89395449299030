import React, { Component } from "react";
import Popper from "../Popper/Popper";
import "./Popup.css";

class SubPopup extends Component {
  render() {
    const {
      children,
      isOpen,
      anchorEl,
      placement,
      className,
      domRef,
      onMouseEnter
    } = this.props;
    
    return (
      <Popper
        isOpen={isOpen}
        disablePortal
        anchorEl={anchorEl}
        placement={placement}
        // className="sub-popup-popper"
      >
        <div
          ref={domRef}
          onMouseEnter={onMouseEnter}
					style={{padding: "0px 8px"}}
          className={`sub-popup ${className ? className : ""}`}
        >
          {children}
        </div>
      </Popper>
    );
  }
}

export default SubPopup;
