import React from "react";
import { connectShallowEntity } from "../../Helpers/ReduxHelper";

const InterventionTypeProfile = ({ interventionType, className }) => {
  if (!interventionType) return null;

  const { Name } = interventionType;

  return <span className={className}>{Name}</span>;
};

export default InterventionTypeProfile;

const mapStateToProps = (state, { interventionType }) => {
  if (!interventionType) return null;

  return state.Entities.SpaceInterventionTypes[interventionType];
};

export const ConnectedInterventionTypeProfile = connectShallowEntity(
  "interventionType",
  mapStateToProps
)(InterventionTypeProfile);
