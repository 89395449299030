import { faEdit } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ConnectedAccountPopup } from "../../Origin/AcountPopup";
import moment from "moment";
import "../Reception.css";
import MessageFiles from "../../../Components/EntitySummaries/EntitySummaryMessages/Message/MessageFiles";
const ReceptionEmailNoteChat = React.memo(({ message, ...rest }) => {
  const { Account, NoTagDescription, CreationDate, Files } = message;

  const formatedTime = moment(CreationDate).format("DD/MM/YYYY HH:mm");

  return (
    <div className="ar-chat-reception-note py-2 px-3  mb-3">
      <div className="d-flex align-items-center justify-content-between  mb-2">
        <div className="d-flex align-items-center text-black fs-16">
          <FontAwesomeIcon icon={faEdit} className="mr-3" />
          <ConnectedAccountPopup
            className="fw-medium mr-2"
            dynamicFont
            account={Account}
          />
          <span>
            <FormattedMessage id={"CREATED_THE_NOTE"} />
          </span>
        </div>
        <div className="d-flex align-items-center text-grey-light ">
          <span className="fs-14">{formatedTime}</span>
          {/* <div className="cursor-pointer px-2">
            <FontAwesomeIcon icon={faEllipsisV} />
          </div> */}
        </div>
      </div>
      <div className="text-black fs-14">{NoTagDescription}</div>
      {Boolean(Files) && Files.length > 0 && <MessageFiles files={Files} />}
    </div>
  );
});

export default ReceptionEmailNoteChat;
