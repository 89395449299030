// import React, { useRef, useEffect } from "react";
// import classnames from "classnames";
import {
  darkred,
  red,
  lightred,
  darkgrey,
  grey,
  darkgreen,
  green,
  createChartComponent,
  darkblue,
  blue,
  // opaqueDarkBlue,
  purple,
  lightPurple,
  darkPurple
  // lightblue
} from "./echartDefaults";
import { largeNumberFormatter } from "../../../Helpers/MiscHelper";

export const PieChart = createChartComponent(
  (Data, intl, difrentCloseColor) => {
    return {
      tooltip: {
        renderMode: "richText",
        trigger: "item",
        textStyle: {
          // fontWight: "bold",
          color: "#FFFFFF",
          fontWeight: "normal"
        },
        backgroundColor: "#002B63",
        axisPointer: {
          animation: false
        }
      },
      series: [
        {
          type: "pie",
          // radius: ["0", "60%"],
          center: ["50%", "50%"],
          data: [
            {
              name: intl.formatMessage({ id: "CLOSED" }),
              value: Data.Closed ? Data.Closed : 0,
              label: {
                formatter: "{b}\n{d}%",
                color: difrentCloseColor ? darkred : darkgreen,
                fontWeight: "bold"
              },
              labelLine: {
                lineStyle: {
                  color: difrentCloseColor ? darkred : green
                }
              },
              itemStyle: {
                color: difrentCloseColor ? red : green
              },
              emphasis: {
                itemStyle: {
                  color: green,
                  borderColor: darkgreen,
                  borderWidth: 1,
                  shadowColor: "rgba(0, 0, 0, 0.1)",
                  shadowBlur: 5
                }
              }
              // emphasis: {
              //   itemStyle: {
              //     color: difrentCloseColor ? lightred : opaqueDarkBlue,
              //     borderColor: difrentCloseColor ? darkred : darkblue,
              //     borderWidth: 1,
              //     shadowColor: "rgba(0, 0, 0, 0.1)",
              //     shadowBlur: 5
              //   }
              // }
            },
            {
              name: intl.formatMessage({ id: "CANCELLED" }),
              value: Data.Canceled ? Data.Canceled : 0,
              label: {
                formatter: "{b}\n{d}%",
                color: darkgrey,
                fontWeight: "bold"
              },
              labelLine: {
                lineStyle: {
                  color: darkgrey
                }
              },
              itemStyle: {
                color: grey
              },
              emphasis: {
                itemStyle: {
                  color: grey,
                  borderColor: darkgrey,
                  borderWidth: 1,
                  shadowColor: "rgba(0, 0, 0, 0.1)",
                  shadowBlur: 5
                }
              }
            },
            {
              name: intl.formatMessage({ id: "OPEN" }),
              value: Data.Open ? Data.Open : 0,
              label: {
                formatter: "{b}\n{d}%",
                color: purple,
                fontWeight: "bold"
              },
              labelLine: {
                lineStyle: {
                  color: purple
                }
              },
              itemStyle: {
                color: lightPurple
              },
              emphasis: {
                itemStyle: {
                  color: lightPurple,
                  borderColor: purple,
                  borderWidth: 1,
                  shadowColor: "rgba(0, 0, 0, 0.1)",
                  shadowBlur: 5
                }
              }
            }
          ]
        }
      ]
    };
  }
);

export const BigBarChartPerformance = createChartComponent(
  (Data, intl, difrentCloseColor) => {
    return {
      legend: {
        show: true,
        left: "left",
        selectedMode: false,
        borderRadius: 3,
        icon: "roundRect",
        textStyle: {
          color: darkgrey,
          backgroundColor: lightred
        },
        data: [
          {
            name: intl.formatMessage({ id: "OPEN" }),
            textStyle: {
              color: purple,
              padding: 5,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          },
          {
            name: intl.formatMessage({ id: "CLOSED" }),
            textStyle: {
              color: difrentCloseColor ? darkred : darkgreen,
              padding: 5,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          },
          {
            name: intl.formatMessage({ id: "CANCELLED" }),
            textStyle: {
              color: darkgrey,
              padding: 5,
              backgroundColor: "#fff",
              borderRadius: 3
            }
          }
        ]
      },
      tooltip: {
        textStyle: {
          color: "#FFFFFF",
          fontWight: "bolder"
        },
        show: false,
        trigger: "item",
        axisPointer: {
          type: "shadow"
        }
      },
      grid: {
        left: "0%",
        //   right: "5%",
        //   bottom: "16%",
        top: "-300px",
        containLabel: false
      },
      xAxis: {
        type: "value",

        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      yAxis: {
        type: "category",

        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        }
        // data: []
      },
      series: [
        {
          name: intl.formatMessage({ id: "OPEN" }),
          type: "bar",
          stack: "stack",
          label: {
            show: Data.Open[0] > 0,
            position: ["0%", "-30%"],
            fontWeight: "bold",
            color: purple
          },
          itemStyle: {
            color: lightPurple
          },
          data: Data.Open ? Data.Open : 0
        },
        {
          name: intl.formatMessage({ id: "CLOSED" }),
          type: "bar",
          stack: "stack",
          label: {
            show: Data.Closed[0] > 0,
            position: ["0%", "-30%"],
            color: difrentCloseColor ? darkred : darkgreen,
            fontWeight: "bold"
          },
          itemStyle: {
            color: difrentCloseColor ? red : green
          },
          data: Data.Closed ? Data.Closed : 0
        },
        {
          name: intl.formatMessage({ id: "CANCELLED" }),
          type: "bar",
          stack: "stack",
          label: {
            show: Data.Canceled[0] > 0,
            position: ["0%", "-30%"],
            color: darkgrey,
            fontWeight: "bold"
          },
          itemStyle: {
            color: grey
          },
          data: Data.Canceled ? Data.Canceled : 0,
          barMaxWidth: 70
        }
      ]
    };
  }
);

// const formatter = new Intl.NumberFormat("de-DE", {
//   style: "decimal",
//   minimumFractionDigits: 2
// });
export const DealsPieChart = createChartComponent((Data, intl) => {
  return {
    tooltip: {
      renderMode: "richText",
      trigger: "item",
      textStyle: {
        color: "#FFFFFF",
        fontWight: "bolder"
      },
      backgroundColor: "#002B63",
      axisPointer: {
        animation: false
      },
      formatter: (item) => {
        return `${item.name}: € ${largeNumberFormatter(item.value[0])}`;
      }
    },
    series: [
      {
        type: "pie",
        radius: ["25%", "60%"],
        center: ["50%", "50%"],
        data: [
          {
            name: intl.formatMessage({ id: "SALES" }),
            value: [Data.Won[1]],
            label: {
              formatter: `{b} (${Data.Won[0]})\n{d}%`,
              color: darkblue,
              fontWeight: "bold"
            },
            labelLine: {
              lineStyle: {
                color: darkblue
              }
            },
            itemStyle: {
              // color:red
              color: blue
            },
            emphasis: {
              itemStyle: {
                color: blue,
                borderColor: darkblue,
                borderWidth: 1,
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 5
              }
            }
          },
          {
            name: intl.formatMessage({ id: "LOSES" }),
            value: [Data.Lost[1]],
            label: {
              formatter: `{b} (${Data.Lost[0]})\n{d}%`,
              color: darkred,
              fontWeight: "bold"
            },
            labelLine: {
              lineStyle: {
                color: darkred
              }
            },
            itemStyle: {
              color: red
            },
            emphasis: {
              itemStyle: {
                color: red,
                borderColor: darkred,
                borderWidth: 1,
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 5
              }
            }
          },
          {
            name: intl.formatMessage({ id: "OPEN" }),
            value: [Data.Open[1]],
            label: {
              formatter: `{b} (${Data.Open[0]})\n{d}%`,
              color: darkPurple,
              fontWeight: "bold"
            },
            labelLine: {
              lineStyle: {
                color: darkPurple
              }
            },
            itemStyle: {
              color: purple
            },
            emphasis: {
              itemStyle: {
                color: purple,
                borderColor: darkPurple,
                borderWidth: 1,
                shadowColor: "rgba(0, 0, 0, 0.1)",
                shadowBlur: 5
              }
            }
          }
        ]
      }
    ]
  };
});

export const BigBarDealChartPerformance = createChartComponent((Data, intl) => {
  return {
    // height: 300,
    legend: {
      left: "left",
      show: false,
      selectedMode: false,
      borderRadius: 3,
      icon: "roundRect",
      textStyle: {
        color: darkgrey,
        backgroundColor: lightred
      },
      data: [
        {
          name: intl.formatMessage({ id: "SALES" }),
          textStyle: {
            color: darkgreen,
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 3
          }
        },
        {
          name: intl.formatMessage({ id: "OPEN" }),
          textStyle: {
            color: darkPurple,
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 3
          }
        },
        {
          name: intl.formatMessage({ id: "LOSES" }),
          textStyle: {
            color: darkred,
            padding: 5,
            backgroundColor: "#fff",
            borderRadius: 3
          }
        }
      ]
    },
    grid: {
      // left: "3%",
      // right: "1%",
      //   bottom: "0%",
      // top: "0%",
      // containLabel: false
      // left: "0%",
      //   right: "5%",
      //   bottom: "16%",
      // top: "-300px",
      top: "20px",
      containLabel: false
    },
    xAxis: {
      type: "value",

      // nameLocation: "end",
      show: false,
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: "category",
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      }
      // data: []
    },
    series: [
      {
        name: intl.formatMessage({ id: "SALES" }),
        type: "bar",
        large: true,
        stack: "stack",
        label: {
          show: Data.Won[1] > 0,
          position: "top",
          padding: [0, 0, 0, 0],
          color: darkgreen,
          fontWeight: "bold",
          formatter: `€ ${largeNumberFormatter(Data.Won[1])}`
        },
        itemStyle: {
          color: blue
        },
        data: [Data.Won[1]],
        tooltip: {
          show: false,
          renderMode: "richText",
          trigger: "item",
          textStyle: {
            color: "#FFFFFF",
            fontWeight: "500"
          },
          formatter: `${intl.formatMessage({ id: "SALES" })}: ${[Data.Won[0]]}`,
          backgroundColor: "#002B63",
          axisPointer: {
            animation: false
          }
        }
      },
      {
        barCategoryGap: "40%",
        large: true,
        name: intl.formatMessage({ id: "OPEN" }),
        type: "bar",
        stack: "stack",
        label: {
          show: Data.Open[1] > 0,
          position: "top",
          padding: [0, 0, 0, 0],
          fontWeight: "bold",
          formatter: `€ ${largeNumberFormatter(Data.Open[1])}`,
          color: darkPurple
        },
        itemStyle: {
          color: purple
        },
        data: [Data.Open[1]],
        tooltip: {
          show: false,
          renderMode: "richText",
          trigger: "item",
          textStyle: {
            color: "#FFFFFF",
            fontWeight: "500"
          },
          formatter: `${intl.formatMessage({ id: "OPEN" })}: ${[Data.Open[0]]}`,
          backgroundColor: "#002B63",
          axisPointer: {
            animation: false
          }
        }
      },
      {
        name: intl.formatMessage({ id: "LOSES" }),
        type: "bar",
        stack: "stack",
        label: {
          show: false,
          position: "top",
          padding: [0, 0, 0, 5],
          color: darkred,
          fontWeight: "bold",
          formatter: `€ ${largeNumberFormatter(Data.Lost[1])}`
        },
        itemStyle: {
          color: red
        },
        data: [Data.Lost[1]],
        barMaxWidth: 70,
        tooltip: {
          show: true,
          renderMode: "richText",
          trigger: "item",
          textStyle: {
            color: "#FFFFFF",
            fontWeight: "500"
          },
          formatter: `${intl.formatMessage({ id: "LOSES" })}: ${[
            Data.Lost[0]
          ]}`,
          backgroundColor: "#002B63",
          axisPointer: {
            animation: false
          }
        }
      },
      {
        barGap: "-100%",
        name: intl.formatMessage({ id: "SALES" }),
        type: "bar",
        large: true,
        stack: "stack2",
        label: {
          show: Data.Won[1] > 0,
          position: "bottom",
          padding: [0, 0, 0, 0],
          color: darkgreen,
          fontWeight: "bold",
          formatter: `{a}: ${[Data.Won[0]]}`
        },
        itemStyle: {
          color: green
        },
        data: [Data.Won[1]],
        tooltip: {
          show: false,
          renderMode: "richText",
          trigger: "item",
          textStyle: {
            color: "#FFFFFF",
            fontWeight: "500"
          },
          formatter: `${intl.formatMessage({ id: "SALES" })}: ${[Data.Won[0]]}`,
          backgroundColor: "#002B63",
          axisPointer: {
            animation: false
          }
        }
      },
      {
        barGap: "-100%",
        // barCategoryGap: "40%",
        large: true,
        name: intl.formatMessage({ id: "OPEN" }),
        type: "bar",
        stack: "stack2",
        label: {
          show: Data.Open[1] > 0,
          position: "bottom",
          padding: [0, 0, 0, 0],
          fontWeight: "bold",
          formatter: `${intl.formatMessage({ id: "OPEN" })}: ${[Data.Open[0]]}`,
          color: darkPurple
        },
        itemStyle: {
          color: purple
        },
        data: [Data.Open[1]],
        tooltip: {
          show: false,
          renderMode: "richText",
          trigger: "item",
          textStyle: {
            color: "#FFFFFF",
            fontWeight: "500"
          },
          formatter: `${intl.formatMessage({ id: "OPEN" })}: ${[Data.Open[0]]}`,
          backgroundColor: "#002B63",
          axisPointer: {
            animation: false
          }
        }
      },
      {
        barGap: "-100%",
        name: intl.formatMessage({ id: "LOSES" }),
        type: "bar",
        stack: "stack2",
        label: {
          show: false,
          position: "top",
          padding: [0, 0, 0, 5],
          color: darkred,
          fontWeight: "bold",
          formatter: `${intl.formatMessage({ id: "LOSES" })}: ${[Data.Lost[0]]}`
        },
        itemStyle: {
          color: "#FFD5D7"
        },
        data: [Data.Lost[1]],
        barMaxWidth: 70,
        tooltip: {
          show: true,
          renderMode: "richText",
          trigger: "item",
          textStyle: {
            color: "#FFFFFF",
            fontWeight: "500"
          },
          formatter: `${intl.formatMessage({ id: "LOSES" })}: ${[
            Data.Lost[0]
          ]}`,
          backgroundColor: "#002B63",
          axisPointer: {
            animation: false
          }
        }
      }
    ]
  };
});
