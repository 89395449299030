import React, { useMemo } from "react";
import { originTypes } from "../../Helpers/MiscHelper";
import { ConnectedCallOrigin } from "./CallOrigin";
import { ConnectedProjectOrigin } from "./ProjectOrigin";
import { ConnectedTicketOrigin } from "./TicketOrigin";
import { ConnectedDealOrigin } from "./DealOrigin";
import { ConnectedClientOrigin } from "./ClientOrigin";
import { useSidebar } from "../../Components/Sidebar/SidebarV2";
import { ConnectedTaskOrigin } from "./TaskOrigin";
import { ConnectedContractOrigin } from "./ContractOrigin";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const OriginProfile = ({ origin, ...rest }) => {
  const details = useMemo(() => {
    if (!origin) return null;

    const { type, data } = origin;

		const resolvedType = capitalizeFirstLetter(type)

    let Comp;

    let baseProps;

    switch (resolvedType) {
      case originTypes.client:
        Comp = ConnectedClientOrigin;
        baseProps = { client: data };
        break;

      case originTypes.deals:
        Comp = ConnectedDealOrigin;
        baseProps = { deal: data };
        break;

      case originTypes.task:
        Comp = ConnectedTaskOrigin;
        baseProps = { task: data };
        break;

      case originTypes.ticket:
        Comp = ConnectedTicketOrigin;
        baseProps = { ticket: data };
        break;

      case originTypes.contract:
        Comp = ConnectedContractOrigin;
        baseProps = { contract: data };
        break;

      case originTypes.call:
        Comp = ConnectedCallOrigin;
        baseProps = { call: data };
        break;

      case originTypes.project:
        Comp = ConnectedProjectOrigin;
        baseProps = { project: data };
        break;

      default:
        return null;
    }

    return { Comp, baseProps };
  }, [origin]);

  const [, closeSidebar] = useSidebar();

  if (!details) return null;

  const { Comp, baseProps } = details;

  return <Comp placement="right" onClick={e => closeSidebar(true)} {...baseProps} {...rest} />;
};

export default OriginProfile;
