import React from "react";

const AirdeskTextSvg = ({ className, ...rest }) => {
  return (
    <svg
      className={className ? className : undefined}
      xmlns="http://www.w3.org/2000/svg"
	  viewBox="0 0 290 66.378"
	  {...rest}
    >
      <path
        fill="currentColor"
        d="M37.532,5.147A1.678,1.678,0,0,0,35.841,4.1H25.61a1.542,1.542,0,0,0-1.531,1.047L13.285,35.114a1.379,1.379,0,0,0,1.047,1.772c3.383.564,6.847.886,10.311,1.128a1.489,1.489,0,0,0,1.369-.886l4.914-14.9,4.833,14.9a1.254,1.254,0,0,0,1.369.886c3.464-.242,6.847-.564,10.231-1.128a1.379,1.379,0,0,0,1.047-1.772Z"
        transform="translate(-2.571 -0.797)"
      />
      <path
        fill="currentColor"
        d="M56.47,50.477a1.32,1.32,0,0,0-1.692-1.289,103.2,103.2,0,0,1-26.584,3.383A107.323,107.323,0,0,1,1.611,49.188,1.284,1.284,0,0,0,0,50.477V59.5a1.293,1.293,0,0,0,1.047,1.289l3.383.725L.161,73.355c-.4.886.081,1.45,1.047,1.45h11.52a1.45,1.45,0,0,0,1.531-1.047l3.383-10.311q5.317.483,10.633.483c3.625,0,7.17-.161,10.714-.483l3.383,10.311A1.4,1.4,0,0,0,43.9,74.8h11.52c.967,0,1.45-.644,1.047-1.531L52.2,61.432l3.383-.725a1.377,1.377,0,0,0,1.047-1.289V50.477Z"
        transform="translate(0 -9.554)"
      />
      <path
        fill="currentColor"
        d="M76.7,7.17a7.129,7.129,0,1,1,14.258,0,7.129,7.129,0,0,1-14.258,0Zm1.853,14.742H89.186A1.226,1.226,0,0,1,90.475,23.2V63.962a1.226,1.226,0,0,1-1.289,1.289H78.553a1.226,1.226,0,0,1-1.289-1.289V23.2A1.15,1.15,0,0,1,78.553,21.911Z"
        transform="translate(-14.913)"
      />
      <path
        fill="currentColor"
        d="M134.592,30.108l-2.9,7.653c-.322,1.047-1.128,1.208-2.175.967A11.35,11.35,0,0,0,125.892,38c-5.478,0-8.781,4.108-8.781,9.425V69.017a1.226,1.226,0,0,1-1.289,1.289H105.189a1.226,1.226,0,0,1-1.289-1.289V28.256a1.226,1.226,0,0,1,1.289-1.289h10.07a1.272,1.272,0,0,1,1.289,1.289l.322,5.075c2.175-4.269,5.881-7.331,11.2-7.331a9.377,9.377,0,0,1,6.042,2.175A1.579,1.579,0,0,1,134.592,30.108Z"
        transform="translate(-20.202 -5.055)"
      />
      <path
        fill="currentColor"
        d="M186.356,2.089V64.117a1.226,1.226,0,0,1-1.289,1.289H175.4a1.272,1.272,0,0,1-1.289-1.289L173.87,60.9a16.033,16.033,0,0,1-12.406,5.639c-12.083,0-20.864-10.07-20.864-22.8s8.861-22.8,20.864-22.8a15.939,15.939,0,0,1,11.681,5.156V2.089A1.226,1.226,0,0,1,174.434.8h10.633A1.226,1.226,0,0,1,186.356,2.089ZM172.823,43.737c0-5.719-4.189-10.633-9.747-10.633-5.639,0-9.828,4.592-9.828,10.633,0,6.122,4.189,10.714,9.828,10.714C168.634,54.451,172.823,49.456,172.823,43.737Z"
        transform="translate(-27.338 -0.156)"
      />
      <path
        fill="currentColor"
        d="M242.153,53.331H215.811c1.208,4.833,5.156,7.411,11.2,7.411a14.683,14.683,0,0,0,8.378-2.578,1.316,1.316,0,0,1,1.933.161l4.592,4.431c.564.644.564,1.208-.161,1.933a22.512,22.512,0,0,1-16.111,6.606c-13.614,0-23.039-9.989-23.039-22.8,0-12.728,9.425-22.8,22.072-22.8,12.567,0,20.622,9.989,20.622,21.992C245.375,51.478,244.892,53.331,242.153,53.331Zm-26.5-8.378h17.883c-.806-5.317-4.431-7.895-8.781-7.895S216.617,39.636,215.65,44.953Z"
        transform="translate(-39.393 -4.997)"
      />
      <path
        fill="currentColor"
        d="M291.671,57.739c0,8.861-9.022,13.695-17.078,13.695a22.658,22.658,0,0,1-16.675-7.25,1.347,1.347,0,0,1,0-1.772l3.947-5.639c.4-.644,1.128-.4,1.933,0,3.947,2.5,7.492,3.625,10.392,3.625,2.014,0,4.511-.4,4.511-2.819,0-5.881-19.978-3.383-19.978-17.964,0-8.781,7.492-13.614,15.306-13.614a20.058,20.058,0,0,1,15.225,6.525,1.364,1.364,0,0,1-.081,1.853l-3.947,5.236c-.564.725-1.047.4-1.933-.081-3.3-1.933-5.156-2.981-7.895-2.981-2.658,0-3.867,1.531-3.867,2.981C271.452,45.656,291.671,41.708,291.671,57.739Z"
        transform="translate(-50.083 -5.055)"
      />
      <path
        fill="currentColor"
        d="M348.181,65.387H336.742a1.707,1.707,0,0,1-1.611-.806L323.047,48.55l-5.236,5.639v9.828a1.226,1.226,0,0,1-1.289,1.289H305.889a1.226,1.226,0,0,1-1.289-1.289V1.989A1.226,1.226,0,0,1,305.889.7h10.633a1.226,1.226,0,0,1,1.289,1.289V38L332.07,22.692a1.674,1.674,0,0,1,1.531-.725h12.083c1.128,0,1.531.806.725,1.611L331.264,39.85l17.722,23.925C349.47,64.662,349.228,65.387,348.181,65.387Z"
        transform="translate(-59.225 -0.136)"
      />
    </svg>
  );
};

export default AirdeskTextSvg;
