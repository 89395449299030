import React from "react";
import SuspenseErrorBoundary from "../../../Components/Suspense/SuspenseErrorBoundary";
import { PageTypeProvider } from "../../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimePageEnum } from "../../RealTime/RealTimePages";

const BillingOverviewComp = React.lazy(() =>
  import(/* webpackChunkName: "billing-overview" */ "./BillingOverviewComp")
);

const BillingOverview = () => {
  return (
    <SuspenseErrorBoundary>
      <PageTypeProvider pageType={RealTimePageEnum.Billing}>
        <BillingOverviewComp />
      </PageTypeProvider>
    </SuspenseErrorBoundary>
  );
};

export default BillingOverview;
